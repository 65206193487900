import React from "react";

import { ActionButton } from "./ActionButton";
import { RATE_FEEDBACK_STATUS_TYPES } from "../../../types";

import type { RATE_FEEDBACK_STATUS_TYPES_TYPE } from "../../../types";

type Props = {
  feedbackStatus: RATE_FEEDBACK_STATUS_TYPES_TYPE;
  onApply: (changes: { status: RATE_FEEDBACK_STATUS_TYPES_TYPE }) => Promise<void>;
};

export default function IDontKnowButton(props: Props) {
  const { feedbackStatus, onApply } = props;

  return (
    <ActionButton
      color={feedbackStatus === RATE_FEEDBACK_STATUS_TYPES.DONT_KNOW ? "warning" : "gray"}
      variant={
        feedbackStatus === RATE_FEEDBACK_STATUS_TYPES.DONT_KNOW ? "filled" : "light"
      }
      onClick={() => onApply({ status: RATE_FEEDBACK_STATUS_TYPES.DONT_KNOW })}
    >
      I Don't Know
    </ActionButton>
  );
}

IDontKnowButton.displayName = "IDontKnowButton";
