// @flow

import React from "react";
import { observer } from "mobx-react";
import BuyRateState from "../../../models/BuyRateState";
import PunchOutCard from "./PunchOutCard";
import SearchDetailStore from "../../../stores/mobx/SearchDetailStore";

const PunchOutsView = (props: { searchDetailStore: SearchDetailStore }) => {
  const buyRates = props.searchDetailStore.search.buyRates;
  return (
    <div>
      <div className="row">
        {buyRates &&
          buyRates.map((item) => (
            <PunchOutCard
              key={item.name}
              buyRateState={
                new BuyRateState(
                  props.searchDetailStore.fetchGraphQL,
                  props.searchDetailStore.fetchAPI,
                  props.searchDetailStore.search,
                  item
                )
              }
            />
          ))}
        <PunchOutCard
          buyRateState={
            new BuyRateState(
              props.searchDetailStore.fetchGraphQL,
              props.searchDetailStore.fetchAPI,
              props.searchDetailStore.search,
              null
            )
          }
        />
      </div>
    </div>
  );
};

export default observer(PunchOutsView);
