// @flow
import { extendObservable, action, observable, runInAction, computed } from "mobx";
import Message from "./Message";
import ModalState from "./ModalState";
import NetworkState from "./NetworkState";
import CurrentUser from "./User";

class Tag {
  tagId: number;
  name: string;
  selected: boolean;

  constructor(object: Object) {
    this.tagId = object.tagId;
    this.name = object.name;

    extendObservable(this, {
      selected: object.selected,
    });
  }
}

export default class ApplyTagState {
  messages: Message[];

  fetchGraphQL: (string, Object) => Promise<any>;
  showTagModal: () => void;
  tagsOnSelectAll: () => void;
  tagsOnDeselectAll: () => void;
  tagsOnInstantSearch: () => void;
  setTagSelectedValue: () => void;
  getSelectedTagList: () => Array<number>;
  getTags: () => void;
  tagsView: any;
  store: any;
  tagsViewState: Object;
  TagsList: Tag[];
  tagModal: ModalState;
  tagNetwork: NetworkState;
  tagsInstantSearchValue: string;
  currentUser: CurrentUser;

  constructor(fetchGraphQL: (string, Object) => Promise<any>, store: Object) {
    this.fetchGraphQL = fetchGraphQL;
    this.store = store;

    extendObservable(this, {
      tagsViewState: observable.map({}),
      tagsView: [],
      tagModal: new ModalState(),
      tagNetwork: new NetworkState(),
      tagsInstantSearchValue: null,
      selectedTag: computed(() => {
        let count = 0;
        this.tagsView.forEach((value, key) => {
          if (value.selected) {
            count++;
          }
        });

        return count;
      }),
    });
    this.currentUser = null;
    this.showTagModal = action(this.showTagModal.bind(this));
    this.tagsOnSelectAll = action(this.tagsOnSelectAll.bind(this));
    this.tagsOnDeselectAll = action(this.tagsOnDeselectAll.bind(this));
    this.tagsOnInstantSearch = action(this.tagsOnInstantSearch.bind(this));
    this.setTagSelectedValue = action(this.setTagSelectedValue.bind(this));
    this.getSelectedTagList = action(this.getSelectedTagList.bind(this));
    this.getTags = action(this.getTags.bind(this));
  }

  showTagModal() {
    //this.tagsOnInstantSearch(null);
    this.tagsInstantSearchValue = "";
    this.tagsOnDeselectAll();
    this.getTags();
    this.tagModal.showModal();
  }

  tagsOnSelectAll(e: Object) {
    this.tagsView.forEach((tag) => {
      this.tagsViewState.set(tag.tagId, true);
      tag.selected = true;
    });
  }

  tagsOnDeselectAll(e: Object) {
    this.tagsView.forEach((tag) => {
      this.tagsViewState.set(tag.tagId, false);
      tag.selected = false;
    });
  }

  tagsOnInstantSearch(value: string) {
    this.tagsInstantSearchValue = value;

    if (!this.tagsInstantSearchValue) {
      this.tagsView = this.TagsList;
      return;
    }

    this.tagsView = this.TagsList.filter((tag) => {
      const tagName = tag.name.toLowerCase();
      const query = this.tagsInstantSearchValue.toLowerCase();

      // this works because ~ is the binary inverse of a number and ~ -1 = 0
      return ~tagName.indexOf(query);
    });
  }

  setTagSelectedValue(tag: Object) {
    const oldSelectedValue = this.tagsViewState.get(tag.tagId);

    if (oldSelectedValue) {
      this.tagsViewState.set(tag.tagId, !oldSelectedValue);
      tag.selected = !oldSelectedValue;
    } else {
      this.tagsViewState.set(tag.tagId, true);
      tag.selected = true;
    }
  }

  getSelectedTagList(): Array<number> {
    const list = this.tagsViewState.entries();

    let tagslist = [];

    list.forEach(([tagId, selected]) => {
      if (selected) {
        tagslist.push(tagId);
      }
    });

    return tagslist;
  }

  async getTags() {
    const query = `
        query getTag{
          viewer{
            tags{
              edges{
                node{
                  name
                  tagId
                }
              }
            }
          }
        }
    `;

    this.tagNetwork.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, {});
    } catch (e) {
      this.tagNetwork.handleError("Getting Users for sharing", e);
      // TODO: Display user friendly error message
      return;
    }

    runInAction("getTags--success", () => {
      this.tagNetwork.loading = false;
      this.tagNetwork.error = null;
      if (this.tagNetwork.logGraphQLError("Filter criteria query", res)) {
        // TODO: Display user friendly error message
        // 'There was an error retrieving the data for this page. Please reload and try again later.',
        return;
      }

      // $FlowFixMe: Type the res value once we abstract gql calls.
      const tags = res.data.viewer.tags.edges;
      // const currentlySharedUsers = res.data.viewer.rateCard.currentlySharedWithUsers.edges;

      this.tagNetwork.loading = false;
      this.tagNetwork.error = null;

      // set as selected the users that are currently shared with
      this.tagsViewState = observable.map({});
      // currentlySharedUsers.forEach(edge => {
      //   this.shareUsersViewState.set(edge.node.userId, true)
      // });

      this.TagsList = tags.map((edge) => {
        const tag = new Tag(edge.node);
        if (this.tagsViewState.has(tag.tagId)) {
          tag.selected = this.tagsViewState.get(tag.tagId);
        } else if (this.store.selectedTags) {
          tag.selected = this.store.selectedTags.has(tag.tagId);
          this.tagsViewState.set(tag.tagId, tag.selected);
        } else {
          tag.selected = false;
        }

        return tag;
      });
      this.tagsView = this.TagsList;
    });
  }
}
