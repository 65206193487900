import { useRef, useState, useEffect, useCallback } from "react";
import { fetchQuery, GraphQLTaggedNode, useRelayEnvironment } from "react-relay";
import {
  OperationType,
  CacheConfig,
  FetchQueryFetchPolicy,
  Subscription,
} from "relay-runtime";

type QueryArgs<T extends OperationType> = {
  gql: GraphQLTaggedNode;
  variables?: T["variables"];
  config?: {
    cacheConfig?: CacheConfig;
    fetchPolicy?: FetchQueryFetchPolicy;
  };
  onError?: () => void;
  enable?: boolean;
  onComplete?: (response: T["response"]) => void;
};

/**
 * Important: since our current react version does not support useTransition
 * this is hook let's us fetch data while avoiding suspense
 */
export function useRelayQuery<T extends OperationType>(args: QueryArgs<T>) {
  const {
    gql,
    onComplete = () => {},
    onError = () => {},
    config = {},
    variables = {},
    enable = true,
  } = args;
  const relayEnvironment = useRelayEnvironment();
  const [data, setData] = useState<T["response"] | null>(null);
  const [loading, setLoading] = useState(false);
  const [failed, setFailed] = useState(false);

  useEffect(() => {
    if (!enable) return;
    setLoading(true);
    const sub = fetchQuery(relayEnvironment, gql, variables, config).subscribe({
      next: (response) => {
        onComplete(response);
        setData(response);
        setLoading(false);
      },
      error: () => {
        if (onError) {
          setLoading(false);
          setFailed(true);
          onError();
        }
      },
    });
    return () => sub.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(variables), enable]);

  return {
    data,
    loading,
    failed,
  };
}

type LazyQueryArgs<T extends OperationType> = {
  gql: GraphQLTaggedNode;
  onComplete?: (response: T["response"]) => void;
  onError?: () => void;
  config?: {
    cacheConfig?: CacheConfig;
    fetchPolicy?: FetchQueryFetchPolicy;
  };
  variables?: T["variables"];
};

/**
 * Important: since our current react version does not support useTransition
 * this is hook let's us fetch data while avoiding suspense
 */
export function useLazyRelayQuery<T extends OperationType>(args: LazyQueryArgs<T>) {
  const {
    gql,
    onComplete = () => {},
    onError = () => {},
    config = {},
    variables = {},
  } = args;
  const relayEnvironment = useRelayEnvironment();
  const subRef = useRef<Subscription>();
  const [data, setData] = useState<T["response"] | null>(null);
  const [loading, setLoading] = useState(false);
  const [failed, setFailed] = useState(false);

  const send = useCallback((vars: T["variables"] = {}) => {
    setLoading(true);
    subRef.current = fetchQuery(
      relayEnvironment,
      gql,
      vars || variables,
      config
    ).subscribe({
      next: (response) => {
        onComplete(response);
        setData(response);
        setLoading(false);
      },
      error: () => {
        setFailed(true);
        setLoading(false);
        onError();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      if (subRef.current?.unsubscribe) {
        subRef.current.unsubscribe();
      }
    };
  }, []);

  return {
    data,
    loading,
    failed,
    send,
  };
}
