import React, { Component } from "react";
import includes from "lodash/includes";
import AsyncSelect from "react-select/lib/Async";
import { reactSelectStyles, reactSelectTheme } from "../../constants/reactSelect";

var timeout = null;

class LocationSelectNew extends Component {
  constructor(props) {
    super(props);

    this.getOptions = this.getOptions.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.formatOptionLabel = this.formatOptionLabel.bind(this);
  }

  getOptions(input, callback) {
    clearTimeout(timeout);
    var that = this;
    timeout = setTimeout(function () {
      if (input.length > 0) {
        var locationList = [];
        that.props
          .locationTitle(input)
          .then((response) => {
            if (!response) return;
            response.locationTitle.forEach((e) => {
              var optionItem = {};
              optionItem["value"] = e.locationId.toString();
              optionItem["label"] = e.subtitle.toString()
                ? e.title.toString() + ", " + e.subtitle.toString()
                : e.title.toString();
              optionItem.countryId = e.countryId;
              locationList.push(optionItem);
            });
            callback(locationList);
          })
          .catch(function (error) {
            callback([]);
          });
      } else {
        callback([]);
      }
    }, 500);
  }

  handleChange(value, actionMeta) {
    let locationsSelected = [];

    value.forEach((l) => {
      let location = {
        locationId: l.value,
        country: l.label,
        countryId: l.countryId,
      };

      locationsSelected.push(location);
    });

    this.props.onChange(locationsSelected);
  }

  formatOptionLabel(option, selectCtx) {
    // console.log('option', option);
    // console.log('selectCtx', selectCtx);
    //
    let optionSuffix = null;

    if (includes(this.props.certifiedCountries, option.countryId)) {
      optionSuffix = (
        <span className="rs-tag rs-tag-green">
          <span className="glyphicon glyphicon-globe" /> Certified
        </span>
      );
    }

    if (includes(this.props.adhocCountries, option.countryId)) {
      optionSuffix = (
        <span className="rs-tag rs-tag-red">
          <span className="glyphicon glyphicon-globe" /> Ad-Hoc
        </span>
      );
    }

    return (
      <div>
        {option.label}
        {optionSuffix}
      </div>
    );
  }

  render() {
    let extraProps = {};

    if (this.props.clientIsClientJobLibrary) {
      extraProps.formatOptionLabel = this.formatOptionLabel;
    }

    return (
      <AsyncSelect
        inputId={this.props.id || "locationSelect"}
        name="locations"
        placeholder="Type to find your location..."
        isMulti
        value={this.props.value}
        loadOptions={this.getOptions}
        onChange={this.handleChange}
        noOptionsMessage={(inputValue) => "No locations found for your input..."}
        styles={reactSelectStyles}
        theme={reactSelectTheme}
        {...extraProps}
      />
    );
  }
}

export default LocationSelectNew;
