// @flow
import { extendObservable, action, runInAction } from "mobx";
import NetworkState from "../../../models/NetworkState";
import MessageState from "../../../models/MessageState";
import ClientList from "../../../models/ClientList";
import type { FetchGraphQL } from "../../../App";

class ClientCreatePTStore {
  router: Object;
  network: NetworkState;
  clientDetail: ClientList;
  messaging: MessageState;
  limitMessaging: MessageState;
  clientId: number;

  clientName: String;
  searchLimit: number;
  limitedSearchFlag: boolean;
  shareRatecardsFlag: boolean;
  perSearchPricingFlag: boolean;
  isClientLibrary: boolean;
  hasThreeLevel: boolean;
  hasNumericLevelsOnExport: boolean;
  termsOfAgreement: boolean;
  activeFlag: boolean;
  isRefreshEligible: boolean;

  createClient: () => void;
  setSearchLimit: () => void;
  setLimitedSearchFlag: () => void;
  setShareRatecardsFlag: () => void;
  setActiveFlag: () => void;
  setRefreshEligible: () => void;
  setSearchPricingFlag: () => void;
  setClientLibraryFlag: () => void;
  setThreeLevel: () => void;
  setHasNumericLevelsOnExport: () => void;
  setTermsOfAgreement: () => void;
  messaging: MessageState;
  redirectToList: () => void;
  resetClientValues: () => void;
  clearAllErrorMessage: () => void;
  fetchGraphQL: FetchGraphQL;

  constructor(fetchGraphQL: FetchGraphQL) {
    this.fetchGraphQL = fetchGraphQL;
    this.router = null;

    extendObservable(this, {
      clientId: null,
      network: new NetworkState(),
      renameNetwork: new NetworkState(),
      clientDetail: null,
      messaging: new MessageState(),
      limitMessaging: new MessageState(),
      searchLimit: 0,
      limitedSearchFlag: false,
      shareRatecardsFlag: false,
      activeFlag: true,
      clientName: "",
      isRefreshEligible: true,
      perSearchPricingFlag: false,
      isClientLibrary: false,
      hasThreeLevel: false,
      hasNumericLevelsOnExport: false,
      termsOfAgreement: true,
    });

    this.createClient = action(this.createClient.bind(this));
    this.setSearchLimit = action(this.setSearchLimit.bind(this));
    this.setLimitedSearchFlag = action(this.setLimitedSearchFlag.bind(this));
    this.setShareRatecardsFlag = action(this.setShareRatecardsFlag.bind(this));
    this.setClientName = action(this.setClientName.bind(this));
    this.setRefreshEligible = action(this.setRefreshEligible.bind(this));
    this.redirectToList = action(this.redirectToList.bind(this));
    this.resetClientValues = action(this.resetClientValues.bind(this));
    this.setActiveFlag = action(this.setActiveFlag.bind(this));
    this.clearAllErrorMessage = action(this.clearAllErrorMessage.bind(this));
    this.setSearchPricingFlag = action(this.setSearchPricingFlag.bind(this));
    this.setClientLibraryFlag = action(this.setClientLibraryFlag.bind(this));
    this.setThreeLevel = action(this.setThreeLevel.bind(this));
    this.setHasNumericLevelsOnExport = action(
      this.setHasNumericLevelsOnExport.bind(this)
    );
  }

  setSearchLimit(e: Event) {
    const re = /^\d+$/;
    if (re.test(e.target.value) || e.target.value === "") {
      this.searchLimit = e.target.value;
    } else {
      return false;
    }
  }

  setLimitedSearchFlag(value) {
    this.limitedSearchFlag = value === "false" ? false : true;
  }

  setShareRatecardsFlag(value) {
    this.shareRatecardsFlag = value === "false" ? false : true;
  }

  setSearchPricingFlag(value) {
    this.perSearchPricingFlag = value === "false" ? false : true;
  }

  setClientLibraryFlag(value) {
    this.isClientLibrary = value === "false" ? false : true;
  }

  setActiveFlag(value) {
    this.activeFlag = value === "false" ? false : true;
  }

  setThreeLevel(value) {
    this.hasThreeLevel = value === "false" ? false : true;
  }

  setHasNumericLevelsOnExport(value) {
    this.hasNumericLevelsOnExport = value === "false" ? false : true;
  }

  setTermsOfAgreement(value) {
    this.termsOfAgreement = value === "false" ? false : true;
  }

  setClientName(e: Event) {
    this.clientName = e.target.value;
  }

  setRefreshEligible(value) {
    this.isRefreshEligible = value === "false" ? false : true;
  }

  redirectToList() {
    this.clearAllErrorMessage();
    if (this.router) {
      this.router.push({
        pathname: "/admin/clients",
      });
    }
  }

  resetClientValues() {
    this.clientName = "";
    this.searchLimit = 0;
    this.limitedSearchFlag = false;
    this.shareRatecardsFlag = false;
    this.activeFlag = false;
  }

  clearAllErrorMessage() {
    this.messaging.removeAll();
    this.limitMessaging.removeAll();
  }

  async createClient() {
    this.clearAllErrorMessage();

    if (!this.clientName.trim()) {
      this.messaging.createMessage("info", "Please enter a Client Name.");
      return;
    }

    if (this.searchLimit === "") {
      this.limitMessaging.createMessage("info", "Please enter a Search Limit.");
      return;
    }
    let input = {
      name: this.clientName,
      //searchLimit: this.searchLimit,
      //limitedSearchFlag: this.limitedSearchFlag ,
      shareRatecardsFlag: this.shareRatecardsFlag,
      activeFlag: this.activeFlag,
      termsOfAgreement: true,
      byUserTermsFlag: true,
      perSearchPricing: this.perSearchPricingFlag,
      isClientJobLibrary: this.isClientLibrary,
      hasThreeLevel: this.hasThreeLevel,
      hasNumericLevelsOnExport: this.hasNumericLevelsOnExport,
      refreshEligible: this.isRefreshEligible,
    };

    const query = `
      mutation createNewClient($input : CreateClientInput!){
        createClient(input : $input){
          client{
            name
            activeFlag
            shareRatecardsFlag
          }
        }
      }
    `;
    const variables = { input };

    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
      // should hit get request
    } catch (e) {
      console.error("Error editing client detail", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }

    runInAction("createClient--success", () => {
      // const client = res.data.updateClient.client;
      this.resetClientValues();
      if (this.router) {
        this.router.push({
          pathname: "/admin/clients",
        });
      }
    });
  }
}
export default ClientCreatePTStore;
