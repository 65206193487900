import React, { useState, useCallback } from "react";
import { fromJS, Map } from "immutable";

import Icon from "../../../components/lib/Icon";
import Alert from "../../../components/lib/Alert";
// @ts-ignore
import SingleSelect from "../../../components/selects/SingleSelect";
import Button from "../../../components/lib/Button";
import TextInput from "../../../components/lib/TextInput";
import Text from "../../../components/lib/Text";
import Inline from "../../../components/lib/Inline";
import Center from "../../../components/lib/Center";
import Stack from "../../../components/lib/Stack";
import Box from "../../../components/lib/Box";
import { Table, THead, TR, TH, TBody, TD } from "../../../components/lib/Table";
import { ButtonGroup } from "../../../components/lib/ButtonGroup";
import {
  Checkbox,
  CheckboxIndicator,
  LabelInline,
} from "../../../components/lib/Checkbox";
import NumberInput, { isFiniteNumber } from "../../../components/lib/NumberInput";
import {
  QUESTION_ANSWER_FORMATS,
  QUESTION_ANSWER_FORMATS_LABELS,
  QUESTION_ANSWER_FORMATS_OPTIONS,
  QUESTION_ANSWER_FORMATS_TYPE,
  QUESTION_INPUTS_TYPES,
  QUESTION_INPUTS_TYPES_TYPE,
  QuestionDataMap,
  QuestionDataObject,
  QuestionsFeedbackOrderedMap,
  QuestionsOrderedMap,
} from "../types";
import { emptyOrderedMap } from "../../../constants";

type NewQuestionDataObject = Omit<QuestionDataObject, "id" | "choices"> & {
  choices?: string;
};

type QuestionAttributeValidator = <T>(
  value: T,
  _: NewQuestionDataObject
) => string | null;

// validators

const requiredValueValidator: QuestionAttributeValidator = (value, obj) => {
  return value === "" || value === null ? "fill up required values!" : null;
};

const isChoicesRequiredValidator: QuestionAttributeValidator = (value, obj) => {
  const answerFormat = obj["answer_format"];

  return answerFormat === QUESTION_ANSWER_FORMATS.SELECT && value == null
    ? "fill up required values!"
    : null;
};

const isCorrectJSONValueValidator: QuestionAttributeValidator = (value, obj) => {
  const answerFormat = obj["answer_format"];

  if (answerFormat === QUESTION_ANSWER_FORMATS.SELECT) {
    try {
      const parsedValue = JSON.parse(value as string);

      if (Array.isArray(parsedValue) && parsedValue.length > 0) {
        return null;
      }
    } catch (ex) {
      // noop
    }

    return "bad values provided as choices for select input";
  }

  return null;
};

// constants

const DEFAULT_ORDER_POSITION = 0;

const newEmptyQuestion: NewQuestionDataObject = {
  statement: "",
  answer_format: QUESTION_ANSWER_FORMATS.TEXTBOX,
  default_value: "",
  included: true,
  is_required: false,
  order: DEFAULT_ORDER_POSITION,
};

const questionInputsValidators: Partial<
  Record<QUESTION_INPUTS_TYPES_TYPE, QuestionAttributeValidator[]>
> = {
  [QUESTION_INPUTS_TYPES.STATEMENT]: [requiredValueValidator],
  [QUESTION_INPUTS_TYPES.ANSWER_FORMAT]: [requiredValueValidator],
  [QUESTION_INPUTS_TYPES.CHOICES]: [
    isChoicesRequiredValidator,
    isCorrectJSONValueValidator,
  ],
  [QUESTION_INPUTS_TYPES.ORDER]: [requiredValueValidator],
};

function validateQuestionData(
  question: NewQuestionDataObject
): Record<QUESTION_INPUTS_TYPES_TYPE, string[]> {
  const errors = {} as Record<QUESTION_INPUTS_TYPES_TYPE, string[]>;
  const inputsKeys = Object.keys(
    questionInputsValidators
  ) as QUESTION_INPUTS_TYPES_TYPE[];

  // validate inputs
  inputsKeys.forEach((inputKey) => {
    const inputValue = question[inputKey] ?? null;
    const inputValidators = questionInputsValidators[inputKey] ?? [];
    const inputErrors = inputValidators
      .map((validator) => validator(inputValue, question))
      .filter((i) => i != null) as string[];

    if (inputErrors.length) {
      errors[inputKey] = inputErrors;
    }
  });

  return errors;
}

const questionTypesDefaultValues = {
  [QUESTION_ANSWER_FORMATS.TEXTBOX]: null,
  [QUESTION_ANSWER_FORMATS.SELECT]: null,
  [QUESTION_ANSWER_FORMATS.CHECKBOX]: null,
} as const;

type QuestionsEditorState = {
  newQuestion: NewQuestionDataObject | null;
  invalidInputs: Array<QUESTION_INPUTS_TYPES_TYPE> | null;
  invalidMessage: string | null;
};

type QuestionsEditorProps = {
  questions: QuestionsOrderedMap;
  onChange: (questions: QuestionsOrderedMap) => void;
};

export const QuestionsEditor = (props: QuestionsEditorProps) => {
  const { questions, onChange } = props;

  // state

  const [state, setState] = useState<QuestionsEditorState>({
    newQuestion: null,
    invalidInputs: null,
    invalidMessage: null,
  });
  const updateState = (data: Partial<QuestionsEditorState>) => {
    setState((prevState) => ({ ...prevState, ...data }));
  };

  // handlers

  const handleClickAddNewQuestion = useCallback(
    () => updateState({ newQuestion: newEmptyQuestion }),
    []
  );

  const handleClickApplyNewQuestion = useCallback(() => {
    if (Object.keys(state.newQuestion ?? {}).length > 0 && onChange) {
      const errors = validateQuestionData(state.newQuestion!);
      const errorsKeys = Object.keys(errors) as QUESTION_INPUTS_TYPES_TYPE[];

      if (errorsKeys.length > 0) {
        updateState({
          invalidInputs: errorsKeys,
          invalidMessage: (errors[errorsKeys[0]] || [])[0],
        });
      } else {
        updateState({
          newQuestion: null,
          invalidInputs: null,
          invalidMessage: null,
        });

        const choicesString = state.newQuestion?.choices;
        const resultingQuestion = {
          ...state.newQuestion,
          choices: choicesString ? fromJS(JSON.parse(choicesString)) : undefined,
        } as QuestionDataObject;

        onChange(
          questions.set(
            Date.now(),
            Map(resultingQuestion) as QuestionDataMap
          ) as QuestionsOrderedMap
        );
      }
    }
  }, [state.newQuestion, questions, onChange]);

  const handleClickCancelNewQuestion = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      newQuestion: null,
      invalidInputs: null,
      invalidMessage: null,
    }));
  }, []);

  const handleExistingQuestionChange = useCallback(
    (id: number, data: Partial<QuestionDataObject> = {}) => {
      let resultingQuestions = questions ?? emptyOrderedMap;

      if (data && Object.keys(data).length > 0 && onChange) {
        if (resultingQuestions.has(id)) {
          resultingQuestions = resultingQuestions.set(
            id,
            resultingQuestions.get(id).merge(Map(data)) as QuestionDataMap
          ) as QuestionsOrderedMap;
        } else {
          resultingQuestions = resultingQuestions.set(
            id,
            Map(data) as QuestionDataMap
          ) as QuestionsOrderedMap;
        }

        onChange(resultingQuestions);
      }
    },
    [questions, onChange]
  );

  const handleIncludeQuestion = useCallback(
    (id: number | undefined) => {
      if (id === undefined) return;

      const resultingQuestions = questions || emptyOrderedMap;
      const currentValue = resultingQuestions.get(id).get("included") ?? false;
      return handleExistingQuestionChange(id, { included: !currentValue });
    },
    [questions, handleExistingQuestionChange]
  );

  const handleIsRequiredQuestionChange = useCallback(
    (id: number | undefined) => {
      if (id === undefined) return;

      const resultingQuestions = questions || emptyOrderedMap;
      const currentValue = resultingQuestions.get(id).get("is_required") ?? false;
      return handleExistingQuestionChange(id, { is_required: !currentValue });
    },
    [questions, handleExistingQuestionChange]
  );

  const handleOrderQuestionChange = useCallback(
    (id: number | undefined, e: React.ChangeEvent<HTMLInputElement>) => {
      if (id === undefined) return;

      const value = e.target.valueAsNumber;
      return handleExistingQuestionChange(id, {
        order: Number.isNaN(value) ? undefined : value,
      });
    },
    [handleExistingQuestionChange]
  );

  const handleNewQuestionParametersChange = useCallback(
    (data: Partial<NewQuestionDataObject> = {}) => {
      if (state.newQuestion != null && Object.keys(data).length > 0) {
        updateState({
          newQuestion: {
            ...state.newQuestion,
            ...data,
          },
        });
      }
    },
    [state.newQuestion]
  );

  const handleNewQuestionStatementChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleNewQuestionParametersChange({ statement: e.target.value });
    },
    [handleNewQuestionParametersChange]
  );

  const handleNewQuestionFormatChange = useCallback(
    (e) => {
      const format = e.value as QUESTION_ANSWER_FORMATS_TYPE;

      return handleNewQuestionParametersChange({
        answer_format: format,
        default_value: questionTypesDefaultValues[format],
      });
    },
    [handleNewQuestionParametersChange]
  );

  const handleNewQuestionChoicesChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      return handleNewQuestionParametersChange({ choices: e.target.value });
    },
    [handleNewQuestionParametersChange]
  );

  const handleNewQuestionRequiredChange = useCallback(
    (checked: boolean) => {
      return handleNewQuestionParametersChange({ is_required: checked });
    },
    [handleNewQuestionParametersChange]
  );

  const handleNewQuestionOrderChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.valueAsNumber;
      return handleNewQuestionParametersChange({
        order: Number.isNaN(value) ? undefined : value,
      });
    },
    [handleNewQuestionParametersChange]
  );

  // render

  const { newQuestion, invalidMessage } = state;
  const newQuestionStatement = newQuestion?.["statement"];
  const newQuestionFormatValue = newQuestion?.["answer_format"];
  const newQuestionFormatLabel = QUESTION_ANSWER_FORMATS_LABELS[newQuestionFormatValue!];
  const newQuestionChoices = newQuestion?.["choices"];
  const newQuestionRequired = newQuestion?.["is_required"];
  const newQuestionOrder = newQuestion?.["order"];

  return (
    <Stack fill css={{ alignItems: "flex-start", gap: "$2" }}>
      <Text as="h6">
        <Icon icon="info-circle" /> - required parameters marked with *
      </Text>

      <Box fill css={{ width: "$full" }}>
        <Table highlighted={false} css={{ backgroundColor: "$white" }}>
          <THead>
            <TR>
              <TH css={{ "@md": { width: "50px" } }}>Include</TH>
              <TH css={{ "@md": { width: "50px" } }}>Required</TH>
              <TH css={{ "@md": { width: "50px" } }}>Order</TH>
              <TH css={{ " @md": { width: "60%" } }}>
                <Text nowrap>Question *</Text>
              </TH>
              <TH css={{ "@md": { width: "10%" } }}>
                <Text nowrap>Answer Format *</Text>
              </TH>
              <TH>Options *</TH>
            </TR>
          </THead>
          <TBody>
            {questions.size === 0 && newQuestion == null && (
              <TR>
                <TD colSpan={999}>
                  <Alert fill color="warning">
                    <Text>No default questions list configured</Text>
                  </Alert>
                </TD>
              </TR>
            )}
            {questions.size > 0 &&
              questions.toArray().map((item) => {
                const id = item.get("id");
                const questionIncluded = item.get("included");
                const questionRequired = item.get("is_required");
                const questionOrder = item.get("order", 0);
                const questionStatement = item.get("statement");
                const questionFormat = item.get("answer_format");
                const questionChoices = item.get("choices") ?? [];
                const questionChoicesString = questionChoices.join(", ");

                return (
                  <TR key={id}>
                    <TD>
                      <Center>
                        <Checkbox
                          checked={!!questionIncluded}
                          onCheckedChange={() => handleIncludeQuestion(id)}
                        >
                          <CheckboxIndicator>
                            <Icon icon="check" />
                          </CheckboxIndicator>
                        </Checkbox>
                      </Center>
                    </TD>
                    <TD>
                      <Center>
                        <Checkbox
                          checked={!!questionRequired}
                          onCheckedChange={() => handleIsRequiredQuestionChange(id)}
                        >
                          <CheckboxIndicator>
                            <Icon icon="check" />
                          </CheckboxIndicator>
                        </Checkbox>
                      </Center>
                    </TD>
                    <TD css={{ textAlign: "center" }}>
                      <NumberInput
                        size="small"
                        style={{ width: "30px" }}
                        value={questionOrder ?? ""}
                        color={isFiniteNumber(questionOrder) ? "primary" : "danger"}
                        onChange={(e) => handleOrderQuestionChange(id, e)}
                      />
                    </TD>
                    <TD>{questionStatement}</TD>
                    <TD>{QUESTION_ANSWER_FORMATS_LABELS[questionFormat]}</TD>
                    <TD>{questionChoicesString}</TD>
                  </TR>
                );
              })}
            {newQuestion != null && (
              <TR key={questions.size}>
                <TD colSpan={2}>
                  <LabelInline>
                    Is Required?
                    <Checkbox
                      checked={!!newQuestionRequired}
                      onCheckedChange={handleNewQuestionRequiredChange}
                    >
                      <CheckboxIndicator>
                        <Icon icon="check" />
                      </CheckboxIndicator>
                    </Checkbox>
                  </LabelInline>
                </TD>
                <TD css={{ textAlign: "center" }}>
                  <NumberInput
                    size="small"
                    style={{ width: "30px" }}
                    value={newQuestionOrder ?? ""}
                    onChange={handleNewQuestionOrderChange}
                  />
                </TD>
                <TD>
                  <Text as="label" css={{ whiteSpace: "nowrap" }}>
                    Enter question text
                  </Text>
                  <TextInput
                    tabIndex={0}
                    value={newQuestionStatement}
                    onChange={handleNewQuestionStatementChange}
                  />
                </TD>
                <TD>
                  <Text as="label" css={{ whiteSpace: "nowrap" }}>
                    Select Question Format *
                  </Text>
                  <SingleSelect
                    value={
                      newQuestionFormatValue
                        ? { value: newQuestionFormatValue, label: newQuestionFormatLabel }
                        : undefined
                    }
                    options={QUESTION_ANSWER_FORMATS_OPTIONS}
                    onChange={handleNewQuestionFormatChange}
                  />
                </TD>
                <TD>
                  {newQuestionFormatValue &&
                    newQuestionFormatValue === QUESTION_ANSWER_FORMATS.SELECT && (
                      <>
                        <label>Enter possible answers *</label>
                        <TextInput
                          css={{ width: "100%" }}
                          tabIndex={0}
                          placeholder={'["value_1", "value_2"]'}
                          value={newQuestionChoices}
                          onChange={handleNewQuestionChoicesChange}
                        />
                      </>
                    )}
                </TD>
              </TR>
            )}
          </TBody>
        </Table>
      </Box>

      <ButtonGroup>
        {newQuestion == null && (
          <Button color="brand" variant="outlined" onClick={handleClickAddNewQuestion}>
            Add Item
          </Button>
        )}
        {newQuestion != null && (
          <Button
            color="success"
            variant="outlined"
            onClick={handleClickApplyNewQuestion}
          >
            Apply
          </Button>
        )}
        {newQuestion != null && (
          <Button variant="outlined" onClick={handleClickCancelNewQuestion}>
            Cancel
          </Button>
        )}
        {newQuestion != null && (
          <Inline>
            {invalidMessage ? (
              <Text as="h6" color="negative">
                {invalidMessage}
              </Text>
            ) : (
              <Text as="h6" color="negative">
                don't forget to apply item changes!
              </Text>
            )}
          </Inline>
        )}
      </ButtonGroup>
    </Stack>
  );
};

QuestionsEditor.displayName = "QuestionsEditor";

type QuestionsViewerProps = {
  questions: QuestionsOrderedMap;
};

export const QuestionsViewer = (props: QuestionsViewerProps) => {
  const { questions } = props;

  return (
    <Stack fill css={{ alignItems: "flex-start", gap: "$2" }}>
      <Text as="h6">
        <Icon icon="info-circle" /> - required questions marked with *
      </Text>
      <Table
        css={{
          [`& ${TH}, & ${TD}`]: {
            backgroundColor: "$brandLighter !important",
          },
          [`& ${THead}, & ${TBody}, & ${TR}, & ${TD}, & ${TH}`]: {
            borderColor: "$white !important",
          },
        }}
      >
        <THead>
          <TR>
            <TH>Question</TH>
            <TH>Answer Format</TH>
          </TR>
        </THead>
        <TBody>
          {questions.toArray().map((question) => {
            const questionId = question.get("id");
            const statement = question.get("statement");
            const answerFormat = question.get("answer_format");
            const choices = question.get("choices") ?? [];
            const choicesString = choices.join(", ");
            const answerFormatString =
              answerFormat === QUESTION_ANSWER_FORMATS.SELECT
                ? `${QUESTION_ANSWER_FORMATS_LABELS[answerFormat]}: ${choicesString}`
                : QUESTION_ANSWER_FORMATS_LABELS[answerFormat];
            const isRequired = question.get("is_required", false);

            return (
              <TR key={questionId}>
                <TD>{isRequired ? `* ${statement}` : statement}</TD>
                <TD>{answerFormatString}</TD>
              </TR>
            );
          })}
        </TBody>
      </Table>
    </Stack>
  );
};

QuestionsViewer.displayName = "QuestionsViewer";

type QuestionsFeedbackViewerProps = {
  questions: QuestionsOrderedMap;
  feedback: QuestionsFeedbackOrderedMap;
};

export const QuestionsFeedbackViewer = (props: QuestionsFeedbackViewerProps) => {
  const { questions, feedback } = props;

  return (
    <Stack fill css={{ alignItems: "flex-start", gap: "$2" }}>
      <Text as="h6">
        <Icon icon="info-circle" /> - Required questions marked with *
      </Text>
      <Table
        css={{
          [`& ${TH}, & ${TD}`]: {
            backgroundColor: "$brandLighter !important",
          },
          [`& ${THead}, & ${TBody}, & ${TR}, & ${TD}, & ${TH}`]: {
            borderColor: "$white !important",
          },
        }}
      >
        <THead>
          <TR>
            <TH>Question</TH>
            <TH>Feedback</TH>
          </TR>
        </THead>
        <TBody>
          {questions.toArray().map((question) => {
            const questionId = question.get("id");
            const questionStatement = question.get("statement");
            const questionFormat = question.get("answer_format");
            const questionAnswer = feedback.get(questionId)?.get("value");
            const isRequired = question.get("is_required", false);

            return (
              <TR key={questionId}>
                <TD>{isRequired ? `* ${questionStatement}` : questionStatement}</TD>
                {questionFormat === QUESTION_ANSWER_FORMATS.CHECKBOX ? (
                  <TD>{questionAnswer == null ? "" : questionAnswer ? "Yes" : "No"}</TD>
                ) : (
                  <TD>{questionAnswer != null ? `${questionAnswer}` : ""}</TD>
                )}
              </TR>
            );
          })}
        </TBody>
      </Table>
    </Stack>
  );
};

QuestionsFeedbackViewer.displayName = "QuestionsFeedbackViewer";
