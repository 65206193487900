import React from "react";
import ContainerSection from "../ratecards/components/ContainerSection";
import FilterModal, { ModalTitle } from "../ratecards/components/FilterModal";
import SortControls from "../ratecards/components/SortControls";

const CreatedOnFilter = ({ createdOnFilter, fromDate, toDate }) => {
  return (
    <FilterModal filterState={createdOnFilter}>
      <div>
        <ContainerSection className="header overlap-t-padding">
          <ModalTitle className="pull-left">Created On</ModalTitle>
          <div className="pull-right">
            <SortControls
              filterState={createdOnFilter}
              sortType="numeric"
              ascText="Sort Oldest First"
              descText="Sort Newest First"
            />
          </div>
          <div className="clearfix" />
        </ContainerSection>
        <ContainerSection>
          <p>Filter by a specific period:</p>
          <div className="pt-range-filter">
            <div className="pt-range-filter__item">
              <p data-testid="from-date">From:</p>
              {fromDate}
            </div>
            <div className="pt-range-filter__item">
              <p data-testid="to-date">To:</p>
              {toDate}
            </div>
          </div>
        </ContainerSection>
      </div>
    </FilterModal>
  );
};

export default CreatedOnFilter;
