import Inline from "./Inline";
import { styled } from "../../stitches.config";

export const ButtonGroup = styled(Inline, {
  gap: "$2",
  flexWrap: "nowrap",
  variants: {
    fill: {
      true: {
        width: "$full",
      },
    },
  },
  defaultVariants: {
    fill: false,
  },
});

ButtonGroup.displayName = "ButtonGroup";

export const ButtonGroupRight = styled(ButtonGroup, {
  justifyContent: "right",
  variants: {
    reverse: {
      true: {
        flexDirection: "row-reverse",
      },
    },
  },
});

ButtonGroupRight.displayName = "ButtonGroupRight";
