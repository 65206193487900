// @flow strict
import * as React from "react";
import graphql from "babel-plugin-relay/macro";
// $FlowFixMe
import { createFragmentContainer } from "react-relay";
// $FlowFixMe
import type { Tag_tag } from "./__generated__/Tag_tag.graphql";

export interface TagItem {
  id: string;
  tagId: number;
  name: string;
  created: string;
  owner: {
    userId: number,
    username: string,
    client: {
      clientId: number,
      name: string,
    },
  };
}

type Props = {
  tag: Tag_tag,
  render: (tag: TagItem) => React.Node,
};

const Tag = (props: Props): React.Node => {
  const { tag, render } = props;
  return render({
    id: tag.id,
    tagId: tag.tagId,
    name: tag.name,
    created: tag.created,
    owner: {
      userId: tag?.owner?.userId || -1,
      username: tag.owner.username,
      client: {
        clientId: tag?.owner?.client?.clientId || -1,
        name: tag?.owner?.client?.name || "",
      },
    },
  });
};

export default createFragmentContainer(Tag, {
  tag: graphql`
    fragment Tag_tag on TagNode {
      id
      tagId
      name
      created
      owner {
        userId
        username
        client {
          clientId
          name
        }
      }
    }
  `,
});
