// @flow
import React, { Component } from "react";
import AsyncSelect from "react-select/lib/Async";
import { reactSelectTheme, reactSelectStyles } from "../../constants/reactSelect";

type Props = {
  locationTitle: (String) => Promise<Object>, // TODO: Verify this is correct type given
  onChange: (Array<{ locationId: String, name: String }>) => void,
  value: String,
  className: String,
  placeholder?: string,
};

let timeout = null;

type locationOption = { value: String, label: String };
type getOptionsCallback = (?Object, ?{ options: Array<locationOption> }) => void;

class RegionLocationSelectNew extends Component<Props, {}> {
  getOptions: (String, getOptionsCallback) => void;
  handleChange: (Array<locationOption>) => void;

  getOptions = (input: String, callback: getOptionsCallback) => {
    if (timeout !== null) {
      clearTimeout(timeout);
    }
    // TODO: Rewrite this better
    var that = this;
    timeout = setTimeout(function () {
      var locationList = [];
      that.props
        .locationTitle(input)
        .then((response) => {
          response.locationTitle.forEach((e) => {
            var optionItem = {};
            optionItem["value"] = e.node.locationId.toString();
            optionItem["label"] = e.node.parent?.name
              ? e.node.name.toString() + ", " + e.node.parent.name.toString()
              : e.node.name.toString();
            locationList.push(optionItem);
          });
          callback(locationList);
        })
        .catch(function (error) {
          callback([]);
        });
    }, 500);
  };

  handleChange = (locations: Array<locationOption>) => {
    this.props.onChange(
      locations.map((location) => ({
        locationId: location.value,
        name: location.label,
      }))
    );
  };

  render() {
    return (
      <AsyncSelect
        name="Location Select"
        placeholder={this.props.placeholder}
        isMulti
        defaultOptions
        cacheOptions={false}
        loadOptions={this.getOptions}
        value={this.props.value}
        className={this.props.className}
        onChange={(value, actionMeta) => {
          this.handleChange(value);
        }}
        styles={reactSelectStyles}
        theme={reactSelectTheme}
      />
    );
  }
}

export default RegionLocationSelectNew;
