// @flow

import React, { Component } from "react";
import { observer } from "mobx-react";
import BatchSearchListHeader from "./BatchSearchListHeader";
import BatchSearchList from "./BatchSearchList";
import type MobXStore from "../../../stores/mobx/MobXStore";
import BatchSearchListEditActions from "./BatchSearchListEditActions";
import ConfirmDeleteBatchSearchesModal from "./ConfirmDeleteBatchSearchesModal";
import ErrorModal from "../../../components/modals/ErrorModal";
import BatchSearchListHelpModal from "./BatchSearchListHelpModal";
import RequestMoreSearchesModal from "../../ratesearch/RequestMoreSearchesModal";
import SessionInfo from "../../../models/SessionInfo";
import BuyMoreSearchesModal from "../../ratesearch/BuyMoreSearchesModal";
import Button from "../../../components/lib/Button";
import Container from "../../../components/lib/Container";
import {
  Card,
  CardHeader,
  CardHeaderSubTitle,
  CardHeaderTitle,
} from "../../../components/lib/Card";
import { InlineElements } from "../../../components/lib/Inline";
import Spacer from "../../../components/lib/Spacer";
import Box from "../../../components/lib/Box";

type Props = {
  store: MobXStore,
  router: Object,
  sessionInfo: SessionInfo,
};

class List extends Component<Props> {
  componentDidMount() {
    const batchSearchListStore = this.props.store.batchSearchPSPListStore;
    batchSearchListStore.router = this.props.router;
    batchSearchListStore.pagination.goFetch();
    // reset to null so some elements visible while editing start hidden
    // this.props.store.batchSearchListStore.isEditing = null;

    // clean rate card details view state
    // this.props.store.batchSearchDetailStore.batchSearchId = null;
    // this.props.store.batchSearchDetailStore.batchSearch = null;
    const rateSearchStore = batchSearchListStore.mobXStore.rateSearchStore;
    const clientPerSearchPricing = this.props.sessionInfo.client.perSearchPricing;
    if (clientPerSearchPricing) {
      rateSearchStore.getSearchesRemaining();
    }
  }

  render() {
    const batchSearchListStore = this.props.store.batchSearchPSPListStore;
    const rateSearchStore = batchSearchListStore.mobXStore.rateSearchStore;
    const clientPerSearchPricing = this.props.sessionInfo.client.perSearchPricing;

    let pspSection = null;
    if (clientPerSearchPricing) {
      let searchesRemainingCount = 0;
      let searchesRemainingLabel = "Searches remaining";

      const resourceAllocations = rateSearchStore.resourceAllocations;
      if (resourceAllocations !== null && resourceAllocations.length > 0) {
        if (resourceAllocations[0].isUnlimited) {
          searchesRemainingCount = "Unlimited";
          searchesRemainingLabel = "Searches";
        } else {
          searchesRemainingCount = resourceAllocations[0].balance;
        }
      }

      pspSection = (
        <div className="countdown">
          <span>
            <strong>{searchesRemainingCount}</strong>{" "}
            <span className="remaining-title">{searchesRemainingLabel}</span>
          </span>
          <Button
            color="brand"
            onClick={rateSearchStore.requestMoreSearchesModal.showModal}
          >
            Request More
          </Button>
        </div>
      );

      if (this.props.sessionInfo.client.pspStoreFlag) {
        pspSection = (
          <div className="countdown">
            <span>
              <strong>{searchesRemainingCount}</strong>{" "}
              <span className="remaining-title">{searchesRemainingLabel}</span>
            </span>
            <Button
              color="brand"
              onClick={rateSearchStore.buyMoreSearchesModal.showModal}
            >
              Buy More
            </Button>
          </div>
        );
      }
    }

    return (
      <Container
        css={{
          paddingTop: "$4",
          paddingLeft: 0,
          paddingRight: 0,
          width: "100%",
        }}
      >
        <Card css={{ zIndex: 2 }}>
          <CardHeader>
            <InlineElements css={{ alignItems: "flex-start" }}>
              <Box>
                <CardHeaderTitle data-testid="batch-search-title">
                  Batch Searches
                </CardHeaderTitle>
                <CardHeaderSubTitle>
                  Run, or re-run, batch searches across many locations, titles, and
                  industries.
                </CardHeaderSubTitle>
              </Box>
              <Spacer />
              {pspSection}
            </InlineElements>
          </CardHeader>
          <BatchSearchListHeader batchSearchListStore={batchSearchListStore} />
          <BatchSearchList store={batchSearchListStore} router={this.props.router} />
        </Card>
        <BatchSearchListEditActions store={batchSearchListStore} />
        <ErrorModal
          modalState={batchSearchListStore.errorModal}
          errorMessage={batchSearchListStore.errorMessage}
        />
        <ConfirmDeleteBatchSearchesModal store={batchSearchListStore} />
        <BatchSearchListHelpModal store={batchSearchListStore} />
        <RequestMoreSearchesModal
          rateSearchStore={rateSearchStore}
          modalState={rateSearchStore.requestMoreSearchesModal}
        />
        <BuyMoreSearchesModal
          rateSearchStore={rateSearchStore}
          modalState={rateSearchStore.buyMoreSearchesModal}
        />
      </Container>
    );
  }
}

export default observer(List);
