// @flow

import { extendObservable, action } from "mobx";
import CurrentUser from "../../../models/User";
import NetworkState from "../../../models/NetworkState";
import MessageState from "../../../models/MessageState";
import type MobXStore from "../MobXStore";

export default class SavedSearchesDetailPTStore {
  store: MobXStore;
  currentUser: CurrentUser;
  showHelpModal: boolean;
  network: NetworkState;
  updateview: boolean;
  showHelp: () => void;
  hideHelp: () => void;
  addToRateCard: () => void;
  closeAddToRateCardModal: () => void;
  addSavedSearchToRateCard: () => void;
  showAddToRateCardModal: boolean;
  messaging: MessageState;
  showHistogram: boolean;
  toggleHistogram: () => void;
  existing: boolean;
  buyRateData: Array<any>;
  useBuyRate: () => void;
  setBuyRateName: () => void;
  showNotes: () => void;
  closeNotes: () => void;
  createAnotherBuyRate: () => void;
  setBuyRateNotes: () => void;
  deleteBuyRate: () => void;
  addPunchouts: () => void;
  setPunchoutData: () => void;
  deletePunchout: () => void;
  tempNotes: String;

  constructor(store: MobXStore) {
    this.store = store;
    this.router = null;
    this.showHelp = action(this.showHelp.bind(this));
    this.hideHelp = action(this.hideHelp.bind(this));
    this.addToRateCard = action(this.addToRateCard.bind(this));
    this.closeAddToRateCardModal = action(this.closeAddToRateCardModal.bind(this));
    this.addSavedSearchToRateCard = action(this.addSavedSearchToRateCard.bind(this));
    this.toggleHistogram = action(this.toggleHistogram.bind(this));
    this.useBuyRate = action(this.useBuyRate.bind(this));
    this.setBuyRateName = action(this.setBuyRateName.bind(this));
    this.showNotes = action(this.showNotes.bind(this));
    this.closeNotes = action(this.closeNotes.bind(this));
    this.createAnotherBuyRate = action(this.createAnotherBuyRate.bind(this));
    this.setBuyRateNotes = action(this.setBuyRateNotes.bind(this));
    this.deleteBuyRate = action(this.deleteBuyRate.bind(this));
    this.addPunchouts = action(this.addPunchouts.bind(this));
    this.setPunchoutData = action(this.setPunchoutData.bind(this));
    this.deletePunchout = action(this.deletePunchout.bind(this));

    extendObservable(this, {
      showHelpModal: false, // Don't display the help modal on page load
      network: new NetworkState(),
      messaging: new MessageState(),
      showAddToRateCardModal: false,
      showHistogram: true,
      tempNotes: "",
      updateview: true,
      buyRateData: [
        { name: "", showNotes: false, notes: "", index: 0, punchoutsList: [] },
      ],
      existing: false,
    });
  }

  showHelp() {
    this.showHelpModal = true;
  }

  hideHelp() {
    this.showHelpModal = false;
  }

  addToRateCard() {
    var listStore = this.store.savedSearchesListPTStore;
    listStore.rateCardsListStore.allowMultipleItemSelection = false;
    listStore.rateCardsListStore.isEditing = true;
    listStore.rateCardsListStore.pagination.goFetch();
    this.showAddToRateCardModal = true;
  }

  /**
   * toggleHistogram
   * @return {void}
   */
  toggleHistogram() {
    this.showHistogram = !this.showHistogram;
  }

  /**
   * addSavedSearchToRateCard - call add saved search data to rate card API
   * @return {void}
   */
  addSavedSearchToRateCard(searchId) {
    this.store.savedSearchesListPTStore.addSavedSearchToRateCard(searchId);
  }

  /**
   * closeAddToRateCardModal - close add to rate card modal
   * @return {void}
   */
  closeAddToRateCardModal() {
    this.showAddToRateCardModal = false;
  }

  setPunchoutData(buyrateIndex, index, value, field) {
    if (value === "" || value.match(/^[0-9]+(\.[0-9]+)?$/)) {
      if (field !== "levelId") {
        var splittedField = field.split(".");
        this.buyRateData[buyrateIndex].punchoutsList[index][splittedField[0]][
          splittedField[1]
        ] = value;
      } else {
        this.buyRateData[buyrateIndex].punchoutsList[index].levelId = value;
      }
    } else {
      return;
    }
    this.updateview = !this.updateview;
  }

  onFocusOutFn(e, buyrateIndex, index, field) {
    var splittedField = field.split(".");
    var val =
      this.buyRateData[buyrateIndex].punchoutsList[index][splittedField[0]][
        splittedField[1]
      ];
    if (!val.match(/^[0-9]+(\.[0-9]+)?$/)) {
      e.target.parentElement.classList.add("has-error");
    } else {
      e.target.parentElement.classList.remove("has-error");
    }
  }

  deletePunchout(buyrateIndex, index) {
    if (this.buyRateData[buyrateIndex].punchoutsList.length <= 1) {
      return;
    } else {
      this.buyRateData[buyrateIndex].punchoutsList.splice(index, 1);
    }
    this.updateview = !this.updateview;
  }

  useBuyRate(data) {
    this.existing = true;
    this.buyRateData = data.map((buyrate, index) => {
      var newpunch = [];

      for (var i = 0; i < buyrate.punchouts.length; i++) {
        newpunch.push({
          billRate: {
            min: buyrate.punchouts[i].billRateMin,
            max: buyrate.punchouts[i].billRateMax,
            mid: buyrate.punchouts[i].billRateMid,
            avg: buyrate.punchouts[i].billRateAvg,
          },
          markupAmt: {
            min: buyrate.punchouts[i].markupAmtMin,
            max: buyrate.punchouts[i].markupAmtMax,
            mid: buyrate.punchouts[i].markupAmtMid,
            avg: buyrate.punchouts[i].markupAmtAvg,
          },
          markupPct: {
            min: buyrate.punchouts[i].markupPctMin,
            max: buyrate.punchouts[i].markupPctMax,
            mid: buyrate.punchouts[i].markupPctMid,
            avg: buyrate.punchouts[i].markupPctAvg,
          },
          payRate: {
            min: buyrate.punchouts[i].payRateMin,
            max: buyrate.punchouts[i].payRateMax,
            mid: buyrate.punchouts[i].payRateMid,
            avg: buyrate.punchouts[i].payRateAvg,
          },
          levelId: buyrate.punchouts[i].level.legacyId,
        });
      }

      return {
        name: buyrate.name,
        showNotes: false,
        notes: buyrate.notes,
        index: index,
        punchoutsList: newpunch,
      };
    });
    this.updateview = !this.updateview;
  }

  errorClass(index) {
    return this.buyRateData[index].name === "" ? "has-error" : "";
  }

  setBuyRateName(index, value) {
    if (this.buyRateData.length === 0) {
      this.buyRateData = [
        { name: "", showNotes: false, notes: "", index: 0, punchoutsList: [] },
      ];
    }
    this.buyRateData[index].name = value;
    this.updateview = !this.updateview;
  }

  /**
   * showNotes - show notes popup
   * @return {void}
   */
  showNotes(index) {
    this.buyRateData[index].showNotes = true;
    this.updateview = !this.updateview;
  }
  /**
   * closeNotes - close notes popup
   * @return {void}
   */
  closeNotes(index) {
    this.tempNotes = "";
    this.buyRateData[index].showNotes = false;
    this.updateview = !this.updateview;
  }

  deleteBuyRate(index) {
    this.buyRateData.splice(index, 1);
    this.updateview = !this.updateview;
  }

  addPunchouts(index) {
    var existingLevel = this.buyRateData[index].punchoutsList.length;
    var punch = this.createPunchoutList(existingLevel + 1);
    for (var i = 0; i < punch.length; i++) {
      this.buyRateData[index].punchoutsList.push(punch[i]);
    }
    this.updateview = !this.updateview;
  }

  createAnotherBuyRate(dataToDisplay) {
    var punch = this.createPunchoutList(1);
    var existingLength = this.buyRateData.length;
    var date = new Date();
    date = date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
    var newBuyRate = {
      name: dataToDisplay.job.jobLabel + " " + date,
      showNotes: false,
      notes: "",
      index: existingLength + 1,
      punchoutsList: punch,
    };
    this.buyRateData.push(newBuyRate);
    this.updateview = !this.updateview;
  }

  createPunchoutList(levelId) {
    var punchoutsList = [];
    var billRate = {};
    var markupPct = {};
    var markupAmt = {};
    var payRate = {};
    billRate.min = "";
    billRate.max = "";
    billRate.mid = "";
    billRate.avg = "";
    markupPct.min = "";
    markupPct.max = "";
    markupPct.mid = "";
    markupPct.avg = "";
    markupAmt.min = "";
    markupAmt.max = "";
    markupAmt.mid = "";
    markupAmt.avg = "";
    payRate.min = "";
    payRate.max = "";
    payRate.mid = "";
    payRate.avg = "";
    punchoutsList.push({
      billRate,
      markupAmt,
      markupPct,
      payRate,
      levelId: levelId,
    });
    return punchoutsList;
  }

  setBuyRateNotes(e) {
    this.tempNotes = e.target.value;
    this.updateview = !this.updateview;
  }

  toJSON() {
    return {};
  }
}
