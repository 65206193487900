// @flow
import React, { Component } from "react";
import chroma from "chroma-js";
import { Map } from "react-leaflet";
import Choropleth from "react-leaflet-choropleth";
import { GoogleLayer } from "react-leaflet-google";

// const { BaseLayer } = LayersControl;
const key = "AIzaSyCqazdE92YXN2R-X96Nawq-WGGHwy362dQ";
const road = "ROADMAP";
// const terrain = "TERRAIN";
// const satellite = "SATELLITE";
// const hydrid = "HYBRID";

const COLORS = ["#fff", "#2875F0"];
const MAX_SCALE_STEPS = 8;

export type LatLng = {
  lat: number,
  lng: number,
};

export type Feature = {
  id: any,
  type: string,
  properties: Object,
  geometry: Object,
};

type MapEvent = {
  target: {
    setStyle: (Object) => void,
    feature: Feature,
  },
};

type Props = {
  defaultZoom: number,
  defaultCenter: LatLng,
  geojson: ?Object,
};

// GOOGLE MAPS DOCS EXAMPLE
// const MyMapComponent = withScriptjs(withGoogleMap((props) =>
//   <GoogleMap
//     defaultZoom={8}
//     defaultCenter={{ lat: -34.397, lng: 150.644 }}
//   >
//     {props.isMarkerShown && <Marker position={{ lat: -34.397, lng: 150.644 }} />}
//   </GoogleMap>
// ));
//
// <MyMapComponent
//   isMarkerShown
//   googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
//   loadingElement={<div style={{ height: `100%` }} />}
//   containerElement={<div style={{ height: `400px` }} />}
//   mapElement={<div style={{ height: `100%` }} />}
// />

// export default MyMapComponent;

// GOOGLE MAPS
// class Map extends Component {
//   props: Props;
//
//   static defaultProps = {
//     googleMapURL: "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places",
//     loadingElement: <div style={{ height: `100%` }} />,
//     containingElement: <div style={{ height: `100%` }} />,
//     mapElement: <div style={{ height: `100%` }} />
//   };
//
//   render() {
//     const props = this.props;
//
//     return (
//       <GoogleMap defaultZoom={8} defaultCenter={{ lat: -34.397, lng: 150.644 }}>
//         {props.isMarkerShown && <Marker position={{ lat: -34.397, lng: 150.644 }} />}
//       </GoogleMap>
//     );
//   }
// }

// export default withScriptjs(withGoogleMap(Map));

// REACT LEAFLET
const featureStyle = {
  weight: 1.5,
  opacity: 1,
  color: "#2875F0",
  dashArray: "4",
  fillOpacity: 0.9,
};

const mapStyle = [
  {
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [{ visibility: "on" }, { color: "#fcfcfc" }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ visibility: "on" }, { color: "#bfddef" }],
  },
];

type State = {
  state: ?string,
  jobCount: number,
};

class LeafletMap extends Component<Props, State> {
  geojson: ?{
    resetStyle: (Object) => void,
  };

  static defaultProps = {
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containingElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      state: null,
      jobCount: 0,
    };
  }

  highlightFeature = (e: MapEvent) => {
    const layer = e.target;

    layer.setStyle({
      weight: 3,
      dashArray: "",
      fillOpacity: 0.95,
    });

    this.setState({
      state: layer.feature.properties.name,
      jobCount: layer.feature.properties.jobs,
    });
  };

  resetHighlight = (e: MapEvent) => {
    const layer = e.target;

    layer.setStyle({
      weight: featureStyle.weight,
      dashArray: featureStyle.dashArray,
      fillOpacity: featureStyle.fillOpacity,
    });

    this.setState({ state: null, jobCount: 0 });
  };

  getScale = (data: number[], steps: number) => {
    const limits = chroma.limits(data, "e", steps - 1);
    const colors = chroma.scale(COLORS).colors(steps);

    return limits.map((limit, index) => {
      const currLimit = Math.floor(limit);
      let limitRange = "0";

      if (index > 0 && index < limits.length - 1) {
        const prevLimit = Math.floor(limits[index - 1]);
        if (prevLimit === currLimit) {
          limitRange = "" + currLimit;
        } else {
          limitRange = (
            <span>
              {prevLimit + 1}&ndash;{currLimit}
            </span>
          );
        }
      } else if (index === limits.length - 1) {
        const prevLimit = Math.floor(limits[index - 1]);
        limitRange = `${prevLimit + 1}+`;
      }

      return { color: colors[index], limit: limitRange, key: index };
    });
  };

  renderNoData = () => {
    return (
      <div className="flex center-items" style={{ width: "100%", height: "100%" }}>
        <div className="text-x-small">No data to display</div>
      </div>
    );
  };

  render() {
    const props = this.props;
    if (!props.geojson) {
      return this.renderNoData();
    }

    // calculate color scale steps
    const data = props.geojson.features.map((feature) => feature.properties.jobs);
    data.sort((a, b) => a - b); // a - b for ascending sort, b - a for descending
    const minValue = data[0];
    const maxValue = data[data.length - 1];
    const delta = maxValue - minValue;
    let scale_steps = delta < MAX_SCALE_STEPS ? delta : MAX_SCALE_STEPS;
    if (scale_steps === 1) scale_steps = 2;

    return (
      <div className="leaflet-map-container">
        <Map
          center={props.defaultCenter}
          zoom={props.defaultZoom}
          style={{ height: "425px" }}
        >
          {/*<TileLayer*/}
          {/*attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"*/}
          {/*url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"*/}
          {/*/>*/}
          {/*<LayersControl position="topright">*/}
          {/*<BaseLayer name="OpenStreetMap.Mapnik">*/}
          {/*<TileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />*/}
          {/*</BaseLayer>*/}
          {/*</LayersControl>*/}
          <GoogleLayer googlekey={key} maptype={road} styles={mapStyle} />
          <Choropleth
            data={props.geojson}
            valueProperty={(feature) => feature.properties.jobs}
            // visible={feature => feature.id !== active.id}
            scale={COLORS}
            steps={scale_steps}
            mode="e"
            style={featureStyle}
            onEachFeature={(feature, layer) => {
              // layer.bindPopup(feature.properties.name + feature.properties.jobs);
              layer.on({
                mouseover: this.highlightFeature,
                mouseout: this.resetHighlight,
                // click: zoomToFeature
              });
            }}
            ref={(el) => (this.geojson = el ? el : null)}
          />
        </Map>
        <div className="map-tooltip">
          <h5 style={{ color: "#666", marginBottom: 4 }}>Job Count</h5>
          {!Boolean(this.state.state) && (
            <span className="text-x-small no-margin">Hover over a state...</span>
          )}
          {Boolean(this.state.state) && (
            <span className="text-x-small no-margin">
              <strong>{this.state.state}</strong>
              <br />
              {this.state.jobCount} Job{this.state.jobCount === 1 ? "" : "s"}
            </span>
          )}
        </div>
        {scale_steps > 0 && (
          <div className="color-scale-container">
            <h5 style={{ padding: "4px 6px" }}>Legend</h5>
            {this.getScale(data, scale_steps).map((item) => {
              return (
                <div
                  key={item.key}
                  className="color-scale-segment"
                  style={{ borderBottomColor: item.color }}
                >
                  {item.limit}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export default LeafletMap;
