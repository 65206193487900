import React, { useCallback, useEffect, useState } from "react";
import { fromJS } from "immutable";
import { useRecoilValue } from "recoil";

import { useVal5KPublicContext } from "../../context/Val5KPublicContext";
import Box from "../../../../components/lib/Box";
import { emptyMap } from "../../../../constants";
import Inline from "../../../../components/lib/Inline";
import { decimalFormatter } from "../../constants";
// @ts-expect-error
import { logAsyncOperationError } from "../../../../utils/logging";
import {
  surveyHistoryTableGlobalState,
  type SurveyHistoryTableDataStateObject,
} from "../../globalState";

import type { RewardsSummaryDataMap } from "../../types";
import type { FetchAPIResponse } from "../../../../types/fetch";

const TotalReward = () => {
  const { auth, fetchArgusFilteringAPI, showModalError } = useVal5KPublicContext();

  // state

  const surveyHistoryTableState = useRecoilValue<SurveyHistoryTableDataStateObject>(
    surveyHistoryTableGlobalState
  );
  const { filtersQuery } = surveyHistoryTableState;

  const [reward, setReward] = useState<RewardsSummaryDataMap>(
    emptyMap as unknown as RewardsSummaryDataMap
  );

  // data fetch funcs

  const fetchSurveyHistoryTotalRewardData = useCallback(async () => {
    try {
      const response: FetchAPIResponse<RewardsSummaryDataMap> =
        await fetchArgusFilteringAPI(`surveys/history/rewards-summary-values/filtered/`, {
          params: { _email: auth.email },
          data: filtersQuery.toJS(),
        });

      const transFormedData = fromJS(response.data);
      setReward(transFormedData);
    } catch (err: any) {
      logAsyncOperationError("fetchSurveyHistoryTotalRewardData", err);
      showModalError(
        "Error occurred while loading reward data. Please, try again later."
      );
      throw err;
    }
  }, [auth.email, fetchArgusFilteringAPI, showModalError, filtersQuery]);

  // trigger fetch

  useEffect(() => {
    fetchSurveyHistoryTotalRewardData();
  }, [filtersQuery, fetchSurveyHistoryTotalRewardData]);

  // render

  const totalReward = reward.get("total_reward_total");
  const totalRowsNumber = reward.get("total_reward_count");
  const totalRowsString = totalRowsNumber
    ? ` (${totalRowsNumber} row${totalRowsNumber > 1 ? "s" : ""})`
    : "";

  return (
    <Inline>
      <label>Total reward{totalRowsString}: </label>
      <Box
        css={{
          padding: "$2",
          minWidth: "$28",
          backgroundColor: "$brand",
          color: "$textLight",
          textAlign: "center",
        }}
      >
        {totalReward ? decimalFormatter(totalReward, false, "$") : "no reward"}
      </Box>
    </Inline>
  );
};

TotalReward.displayName = "TotalReward";

export default TotalReward;
