import React, { useCallback, useState } from "react";
// @ts-expect-error
import numeral from "numeral";

import Stack from "../../../components/lib/Stack";
import Button from "../../../components/lib/Button";
import Text from "../../../components/lib/Text";
import { RadioGroup, RadioGroupItem } from "../../../components/lib/RadioGroup";
import { ButtonGroupRight } from "../../../components/lib/ButtonGroup";
import { RATE_TYPES, RATE_TYPES_LABELS } from "../types";

import type { JobDataMap, RATE_TYPES_TYPE } from "../types";
import { useVal5KAdminContext } from "../context/Val5KAdminContext";

const WORKING_HOURS_FORMAT = "0,0.[0]";

type RateTypeSelectorProps = {
  rateType: RATE_TYPES_TYPE;
  job: JobDataMap;
  onChange: (rateType: RATE_TYPES_TYPE) => void;
};

const RateTypeSelector = (props: RateTypeSelectorProps) => {
  const { job, rateType: initRateType, onChange } = props;
  const { showConfirmationModal, closeConfirmationModal } = useVal5KAdminContext();

  // state

  const [rateType, setRateType] = useState<RATE_TYPES_TYPE>(initRateType);

  // handlers

  const handleChange = useCallback(
    (rateType: RATE_TYPES_TYPE) => {
      setRateType(rateType);
      onChange(rateType);
      closeConfirmationModal();
    },
    [onChange, closeConfirmationModal]
  );

  const handleShowDisplayRateTypeSelector = useCallback(() => {
    const dailyMultiplier = job.get("daily_multiplier");
    const weeklyMultiplier = job.get("weekly_multiplier");
    const monthlyMultiplier = job.get("monthly_multiplier");

    const header = "Change Values Representation";
    const message = (
      <Stack css={{ alignItems: "flex-start" }}>
        <Text>Select values format:</Text>
        <RadioGroup value={rateType} onValueChange={handleChange}>
          <Stack css={{ gap: "$2", alignItems: "flex-start" }}>
            <RadioGroupItem value={RATE_TYPES.HOURLY}>
              {RATE_TYPES_LABELS[RATE_TYPES.HOURLY]}
            </RadioGroupItem>
            <RadioGroupItem value={RATE_TYPES.DAILY}>
              {RATE_TYPES_LABELS[RATE_TYPES.DAILY]}&nbsp;
              <small>
                ({numeral(dailyMultiplier).format(WORKING_HOURS_FORMAT)} hours per day)
              </small>
            </RadioGroupItem>
            <RadioGroupItem value={RATE_TYPES.WEEKLY}>
              {RATE_TYPES_LABELS[RATE_TYPES.WEEKLY]}&nbsp;
              {RATE_TYPES_LABELS[RATE_TYPES.WEEKLY]}&nbsp;
              <small>
                ({numeral(weeklyMultiplier).format(WORKING_HOURS_FORMAT)} hours per week)
              </small>
            </RadioGroupItem>
            <RadioGroupItem value={RATE_TYPES.MONTHLY}>
              {RATE_TYPES_LABELS[RATE_TYPES.MONTHLY]}&nbsp;
              <small>
                ({numeral(monthlyMultiplier).format(WORKING_HOURS_FORMAT)} hours per
                month)
              </small>
            </RadioGroupItem>
          </Stack>
        </RadioGroup>
      </Stack>
    );
    const footer = (
      <ButtonGroupRight fill>
        <Button size="large" onClick={closeConfirmationModal}>
          Cancel
        </Button>
      </ButtonGroupRight>
    );

    showConfirmationModal(message, header, footer);
  }, [job, rateType, handleChange, showConfirmationModal, closeConfirmationModal]);

  return (
    <Button color="brand" variant="outlined" onClick={handleShowDisplayRateTypeSelector}>
      View As Hourly / Daily ...
    </Button>
  );
};
RateTypeSelector.displayName = "RateTypeSelector";

export default RateTypeSelector;
