import * as PercentField from "./PercentField";
import * as ScriptedPercentage from "./ScriptedPercentage";
import * as TaxPercentField from "./TaxPercentField";
import * as FactorMultiple from "./FactorMultiple";

export default {
  percentage: PercentField,
  scriptedPercentage: ScriptedPercentage,
  percentageTax: TaxPercentField,
  factorMultiple: FactorMultiple,
};
