/**
 * @generated SignedSource<<aecce6c603baab007b2133cce0f4717c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LocationsFieldQuery$variables = {
  countryIds?: ReadonlyArray<number | null> | null;
  searchParam: string;
};
export type LocationsFieldQuery$data = {
  readonly viewer: {
    readonly locations: ReadonlyArray<{
      readonly countryId: number | null;
      readonly fullSubtitle: string | null;
      readonly fullTitle: string;
      readonly locationId: string;
    } | null> | null;
  } | null;
};
export type LocationsFieldQuery = {
  response: LocationsFieldQuery$data;
  variables: LocationsFieldQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "countryIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchParam"
},
v2 = [
  {
    "kind": "Variable",
    "name": "countryIds",
    "variableName": "countryIds"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "searchParam"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullTitle",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullSubtitle",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locationId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LocationsFieldQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "LocationListNode",
            "kind": "LinkedField",
            "name": "locations",
            "plural": true,
            "selections": [
              {
                "kind": "RequiredField",
                "field": (v3/*: any*/),
                "action": "NONE",
                "path": "viewer.locations.fullTitle"
              },
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "RequiredField",
                "field": (v6/*: any*/),
                "action": "NONE",
                "path": "viewer.locations.locationId"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "LocationsFieldQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "LocationListNode",
            "kind": "LinkedField",
            "name": "locations",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a4da72f583ccc26980cc9e2ff595d3bc",
    "id": null,
    "metadata": {},
    "name": "LocationsFieldQuery",
    "operationKind": "query",
    "text": "query LocationsFieldQuery(\n  $searchParam: String!\n  $countryIds: [Int]\n) {\n  viewer {\n    locations(search: $searchParam, countryIds: $countryIds) {\n      fullTitle\n      fullSubtitle\n      countryId\n      locationId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "37d16d2c69dd8e2f6c384b75b1ac2883";

export default node;
