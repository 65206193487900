import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import IndustriesStore from "../../stores/mobx/IndustriesStore";
import { reactSelectStyles, reactSelectTheme } from "../../constants/reactSelect";

const iconStyles = {
  width: "25px",
  textAlign: "left",
  fontSize: "1.2em",
};

const IndustrySelect = (props) => {
  const {
    store,
    value,
    onChange,
    name,
    placeholder,
    disabled,
    multi,
    searchable,
    className,
    styles,
    theme,
  } = props;
  const options = store.getAllIndustries();
  const formatOptionLabel = (option) => (
    <span>
      <FontAwesomeIcon
        icon={option["faIcon"] || store.getIndustryById(option["id"])["faIcon"]}
        style={iconStyles}
      />
      &nbsp;
      {option["title"]}
    </span>
  );

  return (
    <Select
      className={className}
      options={options}
      value={value}
      onChange={onChange}
      name={name}
      placeholder={placeholder}
      isDisabled={disabled}
      isMulti={multi}
      isSearchable={searchable}
      styles={styles}
      theme={theme}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.title}
      formatOptionLabel={formatOptionLabel}
    />
  );
};
IndustrySelect.propTypes = {
  store: PropTypes.instanceOf(IndustriesStore).isRequired,
  value: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    label: PropTypes.node,
  }),
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  multi: PropTypes.bool,
  searchable: PropTypes.bool,
  className: PropTypes.string,
  styles: PropTypes.object,
  theme: PropTypes.func,
};
IndustrySelect.defaultProps = {
  placeholder: "Select industry...",
  disabled: false,
  multi: false,
  searchable: true,
  styles: reactSelectStyles,
  theme: reactSelectTheme,
};

export default IndustrySelect;
