// @flow
import React, { Component } from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LibraryTitleRequestList from "./LibraryTitleRequestList";
import type MobXStore from "../../stores/mobx/MobXStore";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../components/lib/DropdownMenu";
import Button from "../../components/lib/Button";
import Inline from "../../components/lib/Inline";

import { styled } from "../../../src/stitches.config";

const EllipsisButton = styled(Button, {
  border: "none !important",
  fontSize: "30px !important",
  color: "#888888 !important",
  backgroundColor: "transparent !important",
  "&:hover": {
    backgroundColor: "transparent !important",
  },
  "&:active": {
    backgroundColor: "transparent !important",
  },
  "&:focus": {
    backgroundColor: "transparent !important",
  },
});

type Props = {
  store: MobXStore,
  router: {
    push: (string) => void,
  },
};

class LibraryTitleRequests extends Component<Props> {
  handleOnRequestJobTitle: () => void;

  constructor(props: Props) {
    super(props);

    this.handleOnRequestJobTitle = this.handleOnRequestJobTitle.bind(this);
  }

  handleOnRequestJobTitle() {
    this.props.router.push(`/job-library/title-requests/create-title-request/`);
  }

  componentDidMount() {
    this.props.store.jobLibraryTitleRequestListStore.pagination.goFetch();
  }

  render() {
    return (
      <div className="cjl-centered-view-container">
        <div className="cjl-centered-view cjl-library-detail">
          <Inline
            css={{ justifyContent: "flex-end", position: "absolute", right: "40px" }}
          >
            <DropdownMenu id="dropdown-custom-menu">
              <DropdownMenuTrigger asChild>
                <EllipsisButton icon="ellipsis-v" />
              </DropdownMenuTrigger>
              <DropdownMenuContent side="bottom">
                <DropdownMenuItem eventKey="3" onSelect={this.handleOnRequestJobTitle}>
                  <span className="cjl-detail-menu-item">
                    <FontAwesomeIcon
                      icon="times"
                      fixedWidth
                      className="icon cjl-detail-menu-item"
                      style={{ visibility: "hidden" }}
                    />
                    Request Job Title
                  </span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </Inline>

          <header className="cjl-detail-name">Job Title Requests</header>
          <LibraryTitleRequestList
            store={this.props.store.jobLibraryTitleRequestListStore}
          />
        </div>
      </div>
    );
  }
}

export default observer(LibraryTitleRequests);
