// @flow
import { action } from "mobx";
import moment from "moment";

export default class TagList {
  tagId: number;
  name: string;

  constructor(store, object) {
    this.store = store;
    this.tag = null;
    this.tagId = 0;
    this.name = null;
    this.owner = null;
    this.createdDate = null;
    this.createdDisplay = null;
    this.shared = false;
    this.sharedWith = [];
    this.viewState = {
      selected: false,
      editing: false,
      expanded: false,
    };

    if (object) {
      this.tag = object;
      this.tagId = object.tagId || 0;
      this.name = object.name || null;
      this.shared = object.sharedWith && object.sharedWith.length > 0;
      if (object.owner) {
        const owner = object.owner;
        this.owner = {
          fullname: `${owner.firstName} ${owner.lastName}` || null,
          clientId: owner.userId || null,
          email: owner.email || null,
        };
      }
      this.createdDate = moment(object.created);
      this.createdDisplay = `Created ${this.createdDate.format("MMMM D, YYYY")}`;
      this.locationCount = object.locationCount;
      this.searchCount = object.searchCount;
      this.sharedWith = object.sharedWith || [];
    }

    this.toggleEdit = action(this.toggleEdit.bind(this));
    this.toggleSelected = action(this.toggleSelected.bind(this));
    this.destroy = action(this.destroy.bind(this));
  }

  toggleEdit() {
    const viewState = this.store.tagsViewState.get(this.tagId);
    viewState.editing = !viewState.editing;
  }

  toggleSelected(e, ignore1 = null, ignore2 = null, setValue = null) {
    const viewState = this.store.tagsViewState.get(this.tagId);

    if (setValue !== null) {
      viewState.selected = setValue;
      return;
    } else {
      viewState.selected = !viewState.selected;

      if (!viewState.selected && this.store.allSelected) {
        this.store.allSelected = false;
      }
    }

    if (viewState.selected === false) {
      this.store.unSelectedTag.push(this.tag.tagId.toString());
    } else {
      for (var i = 0; i < this.store.unSelectedTag.length; i++) {
        if (this.store.unSelectedTag[i] === this.tagId) {
          this.store.unSelectedTag.splice(i, 1);
        }
      }
    }

    if (!this.store.allowMultipleItemSelection) {
      // deselect all other rate cards
      this.store.tagsViewState.forEach((viewState) => {
        if (this.viewState === viewState) return;

        viewState.selected = false;
      });
    }

    this.store.SingleSelectedTag(this.tag);
  }

  destroy() {
    this.store.tags.remove(this);
  }

  toJS() {
    return {
      tagId: this.tagId,
      name: this.name,
    };
  }

  static fromJS(store, object) {
    return new TagList(store, object);
  }
}
