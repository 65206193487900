import PropTypes from "prop-types";
import React from "react";
import { observer } from "mobx-react";
import DetailsTableRow from "./DetailsTableRow";

const ResultLevelCardMarkupDetailsTable = observer((props) => {
  let store = props.store;
  let resultLevel = props.resultLevel;
  let deltaObject = resultLevel.change;

  return (
    <table className="table level-data sub-table no-margin">
      <tbody>
        <DetailsTableRow
          store={store}
          hidden={store.hideMax}
          text="Max:"
          suffix="%"
          value={resultLevel.markupPctMax}
          change={deltaObject.markupPctMaxChange}
          changeIcon={deltaObject.markupPctMaxChangeIcon}
          changeType={deltaObject.markupPctMaxChangeType}
        />

        <DetailsTableRow
          store={store}
          hidden={store.hideMedian}
          text="Median:"
          suffix="%"
          value={resultLevel.markupPctMid}
          change={deltaObject.markupPctMidChange}
          changeIcon={deltaObject.markupPctMidChangeIcon}
          changeType={deltaObject.markupPctMidChangeType}
        />

        <DetailsTableRow
          store={store}
          hidden={store.hideAverage}
          text="Average:"
          suffix="%"
          value={resultLevel.markupPctAvg}
          change={deltaObject.markupPctAvgChange}
          changeIcon={deltaObject.markupPctAvgChangeIcon}
          changeType={deltaObject.markupPctAvgChangeType}
        />

        <DetailsTableRow
          store={store}
          hidden={store.hideMin}
          text="Min:"
          suffix="%"
          value={resultLevel.markupPctMin}
          change={deltaObject.markupPctMinChange}
          changeIcon={deltaObject.markupPctMinChangeIcon}
          changeType={deltaObject.markupPctMinChangeType}
        />
      </tbody>
    </table>
  );
});

ResultLevelCardMarkupDetailsTable.propTypes = {
  store: PropTypes.object.isRequired,
  resultLevel: PropTypes.object.isRequired,
};

export default ResultLevelCardMarkupDetailsTable;
