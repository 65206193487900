// @ts-ignore-error
import { getCookie } from "../../utils/cookie";
import { baseUrlPath } from "./constants";

import type { AuthDataObject } from "./types";

const authCookieKey = "rc-validate-auth";
const authCookiePath = baseUrlPath;
const authCookiesMaxAge = 7 * 24 * 60 * 60; // in seconds (7 days, 24 hours, 60 minutes, 60 seconds)

type CookieParamsType = {
  path: string;
  maxAge?: number;
};

function deleteFromCookies(key: string, params: CookieParamsType) {
  document.cookie = `${key}=; path=${params.path}; max-age=0`;
}

function setToCookies(key: string, data: AuthDataObject, params: CookieParamsType) {
  document.cookie = `${key}=${JSON.stringify(data)}; path=${params.path}; max-age=${
    params.maxAge
  }`;
}

export function authorizeUser(data: AuthDataObject) {
  const { firstName, lastName, email, acceptAgreement } = data;

  const cookieParams: CookieParamsType = {
    path: authCookiePath,
    maxAge: authCookiesMaxAge,
  };

  if (firstName && lastName && email && acceptAgreement) {
    setToCookies(authCookieKey, data, cookieParams);
  }
}

export function unauthorizeUser() {
  const cookieParams: CookieParamsType = {
    path: authCookiePath,
  };

  deleteFromCookies(authCookieKey, cookieParams);
}

export function getAuthCookie(): AuthDataObject | null {
  const authData: string | null = getCookie(authCookieKey);
  const authDataMap = authData ? JSON.parse(authData) : null;

  if (authDataMap && Object.keys(authDataMap).length === 4) {
    return authDataMap;
  }

  return null;
}
