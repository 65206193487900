// @flow

import React, { Component } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import RawJobTitle from "../../../models/RawJobTitle";
import LoadingIndicator from "../../shared/LoadingIndicator";
import Button from "../../../components/lib/Button";
import Box from "../../../components/lib/Box";

type Props = {
  rawJobTitle: RawJobTitle,
};

class RawJobTitleListItem extends Component<Props> {
  handleOnSelect: () => void;

  constructor(props: Props) {
    super(props);

    this.handleOnSelect = this.handleOnSelect.bind(this);
  }

  handleOnSelect() {
    const jobTitle = this.props.rawJobTitle;
    jobTitle.toggleSelected(!jobTitle.viewState.selected);
  }

  handleOnToggleExpand = (event: MouseEvent) => {
    event.stopPropagation();

    this.props.rawJobTitle.toggleExpanded();
  };

  render() {
    const props = this.props;
    const jobTitle = props.rawJobTitle;

    const { expanded, selected } = jobTitle.viewState;

    // let editingSection = (
    //     <CheckBox
    //       type="checkbox"
    //       name="list-item-select"
    //       value={jobTitle.id}
    //       selected={selected}
    //       style={{ width: 34 }}
    //       onClick={this.handleOnSelect}
    //     />
    //   );

    let viewSection = (
      <div
        className="rc-center"
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        }}
      >
        {selected ? (
          <Button css={{ minWidth: 80 }} onClick={this.handleOnSelect}>
            Deselect
          </Button>
        ) : (
          <Button color="brand" css={{ minWidth: 80 }} onClick={this.handleOnSelect}>
            Select
          </Button>
        )}
      </div>
    );

    const expandSectionClasses = classNames("search-peek", {
      "search-peek-expand": expanded,
      "search-peek-collapse": !expanded,
      notransition: false,
    });

    const hasDescription =
      !jobTitle.network.loading && jobTitle.description && jobTitle.description !== "";

    return (
      <div className="search-list-item-container">
        <Box
          data-comp-id="list-item"
          css={{
            ".pt-ui &[data-comp-id='list-item']": {
              backgroundColor: "white",
              "& .search-label": {
                color: "$brand",
              },
              "&:hover": {
                backgroundColor: "$brandLightest",
                "& .search-label, & .pt-ico-moon": {
                  color: "$brand",
                },
              },
              "&.selected": {
                backgroundColor: "$brandLightest",
                "& .search-label, & .pt-ico-moon": {
                  color: "$brand",
                },
              },
            },
          }}
          className={classNames("search-list-item", { selected: selected })}
          onClick={this.handleOnSelect}
        >
          <div className="search-list-item-left ellipsis">
            {/*{editingSection}*/}

            <Box
              data-comp-id="ico-moon"
              className="batch-search search-expand-state"
              css={{
                ".pt-ui &[data-comp-id='ico-moon']": {
                  "& .pt-ico-moon": {
                    color: "$brand",
                  },
                  "&:hover .pt-ico-moon": {
                    color: "$accent",
                  },
                },
              }}
              onClick={this.handleOnToggleExpand}
            >
              <span
                className={classNames("pt-ico-moon", {
                  expanded: expanded,
                })}
              >
                
              </span>
            </Box>

            <div className="ellipsis">
              <div className="search-label ellipsis">
                <span className="ellipsis" data-testid="job-titles-search-list">
                  {jobTitle.title}
                </span>
              </div>
              <div className="search-title ellipsis">
                <span className="ellipsis">
                  {jobTitle.libraryInfo && (
                    <span>{jobTitle.libraryInfo.mappedJobTitleName} </span>
                  )}
                  {!jobTitle.libraryInfo && <span>{jobTitle.title} </span>}
                </span>
              </div>
            </div>
          </div>
          <div className="search-list-item-right">
            <div className="search-info ellipsis">
              <header>Category</header>
              <div
                className="ellipsis"
                style={{ maxWidth: 200 }}
                title={jobTitle.libraryInfo.category}
              >
                {jobTitle.libraryInfo.category}
              </div>
            </div>
            <div className="search-info" style={{ minWidth: 170 }}>
              <header>Created</header>
              {jobTitle.libraryInfo.createdDisplay}
            </div>
            <div className="search-info" style={{ minWidth: 80 }}>
              <header>ID</header>
              {jobTitle.id}
            </div>
            {viewSection}
          </div>
        </Box>
        <div className={expandSectionClasses}>
          <div className="search-peek-body">
            {jobTitle.network.loading && <LoadingIndicator />}
            {hasDescription && (
              <pre className="text-wrap batch-search-job-description">
                {jobTitle.description}
              </pre>
            )}
            {!hasDescription && <p className="text-muted">-- No Description --</p>}
          </div>
        </div>
      </div>
    );
  }
}

export default observer(RawJobTitleListItem);
