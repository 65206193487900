/**
 * @generated SignedSource<<9da9c049652f4a33a4783a8d7d600d8b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type RsLocationSearchDialogCityQuery$variables = {
  id: string;
};
export type RsLocationSearchDialogCityQuery$data = {
  readonly viewer: {
    readonly cities: {
      readonly results: ReadonlyArray<{
        readonly id: string | null;
        readonly title: string | null;
      } | null> | null;
    } | null;
  } | null;
};
export type RsLocationSearchDialogCityQuery = {
  response: RsLocationSearchDialogCityQuery$data;
  variables: RsLocationSearchDialogCityQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "concreteType": "ResultChildrenNode",
        "kind": "LinkedField",
        "name": "cities",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChildrenNode",
            "kind": "LinkedField",
            "name": "results",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RsLocationSearchDialogCityQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RsLocationSearchDialogCityQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b4b3282b01caaea464c527d86399d2c9",
    "id": null,
    "metadata": {},
    "name": "RsLocationSearchDialogCityQuery",
    "operationKind": "query",
    "text": "query RsLocationSearchDialogCityQuery(\n  $id: ID!\n) {\n  viewer {\n    cities(id: $id) {\n      results {\n        id\n        title\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9bdda7d665a7214588f0d9b5f30a0be2";

export default node;
