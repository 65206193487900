import React from "react";
import AsyncSelect from "react-select/lib/Async";

// @ts-ignore-error
import { logGraphQLError, logAsyncOperationError } from "../../utils/logging";
import { reactSelectStyles, reactSelectTheme } from "../../constants/reactSelect";
import { FetchGraphQLAPIFunc, FetchGraphQLAPIResponse } from "../../types/fetch";

export type WorkerTypeSelectValue = {
  id: number;
  name: string;
  description?: string;
};

type WorkerTypesResponseData = {
  viewer: {
    workerTypes: WorkerTypeSelectValue[];
  };
};

type QueryVariables = {
  countryId: number;
  industryId?: number;
};

type BaseAsyncSelectProps = React.ComponentProps<
  typeof AsyncSelect<WorkerTypeSelectValue>
>;

export interface WorkerTypeSelectProps {
  key?: string | number;
  id?: string;
  countryId: number;
  industryId?: number;
  fetchGraphQL: FetchGraphQLAPIFunc<WorkerTypesResponseData>;
  defaultOptions?: WorkerTypeSelectValue[] | boolean;
  value: BaseAsyncSelectProps["value"];
  onChange: BaseAsyncSelectProps["onChange"];
  placeholder: BaseAsyncSelectProps["placeholder"];
  loading: BaseAsyncSelectProps["isLoading"];
  disabled: BaseAsyncSelectProps["isDisabled"];
  multi: BaseAsyncSelectProps["isMulti"];
  clearable: BaseAsyncSelectProps["isClearable"];
  styles: Object;
  theme: BaseAsyncSelectProps["theme"];
  className: BaseAsyncSelectProps["className"];
}

const WorkerTypeSelect = (props: WorkerTypeSelectProps) => {
  const { countryId, industryId, fetchGraphQL } = props;

  const loadOptions = React.useCallback(async () => {
    const variables: QueryVariables = { countryId };
    if (industryId != null) {
      variables["industryId"] = industryId;
    }

    try {
      const response: FetchGraphQLAPIResponse<WorkerTypesResponseData> =
        await fetchGraphQL(
          `query getWorkerTypesList($countryId: Int!, $industryId: Int) {
          viewer {
            workerTypes(countryId: $countryId, industryId: $industryId) {
              id
              name
              description
            }
          }
        }`,
          variables
        );

      if (logGraphQLError("fetchWorkerTypesList", response.data)) return [];

      return response.data.data.viewer.workerTypes || [];
    } catch (err) {
      logAsyncOperationError("fetchWorkerTypesList", err);
      throw err;
    }
  }, [countryId, industryId, fetchGraphQL]);

  const getOptionValue = (option: WorkerTypeSelectValue) => option.id.toString();
  const getOptionLabel = (option: WorkerTypeSelectValue) => option.name;
  const formatOptionLabel = (option: WorkerTypeSelectValue) =>
    [option.name, option.description].filter((item) => !!item).join(" - ");
  const cacheSum = React.useMemo(
    () => [countryId, industryId].join(", "),
    [industryId, countryId]
  );
  const noOptionsMessage = React.useCallback(
    (_) =>
      `No worker types found for the specified Country${
        industryId != null ? "/Industry" : ""
      }.`,
    [industryId]
  );

  return (
    <AsyncSelect
      className={props.className}
      inputId={props.id}
      loadOptions={loadOptions}
      cacheOptions={cacheSum} // doesn't work !!! changing the sum value invalidates options cache and makes options re-fetch
      defaultOptions={props.defaultOptions} // preload
      noOptionsMessage={noOptionsMessage}
      value={props.value}
      onChange={props.onChange}
      styles={props.styles}
      theme={props.theme}
      placeholder={props.placeholder}
      isLoading={props.loading}
      isDisabled={props.disabled}
      isMulti={props.multi}
      isSearchable={false}
      isClearable={props.clearable}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      formatOptionLabel={formatOptionLabel}
    />
  );
};

WorkerTypeSelect.displayName = "WorkerTypeSelect";
WorkerTypeSelect.defaultProps = {
  placeholder: "Choose worker type...",
  defaultOptions: true,
  loading: false,
  disabled: false,
  multi: false,
  clearable: false,
  styles: reactSelectStyles,
  theme: reactSelectTheme,
};

export default WorkerTypeSelect;
