// @flow
import * as React from "react";
import AsyncSelect from "react-select/lib/Async";
import { reactSelectStyles, reactSelectTheme } from "../../constants/reactSelect";

type State = {};

type Props = {
  onChange: (Array<{ id: number, name: String }>) => void,
};

class MultiRegionSelectNew extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.getRegionList = this.getRegionList.bind(this);
  }

  handleChange(value, actionMeta) {
    // console.log('value', value);
    // console.log('actionMeta', actionMeta);

    this.props.onChange(
      value.map((region) => ({
        id: region.value,
        name: region.label,
      }))
    );
  }

  async getRegionList(inputValue) {
    const query = `
      query getRegions{
        viewer{
          regions(order: [{field: NAME}]){
            edges{
              node{
                regionId
                name
              }
            }
          }
        }
      }
      `;

    let res = null;

    try {
      res = await this.props.fetchGraphQL(query, null);
      // should hit get request
    } catch (e) {
      console.error("Error in getting regions", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);

      return [];
    }

    return res.data.viewer.regions.edges.map((e) => ({
      value: e.node.regionId,
      label: e.node.name,
    }));
  }

  render() {
    return (
      <AsyncSelect
        name="regions"
        placeholder="Select Region"
        inputId={this.props.id || "regionSelect"}
        openMenuOnFocus
        isMulti
        cacheOptions
        defaultOptions
        loadOptions={this.getRegionList}
        onChange={this.handleChange}
        styles={reactSelectStyles}
        theme={reactSelectTheme}
      />
    );
  }
}

export default MultiRegionSelectNew;
