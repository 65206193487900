import React, { useRef, useEffect, useCallback } from "react";
import queryString from "query-string";
// @ts-expect-error
import uuid from "uuid";

import PromiseButton from "../../../components/lib/PromiseButton";
// @ts-expect-error
import { getCookie } from "../../../utils/cookie";

import type { PromiseButtonProps } from "../../../components/lib/PromiseButton";
import { UrlQueryObject, FiltersQueryObject } from "../../../components/tables/types";

type MaybePromise<T> = T | Promise<T>;
type MaybeFunction<T> = T | (() => T);

export interface DownloadPOSTButtonExtProps
  extends Omit<PromiseButtonProps, "onError" | "loading"> {
  service: string;
  endpoint: string;
  getQueryArgs?: MaybeFunction<MaybePromise<UrlQueryObject>> | null | undefined;
  getFormData?: MaybeFunction<MaybePromise<FiltersQueryObject>> | null | undefined;
  onValidate?: (
    queryArgs: UrlQueryObject,
    formData: FiltersQueryObject
  ) => Promise<boolean>;
  onSuccess?: () => void;
  onError?: (err: Error) => void;
}

function DownloadPOSTButtonExt(
  props: React.PropsWithChildren<DownloadPOSTButtonExtProps>
) {
  const {
    children,
    service = "m8",
    endpoint,
    getQueryArgs,
    getFormData,
    onValidate,
    onClick,
    onSuccess,
    onError,
    ...restProps
  } = props;
  const [loadingState, setLoadingState] = React.useState(false);

  const cookieIntervalIdRef = useRef<NodeJS.Timeout | null>(null);
  const formRef = useRef<HTMLElement | null>(null);
  const iframeRef = useRef<HTMLElement | null>(null);

  // reusable handlers

  const handleSubmitSuccess = useCallback(() => {
    setLoadingState(false);
    onSuccess?.();
  }, [onSuccess]);
  const handleSubmitError = useCallback(
    (err: Error) => {
      setLoadingState(false);
      onError?.(err);
    },
    [onError]
  );

  // utils

  const cleanUpDOM = useCallback(() => {
    formRef.current?.remove();
    formRef.current = null;
    iframeRef.current?.remove();
    iframeRef.current = null;
  }, [formRef, iframeRef]);

  const stopCheckingCookies = useCallback(() => {
    clearInterval(cookieIntervalIdRef.current || undefined);
    cleanUpDOM();
  }, [cookieIntervalIdRef, cleanUpDOM]);

  const checkCookies = useCallback(
    (cookieId, resolve) => {
      if (getCookie(cookieId) === "true") {
        resolve();
        document.cookie = `${cookieId}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
        stopCheckingCookies();
        handleSubmitSuccess();
      }
    },
    [handleSubmitSuccess, stopCheckingCookies]
  );

  const startCheckingCookies = useCallback(
    (cookieId, resolve) => {
      cookieIntervalIdRef.current = setInterval(
        () => checkCookies(cookieId, resolve),
        100
      );
    },
    [checkCookies]
  );

  const createInputElement = useCallback((name: string, value: string) => {
    const input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", name);
    input.setAttribute("value", value);
    return input;
  }, []);

  const createIframeElement = useCallback((cookieId: string) => {
    const iframe = document.createElement("iframe");
    iframe.setAttribute("name", cookieId);
    iframe.setAttribute("src", "about:blank");
    iframe.setAttribute("accept-charset", "UTF-8");
    return iframe;
  }, []);

  const createFormElement = useCallback((cookieId: string) => {
    const form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("target", cookieId);
    form.setAttribute("style", "display: none");
    return form;
  }, []);

  // handlers

  const handleSubmit = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setLoadingState(true);
      onClick?.(e);

      // Insert target Iframe
      const cookieId = uuid.v4();
      const iframe = createIframeElement(cookieId);
      const form = createFormElement(cookieId);

      formRef.current = form;
      iframeRef.current = iframe;
      document.body.appendChild(iframe);

      // grab url arguments
      let queryArgsPromise = getQueryArgs;
      if (queryArgsPromise == null) {
        queryArgsPromise = Promise.resolve(queryArgsPromise);
      }
      if (typeof getQueryArgs === "function") {
        queryArgsPromise = getQueryArgs();
      }
      if (!(queryArgsPromise instanceof Promise)) {
        queryArgsPromise = Promise.resolve(queryArgsPromise);
      }

      // grab post body parameters
      let formDataPromise = getFormData;
      if (formDataPromise == null) {
        formDataPromise = Promise.resolve(formDataPromise);
      }
      if (typeof getFormData === "function") {
        formDataPromise = getFormData();
      }
      if (!(formDataPromise instanceof Promise)) {
        formDataPromise = Promise.resolve(formDataPromise);
      }

      return Promise.all([queryArgsPromise, formDataPromise])
        .then(([queryArgsData, formData]) => {
          const validatePromise = onValidate
            ? onValidate(queryArgsData, formData)
            : Promise.resolve(true);

          validatePromise.then((isValid) => {
            if (!isValid) return Promise.resolve();
            onClick?.(e);

            // Add query arguments to the form action attr
            let formAction = `/api/${service}/${endpoint}`;
            if (queryArgsData && Object.keys(queryArgsData).length > 0) {
              formAction = `${formAction}?${queryString.stringify(queryArgsData)}`;
            }
            form.setAttribute("action", formAction);

            // Add body parameters as hidden form inputs
            if (formData && Object.keys(formData).length > 0) {
              Object.keys(formData).forEach((key) => {
                const value = formData[key];
                form.appendChild(createInputElement(key, JSON.stringify(value)));
              });
            }
            form.appendChild(createInputElement("__cookie", cookieId));
            form.appendChild(createInputElement("__iehack", "&#9760;"));
            document.body.appendChild(form);
            form.submit();

            return new Promise((resolve, reject) => {
              startCheckingCookies(cookieId, resolve);
              iframe.addEventListener("load", () => {
                // Load event fires uppon error
                const error = new Error("Internal form submition error.");

                reject(error);
                handleSubmitError(error);
                stopCheckingCookies();
              });
            });
          });
        })
        .catch(([queryArgsErr, formDataErr]) => {
          if (queryArgsErr) {
            console.error(
              `${DownloadPOSTButtonExt.displayName}: Error occurred while retrieving query arguments.`,
              queryArgsErr
            );
          } else if (formDataErr) {
            console.error(
              `${DownloadPOSTButtonExt.displayName}: Error occurred while retrieving form data.`,
              formDataErr
            );
          }
        });
    },
    [
      service,
      endpoint,
      getQueryArgs,
      getFormData,
      createInputElement,
      createIframeElement,
      createFormElement,
      onClick,
      onValidate,
      handleSubmitError,
      startCheckingCookies,
      stopCheckingCookies,
    ]
  );

  useEffect(() => {
    return stopCheckingCookies;
  }, [stopCheckingCookies]);

  return (
    <PromiseButton {...restProps} loading={loadingState} onClick={handleSubmit}>
      {children}
    </PromiseButton>
  );
}

DownloadPOSTButtonExt.displayName = DownloadPOSTButtonExt;

export default DownloadPOSTButtonExt;
