// @flow
import * as React from "react";
import ContainerSection from "../views/ratecards/components/ContainerSection";
import Modal from "../views/ratecards/components/Modal";
import graphql from "babel-plugin-relay/macro";
// import { fetchQuery } from "relay-runtime";
import { fetchQuery } from "react-relay";
import LoadingIndicator from "../views/shared/LoadingIndicator";
import type { ExportModalQueryResponse } from "./__generated__/ExportModal_export.graphql";
import { ModalButtons, ModalTitle } from "../views/ratecards/components/FilterModal";
import Button from "./lib/Button";
import LinkButton from "./lib/LinkButton";
import Stack from "./lib/Stack";
import { Environment } from "relay-runtime";

const exportQuery = graphql`
  query ExportModalQuery($exportId: ID!) {
    viewer {
      export(id: $exportId) {
        id
        status
        created
        modified
        expiresAt
        exportFile
      }
    }
  }
`;

type Props = {
  relayEnvironment: Environment,
  show: boolean,
  title?: ?string,
  onHide: () => void,
  innerStyle?: {},
  exportId: ?string,
};

type State = {
  refreshing: boolean,
  exportResponse: ExportModalQueryResponse | null,
};

class ExportModal extends React.Component<Props, State> {
  intervalID: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      refreshing: false,
      exportResponse: null,
    };
  }

  refreshExportStatus = () => {
    if (this.props.exportId) {
      this.setState(
        {
          refreshing: true,
        },
        async () => {
          const response: ExportModalQueryResponse = await fetchQuery(
            this.props.relayEnvironment,
            exportQuery,
            {
              exportId: this.props.exportId,
            }
          ).toPromise();
          this.setState({
            refreshing: false,
            exportResponse: response,
          });

          const exportStatus = response?.viewer?.export?.status;
          if (exportStatus === "FINISHED") {
            clearInterval(this.intervalID);
            this.intervalID = null;
          }
        }
      );
    }
  };

  componentDidMount = () => {
    this.refreshExportStatus();

    this.intervalID = setInterval(() => {
      this.refreshExportStatus();
    }, 5000);
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (prevProps.exportId !== this.props.exportId) {
      if (prevProps.exportId === null) {
        this.refreshExportStatus();

        this.intervalID = setInterval(() => {
          this.refreshExportStatus();
        }, 5000);
      } else if (this.props.exportId === null) {
        clearInterval(this.intervalID);
        this.intervalID = null;
        this.setState({
          refreshing: false,
          exportResponse: null,
        });
      }
    }
  };

  componentWillUnmount = () => {
    clearInterval(this.intervalID);
  };

  render() {
    const { title, show, onHide } = this.props;

    const innerStyle = Object.assign(
      {
        minWidth: 400,
      },
      this.props.innerStyle
    );

    const statusIndicator = <LoadingIndicator />;

    const exportResponse = this.state.exportResponse?.viewer?.export;

    // This component assumes null exportId means it's not yet finished
    // Modal should only be shown by parent component when it is submitting or already submitted an export request.
    return (
      <Modal show={show} onHide={onHide} innerStyle={innerStyle}>
        <ContainerSection className="header">
          <ModalTitle>{title || "Export"}</ModalTitle>
        </ContainerSection>
        <ContainerSection>
          <Stack css={{ height: 200, justifyContent: "center" }}>
            {exportResponse ? (
              <div>Export Status: {exportResponse?.status || "N/A"}</div>
            ) : (
              <div className="flex flex-row" style={{ justifyContent: "center" }}>
                <div>Submitting export request...</div>
                {statusIndicator}
              </div>
            )}
            {exportResponse?.status === "PROCESSING" && statusIndicator}
            {exportResponse?.status === "FINISHED" && (
              <LinkButton
                as="a"
                href={`/api/export/${exportResponse?.id || "ERROR"}`}
                color="brand"
                icon="download"
              >
                Download Export
              </LinkButton>
            )}
          </Stack>
        </ContainerSection>
        <ContainerSection className="footer">
          <ModalButtons>
            <Button
              size="large"
              onClick={() => {
                this.refreshExportStatus();
              }}
            >
              Refresh
            </Button>
            <Button size="large" onClick={onHide}>
              Close
            </Button>
          </ModalButtons>
        </ContainerSection>
      </Modal>
    );
  }
}

export default ExportModal;
