/**
 * @generated SignedSource<<181e9e96c23841b03ec06af37f7d2fea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type RsLocationSearchDialogLocationsByIdQuery$variables = {
  ids?: ReadonlyArray<string | null> | null;
};
export type RsLocationSearchDialogLocationsByIdQuery$data = {
  readonly viewer: {
    readonly locationsByIds: ReadonlyArray<{
      readonly countryId: number | null;
      readonly fullSubtitle: string | null;
      readonly fullTitle: string | null;
      readonly lat: string | null;
      readonly locationId: string | null;
      readonly lon: string | null;
      readonly type: string | null;
    } | null> | null;
  } | null;
};
export type RsLocationSearchDialogLocationsByIdQuery = {
  response: RsLocationSearchDialogLocationsByIdQuery$data;
  variables: RsLocationSearchDialogLocationsByIdQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "ids",
            "variableName": "ids"
          }
        ],
        "concreteType": "LocationListLookupNode",
        "kind": "LinkedField",
        "name": "locationsByIds",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullTitle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullSubtitle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "countryId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "locationId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lat",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lon",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RsLocationSearchDialogLocationsByIdQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RsLocationSearchDialogLocationsByIdQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "273a1dcd2e91f7c979940359b82569a3",
    "id": null,
    "metadata": {},
    "name": "RsLocationSearchDialogLocationsByIdQuery",
    "operationKind": "query",
    "text": "query RsLocationSearchDialogLocationsByIdQuery(\n  $ids: [String]\n) {\n  viewer {\n    locationsByIds(ids: $ids) {\n      type\n      fullTitle\n      fullSubtitle\n      countryId\n      locationId\n      lat\n      lon\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e96b50badeb2178028dd045d117df705";

export default node;
