import React, { useCallback, useState } from "react";
import { fromJS } from "immutable";

import Button from "../../../components/lib/Button";
import Box from "../../../components/lib/Box";
import CurrencySelect from "../../../components/selects/CurrencySelect";
import { ButtonGroupRight } from "../../../components/lib/ButtonGroup";

import type {
  CurrencyData,
  CurrencySelectProps,
} from "../../../components/selects/CurrencySelect";
import type { CurrencyDataMap } from "../types";
import { useVal5KAdminContext } from "../context/Val5KAdminContext";

type DisplayCurrencySelectorProps = {
  currencyData: CurrencyDataMap;
  onChange: (value: CurrencyDataMap) => void;
};

const DisplayCurrencySelector = (props: DisplayCurrencySelectorProps) => {
  const { currencyData: initCurrencyData, onChange } = props;
  const {
    currenciesData: allCurrenciesData,
    showConfirmationModal,
    closeConfirmationModal,
  } = useVal5KAdminContext();

  // state

  const [currencyData, setCurrencyData] = useState<CurrencyDataMap>(initCurrencyData);

  // handlers

  const handleChange = useCallback(
    (value: CurrencyData) => {
      const convertedValue = fromJS(value);
      setCurrencyData(convertedValue);
      onChange(convertedValue);
      closeConfirmationModal();
    },
    [onChange, closeConfirmationModal]
  );

  const handleRequestDisplayCurrencyChange = useCallback(() => {
    const header = "View In Other Currency";
    const message = (
      <Box css={{ width: "500px", height: "300px" }}>
        <label>Currency:</label>
        <CurrencySelect
          currenciesData={allCurrenciesData.toList().toJS()}
          value={currencyData.toJS()}
          onChange={handleChange as CurrencySelectProps["onChange"]}
        />
      </Box>
    );
    const footer = (
      <ButtonGroupRight fill css={{ flexDirection: "row-reverse" }}>
        <Button size="large" onClick={closeConfirmationModal}>
          Cancel
        </Button>
      </ButtonGroupRight>
    );

    showConfirmationModal(message, header, footer);
  }, [
    currencyData,
    allCurrenciesData,
    handleChange,
    showConfirmationModal,
    closeConfirmationModal,
  ]);

  return (
    <Button color="brand" variant="outlined" onClick={handleRequestDisplayCurrencyChange}>
      View In Other Currency
    </Button>
  );
};
DisplayCurrencySelector.displayName = "DisplayCurrencySelector";

export default DisplayCurrencySelector;
