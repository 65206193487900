// @flow

import R from "ramda";
import { extendObservable, action, runInAction, computed, observable } from "mobx";
import axios from "axios";
import PaginationState from "../../models/PaginationState";
import NetworkState from "../../models/NetworkState";
import type { PageQuery, PaginationInfo } from "../../models/PaginationState";
import LocationList from "../../models/LocationList";
import RegionList from "../../models/RegionList";
import FilterObject, { FILTER_COLUMN } from "../../models/Filter";
import { HasLocationTypeFilter } from "../../models/FilterState";
import {
  addIdToPayload,
  consolidateAppliedFilters,
  consolidateAppliedSorts,
} from "./SupportFunctions";
import ModalState from "../../models/ModalState";
import MessageState from "../../models/MessageState";
import ApplyTagState from "../../models/ApplyTagState";
import CurrentUser from "../../models/User";
import type { FetchGraphQL } from "../../App";
import React from "react";
import type { AffectedSearch } from "./RegionListStore";

const locationTypesCriteriaQuery = `
query {
  viewer {
    rateCardsFilterCriteria {
      rateTypes
    }
  }
}
`;

export class LocationListComponentStore {
  fetchGraphQL: FetchGraphQL;
  getRegionLocations: (PageQuery) => Promise<PaginationInfo>;
  router: ?Object;
  network: NetworkState;
  pagination: PaginationState;
  totalCount: any;
  regionsList: LocationList[];
  regionsListView: LocationList[];
  regionsListViewState: Object;
  createdOnFilter: CreatedOnFilter;
  showLocationPopup: boolean;
  showModel: () => void;
  closeModel: () => void;
  defaultFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedSorts: {
    [key: FilterColumn]: Sort,
  };
  isFiltered: boolean;
  appliedSortsOrder: Array<FilterColumn>;
  applyDefaultFilter: (FilterColumn, FilterObject) => void;
  applyFilter: (FilterColumn, FilterObject) => void;
  removeFilter: (FilterColumn) => void;
  applySort: (FilterColumn, Sort) => void;
  removeSort: (FilterColumn) => void;
  clearFilters: () => void;
  isEditing: ?boolean;
  handleStartEdit: () => void;
  handleStopEdit: () => void;
  toggleSelectAllPage: (Object) => void;
  selectAllPage: (Event) => void;
  deselectAllPage: (Event) => void;
  clearAllSelections: () => void;
  allSelected: boolean;
  allOnPageSelected: boolean;
  allowMultipleItemSelection: boolean;
  confirmDeleteModal: ModalState;
  confirmLocationDeleteModal: ModalState;
  getSelectedRegionsList: () => Array<string>;
  regionFilter: RegionFilter;
  countryFilter: CountryFilter;
  regionId: number;
  regionDetail: RegionList;
  renameRegionModal: ModalState;
  showRenameRegionModal: () => void;
  newRegionCardName: string;
  onNewRegionNameChange: (Event) => void;
  renameRegion: () => void;
  hasLocationTypeFilter: HasLocationTypeFilter;
  getFilterCriteriaQuery: (FilterColumn) => GraphQLQuery;
  processFilterCriteria: (FilterColumn, Object) => Array<Object>;
  showHelp: () => void;
  hideHelp: () => void;
  showHelpModal: boolean;
  messaging: MessageState;
  openLocation: () => void;
  CurrentUser: CurrentUser;
  selectedTags: Object;
  selectAllOnPageItem: () => void;
  errorModal: ModalState;
  errorMessage: any;

  constructor(fetchGraphQL: FetchGraphQL) {
    this.fetchGraphQL = fetchGraphQL;
    this.router = null;
    this.getRegionDetail = action(this.getRegionDetail.bind(this));
    this.getRegionLocations = action(this.getRegionLocations.bind(this));
    this.showHelp = action(this.showHelp.bind(this));
    this.hideHelp = action(this.hideHelp.bind(this));
    this.applyFilter = action(this.applyFilter.bind(this));
    this.applySort = action(this.applySort.bind(this));
    this.removeFilter = action(this.removeFilter.bind(this));
    this.removeSort = action(this.removeSort.bind(this));
    this.renameRegion = action(this.renameRegion.bind(this));
    this.getFilterCriteriaQuery = action(this.getFilterCriteriaQuery.bind(this));
    this.processFilterCriteria = action(this.processFilterCriteria.bind(this));
    this.showModel = action(this.showModel.bind(this));
    this.closeModel = action(this.closeModel.bind(this));
    this.openLocation = action(this.openLocation.bind(this));
    this.selectAllOnPageItem = action(this.selectAllOnPageItem.bind(this));

    extendObservable(this, {
      showHelpModal: false,
      network: new NetworkState(),
      pagination: new PaginationState(this.getRegionLocations),
      totalCount: 0,
      regionsList: [],
      regionDetail: {},
      showLocationPopup: false,
      regionId: null,
      regionsListViewState: observable.map({}),
      regionsListView: computed(() => {
        return this.regionsList.map((region) => {
          if (this.regionsListViewState.has(region.regionId)) {
            region.viewState = this.regionsListViewState.get(region.regionId);

            return region;
          }

          return region;
        });
      }),
      allOnPageSelected: computed(() => {
        const allTrue = R.all(R.equals(true));
        const selectedValues = this.regionsListView.map(
          (regionsListView) => regionsListView.viewState.selected
        );

        if (selectedValues.length === 0) {
          return false;
        }

        return allTrue(selectedValues);
      }),
      appliedSortsOrder: observable.shallow([]),
      defaultFilters: {},
      appliedFilters: {},
      appliedSorts: {},
      isFiltered: false,
      isEditing: null,
      allowMultipleItemSelection: true,
      selectedCount: computed(() => {
        const selectedValues = this.regionsListView.map(
          (region) => region.viewState.selected
        );

        if (this.allSelected) {
          return this.pagination.totalCount;
        }

        let count = 0;

        selectedValues.forEach((v) => {
          if (v) {
            count += 1;
          }
        });

        return count;
      }),
      allSelected: false,
      confirmDeleteModal: new ModalState(),
      confirmLocationDeleteModal: new ModalState(),
      renameRegionModal: new ModalState(),
      newRegionName: "",
      hasLocationTypeFilter: new HasLocationTypeFilter(
        this,
        FILTER_COLUMN.LOCATION_TYPE,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.removeFilter
      ),
      errorModal: new ModalState(),
      errorMessage: null,
      messaging: new MessageState(),
      applyTagState: new ApplyTagState(fetchGraphQL, this),
    });
    this.CurrentUser = null;
    this.applyDefaultFilter = action(this.applyDefaultFilter.bind(this));
    this.handleStartEdit = action(this.handleStartEdit.bind(this));
    this.handleStopEdit = action(this.handleStopEdit.bind(this));
    this.clearFilters = action(this.clearFilters.bind(this));
    this.toggleSelectAllPage = action(this.toggleSelectAllPage.bind(this));
    this.selectAllPage = action(this.selectAllPage.bind(this));
    this.deselectAllPage = action(this.deselectAllPage.bind(this));
    this.clearAllSelections = action(this.clearAllSelections.bind(this));
    this.getSelectedRegionsList = action(this.getSelectedRegionsList.bind(this));
    this.showRenameRegionModal = action(this.showRenameRegionModal.bind(this));
    this.onNewRegionNameChange = action(this.onNewRegionNameChange.bind(this));
  }

  openLocation(regionCreateStore, regionId) {
    regionCreateStore.setCountry(this.regionDetail.country, regionId, this);
    regionCreateStore.showModel();
  }

  showModel() {
    this.showLocationPopup = true;
  }

  closeModel() {
    this.showLocationPopup = false;
    this.cityList = [];
  }

  handleStartEdit() {
    this.isEditing = true;
    this.regionsListView.forEach((region) => region.toggleEdit());
  }

  handleStopEdit() {
    this.isEditing = false;
    this.allSelected = false;
    this.regionsListViewState.forEach((viewState) => {
      viewState.selected = false;
      viewState.editing = false;
    });
  }

  showRenameRegionModal() {
    this.messaging.removeAll();
    this.newRegionName = this.regionDetail.name;
    this.renameRegionModal.showModal();
  }

  onNewRegionNameChange(e: Event) {
    this.messaging.removeAll();
    this.newRegionName = e.target.value;
  }

  toggleSelectAllPage(e: Object) {
    if (!this.allowMultipleItemSelection) return;

    const setValue = !this.allOnPageSelected;

    this.regionsListView.forEach((region) => {
      region.toggleSelected(e, null, null, setValue);
    });

    // When All items selected flag is up, clear selection
    if (setValue === false && this.allSelected) this.allSelected = false;
  }

  selectAllPage(e: Event) {
    this.allSelected = true;
  }

  selectAllOnPageItem(e: Event) {
    this.regionsListView.forEach((region) => {
      region.toggleSelected(e, null, null, true);
    });
  }

  deselectAllPage(e: Event) {
    this.regionsListView.forEach((region) => {
      region.toggleSelected(e, null, null, false);
    });

    this.allSelected = false;
  }

  clearAllSelections() {
    this.allSelected = false;
    this.regionsListViewState.forEach((value) => {
      value.selected = false;
    });
  }

  applyFilter(column: FilterColumn, filter: FilterObject) {
    this.appliedFilters[column] = filter;
    this.isFiltered = true;
  }

  applyDefaultFilter(column: FilterColumn, filter: FilterObject) {
    this.defaultFilters[column] = filter;
  }

  removeFilter(column: FilterColumn) {
    delete this.appliedFilters[column];

    let entries = Object.entries(this.appliedFilters);
    if (!entries.length) this.isFiltered = false;
  }

  applySort(column: FilterColumn, sort: Sort) {
    this.appliedSorts[column] = sort;

    const index = this.appliedSortsOrder.indexOf(column);
    if (index === -1) this.appliedSortsOrder.push(column);
  }

  removeSort(column: FilterColumn) {
    delete this.appliedSorts[column];

    const index = this.appliedSortsOrder.indexOf(column);
    if (index > -1) this.appliedSortsOrder.splice(index, 1);
  }

  //clear all filters
  clearFilters() {
    this.hasLocationTypeFilter = new HasLocationTypeFilter(
      this,
      FILTER_COLUMN.LOCATION_TYPE,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.removeFilter
    );

    this.appliedFilters = observable({});
    this.appliedSorts = observable({});
    this.appliedSortsOrder.length = 0;
    this.isFiltered = false;

    return this.pagination.goFetch(null);
  }

  getFilterCriteriaQuery(column: FilterColumn): GraphQLQuery {
    switch (column) {
      case FILTER_COLUMN.LOCATION_TYPE:
        return {
          query: locationTypesCriteriaQuery,
          variables: {},
        };

      default:
        return null;
    }
  }

  processFilterCriteria(column: FilterColumn, payload: Object): ?Array<Object> {
    switch (column) {
      case FILTER_COLUMN.LOCATION_TYPE:
        const rateTypes: [String] = ["City", "State"];
        return addIdToPayload(rateTypes);

      default:
        return null;
    }
  }

  toggleAllItems() {
    if (!this.allowMultipleItemSelection) return;

    this.allSelected = !this.allSelected;

    if (this.allSelected === false) {
      this.projectCostsView.forEach((value) => {
        value.viewState.selected = false;
      });
    }
  }

  getSelectedRegionsList(): Array<string> {
    const regionsList = this.regionsListViewState;

    let selectedregionsList = [];

    regionsList.forEach((value, key) => {
      if (value.selected) {
        selectedregionsList.push(key);
      }
    });

    return selectedregionsList;
  }

  showHelp() {
    this.showHelpModal = true;
  }

  hideHelp() {
    this.showHelpModal = false;
  }

  //function for get region Detail
  async getRegionDetail() {
    let res = null;
    if (!/^\d+$/.test(this.regionId)) {
      if (this.router) {
        this.router.push({
          pathname: "/404NotFound",
          query: this.router.query,
        });
      }
      return res;
    }
    //   let params: string[] = pageQuery.params;
    // let args = pageQuery.args;
    // let variables = pageQuery.variables;
    let params: string[] = [];
    let args = [];
    const variables = {
      id: this.regionId,
    };

    params.push("$id: Int!");
    args.push("id: $id");

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
      query regiondetail(${queryParams}) {
        viewer {
          user{
              firstName
              lastName
              userId
              username
              email
            }
           region (${queryArgs}){
            regionId
            name
            created
            user {
              firstName
              lastName
            }
           country{
             name
             locationId
           }
           tags{
             name
             tagId
           }
         }
        }
      }`;

    this.network.loading = true;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting Regions", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Regions query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getRegionDetail--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get Region query", res)) {
        // TODO: Display user friendly error message
        if (!res.data.viewer.region) {
          if (this.router) {
            this.router.push({
              pathname: "/404NotFound",
              query: this.router.query,
            });
          }
          return;
        }
      }
      this.pagination.goFetch();
      this.selectedTags = observable.map({});
      res.data.viewer.region.tags.forEach((item) => {
        this.selectedTags.set(item.tagId, item);
      });
      this.currentUser = new CurrentUser(this, res.data.viewer.user);
      this.regionDetail = new RegionList(this, res.data.viewer.region);
    });
  }

  async getRegionLocations(pageQuery: PageQuery): Promise<PaginationInfo> {
    let result = null;
    let params: string[] = pageQuery.params;
    let args = pageQuery.args;
    let variables = pageQuery.variables;

    let filtersCriteria: string[] = [];

    let sortCriteria: string[] = [];
    consolidateAppliedSorts(this.appliedSorts, sortCriteria);

    consolidateAppliedFilters(this.appliedFilters, params, filtersCriteria, variables);

    params.push("$id: Int!");
    args.push("id: $id");
    variables.id = this.regionId;

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");
    const queryFiltersCriteria = filtersCriteria.join(", ");

    const query = `
      query allRegionLocations(${queryParams}){
          viewer{
            regionLocations(${queryArgs},filters:{ ${queryFiltersCriteria}}, order: [{field:NAME}]) {
              totalCount
              edges {
                node {
                  locationId
                  name
                  locationType {
                     value
                     locationTypeId
                  }
                }
              }
            }
          }
        }
      `;

    this.network.loading = true;

    try {
      result = await this.fetchGraphQL(query, variables);
    } catch (e) {
      console.log("error", e);
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting Region Locations", e);
      if (result !== null) {
        this.network.logGraphQLError("Get Region Locations query", result);
      }
      return e;
    }

    return runInAction("regionLocations--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get Region Locations query", result)) {
        return {
          totalCount: 0,
        };
      }

      const locations = result.data.viewer.regionLocations.edges;
      this.totalCount = result.data.viewer.regionLocations.totalCount;
      this.regionsList = locations.map((loc) => {
        const region = new LocationList(this, loc.node);
        if (!this.regionsListViewState.has(region.id)) {
          this.regionsListViewState.set(region.id, {
            selected: this.allSelected,
            editing: this.isEditing,
          });
        } else {
          const selectedValue = this.allSelected
            ? true
            : this.regionsListViewState.get(region.id).selected;

          this.regionsListViewState.set(region.id, {
            selected: selectedValue,
            editing: this.isEditing,
          });
        }
        region.viewState = this.regionsListViewState.get(region.id);
        return region;
      });

      return {
        totalCount: result.data.viewer.regionLocations.totalCount,
      };
    });
  }
}

export default class LocationListStore extends LocationListComponentStore {
  deleteLocations: () => void;
  selectRegions: () => void;
  deleteRegion: () => void;
  deleteRegionNetwork: NetworkState;
  searchesAffectedByDeletion: AffectedSearch[];
  getAffectedSearchesByDeletion: () => Promise<void>;
  confirmRegionDeletion: () => void;
  deleteSingleTags: () => void;
  applyTags: () => void;
  applyTagModal: () => void;
  noTagsMessage: String;

  constructor(fetchGraphQL: FetchGraphQL) {
    super(fetchGraphQL);

    this.deleteLocations = action(this.deleteLocations.bind(this));
    this.selectRegions = action(this.selectRegions.bind(this));
    this.deleteRegion = action(this.deleteRegion.bind(this));
    this.deleteSingleTags = action(this.deleteSingleTags.bind(this));
    this.applyTags = action(this.applyTags.bind(this));
    this.applyTagModal = action(this.applyTagModal.bind(this));
    this.getAffectedSearchesByDeletion = action(
      this.getAffectedSearchesByDeletion.bind(this)
    );
    this.confirmRegionDeletion = action(this.confirmRegionDeletion.bind(this));

    extendObservable(this, {
      noTagsMessage: null,
      deleteRegionNetwork: new NetworkState(),
      searchesAffectedByDeletion: [],
    });
  }

  confirmRegionDeletion() {
    this.confirmDeleteModal.showModal();
    this.getAffectedSearchesByDeletion();
  }

  async getAffectedSearchesByDeletion() {
    if (this.deleteRegionNetwork.loading) return;

    this.searchesAffectedByDeletion = [];
    let vars = { regionIdIn: [this.regionId] };

    const query = `
    query regionSearches($regionIdIn: [ID]!) {
      viewer {
        savedsearches(filters: {regionIdIn: $regionIdIn}) {
          edges {
            node {
              searchId
              job {
                jobLabel
              }
              rateCard {
                name
              }
              region {
                name
              }
            }
          }
        }
      }  
    }
    `;
    this.deleteRegionNetwork.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.deleteRegionNetwork.handleError(
        "Getting searches affected by region deletion",
        e
      );
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.deleteRegionNetwork.loading = false;
      return;
    }

    return runInAction("getAffectedSearchesByDeletion--success", () => {
      this.deleteRegionNetwork.loading = false;
      this.deleteRegionNetwork.error = null;

      if (!res) return null;

      this.searchesAffectedByDeletion = res.data.viewer.savedsearches.edges.map(
        (edge) => {
          const search: AffectedSearch = {
            databaseId: edge.node.searchId,
            jobLabel: edge.node.job.jobLabel,
            // jobTitle: edge.node.job.jobTitle,
            regionName: edge.node.region.name,
            rateCardName: edge.node.rateCard?.name,
          };
          return search;
        }
      );
      return this.searchesAffectedByDeletion;
    });
  }

  applyTagModal() {
    this.noTagsMessage = null;
    this.applyTagState.showTagModal();
  }

  selectRegions(type) {
    if (this.network.loading) {
      return;
    }

    let searchCriteria = "";

    const selectedRegionsList = this.getSelectedRegionsList();

    if (!selectedRegionsList) {
      console.error("Cannot " + type + " jobs: No Regions selected");
      return;
    }
    searchCriteria = {
      regionId: selectedRegionsList,
    };

    return searchCriteria;
  }

  // deleted locations of the specific region

  async deleteLocations() {
    var searchCriteria = this.selectRegions("delete");
    let parameters = {};

    if (searchCriteria.regionId !== null && searchCriteria.regionId !== undefined) {
      //parameters.filters = {};
      parameters.locationIds = JSON.parse(JSON.stringify(searchCriteria.regionId));
      parameters.regionId = this.regionId;
    }

    const query = `
      mutation removeLocation ($input : RemoveLocationInput!) {
             removeLocation(input: $input) {
               region {
                 locations {
                   locationId
                 }
               }
             }
            }
    `;

    const variables = {
      input: parameters,
    };
    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Deleting selected Regions", e);
      // TODO: Display user friendly error message
      return;
    }
    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }
    this.handleStopEdit();

    runInAction("deleteLocations--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("deleteLocations", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.pagination.goFetch(null);
    });
  }

  // deleted region

  async deleteRegion() {
    let params = [];
    let filterargs = [];
    let vars = {};

    params.push("$only: [String]!");
    filterargs.push("only: $only");
    vars.only = JSON.parse(JSON.stringify(this.regionId));

    const queryParams = params.join(", ");
    // const queryArgs = filterargs.join(', ');

    const query = `
      mutation deleteRegion(${queryParams}){
       deleteRegion(input:{${filterargs},filters: {}}){
         ok

         errors {
           __typename
           ...on CannotDeleteRegion {
            message
           }
         }
       }
      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Deleting selected Regions", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }
    this.handleStopEdit();

    runInAction("deleteRegions--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("deleteRegion", res)) {
        if (res && res.data && res.data.deleteRegion.errors) {
          const errors = res.data.deleteRegion.errors;
          const cannotDeleteRegion = errors.find(
            (err) => err.__typename === "CannotDeleteRegion"
          );
          if (cannotDeleteRegion) {
            this.errorMessage = (
              <div>
                <p>
                  Could not delete region. Regions referenced by searches cannot be
                  deleted.
                </p>
              </div>
            );
            this.errorModal.showModal();
          } else {
            this.errorMessage = (
              <p>
                Sorry! Could not delete selected regions. There was an error during the
                operation.
              </p>
            );
            this.errorModal.showModal();
          }
          this.network.error = null;
        }
        return;
      }

      if (this.router) {
        this.router.push({
          pathname: `/regions/`,
          query: this.router.query,
        });
      }
    });
  }

  //rename region
  async renameRegion() {
    if (this.network.loading) {
      return;
    }

    this.messaging.removeAll();

    if (!this.newRegionName.trim()) {
      this.messaging.createMessage("info", "Please enter a New Region Name.");
      return;
    }

    const query = `
      mutation updateRegion($regionId: Int!, $name: String!){
        updateRegion(input: {name: $name, regionId: $regionId}){
          region{
            regionId,
            name,
          }
          errors{
            __typename
            ...on NoPermissionError{
              message
            }
            ...on NameAlreadyExistError{
              message
            }
            ...on TagIdsNotExistsError{
              message
            }
            ...on InvalidRegionIdError{
              message
            }
          }
        }
      }
    `;
    const variables = {
      regionId: this.regionId,
      name: this.newRegionName,
    };

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Getting Rate cards detail", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }
    // this.handleStopEdit();

    runInAction("renameRateCard--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (res.data.updateRegion.errors) {
        this.messaging.removeAll();
        this.messaging.createMessage("error", res.data.updateRegion.errors[0].message);
        return;
      }
      this.renameRegionModal.hideModal();
      if (this.network.logGraphQLError("Filter criteria query", res)) {
        // TODO: Display user friendly error message
        return;
      }
      this.regionDetail.name = res.data.updateRegion.region.name;
      //this.pagination.goFetch(null);
    });
  }

  async applyTags() {
    // let searchquery = '';
    // let parameters = {};

    let params = [];
    let filterargs = [];
    let searchargs = [];
    let args = [];
    let vars = {};

    this.noTagsMessage = null;

    const taglist = this.applyTagState.getSelectedTagList();
    if (!taglist || !taglist.length) {
      //console.error("Cannot Apply tags to Worksheets: No Worksheet selected");
      this.noTagsMessage = "Cannot Apply tags to Regions: No Region selected";
      return;
    }
    params.push("$tagIds: [Int]!");
    filterargs.push("tagIds: $tagIds");
    vars.tagIds = taglist;
    params.push("$only: [ID]");
    args.push("only: $only");
    vars.only = [this.regionId];

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
      mutation applyTags(${queryParams}){
       applyTagsToRegions(input:{${filterargs}, filters: { ${queryArgs}}, ${searchargs}}) {
         ok
          errors {
           __typename
           ... on TagIdRequiredError {
             message
           }
           ... on TagIdsNotExistsError {
             message
           }
           ... on InvalidInputError {
             message
           }
           ... on ContentDoesNotExistsError {
             message
           }
         }
       }

      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Apply Tags to Selected Worksheets", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    runInAction("applyTags--success", () => {
      this.applyTagState.tagModal.hideModal();
      this.getRegionDetail();
    });
  }

  async deleteSingleTags(content) {
    const query = `
    mutation removeTagsFromContent{
      removeTagsFromContent(input: {tagIds : [${content.tagId}],contentType :${content.contentType},contentId:${content.contentId}}){
        ok
        }
      }
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, null);
    } catch (e) {
      // TODO: Display user friendly error message
      console.log("error", e);
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }

    runInAction("deleteSingleTags--success", () => {
      // debugger
      this.regionDetail.tags.forEach((item, index) => {
        if (content.tagId === item.tagId) {
          this.regionDetail.tags.splice(index, 1);
          this.selectedTags.delete(item.tagId);
        }
      });
      this.regionDetail = new RegionList(this, this.regionDetail);
    });
  }
}
