/**
 * @generated SignedSource<<c8cab5799aa81368139bc78ad5a49070>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RecentSearchesRefetch$variables = {};
export type RecentSearchesRefetch$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"RecentSearches_viewer">;
  } | null;
};
export type RecentSearchesRefetch = {
  response: RecentSearchesRefetch$data;
  variables: RecentSearchesRefetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RecentSearchesRefetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RecentSearches_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RecentSearchesRefetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "recentSearches",
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 9
              },
              {
                "kind": "Literal",
                "name": "order",
                "value": [
                  {
                    "direction": "DESC",
                    "field": "CREATE_DATE"
                  }
                ]
              }
            ],
            "concreteType": "SaveSearchNodeConnection",
            "kind": "LinkedField",
            "name": "savedsearches",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SaveSearchNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SaveSearchNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "searchId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdDate",
                        "storageKey": null
                      },
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "rateType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "locationFullLabel",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isGlobalSupplierSearch",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "workerTypeName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "IndustryNode",
                        "kind": "LinkedField",
                        "name": "industry",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "value",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NewRegionNode",
                        "kind": "LinkedField",
                        "name": "region",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "regionId",
                            "storageKey": null
                          },
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "JobResponse",
                        "kind": "LinkedField",
                        "name": "job",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "jobTitle",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "jobLabel",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "jobTitleId",
                            "storageKey": null
                          },
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "savedsearches(first:9,order:[{\"direction\":\"DESC\",\"field\":\"CREATE_DATE\"}])"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f93a87bb5d083ffbc6c79efa490a6372",
    "id": null,
    "metadata": {},
    "name": "RecentSearchesRefetch",
    "operationKind": "query",
    "text": "query RecentSearchesRefetch {\n  viewer {\n    ...RecentSearches_viewer\n  }\n}\n\nfragment RecentSearches_viewer on Viewer {\n  recentSearches: savedsearches(first: 9, order: [{field: CREATE_DATE, direction: DESC}]) {\n    edges {\n      node {\n        id\n        searchId\n        createdDate\n        name\n        rateType\n        locationFullLabel\n        isGlobalSupplierSearch\n        workerTypeName\n        industry {\n          id\n          value\n        }\n        region {\n          name\n          regionId\n          id\n        }\n        job {\n          jobTitle\n          jobLabel\n          jobTitleId\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "798823995f6ea1d47676e46eedf0f685";

export default node;
