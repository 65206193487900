import React from "react";

import Stack from "../../../../components/lib/Stack";
import Text from "../../../../components/lib/Text";
import ParametersLine from "../../../validator5K_admin/lib/ParametersLine";
import BiggerText from "../../../validator5K_admin/lib/BiggerText";
import { useReviewDataContext } from "../../../validator5K_admin/context/ReviewDataContext";
import { REVIEW_TYPES, RATE_TYPES, RATE_TYPES_LABELS } from "../../types";
import { getLocationString, getRegionString } from "../../constants";
import Box from "../../../../components/lib/Box";
import { useRateDisplayContext } from "../../../validator5K_admin/context/RateDisplayContext";

export default function SurveyPrimaryParametersBlock() {
  const {
    reviewType,
    reviewTitle,
    firstJobData,
    firstJobRateType,
    categoriesString,
    industriesString,
    countriesString,
  } = useReviewDataContext();
  const { displayCurrencyData } = useRateDisplayContext();

  const title =
    reviewType === REVIEW_TYPES.ORDERED ? reviewTitle : firstJobData.get("title");
  const description =
    reviewType === REVIEW_TYPES.ORDERED ? null : firstJobData.get("description");

  const displayCurrencyCode = displayCurrencyData.get("code");
  const displayCurrencyName = displayCurrencyData.get("name");

  let rateMultiplier = null;
  if (firstJobRateType === RATE_TYPES.HOURLY) {
    rateMultiplier = firstJobData.get("hourly_multiplier");
  } else if (firstJobRateType === RATE_TYPES.DAILY) {
    rateMultiplier = firstJobData.get("daily_multiplier");
  } else if (firstJobRateType === RATE_TYPES.WEEKLY) {
    rateMultiplier = firstJobData.get("weekly_multiplier");
  } else if (firstJobRateType === RATE_TYPES.MONTHLY) {
    rateMultiplier = firstJobData.get("monthly_multiplier");
  }

  const locationString = React.useMemo(
    () => getLocationString(firstJobData),
    [firstJobData]
  );
  const regionString = React.useMemo(() => getRegionString(firstJobData), [firstJobData]);
  const isFirstJobRegionBased = !!firstJobData.get("region");

  return (
    <Stack fill css={{ alignItems: "stretch" }}>
      <Stack css={{ alignItems: "flex-start", gap: "$1", fontSize: "$base" }}>
        <ParametersLine>
          <Text>{reviewType === REVIEW_TYPES.ORDERED ? "Title:" : "Job Title:"}</Text>
          <Text>{title}</Text>
        </ParametersLine>
        <ParametersLine>
          <Text>Industry:</Text>
          <Text>{industriesString}</Text>
        </ParametersLine>
        {categoriesString && (
          <ParametersLine>
            <Text>Category:</Text>
            <Text>{categoriesString}</Text>
          </ParametersLine>
        )}
        {reviewType === REVIEW_TYPES.ORDERED && (
          <ParametersLine>
            <Text>Country:</Text>
            <Text>{countriesString}</Text>
          </ParametersLine>
        )}
        {reviewType !== REVIEW_TYPES.ORDERED && isFirstJobRegionBased && (
          <ParametersLine>
            <Text>Region:</Text>
            <Text>{regionString}</Text>
          </ParametersLine>
        )}
        {reviewType !== REVIEW_TYPES.ORDERED && !isFirstJobRegionBased && (
          <ParametersLine>
            <Text>Location:</Text>
            <Text>{locationString}</Text>
          </ParametersLine>
        )}
        {displayCurrencyCode && (
          <ParametersLine>
            <Text>Currency:</Text>
            <Text>{`${displayCurrencyName} (${displayCurrencyCode})`}</Text>
          </ParametersLine>
        )}
        <ParametersLine>
          <Text>Rate Type:</Text>
          <Text>
            {RATE_TYPES_LABELS[firstJobRateType]}
            {reviewType !== REVIEW_TYPES.FILL_THE_BLANK &&
              (firstJobRateType !== RATE_TYPES.ANNUAL &&
              firstJobRateType !== RATE_TYPES.HOURLY &&
              rateMultiplier != null &&
              rateMultiplier > 1
                ? ` (${rateMultiplier}h)`
                : "")}
          </Text>
        </ParametersLine>
      </Stack>

      {description && (
        <Stack css={{ alignItems: "flex-start", gap: "$2" }}>
          <BiggerText>Job Description:</BiggerText>
          <Box
            fill
            css={{
              border: "1px solid $primaryLight",
              borderRadius: "$rounded",
              padding: "$2 $4",
            }}
          >
            {description}
          </Box>
        </Stack>
      )}
    </Stack>
  );
}

SurveyPrimaryParametersBlock.displayName = "SurveyPrimaryParametersBlock";
