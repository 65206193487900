// @flow

import React, { Component } from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { QueryRenderer } from "react-relay";
import Modal from "./components/Modal";
import Loadable from "./components/Loadable";
import RateCardsList from "../ratecards/components/RateCardsList";
import InstantSearchBox from "../ratecards/components/InstantSearchBox";
import SelectableItem from "../ratecards/components/SelectableItem";
import SelectableItemsList from "../ratecards/components/SelectableItemsList";
import ContainerSection from "../ratecards/components/ContainerSection";
import SearchBox from "../../components/lib/SearchBox";
import Info from "../ratecards/components/Info";
import ExportModal from "./components/ExportModal";
import CustomBuyRatesExportModal from "./components/CustomBuyRatesExportModal";
import DraftSearchList from "../saved_searches/DraftSearchList";
import TagItem from "../ratecards/components/TagItem";
import ApplyTagFilterModal from "../shared/modals/ApplyTagFilterModal";
import RateCardDetailStore from "../../stores/mobx/RateCardDetailStore";
import SessionInfo from "../../models/SessionInfo";
import RelayExportModal from "../../components/ExportModal";
import type MobXStore from "../../stores/mobx/MobXStore";
import type { RenderProps } from "react-relay/ReactRelayQueryRenderer";
import Tooltip from "../../components/lib/Tooltip";
import Button from "../../components/lib/Button";
import Inline from "../../components/lib/Inline";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "../../components/lib/DropdownMenu";
import { CardHeaderSubTitle, CardHeaderTitle } from "../../components/lib/Card";
import LinkButton from "../../components/lib/LinkButton";
import { ModalButtons, ModalTitle } from "./components/FilterModal";
import EditActions from "../../components/lib/EditActions";
import Box from "../../components/lib/Box";
import TextInput from "../../components/lib/TextInput";
import Spacer from "../../components/lib/Spacer";
import { Environment } from "relay-runtime";

type HeaderProps = {
  store: RateCardDetailStore,
  sessionInfo: SessionInfo,
  router: Object,
};

const RateCardDetailHeader = observer((props: HeaderProps) => {
  const { store, sessionInfo } = props;
  let name,
    shared,
    owner,
    tagSection,
    createdDate = null;

  let sharedByYou = false;

  const rateCard = store.rateCard;
  const rateCardOwner =
    rateCard && rateCard.owner ? rateCard.owner : { fullname: "N/A", userId: 0 };

  if (store.network.loading) {
    name = "...";
    shared = false;
    owner = "...";
    createdDate = "...";
  } else if (rateCard) {
    name = rateCard.name;
    shared = rateCard.shared;
    owner = rateCardOwner.fullname;
    createdDate = rateCard.createdDisplay;
    const currentUser = sessionInfo.user;
    const hasOwnership = Boolean(
      currentUser && rateCardOwner.userId === currentUser.userId
    );
    sharedByYou = shared && hasOwnership;
    tagSection = rateCard.tags.map((tag) => (
      <TagItem
        key={tag.tagId}
        tag={tag}
        onClick={store.removeTag}
        hasOwnerShip={hasOwnership}
      />
    ));
  }

  let actionBar = null;

  if (!store.network.loading && !store.network.error) {
    actionBar = (
      <Inline css={{ gap: "$2" }} data-testid="header-actions">
        {store.hasOwnership && (
          <Button onClick={store.applyTagState.showTagModal}>Apply Tag</Button>
        )}
        {store.hasOwnership && (
          <Button icon="edit" onClick={store.showRenameRateCardModal}>
            Rename
          </Button>
        )}
        {store.hasOwnership && localStorage.getItem("shareRateCards") !== "false" && (
          <Button icon="share" onClick={store.share}>
            Share
          </Button>
        )}
        {store.hasOwnership && (
          <Button icon="sync" onClick={store.confirmUpdateRatesOnRateCardModal.showModal}>
            Update Rates
          </Button>
        )}
        {store.hasOwnership && (
          <Button
            icon="undo"
            onClick={store.confirmUndoUpdateRatesOnRateCardModal.showModal}
          >
            Undo Last Update
          </Button>
        )}
        {store.canExport() && (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button icon="download" text="Export ▼" disabled={!store.canExport()} />
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem
                text="All Searches"
                onClick={store.exportRateCardModal.modalState.showModal}
              />
              {store.hasOnlyHourlySearches && (
                <DropdownMenuItem
                  text="Custom Buy Rates"
                  onClick={store.customExportRateCardModal.modalState.showModal}
                />
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        )}
        {/*<Button disabled={true} className="btn" onClick={store.viewRatecardInMap}>*/}
        {/*<FontAwesomeIcon icon="map-marker" fixedWidth className="icon" /> View in Map*/}
        {/*</Button>*/}
        {store.visualizeViewActive ? (
          <Button icon="list" onClick={store.goToDetails}>
            Details
          </Button>
        ) : (
          <Button icon="chart-column" onClick={store.goToVisualize}>
            Visualize
          </Button>
        )}
        {store.hasOwnership && (
          <Button
            icon="trash-alt"
            color="danger"
            onClick={store.confirmDeleteRateCardModal.showModal}
          >
            Delete
          </Button>
        )}
      </Inline>
    );
  }

  return (
    <div className="top">
      <div className="above">
        <div className="ratecard-label-header ellipsis max-width-80">
          <CardHeaderTitle>{name}</CardHeaderTitle>
          {shared && sharedByYou && (
            <Tooltip side="left" content="Shared...">
              <span className="tag text-small align-top">
                <FontAwesomeIcon icon="share" fixedWidth className="icon" />
                By You
              </span>
            </Tooltip>
          )}
          {shared && !sharedByYou && (
            <Tooltip side="left" content="Shared...">
              <span className="tag text-small align-top">
                <FontAwesomeIcon
                  icon="share"
                  fixedWidth
                  flip="horizontal"
                  className="icon"
                />
                To You
              </span>
            </Tooltip>
          )}
        </div>
        <Inline css={{ gap: "$2" }}>
          <LinkButton icon="arrow-left" text="Go Back" color="brand" to={`/ratecards/`} />
          <Button icon="question-circle" text="Help" onClick={store.showHelp} />
        </Inline>
      </div>
      <div className="below">
        <div className="ratecard-meta ellipsis">
          <CardHeaderSubTitle>
            <FontAwesomeIcon icon="user" fixedWidth className="icon" /> {owner}
          </CardHeaderSubTitle>
          <CardHeaderSubTitle>
            <FontAwesomeIcon icon="calendar-alt" fixedWidth className="icon" />{" "}
            {createdDate}
          </CardHeaderSubTitle>
        </div>
        {actionBar}
      </div>
      <div className="tagbox mt-32" data-testid="applied-tags">
        {tagSection}
      </div>
    </div>
  );
});

const MoveSearchesModal = observer((props) => {
  let store = props.store;

  return (
    <Modal
      show={store.moveSearchesModal.show}
      onHide={store.moveSearchesModal.hideModal}
      innerStyle={{
        width: "90vw",
      }}
    >
      <div className="container-section header">
        <ModalTitle>Choose Rate Card to move searches to</ModalTitle>
      </div>
      <div className="rc-viewport overlap-lr-padding">
        <div className="header-action-bar">
          <SearchBox
            value={store.rateCardsListStore.rateCardLabelFilter.textToLookFor}
            onChange={store.rateCardsListStore.rateCardLabelFilter.onTextToLookForChange}
            onSubmit={store.rateCardsListStore.rateCardLabelFilter.onApply}
            style={{ width: "260px" }}
          />
        </div>
        <div className="overlap-lr-padding">
          <RateCardsList store={store.rateCardsListStore} />
        </div>
      </div>
      <ContainerSection className="footer">
        <ModalButtons>
          <Button color="brand" size="large" onClick={store.confirmMoveSearches}>
            Move
          </Button>
          <Button size="large" onClick={store.moveSearchesModal.hideModal}>
            Cancel
          </Button>
        </ModalButtons>
      </ContainerSection>
    </Modal>
  );
});

const AddSearchesModal = observer((props) => {
  let modal = props.store.addSearchesModal;
  let store = props.store;
  let draftSearchStore = props.draftSearchStore;
  let draftsSavedSearchListComponentStore = props.draftsSavedSearchListComponentStore;
  draftsSavedSearchListComponentStore.clearAllSelections();

  return (
    <Modal show={modal.show} onHide={modal.hideModal} innerStyle={{ width: "90vw" }}>
      <div className="container-section header">
        <ModalTitle>Available Searches</ModalTitle>
      </div>
      <div className="rc-viewport overlap-lr-padding">
        <div className="overlap-lr-padding">
          <DraftSearchList store={draftSearchStore} />
        </div>
      </div>

      <ContainerSection className="footer">
        <ModalButtons>
          <Button
            size="large"
            color="brand"
            onClick={() => {
              store.onAddSearchesClick(
                draftSearchStore.draftsSavedSearchListStore.rateCardsView,
                draftSearchStore.draftsSavedSearchListStore
              );
            }}
          >
            Add Selected Searches
          </Button>
          <Button
            size="large"
            onClick={() => {
              draftsSavedSearchListComponentStore.clearAllSelections();
              modal.hideModal();
            }}
          >
            Cancel
          </Button>
        </ModalButtons>
      </ContainerSection>
    </Modal>
  );
});

const ShareWithUsersList = observer((props) => {
  return (
    <div>
      <SelectableItemsList>
        {props.filterState.shareUsersView.map((user, i) => (
          <SelectableItem
            key={user.username}
            item={user}
            name="share-user"
            value={user.username}
            selected={user.selected}
            onChange={props.filterState.setSharedUserSelectedValue}
          >
            <div>
              {user.firstName} {user.lastName}
            </div>
            <div className="text-x-small">
              <em>
                <FontAwesomeIcon icon="user" className="icon no-margin" /> {user.username}
              </em>
            </div>
          </SelectableItem>
        ))}
      </SelectableItemsList>
      <ContainerSection className="info">
        <p className="text-muted text-x-small no-margin pull-left">
          <FontAwesomeIcon icon="info-circle" fixedWidth />{" "}
          <em>Stop sharing by leaving all Users unselected.</em>
        </p>
        <p className="text-muted text-x-small no-margin no-border pull-right">
          <em>
            <strong>{props.filterState.shareUsersSelectedCount} </strong>
            selected, <strong>{props.filterState.shareUsersTotalCount} </strong>
            total
          </em>
        </p>
        <div className="clearfix" />
      </ContainerSection>
    </div>
  );
});

type Props = {
  relayEnvironment: Environment,
  router: Object,
  store: MobXStore,
  sessionInfo: SessionInfo,
  children: any,
};

/**
 * RateCardDetail component for rate card detail page
 */
class Detail extends Component<Props> {
  /**
   * componentDidMount
   * @return {void}
   */
  componentDidMount() {
    const detailStore = this.props.store.rateCardDetailStore;
    detailStore.rateCardId = parseInt(this.props.router.params.rateCardId);
    if (!detailStore.network.loading) {
      detailStore.router = this.props.router;
      detailStore.resetView(); // Resets currency, filters, sorts and fetches RC

      // this.props.store.rateCardDetailStore.softResetFilters();
      // this.props.store.rateCardDetailStore.applyDefaultSort();
      // this.props.store.rateCardDetailStore.pagination.goFetch();

      // reset to null so some elements visible while editing start hidden
      detailStore.isEditing = null;
    }
  }

  /**
   * Render rate card detail page
   * @return {XML}
   */
  render() {
    const store = this.props.store.rateCardDetailStore;
    const rateSearchStore = this.props.store.rateSearchStore;
    //    const rateSearchStore = this.props.store.rateSearchStore;
    //    const savedSearchStore = this.props.store.savedSearchesListStore;
    const draftSearchStore = this.props.store;
    const draftsSavedSearchListStore = this.props.store.draftsSavedSearchListStore;
    const globalStore = this.props.store.globalStateStore;

    const showModal = store.showRelayExportModal;
    const exportId = store.relayExportId;

    return (
      <div className="view">
        <div className="rc-container show-overflow">
          <RateCardDetailHeader
            store={store}
            router={this.props.router}
            sessionInfo={this.props.sessionInfo}
          />
        </div>

        {this.props.children}

        <EditActions hidden={store.isEditing === null} show={Boolean(store.isEditing)}>
          {store.hasOwnership && (
            <Button
              icon="sync"
              disabled={store.selectedCount === 0}
              onClick={store.confirmRefreshSearchesModal.showModal}
            >
              Update Rates
            </Button>
          )}
          {store.hasOwnership && (
            <Button
              icon="undo"
              disabled={store.selectedCount === 0}
              onClick={store.confirmUndoLastUpdateSearchesModal.showModal}
            >
              Undo Last Update
            </Button>
          )}
          {store.hasOwnership && (
            <Button icon="plus" onClick={store.addSearches}>
              Add Searches
            </Button>
          )}
          {store.hasOwnership && (
            <Button
              icon="minus"
              disabled={store.selectedCount === 0}
              onClick={store.confirmRemoveSearchesModal.showModal}
            >
              Remove Searches
            </Button>
          )}
          {store.hasOwnership && (
            <Button
              icon="folder"
              disabled={store.selectedCount === 0}
              onClick={store.onMoveSearchesClick}
            >
              Move
            </Button>
          )}
          {store.currentUser &&
            store.currentUser.permissions.allowExportRateCardsData && (
              <Button
                icon="download"
                disabled={store.selectedCount === 0}
                onClick={store.exportSearchesModal.modalState.showModal}
              >
                Export
              </Button>
            )}
          {/*<Button disabled={true} className="btn" onClick={store.viewSearchesInMap}>*/}
          {/*  <FontAwesomeIcon icon="map-marker" fixedWidth className="icon" /> View in Map*/}
          {/*</Button>*/}
          {store.hasOwnership && (
            <Button
              disabled={store.selectedCount === 0}
              onClick={store.applyTagSearchesState.showTagModal}
            >
              Apply Tag
            </Button>
          )}
          {store.hasOwnership && (
            <Button
              icon="trash-alt"
              data-testid="delete-search"
              color="danger"
              disabled={store.selectedCount === 0}
              onClick={store.confirmDeleteSearchesModal.showModal}
            >
              Delete
            </Button>
          )}
        </EditActions>

        <Modal show={store.showHelpModal} onHide={store.hideHelp}>
          <div className="container-section header">
            <ModalTitle>Rate Card Detail Help</ModalTitle>
          </div>
          <div className="container-section footer">
            <p>
              This page contains the searches you have saved to your Rate Card. On the
              top-left is the Rate Card Name that was chosen, along with the date it was
              created. Click on a search in the list to "Expand" it and show a preview of
              the results for that job. <b>Expand/Collapse All</b> allow you to see all
              search results at a glance.
            </p>
            <ModalTitle>Edit Mode</ModalTitle>
            <Box css={{ height: "$2" }} />
            <p>
              Perform bulk editing, sharing, export, and updating on your searches using
              the <b>Edit</b> Button. The
              <b> Edit</b> mode and <b>Share</b> Button will also allow you to share this
              rate card among your company users. To export the entire rate card to
              Excel/CSV formats, click <b>Export</b> or click <b>Edit</b> and then select
              the searches you would like to export.
            </p>
          </div>
        </Modal>
        <Modal
          show={store.confirmDeleteRateCardModal.show}
          onHide={store.confirmDeleteRateCardModal.hideModal}
        >
          <div className="container-section header">
            <ModalTitle>Confirmation Needed</ModalTitle>
          </div>
          <div className="container-section">
            <p>Are you sure you want to delete this Rate Card?</p>
            <p className="text-danger">
              <FontAwesomeIcon icon="exclamation-triangle" fixedWidth />{" "}
              <em>This will also delete all Searches in this Rate Card.</em>
            </p>
            <p className="text-danger">
              <FontAwesomeIcon icon="exclamation-triangle" fixedWidth />{" "}
              <em>This action cannot be undone!</em>
            </p>
          </div>
          <div className="container-section footer">
            <ModalButtons>
              <Button size="large" color="danger" onClick={store.deleteRatecard}>
                Yes, Delete Rate Card
              </Button>
              <Button size="large" onClick={store.confirmDeleteRateCardModal.hideModal}>
                No, Cancel
              </Button>
            </ModalButtons>
          </div>
        </Modal>
        <Modal
          show={store.confirmUpdateRatesOnRateCardModal.show}
          onHide={store.confirmUpdateRatesOnRateCardModal.hideModal}
        >
          <div className="container-section header">
            <ModalTitle>Confirmation Needed</ModalTitle>
          </div>
          <div className="container-section">
            <p>
              Are you sure you want to update the rates of all Searches in this Rate Card?
            </p>
          </div>
          <div className="container-section footer">
            <ModalButtons>
              <Button size="large" color="brand" onClick={store.refreshRateCards}>
                Yes, Update Rate Card
              </Button>
              <Button
                size="large"
                onClick={store.confirmUpdateRatesOnRateCardModal.hideModal}
              >
                No, Cancel
              </Button>
            </ModalButtons>
          </div>
        </Modal>
        <Modal
          show={store.confirmUndoUpdateRatesOnRateCardModal.show}
          onHide={store.confirmUndoUpdateRatesOnRateCardModal.hideModal}
        >
          <div className="container-section header">
            <ModalTitle>Confirmation Needed</ModalTitle>
          </div>
          <div className="container-section">
            <p>
              Are you sure you want to undo the last
              <em>"Update Rates"</em>
              action for all Searches in this Rate Card?
            </p>
            <Info icon="info-circle" iconClass="icon text-muted text-x-small">
              <p className="text-muted text-x-small">
                <em>
                  This action is only effective once after each "Update Rates" action.
                  Searches that have already been reverted to it's previous state will not
                  be affected.
                </em>
              </p>
            </Info>
          </div>
          <div className="container-section footer">
            <ModalButtons>
              <Button size="large" color="brand" onClick={store.undoLastUpdateRateCard}>
                Yes, Undo Last Update
              </Button>
              <Button
                size="large"
                onClick={store.confirmUndoUpdateRatesOnRateCardModal.hideModal}
              >
                No, Cancel
              </Button>
            </ModalButtons>
          </div>
        </Modal>
        <Modal
          show={store.confirmUpdateRatesOnSearchModal.show}
          onHide={store.confirmUpdateRatesOnSearchModal.hideModal}
        >
          <div className="container-section header">
            <ModalTitle>Confirmation Needed</ModalTitle>
          </div>
          <div className="container-section">
            <p>Are you sure you want to update the rates on this Search?</p>
          </div>
          <div className="container-section footer">
            <ModalButtons>
              <Button
                size="large"
                color="brand"
                onClick={() => {
                  store.confirmUpdateRatesOnSearchModal.search.updateRates(true);
                  store.confirmUpdateRatesOnSearchModal.hideModal();
                }}
              >
                Yes, Update Search
              </Button>
              <Button
                size="large"
                onClick={store.confirmUpdateRatesOnSearchModal.hideModal}
              >
                No, Cancel
              </Button>
            </ModalButtons>
          </div>
        </Modal>
        <Modal
          show={store.confirmUndoUpdateRatesOnSearchModal.show}
          onHide={store.confirmUndoUpdateRatesOnSearchModal.hideModal}
        >
          <div className="container-section header">
            <ModalTitle>Confirmation Needed</ModalTitle>
          </div>
          <div className="container-section">
            <p>
              Are you sure you want to undo the last <em>"Update Rates"</em> action on
              this Search?
            </p>
          </div>
          <div className="container-section footer">
            <ModalButtons>
              <Button
                size="large"
                color="brand"
                onClick={() => {
                  store.confirmUndoUpdateRatesOnSearchModal.search.undoUpdateRates(true);
                  store.confirmUndoUpdateRatesOnSearchModal.hideModal();
                }}
              >
                Yes, Undo Last Update
              </Button>
              <Button
                size="large"
                onClick={store.confirmUndoUpdateRatesOnSearchModal.hideModal}
              >
                No, Cancel
              </Button>
            </ModalButtons>
          </div>
        </Modal>
        <Modal
          show={store.confirmRemoveSearchesModal.show}
          onHide={store.confirmRemoveSearchesModal.hideModal}
        >
          <div className="container-section header">
            <ModalTitle>Confirmation Needed</ModalTitle>
          </div>
          <div className="container-section">
            <p>Are you sure you want to remove these Searches?</p>
            <p className="text-muted text-x-small">
              <FontAwesomeIcon icon="info-circle" fixedWidth />{" "}
              <em>Removed Searches can be added to another Rate Card later.</em>
            </p>
          </div>
          <div className="container-section footer">
            <ModalButtons>
              <Button
                size="large"
                color="brand"
                onClick={() => {
                  store.confirmRemoveSearchesModal.hideModal();
                  store.removeSearches();
                }}
              >
                Yes, Remove Them
              </Button>
              <Button size="large" onClick={store.confirmRemoveSearchesModal.hideModal}>
                No, Cancel
              </Button>
            </ModalButtons>
          </div>
        </Modal>
        <Modal
          show={store.confirmDeleteSearchesModal.show}
          onHide={store.confirmDeleteSearchesModal.hideModal}
        >
          <div className="container-section header">
            <ModalTitle>Confirmation Needed</ModalTitle>
          </div>
          <div className="container-section">
            <p>Are you sure you want to delete these Searches?</p>
            <p className="text-danger">
              <FontAwesomeIcon icon="exclamation-triangle" />{" "}
              <em>This action cannot be undone!</em>
            </p>
          </div>
          <div className="container-section footer">
            <ModalButtons>
              <Button
                size="large"
                color="danger"
                onClick={() => {
                  store.confirmDeleteSearchesModal.hideModal();
                  store.deleteSearches();
                }}
              >
                Yes, Delete Them
              </Button>
              <Button size="large" onClick={store.confirmDeleteSearchesModal.hideModal}>
                No, Cancel
              </Button>
            </ModalButtons>
          </div>
        </Modal>
        <Modal
          show={store.confirmRefreshSearchesModal.show}
          onHide={store.confirmRefreshSearchesModal.hideModal}
        >
          <div className="container-section header">
            <ModalTitle>Confirmation Needed</ModalTitle>
          </div>
          <div className="container-section">
            <p>Are you sure you want to update the rates on the selected Searches?</p>
          </div>
          <div className="container-section footer">
            <ModalButtons>
              <Button size="large" color="brand" onClick={store.refreshSearches}>
                Yes, Update Searches
              </Button>
              <Button size="large" onClick={store.confirmRefreshSearchesModal.hideModal}>
                No, Cancel
              </Button>
            </ModalButtons>
          </div>
        </Modal>
        <Modal
          show={store.confirmUndoLastUpdateSearchesModal.show}
          onHide={store.confirmUndoLastUpdateSearchesModal.hideModal}
        >
          <div className="container-section header">
            <ModalTitle>Confirmation Needed</ModalTitle>
          </div>
          <div className="container-section">
            <p>
              Are you sure you want to undo the last <em>"Update Rates"</em> action on
              these Searches?
            </p>
            <Info icon="info-circle" iconClass="icon text-muted text-x-small">
              <p className="text-muted text-x-small">
                <em>
                  This action is only effective once after each "Update Rates" action.
                  Searches that have already been reverted to it's previous state will not
                  be affected.
                </em>
              </p>
            </Info>
          </div>
          <div className="container-section footer">
            <ModalButtons>
              <Button size="large" color="brand" onClick={store.undoLastUpdateSearches}>
                Yes, Undo Last Update
              </Button>
              <Button
                size="large"
                onClick={store.confirmUndoLastUpdateSearchesModal.hideModal}
              >
                No, Cancel
              </Button>
            </ModalButtons>
          </div>
        </Modal>
        <Modal
          show={globalStore.customMultipliersModal.show}
          onHide={globalStore.onCancelCustomMultipliers}
        >
          <ContainerSection className="header">
            <ModalTitle>Custom Multipliers</ModalTitle>
          </ContainerSection>
          <ContainerSection>
            <label>Change multipliers for daily, weekly and monthly rates.</label>
          </ContainerSection>
          <ContainerSection>
            <div className="flex center-items">
              <div style={{ width: 90 }}>
                <label>Daily:</label>
              </div>
              <div style={{ flex: "1 1 auto" }}>
                <TextInput
                  fill
                  color={globalStore.customDailyMultiplierInvalid ? "danger" : "primary"}
                  value={globalStore.customDailyMultiplier}
                  onChange={globalStore.onCustomDailyMultiplierChange}
                />
              </div>
            </div>
          </ContainerSection>
          <ContainerSection>
            <div className="flex center-items">
              <div style={{ width: 90 }}>
                <label>Weekly:</label>
              </div>
              <div style={{ flex: "1 1 auto" }}>
                <TextInput
                  fill
                  color={globalStore.customWeeklyMultiplierInvalid ? "danger" : "primary"}
                  value={globalStore.customWeeklyMultiplier}
                  onChange={globalStore.onCustomWeeklyMultiplierChange}
                />
              </div>
            </div>
          </ContainerSection>
          <ContainerSection>
            <div className="flex center-items">
              <div style={{ width: 90 }}>
                <label>Monthly:</label>
              </div>
              <div style={{ flex: "1 1 auto" }}>
                <TextInput
                  fill
                  color={
                    globalStore.customMonthlyMultiplierInvalid ? "danger" : "primary"
                  }
                  value={globalStore.customMonthlyMultiplier}
                  onChange={globalStore.onCustomMonthlyMultiplierChange}
                />
              </div>
            </div>
          </ContainerSection>
          <ContainerSection className="footer">
            <ModalButtons>
              <Button
                size="large"
                color="brand"
                onClick={globalStore.onApplyCustomMultipliers}
              >
                Apply
              </Button>
              <Button size="large" onClick={globalStore.onResetCustomMultipliers}>
                Reset
              </Button>
              <Button size="large" onClick={globalStore.onCancelCustomMultipliers}>
                Cancel
              </Button>
            </ModalButtons>
          </ContainerSection>
        </Modal>
        <Modal
          show={store.confirmMoveSearchesModal.show}
          onHide={store.confirmMoveSearchesModal.hideModal}
        >
          <div className="container-section header">
            <ModalTitle>Confirmation Needed</ModalTitle>
          </div>
          <div className="container-section">
            <p>Are you sure you want to move these searches?</p>
          </div>
          <div className="container-section footer">
            <div className="pull-right">
              <Button size="large" color="brand" onClick={store.performMoveSearches}>
                Yes, Move Them
              </Button>
              <Button size="large" onClick={store.confirmMoveSearchesModal.hideModal}>
                No, Cancel
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          show={store.shareModal.show}
          onHide={store.shareModal.hideModal}
          innerStyle={{ width: 550 }}
        >
          <ContainerSection className="header">
            <h4>Share Rate Card</h4>
          </ContainerSection>

          <Loadable isLoading={store.networkShareUsers.loading}>
            <ContainerSection className="container-section no-border">
              <p>Choose Users to share with:</p>
              <Inline css={{ gap: "$2" }}>
                <InstantSearchBox
                  onSearch={store.shareUsersOnInstantSearch}
                  value={store.shareUsersInstantSearchValue}
                />
                <Spacer />
                <Button onClick={store.shareUsersOnSelectAll}>Select All</Button>
                <Button onClick={store.shareUsersOnDeselectAll}>Deselect All</Button>
              </Inline>
            </ContainerSection>
            <ShareWithUsersList filterState={store} />
            <ContainerSection className="footer">
              <ModalButtons>
                <Button size="large" color="brand" onClick={store.performShare}>
                  Share With Selected Users
                </Button>
                <Button size="large" onClick={store.shareModal.hideModal}>
                  Cancel
                </Button>
              </ModalButtons>
            </ContainerSection>
          </Loadable>
        </Modal>
        <Modal
          show={store.renameRateCardModal.show}
          onHide={store.renameRateCardModal.hideModal}
        >
          <ContainerSection className="header">
            <ModalTitle>Rename Rate Card</ModalTitle>
          </ContainerSection>
          <ContainerSection>
            <p>Name of the Rate Card:</p>
            <TextInput
              css={{
                width: "100%",
                maxWidth: 350,
              }}
              value={store.newRateCardName}
              onChange={store.onNewRateCardNameChange}
            />
            <div className="pt-user-messages">
              {store.messaging.messages.length > 0 && (
                <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                  <a
                    href="#close"
                    className="close"
                    data-dismiss="alert"
                    aria-label="close"
                    onClick={store.messaging.removeMessage.bind(
                      store,
                      store.messaging.messages[0]
                    )}
                  >
                    &times;
                  </a>
                  <strong>{store.messaging.messages[0].messageLabel}</strong>
                  {store.messaging.messages[0].message}
                </div>
              )}
            </div>
          </ContainerSection>
          <ContainerSection className="footer">
            <ModalButtons>
              <Button size="large" color="brand" onClick={store.renameRateCard}>
                Rename Rate Card
              </Button>
              <Button
                size="large"
                onClick={() => {
                  store.messaging.removeAll();
                  store.renameRateCardModal.hideModal();
                }}
              >
                Cancel
              </Button>
            </ModalButtons>
          </ContainerSection>
        </Modal>
        <MoveSearchesModal store={store} />
        <AddSearchesModal
          store={store}
          rateSearchStore={rateSearchStore}
          draftSearchStore={draftSearchStore}
          draftsSavedSearchListComponentStore={draftsSavedSearchListStore}
        />
        <ExportModal exportState={store.exportRateCardModal} />
        <ExportModal exportState={store.exportSearchesModal} />
        <CustomBuyRatesExportModal
          exportState={store.customExportRateCardModal}
          searchesHaveAllLevels={
            store.rateCard ? store.rateCard.searchesHaveAllLevels : true
          }
          threeLevelsViewUser={store.threeLevelsViewUser}
        />
        <ApplyTagFilterModal
          filterState={store.applyTagState}
          applyTagsEvent={store.applyTags}
          btnText="Apply Tag to Rate Cards"
        />
        <ApplyTagFilterModal
          filterState={store.applyTagSearchesState}
          applyTagsEvent={store.applyTagsToSearches}
          btnText="Apply Tag to Searches"
        />
        <QueryRenderer
          environment={this.props.relayEnvironment}
          query={null}
          variables={{}}
          render={({ error, props }: RenderProps<{}>): any => {
            if (error) {
              console.error(error);
              return <div>Error!</div>;
            }

            return exportId === null && !showModal ? null : (
              <RelayExportModal
                relayEnvironment={this.props.relayEnvironment}
                exportId={exportId}
                onHide={() => {
                  store.relayExportId = null;
                  store.showRelayExportModal = false;
                }}
                show={showModal}
              />
            );
          }}
        />
        <ExportModal modalState={store.errorModal} errorMessage={store.errorMessage} />
      </div>
    );
  }
}

export default observer(Detail);
