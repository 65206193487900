/**
 * @generated SignedSource<<3b901db256a52615d356830653b42fe8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SubmitRateSearch_user$data = {
  readonly client: {
    readonly legacyClient: {
      readonly perSearchPricing: boolean;
    } | null;
  } | null;
  readonly resourceAllocations: ReadonlyArray<{
    readonly balance: number;
    readonly id: string;
    readonly isUnlimited: boolean | null;
  } | null> | null;
  readonly " $fragmentType": "SubmitRateSearch_user";
};
export type SubmitRateSearch_user$key = {
  readonly " $data"?: SubmitRateSearch_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SubmitRateSearch_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubmitRateSearch_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Client",
      "kind": "LinkedField",
      "name": "client",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LegacyClientAllFieldsNode",
          "kind": "LinkedField",
          "name": "legacyClient",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "perSearchPricing",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "resourceType",
          "value": "SEARCH"
        }
      ],
      "concreteType": "TrapsResourceAllocation",
      "kind": "LinkedField",
      "name": "resourceAllocations",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "balance",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isUnlimited",
          "storageKey": null
        }
      ],
      "storageKey": "resourceAllocations(resourceType:\"SEARCH\")"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "d3d4afa796141db1a031f53d12561e92";

export default node;
