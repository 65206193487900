/**
 * @generated SignedSource<<4521cb25909b3cdc9416ac5ac573e4e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobTitleSearchResults_savesearch$data = {
  readonly savedsearch: {
    readonly id: string;
    readonly industry: {
      readonly id: string;
      readonly legacyId: number;
      readonly value: string;
    } | null;
    readonly isGlobalSupplierSearch: boolean | null;
    readonly job: {
      readonly collectionId: number | null;
      readonly id: string;
      readonly jobLabel: string;
      readonly jobTitle: string | null;
      readonly jobTitleId: number | null;
    };
    readonly jobDescription: string | null;
    readonly locationFullLabel: string | null;
    readonly rateCard: {
      readonly legacyId: number;
      readonly name: string | null;
    } | null;
    readonly rateType: number | null;
    readonly region: {
      readonly name: string;
      readonly regionId: number;
    } | null;
    readonly searchId: number | null;
    readonly workerTypeId: number | null;
    readonly workerTypeName: string | null;
  } | null;
  readonly " $fragmentType": "JobTitleSearchResults_savesearch";
};
export type JobTitleSearchResults_savesearch$key = {
  readonly " $data"?: JobTitleSearchResults_savesearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobTitleSearchResults_savesearch">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "legacyId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobTitleSearchResults_savesearch",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "searchId"
        }
      ],
      "concreteType": "SaveSearchNode",
      "kind": "LinkedField",
      "name": "savedsearch",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "searchId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isGlobalSupplierSearch",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "JobResponse",
          "kind": "LinkedField",
          "name": "job",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "jobTitleId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "jobTitle",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "jobLabel",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "collectionId",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jobDescription",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "locationFullLabel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "IndustryNode",
          "kind": "LinkedField",
          "name": "industry",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "NewRegionNode",
          "kind": "LinkedField",
          "name": "region",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "regionId",
                "storageKey": null
              },
              "action": "NONE",
              "path": "savedsearch.region.regionId"
            },
            {
              "kind": "RequiredField",
              "field": (v2/*: any*/),
              "action": "NONE",
              "path": "savedsearch.region.name"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rateType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workerTypeId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workerTypeName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RateCardNode",
          "kind": "LinkedField",
          "name": "rateCard",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "09e533af12b48ff547ef6fa71b2fe884";

export default node;
