// @flow
import { extendObservable, action } from "mobx";
import FilterObject, { ValueSetFilter, FilterWithSort, IViewItem } from "../Filter";
import type { FilterColumn } from "../Filter";
import Sort from "../Sort";
import SortState from "../SortState";

class SharedCriterionItem implements IViewItem {
  id: string;
  value: string;
  selected: boolean;
  shared: boolean;

  constructor(object: Object) {
    this.id = object.id;
    this.value = object.value;
    this.shared = object.shared;

    extendObservable(this, {
      selected: object.selected,
    });
  }
}

// $FlowFixMe: Need to make the superclass properties covariant to subtype
export default class SharedFilter extends ValueSetFilter(FilterWithSort) {
  constructor(
    store: Object,
    column: FilterColumn,
    applyFilter: (FilterColumn, FilterObject) => void,
    applySort: (FilterColumn, Sort) => void,
    removeFilter: (FilterColumn) => void,
    removeSort: (FilterColumn) => void
  ) {
    super(store, column);

    this.allowMultipleItemSelection = false; // only one item selected at a time

    this.applyFilter = applyFilter;
    this.removeFilter = removeFilter;
    // $FlowFixMe: Need to type these properties as invariant
    this.applySort = applySort;
    // $FlowFixMe: Need to type these properties as invariant
    this.removeSort = removeSort;

    this.sortState = new SortState("SHARE");

    this.viewItemClass = SharedCriterionItem;

    // create criteria and view items
    this.criteria.set("1", { id: "1", value: "Shared", shared: true });
    this.criteria.set("2", { id: "2", value: "Not Shared", shared: false });

    extendObservable(this, {
      viewItems: this.buildViewItems(this.criteria),
    });

    // $FlowFixMe: Need to type these properties as invariant
    this.buildQueryFilter = action(this.buildQueryFilter.bind(this));
  }

  buildQueryFilter() {
    const sharedSelectedValues = this.viewItems
      .filter((item) => item.selected)
      // $FlowFixMe: Suppress until we have good types on buildViewItems
      .map((item) => item.shared);

    if (!sharedSelectedValues.length) {
      // no value selected, just return
      return null;
    }

    if (sharedSelectedValues.length > 1) {
      // NOTE: Both Yes/No selected, just return
      console.error("Unable to build Rate Type filter: More than one value selected");
      return null;
    }

    const shared = sharedSelectedValues[0];

    if (this.filter && this.filter.queryVars.shared === shared) return this.filter; // no change

    return new FilterObject("$isShared: Boolean!", "isShared: $isShared", {
      isShared: shared,
    });
  }
}
