/**
 * @generated SignedSource<<b4f0860df6617f72559b57951aa0a932>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeadingForm_user$data = {
  readonly resourceAllocations: ReadonlyArray<{
    readonly balance: number;
    readonly id: string;
    readonly isUnlimited: boolean | null;
  } | null> | null;
  readonly " $fragmentType": "HeadingForm_user";
};
export type HeadingForm_user$key = {
  readonly " $data"?: HeadingForm_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeadingForm_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeadingForm_user",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "resourceType",
          "value": "SEARCH"
        }
      ],
      "concreteType": "TrapsResourceAllocation",
      "kind": "LinkedField",
      "name": "resourceAllocations",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "balance",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isUnlimited",
          "storageKey": null
        }
      ],
      "storageKey": "resourceAllocations(resourceType:\"SEARCH\")"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "59e4ab8cde91ade299ff5fafc5d01755";

export default node;
