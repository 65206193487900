import React from "react";

import Table, { TableProps, useTableSchemaState } from "./Table";
import { useEditCancelEffect } from "./TableFilterable";
import TableImpl from "./impl/TableImpl";
import { TableBody, TableBodyEditable } from "./parts/TableBody";
import { TableGroups } from "./parts/TableGroups";
import { TableHeadFilterableRestful } from "./parts/TableHead";
import {
  emptyOrderedMap,
  djangoOrderingKey,
  djangoPaginationKey,
  djangoPaginationSizeKey,
  defaultPageNumber,
  defaultPageCapacity,
  djangoOperationSeparator,
  emptyFilter,
} from "./constants";
import { TablePagination } from "./parts/TablePagination";
import { getChangingPageNumber, isNumber } from "./utils";
import {
  OrderDirections,
  FilterTypes,
  FilterConfigMap,
  FiltersQueryOrderedMap,
  FiltersConfigOrderedMap,
  ColumnsFiltersQueriesOrderedMap,
  RestfulTableData,
  DataProviderRestful,
  FiltersDataProviderRestful,
  OnChangePageFunction,
  OnChangeItemsPerPageFunction,
  OnChangeDataFunction,
  OnApplyFilterFunction,
  OnClearFiltersFunction,
  RestfulTableDataStateObject,
  RowEditorComponent,
  RowData,
  OrderDirectionsType,
} from "./types";

function transformFilter(key: string, filter: FilterConfigMap): FiltersQueryOrderedMap {
  const filterType = filter.getIn(["filter", "type"]);
  const filterValues = filter.getIn(["filter", "values"]).toList();
  let queryArgs = emptyOrderedMap as FiltersQueryOrderedMap;

  // dates filter
  if (filterType === FilterTypes.DATES_RANGE) {
    const from = filterValues.get(0);
    const to = filterValues.get(1);

    if (from) {
      queryArgs = queryArgs.set([key, "gte"].join(djangoOperationSeparator), from);
    }
    if (to) {
      queryArgs = queryArgs.set([key, "lte"].join(djangoOperationSeparator), to);
    }
    // numbers filter
  } else if (filterType === FilterTypes.NUMBERS_RANGE) {
    let from = filterValues.get(0);
    let to = filterValues.get(1);

    from = isNumber(from) ? parseFloat(from) : from;
    to = isNumber(to) ? parseFloat(to) : to;

    if (isNumber(from)) {
      queryArgs = queryArgs.set([key, "gte"].join(djangoOperationSeparator), from);
    }
    if (isNumber(to)) {
      queryArgs = queryArgs.set([key, "lte"].join(djangoOperationSeparator), to);
    }
    // values filter
  } else if (
    filterType === FilterTypes.VALUES_CHECKLIST ||
    filterType === FilterTypes.ENUMERATION
  ) {
    queryArgs = queryArgs.set([key, "in"].join(djangoOperationSeparator), filterValues);
  } else if (
    filterType === FilterTypes.VALUES_ICONTAINS &&
    filterValues.size === 1 &&
    filterValues.get(0) != null
  ) {
    const query = filterValues.get(0);

    queryArgs = queryArgs.set(
      [key, "icontains"].join(djangoOperationSeparator),
      query.toLowerCase()
    );
  }

  return queryArgs;
}

/**
 * transform filters internal representation to restful query args
 */
export function transformFilters(
  filters: FiltersConfigOrderedMap,
  multimode: boolean = false
): [FiltersQueryOrderedMap, ColumnsFiltersQueriesOrderedMap] {
  let allFiltersQuery = emptyOrderedMap as FiltersQueryOrderedMap;
  let columnsFiltersQueries = emptyOrderedMap as ColumnsFiltersQueriesOrderedMap;

  if (filters.size) {
    const filteredKeys = filters.keySeq();

    // transform filters
    allFiltersQuery = filters.reduce(
      (
        _allFiltersQuery: FiltersQueryOrderedMap,
        filter: FilterConfigMap,
        key: string
      ) => {
        // obtain filters query for every column
        if (multimode) {
          const filterIdx = filteredKeys.indexOf(key);
          const prevFiltersKeys = filteredKeys.slice(0, filterIdx).toArray();
          const prevFilters = filters.filter(
            (_filter, _key) =>
              prevFiltersKeys.indexOf(_key) >= 0 &&
              _filter != null &&
              _filter !== emptyFilter
          );
          const prevFiltersQuery = prevFilters.reduce(
            (
              _filtersQuery: FiltersQueryOrderedMap,
              _filter: FilterConfigMap,
              _key: string
            ) => _filtersQuery.merge(transformFilter(_key, _filter)),
            emptyOrderedMap as FiltersQueryOrderedMap
          );

          columnsFiltersQueries = columnsFiltersQueries.set(key, prevFiltersQuery);
        }

        // transform filter
        return _allFiltersQuery.merge(transformFilter(key, filter));
      },
      emptyOrderedMap as FiltersQueryOrderedMap
    );

    const foundEntry = filters.findEntry(
      (v, k) => v.getIn(["order", "direction"]) !== OrderDirections.NO
    );
    const orderKey = foundEntry?.[0];
    const orderDirection = foundEntry?.[1]?.getIn([
      "order",
      "direction",
    ]) as OrderDirectionsType;

    // transform ordering
    if (orderDirection) {
      allFiltersQuery = allFiltersQuery.set(
        djangoOrderingKey,
        (orderDirection === OrderDirections.DSC ? "-" : "") + orderKey
      );
    }
  }

  return [allFiltersQuery, columnsFiltersQueries];
}

function mergeFilters(
  filters: FiltersConfigOrderedMap,
  filterKey: string,
  filterValue: FilterConfigMap,
  multimode: boolean = false
): FiltersConfigOrderedMap {
  // check for empty filter
  if (
    filterValue !== emptyFilter &&
    filterValue.getIn(["order", "direction"]) ===
      emptyFilter.getIn(["order", "direction"]) &&
    filterValue.getIn(["filter", "values"]).size ===
      emptyFilter.getIn(["filter", "values"]).size
  ) {
    filterValue = emptyFilter;
  }

  if (multimode) {
    const currentFilterValue = filters.get(filterKey, emptyFilter);

    if (currentFilterValue !== filterValue) {
      if (filterValue === emptyFilter) {
        filters = filters.delete(filterKey);
      } else {
        filters = filters.set(filterKey, filterValue);
        // turn off other column ordering
        if (filterValue.getIn(["order", "direction"]) !== OrderDirections.NO) {
          filters = filters.mapEntries(([key, filter]) => {
            return key !== filterKey &&
              filter.getIn(["order", "direction"], OrderDirections.NO) !==
                OrderDirections.NO
              ? [
                  key,
                  filter.setIn(
                    ["order", "direction"],
                    OrderDirections.NO
                  ) as FilterConfigMap,
                ]
              : [key, filter];
          });
        }
      }
    }
  } else {
    if (filterValue === emptyFilter) {
      filters = emptyOrderedMap as FiltersConfigOrderedMap;
    } else {
      filters = emptyOrderedMap.set(filterKey, filterValue) as FiltersConfigOrderedMap;
    }
  }

  return filters;
}

export interface TableFilterableRestfulProps<RD = RowData>
  extends Omit<TableProps<RD>, "data"> {
  // data
  data: RestfulTableData<RD>;
  itemsCount: number;
  // data providers
  dataProvider: DataProviderRestful<RD>;
  filtersDataProvider: FiltersDataProviderRestful;
  // filtering props
  multimode: boolean;
  filters: FiltersConfigOrderedMap;
  filtersQuery: FiltersQueryOrderedMap;
  columnsFiltersQueries: ColumnsFiltersQueriesOrderedMap;
  // pagination props
  activePage: number;
  itemsPerPage: number;
  disablePagination?: boolean;
  //
  onApplyFilter?: OnApplyFilterFunction<RestfulTableDataStateObject<RD>>;
  onClearFilters?: OnClearFiltersFunction;
  onChangePage?: OnChangePageFunction;
  onChangeItemsPerPage?: OnChangeItemsPerPageFunction;
  onChangeData?: OnChangeDataFunction<RestfulTableDataStateObject<RD>>;
}

export const useTableHandlers = <RD = RowData,>(
  tableProps: TableFilterableRestfulProps<RD>
) => {
  const {
    multimode,
    filters,
    filtersQuery,
    columnsFiltersQueries,
    //
    itemsCount,
    activePage,
    itemsPerPage,
    //
    dataProvider,
    onChangeData,
    onApplyFilter,
    onClearFilters,
    onChangePage,
    onChangeItemsPerPage,
  } = tableProps;

  const handleApplyFilter = React.useCallback(
    async (filterKey, filterValue) => {
      let nextFilters = mergeFilters(filters, filterKey, filterValue, multimode);
      let nextFiltersQuery = filtersQuery;
      let nextColumnsFiltersQueries = columnsFiltersQueries;

      // apply filters
      if (nextFilters !== filters) {
        [nextFiltersQuery, nextColumnsFiltersQueries] = transformFilters(
          nextFilters,
          multimode
        );
        const nextFiltersState = {
          filters: nextFilters,
          filtersQuery: nextFiltersQuery,
          columnsFiltersQueries: nextColumnsFiltersQueries,
        };

        // API request
        const nextDataState = await dataProvider(
          {
            [djangoPaginationKey]: TableFilterableRestful.defaultProps.activePage,
            [djangoPaginationSizeKey]: itemsPerPage,
            [djangoOrderingKey]: nextFiltersQuery.get("ordering"),
          },
          nextFiltersQuery.delete("ordering").toJS(),
          {
            activePage: TableFilterableRestful.defaultProps.activePage,
            ...nextFiltersState,
          }
        );

        const nextTableDataState = {
          ...nextDataState,
          ...nextFiltersState,
          activePage: TableFilterableRestful.defaultProps.activePage,
        } as RestfulTableDataStateObject<RD>;

        if (onChangeData) onChangeData(nextTableDataState);
        if (onApplyFilter) onApplyFilter(filterKey, filterValue, nextTableDataState);

        return nextDataState;
      }
    },
    [
      itemsPerPage,
      filters,
      filtersQuery,
      columnsFiltersQueries,
      multimode,
      onChangeData,
      onApplyFilter,
      dataProvider,
    ]
  );

  const handleClearFilters = React.useCallback(async () => {
    const nextFiltersState = {
      filters: TableFilterableRestful.defaultProps.filters,
      filtersQuery: TableFilterableRestful.defaultProps.filtersQuery,
      columnsFiltersQueries: TableFilterableRestful.defaultProps.columnsFiltersQueries,
    };
    const nextDataState = await dataProvider(
      {
        [djangoPaginationKey]: TableFilterableRestful.defaultProps.activePage,
        [djangoPaginationSizeKey]: itemsPerPage,
      },
      TableFilterableRestful.defaultProps.filtersQuery.toJS(),
      {
        activePage: TableFilterableRestful.defaultProps.activePage,
        ...nextFiltersState,
      }
    );
    const nextTableDataState = {
      ...nextDataState,
      ...nextFiltersState,
      activePage: TableFilterableRestful.defaultProps.activePage,
    } as RestfulTableDataStateObject<RD>;

    if (onChangeData) onChangeData(nextTableDataState);
    if (onClearFilters) onClearFilters();

    return nextTableDataState;
  }, [itemsPerPage, dataProvider, onChangeData, onClearFilters]);

  const handleChangePage = React.useCallback(
    async (pageEvent) => {
      const pagesCount = itemsPerPage > 0 ? Math.ceil(itemsCount / itemsPerPage) : 0;
      const nextPage = getChangingPageNumber(
        pageEvent,
        activePage,
        pagesCount,
        TableFilterableRestful.defaultProps.activePage
      );
      let nextTableDataState = {} as RestfulTableDataStateObject<RD>;

      if (nextPage !== activePage) {
        const nextDataState = await dataProvider(
          {
            [djangoPaginationKey]: nextPage,
            [djangoPaginationSizeKey]: itemsPerPage,
            [djangoOrderingKey]: filtersQuery.get("ordering"),
          },
          filtersQuery.delete("ordering").toJS(),
          { activePage: nextPage }
        );

        nextTableDataState = {
          ...nextDataState,
          activePage: nextPage,
        } as RestfulTableDataStateObject<RD>;

        if (onChangeData) onChangeData(nextTableDataState);
        if (onChangePage) onChangePage(nextPage);
      }

      return nextTableDataState;
    },
    [
      activePage,
      itemsCount,
      itemsPerPage,
      filtersQuery,
      dataProvider,
      onChangeData,
      onChangePage,
    ]
  );

  const handleChangeItemsPerPage = React.useCallback(async () => {
    const nextDataState = await dataProvider(
      {
        [djangoPaginationKey]: TableFilterableRestful.defaultProps.activePage,
        [djangoPaginationSizeKey]: itemsPerPage,
        [djangoOrderingKey]: filtersQuery.get("ordering"),
      },
      filtersQuery.delete("ordering").toJS(),
      {
        activePage: TableFilterableRestful.defaultProps.activePage,
        itemsPerPage,
      }
    );

    const nextTableDataState = {
      ...nextDataState,
      activePage: TableFilterableRestful.defaultProps.activePage,
      itemsPerPage,
    } as RestfulTableDataStateObject<RD>;

    if (onChangeData) onChangeData(nextTableDataState);
    if (onChangeItemsPerPage) onChangeItemsPerPage(itemsPerPage);

    return nextTableDataState;
  }, [itemsPerPage, filtersQuery, dataProvider, onChangeData, onChangeItemsPerPage]);

  // effects

  const prevItemsPerPageRef = React.useRef(itemsPerPage);
  React.useEffect(() => {
    if (itemsPerPage !== prevItemsPerPageRef.current) {
      handleChangeItemsPerPage();
      prevItemsPerPageRef.current = itemsPerPage;
    }
  }, [itemsPerPage, handleChangeItemsPerPage]);

  return {
    handleApplyFilter,
    handleClearFilters,
    handleChangePage,
    handleChangeItemsPerPage,
  };
};

const useDataProviders = <RD = RowData,>(
  dataProviderBase: DataProviderRestful<RD>,
  filtersDataProviderBase: FiltersDataProviderRestful
): [DataProviderRestful<RD>, FiltersDataProviderRestful] => {
  const dataProvider = React.useCallback(
    (...args) => {
      return dataProviderBase
        ? dataProviderBase(...args)
        : Promise.resolve(emptyOrderedMap);
    },
    [dataProviderBase]
  ) as DataProviderRestful<RD>;

  const filtersDataProvider = React.useCallback(
    (...args) => {
      return filtersDataProviderBase
        ? filtersDataProviderBase(...args)
        : Promise.resolve(emptyOrderedMap);
    },
    [filtersDataProviderBase]
  ) as FiltersDataProviderRestful;

  return [dataProvider, filtersDataProvider];
};

export const useTableState = <RD = RowData,>(
  tableProps: TableFilterableRestfulProps<RD>
) => {
  const {
    data,
    itemsCount,
    activePage,
    itemsPerPage,
    filters,
    filtersQuery,
    columnsFiltersQueries,
    //
    dataProvider,
    onChangeData,
    //
    children,
  } = tableProps;
  const [schemaState] = useTableSchemaState(children);
  const [tableState, setTableState] = React.useState<RestfulTableDataStateObject<RD>>({
    data,
    itemsCount,
    activePage,
    itemsPerPage,
    filters,
    filtersQuery,
    columnsFiltersQueries,
  });

  // handlers
  const handleChangeData = React.useCallback(
    (nextState: RestfulTableDataStateObject<RD>) => {
      setTableState((prevState) => ({
        ...prevState,
        ...nextState,
      }));
      if (onChangeData) onChangeData(nextState);
    },
    [setTableState, onChangeData]
  );

  // initial data load
  const dataLoadedRef = React.useRef<boolean>(false);
  React.useEffect(() => {
    if (!dataLoadedRef.current) {
      dataLoadedRef.current = true;
      dataProvider(
        {
          [djangoPaginationKey]: activePage,
          [djangoPaginationSizeKey]: itemsPerPage,
          [djangoOrderingKey]: filtersQuery.get("ordering"),
        },
        filtersQuery.delete("ordering").toJS()
      );
      // ).then((nextData) => handleChangeData(nextData));
    }
  }, [activePage, itemsPerPage, filtersQuery, handleChangeData, dataProvider]);

  return {
    tableState,
    setTableState,
    schemaState,
    handleChangeData,
  };
};

export const TableFilterableRestful = <RD = RowData,>(
  props: TableFilterableRestfulProps<RD>
) => {
  const {
    schema,
    data,
    itemsCount,
    bodyEmptyText,
    highlighted = false,
    // selection
    rowIdGetter,
    selectedRowId,
    selectedCellId,
    onRowClick,
    onCellClick,
    // data providers
    dataProvider: dataProviderBase,
    filtersDataProvider: filtersDataProviderBase,
    // pagination
    activePage,
    itemsPerPage,
    disablePagination,
    // filters
    multimode,
    filters,
    filtersQuery,
    columnsFiltersQueries,
  } = props;

  // data providers
  const [dataProvider, filtersDataProvider] = useDataProviders(
    dataProviderBase,
    filtersDataProviderBase
  );

  // handlers
  const { handleApplyFilter, handleClearFilters, handleChangePage } = useTableHandlers({
    ...props,
    dataProvider,
    filtersDataProvider,
  });

  // rendering

  const tableGroups = <TableGroups groups={schema.groups} columns={schema.columns} />;

  const tableHead = (
    <TableHeadFilterableRestful
      schema={schema}
      multimode={multimode}
      rowIdGetter={rowIdGetter!}
      filters={filters}
      filtersQuery={filtersQuery}
      columnsFiltersQueries={columnsFiltersQueries}
      filtersDataProvider={filtersDataProvider}
      transformFilters={transformFilters}
      onApplyFilter={handleApplyFilter}
      onClearFilters={handleClearFilters}
    />
  );

  const tableBody = (
    <TableBody
      schema={schema}
      data={data}
      bodyEmptyText={bodyEmptyText}
      selectedRowId={selectedRowId}
      selectedCellId={selectedCellId}
      rowIdGetter={rowIdGetter!}
      onRowClick={onRowClick}
      onCellClick={onCellClick}
    />
  );

  const tablePagination = (
    <TablePagination
      activePage={activePage}
      itemsCount={itemsCount}
      itemsPerPage={itemsPerPage}
      disablePagination={disablePagination}
      onChangePage={handleChangePage}
    />
  );

  return (
    <TableImpl
      groups={tableGroups}
      head={tableHead}
      body={tableBody}
      pagination={tablePagination}
      highlighted={highlighted}
    />
  );
};
TableFilterableRestful.displayName = "TableFilterableRestful";
TableFilterableRestful.defaultProps = {
  ...Table.defaultProps,
  // data
  data: emptyOrderedMap,
  itemsCount: 0,
  // filtering props
  multimode: false,
  filters: emptyOrderedMap as FiltersConfigOrderedMap,
  filtersQuery: emptyOrderedMap as FiltersQueryOrderedMap,
  columnsFiltersQueries: emptyOrderedMap as ColumnsFiltersQueriesOrderedMap,
  // pagination props
  activePage: defaultPageNumber,
  itemsPerPage: defaultPageCapacity,
  disablePagination: false,
};

export interface TableFilterableEditableRestfulProps<RD = RowData>
  extends TableFilterableRestfulProps<RD> {
  editable: boolean;
  editorImpl?: RowEditorComponent<RD> | null;
  onEditApply?: React.ComponentProps<RowEditorComponent<RD>>["onApply"];
  onEditCancel?: React.ComponentProps<RowEditorComponent<RD>>["onCancel"];
  onDeleteRow?: React.ComponentProps<RowEditorComponent<RD>>["onDelete"];
}

export const TableFilterableEditableRestful = (
  props: TableFilterableEditableRestfulProps
) => {
  const {
    schema,
    data,
    itemsCount,
    bodyEmptyText,
    highlighted = false,
    // selection
    rowIdGetter,
    selectedRowId,
    selectedCellId,
    onRowClick,
    onCellClick,
    // data providers
    dataProvider: dataProviderBase,
    filtersDataProvider: filtersDataProviderBase,
    // pagination
    activePage,
    itemsPerPage,
    disablePagination,
    // filters
    multimode,
    filters,
    filtersQuery,
    columnsFiltersQueries,
    // editor
    editable,
    editorImpl,
    onEditApply,
    onEditCancel,
    onDeleteRow,
  } = props;

  // data providers
  const [dataProvider, filtersDataProvider] = useDataProviders(
    dataProviderBase,
    filtersDataProviderBase
  );

  // handlers
  const { handleApplyFilter, handleClearFilters, handleChangePage } = useTableHandlers({
    ...props,
    dataProvider,
    filtersDataProvider,
  });

  // effects

  useEditCancelEffect(data, selectedRowId, onEditCancel);

  // rendering

  const tableGroups = <TableGroups groups={schema.groups} columns={schema.columns} />;

  const tableHead = (
    <TableHeadFilterableRestful
      schema={schema}
      multimode={multimode}
      rowIdGetter={rowIdGetter!}
      filters={filters}
      filtersQuery={filtersQuery}
      columnsFiltersQueries={columnsFiltersQueries}
      filtersDataProvider={filtersDataProvider}
      transformFilters={transformFilters}
      onApplyFilter={handleApplyFilter}
      onClearFilters={handleClearFilters}
    />
  );

  const tableBody = (
    <TableBodyEditable
      schema={schema}
      data={data}
      bodyEmptyText={bodyEmptyText}
      selectedRowId={selectedRowId}
      selectedCellId={selectedCellId}
      rowIdGetter={rowIdGetter!}
      onRowClick={onRowClick}
      onCellClick={onCellClick}
      editable={editable}
      editorImpl={editorImpl}
      onEditApply={onEditApply}
      onEditCancel={onEditCancel}
      onDeleteRow={onDeleteRow}
    />
  );

  const tablePagination = (
    <TablePagination
      activePage={activePage}
      itemsCount={itemsCount}
      itemsPerPage={itemsPerPage}
      disablePagination={disablePagination}
      onChangePage={handleChangePage}
    />
  );

  return (
    <TableImpl
      groups={tableGroups}
      head={tableHead}
      body={tableBody}
      pagination={tablePagination}
      highlighted={highlighted}
    />
  );
};
TableFilterableEditableRestful.displayName = "TableFilterableEditableRestful";
TableFilterableEditableRestful.defaultProps = {
  ...TableFilterableRestful.defaultProps,
  editable: false,
};

export const TableFilterableRestfulStateful = (props: TableFilterableRestfulProps) => {
  const { tableState, schemaState, handleChangeData } = useTableState(props);

  return (
    <TableFilterableRestful
      {...props}
      {...tableState}
      schema={schemaState}
      onChangeData={handleChangeData}
    />
  );
};
TableFilterableRestfulStateful.displayName = "TableFilterableRestfulStateful";
TableFilterableRestfulStateful.defaultProps = TableFilterableRestful.defaultProps;

export const TableFilterableEditableRestfulStateful = (
  props: TableFilterableEditableRestfulProps
) => {
  const { tableState, schemaState, handleChangeData } = useTableState(props);

  return (
    <TableFilterableEditableRestful
      {...props}
      {...tableState}
      schema={schemaState}
      onChangeData={handleChangeData}
    />
  );
};
TableFilterableEditableRestfulStateful.displayName =
  "TableFilterableEditableRestfulStateful";
TableFilterableEditableRestfulStateful.defaultProps =
  TableFilterableEditableRestful.defaultProps;

export default TableFilterableRestful;
