// @flow
import React, { Component } from "react";
import { observer } from "mobx-react";
import GoogleMap from "./GoogleMap";

type Props = {
  geojson: ?Object,
  usOnly?: ?boolean,
};

const JobsByCountryChoroplethMap = observer(
  class extends Component<Props> {
    render() {
      const props = this.props;

      return (
        <div style={{ width: "100%" }}>
          <GoogleMap
            defaultCenter={{ lat: 36.3740346, lng: -100.4020313 }}
            defaultZoom={4}
            geojson={props.geojson}
          />
        </div>
      );
    }
  }
);

export default JobsByCountryChoroplethMap;
