// @flow
import React, { Component } from "react";
import { observer } from "mobx-react";
import CheckBox from "../../shared/CheckBox";
import BuyRate from "../../../models/BuyRate";
import PunchOutPeek from "./PunchOutPeek";
import PunchoutsListStore from "../../../stores/mobx/PunchoutsListStore";
import {
  CardListItem,
  CardListItemLeft,
  CardListItemRight,
  CardListItemLabel,
  CardListSubText,
  SubTextAccent,
  Info,
  InfoData,
  InfoLabel,
} from "../../../components/lib/Card";
import Box from "../../../components/lib/Box";
import Inline from "../../../components/lib/Inline";
import { styled } from "../../../../src/stitches.config";

const JobTitleInfo = styled(Info, {
  width: "25ch",
  paddingRight: "50px",
});

type Props = {
  store: PunchoutsListStore,
  punchout: BuyRate,
  expandable: boolean,
  itemClick: (BuyRate) => void,
};

const PunchoutsListItem = observer(
  class extends Component<Props> {
    render() {
      const props = this.props;
      const store = this.props.store;

      const punchout = props.punchout;

      const editing = punchout.viewState.editing;
      const selected = punchout.viewState.selected;
      const name = punchout.name;
      const createdDisplay = punchout.createdDate.format("MMMM D, YYYY");
      const expandable = props.expandable;
      //const id = punchout.buyrateId;
      const label = punchout.jobLabel;
      const expanded = punchout.viewState.expanded;
      let ownerName = "You";
      if (
        punchout.createdBy &&
        store.sessionInfo &&
        store.sessionInfo.user &&
        punchout.createdBy.userId !== store.sessionInfo.user.userId
      ) {
        if (punchout.createdBy.firstName) {
          ownerName = `${punchout.createdBy.firstName} ${
            punchout.createdBy.lastName || ""
          }`.trim();
        } else if (punchout.createdBy.username) {
          ownerName = punchout.createdBy.username;
        }
      }
      //let sharedByYou = shared;

      // if (String(rateCard.owner.clientId) !== String(props.store.currentUser.userId)) {
      //   ownerName = rateCard.owner.fullname;
      //   sharedByYou = false;
      // }
      // if (rateCard.owner.email !== localStorage.getItem("PeopleTickerUserEmail")) {
      //   ownerName = rateCard.owner.fullname;
      // }

      let onItemClick = this.props.itemClick; // || this.handleViewDetail;
      let editingSection = null;

      if (editing) {
        //onItemClick = punchout.toggleSelected;
        editingSection = (
          <CheckBox
            type={props.store.allowMultipleItemSelection ? "checkbox" : "radio"}
            name="list-item-select"
            value={name}
            selected={selected}
            style={{ width: 34 }}
            onClick={onItemClick}
          />
        );
      }

      // const sharedTooltip = (
      //   <Tooltip id="shared-icon-tooltip">Shared...</Tooltip>
      // );

      return (
        <>
          <CardListItem onClick={onItemClick}>
            <CardListItemLeft>
              <Inline css={{ gap: 0, flexWrap: "nowrap" }}>
                {editingSection}
                {expandable && (
                  <div className="search-expand-state">
                    <Box
                      as="span"
                      css={{
                        fontFamily: "IcoMoon Ultimate",
                        fontSize: "$2xl",
                        transition: "transform 150ms ease-in, color 200ms",
                        color: "$brand",
                        transform: expanded ? "rotate(90deg)" : "none",
                      }}
                    >
                      
                    </Box>
                  </div>
                )}
                <div>
                  <CardListItemLabel>{name}</CardListItemLabel>
                  <CardListSubText>
                    Created by <SubTextAccent>{ownerName} </SubTextAccent> on{" "}
                    <SubTextAccent>{createdDisplay}</SubTextAccent>
                  </CardListSubText>
                </div>
              </Inline>
            </CardListItemLeft>
            <CardListItemRight>
              {/*<div className="ratecard-info text-center">*/}
              {/*<header>Shared</header>*/}
              {/*<div>{ shared ? "Yes" : "No"}</div>*/}
              {/*</div>*/}
              {
                <JobTitleInfo>
                  <InfoLabel>Job Title</InfoLabel>
                  <InfoData>{label}</InfoData>
                  {/*<div className="ratecard-info">
                 <header>Locations</header>
                 <div className = "text-center">{locationCount}</div>
              </div>*/}
                  {/* <Link to={`/embed/punchouts/${id}`} className="btn btn-default">Details</Link>*/}
                </JobTitleInfo>
              }
            </CardListItemRight>
          </CardListItem>
          {expandable && (
            <PunchOutPeek
              fetchGraphQL={store.fetchGraphQL}
              fetchAPI={store.fetchAPI}
              buyRate={punchout}
            />
          )}
        </>
      );
    }
  }
);

export default PunchoutsListItem;
