// @flow
import { action } from "mobx";

import FilterObject, { FilterWithSort, ContainsTextFilter } from "../Filter";
import type { DjangoFilterObject, FilterColumn } from "../Filter";
import Sort from "../Sort";
import SortState from "../SortState";

// $FlowFixMe: Need to make the superclass properties covariant to subtype
export default class BatchSearchRateCardLabelFilter extends ContainsTextFilter(
  FilterWithSort
) {
  constructor(
    store: Object,
    column: FilterColumn,
    applyFilter: (FilterColumn, FilterObject) => void,
    applySort: (FilterColumn, Sort) => void,
    removeFilter: (FilterColumn) => void,
    removeSort: (FilterColumn) => void
  ) {
    super(store, column);

    this.applyFilter = applyFilter;
    // $FlowFixMe: Need to type these properties as invariant
    this.applySort = applySort;
    this.removeFilter = removeFilter;
    // $FlowFixMe: Need to type these properties as invariant
    this.removeSort = removeSort;

    // $FlowFixMe: Need to type these properties as invariant
    this.sortState = new SortState(column);

    // $FlowFixMe: Need to type these properties as invariant
    this.buildQueryFilter = action(this.buildQueryFilter.bind(this));
    this.onApply = action(this.onApply.bind(this));
  }

  buildQueryFilter(): ?DjangoFilterObject {
    if (!this.textToLookFor || !this.textToLookFor.trim()) {
      return null;
    }

    return {
      search: this.textToLookFor,
    };
  }
}
