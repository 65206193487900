// @flow
import * as React from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SelectableItemsList from "../ratecards/components/SelectableItemsList";
import SelectableItem from "../ratecards/components/SelectableItem";
import ContainerSection from "../ratecards/components/ContainerSection";
import FilterModal from "../ratecards/components/FilterModal";
import LoadingIndicator from "../shared/LoadingIndicator";
import SortControls from "../ratecards/components/SortControls";
import InstantSearchBox from "../ratecards/components/InstantSearchBox";

// CreatedByCriteriaList : list of the users to be displayed for Created by filter
const CreatedByCriteriaList = observer((props) => {
  return (
    <div>
      <SelectableItemsList listState={props.filterState}>
        {props.filterState.viewItems.map((owner, i) => (
          <SelectableItem
            key={owner.username}
            item={owner}
            name="owner-filter"
            value={owner.username}
            selected={owner.selected}
            onChange={props.filterState.setSelectedValue}
          >
            <div>
              {owner.firstName} {owner.lastName}{" "}
            </div>
            <div className="text-x-small">
              <em>
                <FontAwesomeIcon icon="user" className="icon no-margin" />{" "}
                {owner.username}"
              </em>
            </div>
          </SelectableItem>
        ))}
      </SelectableItemsList>
      <ContainerSection className="info">
        <p className="text-muted text-x-small no-margin pull-right">
          <em>
            <strong>{props.filterState.selectedValuesCount} </strong>
            selected, <strong>{props.filterState.totalValuesCount} </strong>
            total
          </em>
        </p>
        <div className="clearfix" />
      </ContainerSection>
    </div>
  );
});

const CreatedByFilter = ({
  createdByFilter,
  network,
  onInstantSearch,
  instantSearchValue,
}) => {
  return (
    <FilterModal filterState={createdByFilter}>
      {network && <LoadingIndicator />}
      {!network && (
        <div>
          <ContainerSection className="header overlap-t-padding">
            <h4 className="pull-left">Created By</h4>
            <div className="pull-right">
              <SortControls filterState={createdByFilter} />
            </div>
            <div className="clearfix" />
          </ContainerSection>
          <ContainerSection className="no-border">
            <p>Filter by specific Users:</p>
            <div>
              <div className="pull-left">
                <InstantSearchBox onSearch={onInstantSearch} value={instantSearchValue} />
              </div>
              <div
                className="pull-right rc-center"
                style={{
                  height: 40,
                }}
              >
                <button className="btn" onClick={createdByFilter.onSelectAll}>
                  Select All
                </button>
                <button className="btn" onClick={createdByFilter.onDeselectAll}>
                  Deselect All
                </button>
              </div>
              <div className="clearfix" />
            </div>
          </ContainerSection>
          <CreatedByCriteriaList filterState={createdByFilter} />
        </div>
      )}
    </FilterModal>
  );
};

export default CreatedByFilter;
