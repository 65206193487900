import React, { useState, useCallback } from "react";
import { fromJS } from "immutable";

import TextCrop from "../../validator5K_admin/components/TextCrop";
import JobEditor from "./JobEditor";
import Text from "../../../components/lib/Text";
import Tooltip from "../../../components/lib/Tooltip";
import { IconButton } from "../../../components/lib/Button";
import { ButtonGroup } from "../../../components/lib/ButtonGroup";
import { Column } from "../../../components/tables";
import { FilterTypes } from "../../../components/tables/constants";
import { emptyList, emptyOrderedMap } from "../../../constants";
import { Checkbox, CheckboxIndicator } from "../../../components/lib/Checkbox";
import Icon from "../../../components/lib/Icon";
import Center from "../../../components/lib/Center";
import { TableFilterableEditableStateful } from "../../../components/tables/TableFilterable";
import { rowIdGetter } from "../../../components/tables/utils";
import { renderTableColumnsSpecs } from "../../private_index/components/ExtendedRestfulTables";
import { useTableSchemaState } from "../../../components/tables/Table";

import type {
  JobValidationDataMap,
  JobValidationDataList,
} from "../../private_index/types";
import type { TableColumnSpecsObject } from "../types";
import type {
  RowEditorComponent,
  FiltersConfigOrderedMap,
  ColumnsFiltersConfigOrderedMap,
} from "../../../components/tables/types";
import type { TableFilterableEditableProps } from "../../../components/tables/TableFilterable";

const BOOLEAN_GRID_VALUES_TYPES = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

const jobsTableSpecs: TableColumnSpecsObject<JobValidationDataMap>[] = [
  {
    type: "column",
    uniqueKey: "problems",
    title: "Problems",
    getter: (row) => {
      const errorsNumber = row.get("errors", emptyList).size;
      const warningsNumber = row.get("warnings", emptyList).size;

      return errorsNumber + warningsNumber || null;
    },
    formatter: (value) => <span className="negative">{value}</span>,
    filterType: FilterTypes.NUMBERS_RANGE,
  },
  {
    type: "column",
    uniqueKey: "title",
    title: "Job Title",
    getter: (row) => row.get("title"),
    formatter: (value, row) => {
      const publicTitle = row.get("collection_public_title");
      const usePublicTitle = !!row.get("use_public_title");
      const hasPublicTitle = !!publicTitle;

      let formattedValue = null;
      if (hasPublicTitle && usePublicTitle) {
        formattedValue = publicTitle;
      } else {
        const titleId = row.get("job_title_id");
        formattedValue = titleId ? `${value} (#${titleId})` : value;
      }

      return (
        <Tooltip side="top" content={formattedValue}>
          <span>{formattedValue}</span>
        </Tooltip>
      );
    },
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "description",
    title: "Job Description",
    getter: (row) => row.get("description"),
    formatter: (value, row) => {
      const publicTitle = row.get("collection_public_title");
      const publicDescription = row.get("collection_public_description");
      const usePublicTitle = !!row.get("use_public_title");
      const hasPublicTitle = !!publicTitle;

      let resultingValue = value;
      if (hasPublicTitle && usePublicTitle) {
        resultingValue = publicDescription;
      }

      return <TextCrop text={resultingValue} />;
    },
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "collection_title",
    title: "Title Collection",
    getter: (row) => row.get("collection_title"),
    formatter: (value, row) => {
      if (!value) return null;

      value = `${value} (#${row.get("collection_id")})`;

      return (
        <Tooltip side="top" content={value}>
          <span>{value}</span>
        </Tooltip>
      );
    },
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "industry_name",
    title: "Industry",
    getter: (row) => row.get("industry_name"),
    formatter: (value, row) => {
      if (!value) return null;

      const industryId = row.get("industry_id");
      const formattedValue = industryId ? `${value} (#${industryId})` : value;

      return (
        <Tooltip side="top" content={formattedValue}>
          <span>{formattedValue}</span>
        </Tooltip>
      );
    },
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "rate_type_string",
    title: "Rate Type",
    getter: (row) => row.get("rate_type_string"),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "city",
    title: "City",
    getter: (row) => row.get("city"),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "state",
    title: "State/Province",
    getter: (row) => row.get("state"),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "country",
    title: "Country",
    getter: (row) => row.get("country"),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "region",
    title: "Region",
    getter: (row) => row.get("region"),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "is_global_supplier_search",
    title: "Global Supplier Search",
    getter: (row) => row.get("is_global_supplier_search", false),
    formatter: (value) => (!!value ? <Text color="positive">Yes</Text> : <Text>No</Text>),
    sortable: false,
    filterType: FilterTypes.ENUMERATION,
    filterOptions: fromJS(BOOLEAN_GRID_VALUES_TYPES),
  },
];

type UploadedJobsTableProps = Omit<TableFilterableEditableProps, "schema">;

const UploadedJobsTable = (props: UploadedJobsTableProps) => {
  const [schema] = useTableSchemaState(props.children);
  return <TableFilterableEditableStateful {...props} schema={schema} />;
};

export type ChangeFunction = (
  rowId: number,
  data: JobValidationDataMap,
  needValidation?: boolean
) => Promise<JobValidationDataMap | void>;
export type RemoveFunction = (rowId: number) => void;

type Props = {
  data: JobValidationDataList;
  onChange: ChangeFunction;
  onRemove: RemoveFunction;
};

export default function UploadedJobsTableView(props: Props) {
  const { data = emptyList, onChange, onRemove } = props;

  // state

  const [editingRowId, setEditingRowId] = useState<number | null>(null);

  // handlers

  const handleClickEditRow = useCallback(
    (rowId: number) => {
      setEditingRowId(editingRowId === rowId ? null : rowId);
    },
    [editingRowId]
  );

  const handleJobChange = useCallback(
    (nextData, needValidation = true) => {
      return editingRowId == null || !onChange
        ? Promise.resolve()
        : onChange(editingRowId, nextData, needValidation);
    },
    [editingRowId, onChange]
  );

  const handleCancelChange = useCallback(() => {
    setEditingRowId(null);
  }, []);

  const handleJobRemove = useCallback(
    (rowId: number) => {
      if (rowId && onRemove) onRemove(rowId);
    },
    [onRemove]
  );

  // rendering

  const renderActionsButtons = useCallback(
    (row: JobValidationDataMap) => {
      const rowId = rowIdGetter(row);

      return (
        <ButtonGroup>
          <IconButton
            icon="wrench"
            color="brand"
            variant="outlined"
            title="edit row"
            onClick={() => handleClickEditRow(rowId)}
            css={{ width: "28px" }}
          />
          <IconButton
            icon="times"
            color="danger"
            variant="outlined"
            title="remove row"
            onClick={() => handleJobRemove(rowId)}
            css={{ width: "28px" }}
          />
        </ButtonGroup>
      );
    },
    [handleClickEditRow, handleJobRemove]
  );

  const renderPublicTitleCheckbox = useCallback(
    (row: JobValidationDataMap) => {
      const hasPublicTitle = !!row.get("collection_public_title");
      if (!hasPublicTitle) return null;

      const rowId = rowIdGetter(row);
      const currentValue = !!row.get("use_public_title");
      return (
        <Center>
          <Checkbox
            title="use collection's public title for validation"
            checked={currentValue}
            onCheckedChange={(value) =>
              onChange(rowId, row.set("use_public_title", !!value), false)
            }
          >
            <CheckboxIndicator>
              <Icon icon="check" />
            </CheckboxIndicator>
          </Checkbox>
        </Center>
      );
    },
    [onChange]
  );

  const columns = [
    <Column key="row" uniqueKey="row" title="Row" getter={(row) => row.get("row")} />,
    <Column
      key="_edit"
      uniqueKey="_edit"
      title="Actions"
      getter={renderActionsButtons}
    />,
    <Column
      key="_use_public_title"
      uniqueKey="_use_public_title"
      title="Use Public Title"
      getter={renderPublicTitleCheckbox}
    />,
  ].concat(renderTableColumnsSpecs(jobsTableSpecs));

  return (
    <UploadedJobsTable
      data={data}
      rowIdGetter={rowIdGetter}
      selectedRowId={editingRowId}
      editorImpl={JobEditor as RowEditorComponent<JobValidationDataMap>}
      onEditApply={handleJobChange}
      onEditCancel={handleCancelChange}
      multimode
      editable
      activePage={1}
      itemsPerPage={data.size}
      bodyEmptyText="No jobs uploaded yet"
      disablePagination={true}
      filters={emptyOrderedMap as unknown as FiltersConfigOrderedMap}
      columnsFilters={emptyOrderedMap as unknown as ColumnsFiltersConfigOrderedMap}
    >
      {columns}
    </UploadedJobsTable>
  );
}

UploadedJobsTableView.displayName = "UploadedJobsTableView";
