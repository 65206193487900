// @flow
import { extendObservable, action, runInAction, computed, observable } from "mobx";
import moment from "moment";
import Search from "../../models/Search";
import NetworkState from "../../models/NetworkState";
import ModalState from "../../models/ModalState";
import type { FetchGraphQL, FetchAPI } from "../../App";
import { LEVEL_KEYS_MAPPING } from "../../models/pt_store/Pack";
import { SampleSizeItem } from "../../views/searches/components/RatesTrendModal";
import { RateCardListComponentStore } from "./RateCardListStore";
import ApplyTagState from "../../models/ApplyTagState";
import type MobXStore from "./MobXStore";
import TagList from "../../models/TagList";
import { useSearchResultsStore } from "../SearchResults";

const fragments = {
  mins: `
  fragment mins on MarketRatesNode {
   	payRateMin
    billRateMin
    markupAmtMin
    markupPctMin
  }`,
  avgs: `
  fragment avgs on MarketRatesNode {
    payRateAvg
    billRateAvg
    markupAmtAvg
    markupPctAvg
  }`,
  mids: `
  fragment mids on MarketRatesNode {
    payRateMid
    billRateMid
    markupAmtMid
    markupPctMid
  }`,
  maxs: `
  fragment maxs on MarketRatesNode {
    payRateMax
    billRateMax
    markupAmtMax
    markupPctMax
  }`,
  minsHistory: `
  fragment minsHistory on MarketChangeNode {
   	payRateMin
    billRateMin
    markupAmtMin
    markupPctMin
  }`,
  avgsHistory: `
  fragment avgsHistory on MarketChangeNode {
    payRateAvg
    billRateAvg
    markupAmtAvg
    markupPctAvg
  }`,
  midsHistory: `
  fragment midsHistory on MarketChangeNode {
    payRateMid
    billRateMid
    markupAmtMid
    markupPctMid
  }`,
  maxsHistory: `
  fragment maxsHistory on MarketChangeNode {
    payRateMax
    billRateMax
    markupAmtMax
    markupPctMax
  }`,
  searchResultsProps: `
  fragment searchResultsProps on MarketRatesNode {
    id
    level {
      legacyId
      value
    }
    numResults
    created
    modified
    needsUpdate
    #numResultsDelta
    ...mins
    ...mids
    ...maxs
    ...avgs
    history(filters: {fromDate: $resultsHistoryFrom, toDate: $resultsHistoryTo}, order: [{field: CREATED, direction: DESC}]) {
      revNum
      numResults
      created
      level {
        legacyId
        value
      }
      ...minsHistory
      ...midsHistory
      ...maxsHistory
      ...avgsHistory
    }
    #latestChange {
    #  revNum
    #  createdDate
    #  numResults
    #  ...minsHistory
    #  ...midsHistory
    #  ...maxsHistory
    #  ...avgsHistory
    #}
    #history(
    #  filters: {fromDate: $resultsHistoryFrom, toDate: $resultsHistoryTo},
    #  order: [{field: CREATED_DATE, direction: DESC}]
    #  ) {
    #  edges {
    #    node {
    #      revNum
    #      createdDate
    #      numResults
    #      ...minsHistory
    #      ...midsHistory
    #      ...maxsHistory
    #      ...avgsHistory
    #    }
    #  }
    #}
  }`,
  searchCredentialProps: `
  fragment searchCredentialProps on SaveSearchNode {
    id
    searchId
    isDraft
    isGlobalSupplierSearch
    workerTypeName
    rateCard {
      legacyId
      name
    }
    createdBy {
      userId
      username
    }
    job {
      jobLabel
      jobTitle
      jobCategory {
        id
        jobCategoryId
        description
      }
      jobDescription
    }
    jobDescription
    country
    state
    city
    createdDate
    lastUpdated
    rateType
    industry {
      id
      legacyId
      value
    }
    currency {
      iso
      symbol
    }
    region {
      regionId
      created
      name
    }
    tags {
      tagId
      name
    }
    canRevert
    needsUpdate
    buyrates {
      buyrateId
      name
      createdBy {
        username
      }
      active
      created
      punchouts {
        punchoutId
        levelId
        levelName
        levelRomanNumeral
        level {
          legacyId
          value
        }
        created
        payRateMin
        payRateMax
        markupPct
        billRateMin
        billRateMax
        salaryMin
        salaryMax
      }
    }
  }`,
  searchResults: `
  fragment searchResults on SaveSearchNode {
    marketrates {
      ...searchResultsProps
    }
    marketchanges {
      revNum
      numResults
      created
      level {
        legacyId
        value
      }
      ...minsHistory
      ...midsHistory
      ...maxsHistory
      ...avgsHistory
    }
    ratesData
  }`,
  searchResultsForThreeLevelsView: `
  fragment searchResultsForThreeLevelsView on SearchCredentialsNode {
      resultsForThreeLevelsView {
        totalCount
        edges {
          node {
            ...searchResultsProps
          }
        }
      }
  }`,
};

const query = `
${fragments.mins}
${fragments.mids}
${fragments.maxs}
${fragments.avgs}
${fragments.minsHistory}
${fragments.midsHistory}
${fragments.maxsHistory}
${fragments.avgsHistory}
${fragments.searchResultsProps}
${fragments.searchCredentialProps}
${fragments.searchResults}
# , $resultsHistoryFrom: DateTime!, $resultsHistoryTo: DateTime!
query getSearch($legacyId: Int!, $resultsHistoryFrom: DateTime!, $resultsHistoryTo: DateTime!){
  viewer {
    user {
      userId
    }
    savedsearch(id: $legacyId) {
      ...searchCredentialProps
      ...searchResults
    }
  }
}`;

const queryForThreeLevelsView = `
${fragments.mins}
${fragments.mids}
${fragments.maxs}
${fragments.avgs}
${fragments.minsHistory}
${fragments.midsHistory}
${fragments.maxsHistory}
${fragments.avgsHistory}
${fragments.searchResultsProps}
${fragments.searchCredentialProps}
${fragments.searchResultsForThreeLevelsView}

query getSearch($legacyId: Int!, $resultsHistoryFrom: DateTime!, $resultsHistoryTo: DateTime!){
  viewer{
    user {
      legacyId
    }
    searchCredential(legacyId: $legacyId) {
      ...searchCredentialProps
      ...searchResultsForThreeLevelsView
    }
  }
}`;

class SearchDetailStore {
  fetchGraphQL: FetchGraphQL;
  fetchAPI: FetchAPI;
  mobxStore: MobXStore;
  router: ?Object;
  network: NetworkState;
  networkAddtoRateCard: NetworkState;
  searchId: ?number;
  previousLocation: string;
  hasOwnership: boolean;
  threeLevelsViewUser: boolean;
  confirmDeleteModal: ModalState;
  confirmUndoUpdateRatesOnSearchModal: ModalState;
  confirmUpdateRatesOnSearchModal: ModalState;
  ratesTrendModal: ModalState;
  levelsGuideModal: ModalState;
  addToRateCardModal: ModalState;
  rateCardsListStore: RateCardListComponentStore;
  search: Search;
  RESULTS_HISTORY_MONTHS_TO_LOAD: number;
  selectedLevel: string;
  applyTagState: ApplyTagState;
  SHOW_RATES_OPTION: number;
  SHOW_DELTAS_OPTION: number;
  showRates: boolean;
  showDeltas: boolean;
  selectedShowOption: number;

  // rates trend chart
  sampleSizeItems: SampleSizeItem[];
  selectedSampleSize: SampleSizeItem;
  onSelectedSampleSizeChange: (SampleSizeItem) => void;
  onShowRatesTrend: (string, ?number) => void;

  removeTags: (SyntheticEvent<HTMLElement>, number[]) => Promise<any>;
  removeTag: (SyntheticEvent<HTMLElement>, number) => Promise<any>;

  hidePayRate: boolean;
  hideMedian: boolean;
  hideMarkup: boolean;
  hideBillRate: boolean;
  hideMin: boolean;
  hideMedian: boolean;
  hideAverage: boolean;
  hideMax: boolean;

  updateShowOptionFlags: () => void;
  onShowOptionChange: (SyntheticInputEvent<HTMLInputElement>) => void;
  onHidePayRateChange: () => void;
  onHideMarkupChange: () => void;
  onHideBillRateChange: () => void;
  onHideMinChange: () => void;
  onHideMedianChange: () => void;
  onHideAverageChange: () => void;
  onHideMaxChange: () => void;
  deleteSearch: () => void;
  fetchSearch: (number) => void;
  handleLevelViewDisplayOptionChange: () => void;
  addToRateCard: () => void;
  addSearchToRateCard: () => void;
  applyTagsToDetail: () => void;
  selectedTags: Object;

  constructor(
    fetchGraphQL: FetchGraphQL,
    fetchAPI: FetchAPI,
    hasThreeLevel: boolean,
    store: MobXStore
  ) {
    this.fetchGraphQL = fetchGraphQL;
    this.fetchAPI = fetchAPI;
    this.mobxStore = store;
    this.router = null;

    this.RESULTS_HISTORY_MONTHS_TO_LOAD = 6;

    this.SHOW_RATES_OPTION = 1;
    this.SHOW_DELTAS_OPTION = 2;

    this.previousLocation = "/ratecards";

    this.sampleSizeItems = [
      new SampleSizeItem(0, 12, "Last 12 months"),
      new SampleSizeItem(1, 18, "Last 18 months"),
      new SampleSizeItem(2, 24, "Last 24 months"),
    ];

    extendObservable(this, {
      searchId: null,
      search: null,
      hasOwnership: false,
      threeLevelsViewUser: hasThreeLevel,
      confirmUpdateRatesOnSearchModal: new ModalState(),
      confirmUndoUpdateRatesOnSearchModal: new ModalState(),
      confirmDeleteModal: new ModalState(),
      ratesTrendModal: new ModalState(),
      levelsGuideModal: new ModalState(),
      addToRateCardModal: new ModalState(),
      rateCardsListStore: new RateCardListComponentStore(fetchGraphQL),
      selectedLevel: LEVEL_KEYS_MAPPING.Junior,
      selectedShowOption: this.SHOW_RATES_OPTION,
      showRates: false,
      showDeltas: false,
      hidePayRate: false,
      hideMarkup: false,
      hideBillRate: false,
      hideMin: false,
      hideMedian: false,
      hideAverage: false,
      hideMax: false,
      network: new NetworkState({
        loading: true,
        error: null,
      }),
      networkAddtoRateCard: new NetworkState(),
      selectedSampleSize: this.sampleSizeItems[0],
      applyTagState: new ApplyTagState(this.fetchGraphQL, this),
      selectedTags: computed(() => {
        const selectedTags = observable.map({});
        if (!this.search) return selectedTags;
        if (this.search.tags.length === 0) return selectedTags;

        this.search.tags.forEach((tag: TagList) => {
          selectedTags.set(tag.tagId, tag);
        });

        return selectedTags;
      }),
    });

    this.updateShowOptionFlags = action(this.updateShowOptionFlags.bind(this));
    this.onShowOptionChange = action(this.onShowOptionChange.bind(this));
    this.onHidePayRateChange = action(this.onHidePayRateChange.bind(this));
    this.onHideMarkupChange = action(this.onHideMarkupChange.bind(this));
    this.onHideBillRateChange = action(this.onHideBillRateChange.bind(this));
    this.onHideMinChange = action(this.onHideMinChange.bind(this));
    this.onHideMedianChange = action(this.onHideMedianChange.bind(this));
    this.onHideAverageChange = action(this.onHideAverageChange.bind(this));
    this.onHideMaxChange = action(this.onHideMaxChange.bind(this));
    this.deleteSearch = action(this.deleteSearch.bind(this));
    this.fetchSearch = action(this.fetchSearch.bind(this));
    this.handleLevelViewDisplayOptionChange = action(
      this.handleLevelViewDisplayOptionChange.bind(this)
    );
    this.onShowRatesTrend = action(this.onShowRatesTrend.bind(this));
    this.onSelectedSampleSizeChange = action(this.onSelectedSampleSizeChange.bind(this));
    this.addToRateCard = action(this.addToRateCard.bind(this));
    this.addSearchToRateCard = action(this.addSearchToRateCard.bind(this));
    this.applyTagsToDetail = action(this.applyTagsToDetail.bind(this));
    this.removeTags = action(this.removeTags.bind(this));
    this.removeTag = action(this.removeTag.bind(this));

    this.updateShowOptionFlags();
  }

  onSelectedSampleSizeChange(sampleSizeItem: SampleSizeItem) {
    this.selectedSampleSize = sampleSizeItem;
    this.onShowRatesTrend(this.selectedLevel, this.selectedSampleSize.value);
  }

  onShowRatesTrend(levelName: string, months: number = 12) {
    this.selectedLevel = levelName;

    // load last 12 months of rates
    let monthCount = months - 1; // months - 1 because its zero indexed
    let resultsHistoryFrom = moment().subtract(monthCount, "months").startOf("month");
    let resultsHistoryTo = moment().add(1, "days").startOf("day");
    this.search.getSearchResults(true, true, resultsHistoryFrom, resultsHistoryTo);

    this.ratesTrendModal.showModal();
  }

  updateShowOptionFlags() {
    this.showRates = this.selectedShowOption === this.SHOW_RATES_OPTION;
    this.showDeltas = this.selectedShowOption === this.SHOW_DELTAS_OPTION;
  }

  onShowOptionChange(event: SyntheticInputEvent<HTMLInputElement>) {
    let value = parseInt(event.target.value, 10);
    this.selectedShowOption = value;
    this.updateShowOptionFlags();

    useSearchResultsStore
      .getState()
      .setSearchDetailsUI({ cardVariant: value === 1 ? "marketrates" : "marketchanges" });
  }

  onHidePayRateChange() {
    this.hidePayRate = !this.hidePayRate;
  }

  onHideMarkupChange() {
    this.hideMarkup = !this.hideMarkup;
  }

  onHideBillRateChange() {
    this.hideBillRate = !this.hideBillRate;
  }

  onHideMinChange() {
    this.hideMin = !this.hideMin;
  }

  onHideMedianChange() {
    this.hideMedian = !this.hideMedian;
  }

  onHideAverageChange() {
    this.hideAverage = !this.hideAverage;
  }

  onHideMaxChange() {
    this.hideMax = !this.hideMax;
  }

  handleLevelViewDisplayOptionChange(e: Object) {
    this.search.selectedLevelViewDisplayOption = parseInt(e.target.value, 10);
    this.search.updateLevelViewDisplayOptionFlags();

    let monthCount = this.RESULTS_HISTORY_MONTHS_TO_LOAD - 1; // -1 because current month counts
    let resultsHistoryFrom = moment().subtract(monthCount, "months").startOf("month");
    let resultsHistoryTo = moment().add(1, "days").startOf("day");
    this.search.getSearchResults(true, true, resultsHistoryFrom, resultsHistoryTo);

    this.hidePayRate = false;
    this.hideMedian = this.search.showThreeLevelsView;
    this.hideMarkup = this.search.showThreeLevelsView;
    this.hideBillRate = this.search.showThreeLevelsView;
  }

  async fetchSearch(searchId: number) {
    this.searchId = searchId;
    this.network.loading = true;

    let monthCount = this.RESULTS_HISTORY_MONTHS_TO_LOAD - 1; // -1 because current month counts
    let resultsHistoryFrom = moment().subtract(monthCount, "months").startOf("month");
    let resultsHistoryTo = moment().add(1, "days").startOf("day");
    let res = null;
    try {
      const detailQuery = this.threeLevelsViewUser ? queryForThreeLevelsView : query;
      res = await this.fetchGraphQL(detailQuery, {
        legacyId: searchId,
        resultsHistoryFrom: resultsHistoryFrom,
        resultsHistoryTo: resultsHistoryTo,
      });
    } catch (e) {
      this.network.handleError("Getting Search", e);
      // TODO: Display user friendly error message
      return Promise.reject(e);
    }

    runInAction("fetchSearch--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get Search query", res)) {
        // TODO: Display user friendly error message
        return;
      }

      if (!res) return;

      const user = res.data.viewer.user;
      const search = res.data.viewer.savedsearch;

      // this.search = new Search(this, {
      //   searchId: search.searchId,
      //   ownerLogin: search.createdBy.username,
      //   title: search.job.jobTitle,
      //   label: search.job.jobLabel === "" ? "N/A" : search.job.jobLabel,
      //   category: search.job.jobCategory === null ? "N/A" : search.job.jobCategory.description,
      //   jobDescription: search.job.jobDescription,
      //   location: {
      //     country: search.country,
      //     state: search.state,
      //     city: search.city
      //   },
      //   industry: {
      //     title: search.industry.value
      //   },
      //   frequency: {
      //     title: search.rateType === 1 ? 'Hourly': 'Annual'
      //   },
      //   currency: search.currency,
      //   created: moment(search.createdDate),
      //   lastUpdated: moment(search.lastUpdated),
      //   region: search.region,
      //   results: this.threeLevelsViewUser ? search.resultsForThreeLevelsView : search.marketrates,
      //   internalRateCards: search.buyrates
      // });
      this.search = new Search(this, search);
      // console.log("setting search");

      this.hasOwnership = String(search.createdBy.userId) === String(user.userId);

      if (this.threeLevelsViewUser) {
        // default to three levels view
        this.search.selectedLevelViewDisplayOption =
          this.search.SHOW_THREE_LEVELS_VIEW_OPTION;
        this.search.updateLevelViewDisplayOptionFlags();

        this.hideMedian = this.search.hideMedian;
        this.hidePayRate = !this.search.showPayRate;
        this.hideMarkup = !this.search.showMarkup;
        this.hideBillRate = !this.search.showBillRate;
      }
    });
  }

  async deleteSearch() {
    if (this.network.loading) {
      return;
    }

    const searchId = this.search.id;

    const query = `
      mutation DeleteSearch($searchId: [Int]){
        deleteSavedSearch(input: {
            searchId: $searchId
          }) {
          ok
        }
      }
    `;
    const variables = {
      searchId: searchId,
    };

    this.confirmDeleteModal.hideModal();
    this.network.loading = true;

    let res = null;
    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Deleting Search", e);
      // TODO: Display user friendly error message
      return;
    }

    runInAction("deleteSearch--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("deleteSearch", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.router && this.router.goBack();
    });
  }

  addToRateCard() {
    this.rateCardsListStore.allowMultipleItemSelection = false;
    this.rateCardsListStore.isEditing = true;
    this.rateCardsListStore.pagination.goFetch();
    this.addToRateCardModal.showModal();
  }

  async addSearchToRateCard() {
    if (this.networkAddtoRateCard.loading) {
      return;
    }

    let params = [];
    let filterargs = [];
    let args = [];
    let vars = {};

    const selRateCards = this.rateCardsListStore.getSelectedRateCards();
    if (!selRateCards || !selRateCards.length) return;

    if (this.search && this.search.id) {
      params.push("$only: [String]!");
      filterargs.push("only: $only");
      vars.only = [this.search.id].map(String);
    }

    vars.existingRateCardId = selRateCards[0];

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
        mutation saveSearchRateCardExisting($existingRateCardId: String, ${queryParams}) {
          saveSearchesToRateCard(
            input: {
              ${filterargs.toString()},
              filters: { ${queryArgs} },
              existingRatecard: $existingRateCardId
            }
          ) {
            searches {
              searchId
              state
              city
              createdDate
              industry {
                value
              }
            }
          }
        }
      `;

    let res = null;
    this.networkAddtoRateCard.loading = true;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.networkAddtoRateCard.handleError("Adding searches to Rate Card", e);
      // TODO: Display user friendly error message
      return;
    }

    runInAction("addSavedSearchToRateCard--success", () => {
      this.networkAddtoRateCard.loading = false;
      this.networkAddtoRateCard.error = null;
      if (this.networkAddtoRateCard.logGraphQLError("addSavedSearchToRateCard", res)) {
        // TODO: Display user friendly error message
        return;
      }
      // NOTE: Load the new search information after adding it to a ratecard
      if (this.searchId) this.fetchSearch(this.searchId);
    });
  }

  async applyTagsToDetail() {
    let params = [];
    let filterargs = [];
    let searchargs = [];
    let args = [];
    let vars = {};

    const taglist = this.applyTagState.getSelectedTagList();
    if (!taglist || !taglist.length) {
      return;
    }
    params.push("$tagIds: [Int]!");
    filterargs.push("tagIds: $tagIds");
    vars.tagIds = taglist;
    params.push("$only: [ID]");
    args.push("only: $only");
    vars.only = [this.searchId];

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
      mutation applyTags(${queryParams}){
       applyTagsToSearches(input:{${filterargs.toString()}, filters: { ${queryArgs}}, ${searchargs.toString()}}) {
         ok
          errors {
           __typename
           ... on TagIdRequiredError {
             message
           }
           ... on TagIdsNotExistsError {
             message
           }
           ... on InvalidInputError {
             message
           }
           ... on ContentDoesNotExistsError {
             message
           }
         }
       }

      }
    `;

    this.network.loading = true;

    try {
      await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Apply Tags to Selected Worksheets", e);
      // TODO: Display user friendly error message
      return;
    }

    runInAction("applyTagsToDetail--success", () => {
      this.applyTagState.tagModal.hideModal();
      // NOTE: Load the new search information after adding tags
      if (this.searchId) this.fetchSearch(this.searchId);
    });
  }

  async removeTags(e: SyntheticEvent<HTMLElement>, tagIds: number[]): Promise<any> {
    if (this.network.loading) return;
    if (!this.searchId) return;

    const searchId = this.searchId;

    const query = `
    mutation removeTagsFromContent{
      removeTagsFromContent(
        input: {tagIds : [${tagIds.join(", ")}],
        contentType: SAVED_SEARCHES,
        contentId: ${searchId}}
        ){
          ok
          errors {
            __typename
          }
        }
      }
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, null);
    } catch (e) {
      // TODO: Display user friendly error message
      console.error("error", e);
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }

    return runInAction("removeTags--success", () => {
      if (!this.search && !this.search.tags) return;

      this.search.tags = this.search.tags.filter((item: TagList) => {
        return !tagIds.includes(item.tagId);
      });
    });
  }

  async removeTag(e: SyntheticEvent<HTMLElement>, tagId: number): Promise<any> {
    return await this.removeTags(e, [tagId]);
  }
}

export default SearchDetailStore;
