import React from "react";
import * as Stitches from "@stitches/react";

import Icon from "../../../components/lib/Icon";
import { config, styled } from "../../../stitches.config";

interface CheckMarkProps {
  css?: Stitches.CSS<typeof config>;
}

const CheckMark = (props: CheckMarkProps) => <Icon icon="check" {...props} />;

export const InstructionsCheckMark = styled(CheckMark, {
  color: "$brandLight",
});

export default CheckMark;
