import React from "react";
import { styled, CSS } from "../stitches.config";
import rpLogo from "../assets/img/ratepoint_logo_white.svg";
import SmallLogo from "../assets/img/small-logo.svg";
import Pfizer from "../assets/img/pfizer-white-logo.svg";
import Inline from "./lib/Inline";
import Divider from "./lib/Divider";

const StyledImg = styled("img", {});

export type LogoProps = React.ComponentPropsWithRef<"img"> & { css?: CSS };

const Logo = React.forwardRef<React.ElementRef<"img">, LogoProps>((props, ref) => {
  return <StyledImg ref={ref} src={rpLogo} width="166" height="65" {...props} />;
});

Logo.displayName = "Logo";

export default Logo;

export function PfizerLogo() {
  return (
    <Inline
      css={{
        paddingLeft: "$4",
        gap: "$3",
        width: "$56",
        height: "$10",
        overflow: "hidden",
      }}
    >
      <StyledImg src={SmallLogo} width="auto" height="40" />
      <Divider orientation="vertical" />
      <StyledImg src={Pfizer} width="auto" height="40" />
    </Inline>
  );
}
