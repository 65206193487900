// @flow

import {
  roleClientAdmin,
  rolePTAdmin,
  roleReports,
  roleV6User,
} from "../constants/sharedConstants";

type ClientData = {
  id: string | number;
  legacyId: string | number;
  title: string;
  legacyClient?: {
    perSearchPricing: boolean;
    isClientJobLibrary: boolean;
    ratecardSubscriptionFlag: boolean;
    jobLibrarySubscriptionFlag: boolean;
    pspStoreFlag: boolean;
  };
};
type UserData = {
  id: string | number;
  userId: number;
  username: string;
  email: string;
  firstName: string;
  middleName: string;
  lastName: string;
  termsOfAgreement: boolean;
  roles: Array<string>;
  legacySession: string;
  client: ClientData;
};
export type JsonData = {
  user?: UserData;
  client?: ClientData;
};

export class SessionClient {
  id: number;
  legacyId: number;
  title: string;
  perSearchPricing: boolean;
  isClientJobLibrary: boolean;
  ratecardSubscriptionFlag: boolean;
  jobLibrarySubscriptionFlag: boolean;
  pspStoreFlag: boolean;

  constructor(jsonData: ClientData) {
    this.id = 0;
    this.legacyId = 0;
    this.title = "";
    this.perSearchPricing = false;
    this.isClientJobLibrary = false;
    this.ratecardSubscriptionFlag = false;
    this.jobLibrarySubscriptionFlag = false;
    this.pspStoreFlag = false;

    if (jsonData) {
      this.id = parseInt(String(jsonData.id));
      this.legacyId = parseInt(String(jsonData.legacyId));
      this.title = jsonData.title;
      this.perSearchPricing = jsonData.legacyClient?.perSearchPricing || false;
      this.isClientJobLibrary = jsonData.legacyClient?.isClientJobLibrary || false;
      this.jobLibrarySubscriptionFlag =
        jsonData.legacyClient?.jobLibrarySubscriptionFlag || false;
      this.ratecardSubscriptionFlag =
        jsonData.legacyClient?.ratecardSubscriptionFlag || false;
      this.pspStoreFlag = jsonData.legacyClient?.pspStoreFlag || false;
    }
  }
}

export class SessionUser {
  userId: number;
  username: string;
  email: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  termsOfAgreement: boolean;
  roles: string[];

  constructor(jsonData: UserData) {
    this.userId = 0;
    this.username = "";
    this.email = "";
    this.firstName = "";
    this.middleName = "";
    this.lastName = "";
    this.termsOfAgreement = false;
    this.roles = [];

    if (jsonData) {
      this.userId = parseInt(String(jsonData.userId));
      this.username = jsonData.username;
      this.email = jsonData.email;
      this.firstName = jsonData.firstName;
      this.middleName = jsonData.middleName;
      this.lastName = jsonData.lastName;
      this.termsOfAgreement = jsonData.termsOfAgreement;
      this.roles = jsonData.roles || [];
    }
  }

  isPTAdmin = (): boolean => {
    return this.roles.indexOf(rolePTAdmin) >= 0;
  };

  isClientAdmin = (): boolean => {
    return this.roles.indexOf(roleClientAdmin) >= 0;
  };

  isReportsUser = (): boolean => {
    return this.roles.indexOf(roleReports) >= 0;
  };

  isV6User = (): boolean => {
    return this.roles.indexOf(roleV6User) >= 0;
  };
}

export default class SessionInfo {
  legacySession: string;
  user: SessionUser;
  client: SessionClient;

  constructor(jsonResponse: JsonData) {
    this.user = {} as SessionUser;
    this.client = {} as SessionClient;
    this.legacySession = "";
    const userData = jsonResponse && jsonResponse.user ? jsonResponse.user : null;
    if (userData) {
      this.legacySession = userData.legacySession;
      this.user = new SessionUser(userData);

      if (userData.client && userData.client.legacyClient) {
        this.client = new SessionClient(userData.client);
      }
    }
  }
}
