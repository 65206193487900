import React, { useCallback } from "react";

import Stack from "../../../components/lib/Stack";
import Alert from "../../../components/lib/Alert";
import { ButtonGroupRight } from "../../../components/lib/ButtonGroup";

import {
  Card,
  CardHeader,
  CardHeaderTitle,
  CardBody,
} from "../../../components/lib/Card";
import { Button } from "../../../components/lib/Button";

import ProgramAccessTable from "../components/ProgramAccessTable";

import type { CommonProgramChildPageProps } from "../ProgramDataProvider";

const ProgramAccessEditor = (props: CommonProgramChildPageProps) => {
  const { programId, programData, router, isClientAdmin, isPTAdmin } = props;

  const programTitle = programData.get("title");
  const hasNoProgramData = !programData || !programData.size;
  const header = hasNoProgramData
    ? "Edit Access List"
    : `Edit Access List for the Index "${programTitle}"`;

  // handlers
  const handleGoBackToProgramsList = useCallback(() => {
    router.push("/private-index/programs");
  }, [router]);

  return (
    <Stack>
      <Card
        css={{
          width: "$full",
          "@md": {
            maxWidth: "900px",
          },
        }}
      >
        <CardHeader>
          <CardHeaderTitle as="h3">{header}</CardHeaderTitle>
        </CardHeader>
        <CardBody
          css={{
            "& .alert": {
              marginTop: 0,
              marginBottom: 0,
            },
          }}
        >
          <Stack css={{ alignItems: "stretch", gap: "$8" }}>
            {!isClientAdmin && !isPTAdmin ? (
              <Alert color="warning">
                <h5>You have no permissions to access this page</h5>
              </Alert>
            ) : (
              <ProgramAccessTable programId={programId} />
            )}
            <ButtonGroupRight>
              <Button size="large" icon="arrow-left" onClick={handleGoBackToProgramsList}>
                Back To Indexes List
              </Button>
            </ButtonGroupRight>
          </Stack>
        </CardBody>
      </Card>
    </Stack>
  );
};

ProgramAccessEditor.displayName = "ProgramAccessEditor";

export default ProgramAccessEditor;
