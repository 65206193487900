// @flow

import { action } from "mobx";

export const LOCATION_TYPE = {
  LOCATION: 1,
  REGION: 2,
};

export default class LocationList {
  id: number;
  name: ?string;
  title: ?string;
  subtitle: ?string;
  fullTitle: ?string;
  fullSubtitle: ?string;
  locationType: ?Object;
  countryCode: ?string;
  countryId: ?number;
  store: Object;
  highlight: ?{
    title: string,
    subtitle: string,
  };
  viewState: {
    editing: boolean,
    selected: boolean,
  };

  toggleEdit: () => void;
  toggleSelected: (SyntheticEvent<HTMLElement>, ?any, ?any, ?boolean) => void;
  destroy: () => void;
  toString: () => string;

  constructor(store: Object, object: Object) {
    this.store = store;
    this.name = null;
    this.subtitle = null;
    this.title = null;
    this.fullTitle = null;
    this.fullSubtitle = null;
    this.countryCode = null;
    this.viewState = {
      editing: false,
      selected: false,
    };

    if (object) {
      if (!object.locationId && !object.id)
        throw new Error("Cannot instantiate Location without an ID!");
      this.id = object.locationId || object.id || 0;
      this.name = object.name || object.title || "";
      this.title = object.title;
      this.subtitle = object.subtitle;
      this.fullTitle = object.fullTitle;
      this.fullSubtitle = object.fullSubtitle;
      this.locationType = object.locationType || {};
      this.countryCode = object.countryCode || object.isoCode;
      this.countryId = object.countryId;
      this.highlight = object.highlight;
    }

    this.toggleEdit = action(this.toggleEdit.bind(this));
    this.toggleSelected = action(this.toggleSelected.bind(this));
    this.destroy = action(this.destroy.bind(this));
  }

  toggleEdit() {
    const viewState = this.store.regionsListViewState.get(this.id);
    viewState.editing = !viewState.editing;
  }

  toggleSelected(
    e: SyntheticEvent<HTMLElement>,
    foo?: any = null,
    bar?: any = null,
    setValue?: ?boolean = null
  ) {
    // NOTE: ignore foo and bar arguments
    const viewState = this.store.regionsListViewState.get(this.id);

    if (setValue !== null) {
      viewState.selected = setValue;
      return;
    } else {
      viewState.selected = !viewState.selected;

      if (!viewState.selected && this.store.allSelected) {
        this.store.allSelected = false;
      }
    }

    if (!this.store.allowMultipleItemSelection) {
      // deselect all other jobs
      this.store.regionsListViewState.forEach((viewState) => {
        if (this.viewState === viewState) return;

        viewState.selected = false;
      });
    }
  }

  destroy() {
    this.store.regionLists.remove(this);
  }

  toJS() {
    return {
      id: this.id,
      name: this.name,
      locationType: this.locationType,
    };
  }

  toString() {
    return this.name || this.fullTitle || "<-- No Name -->";
  }

  static fromJS(store: Object, object: Object) {
    return new LocationList(store, object);
  }
}
