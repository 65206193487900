import R from "ramda";

class Worksheet {
  constructor(workbook, label) {
    this.workbook = workbook;
    this.label = label;
    this.cells = [];
    this.columns = [];
    this.rows = [];
    this.hyperlinks = [];
    this.images = [];
    this.tables = [];
    this.merged_cells = [];

    this.currentRowIndex = 0;
  }

  writeEmptyRows(rowsToWrite = 1) {
    this.currentRowIndex += rowsToWrite;

    return this;
  }

  setColumnWidth(columnIndex, width, format, options) {
    const column = {
      first_col: columnIndex,
      last_col: columnIndex,
      width,
    };

    if (format) {
      if (!this.workbook.hasFormat(format)) {
        throw new Error(`Column ${columnIndex} refers to non-existent format ${format}`);
      }
      column["format"] = format;
    }
    if (!R.isNil(options)) {
      column["options"] = options;
    }

    this.columns.push(column);

    return this;
  }

  setRowHeight(rowIndex, height, format, options) {
    const row = {
      row: rowIndex,
      height,
    };

    if (format) {
      if (!this.workbook.hasFormat(format)) {
        throw new Error(`Row ${rowIndex} refers to non-existent format ${format}`);
      }
      row["format"] = format;
    }
    if (!R.isNil(options)) {
      row["options"] = options;
    }

    this.rows.push(row);

    return this;
  }

  /**
   * Writes a row
   *
   * writeRow({v: "Foo Bar"}, null, {f: 'bolder', v: "I am bolder"}, 'Also can be a string');
   *
   * @param columns
   * @returns {Worksheet}
   */
  writeRow(...columns) {
    let currentColumnIndex = 0;
    const currentRowIndex = this.currentRowIndex++;

    for (let column of columns) {
      if (column === null) {
        currentColumnIndex++;
        continue;
      }
      let format = null,
        value = null;

      if (typeof column === "string") {
        value = column;
      } else if (typeof column === "object") {
        format = column.hasOwnProperty("f") ? column.f : null;
        value = column.hasOwnProperty("v") ? column.v : null;
      } else {
        throw new Error("Invalid column of type " + typeof column + " provided");
      }

      if (format !== null) {
        if (!this.workbook.hasFormat(format))
          throw new Error(
            `Row ${currentRowIndex}, Column ${currentColumnIndex} refers to non-existent format ${format}`
          );
      }
      if (value === null) {
        throw new Error(
          `Row ${currentRowIndex}, Column ${currentColumnIndex} has no value. Pass null instead of an object instead.`
        );
      }

      const cell = {
        row: currentRowIndex,
        col: currentColumnIndex++,
        value: value,
      };

      if (format !== null) {
        cell.format = format;
      }

      this.cells.push(cell);
    }

    return this;
  }

  writeCell(rowIndex, columnIndex, value, format) {
    const cell = {
      row: rowIndex,
      col: columnIndex,
      value,
    };

    if (format) {
      if (!this.workbook.hasFormat(format)) {
        throw new Error(
          `Row ${rowIndex}, Column ${columnIndex} refers to non-existent format ${format}`
        );
      }
      cell["format"] = format;
    }

    this.cells.push(cell);

    return this;
  }

  writeMergedCell(
    firstRowIndex,
    firstColumnIndex,
    lastRowIndex,
    lastColumnIndex,
    data,
    format
  ) {
    const cell = {
      first_row: firstRowIndex,
      first_col: firstColumnIndex,
      last_row: lastRowIndex,
      last_col: lastColumnIndex,
      data: data,
    };

    if (format) {
      if (!this.workbook.hasFormat(format)) {
        throw new Error(
          `Merged cell (from row ${firstRowIndex} column ${firstColumnIndex} ' +
          'to row ${lastRowIndex} column ${lastColumnIndex} ' +
          'refers to non-existent format ${format}`
        );
      }
      cell["format"] = format;
    }

    this.merged_cells.push(cell);

    return this;
  }

  writeHyperlink(rowIndex, columnIndex, url, format, label, tip) {
    const hyperlink = {
      row: rowIndex,
      col: columnIndex,
      url,
    };

    if (format) {
      if (!this.workbook.hasFormat(format)) {
        throw new Error(
          `Row ${rowIndex}, Column ${columnIndex} refers to non-existent format ${format}`
        );
      }
      hyperlink["format"] = format;
    }
    if (label) {
      hyperlink["label"] = label;
    }
    if (tip) {
      hyperlink["tip"] = tip;
    }

    this.hyperlinks.push(hyperlink);

    return this;
  }

  writeImage(rowIndex, columnIndex, url, options) {
    this.images.push({
      row: rowIndex,
      col: columnIndex,
      url: url,
      options: options,
    });

    return this;
  }

  writeTable(firstRowIndex, firstColumnIndex, lastRowIndex, lastColumnIndex, options) {
    this.tables.push({
      first_row: firstRowIndex,
      first_col: firstColumnIndex,
      last_row: lastRowIndex,
      last_col: lastColumnIndex,
      options: options,
    });

    return this;
  }

  addFormat(formatKey, formatSpec) {
    return this.workbook.addFormat(formatKey, formatSpec);
  }

  hasFormat(formatKey) {
    return this.workbook.hasFormat(formatKey);
  }

  toJSON() {
    const schema = {
      cells: this.cells,
      label: this.label,
    };

    if (this.columns.length) {
      schema.columns = this.columns;
    }
    if (this.rows.length) {
      schema.rows = this.rows;
    }
    if (this.hyperlinks.length) {
      schema.hyperlinks = this.hyperlinks;
    }
    if (this.images.length) {
      schema.images = this.images;
    }
    if (this.tables.length) {
      schema.tables = this.tables;
    }
    if (this.merged_cells.length) {
      schema.merged_cells = this.merged_cells;
    }

    return schema;
  }
}

export default class WorkbookBuilder {
  constructor(filename) {
    this.filename = filename;
    this.formats = {};
    this.worksheets = [];
  }

  addFormat(formatKey, formatSpec) {
    if (this.formats.hasOwnProperty(formatKey))
      throw new Error("A format with the key " + formatKey + " already exists.");

    this.formats[formatKey] = formatSpec;

    return this;
  }

  hasFormat(formatKey) {
    return this.formats.hasOwnProperty(formatKey);
  }

  /**
   * Add a worksheet to the workbook.
   * @param worksheetLabel
   * @returns {Worksheet}
   */
  addWorksheet(worksheetLabel) {
    const worksheet = new Worksheet(this, worksheetLabel);
    this.worksheets.push(worksheet);

    return worksheet;
  }

  toJSON() {
    if (this.worksheets.length === 0) {
      throw new Error("Trying to serialize an empty workbook.");
    }

    const schema = {
      filename: this.filename,
      worksheets: this.worksheets.map((worksheet) => worksheet.toJSON()),
    };

    if (Object.keys(this.formats).length !== 0) {
      schema.formats = this.formats;
    }

    return schema;
  }
}
