import React from "react";
import DocumentTitle from "react-document-title";
import { RecoilRoot } from "recoil";

// @ts-expect-error
import MobXStore from "../../stores/mobx/MobXStore";
import SessionInfo from "../../models/SessionInfo";

import { TickerPageLoader } from "../../components/lib/TickerLoader";
import Container from "../../components/lib/Container";
import { useGlobalContext } from "../../globalContext";

import PLIContext from "./context";
import { omitObjectKeys } from "../../utils/object";

import type { PLIContextObject } from "./context";
import type { Location } from "history";
import type { InjectedRouter } from "react-router";
import type { AllCurrenciesMap } from "./types";
import type { FetchAPIFunc, FetchGraphQLAPIFunc } from "../../types/fetch";
import type { CommonChildPageProps as CommonChildPagePropsBase } from "../../globalContext";
import { useAllCurrenciesData } from "./hooks";

type PrivateIndexProps = {
  sessionInfo: SessionInfo;
  router: InjectedRouter;
  location: Location;
  store: MobXStore;
  fetchAPINew: FetchAPIFunc;
  fetchGraphQLNew: FetchGraphQLAPIFunc;
  children: React.ReactElement;
};

const PrivateIndex = (props: PrivateIndexProps) => {
  const { children } = props;
  const globalContextValues = useGlobalContext();

  const isPTAdmin = globalContextValues.sessionInfo.user.isPTAdmin();
  const isClientAdmin = false; // IMPORTANT: this role is no longer used
  const isRegularUser = !isPTAdmin && !isClientAdmin;

  const { loaded: isCurrenciesDataLoaded, currenciesData } = useAllCurrenciesData();

  if (!isCurrenciesDataLoaded) {
    return <TickerPageLoader />;
  }

  const pliContextValues = {
    ...omitObjectKeys(globalContextValues, ["fetchAPINew", "fetchGraphQLNew"]),
    //
    fetchAPI: globalContextValues["fetchAPINew"],
    fetchGraphQL: globalContextValues["fetchGraphQLNew"],
    //
    isPTAdmin,
    isClientAdmin,
    isRegularUser,
    //
    currenciesData,
  } as PLIContextObject;

  return (
    <Container
      className="pt-private-index"
      css={{
        paddingTop: "$4",
        paddingLeft: 0,
        paddingRight: 0,
        width: "100%",
      }}
    >
      <DocumentTitle title="Private Index" />
      <PLIContext.Provider value={pliContextValues}>
        <RecoilRoot>
          {React.cloneElement(React.Children.only(children), pliContextValues)}
        </RecoilRoot>
      </PLIContext.Provider>
    </Container>
  );
};

PrivateIndex.displayName = "PrivateIndex";

export interface CommonChildPageProps extends CommonChildPagePropsBase {
  isClientAdmin: boolean;
  isPTAdmin: boolean;
  isRegularUser: boolean;
  //
  currenciesData: AllCurrenciesMap;
  //
  params: {
    programId: string;
    indexId: string;
    uploadId: string;
    contractorId: string;
    collectionId: string;
  };
}

export default PrivateIndex;
