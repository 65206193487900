import React, { Fragment } from "react";
// LIB
import Icon from "../../../../components/lib/Icon";
import Inline from "../../../../components/lib/Inline";
import Tooltip from "../../../../components/lib/Tooltip";
import { styled } from "../../../../stitches.config";
// relay
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { DetailTable_savedsearch$key } from "./__generated__/DetailTable_savedsearch.graphql";
import { useSearchResultsStore } from "../../../../stores/SearchResults";

const Wrappper = styled("div", {
  width: "100%",
  overflowX: "auto",
});

const Grid = styled("section", {
  display: "grid",
  gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
  gap: "0",
  minWidth: "100%",
  justifyItems: "center",
});

const GridItem = styled("div", {
  padding: "12px",
  textAlign: "center",
});

const GridSubHeader = styled(GridItem, {
  width: "100%",
});

const PayRateSubHeader = styled(GridSubHeader, {
  backgroundColor: "$brandLighter",
});

const MarkupSubHeader = styled(GridSubHeader, {
  backgroundColor: "$primaryLight",
});

const BillRateSubHeader = styled(GridSubHeader, {
  backgroundColor: "#e5f1de",
});

const LevelColumn = styled(GridItem, {
  backgroundColor: "$brandLighter",
  width: "100%",
});

const fragment = graphql`
  fragment DetailTable_savedsearch on SaveSearchNode {
    id
    rateType @required(action: NONE)
    currency {
      id
      iso @required(action: NONE)
    }
    marketrates {
      id
      # pay rate
      payRateMin @required(action: NONE)
      payRateMid @required(action: NONE)
      payRateMax @required(action: NONE)
      payRateAvg @required(action: NONE)
      # bill rate
      billRateMin @required(action: NONE)
      billRateMid @required(action: NONE)
      billRateMax @required(action: NONE)
      billRateAvg @required(action: NONE)
      # markup %
      markupPctMin @required(action: NONE)
      markupPctMid @required(action: NONE)
      markupPctMax @required(action: NONE)
      markupPctAvg @required(action: NONE)
    }
  }
`;

type Props = {
  savedsearch: DetailTable_savedsearch$key;
};

export function DetailTable({ savedsearch }: Props) {
  const data = useFragment(fragment, savedsearch);
  const isContract = data?.rateType === 1;
  const fromCurrency = data?.currency?.iso || "USD";

  const toCurrency = useSearchResultsStore((s) => s.currency);
  const rateFrequency = useSearchResultsStore((s) => s.rateFrequency);
  const formatRate = useSearchResultsStore((s) => {
    const freq = isContract ? rateFrequency : "Hourly";
    return s.calculateRate(fromCurrency, toCurrency, freq, !isContract);
  });

  const gridTemplateColumns = isContract
    ? "140px repeat(12, 1fr)"
    : "140px repeat(4, 1fr)";

  if (!data) {
    return null;
  }

  return (
    <Wrappper>
      <Grid css={{ gridTemplateColumns }}>
        <GridItem css={{ gridColumn: "2/6" }}>PAY RATE</GridItem>

        {isContract && (
          <>
            <GridItem css={{ gridColumn: "6/10" }}>
              <Inline>
                <div>SUGGESTED MARKUP</div>
                <Tooltip
                  contentProps={{ style: { minWidth: "300px", maxWidth: "300px" } }}
                  content="Markups for this labor category will vary depending on many factors including
                    location, duration, volume of business, type of labor, rebates, etc. Try the
                    Negotiation Worksheets to come up with markups for your unique needs."
                >
                  <div>
                    <Icon icon="info-circle" />
                  </div>
                </Tooltip>
              </Inline>
            </GridItem>
            <GridItem css={{ gridColumn: "10/14" }}>
              <Inline>
                <div>BILL RATE</div>
                <Tooltip
                  contentProps={{ style: { minWidth: "300px", maxWidth: "300px" } }}
                  content="Bill Rates include all costs except VAT or GST tax."
                >
                  <div>
                    <Icon icon="info-circle" />
                  </div>
                </Tooltip>
              </Inline>
            </GridItem>
          </>
        )}

        <PayRateSubHeader css={{ gridColumn: "2", gridRow: "2" }}>MIN</PayRateSubHeader>
        <PayRateSubHeader css={{ gridColumn: "3", gridRow: "2" }}>AVG</PayRateSubHeader>
        <PayRateSubHeader css={{ gridColumn: "4", gridRow: "2" }}>MID</PayRateSubHeader>
        <PayRateSubHeader css={{ gridColumn: "5", gridRow: "2" }}>MAX</PayRateSubHeader>

        {isContract && (
          <>
            <MarkupSubHeader css={{ gridColumn: "6", gridRow: "2" }}>MIN</MarkupSubHeader>
            <MarkupSubHeader css={{ gridColumn: "7", gridRow: "2" }}>AVG</MarkupSubHeader>
            <MarkupSubHeader css={{ gridColumn: "8", gridRow: "2" }}>MID</MarkupSubHeader>
            <MarkupSubHeader css={{ gridColumn: "9", gridRow: "2" }}>MAX</MarkupSubHeader>

            <BillRateSubHeader css={{ gridColumn: "10", gridRow: "2" }}>
              MIN
            </BillRateSubHeader>
            <BillRateSubHeader css={{ gridColumn: "11", gridRow: "2" }}>
              AVG
            </BillRateSubHeader>
            <BillRateSubHeader css={{ gridColumn: "12", gridRow: "2" }}>
              MID
            </BillRateSubHeader>
            <BillRateSubHeader css={{ gridColumn: "13", gridRow: "2" }}>
              MAX
            </BillRateSubHeader>
          </>
        )}

        <LevelColumn css={{ gridRow: 3 }}>Level I(1-3 years)</LevelColumn>
        <LevelColumn css={{ gridRow: 4 }}>Level II(3-5 years)</LevelColumn>
        <LevelColumn css={{ gridRow: 5 }}>Level III(5-7 years)</LevelColumn>
        <LevelColumn css={{ gridRow: 6 }}>Level IV(7-10 years)</LevelColumn>
        <LevelColumn css={{ gridRow: 7 }}>Level V(10+ years)</LevelColumn>

        {data?.marketrates?.map((marketrate, index) => {
          return (
            <Fragment key={marketrate?.id}>
              <GridItem css={{ gridColumn: "2", gridRow: 3 + index }}>
                {formatRate(marketrate?.payRateMin)}
              </GridItem>
              <GridItem css={{ gridColumn: "3", gridRow: 3 + index }}>
                {formatRate(marketrate?.payRateAvg)}
              </GridItem>
              <GridItem css={{ gridColumn: "4", gridRow: 3 + index }}>
                {formatRate(marketrate?.payRateMid)}
              </GridItem>
              <GridItem css={{ gridColumn: "5", gridRow: 3 + index }}>
                {formatRate(marketrate?.payRateMax)}
              </GridItem>
              {isContract && (
                <>
                  <GridItem css={{ gridColumn: "6", gridRow: 3 + index }}>
                    {marketrate?.markupPctMin?.toFixed(2)}%
                  </GridItem>
                  <GridItem css={{ gridColumn: "7", gridRow: 3 + index }}>
                    {marketrate?.markupPctAvg?.toFixed(2)}%
                  </GridItem>
                  <GridItem css={{ gridColumn: "8", gridRow: 3 + index }}>
                    {marketrate?.markupPctMid?.toFixed(2)}%
                  </GridItem>
                  <GridItem css={{ gridColumn: "9", gridRow: 3 + index }}>
                    {marketrate?.markupPctMax?.toFixed(2)}%
                  </GridItem>

                  <GridItem css={{ gridColumn: "10", gridRow: 3 + index }}>
                    {formatRate(marketrate?.billRateMin)}
                  </GridItem>
                  <GridItem css={{ gridColumn: "11", gridRow: 3 + index }}>
                    {formatRate(marketrate?.billRateAvg)}
                  </GridItem>
                  <GridItem css={{ gridColumn: "12", gridRow: 3 + index }}>
                    {formatRate(marketrate?.billRateMid)}
                  </GridItem>
                  <GridItem css={{ gridColumn: "13", gridRow: 3 + index }}>
                    {formatRate(marketrate?.billRateMax)}
                  </GridItem>
                </>
              )}
            </Fragment>
          );
        })}
      </Grid>
    </Wrappper>
  );
}
