import React, { Component } from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/lib/Async";
import debounce from "lodash.debounce";
import { reactSelectStyles, reactSelectTheme } from "../../constants/reactSelect";

class JobTitleSelectNew extends Component {
  constructor(props) {
    super(props);

    this.loadOptions = this.loadOptions.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.debouncedRequestFn = debounce(
      (input, callback) => {
        props
          .getJobTitles(input)
          .then((response) => {
            let jobTitleList = [];
            if (response === undefined) {
              // console.log("response is undefined");
              return;
            }
            response.jobTitles.forEach((e) => {
              let optionItem;

              if (this.props.type === "addJobTitle") {
                let subtitleWithJobLabel = " (via My Job Labels)";
                if (!e.title.includes(subtitleWithJobLabel)) {
                  optionItem = {};
                  optionItem["value"] = e.id.toString();
                  optionItem["label"] = e.title.toString();
                  jobTitleList.push(optionItem);
                }
              } else {
                optionItem = {};
                optionItem["value"] = e.id.toString();
                optionItem["label"] = e.title.toString();

                if (this.props.clientIsClientJobLibrary) {
                  if (
                    e.clientJobLibraryInfo.isCertified === false &&
                    e.shareInfo !== null
                  ) {
                    optionItem.sharedBy = e.shareInfo.sharedBy;
                  }
                  optionItem.adhocCountriesCount =
                    e.clientJobLibraryInfo.adhocCountries.length;
                  optionItem.certifiedCountriesCount =
                    e.clientJobLibraryInfo.certifiedCountries.length;
                  optionItem.clientJobLibraryInfo = e.clientJobLibraryInfo;
                }

                jobTitleList.push(optionItem);
              }
            });
            // console.log("callback(jobTitleList)", jobTitleList[0]);
            callback(jobTitleList);
          })
          .catch(function (error) {
            console.log("Error fetching titles", error);
            callback([]);
          });
      },
      250,
      { trailing: true, leading: false }
    );
  }

  static propTypes = {
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
    clientIsClientJobLibrary: false,
  };

  loadOptions(input, callback) {
    this.debouncedRequestFn(input, callback);
  }

  handleChange(value, actionMeta) {
    if (actionMeta.action === "select-option") {
      let jobTitle = {
        id: value.value,
        title: value.label,
      };

      if (this.props.clientIsClientJobLibrary) {
        jobTitle.clientJobLibraryInfo = value.clientJobLibraryInfo;
      }

      this.props.onChange(jobTitle);
    } else {
      this.props.onChange(null);
    }
  }

  formatOptionLabel(option, selectCtx) {
    // console.log('option', option);
    // console.log('selectCtx', selectCtx);
    //
    let mappingSharedByInfo = null;
    if (option.sharedBy) {
      mappingSharedByInfo = (
        <span className="rs-tag">
          <span className="glyphicon glyphicon-user" /> {option.sharedBy.firstName}{" "}
          {option.sharedBy.lastName}
        </span>
      );
    }

    return (
      <div>
        {option.label}
        {mappingSharedByInfo}
        {option.certifiedCountriesCount > 0 && (
          <span className="rs-tag rs-tag-green">
            <span className="glyphicon glyphicon-globe" />{" "}
            {option.certifiedCountriesCount}
          </span>
        )}
        {option.adhocCountriesCount > 0 && (
          <span className="rs-tag rs-tag-red">
            <span className="glyphicon glyphicon-globe" /> {option.adhocCountriesCount}
          </span>
        )}
      </div>
    );
  }

  render() {
    const { disabled } = this.props;

    let extraProps = {};

    if (this.props.clientIsClientJobLibrary) {
      extraProps.formatOptionLabel = this.formatOptionLabel;
    }

    return (
      <AsyncSelect
        name="jobTitle"
        inputId={this.props.id || "jobTitleSelect"}
        placeholder="Job Title"
        backspaceRemovesValue
        openMenuOnFocus
        isDisabled={disabled}
        tabSelectsValue={false}
        defaultOptions
        cacheOptions={false}
        loadOptions={this.loadOptions}
        onChange={this.handleChange}
        styles={reactSelectStyles}
        theme={reactSelectTheme}
        {...extraProps}
      />
    );
  }
}

export default JobTitleSelectNew;
