import React, { useCallback } from "react";
import AsyncSelect from "react-select/lib/Async";

// @ts-expect-error
import { logAsyncOperationError } from "../../../../utils/logging";

import type { FetchGraphQLAPIResponse } from "../../../../types/fetch";
import { useVal5KAdminContext } from "../../context/Val5KAdminContext";

const defaultPageSize = 25;
const defaultPlaceholder = "Select rate card...";

export type RateCardNodeType = {
  id: number;
  name: string;
  searchCount: number;
  owner: {
    userId: number;
    userName: string;
    client: {
      clientId: number;
      clientName: string;
    };
  };
};

type RateCardResponseGraphQLType = {
  viewer: {
    allRateCards: {
      totalCount: number;
      pageInfo: { hasNextPage: boolean };
      edges: { node: RateCardNodeType }[];
    };
  };
};

type BaseAsyncSelectProps = React.ComponentProps<typeof AsyncSelect<RateCardNodeType>>;

export type ChangeFuncType = BaseAsyncSelectProps["onChange"];

type RateCardSingleSelectProps = {
  value: BaseAsyncSelectProps["value"];
  placeholder?: string;
  pageSize?: number;
  onChange: ChangeFuncType;
};

const RateCardSingleSelect = (props: RateCardSingleSelectProps) => {
  const { value, placeholder, pageSize, onChange } = props;

  const { fetchGraphQL, showModalError } = useVal5KAdminContext();

  const query = `
    query getRateCardsList($search: String, $first: Int, $offset: Int) {
      viewer {
        allRateCards(search: $search, first: $first, offset: $offset, section: ADMIN, order: {field: CREATE_DATE, direction: DESC}) {
          totalCount
          pageInfo {
            hasNextPage
          }
          edges {
            node {
              id: ratecardId
              name
              owner {
                userId
                userName: username
                client {
                  clientId
                  clientName: name
                }
              }
              searchCount
            }
          }
        }
      }
    }
  `;

  const loadOptions = useCallback(
    async (inputValue: string) => {
      const variables = {
        search: inputValue,
        first: pageSize,
        // offset: Math.max(0, page)
      };

      try {
        const response: FetchGraphQLAPIResponse<RateCardResponseGraphQLType> =
          await fetchGraphQL(query, variables);
        return response.data.data.viewer.allRateCards.edges.map((edge) => edge.node);
      } catch (err: any) {
        logAsyncOperationError("RateCardSingleSelect", err);
        showModalError("Error occurred while retrieving rate cards list.");
      }
    },
    [pageSize, showModalError, fetchGraphQL, query]
  );

  const getOptionValue = (option: RateCardNodeType) => String(option.id);
  const getOptionLabel = (option: RateCardNodeType) => String(option.searchCount);

  const formatOptionLabel = (option: RateCardNodeType) => {
    const rateCardId = option.id;
    const rateCardName = option.name || "";
    const clientName = option.owner.client.clientName || "";
    const userName = option.owner.userName || "";

    return (
      <span>
        (Client: {clientName}, Username: {userName})&nbsp;
        {`#${rateCardId}`} - {rateCardName}
      </span>
    );
  };

  return (
    <AsyncSelect<RateCardNodeType>
      value={value}
      isSearchable
      loadOptions={loadOptions}
      defaultOptions
      placeholder={placeholder}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      formatOptionLabel={formatOptionLabel}
      onChange={onChange}
    />
  );
};
RateCardSingleSelect.displayName = "RateCardSingleSelect";
RateCardSingleSelect.defaultProps = {
  placeholder: defaultPlaceholder,
  pageSize: defaultPageSize,
};

export default RateCardSingleSelect;
