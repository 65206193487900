// @ts-ignore
import R from "ramda";

type ObjectKeyType = string | number;

export type EnumOption<T = ObjectKeyType> = [string, string, T];

type EnumKey<T> = EnumOption<T>[0];
type EnumLabel<T> = EnumOption<T>[1];
type EnumId<T> = EnumOption<T>[2];

const key = R.nth(0);
const label = R.nth(1);
const id = R.nth(2);

type LookupObject<T extends ObjectKeyType> = { [K in T]: EnumOption<T> };

export default class Enum<T extends ObjectKeyType> {
  /**
   * Example:
   *  const JOB_STATUS = new Enum(
   *     [ 'PENDING', 'Pending', 1 ],
   *     [ 'ADDED', 'Added', 2 ],
   *     [ 'FAILURE', 'Failure', 3 ]
   *   );
   *
   * JOB_STATUS.PENDING -> 1
   * JOB_STATUS.getLabel(JOB_STATUS.PENDING) -> 'Pending'
   * JOB_STATUS.getKey(JOB_STATUS.PENDING) -> 'PENDING'
   *
   * @param values
   */

  [key: EnumKey<T>]: EnumId<T>;

  // @ts-ignore   ignore non-indexed property failure
  _lookup: LookupObject<T>;

  constructor(...values: EnumOption<T>[]) {
    this._lookup = values.reduce((lookupObj: LookupObject<T>, value: EnumOption<T>) => {
      const _key = key(value) as EnumKey<T>;
      const _label = label(value) as EnumLabel<T>;
      const _id = id(value) as EnumId<T>;

      lookupObj[_id] = [_key, _label, _id];
      this[_key] = _id;

      return lookupObj;
    }, {} as LookupObject<T>);
  }

  // @ts-ignore   ignore non-indexed property failure
  getLabel(id: EnumId<T>): EnumLabel {
    const value = this._lookup[id];

    return value != null ? label(value) : value;
  }

  // @ts-ignore   ignore non-indexed property failure
  getKey(id: EnumId<T>): EnumKey {
    const value = this._lookup[id];

    return value != null ? key(value) : value;
  }

  // @ts-ignore   ignore non-indexed property failure
  keys(sorted: boolean = true): EnumKey<T>[] {
    const keys = Object.values(this._lookup).map(key) as EnumKey<T>[];

    return sorted ? keys.sort() : keys;
  }

  // @ts-ignore   ignore non-indexed property failure
  labels(sorted: boolean = true): EnumLabel<T>[] {
    const labels = Object.values(this._lookup).map(label) as EnumLabel<T>[];

    return sorted ? labels.sort() : labels;
  }

  // @ts-ignore   ignore non-indexed property failure
  values(sorted: boolean = true): EnumId<T>[] {
    const values = Object.keys(this._lookup) as EnumId<T>[];

    return sorted ? values.sort() : values;
  }

  /**
   *  Helper method to get options list for the input in form
   *  [{
   *    value: 1,
   *    label: One
   *  }, ...]
   *  Internal keys of resulting options are totaly configurable.
   *  Options could be sorted by specified keys
   */
  // @ts-ignore   ignore non-indexed property failure
  options(
    valueKey: string = "value",
    labelKey: string = "label",
    sortedBy: string | null = "value"
  ) {
    const options = Object.values<EnumOption>(this._lookup).map((value) => ({
      [valueKey]: id(value) as EnumId<T>,
      [labelKey]: label(value) as EnumLabel<T>,
    }));

    return (sortedBy ? R.sortBy(R.prop(sortedBy))(options) : options) as typeof options;
  }
}
