import React from "react";

import Stack from "../../../components/lib/Stack";
import Alert from "../../../components/lib/Alert";
import { Link } from "../../../components/lib/Link";

import { useProgramContext } from "../ProgramDataProvider";
import { useStoredIndexContext } from "../StoredIndexDataProvider";

type ContractorsAlertProps = { count: number };

function getPluralWordAttributes(itemsCount: number) {
  const manyItems = itemsCount > 1;
  const ending: string = manyItems ? "s" : "";
  const isOrAre: string = manyItems ? "are" : "is";

  return { ending, isOrAre };
}

export const FailedContractorsAlert = (props: ContractorsAlertProps) => {
  const { count } = props;
  const { programId } = useProgramContext();
  const { indexId } = useStoredIndexContext();

  if (count <= 0) return null;

  const { ending, isOrAre } = getPluralWordAttributes(count);

  return (
    <Alert color="warning">
      <Stack fill css={{ alignItems: "start", gap: "$2" }}>
        <h3>
          {count} failed row{ending} found in the {indexId != null ? "view" : "index"}.
        </h3>
        {indexId != null && (
          <span>
            This View lacks <b>{count}</b> row{ending}, which {isOrAre} failed processing.
          </span>
        )}
        <Link to={`/private-index/programs/${programId}/contractors/failed`}>
          <u>See all failed rows.</u>
        </Link>
      </Stack>
    </Alert>
  );
};
FailedContractorsAlert.displayName = "FailedContractorsAlert";

export const NeedApprovalContractorsAlert = (props: ContractorsAlertProps) => {
  const { count } = props;
  const { programId } = useProgramContext();
  const { indexId } = useStoredIndexContext();

  if (count <= 0) return null;

  const { ending, isOrAre } = getPluralWordAttributes(count);

  return (
    <Alert color="warning">
      <Stack fill css={{ alignItems: "start", gap: "$2" }}>
        <h3>
          {count} pending approval row{ending} found in the{" "}
          {indexId != null ? "view" : "index"}.
        </h3>
        {indexId != null && (
          <span>
            This View lacks <b>{count}</b> row{ending}, which {isOrAre} all require
            additional approval.
          </span>
        )}
        <Link to={`/private-index/programs/${programId}/contractors/need-approval`}>
          <u>See all rows pending approval.</u>
        </Link>
      </Stack>
    </Alert>
  );
};
NeedApprovalContractorsAlert.displayName = "NeedApprovalContractorsAlert";
