import type { SearchAllocatorUserDataObject } from "./types";

// types

export type TargetFilterTypeLitteral = "active" | "inactive" | "all";

export type SortByFilterTypeLitteral =
  | "searchesAllocated"
  | "searchesRemainingAsc"
  | "searchesRemainingDesc"
  | "searchesUsed"
  | "usernameAZ"
  | "usernameZA";

export type StateFilterType = {
  filterBy: string;
  targetFilter: TargetFilterTypeLitteral;
  sortBy: SortByFilterTypeLitteral;
};

// handler filters

function nameFilter(user: SearchAllocatorUserDataObject, filterBy: string) {
  const firstName = user["firstName"] || "";
  const lastName = user["lastName"] || "";
  const name = String(firstName) + String(lastName);
  return typeof filterBy === "string" && name.toLowerCase().includes(filterBy);
}

function statusFilter(
  user: SearchAllocatorUserDataObject,
  filterBy: TargetFilterTypeLitteral
) {
  switch (filterBy) {
    case "active":
      return user["isActive"] === true;
    case "inactive":
      return user["isActive"] === false;
    case "all":
      return true;
    default:
      return false;
  }
}

function sortFilter(sortBy: SortByFilterTypeLitteral) {
  const sortFunctions: Record<
    SortByFilterTypeLitteral,
    (a: SearchAllocatorUserDataObject, b: SearchAllocatorUserDataObject) => number
  > = {
    searchesAllocated: (a, b) => b["searchesAllocated"] - a["searchesAllocated"],

    searchesRemainingDesc: (a, b) => b["searchesRemaining"] - a["searchesRemaining"],

    searchesRemainingAsc: (a, b) => a["searchesRemaining"] - b["searchesRemaining"],

    searchesUsed: (a, b) => b["searchesUsed"] - a["searchesUsed"],

    usernameAZ: (a, b) =>
      a["username"].toLowerCase().localeCompare(b["username"].toLowerCase()),

    usernameZA: (a, b) =>
      b["username"].toLowerCase().localeCompare(a["username"].toLowerCase()),
  };

  const sortFunction = sortFunctions[sortBy];
  if (!sortFunction) {
    throw new Error("Invalid sort key");
  }
  return sortFunction;
}

export function applyUserFilters(
  users: SearchAllocatorUserDataObject[],
  filters: StateFilterType
) {
  return users
    .filter((user) => statusFilter(user, filters.targetFilter))
    .filter((user) => (filters.filterBy ? nameFilter(user, filters.filterBy) : true))
    .sort(sortFilter(filters.sortBy));
}

export const defaultFilters: StateFilterType = {
  filterBy: "",
  targetFilter: "active",
  sortBy: "searchesAllocated",
};
