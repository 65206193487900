// @flow

import React from "react";
import { observer } from "mobx-react";
import SectionDisplayOptions from "./SectionDisplayOptions";
import DataItemsDisplayOptions from "./DataItemsDisplayOptions";
import LevelViewDisplayOptions from "./LevelViewDisplayOptions";
import { InlineElements } from "../../../components/lib/Inline";
import Button from "../../../components/lib/Button";
import Spacer from "../../../components/lib/Spacer";

const DisplayOptions = (props) => {
  const store = props.store;
  const rateType = store.search.frequency.title.toLowerCase();
  const resultsByLevel = store.search.resultsByLevel;
  const threeLevelsViewUser = store.threeLevelsViewUser;
  // eslint-disable-next-line
  let hideUpdateOption = true;

  for (let level in resultsByLevel) {
    if (!resultsByLevel.hasOwnProperty(level)) continue;

    if (!resultsByLevel[level]) continue;

    if (resultsByLevel[level].change.needsUpdate) {
      // eslint-disable-next-line
      hideUpdateOption = false;
      break;
    }
  }

  const showSectionOptions = rateType === "contract" && !store.search.showThreeLevelsView;

  return (
    <InlineElements>
      <Button icon="info-circle" onClick={store.levelsGuideModal.showModal}>
        Levels Guide
      </Button>
      <Spacer />
      {showSectionOptions && <SectionDisplayOptions store={store} />}

      <DataItemsDisplayOptions store={store} />

      {threeLevelsViewUser && (
        <LevelViewDisplayOptions
          store={store.search}
          onOptionChange={store.handleLevelViewDisplayOptionChange}
        />
      )}
    </InlineElements>
  );
};

export default observer(DisplayOptions);
