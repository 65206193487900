// @flow

import React, { Component } from "react";
import { Link } from "react-router";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserList from "./components/UserList";
import SearchBox from "../../ratecards/components/SearchBox";
import Modal from "../../ratecards/components/Modal";
import classNames from "classnames";

/**
 * List component for rate card list page
 */
class List extends Component {
  constructor() {
    super();
    this.redirectToCreateUser = this.redirectToCreateUser.bind(this);
  }

  redirectToCreateUser() {
    this.context.router.push({ pathname: "/admin/create" });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /**
   * Render rate card list page
   * @return {XML}
   */
  render() {
    const store = this.props.store.userManagementListPTStore;

    return (
      <div className="view list">
        <div className="rc-container bring-forward">
          <div className="header-action-bar">
            <h2>User Stats Admin</h2>
            <p className="subtext" />
            <div className="clearfix" />
          </div>
          <div className="header-action-bar marginTopzero">
            {!store.clientId && (
              <div className="pull-left">
                <SearchBox
                  value={store.userFilter.textToLookFor}
                  onChange={store.userFilter.onTextToLookForChange}
                  onSearch={store.userFilter.onApply}
                  style={{
                    width: "260px",
                  }}
                />
              </div>
            )}
            <div className="btn-group pull-right rc-center">
              {store.isEditing && (
                <button className="btn btn-yellow" onClick={store.handleStopEdit}>
                  Stop Editing{" "}
                </button>
              )}
              {!store.isEditing && (
                <div>
                  <button className="btn btn-green" onClick={store.handleStartEdit}>
                    <FontAwesomeIcon icon="edit" fixedWidth className="icon" />
                    Edit
                  </button>
                  <Link
                    to={{
                      pathname: "/admin/users/create",
                    }}
                    className="btn btn-green"
                    data-testid="create-user"
                  >
                    <FontAwesomeIcon icon="plus" fixedWidth className="icon" /> Create
                    User
                  </Link>
                  <button className="btn" onClick={store.helpModal.showModal}>
                    <FontAwesomeIcon icon="question-circle" fixedWidth className="icon" />
                    Help
                  </button>
                </div>
              )}
            </div>
            <div className="clearfix" />
          </div>
          <UserList store={store} />
        </div>

        {
          <div
            className={classNames("ratecards-edit-actions", {
              hidden: store.isEditing === null,
              "bottom-appear": store.isEditing === true,
              "bottom-disappear": store.isEditing === false,
            })}
          >
            <button
              className="btn"
              disabled={store.selectedCount === 0}
              onClick={store.exportUsers}
            >
              <FontAwesomeIcon icon="download" fixedWidth className="icon" /> Export
            </button>
          </div>
        }
        {
          <div
            className={classNames("rc-container-backdrop", {
              hidden: store.isEditing === null,
              "fade-in": store.isEditing === true,
              "fade-out": store.isEditing === false,
            })}
          />
        }

        <Modal show={store.helpModal.show} onHide={store.helpModal.hideModal}>
          <div className="container-section header">
            <h4>Help</h4>
          </div>
          <div className="container-section footer">
            <p>Here you can see all of your regions...</p>
          </div>
        </Modal>
      </div>
    );
  }
}

export default observer(List);
