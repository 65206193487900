import React from "react";

import { globalContextDefaults, GlobalContextObject } from "../../../globalContext";
import { omitObjectKeys } from "../../../utils/object";

export type SearchAllocatorContextObject = Omit<
  GlobalContextObject,
  "fetchAPINew" | "fetchGraphQLNew"
>;

const searchAllocatorContextDefaults = {
  ...omitObjectKeys(globalContextDefaults, ["fetchAPINew", "fetchGraphQLNew"]),
} as unknown as SearchAllocatorContextObject;

export const SearchAllocatorContext = React.createContext<SearchAllocatorContextObject>(
  searchAllocatorContextDefaults
);

export function useSearchAllocatorContext() {
  return React.useContext(SearchAllocatorContext);
}

export default SearchAllocatorContext;
