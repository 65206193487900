import React from "react";
import Box from "../../../components/lib/Box";
import Inline from "../../../components/lib/Inline";

const RsViewSkills = ({ skills }) => {
  return (
    <Inline css={{ gap: "$2" }}>
      {skills &&
        skills.map((skill, idx) => (
          <Box
            css={{
              border: "1px solid #E4E4E4",
              borderRadius: "$rounded",
              padding: "$2",
              paddingTop: "$0_5",
              paddingBottom: "$0_5",
            }}
            key={`skills_${skill}_${idx}`}
          >
            {skill}
          </Box>
        ))}
    </Inline>
  );
};

export default RsViewSkills;
