import React, { Fragment } from "react";

import Stack from "../../../../components/lib/Stack";
import { THead, TH, TD, TR } from "../../../../components/lib/Table";
import Icon from "../../../../components/lib/Icon";
import Text from "../../../../components/lib/Text";
import { InlineElements } from "../../../../components/lib/Inline";
import TextCrop from "../../../validator5K_admin/components/TextCrop";
import { Val5000JobFeedbackBlock } from "../JobFeedback";
import JobInternalValuesBlock from "../JobInternalValuesBlock";
import {
  decimalFormatter,
  compareDecimals,
  getRegionString,
  getLocationString,
} from "../../constants";
import { RATE_TYPES, RATE_FEEDBACK_STATUS_TYPES } from "../../types";
import { IconButton } from "../../../../components/lib/Button";
import RatesTableOrderedBase from "./base";
import {
  getLevelRateResultsForJob,
  getLevelRatesFeedbackForJob,
  getJobRateTypeParameters,
  LEVEL_KEYS,
} from "./utils";
import { useRateDisplayContext } from "../../context/RateDisplayContext";

import type {
  JobDataMap,
  RatesFeedbackOrderedMap,
  JobsDataOrderedMap,
} from "../../types";
import type { LEVEL_KEYS_TYPE } from "./utils";
import type {
  RatesTableOrderedBaseProps,
  RatesTableOrderedHeadBaseProps,
  RatesTableOrderedRowBaseProps,
} from "./base";
import {
  SelectSingleRowContext,
  SelectSingleRowContextObject,
  useSelectSingleRowContext,
} from "../../context/SelectSingleRowContext";

type RatesBlockProps = {
  level: LEVEL_KEYS_TYPE;
  jobData: JobDataMap;
  allJobsData: JobsDataOrderedMap;
  allFeedbackData?: RatesFeedbackOrderedMap;
};

function RatesBlock(props: RatesBlockProps) {
  const { jobData, allJobsData, allFeedbackData, level } = props;

  const { resultingCurrencySymbol, convertToDisplayCurrency, convertToDisplayRateType } =
    useRateDisplayContext();

  const jobId = jobData.get("id");
  const levelRates = getLevelRateResultsForJob(allJobsData, jobId, level);
  const levelRatesFeedback = getLevelRatesFeedbackForJob(
    allJobsData,
    jobId,
    level,
    allFeedbackData
  );
  const { rateType, rateMultiplier } = getJobRateTypeParameters(jobData);

  let payRate: number | null =
    level === LEVEL_KEYS.LOW
      ? levelRates.get("pay_rate_min")
      : levelRates.get("pay_rate_max");
  let payRateFeedback =
    level === LEVEL_KEYS.LOW
      ? levelRatesFeedback.get("pay_rate_min")
      : levelRatesFeedback.get("pay_rate_max");

  const hasFeedback = !!(
    payRate &&
    payRateFeedback &&
    compareDecimals(payRate, payRateFeedback) !== 0
  );

  // convert to display rate_type
  if (rateType !== RATE_TYPES.ANNUAL) {
    payRate = convertToDisplayRateType(payRate, rateType, rateMultiplier);
    payRateFeedback = convertToDisplayRateType(payRateFeedback, rateType, rateMultiplier);
  }

  // convert to display currency
  payRate = convertToDisplayCurrency(payRate);
  payRateFeedback = convertToDisplayCurrency(payRateFeedback);

  // format
  const payRateMinString = decimalFormatter(payRate, false, resultingCurrencySymbol);
  const payRateMinFeedbackString = decimalFormatter(
    payRateFeedback,
    false,
    resultingCurrencySymbol
  );

  return (
    <InlineElements css={{ justifyContent: "center" }}>
      {hasFeedback ? (
        <Text color="negative">
          <s>{payRateMinString}</s>
        </Text>
      ) : (
        <Text>{payRateMinString}</Text>
      )}
      {hasFeedback && payRateMinFeedbackString}
    </InlineElements>
  );
}

RatesBlock.displayName = "RatesBlock";

const TableHead = (props: RatesTableOrderedHeadBaseProps) => {
  const { shortMode = false } = props;

  return (
    <THead>
      <TR>
        {!shortMode && <TH />}
        <TH>Location</TH>
        <TH>Industry</TH>
        <TH>Title</TH>
        <TH>Status</TH>
        <TH>
          Lowest&nbsp;Wage
          <br />
          (least&nbsp;amount&nbsp;of&nbsp;experience)
        </TH>
        <TH>
          Highest&nbsp;Wage
          <br />
          (most&nbsp;amount&nbsp;of&nbsp;experience)
        </TH>
        <TH>Comment</TH>
      </TR>
    </THead>
  );
};

TableHead.displayName = "TableHead";

const TableRowWithDetails = (props: RatesTableOrderedRowBaseProps) => {
  const { jobId, jobsData, feedbackData, shortMode = false } = props;
  const jobData = jobsData.get(jobId);

  const { selectedId } = useSelectSingleRowContext();

  if (selectedId === jobId && !shortMode) {
    return (
      <Fragment>
        <TableRow
          jobId={jobId}
          jobsData={jobsData}
          feedbackData={feedbackData}
          shortMode={shortMode}
        />
        <TableRowDetails jobData={jobData} />
      </Fragment>
    );
  }

  return (
    <TableRow
      jobId={jobId}
      jobsData={jobsData}
      feedbackData={feedbackData}
      shortMode={shortMode}
    />
  );
};

TableRowWithDetails.displayName = "TableRowWithDetails";

const TableRow = (props: RatesTableOrderedRowBaseProps) => {
  const { jobId, jobsData, feedbackData, shortMode } = props;

  const { selectedId, onChangeSelected } = useSelectSingleRowContext();

  const jobData = jobsData.get(jobId);
  const jobIndustry = jobData.get("industry_name");
  const jobTitle = jobData.get("title");
  const jobDescription = jobData.get("description");
  const isSelected = selectedId === jobId;

  const lowLevelRatesFeedback = getLevelRatesFeedbackForJob(
    jobsData,
    jobId,
    LEVEL_KEYS.LOW,
    feedbackData
  );
  const highLevelRatesFeedback = getLevelRatesFeedbackForJob(
    jobsData,
    jobId,
    LEVEL_KEYS.HIGH,
    feedbackData
  );

  const feedbackStatus =
    lowLevelRatesFeedback.get("status") ||
    highLevelRatesFeedback.get("status") ||
    RATE_FEEDBACK_STATUS_TYPES.NOT_VALIDATED;
  const feedbackComment =
    lowLevelRatesFeedback.get("comment") || highLevelRatesFeedback.get("comment");

  const jobLocation = getLocationString(jobData);
  const isRowRegionBased = !!jobData.get("region");
  let jobRegion = getRegionString(jobData);
  if (isRowRegionBased && jobRegion && jobRegion.toLowerCase().indexOf("region") < 0) {
    jobRegion += " (region)";
  }

  const handleShowJobDetails = (rowId: number) => {
    onChangeSelected(selectedId === rowId ? null : rowId);
  };

  return (
    <TR>
      {!shortMode && (
        <TD>
          <IconButton
            icon={isSelected ? "chevron-down" : "chevron-right"}
            color="green"
            variant="outlined"
            title="See internal data on this title"
            onClick={() => handleShowJobDetails(jobData.get("id"))}
            css={{ width: "$7", height: "$7" }}
          />
        </TD>
      )}
      <TD>
        <TextCrop
          mode="tooltip"
          title="Location"
          text={isRowRegionBased ? jobRegion : jobLocation}
          emptyStub=""
        />
      </TD>
      <TD>
        <TextCrop mode="tooltip" title="Industry" text={jobIndustry} emptyStub="" />
      </TD>
      <TD>
        <Text underline>
          {shortMode ? (
            <TextCrop mode="tooltip" title="Title" text={jobTitle} emptyStub="" />
          ) : (
            <TextCrop
              mode="modal"
              title={jobTitle}
              text={jobTitle}
              fullText={jobDescription}
              emptyStub=""
            />
          )}
        </Text>
      </TD>
      <TD>
        {feedbackStatus === RATE_FEEDBACK_STATUS_TYPES.APPROVED && (
          <Text color="positive">
            <Icon icon="check" />
            &nbsp;
          </Text>
        )}
        {feedbackStatus === RATE_FEEDBACK_STATUS_TYPES.DENIED && (
          <Text color="negative">
            <Icon icon="times" />
            &nbsp;
          </Text>
        )}
        {feedbackStatus === RATE_FEEDBACK_STATUS_TYPES.DONT_KNOW && (
          <Text color="accent">
            <Icon icon="question" />
            &nbsp;
          </Text>
        )}
        {feedbackComment && <Icon icon={["far", "commenting"]} />}
      </TD>
      <TD>
        <RatesBlock
          level={LEVEL_KEYS.LOW}
          allJobsData={jobsData}
          allFeedbackData={feedbackData}
          jobData={jobData}
        />
      </TD>
      <TD>
        <RatesBlock
          level={LEVEL_KEYS.HIGH}
          allJobsData={jobsData}
          allFeedbackData={feedbackData}
          jobData={jobData}
        />
      </TD>
      <TD>
        <TextCrop mode="tooltip" title="Comment" text={feedbackComment} emptyStub="" />
      </TD>
    </TR>
  );
};

TableRow.displayName = "TableRow";

const TableRowDetails = (props: { jobData: JobDataMap }) => {
  return (
    <TR>
      <TD colSpan={999} css={{ maxWidth: "900px", background: "$white", padding: "$6" }}>
        <Stack css={{ alignItems: "flex-start" }}>
          <JobInternalValuesBlock embedded />
          <Val5000JobFeedbackBlock job={props.jobData} embedded />
        </Stack>
      </TD>
    </TR>
  );
};

TableRowDetails.displayName = "TableRowDetails";

export type RatesTableOrderedWithFeedbackPreviewProps = RatesTableOrderedBaseProps & {
  feedbackData: RatesFeedbackOrderedMap;
};

export default function RatesTableOrderedWithFeedbackPreview(
  props: RatesTableOrderedWithFeedbackPreviewProps
) {
  const { jobsData, feedbackData, shortMode = false } = props;

  // state

  const [selectedRowId, setSelectedRowId] = React.useState<number | null>(null);

  // context

  const selectRowContextValues: SelectSingleRowContextObject = React.useMemo(
    () => ({
      selectedId: selectedRowId,
      onChangeSelected: setSelectedRowId,
    }),
    [selectedRowId]
  );

  return (
    <SelectSingleRowContext.Provider value={selectRowContextValues}>
      <RatesTableOrderedBase
        jobsData={jobsData}
        feedbackData={feedbackData}
        tableHeadImpl={TableHead}
        tableRowImpl={TableRowWithDetails}
        shortMode={shortMode}
      />
    </SelectSingleRowContext.Provider>
  );
}

RatesTableOrderedWithFeedbackPreview.displayName = "RatesTableOrderedWithFeedbackPreview";
