import { useEffect } from "react";
import { assign, createMachine } from "xstate";
import { useMachine } from "@xstate/react";
import xstateLogger from "../utils/xstateLogger";

type FieldKeyType =
  | "CREATE_DATE"
  | "OWNER_FIRST_NAME"
  | "OWNER_LAST_NAME"
  | "OWNER_LOGIN"
  | "SHARE";
type DirectionKeyType = "ASC" | "DESC";

type FieldSortConfigType = {
  field: FieldKeyType;
  direction: DirectionKeyType;
};

export type SortMachineContextType = {
  sortFieldsMap: Map<FieldKeyType, FieldSortConfigType>;
};

type AddSortFieldEventType = {
  type: "ADD_SORT_FIELD";
  field: FieldKeyType;
  direction: DirectionKeyType;
};

type RemoveSortFieldEventType = {
  type: "REMOVE_SORT_FIELD";
  field: FieldKeyType;
};

type RemoveAllSortFieldsEventType = {
  type: "REMOVE_ALL_SORT_FIELDS";
};

const initialContextValue = new Map<FieldKeyType, FieldSortConfigType>([
  ["CREATE_DATE", { field: "CREATE_DATE", direction: "DESC" }],
]);

const rcListSortMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QCcDGBaANgS1gF3VgHtkCAzbMTCWAOgFcA7Y0yAYgEEARLgfQGUA8gCUAKrwBiASQCiAGS6JQAByKxsebEUZKQAD0QAmAJyHaAdkMA2AKwAGc8YCMTmwGYALDZsAaEAE9EAA5jWmNw43MQqyC7O1MAXwS-NCxcAhZySmo6TPZuPiExSVkFXVV1TW1dAwQPQz9AhFdzWg8Ijys3Q067GKSUjBx8QhIsqhpaPIg2YRkAWUEANRleDjk5ARFxaXkufnK1DS0dJH0jJw9aOyCbSKdzc3tLj0bEN1uwiMsnG7cYpwDECpYYZMboCgTXJjdhzRYrLbFXZlM4VY7VM61QyXa63e6PZ4eV4BRAPKxfcKdX7eQxuGxBJLJECMIgQOC6EHpUakCHZSZMaaHSonGqIDyOWhBJw9RwxWweEK+EnNJxBNoRKyPYziqweQFMzkjTK8qFTGEQIXo06gWpRNpuf52JzOcxWWl2BrKnqhdrhJyeN3S6VAw1gnmQnKWqrW87NIJvOOMhJAA */
  createMachine(
    {
      context: { sortFieldsMap: initialContextValue },
      tsTypes: {} as import("./rcListSortMachine.typegen").Typegen0,
      schema: {
        context: {} as SortMachineContextType,
        events: {} as
          | AddSortFieldEventType
          | RemoveSortFieldEventType
          | RemoveAllSortFieldsEventType,
      },
      predictableActionArguments: true,
      id: "RCListSortMachine",
      initial: "sorted",
      states: {
        unsorted: {
          on: {
            ADD_SORT_FIELD: {
              actions: "addSortField",
              target: "sorted",
            },
          },
        },
        sorted: {
          on: {
            ADD_SORT_FIELD: {
              actions: "addSortField",
              target: "sorted",
            },
            REMOVE_ALL_SORT_FIELDS: {
              actions: "removeAllSortFields",
              target: "sorted",
            },
            REMOVE_SORT_FIELD: [
              {
                actions: "removeSortField",
                cond: "hasSingleSortField",
                target: "unsorted",
              },
              {
                actions: "removeSortField",
                target: "sorted",
              },
            ],
          },
        },
      },
    },
    {
      actions: {
        addSortField: assign({
          sortFieldsMap: (
            context: SortMachineContextType,
            event: AddSortFieldEventType
          ) => {
            const copy = new Map(context.sortFieldsMap);
            return copy.set(event.field, {
              field: event.field,
              direction: event.direction ?? "ASC",
            });
          },
        }),
        removeSortField: assign({
          sortFieldsMap: (
            context: SortMachineContextType,
            event: RemoveSortFieldEventType
          ) => {
            const copy = new Map(context.sortFieldsMap);
            copy.delete(event.field);
            return copy;
          },
        }),
        removeAllSortFields: assign({
          sortFieldsMap: (
            context: SortMachineContextType,
            event: RemoveAllSortFieldsEventType
          ) => {
            return initialContextValue;
          },
        }),
      },
      guards: {
        hasSingleSortField: (ctx) => true,
      },
    }
  );

export function useRCListSortMachine(machineName: string) {
  const [rcListSortState, sendToRCListSortMachine, rcListSortService] =
    useMachine(rcListSortMachine);

  // state machine logging
  useEffect(() => {
    // @ts-ignore
    const subscription = rcListSortService.subscribe((state) =>
      // @ts-ignore
      xstateLogger(state, machineName)
    );
    return subscription.unsubscribe;
  }, [machineName, rcListSortService]);

  return {
    rcListSortState,
    sendToRCListSortMachine,
    rcListSortService,
  };
}

export default rcListSortMachine;
