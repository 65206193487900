import React, { useCallback } from "react";

import Inline from "../../../components/lib/Inline";
import Stack from "../../../components/lib/Stack";
import Icon from "../../../components/lib/Icon";
import Text from "../../../components/lib/Text";
import Button from "../../../components/lib/Button";
import Alert from "../../../components/lib/Alert";
import DownloadPOSTButtonExt from "../../private_index/components/DownloadPOSTButtonExt";
import { ButtonGroupRight } from "../../../components/lib/ButtonGroup";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogClose,
  DialogDescription,
  DialogActions,
} from "../../../components/lib/Dialog";

import type {
  FiltersQueryObject,
  UrlQueryObject,
} from "../../../components/tables/types";

type PaymentReportExportConfigProps = {
  show: boolean;
  itemsTotal: number;
  itemsPerPage: number;
  getQueryArgs: (exportCurrentPage: boolean) => UrlQueryObject | null;
  getFormData: () => FiltersQueryObject;
  onClose: () => void;
  onError: (err: Error) => void;
};

const PaymentReportExportConfig = (props: PaymentReportExportConfigProps) => {
  const { show, itemsTotal, itemsPerPage, getQueryArgs, getFormData, onClose, onError } =
    props;
  const hasData = itemsTotal > 0;
  const isSinglePageExport = hasData && itemsTotal <= itemsPerPage;

  const handleError = useCallback(
    (err: Error) => {
      onClose();
      onError(err);
    },
    [onError, onClose]
  );

  return (
    <Dialog open={show} onOpenChange={onClose}>
      <DialogContent css={{ minWidth: "700px" }}>
        <DialogTitle asChild>
          <Inline css={{ justifyContent: "space-between" }}>
            <Text as="h4">Export Payment Report to Excel.</Text>
            <DialogClose asChild>
              <Icon icon="times" />
            </DialogClose>
          </Inline>
        </DialogTitle>
        <DialogDescription css={{ backgroundColor: "inherit" }} asChild>
          <Stack css={{ fontSize: "$sm", alignItems: "flex-start" }}>
            {!hasData && <Text as="h5">No data to export.</Text>}
            {hasData && (
              <Alert fill color="warning">
                <Text>
                  <Text bold underline italic>
                    Please Note
                  </Text>
                  : Exporting payment report could take awhile depending on the size of
                  the output.
                </Text>
              </Alert>
            )}
            {hasData && (
              <Stack css={{ alignItems: "flex-start", gap: "$2", "& h5": { margin: 0 } }}>
                <Text as="h5">Export data using following options:</Text>
                {!isSinglePageExport && (
                  <DownloadPOSTButtonExt
                    size="small"
                    icon={["far", "file-excel"]}
                    color="brand"
                    variant="outlined"
                    loadingText="Export displayed rows only"
                    service="argus"
                    endpoint="v1/attempts/payment-report/export/"
                    getQueryArgs={() => getQueryArgs(true)}
                    getFormData={getFormData}
                    onSuccess={onClose}
                    onError={handleError}
                  >
                    Export displayed rows only
                  </DownloadPOSTButtonExt>
                )}
                <DownloadPOSTButtonExt
                  size="small"
                  icon={["far", "file-excel"]}
                  color="brand"
                  variant="outlined"
                  loadingText="Export whole dataset"
                  service="argus"
                  endpoint="v1/attempts/payment-report/export/"
                  getQueryArgs={() => getQueryArgs(false)}
                  getFormData={getFormData}
                  onSuccess={onClose}
                  onError={handleError}
                >
                  Export whole dataset
                </DownloadPOSTButtonExt>
              </Stack>
            )}
          </Stack>
        </DialogDescription>
        <DialogActions>
          <ButtonGroupRight fill>
            <Button size="large" onClick={onClose}>
              Close
            </Button>
          </ButtonGroupRight>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
PaymentReportExportConfig.displayName = "PaymentReportExportConfig";

export default PaymentReportExportConfig;
