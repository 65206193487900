import React from "react";
import { Set } from "immutable";

import Table, { useTableSchemaState, TableProps } from "./Table";
import TableImpl from "./impl/TableImpl";
import { TableBodyEditablePaginated, TableBodyPaginated } from "./parts/TableBody";
import { TableGroups } from "./parts/TableGroups";
import { TableHeadFilterable } from "./parts/TableHead";
import { getChangingPageNumber, isNumber } from "./utils";
import {
  emptyFilter,
  emptyOrderedMap,
  defaultPageNumber,
  defaultPageCapacity,
} from "./constants";
import { TablePagination } from "./parts/TablePagination";
import {
  OrderDirections,
  FilterTypes,
  FiltersConfigOrderedMap,
  FilterConfigMap,
  SchemaConfigObject,
  ColumnsFiltersConfigOrderedMap,
  TableData,
  OnApplyFilterFunction,
  OnClearFiltersFunction,
  OnChangeDataFunction,
  OnChangePageFunction,
  TableDataStateObject,
  RowEditorComponent,
  RestfulTableData,
  DataProvider,
  OrderDirectionsType,
} from "./types";

export function mergeFilters(
  filters: FiltersConfigOrderedMap,
  filterKey: string,
  filterValue: FilterConfigMap,
  schema: SchemaConfigObject,
  multimode: boolean = false
): [FiltersConfigOrderedMap, ColumnsFiltersConfigOrderedMap] {
  let columnsFilters = emptyOrderedMap as ColumnsFiltersConfigOrderedMap;

  // check for empty filter
  if (
    filterValue !== emptyFilter &&
    filterValue.getIn(["order", "direction"]) ===
      emptyFilter.getIn(["order", "direction"]) &&
    filterValue.getIn(["filter", "values"]).size ===
      emptyFilter.getIn(["filter", "values"]).size
  ) {
    filterValue = emptyFilter;
  }

  // merge new filter
  if (multimode) {
    const currentFilterValue = filters.get(filterKey, emptyFilter);

    if (currentFilterValue !== filterValue) {
      if (filterValue === emptyFilter) {
        filters = filters.delete(filterKey);
      } else {
        filters = filters.set(filterKey, filterValue);
        // turn off other column ordering
        if (filterValue.getIn(["order", "direction"]) !== OrderDirections.NO) {
          filters = filters.mapEntries((entry?: [string, FilterConfigMap]) => {
            const [key, filter] = entry!;

            return key !== filterKey &&
              filter.getIn(["order", "direction"], OrderDirections.NO) !==
                OrderDirections.NO
              ? [
                  key,
                  filter.setIn(
                    ["order", "direction"],
                    OrderDirections.NO
                  ) as FilterConfigMap,
                ]
              : [key, filter];
          });
        }
      }
    }
  } else {
    if (filterValue === emptyFilter) {
      filters = emptyOrderedMap as FiltersConfigOrderedMap;
    } else {
      filters = emptyOrderedMap.set(filterKey, filterValue) as FiltersConfigOrderedMap;
    }
  }

  // calculate required filters for all table columns
  if (filters && filters.size && multimode) {
    const columnsKeys = schema.columns.map((i) => i.uniqueKey);
    const filteredKeys = filters.keySeq();
    const restKeys = Set(columnsKeys).subtract(filteredKeys.toSet()).toArray();

    filters.forEach((_, key) => {
      const filterIdx = filteredKeys.indexOf(key);
      const prevFiltersKeys = filteredKeys.slice(0, filterIdx).toArray();
      const prevFilters = filters.filter(
        (f, k) =>
          k != null && prevFiltersKeys.indexOf(k) >= 0 && f != null && f !== emptyFilter
      );

      columnsFilters = columnsFilters.set(key, prevFilters);
    });

    // set data getters for the rest columns
    restKeys.forEach((key) => {
      columnsFilters = columnsFilters.set(key, filters);
    });
  }

  return [filters, columnsFilters];
}

function applyFilter(
  key: string,
  filter: FilterConfigMap,
  data: TableData,
  schema: SchemaConfigObject
) {
  const filterType = filter.getIn(["filter", "type"]);
  const filterValues = filter.getIn(["filter", "values"]).toList();
  const columnSchema = schema.columns.find((item) => item.uniqueKey === key);
  const { getter } = columnSchema!;
  let filteredData = data;

  // filter by date
  if (filterType === FilterTypes.DATES_RANGE) {
    const from = filterValues.get(0);
    const to = filterValues.get(1);

    filteredData = data.filter((row, idx) => {
      const value = getter(row, idx);

      if (from && value < from) {
        return false;
      } else if (to && value > to) {
        return false;
      } else {
        return true;
      }
    }) as TableData;
    // filter by number
  } else if (filterType === FilterTypes.NUMBERS_RANGE) {
    let from = filterValues.get(0);
    let to = filterValues.get(1);

    from = isNumber(from) ? parseFloat(from) : from;
    to = isNumber(to) ? parseFloat(to) : to;

    filteredData = data.filter((row, idx) => {
      const value = getter(row, idx);

      if (isNumber(from) && value < from) {
        return false;
      } else if (isNumber(to) && value > to) {
        return false;
      } else {
        return true;
      }
    }) as TableData;
    // filter by specific values (values list or enumeration)
  } else if (
    filterType === FilterTypes.VALUES_CHECKLIST ||
    filterType === FilterTypes.ENUMERATION
  ) {
    filteredData = data.filter((row, idx) => {
      const value = getter(row, idx);

      return filterValues.contains(value);
    }) as TableData;
    // filter by value in i-contains manner
  } else if (
    filterType === FilterTypes.VALUES_ICONTAINS &&
    filterValues.size === 1 &&
    filterValues.get(0) != null
  ) {
    const query = filterValues.get(0);

    filteredData = data.filter((row, idx) => {
      const value = getter(row, idx);

      return (
        value != null && ("" + value).toLowerCase().indexOf(query.toLowerCase()) >= 0
      );
    }) as TableData;
  }

  return filteredData;
}

export function applyFilters(
  filters: FiltersConfigOrderedMap,
  data: TableData,
  schema: SchemaConfigObject
) {
  if (filters.size) {
    // apply filters
    data = filters.reduce((data, filter, key) => {
      if (data == null || filter == null || key == null) return data;
      return applyFilter(key, filter, data, schema);
    }, data)!;

    // apply ordering
    const foundFilterEntry = filters.findEntry(
      (v, k) => v.getIn(["order", "direction"]) !== OrderDirections.NO
    );
    const orderKey = foundFilterEntry?.[0];
    const orderDirection = foundFilterEntry?.[1]?.getIn([
      "order",
      "direction",
    ]) as OrderDirectionsType;

    if (
      orderKey != null &&
      orderDirection != null &&
      orderDirection !== OrderDirections.NO
    ) {
      const columnSchema = schema.columns.find((item) => item.uniqueKey === orderKey);
      // const { getter, comparators } = columnSchema;
      const getter = columnSchema?.getter;
      const comparators = columnSchema?.comparators;
      const comparator = comparators?.[orderDirection];

      if (getter != null && comparator != null) {
        data = data.sort((itemA, itemB) =>
          comparator(getter(itemA, itemA?.get("idx")), getter(itemB, itemB?.get("idx")))
        );
      }
    }
  }

  return data;
}

type InitialDataProvider = () => TableData;
type CurrentDataProvider = DataProvider;

const useDataProviders = (
  originalData: TableData,
  schema: SchemaConfigObject,
  dataProvider?: InitialDataProvider
): [InitialDataProvider, CurrentDataProvider] => {
  const initialDataProvider = React.useCallback(() => {
    return dataProvider != null ? dataProvider() : originalData;
  }, [dataProvider, originalData]);

  const currentDataProvider = React.useCallback(
    (filters) => {
      return applyFilters(filters, initialDataProvider(), schema);
    },
    [initialDataProvider, schema]
  );

  return [initialDataProvider, currentDataProvider];
};

interface TableFilterableProps extends TableProps {
  data: TableData;
  dataProvider?: InitialDataProvider;
  // filtering props
  multimode: boolean;
  filters: FiltersConfigOrderedMap;
  columnsFilters: ColumnsFiltersConfigOrderedMap;
  // pagination props
  activePage: number;
  itemsPerPage: number;
  disablePagination: boolean;
  //
  onApplyFilter?: OnApplyFilterFunction<TableDataStateObject>;
  onClearFilters?: OnClearFiltersFunction;
  onChangePage?: OnChangePageFunction;
  onChangeData?: OnChangeDataFunction<TableDataStateObject>;
}

const useTableHandlers = (tableProps: TableFilterableProps) => {
  const {
    schema,
    multimode,
    dataProvider,
    //
    data,
    activePage,
    itemsPerPage,
    filters,
    columnsFilters,
    //
    onApplyFilter,
    onClearFilters,
    onChangePage,
    onChangeData,
  } = tableProps;

  const currentDataProvider = useDataProviders(data, schema, dataProvider)[1];

  const handleApplyFilter = React.useCallback(
    (filterKey, filterValue) => {
      const [nextFilters, nextColumnsFilters] = mergeFilters(
        filters,
        filterKey,
        filterValue,
        schema,
        multimode
      );

      // apply filtered data with filters
      if (nextFilters !== filters || nextColumnsFilters !== columnsFilters) {
        if (onApplyFilter) {
          const nextDataState = {
            data: currentDataProvider(nextFilters),
            activePage: defaultPageNumber,
            filters: nextFilters,
            columnsFilters: nextColumnsFilters,
          };

          if (onChangeData) onChangeData(nextDataState);
          if (onApplyFilter) onApplyFilter(filterKey, filterValue, nextDataState);
        }
      }
    },
    [
      filters,
      columnsFilters,
      schema,
      multimode,
      currentDataProvider,
      onChangeData,
      onApplyFilter,
    ]
  );

  const handleClearFilters = React.useCallback(() => {
    if (onChangeData) {
      onChangeData({
        data: currentDataProvider(
          TableFilterable.defaultProps.filters as FiltersConfigOrderedMap
        ),
        activePage: TableFilterable.defaultProps.activePage,
        filters: TableFilterable.defaultProps.filters as FiltersConfigOrderedMap,
        columnsFilters: TableFilterable.defaultProps
          .columnsFilters as ColumnsFiltersConfigOrderedMap,
      });
    }
    if (onClearFilters) onClearFilters();
  }, [currentDataProvider, onChangeData, onClearFilters]);

  const handleChangePage = React.useCallback(
    (pageEvent) => {
      const totalPagesCount = Math.ceil(data.size / itemsPerPage);
      const nextPage = getChangingPageNumber(
        pageEvent,
        activePage,
        totalPagesCount,
        defaultPageNumber
      );

      if (nextPage !== activePage) {
        if (onChangeData)
          onChangeData({
            data,
            activePage: nextPage,
            filters,
            columnsFilters,
          });
        if (onChangePage) onChangePage(nextPage);
      }
    },
    [data, activePage, itemsPerPage, filters, columnsFilters, onChangeData, onChangePage]
  );

  return {
    handleApplyFilter,
    handleClearFilters,
    handleChangePage,
  };
};

export const useTableState = (tableProps: TableFilterableProps) => {
  const { data, filters, columnsFilters, activePage, children } = tableProps;

  // state
  const [schemaState] = useTableSchemaState(children);
  const [tableState, setTableState] = React.useState({
    data,
    filters,
    columnsFilters,
    activePage,
  });

  // handlers
  const handleChangeData = React.useCallback(
    (nextDataState) => {
      setTableState((prevState) => ({
        ...prevState,
        ...nextDataState,
      }));
    },
    [setTableState]
  );

  // effects
  const prevDataRef = React.useRef(data);
  React.useEffect(() => {
    if (data !== prevDataRef.current) {
      setTableState((prevState) => ({
        ...prevState,
        data: applyFilters(prevState.filters, data, schemaState),
      }));
      prevDataRef.current = data;
    }
  }, [data, schemaState]);

  return {
    tableState,
    setTableState,
    schemaState,
    handleChangeData,
  };
};

export const TableFilterable = (props: TableFilterableProps) => {
  const {
    schema,
    data,
    dataProvider,
    bodyEmptyText,
    highlighted = false,
    //
    selectedRowId,
    selectedCellId,
    rowIdGetter,
    onRowClick,
    onCellClick,
    //
    activePage,
    itemsPerPage,
    disablePagination,
    //
    multimode,
    filters,
    columnsFilters,
  } = props;

  // data providers
  const [initialDataProvider, currentDataProvider] = useDataProviders(
    data,
    schema,
    dataProvider
  );

  // handlers
  const { handleApplyFilter, handleClearFilters, handleChangePage } =
    useTableHandlers(props);

  // rendering
  const tableGroups = <TableGroups groups={schema.groups} columns={schema.columns} />;

  const tableHead = (
    <TableHeadFilterable
      schema={schema}
      multimode={multimode}
      rowIdGetter={rowIdGetter!}
      filters={filters}
      columnsFilters={columnsFilters}
      dataProvider={currentDataProvider}
      onApplyFilter={handleApplyFilter}
      onClearFilters={handleClearFilters}
    />
  );

  const tableBody = (
    <TableBodyPaginated
      schema={schema}
      data={data}
      bodyEmptyText={bodyEmptyText}
      selectedRowId={selectedRowId}
      selectedCellId={selectedCellId}
      rowIdGetter={rowIdGetter!}
      onRowClick={onRowClick}
      onCellClick={onCellClick}
      activePage={activePage}
      itemsPerPage={itemsPerPage}
      disablePagination={disablePagination}
    />
  );

  const initialData = initialDataProvider();
  const tablePagination = (
    <TablePagination
      activePage={activePage}
      itemsCount={initialData.size}
      itemsPerPage={itemsPerPage}
      disablePagination={disablePagination}
      onChangePage={handleChangePage}
    />
  );

  return (
    <TableImpl
      groups={tableGroups}
      head={tableHead}
      body={tableBody}
      pagination={tablePagination}
      highlighted={highlighted}
    />
  );
};
TableFilterable.displayName = "TableFilterable";
TableFilterable.defaultProps = {
  ...Table.defaultProps,
  // filtering props
  filters: emptyOrderedMap as FiltersConfigOrderedMap,
  columnsFilters: emptyOrderedMap as ColumnsFiltersConfigOrderedMap,
  multimode: false,
  // pagination props
  itemsPerPage: defaultPageCapacity,
  activePage: defaultPageNumber,
  disablePagination: false,
};

export const useEditCancelEffect = (
  data: TableData | RestfulTableData,
  selectedRowId?: string | number | null,
  onEditCancel?: () => void
): void => {
  // cancel row editing if the row went out of the view (e.g. row has been removed)
  React.useEffect(() => {
    if (selectedRowId != null && onEditCancel != null) {
      if (!data || !data.size) {
        onEditCancel();
      } else if (!data.find((v) => v.get("id") === selectedRowId)) {
        onEditCancel();
      }
    }
  }, [selectedRowId, data, onEditCancel]);
};

export interface TableFilterableEditableProps extends TableFilterableProps {
  // editing props
  editable: boolean;
  editorImpl: RowEditorComponent;
  onEditApply?: React.ComponentProps<RowEditorComponent>["onApply"];
  onEditCancel?: React.ComponentProps<RowEditorComponent>["onCancel"];
  onDeleteRow?: React.ComponentProps<RowEditorComponent>["onDelete"];
}

export const TableFilterableEditable = (props: TableFilterableEditableProps) => {
  const {
    schema,
    data,
    dataProvider,
    bodyEmptyText,
    highlighted = false,
    // selection
    selectedRowId,
    selectedCellId,
    rowIdGetter,
    onRowClick,
    onCellClick,
    // filters
    filters,
    columnsFilters,
    multimode,
    // pagination
    disablePagination,
    activePage,
    itemsPerPage,
    // editor
    editable,
    editorImpl,
    onEditApply,
    onEditCancel,
    onDeleteRow,
  } = props;

  // utils
  const [initialDataProvider, currentDataProvider] = useDataProviders(
    data,
    schema,
    dataProvider
  );

  // effects
  useEditCancelEffect(data, selectedRowId, onEditCancel);

  // handlers
  const { handleApplyFilter, handleClearFilters, handleChangePage } =
    useTableHandlers(props);

  // rendering

  const tableGroups = <TableGroups groups={schema.groups} columns={schema.columns} />;

  const tableHead = (
    <TableHeadFilterable
      schema={schema}
      multimode={multimode}
      rowIdGetter={rowIdGetter!}
      filters={filters}
      columnsFilters={columnsFilters}
      dataProvider={currentDataProvider}
      onApplyFilter={handleApplyFilter}
      onClearFilters={handleClearFilters}
    />
  );

  const tableBody = (
    <TableBodyEditablePaginated
      schema={schema}
      data={data}
      bodyEmptyText={bodyEmptyText}
      selectedRowId={selectedRowId}
      selectedCellId={selectedCellId}
      rowIdGetter={rowIdGetter!}
      onRowClick={onRowClick}
      onCellClick={onCellClick}
      activePage={activePage}
      itemsPerPage={itemsPerPage}
      disablePagination={disablePagination}
      editable={editable}
      editorImpl={editorImpl}
      onEditApply={onEditApply}
      onEditCancel={onEditCancel}
      onDeleteRow={onDeleteRow}
    />
  );

  const initialData = initialDataProvider();
  const tablePagination = (
    <TablePagination
      activePage={activePage}
      itemsCount={initialData.size}
      itemsPerPage={itemsPerPage}
      disablePagination={disablePagination}
      onChangePage={handleChangePage}
    />
  );

  return (
    <TableImpl
      groups={tableGroups}
      head={tableHead}
      body={tableBody}
      pagination={tablePagination}
      highlighted={highlighted}
    />
  );
};
TableFilterableEditable.displayName = "TableFilterableEditable";
TableFilterableEditable.defaultProps = {
  ...TableFilterable.defaultProps,
  // editing props
  editable: false,
};

export const TableFilterableStateful = (props: TableFilterableProps) => {
  const { tableState, schemaState, handleChangeData } = useTableState(props);

  return (
    <TableFilterable
      {...props}
      {...tableState}
      schema={schemaState}
      onChangeData={handleChangeData}
    />
  );
};
TableFilterableStateful.displayName = "TableFilterableStateful";
TableFilterableStateful.defaultProps = TableFilterable.defaultProps;

export const TableFilterableEditableStateful = (props: TableFilterableEditableProps) => {
  const { tableState, schemaState, handleChangeData } = useTableState(props);

  return (
    <TableFilterableEditable
      {...props}
      {...tableState}
      schema={schemaState}
      onChangeData={handleChangeData}
    />
  );
};
TableFilterableEditableStateful.displayName = "TableFilterableEditableStateful";
TableFilterableEditableStateful.defaultProps = TableFilterableEditable.defaultProps;

export default TableFilterable;
