import * as React from "react";
import Inline from "./Inline";
import { buttonBaseStyles } from "./Button";
import { styled } from "../../stitches.config";
import Stack from "./Stack";

export const Card = styled("div", {
  background: "#fff",
  borderRadius: "4px",
  boxShadow: "0 4px 8px -1px rgb(0 0 0 / 16%)", // TODO
  position: "relative",
  opacity: 1,
  transition: "all .2s",
  color: "$text",
  variants: {
    fill: {
      true: {
        width: "$full",
      },
    },
  },
  defaultVariant: {
    fill: false,
  },
});
Card.displayName = "Card";

export const CardHeader = styled("div", {
  borderBottom: "1px solid $primaryLighter",
  position: "relative",
  padding: "15px 20px",
});
CardHeader.displayName = "CardHeader";

export const CardHeaderTitle = styled("h2", {
  color: "$text",
  fontWeight: 400,
  letterSpacing: "-.0125em",
  margin: "0",
  padding: "0",
  lineHeight: "normal",
});
CardHeaderTitle.displayName = "CardHeaderTitle";

export const CardHeaderSubTitle = styled("p", {
  color: "$primaryDark",
  fontSize: "16px",
  marginBottom: "0 !important",
});
CardHeaderSubTitle.displayName = "CardHeaderSubTitle";

export const CardActions = styled(Inline, {
  borderBottom: "1px solid $primaryLighter",
  justifyContent: "space-between",
  padding: "15px 20px",
});
CardActions.displayName = "CardActions";

export const CardActionsLeft = styled("div", {
  display: "flex",
  gap: "$2",
});
CardActionsLeft.displayName = "CardActionsLeft";

export const CardActionsRight = styled(Inline, {
  gap: "$2",
});
CardActionsRight.displayName = "CardActionsRight";

export const CardFilters = styled(Inline, {
  gap: "$2",
  justifyContent: "flex-end",
  borderBottom: "1px solid $primaryLighter",
  padding: "20px",
  variants: {
    filtered: {
      true: {
        backgroundColor: "$accentLighter",
      },
    },
  },
});
CardFilters.displayName = "CardFilters";

export const CardAlert = styled(Inline, {
  gap: "$2",
  justifyContent: "center",
  borderTop: "1px solid $accent",
  borderBottom: "1px solid $accent",
  padding: "$1",
  backgroundColor: "$accentLighter",
  color: "$text",
  fontWeight: "$semibold",
  fontSize: "$base",
  lineHeight: "$base",
});
CardAlert.displayName = "CardAlert";

export const CardAlertLink = styled("button", {
  color: "$brand !important",
  textDecoration: "none",
  cursor: "pointer",
  background: "none",
  padding: "0 $1",
  "&:hover": {
    color: "$brandDark !important",
    textDecoration: "none",
  },
});
CardAlertLink.displayName = "CardAlertLink";

export const CardFilter = styled("button", buttonBaseStyles, {
  color: "$primaryDark",
  padding: "5px 11px",
  gap: "$1",
  fontSize: "$sm",
  backgroundColor: "transparent",
  border: "1px solid transparent",
  lineHeight: "normal",
  "&:hover": {
    backgroundColor: "white",
    color: "$accentDarker",
    border: "1px solid $accent",
  },
  "&:focus": {
    boxShadow: "$$pt-ringOffsetShadow, $$pt-ringShadow, $$pt-shadow",
    "$$pt-ringColor": "$colors$accentDark",
    "$$pt-ringOffsetColor": "$colors$accentLightest",
  },
  variants: {
    filtered: {
      true: {
        backgroundColor: "$accentLightest",
        color: "$accentDarker",
        border: "1px solid $accent",
      },
    },
  },
});
CardFilter.displayName = "CardFilter";

export const CardBody = styled("div", {
  padding: "$6",
});
CardBody.displayName = "CardBody";

export const CardList = styled("div", {});
CardList.displayName = "CardList";

export const CardListItem = styled("div", {
  borderBottom: "1px solid $primaryLighter",
  // display: "flex",
  // flexWrap: "wrap",
  // justifyContent: "space-between",
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(350px, 1fr))",
  // gridTemplateColumns: "minmax(350px, 1fr) minmax(350px, 1fr)",
  cursor: "pointer",
  margin: "0",
  padding: "20px",
  transition: "background-color .2s",
  "&:hover": {
    backgroundColor: "$brandLightest",
  },
  variants: {
    selected: {
      true: {
        backgroundColor: "$brandLightest",
      },
    },
  },
});
CardListItem.displayName = "CardListItem";

export const CardListItemLeft = styled("div", {
  display: "grid",
  alignContent: "flex-start",
  gap: "$2",
});
CardListItemLeft.displayName = "CardListItemLeft";

export const ellipseContent = {
  minWidth: "0",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const StyledCardListItemLabel = styled("label", {
  '.pt-ui &[data-comp-id="item-label"]': {
    // best to do this than use !important
    ...ellipseContent,
    color: "$text",
    fontSize: "$2xl",
    lineHeight: "$2xl",
    margin: 0,
  },
});
StyledCardListItemLabel.displayName = "StyledCardListItemLabel";

export function CardListItemLabel(
  props: React.ComponentPropsWithRef<typeof StyledCardListItemLabel>
): JSX.Element {
  return <StyledCardListItemLabel data-comp-id="item-label" {...props} />;
}

export const CardListItemName = styled("label", {
  ...ellipseContent,
  color: "$text",
  fontSize: "$2xl",
  fontWeight: "$normal",
  lineHeight: "$2xl",
  margin: 0,
});
CardListItemName.displayName = "CardListItemName";

export const CardListSubText = styled("div", {
  ...ellipseContent,
  color: "$primary",
  fontSize: "16px",
});
CardListSubText.displayName = "CardListSubText";

export const SubTextMuted = styled("span", {
  color: "#aaa",
});

export const SubTextAccent = styled("span", {
  color: "$brandDark",
});

export const Info = styled(Stack, {
  gap: "$1",
});
Info.displayName = "Info";

export const InfoLabel = styled("div", {
  color: "#888", // TODO
});
InfoLabel.displayName = "InfoLabel";

export const InfoData = styled("div", {
  color: "$text",
  fontSize: "$xl",
  maxWidth: "30ch",
  minWidth: "0",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});
InfoData.displayName = "InfoData";

export const CardListItemRight = styled(Inline, {
  justifyContent: "flex-end",
  gap: "$11",
});
CardListItemRight.displayName = "CardListItemRight";

export const CardFooter = styled("div", {});
CardFooter.displayName = "CardFooter";
