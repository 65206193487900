import React from "react";

import { styled } from "../../../stitches.config";

import Grid from "../../../components/lib/Grid";

const Cell = styled("span", {
  padding: "$1 !important",
  whiteSpace: "nowrap",

  variants: {
    type: {
      title: {
        color: "$secondaryLight",
        textTransform: "uppercase",
        fontWeight: "$semibold",
      },
      value: {
        backgroundColor: "$primaryLightest",
        fontWeight: "$bold",
      },
    },
  },
});

export interface StatsTableCellProps<V = number | string | React.ReactElement | null> {
  value: V;
  title: string;
  subtitle?: string;
  hideSubtitle?: boolean;
  formatter?: (value: V) => React.ReactNode;
  emptyFiller?: string | React.ReactElement;
}

const StatsTableCell = <V = number | string | React.ReactElement | null,>(
  props: StatsTableCellProps<V>
) => {
  const { value, title, subtitle, hideSubtitle, formatter, emptyFiller } = props;

  return (
    <Grid
      css={{
        alignItems: "baseline",
        backgroundColor: "$secondaryLightest",
        border: "4px solid $secondaryLightest",
        borderRadius: "$rounded",
        fontSize: "$sm",
        textAlign: "center",
        gridTemplateColumns: "$auto $1-of-2",
        gridColumnGap: 0,
      }}
    >
      <Cell type="title">
        {title}
        {hideSubtitle ? (
          <span className="hidden-md hidden-lg">{subtitle}</span>
        ) : (
          <span>{subtitle}</span>
        )}
      </Cell>
      <Cell type="value">
        {value != null ? (formatter ? formatter(value) : value) : emptyFiller}
      </Cell>
    </Grid>
  );
};

StatsTableCell.displayName = "StatsTableCell";
StatsTableCell.defaultProps = {
  emptyFiller: "no data",
  hideSubtitle: true,
};

export default StatsTableCell;
