// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import Pagination from "../../../components/lib/Pagination";
import LoadingIndicator from "../../shared/LoadingIndicator";
import ContentListItem from "./ContentListItem";
import ToggleButton from "../../ratecards/components/ToggleButton";
import { FILTER_COLUMN } from "../../../models/Filter";
import TagContentFilter from "../../filters/TagContentFilter";
import SortIndicator from "../../ratecards/components/SortIndicator";
import Button from "../../../components/lib/Button";
import Inline from "../../../components/lib/Inline";
import { CardAlert, CardAlertLink } from "../../../components/lib/Card";

class ContentList extends Component {
  constructor(props) {
    super(props);

    this.changeCurrent = this.changeCurrent.bind(this);
  }

  changeCurrent(value) {
    let store: ContentListStore = this.props.store;
    store.pagination.handleCurrentPage(value);
  }

  componentDidMount() {
    this.props.store.tagId = parseInt(this.props.router.params.tagId, 10);
    this.props.store.pagination.goFetch();
  }

  componentWillUnmount() {
    this.props.store.clearFilters();
  }

  render() {
    const store = this.props.store;
    const network = store.network;
    const fillContainer = this.props.fillContainer;
    const fixedHeight = this.props.fixedHeight;
    let selectAllSection = null;
    const tagStore = this.props.tagStore;
    let editClass = "";

    let listBodyStyles = {};
    if (fixedHeight) {
      listBodyStyles.height = fixedHeight;
      listBodyStyles.overflowY = "auto";
    }

    let selectAllOnPageSection = (
      <Inline css={{ flexShrink: 2, flexGrow: 1, gap: "$2" }}>
        <Button onClick={store.selectAllOnPageItem}>Select All</Button>
        <Button onClick={store.deselectAllPage}>Deselect All</Button>
      </Inline>
    );

    if (store.allSelected) {
      selectAllSection = (
        <CardAlert>
          <CardAlertLink>
            All &nbsp;
            <strong>{store.totalCount}</strong>
            &nbsp; Items selected.{" "}
          </CardAlertLink>
          &nbsp; &nbsp;
          <CardAlertLink key="clear-selections-link" onClick={store.clearAllSelections}>
            Clear all selections
          </CardAlertLink>
        </CardAlert>
      );
    }

    if (store.allOnPageSelected && !store.allSelected) {
      selectAllSection = (
        <CardAlert>
          <span>All Items on this page selected. &nbsp;</span>
          &nbsp;
          <CardAlertLink key="select-all-item" onClick={store.selectAllPage}>
            Select all &nbsp;
            <strong>{store.totalCount}</strong>
            &nbsp; Items.
          </CardAlertLink>
          &nbsp;&nbsp;
          <CardAlertLink key="clear-selections-link" onClick={store.clearAllSelections}>
            Clear all selections
          </CardAlertLink>
        </CardAlert>
      );
    }

    let tableLeftActionBar = null;
    if (store.isEditing) {
      tableLeftActionBar = (
        <nav className="header-left-nav">
          {store.allowMultipleItemSelection && selectAllOnPageSection}
        </nav>
      );
    }

    const tags = store.tagDetailListView;
    let tableContent = null;
    if (network.loading) {
      tableContent = <LoadingIndicator />;
    } else if (network.error) {
      // TODO: replace this with a more friendly error message
      tableContent = (
        <div className="pt-error-message">
          <h2>Error</h2>
          <pre>
            <code>{JSON.stringify(network.error, null, 2)}</code>
          </pre>
        </div>
      );
    } else if (tags.length === 0) {
      tableContent = (
        <div className="ratecards-table-empty">
          <div>
            No Item Found
            {/*<p>
              Add new item by edit.
              </p>*/}
          </div>
        </div>
      );
    } else {
      tableContent = tags.map((tagD) => (
        <ContentListItem key={tagD.id} store={store} tagStore={tagStore} tag={tagD} />
      ));
    }

    if (store.isEditing) {
      editClass = "view";
    } else if (!store.isEditing) {
      editClass = "view";
    }

    return (
      <div className={editClass}>
        <div className="ratecards-list">
          {store.tagContentFilter && (
            <TagContentFilter
              tagsFilter={store.tagContentFilter}
              network={store.tagContentFilter.network.loading}
            />
          )}
          <header
            className={classNames("ratecards-table-header", {
              active: store.isFiltered,
            })}
          >
            {!store.isEditing && (
              <nav className="header-right-nav atl-1">
                {store.isFiltered && (
                  <div className="btn btn-yellow" onClick={store.clearFilters}>
                    Clear All Filters & Sorts
                  </div>
                )}
                {store.tagContentFilter && (
                  <ToggleButton
                    borderless={true}
                    name="search-list-filter"
                    value={FILTER_COLUMN.TAG_CONTENT}
                    selected={Boolean(store.appliedFilters[FILTER_COLUMN.TAG_CONTENT])}
                    onClick={store.tagContentFilter.onShowModal}
                  >
                    <SortIndicator
                      sortColumn={FILTER_COLUMN.TAG_CONTENT}
                      sortsOrder={store.appliedSortsOrder}
                      sort={store.appliedSorts[FILTER_COLUMN.TAG_CONTENT]}
                    />
                    Type <span> ▼</span>
                  </ToggleButton>
                )}
              </nav>
            )}
            {tableLeftActionBar}
          </header>
          <div
            className={classNames("ratecards-table", {
              "ratecards-table-fill-container": fillContainer,
            })}
          >
            <div style={listBodyStyles}>
              {selectAllSection}
              {tableContent}
            </div>
          </div>
        </div>
        {store.pagination.pageCount > 0 ? (
          <Pagination
            options={{
              variant: "full",
              currentPage: store.pagination.currentPage,
              numPages: store.pagination.pageCount,
            }}
            onPageClick={(pageEvent) => {
              if (pageEvent.type === "first") {
                this.changeCurrent(1);
              }
              if (pageEvent.type === "last") {
                this.changeCurrent(store.pagination.pageCount);
              }
              if (pageEvent.type === "next") {
                this.changeCurrent(
                  Math.min(store.pagination.currentPage + 1, store.pagination.pageCount)
                );
              }
              if (pageEvent.type === "prev") {
                this.changeCurrent(Math.max(store.pagination.currentPage - 1, 1));
              }
              if (pageEvent.type === "page") {
                this.changeCurrent(pageEvent.page);
              }
            }}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

ContentList.propTypes = {
  store: PropTypes.object.isRequired,
  fixedHeight: PropTypes.number,
  fillContainer: PropTypes.bool,
};

export default observer(ContentList);
