// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import Pagination from "../../../ratecards/components/Pagination";
import LoadingIndicator from "../../../shared/LoadingIndicator";
import NegotiationWorksheetListItem from "./NegotiationWorksheetListItem";
import ToggleButton from "../../../ratecards/components/ToggleButton";
import SortIndicator from "../../../ratecards/components/SortIndicator";
import { FILTER_COLUMN } from "../../../../models/Filter";
import ListContainer from "../../../ratecards/components/ListContainer";
import { SingleDatePicker } from "../../../../components/SingleDatePicker";
import CreatedOnFilter from "../../../filters/CreatedOnFilter";
import CountryFilter from "../../../filters/CountryFilter";
import PayTypeFilter from "../../../filters/PayTypeFilter";
import MarkUpOnFilter from "../../../filters/MarkUpAmtFilter";
import TagsFilter from "../../../filters/TagsFilter";
import UserFilter from "../../../filters/UserFilter";
import ClientUserFilter from "../../../filters/ClientUserFilter";

class NegotiationWorksheetList extends Component {
  constructor(props) {
    super(props);

    this.changeCurrent = this.changeCurrent.bind(this);
  }

  // Change the current value of the pagination
  changeCurrent(value) {
    let store: NegotiationWorksheetListComponentStore = this.props.store;
    store.pagination.handleCurrentPage(value);
  }

  componentWillUnmount() {
    this.props.store.isEditing = false;
    this.props.store.clearAllSelections();
    this.props.store.clearFilters();
  }

  render() {
    const store: NegotiationWorksheetListComponentStore = this.props.store;
    const network = store.network;
    const fillContainer = this.props.fillContainer;
    const fixedHeight = this.props.fixedHeight;
    const maximumDate = new Date();

    let listBodyStyles = {};
    if (fixedHeight) {
      listBodyStyles.height = fixedHeight;
      listBodyStyles.overflowY = "auto";
    }

    let selectAllSection = null;
    let selectAllOnPageSection = (
      <nav className="header-left-nav atl-1">
        <div className="btn" onClick={store.selectAllOnPageItem}>
          Select All
        </div>
        <div className="btn" onClick={store.deselectAllPage}>
          Deselect All
        </div>
      </nav>
    );

    if (store.allOnPageSelected) {
      selectAllSection = (
        <div className="ratecards-list-select-all-section">
          <span>
            All &nbsp;
            <strong>{store.totalCount}</strong>
            &nbsp; Worksheets selected.{" "}
          </span>
          &nbsp;
          {!store.isTagView && (
            <span
              className="pt-link ratecards-all-items"
              key="clear-selections-link"
              onClick={store.clearAllSelections}
            >
              Clear all selections
            </span>
          )}
        </div>
      );
    }

    if (store.allOnPageSelected && !store.allSelected) {
      selectAllSection = (
        <div className="ratecards-list-select-all-section">
          <span>All Worksheets on this page selected. &nbsp;</span>
          &nbsp;
          <span
            className="pt-link ratecards-all-items"
            key="select-all-item"
            onClick={store.selectAllPage}
          >
            Select all &nbsp;
            <strong>{store.totalCount}</strong>
            &nbsp; Worksheets.
          </span>
          &nbsp;&nbsp;
          <span
            className="pt-link ratecards-all-items"
            key="clear-selections-link"
            onClick={store.clearAllSelections}
          >
            Clear all selections
          </span>
        </div>
      );
    }

    let tableLeftActionBar = null;
    if (store.isEditing) {
      tableLeftActionBar = (
        <nav className="header-left-nav">
          {store.allowMultipleItemSelection && selectAllOnPageSection}
        </nav>
      );
    }

    const worksheets = store.negotiationListView;

    let tableContent = null;
    if (network.loading) {
      tableContent = <LoadingIndicator />;
    } else if (network.error) {
      tableContent = (
        <div className="pt-error-message">
          <h2>Error</h2>
          <pre>
            <code>{JSON.stringify(network.error, null, 2)}</code>
          </pre>
        </div>
      );
    } else if (worksheets.length === 0) {
      tableContent = (
        <div className="ratecards-table-empty">
          {Object.keys(store.appliedFilters).length > 0 ? (
            <div>No Worksheet match your current filters</div>
          ) : (
            <div>
              No Worksheet
              <p>Create a new Worksheet using the button above or by searching.</p>
            </div>
          )}
        </div>
      );
    } else {
      tableContent = worksheets.map((worksheet) => (
        <NegotiationWorksheetListItem
          key={worksheet.worksheetId}
          store={store}
          worksheet={worksheet}
          editing={store.isEditing}
        />
      ));
    }

    var fromDate = (
      <SingleDatePicker
        id="from_date"
        date={store.createdOnFilter.fromDate}
        numberOfMonths={1}
        isOutsideRange={(day) => day.isAfter(maximumDate)}
        focused={store.createdOnFilter.fromFocused}
        onDateChange={store.createdOnFilter.fromDateChange}
        onFocusChange={store.createdOnFilter.fromFocusedChange}
      />
    );

    var toDate = (
      <SingleDatePicker
        id="to_date"
        date={store.createdOnFilter.toDate}
        numberOfMonths={1}
        isOutsideRange={(day) => store.toDateRange(day)}
        focused={store.createdOnFilter.toFocused}
        onDateChange={store.createdOnFilter.toDateChange}
        onFocusChange={store.createdOnFilter.toFocusedChange}
      />
    );

    var fromMarkUp = (
      <input
        type="number"
        className="form-control"
        id="from_markup"
        value={store.markUpOnFilter.fromMarkUpAmt}
        onChange={store.markUpOnFilter.fromMarkUpAmtChange}
        min="0"
      />
    );

    var toMarkUp = (
      <input
        type="number"
        className="form-control"
        id="to"
        value={store.markUpOnFilter.toMarkUpAmt}
        onChange={store.markUpOnFilter.toMarkUpAmtChange}
        min="0"
      />
    );

    var payTypeList = (
      <ListContainer>
        {store.payTypeFilter.viewItems.map((item) => (
          <ToggleButton
            key={item.id}
            large={true}
            item={item}
            type="radio"
            name="rate-type"
            value={item.value}
            selected={item.selected}
            onChange={store.payTypeFilter.setSelectedValue}
          >
            {item.value}
          </ToggleButton>
        ))}
      </ListContainer>
    );

    return (
      <div className="ratecards-list">
        {store.createdOnFilter && (
          <CreatedOnFilter
            createdOnFilter={store.createdOnFilter}
            fromDate={fromDate}
            toDate={toDate}
          />
        )}
        {store.countryFilter && (
          <CountryFilter
            countryFilter={store.countryFilter}
            network={store.countryFilter.network.loading}
            instantSearchValue={store.countryFilter.instantSearchValue}
            onInstantSearch={store.countryFilter.onInstantSearch}
          />
        )}
        {store.payTypeFilter && (
          <PayTypeFilter
            payTypeFilter={store.payTypeFilter}
            payTypeList={payTypeList}
            network={store.payTypeFilter.network.loading}
          />
        )}
        {store.markUpOnFilter && (
          <MarkUpOnFilter
            markUpOnFilter={store.markUpOnFilter}
            fromMarkUp={fromMarkUp}
            toMarkUp={toMarkUp}
          />
        )}
        {store.tagsFilter && (
          <TagsFilter
            tagsFilter={store.tagsFilter}
            network={store.tagsFilter.network.loading}
            instantSearchValue={store.tagsFilter.instantSearchValue}
            onInstantSearch={store.tagsFilter.onInstantSearch}
          />
        )}
        {store.userFilter && (
          <UserFilter
            userFilter={store.userFilter}
            network={store.userFilter.network.loading}
            instantSearchValue={store.userFilter.instantSearchValue}
            onInstantSearch={store.userFilter.onInstantSearch}
          />
        )}
        {store.clientUserFilter && (
          <ClientUserFilter
            clientUserFilter={store.clientUserFilter}
            network={store.clientUserFilter.network.loading}
            instantSearchValue={store.clientUserFilter.instantSearchValue}
            onInstantSearch={store.clientUserFilter.onInstantSearch}
          />
        )}

        <header
          className={classNames("ratecards-table-header", {
            active: store.isFiltered,
          })}
        >
          {tableLeftActionBar}
          <nav className="header-right-nav">
            {store.isFiltered && (
              <div className="btn btn-yellow" onClick={store.clearFilters}>
                Clear All Filters & Sorts
              </div>
            )}
            {store.clientUserFilter && (
              <ToggleButton
                borderless={true}
                name="search-list-filter"
                value={FILTER_COLUMN.NW_CLIENT_USER}
                selected={Boolean(store.appliedFilters[FILTER_COLUMN.NW_CLIENT_USER])}
                onClick={store.clientUserFilter.onShowModal}
              >
                <SortIndicator
                  sortColumn={FILTER_COLUMN.NW_CLIENT_USER}
                  sortsOrder={store.appliedSortsOrder}
                  sort={store.appliedSorts[FILTER_COLUMN.NW_CLIENT_USER]}
                />
                Client <span> ▼</span>
              </ToggleButton>
            )}
            {store.userFilter && (
              <ToggleButton
                borderless={true}
                name="search-list-filter"
                value={FILTER_COLUMN.NW_USER}
                selected={Boolean(store.appliedFilters[FILTER_COLUMN.NW_USER])}
                onClick={store.userFilter.onShowModal}
              >
                <SortIndicator
                  sortColumn={FILTER_COLUMN.NW_USER}
                  sortsOrder={store.appliedSortsOrder}
                  sort={store.appliedSorts[FILTER_COLUMN.NW_USER]}
                />
                Created By <span> ▼</span>
              </ToggleButton>
            )}
            {store.tagsFilter && (
              <ToggleButton
                borderless={true}
                name="search-list-filter"
                value={FILTER_COLUMN.TAGS}
                selected={Boolean(store.appliedFilters[FILTER_COLUMN.TAGS])}
                onClick={store.tagsFilter.onShowModal}
              >
                <SortIndicator
                  sortColumn={FILTER_COLUMN.TAGS}
                  sortsOrder={store.appliedSortsOrder}
                  sort={store.appliedSorts[FILTER_COLUMN.TAGS]}
                />
                Tags <span> ▼</span>
              </ToggleButton>
            )}
            {store.payTypeFilter && (
              <ToggleButton
                borderless={true}
                name="rate-cards-filter"
                value={FILTER_COLUMN.PAY_TYPE}
                selected={Boolean(store.appliedFilters[FILTER_COLUMN.PAY_TYPE])}
                onClick={store.payTypeFilter.onShowModal}
              >
                Worksheet Type
                <span> ▼</span>
              </ToggleButton>
            )}
            {store.countryFilter && (
              <ToggleButton
                borderless={true}
                name="search-list-filter"
                value={FILTER_COLUMN.WORKSHEET_COUNTRY}
                selected={Boolean(store.appliedFilters[FILTER_COLUMN.WORKSHEET_COUNTRY])}
                onClick={store.countryFilter.onShowModal}
              >
                <SortIndicator
                  sortColumn={FILTER_COLUMN.WORKSHEET_COUNTRY}
                  sortsOrder={store.appliedSortsOrder}
                  sort={store.appliedSorts[FILTER_COLUMN.WORKSHEET_COUNTRY]}
                />
                Country <span> ▼</span>
              </ToggleButton>
            )}
            {store.createdOnFilter && (
              <ToggleButton
                borderless={true}
                name="rate-cards-filter"
                value={FILTER_COLUMN.WORKSHEET_DATE}
                selected={Boolean(store.appliedFilters[FILTER_COLUMN.WORKSHEET_DATE])}
                onClick={store.createdOnFilter.onShowModal}
              >
                <SortIndicator
                  sortType="numeric"
                  sortColumn={FILTER_COLUMN.WORKSHEET_DATE}
                  sortsOrder={store.appliedSortsOrder}
                  sort={store.appliedSorts[FILTER_COLUMN.WORKSHEET_DATE]}
                />
                Created On
                <span> ▼</span>
              </ToggleButton>
            )}
            {store.markUpOnFilter && (
              <ToggleButton
                borderless={true}
                name="rate-cards-filter"
                value={FILTER_COLUMN.MARKUP_AMT}
                selected={Boolean(store.appliedFilters[FILTER_COLUMN.MARKUP_AMT])}
                onClick={store.markUpOnFilter.onShowModal}
              >
                <SortIndicator
                  sortType="numeric"
                  sortColumn={FILTER_COLUMN.MARKUP_AMT}
                  sortsOrder={store.appliedSortsOrder}
                  sort={store.appliedSorts[FILTER_COLUMN.MARKUP_AMT]}
                />
                Markup
                <span> ▼</span>
              </ToggleButton>
            )}
          </nav>
        </header>

        <div
          className={classNames("ratecards-table ws-table", {
            "ratecards-table-fill-container": fillContainer,
          })}
        >
          <div style={listBodyStyles}>
            {selectAllSection}
            {tableContent}
          </div>
        </div>
        {store.pagination.pageCount > 0 ? (
          <Pagination
            pageCount={store.pagination.pageCount}
            pagesToShow={store.pagination.maxPagesToShow}
            currentPage={store.pagination.currentPage}
            begin={store.pagination.begin}
            end={store.pagination.end}
            isEndRange={store.pagination.isEndRange}
            handleCurrentPage={this.changeCurrent}
            firstPage={store.pagination.firstPage}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

NegotiationWorksheetList.propTypes = {
  store: PropTypes.object.isRequired,
  fixedHeight: PropTypes.number,
  fillContainer: PropTypes.bool,
};

export default observer(NegotiationWorksheetList);
