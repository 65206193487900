// @flow
import * as React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import CheckBox from "../../shared/CheckBox";
import TagItem from "../../ratecards/components/TagItem";
import {
  CardListItem,
  CardListItemLabel,
  CardListItemLeft,
  CardListItemRight,
  CardListSubText,
  SubTextAccent,
} from "../../../components/lib/Card";
import Inline from "../../../components/lib/Inline";
import LinkButton from "../../../components/lib/LinkButton";

const ProjectCostEstimateListItem = observer(
  class extends React.Component {
    static contextTypes = {
      router: PropTypes.object,
    };

    constructor(props) {
      super(props);

      this.handleViewDetail = this.handleViewDetail.bind(this);
      this.deleteTag = this.deleteTag.bind(this);
      this.state = {
        updated: false,
      };
    }

    handleViewDetail() {
      this.context.router.push({
        pathname: `/project-cost-estimate/${this.props.pce.projectCostId}`,
        query: this.context.router.query,
      });
    }

    deleteTag(event: object, tag_id) {
      event.preventDefault();
      event.stopPropagation();
      event.cancelBubble = true;
      event.returnValue = false;

      this.props.store
        .deleteSingleTags({
          tagId: tag_id,
          contentId: this.props.pce.projectCostId,
        })
        .then((res) => {
          if (!res.result) return;
          this.props.pce.tags.forEach((item, index) => {
            if (item.tagId === tag_id) {
              this.props.pce.tags.splice(index, 1);
            }
          });
          this.setState({
            updated: true,
          });
        });
    }

    render() {
      const props = this.props;
      const pce = props.pce;
      const editing = pce.viewState.editing;
      const selected = pce.viewState.selected;
      const name = pce.name;
      const id = pce.projectCostId;
      const createdDisplay = pce.createdDate.format("MMMM D, YYYY");
      let ownerName = "You";
      if (String(pce.user.userId) !== String(props.store.currentUser.userId)) {
        ownerName = pce.owner;
      }

      let onItemClick = this.props.onClick || this.handleViewDetail;
      let editingSection = null;

      const tags = pce.tags;
      let tagSection = null;

      // Display only when edit button is clicked
      if (editing) {
        onItemClick = pce.toggleSelected;
        editingSection = (
          <CheckBox
            type={props.store.allowMultipleItemSelection ? "checkbox" : "radio"}
            name="list-item-select"
            value={name}
            selected={selected}
            style={{ width: 34 }}
            onClick={onItemClick}
          />
        );
      }

      if (tags.length > 0 && !this.props.store.isTagView) {
        tagSection = tags.map((tag) => (
          <TagItem key={tag.tagId} tag={tag} onClick={this.deleteTag} />
        ));
      }

      return (
        <CardListItem selected={selected} onClick={onItemClick}>
          <CardListItemLeft>
            <Inline css={{ gap: 0 }}>
              {editingSection}
              <div>
                <div>
                  <CardListItemLabel>{name}</CardListItemLabel>
                  <CardListSubText>
                    Created by <SubTextAccent>{ownerName}</SubTextAccent> on{" "}
                    <SubTextAccent>{createdDisplay}</SubTextAccent>
                  </CardListSubText>
                </div>
              </div>
            </Inline>
            {props.store.isTagView && (
              <div className="ratecard-list-item-right">&nbsp;</div>
            )}
            <div className={classNames("tagbox", { "p-left-34": editing })}>
              {tagSection}
            </div>
          </CardListItemLeft>
          <CardListItemRight>
            {!props.store.isTagView && (
              <div className="ratecard-list-item-right">
                <div className="rc-center">
                  <LinkButton to={`/project-cost-estimate/${id}`}>Details</LinkButton>
                </div>
              </div>
            )}
          </CardListItemRight>
        </CardListItem>
      );
    }
  }
);

ProjectCostEstimateListItem.propTypes = {
  store: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  pce: PropTypes.object.isRequired,
};

export default ProjectCostEstimateListItem;
