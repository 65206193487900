/**
 * @generated SignedSource<<2e335a32088a19c17f6ba829ad89ef8c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SupportedCountries_viewer$data = {
  readonly countries: ReadonlyArray<{
    readonly id: string;
    readonly isoCode: string;
    readonly locationId: number;
    readonly name: string;
  } | null> | null;
  readonly " $fragmentType": "SupportedCountries_viewer";
};
export type SupportedCountries_viewer$key = {
  readonly " $data"?: SupportedCountries_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SupportedCountries_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SupportedCountries_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CountryListNode",
      "kind": "LinkedField",
      "name": "countries",
      "plural": true,
      "selections": [
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          "action": "NONE",
          "path": "countries.id"
        },
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isoCode",
            "storageKey": null
          },
          "action": "NONE",
          "path": "countries.isoCode"
        },
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          "action": "NONE",
          "path": "countries.name"
        },
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "locationId",
            "storageKey": null
          },
          "action": "NONE",
          "path": "countries.locationId"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "e9171df7f0bf58a7fe0f2d48bbb5c282";

export default node;
