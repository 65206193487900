// @flow
import * as React from "react";
import moment from "moment";
// import graphql from "babel-plugin-relay/macro";
import { SingleDatePicker } from "../components/SingleDatePicker";
import ContainerSection from "../views/ratecards/components/ContainerSection";
import FilterModal from "./FilterModal";
import type { SortDirection } from "../models/Sort";
import SortAscToggleButton from "./SortAscToggleButton";
import SortDescToggleButton from "./SortDescToggleButton";

type CreatedOnFilter = {
  // ISO 8601 Date String
  fromDate?: string,
  toDate?: string,
};

export type CreatedOnSort = {
  field: "CREATE_DATE",
  direction: SortDirection,
  caseSensitive: true,
};

export type MaybeCreatedOnFilter = CreatedOnFilter | null;
export type MaybeCreatedOnSort = CreatedOnSort | null;

type Props = {
  open: boolean,
  currentFilter: MaybeCreatedOnFilter,
  currentSort: MaybeCreatedOnSort,
  onClose: () => void,
  onApply: (filter: MaybeCreatedOnFilter, sort: MaybeCreatedOnSort) => void,
};

type State = {
  disabled: boolean,
  error: Object | null,
  fromDate: string | null,
  fromFocused: boolean,
  toDate: string | null,
  toFocused: boolean,
  sortDirection: SortDirection | null,
};

type CreatedByItem = { id: string, username: string, userId: number };

// Used when "getting all ids"
// const FetchAllCreatedBysQuery = graphql`
//   query FilterAndSortCreatedOnFetchAllQuery($filters: AuthorFiltersInput) {
//     viewer {
//       authors(filters: $filters, section: ADMIN) {
//         edges {
//           node {
//             id
//           }
//         }
//       }
//     }
//   }
// `;

class FilterAndSortCreatedOns extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      disabled: false,
      error: null,
      fromDate: null,
      fromFocused: false,
      toDate: null,
      toFocused: false,
      sortDirection: props.currentSort?.direction || null,
    };
  }

  handleApply = async () => {
    const { fromDate, toDate, sortDirection } = this.state;

    let filter: MaybeCreatedOnFilter = null;
    if (fromDate) filter = { fromDate };
    if (toDate) filter = Object.assign(filter || {}, { toDate });

    let sort: MaybeCreatedOnSort = null;
    if (sortDirection)
      sort = { field: "CREATE_DATE", caseSensitive: true, direction: sortDirection };

    this.setState({ fromDate: null, toDate: null }, () => {
      this.props.onApply(filter, sort);
    });
  };

  handleReset = () => {
    this.setState(
      {
        fromDate: null,
        toDate: null,
        sortDirection: null,
      },
      () => {
        this.props.onApply(null, null);
      }
    );
  };

  handleClose = () => {
    this.setState(
      {
        fromDate: null,
        toDate: null,
        sortDirection: null,
      },
      () => {
        this.props.onClose();
      }
    );
  };

  handleSortOnClick = (dir: SortDirection) => {
    this.setState({ sortDirection: dir });
  };

  renderItem = (item: CreatedByItem) => {
    return (
      <div>
        {item.username} [#{item.userId}]
      </div>
    );
  };

  render() {
    const { error, fromDate, toDate, sortDirection } = this.state;
    const maximumDate = moment().add(1, "d");

    if (error) {
      return (
        <div>
          Error!: <pre>{error}</pre>
        </div>
      );
    }

    return (
      <FilterModal
        show={this.props.open}
        title={"Created On"}
        isCreatedFilter={false}
        onApply={this.handleApply}
        onHide={this.handleClose}
        onReset={this.handleReset}
        selectedValuesCount={1 /* Apply button always enabled */}
      >
        <div>
          <ContainerSection>
            <p>Sort:</p>
            <div className="btn-toggle-group pull-left">
              <SortAscToggleButton
                large
                text="Oldest First"
                selected={sortDirection === "ASC"}
                onClick={this.handleSortOnClick}
              />
              <SortDescToggleButton
                large
                text="Newest First"
                selected={sortDirection === "DESC"}
                onClick={this.handleSortOnClick}
              />
            </div>
          </ContainerSection>
          <ContainerSection>
            <p>Filter by a specific period:</p>
            <div className="pt-range-filter">
              <div className="pt-range-filter__item">
                <p>From:</p>
                <SingleDatePicker
                  id="from_date"
                  date={
                    fromDate !== null
                      ? moment(fromDate)
                      : this.props.currentFilter !== null &&
                        this.props.currentFilter.fromDate !== null
                      ? moment(this.props.currentFilter?.fromDate)
                      : null
                  }
                  numberOfMonths={1}
                  isOutsideRange={(day) => day.isAfter(maximumDate)}
                  focused={this.state.fromFocused}
                  onDateChange={(date) => {
                    this.setState({
                      fromDate: date ? date.toISOString() : null,
                    });
                  }}
                  onFocusChange={({ focused }) => {
                    this.setState({
                      fromFocused: focused,
                    });
                  }}
                />
              </div>
              <div className="pt-range-filter__item">
                <p>To:</p>
                <SingleDatePicker
                  id="to_date"
                  date={
                    toDate !== null
                      ? moment(toDate)
                      : this.props.currentFilter !== null &&
                        this.props.currentFilter.toDate !== null
                      ? moment(this.props.currentFilter?.toDate)
                      : null
                  }
                  numberOfMonths={1}
                  isOutsideRange={(day) => day.isAfter(maximumDate)}
                  focused={this.state.toFocused}
                  onDateChange={(date) => {
                    this.setState({
                      toDate: date ? date.toISOString() : null,
                    });
                  }}
                  onFocusChange={({ focused }) => {
                    this.setState({
                      toFocused: focused,
                    });
                  }}
                />
              </div>
            </div>
          </ContainerSection>
        </div>
      </FilterModal>
    );
  }
}

export default FilterAndSortCreatedOns;
