/**
 * @generated SignedSource<<2058a024b23b1aabc01e2d1531c35a5f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IndustriesField_viewer$data = {
  readonly industries: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly legacyId: number;
        readonly value: string;
      } | null;
    } | null>;
  } | null;
  readonly " $fragmentType": "IndustriesField_viewer";
};
export type IndustriesField_viewer$key = {
  readonly " $data"?: IndustriesField_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"IndustriesField_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "IndustriesField_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "IndustryNodeConnection",
      "kind": "LinkedField",
      "name": "industries",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "IndustryNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "IndustryNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "legacyId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "96fa5924a5dd511a40e2cc9dbdc50f5f";

export default node;
