import React from "react";
import { Map } from "immutable";

import Stack from "../../../components/lib/Stack";

import type { QuestionFeedbackDataMap, QuestionsFeedbackOrderedMap } from "../types";
import { useQuestionsFeedbackContext } from "../context/feedback/QuestionsFeedbackContext";
import { useAttemptDataContext } from "../context/AttemptDataContext";
import QuestionsSurvey from "./QuestionsSurvey";
import Text from "../../../components/lib/Text";

export default function QuestionsBlock() {
  const { attemptId } = useAttemptDataContext();
  const { questionsData, questionsFeedback, setQuestionsFeedback } =
    useQuestionsFeedbackContext();

  const handleChangeQuestionFeedback = React.useCallback(
    (questionId: number, value: string | boolean) => {
      setQuestionsFeedback(
        questionsFeedback.set(
          questionId,
          Map({
            attempt: attemptId,
            question: questionId,
            value,
          }) as unknown as QuestionFeedbackDataMap
        ) as unknown as QuestionsFeedbackOrderedMap
      );
    },
    [attemptId, questionsFeedback, setQuestionsFeedback]
  );

  return (
    <>
      {!!questionsData?.size && (
        <Stack fill css={{ alignItems: "flex-start", gap: "$2" }}>
          <Text as="h4">Additional Questions:</Text>
          <QuestionsSurvey
            questions={questionsData}
            feedback={questionsFeedback}
            onChange={handleChangeQuestionFeedback}
          />
        </Stack>
      )}
    </>
  );
}

QuestionsBlock.displayName = "QuestionsBlock";
