import React, { useCallback } from "react";
import queryString from "query-string";

import RestfulDatasetListView, {
  DataSourceFunc,
  RenderItemFunc,
} from "./BaseRestfulDatasetListView";
import Icon from "../../../../components/lib/Icon";
import SelectItemRow from "../../lib/SelectItemRow";
// @ts-expect-error
import { logAsyncOperationError } from "../../../../utils/logging";
import {
  djangoPaginationKey,
  djangoPaginationSizeKey,
  djangoSearchKey,
} from "../../../../constants";
import { transformValidatorsData } from "../../dataConverters";

import type { FetchAPIFunc, FetchAPIResponse } from "../../../../types/fetch";
import type { UrlQueryObject } from "../../../../components/tables/types";
import type { DjangoPaginatedResponse } from "../../../../types/django";
import type {
  ValidatorSelectFormObject,
  ValidatorSelectFormDataMap,
  ValidatorSelectFormDataList,
} from "../../types";
import type { ShowModalFunc } from "../../../../components/modals/MessageModal";

type ValidatorSelectProps = {
  includeNewValidators?: boolean;
  onSelect: (data: ValidatorSelectFormDataMap) => Promise<void>;
  fetchArgusAPI: FetchAPIFunc;
  showModalError: ShowModalFunc;
};

const ValidatorSelect = (props: ValidatorSelectProps) => {
  const { includeNewValidators = false, onSelect, fetchArgusAPI, showModalError } = props;

  const dataSource: DataSourceFunc<ValidatorSelectFormDataList> = useCallback(
    async (search, page, pageSize) => {
      const queryArgs: UrlQueryObject = {};

      if (page) {
        queryArgs[djangoPaginationKey] = page;
      }
      if (pageSize) {
        queryArgs[djangoPaginationSizeKey] = pageSize;
      }
      if (search) {
        queryArgs[djangoSearchKey] = search;
      }
      if (includeNewValidators) {
        queryArgs["__including_new"] = true;
      }

      try {
        const response: FetchAPIResponse<
          DjangoPaginatedResponse<ValidatorSelectFormObject>
        > = await fetchArgusAPI(`validators/`, {
          method: "get",
          params: queryString.stringify(queryArgs),
        });

        return transformValidatorsData(response.data);
      } catch (err: any) {
        logAsyncOperationError("ValidatorSelect", err);
        showModalError("Error occurred while loading validators list.");
      }
    },
    [includeNewValidators, showModalError, fetchArgusAPI]
  );

  const renderItem: RenderItemFunc<ValidatorSelectFormDataMap> = useCallback(
    (item) => {
      const email = item.get("email");
      const firstName = item.get("first_name");
      const lastName = item.get("last_name");

      return (
        <SelectItemRow onClick={() => onSelect?.(item)}>
          <Icon icon="user" css={{ color: "$brand" }} />
          {`${firstName} ${lastName} (${email})`}
        </SelectItemRow>
      );
    },
    [onSelect]
  );

  return <RestfulDatasetListView dataSource={dataSource} renderItem={renderItem} />;
};
ValidatorSelect.displayName = "ValidatorSelect";

export default ValidatorSelect;
