// @flow
import React, { Component } from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContainerSection from "../ratecards/components/ContainerSection";
import SavedSearchesList from "./components/SavedSearchesList";
import Modal from "../ratecards/components/Modal";
import RateCardsList from "../ratecards/components/RateCardsList";
import ApplyTagFilterModal from "../shared/modals/ApplyTagFilterModal";
import type MobXStore from "../../stores/mobx/MobXStore";
import {
  Card,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
  CardHeader,
  CardHeaderSubTitle,
  CardHeaderTitle,
} from "../../components/lib/Card";
import SearchBox from "../../components/lib/SearchBox";
import Button from "../../components/lib/Button";
import LinkButton from "../../components/lib/LinkButton";
import Container from "../../components/lib/Container";
import EditActions from "../../components/lib/EditActions";
import { ModalButtons, ModalTitle } from "../ratecards/components/FilterModal";
import Box from "../../components/lib/Box";

type Props = {
  store: MobXStore,
};

class List extends Component<Props, null> {
  componentDidMount() {
    this.props.store.savedSearchesListStore.pagination.goFetch();

    // reset to null so some elements visible while editing start hidden
    if (!this.props.store.savedSearchesListStore.isTagView) {
      this.props.store.savedSearchesListStore.isEditing = null;
    }
    //this.props.store.savedSearchesListStore.getAllRateCardsList()
  }

  componentWillUnmount() {
    // this.props.store.savedSearchesListStore.clearFilters();
    // this.props.store.savedSearchesListStore.clearAllSelections();
    // this.props.store.savedSearchesListStore.applyDefaultSort();
  }

  addToRateCardModal = () => {
    this.props.store.savedSearchesListStore.rateCardsListStore.allowMultipleItemSelection = false;
    this.props.store.savedSearchesListStore.rateCardsListStore.isEditing = true;
    this.props.store.savedSearchesListStore.rateCardsListStore.pagination.goFetch();
    this.props.store.savedSearchesListStore.addToRateCardModal.showModal();
  };

  closeAddToRateCardModal = () => {
    this.props.store.savedSearchesListStore.addToRateCardModal.hideModal();
  };

  addSavedSearchToRateCard = () => {
    this.props.store.savedSearchesListStore.addSavedSearchToRateCard();
  };

  render() {
    const store = this.props.store.savedSearchesListStore;
    const rateSearchStore = this.props.store.rateSearchStore;

    return (
      <Container
        css={{
          paddingTop: "$4",
          paddingLeft: 0,
          paddingRight: 0,
          width: "100%",
        }}
      >
        <Card css={{ zIndex: 2 }}>
          {!store.isTagView && (
            <CardHeader>
              <CardHeaderTitle>Searches</CardHeaderTitle>
              <CardHeaderSubTitle>
                View; Sort; Filter searches you have done.
              </CardHeaderSubTitle>
            </CardHeader>
          )}
          <div className="header-action-bar marginTopzero">
            <CardActions>
              <CardActionsLeft>
                <SearchBox
                  value={store.jobSearchFilter.textToLookFor}
                  onChange={store.jobSearchFilter.onTextToLookForChange}
                  onSubmit={store.jobSearchFilter.onApply}
                  css={{ width: "260px" }}
                />
              </CardActionsLeft>
              {!store.isTagView && (
                <CardActionsRight>
                  {store.isEditing && (
                    <Button color="accent" onClick={store.handleStopEdit}>
                      Stop Editing
                    </Button>
                  )}
                  {!store.isEditing && (
                    <>
                      <Button icon="edit" color="brand" onClick={store.handleStartEdit}>
                        Edit
                      </Button>
                      <LinkButton icon="plus" color="brand" to="/ratecards/create">
                        New Rate Card
                      </LinkButton>
                      <Button icon="question-circle" onClick={store.helpModal.showModal}>
                        Help
                      </Button>
                    </>
                  )}
                </CardActionsRight>
              )}
            </CardActions>
          </div>
          <SavedSearchesList store={store} rateSearchStore={rateSearchStore} />
        </Card>

        {!store.isTagView && (
          <EditActions hidden={store.isEditing === null} show={Boolean(store.isEditing)}>
            <Button
              disabled={store.selectedCount === 0}
              onClick={this.addToRateCardModal}
            >
              Add to Rate Card
            </Button>
            <Button
              disabled={store.selectedCount === 0}
              onClick={store.applyTagState.showTagModal}
            >
              Apply Tag
            </Button>
            <Button
              color="danger"
              icon="trash-alt"
              disabled={store.selectedCount === 0}
              onClick={store.confirmDeleteModal.showModal}
            >
              Delete
            </Button>
          </EditActions>
        )}

        <Modal show={store.helpModal.show} onHide={store.helpModal.hideModal}>
          <div className="container-section header">
            <ModalTitle>Help</ModalTitle>
          </div>
          <div className="container-section footer">
            <p>
              Here you can view, sort and filter all of your searches. Each search shows
              the PeopleTicker "Job Title" (dash) "Job Label" along with other
              information. "Draft" searches are searches without a corresponding ratecard.
              To find your draft searches, use the "Draft" filter. Use the edit menu, to
              add searches to a ratecard, delete them and more.
            </p>
          </div>
        </Modal>

        <Modal
          show={store.addToRateCardModal.show}
          onHide={store.addToRateCardModal.hideModal}
          innerStyle={{
            width: "80vw",
          }}
        >
          <div className="container-section header">
            <ModalTitle>Choose Rate Card to add searches to</ModalTitle>
          </div>
          <div className="rc-viewport overlap-lr-padding">
            <ContainerSection className="overlap-t-padding">
              <div className="header-action-bar">
                <SearchBox
                  value={store.rateCardsListStore.rateCardLabelFilter.textToLookFor}
                  onChange={
                    store.rateCardsListStore.rateCardLabelFilter.onTextToLookForChange
                  }
                  onSubmit={store.rateCardsListStore.rateCardLabelFilter.onApply}
                  style={{ width: "260px" }}
                />
              </div>
            </ContainerSection>
            <Box css={{ margin: "0 -20px" }}>
              <RateCardsList store={store.rateCardsListStore} />
            </Box>
          </div>
          <ContainerSection className="footer">
            <ModalButtons>
              <Button
                color="brand"
                size="large"
                onClick={() => {
                  this.addSavedSearchToRateCard();
                  store.addToRateCardModal.hideModal();
                  store.rateCardsListStore.deselectAllPage();
                }}
              >
                Add
              </Button>
              <Button size="large" onClick={this.closeAddToRateCardModal}>
                Cancel
              </Button>
            </ModalButtons>
          </ContainerSection>
        </Modal>

        <Modal
          show={store.confirmDeleteModal.show}
          onHide={store.confirmDeleteModal.hideModal}
        >
          <div className="container-section header">
            <ModalTitle>Confirmation Needed</ModalTitle>
          </div>
          <div className="container-section">
            <p>Are you sure you want to delete the selected Saved Search?</p>
            <p className="text-danger">
              <FontAwesomeIcon icon="exclamation-triangle" />{" "}
              <em>This action cannot be undone!</em>
            </p>
          </div>

          <div className="container-section footer">
            <ModalButtons>
              <Button
                color="danger"
                size="large"
                onClick={() => {
                  store.confirmDeleteModal.hideModal();
                  store.deleteRateCards();
                }}
              >
                Yes, Delete Saved Search
              </Button>
              <Button size="large" onClick={store.confirmDeleteModal.hideModal}>
                No, Cancel
              </Button>
            </ModalButtons>
          </div>
        </Modal>

        <ApplyTagFilterModal
          filterState={store.applyTagState}
          applyTagsEvent={store.applyTags}
          btnText="Apply Tag to Saved Searches"
        />
      </Container>
    );
  }
}

export default observer(List);
