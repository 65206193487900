import React from "react";
import { keyframes, styled, CSS } from "../../stitches.config";

const loading = keyframes({
  "0%": {
    transform: "translateX(-0%)",
  },
  "100%": {
    transform: "translateX(300%)",
  },
});

const Wrapper = styled("div", {
  position: "relative",
  height: "100%",
  width: "100%",
  backgroundColor: "$secondaryLightest",
  overflow: "hidden",
  borderRadius: "$rounded",
});

const Activity = styled("div", {
  position: "absolute",
  left: "-50%",
  height: "100%",
  width: "50%",
  backgroundImage: `linear-gradient(
      to left,
    rgba(251,251,251, .05),
    rgba(251,251,251, .3),
    rgba(251,251,251, .6),
    rgba(251,251,251, .3),
    rgba(251,251,251, .05)
  )`,
  animation: `${loading} .8s infinite`,
});

export type PlaceholderProps = {
  css?: CSS;
  width?: CSS["width"];
  height?: CSS["height"];
  bgColor?: CSS["backgroundColor"];
};

export function Placeholder(props: PlaceholderProps) {
  const {
    css = {},
    width = "100%",
    height = "16px",
    bgColor = "$secondaryLightest",
  } = props;
  return (
    <Wrapper css={{ ...css, width, height, backgroundColor: bgColor }}>
      <Activity />
    </Wrapper>
  );
}
