// @flow
import * as React from "react";
import type { FetchAPI } from "../App";
import SessionInfo from "../models/SessionInfo";
import { createFragmentContainer, QueryRenderer, useRelayEnvironment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import type { AdminSearchDetailQueryResponse as QueryResponse } from "./__generated__/AdminSearchDetailQuery.graphql";
import type { AdminSearchDetail_viewer as Viewer } from "./__generated__/AdminSearchDetail_viewer.graphql";
import type { RenderProps } from "react-relay/ReactRelayQueryRenderer";
import LoadingIndicator from "../views/shared/LoadingIndicator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { browserHistory } from "react-router";
import { TagItemWithoutDelete } from "../views/ratecards/components/TagItem";
import { parseAbsoluteToLocal, DateFormatter } from "@internationalized/date";
import Modal from "../views/ratecards/components/Modal";
import numeral from "numeral";
import SearchPeekTable, { dataOptions, SingleSelectGroup } from "./SearchPeekTable";
import type { ChangeData, ChangeKind, DataOption, RowData } from "./SearchPeekTable";
import HistogramChart from "./rate_search/HistogramChart";
import NonIdealState from "../views/job_library/components/NonIdealState";
import PunchOutTable from "./PunchOutTable";
import type { RowData as PunchOutRowData } from "./PunchOutTable";

const DATE_FORMATTER = new DateFormatter("en-US", {
  dateStyle: "long",
});

const TIME_FORMATTER = new DateFormatter("en-US", {
  timeStyle: "medium",
});

type SavedSearch = $ElementType<Viewer, "savedsearch">;
type BuyRateList = $ElementType<$NonMaybeType<SavedSearch>, "buyrates">;
type BuyRateItem = $ElementType<$NonMaybeType<BuyRateList>, 0>;
type TagsList = $ElementType<$NonMaybeType<SavedSearch>, "tags">;

type DetailHeaderProps = {
  jobLabel: string,
  jobTitle: string,
  createdBy: string,
  createdDate: string,
  location: string,
  isGSS: boolean,
  tags: TagsList,
};

function DetailHeader(props: DetailHeaderProps): React.Node {
  const [showHelp, setShowHelp] = React.useState(false);
  const { jobLabel, jobTitle, createdBy, createdDate, location, isGSS, tags } = props;

  const handleGoBack = () => {
    browserHistory.goBack();
  };

  const handleShowHelp = () => {
    setShowHelp(true);
  };

  return (
    <div className="top">
      <div className="above">
        <div
          className="ratecard-label-header ellipsis max-width-80 flex-column"
          style={{ margin: "0 0 20px 0" }}
        >
          <h2 className="ellipsis no-padding">{jobLabel}</h2>
          <h3 className="ellipsis no-padding text-thin">{jobTitle}</h3>
        </div>
        <nav className="back-bar">
          <button className="btn btn-green" onClick={handleGoBack}>
            <FontAwesomeIcon icon="arrow-left" fixedWidth className="icon" />
            Go Back
          </button>
          <button className="btn" onClick={handleShowHelp}>
            <FontAwesomeIcon icon="question-circle" fixedWidth className="icon" />
            Help
          </button>
        </nav>
      </div>
      <div className="below">
        <div className="ratecard-meta ellipsis">
          <div className="ratecard-created-at ellipsis">
            <FontAwesomeIcon icon="user" fixedWidth className="icon" /> {createdBy}
          </div>
          <div className="ratecard-created-at ellipsis">
            <FontAwesomeIcon icon="calendar-alt" fixedWidth className="icon" /> Created{" "}
            {createdDate}
          </div>
          <div className="ratecard-created-at ellipsis">
            <FontAwesomeIcon icon="map-marker" fixedWidth className="icon" /> {location}
          </div>
          {isGSS && (
            <div className="ratecard-created-at ellipsis">
              <FontAwesomeIcon icon="globe" fixedWidth className="icon" /> Global Supplier
              Search
            </div>
          )}
        </div>
        {/*actionBar*/}
      </div>
      <div className="tagbox">
        {tags?.map((tag) => (
          <TagItemWithoutDelete key={tag?.tagId} tag={tag} />
        ))}
      </div>
      <Modal show={showHelp} onHide={() => setShowHelp(false)}>
        <div className="container-section header">
          <h4>Help</h4>
        </div>
        <div className="container-section footer">
          <p>Here you can see details of this Search.</p>
        </div>
      </Modal>
    </div>
  );
}

type ImplProps = {
  viewer: Viewer,
};

function getChangeKind(value: number): ChangeKind {
  if (value < 0) return "negative-change";
  if (value > 0) return "positive-change";
  return "no-change";
}

export function getTableData(search: SavedSearch): Array<RowData> {
  const data: Array<RowData> = [];

  const { rates, marketchanges } = search || {};
  const { payRate, billRate, markupPct } = rates || {};
  const levelNames = ["Junior", "Mid", "Senior", "Lead", "Guru"];
  const levelRomanNumerals = ["I", "II", "III", "IV", "V"];
  const payRateMinData = payRate?.min || [];
  const payRateMidData = payRate?.mid || [];
  const payRateAvgData = payRate?.avg || [];
  const payRateMaxData = payRate?.max || [];
  const billRateMinData = billRate?.min || [];
  const billRateMidData = billRate?.mid || [];
  const billRateAvgData = billRate?.avg || [];
  const billRateMaxData = billRate?.max || [];
  const markupPctMinData = markupPct?.min || [];
  const markupPctMidData = markupPct?.mid || [];
  const markupPctAvgData = markupPct?.avg || [];
  const markupPctMaxData = markupPct?.max || [];

  for (let level = 0; level < 5; level++) {
    const change = (marketchanges || [])[level];

    const payRateMin = payRateMinData[level] || 0;
    const payRateMinChange = payRateMin - (change?.payRateMin || 0);
    const payRateMinChangeType = getChangeKind(payRateMinChange);

    const payRateMid = payRateMidData[level] || 0;
    const payRateMidChange = payRateMid - (change?.payRateMid || 0);
    const payRateMidChangeType = getChangeKind(payRateMidChange);

    const payRateAvg = payRateAvgData[level] || 0;
    const payRateAvgChange = payRateAvg - (change?.payRateAvg || 0);
    const payRateAvgChangeType = getChangeKind(payRateAvgChange);

    const payRateMax = payRateMaxData[level] || 0;
    const payRateMaxChange = payRateMax - (change?.payRateMax || 0);
    const payRateMaxChangeType = getChangeKind(payRateMaxChange);

    const billRateMin = billRateMinData[level] || 0;
    const billRateMinChange = billRateMin - (change?.billRateMin || 0);
    const billRateMinChangeType = getChangeKind(billRateMinChange);

    const billRateMid = billRateMidData[level] || 0;
    const billRateMidChange = billRateMid - (change?.billRateMid || 0);
    const billRateMidChangeType = getChangeKind(billRateMidChange);

    const billRateAvg = billRateAvgData[level] || 0;
    const billRateAvgChange = billRateAvg - (change?.billRateAvg || 0);
    const billRateAvgChangeType = getChangeKind(billRateAvgChange);

    const billRateMax = billRateMaxData[level] || 0;
    const billRateMaxChange = billRateMax - (change?.billRateMax || 0);
    const billRateMaxChangeType = getChangeKind(billRateMaxChange);

    const markupPctMin = markupPctMinData[level] || 0;
    const markupPctMinChange = markupPctMin - (change?.markupPctMin || 0);
    const markupPctMinChangeType = getChangeKind(markupPctMinChange);

    const markupPctMid = markupPctMidData[level] || 0;
    const markupPctMidChange = markupPctMid - (change?.markupPctMid || 0);
    const markupPctMidChangeType = getChangeKind(markupPctMidChange);

    const markupPctAvg = markupPctAvgData[level] || 0;
    const markupPctAvgChange = markupPctAvg - (change?.markupPctAvg || 0);
    const markupPctAvgChangeType = getChangeKind(markupPctAvgChange);

    const markupPctMax = markupPctMaxData[level] || 0;
    const markupPctMaxChange = markupPctMax - (change?.markupPctMax || 0);
    const markupPctMaxChangeType = getChangeKind(markupPctMaxChange);

    const changeData: ChangeData = {
      payRateMinChange,
      payRateMinChangeType,
      payRateMidChange,
      payRateMidChangeType,
      payRateAvgChange,
      payRateAvgChangeType,
      payRateMaxChange,
      payRateMaxChangeType,
      billRateMinChange,
      billRateMinChangeType,
      billRateMidChange,
      billRateMidChangeType,
      billRateAvgChange,
      billRateAvgChangeType,
      billRateMaxChange,
      billRateMaxChangeType,
      markupPctMinChange,
      markupPctMinChangeType,
      markupPctMidChange,
      markupPctMidChangeType,
      markupPctAvgChange,
      markupPctAvgChangeType,
      markupPctMaxChange,
      markupPctMaxChangeType,
    };

    const rowData: RowData = {
      id: level + 1,
      levelName: levelNames[level],
      levelRomanNumeral: levelRomanNumerals[level],
      payRateMin,
      payRateMid,
      payRateAvg,
      payRateMax,
      billRateMin,
      billRateMid,
      billRateAvg,
      billRateMax,
      markupPctMin,
      markupPctMid,
      markupPctAvg,
      markupPctMax,
      change: changeData,
    };

    data.push(rowData);
  }

  return data;
}

export function getPunchOutData(buyRate: BuyRateItem): Array<PunchOutRowData> {
  const { punchouts } = buyRate || {};
  return (
    punchouts?.map((po) => {
      const {
        id,
        levelName,
        levelRomanNumeral,
        payRateMin,
        payRateMax,
        billRateMin,
        billRateMax,
        markupPct,
      } = po || {};
      return {
        id: id || "",
        levelName: levelName || "",
        levelRomanNumeral: levelRomanNumeral || "",
        payRateMin: payRateMin || 0,
        payRateMax: payRateMax || 0,
        billRateMin: billRateMin || 0,
        billRateMax: billRateMax || 0,
        markupPct: markupPct || 0,
      };
    }) || []
  );
}

function AdminSearchDetailImpl(props: ImplProps): React.Node {
  const [showMarkupAndBillRate /*setShowMarkupAndBillRate*/] = React.useState(true);
  const [selectedDataOption, setSelectedDataOption] = React.useState<DataOption>("rates");

  const { viewer } = props;
  const { savedsearch } = viewer || {};
  const {
    job,
    tags,
    createdBy,
    createdDate,
    region,
    country,
    state,
    city,
    currency,
    rates,
    ratesData,
    rateType,
    canRevert,
    needsUpdate,
    numResults,
    buyrates,
    isGlobalSupplierSearch,
  } = savedsearch || {};
  const { jobLabel = "", jobTitle = "" } = job || {};
  const createdByDisplay = [createdBy?.firstName, createdBy?.lastName]
    .filter((v) => Boolean(v))
    .join(" ");
  const parsedDate = parseAbsoluteToLocal(createdDate).toDate();
  const createdDateDisplay = `on ${DATE_FORMATTER.format(
    parsedDate
  )} at ${TIME_FORMATTER.format(parsedDate)}`;
  const location = [city, state, country].filter((v) => Boolean(v)).join(", ");
  const locationDisplay = region
    ? region?.name || "- Unnamed Region -"
    : location || "- Unknown Location -";

  const currencyDisplay = currency?.symbol || "N/A";
  const payRateAvgTotal = (rates?.payRate?.avg || [])[2] || 0;
  const markupAvgTotal = (rates?.markupPct?.avg || [])[2] || 0;
  const billRateAvgTotal = (rates?.billRate?.avg || [])[2] || 0;
  const payRateAvgMedian = (rates?.payRate?.mid || [])[2] || 0;
  const markupAvgMedian = (rates?.markupPct?.mid || [])[2] || 0;
  const billRateAvgMedian = (rates?.billRate?.mid || [])[2] || 0;

  const tableData: Array<RowData> = getTableData(savedsearch);

  const handleUndoLastUpdate = () => {
    // store.confirmUndoUpdateRatesOnSearchModal.search = search;
    // store.confirmUndoUpdateRatesOnSearchModal.showModal();
  };

  const handleUpdateRates = () => {
    // store.confirmUpdateRatesOnSearchModal.search = search;
    // store.confirmUpdateRatesOnSearchModal.showModal();
  };

  return (
    <div className="view">
      <div className="rc-container show-overflow">
        <DetailHeader
          jobLabel={jobLabel}
          jobTitle={jobTitle || "- No Job Title -"}
          createdBy={createdByDisplay || createdBy?.email || "Unknown"}
          createdDate={createdDateDisplay}
          location={locationDisplay}
          isGSS={isGlobalSupplierSearch || false}
          tags={tags}
        />
      </div>
      <div className="rc-container bring-forward">
        <div className="flex center-items mb15">
          <h4>Summary</h4>
          <div style={{ flex: "1 1 auto" }} />
          <div className="text-muted text-x-small info">{numResults} Samples</div>
        </div>
        <div className="saved-search-details">
          <div className="col-sm-4 list-rate">
            <div className="info-box ">
              <FontAwesomeIcon icon="info-circle" aria-hidden="true" />
              <span>PAY RATE</span>
            </div>
            <div className="rates-summary">
              <div className="rate_box left-r">
                <h2>{currencyDisplay + numeral(payRateAvgTotal).format("0,0.00")}</h2>
                <small>Average</small>
              </div>
              <div className="rate_box">
                <h2>{currencyDisplay + numeral(payRateAvgMedian).format("0,0.00")}</h2>
                <small>Median</small>
              </div>
            </div>
          </div>
          {showMarkupAndBillRate && (
            <div className="col-sm-4 list-rate">
              <div className="info-box info-box_second ">
                <FontAwesomeIcon icon="info-circle" aria-hidden="true" />
                <span>MARKUP</span>
              </div>
              <div className="rates-summary">
                <div className="rate_box left-r">
                  <h2>{markupAvgTotal.toFixed(2)}%</h2>
                  <small>Average</small>
                </div>
                <div className="rate_box">
                  <h2>{markupAvgMedian.toFixed(2)}%</h2>
                  <small>Median</small>
                </div>
              </div>
            </div>
          )}
          {showMarkupAndBillRate && (
            <div className="col-sm-4 list-rate">
              <div className="info-box info-box_third">
                <FontAwesomeIcon icon="info-circle" aria-hidden="true" />
                <span>BILL RATE</span>
              </div>
              <div className="rates-summary">
                <div className="rate_box left-r">
                  <h2>{currencyDisplay + numeral(billRateAvgTotal).format("0,0.00")}</h2>
                  <small>Average</small>
                </div>
                <div className="rate_box">
                  <h2>{currencyDisplay + numeral(billRateAvgMedian).format("0,0.00")}</h2>
                  <small>Median</small>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="rc-container bring-forward">
        <div className="flex center-items mb15">
          <h4>Rates</h4>
          <div style={{ flex: "1 1 auto" }} />
          <div className="btn-toolbar main-toolbar">
            <div className="rc-actions-bar" style={{ float: "unset" }}>
              {!needsUpdate && (
                <p className="text-muted text-x-small no-margin info">
                  <FontAwesomeIcon icon="info-circle" fixedWidth className="icon" />
                  <em>Rates are up to the latest available revision.</em>
                </p>
              )}
              {canRevert && (
                <button
                  className="btn btn-yellow no-margin"
                  onClick={handleUndoLastUpdate}
                  disabled
                >
                  <FontAwesomeIcon icon="undo" className="icon" /> Undo Last Update
                </button>
              )}
              {needsUpdate && (
                <button
                  className="btn btn-green no-margin"
                  onClick={handleUpdateRates}
                  disabled
                >
                  <FontAwesomeIcon icon="sync" className="icon" /> Update Rates
                </button>
              )}
            </div>
            <div className="rc-actions-bar" style={{ float: "unset" }}>
              <SingleSelectGroup
                values={Object.keys(dataOptions)}
                labels={["Rates", "Changes"]}
                selected={selectedDataOption}
                onChange={(value) => setSelectedDataOption(value)}
              />
            </div>
          </div>
        </div>
        <SearchPeekTable
          data={tableData}
          rateMultiplier={1}
          currencySymbol={currencyDisplay}
          rateType={rateType === 2 ? "annual" : "hourly"}
          dataToShow={selectedDataOption}
        />
      </div>

      {buyrates && buyrates.length > 0 && (
        <div className="flex punchouts-container">
          {buyrates.map((buyRate) => {
            const { name } = buyRate || {};
            const data = getPunchOutData(buyRate);
            return (
              <div className="rc-container bring-forward">
                <div className="mb15">
                  <h4>{name || "- Unnamed Punch-Out -"}</h4>
                </div>
                <PunchOutTable currencySymbol={currencyDisplay} data={data} />
              </div>
            );
          })}
        </div>
      )}

      {(!buyrates || buyrates.length === 0) && (
        <div className="flex punchouts-container">
          <div className="rc-container bring-forward">
            <div className="mb15">
              <h4>Punch-Outs</h4>
            </div>
            <NonIdealState
              icon="list-alt"
              iconClasses=""
              title="No Punch-Outs Yet!"
              description="This search has no Punch-Outs configured."
            />
          </div>
        </div>
      )}

      {/*{(!buyrates || buyrates.length === 0) && (*/}
      {/*  <div className="rc-container bring-forward">*/}
      {/*    <div className="mb15">*/}
      {/*      <h4>Punch-Outs</h4>*/}
      {/*    </div>*/}
      {/*    <NonIdealState*/}
      {/*      icon="list-alt"*/}
      {/*      iconClasses=""*/}
      {/*      title="No Punch-Outs Yet!"*/}
      {/*      description="This search has no Punch-Outs configured."*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*)}*/}

      <div className="rc-container bring-forward">
        <div className="mb15">
          <h4>Histogram</h4>
        </div>
        {ratesData && ratesData.length > 0 && (
          <HistogramChart active={true} results={ratesData} />
        )}
        {(!ratesData || ratesData.length === 0) && (
          <NonIdealState
            icon="chart-column"
            iconClasses=""
            title="No Data"
            description="This search has no data for the histogram chart"
          />
        )}
      </div>
    </div>
  );
}

const AdminSearchDetailFragmentContainer = createFragmentContainer(
  AdminSearchDetailImpl,
  {
    viewer: graphql`
      fragment AdminSearchDetail_viewer on Viewer
      @argumentDefinitions(searchId: { type: "Int!" }) {
        savedsearch(id: $searchId, section: ADMIN) {
          searchId
          isDraft
          isGlobalSupplierSearch
          tags {
            name
            tagId
          }
          currency {
            symbol
          }
          job {
            jobLabel
            jobTitle
          }
          industry {
            legacyId
            value
          }
          city
          rateType
          country
          state
          region {
            name
            country {
              name
            }
          }
          buyrates {
            buyrateId
            name
            notes
            punchouts {
              id
              levelName
              levelRomanNumeral
              payRateMin
              payRateMax
              billRateMin
              billRateMax
              markupPct
            }
          }
          marketRate {
            payRate
            billRate
            markupPct
          }
          marketchanges {
            revNum
            level {
              legacyId
              literal
              romanNumeral
            }
            numResults
            billRateAvg
            billRateMax
            billRateMid
            billRateMin
            payRateAvg
            payRateMax
            payRateMid
            payRateMin
            markupPctAvg
            markupPctMax
            markupPctMid
            markupPctMin
            # markupAmtAvg
            # markupAmtMax
            # markupAmtMid
            # markupAmtMin
          }
          rates {
            markupPct {
              min
              max
              avg
              mid
            }
            billRate {
              min
              max
              avg
              mid
            }
            # markupAmt {
            #   min
            #   max
            #   avg
            #   mid
            # }
            payRate {
              min
              max
              avg
              mid
            }
          }
          ratesData
          createdDate
          createdBy {
            firstName
            lastName
            email
          }
          canRevert
          needsUpdate
          numResults
        }
      }
    `,
  }
);

type QueryRendererProps = {
  route: { fetchAPI: FetchAPI },
  params: { searchId?: string },
  sessionInfo: SessionInfo,
};

function AdminSearchDetail(props: QueryRendererProps): React.Node {
  const { params } = props;
  const searchId: number = parseInt(params?.searchId || 0);
  const relayEnvironment = useRelayEnvironment();

  return (
    <QueryRenderer
      environment={relayEnvironment}
      query={graphql`
        query AdminSearchDetailQuery($searchId: Int!) {
          viewer {
            ...AdminSearchDetail_viewer @arguments(searchId: $searchId)
          }
        }
      `}
      variables={{ searchId }}
      render={(renderProps: RenderProps<QueryResponse>): React.Node => {
        const { props, error } = renderProps;

        if (error) {
          console.error(error);
          return (
            <div>
              Error on initially loading data. Please see console for more information.
            </div>
          );
        }

        const { viewer } = props || {};
        if (!viewer) return <LoadingIndicator />;

        return <AdminSearchDetailFragmentContainer viewer={viewer} />;
      }}
    />
  );
}

export default AdminSearchDetail;
