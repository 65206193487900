// @flow
import { extendObservable, action, runInAction } from "mobx";
import ModalState from "./ModalState";
import NetworkState from "./NetworkState";
import BuyRate from "./BuyRate";
import PunchOutRow from "./PunchOutRow";
import type { FetchAPI, FetchGraphQL } from "../App";
import Search from "./Search";

const createQuery = `
mutation create($searchId: Int!, $name: String!){
  createBuyRate(input: {
      searchId: $searchId,
      name: $name,
    }) {

    buyRate {
      buyrateId
      name
      created
    }

    errors {
      __typename
      ...on DoesNotExistError {
        message
        id
      }
    }
  }
}
`;

const updateQuery = `
mutation update($buyRateId: Int!, $name: String!){
  updateBuyRate(input: {
      buyRateId: $buyRateId,
      name: $name,
    }) {

    buyRate {
      buyrateId
      name
    }

    errors {
      __typename
      ...on DoesNotExistError {
        message
        id
      }
    }
  }
}
`;

const deleteQuery = `
mutation delete($buyRateId: Int!){
  deleteBuyRate(input: {
      buyRateId: $buyRateId
    }) {

    errors {
      __typename
      ...on DoesNotExistError {
        message
        id
      }
    }
  }
}
`;

const undoDeletedInternalResultQuery = `
mutation undo($buyRateId: Int!,
              $levelId: Int!,
              $payRateMin: Float!,
              $payRateMax: Float!,
              $markupPct: Float!,
              $billRateMin: Float!,
              $billRateMax: Float!,
              $salaryMin: Float!,
              $salaryMax: Float!,
              ){
  createOrUpdatePunchOut(input: {
      buyRateId: $buyRateId,
      levelId: $levelId,
      payRateMin: $payRateMin,
      payRateMax: $payRateMax,
      markupPct: $markupPct,
      billRateMin: $billRateMin,
      billRateMax: $billRateMax,
      salaryMin: $salaryMin,
      salaryMax: $salaryMax,
    }) {

    punchOut {
      punchoutId
    }

    errors {
      __typename
      ...on DoesNotExistError {
        message
        id
      }
    }
  }
}
`;

export default class BuyRateState {
  PUNCH_OUT_RATES_TAB: number;
  PUNCH_OUT_MARKET_COMPARE_TAB: number;

  search: Search;
  fetchGraphQL: FetchGraphQL;
  fetchAPI: FetchAPI;
  buyRate: ?BuyRate;
  deletedPunchOutRow: ?PunchOutRow;

  name: ?string;
  showDeletionConfirmation: boolean;

  modal: ModalState;
  networkState: NetworkState;
  selectedTab: number;

  onNameChange: (string) => void;
  onTabChange: (SyntheticInputEvent<HTMLButtonElement>) => void;
  submit: () => void;
  create: () => void;
  update: () => void;
  doDelete: () => void;
  hideDeletionConfirmation: () => void;
  cancelDeletion: () => void;
  confirmDeletion: () => void;
  isEmptyBuyRate: () => boolean;
  exportToExcel: () => void;
  undoBuyRateDelete: () => void;
  ignoreDeletedInternalResult: (SyntheticMouseEvent<HTMLButtonElement>) => void;
  showModal: () => void;
  hideModal: () => void;
  resetAndClose: () => void;
  reset: () => void;

  constructor(
    fetchGraphQL: FetchGraphQL,
    fetchAPI: FetchAPI,
    search: Search,
    buyRate: ?BuyRate
  ) {
    this.PUNCH_OUT_RATES_TAB = 0;
    this.PUNCH_OUT_MARKET_COMPARE_TAB = 1;

    this.fetchGraphQL = fetchGraphQL;
    this.fetchAPI = fetchAPI;
    this.search = search;
    this.buyRate = buyRate;
    this.modal = new ModalState();

    extendObservable(this, {
      name: buyRate ? buyRate.name : null,
      deletedPunchOutRow: null, // for undo deletion of item
      networkState: new NetworkState(),
      showDeletionConfirmation: false,
      selectedTab: this.PUNCH_OUT_RATES_TAB,
    });

    this.onNameChange = action(this.onNameChange.bind(this));
    this.onTabChange = action(this.onTabChange.bind(this));
    this.submit = action(this.submit.bind(this));
    this.create = action(this.create.bind(this));
    this.update = action(this.update.bind(this));
    this.doDelete = action(this.doDelete.bind(this));
    this.hideDeletionConfirmation = action(this.hideDeletionConfirmation.bind(this));
    this.cancelDeletion = action(this.cancelDeletion.bind(this));
    this.confirmDeletion = action(this.confirmDeletion.bind(this));
    this.isEmptyBuyRate = action(this.isEmptyBuyRate.bind(this));
    this.exportToExcel = action(this.exportToExcel.bind(this));
    this.undoBuyRateDelete = action(this.undoBuyRateDelete.bind(this));
    this.ignoreDeletedInternalResult = action(
      this.ignoreDeletedInternalResult.bind(this)
    );
    this.showModal = action(this.showModal.bind(this));
    this.hideModal = action(this.hideModal.bind(this));
    this.resetAndClose = action(this.resetAndClose.bind(this));
    this.reset = action(this.reset.bind(this));
  }

  onTabChange(event: SyntheticInputEvent<HTMLButtonElement>) {
    this.selectedTab = parseInt(event.currentTarget.value, 10);
  }

  onNameChange(value: string) {
    this.name = value;
  }

  submit() {
    if (this.buyRate === null) this.create();
    else this.update();
  }

  async create() {
    if (this.networkState.loading) {
      return;
    }

    if (!this.name) return;

    this.networkState.loading = true;
    let res = null;
    try {
      res = await this.fetchGraphQL(createQuery, {
        searchId: this.search.id,
        name: this.name,
      });
    } catch (e) {
      this.networkState.handleError("Creating BuyRate", e);
      // TODO: Display user friendly error message
      return;
    }

    runInAction("create--success", () => {
      this.networkState.loading = false;
      this.networkState.error = null;

      if (this.networkState.logGraphQLError("createBuyRate", res)) {
        // TODO: Display user friendly error message
        return;
      }

      // NOTE: Flow refinement
      if (res === null) {
        return;
      }

      const buyRate = new BuyRate(res.data.createBuyRate.buyRate);
      if (!this.search.buyRates) this.search.buyRates = [];
      this.search.buyRates.push(buyRate);

      this.resetAndClose();
    });
  }

  async update() {
    if (this.networkState.loading) {
      return;
    }

    if (this.buyRate == null) {
      return;
    }

    this.networkState.loading = true;
    let payload = null;
    let queryVars = {
      buyRateId: this.buyRate.id,
      name: this.name,
    };

    try {
      payload = await this.fetchGraphQL(updateQuery, queryVars);
    } catch (e) {
      this.networkState.handleError("Update Punchout", e);
      // TODO: Display user friendly error message
      return;
    }

    runInAction("update--success", () => {
      this.networkState.loading = false;
      this.networkState.error = null;

      if (this.networkState.logGraphQLError("editInternalRateCard", payload)) {
        // TODO: Display user friendly error message
        return;
      }

      if (this.buyRate) {
        this.buyRate.name = this.name;
      }

      this.resetAndClose();
    });
  }

  hideDeletionConfirmation() {
    this.showDeletionConfirmation = false;
  }

  cancelDeletion() {
    this.hideDeletionConfirmation();
  }

  confirmDeletion() {
    // this.search.store.buyRateState = this;
    this.showDeletionConfirmation = true;
  }

  isEmptyBuyRate() {
    if (this.buyRate == null) {
      return true;
    }

    const punchOutRows = this.buyRate.punchOutRows.filter(
      (punchOut) => punchOut !== null
    );

    return punchOutRows.length === 0;
  }

  ignoreDeletedInternalResult(event: SyntheticMouseEvent<HTMLButtonElement>) {
    this.deletedPunchOutRow = null;
  }

  async doDelete() {
    if (this.networkState.loading) return;

    this.hideDeletionConfirmation();

    if (this.buyRate == null) {
      return;
    }

    this.networkState.loading = true;
    let payload = null;
    let queryVars = {
      buyRateId: this.buyRate.id,
    };

    try {
      payload = await this.fetchGraphQL(deleteQuery, queryVars);
    } catch (e) {
      this.networkState.handleError("Delete Punchout", e);
      // TODO: Display user friendly error message
      return;
    }

    runInAction("doDelete--success", () => {
      this.networkState.loading = false;
      this.networkState.error = null;

      if (this.networkState.logGraphQLError("deleteInternalRateCard", payload)) {
        // TODO: Display user friendly error message
        return;
      }

      if (this.buyRate) {
        let buyRateId = this.buyRate.id;

        const buyRates = this.search.buyRates;
        if (buyRates) {
          this.search.buyRates = buyRates.filter((item) => item.id !== buyRateId);
        }
      }

      this.resetAndClose();
    });
  }

  async exportToExcel() {
    if (this.networkState.loading) return;

    if (this.buyRate == null) {
      return;
    }

    this.networkState.loading = true;

    let exportURL = "ratecards/internal/rates/" + this.buyRate.id + "/export/excel/";
    this.fetchAPI(exportURL).then(
      (res) => {
        window.location.href = res.data.url;

        this.networkState.loading = false;
        this.networkState.error = null;
        // TODO: Create this method?
        // this.handleStopEdit();
      },
      (err) => {
        this.networkState.handleError(exportURL, err);
        // TODO: Display user friendly error message
      }
    );
  }

  async undoBuyRateDelete() {
    if (this.networkState.loading) return;

    if (this.buyRate == null || this.deletedPunchOutRow == null) {
      return;
    }

    this.networkState.loading = true;
    let payload = null;
    let queryVars = {
      buyRateId: this.buyRate.id,
      levelId: this.deletedPunchOutRow.levelId,
      payRateMin: this.deletedPunchOutRow.payRateMin || 0,
      payRateMax: this.deletedPunchOutRow.payRateMax || 0,
      markupPct: this.deletedPunchOutRow.markupPct || 0,
      billRateMin: this.deletedPunchOutRow.billRateMin || 0,
      billRateMax: this.deletedPunchOutRow.billRateMax || 0,
      salaryMin: this.deletedPunchOutRow.salaryMin || 0,
      salaryMax: this.deletedPunchOutRow.salaryMax || 0,
    };
    try {
      payload = await this.fetchGraphQL(undoDeletedInternalResultQuery, queryVars);
    } catch (e) {
      this.networkState.handleError("Undo delete Buy Rate", e);
      // TODO: Display user friendly error message
      return;
    }

    runInAction("undoBuyRateDelete--success", () => {
      this.networkState.loading = false;
      this.networkState.error = null;

      // NOTE: Flow refinement
      if (!payload) return;

      if (this.networkState.logGraphQLError("undoDeleteInternalResults", payload)) {
        // TODO: Display user friendly error message
        return;
      }

      if (this.buyRate && this.deletedPunchOutRow) {
        this.deletedPunchOutRow.id =
          payload.data.createOrUpdatePunchOut.punchOut.punchoutId;
        this.deletedPunchOutRow.legacyId =
          payload.data.createOrUpdatePunchOut.punchOut.punchoutId;
        this.buyRate.punchOutRows[this.deletedPunchOutRow.levelId] =
          this.deletedPunchOutRow;
      }
      this.deletedPunchOutRow = null;
    });
  }

  resetAndClose() {
    if (this.networkState.loading) return;

    this.reset();
    this.modal.hideModal();
  }

  showModal() {
    // this.search.store.setBuyRateState(this);
    this.modal.showModal();
  }

  hideModal() {
    this.modal.hideModal();
  }

  reset() {
    this.name = null;

    if (this.buyRate) {
      this.name = this.buyRate.name;
    }
  }
}
