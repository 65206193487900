import React, { useCallback } from "react";
// utils
import { usePayDifference } from "../../hooks/usePayDifference";
import Text from "../../../../components/lib/Text";
import Stack from "../../../../components/lib/Stack";
import Inline from "../../../../components/lib/Inline";

import { THead, TR, Table, TD, TBody } from "../../../../components/lib/Table";
import { useSearchResultsStore } from "../../../../stores/SearchResults";

const textCenter = { textAlign: "center" };

type Props = {
  payDifference: ReturnType<typeof usePayDifference>;
};

export function ComparisonTable(props: Props) {
  const { payDifference } = props;

  const toCurrency = useSearchResultsStore((s) => s.currency);
  const rateFrequency = useSearchResultsStore((s) => s.rateFrequency);
  const getRate = useSearchResultsStore((s) => s.calculateRate);

  const calculateRate = useCallback(
    (fromCurrency: string, rate: number) => {
      return getRate(fromCurrency, toCurrency, rateFrequency, true)(rate, true);
    },
    [getRate, toCurrency, rateFrequency]
  );

  return (
    <Stack css={{ width: "$full", alignItems: "flex-start" }}>
      <Inline css={{ gap: "$4", width: "$full", justifyContent: "space-between" }}>
        <Stack nogap css={{ alignItems: "flex-start" }}>
          <Text as="h4" noMargin>
            Comparison Table By Location
          </Text>
          <Text color="primary">
            The data presented on this table is across all experience levels.
          </Text>
        </Stack>
      </Inline>

      <Table highlighted={false}>
        <THead css={{ textTransform: "uppercase" }}>
          <TR>
            <TD>Location</TD>
            <TD css={textCenter}>Min</TD>
            <TD css={textCenter}>Avg</TD>
            <TD css={textCenter}>Mid</TD>
            <TD css={textCenter}>Max</TD>
          </TR>
        </THead>
        <TBody>
          {payDifference.map((data) => {
            if (!data.rates) return null;
            const currency = data.currency?.iso ?? "USD";
            return (
              <TR key={data.key}>
                <TD>
                  {data?.region?.name || data.locationLabel || data.locationFullLabel}
                </TD>
                <TD css={textCenter}>{calculateRate(currency, data.rates.rawMin)}</TD>
                <TD css={textCenter}>{calculateRate(currency, data.rates.rawAvg)}</TD>
                <TD css={textCenter}>{calculateRate(currency, data.rates.rawMid)}</TD>
                <TD css={textCenter}>{calculateRate(currency, data.rates.rawMax)}</TD>
              </TR>
            );
          })}
        </TBody>
      </Table>
    </Stack>
  );
}
