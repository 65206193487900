// @flow

import { extendObservable, action, runInAction } from "mobx";
import axios from "axios";
import NetworkState from "../../models/NetworkState";
import PaginationState from "../../models/PaginationState";
import ModalState from "../../models/ModalState";
import SavedSearch from "../../models/SavedSearch";
import Search from "../../models/Search";
import UserFeed from "../../models/UserFeed";
import FilterObject, { FILTER_COLUMN } from "../../models/Filter";
import type { PageQuery, PaginationInfo } from "../../models/PaginationState";
import Industry from "../../models/Industry";
import { RateCardListComponentStore } from "./RateCardListStore";
import { DashboardPunchoutsListComponentStore } from "./DashboardPunchoutsListStore";
import { USER_FEEDS_COUNTS, PAGINATION_CONSTANTS } from "../../constants/sharedConstants";
import type { FetchGraphQL } from "../../App";
import { now, toCalendarDateTime, startOfMonth } from "@internationalized/date";

type _searchMarketChange = {
  created: string,
  payRateMin: float,
  payRateMid: float,
  payRateMax: float,
  payRateAvg: float,
  billRateMin: float,
  billRateMid: float,
  billRateMax: float,
  billRateAvg: float,
  markupAmtMin: float,
  markupAmtMid: float,
  markupAmtMax: float,
  markupAmtAvg: float,
  markupPctMin: float,
  markupPctMid: float,
  markupPctMax: float,
  markupPctAvg: float,
  level: {
    value: string,
  },
  revNum: number,
};

export default class IndexStore {
  network: NetworkState;
  rateCardsListStore: RateCardListComponentStore;
  dashboardPunchoutsListStore: DashboardPunchoutsListComponentStore;
  pagination: PaginationState;
  savedSearch: SavedSearch[];
  searches: SavedSearch[];
  userFeeds: UserFeed[];
  feedIndustry: any[];
  rateCards: any[];
  featuredRateCard: any;
  featuredSearch: any;
  feed: any[];
  counter: any;
  indValue: { id: number, name: string }[];
  totalRecent: any;
  selectedSearchId: string;
  selectedPunchoutId: string;
  featuredPunchout: string;
  punchoutList: any[];
  changes: Array<_searchMarketChange>;

  feedIndustryModal: ModalState;
  featuredRateCardModal: ModalState;
  featuredBuyRateModal: ModalState;
  renderView: boolean;

  getRecentActivities: () => void;
  getRateCardSearches: (PageQuery) => Promise<PaginationInfo>;
  getUserFeed: () => void;
  changeCounter: () => void;
  displayFeedIndustry: () => void;
  displayRateCard: () => void;
  displayPunchouts: () => void;
  updateIndustryValue: (Object[]) => void;
  saveFeedIndustry: () => void;
  getFeaturedRateCard: () => void;
  getFeaturedSearch: () => void;
  setFeaturedRateCard: () => void;
  setFeaturedBuyRate: () => void;
  setFeaturedSearch: () => void;
  updateSearchId: () => void;
  updatePunchoutId: () => void;
  getFeaturedPunchout: () => void;
  getAllPunchouts: () => void;
  setFeaturedPunchout: () => void;
  getChanges: () => void;
  featuredBuyRate: string;
  featuredBuyRatename: string;
  fetchGraphQL: FetchGraphQL;

  constructor(fetchGraphQL: FetchGraphQL) {
    this.fetchGraphQL = fetchGraphQL;
    this.getRateCardSearches = action(this.getRateCardSearches.bind(this));
    this.getRecentActivities = action(this.getRecentActivities.bind(this));

    extendObservable(this, {
      network: new NetworkState(),
      headlineNetwork: new NetworkState(),
      industryNetwork: new NetworkState(),
      feedNetwork: new NetworkState(),
      featuredRateCardNetwork: new NetworkState(),
      featuredSearchNetwork: new NetworkState(),
      featuredBuyRateNetwork: new NetworkState(),
      setFeaturedRCNetwork: new NetworkState(),
      getSearchesNetwork: new NetworkState({ loading: false }),
      featuredPunchoutNetwork: new NetworkState({ loading: true }),
      setFeaturedSearchNetwork: new NetworkState(),
      setFeaturedPunchoutNetwork: new NetworkState(),
      getPunchoutsNetwork: new NetworkState(),
      changesNetwork: new NetworkState(),
      rateCardsListStore: new RateCardListComponentStore(fetchGraphQL),
      dashboardPunchoutsListStore: new DashboardPunchoutsListComponentStore(fetchGraphQL),
      savedSearch: [],
      searches: [],
      userFeeds: [],
      totalRecent: 0,
      recArray: [],
      renderView: false,
      featuredRateCard: "",
      featuredSearch: "",
      featuredPunchout: "",
      selectedSearchId: "",
      selectedPunchoutId: "",
      feed: [],
      changes: [],
      punchoutList: [],
      feedIndustry: [
        {
          id: 1,
          name: "All",
        },
      ],
      indValue: [
        {
          id: 1,
          name: "All",
        },
      ],
      rateCards: [],
      pagination: new PaginationState(this.getRateCardSearches),
      feedIndustryModal: new ModalState(),
      featuredRateCardModal: new ModalState(),
      featuredBuyRateModal: new ModalState(),
      counter: 0,
      featuredBuyRate: "",
    });
    this.getUserFeed = action(this.getUserFeed.bind(this));
    this.getIndustries = action(this.getIndustries.bind(this));
    this.getFeed = action(this.getFeed.bind(this));
    this.displayFeedIndustry = action(this.displayFeedIndustry.bind(this));
    this.displayRateCard = action(this.displayRateCard.bind(this));
    this.displayPunchouts = action(this.displayPunchouts.bind(this));
    this.changeCounter = action(this.changeCounter.bind(this));
    this.updateIndustryValue = action(this.updateIndustryValue.bind(this));
    this.saveFeedIndustry = action(this.saveFeedIndustry.bind(this));
    this.getFeaturedRateCard = action(this.getFeaturedRateCard.bind(this));
    this.getFeaturedSearch = action(this.getFeaturedSearch.bind(this));
    this.setFeaturedRateCard = action(this.setFeaturedRateCard.bind(this));
    this.setFeaturedBuyRate = action(this.setFeaturedBuyRate.bind(this));
    this.setFeaturedSearch = action(this.setFeaturedSearch.bind(this));
    this.updateSearchId = action(this.updateSearchId.bind(this));
    this.updatePunchoutId = action(this.updatePunchoutId.bind(this));
    this.getFeaturedPunchout = action(this.getFeaturedPunchout.bind(this));
    this.getAllPunchouts = action(this.getAllPunchouts.bind(this));
    this.setFeaturedPunchout = action(this.setFeaturedPunchout.bind(this));
    this.getChanges = action(this.getChanges.bind(this));
  }

  displayFeedIndustry() {
    this.getIndustries();
    this.feedIndustryModal.showModal();
  }

  displayRateCard() {
    this.rateCardsListStore.allowMultipleItemSelection = false;
    this.rateCardsListStore.isEditing = true;
    if (this.featuredRateCard) {
      const exceptFilter: FilterObject = new FilterObject(
        "$exclude: [ID!]",
        "exclude: $exclude",
        {
          exclude: [String(this.featuredRateCard.ratecardId)],
        }
      );
      this.rateCardsListStore.applyDefaultFilter(FILTER_COLUMN.EXCLUDE, exceptFilter);
      this.rateCardsListStore.applyDefaultSort();
    }
    this.rateCardsListStore.pagination.goFetch();
    this.rateCardsListStore.clearFilters();
    this.featuredRateCardModal.showModal();
  }

  displayPunchouts() {
    this.dashboardPunchoutsListStore.allowMultipleItemSelection = false;
    this.dashboardPunchoutsListStore.isEditing = true;
    this.dashboardPunchoutsListStore.buyrateId = this.featuredBuyRateId;
    this.dashboardPunchoutsListStore.pagination.goFetch();
    this.dashboardPunchoutsListStore.clearFilters();
    this.featuredBuyRateModal.showModal();
  }

  changeCounter() {
    if (this.counter >= this.userFeeds.length - 1) {
      this.counter = 0;
    } else {
      this.counter = this.counter + 1;
    }
  }

  updateIndustryValue(value: Object[]) {
    this.indValue = value;
  }

  updateSearchId(value) {
    this.selectedSearchId = value;
    this.setFeaturedSearch();
  }

  updatePunchoutId(value) {
    this.selectedPunchoutId = value;
    this.setFeaturedPunchout();
  }

  async setFeaturedPunchout() {
    const variables = {
      punchoutId: this.selectedPunchoutId,
    };
    const query = `
      mutation setFeaturedPunchout($punchoutId: ID!){
        setFeaturedPunchout(input: {punchoutId: $punchoutId}){
          punchout{
            created
            modified
            buyRate {
              name
              notes
              buyrateId
            }
            payRateMin
            payRateMax
            markupPctMin
            markupPctMax
            billRateMin
            billRateMax
            markupAmtMin
            markupAmtMax
            punchoutId
            jobTitle
            level {
              legacyId
              value
            }
          }
        }
      }
    `;

    this.setFeaturedPunchoutNetwork.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.setFeaturedPunchoutNetwork.handleError("Setting Featured Punchout", e);
      if (res !== null) {
        this.setFeaturedPunchoutNetwork.logGraphQLError("Set Featured Punchout", res);
      }

      return e;
    }

    return runInAction("setFeaturedPunchout--success", () => {
      this.setFeaturedPunchoutNetwork.loading = false;
      this.setFeaturedPunchoutNetwork.error = null;
      if (
        this.setFeaturedPunchoutNetwork.logGraphQLError(
          "Set Featured Punchout Error",
          res
        )
      ) {
        // TODO: Display user friendly error message
      }
      // this.getFeaturedPunchout();
    });
  }

  async getChanges() {
    let twelveMonthsAgo = toCalendarDateTime(
      startOfMonth(
        now("UTC").subtract({
          months: 12,
        })
      )
    );
    const variables = {
      searchId: this.featuredSearch.searchId,
      fromDate: twelveMonthsAgo.toString(),
    };
    const query = `
      query change($searchId: Int!, $fromDate: DateTime!) {
       viewer {
         marketChanges(searchId: $searchId, createdDate: $fromDate) {
           created
           payRateMin
           payRateMid
           payRateMax
           payRateAvg
           billRateMin
           billRateMid
           billRateMax
           billRateAvg
           markupAmtMin
           markupAmtMid
           markupAmtMax
           markupAmtAvg
           markupPctMin
           markupPctMid
           markupPctMax
           markupPctAvg
           level{
             value
           }
           search {
             currency {
               symbol
             }
           }
           revNum
         }
       }
      }
    `;

    this.changesNetwork.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.changesNetwork.handleError("Getting Changes for Search", e);
      if (res !== null) {
        this.changesNetwork.logGraphQLError("Get Changes for Search", res);
      }

      return e;
    }

    return runInAction("getChanges--success", () => {
      this.changesNetwork.loading = false;
      this.changesNetwork.error = null;
      if (this.changesNetwork.logGraphQLError("Get Changes Error", res)) {
        // TODO: Display user friendly error message
      }
      this.changes = res.data.viewer.marketChanges;
    });
  }

  async getRecentActivities() {
    const variables = {
      offset: PAGINATION_CONSTANTS.current_page,
      first: PAGINATION_CONSTANTS.per_page,
    };
    const query = `
      query recentSearches($offset: Int!){
       viewer {
         savedsearches(offset: $offset, order: [{field:REVERSE_CREATED_DATE}]) {
           edges{
             node{
                 searchId
                 job {
                   jobLabel
                   jobTitle
                 }
                 locationFullLabel
                 createdDate
               }
             }
         }
       }
      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting Recent Activities", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Recent Activities", res);
      }

      return e;
    }

    return runInAction("getRecentActivities--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get Recent Activities query", res)) {
        // TODO: Display user friendly error message
        return {
          totalCount: 0,
        };
      }
      const savedsearches = res.data.viewer.savedsearches.edges;

      this.savedSearch = savedsearches.map((ss) => {
        const recSave = new SavedSearch(this, ss.node);
        return recSave;
      });

      return {
        totalCount: 0,
      };
    });
  }

  async setFeaturedRateCard() {
    const selectedRateCards = this.rateCardsListStore.getSelectedRateCards();
    if (!selectedRateCards || !selectedRateCards.length) return;

    const variables = {
      rateCardId: selectedRateCards[0],
    };
    const query = `
      mutation setfeaturedRateCard($rateCardId: ID!){
        setFeaturedRateCard(input: {rateCardId: $rateCardId}){
          rateCard{
           ratecardId
           name
          }
        }
       }
    `;

    this.setFeaturedRCNetwork.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.setFeaturedRCNetwork.handleError("Setting Featured Rate Card", e);
      if (res !== null) {
        this.setFeaturedRCNetwork.logGraphQLError("Set Featured Rate Card", res);
      }

      return e;
    }

    return runInAction("setFeaturedRateCard--success", () => {
      this.setFeaturedRCNetwork.loading = false;
      this.setFeaturedRCNetwork.error = null;

      if (
        this.setFeaturedRCNetwork.logGraphQLError("Set Featured Rate Card Error", res)
      ) {
        // TODO: Display user friendly error message
      }
      this.featuredRateCard = res.data.setFeaturedRateCard.rateCard;
      this.getFeaturedSearch();
      this.pagination.goFetch();
      this.featuredRateCardModal.hideModal();
    });
  }

  async setFeaturedBuyRate() {
    const selectedBuyrates = this.dashboardPunchoutsListStore.getSelectedPunchouts();
    if (!selectedBuyrates || !selectedBuyrates.length) return;

    const variables = {
      buyrateId: selectedBuyrates[0],
    };
    const query = `
      mutation setFeaturedBuyRate($buyrateId: ID!){
        setFeaturedBuyrate(input: {buyrateId: $buyrateId}){
          buyrate{
           buyrateId
           name
          }
        }
       }
    `;

    this.setFeaturedRCNetwork.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.setFeaturedRCNetwork.handleError("Setting Featured Rate Card", e);
      if (res !== null) {
        this.setFeaturedRCNetwork.logGraphQLError("Set Featured Rate Card", res);
      }

      return e;
    }

    return runInAction("setFeaturedBuyRate--success", () => {
      this.setFeaturedRCNetwork.loading = false;
      this.setFeaturedRCNetwork.error = null;

      if (
        this.setFeaturedRCNetwork.logGraphQLError("Set Featured Rate Card Error", res)
      ) {
        // TODO: Display user friendly error message
      }
      //this.featuredBuyRate = res.data.setFeaturedBuyrate.buyrate;
      this.getFeaturedBuyRate();
      //this.pagination.goFetch();
      this.featuredBuyRateModal.hideModal();
    });
  }

  async setFeaturedSearch() {
    const variables = {
      searchId: this.selectedSearchId,
    };
    const query = `
      mutation setfeaturedSearch($searchId: ID!){
        setFeaturedSearch(input: {searchId: $searchId}){
          search{
            searchId,
            marketRate{
              payRate,
              billRate,
              markupPct
            }
          }
        }
      }
    `;

    this.setFeaturedSearchNetwork.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.setFeaturedSearchNetwork.handleError("Setting Featured Search", e);
      if (res !== null) {
        this.setFeaturedSearchNetwork.logGraphQLError("Set Featured Search", res);
      }

      return e;
    }

    return runInAction("setFeaturedSearch--success", () => {
      this.setFeaturedSearchNetwork.loading = false;
      this.setFeaturedSearchNetwork.error = null;
      if (
        this.setFeaturedSearchNetwork.logGraphQLError("Set Featured Search Error", res)
      ) {
        // TODO: Display user friendly error message
      }
      this.getFeaturedSearch();
      this.getChanges();
    });
  }

  async saveFeedIndustry() {
    if (!this.indValue.length) {
      return;
    }
    const variables = {
      industryId: this.indValue[0].id,
    };
    const query = `
      mutation setUserIndustry($industryId : Int!) {
        setUserFeedIndustry(input: {industryId: $industryId}) {
          userFeedIndustry{
           industry{
             value,
             id
           }
         }
        }
      }
    `;

    this.feedNetwork.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.feedNetwork.handleError("Settings User Feed", e);
      if (res !== null) {
        this.feedNetwork.logGraphQLError("Set User Feed", res);
      }

      return e;
    }

    return runInAction("saveFeedIndustry--success", () => {
      this.feedNetwork.loading = false;
      this.feedNetwork.error = null;
      if (this.feedNetwork.logGraphQLError("Get User Feed Error", res)) {
        // TODO: Display user friendly error message
      }

      this.feed.splice(0, this.feed.length);

      this.feed.push(
        new Industry(this, res.data.setUserFeedIndustry.userFeedIndustry.industry)
      );
      localStorage.removeItem("userFeeds");
      this.getUserFeed();
      this.feedIndustryModal.hideModal();
      this.counter = 0;
    });
  }

  async getFeaturedRateCard() {
    const query = `
      query getFeaturedRateCards{
        viewer{
          featuredRateCard{
            ratecardId
            name
          }
        }
      }
    `;

    this.featuredRateCardNetwork.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, null);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.featuredRateCardNetwork.handleError("Getting Featured Rate Card", e);
      if (res !== null) {
        this.featuredRateCardNetwork.logGraphQLError("Get Featured Rate Card", res);
      }

      return e;
    }

    return runInAction("getFeaturedRateCard--success", () => {
      this.featuredRateCardNetwork.loading = false;
      this.featuredRateCardNetwork.error = null;
      if (
        this.featuredRateCardNetwork.logGraphQLError("Get Featured Rate Card Error", res)
      ) {
        // TODO: Display user friendly error message
      }
      if (res.errors && res.errors.length > 0) {
        return;
      }
      this.featuredRateCard = res.data.viewer.featuredRateCard;
      // NOTE: Only fetch featured search, if featured ratecard exists.
      if (res.data.viewer.featuredRateCard) {
        this.getFeaturedSearch();
        this.pagination.goFetch();
      }
    });
  }

  async getFeaturedBuyRate() {
    const query = `query getFeaturedBuyRate {
  viewer {
    featuredBuyRate{
      name
      buyrateId
      search {
        currency {
              symbol
            }
            rateType
            searchId,
            country,
            state
            city
            createdDate,
              job{
                jobLabel,
                jobTitle,
                jobDescription,
             }
             industry{
               legacyId
               value
             }
             buyrates{
                buyrateId,
                created,
                active,
                createdBy{
                  userId,
                  username
                }
                name
                punchouts{
                  level{
                    legacyId
                    value
                  },
                  payRateMin,
                  payRateMax,
                  billRateMin,
                  billRateMax,
                  markupPct,
                  markupAmtMin,
                  markupAmtMax
                }
            }
            marketchanges{
                created,
                modified,
                payRateMin,
                payRateMax,
                payRateMid,
                payRateAvg
                billRateMin,
                billRateMax,
                billRateMid,
                billRateAvg,
                markupPctMin,
                markupPctMax,
                markupPctMid,
                markupPctAvg,
                markupAmtMin,
                markupAmtMax,
                markupAmtMid,
                markupAmtAvg
                level{
                  legacyId
                  value
                }
              }

      }
    }
  }
}`;

    this.featuredBuyRateNetwork.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, null);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.featuredBuyRateNetwork.handleError("Getting Featured Rate Card", e);
      if (res !== null) {
        this.featuredBuyRateNetwork.logGraphQLError("Get Featured Rate Card", res);
      }

      return e;
    }

    return runInAction("getFeaturedBuyRate--success", () => {
      this.featuredBuyRateNetwork.loading = false;
      this.featuredBuyRateNetwork.error = null;
      if (
        this.featuredBuyRateNetwork.logGraphQLError("Get Featured Rate Card Error", res)
      ) {
        // TODO: Display user friendly error message
      }
      if (res.errors && res.errors.length > 0) {
        return;
      }

      if (!res.data.viewer.featuredBuyRate) return;

      res.data.viewer.featuredBuyRate.search.buyrateId =
        res.data.viewer.featuredBuyRate.buyrateId;
      this.featuredBuyRate = new Search(this, res.data.viewer.featuredBuyRate.search);
      this.featuredBuyRate.getSearchResults();
      //this.pagination.goFetch();
      this.featuredBuyRatename = res.data.viewer.featuredBuyRate.name;
      this.featuredBuyRateId = res.data.viewer.featuredBuyRate.buyrateId;
      //this.getChanges();
    });
  }

  async getFeaturedSearch() {
    const query = `
      query getFeaturedSearch{
        viewer{
          featuredSearch{
            currency {
              symbol
            }
            rateType
            searchId,
            locationFullLabel
            country,
            state
            city
            createdDate,
              job{
                jobLabel,
                jobTitle,
                jobDescription,
             }
             industry{
               legacyId
               value
             }
             buyrates{
                buyrateId,
                created,
                active,
                createdBy{
                  userId,
                  username
                }
                name
                punchouts{
                  level{
                    legacyId
                    value
                  },
                  payRateMin,
                  payRateMax,
                  billRateMin,
                  billRateMax,
                  markupPct,
                  markupAmtMin,
                  markupAmtMax
                }
            }
            marketchanges{
                created,
                modified,
                payRateMin,
                payRateMax,
                payRateMid,
                payRateAvg
                billRateMin,
                billRateMax,
                billRateMid,
                billRateAvg,
                markupPctMin,
                markupPctMax,
                markupPctMid,
                markupPctAvg,
                markupAmtMin,
                markupAmtMax,
                markupAmtMid,
                markupAmtAvg
                level{
                  legacyId
                  value
                }
              }
          }
        }
      }
    `;

    this.featuredSearchNetwork.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, null);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.featuredSearchNetwork.handleError("Getting Featured Search", e);
      if (res !== null) {
        this.featuredSearchNetwork.logGraphQLError("Get Featured Search", res);
      }

      return e;
    }

    return runInAction("getFeaturedSearch--success", () => {
      this.featuredSearchNetwork.loading = false;
      this.featuredSearchNetwork.error = null;
      if (this.featuredSearchNetwork.logGraphQLError("Get Featured Search Error", res)) {
        // TODO: Display user friendly error message
      }

      if (res.data.viewer && res.data.viewer.featuredSearch !== null) {
        // var punchOuts = res.data.viewer.featuredSearch.buyrates[0].punchouts;
        // var recArray = [];
        // for (var i = 0; i < punchOuts.length; i++) {
        //   var records = {};
        //   records.level = punchOuts[i].level.value;
        //   records.rec = punchOuts[i].numResults;
        //   this.recArray.push(records);
        // }

        this.featuredSearch = new Search(this, res.data.viewer.featuredSearch);
        this.featuredSearch.getSearchResults();
        // this.getFeaturedPunchout();
        this.getChanges();
        // this.getAllPunchouts();
      } else {
        this.featuredSearch = null;
      }
    });
  }

  async getFeaturedPunchout() {
    const query = `
      query getFeaturedPunchout{
       viewer{
         featuredPunchout {
           created
           modified
           buyRate {
             name
             notes
             buyrateId
           }
           payRateMin
           payRateMax
           markupPctMin
           markupPctMax
           billRateMin
           billRateMax
           markupAmtMin
           markupAmtMax
           punchoutId
           jobTitle
           level {
             legacyId
             value
           }
         }
       }
      }
    `;

    this.featuredPunchoutNetwork.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, null);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.featuredPunchoutNetwork.handleError("Getting Featured Punchout", e);
      if (res !== null) {
        this.featuredPunchoutNetwork.logGraphQLError("Get Featured Punchout", res);
      }

      return e;
    }

    return runInAction("getFeaturedPunchout--success", () => {
      this.featuredPunchoutNetwork.loading = false;
      this.featuredPunchoutNetwork.error = null;
      if (
        this.featuredPunchoutNetwork.logGraphQLError("Get Featured Punchout Error", res)
      ) {
        // TODO: Display user friendly error message
      }

      this.featuredPunchout = res.data.viewer.featuredPunchout;
    });
  }

  async getRateCardSearches(pageQuery: PageQuery): Promise<PaginationInfo> {
    let params: string[] = pageQuery.params;
    let args = pageQuery.args;
    let variables = pageQuery.variables;

    if (!this.featuredRateCard) return { totalCount: 0 };

    params.push("$id: Int!");
    args.push("id: $id");
    args.push("order: [{field: JOB_TITLE}, {field: LOCATION_FULL_LABEL}]");
    variables.id = this.featuredRateCard.ratecardId;

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
    query getRateCardSearches(${queryParams}){
      viewer{
        rateCardSearches(${queryArgs}){
          totalCount
          edges{
            node{
              currency {
                symbol
              }
              rateType,
              searchId,
              numResults,
              locationFullLabel
              country,
              state
              city
              createdDate,
                job{
                  jobLabel,
                  jobTitle,
                  jobDescription,
               }
             industry{
               legacyId
               value
             }
             buyrates{
                buyrateId,
                created,
                active,
                createdBy{
                  userId,
                  username
                }
                name
                punchouts{
                  level{
                    legacyId
                    value
                  },
                  payRateMin,
                  payRateMax,
                  billRateMin,
                  billRateMax,
                  markupPct,
                  markupAmtMin,
                  markupAmtMax
                }
              }
              marketchanges{
                  created,
                  modified,
                  payRateMin,
                  payRateMax,
                  payRateMid,
                  payRateAvg
                  billRateMin,
                  billRateMax,
                  billRateMid,
                  billRateAvg,
                  markupPctMin,
                  markupPctMax,
                  markupPctMid,
                  markupPctAvg,
                  markupAmtMin,
                  markupAmtMax,
                  markupAmtMid,
                  markupAmtAvg
                  level{
                    legacyId
                    value
                  }
                }
              }
            }
          }
        }
      }
    `;

    this.getSearchesNetwork.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.getSearchesNetwork.handleError(
        "Getting Rate Card Searches For Featured Rate Card",
        e
      );
      if (res !== null) {
        this.getSearchesNetwork.logGraphQLError(
          "Get Rate Card Searches For Featured Rate Card",
          res
        );
      }

      return e;
    }

    return runInAction("getRateCardSearches--success", () => {
      this.getSearchesNetwork.loading = false;
      this.getSearchesNetwork.error = null;
      if (this.getSearchesNetwork.logGraphQLError("Get Rate Card Searches query", res)) {
        // TODO: Display user friendly error message
        return {
          totalCount: 0,
        };
      }

      const rcsearches = res.data.viewer.rateCardSearches.edges;

      this.searches = rcsearches.map((search) => {
        const rcSearch = new Search(this, search.node);
        rcSearch.numResults = search.node.numResults;
        rcSearch.getSearchResults();
        return rcSearch;
      });

      return {
        totalCount: res.data.viewer.rateCardSearches.totalCount,
      };
    });
  }

  async getAllPunchouts() {
    const variables = {
      ratesearches: [this.featuredSearch.searchId],
    };
    const query = `
      query punchoutsList($ratesearches:[ID]!){
        viewer{
         allPunchouts(filters:{ratesearches:$ratesearches}){
           edges {
             node {
               created
               modified
               buyRate {
                 name
                 notes
                 buyrateId
               }
               payRateMin
               payRateMax
               markupPctMin
               markupPctMax
               billRateMin
               billRateMax
               markupAmtMin
               markupAmtMax
               punchoutId
               jobTitle
               level {
                 legacyId
                 value
               }
             }
           }
         }
        }
      }
    `;

    this.getPunchoutsNetwork.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.getPunchoutsNetwork.handleError("Getting punchouts", e);
      if (res !== null) {
        this.getPunchoutsNetwork.logGraphQLError("Get punchout", res);
      }

      return e;
    }

    return runInAction("getAllPunchouts--success", () => {
      this.getPunchoutsNetwork.loading = false;
      this.getPunchoutsNetwork.error = null;
      if (this.getPunchoutsNetwork.logGraphQLError("Get punchout Error", res)) {
        // TODO: Display user friendly error message
        return {
          totalCount: 0,
        };
      }

      this.punchoutList = res.data.viewer.allPunchouts.edges;

      return {
        totalCount: res.data.viewer.allPunchouts.edges.length,
      };
    });
  }

  async getFeed() {
    const query = `
    query userindustries {
      viewer {
        userFeedIndustry {
          legacyId
          value
        }
      }
    }
    `;

    this.feedNetwork.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, null);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.feedNetwork.handleError("Getting User Feed", e);
      if (res !== null) {
        this.feedNetwork.logGraphQLError("Get User Feed", res);
      }

      return e;
    }

    return runInAction("getFeed--success", () => {
      this.feedNetwork.loading = false;
      this.feedNetwork.error = null;
      if (this.feedNetwork.logGraphQLError("Get User Feed Error", res)) {
        // TODO: Display user friendly error message
        return {
          totalCount: 0,
        };
      }

      this.feed.splice(0, this.feed.length);

      this.feed.push(new Industry(this, res.data.viewer.userFeedIndustry));
      this.indValue.splice(0, this.feed.length);
      this.indValue.push(new Industry(this, res.data.viewer.userFeedIndustry));

      return {
        totalCount: res.data.viewer.userFeedIndustry.length,
      };
    });
  }

  async getUserFeed() {
    const variables = {
      limit: USER_FEEDS_COUNTS,
      feedType: "News",
    };

    const query = `
      query userFeeds($limit: Int!, $feedType:String!){
       viewer {
         userFeeds(limit: $limit, feedType: $feedType){
          title
          link,
          pubdate,
          descriptionText
        }
       }
      }
    `;

    this.headlineNetwork.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.headlineNetwork.handleError("Getting User Feeds", e);
      if (res !== null) {
        this.headlineNetwork.logGraphQLError("Get User Feeds", res);
      }

      return e;
    }

    return runInAction("getUserFeed--success", () => {
      // console.log(this.headlineNetwork);
      this.headlineNetwork.loading = false;
      this.headlineNetwork.error = null;
      if (this.headlineNetwork.logGraphQLError("Get User Feeds Error", res)) {
        // TODO: Display user friendly error message
        return {
          totalCount: 0,
        };
      }
      // console.log("Retrieved Feed content: ", res.data.viewer.userFeeds);
      this.userFeeds = res.data.viewer.userFeeds.map((userFeed) => {
        return new UserFeed(this, userFeed);
      });
      // console.log("Stored Feed content: ", this.userFeeds);
      this.renderView = !this.renderView;

      return {
        totalCount: res.data.viewer.userFeeds.length,
      };
    });
  }

  async getIndustries() {
    const query = `
      query industries{
       viewer{
         industries{
           edges{
             node{
               legacyId
               value
             }
           }
         }
       }
      }
    `;

    this.industryNetwork.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, null);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.industryNetwork.handleError("Getting Feed Industries", e);
      if (res !== null) {
        this.industryNetwork.logGraphQLError("Get Feed Industries", res);
      }

      return e;
    }

    return runInAction("getIndustries--success", () => {
      this.industryNetwork.loading = false;
      this.industryNetwork.error = null;
      if (this.industryNetwork.logGraphQLError("Get Feed Industries Error", res)) {
        // TODO: Display user friendly error message
        return {
          totalCount: 0,
        };
      }
      const industriesData = res.data.viewer.industries.edges;
      this.feedIndustry = industriesData.map((industry) => {
        const list = new Industry(this, industry.node);
        return list;
      });

      return {
        totalCount: res.data.viewer.industries.edges.length,
      };
    });
  }
}
