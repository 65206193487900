// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import {
  FILE_TEMPLATE,
  RATE_TYPE_FREQUENCY,
} from "../../../models/CustomBuyRatesExportOptions";
import CustomBuyRatesExportModalState, {
  MARKUP_SOURCE,
} from "../../../models/CustomBuyRatesExportModalState";
import Modal from "./Modal";
import ContainerSection from "./ContainerSection";
import CheckBox from "../../shared/CheckBox";
import moment from "moment";
import { CURRENCY_TYPE } from "../../../models/ExportOptions";
import InfiniteScrollSelect from "../../../components/selects/InfiniteScrollSelect";
import { ModalButtons, ModalTitle } from "./FilterModal";
import { styled } from "../../../stitches.config";
import Inline from "../../../components/lib/Inline";
import Button from "../../../components/lib/Button";
import Tooltip from "../../../components/lib/Tooltip";
import TextInput from "../../../components/lib/TextInput";
import Box from "../../../components/lib/Box";

const CheckBoxGroup = styled(Inline, {
  alignItems: "flex-start",
  "& > label": { flex: 0.5 },
});

class CustomBuyRatesExportModal extends Component {
  componentDidMount() {
    // default file name
    const now = moment();
    this.props.exportState.exportOptions.fileName = [
      "export",
      now.format("YYYYMMDD"),
      now.format("HHmmss"),
    ].join("-");
    this.props.exportState.getMarkupsFromLibrary();
  }

  render() {
    const exportState: CustomBuyRatesExportModalState = this.props.exportState;
    const levelsToolTip = (
      <>
        Level I (1-5 years) -> Levels I & II
        <br />
        Level II (5-10 years) -> Levels III & IV
        <br />
        Level III (10+ years) -> Levels IV & V
        <br />
      </>
    );

    return (
      <Modal
        show={exportState.modalState.show}
        onHide={exportState.modalState.hideModal}
        innerStyle={{
          width: "600",
        }}
      >
        <ContainerSection className="header">
          <ModalTitle>Export</ModalTitle>
        </ContainerSection>
        <div className="rc-viewport overlap-lr-padding">
          <ContainerSection className="overlap-t-padding">
            <label>
              File Name: <em className="text-x-small text-muted">(Optional)</em>
            </label>
            <TextInput
              fill
              value={exportState.exportOptions.fileName}
              onChange={exportState.onChangeFileName}
            />
          </ContainerSection>

          <ContainerSection>
            <p>Markup:</p>
            <CheckBoxGroup>
              <CheckBox
                type="radio"
                name="markup"
                value={MARKUP_SOURCE.LIBRARY}
                selected={exportState.markupSource === MARKUP_SOURCE.LIBRARY}
                onChange={exportState.onChangeMarkupSource}
              >
                <div>From Worksheet Library</div>
              </CheckBox>
              <CheckBox
                type="radio"
                name="markup"
                value={MARKUP_SOURCE.CUSTOM}
                selected={exportState.markupSource === MARKUP_SOURCE.CUSTOM}
                onChange={exportState.onChangeMarkupSource}
              >
                <div>Custom Markup</div>
              </CheckBox>
              <CheckBox
                type="radio"
                name="markup"
                value={MARKUP_SOURCE.MARKET}
                selected={exportState.markupSource === MARKUP_SOURCE.MARKET}
                onChange={exportState.onChangeMarkupSource}
              >
                <div>From Rate Card</div>
              </CheckBox>
            </CheckBoxGroup>
            {exportState.markupSource === MARKUP_SOURCE.LIBRARY && (
              <Box css={{ paddingTop: "$4" }}>
                <InfiniteScrollSelect
                  name="markup-select"
                  placeholder="Select a Markup from your library..."
                  items={exportState.markupsFromLibrary}
                  selectedItem={exportState.selectedMarkupOption}
                  inputValue={exportState.markupSearchValue}
                  showSearchInput={true}
                  onInputValueChange={exportState.onMarkupSearchValueChange}
                  onSelectedItemChange={exportState.onChangeMarkupFromLibrary}
                  loading={exportState.networkState.loading}
                />
              </Box>
            )}
            {exportState.markupSource === MARKUP_SOURCE.CUSTOM && (
              <Box css={{ paddingTop: "$4" }}>
                <label>Enter a custom markup (%):</label>
                <TextInput
                  fill
                  color={
                    Boolean(exportState.customMarkupInputValueError)
                      ? "danger"
                      : "primary"
                  }
                  value={exportState.customMarkupInputValue}
                  onChange={exportState.onChangeCustomMarkup}
                />
              </Box>
            )}
          </ContainerSection>

          <ContainerSection>
            <p>Rate Type:</p>
            <CheckBoxGroup>
              <CheckBox
                type="radio"
                name="rate-type-frequency"
                value={RATE_TYPE_FREQUENCY.HOURLY}
                selected={
                  exportState.exportOptions.rateFrequencyType ===
                  RATE_TYPE_FREQUENCY.HOURLY
                }
                onChange={exportState.onChangeRateFrequencyType}
              >
                <div>Hourly</div>
              </CheckBox>
              <CheckBox
                type="radio"
                name="rate-type-frequency"
                value={RATE_TYPE_FREQUENCY.DAILY}
                selected={
                  exportState.exportOptions.rateFrequencyType ===
                  RATE_TYPE_FREQUENCY.DAILY
                }
                onChange={exportState.onChangeRateFrequencyType}
              >
                <div>Daily</div>
              </CheckBox>
              <CheckBox
                type="radio"
                name="rate-type-frequency"
                value={RATE_TYPE_FREQUENCY.WEEKLY}
                selected={
                  exportState.exportOptions.rateFrequencyType ===
                  RATE_TYPE_FREQUENCY.WEEKLY
                }
                onChange={exportState.onChangeRateFrequencyType}
              >
                <div>Weekly</div>
              </CheckBox>
              <CheckBox
                type="radio"
                name="rate-type-frequency"
                value={RATE_TYPE_FREQUENCY.MONTHLY}
                selected={
                  exportState.exportOptions.rateFrequencyType ===
                  RATE_TYPE_FREQUENCY.MONTHLY
                }
                onChange={exportState.onChangeRateFrequencyType}
              >
                <div>Monthly</div>
              </CheckBox>
            </CheckBoxGroup>
            <Box css={{ paddingTop: "$4" }}>
              <label>Multiplier:</label>
              <TextInput
                fill
                color={
                  Boolean(exportState.rateMultiplierInputValueError)
                    ? "danger"
                    : "primary"
                }
                disabled={
                  exportState.exportOptions.rateFrequencyType ===
                  RATE_TYPE_FREQUENCY.HOURLY
                }
                value={exportState.rateMultiplierInputValue}
                onChange={exportState.onChangeRateMultiplier}
              />
            </Box>
          </ContainerSection>

          <ContainerSection
            className={classNames({
              "overlap-b-padding": !exportState.showCurrencyTypeOptions,
              "no-border": !exportState.showCurrencyTypeOptions,
            })}
          >
            <p>Columns:</p>
            <CheckBoxGroup>
              <CheckBox
                type="radio"
                name="file-format"
                value={FILE_TEMPLATE.ALL_FIELDS}
                selected={
                  exportState.exportOptions.fileTemplate === FILE_TEMPLATE.ALL_FIELDS
                }
                onChange={exportState.onChangeFileTemplate}
              >
                <div>All Columns</div>
              </CheckBox>
              <CheckBox
                type="radio"
                name="file-format"
                value={FILE_TEMPLATE.BILL_RATE_RANGE}
                selected={
                  exportState.exportOptions.fileTemplate === FILE_TEMPLATE.BILL_RATE_RANGE
                }
                onChange={exportState.onChangeFileTemplate}
              >
                <div>Bill Rate Range</div>
              </CheckBox>
              <CheckBox
                type="radio"
                name="file-format"
                value={FILE_TEMPLATE.NTE_BILL_RATE}
                selected={
                  exportState.exportOptions.fileTemplate === FILE_TEMPLATE.NTE_BILL_RATE
                }
                onChange={exportState.onChangeFileTemplate}
              >
                <div>NTE Bill Rate</div>
              </CheckBox>
              {this.props.threeLevelsViewUser &&
                this.props.searchesHaveAllLevels &&
                exportState.markupSource !== MARKUP_SOURCE.MARKET && (
                  <CheckBox
                    type="radio"
                    name="file-format"
                    value={FILE_TEMPLATE.THREE_LEVELS}
                    selected={
                      exportState.exportOptions.fileTemplate ===
                      FILE_TEMPLATE.THREE_LEVELS
                    }
                    onChange={exportState.onChangeFileTemplate}
                  >
                    <Tooltip side="top" content={levelsToolTip}>
                      <div>3 Levels</div>
                    </Tooltip>
                  </CheckBox>
                )}
            </CheckBoxGroup>
          </ContainerSection>

          {exportState.showCurrencyTypeOptions && (
            <ContainerSection className="overlap-b-padding no-border">
              <p>Currency Type:</p>
              <CheckBoxGroup>
                <CheckBox
                  type="radio"
                  name="currency-type"
                  value={CURRENCY_TYPE.DEFAULT}
                  selected={
                    exportState.exportOptions.currencyType === CURRENCY_TYPE.DEFAULT
                  }
                  onChange={exportState.onChangeCurrencyType}
                >
                  <div>Default</div>
                </CheckBox>
                <CheckBox
                  type="radio"
                  name="currency-type"
                  value={CURRENCY_TYPE.USD}
                  selected={exportState.exportOptions.currencyType === CURRENCY_TYPE.USD}
                  onChange={exportState.onChangeCurrencyType}
                >
                  <div>USD</div>
                </CheckBox>
              </CheckBoxGroup>
            </ContainerSection>
          )}
        </div>
        <ContainerSection className="footer border-t">
          <ModalButtons>
            <Button size="large" color="brand" onClick={exportState.onExport}>
              Export
            </Button>
            <Button size="large" onClick={exportState.onCancel}>
              Cancel
            </Button>
          </ModalButtons>
        </ContainerSection>
      </Modal>
    );
  }
}

CustomBuyRatesExportModal.propTypes = {
  exportState: PropTypes.object.isRequired,
  searchesHaveAllLevels: PropTypes.bool.isRequired,
  threeLevelsViewUser: PropTypes.bool.isRequired,
};

export default observer(CustomBuyRatesExportModal);
