// @flow
import * as React from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const List = (props) => {
  const store = props.store.reportStore;

  return (
    <div className="view list">
      <div className="rc-container bring-forward">
        <div className="header-action-bar">
          <div className="row">
            <div className="col-lg-3 col-sm-3 col-md-3">Search History Report</div>
            <div className="col-lg-3 col-sm-3 col-md-3">
              <button className="btn" onClick={store.exportReports}>
                <FontAwesomeIcon icon="download" fixedWidth className="icon" /> Export
              </button>
            </div>
          </div>
          <div className="clearfix" />
        </div>
      </div>
    </div>
  );
};

export default observer(List);
