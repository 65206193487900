// @flow

import React from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BatchSearchPSPListStore } from "../../../stores/mobx/BatchSearchListStore";
import Modal from "../../../components/modals/Modal";
import { ModalButtons, ModalTitle } from "../../ratecards/components/FilterModal";
import Button from "../../../components/lib/Button";

const ConfirmDeleteBatchSearchesModal = (props: { store: BatchSearchPSPListStore }) => {
  const store = props.store;

  return (
    <Modal
      show={store.confirmDeleteModal.show}
      onHide={store.confirmDeleteModal.hideModal}
    >
      <div className="container-section header">
        <ModalTitle>Confirmation Needed</ModalTitle>
      </div>
      <div className="container-section">
        <p>Are you sure you want to delete the selected Batch Searches?</p>
        <p>Rate Cards will not be affected by this action.</p>
        <p className="text-danger">
          <FontAwesomeIcon icon="exclamation-triangle" />{" "}
          <em>This action cannot be undone!</em>
        </p>
      </div>
      <div className="container-section footer">
        <ModalButtons>
          <Button
            color="danger"
            size="large"
            onClick={() => {
              store.confirmDeleteModal.hideModal();
              store.deleteBatchSearches();
            }}
          >
            Yes, Delete Batch Searches
          </Button>
          <Button size="large" onClick={store.confirmDeleteModal.hideModal}>
            No, Cancel
          </Button>
        </ModalButtons>
      </div>
    </Modal>
  );
};

export default observer(ConfirmDeleteBatchSearchesModal);
