// @flow
import { action } from "mobx";

import FilterObject, { ContainsTextFilter } from "../Filter";
import type { FilterColumn } from "../Filter";

// $FlowFixMe: Need to make the superclass properties covariant to subtype
export default class ClientLibraryClientRawJobTitleFilter extends ContainsTextFilter() {
  buildQueryFilter: () => ?FilterObject;
  onApply: () => void;
  softReset: () => void;
  applyFilter: (FilterColumn, FilterObject) => void;
  removeFilter: (FilterColumn) => void;

  constructor(
    store: Object,
    column: FilterColumn,
    applyFilter: (FilterColumn, FilterObject) => void,
    removeFilter: (FilterColumn) => void
  ) {
    super(store, column);

    this.applyFilter = applyFilter;
    this.removeFilter = removeFilter;
    this.buildQueryFilter = action(this.buildQueryFilter.bind(this));
    this.onApply = action(this.onApply.bind(this));
  }

  buildQueryFilter() {
    if (!this.textToLookFor || !this.textToLookFor.trim()) {
      return null;
    }

    return new FilterObject(
      "$libraryClientTitleFilterText: String!",
      "clientTitleIContains: $libraryClientTitleFilterText",
      {
        libraryClientTitleFilterText: this.textToLookFor,
      }
    );
  }
}
