// @flow
import React from "react";
import { FormControl } from "react-bootstrap";
import NumberInput from "../utils/NumberInput";

import {
  RATE_TYPE_OPTIONS,
  SALARY_RATE_TYPE_OPTIONS,
} from "../../../../constants/negotiationWorksheet";

export const valueType = "markup";

type FieldProps = {
  onBlur?: () => void,
  onChange: (any) => void,
  value: any,
  disabled: boolean,
};

export class Field extends React.Component<FieldProps> {
  static defaultProps = {
    disabled: false,
  };

  shouldComponentUpdate(nextProps: FieldProps) {
    return nextProps.value !== this.props.value;
  }

  handleIntegerChange = (value: number) => {
    this.props.onChange(Object.assign({}, this.props.value, { integer: value }));
  };

  handleMultiplierChange = (value: number) => {
    this.props.onChange(Object.assign({}, this.props.value, { multiplier: value }));
  };

  render() {
    const value = this.props.value;
    return (
      <div>
        <NumberInput
          step="1"
          addonAfter="X"
          min={0}
          disabled={this.props.disabled}
          {...this.props}
          onChange={this.handleIntegerChange}
          value={value["integer"]}
        />
        <NumberInput
          step="0.001"
          addonAfter="*"
          min={0}
          disabled={this.props.disabled}
          {...this.props}
          onChange={this.handleMultiplierChange}
          value={value["multiplier"]}
        />
      </div>
    );
  }
}

type CalculatedValueProps = {
  value: ?{
    integer?: number,
    multiplier?: number,
  },
  payType: any,
  rateType: any,
  payRateHourly: number,
  conversionOptions: {
    hoursInDay: number,
    hoursInWeek: number,
    hoursInMonth: number,
  },
  currencySymbol: string,
};

export class CalculatedValue extends React.Component<CalculatedValueProps> {
  shouldComponentUpdate(nextProps: CalculatedValueProps) {
    const props = this.props;
    return (
      nextProps.payType !== props.payType ||
      nextProps.payRateHourly !== props.payRateHourly ||
      nextProps.conversionOptions !== props.conversionOptions ||
      nextProps.currencySymbol !== props.currencySymbol ||
      nextProps.value !== props.value
    );
  }

  render() {
    const { value, payType, payRateHourly, rateType, conversionOptions, currencySymbol } =
      this.props;
    const integer = (value && value["integer"]) || 0;
    const multiplier = value && value["multiplier"] ? value["multiplier"] / 100.0 : 0;
    const percentMarkup = integer * multiplier;
    const calculatedValue =
      payType === 3
        ? SALARY_RATE_TYPE_OPTIONS.convertFromHourly(
            percentMarkup * payRateHourly,
            conversionOptions
          )
        : RATE_TYPE_OPTIONS[rateType].convertFromHourly(
            percentMarkup * payRateHourly,
            conversionOptions
          );

    return (
      <FormControl
        type="number"
        addonBefore={currencySymbol}
        value={calculatedValue.toFixed(3)}
        disabled={true}
      />
    );
  }
}
