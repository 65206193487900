// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import Pagination from "../../../ratecards/components/Pagination";
import LoadingIndicator from "../../../shared/LoadingIndicator";
import ContentListItem from "./ContentListItem";
import ToggleButton from "../../../ratecards/components/ToggleButton";
import { FILTER_COLUMN } from "../../../../models/Filter";
import TagContentFilter from "../../../filters/TagContentFilter";
import SortIndicator from "../../../ratecards/components/SortIndicator";

class ContentList extends Component {
  constructor(props) {
    super(props);

    this.changeCurrent = this.changeCurrent.bind(this);
  }

  changeCurrent(value) {
    let store: ContentListStore = this.props.store;
    store.pagination.handleCurrentPage(value);
  }

  componentDidMount() {
    this.props.store.tagId = this.props.router.params.tagId;
    this.props.store.pagination.goFetch();
  }

  render() {
    const store = this.props.store;
    const network = store.network;
    const fillContainer = this.props.fillContainer;
    const fixedHeight = this.props.fixedHeight;
    let selectAllSection = null;
    const tagStore = this.props.tagStore;
    let editClass = "";

    let listBodyStyles = {};
    if (fixedHeight) {
      listBodyStyles.height = fixedHeight;
      listBodyStyles.overflowY = "auto";
    }

    let selectAllOnPageSection = (
      <nav className="header-left-nav">
        <div className="btn" onClick={store.selectAllPage}>
          Select All
        </div>
        <div className="btn" onClick={store.deselectAllPage}>
          Deselect All
        </div>
      </nav>
    );

    if (store.allOnPageSelected) {
      selectAllSection = (
        <div className="ratecards-list-select-all-section">
          <span>
            All &nbsp;
            <strong>{store.totalCount}</strong>
            &nbsp; Location selected.{" "}
          </span>
          &nbsp;
          <span
            className="pt-link ratecards-all-items"
            key="clear-selections-link"
            onClick={store.clearAllSelections}
          >
            Clear all selections
          </span>
        </div>
      );
    }

    let tableLeftActionBar = null;
    if (store.isEditing) {
      tableLeftActionBar = (
        <nav className="header-left-nav">
          {store.allowMultipleItemSelection && selectAllOnPageSection}
        </nav>
      );
    }

    const tags = store.tagDetailListView;
    let tableContent = null;
    if (network.loading) {
      tableContent = <LoadingIndicator />;
    } else if (network.error) {
      // TODO: replace this with a more friendly error message
      tableContent = (
        <div className="pt-error-message">
          <h2>Error</h2>
          <pre>
            <code>{JSON.stringify(network.error, null, 2)}</code>
          </pre>
        </div>
      );
    } else if (tags.length === 0) {
      tableContent = (
        <div className="ratecards-table-empty">
          <div>
            No Listing
            <p>Create a new Tag using the button above or by searching.</p>
          </div>
        </div>
      );
    } else {
      tableContent = tags.map((tagD) => (
        <ContentListItem key={tagD.id} store={store} tagStore={tagStore} tag={tagD} />
      ));
    }

    if (store.isEditing) {
      editClass = "view";
    } else if (!store.isEditing) {
      editClass = "view";
    }

    return (
      <div className={editClass}>
        <div className="ratecards-list">
          {store.tagContentFilter && (
            <TagContentFilter
              tagsFilter={store.tagContentFilter}
              network={store.tagContentFilter.network.loading}
            />
          )}
          <header
            className={classNames("ratecards-table-header", {
              active: store.isFiltered,
            })}
          >
            <nav className="header-left-nav">
              {" "}
              <h4>Items attached on this tag</h4>
            </nav>
            {!store.isEditing && (
              <nav className="header-right-nav atl-1">
                {store.isFiltered && (
                  <div className="btn btn-yellow" onClick={store.clearFilters}>
                    Clear All Filters & Sorts
                  </div>
                )}
                {store.tagContentFilter && (
                  <ToggleButton
                    borderless={true}
                    name="search-list-filter"
                    value={FILTER_COLUMN.TAG_CONTENT}
                    selected={Boolean(store.appliedFilters[FILTER_COLUMN.TAGS])}
                    onClick={store.tagContentFilter.onShowModal}
                  >
                    <SortIndicator
                      sortColumn={FILTER_COLUMN.TAGS}
                      sortsOrder={store.appliedSortsOrder}
                      sort={store.appliedSorts[FILTER_COLUMN.TAGS]}
                    />
                    Type <span> ▼</span>
                  </ToggleButton>
                )}
              </nav>
            )}
            {tableLeftActionBar}
          </header>
          <div
            className={classNames("ratecards-table", {
              "ratecards-table-fill-container": fillContainer,
            })}
          >
            <div style={listBodyStyles}>
              {selectAllSection}
              {tableContent}
            </div>
          </div>
        </div>
        {store.pagination.pageCount > 0 ? (
          <Pagination
            pageCount={store.pagination.pageCount}
            pagesToShow={store.pagination.maxPagesToShow}
            currentPage={store.pagination.currentPage}
            begin={store.pagination.begin}
            end={store.pagination.end}
            isEndRange={store.pagination.isEndRange}
            handleCurrentPage={this.changeCurrent}
            firstPage={store.pagination.firstPage}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

ContentList.propTypes = {
  store: PropTypes.object.isRequired,
  fixedHeight: PropTypes.number,
  fillContainer: PropTypes.bool,
};

export default observer(ContentList);
