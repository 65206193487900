// @flow

import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LinkButton from "../../components/lib/LinkButton";
import { observer } from "mobx-react";
import SearchBox from "../ratecards/components/SearchBox";
import NegotiationWorksheetList from "./components/NegotiationWorksheetList";
import Modal from "../ratecards/components/Modal";
import ApplyTagFilterModal from "../shared/modals/ApplyTagFilterModal";
import ContainerSection from "../ratecards/components/ContainerSection";
import type MobXStore from "../../stores/mobx/MobXStore";
import Button from "../../components/lib/Button";
import {
  Card,
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubTitle,
  CardActions,
  CardActionsRight,
  CardActionsLeft,
} from "../../components/lib/Card";
import EditActions from "../../components/lib/EditActions";
import Container from "../../components/lib/Container";
import Inline from "../../components/lib/Inline";
import TextInput from "../../components/lib/TextInput";

type Props = {
  store: MobXStore,
};

class List extends Component<Props, null> {
  componentDidMount() {
    this.props.store.negotiationWorksheetListStore.pagination.goFetch();

    // reset to null so some elements visible while editing start hidden
    if (!this.props.store.negotiationWorksheetListStore.isTagView) {
      this.props.store.negotiationWorksheetListStore.isEditing = null;
    }
  }

  render() {
    const store = this.props.store.negotiationWorksheetListStore;
    return (
      <Container
        css={{
          paddingTop: "$4",
          paddingLeft: 0,
          paddingRight: 0,
          width: "100%",
        }}
      >
        <Card css={{ zIndex: 2 }}>
          {!store.isTagView && (
            <CardHeader>
              <CardHeaderTitle>Negotiation Worksheets</CardHeaderTitle>
              <CardHeaderSubTitle>
                Worksheets allow you to calculate the employer statutory and variable
                costs as they pertain to either a contingent labor engagement or full time
                staff. You may view, edit, and export worksheets to assist with offers and
                negotiations.
              </CardHeaderSubTitle>
            </CardHeader>
          )}
          <CardActions>
            <CardActionsLeft>
              <SearchBox
                value={store.negotiationFilter.textToLookFor}
                onChange={store.negotiationFilter.onTextToLookForChange}
                onSearch={store.negotiationFilter.onApply}
                style={{
                  width: "260px",
                }}
              />
            </CardActionsLeft>
            {!store.isTagView && (
              <CardActionsRight>
                {store.isEditing && (
                  <Button color="accent" onClick={store.handleStopEdit}>
                    Stop Editing
                  </Button>
                )}
                {!store.isEditing && (
                  <>
                    <Button color="brand" icon="edit" onClick={store.handleStartEdit}>
                      Edit
                    </Button>
                    <LinkButton
                      icon="plus"
                      to="/negotiation-worksheets/create"
                      color="brand"
                    >
                      Create Worksheet
                    </LinkButton>
                    <Button icon="question-circle" onClick={store.helpModal.showModal}>
                      Help
                    </Button>
                  </>
                )}
              </CardActionsRight>
            )}
          </CardActions>
          <NegotiationWorksheetList store={store} />
        </Card>
        {!store.isTagView && (
          <EditActions hidden={store.isEditing === null} show={Boolean(store.isEditing)}>
            <Button
              icon="edit"
              disabled={store.selectedCount !== 1}
              onClick={store.showRenameWorksheetModal}
            >
              Rename
            </Button>
            <Button
              disabled={store.selectedCount === 0}
              onClick={store.applyTagState.showTagModal}
            >
              Apply Tag
            </Button>
            <Button
              color="danger"
              icon="trash-alt"
              disabled={store.selectedCount === 0}
              onClick={store.confirmDeleteModal.showModal}
            >
              Delete
            </Button>
          </EditActions>
        )}

        <Modal show={store.helpModal.show} onHide={store.helpModal.hideModal}>
          <div className="container-section header">
            <h4>Help</h4>
          </div>
          <div className="container-section footer">
            <p>
              View worksheets, create new worksheets, and search worksheets by tag so you
              can easily find them later.
            </p>
          </div>
        </Modal>

        <Modal
          show={store.confirmDeleteModal.show}
          onHide={store.confirmDeleteModal.hideModal}
        >
          <div className="container-section header">
            <h4>Confirmation Needed</h4>
          </div>
          <div className="container-section">
            <p>Are you sure you want to delete the selected Worksheets?</p>
            <p className="text-danger">
              <FontAwesomeIcon icon="exclamation-triangle" />{" "}
              <em>This action cannot be undone!</em>
            </p>
          </div>
          <div className="container-section footer">
            <Inline>
              <Button onClick={store.confirmDeleteModal.hideModal}>No, Cancel</Button>
              <Button
                color="danger"
                onClick={(e) => {
                  store.confirmDeleteModal.hideModal();
                  store.deleteWorksheets();
                }}
              >
                Yes, Delete Worksheets
              </Button>
            </Inline>
          </div>
        </Modal>
        <Modal
          show={store.renameWorksheetModal.show}
          onHide={store.renameWorksheetModal.hideModal}
        >
          <ContainerSection className="header">
            <h4>Rename Worksheet</h4>
          </ContainerSection>
          <ContainerSection>
            <p>Name of the Worksheet:</p>
            <TextInput
              fill
              value={store.newWorksheetName}
              onChange={store.onNewWorksheetNameChange}
            />
            <div className="pt-user-messages">
              {store.messaging.messages.length > 0 && (
                <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                  <a
                    href="#close"
                    className="close"
                    aria-label="close"
                    onClick={store.messaging.removeMessage.bind(
                      store,
                      store.messaging.messages[0]
                    )}
                  >
                    &times;
                  </a>
                  <strong>{store.messaging.messages[0].messageLabel}</strong>
                  {store.messaging.messages[0].message}
                </div>
              )}
            </div>
          </ContainerSection>
          <ContainerSection className="footer">
            <Inline>
              <Button onClick={store.renameWorksheetModal.hideModal}>Cancel</Button>
              <Button color="brand" onClick={store.renameNegotiationWorksheet}>
                Rename Worksheet
              </Button>
            </Inline>
          </ContainerSection>
        </Modal>
        <ApplyTagFilterModal
          filterState={store.applyTagState}
          applyTagsEvent={store.applyTags}
          btnText="Apply Tag to Worksheets"
        />
      </Container>
    );
  }
}

List.propTypes = {
  store: PropTypes.object.isRequired,
};

export default observer(List);
