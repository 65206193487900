const text = `
NON-DISCLOSURE AGREEMENT


This NON-DISCLOSURE AGREEMENT (the “Agreement”) is made this day between PeopleTicker, LLC. (“Discloser”), and (“Recipient”) an individual that performs rate validation for PeopleTicker. NOW, THEREFORE, in consideration of the promises and agreements contained herein, the parties mutually agree as follows:

1.	DEFINITIONS

For purposes of this Agreement, Confidential Information shall mean all validation information including contingent and annual compensation rates, mark-ups, bill rates, companies, industries, locations, SkillsVillage software, validation process, monetary incentives, and all other information related to the validation of rates, that may be disclosed by Discloser or to which Recipient may be provided access by Discloser or others in accordance with this Agreement.

2.	CONFIDENTIALITY 

Each party shall (a) hold Confidential Information in confidence using the same degree of care as it normally exercises to protect its own proprietary information, (b) prevent disclosure of Confidential Information to third parties; (c) not to use any Confidential Information to compete or obtain advantage against the Discloser in any commercial activity which may be comparable to the commercial activity contemplated by the parties in connection with the Purpose; and (d) comply with any other reasonable security measures requested in writing by Discloser.

3.	TERM

The term of this Agreement shall commence on the date first written above and end three (3) years after Receipient ceases validation work.

4.	NO RIGHTS GRANTED

Nothing in this Agreement shall be construed as granting any rights to Recipient, by license or otherwise, to any of Discloser’s Confidential Information or intellectual property. 

5.	SOFTWARE

Each party agrees not to reverse engineer, reverse compile or otherwise disassemble any software disclosed by the other party.  In addition, each party agrees not to copy, or attempt to copy software disclosed by the other party. This provision shall survive the termination or expiration of this Agreement.  

8.	CONFIDENTIALITY OF DISCUSSION 

Neither party shall make any statement, public announcement, release to any trade publication or the press, or inform any third party of the discussions in connection with the possible transaction or the exchange of Confidential Information related to the proposed transaction. 

11.	IRREPARABLE HARM 

Recipient understands and acknowledges that any disclosure or misappropriation of any of the Confidential Information in violation of this Agreement may cause Discloser irreparable harm, the amount of which may be difficult to ascertain, and therefore agrees that Discloser shall have the right to apply to a court of competent jurisdiction for specific performance and/or an order restraining and enjoining any such further disclosure or breach and for such other relief as Discloser shall deem appropriate. 

12.	SUCCESSORS AND ASSIGNS

This Agreement and Recipient’s obligations hereunder shall be binding on representatives, permitted assigns, and successors of Recipient and shall inure to the benefit of Representatives, assigns and successors of Discloser.

13.	GOVERNING LAW 

This Agreement shall be governed by and construed in accordance with the laws of the State of Florida without regard to the conflicts of law provisions thereof.  

15.	ENTIRE AGREEMENT 

This Agreement expresses the full and complete understanding of the parties with respect to the subject matter hereof and supersedes all prior agreements, representations and understandings, whether written or oral, with respect to the subject matter.
`;

export default text;
