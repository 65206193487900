import * as React from "react";
import { useEffect, useRef, useCallback } from "react";
import TextInput from "./TextInput";
import { IconName } from "@fortawesome/fontawesome-svg-core";

type TextInputProps = React.ComponentPropsWithRef<typeof TextInput>;

export interface SearchBoxProps
  extends Omit<
    TextInputProps,
    | "icon"
    | "iconRight"
    | "iconClicked"
    | "iconRightClicked"
    | "onSubmit"
    | "onChange"
    | "value"
  > {
  value?: string;
  onSubmit?: (value: string | undefined) => void;
  onChange?: (value: string | undefined) => void;
}

const SearchBox = React.forwardRef<HTMLInputElement, SearchBoxProps>(
  (props, ref): JSX.Element => {
    const { onSubmit, onChange, value, ...rest } = props;
    let iconRight: IconName = "search";
    let handleClear: React.MouseEventHandler<HTMLButtonElement> | undefined;

    if (value) {
      iconRight = "times";
      handleClear = () => {
        if (value && onSubmit && onChange) {
          onChange("");
          onSubmit("");
        }
      };
    }

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event.currentTarget.value);
      }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter" && onSubmit) {
        onSubmit(event.currentTarget.value);
      }
    };

    return (
      <TextInput
        ref={ref}
        onChange={handleOnChange}
        onKeyDown={handleKeyDown}
        iconRight={iconRight}
        iconRightClicked={handleClear}
        value={value}
        {...rest}
      />
    );
  }
);

type SubmitFunc = SearchBoxProps["onSubmit"];
type Timeout = number;
type CancelAutoSubmitFunc = () => void;

const useAutoSubmit = (
  onSubmit: SubmitFunc,
  searchValue: string | undefined,
  timeout: Timeout
): CancelAutoSubmitFunc => {
  const prevSearchValueRef = useRef<string | undefined>(searchValue);
  const timeoutIdRef = useRef<number | undefined>(undefined);

  useEffect(() => {
    if (prevSearchValueRef.current !== searchValue && !!onSubmit) {
      prevSearchValueRef.current = searchValue;
      timeoutIdRef.current = window.setTimeout(() => onSubmit(searchValue), timeout || 0);
    }

    return () => {
      if (timeoutIdRef.current) {
        window.clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = undefined;
      }
    };
  }, [searchValue, timeout, onSubmit]);

  return () => window.clearTimeout(timeoutIdRef.current);
};

export interface AutoSearchBoxProps extends SearchBoxProps {
  timeout?: number;
}

export const AutoSearchBox = React.forwardRef<HTMLInputElement, AutoSearchBoxProps>(
  (props, ref): JSX.Element => {
    const { onSubmit, value, defaultValue, timeout = 1000, ...rest } = props;

    if (defaultValue != null) {
      console.error(
        'AutoSearchBox can\'t be used as uncontrolled React component ("defaultValue" component prop usage detected).'
      );
    }

    const cancelTimeout = useAutoSubmit(onSubmit, value, timeout);

    const handleSubmit: SearchBoxProps["onSubmit"] = useCallback(
      (value) => {
        cancelTimeout();
        onSubmit && onSubmit(value);
      },
      [onSubmit, cancelTimeout]
    );

    return <SearchBox ref={ref} onSubmit={handleSubmit} value={value} {...rest} />;
  }
);

export default SearchBox;
