import React, { useEffect, useState } from "react";
// lib
import Box from "../../../../components/lib/Box";
import { Dialog, DialogContent } from "../../../../components/lib/Dialog";
import Inline from "../../../../components/lib/Inline";
import Stack from "../../../../components/lib/Stack";
import { styled } from "../../../../stitches.config";
import Button from "../../../../components/lib/Button";
import { Link } from "../../../../components/lib/Link";
import Text from "../../../../components/lib/Text";
// relay
import graphql from "babel-plugin-relay/macro";
import { LocationsField } from "../../RsForm/LocationsField";
import { RegionsField } from "../../RsForm/RegionsField";
import { ModifyLocationsDialog_viewer$key } from "./__generated__/ModifyLocationsDialog_viewer.graphql";
import { ModifyLocationsDialogMutation } from "./__generated__/ModifyLocationsDialogMutation.graphql";
import { useGlobalContext } from "../../../../globalContext";
import { useFragment, useMutation } from "react-relay";
import { useRateSearchFormStore } from "../../../../stores/RateSearchForm";
import { Location, Region } from "../../types";
import { Set } from "immutable";
import { useSearchId } from "../../hooks/useSearchId";
import { payDifferenceObserver, recentSearchesObserver } from "../../observers";
import { isNonNullable } from "../../../../utils/hashmap";

const RpDialogContent = styled(DialogContent, {
  padding: "$6",
  minHeight: "450px",
  width: "520px !important",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const fragment = graphql`
  fragment ModifyLocationsDialog_viewer on Viewer {
    user {
      resourceAllocations(resourceType: SEARCH) {
        id
        balance
        isUnlimited
      }
    }
    ...RegionsField_viewer
  }
`;

const mutaion = graphql`
  mutation ModifyLocationsDialogMutation($input: FindRateInput!) {
    ratesearches(input: $input) {
      searches {
        ratesearches {
          jobTitle
          jobLabel
          results {
            searchId
          }
        }
      }
    }
  }
`;

interface Props {
  setSearchDetails(): void;
  viewer: ModifyLocationsDialog_viewer$key;
}

export function ModifyLocationsDialog(props: Props) {
  const { setSearchDetails, viewer } = props;
  const { perSearchPricing } = useGlobalContext();
  const { searchId } = useSearchId();
  const [open, setOpen] = useState(false);

  const [regions, setRegions] = useState<Region[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);

  const [commitMutation, isInFlight] =
    useMutation<ModifyLocationsDialogMutation>(mutaion);

  const data = useFragment(fragment, viewer);
  const searchesRemaining = data.user?.resourceAllocations?.[0]?.balance || 0;
  const isUnlimitedSearch = data.user?.resourceAllocations?.[0]?.isUnlimited || false;
  const disabledLocations = useRateSearchFormStore((s) => s.locations);
  const disabledRegions = useRateSearchFormStore((s) => s.regions);
  const getMutationInput = useRateSearchFormStore((s) => s.getMutationInput);
  const canSubmitSearchRate = useRateSearchFormStore((s) => s.canSubmitSearchRate());

  const noSearchAvailable =
    perSearchPricing && !isUnlimitedSearch && searchesRemaining === 0;

  function onSubmit() {
    if (canSubmitSearchRate) {
      commitMutation({
        variables: {
          input: {
            ...getMutationInput(),
            locationIds: locations.map((l) => l.locationId),
            regionIds: regions.map((r) => r.regionId),
          },
        },
        onCompleted(response) {
          const rateSearches = response?.ratesearches?.searches?.ratesearches || [];
          const ids = rateSearches
            .map((s) => s?.results?.[0]?.searchId)
            .filter(isNonNullable);
          payDifferenceObserver.emit(Set(ids));
          recentSearchesObserver.emit();
          setOpen(false);
        },
        onError(e) {
          throw e;
        },
      });
    }
  }

  useEffect(() => {
    setLocations([]);
    setRegions([]);
  }, [searchId]);

  return (
    <Dialog open={open}>
      <Link
        onClick={() => {
          setSearchDetails();
          setOpen(true);
        }}
        as="button"
        css={{ fontWeight: "$semibold", textDecoration: "underline !important" }}
        size="small"
      >
        Modify Locations
      </Link>

      <RpDialogContent
        onOpenAutoFocus={(e) => e.preventDefault()}
        onCloseAutoFocus={(e) => e.preventDefault()}
      >
        <Stack css={{ alignItems: "flex-start" }}>
          <Text as="h3" css={{ margin: "0" }}>
            Modify Locations
          </Text>
          {noSearchAvailable && (
            <Stack nogap css={{ alignItems: "flex-start", paddingBottom: "$2" }}>
              <Text color="negative">
                Search submission is unavailable as there are no remaining searches.
              </Text>
            </Stack>
          )}
          <Box css={{ width: "100%" }}>
            <LocationsField
              hasRegions={regions.length > 0}
              locations={locations}
              setLocations={setLocations}
              isSideMenu={false}
              disabledLocations={disabledLocations}
            />
          </Box>
          <Box css={{ width: "100%" }}>
            <RegionsField
              hasLocations={locations.length > 0}
              regions={regions}
              disabledRegions={disabledRegions}
              setRegions={setRegions}
              viewer={data}
            />
          </Box>
        </Stack>
        <Inline css={{ justifyContent: "flex-end" }}>
          <Button
            onClick={() => {
              setSearchDetails();
              setOpen(false);
            }}
            variant="outlined"
            color="brand"
            size="small"
          >
            Cancel
          </Button>

          <Button
            onClick={onSubmit}
            variant="filled"
            color="brand"
            size="small"
            loading={isInFlight}
            disabled={isInFlight || !canSubmitSearchRate || noSearchAvailable}
            loadingText="Saving Changes"
          >
            Save Changes
          </Button>
        </Inline>
      </RpDialogContent>
    </Dialog>
  );
}
