import React from "react";
import moment from "moment-timezone";

import Icon from "./lib/Icon";
import Grid from "./lib/Grid";
import Stack from "./lib/Stack";
import Inline from "./lib/Inline";
import { RadioGroup, RadioGroupItem } from "./lib/RadioGroup";

// @ts-ignore
import SingleDatePicker from "./SingleDatePicker";
import { FilterDialog, SORT_DIRECTION } from "./FilterDialog";

import type { Moment } from "moment";
import type { FilterDialogProps, SortDirectionType } from "./FilterDialog";

export interface FilterByDateDialogProps {
  title?: string;
  // toggle style modifiers
  filtered?: boolean;
  sorted?: SortDirectionType;
  // values
  sortDirecton?: SortDirectionType;
  fromDate?: string;
  toDate?: string;
  // event handlers
  onChangeFromDate: (value?: string) => void;
  onChangeToDate: (value?: string) => void;
  onChangeSortDirection: (direction: SortDirectionType) => void;
  onApply: FilterDialogProps["onApply"];
  onClear: FilterDialogProps["onClear"];
}

export function FilterByDateDialog(props: FilterByDateDialogProps) {
  const {
    title = "Filter By Date",
    filtered,
    sorted,
    fromDate,
    toDate,
    sortDirecton,
    onChangeFromDate,
    onChangeToDate,
    onChangeSortDirection,
    onApply,
    onClear,
  } = props;
  const sortedObj = React.useMemo(() => {
    if (!sorted) return sorted;
    return {
      dataType: "numeric" as const,
      direction: sorted,
    };
  }, [sorted]);

  // handlers

  const handleSortDirectionChange = React.useCallback(
    (value: SortDirectionType) => {
      if (value !== sortDirecton) onChangeSortDirection(value);
    },
    [sortDirecton, onChangeSortDirection]
  );

  const handleDateFromChange = React.useCallback(
    (value: Moment) => {
      const stringValue =
        value != null
          ? value.utc(true).hour(0).minute(0).second(0).millisecond(0).toISOString()
          : undefined;
      if (stringValue !== fromDate) onChangeFromDate(stringValue);
    },
    [fromDate, onChangeFromDate]
  );

  const handleDateToChange = React.useCallback(
    (value: Moment) => {
      const stringValue =
        value != null
          ? value.utc(true).hour(23).minute(59).second(59).millisecond(999).toISOString()
          : undefined;
      if (stringValue !== toDate) onChangeToDate(stringValue);
    },
    [toDate, onChangeToDate]
  );

  return (
    <FilterDialog
      title={title}
      description={
        <Stack css={{ alignItems: "flex-start" }}>
          <Inline fill css={{ "& button": { flexGrow: 1 } }}>
            <span>Sort:</span>
            <RadioGroup value={sortDirecton} onValueChange={handleSortDirectionChange}>
              <Inline>
                <RadioGroupItem value={SORT_DIRECTION.ASC}>
                  <Icon icon={`sort-numeric-down`} css={{ color: "$accent" }} /> Oldest
                  First
                </RadioGroupItem>
                <RadioGroupItem value={SORT_DIRECTION.DESC}>
                  <Icon icon={`sort-numeric-down-alt`} css={{ color: "$accent" }} />{" "}
                  Newest First
                </RadioGroupItem>
              </Inline>
            </RadioGroup>
          </Inline>
          <span>Filter by a specific period:</span>
          <Grid
            css={{
              paddingLeft: "$4",
              gridTemplateColumns: "40px auto",
              alignItems: "center",
              gap: "$2",
            }}
          >
            <label>From:</label>
            <SingleDatePicker
              date={fromDate != null ? moment.utc(fromDate, moment.ISO_8601) : undefined}
              onDateChange={handleDateFromChange}
            />
            <label>To:</label>
            <SingleDatePicker
              date={toDate != null ? moment.utc(toDate, moment.ISO_8601) : undefined}
              onDateChange={handleDateToChange}
            />
          </Grid>
        </Stack>
      }
      filtered={filtered}
      sorted={sortedObj}
      onApply={onApply}
      onClear={onClear}
    />
  );
}

FilterByDateDialog.displayName = "FilterByDateDialog";

type FilterByDateStateType = {
  sortDirection?: SortDirectionType;
  fromDate?: string;
  toDate?: string;
};

export function useFilterByDateDialogState(initialState: FilterByDateStateType) {
  const [sortDirection, setSortDirection] = React.useState<SortDirectionType | undefined>(
    initialState.sortDirection
  );
  const [fromDate, setFromDate] = React.useState<string | undefined>(
    initialState.fromDate
  );
  const [toDate, setToDate] = React.useState<string | undefined>(initialState.toDate);

  const resetWholeState = React.useCallback(() => {
    setSortDirection(undefined);
    setFromDate(undefined);
    setToDate(undefined);
  }, []);

  return {
    sortDirection,
    fromDate,
    toDate,
    handleFromDateChange: setFromDate,
    handleToDateChange: setToDate,
    handleSortByDateDirectionChange: setSortDirection,
    handleFilterByDateDialogStateReset: resetWholeState,
  };
}
