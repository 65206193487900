// @flow

import React, { Component } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "../../components/lib/Link";
import JobLibraryListItem from "./JobLibraryListItem";
import { observer } from "mobx-react";
import JobLibrary from "../../models/JobLibrary";
import LibraryDisplayOptions from "./components/LibraryDisplayOptions";
import JobLibraryCountryListItem from "./JobLibraryCountryListItem";
import { LIBRARY_DISPLAY_OPTIONS } from "../../stores/mobx/JobLibraryListStore";
import EmptyRow from "./EmptyRow";
import NonIdealState from "./components/NonIdealState";
import type MobXStore from "../../stores/mobx/MobXStore";
import type { CountryLibraryNode } from "../../stores/mobx/JobLibraryListStore";
import { styled } from "../../../src/stitches.config";

const CreateNewLib = styled(Link, {
  background: "#fff !important",
  border: "1px solid #e6e6e6",
  borderRadius: "2px",
  width: "230px",
  height: "150px",
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  "&:hover": {
    textDecoration: "none !important",
    border: "1px solid $brand",
  },
});

type Props = {
  selectedLibraryId: string,
  store: MobXStore,
  router: {
    push: (any) => void,
    params: any,
  },
  className: any,
};

class List extends Component<Props> {
  props: Props;

  handleOnItemClick: (JobLibrary) => void;
  handleCountryLibraryClick: (Object) => void;

  constructor(props: Props) {
    super(props);

    this.handleOnItemClick = this.handleOnItemClick.bind(this);
    this.handleCountryLibraryClick = this.handleCountryLibraryClick.bind(this);
  }

  componentDidMount() {
    this.props.store.jobLibraryListStore.getCountryLibraries();

    if (this.props.router.params.countryId) {
      const countryId = parseInt(this.props.router.params.countryId);
      this.props.store.jobLibraryListStore.libraryDisplayValue =
        LIBRARY_DISPLAY_OPTIONS.COUNTRY_LIBRARY;
      this.props.store.jobLibraryTitleListStore.countryId = countryId;
      // Note: fetch will be executed by the detail view
      // this.props.store.jobLibraryTitleListStore.pagination.goFetch();
    }

    // reset to null so some elements visible while editing start hidden
    // this.props.store.jobLibraryListStore.isEditing = null;
  }

  handleOnItemClick(library: JobLibrary) {
    const store = this.props.store.jobLibraryListStore;
    const jobLibraryTitleListStore = this.props.store.jobLibraryTitleListStore;
    if (store.isFiltered || store.clientLibraryClientRawJobTitleFilter.textToLookFor) {
      jobLibraryTitleListStore.applyFilterFromLibraryStore();
    }

    if (library && library.id) {
      // reset country view fields
      this.props.store.jobLibraryTitleListStore.libraryId = null;
      this.props.store.jobLibraryTitleListStore.countryId = null;
      this.props.store.jobLibraryTitleListStore.countryTitle = "";
      this.props.store.jobLibraryTitleListStore.countryCode = "";

      // show library view
      store.libraryDisplayValue = LIBRARY_DISPLAY_OPTIONS.LIBRARY;
      this.props.router.push(`/job-library/view/${library.id}/`);
      window.scrollTo(0, 0);
    }
  }

  handleCountryLibraryClick(countryLibrary: CountryLibraryNode) {
    // console.log('handleCountryLibraryClick', countryLibrary);
    this.props.store.jobLibraryListStore.libraryDisplayValue =
      LIBRARY_DISPLAY_OPTIONS.COUNTRY_LIBRARY;
    const jobLibraryTitleListStore = this.props.store.jobLibraryTitleListStore;
    jobLibraryTitleListStore.removeFilterBySelectedTitles(false);
    jobLibraryTitleListStore.libraryId = null;
    jobLibraryTitleListStore.countryId = countryLibrary.databaseId;
    jobLibraryTitleListStore.countryTitle = countryLibrary.title;
    jobLibraryTitleListStore.countryCode = countryLibrary.iso3166Alpha2;
    jobLibraryTitleListStore.pagination.goFetch();
    this.props.router.push(`/job-library/view/countries/${countryLibrary.databaseId}/`);

    window.scrollTo(0, 0);
  }

  render() {
    const store = this.props.store.jobLibraryListStore;
    const libraryId = parseInt(this.props.selectedLibraryId, 10);
    const countryId = this.props.store.jobLibraryTitleListStore.countryId;

    let items = null;
    let createNewLibraryButton = null;

    if (store.network.loading || store.countryLibrariesNetwork.loading) {
      items = (
        <div style={{ width: 310, margin: "0 -40px" }}>
          <NonIdealState
            icon="circle-notch"
            iconClasses="fa-spin"
            title="Loading"
            description="Please wait while data is being transferred"
          />
        </div>
      );
    }

    const libraryView = store.libraryDisplayValue === LIBRARY_DISPLAY_OPTIONS.LIBRARY;
    if (libraryView && !store.network.loading) {
      items = store.clientLibraries.map((library) => (
        <JobLibraryListItem
          key={library.id}
          library={library}
          titlesFiltered={store.isFiltered}
          selected={library.id === libraryId}
          onClick={this.handleOnItemClick}
        />
      ));
      createNewLibraryButton = (
        <CreateNewLib
          to={"/job-library/library-requests/"}
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          <FontAwesomeIcon icon="plus-circle" size="lg" />
          Create A New Library
        </CreateNewLib>
      );
    }

    const countryLibraryView =
      store.libraryDisplayValue === LIBRARY_DISPLAY_OPTIONS.COUNTRY_LIBRARY;
    if (countryLibraryView && !store.countryLibrariesNetwork.loading) {
      items = store.countryLibraries.map((countryLibrary) => (
        <JobLibraryCountryListItem
          library={countryLibrary}
          key={countryLibrary.databaseId}
          name={countryLibrary.title}
          countryCode={countryLibrary.iso3166Alpha2}
          totalJobs={countryLibrary.totalTitles.totalCount}
          titlesCount={countryLibrary.clientJobTitles.totalCount}
          titlesFiltered={store.isFiltered}
          selected={countryLibrary.databaseId === countryId}
          onClick={this.handleCountryLibraryClick.bind(this, countryLibrary)}
        />
      ));

      if (items.length === 0) {
        items = (
          <div className="cjl-list-item" style={{ height: "initial", padding: 20 }}>
            <EmptyRow text="No Countries Available" />
          </div>
        );
      }
    }

    return (
      <div>
        <LibraryDisplayOptions store={store} />
        <div
          className={classNames("cjl-list", this.props.className)}
          style={{ minWidth: 230, paddingTop: 15 }}
        >
          {items}
          {createNewLibraryButton}
        </div>
      </div>
    );
  }
}

export default observer(List);
