// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";

import SortIndicator from "../ratecards/components/SortIndicator";
import { FILTER_COLUMN } from "../../models/Filter";
import FilterModal from "../ratecards/components/FilterModal";
import SortControls from "../ratecards/components/SortControls";
import Pagination from "../../components/lib/Pagination";
import LoadingIndicator from "../shared/LoadingIndicator";
import JobListItem from "./JobListItem";
import { SingleDatePicker } from "../../components/SingleDatePicker";
import { JobLabelListComponentStore } from "../../stores/mobx/JobLabelListStore";
import ToggleButton from "../ratecards/components/ToggleButton";
import ContainerSection from "../ratecards/components/ContainerSection";
import TagsFilter from "../filters/TagsFilter";
import Inline from "../../components/lib/Inline";
import Button from "../../components/lib/Button";
import Box from "../../components/lib/Box";
import { CardAlert, CardAlertLink, CardFilters } from "../../components/lib/Card";

/**
 * JobsList component
 */
class JobsList extends Component {
  constructor(props) {
    super(props);

    this.changeCurrent = this.changeCurrent.bind(this);
  }

  changeCurrent(value) {
    let store: JobLabelListComponentStore = this.props.store;
    store.pagination.handleCurrentPage(value);
  }

  render() {
    const store: JobLabelListComponentStore = this.props.store;
    const network = store.network;
    const maximumDate = new Date();

    let selectAllSection = null;
    let selectAllOnPageSection = (
      <Inline css={{ gap: "$2" }}>
        <Button onClick={store.selectAllOnPageItem}>Select All</Button>
        <Button onClick={store.deselectAllPage}>Deselect All</Button>
      </Inline>
    );

    if (store.allSelected) {
      selectAllSection = (
        <CardAlert>
          <span>
            All &nbsp;
            <strong>{store.totalCount}</strong>
            &nbsp; Jobs selected.
          </span>
          {!store.isTagView && (
            <CardAlertLink key="clear-selections-link" onClick={store.clearAllSelections}>
              Clear all selections
            </CardAlertLink>
          )}
          {store.isTagView && (
            <CardAlertLink
              key="clear-selections-link"
              onClick={store.clearAllSelectionsOnTagView}
            >
              Clear all selections
            </CardAlertLink>
          )}
        </CardAlert>
      );
    }

    if (store.allOnPageSelected && !store.allSelected) {
      selectAllSection = (
        <CardAlert>
          <span>All Jobs on this page selected.</span>
          <CardAlertLink key="select-all-item" onClick={store.selectAllPage}>
            Select all <strong>{store.totalCount}</strong> Jobs.
          </CardAlertLink>
          {!store.isTagView && (
            <CardAlertLink key="clear-selections-link" onClick={store.clearAllSelections}>
              Clear all selections
            </CardAlertLink>
          )}
          {store.isTagView && (
            <CardAlertLink
              key="clear-selections-link"
              onClick={store.clearAllSelectionsOnTagView}
            >
              Clear all selections
            </CardAlertLink>
          )}
        </CardAlert>
      );
    }

    let tableLeftActionBar = null;
    if (store.isEditing) {
      tableLeftActionBar = (
        <Box css={{ marginRight: "auto" }}>
          {store.allowMultipleItemSelection && selectAllOnPageSection}
        </Box>
      );
    }

    const jobsList = store.jobsListView;

    let tableContent = null;
    if (network.loading) {
      tableContent = <LoadingIndicator />;
    } else if (network.error) {
      // TODO: replace this with a more friendly error message
      tableContent = (
        <div className="pt-error-message">
          <h2>Error</h2>
          <pre>
            <code>{JSON.stringify(network.error, null, 2)}</code>
          </pre>
        </div>
      );
    } else if (jobsList.length === 0) {
      tableContent = (
        <div className="ratecards-table-empty">
          {Object.keys(store.appliedFilters).length > 0 ? (
            <div>No Jobs match your current filters</div>
          ) : (
            <div>
              No Jobs
              <p>Create a new Job using the form above or by searching.</p>
            </div>
          )}
        </div>
      );
    } else {
      tableContent = jobsList.map((job) => (
        <JobListItem key={job.jobId} store={store} job={job} editing={store.isEditing} />
      ));
    }

    return (
      <>
        {store.tagsFilter && (
          <TagsFilter
            tagsFilter={store.tagsFilter}
            network={store.tagsFilter.network.loading}
            instantSearchValue={store.tagsFilter.instantSearchValue}
            onInstantSearch={store.tagsFilter.onInstantSearch}
          />
        )}
        {store.createdOnFilter && (
          <FilterModal filterState={store.createdOnFilter}>
            <div>
              <ContainerSection className="header overlap-t-padding">
                <h4 className="pull-left">Created On</h4>
                <div className="pull-right">
                  <SortControls
                    filterState={store.createdOnFilter}
                    sortType="numeric"
                    ascText="Sort Oldest First"
                    descText="Sort Newest First"
                  />
                </div>
                <div className="clearfix" />
              </ContainerSection>
              <ContainerSection>
                <p>Filter by a specific period:</p>
                <div className="pt-range-filter">
                  <div className="pt-range-filter__item">
                    <p>From:</p>
                    <SingleDatePicker
                      id="from_date"
                      date={store.createdOnFilter.fromDate}
                      numberOfMonths={1}
                      isOutsideRange={(day) => day.isAfter(maximumDate)}
                      focused={store.createdOnFilter.fromFocused}
                      onDateChange={store.createdOnFilter.fromDateChange}
                      onFocusChange={store.createdOnFilter.fromFocusedChange}
                    />
                  </div>
                  <div className="pt-range-filter__item">
                    <p>To:</p>
                    <SingleDatePicker
                      id="to_date"
                      date={store.createdOnFilter.toDate}
                      numberOfMonths={1}
                      isOutsideRange={(day) => store.toDateRange(day)}
                      focused={store.createdOnFilter.toFocused}
                      onDateChange={store.createdOnFilter.toDateChange}
                      onFocusChange={store.createdOnFilter.toFocusedChange}
                    />
                  </div>
                </div>
              </ContainerSection>
            </div>
          </FilterModal>
        )}
        <CardFilters filtered={store.isFiltered}>
          {tableLeftActionBar}
          {store.isFiltered && (
            <Button color="accent" onClick={store.clearFilters}>
              Clear All Filters &amp; Sorts
            </Button>
          )}
          {store.tagsFilter && (
            <ToggleButton
              borderless={true}
              name="search-list-filter"
              value={FILTER_COLUMN.TAGS}
              selected={Boolean(store.appliedFilters[FILTER_COLUMN.TAGS])}
              onClick={store.tagsFilter.onShowModal}
            >
              <SortIndicator
                sortColumn={FILTER_COLUMN.TAGS}
                sortsOrder={store.appliedSortsOrder}
                sort={store.appliedSorts[FILTER_COLUMN.TAGS]}
              />
              Tags <span> ▼</span>
            </ToggleButton>
          )}
          {store.createdByFilter && (
            <ToggleButton
              borderless={true}
              name="rate-cards-filter"
              value={FILTER_COLUMN.CREATED_BY}
              selected={Boolean(store.appliedFilters[FILTER_COLUMN.CREATED_BY])}
              onClick={store.createdByFilter.onShowModal}
            >
              <SortIndicator
                sortColumn={FILTER_COLUMN.CREATED_BY}
                sortsOrder={store.appliedSortsOrder}
                sort={store.appliedSorts[FILTER_COLUMN.CREATED_BY]}
              />
              Created By
              <span> ▼</span>
            </ToggleButton>
          )}
          {store.createdOnFilter && (
            <ToggleButton
              borderless={true}
              name="rate-cards-filter"
              value={FILTER_COLUMN.DATE_RANGE}
              selected={Boolean(store.appliedFilters[FILTER_COLUMN.DATE_RANGE])}
              onClick={store.createdOnFilter.onShowModal}
            >
              <SortIndicator
                sortType="numeric"
                sortColumn={FILTER_COLUMN.DATE_RANGE}
                sortsOrder={store.appliedSortsOrder}
                sort={store.appliedSorts[FILTER_COLUMN.DATE_RANGE]}
              />
              Created On
              <span> ▼</span>
            </ToggleButton>
          )}
        </CardFilters>
        <div className={classNames("ratecards-table")}>
          <div>
            {selectAllSection}
            {tableContent}
          </div>
        </div>
        {store.pagination.pageCount > 0 && (
          <Pagination
            options={{
              variant: "full",
              currentPage: store.pagination.currentPage,
              numPages: store.pagination.pageCount,
            }}
            onPageClick={(pageEvent) => {
              if (pageEvent.type === "first") {
                this.changeCurrent(1);
              }
              if (pageEvent.type === "last") {
                this.changeCurrent(store.pagination.pageCount);
              }
              if (pageEvent.type === "next") {
                this.changeCurrent(
                  Math.min(store.pagination.currentPage + 1, store.pagination.pageCount)
                );
              }
              if (pageEvent.type === "prev") {
                this.changeCurrent(Math.max(store.pagination.currentPage - 1, 1));
              }
              if (pageEvent.type === "page") {
                this.changeCurrent(pageEvent.page);
              }
            }}
          />
        )}
      </>
    );
  }
}

JobsList.propTypes = {
  store: PropTypes.object.isRequired,
};
export default observer(JobsList);
