import React from "react";
import GoogleMap from "google-map-react";
import { Link } from "react-router";

const styles = {
  height: "100vh",
  width: "100vw",
  display: "flex",
  flexDirection: "column",
};

export default (props) => (
  <div style={styles}>
    <Link to={`/ratecards/${props.ratecardId || 1}`}>Back</Link>
    <GoogleMap
      bootstrapURLKeys={{
        key: "AIzaSyAxzCyKaoE_XufGSpCTqV-AJ61zMSeMAMg",
        language: "en",
      }}
      center={[59.938043, 30.337157]}
      zoom={9}
    />
  </div>
);
