import React from "react";

import { useSearchInputState, ValuesBlock } from "./BaseValuesFilter";
import { BaseValuesCheckFilterProps, ValuesCheckFilter } from "./ValuesCheckFilter";
import { ValuesListBlock } from "./ValuesIContainsFilter";
import { useValuesSource } from "./ValuesCheckFilterRestful";
import {
  OrderDirections,
  resultSetArgName,
  FilterTypes,
  emptyOrderedMap,
} from "../constants";
import { FiltersDataProviderRestful, FiltersQueryOrderedMap, RowData } from "../types";
import { Activatable } from "./types";

const defaultPageNumber = 1;

interface ValuesIContainsFilterRestfulProps<RD = RowData>
  extends BaseValuesCheckFilterProps<RD> {
  dataProvider: FiltersDataProviderRestful;
  dataProviderFilters: FiltersQueryOrderedMap;
  uniqueKey: string;
  pageSize?: number;
}

const ValuesIContainsFilterRestful = React.forwardRef(
  <RD = RowData,>(
    props: ValuesIContainsFilterRestfulProps<RD>,
    ref: React.ForwardedRef<Activatable>
  ) => {
    const {
      emptyHint,
      filter,
      searchTimeout,
      pageSize,
      uniqueKey,
      dataProvider,
      dataProviderFilters,
      onChange,
      isVisible,
    } = props;

    const orderDirection = filter.getIn(["order", "direction"], OrderDirections.NO);
    const searchValue = filter.getIn(["filter", "search"]);

    const { makeSearch, setSearchInputRef, setSearchFocus } = useSearchInputState(
      searchValue,
      filter,
      FilterTypes.VALUES_ICONTAINS,
      onChange
    );

    const { loading, values, valuesSource, handleScrollList } = useValuesSource(
      dataProvider,
      dataProviderFilters,
      resultSetArgName,
      uniqueKey,
      pageSize!
    );

    React.useImperativeHandle(ref, () => ({
      initialize: () => valuesSource(searchValue, orderDirection, defaultPageNumber),
      focus: setSearchFocus,
    }));

    React.useEffect(() => {
      if (!isVisible) return;
      valuesSource(searchValue, orderDirection, defaultPageNumber);
    }, [isVisible, searchValue, orderDirection, valuesSource]);

    return (
      <ValuesBlock
        searchValue={searchValue}
        searchTimeout={searchTimeout!}
        inputRef={setSearchInputRef}
        onSearch={makeSearch}
      >
        <ValuesListBlock
          values={values}
          loading={loading}
          onScroll={loading ? undefined : handleScrollList}
          emptyHint={emptyHint!}
        />
      </ValuesBlock>
    );
  }
) as {
  <RD = RowData>(
    props: ValuesIContainsFilterRestfulProps<RD> & {
      ref?: React.ForwardedRef<Activatable>;
    }
  ): React.ReactElement;
  displayName: string;
  defaultProps: Partial<ValuesIContainsFilterRestfulProps>;
};

ValuesIContainsFilterRestful.displayName = "ValuesIContainsFilterRestful";
ValuesIContainsFilterRestful.defaultProps = {
  dataProviderFilters: emptyOrderedMap as FiltersQueryOrderedMap,
  searchTimeout: ValuesCheckFilter.defaultProps?.searchTimeout,
  emptyHint: ValuesCheckFilter.defaultProps?.emptyHint,
  pageSize: 30,
};

export default ValuesIContainsFilterRestful;
