import React from "react";
import Icon from "./lib/Icon";
import {
  Dialog,
  DialogActions,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogPortal,
} from "./lib/Dialog";
import Inline from "./lib/Inline";
import Box from "./lib/Box";

export type ShowConfirmationModalFunc = (
  description: React.ReactNode,
  title: React.ReactNode,
  actions?: React.ReactNode
) => Promise<void>;
export type CloseConfirmationModalFunc = () => Promise<void>;

export type ConfirmationModalAPI = {
  confirm: ShowConfirmationModalFunc;
  close: CloseConfirmationModalFunc;
};

interface ConfirmationModalPropsType {
  onOpen?: () => void;
  onClose?: () => void;
}
interface ConfirmationModalStateType {
  open: boolean;
  title: React.ReactNode | null;
  description: React.ReactNode | null;
  actions: React.ReactNode | null;
}

const ConfirmationModal = React.forwardRef<
  ConfirmationModalAPI,
  React.PropsWithRef<ConfirmationModalPropsType>
>((props, ref) => {
  const { onOpen, onClose } = props;
  const [{ open, title, description, actions }, setModalState] =
    React.useState<ConfirmationModalStateType>({
      open: false,
      title: null,
      description: null,
      actions: null,
    });

  const confirm: ShowConfirmationModalFunc = React.useCallback(
    async (description, title, actions = null) => {
      return new Promise((resolve) => {
        setModalState((prevState) => ({
          ...prevState,
          title,
          description,
          actions,
          open: true,
        }));
        setTimeout(() => resolve(), 500);
      });
    },
    []
  );

  const close: CloseConfirmationModalFunc = React.useCallback(async () => {
    return new Promise((resolve) => {
      setModalState((prevState) => ({ ...prevState, open: false }));
      setTimeout(() => resolve(), 500);
    });
  }, []);

  React.useImperativeHandle(ref, () => ({ confirm, close }), [confirm, close]);

  const handleOpenChange = React.useCallback(
    async (value) => {
      if (value) {
        onOpen && onOpen();
      } else {
        await close();
        onClose && onClose();
      }
    },
    [onOpen, onClose, close]
  );

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogPortal>
        <DialogContent>
          <DialogTitle asChild>
            <Inline css={{ justifyContent: "space-between" }}>
              <Box>
                <h4>{title}</h4>
              </Box>
              <DialogClose asChild>
                <Icon icon="times" />
              </DialogClose>
            </Inline>
          </DialogTitle>
          <DialogDescription asChild css={{ backgroundColor: "inherit" }}>
            <div>{description}</div>
          </DialogDescription>
          {actions && <DialogActions>{actions}</DialogActions>}
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
});
ConfirmationModal.displayName = "ConfirmationModal";

export function useConfirmationModalRef() {
  const confirmationModalRef = React.useRef<ConfirmationModalAPI>(null);

  const showConfirmationModal: ShowConfirmationModalFunc = React.useCallback(
    async (message, header, footer) =>
      confirmationModalRef.current?.confirm(message, header, footer),
    [confirmationModalRef]
  );

  const closeConfirmationModal: CloseConfirmationModalFunc = React.useCallback(
    async () => confirmationModalRef.current?.close(),
    [confirmationModalRef]
  );

  return {
    confirmationModalRef,
    showConfirmationModal,
    closeConfirmationModal,
  };
}

export default ConfirmationModal;
