import React from "react";

import Button from "../../../../components/lib/Button";
import PromiseButton from "../../../../components/lib/PromiseButton";
import {
  Card,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
  CardHeaderTitle,
} from "../../../../components/lib/Card";
import { RATE_TYPES_LABELS } from "../../types";

import { useReviewDataContext } from "../../context/ReviewDataContext";

type PageHeadBlockProps = {
  onToggleActive: () => Promise<void>;
  onGoBack: () => void;
};

const PageHeadBlock = (props: PageHeadBlockProps) => {
  const { onToggleActive, onGoBack } = props;

  const {
    reviewData,
    firstJobData,
    isActive,
    reviewId,
    reviewTitle,
    isRateCardSource,
    ratecardName,
    isUploadSource,
  } = useReviewDataContext();

  const rateType = firstJobData.get("rate_type");
  const spreadsheetFileName = reviewData.getIn(["upload", "file_name"]);
  const title = reviewTitle ? (
    <span>
      {reviewTitle}, {RATE_TYPES_LABELS[rateType]}
      {isRateCardSource && <small> (Made from rate card "{ratecardName}")</small>}
      {isUploadSource && <small> (Made from spreadsheet {spreadsheetFileName})</small>}
    </span>
  ) : (
    `Survey ${reviewId}`
  );

  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft>
          <CardHeaderTitle>{title}</CardHeaderTitle>
        </CardActionsLeft>
        <CardActionsRight>
          <PromiseButton
            iconRight={isActive ? ["far", "check-square"] : ["far", "square"]}
            css={{ width: "$24" }}
            size="normal"
            onClick={onToggleActive}
            color={isActive ? "success" : "danger"}
          >
            Active
          </PromiseButton>
          <Button icon="arrow-left" size="normal" color="brand" onClick={onGoBack}>
            Back to List
          </Button>
        </CardActionsRight>
      </CardActions>
    </Card>
  );
};
PageHeadBlock.displayName = "PageHeadBlock";

export default PageHeadBlock;
