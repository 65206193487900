import React from "react";

import Center from "../../../components/lib/Center";
import Icon from "../../../components/lib/Icon";
import {
  Checkbox,
  CheckboxIndicator,
  CheckedState,
} from "../../../components/lib/Checkbox";

import type {
  ContractorDataMap,
  ContractorsDataOrderedMap,
  ContractorsTableDataStateObject,
} from "../types";
import type { SelectAllRowsOnThePageHandler } from "../components/ContractorsTable";

type SelectRowFunc = (rowData: ContractorDataMap, isChecked: CheckedState) => void;

type CenterSelectCheckboxProps = {
  checked: boolean;
  onCheckedChange: (isChecked: CheckedState) => void;
};

function CenterSelectCheckbox(props: CenterSelectCheckboxProps) {
  const { checked, onCheckedChange } = props;

  return (
    <Center>
      <Checkbox
        checked={checked}
        onCheckedChange={onCheckedChange}
        css={{ display: "inline-flex" }}
      >
        <CheckboxIndicator>
          <Icon icon="check" />
        </CheckboxIndicator>
      </Checkbox>
    </Center>
  );
}

export function selectRowCheckbox(
  rowData: ContractorDataMap,
  selectedRows: ContractorsDataOrderedMap,
  onSelectRow: SelectRowFunc
) {
  const rowId = rowData.get("id");

  return (
    <CenterSelectCheckbox
      checked={selectedRows.get(rowId) != null}
      onCheckedChange={(isChecked) => onSelectRow(rowData, isChecked)}
    />
  );
}

export function selectAllRowsCheckbox(
  selectedRows: ContractorsDataOrderedMap,
  contractorsData: ContractorsTableDataStateObject,
  onSelectAllRowsOnThePage: SelectAllRowsOnThePageHandler
) {
  const selectedIds = selectedRows.keySeq().toSet();
  const availableIds = contractorsData.data.keySeq().toSet();
  const value = availableIds.isSubset(selectedIds);

  return (
    <CenterSelectCheckbox checked={value} onCheckedChange={onSelectAllRowsOnThePage} />
  );
}
