// @flow
import * as React from "react";
import graphql from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import { createPaginationContainer } from "react-relay";
import FilterAndSort from "./FilterAndSort";
import type { RelayPaginationProp } from "react-relay";
import type { FilterAndSortClientsFetchAllClientsQueryResponse } from "./__generated__/FilterAndSortClientsFetchAllClientsQuery.graphql";
import type { FilterAndSortClients_viewer } from "./__generated__/FilterAndSortClients_viewer.graphql";

type ClientFilter = {
  clientIdIn: Array<string>,
};

type Props = {
  open: boolean,
  currentFilter: ClientFilter | null,
  onClose: () => void,
  onApply: (ClientFilter | null) => void,
  +viewer: FilterAndSortClients_viewer | null,
  +relay: RelayPaginationProp,
};
type State = {
  refetching: boolean,
  page: number,
  disabled: boolean,
  searchText: string | null,
  selectAll: boolean,
  error: Object | null,
  selectedClients: ?Array<string>,
};

// Used when "getting all ids"
const FetchAllClientsQuery = graphql`
  query FilterAndSortClientsFetchAllClientsQuery($filters: ClientFiltersInput) {
    viewer {
      allClients(filters: $filters) {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;

class FilterAndSortClients extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      refetching: false,
      disabled: false,
      page: 0,
      selectAll: false,
      searchText: null,
      error: null,
      selectedClients: null,
    };
  }

  isItemSelected = (id: string) =>
    this.state.selectedClients
      ? this.state.selectedClients.indexOf(id) !== -1
      : this.props.currentFilter
      ? this.props.currentFilter.clientIdIn.indexOf(id) !== -1
      : false;

  handleApply = async () => {
    if (!this.state.selectedClients) {
      // Do nothing if selected clients is null
      return;
    }
    const filter: ClientFilter = {
      clientIdIn: this.state.selectedClients,
    };
    this.setState({ selectedClients: null }, () => {
      this.props.onApply(filter);
    });
  };

  handleToggleSelectAll = async () => {
    // If "All" selected get rid of them
    if (this.state.selectAll) {
      this.setState({ disabled: false, selectAll: false, selectedClients: [] });
    } else {
      const filters =
        this.state.searchText !== null ? { nameIContains: this.state.searchText } : {};
      this.setState({ disabled: true }, async () => {
        const data: FilterAndSortClientsFetchAllClientsQueryResponse = await fetchQuery(
          this.props.relay.environment,
          FetchAllClientsQuery,
          { filters }
        ).toPromise();
        this.setState({
          disabled: false,
          selectAll: true,
          selectedClients:
            data.viewer?.allClients?.edges
              ?.map((edge, index) => edge?.node?.id || "")
              .filter((id) => id !== "") || [],
        });
      });
    }
  };

  handleToggleSelect = async (id: string) => {
    let selectedClients =
      this.state.selectedClients?.slice() ||
      this.props.currentFilter?.clientIdIn.slice() ||
      [];
    const index = selectedClients.indexOf(id);
    const selected = index !== -1;
    if (selected) {
      selectedClients = selectedClients
        .slice(0, index)
        .concat(selectedClients.slice(index + 1, selectedClients.length));
    } else {
      selectedClients.push(id);
    }
    this.setState({
      selectAll: false,
      selectedClients,
    });
  };

  handleReset = () => {
    this.setState(
      {
        selectedClients: null,
      },
      () => {
        this.props.onApply(null);
      }
    );
  };

  handleClose = () => {
    this.setState(
      {
        selectedClients: null,
      },
      () => {
        this.props.onClose();
      }
    );
  };

  renderItem = (item: { id: string, name: string, clientId: number }) => {
    return (
      <div>
        {item.name} [#{item.clientId}]
      </div>
    );
  };

  render() {
    const loading = this.props.relay.isLoading() || this.state.refetching;
    const itemsPerPage = 10;

    const clients = this.props.viewer?.allClients?.edges || [];
    const page = clients.slice(
      itemsPerPage * this.state.page,
      Math.min(itemsPerPage * (this.state.page + 1), clients.length)
    );

    if (this.state.error) {
      return (
        <div>
          Error!: <pre>{this.state.error}</pre>
        </div>
      );
    }
    const Clients: Array<{ id: string, clientId: number, name: string }> =
      page.map((edge, index) => ({
        id: edge?.node?.id || `missing-id-${index}`,
        clientId: edge?.node?.clientId || -1 * index,
        name: edge?.node?.name || "[error: 'name']",
      })) || [];

    return (
      <FilterAndSort
        title="Clients"
        allSelected={this.state.selectAll}
        show={this.props.open}
        disabled={this.state.disabled}
        items={Clients}
        hasPrevPage={this.state.page > 0}
        onPageBackward={() => {
          this.setState({ page: this.state.page > 0 ? this.state.page - 1 : 0 });
        }}
        hasNextPage={
          (this.state.page + 1) * itemsPerPage < clients.length ||
          this.props.relay.hasMore()
        }
        onPageForward={() => {
          if (
            this.props.relay.hasMore &&
            (this.state.page + 1) * itemsPerPage > clients.length - itemsPerPage
          ) {
            console.log("loading more items");
            this.setState({ refetching: true }, () => {
              this.props.relay.loadMore(itemsPerPage, (error) => {
                if (error) {
                  console.error(error);
                }
                this.setState({ error, page: this.state.page + 1, refetching: false });
              });
            });
          } else {
            this.setState({ page: this.state.page + 1 });
          }
        }}
        renderItem={this.renderItem}
        loading={loading}
        onSearch={async (searchText: string) => {
          console.log("start search");
          this.setState({ refetching: true, selectAll: false, searchText }, () => {
            this.props.relay.refetchConnection(
              20,
              (error) => {
                console.log("search finished refetch");
                if (error) {
                  console.error(error);
                }
                this.setState({ error, page: 0, refetching: false });
              },
              {
                filters: {
                  nameIContains: searchText,
                },
              }
            );
          });
        }}
        selectedItems={this.state.selectedClients || this.props.currentFilter?.clientIdIn}
        isItemSelected={(client) => this.isItemSelected(client.id)}
        onApply={this.handleApply}
        onToggleSelectAll={this.handleToggleSelectAll}
        onToggleSelectItem={this.handleToggleSelect}
        onReset={this.handleReset}
        onClose={this.handleClose}
      />
    );
  }
}

export default createPaginationContainer(
  FilterAndSortClients,
  {
    viewer: graphql`
      fragment FilterAndSortClients_viewer on Viewer
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        cursor: { type: "String" }
        filters: { type: "ClientFiltersInput", defaultValue: {} }
        order: {
          type: "[ClientSortInput]"
          defaultValue: [{ direction: ASC, field: NAME }]
        }
      ) {
        allClients(first: $count, after: $cursor, filters: $filters, order: $order)
          @connection(key: "FilterAndSortClients_allClients", filters: ["filters"]) {
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
          edges {
            node {
              id
              clientId
              name
            }
          }
        }
      }
    `,
  },
  {
    direction: "forward",
    // getConnectionFromProps(props: Props) {
    //   return props.viewer?.allClients;
    // },
    // getFragmentVariables(prevVars, totalCount) {
    //   return {
    //     ...prevVars,
    //     count: totalCount
    //   };
    // },
    getVariables(props: Props, { count, cursor }, fragmentVariables) {
      return {
        count,
        cursor,
        filters: fragmentVariables.filters,
        order: fragmentVariables.order,
      };
    },
    query: graphql`
      query FilterAndSortClientsQuery(
        $filters: ClientFiltersInput
        $count: Int
        $cursor: String
      ) {
        viewer {
          ...FilterAndSortClients_viewer
            @arguments(filters: $filters, count: $count, cursor: $cursor)
        }
      }
    `,
  }
);
