// @flow

import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import ResultsByLevelChart from "./ResultsByLevelChart";
import LoadingIndicator from "../../ratecards/components/LoadingIndicator";

const RatesByLevelSection = (props) => {
  let store = props.store;
  return (
    <div className="col-lg-5 col-md-12">
      <div className="panel panel-default">
        <div className="panel-heading">
          <h3 className="panel-title">Average Rate by Level</h3>
        </div>
        <div className="panel-body">
          <div style={{ position: "relative", minHeight: 446 }}>
            {!store.network.loading && <ResultsByLevelChart search={store.search} />}
            <div
              className={classNames({
                "loading-indicator-container": true,
                show: store.network.loading,
              })}
            >
              <LoadingIndicator
                style={{
                  minHeight: 446,
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(RatesByLevelSection);
