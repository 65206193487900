import React from "react";

import {
  getQuestionsFeedbackFromAttempt,
  getByLevelRatesFeedbackFromAttempt,
  getAbsRatesFeedbackFromAttempt,
} from "../utils";

import type {
  AttemptDataMap,
  AttemptDataObject,
  JobFeedbackDataMap,
  QuestionsFeedbackOrderedMap,
  RatesFeedbackOrderedMap,
} from "../types";
import { useReviewDataContext } from "../../validator5K_admin/context/ReviewDataContext";

type FetchAttemptDataFunc = () => Promise<AttemptDataMap | undefined>;
type updateAttemptDataFunc = (
  attemptId: number,
  data: Partial<AttemptDataObject>
) => Promise<AttemptDataMap | undefined>;
type updateAttemptDataStateFunc = (data: AttemptDataMap) => void;

export type AttemptDataContextObject = {
  attemptData: AttemptDataMap;
  attemptId: number;
  ratesFeedback: RatesFeedbackOrderedMap; // per exp level rate feedback (classic and ordered surveys)
  jobFeedback: JobFeedbackDataMap; // abs low/hight rates feedback ("fill in the blank" surveys)
  questionsFeedback: QuestionsFeedbackOrderedMap; // all questions feedback
  fetchAttemptData: FetchAttemptDataFunc; // re-fetch attempt data and save to the app state
  updateAttemptData: updateAttemptDataFunc; // update attempt data on the server and save it to the app state
  updateAttemptDataState: updateAttemptDataStateFunc; // update app state only
};

export const AttemptDataContext = React.createContext<AttemptDataContextObject>({
  attemptData: undefined,
  attemptId: undefined,
  ratesFeedback: undefined,
  jobFeedback: undefined,
  questionsFeedback: undefined,
  fetchAttemptData: undefined,
  updateAttemptData: undefined,
  updateAttemptDataState: undefined,
} as unknown as AttemptDataContextObject);

export const useAttemptDataContext = () => React.useContext(AttemptDataContext);

type AttemptDataContextProviderProps = {
  attemptData: AttemptDataMap;
  fetchAttemptData: FetchAttemptDataFunc;
  updateAttemptData: updateAttemptDataFunc;
  updateAttemptDataState: updateAttemptDataStateFunc;
};

export const AttemptDataContextProvider = (
  props: React.PropsWithChildren<AttemptDataContextProviderProps>
) => {
  const {
    attemptData,
    fetchAttemptData,
    updateAttemptData,
    updateAttemptDataState,
    children,
  } = props;
  const { questionsData, firstJobId } = useReviewDataContext();

  const contextValues = React.useMemo(
    () => ({
      attemptData,
      attemptId: attemptData.get("id"),
      ratesFeedback: getByLevelRatesFeedbackFromAttempt(attemptData, firstJobId),
      jobFeedback: getAbsRatesFeedbackFromAttempt(attemptData, firstJobId),
      questionsFeedback: getQuestionsFeedbackFromAttempt(attemptData, questionsData),
      fetchAttemptData,
      updateAttemptData,
      updateAttemptDataState,
    }),
    [
      attemptData,
      fetchAttemptData,
      updateAttemptData,
      updateAttemptDataState,
      questionsData,
      firstJobId,
    ]
  );

  return (
    <AttemptDataContext.Provider value={contextValues}>
      {children}
    </AttemptDataContext.Provider>
  );
};

AttemptDataContextProvider.displayName = "AttemptDataContextProvider";

export default AttemptDataContext;
