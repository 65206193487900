import React, { ReactElement } from "react";
// lib
import Stack from "../../components/lib/Stack";
import Text from "../../components/lib/Text";
import Icon from "../../components/lib/Icon";
import { Link } from "../../components/lib/Link";
import { NotFound } from "../../utils/errorTypes";
import { withErrorCatch } from "../../utils/withErrorCatch";
import { useGlobalContext } from "../../globalContext";

type Props = {
  error: NotFound | null;
  children: ReactElement;
};

function ErrorBoundary({ error, children }: Props) {
  const { router, showModalError } = useGlobalContext();
  if (error) {
    router.replace("/rate-search");
    setTimeout(() => {
      showModalError(error.message);
    }, 500);

    return (
      <Stack
        css={{
          flex: 1,
          minHeight: "50vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text as="h3" color="negative">
          <Icon icon="exclamation-triangle" /> {error.message}
        </Text>
        <Link to="/rate-search">Go to Rate Search</Link>
      </Stack>
    );
  }

  return children;
}

export const NotFoundBoundary = withErrorCatch<NotFound>(
  ErrorBoundary,
  (error) => error instanceof NotFound
);
