import { action } from "mobx";
import { MODULE_MAP } from "./Filter";

export default class ContentTagList {
  constructor(store, object) {
    this.store = store;
    this.id = null;
    this.type = null;
    this.name = null;
    this.link = null;
    this.tagContentId = null;

    // providing options for selecting and editing
    this.viewState = {
      selected: false,
      editing: false,
    };

    if (object) {
      this.tagContentId = object.tagContentId;
      let objectContent = object.contents;
      let key = MODULE_MAP[objectContent.__typename].id;
      this.id = objectContent[key];
      if (objectContent.__typename === "SearchTagNode") {
        this.name = objectContent.job.jobTitle + " - " + objectContent.job.jobLabel;
      } else {
        this.name = objectContent[MODULE_MAP[objectContent.__typename].column];
      }
      this.type = MODULE_MAP[objectContent.__typename].type;
      this.link = MODULE_MAP[objectContent.__typename].link;
    }

    this.toggleEdit = action(this.toggleEdit.bind(this));
    this.toggleSelected = action(this.toggleSelected.bind(this));
    this.destroy = action(this.destroy.bind(this));
  }

  toggleEdit() {
    const viewState = this.store.tagDetailListViewState.get(this.tagContentId);
    viewState.editing = !viewState.editing;
  }

  toggleSelected(e, ignore1 = null, ignore2 = null, setValue = null) {
    const viewState = this.store.tagDetailListViewState.get(this.tagContentId);

    if (setValue !== null) {
      viewState.selected = setValue;
      return;
    } else {
      viewState.selected = !viewState.selected;

      if (!viewState.selected && this.store.allSelected) {
        this.store.allSelected = false;
      }
    }

    if (viewState.selected === false) {
      this.store.unSelectedTagContent.push(this.tagContentId);
    } else {
      for (var i = 0; i < this.store.unSelectedTagContent.length; i++) {
        if (this.store.unSelectedTagContent[i] === this.tagContentId) {
          this.store.unSelectedTagContent.splice(i, 1);
        }
      }
    }

    if (!this.store.allowMultipleItemSelection) {
      // deselect all other jobs
      this.store.tagDetailListViewState.forEach((viewState) => {
        if (this.viewState === viewState) return;

        viewState.selected = false;
      });
    }
  }

  destroy() {
    this.store.tagDetailList.remove(this);
  }

  toJS() {
    return {
      id: this.tagContentId,
      name: this.name,
      type: this.type,
    };
  }

  static fromJS(store, object) {
    return new ContentTagList(store, object);
  }
}
