import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "./Spinner";
import { config, css, styled } from "../../stitches.config";
import type * as Stitches from "@stitches/react";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import Button from "./Button";

export function isFiniteNumber(value: any) {
  const parsedValue = parseFloat(value);
  return Number.isFinite(parsedValue) && !Number.isNaN(parsedValue);
}

export const inputBorderStyle = css({
  borderRadius: "$rounded",
  border: "2px solid $primaryLight",
  transitionProperty: "background-color, border-color, color, fill, stroke",
  transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
  transitionDuration: "150ms",
  "&:hover, &:focus": {
    outline: "none",
    boxShadow: "none",
  },
});

const Wrapper = styled("div", inputBorderStyle, {
  position: "relative",
  overflow: "hidden",
  display: "inline-flex",
  alignItems: "center",
  borderWidth: 1,
  "& input": {
    flex: "1 1 0%",
    minWidth: 35,
    backgroundColor: "transparent",
    border: "none",
    padding: 0,
    margin: 0,
    "&:hover, &:focus": {
      outline: "none",
      boxShadow: "none",
    },
  },
  variants: {
    color: {
      primary: {
        backgroundColor: "transparent",
        borderColor: "$primaryLight",
        color: "$primaryDarker",
        "input::placeholder, #numberInputIcon, #numberInputIconRight": {
          color: "$primary",
        },
        "&:focus-within": {
          borderColor: "$brand",
          "#numberInputIcon, #numberInputIconRight": {
            color: "$primaryDarker",
          },
        },
        // "#numberInputIconButton, #numberInputIconRightButton": {
        //   color: "$textLight"
        // },
      },
      danger: {
        backgroundColor: "$ptRed25",
        borderColor: "$ptRed300",
        color: "$ptRed400",
        input: {
          color: "$ptRed800",
          "&::placeholder": {
            color: "$ptRed400",
          },
        },
        "&:focus-within": {
          color: "$ptRed600",
          borderColor: "$ptRed500",
        },
      },
    },
    size: {
      "extra-small": {
        gap: "$2",
        padding: "$1 $2",
        fontSize: "$xs",
        lineHeight: "$xs",
        "& input": {
          fontSize: "$xs",
          lineHeight: "$xs",
        },
      },
      small: {
        gap: "$2_5",
        padding: "$1_5 $2_5",
        fontSize: "$sm",
        lineHeight: "$sm",
        "& input": {
          fontSize: "$sm",
          lineHeight: "$sm",
        },
      },
      normal: {
        gap: "$3",
        padding: "calc($2 + 1px) calc($3 + 1px)",
        fontSize: "$base",
        lineHeight: "$base",
        "& input": {
          fontSize: "$base",
          lineHeight: "$base",
        },
        "#numberInputIconButton": {
          padding: "$2_5",
          margin: "-$2_5 0 -$2_5 -$2_5",
        },
        "#numberInputIconRightButton": {
          padding: "$2_5",
          margin: "-$2_5 -$2_5 -$2_5 0",
        },
      },
      large: {
        gap: "$3_5",
        padding: "$2_5 $4",
        fontSize: "$lg",
        lineHeight: "$lg",
        "& input": {
          fontSize: "$lg",
          lineHeight: "$lg",
        },
      },
      "extra-large": {
        gap: "$4",
        padding: "$3 $4",
        fontSize: "$2xl",
        lineHeight: "$2xl",
        "& input": {
          fontSize: "$2xl",
          lineHeight: "$2xl",
        },
      },
    },
    fill: {
      true: {
        width: "100%",
      },
    },
    disabled: {
      true: {
        opacity: 0.55,
        backgroundColor: "$primaryLighter",
      },
    },
  },
  defaultVariants: {
    color: "primary",
    size: "normal",
    fill: false,
    disabled: false,
  },
});

interface UtilInputProps {
  css?: Stitches.CSS<typeof config>;
  loading?: boolean;
  // NOTE: We use IconName over IconProp (more correct) to help fix autocomplete usability but think we're hitting some vscode bugs still
  icon?: IconName;
  iconClicked?: React.MouseEventHandler<HTMLButtonElement>;
  iconRight?: IconName;
  iconRightClicked?: React.MouseEventHandler<HTMLButtonElement>;
}

type InputVariants = Stitches.VariantProps<typeof Wrapper>;
type InputWithoutAttributeSize = Omit<React.ComponentPropsWithRef<"input">, "size">;
export type InputProps = InputWithoutAttributeSize & InputVariants & UtilInputProps;

const NumberInput = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    id,
    type,
    name,
    role,
    value,
    placeholder,
    min,
    disabled,
    loading,
    icon,
    iconRight,
    iconClicked,
    iconRightClicked,
    size,
    onKeyDown,
    onChange,
    ...rest
  } = props;

  return (
    <Wrapper size={size} disabled={disabled} {...rest}>
      {icon && !iconClicked && <FontAwesomeIcon id="numberInputIcon" icon={icon} />}
      {icon && iconClicked && (
        <Button
          id="numberInputIconButton"
          color="brand"
          icon={icon}
          onClick={iconClicked}
        />
      )}
      <input
        ref={ref}
        type="number"
        id={id}
        name={name}
        role={role}
        disabled={disabled}
        min={min}
        placeholder={placeholder}
        value={value}
        onKeyDown={onKeyDown}
        onChange={onChange}
      />
      {loading && <Spinner size={size} />}
      {iconRight && !iconRightClicked && !loading && (
        <FontAwesomeIcon id="numberInputIconRight" icon={iconRight} />
      )}
      {iconRight && iconRightClicked && !loading && (
        <Button
          id="numberInputIconRightButton"
          color="brand"
          icon={iconRight}
          onClick={iconRightClicked}
        />
      )}
    </Wrapper>
  );
});

export default NumberInput;
