import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Tippy from "@tippyjs/react";
import { Row, Col } from "react-bootstrap";

import Text from "../../components/lib/Text";

export default class Card extends React.Component {
  render() {
    return (
      <div {...this.props} className={classNames("pt-card", this.props.className)}>
        {this.props.children}
      </div>
    );
  }
}

export class CardHeading extends React.Component {
  static propTypes = {
    titleClassName: PropTypes.any,
  };

  render() {
    const { title, tooltip, controls } = this.props;

    const heading = tooltip ? (
      <div className={classNames("pt-card__heading", this.props.className)}>
        {controls && <div className="pt-card__heading__controls">{controls}</div>}
        <Tippy
          placement="right"
          interactive={true}
          content={
            <div
              style={{
                maxHeight: "300px",
                overflowY: "auto",
                fontSize: "14px",
                textAlign: "left",
                padding: "5px",
                minWidth: "300px",
                maxWidth: "450px",
              }}
              dangerouslySetInnerHTML={{ __html: tooltip }}
            />
          }
        >
          {title && (
            <Text as="h3" className={classNames(this.props.titleClassName)}>
              {title}
            </Text>
          )}
        </Tippy>
      </div>
    ) : (
      <div className={classNames("pt-card__heading", this.props.className)}>
        {controls && <div className="pt-card__heading__controls">{controls}</div>}
        {title && (
          <Text as="h3" className={classNames(this.props.titleClassName)}>
            {title}
          </Text>
        )}
      </div>
    );

    return (
      <Row>
        <Col sm={6}>{heading}</Col>
      </Row>
    );
  }
}

export class CardContent extends React.Component {
  render() {
    return <div />;
  }
}

export class CardListGroup extends React.Component {
  render() {
    return (
      <ul
        {...this.props}
        className={classNames("pt-card__list-group", this.props.className)}
      >
        {this.props.children}
      </ul>
    );
  }
}

export class CardListGroupItem extends React.Component {
  static propTypes = {
    noPadding: PropTypes.bool,
    noBorder: PropTypes.bool,
  };

  static defaultProps = {
    noPadding: false,
    noBorder: false,
  };

  render() {
    const { children, className } = this.props;
    return (
      <li
        className={classNames(
          "pt-card__list-group__item",
          {
            "pt-card__list-group__item--no-padding": this.props.noPadding,
            "pt-card__list-group__item--no-border": this.props.noBorder,
          },
          className
        )}
      >
        {children}
      </li>
    );
  }
}
