import { css, styled } from "../../stitches.config";

export const inputBorderStyle = css({
  borderRadius: "$rounded",
  border: "2px solid $primaryLight",
  transitionProperty: "background-color, border-color, color, fill, stroke",
  transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
  transitionDuration: "150ms",
  "&:hover, &:focus": {
    outline: "none",
    boxShadow: "none",
  },
});

const HTMLSelect = styled("select", inputBorderStyle, {
  minWidth: 35,
  backgroundColor: "transparent",
  borderWidth: 1,
  "&:hover, &:focus": {
    outline: "none",
    boxShadow: "none",
  },
  variants: {
    color: {
      primary: {
        backgroundColor: "transparent",
        borderColor: "$primaryLight",
        color: "$primaryDarker",
        "&:focus-within": {
          borderColor: "$brand",
        },
      },
      danger: {
        backgroundColor: "transparent",
        borderColor: "$danger",
        color: "$primaryDarker",
        "&:focus-within": {
          borderColor: "$brand",
        },
      },
    },
    size: {
      "extra-small": {
        padding: "$1 $2",
        fontSize: "$xs",
        lineHeight: "$xs",
      },
      small: {
        padding: "$1_5 $2_5",
        fontSize: "$sm",
        lineHeight: "$sm",
      },
      normal: {
        padding: "calc($2 + 2px) calc($3 + 1px)",
        fontSize: "$base",
        lineHeight: "$base",
      },
      large: {
        padding: "$2_5 $4",
        fontSize: "$lg",
        lineHeight: "$lg",
      },
      "extra-large": {
        padding: "$3 $4",
        fontSize: "$2xl",
        lineHeight: "$2xl",
      },
    },
    fill: {
      true: {
        width: "100%",
      },
    },
    disabled: {
      true: {
        opacity: 0.55,
        backgroundColor: "$primaryLighter",
      },
    },
    deep: {
      true: {
        backgroundColor: "$secondaryLightest",
        borderColor: "$secondaryLightest",
      },
    },
  },
  defaultVariants: {
    color: "primary",
    size: "normal",
    fill: false,
    disabled: false,
  },
});

HTMLSelect.displayName = "HTMLInput";

export default HTMLSelect;
