// @flow
import React, { Component } from "react";
import AsyncSelect from "react-select/lib/Async";
import { observer } from "mobx-react";
import { reactSelectStyles, reactSelectTheme } from "../../constants/reactSelect";

type Value = {
  value: number,
  label: string,
  apiLocationId: number | string,
};

type Props = {
  CountryTitle: (string) => Promise<any>,
  onChange: ({ id: number, title: string, apiLocationId: number | string }) => void,
  id?: string,
};

class CountrySelectNew extends Component<Props> {
  getOptions = (input: string, callback: (Array<Value>) => void) => {
    var countryList = [];
    this.props
      .CountryTitle(input)
      .then((response) => {
        response.countries.forEach((e) => {
          var optionItem = {};
          optionItem["value"] = e.node.locationId;
          optionItem["label"] = e.node.name.toString();
          optionItem["apiLocationId"] = parseInt(e.node.apiLocationId, 10) || "";
          countryList.push(optionItem);
        });
        callback(countryList);
      })
      .catch(function (error) {
        callback(error);
      });
  };
  handleChange = (value: Value, actionMeta: Object) => {
    const countryTitle = {
      id: value.value,
      title: value.label,
      apiLocationId: value.apiLocationId || "",
    };
    this.props.onChange(countryTitle);
  };
  render() {
    return (
      <AsyncSelect
        inputId={this.props.id || "countrySelect"}
        name="country"
        placeholder="Country"
        noOptionsMessage={(inputValue) => "No countries"}
        loadOptions={this.getOptions}
        backspaceRemoves={true}
        defaultOptions
        onChange={this.handleChange}
        openOnFocus={true}
        styles={reactSelectStyles}
        theme={reactSelectTheme}
      />
    );
  }
}

export default observer(CountrySelectNew);
