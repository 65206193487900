// @flow
import React, { Component } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";

import SortIndicator from "../../ratecards/components/SortIndicator";
import { FILTER_COLUMN } from "../../../models/Filter";
import Pagination from "../../../components/lib/Pagination";
import LoadingIndicator from "../../shared/LoadingIndicator";
import PunchoutsListItem from "./PunchoutsListItem";
import { SingleDatePicker } from "../../../components/SingleDatePicker";
import CreatedOnFilter from "../../filters/CreatedOnFilter";
import { PunchoutsListComponentStore } from "../../../stores/mobx/PunchoutsListStore";
import {
  CardFilters,
  CardFilter,
  CardAlert,
  CardAlertLink,
} from "../../../components/lib/Card";
import { InlineElements } from "../../../components/lib/Inline";
import Button from "../../../components/lib/Button";

type Props = {
  store: PunchoutsListComponentStore,
  fixedHeight?: number,
  fillContainer?: boolean,
};

class PunchoutsList extends Component<Props> {
  changeCurrent: (number) => void;

  constructor(props: Props) {
    super(props);

    this.changeCurrent = this.changeCurrent.bind(this);
  }

  changeCurrent(value: number) {
    let store: PunchoutsListComponentStore = this.props.store;
    store.pagination.handleCurrentPage(value);
  }

  componentDidMount() {
    this.props.store.pagination.handleCurrentPage(0);
  }

  /**
   * componentWillUnmount
   * @return {void}
   */
  componentWillUnmount() {
    this.props.store.clearFilters();
    this.props.store.clearAllSelections();
    this.props.store.applyDefaultSort();
  }

  render() {
    const store: PunchoutsListComponentStore = this.props.store;
    const network = store.network;
    const fillContainer = this.props.fillContainer;
    const fixedHeight = this.props.fixedHeight;
    const maximumDate = new Date();

    let listBodyStyles = {};
    if (fixedHeight) {
      listBodyStyles.height = fixedHeight;
      listBodyStyles.overflowY = "auto";
    }

    let selectAllSection = null;
    let selectAllOnPageSection = (
      <InlineElements>
        <Button onClick={store.selectAllOnPageItem}>Select All</Button>
        <Button onClick={store.deselectAllPage}>Deselect All</Button>
      </InlineElements>
    );

    if (store.allOnPageSelected) {
      selectAllSection = (
        <CardAlert>
          <span>
            All &nbsp;
            <strong>{store.punchoutCounts}</strong>
            &nbsp; Punchouts selected.{" "}
          </span>
          &nbsp;
          <CardAlertLink key="clear-selections-link" onClick={store.clearAllSelections}>
            Clear all selections
          </CardAlertLink>
        </CardAlert>
      );
    }
    if (store.allOnPageSelected && !store.allSelected) {
      selectAllSection = (
        <CardAlert>
          <span>All Punchouts on this page selected. &nbsp;</span>
          &nbsp;
          <CardAlertLink key="select-all-item" onClick={store.selectAllPage}>
            Select all &nbsp;
            <strong>{store.punchoutCounts}</strong>
            &nbsp; Punchouts.
          </CardAlertLink>
          &nbsp;&nbsp;
          <CardAlertLink key="clear-selections-link" onClick={store.clearAllSelections}>
            Clear all selections
          </CardAlertLink>
        </CardAlert>
      );
    }

    let tableLeftActionBar = null;
    if (store.isEditing) {
      tableLeftActionBar = (
        <nav className="header-left-nav">
          {store.allowMultipleItemSelection && selectAllOnPageSection}
        </nav>
      );
    }

    const punchouts = store.buyRatesView;
    let tableContent = null;
    if (network.loading) {
      tableContent = <LoadingIndicator />;
    } else if (network.error) {
      // TODO: replace this with a more friendly error message
      tableContent = (
        <div className="pt-error-message">
          <h2>Error</h2>
          <pre>
            <code>{JSON.stringify(network.error, null, 2)}</code>
          </pre>
        </div>
      );
    } else if (punchouts.length === 0) {
      tableContent = (
        <div className="ratecards-table-empty">
          {Object.keys(store.appliedFilters).length > 0 ? (
            <div>No Punchout match your current filters</div>
          ) : (
            <div>
              No Punchouts
              <p>Create a Punchout from the search detail page.</p>
            </div>
          )}
        </div>
      );
    } else {
      if (store.isEditing) {
        tableContent = punchouts.map((punchout) => (
          <PunchoutsListItem
            key={punchout.id}
            store={store}
            punchout={punchout}
            editing={store.isEditing}
            expandable={store.allowExpand}
            expanded={punchout.viewState.expanded}
            itemClick={(e) => store.toggleSelected(punchout)}
          />
        ));
      } else {
        tableContent = punchouts.map((punchout) => (
          <PunchoutsListItem
            key={punchout.id}
            store={store}
            punchout={punchout}
            editing={store.isEditing}
            expandable={store.allowExpand}
            expanded={punchout.viewState.expanded}
            itemClick={(e) => store.toggleExpanded(punchout)}
          />
        ));
      }
    }

    var fromDate = (
      <SingleDatePicker
        id="from_date"
        date={store.createdOnFilter.fromDate}
        numberOfMonths={1}
        isOutsideRange={(day) => day.isAfter(maximumDate)}
        focused={store.createdOnFilter.fromFocused}
        onDateChange={store.createdOnFilter.fromDateChange}
        onFocusChange={store.createdOnFilter.fromFocusedChange}
      />
    );

    var toDate = (
      <SingleDatePicker
        id="to_date"
        date={store.createdOnFilter.toDate}
        numberOfMonths={1}
        isOutsideRange={(day) => store.toDateRange(day)}
        focused={store.createdOnFilter.toFocused}
        onDateChange={store.createdOnFilter.toDateChange}
        onFocusChange={store.createdOnFilter.toFocusedChange}
      />
    );

    return (
      <>
        {store.createdOnFilter && (
          <CreatedOnFilter
            createdOnFilter={store.createdOnFilter}
            fromDate={fromDate}
            toDate={toDate}
          />
        )}

        {
          <CardFilters filtered={store.isFiltered}>
            {tableLeftActionBar}
            <InlineElements>
              {store.isFiltered && (
                <Button color="accent" onClick={store.clearFilters}>
                  Clear All Filters & Sorts
                </Button>
              )}
              {store.createdOnFilter && (
                <CardFilter
                  name="rate-cards-filter"
                  value={FILTER_COLUMN.PUNCHOUTS_DATE_RANGE}
                  filtered={Boolean(
                    store.appliedFilters[FILTER_COLUMN.PUNCHOUTS_DATE_RANGE]
                  )}
                  onClick={store.createdOnFilter.onShowModal}
                >
                  <SortIndicator
                    sortType="numeric"
                    sortColumn={FILTER_COLUMN.PUNCHOUTS_DATE_RANGE}
                    sortsOrder={store.appliedSortsOrder}
                    sort={store.appliedSorts[FILTER_COLUMN.PUNCHOUTS_DATE_RANGE]}
                  />
                  Created On
                  <span> ▼</span>
                </CardFilter>
              )}
            </InlineElements>
          </CardFilters>
        }
        <div
          className={classNames("ratecards-table", {
            "ratecards-table-fill-container": fillContainer,
          })}
        >
          <div style={listBodyStyles}>
            {selectAllSection}
            {tableContent}
          </div>
        </div>
        {store.pagination.pageCount > 0 ? (
          <Pagination
            options={{
              variant: "full",
              currentPage: store.pagination.currentPage,
              numPages: store.pagination.pageCount,
            }}
            onPageClick={(pageEvent) => {
              if (pageEvent.type === "first") {
                this.changeCurrent(1);
              }
              if (pageEvent.type === "last") {
                this.changeCurrent(store.pagination.pageCount);
              }
              if (pageEvent.type === "next") {
                this.changeCurrent(
                  Math.min(store.pagination.currentPage + 1, store.pagination.pageCount)
                );
              }
              if (pageEvent.type === "prev") {
                this.changeCurrent(Math.max(store.pagination.currentPage - 1, 1));
              }
              if (pageEvent.type === "page") {
                this.changeCurrent(pageEvent.page);
              }
            }}
          />
        ) : (
          ""
        )}
      </>
    );
  }
}

export default observer(PunchoutsList);
