/**
 * @generated SignedSource<<bf3c0d7796fcac21398d75cc3e0d2ad5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TrapsRequestTransferResourceInput = {
  amount: number;
  destination: string;
  message?: string | null;
  source: string;
};
export type RequestMoreDialogMutation$variables = {
  request: TrapsRequestTransferResourceInput;
};
export type RequestMoreDialogMutation$data = {
  readonly trapsRequestTransferResource: {
    readonly result: {
      readonly __typename: "TrapsTransferRequest";
      readonly amount: number;
      readonly uuid: string;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null;
  } | null;
};
export type RequestMoreDialogMutation = {
  response: RequestMoreDialogMutation$data;
  variables: RequestMoreDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "request"
      }
    ],
    "concreteType": "TrapsRequestTransferResourcePayload",
    "kind": "LinkedField",
    "name": "trapsRequestTransferResource",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "result",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "uuid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "amount",
                "storageKey": null
              }
            ],
            "type": "TrapsTransferRequest",
            "abstractKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RequestMoreDialogMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RequestMoreDialogMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fb88a8f557440e1b57144e4a8ad26fd5",
    "id": null,
    "metadata": {},
    "name": "RequestMoreDialogMutation",
    "operationKind": "mutation",
    "text": "mutation RequestMoreDialogMutation(\n  $request: TrapsRequestTransferResourceInput!\n) {\n  trapsRequestTransferResource(input: $request) {\n    result {\n      ... on TrapsTransferRequest {\n        uuid\n        amount\n      }\n      __typename\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "eb511049c8944c25b4d4da8d30cfc19f";

export default node;
