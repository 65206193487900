// @flow
// eslint-disable-next-line
import * as React from "react";
import Stack from "../components/lib/Stack";
import Box from "../components/lib/Box";
import Center from "./lib/Center";
import Inline from "./lib/Inline";

function LearningCenter(props) {
  const styleStackDiv = {
    display: "flex",
    alignItems: "start",
    gap: "$4",
    flex: "48%",
    marginBottom: "40px",
  };
  return (
    <Box fill>
      <Center css={{ padding: "40px 0" }}>
        <h2>Learning Center</h2>
      </Center>

      <Inline>
        <Stack fill css={styleStackDiv}>
          <h3>Pay Intel – Dashboard Tutorial</h3>
          <iframe
            title="Pay Intel – Dashboard Tutorial"
            src="https://player.vimeo.com/video/787042359?h=aaebf6796f&badge=0&autopause=0&player_id=0&app_id=58479"
            width="640"
            height="375"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />
        </Stack>

        <Stack fill css={styleStackDiv}>
          <h3>Pay Intel – Rate Search</h3>
          <iframe
            title="Pay Intel – Rate Search"
            src="https://player.vimeo.com/video/787044074?h=e4eba17662&badge=0&autopause=0&player_id=0&app_id=58479"
            width="640"
            height="375"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />
        </Stack>

        <Stack fill css={styleStackDiv}>
          <h3>Pay Intel – Rate Cards</h3>
          <iframe
            title="Pay Intel – Rate Cards"
            src="https://player.vimeo.com/video/787042983?h=921c639a88&badge=0&autopause=0&player_id=0&app_id=58479"
            width="640"
            height="375"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />
        </Stack>

        <Stack fill css={styleStackDiv}>
          <h3>Pay Intel – Job Library</h3>
          <iframe
            title="Pay Intel – Job Library"
            src="https://player.vimeo.com/video/787042453?h=897779b81b&badge=0&autopause=0&player_id=0&app_id=58479"
            width="640"
            height="375"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />
        </Stack>

        <Stack fill css={styleStackDiv}>
          <h3>Pay Intel – Batch Search</h3>
          <iframe
            title="Pay Intel – Batch Search"
            src="https://player.vimeo.com/video/787041589?h=14f6766ab9&badge=0&autopause=0&player_id=0&app_id=58479"
            width="640"
            height="375"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />
        </Stack>
      </Inline>
    </Box>
  );
}

export default LearningCenter;
