import React, { useCallback, useState } from "react";
// lib
import Box from "../../../../components/lib/Box";
import { Autocomplete } from "../../../../components/lib/Autocomplete";
// hooks
import { useRateSearchFormStore } from "../../../../stores/RateSearchForm";
import { useFetchCountryLibrary } from "./hooks";
import { CountryLibrary } from "../../types";

type Props = {
  onResetJobOptions(): void;
};

export function CountryLibraryField(props: Props) {
  const { onResetJobOptions } = props;
  const setLibrary = useRateSearchFormStore((s) => s.setLibrary);
  const countryLibrary = useRateSearchFormStore((s) => s.countryLibrary);
  const [value, setValue] = useState(countryLibrary?.title ?? "");
  const { loading, options } = useFetchCountryLibrary();

  const onResetOptions = useCallback(() => {
    setLibrary(null, null);
    onResetJobOptions();
    setValue("");
  }, [setLibrary, onResetJobOptions, setValue]);

  const onSelect = useCallback(
    (lib: CountryLibrary | null) => {
      setLibrary(null, lib);
      onResetJobOptions();
      if (lib) setValue(lib.title);
    },
    [setLibrary, onResetJobOptions, setValue]
  );

  return (
    <Box css={{ width: "100%" }}>
      <Autocomplete
        icon="globe-americas"
        loading={loading}
        id="country-library-select"
        selected={countryLibrary}
        options={options}
        onResetOptions={onResetOptions}
        onSelect={onSelect}
        label="Select a country library"
        placeholder="Select a library"
        readOnly
        getKey={(o) => String(o.databaseId)}
        renderOption={(o) => o.title}
        inputValue={value}
        onInputChange={(e) => setValue(e.target.value)}
      />
    </Box>
  );
}
