/**
 * @generated SignedSource<<de38580ecd2a5b79b74c0cb793e5b883>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminSearchesSearch_search$data = {
  readonly city: string | null;
  readonly country: string;
  readonly createdBy: {
    readonly firstName: string | null;
    readonly lastName: string | null;
    readonly userId: number;
    readonly username: string;
  } | null;
  readonly createdDate: string;
  readonly currency: {
    readonly symbol: string | null;
  } | null;
  readonly id: string;
  readonly isDraft: boolean | null;
  readonly isGlobalSupplierSearch: boolean | null;
  readonly job: {
    readonly id: string;
    readonly jobLabel: string;
    readonly jobTitle: string | null;
    readonly jobTitleId: number | null;
  };
  readonly marketRate: {
    readonly billRate: number | null;
    readonly markupPct: number | null;
    readonly payRate: number | null;
  } | null;
  readonly rateType: number | null;
  readonly region: {
    readonly name: string;
  } | null;
  readonly searchId: number | null;
  readonly state: string | null;
  readonly tags: ReadonlyArray<{
    readonly name: string;
    readonly tagId: number;
  } | null> | null;
  readonly " $fragmentType": "AdminSearchesSearch_search";
};
export type AdminSearchesSearch_search$key = {
  readonly " $data"?: AdminSearchesSearch_search$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminSearchesSearch_search">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminSearchesSearch_search",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "searchId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LegacyUserNode",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "JobResponse",
      "kind": "LinkedField",
      "name": "job",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jobTitleId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jobTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jobLabel",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "country",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "NewRegionNode",
      "kind": "LinkedField",
      "name": "region",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rateType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CurrencyNode",
      "kind": "LinkedField",
      "name": "currency",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "symbol",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MarketRateNode",
      "kind": "LinkedField",
      "name": "marketRate",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "payRate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "billRate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "markupPct",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TagNode",
      "kind": "LinkedField",
      "name": "tags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tagId",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDraft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isGlobalSupplierSearch",
      "storageKey": null
    }
  ],
  "type": "SaveSearchNode",
  "abstractKey": null
};
})();

(node as any).hash = "cc040c9b57c8fb888d791ca51d7feeff";

export default node;
