import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";

import SortIndicator from "../../../ratecards/components/SortIndicator";
import { FILTER_COLUMN } from "../../../../models/Filter";
import Pagination from "../../../ratecards/components/Pagination";
import LoadingIndicator from "../../../shared/LoadingIndicator";
import PunchoutsListItem from "./PunchoutsListItem";
import { SingleDatePicker } from "../../../../components/SingleDatePicker";
import ToggleButton from "../../../ratecards/components/ToggleButton";
import CreatedOnFilter from "../../../filters/CreatedOnFilter";
import { Dropdown, MenuItem } from "react-bootstrap";
import CreatedByFilter from "../../../filters/CreatedByFilter";
import ClientUserFilter from "../../../filters/ClientUserFilter";

class PunchoutsList extends Component {
  constructor(props) {
    super(props);

    this.changeCurrent = this.changeCurrent.bind(this);
  }

  changeCurrent(value) {
    let store = this.props.store;
    store.pagination.handleCurrentPage(value);
  }

  componentDidMount() {
    this.props.store.pagination.goFetch();
  }

  componentWillUnmount() {
    this.props.store.isEditing = false;
    this.props.store.clearAllSelections();
    this.props.store.clearFilters();
  }

  render() {
    const store = this.props.store;
    const network = store.network;
    const fillContainer = this.props.fillContainer;
    const fixedHeight = this.props.fixedHeight;
    const maximumDate = new Date();

    let listBodyStyles = {};
    if (fixedHeight) {
      listBodyStyles.height = fixedHeight;
      listBodyStyles.overflowY = "auto";
    }

    let selectAllSection = null;
    let selectAllOnPageSection = (
      <nav className="header-left-nav">
        <div className="btn" onClick={store.selectAllOnPageItem}>
          Select All
        </div>
        <div className="btn" onClick={store.deselectAllPage}>
          Deselect All
        </div>
      </nav>
    );

    if (store.allOnPageSelected) {
      selectAllSection = (
        <div className="ratecards-list-select-all-section">
          <span>
            All &nbsp;
            <strong>{store.punchoutCounts}</strong>
            &nbsp; Punchouts selected.{" "}
          </span>
          &nbsp;
          <span
            className="pt-link ratecards-all-items"
            key="clear-selections-link"
            onClick={store.clearAllSelections}
          >
            Clear all selections
          </span>
        </div>
      );
    }

    if (store.allOnPageSelected && !store.allSelected) {
      selectAllSection = (
        <div className="ratecards-list-select-all-section">
          <span>All Punchouts on this page selected. &nbsp;</span>
          &nbsp;
          <span
            className="pt-link ratecards-all-items"
            key="select-all-item"
            onClick={store.selectAllPage}
          >
            Select all &nbsp;
            <strong>{store.punchoutCounts}</strong>
            &nbsp; Punchouts.
          </span>
          &nbsp;&nbsp;
          <span
            className="pt-link ratecards-all-items"
            key="clear-selections-link"
            onClick={store.clearAllSelections}
          >
            Clear all selections
          </span>
        </div>
      );
    }

    let tableLeftActionBar = null;
    if (store.isEditing) {
      tableLeftActionBar = (
        <nav className="header-left-nav">
          {store.allowMultipleItemSelection && selectAllOnPageSection}
        </nav>
      );
    } else {
      tableLeftActionBar = (
        <nav className="header-left-nav">
          <Dropdown bsStyle="default" title="Toggle All" noCaret id="dropdown-no-caret">
            <Dropdown.Toggle noCaret>Toggle All ▼</Dropdown.Toggle>
            <Dropdown.Menu>
              <MenuItem header>Searches</MenuItem>
              <MenuItem eventKey="1" onSelect={store.expandAllSearches}>
                Expand
              </MenuItem>
              <MenuItem eventKey="2" onSelect={store.collapseAllSearches}>
                Collapse
              </MenuItem>
            </Dropdown.Menu>
          </Dropdown>
        </nav>
      );
    }

    const punchouts = store.punchoutsView;
    let tableContent = null;
    if (network.loading) {
      tableContent = <LoadingIndicator />;
    } else if (network.error) {
      // TODO: replace this with a more friendly error message
      tableContent = (
        <div className="pt-error-message">
          <h2>Error</h2>
          <pre>
            <code>{JSON.stringify(network.error, null, 2)}</code>
          </pre>
        </div>
      );
    } else if (punchouts.length === 0) {
      tableContent = (
        <div className="ratecards-table-empty">
          {Object.keys(store.appliedFilters).length > 0 ? (
            <div>No Punchout match your current filters</div>
          ) : (
            <div>
              No Punchout
              <p>Create a Punchouts using the button above or by searching.</p>
            </div>
          )}
        </div>
      );
    } else {
      if (store.isEditing) {
        tableContent = punchouts.map((punchout) => (
          <PunchoutsListItem
            key={punchout.buyrateId}
            store={store}
            punchout={punchout}
            editing={store.isEditing}
            expandable={store.allowExpand}
            expanded={punchout.viewState.expanded}
            itemClick={(e) => store.toggleSelected(punchout)}
          />
        ));
      } else {
        tableContent = punchouts.map((punchout) => (
          <PunchoutsListItem
            key={punchout.buyrateId}
            store={store}
            punchout={punchout}
            editing={store.isEditing}
            // expandable={store.allowExpand}
            expandable={false}
            expanded={punchout.viewState.expanded}
            itemClick={(e) => store.toggleExpanded(punchout)}
          />
        ));
      }
    }

    var fromDate = (
      <SingleDatePicker
        id="from_date"
        date={store.createdOnFilter.fromDate}
        numberOfMonths={1}
        isOutsideRange={(day) => day.isAfter(maximumDate)}
        focused={store.createdOnFilter.fromFocused}
        onDateChange={store.createdOnFilter.fromDateChange}
        onFocusChange={store.createdOnFilter.fromFocusedChange}
      />
    );

    var toDate = (
      <SingleDatePicker
        id="to_date"
        date={store.createdOnFilter.toDate}
        numberOfMonths={1}
        isOutsideRange={(day) => store.toDateRange(day)}
        focused={store.createdOnFilter.toFocused}
        onDateChange={store.createdOnFilter.toDateChange}
        onFocusChange={store.createdOnFilter.toFocusedChange}
      />
    );

    return (
      <div className="ratecards-list">
        {store.createdOnFilter && (
          <CreatedOnFilter
            createdOnFilter={store.createdOnFilter}
            fromDate={fromDate}
            toDate={toDate}
          />
        )}
        {store.createdByFilter && (
          <CreatedByFilter
            createdByFilter={store.createdByFilter}
            network={store.createdByFilter.network.loading}
            instantSearchValue={store.createdByFilter.instantSearchValue}
            onInstantSearch={store.createdByFilter.onInstantSearch}
          />
        )}
        {store.clientUserFilter && (
          <ClientUserFilter
            clientUserFilter={store.clientUserFilter}
            network={store.clientUserFilter.network.loading}
            instantSearchValue={store.clientUserFilter.instantSearchValue}
            onInstantSearch={store.clientUserFilter.onInstantSearch}
          />
        )}
        {
          <header
            className={classNames("ratecards-table-header", {
              active: store.isFiltered,
            })}
          >
            {tableLeftActionBar}
            <nav className="header-right-nav">
              {store.isFiltered && (
                <div className="btn btn-yellow" onClick={store.clearFilters}>
                  Clear All Filters & Sorts
                </div>
              )}
              {store.clientUserFilter && (
                <ToggleButton
                  borderless={true}
                  name="search-list-filter"
                  value={FILTER_COLUMN.CLIENT_USER}
                  selected={Boolean(store.appliedFilters[FILTER_COLUMN.CLIENT_USER])}
                  onClick={store.clientUserFilter.onShowModal}
                >
                  <SortIndicator
                    sortColumn={FILTER_COLUMN.CLIENT_USER}
                    sortsOrder={store.appliedSortsOrder}
                    sort={store.appliedSorts[FILTER_COLUMN.CLIENT_USER]}
                  />
                  Client <span> ▼</span>
                </ToggleButton>
              )}
              {store.createdByFilter && (
                <ToggleButton
                  borderless={true}
                  name="rate-cards-filter"
                  value={FILTER_COLUMN.CREATED_BY}
                  selected={Boolean(store.appliedFilters[FILTER_COLUMN.CREATED_BY])}
                  onClick={store.createdByFilter.onShowModal}
                >
                  <SortIndicator
                    sortColumn={FILTER_COLUMN.CREATED_BY}
                    sortsOrder={store.appliedSortsOrder}
                    sort={store.appliedSorts[FILTER_COLUMN.CREATED_BY]}
                  />
                  Created By
                  <span> ▼</span>
                </ToggleButton>
              )}
              {store.createdOnFilter && (
                <ToggleButton
                  borderless={true}
                  name="rate-cards-filter"
                  value={FILTER_COLUMN.PUNCHOUTS_DATE_RANGE}
                  selected={Boolean(
                    store.appliedFilters[FILTER_COLUMN.PUNCHOUTS_DATE_RANGE]
                  )}
                  onClick={store.createdOnFilter.onShowModal}
                >
                  <SortIndicator
                    sortType="numeric"
                    sortColumn={FILTER_COLUMN.PUNCHOUTS_DATE_RANGE}
                    sortsOrder={store.appliedSortsOrder}
                    sort={store.appliedSorts[FILTER_COLUMN.PUNCHOUTS_DATE_RANGE]}
                  />
                  Created On
                  <span> ▼</span>
                </ToggleButton>
              )}
            </nav>
          </header>
        }
        <div
          className={classNames("ratecards-table", {
            "ratecards-table-fill-container": fillContainer,
          })}
        >
          <div style={listBodyStyles}>
            {selectAllSection}
            {tableContent}
          </div>
        </div>
        {store.pagination.pageCount > 0 ? (
          <Pagination
            pageCount={store.pagination.pageCount}
            pagesToShow={store.pagination.maxPagesToShow}
            currentPage={store.pagination.currentPage}
            begin={store.pagination.begin}
            end={store.pagination.end}
            isEndRange={store.pagination.isEndRange}
            handleCurrentPage={this.changeCurrent}
            firstPage={store.pagination.firstPage}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

PunchoutsList.propTypes = {
  store: PropTypes.object.isRequired,
  fixedHeight: PropTypes.number,
  fillContainer: PropTypes.bool,
};

export default observer(PunchoutsList);
