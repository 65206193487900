import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import Modal from "../ratecards/components/Modal";
import ContainerSection from "../ratecards/components/ContainerSection";

class ContactRateSpecialistModal extends Component {
  componentDidMount() {
    const rateSearchStore = this.props.rateSearchStore;
    rateSearchStore.subject = "";
    rateSearchStore.message = "";
  }

  render() {
    const rateSearchStore = this.props.rateSearchStore;

    return (
      <Modal show={rateSearchStore.showPopup} onHide={rateSearchStore.closeModel}>
        <div id="alert_msg">
          {rateSearchStore.assistanceMessage.success && (
            <div className="alert alert-success alert-dismissable fade in pt-user-alert">
              {rateSearchStore.assistanceMessage.message}
            </div>
          )}
        </div>

        {!rateSearchStore.assistanceMessage.success && (
          <div style={{ width: "400px" }}>
            <ContainerSection className="header">
              <h4>Tell Us How We Can Help You</h4>
            </ContainerSection>
            <ContainerSection>
              <label htmlFor="subjectInput">Subject:</label>
              <input
                id="subjectInput"
                type="text"
                className="rc-input-box"
                style={{ width: "100%" }}
                value={rateSearchStore.subject}
                onChange={rateSearchStore.onSubjectChange}
              />
            </ContainerSection>
            <ContainerSection>
              <label htmlFor="messageInput">Message:</label>
              <textarea
                id="messageInput"
                className="form-control"
                rows="8"
                value={rateSearchStore.message}
                onChange={rateSearchStore.changeMessageText}
              />
            </ContainerSection>
            <ContainerSection className="footer">
              <div className="pull-right">
                <button
                  className="btn btn-lg btn-default"
                  onClick={rateSearchStore.closeModel}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-lg btn-green"
                  onClick={rateSearchStore.contactRateSpecialist}
                  disabled={rateSearchStore.checkSendMessageValidate()}
                >
                  Send Message
                </button>
              </div>
            </ContainerSection>
          </div>
        )}

        {rateSearchStore.assistanceMessage.success && (
          <ContainerSection className="footer">
            <div className="pull-right">
              <button
                className="btn btn-lg btn-default"
                onClick={rateSearchStore.closeModel}
              >
                Finished
              </button>
            </div>
          </ContainerSection>
        )}
      </Modal>
    );
  }
}

ContactRateSpecialistModal.propTypes = {
  rateSearchStore: PropTypes.object.isRequired,
};

export default observer(ContactRateSpecialistModal);
