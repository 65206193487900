// @flow

import { action, extendObservable, runInAction } from "mobx";
import NetworkState from "../../models/NetworkState";
import Industry from "../../models/Industry";
import RateSearchData from "../../models/RateSearchData";
import RateSearchOtherInfoData from "../../models/RateSearchOtherInfoData";
import _ from "lodash";
import Search from "../../models/Search";
import MessageState from "../../models/MessageState";
import { RateCardListComponentStore } from "./RateCardListStore";
import MobXStore from "./MobXStore";
import type { FetchAPI, FetchGraphQL } from "../../App";
import ModalState from "../../models/ModalState";
import { createTasteGraphQLWrapper } from "./SupportFunctions";
import { RATE_CARD_ACTION } from "./BatchSearchCreateStore";
import RateCard from "../../models/RateCard";
import SessionInfo from "../../models/SessionInfo";
import { sanitizeInput } from "../../utils/dom";

type WorkerType = {
  id: number,
  name: string,
};

const GSS_COUNTRIES = [
  103, // India
];

export default class RateSearchStore {
  mobxStore: MobXStore;
  sessionInfo: ?SessionInfo;
  contactRateSpecialistNetwork: NetworkState;
  networkDetailRateSearch: NetworkState;
  networkRateDetailSearch: NetworkState;
  networkOtherInfo: NetworkState;
  networkAddtoRateCard: NetworkState;
  networkOnetOccupationData: NetworkState;
  rateSearchData: RateSearchData;
  rateSearchOtherInfoData: RateSearchOtherInfoData;
  rateCardsListStore: RateCardListComponentStore;
  industries: Array<any>;
  tags: Array<any>;
  tagsValid: boolean;
  subject: String;
  rateType: number;
  searchRateType: number;
  clientPerSearchPricing: boolean | null;
  resourceAllocations: ?(Object[]);
  clientResourceAllocations: ?(Object[]);
  onetOccupationData: ?Object;
  updateview: boolean;
  ratesearchdata: Object;
  draftSearchData: Object;
  localRateSearchData: Object;
  local_object: Object;
  states: Array<any>;
  cities: Array<any>;
  clientJobLibraryId: String | null;
  searchableCountries: Array<number> | null;
  adhocCountries: Array<number> | null;
  certifiedCountries: Array<number> | null;
  locationtitle: String;
  education_data: Object;
  rates: Object;
  educationDescription: String;
  newForm: boolean;
  showPopup: boolean;
  message: Text;
  assistanceMessage: Object;
  searches: Search;
  showDataPolicyPopup: boolean;
  showLevelsGuidePopup: boolean;
  tabIndex: number;
  showHistogram: boolean;
  rateDetail: boolean;
  formErrors: Object;
  buyRateData: Object;
  existing: boolean;
  buyRatesValid: boolean;
  messaging: MessageState;
  punchoutSuccess: boolean;
  tempNotes: String;
  showAddToRateCardModal: boolean;
  rateCardAction: number;
  newRateCardName: string;
  jobTitleInfoModal: ModalState;
  requestMoreSearchesModal: ModalState;
  buyMoreSearchesModal: ModalState;
  fetchGraphQL: FetchGraphQL;
  fetchTasteGraphQL: FetchGraphQL;
  fetchAPI: FetchAPI;
  selectedTab: number;
  showGSS: boolean;
  isGSS: boolean;
  onIsGSSChange: () => void;
  checkGSS: (Array<{ countryId: number }>, Array<any>) => void;

  saveNotes: () => void;
  rateSearch: () => void;
  rateSearchDetail: () => void;
  contactRateSpecialist: () => void;
  getIndustries: () => void;
  getClientLibraries: () => Object;
  getClientLibraryJobTitles: () => Object;
  locationTitle: () => void;
  countries: () => Promise<any>;
  statesList: () => Promise<any>;
  citiesList: () => Promise<any>;
  saveBuyRate: () => void;
  rateSearchOtherInfo: () => void;
  displayNewForm: () => void;
  handleIndustryChange: () => void;
  findRates: () => void;
  cRSpeciatist: () => void;
  addToRateCard: () => void;
  closeModel: () => void;
  onSubjectChange: () => void;
  changeMessageText: () => void;
  checkSendMessageValidate: () => void;
  resetForm: () => void;
  handleCancelRateSearchLabel: () => void;
  apiCallForLocalRateSearch: () => void;
  showDataPolicy: () => void;
  closeDataPolicy: () => void;
  showLevelsGuide: () => void;
  closeLevelsGuide: () => void;
  toggleRateType: () => void;
  tabIndexOnSelect: () => void;
  useExistingBuyRate: () => void;
  useBuyRate: () => void;
  setBuyRateName: () => void;
  showNotes: () => void;
  closeNotes: () => void;
  createAnotherBuyRate: () => void;
  setBuyRateNotes: () => void;
  deleteBuyRate: () => void;
  addPunchouts: () => void;
  closestValue: () => void;
  setPunchoutData: () => void;
  deletePunchout: () => void;
  closeAddToRateCardModal: () => void;
  addSearchesToRateCard: () => void;
  removeMessage: () => void;
  requestSearches: (string, string) => void;
  errorModal: ModalState;
  errorMessage: any;
  lastRateCardUsed: ?RateCard;
  getWorkerTypes: (number, ?number) => Promise<Array<WorkerType>>;
  workerTypes: Array<WorkerType>;
  selectedWorkerType: ?WorkerType;
  showWorkerTypes: boolean;
  workerTypesLoading: boolean;
  handleWorkerTypeChange: (?WorkerType) => void;
  checkShowWorkerTypes: (
    Array<{ countryId: number }>,
    Array<any>,
    Array<{ id: number }>,
    number
  ) => void;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI, mobxStore: MobXStore) {
    this.fetchTasteGraphQL = createTasteGraphQLWrapper(fetchAPI);
    this.fetchGraphQL = fetchGraphQL;
    this.fetchAPI = fetchAPI;
    this.mobxStore = mobxStore;
    // NOTE: Bound early to pass into pagination & filter state
    this.rateSearchDetail = action(this.rateSearchDetail.bind(this));
    this.getSearchesRemaining = action(this.getSearchesRemaining.bind(this));
    this.contactRateSpecialist = action(this.contactRateSpecialist.bind(this));
    this.getIndustries = action(this.getIndustries.bind(this));
    this.getClientLibraries = action(this.getClientLibraries.bind(this));
    this.selectClientJobLibrary = action(this.selectClientJobLibrary.bind(this));
    this.getClientLibraryJobTitles = action(this.getClientLibraryJobTitles.bind(this));
    this.locationTitle = action(this.locationTitle.bind(this));
    this.countries = action(this.countries.bind(this));
    this.statesList = action(this.statesList.bind(this));
    this.citiesList = action(this.citiesList.bind(this));
    this.saveBuyRate = action(this.saveBuyRate.bind(this));
    this.addToRateCard = action(this.addToRateCard.bind(this));
    this.getOnetOccupationData = action(this.getOnetOccupationData.bind(this));
    this.rateSearchOtherInfo = action(this.rateSearchOtherInfo.bind(this));
    this.displayNewForm = action(this.displayNewForm.bind(this));
    this.handleIndustryChange = action(this.handleIndustryChange.bind(this));
    this.findRates = action(this.findRates.bind(this));
    this.closeAddToRateCardModal = action(this.closeAddToRateCardModal.bind(this));
    this.cRSpeciatist = action(this.cRSpeciatist.bind(this));
    this.closeModel = action(this.closeModel.bind(this));
    this.onSubjectChange = action(this.onSubjectChange.bind(this));
    this.changeMessageText = action(this.changeMessageText.bind(this));
    this.checkSendMessageValidate = action(this.checkSendMessageValidate.bind(this));
    this.resetForm = action(this.resetForm.bind(this));
    this.removeMessage = action(this.removeMessage.bind(this));
    this.rateSearch = action(this.rateSearch.bind(this));
    this.handleCancelRateSearchLabel = action(
      this.handleCancelRateSearchLabel.bind(this)
    );
    this.apiCallForLocalRateSearch = action(this.apiCallForLocalRateSearch.bind(this));
    this.showDataPolicy = action(this.showDataPolicy.bind(this));
    this.closeDataPolicy = action(this.closeDataPolicy.bind(this));
    this.showLevelsGuide = action(this.showLevelsGuide.bind(this));
    this.closeLevelsGuide = action(this.closeLevelsGuide.bind(this));
    this.toggleRateType = action(this.toggleRateType.bind(this));
    this.tabIndexOnSelect = action(this.tabIndexOnSelect.bind(this));
    this.useExistingBuyRate = action(this.useExistingBuyRate.bind(this));
    this.useBuyRate = action(this.useBuyRate.bind(this));
    this.setBuyRateName = action(this.setBuyRateName.bind(this));
    this.showNotes = action(this.showNotes.bind(this));
    this.closeNotes = action(this.closeNotes.bind(this));
    this.saveNotes = action(this.saveNotes.bind(this));
    this.createAnotherBuyRate = action(this.createAnotherBuyRate.bind(this));
    this.setBuyRateNotes = action(this.setBuyRateNotes.bind(this));
    this.deleteBuyRate = action(this.deleteBuyRate.bind(this));
    this.addPunchouts = action(this.addPunchouts.bind(this));
    this.closestValue = action(this.closestValue.bind(this));
    this.setPunchoutData = action(this.setPunchoutData.bind(this));
    this.deletePunchout = action(this.deletePunchout.bind(this));
    this.addSearchesToRateCard = action(this.addSearchesToRateCard.bind(this));
    this.handleRateCardAction = action(this.handleRateCardAction.bind(this));
    this.onNewRateCardNameChange = action(this.onNewRateCardNameChange.bind(this));
    this.onIsGSSChange = action(this.onIsGSSChange.bind(this));
    this.checkGSS = action(this.checkGSS.bind(this));
    this.getWorkerTypes = action(this.getWorkerTypes.bind(this));
    this.handleWorkerTypeChange = action(this.handleWorkerTypeChange.bind(this));
    this.checkShowWorkerTypes = action(this.checkShowWorkerTypes.bind(this));

    extendObservable(this, {
      sessionInfo: null,
      networkDetailRateSearch: new NetworkState(),
      networkRateDetailSearch: new NetworkState(),
      rateCardsListStore: new RateCardListComponentStore(fetchGraphQL),
      networkOtherInfo: new NetworkState(),
      networkAddtoRateCard: new NetworkState(),
      networkOnetOccupationData: new NetworkState(),
      rateSearchData: new RateSearchData(),
      contactRateSpecialistNetwork: new NetworkState(),
      industries: [],
      showAddToRateCardModal: false,
      jobTitleInfoModal: new ModalState(),
      requestMoreSearchesModal: new ModalState(),
      buyMoreSearchesModal: new ModalState(),
      ratesearchdata: [],
      draftSearchData: [],
      localRateSearchData: [],
      local_object: [],
      states: [],
      cities: [],
      clientJobLibraryId: null,
      searchableCountries: null,
      adhocCountries: null,
      certifiedCountries: null,
      tabIndex: 0,
      existing: false,
      buyRatesValid: false,
      updateview: true,
      locationtitle: "",
      education_data: null,
      showHistogram: true,
      rates: null,
      rateType: 1,
      searchRateType: 1,
      clientPerSearchPricing: null,
      resourceAllocations: null,
      clientResourceAllocations: null,
      onetOccupationData: null,
      rateDetail: false,
      educationDescription: "",
      searches: {},
      newForm: true,
      tagsValid: true,
      rateCardAction: RATE_CARD_ACTION.USE_EXISTING,
      newRateCardName: "",
      showPopup: false,
      showDataPolicyPopup: false,
      showLevelsGuidePopup: false,
      subject: "",
      messaging: new MessageState(),
      tempNotes: "",
      buyRateData: {
        Hourly: [{ name: "", showNotes: false, notes: "", index: 0, punchoutsList: [] }],
        Annual: [{ name: "", showNotes: false, notes: "", index: 0, punchoutsList: [] }],
      },
      assistanceMessage: {
        success: false,
        message: "",
      },
      message: "",
      tags: [
        {
          id: 1,
          name: "All",
        },
      ],
      punchoutSuccess: false,
      selectedTab: 0,
      errorModal: new ModalState(),
      errorMessage: "",
      lastRateCardUsed: null,
      showGSS: false,
      isGSS: false,
      workerTypes: [],
      selectedWorkerType: null,
      showWorkerTypes: false,
      workerTypesLoading: false,
    });
  }

  onIsGSSChange(e: SyntheticInputEvent<HTMLInputElement>) {
    this.isGSS = e.currentTarget.checked;
  }

  checkGSS(locations: Array<{ countryId: number }>, regions: Array<any>) {
    const regionsSelected = regions.length > 0;
    const gssEnabled = locations.filter((l) => GSS_COUNTRIES.includes(l.countryId));
    this.showGSS =
      locations.length > 0 && gssEnabled.length === locations.length && !regionsSelected;
  }

  checkShowWorkerTypes(
    locations: Array<{ countryId: number }>,
    regions: Array<any>,
    industries: Array<{ id: number }>,
    rateType: number
  ) {
    // console.log("locations:", locations);
    // console.log("regions:", regions);
    // console.log("industries:", industries);
    // console.log("rateType:", rateType);
    const regionsSelected = regions.length > 0;
    const countriesSelected = locations.reduce((countryIds, location) => {
      if (!countryIds.includes(location.countryId)) countryIds.push(location.countryId);
      return countryIds;
    }, []);

    this.showWorkerTypes =
      !regionsSelected &&
      countriesSelected.length === 1 &&
      industries.length === 1 &&
      rateType === 1;

    // console.log("check wt:", this.showWorkerTypes);
    if (this.showWorkerTypes) {
      this.workerTypesLoading = true;
      this.getWorkerTypes(countriesSelected[0], industries[0].id).finally(
        () => (this.workerTypesLoading = false)
      );
    } else {
      this.workerTypes = [];
      this.selectedWorkerType = null;
    }
  }

  handleRateCardAction(action) {
    this.rateCardAction = action;
  }

  onNewRateCardNameChange(name) {
    this.newRateCardName = sanitizeInput(name);
  }

  checkSendMessageValidate() {
    let messageSubjectValid = this.subject && this.subject.length > 0 ? true : false;
    let messageBodyValid = this.message && this.message.length > 0 ? true : false;

    if (messageSubjectValid && messageBodyValid) {
      return false;
    }
    return true;
  }

  handleCancelRateSearchLabel(e, index) {
    const ul = document.getElementById("sidebarmenu");
    if (!ul) {
      console.error("cannot find sidebar element in DOM");
      return;
    }

    const tabs = ul.getElementsByTagName("li");
    if (!tabs) {
      console.error("no tabs found in DOM");
      return;
    }

    let activeTabIndex = -1;
    for (let i = 0; i < tabs.length; ++i) {
      if (tabs[i].classList.contains("active")) {
        activeTabIndex = i;
        break;
      }
    }

    const activeTab = activeTabIndex >= 0 ? tabs[activeTabIndex] : null;

    const ratesearchdata = JSON.parse(localStorage.getItem("ratesearchdata"));
    if (ratesearchdata) {
      ratesearchdata.splice(index, 1);
      if (activeTab && activeTab.contains(e.currentTarget)) {
        this.displayNewForm();
      } else if (activeTabIndex > index + 1) {
        // if user removed the tab above, set previous index tab as active
        tabs[activeTabIndex].classList.remove("active");
        tabs[activeTabIndex - 1].classList.add("active");
        this.selectedTab = activeTabIndex - 1;
      }
      if (ratesearchdata.length === 0) {
        localStorage.removeItem("ratesearchdata");
        // this.displayNewForm();
      } else {
        localStorage.setItem("ratesearchdata", JSON.stringify(ratesearchdata));
      }
    }
    // HACK: This is needed cause localstorage was used to render tabs
    this.updateview = !this.updateview;
  }

  /**
   * apiCallForLocalRateSearch - api call for rate seach detail of local store rate searches for the current session
   * @return {void}
   */
  apiCallForLocalRateSearch(e, data, resultsTabIndex) {
    this.newForm = false;
    const ul = document.getElementById("sidebarmenu");
    const items = ul.getElementsByTagName("li");
    for (let i = 0; i < items.length; ++i) {
      items[i].classList.remove("active");
    }
    e.currentTarget.classList.add("active");
    // for (let i = 0; i < items.length; ++i) {
    //   items[i].classList.remove("active");
    // }
    // if (e.target.tagName.toLowerCase() === "a") {
    //   e.target.parentElement.classList.add("active");
    // } else {
    //   e.target.classList.add("active");
    // }

    this.buyRateData = {
      Hourly: [{ name: "", showNotes: false, notes: "", index: 0, punchoutsList: [] }],
      Annual: [{ name: "", showNotes: false, notes: "", index: 0, punchoutsList: [] }],
    };

    this.rateSearchDetail(data.searchId);
    this.rateDetail = true;
    this.tabIndex = 0;
    this.rateType = data.results[0].rateType || 1;
    this.existing = false;
    this.selectedTab = resultsTabIndex || 0;
  }

  /**
   * showDataPolicy - show pdf in popup
   * @return {void}
   */
  showDataPolicy() {
    this.updateview = !this.updateview;
    this.showDataPolicyPopup = true;
  }

  /**
   * closeDataPolicy - close pdf popup
   * @return {void}
   */
  closeDataPolicy() {
    this.showDataPolicyPopup = false;
    this.updateview = !this.updateview;
  }

  showLevelsGuide() {
    this.showLevelsGuidePopup = true;
  }

  closeLevelsGuide() {
    this.showLevelsGuidePopup = false;
  }

  addToRateCard() {
    this.rateCardsListStore.allowMultipleItemSelection = false;
    this.rateCardsListStore.isEditing = true;
    this.rateCardsListStore.pagination.goFetch();
    this.showAddToRateCardModal = true;
    this.updateview = !this.updateview;
  }

  closeAddToRateCardModal() {
    this.showAddToRateCardModal = false;
    this.updateview = !this.updateview;
  }

  async addSearchesToRateCard(searchesIds) {
    if (this.networkAddtoRateCard.loading) {
      return;
    }

    let params = [];
    let filterargs = [];
    let args = [];
    let vars = {};

    if (this.rateCardAction === RATE_CARD_ACTION.USE_EXISTING) {
      const selRateCards = this.rateCardsListStore.getSelectedRateCards();
      if (!selRateCards || !selRateCards.length) return;

      vars.existingRateCardId = selRateCards[0];
    } else if (
      this.rateCardAction === RATE_CARD_ACTION.USE_PREVIOUS &&
      this.lastRateCardUsed
    ) {
      vars.existingRateCardId = this.lastRateCardUsed.ratecardId;
    } else if (this.rateCardAction === RATE_CARD_ACTION.CREATE) {
      vars.newRateCardName = this.newRateCardName;
    }

    if (searchesIds && searchesIds.length > 0) {
      params.push("$only: [String]!");
      filterargs.push("only: $only");
      vars.only = searchesIds.map(String);
    }

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
        mutation saveSearchRateCardExisting($existingRateCardId: String, $newRateCardName: String, ${queryParams}) {
          saveSearchesToRateCard(
            input: {
              ${filterargs},
              filters: { ${queryArgs} },
              existingRatecard: $existingRateCardId,
              newRatecard: $newRateCardName
            }
          ) {
            errors {
              __typename
            }
            rateCard {
              ratecardId
              shared
              tags {
                name
                tagId
              }
              owner {
                firstName
                lastName
                email
                userId
                termsOfAgreement
              }
              name
              createdDate
              updateDate
              searchCount
              locationCount
            }
            searches {
              searchId
              state
              city
              createdDate
              industry {
                value
              }
            }
          }
        }
      `;

    let res = null;
    this.networkAddtoRateCard.loading = true;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.networkAddtoRateCard.handleError("Adding searches to Rate Card", e);
      this.errorMessage =
        "Sorry! Something went wrong on our side and the operation failed.";
      this.errorModal.showModal();
      return;
    }

    runInAction("addSearchesToRateCard--success", () => {
      this.networkAddtoRateCard.loading = false;
      this.networkAddtoRateCard.error = null;
      if (this.networkAddtoRateCard.logGraphQLError("saveSearchesToRateCard", res)) {
        // TODO: Display user friendly error message
        // NameAlreadyExistError
        const errors = res?.data?.saveSearchesToRateCard?.errors || [];
        if (errors.find((err) => err["__typename"] === "NameAlreadyExistError")) {
          this.errorMessage =
            "Operation failed. A rate card with the same name already exists.";
          this.errorModal.showModal();
        } else if (errors.length > 0) {
          this.errorMessage =
            "Sorry! Something went wrong on our side and the operation failed.";
          this.errorModal.showModal();
        }
        return;
      }

      this.showAddToRateCardModal = false;
      this.rateSearchDetail(searchesIds);
      const rateCard = res?.data?.saveSearchesToRateCard?.rateCard;
      if (this.rateCardAction === RATE_CARD_ACTION.CREATE && rateCard) {
        this.newRateCardName = "";
      } else if (this.rateCardAction === RATE_CARD_ACTION.USE_PREVIOUS && rateCard) {
        // left this here in case we need to do something
      } else {
        // left this here in case we need to do something
      }

      this.lastRateCardUsed = new RateCard(this, rateCard, this.sessionInfo);
      this.rateCardAction = RATE_CARD_ACTION.USE_PREVIOUS;
      this.updateview = !this.updateview;
    });
  }

  async getOnetOccupationData(jobTitleId) {
    const query = `
      query getOnetOccupationData($jobTitleId: ID!) {
        rawJobTitle(databaseId: $jobTitleId) {
          onetOccupationData {
            title
            description
            skills
            tasks
            tools
            technology
            education {
              category
              dataValue
            }
          }
        }
      }
    `;
    let variables = {
      jobTitleId,
    };

    let res: ?Object = null;

    this.networkOnetOccupationData.loading = true;

    try {
      res = await this.fetchTasteGraphQL(query, variables);
    } catch (e) {
      this.networkOnetOccupationData.handleError("Requesting getOnetOccupationData", e);
      // TODO: Display user friendly error message
      return;
    }

    return runInAction("getOnetOccupationData--success", () => {
      this.networkOnetOccupationData.loading = false;
      this.networkOnetOccupationData.error = null;

      if (this.networkOnetOccupationData.logGraphQLError("getOnetOccupationData", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.networkOnetOccupationData.loading = false;

      if (!res || !res.data) return;

      this.onetOccupationData = res.data.rawJobTitle.onetOccupationData;
    });
  }

  /**
   * toggleRateType - toggle rateType state
   * @return {void}
   */
  toggleRateType(value) {
    if (this.rateType !== value) {
      this.rateType = value;
      this.existing = false;
      this.updateview = !this.updateview;
      this.searches = new Search(
        this,
        this.localRateSearchData.find((rates) => rates.rateType === this.rateType)
      );
      this.searches.getSearchResults();
    }
  }

  tabIndexOnSelect(tabIndex) {
    this.tabIndex = tabIndex;
    this.updateview = !this.updateview;
  }

  /** toggleHistogram - show/hide histogram
   * @return {void}
   */
  toggleHistogram(value) {
    this.showHistogram = !value;
    this.updateview = !this.updateview;
  }

  closestValue(array, val) {
    for (let i = 0; i < array.length; i++) {
      if (Math.abs(array[i] - val) < Math.abs(array[i - 1] - val)) {
        return array[i];
      } else {
        return array[i - 1];
      }
    }
  }

  setPunchoutData(buyrateIndex, index, value, field) {
    let type;
    if (value === "" || value.match(/^[0-9]+(\.[0-9]+)?$/)) {
      if (field !== "levelId") {
        const splittedField = field.split(".");
        if (this.rateType === 1) {
          type = this.buyRateData.Hourly;
        } else if (this.rateType === 2) {
          type = this.buyRateData.Annual;
        }
        type[buyrateIndex].punchoutsList[index][splittedField[0]][splittedField[1]] =
          value;
      } else {
        type[buyrateIndex].punchoutsList[index].levelId = value;
      }

      this.buyRatesValid = this.validateBuyrate(type[buyrateIndex]);
    } else {
      return;
    }

    this.updateview = !this.updateview;
  }

  onFocusOutFn(e, buyrateIndex, index, field) {
    const splittedField = field.split(".");
    let type;
    if (this.rateType === 1) {
      type = this.buyRateData.Hourly;
    } else if (this.rateType === 2) {
      type = this.buyRateData.Annual;
    }

    const val =
      type[buyrateIndex].punchoutsList[index][splittedField[0]][
        splittedField[1]
      ].toString();
    if (!val.match(/^[0-9]+(\.[0-9]+)?$/)) {
      e.target.parentElement.classList.add("has-error");
    } else {
      e.target.parentElement.classList.remove("has-error");
    }
  }

  validateBuyrate(buyrate) {
    let nameValid = buyrate.name ? true : false;
    let billRateValid = false;
    let markupAmtValid = false;
    let markupPctValid = false;
    let payRateValid = false;

    // Huge HACK, this whole function should be rewritten
    let i = 0;
    let isEmptyBillRate = (k) => buyrate.punchoutsList[i].billRate[k] !== "";
    let isEmptyMarkupAmt = (k) => buyrate.punchoutsList[i].markupAmt[k] !== "";
    let isEmptyMarkupPct = (k) => buyrate.punchoutsList[i].markupPct[k] !== "";
    let isEmptyPayRate = (k) => buyrate.punchoutsList[i].payRate[k] !== "";

    for (i = 0; i < buyrate.punchoutsList.length; i++) {
      billRateValid = Object.keys(buyrate.punchoutsList[i].billRate).every(
        isEmptyBillRate
      );
      markupAmtValid = Object.keys(buyrate.punchoutsList[i].markupAmt).every(
        isEmptyMarkupAmt
      );
      markupPctValid = Object.keys(buyrate.punchoutsList[i].markupPct).every(
        isEmptyMarkupPct
      );
      payRateValid = Object.keys(buyrate.punchoutsList[i].payRate).every(isEmptyPayRate);
      if (
        !nameValid ||
        !billRateValid ||
        !markupAmtValid ||
        !markupPctValid ||
        !payRateValid
      ) {
        break;
      }
    }
    return nameValid && billRateValid && markupAmtValid && markupPctValid && payRateValid;
  }

  deletePunchout(buyrateIndex, index) {
    this.buyRatesValid = true;
    let type;
    if (this.rateType === 1) {
      type = this.buyRateData.Hourly;
    } else if (this.rateType === 2) {
      type = this.buyRateData.Annual;
    }
    if (type[buyrateIndex].punchoutsList.length <= 1) {
      return;
    } else {
      type[buyrateIndex].punchoutsList.splice(index, 1);
    }

    this.updateview = !this.updateview;
  }

  useExistingBuyRate(value) {
    this.buyRatesValid = true;
    this.existing = true;
    const existingBuyRates = [];
    const data = value.rates;

    for (let i = 0; i < 5; i++) {
      existingBuyRates.push({
        billRate: {
          min: data.billRate.min[i],
          max: data.billRate.max[i],
          mid: data.billRate.mid[i],
          avg: data.billRate.avg[i],
        },
        markupAmt: {
          min: data.markupAmt.min[i],
          max: data.markupAmt.max[i],
          mid: data.markupAmt.mid[i],
          avg: data.markupAmt.avg[i],
        },
        markupPct: {
          min: data.markupPct.min[i],
          max: data.markupPct.max[i],
          mid: data.markupPct.mid[i],
          avg: data.markupPct.avg[i],
        },
        payRate: {
          min: data.payRate.min[i],
          max: data.payRate.max[i],
          mid: data.payRate.mid[i],
          avg: data.payRate.avg[i],
        },
        levelId: i + 1,
      });
    }

    const dataToDisplay = this.localRateSearchData.find(
      (rates) => rates.rateType === this.rateType
    );

    if (this.buyRateData.Hourly.length === 0 || this.buyRateData.Annual.length === 0) {
      this.buyRateData = {
        Hourly: [{ name: "", showNotes: false, notes: "", index: 0, punchoutsList: [] }],
        Annual: [{ name: "", showNotes: false, notes: "", index: 0, punchoutsList: [] }],
      };
    }

    let jobTitleOrLabel = dataToDisplay.job.jobLabel
      ? dataToDisplay.job.jobLabel
      : dataToDisplay.job.jobTitle;
    let date = new Date();

    date = date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
    this.setBuyRateName(0, jobTitleOrLabel + " " + date);

    let type;
    if (this.rateType === 1) {
      type = this.buyRateData.Hourly;
    } else if (this.rateType === 2) {
      type = this.buyRateData.Annual;
    }

    type[0].punchoutsList = existingBuyRates;

    const punchList = document.getElementsByClassName("punchout-list");
    for (let j = 0; j < punchList.length; j++) {
      for (let k = 0; k < punchList[j].childElementCount; k++) {
        if (
          punchList[j].childNodes.length > 0 &&
          punchList[j].childNodes[k].childNodes.length > 0 &&
          punchList[j].childNodes[k].childNodes[1]
        ) {
          punchList[j].childNodes[k].childNodes[1].classList.remove("has-error");
          if (punchList[j].childNodes[k].childNodes[1].childNodes) {
            punchList[j].childNodes[k].childNodes[1].childNodes[0].classList.remove(
              "has-error"
            );
          }
        }
      }
    }

    this.updateview = !this.updateview;
  }

  useBuyRate(data, rateType) {
    this.existing = true;
    const customBuyRate = [];
    data.forEach((buyrate, index) => {
      const newpunch = [];

      for (let i = 0; i < buyrate.punchouts.length; i++) {
        newpunch.push({
          billRate: {
            min: buyrate.punchouts[i].billRateMin,
            max: buyrate.punchouts[i].billRateMax,
            mid: buyrate.punchouts[i].billRateMid,
            avg: buyrate.punchouts[i].billRateAvg,
          },
          markupAmt: {
            min: buyrate.punchouts[i].markupAmtMin,
            max: buyrate.punchouts[i].markupAmtMax,
            mid: buyrate.punchouts[i].markupAmtMid,
            avg: buyrate.punchouts[i].markupAmtAvg,
          },
          markupPct: {
            min: buyrate.punchouts[i].markupPctMin,
            max: buyrate.punchouts[i].markupPctMax,
            mid: buyrate.punchouts[i].markupPctMid,
            avg: buyrate.punchouts[i].markupPctAvg,
          },
          payRate: {
            min: buyrate.punchouts[i].payRateMin,
            max: buyrate.punchouts[i].payRateMax,
            mid: buyrate.punchouts[i].payRateMid,
            avg: buyrate.punchouts[i].payRateAvg,
          },
          levelId: buyrate.punchouts[i].level.legacyId,
        });
      }

      const newBuyRate = {
        name: buyrate.name,
        showNotes: false,
        notes: buyrate.notes,
        index: index,
        punchoutsList: newpunch,
      };
      customBuyRate.push(newBuyRate);
    });

    this.buyRateData[rateType] = customBuyRate;

    this.updateview = !this.updateview;
  }

  errorClass(index) {
    let type;

    if (this.rateType === 1) {
      type = this.buyRateData.Hourly;
    } else if (this.rateType === 2) {
      type = this.buyRateData.Annual;
    }

    return type[index].name === "" ? "has-error" : "";
  }

  setBuyRateName(index, value) {
    let type;

    if (this.rateType === 1) {
      type = this.buyRateData.Hourly;
    } else if (this.rateType === 2) {
      type = this.buyRateData.Annual;
    }
    if (type.length === 0) {
      type = [{ name: "", showNotes: false, notes: "", index: 0, punchoutsList: [] }];
    }
    type[index].name = value;
    this.existing = true;
    this.updateview = !this.updateview;
  }

  /**
   * showNotes - show notes popup
   * @return {void}
   */
  showNotes(index) {
    let type;

    if (this.rateType === 1) {
      type = this.buyRateData.Hourly;
    } else if (this.rateType === 2) {
      type = this.buyRateData.Annual;
    }
    type[index].showNotes = true;
    this.updateview = !this.updateview;
  }

  /**
   * closeNotes - close notes popup
   * @return {void}
   */
  closeNotes(index) {
    this.tempNotes = "";
    let type;
    if (this.rateType === 1) {
      type = this.buyRateData.Hourly;
    } else if (this.rateType === 2) {
      type = this.buyRateData.Annual;
    }
    type[index].showNotes = false;
    this.updateview = !this.updateview;
  }

  saveNotes(index) {
    let type;
    if (this.rateType === 1) {
      type = this.buyRateData.Hourly;
    } else if (this.rateType === 2) {
      type = this.buyRateData.Annual;
    }
    type[index].notes = this.tempNotes;
    this.closeNotes(index);
    this.buyRatesValid = true;
  }

  deleteBuyRate(index) {
    this.buyRatesValid = true;
    let type;
    if (this.rateType === 1) {
      type = this.buyRateData.Hourly;
    } else if (this.rateType === 2) {
      type = this.buyRateData.Annual;
    }
    type.splice(index, 1);
    this.updateview = !this.updateview;
  }

  addPunchouts(index, click) {
    this.buyRatesValid = false;
    let type;

    if (this.rateType === 1) {
      type = this.buyRateData.Hourly;
    } else if (this.rateType === 2) {
      type = this.buyRateData.Annual;
    }
    const existingLevel = type[index].punchoutsList.length;

    if (click || existingLevel === 0) {
      const punch = this.createPunchoutList(existingLevel + 1);
      for (let i = 0; i < punch.length; i++) {
        type[index].punchoutsList.push(punch[i]);
      }
    }

    this.updateview = !this.updateview;
  }

  createAnotherBuyRate(dataToDisplay) {
    this.buyRatesValid = false;
    const punch = this.createPunchoutList(1);
    let type;
    if (this.rateType === 1) {
      type = this.buyRateData.Hourly;
    } else if (this.rateType === 2) {
      type = this.buyRateData.Annual;
    }

    const existingLength = type.length;
    let date = new Date();
    date = date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
    const newBuyRate = {
      name: dataToDisplay.job.jobLabel + " " + date,
      showNotes: false,
      notes: "",
      index: existingLength + 1,
      punchoutsList: punch,
    };
    type.push(newBuyRate);
    this.updateview = !this.updateview;
  }

  createPunchoutList(levelId) {
    const punchoutsList = [];
    const billRate = {};
    const markupPct = {};
    const markupAmt = {};
    const payRate = {};
    billRate.min = "";
    billRate.max = "";
    billRate.mid = "";
    billRate.avg = "";
    markupPct.min = "";
    markupPct.max = "";
    markupPct.mid = "";
    markupPct.avg = "";
    markupAmt.min = "";
    markupAmt.max = "";
    markupAmt.mid = "";
    markupAmt.avg = "";
    payRate.min = "";
    payRate.max = "";
    payRate.mid = "";
    payRate.avg = "";
    punchoutsList.push({
      billRate,
      markupAmt,
      markupPct,
      payRate,
      levelId: levelId,
    });
    return punchoutsList;
  }

  setBuyRateNotes(e) {
    this.tempNotes = e.target.value;
    this.updateview = !this.updateview;
  }

  async rateSearch() {
    const jobStore = this.mobxStore.jobLabelListStore;
    const sSCreateStore = this.mobxStore.scheduledSearchCreateStore;

    const title = jobStore.editJobTitle(jobStore.jobtitle);

    const variables = {
      input: {
        jobTitleId: parseInt(jobStore.jobtitleid, 10),
        jobTitle: title,
        jobLabel: jobStore.joblabel,
        description: jobStore.jobdescription,
        locationIds: sSCreateStore.locations.map((loc) => loc.locationId),
        regionIds: sSCreateStore.regionTags.map((region) => parseInt(region.id, 10)),
        industries: this.tags.map((industry) => industry.id),
        gss: this.showGSS ? this.isGSS : false,
        workerTypeId: this.showWorkerTypes ? this.selectedWorkerType?.id : null,
        workerTypeName: this.showWorkerTypes ? this.selectedWorkerType?.name : null,
      },
    };

    variables.input.rateType = this.searchRateType === 1 ? "Hourly" : "Annual";

    const query = `
      mutation ratesearches($input: FindRateInput!) {
        ratesearches(input: $input) {
          searches {
            ratesearches {
              industry {
                id
                name
              }
              location {
                locationId
                country
                state
                city
                regionId
                regionName
                hasWorksheetSchema
              }
              jobTitle
              jobLabel
              results {
                rateType
                searchId
              }
            }
          }
          errors {
            __typename
            ... on LocationOrRegionRequiredError {
              message
            }
            ... on MaxSearchError {
              message
            }
            ... on IndustryLocationSearchError {
              message
            }
            ... on IndustryRegionSearchError {
              message
            }
            ... on InsufficientSearchesError {
              message
            }
          }
        }
      }`;

    let res = null;
    // this.newForm = false;
    this.networkDetailRateSearch.loading = true;

    try {
      this.displayNewForm();
      res = await this.fetchGraphQL(query, variables);
      // should hit get request
    } catch (e) {
      console.error("Error in getting rate seach result", e);
      this.networkDetailRateSearch.loading = false;
      this.networkDetailRateSearch.error = e;
      if (e.length && e.length > 0) {
        for (let i = 0; i < e.length; i++) {
          console.error(e[i].message);
        }
      } else {
        console.error(e.message);
      }

      // this.errorMessage = "Sorry! Something went wrong on our side and we couldn't complete your search.";
      // this.errorModal.showModal();
      this.messaging.removeAll();
      this.messaging.createMessage(
        "error",
        "Sorry! Something went wrong on our side and we couldn't complete your search."
      );
      return;
    }

    runInAction("rateSearch--success", () => {
      this.networkDetailRateSearch.loading = false;
      this.networkDetailRateSearch.error = null;

      if (res.data.ratesearches.errors && res.data.ratesearches.errors.length > 0) {
        this.messaging.removeAll();
        this.messaging.createMessage("error", res.data.ratesearches.errors[0].message);

        return;
      }

      // Update the searches remaining for PSP users after performing a search
      this.getSearchesRemaining();

      const ratesearchdata = res.data.ratesearches.searches.ratesearches;
      this.searches = new Search(this, { job: {}, industry: {} });
      let obj = [];
      this.local_object = [];
      this.rateSearchData = ratesearchdata.map((ratesearch) => {
        const list = new RateSearchData(this, ratesearch);
        let searchId = [];
        searchId.push(list.results[0].searchId);
        if (list.results.length > 1) {
          searchId.push(list.results[1].searchId);
        }
        list.searchId = searchId;

        let result = {};
        result = {
          jobTitle: list.jobTitle,
          location: list.location,
          industry: list.industry,
          searchId: searchId,
          currency: list.currency,
          results: list.results,
          jobLabel: list.jobLabel,
          jobDescription: list.jobDescription,
        };
        obj.push(result);
        return list;
      });

      if (!_.isEqual(JSON.parse(localStorage.getItem("ratesearchdata")), obj)) {
        const prev_obj = JSON.parse(localStorage.getItem("ratesearchdata"));
        if (prev_obj) Array.prototype.push.apply(obj, prev_obj);
      }
      this.local_object = obj;

      if (this.local_object.length > 0) {
        localStorage.setItem("ratesearchdata", JSON.stringify(this.local_object));
      }
    });

    if (res.data.ratesearches.errors && res.data.ratesearches.errors.length > 0) {
      return;
    } else {
      // HACK: Get the tabs list and click on last one to show most recent search result
      let tabs = [].slice.call(document.querySelectorAll(".rate-search-tab"));
      // let tabs = [].slice.call(document.querySelectorAll(".search-result-tab"));
      if (tabs && tabs.length > 0) {
        tabs[1].click();
        // tabs[0].click();
        this.selectedTab = 1;
        this.newForm = false;
      }

      return {
        ratesearch: res.data.ratesearches.searches.ratesearches,
      };
    }
  }

  async contactRateSpecialist() {
    const jobStore = this.mobxStore.jobLabelListStore;
    const sSCreateStore = this.mobxStore.scheduledSearchCreateStore;
    const reg = [];

    for (let j = 0; j < sSCreateStore.regionTags.length; j++) {
      reg.push(parseInt(sSCreateStore.regionTags[j].id, 10));
    }

    const variables = {
      input: {
        jobTitle: parseInt(jobStore.jobtitleid, 10),
        jobTitleSearch: jobStore.jobtitle,
        jobLabel: jobStore.joblabel,
        description: jobStore.jobdescription,
        locations: sSCreateStore.locations,
        regionIds: reg,
        industries: this.tags,
        subject: this.subject,
        message: this.message,
      },
    };

    const query = `
        mutation SendRateAssistanceMessage($input:SendMessageInput!){
          sendRateAssistance(input:$input){
            ok
          }
        }
      `;
    let res = null;
    this.contactRateSpecialistNetwork.loading = true;

    try {
      res = await this.fetchGraphQL(query, variables);
      // should hit get request
    } catch (e) {
      console.error("Error in getting rate seach result", e);
      this.contactRateSpecialistNetwork.loading = false;
      this.contactRateSpecialistNetwork.error = e;
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      this.contactRateSpecialistNetwork.loading = false;

      console.error("Errors", res.errors);
      this.contactRateSpecialistNetwork.error = {
        message: res.errors[0].message,
      };
      throw res.errors;
    }

    runInAction("contactRateSpecialist--success", () => {
      this.contactRateSpecialistNetwork.loading = false;
      this.contactRateSpecialistNetwork.error = null;
      if (res.data.sendRateAssistance.ok) {
        this.assistanceMessage.success = true;
        this.assistanceMessage.message = "Message has been succesfully sent.";
        this.subject = "";
        this.message = "";
        // this.closeModel();
        // this.resetForm();
        // setTimeout(function () {document.getElementById('alert_msg').style.display='none'; that.assistanceMessage.success = false; that.assistanceMessage.message="";}, 3000);
      }
    });

    return {
      response: res.data.sendRateAssistance.ok,
    };
  }

  resetForm() {
    this.messaging.removeAll();
    this.isGSS = false;
    this.tags = [
      // {
      //   id: 1,
      //   name: "All"
      // }
    ];
    this.mobxStore.scheduledSearchCreateStore.resetJobForm();
    this.mobxStore.scheduledSearchCreateStore.resetLocationForm();
  }

  removeMessage() {
    this.assistanceMessage.success = false;
    this.assistanceMessage.message = "";
  }

  formValidation() {
    const jobStore = this.mobxStore.jobLabelListStore;
    const jobFormValid = jobStore.jobValidation(true);

    const sSCreateStore = this.mobxStore.scheduledSearchCreateStore;
    const locationValid = sSCreateStore.addLocationSearch();

    this.tagsValid = this.tags && this.tags.length > 0 ? true : false;

    return jobFormValid && this.tagsValid && locationValid;
  }

  findRates() {
    const valid = this.formValidation();
    if (valid) {
      this.rateSearch();
    }
  }

  closeModel() {
    this.assistanceMessage.success = false;
    this.showPopup = false;
    this.subject = "";
    this.message = "";
  }

  cRSpeciatist() {
    const valid = this.formValidation();
    if (valid) {
      this.showPopup = true;
    }
  }

  onSubjectChange(e) {
    this.subject = e.target.value;
  }

  changeMessageText(e) {
    this.message = e.target.value;
  }

  async rateSearchDetail(searchId) {
    // console.log("requesting details for:", searchId);
    this.localRateSearchData = [];
    const variables = {
      searchId: searchId,
    };

    this.showSearchPeek = true;
    const query = `
        query rateSearchDetail($searchId : [Int]!) {
          viewer {
            ratesearch(id: $searchId) {
              searchId
              currency {
                symbol
              }
              rateType
              isDraft
              isGlobalSupplierSearch
              workerTypeName
              country
              city
              state
              region {
                name
              }
              industry{
                legacyId
                value
              }
              marketRate{
                markupPct
                billRate
                payRate
              }
              buyrates {
                buyrateId
                name
                createdBy {
                  username
                }
                active
                created
                punchouts {
                  punchoutId
                  levelId
                  levelName
                  levelRomanNumeral
                  level {
                    legacyId
                    value
                  }
                  created
                  payRateMin
                  payRateMax
                  markupPct
                  billRateMin
                  billRateMax
                  salaryMin
                  salaryMax
                }
              }
              ratesData
              rates {
                markupPct {
                  min
                  max
                  avg
                  mid
                }
                billRate {
                  min
                  max
                  avg
                  mid
                }
                markupAmt {
                  min
                  max
                  avg
                  mid
                }
                payRate {
                  min
                  max
                  avg
                  mid
                }
              }
              job {
                jobLabel
                jobTitle
                jobTitleId
                jobDescription
              }
              jobDescription
              createdDate
            }
          }
        }
        `;

    let res = null;
    this.networkRateDetailSearch.loading = true;

    try {
      res = await this.fetchGraphQL(query, variables);
      // should hit get request
    } catch (e) {
      console.error("Error in getting rate search detail", e);
      // TODO: Handle errors properly
      this.networkRateDetailSearch.loading = false;
      this.networkRateDetailSearch.error = e;
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      this.networkRateDetailSearch.loading = false;
      console.error("Errors", res.errors);
      return;
    }

    runInAction("rateSearchDetail--success", () => {
      // console.log("details response for:", searchId, "\n", JSON.stringify(res.data, null, 2));
      this.localRateSearchData = res.data.viewer.ratesearch;
      const Hourlydata = this.localRateSearchData.find((rates) => rates.rateType === 1);
      const Annualdata = this.localRateSearchData.find((rates) => rates.rateType === 2);
      if (Hourlydata && Hourlydata.buyrates.length > 0) {
        this.existing = true;
        this.useBuyRate(Hourlydata.buyrates, "Hourly");
      }

      if (Annualdata && Annualdata.buyrates.length > 0) {
        this.existing = true;
        this.useBuyRate(Annualdata.buyrates, "Annual");
      }
      // const ratesearchdata = res.data.viewer.ratesearch[0];
      // const ratesearches = res.data.viewer.ratesearch;

      const searchData = this.localRateSearchData.find(
        (rates) => rates.rateType === this.rateType
      );
      if (!searchData) {
        this.networkRateDetailSearch.loading = false;
        console.error("No search data found for search id:", searchId);
        console.error("localRateSearchData:", this.localRateSearchData);
        return;
      }
      this.networkRateDetailSearch.loading = false;
      this.searches = new Search(this, searchData);
      this.searches.getSearchResults();
    });

    return {
      ratesearch: res.data.viewer.ratesearch,
    };
  }

  async getSearchesRemaining() {
    const query = `
      query getSearchesRemaining {
        viewer {
          user {
            resourceAllocations(resourceType: SEARCH) {
              id
              balance
              isUnlimited
            }
            client {
              resourceAllocations(resourceType : SEARCH) {
                id
                balance
                resource
              }
            }
          }
        }
      }
    `;

    let res = null;
    this.networkRateDetailSearch.loading = true;

    try {
      res = await this.fetchGraphQL(query, null);
      // should hit get request
    } catch (e) {
      console.error("Error in getting searches remaining for user", e);
      // TODO: Handle errors properly
      this.networkRateDetailSearch.loading = false;
      this.networkRateDetailSearch.error = e;
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      this.networkRateDetailSearch.loading = false;
      console.error("Errors", res.errors);
      return;
    }

    runInAction("getSearchesRemaining--success", () => {
      this.networkRateDetailSearch.loading = false;
      this.resourceAllocations = res.data.viewer.user.resourceAllocations;
      this.clientResourceAllocations = res.data.viewer.user.client.resourceAllocations;
    });
  }

  async getIndustries() {
    const query = `
      query industries {
        viewer {
        industries {
          edges {
            node {
              legacyId
              value
            }
          }
          totalCount
         }
        }
      }
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, {});
      // should hit get request
    } catch (e) {
      console.error("Error in getting industries", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      throw res.errors;
    }

    runInAction("getIndustries--success", () => {
      const industriesData = res.data.viewer.industries.edges;

      this.industries = industriesData.map((industry) => {
        return new Industry(this, industry.node);
      });
    });

    return {
      response: res.data.viewer.industries.edges,
    };
  }

  async getClientLibraries() {
    const query = {
      query: `
        query getClientLibraries {
          clientLibraries(order: {field: NAME, direction: ASC}) {
            edges {
              node {
                databaseId
                name
              }
            }
          }
        }
      `,
      variables: null,
    };

    let res = null;

    try {
      res = await this.fetchAPI("taste/", query);
      // should hit get request
    } catch (e) {
      console.error("Error in getClientLibraries", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      throw res.errors;
    }

    return res.data.data.clientLibraries.edges.map((e) => ({
      value: e.node.databaseId,
      label: e.node.name,
    }));
  }

  async getClientLibraryJobTitles(searchText) {
    const variables = {
      searchParam: searchText === undefined ? "" : searchText,
    };

    if (this.clientJobLibraryId !== null) {
      variables.libraryId = this.clientJobLibraryId;
    }

    const query = `
      query jobTitleList($searchParam: String, $libraryId: ID) {
        viewer {
          jobTitles(search: $searchParam, page:1, pageSize:500, libraryId: $libraryId) {
            results {
              id
              title
              slug
              showDescription
              isJobLabel
              category
              shareInfo {
                jobLabelId
                isMine
                sharedBy {
                  firstName
                  lastName
                }
              }
              collection
              clientJobLibraryInfo {
                adhocCountries
                categoryName
                certifiedCountries
                created
                isCertified
                mappedRawJobTitleId
                mappedRawJobTitleTitle
                searchableCountries
              }
            }
          }
        }
      }
    `;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
      // should hit get request
    } catch (e) {
      console.error("Error in getting job title", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }

    return {
      jobTitles: res.data.viewer.jobTitles.results,
    };
  }

  async locationTitle(searchText) {
    const variables = {
      searchParam: searchText === undefined ? "" : searchText,
    };

    if (this.searchableCountries !== null) {
      variables.countryIds = this.searchableCountries;
    }

    const query = `
      query locations($searchParam: String!, $countryIds: [Int]) {
        viewer {
          locations(search: $searchParam, countryIds: $countryIds) {
            title
            subtitle
            type
            fullTitle
            fullSubtitle
            countryId
            locationId
          }
        }
      }
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
      // should hit get request
    } catch (e) {
      console.error("Error in getting location title", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      throw res.errors;
    }

    runInAction("locationTitle--success", () => {});

    return {
      locationTitle: res.data.viewer.locations,
    };
  }

  handleWorkerTypeChange(workerType: ?WorkerType) {
    this.selectedWorkerType = workerType;
  }

  async getWorkerTypes(
    countryId: number,
    industryId?: ?number
  ): Promise<Array<WorkerType>> {
    const variables = { countryId, industryId };

    const query = `
      query workerTypes($countryId: Int!, $industryId: Int) {
        viewer {
          workerTypes(countryId: $countryId, industryId: $industryId) {
            id
            name
            description
          }
        }
      }
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
      // should hit get request
    } catch (e) {
      console.error("Error in getting worker types", e);
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      throw res.errors;
    }

    runInAction("getWorkerTypes--success", () => {
      this.workerTypes = res?.data?.viewer?.workerTypes || [];
    });

    return this.workerTypes;
  }

  selectClientJobLibrary(jobLibrarySelected) {
    // NOTE: 0 means All Libraries option selected
    if (jobLibrarySelected.value === 0) {
      this.clientJobLibraryId = null;
    } else {
      this.clientJobLibraryId = jobLibrarySelected.value;
    }
  }

  async countries() {
    const query = `
      query countries {
        viewer {
          countries {
            name
            locationId
            isoCode
          }
        }
      }
    `;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, {});
      // should hit get request
    } catch (e) {
      console.error("Error in getting countries", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      throw res.errors;
    }

    let countries = res.data.viewer.countries.map((c) => {
      c.countryId = c.locationId;
      return c;
    });
    if (this.searchableCountries !== null) {
      countries = _.filter(countries, (country) => {
        return _.includes(this.searchableCountries, country.locationId);
      });
    }

    return {
      countries,
    };
  }

  async statesList(id, page) {
    const query = `
        query states {
          viewer {
            states(id: "${id}", page: ${page}) {
                totalCount
                results {
                id
                title
             }
           }
         }
       }
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, {});
      // should hit get request
    } catch (e) {
      console.error("Error in getting countries", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }
    const states = res.data.viewer.states.results.map((s) => {
      s.countryId = id;
      return s;
    });
    runInAction("statesList--success", () => {
      this.states = states;
    });

    return {
      states: states,
      totalCount: res.data.viewer.states.totalCount,
    };
  }

  async citiesList(id, page) {
    const query = `
      query cities {
        viewer {
          cities(id: "${id}", page: ${page}) {
            totalCount
            results{
            id
            title
           }
         }
       }
     }
    `;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, {});
      // should hit get request
    } catch (e) {
      console.error("Error in getting countries", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }

    const selectedState = this.states.find((s) => s.id === id);
    const cities = res.data.viewer.cities.results.map((c) => {
      c.countryId =
        selectedState && selectedState.countryId ? selectedState.countryId : 0;
      return c;
    });
    runInAction("citiesList--success", () => {
      this.cities = cities;
    });

    return {
      cities: cities,
      totalCount: res.data.viewer.cities.totalCount,
    };
  }

  async saveBuyRate(searchId) {
    const customBuyRate = [];
    let type;
    if (this.rateType === 1) {
      type = this.buyRateData.Hourly;
    } else if (this.rateType === 2) {
      type = this.buyRateData.Annual;
    }

    type.forEach((buyrate) => {
      const newpunch = [];
      buyrate.punchoutsList.forEach((punch) => {
        newpunch.push({
          billRateMin: punch.billRate.min,
          billRateMax: punch.billRate.max,
          markupPct: punch.markupPct.avg,
          payRateMax: punch.payRate.max,
          payRateMin: punch.payRate.min,
          levelId: punch.levelId,
        });
      });
      const newBuyRate = {
        name: buyrate.name,
        notes: buyrate.notes,
        punchouts: newpunch,
      };
      customBuyRate.push(newBuyRate);
    });

    const variables = {
      searchId: searchId,
      buyRates: customBuyRate,
    };

    const query = `
           mutation createUpdateDeletedBuyRate($searchId: Int!, $buyRates : [BuyRateInput]){
            createUpdateDeleteBuyRate(input: {searchId: $searchId, buyrates: $buyRates}){
              buyRates{
                name,
                notes,
                punchouts{
                  billRateMin,
                  billRateMax,
                  payRateMin,
                  payRateMax,
                  markupPct
                },
                buyrateId
              }
            }
          }
          `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
      // should hit get request
    } catch (e) {
      console.error("Error in saving buy rate", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }

    runInAction("saveBuyRate--success", () => {
      this.buyRatesValid = false;

      let punch = this.createPunchoutList(1);
      let date = new Date();
      date = date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();

      this.buyRateData = {
        Hourly: [
          {
            name: `Punchout ${date}`,
            showNotes: false,
            notes: "",
            index: 0,
            punchoutsList: punch,
          },
        ],
        Annual: [
          {
            name: `Punchout ${date}`,
            showNotes: false,
            notes: "",
            index: 0,
            punchoutsList: punch,
          },
        ],
      };
      this.punchoutSuccess = true;
      this.updateview = !this.updateview;
    });

    return {
      res: res,
    };
  }

  async rateSearchOtherInfo(data) {
    const jobStore = this.mobxStore.jobLabelListStore;
    let variables = {
      country: data.country,
      jobTitle: jobStore.editJobTitle(data.job.jobTitle),
      state: data.state || "",
    };

    const query = `
          query OtherInfo($country: String!, $state: String!, $jobTitle: String!) {
          viewer {
           otherInfo(country: $country, jobTitle: $jobTitle, state: $state) {
            education {
              levelRequired {
                category {
                  name
                  score {
                    content
                    scale
                  }
                }
              }
            }
            title
            desc
            education {
              levelRequired {
                category {
                  name
                  score {
                    content
                    scale
                  }
                }
              }
            }
            skills {
              score {
                content
                scale
              }
              name
            }
            tools {
              category {
                example
                title {
                  content
                  id
                }
                related
              }
            }
            technology {
              category {
                example {
                  content
                  hotTechnology
                }
                title {
                  id
                  content
                }
                related
              }
            }
            tasks
            rates {
              seriesId
              data {
                value
                period
                year
                periodName
              }
            }
          }
        }
      }`;
    let res = null;

    this.networkOtherInfo.loading = true;

    try {
      res = await this.fetchGraphQL(query, variables);
      // should hit get request
    } catch (e) {
      console.error("Error in getting rate seach result", e);
      this.networkOtherInfo.loading = false;
      this.networkOtherInfo.error = e;
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      this.networkOtherInfo.loading = false;
      this.networkOtherInfo.error = {
        message:
          "There was an error retrieving the data for this page. Please reload and try again later.",
      };
      return;
    }

    runInAction("rateSearchOtherInfo--success", () => {
      if (this.networkOtherInfo.logGraphQLError("Other Info query", res)) {
        // TODO: Display user friendly error message
        return res;
      }
      this.education_data = res.data.viewer.otherInfo?.education;
      this.rates = res.data.viewer.otherInfo?.rates;
      this.educationDescription = res.data.viewer.otherInfo?.desc;
      this.networkOtherInfo.loading = false;
      this.networkOtherInfo.error = null;
    });

    return {
      res: res,
    };
  }

  changeLocationTitleText(e: Object) {
    if (e.length > 0) {
      this.locationtitle = e[0].title;
    }
  }

  displayNewForm() {
    const ul = document.getElementById("sidebarmenu");
    const items = ul.getElementsByTagName("li");

    for (let i = 0; i < items.length; ++i) {
      if (items[i].id !== "rateSearch") {
        items[i].classList.remove("active");
      }
    }
    this.resetForm();
    this.newForm = true;
    document.getElementById("rateSearchTab").classList.add("active");
    this.rateDetail = false;
    this.selectedTab = 0;
  }

  handleIndustryChange(industries) {
    this.tags = industries;
  }

  async requestSearches(numSearches, reason) {
    let variables = {
      request: {
        source: this.clientResourceAllocations[0].id,
        destination: this.resourceAllocations[0].id,
        amount: numSearches,
        message: reason,
      },
    };

    const query = `
      mutation transferRequest($request: TrapsRequestTransferResourceInput!) {
        trapsRequestTransferResource(input: $request) {
          result {
            ... on TrapsTransferRequest {
              uuid
              amount
            }
            __typename
          }
        }
      }
    `;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      throw e; // Prevent success action from running
    }

    return res;
  }
}
