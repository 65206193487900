/**
 * @generated SignedSource<<5486367af59eda4e64c56cceb87603ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PayDifferenceMap_viewer$data = {
  readonly locationsByIds: ReadonlyArray<{
    readonly lat: string;
    readonly locationId: string;
    readonly lon: string;
  } | null> | null;
  readonly " $fragmentType": "PayDifferenceMap_viewer";
};
export type PayDifferenceMap_viewer$key = {
  readonly " $data"?: PayDifferenceMap_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"PayDifferenceMap_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "locationIDs"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "viewer"
      ],
      "operation": require('./PayDifferenceMapRefetch.graphql')
    }
  },
  "name": "PayDifferenceMap_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "locationIDs"
        }
      ],
      "concreteType": "LocationListLookupNode",
      "kind": "LinkedField",
      "name": "locationsByIds",
      "plural": true,
      "selections": [
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "locationId",
            "storageKey": null
          },
          "action": "NONE",
          "path": "locationsByIds.locationId"
        },
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lat",
            "storageKey": null
          },
          "action": "NONE",
          "path": "locationsByIds.lat"
        },
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lon",
            "storageKey": null
          },
          "action": "NONE",
          "path": "locationsByIds.lon"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "e9ef48d6b7777354485487b810b087ff";

export default node;
