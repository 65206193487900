// @flow

import moment from "moment";
import { action } from "mobx";
import FilterObject, { DateRangeFilter, FilterWithSort } from "../Filter";
import type { FilterColumn, DjangoFilterObject } from "../Filter";
import Sort, { SORT_DIRECTION } from "../Sort";
import SortState from "../SortState";

const djangoDateFormat = "YYYY-MM-DD";

/*
 *  GraphQL-based filter
 */
// $FlowFixMe: Need to make the superclass properties covariant to subtype
export default class CreatedOnFilter extends DateRangeFilter(FilterWithSort) {
  sortState: SortState;
  allowMultipleItemSelection: boolean;
  applyFilter: (FilterColumn, DjangoFilterObject) => void;
  removeFilter: (FilterColumn) => void;
  applySort: (FilterColumn, Sort) => void;
  removeSort: (FilterColumn) => void;
  buildQueryFilter: () => FilterObject;

  constructor(
    store: Object,
    column: FilterColumn,
    applyFilter: (FilterColumn, FilterObject) => void,
    applySort: (FilterColumn, Sort) => void,
    removeFilter: (FilterColumn) => void,
    removeSort: (FilterColumn) => void
  ) {
    super(store, column);
    this.allowMultipleItemSelection = false; // only one item selected at a time
    this.applyFilter = applyFilter;
    this.applySort = applySort;
    this.removeFilter = removeFilter;
    this.removeSort = removeSort;

    // Used for PCE, for PCE it is CREATED for rest it is CREATE_DATE
    if (
      column === "pcDateRange" ||
      column === "regionDateRange" ||
      column === "punchoutsDateRange"
    ) {
      this.sortState = new SortState("CREATED");
    } else {
      this.sortState = new SortState("CREATE_DATE");
    }

    this.buildQueryFilter = action(this.buildQueryFilter.bind(this));
    this.isCreatedFilter = true;
  }

  buildQueryFilter() {
    if (!this.fromDate && !this.toDate) {
      // no value selected, just return
      return null;
    }

    if (
      this.filter &&
      this.filter.queryVars.fromDate === this.fromDate &&
      this.filter.queryVars.toDate === this.toDate
    ) {
      return this.filter; // no change
    }

    let params = [];
    let args = [];
    let vars = {};

    if (this.fromDate) {
      vars.fromDate = this.fromDate.toISOString();
      params.push("$fromDate: DateTime");
      args.push("fromDate: $fromDate");
    }

    if (this.toDate) {
      vars.toDate = this.toDate.toISOString();
      params.push("$toDate: DateTime");
      args.push("toDate: $toDate");
    }

    return new FilterObject(params.join(", "), args.join(", "), vars);
  }
}

/*
 *  Django-based filyter
 */
export class DjangoCreatedOnFilter extends DateRangeFilter(FilterWithSort) {
  sortState: SortState;
  allowMultipleItemSelection: boolean;
  applyFilter: (FilterColumn, DjangoFilterObject) => void;
  removeFilter: (FilterColumn) => void;
  applySort: (FilterColumn, Sort) => void;
  removeSort: (FilterColumn) => void;
  buildQueryFilter: () => FilterObject;

  constructor(
    store: Object,
    column: FilterColumn,
    applyFilter: (FilterColumn, DjangoFilterObject) => void,
    applySort: (FilterColumn, Sort) => void,
    removeFilter: (FilterColumn) => void,
    removeSort: (FilterColumn) => void
  ) {
    super(store, column);

    this.allowMultipleItemSelection = false; // only one item selected at a time
    this.sortState = new SortState(column);

    this.applyFilter = applyFilter;
    this.applySort = applySort;
    this.removeFilter = removeFilter;
    this.removeSort = removeSort;
    this.buildQueryFilter = action(this.buildQueryFilter.bind(this));
  }

  buildQueryFilter(): DjangoFilterObject {
    if (!this.fromDate && !this.toDate) {
      // no value selected, just return
      return null;
    }

    const result = {};

    if (this.fromDate) {
      const fromDateKey = [this.column, "gte"].join("__");
      const fromDateValue = moment
        .utc(this.fromDate, djangoDateFormat)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .format(moment.defaultFormat);

      result[fromDateKey] = fromDateValue;
    }

    if (this.toDate) {
      const toDateKey = [this.column, "lte"].join("__");
      const toDateValue = moment
        .utc(this.toDate, djangoDateFormat)
        .hour(23)
        .minute(59)
        .second(59)
        .millisecond(999)
        .format(moment.defaultFormat);

      result[toDateKey] = toDateValue;
    }

    return result;
  }

  buildQuerySort() {
    if (!this.sortState || !this.sortState.direction) return null;

    return new Sort(
      this.sortState.direction === SORT_DIRECTION.DESC
        ? "-" + this.sortState.field
        : this.sortState.field
    );
  }
}
