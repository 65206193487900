import React from "react";
import { List } from "immutable";

import { CSS } from "../../stitches.config";
import type {
  ImmutableMap,
  ImmutableOrderedMap,
  ImmutableList,
  ImmutableSet,
} from "../../types/immutable";
import { DjangoPaginatedResponse } from "../../types/django";

export const OrderDirections = {
  NO: "no",
  ASC: "asc",
  DSC: "dsc",
} as const;

export type OrderDirectionsType = (typeof OrderDirections)[keyof typeof OrderDirections];

export type OrderIconsObject = { [key in OrderDirectionsType]: React.ReactNode };

export const FilterTypes = {
  VALUES_CHECKLIST: "values_checklist",
  VALUES_ICONTAINS: "values_icontains",
  DATES_RANGE: "dates_range",
  NUMBERS_RANGE: "numbers_range",
  ENUMERATION: "enumeration",
} as const;

export type FilterTypesType = (typeof FilterTypes)[keyof typeof FilterTypes];

export const FilterPopupSizes = {
  SMALL: "small",
  REGULAR: "regular",
  WIDE: "wide",
  XWIDE: "xwide",
} as const;

export type FilterPopupSizesType =
  (typeof FilterPopupSizes)[keyof typeof FilterPopupSizes];

// Table schema types

export type ColumnConfigObject<RD = RowData> = {
  uniqueKey: string;
  title?: string | React.ReactElement | (() => string | React.ReactElement);
  getter: ValueGetterFunc<RD>;
  formatter?: ValueFormatterFunc<RD>;
  // ordering
  sortable?: boolean;
  comparators?: ComparatorsConfig;
  // filtering
  filterable?: boolean;
  filterType?: FilterTypesType;
  filterKey?: string;
  filterOptions?: FilterOptionsList;
  filterExtraProps?: Object;
  // styles
  css?: CSS;
  headCss?: CSS;
  popupSize?: FilterPopupSizesType;
  // misc
  fixed?: boolean;
  link?: boolean;
};
export type GroupConfigObject = {
  uniqueKey: string;
  title?: string;
  css?: CSS;
  columns: number[];
};
export type SchemaConfigObject<RD = RowData> = {
  columns: ColumnConfigObject<RD>[];
  groups: GroupConfigObject[];
};

// Table filters types

export type OrderConfigObject = {
  key: string;
  direction: OrderDirectionsType;
};
export type OrderConfigMap = ImmutableMap<OrderConfigObject>;

export type FilterConfigObject = {
  order: {
    direction: OrderDirectionsType;
  };
  filter: {
    values: List<any>;
    search: string | null;
    type: FilterTypesType;
  };
};
export type FilterConfigMap = ImmutableMap<FilterConfigObject>;
export type FiltersConfigOrderedMap = ImmutableOrderedMap<string, FilterConfigMap>;
export type FiltersQueryOrderedMap = ImmutableOrderedMap<string, any>;
export type ColumnsFiltersConfigOrderedMap = ImmutableOrderedMap<
  string,
  FiltersConfigOrderedMap
>;
export type ColumnsFiltersQueriesOrderedMap = ImmutableOrderedMap<
  string,
  FiltersQueryOrderedMap
>;

export type TransformRestfulFiltersFunction = (
  filters: FiltersConfigOrderedMap,
  multimode?: boolean
) => [FiltersQueryOrderedMap, ColumnsFiltersQueriesOrderedMap];

// Table incoming props types

export type RowData = ImmutableMap<any>;
export type TableData<RD = RowData> = ImmutableList<RD>;
export type RestfulTableData<RD = RowData> = ImmutableOrderedMap<string | number, RD>;

export type FilterOptionMap = ImmutableMap<{
  label: string;
  value: any;
  valuesList: ImmutableList<any>;
}>;
export type FilterOptionsList = ImmutableList<FilterOptionMap>;

export type ComparatorFunc = (a: any, b: any) => number;
export type ComparatorsConfig = { [key in OrderDirectionsType]: ComparatorFunc };

export type ValueGetterFunc<RD = RowData> = (rowData: RD, rowIdx: number) => any;
export type ValueFormatterFunc<RD = RowData, V = any> = (
  value: V,
  rowData: RD
) => React.ReactChild | null;

export type RowIdGetterFunction<RD = RowData> = (rowData: RD) => string | number;
export type CellOnClickHandler<RD = RowData, V = any> = (
  rowData: RD,
  value: V,
  rowId: number | string,
  uniqueKey: string
) => void;
export type RowOnClickHandler<RD = RowData> = (
  rowData: RD,
  rowId: string | number
) => void;

// Table data state types

export interface TableConfigObject {
  activePage: number;
  filters: FiltersConfigOrderedMap;
  columnsFilters: ColumnsFiltersConfigOrderedMap;
}

export interface TableDataStateObject<RD = RowData> extends TableConfigObject {
  data: TableData<RD>;
}

export interface RestfulTableConfigObject {
  activePage: number;
  itemsPerPage: number;
  filters: FiltersConfigOrderedMap;
  filtersQuery: FiltersQueryOrderedMap;
  columnsFiltersQueries: ColumnsFiltersQueriesOrderedMap;
}

export interface RestfulTableDataStateObject<RD = RowData>
  extends RestfulTableConfigObject {
  data: RestfulTableData<RD>;
  itemsCount: number;
}

export interface SortableTableConfigObject {
  order: OrderConfigMap;
}

export interface SortableTableDataObject<RD = RowData> extends SortableTableConfigObject {
  data: TableData<RD>;
}

// Table data providers

export type DataProvider<RD = RowData> = (
  filters?: FiltersConfigOrderedMap
) => TableData<RD>;

export type UrlQueryObject = { [key: string]: any };
export type FiltersQueryObject = { [key: string]: any };

export type DataProviderRestful<RD = RowData> = (
  urlQuery: UrlQueryObject,
  filtersQuery: FiltersQueryObject,
  nextStateUpdates?: Partial<RestfulTableDataStateObject<RD>>
) => Promise<Partial<RestfulTableDataStateObject<RD>>>;

export type FiltersDataProviderRestful = (
  urlQuery: UrlQueryObject,
  filtersQuery: FiltersQueryObject
) => Promise<DjangoPaginatedResponse<any> | any[]>;

// Table filters handlers

export type OnApplyFilterFunction<DS> = (
  filterKey: string,
  filterValue: FilterConfigMap,
  nextDataState: DS
) => void;
export type OnClearFiltersFunction = () => void;
export type OnChangePageFunction = (page: number) => void;
export type OnChangeItemsPerPageFunction = (itemsPerPage: number) => void;
export type OnChangeDataFunction<DS> = (dataState: DS) => void;

// table row editor

export interface RowEditorComponentProps<RD = RowData> {
  data: RD;
  onApply?: (...args: any[]) => any;
  onCancel?: (...args: any[]) => any;
  onDelete?: (...args: any[]) => any;
}
export type RowEditorComponent<RD = RowData> = React.FunctionComponent<
  RowEditorComponentProps<RD>
>;

// table config

export type TableConfigOptionObject<T = string> = {
  uniqueKey: T;
  title: string;
};
export type TableConfigOptionMap<T = string> = ImmutableMap<TableConfigOptionObject<T>>;
export type TableConfigOptionsList<T = string> = ImmutableList<TableConfigOptionMap<T>>;

export type VisibleColumnsSet<T = string> = ImmutableSet<T>;
export type VisibleColumnsChangeHandler<T = string> = (
  value: VisibleColumnsSet<T> | T,
  isSelected: boolean
) => void;
