import React from "react";
import PropTypes from "prop-types";
import LaddaButton from "./LaddaButton";
import Button from "../../components/lib/Button";
/**
 * LaddaPromiseButton is a wrapper around LaddaButton that has specific functionality for a Button's on click hanldler.
 * It will animate the ladda button with a loader if the onClick function passed in via props returns a Promise.
 * It will continue to animate the ladda button until the promise is resolved or rejected.
 */
export default class LaddaPromiseButton extends React.Component {
  state = {
    promiseFiring: false,
  };

  static defaultProps = {
    laddaAnimationStyle: "zoom-in",
    buttonComponentClass: Button,
  };

  static propTypes = {
    laddaAnimationStyle: PropTypes.string,
    buttonComponentClass: PropTypes.func,
    onClick: PropTypes.func.isRequired,
  };

  componentWillUnmount() {
    this.promiseDone = () => null;
  }

  promiseDone() {
    this.setState({ promiseFiring: false });
  }

  handleOnClick = (...args) => {
    const clickResult = this.props.onClick(...args);
    if (clickResult && typeof clickResult.then === "function") {
      this.setState({ promiseFiring: true });
      const promiseDone = (v) => (r) => {
        this.promiseDone();

        // Need to explicitly reject/resolve or we swallow errors quietly. Which is generally bad.
        return v(r);
      };
      return clickResult.then(
        promiseDone((r) => r),
        promiseDone((r) => Promise.reject(r))
      );
    }
    return clickResult;
  };

  render() {
    const {
      buttonComponentClass: Button,
      laddaAnimationStyle,
      children,
      className,
      ...props
    } = this.props;
    return (
      <LaddaButton
        active={this.state.promiseFiring}
        style={laddaAnimationStyle}
        className={className}
      >
        <Button css={{ fontSize: "14px" }} {...props} onClick={this.handleOnClick}>
          {children}
        </Button>
      </LaddaButton>
    );
  }
}
