import React, { useCallback } from "react";

import AgreeButton from "./buttons/AgreeButton";
import DisagreeButton from "./buttons/DisagreeButton";
import CommentButton from "./buttons/CommentButton";
import IDontKnowButton from "./buttons/IDontKnowButton";
import { ButtonGroupRight } from "../../../../components/lib/ButtonGroup";
import { useVal5KPublicContext } from "../../context/Val5KPublicContext";
import {
  getLowHighRateResultsForJob,
  getLowHighRatesFeedbackForJob,
} from "../../../validator5K_admin/components/RatesTableOrdered/utils";
import { RATE_FEEDBACK_STATUS_TYPES } from "../../types";
import { compareDecimals } from "../../constants";
import { useCreateRateFeedbackAPI, useUpdateRateFeedbackAPI } from "../../hooks";
import { useAttemptDataContext } from "../../context/AttemptDataContext";
import { useRateDisplayContext } from "../../../validator5K_admin/context/RateDisplayContext";
import { useOrderedRatesFeedbackContext } from "../../context/feedback/OrderedRatesFeedbackContext";

import type {
  RATE_FEEDBACK_STATUS_TYPES_TYPE,
  RatesFeedbackDataObject,
} from "../../../validator5K_admin/types";

type FeedbackValues = {
  payRateMin?: number | null;
  payRateMax?: number | null;
  comment: string | null;
  status: RATE_FEEDBACK_STATUS_TYPES_TYPE;
};

export default function FeedbackButtonsBlock(props: { jobId: number; show: boolean }) {
  const { jobId, show } = props;
  const {
    jobsData,
    feedbackData: ratesFeedback,
    updateFeedbackState,
    updateAttemptFeedbackState,
  } = useOrderedRatesFeedbackContext();
  const { attemptId } = useAttemptDataContext();
  const {
    resultingCurrencySymbol,
    convertToDisplayCurrency,
    convertFromDisplayCurrency,
  } = useRateDisplayContext();
  const { closeConfirmationModal } = useVal5KPublicContext();

  // rates values
  const { lowLevelRates, highLevelRates } = getLowHighRateResultsForJob(jobsData, jobId!);
  const { lowLevelRatesFeedback, highLevelRatesFeedback } = getLowHighRatesFeedbackForJob(
    jobsData,
    jobId!,
    ratesFeedback
  );

  // status
  const initialFeedbackStatus =
    lowLevelRatesFeedback.get("status") ||
    highLevelRatesFeedback.get("status") ||
    RATE_FEEDBACK_STATUS_TYPES.NOT_VALIDATED;

  // comment
  const initialFeedbackComment =
    lowLevelRatesFeedback.get("comment") || highLevelRatesFeedback.get("comment");

  // fetch

  const createRateFeedback = useCreateRateFeedbackAPI();
  const updateRateFeedback = useUpdateRateFeedbackAPI();

  // handlers

  const handleApplyFeedbackChanges = useCallback(
    async (values: Partial<FeedbackValues>) => {
      let {
        comment,
        status = initialFeedbackStatus,
        payRateMin: nextPayRateMinFeedback,
        payRateMax: nextPayRateMaxFeedback,
      } = values;

      // convert back from display currency
      nextPayRateMinFeedback = convertFromDisplayCurrency(nextPayRateMinFeedback);
      nextPayRateMaxFeedback = convertFromDisplayCurrency(nextPayRateMaxFeedback);

      const lowLevelRatesId = lowLevelRates.get("id");
      const highLevelRatesId = highLevelRates.get("id");
      const lowLevelRatesFeedbackId = lowLevelRatesFeedback?.get("id");
      const highLevelRatesFeedbackId = highLevelRatesFeedback?.get("id");

      const hasLowLevelRatesFeedback = lowLevelRatesFeedbackId != null;
      const hasHighLevelRatesFeedback = highLevelRatesFeedbackId != null;
      const payRateMin = lowLevelRates.get("pay_rate_min");
      const payRateMax = highLevelRates.get("pay_rate_max");

      if (
        status === RATE_FEEDBACK_STATUS_TYPES.APPROVED ||
        status === RATE_FEEDBACK_STATUS_TYPES.DONT_KNOW
      ) {
        nextPayRateMinFeedback = null;
        nextPayRateMaxFeedback = null;
      } else if (status === RATE_FEEDBACK_STATUS_TYPES.DENIED) {
        // if feedback values are the same as original rates values
        if (
          !!payRateMin &&
          !!nextPayRateMinFeedback &&
          compareDecimals(payRateMin, nextPayRateMinFeedback) === 0
        ) {
          nextPayRateMinFeedback = null;
        }
        if (
          payRateMax &&
          nextPayRateMaxFeedback &&
          compareDecimals(payRateMax, nextPayRateMaxFeedback) === 0
        ) {
          nextPayRateMaxFeedback = null;
        }
      }

      // build payload for 2 API requests
      const basePayload: Partial<RatesFeedbackDataObject> = {
        attempt: attemptId,
        job: jobId!,
        status,
        comment: status === RATE_FEEDBACK_STATUS_TYPES.DONT_KNOW ? null : comment || null,
      };
      const lowRateFeedbackRequestPayload: Partial<RatesFeedbackDataObject> = {
        ...basePayload,
        rate_result: lowLevelRatesId,
        pay_rate_min: nextPayRateMinFeedback,
      };
      const highRateFeedbackRequestPayload: Partial<RatesFeedbackDataObject> = {
        ...basePayload,
        rate_result: highLevelRatesId,
        pay_rate_max: nextPayRateMaxFeedback,
      };

      // make 2 parallel requests to API
      const lowFeedbackPromise = hasLowLevelRatesFeedback
        ? updateRateFeedback(lowLevelRatesFeedbackId, lowRateFeedbackRequestPayload)
        : createRateFeedback(lowRateFeedbackRequestPayload);
      const highFeedbackPromise = hasHighLevelRatesFeedback
        ? updateRateFeedback(highLevelRatesFeedbackId, highRateFeedbackRequestPayload)
        : createRateFeedback(highRateFeedbackRequestPayload);
      const [lowFeedback, highFeedback] = await Promise.all([
        lowFeedbackPromise,
        highFeedbackPromise,
      ]);

      // hide modal
      closeConfirmationModal();

      // update application state
      if (lowFeedback?.size) {
        updateAttemptFeedbackState(lowFeedback.get("rate_result"), lowFeedback);
        updateFeedbackState(lowFeedback.get("rate_result"), lowFeedback.toJS());
      }
      if (highFeedback?.size) {
        updateAttemptFeedbackState(highFeedback.get("rate_result"), highFeedback);
        updateFeedbackState(highFeedback.get("rate_result"), highFeedback.toJS());
      }
    },
    [
      initialFeedbackStatus,
      convertFromDisplayCurrency,
      highLevelRates,
      highLevelRatesFeedback,
      lowLevelRates,
      lowLevelRatesFeedback,
      jobId,
      attemptId,
      createRateFeedback,
      updateRateFeedback,
      updateFeedbackState,
      updateAttemptFeedbackState,
      closeConfirmationModal,
    ]
  );

  // render

  return (
    <ButtonGroupRight
      nogap
      css={{
        height: "$full",
        position: "absolute",
        top: 0,
        right: show ? 0 : "-410px",
        backgroundColor: "$white",
        transition: "right 0.15s ease-in-out",
      }}
    >
      <AgreeButton
        feedbackStatus={initialFeedbackStatus}
        feedbackComment={initialFeedbackComment}
        onApply={handleApplyFeedbackChanges}
      />
      <DisagreeButton
        payRateMin={
          convertToDisplayCurrency(
            lowLevelRatesFeedback.get("pay_rate_min") ??
              lowLevelRates.get("pay_rate_min"),
            2
          ) ?? 0
        }
        payRateMax={
          convertToDisplayCurrency(
            highLevelRatesFeedback.get("pay_rate_max") ??
              highLevelRates.get("pay_rate_max"),
            2
          ) ?? 0
        }
        currencySymbol={resultingCurrencySymbol}
        feedbackStatus={initialFeedbackStatus}
        feedbackComment={initialFeedbackComment}
        onApply={handleApplyFeedbackChanges}
      />
      <IDontKnowButton
        feedbackStatus={initialFeedbackStatus}
        onApply={handleApplyFeedbackChanges}
      />
      <CommentButton
        feedbackStatus={initialFeedbackStatus}
        feedbackComment={initialFeedbackComment}
        onApply={handleApplyFeedbackChanges}
      />
    </ButtonGroupRight>
  );
}

FeedbackButtonsBlock.displayName = "RatesFeedbackButtonsBlock";
