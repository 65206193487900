// @flow

export const SORT_DIRECTION = {
  ASC: "ASC",
  DESC: "DESC",
};

export type SortDirection = "DESC" | "ASC";

export default class Sort {
  queryArg: string;

  constructor(queryArg: string) {
    this.queryArg = queryArg;
  }
}
