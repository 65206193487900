import React from "react";
import { emptyOrderedMap } from "../../constants";

import type { AllCurrenciesMap } from "./types";
import { globalContextDefaults, GlobalContextObject } from "../../globalContext";
import { omitObjectKeys } from "../../utils/object";

export type PLIContextObject = Omit<
  GlobalContextObject,
  "fetchAPINew" | "fetchGraphQLNew"
> & {
  isPTAdmin: boolean;
  isClientAdmin: false; // no longer used, should be always set to FALSE
  isRegularUser: boolean;
  //
  currenciesData: AllCurrenciesMap;
};

const pliContextDefaults = {
  ...omitObjectKeys(globalContextDefaults, ["fetchAPINew", "fetchGraphQLNew"]),
  //
  isPTAdmin: false,
  isClientAdmin: false,
  isRegularUser: true,
  //
  currenciesData: emptyOrderedMap as unknown as AllCurrenciesMap,
} as PLIContextObject;

export const PLIContext = React.createContext<PLIContextObject>(pliContextDefaults);

export function usePLIContext() {
  return React.useContext(PLIContext);
}

export default PLIContext;
