import React from "react";

import * as PopoverPrimitive from "@radix-ui/react-popover";

export const Popover = PopoverPrimitive.Root;
export const PopoverTrigger = PopoverPrimitive.Trigger;
export const PopoverAnchor = PopoverPrimitive.Anchor;
export const PopoverPortal = PopoverPrimitive.Portal;

export const PopoverContent = React.forwardRef(
  (
    { children, ...props }: PopoverPrimitive.PopoverContentProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => (
    <PopoverPrimitive.Content sideOffset={5} {...props} ref={ref}>
      {children}
    </PopoverPrimitive.Content>
  )
);

export const PopoverPrimitiveContent = PopoverPrimitive.Content;
export const PopoverArrow = PopoverPrimitive.Arrow;
export const PopoverClose = PopoverPrimitive.Close;
