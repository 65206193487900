/**
 * @generated SignedSource<<5355fc8cd2c3ac13addb3d072b7f73a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminTagsQuery$variables = {};
export type AdminTagsQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"AdminTags_viewer">;
  } | null;
};
export type AdminTagsQuery = {
  response: AdminTagsQuery$data;
  variables: AdminTagsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "Literal",
  "name": "filters",
  "value": {}
},
v1 = {
  "kind": "Literal",
  "name": "first",
  "value": 10
},
v2 = {
  "kind": "Literal",
  "name": "section",
  "value": "ADMIN"
},
v3 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "kind": "Literal",
    "name": "order",
    "value": [
      {
        "direction": "DESC",
        "field": "CREATE_DATE"
      }
    ]
  },
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasNextPage",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endCursor",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientId",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v14 = [
  "filters"
],
v15 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "kind": "Literal",
    "name": "order",
    "value": [
      {
        "direction": "ASC",
        "field": "NAME"
      }
    ]
  }
],
v16 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "kind": "Literal",
    "name": "order",
    "value": [
      {
        "direction": "ASC",
        "field": "USERNAME"
      }
    ]
  },
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminTagsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AdminTags_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AdminTagsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "TagNodeConnection",
            "kind": "LinkedField",
            "name": "tags",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPreviousPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TagNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TagNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "tagId",
                        "storageKey": null
                      },
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "created",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LegacyUserNode",
                        "kind": "LinkedField",
                        "name": "owner",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v11/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ClientNode",
                            "kind": "LinkedField",
                            "name": "client",
                            "plural": false,
                            "selections": [
                              (v12/*: any*/),
                              (v9/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "tags(filters:{},first:10,order:[{\"direction\":\"DESC\",\"field\":\"CREATE_DATE\"}],section:\"ADMIN\")"
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "filters": (v14/*: any*/),
            "handle": "connection",
            "key": "AdminTags_tags",
            "kind": "LinkedHandle",
            "name": "tags"
          },
          {
            "alias": null,
            "args": (v15/*: any*/),
            "concreteType": "ClientTypeNodeConnection",
            "kind": "LinkedField",
            "name": "allClients",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ClientTypeNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClientTypeNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v12/*: any*/),
                      (v9/*: any*/),
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "allClients(filters:{},first:10,order:[{\"direction\":\"ASC\",\"field\":\"NAME\"}])"
          },
          {
            "alias": null,
            "args": (v15/*: any*/),
            "filters": (v14/*: any*/),
            "handle": "connection",
            "key": "FilterAndSortClients_allClients",
            "kind": "LinkedHandle",
            "name": "allClients"
          },
          {
            "alias": null,
            "args": (v16/*: any*/),
            "concreteType": "AuthorNodeConnection",
            "kind": "LinkedField",
            "name": "authors",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AuthorNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuthorNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastName",
                        "storageKey": null
                      },
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "authors(filters:{},first:10,order:[{\"direction\":\"ASC\",\"field\":\"USERNAME\"}],section:\"ADMIN\")"
          },
          {
            "alias": null,
            "args": (v16/*: any*/),
            "filters": (v14/*: any*/),
            "handle": "connection",
            "key": "FilterAndSortCreatedBy_authors",
            "kind": "LinkedHandle",
            "name": "authors"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6f9eaf99327319b86e16c393110cfa31",
    "id": null,
    "metadata": {},
    "name": "AdminTagsQuery",
    "operationKind": "query",
    "text": "query AdminTagsQuery {\n  viewer {\n    ...AdminTags_viewer\n  }\n}\n\nfragment AdminTags_viewer on Viewer {\n  tags(first: 10, filters: {}, order: [{field: CREATE_DATE, direction: DESC}], section: ADMIN) {\n    pageInfo {\n      hasNextPage\n      endCursor\n      hasPreviousPage\n      startCursor\n    }\n    totalCount\n    edges {\n      cursor\n      node {\n        id\n        tagId\n        ...Tag_tag\n        __typename\n      }\n    }\n  }\n  ...FilterAndSortClients_viewer\n  ...FilterAndSortCreatedBy_viewer\n}\n\nfragment FilterAndSortClients_viewer on Viewer {\n  allClients(first: 10, filters: {}, order: [{direction: ASC, field: NAME}]) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalCount\n    edges {\n      node {\n        id\n        clientId\n        name\n        __typename\n      }\n      cursor\n    }\n  }\n}\n\nfragment FilterAndSortCreatedBy_viewer on Viewer {\n  authors(first: 10, filters: {}, order: [{direction: ASC, field: USERNAME}], section: ADMIN) {\n    edges {\n      node {\n        id\n        userId\n        firstName\n        lastName\n        username\n        email\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment Tag_tag on TagNode {\n  id\n  tagId\n  name\n  created\n  owner {\n    userId\n    username\n    client {\n      clientId\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "02ac4bf394f3cbd7b63aba78233b1961";

export default node;
