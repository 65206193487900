import { atom } from "recoil";

import { emptyOrderedMap, emptyMap } from "../../constants";
import type {
  ReviewDataMap,
  ReviewsDataOrderedMap,
  AttemptDataMap,
  AttemptsDataOrderedMap,
} from "./types";
import type {
  ColumnsFiltersQueriesOrderedMap,
  FiltersQueryOrderedMap,
  FiltersConfigOrderedMap,
  RestfulTableConfigObject,
  RestfulTableDataStateObject as BaseRestfulTableDataStateObject,
  RowData,
} from "../../components/tables/types";

type RestfulTableDataStateObject<RD = RowData> = BaseRestfulTableDataStateObject<RD> & {
  loaded: boolean;
};

export const restfulTableConfigDefaults: RestfulTableConfigObject = {
  activePage: 1,
  itemsPerPage: 50,
  filters: emptyOrderedMap as unknown as FiltersConfigOrderedMap,
  filtersQuery: emptyOrderedMap as unknown as FiltersQueryOrderedMap,
  columnsFiltersQueries: emptyOrderedMap as unknown as ColumnsFiltersQueriesOrderedMap,
};

// surveys history page global state

export type SurveyHistoryTableDataStateObject =
  RestfulTableDataStateObject<AttemptDataMap>;

export const surveyHistoryTableGlobalState = atom<SurveyHistoryTableDataStateObject>({
  key: "surveyHistoryTableGlobalState",
  default: {
    ...restfulTableConfigDefaults,
    data: emptyOrderedMap as unknown as AttemptsDataOrderedMap,
    itemsCount: 0,
    itemsPerPage: 20,
    loaded: false,
  },
});

// surveys list page global state

export type SurveyListTableDataStateObject = RestfulTableDataStateObject<ReviewDataMap>;

export const surveyListTableGlobalState = atom<SurveyListTableDataStateObject>({
  key: "surveyListTableGlobalState",
  default: {
    ...restfulTableConfigDefaults,
    data: emptyOrderedMap as unknown as ReviewsDataOrderedMap,
    itemsCount: 0,
    itemsPerPage: 20,
    loaded: false,
  },
});

// survey item page global state

export type ReviewPublicGlobalState = {
  reviewData: ReviewDataMap;
  reviewDataLoaded: boolean;
};

export const reviewPublicGlobalState = atom<ReviewPublicGlobalState>({
  key: "reviewPublicGlobalState",
  default: {
    reviewData: emptyMap as unknown as ReviewDataMap,
    reviewDataLoaded: false,
  },
});
