import * as React from "react";
import PageContent from "../../components/PageContent";
import Button from "../../components/lib/Button";
import TextInput from "../../components/lib/TextInput";
import TextArea from "../../components/lib/TextArea";
import Tag from "../../components/lib/Tag";
import { Tabs, TabsList, TabsContent, TabsTrigger } from "../../components/lib/Tabs";
import Stack from "../../components/lib/Stack";
import Inline from "../../components/lib/Inline";
import Box from "../../components/lib/Box";
import Divider from "../../components/lib/Divider";
import Spacer from "../../components/lib/Spacer";
import { styled } from "../../stitches.config";
import Tooltip from "../../components/lib/Tooltip";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuCheckboxItem,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTriggerItem,
  DropdownMenuSubContent,
} from "../../components/lib/DropdownMenu";
import HTMLSelect from "../../components/lib/HTMLSelect";
import Pagination, {
  PageEvent,
  PageEventTypeEnum,
} from "../../components/lib/Pagination";
import {
  Checkbox,
  CheckboxIndicator,
  LabelInline,
  CheckboxItem,
} from "../../components/lib/Checkbox";
import {
  Popover,
  PopoverPrimitiveContent,
  PopoverTrigger,
} from "../../components/lib/Popover";
import { Table, THead, TBody, TH, TR, TD } from "../../components/lib/Table";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
  DialogActions,
} from "../../components/lib/Dialog";
import Icon from "../../components/lib/Icon";
import { ButtonGroupRight } from "../../components/lib/ButtonGroup";
import { RadioGroup, RadioGroupItem } from "../../components/lib/RadioGroup";
import Text from "../../components/lib/Text";
import Alert from "../../components/lib/Alert";
import { SimpleLink } from "../../components/lib/Link";
import NumberInput from "../../components/lib/NumberInput";
import FileInput from "../../components/lib/FileInput";
import ImageInput from "../../components/lib/ImageInput";
import { GroupRadioButtons } from "../../components/lib/GroupRadioButtons";

const SectionTitle = styled("h4", {
  marginTop: "$4 !important",
});

const SectionSubTitle = styled("h5", {
  marginTop: "$3 !important",
});

const Paragraph = styled("p", {
  width: "$full",
  "@md": { width: "$1-of-2" },
});

const BorderedBox = styled(Box, {
  padding: "$2",
  border: "1px solid $ptGray700",
});

const ColorBox = styled(BorderedBox, {
  minWidth: "$28",
  fontSize: "$sm",
  padding: "$2_5",
});

function Typography() {
  return (
    <Stack
      css={{
        alignItems: "flex-start",
        "& table tr": { verticalAlign: "baseline" },
        "& table td": { padding: "$4" },
      }}
    >
      <SectionTitle>Typography</SectionTitle>
      <table>
        <tbody>
          <tr>
            <td>
              <code>h1</code>
            </td>
            <td>
              <h1>Lorem Ipsum</h1>
            </td>
          </tr>
          <tr>
            <td>
              <code>h2</code>
            </td>
            <td>
              <h2>Lorem Ipsum</h2>
            </td>
          </tr>
          <tr>
            <td>
              <code>h3</code>
            </td>
            <td>
              <h3>Lorem Ipsum</h3>
            </td>
          </tr>
          <tr>
            <td>
              <code>h4</code>
            </td>
            <td>
              <h4>Lorem Ipsum</h4>
            </td>
          </tr>
          <tr>
            <td>
              <code>h5</code>
            </td>
            <td>
              <h5>Lorem Ipsum</h5>
            </td>
          </tr>
          <tr>
            <td>
              <code>h6</code>
            </td>
            <td>
              <h6>Lorem Ipsum</h6>
            </td>
          </tr>
          <tr>
            <td>
              <code>p</code>
            </td>
            <td>
              <Paragraph>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                consectetur, est a accumsan tristique, ex justo maximus risus, ut rhoncus
                quam odio a libero. Nam egestas ipsum et neque consequat, eu mollis risus
                porta. Praesent purus quam, convallis vel mollis ut, tristique et justo.
                Nam metus erat, rhoncus vitae ligula ac, sagittis hendrerit neque. Nulla
                faucibus nisi purus. Ut feugiat urna sit amet justo condimentum ornare.
                Curabitur faucibus orci sed mollis iaculis. Phasellus ac turpis nisl.
              </Paragraph>
              <Paragraph>
                Lorem ipsum <code>&lt;span&gt;</code>
                <span>dolor sit amet, consectetur</span>
                <code>&lt;/span&gt;</code> adipiscing elit.
              </Paragraph>
              <Paragraph>
                Lorem ipsum <code>&lt;em&gt;</code>
                <em>dolor sit amet, consectetur</em>
                <code>&lt;/em&gt;</code> adipiscing elit.
              </Paragraph>
              <Paragraph>
                Lorem ipsum <code>&lt;strong&gt;</code>
                <strong>dolor sit amet, consectetur</strong>
                <code>&lt;/strong&gt;</code> adipiscing elit.
              </Paragraph>
            </td>
          </tr>
          <tr>
            <td>
              <code>pre</code>
            </td>
            <td>
              <pre>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                consectetur, est a accumsan tristique, ex
                <br />
                justo maximus risus, ut rhoncus quam odio a libero. Nam egestas ipsum et
                neque consequat, eu mollis
                <br />
                risus porta. Praesent purus quam, convallis vel mollis ut, tristique et
                justo. Nam metus erat, rhoncus
                <br />
                vitae ligula ac, sagittis hendrerit neque. Nulla faucibus nisi purus. Ut
                feugiat urna sit amet justo
                <br />
                condimentum ornare. Curabitur faucibus orci sed mollis iaculis. Phasellus
                ac turpis nisl.
              </pre>
            </td>
          </tr>
        </tbody>
      </table>
    </Stack>
  );
}

function Buttons() {
  return (
    <Stack fill css={{ alignItems: "flex-start" }}>
      <SectionTitle>Button</SectionTitle>
      <Inline>
        <Button
          icon="sliders-h"
          text="Extra Small"
          size="extra-small"
          css={{ width: "100px" }}
        />
        <Button icon="sliders-h" text="Small" size="small" css={{ width: "90px" }} />
        <Button icon="sliders-h" text="Normal" size="normal" css={{ width: "110px" }} />
        <Button icon="sliders-h" text="Large" size="large" css={{ width: "125px" }} />
        <Button
          icon="sliders-h"
          text="Extra Large"
          size="extra-large"
          css={{ width: "200px" }}
        />
      </Inline>
      <Inline>
        <Button
          icon="sliders-h"
          text="Extra Small"
          loading
          size="extra-small"
          css={{ width: "100px" }}
        />
        <Button
          icon="sliders-h"
          text="Small"
          loading
          size="small"
          css={{ width: "90px" }}
        />
        <Button
          icon="sliders-h"
          text="Normal"
          loading
          size="normal"
          css={{ width: "110px" }}
        />
        <Button
          icon="sliders-h"
          text="Large"
          loading
          size="large"
          css={{ width: "125px" }}
        />
        <Button
          icon="sliders-h"
          text="Extra Large"
          loading
          size="extra-large"
          css={{ width: "200px" }}
        />
      </Inline>
      <SectionSubTitle>Filled Buttons</SectionSubTitle>
      <Inline>
        <Button icon="sliders-h" text="Filters" color="gray" />
        <Button icon="sliders-h" text="Filters" color="primary" />
        <Button icon="sliders-h" text="Filters" color="brand" />
        <Button icon="sliders-h" text="Filters" color="yellow" />
        <Button icon="sliders-h" text="Filters" color="red" />
        <Button text="Filters" color="blue" iconRight="chevron-right" />
      </Inline>
      <Inline>
        <Button
          icon="sliders-h"
          text="Filters"
          loading
          color="gray"
          css={{ width: "107.562px" }}
        />
        <Button
          icon="sliders-h"
          text="Filters"
          loading
          color="brand"
          css={{ width: "107.562px" }}
        />
        <Button
          icon="sliders-h"
          text="Filters"
          loading
          color="yellow"
          css={{ width: "107.562px" }}
        />
        <Button
          icon="sliders-h"
          text="Filters"
          loading
          color="red"
          css={{ width: "107.562px" }}
        />
        <Button
          icon="sliders-h"
          text="Filters"
          loading
          color="blue"
          loadingText="Loading..."
        />
      </Inline>
      <Inline>
        <Button icon="sliders-h" text="Filters" color="gray" disabled />
        <Button icon="sliders-h" text="Filters" color="brand" disabled />
        <Button icon="sliders-h" text="Filters" color="yellow" disabled />
        <Button icon="sliders-h" text="Filters" color="red" disabled />
        <Button icon="sliders-h" text="Filters" color="blue" disabled />
      </Inline>
      <SectionSubTitle>Outlined Buttons</SectionSubTitle>
      <Inline>
        <Button icon="sliders-h" text="Filters" color="gray" variant="outlined" />
        <Button icon="sliders-h" text="Filters" color="brand" variant="outlined" />
        <Button icon="sliders-h" text="Filters" color="yellow" variant="outlined" />
        <Button icon="sliders-h" text="Filters" color="red" variant="outlined" />
        <Button
          text="Filters"
          color="blue"
          variant="outlined"
          iconRight="chevron-right"
        />
      </Inline>
      <Inline>
        <Button
          icon="sliders-h"
          text="Filters"
          loading
          color="gray"
          variant="outlined"
          css={{ width: "107.562px" }}
        />
        <Button
          icon="sliders-h"
          text="Filters"
          loading
          color="brand"
          variant="outlined"
          css={{ width: "107.562px" }}
        />
        <Button
          icon="sliders-h"
          text="Filters"
          loading
          color="yellow"
          variant="outlined"
          css={{ width: "107.562px" }}
        />
        <Button
          icon="sliders-h"
          text="Filters"
          loading
          color="red"
          variant="outlined"
          css={{ width: "107.562px" }}
        />
        <Button
          icon="sliders-h"
          text="Filters"
          loading
          loadingText="Loading..."
          color="blue"
          variant="outlined"
        />
      </Inline>
      <Inline>
        <Button
          icon="sliders-h"
          text="Filters"
          color="gray"
          variant="outlined"
          disabled
        />
        <Button
          icon="sliders-h"
          text="Filters"
          color="brand"
          variant="outlined"
          disabled
        />
        <Button
          icon="sliders-h"
          text="Filters"
          color="yellow"
          variant="outlined"
          disabled
        />
        <Button icon="sliders-h" text="Filters" color="red" variant="outlined" disabled />
        <Button
          icon="sliders-h"
          text="Filters"
          color="blue"
          variant="outlined"
          disabled
        />
      </Inline>
      <SectionSubTitle>Light Buttons</SectionSubTitle>
      <Inline>
        <Button icon="sliders-h" text="Filters" color="gray" variant="light" />
        <Button icon="sliders-h" text="Filters" color="brand" variant="light" />
        <Button icon="sliders-h" text="Filters" color="yellow" variant="light" />
        <Button icon="sliders-h" text="Filters" color="red" variant="light" />
        <Button
          icon="sliders-h"
          text="Filters"
          color="blue"
          variant="light"
          iconRight="chevron-right"
        />
      </Inline>
      <Inline>
        <Button
          icon="sliders-h"
          text="Filters"
          loading
          color="gray"
          variant="light"
          css={{ width: "107.562px" }}
        />
        <Button
          icon="sliders-h"
          text="Filters"
          loading
          color="brand"
          variant="light"
          css={{ width: "107.562px" }}
        />
        <Button
          icon="sliders-h"
          text="Filters"
          loading
          color="yellow"
          variant="light"
          css={{ width: "107.562px" }}
        />
        <Button
          icon="sliders-h"
          text="Filters"
          loading
          color="red"
          variant="light"
          css={{ width: "107.562px" }}
        />
        <Button
          icon="sliders-h"
          text="Filters"
          loading
          loadingText="Loading..."
          color="blue"
          variant="light"
        />
      </Inline>
      <Inline>
        <Button icon="sliders-h" text="Filters" color="gray" variant="light" disabled />
        <Button icon="sliders-h" text="Filters" color="brand" variant="light" disabled />
        <Button icon="sliders-h" text="Filters" color="yellow" variant="light" disabled />
        <Button icon="sliders-h" text="Filters" color="red" variant="light" disabled />
        <Button icon="sliders-h" text="Filters" color="blue" variant="light" disabled />
      </Inline>
      <SectionSubTitle>Text Buttons</SectionSubTitle>
      <Inline>
        <Button icon="sliders-h" text="Filters" color="gray" variant="text" />
        <Button icon="sliders-h" text="Filters" color="brand" variant="text" />
        <Button icon="sliders-h" text="Filters" color="yellow" variant="text" />
        <Button icon="sliders-h" text="Filters" color="red" variant="text" />
        <Button text="Filters" color="blue" variant="text" iconRight="chevron-right" />
      </Inline>
      <Inline>
        <Button
          icon="sliders-h"
          text="Filters"
          loading
          color="gray"
          variant="text"
          css={{ width: "107.562px" }}
        />
        <Button
          icon="sliders-h"
          text="Filters"
          loading
          color="brand"
          variant="text"
          css={{ width: "107.562px" }}
        />
        <Button
          icon="sliders-h"
          text="Filters"
          loading
          color="yellow"
          variant="text"
          css={{ width: "107.562px" }}
        />
        <Button
          icon="sliders-h"
          text="Filters"
          loading
          color="red"
          variant="text"
          css={{ width: "107.562px" }}
        />
        <Button
          icon="sliders-h"
          text="Filters"
          loading
          loadingText="Loading..."
          color="blue"
          variant="text"
        />
      </Inline>
      <Inline>
        <Button icon="sliders-h" text="Filters" color="gray" variant="text" disabled />
        <Button icon="sliders-h" text="Filters" color="brand" variant="text" disabled />
        <Button icon="sliders-h" text="Filters" color="yellow" variant="text" disabled />
        <Button icon="sliders-h" text="Filters" color="red" variant="text" disabled />
        <Button icon="sliders-h" text="Filters" color="blue" variant="text" disabled />
      </Inline>
      <SectionSubTitle>RP Buttons</SectionSubTitle>
      <Inline>
        <Button icon="sliders-h" text="Primary" color="primary" />
        <Button icon="sliders-h" text="Brand" color="brand" />
        <Button icon="sliders-h" text="Accent" color="accent" />
        <Button icon="sliders-h" text="Danger" color="danger" />
        <Button icon="sliders-h" text="Warning" color="warning" />
        <Button icon="sliders-h" text="Success" color="success" />
      </Inline>
      <Inline>
        <Button icon="sliders-h" text="Primary" color="primary" variant="outlined" />
        <Button icon="sliders-h" text="Brand" color="brand" variant="outlined" />
        <Button icon="sliders-h" text="Accent" color="accent" variant="outlined" />
        <Button icon="sliders-h" text="Danger" color="danger" variant="outlined" />
        <Button icon="sliders-h" text="Warning" color="warning" variant="outlined" />
        <Button icon="sliders-h" text="Success" color="success" variant="outlined" />
      </Inline>
    </Stack>
  );
}

function Inputs() {
  const [fileValue, setFileValue] = React.useState<null | File>(null);
  const [imageValue, setImageValue] = React.useState<"" | string | File>("");

  const handleFileInputChange = React.useCallback(
    (files: FileList | null) => {
      if (!!files?.length) {
        setFileValue(files[0]);
      }
    },
    [setFileValue]
  );

  const handleImageInputChange = React.useCallback(
    (image: "" | string | File) => {
      setImageValue(image);
    },
    [setImageValue]
  );

  return (
    <Stack fill css={{ alignItems: "flex-start" }}>
      <SectionTitle>Text Input</SectionTitle>
      <ShowcaseBox fill>
        <Inline css={{ width: "100%", alignItems: "center", flexWrap: "nowrap" }}>
          <TextInput
            placeholder="Search here..."
            size="extra-small"
            icon="search"
            loading
            css={{ width: 130 }}
          />
          <TextInput
            placeholder="Search here..."
            size="small"
            icon="search"
            loading
            css={{ width: 160 }}
          />
          <TextInput
            defaultValue="java dev"
            placeholder="Search here..."
            size="normal"
            icon="search"
            loading
            css={{ width: 200 }}
          />
          <TextInput
            placeholder="Search here..."
            size="large"
            icon="search"
            loading
            css={{ width: 240 }}
          />
          <TextInput
            placeholder="Search here..."
            size="extra-large"
            icon="search"
            loading
            css={{ flex: 1 }}
          />
        </Inline>
        <TextInput fill placeholder="Search here..." icon="search" loading />
        <Inline css={{ width: "100%", alignItems: "center" }}>
          <TextInput
            placeholder="Search here..."
            color="primary"
            icon="search"
            css={{ flex: 1 }}
          />
          <TextInput
            placeholder="Search here..."
            color="danger"
            icon="search"
            css={{ flex: 1 }}
          />
        </Inline>
        <Inline css={{ width: "100%", alignItems: "center" }}>
          <TextInput
            placeholder="Search here..."
            color="primary"
            icon="search"
            disabled
            css={{ flex: 1 }}
          />
        </Inline>
      </ShowcaseBox>
      <SectionSubTitle>RP Input</SectionSubTitle>
      <ShowcaseBox fill>
        <Inline fill>
          <TextInput
            placeholder="Primary"
            color="primary"
            icon="search"
            css={{ flex: 0.25 }}
          />
          <TextInput
            placeholder="Danger"
            color="danger"
            icon="search"
            css={{ flex: 0.25 }}
          />
          <TextInput
            placeholder="With Addons"
            addon="$"
            addonRight="%"
            css={{ flex: 0.25 }}
          />
          <TextInput
            placeholder="With Buttons"
            color="primary"
            icon="search"
            iconRight="times"
            iconClicked={() => {}}
            iconRightClicked={() => {}}
            css={{ flex: 0.25 }}
          />
        </Inline>
        <Inline fill>
          <TextInput
            deep
            placeholder="Deep"
            color="primary"
            icon="search"
            css={{ flex: 0.25 }}
          />
          <TextInput
            deep
            placeholder="Danger"
            color="danger"
            icon="search"
            css={{ flex: 0.25 }}
          />
          <TextInput
            deep
            placeholder="With Addons"
            addon="$"
            addonRight="%"
            css={{ flex: 0.25 }}
          />
          <TextInput
            deep
            placeholder="With Buttons"
            color="primary"
            icon="search"
            iconRight="arrow-right"
            iconClicked={() => {}}
            iconRightClicked={() => {}}
            css={{ flex: 0.25 }}
          />
        </Inline>
      </ShowcaseBox>
      <SectionSubTitle>Number Input</SectionSubTitle>
      <ShowcaseBox fill>
        <Inline fill>
          <NumberInput
            placeholder="Enter a number..."
            color="primary"
            css={{ flex: 0.25 }}
          />
          <NumberInput
            placeholder="Enter a number..."
            color="danger"
            css={{ flex: 0.25 }}
          />
        </Inline>
      </ShowcaseBox>
      <SectionTitle>Textarea</SectionTitle>
      <ShowcaseBox fill>
        <Inline css={{ width: "100%", alignItems: "center", flexWrap: "nowrap" }}>
          <TextArea
            placeholder="TextArea small"
            size="small"
            css={{ flex: 1, flexBasis: "25%", flexGrow: 0 }}
          />
          <TextArea
            placeholder="TextArea normal"
            size="normal"
            css={{ flex: 1, flexBasis: "25%", flexGrow: 0 }}
          />
          <TextArea
            placeholder="TextArea large"
            size="large"
            css={{ flex: 1, flexBasis: "25%", flexGrow: 0 }}
          />
          <TextArea
            placeholder="TextArea extra-large"
            size="extra-large"
            css={{ flex: 1, flexBasis: "25%", flexGrow: 0 }}
          />
        </Inline>
      </ShowcaseBox>
      <SectionSubTitle>File Input</SectionSubTitle>
      <ShowcaseBox fill>
        <Inline css={{ width: "100%", alignItems: "center", flexWrap: "nowrap" }}>
          <FileInput
            onNotSupported={() => {}}
            onChange={handleFileInputChange}
            color="primary"
            placeholder="custom icon"
            value={fileValue}
            icon={"download"}
          >
            Select File
          </FileInput>
          <FileInput
            onNotSupported={() => {}}
            onChange={handleFileInputChange}
            color="primary"
            value={fileValue}
          >
            Custom button title
          </FileInput>
          <FileInput
            onNotSupported={() => {}}
            onChange={handleFileInputChange}
            color="primary"
            placeholder="invalid"
            value={fileValue}
            invalid
          >
            Select File
          </FileInput>
          <FileInput
            onNotSupported={() => {}}
            onChange={handleFileInputChange}
            color="primary"
            placeholder="disabled"
            value={fileValue}
            disabled
          >
            Select File
          </FileInput>
        </Inline>
        <Inline css={{ width: "100%", alignItems: "center", flexWrap: "nowrap" }}>
          <FileInput
            onNotSupported={() => {}}
            onChange={handleFileInputChange}
            color="primary"
            placeholder="color"
            value={fileValue}
          >
            primary
          </FileInput>
          <FileInput
            onNotSupported={() => {}}
            onChange={handleFileInputChange}
            color="brand"
            placeholder="color"
            value={fileValue}
          >
            brand
          </FileInput>
          <FileInput
            onNotSupported={() => {}}
            onChange={handleFileInputChange}
            color="accent"
            placeholder="color"
            value={fileValue}
          >
            accent
          </FileInput>
          <FileInput
            onNotSupported={() => {}}
            onChange={handleFileInputChange}
            color="success"
            placeholder="color"
            value={fileValue}
          >
            success
          </FileInput>
          <FileInput
            onNotSupported={() => {}}
            onChange={handleFileInputChange}
            color="warning"
            placeholder="color"
            value={fileValue}
          >
            warning
          </FileInput>
          <FileInput
            onNotSupported={() => {}}
            onChange={handleFileInputChange}
            color="danger"
            placeholder="color"
            value={fileValue}
          >
            danger
          </FileInput>
        </Inline>
        <Inline css={{ width: "100%", alignItems: "center", flexWrap: "nowrap" }}>
          <FileInput
            onNotSupported={() => {}}
            onChange={handleFileInputChange}
            color="primary"
            size="extra-small"
            placeholder="size"
            value={fileValue}
          >
            extra-small
          </FileInput>
          <FileInput
            onNotSupported={() => {}}
            onChange={handleFileInputChange}
            color="primary"
            size="small"
            placeholder="size"
            value={fileValue}
          >
            small
          </FileInput>
          <FileInput
            onNotSupported={() => {}}
            onChange={handleFileInputChange}
            color="primary"
            size="normal"
            placeholder="size"
            value={fileValue}
          >
            normal
          </FileInput>
          <FileInput
            onNotSupported={() => {}}
            onChange={handleFileInputChange}
            color="primary"
            size="large"
            placeholder="size"
            value={fileValue}
          >
            large
          </FileInput>
          <FileInput
            onNotSupported={() => {}}
            onChange={handleFileInputChange}
            color="primary"
            size="extra-large"
            placeholder="size"
            value={fileValue}
          >
            extra-large
          </FileInput>
        </Inline>
      </ShowcaseBox>
      <SectionSubTitle>Image Input</SectionSubTitle>
      <ShowcaseBox fill>
        <Inline css={{ width: "100%", alignItems: "center", flexWrap: "nowrap" }}>
          <ImageInput
            name="logo"
            onBadFileMimeType={() => {}}
            onNotSupported={() => {}}
            onChange={handleImageInputChange}
            value={imageValue}
          />
          <ImageInput
            name="logo"
            onBadFileMimeType={() => {}}
            onNotSupported={() => {}}
            onChange={handleImageInputChange}
            value={imageValue}
            placeholder="custom placeholder"
          />
          <ImageInput
            name="logo"
            onBadFileMimeType={() => {}}
            onNotSupported={() => {}}
            onChange={handleImageInputChange}
            value={imageValue}
            placeholder="invalid"
            invalid
          />
          <ImageInput
            name="logo"
            onBadFileMimeType={() => {}}
            onNotSupported={() => {}}
            onChange={handleImageInputChange}
            value={imageValue}
            placeholder="disabled"
            disabled
          />
        </Inline>
        <Inline css={{ width: "100%", alignItems: "center", flexWrap: "nowrap" }}>
          <ImageInput
            name="logo"
            onBadFileMimeType={() => {}}
            onNotSupported={() => {}}
            onChange={handleImageInputChange}
            value={imageValue}
            placeholder="width: 50"
            defaultImageWidth={50}
          />
          <ImageInput
            name="logo"
            onBadFileMimeType={() => {}}
            onNotSupported={() => {}}
            onChange={handleImageInputChange}
            value={imageValue}
            placeholder="width: 100"
            defaultImageWidth={100}
          />
          <ImageInput
            name="logo"
            onBadFileMimeType={() => {}}
            onNotSupported={() => {}}
            onChange={handleImageInputChange}
            value={imageValue}
            placeholder="width: 200"
            defaultImageWidth={200}
          />
        </Inline>
      </ShowcaseBox>
    </Stack>
  );
}

function HTMLSelects() {
  const options = [
    <option key="1" value="1">
      Option 1
    </option>,
    <option key="2" value="2">
      Option 2
    </option>,
    <option key="3" value="3">
      Option 3
    </option>,
  ];
  return (
    <Stack fill css={{ alignItems: "flex-start" }}>
      <SectionTitle>HTML Select</SectionTitle>
      <ShowcaseBox fill>
        <Inline fill>
          <HTMLSelect size="extra-small" css={{ width: 130 }}>
            {options}
          </HTMLSelect>
          <HTMLSelect size="small" css={{ width: 160 }}>
            {options}
          </HTMLSelect>
          <HTMLSelect defaultValue="1" size="normal" css={{ width: 200 }}>
            {options}
          </HTMLSelect>
          <HTMLSelect size="large" css={{ width: 240 }}>
            {options}
          </HTMLSelect>
          <HTMLSelect size="extra-large" css={{ flex: 1 }}>
            {options}
          </HTMLSelect>
        </Inline>
      </ShowcaseBox>
      <SectionSubTitle>RP</SectionSubTitle>
      <ShowcaseBox fill>
        <Inline fill css={{ width: "100%", alignItems: "center" }}>
          <HTMLSelect color="primary" css={{ flex: 0.25 }}>
            {options}
          </HTMLSelect>
          <HTMLSelect color="primary" deep css={{ flex: 0.25 }}>
            {options}
          </HTMLSelect>
          <HTMLSelect color="primary" disabled css={{ flex: 0.25 }}>
            {options}
          </HTMLSelect>
        </Inline>
        <Inline fill>
          <HTMLSelect color="danger" css={{ flex: 0.25 }}>
            {options}
          </HTMLSelect>
          <HTMLSelect color="danger" deep css={{ flex: 0.25 }}>
            {options}
          </HTMLSelect>
        </Inline>
      </ShowcaseBox>
    </Stack>
  );
}

function Tags() {
  return (
    <Stack fill css={{ alignItems: "flex-start" }}>
      <SectionTitle>Tag</SectionTitle>
      <ShowcaseBox fill justify="left">
        <Inline nowrap>
          <Tag text="Engineering" icon="tag" size="extra-small" />
          <Tag text="Engineering" icon="tag" size="small" />
          <Tag text="Engineering" icon="tag" size="normal" />
          <Tag text="Engineering" icon="tag" size="large" />
          <Tag text="Engineering" icon="tag" size="extra-large" />
        </Inline>
        <Inline nowrap>
          <Tag text="Engineering" icon="tag" color="green" />
          <Tag text="Engineering" icon="tag" color="yellow" />
          <Tag text="Engineering" icon="tag" color="gray" />
          <Tag text="Engineering" icon="tag" color="red" />
          <Tag text="Engineering" icon="tag" color="blue" />
        </Inline>
        <Inline nowrap>
          <Tag text="Engineering" icon="tag" color="green" variant="filled" />
          <Tag text="Engineering" icon="tag" color="yellow" variant="filled" />
          <Tag text="Engineering" icon="tag" color="gray" variant="filled" />
          <Tag text="Engineering" icon="tag" color="red" variant="filled" />
          <Tag text="Engineering" icon="tag" color="blue" variant="filled" />
        </Inline>
        <Inline nowrap>
          <Tag text="Engineering" icon="tag" color="green" variant="outlined" />
          <Tag text="Engineering" icon="tag" color="yellow" variant="outlined" />
          <Tag text="Engineering" icon="tag" color="gray" variant="outlined" />
          <Tag text="Engineering" icon="tag" color="red" variant="outlined" />
          <Tag text="Engineering" icon="tag" color="blue" variant="outlined" />
        </Inline>
      </ShowcaseBox>
    </Stack>
  );
}

function Layout() {
  return (
    <Stack fill css={{ alignItems: "flex-start" }}>
      <SectionTitle>Layout</SectionTitle>
      <SectionSubTitle>Box</SectionSubTitle>
      <BorderedBox>Box (div)</BorderedBox>
      <SectionSubTitle>Stack</SectionSubTitle>
      <Stack>
        <BorderedBox>box 1</BorderedBox>
        <BorderedBox>box 2</BorderedBox>
        <BorderedBox>box 3</BorderedBox>
      </Stack>
      <SectionSubTitle>Inline</SectionSubTitle>
      <Inline>
        <BorderedBox>box 1</BorderedBox>
        <BorderedBox>box 2</BorderedBox>
        <BorderedBox>box 3</BorderedBox>
      </Inline>
      <SectionSubTitle>Divider</SectionSubTitle>
      <Divider />
      <SectionSubTitle>Spacer</SectionSubTitle>
      <Inline css={{ width: "$96", backgroundColor: "$ptBlue200" }}>
        <BorderedBox>Item 1</BorderedBox>
        <BorderedBox>Item 2</BorderedBox>
        <Spacer />
        <BorderedBox>Item 3</BorderedBox>
      </Inline>
    </Stack>
  );
}

function Palette() {
  return (
    <Stack fill css={{ alignItems: "flex-start" }}>
      <SectionTitle>Palette (Pay Intel)</SectionTitle>
      <Stack css={{ alignItems: "flex-start" }}>
        <Inline>
          <ColorBox css={{ backgroundColor: "$primaryDarkest", color: "$textLight" }}>
            Darkest
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$primaryDarker", color: "$textLight" }}>
            Darker
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$primaryDark", color: "$textLight" }}>
            Dark
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$primary", color: "$textLight" }}>
            Primary
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$primaryLight", color: "$text" }}>
            Light
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$primaryLighter", color: "$text" }}>
            Lighter
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$primaryLightest", color: "$text" }}>
            Lightest
          </ColorBox>
        </Inline>
        <Inline>
          <ColorBox css={{ backgroundColor: "transparent", color: "$text" }}>
            TBD
          </ColorBox>
          <ColorBox css={{ backgroundColor: "transparent", color: "$text" }}>
            TBD
          </ColorBox>
          <ColorBox css={{ backgroundColor: "transparent", color: "$text" }}>
            TBD
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$secondary", color: "$textLight" }}>
            Secondary
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$secondaryLight", color: "$textLight" }}>
            Light
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$secondaryLighter", color: "$text" }}>
            Lighter
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$secondaryLightest", color: "$text" }}>
            Lightest
          </ColorBox>
        </Inline>
        {/* Brand */}
        <Inline>
          <ColorBox css={{ backgroundColor: "transparent", color: "$text" }}>
            TBD
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$brandDarker", color: "$textLight" }}>
            Darker
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$brandDark", color: "$textLight" }}>
            Dark
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$brand", color: "$textLight" }}>
            Brand
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$brandLight", color: "$text" }}>
            Light
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$brandLighter", color: "$text" }}>
            Lighter
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$brandLightest", color: "$text" }}>
            Lightest
          </ColorBox>
        </Inline>
        {/* Accent */}
        <Inline>
          <ColorBox css={{ backgroundColor: "$accentDarkest", color: "$textLight" }}>
            Darkest
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$accentDarker", color: "$textLight" }}>
            Darker
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$accentDark", color: "$textLight" }}>
            Dark
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$accent", color: "$textLight" }}>
            Accent
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$accentLight", color: "$text" }}>
            Light
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$accentLighter", color: "$text" }}>
            Lighter
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$accentLightest", color: "$text" }}>
            Lightest
          </ColorBox>
        </Inline>
        {/* Success */}
        <Inline>
          <ColorBox css={{ backgroundColor: "$successDarkest", color: "$textLight" }}>
            Darkest
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$successDarker", color: "$textLight" }}>
            Darker
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$successDark", color: "$textLight" }}>
            Dark
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$success", color: "$textLight" }}>
            Success
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$successLight", color: "$text" }}>
            Light
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$successLighter", color: "$text" }}>
            Lighter
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$successLightest", color: "$text" }}>
            Lightest
          </ColorBox>
        </Inline>
        {/* Danger */}
        <Inline>
          <ColorBox css={{ backgroundColor: "$dangerDarkest", color: "$textLight" }}>
            Darkest
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$dangerDarker", color: "$textLight" }}>
            Darker
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$dangerDark", color: "$textLight" }}>
            Dark
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$danger", color: "$textLight" }}>
            Danger
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$dangerLight", color: "$text" }}>
            Light
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$dangerLighter", color: "$text" }}>
            Lighter
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$dangerLightest", color: "$text" }}>
            Lightest
          </ColorBox>
        </Inline>
        {/* Warning */}
        <Inline>
          <ColorBox css={{ backgroundColor: "$warningDarkest", color: "$textLight" }}>
            Darkest
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$warningDarker", color: "$textLight" }}>
            Darker
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$warningDark", color: "$text" }}>
            Dark
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$warning", color: "$textLight" }}>
            Warning
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$warningLight", color: "$text" }}>
            Light
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$warningLighter", color: "$text" }}>
            Lighter
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$warningLightest", color: "$text" }}>
            Lightest
          </ColorBox>
        </Inline>
        {/* Exp Levels */}
        <Inline>
          <ColorBox css={{ backgroundColor: "$levelI", color: "$textLight" }}>
            Level I
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$levelII", color: "$textLight" }}>
            Level II
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$levelIII", color: "$textLight" }}>
            Level III
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$levelIV", color: "$textLight" }}>
            Level IV
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$levelV", color: "$textLight" }}>
            Level V
          </ColorBox>
        </Inline>
        {/* Charts */}
        <Inline>
          <ColorBox css={{ backgroundColor: "$chart1", color: "$textLight" }}>
            Chart 1
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$chart2", color: "$textLight" }}>
            Chart 2
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$chart3", color: "$textLight" }}>
            Chart 3
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$chart4", color: "$textLight" }}>
            Chart 4
          </ColorBox>
          <ColorBox css={{ backgroundColor: "$chart5", color: "$textLight" }}>
            Chart 5
          </ColorBox>
        </Inline>
        <ColorBox css={{ backgroundColor: "$background", color: "$text" }}>
          Background
        </ColorBox>
      </Stack>
    </Stack>
  );
}

const ShowcaseBox = styled(Inline, {
  border: "1px solid $primary",
  backgroundColor: "white",
  borderRadius: "$rounded",
  padding: "$5",
  width: 300,
  variants: {
    justify: {
      left: {
        justifyContent: "flex-start",
      },
      right: {
        justifyContent: "flex-end",
      },
      center: {
        justifyContent: "center",
      },
    },
    fill: {
      true: {
        width: "$full",
      },
    },
  },
  defaultVariants: {
    justify: "center",
    fill: false,
  },
});

function Tooltips() {
  return (
    <Stack fill css={{ alignItems: "flex-start" }}>
      <SectionTitle>Tooltips</SectionTitle>
      <Inline>
        <ShowcaseBox css={{ height: 250 }}>
          <Tooltip content="This is a tooltip..." side="top" align="center" open>
            <Box css={{ flexGrow: 0 }}>
              {/*
                NOTE: for some reason the some components (like Tag) don't display the tooltip
                when used as the trigger. The classes get added and the tooltip is
                also added to the DOM, but it doesn't show. Maybe a css problem?
                Anyhow, wrapping the component with some other comp or html tag works.
              */}
              <Tag icon="info-circle" text="Top" />
            </Box>
          </Tooltip>
        </ShowcaseBox>
        <ShowcaseBox css={{ height: 250 }}>
          <Tooltip content="This is a tooltip...">
            <div>
              <Tag icon="info-circle" text="Hover me..." />
            </div>
          </Tooltip>
        </ShowcaseBox>
      </Inline>
    </Stack>
  );
}

function Popovers() {
  return (
    <Stack fill css={{ alignItems: "flex-start" }}>
      <SectionTitle>Popover (Custom Content)</SectionTitle>
      <Popover>
        <PopoverTrigger asChild>
          <Button>Test Me</Button>
        </PopoverTrigger>
        <PopoverPrimitiveContent>
          <ColorBox css={{ backgroundColor: "$primary", color: "$textLight" }}>
            Primary
          </ColorBox>
        </PopoverPrimitiveContent>
      </Popover>
    </Stack>
  );
}

function DropdownMenus() {
  const [bookmarksChecked, setBookmarksChecked] = React.useState(false);
  const [urlsChecked, setUrlsChecked] = React.useState(true);
  const [person, setPerson] = React.useState("pedro");
  return (
    <Stack fill css={{ alignItems: "flex-start" }}>
      <SectionTitle>Dropdown Menu</SectionTitle>
      <Inline>
        <ShowcaseBox css={{ width: 400, height: 300, alignItems: "flex-start" }}>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button text="Click Me" iconRight="caret-down" />
            </DropdownMenuTrigger>

            <DropdownMenuContent>
              <DropdownMenuItem text="New Tab" icon="plus" rightSlot="⌘+T" />
              <DropdownMenuItem text="New Window" rightSlot="⌘+N" />
              <DropdownMenuItem disabled text="New Private Window" rightSlot="⇧+⌘+N" />
              <DropdownMenu>
                <DropdownMenuTriggerItem text="More Tools" />
                <DropdownMenuSubContent>
                  <DropdownMenuItem text="Save Page As…" rightSlot="⌘+S" />
                  <DropdownMenuItem text="Create Shortcut…" />
                  <DropdownMenuItem text="Name Window…" />
                  <DropdownMenuSeparator />
                  <DropdownMenuItem text="Developer Tools" />
                </DropdownMenuSubContent>
              </DropdownMenu>
              <DropdownMenuSeparator />
              <DropdownMenuCheckboxItem
                text="Show Bookmarks"
                rightSlot="⌘+B"
                checked={bookmarksChecked}
                onCheckedChange={setBookmarksChecked}
              />
              <DropdownMenuCheckboxItem
                text="Show Full URLs"
                checked={urlsChecked}
                onCheckedChange={setUrlsChecked}
              />
              <DropdownMenuSeparator />
              <DropdownMenuLabel>People</DropdownMenuLabel>
              <DropdownMenuRadioGroup value={person} onValueChange={setPerson}>
                <DropdownMenuRadioItem text="Pedro Duarte" value="pedro" />
                <DropdownMenuRadioItem text="Colm Tuite" value="colm" />
              </DropdownMenuRadioGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </ShowcaseBox>
      </Inline>
    </Stack>
  );
}

function PaginationBlock() {
  const totalPagesNum = 20;

  const [currentPageState, setCurrentPageState] = React.useState(1);

  const handlePageClick = React.useCallback(
    (pageEvent: PageEvent) => {
      setCurrentPageState(
        pageEvent.type === PageEventTypeEnum.FIRST
          ? 1
          : pageEvent.type === PageEventTypeEnum.PREV
          ? Math.max(currentPageState - 1, 1)
          : pageEvent.type === PageEventTypeEnum.NEXT
          ? Math.min(currentPageState + 1, totalPagesNum)
          : pageEvent.type === PageEventTypeEnum.LAST
          ? totalPagesNum
          : pageEvent.type === PageEventTypeEnum.PAGE
          ? pageEvent.page
          : 1
      );
    },
    [currentPageState]
  );

  return (
    <Stack fill css={{ alignItems: "flex-start" }}>
      <SectionTitle>Pagination</SectionTitle>
      <Box>
        <Box>Displaying Page: {currentPageState}</Box>
        <Inline>
          <label>Full mode:</label>
          <Pagination
            onPageClick={handlePageClick}
            options={{
              variant: "full",
              currentPage: currentPageState,
              numPages: totalPagesNum,
            }}
          />
        </Inline>
        <Inline>
          <label>Compact mode:</label>
          <Pagination onPageClick={handlePageClick} options={{ variant: "compact" }} />
        </Inline>
      </Box>
    </Stack>
  );
}

const TableRow = (props: { num: number }) => (
  <TR>
    <TD>Cell {props.num}1</TD>
    <TD>Cell {props.num}2</TD>
    <TD>Cell {props.num}3</TD>
    <TD>Cell {props.num}4</TD>
    <TD>Cell {props.num}5</TD>
  </TR>
);

function Tables() {
  return (
    <Stack fill css={{ alignItems: "flex-start" }}>
      <SectionTitle>Table</SectionTitle>
      <ShowcaseBox css={{ width: "500px", height: "300px" }}>
        <Table>
          <THead>
            <TR>
              <TH>Column 1</TH>
              <TH>Column 2</TH>
              <TH>Column 3</TH>
              <TH>Column 4</TH>
              <TH>Column 5</TH>
            </TR>
          </THead>
          <TBody>
            <TableRow num={1} />
            <TableRow num={2} />
            <TableRow num={3} />
            <TableRow num={4} />
            <TableRow num={5} />
          </TBody>
        </Table>
      </ShowcaseBox>
    </Stack>
  );
}

function TabsExample() {
  return (
    <Stack fill css={{ alignItems: "flex-start" }}>
      <SectionTitle>Tabs</SectionTitle>
      <Tabs defaultValue="tab1">
        <TabsList aria-label="Manage your account">
          <TabsTrigger value="tab1">Account</TabsTrigger>
          <TabsTrigger value="tab2">Password</TabsTrigger>
        </TabsList>
        <TabsContent value="tab1">
          <Text>Make changes to your account here. Click save when you're done.</Text>
        </TabsContent>
        <TabsContent value="tab2">
          <Text>Change your password here. After saving, you'll be logged out.</Text>
        </TabsContent>
      </Tabs>
    </Stack>
  );
}

function Dialogs() {
  return (
    <Stack fill css={{ alignItems: "flex-start" }}>
      <SectionTitle>Dialog</SectionTitle>
      <Dialog>
        <DialogTrigger asChild>
          <Button>Test Me</Button>
        </DialogTrigger>
        <DialogContent>
          <DialogTitle asChild>
            <Inline css={{ justifyContent: "space-between" }}>
              <Box>
                <h4>Dialog Title</h4>
              </Box>
              <DialogClose asChild>
                <Icon icon="times" />
              </DialogClose>
            </Inline>
          </DialogTitle>
          <DialogDescription css={{ backgroundColor: "inherit" }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec consectetur,
            est a accumsan tristique, ex justo maximus risus, ut rhoncus quam odio a
            libero. Nam egestas ipsum et neque consequat, eu mollis risus porta. Praesent
            purus quam, convallis vel mollis ut, tristique et justo. Nam metus erat,
            rhoncus vitae ligula ac, sagittis hendrerit neque. Nulla faucibus nisi purus.
            Ut feugiat urna sit amet justo condimentum ornare. Curabitur faucibus orci sed
            mollis iaculis. Phasellus ac turpis nisl.
          </DialogDescription>
          <DialogActions>
            <ButtonGroupRight fill>
              <Button size="normal" color="brand">
                Apply
              </Button>
              <DialogClose asChild>
                <Button size="normal">Close</Button>
              </DialogClose>
            </ButtonGroupRight>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Stack>
  );
}

function Alerts() {
  return (
    <Stack fill css={{ alignItems: "flex-start" }}>
      <SectionTitle>Alert</SectionTitle>
      <ShowcaseBox css={{ width: "$full" }}>
        <Inline fill>
          <Alert color="primary" css={{ width: 400 }}>
            <Stack css={{ alignItems: "start", gap: "$2" }}>
              <Text as="h4">Heading of size 4</Text>
              <Text as="b">Bold text</Text>
              <Text as="label">Label</Text>
              <Text>Regular text</Text>
              <SimpleLink href={undefined}>Link</SimpleLink>
              <Inline>
                <Button icon="arrow-left" color="primary" size="normal">
                  Action
                </Button>
                <Button
                  icon="arrow-left"
                  color="primary"
                  size="normal"
                  variant="outlined"
                >
                  Action
                </Button>
                <Button icon="arrow-left" color="primary" size="normal" variant="text">
                  Action
                </Button>
              </Inline>
            </Stack>
          </Alert>

          <Alert color="success" css={{ width: 400 }}>
            <Stack css={{ alignItems: "start", gap: "$2" }}>
              <Text as="h4">Heading of size 4</Text>
              <Text as="b">Bold text</Text>
              <Text as="label">Label</Text>
              <Text>Regular text</Text>
              <SimpleLink href={undefined}>Link</SimpleLink>
              <Inline>
                <Button icon="arrow-left" color="success" size="normal">
                  Action
                </Button>
                <Button
                  icon="arrow-left"
                  color="success"
                  size="normal"
                  variant="outlined"
                >
                  Action
                </Button>
                <Button icon="arrow-left" color="success" size="normal" variant="text">
                  Action
                </Button>
              </Inline>
            </Stack>
          </Alert>

          <Alert color="warning" css={{ width: 400 }}>
            <Stack css={{ alignItems: "start", gap: "$2" }}>
              <Text as="h4">Heading of size 4</Text>
              <Text as="b">Bold text</Text>
              <Text as="label">Label</Text>
              <Text>Regular text</Text>
              <SimpleLink href={undefined}>Link</SimpleLink>
              <Inline>
                <Button icon="arrow-left" color="warning" size="normal">
                  Action
                </Button>
                <Button
                  icon="arrow-left"
                  color="warning"
                  size="normal"
                  variant="outlined"
                >
                  Action
                </Button>
                <Button icon="arrow-left" color="warning" size="normal" variant="text">
                  Action
                </Button>
              </Inline>
            </Stack>
          </Alert>

          <Alert color="danger" css={{ width: 400 }}>
            <Stack css={{ alignItems: "start", gap: "$2" }}>
              <Text as="h4">Heading of size 4</Text>
              <Text as="b">Bold text</Text>
              <Text as="label">Label</Text>
              <Text>Regular text</Text>
              <SimpleLink href={undefined}>Link</SimpleLink>
              <Inline>
                <Button icon="arrow-left" color="danger" size="normal">
                  Action
                </Button>
                <Button icon="arrow-left" color="danger" size="normal" variant="outlined">
                  Action
                </Button>
                <Button icon="arrow-left" color="danger" size="normal" variant="text">
                  Action
                </Button>
              </Inline>
            </Stack>
          </Alert>
        </Inline>
      </ShowcaseBox>
    </Stack>
  );
}

function Checkboxes() {
  return (
    <Stack fill css={{ alignItems: "flex-start" }}>
      <SectionTitle>Checkbox</SectionTitle>
      <ShowcaseBox fill>
        <Inline fill>
          <CheckboxItem>Active</CheckboxItem>
          <CheckboxItem disabled>Disabled</CheckboxItem>
          <CheckboxItem checked>Checked</CheckboxItem>
          <CheckboxItem checked disabled>
            Checked and Disabled
          </CheckboxItem>
          <CheckboxItem checked icon="times">
            Custom Icon
          </CheckboxItem>
          <CheckboxItem checked disabled icon="times">
            Custom Icon and Disabled
          </CheckboxItem>
        </Inline>
      </ShowcaseBox>

      <ShowcaseBox>
        <Inline fill>
          <LabelInline>
            <Checkbox>
              <CheckboxIndicator>
                <Icon icon="check" />
              </CheckboxIndicator>
            </Checkbox>
            Clickable label
          </LabelInline>
        </Inline>
      </ShowcaseBox>
    </Stack>
  );
}

function RadioGroups() {
  return (
    <Stack fill css={{ alignItems: "flex-start" }}>
      <SectionTitle>Radio / Radio Group</SectionTitle>
      <ShowcaseBox fill>
        <Stack fill css={{ alignItems: "start", gap: "$8" }}>
          <Inline fill css={{ gap: "$20" }}>
            <Inline>
              <span>Vertical:</span>
              <RadioGroup defaultValue="true">
                <Stack css={{ gap: "$2" }}>
                  <RadioGroupItem value="true">Value 1</RadioGroupItem>
                  <RadioGroupItem value="false">Value 2</RadioGroupItem>
                </Stack>
              </RadioGroup>
            </Inline>
            <Inline>
              <span>Horizontal:</span>
              <RadioGroup defaultValue="false">
                <Inline css={{ gap: "$4" }}>
                  <RadioGroupItem value="true">Value 1</RadioGroupItem>
                  <RadioGroupItem value="false">Value 2</RadioGroupItem>
                </Inline>
              </RadioGroup>
            </Inline>
            <Inline>
              <span>No Defaults:</span>
              <RadioGroup>
                <Inline css={{ gap: "$4" }}>
                  <RadioGroupItem value="true">Value 1</RadioGroupItem>
                  <RadioGroupItem value="false">Value 2</RadioGroupItem>
                </Inline>
              </RadioGroup>
            </Inline>
          </Inline>
          <Inline fill css={{ gap: "$20" }}>
            <Inline>
              <span>Disabled:</span>
              <RadioGroup defaultValue="true">
                <Stack css={{ gap: "$2" }}>
                  <RadioGroupItem value="true" disabled>
                    Value 1
                  </RadioGroupItem>
                  <RadioGroupItem value="false" disabled>
                    Value 2
                  </RadioGroupItem>
                </Stack>
              </RadioGroup>
            </Inline>
            <Inline>
              <span>Disabled:</span>
              <RadioGroup defaultValue="false">
                <Inline css={{ gap: "$4" }}>
                  <RadioGroupItem value="true" disabled>
                    Value 1
                  </RadioGroupItem>
                  <RadioGroupItem value="false" disabled>
                    Value 2
                  </RadioGroupItem>
                </Inline>
              </RadioGroup>
            </Inline>
            <Inline>
              <span>Disabled:</span>
              <RadioGroup>
                <Inline css={{ gap: "$4" }}>
                  <RadioGroupItem value="true" disabled>
                    Value 1
                  </RadioGroupItem>
                  <RadioGroupItem value="false" disabled>
                    Value 2
                  </RadioGroupItem>
                </Inline>
              </RadioGroup>
            </Inline>
          </Inline>
        </Stack>
      </ShowcaseBox>
    </Stack>
  );
}

function GroupRadioButtonTheme() {
  const data = [
    {
      value: "Javascript",
      label: "Javascript",
    },
    {
      value: "CSS",
      label: "CSS",
    },
    {
      value: "Python",
      label: "Python",
    },
    {
      value: "Graphql",
      label: "Graphql",
    },
  ];
  return (
    <Stack fill css={{ alignItems: "flex-start" }}>
      <SectionTitle>Group Radio Button</SectionTitle>
      <ShowcaseBox fill justify="left">
        <Box css={{ width: 550 }}>
          <GroupRadioButtons color="brand" name="theme-test" buttons={data} />
        </Box>
        <Box css={{ width: 550 }}>
          <GroupRadioButtons color="primary" name="theme-test2" buttons={data} />
        </Box>
      </ShowcaseBox>
    </Stack>
  );
}

function Theme() {
  return (
    <PageContent>
      <Stack
        css={{
          alignItems: "flex-start",
          "& > :not([hidden]) ~ :not([hidden])": {
            borderTopWidth: 1,
            borderTopColor: "$primaryLight",
          },
        }}
      >
        <Palette />
        <Layout />
        <Typography />
        <Buttons />
        <Inputs />
        <HTMLSelects />
        <RadioGroups />
        <GroupRadioButtonTheme />
        <Checkboxes />
        <Tags />
        <Tooltips />
        <Popovers />
        <Dialogs />
        <DropdownMenus />
        <PaginationBlock />
        <Tables />
        <TabsExample />
        <Alerts />
      </Stack>
    </PageContent>
  );
}

export default Theme;
