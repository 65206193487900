import React, { useState, useCallback } from "react";
import { Set, fromJS } from "immutable";

import {
  VisibleColumnsOptions,
  getOrderedOptions,
  TableConfigModal,
  VisibleColumnsBlock,
  ItemsPerPageBlock,
  useTableConfigState,
  TableConfigProps,
} from "../../../components/tables/TableConfig";
import { emptySet } from "../../../constants";
import Stack from "../../../components/lib/Stack";

import {
  ALL_VALUES_TYPES,
  ALL_VALUES_TYPES_LABELS,
  ALL_VALUES_TYPES_TYPE,
  Values,
  VALUES_SUBTYPES,
  VALUES_SUBTYPES_LABELS,
  VALUES_SUBTYPES_TYPE,
} from "../types";

import type {
  TableConfigOptionObject,
  TableConfigOptionsList,
  VisibleColumnsChangeHandler,
  VisibleColumnsSet,
} from "../../../components/tables/types";
import type { Nullable } from "../../../types/generic";

export const ALL_VALUES_TYPES_OPTIONS: TableConfigOptionObject<ALL_VALUES_TYPES_TYPE>[] =
  [
    {
      uniqueKey: ALL_VALUES_TYPES.PAY_RATE,
      title: ALL_VALUES_TYPES_LABELS[ALL_VALUES_TYPES.PAY_RATE],
    },
    {
      uniqueKey: ALL_VALUES_TYPES.MARKUP,
      title: ALL_VALUES_TYPES_LABELS[ALL_VALUES_TYPES.MARKUP],
    },
    {
      uniqueKey: ALL_VALUES_TYPES.BILL_RATE,
      title: ALL_VALUES_TYPES_LABELS[ALL_VALUES_TYPES.BILL_RATE],
    },
    {
      uniqueKey: ALL_VALUES_TYPES.ANNUAL_SALARY,
      title: ALL_VALUES_TYPES_LABELS[ALL_VALUES_TYPES.ANNUAL_SALARY],
    },
  ];
export const ALL_VALUES_TYPES_OPTIONS_LIST = fromJS(
  ALL_VALUES_TYPES_OPTIONS
) as TableConfigOptionsList;

export const ALL_VALUES_SUBTYPES_OPTIONS: TableConfigOptionObject<VALUES_SUBTYPES_TYPE>[] =
  [
    {
      uniqueKey: VALUES_SUBTYPES.MIN,
      title: VALUES_SUBTYPES_LABELS[VALUES_SUBTYPES.MIN],
    },
    {
      uniqueKey: VALUES_SUBTYPES.MID,
      title: VALUES_SUBTYPES_LABELS[VALUES_SUBTYPES.MID],
    },
    {
      uniqueKey: VALUES_SUBTYPES.MAX,
      title: VALUES_SUBTYPES_LABELS[VALUES_SUBTYPES.MAX],
    },
    {
      uniqueKey: VALUES_SUBTYPES.AVG,
      title: VALUES_SUBTYPES_LABELS[VALUES_SUBTYPES.AVG],
    },
  ];
export const ALL_VALUES_SUBTYPES_OPTIONS_LIST = fromJS(
  ALL_VALUES_SUBTYPES_OPTIONS
) as TableConfigOptionsList;

const VALUES_GRAPH_TYPES = {
  GRAPH: "graph",
} as const;

type VALUES_GRAPH_TYPES_TYPE = Values<typeof VALUES_GRAPH_TYPES>;

const VALUES_GRAPH_LABELS = {
  [VALUES_GRAPH_TYPES.GRAPH]: "Display Graph",
};

const ALL_VALUES_GRAPH_OPTIONS: TableConfigOptionObject<VALUES_GRAPH_TYPES_TYPE>[] = [
  {
    uniqueKey: VALUES_GRAPH_TYPES.GRAPH,
    title: VALUES_GRAPH_LABELS[VALUES_GRAPH_TYPES.GRAPH],
  },
];
const ALL_VALUES_GRAPH_OPTIONS_LIST = fromJS(
  ALL_VALUES_GRAPH_OPTIONS
) as TableConfigOptionsList;

const generalOptionsKeys = Set([
  "market_analysis__refresh_timestamp",
  "category",
  "source",
  "rate_type",
  "worker_type_title",
  "job_title",
  "job_family",
  "processed__job_collection_title",
  "processed__job_collection_id",
  "processed__job_title_id",
  "processed__job_title_match_type",
  "processed__needs_review",
  "market_analysis__collection_limits_client_accepted",
  "market_analysis__location_limits_modeled",
  "market_analysis__confidence_score",
  "processed__industry_title",
  "city",
  "state",
  "country",
  "region",
  "is_global_supplier_search",
  "market_analysis__market_level",
  "supplier",
]);

type ExtraValuesKeys =
  | typeof ALL_VALUES_TYPES.MARKUP
  | typeof ALL_VALUES_TYPES.BILL_RATE
  | typeof ALL_VALUES_TYPES.ANNUAL_SALARY;

const extraComparisonKeys: { [K in ExtraValuesKeys]: string[] } = {
  [ALL_VALUES_TYPES.MARKUP]: ["markup_amount"],
  [ALL_VALUES_TYPES.BILL_RATE]: ["total_cost"],
  [ALL_VALUES_TYPES.ANNUAL_SALARY]: ["burden_perc", "total_cost"],
};

const getSelectedComparisonParameters = (visibleColumns: VisibleColumnsSet) => {
  let selectedValueType: ALL_VALUES_TYPES_TYPE | null = null;
  let selectedValueSubtype: VALUES_SUBTYPES_TYPE | null = null;
  let displayGraph: VALUES_GRAPH_TYPES_TYPE | null = null;

  for (let i = 0; i < ALL_VALUES_TYPES_OPTIONS.length; i++) {
    const valueTypeOption = ALL_VALUES_TYPES_OPTIONS[i];
    const valueType = valueTypeOption.uniqueKey;

    for (let k = 0; k < ALL_VALUES_SUBTYPES_OPTIONS.length; k++) {
      const valueSubtypeOption = ALL_VALUES_SUBTYPES_OPTIONS[k];
      const valueSubtype = valueSubtypeOption.uniqueKey;
      const marketValueKey = ["market_analysis__market", valueType, valueSubtype].join(
        "_"
      );

      if (visibleColumns.includes(marketValueKey)) {
        selectedValueSubtype = valueSubtype;
        break;
      }
    }

    if (visibleColumns.includes(`${valueType}_savings`)) {
      displayGraph = ALL_VALUES_GRAPH_OPTIONS[0].uniqueKey;
    }

    if (visibleColumns.includes(valueType)) {
      selectedValueType = valueType;
      break;
    }
  }

  return {
    selectedValueType,
    selectedValueSubtype,
    displayGraph,
  };
};

type ComparisonParametersState = Nullable<{
  selectedValueType: ALL_VALUES_TYPES_TYPE;
  selectedValueSubtype: VALUES_SUBTYPES_TYPE;
  displayGraph: VALUES_GRAPH_TYPES_TYPE;
}>;

const CompareWithMarketTableConfig = (props: TableConfigProps) => {
  const {
    visibleColumns: defaultVisibleColumns,
    itemsPerPage: defaultItemsPerPage,
    show,
    options,
    onHide,
    onChange,
  } = props;

  const { selectedValueType, selectedValueSubtype, displayGraph } = React.useMemo(() => {
    return getSelectedComparisonParameters(defaultVisibleColumns);
  }, [defaultVisibleColumns]);

  const orderedColumnsOptions = React.useMemo(() => {
    return getOrderedOptions(
      options.filter((i) => generalOptionsKeys.includes(i.get("uniqueKey")))
    );
  }, [options]);

  // state

  const {
    visibleColumns,
    itemsPerPage,
    setVisibleColumns,
    setItemsPerPage,
    hasVisibleColumnsChanges,
    hasItemsPerPageChanges,
    hasChanges,
    handleSelectAllColumns,
    handleChangeVisibleColumns,
    handleChangeItemsPerPage,
  } = useTableConfigState(defaultVisibleColumns, defaultItemsPerPage, options);

  const [comparisonParameters, setComparisonParameters] =
    useState<ComparisonParametersState>({
      selectedValueType,
      selectedValueSubtype,
      displayGraph,
    });

  const handleChangeValueType: VisibleColumnsChangeHandler = useCallback(
    (value, isSelected) => {
      // exclude comparison columns from visible columns set
      setVisibleColumns(visibleColumns.intersect(generalOptionsKeys));
      setComparisonParameters({
        selectedValueType: isSelected ? (value as ALL_VALUES_TYPES_TYPE) : null,
        selectedValueSubtype: null,
        displayGraph: null,
      });
    },
    [visibleColumns, setVisibleColumns]
  );

  const handleChangeValueSubtype: VisibleColumnsChangeHandler = useCallback(
    (value, isSelected) => {
      const selectedValueType =
        comparisonParameters.selectedValueType || ALL_VALUES_TYPES.PAY_RATE;
      const columnsKeys = Set([
        selectedValueType,
        `market_analysis__market_${selectedValueType}_${value}`,
        `market_analysis__market_${selectedValueType}_${value}_variance`,
        ...(extraComparisonKeys[selectedValueType as ExtraValuesKeys] || []),
      ]);

      setVisibleColumns(
        isSelected
          ? visibleColumns.merge(columnsKeys)
          : visibleColumns.subtract(columnsKeys)
      );
      setComparisonParameters((prevState) => ({
        ...prevState,
        selectedValueSubtype: isSelected ? (value as VALUES_SUBTYPES_TYPE) : null,
        displayGraph: null,
      }));
    },
    [comparisonParameters.selectedValueType, setVisibleColumns, visibleColumns]
  );

  const handleChangeDisplayGraph: VisibleColumnsChangeHandler = useCallback(
    (value, isSelected) => {
      const graphColumnKey = `${comparisonParameters.selectedValueType}_savings`;
      setVisibleColumns(
        isSelected
          ? visibleColumns.add(graphColumnKey)
          : visibleColumns.delete(graphColumnKey)
      );
      setComparisonParameters((prevState) => ({
        ...prevState,
        displayGraph: isSelected ? (value as VALUES_GRAPH_TYPES_TYPE) : null,
      }));
    },
    [comparisonParameters.selectedValueType, visibleColumns, setVisibleColumns]
  );

  const handleUnselectAllColumns = useCallback(() => {
    setVisibleColumns(emptySet);
    setComparisonParameters({
      selectedValueType: null,
      selectedValueSubtype: null,
      displayGraph: null,
    });
  }, [setVisibleColumns]);

  const handleApplyChanges = useCallback(() => {
    onHide();
    onChange(
      hasVisibleColumnsChanges ? visibleColumns : null,
      hasItemsPerPageChanges ? itemsPerPage : null
    );
  }, [
    onChange,
    onHide,
    hasVisibleColumnsChanges,
    hasItemsPerPageChanges,
    itemsPerPage,
    visibleColumns,
  ]);

  const handleCancelChanges = useCallback(() => {
    onHide();
    setVisibleColumns(defaultVisibleColumns);
    setComparisonParameters({ selectedValueType, selectedValueSubtype, displayGraph });
    setItemsPerPage(defaultItemsPerPage);
  }, [
    onHide,
    setItemsPerPage,
    setVisibleColumns,
    setComparisonParameters,
    defaultItemsPerPage,
    defaultVisibleColumns,
    selectedValueType,
    selectedValueSubtype,
    displayGraph,
  ]);

  return (
    <TableConfigModal
      show={show}
      onApply={handleApplyChanges}
      onHide={handleCancelChanges}
      disabled={!hasChanges}
    >
      <Stack fill>
        <VisibleColumnsBlock
          onSelectAll={handleSelectAllColumns}
          onUnselectAll={handleUnselectAllColumns}
        >
          <Stack fill css={{ alignItems: "stretch" }}>
            <VisibleColumnsOptions
              title="General Data"
              options={orderedColumnsOptions}
              value={visibleColumns}
              onChange={handleChangeVisibleColumns}
              gridCss={{
                gap: "$1",
                gridAutoFlow: "column",
                gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
                gridTemplateRows: "repeat(11, minmax(0, 1fr))",
                "@lg": {
                  gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
                  gridTemplateRows: "repeat(8, minmax(0, 1fr))",
                },
              }}
            />
            <VisibleColumnsOptions
              title="Market Rates To Compare"
              options={ALL_VALUES_TYPES_OPTIONS_LIST}
              value={comparisonParameters.selectedValueType}
              onChange={handleChangeValueType}
            />
            <VisibleColumnsOptions
              title="Value To Compare"
              options={ALL_VALUES_SUBTYPES_OPTIONS_LIST}
              value={comparisonParameters.selectedValueSubtype}
              onChange={handleChangeValueSubtype}
              disabled={!comparisonParameters.selectedValueType}
            />
            <VisibleColumnsOptions
              title="Visualisation"
              options={ALL_VALUES_GRAPH_OPTIONS_LIST}
              value={comparisonParameters.displayGraph}
              onChange={handleChangeDisplayGraph}
              disabled={
                !comparisonParameters.selectedValueType ||
                !comparisonParameters.selectedValueSubtype
              }
              gridCss={{
                gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
              }}
            />
          </Stack>
        </VisibleColumnsBlock>
        <ItemsPerPageBlock
          itemsPerPage={itemsPerPage}
          onChange={handleChangeItemsPerPage}
        />
      </Stack>
    </TableConfigModal>
  );
};
CompareWithMarketTableConfig.displayName = CompareWithMarketTableConfig;

export default CompareWithMarketTableConfig;
