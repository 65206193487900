import React from "react";

import Stack from "../../../../components/lib/Stack";
import Text from "../../../../components/lib/Text";
import TextArea from "../../../../components/lib/TextArea";
import ParametersLineBase from "../../lib/ParametersLine";
import BiggerTextBase from "../../lib/BiggerText";
import { QuestionsFeedbackViewer } from "../QuestionsTable";
import { dateTimeFormatterExt } from "../../constants";
import { styled } from "../../../../stitches.config";

import type { QuestionsOrderedMap, QuestionsFeedbackOrderedMap } from "../../types";

const ParametersBlock = styled(Stack, {
  alignItems: "stretch",
  gap: "$1",
});

const ParametersLine = styled(ParametersLineBase, {
  [`& ${Text}:first-child`]: {
    fontWeight: "$bold",
    fontSize: "$base",
  },
});

const BiggerText = styled(BiggerTextBase, {
  fontWeight: "$bold",
});

export type JobFeedbackDetailsBlockProps = {
  title: string;
  rateTypeLabel: string;
  validatorString: string;
  validationTimestamp: Date;
  jobIsRegionBased?: boolean;
  jobLocationString?: string;
  jobRegionString?: string;
  countriesString?: string;
  currencyCode?: string;
  currencyName?: string;
  industries?: string;
  description?: string;
  questions: QuestionsOrderedMap;
  questionsFeedback: QuestionsFeedbackOrderedMap;
};

const JobFeedbackDetailsBlock = (
  props: React.PropsWithChildren<JobFeedbackDetailsBlockProps>
) => {
  const {
    title,
    rateTypeLabel,
    validatorString,
    validationTimestamp,
    jobIsRegionBased,
    jobLocationString,
    jobRegionString,
    countriesString,
    currencyCode,
    currencyName,
    industries,
    description,
    questions,
    questionsFeedback,
    children,
  } = props;

  return (
    <>
      <ParametersBlock>
        <ParametersLine>
          <Text>Title:</Text>
          <Text>{title}</Text>
        </ParametersLine>
        {!jobIsRegionBased && jobLocationString && (
          <ParametersLine>
            <Text>Location:</Text>
            <Text>{jobLocationString}</Text>
          </ParametersLine>
        )}
        {jobIsRegionBased && jobRegionString && (
          <ParametersLine>
            <Text>Region:</Text>
            <Text>{jobRegionString}</Text>
          </ParametersLine>
        )}
        {countriesString && (
          <ParametersLine>
            <Text>Country:</Text>
            <Text>{countriesString}</Text>
          </ParametersLine>
        )}
        {currencyCode && (
          <ParametersLine>
            <Text>Currency:</Text>
            <Text>
              {currencyName}, {currencyCode}
            </Text>
          </ParametersLine>
        )}
        {industries && (
          <ParametersLine>
            <Text>Industry:</Text>
            <Text>{industries}</Text>
          </ParametersLine>
        )}
        <ParametersLine>
          <Text bold css={{ fontSize: "base" }}>
            Rate Type:
          </Text>
          <Text>{rateTypeLabel}</Text>
        </ParametersLine>
        {description && (
          <Stack fill css={{ alignItems: "stretch", gap: "$2" }}>
            <Text bold size="base">
              Description:
            </Text>
            <TextArea
              fill
              css={{ resize: "vertical" }}
              readOnly
              rows={10}
              value={description}
            />
          </Stack>
        )}
      </ParametersBlock>
      <ParametersBlock>
        <ParametersLine>
          <Text>Validated by:</Text>
          <Text>{validatorString}</Text>
        </ParametersLine>
        <ParametersLine>
          <Text>Validation date:</Text>
          <Text>{dateTimeFormatterExt(validationTimestamp)}</Text>
        </ParametersLine>
      </ParametersBlock>
      <ParametersBlock>
        <Stack fill css={{ alignItems: "stretch", gap: "$2" }}>
          <BiggerText>Validator feedback:</BiggerText>
          <Stack fill css={{ alignItems: "stretch", gap: "$6" }}>
            {children}
          </Stack>
        </Stack>
      </ParametersBlock>
      {!!questions?.size && (
        <ParametersBlock>
          <BiggerText>Additional questions feedback:</BiggerText>
          <QuestionsFeedbackViewer questions={questions} feedback={questionsFeedback} />
        </ParametersBlock>
      )}
    </>
  );
};
JobFeedbackDetailsBlock.displayName = "JobFeedbackDetailsBlock";

export default JobFeedbackDetailsBlock;
