import React, { useEffect, useState } from "react";
import { useRefetchableFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { WorkerType, Industry, Location } from "../types";
import Box from "../../../components/lib/Box";
import { WorkerTypeField_viewer$key } from "./__generated__/WorkerTypeField_viewer.graphql";
import { Autocomplete } from "../../../components/lib/Autocomplete";
import { useRateSearchFormStore } from "../../../stores/RateSearchForm";
import { isNonNullable } from "../../../utils/hashmap";
import { FormControl, Label, LabelWrapper } from "../../../components/lib/Label";
import { Placeholder } from "../../../components/lib/Placeholder";
import { onLocationsOrIndustriesChange } from "../observers";

const fragment = graphql`
  fragment WorkerTypeField_viewer on Viewer
  @refetchable(queryName: "WorkerTypeFieldRefetchQuery")
  @argumentDefinitions(
    countryId: { type: "Int", defaultValue: 0 }
    industryId: { type: "Int", defaultValue: 0 }
  ) {
    workerTypes(countryId: $countryId, industryId: $industryId) {
      workerTypeId: id
      name
      description
    }
  }
`;

type Props = {
  viewer: WorkerTypeField_viewer$key;
};

export function WorkerTypeField(props: Props) {
  const { viewer } = props;
  const [value, setValue] = useState("");
  const showWorkerType = useRateSearchFormStore((s) => s.showWorkerType());
  const workerType = useRateSearchFormStore((s) => s.workerType);
  const setWorkerType = useRateSearchFormStore((s) => s.setWorkerType);

  const [data, refetch] = useRefetchableFragment(fragment, viewer);
  const options = data?.workerTypes?.filter(isNonNullable) || [];

  function onSelectHandler(o: WorkerType) {
    if (o) {
      setWorkerType(o);
      setValue(o.name || "");
    } else {
      setWorkerType(null);
      setValue("");
    }
  }

  useEffect(() => {
    function listener(value: { locations: Location[]; industries: Industry[] }) {
      const countryId = value.locations.length > 0 ? value.locations[0].countryId! : 0;
      const industryId = value.industries.length > 0 ? value.industries[0].legacyId! : 0;

      if (countryId && industryId) {
        refetch({
          countryId,
          industryId,
        });
      } else if (workerType) {
        setWorkerType(null);
      }
    }

    const unsubscribe = onLocationsOrIndustriesChange.subscribe(listener);
    return () => unsubscribe();
  }, [setWorkerType, workerType, refetch]);

  return (
    <Box>
      <Autocomplete
        icon="file"
        inputValue={value}
        onInputChange={(e) => setValue(e.target.value)}
        renderOption={(o) => o.name}
        id="workerTypeSelect"
        getKey={(o) => String(o.workerTypeId)}
        label="Worker Type (Optional)"
        placeholder="Select Worker Type"
        selected={workerType}
        readOnly
        onSelect={onSelectHandler}
        options={options}
        disabled={!showWorkerType}
      />
    </Box>
  );
}

export function WorkerTypeFieldLoader() {
  return (
    <Box>
      <FormControl fill>
        <LabelWrapper>
          <Label>Worker Type (Optional)</Label>
        </LabelWrapper>
        <Placeholder height={44} />
      </FormControl>
    </Box>
  );
}
