import R from "ramda";

/**
 * Checks to see if a field has been updated. Curryable.
 */
export default R.curryN(4, (unsavedValues, values, field, value) => {
  var fieldReadOnly = false;
  var fieldVal;
  if (field.fieldTypeConfig.readOnly) fieldReadOnly = field.fieldTypeConfig.readOnly;
  const readOnly =
    typeof field === "string"
      ? false
      : fieldReadOnly && field.fieldType !== "scriptedPercentage";
  const fieldKey = field.key;
  let savedValue = undefined;
  let val = undefined;

  if (unsavedValues.length === undefined) {
    val = unsavedValues[fieldKey];
  } else {
    fieldVal = unsavedValues.find(function (obj) {
      return obj.hasOwnProperty(fieldKey);
    });
    if (fieldVal) {
      val = fieldVal[fieldKey];
    }
  }

  if (values && !readOnly) savedValue = values[0] ? values[0][fieldKey] : undefined;
  if (savedValue === undefined) savedValue = field.value;

  let unsavedValue = value !== null ? value : val;
  return unsavedValue !== undefined && unsavedValue !== savedValue;
});
