// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Link } from "react-router";
import classNames from "classnames";
import CheckBox from "../../../shared/CheckBox";
import { TagItemWithoutDelete } from "../../../ratecards/components/TagItem";

const ProjectCostEstimateListItem = observer(
  class extends Component {
    static contextTypes = {
      router: PropTypes.object,
    };

    constructor(props) {
      super(props);

      this.handleViewDetail = this.handleViewDetail.bind(this);
      this.deleteTag = this.deleteTag.bind(this);
      this.state = {
        updated: false,
      };
    }

    handleViewDetail() {
      this.context.router.push({
        pathname: `/admin/project-cost-estimate/${this.props.pce.projectCostId}`,
        query: this.context.router.query,
      });
    }

    deleteTag(event: object, tag_id) {
      console.log("got it");
      event.preventDefault();
      event.stopPropagation();
      event.cancelBubble = true;
      event.returnValue = false;

      console.log("event", event);
      this.props.store
        .deleteSingleTags({
          tagId: tag_id,
          contentId: this.props.pce.projectCostId,
        })
        .then((res) => {
          console.log("result", res);
          if (!res) return;
          this.props.pce.tags.forEach((item, index) => {
            if (item.tagId === tag_id) {
              this.props.pce.tags.splice(index, 1);
            }
          });
          this.setState({
            updated: true,
          });
        });
    }

    render() {
      const props = this.props;
      const pce = props.pce;
      const editing = pce.viewState.editing;
      const selected = pce.viewState.selected;
      const name = pce.name;
      const id = pce.projectCostId;
      const createdDisplay = pce.createdDate.format("MMMM D, YYYY");
      const ownerName = pce.owner;

      let onItemClick = this.props.onClick || this.handleViewDetail;
      let editingSection = null;

      const tags = pce.tags;
      let tagSection = null;

      // Display only when edit button is clicked
      if (editing) {
        onItemClick = pce.toggleSelected;
        editingSection = (
          <CheckBox
            type={props.store.allowMultipleItemSelection ? "checkbox" : "radio"}
            name="list-item-select"
            value={name}
            selected={selected}
            style={{ width: 34 }}
            onClick={onItemClick}
          />
        );
      }

      if (tags.length > 0 && !this.props.store.isTagView) {
        tagSection = tags.map((tag) => (
          <TagItemWithoutDelete key={tag.tagId} tag={tag} onClick={this.deleteTag} />
        ));
      }

      return (
        <div className="ratecard-list-item-container">
          <div
            className={classNames("ratecard-list-item", { selected: selected })}
            onClick={onItemClick}
          >
            <div className="ratecard-list-item-left ellipsis">
              {editingSection}
              <div className="ratecard-label-container ellipsis">
                <div className="ratecard-label ellipsis">
                  <span className="ellipsis">{name}</span>
                </div>
                <div className="ratecard-meta ellipsis">
                  <span className="item-subtext-muted">Created by </span>
                  <span className="item-subtext-accent">{ownerName} </span>
                  <span className="item-subtext-muted">on </span>
                  <span className="item-subtext-accent">{createdDisplay}</span>
                </div>
              </div>
            </div>
            {!props.store.isTagView && (
              <div className="ratecard-list-item-right">
                <div className="ratecard-info w-300 text-center">
                  <header data-testid="ID-header-project-cost-estimate">ID</header>
                  <div>{id}</div>
                </div>
                <div className="rc-center">
                  <Link
                    to={`/admin/project-cost-estimate/${id}`}
                    className="btn btn-default"
                  >
                    View
                  </Link>
                </div>
              </div>
            )}
            {props.store.isTagView && (
              <div className="ratecard-list-item-right">&nbsp;</div>
            )}
            <div className={classNames("tagbox", { "p-left-34": editing })}>
              {tagSection}
            </div>
          </div>
        </div>
      );
    }
  }
);

ProjectCostEstimateListItem.propTypes = {
  store: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  pce: PropTypes.object.isRequired,
};

export default ProjectCostEstimateListItem;
