import React, { useEffect, useState, useCallback } from "react";
import { Set } from "immutable";

import { styled } from "../../../stitches.config";

// @ts-expect-error
import ExpLevelSelect from "../../../components/selects/ExpLevelSelect";
import { ButtonGroupRight } from "../../../components/lib/ButtonGroup";
import Grid from "../../../components/lib/Grid";
import Stack from "../../../components/lib/Stack";
import Box from "../../../components/lib/Box";
import Alert from "../../../components/lib/Alert";
import { Link } from "../../../components/lib/Link";

// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";
import { emptyList, emptyMap, emptySet } from "../../../constants";

import { TickerPageLoader } from "../../../components/lib/TickerLoader";
import {
  Card,
  CardHeaderTitle,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
  CardBody,
} from "../../../components/lib/Card";
import { NavigationButton, Button } from "../../../components/lib/Button";

import DownloadPOSTButtonExt from "../components/DownloadPOSTButtonExt";
import StatsTableCell from "../components/StatsTableCell";
import Description from "../components/Description";
import MarketRatesTable from "../components/MarketRatesTable";

import { useProgramContext } from "../ProgramDataProvider";
import { contractorItemToImmutableMap } from "../dataConverters";
import { decimalFormatter as baseDecimalFormatter, percentFormatter } from "../constants";
import {
  RATE_TYPES,
  CONTINGENT_VALUES_TYPES,
  ALL_VALUES_TYPES,
  VALUES_SUBTYPES,
  SKILLS_LEVELS,
  SKILLS_LEVELS_KEYS,
  SKILLS_LEVELS_ROMAN_LABELS,
  AllMarketRatesObject,
  ALL_VALUES_TYPES_TYPE,
  SKILLS_LEVELS_TYPE,
} from "../types";

import type {
  ContractorDataMap,
  ContractorDataObject,
  MarketSearchRatesDataObject,
  MarketSearchResultMap,
  MarketSearchResultsList,
} from "../types";
import type { CommonProgramChildPageProps } from "../ProgramDataProvider";
import type { UrlQueryObject } from "../../../components/tables/types";
import type { FetchAPIResponse } from "../../../types/fetch";
import { ImmutableSet } from "../../../types/immutable";
import { usePLIContext } from "../context";

type SkillsLevelsSet = ImmutableSet<SKILLS_LEVELS_TYPE>;

const ALL_SKILLS_LEVELS_ARRAY = [
  SKILLS_LEVELS.JUNIOR,
  SKILLS_LEVELS.INTERMEDIATE,
  SKILLS_LEVELS.SENIOR,
  SKILLS_LEVELS.LEAD,
  SKILLS_LEVELS.GURU,
];
const ALL_SKILLS_LEVELS_SET = Set(ALL_SKILLS_LEVELS_ARRAY) as unknown as SkillsLevelsSet;

const ALL_CONTINGENT_RATE_VALUE_TYPES = [
  CONTINGENT_VALUES_TYPES.PAY_RATE,
  CONTINGENT_VALUES_TYPES.BILL_RATE,
  CONTINGENT_VALUES_TYPES.MARKUP,
];
const ALL_RATE_VALUE_SUBTYPES = [
  VALUES_SUBTYPES.MIN,
  VALUES_SUBTYPES.MID,
  VALUES_SUBTYPES.AVG,
  VALUES_SUBTYPES.MAX,
];

const Paragraph = styled("p", {
  marginLeft: "$2 !important",
  variants: {
    big: {
      true: {
        fontSize: "$xl",
        lineHeight: "$lg",
      },
    },
  },
  defaultVariants: {
    big: false,
  },
});

Paragraph.displayName = "Paragraph";

const ActionLink = styled(Link, {
  display: "block",
  marginLeft: "$2",
  "&:first-of-type": {
    marginTop: "$1_5",
  },
  "&:hover": {
    textDecoration: "underline",
  },
});

ActionLink.displayName = "ActionLink";

// INFO: display currency feature is disabled
const displayCurrencyCode: string | undefined = undefined;

type PageHeadBlockProps = {
  contractorId: number;
  hasDataToExport: boolean;
  onBackToPreviousPage: () => void;
};

const PageHeadBlock = (props: PageHeadBlockProps) => {
  const { contractorId, hasDataToExport, onBackToPreviousPage } = props;

  const { showModalError, showLoader, hideLoader, sessionInfo } = usePLIContext();
  const { programId } = useProgramContext();

  const handleGetExportQueryArgs = useCallback(() => {
    const queryArgs: UrlQueryObject = {};

    if (sessionInfo.legacySession) {
      queryArgs["x-session-id"] = sessionInfo.legacySession;
    }

    if (displayCurrencyCode) {
      queryArgs["currency-code"] = displayCurrencyCode;
    }

    return Object.keys(queryArgs).length > 0 ? queryArgs : null;
  }, [sessionInfo.legacySession]);

  const handleExportStarted = useCallback(() => {
    showLoader();
  }, [showLoader]);

  const handleExportSuccess = useCallback(() => {
    hideLoader();
  }, [hideLoader]);

  const handleExportError = useCallback(
    (err: Error) => {
      logAsyncOperationError("exportMarketSearchDetails", err);
      showModalError("Error occurred while exporting, try again later.");
    },
    [showModalError]
  );

  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft>
          <CardHeaderTitle>Market Search Results</CardHeaderTitle>
        </CardActionsLeft>
        <CardActionsRight>
          {hasDataToExport && (
            <DownloadPOSTButtonExt
              size="normal"
              icon={["far", "file-excel"]}
              color="brand"
              variant="outlined"
              loadingText="Export to Excel"
              service="m8"
              endpoint={`programs/${programId}/contractors/${contractorId}/market_analysis/export/`}
              getQueryArgs={handleGetExportQueryArgs}
              onClick={handleExportStarted}
              onSuccess={handleExportSuccess}
              onError={handleExportError}
            >
              Export to Excel
            </DownloadPOSTButtonExt>
          )}
          <NavigationButton icon="arrow-left" onClick={onBackToPreviousPage}>
            Back To Previous Page
          </NavigationButton>
        </CardActionsRight>
      </CardActions>
    </Card>
  );
};

PageHeadBlock.displayName = "PageHeadBlock";

type NoContractorDataFoundProps = {
  onBackToPreviousPage: () => void;
};

const NoContractorDataFound = (props: NoContractorDataFoundProps) => {
  const { onBackToPreviousPage } = props;

  return (
    <Alert color="warning">
      <Stack fill css={{ alignItems: "start" }}>
        <h4>Can't find contractor data for comparison.</h4>
        <NavigationButton
          icon="arrow-left"
          color="warning"
          onClick={onBackToPreviousPage}
        >
          Back To Previous Page
        </NavigationButton>
      </Stack>
    </Alert>
  );
};

NoContractorDataFound.displayName = "NoContractorDataFound";

type PageDataState = {
  showVariances: boolean;
  showLevels: SkillsLevelsSet;
  showValues: ALL_VALUES_TYPES_TYPE | null;
};

type MainContentBlockProps = {
  contractorData: ContractorDataMap;
  fetchMarketAnalysisData: (queryArgs: UrlQueryObject) => Promise<any>;
  searchResults: MarketSearchResultsList;
};

const MainContentBlock = (props: MainContentBlockProps) => {
  const { contractorData, fetchMarketAnalysisData, searchResults } = props;

  const {
    currenciesData,
    showConfirmationModal,
    closeConfirmationModal,
    showLoader,
    hideLoader,
  } = usePLIContext();
  const { isPreviewMode } = useProgramContext();

  const contractorJobTitle = contractorData.get("job_title");
  const contractorJobDescription = contractorData.get("job_description");
  const contractorLocation = [
    contractorData.get("city"),
    contractorData.get("state"),
    contractorData.get("country"),
  ]
    .filter((i) => !!i)
    .join(", ");
  const contractorRegion = contractorData.get("region");
  const contractorExpLevel = contractorData.get("market_analysis")?.get("market_level");
  const isLevelFixedManually =
    contractorData.get("market_analysis")?.get("is_level_fixed") ?? false;

  const displayCurrencyFactor = displayCurrencyCode
    ? currenciesData.get(displayCurrencyCode)?.get("factor")
    : undefined;
  const displayCurrencySymbol = displayCurrencyCode
    ? currenciesData.get(displayCurrencyCode)?.get("symbol")
    : undefined;
  const contractorCurrencyCode = contractorData.get("processed")!.get("currency_code");
  const contractorCurrencyFactor = contractorCurrencyCode
    ? currenciesData.get(contractorCurrencyCode)?.get("factor")
    : undefined;
  const contractorCurrencySymbol = contractorCurrencyCode
    ? currenciesData.get(contractorCurrencyCode)?.get("symbol")
    : undefined;
  const decimalFormatter = (value: number | null): string =>
    baseDecimalFormatter(value, false, displayCurrencySymbol || contractorCurrencySymbol);
  const needConversion = !!(
    displayCurrencyCode &&
    contractorCurrencyCode &&
    displayCurrencyCode !== contractorCurrencyCode &&
    displayCurrencyFactor &&
    contractorCurrencyFactor
  );

  let contractorPayRate = contractorData.get("pay_rate");
  let contractorBillRate = contractorData.get("bill_rate");
  let contractorMarkup = contractorData.get("markup");
  let contractorAnnualSalary = contractorData.get("annual_salary");
  if (contractorPayRate && needConversion)
    contractorPayRate =
      (contractorPayRate / contractorCurrencyFactor) * displayCurrencyFactor;
  if (contractorBillRate && needConversion)
    contractorBillRate =
      (contractorBillRate / contractorCurrencyFactor) * displayCurrencyFactor;
  if (contractorAnnualSalary && needConversion)
    contractorAnnualSalary =
      (contractorAnnualSalary / contractorCurrencyFactor) * displayCurrencyFactor;

  const hasHourlyRatesData =
    contractorPayRate != null ||
    contractorBillRate != null ||
    contractorAnnualSalary != null;
  const hasAnnualRatesData = contractorAnnualSalary != null;
  const hasAnyRatesData = hasHourlyRatesData || hasAnnualRatesData;

  // state

  const [pageDataState, setPageDataState] = useState<PageDataState>({
    showVariances: false,
    showLevels: (contractorData
      ? Set([contractorExpLevel || SKILLS_LEVELS.SENIOR])
      : emptySet) as SkillsLevelsSet,
    showValues:
      contractorData && contractorData.get("rate_type") === RATE_TYPES.FTE
        ? ALL_VALUES_TYPES.ANNUAL_SALARY
        : ALL_VALUES_TYPES.BILL_RATE,
  });

  useEffect(() => {
    setPageDataState((prevState) => {
      if (prevState.showLevels.size <= 1) {
        return {
          ...prevState,
          showLevels: Set([
            contractorExpLevel || SKILLS_LEVELS.SENIOR,
          ]) as unknown as SkillsLevelsSet,
        };
      }
      return prevState;
    });
  }, [contractorExpLevel, pageDataState.showValues, pageDataState.showVariances]);

  // handlers

  const handleCompareBillRate = useCallback(() => {
    setPageDataState((prevState) => ({
      ...prevState,
      showValues: ALL_VALUES_TYPES.BILL_RATE,
      showVariances: true,
    }));
  }, [setPageDataState]);

  const handleComparePayRate = useCallback(() => {
    setPageDataState((prevState) => ({
      ...prevState,
      showValues: ALL_VALUES_TYPES.PAY_RATE,
      showVariances: true,
    }));
  }, [setPageDataState]);

  const handleCompareMarkup = useCallback(() => {
    setPageDataState((prevState) => ({
      ...prevState,
      showValues: ALL_VALUES_TYPES.MARKUP,
      showVariances: true,
    }));
  }, [setPageDataState]);

  const handleCompareAnnualSalary = useCallback(() => {
    setPageDataState((prevState) => ({
      ...prevState,
      showValues: ALL_VALUES_TYPES.ANNUAL_SALARY,
      showVariances: true,
    }));
  }, [setPageDataState]);

  const handleShowMarketBillRates = useCallback(() => {
    setPageDataState((prevState) => ({
      ...prevState,
      showValues: ALL_VALUES_TYPES.BILL_RATE,
      showVariances: false,
    }));
  }, [setPageDataState]);

  const handleShowMarketPayRates = useCallback(() => {
    setPageDataState((prevState) => ({
      ...prevState,
      showValues: ALL_VALUES_TYPES.PAY_RATE,
      showVariances: false,
    }));
  }, [setPageDataState]);

  const handleShowMarketMarkup = useCallback(() => {
    setPageDataState((prevState) => ({
      ...prevState,
      showValues: ALL_VALUES_TYPES.MARKUP,
      showVariances: false,
    }));
  }, [setPageDataState]);

  const handleShowMarketAnnualSalary = useCallback(() => {
    setPageDataState((prevState) => ({
      ...prevState,
      showValues: ALL_VALUES_TYPES.ANNUAL_SALARY,
      showVariances: false,
    }));
  }, [setPageDataState]);

  const handleShowAllRates = useCallback(() => {
    setPageDataState((prevState) => ({
      ...prevState,
      showValues: null,
      showVariances: false,
    }));
  }, [setPageDataState]);

  const handleExpandAll = useCallback(() => {
    setPageDataState((prevState) => ({
      ...prevState,
      showLevels: ALL_SKILLS_LEVELS_SET,
    }));
  }, [setPageDataState]);

  const handleCollapseAll = useCallback(() => {
    setPageDataState((prevState) => ({
      ...prevState,
      showLevels: emptySet,
    }));
  }, [setPageDataState]);

  const handleToggleTable = useCallback(
    (expLevel: SKILLS_LEVELS_TYPE) => {
      if (expLevel != null) {
        setPageDataState((prevState) => ({
          ...prevState,
          showLevels: prevState.showLevels.includes(expLevel)
            ? prevState.showLevels.delete(expLevel)
            : prevState.showLevels.add(expLevel),
        }));
      }
    },
    [setPageDataState]
  );

  const handleMarketLevelChange = useCallback(
    async (value: { id: SKILLS_LEVELS_TYPE }) => {
      const currentLevel = contractorData.get("market_analysis")?.get("market_level");
      const requiredLevel = value && value["id"];

      if (requiredLevel !== currentLevel) {
        showLoader();
        closeConfirmationModal();
        await fetchMarketAnalysisData({ set_to_level: requiredLevel });
        await hideLoader();
      }
    },
    [
      contractorData,
      fetchMarketAnalysisData,
      showLoader,
      hideLoader,
      closeConfirmationModal,
    ]
  );

  const handleBindToAnotherMarketLevel = useCallback(() => {
    const currentLevel = contractorData.get("market_analysis")?.get("market_level");
    const currentLevelSelectValue =
      currentLevel != null
        ? {
            id: currentLevel,
            title: `Level ${SKILLS_LEVELS_ROMAN_LABELS[currentLevel]}`,
          }
        : null;
    const header = "Change Market Experience Level";
    const message = (
      <Box css={{ width: "500px", height: "200px" }}>
        <label>Select required level:</label>
        <ExpLevelSelect
          value={currentLevelSelectValue}
          onChange={handleMarketLevelChange}
        />
      </Box>
    );
    const footer = (
      <ButtonGroupRight fill>
        <Button size="large" onClick={closeConfirmationModal}>
          Cancel
        </Button>
      </ButtonGroupRight>
    );

    showConfirmationModal(message, header, footer);
  }, [
    contractorData,
    handleMarketLevelChange,
    showConfirmationModal,
    closeConfirmationModal,
  ]);

  const handleResetBackToMarketLevel = useCallback(async () => {
    showLoader();
    await fetchMarketAnalysisData({ reset_to_market_level: true });
    await hideLoader();
  }, [fetchMarketAnalysisData, showLoader, hideLoader]);

  let marketDataBlock: React.ReactNode = null;

  if (searchResults.size === 0) {
    marketDataBlock = (
      <Box css={{ padding: "$6" }}>
        <Alert color="warning">
          <h5>No market data found.</h5>
        </Alert>
      </Box>
    );
  } else {
    const { showValues, showVariances, showLevels } = pageDataState;

    marketDataBlock = searchResults.map((result) => {
      const resultLevel = result.get("level");
      return (
        <MarketRatesTable
          key={resultLevel}
          contractorData={contractorData}
          searchData={result}
          currencies={currenciesData}
          displayCurrencyCode={displayCurrencyCode}
          showValues={showValues}
          showVariances={showVariances}
          expanded={showLevels.includes(resultLevel)}
          onToggle={handleToggleTable}
        />
      );
    });
  }

  const yourDataBlock = (
    <Stack css={{ alignItems: "flex-start", justifyContent: "stretch", gap: 0 }}>
      <Box>
        <h4>Job Title:</h4>
        <Paragraph big>{contractorJobTitle}</Paragraph>
      </Box>

      <Box>
        <h4>Job Description:</h4>
        <Paragraph>
          <Description text={contractorJobDescription} charLimit={500} />
        </Paragraph>
      </Box>

      {!contractorRegion && (
        <Box>
          <CardHeaderTitle as="h4">Location:</CardHeaderTitle>
          <Paragraph big>{contractorLocation}</Paragraph>
        </Box>
      )}

      {contractorRegion && (
        <Box>
          <CardHeaderTitle as="h4">Region:</CardHeaderTitle>
          <Paragraph big>{contractorRegion}</Paragraph>
        </Box>
      )}

      <Stack
        fill
        css={{
          alignItems: "stretch",
          gap: "$3",

          [`& ${Grid}`]: {
            gridTemplateColumns: "$full",
            gridRowGap: 0,

            "@lg": {
              gridTemplateColumns: "$auto $1-of-2",
              gridColumnGap: 0,
            },
          },
        }}
      >
        <h4>Rates:</h4>
        {!hasAnyRatesData && (
          <Alert color="warning">
            <h5>No rates data found.</h5>
          </Alert>
        )}

        {!hasAnnualRatesData && contractorBillRate != null && (
          <StatsTableCell
            value={contractorBillRate}
            title="Bill Rate"
            formatter={decimalFormatter}
          />
        )}
        {!hasAnnualRatesData && contractorPayRate != null && (
          <StatsTableCell
            value={contractorPayRate}
            title="Pay Rate"
            formatter={decimalFormatter}
          />
        )}
        {!hasAnnualRatesData && contractorMarkup != null && (
          <StatsTableCell
            value={contractorMarkup}
            title="Markup"
            formatter={percentFormatter}
          />
        )}
        {hasAnnualRatesData && contractorAnnualSalary != null && (
          <StatsTableCell
            value={contractorAnnualSalary}
            title="Annual Salary"
            formatter={decimalFormatter}
          />
        )}
      </Stack>
    </Stack>
  );

  const actionsBlock = (
    <Stack css={{ alignItems: "flex-start", gap: "$2" }}>
      {hasAnyRatesData && (
        <Box>
          <h5>Compare To Market:</h5>
          {!hasAnnualRatesData && contractorBillRate != null && (
            <ActionLink onClick={handleCompareBillRate}>
              Compare your Bill Rate to Market
            </ActionLink>
          )}
          {!hasAnnualRatesData && contractorPayRate != null && (
            <ActionLink onClick={handleComparePayRate}>
              Compare your Pay Rate to Market
            </ActionLink>
          )}
          {!hasAnnualRatesData && contractorMarkup != null && (
            <ActionLink onClick={handleCompareMarkup}>
              Compare your Markup to Market
            </ActionLink>
          )}
          {hasAnnualRatesData && contractorAnnualSalary != null && (
            <ActionLink onClick={handleCompareAnnualSalary}>
              Compare your Salary to Market
            </ActionLink>
          )}
        </Box>
      )}
      <Box>
        <h5>Ready to Negotiate?</h5>
        {!hasAnnualRatesData && (
          <ActionLink onClick={handleShowMarketBillRates}>
            See Market Bill Rates
          </ActionLink>
        )}
        {!hasAnnualRatesData && (
          <ActionLink onClick={handleShowMarketPayRates}>See Market Pay Rates</ActionLink>
        )}
        {!hasAnnualRatesData && (
          <ActionLink onClick={handleShowMarketMarkup}>See Market Markups</ActionLink>
        )}
        {!hasAnnualRatesData && (
          <ActionLink onClick={handleShowAllRates}>See All Market Data</ActionLink>
        )}
        {hasAnnualRatesData && (
          <ActionLink onClick={handleShowMarketAnnualSalary}>
            See Market Salary Values
          </ActionLink>
        )}
      </Box>
      {!isPreviewMode && (
        <Box>
          <h5>Edit This Job:</h5>
          <ActionLink onClick={handleBindToAnotherMarketLevel}>
            Change Market Exp. Level
          </ActionLink>
          {isLevelFixedManually && (
            <ActionLink onClick={handleResetBackToMarketLevel}>
              Reset to Market Exp. Level
            </ActionLink>
          )}
        </Box>
      )}
    </Stack>
  );

  return (
    <Stack
      fill
      css={{
        gap: "$6",
        "@lg": {
          flexDirection: "row",
        },
      }}
    >
      <Box fill css={{ "@lg": { width: "$1-of-2", alignSelf: "flex-start" } }}>
        <Card>
          <CardActions>
            <CardActionsLeft>
              <CardHeaderTitle as="h4">Your Data</CardHeaderTitle>
            </CardActionsLeft>
          </CardActions>
          <CardBody>{yourDataBlock}</CardBody>
        </Card>

        {searchResults.size > 0 && (
          <Card css={{ marginTop: "$6", display: "none", "@lg": { display: "block" } }}>
            <CardActions>
              <CardActionsRight>
                <CardHeaderTitle as="h4">Actions</CardHeaderTitle>
              </CardActionsRight>
            </CardActions>
            <CardBody>{actionsBlock}</CardBody>
          </Card>
        )}
      </Box>

      <Card fill css={{ alignSelf: "flex-start" }}>
        <CardActions>
          <CardActionsLeft>
            <CardHeaderTitle as="h4">Market Data</CardHeaderTitle>
          </CardActionsLeft>
          <CardActionsRight>
            <Button
              color="accent"
              variant="outlined"
              css={{ padding: "0 $2" }}
              onClick={handleExpandAll}
            >
              Expand All
            </Button>
            <Button
              color="accent"
              variant="outlined"
              css={{ padding: "0 $2" }}
              onClick={handleCollapseAll}
            >
              Collapse All
            </Button>
          </CardActionsRight>
        </CardActions>
        <CardBody css={{ padding: 0 }}>{marketDataBlock}</CardBody>
      </Card>

      {searchResults.size > 0 && (
        <Card fill css={{ display: "block", "@lg": { display: "none" } }}>
          <CardActions>
            <CardActionsRight>
              <CardHeaderTitle as="h4">Actions</CardHeaderTitle>
            </CardActionsRight>
          </CardActions>
          <CardBody>{actionsBlock}</CardBody>
        </Card>
      )}
    </Stack>
  );
};

MainContentBlock.displayName = "MainContentBlock";

const getSearchResults = (
  contractorDataMap: ContractorDataMap
): MarketSearchResultsList => {
  let resultsList = emptyList;

  ALL_SKILLS_LEVELS_ARRAY.forEach((levelId) => {
    const levelSubkey = SKILLS_LEVELS_KEYS[levelId];
    let resultItem = (emptyMap as MarketSearchResultMap).set("level", levelId);

    ALL_CONTINGENT_RATE_VALUE_TYPES.forEach((rateSubkey) => {
      ALL_RATE_VALUE_SUBTYPES.forEach((valueSubkey) => {
        const sourceKey = [levelSubkey, rateSubkey, valueSubkey].join(
          "_"
        ) as keyof AllMarketRatesObject;
        const targetKey = [rateSubkey, valueSubkey].join(
          "_"
        ) as keyof MarketSearchRatesDataObject;

        resultItem = resultItem.set(
          targetKey,
          contractorDataMap.get("market_analysis")?.get(sourceKey)!
        );
      });
    });

    resultsList = resultsList.push(resultItem);
  });

  return resultsList;
};

type MarketAnalysisDataProvider = (queryArgs?: UrlQueryObject) => Promise<void>;

interface MarketSearchResultsProps extends Omit<CommonProgramChildPageProps, "params"> {
  params: CommonProgramChildPageProps["params"] & { contractorId: string };
}

const MarketSearchResults = (props: MarketSearchResultsProps) => {
  const { programId, params, fetchM8API, showModalError, router } = props;

  const contractorId = parseInt("" + params.contractorId, 10);

  // state

  const [contractorData, setContractorData] = useState<ContractorDataMap>(
    emptyMap as ContractorDataMap
  );
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<MarketSearchResultsList>(
    emptyList as MarketSearchResultsList
  );

  // utils

  const fetchMarketAnalysisData: MarketAnalysisDataProvider = useCallback(
    async (queryArgs = {}) => {
      try {
        const response: FetchAPIResponse<ContractorDataObject> = await fetchM8API(
          `programs/${programId}/contractors/${contractorId}/market_analysis/`,
          {
            params: { ...queryArgs },
          }
        );
        const newContractorData: ContractorDataMap = contractorItemToImmutableMap(
          response.data
        );
        const newSearchResultsList: MarketSearchResultsList =
          getSearchResults(newContractorData);

        setContractorData(newContractorData);
        setSearchResults(newSearchResultsList);
        setIsDataLoaded(true);
      } catch (err: any) {
        logAsyncOperationError("runMarketSearch", err);
        showModalError("Error occurred while running market search.");
      }
    },
    [
      contractorId,
      fetchM8API,
      programId,
      showModalError,
      setContractorData,
      setSearchResults,
      setIsDataLoaded,
    ]
  );

  // initial data load
  useEffect(() => {
    fetchMarketAnalysisData();
  }, [fetchMarketAnalysisData]);

  // handlers
  const handleBackToPreviousPage = useCallback(() => router.goBack(), [router]);

  if (!isDataLoaded) {
    return <TickerPageLoader />;
  } else if (!contractorData || !contractorData.size) {
    return <NoContractorDataFound onBackToPreviousPage={handleBackToPreviousPage} />;
  }

  return (
    <Stack>
      <PageHeadBlock
        contractorId={contractorId}
        hasDataToExport={!!searchResults.size}
        onBackToPreviousPage={handleBackToPreviousPage}
      />
      <MainContentBlock
        contractorData={contractorData}
        fetchMarketAnalysisData={fetchMarketAnalysisData}
        searchResults={searchResults}
      />
    </Stack>
  );
};

MarketSearchResults.displayName = "MarketSearchResults";

export default MarketSearchResults;
