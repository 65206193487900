import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import CheckBox from "../../shared/CheckBox";
import {
  CardListItem,
  CardListItemLabel,
  CardListSubText,
  SubTextAccent,
} from "../../../components/lib/Card";

const PunchoutsListItem = observer(
  class extends Component {
    static contextTypes = {
      router: PropTypes.object,
    };

    render() {
      const props = this.props;
      const punchout = props.punchout;
      const editing = punchout.viewState.editing;
      const selected = punchout.viewState.selected;
      const name = punchout.name;
      const createdDisplay = punchout.createdDate.format("MMMM D, YYYY");
      let ownerName = "You";
      let label = punchout.jobTitle;
      let onItemClick = this.props.onClick || this.handleViewDetail;
      let editingSection = null;

      if (editing) {
        onItemClick = punchout.toggleSelected;
        editingSection = (
          <CheckBox
            type={props.store.allowMultipleItemSelection ? "checkbox" : "radio"}
            name="list-item-select"
            value={name}
            selected={selected}
            style={{ width: 34 }}
            onClick={onItemClick}
          />
        );
      }

      return (
        <CardListItem
          selected={selected}
          onClick={onItemClick}
          css={{ display: "flex", flexWrap: "wrap" }}
          role="listitem"
        >
          <div className="ratecard-list-item-left ellipsis">
            {editingSection}
            <div className="ratecard-label-container ellipsis">
              <CardListItemLabel>{name}</CardListItemLabel>
              <CardListSubText>
                Created by <SubTextAccent>{ownerName}</SubTextAccent> on{" "}
                <SubTextAccent>{createdDisplay}</SubTextAccent>
              </CardListSubText>
            </div>
          </div>
          <div className="ratecard-list-item-right">
            {
              <div className="rc-center ">
                <div className="ratecard-info text-center">
                  <header>Job Title</header>
                  <div className="text-center">{label}</div>
                </div>
              </div>
            }
          </div>
        </CardListItem>
      );
    }
  }
);

PunchoutsListItem.propTypes = {
  store: PropTypes.object.isRequired,
  rateCard: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default PunchoutsListItem;
