import React from "react";

import { TickerPageLoader } from "../../../../components/lib/TickerLoader";
import AlertWithButton from "../../../../components/AlertWithButton";
import { emptyMap } from "../../../../constants";
import { baseUrlPath } from "../../constants";

import type { CommonChildPageProps } from "../../Val5KPublicEntry";
import type { AttemptDataMap, AttemptDataObject, ReviewDataMap } from "../../types";
import { AttemptDataContextProvider } from "../../context/AttemptDataContext";
import {
  UpdateAttemptDataFunc,
  useFetchOrCreateAttemptAPI,
  useFetchReviewAPI,
  useUpdateAttemptAPI,
} from "../../hooks";
import {
  ReviewDataContext,
  getReviewDataContextValues,
} from "../../../validator5K_admin/context/ReviewDataContext";

type SurveyItemEntryProps = CommonChildPageProps & {
  params: {
    reviewId: string;
  };
  children: React.ReactElement;
};

const SurveyItemEntry = (props: SurveyItemEntryProps) => {
  const { router, auth, params, children } = props;
  const { firstName, lastName, email } = auth || {};
  const reviewId = params.reviewId;

  // survey data state

  const [{ reviewData, reviewDataLoaded }, setReviewDataState] = React.useState({
    reviewData: emptyMap as ReviewDataMap,
    reviewDataLoaded: false,
  });
  const resetReviewDataState = React.useCallback(() => {
    setReviewDataState({
      reviewData: emptyMap as ReviewDataMap,
      reviewDataLoaded: false,
    });
  }, [setReviewDataState]);

  // validation attempt data state

  const [{ attemptData, attemptDataLoaded }, setAttemptDataState] = React.useState({
    attemptData: emptyMap as AttemptDataMap,
    attemptDataLoaded: false,
  });
  const resetAttemptDataState = React.useCallback(() => {
    setAttemptDataState({
      attemptData: emptyMap as AttemptDataMap,
      attemptDataLoaded: false,
    });
  }, []);
  const updateAttemptDataState = React.useCallback((data: AttemptDataMap) => {
    setAttemptDataState((prevState) => ({
      ...prevState,
      attemptData: data,
    }));
  }, []);

  const allDataLoaded = reviewDataLoaded && attemptDataLoaded;

  // data fetch methods

  const callFetchReviewAPI = useFetchReviewAPI();
  const callUpdateAttemptAPI = useUpdateAttemptAPI();
  const callFetchOrCreateAttemptAPI = useFetchOrCreateAttemptAPI();

  const fetchReviewData = React.useCallback(async () => {
    const data = await callFetchReviewAPI(reviewId);
    if (data) {
      setReviewDataState({
        reviewData: data,
        reviewDataLoaded: true,
      });
    }
    return data;
  }, [reviewId, callFetchReviewAPI]);

  const fetchOrCreateAttemptData = React.useCallback(async () => {
    if (firstName && lastName && email) {
      const payload = {
        review_id: reviewId,
        first_name: firstName,
        last_name: lastName,
        email: email,
      };
      const data = await callFetchOrCreateAttemptAPI(payload);
      if (data) {
        setAttemptDataState({
          attemptData: data,
          attemptDataLoaded: true,
        });
      }
      return data;
    }
  }, [reviewId, firstName, lastName, email, callFetchOrCreateAttemptAPI]);

  const updateAttemptData: UpdateAttemptDataFunc = React.useCallback(
    async (attemptId: number, payload: Partial<AttemptDataObject>) => {
      const data = await callUpdateAttemptAPI(attemptId, payload);
      if (data) updateAttemptDataState(data);
      return data;
    },
    [callUpdateAttemptAPI, updateAttemptDataState]
  );

  // handlers

  const handleBackToList = React.useCallback(
    () => router.push(`${baseUrlPath}/surveys`),
    [router]
  );

  // effects

  React.useEffect(() => {
    fetchReviewData();
    fetchOrCreateAttemptData();
    return () => {
      resetReviewDataState();
      resetAttemptDataState();
    };
  }, [
    fetchReviewData,
    fetchOrCreateAttemptData,
    resetReviewDataState,
    resetAttemptDataState,
  ]);

  // context

  const reviewDataContextValues = React.useMemo(
    () => getReviewDataContextValues(reviewData, fetchReviewData),
    [reviewData, fetchReviewData]
  );

  // render

  if (!allDataLoaded) {
    return <TickerPageLoader />;
  }

  if (!reviewData.size) {
    return (
      <AlertWithButton
        head="Requested survey data is not found."
        onButtonClick={handleBackToList}
      />
    );
  }

  if (!reviewData.get("jobs")?.first()?.size) {
    return (
      <AlertWithButton
        head="Survey has no data for validation."
        onButtonClick={handleBackToList}
      />
    );
  }

  if (!attemptData.size) {
    return (
      <AlertWithButton
        head="Can't load validation attempt data."
        onButtonClick={handleBackToList}
      />
    );
  }

  return (
    <ReviewDataContext.Provider value={reviewDataContextValues}>
      <AttemptDataContextProvider
        attemptData={attemptData}
        fetchAttemptData={fetchOrCreateAttemptData} // re-fetch attempt data and save to the app state
        updateAttemptData={updateAttemptData} // update attempt data on the server and save it to the app state
        updateAttemptDataState={updateAttemptDataState} // update app state only
      >
        {children}
      </AttemptDataContextProvider>
    </ReviewDataContext.Provider>
  );
};

SurveyItemEntry.displayName = "SurveyItemEntry";

export default SurveyItemEntry;
