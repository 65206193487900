import React, { MouseEventHandler } from "react";

import Icon from "../../../components/lib/Icon";
import Box from "../../../components/lib/Box";
import Stack from "../../../components/lib/Stack";
import Grid from "../../../components/lib/Grid";
import Inline from "../../../components/lib/Inline";
import Center from "../../../components/lib/Center";
import Text from "../../../components/lib/Text";
import { TextOverflow } from "../../../components/lib/Text";

import Tooltip from "../lib/Tooltip";
import { Button } from "../../../components/lib/Button";

import DiscoverySearchResultCounter from "./DiscoverySearchResultCounter";

import type { DiscoverySearchResultMap } from "../types";

interface DiscoverySearchResultProps {
  programName: string | null;
  result: DiscoverySearchResultMap;
  onExplore: MouseEventHandler<HTMLButtonElement>;
}

const DiscoverySearchResult = (props: DiscoverySearchResultProps) => {
  const { result, programName, onExplore } = props;

  const collectionPublicTitle =
    result.get("job_collection_public_title") || result.get("first_label");
  const rowsCount = result.get("rows_count") ?? 0;
  const labelsCount = result.get("labels_count") ?? 0;
  const labelsString = result.get("labels_string") ?? "";
  const locationsCount = result.get("locations_count") ?? 0;
  const regionsCount = result.get("regions_count") ?? 0;
  const suppliersCount = result.get("suppliers_count") ?? 0;
  const managersCount = result.get("managers_count") ?? 0;

  const libraryTitleValue =
    collectionPublicTitle && collectionPublicTitle.length >= 26 ? (
      <Tooltip side="top" content={collectionPublicTitle}>
        <TextOverflow underline bold centered>
          {collectionPublicTitle}
        </TextOverflow>
      </Tooltip>
    ) : (
      <TextOverflow underline bold centered>
        {collectionPublicTitle}
      </TextOverflow>
    );

  const clientTitlesValue =
    labelsString && labelsString.length >= 30 ? (
      <Tooltip
        side="top"
        content={
          <Text centered as={Box} css={{ maxWidth: "200px" }}>
            {labelsString}
          </Text>
        }
      >
        <TextOverflow bold>{labelsString}</TextOverflow>
      </Tooltip>
    ) : (
      <TextOverflow bold>{labelsString}</TextOverflow>
    );

  return (
    <Stack
      css={{
        padding: "$4",
        border: "1px solid $primaryLight",
        backgroundColor: "$primaryLighter",
        alignItems: "stretch",
      }}
    >
      <Box css={{ fontSize: "$sm" }}>PeopleTicker Library Title:</Box>
      <Center css={{ fontSize: "$lg" }}>{libraryTitleValue}</Center>

      <Box>
        <Box css={{ fontSize: "$sm" }}>Applies to following Job Labels:</Box>
        <Inline
          css={{
            fontSize: "$base",
            paddingLeft: "$3",
          }}
        >
          {clientTitlesValue}
        </Inline>
        {!!programName && (
          <Inline>
            <TextOverflow>
              Uploaded for <i>{programName}</i>
            </TextOverflow>
          </Inline>
        )}
      </Box>

      <Center>
        <Button
          css={{
            width: "$full",
            fontStyle: "italic",
            fontSize: "$lg",
          }}
          onClick={onExplore}
        >
          Explore
        </Button>
      </Center>

      <Grid
        css={{
          gridTemplateColumns: "repeat(3, minmax(0, 0.2fr))",
          border: "1px solid $secondaryLight",
          color: "$secondary",
          gap: "$1_5 $4",
          justifyContent: "center",
          justifyItems: "stretch",
          padding: "$1",
        }}
      >
        <DiscoverySearchResultCounter
          counter={rowsCount}
          icon={<Icon icon="list-ul" />}
          label="Rows"
        />
        <DiscoverySearchResultCounter
          counter={labelsCount}
          icon={<Icon icon="list-alt" />}
          label="Labels"
        />
        <DiscoverySearchResultCounter
          counter={locationsCount}
          icon={<Icon icon="map-marker-alt" />}
          label="Locations"
        />
        <DiscoverySearchResultCounter
          counter={regionsCount}
          icon={<Icon icon="globe-americas" />}
          label="Regions"
        />
        <DiscoverySearchResultCounter
          counter={suppliersCount}
          icon={<Icon icon="briefcase" />}
          label="Suppliers"
        />
        <DiscoverySearchResultCounter
          counter={managersCount}
          icon={<Icon icon="users" />}
          label="Managers"
        />
      </Grid>
    </Stack>
  );
};

DiscoverySearchResult.displayName = "DiscoverySearchResult";
DiscoverySearchResult.defaultProps = {
  programName: null,
};

export default DiscoverySearchResult;
