/**
 * @generated SignedSource<<d7593e26b144e481eea3807914833fab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Tag_tag$data = {
  readonly created: string;
  readonly id: string;
  readonly name: string;
  readonly owner: {
    readonly client: {
      readonly clientId: number | null;
      readonly name: string;
    } | null;
    readonly userId: number;
    readonly username: string;
  };
  readonly tagId: number;
  readonly " $fragmentType": "Tag_tag";
};
export type Tag_tag$key = {
  readonly " $data"?: Tag_tag$data;
  readonly " $fragmentSpreads": FragmentRefs<"Tag_tag">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Tag_tag",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tagId",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LegacyUserNode",
      "kind": "LinkedField",
      "name": "owner",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ClientNode",
          "kind": "LinkedField",
          "name": "client",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "clientId",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TagNode",
  "abstractKey": null
};
})();

(node as any).hash = "ec05796740df207a5c65cf78f98b48af";

export default node;
