// @flow

import { extendObservable, action } from "mobx";
import type { FetchGraphQL, FetchAPI } from "../../App";
import { Environment } from "relay-runtime";

import DashboardStore from "./DashboardStore";
import RateCardListStore from "./RateCardListStore";
import RateCardCreateStore from "./RateCardCreateStore";
import RateCardDetailStore from "./RateCardDetailStore";
import SearchDetailStore from "./SearchDetailStore";
import IndustriesStore from "./IndustriesStore";
import RateSearchStore from "./RateSearchStore";
import IndexStore from "./IndexStore";
import JobLabelListStore from "./JobLabelListStore";
import SavedSearchesListStore from "./SavedSearchesListStore";
import SavedSearchesDetailStore from "./SavedSearchesDetailStore";
import DraftsSavedSearchListStore from "./DraftsSavedSearchListStore";
import ProjectCostEstimateStore from "./ProjectCostEstimateStore";
import ProjectCostEstimateCreateStore from "./ProjectCostEstimateCreateStore";
import ProjectCostEstimateDetailStore from "./ProjectCostEstimateDetailStore";
import RegionListStore from "./RegionListStore";
import RegionCreateStore from "./RegionCreateStore";
import LocationListStore from "./LocationListStore";
import ScheduledSearchCreateStore from "./ScheduledSearchCreateStore";
import NotificationsListStore from "./NotificationsListStore";
import PunchoutsListStore from "./PunchoutsListStore";
import NegotiationWorksheetListStore from "./NegotiationWorksheetListStore";
import NegotiationWorksheetCreateStore from "./NegotiationWorksheetCreateStore";
import NegotiationWorksheetDetailStore from "./NegotiationWorksheetDetailStore";
import TagManagementListStore from "./TagManagementListStore";
import TagManagementDetailStore from "./TagManagementDetailStore";
import ContentListStore from "./ContentListStore";
import DashboardPunchoutsListStore from "./DashboardPunchoutsListStore";
import JobLabelDetailStore from "./JobLabelDetailStore";
import AccountDetailStore from "./AccountDetailStore";

//import pt_admin stores
import RegionListPTStore from "./pt_admin_store/RegionListStore";
import LocationListPTStore from "./pt_admin_store/LocationListStore";
import ProjectCostEstimatePTStore from "./pt_admin_store/ProjectCostEstimateStore";
import ProjectCostEstimateDetailPTStore from "./pt_admin_store/ProjectCostEstimateDetailStore";
import TagManagementListPTStore from "./pt_admin_store/TagManagementListStore";
import TagManagementDetailPTStore from "./pt_admin_store/TagManagementDetailStore";
import ContentListPTStore from "./pt_admin_store/ContentListStore";
import JobLabelListAdminStore from "./pt_admin_store/JobLabelListAdminStore";
import JobLabelDetailAdminStore from "./pt_admin_store/JobLabelDetailAdminStore";
import ScheduledSearchListPTStore from "./pt_admin_store/ScheduledSearchListStore";
import ScheduledSearchDetailPTStore from "./pt_admin_store/ScheduledSearchDetailStore";
import RateCardListPTStore from "./pt_admin_store/RateCardListStore";
import RateCardDetailPTStore from "./pt_admin_store/RateCardDetailStore";
import SavedSearchesListPTStore from "./pt_admin_store/SavedSearchesListStore";
import SavedSearchesDetailPTStore from "./pt_admin_store/SavedSearchesDetailPTStore";
import NegotiationWorksheetListPTStore from "./pt_admin_store/NegotiationWorksheetListStore";
import NegotiationWorksheetDetailPTStore from "./pt_admin_store/NegotiationWorksheetDetailStore";
import NegotiationWorksheetCreatePTStore from "./pt_admin_store/NegotiationWorksheetCreateStore";
import ClientListPTStore from "./pt_admin_store/ClientListStore";
import ClientDetailPTStore from "./pt_admin_store/ClientDetailStore";
import ClientCreatePTStore from "./pt_admin_store/ClientCreateStore";
import UserListPTStore from "./pt_admin_store/UserListStore";
import UserDetailPTStore from "./pt_admin_store/UserDetailStore";
import PunchoutsListPTStore from "./pt_admin_store/PunchoutsListStore";
import UserCreatePTStore from "./pt_admin_store/UserCreatePTStore";
import UserManagementListPTStore from "./pt_admin_store/UserManagementListStore";
import ReportStore from "./pt_admin_store/ReportStore";

import JobLibraryListStore from "./JobLibraryListStore";
import JobLibraryTitleListStore from "./JobLibraryTitleListStore";
import JobLibraryEditCustomStore from "./JobLibraryEditCustomStore";
import JobLibraryRequestCountryStore from "./JobLibraryRequestCountryStore";
import JobLibraryTitleRequestCreateStore from "./JobLibraryTitleRequestCreateStore";
import JobLibraryTitleRequestListStore from "./JobLibraryTitleRequestListStore";
import JobLibraryRequestListStore from "./JobLibraryRequestListStore";
import JobLibraryCreateCustomStore from "./JobLibraryCreateCustomStore";
import JobLibraryRequestCreateStore from "./JobLibraryRequestCreateStore";
import JobLibraryTitleRequestCountryCreateStore from "./JobLibraryTitleRequestCountryCreateStore";
import { BatchSearchJLListStore, BatchSearchPSPListStore } from "./BatchSearchListStore";
import {
  BatchSearchJLCreateStore,
  BatchSearchPSPCreateStore,
} from "./BatchSearchCreateStore";
import {
  BatchSearchJLDetailStore,
  BatchSearchPSPDetailStore,
} from "./BatchSearchDetailStore";
import GlobalStateStore from "./GlobalStateStore";

export default class MobXStore {
  disableAnimations: boolean;
  globalStateStore: GlobalStateStore;
  dashboardStore: DashboardStore;
  rateCardListStore: RateCardListStore;
  rateCardCreateStore: RateCardCreateStore;
  rateCardDetailStore: RateCardDetailStore;
  searchDetailStore: SearchDetailStore;
  industriesStore: IndustriesStore;
  rateSearchStore: RateSearchStore;
  // Job Library
  jobLibraryListStore: JobLibraryListStore;
  jobLibraryCreateCustomStore: JobLibraryCreateCustomStore;
  jobLibraryEditCustomStore: JobLibraryEditCustomStore;
  jobLibraryRequestListStore: JobLibraryRequestListStore;
  jobLibraryTitleListStore: JobLibraryTitleListStore;
  jobLibraryTitleRequestListStore: JobLibraryTitleRequestListStore;
  jobLibraryTitleRequestCreateStore: JobLibraryTitleRequestCreateStore;
  jobLibraryRequestCountryStore: JobLibraryRequestCountryStore;
  jobLibraryRequestCreateStore: JobLibraryRequestCreateStore;
  jobLibraryTitleRequestCountryCreateStore: JobLibraryTitleRequestCountryCreateStore;
  // Batch Search
  batchSearchJLListStore: BatchSearchJLListStore;
  batchSearchJLCreateStore: BatchSearchJLCreateStore;
  batchSearchJLDetailStore: BatchSearchJLDetailStore;
  batchSearchPSPListStore: BatchSearchPSPListStore;
  batchSearchPSPDetailStore: BatchSearchPSPDetailStore;
  batchSearchPSPCreateStore: BatchSearchPSPCreateStore;

  jobLabelListStore: JobLabelListStore;
  jobLabelDetailStore: JobLabelDetailStore;
  savedSearchesListStore: SavedSearchesListStore;
  savedSearchesDetailStore: SavedSearchesDetailStore;
  draftsSavedSearchListStore: DraftsSavedSearchListStore;
  projectCostEstimateStore: ProjectCostEstimateStore;
  projectCostEstimateCreateStore: ProjectCostEstimateCreateStore;
  projectCostEstimateDetailStore: ProjectCostEstimateDetailStore;
  indexStore: IndexStore;
  regionListStore: RegionListStore;
  regionCreateStore: RegionCreateStore;
  locationListStore: LocationListStore;
  notificationsListStore: NotificationsListStore;
  punchoutsListStore: PunchoutsListStore;
  negotiationWorksheetListStore: NegotiationWorksheetListStore;
  negotiationWorksheetCreateStore: NegotiationWorksheetCreateStore;
  negotiationWorksheetDetailStore: NegotiationWorksheetDetailStore;
  tagManagementListStore: TagManagementListStore;
  tagManagementDetailStore: TagManagementDetailStore;
  contentListStore: ContentListStore;
  dashboardPunchoutsListStore: DashboardPunchoutsListStore;
  accountDetailStore: AccountDetailStore;
  regionListPTStore: RegionListPTStore;
  locationListPTStore: LocationListPTStore;
  projectCostEstimatePTStore: ProjectCostEstimatePTStore;
  projectCostEstimateDetailPTStore: ProjectCostEstimateDetailPTStore;
  tagManagementListPTStore: TagManagementListPTStore;
  tagManagementDetailPTStore: TagManagementDetailPTStore;
  contentListPTStore: ContentListPTStore;
  jobLabelListAdminStore: JobLabelListAdminStore;
  jobLabelDetailAdminStore: JobLabelDetailAdminStore;
  scheduledSearchCreateStore: ScheduledSearchCreateStore;
  scheduleSearchListPTStore: ScheduledSearchListPTStore;
  scheduledSearchDetailPTStore: ScheduledSearchDetailPTStore;
  rateCardListPTStore: RateCardListPTStore;
  rateCardDetailPTStore: RateCardDetailPTStore;
  savedSearchesListPTStore: SavedSearchesListPTStore;
  savedSearchesDetailPTStore: SavedSearchesDetailPTStore;
  negotiationWorksheetListPTStore: NegotiationWorksheetListPTStore;
  negotiationWorksheetDetailPTStore: NegotiationWorksheetDetailPTStore;
  negotiationWorksheetCreatePTStore: NegotiationWorksheetCreatePTStore;
  clientListPTStore: ClientListPTStore;
  clientDetailPTStore: ClientDetailPTStore;
  userListPTStore: UserListPTStore;
  clientCreatePTStore: ClientCreatePTStore;
  UserDetailPTStore: UserDetailPTStore;
  punchoutsListPTStore: PunchoutsListPTStore;
  userCreatePTStore: UserCreatePTStore;
  userManagementListPTStore: UserManagementListPTStore;
  reportStore: ReportStore;

  toggleAnimations: () => void;
  apiServerUrl: string;
  legacySessionId: string | null;
  hasPerSearchPricing: boolean | null;
  hasThreeLevel: boolean;
  fetchGraphQL: FetchGraphQL;

  relayEnvironment: Environment;

  constructor(
    relayEnvironment: Environment,
    fetchGraphQL: FetchGraphQL,
    fetchAPI: FetchAPI,
    apiServerUrl: string = ""
  ) {
    this.relayEnvironment = relayEnvironment;

    // These variables need to updated on state changes from App.js
    extendObservable(this, {
      apiServerUrl,
      legacySessionId: null,
      hasPerSearchPricing: null,
      hasThreeLevel: false,
      disableAnimations: false,
    });

    this.toggleAnimations = action(this.toggleAnimations.bind(this));
    this.industriesStore = new IndustriesStore();

    this.dashboardStore = new DashboardStore(fetchGraphQL, this);
    this.rateCardListStore = new RateCardListStore(
      fetchGraphQL,
      fetchAPI,
      this.apiServerUrl
    );
    this.rateCardCreateStore = new RateCardCreateStore(fetchGraphQL, fetchAPI);
    this.rateCardDetailStore = new RateCardDetailStore(
      this.relayEnvironment,
      fetchGraphQL,
      fetchAPI,
      this.apiServerUrl,
      this
    );
    this.globalStateStore = new GlobalStateStore();
    this.searchDetailStore = new SearchDetailStore(
      fetchGraphQL,
      fetchAPI,
      this.hasThreeLevel,
      this
    );
    this.rateSearchStore = new RateSearchStore(fetchGraphQL, fetchAPI, this);
    // job library
    this.jobLibraryListStore = new JobLibraryListStore(fetchGraphQL, fetchAPI, this);
    this.jobLibraryTitleListStore = new JobLibraryTitleListStore(
      fetchGraphQL,
      fetchAPI,
      this
    );
    this.jobLibraryEditCustomStore = new JobLibraryEditCustomStore(
      fetchGraphQL,
      fetchAPI,
      this
    );
    this.jobLibraryCreateCustomStore = new JobLibraryCreateCustomStore(
      fetchGraphQL,
      fetchAPI,
      this
    );
    this.jobLibraryRequestCountryStore = new JobLibraryRequestCountryStore(
      fetchGraphQL,
      fetchAPI,
      this
    );
    this.jobLibraryTitleRequestCreateStore = new JobLibraryTitleRequestCreateStore(
      fetchGraphQL,
      fetchAPI,
      this
    );
    this.jobLibraryTitleRequestListStore = new JobLibraryTitleRequestListStore(
      fetchGraphQL,
      fetchAPI
    );
    this.jobLibraryRequestListStore = new JobLibraryRequestListStore(
      fetchGraphQL,
      fetchAPI,
      this
    );
    this.jobLibraryRequestCreateStore = new JobLibraryRequestCreateStore(
      fetchGraphQL,
      fetchAPI
    );
    this.jobLibraryTitleRequestCountryCreateStore =
      new JobLibraryTitleRequestCountryCreateStore(fetchGraphQL, fetchAPI, this);
    // Batch Search
    this.batchSearchJLListStore = new BatchSearchJLListStore(
      fetchGraphQL,
      fetchAPI,
      this
    );
    this.batchSearchJLCreateStore = new BatchSearchJLCreateStore(
      fetchGraphQL,
      fetchAPI,
      this
    );
    this.batchSearchJLDetailStore = new BatchSearchJLDetailStore(fetchGraphQL, fetchAPI);
    this.batchSearchPSPListStore = new BatchSearchPSPListStore(
      fetchGraphQL,
      fetchAPI,
      this
    );
    this.batchSearchPSPDetailStore = new BatchSearchPSPDetailStore(
      fetchGraphQL,
      fetchAPI,
      this
    );
    this.batchSearchPSPCreateStore = new BatchSearchPSPCreateStore(
      fetchGraphQL,
      fetchAPI,
      this
    );

    this.jobLabelListStore = new JobLabelListStore(fetchGraphQL, fetchAPI);
    this.jobLabelDetailStore = new JobLabelDetailStore(fetchGraphQL);
    this.savedSearchesListStore = new SavedSearchesListStore(
      fetchGraphQL,
      fetchAPI,
      this.apiServerUrl
    );
    this.savedSearchesDetailStore = new SavedSearchesDetailStore(
      fetchGraphQL,
      this.savedSearchesListStore
    );
    this.draftsSavedSearchListStore = new DraftsSavedSearchListStore(
      fetchGraphQL,
      fetchAPI
    );
    this.projectCostEstimateStore = new ProjectCostEstimateStore(fetchGraphQL, fetchAPI);
    this.projectCostEstimateCreateStore = new ProjectCostEstimateCreateStore(
      fetchGraphQL,
      fetchAPI,
      this.apiServerUrl
    );
    this.projectCostEstimateDetailStore = new ProjectCostEstimateDetailStore(
      fetchGraphQL,
      fetchAPI
    );
    this.indexStore = new IndexStore(fetchGraphQL);
    this.regionListStore = new RegionListStore(fetchGraphQL);
    this.regionCreateStore = new RegionCreateStore(fetchGraphQL);
    this.locationListStore = new LocationListStore(fetchGraphQL);
    this.notificationsListStore = new NotificationsListStore(fetchGraphQL, this);
    this.punchoutsListStore = new PunchoutsListStore(fetchGraphQL, fetchAPI, this);
    this.negotiationWorksheetListStore = new NegotiationWorksheetListStore(fetchGraphQL);
    this.negotiationWorksheetCreateStore = new NegotiationWorksheetCreateStore(
      fetchGraphQL,
      fetchAPI,
      this
    );
    this.negotiationWorksheetDetailStore = new NegotiationWorksheetDetailStore(
      fetchGraphQL,
      this
    );
    this.tagManagementListStore = new TagManagementListStore(fetchGraphQL);
    this.tagManagementDetailStore = new TagManagementDetailStore(fetchGraphQL, this);
    this.contentListStore = new ContentListStore(fetchGraphQL, this);
    this.dashboardPunchoutsListStore = new DashboardPunchoutsListStore(fetchGraphQL);
    this.accountDetailStore = new AccountDetailStore(fetchGraphQL);

    // TODO: remove this store
    this.scheduledSearchCreateStore = new ScheduledSearchCreateStore(fetchGraphQL, this);

    //pt-admin stores
    this.regionListPTStore = new RegionListPTStore(fetchGraphQL, fetchAPI);
    this.locationListPTStore = new LocationListPTStore(fetchGraphQL);
    this.projectCostEstimatePTStore = new ProjectCostEstimatePTStore(
      fetchGraphQL,
      fetchAPI
    );
    this.projectCostEstimateDetailPTStore = new ProjectCostEstimateDetailPTStore(
      fetchGraphQL,
      fetchAPI
    );
    this.tagManagementListPTStore = new TagManagementListPTStore(fetchGraphQL, fetchAPI);
    this.tagManagementDetailPTStore = new TagManagementDetailPTStore(fetchGraphQL, this);
    this.contentListPTStore = new ContentListPTStore(fetchGraphQL, this);
    this.jobLabelListAdminStore = new JobLabelListAdminStore(
      fetchGraphQL,
      fetchAPI,
      apiServerUrl
    );
    this.jobLabelDetailAdminStore = new JobLabelDetailAdminStore(fetchGraphQL);
    this.scheduleSearchListPTStore = new ScheduledSearchListPTStore(fetchGraphQL);
    this.scheduledSearchDetailPTStore = new ScheduledSearchDetailPTStore(
      fetchGraphQL,
      this
    );
    this.rateCardListPTStore = new RateCardListPTStore(fetchGraphQL, fetchAPI);
    this.rateCardDetailPTStore = new RateCardDetailPTStore(fetchGraphQL, fetchAPI, this);
    this.savedSearchesListPTStore = new SavedSearchesListPTStore(
      fetchGraphQL,
      fetchAPI,
      this
    );
    this.savedSearchesDetailPTStore = new SavedSearchesDetailPTStore(this);
    this.negotiationWorksheetListPTStore = new NegotiationWorksheetListPTStore(
      fetchGraphQL,
      fetchAPI
    );
    this.negotiationWorksheetDetailPTStore = new NegotiationWorksheetDetailPTStore(
      fetchGraphQL,
      this
    );
    this.negotiationWorksheetCreatePTStore = new NegotiationWorksheetCreatePTStore(
      fetchGraphQL,
      fetchAPI,
      this
    );
    this.clientListPTStore = new ClientListPTStore(fetchGraphQL, fetchAPI);
    this.clientDetailPTStore = new ClientDetailPTStore(fetchGraphQL);
    this.userListPTStore = new UserListPTStore(fetchGraphQL, fetchAPI);
    this.clientCreatePTStore = new ClientCreatePTStore(fetchGraphQL);
    this.UserDetailPTStore = new UserDetailPTStore(fetchGraphQL, this);
    this.punchoutsListPTStore = new PunchoutsListPTStore(fetchGraphQL, fetchAPI, this);
    this.userCreatePTStore = new UserCreatePTStore(fetchGraphQL);
    this.userManagementListPTStore = new UserManagementListPTStore(
      fetchGraphQL,
      fetchAPI
    );

    this.reportStore = new ReportStore(fetchAPI);
  }

  toggleAnimations() {
    this.disableAnimations = !this.disableAnimations;
  }
}
