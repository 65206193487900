// @flow

import R from "ramda";
import { extendObservable, action, runInAction, computed, observable } from "mobx";
import axios from "axios";
import moment from "moment";
import JobList from "../../models/JobList";
import CurrentUser from "../../models/User";
import { JobFilter, CreatedOnFilter, TagsFilter } from "../../models/FilterState";
import NetworkState from "../../models/NetworkState";
import PaginationState from "../../models/PaginationState";
import FilterObject, { FILTER_COLUMN } from "../../models/Filter";
import Sort, { SORT_DIRECTION } from "../../models/Sort";
import ModalState from "../../models/ModalState";
import { consolidateAppliedFilters, consolidateAppliedSorts } from "./SupportFunctions";
import type { PageQuery, PaginationInfo } from "../../models/PaginationState";
import type { FilterColumn } from "../../models/Filter";
import type { GraphQLQuery } from "../../models/GraphQL";
import ExportOptionsState from "../../models/ExportOptionsState";
import ExportOptions, { FILE_TYPE, EXPORT_TYPE } from "../../models/ExportOptions";
import ApplyTagState from "../../models/ApplyTagState";
import { browserHistory } from "react-router";
import MessageState from "../../models/MessageState";
import type { FetchGraphQL, FetchAPI } from "../../App";

function mapJobTitles(res) {
  var subtitlewithoutJobLabel = "(stock title)";
  var subtitleWithJobLabel = "(via My Job Labels)";

  function applyJobTitles(jobTitle) {
    if (jobTitle.isJobLabel) {
      jobTitle.title = jobTitle.title + " " + subtitleWithJobLabel;
    } else {
      jobTitle.title = jobTitle.title + " " + subtitlewithoutJobLabel;
    }
  }
  res.data.viewer.jobTitles.results.map(applyJobTitles);

  return res;
}

const tagsFilterCriteria = `
query getTags {
 viewer{
   tagsFilterCriteria{
     tagnames(contentType: JOB_LIBRARY){
       tag
     }
   }
 }
}
`;

export class JobLabelListComponentStore {
  network: NetworkState;
  pagination: PaginationState;

  jobsList: JobList[];
  jobsListViewState: Object;
  jobsListView: JobList[];
  totalCount: any;

  currentUser: CurrentUser;
  isEditing: ?boolean;
  allOnPageSelected: boolean;
  allSelected: boolean;
  allowMultipleItemSelection: boolean;

  joblabelValid: boolean;
  jobtitleValid: boolean;
  jobdescriptionValid: boolean;
  formValid: boolean;
  fileName: string;

  jobFilter: JobFilter;
  joblabelValid: boolean;
  jobtitleValid: boolean;
  jobdescriptionValid: boolean;
  formValid: boolean;

  createdOnFilter: CreatedOnFilter;
  tagsFilter: TagsFilter;

  defaultFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedSorts: {
    [key: FilterColumn]: Sort,
  };
  appliedSortsOrder: Array<FilterColumn>;
  isFiltered: boolean;
  isTagView: ?boolean;
  getJobsList: (PageQuery) => Promise<PaginationInfo>;
  applyDefaultFilter: (FilterColumn, FilterObject) => void;
  handleStartEdit: () => void;
  handleStopEdit: () => void;
  toggleSelectAllPage: (Object) => void;
  selectAllPage: (Event) => void;
  deselectAllPage: (Event) => void;
  clearAllSelections: () => void;
  getSelectedjobsList: () => Array<string>;
  getFirstSelectedJobs: () => Object;
  jobValidation: () => boolean;
  editJobTitle: () => void;
  getFilterCriteriaQuery: (FilterColumn) => GraphQLQuery;
  processFilterCriteria: (FilterColumn, Object) => Array<Object>;
  applyFilter: (FilterColumn, FilterObject) => void;
  removeFilter: (FilterColumn) => void;
  applySort: (FilterColumn, Sort) => void;
  removeSort: (FilterColumn) => void;
  filterjobsList: () => void;
  clearFilters: () => void;
  applyDefaultSort: () => void;
  applyTagState: ApplyTagState;
  setTagViewFn: () => void;
  resetFormValidation: () => void;
  selectAllOnPageItem: () => void;
  fileUploadInProgress: boolean;
  renameModal: ModalState;
  showRenameModal: () => void;
  renameTag: () => void;
  newJobName: string;
  onNewJobNameChange: () => void;
  messaging: MessageState;
  clearAllSelectionsOnTagView: () => void;
  toDateRange: () => void;
  exportUrl: string;
  fetchGraphQL: FetchGraphQL;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI) {
    this.fetchGraphQL = fetchGraphQL;
    // NOTE: Bound early to pass into pagination & filter state
    this.getJobsList = action(this.getJobsList.bind(this));

    this.getFilterCriteriaQuery = action(this.getFilterCriteriaQuery.bind(this));
    this.processFilterCriteria = action(this.processFilterCriteria.bind(this));
    this.applyFilter = action(this.applyFilter.bind(this));
    this.applySort = action(this.applySort.bind(this));
    this.removeFilter = action(this.removeFilter.bind(this));
    this.removeSort = action(this.removeSort.bind(this));
    this.jobValidation = action(this.jobValidation.bind(this));
    this.toDateRange = action(this.toDateRange.bind(this));
    this.editJobTitle = action(this.editJobTitle.bind(this));
    this.exportUrl = "job-labels/list/export/excel/";

    extendObservable(this, {
      network: new NetworkState(),

      pagination: new PaginationState(this.getJobsList),
      jobsList: [],
      totalCount: 0,
      jobsListViewState: observable.map({}),
      jobsListView: computed(() => {
        return this.jobsList.map((job) => {
          if (this.jobsListViewState.has(job.jobId)) {
            job.viewState = this.jobsListViewState.get(job.jobId);

            return job;
          }

          return job;
        });
      }),
      isEditing: null, // we start with null so some view elements be hidden initially
      isFiltered: false,
      allSelected: false,
      joblabelValid: true,
      jobtitleValid: true,
      jobdescriptionValid: true,
      formValid: false,
      fileName: "",
      allowMultipleItemSelection: true,
      selectedCount: computed(() => {
        const selectedValues = this.jobsListView.map((job) => job.viewState.selected);

        if (this.allSelected) {
          return this.pagination.totalCount;
        }

        let count = 0;

        selectedValues.forEach((v) => {
          if (v) {
            count += 1;
          }
        });

        return count;
      }),
      allOnPageSelected: computed(() => {
        const allTrue = R.all(R.equals(true));
        const selectedValues = this.jobsListView.map((job) => job.viewState.selected);

        if (selectedValues.length === 0) {
          return false;
        }

        return allTrue(selectedValues);
      }),
      defaultFilters: {},
      appliedFilters: {},
      appliedSorts: {},
      appliedSortsOrder: observable.shallow([]),
      jobFilter: new JobFilter(
        this,
        FILTER_COLUMN.JOB_LABEL,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      createdOnFilter: new CreatedOnFilter(
        this,
        FILTER_COLUMN.DATE_RANGE,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      applyTagState: new ApplyTagState(fetchGraphQL, this),
      tagsFilter: new TagsFilter(
        this,
        FILTER_COLUMN.TAGS,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      isTagView: null,
      fileUploadInProgress: false,
      renameModal: new ModalState(),
      newJobName: "",
      messaging: new MessageState(),
    });

    this.currentUser = null;

    this.applyDefaultFilter = action(this.applyDefaultFilter.bind(this));
    this.handleStartEdit = action(this.handleStartEdit.bind(this));
    this.handleStopEdit = action(this.handleStopEdit.bind(this));
    this.toggleSelectAllPage = action(this.toggleSelectAllPage.bind(this));
    this.selectAllPage = action(this.selectAllPage.bind(this));
    this.deselectAllPage = action(this.deselectAllPage.bind(this));
    this.clearAllSelections = action(this.clearAllSelections.bind(this));
    this.getSelectedjobsList = action(this.getSelectedjobsList.bind(this));
    this.getFirstSelectedJobs = action(this.getFirstSelectedJobs.bind(this));

    this.filterjobsList = action(this.filterjobsList.bind(this));
    this.clearFilters = action(this.clearFilters.bind(this));
    this.applyDefaultSort = action(this.applyDefaultSort.bind(this));
    this.setTagViewFn = action(this.setTagViewFn.bind(this));
    this.resetFormValidation = action(this.resetFormValidation.bind(this));
    this.selectAllOnPageItem = action(this.selectAllOnPageItem.bind(this));
    this.showRenameModal = action(this.showRenameModal.bind(this));
    this.onNewJobNameChange = action(this.onNewJobNameChange.bind(this));
    this.clearAllSelectionsOnTagView = action(
      this.clearAllSelectionsOnTagView.bind(this)
    );

    this.applyDefaultSort();
  }

  canExport() {
    const exportRole = "No Export Allowed";
    if (
      this.currentUser &&
      this.currentUser.roles &&
      this.currentUser.roles.indexOf(exportRole) > -1
    ) {
      return false;
    }
    return true;
  }

  showRenameModal() {
    const job = this.getFirstSelectedJobs();
    if (!job) return;
    this.newJobName = job.jobLabel;
    this.renameModal.showModal();
  }

  onNewJobNameChange(e) {
    this.newJobName = e.target.value;
  }

  setTagViewFn() {
    this.isTagView = true;
    this.isEditing = true;
  }

  applyDefaultSort() {
    this.createdOnFilter.sortState.direction = SORT_DIRECTION.DESC;
    this.createdOnFilter.sort = this.createdOnFilter.buildQuerySort();
    this.applySort(this.createdOnFilter.column, this.createdOnFilter.sort);
  }

  jobValidation(ignoreLabel = true) {
    if (ignoreLabel === true) {
      this.joblabelValid = true;
    } else {
      this.joblabelValid = !!this.joblabel;
    }
    this.jobtitleValid = !!this.jobtitle;
    this.jobdescriptionValid = !!this.jobdescription;

    this.formValid = this.joblabelValid && this.jobtitleValid && this.jobdescriptionValid;

    return this.formValid;
  }

  resetFormValidation() {
    this.joblabelValid = true;
    this.jobtitleValid = true;
    this.jobdescriptionValid = true;
    this.formValid = true;
  }

  handleStartEdit() {
    this.isEditing = true;
    this.jobsListView.forEach((job) => job.toggleEdit());
  }

  handleStopEdit() {
    this.isEditing = false;
    this.allSelected = false;
    this.jobsListViewState.forEach((viewState) => {
      viewState.selected = false;
      viewState.editing = false;
    });
  }

  toggleSelectAllPage(e: Object) {
    if (!this.allowMultipleItemSelection) return;

    const setValue = !this.allOnPageSelected;

    this.jobsListView.forEach((job) => {
      job.toggleSelected(e, null, null, setValue);
    });

    // When All items selected flag is up, clear selection
    if (setValue === false && this.allSelected) this.allSelected = false;
  }

  selectAllPage(e: Event) {
    this.allSelected = true;
  }

  selectAllOnPageItem(e: Event) {
    this.jobsListView.forEach((job) => {
      job.toggleSelected(e, null, null, true);
    });
  }

  deselectAllPage(e: Event) {
    this.jobsListView.forEach((job) => {
      job.toggleSelected(e, null, null, false);
    });

    this.allSelected = false;
  }

  clearAllSelections() {
    this.isTagView = false;
    //  this.isEditing = false;
    this.allSelected = false;
    this.jobsListViewState.forEach((value) => {
      value.selected = false;
    });
  }

  clearAllSelectionsOnTagView() {
    this.allSelected = false;
    this.jobsListViewState.forEach((value) => {
      value.selected = false;
    });
  }

  getFilterCriteriaQuery(column: FilterColumn): GraphQLQuery {
    switch (column) {
      case FILTER_COLUMN.TAGS:
        return {
          query: tagsFilterCriteria,
          variables: {},
        };

      default:
        return null;
    }
  }

  processFilterCriteria(column: FilterColumn, payload: Object): ?Array<Object> {
    switch (column) {
      case FILTER_COLUMN.TAGS:
        const tags: [
          {
            tag: string,
          }
        ] = payload.data.viewer.tagsFilterCriteria.tagnames;
        let processedTag = observable.map({});
        tags.forEach((tag, index) => {
          processedTag.set(String(index), {
            id: String(index),
            ...tag,
          });
        });
        return processedTag;

      default:
        return null;
    }
  }

  applyFilter(column: FilterColumn, filter: FilterObject) {
    this.appliedFilters[column] = filter;
    this.isFiltered = true;
  }

  applyDefaultFilter(column: FilterColumn, filter: FilterObject) {
    this.defaultFilters[column] = filter;
  }

  removeFilter(column: FilterColumn) {
    delete this.appliedFilters[column];

    let entries = Object.entries(this.appliedFilters);
    if (!entries.length) this.isFiltered = false;
  }

  applySort(column: FilterColumn, sort: Sort) {
    this.appliedSorts[column] = sort;

    const index = this.appliedSortsOrder.indexOf(column);
    if (index === -1) this.appliedSortsOrder.push(column);
  }

  removeSort(column: FilterColumn) {
    delete this.appliedSorts[column];

    const index = this.appliedSortsOrder.indexOf(column);
    if (index > -1) this.appliedSortsOrder.splice(index, 1);
  }

  clearFilters() {
    this.jobFilter = new JobFilter(
      this,
      FILTER_COLUMN.JOB_LABEL,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.createdOnFilter = new CreatedOnFilter(
      this,
      FILTER_COLUMN.DATE_RANGE,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.tagsFilter = new TagsFilter(
      this,
      FILTER_COLUMN.TAGS,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );

    this.appliedFilters = observable({});
    this.appliedSorts = observable({});
    this.appliedSortsOrder.length = 0;
    this.isFiltered = false;
    this.applyDefaultSort();

    return this.pagination.goFetch(null);
  }

  filterjobsList() {
    return this.pagination.goFetch(null);
  }

  getSelectedjobsList(): Array<string> {
    const jobsList = this.jobsListViewState;

    let selectedjobsList = [];

    jobsList.forEach((value, key) => {
      if (value.selected) {
        selectedjobsList.push(key);
      }
    });

    return selectedjobsList;
  }

  getFirstSelectedJobs() {
    for (let j = 0; j < this.jobsListView.length; j++) {
      if (this.jobsList[j].viewState.selected) return this.jobsList[j];
    }

    return null;
  }

  async getJobsList(pageQuery: PageQuery): Promise<PaginationInfo> {
    let params: string[] = pageQuery.params;
    let args = pageQuery.args;
    let variables = pageQuery.variables;

    // HACK: force limit on query, because PaginationState.goFetch method seems broken
    // if (variables.first) {
    //   params.push("$first: Int!");
    //   args.push("first: $first");
    // }

    let sortCriteria: string[] = [];
    consolidateAppliedSorts(this.appliedSorts, sortCriteria);

    let filtersCriteria: string[] = [];
    consolidateAppliedFilters(this.appliedFilters, params, filtersCriteria, variables);

    // NOTE: applied filters could override default filters, handle this if needed
    consolidateAppliedFilters(this.defaultFilters, params, filtersCriteria, variables);

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");
    const queryFiltersCriteria = filtersCriteria.join(", ");
    const querySortCriteria = sortCriteria.join(", ");

    const query = `

    query listJobs(${queryParams}){
      viewer{
        user{
            firstName
            lastName
            userId
            username
            email
            roles
          }
      jobs(${queryArgs}, filters: { ${queryFiltersCriteria} }, order: [${querySortCriteria}]){
      edges{
      node{
      jobId,
      jobLabel,
      jobTitle,
      jobTitleId,
      jobDescription,
      tags{
        name
        tagId
      }
      createdBy{
          firstName,
          lastName,
          email
      },
        created,
        modified
      }
      },
      totalCount,
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
      }
      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting Jobs Cards", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Jobs query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getJobsList--success", () => {
      this.network.loading = false;
      this.network.totalCounterror = null;
      if (res.data.viewer.jobs.edges > 0) {
        this.network.error = null;
      }

      if (this.network.logGraphQLError("Get Jobs query", res)) {
        // TODO: Display user friendly error message
        return {
          totalCount: 0,
          startCursor: "",
          endCursor: "",
        };
      }
      this.currentUser = new CurrentUser(this, res.data.viewer.user);

      // // $FlowFixMe: Type the res value once we abstract gql calls.
      const cards = res.data.viewer.jobs.edges;

      this.totalCount = res.data.viewer.jobs.totalCount;
      // // TODO: Deserialize this properly...
      this.jobsList = cards.map((card) => {
        const job = new JobList(this, card.node);

        // job.searches = card.node.searches.totalCount;
        // job.locations = card.node.locations;

        if (!this.jobsListViewState.has(job.jobId)) {
          this.jobsListViewState.set(job.jobId, {
            selected: this.allSelected,
            editing: this.isEditing,
          });
        } else {
          const selectedValue = this.allSelected
            ? true
            : this.jobsListViewState.get(job.jobId).selected;

          this.jobsListViewState.set(job.jobId, {
            selected: selectedValue,
            editing: this.isEditing,
          });
        }

        job.viewState = this.jobsListViewState.get(job.jobId);

        return job;
      });

      return {
        totalCount: res.data.viewer.jobs.totalCount,
        startCursor: res.data.viewer.jobs.pageInfo.startCursor,
        endCursor: res.data.viewer.jobs.pageInfo.endCursor,
      };
    });
  }
}

export default class JobLabelListStore extends JobLabelListComponentStore {
  showHelpModal: boolean;

  joblabel: string;
  jobtitle: string;
  jobtitleid: string;
  jobdescription: string;

  helpModal: ModalState;
  confirmUpdateRatesModal: ModalState;
  confirmUndoUpdateRatesModal: ModalState;
  confirmDeleteModal: ModalState;

  exportModalState: ExportOptionsState;

  showHelp: () => void;
  hideHelp: () => void;
  deletejobsList: () => void;
  refreshjobsList: () => void;
  exportjobsList: (options: ExportOptions) => void;
  validateExportOptions: (modalState: ExportOptionsState) => void;
  viewInMap: () => void;
  changeJobLabelText: () => void;
  changeJobTitleText: () => void;
  changeJobDescriptionText: () => void;
  deleteJob: () => void;
  export: () => void;
  selectJobs: () => void;
  changeDateFormat: () => void;
  applyTags: () => void;
  deleteSingleTags: () => void;
  exportJobs: () => void;
  renameJob: () => void;
  fetchGraphQL: FetchGraphQL;
  fetchAPI: FetchAPI;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI) {
    super(fetchGraphQL, fetchAPI);

    this.fetchGraphQL = fetchGraphQL;
    this.fetchAPI = fetchAPI;

    // Bound early to pass into export state
    // this.exportjobsList = action(this.exportjobsList.bind(this));
    this.validateExportOptions = action(this.validateExportOptions.bind(this));
    this.changeJobLabelText = action(this.changeJobLabelText.bind(this));
    this.changeJobDescriptionText = action(this.changeJobDescriptionText.bind(this));
    this.changeJobTitleText = action(this.changeJobTitleText.bind(this));
    this.deleteJob = action(this.deleteJob.bind(this));
    this.selectJobs = action(this.selectJobs.bind(this));
    this.changeDateFormat = action(this.changeDateFormat.bind(this));
    this.applyTags = action(this.applyTags.bind(this));
    this.deleteSingleTags = action(this.deleteSingleTags.bind(this));
    this.exportJobs = action(this.exportJobs.bind(this));
    this.renameJob = action(this.renameJob.bind(this));
    this.JobTitle = this.JobTitle.bind(this);

    extendObservable(this, {
      helpModal: new ModalState(),
      confirmDeleteModal: new ModalState(),
      exportModal: new ExportOptionsState(
        this.exportjobsList,
        this.validateExportOptions
      ),
      networkShareUsers: new NetworkState(),
      networkJobTitle: new NetworkState(),
      joblabel: "",
      jobtitle: "",
      jobdescription: "",
    });

    this.showHelp = action(this.showHelp.bind(this));
    this.hideHelp = action(this.hideHelp.bind(this));
    this.export = action(this.export.bind(this));
  }

  showHelp() {
    this.showHelpModal = true;
  }

  hideHelp() {
    this.showHelpModal = false;
  }

  validateExportOptions(modalState: ExportOptionsState) {
    if (modalState.exportOptions.fileType === FILE_TYPE.CSV) {
      modalState.disableExportTypeOptions = true;
      modalState.exportOptions.exportType = EXPORT_TYPE.DETAILED;
      modalState.info = "CSV file format will always include rates data.";
    } else {
      modalState.disableExportTypeOptions = false;
      modalState.info = null;
    }
  }

  async addJob() {
    let variables = {
      jobData: {
        jobLabel: this.joblabel,
        jobTitle: this.jobtitle,
        jobTitleId: 2,
        jobDescription: this.jobdescription,
      },
    };

    const query = `
        mutation createJobMutation($jobData: CreateJobInput){
          createJob(jobData:$jobData){
          job{
          jobLabel,
          jobTitle,
          jobTitleId,
          jobDescription
          }
          }
        }
        `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
      // should hit get request
    } catch (e) {
      console.error("Error adding job", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);

      return;
    }

    runInAction("addJob--success", () => {
      this.joblabel = "";
      // this.jobtitle = ''
      this.jobdescription = "";
      browserHistory.push("/job-labels");
      //this.pagination.goFetch(null)
    });

    return {
      job: res.data.createJob.job,
    };
  }

  async JobTitle(searchText) {
    // console.log("searchText: ", searchText);
    // console.log("this.networkJobTitle.loading: ", this.networkJobTitle.loading);
    if (this.networkJobTitle.loading) {
      // console.log("checking cancelTokenSource", this.networkJobTitle.cancelTokenSource);
      this.networkJobTitle.cancel();
      // throw new Error("Cancelled request");
    }

    const variables = {
      searchParam: searchText === undefined ? "" : searchText,
    };
    const query = `
          query jobTitleList($searchParam: String){
          viewer{
            jobTitles(search: $searchParam, page:1, pageSize:100){
              results {
              slug,
              showDescription,
              isJobLabel,
              title,
              category,
              shareInfo{
                jobLabelId,
                isMine,
              },
              collection,
              id
            }
          }
          }
        }
        `;
    const cancelToken = this.networkJobTitle.setLoading(true);
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables, cancelToken);
      // should hit get request
    } catch (e) {
      if (this.networkJobTitle.isCancelError(e)) {
        console.log("request cancelled");
        return;
      }

      this.networkJobTitle.setLoading(false);
      console.error("Error in getting job title", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    this.networkJobTitle.setLoading(false);
    if (res.errors) {
      console.error("Errors", res.errors);
      throw new Error("Error getting titles");
    }

    if (res.data.viewer.jobTitles.results.length > 0) {
      res = mapJobTitles(res);
    }
    // console.log("returning", res.data.viewer.jobTitles.results[0]);
    return {
      jobTitles: res.data.viewer.jobTitles.results,
    };
  }

  async JobDescription(title_id) {
    const query = `
            query jobTitleDetail($titleId: Int!){
            viewer{
              jobTitle(id: $titleId){
                isJobLabel,
                title,
                slug,
                collection,
                description,
                id
              }
            }
          }
        `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, { titleId: title_id });
      // should hit get request
    } catch (e) {
      console.error("Error editing job", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);

      return;
    }

    runInAction("JobDescription--success", () => {
      if (res.data.viewer.jobTitle.description) {
        this.jobdescription = res.data.viewer.jobTitle.description;
      } else {
        // NOTE: When the job title does not have a job description, we send an empty space to perform search
        // for legacy reasons.
        this.jobdescription = " ";
      }

      // TODO: Deserialize this properly...
      // const list = new SignupUser(this, list);
      // this.signupuser.firstName = SignupUserData.firstName
      // this.signupuser.lastName = SignupUserData.lastName
      // this.signupuser.email = SignupUserData.email
    });

    return {
      jobDescription: res.data.viewer.jobTitle.description,
    };
  }

  changeDateFormat(date) {
    var formatted_date = new Date(date);
    var year = formatted_date.getFullYear() + "";
    var month = formatted_date.getMonth() + 1 + "";
    var day = formatted_date.getDate() + "";
    var dateFormat = year + "-" + month + "-" + day;

    return dateFormat;
  }

  toDateRange(date) {
    var val = true;
    if (this.createdOnFilter.fromDate) {
      val = !date.isBetween(
        this.createdOnFilter.fromDate,
        moment(new Date()),
        "days",
        "[]"
      );
    }
    return val;
  }

  selectJobs(type) {
    if (this.network.loading) {
      return;
    }

    let params = [];
    let args = [];
    let vars = {};
    let searchCriteria = "";

    if (this.allSelected) {
      consolidateAppliedFilters(this.appliedFilters, params, args, vars);
      searchCriteria = {
        searchParam: vars,
      };
    } else {
      const selectedjobsList = this.getSelectedjobsList();

      if (!selectedjobsList) {
        console.error("Cannot " + type + " jobs: No Jobs selected");
        return;
      }
      searchCriteria = {
        jobId: selectedjobsList,
      };
    }

    return searchCriteria;
  }

  async applyTags() {
    //var searchCriteria = this.selectWorksheets("delete");
    // let searchquery = '';
    // let parameters = {};

    let params = [];
    let filterargs = [];
    let searchargs = [];
    let args = [];
    let vars = {};

    const taglist = this.applyTagState.getSelectedTagList();
    if (!taglist || !taglist.length) {
      console.error("Cannot Apply tags to Jobs: No Jobs selected");
      return;
    }
    params.push("$tagIds: [Int]!");
    filterargs.push("tagIds: $tagIds");
    vars.tagIds = taglist;

    if (this.allSelected) {
      consolidateAppliedFilters(this.appliedFilters, params, args, vars);
      params.push("$exclude: [ID]");
      args.push("exclude: $exclude");
      vars.exclude = [];
      // vars.exclude = vars.exclude.filter(function(item, pos) {
      //   return vars.exclude.indexOf(item) == pos;
      // })
    } else {
      const itemList = this.getSelectedjobsList();
      if (!itemList || !itemList.length) {
        console.error("Cannot apply tage Jobs: No Jobs selected");
        return;
      }
      params.push("$only: [ID]");
      args.push("only: $only");
      vars.only = itemList;
    }

    if (this.jobFilter.textToLookFor) {
      params.push("$search: String");
      searchargs.push("search:$search");
      vars.search = this.jobFilter.textToLookFor;
    }

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
      mutation applyTags(${queryParams}){
       applyTagsToJobs(input:{${filterargs}, filters: { ${queryArgs}}, ${searchargs}}) {
         ok
          errors {
           __typename
           ... on TagIdRequiredError {
             message
           }
           ... on TagIdsNotExistsError {
             message
           }
           ... on InvalidInputError {
             message
           }
           ... on ContentDoesNotExistsError {
             message
           }
         }
       }

      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Apply Tags to Selected Jobs", e);
      // TODO: Display user friendly error message
      return;
    }
    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }
    this.handleStopEdit();

    runInAction("applyTags--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("applyTags", res)) {
        // TODO: Display user friendly error message
        return;
      }
      this.applyTagState.tagModal.hideModal();
      this.pagination.goFetch(null);
    });
  }

  async deleteJob() {
    var searchCriteria = this.selectJobs("delete");
    // let searchquery = '';
    let parameters = {};

    if (searchCriteria.jobId !== null && searchCriteria.jobId !== undefined) {
      parameters.jobId = JSON.parse(
        JSON.stringify(searchCriteria.jobId).replace(/"/g, "")
      );
    }

    if (
      searchCriteria.searchParam !== null &&
      searchCriteria.searchParam !== undefined &&
      "nameFilterText" in searchCriteria.searchParam
    ) {
      parameters.search = searchCriteria.searchParam.nameFilterText;
    }

    if (
      searchCriteria.searchParam !== null &&
      searchCriteria.searchParam !== undefined &&
      "fromDate" in searchCriteria.searchParam &&
      "toDate" in searchCriteria.searchParam
    ) {
      parameters.fromDate = this.changeDateFormat(searchCriteria.searchParam.fromDate);
      parameters.toDate = this.changeDateFormat(searchCriteria.searchParam.toDate);
    }

    const query = `
      mutation deleteJob($jobData : DeleteJobInput){
       deleteJob(jobData: $jobData){
         ok
       }
      }
    `;

    const variables = {
      jobData: parameters,
    };

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Deleting selected Jobs", e);
      // TODO: Display user friendly error message
      return;
    }
    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }
    this.handleStopEdit();

    runInAction("deleteJob--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("deleteJob", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.pagination.goFetch(null);
    });
  }

  changeJobLabelText(e: Object) {
    this.joblabel = e.target.value;
  }

  changeJobTitleText(e: Object) {
    if (e && e.id) {
      if (
        document.getElementById("jobLabel") &&
        document.getElementById("jobDescription")
      ) {
        var subtitleWithJobLabel = " (via My Job Labels)";
        if (e.title.includes(subtitleWithJobLabel)) {
          this.joblabel = e.title.replace(subtitleWithJobLabel, "");
          document.getElementById("jobLabel").disabled = true;
          document.getElementById("jobDescription").disabled = true;
        } else {
          this.joblabel = "";
          document.getElementById("jobLabel").disabled = false;
          document.getElementById("jobDescription").disabled = false;
        }
      }
      this.jobtitle = e.title;
      this.jobtitleid = e.id;
      this.JobDescription(e.id);
    } else {
      this.jobtitle = "";
      if (
        document.getElementById("jobLabel") &&
        document.getElementById("jobDescription")
      ) {
        this.joblabel = "";
        document.getElementById("jobLabel").disabled = false;
        document.getElementById("jobDescription").disabled = false;
      }
      this.jobdescription = "";
    }
  }

  editJobTitle(val) {
    var newJobTitle = val;
    var subtitlewithoutJobLabel = " (stock title)";
    var subtitleWithJobLabel = " (via My Job Labels)";
    if (val.includes(subtitlewithoutJobLabel)) {
      newJobTitle = val.replace(subtitlewithoutJobLabel, "");
    } else if (val.includes(subtitleWithJobLabel)) {
      newJobTitle = val.replace(subtitleWithJobLabel, "");
    }
    return newJobTitle;
  }

  searchName(title, industry, country) {
    var jobtitle = this.editJobTitle(title);
    var name = jobtitle + ", " + industry + ", " + country;
    return name;
  }

  changeJobDescriptionText(e: Object) {
    this.jobdescription = e.target.value;
  }

  export() {
    var searchCriteria = this.selectJobs("export");
    let parameters = [];
    let searchquery = "";

    if (searchCriteria.jobId !== null && searchCriteria.jobId !== undefined) {
      searchquery = searchCriteria.jobId.toString().replace(/"/g, "");
      searchquery = "&jobId=" + searchquery;
      parameters = parameters.concat(searchquery);
    }

    if (
      searchCriteria.searchParam !== null &&
      searchCriteria.searchParam !== undefined &&
      "nameFilterText" in searchCriteria.searchParam
    ) {
      searchquery = "&search=" + searchCriteria.searchParam.nameFilterText;
      parameters = parameters.concat(searchquery);
    }

    if (
      searchCriteria.searchParam !== null &&
      searchCriteria.searchParam !== undefined &&
      "fromDate" in searchCriteria.searchParam &&
      "toDate" in searchCriteria.searchParam
    ) {
      var from_Date = this.changeDateFormat(searchCriteria.searchParam.fromDate);
      var to_date = this.changeDateFormat(searchCriteria.searchParam.toDate);
      searchquery = "&fromDate=" + from_Date + "&toDate=" + to_date;
      parameters = parameters.concat(searchquery);
    }

    parameters = parameters.join("");

    const nameOfFile = "exported_table_" + Math.floor(Math.random() * 9999999 + 1000000);

    try {
      setTimeout(() => {
        const response = {
          file:
            this.sevenUrl.seven + this.exportUrl + "?fileName=" + nameOfFile + parameters,
        };
        window.location.href = response.file;
      }, 100);
    } catch (e) {
      console.error("Error downloading excel", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    this.network.loading = false;
    this.handleStopEdit();
    //this.pagination.goFetch(null);
  }

  exportJobs() {
    if (this.network.loading) {
      return;
    }

    let params = [];
    let args = [];
    let vars = {};
    let exportURL = "job-labels/basic/list/export/excel/";
    // Change the Filename
    var fileName = "export_jobs_" + Math.floor(Math.random() * 9999999 + 1000000);
    let parameters = "";
    let exparams = "";

    if (this.allSelected) {
      consolidateAppliedFilters(this.appliedFilters, params, args, vars);
      vars.exclude = [];
      if (vars.nameFilterText) {
        vars.jobTitleIContains = vars.nameFilterText;
        delete vars.nameFilterText;
      }
      parameters = JSON.stringify(vars);
      if (parameters === "") {
        exparams = `{"fileName": "${fileName}", "jobsFilters": {${parameters}}}`;
      } else {
        exparams = `{"fileName": "${fileName}", "jobsFilters": ${parameters}}`;
      }
    } else {
      const selectedJobs = this.getSelectedjobsList();
      if (!selectedJobs || !selectedJobs.length) {
        console.error("Cannot export Jobs: No Jobs selected");
        return;
      }
      vars.only = selectedJobs;
      parameters = JSON.stringify(vars);
      if (parameters === "") {
        exparams = `{"fileName": "${fileName}", "jobsFilters": {${parameters}}}`;
      } else {
        exparams = `{"fileName": "${fileName}","jobsFilters": ${parameters}}`;
      }
    }

    // TODO: Fix this crazy param stuff. I don't have time to figure it out.
    this.fetchAPI(exportURL, JSON.parse(exparams))
      .then((res) => res.data)
      .then((json) => {
        setTimeout(() => {
          window.location.href = json.url; // GET request for response returned from the POST request made above
        }, 100);
      })
      .catch((e) => {
        console.error("Error downloading excel", e);
        throw e;
      });

    this.handleStopEdit();
  }

  async deleteSingleTags(content) {
    if (this.network.loading) {
      return;
    }

    // let params = [];
    // let args = [];
    // let vars = {};

    //   params.push("$tagIds: [Int]");
    //   args.push("tagIds: $tagIds");
    //   vars.tagIds.push(tag_id);

    const query = `
    mutation removeTagsFromContent{
      removeTagsFromContent(input: {tagIds : [${content.tagId}],contentType :JOB_LIBRARY,contentId:${content.contentId}}){
        ok
        }
      }
    `;
    //query.variables = vars;

    // this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, {});
    } catch (e) {
      this.network.handleError("Deleting selected Tag", e);
      // TODO: Display user friendly error message
      return;
    }

    //this.handleStopEdit();
    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }
    runInAction("deleteSingleTags--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("deleteTag", res)) {
        // TODO: Display user friendly error message
        return;
      }

      //this.pagination.goFetch(null);
    });
    return {
      result: res.data.removeTagsFromContent.ok,
    };
  }

  async renameJob() {
    let variables = {
      jobLabel: this.newJobName,
      jobId: this.getSelectedjobsList()[0],
    };

    this.messaging.removeAll();

    if (!this.newJobName.trim()) {
      this.messaging.createMessage("info", "Please enter a New Job Name.");
      return;
    }

    const query = `
      mutation renameJob($jobId:Int!, $jobLabel:String! ){
        renameJob(jobLabel:$jobLabel, jobId:$jobId){
          ok
          error {
            __typename
            ... on JobDoesNotExistsError {
              message
            }
            ... on JobLabelEmptyError {
              message
            }
         }
        }

      }
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
      // should hit get request
    } catch (e) {
      console.error("Error renaming job", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }

    runInAction("renameJob--success", () => {
      if (res.data.renameJob.error) {
        this.messaging.removeAll();
        this.messaging.createMessage("error", res.data.renameJob.error[0].message);
        return;
      }
      //this.getJobDetails();
      this.handleStopEdit();
      this.pagination.goFetch(null);
      this.renameModal.hideModal();
    });

    return {};
  }
}
