/**
 * @generated SignedSource<<6dc1eeb715b8c067baaf9386a6f03360>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type LevelLiteral = "Guru" | "Junior" | "Lead" | "Mid" | "Senior" | "%future added value";
export type LevelRomanNumeral = "I" | "II" | "III" | "IV" | "V" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AdminSearchDetail_viewer$data = {
  readonly savedsearch: {
    readonly buyrates: ReadonlyArray<{
      readonly buyrateId: number | null;
      readonly name: string;
      readonly notes: string | null;
      readonly punchouts: ReadonlyArray<{
        readonly billRateMax: number | null;
        readonly billRateMin: number | null;
        readonly id: string;
        readonly levelName: LevelLiteral | null;
        readonly levelRomanNumeral: LevelRomanNumeral | null;
        readonly markupPct: number | null;
        readonly payRateMax: number | null;
        readonly payRateMin: number | null;
      } | null> | null;
    } | null> | null;
    readonly canRevert: boolean | null;
    readonly city: string | null;
    readonly country: string;
    readonly createdBy: {
      readonly email: string | null;
      readonly firstName: string | null;
      readonly lastName: string | null;
    } | null;
    readonly createdDate: string;
    readonly currency: {
      readonly symbol: string | null;
    } | null;
    readonly industry: {
      readonly legacyId: number;
      readonly value: string;
    } | null;
    readonly isDraft: boolean | null;
    readonly isGlobalSupplierSearch: boolean | null;
    readonly job: {
      readonly jobLabel: string;
      readonly jobTitle: string | null;
    };
    readonly marketRate: {
      readonly billRate: number | null;
      readonly markupPct: number | null;
      readonly payRate: number | null;
    } | null;
    readonly marketchanges: ReadonlyArray<{
      readonly billRateAvg: number | null;
      readonly billRateMax: number | null;
      readonly billRateMid: number | null;
      readonly billRateMin: number | null;
      readonly level: {
        readonly legacyId: number | null;
        readonly literal: LevelLiteral | null;
        readonly romanNumeral: LevelRomanNumeral | null;
      } | null;
      readonly markupPctAvg: number | null;
      readonly markupPctMax: number | null;
      readonly markupPctMid: number | null;
      readonly markupPctMin: number | null;
      readonly numResults: number | null;
      readonly payRateAvg: number | null;
      readonly payRateMax: number | null;
      readonly payRateMid: number | null;
      readonly payRateMin: number | null;
      readonly revNum: number;
    } | null> | null;
    readonly needsUpdate: boolean | null;
    readonly numResults: number | null;
    readonly rateType: number | null;
    readonly rates: {
      readonly billRate: {
        readonly avg: ReadonlyArray<number | null> | null;
        readonly max: ReadonlyArray<number | null> | null;
        readonly mid: ReadonlyArray<number | null> | null;
        readonly min: ReadonlyArray<number | null> | null;
      } | null;
      readonly markupPct: {
        readonly avg: ReadonlyArray<number | null> | null;
        readonly max: ReadonlyArray<number | null> | null;
        readonly mid: ReadonlyArray<number | null> | null;
        readonly min: ReadonlyArray<number | null> | null;
      } | null;
      readonly payRate: {
        readonly avg: ReadonlyArray<number | null> | null;
        readonly max: ReadonlyArray<number | null> | null;
        readonly mid: ReadonlyArray<number | null> | null;
        readonly min: ReadonlyArray<number | null> | null;
      } | null;
    } | null;
    readonly ratesData: ReadonlyArray<number | null> | null;
    readonly region: {
      readonly country: {
        readonly name: string | null;
      } | null;
      readonly name: string;
    } | null;
    readonly searchId: number | null;
    readonly state: string | null;
    readonly tags: ReadonlyArray<{
      readonly name: string;
      readonly tagId: number;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "AdminSearchDetail_viewer";
};
export type AdminSearchDetail_viewer$key = {
  readonly " $data"?: AdminSearchDetail_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminSearchDetail_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "legacyId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payRateMin",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payRateMax",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billRateMin",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billRateMax",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "markupPct",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numResults",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "min",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "max",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "avg",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "mid",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminSearchDetail_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "searchId"
        },
        {
          "kind": "Literal",
          "name": "section",
          "value": "ADMIN"
        }
      ],
      "concreteType": "SaveSearchNode",
      "kind": "LinkedField",
      "name": "savedsearch",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "searchId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isDraft",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isGlobalSupplierSearch",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TagNode",
          "kind": "LinkedField",
          "name": "tags",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tagId",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CurrencyNode",
          "kind": "LinkedField",
          "name": "currency",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "symbol",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "JobResponse",
          "kind": "LinkedField",
          "name": "job",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "jobLabel",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "jobTitle",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "IndustryNode",
          "kind": "LinkedField",
          "name": "industry",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rateType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "country",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "state",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "NewRegionNode",
          "kind": "LinkedField",
          "name": "region",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "LocationNode",
              "kind": "LinkedField",
              "name": "country",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BuyRateNode",
          "kind": "LinkedField",
          "name": "buyrates",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "buyrateId",
              "storageKey": null
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "notes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PunchoutNode",
              "kind": "LinkedField",
              "name": "punchouts",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "levelName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "levelRomanNumeral",
                  "storageKey": null
                },
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MarketRateNode",
          "kind": "LinkedField",
          "name": "marketRate",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "payRate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "billRate",
              "storageKey": null
            },
            (v6/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MarketChangeNode",
          "kind": "LinkedField",
          "name": "marketchanges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "revNum",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "LevelType",
              "kind": "LinkedField",
              "name": "level",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "literal",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "romanNumeral",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "billRateAvg",
              "storageKey": null
            },
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "billRateMid",
              "storageKey": null
            },
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "payRateAvg",
              "storageKey": null
            },
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "payRateMid",
              "storageKey": null
            },
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "markupPctAvg",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "markupPctMax",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "markupPctMid",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "markupPctMin",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RateSearchResponse",
          "kind": "LinkedField",
          "name": "rates",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LevelResponse",
              "kind": "LinkedField",
              "name": "markupPct",
              "plural": false,
              "selections": (v8/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "LevelResponse",
              "kind": "LinkedField",
              "name": "billRate",
              "plural": false,
              "selections": (v8/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "LevelResponse",
              "kind": "LinkedField",
              "name": "payRate",
              "plural": false,
              "selections": (v8/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ratesData",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LegacyUserNode",
          "kind": "LinkedField",
          "name": "createdBy",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canRevert",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "needsUpdate",
          "storageKey": null
        },
        (v7/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "422f8d0353b7f1e0f428856efb6f74e4";

export default node;
