// @flow
import React, { Component } from "react";
import { observer } from "mobx-react";
import PunchoutsList from "./components/PunchoutsList";
import SearchBox from "../../components/lib/SearchBox";
import Modal from "../ratecards/components/Modal";
import InputNumberForAlert from "../../components/inputs/InputNumberForAlert";
import type MobXStore from "../../stores/mobx/MobXStore";
import SessionInfo from "../../models/SessionInfo";
import Container from "../../components/lib/Container";
import {
  Card,
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubTitle,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
} from "../../components/lib/Card";
import Button from "../../components/lib/Button";
import EditActions from "../../components/lib/EditActions";
import { ModalTitle, ModalButtons } from "../ratecards/components/FilterModal";
import ContainerSection from "../ratecards/components/ContainerSection";
import TextInput from "../../components/lib/TextInput";

type Props = {
  store: MobXStore,
  sessionInfo: SessionInfo,
};

class List extends Component<Props> {
  componentDidMount() {
    this.props.store.punchoutsListStore.sessionInfo = this.props.sessionInfo;
  }

  render() {
    const props = this.props;
    const store = props.store.punchoutsListStore;

    return (
      <Container
        css={{
          paddingTop: "$4",
          paddingLeft: 0,
          paddingRight: 0,
          width: "100%",
        }}
      >
        <Card css={{ zIndex: 2 }}>
          <CardHeader>
            <CardHeaderTitle>Punchouts and Market Comparison</CardHeaderTitle>
            <CardHeaderSubTitle className="subtext">
              Review punchouts created on ratecards.
            </CardHeaderSubTitle>
          </CardHeader>
          <CardActions>
            <CardActionsLeft>
              <SearchBox
                value={store.punchoutLabelFilter.textToLookFor}
                onChange={store.punchoutLabelFilter.onTextToLookForChange}
                onSubmit={store.punchoutLabelFilter.onApply}
                style={{ width: "260px" }}
                data-testid="search-box-punchouts"
              />
            </CardActionsLeft>
            <CardActionsRight>
              {store.isEditing && (
                <Button color="accent" onClick={store.handleStopEdit}>
                  Stop Editing
                </Button>
              )}
              {!store.isEditing && (
                <Button color="brand" icon="edit" onClick={store.handleStartEdit}>
                  Edit
                </Button>
              )}
            </CardActionsRight>
          </CardActions>
          <PunchoutsList store={store} />
        </Card>
        <EditActions hidden={store.isEditing === false} show={store.isEditing === true}>
          {
            <Button
              color="danger"
              icon="trash-alt"
              disabled={store.selectedCount === 0}
              onClick={store.confirmDeleteModal.showModal}
            >
              Delete
            </Button>
          }
        </EditActions>

        {
          /*<Modal show={store.helpModal.show} onHide={store.helpModal.hideModal}>
        <div className="container-section header">
          <h4>Help</h4>
        </div>
        <div className="container-section footer">
          <p>Here you can see all of your regions...</p>
        </div>
      </Modal>*/

          <Modal
            show={store.confirmDeleteModal.show}
            onHide={store.confirmDeleteModal.hideModal}
          >
            <ContainerSection className="header">
              <ModalTitle>Confirmation Needed</ModalTitle>
            </ContainerSection>
            <ContainerSection>
              <p>Are you sure you want to delete the selected Punchouts?</p>
              {/*<p className="text-danger">
            <FontAwesomeIcon icon="exclamation-triangle"/> {" "}
            <em>This action cannot be undone!</em>
          </p>*/}
            </ContainerSection>
            <ContainerSection className="footer">
              <ModalButtons>
                <Button size="large" onClick={store.confirmDeleteModal.hideModal}>
                  No, Cancel
                </Button>
                <Button
                  size="large"
                  color="danger"
                  onClick={(e) => {
                    store.confirmDeleteModal.hideModal();
                    store.deletePunchouts();
                  }}
                >
                  Yes, Delete Punchouts
                </Button>
              </ModalButtons>
            </ContainerSection>
          </Modal>
        }
        {
          <Modal show={store.setAlertModal.show} onHide={store.setAlertModal.hideModal}>
            <ContainerSection className="header">
              <ModalTitle>Set Alert</ModalTitle>
            </ContainerSection>
            <ContainerSection>
              <p>Are you sure you want to set Alert?</p>
              <table
                className="no-spacing table table_label"
                cellSpacing="0"
                style={{
                  textAlign: "center",
                }}
              >
                <thead className="">
                  <tr>
                    <th className="col-sm-1" />
                    <th className="col-sm-1">Pay Rate</th>
                    <th className="col-sm-1">Set Alert (in %)</th>
                  </tr>
                </thead>
                <tbody>
                  {/*tableRows*/}
                  <tr>
                    <td>Avg</td>
                    <td className="col-sm-1">
                      <TextInput
                        style={{
                          width: "70%",
                        }}
                        disabled="true"
                        value={store.payRateValues.payAvg}
                      />
                    </td>
                    <td className="col-sm-1">
                      <InputNumberForAlert
                        value={store.payRateAlertValues.payRateAvgAlert}
                        onChange={store.setAlertValues}
                        payRateType="payRateAvgAlert"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Mid</td>
                    <td className="col-sm-1">
                      <TextInput
                        style={{
                          width: "70%",
                        }}
                        disabled="true"
                        value={store.payRateValues.payMid}
                      />
                    </td>
                    <td className="col-sm-1">
                      <InputNumberForAlert
                        value={store.payRateAlertValues.payRateMidAlert}
                        onChange={store.setAlertValues}
                        payRateType="payRateMidAlert"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Min</td>
                    <td className="col-sm-1">
                      <TextInput
                        style={{
                          width: "70%",
                        }}
                        disabled="true"
                        value={store.payRateValues.payMin}
                      />
                    </td>
                    <td className="col-sm-1">
                      <InputNumberForAlert
                        value={store.payRateAlertValues.payRateMinAlert}
                        onChange={store.setAlertValues}
                        payRateType="payRateMinAlert"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Max</td>
                    <td className="col-sm-1">
                      <TextInput
                        style={{
                          width: "70%",
                        }}
                        disabled="true"
                        value={store.payRateValues.payMax}
                      />
                    </td>
                    <td className="col-sm-1">
                      <InputNumberForAlert
                        value={store.payRateAlertValues.payRateMaxAlert}
                        onChange={store.setAlertValues}
                        payRateType="payRateMaxAlert"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </ContainerSection>
            <ContainerSection className="footer">
              <ModalButtons>
                <Button size="large" onClick={store.setAlertModal.hideModal}>
                  No, Cancel
                </Button>
                <Button
                  size="large"
                  color="brand"
                  onClick={(e) => {
                    store.setAlertModal.hideModal();
                    store.setPunchoutAlert();
                  }}
                >
                  Yes, Set Alert
                </Button>
              </ModalButtons>
            </ContainerSection>
          </Modal>
        }
      </Container>
    );
  }
}

export default observer(List);
