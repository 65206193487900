import { styled } from "../../stitches.config";
import { Link as RRLink } from "react-router";

export type LinkBaseColor =
  | "primary"
  | "secondary"
  | "brand"
  | "accent"
  | "success"
  | "danger"
  | "warning";

export function getLinkBaseStyles(baseColor: LinkBaseColor) {
  return {
    // we use !important here to override global link styles
    color: `$${baseColor}Dark !important`,
    textDecoration: "none !important",
    cursor: "pointer",
    background: "none",
    "&:hover": {
      color: `$${baseColor} !important`,
      textDecoration: "underline !important",
    },
  };
}

export const linkBaseStyles = getLinkBaseStyles("brand");

// @ts-ignore
export const NavBarLink = styled(RRLink, {
  color: "$brand !important",
});

// @ts-ignore
export const Link = styled(RRLink, linkBaseStyles);

export const createLink = (baseColor: LinkBaseColor) =>
  styled(RRLink as any, getLinkBaseStyles(baseColor));

// NOTE: Use this component for mailto and other links that do not require client side routing
export const SimpleLink = styled("a", linkBaseStyles);
