// @flow
import * as React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import SortIndicator from "../../ratecards/components/SortIndicator";
import { FILTER_COLUMN } from "../../../models/Filter";
import LoadingIndicator from "../../shared/LoadingIndicator";
import DraftSearchesListItem from "./DraftSearchesListItem";
import { SingleDatePicker } from "../../../components/SingleDatePicker";
import { DraftsSavedSearchListComponentStore } from "../../../stores/mobx/DraftsSavedSearchListStore";
import ToggleButton from "../../ratecards/components/ToggleButton";
import JobLabelFilter from "../../filters/JobLabelFilter";
import JobTitleFilter from "../../filters/JobTitleFilter";
import LocationFilter from "../../filters/LocationFilter";
import CreatedOnFilter from "../../filters/CreatedOnFilter";
import IndustryFilter from "../../filters/IndustryFilter";
import RateTypeFilter from "../../filters/RateTypeFilter";
import Button from "../../../components/lib/Button";
import {
  CardAlert,
  CardAlertLink,
  CardFilter,
  CardFilters,
} from "../../../components/lib/Card";
import Spacer from "../../../components/lib/Spacer";
import Pagination from "../../../components/lib/Pagination";
import { InlineElements } from "../../../components/lib/Inline";

/**
 * DraftSearchesList component for draft search page
 */
class DraftSearchesList extends React.Component {
  /**
   * constructor
   * @param {props} props The first number.

   */
  constructor(props) {
    super(props);
    this.state = {
      locationTitle: [
        {
          title: "Please Input keywords to Search...",
        },
      ],
      locations: [],
    };
    this.handleLocationSearchChange = this.handleLocationSearchChange.bind(this);
    this.onLocationChange = this.onLocationChange.bind(this);
    this.changeCurrent = this.changeCurrent.bind(this);
  }
  /**
   * handleLocationSearchChange
   * @return {void}
   */
  handleLocationSearchChange(e) {
    this.setState({ locationTitle: [] });
    if (e === "" || undefined || null) {
      this.setState({
        locationTitle: [
          {
            title: "Please Input keywords to Search...",
          },
        ],
      });
    } else {
      if (e.length > 2) {
        this.props.rateSearchStore
          .locationTitle(e)
          .then((response) => this.setState({ locationTitle: response.locationTitle }));
      }
    }
  }
  /**
   * onLocationChange
   * @return {void}
   */
  componentDidMount() {
    this.props.store.pagination.goFetch();
    this.props.store.clearFilters();
  }

  onLocationChange(location) {
    var result = location.map(function (a) {
      return a.locationId;
    });
    this.props.store.locationFilter.onIdToLookForChange(result);
    this.setState({ locations: location });
  }
  /**
   * Render draft search list page
   * @return {XML}
   */

  changeCurrent(value) {
    let store: DraftsSavedSearchListComponentStore = this.props.store;
    store.pagination.handleCurrentPage(value);
  }

  render() {
    const store: DraftsSavedSearchListComponentStore = this.props.store;
    const network = store.network;
    const fillContainer = this.props.fillContainer;
    const fixedHeight = this.props.fixedHeight;
    const maximumDate = new Date();
    let listBodyStyles = {};
    if (fixedHeight) {
      listBodyStyles.height = fixedHeight;
      listBodyStyles.overflowY = "auto";
    }

    let selectAllSection = null;
    let selectAllOnPageSection = (
      <>
        <Button onClick={store.selectAllOnPageItem}>Select All</Button>
        <Button onClick={store.deselectAllPage}>Deselect All</Button>
      </>
    );

    if (store.allOnPageSelected) {
      selectAllSection = (
        <CardAlert>
          <span>
            All &nbsp;
            <strong>{store.savedSearchCount}</strong>
            &nbsp; Draft Search selected.{" "}
          </span>
          &nbsp;
          <CardAlertLink key="clear-selections-link" onClick={store.clearAllSelections}>
            Clear all selections
          </CardAlertLink>
        </CardAlert>
      );
    }

    if (store.allOnPageSelected && !store.allSelected) {
      selectAllSection = (
        <CardAlert>
          <span>All Draft Search on this page selected. &nbsp;</span>
          &nbsp;
          <CardAlertLink key="select-all-item" onClick={store.selectAllPage}>
            Select all &nbsp;
            <strong>{store.savedSearchCount}</strong>
            &nbsp; Draft Search.
          </CardAlertLink>
          &nbsp;&nbsp;
          <CardAlertLink key="clear-selections-link" onClick={store.clearAllSelections}>
            Clear all selections
          </CardAlertLink>
        </CardAlert>
      );
    }

    let tableLeftActionBar = null;

    tableLeftActionBar = (
      <>{store.allowMultipleItemSelection && selectAllOnPageSection}</>
    );

    const rateCards = store.rateCardsView;
    let tableContent = null;
    if (network.loading) {
      tableContent = <LoadingIndicator />;
    } else if (rateCards.length === 0) {
      tableContent = (
        <div className="ratecards-table-empty">
          {Object.keys(store.appliedFilters).length > 0 ? (
            <div>No Searches found</div>
          ) : (
            <div>
              No Saved Search
              <p>Create a new Saved Search using the button above or by searching.</p>
            </div>
          )}
        </div>
      );
    } else {
      tableContent = rateCards.map((rateCard) => (
        <DraftSearchesListItem
          key={rateCard.searchId}
          store={store}
          rateCard={rateCard}
          editing={true}
        />
      ));
    }

    var fromDate = (
      <SingleDatePicker
        id="from_date"
        date={store.createdOnFilter.fromDate}
        numberOfMonths={1}
        isOutsideRange={(day) => day.isAfter(maximumDate)}
        focused={store.createdOnFilter.fromFocused}
        onDateChange={store.createdOnFilter.fromDateChange}
        onFocusChange={store.createdOnFilter.fromFocusedChange}
      />
    );

    var toDate = (
      <SingleDatePicker
        id="to_date"
        date={store.createdOnFilter.toDate}
        numberOfMonths={1}
        isOutsideRange={(day) => store.toDateRange(day)}
        focused={store.createdOnFilter.toFocused}
        onDateChange={store.createdOnFilter.toDateChange}
        onFocusChange={store.createdOnFilter.toFocusedChange}
      />
    );

    var rateTypeList = (
      <InlineElements>
        {store.rateTypeFilter.viewItems.map((item) => (
          <ToggleButton
            key={item.id}
            large={true}
            item={item}
            type="radio"
            name="rate-type"
            value={item.value}
            selected={item.selected}
            onChange={store.rateTypeFilter.setSelectedValue}
          >
            {item.value}
          </ToggleButton>
        ))}
      </InlineElements>
    );

    return (
      <div>
        {store.rateTypeFilter && (
          <RateTypeFilter
            rateTypeFilter={store.rateTypeFilter}
            rateTypeList={rateTypeList}
            network={store.rateTypeFilter.network.loading}
          />
        )}
        {store.jobLabelFilter && (
          <JobLabelFilter
            jobLabel={store.jobLabelFilter}
            network={store.jobLabelFilter.network.loading}
            instantSearchValue={store.jobLabelFilter.instantSearchValue}
            onInstantSearch={store.jobLabelFilter.onInstantSearch}
          />
        )}
        {store.jobFilter && (
          <JobTitleFilter
            jobTitle={store.jobFilter}
            network={store.jobFilter.network.loading}
            instantSearchValue={store.jobFilter.instantSearchValue}
            onInstantSearch={store.jobFilter.onInstantSearch}
          />
        )}
        {store.locationFilter && (
          <LocationFilter
            location={store.locationFilter}
            network={store.locationFilter.network.loading}
            instantSearchValue={store.locationFilter.instantSearchValue}
            onInstantSearch={store.locationFilter.onInstantSearch}
          />
        )}
        {store.createdOnFilter && (
          <CreatedOnFilter
            createdOnFilter={store.createdOnFilter}
            fromDate={fromDate}
            toDate={toDate}
          />
        )}
        {store.industryFilter && (
          <IndustryFilter
            industryFilter={store.industryFilter}
            network={store.industryFilter.network.loading}
            instantSearchValue={store.industryFilter.instantSearchValue}
            onInstantSearch={store.industryFilter.onInstantSearch}
          />
        )}

        <CardFilters filtered={store.isFiltered}>
          {tableLeftActionBar}
          <Spacer />
          {store.isFiltered && (
            <Button color="accent" onClick={store.clearFilters}>
              Clear All Filters & Sorts
            </Button>
          )}
          {store.rateTypeFilter && (
            <CardFilter
              name="rate-cards-filter"
              value={FILTER_COLUMN.RATE_TYPE}
              filtered={Boolean(store.appliedFilters[FILTER_COLUMN.RATE_TYPE])}
              onClick={store.rateTypeFilter.onShowModal}
            >
              <SortIndicator
                sortColumn={FILTER_COLUMN.RATE_TYPE}
                sortsOrder={store.appliedSortsOrder}
                sort={store.appliedSorts[FILTER_COLUMN.RATE_TYPE]}
              />
              Rate Type
              <span> ▼</span>
            </CardFilter>
          )}
          {store.jobLabelFilter && (
            <CardFilter
              name="rate-cards-filter"
              value={FILTER_COLUMN.JOB_LABEL}
              filtered={Boolean(store.appliedFilters[FILTER_COLUMN.JOB_LABEL])}
              onClick={store.jobLabelFilter.onShowModal}
            >
              <SortIndicator
                sortColumn={FILTER_COLUMN.JOB_LABEL}
                sortsOrder={store.appliedSortsOrder}
                sort={store.appliedSorts[FILTER_COLUMN.JOB_LABEL]}
              />
              Job Label
              <span> ▼</span>
            </CardFilter>
          )}
          {store.jobFilter && (
            <CardFilter
              name="rate-cards-filter"
              value={FILTER_COLUMN.JOB_TITLE}
              filtered={Boolean(store.appliedFilters[FILTER_COLUMN.JOB_TITLE])}
              onClick={store.jobFilter.onShowModal}
            >
              <SortIndicator
                sortColumn={FILTER_COLUMN.JOB_TITLE}
                sortsOrder={store.appliedSortsOrder}
                sort={store.appliedSorts[FILTER_COLUMN.JOB_TITLE]}
              />
              Job Title
              <span> ▼</span>
            </CardFilter>
          )}
          {store.locationFilter && (
            <CardFilter
              name="rate-cards-filter"
              value={FILTER_COLUMN.LOCATION}
              filtered={Boolean(store.appliedFilters[FILTER_COLUMN.LOCATION])}
              onClick={store.locationFilter.onShowModal}
            >
              <SortIndicator
                sortColumn={FILTER_COLUMN.LOCATION}
                sortsOrder={store.appliedSortsOrder}
                sort={store.appliedSorts[FILTER_COLUMN.LOCATION]}
              />
              Location
              <span> ▼</span>
            </CardFilter>
          )}
          {store.createdOnFilter && (
            <CardFilter
              name="rate-cards-filter"
              value={FILTER_COLUMN.DATE_RANGE}
              filtered={Boolean(store.appliedFilters[FILTER_COLUMN.DATE_RANGE])}
              onClick={store.createdOnFilter.onShowModal}
            >
              <SortIndicator
                sortType="numeric"
                sortColumn={FILTER_COLUMN.DATE_RANGE}
                sortsOrder={store.appliedSortsOrder}
                sort={store.appliedSorts[FILTER_COLUMN.DATE_RANGE]}
              />
              Created On
              <span> ▼</span>
            </CardFilter>
          )}
          {store.industryFilter && (
            <CardFilter
              name="rate-cards-filter"
              value={FILTER_COLUMN.DATE_RANGE}
              filtered={Boolean(store.appliedFilters[FILTER_COLUMN.INDUSTRY])}
              onClick={store.industryFilter.onShowModal}
            >
              <SortIndicator
                sortColumn={FILTER_COLUMN.INDUSTRY}
                sortsOrder={store.appliedSortsOrder}
                sort={store.appliedSorts[FILTER_COLUMN.INDUSTRY]}
              />
              Industry
              <span> ▼</span>
            </CardFilter>
          )}
        </CardFilters>
        <div
          className={classNames("ratecards-table", {
            "ratecards-table-fill-container": fillContainer,
          })}
        >
          <div style={listBodyStyles}>
            {selectAllSection}
            {tableContent}
          </div>
        </div>
        {store.pagination.pageCount > 0 ? (
          <Pagination
            options={{
              variant: "full",
              currentPage: store.pagination.currentPage,
              numPages: store.pagination.pageCount,
            }}
            onPageClick={(pageEvent) => {
              if (pageEvent.type === "first") {
                this.changeCurrent(1);
              }
              if (pageEvent.type === "last") {
                this.changeCurrent(store.pagination.pageCount);
              }
              if (pageEvent.type === "next") {
                this.changeCurrent(
                  Math.min(store.pagination.currentPage + 1, store.pagination.pageCount)
                );
              }
              if (pageEvent.type === "prev") {
                this.changeCurrent(Math.max(store.pagination.currentPage - 1, 1));
              }
              if (pageEvent.type === "page") {
                this.changeCurrent(pageEvent.page);
              }
            }}
            // pageCount={store.pagination.pageCount}
            // pagesToShow={store.pagination.maxPagesToShow}
            // currentPage={store.pagination.currentPage}
            // begin={store.pagination.begin}
            // end={store.pagination.end}
            // isEndRange={store.pagination.isEndRange}
            // handleCurrentPage={this.changeCurrent}
            // firstPage={store.pagination.firstPage}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

DraftSearchesList.propTypes = {
  store: PropTypes.object.isRequired,
  fixedHeight: PropTypes.number,
  fillContainer: PropTypes.bool,
};

export default observer(DraftSearchesList);
