/**
 * There is no data to show.
 */
export class NotFound extends Error {
  constructor(message: string) {
    super(message);
    this.name = "Not Found Error";
    this.message = message;
  }
}

/**
 * User is logged in but not Authorized to do something.
 */
export class NotAuthorized extends Error {
  username: string;
  userId: number;

  constructor(username: string, userId: number, message?: string) {
    const msg =
      message ??
      `You are logged in as ${username} #${userId} which is forbidden from accessing this resource.`;

    super(msg);
    this.name = "Not Authorized Error";
    this.message = msg;
    this.username = username;
    this.userId = userId;
  }
}

/**
 * User not logged in.
 */
export class NotAuthenticated extends Error {
  constructor(message?: string) {
    const msg = message ?? `No user found, please log in.`;
    super(msg);
    this.name = "Not Authenticated Error";
    this.message = msg;
  }
}

/**
 * A given input is missing or in the wrong format.
 */
export class UserInput extends Error {
  property: string;
  reason: string;

  constructor(property: string, reason = "is required.") {
    const msg = `${property} ${reason}`;
    super(msg);
    this.name = "User Input Error";
    this.message = msg;
    this.property = property;
    this.reason = reason;
  }
}
