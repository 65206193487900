import React from "react";

import Text from "../../../components/lib/Text";
import Box from "../../../components/lib/Box";
import Stack from "../../../components/lib/Stack";
import Inline, { InlineElements } from "../../../components/lib/Inline";
import NumberInput, { isFiniteNumber } from "../../../components/lib/NumberInput";

import { useRateDisplayContext } from "../../validator5K_admin/context/RateDisplayContext";
import { useFillTheBlankFeedbackContext } from "../../validator5K/context/feedback/FillTheBlankFeedbackContext";
import {
  RateFeedbackBlock,
  RateFeedbackValue,
} from "../../validator5K_admin/components/FillTheBlankFeedbackPreview";

export function FillTheBlankHourlyFeedback() {
  const { jobFeedback, updateFeedbackState } = useFillTheBlankFeedbackContext();
  const {
    convertToDisplayCurrency,
    convertFromDisplayCurrency,
    resultingCurrencySymbol,
  } = useRateDisplayContext();

  const payRateMin = convertToDisplayCurrency(jobFeedback.get("pay_rate_min"));
  const payRateMax = convertToDisplayCurrency(jobFeedback.get("pay_rate_max"));

  return (
    <>
      <RateFeedbackBlock
        editable={true}
        heading="Lowest Realistic Hourly Wage:"
        description="This rate is for someone with up to 2 years of experience performing the duties described in the job description."
      >
        <InlineElements nowrap>
          <Text>{resultingCurrencySymbol}</Text>
          <NumberInput
            size="small"
            value={payRateMin ?? ""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateFeedbackState(
                "pay_rate_min",
                convertFromDisplayCurrency(
                  isFiniteNumber(e.target.valueAsNumber) ? e.target.valueAsNumber : null
                )
              )
            }
          />
        </InlineElements>
      </RateFeedbackBlock>

      <RateFeedbackBlock
        editable={true}
        heading="Highest Realistic Hourly Wage:"
        description="This rate is for someone who is among the best of the best performing the duties described in the job description."
      >
        <InlineElements nowrap>
          <Text>{resultingCurrencySymbol}</Text>
          <NumberInput
            size="small"
            value={payRateMax ?? ""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateFeedbackState(
                "pay_rate_max",
                convertFromDisplayCurrency(
                  isFiniteNumber(e.target.valueAsNumber) ? e.target.valueAsNumber : null
                )
              )
            }
          />
        </InlineElements>
      </RateFeedbackBlock>

      <Text size="base">
        What are taxes and supplier fees/rewards percentages to be expected for this
        resource:
      </Text>

      <Inline fill nowrap css={{ alignItems: "flex-start" }}>
        <Stack
          css={{
            alignItems: "flex-start",
            gap: "$2",
            justifySelf: "flex-start",
            width: "50%",
          }}
        >
          <Text>Employment/Payroll Taxes %:</Text>
          <RateFeedbackValue editable>
            <NumberInput
              size="small"
              value={jobFeedback.get("employment_taxes_percentage") ?? ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateFeedbackState(
                  "employment_taxes_percentage",
                  !isFiniteNumber(e.target.valueAsNumber) ? null : e.target.valueAsNumber
                )
              }
            />
          </RateFeedbackValue>
        </Stack>

        <Stack css={{ alignItems: "flex-start", gap: "$2", width: "50%" }}>
          <Text>Supplier Fees % (including profit):</Text>

          <Inline>
            <Box css={{ width: "50px" }}>Low %:</Box>
            <RateFeedbackValue editable>
              <NumberInput
                size="small"
                value={jobFeedback.get("supplier_rewards_percentage_min") ?? ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  updateFeedbackState(
                    "supplier_rewards_percentage_min",
                    !isFiniteNumber(e.target.valueAsNumber)
                      ? null
                      : e.target.valueAsNumber
                  )
                }
              />
            </RateFeedbackValue>
          </Inline>

          <Inline>
            <Box css={{ width: "50px" }}>High %:</Box>
            <RateFeedbackValue editable>
              <NumberInput
                size="small"
                value={jobFeedback.get("supplier_rewards_percentage_max") ?? ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  updateFeedbackState(
                    "supplier_rewards_percentage_max",
                    !isFiniteNumber(e.target.valueAsNumber)
                      ? null
                      : e.target.valueAsNumber
                  )
                }
              />
            </RateFeedbackValue>
          </Inline>
        </Stack>
      </Inline>
    </>
  );
}

FillTheBlankHourlyFeedback.displayName = "FillTheBlankHourlyFeedback";

export function FillTheBlankSalaryFeedback() {
  const { jobFeedback, updateFeedbackState } = useFillTheBlankFeedbackContext();
  const {
    resultingCurrencySymbol,
    convertToDisplayCurrency,
    convertFromDisplayCurrency,
  } = useRateDisplayContext();

  const annualSalaryMin = convertToDisplayCurrency(jobFeedback.get("annual_salary_min"));
  const annualSalaryMax = convertToDisplayCurrency(jobFeedback.get("annual_salary_max"));

  return (
    <>
      <RateFeedbackBlock
        editable={true}
        heading="Lowest Realistic Annual Base Salary:"
        description="This rate is for someone with up to 2 years of experience performing the duties described in the job description."
      >
        <InlineElements nowrap>
          <Text>{resultingCurrencySymbol}</Text>
          <NumberInput
            size="small"
            value={annualSalaryMin ?? ""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateFeedbackState(
                "annual_salary_min",
                convertFromDisplayCurrency(
                  isFiniteNumber(e.target.valueAsNumber) ? e.target.valueAsNumber : null
                )
              )
            }
          />
        </InlineElements>
      </RateFeedbackBlock>

      <RateFeedbackBlock
        editable={true}
        heading="Highest Realistic Annual Base Salary:"
        description="This rate is for someone who is among the best of the best performing the duties described in the job description."
      >
        <InlineElements nowrap>
          <Text>{resultingCurrencySymbol}</Text>
          <NumberInput
            size="small"
            value={annualSalaryMax ?? ""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateFeedbackState(
                "annual_salary_max",
                convertFromDisplayCurrency(
                  isFiniteNumber(e.target.valueAsNumber) ? e.target.valueAsNumber : null
                )
              )
            }
          />
        </InlineElements>
      </RateFeedbackBlock>
    </>
  );
}

FillTheBlankSalaryFeedback.displayName = "FillTheBlankSalaryFeedback";
