// @flow

import { extendObservable, runInAction, action } from "mobx";
import NetworkState from "../NetworkState";
import type { FetchGraphQL } from "../../App";

export type PieChartPayloadDataItem = {
  name: string,
  y?: number,
  sliced?: boolean,
  selected?: boolean,
};

export class PieChartPayloadData {
  data: ?(PieChartPayloadDataItem[]);

  constructor(payloadData: ?(PieChartPayloadDataItem[])) {
    this.data = null;

    if (payloadData) {
      this.data = payloadData;
    }
  }
}

export default class JobsByTagPieState {
  rateCardStore: Object;
  fetchGraphQL: FetchGraphQL;
  network: NetworkState;
  chartData: PieChartPayloadData;
  load: (?boolean) => Promise<any>;

  constructor(store: Object, fetchGraphQL: FetchGraphQL) {
    this.rateCardStore = store;
    this.fetchGraphQL = fetchGraphQL;

    extendObservable(this, {
      network: new NetworkState(),
      chartData: new PieChartPayloadData(null),
    });

    this.load = action(this.load.bind(this));
  }

  async load(fullReload: boolean = false) {
    this.network.loading = true;

    if (!fullReload && this.chartData.data) return;

    const query = `
    query getRateCardVisualizations($rateCardId: Int!) {
      viewer {
        rateCard(legacyId: $rateCardId) {
          visualizations {
            jobsByTag {
              name
              y
            }
          }
        }
      }
    }
    `;

    let payload: ?Object;
    try {
      const variables = {
        rateCardId: this.rateCardStore.rateCardId,
      };
      payload = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Getting Jobs By Category", e);
      // TODO: Display user friendly error message
      return;
    }

    runInAction("getVisualizations--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("getRateCardVisualizations", payload)) {
        // TODO: Display user friendly error message
        return;
      }

      // NOTE: Flow refinement
      if (payload == null) {
        return;
      }

      this.chartData = new PieChartPayloadData(
        payload.data.viewer.rateCard.visualizations.jobsByTag
      );
    });
  }
}
