/**
 * @generated SignedSource<<3b099a34887c5da42b11c7713e6dd3f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PayDifference_viewer$data = {
  readonly countries: ReadonlyArray<{
    readonly id: string;
    readonly isoCode: string;
    readonly locationId: number;
    readonly name: string;
  } | null> | null;
  readonly savedsearches: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly country: string;
        readonly currency: {
          readonly id: string;
          readonly iso: string;
        } | null;
        readonly locationFullLabel: string;
        readonly locationId: number | null;
        readonly locationLabel: string | null;
        readonly marketrates: ReadonlyArray<{
          readonly billRateAvg: number;
          readonly billRateMax: number;
          readonly billRateMid: number;
          readonly billRateMin: number;
          readonly id: string;
          readonly level: {
            readonly legacyId: number | null;
            readonly value: string;
          };
          readonly markupPctAvg: number;
          readonly markupPctMax: number;
          readonly markupPctMid: number;
          readonly markupPctMin: number;
          readonly payRateAvg: number;
          readonly payRateMax: number;
          readonly payRateMid: number;
          readonly payRateMin: number;
        } | null> | null;
        readonly rates: {
          readonly rawAvg: number;
          readonly rawMax: number;
          readonly rawMid: number;
          readonly rawMin: number;
        } | null;
        readonly region: {
          readonly name: string;
          readonly regionId: number;
        } | null;
        readonly searchId: number;
      } | null;
    } | null>;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ModifyLocationsDialog_viewer" | "PayDifferenceMap_viewer">;
  readonly " $fragmentType": "PayDifference_viewer";
};
export type PayDifference_viewer$key = {
  readonly " $data"?: PayDifference_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"PayDifference_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locationId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "searchIDs"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "viewer"
      ],
      "operation": require('./PayDifferenceRefetchQuery.graphql')
    }
  },
  "name": "PayDifference_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ModifyLocationsDialog_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PayDifferenceMap_viewer"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CountryListNode",
      "kind": "LinkedField",
      "name": "countries",
      "plural": true,
      "selections": [
        {
          "kind": "RequiredField",
          "field": (v0/*: any*/),
          "action": "NONE",
          "path": "countries.id"
        },
        {
          "kind": "RequiredField",
          "field": (v1/*: any*/),
          "action": "NONE",
          "path": "countries.locationId"
        },
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isoCode",
            "storageKey": null
          },
          "action": "NONE",
          "path": "countries.isoCode"
        },
        {
          "kind": "RequiredField",
          "field": (v2/*: any*/),
          "action": "NONE",
          "path": "countries.name"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "only",
              "variableName": "searchIDs"
            }
          ],
          "kind": "ObjectValue",
          "name": "filters"
        }
      ],
      "concreteType": "SaveSearchNodeConnection",
      "kind": "LinkedField",
      "name": "savedsearches",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SaveSearchNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SaveSearchNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "searchId",
                    "storageKey": null
                  },
                  "action": "NONE",
                  "path": "savedsearches.edges.node.searchId"
                },
                (v1/*: any*/),
                {
                  "kind": "RequiredField",
                  "field": {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "locationFullLabel",
                    "storageKey": null
                  },
                  "action": "NONE",
                  "path": "savedsearches.edges.node.locationFullLabel"
                },
                {
                  "kind": "RequiredField",
                  "field": {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "country",
                    "storageKey": null
                  },
                  "action": "NONE",
                  "path": "savedsearches.edges.node.country"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "locationLabel",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CurrencyNode",
                  "kind": "LinkedField",
                  "name": "currency",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "RequiredField",
                      "field": {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "iso",
                        "storageKey": null
                      },
                      "action": "NONE",
                      "path": "savedsearches.edges.node.currency.iso"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "NewRegionNode",
                  "kind": "LinkedField",
                  "name": "region",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": (v2/*: any*/),
                      "action": "NONE",
                      "path": "savedsearches.edges.node.region.name"
                    },
                    {
                      "kind": "RequiredField",
                      "field": {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "regionId",
                        "storageKey": null
                      },
                      "action": "NONE",
                      "path": "savedsearches.edges.node.region.regionId"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RateSearchResponse",
                  "kind": "LinkedField",
                  "name": "rates",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "rawMin",
                        "storageKey": null
                      },
                      "action": "NONE",
                      "path": "savedsearches.edges.node.rates.rawMin"
                    },
                    {
                      "kind": "RequiredField",
                      "field": {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "rawAvg",
                        "storageKey": null
                      },
                      "action": "NONE",
                      "path": "savedsearches.edges.node.rates.rawAvg"
                    },
                    {
                      "kind": "RequiredField",
                      "field": {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "rawMid",
                        "storageKey": null
                      },
                      "action": "NONE",
                      "path": "savedsearches.edges.node.rates.rawMid"
                    },
                    {
                      "kind": "RequiredField",
                      "field": {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "rawMax",
                        "storageKey": null
                      },
                      "action": "NONE",
                      "path": "savedsearches.edges.node.rates.rawMax"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "MarketRatesNode",
                  "kind": "LinkedField",
                  "name": "marketrates",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": (v0/*: any*/),
                      "action": "NONE",
                      "path": "savedsearches.edges.node.marketrates.id"
                    },
                    {
                      "kind": "RequiredField",
                      "field": {
                        "alias": null,
                        "args": null,
                        "concreteType": "LevelType",
                        "kind": "LinkedField",
                        "name": "level",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "legacyId",
                            "storageKey": null
                          },
                          {
                            "kind": "RequiredField",
                            "field": {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "value",
                              "storageKey": null
                            },
                            "action": "NONE",
                            "path": "savedsearches.edges.node.marketrates.level.value"
                          }
                        ],
                        "storageKey": null
                      },
                      "action": "NONE",
                      "path": "savedsearches.edges.node.marketrates.level"
                    },
                    {
                      "kind": "RequiredField",
                      "field": {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "payRateMin",
                        "storageKey": null
                      },
                      "action": "NONE",
                      "path": "savedsearches.edges.node.marketrates.payRateMin"
                    },
                    {
                      "kind": "RequiredField",
                      "field": {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "payRateMid",
                        "storageKey": null
                      },
                      "action": "NONE",
                      "path": "savedsearches.edges.node.marketrates.payRateMid"
                    },
                    {
                      "kind": "RequiredField",
                      "field": {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "payRateMax",
                        "storageKey": null
                      },
                      "action": "NONE",
                      "path": "savedsearches.edges.node.marketrates.payRateMax"
                    },
                    {
                      "kind": "RequiredField",
                      "field": {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "payRateAvg",
                        "storageKey": null
                      },
                      "action": "NONE",
                      "path": "savedsearches.edges.node.marketrates.payRateAvg"
                    },
                    {
                      "kind": "RequiredField",
                      "field": {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "billRateMin",
                        "storageKey": null
                      },
                      "action": "NONE",
                      "path": "savedsearches.edges.node.marketrates.billRateMin"
                    },
                    {
                      "kind": "RequiredField",
                      "field": {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "billRateMid",
                        "storageKey": null
                      },
                      "action": "NONE",
                      "path": "savedsearches.edges.node.marketrates.billRateMid"
                    },
                    {
                      "kind": "RequiredField",
                      "field": {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "billRateMax",
                        "storageKey": null
                      },
                      "action": "NONE",
                      "path": "savedsearches.edges.node.marketrates.billRateMax"
                    },
                    {
                      "kind": "RequiredField",
                      "field": {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "billRateAvg",
                        "storageKey": null
                      },
                      "action": "NONE",
                      "path": "savedsearches.edges.node.marketrates.billRateAvg"
                    },
                    {
                      "kind": "RequiredField",
                      "field": {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "markupPctMin",
                        "storageKey": null
                      },
                      "action": "NONE",
                      "path": "savedsearches.edges.node.marketrates.markupPctMin"
                    },
                    {
                      "kind": "RequiredField",
                      "field": {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "markupPctMid",
                        "storageKey": null
                      },
                      "action": "NONE",
                      "path": "savedsearches.edges.node.marketrates.markupPctMid"
                    },
                    {
                      "kind": "RequiredField",
                      "field": {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "markupPctMax",
                        "storageKey": null
                      },
                      "action": "NONE",
                      "path": "savedsearches.edges.node.marketrates.markupPctMax"
                    },
                    {
                      "kind": "RequiredField",
                      "field": {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "markupPctAvg",
                        "storageKey": null
                      },
                      "action": "NONE",
                      "path": "savedsearches.edges.node.marketrates.markupPctAvg"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "5c9ab9c4e7bf1aaab490ebff56f012a0";

export default node;
