import React from "react";

import { styled, CSS } from "../../stitches.config";
import type {
  PROCESSING_TYPES_LABELS,
  PROCESSING_TYPES_TYPE,
} from "../../views/private_index/types";

const componentHeight = "22px";

export const ProgressContainer = styled("div", {
  display: "inline-block",
  position: "relative",
  minWidth: "100px",
  height: componentHeight,
  borderRadius: "$sm",
  background: "$primaryLighter",
  fontSize: "$sm",
  overflow: "hidden",
  "&:focus": {
    outline: "none",
  },
  variants: {
    fill: {
      true: {
        display: "block",
        width: "$full",
      },
    },
  },
  default: {
    fill: false,
  },
});

export const ProgressLine = styled("div", {
  display: "block",
  height: "$full",
  backgroundColor: "$brand",
  verticalAlign: "middle",
  transition: "width 0.5s ease-in-out",
});

export const ProgressText = styled("div", {
  display: "block",
  position: "absolute",
  top: 0,
  left: 0,
  width: "$full",
  height: "$full",
  lineHeight: componentHeight,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textAlign: "center",
  textOverflow: "ellipsis",
  verticalAlign: "middle",
  color: "inherit",
  "& > *": {
    verticalAlign: "middle",
  },
});

type ProgressBarProps = {
  progress: number;
  total: number;
  label?: (typeof PROCESSING_TYPES_LABELS)[PROCESSING_TYPES_TYPE] | null;
  postLabel?: string | null;
  css?: CSS;
};

const getProgressInPercents = (total: number, progress: number): number => {
  if (total <= 0) return 0;
  if (progress < 0) progress = 0;

  let result = Math.round((progress * 100) / total);

  if (result > 100) result = 100;

  return result;
};

export const ProgressBar = (props: ProgressBarProps) => {
  const { label, postLabel, css, total, progress } = props;

  const progressInPercents = getProgressInPercents(total, progress);

  return (
    <ProgressContainer tabIndex={0} css={css}>
      <ProgressLine css={{ width: `${progressInPercents}%` }} />
      <ProgressText>
        {label ? `${label} ` : null}
        <b>{`${progressInPercents}%`}</b>
        {postLabel ? ` ${postLabel}` : null}
      </ProgressText>
    </ProgressContainer>
  );
};

ProgressBar.displayName = "ProgressBar";
ProgressBar.defaultProps = {
  progress: 0,
  total: 100,
};

export default ProgressBar;
