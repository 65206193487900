import * as React from "react";
import type Stitches from "@stitches/react";
import { keyframes, styled } from "../../stitches.config";
import type { CSS } from "../../stitches.config";

const spin = keyframes({
  "100%": { transform: "rotate(360deg)" },
});

const SpinningSVG = styled("svg", {
  animation: `${spin} 1s linear infinite`,
  variants: {
    size: {
      "extra-small": { width: "$4", height: "$4" },
      small: { width: "$5", height: "$5" },
      normal: { width: "$6", height: "$6" },
      ptNormal: { width: "$6", height: "$6" },
      large: { width: "$7", height: "$7" },
      "extra-large": { width: "$8", height: "$8" },
    },
  },
  defaultVariants: {
    size: "normal",
  },
});

export type SpinnerProps = { css?: CSS } & Stitches.VariantProps<typeof SpinningSVG> &
  React.ComponentPropsWithoutRef<"svg">;

function Spinner(props: SpinnerProps) {
  return (
    <SpinningSVG
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <circle
        style={{ opacity: 0.25 }}
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      />
      <path
        style={{ opacity: 0.75 }}
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </SpinningSVG>
  );
}

Spinner.displayName = "Spinner";

export default Spinner;
