import { styled } from "../../stitches.config";
import Box from "./Box";

const Inline = styled(Box, {
  gap: "$4",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexWrap: "wrap",
  variants: {
    fill: {
      true: {
        width: "$full",
      },
    },
    nowrap: {
      true: {
        flexWrap: "nowrap",
      },
    },
    nogap: {
      true: {
        gap: 0,
      },
    },
  },
  defaultVariants: {
    fill: false,
    nowrap: false,
    nogap: false,
  },
});

Inline.displayName = "Inline";

/**
 * Shorthand for `Inline` component but with a gap of `$2`.
 *
 * DL: Found myself using `<Inline css={{ gap: "$2" }}>` everywhere
 * and since `Inline` was already being use extensively did
 * not want to mess with the defaults
 */
export const InlineElements = styled(Inline, {
  gap: "$2",
});

InlineElements.displayName = "InlineElements";

export default Inline;
