import React from "react";

import Stack from "../../../../components/lib/Stack";
import Text from "../../../../components/lib/Text";
import { RATE_TYPES, JOB_FEEDBACK_STATUS_TYPES, REVIEW_STATUS_TYPES } from "../../types";
import {
  useCreateOrUpdateJobFeedbackAPI,
  useCreateOrUpdateQuestionsFeedbackAPI,
} from "../../hooks";
import {
  FillTheBlankHourlyFeedback,
  FillTheBlankSalaryFeedback,
} from "../../components/FillTheBlankFeedback";
import SurveyItemBase from "./SurveyItemBase";

import { useReviewDataContext } from "../../../validator5K_admin/context/ReviewDataContext";
import { useAttemptDataContext } from "../../context/AttemptDataContext";
import FillTheBlankFeedbackContext, {
  useCreateFillTheBlankFeedbackContext,
  useFillTheBlankFeedbackContext,
} from "../../context/feedback/FillTheBlankFeedbackContext";
import QuestionsFeedbackContext, {
  useCreateQuestionsFeedbackContext,
} from "../../context/feedback/QuestionsFeedbackContext";
import ValidationContext, {
  useCreateValidationContext,
} from "../../context/ValidationContext";

function SecondaryParametersBlock() {
  const { firstJobRateType: rateType } = useReviewDataContext();
  const { hasMissingRatesFeedback } = useFillTheBlankFeedbackContext();

  return (
    <Stack fill css={{ alignItems: "flex-start", gap: "$6" }}>
      {rateType !== RATE_TYPES.ANNUAL && <FillTheBlankHourlyFeedback />}
      {rateType === RATE_TYPES.ANNUAL && <FillTheBlankSalaryFeedback />}
      {hasMissingRatesFeedback && (
        <Text noMargin as="h5" color="negative">
          Please fill up all the given rates fields above.
        </Text>
      )}
    </Stack>
  );
}

SecondaryParametersBlock.displayName = "SecondaryParametersBlock";

export function SurveyItemPageFillInTheBlanks() {
  const { firstJobId } = useReviewDataContext();
  const { attemptId, updateAttemptData } = useAttemptDataContext();

  // state

  const jobFeedbackContext = useCreateFillTheBlankFeedbackContext();
  const questionsFeedbackContext = useCreateQuestionsFeedbackContext();
  const validationContext = useCreateValidationContext();

  // utils

  const isValidFeedback =
    !jobFeedbackContext.hasMissingRatesFeedback &&
    !questionsFeedbackContext.hasMissingRequiredQuestionsFeedback;

  // fetch

  const createOrUpdateJobFeedback = useCreateOrUpdateJobFeedbackAPI();
  const createOrUpdateQuestionsFeedback = useCreateOrUpdateQuestionsFeedbackAPI();

  // handlers

  const handleSubmitFeedback = React.useCallback(async () => {
    if (!isValidFeedback) return;

    // convert rate values back from display currency
    let nextJobFeedback = jobFeedbackContext.jobFeedback;

    await createOrUpdateQuestionsFeedback(questionsFeedbackContext.questionsFeedback);
    await createOrUpdateJobFeedback({
      ...nextJobFeedback.toJS(),
      attempt: attemptId,
      job: firstJobId,
      status: JOB_FEEDBACK_STATUS_TYPES.VALIDATED,
    });
    await updateAttemptData(attemptId, { review_status: REVIEW_STATUS_TYPES.REVIEWED });
  }, [
    isValidFeedback,
    questionsFeedbackContext.questionsFeedback,
    jobFeedbackContext.jobFeedback,
    attemptId,
    firstJobId,
    createOrUpdateJobFeedback,
    createOrUpdateQuestionsFeedback,
    updateAttemptData,
  ]);

  // render

  return (
    <FillTheBlankFeedbackContext.Provider value={jobFeedbackContext}>
      <QuestionsFeedbackContext.Provider value={questionsFeedbackContext}>
        <ValidationContext.Provider value={validationContext}>
          <SurveyItemBase
            secondaryParametersBlock={<SecondaryParametersBlock />}
            hasNoRatesFeedback={!jobFeedbackContext.jobFeedback.size}
            hasNoQuestionsFeedback={!questionsFeedbackContext.questionsFeedback.size}
            disableSubmit={!isValidFeedback}
            onSubmitFeedback={handleSubmitFeedback}
          />
        </ValidationContext.Provider>
      </QuestionsFeedbackContext.Provider>
    </FillTheBlankFeedbackContext.Provider>
  );
}

SurveyItemPageFillInTheBlanks.displayName = "SurveyItemPageFillInTheBlanks";

export default SurveyItemPageFillInTheBlanks;
