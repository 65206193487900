import { styled } from "../../stitches.config";

const Spacer = styled("div", {
  border: "none",
  flex: "1 1 0%", // grow and shrink as needed, ignoring its initial size
});

Spacer.displayName = "Spacer";

export default Spacer;
