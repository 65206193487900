// @flow
// $FlowFixMe ignoring "unable to resolve module" error
import templateFile from "../../assets/templates/library_request_template.xlsx";
import React, { Component } from "react";
import type { Element } from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingIndicator from "../ratecards/components/LoadingIndicator";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import classNames from "classnames";
import NonIdealState from "./components/NonIdealState";
import MultiDownshift from "./components/MultiDownshift";
import CCCCountry from "../../models/CCCCountry";
import FlagIcon from "../../components/FlagIcon";
import ErrorModal from "../../components/modals/ErrorModal";
import type MobXStore from "../../stores/mobx/MobXStore";
import Button from "../../components/lib/Button";
import Container from "../../components/lib/Container";
import { styled } from "../../../src/stitches.config";
import TextInput from "../../components/lib/TextInput";
import Inline from "../../components/lib/Inline";
import Box from "../../components/lib/Box";
import TextArea from "./components/TextArea";
import { Link } from "../../components/lib/Link";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../components/lib/DropdownMenu";

const JobLibCenteredForm = styled("div", {
  padding: "50px 65px",
  background: "#ffffff",
  border: "1px solid #e6e6e6",
  borderRadius: "2px",
  width: "100%",
  maxWidth: "556px",
  position: "relative",
  overflow: "hidden",
  color: "#333333",
  alignSelf: "flex-start",
});

const JobLibFormLabel = styled("label", {
  color: "#999",
  fontSize: "10.5px !important",
  fontWeight: "600 !important",
  letterSpacing: "0.6px",
  wordSpacing: "1.2px",
});

type Props = {
  store: MobXStore,
  router: Object,
};

class JobLibraryRequestCreate extends Component<Props> {
  constructor(props: Props) {
    super(props);

    const store = this.props.store.jobLibraryRequestCreateStore;
    store.previousLocation = "/job-library/library-requests/";
    store.successModal.hideModal();
    store.reset();

    store.getUserCountries().then(() => store.getCCCCountries());
  }

  componentDidMount() {
    this.props.store.jobLibraryRequestCreateStore.router = this.props.router;
  }

  renderCountryItem = (country: CCCCountry): Element<any> => {
    return (
      <span>
        {country.iso3166Alpha2 && <FlagIcon code={country.iso3166Alpha2} />}{" "}
        {country.title}
      </span>
    );
  };

  render() {
    const store = this.props.store.jobLibraryRequestCreateStore;

    const titleErrorTooltip = (
      <Tooltip id="titleErrorTooltip" placement="top">
        {store.titleError}
      </Tooltip>
    );

    return (
      <Container>
        <JobLibCenteredForm>
          {store.network.loading && (
            <NonIdealState
              icon="circle-notch"
              iconClasses="fa-spin"
              title="Loading"
              description="Please wait while data it's being transferred."
            />
          )}
          {!store.network.loading && (
            <div>
              <button
                className="cjl-form-close-button"
                onClick={store.goBack}
                data-testid="close-button"
              >
                <FontAwesomeIcon icon="times" />
              </button>
              <h2>Request New Library</h2>
              <p className="cjl-p">
                To have a new library created please submit the form below. You'll receive
                a notification via email when your new library is available.
              </p>

              <Box css={{ marginBottom: "15px" }}>
                <JobLibFormLabel htmlFor="libraryTitle">LIBRARY TITLE</JobLibFormLabel>
                <TextInput
                  size="small"
                  fill="true"
                  id="libraryTitle"
                  onChange={store.onTitleChange}
                />
                {Boolean(store.titleError) && (
                  <OverlayTrigger placement="top" overlay={titleErrorTooltip}>
                    <FontAwesomeIcon
                      icon="exclamation-circle"
                      fixedWidth
                      className="icon text-danger cjl-input-icon-right"
                    />
                  </OverlayTrigger>
                )}
              </Box>

              <Box css={{ marginBottom: "15px" }}>
                <Inline css={{ justifyContent: "space-between" }}>
                  <JobLibFormLabel htmlFor="libraryDescription1">
                    CERTIFY COUNTRIES{" "}
                    <span style={{ fontWeight: "300" }}>(OPTIONAL)</span>
                  </JobLibFormLabel>
                  <div>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          id="cjl-label-dropdown-countries-selection"
                          text={`${store.selectedCountries.length} SELECTED `}
                        />
                      </DropdownMenuTrigger>
                      <DropdownMenuContent side="bottom">
                        <DropdownMenuItem
                          eventKey="1"
                          onSelect={store.onSelectAllCountries}
                        >
                          Select All ({store.filteredCountries.length} Countries)
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          eventKey="2"
                          onSelect={store.onSelectNoneCountries}
                        >
                          Clear Selection
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </Inline>
                <Inline css={{ position: "relative" }}>
                  <MultiDownshift
                    items={[...store.filteredCountries]}
                    selectedItems={[...store.selectedCountries]}
                    inputValue={store.countryFilterQuery}
                    itemToString={(country: CCCCountry) => country.title || ""}
                    onInputValueChange={store.onCountryFilterQueryChange}
                    onItemAdd={store.onAddSelectedCountry}
                    onItemRemove={store.onRemoveSelectedCountry}
                    renderItem={this.renderCountryItem}
                  />
                  {/*{Boolean(store.selectedCountriesError) && (*/}
                  {/*<OverlayTrigger placement="top" overlay={countriesErrorTooltip}>*/}
                  {/*<FontAwesomeIcon icon="exclamation-circle" fixedWidth className="icon text-danger cjl-input-icon-right" />*/}
                  {/*</OverlayTrigger>*/}
                  {/*)}*/}
                </Inline>
              </Box>

              <Box css={{ margin: "-5px 0 -10px 0" }}>
                <JobLibFormLabel htmlFor="libraryDescription">
                  DESCRIPTION <span style={{ fontWeight: "300" }}>(OPTIONAL)</span>
                </JobLibFormLabel>
                <TextArea
                  fill="true"
                  id="libraryDescription"
                  onChange={store.onDescriptionChange}
                  //   style={{ maxWidth: 424 }}
                  placeholder="In a few words, what is the purpose of this library?"
                />
              </Box>

              <Box css={{ marginBottom: "30px" }}>
                <JobLibFormLabel htmlFor="userMessage">
                  MESSAGE <span style={{ fontWeight: "300" }}>(OPTIONAL)</span>
                </JobLibFormLabel>
                <TextArea
                  fill="true"
                  id="userMessage"
                  onChange={store.onUserMessageChange}
                  style={{ maxWidth: 424 }}
                  placeholder="Any other information the support team should know about?"
                />
              </Box>
              <div className="cjl-button-bar center-items">
                <div>
                  <label
                    htmlFor="file-upload"
                    className="cjl-custom-file-upload-button cjl-label"
                  >
                    <FontAwesomeIcon icon="upload" className="btn-icon-left" />
                    {store.uploadButtonText}
                    <div
                      className="cjl-custom-file-upload-help"
                      style={{ marginLeft: 0 }}
                    >
                      <FontAwesomeIcon
                        icon="upload"
                        className="btn-icon-left"
                        style={{ visibility: "hidden" }}
                      />
                      Only csv, xls, or xlsx files.
                    </div>
                    <input
                      id="file-upload"
                      type="file"
                      onChange={store.onJobTitlesFileChange}
                      accept=".csv,.xls,.xlsx"
                    />
                  </label>
                </div>

                <div className="cjl-spacer" />

                <Button color="brand" size="large" onClick={store.submitRequest}>
                  Submit Request
                  <FontAwesomeIcon icon="chevron-right" />
                </Button>
              </div>
              <Link
                css={{ fontSize: "14px", fontWeight: "600" }}
                href={templateFile}
                download
              >
                <FontAwesomeIcon icon="file-excel-o" /> Download template
              </Link>

              <div
                className={classNames("loading-indicator-container", {
                  show: store.network.loading,
                })}
              >
                <LoadingIndicator
                  style={{
                    minHeight: 0,
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                />
              </div>
            </div>
          )}
        </JobLibCenteredForm>
        <ErrorModal
          modalState={store.errorModal}
          errorMessage={store.errorMessage}
          style={{ width: 600 }}
        />
      </Container>
    );
  }
}

export default observer(JobLibraryRequestCreate);
