//@flow
import React, { Component } from "react";
import type { Element } from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LibraryTitleList from "./LibraryTitleList";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import classNames from "classnames";
import Modal from "../../components/modals/Modal";
import ErrorModal from "../../components/modals/ErrorModal";
import MultiDownshift from "./components/MultiDownshift";
import CCCCountry from "../../models/CCCCountry";
import TextArea from "./components/TextArea";
import FlagIcon from "../../components/FlagIcon";
import ContainerSection from "../ratecards/components/ContainerSection";
import NonIdealState from "./components/NonIdealState";
import type MobXStore from "../../stores/mobx/MobXStore";
import Button from "../../components/lib/Button";
import Inline from "../../components/lib/Inline";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../components/lib/DropdownMenu";
import { styled } from "../../stitches.config";

const NewCountryCheckbox = styled("span", {
  color: "$accent",
});

type Props = {
  store: MobXStore,
  router: Object,
};

class LibraryTitleRequestCountryCreate extends Component<Props> {
  handleGoBack: () => void;

  constructor(props: Props) {
    super(props);

    this.handleGoBack = this.handleGoBack.bind(this);

    const store = props.store.jobLibraryTitleRequestCountryCreateStore;

    store.previousLocation = "/job-library/title-requests/create-country-request/";
    store.successModal.hideModal();
    store.reset();
  }

  componentDidMount() {
    const store = this.props.store.jobLibraryTitleRequestCountryCreateStore;
    store.router = this.props.router;
    store.load();
  }

  componentWillUnmount() {
    const jobLibraryTitleListStore = this.props.store.jobLibraryTitleListStore;
    jobLibraryTitleListStore.removeFilterBySelectedTitles(false);
  }

  handleGoBack() {
    // NOTE: old way to go back to reactr rate search page
    // if (this.props.location.query && this.props.location.query.b) {
    //   // get path to go back to
    //   const path = this.props.location.query["b"];
    //   // get session params
    //   const session = this.props.location.query["x-session-id"] || "";
    //   // get user params
    //   const userId = this.props.location.query["u"] || "";
    //
    //   window.location = path + "?x-session-id=" + session + "&s=" + session + "&u=" + userId;
    // } else {
    //   this.props.router.goBack();
    // }

    this.props.router.goBack();
  }

  renderCountryItem = (country: CCCCountry): Element<any> => {
    return (
      <span>
        {country.iso3166Alpha2 && <FlagIcon code={country.iso3166Alpha2} />}{" "}
        {country.title}
      </span>
    );
  };

  render() {
    const store = this.props.store.jobLibraryTitleRequestCountryCreateStore;
    const jobTitlesStore = this.props.store.jobLibraryTitleListStore;

    const additionalInfo =
      "If you would like to add additional information regarding either why you are " +
      "requesting this job title or pertinent information as to its importance; you may enter that here.";

    const countriesErrorTooltip = (
      <Tooltip id="titleErrorTooltip" placement="left">
        {store.selectedCountriesError}
      </Tooltip>
    );

    return (
      <div className="cjl-centered-view-container">
        <div className="cjl-centered-view create-custom-library">
          <div>
            <Inline
              css={{
                justifyContent: "flex-end",
                position: "absolute",
                top: "20px",
                right: "40px",
              }}
            >
              <Button color="brand" shape="rounded" onClick={store.handleSubmitRequest}>
                Submit Request{" "}
                <FontAwesomeIcon icon="chevron-right" style={{ marginLeft: 5 }} />
              </Button>
            </Inline>
            <h2>Request Countries</h2>
            <p className="cjl-p" style={{ marginTop: 10, marginBottom: 20 }}>
              To have your Job Titles available in other countries please submit the form
              below. You'll receive a notification via email when your new job is
              available.
            </p>

            {store.network.loading && (
              <NonIdealState
                icon="circle-notch"
                iconClasses="fa-spin"
                title="Loading"
                description="Please wait while data it's being transferred."
              />
            )}
            {!store.network.loading && (
              <div>
                <div className="form-group">
                  <div className="flex" style={{ justifyContent: "space-between" }}>
                    <label htmlFor="libraryDescription1" className="cjl-label">
                      CERTIFY COUNTRIES
                    </label>
                    <div className="cjl-label no-wrap">
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button
                            id="cjl-label-dropdown-countries-selection"
                            text={`${store.selectedCountries.length} SELECTED `}
                          />
                        </DropdownMenuTrigger>
                        <DropdownMenuContent side="bottom">
                          <DropdownMenuItem
                            eventKey="1"
                            onSelect={store.onSelectAllCountries}
                          >
                            Select All ({store.filteredCountries.length} Countries)
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            eventKey="2"
                            onSelect={store.onSelectNoneCountries}
                          >
                            Clear Selection
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  </div>
                  <div style={{ position: "relative" }}>
                    <MultiDownshift
                      items={[...store.filteredCountries]}
                      selectedItems={[...store.selectedCountries]}
                      inputValue={store.countryFilterQuery}
                      itemToString={(country: CCCCountry) => country.title || ""}
                      onInputValueChange={store.onCountryFilterQueryChange}
                      onItemAdd={store.onAddSelectedCountry}
                      onItemRemove={store.onRemoveSelectedCountry}
                      renderItem={this.renderCountryItem}
                    />
                    {Boolean(store.selectedCountriesError) && (
                      <OverlayTrigger placement="left" overlay={countriesErrorTooltip}>
                        <FontAwesomeIcon
                          icon="info-circle"
                          fixedWidth
                          className="icon text-danger cjl-input-icon-right"
                        />
                      </OverlayTrigger>
                    )}
                  </div>

                  <div className="flex">
                    <div
                      className="cjl-label"
                      style={{ fontSize: 12, marginRight: 5, cursor: "pointer" }}
                      onClick={store.onRequestNewCountriesChange}
                    >
                      <NewCountryCheckbox>
                        <FontAwesomeIcon
                          icon={store.requestNewCountries ? "check-square" : "square"}
                          className={classNames("none", {
                            selected: store.requestNewCountries,
                          })}
                        />
                      </NewCountryCheckbox>
                    </div>
                    <div
                      className="cjl-label"
                      style={{ fontSize: 12, cursor: "pointer" }}
                      onClick={store.onRequestNewCountriesChange}
                    >
                      Request New Country
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="newCountryTooltip">
                            When checked you'll be able to select countries that you don't
                            already have
                          </Tooltip>
                        }
                      >
                        <FontAwesomeIcon
                          icon="question-circle"
                          style={{ marginLeft: 5 }}
                        />
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>

                <TextArea
                  id="messageInput"
                  label={
                    <span>
                      MESSAGE <span className="cjl-thin">(OPTIONAL)</span>
                    </span>
                  }
                  value={store.userMessage}
                  onChange={store.onUserMessageChange}
                  minHeight={84}
                  fill
                  placeholder={additionalInfo}
                />
              </div>
            )}
            <div style={{ position: "relative", paddingTop: 10 }}>
              <label
                htmlFor="libraryDescription1"
                className="cjl-label"
                style={{ position: "absolute" }}
              >
                SELECT YOUR TITLES
              </label>
            </div>
            <LibraryTitleList
              store={jobTitlesStore}
              itemActions={[store.addRemoveClientJobTitleAction]}
            />
            <Inline css={{ justifyContent: "flex-end", padding: "10px 0" }}>
              <Button color="brand" shape="rounded" onClick={store.handleSubmitRequest}>
                Submit Request{" "}
                <FontAwesomeIcon icon="chevron-right" style={{ marginLeft: 5 }} />
              </Button>
            </Inline>
          </div>
        </div>
        <Modal
          show={store.confirmEmptyLibraryModal.show}
          onHide={store.handleConfirmEmptyLibraryCancel}
        >
          <div className="container-section header">
            <h4>Action Required</h4>
          </div>
          <div className="container-section">
            <p>
              No titles have been selected. To submit a request you must select at least
              one title.
            </p>
          </div>
          <div className="container-section footer">
            <div className="pull-right">
              {/*<button className="btn btn-lg btn-danger" onClick={store.handleConfirmEmptyLibrarySubmit}>*/}
              {/*Yes, create empty library*/}
              {/*</button>*/}
              <button
                className="btn btn-lg"
                onClick={store.handleConfirmEmptyLibraryCancel}
              >
                Select titles now
              </button>
            </div>
          </div>
        </Modal>
        <Modal show={store.successModal.show} onHide={store.successModal.hideModal}>
          <ContainerSection className="header">
            <h4>Success!</h4>
          </ContainerSection>
          <ContainerSection>
            <p>
              The Request has been submitted. We will contact you when the countries are
              available for search.
            </p>
          </ContainerSection>
          <ContainerSection className="footer">
            <Inline css={{ justifyContent: "flex-end" }}>
              <Button
                color="brand"
                size="large"
                onClick={() => {
                  store.successModal.hideModal();
                  store.reset();
                }}
              >
                Request Another Country
              </Button>{" "}
              <Button size="large" onClick={this.handleGoBack}>
                Go Back
              </Button>
            </Inline>
          </ContainerSection>
        </Modal>
        <ErrorModal modalState={store.errorModal} errorMessage={store.errorMessage} />
      </div>
    );
  }
}

export default observer(LibraryTitleRequestCountryCreate);
