// @flow strict
import * as React from "react";
import type MobXStore from "../../stores/mobx/MobXStore";
import type { FetchGraphQL, UpdateLoginState } from "../../App";
import SessionInfo from "../../models/SessionInfo";
import Message from "../../models/Message";
import { LoginBox, LoginPage } from "./LoginForm";
import TextInput from "../../components/lib/TextInput";
import Stack from "../../components/lib/Stack";
import Inline from "../../components/lib/Inline";
import Button from "../../components/lib/Button";

type Props = {
  store: MobXStore,
  router: Object,
  fetchGraphQL: FetchGraphQL,
  updateLoginState: UpdateLoginState,
};

type State = {
  username: string,
  email: string,
  password: string,
  passwordConfirm: string,
  firstName: string,
  lastName: string,
  clientId: string | null,
  showSignUpForm: boolean,
  signupMessages: Message[],
  loading: boolean,
  signupSuccess: boolean,
};

const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const signUpQuery = `
  mutation SignupUser($signupData : SignupInput) {
    signupUser(signupData:$signupData) {
      user {
        userId
        username
        firstName
        lastName
        termsOfAgreement
        roles
        legacySession
        client {
          id
          legacyId
          title
          legacyClient {
            perSearchPricing
            isClientJobLibrary
          }
        }
      }
      errors {
        __typename
        ...on UsernameAlreadyExistsError {
          message
        }
        ...on InvalidClientIdError {
          message
        }
        ...on InvalidPasswordError {
          message
        }
      }
    }
  }`;

type signupQueryResponse = {
  data?: ?{
    signupUser: ?{
      user: ?{
        userId: string,
        username: string,
        firstName: string,
        lastName: string,
        termsOfAgreement: boolean,
        roles: string[],
        legacySession: string,
        client: ?{
          id: string,
          legacyId: string,
          title: string,
          legacyClient: ?{
            perSearchPricing: boolean,
            isClientJobLibrary: boolean,
          },
        },
      },
      errors: {
        __typename: string,
        message: string,
      }[],
    },
  },
  errors?: {
    message: string,
  }[],
};

class SignupForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      password: "",
      passwordConfirm: "",
      firstName: "",
      lastName: "",
      clientId: this.props.router.params.id || null,
      showSignUpForm: false,
      signupMessages: [],
      loading: false,
      signupSuccess: false,
    };
  }

  componentDidMount() {
    this.setState({
      username: "",
      email: "",
      password: "",
      passwordConfirm: "",
      firstName: "",
      lastName: "",
      clientId: this.props.router.params.id || null,
      loading: false,
      signupSuccess: false,
    });
  }

  submit = (e: Event) => {
    e.preventDefault();
    let messages = [];

    const { username, password, passwordConfirm, email, firstName, lastName, clientId } =
      this.state;
    if (username === "") {
      messages.push(new Message("warning", "Username is required"));
    }
    if (password === "") {
      messages.push(new Message("warning", "Password is required"));
    }
    if (passwordConfirm === "" || passwordConfirm !== password) {
      messages.push(
        new Message("warning", "Password confirmation must be the same as password")
      );
    }
    if (email === "" || email.match(emailRegex) === null) {
      messages.push(new Message("warning", "Valid email required"));
    }
    if (firstName === "") {
      messages.push(new Message("warning", "First name is required"));
    }
    if (lastName === "") {
      messages.push(new Message("warning", "Last name is required"));
    }
    if (clientId === null || clientId === "") {
      messages.push(
        new Message(
          "danger",
          "Client ID is required, but should already be provided. Please make sure you're on the right page."
        )
      );
    }

    if (!this.canSubmit()) {
      this.setState({ signupMessages: messages });
      return; // Stop trying to login
    }

    const variables = {
      signupData: {
        username: this.state.username,
        password: this.state.passwordConfirm,
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        clientId: this.state.clientId,
      },
    };

    this.setState({ loading: true });
    this.props
      .fetchGraphQL(signUpQuery, variables)
      .then((data: signupQueryResponse) => {
        // Check for GraphQL level errors
        if (data.errors) {
          data.errors.forEach(({ message }) => {
            messages.push(new Message("danger", `An API error occurred: "${message}"`));
          });
        } else {
          // Check for data
          if (data.data && data.data.signupUser) {
            // Check mutation level errors
            if (data.data.signupUser.errors) {
              data.data.signupUser.errors.forEach((error) => {
                messages.push(new Message("danger", error.message));
              });
            } else {
              // Handle success!
              this.setState({ signupMessages: [], loading: false, signupSuccess: true });
              const sessionInfo = new SessionInfo(data.data.signupUser);
              this.props.updateLoginState(true, null, sessionInfo);
              setTimeout(() => {
                this.props.router.push("/");
              }, 3000);
              return;
            }
          }
        }
        this.setState({ signupMessages: messages, loading: false });
      })
      .catch((error: any) => {
        this.setState({ loading: false });
        console.error("Error handling login", error);
      });
  };

  canSubmit = () => {
    return (
      this.state.username !== "" &&
      this.state.password !== "" &&
      this.state.passwordConfirm !== "" &&
      this.state.passwordConfirm === this.state.password &&
      this.state.email !== "" &&
      this.state.firstName !== "" &&
      this.state.lastName !== "" &&
      this.state.clientId !== null &&
      this.state.clientId !== ""
    );
  };

  render() {
    return (
      <LoginPage showSignupButton={false}>
        <div className="pt-ui box-container">
          <form onSubmit={this.submit}>
            <LoginBox className="box-login" style={{ width: 500 }}>
              <div className="box-header">
                <div className="box-header-bg" />
                <h1>Sign Up</h1>
              </div>
              {!this.state.signupSuccess && (
                <Stack>
                  <Inline css={{ flexWrap: "nowrap", width: "100%" }}>
                    <TextInput
                      fill
                      name="firstname"
                      type="text"
                      maxLength="30"
                      placeholder="First Name"
                      value={this.state.firstName}
                      onChange={(e) => {
                        this.setState({ firstName: e.currentTarget.value });
                      }}
                    />
                    <TextInput
                      fill
                      name="lastname"
                      type="text"
                      maxLength="250"
                      placeholder="Last Name"
                      value={this.state.lastName}
                      onChange={(e) => {
                        this.setState({ lastName: e.currentTarget.value });
                      }}
                    />
                  </Inline>
                  <TextInput
                    fill
                    name="email"
                    type="email"
                    maxLength="250"
                    placeholder="Email"
                    value={this.state.email}
                    onChange={(e) => {
                      this.setState({ email: e.currentTarget.value });
                    }}
                  />
                  <TextInput
                    fill
                    name="username"
                    type="text"
                    maxLength="64"
                    placeholder="Username"
                    value={this.state.username}
                    onChange={(e) => {
                      this.setState({ username: e.currentTarget.value });
                    }}
                  />
                  <Inline css={{ flexWrap: "nowrap", width: "100%" }}>
                    <TextInput
                      fill
                      name="password"
                      type="password"
                      placeholder="Enter Password"
                      value={this.state.password}
                      onChange={(e) => {
                        this.setState({ password: e.currentTarget.value });
                      }}
                    />
                    <TextInput
                      fill
                      name="passwordConfirm"
                      type="password"
                      placeholder="Confirm password"
                      value={this.state.passwordConfirm}
                      onChange={(e) => {
                        this.setState({ passwordConfirm: e.currentTarget.value });
                      }}
                    />
                  </Inline>
                </Stack>
              )}
              {this.state.signupSuccess && (
                <>
                  <div className="alert alert-success fade in pt-user-alert">
                    <strong>Success!</strong>
                    <div>You'll be automatically redirected to your dashboard...</div>
                  </div>
                </>
              )}
              {this.state.signupMessages.map((message: Message, i: number) => (
                <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                  <a
                    href="#close"
                    className="close"
                    data-dismiss="alert"
                    aria-label="close"
                    onClick={() => {
                      // Copy and remove given index
                      let signupMessages = this.state.signupMessages.slice();
                      signupMessages.splice(i, 1);
                      this.setState({
                        signupMessages,
                      });
                    }}
                  >
                    &times;
                  </a>
                  <strong>{message.messageLabel}</strong>
                  {message.message}
                </div>
              ))}
              <div className="form-buttons">
                <Button
                  type="submit"
                  size="large"
                  color="brand"
                  text="Submit"
                  loading={this.state.loading}
                  disabled={this.state.loading || this.state.signupSuccess}
                />
              </div>
            </LoginBox>
          </form>
        </div>
      </LoginPage>
    );
  }
}

export default SignupForm;
