// @flow

import React, { Component } from "react";
import { observer } from "mobx-react";
import type MobXStore from "../../../stores/mobx/MobXStore";
import StitchesContainer from "../../../components/lib/Container";
import ContainerSection from "../../shared/ContainerSection";
import ErrorModal from "../../../components/modals/ErrorModal";
import InfoModal from "../../../components/modals/InfoModal";
import Modal from "../../../components/modals/Modal";
import Wizard, { WizardStep } from "../../../components/wizards/Wizard";
import WizardStep01SelectRateType from "./WizardStep01SelectRateType";
import WizardStep02TitlesAndLocations from "./WizardStep02TitlesAndLocations";
import WizardStep03SelectRateCard from "./WizardStep03SelectRateCard";
import WizardStep04Summary from "./WizardStep04Summary";
import InfiniteScrollSelect from "../../../components/selects/InfiniteScrollSelect";
import type { InfiniteScrollSelectProps } from "../../../components/selects/InfiniteScrollSelect";
import { ItemFilter } from "../../../stores/mobx/BatchSearchCreateStore";
import { Card, CardHeader, CardHeaderTitle } from "../../../components/lib/Card";
import { ModalButtons, ModalTitle } from "../../ratecards/components/FilterModal";
import TextInput from "../../../components/lib/TextInput";
import Button from "../../../components/lib/Button";

const FiltersInfiniteScrollSelect = (props: InfiniteScrollSelectProps<ItemFilter>) => (
  <InfiniteScrollSelect {...props} />
);

type Props = {
  store: MobXStore,
  router: {
    push: (any) => void,
    goBack: () => void,
  },
};

class Create extends Component<Props> {
  componentDidMount() {
    this.props.store.batchSearchPSPCreateStore.router = this.props.router;
  }

  componentWillUnmount() {
    this.props.store.batchSearchPSPCreateStore.showAddJobTitlesWizard = true;
    this.props.store.batchSearchPSPCreateStore.addTitleWizard.reset();
    this.props.store.batchSearchPSPCreateStore.wizard.reset();
    this.props.store.batchSearchPSPCreateStore.resetAll();
  }

  render() {
    const store = this.props.store.batchSearchPSPCreateStore;

    return (
      <StitchesContainer
        css={{
          paddingTop: "$4",
          paddingLeft: 0,
          paddingRight: 0,
          width: "100%",
        }}
      >
        <Card>
          <CardHeader>
            <CardHeaderTitle data-testid="create-batch-search-title">
              Create Batch Search
            </CardHeaderTitle>
          </CardHeader>

          <Wizard
            wizardState={store.wizard}
            validateStep={store.validateStep}
            onCurrentStepChange={store.onCurrentStepChange}
            onComplete={store.createAndRunBatchSearch}
            onCancel={store.cancel}
            lastStepButtonText="Run Batch Search"
          >
            <WizardStep title="Rate Type">
              <WizardStep01SelectRateType store={store} />
            </WizardStep>
            <WizardStep title="Titles and Locations">
              <WizardStep02TitlesAndLocations store={store} />
            </WizardStep>
            <WizardStep title="Rate Card">
              <WizardStep03SelectRateCard store={store} />
            </WizardStep>
            <WizardStep title="Summary">
              <WizardStep04Summary store={store} />
            </WizardStep>
          </Wizard>
        </Card>
        <ErrorModal modalState={store.errorModal} errorMessage={store.errorMessage} />
        <InfoModal modalState={store.infoModal} infoMessage={store.infoMessage} />
        <Modal
          show={store.jobsToSearchFiltersModal.show}
          onHide={store.jobsToSearchFiltersModal.hideModal}
        >
          <div className="container-section header">
            <ModalTitle>Filters</ModalTitle>
          </div>
          <ContainerSection>
            <p>Title:</p>
            <TextInput
              fill
              value={store.jobsToSearchTitleFilter}
              onChange={(e) =>
                store.onJobsToSearchTitleFilterChange(e.currentTarget.value)
              }
            />
          </ContainerSection>
          <ContainerSection>
            <p>Industry:</p>
            <FiltersInfiniteScrollSelect
              name="industry-filter-select"
              placeholder="Select an industry..."
              items={store.jobsToSearchAvailableIndustryFilters}
              selectedItem={store.jobsToSearchSelectedIndustryFilter}
              // inputValue={}
              // onInputValueChange={store.jobsTo}
              onSelectedItemChange={store.onJobsToSearchSelectedIndustryFilterChange}
              showSearchInput={false}
            />
          </ContainerSection>
          <div className="container-section footer">
            <ModalButtons>
              <Button color="brand" size="large" onClick={store.filterJobsToSearch}>
                Apply Filters
              </Button>
              <Button size="large" onClick={store.clearJobsToSearchFilters}>
                Clear Filters
              </Button>
              <Button size="large" onClick={store.jobsToSearchFiltersModal.hideModal}>
                Close
              </Button>
            </ModalButtons>
          </div>
        </Modal>
        <Modal
          show={store.bulkChangeIndustryModal.show}
          onHide={store.bulkChangeIndustryModalOnCancel}
        >
          <div className="container-section header">
            <ModalTitle>Industry Selection</ModalTitle>
          </div>
          <ContainerSection>
            <p>Industry:</p>
            <InfiniteScrollSelect
              name="bulk-change-industry-select"
              items={store.industries}
              selectedItem={store.bulkChangeIndustryModalSelectedIndustry}
              onSelectedItemChange={store.bulkChangeIndustryModalOnSelectedIndustryChange}
              inputValue={store.industrySearch}
              onInputValueChange={store.onIndustrySearchChange}
            />
          </ContainerSection>
          <div className="container-section footer">
            <ModalButtons>
              <Button
                color="brand"
                size="large"
                onClick={store.bulkChangeIndustryModalOnCommitChange}
              >
                Set Industry
              </Button>
              <Button size="large" onClick={store.bulkChangeIndustryModalOnCancel}>
                Close
              </Button>
            </ModalButtons>
          </div>
        </Modal>
        <Modal
          show={store.bulkRemoveJobsModal.show}
          onHide={store.bulkRemoveJobsModal.hideModal}
        >
          <div className="container-section header">
            <ModalTitle>Confirmation Needed</ModalTitle>
          </div>
          <div className="container-section">
            <p>Are you sure you want to remove all selected items?</p>
          </div>
          <div className="container-section footer">
            <ModalButtons>
              <Button
                color="danger"
                size="large"
                onClick={store.bulkRemoveJobsModalOnConfirm}
              >
                Yes, Remove Items
              </Button>
              <Button size="large" onClick={store.bulkRemoveJobsModal.hideModal}>
                No, Cancel
              </Button>
            </ModalButtons>
          </div>
        </Modal>
      </StitchesContainer>
    );
  }
}

export default observer(Create);
