// @flow
import React, { Component } from "react";
import type { Element } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react";
import { WizardState } from "./Wizard";
import { styled } from "../../stitches.config";

const StepTitle = styled("h3", {
  ".pt-ui &, &": {
    color: "$text",
    lineHeight: "40px",
    verticalAlign: "middle",
    paddingBottom: 10,
  },
});

const StepBullet = styled("div", {
  border: "4px solid $primaryLight",
  backgroundColor: "transparent",
  color: "$textLight",
  width: "40px",
  height: "40px",
  minWidth: "40px",
  minHeight: "40px",
  maxWidth: "40px",
  maxHeight: "40px",
  borderRadius: "50px",
  textAlign: "center",
  lineHeight: "32px",
  fontSize: "20px",
  verticalAlign: "middle",
  display: "inline-block",
  fontFamily: "monospace",
  fontWeight: "bold",
  variants: {
    active: {
      true: {
        borderColor: "$accentDark",
        backgroundColor: "$accentDark",
        // opacity: "0.7"
      },
    },
    completed: {
      true: {
        borderColor: "$brandDark",
        backgroundColor: "$brandDark",
        // opacity: "0.7"
      },
    },
  },
});

const StepLine = styled("div", {
  position: "relative",
  width: "0",
  border: "4px solid $primaryLight",
  margin: "10px auto 0 auto",
  height: "100%",
  borderTop: "2px solid transparent",
  variants: {
    active: {
      true: {
        borderColor: "$accentDark",
        borderTopColor: "transparent",
        opacity: "0.7",
      },
    },
    completed: {
      true: {
        borderColor: "$brandDark",
        borderTopColor: "transparent",
        opacity: "0.7",
      },
    },
  },
});

const StepLineTip = styled("div", {
  width: "0",
  margin: "auto",
  borderLeft: "4px solid transparent",
  borderRight: "4px solid transparent",
  borderTop: "5px solid $primaryLight",
  variants: {
    active: {
      true: {
        borderColor: "transparent",
        borderTopColor: "$accentDark",
        opacity: "0.7",
      },
    },
    completed: {
      true: {
        borderColor: "transparent",
        borderTopColor: "$brandDark",
        opacity: "0.7",
      },
    },
  },
});

type StepProps = {
  children: *,
  title: string | Element<any>,
  description?: string | Element<any>,
  stepNumber?: number,
  numbered?: boolean,
  first?: boolean,
  last?: boolean,
  active?: boolean,
  completed?: boolean,
  static?: boolean,
  showDescription?: boolean,
};

const VerticalWizardStep = observer(
  class extends Component<StepProps> {
    static defaultProps = {
      active: false,
      completed: false,
      static: false,
      numbered: false,
      first: false,
      last: false,
      showDescription: false,
    };

    render() {
      const {
        children,
        description,
        title,
        stepNumber,
        completed,
        active,
        numbered,
        last,
        showDescription,
      } = this.props;

      const classes = classNames("rc-vertical-wizard-step", {
        active: active,
        completed: completed,
      });

      return (
        <div className={classes}>
          <div
            className={classNames("step-description", { invisible: !showDescription })}
          >
            {description}
          </div>
          <div className="step-delimiter">
            {numbered ? (
              <StepBullet active={active} completed={completed}>
                {stepNumber}
              </StepBullet>
            ) : (
              <StepBullet active={active} completed={completed}>
                {active && <FontAwesomeIcon icon="arrow-right" fixedWidth />}
                {completed && <FontAwesomeIcon icon="check" fixedWidth />}
              </StepBullet>
            )}
            <StepLine active={active} completed={completed} />
            {!last && <StepLineTip active={active} completed={completed} />}
          </div>
          <div className="step-body">
            <StepTitle>{title}</StepTitle>
            <div className="step-content">{children}</div>
          </div>
        </div>
      );
    }
  }
);

type WizardProps = {
  children: *,
  wizardState: WizardState,
  validateStep: (number) => boolean,
  onCurrentStepChange?: (number) => void,
  onComplete?: () => void,
  numbered: boolean,
};

class LinearVerticalWizard extends Component<WizardProps> {
  static defaultProps = {
    numbered: false,
  };

  handleContinueButtonOnClick: () => void;
  handleGoBackButtonOnClick: () => void;
  handleGoToButtonOnClick: (number) => void;

  constructor(props: WizardProps) {
    super(props);

    this.handleContinueButtonOnClick = this.handleContinueButtonOnClick.bind(this);
    this.handleGoBackButtonOnClick = this.handleGoBackButtonOnClick.bind(this);
    this.handleGoToButtonOnClick = this.handleGoToButtonOnClick.bind(this);
  }

  handleContinueButtonOnClick(): void {
    const wizardState = this.props.wizardState;
    if (!this.props.validateStep(wizardState.currentStep)) return;

    if (wizardState.canGoForward) {
      wizardState.next();
      if (this.props.onCurrentStepChange) {
        this.props.onCurrentStepChange(wizardState.currentStep);
      }
    } else if (this.props.onComplete) {
      this.props.onComplete();
    }
  }

  handleGoBackButtonOnClick(): void {
    const wizardState = this.props.wizardState;

    if (wizardState.canGoBackward) {
      wizardState.previous();
      if (this.props.onCurrentStepChange) {
        this.props.onCurrentStepChange(wizardState.currentStep);
      }
    }
  }

  handleGoToButtonOnClick(step: number): void {
    const wizardState = this.props.wizardState;
    wizardState.goTo(step);
    if (this.props.onCurrentStepChange) {
      this.props.onCurrentStepChange(wizardState.currentStep);
    }
  }

  UNSAFE_componentWillMount() {
    const children = this.props.children;
    const steps = children.length ? children.length : 1;
    this.props.wizardState.setSteps(steps);
  }

  renderSteps(
    children: *,
    wizardState: WizardState,
    numbered: boolean = false
  ): Element<any>[] {
    const { currentStep } = wizardState;
    const steps: Element<any>[] = [];

    const childrenArray = children instanceof Array ? children : [children];
    childrenArray.forEach((wizStep: VerticalWizardStep, index: number) => {
      const stepProps = (wizStep.props: StepProps);
      const stepNumber = index + 1;
      const completed = stepNumber < currentStep;
      const active = stepNumber === currentStep;

      steps.push(
        // $FlowFixMe ignore incompatible type error for first param of cloneElement
        React.cloneElement(wizStep, {
          key: `step-${stepNumber}`,
          stepNumber: stepNumber,
          active: !stepProps.static && active,
          completed: stepProps.static || completed,
          numbered: numbered,
          first: stepNumber === 1,
          last: stepNumber === wizardState.steps,
        })
      );
    });

    return steps;
  }

  render() {
    const { children, wizardState } = this.props;

    // const renderPrevStep = Boolean(previousStep) && previousStep > 0 && previousStep !== currentStep;
    return (
      <div className="rc-vertical-wizard">
        <div className="rc-vertical-wizard-content">
          {this.renderSteps(children, wizardState)}
        </div>
      </div>
    );
  }
}

export default observer(LinearVerticalWizard);

export { VerticalWizardStep };
