import React, { useState, useCallback } from "react";
import { fromJS } from "immutable";

import Stack from "../../../components/lib/Stack";
import Box from "../../../components/lib/Box";
import Text from "../../../components/lib/Text";
import Icon from "../../../components/lib/Icon";
import TextInput from "../../../components/lib/TextInput";
import PromiseButton from "../../../components/lib/PromiseButton";
import ValidatorSelect from "./selects/ValidatorSelect";
import { ButtonGroupRight } from "../../../components/lib/ButtonGroup";
import { validateEmail, validateUserName } from "../../../utils/validation";

import type { ValidatorSelectFormDataMap } from "../types";
import type { FetchAPIFunc } from "../../../types/fetch";
import type { ShowModalFunc } from "../../../components/modals/MessageModal";

type ValidatorSelectFormState = {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
};

type ValidatorSelectFormProps = {
  fetchArgusAPI: FetchAPIFunc;
  showModalError: ShowModalFunc;
  onSelect: (data: ValidatorSelectFormDataMap) => Promise<void>;
};

const ValidatorSelectForm = (props: ValidatorSelectFormProps) => {
  const { fetchArgusAPI, showModalError, onSelect } = props;

  const [formState, setFormState] = useState<ValidatorSelectFormState>({
    firstName: null,
    lastName: null,
    email: null,
  });

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // utils

  const validateInputs = useCallback(
    (firstName: string, lastName: string, email: string) => {
      if (!firstName || !lastName || !email) {
        setErrorMessage("all credentials should be provided");
        return false;
      }

      if (!validateUserName(firstName) || !validateUserName(lastName)) {
        setErrorMessage(
          `"First Name" or "Last Name" must start with a letter and contain only letters, spaces, dashes, or numbers.`
        );
        return false;
      }

      if (!validateEmail(email)) {
        setErrorMessage("bad email provided");
        return false;
      }

      return true;
    },
    []
  );

  // handlers

  const handleFirstNameChange = useCallback(
    (e) => {
      const value = e.target.value;
      if (value !== formState.firstName) {
        setFormState((prevData) => ({
          ...prevData,
          firstName: value,
        }));
      }
    },
    [formState.firstName]
  );

  const handleLastNameChange = useCallback(
    (e) => {
      const value = e.target.value;
      if (value !== formState.lastName) {
        setFormState((prevData) => ({
          ...prevData,
          lastName: value,
        }));
      }
    },
    [formState.lastName]
  );

  const handleEmailChange = useCallback(
    (e) => {
      const value = e.target.value;
      if (value !== formState.email) {
        setFormState((prevData) => ({
          ...prevData,
          email: value,
        }));
      }
    },
    [formState.email]
  );

  const handleCreateFromInputs = useCallback(async () => {
    const firstName = (formState.firstName || "").trim();
    const lastName = (formState.lastName || "").trim();
    const email = (formState.email || "").trim().toLowerCase();

    if (validateInputs(firstName, lastName, email) && onSelect) {
      return await onSelect(
        fromJS({
          first_name: firstName,
          last_name: lastName,
          email: email,
        })
      );
    }
  }, [
    formState.firstName,
    formState.lastName,
    formState.email,
    onSelect,
    validateInputs,
  ]);

  // render

  const hasErrors = !!errorMessage;

  return (
    <Stack fill css={{ gap: "$1", alignItems: "start" }}>
      <Stack fill css={{ gap: "$2", alignItems: "start" }}>
        <Text as="h4" css={{ color: "$brand" }}>
          Create permission object for the validator you know
        </Text>
        <Text thin>
          <Icon icon="info-circle" />
          &nbsp; required fields marked with *
        </Text>

        <Box fill>
          <label>First Name*:</label>
          <TextInput
            fill
            value={formState.firstName || ""}
            onChange={handleFirstNameChange}
            color={hasErrors ? "danger" : "primary"}
          />
        </Box>

        <Box fill>
          <label>Last Name*:</label>
          <TextInput
            fill
            value={formState.lastName || ""}
            onChange={handleLastNameChange}
            color={hasErrors ? "danger" : "primary"}
          />
        </Box>

        <Box fill>
          <label>Email*:</label>
          <TextInput
            fill
            value={formState.email || ""}
            onChange={handleEmailChange}
            color={hasErrors ? "danger" : "primary"}
          />
        </Box>

        <ButtonGroupRight fill nowrap css={{ marginTop: "$4" }}>
          {errorMessage && (
            <Text thin color="negative" css={{ marginRight: "$2" }}>
              {errorMessage}
            </Text>
          )}
          <PromiseButton size="large" color="brand" onClick={handleCreateFromInputs}>
            <Text nowrap>Create Permissions</Text>
          </PromiseButton>
        </ButtonGroupRight>
      </Stack>

      <Stack fill css={{ gap: "$1", alignItems: "start" }}>
        <Text as="h4" css={{ color: "$brand" }}>
          Or search for validator in the system
        </Text>
        <Text thin>
          <Icon icon="info-circle" />
          &nbsp; Here could be several rows addressed to the same email (validator). Try
          to choose the one with correct email value.
        </Text>
        <ValidatorSelect
          includeNewValidators
          onSelect={onSelect}
          fetchArgusAPI={fetchArgusAPI}
          showModalError={showModalError}
        />
      </Stack>
    </Stack>
  );
};
ValidatorSelectForm.displayName = "ValidatorSelectForm";

export default ValidatorSelectForm;
