// @flow

import { action, extendObservable, observable } from "mobx";
import moment from "moment";
import SessionInfo from "./SessionInfo";
import type { PieChartPayloadDataItem } from "./VisualizationsState/JobsByTagPieState";
import { ScatterPlotChartPayloadData } from "./VisualizationsState/PayRateBillRateScatterPlotState";
import TagList from "./TagList";

export class RateCardVisualizations {
  jobsByCategory: ?(PieChartPayloadDataItem[]);
  payRateBillRateByLevel: ?ScatterPlotChartPayloadData;

  constructor(object: Object) {
    this.jobsByCategory = object.jobsByCategory || [];
    this.payRateBillRateByLevel = object.payRateBillRateByLevel;
  }
}

export class CountryFilterCriterion {
  id: string;
  country: string;
  isoCode: string;

  constructor(payloadItem: Object) {
    if (payloadItem) {
      this.id = encodeURIComponent(payloadItem.country);
      this.country = payloadItem.country;
      this.isoCode = payloadItem.isoCode;
    }
  }

  toString() {
    return this.country;
  }
}

export class LevelFilterCriterion {
  id: number;
  romanNumeral: string;
  literal: string;

  constructor(payloadItem: Object) {
    if (payloadItem) {
      this.id = payloadItem.legacyId;
      this.romanNumeral = payloadItem.romanNumeral;
      this.literal = payloadItem.literal;
    }
  }

  toString() {
    return "Level " + this.romanNumeral;
  }
}

export class LimitFilterCriterion {
  id: number;
  limit: number;

  constructor(limit: number) {
    this.id = limit;
    this.limit = limit;
  }

  toString() {
    return "Top " + this.limit.toString();
  }
}

export class RateTypeFilterCriterion {
  id: string;
  name: string;

  constructor(rateType: string) {
    this.id = encodeURIComponent(rateType);
    this.name = rateType;
  }

  toString() {
    return this.name;
  }
}

// type RateCardData = {
//   legacyId: ?string,
//   name: ?string,
//   shared: boolean,
//   searchesHaveAllLevels: boolean,
//   canConvertToUsd: boolean,
//   ownerUserId: number,
//   ownerFirstName: string,
//   ownerLastName: string,
//   ownerClientId: ?number,
//   createdDate: string,
//   owner?: {
//     id: ?number,
//     firstName?: ?string,
//     middleName?: ?string,
//     lastName?: ?string,
//     clientId: ?number,
//     email: ?string
//   }
// };

export default class RateCard {
  store: Object;
  rateCard: Object; // original data object
  id: string;
  ratecardId: string;
  name: ?string;
  owner: ?{
    userId: ?number,
    fullname: ?string,
    clientId: ?number,
    email: ?string,
  };
  mine: boolean;
  searches: ?any;
  searchCount: ?number;
  locationCount: ?number;
  shared: boolean;
  searchesHaveAllLevels: boolean;
  canConvertToUsd: boolean;
  createdDate: ?Object;
  createdDisplay: ?string;
  tags: Array<TagList>;
  viewState: {
    selected: boolean,
    editing: boolean,
    expanded: boolean,
  };
  toggleEdit: () => void;
  toggleSelected: (
    e: Object,
    ignore1?: ?string,
    ignore2?: ?string,
    setValue?: ?boolean
  ) => void;
  destroy: () => void;
  toString: () => string;

  constructor(store: Object, object: Object, sessionInfo?: ?SessionInfo = null) {
    this.store = store;

    this.name = null;
    this.owner = {
      id: 0,
      userId: 0,
      fullname: null,
      clientId: null,
      email: null,
    };
    this.mine = false;
    this.searches = null;
    this.shared = false;
    this.searchesHaveAllLevels = true;
    this.canConvertToUsd = false;
    this.createdDate = null;
    this.createdDisplay = null;
    this.locationCount = 0;
    this.searchCount = 0;
    this.viewState = {
      selected: false,
      editing: false,
      expanded: false,
    };

    if (!object) throw new Error("Cannot instantiate RateCard without data!");
    if (!object.ratecardId) throw new Error("Cannot instantiate RateCard without an ID!");

    this.rateCard = object;
    this.ratecardId = object.ratecardId;
    this.id = this.ratecardId;
    this.first = 10;
    this.name = object.name || null;
    this.shared = object.shared || false;
    this.searchesHaveAllLevels = object.searchesHaveAllLevels || false;
    this.canConvertToUsd = object.canConvertToUsd;
    if (object.owner && object.owner.firstName) {
      this.owner = {
        id: object.owner.userId || 0,
        userId: object.owner.userId || 0,
        fullname: `${object.owner.firstName} ${object.owner.lastName}` || null,
        clientId: object.owner.userId || null,
        email: object.owner.email || null,
      };
    } else {
      this.owner = object.owner;
    }

    if (this.owner && sessionInfo && sessionInfo.user) {
      this.mine = this.owner.id === sessionInfo.user.userId;
    }

    this.createdDate = moment(object.createdDate);
    this.createdDisplay = `Created On ${this.createdDate.format("MMMM D, YYYY")}`;
    this.locationCount = object.locationCount;
    this.searchCount = object.searchCount || 0;
    const tags = object.tags || [];

    extendObservable(this, {
      visualizations: observable(object.visualizations),
      tags: tags.map((tag) => new TagList(this, tag)),
    });

    this.toggleEdit = action(this.toggleEdit.bind(this));
    this.toggleSelected = action(this.toggleSelected.bind(this));
    this.destroy = action(this.destroy.bind(this));
  }

  toggleEdit() {
    const viewState = this.store.rateCardsViewState.get(this.ratecardId);

    viewState.editing = !viewState.editing;
  }

  toggleSelected(
    e: Object,
    ignore1: ?string = null,
    ignore2: ?string = null,
    setValue: ?boolean = null
  ) {
    const viewState = this.store.rateCardsViewState.get(this.ratecardId);
    // console.log("toogleSelected:", this.name, "->", viewState.selected);

    if (setValue !== null) {
      viewState.selected = setValue;
      return;
    } else {
      viewState.selected = !viewState.selected;

      if (!viewState.selected && this.store.allSelected) {
        this.store.allSelected = false;
      }
    }

    if (viewState.selected === false) {
      this.store.unSelectedRateCard.push(this.rateCard.ratecardId.toString());
    } else {
      for (let i = 0; i < this.store.unSelectedRateCard.length; i++) {
        if (this.store.unSelectedRateCard[i] === this.ratecardId) {
          this.store.unSelectedRateCard.splice(i, 1);
        }
      }
    }

    if (!this.store.allowMultipleItemSelection) {
      // deselect all other rate cards
      this.store.rateCardsViewState.forEach((viewState) => {
        if (this.viewState === viewState) return;

        viewState.selected = false;
      });
    }
    this.store.SingleSelectedRateCard(this.rateCard);
  }

  destroy() {
    this.store.rateCards.remove(this);
  }

  toJS() {
    return {
      ratecardId: this.ratecardId,
      name: this.name,
    };
  }

  toString() {
    return this.name || "<-- Unknown Name -->";
  }

  static fromJS(store, object) {
    return new RateCard(store, object);
  }
}
