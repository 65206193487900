import React, { useState, useEffect, useCallback } from "react";
import { Set } from "immutable";

import Icon from "../../../components/lib/Icon";
import CurrencySelect, { CurrencyData } from "../../../components/selects/CurrencySelect";
import TextCrop from "../../validator5K_admin/components/TextCrop";
import InputsPair from "../../validator5K_admin/components/InputsPair";
import InputsList from "../../validator5K_admin/components/InputsList";
// @ts-expect-error
import SingleSelect from "../../../components/selects/SingleSelect";
import ClientSelect from "../../validator5K_admin/components/selects/ClientSelect";
import TextInput from "../../../components/lib/TextInput";
import { usePLIContext } from "../context";
import Text from "../../../components/lib/Text";
import Tooltip from "../lib/Tooltip";
import Stack from "../../../components/lib/Stack";
import Box from "../../../components/lib/Box";
import Inline from "../../../components/lib/Inline";
import Grid from "../../../components/lib/Grid";
import TextArea from "../../../components/lib/TextArea";
import PromiseButton from "../../../components/lib/PromiseButton";
import InlineValuePicker, {
  InlineMultiValuePicker,
  PickerOptionObject,
} from "../../../components/pickers/InlineValuePicker";
import { Button } from "../../../components/lib/Button";
import { ButtonGroupRight } from "../../../components/lib/ButtonGroup";
import { RadioGroup, RadioGroupItem } from "../../../components/lib/RadioGroup";
import {
  Checkbox,
  CheckboxIndicator,
  CheckboxItem,
} from "../../../components/lib/Checkbox";
import NumberInput from "../../../components/lib/NumberInput";
import {
  decimalFormatter,
  reactSelectStyles,
} from "../../../views/validator5K_admin/constants";
import {
  classicSkillsLevelsInfoText,
  threeLevelBandingSkillsLevelsInfoText,
} from "../../validator5K_admin/components/Rates";
import { QuestionsEditor } from "../../validator5K_admin/components/QuestionsTable";
import {
  reviewsListToImmutableList,
  questionsListToImmutableOrderedMap,
} from "../../validator5K_admin/dataConverters";
import {
  REVIEW_TYPES,
  REVIEW_TYPES_LABELS,
  REVIEW_TYPES_OPTIONS,
  CONTINGENT_RATE_TYPES,
  CREATE_REVIEW_WIZARD_PAGES,
  CONTINGENT_RATE_TYPES_LABELS,
  LEVELS_PICKER_OPTIONS,
  LEVELS_PICKER_OPTIONS_SHORT,
  RATES_PICKER_OPTIONS,
} from "../../validator5K_admin/types";
import { useProgramContext } from "../ProgramDataProvider";
import { useStoredIndexContext } from "../StoredIndexDataProvider";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";
import { styled } from "../../../stitches.config";
import { emptyList, emptyOrderedMap } from "../constants";

import type {
  REVIEW_TYPES_TYPE,
  CONTINGENT_RATE_TYPES_TYPE,
  CREATE_REVIEW_WIZARD_PAGES_TYPE,
  QuestionsOrderedMap,
} from "../../validator5K_admin/types";
import type { ImmutableList, ImmutableSet } from "../../../types/immutable";
import type { FetchAPIResponse } from "../../../types/fetch";
import type { ValueType } from "react-select/lib/types";
import type { JobsValidationsOrderedMap, SKILLS_LEVELS_TYPE } from "../types";
import type {
  ClientData,
  ClientSelectProps,
} from "../../validator5K_admin/components/selects/ClientSelect";
import type { QuestionDataObject } from "../../validator5K_admin/types";

export type ReviewTypeOption = {
  value: REVIEW_TYPES_TYPE;
  label: string;
};

export const StepID = styled("span", {
  fontSize: "$4xl",
  fontWeight: "$semibold",
  color: "$brandLight",
  paddingRight: "$8 !important",
  alignSelf: "flex-start",

  "@md": {
    padding: "0 $8 !important",
  },
});

export const Label = styled(Text, {
  fontWeight: "$extralight", // thin: true
  display: "block",
  color: "$primaryDarkest",
});

export const Content = styled(Stack, {
  alignItems: "flex-start",
  gap: "$2",
  width: "$full",

  "& > div:last-child": {
    width: "$full",
  },
  "& > div:nth-child(2)": {
    width: "$full",
  },
});

export const Container = styled(Inline, {
  alignItems: "flex-start",
  flexWrap: "nowrap",
  gap: "$1",
  "@md": {
    gap: 0,
  },
});

const defaultAttemptsNumber = 5;
const defaultReviewTypeValue: { value: REVIEW_TYPES_TYPE; label: string } = {
  value: REVIEW_TYPES.CLASSIC,
  label: REVIEW_TYPES_LABELS[REVIEW_TYPES.CLASSIC],
};

const allPossibleLevelsValue = Set(
  (Object.values(LEVELS_PICKER_OPTIONS) as PickerOptionObject<number>[]).map(
    (option) => option.value
  )
) as unknown as ImmutableSet<number>;
const defaultSelectedLevelsValue = allPossibleLevelsValue;
const defaultThreeLevelBandingSelectedLevelsValues = Set(
  (Object.values(LEVELS_PICKER_OPTIONS_SHORT) as PickerOptionObject<number>[]).map(
    (option) => option.value
  )
) as unknown as ImmutableSet<number>;

const defaultSelectedRatesValue = RATES_PICKER_OPTIONS.PAY_RATE_ONLY
  .value as unknown as ImmutableSet<string>;
const allPossibleRatesValue = RATES_PICKER_OPTIONS.ALL
  .value as unknown as ImmutableSet<string>;
const defaultHourlyRateMultiplier = 1;
const defaultDailyRateMultiplier = 8;
const defaultWeeklyRateMultiplier = 40;
const defaultMonthlyRateMultiplier = 160;

const replacementsTooltipContent = (
  <Text centered as={Box} css={{ width: 200 }}>
    <strong>Use words replacements in order to hide sensitive client's data.</strong>
    <br />
    <span>This mechanism only able to swap whole words.</span>
  </Text>
);

type SubmitContractorsWizardPageProps = {
  jobsData: JobsValidationsOrderedMap;
  onChangeWizardPage: (wizardPage: CREATE_REVIEW_WIZARD_PAGES_TYPE) => void;
  onGoBack: () => void;
};

type ReplacementItem = ImmutableList<string>;
type ReplacementsList = ImmutableList<ReplacementItem>;

interface SubmitContractorsWizardPageState {
  uploadName: string | null;
  clientData: ClientData;
  reviewType: ReviewTypeOption;
  reviewTitle: string | null;
  isThreeLevelBanding: boolean;
  selectedLevels: ImmutableSet<number>;
  selectedRates: ImmutableSet<string>;
  displayRateType: CONTINGENT_RATE_TYPES_TYPE;
  displayCurrency: CurrencyData | null;
  hourlyRateMultiplier: number;
  dailyRateMultiplier: number | null;
  weeklyRateMultiplier: number | null;
  monthlyRateMultiplier: number | null;
  replacements: ReplacementsList;
  questions: QuestionsOrderedMap;
  predefinedQuestions: QuestionsOrderedMap;
  isFreeValidation: boolean;
  isActive: boolean;
  rewardPerJob: number | null;
  maxAttemptsNumber: number | null;
  instructions: string | null;
  created: boolean;
}

const SubmitContractorsWizardPage = (props: SubmitContractorsWizardPageProps) => {
  const { jobsData, onChangeWizardPage, onGoBack } = props;
  const jobsTotal = jobsData.size;

  // context

  const { programId, programTitle } = useProgramContext();
  const { indexId, indexTitle } = useStoredIndexContext();
  const {
    currenciesData,
    clientId,
    clientName,
    showModalWarning,
    showModalError,
    showModalSuccess,
    fetchArgusAPI,
    fetchGraphQL,
  } = usePLIContext();

  // state

  const [pageState, setPageState] = useState<SubmitContractorsWizardPageState>({
    uploadName:
      [clientName, programTitle, indexTitle].filter((i) => i).join(": ") || null,
    clientData: { clientId, clientName },
    reviewType: defaultReviewTypeValue,
    reviewTitle: null,
    isThreeLevelBanding: false,
    selectedLevels: defaultSelectedLevelsValue,
    selectedRates: defaultSelectedRatesValue,
    displayRateType: CONTINGENT_RATE_TYPES.HOURLY,
    displayCurrency: null,
    hourlyRateMultiplier: defaultHourlyRateMultiplier,
    dailyRateMultiplier: defaultDailyRateMultiplier,
    weeklyRateMultiplier: defaultWeeklyRateMultiplier,
    monthlyRateMultiplier: defaultMonthlyRateMultiplier,
    replacements: emptyList,
    questions: emptyOrderedMap as unknown as QuestionsOrderedMap,
    predefinedQuestions: emptyOrderedMap as unknown as QuestionsOrderedMap,
    isFreeValidation: false,
    isActive: true,
    rewardPerJob: null,
    maxAttemptsNumber: defaultAttemptsNumber,
    instructions: null,
    created: false,
  });

  // fetch data

  const fetchPredefinedQuestionsList = useCallback(async () => {
    try {
      const response: FetchAPIResponse<QuestionDataObject[]> = await fetchArgusAPI(
        "predefined-questions/"
      );
      const data = questionsListToImmutableOrderedMap(response.data);
      if (data?.size) {
        setPageState((prevPageState) => ({
          ...prevPageState,
          predefinedQuestions: data,
          questions: data,
        }));
      }
    } catch (err: any) {
      logAsyncOperationError("fetchPredefinedQuestionsList", err);
      showModalError("Error occurred while retrieving predefined questions list.");
    }
  }, [fetchArgusAPI, showModalError]);

  // effects

  useEffect(() => {
    if (pageState.predefinedQuestions.size === 0) {
      fetchPredefinedQuestionsList();
    }
  }, [fetchPredefinedQuestionsList, pageState.predefinedQuestions.size]);

  // handlers

  const handleIsFreeChange = useCallback(() => {
    setPageState((prevPageState) => ({
      ...prevPageState,
      isFreeValidation: !pageState.isFreeValidation,
      rewardPerJob: pageState.isFreeValidation ? pageState.rewardPerJob : null,
    }));
  }, [pageState.isFreeValidation, pageState.rewardPerJob]);

  const handleThreeLevelBandingChange = useCallback(() => {
    const newValue = !pageState.isThreeLevelBanding;

    setPageState((prevPageState) => ({
      ...prevPageState,
      isThreeLevelBanding: newValue,
      selectedLevels: newValue
        ? defaultThreeLevelBandingSelectedLevelsValues
        : defaultSelectedLevelsValue,
    }));
  }, [pageState.isThreeLevelBanding]);

  const handleSelectedLevelsChange = useCallback(
    (value: SKILLS_LEVELS_TYPE) => {
      if (!value) return;

      let selectedLevels = pageState.selectedLevels;

      selectedLevels = selectedLevels.includes(value)
        ? selectedLevels.delete(value)
        : selectedLevels.add(value);
      if (!selectedLevels.size) {
        selectedLevels = selectedLevels.add(LEVELS_PICKER_OPTIONS.SENIOR.value);
        showModalWarning("You should provide at least one expertise level.", "Hint!");
      }
      setPageState((prevPageState) => ({ ...prevPageState, selectedLevels }));
    },
    [pageState.selectedLevels, showModalWarning]
  );

  const handleReviewTypeChange = useCallback(
    (value: ReviewTypeOption) => {
      const nextPageState: Partial<SubmitContractorsWizardPageState> = {
        reviewType: value,
        isThreeLevelBanding: false,
      };

      if (value.value === REVIEW_TYPES.FILL_THE_BLANK) {
        nextPageState["displayRateType"] = CONTINGENT_RATE_TYPES.HOURLY;
        nextPageState["hourlyRateMultiplier"] = defaultHourlyRateMultiplier;
        nextPageState["dailyRateMultiplier"] = defaultDailyRateMultiplier;
        nextPageState["weeklyRateMultiplier"] = defaultWeeklyRateMultiplier;
        nextPageState["monthlyRateMultiplier"] = defaultMonthlyRateMultiplier;
      }

      if (value.value !== REVIEW_TYPES.ORDERED) {
        nextPageState["questions"] = pageState.predefinedQuestions;
      }

      if (value.value === REVIEW_TYPES.FILL_THE_BLANK) {
        nextPageState["selectedLevels"] = allPossibleLevelsValue;
        nextPageState["selectedRates"] = allPossibleRatesValue;
      } else if (value.value === REVIEW_TYPES.CLASSIC) {
        nextPageState["selectedLevels"] = defaultSelectedLevelsValue;
        nextPageState["selectedRates"] = defaultSelectedRatesValue;
      } else if (value.value === REVIEW_TYPES.ORDERED) {
        nextPageState["selectedLevels"] = allPossibleLevelsValue;
        nextPageState["selectedRates"] = allPossibleRatesValue;
        nextPageState["questions"] = emptyList;
      }

      setPageState((prevPageState) => ({ ...prevPageState, ...nextPageState }));
    },
    [pageState.predefinedQuestions]
  );

  const handleSubmit = () => {
    const {
      clientData,
      uploadName,
      reviewType,
      isFreeValidation,
      rewardPerJob,
      displayRateType,
      dailyRateMultiplier,
      weeklyRateMultiplier,
      monthlyRateMultiplier,
      displayCurrency,
      selectedLevels,
      selectedRates,
      reviewTitle,
      isThreeLevelBanding,
      maxAttemptsNumber,
      replacements,
      questions,
      instructions,
      isActive,
    } = pageState;

    if (!jobsData || !jobsTotal) {
      showModalError("No uploaded jobs data found.");
      return Promise.resolve();
    }

    if (!uploadName) {
      showModalWarning("Please provide the Upload Name.", "Missing Upload Name!");
      return Promise.resolve();
    }

    if (
      reviewType &&
      reviewType.value === REVIEW_TYPES.CLASSIC &&
      (!selectedLevels || !selectedLevels.size)
    ) {
      showModalWarning(
        "Please select Expertise Levels to be included in new surveys.",
        "Missing Exp Levels Parameters!"
      );
      return Promise.resolve();
    }

    if (!isFreeValidation && !rewardPerJob) {
      showModalWarning(
        "Please enter Reward Per Job value or otherwise mark surveys as Free.",
        "Missing Reward Parameters!"
      );
      return Promise.resolve();
    }

    let displayRateTypeData = {};
    let displayCurrencyData = {};

    if (
      reviewType.value === REVIEW_TYPES.CLASSIC ||
      reviewType.value === REVIEW_TYPES.ORDERED
    ) {
      displayRateTypeData = {
        display_rate_type: displayRateType,
        daily_multiplier: dailyRateMultiplier || defaultDailyRateMultiplier,
        weekly_multiplier: weeklyRateMultiplier || defaultWeeklyRateMultiplier,
        monthly_multiplier: monthlyRateMultiplier || defaultMonthlyRateMultiplier,
      };
    }

    if (displayCurrency && Object.keys(displayCurrency).length) {
      displayCurrencyData = {
        display_currency_id: displayCurrency["id"],
        display_currency_code: displayCurrency["code"],
        display_currency_name: displayCurrency["name"],
        display_currency_symbol: displayCurrency["symbol"],
      };
    }

    return fetchArgusAPI("reviews/create_from_private_index/", {
      method: "post",
      data: {
        client_id: clientData?.clientId,
        client_name: clientData?.clientName,
        program_id: programId,
        program_name: programTitle,
        stored_index_id: indexId,
        stored_index_name: indexTitle,
        contractors_data: jobsData.toList().toJS(),
        upload_name: uploadName,
        review_type: reviewType.value,
        review_title: reviewTitle,
        is_three_level_banding: isThreeLevelBanding,
        required_levels: selectedLevels.toJS(),
        required_rates: selectedRates.toJS(),
        free_validation: isFreeValidation,
        is_active: isActive,
        reward_per_job: rewardPerJob,
        max_attempts: maxAttemptsNumber || defaultAttemptsNumber,
        replacements: replacements.toJS(),
        questions: questions
          .filter((q) => q.get("included", false))
          .toList()
          .toJS(),
        instructions,
        ...displayRateTypeData,
        ...displayCurrencyData,
      },
    })
      .then((response) => {
        const data = reviewsListToImmutableList(response.data);
        if (data?.size) showModalSuccess(`${data.size} surveys successfully created.`);
        setPageState((prevStatePage) => ({ ...prevStatePage, created: true }));
      })
      .catch((err: any) => {
        logAsyncOperationError("createSurveysList", err);
        showModalError("Error occurred while creating surveys list.");
      });
  };

  const handleChange = useCallback(
    (updatePageState: Partial<SubmitContractorsWizardPageState>) => {
      setPageState((prevPageState) => ({
        ...prevPageState,
        ...updatePageState,
      }));
    },
    []
  );

  const handleChangeClient: ClientSelectProps["onChange"] = useCallback(
    (value) => {
      handleChange({ clientData: value });
    },
    [handleChange]
  );

  return (
    <Stack
      fill
      css={{
        alignItems: "stretch",
        gap: "$6",
      }}
    >
      <Container>
        <StepID>3</StepID>
        <Content>
          <Label>Name this upload *:</Label>
          <TextInput
            css={{ width: "300px" }}
            size="small"
            value={pageState.uploadName ?? ""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange({ uploadName: e.target.value })
            }
          />
        </Content>
      </Container>

      <Container>
        <StepID>4</StepID>
        <Content>
          <Label>Select a client, to associate resulting surveys with it:</Label>
          <ClientSelect
            className="full-width"
            fetchGraphQL={fetchGraphQL}
            value={pageState.clientData}
            onChange={handleChangeClient}
            styles={reactSelectStyles}
          />
        </Content>
      </Container>

      <Container>
        <StepID>5</StepID>
        <Content>
          <Label>Select the type of survey to create:</Label>
          <SingleSelect
            className="full-width"
            options={REVIEW_TYPES_OPTIONS}
            value={pageState.reviewType}
            onChange={handleReviewTypeChange}
            styles={reactSelectStyles}
          />
          {pageState.reviewType.value === REVIEW_TYPES.CLASSIC && (
            <>
              <Label>
                Select levels to be included into new surveys&nbsp;
                <TextCrop
                  mode="modal"
                  title="Depth Of Capability"
                  label={<Icon icon={["far", "question-circle"]} />}
                  fullText={
                    pageState.isThreeLevelBanding
                      ? threeLevelBandingSkillsLevelsInfoText
                      : classicSkillsLevelsInfoText
                  }
                  hint="Click to see details"
                />
                &nbsp;:
              </Label>
              <CheckboxItem
                checked={pageState.isThreeLevelBanding}
                onCheckedChange={handleThreeLevelBandingChange}
              >
                Use 3 level banding
              </CheckboxItem>

              <InlineMultiValuePicker
                options={
                  pageState.isThreeLevelBanding
                    ? Object.values(LEVELS_PICKER_OPTIONS_SHORT)
                    : Object.values(LEVELS_PICKER_OPTIONS)
                }
                label="Levels"
                value={pageState.selectedLevels.toArray()}
                onChange={handleSelectedLevelsChange}
              />
            </>
          )}
          {pageState.reviewType.value === REVIEW_TYPES.CLASSIC && (
            <>
              <Label>
                For contingent labor surveys choose values to be included into new
                surveys:
              </Label>
              <InlineValuePicker
                options={Object.values(RATES_PICKER_OPTIONS)}
                label="Rates"
                value={pageState.selectedRates}
                onChange={(value: ImmutableSet<string>) =>
                  handleChange({ selectedRates: value })
                }
              />
            </>
          )}
          {(pageState.reviewType.value === REVIEW_TYPES.CLASSIC ||
            pageState.reviewType.value === REVIEW_TYPES.ORDERED) && (
            <>
              <Label>
                For contingent labor values choose how values should be calculated and
                displayed for validators:
              </Label>
              <RadioGroup
                value={pageState.displayRateType}
                onValueChange={(value: CONTINGENT_RATE_TYPES_TYPE) =>
                  handleChange({ displayRateType: value })
                }
              >
                <Grid
                  css={{
                    gridTemplateColumns: "repeat(3, auto)",
                    gridColumnGap: "$2",
                    gridRowGap: "$1",
                    alignItems: "center",
                  }}
                >
                  <RadioGroupItem value={CONTINGENT_RATE_TYPES.HOURLY}>
                    {CONTINGENT_RATE_TYPES_LABELS[CONTINGENT_RATE_TYPES.HOURLY]}
                  </RadioGroupItem>
                  <Text>multiplier = </Text>
                  <NumberInput
                    size="small"
                    value={pageState.hourlyRateMultiplier}
                    disabled
                  />

                  <RadioGroupItem value={CONTINGENT_RATE_TYPES.DAILY}>
                    {CONTINGENT_RATE_TYPES_LABELS[CONTINGENT_RATE_TYPES.DAILY]}
                  </RadioGroupItem>
                  <Text>multiplier = </Text>
                  <NumberInput
                    size="small"
                    value={pageState.dailyRateMultiplier ?? ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange({
                        dailyRateMultiplier: Number.isNaN(e.target.valueAsNumber)
                          ? null
                          : e.target.valueAsNumber,
                      })
                    }
                    disabled={pageState.displayRateType !== CONTINGENT_RATE_TYPES.DAILY}
                    color={
                      pageState.displayRateType !== CONTINGENT_RATE_TYPES.DAILY
                        ? "primary"
                        : pageState.dailyRateMultiplier
                        ? "primary"
                        : "danger"
                    }
                  />

                  <RadioGroupItem value={CONTINGENT_RATE_TYPES.WEEKLY}>
                    {CONTINGENT_RATE_TYPES_LABELS[CONTINGENT_RATE_TYPES.WEEKLY]}
                  </RadioGroupItem>
                  <Text>multiplier = </Text>
                  <NumberInput
                    size="small"
                    value={pageState.weeklyRateMultiplier ?? ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange({
                        weeklyRateMultiplier: Number.isNaN(e.target.valueAsNumber)
                          ? null
                          : e.target.valueAsNumber,
                      })
                    }
                    disabled={pageState.displayRateType !== CONTINGENT_RATE_TYPES.WEEKLY}
                    color={
                      pageState.displayRateType !== CONTINGENT_RATE_TYPES.WEEKLY
                        ? "primary"
                        : pageState.weeklyRateMultiplier
                        ? "primary"
                        : "danger"
                    }
                  />

                  <RadioGroupItem value={CONTINGENT_RATE_TYPES.MONTHLY}>
                    {CONTINGENT_RATE_TYPES_LABELS[CONTINGENT_RATE_TYPES.MONTHLY]}
                  </RadioGroupItem>
                  <Text>multiplier = </Text>
                  <NumberInput
                    size="small"
                    value={pageState.monthlyRateMultiplier ?? ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange({
                        monthlyRateMultiplier: Number.isNaN(e.target.valueAsNumber)
                          ? null
                          : e.target.valueAsNumber,
                      })
                    }
                    disabled={pageState.displayRateType !== CONTINGENT_RATE_TYPES.MONTHLY}
                    color={
                      pageState.displayRateType !== CONTINGENT_RATE_TYPES.MONTHLY
                        ? "primary"
                        : pageState.monthlyRateMultiplier
                        ? "primary"
                        : "danger"
                    }
                  />
                </Grid>
              </RadioGroup>
            </>
          )}
          <Label>
            Select display currency (leaving it blank will display rates in local
            currencies for every Job Title):
          </Label>
          <CurrencySelect
            className="full-width"
            currenciesData={currenciesData.toList().toJS()}
            value={pageState.displayCurrency}
            onChange={(value: ValueType<CurrencyData>) =>
              handleChange({ displayCurrency: value as CurrencyData })
            }
            styles={reactSelectStyles}
          />
        </Content>
      </Container>

      <Container>
        <StepID>6</StepID>
        <Content>
          <Label>
            Configure words replacements:&nbsp;
            <Tooltip side="right" content={replacementsTooltipContent}>
              <span>
                <Icon icon={["far", "question-circle"]} />
              </span>
            </Tooltip>
          </Label>
          <InputsList
            labels={["Replace", "With"]}
            values={pageState.replacements}
            inputImpl={InputsPair}
            onChange={(value: ReplacementsList) => handleChange({ replacements: value })}
          />
        </Content>
      </Container>

      <Container>
        <StepID>7</StepID>
        <Content>
          <Label>Other survey properties:</Label>
          <Grid
            css={{
              gridTemplateColumns: "auto auto",
              justifyContent: "flex-start",
              gap: "$2",
              [`& ${Text}`]: {
                justifySelf: "flex-end",
                alignSelf: "center",
              },
            }}
          >
            {pageState.reviewType.value === REVIEW_TYPES.ORDERED && (
              <>
                <Text>name the future survey</Text>
                <TextInput
                  size="small"
                  css={{ padding: "$2px", borderRadius: "$rounded" }}
                  value={pageState.reviewTitle ?? ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange({ reviewTitle: e.target.value })
                  }
                />
              </>
            )}
            <Text>mark survey as free</Text>
            <Checkbox
              checked={pageState.isFreeValidation}
              onCheckedChange={handleIsFreeChange}
            >
              <CheckboxIndicator>
                <Icon icon="check" />
              </CheckboxIndicator>
            </Checkbox>

            <Text
              css={{
                color: pageState.isFreeValidation ? "$primaryLight" : "$text",
              }}
            >
              reward per job
            </Text>
            <Inline>
              <NumberInput
                size="small"
                value={pageState.rewardPerJob ?? ""}
                disabled={pageState.isFreeValidation}
                color={
                  pageState.isFreeValidation
                    ? "primary"
                    : pageState.rewardPerJob
                    ? "primary"
                    : "danger"
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange({
                    rewardPerJob: Number.isNaN(e.target.valueAsNumber)
                      ? null
                      : e.target.valueAsNumber,
                  })
                }
              />
              {jobsTotal && pageState.rewardPerJob != null && (
                <Text thin>
                  {decimalFormatter(jobsTotal * pageState.rewardPerJob, false, "$")} total
                </Text>
              )}
            </Inline>

            <Text>pause after given attempts number</Text>
            <NumberInput
              size="small"
              value={pageState.maxAttemptsNumber ?? ""}
              color={pageState.maxAttemptsNumber ? "primary" : "danger"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange({
                  maxAttemptsNumber: Number.isNaN(e.target.valueAsNumber)
                    ? null
                    : e.target.valueAsNumber,
                })
              }
            />

            <Text>make all survey inactive by default</Text>
            <Checkbox
              checked={!pageState.isActive}
              onCheckedChange={() => handleChange({ isActive: !pageState.isActive })}
            >
              <CheckboxIndicator>
                <Icon icon="check" />
              </CheckboxIndicator>
            </Checkbox>
          </Grid>
        </Content>
      </Container>

      <Container>
        <StepID>8</StepID>
        <Content>
          <Label>Enter any special instructions (they will appear for every job):</Label>
          <TextArea
            rows={5}
            fill
            value={pageState.instructions || ""}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              handleChange({ instructions: e.target.value })
            }
          />
        </Content>
      </Container>

      <Container>
        <StepID>9</StepID>
        <Content>
          <Label>
            Select optional questions to appear with each job to be validated:
          </Label>
          <QuestionsEditor
            questions={pageState.questions}
            onChange={(value: QuestionsOrderedMap) => handleChange({ questions: value })}
          />
        </Content>
      </Container>

      <ButtonGroupRight>
        <Button icon="arrow-left" color="brand" size="normal" onClick={onGoBack}>
          Back to Private Index
        </Button>
        <Button
          icon="arrow-left"
          color="brand"
          size="normal"
          onClick={() => onChangeWizardPage(CREATE_REVIEW_WIZARD_PAGES.REVIEW)}
        >
          Back To Page {CREATE_REVIEW_WIZARD_PAGES.REVIEW}
        </Button>
        {!pageState.created && (
          <PromiseButton
            size="normal"
            color="success"
            loadingText="Create Surveys"
            onClick={handleSubmit}
          >
            Create Surveys
          </PromiseButton>
        )}
      </ButtonGroupRight>
    </Stack>
  );
};

SubmitContractorsWizardPage.displayName = "SubmitContractorsWizardPage";

export default SubmitContractorsWizardPage;
