import React from "react";
import DocumentTitle from "react-document-title";
import { RecoilRoot } from "recoil";
import type { InjectedRouter } from "react-router";

// @ts-expect-error
import MobXStore from "../../stores/mobx/MobXStore";
import SessionInfo from "../../models/SessionInfo";

import Container from "../../components/lib/Container";
import { TickerPageLoader } from "../../components/lib/TickerLoader";
import { useGlobalContext } from "../../globalContext";
import { omitObjectKeys } from "../../utils/object";
import { useAllCurrenciesData } from "../private_index/hooks";
import { baseUrlPath } from "../validator5K/constants";
import Val5KAdminContext from "./context/Val5KAdminContext";

import type { Location } from "history";
import type { Val5KAdminContextObject } from "./context/Val5KAdminContext";
import type { AllCurrenciesMap } from "../private_index/types";
import type { FetchAPIFunc, FetchGraphQLAPIFunc } from "../../types/fetch";
import type { CommonChildPageProps as CommonChildPagePropsBase } from "../../globalContext";

type Val5KAdminProps = {
  store: MobXStore;
  location: Location;
  sessionInfo: SessionInfo;
  router: InjectedRouter;
  fetchAPINew: FetchAPIFunc;
  fetchGraphQLNew: FetchGraphQLAPIFunc;
  children: React.ReactElement;
};

const Val5KAdmin = (props: Val5KAdminProps) => {
  const { children } = props;
  const globalContextValues = useGlobalContext();

  const { loaded: isCurrenciesDataLoaded, currenciesData } = useAllCurrenciesData();

  if (!isCurrenciesDataLoaded) {
    return <TickerPageLoader />;
  }

  const val5KContextValues = {
    ...omitObjectKeys(globalContextValues, ["fetchAPINew", "fetchGraphQLNew"]),
    //
    fetchAPI: globalContextValues["fetchAPINew"],
    fetchGraphQL: globalContextValues["fetchGraphQLNew"],
    //
    surveysValidationUIUrl: new URL(
      `${baseUrlPath}/surveys`,
      globalThis.location.origin
    ).toString(),
    //
    currenciesData,
  } as Val5KAdminContextObject;

  return (
    <Container
      className="pt-rate-card-validate"
      css={{
        paddingTop: "$4",
        paddingLeft: 0,
        paddingRight: 0,
        width: "100%",
      }}
    >
      <DocumentTitle title="Validator 5000" />
      <Val5KAdminContext.Provider value={val5KContextValues}>
        <RecoilRoot>
          {React.cloneElement(React.Children.only(children), globalContextValues)}
        </RecoilRoot>
      </Val5KAdminContext.Provider>
    </Container>
  );
};

Val5KAdmin.displayName = "Val5KAdmin";

export interface CommonChildPageProps extends CommonChildPagePropsBase {
  currenciesData: AllCurrenciesMap;
}

export default Val5KAdmin;
