/**
 * @generated SignedSource<<995036cdce4e3b80ba15e2feec962795>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DescriptionField_viewer$data = {
  readonly jobTitle?: {
    readonly description: string | null;
  } | null;
  readonly " $fragmentType": "DescriptionField_viewer";
};
export type DescriptionField_viewer$key = {
  readonly " $data"?: DescriptionField_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"DescriptionField_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": 0,
      "kind": "LocalArgument",
      "name": "jobTitleId"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "skipQuery"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "viewer"
      ],
      "operation": require('./DescriptionFieldRefetch.graphql')
    }
  },
  "name": "DescriptionField_viewer",
  "selections": [
    {
      "condition": "skipQuery",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "id",
              "variableName": "jobTitleId"
            }
          ],
          "concreteType": "JobTitleDetailNode",
          "kind": "LinkedField",
          "name": "jobTitle",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "71ba2d0ad175f0f9da65982ef533c5af";

export default node;
