export function omitObjectKeys<T = Object>(
  object: T,
  keysToOmit: Array<keyof T>
): Partial<T> {
  if (!Array.isArray(keysToOmit)) keysToOmit = [keysToOmit];
  if (!keysToOmit?.length) return object;

  const objectCopy = { ...object };

  keysToOmit.forEach((key) => {
    delete objectCopy[key];
  });

  return objectCopy;
}

export function pickObjectKeys<T extends Object = Object>(
  object: T,
  keysToPick: Array<keyof T>
): Partial<T> {
  if (!Array.isArray(keysToPick)) keysToPick = [keysToPick];
  if (!keysToPick?.length) return object;

  const objectCopy: Partial<T> = {};

  keysToPick.forEach((key) => {
    if (key in object) {
      objectCopy[key] = object[key];
    }
  });

  return objectCopy;
}
