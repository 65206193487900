import moment from "moment";
// @ts-expect-error
import numeral from "numeral";

import {
  CONTRACTOR_STATUSES,
  RATE_TYPES,
  RATE_TYPES_LABELS,
  PROCESSING_STATUSES,
  PROCESSING_STATUSES_LABELS,
  PROCESSING_TYPES,
  PROCESSING_TYPES_LABELS,
  PROGRAM_STATUSES,
  PROGRAM_STATUSES_LABELS,
  PROGRAM_ACCESS_STATUSES,
  PROGRAM_ACCESS_STATUSES_LABELS,
  JOB_TITLE_MATCH_TYPES,
  JOB_TITLE_MATCH_TYPES_LABELS,
} from "./types";

export {
  djangoOrderingKey,
  djangoPaginationKey,
  djangoPaginationSizeKey,
  djangoSearchKey,
  emptyList,
  emptyMap,
  emptySet,
  emptyOrderedMap,
} from "../../constants";
export { FilterTypes } from "../../components/tables/constants";

export const RATE_TYPES_OPTIONS = [
  { value: RATE_TYPES.CONTRACT, label: RATE_TYPES_LABELS[RATE_TYPES.CONTRACT] },
  { value: RATE_TYPES.FTE, label: RATE_TYPES_LABELS[RATE_TYPES.FTE] },
];

export const CONTRACTOR_GOOD_PROCESSING_STATUSES = [
  CONTRACTOR_STATUSES.PENDING,
  CONTRACTOR_STATUSES.CLEAN,
  CONTRACTOR_STATUSES.FINISHED,
  CONTRACTOR_STATUSES.NEED_APPROVAL,
];

export const PROCESSING_STATUSES_OPTIONS = [
  {
    value: PROCESSING_STATUSES.RUNNING,
    label: PROCESSING_STATUSES_LABELS[PROCESSING_STATUSES.RUNNING],
  },
  {
    value: PROCESSING_STATUSES.FINISHED,
    label: PROCESSING_STATUSES_LABELS[PROCESSING_STATUSES.FINISHED],
  },
  {
    value: PROCESSING_STATUSES.FAILED,
    label: PROCESSING_STATUSES_LABELS[PROCESSING_STATUSES.FAILED],
  },
  {
    value: PROCESSING_STATUSES.STOPPED,
    label: PROCESSING_STATUSES_LABELS[PROCESSING_STATUSES.STOPPED],
  },
];

export const PROCESSING_TYPES_OPTIONS = [
  {
    value: PROCESSING_TYPES.CONTRACTORS,
    label: PROCESSING_TYPES_LABELS[PROCESSING_TYPES.CONTRACTORS],
  },
  {
    value: PROCESSING_TYPES.CONTRACTORS_UPLOAD,
    label: PROCESSING_TYPES_LABELS[PROCESSING_TYPES.CONTRACTORS_UPLOAD],
  },
  {
    value: PROCESSING_TYPES.CREATE_RATECARD,
    label: PROCESSING_TYPES_LABELS[PROCESSING_TYPES.CREATE_RATECARD],
  },
];

export const PROGRAM_STATUSES_OPTIONS = [
  {
    value: PROGRAM_STATUSES.PENDING,
    label: PROGRAM_STATUSES_LABELS[PROGRAM_STATUSES.PENDING],
  },
  {
    value: PROGRAM_STATUSES.ACTIVE,
    label: PROGRAM_STATUSES_LABELS[PROGRAM_STATUSES.ACTIVE],
  },
  {
    value: PROGRAM_STATUSES.INACTIVE,
    label: PROGRAM_STATUSES_LABELS[PROGRAM_STATUSES.INACTIVE],
  },
];

export const PROGRAM_ACCESS_STATUSES_OPTIONS = [
  {
    value: PROGRAM_ACCESS_STATUSES.PENDING,
    label: PROGRAM_ACCESS_STATUSES_LABELS[PROGRAM_ACCESS_STATUSES.PENDING],
  },
  {
    value: PROGRAM_ACCESS_STATUSES.APPROVED,
    label: PROGRAM_ACCESS_STATUSES_LABELS[PROGRAM_ACCESS_STATUSES.APPROVED],
  },
  {
    value: PROGRAM_ACCESS_STATUSES.DECLINED,
    label: PROGRAM_ACCESS_STATUSES_LABELS[PROGRAM_ACCESS_STATUSES.DECLINED],
  },
];

export const JOB_TITLE_MATCH_TYPES_OPTIONS = [
  {
    value: JOB_TITLE_MATCH_TYPES.EXACT_MATCH,
    label: JOB_TITLE_MATCH_TYPES_LABELS[JOB_TITLE_MATCH_TYPES.EXACT_MATCH],
  },
  {
    value: JOB_TITLE_MATCH_TYPES.FUZZY_MATCH,
    label: JOB_TITLE_MATCH_TYPES_LABELS[JOB_TITLE_MATCH_TYPES.FUZZY_MATCH],
  },
  {
    value: JOB_TITLE_MATCH_TYPES.FIXED_MANUALLY,
    label: JOB_TITLE_MATCH_TYPES_LABELS[JOB_TITLE_MATCH_TYPES.FIXED_MANUALLY],
  },
];

export const BOOLEAN_FILTER_VALUES_OPTIONS = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export const DECIMAL_FORMAT = "0,0.[00]";
export const DECIMAL_FORMAT_WITH_SIGN = "+0,0.[00]";
export const DATE_FORMAT = "MM-DD-YYYY";
export const DATETIME_FORMAT = "dddd, MMMM Do YYYY, h:mm:ss a";

export const dateFormatterBase = (
  value: Date | undefined | null,
  formatString: string
): string => {
  if (value == null) return "";
  return moment.utc(value).format(formatString);
};

export const dateFormatter = (value: Date | undefined | null) =>
  dateFormatterBase(value, DATE_FORMAT);
export const dateTimeFormatter = (value: Date | undefined | null) =>
  dateFormatterBase(value, DATETIME_FORMAT);

export const decimalFormatter = (
  value: number | undefined | null,
  verbose: boolean = false,
  units: string | null = null
): string => {
  const formatter = verbose ? DECIMAL_FORMAT_WITH_SIGN : DECIMAL_FORMAT;
  return value != null
    ? (units ? ` ${units} ` : "") + numeral(value).format(formatter)
    : "";
};
export const percentFormatter = (
  value: number | undefined | null,
  verbose: boolean = false,
  units: string = "%"
): string => {
  const formatter = verbose ? DECIMAL_FORMAT_WITH_SIGN : DECIMAL_FORMAT;
  return value != null
    ? numeral(value).format(formatter) + (units ? ` ${units} ` : "")
    : "";
};

export const timeLeftFormatter = (
  value: Date | undefined | null,
  empty: string = ""
): string => {
  if (value == null) return empty;
  return moment
    .duration(
      moment.utc(Date.now()).diff(moment.utc(new Date(value)), "seconds"),
      "seconds"
    )
    .humanize();
};
export const timePassedFormatter = timeLeftFormatter;

type TimeUnits = "years" | "months" | "weeks" | "days" | "hours" | "minutes" | "seconds";

export const getTimePassedInUnits = (
  timestamp: Date,
  units: TimeUnits = "seconds"
): number => {
  return moment.utc().diff(moment.utc(timestamp), units);
};

export const secondsToIntervalString = (seconds: number): string => {
  let minutes = Math.floor(seconds / 60);
  seconds = Math.floor(seconds % 60);
  let hours = Math.floor(minutes / 60);
  minutes = Math.floor(minutes % 60);

  let str = "";
  if (seconds) {
    str = `${seconds} sec` + str;
  }
  if (minutes) {
    str = `${minutes} min ` + str;
  }
  if (hours) {
    str = `${hours} h ` + str;
  }

  return str;
};

export const reactSelectStyles = {
  control: (provided: HTMLStyleElement, state: any) => ({
    ...provided,
    fontSize: 14,
  }),
  menuList: (provided: HTMLStyleElement, state: any) => ({
    ...provided,
    fontSize: 14,
  }),
  placeholder: (provided: HTMLStyleElement, state: any) => ({
    ...provided,
    fontSize: 14,
    color: "#ccc",
  }),
  singleValue: (provided: HTMLStyleElement, state: any) => ({
    ...provided,
    fontSize: 14,
  }),
};
