import * as React from "react";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { styled, CSS } from "../../stitches.config";

export interface RadioGroupProps
  extends Omit<
    RadioGroupPrimitive.RadioGroupProps,
    "value" | "defaultValue" | "onValueChange"
  > {
  value?: any;
  defaultValue?: any;
  onValueChange?: (value: any) => void;
  css?: CSS;
}

export interface RadioGroupItemProps
  extends Omit<RadioGroupPrimitive.RadioGroupItemProps, "value"> {
  value: any;
  css?: CSS;
}

export const RadioGroup = (props: RadioGroupProps) => (
  <RadioGroupPrimitive.Root {...props} />
);
RadioGroup.displayName = "RadioGroup";

const StyledRadioGroupItem = styled(RadioGroupPrimitive.Item, {
  all: "unset",
  backgroundColor: "white",
  width: 16,
  height: 16,
  borderRadius: "100%",
  border: "2px solid $primaryDark",
  cursor: "pointer",
  // boxShadow: `0 2px 10px $brand`,
  "&:hover": {
    borderColor: "$brand",
  },
  // '&:focus': { boxShadow: `0 0 0 2px $brand` },
  '&[data-state="checked"]': {
    borderColor: "$brand",
  },
  "&:disabled": {
    borderColor: "$primaryLight",
    cursor: "not-allowed",
  },
});

export const RadioGroupRadio = (props: RadioGroupItemProps) => (
  <StyledRadioGroupItem {...props} />
);
RadioGroupRadio.displayName = "RadioGroupRadio";

export const RadioGroupIndicator = styled(RadioGroupPrimitive.Indicator, {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  position: "relative",
  "&::after": {
    content: '""',
    display: "block",
    width: 10,
    height: 10,
    borderRadius: "50%",
    backgroundColor: "$brand",
  },

  variants: {
    disabled: {
      true: {
        "&::after": {
          backgroundColor: "$primaryLight",
        },
      },
    },
  },
});
RadioGroupIndicator.displayName = "RadioGroupIndicator";

const Label = styled("label", {
  color: "$text",
  fontSize: "16px",
  fontWeight: "normal",
  lineHeight: 1,
  marginBottom: 0,
  userSelect: "none",
  cursor: "pointer",

  variants: {
    disabled: {
      true: {
        cursor: "not-allowed",
        color: "$primary",
      },
    },
    bold: {
      true: {
        fontWeight: "$semibold",
      },
    },
  },

  defaultVariants: {
    disabled: false,
    bold: false,
  },
});

export const LabelInline = styled(Label, {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexWrap: "wrap",
  gap: "$3",

  variants: {
    nowrap: {
      true: {
        flexWrap: "nowrap",
        whiteSpace: "nowrap",
      },
    },
  },
});
LabelInline.displayName = "LabelInline";

export const RadioGroupItem = (props: React.PropsWithChildren<RadioGroupItemProps>) => {
  const { children, id, css, value, disabled = false } = props;

  return (
    <LabelInline nowrap css={css} disabled={disabled} htmlFor={id}>
      <RadioGroupRadio id={id} value={value} disabled={disabled}>
        <RadioGroupIndicator disabled={disabled} />
      </RadioGroupRadio>
      <span>{children}</span>
    </LabelInline>
  );
};
RadioGroupItem.displayName = "RadioGroupItem";
