// @flow
import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SortState from "../../models/SortState";
import { SORT_DIRECTION } from "../../models/Sort";
import JobLibraryRequestListStore from "../../stores/mobx/JobLibraryRequestListStore";
import { LIBRARY_REQUEST_SORT_FIELD } from "../../constants/jobLibraryRequest";
import classNames from "classnames";

function isSortAsc(sortState: ?SortState, field: string) {
  if (!sortState) return false;
  if (sortState.field !== field) return false;
  return !sortState.direction || sortState.direction === SORT_DIRECTION.ASC;
}

function isSortDesc(sortState: ?SortState, field: string) {
  if (!sortState) return false;
  if (sortState.field !== field) return false;
  if (!sortState.direction) return false;

  return sortState.direction === SORT_DIRECTION.DESC;
}

type Props = {
  store: JobLibraryRequestListStore,
  children: any,
};

export default class JobLibraryRequestTable extends Component<Props> {
  handleSortByStatus: () => void;
  handleSortByTitle: () => void;
  handleSortByCreated: () => void;
  handleSortByFileName: () => void;

  constructor(props: Props) {
    super(props);

    this.handleSortByStatus = this.handleSortByStatus.bind(this);
    this.handleSortByTitle = this.handleSortByTitle.bind(this);
    this.handleSortByCreated = this.handleSortByCreated.bind(this);
    this.handleSortByFileName = this.handleSortByFileName.bind(this);
  }

  handleSortByStatus() {
    const { store } = this.props;

    store.onSortByField(LIBRARY_REQUEST_SORT_FIELD.STATUS, true);
  }

  handleSortByTitle() {
    const { store } = this.props;

    store.onSortByField(LIBRARY_REQUEST_SORT_FIELD.TITLE);
  }

  handleSortByCreated() {
    const { store } = this.props;

    store.onSortByField(LIBRARY_REQUEST_SORT_FIELD.CREATED, true);
  }

  handleSortByFileName() {
    const { store } = this.props;

    store.onSortByField(LIBRARY_REQUEST_SORT_FIELD.FILE_NAME);
  }

  render() {
    const props = this.props;
    const { store } = props;
    if (!store) return <code>Store required to render table</code>;
    const sortState = store.sortState;

    const sortedByStatus =
      sortState && sortState.field === LIBRARY_REQUEST_SORT_FIELD.STATUS;
    const sortedByTitle =
      sortState && sortState.field === LIBRARY_REQUEST_SORT_FIELD.TITLE;
    const sortedByCreated =
      sortState && sortState.field === LIBRARY_REQUEST_SORT_FIELD.CREATED;
    const sortedByFileName =
      sortState && sortState.field === LIBRARY_REQUEST_SORT_FIELD.FILE_NAME;

    const statusSortIcon = !sortedByStatus
      ? "sort"
      : (isSortAsc(sortState, LIBRARY_REQUEST_SORT_FIELD.STATUS) && "sort-up") ||
        (isSortDesc(sortState, LIBRARY_REQUEST_SORT_FIELD.STATUS) && "sort-down");
    const statusSortIconClasses = classNames("sort-icon", {
      "sort-none": !sortedByStatus,
    });

    const titleSortIcon = !sortedByTitle
      ? "sort"
      : (isSortAsc(sortState, LIBRARY_REQUEST_SORT_FIELD.TITLE) && "sort-up") ||
        (isSortDesc(sortState, LIBRARY_REQUEST_SORT_FIELD.TITLE) && "sort-down");
    const titleSortIconClasses = classNames("sort-icon", { "sort-none": !sortedByTitle });

    const createdSortIcon = !sortedByCreated
      ? "sort"
      : (isSortAsc(sortState, LIBRARY_REQUEST_SORT_FIELD.CREATED) && "sort-up") ||
        (isSortDesc(sortState, LIBRARY_REQUEST_SORT_FIELD.CREATED) && "sort-down");
    const createdSortIconClasses = classNames("sort-icon", {
      "sort-none": !sortedByCreated,
    });

    const fileNameSortIcon = !sortedByFileName
      ? "sort"
      : (isSortAsc(sortState, LIBRARY_REQUEST_SORT_FIELD.FILE_NAME) && "sort-up") ||
        (isSortDesc(sortState, LIBRARY_REQUEST_SORT_FIELD.FILE_NAME) && "sort-down");
    const fileNameSortIconClasses = classNames("sort-icon", {
      "sort-none": !sortedByFileName,
    });

    return (
      <div className="cjl-flex-table">
        <div className="cjl-flex-table header">
          <div className="column auto cjl-caret-col" />
          <div
            className="column auto click-enabled cjl-library-request-status-col"
            onClick={this.handleSortByStatus}
          >
            <span>
              Status{" "}
              <FontAwesomeIcon icon={statusSortIcon} className={statusSortIconClasses} />
            </span>
          </div>
          <div className="column click-enabled" onClick={this.handleSortByTitle}>
            <span>
              Title{" "}
              <FontAwesomeIcon icon={titleSortIcon} className={titleSortIconClasses} />
            </span>
          </div>
          <div
            className="column auto click-enabled cjl-has-file-col"
            onClick={this.handleSortByFileName}
          >
            <span>
              File{" "}
              <FontAwesomeIcon
                icon={fileNameSortIcon}
                className={fileNameSortIconClasses}
              />
            </span>
          </div>
          <div className="column auto cjl-countries-count-col">
            <span>Countries</span>
          </div>
          <div
            className="column auto click-enabled cjl-date-col"
            onClick={this.handleSortByCreated}
          >
            <span>
              Submitted{" "}
              <FontAwesomeIcon
                icon={createdSortIcon}
                className={createdSortIconClasses}
              />
            </span>
          </div>
        </div>
        {props.children}
      </div>
    );
  }
}
