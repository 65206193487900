// @flow
import React, { Component } from "react";
import type { Node } from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SortState from "../../models/SortState";
import classNames from "classnames";
import { CLIENT_JOB_TITLE_SORT_FIELD } from "../../constants/clientJobTitle";
import { SORT_DIRECTION } from "../../models/Sort";
import JobLibraryTitleListStore, {
  DisplayOption,
} from "../../stores/mobx/JobLibraryTitleListStore";
import ToggleButton, { ToggleButtonGroup } from "../ratecards/components/ToggleButton";
import ContainerSection from "../ratecards/components/ContainerSection";
import Modal from "../ratecards/components/Modal";
import Button from "../../components/lib/Button";
import Inline from "../../components/lib/Inline";

function isSortAsc(sortState: ?SortState, field: string) {
  if (!sortState) return false;
  if (sortState.field !== field) return false;
  return !sortState.direction || sortState.direction === SORT_DIRECTION.ASC;
}

function isSortDesc(sortState: ?SortState, field: string) {
  if (!sortState) return false;
  if (sortState.field !== field) return false;
  if (!sortState.direction) return false;

  return sortState.direction === SORT_DIRECTION.DESC;
}

type Props = {
  store: JobLibraryTitleListStore,
  showCountries: boolean,
  actions?: boolean,
  children: Node,
  showLatestSearch?: boolean,
};

class LibraryTitleTable extends Component<Props> {
  props: Props;

  static defaultProps = {
    showCountries: true,
    showLatestSearch: false,
  };

  handleSortByCountries: () => void;
  handleSortByTitle: () => void;
  handleSortByCreated: () => void;

  constructor(props: Props) {
    super(props);

    this.handleSortByCountries = this.handleSortByCountries.bind(this);
    this.handleSortByTitle = this.handleSortByTitle.bind(this);
    this.handleSortByCreated = this.handleSortByCreated.bind(this);
  }

  handleSortByCountries() {
    const { store } = this.props;

    store.onSortByField(CLIENT_JOB_TITLE_SORT_FIELD.COUNTRIES, true);
  }

  handleSortByTitle() {
    const { store } = this.props;

    store.onSortByField(CLIENT_JOB_TITLE_SORT_FIELD.CLIENT_TITLE);
  }

  handleSortByCreated() {
    const { store } = this.props;

    store.onSortByField(CLIENT_JOB_TITLE_SORT_FIELD.CREATED, true);
  }

  renderModals = () => {
    const { store } = this.props;
    return (
      <div>
        <Modal show={store.displayOptionsModal.show} onHide={store.applyDisplayOptions}>
          <ContainerSection className="header">
            <h4>Latest Search Display Options</h4>
          </ContainerSection>
          <ContainerSection>
            <ToggleButtonGroup>
              <ToggleButton
                name="show-options"
                type="radio"
                item={DisplayOption.RATES.PAY_RATE}
                selected={
                  store.selectedDisplayRateOption.value ===
                  DisplayOption.RATES.PAY_RATE.value
                }
                onClick={store.handleDisplayRateOptionChange}
              >
                {DisplayOption.RATES.PAY_RATE.label}
              </ToggleButton>
              <ToggleButton
                name="show-options"
                type="radio"
                item={DisplayOption.RATES.BILL_RATE}
                selected={
                  store.selectedDisplayRateOption.value ===
                  DisplayOption.RATES.BILL_RATE.value
                }
                onClick={store.handleDisplayRateOptionChange}
              >
                {DisplayOption.RATES.BILL_RATE.label}
              </ToggleButton>
            </ToggleButtonGroup>
          </ContainerSection>
          <ContainerSection>
            <ToggleButtonGroup>
              <ToggleButton
                name="rate-options"
                type="radio"
                item={DisplayOption.AGGREGATION.HOURLY}
                selected={
                  store.selectedDisplayAggregationOption.value ===
                  DisplayOption.AGGREGATION.HOURLY.value
                }
                onClick={store.handleDisplayAggregationOptionChange}
              >
                {DisplayOption.AGGREGATION.HOURLY.label}
              </ToggleButton>
              <ToggleButton
                name="rate-options"
                type="radio"
                item={DisplayOption.AGGREGATION.DAILY}
                selected={
                  store.selectedDisplayAggregationOption.value ===
                  DisplayOption.AGGREGATION.DAILY.value
                }
                onClick={store.handleDisplayAggregationOptionChange}
              >
                {DisplayOption.AGGREGATION.DAILY.label}
              </ToggleButton>
              <ToggleButton
                name="rate-options"
                type="radio"
                item={DisplayOption.AGGREGATION.WEEKLY}
                selected={
                  store.selectedDisplayAggregationOption.value ===
                  DisplayOption.AGGREGATION.WEEKLY.value
                }
                onClick={store.handleDisplayAggregationOptionChange}
              >
                {DisplayOption.AGGREGATION.WEEKLY.label}
              </ToggleButton>
              <ToggleButton
                name="rate-options"
                type="radio"
                item={DisplayOption.AGGREGATION.MONTHLY}
                selected={
                  store.selectedDisplayAggregationOption.value ===
                  DisplayOption.AGGREGATION.MONTHLY.value
                }
                onClick={store.handleDisplayAggregationOptionChange}
              >
                {DisplayOption.AGGREGATION.MONTHLY.label}
              </ToggleButton>
            </ToggleButtonGroup>
            <div className="control-info-text">
              <FontAwesomeIcon icon="info-circle" className="icon" />
              <em>Salary searches are not affected by this option.</em>
            </div>
          </ContainerSection>
          <ContainerSection>
            <label>Hourly aggregation factors</label>
            <div className="control-info-text">
              <FontAwesomeIcon icon="info-circle" className="icon" />
              <em>Used to convert hourly rates to daily, weekly and monthly.</em>
            </div>
          </ContainerSection>
          <ContainerSection>
            <div className="flex center-items">
              <div style={{ width: 90 }}>
                <label>Daily:</label>
              </div>
              <div style={{ flex: "1 1 auto" }}>
                <input
                  type="text"
                  className={classNames("rc-input-box", {
                    "has-error": Boolean(store.dailyMultiplierError),
                  })}
                  style={{ width: "100%" }}
                  value={store.dailyMultiplier}
                  onChange={store.handleDailyMultiplierChange}
                />
              </div>
            </div>
          </ContainerSection>
          <ContainerSection>
            <div className="flex center-items">
              <div style={{ width: 90 }}>
                <label>Weekly:</label>
              </div>
              <div style={{ flex: "1 1 auto" }}>
                <input
                  type="text"
                  className={classNames("rc-input-box", {
                    "has-error": Boolean(store.weeklyMultiplierError),
                  })}
                  style={{ width: "100%" }}
                  value={store.weeklyMultiplier}
                  onChange={store.handleWeeklyMultiplierChange}
                />
              </div>
            </div>
          </ContainerSection>
          <ContainerSection>
            <div className="flex center-items">
              <div style={{ width: 90 }}>
                <label>Monthly:</label>
              </div>
              <div style={{ flex: "1 1 auto" }}>
                <input
                  type="text"
                  className={classNames("rc-input-box", {
                    "has-error": Boolean(store.monthlyMultiplierError),
                  })}
                  style={{ width: "100%" }}
                  value={store.monthlyMultiplier}
                  onChange={store.handleMonthlyMultiplierChange}
                />
              </div>
            </div>
          </ContainerSection>
          <ContainerSection className="footer">
            <Inline css={{ justifyContent: "flex-end" }}>
              <Button size="large" color="brand" onClick={store.applyDisplayOptions}>
                Close
              </Button>
              <Button size="large" onClick={store.resetDisplayOptions}>
                Reset
              </Button>
            </Inline>
          </ContainerSection>
        </Modal>
      </div>
    );
  };

  render() {
    const props = this.props;
    const { store, showLatestSearch, showCountries } = props;
    if (!store) return <code>Store required to render table</code>;
    const sortState = store.sortState;

    const sortedByCountries =
      sortState && sortState.field === CLIENT_JOB_TITLE_SORT_FIELD.COUNTRIES;
    const sortedByTitle =
      sortState && sortState.field === CLIENT_JOB_TITLE_SORT_FIELD.CLIENT_TITLE;
    const sortedByCreated =
      sortState && sortState.field === CLIENT_JOB_TITLE_SORT_FIELD.CREATED;

    const countriesSortIcon = !sortedByCountries
      ? "sort"
      : (isSortAsc(sortState, CLIENT_JOB_TITLE_SORT_FIELD.COUNTRIES) && "sort-up") ||
        (isSortDesc(sortState, CLIENT_JOB_TITLE_SORT_FIELD.COUNTRIES) && "sort-down");
    const countriesSortIconClasses = classNames("sort-icon", {
      "sort-none": !sortedByCountries,
    });

    const titleSortIcon = !sortedByTitle
      ? "sort"
      : (isSortAsc(sortState, CLIENT_JOB_TITLE_SORT_FIELD.CLIENT_TITLE) && "sort-up") ||
        (isSortDesc(sortState, CLIENT_JOB_TITLE_SORT_FIELD.CLIENT_TITLE) && "sort-down");
    const titleSortIconClasses = classNames("sort-icon", { "sort-none": !sortedByTitle });

    const createdSortIcon = !sortedByCreated
      ? "sort"
      : (isSortAsc(sortState, CLIENT_JOB_TITLE_SORT_FIELD.CREATED) && "sort-up") ||
        (isSortDesc(sortState, CLIENT_JOB_TITLE_SORT_FIELD.CREATED) && "sort-down");
    const createdSortIconClasses = classNames("sort-icon", {
      "sort-none": !sortedByCreated,
    });

    return (
      <div className="cjl-flex-table">
        <div className="cjl-flex-table header no-text-select">
          <div className="column auto cjl-caret-col" />
          <div className="column auto cjl-job-title-id-col">
            <span data-testid="id-column">ID</span>
          </div>
          <div className="column click-enabled" onClick={this.handleSortByTitle}>
            <span data-testid="title-column">
              Title{" "}
              <FontAwesomeIcon icon={titleSortIcon} className={titleSortIconClasses} />
            </span>
          </div>
          {showLatestSearch && (
            <div className="column auto cjl-latest-search-col">
              <div className="cjl-flex-table row center-items">
                <div style={{ marginRight: 15 }}>Latest Search</div>
                <ToggleButtonGroup>
                  <ToggleButton
                    name="show-options"
                    type="checkbox"
                    item={store.selectedDisplayAggregationOption}
                    selected={true}
                    // onClick={store.handleDisplayRateOptionChange}
                  >
                    {store.selectedDisplayAggregationOption.label}
                  </ToggleButton>
                  <ToggleButton
                    name="show-options"
                    type="checkbox"
                    item={store.selectedDisplayRateOption}
                    selected={true}
                    // onClick={store.handleDisplayRateOptionChange}
                  >
                    {store.selectedDisplayRateOption.label}
                  </ToggleButton>
                  <ToggleButton
                    name="rate-options"
                    type="radio"
                    value="config"
                    selected={false}
                    onClick={store.displayOptionsModal.showModal}
                  >
                    <FontAwesomeIcon icon="cog" />
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </div>
          )}
          {showCountries && (
            <div
              className="column auto cjl-countries-col click-enabled"
              onClick={this.handleSortByCountries}
            >
              <span>
                Available Countries{" "}
                <FontAwesomeIcon
                  icon={countriesSortIcon}
                  className={countriesSortIconClasses}
                />
              </span>
            </div>
          )}
          {!showLatestSearch && (
            <div
              className="column auto click-enabled cjl-date-col"
              onClick={this.handleSortByCreated}
            >
              <span>
                Created{" "}
                <FontAwesomeIcon
                  icon={createdSortIcon}
                  className={createdSortIconClasses}
                />
              </span>
            </div>
          )}
          {props.actions && (
            <div className="column auto cjl-job-title-actions-col">Actions</div>
          )}
          <div className="column auto">
            <div style={{ width: 25 }} />
          </div>
        </div>
        {props.children}
        {showLatestSearch && this.renderModals()}
      </div>
    );
  }
}

export default observer(LibraryTitleTable);
