export default class LoginUser {
  constructor(store, object) {
    this.store = store;
    this.login_firstname = null;
    this.login_lastname = null;
    this.login_email = null;
    this.login_username = null;
    this.userId = null;
  }

  toJS() {
    return {
      login_firstname: this.login_firstname,
      login_lastname: this.login_lastname,
      login_email: this.login_email,
      login_username: this.login_username,
      userId: this.userId,
    };
  }

  static fromJS(store, object) {
    return new LoginUser(store, object);
  }
}
