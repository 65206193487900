import React, { useState, useCallback } from "react";

import Stack from "../../../components/lib/Stack";
import Text from "../../../components/lib/Text";
import Table from "../lib/Table";
import { THead, TBody, TR, TH, TD } from "../../../components/lib/Table";
import { SimpleLink } from "../../../components/lib/Link";
import {
  Card,
  CardBody,
  CardActions,
  CardActionsLeft,
  CardHeaderTitle,
} from "../../../components/lib/Card";
import { useInitialFetch } from "../../../utils/hooks";
import RatesLimitsViewer from "./RatesLimitsViewer";
import { decimalFormatter, percentFormatter, calculateLevelsLimits } from "../constants";
import { RATE_TYPES } from "../types";
import { emptyMap, emptyOrderedMap } from "../../../constants";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";
import { collectionLimitsToImmutableMap } from "../dataConverters";
import { TickerContentLoader } from "../../../components/lib/TickerLoader";
import { useReviewDataContext } from "../context/ReviewDataContext";
import { useRateDisplayContext } from "../context/RateDisplayContext";

import type {
  CollectionLimitsMap,
  CollectionLimitsObject,
  RateResultsOrderedMap,
} from "../types";
import type { FetchAPIResponse } from "../../../types/fetch";
import { useVal5KAdminContext } from "../context/Val5KAdminContext";

const ValuesFromCollectionBlock = () => {
  const { firstJobData } = useReviewDataContext();
  const { fetchTasteAPI, showModalError, services } = useVal5KAdminContext();

  const collectionId = firstJobData.get("collection_id");
  const countryId = firstJobData.get("country_id");
  const collectionTitle = firstJobData.get("collection_title");
  const collectionLabel = `#${collectionId}${
    collectionTitle ? ` "${collectionTitle}"` : null
  }`;
  const collectionUrl = services?.taste
    ? `${services?.taste}job-title-collections/${collectionId}/`
    : null;

  // state

  const [limitsData, setLimits] = useState<CollectionLimitsMap>(
    emptyMap as unknown as CollectionLimitsMap
  );

  // fetch data

  const fetchRatesLimitsData = useCallback(async () => {
    if (!collectionId || !countryId) return null;

    try {
      const response: FetchAPIResponse<CollectionLimitsObject> = await fetchTasteAPI(
        `job_title_collection/limits_for_location/`,
        {
          method: "post",
          data: { collection_id: collectionId, location_id: countryId },
        }
      );
      const data: CollectionLimitsMap = collectionLimitsToImmutableMap(response.data);
      setLimits(calculateLevelsLimits(data));
    } catch (err: any) {
      logAsyncOperationError("fetchRatesLimitsData", err);
      showModalError(
        "Error occurred while loading limits from Taste. Please, try again later."
      );
    }
  }, [collectionId, countryId, fetchTasteAPI, showModalError]);

  // initial fetch

  useInitialFetch(fetchRatesLimitsData);

  // render

  if (!collectionId || !countryId) return null;

  return (
    <>
      <Text as="h5">
        Limits for collection&nbsp;
        {collectionUrl ? (
          <SimpleLink href={collectionUrl} target="_blank" rel="noopener noreferrer">
            {collectionLabel}
          </SimpleLink>
        ) : (
          collectionLabel
        )}
        :
      </Text>
      {limitsData?.size > 0 ? (
        <RatesLimitsViewer limitsData={limitsData} />
      ) : (
        <TickerContentLoader />
      )}
    </>
  );
};
ValuesFromCollectionBlock.displayName = "ValuesFromCollectionBlock";

function ValuesFromRatecardBlock() {
  const { displayRateType, displayRateMultiplier } = useRateDisplayContext();
  const {
    ratecardId,
    ratecardName,
    firstJobData,
    firstJobCurrencyData,
    firstJobRateType: rateType,
  } = useReviewDataContext();

  const ratecardRates = firstJobData
    .get("rate_results", emptyOrderedMap as unknown as RateResultsOrderedMap)
    .sortBy((i) => i?.get("level"));
  const currencySymbol = firstJobCurrencyData.get("symbol");

  if (!ratecardId || ratecardRates.size <= 0) return null;

  const lowestRates = ratecardRates.size > 0 ? ratecardRates.first() : null;
  const highestRates = ratecardRates.size > 0 ? ratecardRates.last() : null;

  let payRateMin = null;
  let payRateMax = null;
  let markupMin = null;
  let markupMax = null;
  let billRateMin = null;
  let billRateMax = null;
  let annualSalaryMin = null;
  let annualSalaryMax = null;

  if (highestRates) {
    payRateMax = highestRates.get("pay_rate_max");
    markupMax = highestRates.get("markup_max");
    billRateMax = highestRates.get("bill_rate_max");
    annualSalaryMax = highestRates.get("pay_rate_max");
  }
  if (lowestRates) {
    payRateMin = lowestRates.get("pay_rate_min");
    markupMin = lowestRates.get("markup_min");
    billRateMin = lowestRates.get("bill_rate_min");
    annualSalaryMin = lowestRates.get("pay_rate_min");
  }

  let rateMultiplier = null;
  if (rateType === RATE_TYPES.HOURLY) {
    rateMultiplier = firstJobData.get("hourly_multiplier");
  } else if (rateType === RATE_TYPES.DAILY) {
    rateMultiplier = firstJobData.get("daily_multiplier");
  } else if (rateType === RATE_TYPES.WEEKLY) {
    rateMultiplier = firstJobData.get("weekly_multiplier");
  } else if (rateType === RATE_TYPES.MONTHLY) {
    rateMultiplier = firstJobData.get("monthly_multiplier");
  }

  if (rateType !== RATE_TYPES.ANNUAL) {
    if (
      (rateType === RATE_TYPES.DAILY ||
        rateType === RATE_TYPES.WEEKLY ||
        rateType === RATE_TYPES.MONTHLY) &&
      rateMultiplier &&
      rateMultiplier !== 1
    ) {
      // convert to hourly values
      if (payRateMin) payRateMin /= rateMultiplier;
      if (payRateMax) payRateMax /= rateMultiplier;
      if (billRateMin) billRateMin /= rateMultiplier;
      if (billRateMax) billRateMax /= rateMultiplier;
    }
    if (
      (displayRateType === RATE_TYPES.DAILY ||
        displayRateType === RATE_TYPES.WEEKLY ||
        displayRateType === RATE_TYPES.MONTHLY) &&
      displayRateMultiplier &&
      displayRateMultiplier !== 1
    ) {
      // convert to display values
      if (payRateMin) payRateMin *= displayRateMultiplier;
      if (payRateMax) payRateMax *= displayRateMultiplier;
      if (billRateMin) billRateMin *= displayRateMultiplier;
      if (billRateMax) billRateMax *= displayRateMultiplier;
    }
  }

  return (
    <>
      <Text as="h5">
        Values from ratecard #{ratecardId} {ratecardName ? `"${ratecardName}"` : null}:
      </Text>

      <Table
        css={{ width: "$2-of-5 !important" }}
        highlighted={false}
        center
        highlightFirstColumn
      >
        <THead>
          <TR>
            <TH />
            {rateType === RATE_TYPES.ANNUAL ? (
              <TH>
                <Text nowrap>Annual Salary</Text>
              </TH>
            ) : (
              <>
                <TH>
                  <Text nowrap>Pay Rate</Text>
                </TH>
                <TH>
                  <Text nowrap>Markup</Text>
                </TH>
                <TH>
                  <Text nowrap>Bill Rate</Text>
                </TH>
              </>
            )}
          </TR>
        </THead>
        <TBody>
          <TR>
            <TD>High</TD>
            {rateType === RATE_TYPES.ANNUAL ? (
              <TD>
                <Text nowrap>
                  {decimalFormatter(annualSalaryMax, false, currencySymbol)}
                </Text>
              </TD>
            ) : (
              <>
                <TD>
                  <Text nowrap>
                    {decimalFormatter(payRateMax, false, currencySymbol)}
                  </Text>
                </TD>
                <TD>
                  <Text nowrap>{percentFormatter(markupMax)}</Text>
                </TD>
                <TD>
                  <Text nowrap>
                    {decimalFormatter(billRateMax, false, currencySymbol)}
                  </Text>
                </TD>
              </>
            )}
          </TR>
          <TR>
            <TD>Low</TD>
            {rateType === RATE_TYPES.ANNUAL ? (
              <TD>
                <Text nowrap>
                  {decimalFormatter(annualSalaryMin, false, currencySymbol)}
                </Text>
              </TD>
            ) : (
              <>
                <TD>
                  <Text nowrap>
                    {decimalFormatter(payRateMin, false, currencySymbol)}
                  </Text>
                </TD>
                <TD>
                  <Text nowrap>{percentFormatter(markupMin)}</Text>
                </TD>
                <TD>
                  <Text nowrap>
                    {decimalFormatter(billRateMin, false, currencySymbol)}
                  </Text>
                </TD>
              </>
            )}
          </TR>
        </TBody>
      </Table>
    </>
  );
}
ValuesFromRatecardBlock.displayName = "ValuesFromRatecardBlock";

type JobInternalValuesBlockProps = {
  embedded?: boolean;
};

const JobInternalValuesBlock = (props: JobInternalValuesBlockProps) => {
  const { embedded = false } = props;
  const { firstJobCurrencyData } = useReviewDataContext();

  // render

  const contentBlock = (
    <Stack css={{ alignItems: "flex-start" }}>
      <ValuesFromRatecardBlock />
      <ValuesFromCollectionBlock />
    </Stack>
  );

  if (embedded) return contentBlock;

  const currencyCode = firstJobCurrencyData.get("code");
  const currencyName = firstJobCurrencyData.get("name");
  const currencyTitle = currencyName ? (
    <Text as="small" color="primary">
      ({currencyName}, {currencyCode})
    </Text>
  ) : (
    ""
  );

  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft>
          <CardHeaderTitle as="h3">
            PeopleTicker Data On Title {currencyTitle}
          </CardHeaderTitle>
        </CardActionsLeft>
      </CardActions>
      <CardBody>{contentBlock}</CardBody>
    </Card>
  );
};
JobInternalValuesBlock.displayName = "JobInternalValuesBlock";

export default JobInternalValuesBlock;
