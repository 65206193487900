import React, { Suspense } from "react";
import graphql from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { RateSearchPageQuery } from "./__generated__/RateSearchPageQuery.graphql";
import { Form } from "./RsForm/Form";
import { RsRecentSearches, RsRecentSearchesLoader } from "./RecentSearches";
import { styled } from "../../stitches.config";
import { PageContaier } from "../../components/lib/Container";

/**
 * Css container queries
 */
const FormView = styled("div", {
  containerName: "RateSearchFormView",
  containerType: "inline-size",
  width: "100%",
});

const query = graphql`
  query RateSearchPageQuery {
    viewer {
      ...FormRateSearch_viewer
      ...RecentSearches_viewer
    }
  }
`;

function RateSearchPage() {
  const data = useLazyLoadQuery<RateSearchPageQuery>(query, {});

  if (!data?.viewer) {
    return null;
  }

  return (
    <PageContaier withPaddingTop>
      <FormView>
        <Form isSideMenu={false} viewer={data.viewer} />
        <Suspense fallback={<RsRecentSearchesLoader pageSize={9} />}>
          <RsRecentSearches viewer={data.viewer} pageSize={9} />
        </Suspense>
      </FormView>
    </PageContaier>
  );
}

export default RateSearchPage;
