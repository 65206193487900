import React from "react";
import { usePaginationFragment, useFragment } from "react-relay";
// @ts-ignore
import graphql from "babel-plugin-relay/macro";
import { useVirtualizer } from "@tanstack/react-virtual";

import Box from "./lib/Box";
import { TextOverflow } from "./lib/Text";
import Icon from "./lib/Icon";
import Stack from "./lib/Stack";
import Inline from "./lib/Inline";
import Button from "./lib/Button";
import { RadioGroup, RadioGroupItem } from "./lib/RadioGroup";
import { CardListItem, CardListItemLeft } from "./lib/Card";
import { Checkbox, CheckboxIndicator, LabelInline } from "./lib/Checkbox";

import {
  FilterDialog,
  FilterDialogProps,
  SortDirectionType,
  SORT_DIRECTION,
} from "./FilterDialog";
import { SelectionSet, useSelectionMachine } from "../machines/selectionMachine";

import {
  FilterByAuthorDialog_viewer$key,
  FilterByAuthorDialog_viewer$data,
} from "./__generated__/FilterByAuthorDialog_viewer.graphql";
import { TickerContentLoader } from "./lib/TickerLoader";
import SearchBox from "./lib/SearchBox";
import type { FilterByAuthorDialogPaginationQuery$variables } from "./__generated__/FilterByAuthorDialogPaginationQuery.graphql";

type AuthorEdges = NonNullable<FilterByAuthorDialog_viewer$data["authors"]>["edges"];

const authorsQueryFragment = graphql`
  fragment FilterByAuthorDialog_viewer on Viewer
  @refetchable(queryName: "FilterByAuthorDialogPaginationQuery")
  @argumentDefinitions(
    after: { type: "String" }
    before: { type: "String" }
    first: { type: "Int" }
    last: { type: "Int" }
    search: { type: "String" }
    order: { type: "[AuthorSortInput]" }
  ) {
    authors(
      after: $after
      before: $before
      first: $first
      last: $last
      search: $search
      order: $order
      filters: { isActive: true }
    ) @connection(key: "FilterByAuthorDialog_authors") {
      edges {
        node {
          id
          userId
          firstName
          lastName
          username
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

type AuthorsVirtualListProps = {
  viewer: FilterByAuthorDialog_viewer$key;
  searchTerm: string;
  sortDirection?: SortDirectionType;
  selectedAuthors: SelectionSet<number>;
  onCheckedChange: (checked: boolean, userId: number) => void;
};

export function AuthorsVirtualList(props: AuthorsVirtualListProps) {
  const { searchTerm, sortDirection, selectedAuthors } = props;

  // data query

  const { data: viewer, refetch } = usePaginationFragment(
    authorsQueryFragment,
    props.viewer
  );
  const authorsEdges: AuthorEdges = viewer.authors?.edges ?? [];

  // effects

  const prevSearchTermRef = React.useRef<string>(searchTerm);
  const prevSortDirectionRef = React.useRef<SortDirectionType | undefined>(sortDirection);

  React.useEffect(() => {
    if (
      sortDirection !== prevSortDirectionRef.current ||
      searchTerm !== prevSearchTermRef.current
    ) {
      prevSortDirectionRef.current = sortDirection;
      prevSearchTermRef.current = searchTerm;

      const variables: FilterByAuthorDialogPaginationQuery$variables = {};
      if (!!searchTerm) {
        variables["search"] = searchTerm;
      }
      if (sortDirection != null) {
        variables["order"] = [
          { field: "FIRST_NAME", direction: sortDirection },
          { field: "LAST_NAME", direction: sortDirection },
        ];
      }

      refetch(variables, { fetchPolicy: "network-only" });
    }
  }, [searchTerm, sortDirection, refetch]);

  // list virtualization

  const parentRef = React.useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtualizer<HTMLDivElement, HTMLDivElement>({
    count: authorsEdges.length,
    getScrollElement: () => parentRef.current!,
    estimateSize: React.useCallback(() => 57, []),
    overscan: 5,
  });

  return (
    <Box
      ref={parentRef}
      css={{
        height: "250px",
        width: "$full",
        overflow: "auto",
      }}
    >
      <Box
        css={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: "$full",
          position: "relative",
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualItem) => {
          const authorNode = authorsEdges[virtualItem.index]!.node;
          const authorUserId = authorNode!.userId;
          const authorUserName = authorNode!.username;
          const authorFullName = [authorNode!.firstName, authorNode!.lastName]
            .filter((i) => !!i)
            .join(" ");
          const authorFullLine = [authorFullName, authorUserName]
            .filter((i) => !!i)
            .join(", ");

          return (
            <Box
              key={virtualItem.key}
              css={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "$full",
                height: `${virtualItem.size}px`,
                transform: `translateY(${virtualItem.start}px)`,
              }}
            >
              <CardListItem css={{ padding: 0 }}>
                <CardListItemLeft>
                  <LabelInline nowrap css={{ padding: "20px", overflow: "hidden" }}>
                    <Checkbox
                      checked={selectedAuthors.has(authorUserId)}
                      onCheckedChange={(checked) => {
                        if (checked !== "indeterminate" && authorUserId != null) {
                          props.onCheckedChange(checked, authorUserId);
                        }
                      }}
                    >
                      <CheckboxIndicator>
                        <Icon icon="check" />
                      </CheckboxIndicator>
                    </Checkbox>
                    <TextOverflow title={authorFullLine}>
                      {authorFullName} ({authorUserName})
                    </TextOverflow>
                  </LabelInline>
                </CardListItemLeft>
              </CardListItem>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

AuthorsVirtualList.displayName = "AuthorsVirtualList";

export type FilterByAuthorDialogProps = {
  title?: string;
  viewer: FilterByAuthorDialog_viewer$key;
  disableApply: boolean;
  // toggle style modifiers
  filtered?: boolean;
  sorted?: SortDirectionType;
  // values
  searchTerm?: string;
  sortDirection?: SortDirectionType;
  selectedAuthors: AuthorsVirtualListProps["selectedAuthors"];
  // event handlers
  onChangeSortDirection: (direction: SortDirectionType) => void;
  onChangeSearchTerm: (value: string) => void;
  onCheckedChange: AuthorsVirtualListProps["onCheckedChange"];
  onSelectAll: React.MouseEventHandler<HTMLButtonElement>;
  onDeSelectAll: React.MouseEventHandler<HTMLButtonElement>;
  onApply: FilterDialogProps["onApply"];
  onClear: FilterDialogProps["onClear"];
};

export function FilterByAuthorDialog(props: FilterByAuthorDialogProps) {
  const {
    title = "Filter By Author",
    disableApply = false,
    filtered,
    sorted,
    searchTerm = "",
    sortDirection,
    selectedAuthors,
    onChangeSortDirection,
    onChangeSearchTerm,
    onCheckedChange,
    onSelectAll,
    onDeSelectAll,
  } = props;

  const sortedObj = React.useMemo(() => {
    if (!sorted) return sorted;
    return {
      dataType: "alpha" as const,
      direction: sorted,
    };
  }, [sorted]);

  // state

  const [searchBoxState, setSearchBoxState] = React.useState<string>(searchTerm);

  // effects

  const prevSerchTermRef = React.useRef<string>(searchTerm);
  React.useEffect(() => {
    if (searchTerm !== prevSerchTermRef.current) {
      prevSerchTermRef.current = searchTerm;

      // reset search box state in case somebody has clicked "Clear Filter" button
      if (!searchTerm && !!searchBoxState) {
        setSearchBoxState("");
      }
    }
  }, [searchTerm, searchBoxState]);

  // handlers

  const handleSearchBoxStateChange = React.useCallback(
    (value?: string) => {
      if (value !== searchBoxState) setSearchBoxState(value ?? "");
    },
    [searchBoxState]
  );

  const handleSearchBoxSubmit = React.useCallback(
    (value?: string) => {
      if (value !== searchBoxState) setSearchBoxState(value ?? "");
      if (value !== searchTerm) onChangeSearchTerm(value ?? "");
    },
    [searchBoxState, searchTerm, onChangeSearchTerm]
  );

  const handleSortDirectionChange = React.useCallback(
    (value: SortDirectionType) => {
      if (value !== sortDirection) onChangeSortDirection(value);
    },
    [sortDirection, onChangeSortDirection]
  );

  return (
    <FilterDialog
      title={title}
      description={
        <Stack css={{ alignItems: "flex-start" }}>
          <Inline fill css={{ "& button": { flexGrow: 1 } }}>
            <span>Sort:</span>
            <RadioGroup value={sortDirection} onValueChange={handleSortDirectionChange}>
              <Inline>
                <RadioGroupItem value={SORT_DIRECTION.ASC}>
                  <Icon icon={`sort-alpha-down`} css={{ color: "$accent" }} /> Sort A to Z
                </RadioGroupItem>
                <RadioGroupItem value={SORT_DIRECTION.DESC}>
                  <Icon icon={`sort-alpha-down-alt`} css={{ color: "$accent" }} /> Sort Z
                  to A
                </RadioGroupItem>
              </Inline>
            </RadioGroup>
          </Inline>
          <span>Filter by specific Users:</span>
          <Inline fill css={{ justifyContent: "space-between" }}>
            <SearchBox
              value={searchBoxState}
              onChange={handleSearchBoxStateChange}
              onSubmit={handleSearchBoxSubmit}
              css={{ width: 180 }}
            />
            <Inline css={{ gap: "$2" }}>
              <Button onClick={onSelectAll}>Select All</Button>
              <Button onClick={onDeSelectAll}>Deselect All</Button>
            </Inline>
          </Inline>
        </Stack>
      }
      filtered={filtered}
      sorted={sortedObj}
      disableApply={disableApply}
      onApply={props.onApply}
      onClear={props.onClear}
    >
      <React.Suspense
        fallback={
          <Box css={{ height: "300px", position: "relative" }}>
            <TickerContentLoader />
          </Box>
        }
      >
        <AuthorsVirtualList
          viewer={props.viewer}
          searchTerm={searchTerm}
          sortDirection={sortDirection}
          selectedAuthors={selectedAuthors}
          onCheckedChange={onCheckedChange}
        />
      </React.Suspense>
    </FilterDialog>
  );
}

FilterByAuthorDialog.displayName = "FilterByAuthorDialog";

type FilterByAuthorInitialStateType = {
  searchTerm: string;
  sortDirection?: SortDirectionType;
  selection?: SelectionSet<number>;
};

export function useFilterByAuthorDialogState(
  initialState: FilterByAuthorInitialStateType,
  viewer: FilterByAuthorDialog_viewer$key,
  machineName: string
) {
  const [searchTerm, setSearchTerm] = React.useState<string>(initialState.searchTerm);
  const [sortDirection, setSortDirection] = React.useState<SortDirectionType | undefined>(
    initialState.sortDirection
  );

  // retrieve fragment data from cache
  const data = useFragment(authorsQueryFragment, viewer);

  const getAllAuthorsIdsFromViewer = React.useCallback(() => {
    if (!data.authors?.edges) return [];

    return data.authors?.edges.reduce((acc, edge) => {
      if (edge?.node) {
        acc.push(edge?.node?.userId);
      }
      return acc;
    }, [] as number[]);
  }, [data.authors]);

  const {
    selectionState,
    handleSelectAll,
    handleDeSelectAll,
    handleCheckedChange,
    // TODO use initial selection state to provide initial context to the state machine
  } = useSelectionMachine<number>(getAllAuthorsIdsFromViewer, machineName);

  const resetWholeState = React.useCallback(() => {
    setSearchTerm("");
    setSortDirection(undefined);
    handleDeSelectAll();
  }, [handleDeSelectAll]);

  return {
    searchTerm,
    sortDirection,
    selectionState,
    handleSelectAllAuthors: handleSelectAll,
    handleDeSelectAllAuthors: handleDeSelectAll,
    handleAuthorCheckedChange: handleCheckedChange,
    handleAuthorSearchTermChange: setSearchTerm,
    handleSortByAuthorDirectionChange: setSortDirection,
    handleFilterByAuthorDialogStateReset: resetWholeState,
  };
}
