import React, { useEffect } from "react";
import graphql from "babel-plugin-relay/macro";
// store
import { useSearchResultsStore } from "../../stores/SearchResults";
// relay
import { useLazyLoadQuery } from "react-relay";
import { RateSearchResultsPageQuery } from "./__generated__/RateSearchResultsPageQuery.graphql";
// design-system
import Inline from "../../components/lib/Inline";
// components
import { SideMenu } from "./RsResults/SideMenu";
import { Content } from "./RsResults/Content";
import { useSearchId } from "./hooks/useSearchId";
import { NotFoundBoundary } from "./NotFoundBoundary";
import { styled } from "../../stitches.config";
import { PageContaier } from "../../components/lib/Container";

const Wrapper = styled(Inline, {
  gap: "$0",
  flexWrap: "nowrap",
  alignItems: "flex-start",
  width: "100%",
});

const query = graphql`
  query RateSearchResultsPageQuery($searchId: Int!) {
    viewer {
      ...SideMenu_viewer
      ...ContentRateSearch_viewer @arguments(searchId: $searchId)
    }
  }
`;

function RateSearchResultsPage() {
  const { searchId } = useSearchId();

  const data = useLazyLoadQuery<RateSearchResultsPageQuery>(query, {
    searchId,
  });

  const initializeRates = useSearchResultsStore((s) => s.initializeRates);

  useEffect(() => {
    initializeRates();
  }, [initializeRates]);

  if (!data?.viewer) {
    return null;
  }

  return (
    <PageContaier>
      <Wrapper>
        <SideMenu viewer={data.viewer} />
        <Content viewer={data.viewer} />
      </Wrapper>
    </PageContaier>
  );
}

function ResultsPage() {
  return (
    <NotFoundBoundary>
      <RateSearchResultsPage />
    </NotFoundBoundary>
  );
}

export default ResultsPage;
