import React, { useState, ChangeEvent, useMemo, useCallback, useEffect } from "react";
import graphql from "babel-plugin-relay/macro";
import debounce from "lodash/debounce";
import { useLazyRelayQuery } from "../../../hooks/useRelayQuery";
import { JobTitleFieldQuery } from "./__generated__/JobTitleFieldQuery.graphql";
import { Autocomplete } from "../../../components/lib/Autocomplete";
import { Link } from "../../../components/lib/Link";
import { JobTags } from "./JobTags";
import { JobTitle, JobTitleInfo } from "../types";
import { JobTitleModals } from "./JobTitleModals";
import Box from "../../../components/lib/Box";
import { useGlobalContext } from "../../../globalContext";
import { useRateSearchFormStore } from "../../../stores/RateSearchForm";

const gql = graphql`
  query JobTitleFieldQuery($searchParam: String, $libraryId: ID, $countryId: ID) {
    viewer {
      jobTitles(
        search: $searchParam
        page: 1
        pageSize: 25
        libraryId: $libraryId
        countryId: $countryId
      ) {
        results {
          isHealthcare: showQuantiles
          id @required(action: NONE)
          title @required(action: NONE)
          isJobLabel
          collection
          shareInfo {
            isMine
            sharedBy {
              firstName
              lastName
            }
          }
          clientJobLibraryInfo {
            adhocCountries
            certifiedCountries
          }
        }
      }
    }
  }
`;

type Props = {
  isSideMenu: boolean;
  onJobTitleChange(job: JobTitle | null): void;
};

export function JobTitleField(props: Props) {
  const { isSideMenu, onJobTitleChange } = props;

  const { perSearchPricing } = useGlobalContext();
  const job = useRateSearchFormStore((s) => s.jobTitle);
  const resetForm = useRateSearchFormStore((s) => s.reset);
  const jobLibrary = useRateSearchFormStore((s) => s.jobLibrary);
  const countryLibrary = useRateSearchFormStore((s) => s.countryLibrary);

  const [options, setOptions] = useState<JobTitle[]>([]);
  const [value, setValue] = useState("");

  // !important: Unable to useRefetchableFragment, no useTransition available.
  const { send, loading } = useLazyRelayQuery<JobTitleFieldQuery>({
    gql,
    onComplete(data) {
      const result = (data?.viewer?.jobTitles?.results || []) as JobTitle[];
      setOptions(result);
    },
  });

  const fetchSearch = useMemo(
    () =>
      debounce(function (searchParam: string) {
        if (searchParam.length > 0) {
          send({
            searchParam,
            libraryId: jobLibrary?.databaseId ? String(jobLibrary?.databaseId) : null,
            countryId: countryLibrary?.databaseId
              ? String(countryLibrary?.databaseId)
              : null,
          });
        } else {
          setOptions([]);
        }
      }, 250),
    [jobLibrary, countryLibrary, send]
  );

  const onResetJobOptions = useCallback(() => {
    setOptions([]);
    setValue("");
  }, []);

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const search = e.target.value;
      setValue(search);
      fetchSearch(search);
    },
    [fetchSearch]
  );

  const handleJobTitleChange = useCallback(
    (jobTitle: JobTitle | null) => {
      onJobTitleChange(jobTitle);
      setValue(jobTitle?.title || "");
    },
    [onJobTitleChange]
  );

  const handleGetSearchResults = useCallback(
    (results: JobTitleInfo) => {
      const { jobTitle } = results;
      handleJobTitleChange(jobTitle);
      setOptions([]);
    },
    [handleJobTitleChange]
  );

  // TODO: Fix setState in useEffect
  useEffect(() => {
    if (job) {
      setValue(job.title);
    }
    return () => setValue("");
  }, [job]);

  useEffect(() => {
    return () => resetForm();
  }, [resetForm]);

  return (
    <Box>
      <Autocomplete
        icon="search"
        label="Job Title"
        extraLabel={
          <JobTitleModals
            handleGetSearchResults={handleGetSearchResults}
            onResetJobOptions={onResetJobOptions}
          />
        }
        inputValue={value}
        onInputChange={onInputChange}
        selected={job}
        onResetOptions={onResetJobOptions}
        onSelect={handleJobTitleChange}
        id="rsjob-title-select"
        required
        loading={loading}
        placeholder="Search by job title or keywords"
        options={options}
        getKey={(o) => o.id}
        renderAddon={(o) =>
          o && (
            <JobTags
              isSideMenu={isSideMenu}
              job={o}
              addMargin
              perSearchPricing={perSearchPricing}
            />
          )
        }
        renderOption={(o) => (
          <>
            <span>{o.title}</span>
            <JobTags job={o} addMargin perSearchPricing={perSearchPricing} />
          </>
        )}
        noResultsRender={
          <>
            <span>No results matched your search</span>
            {!perSearchPricing && (
              <Link css={{ marginLeft: "$2" }} to="/job-library/view">
                Browse all Jobs
              </Link>
            )}
          </>
        }
      />
    </Box>
  );
}
