import React from "react";

import Icon from "../../../components/lib/Icon";
import ProgressBar from "../../../components/lib/ProgressBar";
import Text from "../../../components/lib/Text";
import Tooltip from "../lib/Tooltip";
import { secondsToIntervalString } from "../constants";
import { PROCESSING_STATUSES, PROCESSING_STATUSES_LABELS } from "../types";

import type { ProcessingDataMap } from "../types";

const baseClassName = "pt-processing-progress-bar";

const statusIcons = {
  ["" + PROCESSING_STATUSES.FINISHED]: (
    <Text as={Icon} color="positive" icon="check" size="sm" />
  ),
  ["" + PROCESSING_STATUSES.FAILED]: (
    <Text as={Icon} color="negative" icon="exclamation" size="sm" />
  ),
};

interface ProcessingProgressBarProps {
  processing: ProcessingDataMap;
}

const ProcessingProgressBar = ({
  processing,
}: ProcessingProgressBarProps): React.ReactElement => {
  const status = processing.get("status") ?? PROCESSING_STATUSES.FINISHED;
  const statusIcon = statusIcons["" + status] ?? null;

  if (status !== PROCESSING_STATUSES.RUNNING) {
    return (
      <span title="All data processing procedures are finished.">
        {statusIcon}&nbsp;
        {PROCESSING_STATUSES_LABELS[status]}
      </span>
    );
  }

  const totalItems = processing.get("total_items");
  const finishedItems = processing.get("finished_items");
  const failedItems = processing.get("failed_items");
  const estimatedTimeLeft = processing.get("estimated_time_left");

  if (finishedItems > 0 || failedItems > 0) {
    return (
      <ProgressBar
        progress={finishedItems + failedItems}
        total={totalItems}
        postLabel={
          !!estimatedTimeLeft
            ? `(${secondsToIntervalString(estimatedTimeLeft)} left)`
            : null
        }
      />
    );
  }

  const queuePositionNumber = processing.get("queue_position_number");

  if (queuePositionNumber != null && queuePositionNumber > 0) {
    return (
      <Tooltip
        side="top"
        content={`waiting for another ${queuePositionNumber} processes to finish...`}
      >
        <span className={baseClassName + "__status"}>
          queueing
          <span className={baseClassName + "__counter"}>{queuePositionNumber}</span>
        </span>
      </Tooltip>
    );
  }

  return <span>queueing</span>;
};

export default ProcessingProgressBar;
