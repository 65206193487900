// @ts-expect-error
import numeral from "numeral";
import { fromJS, List, OrderedMap, Map } from "immutable";

import { getFilteredRatesKeys } from "./constants";
import { RATE_TYPES } from "./types";
import { emptyList, emptyOrderedMap } from "../../constants";
import {
  baseDjangoResponseConverter,
  SpecificDjangoResponseConverter,
} from "../../utils/django";
// @ts-expect-error
import byKey from "../../utils/byKey";

import type {
  JobValidationDataObject,
  JobsValidationsOrderedMap,
} from "../private_index/types";
import type {
  IndustryObject,
  IndustryDataMap,
  IndustryDataList,
} from "./components/selects/IndustriesSelect";

import type {
  CollectionLimitsObject,
  CollectionLimitsMap,
  CountryInfoDataObject,
  CountryInfoDataMap,
  UploadDataObject,
  UploadDataMap,
  UploadDataList,
  AlertDataObject,
  AlertDataMap,
  AlertsDataOrderedMap,
  TotalFeesPaidDataObject,
  TotalFeesPaidDataMap,
  TotalFeesPaidDataOrderedMap,
  NdaHistoryDataObject,
  NdaHistoryDataMap,
  NdaHistoryDataOrderedMap,
  ValidatorSelectFormObject,
  ValidatorSelectFormDataMap,
  ValidatorSelectFormDataList,
  PermissionDataObject,
  PermissionDataMap,
  PermissionsDataOrderedMap,
  JobDataObject,
  JobDataMap,
  JobsDataOrderedMap,
  AttemptDataObject,
  AttemptDataMap,
  ReviewDataObject,
  ReviewDataMap,
  ReviewsDataList,
  ReviewsDataOrderedMap,
  RatesFeedbackDataObject,
  RatesFeedbackDataMap,
  RatesFeedbackOrderedMap,
  JobFeedbackDataObject,
  JobFeedbackDataMap,
  JobsFeedbackDataOrderedMap,
  QuestionFeedbackDataObject,
  QuestionFeedbackDataMap,
  QuestionsFeedbackOrderedMap,
  AttemptsDataOrderedMap,
  CountryDataObject,
  CountryDataMap,
  CountriesDataList,
  QuestionDataObject,
  QuestionDataMap,
  OverallJobFeedbackDataObject,
  OverallJobFeedbackDataMap,
  OverallJobFeedbackDataOrderedMap,
  CurrencyDataMap,
  RateResultDataObject,
  RateResultDataMap,
  RateResultsOrderedMap,
  REQUIRED_LEVELS_LIST,
  REQUIRED_RATES_LIST,
  QUESTION_ANSWER_FORMATS_TYPE,
  QuestionsOrderedMap,
} from "./types";

function transformRatesValues<T extends object>(obj: T, ratesKeys: Array<keyof T>) {
  ratesKeys.forEach((key) => {
    const value = obj[key];

    if (value && typeof value === "string") {
      obj[key] = numeral().unformat(value);
    }
  });

  return obj;
}

type IdentifiableObjectType = { id: number };

export const defaultIdentifierFunc = (item: IdentifiableObjectType) => item["id"];

// rate results converters

export const rateResultToImmutableMap = (
  item: RateResultDataObject
): RateResultDataMap => {
  // always handle full list of values - pay rate, bill rate, markup
  return fromJS(transformRatesValues(item, getFilteredRatesKeys(RATE_TYPES.HOURLY)));
};

export const rateResultsListToImmutableOrderedMap = (
  list: RateResultDataObject[]
): RateResultsOrderedMap => {
  return byKey(list, defaultIdentifierFunc, OrderedMap, rateResultToImmutableMap);
};

// job converters

export const jobToImmutableMap = (item: JobDataObject): JobDataMap => {
  item["hourly_multiplier"] = parseFloat(item["hourly_multiplier"] as unknown as string);
  item["daily_multiplier"] = parseFloat(item["daily_multiplier"] as unknown as string);
  item["weekly_multiplier"] = parseFloat(item["weekly_multiplier"] as unknown as string);
  item["monthly_multiplier"] = parseFloat(
    item["monthly_multiplier"] as unknown as string
  );

  item["rate_results"] =
    item["rate_results"] != null
      ? rateResultsListToImmutableOrderedMap(
          item["rate_results"] as unknown as RateResultDataObject[]
        )
      : (emptyOrderedMap as unknown as RateResultsOrderedMap);

  if (item["currency_code"] != null) {
    item["currency_factor"] = parseFloat(item["currency_factor"] as unknown as string);
    item["currency_data"] = Map({
      code: item["currency_code"],
      name: item["currency_name"],
      symbol: item["currency_symbol"],
      factor: item["currency_factor"],
    }) as unknown as CurrencyDataMap;
  }

  return fromJS(item);
};

export const jobsListToImmutableOrderedMap = (
  list: JobDataObject[]
): JobsDataOrderedMap => {
  return byKey(list, defaultIdentifierFunc, OrderedMap, jobToImmutableMap);
};

// review converters

export const reviewToImmutableMap = (item: ReviewDataObject): ReviewDataMap => {
  item["client_id"] = parseInt(item["client_id"] as unknown as string, 10);
  item["program_id"] = parseInt(item["program_id"] as unknown as string, 10);
  item["stored_index_id"] =
    item["stored_index_id"] != null
      ? parseInt(item["stored_index_id"] as unknown as string, 10)
      : null;
  item["ratecard_id"] =
    item["ratecard_id"] != null
      ? parseInt(item["ratecard_id"] as unknown as string, 10)
      : null;

  item["created"] = new Date(item["created"]);
  item["reward_per_job"] = parseFloat(item["reward_per_job"] as unknown as string);
  item["required_levels"] =
    item["required_levels"] != null
      ? (List(item["required_levels"]) as unknown as REQUIRED_LEVELS_LIST)
      : emptyList;
  item["required_rates"] =
    item["required_rates"] != null
      ? (List(item["required_rates"]) as unknown as REQUIRED_RATES_LIST)
      : emptyList;

  item["jobs"] = item["jobs"]
    ? jobsListToImmutableOrderedMap(item["jobs"] as unknown as JobDataObject[])
    : (emptyOrderedMap as unknown as JobsDataOrderedMap);
  item["questions"] = item["questions"]
    ? questionsListToImmutableOrderedMap(
        item["questions"] as unknown as QuestionDataObject[]
      )
    : (emptyOrderedMap as unknown as QuestionsOrderedMap);

  // all validation attempts taken on this survey
  item["attempts"] = item["attempts"]
    ? reviewAttemptsListToImmutableOrderedMap(
        item["attempts"] as unknown as AttemptDataObject[]
      )
    : (emptyOrderedMap as unknown as AttemptsDataOrderedMap);

  // related validation attempt for some particular user
  // (user specified in the data request, usually currently authorized user)
  item["attempt"] = item["attempt"]
    ? reviewAttemptToImmutableMap(item["attempt"] as unknown as AttemptDataObject)
    : null;

  if (item["display_currency_code"] != null) {
    item["display_currency_factor"] =
      item["display_currency_factor"] != null
        ? parseFloat(item["display_currency_factor"] as unknown as string)
        : null;
    item["display_currency_data"] = Map({
      code: item["display_currency_code"],
      name: item["display_currency_name"],
      symbol: item["display_currency_symbol"],
      factor: item["display_currency_factor"],
    }) as unknown as CurrencyDataMap;
  }

  return fromJS(item);
};

export const reviewsListToImmutableOrderedMap = (
  list: ReviewDataObject[]
): ReviewsDataOrderedMap => {
  return byKey(list, defaultIdentifierFunc, OrderedMap, reviewToImmutableMap);
};

export const reviewsListToImmutableList = (list: ReviewDataObject[]): ReviewsDataList => {
  return List(list.map(reviewToImmutableMap)) as unknown as ReviewsDataList;
};

export const transformReviewsData: SpecificDjangoResponseConverter<
  ReviewDataObject,
  ReviewsDataOrderedMap
> = (...args) => {
  return baseDjangoResponseConverter(
    reviewsListToImmutableOrderedMap,
    emptyOrderedMap as unknown as ReviewsDataOrderedMap,
    ...args
  );
};

// feedback converters

export const rateFeedbackToImmutableMap = (
  item: RatesFeedbackDataObject
): RatesFeedbackDataMap => {
  return fromJS(transformRatesValues(item, getFilteredRatesKeys(RATE_TYPES.HOURLY)));
};

export const rateFeedbackListToImmutableOrderedMap = (
  list: RatesFeedbackDataObject[]
): RatesFeedbackOrderedMap => {
  return byKey(
    list,
    (i: RatesFeedbackDataObject) => i["rate_result"],
    OrderedMap,
    rateFeedbackToImmutableMap
  );
};

export const jobFeedbackToImmutableMap = (
  item: JobFeedbackDataObject
): JobFeedbackDataMap => {
  item["created"] = new Date(item["created"]);
  return fromJS(
    transformRatesValues(item, [
      "pay_rate_min",
      "pay_rate_max",
      "employment_taxes_percentage",
      "supplier_rewards_percentage_min",
      "supplier_rewards_percentage_max",
      "annual_salary_min",
      "annual_salary_max",
    ])
  );
};

export const jobFeedbackListToImmutableOrderedMap = (
  list: JobFeedbackDataObject[]
): JobsFeedbackDataOrderedMap => {
  return byKey(
    list,
    (i: JobFeedbackDataObject) => i["job"],
    OrderedMap,
    jobFeedbackToImmutableMap
  );
};

// questions feedback converters

export const questionFeedbackToImmutableMap = (
  item: QuestionFeedbackDataObject
): QuestionFeedbackDataMap => {
  item["created"] = new Date(item["created"]);

  return fromJS(item);
};
export const questionFeedbackListToImmutableOrderedMap = (
  list: QuestionFeedbackDataObject[]
): QuestionsFeedbackOrderedMap => {
  return byKey(
    list,
    (i: QuestionFeedbackDataObject) => i["question"],
    OrderedMap,
    questionFeedbackToImmutableMap
  );
};

// review attempt converters

export const reviewAttemptToImmutableMap = (item: AttemptDataObject): AttemptDataMap => {
  let limitsUpdatedTimestamp = item["limits_updated_timestamp"];
  if (limitsUpdatedTimestamp == null) {
    limitsUpdatedTimestamp = "Never";
  } else if (limitsUpdatedTimestamp !== "N/A") {
    limitsUpdatedTimestamp = new Date(limitsUpdatedTimestamp);
  }

  return fromJS({
    ...item,
    created: item["created"] != null ? new Date(item["created"]) : null,
    completed_timestamp:
      item["completed_timestamp"] != null ? new Date(item["completed_timestamp"]) : null,
    ready_for_payment_timestamp:
      item["ready_for_payment_timestamp"] != null
        ? new Date(item["ready_for_payment_timestamp"])
        : null,
    paid_timestamp:
      item["paid_timestamp"] != null ? new Date(item["paid_timestamp"]) : null,
    analysis_completed_timestamp:
      item["analysis_completed_timestamp"] != null
        ? new Date(item["analysis_completed_timestamp"])
        : null,
    limits_updated_timestamp: limitsUpdatedTimestamp,
    reward_per_job:
      item["reward_per_job"] != null
        ? parseFloat(item["reward_per_job"] as unknown as string)
        : null,
    review:
      typeof item["review"] === "string"
        ? item["review"]
        : item["review"] != null
        ? reviewToImmutableMap(item["review"] as unknown as ReviewDataObject)
        : null,
    rates_feedback: item["rates_feedback"]
      ? rateFeedbackListToImmutableOrderedMap(
          item["rates_feedback"] as unknown as RatesFeedbackDataObject[]
        )
      : emptyOrderedMap,
    jobs_feedback: item["jobs_feedback"]
      ? jobFeedbackListToImmutableOrderedMap(
          item["jobs_feedback"] as unknown as JobFeedbackDataObject[]
        )
      : emptyOrderedMap,
    questions_feedback: item["questions_feedback"]
      ? questionFeedbackListToImmutableOrderedMap(
          item["questions_feedback"] as unknown as QuestionFeedbackDataObject[]
        )
      : emptyOrderedMap,
  });
};

export const reviewAttemptsListToImmutableOrderedMap = (
  list: AttemptDataObject[]
): AttemptsDataOrderedMap => {
  return byKey(list, defaultIdentifierFunc, OrderedMap, reviewAttemptToImmutableMap);
};

export const transformAttemptsData: SpecificDjangoResponseConverter<
  AttemptDataObject,
  AttemptsDataOrderedMap
> = (...args) => {
  return baseDjangoResponseConverter(
    reviewAttemptsListToImmutableOrderedMap,
    emptyOrderedMap as unknown as AttemptsDataOrderedMap,
    ...args
  );
};

// questions converters

export const questionToImmutableMap = (item: QuestionDataObject): QuestionDataMap => {
  if (item["answer_format"] != null) {
    item["answer_format"] = parseInt(
      item["answer_format"] as unknown as string,
      10
    ) as unknown as QUESTION_ANSWER_FORMATS_TYPE;
  }
  if (item["order"] != null) {
    item["order"] = parseInt(item["order"] as unknown as string, 10);
  }

  return fromJS(item);
};

export function questionsListToImmutableOrderedMap(
  list: QuestionDataObject[]
): QuestionsOrderedMap {
  return byKey(list, defaultIdentifierFunc, OrderedMap, questionToImmutableMap);
}

// jobs validations converters

export function jobsValidationsListToImmutableOrderedMap(
  list: JobValidationDataObject[]
): JobsValidationsOrderedMap {
  return OrderedMap(
    list.map((i, idx) => [
      `_${idx}`,
      fromJS({
        id: `_${idx}`,
        row: idx + 1,
        ...i,
      }),
    ])
  ) as unknown as JobsValidationsOrderedMap;
}

// aggregated jobs feedback converters

type OverallJobFeedbackObjectKeys = keyof Omit<
  OverallJobFeedbackDataObject,
  "job" | "attempt"
>;

export function aggregatedJobFeedbackToImmutableMap(
  item: OverallJobFeedbackDataObject
): OverallJobFeedbackDataMap {
  if (item["attempt"] != null) {
    item["attempt"] = reviewAttemptToImmutableMap(
      item["attempt"] as unknown as AttemptDataObject
    );
  }
  if (item["job"] != null) {
    item["job"] = jobToImmutableMap(item["job"] as unknown as JobDataObject);
  }

  ["pay_rate", "markup", "bill_rate", "annual_salary"].forEach((type) => {
    ["min", "max"].forEach((subtype) => {
      const key = [type, subtype].join("_") as unknown as OverallJobFeedbackObjectKeys;

      if (key && item[key] != null) {
        item[key] = parseFloat(item[key] as unknown as string);
      }
    });
  });

  (
    [
      "approved_number",
      "denied_number",
      "skipped_number",
      "total_number",
    ] as unknown as OverallJobFeedbackObjectKeys[]
  ).forEach((key) => {
    if (key && item[key] != null) {
      item[key] = parseInt(item[key] as unknown as string, 10);
    }
  });

  return fromJS(item);
}

export function aggregatedJobFeedbackListToImmutableOrderedMap(
  list: OverallJobFeedbackDataObject[]
): OverallJobFeedbackDataOrderedMap {
  const getId = (item: OverallJobFeedbackDataObject) => {
    const attempt = item["attempt"] as unknown as AttemptDataObject;
    const job = item["job"] as unknown as JobDataObject;
    return `${attempt["id"]}_${job["id"]}`;
  };
  return byKey(list, getId, OrderedMap, aggregatedJobFeedbackToImmutableMap);
}

export const transformAggregatedJobFeedbackData: SpecificDjangoResponseConverter<
  OverallJobFeedbackDataObject,
  OverallJobFeedbackDataOrderedMap
> = (...args) => {
  return baseDjangoResponseConverter(
    aggregatedJobFeedbackListToImmutableOrderedMap,
    emptyOrderedMap as unknown as OverallJobFeedbackDataOrderedMap,
    ...args
  );
};

// permissions converters

export const permissionToImmutableMap = (
  item: PermissionDataObject
): PermissionDataMap => {
  return fromJS(item);
};

export const permissionsListToImmutableOrderedMap = (
  list: PermissionDataObject[]
): PermissionsDataOrderedMap => {
  return byKey(
    list,
    (i: PermissionDataObject) => i["email"],
    OrderedMap,
    permissionToImmutableMap
  );
};

export const transformPermissionsData: SpecificDjangoResponseConverter<
  PermissionDataObject,
  PermissionsDataOrderedMap
> = (...args) => {
  return baseDjangoResponseConverter(
    permissionsListToImmutableOrderedMap,
    emptyOrderedMap as unknown as PermissionsDataOrderedMap,
    ...args
  );
};

// validators data converters

export const validatorToImmutableMap = (
  item: ValidatorSelectFormObject
): ValidatorSelectFormDataMap => {
  return fromJS(item);
};

export const validatorsListToImmutableList = (
  list: ValidatorSelectFormObject[]
): ValidatorSelectFormDataList => {
  return List(
    list.map(validatorToImmutableMap)
  ) as unknown as ValidatorSelectFormDataList;
};

export const transformValidatorsData: SpecificDjangoResponseConverter<
  ValidatorSelectFormObject,
  ValidatorSelectFormDataList
> = (...args) => {
  return baseDjangoResponseConverter(
    validatorsListToImmutableList,
    emptyList as unknown as ValidatorSelectFormDataList,
    ...args
  );
};

// countries data converters

export const countryToImmutableMap = (item: CountryDataObject): CountryDataMap => {
  return fromJS(item);
};

export const countriesListToImmutableList = (
  list: CountryDataObject[]
): CountriesDataList => {
  return List(list.map(countryToImmutableMap)) as unknown as CountriesDataList;
};

export const transformCountriesData: SpecificDjangoResponseConverter<
  CountryDataObject,
  CountriesDataList
> = (...args) => {
  return baseDjangoResponseConverter(
    countriesListToImmutableList,
    emptyList as unknown as CountriesDataList,
    ...args
  );
};

// industries data converters

export const industriesToImmutableMap = (item: IndustryObject): IndustryDataMap => {
  return fromJS(item);
};

export const industriesListToImmutableList = (
  list: IndustryObject[]
): IndustryDataList => {
  return List(list.map(industriesToImmutableMap)) as unknown as IndustryDataList;
};

// NDA agreement converters

export const ndaToImmutableMap = (item: NdaHistoryDataObject): NdaHistoryDataMap => {
  return fromJS({
    ...item,
    created: new Date(item["created"]),
    updated: new Date(item["updated"]),
  });
};

export const ndaHistoryToImmutableMapOrderedMap = (
  list: NdaHistoryDataObject[]
): NdaHistoryDataOrderedMap => {
  return byKey(list, defaultIdentifierFunc, OrderedMap, ndaToImmutableMap);
};

export const transformNdaData: SpecificDjangoResponseConverter<
  NdaHistoryDataObject,
  NdaHistoryDataOrderedMap
> = (...args) => {
  return baseDjangoResponseConverter(
    ndaHistoryToImmutableMapOrderedMap,
    emptyOrderedMap as unknown as NdaHistoryDataOrderedMap,
    ...args
  );
};

// total fees paid converters

export const totalFeesPaidToImmutableMap = (
  item: TotalFeesPaidDataObject
): TotalFeesPaidDataMap => {
  if (item["fee_summ"] != null) {
    item["fee_summ"] = parseFloat(item["fee_summ"] as unknown as string);
  }
  if (item["count"] != null) {
    item["count"] = parseInt(item["count"] as unknown as string, 10);
  }

  return fromJS(item);
};

export const totalFeesPaidListToImmutableOrderedMap = (
  list: TotalFeesPaidDataObject[]
): TotalFeesPaidDataOrderedMap => {
  return byKey(
    list,
    (i: TotalFeesPaidDataObject) => i["email"],
    OrderedMap,
    totalFeesPaidToImmutableMap
  );
};

export const transformTotalFeesPaidData: SpecificDjangoResponseConverter<
  TotalFeesPaidDataObject,
  TotalFeesPaidDataOrderedMap
> = (...args) => {
  return baseDjangoResponseConverter(
    totalFeesPaidListToImmutableOrderedMap,
    emptyOrderedMap as unknown as TotalFeesPaidDataOrderedMap,
    ...args
  );
};

// alert system converters

export const alertToImmutableMap = (item: AlertDataObject): AlertDataMap => {
  return fromJS({
    ...item,
    created: new Date(item["created"]),
    updated: new Date(item["updated"]),
  });
};

const alertsListToImmutableOrderedMap = (
  list: AlertDataObject[]
): AlertsDataOrderedMap => {
  return byKey(list, (i: AlertDataObject) => i["id"], OrderedMap, alertToImmutableMap);
};

export const transformAlertsData: SpecificDjangoResponseConverter<
  AlertDataObject,
  AlertsDataOrderedMap
> = (...args) => {
  return baseDjangoResponseConverter(
    alertsListToImmutableOrderedMap,
    emptyOrderedMap as unknown as AlertsDataOrderedMap,
    ...args
  );
};

// contractors uploads converters

export const uploadToImmutableMap = (item: UploadDataObject): UploadDataMap => {
  return fromJS({
    ...item,
    created: new Date(item["created"]),
  });
};

export const uploadsListToImmutableList = (list: UploadDataObject[]): UploadDataList => {
  return List(list.map(uploadToImmutableMap)) as unknown as UploadDataList;
};

export const transformUploadsData: SpecificDjangoResponseConverter<
  UploadDataObject,
  UploadDataList
> = (...args) => {
  return baseDjangoResponseConverter(
    uploadsListToImmutableList,
    emptyList as unknown as UploadDataList,
    ...args
  );
};

// collection limits converters

export const countryInfoToImmutableMap = (
  item: CountryInfoDataObject
): CountryInfoDataMap => fromJS(item);

export const collectionLimitsToImmutableMap = (
  item: CollectionLimitsObject
): CollectionLimitsMap => {
  return fromJS({
    ...item,
    country_info:
      item["country_info"] &&
      countryInfoToImmutableMap(item["country_info"] as unknown as CountryInfoDataObject),
    created: new Date(item["created"]),
    updated: new Date(item["updated"]),
  });
};
