// @flow

import React, { Component } from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

type Props = {
  id: string,
  value: string,
  label: ?string,
  error: ?string,
  placeholder?: ?string,
  onChange?: (string) => void,
};

class TextInput extends Component<Props> {
  handleInputOnChange: (SyntheticInputEvent<HTMLInputElement>) => void;

  constructor(props: Props) {
    super(props);

    this.handleInputOnChange = this.handleInputOnChange.bind(this);
  }

  handleInputOnChange(e: SyntheticInputEvent<HTMLInputElement>) {
    if (this.props.onChange) {
      this.props.onChange(e.currentTarget.value);
    }
    // if (this.props.isLoading || this.props.pageCount === 0 || this.props.currentPage === this.props.pageCount) {
    //   return;
    // }
    //
    // const { handleNextPage } = this.props;
    //
    // handleNextPage();
  }

  render() {
    if (!this.props.id) return <code>Property "id" required on TextInput</code>;

    const errorTooltip = (
      <Tooltip id={`${this.props.id}ErrorTooltip`} placement="top">
        {this.props.error}
      </Tooltip>
    );

    return (
      <div className="form-group">
        <label htmlFor={this.props.id} className="cjl-label">
          {this.props.label}
        </label>
        <div style={{ position: "relative" }}>
          <input
            id={this.props.id}
            className="from-control cjl-input"
            value={this.props.value}
            onChange={this.handleInputOnChange}
            placeholder={this.props.placeholder}
          />
          {Boolean(this.props.error) && (
            <OverlayTrigger placement="top" overlay={errorTooltip}>
              <FontAwesomeIcon
                icon="info-circle"
                fixedWidth
                className="icon text-danger cjl-input-icon-right"
              />
            </OverlayTrigger>
          )}
        </div>
      </div>
    );
  }
}

export default observer(TextInput);
