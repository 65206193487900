import { styled } from "../../stitches.config";

export const TFoot = styled("tfoot", {});

export const THead = styled("thead", {
  borderBottom: "2px solid $primaryLight",
  "& > tr": {
    borderTop: "none",
    backgroundColor: "transparent",
    cursor: "unset",
  },
});

export const TH = styled("th", {
  backgroundColor: "#f9f9f9",
  verticalAlign: "middle !important",
  color: "$primary",
  padding: "$2_5",
  fontSize: "$sm",
  lineHeight: "$sm",
  textAlign: "left",
});

export const TBody = styled("tbody", {
  borderWidth: "0",
  borderBottom: "1px solid $primaryLight",
});

export const TR = styled("tr", {
  transition: "all 200ms ease",
  borderTop: "1px solid $primaryLight",
  "&:nth-of-type(even)": {
    backgroundColor: "$primaryLightest",
  },
  variants: {
    selected: {
      true: {
        // trick to supersede "&:nth-of-type(even)" selector
        [`${TBody} > &`]: {
          backgroundColor: "$brandLightest",
        },
      },
    },
    failed: {
      true: {
        // trick to supersede "&:nth-of-type(even)" selector
        [`${TBody} > &`]: {
          backgroundColor: "$dangerLightest",
        },
      },
    },
  },
});

export const TD = styled("td", {
  borderWidth: "0",
  height: "auto",
  verticalAlign: "middle !important",
  color: "$text",
  padding: "$2_5",
  fontSize: "$sm",
  lineHeight: "$sm",
  textAlign: "left",
  variants: {
    selected: {
      true: {
        backgroundColor: "$brandLightest",
      },
    },
    failed: {
      true: {
        backgroundColor: "$dangerLightest",
      },
    },
  },
});

export const Table = styled("table", {
  color: "$text",
  fontSize: "$base",
  lineHeight: "$base",
  width: "100%",
  maxWidth: "100%",
  variants: {
    highlighted: {
      true: {
        [`& ${TR}`]: {
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "$brandLightest",
          },
        },
      },
    },
  },
  defaultVariants: {
    highlighted: true,
  },
});
