// @flow

import React, { Component } from "react";
import type { Element } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingIndicator from "../../ratecards/components/LoadingIndicator";
import HighestPayingJobsWhiskerChart from "./HighestPayingJobsWhiskerChart";
import { CountryFilterCriterion } from "../../../models/RateCard";
import FlagIcon from "../../../components/FlagIcon";
import { OverlayTrigger, Popover } from "react-bootstrap";
import InfiniteScrollSelect from "../../../components/selects/InfiniteScrollSelect";
import RateCardDetailStore from "../../../stores/mobx/RateCardDetailStore";

type Props = {
  store: RateCardDetailStore,
};

class HighestPayingJobsWhiskerPanel extends Component<Props> {
  renderCountryItem = (
    country: CountryFilterCriterion,
    term?: ?string = null,
    highlight?: ?(string, string) => Element<any> = null
  ): Element<any> => {
    let title =
      term && highlight ? highlight(country.toString(), term) : country.toString();
    return (
      <span className="no-wrap flex">
        {country.isoCode && <FlagIcon code={country.isoCode} />} {title}
      </span>
    );
  };

  _renderHintPopover = () => {
    return (
      <Popover
        className="pt-ui-popover"
        id="popover-positioned-top"
        title="About This Chart"
      >
        <p className="text-xx-small">
          Shows the top 10 jobs per country with the highest contract/fte range on the
          Rate Card.
        </p>
        <h5>Restrictions</h5>
        <p className="text-xx-small">Region jobs are not supported.</p>
      </Popover>
    );
  };

  render() {
    const props = this.props;
    const store = props.store;
    const state = store.highestPayingJobsWhiskerState;
    const loading = store.network.loading || state.network.loading;

    return (
      <div className="panel panel-default no-margin">
        <div className="panel-heading">
          <div className="flex center-items">
            <div>
              <h3 className="panel-title">
                Top 10 Highest Paying Jobs
                <OverlayTrigger placement="top" overlay={this._renderHintPopover()}>
                  <FontAwesomeIcon
                    icon="question-circle"
                    className="icon"
                    style={{ marginLeft: ".25em" }}
                  />
                </OverlayTrigger>
              </h3>
            </div>
            <div style={{ flexGrow: 1 }} />
            <div style={{ width: 125, margin: "-10px 0 -10px 10px" }}>
              <InfiniteScrollSelect
                name="rateType"
                items={state.rateTypes}
                selectedItem={state.selectedRateType}
                onSelectedItemChange={state.onSelectedRateTypeChange}
                showSearchInput={false}
                small
                disabled={
                  !store.showSalaryVisualizations || !store.showHourlyVisualizations
                }
              />
            </div>
            <div style={{ width: 220, margin: "-10px -13px -10px 3px" }}>
              <InfiniteScrollSelect
                name="Countries"
                items={state.countriesView}
                selectedItem={state.selectedCountry}
                inputValue={state.countrySearch}
                onInputValueChange={state.onCountrySearchChange}
                onSelectedItemChange={state.onSelectedCountryChange}
                renderItem={this.renderCountryItem}
                renderSelection={this.renderCountryItem}
                small
              />
            </div>
          </div>
        </div>
        <div className="panel-body">
          <div style={{ position: "relative", height: 400, overflow: "hidden" }}>
            {!loading && (
              <HighestPayingJobsWhiskerChart
                currencySymbol={state.chartData.currency || ""}
                series={state.chartData.series}
              />
            )}
            {loading && (
              <div
                className={classNames({
                  "loading-indicator-container": true,
                  show: loading,
                })}
              >
                <LoadingIndicator
                  style={{
                    height: 400,
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default observer(HighestPayingJobsWhiskerPanel);
