// @flow
import { extendObservable } from "mobx";

export default class FilterOptionProps {
  key: string;
  label: string;
  onSelect: (Object) => void;

  constructor(key: string, label: string, onSelect: (Object) => void) {
    extendObservable(this, {
      key: key,
      label: label,
      onSelect: onSelect,
    });
  }
}
