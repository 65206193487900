import React, { Suspense } from "react";
import { observer } from "mobx-react";
import { NavBarQueryLoader } from "../../components/NavBar2";
import Footer from "../../components/lib/Footer";
import { TickerContentLoader } from "../../components/lib/TickerLoader";
import Box from "../../components/lib/Box";
import Container from "../../components/lib/Container";
import { styled } from "../../../src/stitches.config";
import { CommonChildPageProps } from "../../globalContext";

const NO_FOOTER_PAGES = ["/rate-search-result"];

const ContainerPrimary = styled(Container, {
  width: "100%",
  paddingLeft: "0px",
  paddingRight: "0px",
  minHeight: "calc(100vh - 162px)",
});

const Wrapper = styled(Box, {
  position: "relative",
});

function PrimaryComponent(props: React.PropsWithChildren<CommonChildPageProps>) {
  const { router, sessionInfo, userId, logout } = props;

  const showFooter = !NO_FOOTER_PAGES.includes(router.location.pathname);

  if (!sessionInfo || !userId) {
    router.replace({
      pathname: "/login",
      state: {
        error: {
          message: "User not logged in.",
        },
      },
    });
    return <code>Redirecting to login.</code>;
  }

  return (
    <Wrapper>
      <Suspense fallback={null}>
        <NavBarQueryLoader router={router} logout={logout} />
      </Suspense>
      <ContainerPrimary>
        <div className="view pt-ui ratecards">
          <Suspense fallback={<TickerContentLoader />}>{props.children}</Suspense>
        </div>
      </ContainerPrimary>
      {showFooter && <Footer />}
    </Wrapper>
  );
}

export default observer(PrimaryComponent);
