// @flow
import * as React from "react";
import { Environment } from "relay-runtime";
import {
  commitMutation,
  createPaginationContainer,
  QueryRenderer,
  useRelayEnvironment,
} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import LoadingIndicator from "../views/shared/LoadingIndicator";
import Modal from "./modals/Modal";
import { getVariables } from "./common";
import type { RelayPaginationProp } from "react-relay/ReactRelayTypes";
import type { RenderProps } from "react-relay/ReactRelayQueryRenderer";
import type { MutationConfig } from "relay-runtime/mutations/commitMutation";
import type { CopyRegionsWizardModalMutation } from "./__generated__/CopyRegionsWizardModalMutation.graphql";
import type { CopyRegionsWizardModal_viewer as Viewer } from "./__generated__/CopyRegionsWizardModal_viewer.graphql";
import type {
  CopyRegionsWizardModalQueryResponse,
  UserFiltersInput,
} from "./__generated__/CopyRegionsWizardModalQuery.graphql";
import ContainerSection from "../views/shared/ContainerSection";
import SelectableItem from "../views/ratecards/components/SelectableItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SelectableItemsList from "../views/ratecards/components/SelectableItemsList";
import SearchBox from "./SearchBox";
import CheckBox from "../views/shared/CheckBox";
import UserRegionsContainerSection from "./UserRegionsContainerSection";

const steps = {
  SELECT_USER: 1,
  REVIEW: 2,
  DONE: 3,
};
type WizardStep = $Keys<typeof steps>;
type UserFilters = $Shape<UserFiltersInput> | null;

type UserItem = {
  userId: number,
  regionCount: number,
};

function getFilters(search: string, activeOnly: boolean): UserFilters {
  let filters: UserFilters = null;
  if (search) filters = { usernameIContains: search };
  if (activeOnly) filters = Object.assign(filters || {}, { isActive: true });
  return filters;
}

const pageSize = 10;

const copyRegionsMutationQuery = graphql`
  mutation CopyRegionsWizardModalMutation($input: CopyUserRegionsInput!) {
    copyUserRegions(input: $input) {
      ok

      errors {
        ... on NoPermissionError {
          __typename
          message
        }

        ... on UserDoesNotExistsError {
          __typename
          message
        }
      }
    }
  }
`;

type CopyUserRegionsConfig = MutationConfig<CopyRegionsWizardModalMutation>;

const CopyUserRegions = {
  commit: function (
    environment: Environment,
    variables: $ElementType<CopyUserRegionsConfig, "variables">,
    onCompleted: $ElementType<CopyUserRegionsConfig, "onCompleted">,
    onError: $ElementType<CopyUserRegionsConfig, "onError">
  ) {
    const config: CopyUserRegionsConfig = {
      mutation: copyRegionsMutationQuery,
      variables,
      onCompleted,
      onError,
    };

    commitMutation(environment, config);
  },
};

type CopyRegionsWizardCommonProps = {|
  toUserId: number,
  isOpen: boolean,
  onClose: () => void,
|};

type CopyRegionsWizardModalImplProps = {|
  relay: RelayPaginationProp,
  viewer: Viewer,
  ...CopyRegionsWizardCommonProps,
|};

export function CopyRegionsWizardModalImpl(
  props: CopyRegionsWizardModalImplProps
): React.Node {
  const relayEnvironment = useRelayEnvironment();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [selectedUser, setSelectedUser] = React.useState<UserItem | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [activeOnly, setActiveOnly] = React.useState(true);
  const [currentStep, setCurrentStep] = React.useState<WizardStep>("SELECT_USER");
  const { relay, viewer, onClose, toUserId } = props;
  const { allUsers } = viewer;
  const { totalCount = 0, edges = [] } = allUsers || {};
  const lastPage = edges.length + 1 >= (totalCount || 0);

  let nextButtonText = "Next";
  if (steps[currentStep] === steps.REVIEW) nextButtonText = "Start Copy";
  if (steps[currentStep] === steps.DONE) nextButtonText = "Done";

  const refetchComplete = (error: ?Error) => {
    setLoading(false);
    if (error) {
      console.error(error);
      setErrorMessage("There was an error fetching the data.");
    }
  };

  const handleUserSelected = (user: UserItem) => {
    selectedUser?.userId === user.userId ? setSelectedUser(null) : setSelectedUser(user);
  };

  const handleLoadMore = () => {
    setLoading(true);
    relay.loadMore(pageSize, refetchComplete);
  };

  const handleSearch = (value: string): Promise<void> => {
    setSearch(value);
    setLoading(true);
    relay.refetchConnection(pageSize, refetchComplete, {
      filters: getFilters(value, activeOnly),
    });
    return Promise.resolve(); // typing requirement for SearchBox.onSearch
  };

  const handleActiveOnlyChange = () => {
    const newValue = !activeOnly;
    setActiveOnly(newValue);
    setLoading(true);

    relay.refetchConnection(pageSize, refetchComplete, {
      filters: getFilters(search, newValue),
    });
  };

  const handleNext = () => {
    if (steps[currentStep] === steps.SELECT_USER) {
      setCurrentStep("REVIEW");
    } else if (steps[currentStep] === steps.REVIEW) {
      if (!selectedUser) {
        setErrorMessage("No user has been selected. Go back and select one.");
        return;
      }

      setCurrentStep("DONE");
      setErrorMessage("");
      setLoading(true);

      const vars = {
        input: {
          fromUserId: selectedUser.userId,
          toUserId: toUserId,
        },
      };

      CopyUserRegions.commit(
        relayEnvironment,
        vars,
        (response, errors) => {
          setLoading(false);
          if (errors) {
            setErrorMessage("Something went wrong. Check the console for more info.");
            errors.forEach((err) => console.error(err));
          }
          if (response.copyUserRegions?.errors) {
            const { errors } = response.copyUserRegions;
            const noPermission = errors.find(
              (err) => err?.__typename === "NoPermissionError"
            );
            const userDNE = errors.find(
              (err) => err?.__typename === "UserDoesNotExistsError"
            );
            if (noPermission) {
              setErrorMessage("You don't have permission to perform this operation.");
              console.error(noPermission);
            } else if (userDNE) {
              setErrorMessage("We couldn't find the user.");
              console.error(userDNE);
            } else {
              setErrorMessage("Something went wrong. Check the console for more info.");
              errors.forEach((err) => console.error(err || "Unknown Error"));
            }
          }
        },
        (error: Error) => {
          setLoading(false);
          setErrorMessage("Something went wrong. Check the console for more info.");
          console.error(error);
        }
      );
    }
  };

  const handleBack = () => {
    if (steps[currentStep] === steps.DONE) {
      setCurrentStep("REVIEW");
      setErrorMessage("");
    } else if (steps[currentStep] === steps.REVIEW) {
      setCurrentStep("SELECT_USER");
      setErrorMessage("");
    }
  };

  return (
    <>
      <ContainerSection className="header">
        <h4>Copy User Regions</h4>
      </ContainerSection>

      <ContainerSection
        className={steps[currentStep] === steps.SELECT_USER ? "" : "hidden"}
        style={{ minWidth: 640, minHeight: 487 }}
      >
        <p>Select the user that has the regions you want to copy.</p>
        <SearchBox
          onSearch={handleSearch}
          style={{ marginBottom: 12 }}
          placeholder="Type and press ENTER to search"
        />
        <SelectableItemsList>
          {edges.map((userEdge) => {
            const { node: user } = userEdge || {};
            if (!user) return <div className="text-danger">Could not render item</div>;

            const { userId, username, isActive } = user;
            const clientName = user.client ? user.client.name || "" : "";
            const regionCount = user.regions ? user.regions.totalCount || 0 : 0;

            const userItem: UserItem = { userId, regionCount };

            return (
              <SelectableItem
                key={userId}
                item={userItem}
                name="copy-from-user"
                value={userId}
                selected={userId === selectedUser?.userId}
                onChange={handleUserSelected}
              >
                <div className="flex center-items">
                  <div style={{ fontSize: "1.75em", marginRight: 12 }}>
                    <FontAwesomeIcon icon="user" className="icon no-margin" />
                  </div>
                  <div style={{ flex: "1" }}>
                    <div style={{ lineHeight: "1em" }}>{username}</div>
                    <div className="text-x-small">
                      {regionCount} {regionCount === 1 ? "Region" : "Regions"},{" "}
                      {clientName}
                    </div>
                  </div>
                  <div>
                    {isActive && (
                      <div className="text-green text-x-small">
                        <FontAwesomeIcon
                          icon="check-circle"
                          fixedWidth
                          className="icon"
                        />{" "}
                        Active
                      </div>
                    )}
                    {!isActive && (
                      <div className="text-danger text-x-small">
                        <FontAwesomeIcon
                          icon="times-circle"
                          fixedWidth
                          className="icon"
                        />{" "}
                        Inactive
                      </div>
                    )}
                  </div>
                </div>
              </SelectableItem>
            );
          })}
          {loading && <LoadingIndicator />}
          {!lastPage && (
            <div
              className="flex center-items text-x-small"
              style={{ padding: "10px 48px" }}
            >
              <button
                className="btn btn-green"
                onClick={handleLoadMore}
                disabled={loading}
              >
                Load {pageSize} More...
              </button>
            </div>
          )}
        </SelectableItemsList>
        <div className="flex center-items text-x-small" style={{ paddingTop: 10 }}>
          <CheckBox
            type="checkbox"
            value={1}
            selected={activeOnly}
            onChange={handleActiveOnlyChange}
            disabled={loading}
          >
            Active Only
          </CheckBox>
        </div>
      </ContainerSection>

      <UserRegionsContainerSection
        userId={selectedUser?.userId || 0}
        visible={steps[currentStep] === steps.REVIEW}
      />

      <ContainerSection
        className={steps[currentStep] === steps.DONE ? "" : "hidden"}
        style={{ minWidth: 640, minHeight: 487 }}
      >
        {loading && (
          <>
            <p>Copying regions...</p>
            <LoadingIndicator />
          </>
        )}
        {!loading && (
          <>
            {errorMessage && (
              <div className="text-danger flex center-items">
                <div style={{ fontSize: "1.75em", marginRight: 12 }}>
                  <FontAwesomeIcon icon="exclamation-circle" className="icon no-margin" />
                </div>
                <div>
                  <h4>Error!</h4>
                  <p>{errorMessage}</p>
                </div>
              </div>
            )}
            {!errorMessage && (
              <div className="text-green flex center-items">
                <div style={{ fontSize: "1.75em", marginRight: 12 }}>
                  <FontAwesomeIcon icon="check" className="icon no-margin" />
                </div>
                <div>
                  <h4>Done!</h4>
                  <p>All regions have been copied.</p>
                </div>
              </div>
            )}
          </>
        )}
      </ContainerSection>
      <ContainerSection className="footer">
        <div className="flex center-items">
          {steps[currentStep] === steps.SELECT_USER && (
            <button className="btn btn-lg" onClick={onClose} style={{ minWidth: 124 }}>
              Close
            </button>
          )}
          {steps[currentStep] !== steps.SELECT_USER && (
            <button className="btn btn-lg" onClick={handleBack} style={{ minWidth: 124 }}>
              Back
            </button>
          )}
          <div style={{ flex: "1" }} />
          {steps[currentStep] !== steps.DONE && (
            <button
              className="btn btn-lg btn-green"
              onClick={handleNext}
              style={{ minWidth: 124 }}
              disabled={loading || !selectedUser?.regionCount}
            >
              {nextButtonText}
            </button>
          )}
          {steps[currentStep] === steps.DONE && (
            <button
              className="btn btn-lg btn-green"
              onClick={onClose}
              style={{ minWidth: 124 }}
              disabled={loading}
            >
              {loading ? "Copying..." : "Finish"}
            </button>
          )}
        </div>
      </ContainerSection>
    </>
  );
}

export const CopyRegionsWizardModalPaginationContainer = createPaginationContainer(
  CopyRegionsWizardModalImpl,
  {
    viewer: graphql`
      fragment CopyRegionsWizardModal_viewer on Viewer
      @argumentDefinitions(
        count: { type: "Int" }
        cursor: { type: "String", defaultValue: null }
        filters: { type: "UserFiltersInput", defaultValue: null }
      ) {
        allUsers(
          first: $count
          after: $cursor
          filters: $filters
          order: [{ field: USERNAME }]
        ) @connection(key: "CopyRegionsWizardModal_allUsers", filters: ["filters"]) {
          totalCount
          edges {
            node {
              userId
              username
              firstName
              lastName
              isActive
              client {
                name
              }
              regions(first: 0) {
                totalCount
              }
            }
          }
        }
      }
    `,
  },
  {
    direction: "forward",
    getVariables,
    query: graphql`
      query CopyRegionsWizardModalPaginationQuery(
        $count: Int!
        $cursor: String
        $filters: UserFiltersInput
      ) {
        viewer {
          ...CopyRegionsWizardModal_viewer
            @arguments(count: $count, cursor: $cursor, filters: $filters)
        }
      }
    `,
  }
);

type CopyRegionsWizardModalRendererProps = {|
  // nothing here yet
  ...CopyRegionsWizardCommonProps,
|};

function CopyRegionsWizardModalRenderer(
  props: CopyRegionsWizardModalRendererProps
): React.Node {
  const relayEnvironment = useRelayEnvironment();

  return (
    <QueryRenderer
      environment={relayEnvironment}
      query={graphql`
        query CopyRegionsWizardModalQuery(
          $count: Int!
          $cursor: String
          $filters: UserFiltersInput
        ) {
          viewer {
            ...CopyRegionsWizardModal_viewer
              @arguments(count: $count, cursor: $cursor, filters: $filters)
          }
        }
      `}
      variables={{ count: pageSize, filters: { isActive: true } }}
      render={({
        error,
        props: renderProps,
      }: RenderProps<CopyRegionsWizardModalQueryResponse>): any => {
        if (error) {
          console.error(error);
          return <div>Error!</div>;
        }

        const { viewer } = renderProps || {};
        if (!viewer) return <LoadingIndicator />;

        const { ...commonProps } = props;

        return (
          <CopyRegionsWizardModalPaginationContainer viewer={viewer} {...commonProps} />
        );
      }}
    />
  );
}

type CopyRegionsWizardModalProps = {|
  // nothing here yet
  ...CopyRegionsWizardCommonProps,
|};

function CopyRegionsWizardModal(props: CopyRegionsWizardModalProps): React.Node {
  const { isOpen, onClose } = props;
  return (
    <Modal show={isOpen} onHide={onClose} innerStyle={{ minWidth: 640, minHeight: 487 }}>
      <CopyRegionsWizardModalRenderer {...props} />
    </Modal>
  );
}

export default CopyRegionsWizardModal;
