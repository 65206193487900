import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import Button from "../../components/lib/Button";
import LinkButton from "../../components/lib/LinkButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Inline from "../../components/lib/Inline";
import TextInput from "../../components/lib/TextInput";
import Stack from "../../components/lib/Stack";

/**
 * AddJob component for add and upload job
 */
class AddTag extends Component {
  /**
   * Render Add Tag
   * @return {XML}
   */
  componentDidMount() {
    const store = this.props.store.tagManagementListStore;
    store.createMessaging.removeAll();
    store.tagLabel = "";
  }

  render() {
    const store = this.props.store.tagManagementListStore;
    return (
      <div className="view list">
        <div className="rc-container bring-forward">
          <div className="col-sm-12 add-job alt-add-job user-job-data">
            <h2>Add Tag</h2>
            <div className="row">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <Stack css={{ alignItems: "flex-start", gap: "0", margin: "0 15px" }}>
                  <label htmlFor="tag-input">Tag Label</label>
                  <TextInput
                    id="tag-input"
                    css={{ width: "100%" }}
                    placeholder="Tag Label"
                    type="text"
                    value={store.tagLabel}
                    maxLength="250"
                    onChange={store.changeTagLabelText}
                  />
                  <p className="text-muted text-x-small no-margin info">
                    <FontAwesomeIcon icon="info-circle" fixedWidth className="icon" />
                    <em>
                      You can add multiple tags using comma. Tag name cannot be more than
                      50 characters long.
                    </em>
                  </p>

                  <div className="pt-user-messages">
                    {store.createMessaging.messages.length > 0 && (
                      <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                        <a
                          href="#close"
                          className="close"
                          data-dismiss="alert"
                          aria-label="close"
                          onClick={store.createMessaging.removeMessage.bind(
                            store,
                            store.createMessaging.messages[0]
                          )}
                        >
                          &times;
                        </a>
                        <strong>{store.createMessaging.messages[0].messageLabel}</strong>
                        {store.createMessaging.messages[0].message}
                      </div>
                    )}
                  </div>
                </Stack>
                <div className="col-sm-12 text-right">
                  <Inline
                    css={{ marginBottom: "15px", gap: "$2", justifyContent: "flex-end" }}
                  >
                    <LinkButton to="/tag" size="large">
                      Cancel
                    </LinkButton>
                    <Button
                      data-testid="add-tag-btn"
                      color="brand"
                      size="large"
                      disabled={!store.tagLabel}
                      onClick={store.createTag}
                    >
                      Add Tag
                    </Button>
                  </Inline>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AddTag.propTypes = {
  store: PropTypes.object.isRequired,
};

export default observer(AddTag);
