import React from "react";
// @ts-ignore
import { Modal as BaseModal } from "react-overlays";
import classNames from "classnames";

interface ModalProps {
  show: boolean;
  onHide: () => void;
  style?: Object;
  innerStyle?: Object;
  children?: React.ReactNode;
}

const Modal = (props: ModalProps): JSX.Element => (
  <BaseModal
    aria-labelledby="model-label"
    className={classNames("pt-filter-modal", {
      showFadeIn: props.show,
      hideFadeOut: !props.show,
    })}
    backdropClassName="pt-filter-modal-backdrop"
    show={props.show}
    onHide={props.onHide}
    style={props.style}
  >
    <div className="pt-ui pt-filter-modal-inner" style={props.innerStyle}>
      {props.children}
    </div>
  </BaseModal>
);
Modal.displayName = "Modal";
Modal.defaultProps = {
  style: {},
  innerStyle: {},
};

export default Modal;
