import React, { useMemo } from "react";
import { Set } from "immutable";
// lib
import Button from "../../../../components/lib/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../components/lib/DropdownMenu";
// store
import { useSearchResultsStore } from "../../../../stores/SearchResults";
// relay
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { CurrencyDropdown_viewer$key } from "./__generated__/CurrencyDropdown_viewer.graphql";
import { CurrencyMap } from "../../../../constants/countryToCurrencyMap";

const fragment = graphql`
  fragment CurrencyDropdown_viewer on Viewer {
    countries {
      name
      isoCode
    }
  }
`;

type Props = {
  viewer: CurrencyDropdown_viewer$key;
};

export function CurrencyDropdown({ viewer }: Props) {
  const data = useFragment(fragment, viewer);
  const setCurrency = useSearchResultsStore((s) => s.setCurrency);
  const currency = useSearchResultsStore((s) => s.currency);

  const onSelect = (c: string) => () => setCurrency(c);

  const currencies = useMemo(
    () =>
      (data.countries || [])
        .reduce((currencySet, country) => {
          const isoCode = String(country?.isoCode);
          // filter out countries not in map
          if (CurrencyMap.has(isoCode)) {
            // convert country list to a set of currencies and removes duplicates
            const currency = CurrencyMap.get(isoCode);
            return currencySet.add(currency);
          }
          return currencySet;
        }, Set<string>())
        .sort((a, b) => (a > b ? 1 : -1)),
    [data.countries]
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button text={currency} iconRight="caret-down" />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        style={{
          minWidth: "80px",
          maxHeight: "325px",
          overflowY: "auto",
        }}
      >
        {currencies.map((c) => {
          return <DropdownMenuItem text={c} onSelect={onSelect(c!)} key={c} />;
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
