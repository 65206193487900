import React from "react";
import { Set } from "immutable";

import PromiseButton from "../../../components/lib/PromiseButton";
import AdminContractorBulkEditor, {
  PARAMS_FOR_BULK_UPDATE,
} from "../components/AdminContractorBulkEditor";
import { emptyList } from "../../../constants";
import { usePLIContext } from "../context";
import { useProgramContext } from "../ProgramDataProvider";
import { bulkUpdateCheckDataToImmutableMap } from "../dataConverters";

import type { ButtonProps } from "../../../components/lib/Button";
import type {
  FiltersQueryObject,
  FiltersQueryOrderedMap,
} from "../../../components/tables/types";
import type { PARAMS_FOR_BULK_UPDATE_TYPE } from "../components/AdminContractorBulkEditor";
import type { FetchAPIResponse } from "../../../types/fetch";
import type { ImmutableSet, ImmutableList } from "../../../types/immutable";
import type { CONTRACTOR_STATUSES_TYPE } from "../types";
import type { BulkUpdateCheckResultObject } from "../pages/StoredIndexCreate";

export type BulkUpdateContractorsButtonProps = Omit<ButtonProps, "onClick"> & {
  contractorsFiltersQuery: FiltersQueryOrderedMap;
  allowedStatusesForUpdate: ImmutableSet<CONTRACTOR_STATUSES_TYPE>;
  allowedParametersForUpdate: ImmutableSet<PARAMS_FOR_BULK_UPDATE_TYPE>;
  selectedIds?: ImmutableList<number>;
  onUpdateIsDone: (withProcessing?: boolean) => Promise<any>;
};

export function BulkUpdateContractorsButton(props: BulkUpdateContractorsButtonProps) {
  const {
    contractorsFiltersQuery,
    allowedStatusesForUpdate,
    allowedParametersForUpdate,
    onUpdateIsDone,
    selectedIds,
    ...restProps
  } = props;
  const {
    store,
    isPTAdmin,
    userEmail,
    showConfirmationModal,
    closeConfirmationModal,
    showModalSuccess,
    showModalWarning,
    showModalError,
    fetchM8API,
    fetchM8FilteringAPI,
    fetchCCCV1API,
    fetchTasteAPI,
    fetchGraphQL,
    services,
  } = usePLIContext();
  const { programId } = useProgramContext();

  // handlers

  const handlePostBulkUpdateActions = React.useCallback(async () => {
    await onUpdateIsDone?.();
    await closeConfirmationModal();
  }, [onUpdateIsDone, closeConfirmationModal]);

  const handleBulkUpdateMappings = React.useCallback(async () => {
    if (!isPTAdmin) {
      showModalWarning("You have no permissions to update data mappings");
      return;
    }

    const filtersQuery: FiltersQueryObject = {
      status__in: allowedStatusesForUpdate.toJS(),
      ...contractorsFiltersQuery.toJS(),
    };
    const response: FetchAPIResponse<BulkUpdateCheckResultObject> =
      await fetchM8FilteringAPI(
        `programs/${programId}/contractors/check_for_bulk_update/`,
        { data: filtersQuery }
      );
    const data = bulkUpdateCheckDataToImmutableMap(response.data);

    if (data && data.size) {
      let idsToUpdate = data.get("ids_to_update") || emptyList;
      const uniqueTitlesCount = data.get("unique_titles_count");
      const regionBasedDataCount = data.get("region_based_data_count");
      const locationBasedDataCount = data.get("location_based_data_count");
      const allowApproveAction = data.get("not_approved_data_found", false);
      const uniqueCountriesCount = data.get("unique_countries_count");
      const uniqueIndustriesCount = data.get("unique_industries_count");
      // if worker type is allowed for update - following data will be returned from API
      const workerTypeCountryId = data.get("worker_type_country_id");
      const workerTypeIndustryId = data.get("worker_type_industry_id");

      let paramsToUpdate = Set([
        PARAMS_FOR_BULK_UPDATE.INDUSTRY,
        PARAMS_FOR_BULK_UPDATE.EXP_LEVEL,
        // IMPORTANT: Per Marc - need more manual control on Accepted flag, should be always available
        PARAMS_FOR_BULK_UPDATE.LIMITS_ACCEPTED,
        PARAMS_FOR_BULK_UPDATE.GLOBAL_SUPPLIER_SEARCH,
      ]) as any as ImmutableSet<string>;

      // add those parameters available under certain conditions
      if (uniqueTitlesCount != null && uniqueTitlesCount === 1) {
        paramsToUpdate = paramsToUpdate
          .add(PARAMS_FOR_BULK_UPDATE.COLLECTION)
          .add(PARAMS_FOR_BULK_UPDATE.DESCRIPTION);
      }
      if (regionBasedDataCount != null && regionBasedDataCount === 0) {
        paramsToUpdate = paramsToUpdate.add(PARAMS_FOR_BULK_UPDATE.LOCATION);
      }
      if (locationBasedDataCount != null && locationBasedDataCount === 0) {
        paramsToUpdate = paramsToUpdate.add(PARAMS_FOR_BULK_UPDATE.REGION);
      }
      if (
        uniqueCountriesCount === 1 &&
        uniqueIndustriesCount === 1 &&
        workerTypeCountryId != null &&
        workerTypeIndustryId != null
      ) {
        paramsToUpdate = paramsToUpdate.add(PARAMS_FOR_BULK_UPDATE.WORKER_TYPE);
      }
      // IMPORTANT: Per Marc - need more manual control on Accepted flag, should be always available
      // if (!notVisibleDataFound && !emptyMarketDataFound &&
      //         !(globalSupplierDataFound && notGlobalSupplierDataFound)) {
      //   paramsToUpdate = paramsToUpdate.add(PARAMS_FOR_BULK_UPDATE.LIMITS_ACCEPTED);
      // }

      // filter out not allowed parameters
      paramsToUpdate = paramsToUpdate.intersect(allowedParametersForUpdate);

      if (selectedIds && selectedIds.size > 0) {
        idsToUpdate = selectedIds;
      }

      const header = "Bulk Update Contractors";
      const message = (
        <AdminContractorBulkEditor
          store={store}
          email={userEmail}
          programId={programId}
          idsToUpdate={idsToUpdate}
          paramsToUpdate={paramsToUpdate}
          allowApproveAction={allowApproveAction}
          workerTypeCountryId={workerTypeCountryId}
          workerTypeIndustryId={workerTypeIndustryId}
          onApply={handlePostBulkUpdateActions}
          onCancel={closeConfirmationModal}
          showModalSuccess={showModalSuccess}
          showModalError={showModalError}
          fetchM8API={fetchM8API}
          fetchCCCV1API={fetchCCCV1API}
          fetchTasteAPI={fetchTasteAPI}
          fetchGraphQL={fetchGraphQL}
          services={services}
        />
      );

      await showConfirmationModal(message, header);
    } else {
      await showModalError("Bad response format.");
    }
    return data;
  }, [
    store,
    selectedIds,
    programId,
    userEmail,
    isPTAdmin,
    contractorsFiltersQuery,
    allowedStatusesForUpdate,
    allowedParametersForUpdate,
    handlePostBulkUpdateActions,
    showConfirmationModal,
    closeConfirmationModal,
    showModalWarning,
    showModalSuccess,
    showModalError,
    fetchM8API,
    fetchM8FilteringAPI,
    fetchTasteAPI,
    fetchCCCV1API,
    fetchGraphQL,
    services,
  ]);

  return <PromiseButton onClick={handleBulkUpdateMappings} {...restProps} />;
}

export default BulkUpdateContractorsButton;
