import React, { Component } from "react";
import SearchCounter from "./SearchCounter";

class SearchCounterContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searches: 100,
    };
  }

  render() {
    return <SearchCounter searches={this.state.searches} />;
  }
}

export default SearchCounterContainer;
