import React from "react";
// LIB
import Box from "../../../components/lib/Box";
import Icon from "../../../components/lib/Icon";
import TextInput from "../../../components/lib/TextInput";
import Tooltip from "../../../components/lib/Tooltip";
import Text from "../../../components/lib/Text";
import { FormControl, Label, LabelWrapper } from "../../../components/lib/Label";
// store
import { useRateSearchFormStore } from "../../../stores/RateSearchForm";
// context
import { useGlobalContext } from "../../../globalContext";
import { sanitizeInput } from "../../../utils/dom";

const tooltipText = `
  The Job Label is your internal job title identifier.
  The Job Title you select is the generic job title that
  will be used for searching along with the job description.
`;

export default function JobLabelField() {
  const { perSearchPricing } = useGlobalContext();

  const jobLabel = useRateSearchFormStore((s) => s.jobLabel);
  const setJobLabel = useRateSearchFormStore((s) => s.setJobLabel);
  const isJobLabel = useRateSearchFormStore((s) => Boolean(s.jobTitle?.isJobLabel));

  const id = "rs-job-label-input";

  if (!perSearchPricing) return null;

  return (
    <Box>
      <FormControl fill>
        <LabelWrapper>
          <Label htmlFor={id}>Job Label (Optional)</Label>
          <Tooltip
            contentProps={{ style: { minWidth: "300px", maxWidth: "300px" } }}
            content={tooltipText}
          >
            <Text css={{ cursor: "pointer" }}>
              What's this? <Icon icon="question-circle" />
            </Text>
          </Tooltip>
        </LabelWrapper>
        <TextInput
          id={id}
          fill
          value={jobLabel}
          disabled={isJobLabel}
          onChange={(e) => setJobLabel(sanitizeInput(e.target.value))}
        />
      </FormControl>
    </Box>
  );
}
