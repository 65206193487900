import React, { useCallback } from "react";
import PropTypes from "prop-types";
import R from "ramda";
import Icon from "./lib/Icon";

import { styled } from "../stitches.config";

const InnerSticker = styled("span", {
  display: "inline-block",
  position: "relative",
  backgroundColor: "$brand",
  color: "$textLight",
  borderRadius: "$rounded",
  padding: "$2 $4 !important",
  "a, a:visited": {
    color: "$textLight !important",
  },
  variants: {
    disabled: {
      true: {
        backgroundColor: "$primaryLight",
        color: "$primary",
      },
    },
  },
  defaultVariants: {
    disabled: false,
  },
});

const EmptyStub = styled("span", {
  display: "inline-block",
  variants: {
    disabled: {
      true: {
        color: "$primaryLight",
      },
    },
  },
  defaultVariants: {
    disabled: false,
  },
});

const RemoveIcon = styled(Icon, {
  display: "inline-block",
  position: "absolute",
  top: "-$2",
  left: "-$2",
  backgroundColor: "$textLight",
  color: "$danger",
  borderRadius: "$full",
  fontSize: "$lg",
  cursor: "pointer",
  "&:hover": {
    transform: "scale(1.1)",
  },
  "&:active": {
    transform: "none",
  },
  variants: {
    disabled: {
      true: {
        color: "$primary",
        cursor: "not-allowed",
        "&:hover": {
          transform: "none",
        },
      },
    },
  },
  defaultVariants: {
    disabled: false,
  },
});

const Sticker = (props) => {
  const { value, removable, emptyStub, disabled, onRemove, css } = props;

  const handleClickRemove = useCallback(
    (e) => {
      removable && !disabled && onRemove && onRemove(e);
    },
    [removable, disabled, onRemove]
  );

  if (R.isNil(value) && R.isNil(emptyStub)) return null;

  return !R.isNil(value) ? (
    <InnerSticker disabled={disabled} css={css}>
      {value}
      {removable && (
        <RemoveIcon
          icon="times-circle"
          onClick={disabled ? undefined : handleClickRemove}
          disabled={disabled}
        />
      )}
    </InnerSticker>
  ) : (
    <EmptyStub disabled={disabled} css={css}>
      {emptyStub}
    </EmptyStub>
  );
};

Sticker.displayName = "Sticker";
Sticker.propTypes = {
  value: PropTypes.node,
  removable: PropTypes.bool,
  onRemove: PropTypes.func,
  emptyStub: PropTypes.node,
  disabled: PropTypes.bool,
};
Sticker.defaultProps = {
  removable: false,
  emptyStub: "None",
  disabled: false,
};

export default Sticker;
