// @flow

import { extendObservable, action } from "mobx";
import FilterObject, {
  FilterWithSort,
  ValueSetFilter,
  FilterCriteriaLoader,
  IViewItem,
} from "../Filter";
import type { FilterColumn } from "../Filter";
import { GraphQLQuery } from "../GraphQL";
import Sort from "../Sort";
import SortState from "../SortState";
import { filter } from "fuzzaldrin-plus";

export const LOCATION_FILTER_TYPE = {
  COUNTRY: "country",
  STATE: "state",
  CITY: "city",
  REGION: "region",
};

export type LocationType = "country" | "state" | "city" | "region";

export class Location implements IViewItem {
  id: string;
  location: String;
  type: LocationType;
  locationId: String;
  selected: boolean;

  constructor(object: Object) {
    this.id = object.id;
    this.location = object.location;
    this.type = object.type;
    this.locationId = object.locationId;

    extendObservable(this, {
      selected: object.selected,
    });
  }
}

export default class LocationFilter extends FilterCriteriaLoader(
  ValueSetFilter(FilterWithSort)
) {
  constructor(
    store: Object,
    column: FilterColumn,
    filterCriteriaQuery: (FilterColumn) => GraphQLQuery,
    processPayload: (FilterColumn, Object) => ?Array<Object>,
    applyFilter: (FilterColumn, FilterObject) => void,
    applySort: (FilterColumn, Sort) => void,
    removeFilter: (FilterColumn) => void,
    removeSort: (FilterColumn) => void,
    reloadCriteria: boolean = false
  ) {
    super(store, column);

    extendObservable(this, {
      strictCountries: true,
    });

    this.filterCriteriaQuery = filterCriteriaQuery;
    this.processPayload = processPayload;
    this.applyFilter = applyFilter;
    this.applySort = applySort;
    this.removeFilter = removeFilter;
    this.removeSort = removeSort;

    this.sortState = new SortState("LOCATION");

    this.reloadCriteria = reloadCriteria;

    this.viewItemClass = Location;

    // $FlowFixMe: Need to type these properties as invariant
    this.onInstantSearch = action(this.onInstantSearch.bind(this));
    // $FlowFixMe: Need to type these properties as invariant
    this.buildQueryFilter = action(this.buildQueryFilter.bind(this));
    // $FlowFixMe: Need to type these properties as invariant
    this.toggleStrictCountries = action(this.toggleStrictCountries.bind(this));
    // $FlowFixMe: Need to type these properties as invariant
    this.softResetFilter = action(this.softResetFilter.bind(this));
  }

  onInstantSearch(value: string) {
    if (super.onInstantSearch) super.onInstantSearch(value);

    if (!this.instantSearchValue) {
      this.viewItems = this.unfilteredViewItems;
      return;
    }

    this.viewItems = filter(this.unfilteredViewItems, value, {
      key: "location",
      maxResults: 20,
    });
  }

  toggleStrictCountries() {
    this.strictCountries = !this.strictCountries;
  }

  buildQueryFilter() {
    const selectedLocations = this.selectedValues
      .entries()
      .filter((entry) => entry[1] === true)
      .map((entry) => this.criteria.get(entry[0]));

    const locationText = this.selectedValue;

    if (!selectedLocations.length && (!locationText || !locationText.trim())) {
      return null;
    }

    let params = [];
    let args = [];
    let vars = {};

    if (locationText && locationText.trim()) {
      params.push("$location: String!");
      args.push("locationIContains: $location");
      vars.location = locationText.trim();
    }

    if (selectedLocations.length) {
      const locationsByType: { [id: string]: Array<Location> } = {
        countries: [],
        states: [],
        cities: [],
        regions: [],
      };

      selectedLocations.forEach((location) => {
        switch (location.type) {
          case LOCATION_FILTER_TYPE.COUNTRY:
            locationsByType.countries.push(location);
            break;
          case LOCATION_FILTER_TYPE.STATE:
            locationsByType.states.push(location);
            break;
          case LOCATION_FILTER_TYPE.CITY:
            locationsByType.cities.push(location);
            break;
          case LOCATION_FILTER_TYPE.REGION:
            locationsByType.regions.push(location);
            break;
          // Do nothing
          default:
            break;
        }
      });

      if (locationsByType.countries.length) {
        params.push("$countries: [CountryCriterionInput]!");
        if (this.strictCountries) args.push("strictCountries: $countries");
        else args.push("countries: $countries");
        vars.countries = locationsByType.countries.map((location) => {
          return { country: location.location };
        });
      }

      if (locationsByType.states.length) {
        params.push("$states: [StateCriterionInput]!");
        args.push("states: $states");
        vars.states = locationsByType.states.map((location) => {
          return { state: location.location };
        });
      }

      if (locationsByType.cities.length) {
        params.push("$cities: [CityCriterionInput]!");
        args.push("cities: $cities");
        vars.cities = locationsByType.cities.map((location) => {
          return { city: location.location };
        });
      }

      if (locationsByType.regions.length) {
        params.push("$regions: [RegionCriterionInput]!");
        args.push("regions: $regions");
        vars.regions = locationsByType.regions.map((location) => {
          return { region: location.location };
        });
      }
    }

    return new FilterObject(params.join(", "), args.join(", "), vars);
  }

  softResetFilter() {
    if (super.softResetFilter) super.softResetFilter();

    this.strictCountries = false;
  }
}
