// @flow

import React, { Component } from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JobsByCategoryPanel from "./components/JobsByTagPanel";
import PayRateBillRateScatterPlotPanel from "./components/PayRateBillRateScatterPlotPanel";
import HighestPayingJobsWhiskerPanel from "./components/HighestPayingJobsWhiskerPanel";
import JobsByCountryChoroplethPanel from "./components/JobsByCountryChoroplethPanel";
import SalaryHistogramPanel from "./components/SalaryHistogramPanel";
import type MobXStore from "../../stores/mobx/MobXStore";

type Props = {
  store: MobXStore,
  router: Object,
};

class DetailVisualize extends Component<Props> {
  componentDidMount() {
    const detailStore = this.props.store.rateCardDetailStore;
    detailStore.router = this.props.router;

    if (!detailStore.network.loading && !detailStore.rateCard) {
      // if we got here first instead of the list view, we need to load
      // the rate card first and then visualizations.
      // TODO: separate rate card header loading form search list. detailStore.pagination.goFetch() does both.
      detailStore.rateCardId = this.props.router.params.rateCardId;
      detailStore.pagination.goFetch().then(() => {
        detailStore.initVisualizations();
      });

      // reset to null so some elements visible while editing start hidden
      detailStore.isEditing = null;
    }

    if (!detailStore.network.loading && detailStore.rateCard) {
      // if we got here after loading the list of searches, just load visualizations
      detailStore.initVisualizations();
    }
  }

  handleGoBack = () => {
    this.props.router.goBack();
  };

  render() {
    const store = this.props.store.rateCardDetailStore;
    // const globalStore = this.props.store.globalStateStore;
    // const rateMultiplier = globalStore.rateMultiplier;
    // const contingentRateFrequency = globalStore.selectedContingentRateDisplayOption;

    const mapState = store.jobsByCountryChoroplethState;
    const showMap = Boolean(mapState.chartData.geojson);

    const payRateBillRateState = store.payRateBillRateScatterPlotState;
    const maxSeries = payRateBillRateState.chartData.maxSeries;
    const minSeries = payRateBillRateState.chartData.minSeries;
    const showPayRateBillRate =
      Boolean(maxSeries) &&
      maxSeries.length > 0 &&
      Boolean(minSeries) &&
      minSeries.length > 0;
    return (
      <div className="rc-container bring-forward">
        <div className="header-action-bar">
          <h4 className="pull-left" style={{ lineHeight: "30px" }}>
            Rate Card Visualizations
          </h4>
          {!store.network.loading && !store.network.error && (
            <div className="btn-group pull-right">
              {store.isEditing && (
                <button className="btn btn-yellow" onClick={store.handleStopEdit}>
                  Stop Editing
                </button>
              )}
              {!store.isEditing && (
                <nav className="action-bar">
                  {/*<RateDisplayToggle*/}
                  {/*selectedOption={globalStore.selectedContingentRateDisplayOption}*/}
                  {/*onChange={globalStore.onContingentRatesDisplayChange}*/}
                  {/*onConfig={globalStore.customMultipliersModal.showModal}*/}
                  {/*/>*/}

                  {/*<button className="btn btn-green" onClick={store.handleStartEdit}>*/}
                  {/*<FontAwesomeIcon icon="edit" fixedWidth className="icon" /> Edit*/}
                  {/*</button>*/}
                </nav>
              )}
            </div>
          )}
          <div className="clearfix" />
        </div>

        {!store.network.error && !store.showRegionsOnlyMessage && (
          <div className="chart-grid" style={{ margin: "0 -20px" }}>
            <div className="chart-grid-item">
              <JobsByCategoryPanel store={store} />
            </div>
            {showPayRateBillRate && (
              <div className="chart-grid-item">
                <PayRateBillRateScatterPlotPanel store={store} />
              </div>
            )}
            {store.showSalaryVisualizations && (
              <div className="chart-grid-item">
                <SalaryHistogramPanel store={store} />
              </div>
            )}
            <div className="chart-grid-item">
              <HighestPayingJobsWhiskerPanel store={store} />
            </div>
            {showMap && (
              <div className="chart-grid-item" style={{ width: "50%", minWidth: 600 }}>
                <JobsByCountryChoroplethPanel store={store} />
              </div>
            )}
            {/*<div className="chart-grid-item">*/}
            {/*<div style={{ width: "100%", minWidth: 500, height: 400, textAlign: "center" }}>*/}
            {/*<FontAwesomeIcon icon="bar-chart" style={{ fontSize: 380 }} />*/}
            {/*</div>*/}
            {/*</div>*/}
            {/*<div className="chart-grid-item">*/}
            {/*<div style={{ width: "100%", minWidth: 500, height: 400, textAlign: "center" }}>*/}
            {/*<FontAwesomeIcon icon="area-chart" style={{ fontSize: 380 }} />*/}
            {/*</div>*/}
            {/*</div>*/}
          </div>
        )}
        {store.network.error && (
          <div className="pt-error-message fade-in">
            <h2>Error</h2>
            <pre>
              <code>{JSON.stringify(store.network.error, null, 2)}</code>
            </pre>
          </div>
        )}
        {store.showRegionsOnlyMessage && (
          <div className="ratecards-table-empty" style={{ padding: "80px 0 0 0" }}>
            <div>
              Regions not supported for visualizations!
              <div style={{ marginTop: 20 }}>
                <button className="btn btn-yellow" onClick={this.handleGoBack}>
                  <FontAwesomeIcon icon="arrow-left" fixedWidth className="icon" /> Go
                  Back
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default observer(DetailVisualize);
