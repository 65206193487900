import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import CheckBox from "../../../shared/CheckBox";
import classNames from "classnames";
import SearchPeek from "../../../searches/components/SearchPeek";

const PunchoutsListItem = observer(
  class extends Component {
    static contextTypes = {
      router: PropTypes.object,
    };

    render() {
      const props = this.props;
      const store = this.props.store;
      const punchout = props.punchout;
      const editing = punchout.viewState.editing;
      const selected = punchout.viewState.selected;
      const name = punchout.buyrateName;
      const createdDisplay = punchout.createdBuyrate.format("MMMM D, YYYY");
      const id = punchout.buyrateId;
      let ownerName = "You";
      let label = punchout.title;
      let expandable = props.expandable;
      let expanded = this.props.expanded;
      if (String(punchout.ownerLogin.userId) !== String(store.currentUser.userId)) {
        ownerName = punchout.ownerLogin.firstName;
      }

      let onItemClick = this.props.itemClick || this.handleViewDetail;
      let editingSection = null;

      if (editing) {
        editingSection = (
          <CheckBox
            type={props.store.allowMultipleItemSelection ? "checkbox" : "radio"}
            name="list-item-select"
            value={name}
            selected={selected}
            style={{ width: 34 }}
            onClick={onItemClick}
          />
        );
      }

      return (
        <div className="ratecard-list-item-container">
          <div
            className={classNames("ratecard-list-item", { selected: selected })}
            onClick={onItemClick}
          >
            <div className="ratecard-list-item-left ellipsis">
              {editingSection}
              {expandable && (
                <div className="search-expand-state">
                  <span
                    className={classNames("pt-ico-moon", {
                      expanded: this.props.expanded,
                    })}
                  >
                    
                  </span>
                </div>
              )}
              <div className="ratecard-label-container ellipsis">
                <div className="ratecard-label ellipsis">
                  <span className="ellipsis">{name}</span>
                </div>
                <div className="ratecard-meta ellipsis">
                  <span className="item-subtext-muted">Created by </span>
                  <span className="item-subtext-accent">{ownerName} </span>
                  <span className="item-subtext-muted">on </span>
                  <span className="item-subtext-accent">{createdDisplay}</span>
                </div>
              </div>
            </div>
            <div className="ratecard-list-item-right">
              {
                <div className="rc-center">
                  <div className="ratecard-info w-300 text-center">
                    <header data-testid="ID-header-punchouts">ID</header>
                    <div>{id}</div>
                  </div>
                  <div className="ratecard-info w-300">
                    <header data-testid="job-title-header-punchouts">Job Title</header>
                    <div className="text-center">{label}</div>
                  </div>
                </div>
              }
            </div>
          </div>
          {expandable && (
            <SearchPeek store={store} expanded={expanded} search={punchout} />
          )}
        </div>
      );
    }
  }
);

PunchoutsListItem.propTypes = {
  store: PropTypes.object.isRequired,
  rateCard: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default PunchoutsListItem;
