/**
 * @generated SignedSource<<072d264c182b57f266bc226d1b51050c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DetailTable_savedsearch$data = {
  readonly currency: {
    readonly id: string;
    readonly iso: string;
  } | null;
  readonly id: string;
  readonly marketrates: ReadonlyArray<{
    readonly billRateAvg: number;
    readonly billRateMax: number;
    readonly billRateMid: number;
    readonly billRateMin: number;
    readonly id: string;
    readonly markupPctAvg: number;
    readonly markupPctMax: number;
    readonly markupPctMid: number;
    readonly markupPctMin: number;
    readonly payRateAvg: number;
    readonly payRateMax: number;
    readonly payRateMid: number;
    readonly payRateMin: number;
  } | null> | null;
  readonly rateType: number;
  readonly " $fragmentType": "DetailTable_savedsearch";
} | null;
export type DetailTable_savedsearch$key = {
  readonly " $data"?: DetailTable_savedsearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"DetailTable_savedsearch">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DetailTable_savedsearch",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rateType",
        "storageKey": null
      },
      "action": "NONE",
      "path": "rateType"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CurrencyNode",
      "kind": "LinkedField",
      "name": "currency",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "iso",
            "storageKey": null
          },
          "action": "NONE",
          "path": "currency.iso"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MarketRatesNode",
      "kind": "LinkedField",
      "name": "marketrates",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "payRateMin",
            "storageKey": null
          },
          "action": "NONE",
          "path": "marketrates.payRateMin"
        },
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "payRateMid",
            "storageKey": null
          },
          "action": "NONE",
          "path": "marketrates.payRateMid"
        },
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "payRateMax",
            "storageKey": null
          },
          "action": "NONE",
          "path": "marketrates.payRateMax"
        },
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "payRateAvg",
            "storageKey": null
          },
          "action": "NONE",
          "path": "marketrates.payRateAvg"
        },
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "billRateMin",
            "storageKey": null
          },
          "action": "NONE",
          "path": "marketrates.billRateMin"
        },
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "billRateMid",
            "storageKey": null
          },
          "action": "NONE",
          "path": "marketrates.billRateMid"
        },
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "billRateMax",
            "storageKey": null
          },
          "action": "NONE",
          "path": "marketrates.billRateMax"
        },
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "billRateAvg",
            "storageKey": null
          },
          "action": "NONE",
          "path": "marketrates.billRateAvg"
        },
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "markupPctMin",
            "storageKey": null
          },
          "action": "NONE",
          "path": "marketrates.markupPctMin"
        },
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "markupPctMid",
            "storageKey": null
          },
          "action": "NONE",
          "path": "marketrates.markupPctMid"
        },
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "markupPctMax",
            "storageKey": null
          },
          "action": "NONE",
          "path": "marketrates.markupPctMax"
        },
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "markupPctAvg",
            "storageKey": null
          },
          "action": "NONE",
          "path": "marketrates.markupPctAvg"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SaveSearchNode",
  "abstractKey": null
};
})();

(node as any).hash = "35ef611e2e63b57d2f61d32dcbdab1b5";

export default node;
