import React, { ReactNode, useState } from "react";
// @ts-ignore
import graphql from "babel-plugin-relay/macro";
import Button from "../../../../components/lib/Button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "../../../../components/lib/Dialog";
import Inline from "../../../../components/lib/Inline";
import Stack from "../../../../components/lib/Stack";
import Text from "../../../../components/lib/Text";
import TextArea from "../../../../components/lib/TextArea";
import TextInput from "../../../../components/lib/TextInput";
import { useMutation } from "react-relay";
import { RequestMoreDialogMutation } from "./__generated__/RequestMoreDialogMutation.graphql";

const mutation = graphql`
  mutation RequestMoreDialogMutation($request: TrapsRequestTransferResourceInput!) {
    trapsRequestTransferResource(input: $request) {
      result {
        ... on TrapsTransferRequest {
          uuid
          amount
        }
        __typename
      }
    }
  }
`;

interface Props {
  children: ReactNode;
}

export function RequestMoreDialog({ children }: Props) {
  const [numSearches, setNumSearches] = useState(0);
  const [reason, setReason] = useState("");

  const [requestMoreCommit] = useMutation<RequestMoreDialogMutation>(mutation);

  function onRequest() {
    const variables = {
      request: { source: "1", destination: "8", amount: numSearches, message: reason },
    };

    requestMoreCommit({
      variables,
      onError(error) {
        // TODO: show error message
        console.error("Error on requestMoreCommit:", error);
      },
    });
  }

  function onOpenChangeHandler(isOpen: boolean) {}

  return (
    <Dialog onOpenChange={onOpenChangeHandler}>
      <DialogTrigger asChild>
        <span>{children}</span>
      </DialogTrigger>
      <DialogContent css={{ width: "500px", maxWidth: "500px", padding: "25px" }}>
        <Stack fill css={{ alignItems: "flex-start", gap: "25px" }}>
          <Text as="h3">Request More Searches</Text>

          <Stack fill css={{ alignItems: "flex-start", gap: "0px" }}>
            <div>I would like</div>
            <TextInput
              fill
              type="number"
              id="psp-rs__num-searches"
              min="0"
              step="1"
              placeholder="e.x. 100"
              addonRight="Searches"
              value={numSearches}
              onChange={(e) => {
                setNumSearches(Number(e.target.value));
              }}
            />
          </Stack>

          <Stack fill css={{ alignItems: "flex-start", gap: "0px" }}>
            <div>Reason (optional)</div>
            <TextArea
              value={reason}
              css={{ width: "100%", height: "92px", resize: "none" }}
              placeholder="Please enter the reason for the request."
              onChange={(e) => {
                setReason(e.target.value);
              }}
            />
          </Stack>

          <Inline fill css={{ justifyContent: "right" }}>
            <DialogClose asChild>
              <Button variant="outlined" color="brand" size="small">
                Close
              </Button>
            </DialogClose>
            <DialogClose asChild>
              <Button onClick={onRequest} variant="filled" color="brand" size="small">
                Request
              </Button>
            </DialogClose>
          </Inline>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
