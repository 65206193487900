// @flow

import R from "ramda";
import numeral from "numeral";
import { Set } from "immutable";
import Enum from "../utils/enumerations";

export const jobDescriptionMaxLinesAmount = 24;
export const jobDescriptionMaxCharInLine = 171;
export const jobTitleMaxLength = 55;

export const getNumberOfLines = (value: ?string, lineLength: number = 100) => {
  if (!value) return 0;
  value = value.trim();
  const rawLinesArray = value.split("\n");
  if (!rawLinesArray || !rawLinesArray.length) return 0;
  let numberOfLines = 0;
  rawLinesArray.forEach(
    (line: string) => (numberOfLines += Math.ceil(line.length / lineLength))
  );
  return numberOfLines;
};
export const getJobDescriptionErrors = (value: ?string, name: string = "Description") => {
  const errors = [];
  value = value ? value.trim() : value;
  if (value) {
    const numberOfLines = getNumberOfLines(value, jobDescriptionMaxCharInLine);
    if (numberOfLines > jobDescriptionMaxLinesAmount) {
      errors.push(
        `${name} should have max ${jobDescriptionMaxLinesAmount} lines total to fit PDF page well (currently ${numberOfLines} lines).`
      );
    }
  } else {
    errors.push(`${name} should be non-empty value.`);
  }
  return errors.length ? errors : null;
};

export const getJobTitleErrors = (value: ?string, name: string = "Title") => {
  const errors = [];
  value = value ? value.trim() : value;
  if (value) {
    if (value.length > jobTitleMaxLength) {
      errors.push(
        `${name} should be of ${jobTitleMaxLength} characters length max to fit PDF page well (current length ${value.length} characters).`
      );
    }
  } else {
    errors.push(`${name} should be non-empty value.`);
  }
  return errors.length ? errors : null;
};

export const EXP_LEVELS = new Enum(
  ["JUNIOR", "Junior", 1],
  ["INTERMEDIATE", "Intermediate", 2],
  ["SENIOR", "Senior", 3],
  ["LEAD", "Lead", 4],
  ["GURU", "Guru", 5]
);

export const EXP_LEVELS_ROMAN = new Enum(
  ["JUNIOR", "I", 1],
  ["INTERMEDIATE", "II", 2],
  ["SENIOR", "III", 3],
  ["LEAD", "IV", 4],
  ["GURU", "V", 5]
);

export const ALL_EXP_LEVELS = [
  EXP_LEVELS.JUNIOR,
  EXP_LEVELS.INTERMEDIATE,
  EXP_LEVELS.SENIOR,
  EXP_LEVELS.LEAD,
  EXP_LEVELS.GURU,
];

export const RATE_TYPES = new Enum(["HOURLY", "Hourly", 1], ["ANNUAL", "Annual", 2]);

export const PACK_STATUSES = new Enum(
  ["PROCESSING", "Processing", 1],
  ["FINISHED_PROCESSING", "Finished Processing", 2],
  ["FAILED_PROCESSING", "Failed Processing", 3],
  ["PUBLISHING", "Publishing", 4],
  ["FINISHED_PUBLISHING", "Finished Publishing", 5],
  ["FAILED_PUBLISHING", "Failed Publishing", 6]
);
export type PackStatusType =
  | PACK_STATUSES.PROCESSING
  | PACK_STATUSES.READY
  | PACK_STATUSES.FAILED;

export const DECIMAL_FORMAT = "0,0.00";
export const DECIMAL_FORMAT_WITH_SIGN = "+0,0.00";

export const decimalFormatter = (
  value: number,
  verbose: boolean = false,
  units: ?string = null
) => {
  const formatter = verbose ? DECIMAL_FORMAT_WITH_SIGN : DECIMAL_FORMAT;

  return !R.isNil(value)
    ? (units ? ` ${units} ` : "") + numeral(value).format(formatter)
    : "";
};
export const percentFormatter = (
  value: number,
  verbose: boolean = false,
  units: string = "%"
) => {
  const formatter = verbose ? DECIMAL_FORMAT_WITH_SIGN : DECIMAL_FORMAT;

  return !R.isNil(value)
    ? numeral(value).format(formatter) + (units ? ` ${units} ` : "")
    : "";
};

export const dedupeCSVString = (value: string) => {
  if (!value) return value;
  return new Set(
    value
      .split(",")
      .map((i) => i.trim())
      .filter((i) => i && i)
  )
    .toJS()
    .join(", ");
};

export type IndustryType = { id: number, title: string, faIcon: string };
export type PackCategoryType = IndustryType;
export const industryIdGetter = (item: IndustryType) =>
  !R.isNil(item.id) ? parseInt(item.id) : item.id;
export const industryTitleGetter = (item: IndustryType) => item.title || "";
export const industryIconGetter = (item: IndustryType) => item.faIcon;
export const industryItemConverter = (item: Object) => ({
  id: industryIdGetter(item),
  title: industryTitleGetter(item),
  faIcon: industryIconGetter(item),
});

export const categoryIdGetter = industryIdGetter;
export const categoryTitleGetter = industryTitleGetter;
export const categoryIconGetter = industryIconGetter;
export const categoryItemConverter = (item: Object) => ({
  id: categoryIdGetter(item),
  title: categoryTitleGetter(item),
  faIcon: categoryIconGetter(item),
});
