// @flow
import React, { Component } from "react";
import { observer } from "mobx-react";
import JobLibraryRequestTable from "./JobLibraryRequestTable";
import JobLibraryRequestTableRow from "./JobLibraryRequestTableRow";
import EmptyRow from "./EmptyRow";
import JobLibraryRequestListStore from "../../stores/mobx/JobLibraryRequestListStore";
import Pagination from "./Pagination";

function times(n, f) {
  const result = [];
  while (n-- > 0) {
    result.push(f(n));
  }

  return result;
}

type Props = {
  store: JobLibraryRequestListStore,
};

class JobLibraryRequestList extends Component<Props> {
  componentDidMount() {
    this.props.store.pagination.goFetch();
  }

  render() {
    const store = this.props.store;
    const clientLibraryRequestsView = store.jobLibraryRequestsView;

    return (
      <div className="cjl-centered-view-container">
        <div className="cjl-library-request-list">
          <JobLibraryRequestTable store={store}>
            {clientLibraryRequestsView &&
              clientLibraryRequestsView.length > 0 &&
              !store.network.loading &&
              clientLibraryRequestsView.map((item) => (
                <JobLibraryRequestTableRow key={item.id} request={item} />
              ))}
            {/* If no items to show */}
            {!store.network.loading &&
              (!clientLibraryRequestsView || clientLibraryRequestsView.length === 0) && (
                <EmptyRow text="No Requests" />
              )}
            {/* If its loading */}
            {store.network.loading &&
              // generate placeholder items
              times(store.jobLibraryRequests.length || 5, (i) => (
                <JobLibraryRequestTableRow key={i} />
              ))}
            <Pagination
              hasLoaded={store.pagination.hasLoaded}
              pageCount={store.pagination.pageCount}
              pagesToShow={store.pagination.maxPagesToShow}
              currentPage={store.pagination.currentPage}
              begin={store.pagination.begin}
              end={store.pagination.end}
              isEndRange={store.pagination.isEndRange}
              handleNextPage={store.pagination.handleNextPage}
              handlePrevPage={store.pagination.handlePrevPage}
              handleFirstPage={store.pagination.handleFirstPage}
              handleLastPage={store.pagination.handleLastPage}
            />
          </JobLibraryRequestTable>
        </div>
      </div>
    );
  }
}

export default observer(JobLibraryRequestList);
