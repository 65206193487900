import React, { useState, useCallback } from "react";
import { Set, Map, fromJS } from "immutable";

import Icon from "../../../components/lib/Icon";
// @ts-expect-error
import ExpLevelSelect from "../../../components/selects/ExpLevelSelect";
// @ts-expect-error
import JobCollectionSelect from "../../../components/selects/JobCollectionSelect";
// @ts-expect-error
import Sticker from "../../../components/Sticker";
// @ts-expect-error
import Messager from "../../../components/Messager";
// @ts-expect-error
import LocationSelect from "../../../components/selects/LocationSelect";
// @ts-expect-error
import RegionSelect from "../../../components/selects/RegionSelect";
// @ts-expect-error
import IndustrySelect from "../../../components/selects/IndustrySelect";
import WorkerTypeSelect from "../../../components/selects/WorkerTypeSelect";
// @ts-expect-error
import MobXStore from "../../../stores/mobx/MobXStore";
import Stack from "../../../components/lib/Stack";
import Inline from "../../../components/lib/Inline";
import Text from "../../../components/lib/Text";
import Alert from "../../../components/lib/Alert";
import { Button } from "../../../components/lib/Button";
import TextArea from "../../../components/lib/TextArea";
import { ButtonGroupRight } from "../../../components/lib/ButtonGroup";
import { CheckboxItem } from "../../../components/lib/Checkbox";
import { RadioGroup, RadioGroupItem } from "../../../components/lib/RadioGroup";
import { useMessagerRef } from "../hooks";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";
import { styled } from "../../../stitches.config";
import { reactSelectStyles, emptyMap } from "../constants";

import type { ShowModalFunc } from "../../../components/modals/MessageModal";
import type { ImmutableList, ImmutableMap, ImmutableSet } from "../../../types/immutable";
import type { Nullable } from "../../../types/generic";
import type {
  FetchAPIFunc,
  FetchAPIResponse,
  FetchGraphQLAPIFunc,
} from "../../../types/fetch";
import type { ProcessingMessagesMap, Values } from "../types";
import type { ValueType } from "react-select/lib/types";
import type { WorkerTypeSelectValue } from "../../../components/selects/WorkerTypeSelect";
import PromiseButton from "../../../components/lib/PromiseButton";
import { ServicesObject } from "../../../globalContext";

const Container = styled(Stack, {
  alignItems: "flex-start",
  gap: "$3",
  padding: "$6 $3",
  borderBottom: "1px dashed $primaryLight",
  "&:last-child": {
    borderBottom: "none",
  },

  [`& ${Inline}`]: {
    gap: "$2",
  },

  "& label": {
    marginBottom: "0 !important",
  },
});
Container.displayName = "Container";

export const PARAMS_FOR_BULK_UPDATE = {
  DESCRIPTION: "description",
  COLLECTION: "collection",
  INDUSTRY: "industry",
  LOCATION: "location",
  REGION: "region",
  EXP_LEVEL: "exp_level",
  LIMITS_ACCEPTED: "limits_accepted",
  GLOBAL_SUPPLIER_SEARCH: "global_supplier_search",
  WORKER_TYPE: "worker_type",
} as const;

export type PARAMS_FOR_BULK_UPDATE_TYPE = Values<typeof PARAMS_FOR_BULK_UPDATE>;

type AllowedParametersFlags = {
  allowDescriptionUpdate: boolean;
  allowCollectionUpdate: boolean;
  allowIndustryUpdate: boolean;
  allowLocationUpdate: boolean;
  allowRegionUpdate: boolean;
  allowExpLevelUpdate: boolean;
  allowLimitsAcceptedUpdate: boolean;
  allowGlobalSupplierSearchUpdate: boolean;
  allowWorkerTypeUpdate: boolean;
};

const getAllowedParametersFlags = (
  paramsToUpdate: ImmutableSet<string>
): AllowedParametersFlags => {
  let allowDescriptionUpdate = true;
  let allowCollectionUpdate = true;
  let allowIndustryUpdate = true;
  let allowLocationUpdate = true;
  let allowRegionUpdate = true;
  let allowExpLevelUpdate = true;
  let allowLimitsAcceptedUpdate = true;
  let allowGlobalSupplierSearchUpdate = true;
  let allowWorkerTypeUpdate = true;

  if (paramsToUpdate.size > 0) {
    allowDescriptionUpdate = paramsToUpdate.includes(PARAMS_FOR_BULK_UPDATE.DESCRIPTION);
    allowCollectionUpdate = paramsToUpdate.includes(PARAMS_FOR_BULK_UPDATE.COLLECTION);
    allowIndustryUpdate = paramsToUpdate.includes(PARAMS_FOR_BULK_UPDATE.INDUSTRY);
    allowLocationUpdate = paramsToUpdate.includes(PARAMS_FOR_BULK_UPDATE.LOCATION);
    allowRegionUpdate = paramsToUpdate.includes(PARAMS_FOR_BULK_UPDATE.REGION);
    allowExpLevelUpdate = paramsToUpdate.includes(PARAMS_FOR_BULK_UPDATE.EXP_LEVEL);
    allowLimitsAcceptedUpdate = paramsToUpdate.includes(
      PARAMS_FOR_BULK_UPDATE.LIMITS_ACCEPTED
    );
    allowGlobalSupplierSearchUpdate = paramsToUpdate.includes(
      PARAMS_FOR_BULK_UPDATE.GLOBAL_SUPPLIER_SEARCH
    );
    allowWorkerTypeUpdate = paramsToUpdate.includes(PARAMS_FOR_BULK_UPDATE.WORKER_TYPE);
  }

  return {
    allowDescriptionUpdate,
    allowCollectionUpdate,
    allowIndustryUpdate,
    allowLocationUpdate,
    allowRegionUpdate,
    allowExpLevelUpdate,
    allowLimitsAcceptedUpdate,
    allowGlobalSupplierSearchUpdate,
    allowWorkerTypeUpdate,
  };
};

const getCollectionStickerValue = (
  data: ContractorUpdateDataMap,
  tasteUrl: string | null
): React.ReactNode => {
  const collectionId = data.get("job_collection_id");
  const collectionTitle = data.get("job_collection_title");
  const collectionUrl =
    collectionId && tasteUrl ? `${tasteUrl}job-title-collections/${collectionId}` : null;
  const label =
    collectionTitle && collectionId ? `${collectionTitle} (#${collectionId})` : null;

  return collectionUrl && label ? (
    <a href={collectionUrl} target="_blank" rel="noopener noreferrer">
      {label}
    </a>
  ) : (
    label
  );
};

type JobCollectionSelectValue = ImmutableMap<{
  id: number;
  title: string;
}>;

const getCollectionSelectValue = (
  data: ContractorUpdateDataMap
): JobCollectionSelectValue | null => {
  const collectionTitle = data.get("job_collection_title");
  const collectionId = data.get("job_collection_id");

  return collectionId != null
    ? (Map({ id: collectionId, title: collectionTitle }) as JobCollectionSelectValue)
    : null;
};

type IndustrySelectValue = {
  id: number;
  title?: string | null;
};

const getIndustrySelectValue = (
  data: ContractorUpdateDataMap
): IndustrySelectValue | null => {
  const industryId = data.get("industry_id");
  const industryTitle = data.get("industry_title");

  return industryId != null ? { id: industryId, title: industryTitle } : null;
};

const getWorkerTypeSelectValue = (
  data: ContractorUpdateDataMap
): WorkerTypeSelectValue | null => {
  const workerTypeId = data.get("worker_type_id");
  const workerTypeTitle = data.get("worker_type_title");

  return workerTypeId != null && workerTypeTitle != null
    ? { id: workerTypeId, name: workerTypeTitle }
    : null;
};

type LocationSelectValue = {
  type?: string | null;
  title?: string | null;
  subtitle?: string | null;
  full_title?: string | null;
  full_subtitle?: string | null;
  location_id: number;
  country_id?: number | null;
};

const getLocationSelectValue = (
  data: ContractorUpdateDataMap
): null | LocationSelectValue => {
  // we need some fake value for ID to fill select component with initial value
  const locationId = data.get("location_id");

  if (locationId == null) {
    return null;
  }

  const countryId = data.get("country_id");
  const type = data.get("location_type");
  const title = data.get("location_title");
  const subtitle = data.get("location_subtitle");
  const fullTitle = data.get("location_full_title");
  const fullSubtitle = data.get("location_full_subtitle");

  // LocationSelect consumes plain JS objects
  return {
    type,
    title,
    subtitle,
    full_title: fullTitle,
    full_subtitle: fullSubtitle,
    location_id: locationId,
    country_id: countryId,
  };
};

type RegionSelectValue = {
  name?: string | null;
  region_id: string;
  country_id?: number | null;
  country_name?: string | null;
};

const getRegionSelectValue = (
  data: ContractorUpdateDataMap
): null | RegionSelectValue => {
  // we need some fake value for ID to fill select component with initial value
  const regionId = data.get("region_id") || " ";

  if (regionId == null) {
    return null;
  }

  return {
    name: data.get("region"),
    region_id: regionId,
    country_id: data.get("country_id"),
    country_name: data.get("country"),
  };
};

const getExpLevelSelectValue = (
  data: ContractorUpdateDataMap
): { id: number; title?: string | null } | null => {
  const expLevel = data.get("exp_level");
  const expLevelTitle = data.get("exp_level_title");

  return expLevel != null ? { id: expLevel, title: expLevelTitle } : null;
};

type ContractorUpdateDataObject = Partial<
  Nullable<{
    job_title_id: number;
    job_collection_id: number;
    job_collection_title: string;
    job_collection_slug: string;
    industry_id: number;
    industry_title: string;
    worker_type_id: number;
    worker_type_title: string;
    worker_type_reset: boolean;
    location_id: number;
    country_id: number;
    country: string;
    city: string;
    state: string;
    location_type: string;
    location_title: string;
    location_subtitle: string;
    location_full_title: string;
    location_full_subtitle: string;
    region_id: string;
    region: string;
    exp_level: number;
    exp_level_title: string;
    exp_level_reset: boolean;
    job_description: string;
    collection_limits_client_accepted: boolean;
    is_global_supplier_search: boolean;
    ids_to_update: ImmutableList<number>;
  }>
>;

type ContractorUpdateDataMap = ImmutableMap<ContractorUpdateDataObject>;

type PropsType = {
  store: MobXStore;
  idsToUpdate: ImmutableList<number>;
  programId: number;
  storedIndexId?: number;
  paramsToUpdate: ImmutableSet<string>;
  allowApproveAction?: boolean;
  allowRefreshPublicTitlesAction?: boolean;
  email?: string; // admin email
  workerTypeCountryId?: number; // for Worker Type select operation
  workerTypeIndustryId?: number; // for Worker Type select operation
  onApply?: () => Promise<void>;
  onCancel?: () => void;
  showModalSuccess: ShowModalFunc;
  showModalError: ShowModalFunc;
  fetchM8API: FetchAPIFunc;
  fetchCCCV1API: FetchAPIFunc;
  fetchTasteAPI: FetchAPIFunc;
  fetchGraphQL: FetchGraphQLAPIFunc;
  services: ServicesObject;
};

interface IncludeParametersStateType {
  needDescriptionChange: boolean;
  needCollectionChange: boolean;
  needLocationChange: boolean;
  needRegionChange: boolean;
  needIndustryChange: boolean;
  needExpLevelChange: boolean;
  needLimitsAcceptedChange: boolean;
  needGlobalSupplierSearchChange: boolean;
  needWorkerTypeChange: boolean;
}

interface StateType extends IncludeParametersStateType {
  data: ContractorUpdateDataMap;
  errors: ProcessingMessagesMap;
  warnings: ProcessingMessagesMap;
  skipWarnings: boolean;
  noValidationsYet: boolean;
  madeUpdate: boolean;
  sendEmail: boolean;
  loading: boolean;
}

const initialState: StateType = {
  data: emptyMap as ContractorUpdateDataMap,
  errors: emptyMap as ProcessingMessagesMap,
  warnings: emptyMap as ProcessingMessagesMap,
  skipWarnings: false,
  noValidationsYet: true,
  needDescriptionChange: false,
  needCollectionChange: false,
  needLocationChange: false,
  needRegionChange: false,
  needIndustryChange: false,
  needExpLevelChange: false,
  needLimitsAcceptedChange: false,
  needGlobalSupplierSearchChange: false,
  needWorkerTypeChange: false,
  madeUpdate: false,
  sendEmail: false,
  loading: false,
};

const AdminContractorBulkEditor = (props: PropsType): React.ReactElement => {
  const {
    store,
    programId,
    storedIndexId,
    idsToUpdate,
    paramsToUpdate,
    allowApproveAction,
    allowRefreshPublicTitlesAction,
    workerTypeCountryId,
    workerTypeIndustryId,
    email,
    onApply,
    onCancel,
    showModalSuccess,
    showModalError,
    fetchM8API,
    fetchCCCV1API,
    fetchTasteAPI,
    fetchGraphQL,
    services,
  } = props;

  const [state, setState] = useState<StateType>(initialState);

  const errors = state.errors;
  const warnings = state.warnings;

  // messaging

  const { messagerRef, showError, showSuccess, showHint, hideMessage } = useMessagerRef();

  // handlers

  const handleSendEmailChange = useCallback((value: boolean) => {
    setState((prevState) => ({
      ...prevState,
      sendEmail: value,
    }));
  }, []);

  const handleIncludeExcludeParameter = useCallback(
    (key: keyof IncludeParametersStateType, value: boolean) => {
      if (state[key] !== value) {
        setState((prevState) => ({
          ...prevState,
          [key]: value,
        }));
      }
    },
    [state]
  );

  const handleDescriptionChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const value = e.target.value;
      if (value !== state.data.get("job_description")) {
        setState((prevState) => ({
          ...prevState,
          data: state.data.set("job_description", value),
        }));
      }
    },
    [state.data]
  );

  const handleLimitsAcceptedChange = useCallback(
    (value: boolean) => {
      if (value != null) {
        setState((prevState) => ({
          ...prevState,
          data: state.data.set("collection_limits_client_accepted", value),
        }));
      }
    },
    [state.data]
  );

  const handleGlobalSupplierSearchChange = useCallback(
    (value: boolean) => {
      if (value != null) {
        setState((prevState) => ({
          ...prevState,
          data: state.data.set("is_global_supplier_search", value),
        }));
      }
    },
    [state.data]
  );

  const handleCollectionRemove = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      data: state.data
        .set("job_title_id", null)
        .set("job_collection_id", null)
        .set("job_collection_title", null)
        .set("job_collection_slug", null),
      skipWarnings: false,
      madeUpdate: false,
    }));
  }, [state.data]);

  type CollectionSelectData = ImmutableMap<{
    id: number;
    title: string;
    slug: string;
  }>;

  const handleCollectionSelect = useCallback(
    (value: CollectionSelectData) => {
      if (value && value.size) {
        const data = state.data || emptyMap;
        const collectionId = value.get("id");
        const collectionTitle = value.get("title");
        const collectionSlug = value.get("slug");

        if (collectionId != null && collectionId !== data.get("job_collection_id")) {
          setState((prevState) => ({
            ...prevState,
            data: data
              .set("job_collection_id", collectionId)
              .set("job_collection_title", collectionTitle)
              .set("job_collection_slug", collectionSlug),
            skipWarnings: false,
            madeUpdate: false,
          }));
        }
      }
    },
    [state.data]
  );

  const handleIndustryChange = useCallback(
    (value: IndustrySelectValue) => {
      const data = state.data || emptyMap;
      const industryId = value["id"];
      const industryTitle = value["title"];

      if (industryId != null && industryId !== data.get("industry_id")) {
        setState((prevState) => ({
          ...prevState,
          data: data.set("industry_id", industryId).set("industry_title", industryTitle),
          skipWarnings: false,
          madeUpdate: false,
        }));
      }
    },
    [state.data]
  );

  const handleWorkerTypeChange = useCallback(
    (value: ValueType<WorkerTypeSelectValue>) => {
      const data = state.data || emptyMap;
      const cleanValue: WorkerTypeSelectValue | null | undefined = Array.isArray(value)
        ? value[0]
        : value;
      const workerTypeId = cleanValue?.id;
      const workerTypeTitle = cleanValue?.name;

      if (workerTypeId != null && workerTypeId !== data.get("worker_type_id")) {
        setState((prevState) => ({
          ...prevState,
          data: data
            .set("worker_type_id", workerTypeId)
            .set("worker_type_title", workerTypeTitle)
            .delete("worker_type_reset"),
          skipWarnings: false,
          madeUpdate: false,
        }));
      } else if (workerTypeId == null) {
        setState((prevState) => ({
          ...prevState,
          data: data
            .delete("worker_type_id")
            .delete("worker_type_title")
            .delete("worker_type_reset"),
          skipWarnings: false,
          madeUpdate: false,
        }));
      }
    },
    [state.data]
  );

  const handleWorkerTypeReset = useCallback(
    (value: boolean) => {
      const data = state.data || emptyMap;

      if (!!value) {
        setState((prevState) => ({
          ...prevState,
          data: data
            .set("worker_type_id", null)
            .set("worker_type_title", null)
            .set("worker_type_reset", true),
          skipWarnings: false,
          madeUpdate: false,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          data: data
            .delete("worker_type_id")
            .delete("worker_type_title")
            .delete("worker_type_reset"),
          skipWarnings: false,
          madeUpdate: false,
        }));
      }
    },
    [state.data]
  );

  const handleRegionChange = useCallback(
    (value: RegionSelectValue) => {
      if (value && value["region_id"] != null) {
        const data = state.data || emptyMap;
        const regionId = value["region_id"];
        const regionName = value["name"];
        const regionHasChanged = regionId !== data.get("region_id");

        if (regionId != null && regionHasChanged) {
          let countryId = value["country_id"];
          let countryName = value["country_name"];

          // we can't change region if its country data is still NOT available
          if (countryId == null || countryName == null) {
            showModalError(
              "Not all Region data available for further update, please contact support team."
            );
            return;
          }

          setState((prevState) => ({
            ...prevState,
            data: data
              .set("region", regionName)
              .set("country", countryName)
              .set("region_id", regionId)
              .set("country_id", countryId),
            skipWarnings: false,
            madeUpdate: false,
          }));
        }
      }
    },
    [state.data, showModalError]
  );

  interface LocationData {
    city: string;
    state: string;
    country: string;
  }

  interface LocationsResponse {
    data: LocationData;
  }

  const handleLocationChange = useCallback(
    (value: LocationSelectValue) => {
      if (value && value["location_id"] != null) {
        const data = state.data || emptyMap;
        const locationId = value["location_id"];
        const countryId = value["country_id"];
        const locationHasChanged = locationId !== data.get("location_id");

        if (locationId != null && countryId != null && locationHasChanged) {
          setState((prevState) => ({ ...prevState, loading: true }));
          showHint("checking location...");

          const isLegacy = ("" + locationId).startsWith("ll:");
          const locationPathUrl = `${
            isLegacy ? "legacy_locations" : "locations"
          }/${encodeURIComponent(locationId)}/path/`;

          fetchCCCV1API(locationPathUrl)
            .then((response: LocationsResponse) => {
              const locationPath = response.data;
              const city = locationPath["city"];
              const state = locationPath["state"];
              const country = locationPath["country"];

              setState((prevState) => ({
                ...prevState,
                data: data
                  .set("city", city)
                  .set("state", state)
                  .set("country", country)
                  .set("location_id", locationId)
                  .set("country_id", countryId)
                  .set("location_title", value["title"])
                  .set("location_subtitle", value["subtitle"])
                  .set("location_full_title", value["full_title"])
                  .set("location_full_subtitle", value["full_subtitle"]),
                skipWarnings: false,
                madeUpdate: false,
                loading: false,
              }));
              hideMessage();
            })
            .catch((err: Error) => {
              logAsyncOperationError("fetchLocationData", err);
              showError("Error while retrieving location data.");
              setState((prevState) => ({ ...prevState, loading: false }));
            });
        }
      }
    },
    [state.data, fetchCCCV1API, showHint, hideMessage, showError]
  );

  const handleExpLevelChange = useCallback(
    (value: { id: number; title?: string | null }) => {
      if (value && value["id"] != null) {
        const data = state.data || emptyMap;
        const expLevel = value["id"];
        const expLevelTitle = value["title"];

        if (expLevel !== data.get("exp_level")) {
          setState((prevState) => ({
            ...prevState,
            data: data
              .set("exp_level", expLevel)
              .set("exp_level_title", expLevelTitle)
              .set("exp_level_reset", false),
            skipWarnings: false,
            madeUpdate: false,
          }));
        }
      }
    },
    [state.data]
  );

  const handleExpLevelResetChange = useCallback(
    (value: boolean) => {
      setState((prevState) => ({
        ...prevState,
        data: state.data
          .set("exp_level_reset", !!value)
          .set("exp_level", !!value ? null : state.data.get("exp_level"))
          .set("exp_level_title", !!value ? null : state.data.get("exp_level_title")),
        skipWarnings: false,
        madeUpdate: false,
      }));
    },
    [state.data]
  );

  const handleSkipWarnings = useCallback(() => {
    const errors: ProcessingMessagesMap = state.errors || emptyMap;
    const warnings: ProcessingMessagesMap = state.warnings || emptyMap;

    if (errors.size) {
      return;
    }
    if (warnings.size) {
      setState((prevState) => ({
        ...prevState,
        warnings: emptyMap as ProcessingMessagesMap,
        skipWarnings: true,
      }));

      showSuccess("Now you can apply changes.");
    }
  }, [state.errors, state.warnings, showSuccess]);

  interface BulkUpdateMappingsData {
    status: "success";
    produced_searches_count: number;
  }

  interface BulkUpdateMappingsResponse {
    data: BulkUpdateMappingsData;
  }

  const handleApplyChanges = useCallback(() => {
    const {
      data,
      skipWarnings,
      sendEmail,
      needDescriptionChange,
      needCollectionChange,
      needLocationChange,
      needIndustryChange,
      needRegionChange,
      needExpLevelChange,
      needLimitsAcceptedChange,
      needGlobalSupplierSearchChange,
      needWorkerTypeChange,
    } = state;

    const dataToUpdate: ContractorUpdateDataObject = {
      ids_to_update: idsToUpdate,
    };
    const extraArgs: { __stored_index?: number; __inform_on_email?: string } = {};

    if (storedIndexId != null) {
      extraArgs["__stored_index"] = storedIndexId;
    }
    if (sendEmail && email) {
      extraArgs["__inform_on_email"] = email;
    }

    if (needCollectionChange) {
      // dataToUpdate['job_title_id'] = data.get('job_title_id') || null;
      dataToUpdate["job_collection_id"] = data.get("job_collection_id") || null;
      dataToUpdate["job_collection_title"] = data.get("job_collection_title") || null;
      dataToUpdate["job_collection_slug"] = data.get("job_collection_slug") || null;
    }
    if (needLocationChange) {
      dataToUpdate["city"] = data.get("city");
      dataToUpdate["state"] = data.get("state");
      dataToUpdate["country"] = data.get("country");
      dataToUpdate["location_id"] = data.get("location_id") || null;
      dataToUpdate["country_id"] = data.get("country_id") || null;
    }
    if (needRegionChange) {
      dataToUpdate["region"] = data.get("region");
      dataToUpdate["country"] = data.get("country");
      dataToUpdate["region_id"] = data.get("region_id");
      dataToUpdate["country_id"] = data.get("country_id");
    }
    if (needIndustryChange) {
      dataToUpdate["industry_id"] = data.get("industry_id") || null;
      dataToUpdate["industry_title"] = data.get("industry_title") || null;
    }
    if (needWorkerTypeChange) {
      dataToUpdate["worker_type_id"] = data.get("worker_type_id") || null;
      dataToUpdate["worker_type_title"] = data.get("worker_type_title") || null;
      dataToUpdate["worker_type_reset"] = data.get("worker_type_reset") || false;
    }
    if (needDescriptionChange) {
      dataToUpdate["job_description"] = data.get("job_description") || null;
    }
    if (needExpLevelChange) {
      dataToUpdate["exp_level"] = data.get("exp_level") || null;
      dataToUpdate["exp_level_reset"] = data.get("exp_level_reset") || false;
    }
    if (needLimitsAcceptedChange) {
      dataToUpdate["collection_limits_client_accepted"] = data.get(
        "collection_limits_client_accepted",
        null
      );
    }
    if (needGlobalSupplierSearchChange) {
      dataToUpdate["is_global_supplier_search"] = data.get(
        "is_global_supplier_search",
        null
      );
    }

    showHint("Checking data...");

    const validationPromise = skipWarnings
      ? Promise.resolve(null)
      : fetchM8API(`programs/${programId}/contractors/bulk_validate_mappings/`, {
          method: "post",
          params: extraArgs,
          data: dataToUpdate,
        });

    type BulkUpdateValidationResultObject = {
      errors: ProcessingMessagesMap;
      warnings: ProcessingMessagesMap;
    };
    type BulkUpdateValidationResultMap = ImmutableMap<BulkUpdateValidationResultObject>;

    return validationPromise
      .then((response: FetchAPIResponse<BulkUpdateValidationResultObject> | null) => {
        const validationData = (
          response?.data ? fromJS(response.data) : emptyMap
        ) as BulkUpdateValidationResultMap;
        const newErrors =
          validationData.get("errors") || (emptyMap as ProcessingMessagesMap);
        const newWarnings =
          validationData.get("warnings") || (emptyMap as ProcessingMessagesMap);

        if (newErrors.size || newWarnings.size) {
          let errors = state.errors ?? (emptyMap as ProcessingMessagesMap);
          let warnings = state.warnings ?? (emptyMap as ProcessingMessagesMap);

          errors = newErrors.size
            ? errors.merge(newErrors)
            : (emptyMap as ProcessingMessagesMap);
          warnings = newWarnings.size
            ? warnings.merge(newWarnings)
            : (emptyMap as ProcessingMessagesMap);

          setState((prevState) => ({
            ...prevState,
            noValidationsYet: false,
            errors,
            warnings,
          }));

          if (errors.size) {
            showError("There are still some errors! Check status line above.");
          } else if (warnings.size) {
            showError("There are still some warnings! Check status line above.");
          }

          return Promise.resolve();
        } else {
          showHint("Updating rows...");
          setState((prevState) => ({
            ...prevState,
            noValidationsYet: false,
            errors: emptyMap as ProcessingMessagesMap,
            warnings: emptyMap as ProcessingMessagesMap,
          }));

          return fetchM8API(`programs/${programId}/contractors/bulk_update_mappings/`, {
            method: "post",
            params: extraArgs,
            data: dataToUpdate,
          }).then((response: BulkUpdateMappingsResponse) => {
            setState((prevState: StateType) => ({
              ...prevState,
              data: emptyMap as ContractorUpdateDataMap,
              errors: emptyMap as ProcessingMessagesMap,
              warnings: emptyMap as ProcessingMessagesMap,
              skipWarnings: false,
              noValidationsYet: true,
              needDescriptionChange: false,
              needCollectionChange: false,
              needLocationChange: false,
              needIndustryChange: false,
              needRegionChange: false,
              needExpLevelChange: false,
              needLimitsAcceptedChange: false,
              needGlobalSupplierSearchChange: false,
              madeUpdate: true,
            }));
            hideMessage();

            const updateResultData: ImmutableMap<BulkUpdateMappingsData> = fromJS(
              response.data
            );
            const producedSearchesCount = updateResultData.get(
              "produced_searches_count",
              0
            );
            const onApplyPromise = onApply ? onApply() : Promise.resolve();

            return onApplyPromise.then(() => {
              if (producedSearchesCount) {
                showModalSuccess(
                  `Bulk update has been finished. ${producedSearchesCount} rows sent for processing.`
                );
              } else {
                showModalSuccess("Bulk update has been finished.");
              }
            });
          });
        }
      })
      .catch((err: Error) => {
        hideMessage();
        logAsyncOperationError("bulkUpdateMappings", err);
        showModalError("Error while bulk-updating contractors mappings.");
      });
  }, [
    idsToUpdate,
    programId,
    email,
    storedIndexId,
    state,
    hideMessage,
    showModalError,
    showError,
    showHint,
    showModalSuccess,
    onApply,
    fetchM8API,
  ]);

  interface RunPublicTitlesRefreshData {
    status: "OK";
    count: number;
  }

  interface RunPublicTitlesRefreshResponse {
    data: RunPublicTitlesRefreshData;
  }

  const handleRunPublicTitlesRefresh = useCallback(async () => {
    try {
      showHint("refreshing...");
      const response: RunPublicTitlesRefreshResponse = await fetchM8API(
        `programs/${programId}/contractors/run_public_titles_refresh/`,
        {
          method: "post",
          data: { ids_to_refresh: idsToUpdate },
        }
      );
      const data = response.data;

      hideMessage();
      if (onApply) await onApply();
      showModalSuccess("Public titles have been successfully refreshed.");

      return data;
    } catch (err: any) {
      hideMessage();
      logAsyncOperationError("runPublicTitlesRefresh", err);
      showModalError("Error occurred while public titles refreshing.");
    }
  }, [
    idsToUpdate,
    programId,
    hideMessage,
    showModalError,
    showHint,
    showModalSuccess,
    onApply,
    fetchM8API,
  ]);

  const handleApproveData = useCallback(async () => {
    try {
      showHint("approving...");
      const response: BulkUpdateMappingsResponse = await fetchM8API(
        `programs/${programId}/contractors/bulk_approve_mappings/`,
        {
          method: "post",
          data: { ids_to_approve: idsToUpdate },
        }
      );
      const data = response.data;
      const producedSearchesCount = data["produced_searches_count"] || 0;

      if (onApply) await onApply();
      hideMessage();
      if (producedSearchesCount) {
        showModalSuccess(
          `Bulk approve has been finished. ${producedSearchesCount} rows sent for processing.`
        );
      } else {
        showModalSuccess("Bulk approve has been finished.");
      }

      return data;
    } catch (err: any) {
      hideMessage();
      logAsyncOperationError("bulkApproveMappings", err);
      showModalError("Error occurred while rows bulk-approval operation.");
    }
  }, [
    idsToUpdate,
    programId,
    hideMessage,
    showModalError,
    showHint,
    showModalSuccess,
    onApply,
    fetchM8API,
  ]);

  const hasChanges =
    !!state.data && !!state.data.size && !!state.data.filter((val) => val != null).size;
  const description = state.data.get("job_description");
  const collectionId = state.data.get("job_collection_id");
  const expLevelReset = !!state.data.get("exp_level_reset", false);
  const workerTypeReset = !!state.data.get("worker_type_reset", false);

  let problemsBlockBackgroundColor;
  if (!state.noValidationsYet && errors.size > 0) {
    problemsBlockBackgroundColor = "$dangerLightest";
  } else if (!state.noValidationsYet && errors.size === 0 && warnings.size > 0) {
    problemsBlockBackgroundColor = "$warningLightest";
  } else if (!state.noValidationsYet && errors.size === 0 && warnings.size === 0) {
    problemsBlockBackgroundColor = "$successLightest";
  } else if (state.noValidationsYet) {
    problemsBlockBackgroundColor = "$primaryLighter";
  }

  const {
    allowDescriptionUpdate,
    allowCollectionUpdate,
    allowIndustryUpdate,
    allowLocationUpdate,
    allowRegionUpdate,
    allowExpLevelUpdate,
    allowLimitsAcceptedUpdate,
    allowGlobalSupplierSearchUpdate,
    allowWorkerTypeUpdate,
  } = React.useMemo(() => getAllowedParametersFlags(paramsToUpdate), [paramsToUpdate]);

  return (
    <Stack fill css={{ alignItems: "flex-start", gap: "$6" }}>
      {/* Messages block */}
      <Stack
        fill
        css={{
          backgroundColor: problemsBlockBackgroundColor,
          alignItems: "stretch",
          padding: "$5",
          gap: "$2",

          "& h4": {
            margin: "0 !important",
          },

          [`& ${Icon}`]: {
            fontSize: "$3xl",
            verticalAlign: "middle",
            marginRight: "$2",
            color: "$primary",
          },

          [`& ${Inline}`]: {
            paddingLeft: "$3",
            gap: "0",
            fontSize: "$lg",
          },
        }}
      >
        {errors.size > 0 ? (
          <Text as="h4">We found following errors in provided data:</Text>
        ) : warnings.size > 0 ? (
          <Text as="h4">
            We found following warnings in provided data. They are not critical and you
            can skip them using button below:
          </Text>
        ) : state.noValidationsYet ? (
          <>
            <Text as="h4">
              <Icon icon="info-circle" /> Use the fields below to optionally edit mappings
              parameters.
            </Text>
            {(!allowCollectionUpdate || !allowDescriptionUpdate) && (
              <Text as="h4">
                <Icon icon="info-circle" /> In order to be able to bulk update job
                description or collection mapping, titles selected must all be identical.
              </Text>
            )}
            {!allowLocationUpdate && !allowRegionUpdate && (
              <Text as="h4">
                <Icon icon="info-circle" /> In order to be able to bulk update location or
                region mapping, titles selected must all be of single type - either
                location-based or region-based.
              </Text>
            )}
            {!allowWorkerTypeUpdate && (
              <Text as="h4">
                <Icon icon="info-circle" /> In order to be able to bulk update worker type
                value, titles selected must all be of the same exact Country/Industry.
              </Text>
            )}
          </>
        ) : (
          <Text as="h4">
            <Icon css={{ color: "$successLight !important" }} icon="check-circle" /> We
            didn't find any problems with provided data.
          </Text>
        )}
        {errors
          .valueSeq()
          .toArray()
          .map((error, idx) => (
            <Inline nowrap key={idx}>
              <Icon css={{ color: "$dangerLight !important" }} icon="times-circle" />{" "}
              {error.get("message")}
            </Inline>
          ))}
        {warnings
          .valueSeq()
          .toArray()
          .map((warning, idx) => (
            <Inline nowrap key={idx}>
              <Icon
                css={{ color: "$warningLight !important" }}
                icon="exclamation-circle"
              />{" "}
              {warning.get("message")}
            </Inline>
          ))}
      </Stack>

      {/* Editor form block */}
      <Stack
        fill
        nogap
        css={{
          alignItems: "stretch",
          "@md": {
            padding: "0 $8",
          },
        }}
      >
        {allowDescriptionUpdate && (
          <Container>
            <CheckboxItem
              checked={state.needDescriptionChange}
              onCheckedChange={(value: boolean) =>
                handleIncludeExcludeParameter("needDescriptionChange", value)
              }
            >
              Job Description:
            </CheckboxItem>
            <Alert
              color={state.needDescriptionChange ? "warning" : "primary"}
              css={{ padding: "$2", width: "$full" }}
            >
              <Text underline bold>
                IMPORTANT:
              </Text>
              &nbsp; system will search and fix others exactly same
              title/description/source.
            </Alert>
            <TextArea
              value={description ?? ""}
              disabled={!state.needDescriptionChange}
              onChange={handleDescriptionChange}
            />
          </Container>
        )}
        {allowCollectionUpdate && (
          <Container>
            <CheckboxItem
              checked={state.needCollectionChange}
              onCheckedChange={(value: boolean) =>
                handleIncludeExcludeParameter("needCollectionChange", value)
              }
            >
              Job Title Collection:
            </CheckboxItem>
            <Alert
              color={state.needCollectionChange ? "warning" : "primary"}
              css={{ padding: "$2", width: "$full" }}
            >
              <Text underline bold>
                IMPORTANT
              </Text>
              :&nbsp; system will search and fix others exactly same
              title/description/source.
            </Alert>

            <Sticker
              value={getCollectionStickerValue(state.data, services?.taste)}
              onRemove={handleCollectionRemove}
              disabled={!state.needCollectionChange}
              removable
            />

            <JobCollectionSelect
              className="full-width"
              searchPlaceholder="Search by id or title..."
              value={getCollectionSelectValue(state.data)}
              onSelect={handleCollectionSelect}
              disabled={collectionId != null || !state.needCollectionChange}
              fetchTasteAPI={fetchTasteAPI}
              showModalError={showModalError}
            />
          </Container>
        )}
        {allowIndustryUpdate && (
          <Container>
            <CheckboxItem
              checked={state.needIndustryChange}
              onCheckedChange={(value: boolean) =>
                handleIncludeExcludeParameter("needIndustryChange", value)
              }
            >
              Industry:
            </CheckboxItem>
            <IndustrySelect
              className="full-width"
              store={store.industriesStore}
              value={getIndustrySelectValue(state.data)}
              onChange={handleIndustryChange}
              disabled={!state.needIndustryChange}
              styles={reactSelectStyles}
            />
          </Container>
        )}
        {allowLocationUpdate && (
          <Container>
            <CheckboxItem
              checked={state.needLocationChange}
              onCheckedChange={(value: boolean) =>
                handleIncludeExcludeParameter("needLocationChange", value)
              }
            >
              Location:
            </CheckboxItem>
            <LocationSelect
              className="full-width"
              fetchGraphQL={fetchGraphQL}
              value={getLocationSelectValue(state.data)}
              loading={state.needLocationChange && state.loading}
              disabled={!state.needLocationChange}
              onChange={handleLocationChange}
              styles={reactSelectStyles}
            />
          </Container>
        )}
        {allowRegionUpdate && (
          <Container>
            <CheckboxItem
              checked={state.needRegionChange}
              onCheckedChange={(value: boolean) =>
                handleIncludeExcludeParameter("needRegionChange", value)
              }
            >
              Region:
            </CheckboxItem>
            <RegionSelect
              className="full-width"
              fetchGraphQL={fetchGraphQL}
              value={getRegionSelectValue(state.data)}
              loading={state.needRegionChange && state.loading}
              disabled={!state.needRegionChange}
              onChange={handleRegionChange}
              styles={reactSelectStyles}
            />
          </Container>
        )}
        {workerTypeCountryId != null &&
          workerTypeIndustryId != null &&
          allowWorkerTypeUpdate && (
            <Container>
              <CheckboxItem
                checked={state.needWorkerTypeChange}
                onCheckedChange={(value: boolean) =>
                  handleIncludeExcludeParameter("needWorkerTypeChange", value)
                }
              >
                Worker Type:
              </CheckboxItem>
              <WorkerTypeSelect
                // providing key prop fixes the react-select cache issue (it doesn't invalidate) by re-rendering it completely
                key={[workerTypeCountryId, workerTypeIndustryId].join(", ")}
                className="full-width"
                placeholder="No worker type selected"
                countryId={workerTypeCountryId}
                industryId={workerTypeIndustryId}
                value={getWorkerTypeSelectValue(state.data)}
                disabled={!state.needWorkerTypeChange}
                onChange={handleWorkerTypeChange}
                fetchGraphQL={fetchGraphQL}
                styles={reactSelectStyles}
                clearable
              />
              <CheckboxItem
                checked={workerTypeReset}
                onCheckedChange={handleWorkerTypeReset}
                disabled={!state.needWorkerTypeChange}
              >
                Reset Worker Type
              </CheckboxItem>
            </Container>
          )}
        {allowExpLevelUpdate && (
          <Container>
            <CheckboxItem
              checked={state.needExpLevelChange}
              onCheckedChange={(value: boolean) =>
                handleIncludeExcludeParameter("needExpLevelChange", value)
              }
            >
              Expertise Level:
            </CheckboxItem>
            <ExpLevelSelect
              className="full-width"
              value={getExpLevelSelectValue(state.data)}
              onChange={handleExpLevelChange}
              disabled={!state.needExpLevelChange}
              styles={reactSelectStyles}
            />
            <CheckboxItem
              checked={expLevelReset}
              onCheckedChange={handleExpLevelResetChange}
              disabled={!state.needExpLevelChange}
            >
              Reset to Market Expertise Level
            </CheckboxItem>
          </Container>
        )}
        {allowLimitsAcceptedUpdate && (
          <Container>
            <CheckboxItem
              checked={state.needLimitsAcceptedChange}
              onCheckedChange={(value: boolean) =>
                handleIncludeExcludeParameter("needLimitsAcceptedChange", value)
              }
            >
              Mark rates limits as Accepted/Not Accepted By Client:
            </CheckboxItem>
            <Alert
              color={state.needLimitsAcceptedChange ? "warning" : "primary"}
              css={{ padding: "$2", width: "$full" }}
            >
              <Text underline bold>
                IMPORTANT
              </Text>
              :&nbsp; system will apply chosen value to all collection/country pairs.
            </Alert>
            <RadioGroup
              value={state.data.get("collection_limits_client_accepted")}
              onValueChange={handleLimitsAcceptedChange}
            >
              <Inline>
                <RadioGroupItem value={true} disabled={!state.needLimitsAcceptedChange}>
                  Accepted
                </RadioGroupItem>
                <RadioGroupItem value={false} disabled={!state.needLimitsAcceptedChange}>
                  Not Accepted
                </RadioGroupItem>
              </Inline>
            </RadioGroup>
          </Container>
        )}
        {allowGlobalSupplierSearchUpdate && (
          <Container>
            <CheckboxItem
              checked={state.needGlobalSupplierSearchChange}
              onCheckedChange={(value: boolean) =>
                handleIncludeExcludeParameter("needGlobalSupplierSearchChange", value)
              }
            >
              Global Supplier Search:
            </CheckboxItem>
            <RadioGroup
              value={state.data.get("is_global_supplier_search")}
              onValueChange={handleGlobalSupplierSearchChange}
            >
              <Inline>
                <RadioGroupItem
                  value={true}
                  disabled={!state.needGlobalSupplierSearchChange}
                >
                  Yes
                </RadioGroupItem>
                <RadioGroupItem
                  value={false}
                  disabled={!state.needGlobalSupplierSearchChange}
                >
                  No
                </RadioGroupItem>
              </Inline>
            </RadioGroup>
          </Container>
        )}
      </Stack>

      {/* Email block */}
      {email && (
        <Stack
          fill
          css={{
            alignItems: "flex-start",
            gap: "$2",
            "@md": {
              padding: "0 $4",
            },
          }}
        >
          <Text as="h5" css={{ fontSize: "$xs", margin: 0 }}>
            <Icon icon="info-circle" /> - Right after update system will run searches for
            just updated rows. Running searches is async procedure. Turn on email
            notification in order ot stay tuned.
          </Text>
          <CheckboxItem checked={state.sendEmail} onCheckedChange={handleSendEmailChange}>
            Send me an email when it's done.
          </CheckboxItem>
        </Stack>
      )}

      {/* Buttons block */}
      <Stack fill>
        <Messager ref={messagerRef} />
        <ButtonGroupRight fill>
          {!state.madeUpdate && (
            <PromiseButton
              color="green"
              size="normal"
              loadingText="Applying..."
              title="Apply changes to selected rows"
              disabled={!hasChanges || state.loading}
              onClick={handleApplyChanges}
            >
              Apply Changes
            </PromiseButton>
          )}
          {warnings.size > 0 && (
            <Button
              color="yellow"
              size="normal"
              title="Skip warnings to be able to proceed with changes"
              disabled={errors.size > 0}
              onClick={handleSkipWarnings}
            >
              Skip Warnings
            </Button>
          )}
          {warnings.size === 0 &&
            errors.size === 0 &&
            !hasChanges &&
            allowRefreshPublicTitlesAction && (
              <PromiseButton
                color="green"
                size="normal"
                loadingText="Refresh Public Titles"
                title="Run public titles refresh for selected rows"
                disabled={state.loading}
                onClick={handleRunPublicTitlesRefresh}
              >
                Refresh Public Titles
              </PromiseButton>
            )}
          {warnings.size === 0 &&
            errors.size === 0 &&
            !hasChanges &&
            allowApproveAction && (
              <PromiseButton
                color="green"
                size="normal"
                loadingText="Approve Data"
                title="Approve mappings for selected rows"
                disabled={state.loading}
                onClick={handleApproveData}
              >
                Approve Data
              </PromiseButton>
            )}
          <Button
            color="gray"
            size="normal"
            title="Cancel changes and close editor"
            disabled={state.loading}
            onClick={onCancel}
          >
            Cancel
          </Button>
        </ButtonGroupRight>
      </Stack>
    </Stack>
  );
};

AdminContractorBulkEditor.displayName = "AdminContractorBulkEditor";
AdminContractorBulkEditor.defaultProps = {
  paramsToUpdate: Set([PARAMS_FOR_BULK_UPDATE.INDUSTRY]),
  allowApproveAction: false,
  allowRefreshPublicTitlesAction: true,
};

export default AdminContractorBulkEditor;
