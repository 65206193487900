// @flow
import React, { Component } from "react";
import { observer } from "mobx-react";
import PlainHighChart from "../../ratecards/components/PlainHighChart";
import numeral from "numeral";

type WhiskerChartData = {
  name?: string,
  low: number,
  high: number,
  median: number,
  q1: number,
  q3: number,
};

type Props = {
  series: WhiskerChartData[],
  currencySymbol?: ?string,
};

const HighestPayingJobsWhiskerChart = observer(
  class extends Component<Props> {
    render() {
      const props = this.props;
      const currencySymbol = props.currencySymbol || "";
      let chartConfig = {
        chart: {
          type: "boxplot",
          inverted: true,
        },

        title: null,

        legend: {
          enabled: false,
        },

        xAxis: {
          type: "category",
        },

        yAxis: {
          title: {
            text: "Pay Rate",
          },
          labels: {
            formatter: function () {
              const x = numeral(this.value).format("Oa");
              return `${currencySymbol} ${x}`;
            },
          },
        },

        plotOptions: {
          boxplot: {
            colorByPoint: true,
            lineWidth: 2,
            medianWidth: 0,
            stemWidth: 10,
            whiskerLength: 18,
            whiskerWidth: 3,
            maxPointWidth: 8,
            connectNulls: true,
          },
        },

        series: [
          {
            name: "Jobs",
            data: props.series,
            tooltip: {
              headerFormat: "<b>{point.key}</b><br/>",
              pointFormatter: function () {
                const low = currencySymbol + " " + numeral(this.low).format("0,0.00");
                // const median = currencySymbol + " " + numeral(this.median).format("0,0.00");
                const high = currencySymbol + " " + numeral(this.high).format("0,0.00");

                return `Pay Rate Min: <b>${low}</b><br/>Pay Rate Max: <b>${high}</b><br/>`;
              },
            },
          },
        ],
      };

      return (
        <div className="chart-container" style={{ height: 426, width: "100%" }}>
          <PlainHighChart options={chartConfig} style={{ height: 426, width: "100%" }} />
        </div>
      );
    }
  }
);

export default HighestPayingJobsWhiskerChart;
