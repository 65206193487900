// @flow

import { HTTPError } from "./fetch";

const debug = process.env.NODE_ENV !== "production";

/**
 * Logs any HTTP error usually coming from data fetch, returns boolean representing whether it succeeds or not
 */
export function logHTTPError(operationName: string, error: HTTPError): boolean {
  if (!error || !(error instanceof HTTPError)) return false;

  if (debug) {
    console.error(`${error.message}.\n`, `Operation: ${operationName}.\n`, error);
  } else {
    console.error(
      `${error.message}.\n`,
      `Operation: ${operationName}.\n`,
      "Please contact PeopleTicker and let them know. Thanks!"
    );
  }

  return true;
}

/**
 * Logs any async operation error if provided, returns boolean representing whether it succeeds or not
 */
export function logAsyncOperationErrorBase(operationName: string, error: Error): boolean {
  if (!error) return false;

  let errorCode = "No Error code";
  // $FlowFixMe suppress complaints on the "code" attribute
  if (error.hasOwnProperty("code")) errorCode = "Error code: " + error.code;

  if (debug) {
    console.error(
      "Async operation error.\n",
      `Operation: ${operationName}.\n`,
      `${errorCode}.\n`,
      error
    );
  } else {
    console.error(
      "Async operation error.\n",
      `Operation: ${operationName}.\n`,
      `${errorCode}.\n`,
      "Please contact PeopleTicker and let them know. Thanks!"
    );
  }

  return true;
}

export function logAsyncOperationError(operationName: string, error: Error): boolean {
  // TODO Think on how to handle GraphQL-specific errors here (usually received in the response payload),
  // TODO it will help us simplify the code by handling errors only in the "catch" block,
  // TODO might be good to define a special GraphQLError class, like HTTPError, in order to identify GraphQL payload errors.

  if (error && error instanceof HTTPError && logHTTPError(operationName, error))
    return true;
  else if (error && logAsyncOperationErrorBase(operationName, error)) return true;
  return false;
}

/**
 * Logs the graphql payload errors and returns them in the form of Array
 */
export function logGraphQLError(operationName: string, payload: Object): ?(string[]) {
  let errors = null;

  if (!payload) errors = ["No data received from server"];

  if (!errors && payload) errors = payload.errors;
  if (!errors && payload?.data?.errors) errors = payload.data.errors;
  if (!errors && payload?.data?.[operationName]?.errors)
    errors = payload.data[operationName].errors;
  if (!errors && payload?.data?.[operationName]?.error)
    errors = [payload.data[operationName].error];
  // TODO might be better to check all available operation names on the "data" object and search for errors in there
  // TODO and have operation name only for logging purposes, cause retrieving only particular operation errors requires
  // TODO to keep that name in sync with the query/mutation

  if (!errors) return null;

  if (debug) {
    console.error(
      "GraphQL query error.\n",
      `Operation: ${operationName}.\n`,
      "Errors:",
      errors
    );
  } else {
    console.error(
      "GraphQL query error: Invalid query or server error.\n",
      "Please contact PeopleTicker and let them know. Thanks!"
    );
  }

  return errors;
}
