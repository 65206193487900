// @flow
import { extendObservable, action, runInAction } from "mobx";
import axios from "axios";
import NetworkState from "../../models/NetworkState";
import ModalState from "../../models/ModalState";
import MessageState from "../../models/MessageState";
import UserList from "../../models/UserList";
import type { FetchGraphQL } from "../../App";
import randPasswordStr from "../../utils/generatePassword";

class AccountDetailStore {
  router: Object;
  network: NetworkState;
  userDetail: UserList;
  messaging: MessageState;
  userId: number;
  closeResetModal: () => void;
  clickDone: () => void;

  firstName: String;
  middleName: String;
  lastName: String;
  email: String;
  secondaryEmail: String;
  phoneNumber: String;
  alterPhoneNumber: String;
  address: String;
  apartment: String;
  city: String;
  state: String;
  postalCode: String;
  country: String;
  allowedCountries: String;
  resetPasswordSuccessMsg: String;
  clientId: number;
  clientName: String;
  newClientName: String;

  showHelpModal: boolean;
  showHelp: () => void;
  hideHelp: () => void;
  getUserDetails: () => void;
  isEditing: boolean;
  stopEditing: () => void;
  startEditing: () => void;
  saveEditedAccountDetail: () => void;
  resetPasswordMessage: MessageState;
  setLastName: () => void;
  resetPasswordModal: ModalState;
  resetUserPassword: () => void;
  newPassword: String;
  changeNewPassword: () => void;
  password: String;
  setPassword: () => void;
  generatePassword: () => void;
  primaryEmailMessage: MessageState;
  secondaryEmailMessage: MessageState;
  phoneMessage: MessageState;
  alterPhoneMessage: MessageState;
  lastNameMessage: MessageState;
  passwordChangeSuccess: boolean;
  oldPasswordView: boolean;
  newPasswordView: boolean;
  changeOldPasswordView: () => void;
  changeNewPasswordView: () => void;
  fetchGraphQL: FetchGraphQL;

  constructor(fetchGraphQL: FetchGraphQL) {
    this.fetchGraphQL = fetchGraphQL;
    this.router = null;

    extendObservable(this, {
      userId: null,
      network: new NetworkState(),
      renameNetwork: new NetworkState(),
      userDetail: null,
      showHelpModal: false,
      messaging: new MessageState(),
      resetPasswordMessage: new MessageState(),
      isEditing: false,
      shareRateCards: false,
      firstName: "",
      middleName: "",
      password: "",
      lastName: "",
      email: "",
      secondaryEmail: "",
      phoneNumber: "",
      alterPhoneNumber: "",
      address: "",
      apartment: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
      allowedCountries: "",
      clientId: 0,
      clientName: "",
      newClientName: "",
      resetPasswordModal: new ModalState(),
      newPassword: "",
      resetPasswordSuccessMsg: "",
      primaryEmailMessage: new MessageState(),
      secondaryEmailMessage: new MessageState(),
      phoneMessage: new MessageState(),
      alterPhoneMessage: new MessageState(),
      lastNameMessage: new MessageState(),
      passwordChangeSuccess: false,
      newPasswordView: false,
      oldPasswordView: false,
    });

    this.showHelp = action(this.showHelp.bind(this));
    this.hideHelp = action(this.hideHelp.bind(this));
    this.getUserDetails = action(this.getUserDetails.bind(this));
    this.stopEditing = action(this.stopEditing.bind(this));
    this.startEditing = action(this.startEditing.bind(this));
    this.saveEditedAccountDetail = action(this.saveEditedAccountDetail.bind(this));
    this.setEmail = action(this.setEmail.bind(this));
    this.setFirstName = action(this.setFirstName.bind(this));
    this.setMiddleName = action(this.setMiddleName.bind(this));
    this.setLastName = action(this.setLastName.bind(this));
    this.setPhoneNumber = action(this.setPhoneNumber.bind(this));
    this.setAlterPhoneNumber = action(this.setAlterPhoneNumber.bind(this));
    this.setLastName = action(this.setLastName.bind(this));
    this.setLastName = action(this.setLastName.bind(this));
    this.setAddress = action(this.setAddress.bind(this));
    this.setApartment = action(this.setApartment.bind(this));
    this.setCity = action(this.setCity.bind(this));
    this.setState = action(this.setState.bind(this));
    this.setPostalCode = action(this.setPostalCode.bind(this));
    this.setCountry = action(this.setCountry.bind(this));
    this.resetUserPassword = action(this.resetUserPassword.bind(this));
    this.changeNewPassword = action(this.changeNewPassword.bind(this));
    this.setPassword = action(this.setPassword.bind(this));
    this.generatePassword = action(this.generatePassword.bind(this));
    this.setSecondaryEmail = action(this.setSecondaryEmail.bind(this));
    this.changeOldPasswordView = action(this.changeOldPasswordView.bind(this));
    this.changeNewPasswordView = action(this.changeNewPasswordView.bind(this));
    this.closeResetModal = action(this.closeResetModal.bind(this));
    this.clickDone = action(this.clickDone.bind(this));
  }

  changeNewPasswordView() {
    this.newPasswordView = !this.newPasswordView;
  }

  changeOldPasswordView() {
    this.oldPasswordView = !this.oldPasswordView;
  }

  showHelp() {
    this.showHelpModal = true;
  }

  hideHelp() {
    this.showHelpModal = false;
  }

  changeNewPassword(e: Event) {
    this.newPassword = e.target.value;
  }

  setPassword(e: Event) {
    this.password = e.target.value;
  }

  closeResetModal() {
    this.password = "";
    this.newPassword = "";
    this.messaging.removeAll();
    this.resetPasswordModal.hideModal();
    this.resetPasswordMessage.removeAll();
  }

  clickDone() {
    this.passwordChangeSuccess = false;
    this.resetPasswordModal.hideModal();
  }

  stopEditing() {
    this.primaryEmailMessage.removeAll();
    this.secondaryEmailMessage.removeAll();
    this.phoneMessage.removeAll();
    this.alterPhoneMessage.removeAll();
    this.lastNameMessage.removeAll();
    this.email = this.userDetail.email;
    this.secondaryEmail = this.userDetail.secondaryEmail;
    this.shareRateCards = this.userDetail.shareRateCards;
    this.firstName = this.userDetail.firstName;
    this.middleName = this.userDetail.middleName;
    this.lastName = this.userDetail.lastName;
    this.newClientName = this.clientName;
    this.isEditing = false;
  }

  startEditing() {
    this.isEditing = true;
  }

  validateEmail(email) {
    if (
      /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    )
      return true;
    else return false;
  }

  setEmail(e: Object) {
    this.primaryEmailMessage.removeAll();
    this.email = e.target.value;
  }

  setSecondaryEmail(e: Object) {
    this.secondaryEmailMessage.removeAll();
    this.secondaryEmail = e.target.value;
  }

  setFirstName(e: Event) {
    this.messaging.removeAll();
    this.firstName = e.target.value;
  }

  setMiddleName(e: Event) {
    this.middleName = e.target.value;
  }

  setLastName(e: Event) {
    this.lastNameMessage.removeAll();
    this.lastName = e.target.value;
  }

  validatePhoneNumber(phoneNumber: string) {
    return /^[+]?([\s]?([0-9]{1,3})[-\s.]?)?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}([#][0-9]+)?$/.test(
      phoneNumber
    );
  }

  setPhoneNumber(e: Object) {
    const re = /^[()+-\s.0-9]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.phoneNumber = e.target.value;
    }
  }
  setAlterPhoneNumber(e: Object) {
    const re = /^[()+-\s.0-9]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.alterPhoneNumber = e.target.value;
    }
  }

  setAddress(e: Event) {
    this.address = e.target.value;
  }

  setApartment(e: Event) {
    this.apartment = e.target.value;
  }

  setCity(e: Event) {
    this.city = e.target.value;
  }

  setState(e: Event) {
    this.state = e.target.value;
  }

  setPostalCode(e: Event) {
    this.postalCode = e.target.value;
  }

  setCountry(e: Event) {
    this.country = e.target.value;
  }

  generatePassword() {
    this.newPassword = randPasswordStr();
  }

  async saveEditedAccountDetail() {
    this.primaryEmailMessage.removeAll();
    this.secondaryEmailMessage.removeAll();
    this.phoneMessage.removeAll();
    this.alterPhoneMessage.removeAll();
    this.lastNameMessage.removeAll();
    this.messaging.removeAll();

    let validationFlag = false;

    if (!this.firstName.trim()) {
      this.messaging.createMessage("info", "Please enter a First Name.");
      validationFlag = true;
    }

    if (!this.lastName.trim()) {
      this.lastNameMessage.createMessage("info", "Please enter a Last Name.");
      validationFlag = true;
    }

    if (!this.email.trim()) {
      this.primaryEmailMessage.createMessage("info", "Please enter a Primary Email.");
      validationFlag = true;
    }

    if (!this.validateEmail(this.email.trim())) {
      this.primaryEmailMessage.createMessage(
        "info",
        "Please enter a valid Primary Email."
      );
      validationFlag = true;
    }

    if (
      this.secondaryEmail &&
      this.secondaryEmail.trim() &&
      !this.validateEmail(this.secondaryEmail.trim())
    ) {
      this.secondaryEmailMessage.createMessage(
        "info",
        "Please enter a valid Secondary Email."
      );
      validationFlag = true;
    }

    if (this.phoneNumber?.trim() && !this.validatePhoneNumber(this.phoneNumber.trim())) {
      this.phoneMessage.createMessage("info", "Please enter a valid phone number.");
      validationFlag = true;
    }

    if (
      this.alterPhoneNumber?.trim() &&
      !this.validatePhoneNumber(this.alterPhoneNumber.trim())
    ) {
      this.alterPhoneMessage.createMessage("info", "Please enter a valid phone number.");
      validationFlag = true;
    }

    if (validationFlag) {
      return;
    }

    const variables = {
      userProfile: {
        userData: {
          firstName: this.firstName,
          middleName: this.middleName,
          lastName: this.lastName,
          email: this.email,
          secondaryEmail: this.secondaryEmail,
        },
        userProfileData: {
          phoneNumber: this.phoneNumber,
          alterPhoneNumber: this.alterPhoneNumber,
          address: this.address,
          apartment: this.apartment,
          city: this.city,
          state: this.state,
          postalCode: this.postalCode,
          country: this.country,
        },

        //active: this.active,
      },
    };

    const query = `
      mutation updateSelfProfile($userProfile:  UpdateSelfProfileInput!){
        updateSelfProfile(input : $userProfile){
          user{
            firstName
            middleName
            lastName
            userId
          }
          errors{
            __typename
            ... on InvalidFormDataError{
              message
            }
          }
        }
      }
    `;

    let res = null;
    this.network.loading = true;

    try {
      res = await this.fetchGraphQL(query, variables);
      // should hit get request
    } catch (e) {
      console.error("Error editing user detail", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    runInAction("saveEditedUserDetail--success", () => {
      this.network.loading = false;
      if (
        res.data.updateSelfProfile.errors &&
        res.data.updateSelfProfile.errors.length > 0
      ) {
        console.error(res.data.updateSelfProfile.errors[0]);
        this.getUserDetails();
        return;
      }
      this.getUserDetails();
      this.isEditing = false;
    });
  }

  async getUserDetails() {
    const query = `
                query User {
            viewer {
            user {
            firstName
            lastName
            middleName
            email
            phoneNumber
            alterPhoneNumber
            address
            apartment
            city
            state
            postalCode
            country
            allowedCountries
            secondaryEmail
            }
            }
            }

    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, null);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting User Details", e);
      if (res !== null) {
        this.network.logGraphQLError("Get User Details query", res);
      }
      return e;
    }
    return runInAction("getUserDetails--success", () => {
      //this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get User Details query", res)) {
        return {};
      }

      this.userDetail = res.data.viewer.user;
      this.firstName = this.userDetail.firstName;
      this.middleName = this.userDetail.middleName;
      this.lastName = this.userDetail.lastName;
      this.email = this.userDetail.email;
      this.secondaryEmail = this.userDetail.secondaryEmail;
      this.phoneNumber = this.userDetail.phoneNumber;
      this.alterPhoneNumber = this.userDetail.alterPhoneNumber;
      this.address = this.userDetail.address;
      this.apartment = this.userDetail.apartment;
      this.city = this.userDetail.city;
      this.state = this.userDetail.state;
      this.postalCode = this.userDetail.postalCode;
      this.country = this.userDetail.country;
      this.allowedCountries = this.userDetail.allowedCountries;
      this.allCountries = this.allowedCountries.join(", ");
    });
  }

  async resetUserPassword() {
    this.resetPasswordSuccessMsg = null;

    this.resetPasswordMessage.removeAll();
    if (!this.newPassword.trim()) {
      this.resetPasswordMessage.createMessage("info", "Please enter a New password.");
      return;
    }

    const query = `
          mutation changePasswordUser($pass: String!, $newPass: String!) {
              changePasswordUser(input : {password : $pass, newPassword: $newPass}){
                ok
                errors {
                  __typename
                  ...on InvalidCurrentPasswordError{
                    message
                  }
                  ...on SameNewPasswordError{
                    message
                  }
                  ...on InvalidPasswordError{
                    message
                  }
                }

            }
          }
            `;
    const vars = {
      pass: this.password,
      newPass: this.newPassword,
    };
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      console.error("Error in getting client ", e);
      // TODO: Handle errors properly
      console.log("err");
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }

    runInAction("resetUserPassword--success", () => {
      // var that = this;
      if (res.data.changePasswordUser.errors) {
        this.resetPasswordMessage.removeAll();
        this.resetPasswordMessage.createMessage(
          "info",
          res.data.changePasswordUser.errors[0].message
        );
        //setTimeout(function () {document.getElementById('error_msg').style.display='none'; that.resetPasswordMessage.removeAll();}, 3000);
        return;
      }
      if (res.data.changePasswordUser.ok) {
        this.resetPasswordSuccessMsg = "Your password has been changed";
        this.passwordChangeSuccess = true;
        //setTimeout(function () {document.getElementById('alert_msg').style.display='none'; that.resetPasswordSuccessMsg=""; that.password="", that.newPassword="";}, 3000);
      }
    });
  }
}
export default AccountDetailStore;
