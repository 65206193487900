import React, { useState, useCallback } from "react";

import Stack from "../../../../components/lib/Stack";
import Button from "../../../../components/lib/Button";
import Text from "../../../../components/lib/Text";
import TextArea from "../../../../components/lib/TextArea";
import PromiseButton from "../../../../components/lib/PromiseButton";
import { ButtonGroupRight } from "../../../../components/lib/ButtonGroup";

type ReviewJobDescriptionBulkUpdateFormProps = {
  defaultValue: string | null;
  onApply: (params: { jobDescription: string }) => Promise<void>;
  onCancel: () => void;
};

const ReviewJobDescriptionBulkUpdateForm = (
  props: ReviewJobDescriptionBulkUpdateFormProps
) => {
  const { defaultValue, onApply, onCancel } = props;

  const [jobDescription, setJobDescription] = useState<string>(defaultValue || "");

  const handleChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setJobDescription(event.target.value);
  }, []);

  const handleApply = useCallback(async () => {
    await onApply({ jobDescription });
  }, [jobDescription, onApply]);

  return (
    <Stack css={{ alignItems: "flex-start" }}>
      <Text>Job Description:</Text>
      <TextArea
        fill
        rows={10}
        cols={150}
        value={jobDescription}
        onChange={handleChange}
        css={{ resize: "none" }}
      />

      <ButtonGroupRight fill css={{ flexDirection: "row-reverse" }}>
        <Button size="large" onClick={onCancel}>
          Cancel
        </Button>
        <PromiseButton
          color="brand"
          size="large"
          loadingText="Apply"
          onClick={handleApply}
        >
          Apply
        </PromiseButton>
      </ButtonGroupRight>
    </Stack>
  );
};
ReviewJobDescriptionBulkUpdateForm.displayName = "ReviewJobDescriptionBulkUpdateForm";

export default ReviewJobDescriptionBulkUpdateForm;
