//@flow
import { extendObservable, action, runInAction } from "mobx";
import axios from "axios";
import NetworkState from "../../../models/NetworkState";
import ModalState from "../../../models/ModalState";
import MessageState from "../../../models/MessageState";
import UserList from "../../../models/UserList";
import type { FetchGraphQL } from "../../../App";
import type MobXStore from "../MobXStore";
import SessionInfo from "../../../models/SessionInfo";

type CountryOption = {
  value: number,
  label: string,
};

class PTAdminUserDetailPTStore {
  mobXStore: MobXStore;
  sessionInfo: ?SessionInfo;
  router: ?Object;
  network: NetworkState;
  userDetail: UserList;
  messaging: MessageState;
  firstNameMessage: MessageState;
  lastNameMessage: MessageState;
  primaryEmailMessage: MessageState;
  primaryPhoneMessage: MessageState;
  secondaryPhoneMessage: MessageState;
  clientNameMessage: MessageState;
  userId: number;
  multiCountryMessage: MessageState;
  rolesMessage: MessageState;

  username: String;
  firstName: String;
  lastName: String;
  primaryEmail: String;
  searchLimit: number;
  limitedSearchFlag: boolean;
  shareRateCards: boolean;
  activeFlag: boolean;
  welcomeEmailLastSentOn: null | String;
  jobLibraryWelcomeEmailLastSentOn: null | String;
  clientId: number;
  clientName: String;
  newClientName: String;
  secondaryEmail: String;
  primaryPhone: String;
  secondaryPhone: String;
  mainAddress: String;
  additionalAddress: String;
  city: String;
  state: String;
  country: string;
  postalCode: String;
  shareRatecardsFlag: boolean;
  allowedCountries: Array<any>;
  selectedLocations: Array<any>;
  selectedRoles: Array<any>;
  selectedClient: Array<any>;
  selected: boolean;
  middleName: String;
  changeMiddleNameText: () => void;

  showHelpModal: boolean;
  showHelp: () => void;
  hideHelp: () => void;
  getUserDetails: () => void;
  isEditing: boolean;
  stopEditing: () => void;
  startEditing: () => void;
  saveEditedUserDetail: () => void;
  setSearchLimit: () => void;
  setLimitedSearchFlag: () => void;
  setShareRatecardsFlag: () => void;
  toggleActiveUser: () => void;
  ClientText: () => void;
  changeClientText: () => void;
  setLastName: () => void;
  ActivateDeactivateUser: () => void;
  resetPasswordMessage: MessageState;
  resetUserMessage: MessageState;
  resetUserPassword: () => void;
  resetPasswordModal: ModalState;
  setPassword: () => void;
  setPasswordModal: ModalState;
  releasePassword: () => void;
  releasePasswordModal: ModalState;
  releaseUserModal: ModalState;
  confirmActivateDeactivateModal: ModalState;
  copyUserRegionsModal: ModalState;
  selectAllCountries: () => void;
  changeCountryText: () => void;
  stopCreatingUser: () => void;
  changeSecondaryEmail: () => void;
  changePrimaryPhone: () => void;
  changeSecondaryPhone: () => void;
  changeMainAddress: () => void;
  changeAdditionalAddress: () => void;
  changeCity: () => void;
  changeState: () => void;
  changeCountry: (CountryOption) => void;
  changePostalCode: () => void;
  changePrimaryEmail: () => void;
  changeLastNameText: () => void;
  showActiveModal: () => void;
  activeDeactiveBtnFlag: boolean;
  isPasswordReset: boolean;
  isPasswordSet: boolean;
  isPasswordReleased: boolean;
  isUserReleased: boolean;
  fetchGraphQL: FetchGraphQL;
  countryOptions: Array<CountryOption>;
  selectedCountryOption: ?CountryOption;
  getCountryOptions: () => Promise<void>;

  constructor(fetchGraphQL: FetchGraphQL, mobXStore: MobXStore) {
    this.fetchGraphQL = fetchGraphQL;
    this.mobXStore = mobXStore;
    this.sessionInfo = null; // should be set on componentDidMount
    this.router = null; // should be set on componentDidMount

    extendObservable(this, {
      userId: null,
      network: new NetworkState(),
      renameNetwork: new NetworkState(),
      userDetail: null,
      showHelpModal: false,
      messaging: new MessageState(),
      isEditing: false,
      searchLimit: 0,
      limitedSearchFlag: false,
      shareRateCards: false,
      welcomeEmailLastSentOn: null,
      jobLibraryWelcomeEmailLastSentOn: null,
      username: "",
      firstName: "",
      lastName: "",
      middleName: "",
      primaryEmail: "",
      secondaryEmail: "",
      primaryPhone: "",
      secondaryPhone: "",
      mainAddress: "",
      additionalAddress: "",
      allowedCountries: [],
      city: "",
      state: "",
      country: "",
      postalCode: "",
      selectedLocations: [],
      selectedRoles: [],
      selectedClient: [],
      selected: false,
      clientId: 0,
      clientName: "",
      newClientName: "",
      resetPasswordMessage: new MessageState(),
      resetUserMessage: new MessageState(),
      resetPasswordModal: new ModalState(),
      setPasswordModal: new ModalState(),
      releasePasswordModal: new ModalState(),
      releaseUserModal: new ModalState(),
      confirmActivateDeactivateModal: new ModalState(),
      copyUserRegionsModal: new ModalState(),
      firstNameMessage: new MessageState(),
      lastNameMessage: new MessageState(),
      primaryEmailMessage: new MessageState(),
      secondaryEmailMessage: new MessageState(),
      primaryPhoneMessage: new MessageState(),
      secondaryPhoneMessage: new MessageState(),
      clientNameMessage: new MessageState(),
      multiCountryMessage: new MessageState(),
      rolesMessage: new MessageState(),
      isPasswordReset: false,
      isPasswordSet: false,
      isPasswordReleased: false,
      isUserReleased: false,
      countryOptions: [],
      selectedCountryOption: null,
    });

    this.showHelp = action(this.showHelp.bind(this));
    this.hideHelp = action(this.hideHelp.bind(this));
    this.getUserDetails = action(this.getUserDetails.bind(this));
    this.stopEditing = action(this.stopEditing.bind(this));
    this.startEditing = action(this.startEditing.bind(this));
    this.saveEditedUserDetail = action(this.saveEditedUserDetail.bind(this));
    this.setLimitedSearchFlag = action(this.setLimitedSearchFlag.bind(this));
    this.setShareRatecardsFlag = action(this.setShareRatecardsFlag.bind(this));
    this.toggleActiveUser = action(this.toggleActiveUser.bind(this));
    this.ClientText = action(this.ClientText.bind(this));
    this.changeClientText = action(this.changeClientText.bind(this));
    this.ActivateDeactivateUser = action(this.ActivateDeactivateUser.bind(this));
    this.resetUserPassword = action(this.resetUserPassword.bind(this));
    this.setUserPassword = action(this.setUserPassword.bind(this));
    this.releaseUserPassword = action(this.releaseUserPassword.bind(this));
    this.releaseUser = action(this.releaseUser.bind(this));
    this.loginAsUserId = action(this.loginAsUserId.bind(this));
    this.changeCountryText = action(this.changeCountryText.bind(this));
    this.changePrimaryEmail = action(this.changePrimaryEmail.bind(this));
    this.changeUsernameText = action(this.changeUsernameText.bind(this));
    this.changeFirstNameText = action(this.changeFirstNameText.bind(this));
    this.changeLastNameText = action(this.changeLastNameText.bind(this));
    this.changeSecondaryEmail = action(this.changeSecondaryEmail.bind(this));
    this.changePrimaryPhone = action(this.changePrimaryPhone.bind(this));
    this.changeSecondaryPhone = action(this.changeSecondaryPhone.bind(this));
    this.changeMainAddress = action(this.changeMainAddress.bind(this));
    this.changeAdditionalAddress = action(this.changeAdditionalAddress.bind(this));
    this.changeCity = action(this.changeCity.bind(this));
    this.changeState = action(this.changeState.bind(this));
    this.changeCountry = action(this.changeCountry.bind(this));
    this.changePostalCode = action(this.changePostalCode.bind(this));
    this.selectAllCountries = action(this.selectAllCountries.bind(this));
    this.changeMiddleNameText = action(this.changeMiddleNameText.bind(this));
    this.showActiveModal = action(this.showActiveModal.bind(this));
    this.countryTitle = action(this.countryTitle.bind(this));
    this.getCountryOptions = action(this.getCountryOptions.bind(this));
  }

  showHelp() {
    this.showHelpModal = true;
  }

  hideHelp() {
    this.showHelpModal = false;
  }

  showActiveModal(value) {
    this.activeDeactiveBtnFlag = value;
    this.confirmActivateDeactivateModal.showModal();
  }

  stopEditing() {
    this.firstNameMessage.removeAll();
    this.lastNameMessage.removeAll();
    this.primaryEmailMessage.removeAll();
    this.secondaryEmailMessage.removeAll();
    this.clientNameMessage.removeAll();
    this.multiCountryMessage.removeAll();
    this.rolesMessage.removeAll();
    this.messaging.removeAll();

    this.primaryEmail = this.userDetail.email;
    this.shareRateCards = this.userDetail.shareRateCards;
    this.firstName = this.userDetail.firstName;
    this.middleName = this.userDetail.middleName;
    this.lastName = this.userDetail.lastName;
    this.isEditing = false;
    this.primaryPhone = this.userDetail.phoneNumber;
    this.secondaryPhone = this.userDetail.alterPhoneNumber;
    this.secondaryEmail = this.userDetail.secondaryEmail;
    this.mainAddress = this.userDetail.address;
    this.additionalAddress = this.userDetail.apartment;
    this.city = this.userDetail.city;
    this.state = this.userDetail.state;
    this.country = this.userDetail.country;
    this.postalCode = this.userDetail.postalCode;
    this.changeClientText(this.userDetail.client);
    this.changeCountryText(this.userDetail.allowedCountries);
    this.changeRoleText(this.userDetail.roles);
  }

  validateEmail(email) {
    if (
      /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    )
      return true;
    else return false;
  }

  startEditing() {
    this.isEditing = true;
  }

  changePrimaryEmail(e: Object) {
    this.primaryEmailMessage.removeAll();
    this.primaryEmail = e.target.value;
  }

  setLimitedSearchFlag(value) {
    this.limitedSearchFlag = value === "false" ? false : true;
  }

  setShareRatecardsFlag(value) {
    this.shareRateCards = value === "false" ? false : true;
  }

  changeUsernameText(e: Object) {
    this.username = e.target.value;
  }

  changeFirstNameText(e: Object) {
    this.firstNameMessage.removeAll();
    this.firstName = e.target.value;
  }

  changeMiddleNameText(e: Object) {
    this.multiCountryMessage.removeAll();
    this.middleName = e.target.value;
  }

  changeLastNameText(e: Object) {
    this.lastNameMessage.removeAll();
    this.lastName = e.target.value;
  }

  changeSecondaryEmail(e: Object) {
    this.secondaryEmailMessage.removeAll();
    this.secondaryEmail = e.target.value;
  }

  changePrimaryPhone(e: Object) {
    this.primaryPhone = e.target.value || "";
  }

  changeSecondaryPhone(e: Object) {
    this.secondaryPhone = e.target.value || "";
  }

  changeMainAddress(e: Object) {
    this.mainAddress = e.target.value;
  }

  changeAdditionalAddress(e: Object) {
    this.additionalAddress = e.target.value;
  }

  changeCity(e: Object) {
    this.city = e.target.value;
  }

  changeState(e: Object) {
    this.state = e.target.value;
  }

  changeCountry(country: CountryOption) {
    this.selectedCountryOption = country;
    this.country = country.label;
  }

  changePostalCode(e: Object) {
    this.postalCode = e.target.value;
  }

  toggleActiveUser(value) {
    this.activeFlag = value;
    this.saveEditedUserDetail();
  }

  changeClientText(client) {
    this.clientNameMessage.removeAll();
    var clie = [];
    client.forEach((cli) => {
      clie.push({
        title: cli.name,
        clientId: cli.clientId,
      });
    });
    this.selectedClient = clie;
  }

  changeCountryText(region) {
    this.multiCountryMessage.removeAll();
    var loc = [];
    if (region.length) {
      region.forEach((reg) => {
        loc.push({
          title: reg.name,
          locationId: reg.locationId,
        });
      });
      this.selectedLocations = loc;
    } else {
      this.selectedLocations = [];
    }

    if (this.allowedCountries.length === this.selectedLocations.length) {
      this.selected = true;
    } else {
      this.selected = false;
    }
  }

  selectAllCountries() {
    this.selected = !this.selected;
    if (this.selected) {
      this.multiCountryMessage.removeAll();
      this.selectedLocations = this.allowedCountries;
    } else {
      this.selectedLocations = [];
    }
  }

  changeRoleText(role) {
    this.rolesMessage.removeAll();
    var roleArr = [];
    if (role.length) {
      role.forEach((rol) => {
        roleArr.push({
          title: rol.name,
          roleId: rol.roleId,
        });
      });
      this.selectedRoles = roleArr;
    } else {
      this.selectedRoles = [];
    }
  }

  async saveEditedUserDetail() {
    this.messaging.removeAll();
    this.firstNameMessage.removeAll();
    this.lastNameMessage.removeAll();
    this.primaryEmailMessage.removeAll();
    this.secondaryEmailMessage.removeAll();
    this.primaryPhoneMessage.removeAll();
    this.secondaryPhoneMessage.removeAll();
    this.clientNameMessage.removeAll();

    let validationFlag = false;

    if (!this.selectedClient.length) {
      this.clientNameMessage.createMessage("info", "Please enter a Client Name.");
      validationFlag = true;
    }

    if (!this.firstName.trim()) {
      this.firstNameMessage.createMessage("info", "Please enter a First Name.");
      validationFlag = true;
    }

    if (!this.lastName.trim()) {
      this.lastNameMessage.createMessage("info", "Please enter a Last Name.");
      validationFlag = true;
    }

    if (!this.primaryEmail.trim()) {
      this.primaryEmailMessage.createMessage("info", "Please enter a Primary Email.");
      validationFlag = true;
    }

    if (!this.validateEmail(this.primaryEmail.trim())) {
      this.primaryEmailMessage.createMessage(
        "info",
        "Please enter a valid Primary Email."
      );
      validationFlag = true;
    }

    if (this.secondaryEmail.trim() && !this.validateEmail(this.secondaryEmail.trim())) {
      this.secondaryEmailMessage.createMessage(
        "info",
        "Please enter a valid Secondary Email."
      );
      validationFlag = true;
    }

    if (this.primaryPhone.trim().length > 30) {
      this.primaryPhoneMessage.createMessage(
        "info",
        "This field is limited to 30 characters."
      );
      validationFlag = true;
    }

    if (this.secondaryPhone.trim().length > 30) {
      this.secondaryPhoneMessage.createMessage(
        "info",
        "This field is limited to 30 characters."
      );
      validationFlag = true;
    }

    if (!this.selectedLocations.length) {
      this.multiCountryMessage.createMessage("info", "Please select Country.");
      validationFlag = true;
    }

    if (!this.selectedRoles.length) {
      this.rolesMessage.createMessage("info", "Please select Role.");
      validationFlag = true;
    }

    if (validationFlag) {
      return;
    }

    let locationIds = [];
    this.selectedLocations.forEach((item) => {
      locationIds.push(item.locationId);
    });

    let roleIds = [];
    this.selectedRoles.forEach((role) => {
      roleIds.push(role.roleId);
    });

    const variables = {
      userData: {
        username: this.username,
        firstName: this.firstName,
        middleName: this.middleName,
        lastName: this.lastName,
        shareRateCards: this.shareRateCards,
        email: this.primaryEmail,
        userId: this.userId,
        clientId: this.selectedClient[0].clientId,
        locationIds: locationIds,
        roleIds: roleIds,
        secondaryEmail: this.secondaryEmail,
        profileData: {
          address: this.mainAddress,
          apartment: this.additionalAddress,
          phoneNumber: this.primaryPhone,
          alterPhoneNumber: this.secondaryPhone,
          city: this.city,
          state: this.state,
          postalCode: this.postalCode,
          country: this.country,
        },
      },
    };

    const query = `
      mutation updateUserDetial($userData: UpdateUserDataInput!){
        updateUser(input : {userData:$userData}){
          user{
            firstName
            lastName
            email
          }
          errors{
            __typename
            ... on UserDoesNotExistsError{
              message
            }
            ... on NoPermissionError{
              message
            }
            ... on InvalidFormDataError{
              message
            }
            ... on ClientDoesNotExistsError{
              message
            }
            ... on LocationIdsNotExistsError{
              message
            }
            ... on RoleIdsNotExistsError{
              message
            }
          }
        }
      }
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
      // should hit get request
    } catch (e) {
      console.error("Error editing user detail", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }

    runInAction("saveEditedUserDetail--success", () => {
      if (res.data.updateUser.errors && res.data.updateUser.errors.length > 0) {
        this.messaging.createMessage("error", res.data.updateUser.errors[0].message);
        // this.getUserDetails();
        return;
      }
      this.getUserDetails();
      this.isEditing = false;
    });

    return {
      job: res.data.updateUser.user,
    };
  }

  async ActivateDeactivateUser(value) {
    const query = `
      mutation activateDeactivateUser{
        activateDeactivateUser(input : {userId:${this.userId}, isActive: ${value}}){
          user{
            userId
          }
        }
      }
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, null);
      // should hit get request
    } catch (e) {
      console.error("Error editing user detail", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }

    runInAction("ActivateDeactivateUser--success", () => {
      //this.getUserDetails();
      this.getUserDetails();
      this.isEditing = false;
    });

    // return {
    //   job: res.data.updateUserUser.user
    // };
  }

  async getUserDetails() {
    let res = null;
    if (!/^\d+$/.test(this.userId)) {
      if (this.router) {
        this.router.push({
          pathname: "/404NotFound",
          query: this.router.query,
        });
      }
      return res;
    }
    const variables = {
      id: parseInt(this.userId, 10),
    };
    const query = `
      query getUserDetail($id : Int!){
        viewer{
          userDetail(id:$id){
            userId
            firstName
            middleName
            lastName
            email
            secondaryEmail
            shareRateCards
            isActive
            client{
              clientId
              name
            }
            roles{
              roleId
              name
            }
            allowedCountries{
              locationId
              name
            }
            welcomeEmailLastSentOn
            jobLibraryWelcomeEmailLastSentOn
            phoneNumber
            alterPhoneNumber
            address
            apartment
            city
            state
            postalCode
            country
            username
          }
        }
      }
    `;

    this.network.loading = true;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting User Details", e);
      if (res !== null) {
        this.network.logGraphQLError("Get User Details query", res);
      }
      return e;
    }
    return runInAction("getUserDetails--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get User Details query", res)) {
        return {};
      }
      var that = this;
      if (res.data.viewer && !res.data.viewer.userDetail) {
        if (that.router) {
          that.router.push({
            pathname: "/404NotFound",
            query: that.router.query,
          });
        }
        return;
      }

      const user = res.data.viewer.userDetail;
      this.userDetail = new UserList(this, user);
      this.searchLimit = this.userDetail.searchLimit;
      this.limitedSearchFlag = this.userDetail.limitedSearchFlag;
      this.shareRateCards = this.userDetail.shareRateCards;
      this.username = this.userDetail.username;
      this.firstName = this.userDetail.firstName;
      this.middleName = this.userDetail.middleName;
      this.lastName = this.userDetail.lastName;
      this.activeFlag = this.userDetail.active;
      this.welcomeEmailLastSentOn = user.welcomeEmailLastSentOn;
      this.jobLibraryWelcomeEmailLastSentOn = user.jobLibraryWelcomeEmailLastSentOn;
      this.primaryEmail = this.userDetail.email;
      this.primaryPhone = this.userDetail.phoneNumber;
      this.secondaryPhone = this.userDetail.alterPhoneNumber;
      this.secondaryEmail = this.userDetail.secondaryEmail;
      this.mainAddress = this.userDetail.address;
      this.additionalAddress = this.userDetail.apartment;
      this.city = this.userDetail.city;
      this.state = this.userDetail.state;
      this.country = this.userDetail.country;
      this.postalCode = this.userDetail.postalCode;
      this.changeClientText(this.userDetail.client);
      this.changeCountryText(this.userDetail.allowedCountries);
      this.changeRoleText(this.userDetail.roles);
      this.isPasswordReset = false;
      this.isPasswordSet = false;
      this.isPasswordReleased = false;
      this.isUserReleased = false;
      this.selectedCountryOption = this.countryOptions.find(
        (c) => c.label === this.userDetail.country
      );

      if (this.allowedCountries.length === this.selectedLocations.length) {
        this.selected = true;
      } else {
        this.selected = false;
      }
    });
  }

  async ClientText(searchText) {
    var clientText = searchText === undefined ? "" : searchText;

    if (!this.isEditing) {
      clientText = "";
    }

    const variables = {
      searchParam: clientText,
    };

    const query = `
          query getClient{
            viewer{
              allClients(filters: {nameIContains:"${clientText}"}, order:[{direction : ASC, field: NAME}]){
                edges{
                  node{
                    clientId
                    name
                  }
                }
              }
            }
          }
            `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
      // should hit get request
    } catch (e) {
      console.error("Error in getting client ", e);
      // TODO: Handle errors properly
      console.log("err");
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);

      return;
    }

    runInAction("ClientText--success", () => {});

    return {
      clients: res.data.viewer.allClients.edges,
    };
  }

  async countryTitle(searchText) {
    var countryText = searchText === undefined ? "" : searchText;

    if (!this.isEditing) {
      countryText = "";
    }

    const variables = {
      searchParam: countryText,
    };

    /* locationTypeId
      1: Cities
      2: States
      3: Country
    */

    const query = `
      query countries{
        viewer{
          allLocations (filters: {locationTypeId: 3,nameIContains:"${countryText}"}, order: [{field: NAME, direction: ASC}], section:ADMIN) {
            edges{
              node{
                locationId
                name
              }
            }
          }
        }
      }
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
      // should hit get request
    } catch (e) {
      console.error("Error in getting country title", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }
    runInAction("countryTitle--success", () => {
      this.allowedCountries = [];
      res.data.viewer.allLocations.edges.forEach((reg) => {
        this.allowedCountries.push({
          title: reg.node.name,
          locationId: reg.node.locationId,
        });
      });

      if (this.allowedCountries.length === this.selectedLocations.length) {
        this.selected = true;
      } else {
        this.selected = false;
      }
    });
    return {
      countries: res.data.viewer.allLocations.edges,
    };
  }

  async getAllRoles(searchText) {
    var roleText = searchText === undefined ? "" : searchText;

    if (!this.isEditing) {
      roleText = "";
    }

    const variables = {
      searchParam: roleText,
    };
    const query = `
      query roles{
        viewer{
          allRoles (filters: {name_Icontains:"${roleText}"}, order: {field: NAME, direction: ASC}) {
            edges{
              node{
                name
                roleId
              }
            }
          }
        }
      }
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
      // should hit get request
    } catch (e) {
      console.error("Error in getting roles", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }
    runInAction("role--success", () => {});
    return {
      roles: res.data.viewer.allRoles.edges,
    };
  }

  async resetUserPassword() {
    this.resetPasswordMessage.removeAll();

    const query = `
          mutation resetUserPassword($input: ResetPasswordOfUserInput!) {
              resetPasswordOfUser(input: $input){
                ok
                errors{
                  __typename
                    ...on NoPermissionError{
                      message
                    }
                    ...on UserDoesNotExistsError{
                      message
                    }
                }
            }
          }
            `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, { input: { userId: this.userId } });
      // should hit get request
    } catch (e) {
      console.error("Error in getting client ", e);
      // TODO: Handle errors properly
      console.log("err");
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);

      return;
    }

    runInAction("resetUserPassword--success", () => {
      if (res.data.resetPasswordOfUser.errors) {
        this.resetPasswordMessage.removeAll();
        this.resetPasswordMessage.createMessage(
          "info",
          res.data.resetPasswordOfUser.errors[0].message
        );
        return;
      }

      if (res.data.resetPasswordOfUser.ok) {
        this.isPasswordReset = true;
      }

      //this.resetPasswordModal.hideModal();
    });
  }

  async setUserPassword() {
    this.resetPasswordMessage.removeAll();

    const query = `
          mutation setUserPassword($input: SetPasswordInput!){
              setPassowrd(input: $input){
                ok
                errors{
                  __typename
                  ...on InvalidCredentialsError{
                    message
                  }
                  ...on UserDoesNotExistsError{
                    message
                  }
                }
            }
          }
            `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, null);
      // should hit get request
    } catch (e) {
      console.error("Error in getting client ", e);
      // TODO: Handle errors properly
      console.log("err");
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);

      return;
    }

    runInAction("resetUserPassword--success", () => {
      if (res.data.setPassword.errors) {
        this.resetPasswordMessage.removeAll();
        this.resetPasswordMessage.createMessage(
          "info",
          res.data.setPassword.errors[0].message
        );
        return;
      }

      if (res.data.setPassword.ok) {
        this.isPasswordSet = true;
      }
    });
  }

  async releaseUserPassword() {
    this.resetPasswordMessage.removeAll();

    const query = `
          mutation releaseUserPassword($userId: ID!){
              releasePassword(userId: $userId){
                ok
                errors{
                  __typename
                }
            }
          }
            `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, {
        userId: this.userId,
      });
      // should hit get request
    } catch (e) {
      console.error("Error in getting client ", e);
      // TODO: Handle errors properly
      console.log("err");
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);

      return;
    }

    runInAction("releaseUserPassword--success", () => {
      if (res.data.releasePassword.errors) {
        this.resetPasswordMessage.removeAll();
        this.resetPasswordMessage.createMessage(
          "info",
          res.data.releasePassword.errors[0].message
        );
        return;
      }

      if (res.data.releasePassword.ok) {
        this.isPasswordReleased = true;
        // Reset when modal is hidden by close handler
      }
    });
  }

  async releaseUser() {
    this.resetPasswordMessage.removeAll();

    const query = `
          mutation releaseUser($userId: ID!){
              releaseUser(userId: $userId){
                ok
                errors{
                  __typename
                }
            }
          }
            `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, {
        userId: this.userId,
      });
      // should hit get request
    } catch (e) {
      console.error("Error in getting client ", e);
      // TODO: Handle errors properly
      console.log("err");
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);

      return;
    }

    runInAction("releaseUser--success", () => {
      if (res.data.releaseUser.errors) {
        this.resetPasswordMessage.removeAll();
        this.resetPasswordMessage.createMessage(
          "info",
          res.data.releaseUser.errors[0].message
        );
        return;
      }

      if (res.data.releaseUser.ok) {
        this.isUserReleased = true;
        // Reset when modal is hidden by close handler
      }
    });
  }

  async loginAsUserId(userId) {
    this.network.loading = true;
    await this.mobXStore.dashboardStore.loginAsUser(userId);
    // this.network.loading = false;
  }

  async getCountryOptions(): Promise<void> {
    const query = `
      query countries {
        viewer {
          countries {
            value: locationId
            label: name
          }
        }
      }
    `;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, {});
      // should hit get request
    } catch (e) {
      console.error("Error in getting countryOptions", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      throw res.errors;
    }

    runInAction("getCountryOptions--success", () => {
      this.countryOptions = res?.data?.viewer?.countries || [];
    });
  }
}
export default PTAdminUserDetailPTStore;
