import React, { ReactNode } from "react";
import Button from "../../../../components/lib/Button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "../../../../components/lib/Dialog";
import Inline from "../../../../components/lib/Inline";
import Stack from "../../../../components/lib/Stack";
import Text from "../../../../components/lib/Text";
import TextInput from "../../../../components/lib/TextInput";
import { styled } from "../../../../stitches.config";
import { useSearchResultsStore } from "../../../../stores/SearchResults";

interface RsRateMultiplierDialogProps {
  children: ReactNode;
}

const InputWrapper = styled(Inline, {
  gap: "$2",
  "& > div": {
    maxWidth: "85px",
  },
});

const LeftColumn = styled(Text, {
  width: "60px",
});

function RsRateMultiplierDialog(props: RsRateMultiplierDialogProps) {
  const { children } = props;

  const multipliers = useSearchResultsStore((s) => s.rateFrequencyMultipliers);
  const onChange = useSearchResultsStore((s) => s.changeRateFreqMultipliers);
  const onReset = useSearchResultsStore((s) => s.resetRateFreqMultipliers);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <span>{children}</span>
      </DialogTrigger>
      <DialogContent>
        <Stack css={{ padding: "$4", alignItems: "flex-start", gap: "$6" }}>
          <Stack nogap css={{ alignItems: "flex-start", gap: "$2" }}>
            <Text as="h3" noMargin>
              Custom Multipliers
            </Text>
            <Text color="dark">
              Change multipliers for daily, weekly, monthly, and annual rates.
            </Text>
          </Stack>
          <Stack css={{ margin: "0 auto" }}>
            <InputWrapper>
              <LeftColumn>Daily:</LeftColumn>
              <TextInput
                value={multipliers.Daily}
                type="number"
                onChange={(e) => onChange("Daily", Number(e.target.value))}
              />
            </InputWrapper>
            <InputWrapper>
              <LeftColumn>Weekly:</LeftColumn>
              <TextInput
                type="number"
                value={multipliers.Weekly}
                onChange={(e) => onChange("Weekly", Number(e.target.value))}
              />
            </InputWrapper>
            <InputWrapper>
              <LeftColumn>Monthly:</LeftColumn>
              <TextInput
                type="number"
                value={multipliers.Monthly}
                onChange={(e) => onChange("Monthly", Number(e.target.value))}
              />
            </InputWrapper>
            <InputWrapper>
              <LeftColumn>Annually:</LeftColumn>
              <TextInput
                type="number"
                value={multipliers.Annually}
                onChange={(e) => onChange("Annually", Number(e.target.value))}
              />
            </InputWrapper>
          </Stack>
          <Inline fill css={{ justifyContent: "flex-end" }}>
            <Button onClick={() => onReset()} size="small">
              Reset
            </Button>

            <DialogClose asChild>
              <Button size="small">Close</Button>
            </DialogClose>
          </Inline>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default RsRateMultiplierDialog;
