// @flow

import { extendObservable, action } from "mobx";

export default class ModalState {
  show: boolean;
  store: Object;

  showModal: () => void;
  hideModal: () => void;

  constructor(show: boolean = false) {
    extendObservable(this, {
      show: show,
      store: null,
    });

    this.showModal = action(this.showModal.bind(this));
    this.hideModal = action(this.hideModal.bind(this));
  }

  showModal() {
    this.show = true;
  }

  hideModal() {
    this.show = false;
  }
}
