import React from "react";
import { observer } from "mobx-react";
import SelectableItemsList from "../ratecards/components/SelectableItemsList";
import SelectableItem from "../ratecards/components/SelectableItem";
import ContainerSection from "../ratecards/components/ContainerSection";
import FilterModal from "../ratecards/components/FilterModal";
import LoadingIndicator from "../shared/LoadingIndicator";
import SortControls from "../ratecards/components/SortControls";
import { CONTENT_TYPE } from "../../models/Filter";

const TagContentCriteriaList = observer((props) => (
  <div>
    {props.filterState.viewItems.length !== 0 && (
      <SelectableItemsList className="overlap-tb-padding" listState={props.filterState}>
        {props.filterState.viewItems.map((item, i) => (
          <SelectableItem
            key={item.id}
            item={item}
            name="location-filter"
            value={item.value}
            selected={item.selected}
            onChange={props.filterState.setSelectedValue}
          >
            <div>{CONTENT_TYPE[item.value]}</div>
          </SelectableItem>
        ))}
      </SelectableItemsList>
    )}
    {props.filterState.viewItems.length === 0 && (
      <SelectableItemsList className="overlap-tb-padding" listState={props.filterState}>
        <h4 className="text-center">No Content Available</h4>
      </SelectableItemsList>
    )}
  </div>
));

const TagContentFilter = ({ tagsFilter, network }) => {
  return (
    <FilterModal filterState={tagsFilter}>
      {network && <LoadingIndicator />}
      {!network && (
        <div>
          <ContainerSection className="header overlap-t-padding">
            <h4 className="pull-left">Type</h4>
            <div className="pull-right">
              <SortControls filterState={tagsFilter} />
            </div>
            <div className="clearfix" />
          </ContainerSection>
          <ContainerSection className="no-border">
            <div>
              <div className="pull-right rc-center" style={{ height: 40 }}>
                <button className="btn" onClick={tagsFilter.onSelectAll}>
                  Select All
                </button>
                <button className="btn" onClick={tagsFilter.onDeselectAll}>
                  Deselect All
                </button>
              </div>
              <div className="clearfix" />
            </div>
          </ContainerSection>
          <TagContentCriteriaList filterState={tagsFilter} />
        </div>
      )}
    </FilterModal>
  );
};

export default TagContentFilter;
