// @flow

import R from "ramda";
import { extendObservable, computed } from "mobx";
import {
  EXP_LEVELS,
  EXP_LEVELS_ROMAN,
  PACK_STATUSES,
  getJobDescriptionErrors,
  getJobTitleErrors,
} from "../../constants/ptStore";
import type { PackStatusType, PackCategoryType } from "../../constants/ptStore";

export const LEVEL_KEYS_MAPPING = {
  Junior: "Junior",
  Intermediate: "Mid",
  Senior: "Senior",
  Lead: "Lead",
  Guru: "Guru",
};

// Stored search result data from Packr
export class SearchResult {
  store: Object;

  id: number;
  searchResultId: number;
  level: number;
  levelName: string;
  datasetLength: number;

  billRateMin: number;
  billRateMax: number;
  billRateAvg: number;
  billRateMid: number;

  payRateMin: number;
  payRateMax: number;
  payRateAvg: number;
  payRateMid: number;

  markupPctMin: number;
  markupPctMax: number;
  markupPctAvg: number;
  markupPctMid: number;

  markupAmtMin: number;
  markupAmtMax: number;
  markupAmtAvg: number;
  markupAmtMid: number;

  // XXX hack to reuse results cards UI
  change: Object;

  constructor(store: Object, object: Object) {
    this.store = store;

    this.id = parseInt(object["id"], 10);
    this.searchResultId = parseInt(object["search_result_id"], 10);
    this.level = parseInt(object["level"], 10);
    this.levelName = LEVEL_KEYS_MAPPING[EXP_LEVELS.getLabel(this.level)];

    this.datasetLength = parseInt(object["dataset_length"], 10);
    this.billRateMin = parseFloat(object["bill_rate_min"]);
    this.billRateMax = parseFloat(object["bill_rate_max"]);
    this.billRateAvg = parseFloat(object["bill_rate_avg"]);
    this.billRateMid = parseFloat(object["bill_rate_mid"]);
    this.payRateMin = parseFloat(object["pay_rate_min"]);
    this.payRateMax = parseFloat(object["pay_rate_max"]);
    this.payRateAvg = parseFloat(object["pay_rate_avg"]);
    this.payRateMid = parseFloat(object["pay_rate_mid"]);
    this.markupPctMin = parseFloat(object["markup_pct_min"]);
    this.markupPctMax = parseFloat(object["markup_pct_max"]);
    this.markupPctAvg = parseFloat(object["markup_pct_avg"]);
    this.markupPctMid = parseFloat(object["markup_pct_mid"]);
    this.markupAmtMin = parseFloat(object["markup_amt_min"]);
    this.markupAmtMax = parseFloat(object["markup_amt_max"]);
    this.markupAmtAvg = parseFloat(object["markup_amt_avg"]);
    this.markupAmtMid = parseFloat(object["markup_amt_mid"]);

    // XXX hack to reuse results cards UI
    this.change = {};
  }
}

// stored search data from Packr
export class Job {
  store: Object;

  id: number;
  searchId: number;
  rateType: number;
  title: string;
  titleErrors: string[];
  titleHasErrors: boolean;
  description: string;
  descriptionErrors: string[];
  descriptionHasErrors: boolean;
  industryId: number;
  industryName: string;
  country: string;
  state: string;
  city: string;
  countriesString: string;
  statesString: string;
  citiesString: string;
  fullLocationString: string;
  currencyName: string;
  currencyCode: string;
  currencySymbol: string;
  results: SearchResult[];
  expLevelsString: string;

  selected: boolean;

  constructor(store: Object, object: Object, editable: boolean = true) {
    this.store = store;

    this.id = parseInt(object["id"], 10);
    this.searchId = parseInt(object["search_id"], 10);
    this.rateType = parseInt(object["rate_type"], 10);
    this.industryId = parseInt(object["industry_id"], 10);
    this.industryName = object["industry_name"];
    this.country = object["country"];
    this.state = object["state"];
    this.city = object["city"];
    this.countriesString = object["countries_string"];
    this.statesString = object["states_string"];
    this.citiesString = object["cities_string"];
    this.fullLocationString = [this.citiesString, this.statesString, this.countriesString]
      .filter((i) => i && i)
      .join(", ");
    this.currencyName = object["currency_name"];
    this.currencyCode = object["currency_code"];
    this.currencySymbol = object["currency_symbol"];
    this.results = (object.results || [])
      .sort((a, b) => (a.level === b.level ? 0 : a.level < b.level ? -1 : 1))
      .map((object) => new SearchResult(store, object));
    this.expLevelsString =
      this.results.length === 5
        ? "All"
        : this.results.map((r) => EXP_LEVELS_ROMAN.getLabel(r.level)).join(", ");

    if (editable) {
      extendObservable(this, this.getEditableAttributesObject(store, object));
    } else {
      Object.assign(this, this.getEditableAttributesObject(store, object));
    }
  }

  getEditableAttributesObject(store: Object, object: Object): Object {
    return {
      title: object["title"],
      titleErrors: computed(() => getJobTitleErrors(this.title)),
      titleHasErrors: computed(() => !!(this.titleErrors && this.titleErrors.length)),
      description: object["description"],
      descriptionErrors: computed(() => getJobDescriptionErrors(this.description)),
      descriptionHasErrors: computed(
        () => !!(this.descriptionErrors && this.descriptionErrors.length)
      ),
      selected: false,
    };
  }

  toggleSelected = () => {
    this.selected = !this.selected;
  };

  makeSelected = () => {
    if (!this.selected) this.selected = true;
  };

  makeNotSelected = () => {
    if (this.selected) this.selected = false;
  };
}

// stored pack data from Packr
export class Pack {
  store: Object;

  id: number;
  created: Date;
  updated: Date;
  rateType: number;
  rateCardId: number;
  rateCardName: string;
  jobsNum: number;
  locationsNum: ?number;
  status: PackStatusType;
  isProcessingRequired: boolean;
  isPublishingRequired: boolean;
  isDataCleanupRequired: boolean;
  isActive: boolean;
  title: string;
  description: string;
  category: PackCategoryType;
  categoryId: number;
  categoryTitle: string;
  fileUrl: ?string;
  imageUrl: ?string;
  pdfCoverImageUrl: ?string;
  currentPrice: number;
  freshPrice: number;
  priceReductionInterval: number;
  priceReductionPerc: number;
  priceReductionTimestamp: Object;
  nextPriceReductionDate: Object;
  refreshInterval: number;
  refreshTimestamp: Object;
  nextRefreshDate: Object;
  country: ?string;
  state: ?string;
  city: ?string;
  primaryFunctions: ?string;
  jobs: Job[];
  selectedJobs: Job[];
  selectedJobsIds: number[];
  jobsHasErrors: boolean;

  isProcessing: boolean;
  isPublishing: boolean;

  // selected: boolean;

  constructor(store: Object, object: Object, editable: boolean = true) {
    this.store = store;

    this.id = parseInt(object["id"], 10);
    this.created = new Date(object["created"]);
    this.rateType = parseInt(object["rate_type"], 10);
    this.rateCardId = parseInt(object["ratecard_id"], 10);
    this.rateCardName = object["ratecard_name"];

    if (editable) {
      extendObservable(this, this.getEditableAttributesObject(object, editable));
    } else {
      Object.assign(this, this.getEditableAttributesObject(object, editable));
    }
  }

  getEditableAttributesObject(object: Object, editable: boolean): Object {
    return {
      title: object["title"],
      description: object["description"],
      category: {
        id: parseInt(object["category_id"], 10),
        title: object["category_name"],
      },
      categoryId: computed(() => (this.category ? this.category.id : null)),
      categoryTitle: computed(() => (this.category ? this.category.title : null)),
      currentPrice: parseFloat(object["current_price"]),
      freshPrice: parseFloat(object["fresh_price"]),
      priceReductionPerc: parseFloat(object["price_reduction_perc"]),
      priceReductionInterval: parseInt(object["price_reduction_interval"], 10),
      priceReductionTimestamp: !R.isNil(object["price_reduction_timestamp"])
        ? new Date(object["price_reduction_timestamp"])
        : null,
      nextPriceReductionDate: object["next_price_reduction_date"],
      refreshInterval: parseInt(object["refresh_interval"], 10),
      refreshTimestamp: !R.isNil(object["refresh_timestamp"])
        ? new Date(object["refresh_timestamp"])
        : null,
      nextRefreshDate: object["next_refresh_date"],
      country: object["country"],
      state: object["state"],
      city: object["city"],
      primaryFunctions: object["primary_functions"],
      jobs: (object["jobs"] || [])
        .sort((a, b) => (a.title === b.title ? 0 : a.title < b.title ? -1 : 1))
        .map((object) => new Job(this.store, object, editable)),
      jobsNum: parseInt(object["jobs_num"], 10),
      locationsNum: !R.isNil(object["locations_num"])
        ? parseInt(object["locations_num"], 10)
        : null,
      selectedJobs: computed(() => this.jobs.filter((j) => j.selected)),
      selectedJobsIds: computed(() => this.selectedJobs.map((j) => j.id)),
      updated: !R.isNil(object["updated"]) ? new Date(object["updated"]) : null,
      jobsHasErrors: computed(
        () =>
          !!(
            this.jobs &&
            this.jobs.length &&
            this.jobs.reduce(
              (acc, job) => acc || job.titleHasErrors || job.descriptionHasErrors,
              false
            )
          )
      ),
      isActive: !!object["is_active"],
      status: parseInt(object["status"], 10),
      isProcessing: computed(() => this.status === PACK_STATUSES.PROCESSING),
      isPublishing: computed(() => this.status === PACK_STATUSES.PUBLISHING),
      isProcessingRequired: object["is_processing_required"],
      isPublishingRequired: object["is_publishing_required"],
      isDataCleanupRequired: object["is_data_cleanup_required"],
      fileUrl: object["file_url"],
      imageUrl: object["image_url"],
      pdfCoverImageUrl: object["pdf_cover_image_url"],
    };
  }

  setUpDetailsStore = () => {
    this.store.globalStore.packDetailsStore.switchToNewPack(this);
  };

  selectAllJobs = () => {
    this.jobs.forEach((j) => j.makeSelected());
  };

  deselectAllJobs = () => {
    this.jobs.forEach((j) => j.makeNotSelected());
  };
}
