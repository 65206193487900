import React from "react";

import Table, { useTableSchemaState, TableProps } from "./Table";
import { emptyMap } from "./constants";
import TableImpl from "./impl/TableImpl";
import { TableBody } from "./parts/TableBody";
import { TableGroups } from "./parts/TableGroups";
import { TableHeadSortable } from "./parts/TableHead";
import {
  OrderConfigMap,
  OrderDirections,
  SchemaConfigObject,
  SortableTableDataObject,
  TableData,
} from "./types";

export const applyOrder = (
  nextOrder: OrderConfigMap,
  data: TableData,
  schema: SchemaConfigObject
) => {
  if (nextOrder === emptyMap) return data;

  const column = schema.columns.find((item) => item.uniqueKey === nextOrder.get("key"));
  const getter = column?.getter;
  const comparator = column?.comparators?.[nextOrder.get("direction")];

  if (getter == null || comparator == null) return data;

  return data.sort((itemA, itemB) =>
    comparator(getter(itemA, itemA?.get("idx")), getter(itemB, itemB?.get("idx")))
  );
};

interface TableSortableProps extends TableProps {
  order: OrderConfigMap;
  onApplyOrder?: (orderKey: string) => void;
  onChangeData?: (dataState: SortableTableDataObject) => void;
}

const useTableHandlers = (tableProps: TableSortableProps) => {
  const { schema, data, order, onChangeData, onApplyOrder } = tableProps;

  // handlers

  const handleApplyOrder = React.useCallback(
    (key) => {
      let nextOrder = order;

      if (nextOrder && nextOrder.get("key") === key) {
        if (nextOrder.get("direction") === OrderDirections.DSC) {
          nextOrder = emptyMap as OrderConfigMap;
        } else {
          nextOrder = nextOrder.set("direction", OrderDirections.DSC);
        }
      } else {
        nextOrder = nextOrder.set("key", key).set("direction", OrderDirections.ASC);
      }

      const nextData = applyOrder(nextOrder, data, schema);
      const nextDataState = { data: nextData, order: nextOrder };

      if (onChangeData) onChangeData(nextDataState);
      if (onApplyOrder) onApplyOrder(key);
    },
    [data, order, schema, onApplyOrder, onChangeData]
  );

  return { handleApplyOrder };
};

export const useTableState = (tableProps: TableSortableProps) => {
  const { data, order, children } = tableProps;

  // state
  const [schemaState] = useTableSchemaState(children);
  const [tableState, setTableState] = React.useState({ data, order });

  // effects
  const prevDataRef = React.useRef(data);
  React.useEffect(() => {
    if (data !== prevDataRef.current) {
      setTableState((prevState) => ({
        ...prevState,
        data: applyOrder(prevState.order, data, schemaState),
      }));
      prevDataRef.current = data;
    }
  }, [data, schemaState]);

  // handlers
  const { handleApplyOrder } = useTableHandlers(tableProps);

  return { tableState, setTableState, schemaState, handleApplyOrder };
};

export const TableSortable = (props: TableSortableProps) => {
  const {
    schema,
    data,
    order,
    highlighted = false,
    //
    selectedRowId,
    selectedCellId,
    rowIdGetter,
    onRowClick,
    onCellClick,
    //
    bodyEmptyText,
  } = props;

  // handlers
  const { handleApplyOrder } = useTableHandlers(props);

  const tableGroups = <TableGroups groups={schema.groups} columns={schema.columns} />;

  const tableHead = (
    <TableHeadSortable schema={schema} order={order} onApplyOrder={handleApplyOrder} />
  );

  const tableBody = (
    <TableBody
      schema={schema}
      data={data}
      bodyEmptyText={bodyEmptyText}
      selectedRowId={selectedRowId}
      selectedCellId={selectedCellId}
      rowIdGetter={rowIdGetter!}
      onRowClick={onRowClick}
      onCellClick={onCellClick}
    />
  );

  return (
    <TableImpl
      groups={tableGroups}
      head={tableHead}
      body={tableBody}
      highlighted={highlighted}
    />
  );
};
TableSortable.displayName = "TableSortable";
TableSortable.defaultProps = {
  ...Table.defaultProps,
  order: emptyMap,
};

export const TableSortableStateful = (props: TableSortableProps) => {
  const { tableState, schemaState, handleApplyOrder } = useTableState(props);

  return (
    <TableSortable
      {...props}
      {...tableState}
      schema={schemaState}
      onApplyOrder={handleApplyOrder}
    />
  );
};
TableSortableStateful.displayName = "TableSortableStateful";
TableSortableStateful.defaultProp = TableSortable.defaultProps;

export default TableSortable;
