// @flow

import { extendObservable, action } from "mobx";
import FilterObject, {
  FilterWithSort,
  ValueSetFilter,
  FilterCriteriaLoader,
  IViewItem,
} from "../Filter";
import type { FilterColumn } from "../Filter";
import { GraphQLQuery } from "../GraphQL";
import Sort from "../Sort";
import SortState from "../SortState";

export class Industry implements IViewItem {
  id: string;
  industry: string;
  selected: boolean;

  constructor(object: Object) {
    this.id = object.id;
    this.industry = object.name;

    extendObservable(this, {
      selected: object.selected,
    });
  }
}

export default class IndustryFilter extends FilterCriteriaLoader(
  ValueSetFilter(FilterWithSort)
) {
  constructor(
    store: Object,
    column: FilterColumn,
    filterCriteriaQuery: (FilterColumn) => GraphQLQuery,
    processPayload: (FilterColumn, Object) => ?Array<Object>,
    applyFilter: (FilterColumn, FilterObject) => void,
    applySort: (FilterColumn, Sort) => void,
    removeFilter: (FilterColumn) => void,
    removeSort: (FilterColumn) => void,
    reloadCriteria: boolean = false
  ) {
    super(store, column);

    this.filterCriteriaQuery = filterCriteriaQuery;
    this.processPayload = processPayload;
    this.applyFilter = applyFilter;
    this.applySort = applySort;
    this.removeFilter = removeFilter;
    this.removeSort = removeSort;

    this.sortState = new SortState("INDUSTRY_ID");

    this.reloadCriteria = reloadCriteria;

    this.viewItemClass = Industry;

    // $FlowFixMe: Need to type these properties as invariant
    this.onInstantSearch = action(this.onInstantSearch.bind(this));
    // $FlowFixMe: Need to type these properties as invariant
    this.buildQueryFilter = action(this.buildQueryFilter.bind(this));
  }

  onInstantSearch(value: string) {
    if (super.onInstantSearch) super.onInstantSearch(value);

    if (!this.instantSearchValue) {
      this.viewItems = this.unfilteredViewItems;
      return;
    }

    this.viewItems = this.unfilteredViewItems.filter((item) => {
      const industry = item.industry.toLowerCase();
      const query = this.instantSearchValue.toLowerCase();

      // this works because ~ is the binary inverse of a number and ~ -1 = 0
      return ~industry.indexOf(query);
    });
  }

  buildQueryFilter() {
    const industries = this.selectedValues
      .entries()
      .filter((entry) => entry[1] === true)
      .map((entry) => this.criteria.get(entry[0]).id);

    if (!industries.length) {
      return null;
    }

    let params = [];
    let args = [];
    let vars = {};

    params.push("$industries: [ID]!");
    args.push("industries: $industries");
    vars.industries = industries;

    return new FilterObject(params.join(", "), args.join(", "), vars);
  }
}
