// @flow

import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBox from "../ratecards/components/SearchBox";
import JobsList from "./JobsList";
import Modal from "../ratecards/components/Modal";
import ApplyTagFilterModal from "../shared/modals/ApplyTagFilterModal";
import ContainerSection from "../ratecards/components/ContainerSection";

import Button from "../../components/lib/Button";
import LinkButton from "../../components/lib/LinkButton";
import Container from "../../components/lib/Container";
import {
  Card,
  CardActions,
  CardActionsLeft,
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubTitle,
  CardActionsRight,
} from "../../components/lib/Card";
import EditActions from "../../components/lib/EditActions";
import Inline from "../../components/lib/Inline";
import TextInput from "../../components/lib/TextInput";

/**
 * List component for job list page
 */
class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exportData: "",
    };
    this.export = this.export.bind(this);
  }

  componentDidMount() {
    this.props.store.jobLabelListStore.pagination.goFetch();

    // reset to null so some elements visible while editing start hidden
    if (!this.props.store.jobLabelListStore.isTagView) {
      this.props.store.jobLabelListStore.isEditing = null;
    }
  }

  componentWillUnmount() {
    this.props.store.jobLabelListStore.clearFilters();
    this.props.store.jobLabelListStore.clearAllSelections();
    this.props.store.jobLabelListStore.applyDefaultSort();
  }
  /**
   * export - export job_labels as csv
   * @return {void}
   */
  export(e) {
    this.props.store.jobLabelListStore.export();
  }

  render() {
    const store = this.props.store.jobLabelListStore;

    return (
      <Container
        css={{
          paddingTop: "$4",
          paddingLeft: 0,
          paddingRight: 0,
          width: "100%",
        }}
      >
        <Card css={{ zIndex: 2 }}>
          {!store.isTagView && (
            <CardHeader>
              <CardHeaderTitle>Job Labels</CardHeaderTitle>
              <CardHeaderSubTitle>
                Create, view, and manage job labels in your account or those shared to
                you.
              </CardHeaderSubTitle>
            </CardHeader>
          )}
          <CardActions className="header-action-bar marginTopzero">
            <CardActionsLeft>
              <SearchBox
                value={store.jobFilter.textToLookFor}
                onChange={store.jobFilter.onTextToLookForChange}
                onSearch={store.jobFilter.onApply}
                style={{
                  width: "260px",
                }}
              />
            </CardActionsLeft>
            <CardActionsRight>
              {store.isEditing && !store.isTagView && (
                <Button color="accent" onClick={store.handleStopEdit}>
                  Stop Editing
                </Button>
              )}
              {!store.isEditing && !store.isTagView && (
                <>
                  <Button color="brand" icon="edit" onClick={store.handleStartEdit}>
                    Edit
                  </Button>
                  <LinkButton color="brand" icon="plus" to="/job-labels/create">
                    Create Job
                  </LinkButton>
                  <Button icon="question-circle" onClick={store.helpModal.showModal}>
                    Help
                  </Button>
                </>
              )}
            </CardActionsRight>
          </CardActions>
          <JobsList store={store} />
        </Card>
        {!store.isTagView && (
          <EditActions hidden={store.isEditing === null} show={Boolean(store.isEditing)}>
            <Button
              icon="edit"
              disabled={store.selectedCount !== 1}
              onClick={store.showRenameModal}
            >
              Rename
            </Button>
            {store.canExport() && (
              <Button
                icon="download"
                disabled={store.selectedCount === 0}
                onClick={store.exportJobs}
              >
                Export
              </Button>
            )}
            <Button
              disabled={store.selectedCount === 0}
              onClick={store.applyTagState.showTagModal}
            >
              Apply Tag
            </Button>
            <Button
              color="danger"
              icon="trash-alt"
              disabled={store.selectedCount === 0}
              onClick={store.confirmDeleteModal.showModal}
            >
              Delete
            </Button>
          </EditActions>
        )}
        <Modal show={store.helpModal.show} onHide={store.helpModal.hideModal}>
          <div className="container-section header">
            <h4>Help</h4>
          </div>
          <div className="container-section footer">
            <p>Here you can see all of your Jobs...</p>
          </div>
        </Modal>
        <Modal
          show={store.confirmDeleteModal.show}
          onHide={store.confirmDeleteModal.hideModal}
        >
          <div className="container-section header">
            <h4>Confirmation Needed</h4>
          </div>
          <div className="container-section">
            <p>Are you sure you want to delete the selected Jobs?</p>
            <p className="text-danger">
              <FontAwesomeIcon icon="exclamation-triangle" />{" "}
              <em>This action cannot be undone!</em>
            </p>
          </div>
          <div className="container-section footer">
            <Inline css={{ gap: "$2", justifyContent: "flex-end" }}>
              <Button size="large" onClick={store.confirmDeleteModal.hideModal}>
                No, Cancel
              </Button>
              <Button
                color="danger"
                size="large"
                onClick={(e) => {
                  store.confirmDeleteModal.hideModal();
                  store.deleteJob();
                }}
              >
                Yes, Delete Jobs
              </Button>
            </Inline>
          </div>
        </Modal>
        <Modal show={store.renameModal.show} onHide={store.renameModal.hideModal}>
          <ContainerSection className="header">
            <h4>Rename Job</h4>
          </ContainerSection>
          <ContainerSection>
            <p>Name of the Job:</p>
            <TextInput
              fill
              value={store.newJobName}
              maxLength="250"
              onChange={store.onNewJobNameChange}
            />
            <div className="pt-user-messages">
              {store.messaging.messages.length > 0 && (
                <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                  <a
                    href="#close"
                    className="close"
                    data-dismiss="alert"
                    aria-label="close"
                    onClick={store.messaging.removeMessage.bind(
                      store,
                      store.messaging.messages[0]
                    )}
                  >
                    &times;
                  </a>
                  <strong>{store.messaging.messages[0].messageLabel}</strong>
                  {store.messaging.messages[0].message}
                </div>
              )}
            </div>
          </ContainerSection>
          <ContainerSection className="footer">
            <Inline css={{ gap: "$2", justifyContent: "flex-end" }}>
              <Button size="large" onClick={store.renameModal.hideModal}>
                Cancel
              </Button>
              <Button size="large" color="brand" onClick={store.renameJob}>
                Rename Job
              </Button>
            </Inline>
          </ContainerSection>
        </Modal>
        <ApplyTagFilterModal
          filterState={store.applyTagState}
          applyTagsEvent={store.applyTags}
          btnText="Apply Tag to Jobs"
        />
      </Container>
    );
  }
}

List.propTypes = {
  store: PropTypes.object.isRequired,
};

export default observer(List);
