import React from "react";
import ContainerSection from "../ratecards/components/ContainerSection";
import FilterModal, { ModalTitle } from "../ratecards/components/FilterModal";

const DraftFilter = ({ draftFilter, draftList }) => {
  return (
    <FilterModal filterState={draftFilter}>
      <div>
        <ContainerSection className="header overlap-t-padding">
          <ModalTitle className="pull-left">Draft</ModalTitle>
          <div className="pull-right" />
          <div className="clearfix" />
        </ContainerSection>
        <ContainerSection>
          <p>Filter by a specific Draft State:</p>
          {draftList}
        </ContainerSection>
      </div>
    </FilterModal>
  );
};

export default DraftFilter;
