import React from "react";

import Stack from "../../../../components/lib/Stack";
import Text from "../../../../components/lib/Text";

type StatusMessagerBlockProps = {
  hasUncommittedChanges?: boolean;
  hasMissingRatesFeedback?: boolean;
  hasMissingQuestionsFeedback?: boolean;
};

export default function StatusMessagerBlock(props: StatusMessagerBlockProps) {
  const {
    hasUncommittedChanges = false,
    hasMissingRatesFeedback = false,
    hasMissingQuestionsFeedback = false,
  } = props;

  return (
    <Stack fill css={{ alignItems: "center" }}>
      {hasUncommittedChanges ? (
        <Text noMargin as="h5" color="negative">
          Please don't forget to save changes.
        </Text>
      ) : hasMissingRatesFeedback && hasMissingQuestionsFeedback ? (
        <Text noMargin as="h5" color="negative">
          Please validate all rates above, and answer all required questions marked with
          *.
        </Text>
      ) : hasMissingRatesFeedback ? (
        <Text noMargin as="h5" color="negative">
          Please finish up rates validation.
        </Text>
      ) : hasMissingQuestionsFeedback ? (
        <Text noMargin as="h5" color="negative">
          Please answer all required questions marked with *.
        </Text>
      ) : (
        <Text noMargin as="h5" color="positive">
          You are good to submit your feedback.
        </Text>
      )}
    </Stack>
  );
}
