import React from "react";
import { formatRate } from "../../utils/MoneyUtils";
import classNames from "classnames";

export default class PayRateBarChart extends React.Component {
  render() {
    let { data, jobRateType, rate } = this.props,
      { symbol } = data.currency;
    let barMarksClasses,
      barGraphLabels,
      greenBarStyle,
      rateStyle = {},
      arrowStyle,
      multiplier = 1, // Adjust the bound
      barPartNumber = 8,
      barPartBaseStep = 25;

    const rateRange = data.max - data.min,
      minRate = data.min,
      maxRate = data.max;

    if (jobRateType === 1) {
      // Hourly
      multiplier = 1;
      barPartNumber = 7; // to change this number, also modify the barMarksClasses to get correct markers
      barPartBaseStep = 25;
      barMarksClasses = "bar-slider-marks-hourly";
    } else {
      // Annually
      multiplier = 1;
      barPartNumber = 10; // to change part number, also modify the barMarksClasses to get correct markers
      barPartBaseStep = 25000;
      barMarksClasses = "bar-slider-marks-annual";
    }

    let maxBound = multiplier * barPartNumber * barPartBaseStep;
    // Ensure the max rate is in bar range
    if (maxBound < maxRate) {
      const multiple = maxRate / maxBound;
      multiplier = Math.ceil(multiple);
      maxBound = multiplier * barPartNumber * barPartBaseStep;
    }

    // Ensure the rate range takes up less than 55% of the bar
    if (rateRange / maxBound >= 0.55) {
      multiplier *= 2;
      maxBound = multiplier * barPartNumber * barPartBaseStep;
    }

    let sq = (x) => x * barPartBaseStep * multiplier;
    // Annual
    if (jobRateType === 2) {
      sq = (x) => (x * barPartBaseStep * multiplier) / 1000;
    }
    barGraphLabels = Array.from(Array(barPartNumber + 1), (_, x) => sq(x));
    greenBarStyle = {
      width: (rateRange / maxBound) * 100 + "%",
      margin: "0 0 0 " + (minRate / maxBound) * 100 + "%",
    };

    arrowStyle = {
      marginLeft: "calc(" + (maxRate / maxBound) * 100 + "% - 10px)",
    };

    return (
      <div className={classNames("bar-slider", this.props.className)}>
        <h3 className="bar-slider-header" data-testid="pay-rate-range">
          {jobRateType === 1 ? "Contract" : "FTE"} Pay Rate Range <br />
          <small>
            {formatRate(jobRateType, data.min, symbol)} -{" "}
            {formatRate(jobRateType, data.max, symbol)}
          </small>
        </h3>
        <div className="bar-slider-base">
          <ul className={barMarksClasses}>
            {barGraphLabels.map(function (i) {
              return (
                <li key={i.toString()}>
                  <div className="bar-slider-marks" />
                  <div>
                    {i}
                    {jobRateType === 2 && symbol === " $ " ? "k" : null}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="bar-slider-green" style={greenBarStyle} />
        {rate ? (
          <div className="bar-slider-arrow" style={arrowStyle}>
            <div className="bar-slider-your-rate" style={rateStyle}>
              Your Rate {formatRate(jobRateType, rate, symbol)}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
