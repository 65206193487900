import React from "react";
import { observer } from "mobx-react";
import Modal from "../ratecards/components/Modal";
import ContainerSection from "../ratecards/components/ContainerSection";

function JobTitleInfoModal(props) {
  return (
    <Modal show={props.modalState.show} onHide={props.modalState.hideModal}>
      <div className="rc-viewport overlap-lr-padding overlap-tb-padding">
        <ContainerSection className="header">
          <h4>Job Title / Keywords Search</h4>
        </ContainerSection>
        <ContainerSection>
          Example:
          <br />
          <strong>Job Title: </strong>Software Engineer (SE)
          <br />
          or
          <br />
          <strong>Keywords: </strong>Python, Django, PostgreSQL
          {props.clientIsClientJobLibrary && (
            <div>
              <hr />
              <strong>What do the globes signify?</strong>
              <br />
              <p style={{ marginTop: "5px" }}>
                <span
                  className="pt-ui tag tag-red"
                  style={{ marginLeft: 0, width: "55px" }}
                >
                  <span className="glyphicon glyphicon-globe" />
                  &nbsp;#
                </span>{" "}
                The title can be searched in the displayed number of countries but the
                search is an ad-hoc search title match which was selected by the user and
                not a PeopleTicker rate analyst.
              </p>
              <p>
                <span
                  className="pt-ui tag tag-green"
                  style={{ marginLeft: 0, width: "55px" }}
                >
                  <span className="glyphicon glyphicon-globe" />
                  &nbsp;#
                </span>{" "}
                The title can be searched in the displayed number of countries and the
                title has been certified by a rate analyst for best match.
              </p>
            </div>
          )}
          <hr />
          Matches are ranked by keyword relevance.
        </ContainerSection>
        <ContainerSection className="footer">
          <div className="pull-right">
            <button className="btn btn-lg btn-green" onClick={props.modalState.hideModal}>
              Ok
            </button>
          </div>
        </ContainerSection>
      </div>
    </Modal>
  );
}

export default observer(JobTitleInfoModal);
