import React from "react";
// Lib
import Inline from "../../../../components/lib/Inline";
import Text from "../../../../components/lib/Text";
import { Placeholder } from "../../../../components/lib/Placeholder";
import Stack from "../../../../components/lib/Stack";

export function PayChartLoader() {
  return (
    <Stack fill css={{ gap: "$4" }}>
      <Stack nogap fill css={{ alignItems: "flex-start" }}>
        <Text noMargin as="h4">
          Market Rates
        </Text>
        <Text color="primary">
          The data presented on these charts is across all experience levels.
        </Text>
      </Stack>
      <Inline fill css={{ gap: "0", justifyContent: "space-between" }}>
        <Inline fill css={{ gap: "$6", justifyContent: "space-between" }}>
          <Placeholder height={156} css={{ flex: 1 }} />
          <Placeholder height={156} css={{ flex: 1 }} />
          <Placeholder height={156} css={{ flex: 1 }} />
        </Inline>
      </Inline>
    </Stack>
  );
}
