import React from "react";
import ContainerSection from "../ratecards/components/ContainerSection";
import FilterModal from "../ratecards/components/FilterModal";
import SortControls from "../ratecards/components/SortControls";

const MarkUpOnFilter = ({ markUpOnFilter, fromMarkUp, toMarkUp }) => {
  return (
    <FilterModal filterState={markUpOnFilter}>
      <div>
        <ContainerSection className="header overlap-t-padding">
          <h4 className="pull-left">Markup Amount</h4>
          <div className="pull-right">
            <SortControls
              filterState={markUpOnFilter}
              sortType="numeric"
              ascText="Sort Smallest First"
              descText="Sort Largest First"
            />
          </div>
          <div className="clearfix" />
        </ContainerSection>
        <ContainerSection>
          <p>Filter by a specific amount:</p>
          <div className="pt-range-filter">
            <div className="pt-range-filter__item">
              <p>From:</p>
              {fromMarkUp}
            </div>
            <div className="pt-range-filter__item">
              <p>To:</p>
              {toMarkUp}
            </div>
          </div>
        </ContainerSection>
      </div>
    </FilterModal>
  );
};

export default MarkUpOnFilter;
