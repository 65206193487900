// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import TextInput from "../../../components/lib/TextInput";

const InstantSearchBox = observer(
  class extends Component {
    searchBox: HTMLInputElement | null;

    handleOnChange = (e) => {
      const onSearch = this.props.onSearch;

      if (onSearch) {
        onSearch(e.target.value);
      }
    };

    componentDidUpdate() {
      if (this.searchBox && this.props.autoFocus) {
        this.searchBox.focus();
      }
    }

    render() {
      // const classes = classNames("pt-search", { small: this.props.small, "icon-left": this.props.iconLeft });

      return (
        <TextInput
          autoComplete="off"
          disabled={this.props.disabled}
          placeholder={this.props.placeholder}
          ref={(element) => (this.searchBox = element)}
          name="search-box"
          value={this.props.value}
          onChange={this.handleOnChange}
          icon={this.props.iconLeft ? "search" : undefined}
          iconRight={this.props.iconLeft ? undefined : "search"}
          size={this.props.small ? "small" : "normal"}
          css={this.props.style}
        />
      );
    }
  }
);

InstantSearchBox.propTypes = {
  onSearch: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  iconLeft: PropTypes.bool,
};

InstantSearchBox.defaultProps = {
  autoFocus: false,
};

export default InstantSearchBox;
