// @flow

import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {|
  +hasPreviousPage: boolean,
  +hasNextPage: boolean,
  +handlePrevious: (e: SyntheticEvent<HTMLDivElement>) => void,
  +handleNext: (e: SyntheticEvent<HTMLDivElement>) => void,
|};

function WindowPagination(props: Props) {
  const { hasNextPage, hasPreviousPage, handleNext, handlePrevious } = props;

  function onPrevious(e: SyntheticEvent<HTMLDivElement>) {
    if (hasPreviousPage) {
      handlePrevious(e);
    }
  }

  function onNext(e: SyntheticEvent<HTMLDivElement>) {
    if (hasNextPage) {
      handleNext(e);
    }
  }

  return (
    <div className="pt-pagination-outer">
      <div id="pt-prev-page" className="pt-prev-page" onClick={onPrevious}>
        <FontAwesomeIcon
          icon="chevron-left"
          fixedWidth
          className="pt-page-left-chevron"
        />
      </div>
      <div id="pt-next-page" className="pt-next-page" onClick={onNext}>
        <FontAwesomeIcon
          icon="chevron-right"
          fixedWidth
          className="pt-page-right-chevron"
        />
      </div>
    </div>
  );
}

export default WindowPagination;
