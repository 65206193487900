import React from "react";

import Stack from "../../../../components/lib/Stack";
import Text from "../../../../components/lib/Text";
import Icon from "../../../../components/lib/Icon";
import PageHeadBlock from "./PageHeadBlock";
import {
  Card,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
  CardHeaderTitle,
  CardBody,
} from "../../../../components/lib/Card";

export default function WrapperBlock({ children }: { children: React.ReactNode }) {
  return (
    <Stack>
      <PageHeadBlock />
      <Card fill>
        <CardActions>
          <CardActionsLeft>
            <CardHeaderTitle as="h3">Create Survey Form</CardHeaderTitle>
          </CardActionsLeft>
          <CardActionsRight />
        </CardActions>
        <CardBody>
          <Stack css={{ gap: "$1", alignItems: "start" }}>
            <Text thin css={{ fontSize: "$sm" }}>
              <Icon icon="info-circle" /> Required steps marked with *
            </Text>
          </Stack>
          <Stack
            fill
            css={{
              alignItems: "stretch",
              gap: "$6",
              marginTop: "$6",
            }}
          >
            {children}
          </Stack>
        </CardBody>
      </Card>
    </Stack>
  );
}

WrapperBlock.displayName = "WrapperBlock";
