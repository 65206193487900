import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import CheckBox from "../../shared/CheckBox";
import { styled } from "../../../../src/stitches.config";
import Box from "../../../components/lib/Box";
import LinkButton from "../../../components/lib/LinkButton";
import Inline from "../../../components/lib/Inline";

const RateCardListItem = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "20px",
  transition: "all 200ms",
  borderBottom: "1px solid #eceff1;",
});

const RateCardMeta = styled("div", {
  minWidth: "0",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontSize: "16px",
  lineHeight: "18px",
  display: "flex",
  gap: "$1",
});

const RateCardLabel = styled("div", {
  color: "$brand",
  fontSize: "24px",
  transition: "all 200ms",
  lineHeight: "1.2",
  minWidth: "0",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "60ch",
  alignItems: "center",
  display: "flex",
});

const RateCardInfo = styled("div", {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  fontSize: "20px",
  minWidth: "100px",
  whiteSpace: "nowrap",
  width: "300px",
});

const ContentListItem = observer(
  class extends Component {
    render() {
      const props = this.props;
      const tag = props.tag;
      const editing = tag.viewState.editing;
      const selected = tag.viewState.selected;

      const name = tag.name;
      const type = tag.type;
      const link = tag.link;

      let onItemClick = this.props.onClick;
      let editingSection = null;
      if (editing) {
        onItemClick = tag.toggleSelected;
        editingSection = (
          <CheckBox
            type={props.store.allowMultipleItemSelection ? "checkbox" : "radio"}
            name="list-item-select"
            value={name}
            selected={selected}
            style={{ width: 34 }}
            onClick={onItemClick}
          />
        );
      }

      return (
        <Box css={{ borderTop: "1px solid #eceff1" }}>
          <RateCardListItem selected={selected} onClick={onItemClick}>
            <RateCardMeta>
              {editingSection}
              <RateCardLabel>{name}</RateCardLabel>
            </RateCardMeta>
            <div className="ratecard-list-item-right">
              <RateCardInfo>
                <Inline
                  css={{
                    color: "$primary",
                    backgroundColor: "inherit",
                    fontSize: "14px",
                  }}
                >
                  Type
                </Inline>
                <div>{type}</div>
              </RateCardInfo>
              {!editing && (
                <div className="rc-center">
                  {<LinkButton to={`/${link}/${tag.id}`}>View</LinkButton>}
                </div>
              )}
            </div>
          </RateCardListItem>
        </Box>
      );
    }
  }
);

ContentListItem.propTypes = {
  store: PropTypes.object.isRequired,
  //rateCard: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default ContentListItem;
