import React from "react";

import Alert from "./lib/Alert";
import Stack from "./lib/Stack";
import Text from "./lib/Text";
import Button from "./lib/Button";

import { CSS } from "../stitches.config";

import type { AlertProps } from "./lib/Alert";
import type { ButtonProps } from "./lib/Button";

type AlertWithButtonProps = {
  fill?: boolean;
  color?: AlertProps["color"];
  head?: React.ReactChild;
  body?: React.ReactChild;
  noButton?: boolean;
  buttonText?: ButtonProps["children"];
  buttonIcon?: ButtonProps["icon"];
  onButtonClick?: React.ReactEventHandler<HTMLButtonElement>;
  //
  alertCss?: CSS;
  contentCss?: CSS;
  buttonCss?: CSS;
};

export const AlertWithButton = (props: AlertWithButtonProps) => {
  const {
    fill = true,
    color = "warning",
    head,
    body,
    noButton = false,
    buttonText = "Back To List",
    buttonIcon = "arrow-left",
    onButtonClick,
    alertCss,
    contentCss,
    buttonCss,
  } = props;

  return (
    <Alert fill={fill} color={color} css={alertCss}>
      <Stack css={{ alignItems: "flex-start", ...contentCss }}>
        {!!head && <Text as="h3">{head}</Text>}
        {!!body && <Text>{body}</Text>}
        {!noButton && (
          <Button icon={buttonIcon} color={color} onClick={onButtonClick} css={buttonCss}>
            {buttonText}
          </Button>
        )}
      </Stack>
    </Alert>
  );
};
AlertWithButton.displayName = "AlertWithButton";

export default AlertWithButton;
