export function rpReactSelectTheme(theme: any) {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary: "hsla(217, 87%, 55%, 100)",
      primary75: "hsla(217, 87%, 55%, 100)",
      primary50: "hsla(217, 87%, calc(55% + 12%), 100)",
      primary25: "hsla(217, 87%, calc(55% + 36%), 100)",
    },
  };
}

export const reactSelectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderWidth: 2,
    fontSize: 16,
    paddingTop: 2,
    paddingBottom: 2,
    lineHeight: "1.5em",
    borderColor: state.isDisabled ? "#ECECEC" : "#D4D4D8",
    backgroundColor: state.isDisabled ? "#ECEFF1" : "white",
    cursor: state.isDisabled ? "not-allowed" : "normal",
    opacity: state.isDisabled ? "0.6" : 1,
    "&:focus-within": {
      boxShadow: "0 0 0 #a2b624",
    },
    "&:disabled": {
      borderColor: "#ECECEC",
      color: "#999999",
      cursor: "not-allowed",
      backgroundColor: "#eceff1",
      opacity: "0.6",
    },
  }),
  menuList: (provided: any, state: any) => ({
    ...provided,
    fontSize: 16,
    lineHeight: "1.5em",
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    fontSize: 16,
    lineHeight: "1.5em",
    color: "#ccc",
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    fontSize: 16,
    lineHeight: "1.5em",
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    fontSize: 16,
    lineHeight: "1.5em",
    paddingTop: 2,
    paddingBottom: 2,
  }),
  container: (provided: any, state: any) => ({
    ...provided,
    width: "100%",
  }),
};

export const reactSelectWithIconStyles = {
  ...reactSelectStyles,
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    paddingLeft: 31,
  }),
};
