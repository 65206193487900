// @flow

import R from "ramda";
import { extendObservable, action, runInAction, computed, observable } from "mobx";
import axios from "axios";
import moment from "moment";
import Punchouts from "../../../models/Punchouts";
import CurrentUser from "../../../models/User";
import {
  CreatedOnFilter,
  RateCardLabelFilter,
  CreatedByFilter,
  ClientUserFilter,
} from "../../../models/FilterState";
import NetworkState from "../../../models/NetworkState";
import PaginationState from "../../../models/PaginationState";
import FilterObject, { FILTER_COLUMN } from "../../../models/Filter";
import Sort, { SORT_DIRECTION } from "../../../models/Sort";
import { consolidateAppliedFilters, consolidateAppliedSorts } from "../SupportFunctions";
import type { PageQuery, PaginationInfo } from "../../../models/PaginationState";
import type { FilterColumn } from "../../../models/Filter";
import type { GraphQLQuery } from "../../../models/GraphQL";
import Search from "../../../models/Search";
import ModalState from "../../../models/ModalState";
import type { FetchGraphQL, FetchAPI } from "../../../App";
import MobXStore from "../MobXStore";

const ownersCriteriaQuery = `
query authorList {
  viewer {
    authors(section:ADMIN) {
      edges {
        node {
          userId
          firstName
          lastName
          username
          email
        }
      }
    }
  }
}
`;
const clientUserCriteriaQuery = `
query allClients {
  viewer {
    allClients(order : [{direction : ASC, field: NAME}]) {
      edges {
        node {
          clientId
          name
        }
      }
    }
  }
}
`;

export class PunchoutsListComponentStore {
  network: NetworkState;
  pagination: PaginationState;

  punchouts: Punchouts[];
  punchoutsViewState: Object;
  punchoutsView: Punchouts[];
  punchoutCounts: any;

  currentUser: CurrentUser;
  isEditing: ?boolean;
  allOnPageSelected: boolean;
  allSelected: boolean;
  allSelectedfilter: boolean;
  allowMultipleItemSelection: boolean;

  //hasRateTypeFilter: HasRateTypeFilter;
  punchoutLabelFilter: RateCardLabelFilter;
  // createdByFilter: CreatedByFilter;
  // sharedFilter: SharedFilter;
  createdByFilter: CreatedByFilter;
  clientUserFilter: ClientUserFilter;
  createdOnFilter: CreatedOnFilter;
  requestFrom: boolean;

  defaultFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedSorts: {
    [key: FilterColumn]: Sort,
  };
  appliedSortsOrder: Array<FilterColumn>;
  isFiltered: boolean;
  hasOwnership: boolean;
  selectedPunchoutId: null;
  sevenUrl: string;
  unSelectedPunchout: [];

  getPunchouts: (PageQuery) => Promise<PaginationInfo>;
  getPunchoutsList: (PageQuery) => Promise<PaginationInfo>;
  applyDefaultFilter: (FilterColumn, FilterObject) => void;
  handleStartEdit: () => void;
  handleStopEdit: () => void;
  toggleSelectAllPage: (Object) => void;
  selectAllPage: (Event) => void;
  deselectAllPage: (Event) => void;
  toggleAllItems: () => void;
  clearAllSelections: () => void;
  getSelectedPunchouts: () => void;
  getFirstSelectedPunchout: () => Object;
  toDateRange: () => void;
  getFilterCriteriaQuery: (FilterColumn) => GraphQLQuery;
  processFilterCriteria: (FilterColumn, Object) => Array<Object>;
  applyFilter: (FilterColumn, FilterObject) => void;
  removeFilter: (FilterColumn) => void;
  applySort: (FilterColumn, Sort) => void;
  removeSort: (FilterColumn) => void;
  filterpunchouts: () => void;
  clearFilters: () => void;
  applyDefaultSort: () => void;
  //updateRequestFrom: () => void;
  SingleSelectedPunchout: () => void;
  toggleExpanded: (Search) => void;
  toggleSelected: (Search) => void;
  allowExpand: boolean;
  expandAllSearches: () => void;
  collapseAllSearches: () => void;
  getUnSelectedPunchouts: () => void;
  //getSelectedPunchouts: () => void;
  confirmDeleteModal: ModalState;
  setAlertModal: ModalState;
  unSelectedPunchouts: [];
  showSetAlertModal: () => void;
  payRateValues: Object;
  setAlertValues: () => void;
  showUpdateRateBtn: boolean;
  resultLevel: Object;
  selectAllOnPageItem: () => void;
  fetchGraphQL: FetchGraphQL;

  constructor(fetchGraphQL: FetchGraphQL, mobxStore: MobXStore) {
    this.fetchGraphQL = fetchGraphQL;
    this.mobxStore = mobxStore;
    // NOTE: Bound early to pass into pagination & filter state
    this.getPunchoutsList = action(this.getPunchoutsList.bind(this));
    this.applyFilter = action(this.applyFilter.bind(this));
    this.applySort = action(this.applySort.bind(this));
    this.removeFilter = action(this.removeFilter.bind(this));
    this.removeSort = action(this.removeSort.bind(this));
    this.toDateRange = action(this.toDateRange.bind(this));
    this.unSelectedPunchout = [];

    extendObservable(this, {
      network: new NetworkState(),
      pagination: new PaginationState(this.getPunchoutsList),
      punchouts: [],
      punchoutCounts: 0,
      hasOwnership: true,
      selectedPunchoutId: null,
      punchoutsViewState: observable.map({}),
      unSelectedPunchouts: [],
      punchoutsView: computed(() => {
        return this.punchouts.map((punchout) => {
          if (this.punchoutsViewState.has(punchout.buyrateId)) {
            punchout.viewState = this.punchoutsViewState.get(punchout.buyrateId);
            return punchout;
          }

          return punchout;
        });
      }),
      isEditing: null, // we start with null so some view elements be hidden initially
      isFiltered: false,
      allSelected: false,
      allSelectedfilter: false,
      allowMultipleItemSelection: true,
      requestFrom: false,
      selectedCount: computed(() => {
        const selectedValues = this.punchoutsView.map(
          (punchout) => punchout.viewState.selected
        );

        if (this.allSelected) {
          return this.pagination.totalCount;
        }

        let count = 0;

        selectedValues.forEach((v) => {
          if (v) {
            count += 1;
          }
        });

        return count;
      }),
      allOnPageSelected: computed(() => {
        const allTrue = R.all(R.equals(true));
        const selectedValues = this.punchoutsView.map(
          (punchout) => punchout.viewState.selected
        );

        if (selectedValues.length === 0) {
          return false;
        }

        return allTrue(selectedValues);
      }),
      defaultFilters: {},
      appliedFilters: {},
      appliedSorts: {},
      appliedSortsOrder: observable.shallow([]),
      allowExpand: true,
      punchoutLabelFilter: new RateCardLabelFilter(
        this,
        FILTER_COLUMN.RATE_CARD_LABEL,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),

      createdOnFilter: new CreatedOnFilter(
        this,
        FILTER_COLUMN.PUNCHOUTS_DATE_RANGE,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      createdByFilter: new CreatedByFilter(
        this,
        FILTER_COLUMN.CREATED_BY,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      clientUserFilter: new ClientUserFilter(
        this,
        FILTER_COLUMN.CLIENT_USER,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      confirmDeleteModal: new ModalState(),
      setAlertModal: new ModalState(),
      payRateValues: {
        payAvg: null,
        payMid: null,
        payMin: null,
        payMax: null,
      },
      payRateAlertValues: {
        payRateAvgAlert: 0,
        payRateMidAlert: 0,
        payRateMinAlert: 0,
        payRateMaxAlert: 0,
        punchoutId: null,
      },
      showUpdateRateBtn: true,
      resultLevel: {},
    });

    this.currentUser = null;

    this.applyDefaultFilter = action(this.applyDefaultFilter.bind(this));
    this.handleStartEdit = action(this.handleStartEdit.bind(this));
    this.handleStopEdit = action(this.handleStopEdit.bind(this));
    this.toggleSelectAllPage = action(this.toggleSelectAllPage.bind(this));
    this.selectAllPage = action(this.selectAllPage.bind(this));
    this.deselectAllPage = action(this.deselectAllPage.bind(this));
    this.toggleAllItems = action(this.toggleAllItems.bind(this));
    this.clearAllSelections = action(this.clearAllSelections.bind(this));
    this.getSelectedPunchouts = action(this.getSelectedPunchouts.bind(this));
    this.getFirstSelectedPunchout = action(this.getFirstSelectedPunchout.bind(this));

    this.filterpunchouts = action(this.filterpunchouts.bind(this));
    this.clearFilters = action(this.clearFilters.bind(this));
    this.applyDefaultSort = action(this.applyDefaultSort.bind(this));
    // this.updateRequestFrom = action(this.updateRequestFrom.bind(this));
    this.SingleSelectedPunchout = action(this.SingleSelectedPunchout.bind(this));
    this.toggleExpanded = action(this.toggleExpanded.bind(this));
    this.toggleSelected = action(this.toggleSelected.bind(this));
    this.expandAllSearches = action(this.expandAllSearches.bind(this));
    this.collapseAllSearches = action(this.collapseAllSearches.bind(this));
    this.getUnSelectedPunchouts = action(this.getUnSelectedPunchouts.bind(this));
    this.showSetAlertModal = action(this.showSetAlertModal.bind(this));
    this.setAlertValues = action(this.setAlertValues.bind(this));
    this.getFilterCriteriaQuery = action(this.getFilterCriteriaQuery.bind(this));
    this.processFilterCriteria = action(this.processFilterCriteria.bind(this));
    this.selectAllOnPageItem = action(this.selectAllOnPageItem.bind(this));

    this.applyDefaultSort();
  }

  SingleSelectedPunchout(punchout) {
    let viewState = this.punchoutsViewState.get(punchout.buyrateId).selected;

    if (!viewState) {
      this.selectedRateCardId = punchout.buyrateId;
    } else {
      this.selectedRateCardId = null;
    }

    if (!viewState && punchout.owner && punchout.owner.userId) {
      this.hasOwnership =
        String(punchout.owner.userId) === String(this.currentUser.userId);
    } else {
      this.hasOwnership = true;
    }
  }

  toDateRange(date) {
    var val = true;
    if (this.createdOnFilter.fromDate) {
      val = !date.isBetween(
        this.createdOnFilter.fromDate,
        moment(new Date()),
        "days",
        "[]"
      );
    }
    return val;
  }

  applyDefaultSort() {
    this.createdOnFilter.sortState.direction = SORT_DIRECTION.DESC;
    this.createdOnFilter.sort = this.createdOnFilter.buildQuerySort();
    this.applySort(this.createdOnFilter.column, this.createdOnFilter.sort);
  }

  handleStartEdit() {
    this.isEditing = true;
    this.allowExpand = false;
    this.punchoutsView.forEach((punchout) => punchout.toggleEdit());
  }

  handleStopEdit() {
    this.isEditing = false;
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.allowExpand = true;
    this.punchoutsViewState.forEach((viewState) => {
      viewState.selected = false;
      viewState.editing = false;
    });
  }

  toggleSelectAllPage(e: Object) {
    if (!this.allowMultipleItemSelection) return;

    const setValue = !this.allOnPageSelected;

    this.punchoutsView.forEach((punchout) => {
      this.toggleSelected(punchout, null, null, setValue);
    });

    // When All items selected flag is up, clear selection
    if (setValue === false && this.allSelected) this.allSelected = false;
  }

  selectAllPage(e: Event) {
    this.unSelectedPunchouts = [];
    this.allSelected = true;
    this.allSelectedfilter = true;
  }

  selectAllOnPageItem(e: Event) {
    this.punchoutsView.forEach((punchout) => {
      this.toggleSelected(punchout, null, null, true);
    });
  }

  deselectAllPage(e: Event) {
    this.punchoutsView.forEach((punchout) => {
      this.toggleSelected(punchout, null, null, false);
    });

    this.allSelected = false;
    this.allSelectedfilter = false;
  }

  toggleAllItems() {
    if (!this.allowMultipleItemSelection) return;

    this.allSelected = !this.allSelected;

    if (this.allSelected === false) {
      this.punchoutsViewState.forEach((value) => {
        value.selected = false;
      });
    }
  }

  clearAllSelections() {
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.punchoutsViewState.forEach((value) => {
      value.selected = false;
    });
  }

  applyFilter(column: FilterColumn, filter: FilterObject) {
    this.appliedFilters[column] = filter;
    this.isFiltered = true;
  }

  applyDefaultFilter(column: FilterColumn, filter: FilterObject) {
    this.defaultFilters[column] = filter;
  }

  removeFilter(column: FilterColumn) {
    delete this.appliedFilters[column];

    let entries = Object.entries(this.appliedFilters);
    if (!entries.length) this.isFiltered = false;
  }

  applySort(column: FilterColumn, sort: Sort) {
    this.appliedSorts[column] = sort;

    const index = this.appliedSortsOrder.indexOf(column);
    if (index === -1) this.appliedSortsOrder.push(column);
  }

  removeSort(column: FilterColumn) {
    delete this.appliedSorts[column];

    const index = this.appliedSortsOrder.indexOf(column);
    if (index > -1) this.appliedSortsOrder.splice(index, 1);
  }

  clearFilters() {
    this.punchoutLabelFilter = new RateCardLabelFilter(
      this,
      FILTER_COLUMN.RATE_CARD_LABEL,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.createdOnFilter = new CreatedOnFilter(
      this,
      FILTER_COLUMN.PUNCHOUTS_DATE_RANGE,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.createdByFilter = new CreatedByFilter(
      this,
      FILTER_COLUMN.CREATED_BY,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.clientUserFilter = new ClientUserFilter(
      this,
      FILTER_COLUMN.CLIENT_USER,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );

    this.appliedFilters = observable({});
    this.appliedSorts = observable({});
    this.appliedSortsOrder.length = 0;
    this.isFiltered = false;
    this.applyDefaultSort();

    return this.pagination.goFetch();
  }

  filterpunchouts() {
    return this.pagination.goFetch(null);
  }

  showSetAlertModal(resultLevel) {
    this.payRateValues.payAvg = resultLevel.payRateAvg;
    this.payRateValues.payMid = resultLevel.payRateMid;
    this.payRateValues.payMin = resultLevel.payRateMin;
    this.payRateValues.payMax = resultLevel.payRateMax;

    this.payRateAlertValues.payRateAvgAlert = resultLevel.payRateAvgAlert || 0;
    this.payRateAlertValues.payRateMinAlert = resultLevel.payRateMinAlert || 0;
    this.payRateAlertValues.payRateMaxAlert = resultLevel.payRateMaxAlert || 0;
    this.payRateAlertValues.payRateMidAlert = resultLevel.payRateMidAlert || 0;

    this.resultLevel = resultLevel;

    this.payRateAlertValues.punchoutId = resultLevel.punchoutId;
    this.setAlertModal.showModal();
  }

  setAlertValues(value, paytype) {
    this.payRateAlertValues[paytype] = value;
  }

  getSelectedPunchouts() {
    const punchouts = this.punchoutsViewState;

    let selectedpunchouts = [];

    punchouts.forEach((value, key) => {
      if (value.selected) {
        selectedpunchouts.push(parseInt(key, 10));
      }
    });

    return selectedpunchouts;
  }

  getFirstSelectedPunchout() {
    for (let j = 0; j < this.punchoutsView.length; j++) {
      if (this.punchouts[j].viewState.selected) return this.punchouts[j];
    }

    return null;
  }

  toggleExpanded(punchout: Punchouts) {
    punchout.viewState.expanded = !punchout.viewState.expanded;
    if (punchout.viewState.expanded) {
      //search.setCurrencyType(this.currencyType);
      punchout.getSearchResults();
    }
  }

  toggleSelected(search: Search) {
    const viewState = this.punchoutsViewState.get(search.buyrateId);

    viewState.selected = !viewState.selected;

    if (viewState.selected === false && this.allSelected) {
      this.allSelected = false;
    }

    if (viewState.selected === false) {
      this.unSelectedPunchouts.push(search.buyrateId.toString());
    } else {
      for (var i = 0; i < this.unSelectedPunchouts.length; i++) {
        if (this.unSelectedPunchouts[i] === search.buyrateId) {
          this.unSelectedPunchouts.splice(i, 1);
        }
      }
    }

    if (!this.allowMultipleItemSelection) {
      // deselect all other rate cards
      this.punchoutsViewState.forEach((currentViewState) => {
        if (currentViewState === viewState) return;

        currentViewState.selected = false;
      });
    }
  }

  getUnSelectedPunchouts() {
    const punchouts = this.punchoutsViewState;

    punchouts.forEach((value, key) => {
      if (!value.selected) {
        this.unSelectedSearches.push(key);
      }
    });

    return this.unSelectedSearches;
  }

  // getSelectedPunchouts() {
  //   const punchouts = this.punchoutsViewState;

  //   let selectedSearches = [];
  //  // this.exportRateCardModal.exportOptions.levelType = LEVELS_TYPE.DEFAULT;

  //   punchouts.forEach((value, key) => {
  //     if (value.selected) {
  //       debugger
  //       selectedSearches.push(key);
  //     }
  //   });

  //   return selectedSearches;
  // }

  expandAllSearches() {
    this.punchouts.forEach((search: Search) => {
      search.viewState.expanded = true;
      search.setCurrencyType(this.currencyType);
      search.getSearchResults();
    });
  }

  collapseAllSearches() {
    this.punchouts.forEach((search: Search) => (search.viewState.expanded = false));
  }

  getFilterCriteriaQuery(column: FilterColumn): GraphQLQuery {
    switch (column) {
      case FILTER_COLUMN.CREATED_BY:
        return {
          query: ownersCriteriaQuery,
          variables: {},
        };

      case FILTER_COLUMN.CLIENT_USER:
        return {
          query: clientUserCriteriaQuery,
          variables: {},
        };

      default:
        return null;
    }
  }

  processFilterCriteria(column: FilterColumn, payload: Object): ?Array<Object> {
    switch (column) {
      case FILTER_COLUMN.CREATED_BY:
        const owners: [
          {
            userId: string,
            username: string,
          }
        ] = payload.data.viewer.authors.edges;
        let processedOwners = observable.map({});
        owners.forEach((owner) => {
          processedOwners.set(String(owner.node.userId), {
            id: String(owner.node.userId),
            ...owner,
          });
        });

        return processedOwners;

      case FILTER_COLUMN.CLIENT_USER:
        const clientowners: [
          {
            clientId: string,
            name: string,
          }
        ] = payload.data.viewer.allClients.edges;
        let processedClientOwners = observable.map({});
        clientowners.forEach((owner) => {
          processedClientOwners.set(owner.node.clientId, {
            id: owner.node.clientId,
            ...owner,
          });
        });

        return processedClientOwners;

      default:
        return null;
    }
  }

  async getPunchoutsList(pageQuery: PageQuery): Promise<PaginationInfo> {
    let params: string[] = pageQuery.params;
    let args = pageQuery.args;
    let variables = pageQuery.variables;
    let filtersCriteria: string[] = [];

    let sortCriteria: string[] = [];
    consolidateAppliedSorts(this.appliedSorts, sortCriteria);

    consolidateAppliedFilters(this.appliedFilters, params, filtersCriteria, variables);

    // NOTE: applied filters could override default filters, handle this if needed
    consolidateAppliedFilters(this.defaultFilters, params, filtersCriteria, variables);

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");
    const queryFiltersCriteria = filtersCriteria.join(", ");
    const querySortCriteria = sortCriteria.join(", ");

    const query = `
    query buyrates(${queryParams}) {
        viewer {
          user{
            firstName
            lastName
            userId
            username
            email
          }
          buyrates(${queryArgs},filters: { ${queryFiltersCriteria} }, order: [${querySortCriteria}], section: ADMIN){
            totalCount
            pageInfo{
              startCursor
              endCursor
            }
            edges{
              node{
                buyrateId
                name
                createdBy {
                  firstName
                  lastName
                  email
                  userId
                }
                     search {
                      currency {
                            symbol
                          }
                          rateType
                          searchId,
                          country,
                          state
                          city
                          createdDate,
                            job{
                              jobLabel,
                              jobTitle,
                              jobDescription,
                           }
                           industry{
                             legacyId
                             value
                           }
                          marketchanges{
                              created,
                              modified,
                              payRateMin,
                              payRateMax,
                              payRateMid,
                              payRateAvg
                              billRateMin,
                              billRateMax,
                              billRateMid,
                              billRateAvg,
                              markupPctMin,
                              markupPctMax,
                              markupPctMid,
                              markupPctAvg,
                              markupAmtMin,
                              markupAmtMax,
                              markupAmtMid,
                              markupAmtAvg
                              level{
                                legacyId
                                value
                              }
                            }

                    }
                created
              }
            }
          }
        }
      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting Rate Cards", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Rate Card query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getPunchoutsList--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get Rate Cards query", res)) {
        // TODO: Display user friendly error message
        return {
          totalCount: 0,
          startCursor: "",
          endCursor: "",
        };
      }

      this.currentUser = new CurrentUser(this, res.data.viewer.user);

      // // $FlowFixMe: Type the res value once we abstract gql calls.
      const cards = res.data.viewer.buyrates.edges;
      this.punchoutCounts = res.data.viewer.buyrates.totalCount;
      // // TODO: Deserialize this properly...
      this.punchouts = cards.map((card) => {
        card.node.search.buyrateId = card.node.buyrateId;
        card.node.search.buyrateName = card.node.name;
        card.node.search.createdBuyrate = card.node.created;
        card.node.search.ownerLogin = card.node.createdBy;
        card.node.search.needsSetAlertBtn = true;
        card.node.search.isPtAdmin = true;
        const punchout = new Search(this, card.node.search);
        punchout.lastUpdatedDateDisplay = punchout.lastUpdated.format("MMM, YYYY");
        // rateCard.searches = card.node.searches.totalCount;
        // rateCard.locations = card.node.locations;
        // debugger

        if (!this.punchoutsViewState.has(punchout.buyrateId)) {
          this.punchoutsViewState.set(punchout.buyrateId, {
            selected: this.allSelected,
            editing: this.isEditing,
            expanded: false,
          });
        } else {
          const selectedValue = this.allSelected
            ? true
            : this.punchoutsViewState.get(punchout.buyrateId).selected;

          this.punchoutsViewState.set(punchout.buyrateId, {
            selected: selectedValue,
            editing: this.isEditing,
            expanded: false,
          });
        }

        punchout.viewState = this.punchoutsViewState.get(punchout.buyrateId);
        // punchout.owner = card.createdBy;

        return punchout;
      });

      return {
        totalCount: res.data.viewer.buyrates.totalCount,
      };
    });
  }
}

export default class PunchoutsListStore extends PunchoutsListComponentStore {
  changeDateFormat: () => void;
  setPunchoutAlert: () => void;
  selectPunchouts: () => void;
  fetchAPI: FetchAPI;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI, mobxStore: MobXStore) {
    super(fetchGraphQL, mobxStore);
    this.fetchAPI = fetchAPI;

    // Bound early to pass into export state
    this.exportPunchouts = action(this.exportPunchouts.bind(this));
    this.changeDateFormat = action(this.changeDateFormat.bind(this));
    this.selectPunchouts = action(this.selectPunchouts.bind(this));
  }

  changeDateFormat(date) {
    var formatted_date = new Date(date);
    var year = formatted_date.getFullYear() + "";
    var month = formatted_date.getMonth() + 1 + "";
    var day = formatted_date.getDate() + "";
    var dateFormat = year + "-" + month + "-" + day;

    return dateFormat;
  }

  selectPunchouts(type) {
    if (this.network.loading) {
      return;
    }

    let params = [];
    let args = [];
    let vars = {};
    let searchCriteria = "";

    if (this.allSelected) {
      consolidateAppliedFilters(this.appliedFilters, params, args, vars);
      searchCriteria = {
        searchParam: vars,
      };
    } else {
      const selectedpunchoutsList = this.getSelectedPunchouts();

      if (!selectedpunchoutsList) {
        console.error("Cannot " + type + " jobs: No Punchouts selected");
        return;
      }
      searchCriteria = {
        punchoutId: selectedpunchoutsList,
      };
    }

    return searchCriteria;
  }

  exportPunchouts() {
    if (this.network.loading) {
      return;
    }

    let params = [];
    let args = [];
    let vars = {};
    let exportURL = "ratecards/export/punchout/excel/";
    // Change the Filename
    var fileName = "export_table_" + Math.floor(Math.random() * 9999999 + 1000000);
    let parameters = "";
    let exparams = "";

    if (this.allSelectedfilter) {
      consolidateAppliedFilters(this.appliedFilters, params, args, vars);
      vars.exclude = [];
      if (vars.nameFilterText) {
        vars.nameIContains = vars.nameFilterText;
        delete vars.nameFilterText;
      }
      parameters = JSON.stringify(vars);
      if (parameters === "") {
        exparams = `{"fileName": "${fileName}","section":"admin" ,"punchoutFilters": {${parameters}}}`;
      } else {
        exparams = `{"fileName": "${fileName}","section":"admin" ,"punchoutFilters": ${parameters}}`;
      }
    } else {
      const selectedPunchouts = this.getSelectedPunchouts();
      if (!selectedPunchouts || !selectedPunchouts.length) {
        console.error("Cannot export Regions: No Regions selected");
        return;
      }
      vars.only = selectedPunchouts;
      parameters = JSON.stringify(vars);
      if (parameters === "") {
        exparams = `{"fileName": "${fileName}","section":"admin" ,"punchoutFilters": {${parameters}}}`;
      } else {
        exparams = `{"fileName": "${fileName}","section":"admin" ,"punchoutFilters": ${parameters}}`;
      }
    }

    this.fetchAPI(exportURL, JSON.parse(exparams))
      .then((res) => {
        window.location.href = res.data.url;
        this.handleStopEdit();
      })
      .catch((e) => {
        console.error("Error downloading excel", e);
        // TODO: Handle errors properly
        throw e; // Prevent success action from running
      });
  }
}
