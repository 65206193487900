// @flow

import React, { Component } from "react";
import { observer } from "mobx-react";
import BatchSearchListHeader from "./BatchSearchListHeader";
import BatchSearchList from "./BatchSearchList";
import type MobXStore from "../../../stores/mobx/MobXStore";
import BatchSearchListEditActions from "./BatchSearchListEditActions";
import ConfirmDeleteBatchSearchesModal from "./ConfirmDeleteBatchSearchesModal";
import ErrorModal from "../../../components/modals/ErrorModal";
import BatchSearchListHelpModal from "./BatchSearchListHelpModal";
import Container from "../../../components/lib/Container";
import {
  Card,
  CardHeader,
  CardHeaderSubTitle,
  CardHeaderTitle,
} from "../../../components/lib/Card";

type Props = {
  store: MobXStore,
  router: Object,
  sessionInfo: SessionInfo,
};

class List extends Component<Props> {
  componentDidMount() {
    this.props.store.batchSearchJLListStore.router = this.props.router;
    this.props.store.batchSearchJLListStore.pagination.goFetch();
    // reset to null so some elements visible while editing start hidden
    // this.props.store.batchSearchListStore.isEditing = null;

    // clean rate card details view state
    // this.props.store.batchSearchDetailStore.batchSearchId = null;
    // this.props.store.batchSearchDetailStore.batchSearch = null;
  }

  render() {
    const batchSearchListStore = this.props.store.batchSearchJLListStore;

    return (
      <Container
        css={{
          paddingTop: "$4",
          paddingLeft: 0,
          paddingRight: 0,
          width: "100%",
        }}
      >
        <Card css={{ zIndex: 2 }}>
          <CardHeader>
            <CardHeaderTitle>Batch Searches</CardHeaderTitle>
            <CardHeaderSubTitle>
              Run, or re-run, batch searches across many locations, titles, and
              industries.
            </CardHeaderSubTitle>
          </CardHeader>
          <BatchSearchListHeader batchSearchListStore={batchSearchListStore} />
          <BatchSearchList store={batchSearchListStore} router={this.props.router} />
        </Card>
        <BatchSearchListEditActions store={batchSearchListStore} />
        <ErrorModal
          modalState={batchSearchListStore.errorModal}
          errorMessage={batchSearchListStore.errorMessage}
        />
        <ConfirmDeleteBatchSearchesModal store={batchSearchListStore} />
        <BatchSearchListHelpModal store={batchSearchListStore} />
      </Container>
    );
  }
}

export default observer(List);
