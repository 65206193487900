import { styled } from "../../stitches.config";

export const Text = styled("span", {
  "& sup": {
    top: "-$1",
    fontSize: "75%",
  },
  variants: {
    noMargin: {
      true: {
        margin: 0,
      },
    },
    underline: {
      true: {
        textDecoration: "underline",
      },
    },
    italic: {
      true: {
        fontStyle: "italic",
      },
    },
    bold: {
      true: {
        fontWeight: "$bold",
      },
    },
    thin: {
      true: {
        fontWeight: "$extralight",
      },
    },
    centered: {
      true: {
        textAlign: "center",
      },
    },
    nowrap: {
      true: {
        whiteSpace: "nowrap",
      },
    },
    color: {
      positive: {
        color: "$success !important",
      },
      negative: {
        color: "$danger !important",
      },
      light: {
        color: "$primaryLight !important",
      },
      primary: {
        color: "$primary !important",
      },
      dark: {
        color: "$primaryDarkest !important",
      },
      accent: {
        color: "$accent !important",
      },
      brand: {
        color: "$brand !important",
      },
    },
    size: {
      "2xs": {
        fontSize: "$2xs",
      },
      xs: {
        fontSize: "$xs",
      },
      sm: {
        fontSize: "$sm",
      },
      base: {
        fontSize: "$base",
      },
      lg: {
        fontSize: "$lg",
      },
      xl: {
        fontSize: "$xl",
      },
    },
  },
  defaultVariants: {
    underline: false,
    italic: false,
    bold: false,
    thin: false,
    centered: false,
    color: undefined,
    nowrap: false,
  },
});
Text.displayName = "Text";

export const TextOverflow = styled(Text, {
  display: "inline-block",
  width: "$full",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
});
TextOverflow.displayName = "TextOverflow";

export default Text;
