import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import { observer } from "mobx-react";
import CheckBox from "../../../shared/CheckBox";
import classNames from "classnames";

const ClientListItem = observer(
  class extends Component {
    static contextTypes = {
      router: PropTypes.object,
    };

    constructor(props) {
      super(props);

      this.handleViewDetail = this.handleViewDetail.bind(this);
    }

    handleViewDetail() {
      this.context.router.push({
        pathname: `/admin/clients/${this.props.region.id}`,
        query: this.context.router.query,
      });
    }

    render() {
      const props = this.props;
      const clientDetail = props.region;

      const editing = clientDetail.viewState.editing;
      const selected = clientDetail.viewState.selected;
      const name = clientDetail.name;
      const id = clientDetail.id;
      const active = clientDetail.active;
      const isClientJobLibrary = clientDetail.isClientJobLibrary;
      const perSearchPricing = clientDetail.perSearchPricing;
      const totalUserCount = clientDetail.totalUserCount;

      let onItemClick = this.props.onClick || this.handleViewDetail;
      let editingSection = null;
      //let sharedByYou = shared;

      if (editing) {
        onItemClick = clientDetail.toggleSelected;
        editingSection = (
          <CheckBox
            type={props.store.allowMultipleItemSelection ? "checkbox" : "radio"}
            name="list-item-select"
            value={name}
            selected={selected}
            style={{ width: 34 }}
            onClick={onItemClick}
          />
        );
      }

      return (
        <div className="ratecard-list-item-container" data-testid="client-list-item">
          <div
            className={classNames("ratecard-list-item", { selected: selected })}
            onClick={onItemClick}
          >
            <div className="ratecard-list-item-left ellipsis">
              {editingSection}
              <div className="ratecard-label-container ellipsis">
                <div className="ratecard-label ellipsis">
                  <span className="ellipsis">{name}</span>
                </div>
                {/*<div className="ratecard-meta ellipsis">
                <span className="item-subtext-muted">Created by </span>
                <span className="item-subtext-accent">{ownerName} </span>
                <span className="item-subtext-muted">on </span>
                <span className="item-subtext-accent">{createdDisplay}</span>
              </div>*/}
              </div>
            </div>
            <div className="ratecard-list-item-right">
              <div className="ratecard-info w-300 text-center">
                <header data-testid="ID-header-client-detail">ID</header>
                <div>{id}</div>
              </div>
              <div className="ratecard-info w-300 text-center">
                <header data-testid="users-header-client-detail">Users</header>
                <div>{totalUserCount}</div>
              </div>
              <div className="ratecard-info w-300 text-center">
                <header data-testid="PSP-header-client-detail">PSP</header>
                <div>{perSearchPricing ? "Enabled" : "Disabled"}</div>
              </div>
              <div className="ratecard-info w-300 text-center">
                <header data-testid="library-header-client-detail">Library</header>
                <div>{isClientJobLibrary ? "Enabled" : "Disabled"}</div>
              </div>
              <div className="ratecard-info w-300 text-center">
                <header data-testid="status-header-client-detail">Status</header>
                <div>{active ? "Active" : "Inactive"}</div>
              </div>
              {!editing && (
                <div className="rc-center">
                  {
                    <Link to={`/admin/clients/${id}`} className="btn btn-default">
                      View
                    </Link>
                  }
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
);

ClientListItem.propTypes = {
  store: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default ClientListItem;
