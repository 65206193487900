// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SortIndicator from "../../ratecards/components/SortIndicator";
import { FILTER_COLUMN } from "../../../models/Filter";
import FilterModal from "../../ratecards/components/FilterModal";
import SortControls from "../../ratecards/components/SortControls";
import ListContainer from "../../ratecards/components/ListContainer";
import Pagination from "../../../components/lib/Pagination";
import LoadingIndicator from "../../shared/LoadingIndicator";
import TagListItem from "./TagManagementListItem";
import { SingleDatePicker } from "../../../components/SingleDatePicker";
import { TagManagementListComponentStore } from "../../../stores/mobx/TagManagementListStore";
import InstantSearchBox from "../../ratecards/components/InstantSearchBox";
import SelectableItem from "../../ratecards/components/SelectableItem";
import ToggleButton from "../../ratecards/components/ToggleButton";
import ContainerSection from "../../ratecards/components/ContainerSection";
import SelectableItemsList from "../../ratecards/components/SelectableItemsList";
import Button from "../../../components/lib/Button";
import Inline from "../../../components/lib/Inline";
import { CardAlert, CardAlertLink } from "../../../components/lib/Card";

const CreatedByCriteriaList = observer((props) => {
  return (
    <div>
      <SelectableItemsList listState={props.filterState}>
        {props.filterState.viewItems.map((owner, i) => (
          <SelectableItem
            key={owner.username}
            item={owner}
            name="owner-filter"
            value={owner.username}
            selected={owner.selected}
            onChange={props.filterState.setSelectedValue}
          >
            <div>
              {owner.firstName} {owner.lastName}{" "}
            </div>
            <div className="text-x-small">
              <em>
                <FontAwesomeIcon icon="user" className="icon no-margin" />{" "}
                {owner.username}
              </em>
            </div>
          </SelectableItem>
        ))}
      </SelectableItemsList>
      <ContainerSection className="info">
        <p className="text-muted text-x-small no-margin pull-right">
          <em>
            <strong>{props.filterState.selectedValuesCount} </strong>
            selected, <strong>{props.filterState.totalValuesCount} </strong>
            total
          </em>
        </p>
        <div className="clearfix" />
      </ContainerSection>
    </div>
  );
});

class TagManagementList extends Component {
  constructor(props) {
    super(props);

    this.changeCurrent = this.changeCurrent.bind(this);
  }

  changeCurrent(value) {
    let store: TagManagementListComponentStore = this.props.store;
    store.pagination.handleCurrentPage(value);
  }

  componentDidMount() {
    //this.props.store.pagination.goFetch();
  }

  render() {
    const store: TagManagementListComponentStore = this.props.store;
    const network = store.network;
    const fillContainer = this.props.fillContainer;
    const fixedHeight = this.props.fixedHeight;
    const maximumDate = new Date();

    let listBodyStyles = {};
    if (fixedHeight) {
      listBodyStyles.height = fixedHeight;
      listBodyStyles.overflowY = "auto";
    }

    let selectAllSection = null;
    let selectAllOnPageSection = (
      <Inline css={{ gap: "$2" }}>
        <Button onClick={store.selectAllOnPageItem}>Select All</Button>
        <Button onClick={store.deselectAllPage}>Deselect All</Button>
      </Inline>
    );

    if (store.allOnPageSelected) {
      selectAllSection = (
        <CardAlert>
          <span>
            All <strong>{store.tagCounts}</strong> Tags selected.{" "}
          </span>
          <CardAlertLink key="clear-selections-link" onClick={store.clearAllSelections}>
            Clear all selections
          </CardAlertLink>
        </CardAlert>
      );
    }

    if (store.allOnPageSelected && !store.allSelected) {
      selectAllSection = (
        <CardAlert>
          <span>All Tags on this page selected.</span>
          <CardAlertLink key="select-all-item" onClick={store.selectAllPage}>
            Select all <strong>{store.tagCounts}</strong> Tags.
          </CardAlertLink>
          <CardAlertLink key="clear-selections-link" onClick={store.clearAllSelections}>
            Clear all selections
          </CardAlertLink>
        </CardAlert>
      );
    }

    let tableLeftActionBar = null;
    if (store.isEditing) {
      tableLeftActionBar = (
        <nav className="header-left-nav">
          {store.allowMultipleItemSelection && selectAllOnPageSection}
        </nav>
      );
    }

    const tags = store.tagsView;
    let tableContent = null;
    if (network.loading) {
      tableContent = <LoadingIndicator />;
    } else if (network.error) {
      // TODO: replace this with a more friendly error message
      tableContent = (
        <div className="pt-error-message">
          <h2>Error</h2>
          <pre>
            <code>{JSON.stringify(network.error, null, 2)}</code>
          </pre>
        </div>
      );
    } else if (tags.length === 0) {
      tableContent = (
        <div className="ratecards-table-empty">
          {Object.keys(store.appliedFilters).length > 0 ? (
            <div>No Tags match your current filters</div>
          ) : (
            <div>
              No Tags
              <p>Create a new Tag using the button above or by searching.</p>
            </div>
          )}
        </div>
      );
    } else {
      tableContent = tags.map((tag) => (
        <TagListItem key={tag.tagId} store={store} tag={tag} editing={store.isEditing} />
      ));
    }

    return (
      <div className="ratecards-list">
        {store.createdByFilter && (
          <FilterModal
            filterState={store.createdByFilter}
            innerStyle={{
              width: 550,
            }}
          >
            {store.createdByFilter.network.loading && <LoadingIndicator />}
            {!store.createdByFilter.network.loading && (
              <div>
                <ContainerSection className="header overlap-t-padding">
                  <h4 className="pull-left">Created By</h4>
                  <div className="pull-right">
                    <SortControls filterState={store.createdByFilter} />
                  </div>
                  <div className="clearfix" />
                </ContainerSection>
                <ContainerSection className="no-border">
                  <p>Filter by specific Users:</p>
                  <div>
                    <div className="pull-left">
                      <InstantSearchBox
                        onSearch={store.createdByFilter.onInstantSearch}
                        value={store.createdByFilter.instantSearchValue}
                      />
                    </div>
                    <div
                      className="pull-right rc-center"
                      style={{
                        height: 40,
                      }}
                    >
                      <button className="btn" onClick={store.createdByFilter.onSelectAll}>
                        Select All
                      </button>
                      <button
                        className="btn"
                        onClick={store.createdByFilter.onDeselectAll}
                      >
                        Deselect All
                      </button>
                    </div>
                    <div className="clearfix" />
                  </div>
                </ContainerSection>
                <CreatedByCriteriaList
                  filterState={store.createdByFilter}
                  store={store}
                />
              </div>
            )}
          </FilterModal>
        )}
        {store.sharedFilter && (
          <FilterModal filterState={store.sharedFilter}>
            <div>
              <ContainerSection className="header overlap-t-padding">
                <h4 className="pull-left">Shared</h4>
                <div className="pull-right">
                  <SortControls filterState={store.sharedFilter} />
                </div>
                <div className="clearfix" />
              </ContainerSection>
              <ContainerSection>
                <p>Filter by a specific Sharing State:</p>
                <ListContainer>
                  {store.sharedFilter.viewItems.map((item) => (
                    <ToggleButton
                      key={item.id}
                      large={true}
                      item={item}
                      type="radio"
                      name="shared-state"
                      value={item.value}
                      selected={item.selected}
                      onChange={store.sharedFilter.setSelectedValue}
                    >
                      {item.value}
                    </ToggleButton>
                  ))}
                </ListContainer>
              </ContainerSection>
            </div>
          </FilterModal>
        )}
        {store.createdOnFilter && (
          <FilterModal filterState={store.createdOnFilter}>
            <div>
              <ContainerSection className="header overlap-t-padding">
                <h4 className="pull-left">Created On</h4>
                <div className="pull-right">
                  <SortControls
                    filterState={store.createdOnFilter}
                    sortType="numeric"
                    ascText="Sort Oldest First"
                    descText="Sort Newest First"
                  />
                </div>
                <div className="clearfix" />
              </ContainerSection>
              <ContainerSection>
                <p>Filter by a specific period:</p>
                <div className="pt-range-filter">
                  <div className="pt-range-filter__item">
                    <p>From:</p>
                    <SingleDatePicker
                      id="from_date"
                      date={store.createdOnFilter.fromDate}
                      numberOfMonths={1}
                      isOutsideRange={(day) => day.isAfter(maximumDate)}
                      focused={store.createdOnFilter.fromFocused}
                      onDateChange={store.createdOnFilter.fromDateChange}
                      onFocusChange={store.createdOnFilter.fromFocusedChange}
                    />
                  </div>
                  <div className="pt-range-filter__item">
                    <p>To:</p>
                    <SingleDatePicker
                      id="to_date"
                      date={store.createdOnFilter.toDate}
                      numberOfMonths={1}
                      isOutsideRange={(day) => store.toDateRange(day)}
                      focused={store.createdOnFilter.toFocused}
                      onDateChange={store.createdOnFilter.toDateChange}
                      onFocusChange={store.createdOnFilter.toFocusedChange}
                    />
                  </div>
                </div>
              </ContainerSection>
            </div>
          </FilterModal>
        )}

        <header
          className={classNames("ratecards-table-header", {
            active: store.isFiltered,
          })}
        >
          {tableLeftActionBar}
          <nav className="header-right-nav">
            {store.isFiltered && (
              <div className="btn btn-yellow" onClick={store.clearFilters}>
                Clear All Filters & Sorts
              </div>
            )}
            {store.createdByFilter && (
              <ToggleButton
                borderless={true}
                name="rate-cards-filter"
                value={FILTER_COLUMN.CREATED_BY}
                selected={Boolean(store.appliedFilters[FILTER_COLUMN.CREATED_BY])}
                onClick={store.createdByFilter.onShowModal}
              >
                <SortIndicator
                  sortColumn={FILTER_COLUMN.CREATED_BY}
                  sortsOrder={store.appliedSortsOrder}
                  sort={store.appliedSorts[FILTER_COLUMN.CREATED_BY]}
                />
                Created By
                <span> ▼</span>
              </ToggleButton>
            )}
            {store.sharedFilter && (
              <ToggleButton
                borderless={true}
                name="rate-cards-filter"
                value={FILTER_COLUMN.SHARED}
                selected={Boolean(store.appliedFilters[FILTER_COLUMN.SHARED])}
                onClick={store.sharedFilter.onShowModal}
              >
                <SortIndicator
                  sortColumn={FILTER_COLUMN.SHARED}
                  sortsOrder={store.appliedSortsOrder}
                  sort={store.appliedSorts[FILTER_COLUMN.SHARED]}
                />
                Shared
                <span> ▼</span>
              </ToggleButton>
            )}
            {store.createdOnFilter && (
              <ToggleButton
                borderless={true}
                name="rate-cards-filter"
                value={FILTER_COLUMN.DATE_RANGE}
                selected={Boolean(store.appliedFilters[FILTER_COLUMN.DATE_RANGE])}
                onClick={store.createdOnFilter.onShowModal}
              >
                <SortIndicator
                  sortType="numeric"
                  sortColumn={FILTER_COLUMN.DATE_RANGE}
                  sortsOrder={store.appliedSortsOrder}
                  sort={store.appliedSorts[FILTER_COLUMN.DATE_RANGE]}
                />
                Created On
                <span> ▼</span>
              </ToggleButton>
            )}
          </nav>
        </header>
        <div
          className={classNames("ratecards-table", {
            "ratecards-table-fill-container": fillContainer,
          })}
        >
          <div style={listBodyStyles}>
            {selectAllSection}
            {tableContent}
          </div>
        </div>
        {store.pagination.pageCount > 0 ? (
          <Pagination
            options={{
              variant: "full",
              currentPage: store.pagination.currentPage,
              numPages: store.pagination.pageCount,
            }}
            onPageClick={(pageEvent) => {
              if (pageEvent.type === "first") {
                this.changeCurrent(1);
              }
              if (pageEvent.type === "last") {
                this.changeCurrent(store.pagination.pageCount);
              }
              if (pageEvent.type === "next") {
                this.changeCurrent(
                  Math.min(store.pagination.currentPage + 1, store.pagination.pageCount)
                );
              }
              if (pageEvent.type === "prev") {
                this.changeCurrent(Math.max(store.pagination.currentPage - 1, 1));
              }
              if (pageEvent.type === "page") {
                this.changeCurrent(pageEvent.page);
              }
            }}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

TagManagementList.propTypes = {
  store: PropTypes.object.isRequired,
  fixedHeight: PropTypes.number,
  fillContainer: PropTypes.bool,
};

export default observer(TagManagementList);
