// @flow

import React from "react";
import { observer } from "mobx-react";
import { getPunchOutRowStates } from "./SupportFunctions";
import AnnualPunchOutLevelRow from "./AnnualPunchOutLevelRow";
import BuyRateState from "../../../models/BuyRateState";
import PunchOutRowState from "../../../models/PunchOutRowState";

const AnnualPunchOutsTableView = (props: { buyRateState: BuyRateState }) => {
  const fetchGraphQL = props.buyRateState.fetchGraphQL;
  let search = props.buyRateState.search;
  let buyRate = props.buyRateState.buyRate;
  if (!buyRate) return <code>Unable to render</code>;

  let punchOutRows = buyRate.punchOutRows;

  let { rowStates, blanks, addRowState } = getPunchOutRowStates(
    punchOutRows,
    fetchGraphQL,
    search,
    props
  );

  return (
    <table className="table buy-rate-level-data">
      <thead>
        <tr>
          <th />
          <th className="hidden-md hidden-lg">Rates</th>
          <th className="hidden-xs hidden-sm" />
          <th className="hidden-xs hidden-sm text-right fixed-size">Min Salary</th>
          <th className="hidden-xs hidden-sm nte-column text-right fixed-size">
            NTE Salary
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        {rowStates.map((rowState: PunchOutRowState, index: number) => (
          <AnnualPunchOutLevelRow key={index} modalState={rowState} />
        ))}
        {addRowState && <AnnualPunchOutLevelRow modalState={addRowState} />}
        {blanks.map((item, index) => (
          <tr key={index}>
            <td colSpan="8" className="buy-rate-level-place-holder" />
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default observer(AnnualPunchOutsTableView);
