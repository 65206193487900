// @flow

import React, { Component } from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RegionList from "./components/RegionList";
import SearchBox from "../ratecards/components/SearchBox";
import Modal from "../ratecards/components/Modal";
import ApplyTagFilterModal from "../shared/modals/ApplyTagFilterModal";
import ContainerSection from "../ratecards/components/ContainerSection";
import type MobXStore from "../../stores/mobx/MobXStore";
import SelectableItemsPaginatedList from "../ratecards/components/SelectableItemsPaginatedList";
import LoadingIndicator from "../shared/LoadingIndicator";
import Button from "../../components/lib/Button";
import LinkButton from "../../components/lib/LinkButton";
import Container from "../../components/lib/Container";
import {
  Card,
  CardActions,
  CardActionsLeft,
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubTitle,
  CardActionsRight,
  CardAlert,
  CardAlertLink,
} from "../../components/lib/Card";
import EditActions from "../../components/lib/EditActions";
import Inline from "../../components/lib/Inline";
import TextInput from "../../components/lib/TextInput";

type Props = {
  store: MobXStore,
};

class List extends Component<Props, null> {
  componentDidMount() {
    if (!this.props.store.regionListStore.isTagView) {
      this.props.store.regionListStore.isEditing = null;
    }
  }

  render() {
    const store = this.props.store.regionListStore;

    return (
      <Container
        css={{
          paddingTop: "$4",
          paddingLeft: 0,
          paddingRight: 0,
          width: "100%",
        }}
      >
        <Card css={{ zIndex: 2 }}>
          {!store.isTagView && (
            <CardHeader>
              <CardHeaderTitle>Regions</CardHeaderTitle>
              <CardHeaderSubTitle>
                Manage and create custom regions made up of many locations.
              </CardHeaderSubTitle>
            </CardHeader>
          )}
          <CardActions>
            <CardActionsLeft>
              <SearchBox
                value={store.regionFilter.textToLookFor}
                onChange={store.regionFilter.onTextToLookForChange}
                onSearch={store.regionFilter.onApply}
                style={{
                  width: "260px",
                }}
              />
            </CardActionsLeft>
            <CardActionsRight>
              {store.isEditing && !store.isTagView && (
                <Button color="accent" onClick={store.handleStopEdit}>
                  Stop Editing
                </Button>
              )}
              {!store.isEditing && !store.isTagView && (
                <>
                  <Button color="brand" icon="edit" onClick={store.handleStartEdit}>
                    Edit
                  </Button>
                  <LinkButton color="brand" icon="plus" to="/regions/create">
                    New Region
                  </LinkButton>
                  <Button icon="question-circle" onClick={store.helpModal.showModal}>
                    Help
                  </Button>
                </>
              )}
            </CardActionsRight>
          </CardActions>
          <RegionList store={store} />
        </Card>

        {!store.isTagView && (
          <EditActions hidden={store.isEditing === null} show={Boolean(store.isEditing)}>
            <Button
              icon="edit"
              disabled={store.selectedCount !== 1}
              onClick={store.showRenameModal}
            >
              Rename
            </Button>
            <Button
              disabled={store.selectedCount === 0}
              onClick={store.applyTagState.showTagModal}
            >
              Apply Tag
            </Button>
            <Button
              color="danger"
              icon="trash-alt"
              disabled={store.selectedCount === 0}
              onClick={store.confirmRegionDeletion}
            >
              Delete
            </Button>
          </EditActions>
        )}

        <Modal show={store.helpModal.show} onHide={store.helpModal.hideModal}>
          <div className="container-section header">
            <h4>Help</h4>
          </div>
          <div className="container-section footer">
            <p>Here you can see all of your regions...</p>
          </div>
        </Modal>

        <Modal show={store.errorModal.show} onHide={store.errorModal.hideModal}>
          <div className="container-section header">
            <h4>Error</h4>
          </div>
          <div className="container-section">{store.errorMessage}</div>
          <div className="container-section footer">
            <div className="pull-right">
              <Button onClick={store.errorModal.hideModal}>Close</Button>
            </div>
          </div>
        </Modal>

        <Modal
          show={store.confirmDeleteModal.show}
          onHide={store.confirmDeleteModal.hideModal}
        >
          <div className="container-section header">
            <h4>Confirmation Needed</h4>
          </div>
          <div className="container-section">
            <p>
              Are you sure you want to delete the selected Regions? If you delete the
              selected regions, all rate searches associated with the regions will be
              deleted.
            </p>
            <p className="text-danger">
              <FontAwesomeIcon icon="exclamation-triangle" />{" "}
              <em>This action cannot be undone!</em>
            </p>
            <p>Searches Affected:</p>
            <div
              className="flex-row no-padding text-x-small flex-align-center"
              style={{ color: "#565656", marginBottom: 6 }}
            >
              <div className="text-muted">Title and Region</div>
              <div className="flex-fill" />
              <div className="text-muted">Rate Card</div>
            </div>
            <SelectableItemsPaginatedList
              items={store.searchesAffectedByDeletion}
              itemsPerPage={5}
              loading={store.deleteRegionNetwork.loading}
              loadingView={<LoadingIndicator />}
              noItemsView={
                <CardAlert>
                  <CardAlertLink>No searches will be affected.</CardAlertLink>
                </CardAlert>
              }
              renderItem={(search) => (
                <label
                  key={search.databaseId}
                  className="rc-selectable-item"
                  style={{ cursor: "default" }}
                >
                  <div className="flex-row no-padding text-x-small flex-align-center">
                    <div>
                      <div style={{ color: "#565656" }}>{search.jobLabel}</div>
                      <div>{search.regionName}</div>
                    </div>
                    <div className="flex-fill" />
                    <div>
                      {search.rateCardName || (
                        <em className="text-muted">-- No Rate Card --</em>
                      )}
                    </div>
                  </div>
                </label>
              )}
            />
          </div>
          <div className="container-section footer">
            <Inline css={{ justifyContent: "flex-end", gap: "$2" }}>
              <Button size="large" onClick={store.confirmDeleteModal.hideModal}>
                No, Cancel
              </Button>
              <Button
                size="large"
                color="danger"
                onClick={(e) => {
                  store.confirmDeleteModal.hideModal();
                  store.deleteRegions();
                }}
              >
                Yes, Delete Regions
              </Button>
            </Inline>
          </div>
        </Modal>
        <Modal show={store.renameModal.show} onHide={store.renameModal.hideModal}>
          <ContainerSection className="header">
            <h4>Rename Region</h4>
          </ContainerSection>
          <ContainerSection>
            <p>Name of the Region:</p>
            <TextInput
              fill
              value={store.newRegionName}
              maxLength="250"
              onChange={store.onNewRegionNameChange}
            />
            <div className="pt-user-messages">
              {store.messaging.messages.length > 0 && (
                <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                  <a
                    href="#close"
                    className="close"
                    data-dismiss="alert"
                    aria-label="close"
                    onClick={store.messaging.removeMessage.bind(
                      store,
                      store.messaging.messages[0]
                    )}
                  >
                    &times;
                  </a>
                  <strong>{store.messaging.messages[0].messageLabel}</strong>
                  {store.messaging.messages[0].message}
                </div>
              )}
            </div>
          </ContainerSection>
          <ContainerSection className="footer">
            <Inline css={{ gap: "$2" }}>
              <Button size="large" onClick={store.renameModal.hideModal}>
                Cancel
              </Button>
              <Button color="brand" size="large" onClick={store.renameRegion}>
                Rename Region
              </Button>
            </Inline>
          </ContainerSection>
        </Modal>
        <ApplyTagFilterModal
          filterState={store.applyTagState}
          applyTagsEvent={store.applyTags}
          btnText="Apply Tag to Regions"
        />
      </Container>
    );
  }
}

export default observer(List);
