import React from "react";

import Icon from "../../../../../components/lib/Icon";
import { RatesButton } from "./ButtonStyle";

type Props = {
  disabled: boolean;
  comment: string | null;
  onClick: () => void;
};

export default function RatesFeedbackCommentButton(props: Props) {
  const { disabled, comment, onClick } = props;

  return (
    <RatesButton
      css={{
        backgroundColor: comment ? "$accentLighter" : undefined,
        "&:hover": { backgroundColor: disabled ? undefined : "$accentLight" },
      }}
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
    >
      <Icon icon={["far", "commenting"]} /> {comment ? "Edit" : "Add"} Comment
    </RatesButton>
  );
}

RatesFeedbackCommentButton.displayName = "RatesFeedbackCommentButton";
