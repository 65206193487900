import React from "react";
import Tippy from "@tippyjs/react";

import Text from "../../../../components/lib/Text";

export default class FieldLabel extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.field !== this.props.field || nextProps.isUpdated !== this.props.isUpdated
    );
  }

  static getLabel(obj) {
    const label = obj.label;
    const tooltip = obj.description;
    return tooltip ? (
      <Tippy
        placement="right"
        content={
          <div
            style={{
              maxHeight: "300px",
              overflowY: "auto",
              fontSize: "14px",
              textAlign: "left",
              padding: "5px",
              whiteSpace: "pre-wrap",
              minWidth: "300px",
              maxWidth: "450px",
            }}
            dangerouslySetInnerHTML={{ __html: tooltip }}
          />
        }
        interactive={true}
      >
        <label htmlFor={`${obj.key}-input`} style={{ fontSize: "18px" }}>
          <Text css={{ color: "$brand" }}>{label}</Text>
        </label>
      </Tippy>
    ) : (
      <label htmlFor={`${obj.key}-input`} style={{ fontSize: "18px" }}>
        <Text css={{ color: "$brand" }}>{label}</Text>
      </label>
    );
  }

  render() {
    const { field, isUpdated } = this.props;

    return (
      <span>
        <h4>{FieldLabel.getLabel(field)}</h4>
        {isUpdated && <i>Field updated</i>}
      </span>
    );
  }
}
