import React, { useCallback } from "react";
import queryString from "query-string";

import Icon from "../../../../components/lib/Icon";
import RestfulDatasetListView, {
  DataSourceFunc,
  RenderItemFunc,
} from "./BaseRestfulDatasetListView";
import SelectItemRow from "../../lib/SelectItemRow";
import Tooltip from "../../../../components/lib/Tooltip";
import {
  djangoPaginationKey,
  djangoPaginationSizeKey,
  djangoSearchKey,
} from "../../../../constants";
import { transformUploadsData } from "../../dataConverters";
// @ts-expect-error
import { logAsyncOperationError } from "../../../../utils/logging";
import { emptyList } from "../../../../constants";

import type { UrlQueryObject } from "../../../../components/tables/types";
import type { FetchAPIResponse } from "../../../../types/fetch";
import type { DjangoPaginatedResponse } from "../../../../types/django";
import type { UploadDataMap, UploadDataObject, UploadDataList } from "../../types";
import { useVal5KAdminContext } from "../../context/Val5KAdminContext";

const UPLOAD_TYPES = {
  FROM_FILE: 1,
  FROM_PRIVATE_INDEX: 2,
};

type UploadSelectProps = {
  values: UploadDataList;
  disabled?: boolean;
  onSelect: (item: UploadDataMap) => void;
};

const UploadSelect = (props: UploadSelectProps) => {
  const { values, disabled = false, onSelect } = props;

  const { fetchArgusAPI, showModalError } = useVal5KAdminContext();

  const dataSource: DataSourceFunc<UploadDataList> = useCallback(
    async (search, page, pageSize) => {
      const queryArgs: UrlQueryObject = {};

      if (page) {
        queryArgs[djangoPaginationKey] = page;
      }
      if (pageSize) {
        queryArgs[djangoPaginationSizeKey] = pageSize;
      }
      if (search) {
        queryArgs[djangoSearchKey] = search;
      }

      try {
        const response: FetchAPIResponse<DjangoPaginatedResponse<UploadDataObject>> =
          await fetchArgusAPI(`uploads/`, {
            params: queryString.stringify(queryArgs),
          });

        return transformUploadsData(response.data);
      } catch (err: any) {
        logAsyncOperationError("UploadSelect", err);
        showModalError(`Error occurred while loading upload list data.`);
      }
    },
    [showModalError, fetchArgusAPI]
  );

  const renderItem: RenderItemFunc<UploadDataMap> = useCallback(
    (item) => {
      const itemId = item.get("id");
      const valueIds = values?.size
        ? values.map((i: UploadDataMap) => i.get("id"))
        : emptyList;
      const isSelected = !!(itemId != null && valueIds.size && valueIds.includes(itemId));

      const itemType = item.get("upload_type") || UPLOAD_TYPES.FROM_PRIVATE_INDEX;
      const itemTitle = item.get("title");
      const fileName = item.get("file_name");
      const itemsCount = item.get("dataset_length");
      const value = `#${itemId} - ${itemTitle} (${
        itemType === UPLOAD_TYPES.FROM_PRIVATE_INDEX ? "via Private Index" : fileName
      }, ${itemsCount} surveys)`;

      const component = (
        <SelectItemRow
          selected={isSelected}
          disabled={disabled}
          onClick={() => !disabled && onSelect?.(item)}
        >
          <Icon icon={["far", "file-excel"]} />
          {value}
        </SelectItemRow>
      );

      return disabled ? component : <Tooltip content={value}>{component}</Tooltip>;
    },
    [values, disabled, onSelect]
  );

  return (
    <RestfulDatasetListView
      disabled={disabled}
      dataSource={dataSource}
      renderItem={renderItem}
      searchPlaceholder="search uploads by name"
    />
  );
};
UploadSelect.displayName = "UploadSelect";

export default UploadSelect;
