import React from "react";
import { CardListItem } from "../../../components/lib/Card";
import Text from "../../../components/lib/Text";

export const WorkerTypeCardItem = ({ description }) => {
  return (
    <CardListItem>
      <Text dangerouslySetInnerHTML={{ __html: description }} />
    </CardListItem>
  );
};

const WorkerTypeCard = (props) => {
  const { workerType } = props;

  return (
    <div>
      <span className="icon border">
        <h1 className="pt-card__heading__title">{workerType.label}</h1>
      </span>
      <div className="news-box">
        <WorkerTypeCardItem key={workerType.id} description={workerType.description} />
      </div>
    </div>
  );
};

export default WorkerTypeCard;
