import { createStitches } from "@stitches/react";
import type * as Stitches from "@stitches/react";

const color =
  (h: number, s: number, l: number) =>
  (options?: { lighten?: number; darken?: number; alpha?: number }) => {
    const { lighten, darken, alpha } = options ?? {};
    let newL = l + "%";
    if (lighten) {
      newL = `calc(${l}% + ${lighten}%)`;
    } else if (darken) {
      newL = `calc(${l}% - ${darken}%)`;
    }
    return `hsla(${h}, ${s}%, ${newL}, ${alpha ?? 100})`;
  };

export const RPColors = {
  primary: color(0, 0, 60),
  secondary: color(216, 31, 24),
  background: color(240, 16, 96),
  brand: color(217, 87, 55),
  brandBackground: color(220, 33, 95),
  accent: color(206, 82, 59),
  success: color(150, 61, 43),
  danger: color(0, 84, 60),
  warning: color(50, 81, 54),
  text: color(216, 31, 24),
  textLight: color(0, 0, 100),
};

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  theme: {
    colors: {
      // NOTE: Remove deprecated PT colors
      ptGreen25: "#FBFCF4",
      ptGreen50: "#F6F8E9",
      ptGreen100: "#EDF1D3",
      ptGreen200: "#DBE3A7",
      ptGreen300: "#C9D57B",
      ptGreen400: "#B7C74F",
      ptGreen500: "#A2B624",
      ptGreen600: "#899B21",
      ptGreen700: "#6F811C",
      ptGreen800: "#556717",
      ptGreen900: "#3B4D12",
      ptYellow25: "#FEFDF5",
      ptYellow50: "#FDFAEA",
      ptYellow100: "#FBF5D5",
      ptYellow200: "#F7EBAB",
      ptYellow300: "#F3E181",
      ptYellow400: "#EFD757",
      ptYellow500: "#E9C82C",
      ptYellow600: "#C9AA25",
      ptYellow700: "#AA8C1C",
      ptYellow800: "#8B6E13",
      ptYellow900: "#6C500A",
      ptBlue25: "#F7FCFF",
      ptBlue50: "#EFF9FF",
      ptBlue100: "#DFF3FF",
      ptBlue200: "#BFE7FF",
      ptBlue300: "#9FDBFF",
      ptBlue400: "#7FCFFF",
      ptBlue500: "#5FC5FC",
      ptBlue600: "#47A5D6",
      ptBlue700: "#3083AF",
      ptBlue800: "#196188",
      ptBlue900: "#023F61",
      ptRed25: "#FFF9F9",
      ptRed50: "#FEF2F2",
      ptRed100: "#FEE2E2",
      ptRed200: "#FECACA",
      ptRed300: "#FCA5A5",
      ptRed400: "#F87171",
      ptRed500: "#EF4444",
      ptRed600: "#DC2626",
      ptRed700: "#B91C1C",
      ptRed800: "#991B1B",
      ptRed900: "#7F1D1D",
      ptGray050: "#F8F8FA",
      ptGray100: "#F8F8F9",
      ptGray200: "#F6F6F8",
      ptGray300: "#F4F4F7",
      ptGray400: "#F2F2F6",
      ptGray500: "#E3E3E7",
      ptGray600: "#D4D4D8",
      ptGray700: "#C5C5C9",
      ptGray800: "#B6B6BA",
      ptGray900: "#A7A7AB",
      ptBlack050: "#A7A7AB",
      ptBlack100: "#AFAEAE",
      ptBlack200: "#999999",
      ptBlack300: "#858585",
      ptBlack400: "#717171",
      ptBlack500: "#5D5D5D",
      ptBlack600: "#494949",
      ptBlack700: "#353535",
      ptBlack800: "#212121",
      ptBlack900: "#0D0D0D",
      // END NOTE: Remove deprecated PT colors

      white: "#FFFFFF",
      black: "#000000",

      primary: RPColors.primary(),
      primaryDarkest: RPColors.primary({ darken: 36 }),
      primaryDarker: RPColors.primary({ darken: 24 }),
      primaryDark: RPColors.primary({ darken: 16 }),
      primaryLight: RPColors.primary({ lighten: 25 }),
      primaryLighter: RPColors.primary({ lighten: 35 }),
      primaryLightest: RPColors.primary({ lighten: 37.2 }),
      secondary: RPColors.secondary(),
      secondaryLight: RPColors.secondary({ lighten: 9 }),
      secondaryLighter: RPColors.secondary({ lighten: 36 }),
      secondaryLightest: RPColors.secondary({ lighten: 70 }),
      background: RPColors.background(),
      brand: RPColors.brand(),
      brandBackground: RPColors.brandBackground(),
      brandLight: RPColors.brand({ lighten: 12 }),
      brandLighter: RPColors.brand({ lighten: 36 }),
      brandLightest: RPColors.brand({ lighten: 40 }),
      brandDark: RPColors.brand({ darken: 10 }),
      brandDarker: RPColors.brand({ darken: 18 }),
      accent: RPColors.accent(),
      accentLight: RPColors.accent({ lighten: 18 }),
      accentLighter: RPColors.accent({ lighten: 36 }),
      accentLightest: RPColors.accent({ lighten: 39 }),
      accentDark: RPColors.accent({ darken: 10 }),
      accentDarker: RPColors.accent({ darken: 20 }),
      accentDarkest: RPColors.accent({ darken: 29 }),
      success: RPColors.success(),
      successLight: RPColors.success({ lighten: 15 }),
      successLighter: RPColors.success({ lighten: 30 }),
      successLightest: RPColors.success({ lighten: 45 }),
      successDark: RPColors.success({ darken: 6 }),
      successDarker: RPColors.success({ darken: 11 }),
      successDarkest: RPColors.success({ darken: 15 }),
      danger: RPColors.danger(),
      dangerLight: RPColors.danger({ lighten: 15 }),
      dangerLighter: RPColors.danger({ lighten: 30 }),
      dangerLightest: RPColors.danger({ lighten: 36 }),
      dangerDark: RPColors.danger({ darken: 10 }),
      dangerDarker: RPColors.danger({ darken: 17 }),
      dangerDarkest: RPColors.danger({ darken: 24 }),
      warning: RPColors.warning(),
      warningLight: RPColors.warning({ lighten: 18 }),
      warningLighter: RPColors.warning({ lighten: 36 }),
      warningLightest: RPColors.warning({ lighten: 42 }),
      warningDark: RPColors.warning({ darken: 8 }),
      warningDarker: RPColors.warning({ darken: 18 }),
      warningDarkest: RPColors.warning({ darken: 25 }),
      text: RPColors.text(),
      textLight: RPColors.textLight(),

      levelI: "hsl(204deg 82% 31%)",
      levelII: "hsl(326deg 50% 35%)",
      levelIII: "hsl(148deg 50% 31%)",
      levelIV: "hsl(282deg 47% 30%)",
      levelV: "hsl(288deg 57% 14%)",

      chart1: "$brandDark",
      chart2: "$accent",
      chart3: "$levelII",
      chart4: "$levelIII",
      chart5: "$levelIV",
    },
    fontWeights: {
      thin: "100",
      extralight: "200",
      light: "300",
      normal: "400",
      medium: "500",
      semibold: "600",
      bold: "700",
      extrabold: "800",
      black: "900",
    },
    fontSizes: {
      "2xs": ".55rem",
      xs: ".75rem",
      sm: ".875rem",
      base: "1rem",
      lg: "1.125rem",
      xl: "1.25rem",
      "2xl": "1.5rem",
      "3xl": "1.875rem",
      "4xl": "2.25rem",
      "5xl": "3rem",
      "6xl": "3.75rem",
      "7xl": "4.5rem",
      "8xl": "6rem",
      "9xl": "8rem",
    },
    lineHeights: {
      xs: "1rem",
      sm: "1.25rem",
      base: "1.5rem",
      lg: "1.75rem",
      xl: "1.75rem",
      "2xl": "2rem",
      "3xl": "2.25rem",
      "4xl": "2.5rem",
      "5xl": "3rem",
      "6xl": "1",
      "7xl": "1",
      "8xl": "1",
      "9xl": "1",
    },
    sizes: {
      // applies to:
      // width, height, min-width, max-width, min-height, max-height
      0: "0px",
      px: "1px",
      "0_5": "0.125rem",
      "1": "0.25rem",
      "1_5": "0.375rem",
      "2": "0.5rem",
      "2_5": "0.625rem",
      "3": "0.75rem",
      "3_5": "0.875rem",
      "4": "1rem",
      "5": "1.25rem",
      "6": "1.5rem",
      "7": "1.75rem",
      "8": "2rem",
      "9": "2.25rem",
      "10": "2.5rem",
      "11": "2.75rem",
      "12": "3rem",
      "14": "3.5rem",
      "16": "4rem",
      "20": "5rem",
      "24": "6rem",
      "28": "7rem",
      "32": "8rem",
      "36": "9rem",
      "40": "10rem",
      "44": "11rem",
      "48": "12rem",
      "52": "13rem",
      "56": "14rem",
      "60": "15rem",
      "64": "16rem",
      "68": "17rem",
      "72": "18rem",
      "80": "20rem",
      "96": "24rem",
      "1-of-2": "50%",
      "1-of-3": "33.333333%",
      "2-of-3": "66.666667%",
      "1-of-4": "25%",
      "2-of-4": "50%",
      "3-of-4": "75%",
      "1-of-5": "20%",
      "2-of-5": "40%",
      "3-of-5": "60%",
      "4-of-5": "80%",
      "1-of-6": "16.666667%",
      "2-of-6": "33.333333%",
      "3-of-6": "50%",
      "4-of-6": "66.666667%",
      "5-of-6": "83.333333%",
      "1-of-12": "8.333333%",
      "2-of-12": "16.666667%",
      "3-of-12": "25%",
      "4-of-12": "33.333333%",
      "5-of-12": "41.666667%",
      "6-of-12": "50%",
      "7-of-12": "58.333333%",
      "8-of-12": "66.666667%",
      "9-of-12": "25%",
      "10-of-12": "83.333333%",
      "11-of-12": "91.666667%",
      full: "100%",
      screen: "100vw",
      auto: "auto",
      min: "min-content",
      max: "max-content",
      screenMax: "1440px",
    },
    space: {
      // applies to:
      // margin, margin-top, margin-right, margin-bottom, margin-left,
      // padding, padding-top, padding-right, padding-bottom, padding-left,
      // grid-gap, grid-column-gap, grid-row-gap
      "0": "0px",
      px: "1px",
      "0_5": "0.125rem",
      "1": "0.25rem",
      "1_5": "0.375rem",
      "2": "0.5rem",
      "2_5": "0.625rem",
      "3": "0.75rem",
      "3_5": "0.875rem",
      "4": "1rem",
      "5": "1.25rem",
      "6": "1.5rem",
      "7": "1.75rem",
      "8": "2rem",
      "9": "2.25rem",
      "10": "2.5rem",
      "11": "2.75rem",
      "12": "3rem",
      "14": "3.5rem",
      "16": "4rem",
      "20": "5rem",
      "24": "6rem",
      "28": "7rem",
      "32": "8rem",
      "36": "9rem",
      "40": "10rem",
      "44": "11rem",
      "48": "12rem",
      "52": "13rem",
      "56": "14rem",
      "60": "15rem",
      "64": "16rem",
      "72": "18rem",
      "80": "20rem",
      "96": "24rem",
      auto: "auto",
    },
    radii: {
      // applies to:
      // border-radius
      none: "0px",
      sm: "0.125rem",
      rounded: "0.25rem",
      md: "0.375rem",
      lg: "0.5rem",
      xl: "0.75rem",
      "2xl": "1rem",
      "3xl": "1.5rem",
      full: "9999px",
    },
    shadows: {
      sm: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      default: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
      md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
      lg: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
      xl: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
      "2xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
      "3xl": "0 35px 60px -15px rgba(0, 0, 0, 0.3)",
      inner: "inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)",
      none: "none",
    },
    zIndices: {
      "0": 0,
      "10": 10,
      "20": 20,
      "30": 30,
      "40": 40,
      "50": 50,
      "25": 25,
      "75": 75,
      "100": 100,
      auto: "auto",
    },
  },
  media: {
    sm: "(min-width: 640px)",
    md: "(min-width: 768px)",
    lg: "(min-width: 1024px)",
    xl: "(min-width: 1280px)",
    "2xl": "(min-width: 1536px)",
  },
});

export type CSS = Stitches.CSS<typeof config>;
