import { useEffect, MutableRefObject, useRef } from "react";

/**
 * Hook
 */
export function useArrowNav(
  menuRef: MutableRefObject<HTMLElement | null>,
  triggerRef?: MutableRefObject<HTMLElement | null>,
  enable: boolean = true
) {
  const activeRef = useRef<HTMLElement | null>(null);

  function focusCurrent(event: KeyboardEvent) {
    if (activeRef.current) {
      activeRef.current.focus();
      if (activeRef.current.firstChild) {
        const currentChild = activeRef.current.firstChild as HTMLElement;
        if (currentChild.nodeName === "BUTTON") {
          currentChild.focus() as any;
        }
        event.preventDefault();
      }
    }
  }

  useEffect(() => {
    function onNavigate(event: KeyboardEvent) {
      if (!enable) return;

      if (menuRef.current && event.key === "ArrowDown") {
        if (!activeRef.current) {
          activeRef.current = menuRef.current.firstChild as HTMLElement;
          focusCurrent(event);
          return;
        }
        if (activeRef.current) {
          activeRef.current = activeRef.current.nextSibling as HTMLElement;
          focusCurrent(event);
        }
      }

      if (menuRef.current && event.key === "ArrowUp") {
        if (!activeRef.current) {
          activeRef.current = menuRef.current.firstChild as HTMLElement;
          focusCurrent(event);
          return;
        }
        if (activeRef.current) {
          if (activeRef.current.previousSibling) {
            activeRef.current = activeRef.current.previousSibling as HTMLElement;
            focusCurrent(event);
          } else {
            triggerRef?.current?.focus();
            activeRef.current = null;
          }
        }
      }
    }
    // Bind the event listener
    document.addEventListener("keydown", onNavigate);

    // Unbind the event listener on clean up
    return () => {
      document.removeEventListener("keydown", onNavigate);
    };
  }, [menuRef, triggerRef, enable]);
}
