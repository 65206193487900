// @flow

export class Permissions {
  allowExportRateCardsData: boolean;

  constructor(store, object) {
    this.store = store;
    // set defaults
    this.allowExportRateCardsData = true;

    // set real values
    if (object) {
      this.allowExportRateCardsData = object.allowExportRateCardsData;
    }
  }
}

export default class CurrentUser {
  store: Object;
  id: string;
  legacy_id: string;
  username: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  isAdmin: boolean;
  permissions: Permissions;
  roles: Array;

  constructor(store, object) {
    this.store = store;
    this.id = object.id || object.userId;
    this.legacyId = object.legacyId || object.userId;
    this.username = object.username;
    this.firstName = object.firstName;
    this.lastName = object.lastName;
    this.isActive = object.isActive;
    this.isAdmin = object.isAdmin;
    this.userId = object.userId;
    this.roles = object.roles;
    this.permissions = new Permissions(store, object.permissions);
  }
}
