import Text from "../../../components/lib/Text";
import { styled } from "../../../stitches.config";

// TODO get rid of this component in a favor of <Text as="something">

const BiggerText = styled(Text, {
  fontSize: "$base",
});

export default BiggerText;
