/**
 * @generated SignedSource<<006276cf1c4ad771f70d5b369a3a1b91>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CopyUserRegionsInput = {
  clientMutationId?: string | null;
  fromUserId: number;
  toUserId: number;
};
export type CopyRegionsWizardModalMutation$variables = {
  input: CopyUserRegionsInput;
};
export type CopyRegionsWizardModalMutation$data = {
  readonly copyUserRegions: {
    readonly errors: ReadonlyArray<{
      readonly __typename: "NoPermissionError";
      readonly message: string | null;
    } | {
      readonly __typename: "UserDoesNotExistsError";
      readonly message: string | null;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null> | null;
    readonly ok: boolean | null;
  } | null;
};
export type CopyRegionsWizardModalMutation = {
  response: CopyRegionsWizardModalMutation$data;
  variables: CopyRegionsWizardModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ok",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v5 = [
  (v3/*: any*/),
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CopyRegionsWizardModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CopyUserRegionsPayload",
        "kind": "LinkedField",
        "name": "copyUserRegions",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": (v5/*: any*/),
                "type": "NoPermissionError",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v5/*: any*/),
                "type": "UserDoesNotExistsError",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CopyRegionsWizardModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CopyUserRegionsPayload",
        "kind": "LinkedField",
        "name": "copyUserRegions",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v4/*: any*/)
                ],
                "type": "NoPermissionError",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "type": "UserDoesNotExistsError",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7409c594a2e0329f6139847458983a56",
    "id": null,
    "metadata": {},
    "name": "CopyRegionsWizardModalMutation",
    "operationKind": "mutation",
    "text": "mutation CopyRegionsWizardModalMutation(\n  $input: CopyUserRegionsInput!\n) {\n  copyUserRegions(input: $input) {\n    ok\n    errors {\n      __typename\n      ... on NoPermissionError {\n        __typename\n        message\n      }\n      ... on UserDoesNotExistsError {\n        __typename\n        message\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "02ea8b150062488292da42a3f5c62019";

export default node;
