import React from "react";

import JobFeedbackDetailsBlock, {
  type JobFeedbackDetailsBlockProps,
} from "./JobFeedbackDetailsBlock";

import RatesTableOrderedWithFeedbackPreview, {
  type RatesTableOrderedWithFeedbackPreviewProps,
} from "../RatesTableOrdered/RatesTableOrderedWithFeedbackPreview";

type OrderedDetailsContentProps = JobFeedbackDetailsBlockProps &
  RatesTableOrderedWithFeedbackPreviewProps;

export default function OrderedDetailsContent(props: OrderedDetailsContentProps) {
  const {
    title,
    countriesString,
    currencyCode,
    currencyName,
    industries,
    rateTypeLabel,
    validatorString,
    validationTimestamp,
    questions,
    questionsFeedback,
    jobsData,
    feedbackData,
    shortMode,
  } = props;

  return (
    <JobFeedbackDetailsBlock
      title={title}
      countriesString={countriesString}
      currencyCode={currencyCode}
      currencyName={currencyName}
      industries={industries}
      rateTypeLabel={rateTypeLabel}
      validatorString={validatorString}
      validationTimestamp={validationTimestamp}
      questions={questions}
      questionsFeedback={questionsFeedback}
    >
      <RatesTableOrderedWithFeedbackPreview
        jobsData={jobsData}
        feedbackData={feedbackData}
        shortMode={shortMode}
      />
    </JobFeedbackDetailsBlock>
  );
}

OrderedDetailsContent.displayName = "OrderedDetailsContent";
