import React from "react";
import ContainerSection from "../ratecards/components/ContainerSection";
import FilterModal from "../ratecards/components/FilterModal";
import LoadingIndicator from "../shared/LoadingIndicator";

const PayTypeFilter = ({ payTypeFilter, network, payTypeList }) => {
  return (
    <FilterModal title="Worksheet Type" filterState={payTypeFilter}>
      {network && <LoadingIndicator />}
      {!network && (
        <ContainerSection>
          <p>Filter by a specific Pay Type:</p>
          {payTypeList}
        </ContainerSection>
      )}
    </FilterModal>
  );
};

export default PayTypeFilter;
