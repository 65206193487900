// @flow
import { extendObservable, action, runInAction } from "mobx";
import axios from "axios";
import NetworkState from "../../../models/NetworkState";
import ModalState from "../../../models/ModalState";
import ScheduledSearchList from "../../../models/ScheduledSearchList";
import MessageState from "../../../models/MessageState";
import Industry from "../../../models/Industry";
import { browserHistory } from "react-router";
import type MobXStore from "../MobXStore";
import type { FetchGraphQL } from "../../../App";

class ScheduledSearchDetailStore {
  router: Object;
  network: NetworkState;
  showHelpModal: boolean;
  industryNetwork: NetworkState;
  confirmDeleteModal: ModalState;
  schSearchDetail: ScheduledSearchList;
  messaging: MessageState;
  searchId: number;
  industries: Array<any>;
  fetchSearchDetails: () => void;
  fetchIndustries: () => void;
  showHelp: () => void;
  hideHelp: () => void;
  rerun: () => void;
  localStorageDetails: String;
  fetchGraphQL: FetchGraphQL;
  store: MobXStore;

  constructor(fetchGraphQL: FetchGraphQL, store: MobXStore) {
    this.fetchGraphQL = fetchGraphQL;
    this.store = store;
    this.router = null;

    extendObservable(this, {
      searchId: null,
      schSearchDetail: null,
      showHelpModal: false,
      network: new NetworkState(),
      industryNetwork: new NetworkState(),
      industries: Array,
      localStorageDetails: "",
    });
    this.fetchSearchDetails = action(this.fetchSearchDetails.bind(this));
    this.fetchIndustries = action(this.fetchIndustries.bind(this));
    this.showHelp = action(this.showHelp.bind(this));
    this.hideHelp = action(this.hideHelp.bind(this));
    this.rerun = action(this.rerun.bind(this));
  }

  showHelp() {
    this.showHelpModal = true;
  }

  hideHelp() {
    this.showHelpModal = false;
  }

  rerun(uuid) {
    this.searchId = uuid;
    this.fetchIndustries();
  }

  async fetchIndustries() {
    let res = null;
    const query = `
      query industries{
       viewer{
         industries{
           edges{
             node{
               legacyId
               value
             }
           }
         }
       }
      }
    `;

    this.industryNetwork.error = null;
    this.industryNetwork.loading = true;

    try {
      res = await this.fetchGraphQL(query, null);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.industryNetwork.handleError("Getting Industries", e);
      if (res !== null) {
        this.industryNetwork.logGraphQLError("Get Industries query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    runInAction("fetchIndustries--success", () => {
      this.industryNetwork.loading = false;
      this.industryNetwork.error = null;

      if (this.industryNetwork.logGraphQLError("Get Industries query", res)) {
        // TODO: Display user friendly error message
        return;
      }
      const industriesData = res.data.viewer.industries.edges;
      this.industries = industriesData.map((industry) => {
        const list = new Industry(this, industry.node);
        return list;
      });
      this.fetchSearchDetails();
      return {
        totalCount: res.data.viewer.industries.edges.length,
      };
    });
  }

  async fetchSearchDetails() {
    let res = null;
    if (!/^\d+$/.test(this.searchId)) {
      if (this.router) {
        this.router.push({
          pathname: "/404NotFound",
          query: this.router.query,
        });
      }
      return res;
    }

    const variables = {
      scheduledSearchUuid: this.searchId,
    };

    const query = `
      query scheduledSearchDetail($scheduledSearchUuid: String!) {
        viewer {
          scheduledSearchDetail (scheduledSearchUuid: $scheduledSearchUuid){
            rateType
            rateCardLabel
            jobs {
              titleId
              industryId
              jobDescription
              jobLabel
              jobCategory
              jobTitle
            }
            locationIds
            regionIds
           }
         }
        }
        `;

    this.network.error = null;
    this.network.loading = true;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting Scheduled Search Detail", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Scheduled Search query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    runInAction("fetchSearchDetails--success", () => {
      this.network.loading = false;
      this.network.error = null;

      if (this.network.logGraphQLError("Get Scheduled Search Detail query", res)) {
        // TODO: Display user friendly error message
        return;
      }
      if (!res.data.viewer.scheduledSearchDetail) {
        if (this.router) {
          this.router.push({
            pathname: "/404NotFound",
            query: this.router.query,
          });
        }
        return;
      }
      this.schSearchDetail = new ScheduledSearchList(
        this,
        res.data.viewer.scheduledSearchDetail
      );
      const createStore = this.store.scheduledSearchCreateStore;
      var rateTypes = {
        HOURLY: "Hourly",
        ANNUAL: "Annual",
      };
      createStore.rateType = rateTypes[this.schSearchDetail.rateTypeValue];
      createStore.selectedRateCard = this.schSearchDetail.rateCardLabel;

      const findIndustry = (industry) => industry.id === det.industryId;
      for (var i = 0; i < this.schSearchDetail.jobs.length; i++) {
        var det = this.schSearchDetail.jobs[i];
        var ind = [];

        ind.push(this.industries.find(findIndustry));
        createStore.addSearch(
          this.schSearchDetail.rateCardLabel,
          det.titleId,
          det.jobTitle,
          det.jobLabel,
          det.jobDescription,
          ind,
          rateTypes[this.schSearchDetail.rateTypeValue]
        );
      }

      browserHistory.push("/scheduled_search/create");
    });
  }
}

export default ScheduledSearchDetailStore;
