import React from "react";
import { styled } from "../../../../stitches.config";
// lib
import Stack from "../../../../components/lib/Stack";
import Inline from "../../../../components/lib/Inline";
import Text from "../../../../components/lib/Text";
import HiringDifficultyChart from "./HiringDifficultyChart";

export const Card = styled(Stack, {
  alignItems: "start",
  justifyContent: "space-between",
  height: "156px",
  padding: "$6",
  borderTop: "solid 1px $primaryLight",
  "@md": {
    borderTop: "none",
    borderRight: "solid 1px $primaryLight",
  },
  "&:first-child": {
    borderTop: "none",
    "@md": {
      paddingLeft: 0,
    },
  },
  "&:last-child": {
    "@md": {
      paddingRight: 0,
      borderRight: 0,
    },
  },
});

export const CardHeading = styled(Inline, {
  flexBasis: "1em !important",
  flexGrow: "0 !important",
  gap: "$2",
});

export const CardContent = styled(Inline, {
  alignItems: "flex-end",
  flexGrow: "0 !important",
  flexBasis: "50px !important",
  overflow: "hidden",
});

const DifficultyLabel = styled("div", {
  flex: 1,
  "@lg": {
    flex: 2,
  },
});

const DifficultyChart = styled("div", {
  flex: 2,
  // flexGrow: "0 !important",
  alignSelf: "flex-start",
});

const CardLabel = styled("div", {
  display: "inline-block",
  padding: "$1 $8",
  textAlign: "center",
  width: "auto",
  borderRadius: "$rounded",
  backgroundColor: "$primaryLight",
});

type HiringDifficultyCardProps = {
  title: string;
  hiringDifficulty: number;
  difficultyLabel: string;
};

export function HiringDifficultyCard(props: HiringDifficultyCardProps) {
  const { title, hiringDifficulty, difficultyLabel } = props;

  return (
    <Card>
      <CardHeading fill nowrap>
        <Text color="primary">{title}</Text>
      </CardHeading>
      <CardContent fill nowrap>
        <DifficultyLabel>
          <CardLabel>
            <Text color="dark">{difficultyLabel ? difficultyLabel : "No Label"}</Text>
          </CardLabel>
        </DifficultyLabel>
        <DifficultyChart>
          <HiringDifficultyChart hiringDifficulty={hiringDifficulty} />
        </DifficultyChart>
      </CardContent>
    </Card>
  );
}
