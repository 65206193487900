import React from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SelectableItemsList from "../ratecards/components/SelectableItemsList";
import SelectableItem from "../ratecards/components/SelectableItem";
import ContainerSection from "../ratecards/components/ContainerSection";
import FilterModal, { ModalTitle } from "../ratecards/components/FilterModal";
import LoadingIndicator from "../shared/LoadingIndicator";
import InstantSearchBox from "../ratecards/components/InstantSearchBox";
import CheckBox from "../shared/CheckBox";
import Button from "../../components/lib/Button";
import Spacer from "../../components/lib/Spacer";
import Inline from "../../components/lib/Inline";

const LocationCriteriaList = observer((props) => (
  <div>
    <SelectableItemsList className="overlap-tb-padding">
      {props.filterState.viewItems.map((item, i) => (
        <SelectableItem
          key={item.id}
          item={item}
          name="location-filter"
          value={item.location}
          selected={item.selected}
          onChange={props.filterState.setSelectedValue}
        >
          <div title={item.location}>{item.location}</div>
          <div className="text-x-small">
            <em>
              <FontAwesomeIcon icon="map-marker" className="icon no-margin" /> {item.type}
            </em>
          </div>
        </SelectableItem>
      ))}
    </SelectableItemsList>
    <ContainerSection className="info">
      <div className="pull-left">
        <CheckBox
          type="checkbox"
          selected={props.filterState.strictCountries}
          onChange={props.filterState.toggleStrictCountries}
          style={{ maxWidth: 300 }}
        >
          <div className="text-small">Exclude Descendant Locations of Country</div>
          <div className="text-x-small text-muted">
            <em>
              <FontAwesomeIcon icon="info-circle" className="icon no-margin" />
              If enabled, searches made in a state or city of a selected country will be
              excluded.
            </em>
          </div>
        </CheckBox>
      </div>
      <p className="text-muted text-x-small no-margin pull-right">
        <em>
          <strong>{props.filterState.selectedValuesCount} </strong>
          selected, <strong>{props.filterState.totalValuesCount} </strong>
          total
        </em>
      </p>
      <div className="clearfix" />
    </ContainerSection>
  </div>
));

const LocationFilter = ({ location, network, onInstantSearch, instantSearchValue }) => {
  return (
    <FilterModal filterState={location}>
      {network && <LoadingIndicator />}
      {!network && (
        <div>
          <ContainerSection className="header overlap-t-padding">
            <ModalTitle className="pull-left">Location</ModalTitle>
            {/*<div className="pull-right">*/}
            {/*  <SortControls filterState={location} />*/}
            {/*</div>*/}
            <div className="clearfix" />
          </ContainerSection>
          <ContainerSection className="no-border">
            <p>Filter by specific Location:</p>
            <Inline css={{ gap: "$2" }}>
              <InstantSearchBox onSearch={onInstantSearch} value={instantSearchValue} />
              <Spacer />
              <Button onClick={location.onSelectAll}>Select All</Button>
              <Button onClick={location.onDeselectAll}>Deselect All</Button>
            </Inline>
          </ContainerSection>
          <LocationCriteriaList filterState={location} />
        </div>
      )}
    </FilterModal>
  );
};

export default LocationFilter;
