import React from "react";

import PlainHighChart from "../../../views/ratecards/components/PlainHighChart";
import {
  RATE_TYPE_OPTIONS,
  SALARY_RATE_TYPE_OPTIONS,
} from "../../../constants/negotiationWorksheet";
import * as filter from "../utils/filter";
import fieldValueGetter from "../utils/fieldValueGetter";

import { Card } from "../../../components/material";

export default class GroupChart extends React.Component {
  state = {
    options: {
      title: {
        text: this.props.group.label,
      },
      chart: {
        type: "pie",
        backgroundColor: null,
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
      },
      legend: {
        enabled: true,
        floating: true,
        width: 280,
        layout: "vertical",
        align: "right",
        verticalAlign: "bottom",
        backgroundColor: "#fdfdfd",
        borderColor: "#eeeeee",
        useHTML: true,
        itemStyle: { lineHeight: "14px" },
        labelFormat: `<span style="font-size:10px;">{name}: {y:.2f}% / ${this.props.store.worksheetSchema.currencySymbol}{price:.2f}</span>`,
      },
      tooltip: {
        borderColor: "#eeeeee",
        backgroundColor: "#fdfdfd",
        shadow: false,
        headerFormat: "",
        pointFormat: `<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> / ${this.props.store.worksheetSchema.currencySymbol}{point.price:.2f}<br/>`,
      },
      series: [
        {
          type: "pie",
        },
      ],
      plotOptions: {
        pie: {
          cursor: "pointer",
          center: ["15%", "50%"],
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      },
      credits: {
        enabled: false,
      },
    },
  };

  getPieChartSalaryValues(props, valueType = "markup") {
    const { store, group, payRateHourly } = props;
    const { fields, conversionOptions } = store.worksheetSchema;
    const getDollarValue = (value) =>
      SALARY_RATE_TYPE_OPTIONS.convertFromHourly(
        (value / 100) * payRateHourly,
        conversionOptions
      );
    const getFieldValue = fieldValueGetter(
      store.currentState.unsavedValues,
      store.values
    );

    if (store.checkLegacyStd() && store.apiStateId) {
      return (
        fields
          .filter((field) => group.fields.includes(field.id))
          // .filter(rec => rec.states.includes(parseInt(store.apiStateId, 10)))
          .filter(filter.all(filter.rateTypeContains("salary")))
          .reduce((data, field) => {
            const value = getFieldValue(field);

            if (value !== undefined) {
              data.push({
                y: value,
                price: getDollarValue(value),
                name: field.label,
              });
            }

            return data;
          }, [])
      );
    } else
      return fields
        .filter((field) => group.fields.includes(field.id))
        .filter(filter.all(filter.rateTypeContains("salary")))
        .reduce((data, field) => {
          const value = getFieldValue(field);

          if (value !== undefined) {
            data.push({
              y: value,
              price: getDollarValue(value),
              name: field.label,
            });
          }

          return data;
        }, []);
  }

  getPieChartValues(props, valueType = "markup") {
    const { store, group, payRateHourly } = props;
    const workerTypeId = store.selectedWorkerType.id;
    const rateType = store.currentState.rateType;
    const { fields, conversionOptions } = store.worksheetSchema;
    const getDollarValue = (value) =>
      RATE_TYPE_OPTIONS[rateType].convertFromHourly(
        (value / 100) * payRateHourly,
        conversionOptions
      );
    const getFieldValue = fieldValueGetter(
      store.currentState.unsavedValues,
      store.values
    );

    if (store.checkLegacyStd() && store.apiStateId) {
      return (
        fields
          .filter((field) => group.fields.includes(field.id))
          // .filter(rec => rec.states.includes(parseInt(store.apiStateId, 10)))
          .filter(filter.all(filter.notNull, filter.workerTypesContains(workerTypeId)))
          .reduce((data, field) => {
            const value = getFieldValue(field);

            if (value !== undefined) {
              data.push({
                y: value,
                price: getDollarValue(value),
                name: field.label,
              });
            }
            return data;
          }, [])
      );
    } else
      return fields
        .filter((field) => group.fields.includes(field.id))
        .filter(filter.all(filter.notNull, filter.workerTypesContains(workerTypeId)))
        .reduce((data, field) => {
          const value = getFieldValue(field);

          if (value !== undefined) {
            data.push({
              y: value,
              price: getDollarValue(value),
              name: field.label,
            });
          }
          return data;
        }, []);
  }

  render() {
    const { store } = this.props;
    var values =
      store.currentState.payType === 3
        ? this.getPieChartSalaryValues(this.props)
        : this.getPieChartValues(this.props);

    values = values.filter(function (n) {
      return n != null;
    });

    if (values.length < 2) return null;

    return (
      <Card>
        <div className="pt-worksheet__group-chart__chart">
          <PlainHighChart
            style={{ width: "100%", height: "300px" }}
            options={{
              ...this.state.options,
              ...{ series: [{ type: "pie", data: values }] },
            }}
          />
        </div>
      </Card>
    );
  }
}
