import React from "react";

import {
  TableHeadCellImpl,
  TableHeadCellImplFilterable,
  TableHeadCellImplSortable,
  TableHeadRowImpl,
} from "../impl/TableHeadImpl";
import ClearFiltersButton from "../impl/ClearFiltersButton";
import { djangoOrderingKey, emptyFilter, OrderDirections } from "../constants";
import { emptyMap, emptyOrderedMap } from "../../../constants";

import type {
  ColumnsFiltersConfigOrderedMap,
  ColumnsFiltersQueriesOrderedMap,
  FilterConfigMap,
  FiltersConfigOrderedMap,
  FiltersQueryOrderedMap,
  OrderConfigMap,
  FiltersDataProviderRestful,
  RowIdGetterFunction,
  SchemaConfigObject,
  TransformRestfulFiltersFunction,
  DataProvider,
  OnClearFiltersFunction,
  ColumnConfigObject,
  RowData,
} from "../types";
import type { OnApplyFilterFunction } from "../impl/types";

type TableHeadProps<RD = RowData> = {
  schema: SchemaConfigObject<RD>;
};

export function TableHead<RD = RowData>(props: TableHeadProps<RD>) {
  return (
    <TableHeadRowImpl>
      {props.schema.columns.map((config: ColumnConfigObject<RD>) => {
        let { uniqueKey, title, headCss } = config;

        if (typeof title === "function") title = title();

        return (
          <TableHeadCellImpl key={uniqueKey} css={headCss}>
            {title}
          </TableHeadCellImpl>
        );
      })}
    </TableHeadRowImpl>
  );
}

TableHead.displayName = "TableHead";

interface TableHeadSortableProps<RD = RowData> extends TableHeadProps<RD> {
  order: OrderConfigMap;
  onApplyOrder?: (uniqueKey: string) => void;
}

export function TableHeadSortable<RD = RowData>(props: TableHeadSortableProps<RD>) {
  const { schema, order, onApplyOrder } = props;

  return (
    <TableHeadRowImpl>
      {schema.columns.map((config: ColumnConfigObject<RD>) => {
        let { uniqueKey, title, sortable, headCss } = config;

        const orderDirection =
          sortable && order !== emptyMap && order.get("key") === uniqueKey
            ? order.get("direction", OrderDirections.NO)
            : OrderDirections.NO;

        if (typeof title === "function") title = title();

        return (
          <TableHeadCellImplSortable
            key={uniqueKey}
            css={headCss}
            sortable={sortable}
            orderDirection={orderDirection}
            onClick={sortable && onApplyOrder ? () => onApplyOrder(uniqueKey) : undefined}
          >
            {title}
          </TableHeadCellImplSortable>
        );
      })}
    </TableHeadRowImpl>
  );
}

TableHeadSortable.displayName = "TableHeadSortable";

interface TableHeadFilterableProps<RD = RowData> extends TableHeadProps<RD> {
  multimode: boolean;
  rowIdGetter: RowIdGetterFunction<RD>;
  //
  filters: FiltersConfigOrderedMap;
  columnsFilters: ColumnsFiltersConfigOrderedMap;
  dataProvider: DataProvider<RD>;
  //
  onApplyFilter: OnApplyFilterFunction;
  onClearFilters: OnClearFiltersFunction;
}

export function TableHeadFilterable<RD = RowData>(props: TableHeadFilterableProps<RD>) {
  const {
    schema,
    multimode,
    rowIdGetter,
    dataProvider,
    filters,
    columnsFilters: allColumnsFilters,
    onApplyFilter,
    onClearFilters,
  } = props;

  return (
    <TableHeadRowImpl>
      {schema.columns.map((config: ColumnConfigObject<RD>, idx: number) => {
        let {
          uniqueKey,
          title,
          getter,
          sortable,
          filterable,
          filterType,
          filterKey,
          filterOptions,
          filterExtraProps,
          comparators,
          popupSize,
          headCss,
        } = config;
        const filter: FilterConfigMap = filters.get(
          uniqueKey,
          emptyFilter as unknown as FilterConfigMap
        );
        const columnFilters: FiltersConfigOrderedMap = allColumnsFilters.get(
          uniqueKey,
          emptyOrderedMap as unknown as FiltersConfigOrderedMap
        );
        const filterIdx: number = filters
          .filter(
            (value: FilterConfigMap) =>
              !!value && value.getIn(["filter", "values"]).size > 0
          )
          .keySeq()
          .indexOf(uniqueKey);

        if (typeof title === "function") title = title();

        return (
          <TableHeadCellImplFilterable
            key={uniqueKey}
            uniqueKey={uniqueKey}
            filterType={filterType}
            filterKey={filterKey}
            filterOptions={filterOptions}
            filterExtraProps={filterExtraProps}
            sortable={sortable}
            filterable={filterable}
            getter={getter}
            idGetter={rowIdGetter}
            comparators={comparators}
            dataProvider={dataProvider}
            dataProviderFilters={columnFilters}
            filter={filter}
            filterIdx={multimode ? filterIdx : undefined}
            onApplyFilter={onApplyFilter}
            popupSize={popupSize}
            css={headCss}
          >
            {filters.size > 0 && multimode && idx === 0 && (
              <ClearFiltersButton
                onClick={onClearFilters as React.MouseEventHandler<HTMLButtonElement>}
              />
            )}
            {title}
          </TableHeadCellImplFilterable>
        );
      })}
    </TableHeadRowImpl>
  );
}

TableHeadFilterable.displayName = "TableHeadFilterable";

interface TableHeadFilterableRestfulProps<RD = RowData> extends TableHeadProps<RD> {
  rowIdGetter: RowIdGetterFunction<RD>;
  multimode: boolean;
  //
  filters: FiltersConfigOrderedMap;
  filtersQuery: FiltersQueryOrderedMap;
  columnsFiltersQueries: ColumnsFiltersQueriesOrderedMap;
  filtersDataProvider: FiltersDataProviderRestful;
  transformFilters: TransformRestfulFiltersFunction;
  //
  onApplyFilter: OnApplyFilterFunction;
  onClearFilters: OnClearFiltersFunction;
}

export function TableHeadFilterableRestful<RD = RowData>(
  props: TableHeadFilterableRestfulProps<RD>
) {
  let {
    filters,
    filtersQuery,
    columnsFiltersQueries,
    filtersDataProvider,
    transformFilters,
  } = props;
  const { schema, rowIdGetter, multimode, onApplyFilter, onClearFilters } = props;

  // columnsFiltersQueries is important here, otherwise UI filters will be inconsistent
  if (
    filters &&
    filters.size &&
    (filtersQuery == null ||
      columnsFiltersQueries == null ||
      !filtersQuery.size ||
      !columnsFiltersQueries.size)
  ) {
    [filtersQuery, columnsFiltersQueries] = transformFilters(filters, multimode);
  }

  return (
    <TableHeadRowImpl>
      {schema.columns.map((config: ColumnConfigObject<RD>, idx: number) => {
        let {
          uniqueKey,
          title,
          getter,
          filterable,
          sortable,
          filterType,
          filterKey,
          filterOptions,
          filterExtraProps,
          comparators,
          popupSize,
          headCss,
        } = config;
        const filter = filters.get(uniqueKey, emptyFilter);
        const filterIdx = filters
          .filter(
            (item?: FilterConfigMap) =>
              !!item && item.getIn(["filter", "values"]).size > 0
          )
          .keySeq()
          .indexOf(uniqueKey);
        let columnFiltersQuery = columnsFiltersQueries.get(uniqueKey);

        // apply whole filters set except ordering
        if (!columnFiltersQuery) {
          columnFiltersQuery = filtersQuery.filter((_, key) => key !== djangoOrderingKey);
        }

        if (typeof title === "function") {
          title = title();
        }

        return (
          <TableHeadCellImplFilterable
            key={uniqueKey}
            uniqueKey={uniqueKey}
            filterType={filterType}
            filterKey={filterKey}
            filterIdx={multimode ? filterIdx : undefined}
            filterOptions={filterOptions}
            filterExtraProps={filterExtraProps}
            filterable={filterable}
            sortable={sortable}
            getter={getter}
            idGetter={rowIdGetter}
            comparators={comparators}
            filter={filter}
            dataProvider={filtersDataProvider}
            dataProviderFilters={columnFiltersQuery}
            onApplyFilter={onApplyFilter}
            popupSize={popupSize}
            css={headCss}
            restful
          >
            {filters.size > 0 && multimode && idx === 0 && (
              <ClearFiltersButton onClick={onClearFilters} />
            )}
            {title}
          </TableHeadCellImplFilterable>
        );
      })}
    </TableHeadRowImpl>
  );
}

TableHeadFilterableRestful.displayName = "TableHeadFilterableRestful";
