import { action } from "mobx";
import moment from "moment";

export default class SavedSearch {
  constructor(store, object) {
    this.store = store;
    this.searchId = null;
    this.job = null;
    this.locationFullLabel = null;
    this.city = null;
    this.state = null;
    this.country = null;
    this.region = null;
    this.rates = null;
    this.rateType = null;
    this.marketRate = null;
    this.marketChanges = null;
    this.buyRates = null;
    this.industry = null;
    this.owner = null;
    // this.searches = null;
    this.createdDate = null;
    this.createdDisplay = null;
    this.symbol = "$";
    this.tags = {};
    this.isGSS = false;
    this.viewState = {
      selected: false,
      editing: false,
    };

    if (object) {
      this.searchId = object.searchId || null;
      this.marketRate = object.marketRate || null;
      this.marketChanges = object.marketchanges || null;
      this.buyRates = object.buyrates || null;
      this.locationFullLabel = object.locationFullLabel || null;
      this.city = object.city || null;
      this.state = object.state || null;
      this.country = object.country || null;
      this.region = object.region || null;
      this.rateType = object.rateType || null;
      this.rates = object.rates || null;
      this.job = {
        jobLabel: object.job.jobLabel || null,
        jobTitle: object.job.jobTitle || null,
      };
      this.symbol = object.currency ? object.currency.symbol : "$";
      this.industry = object.industry || null;
      if (object.createdDate) {
        this.createdDate = moment(object.createdDate);
        this.createdDisplay = `Created ${this.createdDate.format("MMMM D, YYYY")}`;
      }
      this.recentUpdated = this.createdDate
        ? `${this.createdDate.format("YYYY/MM/DD")}`
        : null;
      this.tags = object.tags;
      this.owner = object.createdBy || {};
      this.isGSS = object.isGlobalSupplierSearch || false;
      this.workerTypeName = object.workerTypeName || null;
    }

    this.toggleEdit = action(this.toggleEdit.bind(this));
    this.toggleSelected = action(this.toggleSelected.bind(this));
    this.destroy = action(this.destroy.bind(this));
  }

  toggleEdit() {
    const viewState = this.store.rateCardsViewState.get(this.searchId);
    viewState.editing = !viewState.editing;
  }

  toggleSelected(e, ignore1 = null, ignore2 = null, setValue = null) {
    const viewState = this.store.rateCardsViewState.get(this.searchId);

    if (setValue !== null) {
      viewState.selected = setValue;
      return;
    } else {
      viewState.selected = !viewState.selected;

      if (!viewState.selected && this.store.allSelected) {
        this.store.allSelected = false;
      }
    }

    if (viewState.selected === false) {
      this.store.unSelectedSearches.push(this.searchId.toString());
    } else {
      for (var i = 0; i < this.store.unSelectedSearches.length; i++) {
        if (this.store.unSelectedSearches[i] === this.searchId) {
          this.store.unSelectedSearches.splice(i, 1);
        }
      }
    }

    if (!this.store.allowMultipleItemSelection) {
      // deselect all other rate cards
      this.store.rateCardsViewState.forEach((viewState) => {
        if (this.viewState === viewState) return;

        viewState.selected = false;
      });
    }
  }

  destroy() {
    this.store.rateCards.remove(this);
  }

  toJS() {
    return {
      searchId: this.searchId,
    };
  }

  static fromJS(store, object) {
    return new SavedSearch(store, object);
  }
}
