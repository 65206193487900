import { action } from "mobx";

export default class ClientList {
  constructor(store, object) {
    this.store = store;
    this.id = null;
    this.name = null;
    this.searchLimit = null;
    this.active = null;
    this.limitedSearchFlag = null;
    this.shareRatecardsFlag = null;
    this.cid = null;
    this.editionCode = null;
    this.termsOfAgreement = null;
    this.freeTrial = null;
    this.byUserTermsFlag = null;
    this.encodedClientId = null;
    this.isClientJobLibrary = false;
    this.isRefreshEligible = true;
    this.perSearchPricing = false;
    this.totalSearchesCount = 0;
    this.totalJobLebelsCount = 0;
    this.totalRatecardsCount = 0;
    this.totalProjectsCount = 0;
    this.totalNegotiationWorksheetsCount = 0;
    this.totalActiveUserCount = 0;
    this.totalInactiveUserCount = 0;
    this.totalUserCount = 0;
    this.hasThreeLevel = false;
    this.hasNumericLevelsOnExport = false;
    this.viewState = {
      selected: false,
      editing: false,
    };

    if (object) {
      this.id = object.clientId || null;
      this.name = object.name || "";
      this.searchLimit = object.searchLimit || 0;
      this.active = object.activeFlag || false;
      this.isRefreshEligible = object.refreshEligible ?? true;
      this.limitedSearchFlag = object.limitedSearchFlag || false;
      this.shareRatecardsFlag = object.shareRatecardsFlag || false;
      this.cid = object.cid || "";
      this.editionCode = object.editionCode || "";
      this.termsOfAgreement = object.termsOfAgreement || false;
      this.freeTrial = object.freeTrial;
      this.byUserTermsFlag = object.byUserTermsFlag || false;
      this.encodedClientId = object.encodedClientId || null;
      this.isClientJobLibrary = object.isClientJobLibrary || false;
      this.perSearchPricing = object.perSearchPricing || false;
      this.totalSearchesCount = object.totalSearchesCount || 0;
      this.totalJobLebelsCount = object.totalJobLabelsCount || 0;
      this.totalRatecardsCount = object.totalRatecardsCount || 0;
      this.totalProjectsCount = object.totalProjectsCount || 0;
      this.totalNegotiationWorksheetsCount = object.totalNegotiationWorksheetsCount || 0;
      this.totalUserCount = object.totalUserCount || 0;
      this.totalActiveUserCount = object.totalActiveUserCount || 0;
      this.totalInactiveUserCount = object.totalInactiveUserCount || 0;
      this.hasThreeLevel = object.hasThreeLevel || false;
      this.hasNumericLevelsOnExport = object.hasNumericLevelsOnExport || false;
    }

    this.toggleEdit = action(this.toggleEdit.bind(this));
    this.toggleSelected = action(this.toggleSelected.bind(this));
    this.destroy = action(this.destroy.bind(this));
  }

  toggleEdit() {
    const viewState = this.store.clientsListViewState.get(this.id);
    viewState.editing = !viewState.editing;
  }

  toggleSelected(e, ignore1 = null, ignore2 = null, setValue = null) {
    const viewState = this.store.clientsListViewState.get(this.id);

    if (setValue !== null) {
      viewState.selected = setValue;
      return;
    } else {
      viewState.selected = !viewState.selected;

      if (!viewState.selected && this.store.allSelected) {
        this.store.allSelected = false;
      }
    }

    if (viewState.selected === false) {
      this.store.unSelectedClients.push(this.id.toString());
    } else {
      for (var i = 0; i < this.store.unSelectedClients.length; i++) {
        if (this.store.unSelectedClients[i] === this.id) {
          this.store.unSelectedClients.splice(i, 1);
        }
      }
    }

    if (!this.store.allowMultipleItemSelection) {
      // deselect all other jobs
      this.store.clientsListViewState.forEach((viewState) => {
        if (this.viewState === viewState) return;

        viewState.selected = false;
      });
    }
  }

  destroy() {
    this.store.clientLists.remove(this);
  }

  toJS() {
    return {
      id: this.id,
      name: this.name,
    };
  }

  static fromJS(store, object) {
    return new ClientList(store, object);
  }
}
