// @flow

import { action, extendObservable, observable, runInAction } from "mobx";
import axios from "axios";
import JobLibrary from "../../models/JobLibrary";
import NetworkState from "../../models/NetworkState";
import type { PageQuery, PaginationInfo } from "../../models/PaginationState";
import { CursorPaginationState } from "../../models/PaginationState";
import type { FilterColumn } from "../../models/Filter";
import FilterObject, { FILTER_COLUMN } from "../../models/Filter";
import Sort, { SORT_DIRECTION } from "../../models/Sort";
import ModalState from "../../models/ModalState";
import {
  consolidateAppliedFilters,
  consolidateAppliedSorts,
  createTasteGraphQLWrapper,
} from "./SupportFunctions";
import type { GraphQLQuery } from "../../models/GraphQL";
import ClientLibraryNameFilter from "../../models/FilterState/ClientLibraryNameFilter";
import ClientLibraryClientRawJobTitleFilter from "../../models/FilterState/ClientLibraryClientRawJobTitleFilter";
import FilterOptionProps from "../../views/job_library/supportClasses/FilterOptionProps";
import SessionInfo from "../../models/SessionInfo";
import type MobXStore from "./MobXStore";
import type { FetchAPI, FetchGraphQL } from "../../App";

export type CountryLibraryNode = {
  id: number,
  databaseId: number,
  title: string,
  iso3166Alpha2: string,
  totalTitles: { totalCount: number },
  clientJobTitles: { totalCount: number },
};

const clientLibrariesCriteriaQuery = `
query {
  clientLibraries {
    edges {
      node {
        databaseId
        name
      }
    }
  }
}
`;

export const LIBRARY_DISPLAY_OPTIONS = {
  LIBRARY: "library",
  COUNTRY_LIBRARY: "country_library",
};

export class JobLibraryListComponentStore {
  fetchTasteGraphQL: FetchGraphQL;
  fetchGraphQL: FetchGraphQL;
  fetchAPI: FetchAPI;
  mobXStore: MobXStore;
  sessionInfo: ?SessionInfo;
  network: NetworkState;
  countryLibrariesNetwork: NetworkState;
  pagination: CursorPaginationState;

  router: Object;

  countryLibraries: CountryLibraryNode[];
  clientLibraries: JobLibrary[];
  clientLibraryNameFilter: ClientLibraryNameFilter;
  clientLibraryClientRawJobTitleFilter: ClientLibraryClientRawJobTitleFilter;
  defaultFilters: { [key: FilterColumn]: FilterObject };
  appliedFilters: { [key: FilterColumn]: FilterObject };
  appliedSorts: { [key: FilterColumn]: Sort };
  appliedSortsOrder: Array<FilterColumn>;
  isFiltered: boolean;
  resultsForText: string;

  getClientLibraries: (PageQuery) => Promise<PaginationInfo>;
  getCountryLibraries: () => Promise<any>;
  applyDefaultFilter: (FilterColumn, FilterObject) => void;
  searchFilterOptions: FilterOptionProps[];
  searchSelectedFilterOption: FilterOptionProps;
  onSearchFilterMyLibraries: () => void;
  onSearchFilterSubLibraries: () => void;
  onSearchFilterAllLibraries: () => void;
  applyHardCodedFilters: (string[], string[], string[], any) => void;
  myLibrariesOnly: boolean;
  subLibrariesOnly: boolean;
  libraryDisplayValue: string;

  getFilterCriteriaQuery: (FilterColumn) => GraphQLQuery;
  processFilterCriteria: (FilterColumn, Object) => Array<Object>;
  applyFilter: (FilterColumn, FilterObject) => void;
  removeFilter: (FilterColumn) => void;
  applySort: (FilterColumn, Sort) => void;
  removeSort: (FilterColumn) => void;
  filterClientLibraries: () => void;
  clearFilters: () => void;
  applyDefaultSort: () => void;
  handleLibraryDisplayValue: (SyntheticEvent<HTMLInputElement>) => void;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI, mobXStore: MobXStore) {
    this.fetchTasteGraphQL = createTasteGraphQLWrapper(fetchAPI);
    this.fetchGraphQL = fetchGraphQL;
    this.fetchAPI = fetchAPI;
    this.mobXStore = mobXStore;
    this.sessionInfo = null; // set by component on mount

    // NOTE: Bound early to pass into pagination & filter state
    this.getClientLibraries = action(this.getClientLibraries.bind(this));
    this.getCountryLibraries = action(this.getCountryLibraries.bind(this));

    this.getFilterCriteriaQuery = action(this.getFilterCriteriaQuery.bind(this));
    this.processFilterCriteria = action(this.processFilterCriteria.bind(this));
    this.applyFilter = action(this.applyFilter.bind(this));
    this.applySort = action(this.applySort.bind(this));
    this.removeFilter = action(this.removeFilter.bind(this));
    this.removeSort = action(this.removeSort.bind(this));
    this.onSearchFilterMyLibraries = action(this.onSearchFilterMyLibraries.bind(this));
    this.onSearchFilterSubLibraries = action(this.onSearchFilterSubLibraries.bind(this));
    this.onSearchFilterAllLibraries = action(this.onSearchFilterAllLibraries.bind(this));
    this.handleLibraryDisplayValue = action(this.handleLibraryDisplayValue.bind(this));

    const searchFilterOptions = [
      new FilterOptionProps("1", "All Libraries", this.onSearchFilterAllLibraries),
      new FilterOptionProps("2", "Sub-Libraries", this.onSearchFilterSubLibraries),
      new FilterOptionProps("3", "My Libraries", this.onSearchFilterMyLibraries),
    ];

    extendObservable(this, {
      network: new NetworkState(),
      countryLibrariesNetwork: new NetworkState(),
      pagination: new CursorPaginationState(this.getClientLibraries),
      resultsForText: "",
      clientLibraries: [],
      countryLibraries: [],
      isFiltered: false,
      defaultFilters: {},
      appliedFilters: {},
      appliedSorts: {},
      appliedSortsOrder: observable.shallow([]),
      searchFilterOptions: searchFilterOptions,
      searchSelectedFilterOption: searchFilterOptions[0],
      myLibrariesOnly: false,
      subLibrariesOnly: false,
      libraryDisplayValue: LIBRARY_DISPLAY_OPTIONS.LIBRARY,
      clientLibraryNameFilter: new ClientLibraryNameFilter(
        this,
        FILTER_COLUMN.CLIENT_LIBRARY_NAME,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      clientLibraryClientRawJobTitleFilter: new ClientLibraryClientRawJobTitleFilter(
        this,
        FILTER_COLUMN.CLIENT_LIBRARY_CLIENT_TITLE,
        this.applyFilter,
        this.removeFilter
      ),
    });

    this.filterClientLibraries = action(this.filterClientLibraries.bind(this));
    this.clearFilters = action(this.clearFilters.bind(this));
    this.applyDefaultSort = action(this.applyDefaultSort.bind(this));
    this.applyHardCodedFilters = action(this.applyHardCodedFilters.bind(this));

    this.applyDefaultSort();
  }

  onSearchFilterMyLibraries() {
    this.myLibrariesOnly = true;
    this.subLibrariesOnly = false;
    this.searchSelectedFilterOption = this.searchFilterOptions[2];
    this.pagination.goFetch();
  }

  onSearchFilterSubLibraries() {
    this.myLibrariesOnly = false;
    this.subLibrariesOnly = true;
    this.searchSelectedFilterOption = this.searchFilterOptions[1];
    this.pagination.goFetch();
  }

  onSearchFilterAllLibraries() {
    this.myLibrariesOnly = false;
    this.subLibrariesOnly = false;
    this.searchSelectedFilterOption = this.searchFilterOptions[0];
    this.pagination.goFetch();
  }

  applyDefaultSort() {
    this.clientLibraryNameFilter.sortState.direction = SORT_DIRECTION.ASC;
    this.clientLibraryNameFilter.sort = this.clientLibraryNameFilter.buildQuerySort();
    if (this.clientLibraryNameFilter.sort)
      this.applySort(
        this.clientLibraryNameFilter.column,
        this.clientLibraryNameFilter.sort
      );
  }

  getFilterCriteriaQuery(column: FilterColumn): GraphQLQuery {
    switch (column) {
      case FILTER_COLUMN.CLIENT_JOB_TITLE_IN_ANY_LIBRARY:
        return {
          query: clientLibrariesCriteriaQuery,
          variables: {},
        };

      default:
        return null;
    }
  }

  processFilterCriteria(column: FilterColumn, payload: Object): ?Array<Object> {
    switch (column) {
      case FILTER_COLUMN.CLIENT_JOB_TITLE_IN_ANY_LIBRARY:
        const libraries: [{ node: { databaseId: string, name: string } }] =
          payload.data.clientLibraries.edges;
        let processedLibraries = observable.map({});
        libraries.forEach((library) => {
          processedLibraries.set(String(library.node.databaseId), {
            id: String(library.node.databaseId),
            ...library.node,
          });
        });

        return processedLibraries;

      default:
        return null;
    }
  }

  handleLibraryDisplayValue(e: SyntheticEvent<HTMLInputElement>) {
    this.libraryDisplayValue = e.currentTarget.value;
  }

  applyFilter(column: FilterColumn, filter: FilterObject) {
    this.appliedFilters[column] = filter;
    this.isFiltered = true;
  }

  applyDefaultFilter(column: FilterColumn, filter: FilterObject) {
    this.defaultFilters[column] = filter;
  }

  removeFilter(column: FilterColumn) {
    delete this.appliedFilters[column];

    let entries = Object.entries(this.appliedFilters);
    if (!entries.length) this.isFiltered = false;
  }

  applySort(column: FilterColumn, sort: Sort) {
    this.appliedSorts[column] = sort;

    const index = this.appliedSortsOrder.indexOf(column);
    if (index === -1) this.appliedSortsOrder.push(column);
  }

  removeSort(column: FilterColumn) {
    delete this.appliedSorts[column];

    const index = this.appliedSortsOrder.indexOf(column);
    if (index > -1) this.appliedSortsOrder.splice(index, 1);
  }

  clearFilters() {
    this.clientLibraryNameFilter = new ClientLibraryNameFilter(
      this,
      FILTER_COLUMN.CLIENT_LIBRARY_NAME,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.clientLibraryClientRawJobTitleFilter = new ClientLibraryClientRawJobTitleFilter(
      this,
      FILTER_COLUMN.CLIENT_LIBRARY_CLIENT_TITLE,
      this.applyFilter,
      this.removeFilter
    );

    this.appliedFilters = observable({});
    this.appliedSorts = observable({});
    this.appliedSortsOrder.length = 0;
    this.isFiltered = false;

    return this.pagination.goFetch();
  }

  filterClientLibraries() {
    return this.pagination.goFetch();
  }

  applyHardCodedFilters(
    params: string[],
    filtersCriteria: string[],
    titlesFiltersCriteria: string[],
    variables: Object
  ) {
    if (this.clientLibraryClientRawJobTitleFilter.textToLookFor) {
      // this is for getting the totalCount on the titles in the library that match the query
      params.push("$clientTitleFilterText: String!");
      titlesFiltersCriteria.push("clientRawTitleIContains: $clientTitleFilterText");
      variables.clientTitleFilterText =
        this.clientLibraryClientRawJobTitleFilter.textToLookFor;
    }

    if (this.myLibrariesOnly && this.sessionInfo) {
      const userId = this.sessionInfo.user ? this.sessionInfo.user.userId : 0;

      params.push("$createdBy: [ID]!");
      filtersCriteria.push("createdByLegacyId: $createdBy");
      variables.createdBy = [userId];
    }

    if (this.subLibrariesOnly) {
      params.push("$systemLibrary: Boolean!");
      filtersCriteria.push("systemLibrary: $systemLibrary");
      variables.systemLibrary = false;
    }
  }

  async getClientLibraries(pageQuery: PageQuery): Promise<PaginationInfo> {
    let params: string[] = pageQuery.params;
    let args = pageQuery.args;
    let variables = pageQuery.variables;
    let filtersCriteria: string[] = [];
    let titlesFiltersCriteria: string[] = [];
    let query = {};

    let sortCriteria: string[] = [];
    consolidateAppliedSorts(this.appliedSorts, sortCriteria);

    consolidateAppliedFilters(this.appliedFilters, params, filtersCriteria, variables);

    // NOTE: applied filters could override default filters, handle this if needed
    consolidateAppliedFilters(this.defaultFilters, params, filtersCriteria, variables);

    this.applyHardCodedFilters(params, filtersCriteria, titlesFiltersCriteria, variables);

    // NOTE: exclude argument "first" to get all libraries for now
    const queryParams = params.filter((a) => !a.includes("first")).join(", ");
    const queryArgs = args.filter((a) => !a.includes("first")).join(", ");
    const queryFiltersCriteria = filtersCriteria.join(", ");
    const queryTitlesFiltersCriteria = titlesFiltersCriteria.join(", ");
    const querySortCriteria = sortCriteria.join(", ");

    query.query = `
      query getClientLibraries${queryParams ? "(" : ""}${queryParams}${
      queryParams ? ")" : ""
    } {
        clientLibraries(${queryArgs}${
      queryArgs ? "," : ""
    } filters: { ${queryFiltersCriteria} }, order: [${querySortCriteria}]) {
          totalCount
          pageInfo {
            startCursor
            endCursor
          }
          edges {
            node {
              ...library
            }
          }
        }
      }

      fragment library on ClientLibraryNode {
        id
        databaseId
        created
        modified
        name
        description
        systemLibrary
        isMainLibrary
        logoUrl
        totalTitleByCountryCertifications
        totalTitleBySearchableCountries
        totalTitles: clientJobTitles(first: 0) {
          totalCount
        }
        clientJobTitles(first: 0, filters: { ${queryTitlesFiltersCriteria} }) {
          totalCount
        }
        createdBy {
          id
          databaseId
          userId
        }
      }
    `;
    query.variables = variables;

    if (this.network.loading === true) {
      // TODO: figure out a way to cancel
      // this.tasteApi.cancelTokenSource.cancel();
      return { totalCount: 0, startCursor: "", endCursor: "" };
    }

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchTasteGraphQL(query.query, query.variables);
    } catch (e) {
      if (axios.isCancel(e)) return e;

      this.network.handleError("Getting Client Libraries", e);

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getClientLibraries--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get Client Libraries query", res)) {
        // TODO: Display user friendly error message
        return { totalCount: 0, startCursor: "", endCursor: "" };
      }

      if (!res) {
        return { totalCount: 0, startCursor: "", endCursor: "" };
      }

      // this.currentUser = new CurrentUser(this, res.data.currentUser);
      if (this.isFiltered && this.clientLibraryClientRawJobTitleFilter.textToLookFor) {
        // set default client job titles filter
        const libraryClientTitleFilter = this.clientLibraryClientRawJobTitleFilter;
        const clientJobTitleTitleFilter =
          this.mobXStore.jobLibraryTitleListStore.clientJobTitleTitleFilter;

        clientJobTitleTitleFilter.textToLookFor = libraryClientTitleFilter.textToLookFor;
        clientJobTitleTitleFilter.filter = clientJobTitleTitleFilter.buildQueryFilter();
        if (clientJobTitleTitleFilter.filter) {
          clientJobTitleTitleFilter.applyFilter(
            clientJobTitleTitleFilter.column,
            clientJobTitleTitleFilter.filter
          );
          // this line must go after applying the filter
          this.mobXStore.jobLibraryTitleListStore.defaultToLibraryFilter = true;
        }

        // set results text
        this.resultsForText = this.clientLibraryClientRawJobTitleFilter.textToLookFor;
      } else {
        this.resultsForText = "";
      }

      const libraries = res.data.clientLibraries.edges;

      this.clientLibraries = libraries.map((library) => {
        return new JobLibrary(this, library.node);
      });

      return {
        totalCount: res.data.clientLibraries.totalCount,
        startCursor: res.data.clientLibraries.pageInfo.startCursor,
        endCursor: res.data.clientLibraries.pageInfo.endCursor,
      };
    });
  }

  async getCountryLibraries(): Promise<any> {
    let params: string[] = [];
    let filtersCriteria: string[] = [];
    let titlesFiltersCriteria: string[] = [];
    let variables = {};

    this.applyHardCodedFilters(params, filtersCriteria, titlesFiltersCriteria, variables);

    const hasParams = params && params.length > 0;
    const queryParams = params.join(", ");
    const queryTitlesFiltersCriteria = titlesFiltersCriteria.join(", ");

    const query = { query: "", variables: variables };
    query.query = `
      query getCountryLibraries${hasParams ? "(" + queryParams + ")" : ""} {
        countryLibraries {
          totalCount
          edges {
            node {
              id
              databaseId
              title
              iso3166Alpha2
              totalTitles: clientJobTitles(first: 0) {
                totalCount
              }
              clientJobTitles(first: 0, filters: { ${queryTitlesFiltersCriteria} }) {
                totalCount
              }
            }
          }
        }
      }
    `;
    query.variables = variables;

    if (this.countryLibrariesNetwork.loading === true) {
      // TODO: figure out a way to cancel
      // this.tasteApi.cancelTokenSource.cancel();
      return { totalCount: 0, startCursor: "", endCursor: "" };
    }

    this.countryLibrariesNetwork.loading = true;
    let res = null;

    try {
      res = await this.fetchTasteGraphQL(query.query, query.variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.countryLibrariesNetwork.handleError("Getting Country Libraries", e);
      if (res !== null) {
        this.countryLibrariesNetwork.logGraphQLError("Get Country Libraries query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getCountryLibraries--success", () => {
      this.countryLibrariesNetwork.loading = false;
      this.countryLibrariesNetwork.error = null;
      if (
        this.countryLibrariesNetwork.logGraphQLError("Get Country Libraries query", res)
      ) {
        // TODO: Display user friendly error message
        return { totalCount: 0, startCursor: "", endCursor: "" };
      }

      if (!res || !res.data) return null;

      const libraries = res.data.countryLibraries.edges;

      const countryLibraries = libraries.map((library) => {
        return library.node;
      });

      const sortedByJobCount = countryLibraries.sort((a, b) => {
        const jobOrder = b.totalTitles.totalCount - a.totalTitles.totalCount;
        if (jobOrder === 0) {
          const aTitle = a.title.toLowerCase();
          const bTitle = b.title.toLowerCase();
          return aTitle < bTitle ? -1 : aTitle > bTitle ? 1 : 0;
        }
        return jobOrder;
      });

      if (this.isFiltered) {
        this.countryLibraries = sortedByJobCount.filter(
          (library) => library.clientJobTitles && library.clientJobTitles.totalCount > 0
        );
      } else {
        this.countryLibraries = sortedByJobCount;
      }
    });
  }
}

export default class JobLibraryListStore extends JobLibraryListComponentStore {
  helpModal: ModalState;
  requestChangesModal: ModalState;
  confirmRemoveClientJobTitle: ModalState;
  confirmDeleteLibraryModal: ModalState;
  goBatchSearchModal: ModalState;
  selectedRateType: string;
  selectedRateTypeError: ?string;
  selectedIndustry: number;
  selectedIndustryError: ?string;
  validateBatchSearch: () => boolean;

  // exportModalState: ExportOptionsState;

  exportSelectedLibrary: (string) => void;
  exportSelectedLibraryCertifiedJobs: (string) => void;
  deleteClientLibrary: (string) => void;
  currentUserIsOwnerOfLibrary: (number) => Boolean;
  updateLibraryLogo: (number, ?Object) => void;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI, mobXStore: MobXStore) {
    super(fetchGraphQL, fetchAPI, mobXStore);

    extendObservable(this, {
      helpModal: new ModalState(),
      requestChangesModal: new ModalState(),
      confirmRemoveClientJobTitle: new ModalState(),
      confirmDeleteLibraryModal: new ModalState(),
      goBatchSearchModal: new ModalState(),
      selectedRateType: "1",
      selectedRateTypeError: null,
      selectedIndustry: 1,
      selectedIndustryError: null,
      // exportModal: new ExportOptionsState(this.exportLibrary, this.validateExportOptions),
    });

    this.validateBatchSearch = action(this.validateBatchSearch.bind(this));
    this.deleteClientLibrary = action(this.deleteClientLibrary.bind(this));
    this.exportSelectedLibrary = action(this.exportSelectedLibrary.bind(this));
    this.exportSelectedLibraryCertifiedJobs = action(
      this.exportSelectedLibraryCertifiedJobs.bind(this)
    );
    this.currentUserIsOwnerOfLibrary = action(
      this.currentUserIsOwnerOfLibrary.bind(this)
    );
    this.updateLibraryLogo = action(this.updateLibraryLogo.bind(this));
  }

  validateBatchSearch(): boolean {
    // TODO: Enable this when BatchSearchCreateStore is working
    let valid = true;
    // const batchSearchCreateStore = this.mobXStore.batchSearchCreateStore;
    // const isCountryLibrary = this.libraryDisplayValue === LIBRARY_DISPLAY_OPTIONS.COUNTRY_LIBRARY;
    //
    // if (!batchSearchCreateStore.selectedRateType) {
    //   this.selectedRateTypeError = "This field is required. Please select a Rate Type.";
    //   valid = false;
    // } else {
    //   this.selectedRateTypeError = null;
    //   console.log("rate type valid");
    // }
    //
    // if (isCountryLibrary && !batchSearchCreateStore.selectedIndustry) {
    //   this.selectedIndustryError = "This field is required. Please select an Industry.";
    //   valid = false;
    // } else {
    //   console.log("industry valid");
    //   this.selectedIndustryError = null;
    // }

    return valid;
  }

  currentUserIsOwnerOfLibrary(libraryId: number) {
    const libraries = this.clientLibraries;
    let library = null;
    for (let i = 0; i < libraries.length; i++) {
      if (libraries[i].id === libraryId) {
        library = libraries[i];
        break;
      }
    }

    if (!library) return false;

    const userId =
      this.sessionInfo && this.sessionInfo.user ? this.sessionInfo.user.userId : null;

    if (!userId) return false;

    if (library.createdBy) {
      return library.createdBy.legacyId === userId;
    }

    return false;
  }

  async deleteClientLibrary(libraryId: string) {
    if (this.network.loading) {
      return;
    }

    let vars = {
      libraryId: libraryId,
    };

    let query = {};

    query.operationName = "DeleteCustomLibrary";
    query.query = `
      mutation DeleteCustomLibrary($libraryId: ID!){
        deleteCustomLibrary(input: {
            clientMutationId: "",
            libraryId: $libraryId
          }) {

          success {
            message
          }

          errors {
            __typename
            ...on DoesNotExistError {
              message
            }
            ...on NotAllowedForCurrentUserError {
              details
              message
            }
          }
        }
      }
    `;
    query.variables = vars;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchTasteGraphQL(query.query, query.variables);
    } catch (e) {
      this.network.handleError("Deleting client library", e);
      // TODO: Display user friendly error message
      return;
    }

    runInAction("deleteClientLibrary--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("deleteClientLibrary", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.pagination.goFetch();
    });
  }

  tasteExport = (tasteURL: string) => {
    window.location = `${this.mobXStore.apiServerUrl}/api/taste/library/export${tasteURL}`;
  };

  exportSelectedLibrary(libraryId: string) {
    if (this.network.loading) {
      return;
    }

    this.tasteExport(`/api/v1/client_library/export/${libraryId}/`);
  }

  exportSelectedLibraryCertifiedJobs(libraryId: string) {
    if (this.network.loading) {
      return;
    }

    this.tasteExport(`/api/v1/client_library/certified_jobs/export/${libraryId}/`);
  }

  async updateLibraryLogo(libraryId: number, logoFile: ?Object) {
    if (this.network.loading) {
      // NOTE: Debounces submitRequest
      return;
    }

    const library = this.clientLibraries.find((lib) => lib.id === libraryId);

    if (!library) return;

    // query.operationName = 'requestLibrary';
    const query = `
      mutation updateLibrary($libraryId: ID!){
        updateClientLibraryLogo(input: {
            clientMutationId: "",
            libraryId: $libraryId
          }) {
          success {
            message
          }

          library {
            logoUrl
          }

          errors {
            __typename
            ...on ErrorBase {
              message
            }
          }
        }
      }
    `;

    const variables = {
      libraryId: library.id,
    };

    let formData = new FormData();

    formData.append("operationName", "updateLibrary");
    formData.append("query", query);
    formData.append("variables", JSON.stringify(variables));
    if (logoFile)
      // not sending the file will reset the logo
      formData.append("logoFile", logoFile);

    this.network.loading = true;
    let res: ?Object = null;

    try {
      res = await this.fetchAPI("taste/", formData).then((r) => r.data);
    } catch (e) {
      this.network.handleError("update Library logo", e);
      // TODO: Display user friendly error message
      return;
    }

    runInAction("updateLibraryLogo--success", () => {
      this.network.loading = false;
      this.network.error = null;

      if (this.network.logGraphQLError("updateLibrary", res)) {
        // TODO: Display user friendly error message
        return;
      }

      if (!res || !res.data) return;

      library.logoUrl = res.data.updateClientLibraryLogo.library.logoUrl;
    });
  }
}
