import React from "react";

import Button, { ButtonProps } from "../../../../../components/lib/Button";

const buttonCSS = {
  borderRadius: "0px",
  border: "none",
  borderRight: "1px solid $primaryLighter !important",
  height: "$full",
  width: "100px",
  "&:hover": {
    border: "none",
  },
  "&:focus": {
    outline: "none",
    boxShadow: "none",
    border: "none",
  },
};

export function ActionButton(props: ButtonProps) {
  return <Button variant="light" css={buttonCSS} {...props} />;
}

ActionButton.displayName = "ActionsButton";
