// @flow

import { extendObservable, action, runInAction } from "mobx";
import NetworkState from "../../models/NetworkState";
import BatchSearch from "../../models/BatchSearch";
import type { FetchAPI, FetchGraphQL } from "../../App";
import { createTasteGraphQLWrapper } from "./SupportFunctions";

export class BatchSearchJLDetailStore {
  fetchTasteGraphQL: FetchGraphQL;
  fetchGraphQL: FetchGraphQL;
  fetchAPI: FetchAPI;
  network: NetworkState;
  router: Object;

  batchSearchUUID: ?string;
  batchSearch: ?BatchSearch;
  getBatchSearch: () => Promise<any>;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI) {
    this.fetchTasteGraphQL = createTasteGraphQLWrapper(fetchAPI);
    this.fetchGraphQL = fetchGraphQL;
    this.fetchAPI = fetchAPI;

    extendObservable(this, {
      network: new NetworkState(),
      batchSearch: null,
    });

    this.getBatchSearch = action(this.getBatchSearch.bind(this));
  }

  async getBatchSearch(): Promise<any> {
    if (!this.batchSearchUUID) {
      const error = new Error(
        "Could not retrieve batch search. Store.batchSearchUUID has not been set."
      );
      console.error(error);
      return Promise.reject(error);
    }

    if (this.network.loading) {
      this.network.cancel();
    }

    this.network.loading = true;
    let res = null;

    const query = `
    query batchSearchDetail($batchSearchUuid: String!) {
      viewer {
        batchSearchDetail(batchSearchUuid: $batchSearchUuid) {
          uuid
          rateCardLabel
          rateCardId
          searchesTotal
          searchesFinished
          searchesFailed
          searchesQueued
          searchesRunning
          finished
          created
          updated
          status
          statusDisplay
        }
      }
    }
    `;

    const variables = { batchSearchUuid: this.batchSearchUUID };

    try {
      res = await this.fetchGraphQL(query, variables, this.network.getCancelToken());
    } catch (e) {
      if (this.network.isCancelError(e)) {
        return Promise.reject(e);
      }

      this.network.handleError("Creating Batch Search", e);

      // TODO: Display user friendly error message
      return Promise.reject(e);
    }

    return runInAction("getBatchSearch--success", () => {
      this.network.loading = false;
      this.network.error = null;

      if (!res) {
        return null;
      }

      this.batchSearch = new BatchSearch(this, res.data.viewer.batchSearchDetail);

      return this.batchSearch;
    });
  }
}

export class BatchSearchPSPDetailStore {
  fetchTasteGraphQL: FetchGraphQL;
  fetchGraphQL: FetchGraphQL;
  fetchAPI: FetchAPI;
  network: NetworkState;
  router: Object;

  batchSearchUUID: ?string;
  batchSearch: ?BatchSearch;
  getBatchSearch: () => Promise<any>;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI) {
    this.fetchTasteGraphQL = createTasteGraphQLWrapper(fetchAPI);
    this.fetchGraphQL = fetchGraphQL;
    this.fetchAPI = fetchAPI;

    extendObservable(this, {
      network: new NetworkState(),
      batchSearch: null,
    });

    this.getBatchSearch = action(this.getBatchSearch.bind(this));
  }

  async getBatchSearch(): Promise<any> {
    if (!this.batchSearchUUID) {
      const error = new Error(
        "Could not retrieve batch search. Store.batchSearchUUID has not been set."
      );
      console.error(error);
      return Promise.reject(error);
    }

    if (this.network.loading) {
      this.network.cancel();
    }

    this.network.loading = true;
    let res = null;

    const query = `
    query batchSearchDetail($batchSearchUuid: String!) {
      viewer {
        batchSearchDetail(batchSearchUuid: $batchSearchUuid) {
          uuid
          rateCardLabel
          rateCardId
          searchesTotal
          searchesFinished
          searchesFailed
          searchesQueued
          searchesRunning
          finished
          created
          updated
          status
          statusDisplay
        }
      }
    }
    `;

    const variables = { batchSearchUuid: this.batchSearchUUID };

    try {
      res = await this.fetchGraphQL(query, variables, this.network.getCancelToken());
    } catch (e) {
      if (this.network.isCancelError(e)) {
        return Promise.reject(e);
      }

      this.network.handleError("Creating Batch Search", e);

      // TODO: Display user friendly error message
      return Promise.reject(e);
    }

    return runInAction("getBatchSearch--success", () => {
      this.network.loading = false;
      this.network.error = null;

      if (!res) {
        return null;
      }

      this.batchSearch = new BatchSearch(this, res.data.viewer.batchSearchDetail);

      return this.batchSearch;
    });
  }
}
