/**
 * @generated SignedSource<<5a5ef355f71542db3a98ee7ad106c52c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ResultOptionsResultsRefetch$variables = {
  searchId: number;
};
export type ResultOptionsResultsRefetch$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"ResultOptions_viewer">;
  } | null;
};
export type ResultOptionsResultsRefetch = {
  response: ResultOptionsResultsRefetch$data;
  variables: ResultOptionsResultsRefetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ResultOptionsResultsRefetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "searchId",
                "variableName": "searchId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "ResultOptions_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ResultOptionsResultsRefetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "searchId"
              }
            ],
            "concreteType": "SaveSearchNode",
            "kind": "LinkedField",
            "name": "savedsearch",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "RateSearchResponse",
                "kind": "LinkedField",
                "name": "rates",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rawBillRates",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RateCardNode",
                "kind": "LinkedField",
                "name": "rateCard",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "legacyId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "db85d2d8f2e75fd81204820718d5aef0",
    "id": null,
    "metadata": {},
    "name": "ResultOptionsResultsRefetch",
    "operationKind": "query",
    "text": "query ResultOptionsResultsRefetch(\n  $searchId: Int!\n) {\n  viewer {\n    ...ResultOptions_viewer_1CYBKI\n  }\n}\n\nfragment ResultOptions_viewer_1CYBKI on Viewer {\n  savedsearch(id: $searchId) {\n    id\n    rates {\n      rawBillRates\n    }\n    rateCard {\n      id\n      legacyId\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a58d148c517004e1056484b37e3523ff";

export default node;
