export const LEVEL_ROMAN_NUMERAL = {
  Junior: "I",
  Mid: "II",
  Senior: "III",
  Lead: "IV",
  Guru: "V",
};

export const LEVEL_ABBREVIATION = {
  Junior: "JR",
  Mid: "MD",
  Senior: "SR",
  Lead: "LD",
  Guru: "G",
};
