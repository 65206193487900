// @flow

import * as React from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextInput from "../../../components/lib/TextInput";

const ENTER_KEY = 13;
type Props = {
  style?: Object,
  onSearch: (string) => void,
  onChange: (string) => void,
  value: string,
  placeholder: string,
  disabled: boolean,
};

class SearchBox extends React.Component<Props> {
  handleSearchToggle = (e: SyntheticEvent<HTMLElement>) => {
    const { onSearch } = this.props;

    if (onSearch) {
      onSearch(this.props.value);
    }
  };

  handleSearchKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode !== ENTER_KEY) {
      return;
    }

    const onSearch = this.props.onSearch;

    if (onSearch) {
      onSearch(this.props.value);
    }
  };

  handleOnChange = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const onChange = this.props.onChange;

    if (onChange) {
      onChange(e.currentTarget.value);
    }
  };

  render() {
    return (
      <div className="pt-search" style={this.props.style}>
        <TextInput
          data-testid="search-box"
          fill
          name="search-box"
          placeholder={this.props.placeholder}
          value={this.props.value}
          onChange={this.handleOnChange}
          onKeyDown={this.handleSearchKeyDown}
          disabled={this.props.disabled}
        />
        <FontAwesomeIcon
          icon="search"
          fixedWidth
          className="pt-search-icon"
          onClick={this.handleSearchToggle}
        />
      </div>
    );
  }
}

export default observer(SearchBox);
