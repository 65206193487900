import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/lib/Async";

import { reactSelectStyles, reactSelectTheme } from "../../constants/reactSelect";
import { logGraphQLError, logAsyncOperationError } from "../../utils/logging";

const LocationSelect = (props) => {
  const loadOptions = async (inputValue) => {
    try {
      const response = await props.fetchGraphQL(
        `query fetchAllLocations($searchParam: String!) {
          viewer {
            locations(search: $searchParam) {
              type
              title
              subtitle
              full_title: fullTitle
              full_subtitle: fullSubtitle
              location_id: locationId
              country_id: countryId
              highlight {
                title
                subtitle
              }
            }
          }
        }`,
        { searchParam: inputValue }
      );

      if (logGraphQLError("fetchAllLocations", response.data)) return [];

      return response.data.data.viewer.locations || [];
    } catch (err) {
      logAsyncOperationError("fetchAllLocations", err);
      throw err;
    }
  };
  const getOptionValue = (option) => option.location_id.toString();
  const formatOptionLabel = (option) => {
    const title = option["title"];
    const subtitle = option["subtitle"];
    // TODO make highlights working
    // const highlight = option["highlight"];
    // const titleHighlight = highlight && highlight["title"];
    // const subtitleHighlight = highlight && highlight["subtitle"];

    return [title, subtitle].filter((item) => !!item).join(", ");
  };

  return (
    <AsyncSelect
      className={props.className}
      inputId={props.id}
      loadOptions={loadOptions}
      cacheOptions
      noOptionsMessage={(inputValue) => "No locations found for your input..."}
      value={props.value}
      onChange={props.onChange}
      styles={props.styles}
      theme={props.theme}
      placeholder={props.placeholder}
      isLoading={props.loading}
      isDisabled={props.disabled}
      isMulti={props.multi}
      isSearchable={props.searchable}
      isClearable={props.clearable}
      getOptionValue={getOptionValue}
      formatOptionLabel={formatOptionLabel}
    />
  );
};

LocationSelect.propTypes = {
  id: PropTypes.string,
  fetchGraphQL: PropTypes.func.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  multi: PropTypes.bool,
  searchable: PropTypes.bool,
  clearable: PropTypes.bool,
  styles: PropTypes.object,
  theme: PropTypes.func,
  className: PropTypes.string,
};
LocationSelect.defaultProps = {
  placeholder: "Type to find your location...",
  loading: false,
  disabled: false,
  multi: false,
  searchable: true,
  clearable: false,
  styles: reactSelectStyles,
  theme: reactSelectTheme,
};

export default LocationSelect;
