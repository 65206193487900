import Inline from "../../../components/lib/Inline";
import Text from "../../../components/lib/Text";
import { styled } from "../../../stitches.config";

const ParametersLine = styled(Inline, {
  gap: "$4 !important",
  width: "$full !important",
  flexWrap: "nowrap !important",
  [`& > ${Text}:first-child`]: {
    flex: 1,
  },
  [`& > ${Text}`]: {
    flex: 4,
    whiteSpace: "nowrap",
  },
});

export default ParametersLine;
