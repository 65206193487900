import React from "react";

import Icon from "../../../../../components/lib/Icon";
import { RATE_FEEDBACK_STATUS_TYPES } from "../../../types";
import { RatesButton } from "./ButtonStyle";

import type { RATE_FEEDBACK_STATUS_TYPES_TYPE } from "../../../types";

type Props = {
  status: RATE_FEEDBACK_STATUS_TYPES_TYPE;
  onClick: (value: RATE_FEEDBACK_STATUS_TYPES_TYPE) => void;
};

export default function RatesFeedbackAgreeButton(props: Props) {
  const { status, onClick } = props;
  const isApprovedStatus = status === RATE_FEEDBACK_STATUS_TYPES.APPROVED;

  return (
    <RatesButton
      css={{
        backgroundColor: isApprovedStatus ? "$successLightest" : undefined,
        "&:hover": { background: "$successLighter" },
      }}
      onClick={() => onClick(RATE_FEEDBACK_STATUS_TYPES.APPROVED)}
    >
      <Icon icon="check" /> Agree
    </RatesButton>
  );
}

RatesFeedbackAgreeButton.displayName = "RatesFeedbackAgreeButton";
