import React from "react";
import { observer } from "mobx-react";
import Modal from "./Modal";
// @ts-ignore
import ModalState from "../../models/ModalState";
// @ts-ignore
import { ModalButtons, ModalTitle } from "../../views/ratecards/components/FilterModal";
import Button from "../lib/Button";
import Inline from "../lib/Inline";
import Icon from "../lib/Icon";

interface BaseInfoModalProps {
  infoMessage: React.ReactNode;
  show: boolean;
  onHide: () => void;
  style?: any;
}

export const BaseInfoModal = (props: BaseInfoModalProps): JSX.Element => {
  const { infoMessage, show, onHide, style } = props;

  return (
    <Modal show={show} onHide={onHide} innerStyle={style}>
      <div className="container-section header">
        <ModalTitle>Information</ModalTitle>
      </div>
      <div className="container-section">
        <Inline fill nowrap css={{ alignItems: "center" }}>
          <Icon icon="info-circle" css={{ color: "$brand", fontSize: "$4xl" }} />
          <div>{infoMessage}</div>
        </Inline>
      </div>
      <div className="container-section footer">
        <ModalButtons>
          <Button size="large" onClick={onHide}>
            Close
          </Button>
        </ModalButtons>
      </div>
    </Modal>
  );
};
BaseInfoModal.displayName = "BaseInfoModal";

interface InfoModalProps {
  modalState: ModalState;
  infoMessage: React.ReactNode;
  style?: any;
}

const InfoModal = (props: InfoModalProps) => {
  const { modalState, infoMessage, style } = props;

  return (
    <BaseInfoModal
      infoMessage={infoMessage}
      show={modalState.show}
      onHide={modalState.hideModal}
      style={style}
    />
  );
};
InfoModal.displayName = "InfoModal";

export default observer(InfoModal);
