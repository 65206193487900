import React from "react";
import { List } from "immutable";

import { ShortcutButton } from "./BaseValuesFilter";
import Icon from "../../lib/Icon";
import { CheckboxItem } from "../../lib/Checkbox";
import Inline from "../../lib/Inline";
import { FilterTypes, OrderDirections, emptyList } from "../constants";
import Stack from "../../lib/Stack";
import {
  ComparatorsConfig,
  FilterConfigMap,
  FilterOptionMap,
  FilterOptionsList,
  OrderDirectionsType,
} from "../types";
import { OnChangeFilterValueFunction } from "./types";
import { CSS } from "../../../stitches.config";
import { ImmutableList } from "../../../types/immutable";

type EnumFilterProps = {
  filter: FilterConfigMap;
  onChange: OnChangeFilterValueFunction;
  filterOptions: FilterOptionsList;
  comparators: ComparatorsConfig;
  css?: CSS;
};

const EnumFilter = React.forwardRef((props: EnumFilterProps, ref) => {
  const { filter, filterOptions, comparators, onChange, css } = props;

  const orderDirection = filter.getIn(
    ["order", "direction"],
    OrderDirections.NO
  ) as OrderDirectionsType;
  const selectedValuesList = filter.getIn(["filter", "values"]).toList();

  const sortedOptions = React.useMemo(() => {
    // transform options
    let result: FilterOptionsList = filterOptions.map((option) => {
      let valuesList = option.get("value");

      if (!(valuesList instanceof List)) {
        valuesList = List([valuesList]);
      }

      return option.set("valuesList", valuesList) as FilterOptionMap;
    });

    // apply ordering
    if (orderDirection !== OrderDirections.NO && comparators[orderDirection] != null) {
      result = result.sortBy(
        (option) => option.get("valuesList", emptyList).first(),
        comparators[orderDirection]
      );
    }

    return result;
  }, [filterOptions, orderDirection, comparators]);

  const allValuesList = React.useMemo(() => {
    return sortedOptions.reduce(
      (acc, option) => acc!.push(...option!.get("valuesList").toArray()),
      emptyList
    );
  }, [sortedOptions]);

  // handlers

  const handleItemChange = React.useCallback(
    (valuesList: ImmutableList<any>, isDelete: boolean) => {
      let selectedValues = filter.getIn(["filter", "values"]);

      valuesList.forEach((value) => {
        const valueIdx = isDelete ? selectedValues.indexOf(value) : -1;

        selectedValues =
          isDelete && valueIdx >= 0
            ? selectedValues.delete(valueIdx)
            : selectedValues.push(value);
      });

      onChange(
        filter
          .setIn(["filter", "values"], selectedValues)
          .setIn(["filter", "type"], FilterTypes.ENUMERATION) as FilterConfigMap
      );
    },
    [filter, onChange]
  );

  const handleSelectAllValues = React.useCallback(() => {
    if (allValuesList.size > 0) {
      onChange(
        filter
          .setIn(["filter", "values"], allValuesList)
          .setIn(["filter", "type"], FilterTypes.ENUMERATION) as FilterConfigMap
      );
    }
  }, [allValuesList, filter, onChange]);

  const handleUnselectAllValues = React.useCallback(() => {
    onChange(
      filter
        .setIn(["filter", "values"], emptyList)
        .setIn(["filter", "type"], FilterTypes.ENUMERATION) as FilterConfigMap
    );
  }, [filter, onChange]);

  return (
    <Stack fill css={{ alignItems: "stretch", gap: "$2", ...css }}>
      <span>Select values to be included:</span>

      <Stack fill css={{ alignItems: "stretch", gap: "$2", paddingLeft: "$2" }}>
        <Inline fill>
          <ShortcutButton onClick={handleSelectAllValues}>
            <Icon icon="check" />
            &nbsp;
            <span>Check all</span>
          </ShortcutButton>
          <ShortcutButton onClick={handleUnselectAllValues}>
            <Icon icon="times" />
            &nbsp;
            <span>Uncheck all</span>
          </ShortcutButton>
        </Inline>

        {sortedOptions.toArray().map((option) => {
          const valuesList = option.get("valuesList");
          const key = valuesList.join(",");
          const label = option.get("label");
          const isSelected =
            valuesList.findIndex((value) => selectedValuesList.indexOf(value) >= 0) >= 0;

          return (
            <CheckboxItem
              key={key}
              checked={isSelected}
              onCheckedChange={() => handleItemChange(valuesList, isSelected)}
            >
              {label}
            </CheckboxItem>
          );
        })}
      </Stack>
    </Stack>
  );
});
EnumFilter.displayName = "EnumFilter";

export default EnumFilter;
