import React, { CSSProperties } from "react";

import Icon from "../../../components/lib/Icon";
import CheckboxQuestionInput from "./CheckboxQuestionInput";
// @ts-ignore
import SingleSelect from "../../../components/selects/SingleSelect";
import Text from "../../../components/lib/Text";
import Stack from "../../../components/lib/Stack";
import { Table, THead, TR, TH, TBody, TD } from "../../../components/lib/Table";
import {
  QUESTION_ANSWER_FORMATS,
  QuestionDataMap,
  QuestionFeedbackDataMap,
  QuestionsFeedbackOrderedMap,
  QuestionsOrderedMap,
} from "../types";
import { emptyMap } from "../../../constants";
import { useQuestionsFeedbackContext } from "../context/feedback/QuestionsFeedbackContext";
import TextAreaBase from "../../../components/lib/TextArea";

const textInputGetProps = (
  question: QuestionDataMap,
  feedback: QuestionFeedbackDataMap
) => ({
  rows: 1,
  value: feedback.get("value"),
  css: {
    width: "$full",
    backgroundColor: "$white",
    resize: "vertical",
    fontSize: "inherit",
  },
});

const checkboxGetProps = (
  question: QuestionDataMap,
  feedback: QuestionFeedbackDataMap
) => ({
  value: feedback.get("value"),
  css: { backgroundColor: "$white" },
});

const selectGetProps = (question: QuestionDataMap, feedback: QuestionFeedbackDataMap) => {
  const value = feedback.get("value");
  const choices = question.get("choices") ?? [];

  return {
    value: {
      value: value != null ? String(value) : value,
      label: value != null ? String(value) : "",
    },
    options: choices.map((val) => ({
      value: String(val),
      label: String(val),
    })),
    placeholder: "",
    styles: {
      backgroundColor: "$white",
      fontSize: "$small",
      control: (base: CSSProperties) => ({
        ...base,
        boxShadow: "none",
      }),
    },
  };
};

type TextAreaProps = Omit<React.ComponentProps<typeof TextAreaBase>, "onChange"> & {
  onChange: (value: string) => void;
};

const TextArea = (props: TextAreaProps) => {
  const { onChange, ...rest } = props;

  const handleValueChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange?.(e.target.value);
  };

  return <TextAreaBase onChange={handleValueChange} {...rest} />;
};

type SelectValueType = { value: string; label: string };

const questionInputParamsSpecs = {
  [QUESTION_ANSWER_FORMATS.TEXTBOX]: {
    ComponentImpl: TextArea,
    onChangeImpl: "textInputOnChange",
    getPropsImpl: textInputGetProps,
  } as const,
  [QUESTION_ANSWER_FORMATS.CHECKBOX]: {
    ComponentImpl: CheckboxQuestionInput,
    onChangeImpl: "checkboxOnChange",
    getPropsImpl: checkboxGetProps,
  } as const,
  [QUESTION_ANSWER_FORMATS.SELECT]: {
    ComponentImpl: SingleSelect,
    onChangeImpl: "selectOnChange",
    getPropsImpl: selectGetProps,
  } as const,
} as const;

type QuestionsSurveyProps = {
  questions: QuestionsOrderedMap;
  feedback: QuestionsFeedbackOrderedMap;
  onChange: (questionId: number, value: string | boolean) => void;
};

export const QuestionsSurvey = (props: QuestionsSurveyProps) => {
  const { questions, feedback, onChange } = props;
  const { hasMissingRequiredQuestionsFeedback } = useQuestionsFeedbackContext();

  // handlers

  const onChangeHandlers = React.useMemo(() => {
    return {
      textInputOnChange: (questionId: number, value: string) => {
        onChange?.(questionId, value);
      },
      checkboxOnChange: (questionId: number, value: boolean) => {
        onChange?.(questionId, value);
      },
      selectOnChange: (questionId: number, value: SelectValueType) => {
        onChange?.(questionId, value?.value);
      },
    };
  }, [onChange]);

  // render

  return (
    <Stack fill css={{ alignItems: "flex-start", gap: "$2" }}>
      <Text as="h6">
        <Icon icon="info-circle" /> - Required questions marked with *
      </Text>
      <Table
        css={{
          [`& ${TH}, & ${TD}`]: {
            backgroundColor: "$brandLighter",
          },
          [`& ${THead}, & ${TBody}, & ${TR}, & ${TD}, & ${TH}`]: {
            borderColor: "$white",
          },
        }}
      >
        <THead>
          <TR>
            <TH>Question</TH>
            <TH>Answer</TH>
          </TR>
        </THead>
        <TBody>
          {questions.toArray().map((question: QuestionDataMap) => {
            const questionId = question.get("id");
            const statement = question.get("statement");
            const questionFormat = question.get("answer_format");
            const questionFeedback = feedback.get(questionId) || emptyMap;
            const inputSpecs = questionInputParamsSpecs[questionFormat];
            const { ComponentImpl, onChangeImpl, getPropsImpl } = inputSpecs;
            const inputProps = getPropsImpl(question, questionFeedback) ?? {};
            const isRequired = question.get("is_required", false);
            const onChangeHandler = onChangeHandlers[onChangeImpl];

            if (ComponentImpl && onChangeImpl) {
              return (
                <TR key={questionId}>
                  <TD>{isRequired ? `* ${statement}` : statement}</TD>
                  <TD>
                    <ComponentImpl
                      {...inputProps}
                      onChange={(value: string | boolean | SelectValueType) => {
                        // @ts-expect-error
                        onChangeHandler(questionId, value);
                      }}
                    />
                  </TD>
                </TR>
              );
            }

            return null;
          })}
        </TBody>
      </Table>
      <Stack fill css={{ alignItems: "center" }}>
        {hasMissingRequiredQuestionsFeedback && (
          <Text noMargin as="h5" color="negative">
            Don't forget to answer all required questions marked with * above.
          </Text>
        )}
      </Stack>
    </Stack>
  );
};

export default QuestionsSurvey;
