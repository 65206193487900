// @flow

import React, { Component } from "react";
import { observer } from "mobx-react";
import LoadingIndicator from "../../ratecards/components/LoadingIndicator";
import Search from "../../../models/Search";
import ContainerSection from "../../ratecards/components/ContainerSection";
import Modal from "../../ratecards/components/Modal";
import ResultsTrendChart from "./ResultsTrendChart";
import { LEVEL_ROMAN_NUMERAL } from "../../../constants/levels";
import { LEVEL_COLOR, LEVEL_SELECTOR } from "../../../constants/css";
import {
  buildData,
  buildMonthsList,
  getCurrency,
  shouldShowCurrency,
} from "./SupportFunctions";
import { LEVEL_SPARKLINE_MONTH_FORMAT } from "../../../constants/dateFormats";
// import InfiniteScrollSelect from "../../scheduled_search/components/InfiniteScrollSelect";
import type { SelectOption } from "../../../models/interfaces/InfiniteScrollSelect";
import { ModalButtons, ModalTitle } from "../../ratecards/components/FilterModal";
import Button from "../../../components/lib/Button";

export class SampleSizeItem implements SelectOption {
  id: number;
  value: number;
  name: string;
  toString: () => string;

  constructor(id: number, value: number, name: string) {
    this.id = id;
    this.value = value;
    this.name = name;
  }

  toString = () => {
    return this.name;
  };
}

type Props = {
  search: Search,
  show: boolean,
  loading: boolean,
  levelName: string,
  currency?: string,
  sampleSizeItems: SampleSizeItem[],
  selectedSampleSize: SampleSizeItem,
  onClose: () => void,
  onSelectedSampleSizeChange: (SampleSizeItem, string) => void,
};

class RatesTrendModal extends Component<Props> {
  props: Props;

  static defaultProps = {
    loading: false,
  };

  // _handleSelectedSampleSizeChange = (sampleSize: SampleSizeItem) => {
  //   this.setState({ selectedSampleSize: sampleSize });
  //
  //   if (this.props.onSelectedSampleSizeChange) {
  //     const levelName = this.props.levelName;
  //     const sampleMonths = sampleSize.value;
  //     this.props.onSelectedSampleSizeChange(levelName, sampleMonths);
  //   }
  // };

  _renderDetails = () => {
    const props: Props = this.props;
    const search = props.search;
    const levelName = props.levelName;
    const levelSelector = LEVEL_SELECTOR[levelName];
    const levelNumeral = LEVEL_ROMAN_NUMERAL[levelName];
    return (
      <div className="flex" style={{ paddingRight: 35 }}>
        <div style={{ flex: "1 1 auto" }}>
          <div className="media">
            <div className="media-left">
              <div className={`level-icon ${levelSelector}`}>
                <header>{levelNumeral}</header>
              </div>
            </div>
            <div className="media-body">
              <h4 className="level-card-job-label">{search.label}</h4>
              <label className="level-card-location">{search.locationDetail()}</label>
            </div>
          </div>
        </div>

        <div style={{ width: 200, flex: "0 0 auto" }}>
          {/*
          NOTE: We do not yet want to offer the ability to change the data sample size
          cuz going too far back into the rates starts to show weird data trends. In
          the future, when data is ready to be shown for the las 24 months or client
          requests it, just uncomment InfiniteScrollSelect and remove the fixed
          props.selectedSampleSize.toString() line.
          */}
          <label className="pull-right">{props.selectedSampleSize.toString()}</label>
          {/*<InfiniteScrollSelect*/}
          {/*name="data-sample"*/}
          {/*items={props.sampleSizeItems}*/}
          {/*selectedItem={props.selectedSampleSize}*/}
          {/*onSelectedItemChange={props.onSelectedSampleSizeChange}*/}
          {/*hasMoreItems={false}*/}
          {/*showSearchInput={false}*/}
          {/*/>*/}
        </div>
      </div>
    );
  };

  _renderLoading = () => {
    const props = this.props;
    const levelName = props.levelName;
    const levelNumeral = LEVEL_ROMAN_NUMERAL[levelName];

    return (
      <Modal show={props.show} onHide={props.onClose} innerStyle={{ width: "80vw" }}>
        <ContainerSection className="header">
          <ModalTitle>Rates Trend - Level {levelNumeral}</ModalTitle>
        </ContainerSection>
        <ContainerSection>
          <div style={{ minHeight: 400 }}>
            <div style={{ position: "relative" }}>
              {this._renderDetails()}
              <div className="loading-indicator-container show">
                <LoadingIndicator
                  style={{
                    minHeight: 460,
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                />
              </div>
            </div>
          </div>
        </ContainerSection>
        <ContainerSection className="footer">
          <div className="pull-right">
            <button className="btn btn-lg" onClick={props.onClose}>
              Close
            </button>
          </div>
        </ContainerSection>
      </Modal>
    );
  };

  render() {
    const props: Props = this.props;
    const isLoading = props.loading || props.search.network.loading;

    if (isLoading) return this._renderLoading();

    const search = props.search;
    const levelName = props.levelName;
    const resultLevel = search.resultsByLevel[levelName];

    const rateType = search.frequency.title.toLowerCase();
    const levelNumeral = LEVEL_ROMAN_NUMERAL[levelName];
    // let levelAbbreviation = LEVEL_ABBREVIATION[resultLevel.levelName];
    // const levelSelector = LEVEL_SELECTOR[levelName];

    const months = buildMonthsList(
      -1 * props.selectedSampleSize.value,
      LEVEL_SPARKLINE_MONTH_FORMAT
    );

    if (!resultLevel) return null;
    let payRateMinData = buildData(resultLevel, "payRateMin", months);
    let payRateMaxData = buildData(resultLevel, "payRateMax", months);
    let payRateAvgData = buildData(resultLevel, "payRateAvg", months);

    let billRateMinData = null;
    let billRateMaxData = null;
    let billRateAvgData = null;
    if (rateType === "contract") {
      billRateMinData = buildData(resultLevel, "billRateMin", months);
      billRateMaxData = buildData(resultLevel, "billRateMax", months);
      billRateAvgData = buildData(resultLevel, "billRateAvg", months);
    }

    let levelColor = LEVEL_COLOR[resultLevel.levelName];
    // let positiveColor = VALUE_CHANGE_COLOR.positive;
    // let negativeColor = VALUE_CHANGE_COLOR.negative;

    let currencySymbol = "";
    let showCurrency = shouldShowCurrency(resultLevel);
    if (showCurrency) currencySymbol = props.currency || getCurrency(search);

    return (
      <Modal show={props.show} onHide={props.onClose} innerStyle={{ width: "80vw" }}>
        <div className="rc-viewport overlap-lr-padding overlap-tb-padding">
          <ContainerSection className="header">
            <ModalTitle>Rates Trend - Level {levelNumeral}</ModalTitle>
          </ContainerSection>
          <ContainerSection>
            <div style={{ minHeight: 400 }}>
              <div style={{ position: "relative" }}>
                <div>
                  {this._renderDetails()}
                  <ResultsTrendChart
                    search={props.search}
                    resultLevel={resultLevel}
                    levelColor={levelColor}
                    payRateMinData={payRateMinData}
                    payRateMaxData={payRateMaxData}
                    payRateAvgData={payRateAvgData}
                    billRateMinData={billRateMinData}
                    billRateMaxData={billRateMaxData}
                    billRateAvgData={billRateAvgData}
                    months={months}
                    currencySymbol={currencySymbol}
                  />
                </div>
              </div>
            </div>
          </ContainerSection>
          <ContainerSection className="footer">
            <ModalButtons>
              <Button size="large" onClick={props.onClose}>
                Close
              </Button>
            </ModalButtons>
          </ContainerSection>
        </div>
      </Modal>
    );
  }
}

export default observer(RatesTrendModal);
