// @flow
import ReactDOM from "react-dom";
import * as React from "react";
import App from "./App";
import "./include/bootstrap";
import "ladda/dist/ladda.min.css";
import "./scss/index.scss";
import "./assets/legacy/css/peopleticker.css";
import "tippy.js/dist/tippy.css";
import { registerIcons } from "./registerFAIcons";
import { AppErrorBoundary } from "./components/AppErrorBoundary";

registerIcons();
const mountPoint = document.getElementById("root");

ReactDOM.render(
  <AppErrorBoundary>
    <App reactrURL={window?.SERVICES["reactr"]} services={window?.SERVICES ?? {}} />
  </AppErrorBoundary>,
  // $FlowFixMe: mountPoint won't ever be null because it'd require changes to index.html we never do.
  mountPoint
);
