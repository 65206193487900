import React from "react";
import { observer } from "mobx-react";
import SelectableItemsList from "../ratecards/components/SelectableItemsList";
import SelectableItem from "../ratecards/components/SelectableItem";
import ContainerSection from "../ratecards/components/ContainerSection";
import FilterModal, { ModalTitle } from "../ratecards/components/FilterModal";
import LoadingIndicator from "../shared/LoadingIndicator";
import InstantSearchBox from "../ratecards/components/InstantSearchBox";
import Button from "../../components/lib/Button";
import Inline from "../../components/lib/Inline";
import Spacer from "../../components/lib/Spacer";
import { CardAlert } from "../../components/lib/Card";

const TagsCriteriaList = observer((props) => (
  <div>
    {props.filterState.viewItems.length !== 0 && (
      <SelectableItemsList className="overlap-tb-padding" listState={props.filterState}>
        {props.filterState.viewItems.map((item, i) => (
          <SelectableItem
            key={item.id}
            item={item}
            name="location-filter"
            value={item.tag}
            selected={item.selected}
            onChange={props.filterState.setSelectedValue}
          >
            <div>{item.tag}</div>
          </SelectableItem>
        ))}
      </SelectableItemsList>
    )}
    {props.filterState.viewItems.length === 0 && (
      <CardAlert css={{ margin: "40px 0" }}>
        <span className="text-center">No Tags applied to any item.</span>
      </CardAlert>
    )}
  </div>
));

const TagsFilter = ({ tagsFilter, network, onInstantSearch, instantSearchValue }) => {
  return (
    <FilterModal filterState={tagsFilter}>
      {network && <LoadingIndicator />}
      {!network && (
        <div>
          <ContainerSection className="header overlap-t-padding">
            <ModalTitle>Tags</ModalTitle>
          </ContainerSection>
          <ContainerSection className="no-border">
            <p>Filter by specific Tag:</p>
            <Inline css={{ gap: "$2" }}>
              <InstantSearchBox onSearch={onInstantSearch} value={instantSearchValue} />
              <Spacer />
              <Button onClick={tagsFilter.onSelectAll}>Select All</Button>
              <Button onClick={tagsFilter.onDeselectAll}>Deselect All</Button>
            </Inline>
          </ContainerSection>
          <TagsCriteriaList filterState={tagsFilter} />
        </div>
      )}
    </FilterModal>
  );
};

export default TagsFilter;
