import React, { MouseEvent, ChangeEvent, useRef, useCallback } from "react";
import Icon from "./Icon";
import Stack from "./Stack";
import Text from "./Text";
import Box from "./Box";
import { inputBorderStyle } from "./TextInput";
import { styled } from "../../stitches.config";

const Wrapper = styled(Box, inputBorderStyle, {
  borderWidth: "1px",
  padding: "$1",
  cursor: "pointer",

  variants: {
    invalid: {
      true: {
        borderColor: "$danger",
      },
    },
  },
});

const Badge = styled("button", {
  display: "flex",
  position: "absolute",
  width: "$4",
  height: "$4",
  top: "-$3",
  right: "-$3",
  borderRadius: "$full",
  fontSize: "$xs",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  backgroundColor: "$danger",
  color: "$white",
  "&:hover": {
    transform: "scale(1.1)",
    backgroundColor: "$dangerDark",
    color: "$white",
  },
  "&:active": {
    transform: "scale(0.9)",
  },
});

enum ALLOWED_FILE_EXTENTIONS {
  JPEG = "jpeg",
  JPG = "jpg",
  PNG = "png",
}

const supportedJpegMimeTypes = ["image/jpeg"];
const supportedPngMimeTypes = ["image/png"];

const supportedMimeTypesMatcher: { [key in ALLOWED_FILE_EXTENTIONS]: string[] } = {
  jpeg: supportedJpegMimeTypes,
  jpg: supportedJpegMimeTypes,
  png: supportedPngMimeTypes,
};
const allSupportedMimeTypes = [...supportedJpegMimeTypes, ...supportedPngMimeTypes];

type ImageFileObject = File & {
  dataUrl?: string;
  url?: string;
};

const checkHtmlFileApiSupport = (): boolean => {
  return !!(window && window.Blob && window.File && window.FileReader);
};

type ImageStubProps = {
  placeholder: string;
};

const ImageStub = (props: ImageStubProps) => {
  const { placeholder } = props;

  return (
    <Stack
      fill
      css={{
        justifyContent: "center",
        gap: "$2",
        background: "$primaryLight",
        color: "$white",
        textAlign: "center",
        height: "$24",
        padding: "$3",
        borderRadius: "$rounded",
      }}
    >
      <Icon icon="image" css={{ fontSize: "$5xl" }} />
      <Text>{placeholder}</Text>
    </Stack>
  );
};

ImageStub.displayName = "ImageStub";

type ImageViewProps = {
  value: string | ImageFileObject;
  defaultImageWidth: number;
  onChange: (file: string | File) => void;
  disabled?: boolean;
};

const ImageView = (props: ImageViewProps) => {
  const { value, defaultImageWidth, onChange, disabled } = props;

  const handleResetClick = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      if (e.stopPropagation) e.stopPropagation();
      if (onChange) onChange("");
    },
    [onChange]
  );

  let src = "";

  if (typeof value === "string") {
    src = value;
  } else if (value.dataUrl) {
    src = value.dataUrl;
  } else if (value.url) {
    src = value.url;
  }

  if (!src) return null;

  return (
    <Box css={{ position: "relative" }}>
      <img src={src} width={defaultImageWidth} alt="uploaded icon" />
      {!disabled && (
        <Badge title="reset" onClick={handleResetClick}>
          <Icon icon="times" />
        </Badge>
      )}
    </Box>
  );
};

ImageView.displayName = "ImageBlock";

type ImageInputProps = {
  name: string;
  value: string | File;
  placeholder: string;
  disabled: boolean;
  invalid: boolean;
  onChange: (file: "" | string | File, e?: ChangeEvent<HTMLInputElement>) => void;
  onNotSupported: () => void;
  onBadFileMimeType: () => void;
  defaultImageWidth: number;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
};

const ImageInput = (props: ImageInputProps) => {
  const {
    name,
    value,
    placeholder,
    disabled,
    invalid,
    onChange,
    onClick,
    onNotSupported,
    onBadFileMimeType,
    defaultImageWidth,
    ...rest
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const handleComponentClick = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      const element = inputRef.current;

      if (element && element.click) {
        element.click();
        if (onClick) onClick(e);
      }
    },
    [onClick]
  );

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (checkHtmlFileApiSupport()) {
        const files = e.target.files;

        if (!files?.length) return;

        const file = files[0];
        const fileName = file.name;
        const extension = fileName
          ? (fileName.split(".").slice(-1)[0] as ALLOWED_FILE_EXTENTIONS)
          : null;
        let mimeType = file.type;

        if (!mimeType && extension && supportedMimeTypesMatcher[extension]) {
          mimeType = supportedMimeTypesMatcher[extension][0];
        }

        // check file type
        if (mimeType && allSupportedMimeTypes.indexOf(mimeType) < 0) {
          if (onBadFileMimeType) onBadFileMimeType();
          console.error("ImageInput component: provided file type is not supported.");
          return;
        } else if (
          !mimeType &&
          extension &&
          Object.keys(supportedMimeTypesMatcher).indexOf(extension) < 0
        ) {
          if (onBadFileMimeType) onBadFileMimeType();
          console.error("ImageInput component: provided file type is not supported.");
          return;
        }

        // read image as dataUrl (to show icon instantly to the user)
        const reader = new FileReader();
        reader.onload = (event: ProgressEvent<FileReader>) => {
          const dataUrl = event.target?.result as string | null;

          if (dataUrl) {
            (file as ImageFileObject).dataUrl = dataUrl;

            if (onChange) {
              onChange(file, e);
            }
          }
        };
        reader.readAsDataURL(file);
      } else if (onNotSupported) {
        onNotSupported();
      }
    },
    [onNotSupported, onChange, onBadFileMimeType]
  );

  return (
    <Wrapper onClick={handleComponentClick} invalid={invalid}>
      <input
        {...rest}
        ref={inputRef}
        type="file"
        name={name}
        disabled={disabled}
        onChange={handleInputChange}
        style={{ visibility: "hidden", width: 0, height: 0 }}
      />
      {!value ? (
        <ImageStub placeholder={placeholder} />
      ) : (
        <ImageView
          value={value}
          defaultImageWidth={defaultImageWidth}
          onChange={onChange}
          disabled={disabled}
        />
      )}
    </Wrapper>
  );
};

ImageInput.displayName = "ImageInput";
ImageInput.defaultProps = {
  placeholder: "click to select",
  disabled: false,
  invalid: false,
  defaultImageWidth: 120,
};

export default ImageInput;
