import React from "react";

import PromiseButton from "../../../../components/lib/PromiseButton";
import Button from "../../../../components/lib/Button";
import TextArea from "../../../../components/lib/TextArea";
import {
  Card,
  CardBody,
  CardHeaderTitle,
  CardHeader,
} from "../../../../components/lib/Card";
import { useValidationContext } from "../../context/ValidationContext";
import { useReviewDataContext } from "../../../validator5K_admin/context/ReviewDataContext";
import { useAttemptDataContext } from "../../context/AttemptDataContext";
import { useVal5KPublicContext } from "../../context/Val5KPublicContext";
import { baseUrlPath } from "../../constants";
import { ButtonGroupRight } from "../../../../components/lib/ButtonGroup";
import Stack from "../../../../components/lib/Stack";

export default function CommentForm() {
  const { router } = useVal5KPublicContext();
  const { reviewId, reviewsGroupId } = useReviewDataContext();
  const { attemptId, updateAttemptData } = useAttemptDataContext();
  const { comment, updateValidationState, goBackToRatesPage } = useValidationContext();

  // event handlers

  const handleChangeComment: React.ChangeEventHandler<HTMLTextAreaElement> =
    React.useCallback(
      (e) => {
        const value = e.target.value;

        if (value !== comment) {
          updateValidationState({ comment: value });
        }
      },
      [comment, updateValidationState]
    );

  const handleSubmitComment = React.useCallback(async () => {
    if (!attemptId) return;

    if (comment) {
      await updateAttemptData(attemptId, { comment });
    }

    if (reviewsGroupId) {
      router.push(`${baseUrlPath}/surveys/${reviewId}/pecking-order`);
    } else {
      updateValidationState({ dontBlockUI: false });
    }
  }, [
    comment,
    updateValidationState,
    updateAttemptData,
    reviewsGroupId,
    reviewId,
    attemptId,
    router,
  ]);

  // render

  return (
    <Card fill>
      <CardHeader>
        <CardHeaderTitle as="h3">
          If you have additional comments, please provide them below...
        </CardHeaderTitle>
      </CardHeader>

      <CardBody>
        <Stack css={{ alignItems: "stretch" }}>
          <TextArea
            fill
            rows={15}
            value={comment || ""}
            placeholder="Enter text here"
            onChange={handleChangeComment}
            css={{ resize: "vertical" }}
          />

          <ButtonGroupRight>
            <Button
              color="brand"
              variant="outlined"
              icon="arrow-left"
              size="normal"
              onClick={goBackToRatesPage}
            >
              Back To Rates Form
            </Button>
            <PromiseButton
              color="brand"
              variant="filled"
              size="normal"
              onClick={handleSubmitComment}
            >
              Finish
            </PromiseButton>
          </ButtonGroupRight>
        </Stack>
      </CardBody>
    </Card>
  );
}

CommentForm.displayName = "CommentForm";
