import React, { ReactElement } from "react";
import { supportEmail } from "../constants";
import { withErrorCatch } from "../utils/withErrorCatch";
import Text from "./lib/Text";
import Container from "./lib/Container";
import { Link, NavBarLink } from "./lib/Link";
import Alert from "./lib/Alert";
import Stack from "./lib/Stack";
import { styled } from "../stitches.config";
import Logo from "./Logo";

const maxWidth = 850;

const Nav = styled("nav", {
  height: "60px",
  backgroundColor: "$secondary",
  color: "$textLight",
  zIndex: "$20",
  overflow: "hidden",
  display: "flex",
  alignItems: "center",
});

type Props = {
  error: Error | null;
  children: ReactElement;
};

function AppBoundary(props: Props) {
  const { error, children } = props;

  if (error) {
    return (
      <>
        <Nav>
          <Stack
            fill
            css={{
              alignItems: "flex-start",
              maxWidth,
              padding: "0 $8",
              margin: "0 auto",
            }}
          >
            <NavBarLink href="/">
              <Logo title="logo" width="140" height="45" />
            </NavBarLink>
          </Stack>
        </Nav>
        <Container
          css={{
            padding: "$8",
            maxWidth,
          }}
        >
          <Alert color="danger">
            <Stack css={{ gap: "$6", alignItems: "flex-start" }}>
              <Text as="h3" css={{ margin: 0 }}>
                We're sorry, something went wrong.
              </Text>
              <Text as="p" css={{ margin: 0 }}>
                Please contact our support team at:{" "}
                <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
              </Text>
              <Text>
                {error?.name}: {error?.message}
              </Text>
            </Stack>
          </Alert>
        </Container>
      </>
    );
  }

  return children;
}

export const AppErrorBoundary = withErrorCatch(AppBoundary);
