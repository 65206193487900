import React from "react";

import PromiseButton from "../../../../components/lib/PromiseButton";
import Text from "../../../../components/lib/Text";
import { ButtonGroupRight } from "../../../../components/lib/ButtonGroup";

export type ButtonsBlockProps = {
  disableSubmit: boolean;
  onSubmitIDK: () => Promise<void>;
  onSubmitFeedback: () => Promise<void>;
};

export default function ButtonsBlock(props: ButtonsBlockProps) {
  const { disableSubmit, onSubmitIDK, onSubmitFeedback } = props;

  return (
    <ButtonGroupRight>
      {!disableSubmit && (
        <Text as="h5" color="positive">
          You are good to submit your feedback.
        </Text>
      )}
      <PromiseButton size="normal" color="brand" variant="outlined" onClick={onSubmitIDK}>
        I Don't Know This One
      </PromiseButton>
      <PromiseButton
        size="normal"
        color="brand"
        variant="filled"
        disabled={disableSubmit}
        onClick={onSubmitFeedback}
      >
        Submit Feedback
      </PromiseButton>
    </ButtonGroupRight>
  );
}

ButtonsBlock.displayName = "ButtonsBlock";
