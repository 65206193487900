/**
 * @generated SignedSource<<a5fb5ed07fc9627274d03dfc3ff7d7bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateRateCardListExportInput = {
  clientMutationId?: string | null;
  convertToUsd?: boolean | null;
  createdAfter?: string | null;
  createdBefore?: string | null;
  rateCardId: string;
  userIds?: ReadonlyArray<string | null> | null;
};
export type RateCardDetailStoreCreateRateCardListExportMutation$variables = {
  input: CreateRateCardListExportInput;
};
export type RateCardDetailStoreCreateRateCardListExportMutation$data = {
  readonly exportRateCardList: {
    readonly errors: ReadonlyArray<{
      readonly __typename: string;
    } | null> | null;
    readonly export: {
      readonly id: string;
    } | null;
  } | null;
};
export type RateCardDetailStoreCreateRateCardListExportMutation = {
  response: RateCardDetailStoreCreateRateCardListExportMutation$data;
  variables: RateCardDetailStoreCreateRateCardListExportMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateRateCardListExportPayload",
    "kind": "LinkedField",
    "name": "exportRateCardList",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RateCardListExportNode",
        "kind": "LinkedField",
        "name": "export",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RateCardDetailStoreCreateRateCardListExportMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RateCardDetailStoreCreateRateCardListExportMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4232ba016a040dccc3d9d075b215f969",
    "id": null,
    "metadata": {},
    "name": "RateCardDetailStoreCreateRateCardListExportMutation",
    "operationKind": "mutation",
    "text": "mutation RateCardDetailStoreCreateRateCardListExportMutation(\n  $input: CreateRateCardListExportInput!\n) {\n  exportRateCardList(input: $input) {\n    export {\n      id\n    }\n    errors {\n      __typename\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6cde1e4193db0afbc9786d544b7341d0";

export default node;
