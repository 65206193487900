import React from "react";

import Stack from "../../../../components/lib/Stack";
import Alert from "../../../../components/lib/Alert";
import Text from "../../../../components/lib/Text";
import { useRateDisplayContext } from "../../../validator5K_admin/context/RateDisplayContext";

export default function RatesBlock(props: React.PropsWithChildren<{}>) {
  const { children } = props;
  const { firstJobCurrencyData, displayCurrencyData } = useRateDisplayContext();
  const showLocalCurrencyAlert =
    !displayCurrencyData.get("code") && !firstJobCurrencyData.get("code");

  return (
    <Stack fill css={{ alignItems: "start" }}>
      {showLocalCurrencyAlert && (
        <Alert fill color="warning">
          <Text bold>IMPORTANT:</Text> Rates are displayed in the local currency.
        </Alert>
      )}
      {children}
    </Stack>
  );
}

RatesBlock.displayName = "RatesBlock";
