import React, { SetStateAction } from "react";

import Stack from "../../components/lib/Stack";
import Text from "../../components/lib/Text";
import TextInput from "../../components/lib/TextInput";
import Icon from "../../components/lib/Icon";
import Inline from "../../components/lib/Inline";
import Alert from "../../components/lib/Alert";
import SearchAllocatorHelpModal from "./SearchAllocatorHelpModal";
import SearchPlanMoal from "./SearchPlanModal";
import HeaderMenu from "./HeaderMenu";

import { DraggableLayer, DroppableLayer, useDragAndDropState } from "./DnD";
import { LayerIsOver, LayerIsDrop } from "./SearchAllocatorDnD";
import { Card } from "../../components/lib/Card";
import { useModalState } from "../../utils/hooks";

import type { StateFilterType } from "./filters";

type Props = {
  resourceAllocationId: string;
  searchesUsed: number;
  searchCount: number;
  searchPrice: number;
  searchTier: number;
  setFilters: (value: SetStateAction<StateFilterType>) => void;
};

export default function Header(props: Props) {
  const {
    resourceAllocationId,
    searchesUsed,
    searchCount,
    searchPrice,
    searchTier,
    setFilters,
  } = props;

  const {
    modalState: helpModalState,
    showModal: showHelpModal,
    closeModal: closeHelpModal,
  } = useModalState();

  const {
    modalState: buyModalState,
    showModal: showBuyModal,
    closeModal: closeBuyModal,
  } = useModalState();

  const {
    isOver,
    setIsOver,
    isDrop,
    setIsDrop,
    searchAllocatorInput,
    setSearchAllocatorInput,
  } = useDragAndDropState();

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value || "";
    setFilters((prevState) => ({
      ...prevState,
      filterBy: search,
    }));
  };

  return (
    <>
      <Stack
        fill
        css={{
          gap: "$3",
          flexWrap: "wrap",
          "@lg": {
            flexDirection: "row",
            gap: "$32",
            flexWrap: "nowrap",
          },
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Stack fill css={{ alignItems: "flex-start" }}>
          <Text nowrap css={{ fontSize: "$xl" }}>
            {searchesUsed} searches used
          </Text>
          <TextInput
            icon="search"
            css={{
              backgroundColor: "$white",
              width: "$full",
            }}
            onChange={handleSearchChange}
          />
        </Stack>

        <DraggableLayer
          id={resourceAllocationId}
          disabled={isDrop}
          searchesRemaining={searchCount}
        >
          <DroppableLayer
            id={resourceAllocationId}
            onOver={setIsOver}
            onDrop={setIsDrop}
            onSearchAllocatorInput={setSearchAllocatorInput}
          >
            <Card fill>
              {isOver && <LayerIsOver isAdd={false} />}
              {isDrop && (
                <LayerIsDrop
                  isAdd={false}
                  searchAllocatorInput={searchAllocatorInput}
                  onCancel={() => setIsDrop(false)}
                />
              )}
              <Stack css={{ padding: "$2_5 $14", gap: "$2" }}>
                <Text nowrap css={{ fontSize: "$2xl" }}>
                  {searchCount} searches remaining
                </Text>
                <Alert color="warning" css={{ padding: "$2" }}>
                  <Inline nowrap>
                    <Icon icon="arrows-up-down-left-right" size="xl" />
                    <Text nowrap>Drag and Drop to allocate searches</Text>
                  </Inline>
                </Alert>
              </Stack>
            </Card>
          </DroppableLayer>
        </DraggableLayer>

        <HeaderMenu
          showBuyModal={showBuyModal}
          showHelpModal={showHelpModal}
          setFilters={setFilters}
        />
      </Stack>

      <SearchAllocatorHelpModal show={helpModalState} onClose={closeHelpModal} />
      <SearchPlanMoal
        show={buyModalState}
        searchPrice={searchPrice}
        searchTier={searchTier}
        onClose={closeBuyModal}
      />
    </>
  );
}
