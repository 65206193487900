import React from "react";

import { emptyMap } from "../../../../constants";
import {
  RateResultDataMap,
  RatesDataKey,
  RatesFeedbackDataMap,
  RatesFeedbackDataObject,
} from "../../types";
import { useClassicRatesFeedbackContext } from "./ClassicRatesFeedbackContext";
import { compareDecimals, getFilteredRatesKeys } from "../../constants";
import { useReviewDataContext } from "../../../validator5K_admin/context/ReviewDataContext";
import { useAttemptDataContext } from "../AttemptDataContext";
import { useCreateRateFeedbackAPI, useUpdateRateFeedbackAPI } from "../../hooks";

export type ClassicRatesItemFeedbackContextObject = {
  rateResultId: number;
  rateResult: RateResultDataMap; // in local currency
  rateFeedback: RatesFeedbackDataMap; // in local currency
  initialRateFeedback: RatesFeedbackDataMap; // in local currency
  //
  isRatesEditable: boolean;
  isCommentEditable: boolean;
  //
  hasExistingFeedbackObject: boolean;
  hasAnyInitialRatesFeedback: boolean;
  hasAnyRatesFeedback: boolean;
  hasAnyRatesChanges: boolean;
  hasRequiredRatesFeedback: boolean;
  hasInitialCommentFeedback: boolean;
  hasCommentFeedback: boolean;
  hasCommentChanges: boolean;
  hasStatusChanges: boolean;
  //
  startEditingFeedback: (part: "rates" | "comment") => void;
  stopEditingFeedback: () => void;
  updateFeedbackState: (data: Partial<RatesFeedbackDataObject>) => void;
  updateAttemptFeedbackState: (data: RatesFeedbackDataMap) => void;
  saveFeedback: (data: Partial<RatesFeedbackDataObject>) => Promise<RatesFeedbackDataMap>;
};

export const ClassicRatesItemFeedbackContext =
  React.createContext<ClassicRatesItemFeedbackContextObject>(
    {} as unknown as ClassicRatesItemFeedbackContextObject
  );

export const useClassicRatesItemFeedbackContext = () => {
  return React.useContext(ClassicRatesItemFeedbackContext);
};

export const ClassicRatesItemFeedbackContextProvider = (
  props: React.PropsWithChildren<{
    rateResult: RateResultDataMap;
    rateFeedback: RatesFeedbackDataMap;
  }>
) => {
  const { rateResult, rateFeedback } = props;
  const rateResultId = rateResult.get("id");

  const { attemptId, ratesFeedback: initialAllRatesFeedback } = useAttemptDataContext();
  const {
    firstJobId: jobId,
    firstJobRateType: rateType,
    requiredRates,
  } = useReviewDataContext();
  const {
    editingRatesIds,
    startEditingFeedback: startEditingFeedbackItem,
    stopEditingFeedback: stopEditingFeedbackItem,
    updateFeedbackState: updateFeedbackStateItem,
    updateAttemptFeedbackState: updateAttemptFeedbackStateBase,
  } = useClassicRatesFeedbackContext();

  // API calls

  const createRateFeedback = useCreateRateFeedbackAPI();
  const updateRateFeedback = useUpdateRateFeedbackAPI();

  // derivatives

  const initialRateFeedback = React.useMemo(
    () => initialAllRatesFeedback.get(rateResultId) ?? emptyMap,
    [rateResultId, initialAllRatesFeedback]
  );

  const isRatesEditable = React.useMemo(
    () => editingRatesIds.get(rateResultId) === "rates",
    [editingRatesIds, rateResultId]
  );

  const isCommentEditable = React.useMemo(
    () => isRatesEditable || editingRatesIds.get(rateResultId) === "comment",
    [isRatesEditable, editingRatesIds, rateResultId]
  );

  const hasExistingFeedbackObject = React.useMemo(
    () => !!initialRateFeedback?.size,
    [initialRateFeedback]
  );

  const hasAnyInitialRatesFeedback = React.useMemo(() => {
    if (!initialRateFeedback?.size) return false;
    const ratesKeys = getFilteredRatesKeys(rateType, requiredRates.toJS());
    return ratesKeys.filter((key) => initialRateFeedback?.get(key) != null).length > 0;
  }, [initialRateFeedback, rateType, requiredRates]);

  const hasAnyRatesFeedback = React.useMemo(() => {
    if (!rateFeedback?.size) return false;
    const ratesKeys = getFilteredRatesKeys(rateType, requiredRates.toJS());
    return ratesKeys.filter((key) => rateFeedback?.get(key) != null).length > 0;
  }, [rateFeedback, rateType, requiredRates]);

  const hasAnyRatesChanges = React.useMemo(() => {
    const ratesKeys = getFilteredRatesKeys(rateType, requiredRates.toJS());

    for (let i = 0; i < ratesKeys.length; i++) {
      const key = ratesKeys[i];
      const initialFeedbackValue = initialRateFeedback?.get(key) ?? null;
      let changedFeedbackValue = rateFeedback?.get(key) ?? null;

      if (changedFeedbackValue != null) {
        if (compareDecimals(changedFeedbackValue, initialFeedbackValue ?? 0) !== 0) {
          return true;
        }
      }
    }

    return false;
  }, [rateFeedback, initialRateFeedback, rateType, requiredRates]);

  const hasRequiredRatesFeedback = React.useMemo(() => {
    const requiredKeys = [
      "pay_rate_min",
      "pay_rate_max",
      "markup_min",
      "markup_max",
    ] as unknown as RatesDataKey[];

    return (
      requiredKeys.filter((key) => {
        const rateValue = rateResult.get(key) ?? null;
        const initialFeedbackValue = initialRateFeedback?.get(key) ?? null;
        const changedFeedbackValue = rateFeedback?.get(key) ?? null;
        const resultingFeedbackValue =
          changedFeedbackValue ?? initialFeedbackValue ?? rateValue;

        if (!resultingFeedbackValue) return false;
        if (!rateValue) return true;

        return compareDecimals(resultingFeedbackValue, rateValue) !== 0;
      }).length > 0
    );
  }, [initialRateFeedback, rateFeedback, rateResult]);

  const hasInitialCommentFeedback = React.useMemo(
    () => initialRateFeedback?.get("comment") != null,
    [initialRateFeedback]
  );

  const hasCommentFeedback = React.useMemo(
    () => rateFeedback?.get("comment") != null,
    [rateFeedback]
  );

  const hasCommentChanges = React.useMemo(() => {
    const originalComment = initialRateFeedback?.get("comment") ?? null;
    const currentComment = rateFeedback?.get("comment") ?? null;
    return currentComment !== originalComment;
  }, [rateFeedback, initialRateFeedback]);

  const hasStatusChanges = React.useMemo(() => {
    const originalStatus = initialRateFeedback?.get("status") ?? null;
    const currentStatus = rateFeedback?.get("status") ?? null;
    return currentStatus !== originalStatus;
  }, [rateFeedback, initialRateFeedback]);

  // handlers

  const startEditingFeedback = React.useCallback(
    (part: "rates" | "comment") => startEditingFeedbackItem(rateResultId, part),
    [rateResultId, startEditingFeedbackItem]
  );

  const stopEditingFeedback = React.useCallback(
    () => stopEditingFeedbackItem(rateResultId),
    [rateResultId, stopEditingFeedbackItem]
  );

  const updateFeedbackState = React.useCallback(
    (data: Partial<RatesFeedbackDataObject>) =>
      updateFeedbackStateItem(rateResultId, data),
    [rateResultId, updateFeedbackStateItem]
  );

  const updateAttemptFeedbackState = React.useCallback(
    (data: RatesFeedbackDataMap) => updateAttemptFeedbackStateBase(rateResultId, data),
    [rateResultId, updateAttemptFeedbackStateBase]
  );

  const saveFeedback = React.useCallback(
    async (data: Partial<RatesFeedbackDataObject>) => {
      let savedFeedbackData;

      // API request to save
      if (hasExistingFeedbackObject) {
        savedFeedbackData = await updateRateFeedback(rateFeedback.get("id"), {
          attempt: attemptId,
          ...data,
        });
      } else {
        savedFeedbackData = await createRateFeedback({
          attempt: attemptId,
          job: jobId,
          rate_result: rateResultId,
          ...data,
        });
      }

      // update application state
      if (savedFeedbackData?.size) {
        updateAttemptFeedbackState(savedFeedbackData);
        updateFeedbackState(data);
      }

      return savedFeedbackData!;
    },
    [
      rateResultId,
      attemptId,
      jobId,
      rateFeedback,
      hasExistingFeedbackObject,
      createRateFeedback,
      updateRateFeedback,
      updateFeedbackState,
      updateAttemptFeedbackState,
    ]
  );

  // context values

  const contextValues: ClassicRatesItemFeedbackContextObject = React.useMemo(
    () => ({
      rateResultId,
      rateResult,
      rateFeedback,
      initialRateFeedback,
      //
      isRatesEditable,
      isCommentEditable,
      //
      hasExistingFeedbackObject,
      hasAnyInitialRatesFeedback,
      hasAnyRatesFeedback,
      hasAnyRatesChanges,
      hasRequiredRatesFeedback,
      hasInitialCommentFeedback,
      hasCommentFeedback,
      hasCommentChanges,
      hasStatusChanges,
      //
      startEditingFeedback,
      stopEditingFeedback,
      updateFeedbackState,
      updateAttemptFeedbackState,
      saveFeedback,
    }),
    [
      rateResultId,
      rateResult,
      rateFeedback,
      initialRateFeedback,
      //
      isRatesEditable,
      isCommentEditable,
      //
      hasExistingFeedbackObject,
      hasAnyInitialRatesFeedback,
      hasAnyRatesFeedback,
      hasAnyRatesChanges,
      hasRequiredRatesFeedback,
      hasInitialCommentFeedback,
      hasCommentFeedback,
      hasCommentChanges,
      hasStatusChanges,
      //
      startEditingFeedback,
      stopEditingFeedback,
      updateFeedbackState,
      updateAttemptFeedbackState,
      saveFeedback,
    ]
  );

  // render

  return (
    <ClassicRatesItemFeedbackContext.Provider value={contextValues}>
      {props.children}
    </ClassicRatesItemFeedbackContext.Provider>
  );
};

ClassicRatesItemFeedbackContextProvider.displayName = "RateResultFeedbackContextProvider";

export default ClassicRatesItemFeedbackContext;
