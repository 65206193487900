import React from "react";
import Box from "../../../components/lib/Box";
import { Label, LabelWrapper } from "../../../components/lib/Label";
import Stack from "../../../components/lib/Stack";
import { GroupRadioButtons } from "../../../components/lib/GroupRadioButtons";
import { RoleTypeTooltip } from "../../../components/RoleTypeTooltip";
import { useRateSearchFormStore } from "../../../stores/RateSearchForm";
import { RoleType } from "../types";

type RoleTypeRadio = {
  label: RoleType;
  value: RoleType;
  id: string;
};

const radioButtons: RoleTypeRadio[] = [
  { label: "Registry", value: "Registry", id: "role-type-registry" },
  { label: "Traveler", value: "Traveler", id: "role-type-traveler" },
];

export function RoleTypeGroup() {
  const roleType = useRateSearchFormStore((s) => s.roleType);
  const isHealthcare = useRateSearchFormStore((s) => s.jobTitle?.isHealthcare);
  const setRoleType = useRateSearchFormStore((s) => s.setRoleType);

  if (!isHealthcare) return null;

  return (
    <Box>
      <Stack
        fill
        css={{
          alignItems: "flex-start",
          gap: "0",
        }}
      >
        <LabelWrapper css={{ justifyContent: "flex-start", gap: "$2" }}>
          <Label>Identify Role Type</Label>
          <RoleTypeTooltip />
        </LabelWrapper>
        <GroupRadioButtons
          wrapperProps={{ css: { width: "$full" } }}
          buttons={radioButtons}
          color="brand"
          name="role-type"
          value={roleType}
          onChange={(e) => setRoleType(e.target.value as RoleType)}
        />
      </Stack>
    </Box>
  );
}
