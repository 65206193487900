import PropTypes from "prop-types";
import React from "react";
import { observer } from "mobx-react";
import DetailsTableRow from "./DetailsTableRow";

const ResultLevelCardBillRateDetailsTable = observer((props) => {
  let store = props.store;
  let resultLevel = props.resultLevel;
  let deltaObject = resultLevel.change;
  let currency = props.currency;
  let rateMultiplier = props.rateMultiplier || 1;

  return (
    <table className="table level-data sub-table no-margin">
      <tbody>
        <DetailsTableRow
          store={store}
          hidden={store.hideMax}
          text="Max:"
          prefix={currency}
          value={resultLevel.billRateMax * rateMultiplier}
          change={deltaObject.billRateMaxChange * rateMultiplier}
          changeIcon={deltaObject.billRateMaxChangeIcon}
          changeType={deltaObject.billRateMaxChangeType}
        />

        <DetailsTableRow
          store={store}
          hidden={store.hideMedian}
          text="Median:"
          prefix={currency}
          value={resultLevel.billRateMid * rateMultiplier}
          change={deltaObject.billRateMidChange * rateMultiplier}
          changeIcon={deltaObject.billRateMidChangeIcon}
          changeType={deltaObject.billRateMidChangeType}
        />

        <DetailsTableRow
          store={store}
          hidden={store.hideAverage}
          text="Average:"
          prefix={currency}
          value={resultLevel.billRateAvg * rateMultiplier}
          change={deltaObject.billRateAvgChange * rateMultiplier}
          changeIcon={deltaObject.billRateAvgChangeIcon}
          changeType={deltaObject.billRateAvgChangeType}
        />

        <DetailsTableRow
          store={store}
          hidden={store.hideMin}
          text="Min:"
          prefix={currency}
          value={resultLevel.billRateMin * rateMultiplier}
          change={deltaObject.billRateMinChange * rateMultiplier}
          changeIcon={deltaObject.billRateMinChangeIcon}
          changeType={deltaObject.billRateMinChangeType}
        />
      </tbody>
    </table>
  );
});

ResultLevelCardBillRateDetailsTable.propTypes = {
  store: PropTypes.object.isRequired,
  resultLevel: PropTypes.object.isRequired,
  currency: PropTypes.string,
};

export default ResultLevelCardBillRateDetailsTable;
