// @flow
import * as React from "react";
import LoadingIndicator from "../views/shared/LoadingIndicator";
import SelectableItem from "../views/ratecards/components/SelectableItem";
import ContainerSection from "../views/ratecards/components/ContainerSection";
import SearchBox from "./SearchBox";
import FilterModal from "./FilterModal";
import SelectableItemsList from "./SelectableItemsList";
import type { SortDirection } from "../models/Sort";
import type { SortIconKind } from "./SortIcon";
import SortAscToggleButton from "./SortAscToggleButton";
import SortDescToggleButton from "./SortDescToggleButton";

type Item = {
  id: string,
};

type ItemID = string;

type Props<T> = {
  show: boolean,
  title?: string,
  loading: boolean,
  disabled: boolean,
  onSearch: (string) => Promise<any>,
  items: Array<T>,
  hasPrevPage: boolean,
  hasNextPage: boolean,
  onPageForward?: () => void,
  onPageBackward?: () => void,
  selectedItems: ?Array<ItemID>,
  allSelected: boolean,
  renderItem: (T) => React.Node,
  isItemSelected?: (T) => boolean,
  onApply: () => any,
  onToggleSelectAll: () => any,
  onToggleSelectItem: (ItemID) => any,
  onReset: () => void,
  onClose: () => void,
  showSort?: boolean,
  sortKind?: SortIconKind | "date",
  sortDirection?: ?SortDirection,
  sortOnClick?: (SortDirection) => void,
};

class FilterAndSort<T: Item> extends React.Component<Props<T>> {
  render() {
    const {
      loading,
      showSort = false,
      sortKind = "alpha",
      sortDirection,
      sortOnClick = () => {},
    } = this.props;
    let sortAscLabel = "A to Z";
    let sortDescLabel = "Z to A";
    if (sortKind === "date") {
      sortAscLabel = "Oldest First";
      sortDescLabel = "Newest First";
    } else if (sortKind === "numeric" || sortKind === "amount") {
      sortAscLabel = "Smallest First";
      sortDescLabel = "Largest First";
    }

    return (
      <FilterModal
        show={this.props.show}
        title={this.props.title || "Filter"}
        isCreatedFilter={false}
        onApply={this.props.onApply}
        onHide={this.props.onClose}
        onReset={this.props.onReset}
        selectedValuesCount={this.props.selectedItems?.length || 0}
        sortDirection={sortDirection}
      >
        <div>
          {showSort && (
            <ContainerSection>
              <p>Sort:</p>
              <div className="btn-toggle-group pull-left">
                <SortAscToggleButton
                  large
                  iconKind={sortKind === "date" ? "numeric" : sortKind}
                  text={sortAscLabel}
                  selected={sortDirection === "ASC"}
                  onClick={sortOnClick}
                />
                <SortDescToggleButton
                  large
                  iconKind={sortKind === "date" ? "numeric" : sortKind}
                  text={sortDescLabel}
                  selected={sortDirection === "DESC"}
                  onClick={sortOnClick}
                />
              </div>
            </ContainerSection>
          )}
          <ContainerSection className="no-border">
            <div>
              <div className="pull-left">
                <SearchBox
                  onSearch={this.props.onSearch}
                  initialValue={""}
                  disabled={loading}
                />
              </div>
              <div
                className="pull-right rc-center"
                style={{ height: 46, marginLeft: 20 }}
              >
                <button className="btn" onClick={this.props.onToggleSelectAll}>
                  {this.props.allSelected ? "Deselect All" : "Select All"}
                </button>
                <button
                  className="btn"
                  disabled={!(this.props.onPageBackward && this.props.hasPrevPage)}
                  onClick={() => {
                    this.props.onPageBackward && this.props.onPageBackward();
                  }}
                >
                  Prev
                </button>
                <button
                  className="btn"
                  disabled={!(this.props.onPageForward && this.props.hasNextPage)}
                  onClick={() => {
                    this.props.onPageForward && this.props.onPageForward();
                  }}
                >
                  Next
                </button>
              </div>
              <div className="clearfix" />
            </div>
          </ContainerSection>
          {loading ? (
            <LoadingIndicator />
          ) : this.props.items.length > 0 ? (
            <SelectableItemsList className="overlap-tb-padding">
              {this.props.items.map((item) => (
                <SelectableItem
                  key={item.id}
                  item={item}
                  name="location-filter"
                  selected={
                    this.props.isItemSelected
                      ? this.props.isItemSelected(item)
                      : this.props.selectedItems?.indexOf(item.id) !== -1 || false
                  }
                  onChange={(e) => {
                    this.props.onToggleSelectItem(item.id);
                  }}
                >
                  {this.props.renderItem(item)}
                </SelectableItem>
              ))}
            </SelectableItemsList>
          ) : (
            <div
              className="ratecards-list-select-all-section"
              style={{ margin: "40px 0" }}
            >
              <span className="text-center">Nothing to filter by. No items found.</span>
            </div>
          )}
        </div>
      </FilterModal>
    );
  }
}

export default FilterAndSort;
