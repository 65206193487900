import React from "react";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { styled } from "../../stitches.config";

export type IconProps = FontAwesomeIconProps;

// * Commenting now that font awesome was upgraded.
// TODO: Remove comments after some time if everything works in prod.

// const Icon = styled(
// React.forwardRef((props: IconProps, ref) => {
// IMPORTANT:
// We don't pass ref here since current FontAwesomeIcon version doesn't support refs/forwarding
// This causes issues to the <Tooltip> component if you use FontAwesomeIcon as a show-up trigger
// The workaround for Tooltip usages can be wrapping FontAwesomeIcon into <span>, like following:
// <Tooltip><span><FontAwesomeIcon</span></Tooltip>
// Otherwise the Tooltip is not gonna work.
// TODO: consider upgrading "react-fontawesome" lib up to the version providing proper refs support,
// TODO: and then pass ref prop to the FontAwesomeIconBase component.
// return <FontAwesomeIcon {...props} />;
// })
// );

const Icon = styled(
  React.forwardRef<SVGSVGElement, IconProps>((props, ref) => (
    <FontAwesomeIcon {...props} ref={ref} />
  ))
);

Icon.displayName = "Icon";

export default Icon;
