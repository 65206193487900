import React from "react";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";

import { Nav, NavLeft, NavRight, NavItems, NavItem } from "../../../components/NavBar2";
import { NavBarLink } from "../../../components/lib/Link";
import Text from "../../../components/lib/Text";
import Logo from "../../../components/Logo";
import Icon from "../../../components/lib/Icon";
import Spacer from "../../../components/lib/Spacer";
import Inline from "../../../components/lib/Inline";

import { useVal5KPublicContext } from "../context/Val5KPublicContext";
import { baseUrlPath } from "../constants";
import { unauthorizeUser } from "../auth";

const navBarItems = [
  {
    key: "surveys-list",
    to: `${baseUrlPath}/surveys`,
    label: "Surveys",
    icon: "list" as IconProp,
    hint: "Surveys to validate",
  },
  {
    key: "surveys-history",
    to: `${baseUrlPath}/history`,
    label: "History",
    icon: "history" as IconProp,
    hint: "History of validations",
  },
];

function Val5KNavBar() {
  const { auth } = useVal5KPublicContext();

  const handleSignOut = () => {
    unauthorizeUser();
    document.location.reload();
  };

  const handleMenuItemActive = (path: string) => {
    if (globalThis.location.pathname === path) {
      return true;
    }

    if (
      globalThis.location.pathname === baseUrlPath &&
      path === `${baseUrlPath}/surveys`
    ) {
      return true;
    }

    return false;
  };

  return (
    <Nav css={{ paddingLeft: "$6", paddingRight: "$2" }}>
      <Inline fill>
        {/* quick navigation and main logo block */}
        <NavLeft>
          <NavBarLink to="/" style={{ alignSelf: "center" }}>
            <Logo title="logo" width="140" height="45" />
          </NavBarLink>
          <NavItems>
            {navBarItems.map((item) => {
              return (
                <NavItem key={item.key} active={handleMenuItemActive(item.to)}>
                  <NavBarLink to={item.to} title={item.hint}>
                    <Icon icon={item.icon} />
                    {item.label}
                  </NavBarLink>
                </NavItem>
              );
            })}
          </NavItems>
        </NavLeft>

        <Spacer />

        {/* user credentials and logout block */}
        <NavRight>
          <Inline nowrap css={{ gap: "$2" }}>
            <Text css={{ display: "none", "@md": { display: "inline" } }}>
              <Text thin>authorized as</Text>&nbsp;
              <Text bold>
                {auth.firstName} {auth.lastName} ({auth.email})
              </Text>
            </Text>
            <NavItem onClick={handleSignOut}>
              <NavBarLink title="Authorize as another person">
                <Icon icon="sign-out" />
              </NavBarLink>
            </NavItem>
          </Inline>
        </NavRight>
      </Inline>
    </Nav>
  );
}

Val5KNavBar.displayName = "Val5KNavBar";

export default Val5KNavBar;
