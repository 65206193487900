import React from "react";
import {
  TableBodyCellImpl,
  TableBodyEmptyCellImpl,
  TableBodyRowImpl,
} from "../impl/TableBodyImpl";
import { styled, CSS } from "../../../stitches.config";

import type {
  ValueGetterFunc,
  ValueFormatterFunc,
  SchemaConfigObject,
  CellOnClickHandler,
  RowOnClickHandler,
  RowIdGetterFunction,
  TableData,
  RestfulTableData,
  RowEditorComponent,
  RowData,
} from "../types";

const TD = styled("td");

type TableBodyCellProps<RD = RowData> = {
  rowIdx: number;
  rowId: number | string;
  rowData: RD;
  uniqueKey: string;
  getter: ValueGetterFunc<RD>;
  formatter?: ValueFormatterFunc<RD>;
  selectedCellId?: number | string | null | undefined;
  onCellClick?: CellOnClickHandler<RD>;
  css?: CSS;
};

export const TableBodyCell = <RD = RowData,>(props: TableBodyCellProps<RD>) => {
  const {
    rowIdx,
    rowId,
    rowData,
    uniqueKey,
    getter,
    formatter,
    selectedCellId,
    onCellClick,
    css,
  } = props;
  const isCellSelected = selectedCellId != null && uniqueKey === selectedCellId;
  let value = getter(rowData, rowIdx);

  if (formatter) {
    value = formatter(value, rowData);
  }

  const handleCellClick = React.useCallback(() => {
    if (onCellClick) onCellClick(rowData, value, rowId, uniqueKey);
  }, [onCellClick, rowData, value, rowId, uniqueKey]);

  return (
    <TableBodyCellImpl selected={isCellSelected} onClick={handleCellClick} css={css}>
      {value}
    </TableBodyCellImpl>
  );
};
TableBodyCell.displayName = "TableBodyCell";

type TableBodyEmptyRowProps = {
  bodyEmptyText: React.ReactNode;
};

export const TableBodyEmptyRow = (props: TableBodyEmptyRowProps) => {
  return (
    <TableBodyRowImpl>
      <TableBodyEmptyCellImpl>{props.bodyEmptyText}</TableBodyEmptyCellImpl>
    </TableBodyRowImpl>
  );
};
TableBodyEmptyRow.displayName = "TableBodyEmptyRow";

type TableBodyRowProps<RD = RowData> = {
  schema: SchemaConfigObject<RD>;
  rowIdx: number;
  rowId: string | number;
  rowData: RD;
  selectedRowId?: string | number | null | undefined;
  selectedCellId?: string | number | null | undefined;
  onRowClick?: RowOnClickHandler<RD>;
  onCellClick?: CellOnClickHandler<RD>;
};

export const TableBodyRow = <RD = RowData,>(props: TableBodyRowProps<RD>) => {
  const {
    schema,
    rowIdx,
    rowId,
    rowData,
    selectedRowId,
    selectedCellId,
    onRowClick,
    onCellClick,
  } = props;
  const isRowSelected = selectedRowId != null && rowId === selectedRowId;

  const handleBodyRowClick = React.useCallback(() => {
    if (onRowClick) onRowClick(rowData, rowId);
  }, [rowData, rowId, onRowClick]);

  return (
    <TableBodyRowImpl selected={isRowSelected} onClick={handleBodyRowClick}>
      {schema.columns.map(({ uniqueKey, getter, formatter, css }) => (
        <TableBodyCell
          key={uniqueKey}
          rowIdx={rowIdx}
          rowId={rowId}
          rowData={rowData}
          uniqueKey={uniqueKey}
          getter={getter}
          formatter={formatter}
          selectedCellId={isRowSelected ? selectedCellId : undefined}
          onCellClick={onCellClick}
          css={css}
        />
      ))}
    </TableBodyRowImpl>
  );
};
TableBodyRow.displayName = "TableBodyRow";

interface TableBodyRowEditableProps<RD = RowData> extends TableBodyRowProps<RD> {
  editable: boolean;
  editorImpl?: RowEditorComponent<RD> | null;
  onEditApply?: () => void;
  onEditCancel?: () => void;
  onDeleteRow?: () => void;
}

export const TableBodyRowEditable = <RD = RowData,>(
  props: TableBodyRowEditableProps<RD>
) => {
  const {
    schema,
    rowIdx,
    rowId,
    rowData,
    selectedRowId,
    selectedCellId,
    onRowClick,
    onCellClick,
    //
    editable,
    editorImpl: EditorImpl,
    onEditApply,
    onEditCancel,
    onDeleteRow,
  } = props;
  const isRowSelected = selectedRowId != null && rowId === selectedRowId;

  const renderedRow = (
    <TableBodyRow
      key={rowId}
      schema={schema}
      rowIdx={rowIdx}
      rowId={rowId}
      rowData={rowData}
      selectedRowId={selectedRowId}
      selectedCellId={selectedCellId}
      onRowClick={onRowClick}
      onCellClick={onCellClick}
    />
  );

  if (editable && EditorImpl && isRowSelected) {
    const renderedEditorRow = (
      <TableBodyRowImpl key={`row-${selectedRowId}-editor`}>
        <TD colSpan={999} css={{ background: "$white" }}>
          <EditorImpl
            data={rowData}
            onApply={onEditApply}
            onCancel={onEditCancel}
            onDelete={onDeleteRow}
          />
        </TD>
      </TableBodyRowImpl>
    );

    return (
      <>
        {renderedRow}
        {renderedEditorRow}
      </>
    );
  } else {
    return renderedRow;
  }
};
TableBodyRowEditable.displayName = "TableBodyRowEditable";

type TableBodyProps<RD = RowData> = {
  schema: SchemaConfigObject<RD>;
  data: TableData<RD> | RestfulTableData<RD>;
  bodyEmptyText?: React.ReactNode;
  selectedRowId?: string | number | null | undefined;
  selectedCellId?: string | number | null | undefined;
  rowIdGetter: RowIdGetterFunction<RD>;
  onRowClick?: RowOnClickHandler<RD>;
  onCellClick?: CellOnClickHandler<RD>;
};

export const TableBody = <RD = RowData,>(props: TableBodyProps<RD>) => {
  const {
    schema,
    data,
    bodyEmptyText,
    selectedRowId,
    selectedCellId,
    rowIdGetter,
    onRowClick,
    onCellClick,
  } = props;

  if (data.size > 0) {
    return (
      <>
        {data.toArray().map((rowData, rowIdx) => {
          const rowId = rowIdGetter?.(rowData);

          return (
            <TableBodyRow
              key={rowId != null ? rowId : rowIdx}
              schema={schema}
              rowIdx={rowIdx}
              rowId={rowId}
              rowData={rowData}
              selectedRowId={selectedRowId}
              selectedCellId={selectedCellId}
              onRowClick={onRowClick}
              onCellClick={onCellClick}
            />
          );
        })}
      </>
    );
  } else {
    return <TableBodyEmptyRow key="empty-row" bodyEmptyText={bodyEmptyText} />;
  }
};
TableBody.displayName = "TableBody";

interface TableBodyEditableProps<RD = RowData> extends TableBodyProps<RD> {
  editable: boolean;
  editorImpl?: RowEditorComponent<RD> | null;
  onEditApply?: () => void;
  onEditCancel?: () => void;
  onDeleteRow?: () => void;
}

export const TableBodyEditable = <RD = RowData,>(props: TableBodyEditableProps<RD>) => {
  const {
    schema,
    data,
    bodyEmptyText,
    selectedRowId,
    selectedCellId,
    rowIdGetter,
    onRowClick,
    onCellClick,
    //
    editable,
    editorImpl: EditorImpl,
    onEditApply,
    onEditCancel,
    onDeleteRow,
  } = props;

  if (data.size > 0) {
    return (
      <>
        {data.toArray().map((rowData, rowIdx) => {
          const rowId = rowIdGetter && rowIdGetter(rowData);

          return (
            <TableBodyRowEditable
              key={rowId != null ? rowId : rowIdx}
              schema={schema}
              rowIdx={rowIdx}
              rowId={rowId}
              rowData={rowData}
              selectedRowId={selectedRowId}
              selectedCellId={selectedCellId}
              onRowClick={onRowClick}
              onCellClick={onCellClick}
              editable={editable}
              editorImpl={EditorImpl}
              onEditApply={onEditApply}
              onEditCancel={onEditCancel}
              onDeleteRow={onDeleteRow}
            />
          );
        })}
      </>
    );
  } else {
    return <TableBodyEmptyRow key="empty-row" bodyEmptyText={bodyEmptyText} />;
  }
};
TableBodyEditable.displayName = "TableBodyEditable";

function usePaginatedData<RD = RowData>(
  disablePagination: boolean,
  data: TableData<RD> | RestfulTableData<RD>,
  activePage: number,
  itemsPerPage: number
): TableData<RD> {
  return React.useMemo(() => {
    return disablePagination
      ? data
      : data.slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage);
  }, [disablePagination, data, activePage, itemsPerPage]) as TableData<RD>;
}

interface TableBodyPaginatedProps<RD = RowData> extends TableBodyProps<RD> {
  activePage: number;
  itemsPerPage: number;
  disablePagination: boolean;
}

export const TableBodyPaginated = <RD = RowData,>(props: TableBodyPaginatedProps<RD>) => {
  const { activePage, itemsPerPage, disablePagination, data, ...bodyProps } = props;
  const paginatedData: TableData<RD> = usePaginatedData(
    disablePagination,
    data,
    activePage,
    itemsPerPage
  );

  return <TableBody {...bodyProps} data={paginatedData} />;
};
TableBodyPaginated.displayName = "TableBodyPaginated";

interface TableBodyEditablePaginatedProps<RD = RowData>
  extends TableBodyEditableProps<RD> {
  activePage: number;
  itemsPerPage: number;
  disablePagination: boolean;
}

export const TableBodyEditablePaginated = <RD = RowData,>(
  props: TableBodyEditablePaginatedProps<RD>
) => {
  const { activePage, itemsPerPage, disablePagination, data, ...bodyProps } = props;
  const paginatedData = usePaginatedData(
    disablePagination,
    data,
    activePage,
    itemsPerPage
  );

  return <TableBodyEditable {...bodyProps} data={paginatedData} />;
};
TableBodyEditablePaginated.displayName = "TableBodyEditablePaginated";
