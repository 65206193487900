import { emptyOrderedMap, emptyMap } from "../../constants";
import {
  JobsFeedbackDataOrderedMap,
  QUESTION_ANSWER_FORMATS,
  RATE_FEEDBACK_STATUS_TYPES,
  RATE_FEEDBACK_STATUS_TYPES_TYPE,
  REVIEW_TYPES,
} from "../validator5K_admin/types";
import { Map } from "immutable";

import type {
  AttemptDataMap,
  QuestionsOrderedMap,
  QuestionFeedbackDataMap,
  QuestionsFeedbackOrderedMap,
  RatesFeedbackOrderedMap,
  RateResultsOrderedMap,
  JobFeedbackDataMap,
  ReviewDataMap,
  JobsDataOrderedMap,
} from "./types";
import { baseUrlPath } from "./constants";

export function getByLevelRatesFeedbackFromAttempt(
  attemptData: AttemptDataMap,
  filterByJobId?: number,
  filterByStatus?: RATE_FEEDBACK_STATUS_TYPES_TYPE
) {
  let ratesFeedback =
    attemptData.get("rates_feedback") ?? (emptyOrderedMap as RatesFeedbackOrderedMap);
  if (filterByJobId != null) {
    ratesFeedback.filter((item) => item.get("job") === filterByJobId);
  }
  if (filterByStatus != null) {
    ratesFeedback.filter((item) => item.get("status") === filterByStatus);
  }
  return ratesFeedback;
}

export function getAbsRatesFeedbackFromAttempt(
  attemptData: AttemptDataMap,
  firstJobId: number
) {
  return attemptData
    .get("jobs_feedback", emptyOrderedMap as JobsFeedbackDataOrderedMap)
    .get(firstJobId, emptyMap as JobFeedbackDataMap);
}

export function getQuestionsFeedbackFromAttempt(
  attempt: AttemptDataMap,
  questions: QuestionsOrderedMap,
  questionsFeedback?: QuestionsFeedbackOrderedMap | null
) {
  const attemptId = attempt.get("id");
  let _questionsFeedback = attempt.get("questions_feedback") ?? emptyOrderedMap;

  if (!_questionsFeedback.size && questionsFeedback != null) {
    _questionsFeedback = questionsFeedback || emptyOrderedMap;
  }

  // set default values
  questions.forEach((item) => {
    const questionId = item.get("id");
    const defaultValue = item.get("default_value");
    const feedbackValue = _questionsFeedback.getIn([questionId, "value"]);

    if (feedbackValue == null && defaultValue != null) {
      const questionFeedbackMap = Map({
        attempt: attemptId,
        question: questionId,
        value: defaultValue,
      }) as unknown as QuestionFeedbackDataMap;
      _questionsFeedback = _questionsFeedback.set(
        questionId,
        questionFeedbackMap
      ) as unknown as QuestionsFeedbackOrderedMap;
    }
  });

  return _questionsFeedback;
}

export function checkMissingClassicRatesFeedback(
  rateResults: RateResultsOrderedMap,
  ratesFeedback: RatesFeedbackOrderedMap
): boolean {
  return !!rateResults.some((rateResult) => {
    const rateResultId = rateResult?.get("id");
    const rateFeedbackStatus = ratesFeedback?.getIn(
      [rateResultId, "status"],
      RATE_FEEDBACK_STATUS_TYPES.NOT_VALIDATED
    );

    return rateFeedbackStatus === RATE_FEEDBACK_STATUS_TYPES.NOT_VALIDATED;
  });
}

export function checkMissingOrderedRatesFeedback(
  jobsData: JobsDataOrderedMap,
  ratesFeedback: RatesFeedbackOrderedMap
): boolean {
  const allJobsIdsSet = jobsData.map((i) => i.get("id")).toSet();
  const reviewedJobsIdsSet = ratesFeedback
    .filter((i) => i.get("status") !== RATE_FEEDBACK_STATUS_TYPES.NOT_VALIDATED)
    .map((i) => i.get("job"))
    .toSet();

  return !reviewedJobsIdsSet.equals(allJobsIdsSet);
}

export function checkMissingRequiredQuestionsFeedback(
  questions: QuestionsOrderedMap,
  questionsFeedback: QuestionsFeedbackOrderedMap
): boolean {
  return !!questions.some((question) => {
    if (!question?.get("is_required", false)) return false;

    const questionId = question?.get("id");
    const questionFormat = question?.get("answer_format");
    const feedbackValue = questionsFeedback.getIn([questionId, "value"]);

    return questionFormat === QUESTION_ANSWER_FORMATS.TEXTBOX
      ? !feedbackValue
      : feedbackValue == null;
  });
}

export function getAttemptIdFromUrl(): number {
  const urlParams = new URLSearchParams(window.location.search);
  return Number(urlParams.get("attempt"));
}

export function getSurveyUrlPath(reviewData: ReviewDataMap): string {
  const reviewId = reviewData.get("id");
  const reviewType = reviewData.get("review_type");
  let surveyPath = "";

  if (reviewType === REVIEW_TYPES.CLASSIC) {
    surveyPath = `${baseUrlPath}/surveys/${reviewId}/classic`;
  } else if (reviewType === REVIEW_TYPES.FILL_THE_BLANK) {
    surveyPath = `${baseUrlPath}/surveys/${reviewId}/fill-the-blank`;
  } else if (reviewType === REVIEW_TYPES.ORDERED) {
    surveyPath = `${baseUrlPath}/surveys/${reviewId}/ordered`;
  }

  return surveyPath;
}
