import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import numeral from "numeral";
import ResultLevelCardMarkupDetailsTable from "../components/ResultLevelCardMarkupDetailsTable";

const ResultLevelCardMarkupSection = (props) => {
  let store = props.store;
  let resultLevel = props.resultLevel;

  return (
    <div className={classNames("row", "markup-info", { hidden: store.hideMarkup })}>
      <div className="col-xs-12">
        <table className="table level-data">
          <thead>
            <tr>
              <th>Markup</th>
              <th colSpan="3" className="text-right" />
            </tr>
          </thead>
          <tbody className="text-muted">
            <tr>
              <td className="max-value">
                <div className="max-value-wrapper">
                  <header>
                    <h4>{numeral(resultLevel.markupPctMax).format("0,0.00")}%</h4>
                    <h5 className="unit">Max Markup</h5>
                  </header>
                </div>
              </td>
              <td colSpan="3" className="no-padding">
                <ResultLevelCardMarkupDetailsTable
                  store={store}
                  resultLevel={resultLevel}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

ResultLevelCardMarkupSection.propTypes = {
  store: PropTypes.object.isRequired,
  resultLevel: PropTypes.object.isRequired,
};

export default observer(ResultLevelCardMarkupSection);
