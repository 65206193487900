import React, { useCallback } from "react";

import Grid from "../../../../components/lib/Grid";
import Text from "../../../../components/lib/Text";
import NumberInput from "../../../../components/lib/NumberInput";
import { Label } from "../../../private_index/components/SubmitContractorsWizardPage";
import { RadioGroup, RadioGroupItem } from "../../../../components/lib/RadioGroup";

import { CONTINGENT_RATE_TYPES, CONTINGENT_RATE_TYPES_LABELS } from "../../types";

import type { CONTINGENT_RATE_TYPES_TYPE } from "../../types";
import type { ReviewFromRatecardFromState } from "./index";

type LaborValuesChooseBlockProps = {
  displayRateType: CONTINGENT_RATE_TYPES_TYPE;
  hourlyRateMultiplier: number;
  dailyRateMultiplier: number | null;
  weeklyRateMultiplier: number | null;
  monthlyRateMultiplier: number | null;
  onChange: (updatePageState: Partial<ReviewFromRatecardFromState>) => void;
};

export default function LaborValuesChooseBlock(props: LaborValuesChooseBlockProps) {
  const {
    displayRateType,
    hourlyRateMultiplier,
    dailyRateMultiplier,
    weeklyRateMultiplier,
    monthlyRateMultiplier,
    onChange,
  } = props;

  const getColor = useCallback(
    (value: CONTINGENT_RATE_TYPES_TYPE) => {
      if (value === displayRateType) {
        return "primary";
      }
      if (value === CONTINGENT_RATE_TYPES.DAILY && !dailyRateMultiplier) {
        return "danger";
      }
      if (value === CONTINGENT_RATE_TYPES.WEEKLY && !weeklyRateMultiplier) {
        return "danger";
      }
      if (value === CONTINGENT_RATE_TYPES.MONTHLY && !monthlyRateMultiplier) {
        return "danger";
      }
      return "primary";
    },
    [displayRateType, dailyRateMultiplier, weeklyRateMultiplier, monthlyRateMultiplier]
  );

  const cleanupValue = useCallback(
    (value: number | null | undefined): number | string => {
      return Number.isNaN(value) ? "" : value ?? "";
    },
    []
  );

  return (
    <>
      <Label>
        For contingent labor values choose how values should be calculated and displayed
        for validators:
      </Label>
      <RadioGroup
        value={displayRateType}
        onValueChange={(value: CONTINGENT_RATE_TYPES_TYPE) =>
          onChange({ displayRateType: value })
        }
      >
        <Grid
          css={{
            gridTemplateColumns: "repeat(3, auto)",
            gridColumnGap: "$2",
            gridRowGap: "$1",
            alignItems: "center",
          }}
        >
          <RadioGroupItem value={CONTINGENT_RATE_TYPES.HOURLY}>
            {CONTINGENT_RATE_TYPES_LABELS[CONTINGENT_RATE_TYPES.HOURLY]}
          </RadioGroupItem>
          <Text>multiplier = </Text>
          <NumberInput size="small" value={hourlyRateMultiplier} disabled />

          <RadioGroupItem value={CONTINGENT_RATE_TYPES.DAILY}>
            {CONTINGENT_RATE_TYPES_LABELS[CONTINGENT_RATE_TYPES.DAILY]}
          </RadioGroupItem>
          <Text>multiplier = </Text>
          <NumberInput
            size="small"
            value={cleanupValue(dailyRateMultiplier)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange({ dailyRateMultiplier: e.target.valueAsNumber })
            }
            disabled={displayRateType !== CONTINGENT_RATE_TYPES.DAILY}
            color={getColor(CONTINGENT_RATE_TYPES.DAILY)}
          />

          <RadioGroupItem value={CONTINGENT_RATE_TYPES.WEEKLY}>
            {CONTINGENT_RATE_TYPES_LABELS[CONTINGENT_RATE_TYPES.WEEKLY]}
          </RadioGroupItem>
          <Text>multiplier = </Text>
          <NumberInput
            size="small"
            value={cleanupValue(weeklyRateMultiplier)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange({ weeklyRateMultiplier: e.target.valueAsNumber })
            }
            disabled={displayRateType !== CONTINGENT_RATE_TYPES.WEEKLY}
            color={getColor(CONTINGENT_RATE_TYPES.WEEKLY)}
          />

          <RadioGroupItem value={CONTINGENT_RATE_TYPES.MONTHLY}>
            {CONTINGENT_RATE_TYPES_LABELS[CONTINGENT_RATE_TYPES.MONTHLY]}
          </RadioGroupItem>
          <Text>multiplier = </Text>
          <NumberInput
            size="small"
            value={cleanupValue(monthlyRateMultiplier)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange({ monthlyRateMultiplier: e.target.valueAsNumber })
            }
            disabled={displayRateType !== CONTINGENT_RATE_TYPES.MONTHLY}
            color={getColor(CONTINGENT_RATE_TYPES.MONTHLY)}
          />
        </Grid>
      </RadioGroup>
    </>
  );
}

LaborValuesChooseBlock.displayName = "LaborValuesChooseBlock";
