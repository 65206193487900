import React from "react";
import moment from "moment";
import { fromJS } from "immutable";

import { styled } from "../../../stitches.config";

import Tooltip from "../lib/Tooltip";
import Stack from "../../../components/lib/Stack";
import Box from "../../../components/lib/Box";
import Inline from "../../../components/lib/Inline";
import { SimpleLink } from "../../../components/lib/Link";
import Text, { TextOverflow } from "../../../components/lib/Text";
import Icon from "../../../components/lib/Icon";

import SavingsBarChart, { SAVINGS_BAR_CHART_SIZES } from "./SavingsBarChart";
import Description from "./Description";

import { FilterTypes } from "../../../components/tables/constants";
import {
  RATE_TYPES_OPTIONS,
  JOB_TITLE_MATCH_TYPES_OPTIONS,
  BOOLEAN_FILTER_VALUES_OPTIONS,
  dateFormatter,
  decimalFormatter as baseDecimalFormatter,
  percentFormatter as basePercentFormatter,
} from "../constants";

import {
  RATE_TYPES,
  RATE_TYPES_LABELS,
  SKILLS_LEVELS,
  SKILLS_LEVELS_ROMAN_LABELS,
  JOB_TITLE_MATCH_TYPES_LABELS,
  SKILLS_LEVELS_TYPE,
  JOB_TITLE_MATCH_TYPES_TYPE,
} from "../types";
import type {
  RATE_TYPES_TYPE,
  ContractorDataMap,
  TableColumnSpecsObject,
} from "../types";

const SKILLS_LEVELS_FILTER_ROMAN_OPTIONS = [
  {
    value: SKILLS_LEVELS.JUNIOR,
    label: SKILLS_LEVELS_ROMAN_LABELS[SKILLS_LEVELS.JUNIOR],
  },
  {
    value: SKILLS_LEVELS.INTERMEDIATE,
    label: SKILLS_LEVELS_ROMAN_LABELS[SKILLS_LEVELS.INTERMEDIATE],
  },
  {
    value: SKILLS_LEVELS.SENIOR,
    label: SKILLS_LEVELS_ROMAN_LABELS[SKILLS_LEVELS.SENIOR],
  },
  { value: SKILLS_LEVELS.LEAD, label: SKILLS_LEVELS_ROMAN_LABELS[SKILLS_LEVELS.LEAD] },
  { value: SKILLS_LEVELS.GURU, label: SKILLS_LEVELS_ROMAN_LABELS[SKILLS_LEVELS.GURU] },
];

const SavingsColorMark = styled("div", {
  display: "inline-block",
  width: "14px",
  height: "14px",
  verticalAlign: "middle",
});

type SavingsTooltipProps = {
  value: number;
  minValue: number;
  maxValue: number;
  potentialSavings: number;
  achievedSavings: number;
  currencySymbol: string;
};

const SavingsTooltip = (props: SavingsTooltipProps) => {
  const { value, minValue, maxValue, potentialSavings, achievedSavings, currencySymbol } =
    props;

  const tooltipContent = (
    <Stack css={{ padding: "$2", maxWidth: "none", gap: "$4" }}>
      <Box css={{ padding: "$4" }}>
        <SavingsBarChart
          value={value}
          minValue={minValue}
          maxValue={maxValue}
          currencySymbol={currencySymbol}
        />
      </Box>
      <Stack nogap css={{ alignItems: "start", width: "$full" }}>
        <Inline fill css={{ gap: "$2" }}>
          <SavingsColorMark css={{ backgroundColor: "$danger" }} />
          <span>Potential Additional Savings:</span>
          <b>{baseDecimalFormatter(potentialSavings, false, currencySymbol)}</b>
        </Inline>
        <Inline fill css={{ gap: "$2" }}>
          <SavingsColorMark css={{ backgroundColor: "$success" }} />
          <span>Achieved Avoidance Savings:</span>
          <b>{baseDecimalFormatter(achievedSavings, false, currencySymbol)}</b>
        </Inline>
      </Stack>
    </Stack>
  );

  return (
    <Tooltip
      side="top"
      content={tooltipContent}
      arrowProps={{ width: 20, height: 10, css: { fill: "$white" } }}
      contentProps={{
        css: {
          backgroundColor: "$white",
          color: "$secondary",
          border: "1px solid $primaryLight",
        },
      }}
    >
      <div>
        <SavingsBarChart
          value={value}
          minValue={minValue}
          maxValue={maxValue}
          currencySymbol={currencySymbol}
          size={SAVINGS_BAR_CHART_SIZES.SMALL}
        />
      </div>
    </Tooltip>
  );
};
SavingsTooltip.displayName = "SavingsTooltip";

const unitsGetter = (row: ContractorDataMap) =>
  row.getIn(["processed", "currency_symbol"]) ?? null;
const decimalFormatter = (value: number, row: ContractorDataMap): string =>
  baseDecimalFormatter(value, false, unitsGetter(row));
const percentFormatter = (value: number, row: ContractorDataMap): string =>
  basePercentFormatter(value, false);

export const contractorValuesColumnsSpecs: TableColumnSpecsObject<ContractorDataMap>[] = [
  {
    type: "column",
    uniqueKey: "id",
    title: "UID",
    getter: (row) => row.get("id"),
    filterType: FilterTypes.NUMBERS_RANGE,
  },
  {
    type: "column",
    uniqueKey: "bill_rate_savings",
    title: "Savings: Bill",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.FTE ? null : row.get("bill_rate"),
    formatter: (value, row) => {
      const rateType = row.get("rate_type") || RATE_TYPES.CONTRACT;
      const currencySymbol = row.getIn(["processed", "currency_symbol"]);
      const minValue =
        rateType === RATE_TYPES.FTE
          ? null
          : row.getIn(["market_analysis", "market_bill_rate_min"]);
      const maxValue =
        rateType === RATE_TYPES.FTE
          ? null
          : row.getIn(["market_analysis", "market_bill_rate_max"]);
      const hasAllValues =
        [value, minValue, maxValue].filter((i) => i != null).length === 3;

      if (!hasAllValues) return null;

      let potentialSavings =
        rateType === RATE_TYPES.FTE
          ? null
          : row.getIn(["market_analysis", "bill_rate_potential_savings"]);
      let achievedSavings =
        rateType === RATE_TYPES.FTE
          ? null
          : row.getIn(["market_analysis", "bill_rate_achieved_savings"]);

      return (
        <SavingsTooltip
          value={value}
          minValue={minValue}
          maxValue={maxValue}
          potentialSavings={potentialSavings}
          achievedSavings={achievedSavings}
          currencySymbol={currencySymbol}
        />
      );
    },
    sortable: false,
    filterable: false,
  },
  {
    type: "column",
    uniqueKey: "pay_rate_savings",
    title: "Savings: Pay",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.FTE ? null : row.get("pay_rate"),
    formatter: (value, row) => {
      const rateType = row.get("rate_type") || RATE_TYPES.FTE;
      const currencySymbol = row.getIn(["processed", "currency_symbol"]);
      const minValue =
        rateType === RATE_TYPES.FTE
          ? null
          : row.getIn(["market_analysis", "market_pay_rate_min"]);
      const maxValue =
        rateType === RATE_TYPES.FTE
          ? null
          : row.getIn(["market_analysis", "market_pay_rate_max"]);
      const hasAllValues =
        [value, minValue, maxValue].filter((i) => i != null).length === 3;

      if (!hasAllValues) return null;

      let potentialSavings =
        rateType === RATE_TYPES.FTE
          ? null
          : row.getIn(["market_analysis", "pay_rate_potential_savings"]);
      let achievedSavings =
        rateType === RATE_TYPES.FTE
          ? null
          : row.getIn(["market_analysis", "pay_rate_achieved_savings"]);

      return (
        <SavingsTooltip
          value={value}
          minValue={minValue}
          maxValue={maxValue}
          potentialSavings={potentialSavings}
          achievedSavings={achievedSavings}
          currencySymbol={currencySymbol}
        />
      );
    },
    sortable: false,
    filterable: false,
  },
  {
    type: "column",
    uniqueKey: "annual_salary_savings",
    title: "Savings: Salary",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT ? null : row.get("annual_salary"),
    formatter: (value, row) => {
      const rateType = row.get("rate_type") || RATE_TYPES.CONTRACT;
      const currencySymbol = row.getIn(["processed", "currency_symbol"]);
      const minValue =
        rateType === RATE_TYPES.CONTRACT
          ? null
          : row.getIn(["market_analysis", "market_pay_rate_min"]);
      const maxValue =
        rateType === RATE_TYPES.CONTRACT
          ? null
          : row.getIn(["market_analysis", "market_pay_rate_max"]);
      const hasAllValues =
        [value, minValue, maxValue].filter((i) => i != null).length === 3;

      if (!hasAllValues) return null;

      let potentialSavings =
        rateType === RATE_TYPES.CONTRACT
          ? null
          : row.getIn(["market_analysis", "annual_salary_potential_savings"]);
      let achievedSavings =
        rateType === RATE_TYPES.CONTRACT
          ? null
          : row.getIn(["market_analysis", "annual_salary_achieved_savings"]);

      return (
        <SavingsTooltip
          value={value}
          minValue={minValue}
          maxValue={maxValue}
          potentialSavings={potentialSavings}
          achievedSavings={achievedSavings}
          currencySymbol={currencySymbol}
        />
      );
    },
    sortable: false,
    filterable: false,
  },
  {
    type: "column",
    uniqueKey: "date_engaged",
    title: "Effective Date",
    getter: (row) => row.get("date_engaged"),
    formatter: dateFormatter,
    filterType: FilterTypes.DATES_RANGE,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__refresh_timestamp",
    title: "Freshness Date",
    getter: (row) => row.getIn(["market_analysis", "refresh_timestamp"]),
    formatter: dateFormatter,
    filterType: FilterTypes.DATES_RANGE,
    filterExtraProps: { datesFormat: moment.defaultFormat },
  },
  {
    type: "column",
    uniqueKey: "upload__title",
    title: "Upload",
    getter: (row) => row.getIn(["upload", "title"]),
    formatter: (value, row) => {
      // TODO: we need some context about the user here to properly display the link.
      // TODO: we need user_name and its role (PT_ADMIN or not).
      // const programId = row.get('program_id');
      // const uploadId = row.getIn(['upload', 'id']);
      // const uploadPath = `/embed/private_index/programs/${programId}/contractors/uploads/${uploadId}`;
      // const link = <Link className="olive-text" to={uploadPath}><u>{ value }</u></Link>;

      return (
        <Tooltip side="top" content={value}>
          {/* (programId != null && uploadId != null) ? link : <span>value</span> */}
          <span>{value}</span>
        </Tooltip>
      );
    },
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "category",
    title: "Category",
    getter: (row) => row.get("category"),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "source",
    title: "Source",
    getter: (row) => row.get("source"),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "job_title",
    title: "Job Title",
    getter: (row) => row.get("job_title"),
    formatter: (value, row) => (
      <Tooltip side="top" content={value}>
        <TextOverflow>{value}</TextOverflow>
      </Tooltip>
    ),
    css: { maxWidth: "250px" },
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "job_description",
    title: "Job Description",
    getter: (row) => row.get("job_description"),
    formatter: (value) => <Description text={value} />,
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "job_family",
    title: "Job Family",
    getter: (row) => row.get("job_family"),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "processed__job_collection_title",
    title: "Collection Name",
    getter: (row) => row.getIn(["processed", "job_collection_title"]),
    formatter: (value, row) => {
      // @ts-ignore
      const tasteUrl = window?.SERVICES?.taste;

      if (!value) return null;
      // @ts-ignore
      const collectionId = row.getIn(["processed", "job_collection_id"]);
      const link = (
        <SimpleLink
          href={`${tasteUrl}job-title-collections/${collectionId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <u>{value}</u>
        </SimpleLink>
      );
      return (
        <Tooltip side="top" content={value}>
          <TextOverflow>{tasteUrl ? link : value}</TextOverflow>
        </Tooltip>
      );
    },
    link: true,
    css: { maxWidth: "250px" },
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "processed__job_collection_id",
    title: "Collection Id",
    getter: (row) => row.getIn(["processed", "job_collection_id"]),
    formatter: (value, row) => {
      // @ts-ignore
      const tasteUrl = window?.SERVICES?.taste;

      if (!value) return null;
      if (!tasteUrl) return value;

      return (
        <SimpleLink
          href={`${tasteUrl}job-title-collections/${value}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <u>{value}</u>
        </SimpleLink>
      );
    },
    link: true,
    filterType: FilterTypes.NUMBERS_RANGE,
  },
  {
    type: "column",
    uniqueKey: "processed__job_title_id",
    title: "Job Title Id",
    getter: (row) => row.getIn(["processed", "job_title_id"]),
    formatter: (value, row) => {
      // @ts-ignore
      const tasteUrl = window?.SERVICES?.taste;

      if (!value) return null;
      if (!tasteUrl) return value;

      return (
        <SimpleLink
          href={`${tasteUrl}raw-job-titles/${value}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <u>{value}</u>
        </SimpleLink>
      );
    },
    link: true,
    filterType: FilterTypes.NUMBERS_RANGE,
  },
  {
    type: "column",
    uniqueKey: "processed__job_collection_public_title",
    title: "Public Title",
    getter: (row) => row.getIn(["processed", "job_collection_public_title"]),
    formatter: (value, row) => {
      const publicTitleId = row.getIn(["processed", "job_collection_public_title_id"]);
      const result = [
        value,
        publicTitleId != null ? `(#${publicTitleId})` : publicTitleId,
      ]
        .filter((i) => !!i)
        .join(" ");

      return (
        <Tooltip side="top" content={result}>
          <TextOverflow>{result}</TextOverflow>
        </Tooltip>
      );
    },
    css: { maxWidth: "250px" },
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "processed__job_collection_public_title_description",
    title: "Public Title Description",
    getter: (row) => row.getIn(["processed", "job_collection_public_title_description"]),
    formatter: (value) => <Description text={value} />,
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "processed__job_title_match_type",
    title: "Match Type",
    getter: (row: ContractorDataMap) => row.getIn(["processed", "job_title_match_type"]),
    formatter: (value: JOB_TITLE_MATCH_TYPES_TYPE) => JOB_TITLE_MATCH_TYPES_LABELS[value],
    filterType: FilterTypes.ENUMERATION,
    filterOptions: fromJS(JOB_TITLE_MATCH_TYPES_OPTIONS),
  },
  {
    type: "column",
    uniqueKey: "processed__needs_review",
    title: "Needs Review",
    getter: (row) => row.getIn(["processed", "needs_review"]),
    formatter: (value, row) => (
      <Text color={value ? "positive" : undefined}>{value ? "Yes" : "No"}</Text>
    ),
    sortable: false,
    filterType: FilterTypes.ENUMERATION,
    filterOptions: fromJS(BOOLEAN_FILTER_VALUES_OPTIONS),
  },
  {
    type: "column",
    uniqueKey: "market_analysis__collection_limits_client_accepted",
    title: "Client Accepted",
    getter: (row) => row.getIn(["market_analysis", "collection_limits_client_accepted"]),
    formatter: (value, row) => (
      <Text color={value ? "positive" : undefined}>{value ? "Yes" : "No"}</Text>
    ),
    sortable: false,
    filterType: FilterTypes.ENUMERATION,
    filterOptions: fromJS(BOOLEAN_FILTER_VALUES_OPTIONS),
  },
  {
    type: "column",
    uniqueKey: "market_analysis__location_limits_modeled",
    title: "Modeled",
    getter: (row) => row.getIn(["market_analysis", "location_limits_modeled"]),
    formatter: (value, row) => (
      <Text color={value ? "positive" : undefined}>{value ? "Yes" : "No"}</Text>
    ),
    sortable: false,
    filterType: FilterTypes.ENUMERATION,
    filterOptions: fromJS(BOOLEAN_FILTER_VALUES_OPTIONS),
  },
  {
    type: "column",
    uniqueKey: "market_analysis__confidence_score",
    title: "Confidence Score",
    getter: (row) => row.getIn(["market_analysis", "confidence_score"]),
    formatter: percentFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__has_surveys",
    title: "Surveys Exist",
    getter: (row) => row.getIn(["market_analysis", "has_surveys"]),
    formatter: (value, row) => {
      if (!value) return "No";

      const activeSurveysCount =
        row.getIn(["market_analysis", "active_surveys_count"]) || 0;
      const inactiveSurveysCount =
        row.getIn(["market_analysis", "inactive_surveys_count"]) || 0;
      const tooltipContent = (
        <>
          {activeSurveysCount ? (
            <div>
              <b>{activeSurveysCount}</b> active surveys
            </div>
          ) : (
            ""
          )}
          {inactiveSurveysCount ? (
            <div>
              <b>{inactiveSurveysCount}</b> inactive surveys
            </div>
          ) : (
            ""
          )}
        </>
      );

      return (
        <Tooltip side="top" content={tooltipContent}>
          <Text color="positive">Yes</Text>
        </Tooltip>
      );
    },
    sortable: false,
    filterType: FilterTypes.ENUMERATION,
    filterOptions: fromJS(BOOLEAN_FILTER_VALUES_OPTIONS),
  },
  {
    type: "column",
    uniqueKey: "market_analysis__has_validations",
    title: "Validations Exist",
    getter: (row) => row.getIn(["market_analysis", "has_validations"]),
    formatter: (value, row) => {
      if (!value) return "No";

      const validationsCount = row.getIn(["market_analysis", "validations_count"]) || 0;
      const tooltipContent = validationsCount ? (
        <span>
          <b>{validationsCount}</b> validations
        </span>
      ) : (
        ""
      );

      return (
        <Tooltip side="top" content={tooltipContent}>
          <Text color="positive">Yes</Text>
        </Tooltip>
      );
    },
    sortable: false,
    filterType: FilterTypes.ENUMERATION,
    filterOptions: fromJS(BOOLEAN_FILTER_VALUES_OPTIONS),
  },
  {
    type: "column",
    uniqueKey: "processed__industry_title",
    title: "Industry",
    getter: (row) => row.getIn(["processed", "industry_title"]),
    formatter: (value, row) => {
      if (!value) return null;

      value = `${value} (#${row.getIn(["processed", "industry_id"])})`;

      return (
        <Tooltip side="top" content={value}>
          <span>{value}</span>
        </Tooltip>
      );
    },
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "city",
    title: "City",
    getter: (row) => row.get("city"),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "state",
    title: "State/Province",
    getter: (row) => row.get("state"),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "country",
    title: "Country",
    getter: (row) => row.get("country"),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "region",
    title: "Region",
    getter: (row) => row.get("region"),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "is_global_supplier_search",
    title: "Global Supplier Search",
    getter: (row) => row.get("is_global_supplier_search"),
    formatter: (value, row) => (
      <Text color={value ? "positive" : undefined}>{value ? "Yes" : "No"}</Text>
    ),
    sortable: false,
    filterType: FilterTypes.ENUMERATION,
    filterOptions: fromJS(BOOLEAN_FILTER_VALUES_OPTIONS),
  },
  {
    type: "column",
    uniqueKey: "market_analysis__market_level",
    title: "Market Exp Level",
    getter: (row: ContractorDataMap) => row.getIn(["market_analysis", "market_level"]),
    formatter: (value: SKILLS_LEVELS_TYPE) => SKILLS_LEVELS_ROMAN_LABELS[value],
    filterType: FilterTypes.ENUMERATION,
    filterOptions: fromJS(SKILLS_LEVELS_FILTER_ROMAN_OPTIONS),
  },
  {
    type: "column",
    uniqueKey: "rate_type",
    title: "Rate Type",
    getter: (row: ContractorDataMap) => row.get("rate_type"),
    formatter: (value: RATE_TYPES_TYPE) => RATE_TYPES_LABELS[value],
    filterType: FilterTypes.ENUMERATION,
    filterOptions: fromJS(RATE_TYPES_OPTIONS),
  },
  {
    type: "column",
    uniqueKey: "worker_type_title",
    title: "Worker Type",
    getter: (row: ContractorDataMap) =>
      row.getIn(["processed", "worker_type_title"]) || row.get("worker_type_title"),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "pay_rate",
    title: "Your Pay Rate",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.FTE ? null : row.get("pay_rate"),
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
  },
  {
    type: "column",
    uniqueKey: "markup",
    title: "Your Markup",
    getter: (row) => (row.get("rate_type") === RATE_TYPES.FTE ? null : row.get("markup")),
    formatter: percentFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
  },
  {
    type: "column",
    uniqueKey: "markup_amount",
    title: "Your Markup Amount",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.FTE ? null : row.get("markup_amount"),
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
  },
  {
    type: "column",
    uniqueKey: "bill_rate",
    title: "Your Bill Rate",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.FTE ? null : row.get("bill_rate"),
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
  },
  {
    type: "column",
    uniqueKey: "annual_salary",
    title: "Your Annual Salary",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.FTE ? row.get("annual_salary") : null,
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
  },
  {
    type: "column",
    uniqueKey: "burden_perc",
    title: "Your Burden %",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.FTE ? row.get("burden_perc") : null,
    formatter: percentFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
  },
  {
    type: "column",
    uniqueKey: "total_cost",
    title: "Your Total Cost For Year",
    getter: (row) => {
      const value = row.get("total_cost");

      if (value == null) return value;

      let formattedValue = null;
      if (value != null) {
        formattedValue = decimalFormatter(value, row);
      }

      const tooltipContent = (
        <Text centered as={Box} css={{ width: 220 }}>
          value is based on 2000 working hours per year
        </Text>
      );

      const rateType = row.get("rate_type");
      return rateType === RATE_TYPES.FTE ? (
        formattedValue
      ) : (
        <Tooltip side="top" content={tooltipContent}>
          <span>
            {formattedValue} <Icon icon="info-circle" />
          </span>
        </Tooltip>
      );
    },
    // formatter: decimalFormatter
    filterType: FilterTypes.NUMBERS_RANGE,
  },
  {
    type: "column",
    uniqueKey: "manager",
    title: "Manager",
    getter: (row) => row.get("manager"),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "supplier",
    title: "Supplier",
    getter: (row) => row.get("supplier"),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "company_name",
    title: "Company Name",
    getter: (row) => row.get("company_name"),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "bonus_amount",
    title: "Bonus Amount",
    getter: (row) => row.get("bonus_amount"),
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
  },
];
