import React, { Component, ErrorInfo, FunctionComponent, ReactElement } from "react";

type FunctionalComponentCatcher<E extends Error> = FunctionComponent<{
  error: E | null;
  children: ReactElement;
}>;

type ShouldCatch<E extends Error> = (error: E, info: ErrorInfo) => boolean;

type ErrorHandler<E extends Error> = (error: E, info: ErrorInfo) => void;

type BoudaryProps<E extends Error> = {
  ComponentCatcher: FunctionalComponentCatcher<E>;
  shouldCatch?: ShouldCatch<E>;
  onError?: ErrorHandler<E>;
  children: ReactElement;
};

type BoudaryState<E extends Error> = { error: E | null };

class ErrorBoundary<ErrorType extends Error> extends Component<
  BoudaryProps<ErrorType>,
  BoudaryState<ErrorType>
> {
  constructor(props: BoudaryProps<ErrorType>) {
    super(props);

    this.state = {
      error: null,
    };
  }

  componentDidCatch(error: ErrorType, info: ErrorInfo) {
    // Validate if error shoulbe be caught by this boundry, otherwise re-throw.
    if (this.props.shouldCatch && !this.props.shouldCatch(error, info)) {
      throw error;
    }
    // Handle Error
    if (this.props.onError) {
      this.props.onError(error, info);
    }
    this.setState({ error });
  }

  render() {
    const Component = this.props.ComponentCatcher;
    return <Component error={this.state.error}>{this.props.children}</Component>;
  }
}

/**
 * Functional react components as Error Boundaries.
 *
 * @param Component Functional error boundary component.
 *
 * @param shouldCatch
 * Function that validates if a given error should be caught by the current error boundary.
 * If no callback is provided the boundary will catch all errors.
 *
 * @param onError An optional callback that is executed when an error is caught.
 *
 * @example
 *
  // Only catch not found errors in this component.
  const shouldCatch = (error: Error) => error instanceof NotFound;

  const NotFoundRateCard = withErrorCatch(({ error, children }) => {
    if (error) {
      return (
        <Alert variant="danger">
          <p>{error.message}</p>
        </Alert>
      );
    }
    return children;
  }, shouldCatch);
 */
export function withErrorCatch<ErrorType extends Error>(
  Component: FunctionalComponentCatcher<ErrorType>,
  shouldCatch?: ShouldCatch<ErrorType>,
  onError?: ErrorHandler<ErrorType>
): FunctionComponent<{ children: ReactElement }> {
  return ({ children }) => (
    <ErrorBoundary
      ComponentCatcher={Component}
      shouldCatch={shouldCatch}
      onError={onError}
    >
      {children}
    </ErrorBoundary>
  );
}
