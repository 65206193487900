import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { NOTIFICATION_CONTENT_TYPE } from "../../../models/Filter";
import LinkButton from "../../../components/lib/LinkButton";
import {
  CardListItem,
  CardListItemLeft,
  CardListItemRight,
  CardListItemLabel,
  CardListSubText,
  SubTextAccent,
} from "../../../components/lib/Card";

const NotificationsListItem = observer(
  class extends Component {
    static contextTypes = {
      router: PropTypes.object,
    };

    constructor(props) {
      super(props);

      this.handleViewDetail = this.handleViewDetail.bind(this);
    }

    handleViewDetail() {
      if (this.props.notification.content.notificationType === "EXPORT_COMPLETE") {
        window.location.href = `/${
          NOTIFICATION_CONTENT_TYPE[this.props.notification.content.notificationType]
        }/${this.props.notification.content.contentId}`;
      } else {
        this.context.router.push({
          pathname: `/${
            NOTIFICATION_CONTENT_TYPE[this.props.notification.content.notificationType]
          }/${this.props.notification.content.contentId}`,
          query: this.context.router.query,
        });
      }
    }

    render() {
      const props = this.props;
      const notification = props.notification;
      const message = notification.message;
      const isRead = notification.isRead;
      const createdDisplay = notification.created.format("MMMM D, YYYY");
      let onItemClick = this.handleViewDetail;

      return (
        <CardListItem selected={!isRead} onClick={onItemClick}>
          <CardListItemLeft>
            <CardListItemLabel>{message}</CardListItemLabel>
            <CardListSubText>
              On <SubTextAccent>{createdDisplay}</SubTextAccent>
            </CardListSubText>
          </CardListItemLeft>
          <CardListItemRight>
            {this.props.notification.content &&
              (this.props.notification.content.notificationType === "EXPORT_COMPLETE" ? (
                <LinkButton
                  to={`/${
                    NOTIFICATION_CONTENT_TYPE[
                      this.props.notification.content.notificationType
                    ]
                  }/${this.props.notification.content.contentId}`}
                  download
                >
                  View
                </LinkButton>
              ) : (
                <LinkButton
                  to={`/${
                    NOTIFICATION_CONTENT_TYPE[
                      this.props.notification.content.notificationType
                    ]
                  }/${this.props.notification.content.contentId}`}
                >
                  View
                </LinkButton>
              ))}
          </CardListItemRight>
        </CardListItem>
      );
    }
  }
);

NotificationsListItem.propTypes = {
  store: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default NotificationsListItem;
