// @flow

export default class JobCategory {
  id: string;
  name: string;
  // TODO: Fill these out once needed
  // createdDate: ?DateTime;
  // shared: boolean;
  // active: boolean;
  // ownerFirstName: ?string;
  // ownerLastName: ?string;
  // nameWithSharedBy: ?string;
  // ownerUserLegacyId: ?string;
  // ownerClientLegacyId: ?string;

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }
}
