// @flow
import React, { Component } from "react";
import FlagIconFactory from "react-flag-icon-css";
import type { FlagIconSizeType } from "react-flag-icon-css/lib/types/flow/enums";

// Please only use `FlagIconFactory` one time in your application, there is no
// need to use it multiple times (it would slow down your app). You may place the
// line below in a `FlagIcon.js` file in your 'components' directory, then
// write `export default FlagIcon` as shown below and import it elsewhere in your app.
// const FlagIcon = FlagIconFactory(React);
// If you are not using css modules, write the following:
const FlagIconComponent = FlagIconFactory(React, { useCssModules: false });

type Props = {
  code: string,
  size?: FlagIconSizeType,
};

export default class FlagIcon extends Component<Props> {
  props: Props;

  render() {
    const { code, ...otherProps } = this.props;

    if (!code) return null;

    return (
      <span className="flag-icon-wrapper">
        {/*// $FlowFixMe code must be one of FlagIconCodeType */}
        <FlagIconComponent code={code.toLocaleLowerCase()} {...otherProps} />
      </span>
    );
  }
}
