export default function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w-]+/g, "") // Remove all non-word chars
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
}

export function uniqueSlug(text, existingSlugs) {
  const slug = slugify(text);
  if (!existingSlugs.hasOwnProperty(slug)) return slug;

  let i = 1;
  while (1) {
    const trySlug = slug + "-" + i;
    if (!existingSlugs.hasOwnProperty(trySlug)) return trySlug;

    i++;
  }
}
