import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react";
import { RATE_TYPE_OPTIONS } from "../../../constants/negotiationWorksheet";

import Inline from "../../../components/lib/Inline";

const ResultSummary = (props) => {
  const {
    currencySymbol,
    rateType,
    payRateHourly,
    billRateHourly,
    totalTax,
    totalMarkup,
    billRateHourlyAfterTax,
    conversionOptions,
  } = props;
  const convertFromHourly = (r, decimals = 2) =>
    RATE_TYPE_OPTIONS[rateType].convertFromHourly(r, conversionOptions).toFixed(decimals);

  return (
    <Inline css={{ gap: "$1", height: "100%", paddingLeft: "$2" }}>
      <span
        className="pt-worksheet__bottom-controls__summary__pay-rate"
        title="Pay Rate"
        data-testid="payRateAmount"
      >
        {currencySymbol}
        {convertFromHourly(payRateHourly)}
      </span>
      <FontAwesomeIcon icon="times" />
      <span
        className="pt-worksheet__bottom-controls__summary__total-markup"
        title="Total Markup"
        data-testid="totalMarkupPercent"
      >
        {parseFloat(totalMarkup).toFixed(2)}%
      </span>
      <span className="pt-worksheet__bottom-controls__summary__equals"> = </span>
      <span
        className="pt-worksheet__bottom-controls__summary__bill-rate"
        title="Bill Rate"
        data-testid="billRateAmount"
      >
        {currencySymbol}
        {convertFromHourly(billRateHourly)}
      </span>
      {totalTax ? (
        <span>
          <FontAwesomeIcon icon="times" />
          <span
            className="pt-worksheet__bottom-controls__summary__total-tax"
            title="Total Tax"
            data-testid="totalTaxPercent"
          >
            {parseFloat(totalTax).toFixed(2)}%
          </span>
          <span className="pt-worksheet__bottom-controls__summary__equals"> = </span>
          <span
            className="pt-worksheet__bottom-controls__summary__bill-rate-after-tax"
            title="Bill Rate After Tax"
            data-testid="billRateAfterTaxAmount"
          >
            {currencySymbol}
            {convertFromHourly(billRateHourlyAfterTax)}
          </span>
        </span>
      ) : null}
    </Inline>
  );
};

export default observer(ResultSummary);
