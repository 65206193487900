import React from "react";

import PromiseButton from "../../../components/lib/PromiseButton";
import Button from "../../../components/lib/Button";
import { ButtonGroupRight } from "../../../components/lib/ButtonGroup";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";

import { usePLIContext } from "../context";
import { useProgramContext } from "../ProgramDataProvider";
import { bulkDeleteCheckDataToImmutableMap } from "../dataConverters";
import { emptyList } from "../constants";

import type {
  BulkDeleteCheckResultMap,
  BulkDeleteCheckResultObject,
} from "../pages/StoredIndexCreate";
import type {
  FiltersQueryObject,
  FiltersQueryOrderedMap,
} from "../../../components/tables/types";
import type { CONTRACTOR_STATUSES_TYPE } from "../types";
import type { FetchAPIResponse } from "../../../types/fetch";
import type { ImmutableSet } from "../../../types/immutable";
import type { ButtonProps } from "../../../components/lib/Button";

export type BulkDeleteContractorsButtonProps = Omit<ButtonProps, "onClick"> & {
  contractorsFiltersQuery: FiltersQueryOrderedMap;
  allowedStatusesForDelete: ImmutableSet<CONTRACTOR_STATUSES_TYPE>;
  onDeleteIsDone: () => Promise<any>;
};

export default function BulkDeleteContractorsButton(
  props: BulkDeleteContractorsButtonProps
) {
  const {
    contractorsFiltersQuery,
    allowedStatusesForDelete,
    onDeleteIsDone,
    ...restProps
  } = props;

  const {
    isPTAdmin,
    showConfirmationModal,
    closeConfirmationModal,
    showModalSuccess,
    showModalWarning,
    showModalError,
    fetchM8API,
    fetchM8FilteringAPI,
  } = usePLIContext();
  const { programId } = useProgramContext();

  // handlers

  const handleBulkDelete = React.useCallback(
    async (idsToDelete, idsToDeleteCount) => {
      try {
        await fetchM8API(`programs/${programId}/contractors/bulk_delete/`, {
          method: "delete",
          data: { ids_to_delete: idsToDelete },
        });
        await onDeleteIsDone?.();
        await closeConfirmationModal();
        showModalSuccess(
          `${idsToDeleteCount} ${
            idsToDeleteCount > 1 ? "rows have" : "row has"
          } been deleted.`
        );
      } catch (err) {
        logAsyncOperationError("bulkDeleteContractors", err);
        showModalError("Error occurred during bulk delete operation.");
      }
    },
    [
      onDeleteIsDone,
      programId,
      fetchM8API,
      showModalSuccess,
      showModalError,
      closeConfirmationModal,
    ]
  );

  const handleCheckForBulkDelete = React.useCallback(async () => {
    if (!isPTAdmin) {
      showModalWarning("You have no permissions to bulk delete data.");
      return;
    }

    const filtersQuery: FiltersQueryObject = {
      status__in: allowedStatusesForDelete.toJS(),
      ...contractorsFiltersQuery.toJS(),
    };
    const response: FetchAPIResponse<BulkDeleteCheckResultObject> =
      await fetchM8FilteringAPI(
        `programs/${programId}/contractors/check_for_bulk_delete/`,
        { data: filtersQuery }
      );
    const data: BulkDeleteCheckResultMap = bulkDeleteCheckDataToImmutableMap(
      response.data
    );
    const idsToDeleteCount = data.get("ids_to_delete_count");
    const idsToDelete = data.get("ids_to_delete") || emptyList;

    const header = "Confirm bulk delete action";
    const message = (
      <span>
        Do you really want to delete <b>{idsToDeleteCount}</b>{" "}
        {idsToDeleteCount > 1 ? "rows" : "row"}?
      </span>
    );
    const footer = (
      <ButtonGroupRight fill css={{ flexDirection: "row-reverse" }}>
        <Button
          size="large"
          title="Cancel changes and close editor"
          onClick={closeConfirmationModal}
        >
          Cancel
        </Button>
        <PromiseButton
          color="danger"
          size="large"
          icon={["far", "trash-alt"]}
          loadingText="Deleting..."
          onClick={() => handleBulkDelete(idsToDelete, idsToDeleteCount)}
        >
          Yes, Delete {`${idsToDeleteCount} ${idsToDeleteCount > 1 ? "rows" : "row"}`}
        </PromiseButton>
      </ButtonGroupRight>
    );

    showConfirmationModal(message, header, footer);

    return data;
  }, [
    allowedStatusesForDelete,
    contractorsFiltersQuery,
    handleBulkDelete,
    programId,
    isPTAdmin,
    fetchM8FilteringAPI,
    showModalWarning,
    showConfirmationModal,
    closeConfirmationModal,
  ]);

  return <PromiseButton onClick={handleCheckForBulkDelete} {...restProps} />;
}
