import React, { useState, useCallback } from "react";
import { Map, fromJS } from "immutable";

import Icon from "../../../components/lib/Icon";
import RateTypeSelect from "./RateTypeSelect";
// @ts-expect-error
import JobCollectionSelect from "../../../components/selects/JobCollectionSelect";
// @ts-expect-error
import Messager from "../../../components/Messager";
// @ts-expect-error
import Sticker from "../../../components/Sticker";
// @ts-expect-error
import LocationSelect from "../../../components/selects/LocationSelect";
// @ts-expect-error
import RegionSelect from "../../../components/selects/RegionSelect";
// @ts-expect-error
import IndustrySelect from "../../../components/selects/IndustrySelect";
import { usePLIContext } from "../../private_index/context";
import Button from "../../../components/lib/Button";
import TextInput from "../../../components/lib/TextInput";
import Stack from "../../../components/lib/Stack";
import Inline from "../../../components/lib/Inline";
import Text from "../../../components/lib/Text";
import TextArea from "../../../components/lib/TextArea";
import { ButtonGroup } from "../../../components/lib/ButtonGroup";
import { emptyList, emptyMap } from "../../../constants";
import {
  Checkbox,
  CheckboxIndicator,
  LabelInline,
} from "../../../components/lib/Checkbox";
import { useMessagerRef } from "../../private_index/hooks";
import { styled } from "../../../stitches.config";
import { reactSelectStyles } from "../constants";
import PromiseButton from "../../../components/lib/PromiseButton";

import type { ShowModalFunc } from "../../../components/modals/MessageModal";
import type { RowEditorComponentProps } from "../../../components/tables/types";
import type {
  JobValidationDataObject,
  JobValidationDataMap,
  JobValidationErrorsList,
  JobValidationWarningsList,
} from "../../private_index/types";
import { ImmutableList } from "../types";

type SelectedValueType = {
  id: string;
  title?: string;
  text?: string;
};

type LocationSelectValue = {
  location_id: string;
  full_title: string | null;
  full_subtitle: string | null;
  type: string | null;
  title: string | null;
  subtitle: string | null;
};

const EditorSegment = styled(Stack, {
  alignItems: "flex-start",
  width: "$full",
  padding: "$5 $3",
  borderBottom: "1px dashed $primaryLight",
  "&:last-child": {
    borderBottom: "none",
  },
  "& > label": {
    marginBottom: "0 !important",
  },
});
EditorSegment.displayName = "EditorSegment";

const getCollectionStickerValue = (data: JobValidationDataMap) => {
  if (!data?.size) return null;

  const collectionTitle = data.get("collection_title");
  const collectionId = data.get("collection_id");

  return collectionTitle != null && collectionId != null
    ? `${collectionTitle} (#${collectionId})`
    : null;
};

const getCollectionSelectValue = (data: JobValidationDataMap) => {
  if (!data?.size) return null;

  const collectionId = data.get("collection_id");
  const collectionTitle = data.get("collection_title");

  return collectionId != null
    ? Map({
        id: collectionId,
        title: collectionTitle,
      })
    : null;
};

const getIndustrySelectValue = (data: JobValidationDataMap) => {
  if (!data?.size) return null;

  // IndustrySelect consumes plain JS objects
  return {
    // we need some fake value for ID to fill select component with initial value
    id: data.get("industry_id") || " ",
    title: data.get("industry_name") || null,
  };
};

const getRateTypeSelectValue = (data: JobValidationDataMap) => {
  return {
    id: String(data.get("rate_type")) || " ",
    title: data.get("rate_type_string") || " ",
  };
};

const getLocationSelectValue = (data: JobValidationDataMap) => {
  if (!data?.size) return null;

  // we need some fake value for ID to fill select component with initial value
  const locations_ids = data.get("locations_ids") as unknown as ImmutableList<string>;
  const id = locations_ids?.first() ?? " ";
  const city = data.get("city");
  const state = data.get("state");
  const country = data.get("country");
  let type = null;
  let title = null;
  let subtitle = null;

  if (city) {
    type = "city";
    title = city.trim();
    subtitle = [state, country]
      .filter((i) => !!i)
      .map((i) => i?.trim())
      .join(", ");
  } else if (state) {
    type = "state";
    title = state.trim();
    subtitle = country.trim();
  } else if (country) {
    type = "country";
    title = country.trim();
  }

  // LocationSelect consumes plain JS objects
  return {
    location_id: id,
    full_title: title,
    full_subtitle: subtitle,
    type,
    title,
    subtitle,
  };
};

const getRegionSelectValue = (data: JobValidationDataMap) => {
  if (!data?.size) return null;

  // we need some fake value for ID to fill select component with initial value
  const id = data.get("region_id") || " ";
  const region = data.get("region");

  return region
    ? {
        id: id,
        text: region,
      }
    : null;
};

const transformIndustrySelectedValue = (
  value: SelectedValueType
): Partial<JobValidationDataObject> => {
  const industryId = value["id"];
  const industryTitle = value["title"];

  if (industryId && industryTitle) {
    return fromJS({
      industry_id: industryId,
      industry_name: industryTitle,
    });
  }

  return emptyMap as unknown as JobValidationDataObject;
};

const transformRateTypeSelectedValue = (
  value: SelectedValueType
): Partial<JobValidationDataObject> => {
  const rateType = value["id"];
  const rateTypeString = value["title"];

  if (rateType && rateTypeString) {
    return fromJS({
      rate_type: rateType,
      rate_type_string: rateTypeString,
    });
  }

  return emptyMap as unknown as JobValidationDataObject;
};

const transformLocationSelectedValue = (
  value: LocationSelectValue,
  showModalError: ShowModalFunc
) => {
  if (value && Object.keys(value).length > 0) {
    const {
      type,
      full_title: title,
      full_subtitle: subtitle,
      location_id: locationId,
    } = value;
    let city = null;
    let state = null;
    let country = null;

    switch (type) {
      case "city":
        [state, country] = subtitle?.split(",").map((i: string) => i.trim()) || [];
        city = title?.trim();
        break;

      case "state":
        country = subtitle?.trim();
        state = title?.trim();
        break;

      case "country":
        country = title?.trim();
        break;

      default:
        showModalError("Error: Bad location type provided.");
        break;
    }

    if (city == null && state == null && country == null) {
      showModalError("Error: Can't parse location correctly.");
    } else {
      let result = fromJS({
        city,
        state,
        country,
      });

      if (locationId) {
        result = result.set("locations_ids", fromJS([locationId]));
      }

      return result;
    }
  }

  return emptyMap;
};

const transformRegionSelectedValue = (value: SelectedValueType) => {
  if (value && Object.keys(value).length > 0) {
    return fromJS({
      region: value["text"],
      region_id: value["id"],
      locations_ids: null,
      country_id: null,
      country: null,
    });
  }

  return emptyMap;
};

type ProblemsBlockProps = {
  warnings: JobValidationWarningsList;
  errors: JobValidationErrorsList;
};

const ProblemsBlock = (props: ProblemsBlockProps) => {
  const { errors, warnings } = props;

  let backgroundColor;
  if (errors.size > 0) {
    backgroundColor = "$dangerLightest";
  } else if (errors.size === 0 && warnings.size > 0) {
    backgroundColor = "$warningLightest";
  } else if (errors.size === 0 && warnings.size === 0) {
    backgroundColor = "$successLightest";
  }

  return (
    <Stack
      fill
      css={{
        backgroundColor: backgroundColor,
        alignItems: "stretch",
        padding: "$5",
        gap: "$2",

        "& h4": {
          margin: "0 !important",
        },

        [`& ${Icon}`]: {
          fontSize: "$3xl",
          verticalAlign: "middle",
          marginRight: "$2",
          color: "$primary",
        },

        [`& ${Inline}`]: {
          paddingLeft: "$3",
          gap: "0",
          fontSize: "$lg",
          alignItems: "flex-start",
        },
      }}
    >
      {errors.size > 0 ? (
        <Text as="h4">We found following problems in selected job:</Text>
      ) : warnings.size > 0 ? (
        <Text as="h4">
          We found only following warnings in selected job. They are not critical and you
          can skip them using button below:
        </Text>
      ) : (
        <Text as="h4">
          <Icon css={{ color: "$successLight !important" }} icon="check-circle" />
          &nbsp;&nbsp;We didn't find any problems with selected job.
        </Text>
      )}
      {errors.toArray().map((error, idx) => {
        const errorSource = error.get("col");
        const errorMessage = error.get("error");

        return (
          <Inline nowrap key={idx}>
            <Icon css={{ color: "$dangerLight !important" }} icon="times-circle" />
            <Stack css={{ gap: "$2", alignItems: "start" }}>
              {errorSource && (
                <Text italic css={{ fontSize: "$sm" }}>
                  {errorSource}:
                </Text>
              )}
              {errorMessage && <Text>{errorMessage}</Text>}
            </Stack>
          </Inline>
        );
      })}
      {warnings.toArray().map((warning, idx) => {
        const warningSource = warning.get("col");
        const warningMessage = warning.get("warning");

        return (
          <Inline nowrap key={idx}>
            <Icon css={{ color: "$warningLight !important" }} icon="exclamation-circle" />
            <Stack css={{ gap: "$2", alignItems: "start" }}>
              {warningSource && (
                <Text italic css={{ fontSize: "$sm" }}>
                  {warningSource}:
                </Text>
              )}
              {warningMessage && <Text>{warningMessage}</Text>}
            </Stack>
          </Inline>
        );
      })}
    </Stack>
  );
};
ProblemsBlock.displayName = "ProblemsBlock";

type JobEditorProps = RowEditorComponentProps<JobValidationDataMap>;

const JobEditor = (props: JobEditorProps) => {
  const { data: defaultData, onApply, onCancel } = props;

  const { store, fetchTasteAPI, fetchGraphQL, showModalError } = usePLIContext();

  const [dataState, setDataState] = useState(defaultData);

  // messaging

  const { messagerRef, showError, showSuccess, showHint, hideMessage } = useMessagerRef();

  // handlers

  const handleTitleChange = useCallback(
    (e) => {
      const value = e.target.value;
      if (value !== dataState.get("title")) {
        setDataState(dataState.set("title", value));
      }
    },
    [dataState]
  );

  const handleDescriptionChange = useCallback(
    (e) => {
      const value = e.target.value;
      if (value !== dataState.get("description")) {
        setDataState(dataState.set("description", value));
      }
    },
    [dataState]
  );

  const handleCollectionRemove = useCallback(
    () =>
      setDataState(dataState.set("collection_id", null).set("collection_title", null)),
    [dataState]
  );

  const handleCollectionSelect = useCallback(
    (value) => {
      if (value?.size) {
        const collectionId = value.get("id");
        const collectionTitle = value.get("title");

        if (collectionId !== dataState.get("collection_id")) {
          setDataState(
            dataState
              .set("collection_id", collectionId)
              .set("collection_title", collectionTitle)
          );
        }
      }
    },
    [dataState]
  );

  const handleIndustryChange = useCallback(
    (value) =>
      setDataState(
        dataState.merge(
          transformIndustrySelectedValue(value)
        ) as unknown as JobValidationDataMap
      ),
    [dataState]
  );

  const handleRateTypeChange = useCallback(
    (value) =>
      setDataState(
        dataState.merge(
          transformRateTypeSelectedValue(value)
        ) as unknown as JobValidationDataMap
      ),
    [dataState]
  );

  const handleLocationChange = useCallback(
    (value) =>
      setDataState(
        dataState.merge(
          transformLocationSelectedValue(value, showModalError)
        ) as unknown as JobValidationDataMap
      ),
    [dataState, showModalError]
  );

  const handleRegionChange = useCallback(
    (value) =>
      setDataState(
        dataState.merge(
          transformRegionSelectedValue(value)
        ) as unknown as JobValidationDataMap
      ),
    [dataState]
  );

  const handleGlobalSupplierSearchFlagChange = useCallback(
    (value) => setDataState(dataState.set("is_global_supplier_search", value)),
    [dataState]
  );

  const handleApplyChanges = useCallback(
    async (data) => {
      if (!onApply) return null;

      showHint("validating data...");

      const updatedData = await onApply(data);
      const errors = updatedData.get("errors") || emptyList;
      const warnings = updatedData.get("warnings") || emptyList;

      if (errors.size + warnings.size > 0) {
        showError("this row still have some problems");
      } else {
        hideMessage();
      }

      return updatedData;
    },
    [onApply, showError, showHint, hideMessage]
  );

  const handlePreApplyChanges = useCallback(async () => {
    let newData = dataState;

    // if something title-related is changed - clear title id - probably would have to create/add new title
    if (
      defaultData.get("title") !== dataState.get("title") ||
      defaultData.get("description") !== dataState.get("description") ||
      defaultData.get("collection_id") !== dataState.get("collection_id")
    ) {
      newData = newData.set("job_title_id", null);
    }

    // if collection match is not good and has been removed
    newData =
      defaultData.get("collection_id") != null && dataState.get("collection_id") == null
        ? newData.set("collection_removed", true)
        : newData.set("collection_removed", false);

    if (newData !== dataState) {
      setDataState(newData);
    }

    await handleApplyChanges(newData);
  }, [dataState, defaultData, handleApplyChanges]);

  const handleSkipWarnings = useCallback(() => {
    if (!onApply) return null;

    const updatedData = onApply(dataState.set("warnings", emptyList), false);
    const errors = updatedData.get("errors") || emptyList;
    const warnings = updatedData.get("warnings") || emptyList;

    if (errors.size + warnings.size === 0) {
      showSuccess("this row is resolved");
    }

    return updatedData;
  }, [dataState, onApply, showSuccess]);

  const hasChanges = dataState !== defaultData;
  const collectionId = dataState.get("collection_id");
  const region = dataState.get("region");
  const errors = defaultData.get("errors") || emptyList;
  const warnings = defaultData.get("warnings") || emptyList;
  const isGlobalSupplierSearchFlag = dataState.get("is_global_supplier_search", false);

  return (
    <Stack fill css={{ alignItems: "flex-start" }}>
      <ProblemsBlock errors={errors} warnings={warnings} />

      <Stack
        nogap
        css={{
          alignItems: "flex-start",
          padding: "0 $9",
          width: "500px",
          "@lg": {
            width: "800px",
          },
        }}
      >
        <Text as="h4">Use the fields below to fix/edit job data:</Text>
        <EditorSegment>
          <label>Job Title:</label>
          <TextInput
            value={dataState != null ? dataState.get("title") : ""}
            onChange={handleTitleChange}
          />
        </EditorSegment>
        <EditorSegment>
          <label>Job Description:</label>
          <TextArea
            fill
            size="small"
            css={{ minHeight: "100px", borderWidth: "1px" }}
            value={dataState != null ? dataState.get("description") : ""}
            onChange={handleDescriptionChange}
          />
        </EditorSegment>
        <EditorSegment>
          <label>Job Title Collection:</label>
          <Sticker
            value={getCollectionStickerValue(dataState)}
            onRemove={handleCollectionRemove}
            removable
          />
          <JobCollectionSelect
            className="full-width"
            fetchTasteAPI={fetchTasteAPI}
            searchPlaceholder="Search by id or title..."
            value={getCollectionSelectValue(dataState)}
            onSelect={handleCollectionSelect}
            disabled={collectionId != null}
          />
        </EditorSegment>
        <EditorSegment>
          <label>Industry:</label>
          <IndustrySelect
            className="full-width"
            store={store.industriesStore}
            value={getIndustrySelectValue(dataState)}
            onChange={handleIndustryChange}
            styles={reactSelectStyles}
          />
        </EditorSegment>

        <EditorSegment>
          <label>Rate Type:</label>
          <RateTypeSelect
            value={getRateTypeSelectValue(dataState)}
            onChange={handleRateTypeChange}
          />
        </EditorSegment>

        {!region && (
          <EditorSegment>
            <label>Location:</label>
            <Stack fill css={{ alignItems: "start", gap: "$2" }}>
              <LocationSelect
                className="full-width"
                fetchGraphQL={fetchGraphQL}
                value={getLocationSelectValue(dataState)}
                onChange={handleLocationChange}
                styles={reactSelectStyles}
              />
              <LabelInline>
                Global Supplier Search
                <Checkbox
                  checked={isGlobalSupplierSearchFlag}
                  title={
                    isGlobalSupplierSearchFlag
                      ? "Mark row as not Global Supplier Search"
                      : "Mark row as Global Supplier Search"
                  }
                  onCheckedChange={handleGlobalSupplierSearchFlagChange}
                >
                  <CheckboxIndicator>
                    <Icon icon="check" />
                  </CheckboxIndicator>
                </Checkbox>
              </LabelInline>
            </Stack>
          </EditorSegment>
        )}

        {region && (
          <EditorSegment>
            <label>Region:</label>
            <Stack fill css={{ alignItems: "start", gap: "$2" }}>
              <RegionSelect
                className="full-width"
                fetchGraphQL={fetchGraphQL}
                value={getRegionSelectValue(dataState)}
                onChange={handleRegionChange}
                styles={reactSelectStyles}
              />

              <LabelInline>
                Global Supplier Search
                <Checkbox
                  checked={isGlobalSupplierSearchFlag}
                  title={
                    isGlobalSupplierSearchFlag
                      ? "Mark row as not Global Supplier Search"
                      : "Mark row as Global Supplier Search"
                  }
                  onCheckedChange={handleGlobalSupplierSearchFlagChange}
                >
                  <CheckboxIndicator>
                    <Icon icon="check" />
                  </CheckboxIndicator>
                </Checkbox>
              </LabelInline>
            </Stack>
          </EditorSegment>
        )}
      </Stack>

      <ButtonGroup css={{ padding: "$3 $4", paddingTop: "0" }}>
        <PromiseButton
          color="brand"
          size="normal"
          title="Apply changes to existing row data"
          loadingText="Apply Changes"
          disabled={!hasChanges}
          onClick={handlePreApplyChanges}
        >
          Apply Changes
        </PromiseButton>
        {warnings.size > 0 && (
          <Button
            color="accent"
            size="normal"
            title="Skip warnings and approve existing row data"
            disabled={errors.size > 0}
            onClick={handleSkipWarnings}
          >
            Skip Warnings
          </Button>
        )}
        <Button size="normal" title="Cancel changes and close editor" onClick={onCancel}>
          Close Editor
        </Button>

        <Messager ref={messagerRef} />
      </ButtonGroup>
    </Stack>
  );
};

JobEditor.displayName = "JobEditor";

export default JobEditor;
