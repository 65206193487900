import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { styled, CSS } from "../../stitches.config";

const StyledPagination = styled("div", {
  color: "$brand",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "6px",
  paddingTop: "15px",
  paddingBottom: "15px",
  fontSize: "18px",
  minWidth: "510px",
});

const FirstPageButton = styled("button", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 0,
  gap: "8px",
  backgroundColor: "white",
  textDecoration: "underline",
  height: "32px",
  width: "110px",
  fontSize: "$base",
  color: "$brandDarker",
  "&:hover": {
    backgroundColor: "$brandBackground",
    color: "$brandDarker",
    borderRadius: "5px",
  },
});

const PrevPageButton = styled("button", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 0,
  backgroundColor: "white",
  height: "32px",
  width: "32px",
  gap: "8px",
  fontSize: "$base",
  color: "$brandDarker",
  "&:hover": {
    backgroundColor: "$brandBackground",
    color: "$brandDarker",
    borderRadius: "5px",
  },
});

const Page = styled("button", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 0,
  backgroundColor: "white",
  height: "32px",
  width: "32px",
  gap: "8px",
  fontSize: "$base",
  color: "$primary",
  variants: {
    active: {
      true: {
        backgroundColor: "$brandBackground",
        color: "$brandDarker",
        borderRadius: "$rounded",
      },
    },
  },
  "&:hover": {
    backgroundColor: "$ptGray100",
    color: "$ptBlack300",
    borderRadius: "$rounded",
  },
});

const PageCollapse = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  fontSize: "32px",
  height: "32px",
  width: "32px",
  color: "$primary",
});

const NextPageButton = styled("button", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 0,
  backgroundColor: "white",
  height: "32px",
  width: "32px",
  gap: "8px",
  fontSize: "$base",
  color: "$brandDarker",
  "&:hover": {
    backgroundColor: "$brandBackground",
    color: "$brandDarker",
    borderRadius: "5px",
  },
});

const LastPageButton = styled("button", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 0,
  gap: "8px",
  fontSize: "$base",
  backgroundColor: "white",
  textDecoration: "underline",
  height: "32px",
  width: "110px",
  color: "$brandDarker",
  "&:hover": {
    backgroundColor: "$brandBackground",
    color: "$brandDarker",
    borderRadius: "5px",
  },
});

export const PageEventTypeEnum = {
  FIRST: "first",
  PREV: "prev",
  NEXT: "next",
  LAST: "last",
  PAGE: "page",
} as const;

export type PageEventTypeEnumType =
  (typeof PageEventTypeEnum)[keyof typeof PageEventTypeEnum];

export type PageEvent =
  | { type: typeof PageEventTypeEnum.FIRST }
  | { type: typeof PageEventTypeEnum.PREV }
  | { type: typeof PageEventTypeEnum.NEXT }
  | { type: typeof PageEventTypeEnum.LAST }
  | { type: typeof PageEventTypeEnum.PAGE; page: number };

interface FullOptions {
  variant: "full";
  currentPage: number;
  numPages: number;
  hidePageCursor?: boolean;
}

interface CompactOptions {
  variant: "compact";
}

interface PaginationProps {
  options: FullOptions | CompactOptions | any;
  onPageClick: (pageEvent: PageEvent) => void;
  css?: CSS;
}

const Pagination = React.forwardRef<HTMLDivElement, PaginationProps>(
  (props, forwardedRef) => {
    function handlePageClick(
      pageEvent: PageEvent,
      event: React.MouseEvent<HTMLButtonElement>
    ) {
      props.onPageClick(pageEvent);
    }

    const {
      options: { currentPage, numPages },
    } = props;
    let pages = [];

    if (props.options.variant === "full") {
      // NOTE: Hacky logic, should be simplified
      let numPages = Math.max(1, props.options.numPages);
      let currentPage = Math.min(props.options.currentPage, numPages);
      let isEndRange = numPages > 4 && currentPage >= numPages - 3;

      if (numPages <= 4 || isEndRange) {
        let begin = isEndRange ? numPages - 4 : 1;

        for (let i = begin; i <= numPages; i++) {
          let p = (
            <Page
              key={i}
              css={{
                cursor: props?.options?.hidePageCursor ? "auto" : "pointer",
                textDecoration: currentPage === i ? "underline" : "none",
              }}
              active={currentPage === i}
              disabled={i === currentPage}
              onClick={handlePageClick.bind(null, {
                type: PageEventTypeEnum.PAGE,
                page: i,
              })}
            >
              {i}
            </Page>
          );
          pages.push(p);
        }
      }

      if (numPages > 4 && !isEndRange) {
        let begin = Math.max(1, currentPage - 1);
        let end = currentPage + 1;
        if (currentPage === 1) {
          end = 3;
        }

        for (let i = begin; i <= end; i++) {
          pages.push(
            <Page
              key={i}
              active={i === currentPage}
              disabled={i === currentPage}
              css={{ textDecoration: currentPage === i ? "underline" : "none" }}
              onClick={handlePageClick.bind(null, {
                type: PageEventTypeEnum.PAGE,
                page: i,
              })}
            >
              {i}
            </Page>
          );
        }

        pages.push(<PageCollapse key="collapsePage">•••</PageCollapse>);
        pages.push(
          <Page
            key="currentPage"
            active={currentPage === numPages}
            disabled={currentPage === numPages}
            css={{ textDecoration: currentPage === numPages ? "underline" : "none" }}
            onClick={handlePageClick.bind(null, {
              type: PageEventTypeEnum.PAGE,
              page: numPages,
            })}
          >
            {numPages}
          </Page>
        );
      }
    }

    return (
      <StyledPagination css={props.css} ref={forwardedRef}>
        <FirstPageButton
          onClick={handlePageClick.bind(null, { type: PageEventTypeEnum.FIRST })}
          disabled={currentPage === 1}
        >
          <FontAwesomeIcon icon="chevron-left" />
          <span>First Page</span>
        </FirstPageButton>
        <PrevPageButton
          onClick={handlePageClick.bind(null, { type: PageEventTypeEnum.PREV })}
          disabled={currentPage === 1}
        >
          <FontAwesomeIcon icon="chevron-left" />
        </PrevPageButton>
        {props.options.variant === "full" && pages}
        <NextPageButton
          onClick={handlePageClick.bind(null, { type: PageEventTypeEnum.NEXT })}
          disabled={currentPage === numPages}
        >
          <FontAwesomeIcon icon="chevron-right" />
        </NextPageButton>
        <LastPageButton
          onClick={handlePageClick.bind(null, { type: PageEventTypeEnum.LAST })}
          disabled={currentPage === numPages}
        >
          <span>Last Page</span>
          <FontAwesomeIcon icon="chevron-right" />
        </LastPageButton>
      </StyledPagination>
    );
  }
);

Pagination.displayName = "Pagination";

export default Pagination;
