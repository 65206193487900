import React, { useCallback, useRef } from "react";
import { styled } from "../../../stitches.config";

import ProgramsTableView from "../components/ProgramsTable";
import piInfoPic from "../../../assets/img/private_index_workflow.jpg";
import { Button, NavigationButton } from "../../../components/lib/Button";
import { ButtonGroupRight } from "../../../components/lib/ButtonGroup";
import Icon from "../../../components/lib/Icon";
import { emptyList } from "../../../constants";
import {
  programsListToImmutableList,
  sampleVideosListToImmutableList,
} from "../dataConverters";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";
import Stack from "../../../components/lib/Stack";
import { SimpleLink } from "../../../components/lib/Link";
import {
  Card,
  CardHeader,
  CardHeaderTitle,
  CardBody,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
} from "../../../components/lib/Card";
import Inline from "../../../components/lib/Inline";
import Box from "../../../components/lib/Box";
import { DEVICE_TYPES, DEVICE_TYPES_TYPE } from "../types";

import type {
  SampleVideoDataObject,
  SampleVideoDataMap,
  SampleVideosDataList,
  ProgramDataMap,
  ProgramDataObject,
  ProgramsDataList,
} from "../types";
import type { CommonChildPageProps } from "../PrivateIndex";
import type { ImmutableList } from "../../../types/immutable";
import type { FetchAPIResponse } from "../../../types/fetch";
import { usePLIContext } from "../context";

const defaultVimeoVideoSizes = {
  [DEVICE_TYPES.DESKTOP]: {
    width: 568,
    height: 320,
  },
  [DEVICE_TYPES.TABLET]: {
    width: 568,
    height: 320,
  },
  [DEVICE_TYPES.MOBILE]: {
    width: 260,
    height: 146,
  },
};

const TopImage = styled("img", {
  maxWidth: "100%",
  "@md": {
    maxHeight: "500px",
  },
});

type SampleVideoItemProps = {
  title: string;
  thumbnailSrc?: string;
  onPlay: () => void;
};

const SampleVideoItem = (props: SampleVideoItemProps) => {
  const { title, thumbnailSrc, onPlay } = props;

  return (
    <Stack
      onClick={onPlay}
      css={{
        width: "170px",
        height: "170px",
        padding: "$6",
        border: "1px solid $primaryLight",
        borderRadius: "$rounded",
        textAlign: "center",
        overflow: "hidden",
        cursor: "pointer",
        "&:hover .fa-play": {
          transform: "scale(1.1)",
        },
        "&:active .fa-play": {
          transform: "scale(0.9)",
        },
        backgroundImage: thumbnailSrc ? `url("${thumbnailSrc}")` : "none",
        backgroundSize: thumbnailSrc ? "100% 100%" : "none",
      }}
    >
      <Box
        css={{
          overflow: "hidden",
          color: thumbnailSrc ? "$textLight" : "$text",
          fontSize: "$2xl",
        }}
      >
        <span>{title}</span>
      </Box>
      <Icon
        icon="play"
        css={{
          color: "$brandLight",
          fontSize: "$7xl",
        }}
      />
    </Stack>
  );
};
SampleVideoItem.displayName = "SampleVideoItem";

type SampleVideoSectionProps = {
  data: SampleVideosDataList;
  deviceType: DEVICE_TYPES_TYPE;
};

const SampleVideosSection = (props: SampleVideoSectionProps) => {
  const { data: sampleVideosData, deviceType } = props;
  const { showConfirmationModal, closeConfirmationModal } = usePLIContext();

  // handlers
  const handlePlaySampleVideo = useCallback(
    (video: SampleVideoDataMap) => {
      const videoSrc = video.get("src");
      const videoVimeoSrc = video.get("vimeo_src");
      const videoTitle = video.get("title");

      if (videoVimeoSrc || videoSrc) {
        const header = videoTitle;
        let message: React.ReactElement | null = null;
        const footer = (
          <ButtonGroupRight fill>
            <Button onClick={closeConfirmationModal}>Close</Button>
          </ButtonGroupRight>
        );

        if (videoVimeoSrc) {
          const vimeoVideoSizes =
            defaultVimeoVideoSizes[deviceType] ||
            defaultVimeoVideoSizes[DEVICE_TYPES.DESKTOP];

          message = (
            <Box fill>
              <iframe
                src={videoVimeoSrc}
                title={videoTitle}
                frameBorder="0"
                // @ts-ignore
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
                {...vimeoVideoSizes}
              />
            </Box>
          );
        } else if (videoSrc) {
          message = (
            <Box fill>
              <video width="100%" src={videoSrc} preload="auto" controls autoPlay />
            </Box>
          );
        }

        showConfirmationModal(message, header, footer);
      }
    },
    [deviceType, showConfirmationModal, closeConfirmationModal]
  );

  return (
    <Card fill>
      <CardHeader>
        <CardHeaderTitle as="h3">Videos</CardHeaderTitle>
      </CardHeader>
      <CardBody>
        <Inline>
          {sampleVideosData.toArray().map((video) => {
            const videoSrc = video.get("src");

            if (!videoSrc) return null;

            const videoId = video.get("id");
            const videoTitle = video.get("title");
            const videoThumbnailSrc = video.get("thumbnail_src");

            return (
              <SampleVideoItem
                key={videoId}
                title={videoTitle}
                thumbnailSrc={videoThumbnailSrc}
                onPlay={() => handlePlaySampleVideo(video)}
              />
            );
          })}
        </Inline>
      </CardBody>
    </Card>
  );
};

SampleVideosSection.displayName = "SampleVideosSection";

const InstructionsSection = () => {
  const { isRegularUser, isClientAdmin, isPTAdmin, router } = usePLIContext();

  return (
    <Card fill>
      <CardHeader>
        <CardHeaderTitle as="h3">Instructions</CardHeaderTitle>
      </CardHeader>
      <CardBody
        css={{
          color: "$primary",
          "& h3": {
            lineHeight: "$2xl !important",
            marginBottom: "$4 !important",
          },
        }}
      >
        {(isRegularUser || isClientAdmin) && (
          <h3>
            - Click "Upload Your Rate Card" button to upload your data in the spreadsheet
            format into a new index
          </h3>
        )}
        {(isRegularUser || isClientAdmin) && (
          <h3>- Download a template in Excel or CSV format</h3>
        )}
        {(isRegularUser || isClientAdmin) && (
          <h3>
            - Populate the template with your current rate card information (Bill Rate is
            minimum, for better results also include markup and if possible, pay rates)
          </h3>
        )}
        {(isRegularUser || isClientAdmin) && (
          <h3>- After upload our representative will contact you to discuss delivery</h3>
        )}
        {isPTAdmin && (
          <h3>
            - Click "Settings" button to edit index parameters, activate, or deactivate an
            index
          </h3>
        )}
        {isPTAdmin && (
          <h3>
            - Click "View Data" button to upload more data, create custom views, and to
            compare your data to the market
          </h3>
        )}
        {(isRegularUser || isClientAdmin) && (
          <h3>
            - Data in each index is visible only to those who have been granted access by
            the administrator
          </h3>
        )}
        {isRegularUser && (
          <h3>
            - To gain access to an Index, click "Request Access" button to alert your
            administrator
          </h3>
        )}
        {(isClientAdmin || isPTAdmin) && (
          <h3>
            - Click "Manage Users" button to manage users access to some particular index
          </h3>
        )}
        {(isRegularUser || isClientAdmin) && (
          <h3>
            - Click "View Data" button to create custom views and to compare your data to
            the market
          </h3>
        )}
        {(isRegularUser || isClientAdmin) && (
          <h3>
            - Contact us at&nbsp;&nbsp;
            <SimpleLink href="tel:1-844-887-5501">
              <Icon icon="phone-alt" /> 1-844-887-5501
            </SimpleLink>
            &nbsp;&nbsp;to get your Private Labor Index!
          </h3>
        )}
        {(isRegularUser || isClientAdmin) && (
          <ButtonGroupRight>
            <NavigationButton
              icon="upload"
              color="brand"
              onClick={() => router.push("/private-index/programs/upload")}
            >
              Upload Your Rate Card
            </NavigationButton>
          </ButtonGroupRight>
        )}
      </CardBody>
    </Card>
  );
};

const SampleProgramIcon = styled("img", {
  display: "inline-block",
  verticalAlign: "middle",
  maxWidth: "150px",
  height: "auto",
  maxHeight: "80px",
});

const SampleProgramDummyIcon = styled(Icon, {
  display: "inline-block",
  verticalAlign: "middle",
  maxWidth: "150px",
  height: "auto",
  maxHeight: "80px",
  fontSize: "$9xl",
  color: "$brandLighter",
});

type SampleProgramItemProps = {
  programId: number;
  title: string;
  iconUrl?: string;
};

const SampleProgramItem = (props: SampleProgramItemProps): React.ReactElement => {
  const { programId, title, iconUrl } = props;
  const { router } = usePLIContext();

  return (
    <Stack
      css={{
        justifyContent: "space-between",
        alignItems: "stretch",
        gap: "$3",
        width: "180px",
        padding: "$3",
        border: "1px solid $primaryLight",
        borderRadius: "$rounded",
        textAlign: "center",
        overflow: "hidden",
      }}
    >
      <Box>
        {iconUrl ? (
          <SampleProgramIcon src={iconUrl} alt="index icon" />
        ) : (
          <SampleProgramDummyIcon icon="image" />
        )}
      </Box>
      <Inline
        css={{
          display: "inline-block",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          fontSize: "$lg",
        }}
      >
        {title}
      </Inline>
      <Button
        color="brand"
        variant="outlined"
        css={{ width: "$full", whiteSpace: "nowrap" }}
        onClick={() => router.push(`/private-index/programs/${programId}`)}
      >
        View Sample Index
      </Button>
    </Stack>
  );
};

SampleProgramItem.displayName = "SampleProgramItem";

type ProgramsSamplesSectionProps = {
  data: ImmutableList<ProgramDataMap>;
};

const ProgramsSamplesSection = (props: ProgramsSamplesSectionProps) => {
  const programsSamples = props.data;

  return (
    <Card fill>
      <CardHeader>
        <CardHeaderTitle as="h3">Sample Indexes</CardHeaderTitle>
      </CardHeader>
      <CardBody>
        <Inline>
          {programsSamples?.size > 0 &&
            programsSamples.toArray().map((program) => {
              const programId = program.get("id");
              const programTitle = program.get("title");
              const programIconUrl = program.get("icon");

              return (
                <SampleProgramItem
                  key={programId}
                  programId={programId}
                  title={programTitle}
                  iconUrl={programIconUrl}
                />
              );
            })}
        </Inline>
      </CardBody>
    </Card>
  );
};

ProgramsSamplesSection.displayName = "ProgramsSamplesSection";

const ProgramsSection = () => {
  const { isPTAdmin, isClientAdmin, isRegularUser, router } = usePLIContext();

  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft>
          <CardHeaderTitle as="h3">Your Indexes</CardHeaderTitle>
        </CardActionsLeft>
        <CardActionsRight>
          {isPTAdmin && (
            <NavigationButton
              icon="plus"
              color="brand"
              onClick={() => router.push("/private-index/programs/create")}
            >
              Create New Index
            </NavigationButton>
          )}
          {(isRegularUser || isClientAdmin) && (
            <NavigationButton
              icon="upload"
              color="brand"
              onClick={() => router.push("/private-index/programs/upload")}
            >
              Upload Your Rate Card
            </NavigationButton>
          )}
        </CardActionsRight>
      </CardActions>
      <CardBody
        css={{
          "& .alert": {
            marginTop: 0,
            marginBottom: 0,
          },
        }}
      >
        <ProgramsTableView />
      </CardBody>
    </Card>
  );
};

ProgramsSection.displayName = "ProgramsSection";

const ProgramsList = (props: CommonChildPageProps) => {
  const { fetchM8API, showModalError, isClientAdmin, isRegularUser } = props;
  const deviceType = DEVICE_TYPES.DESKTOP;

  const [sampleVideos, setSampleVideosState] =
    React.useState<SampleVideosDataList>(emptyList);
  const [programsSamples, setProgramsSamplesState] =
    React.useState<ProgramsDataList>(emptyList);

  // data fetch funcs

  const fetchSampleVideosData = useCallback(async () => {
    try {
      const response: FetchAPIResponse<SampleVideoDataObject[]> = await fetchM8API(
        "videos/samples/"
      );
      const data: SampleVideosDataList = sampleVideosListToImmutableList(response.data);

      setSampleVideosState(data);
    } catch (err: any) {
      logAsyncOperationError("fetchSampleVideosList", err);
      showModalError(
        "Error occurred while retrieving samples videos data. Please, try again later."
      );
    }
  }, [setSampleVideosState, fetchM8API, showModalError]);

  const fetchProgramsSamplesData = useCallback(async () => {
    try {
      const response: FetchAPIResponse<ProgramDataObject[]> = await fetchM8API(
        "programs/samples/"
      );
      const data: ImmutableList<ProgramDataMap> = programsListToImmutableList(
        response.data
      );

      setProgramsSamplesState(data);
    } catch (err: any) {
      logAsyncOperationError("fetchSampleProgramsList", err);
      showModalError(
        "Error occurred while retrieving programs samples data. Please, try again later."
      );
    }
  }, [setProgramsSamplesState, fetchM8API, showModalError]);

  // effects

  const sampleVideosLoadedRef = useRef<boolean>(false);

  React.useEffect(() => {
    if (!sampleVideosLoadedRef.current) {
      fetchSampleVideosData();
      sampleVideosLoadedRef.current = true;
    }
  }, [fetchSampleVideosData, sampleVideosLoadedRef]);

  const programsSamplesLoadedRef = useRef(false);

  React.useEffect(() => {
    if (!programsSamplesLoadedRef.current) {
      fetchProgramsSamplesData();
      programsSamplesLoadedRef.current = true;
    }
  }, [fetchProgramsSamplesData, programsSamplesLoadedRef]);

  const showSampleVideos = sampleVideos.size > 0 && (isRegularUser || isClientAdmin);
  const showProgramsSamples = programsSamples.size > 0;

  return (
    <Stack>
      {(isRegularUser || isClientAdmin) && (
        <Card fill css={{ display: "flex", justifyContent: "center", padding: "$4" }}>
          <TopImage src={piInfoPic} alt="features details" />
        </Card>
      )}
      {showSampleVideos && (
        <SampleVideosSection data={sampleVideos} deviceType={deviceType} />
      )}
      <InstructionsSection />
      {showProgramsSamples && <ProgramsSamplesSection data={programsSamples} />}
      <ProgramsSection />
    </Stack>
  );
};
ProgramsList.displayName = "ProgramsList";

export default ProgramsList;
