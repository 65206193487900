import React, { useMemo } from "react";

import Stack from "../../../../components/lib/Stack";
import Grid from "../../../../components/lib/Grid";
import Alert from "../../../../components/lib/Alert";
import Button from "../../../../components/lib/Button";
import Text from "../../../../components/lib/Text";
import {
  Card,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
  CardHeaderTitle,
  CardBody,
} from "../../../../components/lib/Card";
import {
  REVIEW_PROCESSING_STATUS_TYPES,
  RATE_TYPES,
  RateResultsOrderedMap,
  RatesFeedbackDataMap,
} from "../../types";
import { useReviewDataContext } from "../../context/ReviewDataContext";
import { getDisplayCurrencyChanged } from "../../utils";
import RateTypeSelector from "../../components/RateTypeSelector";
import Rates from "../../components/Rates";
import { emptyOrderedMap, emptyMap } from "../../../../constants";
import DisplayCurrencySelector from "../../components/DisplayCurrencySelector";
import { useRateDisplayContext } from "../../context/RateDisplayContext";

type RatesValidationBlockProps = {
  onRerunMarketSearch: () => void;
};

const RatesValidationBlock = (props: RatesValidationBlockProps) => {
  const { onRerunMarketSearch } = props;
  const {
    reviewData,
    firstJobData,
    isThreeLevelBanding,
    processingStatus,
    isEditable,
    firstJobRateType,
  } = useReviewDataContext();
  const {
    displayRateType,
    displayCurrencyData,
    changeDisplayRateType: handleChangeRateType,
    changeDisplayCurrency: handleChangeCurrency,
    resetDisplayCurrency: handleResetCurrency,
  } = useRateDisplayContext();

  const jobRateResults = firstJobData.get(
    "rate_results",
    emptyOrderedMap as unknown as RateResultsOrderedMap
  );

  const defaultProcessingFailureMessage = "Server error occurred while rates search.";
  const processingMessage =
    reviewData.getIn(["meta", "processing_message"]) || defaultProcessingFailureMessage;

  const jobCurrencyData = firstJobData.get("currency_data") || emptyMap;
  const reviewCurrencyData =
    reviewData.get("display_currency_data") || jobCurrencyData || emptyMap;
  const reviewCurrencyCode = reviewCurrencyData.get("code");
  const isDisplayCurrencyChanged = useMemo(
    () => getDisplayCurrencyChanged(displayCurrencyData.get("code"), reviewCurrencyCode),
    [displayCurrencyData, reviewCurrencyCode]
  );

  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft>
          <CardHeaderTitle as="h3">Rates For Validation</CardHeaderTitle>
        </CardActionsLeft>
        <CardActionsRight>
          {isEditable && processingStatus === REVIEW_PROCESSING_STATUS_TYPES.FAILED && (
            <Button
              icon="sync"
              color="brand"
              variant="outlined"
              onClick={onRerunMarketSearch}
            >
              Rerun Market Search
            </Button>
          )}
          {firstJobRateType !== RATE_TYPES.ANNUAL && jobRateResults.size > 0 && (
            <RateTypeSelector
              rateType={displayRateType}
              job={firstJobData}
              onChange={handleChangeRateType}
            />
          )}
          {displayCurrencyData.size > 0 &&
            !isDisplayCurrencyChanged &&
            jobRateResults.size > 0 && (
              <DisplayCurrencySelector
                currencyData={displayCurrencyData}
                onChange={handleChangeCurrency}
              />
            )}
          {displayCurrencyData.size > 0 && isDisplayCurrencyChanged && (
            <Button color="brand" variant="outlined" onClick={handleResetCurrency}>
              Reset Currency Back
            </Button>
          )}
        </CardActionsRight>
      </CardActions>
      <CardBody>
        <Stack fill css={{ alignItems: "start", gap: "$6" }}>
          {isThreeLevelBanding && (
            <Alert fill color="warning">
              <Text bold>IMPORTANT:</Text> This survey has three level banding results to
              validate.
            </Alert>
          )}
          <Grid
            css={{
              width: "$full",
              gridGap: "$8",
              alignItems: "center",
              fontSize: "$base",
              gridTemplateColumns: "repeat(1, auto)",
              "@md": {
                gridTemplateColumns: "repeat(2, auto)",
              },
              "@lg": {
                gridTemplateColumns: "repeat(3, auto)",
              },
            }}
          >
            {processingStatus === REVIEW_PROCESSING_STATUS_TYPES.FINISHED &&
              jobRateResults.size > 0 &&
              jobRateResults
                .toArray()
                .map((rateResult) => (
                  <Rates
                    key={rateResult.get("id")}
                    rateResult={rateResult}
                    rateFeedback={emptyMap as RatesFeedbackDataMap}
                  />
                ))}
          </Grid>
          {processingStatus === REVIEW_PROCESSING_STATUS_TYPES.PROCESSING && (
            <Alert fill color="warning">
              Performing search to get rates values for the survey...
            </Alert>
          )}
          {processingStatus === REVIEW_PROCESSING_STATUS_TYPES.FAILED && (
            <Alert fill color="danger">
              <Text as="h3">Rates Search Failed.</Text>
              <Text>{processingMessage}</Text>
            </Alert>
          )}
        </Stack>
      </CardBody>
    </Card>
  );
};
RatesValidationBlock.displayName = "RatesValidationBlock";

export default RatesValidationBlock;
