// @flow

import React, { Component } from "react";

type Props = {
  router: Object,
};

class NotFound extends Component<Props> {
  goBack = () => {
    this.props.router.goBack();
  };

  render() {
    const style = {
      minHeight: 300,
      height: "100%",
      display: "flex",
      justifyContent: "center",
      width: "100%",
    };

    const innerStyle = {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    };

    return (
      <div className="view list">
        <div className="rc-container bring-forward">
          <div className="header-action-bar">
            <h2>Rate Card Not Found</h2>
            <p className="subtext">
              This ratecard does not exist. It may have been moved or deleted.
            </p>
            <div className="clearfix" />
          </div>
          <div style={style}>
            <div style={innerStyle}>
              <h1>Rate Card Missing</h1>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFound;
