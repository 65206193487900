import React, { useState, useEffect, useCallback } from "react";
import queryString from "query-string";
import { useSetRecoilState } from "recoil";
import { fromJS } from "immutable";

import Stack from "../../../../components/lib/Stack";
import Box from "../../../../components/lib/Box";
// @ts-expect-error
import { logAsyncOperationError } from "../../../../utils/logging";

import ReviewsPeckingOrderBlock from "../../components/ReviewsPeckingOrderBlock";
import { REVIEW_TYPES, REVIEW_PROCESSING_STATUS_TYPES } from "../../types";
import { emptySet, emptyMap, emptyOrderedMap } from "../../../../constants";
import { useReviewDataContext } from "../../context/ReviewDataContext";
import { reviewGlobalState } from "../../globalState";

import { useIntervalDataRefresh } from "../../../private_index/hooks";

import type { ReviewDataMap } from "../../types";
import type { ImmutableSet } from "../../../../types/immutable";
import type { ReviewGlobalState } from "../../globalState";

import PageHeadBlock from "./PageHeadBlock";
import ReviewParametersBlock from "./ReviewParametersBlock";
import RatesValidationBlock from "./RatesValidationBlock";
import OrderedTitleValidationBlock from "./OrderedTitleValidationBlock";
import ReviewJobTitleBulkUpdateForm from "./ReviewJobTitleBulkUpdateForm";
import ReviewJobDescriptionBulkUpdateForm from "./ReviewJobDescriptionBulkUpdateForm";
import BackToListPageSection from "../../components/BackToListPageSection";
import RateDisplayContextProvider from "../../context/RateDisplayContext";
import { useVal5KAdminContext } from "../../context/Val5KAdminContext";

type JobChangesType = Partial<{
  title: string;
  description: string;
  replacements_resolved: boolean;
}>;

type JobParametersChangesType = Partial<{
  jobTitle: string;
  jobDescription: string;
}>;

type EditingStateType = {
  editing: boolean;
  editingJobsIds: ImmutableSet<number>;
  jobTitle: string | null;
  jobDescription: string | null;
};

const ReviewItemPage = () => {
  const {
    fetchArgusAPI,
    hideModal,
    showModalInfo,
    showModalError,
    showModalSuccess,
    showModalWarning,
    router,
    showConfirmationModal,
    closeConfirmationModal,
  } = useVal5KAdminContext();

  const {
    reviewData,
    firstJobData,
    refreshReviewData: refreshReviewItemData,
    reviewId,
    reviewType,
    reviewsGroupId,
    processingStatus,
    jobsData,
    isClassicSurvey,
    isFillInTheBlankSurvey,
    isOrderedSurvey,
  } = useReviewDataContext();

  // review state

  const setReviewGlobalState = useSetRecoilState<ReviewGlobalState>(reviewGlobalState);

  // editing state

  const [editingState, setEditingState] = useState<EditingStateType>({
    editing: false,
    editingJobsIds: emptySet,
    jobTitle: reviewType === REVIEW_TYPES.ORDERED ? null : firstJobData.get("title"),
    jobDescription:
      reviewType === REVIEW_TYPES.ORDERED ? null : firstJobData.get("description"),
  });

  // effects

  useEffect(() => {
    setEditingState((prevState) => ({
      ...prevState,
      jobTitle: prevState.editing ? prevState.jobTitle : firstJobData.get("title"),
      jobDescription: prevState.editing
        ? prevState.jobDescription
        : firstJobData.get("description"),
    }));
  }, [firstJobData]);

  // handlers

  const handleBackToList = useCallback(
    () => router.push("/admin/val5000/surveys"),
    [router]
  );

  const handleEnterEditing = useCallback(() => {
    setEditingState((prevData) => ({
      ...prevData,
      editing: true,
      editingJobsIds:
        reviewType === REVIEW_TYPES.ORDERED ? emptySet : fromJS([firstJobData.get("id")]),
      jobTitle: reviewType === REVIEW_TYPES.ORDERED ? null : prevData.jobTitle,
      jobDescription:
        reviewType === REVIEW_TYPES.ORDERED ? null : prevData.jobDescription,
    }));
  }, [firstJobData, reviewType]);

  const handleChangeReviewActiveState = useCallback(async () => {
    const nextState = !reviewData.get("is_active", false);

    try {
      await fetchArgusAPI(`reviews/${reviewId}/`, {
        method: "patch",
        data: { is_active: nextState },
      });
      setReviewGlobalState((prevData) => ({
        ...prevData,
        reviewData: prevData.reviewData.set("is_active", nextState),
      }));
    } catch (err: any) {
      logAsyncOperationError("handleChangeReviewActiveState", err);
      showModalError("Error occurred while updating review. Please, try again later.");
    }
  }, [reviewData, reviewId, setReviewGlobalState, fetchArgusAPI, showModalError]);

  const handleCancelChanges = useCallback(() => {
    setEditingState((prevData) => ({
      ...prevData,
      editing: false,
      editingJobsIds: emptySet,
      jobTitle: reviewType === REVIEW_TYPES.ORDERED ? null : firstJobData.get("title"),
      jobDescription:
        reviewType === REVIEW_TYPES.ORDERED ? null : firstJobData.get("description"),
    }));
  }, [firstJobData, reviewType]);

  const handleChange = useCallback((newPageState: Partial<EditingStateType>) => {
    setEditingState((prevData) => ({
      ...prevData,
      ...newPageState,
    }));
  }, []);

  const handleJobsBulkUpdate = useCallback(
    async (jobsIds: number[], data = {}) => {
      try {
        await fetchArgusAPI(`jobs/bulk_update/`, {
          method: "post",
          params: queryString.stringify({ ids_to_update: jobsIds }),
          data: data,
        });
        await refreshReviewItemData();
      } catch (err: any) {
        logAsyncOperationError("handleJobsBulkUpdate", err);
        showModalError(
          "Error occurred while jobs bulk updating. Please, try again later."
        );
      }
    },
    [refreshReviewItemData, fetchArgusAPI, showModalError]
  );

  const handleApplyJobParametersChanges = useCallback(
    async (parametersUpdate: JobParametersChangesType) => {
      const jobsIdsToUpdate = editingState.editingJobsIds || emptySet;
      const changedAttributes: JobChangesType = {};

      if (reviewType === REVIEW_TYPES.ORDERED) {
        if (parametersUpdate.jobTitle) {
          changedAttributes["title"] = parametersUpdate.jobTitle;
        }
        if (parametersUpdate.jobDescription) {
          changedAttributes["description"] = parametersUpdate.jobDescription;
        }
      } else {
        if (
          parametersUpdate.jobTitle &&
          parametersUpdate.jobTitle !== firstJobData.get("title")
        ) {
          changedAttributes["title"] = parametersUpdate.jobTitle;
        }
        if (
          parametersUpdate.jobDescription &&
          parametersUpdate.jobDescription !== firstJobData.get("description")
        ) {
          changedAttributes["description"] = parametersUpdate.jobDescription;
        }
      }

      if (Object.keys(changedAttributes).length > 0 && jobsIdsToUpdate.size > 0) {
        await handleJobsBulkUpdate(jobsIdsToUpdate.toJS(), changedAttributes);

        setEditingState((prevData) => ({
          ...prevData,
          editing: false,
          editingJobsIds: emptySet,
          jobTitle:
            reviewType === REVIEW_TYPES.ORDERED ? null : changedAttributes?.title || null,
          jobDescription:
            reviewType === REVIEW_TYPES.ORDERED
              ? null
              : changedAttributes?.description || null,
        }));
      }

      closeConfirmationModal();
    },
    [
      editingState.editingJobsIds,
      reviewType,
      firstJobData,
      handleJobsBulkUpdate,
      closeConfirmationModal,
    ]
  );

  const handleRequestBulkJobsTitleChange = useCallback(() => {
    const uniqueTitlesSet = jobsData
      .filter((i) => editingState.editingJobsIds.contains(i.get("id")))
      .map((i) => i.get("title"))
      .toSet();

    let jobTitle = editingState.jobTitle;
    if (uniqueTitlesSet.size === 1) {
      jobTitle = uniqueTitlesSet.first();
    } else {
      jobTitle = null;
    }

    const header = "Change Jobs Title";
    const message = (
      <ReviewJobTitleBulkUpdateForm
        defaultValue={jobTitle}
        onApply={handleApplyJobParametersChanges}
        onCancel={closeConfirmationModal}
      />
    );

    showConfirmationModal(message, header);
  }, [
    editingState.editingJobsIds,
    editingState.jobTitle,
    jobsData,
    handleApplyJobParametersChanges,
    showConfirmationModal,
    closeConfirmationModal,
  ]);

  const handleRequestBulkJobsDescriptionChange = useCallback(() => {
    const uniqueDescriptionsSet = jobsData
      .filter((i) => editingState.editingJobsIds.contains(i.get("id")))
      .map((i) => i.get("description"))
      .toSet();

    let jobDescription = editingState.jobDescription;

    if (uniqueDescriptionsSet.size === 1) {
      jobDescription = uniqueDescriptionsSet.first();
      setEditingState((prevData) => ({
        ...prevData,
        jobDescription,
      }));
    } else {
      jobDescription = null;
      setEditingState((prevData) => ({
        ...prevData,
        jobDescription,
      }));
    }

    const header = "Change Jobs Descriptions";
    const message = (
      <ReviewJobDescriptionBulkUpdateForm
        defaultValue={jobDescription}
        onApply={handleApplyJobParametersChanges}
        onCancel={closeConfirmationModal}
      />
    );

    showConfirmationModal(message, header);
  }, [
    editingState.editingJobsIds,
    editingState.jobDescription,
    jobsData,
    handleApplyJobParametersChanges,
    showConfirmationModal,
    closeConfirmationModal,
  ]);

  const handleResolveReplacements = useCallback(() => {
    const allJobsIds = jobsData.toArray().map((job) => job.get("id"));

    if (allJobsIds.length) {
      return handleJobsBulkUpdate(allJobsIds, {
        replacements_resolved: true,
      });
    }
  }, [jobsData, handleJobsBulkUpdate]);

  const handleHandleMarketSearchResults = useCallback(
    (data: ReviewDataMap) => {
      const firstJob = data.get("jobs").first() || emptyMap;
      const firstJobRateResults = firstJob.get("rate_results") || emptyOrderedMap;
      const processingStatus = data.get("processing_status");
      const processingMessage = data.getIn(["meta", "processing_message"]);

      if (
        processingStatus === REVIEW_PROCESSING_STATUS_TYPES.FINISHED &&
        firstJobRateResults.size
      ) {
        showModalSuccess(
          `${firstJobRateResults.size} results found.`,
          "Market search finished."
        );
      } else if (
        processingStatus === REVIEW_PROCESSING_STATUS_TYPES.FAILED &&
        processingMessage
      ) {
        showModalWarning(processingMessage, "Rates search failed.");
      }
    },
    [showModalSuccess, showModalWarning]
  );

  const handleRunMarketSearch = useCallback(async () => {
    if (processingStatus === REVIEW_PROCESSING_STATUS_TYPES.FAILED) {
      showModalInfo("processing...");

      try {
        await fetchArgusAPI(`reviews/run_market_search_for_job/`, {
          method: "post",
          data: { job_id: firstJobData.get("id") },
        });

        const data = await refreshReviewItemData();
        if (data?.size) {
          handleHandleMarketSearchResults(data);
        }
        hideModal();
      } catch (err: any) {
        logAsyncOperationError("handleRunMarketSearch", err);
        showModalError(
          "Error occurred while running market search. Please, try again later."
        );
      }
    }
  }, [
    processingStatus,
    firstJobData,
    fetchArgusAPI,
    showModalInfo,
    hideModal,
    refreshReviewItemData,
    handleHandleMarketSearchResults,
    showModalError,
  ]);

  // refresh data

  const needRefresh = useCallback(
    () => processingStatus === REVIEW_PROCESSING_STATUS_TYPES.PROCESSING,
    [processingStatus]
  );
  useIntervalDataRefresh(refreshReviewItemData, reviewData, needRefresh, 3000);

  // rendering

  return (
    <RateDisplayContextProvider reviewData={reviewData}>
      <Stack>
        <PageHeadBlock
          onGoBack={handleBackToList}
          onToggleActive={handleChangeReviewActiveState}
        />
        <ReviewParametersBlock
          editing={editingState.editing}
          jobTitle={editingState.jobTitle || ""}
          jobDescription={editingState.jobDescription || ""}
          onChangeJobDescription={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
            handleChange({ jobDescription: event.target.value })
          }
          onChangeJobTitle={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange({ jobTitle: event.target.value })
          }
          onResolveReplacements={handleResolveReplacements}
          onEdit={handleEnterEditing}
          onApplyEdit={handleApplyJobParametersChanges}
          onCancelEdit={handleCancelChanges}
        />
        {isClassicSurvey && (
          <RatesValidationBlock onRerunMarketSearch={handleRunMarketSearch} />
        )}
        {isOrderedSurvey && jobsData.size > 0 && (
          <OrderedTitleValidationBlock
            editing={editingState.editing}
            editingJobsIds={editingState.editingJobsIds}
            onEdit={handleEnterEditing}
            onChangeJobsTitle={handleRequestBulkJobsTitleChange}
            onChangeJobsDescription={handleRequestBulkJobsDescriptionChange}
            onCancelEdit={() =>
              handleChange({ editingJobsIds: emptySet, editing: false })
            }
            onResolveReplacements={handleResolveReplacements}
            onSelectJobsForEditing={(selectedJobsIds: ImmutableSet<number>) =>
              handleChange({ editingJobsIds: selectedJobsIds })
            }
          />
        )}
        {(isClassicSurvey || isFillInTheBlankSurvey) && !!reviewsGroupId && (
          // id used for scrolling purposes
          <Box fill id="reviews-group">
            <ReviewsPeckingOrderBlock />
          </Box>
        )}
        <BackToListPageSection onButtonClick={handleBackToList} />
      </Stack>
    </RateDisplayContextProvider>
  );
};
ReviewItemPage.displayName = "ReviewItemPage";

export default ReviewItemPage;
