// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../ratecards/components/Modal";
import ContainerSection from "../ratecards/components/ContainerSection";
import ApplyTagFilterModal from "../shared/modals/ApplyTagFilterModal";
import TagItem from "../ratecards/components/TagItem";
import JobTitleSelectNew from "../../components/selects/JobTitleSelectNew";
import Button from "../../components/lib/Button";
import Inline from "../../components/lib/Inline";
import LinkButton from "../../components/lib/LinkButton";
import TextInput from "../../components/lib/TextInput";
import TextArea from "../../components/lib/TextArea";
import Box from "../../components/lib/Box";
import RsSkillsWithJobDescription from "../ratesearch2/JobTittleSkills.tsx";
import { getSkillFeatureFlag } from "../ratesearch2/rsSearchBySkillsUtil";

const JobDetail = observer((props) => {
  const store = props.store;

  let owner,
    jobLabel,
    tagSection,
    createdDate = null;

  if (store.network.loading) {
    owner = "...";
    createdDate = "...";
    jobLabel = "...";
  } else if (store.jobDetail) {
    owner = store.jobDetail.owner.fullname;
    createdDate = store.jobDetail.created.format("MMMM D, YYYY");
    jobLabel = store.jobDetail.jobLabel;
    tagSection = store.jobDetail.tags.map((tag) => (
      <TagItem
        key={tag.tagId}
        tag={tag}
        onClick={(event) => {
          props.onClick(event, tag.tagId);
        }}
      />
    ));
  }

  var actionBar = (
    <Inline css={{ gap: "$3" }}>
      <Button onClick={store.applyTagState.showTagModal}>Apply Tag</Button>
      <Button icon="edit" onClick={store.showRenameJobModal}>
        Rename
      </Button>
      <Button
        color="danger"
        icon="trash-alt"
        onClick={store.confirmDeleteModal.showModal}
      >
        Delete
      </Button>
    </Inline>
  );

  return (
    <div className="top">
      <div className="above">
        <div className="ratecard-label-header ellipsis max-width-80">
          <h2 className="ellipsis">{jobLabel}</h2>
        </div>
        <Inline>
          <LinkButton color="brand" icon="arrow-left" to={`/job-labels/`}>
            Go Back
          </LinkButton>
          <Button icon="question-circle" onClick={store.showHelp}>
            Help
          </Button>
        </Inline>
      </div>
      <div className="below">
        <div className="ratecard-meta ellipsis">
          <div className="ratecard-owner ellipsis">
            <FontAwesomeIcon icon="user" fixedWidth className="icon" />
            <span />
            <span />
            <span>{owner}</span>
          </div>
          <div className="ratecard-created-at ellipsis">
            <FontAwesomeIcon icon="calendar-alt" fixedWidth className="icon" />
            <span />
            <span />
            <span>Created</span>
            <span> {createdDate}</span>
          </div>
        </div>
        {actionBar}
      </div>
      <div className="tagbox">{tagSection}</div>
      <Modal show={store.showHelpModal} onHide={store.hideHelp}>
        <div className="container-section header">
          <h4>Help</h4>
        </div>
        <div className="container-section footer">
          <p>Here you can see all the details of the Job</p>
        </div>
      </Modal>

      <Modal show={store.renameJobModal.show} onHide={store.renameJobModal.hideModal}>
        <ContainerSection className="header">
          <h4>Change Job Label</h4>
        </ContainerSection>
        <ContainerSection>
          <p>Job Label:</p>
          <TextInput
            type="text"
            maxLength="250"
            fill
            value={store.newJobLabel}
            onChange={store.onNewJobLabelChange}
          />
          <div className="pt-user-messages">
            {store.messaging.messages.length > 0 && (
              <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                <a
                  href="#close"
                  className="close"
                  data-dismiss="alert"
                  aria-label="close"
                  onClick={store.messaging.removeMessage.bind(
                    store,
                    store.messaging.messages[0]
                  )}
                >
                  &times;
                </a>
                <strong>{store.messaging.messages[0].messageLabel}</strong>
                {store.messaging.messages[0].message}
              </div>
            )}
          </div>
        </ContainerSection>
        <ContainerSection className="footer">
          <Inline>
            <Button size="large" onClick={store.renameJobModal.hideModal}>
              Cancel
            </Button>
            <Button size="large" color="brand" onClick={store.renameJob}>
              Change Job Label
            </Button>
          </Inline>
        </ContainerSection>
      </Modal>

      <Modal
        show={store.confirmDeleteModal.show}
        onHide={store.confirmDeleteModal.hideModal}
      >
        <div className="container-section header">
          <h4>Confirmation Needed</h4>
        </div>
        <div className="container-section">
          <p>Are you sure you want to delete this Job?</p>
          <Inline css={{ color: "$danger" }}>
            <FontAwesomeIcon icon="exclamation-triangle" />{" "}
            <em>This action cannot be undone!</em>
          </Inline>
        </div>
        <div className="container-section footer">
          <Inline>
            <Button size="large" onClick={store.confirmDeleteModal.hideModal}>
              No, Cancel
            </Button>
            <Button
              color="danger"
              size="large"
              onClick={(e) => {
                store.confirmDeleteModal.hideModal();
                store.deletejob();
              }}
            >
              Yes, Delete Job
            </Button>
          </Inline>
        </div>
      </Modal>
      <ApplyTagFilterModal
        filterState={store.applyTagState}
        applyTagsEvent={store.applyTags}
        btnText="Apply Tag to Job"
      />
    </div>
  );
});

/**
 * JobDetail component for job_labels detail page
 */
class Detail extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor() {
    super();
    this.deleteTag = this.deleteTag.bind(this);
    this.validateUpdate = this.validateUpdate.bind(this);
  }
  /**
   * componentDidMount
   * @return {void}
   */
  UNSAFE_componentWillMount() {
    this.props.store.jobLabelDetailStore.jobId = this.props.router.params.jobId;
    this.props.store.jobLabelDetailStore.router = this.context.router;
    this.props.store.jobLabelDetailStore.getJobDetails();
    window.scrollTo(0, 0);
  }

  deleteTag(e: object, tag_id) {
    this.props.store.jobLabelDetailStore.deleteSingleTags({
      tagId: tag_id,
      contentId: this.props.router.params.jobId,
      contentType: "JOB_LIBRARY",
    });
  }

  validateUpdate() {
    const store = this.props.store.jobLabelDetailStore;
    if (store.newJobDescription && store.newJobTitle) {
      return false;
    }
    return true;
  }

  componentWillUnmount() {
    this.props.store.jobLabelDetailStore.isEditing = false;
  }
  /**
   * Render rate card detail page
   * @return {XML}
   */
  render() {
    const store = this.props.store.jobLabelDetailStore;

    return (
      <div className="view">
        <div className="rc-container show-overflow">
          <JobDetail store={store} router={this.props.router} onClick={this.deleteTag} />
        </div>
        <div className="rc-container bring-forward">
          <Inline css={{ justifyContent: "space-between" }}>
            <h4>Job Detail</h4>
            <Inline css={{ justifyContent: "flex-end" }}>
              {store.isEditing && (
                <Button color="accent" onClick={store.stopEditing}>
                  Stop Editing{" "}
                </Button>
              )}
              {!store.isEditing && (
                <Button
                  icon="edit"
                  color="brand"
                  onClick={(e) => {
                    store.startEditing();
                  }}
                >
                  Edit
                </Button>
              )}
            </Inline>
          </Inline>
          <div className="row userjob">
            <div className="col-sm-6 col-md-6 col-lg-6">
              <div className="form-group">
                <label>Job Title</label>
                <div>
                  {!store.isEditing && (
                    <TextInput
                      style={{ fontSize: "16px" }}
                      color="primary"
                      type="text"
                      disabled={true}
                      fill
                      value={store.newJobTitle}
                    />
                  )}
                  {store.isEditing && (
                    <JobTitleSelectNew
                      getJobTitles={(param) =>
                        this.props.store.jobLabelListStore.JobTitle(param)
                      }
                      onChange={(value) => store.changeJobTitleText(value)}
                      value={store.newJobTitle}
                      disabled={false}
                      type="addJobTitle"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6">
              <div>
                <label>Description</label>
                <div className="input_group">
                  <TextArea
                    disabled={!store.isEditing}
                    fill
                    rows="5"
                    value={store.newJobDescription}
                    onChange={store.changeJobDescriptionText}
                  />
                </div>
              </div>
              {getSkillFeatureFlag(this.props.sessionInfo?.client) && (
                <Box css={{ fontSize: "$base" }}>
                  <label>Skills</label>
                  <RsSkillsWithJobDescription
                    jobDescription={store.newJobDescription}
                    showSkillLabel={false}
                  />
                </Box>
              )}
            </div>
          </div>
          {store.isEditing && (
            <ContainerSection className="footer">
              <Inline css={{ justifyContent: "flex-end" }}>
                <Button
                  size="large"
                  onClick={(e) => {
                    store.stopEditing();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  color="brand"
                  size="large"
                  disabled={this.validateUpdate()}
                  onClick={(e) => {
                    store.saveEditedJob();
                  }}
                >
                  Update Job
                </Button>
              </Inline>
            </ContainerSection>
          )}
        </div>
      </div>
    );
  }
}

export default observer(Detail);
