import { useEffect, useState } from "react";
import { fetchAPINew, FetchAPIOptionsType } from "../../../utils/fetch";

function getFetchConfig(queryText: string): FetchAPIOptionsType {
  return {
    headers: {
      accept: "application/json",
    },
    method: "POST",
    body: {
      query: `
      query namedEntities($queryText: String!, $industry: String!) {
        namedEntities(queryText: $queryText, industry: $industry) {
        allTerms
        titles
        skills
        responsibility
        education
        autonomy
        expertise
        }
      }
    `,
      variables: {
        queryText,
        industry: "",
      },
    },
  };
}

function isSkillArray(response: unknown): response is string[] {
  return Array.isArray(response) && response.every((s) => typeof s === "string");
}

/**
 * Fetches Job Skills from a given Job description.
 *
 * TODO: Refactor with relay once skills are available in nueve/seven schema.
 */
export function useFetchSkills(
  queryText: string,
  getSkillsHandler?: (s: string[]) => void
) {
  const [data, setData] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const config = getFetchConfig(queryText);
    fetchAPINew(`/api/taste/graphql/`, config).then((json: any) => {
      const response = json?.data?.data?.namedEntities?.skills;
      const result = isSkillArray(response) ? response : [];
      setData(result);
      if (getSkillsHandler) {
        getSkillsHandler(result);
      }
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryText]);

  return { data, loading };
}
