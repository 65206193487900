// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import SortIndicator from "../../ratecards/components/SortIndicator";
import { FILTER_COLUMN } from "../../../models/Filter";
import LoadingIndicator from "../../shared/LoadingIndicator";
import SavedSearchesListItem from "./SavedSearchesListItem";
import { SavedSearchesListComponentStore } from "../../../stores/mobx/SavedSearchesListStore";
import ToggleButton from "../../ratecards/components/ToggleButton";
import JobLabelFilter from "../../filters/JobLabelFilter";
import JobTitleFilter from "../../filters/JobTitleFilter";
import LocationFilter from "../../filters/LocationFilter";
import DraftFilter from "../../filters/DraftFilter";
import CreatedOnFilter from "../../filters/CreatedOnFilter";
import IndustryFilter from "../../filters/IndustryFilter";
import ListContainer from "../../ratecards/components/ListContainer";
import RateTypeFilter from "../../filters/RateTypeFilter";
import { SingleDatePicker } from "../../../components/SingleDatePicker";
import TagsFilter from "../../filters/TagsFilter";
import {
  CardAlert,
  CardAlertLink,
  CardFilter,
  CardFilters,
} from "../../../components/lib/Card";
import Pagination from "../../../components/lib/Pagination";
import Button from "../../../components/lib/Button";
import Spacer from "../../../components/lib/Spacer";
import { InlineElements } from "../../../components/lib/Inline";

/**
 * SavedSearchesList component for Saved Searches List page
 */
class SavedSearchesList extends Component {
  /**
   * constructor
   * @param {props} props The first number.

   */
  constructor(props) {
    super(props);
    this.changeCurrent = this.changeCurrent.bind(this);
  }

  changeCurrent(value) {
    let store: SavedSearchesListComponentStore = this.props.store;
    store.pagination.handleCurrentPage(value);
  }
  /**
   * Render Saved Searches List page
   * @return {XML}
   */
  render() {
    const store: SavedSearchesListComponentStore = this.props.store;
    const network = store.network;
    const maximumDate = new Date();
    const fillContainer = this.props.fillContainer;
    const fixedHeight = this.props.fixedHeight;
    let listBodyStyles = {};
    if (fixedHeight) {
      listBodyStyles.height = fixedHeight;
      listBodyStyles.overflowY = "auto";
    }
    let selectAllSection = null;
    let selectAllOnPageSection = (
      <>
        <Button onClick={store.selectAllOnPageItem}>Select All</Button>
        <Button onClick={store.deselectAllPage}>Deselect All</Button>
      </>
    );

    if (store.allOnPageSelected) {
      selectAllSection = (
        <CardAlert>
          <span>
            All &nbsp;
            <strong>{store.savedSearchCount}</strong>
            &nbsp; Searches selected.{" "}
          </span>
          &nbsp;
          {!store.isTagView && (
            <CardAlertLink key="clear-selections-link" onClick={store.clearAllSelections}>
              Clear all selections
            </CardAlertLink>
          )}
          {store.isTagView && (
            <CardAlertLink
              key="clear-selections-link"
              onClick={store.clearAllSelectionsOnTagView}
            >
              Clear all selections
            </CardAlertLink>
          )}
        </CardAlert>
      );
    }

    if (store.allOnPageSelected && !store.allSelected) {
      selectAllSection = (
        <CardAlert>
          <span>All Searches on this page selected. &nbsp;</span>
          &nbsp;
          <CardAlertLink key="select-all-item" onClick={store.selectAllPage}>
            Select all &nbsp;
            <strong>{store.savedSearchCount}</strong>
            &nbsp; Searches.
          </CardAlertLink>
          &nbsp;&nbsp;
          {!store.isTagView && (
            <CardAlertLink key="clear-selections-link" onClick={store.clearAllSelections}>
              Clear all selections
            </CardAlertLink>
          )}
          {store.isTagView && (
            <CardAlertLink
              key="clear-selections-link"
              onClick={store.clearAllSelectionsOnTagView}
            >
              Clear all selections
            </CardAlertLink>
          )}
        </CardAlert>
      );
    }

    let tableLeftActionBar = null;
    if (store.isEditing) {
      tableLeftActionBar = (
        <>{store.allowMultipleItemSelection && selectAllOnPageSection}</>
      );
    }

    const rateCards = store.rateCardsView;

    let tableContent = null;
    if (network.loading) {
      tableContent = <LoadingIndicator />;
    } else if (network.error) {
      // TODO: replace this with a more friendly error message
      tableContent = (
        <div className="pt-error-message">
          <h2>Error</h2>
          <pre>
            <code>{JSON.stringify(network.error, null, 2)}</code>
          </pre>
        </div>
      );
    } else if (rateCards.length === 0) {
      tableContent = (
        <div className="ratecards-table-empty">
          {Object.keys(store.appliedFilters).length > 0 ? (
            <div>No Searches found</div>
          ) : (
            <div>
              No Saved Search
              <p>Create a new Saved Search using the button above or by searching.</p>
            </div>
          )}
        </div>
      );
    } else {
      tableContent = rateCards.map((rateCard) => (
        <SavedSearchesListItem
          key={rateCard.searchId}
          store={store}
          rateCard={rateCard}
          editing={store.isEditing}
        />
      ));
    }
    var draftList = (
      <ListContainer>
        {store.draftFilter.viewItems.map((item) => (
          <ToggleButton
            key={item.id}
            large={true}
            item={item}
            type="radio"
            name="draft-state"
            value={item.value}
            selected={item.selected}
            onChange={store.draftFilter.setSelectedValue}
          >
            {item.value}
          </ToggleButton>
        ))}
      </ListContainer>
    );

    var fromDate = (
      <SingleDatePicker
        id="from_date"
        date={store.createdOnFilter.fromDate}
        numberOfMonths={1}
        isOutsideRange={(day) => day.isAfter(maximumDate)}
        focused={store.createdOnFilter.fromFocused}
        onDateChange={store.createdOnFilter.fromDateChange}
        onFocusChange={store.createdOnFilter.fromFocusedChange}
      />
    );

    var toDate = (
      <SingleDatePicker
        id="to_date"
        date={store.createdOnFilter.toDate}
        numberOfMonths={1}
        isOutsideRange={(day) => store.toDateRange(day)}
        focused={store.createdOnFilter.toFocused}
        onDateChange={store.createdOnFilter.toDateChange}
        onFocusChange={store.createdOnFilter.toFocusedChange}
      />
    );

    var rateTypeList = (
      <InlineElements>
        {store.rateTypeFilter.viewItems.map((item) => (
          <ToggleButton
            key={item.id}
            large={true}
            item={item}
            type="radio"
            name="rate-type"
            value={item.value}
            selected={item.selected}
            onChange={store.rateTypeFilter.setSelectedValue}
          >
            {item.value}
          </ToggleButton>
        ))}
      </InlineElements>
    );

    return (
      <div>
        {store.rateTypeFilter && (
          <RateTypeFilter
            rateTypeFilter={store.rateTypeFilter}
            rateTypeList={rateTypeList}
            network={store.rateTypeFilter.network.loading}
          />
        )}
        {store.jobLabelFilter && (
          <JobLabelFilter
            jobLabel={store.jobLabelFilter}
            network={store.jobLabelFilter.network.loading}
            instantSearchValue={store.jobLabelFilter.instantSearchValue}
            onInstantSearch={store.jobLabelFilter.onInstantSearch}
          />
        )}
        {store.jobFilter && (
          <JobTitleFilter
            jobTitle={store.jobFilter}
            network={store.jobFilter.network.loading}
            instantSearchValue={store.jobFilter.instantSearchValue}
            onInstantSearch={store.jobFilter.onInstantSearch}
          />
        )}
        {store.locationFilter && (
          <LocationFilter
            location={store.locationFilter}
            network={store.locationFilter.network.loading}
            instantSearchValue={store.locationFilter.instantSearchValue}
            onInstantSearch={store.locationFilter.onInstantSearch}
          />
        )}
        {store.draftFilter && (
          <DraftFilter draftFilter={store.draftFilter} draftList={draftList} />
        )}
        {store.createdOnFilter && (
          <CreatedOnFilter
            createdOnFilter={store.createdOnFilter}
            fromDate={fromDate}
            toDate={toDate}
          />
        )}
        {store.industryFilter && (
          <IndustryFilter
            industryFilter={store.industryFilter}
            network={store.industryFilter.network.loading}
            instantSearchValue={store.industryFilter.instantSearchValue}
            onInstantSearch={store.industryFilter.onInstantSearch}
          />
        )}
        {store.tagsFilter && (
          <TagsFilter
            tagsFilter={store.tagsFilter}
            network={store.tagsFilter.network.loading}
            instantSearchValue={store.tagsFilter.instantSearchValue}
            onInstantSearch={store.tagsFilter.onInstantSearch}
          />
        )}

        <CardFilters filtered={store.isFiltered}>
          {tableLeftActionBar}
          <Spacer />
          {store.isFiltered && (
            <Button color="accent" onClick={store.clearFilters}>
              Reset Filters & Sorts
            </Button>
          )}
          {store.tagsFilter && (
            <CardFilter
              name="search-list-filter"
              value={FILTER_COLUMN.TAGS}
              filtered={Boolean(store.appliedFilters[FILTER_COLUMN.TAGS])}
              onClick={store.tagsFilter.onShowModal}
            >
              <SortIndicator
                sortColumn={FILTER_COLUMN.TAGS}
                sortsOrder={store.appliedSortsOrder}
                sort={store.appliedSorts[FILTER_COLUMN.TAGS]}
              />
              Tags <span> ▼</span>
            </CardFilter>
          )}
          {store.rateTypeFilter && (
            <CardFilter
              name="rate-cards-filter"
              value={FILTER_COLUMN.RATE_TYPE}
              filtered={Boolean(store.appliedFilters[FILTER_COLUMN.RATE_TYPE])}
              onClick={store.rateTypeFilter.onShowModal}
            >
              <SortIndicator
                sortColumn={FILTER_COLUMN.RATE_TYPE}
                sortsOrder={store.appliedSortsOrder}
                sort={store.appliedSorts[FILTER_COLUMN.RATE_TYPE]}
              />
              Rate Type
              <span> ▼</span>
            </CardFilter>
          )}
          {store.jobLabelFilter && (
            <CardFilter
              name="rate-cards-filter"
              value={FILTER_COLUMN.JOB_LABEL}
              filtered={Boolean(store.appliedFilters[FILTER_COLUMN.JOB_LABEL])}
              onClick={store.jobLabelFilter.onShowModal}
            >
              <SortIndicator
                sortColumn={FILTER_COLUMN.JOB_LABEL}
                sortsOrder={store.appliedSortsOrder}
                sort={store.appliedSorts[FILTER_COLUMN.JOB_LABEL]}
              />
              Job Label
              <span> ▼</span>
            </CardFilter>
          )}
          {store.jobFilter && (
            <CardFilter
              name="rate-cards-filter"
              value={FILTER_COLUMN.JOB_TITLE}
              filtered={Boolean(store.appliedFilters[FILTER_COLUMN.JOB_TITLE])}
              onClick={store.jobFilter.onShowModal}
            >
              <SortIndicator
                sortColumn={FILTER_COLUMN.JOB_TITLE}
                sortsOrder={store.appliedSortsOrder}
                sort={store.appliedSorts[FILTER_COLUMN.JOB_TITLE]}
              />
              Job Title
              <span> ▼</span>
            </CardFilter>
          )}
          {store.locationFilter && (
            <CardFilter
              name="rate-cards-filter"
              value={FILTER_COLUMN.LOCATION}
              filtered={Boolean(store.appliedFilters[FILTER_COLUMN.LOCATION])}
              onClick={store.locationFilter.onShowModal}
            >
              <SortIndicator
                sortColumn={FILTER_COLUMN.LOCATION}
                sortsOrder={store.appliedSortsOrder}
                sort={store.appliedSorts[FILTER_COLUMN.LOCATION]}
              />
              Location
              <span> ▼</span>
            </CardFilter>
          )}

          {store.draftFilter && (
            <CardFilter
              name="rate-cards-filter"
              value={FILTER_COLUMN.DRAFT}
              filtered={Boolean(store.appliedFilters[FILTER_COLUMN.DRAFT])}
              onClick={store.draftFilter.onShowModal}
            >
              <SortIndicator
                sortColumn={FILTER_COLUMN.DRAFT}
                sortsOrder={store.appliedSortsOrder}
                sort={store.appliedSorts[FILTER_COLUMN.DRAFT]}
              />
              Draft
              <span> ▼</span>
            </CardFilter>
          )}
          {store.createdOnFilter && (
            <CardFilter
              name="rate-cards-filter"
              value={FILTER_COLUMN.DATE_RANGE}
              filtered={Boolean(store.appliedFilters[FILTER_COLUMN.DATE_RANGE])}
              onClick={store.createdOnFilter.onShowModal}
              data-testid="created-on-filter"
            >
              <SortIndicator
                sortType="numeric"
                sortColumn={FILTER_COLUMN.DATE_RANGE}
                sortsOrder={store.appliedSortsOrder}
                sort={store.appliedSorts[FILTER_COLUMN.DATE_RANGE]}
              />
              Created On
              <span> ▼</span>
            </CardFilter>
          )}
          {store.industryFilter && (
            <CardFilter
              name="rate-cards-filter"
              value={FILTER_COLUMN.DATE_RANGE}
              filtered={Boolean(store.appliedFilters[FILTER_COLUMN.INDUSTRY])}
              onClick={store.industryFilter.onShowModal}
              data-testid="industry-filter"
            >
              <SortIndicator
                sortColumn={FILTER_COLUMN.INDUSTRY}
                sortsOrder={store.appliedSortsOrder}
                sort={store.appliedSorts[FILTER_COLUMN.INDUSTRY]}
              />
              Industry
              <span> ▼</span>
            </CardFilter>
          )}
        </CardFilters>
        <div
          className={classNames("ratecards-table", {
            "ratecards-table-fill-container": fillContainer,
          })}
        >
          <div style={listBodyStyles}>
            {selectAllSection}
            {tableContent}
          </div>
        </div>
        {store.pagination.pageCount > 0 ? (
          <Pagination
            options={{
              variant: "full",
              currentPage: store.pagination.currentPage,
              numPages: store.pagination.pageCount,
            }}
            onPageClick={(pageEvent) => {
              if (pageEvent.type === "first") {
                this.changeCurrent(1);
              }
              if (pageEvent.type === "last") {
                this.changeCurrent(store.pagination.pageCount);
              }
              if (pageEvent.type === "next") {
                this.changeCurrent(
                  Math.min(store.pagination.currentPage + 1, store.pagination.pageCount)
                );
              }
              if (pageEvent.type === "prev") {
                this.changeCurrent(Math.max(store.pagination.currentPage - 1, 1));
              }
              if (pageEvent.type === "page") {
                this.changeCurrent(pageEvent.page);
              }
            }}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

SavedSearchesList.propTypes = {
  store: PropTypes.object.isRequired,
  fixedHeight: PropTypes.number,
  fillContainer: PropTypes.bool,
};

export default observer(SavedSearchesList);
