// @flow

export default class Industry {
  store: Object;
  id: number;
  name: ?string;

  constructor(store: Object, dataObject: Object) {
    this.store = store;
    // this.id = null;
    this.name = null;

    if (dataObject) {
      this.id = dataObject.legacyId;
      this.name = dataObject.value || dataObject.name || null;
    }
  }

  toString = (): string => {
    return this.name || "<Name: Not Available>";
  };

  toJS = (): Object => {
    return {
      id: this.id,
      name: this.name,
    };
  };
}
