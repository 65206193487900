import React from "react";

import Stack from "../../../../components/lib/Stack";
import Text from "../../../../components/lib/Text";
// @ts-expect-error
import Sticker from "../../../../components/Sticker";

import type { ImmutableList } from "../../../../types/immutable";
import type { PermsReprAllowedItemMap } from "../../types";

type StickerItem = PermsReprAllowedItemMap;
export type RenderStickerFunc<T = StickerItem> = (item: T) => React.ReactElement;

interface Props<T> {
  selectedItems: ImmutableList<T>;
  noItemsSelectedText: string;
  getStickerValue: RenderStickerFunc<T>;
  disabled?: boolean;
  onRemove: (item: T) => void;
}

export default function StickersSelect<T>(props: Props<T>) {
  const {
    selectedItems,
    noItemsSelectedText,
    disabled = false,
    getStickerValue,
    onRemove,
  } = props;

  return (
    <Stack
      css={{ flexDirection: "row", flexWrap: "wrap", gap: "$3", paddingBottom: "$2" }}
    >
      {selectedItems.size ? (
        selectedItems
          .toArray()
          .map((item, idx) => (
            <Sticker
              key={idx}
              value={getStickerValue(item)}
              onRemove={() => onRemove(item)}
              disabled={disabled}
              removable
            />
          ))
      ) : (
        <Text thin>{noItemsSelectedText}</Text>
      )}
    </Stack>
  );
}
StickersSelect.displayName = "StickersSelect";
