// @flow
import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { SortObject } from "../common";

export type SortIconKind = "alpha" | "numeric" | "amount";

type Props = {
  sortObject: ?SortObject,
  sortingOrder?: Array<string>,
  kind?: SortIconKind,
};

function SortIcon(props: Props): React.Node {
  const { sortObject, sortingOrder = [], kind = "alpha" } = props;

  if (!sortObject) return null;

  const showOrder = sortingOrder.length > 1;
  const index = sortingOrder.indexOf(sortObject.field);
  const icon = sortObject.direction === "DESC" ? `sort-${kind}-up` : `sort-${kind}-down`;

  return (
    <span className="sort-indicator">
      {showOrder && (
        <span data-testid="sort-position" className="sort-position">
          {index + 1}
        </span>
      )}
      <FontAwesomeIcon data-testid="sort-icon" icon={icon} fixedWidth className="icon" />
    </span>
  );
}

export default SortIcon;
