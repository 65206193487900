import React, { useState, useCallback, useMemo } from "react";

import Stack from "../../../components/lib/Stack";
import Text from "../../../components/lib/Text";
import Box from "../../../components/lib/Box";
import Button from "../../../components/lib/Button";
import TextInput from "../../../components/lib/TextInput";
import agreementText from "./NdaText";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogPortal,
} from "../../../components/lib/Dialog";
import { CheckboxItem } from "../../../components/lib/Checkbox";
import { styled } from "../../../stitches.config";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";
import { authorizeUser } from "../auth";
import { validateEmail } from "../../../utils/validation";

import type { AuthDataObject } from "../types";
import type { FetchAPIResponse, FetchAPIFunc } from "../../../types/fetch";
import type { ShowModalFunc } from "../../../components/modals/MessageModal";
import type { InputProps } from "../../../components/lib/TextInput";

type CreateNdaRequestData = {
  email: string;
  first_name: string;
  last_name: string;
  nda_text: string;
};

function validateField(fieldName: string, value: boolean | string | null) {
  switch (fieldName) {
    case "firstName":
    case "lastName":
      return !!value;
    case "email":
      return !!value && validateEmail(String(value));
    case "acceptAgreement":
      return value === true;
    default:
      return false;
  }
}

const Content = styled(Stack, {
  alignItems: "flex-start",
  gap: "$1",
  width: "$full",
});

type Props = {
  fetchArgusAPI: FetchAPIFunc;
  showModalError: ShowModalFunc;
};

type FormData = {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  acceptAgreement: boolean;
};
type FormDataKeys = keyof FormData;

const formKeys: FormDataKeys[] = ["firstName", "lastName", "email", "acceptAgreement"];

type State = FormData & {
  initial: boolean;
  showNDA: boolean;
  loadingButton: boolean;
};

export default function SurveyAuthForm(props: Props) {
  const { fetchArgusAPI, showModalError } = props;

  const [pageState, setPageState] = useState<State>({
    firstName: null,
    lastName: null,
    email: null,
    acceptAgreement: false,
    initial: true,
    showNDA: false,
    loadingButton: false,
  });

  // fetch

  const fetchCreateNdaConfirmation = useCallback(
    async (payload: CreateNdaRequestData) => {
      try {
        const response: FetchAPIResponse = await fetchArgusAPI("nda/", {
          method: "post",
          data: payload,
        });

        const data = await response.data;

        const authData: AuthDataObject = {
          email: data.email,
          firstName: data.first_name,
          lastName: data.last_name,
          acceptAgreement: true,
        };

        return authData;
      } catch (err: any) {
        logAsyncOperationError("fetchCreateNdaConfirmation", err);
        showModalError(
          "Error occurred while creating NDA confirmation object. Please, try again later."
        );
      }
    },
    [fetchArgusAPI, showModalError]
  );

  // utils

  const isDataValid = useMemo(() => {
    for (const key of formKeys) {
      if (!validateField(key, pageState[key])) {
        return false;
      }
    }
    return true;
  }, [pageState]);

  const styleField = useCallback(
    (fieldName: string, value: boolean | string | null): Partial<InputProps> => {
      if (pageState.initial) {
        return {};
      }

      const isValid = validateField(fieldName, value);
      return {
        color: isValid ? "primary" : "danger",
        iconRight: isValid ? "check" : "times",
      };
    },
    [pageState.initial]
  );

  // handlers

  const handleToggleNDA = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    setPageState((prevState) => ({
      ...prevState,
      showNDA: !prevState.showNDA,
    }));
  }, []);

  const handleAcceptChange = useCallback(() => {
    setPageState((prevState) => ({
      ...prevState,
      acceptAgreement: !prevState.acceptAgreement,
      showNDA: !prevState.acceptAgreement ? false : true,
    }));
  }, []);

  const handleChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
      let value = e.currentTarget.value;
      value = fieldName === "email" ? value.trim().toLowerCase() : value.trim();

      setPageState((prevState) => ({
        ...prevState,
        [fieldName]: value,
        initial: false,
      }));
    },
    []
  );

  const handleSubmit = useCallback(async () => {
    if (!pageState.email || !pageState.firstName || !pageState.lastName) {
      return;
    }

    setPageState((prevState) => ({
      ...prevState,
      loadingButton: true,
    }));

    const payload: CreateNdaRequestData = {
      email: pageState.email,
      first_name: pageState.firstName,
      last_name: pageState.lastName,
      nda_text: agreementText,
    };

    const data = await fetchCreateNdaConfirmation(payload);
    if (data) {
      authorizeUser(data);
    }

    document.location.reload();
  }, [pageState, fetchCreateNdaConfirmation]);

  return (
    <Dialog open={true}>
      <DialogPortal>
        <DialogContent css={{ width: "500px" }}>
          <DialogTitle asChild>
            <Text centered as="h3">
              Validator Authorization Form
            </Text>
          </DialogTitle>
          <DialogDescription css={{ backgroundColor: "inherit" }} asChild>
            <Stack css={{ fontSize: "$sm", alignItems: "flex-start" }}>
              <Text thin noMargin>
                In order to list opportunities to validate rates, please provide following
                data:
              </Text>
              <Content>
                <Text>First Name:</Text>
                <TextInput
                  fill
                  size="small"
                  value={pageState.firstName ?? ""}
                  placeholder="Enter text"
                  {...styleField("firstName", pageState.firstName)}
                  onChange={(e) => handleChangeInput(e, "firstName")}
                />
              </Content>
              <Content>
                <Text>Last Name:</Text>
                <TextInput
                  fill
                  size="small"
                  value={pageState.lastName ?? ""}
                  placeholder="Enter text"
                  {...styleField("lastName", pageState.lastName)}
                  onChange={(e) => handleChangeInput(e, "lastName")}
                />
              </Content>
              <Content>
                <Text>Email:</Text>
                <TextInput
                  type="email"
                  fill
                  size="small"
                  value={pageState.email ?? ""}
                  placeholder="Enter text"
                  {...styleField("email", pageState.email)}
                  onChange={(e) => handleChangeInput(e, "email")}
                />
              </Content>
              <CheckboxItem
                checked={pageState.acceptAgreement}
                onCheckedChange={handleAcceptChange}
              >
                I agree with the{" "}
                <Text underline color="accent" onClick={handleToggleNDA}>
                  Non Disclosure Agreement
                </Text>
              </CheckboxItem>
              <Box
                css={{
                  fontSize: "$sm",
                  fontWeight: "$extralight",
                  padding: "$3",
                  borderRadius: "$rounded",
                  border: "1px solid $primaryLight",
                  "& a": {
                    color: "$accent",
                    textDecoration: "underline",
                  },
                  display: pageState.showNDA ? "block" : "none",
                }}
              >
                The information contained in this electronic document is considered
                Confidential Information and is proprietary to Magnit and PayIntel. By
                clicking “I AGREE” you are acknowledging your acceptance of{" "}
                <a href="/_assets/SkillsVillageNDA.docx" download>
                  Magnit's Non Disclosure Agreement
                </a>
                .
                <br />
                Thank you for participating in rate validation for SkillsVillage.
              </Box>
            </Stack>
          </DialogDescription>
          <DialogActions fill css={{ background: "$white" }}>
            <Button
              css={{ width: "$full" }}
              size="large"
              variant="filled"
              color="brand"
              disabled={!isDataValid}
              loading={pageState.loadingButton}
              onClick={isDataValid ? handleSubmit : undefined}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
}

SurveyAuthForm.displayName = "SurveyAuthForm";
