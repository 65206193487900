// @flow

import React, { Component } from "react";
import type { Element } from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  BatchSearchJLCreateStore,
  RATE_CARD_ACTION,
} from "../../../stores/mobx/BatchSearchCreateStore";
import InfiniteScrollSelect from "../../../components/selects/InfiniteScrollSelect";
import type { InfiniteScrollSelectProps } from "../../../components/selects/InfiniteScrollSelect";
import RateCard from "../../../models/RateCard";
import CheckBox from "../../shared/CheckBox";
import Box from "../../../components/lib/Box";
import TextInput from "../../../components/lib/TextInput";

type Props = {
  store: BatchSearchJLCreateStore,
};

const RateCardInfiniteScrollSelect = (props: InfiniteScrollSelectProps<RateCard>) => (
  <InfiniteScrollSelect {...props} />
);

class WizardStep03SelectRateCard extends Component<Props> {
  handleRateCardActionChange: (number) => void;
  renderRateCardItem: (
    RateCard,
    ?string,
    ?(string, string) => Element<any>
  ) => Element<any>;
  renderRateCardSelection: (RateCard) => Element<any>;

  constructor(props: Props) {
    super(props);

    this.handleRateCardActionChange = this.handleRateCardActionChange.bind(this);
    this.renderRateCardSelection = this.renderRateCardSelection.bind(this);
    this.renderRateCardItem = this.renderRateCardItem.bind(this);
  }

  componentDidMount() {
    // this.props.store.rateCardsLoadFirstPage();
  }

  handleRateCardActionChange(value: number): void {
    const store = this.props.store;
    store.onRateCardActionChange(value);

    if (
      store.rateCardAction === RATE_CARD_ACTION.USE_EXISTING &&
      store.rateCards.length === 0
    ) {
      store.rateCardsLoadFirstPage();
    }
  }

  renderRateCardSelection(rateCard: RateCard): Element<any> {
    return this.renderRateCardItem(rateCard);
  }

  renderRateCardItem(
    rateCard: RateCard,
    term?: ?string = null,
    highlight?: ?(string, string) => Element<any> = null
  ): Element<any> {
    const title =
      term && highlight ? highlight(rateCard.toString(), term) : rateCard.toString();
    return (
      <span className="no-wrap flex">
        {title}
        <span className="flex center-items">
          {!rateCard.searchCount ? (
            <span className="pt-ui ss-tag">Empty</span>
          ) : (
            <span className="pt-ui ss-tag">
              {rateCard.searchCount} Job{rateCard.searchCount === 1 ? "s" : ""}
            </span>
          )}
          {!rateCard.mine && rateCard.shared && (
            <span className="pt-ui ss-tag">
              <FontAwesomeIcon icon="reply" /> Shared by{" "}
              {rateCard.owner ? rateCard.owner.fullname : ""}
            </span>
          )}
          {rateCard.mine && rateCard.shared && (
            <span className="pt-ui ss-tag tag-yellow">
              <FontAwesomeIcon icon="share" /> Shared
            </span>
          )}
        </span>
      </span>
    );
  }

  render() {
    const store = this.props.store;

    return (
      <div style={{ minHeight: 310, maxWidth: 1200, margin: "auto" }}>
        <div className="form-horizontal pt-ss-title-form">
          <div className="form-group">
            <Box
              as="label"
              className="rc-control-label control-label col-xs-12 col-sm-3"
              css={{
                ".pt-ui &.control-label": {
                  fontSize: "$lg",
                  paddingTop: 10,
                },
              }}
            >
              Rate Card
            </Box>
            <div className="col-xs-11 col-sm-6">
              <Box
                className="rc-checkbox-group form-horizontal"
                css={{
                  ".pt-ui & label": {
                    fontSize: "$lg",
                    paddingTop: 10,
                  },
                }}
              >
                <CheckBox
                  style={{ width: 150 }}
                  type="radio"
                  name="rateCardAction"
                  value={RATE_CARD_ACTION.USE_EXISTING}
                  selected={store.rateCardAction === RATE_CARD_ACTION.USE_EXISTING}
                  onChange={this.handleRateCardActionChange}
                >
                  Use Existing
                </CheckBox>
                <CheckBox
                  style={{ width: 150 }}
                  type="radio"
                  name="rateCardAction"
                  value={RATE_CARD_ACTION.CREATE}
                  selected={store.rateCardAction === RATE_CARD_ACTION.CREATE}
                  onChange={this.handleRateCardActionChange}
                >
                  Create
                </CheckBox>
              </Box>
              {store.rateCardAction === RATE_CARD_ACTION.USE_EXISTING && (
                <RateCardInfiniteScrollSelect
                  name="rateCard"
                  items={store.rateCards}
                  selectedItem={store.selectedRateCard}
                  inputValue={store.rateCardSearch}
                  onInputValueChange={store.onRateCardSearchChange}
                  onSelectedItemChange={store.onSelectedRateCardChange}
                  hasMoreItems={
                    !store.rateCardsPagination.hasLoaded ||
                    store.rateCardsPagination.currentPage <
                      store.rateCardsPagination.pageCount
                  }
                  loadMoreItems={store.rateCardsLoadNextPage}
                  renderItem={this.renderRateCardItem}
                  // renderSelection={this.renderRateCardSelection}
                  placeholder="Select a Rate Card..."
                />
              )}
              {store.rateCardAction === RATE_CARD_ACTION.CREATE && (
                <TextInput
                  fill
                  value={store.newRateCardName}
                  onChange={(e) => store.onNewRateCardNameChange(e.currentTarget.value)}
                  placeholder="Enter Rate Card name..."
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(WizardStep03SelectRateCard);
