import React from "react";
import ContainerSection from "../ratecards/components/ContainerSection";
import FilterModal from "../ratecards/components/FilterModal";
import LoadingIndicator from "../shared/LoadingIndicator";

const RateTypeFilter = ({ rateTypeFilter, network, rateTypeList }) => {
  return (
    <FilterModal title="Rate Type" filterState={rateTypeFilter}>
      {network && <LoadingIndicator />}
      {!network && (
        <ContainerSection>
          <p>Filter by a specific Rate Type:</p>
          {rateTypeList}
        </ContainerSection>
      )}
    </FilterModal>
  );
};

export default RateTypeFilter;
