import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import JobTitleSelectNew from "../../components/selects/JobTitleSelectNew";
import { browserHistory } from "react-router";
import Button from "../../components/lib/Button";
import Inline from "../../components/lib/Inline";
import LinkButton from "../../components/lib/LinkButton";
import TextInput from "../../components/lib/TextInput";
import TextArea from "../../components/lib/TextArea";

/**
 * AddJob component for add and upload job
 */
class AddJob extends Component {
  constructor(props) {
    super(props);

    this.add = this.add.bind(this);
  }

  componentDidMount() {
    this.props.store.jobLabelListStore.jobtitle = "";
    this.props.store.jobLabelListStore.joblabel = "";
    this.props.store.jobLabelListStore.jobdescription = "";
    this.props.store.jobLabelListStore.fileName = "";
  }

  add() {
    this.props.store.jobLabelListStore.addJob().then(() => {
      this.props.store.jobLabelListStore.jobtitle = "";
      browserHistory.push("/job-labels");
    });
  }

  render() {
    const store = this.props.store.jobLabelListStore;

    return (
      <div className="view list">
        <div className="rc-container">
          <div className="user-job-data">
            <div className="col-sm-12 add-job alt-add-job">
              <h2>Add Job</h2>
              <div className="box">
                <form className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="job-label">Job Label</label>
                      <TextInput
                        id="job-label"
                        placeholder="Job Label"
                        fill
                        type="text"
                        value={store.joblabel}
                        onChange={store.changeJobLabelText}
                        maxLength="250"
                      />
                    </div>
                    <div className="form-group input-box-fix">
                      <label>Select Title</label>
                      <JobTitleSelectNew
                        getJobTitles={(param) =>
                          this.props.store.jobLabelListStore.JobTitle(param)
                        }
                        onChange={(value) => store.changeJobTitleText(value)}
                        value={store.jobtitle}
                        type="addJobTitle"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Description</label>
                      <TextArea
                        fill
                        id="job_description"
                        rows="3"
                        value={store.jobdescription}
                        onChange={store.changeJobDescriptionText}
                        data-testid="job-description"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <Inline css={{ justifyContent: "flex-end" }}>
                      <LinkButton size="large" to="/job-labels">
                        Cancel
                      </LinkButton>
                      <Button
                        data-testid="add-job-btn"
                        disabled={
                          !(store.joblabel && store.jobtitle && store.jobdescription)
                        }
                        type="button"
                        size="large"
                        color="brand"
                        onClick={this.add}
                      >
                        Add Job
                      </Button>
                    </Inline>
                  </div>
                </form>
              </div>
            </div>
            {/* Comment Bulk Job Upload Feature
            <form id="upload_job">
              {store.fileUploadInProgress && <LoadingIndicator />}
              {!store.fileUploadInProgress && (
                <div className="col-sm-12 upload-job">
                  <h2>Upload</h2>
                  <div className="box">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Name this upload</label>
                          <input type="text" className="rc-input-box" maxLength="250" placeholder="Upload name" />
                        </div>
                      </div>

                      <div className="col-sm-2">
                        <div className="form-group">
                          <label>Locate file</label>
                          <input
                            type="file"
                            name="file"
                            className="form-control browse_button"
                            id="file_uploaded"
                            accept=".csv, .xlsx, .xls"
                            placeholder=""
                            required
                            onChange={this.handleOnchange}
                          />
                          <div className="fileinput fileinput-new" data-provides="fileinput">
                            <span className="btn-file">
                              <FontAwesomeIcon icon="arrow-circle-o-up" aria-hidden="true" /> BROWSE
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>&nbsp;</label>
                          <span className="filename">{store.fileName}</span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 text-right">
                        <div className="form-group">
                          <Link to="/job-labels" className="btn btn-lg btn-default">
                            Cancel
                          </Link>
                          <button type="submit" className="btn btn-lg btn-green" disabled={!store.fileName}>
                            Upload
                          </button>
                          {this.state.fileUploadError ? <p> {this.state.fileUploadError} </p> : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </form>
            */}
          </div>
        </div>
      </div>
    );
  }
}

AddJob.propTypes = {
  store: PropTypes.object.isRequired,
};

export default observer(AddJob);
