// @flow

import ProjectCostEstimates from "../../../models/ProjectCostEstimate";
import { extendObservable, action, runInAction } from "mobx";
import CurrentUser from "../../../models/User";
import NetworkState from "../../../models/NetworkState";
import axios from "axios";
import ModalState from "../../../models/ModalState";
import MessageState from "../../../models/MessageState";
import type { FetchGraphQL, FetchAPI } from "../../../App";

export default class ProjectCostEstimateDetailStore {
  currentUser: CurrentUser;
  showHelpModal: boolean;
  showRateCardModal: boolean;
  projectId: ?number;
  project: ?ProjectCostEstimates;
  network: NetworkState;
  getProjectDetails: () => void;
  showHelp: () => void;
  hideHelp: () => void;
  showRateCard: () => void;
  hideRateCard: () => void;
  changePeopleNeededForPunchout: () => void;
  changeBillRates: () => void;
  onFocusOutFn: () => void;
  onFocusOutPeopleNeeded: () => void;
  onFocusOutBillableHours: () => void;
  changeBillableHoursForPunchout: () => void;
  removePunchout: () => void;
  updateProjectStats: () => void;

  renameProjectModal: ModalState;
  newProjectName: string;
  showRenameProjectModal: () => void;
  onNewProjectNameChange: (Event) => void;
  export: () => void;

  confirmDeleteModal: ModalState;
  exportFileName: string;
  messaging: MessageState;
  fetchGraphQL: FetchGraphQL;
  fetchAPI: FetchAPI;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI) {
    this.fetchGraphQL = fetchGraphQL;
    this.fetchAPI = fetchAPI;
    this.router = null;
    this.getProjectDetails = action(this.getProjectDetails.bind(this));
    this.showHelp = action(this.showHelp.bind(this));
    this.hideHelp = action(this.hideHelp.bind(this));
    this.showRateCard = action(this.showRateCard.bind(this));
    this.hideRateCard = action(this.hideRateCard.bind(this));

    this.onNewProjectNameChange = action(this.onNewProjectNameChange.bind(this));
    this.showRenameProjectModal = action(this.showRenameProjectModal.bind(this));
    this.changePeopleNeededForPunchout = action(
      this.changePeopleNeededForPunchout.bind(this)
    );
    this.changeBillRates = action(this.changeBillRates.bind(this));
    this.onFocusOutFn = action(this.onFocusOutFn.bind(this));
    this.onFocusOutPeopleNeeded = action(this.onFocusOutPeopleNeeded.bind(this));
    this.onFocusOutBillableHours = action(this.onFocusOutBillableHours.bind(this));
    this.changeBillableHoursForPunchout = action(
      this.changeBillableHoursForPunchout.bind(this)
    );
    this.removePunchout = action(this.removePunchout.bind(this));
    this.export = action(this.export.bind(this));
    this.updateProjectStats = action(this.updateProjectStats.bind(this));

    extendObservable(this, {
      exportFileName: "exported_pce_" + Math.floor(Math.random() * 9999999 + 1000000), // Default name for  exporting file
      showHelpModal: false, // Don't display the help modal on page load
      showRateCardModal: false,
      network: new NetworkState(),
      projectId: null,
      project: null,

      // Rename Project
      newProjectName: "",
      renameProjectModal: new ModalState(), // Rename project modal
      projectStats: [],
      exchangeRates: null,
      messaging: new MessageState(),
    });
  }

  // Method for exporting the project
  export() {
    var url = "projects/" + this.projectId + "/export/excel/";

    // Change the Filename
    var params = { fileName: this.exportFileName, section: "admin" };

    this.fetchAPI(url, params)
      .then((res) => {
        window.location.href = res.data.url;
      })
      .catch((e) => {
        console.error("Error downloading excel", e);
        throw e;
      });
  }

  showHelp() {
    this.showHelpModal = true;
  }

  hideHelp() {
    this.showHelpModal = false;
  }

  showRateCard(pceStore, projectStats) {
    pceStore.handlePunchoutEditStop();
    pceStore.selectedProjectStats(projectStats);
    this.showRateCardModal = true;
  }

  hideRateCard() {
    this.showRateCardModal = false;
  }

  // Change the project name
  onNewProjectNameChange(e: Event) {
    this.newProjectName = e.target.value;
  }

  showRenameProjectModal() {
    this.newProjectName = this.project.name;
    this.renameProjectModal.showModal();
  }

  // update the people required
  changePeopleNeededForPunchout(e: Event, punchout) {
    for (var i = this.projectStats.length - 1; i >= 0; i--) {
      if (this.projectStats[i].projectStatId === punchout.projectStatId) {
        const re = /^[0-9]+?$/;
        if (e.target.value === "" || re.test(e.target.value)) {
          this.projectStats[i].peopleNeeded = e.target.value;
          var id = "peopleNeeded" + punchout.projectStatId;
          document.getElementById(id).value = e.target.value;
        } else return false;
      }
    }
  }

  // update bill rates
  changeBillRates(e: Event, punchout) {
    for (var i = this.projectStats.length - 1; i >= 0; i--) {
      if (this.projectStats[i].projectStatId === punchout.projectStatId) {
        var value;
        const re = /^[0-9]+(\.[0-9]+)?$/; // regex for alowing positive integers before and after decimal
        const regex = /^(\d+\.)$/; // regex allowing nothing after a decimal
        const regex2 = /^\.$/; // regex if only . is provided
        var id = "billrate" + punchout.projectStatId;
        if (
          e.target.value.startsWith("0") &&
          !e.target.value.startsWith("0.") &&
          re.test(e.target.value)
        ) {
          value = Number(e.target.value);
        } else if (
          e.target.value === "" ||
          re.test(e.target.value) ||
          regex.test(e.target.value)
        ) {
          value = e.target.value;
        } else if (regex2.test(e.target.value)) {
          // Add 0 before decimal in this situation
          value = 0 + e.target.value;
        } else return false;

        if (e.target.value.indexOf(".") > -1) {
          if (e.target.value.length - e.target.value.indexOf(".") - 1 <= 2) {
            this.projectStats[i].avgBillRate = value;
            document.getElementById(id).value = value;
          } else {
            return false;
          }
        } else {
          this.projectStats[i].avgBillRate = value;
          document.getElementById(id).value = value;
        }
      }
    }
  }

  // update bill rates when key is out of the text field
  onFocusOutFn(e: Event, punchout) {
    for (var i = this.projectStats.length - 1; i >= 0; i--) {
      if (this.projectStats[i].projectStatId === punchout.projectStatId) {
        const regex = /^(\d+\.)$/; // regex allowing nothing after a decimal
        var id = "billrate" + punchout.projectStatId;
        // For blank and 0 return 0.0
        if (e.target.value === "" || e.target.value === "0") {
          document.getElementById(id).value = "0.0";
          this.projectStats[i].avgBillRate = "0.0";
          // Add 0 after decimal in this condition
        } else if (regex.test(document.getElementById(id).value)) {
          document.getElementById(id).value = e.target.value.replace(/^(\d+\.)$/, "$10");
          this.projectStats[i].avgBillRate = e.target.value.replace(/^(\d+\.)$/, "$10");
        }
      }
    }
  }

  onFocusOutPeopleNeeded(e: Event, punchout, type) {
    for (var i = this.projectStats.length - 1; i >= 0; i--) {
      if (this.projectStats[i].projectStatId === punchout.projectStatId) {
        var id = "peopleNeeded" + punchout.projectStatId;
        // For blank and 0 return 0
        if (e.target.value === "") {
          document.getElementById(id).value = "0";
          this.projectStats[i].peopleNeeded = "0";
        }
      }
    }
  }

  onFocusOutBillableHours(e: Event, punchout, type) {
    for (var i = this.projectStats.length - 1; i >= 0; i--) {
      if (this.projectStats[i].projectStatId === punchout.projectStatId) {
        var id = "billableHours" + punchout.projectStatId;
        // For blank and 0 return 0
        if (e.target.value === "") {
          document.getElementById(id).value = "0";
          this.projectStats[i].billableHours = "0";
        }
      }
    }
  }

  // update the billableHours required
  changeBillableHoursForPunchout(e: Event, punchout) {
    for (var i = this.projectStats.length - 1; i >= 0; i--) {
      if (this.projectStats[i].projectStatId === punchout.projectStatId) {
        const re = /^[0-9]+?$/;
        if (e.target.value === "" || re.test(e.target.value)) {
          this.projectStats[i].billableHours = e.target.value;
          var id = "billableHours" + punchout.projectStatId;
          document.getElementById(id).value = e.target.value;
        } else return false;
      }
    }
  }

  // Remove punchouts from a project
  removePunchout(e: Event, punchout) {
    for (var i = this.projectStats.length - 1; i >= 0; i--) {
      if (this.projectStats[i].projectStatId === punchout.projectStatId) {
        this.projectStats.splice(i, 1);
      }
    }
  }

  // Update the project Stats
  updateProjectStats(stats) {
    if (stats.length > 0) {
      stats.forEach((punchout) => {
        if (punchout.viewState.selected && !punchout.viewState.alreadyAdded) {
          var projectStats = {};
          var rateCard = {};
          var level = {};
          var buyRate = {};
          var search = {};
          var job = {};
          var projectkey = "key" + punchout.punchoutId;
          rateCard.ratecardId = punchout.rateCardId;
          level.levelId = punchout.levelId;
          level.value = punchout.levelName;
          buyRate.buyrateId = punchout.store.buyrateId;
          search.searchId = punchout.store.store.searchId;
          job.jobLabel = punchout.store.store.jobLabel;
          job.jobTitle = punchout.store.store.name;
          projectStats.peopleNeeded = 0;
          projectStats.billableHours = 0;
          projectStats.avgBillRate = punchout.billRateAvg;
          search.job = job;
          search.state = punchout.store.store.state;
          search.country = punchout.store.store.country;
          search.currency = punchout.store.store.currency;
          projectStats.rateCard = rateCard;
          projectStats.level = level;
          projectStats.buyRate = buyRate;
          projectStats.search = search;
          projectStats.projectStatId = projectkey;
          this.projectStats.push(projectStats);
        }
        if (!punchout.viewState.selected && punchout.viewState.alreadyAdded) {
          for (var i = this.projectStats.length - 1; i >= 0; i--) {
            if (
              this.projectStats[i].buyRate.buyrateId === punchout.buyrateId &&
              this.projectStats[i].level.levelId === punchout.levelId
            ) {
              this.projectStats.splice(i, 1);
            }
          }
        }
      });
    }
  }

  // Get project details when page loads
  async getProjectDetails() {
    let res = null;
    if (!/^\d+$/.test(this.projectId)) {
      if (this.router) {
        this.router.push({
          pathname: "/404NotFound",
          query: this.router.query,
        });
      }
      return res;
    }
    const variables = {
      id: parseInt(this.projectId, 10),
    };
    const query = `
      query projectDetail($id : Int!){
       viewer {
         projectCost(id: $id,section:ADMIN) {
            projectId
            name
            tags{
                name
                tagId
               }
            user{
              firstName
              lastName
            }
            created
            projectStats {
              rateCard{
                ratecardId
              }
              level {
               levelId
               value
              }
              projectStatId
              peopleNeeded
              billableHours
              avgBillRate
              buyRate{
                buyrateId
              }
              search {
                searchId
                job {
                  jobLabel
                  jobTitle
                }
                state
                country
                currency{
                  iso
                }
              }
            }
         }
       }
     }`;

    this.network.loading = true;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting Project Details", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Project Details query", res);
      }
      return e;
    }

    return runInAction("getProjects--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get Project Details query", res)) {
        if (res.data.viewer && !res.data.viewer.projectCost) {
          if (this.router) {
            this.router.push({
              pathname: "/404NotFound",
              query: this.router.query,
            });
          }
          return;
        }
        return {};
      }

      const pc = res.data.viewer.projectCost;
      this.project = new ProjectCostEstimates(this, pc);
      this.projectStats = this.project.projectStats;
      var url = "projects/exchange-rates/";
      var pcestore = this;

      this.fetchAPI(url)
        .then((res) => {
          // Correct?
          pcestore.exchangeRates = res.data;
        })
        .catch((e) => {
          console.error("Error requesting exchange rates", e);
          throw e;
        });
    });
  }

  toJSON() {
    return {};
  }
}
