import React from "react";

import Button from "../../../components/lib/Button";
import {
  Card,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
} from "../../../components/lib/Card";

type BackToListPageSectionProps = {
  buttonText?: string;
  onButtonClick: React.ReactEventHandler<HTMLButtonElement>;
};

function BackToListPageSection(props: BackToListPageSectionProps) {
  const { buttonText = "Back To List", onButtonClick } = props;

  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft />
        <CardActionsRight>
          <Button icon="arrow-left" size="normal" color="brand" onClick={onButtonClick}>
            {buttonText}
          </Button>
        </CardActionsRight>
      </CardActions>
    </Card>
  );
}

export default BackToListPageSection;
