// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react";
import Modal from "../ratecards/components/Modal";
import ContainerSection from "../ratecards/components/ContainerSection";
import LoadingIndicator from "../shared/LoadingIndicator";
import CheckBox from "../shared/CheckBox";
import RateCardListings from "./components/RateCardListings";
import TagItem from "../ratecards/components/TagItem";
import ApplyTagFilterModal from "../shared/modals/ApplyTagFilterModal";
import type MobXStore from "../../stores/mobx/MobXStore";
import type ProjectCostEstimateStore from "../../stores/mobx/ProjectCostEstimateStore";
import type ProjectCostEstimateDetailStore from "../../stores/mobx/ProjectCostEstimateDetailStore";
import TextInput from "../../components/lib/TextInput";
import Button from "../../components/lib/Button";
import { InlineElements } from "../../components/lib/Inline";
import LinkButton from "../../components/lib/LinkButton";
import { CardHeaderTitle, CardHeaderSubTitle } from "../../components/lib/Card";
import { ModalTitle, ModalButtons } from "../ratecards/components/FilterModal";

type PCEDetailProps = {
  store: ProjectCostEstimateDetailStore,
  pceStore: ProjectCostEstimateStore,
  onClick: (any, any) => void,
};

const PCEDetail = observer((props: PCEDetailProps) => {
  const store = props.store;
  const pcestore = props.pceStore;
  const selectedExcel = store.selectedExcel;
  const selectedCSV = store.selectedCSV;

  let name,
    owner,
    tagSection,
    createdDate = null;

  if (store.network.loading) {
    name = "...";
    owner = "...";
    createdDate = "...";
  } else if (store.project) {
    name = store.project.name;
    owner = store.project.owner;
    createdDate = store.project.createdDate.format("MMMM D, YYYY");
    tagSection = store.project.tags.map((tag) => (
      <TagItem
        key={tag.tagId}
        tag={tag}
        onClick={(event) => {
          props.onClick(event, tag.tagId);
        }}
      />
    ));
  }

  var actionBar = (
    <InlineElements>
      <Button
        color="primary"
        disabled={store.selectedCount === 0}
        onClick={store.applyTagState.showTagModal}
      >
        Apply Tag
      </Button>
      <Button onClick={store.showRenameProjectModal} icon="edit" color="primary">
        Rename
      </Button>
      {store.canExport() && (
        <Button icon="download" color="primary" onClick={store.showExportModal}>
          Export
        </Button>
      )}
      <Button
        icon="trash-alt"
        color="danger"
        onClick={pcestore.confirmDeleteModal.showModal}
      >
        Delete
      </Button>
    </InlineElements>
  );

  return (
    <div className="top">
      <div className="above">
        <div className="ratecard-label-header ellipsis max-width-80">
          <CardHeaderTitle>{name}</CardHeaderTitle>
        </div>
        <InlineElements>
          <LinkButton to="/project-cost-estimate" icon="arrow-left" color="brand">
            Go Back
          </LinkButton>
          <Button onClick={store.showHelp} icon="question-circle">
            Help
          </Button>
        </InlineElements>
      </div>
      <div className="below">
        <div className="ratecard-meta ellipsis">
          <CardHeaderSubTitle>
            <FontAwesomeIcon icon="user" fixedWidth className="icon" /> {owner}
          </CardHeaderSubTitle>
          <CardHeaderSubTitle>
            <FontAwesomeIcon icon="calendar-alt" fixedWidth className="icon" />{" "}
            {createdDate}
          </CardHeaderSubTitle>
        </div>
        {actionBar}
      </div>
      <div className="tagbox">{tagSection}</div>

      <Modal show={store.showHelpModal} onHide={store.hideHelp}>
        <ContainerSection className="header">
          <ModalTitle>Help</ModalTitle>
        </ContainerSection>
        <ContainerSection className="footer">
          <p>Here you can see all the Details related to this Project.</p>
        </ContainerSection>
      </Modal>

      <Modal
        show={store.renameProjectModal.show}
        onHide={store.renameProjectModal.hideModal}
      >
        <ContainerSection className="header">
          <ModalTitle>Rename Project</ModalTitle>
        </ContainerSection>
        <ContainerSection>
          <p>Name of the Project:</p>
          <TextInput
            maxLength="250"
            css={{
              width: "100%",
            }}
            value={store.newProjectName}
            onChange={store.onNewProjectNameChange}
          />
          <div className="pt-user-messages">
            {store.messaging.messages.length > 0 && (
              <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                <a
                  href="#close"
                  className="close"
                  data-dismiss="alert"
                  aria-label="close"
                  onClick={store.messaging.removeMessage.bind(
                    store,
                    store.messaging.messages[0]
                  )}
                >
                  &times;
                </a>
                <strong>{store.messaging.messages[0].messageLabel}</strong>
                {store.messaging.messages[0].message}
              </div>
            )}
          </div>
        </ContainerSection>
        <ContainerSection className="footer">
          <ModalButtons>
            <Button size="large" onClick={store.renameProjectModal.hideModal}>
              Cancel
            </Button>
            <Button size="large" color="brand" onClick={store.renameProject}>
              Rename Project
            </Button>
          </ModalButtons>
        </ContainerSection>
      </Modal>

      <Modal
        show={store.exportProjectModal.show}
        onHide={store.exportProjectModal.hideModal}
      >
        <ContainerSection className="header">
          <ModalTitle>Export</ModalTitle>
        </ContainerSection>
        <ContainerSection>
          <p>File Name:</p>
          <TextInput
            maxLength="250"
            css={{
              width: "300px",
            }}
            value={store.exportFileName}
            onChange={store.changeExportFileName}
          />
        </ContainerSection>
        <ContainerSection>
          <p>File Format:</p>
          <CheckBox
            children="Microsoft Excel (Default)"
            type="radio"
            name="chooseExcelType"
            value="excel"
            selected={selectedExcel}
            onClick={store.handleExcelTypeChange.bind(this, "excel")}
          />
          <CheckBox
            children="Comma Separated Values"
            type="radio"
            name="chooseExcelType"
            value="csv"
            selected={selectedCSV}
            onClick={store.handleExcelTypeChange.bind(this, "csv")}
          />
        </ContainerSection>
        <ContainerSection className="footer">
          <ModalButtons className="pull-right">
            <Button size="large" onClick={store.exportProjectModal.hideModal}>
              Cancel
            </Button>
            <Button
              size="large"
              color="brand"
              onClick={(e) => {
                store.exportProjectModal.hideModal();
                store.export();
              }}
            >
              Export
            </Button>
          </ModalButtons>
        </ContainerSection>
      </Modal>

      <Modal
        show={pcestore.confirmDeleteModal.show}
        onHide={pcestore.confirmDeleteModal.hideModal}
      >
        <ContainerSection className="header">
          <ModalTitle>Confirmation Needed</ModalTitle>
        </ContainerSection>
        <ContainerSection>
          <p>Are you sure you want to delete the Project?</p>
          <p className="text-danger">
            <FontAwesomeIcon icon="exclamation-triangle" />{" "}
            <em>This action cannot be undone!</em>
          </p>
        </ContainerSection>
        <ContainerSection className="footer">
          <ModalButtons className="pull-right">
            <Button size="large" onClick={pcestore.confirmDeleteModal.hideModal}>
              No, Cancel
            </Button>
            <Button
              size="large"
              color="danger"
              onClick={(e) => {
                pcestore.confirmDeleteModal.hideModal();
                pcestore.deleteProjects(store.projectId);
              }}
            >
              Yes, Delete Projects
            </Button>
          </ModalButtons>
        </ContainerSection>
      </Modal>
    </div>
  );
});

type Props = {
  store: MobXStore,
  router: Object,
};

class Detail extends Component<Props, null> {
  static contextTypes = {
    router: PropTypes.object,
  };

  componentDidMount() {
    this.props.store.projectCostEstimateDetailStore.projectId =
      this.props.router.params.projectCostEstimateId;
    this.props.store.projectCostEstimateDetailStore.router = this.context.router;
    this.props.store.projectCostEstimateDetailStore.getProjectDetails();
  }

  deleteTag = (e: object, tag_id) => {
    this.props.store.projectCostEstimateDetailStore.deleteSingleTags({
      tagId: tag_id,
      contentId: this.props.router.params.projectCostEstimateId,
      contentType: "PROJECT_STATS",
    });
  };

  getExchangeRates = (localCurrency: ?{ iso: string }, rate: number): number => {
    // TODO: Return Null for currency
    const store: ProjectCostEstimateDetailStore =
      this.props.store.projectCostEstimateDetailStore;
    const currency: string = (localCurrency && localCurrency.iso) || "USD";
    if (store.network.loading) return 1;

    if (!store.exchangeRates || !store.exchangeRates.rates) {
      console.error("No exchange rate information", store.exchangeRates);
      return 0;
    }

    const exchangeRate = store.exchangeRates.rates[currency] || null;
    if (exchangeRate === null) {
      console.error(`No exchange rate for ${currency}`, store.exchangeRates.rates);
      return 0;
    }
    return rate / exchangeRate;
  };

  render() {
    const store = this.props.store.projectCostEstimateDetailStore;
    const pceStore = this.props.store.projectCostEstimateStore;
    const rcstore = this.props.store.rateCardListStore;
    const network = store.network;
    let totalPeopleNeeded = 0;
    let totalBillableHours = 0;
    let totalCostEstimate = 0.0;
    let projectStats = store.projectStats;

    for (let i = projectStats.length - 1; i >= 0; i--) {
      totalPeopleNeeded += parseInt(projectStats[i].peopleNeeded, 10) || 0;
      totalBillableHours += parseFloat(projectStats[i].billableHours) || 0;
      totalCostEstimate +=
        projectStats[i].peopleNeeded *
        projectStats[i].billableHours *
        this.getExchangeRates(
          projectStats[i].search.currency,
          projectStats[i].avgBillRate
        );
    }

    totalCostEstimate = totalCostEstimate
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");

    let tableContent = null;
    let tableRows = null;
    if (network.loading) {
      tableContent = <LoadingIndicator />;
    } else if (network.error) {
      tableContent = (
        <div className="pt-error-message">
          <h2>Error</h2>
          <pre>
            <code>{network.error[0].message}</code>
          </pre>
        </div>
      );
    } else {
      tableRows = projectStats.map((punchout) => (
        <PCEPunchoutDetails
          key={punchout.projectStatId}
          punchout={punchout}
          store={store}
          usd={this.getExchangeRates(punchout.search.currency, punchout.avgBillRate)}
        />
      ));

      tableContent = (
        <div className="pce-table-container">
          <table className="no-spacing table pce-table">
            <thead>
              <tr>
                <th>Job Label</th>
                <th>Job Title</th>
                <th>Job Location</th>
                <th className="text-center">Exp. Level</th>
                <th className="text-right input-pad">
                  <div>
                    Bill Rate
                    <br />
                    <small>(Local Currency)</small>
                  </div>
                </th>
                <th className="text-right">
                  <div>
                    Bill Rate
                    <br />
                    <small>(Common Currency)</small>
                  </div>
                </th>
                <th className="text-right input-pad">Quantity</th>
                <th className="text-right input-pad">Billable Hours</th>
                <th className="text-right">Cost Estimate</th>
                <th />
              </tr>
            </thead>
            {projectStats.length > 0 ? (
              <tbody>{tableRows}</tbody>
            ) : (
              <tbody>
                <tr>
                  <td>No Available Punchouts</td>
                </tr>
              </tbody>
            )}
            <thead className="">
              <tr>
                <th colSpan={5} />
                <th className="text-right">Total</th>
                <th className="text-right input-pad">{totalPeopleNeeded}</th>
                <th className="text-right input-pad">{totalBillableHours}</th>
                <th className="text-right">${totalCostEstimate}</th>
                <th />
              </tr>
            </thead>
          </table>
        </div>
      );
    }

    return (
      <div className="view">
        <div className="rc-container show-overflow">
          <PCEDetail
            store={store}
            pceStore={pceStore}
            router={this.props.router}
            onClick={this.deleteTag}
          />
        </div>
        <div className="rc-container bring-forward">
          <div className="header-action-bar">
            <div className="btn-group pull-right">
              <Button
                color="brand"
                icon="edit"
                onClick={(e) => {
                  store.showRateCard(pceStore, store.projectStats);
                }}
              >
                Edit
              </Button>
            </div>
            <div className="clearfix" />
          </div>
          {tableContent}
          <div>
            <nav className="action-bar">
              <Button onClick={store.updateProject} size="large" color="brand">
                Save to Worksheet
              </Button>
            </nav>
          </div>
        </div>

        <Modal
          show={store.showRateCardModal}
          onHide={store.hideRateCard}
          innerStyle={{
            width: "90vw",
          }}
        >
          <ContainerSection className="header">
            <ModalTitle>Available Rate Cards</ModalTitle>
          </ContainerSection>
          <div className="rc-viewport overlap-lr-padding">
            <div className="modal-container">
              <RateCardListings
                store={rcstore}
                pcestore={pceStore}
                createstore={store}
                requestFrom="pce"
              />
            </div>
          </div>
          <ContainerSection className="footer">
            <ModalButtons>
              <Button size="large" onClick={store.hideRateCard}>
                Cancel
              </Button>
              <Button
                size="large"
                color="brand"
                onClick={(e) => {
                  store.updateProjectStats(pceStore.punchoutArrayView);
                  store.hideRateCard();
                }}
              >
                Update Worksheet
              </Button>
            </ModalButtons>
          </ContainerSection>
        </Modal>
        <ApplyTagFilterModal
          filterState={store.applyTagState}
          applyTagsEvent={store.applyTags}
          btnText="Apply Tag to Worksheet"
        />
      </div>
    );
  }
}

type PCEPunchoutDetailsProps = {
  punchout: Object,
  store: ProjectCostEstimateDetailStore,
  usd: number, // Is this usd exchange rate?
};

const PCEPunchoutDetails = (props: PCEPunchoutDetailsProps) => {
  const punchout = props.punchout;
  const store = props.store;
  var usd = props.usd.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
  var estimatedCost = (props.usd * punchout.peopleNeeded * punchout.billableHours)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
  const region = punchout.search.region;
  const locParts = [punchout.search.city, punchout.search.state, punchout.search.country];
  const loc = !!region ? region.name : locParts.filter((p) => !!p).join(", ");

  return (
    <tr>
      <td className="job-label">{punchout.search.job.jobLabel}</td>
      <td className="job-title">{punchout.search.job.jobTitle}</td>
      <td className="location">{loc}</td>
      <td className="text-center level">Level {punchout.level.romanNumeral}</td>
      <td className="">
        <TextInput
          css={{
            width: "100%",
            minWidth: 100,
            textAlign: "right",
          }}
          value={punchout.avgBillRate}
          onChange={(e) => store.changeBillRates(e, punchout)}
        />
      </td>
      <td className="text-right bill-rate-usd">${usd}</td>
      <td className="">
        <TextInput
          value={punchout.peopleNeeded}
          css={{
            width: "100%",
            minWidth: 100,
            textAlign: "right",
          }}
          onChange={(e) => store.changePeopleNeededForPunchout(e, punchout)}
        />
      </td>
      <td className="">
        <TextInput
          fill
          value={punchout.billableHours}
          onChange={(e) => store.changeBillableHoursForPunchout(e, punchout)}
        />
      </td>
      <td className="text-right">${estimatedCost}</td>
      <td className="text-center">
        <Button
          color="danger"
          icon="trash-alt"
          onClick={(e) => {
            store.removePunchout(e, punchout);
          }}
        />
      </td>
    </tr>
  );
};

export default observer(Detail);
