import React from "react";

import { RatesBase } from "../../../validator5K_admin/components/Rates/RatesBase";
import { ClassicRatesItemFeedbackContextProvider } from "../../context/feedback/ClassicRatesItemFeedbackContext";
import RatesRow from "./RatesRow";
import RatesTitle from "./RatesTitle";

import RatesFeedback from "./RatesFeedback";
import { useReviewDataContext } from "../../../validator5K_admin/context/ReviewDataContext";

import type { RatesProps } from "../../../validator5K_admin/components/Rates/types";

export default function RatesWithFeedback(props: RatesProps) {
  const { rateResult, rateFeedback } = props;

  const { firstJobRateType: rateType, requiredRates } = useReviewDataContext();

  return (
    <ClassicRatesItemFeedbackContextProvider
      rateResult={rateResult}
      rateFeedback={rateFeedback}
    >
      <RatesBase
        rateResult={rateResult}
        rateFeedback={rateFeedback}
        rateType={rateType}
        requiredRates={requiredRates}
        ratesTitleImpl={RatesTitle}
        ratesRowImpl={RatesRow}
        ratesFeedbackImpl={RatesFeedback}
      />
    </ClassicRatesItemFeedbackContextProvider>
  );
}

RatesWithFeedback.displayName = "RatesWithFeedback";
