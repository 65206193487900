// @flow

import R from "ramda";
import { extendObservable, action, runInAction, computed, observable } from "mobx";
import moment from "moment";
import RateCard from "../../models/RateCard";
import CurrentUser from "../../models/User";
import {
  RateCardLabelFilter,
  HasRateTypeFilter,
  CreatedByFilter,
  SharedFilter,
  CreatedOnFilter,
  TagsFilter,
} from "../../models/FilterState";
import NetworkState from "../../models/NetworkState";
import PaginationState from "../../models/PaginationState";
import FilterObject, { FILTER_COLUMN } from "../../models/Filter";
import Sort, { SORT_DIRECTION } from "../../models/Sort";
import ModalState from "../../models/ModalState";
import {
  addIdToPayload,
  consolidateAppliedFilters,
  consolidateAppliedSorts,
} from "./SupportFunctions";
import type { PageQuery, PaginationInfo } from "../../models/PaginationState";
import type { FilterColumn } from "../../models/Filter";
import type { GraphQLQuery } from "../../models/GraphQL";
import ExportOptionsState from "../../models/ExportOptionsState";
import ExportOptions, { FILE_TYPE, EXPORT_TYPE } from "../../models/ExportOptions";
import ApplyTagState from "../../models/ApplyTagState";
import MessageState from "../../models/MessageState";
import type { FetchGraphQL, FetchAPI } from "../../App";
import { sanitizeInput } from "../../utils/dom";

const ownersCriteriaQuery = (currentUserId: number) => `
query authorList {
  viewer {
    authors(filters:{exclude:[${currentUserId}], isActive: true}) {
      edges {
        node {
          userId
          firstName
          lastName
          username
          email
        }
      }
    }
  }
}
`;

const rateTypesCriteriaQuery = `
query {
  viewer {
    rateCardsFilterCriteria {
      rateTypes
    }
  }
}
`;

const tagsFilterCriteria = `
query getTags {
 viewer{
   tagsFilterCriteria{
     tagnames(contentType: RATE_CARD){
       tag
     }
   }
 }
}
`;

// type Owner = { userId: string, username: string };
class User {
  id: string;
  userId: string;
  username: string;
  firstName: string;
  lastName: string;
  selected: boolean;

  constructor(object: Object) {
    this.id = object.userId;
    this.userId = object.userId;
    this.username = object.username;
    this.firstName = object.firstName;
    this.lastName = object.lastName;

    extendObservable(this, {
      selected: object.selected,
    });
  }
}

export class RateCardListComponentStore {
  network: NetworkState;
  pagination: PaginationState;

  rateCards: RateCard[];
  rateCardsViewState: Object;
  rateCardsView: RateCard[];
  rateCardCounts: any;

  currentUser: CurrentUser;
  isEditing: ?boolean;
  allOnPageSelected: boolean;
  allSelected: boolean;
  allSelectedfilter: boolean;
  allowMultipleItemSelection: boolean;
  selectedCount: number;

  hasRateTypeFilter: HasRateTypeFilter;
  rateCardLabelFilter: RateCardLabelFilter;
  createdByFilter: CreatedByFilter;
  sharedFilter: SharedFilter;
  createdOnFilter: CreatedOnFilter;
  tagsFilter: TagsFilter;
  requestFrom: boolean;

  defaultFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedSorts: {
    [key: FilterColumn]: Sort,
  };
  appliedSortsOrder: Array<FilterColumn>;
  isFiltered: boolean;
  hasOwnership: boolean;
  selectedRateCardId: null;
  unSelectedRateCard: [];

  libraryId: ?number;
  libraryTitleId: ?number;
  libraryTitleName: ?string;
  applyLibraryTitleFilter: (string[], string[], Object) => void;
  removeLibraryTitleFilter: () => void;
  backToLibrary: (Object) => void;

  isConverted: boolean;
  canConvert: () => boolean;
  applyConversion: (String) => void;
  clearConversion: () => void;

  getRateCards: (PageQuery) => Promise<PaginationInfo>;
  applyDefaultFilter: (FilterColumn, FilterObject) => void;
  handleStartEdit: () => void;
  handleStopEdit: () => void;
  toggleSelectAllPage: (Object) => void;
  selectAllPage: (Event) => void;
  deselectAllPage: (Event) => void;
  toggleAllItems: () => void;
  clearAllSelections: () => void;
  getSelectedRateCards: () => Array<string>;
  getFirstSelectedRateCard: () => Object;
  getSelectedRateCardName: () => String;
  toDateRange: () => void;
  getFilterCriteriaQuery: (FilterColumn) => GraphQLQuery;
  processFilterCriteria: (FilterColumn, Object) => Array<Object>;
  applyFilter: (FilterColumn, FilterObject) => void;
  removeFilter: (FilterColumn) => void;
  applySort: (FilterColumn, Sort) => void;
  removeSort: (FilterColumn) => void;
  filterRateCards: () => void;
  clearFilters: () => void;
  applyDefaultSort: () => void;
  updateRequestFrom: (string) => void;
  SingleSelectedRateCard: () => void;
  applyTagState: ApplyTagState;
  setTagViewFn: () => void;
  isTagView: ?boolean;
  deleteSingleTags: () => void;
  renameMessaging: MessageState;
  selectAllOnPageItem: () => void;
  clearAllSelectionsOnTagView: () => void;
  fetchGraphQL: FetchGraphQL;

  constructor(fetchGraphQL: FetchGraphQL) {
    this.fetchGraphQL = fetchGraphQL;

    // NOTE: Bound early to pass into pagination & filter state
    this.getRateCards = action(this.getRateCards.bind(this));

    this.getFilterCriteriaQuery = action(this.getFilterCriteriaQuery.bind(this));
    this.processFilterCriteria = action(this.processFilterCriteria.bind(this));
    this.applyFilter = action(this.applyFilter.bind(this));
    this.applySort = action(this.applySort.bind(this));
    this.removeFilter = action(this.removeFilter.bind(this));
    this.removeSort = action(this.removeSort.bind(this));
    this.toDateRange = action(this.toDateRange.bind(this));
    this.unSelectedRateCard = [];

    extendObservable(this, {
      network: new NetworkState(),
      pagination: new PaginationState(this.getRateCards),
      rateCards: [],
      rateCardCounts: 0,
      hasOwnership: true,
      selectedRateCardId: null,
      rateCardsViewState: observable.map({}),
      rateCardsView: computed(() => {
        return this.rateCards.map((rateCard) => {
          if (this.rateCardsViewState.has(rateCard.ratecardId)) {
            rateCard.viewState = this.rateCardsViewState.get(rateCard.ratecardId);

            return rateCard;
          }

          return rateCard;
        });
      }),
      isEditing: null, // we start with null so some view elements be hidden initially
      isFiltered: false,
      isConverted: false,
      allSelected: false,
      allSelectedfilter: false,
      allowMultipleItemSelection: true,
      requestFrom: false,
      selectedCount: computed(() => {
        const selectedValues = this.rateCardsView.map(
          (rateCard) => rateCard.viewState.selected
        );

        if (this.allSelected) {
          return this.pagination.totalCount;
        }

        let count = 0;

        selectedValues.forEach((v) => {
          if (v) {
            count += 1;
          }
        });

        return count;
      }),
      allOnPageSelected: computed(() => {
        const allTrue = R.all(R.equals(true));
        const selectedValues = this.rateCardsView.map(
          (rateCard) => rateCard.viewState.selected
        );

        if (selectedValues.length === 0) {
          return false;
        }

        return allTrue(selectedValues);
      }),
      libraryId: null,
      libraryTitleId: null,
      libraryTitleName: null,
      defaultFilters: {},
      appliedFilters: {},
      appliedSorts: {},
      appliedSortsOrder: observable.shallow([]),
      rateCardLabelFilter: new RateCardLabelFilter(
        this,
        FILTER_COLUMN.RATE_CARD_LABEL,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      hasRateTypeFilter: new HasRateTypeFilter(
        this,
        FILTER_COLUMN.RATE_TYPE,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.removeFilter
      ),
      createdByFilter: new CreatedByFilter(
        this,
        FILTER_COLUMN.CREATED_BY,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      sharedFilter: new SharedFilter(
        this,
        FILTER_COLUMN.SHARED,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      createdOnFilter: new CreatedOnFilter(
        this,
        FILTER_COLUMN.DATE_RANGE,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      tagsFilter: new TagsFilter(
        this,
        FILTER_COLUMN.TAGS,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      applyTagState: new ApplyTagState(this.fetchGraphQL, this),
      isTagView: null,
      renameMessaging: new MessageState(),
    });

    this.currentUser = null;

    this.applyDefaultFilter = action(this.applyDefaultFilter.bind(this));
    this.handleStartEdit = action(this.handleStartEdit.bind(this));
    this.handleStopEdit = action(this.handleStopEdit.bind(this));
    this.toggleSelectAllPage = action(this.toggleSelectAllPage.bind(this));
    this.selectAllPage = action(this.selectAllPage.bind(this));
    this.deselectAllPage = action(this.deselectAllPage.bind(this));
    this.toggleAllItems = action(this.toggleAllItems.bind(this));
    this.clearAllSelections = action(this.clearAllSelections.bind(this));
    this.getSelectedRateCards = action(this.getSelectedRateCards.bind(this));
    this.getSelectedRateCardName = action(this.getSelectedRateCardName.bind(this));

    this.getFirstSelectedRateCard = action(this.getFirstSelectedRateCard.bind(this));

    this.filterRateCards = action(this.filterRateCards.bind(this));
    this.clearFilters = action(this.clearFilters.bind(this));
    this.applyDefaultSort = action(this.applyDefaultSort.bind(this));
    this.applyLibraryTitleFilter = action(this.applyLibraryTitleFilter.bind(this));
    this.removeLibraryTitleFilter = action(this.removeLibraryTitleFilter.bind(this));
    this.backToLibrary = action(this.backToLibrary.bind(this));
    this.updateRequestFrom = action(this.updateRequestFrom.bind(this));
    this.SingleSelectedRateCard = action(this.SingleSelectedRateCard.bind(this));
    this.setTagViewFn = action(this.setTagViewFn.bind(this));
    this.deleteSingleTags = action(this.deleteSingleTags.bind(this));
    this.selectAllOnPageItem = action(this.selectAllOnPageItem.bind(this));
    this.clearAllSelectionsOnTagView = action(
      this.clearAllSelectionsOnTagView.bind(this)
    );

    this.applyDefaultSort();
  }

  setTagViewFn() {
    this.isTagView = true;
    this.isEditing = true;
  }

  toDateRange(date) {
    var val = true;
    if (this.createdOnFilter.fromDate) {
      val = !date.isBetween(
        this.createdOnFilter.fromDate,
        moment(new Date()),
        "days",
        "[]"
      );
    }
    return val;
  }

  updateRequestFrom(request: string) {
    if (request && request === "pce") this.requestFrom = true;
    else {
      this.requestFrom = false;
    }
  }

  canExport() {
    const exportRole = "No Export Allowed";
    if (
      this.currentUser &&
      this.currentUser.roles &&
      this.currentUser.roles.indexOf(exportRole) > -1
    ) {
      return false;
    }
    return true;
  }

  SingleSelectedRateCard(ratecard) {
    let viewState = this.rateCardsViewState.get(ratecard.ratecardId).selected;
    if (viewState) {
      this.selectedRateCardId = ratecard.ratecardId;
    } else {
      this.selectedRateCardId = null;
    }

    let checkOwnership = true;
    if (this.allSelected || this.allOnPageSelected) {
      this.hasOwnership = true;
      return;
    }

    this.rateCardsView.forEach((value) => {
      if (
        String(value.owner.clientId) !== String(this.currentUser.userId) &&
        value.viewState.selected
      ) {
        this.hasOwnership = false;
        checkOwnership = false;
        return;
      }
    });

    if (checkOwnership) {
      if (!viewState && ratecard.owner && ratecard.owner.clientId) {
        this.hasOwnership =
          String(ratecard.owner.clientId) === String(this.currentUser.userId);
      } else {
        this.hasOwnership = true;
      }
    }
  }

  applyDefaultSort() {
    this.createdOnFilter.sortState.direction = SORT_DIRECTION.DESC;
    // $FlowFixMe: Suppress until Filter classes have proper types
    this.createdOnFilter.sort = this.createdOnFilter.buildQuerySort();
    this.applySort(this.createdOnFilter.column, this.createdOnFilter.sort);
  }

  handleStartEdit() {
    this.isEditing = true;
    this.rateCardsView.forEach((rateCard) => rateCard.toggleEdit());
  }

  handleStopEdit() {
    this.isEditing = false;
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.rateCardsViewState.forEach((viewState) => {
      viewState.selected = false;
      viewState.editing = false;
    });
  }

  toggleSelectAllPage(e: Object) {
    if (!this.allowMultipleItemSelection) return;

    const setValue = !this.allOnPageSelected;

    this.rateCardsView.forEach((rateCard) => {
      rateCard.toggleSelected(e, null, null, setValue);
    });

    // When All items selected flag is up, clear selection
    if (setValue === false && this.allSelected) this.allSelected = false;
  }

  selectAllPage(e: Event) {
    this.hasOwnership = true;
    this.unSelectedRateCard = [];
    this.allSelected = true;
    this.allSelectedfilter = true;
  }

  selectAllOnPageItem(e: Event) {
    this.rateCardsView.forEach((rateCard) => {
      rateCard.toggleSelected(e, null, null, true);
    });
  }

  deselectAllPage(e: Event) {
    this.rateCardsView.forEach((rateCard) => {
      rateCard.toggleSelected(e, null, null, false);
    });

    this.allSelected = false;
    this.allSelectedfilter = false;
  }

  toggleAllItems() {
    if (!this.allowMultipleItemSelection) return;

    this.allSelected = !this.allSelected;

    if (this.allSelected === false) {
      this.rateCardsViewState.forEach((value) => {
        value.selected = false;
      });
    }
  }

  clearAllSelections() {
    this.isTagView = false;
    //this.isEditing = false;
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.rateCardsViewState.forEach((value) => {
      value.selected = false;
    });
  }

  clearAllSelectionsOnTagView() {
    //this.isEditing = false;
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.rateCardsViewState.forEach((value) => {
      value.selected = false;
    });
  }

  getFilterCriteriaQuery(column: FilterColumn): GraphQLQuery {
    switch (column) {
      case FILTER_COLUMN.RATE_TYPE:
        return {
          query: rateTypesCriteriaQuery,
          variables: {},
        };

      case FILTER_COLUMN.CREATED_BY:
        return {
          query: ownersCriteriaQuery(this.currentUser.userId),
          variables: {},
        };
      case FILTER_COLUMN.TAGS:
        return {
          query: tagsFilterCriteria,
          variables: {},
        };

      default:
        return null;
    }
  }

  processFilterCriteria(column: FilterColumn, payload: Object): ?Array<Object> {
    switch (column) {
      case FILTER_COLUMN.RATE_TYPE:
        const rateTypes: [String] = payload.data.viewer.rateCardsFilterCriteria.rateTypes;
        return addIdToPayload(rateTypes);

      case FILTER_COLUMN.CREATED_BY:
        const owners: [
          {
            userId: string,
            username: string,
          }
        ] = payload.data.viewer.authors.edges;
        let processedOwners = observable.map({});
        owners.forEach((owner) => {
          processedOwners.set(String(owner.node.userId), {
            id: String(owner.node.userId),
            ...owner,
          });
        });

        return processedOwners;

      case FILTER_COLUMN.TAGS:
        const tags: [
          {
            tag: string,
          }
        ] = payload.data.viewer.tagsFilterCriteria.tagnames;
        let processedTag = observable.map({});
        tags.forEach((tag, index) => {
          processedTag.set(String(index), {
            id: String(index),
            ...tag,
          });
        });
        return processedTag;

      default:
        return null;
    }
  }

  applyFilter(column: FilterColumn, filter: FilterObject) {
    this.appliedFilters[column] = filter;
    this.isFiltered = true;
  }

  applyDefaultFilter(column: FilterColumn, filter: FilterObject) {
    this.defaultFilters[column] = filter;
  }

  removeFilter(column: FilterColumn) {
    delete this.appliedFilters[column];

    let entries = Object.entries(this.appliedFilters);
    if (!entries.length) this.isFiltered = false;
  }

  applySort(column: FilterColumn, sort: Sort) {
    this.appliedSorts[column] = sort;

    const index = this.appliedSortsOrder.indexOf(column);
    if (index === -1) this.appliedSortsOrder.push(column);
  }

  removeSort(column: FilterColumn) {
    delete this.appliedSorts[column];

    const index = this.appliedSortsOrder.indexOf(column);
    if (index > -1) this.appliedSortsOrder.splice(index, 1);
  }

  clearFilters() {
    this.rateCardLabelFilter = new RateCardLabelFilter(
      this,
      FILTER_COLUMN.RATE_CARD_LABEL,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.hasRateTypeFilter = new HasRateTypeFilter(
      this,
      FILTER_COLUMN.RATE_TYPE,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.removeFilter
    );
    this.createdByFilter = new CreatedByFilter(
      this,
      FILTER_COLUMN.CREATED_BY,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.sharedFilter = new SharedFilter(
      this,
      FILTER_COLUMN.SHARED,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.createdOnFilter = new CreatedOnFilter(
      this,
      FILTER_COLUMN.DATE_RANGE,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );

    this.tagsFilter = new TagsFilter(
      this,
      FILTER_COLUMN.TAGS,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );

    this.appliedFilters = observable({});
    this.appliedSorts = observable({});
    this.appliedSortsOrder.length = 0;
    this.isFiltered = false;
    this.applyDefaultSort();

    return this.pagination.goFetch(null);
  }

  filterRateCards() {
    return this.pagination.goFetch(null);
  }

  canConvert() {
    // You can convert if not US
    // If only country
    return false;
  }

  applyConversion(currency: String) {}

  clearConversion() {}

  getSelectedRateCards(): Array<string> {
    const rateCards = this.rateCardsViewState;

    let selectedRateCards = [];

    rateCards.forEach((value, key) => {
      if (value.selected) {
        selectedRateCards.push(key);
      }
    });

    return selectedRateCards;
  }

  getSelectedRateCardName() {
    const rateCards = this.rateCardsViewState;

    let selectedRateCards = [];
    var that = this;
    rateCards.forEach((value, key) => {
      if (value.selected) {
        for (var idx = 0; idx < that.rateCardsView.length; idx++) {
          if (parseInt(that.rateCardsView[idx].ratecardId, 10) === parseInt(key, 10)) {
            selectedRateCards.push(this.rateCards[idx].name);
          }
        }
      }
    });

    return selectedRateCards;
  }

  getFirstSelectedRateCard() {
    for (let j = 0; j < this.rateCardsView.length; j++) {
      if (this.rateCards[j].viewState.selected) return this.rateCards[j];
    }

    return null;
  }

  backToLibrary(router: Object) {
    const libraryId = this.libraryId;
    if (!libraryId) return;
    this.libraryId = null;
    this.libraryTitleId = null;
    this.libraryTitleName = null;
    router.push(`/job-library/view/${libraryId}/`);
  }

  removeLibraryTitleFilter() {
    this.libraryId = null;
    this.libraryTitleId = null;
    this.libraryTitleName = null;
    this.pagination.goFetch();
  }

  applyLibraryTitleFilter(
    params: string[],
    filtersCriteria: string[],
    variables: Object
  ) {
    params.push("$libraryTitleId: ID!");
    filtersCriteria.push("hasSearchWithTitleId: $libraryTitleId");
    Object.assign(variables, { libraryTitleId: this.libraryTitleId });
  }

  async getRateCards(pageQuery: PageQuery): Promise<PaginationInfo> {
    let params: string[] = pageQuery.params;
    let args = pageQuery.args;
    let variables = pageQuery.variables;
    let filtersCriteria: string[] = [];

    let sortCriteria: string[] = [];
    consolidateAppliedSorts(this.appliedSorts, sortCriteria);

    consolidateAppliedFilters(this.appliedFilters, params, filtersCriteria, variables);

    // NOTE: applied filters could override default filters, handle this if needed
    consolidateAppliedFilters(this.defaultFilters, params, filtersCriteria, variables);
    if (this.libraryTitleId) {
      this.applyLibraryTitleFilter(params, filtersCriteria, variables);
    }
    if (this.requestFrom) {
      params.push("$hasSearch: Boolean");
      params.push("$hasBuyRate: Boolean");
      filtersCriteria.push("hasSearch: $hasSearch");
      filtersCriteria.push("hasBuyRate: $hasBuyRate");
      variables.hasSearch = true;
      variables.hasBuyRate = true;
    }

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");
    const queryFiltersCriteria = filtersCriteria.join(", ");
    const querySortCriteria = sortCriteria.join(", ");

    const query = `
    query rateCardList (${queryParams}) {
        viewer {
          user{
            firstName
            lastName
            userId
            username
            email
            roles
          }
          allRateCards(${queryArgs}, filters: { ${queryFiltersCriteria} }, order: [${querySortCriteria}]){
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            totalCount
            edges {
              node {
                ratecardId
                shared
                tags{
                  name
                  tagId
                }
                owner {
                  firstName
                  lastName
                  email
                  userId
                  termsOfAgreement
                }
                name
                createdDate
                updateDate
                searchCount
                locationCount
              }
            }
          }
        }
      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Getting Rate Cards", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Rate Card query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getRateCards--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get Rate Cards query", res)) {
        // TODO: Display user friendly error message
        return {
          totalCount: 0,
          startCursor: "",
          endCursor: "",
        };
      }

      this.currentUser = new CurrentUser(this, res.data.viewer.user);

      // // $FlowFixMe: Type the res value once we abstract gql calls.
      const cards = res.data.viewer.allRateCards.edges;

      this.rateCardCounts = res.data.viewer.allRateCards.totalCount;
      // // TODO: Deserialize this properly...
      this.rateCards = cards.map((card) => {
        const rateCard = new RateCard(this, card.node);

        // rateCard.searches = card.node.searches.totalCount;
        // rateCard.locations = card.node.locations;
        if (!this.rateCardsViewState.has(rateCard.ratecardId)) {
          this.rateCardsViewState.set(rateCard.ratecardId, {
            selected: this.allSelected,
            editing: this.isEditing,
            expanded: false,
          });
        } else {
          const selectedValue = this.allSelected
            ? true
            : this.rateCardsViewState.get(rateCard.ratecardId).selected;

          this.rateCardsViewState.set(rateCard.ratecardId, {
            selected: selectedValue,
            editing: this.isEditing,
            expanded: false,
          });
        }

        rateCard.viewState = this.rateCardsViewState.get(rateCard.ratecardId);

        return rateCard;
      });

      return {
        totalCount: res.data.viewer.allRateCards.totalCount,
        startCursor: res.data.viewer.allRateCards.pageInfo.startCursor,
        endCursor: res.data.viewer.allRateCards.pageInfo.endCursor,
      };
    });
  }

  async deleteSingleTags(content) {
    if (this.network.loading) {
      return;
    }

    const query = `
    mutation removeTagsFromContent{
      removeTagsFromContent(input: {tagIds : [${content.tagId}],contentType :RATE_CARD,contentId:${content.contentId}}){
        ok
        }
      }
    `;
    //query.variables = vars;

    // this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, {});
    } catch (e) {
      this.network.handleError("Deleting selected Tag", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }

    // this.handleStopEdit();

    runInAction("deleteSingleTags--success", () => {
      // this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("deleteTag", res)) {
        // TODO: Display user friendly error message
        return;
      }

      //this.pagination.goFetch(null);
    });
    return {
      result: res.data.removeTagsFromContent.ok,
    };
  }
}

export default class RateCardListStore extends RateCardListComponentStore {
  showHelpModal: boolean;
  newRateCardName: string;

  shareUsers: User[];
  shareUsersView: User[];
  shareUsersViewState: Object;
  shareUsersInstantSearchValue: string;
  shareUsersTotalCount: number;
  shareUsersSelectedCount: number;

  helpModal: ModalState;
  confirmUpdateRatesModal: ModalState;
  confirmUndoUpdateRatesModal: ModalState;
  confirmDeleteModal: ModalState;
  renameRateCardModal: ModalState;
  shareModal: ModalState;
  networkShareUsers: NetworkState;

  exportModal: ExportOptionsState;

  errorModal: ModalState;
  errorMessage: any;

  showHelp: () => void;
  hideHelp: () => void;
  showRenameRateCardModal: () => void;
  renameRateCard: () => void;
  onNewRateCardNameChange: (e: Event & { target: HTMLInputElement }) => void;
  shareUsersOnSelectAll: () => void;
  shareUsersOnDeselectAll: () => void;
  shareUsersOnInstantSearch: (string) => void;
  getShareUsers: () => void;
  share: () => void;
  setSharedUserSelectedValue: (Object) => void;
  performShare: () => void;
  deleteRateCards: () => void;
  refreshRateCards: () => void;
  undoLastUpdateRateCards: () => Promise<?Object>;
  exportRateCards: (options: ExportOptions) => void;
  validateExportOptions: (modalState: ExportOptionsState) => void;
  viewInMap: () => void;
  applyTags: () => void;
  fetchAPI: FetchAPI;
  apiServerURL: string;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI, apiServerURL: string) {
    super(fetchGraphQL);

    this.fetchGraphQL = fetchGraphQL;
    this.fetchAPI = fetchAPI;

    // Bound early to pass into export state
    this.exportRateCards = action(this.exportRateCards.bind(this));
    this.validateExportOptions = action(this.validateExportOptions.bind(this));

    extendObservable(this, {
      apiServerURL,
      newRateCardName: "",
      errorMessage: "",
      errorModal: new ModalState(),
      helpModal: new ModalState(),
      renameRateCardModal: new ModalState(),
      confirmUpdateRatesModal: new ModalState(),
      confirmUndoUpdateRatesModal: new ModalState(),
      confirmDeleteModal: new ModalState(),
      exportModal: new ExportOptionsState(
        this.exportRateCards,
        this.validateExportOptions
      ),
      shareModal: new ModalState(),
      networkShareUsers: new NetworkState(),
      shareUsers: observable.shallow([]),
      shareUsersViewState: observable.map({}),
      shareUsersInstantSearchValue: "",
      shareUsersView: observable.shallow([]),
      shareUsersHasNoSelection: computed(() => {
        return this.shareUsersViewState.entries().length === 0;
      }),
      shareUsersTotalCount: computed(() => {
        if (!this.shareUsers) return 0;

        return this.shareUsers.length;
      }),
      shareUsersSelectedCount: computed(() => {
        if (!this.shareUsersViewState) return 0;

        const selected = this.shareUsersViewState
          .entries()
          .filter((entry) => entry[1] === true);
        return selected.length;
      }),
    });

    this.showHelp = action(this.showHelp.bind(this));
    this.hideHelp = action(this.hideHelp.bind(this));
    this.showRenameRateCardModal = action(this.showRenameRateCardModal.bind(this));
    this.renameRateCard = action(this.renameRateCard.bind(this));
    this.onNewRateCardNameChange = action(this.onNewRateCardNameChange.bind(this));

    this.getShareUsers = action(this.getShareUsers.bind(this));
    this.shareUsersOnSelectAll = action(this.shareUsersOnSelectAll.bind(this));
    this.shareUsersOnDeselectAll = action(this.shareUsersOnDeselectAll.bind(this));
    this.setSharedUserSelectedValue = action(this.setSharedUserSelectedValue.bind(this));
    this.shareUsersOnInstantSearch = action(this.shareUsersOnInstantSearch.bind(this));
    this.share = action(this.share.bind(this));
    this.performShare = action(this.performShare.bind(this));
    this.deleteRateCards = action(this.deleteRateCards.bind(this));
    this.refreshRateCards = action(this.refreshRateCards.bind(this));
    this.undoLastUpdateRateCards = action(this.undoLastUpdateRateCards.bind(this));
    this.viewInMap = action(this.viewInMap.bind(this));
    this.applyTags = action(this.applyTags.bind(this));
  }

  showHelp() {
    this.showHelpModal = true;
  }

  hideHelp() {
    this.showHelpModal = false;
  }

  showRenameRateCardModal() {
    const rateCard = this.getFirstSelectedRateCard();
    if (!rateCard) return;

    this.newRateCardName = rateCard.name;
    this.renameRateCardModal.showModal();
  }

  onNewRateCardNameChange(e: Event & { target: HTMLInputElement }) {
    this.newRateCardName = sanitizeInput(e.target.value);
  }

  shareUsersOnInstantSearch(value: string) {
    this.shareUsersInstantSearchValue = value;

    if (!this.shareUsersInstantSearchValue) {
      this.shareUsersView = this.shareUsers;
      return;
    }

    this.shareUsersView = this.shareUsers.filter((user) => {
      const firstName = user.firstName.toLowerCase();
      const lastName = user.lastName.toLowerCase();
      const username = user.username.toLowerCase();
      const query = this.shareUsersInstantSearchValue.toLowerCase();

      // this works because ~ is the binary inverse of a number and ~ -1 = 0
      return (
        ~firstName.indexOf(query) || ~lastName.indexOf(query) || ~username.indexOf(query)
      );
    });
  }

  shareUsersOnSelectAll(e: Object) {
    this.shareUsersView.forEach((user) => {
      this.shareUsersViewState.set(user.userId, true);
      user.selected = true;
    });
  }

  shareUsersOnDeselectAll(e: Object) {
    this.shareUsersView.forEach((user) => {
      this.shareUsersViewState.set(user.userId, false);
      user.selected = false;
    });
  }

  setSharedUserSelectedValue(user: Object) {
    const oldSelectedValue = this.shareUsersViewState.get(user.userId);

    if (oldSelectedValue) {
      this.shareUsersViewState.set(user.userId, !oldSelectedValue);
      user.selected = !oldSelectedValue;
    } else {
      this.shareUsersViewState.set(user.userId, true);
      user.selected = true;
    }
  }

  share() {
    this.shareUsersInstantSearchValue = "";
    this.getShareUsers();
    this.shareModal.showModal();
  }

  validateExportOptions(modalState: ExportOptionsState) {
    if (modalState.exportOptions.fileType === FILE_TYPE.CSV) {
      modalState.disableExportTypeOptions = true;
      modalState.exportOptions.exportType = EXPORT_TYPE.DETAILED;
      modalState.info = "CSV file format will always include rates data.";
    } else {
      modalState.disableExportTypeOptions = false;
      modalState.info = null;
    }
  }

  exportRateCards(options: ExportOptions) {
    if (this.network.loading) {
      return;
    }

    let params = [];
    let args = [];
    let vars = {};
    // let input = {};
    let exportURL = "";
    const rateType = {};
    rateType.Annual = 2;
    rateType.Hourly = 1;
    // Change the Filename
    var fileName = options.fileName;
    let parameters = "";
    let exparams = "";

    if (this.allSelectedfilter) {
      consolidateAppliedFilters(this.appliedFilters, params, args, vars);
      if (vars.rateType) {
        for (var i in rateType) {
          if (rateType[i] === vars.rateType) {
            vars.rateType = i;
          }
        }
      }
      vars.exclude = this.unSelectedRateCard;
      exportURL = "ratecards/list/export/excel/";
      parameters = JSON.stringify(vars);
      if (parameters === "") {
        exparams = `{"fileName": "${fileName}", "rateCardFilters": {${parameters}}}`;
      } else {
        exparams = `{"fileName": "${fileName}", "rateCardFilters": ${parameters}}`;
      }
    } else {
      const selectedRateCards = this.getSelectedRateCards();
      if (!selectedRateCards || !selectedRateCards.length) {
        console.error("Cannot export RateCards: No RateCards selected");
        return;
      }
      vars.only = selectedRateCards;
      if (selectedRateCards.length === 1) {
        if (options.fileType === "csv") {
          options.exportType = "basic";
        }
        exportURL = [
          "ratecards/",
          selectedRateCards[0],
          "/searches/",
          options.exportType,
          "/list/export/",
          options.fileType,
          "/",
        ].join("");
        exparams = `{"fileName": "${fileName}"}`;
      } else {
        exportURL = "ratecards/list/export/excel/";
        parameters = JSON.stringify(vars);
        if (parameters === "") {
          exparams = `{"fileName": "${fileName}", "rateCardFilters": {${parameters}}}`;
        } else {
          exparams = `{"fileName": "${fileName}", "rateCardFilters": ${parameters}}`;
        }
      }
    }

    // TODO: Replace this with some kind of special fetch function for downloading files
    var url = `${this.apiServerURL}/api/${exportURL}`;
    this.fetchAPI(url, JSON.parse(exparams))
      .then((res) => {
        return res.data;
      })
      .then((json) => {
        window.location.href = json.url;
      })
      .catch((e) => {
        console.error("Error downloading excel", e);
        // TODO: Handle errors properly
        throw e; // Prevent success action from running
      });
    this.handleStopEdit();
  }

  viewInMap() {
    throw new Error("viewInMap not implemented");
  }

  async renameRateCard() {
    if (this.network.loading) {
      return;
    }

    const selectedRateCards = this.getSelectedRateCards();
    if (selectedRateCards.length !== 1) {
      console.error("One and only one Rate Card must be selected");
      return;
    }

    this.renameMessaging.removeAll();
    if (!this.newRateCardName.trim()) {
      this.renameMessaging.createMessage("info", "Please enter a New Rate Card Name.");
      return;
    }

    const query = `
      mutation updateRateCard($rateCardId: ID!, $name: String!){
        updateRateCards(input: {name: $name, rateCardId: $rateCardId}){
            rateCard{
            ratecardId,
            name,
            createdDate,
            updateDate
          }errors{
            __typename
            ...on RateCardNameEmptyError{
              message
            }
            ...on RateCardNameAlreadyExistsError{
              message
            }
            ...on RateCardNameMaxLengthError{
              message
            }
            ...on NameAlreadyExistError{
              message
            }
          }
        }
      }
    `;
    const variables = {
      rateCardId: selectedRateCards[0],
      name: this.newRateCardName,
    };

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Renaming Rate Card", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    runInAction("renameRateCard--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("editRateCardName", res)) {
        // TODO: Display user friendly error message
        return;
      }
      if (res.data.updateRateCards.errors) {
        this.renameMessaging.removeAll();
        this.renameMessaging.createMessage(
          "error",
          res.data.updateRateCards.errors[0].message
        );
        return;
      }
      this.renameRateCardModal.hideModal();
      this.handleStopEdit();
      this.pagination.goFetch(null);
    });
  }

  async getShareUsers() {
    const rateCard = this.getFirstSelectedRateCard();

    let excludeCurrentUser = [this.currentUser.userId];
    if (!rateCard) {
      console.error("One and only one Rate Card must be selected");
      return;
    }

    const query = `

    query authorList($rateCardId: Int!) {
      viewer {
        authors(order:[{field: FIRST_NAME}, {field: LAST_NAME}], filters:{exclude:${excludeCurrentUser}, isActive: true}) {
          edges {
            node {
              userId
              firstName
              lastName
              username
              email
            }
          }
        }
        currentlySharedWithUsers(rateCardId: $rateCardId) {
          edges {
            node {
              userId
              firstName
              lastName
              lastLogin
              username
              email
            }
          }
        }
      }
    }
    `;

    const variables = {
      rateCardId: rateCard.ratecardId,
    };

    this.networkShareUsers.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.networkShareUsers.handleError("Getting Users for sharing", e);
      // TODO: Display user friendly error message
      return;
    }

    runInAction("getShareUsers--success", () => {
      this.networkShareUsers.loading = false;
      this.networkShareUsers.error = null;
      if (this.networkShareUsers.logGraphQLError("Filter criteria query", res)) {
        // TODO: Display user friendly error message
        // 'There was an error retrieving the data for this page. Please reload and try again later.',
        return;
      }

      // $FlowFixMe: Type the res value once we abstract gql calls.
      const users = res.data.viewer.authors.edges;
      const currentlySharedUsers = res.data.viewer.currentlySharedWithUsers.edges;

      this.networkShareUsers.loading = false;
      this.networkShareUsers.error = null;

      // set as selected the users that are currently shared with
      this.shareUsersViewState = observable.map({});
      currentlySharedUsers.forEach((edge) => {
        this.shareUsersViewState.set(edge.node.userId, true);
      });

      // TODO: Deserialize this properly...
      this.shareUsers = users.map((edge) => {
        const user = new User(edge.node);

        if (!user.firstName) user.firstName = "";
        if (!user.lastName) user.lastName = "";

        if (this.shareUsersViewState.has(user.userId)) {
          user.selected = this.shareUsersViewState.get(user.userId);
        } else {
          user.selected = false;
        }

        return user;
      });

      this.shareUsersView = this.shareUsers;
    });
  }

  async performShare() {
    if (this.network.loading) {
      return;
    }
    const rateCard = this.getFirstSelectedRateCard();
    const selectedUserIds = this.shareUsersViewState
      .entries()
      .filter((entry) => entry[1] === true)
      .map((entry) => entry[0]);

    const query = `
      mutation shareRateCard($userIds: [String]!, $rateCardId: String!) {
        shareRateCards(input: { ratecardId:$rateCardId, userIds: $userIds }) {
           shareRateCards {
             user{
               firstName,
               lastName,
               username
             },
             rateCard{
               name
             }
           }
           errors {
             __typename
           }
         }
      }
    `;

    const variables = {
      userIds: selectedUserIds,
      rateCardId: rateCard.ratecardId,
    };

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Sharing Rate Card", e);
      this.errorMessage = "Sorry! The operation could not be completed.";
      this.errorModal.showModal();
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    this.shareModal.hideModal();
    this.handleStopEdit();

    runInAction("performShare--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("shareRateCard", res)) {
        const errors =
          res && res.data && res.data.shareRateCards
            ? res.data.shareRateCards.errors
            : [];
        const notAllowed = errors.find(
          (e) => e.__typename === "NotAllowedForCurrentUserError"
        );
        if (!!notAllowed) {
          this.errorMessage = "Sorry! You're not allowed to share Rate Cards.";
          this.errorModal.showModal();
        } else if (!!errors) {
          this.errorMessage =
            "Sorry! Something went wrong. Check to make sure the operation completed correctly.";
          this.errorModal.showModal();
        }
      }

      this.pagination.goFetch(null);
    });
  }

  async refreshRateCards() {
    if (this.network.loading) {
      return;
    }

    const query = `
      mutation updateMarketRates($rateCardId: String){
        updateMarketRate(input: {rateCardId: $rateCardId, filters: {}}){
          ok
        }
      }
    `;

    const variables = {
      rateCardId: String(this.selectedRateCardId),
    };

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Updating Rate Cards", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    this.confirmUpdateRatesModal.hideModal();
    this.handleStopEdit();

    runInAction("refreshRateCards--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("refreshRateCards", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.pagination.goFetch(null);
    });
  }

  async undoLastUpdateRateCards() {
    if (this.network.loading) {
      return;
    }

    const query = `
      mutation undoUpdateMarketRates($rateCardId: String){
        undoUpdateRatecardSearchMarketRate(input: {rateCardId: $rateCardId, filters: {}}){
          ok
        }
      }
    `;

    const variables = {
      rateCardId: String(this.selectedRateCardId),
    };

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Undo Last Update", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    this.confirmUndoUpdateRatesModal.hideModal();
    this.handleStopEdit();

    runInAction("undoLastUpdateRateCards--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("undoLastUpdateRateCards", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.pagination.goFetch(null);
    });
  }

  async deleteRateCards() {
    if (this.network.loading) {
      return;
    }

    let params = [];
    let args = [];
    let vars = {};
    let queryArgs = [];

    consolidateAppliedFilters(this.appliedFilters, params, queryArgs, vars);

    if (this.allSelected) {
      params.push("$exclude: [Int]!");
      args.push("exclude: $exclude");
      vars.exclude = []; // no exclusions means all will be processed if there are no other filters

      // include current filters
    } else {
      const selectedRateCards = this.getSelectedRateCards();
      if (!selectedRateCards || !selectedRateCards.length) {
        console.error("Cannot delete Rate Cards: No Rate Cards selected");
        return;
      }

      params.push("$only: [Int]");
      args.push("only: $only");
      vars.only = selectedRateCards;
    }

    const query = `
    mutation deleteRateCard(${params}){
      deleteRateCards(input: {${args},filters: { ${queryArgs}}}){
      ok
      }
      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Deleting selected Rate Cards", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    this.handleStopEdit();

    runInAction("deleteRateCards--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("deleteRateCard", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.pagination.goFetch(null);
    });
  }

  async applyTags() {
    //var searchCriteria = this.selectWorksheets("delete");
    // let searchquery = '';
    // let parameters = {};

    let params = [];
    let filterargs = [];
    let searchargs = [];
    let args = [];
    let vars = {};

    const taglist = this.applyTagState.getSelectedTagList();
    if (!taglist || !taglist.length) {
      console.error("Cannot Apply tags to RateCards: No RateCards selected");
      return;
    }
    params.push("$tagIds: [Int]!");
    filterargs.push("tagIds: $tagIds");
    vars.tagIds = taglist;

    if (this.allSelectedfilter) {
      consolidateAppliedFilters(this.appliedFilters, params, args, vars);
      params.push("$exclude: [ID]");
      args.push("exclude: $exclude");
      vars.exclude = [];
      // vars.exclude = vars.exclude.filter(function(item, pos) {
      //   return vars.exclude.indexOf(item) == pos;
      // })
    } else {
      const itemList = this.getSelectedRateCards();
      if (!itemList || !itemList.length) {
        console.error("Cannot apply tage RateCards: No RateCards selected");
        return;
      }
      params.push("$only: [ID]");
      args.push("only: $only");
      vars.only = itemList;
    }

    if (this.rateCardLabelFilter.textToLookFor) {
      params.push("$nameIContains: String");
      args.push("nameIContains:$nameIContains");
      vars.nameIContains = this.rateCardLabelFilter.textToLookFor;
    }

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
      mutation applyTags(${queryParams}){
       applyTagsToRatecards(input:{${filterargs}, filters: { ${queryArgs}}, ${searchargs}}) {
         ok
          errors {
           __typename
           ... on TagIdRequiredError {
             message
           }
           ... on TagIdsNotExistsError {
             message
           }
           ... on InvalidInputError {
             message
           }
           ... on ContentDoesNotExistsError {
             message
           }
         }
       }

      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Apply Tags to Selected RateCards", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    this.handleStopEdit();

    runInAction("applyTags--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("applyTags", res)) {
        // TODO: Display user friendly error message
        return;
      }
      this.applyTagState.tagModal.hideModal();
      this.pagination.goFetch(null);
    });
  }
}
