import React from "react";

import Box from "../../../../components/lib/Box";
import TextBase from "../../../../components/lib/Text";
import Inline from "../../../../components/lib/Inline";
import { RadioGroup, RadioGroupItem } from "../../../../components/lib/RadioGroup";
import { styled } from "../../../../stitches.config";

const Items = styled(Inline, {
  padding: "$2",
  border: "1px dashed $primaryLight",
  borderBottomWidth: 0,

  "&:first-of-type": {
    borderRadius: "3px",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },

  "&:last-of-type": {
    borderRadius: "3px",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomWidth: "1px",
  },
});

const Text = styled(TextBase, {
  variants: {
    disabled: {
      true: {
        color: "$primaryLight",
      },
    },
  },

  defaultVariants: {
    disabled: false,
  },
});

const RADIO_VALUES_TYPES = {
  SELECT_NONE: 1,
  SELECT_ALL: 2,
  SELECT_ITEMS: 3,
};

type RadioGroupSelectProps = {
  selectedAll: boolean;
  selectedNone: boolean;
  selectAllTitle: string;
  selectItemsTitle: string;
  selectNoneTitle: string;
  onSelectAll: () => void;
  onStartSelectItems: () => void;
  onSelectNone: () => void;
  disabled?: boolean;
};

const RadioGroupSelect = (props: React.PropsWithChildren<RadioGroupSelectProps>) => {
  const {
    selectedNone,
    selectedAll,
    selectAllTitle,
    selectItemsTitle,
    selectNoneTitle,
    disabled = false,
    onSelectAll,
    onStartSelectItems,
    onSelectNone,
  } = props;

  const radioInputValue = selectedNone
    ? RADIO_VALUES_TYPES.SELECT_NONE
    : selectedAll
    ? RADIO_VALUES_TYPES.SELECT_ALL
    : RADIO_VALUES_TYPES.SELECT_ITEMS;

  const handleChange = (value: number) => {
    if (!disabled) {
      if (value === RADIO_VALUES_TYPES.SELECT_NONE) {
        onSelectNone?.();
      } else if (value === RADIO_VALUES_TYPES.SELECT_ALL) {
        onSelectAll?.();
      } else if (value === RADIO_VALUES_TYPES.SELECT_ITEMS) {
        onStartSelectItems?.();
      }
    }
  };

  return (
    <Box css={{ width: "$full" }}>
      <RadioGroup value={radioInputValue} onValueChange={handleChange}>
        <Items>
          <RadioGroupItem value={RADIO_VALUES_TYPES.SELECT_NONE} disabled={disabled}>
            <Text disabled={!selectedNone}>{selectNoneTitle}</Text>
          </RadioGroupItem>
        </Items>
        <Items>
          <RadioGroupItem value={RADIO_VALUES_TYPES.SELECT_ALL} disabled={disabled}>
            <Text disabled={!selectedAll}>{selectAllTitle}</Text>
          </RadioGroupItem>
        </Items>
        <Items>
          <RadioGroupItem value={RADIO_VALUES_TYPES.SELECT_ITEMS} disabled={disabled}>
            <Text disabled={selectedNone || selectedAll}>{selectItemsTitle}</Text>
          </RadioGroupItem>
          {props.children}
        </Items>
      </RadioGroup>
    </Box>
  );
};
RadioGroupSelect.displayName = "RadioGroupSelect";

export default RadioGroupSelect;
