import Inline from "../../../../../components/lib/Inline";
import { styled } from "../../../../../stitches.config";

export const RatesButton = styled(Inline, {
  textTransform: "uppercase",
  padding: "$2 $5",
  width: "$full",
  cursor: "pointer",
  borderBottom: "1px solid $primaryLighter",

  variants: {
    disabled: {
      true: {
        backgroundColor: "$primaryLighter",
        cursor: "not-allowed",
      },
    },
  },

  defaultVariants: {
    disabled: false,
  },
});
