// @flow

import React, { Component } from "react";
import { observer } from "mobx-react";
import LibraryTitleRequestTable from "./LibraryTitleRequestTable";
import LibraryTitleRequestTableRow from "./LibraryTitleRequestTableRow";
import EmptyRow from "./EmptyRow";
import Pagination from "./Pagination";
import LibrarySearch from "./LibrarySearch";
import JobLibraryTitleRequestListStore from "../../stores/mobx/JobLibraryTitleRequestListStore";

function times(n, f) {
  const result = [];
  while (n-- > 0) {
    result.push(f(n));
  }

  return result;
}

type Props = {
  store: JobLibraryTitleRequestListStore,
  itemActions?: any,
};

class ClientJobTitleRequestList extends Component<Props> {
  render() {
    const store = this.props.store;
    const clientJobTitleRequestsView = store.clientJobTitleRequestsView;

    const setClearSearch = store.appliedFilters.hasOwnProperty(
      store.clientJobTitleRequestTitleFilter.column
    );

    return (
      <div>
        <div className="cjl-flex-table">
          <div className="cjl-flex-table row double-vertical-space">
            <div className="column auto no-padding-left">
              <LibrarySearch
                style={{ width: 300 }}
                className="cjl-title-list-search"
                placeholder="Search Requests..."
                value={store.clientJobTitleRequestTitleFilter.textToLookFor}
                onChange={store.clientJobTitleRequestTitleFilter.onTextToLookForChange}
                onSearch={store.clientJobTitleRequestTitleFilter.onApply}
                onClear={
                  setClearSearch ? store.clientJobTitleRequestTitleFilter.onReset : null
                }
              />
            </div>
            {/*<div className="column">*/}
            {/*<span className="caption">*/}
            {/*{store.isFiltered && <span>{store.pagination.totalCount} Request Matches</span>}*/}
            {/*</span>*/}
            {/*</div>*/}
            {/*<div className="column auto">*/}
            {/*<span className="caption">*/}
            {/*{store.isEditing && (*/}
            {/*<span>*/}
            {/*<span style={{ fontWeight: "normal" }}>{store.selectedCount} Selected</span>{" "}*/}
            {/*<span>/ {store.totalCount} Title Requests</span>*/}
            {/*</span>*/}
            {/*)}*/}
            {/*{!store.isEditing && <span>{store.totalCount} Title Requests</span>}*/}
            {/*</span>*/}
            {/*</div>*/}
          </div>
        </div>
        {store.isFiltered && store.resultsForText && (
          <div className="cjl-search-query">
            <span className="cjl-label">RESULTS FOR:</span>{" "}
            <span>&ldquo;{store.resultsForText}&rdquo;</span>
          </div>
        )}
        <LibraryTitleRequestTable store={store} actions={Boolean(this.props.itemActions)}>
          {/* Show Items */}
          {!store.network.loading &&
            clientJobTitleRequestsView &&
            clientJobTitleRequestsView.length > 0 &&
            clientJobTitleRequestsView.map((item) => {
              const actions = this.props.itemActions
                ? this.props.itemActions.map((itemAction) => itemAction(item))
                : null;
              return (
                <LibraryTitleRequestTableRow
                  key={item.id}
                  libraryTitleRequest={item}
                  actions={actions}
                  expandable={true}
                />
              );
            })}
          {/* If no items to show */}
          {!store.network.loading &&
            (!clientJobTitleRequestsView || clientJobTitleRequestsView.length === 0) && (
              <EmptyRow text="No Requests" />
            )}
          {/* If its loading */}
          {store.network.loading &&
            // generate placeholder items
            times(store.clientJobTitleRequests.length || 5, (i) => (
              <LibraryTitleRequestTableRow key={i} />
            ))}
          <Pagination
            hasLoaded={store.pagination.hasLoaded}
            pageCount={store.pagination.pageCount}
            pagesToShow={store.pagination.maxPagesToShow}
            currentPage={store.pagination.currentPage}
            begin={store.pagination.begin}
            end={store.pagination.end}
            isEndRange={store.pagination.isEndRange}
            handleNextPage={store.pagination.handleNextPage}
            handlePrevPage={store.pagination.handlePrevPage}
            handleFirstPage={store.pagination.handleFirstPage}
            handleLastPage={store.pagination.handleLastPage}
          />
        </LibraryTitleRequestTable>
      </div>
    );
  }
}

export default observer(ClientJobTitleRequestList);
