import React from "react";

import { ActionButton } from "./ActionButton";
import { RATE_FEEDBACK_STATUS_TYPES } from "../../../types";
import { useVal5KPublicContext } from "../../../context/Val5KPublicContext";
import { CommentForm } from "../FeedbackForm";

import type { RATE_FEEDBACK_STATUS_TYPES_TYPE } from "../../../types";

type Props = {
  feedbackStatus: RATE_FEEDBACK_STATUS_TYPES_TYPE;
  feedbackComment: string | null;
  onApply: (changes: {
    status: RATE_FEEDBACK_STATUS_TYPES_TYPE;
    comment: string | null;
  }) => Promise<void>;
};

export default function AgreeButton(props: Props) {
  const { feedbackStatus, feedbackComment, onApply } = props;
  const { showConfirmationModal, closeConfirmationModal } = useVal5KPublicContext();

  // handlers

  const handleShowAgreeConfirmationModal = () => {
    const title = "Extra comment";
    const body = (
      <CommentForm
        comment={feedbackComment ?? ""}
        onApply={(comment) =>
          onApply({ status: RATE_FEEDBACK_STATUS_TYPES.APPROVED, comment })
        }
        onCancel={closeConfirmationModal}
      />
    );

    showConfirmationModal(body, title);
  };

  // render

  return (
    <ActionButton
      color={feedbackStatus === RATE_FEEDBACK_STATUS_TYPES.APPROVED ? "success" : "gray"}
      variant={
        feedbackStatus === RATE_FEEDBACK_STATUS_TYPES.APPROVED ? "filled" : "light"
      }
      onClick={handleShowAgreeConfirmationModal}
    >
      Agree
    </ActionButton>
  );
}

AgreeButton.displayName = "AgreeButton";
