import React from "react";

import { CommentForm } from "../FeedbackForm";
import { ActionButton } from "./ActionButton";
import { useVal5KPublicContext } from "../../../context/Val5KPublicContext";
import { RATE_FEEDBACK_STATUS_TYPES } from "../../../types";

import type { RATE_FEEDBACK_STATUS_TYPES_TYPE } from "../../../types";

type Props = {
  feedbackStatus: RATE_FEEDBACK_STATUS_TYPES_TYPE;
  feedbackComment: string | null;
  onApply: (changes: { comment: string | null }) => Promise<void>;
};

export default function CommentButton(props: Props) {
  const { feedbackStatus, feedbackComment, onApply } = props;
  const { showConfirmationModal, closeConfirmationModal } = useVal5KPublicContext();

  const showCommentConfirmationModal = () => {
    const title = "Edit Comment";
    const body = (
      <CommentForm
        comment={feedbackComment ?? ""}
        onApply={(comment) => onApply({ comment })}
        onCancel={closeConfirmationModal}
      />
    );

    showConfirmationModal(body, title);
  };

  return (
    <ActionButton
      color={!!feedbackComment ? "accent" : "gray"}
      variant={!!feedbackComment ? "filled" : "light"}
      onClick={showCommentConfirmationModal}
      disabled={
        feedbackStatus === RATE_FEEDBACK_STATUS_TYPES.NOT_VALIDATED ||
        feedbackStatus === RATE_FEEDBACK_STATUS_TYPES.DONT_KNOW
      }
    >
      Comment
    </ActionButton>
  );
}

CommentButton.displayName = "CommentButton";
