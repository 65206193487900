// @flow
import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LEVEL_SELECTOR } from "../constants/css";
import numeral from "numeral";
import { RATE_TYPES } from "../constants/rateTypes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { MinWageDisclaimer } from "../models/Search";
import ToggleButton from "../views/ratecards/components/ToggleButton";

export type ChangeKind = "positive-change" | "negative-change" | "no-change";

type ChangeIconProps = {
  change: ChangeKind,
};

export function ChangeIcon(props: ChangeIconProps): React.Node {
  const { change = "no-change" } = props;
  let icon = "minus";
  if (change === "positive-change") {
    icon = "sort-up";
  } else if (change === "negative-change") {
    icon = "sort-down";
  }

  return <FontAwesomeIcon icon={icon} className={["ticker", change].join(" ")} />;
}

export interface ChangeData {
  payRateMinChangeType: ChangeKind;
  payRateMinChange: number;
  payRateAvgChangeType: ChangeKind;
  payRateAvgChange: number;
  payRateMidChangeType: ChangeKind;
  payRateMidChange: number;
  payRateMaxChangeType: ChangeKind;
  payRateMaxChange: number;
  billRateMinChangeType: ChangeKind;
  billRateMinChange: number;
  billRateAvgChangeType: ChangeKind;
  billRateAvgChange: number;
  billRateMidChangeType: ChangeKind;
  billRateMidChange: number;
  billRateMaxChangeType: ChangeKind;
  billRateMaxChange: number;
  markupPctMinChangeType: ChangeKind;
  markupPctMinChange: number;
  markupPctAvgChangeType: ChangeKind;
  markupPctAvgChange: number;
  markupPctMidChangeType: ChangeKind;
  markupPctMidChange: number;
  markupPctMaxChangeType: ChangeKind;
  markupPctMaxChange: number;
}

export interface RowData {
  id: number;
  levelName: "Junior" | "Mid" | "Senior" | "Lead" | "Guru" | string;
  levelRomanNumeral: string;
  payRateMin: number;
  payRateAvg: number;
  payRateMid: number;
  payRateMax: number;
  billRateMin: number;
  billRateAvg: number;
  billRateMid: number;
  billRateMax: number;
  markupPctMin: number;
  markupPctAvg: number;
  markupPctMid: number;
  markupPctMax: number;
  change: ChangeData;
}

type SearchPeekTablePayRateRowProps = {|
  currencySymbol: string,
  data: RowData,
  rateMultiplier?: number,
  threeLevelsView?: boolean,
  showDeltas?: boolean,
|};

export function SearchPeekTablePayRateRow(
  props: SearchPeekTablePayRateRowProps
): React.Node {
  const {
    currencySymbol,
    data,
    rateMultiplier = 1,
    threeLevelsView = false,
    showDeltas = false,
  } = props;

  const {
    levelName,
    levelRomanNumeral,
    payRateMin,
    payRateAvg,
    payRateMax,
    payRateMid,
    change,
  } = data;
  const levelSelector = LEVEL_SELECTOR[levelName];

  if (showDeltas) {
    return (
      <tr key={levelName}>
        <td className={`no-wrap table-header-background ${levelSelector}`}>
          Level {levelRomanNumeral}
        </td>
        {/* Pay Rate */}
        <td className={`no-wrap align-right ${change.payRateMinChangeType}`}>
          <ChangeIcon change={change.payRateMinChangeType} /> {currencySymbol}{" "}
          {numeral(change.payRateMinChange * rateMultiplier).format("+0,0.00")}
        </td>
        <td className={`no-wrap align-right ${change.payRateAvgChangeType}`}>
          <ChangeIcon change={change.payRateAvgChangeType} /> {currencySymbol}{" "}
          {numeral(change.payRateAvgChange * rateMultiplier).format("+0,0.00")}
        </td>
        {!threeLevelsView && (
          <td className={`no-wrap align-right ${change.payRateMidChangeType}`}>
            <ChangeIcon change={change.payRateMidChangeType} /> {currencySymbol}{" "}
            {numeral(change.payRateMidChange * rateMultiplier).format("+0,0.00")}
          </td>
        )}
        <td className={`no-wrap align-right ${change.payRateMaxChangeType}`}>
          <ChangeIcon change={change.payRateMaxChangeType} /> {currencySymbol}{" "}
          {numeral(change.payRateMaxChange * rateMultiplier).format("+0,0.00")}
        </td>
      </tr>
    );
  }

  return (
    <tr key={levelName}>
      <td className={`no-wrap table-header-background ${levelSelector}`}>
        Level {levelRomanNumeral}
      </td>
      {/* Pay Rate */}
      <td className="no-wrap align-right">
        <ChangeIcon change={change.payRateMinChangeType} /> {currencySymbol}{" "}
        {numeral(payRateMin * rateMultiplier).format("0,0.00")}
      </td>
      <td className="no-wrap align-right">
        <ChangeIcon change={change.payRateAvgChangeType} /> {currencySymbol}{" "}
        {numeral(payRateAvg * rateMultiplier).format("0,0.00")}
      </td>
      {!threeLevelsView && (
        <td className="no-wrap align-right">
          <ChangeIcon change={change.payRateMidChangeType} /> {currencySymbol}{" "}
          {numeral(payRateMid * rateMultiplier).format("0,0.00")}
        </td>
      )}
      <td className="no-wrap align-right">
        <ChangeIcon change={change.payRateMaxChangeType} /> {currencySymbol}{" "}
        {numeral(payRateMax * rateMultiplier).format("0,0.00")}
      </td>
    </tr>
  );
}

type SearchPeekTableBillRateRowProps = {|
  currencySymbol: string,
  data: RowData,
  rateMultiplier?: number,
  threeLevelsView?: boolean,
  showDeltas?: boolean,
|};

export function SearchPeekTableBillRateRow(
  props: SearchPeekTableBillRateRowProps
): React.Node {
  const { currencySymbol, data, rateMultiplier = 1, showDeltas = false } = props;
  const { levelName, billRateMin, billRateAvg, billRateMax, billRateMid, change } = data;

  if (showDeltas) {
    return (
      <tr key={levelName}>
        {/* Bill Rate */}
        <td
          className={`no-wrap table-border-left align-right ${change.billRateMinChangeType}`}
        >
          <ChangeIcon change={change.billRateMinChangeType} /> {currencySymbol}{" "}
          {numeral(change.billRateMinChange * rateMultiplier).format("+0,0.00")}
        </td>
        <td className={`no-wrap align-right ${change.billRateAvgChangeType}`}>
          <ChangeIcon change={change.billRateAvgChangeType} /> {currencySymbol}{" "}
          {numeral(change.billRateAvgChange * rateMultiplier).format("+0,0.00")}
        </td>
        <td className={`no-wrap align-right ${change.billRateMidChangeType}`}>
          <ChangeIcon change={change.billRateMidChangeType} /> {currencySymbol}{" "}
          {numeral(change.billRateMidChange * rateMultiplier).format("+0,0.00")}
        </td>
        <td className={`no-wrap align-right ${change.billRateMaxChangeType}`}>
          <ChangeIcon change={change.billRateMaxChangeType} /> {currencySymbol}{" "}
          {numeral(change.billRateMaxChange * rateMultiplier).format("+0,0.00")}
        </td>
      </tr>
    );
  }

  return (
    <tr key={levelName}>
      {/* Bill Rate */}
      <td className="no-wrap table-border-left align-right">
        <ChangeIcon change={change.billRateMinChangeType} /> {currencySymbol}{" "}
        {numeral(billRateMin * rateMultiplier).format("0,0.00")}
      </td>
      <td className="no-wrap align-right">
        <ChangeIcon change={change.billRateAvgChangeType} /> {currencySymbol}{" "}
        {numeral(billRateAvg * rateMultiplier).format("0,0.00")}
      </td>
      <td className="no-wrap align-right">
        <ChangeIcon change={change.billRateMidChangeType} /> {currencySymbol}{" "}
        {numeral(billRateMid * rateMultiplier).format("0,0.00")}
      </td>
      <td className="no-wrap align-right">
        <ChangeIcon change={change.billRateMaxChangeType} /> {currencySymbol}{" "}
        {numeral(billRateMax * rateMultiplier).format("0,0.00")}
      </td>
    </tr>
  );
}

type SearchPeekTableMarkupRowProps = {|
  data: RowData,
  threeLevelsView?: boolean,
  showDeltas?: boolean,
|};

export function SearchPeekTableMarkupRow(
  props: SearchPeekTableMarkupRowProps
): React.Node {
  const { data, showDeltas = false } = props;
  const { levelName, markupPctMin, markupPctAvg, markupPctMax, markupPctMid, change } =
    data;

  if (showDeltas) {
    return (
      <tr key={levelName}>
        {/* Markup */}
        <td
          className={`no-wrap table-border-left align-right ${change.markupPctMinChangeType}`}
        >
          <ChangeIcon change={change.markupPctMinChangeType} />{" "}
          {numeral(change.markupPctMinChange).format("+0,0.00")} %
        </td>
        <td className={`no-wrap align-right ${change.markupPctAvgChangeType}`}>
          <ChangeIcon change={change.markupPctAvgChangeType} />{" "}
          {numeral(change.markupPctAvgChange).format("+0,0.00")} %
        </td>
        <td className={`no-wrap align-right ${change.markupPctMidChangeType}`}>
          <ChangeIcon change={change.markupPctMidChangeType} />{" "}
          {numeral(change.markupPctMidChange).format("+0,0.00")} %
        </td>
        <td className={`no-wrap align-right ${change.markupPctMaxChangeType}`}>
          <ChangeIcon change={change.markupPctMaxChangeType} />{" "}
          {numeral(change.markupPctMaxChange).format("+0,0.00")} %
        </td>
      </tr>
    );
  }

  return (
    <tr key={levelName}>
      {/* Markup */}
      <td className="no-wrap table-border-left align-right">
        <ChangeIcon change={change.markupPctMinChangeType} />{" "}
        {numeral(markupPctMin).format("0,0.00")} %
      </td>
      <td className="no-wrap align-right">
        <ChangeIcon change={change.markupPctAvgChangeType} />{" "}
        {numeral(markupPctAvg).format("0,0.00")} %
      </td>
      <td className="no-wrap align-right">
        <ChangeIcon change={change.markupPctMidChangeType} />{" "}
        {numeral(markupPctMid).format("0,0.00")} %
      </td>
      <td className="no-wrap align-right">
        <ChangeIcon change={change.markupPctMaxChangeType} />{" "}
        {numeral(markupPctMax).format("0,0.00")} %
      </td>
    </tr>
  );
}

export const dataOptions = {
  rates: 1,
  change: 2,
};

export type DataOption = $Keys<typeof dataOptions>;

export type SelectGroupItem = {
  label: string,
  value: string | number,
};

type DataDisplaySelectProps<T> = {
  values: Array<T>,
  labels: Array<string>,
  selected: T,
  onChange: (T) => void,
  name?: string,
};

export function SingleSelectGroup<T>(props: DataDisplaySelectProps<T>): React.Node {
  const { values, labels, selected, onChange, name } = props;

  const handleOptionOnClick = (value: T) => {
    onChange(value);
  };

  return (
    <div className="btn-toggle-group pull-left">
      {values.map((value: T, index: number) => {
        return (
          <ToggleButton
            key={index}
            type="radio"
            name={name}
            item={value}
            value={value}
            selected={value === selected}
            onClick={handleOptionOnClick}
          >
            {labels[index] || `Option ${index}`}
          </ToggleButton>
        );
      })}
      {/*<ToggleButton*/}
      {/*  name="show-options"*/}
      {/*  type="radio"*/}
      {/*  value={dataOptions.rates}*/}
      {/*  selected={dataOptions[value] === dataOptions.rates}*/}
      {/*  onClick={search.handleDataDisplayOptionChange}*/}
      {/*>*/}
      {/*  Rates*/}
      {/*</ToggleButton>*/}
      {/*<ToggleButton*/}
      {/*  name="show-options"*/}
      {/*  type="radio"*/}
      {/*  value={search.SHOW_DELTAS_OPTION}*/}
      {/*  selected={search.showDeltas}*/}
      {/*  onClick={search.handleDataDisplayOptionChange}*/}
      {/*>*/}
      {/*  Change*/}
      {/*</ToggleButton>*/}
    </div>
  );
}

type SearchPeekTableProps = {
  data: Array<RowData>,
  rateMultiplier: number,
  rateType: "hourly" | "annual" | string,
  currencySymbol: string,
  threeLevelsViewUser?: boolean,
  showThreeLevelsView?: boolean,
  country?: string,
  dataToShow?: DataOption,
};

function SearchPeekTable(props: SearchPeekTableProps): React.Node {
  const {
    data,
    rateType,
    currencySymbol,
    rateMultiplier,
    threeLevelsViewUser = false,
    showThreeLevelsView = false,
    country = "",
    dataToShow = "rates",
  } = props;

  // const store = props.store;
  // const rateMultiplier = props.rateMultiplier;
  // const search = props.search;
  // const threeLevelsViewUser = store.threeLevelsViewUser;

  const showMarkupAndBillRate = rateType === RATE_TYPES.HOURLY && !showThreeLevelsView;

  const showingThreeLevels = threeLevelsViewUser && showThreeLevelsView;
  const showChange = dataOptions[dataToShow] === dataOptions.change;

  return (
    <>
      <div className="search-peek-table-container">
        <div className="flex-grid">
          <div className="flex-row no-gutters">
            <div className="flex-grid flex-auto" style={{ margin: "0 auto" }}>
              <div className="flex-row no-gutters no-wrap flex-auto">
                <div className="flex-col flex-auto">
                  <table className="table table-striped pay-rate-table no-margin">
                    <thead className="">
                      <tr>
                        <th className="table-header-no-background no-border" />
                        <th
                          className="table-header-no-background peek-table-header"
                          colSpan="4"
                        >
                          PAY RATE
                        </th>
                      </tr>
                      <tr>
                        <th className="table-no-border-top" />
                        {/* Pay Rate */}
                        <th className="">
                          Min <MinWageDisclaimer country={country} />
                        </th>
                        <th className="">Avg</th>
                        {!showingThreeLevels && <th className="">Mid</th>}
                        <th className="">Max</th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {data.map((row) => {
                        if (!row) return null;

                        return (
                          <SearchPeekTablePayRateRow
                            key={row.id}
                            data={row}
                            currencySymbol={currencySymbol}
                            threeLevelsView={showingThreeLevels}
                            rateMultiplier={rateMultiplier}
                            showDeltas={showChange}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {showMarkupAndBillRate && (
                  <div className="flex-col flex-auto">
                    <table className="table table-striped markup-table no-margin">
                      <thead className="">
                        <tr>
                          <th
                            className="table-header-no-background peek-table-header"
                            colSpan="4"
                          >
                            SUGGESTED MARKUP{" "}
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="markupTooltip">
                                  Markups for this labor category will vary depending on
                                  many factors including location, duration, volume of
                                  business, type of labor, rebates, etc. Try the
                                  Negotiation Worksheets to come up with markups for your
                                  unique needs.
                                </Tooltip>
                              }
                            >
                              <FontAwesomeIcon
                                icon="info-circle"
                                style={{ marginLeft: 5 }}
                              />
                            </OverlayTrigger>
                          </th>
                        </tr>
                        <tr>
                          {/* Markup */}
                          <th className="">Min</th>
                          <th className="">Avg</th>
                          <th className="">Mid</th>
                          <th className="">Max</th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {data.map((row) => {
                          if (!row) return null;

                          return (
                            <SearchPeekTableMarkupRow
                              key={row.id}
                              data={row}
                              threeLevelsView={showingThreeLevels}
                              showDeltas={showChange}
                            />
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
                {showMarkupAndBillRate && (
                  <div className="flex-col flex-auto">
                    <table className="table table-striped bill-rate-table no-margin">
                      <thead className="">
                        <tr>
                          <th
                            className="table-header-no-background peek-table-header"
                            colSpan="4"
                          >
                            BILL RATE{" "}
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="billRateTooltip">
                                  Bill Rates include all costs and VAT/GST tax.
                                </Tooltip>
                              }
                            >
                              <FontAwesomeIcon
                                icon="info-circle"
                                style={{ marginLeft: 5 }}
                              />
                            </OverlayTrigger>
                          </th>
                        </tr>
                        <tr>
                          {/* Bill Rate */}
                          <th className="">Min</th>
                          <th className="">Avg</th>
                          <th className="">Mid</th>
                          <th className="">Max</th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {data.map((row) => {
                          if (!row) return null;

                          return (
                            <SearchPeekTableBillRateRow
                              key={row.id}
                              data={row}
                              currencySymbol={currencySymbol}
                              threeLevelsView={showingThreeLevels}
                              rateMultiplier={rateMultiplier}
                              showDeltas={showChange}
                            />
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchPeekTable;
