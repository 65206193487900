// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
// import { Link } from "react-router";
import { observer } from "mobx-react";
import CheckBox from "../../../shared/CheckBox";
import classNames from "classnames";
import { TagItemWithoutDelete } from "../../../ratecards/components/TagItem";

const RateCardListItem = observer(
  class extends Component {
    static contextTypes = {
      router: PropTypes.object,
    };

    constructor(props) {
      super(props);

      this.handleViewDetail = this.handleViewDetail.bind(this);
      this.deleteTag = this.deleteTag.bind(this);
      this.state = {
        updated: false,
      };
    }

    handleViewDetail() {
      this.context.router.push({
        pathname: `/admin/ratecards/${this.props.rateCard.ratecardId}`,
        query: this.context.router.query,
      });
    }

    deleteTag(event: object, tag_id) {
      event.preventDefault();
      event.stopPropagation();
      event.cancelBubble = true;
      event.returnValue = false;
    }

    render() {
      const props = this.props;

      const rateCard = props.rateCard;

      const editing = rateCard.viewState.editing;
      const selected = rateCard.viewState.selected;
      const name = rateCard.name;
      const createdDisplay = rateCard.createdDate.format("MMMM D, YYYY");
      // const searches = rateCard.searches;
      // const locations = rateCard.locations;
      const id = rateCard.ratecardId;
      const searchCount = rateCard.searchCount;
      const locationCount = rateCard.locationCount;
      let ownerName = "You";
      const tags = rateCard.tags;
      let tagSection = null;

      if (String(rateCard.owner.clientId) !== String(props.store.currentUser.userId)) {
        ownerName = rateCard.owner.fullname;
      }
      if (rateCard.owner.email !== localStorage.getItem("PeopleTickerUserEmail")) {
        ownerName = rateCard.owner.fullname;
      }

      let onItemClick = this.props.onClick || this.handleViewDetail;
      let editingSection = null;

      if (this.props.editing) {
        onItemClick = rateCard.toggleSelected;
        editingSection = (
          <CheckBox
            type={props.store.allowMultipleItemSelection ? "checkbox" : "radio"}
            name="list-item-select"
            value={name}
            selected={selected}
            style={{ width: 34 }}
            onClick={onItemClick}
          />
        );
      }
      if (tags.length > 0 && !this.props.store.isTagView) {
        tagSection = tags.map((tag) => (
          <TagItemWithoutDelete key={tag.tagId} tag={tag} onClick={this.deleteTag} />
        ));
      }

      // NOTE: Re-enable onClick={onItemClick} when rc list item views are fixed
      return (
        <div className="ratecard-list-item-container">
          <div
            className={classNames("ratecard-list-item", { selected: selected })}
            onClick={onItemClick}
          >
            <div className="ratecard-list-item-left ellipsis">
              {editingSection}
              <div className="ratecard-label-container ellipsis">
                <div
                  className="ratecard-label ellipsis"
                  data-testid="ratecard-search-list"
                >
                  <span>
                    <span className="ellipsis max-80">{name}</span>
                    {/*shared &&
                   sharedByYou &&
                   <OverlayTrigger placement="top" overlay={sharedTooltip}>
                     <span className="tag text-x-small align-top">
                       <FontAwesomeIcon icon="share" fixedWidth className="icon" /> By You
                     </span>
                   </OverlayTrigger>}
                 {shared &&
                   !sharedByYou &&
                   <OverlayTrigger placement="top" overlay={sharedTooltip}>
                     <span className="tag text-x-small align-top">
                       <FontAwesomeIcon icon="share" fixedWidth flip="horizontal" className="icon" /> To You
                     </span>
                   </OverlayTrigger>*/}
                  </span>
                </div>
                <div className="ratecard-meta ellipsis">
                  <span className="item-subtext-muted">Created by </span>
                  <span className="item-subtext-accent">{ownerName} </span>
                  <span className="item-subtext-muted">on </span>
                  <span className="item-subtext-accent">{createdDisplay}</span>
                </div>
              </div>
            </div>
            <div className="ratecard-list-item-right">
              {/*<div className="ratecard-info text-center">*/}
              {/*<header>Shared</header>*/}
              {/*<div>{ shared ? "Yes" : "No"}</div>*/}
              {/*</div>*/}
              <div className="rc-center">
                <div className="ratecard-info ">
                  <header data-testid="ID-header-rate-card">ID</header>
                  <div className="text-center">{id}</div>
                </div>
                <div className="ratecard-info ">
                  <header data-testid="searches-header-rate-card">Searches</header>
                  <div className="text-center">{searchCount}</div>
                </div>
                <div className="ratecard-info">
                  <header data-testid="locations-header-rate-card">Locations</header>
                  <div className="text-center">{locationCount}</div>
                </div>
                {/*{!editing && (*/}
                {/*  <Link to={`/admin/ratecards/${id}`} className="btn btn-default">*/}
                {/*    View*/}
                {/*  </Link>*/}
                {/*)}*/}
              </div>
            </div>
            <div className={classNames("tagbox", { "p-left-34": editing })}>
              {tagSection}
            </div>
          </div>
        </div>
      );
    }
  }
);

RateCardListItem.propTypes = {
  store: PropTypes.object.isRequired,
  rateCard: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default RateCardListItem;
