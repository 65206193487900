import React, { useCallback } from "react";
import { fromJS } from "immutable";

import { usePLIContext } from "../context";
import Text from "../../../components/lib/Text";
import Stack from "../../../components/lib/Stack";
import Box from "../../../components/lib/Box";
import Inline from "../../../components/lib/Inline";
import Spacer from "../../../components/lib/Spacer";
import { Button } from "../../../components/lib/Button";
import { ButtonGroupRight } from "../../../components/lib/ButtonGroup";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";
import { StepID } from "./SubmitContractorsWizardPage";
import { emptyList } from "../constants";
import { CREATE_REVIEW_WIZARD_PAGES } from "../../../views/validator5K_admin/types";
import UploadedJobsTableView, {
  ChangeFunction,
  RemoveFunction,
} from "../../validator5K_admin/components/UploadedJobsTable";

import type { CREATE_REVIEW_WIZARD_PAGES_TYPE } from "../../../views/validator5K_admin/types";
import type { JobsValidationsOrderedMap, JobValidationDataMap } from "../types";

type ReviewContractorsWizardPageProps = {
  jobsData: JobsValidationsOrderedMap;
  setJobsData: (nextJobsData: JobsValidationsOrderedMap) => void;
  hasWarnings: boolean;
  hasAnyProblems: boolean;
  onChangeWizardPage: (wizardPage: CREATE_REVIEW_WIZARD_PAGES_TYPE) => void;
  onGoBack: () => void;
};

const ReviewContractorsWizardPage = (
  props: ReviewContractorsWizardPageProps
): JSX.Element => {
  const {
    jobsData,
    hasWarnings,
    hasAnyProblems,
    setJobsData,
    onChangeWizardPage,
    onGoBack,
  } = props;
  const {
    fetchArgusAPI,
    showModalWarning,
    showModalError,
    showConfirmationModal,
    closeConfirmationModal,
  } = usePLIContext();

  // handlers

  const handleSkipAllWarnings = useCallback(() => {
    setJobsData(jobsData.map((data) => data.set("warnings", emptyList)));
    closeConfirmationModal();
  }, [jobsData, setJobsData, closeConfirmationModal]);

  const handleConfirmSkipAllWarnings = useCallback(() => {
    const header = "Skip all warnings.";
    const body = (
      <p>
        You are about to skip all warnings for the whole data. Please, confirm your action
        or cancel it.
      </p>
    );
    const footer = (
      <ButtonGroupRight fill css={{ flexDirection: "row-reverse" }}>
        <Button size="large" onClick={closeConfirmationModal}>
          Cancel
        </Button>
        <Button size="large" color="accent" onClick={handleSkipAllWarnings}>
          Confirm
        </Button>
      </ButtonGroupRight>
    );

    showConfirmationModal(body, header, footer);
  }, [handleSkipAllWarnings, closeConfirmationModal, showConfirmationModal]);

  const handleDatasetApprove = useCallback(() => {
    if (hasAnyProblems) {
      showModalWarning(
        "There are still unresolved rows in the uploaded data. " +
          "Fix them first in order to be able to continue to the next step."
      );
    } else {
      onChangeWizardPage(CREATE_REVIEW_WIZARD_PAGES.SUBMIT);
    }
  }, [hasAnyProblems, onChangeWizardPage, showModalWarning]);

  const handleJobDataChange: ChangeFunction = useCallback(
    async (rowId, data, needValidation = true) => {
      if (!data || !data.size) return Promise.resolve();

      if (needValidation) {
        try {
          const response = await fetchArgusAPI("reviews/validate_job_data/", {
            method: "post",
            data: data.toJS(),
          });

          setJobsData(jobsData.set(rowId, fromJS(response.data) as JobValidationDataMap));
        } catch (err: any) {
          logAsyncOperationError("validateJobData", err);
          showModalError("Error occurred while job data validation.");
        }
      } else {
        setJobsData(jobsData.set(rowId, fromJS(data)));
      }

      return data;
    },
    [jobsData, setJobsData, fetchArgusAPI, showModalError]
  );

  const handleJobRemove: RemoveFunction = useCallback(
    (rowId) => setJobsData(jobsData.delete(rowId)),
    [jobsData, setJobsData]
  );

  return (
    <Stack fill css={{ alignItems: "stretch", gap: "$6" }}>
      <Inline fill nowrap css={{ alignItems: "flex-start" }}>
        <StepID>2</StepID>
        <Box fill nowrap css={{ overflow: "scroll" }}>
          <Text thin>
            Review imported data and approve it (you can fix data right here) *:
          </Text>
          <Spacer css={{ paddingTop: "$3" }} />
          {jobsData != null && jobsData.size > 0 && (
            <UploadedJobsTableView
              data={jobsData.toList()}
              onChange={handleJobDataChange}
              onRemove={handleJobRemove}
            />
          )}
        </Box>
      </Inline>

      <ButtonGroupRight>
        <Button icon="arrow-left" color="brand" size="normal" onClick={onGoBack}>
          Back to Private Index
        </Button>
        {hasWarnings && (
          <Button
            icon="sync"
            color="accent"
            size="normal"
            onClick={handleConfirmSkipAllWarnings}
          >
            Skip All Warnings
          </Button>
        )}
        <Button
          icon="check"
          color="success"
          size="normal"
          disabled={jobsData == null || jobsData.size === 0}
          onClick={
            jobsData != null && jobsData.size > 0 ? handleDatasetApprove : undefined
          }
        >
          Approve Data
        </Button>
      </ButtonGroupRight>
    </Stack>
  );
};

ReviewContractorsWizardPage.displayName = "ReviewContractorsWizardPage";

export default ReviewContractorsWizardPage;
