import React, { useEffect } from "react";
import { Link } from "react-router";
import moment from "moment";
// LIB
import Inline from "../../components/lib/Inline";
import { Card } from "../../components/lib/Card";
import Stack from "../../components/lib/Stack";
import Text from "../../components/lib/Text";
import { styled } from "../../stitches.config";
import { Placeholder } from "../../components/lib/Placeholder";
// Relay
import graphql from "babel-plugin-relay/macro";
import { RecentSearches_viewer$key } from "./__generated__/RecentSearches_viewer.graphql";
import { RecentSearchesRefetch } from "./__generated__/RecentSearchesRefetch.graphql";
import { useRefetchableFragment } from "react-relay";
import { isNonNullable } from "../../utils/hashmap";
import { recentSearchesObserver } from "./observers";

const Wrapper = styled(Card, {
  padding: "$6",
  "@container RsSideMenu (max-width: 500px)": {
    padding: "$3",
    marginBottom: "0px",
    border: "none",
    boxShadow: "unset",
  },
});

const SearchGrid = styled("div", {
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gridTemplateRows: "min-content",
  gridGap: "$4",
  "@media (max-width: 1300px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  "@media (max-width: 900px)": {
    gridTemplateColumns: "1fr",
  },
  "@container RsSideMenu (max-width: 500px)": {
    gridTemplateColumns: "1fr",
    gridGap: "$3",
  },
});

const SearchItem = styled(Link as any, {
  backgroundColor: "$primaryLightest",
  padding: "$2 $4",
  border: "solid 1px $primaryLight",
  transition: "all 300ms ease",
  borderRadius: "$rounded",
  textDecoration: "node !important",
  display: "flex",
  flex: 1,
  flexDirection: "column",
  alignItems: "start",
  gap: "$2",
  color: "$secondaryLight !important",
  "&:focus": {
    textDecoration: "none !important",
  },
  "& span": {
    fontSize: "$xs",
    "&.semibold": {
      fontWeight: "$semibold",
    },
    "&.upper": {
      textTransform: "uppercase",
    },
    "&.rateType": {
      display: "inline-block",
      padding: "0 $1",
      borderRadius: "$rounded",
      border: "solid 1px $primaryLight",
      backgroundColor: "$white",
    },
  },
  "& h4": {
    margin: 0,
    fontSize: "$sm",
  },
  "&:hover": {
    textDecoration: "none !important",
    borderColor: "$accent",
    backgroundColor: "$accentLighter",
  },
});

const fragment = graphql`
  fragment RecentSearches_viewer on Viewer
  @refetchable(queryName: "RecentSearchesRefetch") {
    recentSearches: savedsearches(
      first: 9
      order: [{ field: CREATE_DATE, direction: DESC }]
    ) {
      edges {
        node {
          id
          searchId
          createdDate
          name
          rateType
          locationFullLabel
          isGlobalSupplierSearch
          workerTypeName
          locationFullLabel
          industry {
            id
            value
          }
          region {
            name
            regionId
          }
          job {
            jobTitle
            jobLabel
            jobTitleId
          }
        }
      }
    }
  }
`;

type Props = {
  viewer: RecentSearches_viewer$key;
  pageSize: 3 | 9;
};

export function RsRecentSearches(props: Props) {
  const { viewer, pageSize } = props;

  const [data, refetch] = useRefetchableFragment<
    RecentSearchesRefetch,
    RecentSearches_viewer$key
  >(fragment, viewer);

  const searches =
    data.recentSearches?.edges.map((item) => item?.node).filter(isNonNullable) || [];

  /**
   * Effect that subscribes to `recentSearchesObserver`,
   * and refetches recent searches when the observer emits a value.
   */
  useEffect(() => {
    function listener() {
      refetch({ pageSize }, { fetchPolicy: "network-only" });
    }
    // Subscribes the refetch listener to the observer
    recentSearchesObserver.subscribe(listener);
    // Unsubscribes the listener from the observer on clean up
    return () => {
      recentSearchesObserver.unsubscribe(listener);
    };
  }, [refetch, pageSize]);

  return (
    <Wrapper>
      <Inline css={{ justifyContent: "space-between", alignItems: "flex-start" }}>
        <Stack css={{ alignItems: "flex-start", gap: "$0", paddingBottom: "$2" }}>
          <Text as="h4" noMargin>
            Recent Searches
          </Text>
        </Stack>
      </Inline>

      <SearchGrid>
        {searches.slice(0, pageSize).map((node) => {
          const rateType = node.rateType === 1 ? "Contract" : "FTE";
          return (
            <SearchItem
              to={`/rate-search-result?searchIds=${node.searchId}`}
              key={node.id}
              data-testid="recent-searches-card"
            >
              <Inline as="span" fill css={{ justifyContent: "space-between" }}>
                <span className="semibold upper">{node.industry?.value}</span>
                <span>{moment(node.createdDate).format("LL")}</span>
              </Inline>
              <h4>{node.job.jobLabel || node.job.jobTitle}</h4>
              <Inline as="span" fill>
                <span className="rateType">{rateType}</span>
                <span>{node.region?.name || node.locationFullLabel}</span>
              </Inline>
            </SearchItem>
          );
        })}
      </SearchGrid>
    </Wrapper>
  );
}

export function RsRecentSearchesLoader({ pageSize }: { pageSize: 3 | 9 }) {
  const loaders = new Array(pageSize).fill(1).map((x, i) => `loader-${i + 1}`);
  return (
    <Card css={{ padding: "$6" }}>
      <Inline css={{ justifyContent: "space-between", alignItems: "flex-start" }}>
        <Stack css={{ alignItems: "flex-start", gap: "$0", paddingBottom: "$2" }}>
          <Text as="h4" noMargin>
            Recent Searches
          </Text>
          <Placeholder width={190} height={16} css={{ marginTop: 4 }} />
        </Stack>
      </Inline>
      <SearchGrid>
        {loaders.map((key) => (
          <Placeholder key={key} height="92px" />
        ))}
      </SearchGrid>
    </Card>
  );
}
