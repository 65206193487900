// @flow

import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { Element } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import List from "./List";
import Modal from "../ratecards/components/Modal";
import LibrarySearch from "./LibrarySearch";
import HelpButton from "./HelpButton";
import { Accordion, Panel } from "react-bootstrap";
import type MobXStore from "../../stores/mobx/MobXStore";
import SessionInfo from "../../models/SessionInfo";
import LinkButton from "../../components/lib/LinkButton";
import Inline from "../../components/lib/Inline";
import { styled } from "../../../src/stitches.config";

const PanelHead = styled(Panel, {
  cursor: "pointer",
});

const MailToLink = styled("a", {
  color: "$brand !important",
  "&:hover": {
    textDecoration: "underline !important",
  },
});

const GlobeTag = styled("span", {
  fontSize: "14px",
  backgroundColor: "$brandLightest",
  color: "$brand",
  borderRadius: "5px",
  padding: "2px 5px !important",
});

type Props = {
  store: MobXStore,
  router: {
    push: (Object) => void,
  },
  location: {
    pathname: string,
  },
  params: {
    libraryId?: string,
    countryId?: string,
  },
  children: Element<any> | Element<any>[],
  sessionInfo?: SessionInfo,
};

type State = {
  autoStart?: boolean,
  running?: boolean,
  step?: number,
  showOverlay?: boolean,
};

class JobLibrary extends Component<Props, State> {
  props: Props;
  state: State;

  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.store.jobLibraryListStore.router = this.props.router;
    this.props.store.jobLibraryListStore.sessionInfo = this.props.sessionInfo;
    this.props.store.jobLibraryTitleListStore.sessionInfo = this.props.sessionInfo;
  }

  handleOnSearch = () => {
    const store = this.props.store.jobLibraryListStore;
    store.clientLibraryClientRawJobTitleFilter.onApply();
    store.getCountryLibraries();

    const titleListStore = this.props.store.jobLibraryTitleListStore;
    titleListStore.applyFilterFromLibraryStore();
    titleListStore.pagination.goFetch();
  };

  handleOnClearSearch = () => {
    const store = this.props.store.jobLibraryListStore;
    store.clientLibraryClientRawJobTitleFilter.onReset();
    store.getCountryLibraries();
  };

  render() {
    const store = this.props.store.jobLibraryListStore;
    const haveLibraryIdParam = !!this.props.params.libraryId; // Proxy for knowing we're on the detail page
    const setClearSearch = store.appliedFilters.hasOwnProperty(
      store.clientLibraryClientRawJobTitleFilter.column
    );

    return (
      <div className="cjl-view">
        <Inline css={{ height: "34px", marginBottom: "15px", display: "flex" }}>
          <LibrarySearch
            value={store.clientLibraryClientRawJobTitleFilter.textToLookFor}
            onChange={store.clientLibraryClientRawJobTitleFilter.onTextToLookForChange}
            placeholder="Search libraries..."
            filterOptions={store.searchFilterOptions}
            selectedFilterOption={store.searchSelectedFilterOption}
            onSearch={this.handleOnSearch}
            onClear={setClearSearch ? this.handleOnClearSearch : null}
          />
          <Inline css={{ marginLeft: "auto", gap: "$2", alignItems: "stretch" }}>
            <HelpButton onClick={store.helpModal.showModal} />
            <LinkButton
              variant="outlined"
              color="brand"
              shape="rounded"
              to="/job-library/library-requests/"
            >
              Create A New Library
            </LinkButton>
            <LinkButton
              color="brand"
              shape="rounded"
              to="/job-library/title-requests/create-title-request"
            >
              <FontAwesomeIcon
                style={{ marginRight: "10px" }}
                icon="plus"
                aria-hidden="true"
              />
              Request a New Job Title
            </LinkButton>
          </Inline>
        </Inline>
        {store.isFiltered && store.resultsForText && (
          <div className="cjl-search-query">
            <span className="cjl-label">RESULTS FOR:</span>{" "}
            <span>&ldquo;{store.resultsForText}&rdquo;</span>
          </div>
        )}
        <div className="cjl-body">
          <List
            className={classNames({
              "hidden-sm": !haveLibraryIdParam,
              "hidden-xs": !haveLibraryIdParam,
            })}
            store={this.props.store}
            selectedLibraryId={this.props.params.libraryId || ""}
            router={this.props.router}
          />
          {React.Children.map(this.props.children, (child) => React.cloneElement(child))}
        </div>

        <Modal show={store.helpModal.show} onHide={store.helpModal.hideModal}>
          <div className="container-section header">
            <button className="cjl-form-close-button" onClick={store.helpModal.hideModal}>
              <FontAwesomeIcon icon="times" />
            </button>
            <h4>Job Library Help</h4>
          </div>
          <div className="container-section" style={{ overflowY: "auto" }}>
            <p>
              These pages let you view and search your libraries and sub libraries. Click
              on a library to get started or click <b>Create A New Library</b> to make
              your own.
            </p>
            <p>The following are answers to frequently asked questions</p>
            <div>
              <Accordion>
                <PanelHead header="How do I search titles in my library?" eventKey="1">
                  <p>
                    You can search using a title in your library from either the{" "}
                    <b>Rate search</b> or <b>Batch Search</b> pages.
                  </p>
                  <p>
                    You can search for a title across your libraries in the top left of
                    this page and inside a specific library by clicking on the library you
                    want to search and using the search box on that page.
                  </p>
                </PanelHead>
                <PanelHead header="How do I request new titles?" eventKey="2">
                  <p>
                    If you'd like to add a single title, go to a library and click on it's
                    menu to the top right of the page. From there you can see an option to
                    request a new title as well as see all of your current title requests
                    and their progress.
                  </p>
                  <p>
                    If you'd like to add many titles, you can request a new sub library by
                    clicking <b>Create A New Library</b> in the top right and then
                    requesting a new library by selecting the download template option.
                  </p>
                </PanelHead>
                <PanelHead header="I requested a library and I don't see it" eventKey="3">
                  <p>
                    You can see all the Libraries you've requested by clicking{" "}
                    <strong>Create A New Library</strong> and scrolling to the bottom or
                    selecting Requested Libraries from the Main Overview Library Page.
                  </p>
                </PanelHead>
                <PanelHead
                  header="I requested a title, but I can't search it"
                  eventKey="4"
                >
                  <p>
                    Your title may not have been processed yet or it may be in a different
                    sub library than you expected.
                  </p>
                  <p>
                    You can try to find your title by{" "}
                    <i>searching titles in your library</i>.
                  </p>
                  <p>
                    If you couldn't find your title in any of your libraries, check the
                    job title requests page which you can access either via the overview
                    page or in the menu of any library.
                  </p>
                </PanelHead>
                <PanelHead
                  header="I requested a Job Title (s). How long before the selection is available in the Job Library?"
                  eventKey="5"
                >
                  <p>
                    Once the request is received; an analyst from the compensation team
                    will contact you via email to provide a delivery date. If there is an
                    urgent need; please enter this information in the Message field.
                  </p>
                </PanelHead>
                <PanelHead header="What is a Country Library?" eventKey="6">
                  <p>
                    Libraries are segmented by Countries. A Country Library allows you to
                    search in any available Country location.
                  </p>
                </PanelHead>
                <PanelHead
                  header="How do I add jobs from one Country Library to another?"
                  eventKey="7"
                >
                  <p>
                    Please contact{" "}
                    <MailToLink href="mailto:ratepoint@prounlimited.com">
                      ratepoint@prounlimited.com
                    </MailToLink>{" "}
                    for assistance.
                  </p>
                </PanelHead>
                <PanelHead header="What do the globes signify?" eventKey="8">
                  <p>
                    <span
                      className="tag tag-red text-x-small"
                      style={{ marginLeft: 0, borderRadius: 5, padding: "2px 5px" }}
                    >
                      <span className="glyphicon glyphicon-globe" /> #
                    </span>{" "}
                    The title can be searched in the displayed number of countries but the
                    search is an ad-hoc search title match which was selected by the user
                    and not a Pay Intel rate analyst.
                  </p>
                  <p>
                    <GlobeTag>
                      <span className="glyphicon glyphicon-globe" /> #
                    </GlobeTag>{" "}
                    The title can be searched in the displayed number of countries and the
                    title has been certified by a rate analyst for best match.
                  </p>
                </PanelHead>
                <PanelHead
                  header="What's the difference between a Title and a Job?"
                  eventKey="9"
                >
                  <p>
                    <ul>
                      <li>
                        A <strong>Title</strong> is a job title and job description.
                      </li>
                      <li>
                        A <strong>Job</strong> is a <strong>Title</strong> searchable in a{" "}
                        <strong>Country</strong>.
                      </li>
                      <li>
                        <strong>Jobs</strong> = Sum of the number of searchable countries
                        for each <strong>Title</strong> in the job library.
                      </li>
                    </ul>
                  </p>
                </PanelHead>
                <PanelHead header="I need more help!" eventKey="10">
                  <p>
                    If something is not clear, not working, or you'd just like some
                    questions answered please contact support.
                  </p>
                  <p>
                    <MailToLink href="mailto:ratepoint@prounlimited.com">
                      ratepoint@prounlimited.com
                    </MailToLink>
                  </p>
                </PanelHead>
              </Accordion>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default observer(JobLibrary);
