import React, { useMemo, useCallback } from "react";

import { styled } from "../../../../stitches.config";

import Stack from "../../../../components/lib/Stack";

import RatesFeedbackAgreeButton from "./buttons/RatesFeedbackAgreeButton";
import RatesFeedbackDisagreeButton from "./buttons/RatesFeedbackDisagreeButton";
import RatesFeedbackDontKnowButton from "./buttons/RatesFeedbackDontKnowButton";
import RatesFeedbackCommentButton from "./buttons/RatesFeedbackCommentButton";
import RatesFeedbackEditButton from "./buttons/RatesFeedbackEditButton";
import { getFilteredRatesKeys, compareDecimals } from "../../constants";
import { useReviewDataContext } from "../../../validator5K_admin/context/ReviewDataContext";
import { useVal5KPublicContext } from "../../context/Val5KPublicContext";
import { useClassicRatesItemFeedbackContext } from "../../context/feedback/ClassicRatesItemFeedbackContext";
import { useAttemptDataContext } from "../../context/AttemptDataContext";
import { RATE_FEEDBACK_STATUS_TYPES, RatesDataKey } from "../../types";
import RatesComment from "./RatesComment";

import type {
  RATE_FEEDBACK_STATUS_TYPES_TYPE,
  RatesDataObject,
  RatesFeedbackDataObject,
  VALUE_TYPES_TYPE,
} from "../../types";

const FeedbackActionsContainer = styled(Stack, {
  flexFlow: "row nowrap",
  alignItems: "flex-start",
  width: "200%",
  transition: "transform 0.3s ease-out",
  variants: {
    showButtons: {
      true: {
        transform: "translateX(0)",
      },
      false: {
        transform: "translateX(-50%)",
      },
    },
  },
});

function RatesFeedback() {
  const { showModalWarning } = useVal5KPublicContext();
  const { attemptId } = useAttemptDataContext();
  const { firstJobRateType: rateType, requiredRates } = useReviewDataContext();
  const {
    rateResult, // in local currency
    rateFeedback, // in local currency
    initialRateFeedback, // in local currency
    //
    isRatesEditable,
    isCommentEditable,
    //
    hasAnyRatesChanges,
    hasAnyInitialRatesFeedback,
    hasRequiredRatesFeedback,
    hasCommentFeedback,
    hasCommentChanges,
    hasStatusChanges,
    //
    startEditingFeedback,
    stopEditingFeedback,
    updateFeedbackState,
    saveFeedback,
  } = useClassicRatesItemFeedbackContext();

  const feedbackStatus = rateFeedback?.get("status");
  const feedbackComment = rateFeedback?.get("comment");

  // state

  const showButtons = useMemo(
    () => !isCommentEditable && !isRatesEditable,
    [isCommentEditable, isRatesEditable]
  );

  // utils

  const checkMinMaxConstraints = useCallback(
    (rateValueType: VALUE_TYPES_TYPE) => {
      const minValueKey: RatesDataKey = `${rateValueType}_min`;
      const maxValueKey: RatesDataKey = `${rateValueType}_max`;

      let minValue = rateFeedback?.get(minValueKey);
      let maxValue = rateFeedback?.get(maxValueKey);

      if (minValue == null) minValue = initialRateFeedback?.get(minValueKey);
      if (minValue == null) minValue = rateResult?.get(minValueKey);
      if (maxValue == null) maxValue = initialRateFeedback?.get(maxValueKey);
      if (maxValue == null) maxValue = rateResult?.get(maxValueKey);
      if (minValue == null || maxValue == null) return true;

      return compareDecimals(minValue, maxValue) < 0;
    },
    [rateFeedback, initialRateFeedback, rateResult]
  );

  // handlers

  const handleCommentButtonClick = useCallback(
    () => startEditingFeedback("comment"),
    [startEditingFeedback]
  );

  const handleRatesButtonClick = useCallback(
    () => startEditingFeedback("rates"),
    [startEditingFeedback]
  );

  const handleDisagreeButtonClick = useCallback(() => {
    startEditingFeedback("rates");
    updateFeedbackState({ status: RATE_FEEDBACK_STATUS_TYPES.DENIED });
  }, [startEditingFeedback, updateFeedbackState]);

  const handleApproveOrDontKnowButtonClick = useCallback(
    async (value: RATE_FEEDBACK_STATUS_TYPES_TYPE) => {
      if (attemptId == null) return;
      if (value === rateFeedback.get("status")) return;

      const feedbackUpdates: Partial<RatesFeedbackDataObject> = {
        status: value,
      };

      // clear previously changed rates
      if (hasAnyInitialRatesFeedback) {
        getFilteredRatesKeys(rateType, requiredRates.toJS()).forEach(
          (key) => (feedbackUpdates[key] = null)
        );
      }

      // API call
      await saveFeedback(feedbackUpdates);

      // show comment again if not empty
      if (hasCommentFeedback) {
        startEditingFeedback("comment");
      }
    },
    [
      attemptId,
      rateFeedback,
      hasCommentFeedback,
      hasAnyInitialRatesFeedback,
      rateType,
      requiredRates,
      saveFeedback,
      startEditingFeedback,
    ]
  );

  const handleChangeComment = useCallback(
    (value: string | null) => {
      if (value !== rateFeedback.get("comment")) {
        updateFeedbackState({ comment: value || null });
      }
    },
    [rateFeedback, updateFeedbackState]
  );

  const handleCancelButtonClick = useCallback(
    () => stopEditingFeedback(),
    [stopEditingFeedback]
  );

  const validateFeedbackState = useCallback((): boolean => {
    if (feedbackStatus == null) return false;

    const hasAnyCHanges =
      (isCommentEditable && hasCommentChanges) ||
      (isRatesEditable && hasAnyRatesChanges) ||
      hasStatusChanges;

    if (!hasAnyCHanges) return false;

    if (feedbackStatus === RATE_FEEDBACK_STATUS_TYPES.DENIED) {
      // don't save empty comment for disagreed card
      if (isCommentEditable && (feedbackComment ?? "").trim() === "") {
        showModalWarning("Please provide a comment to clarify your feedback.");
        return false;
      }
      // don't save no rates feedback for disagreed card
      if (isRatesEditable && !hasRequiredRatesFeedback) {
        showModalWarning("Please fix at least the Pay Rate Min/Max values.");
        return false;
      }
      // check Pay Rate constraints
      const hasPayRateFeedback =
        rateFeedback.filter((v: number, k: RatesDataKey) => k.startsWith("pay_rate"))
          .size > 0;
      if (hasPayRateFeedback && !checkMinMaxConstraints("pay_rate")) {
        showModalWarning("Pay Rate Min value can't be greater than Pay Rate Max.");
        return false;
      }
      // check Bill Rate constraints
      const hasBillRateFeedback =
        rateFeedback.filter((v: number, k: RatesDataKey) => k.startsWith("bill_rate"))
          .size > 0;
      if (hasBillRateFeedback && !checkMinMaxConstraints("bill_rate")) {
        showModalWarning("Bill Rate Min value can't be greater than Bill Rate Max.");
        return false;
      }
    }

    return true;
  }, [
    feedbackComment,
    feedbackStatus,
    rateFeedback,
    isCommentEditable,
    isRatesEditable,
    hasCommentChanges,
    hasAnyRatesChanges,
    hasStatusChanges,
    showModalWarning,
    checkMinMaxConstraints,
    hasRequiredRatesFeedback,
  ]);

  const handleSaveButtonClick = useCallback(async () => {
    if (attemptId == null) return;
    if (feedbackStatus == null) return;
    if (!validateFeedbackState()) return;

    const feedbackUpdates: Partial<RatesFeedbackDataObject> = {
      status: feedbackStatus,
    };

    // add comment if it has changed
    if (isCommentEditable && hasCommentChanges) {
      feedbackUpdates["comment"] = feedbackComment;
    }

    // add those changed only rates
    const ratesUpdates: Partial<RatesDataObject> = {};

    if (isRatesEditable && hasAnyRatesChanges) {
      const keysToChange = getFilteredRatesKeys(rateType, requiredRates.toJS());

      // apply only changed rates to the new state
      keysToChange.forEach((key: RatesDataKey) => {
        const initialValue = rateResult.get(key) ?? null;
        const previousValue = initialRateFeedback?.get(key) ?? null;
        let currentValue = rateFeedback?.get(key) ?? null;

        // if (!key.startsWith("markup")) {
        //   currentValue = convertFromDisplayCurrency(currentValue);
        // }

        if (currentValue) {
          if (initialValue && compareDecimals(currentValue, initialValue) === 0) {
            ratesUpdates[key] = null;
          } else if (previousValue) {
            if (compareDecimals(currentValue, previousValue) !== 0) {
              ratesUpdates[key] = currentValue;
            }
          } else if (initialValue) {
            if (compareDecimals(currentValue, initialValue) !== 0) {
              ratesUpdates[key] = currentValue;
            }
          } else {
            ratesUpdates[key] = currentValue;
          }
        }
      });
    }

    if (Object.keys(ratesUpdates).length > 0) {
      Object.assign(feedbackUpdates, ratesUpdates);
    }

    // turn off editing
    stopEditingFeedback();

    // API call
    await saveFeedback(feedbackUpdates);
  }, [
    rateType,
    requiredRates,
    feedbackComment,
    feedbackStatus,
    rateResult,
    rateFeedback,
    initialRateFeedback,
    attemptId,
    validateFeedbackState,
    stopEditingFeedback,
    isRatesEditable,
    isCommentEditable,
    hasCommentChanges,
    hasAnyRatesChanges,
    saveFeedback,
  ]);

  // renders

  const buttonsBlock = (
    <Stack fill nogap>
      <RatesFeedbackAgreeButton
        status={feedbackStatus}
        onClick={handleApproveOrDontKnowButtonClick}
      />
      <RatesFeedbackDisagreeButton
        status={feedbackStatus}
        onClick={handleDisagreeButtonClick}
      />
      <RatesFeedbackDontKnowButton
        status={feedbackStatus}
        onClick={handleApproveOrDontKnowButtonClick}
      />
      <RatesFeedbackCommentButton
        disabled={feedbackStatus == null}
        comment={feedbackComment}
        onClick={handleCommentButtonClick}
      />
      <RatesFeedbackEditButton
        feedback={rateFeedback}
        disabled={feedbackStatus !== RATE_FEEDBACK_STATUS_TYPES.DENIED}
        editable={isRatesEditable}
        onClick={handleRatesButtonClick}
      />
    </Stack>
  );

  const placeholder =
    isCommentEditable && isRatesEditable
      ? "Please adjust rates values above and type a comment here..."
      : isCommentEditable
      ? "Please type a comment here..."
      : undefined;

  const commentBlock = (
    <RatesComment
      comment={feedbackComment}
      placeholder={placeholder}
      disabled={!isCommentEditable}
      onSave={handleSaveButtonClick}
      onChange={handleChangeComment}
      onCancel={handleCancelButtonClick}
    />
  );

  return (
    <FeedbackActionsContainer nogap showButtons={showButtons}>
      {buttonsBlock}
      {commentBlock}
    </FeedbackActionsContainer>
  );
}

RatesFeedback.displayName = "RatesFeedback";

export default RatesFeedback;
