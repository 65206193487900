import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import moment from "moment";
import { Map, Set, List, fromJS } from "immutable";

import Description from "./Description";
// @ts-expect-error
import JobCollectionSelect from "../../../components/selects/JobCollectionSelect";
// @ts-expect-error
import Sticker from "../../../components/Sticker";
// @ts-expect-error
import Messager from "../../../components/Messager";
// @ts-expect-error
import LocationSelect from "../../../components/selects/LocationSelect";
// @ts-expect-error
import RegionSelect from "../../../components/selects/RegionSelect";
// @ts-expect-error
import IndustrySelect from "../../../components/selects/IndustrySelect";
import WorkerTypeSelect from "../../../components/selects/WorkerTypeSelect";
import type {
  WorkerTypeSelectProps,
  WorkerTypeSelectValue,
} from "../../../components/selects/WorkerTypeSelect";
import { usePLIContext } from "../context";
import PromiseButton from "../../../components/lib/PromiseButton";
import Icon from "../../../components/lib/Icon";
import TextArea from "../../../components/lib/TextArea";
import Stack from "../../../components/lib/Stack";
import Box from "../../../components/lib/Box";
import Inline from "../../../components/lib/Inline";
import Text, { TextOverflow } from "../../../components/lib/Text";
import { Link } from "../../../components/lib/Link";
import { RerunRowModal } from "./RerunIndexModals";
import {
  Table as TableBase,
  THead,
  TH,
  TBody,
  TR,
  TD as TDBase,
} from "../../../components/lib/Table";
import {
  Checkbox,
  CheckboxIndicator,
  LabelInline,
  CheckedState,
} from "../../../components/lib/Checkbox";
import Button from "../../../components/lib/Button";
import { ButtonGroup, ButtonGroupRight } from "../../../components/lib/ButtonGroup";
import { contractorItemToImmutableMap } from "../dataConverters";
import { useMessagerRef } from "../hooks";
import { ensurePromise } from "../utils";
// @ts-expect-error
import { logAsyncOperationError, logGraphQLError } from "../../../utils/logging";
import { useModalState } from "../../../utils/hooks";
import { styled } from "../../../stitches.config";
import { useProgramContext } from "../ProgramDataProvider";
import {
  RATE_TYPES,
  CONTRACTOR_STATUSES,
  PROCESSING_STATUSES,
  JOB_TITLE_MATCH_TYPES,
  JOB_TITLE_MATCH_TYPES_LABELS,
  JOB_TITLE_MATCH_TYPES_TYPE,
  CONTRACTOR_STATUSES_TYPE,
} from "../types";
import {
  decimalFormatter,
  dateTimeFormatter,
  timeLeftFormatter,
  percentFormatter,
  getTimePassedInUnits,
  timePassedFormatter,
  reactSelectStyles,
  emptyMap,
  emptyList,
} from "../constants";

import type {
  ImmutableArbitraryMap,
  ImmutableList,
  ImmutableMap,
} from "../../../types/immutable";
import type { FetchAPIResponse, FetchGraphQLAPIResponse } from "../../../types/fetch";
import type { RowEditorComponentProps } from "../../../components/tables/types";
import type {
  ContractorDataObject,
  ContractorDataMap,
  ProcessingMessagesMap,
  ProcessingMessageItemMap,
  ProcessingMessageItemObject,
} from "../types";
import type { ValueType } from "react-select/lib/types";

const ScoreText = styled(Text, {
  marginLeft: "$1_5 !important",
  fontWeight: "$extralight",
});
ScoreText.displayName = "ScoreText";

const ScoreBlock = styled(Inline, {
  flexWrap: "nowrap",
  justifyContent: "space-between",
  width: "400px",
  padding: "$1_5 $3",
  paddingRight: "$2",
  borderRadius: "$sm",
  color: "$textLight",

  variants: {
    color: {
      green: {
        backgroundColor: "$brand",
      },
      gray: {
        backgroundColor: "$primary",
      },
    },
  },
});
ScoreBlock.displayName = "ScoreBlock";

const IndentedStack = styled(Stack, {
  width: "$full",
  alignItems: "flex-start",
  paddingLeft: "$3",
  gap: "$2",
});
IndentedStack.displayName = "IndentedStack";

const EditorSegment = styled(Stack, {
  alignItems: "flex-start",
  width: "$full",
  padding: "$5 $3",
  borderBottom: "1px dashed $primaryLight",
  "&:last-child": {
    borderBottom: "none",
  },
  "& > label": {
    marginBottom: "0 !important",
  },
});
EditorSegment.displayName = "EditorSegment";

const Table = styled(TableBase, {
  "& tr": {
    border: "1px solid $primaryLight",
  },

  "& thead": {
    borderBottom: "none",
  },

  "& th": {
    border: "1px solid $primaryLight",
    textAlign: "center",
    color: "$primaryDark",
    padding: "$1_5",
  },

  "& td": {
    border: "1px solid $primaryLight",
    padding: "$1 $1_5",
  },

  variants: {
    nowrap: {
      true: {
        "& th": { whiteSpace: "nowrap" },
        "& td": { whiteSpace: "nowrap" },
      },
    },
  },
  defaultValues: {
    nowrap: false,
  },
});
Table.displayName = "Table";

const TD = styled(TDBase, {
  verticalAlign: "middle",
  variants: {
    centered: {
      true: {
        textAlign: "center",
      },
    },
  },
});
TD.displayName = "TD";

const equalAsStrings = (
  value1: number | string | undefined | null,
  value2: number | string | undefined | null
): boolean => {
  if (value1 == null || value2 == null) return false;
  return "" + value1 === "" + value2;
};

type TitleMatchItemMap = ImmutableMap<{
  collectionId: number;
  titleMatched: string;
  collectionDateCreated: string;
  collectionTitle: string;
  collectionSlug: string;
  createdBy: string;
  collectionNumOfTitles: number;
}>;
type TitleMatchesList = ImmutableList<TitleMatchItemMap>;

type TitleMatchMetadataItemMap = ImmutableMap<{
  collectionId: number;
  collectionTitle: string;
  collectionSlug: string;
  collectionNotes: string;
  collectionCreated: string;
  jobTitle: string;
  jobTitleSlug: string;
  sourceTitles: string[];
}>;
type TitleMatchesMetadataMap = ImmutableArbitraryMap<string, TitleMatchMetadataItemMap>;

type SuggestedCollectionResultMap = ImmutableMap<{
  id: number;
  title: string;
  slug: string;
}>;

type SuggestedCollectionsTableProps = {
  jobTitle: string;
  jobDescription?: string;
  onSelect: (value: SuggestedCollectionResultMap) => void;
};

const SuggestedCollectionsTable = (props: SuggestedCollectionsTableProps) => {
  const { jobTitle, jobDescription, onSelect } = props;
  const {
    fetchTasteAPI,
    showModalError,
    showConfirmationModal,
    closeConfirmationModal,
    services,
  } = usePLIContext();
  const tasteUrl = services?.taste;

  // state

  const [titleMatchDataState, setTitleMatchDataState] = useState<TitleMatchesList>(
    emptyList as TitleMatchesList
  );
  const [titleMatchMetadataState, setTitleMatchMetadataState] =
    useState<TitleMatchesMetadataMap>(emptyMap as TitleMatchesMetadataMap);

  // fetch data

  type FilterItemMap = ImmutableMap<{
    collectionId: number;
    jobTitle: string;
  }>;
  type FiltersMap = ImmutableArbitraryMap<string, FilterItemMap>;

  type TitleMetadataItemObject = {
    collectionId: number;
    collectionTitle: string;
    collectionSlug: string;
    collectionNotes: string;
    collectionCreated: string;
    jobTitle: string;
    jobTitleSlug: string;
    sourceTitles: string[];
  };

  type TitlesMetadataResponseObject = {
    collectionsTitlesMetadata: TitleMetadataItemObject[];
  };

  const fetchTitlesMetadata = useCallback(
    async (titleMatchesList) => {
      // Map being used here for filters deduplication
      const filters: FiltersMap = titleMatchesList.reduce(
        (acc: FiltersMap, match: TitleMatchItemMap) => {
          const matchedCollectionId = match.get("collectionId");
          const matchedCollectionTitle = match.get("collectionTitle");
          const matchedJobTitle = match.get("titleMatched");
          const key = [matchedCollectionTitle, matchedJobTitle]
            .filter((i) => i && i)
            .join(":");
          const filterItemMap = Map({
            collectionId: matchedCollectionId,
            jobTitle: matchedJobTitle,
          }) as FilterItemMap;

          return acc.set(key, filterItemMap);
        },
        emptyMap as FiltersMap
      );

      let titlesMetadata = emptyMap as TitleMatchesMetadataMap;

      if (filters.size) {
        try {
          const response: FetchGraphQLAPIResponse<TitlesMetadataResponseObject> =
            await fetchTasteAPI("graphql/", {
              method: "post",
              data: {
                query: `
                query CollectionsTitlesMetadataQuery($filters: CollectionsMetadataFiltersInput!) {
                  collectionsTitlesMetadata(filters: $filters) {
                    collectionId
                    collectionTitle
                    collectionSlug
                    collectionNotes
                    collectionCreated
                    jobTitle
                    jobTitleSlug
                    sourceTitles
                  }
                }
              `,
                variables: {
                  filters: { collectionIdTitleOnly: filters.toList().toJS() },
                },
              },
            });

          if (logGraphQLError("fetchCollectionsTitlesMetadata", response.data)) {
            return emptyMap;
          } else if (response?.data?.data?.collectionsTitlesMetadata) {
            // Map being used here for filters deduplication
            titlesMetadata = response.data.data.collectionsTitlesMetadata.reduce(
              (acc: TitleMatchesMetadataMap, item: TitleMetadataItemObject) => {
                const key = [item.collectionTitle, item.jobTitle]
                  .filter((i) => i && i)
                  .join(":");
                return acc.set(key, Map(item) as TitleMatchMetadataItemMap);
              },
              emptyMap as TitleMatchesMetadataMap
            );

            if (titlesMetadata.size) {
              setTitleMatchMetadataState(titlesMetadata);
            }
          }
        } catch (err) {
          logAsyncOperationError("fetchCollectionsTitlesMetadata", err);
          showModalError("Error occurred while retrieving public titles suggestions.");
        }
      }

      return titlesMetadata;
    },
    [showModalError, fetchTasteAPI]
  );

  type SemanticTitleMatchResponseData = {
    jobMatched: {
      title: string;
      collection: {
        databaseId: number;
        created: Date;
        title: string;
        slug: string;
        totalRawJobTitles: number;
        createdBy: {
          username: string;
        };
      };
    };
  };

  type SemanticTitleMatchResponse = {
    semanticTitleMatch: SemanticTitleMatchResponseData[];
  };

  const makeSemanticTitleMatch = useCallback(
    async (jobTitle: string, jobDescription?: string): Promise<TitleMatchesList> => {
      let matches = emptyList as TitleMatchesList;

      try {
        const response: FetchGraphQLAPIResponse<SemanticTitleMatchResponse> =
          await fetchTasteAPI("graphql/", {
            method: "post",
            data: {
              // query: `
              //   query ESCollectionsQuery($title: String!, $description: String!) {
              //     esCollectionsMatch(title: $title, descriptionText: $description) {
              //       collectionId
              //       titleMatched
              //       collectionDateCreated
              //       collectionTitle
              //       collectionSlug
              //       createdBy
              //       collectionNumOfTitles
              //     }
              //   }
              // `,
              query: `
              query getTitleMatches($jobTitle: String!, $jobDescription: String!) {
                semanticTitleMatch(
                  jobTitle: $jobTitle,
                  jobDescription: $jobDescription,
                  topK: 10,
                  minSimilarity: 0.5,
                  dedupeByCollection: true
                ) {
                  jobSimilarity
                  jobMatched {
                    databaseId
                    title
                    collection {
                      databaseId
                      title
                      slug
                      totalRawJobTitles
                      created
                      createdBy {
                        username
                      }
                    }
                  }
                }
              }
            `,
              variables: { jobTitle, jobDescription },
            },
          });

        if (logGraphQLError("fetchMappingSuggestions", response.data)) {
          return emptyList as TitleMatchesList;
        }

        if (response?.data?.data?.semanticTitleMatch) {
          matches = List(
            response.data.data.semanticTitleMatch.map(
              (match: SemanticTitleMatchResponseData) => {
                return Map({
                  collectionId: match?.jobMatched?.collection?.databaseId,
                  titleMatched: match?.jobMatched?.title,
                  collectionDateCreated: match?.jobMatched?.collection?.created,
                  collectionTitle: match?.jobMatched?.collection?.title,
                  collectionSlug: match?.jobMatched?.collection?.slug,
                  createdBy: match?.jobMatched?.collection?.createdBy?.username,
                  collectionNumOfTitles: match?.jobMatched?.collection?.totalRawJobTitles,
                }) as TitleMatchItemMap;
              }
            )
          ) as TitleMatchesList;
        }

        setTitleMatchDataState(matches);
        fetchTitlesMetadata(matches);
      } catch (err) {
        logAsyncOperationError("fetchMappingSuggestions", err);
        showModalError("Error occurred while retrieving collection suggestions.");
      }

      return matches;
    },
    [fetchTitlesMetadata, showModalError, fetchTasteAPI]
  );

  // effects

  useEffect(() => {
    makeSemanticTitleMatch(jobTitle, jobDescription);
  }, [jobTitle, jobDescription, makeSemanticTitleMatch]);

  // handlers

  const handleShowMoreTitleInfo = useCallback(
    (titleMatchData: TitleMatchItemMap, titleMetadata: TitleMatchMetadataItemMap) => {
      if (titleMatchData.size && titleMetadata.size) {
        const collectionId = titleMatchData.get("collectionId");
        const collectionTitle = titleMatchData.get("collectionTitle");
        const collectionCreatedAt = titleMetadata.get("collectionCreated");
        const collectionCreatedBy = titleMatchData.get("createdBy");
        const collectionNotes = titleMetadata.get("collectionNotes");
        const titleMatched =
          titleMetadata.get("jobTitle") || titleMatchData.get("titleMatched");
        const clientsList =
          Set(titleMetadata.get("sourceTitles") || [])
            .toList()
            .sort()
            .join("\n") || null;

        const collectionLink =
          collectionId && tasteUrl
            ? `${tasteUrl}job-title-collections/${collectionId}`
            : null;

        const header = `Collection "${collectionTitle}" Info`;
        const message = (
          <Stack css={{ alignItems: "flex-start", gap: "$1" }}>
            <Text>
              <Text bold underline>
                Id
              </Text>
              : {collectionId}
            </Text>
            <Text>
              <Text bold underline>
                Title
              </Text>
              : {collectionTitle}
            </Text>

            {collectionCreatedAt && (
              <Text>
                <Text bold underline>
                  Created At
                </Text>
                : {dateTimeFormatter(new Date(collectionCreatedAt))}
              </Text>
            )}
            {collectionCreatedBy && (
              <Text>
                <Text bold underline>
                  Created By
                </Text>
                : &nbsp;
                <Icon icon="user" css={{ color: "$brand" }} />
                &nbsp;{collectionCreatedBy}
              </Text>
            )}
            {collectionLink && (
              <Text>
                <Text bold underline>
                  Link
                </Text>
                :&nbsp;
                <Link href={collectionLink} target="_blank" rel="noopener noreferrer">
                  {collectionTitle} <Icon icon="external-link" />
                </Link>
              </Text>
            )}
            {titleMatched && (
              <Text>
                <Text bold underline>
                  Job Title Matched
                </Text>
                : {titleMatched}
              </Text>
            )}
            {clientsList && (
              <>
                <Text>
                  <Text bold underline>
                    Job Title Clients List
                  </Text>
                  :
                </Text>
                <Text as="pre" css={{ fontSize: "$sm" }}>
                  {clientsList}
                </Text>
              </>
            )}
            {collectionNotes && (
              <>
                <Text>
                  <Text bold underline>
                    Notes
                  </Text>
                  :
                </Text>
                <Text as="pre" css={{ fontSize: "$sm" }}>
                  {collectionNotes}
                </Text>
              </>
            )}
          </Stack>
        );
        const footer = (
          <ButtonGroupRight fill>
            <Button size="large" onClick={closeConfirmationModal}>
              Close
            </Button>
          </ButtonGroupRight>
        );

        showConfirmationModal(message, header, footer);
      }
    },
    [showConfirmationModal, closeConfirmationModal, tasteUrl]
  );

  const handleSuggestedCollectionSelect = useCallback(
    (item: TitleMatchItemMap) => {
      onSelect(
        Map({
          id: item.get("collectionId"),
          title: item.get("collectionTitle"),
          slug: item.get("collectionSlug"),
        }) as SuggestedCollectionResultMap
      );
    },
    [onSelect]
  );

  if (!titleMatchDataState?.size) {
    return (
      <>
        <Text as="h5">Suggested Collections:</Text>
        {(!titleMatchDataState || !titleMatchDataState.size) && (
          <Text thin>loading collection suggestions list...</Text>
        )}
      </>
    );
  }

  return (
    <>
      <Text as="h5">Suggested Collections:</Text>
      <Table nowrap highlighted={false}>
        <THead>
          <TR>
            <TH>Collection ID</TH>
            <TH>Collection Title</TH>
            <TH>Job Title Matched</TH>
            <TH>Date</TH>
            <TH>Created By</TH>
            <TH># of Jobs</TH>
            <TH>More Info</TH>
            <TH>Select</TH>
          </TR>
        </THead>
        <TBody>
          {titleMatchDataState
            .toArray()
            .map((titleMatchData: TitleMatchItemMap, idx: number) => {
              const collectionId = titleMatchData.get("collectionId");
              const collectionTitle = titleMatchData.get("collectionTitle");
              const jobTitle = titleMatchData.get("titleMatched");
              const collectionDateCreatedRaw = titleMatchData.get(
                "collectionDateCreated"
              );
              const collectionDateCreated =
                collectionDateCreatedRaw != null
                  ? moment.utc(collectionDateCreatedRaw).format("MM/DD/YYYY")
                  : "";
              const metadataKey = [collectionTitle, jobTitle]
                .filter((i) => i && i)
                .join(":");
              const titleMatchMetadata =
                titleMatchMetadataState.get(metadataKey) || emptyMap;

              return (
                <TR key={idx}>
                  <TD centered>{collectionId}</TD>
                  <TD>{collectionTitle}</TD>
                  <TD>{jobTitle}</TD>
                  <TD centered>{collectionDateCreated}</TD>
                  <TD centered>{titleMatchData.get("createdBy")}</TD>
                  <TD centered>{titleMatchData.get("collectionNumOfTitles")}</TD>
                  <TD centered>
                    {titleMatchMetadata && titleMatchMetadata.size ? (
                      <Button
                        color="brand"
                        size="ptNormal"
                        css={{ padding: "$0_5" }}
                        onClick={() =>
                          handleShowMoreTitleInfo(titleMatchData, titleMatchMetadata)
                        }
                      >
                        Show
                      </Button>
                    ) : null}
                  </TD>
                  <TD centered>
                    <Button
                      color="brand"
                      size="ptNormal"
                      css={{ padding: "$0_5" }}
                      onClick={() => handleSuggestedCollectionSelect(titleMatchData)}
                    >
                      Select
                    </Button>
                  </TD>
                </TR>
              );
            })}
        </TBody>
      </Table>
    </>
  );
};

SuggestedCollectionsTable.displayName = "SuggestedCollectionsTable";

type CollectionPublicTitleObject = {
  id: number;
  title: string;
  is_default: boolean;
  is_job_label: boolean;
  show_description: boolean;
  description: string;
};
type CollectionPublicTitleMap = ImmutableMap<CollectionPublicTitleObject>;
type CollectionPublicTitlesList = ImmutableList<CollectionPublicTitleMap>;

type SuggestedPublicTitlesTableProps = {
  data: CollectionPublicTitlesList;
  onSelect: (value: CollectionPublicTitleMap) => void;
};

const SuggestedPublicTitlesTable = (props: SuggestedPublicTitlesTableProps) => {
  const { data, onSelect } = props;

  if (!data || !data.size) return null;

  return (
    <Box fill>
      <h5>Suggested Public Titles:</h5>
      <Table highlighted={false}>
        <THead>
          <TR>
            <TH>ID</TH>
            <TH>Public Title</TH>
            <TH>Public Title Description</TH>
            <TH>Is Default</TH>
            <TH>Is Label</TH>
            <TH>Showing Description</TH>
            <TH>Select</TH>
          </TR>
        </THead>
        <TBody>
          {data.toArray().map((item: CollectionPublicTitleMap, idx: number) => {
            const publicTitleId = item.get("id");

            return (
              <TR key={idx}>
                <TD centered>{publicTitleId}</TD>
                <TD>{item.get("title")}</TD>
                <TD>
                  <Description text={item.get("description")} />
                </TD>
                <TD centered>
                  {!!item.get("is_default") ? (
                    <Text color="positive">Default</Text>
                  ) : null}
                </TD>
                <TD centered>
                  {!!item.get("is_job_label") ? (
                    <Text color="positive">Label</Text>
                  ) : null}
                </TD>
                <TD centered>
                  {!!item.get("show_description") ? (
                    <Text color="positive">True</Text>
                  ) : null}
                </TD>
                <TD centered>
                  <Button
                    color="brand"
                    size="ptNormal"
                    css={{ padding: "$0_5" }}
                    onClick={() => onSelect && onSelect(item)}
                  >
                    Select
                  </Button>
                </TD>
              </TR>
            );
          })}
        </TBody>
      </Table>
    </Box>
  );
};

SuggestedPublicTitlesTable.displayName = "SuggestedPublicTitlesTable";

type ClientDeliveryMap = ImmutableMap<{
  clientId: number;
  clientTitle: string;
  clientSubscriptionId: number | null;
  lastDeliveryTimestamp: Date | null;
}>;
type ClientDeliveriesList = ImmutableList<ClientDeliveryMap>;

type DeliveriesListBlockProps = {
  data: ClientDeliveriesList | null;
};

const DeliveriesListBlock = (props: DeliveriesListBlockProps) => {
  const { data } = props;

  return (
    <Stack css={{ gap: "$1", alignItems: "flex-start" }}>
      <Text italic bold>
        Delivered to following trusted clients:
      </Text>
      {(!data || !data.size) && (
        <Text thin css={{ marginLeft: "$1 !important" }}>
          No deliveries so far.
        </Text>
      )}
      {!!(data && data.size) &&
        data
          .slice(0, 10)
          .toArray()
          .map((item: ClientDeliveryMap, idx: number) => {
            const clientId = item.get("clientId");
            const clientTitle = item.get("clientTitle");
            const clientSubscriptionId = item.get("clientSubscriptionId");
            const lastDeliveryTimestamp = item.get("lastDeliveryTimestamp");
            let timestampString = null;

            if (clientId == null || clientTitle == null) {
              return "";
            }

            if (lastDeliveryTimestamp != null) {
              timestampString = `${timePassedFormatter(lastDeliveryTimestamp)} ago`;
            }

            return (
              <Inline key={idx} css={{ gap: "$1", marginLeft: "$2" }}>
                <Text bold>#{clientId}</Text>
                {clientTitle}
                {(!!timestampString || !!clientSubscriptionId) && (
                  <Text thin>
                    delivered
                    {!!timestampString && <span> {timestampString}</span>}
                    {!!clientSubscriptionId && <span> via PT Store</span>}
                  </Text>
                )}
              </Inline>
            );
          })}
    </Stack>
  );
};

DeliveriesListBlock.displayName = "DeliveriesListBlock";

type ProblemsBlockProps = {
  needApproval: boolean;
  warnings: ProcessingMessagesMap;
  errors: ProcessingMessagesMap;
};

const ProblemsBlock = (props: ProblemsBlockProps) => {
  const { needApproval, warnings, errors } = props;

  const hasErrors = errors.size > 0;
  const hasWarnings = errors.size === 0 && warnings.size > 0;
  const hasNoProblems = errors.size === 0 && warnings.size === 0;

  let backgroundColor;
  if (hasErrors) {
    backgroundColor = "$dangerLightest";
  } else if (hasWarnings || needApproval) {
    backgroundColor = "$warningLightest";
  } else if (hasNoProblems && !needApproval) {
    backgroundColor = "$successLightest";
  }

  return (
    <Stack
      fill
      css={{
        backgroundColor: backgroundColor,
        alignItems: "flex-start",
        padding: "$5",
        gap: "$2",

        "& h4": {
          margin: "0 !important",
        },

        [`& ${Icon}`]: {
          fontSize: "$3xl",
          verticalAlign: "middle",
          marginRight: "$2",
          color: "$primary",
        },

        [`& ${Inline}`]: {
          paddingLeft: "$3",
          gap: "0",
          fontSize: "$lg",
        },
      }}
    >
      {hasErrors ? (
        <Text as="h4">We found following problems in selected row:</Text>
      ) : hasWarnings ? (
        <Text as="h4">
          We found only following warnings in selected job. They are not critical and you
          can skip them using button below:
        </Text>
      ) : needApproval ? (
        <Text as="h4">
          <Icon css={{ color: "$warningLight !important" }} icon="exclamation-circle" />{" "}
          This row is going to create a NEW JOB TITLE in TASTE. Please approve this
          operation below.
        </Text>
      ) : (
        <Text as="h4">
          <Icon css={{ color: "$successLight !important" }} icon="check-circle" /> We
          didn't find any problems with selected row.
        </Text>
      )}
      {errors
        .valueSeq()
        .toArray()
        .map((error: ProcessingMessageItemMap, idx: number) => (
          <Inline key={idx}>
            <Icon css={{ color: "$dangerLight !important" }} icon="times-circle" />{" "}
            {error.get("message")}
          </Inline>
        ))}
      {warnings
        .valueSeq()
        .toArray()
        .map((warning: ProcessingMessageItemMap, idx: number) => (
          <Inline key={idx}>
            <Icon css={{ color: "$warningLight !important" }} icon="exclamation-circle" />{" "}
            {warning.get("message")}
          </Inline>
        ))}
    </Stack>
  );
};

ProblemsBlock.displayName = "ProblemsBlock";

const LimitsValue = styled(Inline, {
  gap: 0,
  border: "1px solid $primaryLight",
  borderRadius: "$rounded",
  whiteSpace: "nowrap",
  overflow: "hidden",

  "& span": {
    padding: "$1 $2 !important",
    display: "inline-block",

    "&:first-child": {
      color: "$textLight",
      backgroundColor: "$brand",
    },
  },
});

type CollectionLimitsProps = {
  collectionId?: number;
  countryId?: number;
  country?: string;
  isLimitsAccepted?: boolean;
  contractorData: ContractorDataMap;
  collectionLimitsData: CollectionLimitsDataMap;
  isDescriptionChanged: boolean;
  isCollectionChanged: boolean;
  isIndustryChanged: boolean;
  isCountryChanged: boolean;
  onChange: (checked: CheckedState) => void;
};

const CollectionLimits = (props: CollectionLimitsProps) => {
  const {
    collectionId,
    countryId,
    country,
    isLimitsAccepted,
    contractorData,
    collectionLimitsData,
    isDescriptionChanged,
    isCollectionChanged,
    isIndustryChanged,
    isCountryChanged,
    onChange,
  } = props;

  // IMPORTANT: Per Marc - need more manual control on Accepted flag, should be always available
  const isLimitsAcceptedEditable = true;
  // const isLimitsAcceptedEditable = (
  //   data.getIn(['market_analysis','junior_pay_rate_avg']) != null &&
  //     data.getIn(['upload', 'is_active'], false)
  // );

  if (collectionId == null || countryId == null) return null;
  if (!collectionLimitsData || !collectionLimitsData.size) return null;

  const rateType = contractorData.get("rate_type") || RATE_TYPES.CONTRACT;
  const currencySymbol = contractorData.getIn(["processed", "currency_symbol"]);
  let lowLimitValue = null;
  let highLimitValue = null;

  if (rateType === RATE_TYPES.CONTRACT) {
    lowLimitValue = collectionLimitsData.get("hourly_low");
    highLimitValue = collectionLimitsData.get("hourly_high");
  } else if (rateType === RATE_TYPES.FTE) {
    lowLimitValue = collectionLimitsData.get("salary_low");
    highLimitValue = collectionLimitsData.get("salary_high");
  }

  if (lowLimitValue == null && highLimitValue == null) return null;

  const isStatusFailed = contractorData.get("status") === CONTRACTOR_STATUSES.FAILED;
  const lastModifiedTimestampString = timeLeftFormatter(
    collectionLimitsData.get("updated")
  );

  return (
    <>
      <Text as="h5">
        Rates Limits for the country: <b>{country}</b>
      </Text>
      <Stack css={{ alignItems: "flex-start", gap: "$1_5" }}>
        <Inline css={{ justifyContent: "flex-start", gap: "$2" }}>
          <Inline nowrap nogap css={{ marginRight: "$2" }}>
            <LimitsValue>
              <span>Low</span>
              <span>{decimalFormatter(lowLimitValue, false, currencySymbol)}</span>
            </LimitsValue>
            &nbsp;&nbsp;&mdash;&nbsp;&nbsp;
            <LimitsValue>
              <span>High</span>
              <span>{decimalFormatter(highLimitValue, false, currencySymbol)}</span>
            </LimitsValue>
          </Inline>
          <LabelInline
            title={
              isStatusFailed
                ? "Row is failed, fix row's mappings first."
                : isCollectionChanged
                ? "Collection is changed, apply changes first."
                : isDescriptionChanged
                ? "Description is changed, apply changes first."
                : isCountryChanged
                ? "Country is changed, apply changes first."
                : isIndustryChanged
                ? "Industry is changed, apply changes first"
                : !isLimitsAcceptedEditable
                ? "Upload should be approved by the PT admin."
                : isLimitsAccepted
                ? "Make Not Accepted"
                : "Make Accepted"
            }
          >
            Limits Accepted By The Client
            <Checkbox
              checked={isLimitsAccepted}
              onCheckedChange={onChange}
              disabled={
                !isLimitsAcceptedEditable ||
                isStatusFailed ||
                isCollectionChanged ||
                isDescriptionChanged ||
                isCountryChanged ||
                isIndustryChanged
              }
            >
              <CheckboxIndicator>
                <Icon icon="check" />
              </CheckboxIndicator>
            </Checkbox>
          </LabelInline>
        </Inline>
        <Text thin>
          Last updated:{" "}
          {lastModifiedTimestampString ? lastModifiedTimestampString + " ago" : "never"}
        </Text>
      </Stack>
    </>
  );
};

CollectionLimits.displayName = "CollectionLimits";

type CollectionLimitsDataMap = ImmutableMap<{
  id: number;
  is_generated: boolean;
  is_client_accepted: boolean;
  updated: Date;
  created: Date;
  hourly_low: number;
  hourly_high: number;
  salary_low: number;
  salary_high: number;
}>;

type ConfidenceScoreDetailsProps = {
  contractorData: ContractorDataMap;
  collectionLimitsData: CollectionLimitsDataMap;
  clientsDeliveriesData: ImmutableList<ClientDeliveryMap>;
};

const ConfidenceScoreDetails = (props: ConfidenceScoreDetailsProps) => {
  const { contractorData, clientsDeliveriesData, collectionLimitsData } = props;

  if (!contractorData?.size) return null;

  const matchType = contractorData.get("processed")?.get("job_title_match_type");

  const hasLimits =
    !!collectionLimitsData &&
    !!collectionLimitsData.size &&
    !!collectionLimitsData.get("id");
  const isLimitsModeled = !!collectionLimitsData.get("is_generated", false);
  const isLimitsAccepted = !!collectionLimitsData.get("is_client_accepted", false);
  const limitsModificationTimestamp =
    collectionLimitsData.get("updated") || collectionLimitsData.get("created");
  const isLimitsModifiedRecently =
    getTimePassedInUnits(limitsModificationTimestamp, "months") <= 18;

  const hasClientDeliveries = !!(
    clientsDeliveriesData &&
    clientsDeliveriesData.size &&
    clientsDeliveriesData.first().size
  );
  const lastClientDeliveryTimestamp = hasClientDeliveries
    ? clientsDeliveriesData.first().get("lastDeliveryTimestamp")
    : null;
  const isClientDeliveryFreshEnough =
    lastClientDeliveryTimestamp != null &&
    getTimePassedInUnits(lastClientDeliveryTimestamp, "months") <= 18;

  let matchingScore = 0;
  let deliveriesScore = 0;
  let collectionLimitsScore = 0;

  // type of matching algorithm applied
  if (matchType === JOB_TITLE_MATCH_TYPES.FUZZY_MATCH) {
    matchingScore = 50;
  } else if (matchType === JOB_TITLE_MATCH_TYPES.EXACT_MATCH) {
    matchingScore = 75;
  } else if (matchType === JOB_TITLE_MATCH_TYPES.FIXED_MANUALLY) {
    matchingScore = 100;
  }

  // how accurate collection limits are in Taste
  if (hasLimits) {
    // limits generated automatically
    if (isLimitsModeled) {
      collectionLimitsScore = 0;
    } else {
      collectionLimitsScore = 25;
    }

    // rates limits accepted by the client
    if (isLimitsAccepted) {
      collectionLimitsScore += 25;
    }

    // modified within last 18 months
    if (isLimitsModifiedRecently) {
      collectionLimitsScore += 50;
    }
  }

  // trusted clients deliveries (collection titles could be delivered to some trusted clients)
  if (hasClientDeliveries) {
    deliveriesScore = 50;

    // delivered within last 18 months
    if (isClientDeliveryFreshEnough) {
      deliveriesScore = 100;
    }
  }

  const totalScore = matchingScore + deliveriesScore + collectionLimitsScore;
  const totalScorePercentage = Math.round(totalScore / 3);

  return (
    <Stack css={{ alignItems: "flex-start", gap: "$1_5" }}>
      <ScoreText>
        Type of matching algorithm applied to the row (max score 100):
      </ScoreText>
      <Inline fill nowrap nogap>
        <ScoreBlock color={matchType != null ? "green" : "gray"}>
          {matchType === JOB_TITLE_MATCH_TYPES.FUZZY_MATCH && (
            <TextOverflow>
              <b>50</b>&nbsp;&nbsp;{JOB_TITLE_MATCH_TYPES_LABELS[matchType]}
            </TextOverflow>
          )}
          {matchType === JOB_TITLE_MATCH_TYPES.EXACT_MATCH && (
            <TextOverflow>
              <b>75</b>&nbsp;&nbsp;{JOB_TITLE_MATCH_TYPES_LABELS[matchType]}
            </TextOverflow>
          )}
          {matchType === JOB_TITLE_MATCH_TYPES.FIXED_MANUALLY && (
            <TextOverflow>
              <b>100</b>&nbsp;&nbsp;{JOB_TITLE_MATCH_TYPES_LABELS[matchType]}
            </TextOverflow>
          )}
          {matchType != null && <Icon icon="check" />}
          {matchType == null && (
            <TextOverflow>
              <b>0</b>&nbsp;&nbsp;Type of match
            </TextOverflow>
          )}
        </ScoreBlock>

        {matchType == null && <ScoreText>up to 100 to score</ScoreText>}
      </Inline>

      <ScoreText>
        How accurate are Taste's collection rates limits (max score 100):
      </ScoreText>

      <Inline fill nowrap nogap>
        <ScoreBlock color={hasLimits && !isLimitsModeled ? "green" : "gray"}>
          <TextOverflow>
            <b>{hasLimits && !isLimitsModeled ? "+25" : "0"}</b>&nbsp;&nbsp; Rates limits
            are NOT modeled
          </TextOverflow>
          {hasLimits && !isLimitsModeled && <Icon icon="check" />}
        </ScoreBlock>
        {!(hasLimits && !isLimitsModeled) && <ScoreText>+25 to score</ScoreText>}
      </Inline>

      <Inline fill nowrap nogap>
        <ScoreBlock color={hasLimits && isLimitsAccepted ? "green" : "gray"}>
          <TextOverflow>
            <b>{hasLimits && isLimitsAccepted ? "+25" : "0"}</b>&nbsp;&nbsp; Rates limits
            are accepted by the client
          </TextOverflow>
          {hasLimits && isLimitsAccepted && <Icon icon="check" />}
        </ScoreBlock>
        {!(hasLimits && isLimitsAccepted) && <ScoreText>+25 to score</ScoreText>}
      </Inline>

      <Inline fill nowrap nogap>
        <ScoreBlock color={hasLimits && isLimitsModifiedRecently ? "green" : "gray"}>
          <TextOverflow>
            <b>{hasLimits && isLimitsModifiedRecently ? "+50" : "0"}</b>&nbsp;&nbsp; Rates
            limits updated within last 18 months
          </TextOverflow>
          {hasLimits && isLimitsModifiedRecently && <Icon icon="check" />}
        </ScoreBlock>
        {!(hasLimits && isLimitsModifiedRecently) && <ScoreText>+50 to score</ScoreText>}
      </Inline>

      <ScoreText>Any deliveries to trusted clients (max score 100):</ScoreText>

      <Inline fill nowrap nogap>
        <ScoreBlock color={hasClientDeliveries ? "green" : "gray"}>
          <TextOverflow>
            <b>{hasClientDeliveries ? "+50" : "0"}</b>&nbsp;&nbsp; Delivered to any
            trusted clients
          </TextOverflow>
          {hasClientDeliveries && <Icon icon="check" />}
        </ScoreBlock>
        {!hasClientDeliveries && <ScoreText>+50 to score</ScoreText>}
      </Inline>

      <Inline fill nowrap nogap>
        <ScoreBlock
          color={hasClientDeliveries && isClientDeliveryFreshEnough ? "green" : "gray"}
        >
          <TextOverflow>
            <b>{hasClientDeliveries && isClientDeliveryFreshEnough ? "+50" : "0"}</b>
            &nbsp;&nbsp; Delivered to trusted client within last 18 months
          </TextOverflow>
          {hasClientDeliveries && isClientDeliveryFreshEnough && <Icon icon="check" />}
        </ScoreBlock>
        {!(hasClientDeliveries && isClientDeliveryFreshEnough) && (
          <ScoreText>+50 to score</ScoreText>
        )}
      </Inline>

      <Inline css={{ marginTop: "$2", gap: "$1" }}>
        <Text italic bold>
          Total Score:
        </Text>
        <Text bold as="big">
          {percentFormatter(totalScorePercentage)}
        </Text>
        &nbsp;({totalScore} out of 300)
      </Inline>
    </Stack>
  );
};

ConfidenceScoreDetails.displayName = "ConfidenceScoreDetails";

type ContractorUpdateDataObject = Partial<{
  job_description: string;
  job_title_match_type: JOB_TITLE_MATCH_TYPES_TYPE;
  job_title_id: number | null;
  job_collection_id: number | null;
  job_collection_title: string | null;
  job_collection_slug: string | null;
  job_collection_public_title: string | null;
  job_collection_public_title_id: number | null;
  job_collection_public_title_description: string | null;
  collection_limits_client_accepted: boolean;
  industry_id: number;
  industry_title: string;
  worker_type_id: number;
  worker_type_title: string;
  city: string | null;
  state: string | null;
  country: string;
  region: string | null;
  location_id: string;
  region_id: number;
  country_id: number;
  is_global_supplier_search: boolean;
  location_title: string;
  location_subtitle: string;
  location_full_title: string;
  location_full_subtitle: string;
}>;
type ContractorUpdateDataMap = ImmutableMap<ContractorUpdateDataObject>;

interface AdminContractorEditorProps extends RowEditorComponentProps<ContractorDataMap> {
  programId: number;
  onApply: () => void;
  onCancel: (refresh: boolean) => Promise<void>;
  onDelete: (contractorId: number) => Promise<void>;
}

type AdminContractorEditorState = {
  changedData: ContractorUpdateDataMap;
  isDescriptionChanged: boolean;
  isCollectionChanged: boolean;
  isPublicTitleChanged: boolean;
  isLimitsAcceptedFlagChanged: boolean;
  isLocationChanged: boolean;
  isRegionChanged: boolean;
  isCountryChanged: boolean;
  isIndustryChanged: boolean;
  isWorkerTypeChanged: boolean;
  isGlobalSupplierSearchFlagChanged: boolean;
  //
  collectionPublicTitlesData: CollectionPublicTitlesList | null;
  collectionLimitsData: CollectionLimitsDataMap | null;
  clientsDeliveriesData: ClientDeliveriesList | null;
  //
  warnings: ProcessingMessagesMap;
  errors: ProcessingMessagesMap;
  skipWarnings: boolean;
  //
  loading: boolean;
};

const AdminContractorEditor = (props: AdminContractorEditorProps) => {
  const { data: originalData, onApply, onCancel, onDelete } = props;
  const {
    store,
    fetchTasteAPI,
    fetchCCCV1API,
    fetchM8API,
    fetchGraphQL,
    showModalError,
    showModalWarning,
    showModalSuccess,
    showConfirmationModal,
    closeConfirmationModal,
    services,
  } = usePLIContext();
  const { programId } = useProgramContext();
  const tasteUrl = services?.taste;

  // state

  const {
    modalState: rerunModalState,
    showModal: showRerunModal,
    closeModal: closeRerunModal,
  } = useModalState();
  const [editorState, setEditorState] = useState<AdminContractorEditorState>({
    changedData: emptyMap as ContractorUpdateDataMap,
    //
    isDescriptionChanged: false,
    isCollectionChanged: false,
    isPublicTitleChanged: false,
    isLimitsAcceptedFlagChanged: false,
    isLocationChanged: false,
    isRegionChanged: false,
    isCountryChanged: false,
    isIndustryChanged: false,
    isWorkerTypeChanged: false,
    isGlobalSupplierSearchFlagChanged: false,
    //
    collectionPublicTitlesData: null,
    collectionLimitsData: null,
    clientsDeliveriesData: null,
    //
    errors: originalData.get("errors") ?? emptyMap,
    warnings: originalData.get("warnings") ?? emptyMap,
    skipWarnings: false,
    //
    loading: false,
  });
  const { changedData, errors, warnings, skipWarnings, loading } = editorState;

  const contractorId = originalData.get("id");
  const status = originalData.get("status");
  const isFinished = status === CONTRACTOR_STATUSES.FINISHED;
  const needApproval = status === CONTRACTOR_STATUSES.NEED_APPROVAL;
  const isRegionBased = !!originalData.get("region");

  const processing = originalData.get("upload")?.get("processing");
  const processingIsRunning = processing?.get("status") === PROCESSING_STATUSES.RUNNING;

  // builtin messaging

  const { messagerRef, showError, showWarning, showSuccess, showHint, hideMessage } =
    useMessagerRef();

  // utils

  const setLoading = useCallback(
    (value) =>
      setEditorState((prevState) => ({
        ...prevState,
        loading: value,
      })),
    []
  );

  const resetEditingState = useCallback(() => {
    setEditorState((prevState) => ({
      ...prevState,
      changedData: emptyMap as ContractorUpdateDataMap,
      //
      isDescriptionChanged: false,
      isCollectionChanged: false,
      isPublicTitleChanged: false,
      isLimitsAcceptedFlagChanged: false,
      isLocationChanged: false,
      isRegionChanged: false,
      isCountryChanged: false,
      isIndustryChanged: false,
      isWorkerTypeChanged: false,
      isGlobalSupplierSearchFlagChanged: false,
      //
      skipWarnings: false,
      loading: false,
    }));
  }, []);

  // data sources

  const retrieveCollectionLimits = useCallback(
    async (
      collectionId: number,
      countryId: number,
      isGlobalSupplierSearchFlag: boolean = false
    ): Promise<CollectionLimitsDataMap | null> => {
      if (collectionId == null || countryId == null) {
        return null;
      }

      let limitsData: CollectionLimitsDataMap | null = null;

      try {
        const response = await fetchTasteAPI(
          "job_title_collection/limits_for_location/",
          {
            method: "post",
            data: {
              collection_id: collectionId,
              location_id: countryId,
              is_global_supplier_search: isGlobalSupplierSearchFlag ? 1 : 0,
            },
          }
        );
        limitsData = fromJS(response.data) as CollectionLimitsDataMap;

        if (limitsData && limitsData.size) {
          setEditorState((prevState) => ({
            ...prevState,
            collectionLimitsData: limitsData,
          }));
        }
      } catch (err: any) {
        logAsyncOperationError("fetchCollectionLimitsData", err);
        setEditorState((prevState) => ({ ...prevState, collectionLimitsData: null }));

        // handle HTTP 400 "Bad Request"
        if (err?.response?.status === 400) {
          const responseData = err.response.data;
          const hasResponseData = !!responseData;
          const responseDataString = hasResponseData ? JSON.stringify(responseData) : "";
          const hasCollectionIdInResponse = hasResponseData
            ? responseDataString.indexOf("" + collectionId) >= 0
            : false;
          const hasDoesntExistPhraseInResponse = hasResponseData
            ? responseDataString.indexOf("object does not exist") >= 0
            : false;
          const hasCollectionIdMessageInResponse = hasResponseData
            ? !!responseData["collection_id"]
            : false;

          if (hasCollectionIdInResponse && hasDoesntExistPhraseInResponse) {
            showModalError(`Collection #${collectionId} does not exist anymore.`);
          } else if (hasCollectionIdMessageInResponse) {
            showModalError(responseData["collection_id"]);
          } else if (hasResponseData) {
            showModalError(responseDataString);
          }
        } else {
          showModalError("Error while retrieving collection limits data.");
        }
      }

      return limitsData;
    },
    [showModalError, fetchTasteAPI]
  );

  type DeliveredClientsResponseData = {
    clientId: number;
    clientTitle: string;
    clientSubscriptionId: number;
    lastDeliveryTimestamp: string;
  };
  type ClientsDeliveriesResponseData = {
    collectionDeliveriesData: {
      deliveredClientsList: DeliveredClientsResponseData[];
    };
  };

  const retrieveClientsDeliveriesData = useCallback(
    async (
      collectionId: number,
      countryId: number
    ): Promise<ImmutableMap<ClientsDeliveriesResponseData> | null> => {
      if (collectionId == null || countryId == null) {
        return null;
      }

      let deliveriesData: ImmutableMap<ClientsDeliveriesResponseData> | null = null;
      let clientsDeliveriesData: ImmutableList<ClientDeliveryMap> | null = null;

      try {
        const response: FetchGraphQLAPIResponse<ClientsDeliveriesResponseData> =
          await fetchTasteAPI("graphql/", {
            method: "post",
            data: {
              query: `
              query getCollectionDeliveriesData($collectionId: Int!, $countryId: String!) {
                collectionDeliveriesData(collectionId: $collectionId, countryId: $countryId) {
                  deliveredClientsList {
                    clientId
                    clientTitle
                    clientSubscriptionId
                    lastDeliveryTimestamp
                  }
                }
              }
            `,
              variables: { collectionId, countryId: countryId.toString() },
            },
          });

        if (logGraphQLError("fetchClientDeliveriesData", response.data)) return null;

        deliveriesData = fromJS(response.data.data);

        if (deliveriesData && deliveriesData.size) {
          clientsDeliveriesData = deliveriesData.getIn([
            "collectionDeliveriesData",
            "deliveredClientsList",
          ]);

          if (clientsDeliveriesData && clientsDeliveriesData.size) {
            setEditorState((prevState) => ({ ...prevState, clientsDeliveriesData }));
          }
        }
      } catch (err) {
        logAsyncOperationError("fetchClientDeliveriesData", err);
        showModalError("Error occurred while retrieving clients deliveries data.");
      }

      return deliveriesData;
    },
    [showModalError, fetchTasteAPI]
  );

  const retrieveCollectionPublicTitles = useCallback(
    async (collectionId: number): Promise<CollectionPublicTitlesList | null> => {
      if (collectionId == null) {
        return null;
      }

      let publicTitlesData: CollectionPublicTitlesList | null = null;

      try {
        const response: FetchAPIResponse<CollectionPublicTitleObject[]> =
          await fetchTasteAPI("raw_title/collection_public_titles/", {
            params: {
              collection_id: collectionId,
              result_size: 10,
            },
          });

        publicTitlesData = fromJS(response.data || []);

        if (publicTitlesData && publicTitlesData.size) {
          setEditorState((prevState) => ({
            ...prevState,
            collectionPublicTitlesData: publicTitlesData,
          }));
        }
      } catch (err) {
        logAsyncOperationError("fetchCollectionPublicTitlesData", err);
        showModalError("Error occurred while retrieving collection public titles.");
      }

      return publicTitlesData;
    },
    [showModalError, fetchTasteAPI]
  );

  const resultingJobDescription = useMemo(
    () => ({
      jobDescription: changedData.get(
        "job_description",
        originalData.get("job_description")
      ),
    }),
    [changedData, originalData]
  );

  type ResultingCollectionMappingsObject = {
    jobTitleId: number | undefined;
    collectionId: number | undefined;
    collectionTitle: string | undefined;
    collectionSlug: string | undefined;
    collectionPublicTitle: string | undefined;
    collectionPublicTitleId: number | undefined;
    collectionPublicTitleDescription: string | undefined;
    collectionLimitsAcceptedFlag: boolean | undefined;
    collectionLimitsId: number | undefined;
  };

  const resultingCollectionMappings = useMemo((): ResultingCollectionMappingsObject => {
    const collectionLimitsData = editorState.collectionLimitsData || emptyMap;

    return {
      jobTitleId: changedData.get(
        "job_title_id",
        originalData.getIn(["processed", "job_title_id"])
      ),
      collectionId: changedData.get(
        "job_collection_id",
        originalData.getIn(["processed", "job_collection_id"])
      ),
      collectionTitle: changedData.get(
        "job_collection_title",
        originalData.getIn(["processed", "job_collection_title"])
      ),
      collectionSlug: changedData.get(
        "job_collection_slug",
        originalData.getIn(["processed", "job_collection_slug"])
      ),
      collectionPublicTitle: changedData.get(
        "job_collection_public_title",
        originalData.getIn(["processed", "job_collection_public_title"])
      ),
      collectionPublicTitleId: changedData.get(
        "job_collection_public_title_id",
        originalData.getIn(["processed", "job_collection_public_title_id"])
      ),
      collectionPublicTitleDescription: changedData.get(
        "job_collection_public_title_description",
        originalData.getIn(["processed", "job_collection_public_title_description"])
      ),
      collectionLimitsAcceptedFlag: changedData.get(
        "collection_limits_client_accepted",
        collectionLimitsData.get("is_client_accepted")
      ),
      collectionLimitsId:
        editorState.isCountryChanged || editorState.isCollectionChanged
          ? collectionLimitsData.get("id")
          : originalData.getIn(["market_analysis", "location_limits_id"]),
    };
  }, [
    changedData,
    originalData,
    editorState.isCountryChanged,
    editorState.isCollectionChanged,
    editorState.collectionLimitsData,
  ]);

  type ResultingIndustryMappingsObject = {
    industryId: number | undefined;
    industryTitle: string | undefined;
  };

  const resultingIndustryMappings = useMemo(
    (): ResultingIndustryMappingsObject => ({
      industryId: changedData.get(
        "industry_id",
        originalData.getIn(["processed", "industry_id"])
      ),
      industryTitle: changedData.get(
        "industry_title",
        originalData.getIn(["processed", "industry_title"])
      ),
    }),
    [changedData, originalData]
  );

  type ResultingWorkerTypeMappingsObject = {
    workerTypeId: number | undefined;
    workerTypeTitle: string | undefined;
  };

  const resultingWorkerTypeMappings = useMemo(
    (): ResultingWorkerTypeMappingsObject => ({
      workerTypeId: changedData.get(
        "worker_type_id",
        originalData.getIn(["processed", "worker_type_id"])
      ),
      workerTypeTitle: changedData.get(
        "worker_type_title",
        originalData.getIn(["processed", "worker_type_title"])
      ),
    }),
    [changedData, originalData]
  );

  type ResultingLocationMappingsObject = {
    city: string | undefined | null;
    state: string | undefined | null;
    country: string;
    region: string | undefined | null;
    locationId: string | undefined;
    regionId: number | undefined;
    countryId: number | undefined;
    locationTitle: string | undefined;
    locationSubtitle: string | undefined;
    locationFullTitle: string | undefined;
    locationFullSubtitle: string | undefined;
    isGlobalSupplierSearchFlag: boolean;
  };

  const resultingLocationMappings = useMemo(
    (): ResultingLocationMappingsObject => ({
      city: changedData.get("city", originalData.get("city")),
      state: changedData.get("state", originalData.get("state")),
      country: changedData.get("country", originalData.get("country")),
      region: changedData.get("region", originalData.get("region")),
      locationId: changedData.get(
        "location_id",
        originalData.get("processed")?.get("location_id")
      ),
      regionId: changedData.get(
        "region_id",
        originalData.get("processed")?.get("region_id")
      ),
      countryId: changedData.get(
        "country_id",
        originalData.get("processed")?.get("country_id")
      ),
      locationTitle: changedData.get("location_title"),
      locationSubtitle: changedData.get("location_subtitle"),
      locationFullTitle: changedData.get("location_full_title"),
      locationFullSubtitle: changedData.get("location_full_subtitle"),
      isGlobalSupplierSearchFlag: changedData.get(
        "is_global_supplier_search",
        originalData.get("is_global_supplier_search", false)
      ),
    }),
    [changedData, originalData]
  );

  const allResultingMappings = useMemo(() => {
    return {
      ...resultingJobDescription,
      ...resultingCollectionMappings,
      ...resultingIndustryMappings,
      ...resultingWorkerTypeMappings,
      ...resultingLocationMappings,
    };
  }, [
    resultingJobDescription,
    resultingCollectionMappings,
    resultingIndustryMappings,
    resultingWorkerTypeMappings,
    resultingLocationMappings,
  ]);

  const hasAnyChanges = useMemo(() => {
    return !!(
      !!editorState.isDescriptionChanged ||
      !!editorState.isCollectionChanged ||
      !!editorState.isPublicTitleChanged ||
      !!editorState.isLimitsAcceptedFlagChanged ||
      !!editorState.isLocationChanged ||
      !!editorState.isRegionChanged ||
      !!editorState.isIndustryChanged ||
      !!editorState.isWorkerTypeChanged ||
      !!editorState.isGlobalSupplierSearchFlagChanged
    );
  }, [
    editorState.isDescriptionChanged,
    editorState.isCollectionChanged,
    editorState.isPublicTitleChanged,
    editorState.isLimitsAcceptedFlagChanged,
    editorState.isLocationChanged,
    editorState.isRegionChanged,
    editorState.isIndustryChanged,
    editorState.isWorkerTypeChanged,
    editorState.isGlobalSupplierSearchFlagChanged,
  ]);

  const collectionStickerValue = useMemo(() => {
    const { collectionId, collectionTitle, collectionLimitsId } =
      resultingCollectionMappings;
    const label =
      collectionTitle != null && collectionId != null
        ? `${collectionTitle} (#${collectionId})`
        : null;

    if (tasteUrl && collectionId && collectionLimitsId && label) {
      const collectionLimitsUrl = `${tasteUrl}job-title-collections/${collectionId}/job-title-collection-boundaries/${collectionLimitsId}/`;

      return (
        <a href={collectionLimitsUrl} target="_blank" rel="noopener noreferrer">
          {label}
        </a>
      );
    } else if (tasteUrl && collectionId && label) {
      const collectionUrl = `${tasteUrl}job-title-collections/${collectionId}/`;

      return (
        <a href={collectionUrl} target="_blank" rel="noopener noreferrer">
          {label}
        </a>
      );
    }

    return label;
  }, [resultingCollectionMappings, tasteUrl]);

  const collectionSelectValue = useMemo((): CollectionSelectValue | null => {
    const { collectionId, collectionTitle } = resultingCollectionMappings;

    return collectionId != null
      ? (Map({
          id: collectionId,
          title: collectionTitle,
        }) as CollectionSelectValue)
      : null;
  }, [resultingCollectionMappings]);

  const publicTitleStickerValue = useMemo(() => {
    const { collectionPublicTitleId, collectionPublicTitle } =
      resultingCollectionMappings;

    const label =
      collectionPublicTitleId != null
        ? `${collectionPublicTitle} (#${collectionPublicTitleId})`
        : null;

    if (tasteUrl && collectionPublicTitleId && label) {
      const collectionPublicTitleUrl = `${tasteUrl}raw-job-titles/${collectionPublicTitleId}`;
      return (
        <a href={collectionPublicTitleUrl} target="_blank" rel="noopener noreferrer">
          {label}
        </a>
      );
    }

    return label;
  }, [resultingCollectionMappings, tasteUrl]);

  const industrySelectValue = useMemo(() => {
    const { industryId, industryTitle } = resultingIndustryMappings;

    if (industryId == null) {
      return null;
    }

    return {
      id: industryId,
      title: industryTitle,
    };
  }, [resultingIndustryMappings]);

  const workerTypeSelectValue = useMemo((): WorkerTypeSelectValue | undefined => {
    const { workerTypeId, workerTypeTitle } = resultingWorkerTypeMappings;

    if (workerTypeId == null || workerTypeTitle == null) {
      return undefined;
    }

    return {
      id: workerTypeId,
      name: workerTypeTitle,
    };
  }, [resultingWorkerTypeMappings]);

  const locationSelectValue = useMemo(() => {
    let {
      city,
      state,
      country,
      countryId,
      locationId,
      locationTitle,
      locationSubtitle,
      locationFullTitle,
      locationFullSubtitle,
    } = resultingLocationMappings;
    let type = null;

    if (city) {
      type = "city";
      if (locationTitle == null) {
        locationTitle = city?.trim?.();
      }
      if (locationSubtitle == null) {
        locationSubtitle = [state, country]
          .filter((i) => !!i)
          .map((i) => i?.trim?.())
          .join(", ");
      }
    } else if (state) {
      type = "state";
      if (locationTitle == null) {
        locationTitle = state?.trim?.();
      }
      if (locationSubtitle == null) {
        locationSubtitle = country?.trim?.();
      }
    } else if (country) {
      type = "country";
      if (locationTitle == null) {
        locationTitle = country?.trim?.();
      }
    }

    if (locationFullTitle == null && locationTitle != null) {
      locationFullTitle = locationTitle;
    }
    if (locationFullSubtitle == null && locationSubtitle != null) {
      locationFullSubtitle = locationSubtitle;
    }

    return {
      // we need some fake value for ID to fill select component with initial value
      location_id: locationId || "",
      country_id: countryId || "",
      type: type!,
      title: locationTitle,
      subtitle: locationSubtitle,
      full_title: locationFullTitle,
      full_subtitle: locationFullSubtitle,
    };
  }, [resultingLocationMappings]);

  const regionSelectValue = useMemo(() => {
    const { region, country, regionId, countryId } = resultingLocationMappings;

    if (!region) {
      return null;
    }

    return {
      // we need some fake value for ID to fill select component with initial value
      region_id: regionId || "",
      country_id: countryId || "",
      name: region,
      country_name: country,
    };
  }, [resultingLocationMappings]);

  const validateEditorData = useCallback(() => {
    const { collectionId, industryId, locationId, region, regionId } =
      allResultingMappings;

    if (collectionId == null) {
      showModalWarning("Please select a collection.");
      return false;
    }
    if (industryId == null) {
      showModalWarning("Please select an industry.");
      return false;
    }
    if (!region && locationId == null) {
      showModalWarning("Please select a location");
      return false;
    }
    if (region && regionId == null) {
      showModalWarning("Please select a region");
      return false;
    }

    return true;
  }, [allResultingMappings, showModalWarning]);

  // handlers

  const handleCollectionRemove = useCallback(() => {
    setEditorState((prevState: AdminContractorEditorState) => ({
      ...prevState,
      changedData: prevState.changedData
        .set("job_title_id", null)
        .set("job_collection_id", null)
        .set("job_collection_title", null)
        .set("job_collection_slug", null)
        .set("job_collection_public_title", null)
        .set("job_collection_public_title_id", null)
        .set("job_collection_public_title_description", null)
        .delete("collection_limits_client_accepted"),
      collectionLimitsData: null,
      collectionPublicTitlesData: null,
      isLimitsAcceptedFlagChanged: false,
      isCollectionChanged: true,
      skipWarnings: false,
    }));
  }, []);

  const handlePublicTitleRemove = useCallback(() => {
    setEditorState((prevState) => ({
      ...prevState,
      isPublicTitleChanged: false,
      changedData: prevState.changedData
        .set("job_collection_public_title", null)
        .set("job_collection_public_title_id", null)
        .set("job_collection_public_title_description", null),
    }));
    showWarning("Don't forget to apply changes!");
  }, [showWarning]);

  const prevPublicTitleId = originalData
    .get("processed")
    ?.get("job_collection_public_title_id");
  const currentPublicTitleId = changedData.get("job_collection_public_title_id");

  const handlePublicTitleSelect = useCallback(
    (value: CollectionPublicTitleMap) => {
      if (value && value.size) {
        const newPublicTitleId = value.get("id");
        const newPublicTitle = value.get("title");
        const newPublicTitleDescription = value.get("description");

        const currentPublicTitleIsChanged = !equalAsStrings(
          currentPublicTitleId,
          newPublicTitleId
        );
        const prevPublicTitleIsChanged = !equalAsStrings(
          prevPublicTitleId,
          newPublicTitleId
        );

        if (currentPublicTitleIsChanged) {
          setEditorState((prevState) => ({
            ...prevState,
            changedData: prevState.changedData
              .set("job_collection_public_title", newPublicTitle)
              .set("job_collection_public_title_id", newPublicTitleId)
              .set("job_collection_public_title_description", newPublicTitleDescription),
            isPublicTitleChanged: prevPublicTitleIsChanged,
          }));
          showWarning("Don't forget to apply changes!");
        }
      }
    },
    [currentPublicTitleId, prevPublicTitleId, showWarning]
  );

  const prevJobTitleId = originalData.get("processed")?.get("job_title_id");
  const prevCollectionId = originalData.get("processed")?.get("job_collection_id");
  const currentCollectionId = changedData.get("job_collection_id");
  const isLimitsAcceptedFlagChanged = editorState.isLimitsAcceptedFlagChanged;

  type CollectionSelectValue = ImmutableMap<{
    id: number;
    title: string;
    slug?: string;
  }>;

  const handleCollectionSelect = useCallback(
    (value: CollectionSelectValue) => {
      if (value && value.size) {
        const newCollectionId = value.get("id");
        const newCollectionTitle = value.get("title");
        const newCollectionSlug = value.get("slug");
        const isCurrentCollectionChanged = !equalAsStrings(
          currentCollectionId,
          newCollectionId
        );
        const isPrevCollectionChanged = !equalAsStrings(
          prevCollectionId,
          newCollectionId
        );

        // if collection has changed
        if (isCurrentCollectionChanged) {
          // we can't change Collection after "Client Accepted" flag is changed
          if (isLimitsAcceptedFlagChanged) {
            showModalWarning(
              `Can't change the Collection after the "Client Accepted" flag is changed. Please start from changing Collection first.`,
              "Important!",
              3000
            );
            return false;
          }

          setEditorState((prevState) => ({
            ...prevState,
            changedData: prevState.changedData
              .set("job_title_id", isPrevCollectionChanged ? null : prevJobTitleId)
              .set("job_collection_id", newCollectionId)
              .set("job_collection_title", newCollectionTitle)
              .set("job_collection_slug", newCollectionSlug)
              .set("job_collection_public_title", null)
              .set("job_collection_public_title_id", null)
              .set("job_collection_public_title_description", null)
              .delete("collection_limits_client_accepted"),
            collectionLimitsData: null,
            collectionPublicTitlesData: null,
            isCollectionChanged: isPrevCollectionChanged,
            isPublicTitleChanged: false,
            isLimitsAcceptedFlagChanged: false,
            skipWarnings: false,
          }));
          showWarning("Don't forget to apply changes!");
        }
      }
    },
    [
      currentCollectionId,
      prevJobTitleId,
      prevCollectionId,
      isLimitsAcceptedFlagChanged,
      showWarning,
      showModalWarning,
    ]
  );

  const collectionLimitsData = editorState.collectionLimitsData || emptyMap;
  const prevIsLimitsAcceptedFlag = collectionLimitsData.get("is_client_accepted");
  const currentIsLimitsAcceptedFlag = changedData.get(
    "collection_limits_client_accepted"
  );
  const isGlobalSupplierSearchFlagChanged = editorState.isGlobalSupplierSearchFlagChanged;

  const handleLimitsAcceptedChange = useCallback(
    (checked: CheckedState) => {
      if (isGlobalSupplierSearchFlagChanged) {
        showModalWarning(
          `Can't update both flags "Client Accepted" and "Global Supplier Search" at the same time.`,
          "Important!",
          3000
        );
        return;
      }

      const prevIsLimitsAcceptedFlagIsChanged = prevIsLimitsAcceptedFlag !== checked;
      const currentIsLimitsAcceptedFlagIsChanged =
        currentIsLimitsAcceptedFlag !== checked;

      if (currentIsLimitsAcceptedFlagIsChanged) {
        setEditorState((prevState) => ({
          ...prevState,
          changedData: prevIsLimitsAcceptedFlagIsChanged
            ? prevState.changedData.set(
                "collection_limits_client_accepted",
                checked as boolean
              )
            : prevState.changedData.delete("collection_limits_client_accepted"),
          isLimitsAcceptedFlagChanged: prevIsLimitsAcceptedFlagIsChanged,
        }));
        showWarning("Don't forget to apply changes!");

        if (prevIsLimitsAcceptedFlagIsChanged) {
          showModalWarning(
            `This action will change "Limits Accepted" flag in the Taste for the selected Collection/Country. Don't forget to apply changes!`,
            "Important!",
            4000
          );
        }
      }
    },
    [
      currentIsLimitsAcceptedFlag,
      prevIsLimitsAcceptedFlag,
      isGlobalSupplierSearchFlagChanged,
      showModalWarning,
      showWarning,
    ]
  );

  const currentIsGlobalSupplierSearchFlag = changedData.get("is_global_supplier_search");
  const prevIsGlobalSupplierSearchFlag = originalData.get(
    "is_global_supplier_search",
    false
  );

  const handleGlobalSupplierSearchFlagChange = useCallback(
    (checked: CheckedState) => {
      if (isLimitsAcceptedFlagChanged) {
        showModalWarning(
          `Can't update both flags "Client Accepted" and "Global Supplier Search" at the same time.`,
          "Important!",
          3000
        );
        return;
      }

      const prevIsGlobalSupplierSearchFlagChanged =
        prevIsGlobalSupplierSearchFlag !== checked;
      const currentIsGlobalSupplierSearchFlagChanged =
        currentIsGlobalSupplierSearchFlag !== checked;

      if (currentIsGlobalSupplierSearchFlagChanged) {
        setEditorState((prevState) => ({
          ...prevState,
          changedData: prevState.changedData.set(
            "is_global_supplier_search",
            checked as boolean
          ),
          collectionLimitsData: null,
          isGlobalSupplierSearchFlagChanged: prevIsGlobalSupplierSearchFlagChanged,
        }));
        showWarning("Don't forget to apply changes!");
        if (prevIsGlobalSupplierSearchFlagChanged) {
          showModalWarning(
            `This action will change "Global Supplier Search" flag for the row, and adjust Rates as well. Don't forget to apply changes!`,
            "Important!",
            4000
          );
        }
      }
    },
    [
      currentIsGlobalSupplierSearchFlag,
      prevIsGlobalSupplierSearchFlag,
      isLimitsAcceptedFlagChanged,
      showModalWarning,
      showWarning,
    ]
  );

  const prevIndustryId = originalData.get("processed")?.get("industry_id");
  const currentIndustryId = changedData.get("industry_id");

  const handleIndustryChange = useCallback(
    (value: { id: number; title: string }) => {
      if (value?.id == null) return;

      const newIndustryId = value.id;
      const newIndustryTitle = value.title;

      const prevIndustryIsChanged = !equalAsStrings(prevIndustryId, newIndustryId);
      const currentIndustryIsChanged = !equalAsStrings(currentIndustryId, newIndustryId);

      if (!currentIndustryIsChanged) return;

      setEditorState((prevState) => ({
        ...prevState,
        changedData: prevState.changedData
          .set("industry_id", newIndustryId)
          .set("industry_title", newIndustryTitle),
        isIndustryChanged: prevIndustryIsChanged,
        skipWarnings: false,
      }));
      showWarning("Don't forget to apply changes!");
    },
    [currentIndustryId, prevIndustryId, showWarning]
  );

  const prevWorkerTypeId = originalData.get("processed")?.get("worker_type_id");
  const currentWorkerTypeId = changedData.get("worker_type_id");

  const handleWorkerTypeChange: WorkerTypeSelectProps["onChange"] = useCallback(
    (value: ValueType<WorkerTypeSelectValue>) => {
      const cleanValue: WorkerTypeSelectValue | null | undefined = Array.isArray(value)
        ? value[0]
        : value;
      const newWorkerTypeId = cleanValue?.id;
      const newWorkerTypeTitle = cleanValue?.name;

      const prevWorkerTypeIsChanged = !equalAsStrings(prevWorkerTypeId, newWorkerTypeId);
      const currentWorkerTypeIsChanged = !equalAsStrings(
        currentWorkerTypeId,
        newWorkerTypeId
      );

      if (!currentWorkerTypeIsChanged) return;

      setEditorState((prevState) => ({
        ...prevState,
        changedData: prevState.changedData
          .set("worker_type_id", newWorkerTypeId)
          .set("worker_type_title", newWorkerTypeTitle),
        isWorkerTypeChanged: prevWorkerTypeIsChanged,
        skipWarnings: false,
      }));
      showWarning("Don't forget to apply changes!");
    },
    [currentWorkerTypeId, prevWorkerTypeId, showWarning]
  );

  const prevLocationId: string = originalData.getIn(["processed", "location_id"]);
  const currentLocationId = changedData.get("location_id");
  const prevCountryId: string = originalData.getIn(["processed", "country_id"]);
  const currentCountryId = changedData.get("country_id");

  type LocationSelectValue = {
    location_id: string;
    country_id: number;
    title: string;
    subtitle: string;
    full_title: string;
    full_subtitle: string;
  };

  const handleLocationChange = useCallback(
    async (value: LocationSelectValue) => {
      if (value?.location_id == null) return;

      const newLocationId = value["location_id"];
      const newCountryId = value["country_id"];

      const prevLocationIsChanged = !equalAsStrings(prevLocationId, newLocationId);
      const currentLocationIsChanged = !equalAsStrings(currentLocationId, newLocationId);
      const prevCountryIsChanged = !equalAsStrings(prevCountryId, newCountryId);
      const currentCountryIsChanged = !equalAsStrings(currentCountryId, newCountryId);

      if (newLocationId == null || newCountryId == null || !currentLocationIsChanged)
        return;

      // we can't change country after "Client Accepted" flag is changed
      if (isLimitsAcceptedFlagChanged && currentCountryIsChanged) {
        showModalWarning(
          `Can't change the Country after the "Client Accepted" flag is changed. Please start from changing Country first.`,
          "Important!",
          3000
        );
        return;
      }

      setLoading(true);
      showHint("checking location...");

      const isLegacyLocation = ("" + newLocationId).startsWith("ll:");
      const locationPathUrl = `${
        isLegacyLocation ? "legacy_locations" : "locations"
      }/${encodeURIComponent(newLocationId)}/path/`;

      try {
        // check location path call
        const response = await fetchCCCV1API(locationPathUrl);
        const newLocationPath = response.data;

        setEditorState((prevState: AdminContractorEditorState) => {
          let nextChangedData = prevState.changedData
            .set("city", newLocationPath["city"])
            .set("state", newLocationPath["state"])
            .set("country", newLocationPath["country"])
            .set("location_id", newLocationId)
            .set("country_id", newCountryId)
            .set("location_title", value["title"])
            .set("location_subtitle", value["subtitle"])
            .set("location_full_title", value["full_title"])
            .set("location_full_subtitle", value["full_subtitle"]);
          if (currentCountryIsChanged) {
            nextChangedData = nextChangedData.delete("collection_limits_client_accepted");
          }

          return {
            ...prevState,
            changedData: nextChangedData,
            isLimitsAcceptedFlagChanged: currentCountryIsChanged
              ? false
              : isLimitsAcceptedFlagChanged,
            isLocationChanged: prevLocationIsChanged,
            isCountryChanged: prevCountryIsChanged,
            skipWarnings: false,
            loading: false,
          };
        });
        showWarning("Don't forget to apply changes!");

        return newLocationPath;
      } catch (err) {
        logAsyncOperationError("fetchLocationData", err);
        showModalError("Error occurred while retrieving location data.");
        setLoading(false);
        hideMessage();
      }
    },
    [
      prevLocationId,
      currentLocationId,
      prevCountryId,
      currentCountryId,
      isLimitsAcceptedFlagChanged,
      setLoading,
      showHint,
      showWarning,
      hideMessage,
      showModalWarning,
      showModalError,
      fetchCCCV1API,
    ]
  );

  const prevRegionId = originalData.getIn(["processed", "region_id"]);
  const currentRegionId = changedData.get("region_id");

  type RegionSelectValue = {
    region_id: number;
    name: string;
    country_id: number;
    country_name: string;
  };

  const handleRegionChange = useCallback(
    (value: RegionSelectValue) => {
      if (value?.region_id == null) return;

      const newRegionId = value["region_id"];
      const newRegionName = value["name"];

      const prevRegionIsChanged = !equalAsStrings(prevRegionId, newRegionId);
      const currentRegionIsChanged = !equalAsStrings(currentRegionId, newRegionId);

      if (newRegionId == null || !currentRegionIsChanged) return;

      const newCountryId = value["country_id"];
      const newCountryName = value["country_name"];

      // we can't change region if its country data is still NOT available
      if (newCountryId == null || newCountryName == null) {
        showModalError(
          "Not all Region data available for further update, please contact support team."
        );
        return;
      }

      const prevCountryIsChanged = !equalAsStrings(prevCountryId, newCountryId);
      const currentCountryIsChanged = !equalAsStrings(currentCountryId, newCountryId);

      // we can't change country after "Client Accepted" flag is changed
      if (isLimitsAcceptedFlagChanged && currentCountryIsChanged) {
        showModalWarning(
          `Can't change the Country after the "Client Accepted" flag is changed. Please start from changing Country first.`,
          "Important!",
          3000
        );
        return;
      }

      setEditorState((prevState) => {
        let nextChangedData = prevState.changedData
          .set("region", newRegionName)
          .set("country", newCountryName)
          .set("region_id", newRegionId)
          .set("country_id", newCountryId);
        if (currentCountryIsChanged) {
          nextChangedData = nextChangedData.delete("collection_limits_client_accepted");
        }

        return {
          ...prevState,
          changedData: nextChangedData,
          isRegionChanged: prevRegionIsChanged,
          isCountryChanged: prevCountryIsChanged,
          isLimitsAcceptedFlagChanged: currentCountryIsChanged
            ? false
            : isLimitsAcceptedFlagChanged,
          skipWarnings: false,
        };
      });
      showWarning("Don't forget to apply changes!");
    },
    [
      currentRegionId,
      currentCountryId,
      prevRegionId,
      prevCountryId,
      isLimitsAcceptedFlagChanged,
      showWarning,
      showModalError,
      showModalWarning,
    ]
  );

  const currentDescription = changedData.get("job_description");
  const prevDescription = originalData.get("job_description");

  const handleJobDescriptionChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const value = e.target.value;
      const prevDescriptionIsChanged = !equalAsStrings(prevDescription, value);
      const currentDescriptionIsChanged = !equalAsStrings(currentDescription, value);

      if (currentDescriptionIsChanged) {
        // we can't change Description after "Client Accepted" flag is changed
        if (isLimitsAcceptedFlagChanged) {
          showModalWarning(
            `Can't change the Job Description after the "Client Accepted" flag is changed. Please start from changing Job Description first.`,
            "Important!",
            3000
          );
          return;
        }

        setEditorState((prevState) => ({
          ...prevState,
          changedData: prevState.changedData.set("job_description", value),
          isDescriptionChanged: prevDescriptionIsChanged,
          skipWarnings: false,
        }));
        showWarning("Don't forget to apply changes!");
      }
    },
    [
      currentDescription,
      prevDescription,
      isLimitsAcceptedFlagChanged,
      showWarning,
      showModalWarning,
    ]
  );

  const handleSkipWarnings = useCallback(() => {
    if (editorState.errors.size) {
      return;
    }
    if (editorState.warnings.size) {
      setEditorState((prevState) => ({
        ...prevState,
        skipWarnings: true,
      }));
      showSuccess("All problems are fixed, now you can apply changes.");
    }
  }, [editorState.errors.size, editorState.warnings.size, showSuccess]);

  const updatePayload = useMemo(() => {
    const payload: ContractorUpdateDataObject = {
      job_title_id: allResultingMappings.jobTitleId,
      job_collection_id: allResultingMappings.collectionId,
      job_collection_title: allResultingMappings.collectionTitle,
      job_collection_slug: allResultingMappings.collectionSlug,
      job_collection_public_title: allResultingMappings.collectionPublicTitle,
      job_collection_public_title_id: allResultingMappings.collectionPublicTitleId,
      job_collection_public_title_description:
        allResultingMappings.collectionPublicTitleDescription,
      industry_id: allResultingMappings.industryId,
      industry_title: allResultingMappings.industryTitle,
      worker_type_id: allResultingMappings.workerTypeId,
      worker_type_title: allResultingMappings.workerTypeTitle,
    };

    if (isRegionBased) {
      payload["region"] = allResultingMappings.region;
      payload["country"] = allResultingMappings.country;
      payload["region_id"] = allResultingMappings.regionId;
      payload["country_id"] = allResultingMappings.countryId;
      payload["is_global_supplier_search"] =
        allResultingMappings.isGlobalSupplierSearchFlag;
    } else {
      payload["city"] = allResultingMappings.city;
      payload["state"] = allResultingMappings.state;
      payload["country"] = allResultingMappings.country;
      payload["location_id"] = allResultingMappings.locationId;
      payload["country_id"] = allResultingMappings.countryId;
      payload["is_global_supplier_search"] =
        allResultingMappings.isGlobalSupplierSearchFlag;
    }

    if (editorState.isDescriptionChanged) {
      payload["job_description"] = currentDescription;
    }
    if (editorState.isLimitsAcceptedFlagChanged) {
      payload["collection_limits_client_accepted"] = currentIsLimitsAcceptedFlag;
    }

    return payload;
  }, [
    allResultingMappings,
    currentDescription,
    currentIsLimitsAcceptedFlag,
    editorState.isDescriptionChanged,
    editorState.isLimitsAcceptedFlagChanged,
    isRegionBased,
  ]);

  type ValidationResponseData = {
    errors: ProcessingMessageItemObject[];
    warnings: ProcessingMessageItemObject[];
  };
  type ValidationDataMap = ImmutableMap<{
    errors: ProcessingMessagesMap;
    warnings: ProcessingMessagesMap;
  }>;

  const validateResultingMappings = React.useCallback(
    async (updatePayload: ContractorUpdateDataObject) => {
      showHint("Checking data...");

      const currentErrors = editorState.errors;
      const currentWarnings = editorState.warnings;
      let nextErrors = emptyMap as ProcessingMessagesMap;
      let nextWarnings = emptyMap as ProcessingMessagesMap;

      try {
        const validationResponse: FetchAPIResponse<ValidationResponseData> =
          await fetchM8API(
            `programs/${programId}/contractors/${contractorId}/validate_mappings/`,
            { method: "post", data: updatePayload }
          );

        const validationData: ValidationDataMap = fromJS(validationResponse.data);
        const validationErrors = (validationData.get("errors") ??
          emptyMap) as ProcessingMessagesMap;
        const validationWarnings = (validationData.get("warnings") ??
          emptyMap) as ProcessingMessagesMap;

        if (validationErrors.size || validationWarnings.size) {
          if (validationErrors.size) {
            showError("This row still has some errors!");
          } else if (validationWarnings.size) {
            showError("This row still has some warnings!");
          }

          nextErrors = validationErrors.size
            ? currentErrors.merge(validationErrors)
            : (emptyMap as ProcessingMessagesMap);
          nextWarnings = validationWarnings.size
            ? currentWarnings.merge(validationWarnings)
            : (emptyMap as ProcessingMessagesMap);

          setEditorState((prevState) => ({
            ...prevState,
            errors: nextErrors,
            warnings: nextWarnings,
          }));
        }
      } catch (err: any) {
        logAsyncOperationError("validateMappings", err);
        showModalError("Error occurred during contractor mappings validation.");
      } finally {
        hideMessage();
      }

      return { errors: nextErrors, warnings: nextWarnings };
    },
    [
      editorState.errors,
      editorState.warnings,
      contractorId,
      programId,
      fetchM8API,
      showHint,
      showError,
      hideMessage,
      showModalError,
    ]
  );

  const handleApplyChanges = useCallback(async () => {
    if (!validateEditorData()) return;

    if (!editorState.skipWarnings) {
      const { errors, warnings } = await validateResultingMappings(updatePayload);
      if (errors.size || warnings.size) return;
    }

    showHint("updating row...");
    setEditorState((prevState) => ({
      ...prevState,
      errors: emptyMap as ProcessingMessagesMap,
      warnings: emptyMap as ProcessingMessagesMap,
    }));

    try {
      const updateResponse: FetchAPIResponse<ContractorDataObject> = await fetchM8API(
        `programs/${programId}/contractors/${contractorId}/update_mappings/`,
        { method: "post", data: updatePayload }
      );
      const updateData: ContractorDataMap = contractorItemToImmutableMap(
        updateResponse.data
      );
      const status: CONTRACTOR_STATUSES_TYPE = updateData.get("status");
      const errors: ProcessingMessagesMap =
        updateData.get("errors") || (emptyMap as ProcessingMessagesMap);

      if (status === CONTRACTOR_STATUSES.FAILED && errors.size > 0) {
        await showModalWarning(
          <div>
            <label>
              Row failed processing with the following errors and has been moved to the
              Failed Rows List:
            </label>
            <p>
              {errors
                .valueSeq()
                .toArray()
                .map((error: ProcessingMessageItemMap, idx: number) => {
                  return (
                    <div key={idx}>
                      <Icon icon="exclamation" /> {error.get("message")}
                    </div>
                  );
                })}
            </p>
          </div>,
          "Warning!",
          10000
        );
        await ensurePromise(onCancel(true));
      } else {
        await ensurePromise(onApply());
        resetEditingState();
        showSuccess("Row has been successfully updated.");
      }
    } catch (err: any) {
      hideMessage();
      logAsyncOperationError("updateMappings", err);
      showModalError("Error occurred during contractor mappings update.");
    }
  }, [
    editorState.skipWarnings,
    updatePayload,
    validateEditorData,
    validateResultingMappings,
    resetEditingState,
    programId,
    contractorId,
    onApply,
    onCancel,
    showSuccess,
    showHint,
    hideMessage,
    showModalError,
    showModalWarning,
    fetchM8API,
  ]);

  const handleRunDataRefresh = useCallback(
    async (includeRemapping: boolean) => {
      try {
        const response: FetchAPIResponse<ContractorDataObject> = await fetchM8API(
          `programs/${programId}/contractors/${contractorId}/reprocess/`,
          {
            method: "post",
            params: { __include_remapping: includeRemapping },
          }
        );
        const data: ContractorDataMap = contractorItemToImmutableMap(response.data);
        const status = data.get("status");

        closeRerunModal();
        if (status === CONTRACTOR_STATUSES.FINISHED) {
          showModalSuccess("Row has been successfully reprocessed.");
        } else if (status === CONTRACTOR_STATUSES.FAILED) {
          showModalWarning("Row still has some problems.");
        } else if (status === CONTRACTOR_STATUSES.NEED_APPROVAL) {
          showModalWarning("Mapping requires your approval.");
        }
        onApply();

        return data;
      } catch (err) {
        logAsyncOperationError("runContractorProcessing", err);
        showModalError("Error occurred while contractor reprocessing.");
      }
    },
    [
      contractorId,
      programId,
      fetchM8API,
      onApply,
      closeRerunModal,
      showModalSuccess,
      showModalWarning,
      showModalError,
    ]
  );

  const handleRerunRow = React.useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      return showRerunModal();
    },
    [showRerunModal]
  );

  const handleRunPublicTitlesRefresh = useCallback(async () => {
    try {
      const response: FetchAPIResponse<any> = await fetchM8API(
        `programs/${programId}/contractors/run_public_titles_refresh/`,
        {
          method: "post",
          data: { ids_to_refresh: [contractorId] },
        }
      );
      const data = fromJS(response.data);

      showModalSuccess("Public title has been successfully refreshed.");
      if (onApply) onApply();

      return data;
    } catch (err) {
      logAsyncOperationError("runPublicTitleRefresh", err);
      showModalError("Error occurred while public title refreshing.");
    }
  }, [programId, contractorId, onApply, showModalSuccess, showModalError, fetchM8API]);

  const handleApproveRow = useCallback(async () => {
    try {
      const response: FetchAPIResponse = await fetchM8API(
        `programs/${programId}/contractors/${contractorId}/approve_mappings/`,
        {
          method: "post",
        }
      );
      const data = response.data;

      await closeConfirmationModal();
      await (onCancel ? onCancel(true) : Promise.resolve());

      return data;
    } catch (err) {
      logAsyncOperationError("approveMappings", err);
      showModalError("Error occurred during row approval.");
    }
  }, [
    programId,
    contractorId,
    onCancel,
    showModalError,
    closeConfirmationModal,
    fetchM8API,
  ]);

  const originalJobTitle = originalData.get("job_title");
  const originalJobDescription = originalData.get("job_description");

  const handleConfirmRowApproval = useCallback(() => {
    const header = "Confirm row approval";
    const message = (
      <div>
        <span>
          You are about to approve the following row.
          <br />
          <b>{`- #${contractorId} ${originalJobTitle}`}</b>
          <br />
          <br />
          <i>
            <b>
              <u>IMPORTANT:</u>
            </b>
            &nbsp; this action will affect all similar titles in the index.
          </i>
        </span>
      </div>
    );
    const footer = (
      <ButtonGroupRight fill css={{ flexDirection: "row-reverse" }}>
        <Button size="large" onClick={closeConfirmationModal}>
          Cancel
        </Button>
        <PromiseButton
          icon="thumbs-up"
          size="large"
          color="brand"
          loadingText="Approve Row"
          onClick={handleApproveRow}
        >
          Approve Row
        </PromiseButton>
      </ButtonGroupRight>
    );

    return showConfirmationModal(message, header, footer);
  }, [
    contractorId,
    originalJobTitle,
    handleApproveRow,
    showConfirmationModal,
    closeConfirmationModal,
  ]);

  const handleDeleteRow = useCallback(async () => {
    try {
      const response: FetchAPIResponse<ContractorDataObject> = await fetchM8API(
        `programs/${programId}/contractors/${contractorId}/`,
        {
          method: "delete",
        }
      );
      const data = response.data;

      showModalSuccess(
        ` Row #${contractorId} "${originalJobTitle}" has been successfully deleted.`
      );
      await closeConfirmationModal();
      await (onDelete ? onDelete(contractorId) : Promise.resolve());

      return data;
    } catch (err) {
      logAsyncOperationError("deleteContractor", err);
      showModalError("Error occurred during Contractor row deletion.");
    }
  }, [
    programId,
    contractorId,
    originalJobTitle,
    onDelete,
    showModalSuccess,
    showModalError,
    closeConfirmationModal,
    fetchM8API,
  ]);

  const handleConfirmRowDeletion = useCallback(() => {
    const header = "Confirm delete action";
    const message = (
      <div>
        <span>
          Do you really want to delete the following row? <br />
          <b>{`- #${contractorId} ${originalJobTitle}`}</b>
        </span>
      </div>
    );
    const footer = (
      <ButtonGroupRight fill css={{ flexDirection: "row-reverse" }}>
        <Button size="large" onClick={closeConfirmationModal}>
          Cancel
        </Button>
        <PromiseButton
          icon={["far", "trash-alt"]}
          color="danger"
          size="large"
          loadingText="Delete Row"
          onClick={handleDeleteRow}
        >
          Delete Row
        </PromiseButton>
      </ButtonGroupRight>
    );

    return showConfirmationModal(message, header, footer);
  }, [
    contractorId,
    originalJobTitle,
    handleDeleteRow,
    showConfirmationModal,
    closeConfirmationModal,
  ]);

  const handleCloseEditor = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (onCancel) onCancel(false);
    },
    [onCancel]
  );

  // effects

  const prevCollectionIdRef = useRef(allResultingMappings.collectionId);

  // public titles related effects
  useEffect(() => {
    const hasCollectionMapping = allResultingMappings.collectionId != null;
    const collectionHasChanged =
      allResultingMappings.collectionId !== prevCollectionIdRef.current;
    const needPublicTitlesRefresh =
      collectionHasChanged ||
      !editorState.collectionPublicTitlesData ||
      !editorState.collectionPublicTitlesData.size;

    if (hasCollectionMapping && needPublicTitlesRefresh) {
      retrieveCollectionPublicTitles(allResultingMappings.collectionId!).then(
        (publicTitlesData) => {
          if (publicTitlesData && publicTitlesData.size && collectionHasChanged) {
            handlePublicTitleSelect(publicTitlesData.first());
          }
        }
      );
    }

    prevCollectionIdRef.current = allResultingMappings.collectionId;
  }, [
    allResultingMappings.collectionId,
    editorState.collectionPublicTitlesData,
    retrieveCollectionPublicTitles,
    handlePublicTitleSelect,
  ]);

  // collection limits related effects
  useEffect(() => {
    if (
      allResultingMappings.collectionId != null &&
      allResultingMappings.countryId != null
    ) {
      retrieveCollectionLimits(
        allResultingMappings.collectionId,
        allResultingMappings.countryId,
        allResultingMappings.isGlobalSupplierSearchFlag
      );
    }
  }, [
    allResultingMappings.collectionId,
    allResultingMappings.countryId,
    allResultingMappings.isGlobalSupplierSearchFlag,
    retrieveCollectionLimits,
  ]);

  // deliveries related effects
  useEffect(() => {
    if (
      allResultingMappings.collectionId != null &&
      allResultingMappings.countryId != null
    ) {
      retrieveClientsDeliveriesData(
        allResultingMappings.collectionId,
        allResultingMappings.countryId
      );
    }
  }, [
    allResultingMappings.collectionId,
    allResultingMappings.countryId,
    retrieveClientsDeliveriesData,
  ]);

  const {
    jobDescription,
    collectionId,
    countryId,
    country,
    region,
    isGlobalSupplierSearchFlag,
    collectionLimitsAcceptedFlag,
  } = allResultingMappings;

  return (
    <Stack fill css={{ alignItems: "flex-start" }}>
      <ProblemsBlock needApproval={needApproval} warnings={warnings} errors={errors} />
      <Stack
        nogap
        css={{
          alignItems: "flex-start",
          padding: "0 $9",
          width: "500px",
          "@lg": {
            width: "800px",
          },
        }}
      >
        <Text as="h4">Use the fields below to fix/edit mappings:</Text>
        <EditorSegment>
          <label>
            Job Description (
            <Text italic>
              <Text bold underline>
                IMPORTANT
              </Text>
              : &nbsp;system will search and fix others exactly same
              title/description/source rows
            </Text>
            ):
          </label>
          <IndentedStack>
            <TextArea
              fill
              size="small"
              css={{ minHeight: "100px", borderWidth: "1px" }}
              value={jobDescription}
              onChange={handleJobDescriptionChange}
            />
          </IndentedStack>
        </EditorSegment>
        <EditorSegment>
          <label>
            Job Title Collection (
            <Text italic>
              <Text bold underline>
                IMPORTANT
              </Text>
              : &nbsp;system will search and fix others exactly same
              title/description/source rows
            </Text>
            ):
          </label>
          <IndentedStack css={{ gap: "$3" }}>
            <Sticker
              value={collectionStickerValue}
              onRemove={handleCollectionRemove}
              removable
            />
            <JobCollectionSelect
              className="full-width"
              fetchTasteAPI={fetchTasteAPI}
              searchPlaceholder="Search by id or title..."
              value={collectionSelectValue}
              onSelect={handleCollectionSelect}
              disabled={collectionId != null}
            />
            <SuggestedCollectionsTable
              jobTitle={originalJobTitle}
              jobDescription={originalJobDescription}
              onSelect={handleCollectionSelect}
            />
            <CollectionLimits
              collectionId={collectionId}
              countryId={countryId}
              country={country}
              isLimitsAccepted={collectionLimitsAcceptedFlag}
              contractorData={originalData}
              collectionLimitsData={
                editorState.collectionLimitsData || (emptyMap as CollectionLimitsDataMap)
              }
              isDescriptionChanged={editorState.isDescriptionChanged}
              isCollectionChanged={editorState.isCollectionChanged}
              isIndustryChanged={editorState.isIndustryChanged}
              isCountryChanged={editorState.isCountryChanged}
              onChange={handleLimitsAcceptedChange}
            />
          </IndentedStack>
        </EditorSegment>
        <EditorSegment>
          <label>Confidence Score Details:</label>
          <IndentedStack css={{ gap: "$1" }}>
            {collectionId == null && (
              <Text>
                <Text italic bold underline>
                  Hint:
                </Text>
                &nbsp;Collection should be selected first.
              </Text>
            )}
            {countryId == null && (
              <Text>
                <Text italic bold underline>
                  Hint:
                </Text>
                &nbsp;Location/Region issues should be fixed first.
              </Text>
            )}
            {collectionId != null && countryId != null && (
              <ConfidenceScoreDetails
                contractorData={originalData}
                collectionLimitsData={
                  editorState.collectionLimitsData ||
                  (emptyMap as CollectionLimitsDataMap)
                }
                clientsDeliveriesData={
                  editorState.clientsDeliveriesData || (emptyList as ClientDeliveriesList)
                }
              />
            )}
            {collectionId != null && countryId != null && (
              <DeliveriesListBlock data={editorState.clientsDeliveriesData} />
            )}
          </IndentedStack>
        </EditorSegment>
        <EditorSegment>
          <label>
            Public Title (
            <Text italic>
              <Text bold underline>
                IMPORTANT
              </Text>
              : &nbsp;system will search and fix others exactly same
              title/description/source rows
            </Text>
            ):
          </label>
          <IndentedStack>
            {collectionId == null && (
              <Text>
                <Text italic bold underline>
                  Hint:
                </Text>
                &nbsp;Collection should be selected first.
              </Text>
            )}
            {collectionId != null && editorState.collectionPublicTitlesData == null && (
              <Text thin>Loading public titles data...</Text>
            )}
            {collectionId != null &&
              editorState.collectionPublicTitlesData != null &&
              editorState.collectionPublicTitlesData.size === 0 && (
                <Text thin>No public titles found for selected collection.</Text>
              )}
            {collectionId != null &&
              editorState.collectionPublicTitlesData != null &&
              editorState.collectionPublicTitlesData.size > 0 && (
                <Sticker
                  value={publicTitleStickerValue}
                  onRemove={handlePublicTitleRemove}
                  emptyStub={
                    <Text italic>
                      <Text bold underline>
                        Hint
                      </Text>
                      : &nbsp;Select public title below, or the first one will be selected
                      automatically.
                    </Text>
                  }
                  removable
                />
              )}
            {collectionId != null &&
              editorState.collectionPublicTitlesData != null &&
              editorState.collectionPublicTitlesData.size > 0 && (
                <SuggestedPublicTitlesTable
                  data={editorState.collectionPublicTitlesData}
                  onSelect={handlePublicTitleSelect}
                />
              )}
          </IndentedStack>
        </EditorSegment>
        <EditorSegment>
          <label>Industry:</label>
          <IndentedStack>
            <IndustrySelect
              className="full-width"
              store={store.industriesStore}
              value={industrySelectValue}
              onChange={handleIndustryChange}
              styles={reactSelectStyles}
            />
          </IndentedStack>
        </EditorSegment>
        {!region && (
          <EditorSegment>
            <label>Location:</label>
            <IndentedStack>
              <LocationSelect
                className="full-width"
                fetchGraphQL={fetchGraphQL}
                value={locationSelectValue}
                onChange={handleLocationChange}
                styles={reactSelectStyles}
              />
              <LabelInline
                title={
                  isGlobalSupplierSearchFlag
                    ? "Mark row as not Global Supplier Search"
                    : "Mark row as Global Supplier Search"
                }
              >
                Global Supplier Search
                <Checkbox
                  checked={isGlobalSupplierSearchFlag}
                  onCheckedChange={handleGlobalSupplierSearchFlagChange}
                >
                  <CheckboxIndicator>
                    <Icon icon="check" />
                  </CheckboxIndicator>
                </Checkbox>
              </LabelInline>
            </IndentedStack>
          </EditorSegment>
        )}
        {region && (
          <EditorSegment>
            <label>Region:</label>
            <IndentedStack>
              <RegionSelect
                className="full-width"
                fetchGraphQL={fetchGraphQL}
                value={regionSelectValue}
                onChange={handleRegionChange}
                styles={reactSelectStyles}
              />
              <LabelInline
                title={
                  isGlobalSupplierSearchFlag
                    ? "Mark row as not Global Supplier Search"
                    : "Mark row as Global Supplier Search"
                }
              >
                Global Supplier Search
                <Checkbox
                  checked={isGlobalSupplierSearchFlag}
                  onCheckedChange={handleGlobalSupplierSearchFlagChange}
                >
                  <CheckboxIndicator>
                    <Icon icon="check" />
                  </CheckboxIndicator>
                </Checkbox>
              </LabelInline>
            </IndentedStack>
          </EditorSegment>
        )}
        {allResultingMappings.countryId != null &&
          allResultingMappings.industryId != null && (
            <EditorSegment>
              <label>Worker Type:</label>
              <IndentedStack>
                <WorkerTypeSelect
                  // providing key prop fixes the react-select cache issue (it doesn't invalidate) by re-rendering it completely
                  key={[
                    allResultingMappings.countryId,
                    allResultingMappings.industryId,
                  ].join(", ")}
                  className="full-width"
                  placeholder="No worker type selected"
                  value={workerTypeSelectValue}
                  onChange={handleWorkerTypeChange}
                  countryId={allResultingMappings.countryId}
                  industryId={allResultingMappings.industryId}
                  fetchGraphQL={fetchGraphQL}
                  styles={reactSelectStyles}
                  clearable
                />
              </IndentedStack>
            </EditorSegment>
          )}
      </Stack>

      <ButtonGroup css={{ padding: "$3 $4", paddingTop: "0" }}>
        <PromiseButton
          color="green"
          size="normal"
          loadingText="Apply Changes"
          title="Apply changes to the row"
          disabled={!hasAnyChanges || loading || processingIsRunning}
          onClick={hasAnyChanges ? handleApplyChanges : undefined}
        >
          Apply Changes
        </PromiseButton>
        {warnings.size > 0 && (
          <Button
            color="yellow"
            size="normal"
            title="Skip warnings to be able to proceed with changes"
            disabled={errors.size > 0 || skipWarnings || processingIsRunning}
            onClick={handleSkipWarnings}
          >
            Skip Warnings
          </Button>
        )}
        <PromiseButton
          color="green"
          size="normal"
          loadingText="Rerun Row"
          title="Run data refresh procedure"
          disabled={loading || processingIsRunning}
          onClick={handleRerunRow}
        >
          Rerun Row
        </PromiseButton>
        {!needApproval && (
          <PromiseButton
            color="green"
            size="normal"
            loadingText="Refresh Public Title"
            title="Run public title refresh"
            disabled={!isFinished || loading || processingIsRunning}
            onClick={handleRunPublicTitlesRefresh}
          >
            Refresh Public Title
          </PromiseButton>
        )}
        {needApproval && (
          <PromiseButton
            color="green"
            size="normal"
            loadingText="Approve Row"
            title="Approve row mappings"
            onClick={handleConfirmRowApproval}
            disabled={hasAnyChanges || loading || processingIsRunning}
          >
            Approve Row
          </PromiseButton>
        )}
        <Button
          color="danger"
          size="normal"
          title="Delete row from the whole index"
          disabled={loading || processingIsRunning}
          onClick={handleConfirmRowDeletion}
        >
          Delete Row
        </Button>
        <Button
          color="primary"
          title="Cancel changes and close editor"
          size="normal"
          disabled={loading}
          onClick={handleCloseEditor}
        >
          Close Editor
        </Button>
        <Messager ref={messagerRef} />
      </ButtonGroup>

      <RerunRowModal
        show={rerunModalState}
        onRerun={handleRunDataRefresh}
        onHide={closeRerunModal}
      />
    </Stack>
  );
};

AdminContractorEditor.displayName = "AdminContractorEditor";
AdminContractorEditor.defaultProps = {
  data: emptyMap as ContractorDataMap,
};

export default AdminContractorEditor;
