import React, { useCallback } from "react";

import Stack from "../../../components/lib/Stack";
import Text from "../../../components/lib/Text";
import Button from "../../../components/lib/Button";
import { Card } from "../../../components/lib/Card";
import { ButtonGroup as ButtonGroupBase } from "../../../components/lib/ButtonGroup";
import { styled } from "../../../stitches.config";
import { useVal5KAdminContext } from "../context/Val5KAdminContext";

const ButtonGroup = styled(ButtonGroupBase, {
  flexWrap: "wrap",
  justifyContent: "center",
});

const DashboardPage = () => {
  const { router } = useVal5KAdminContext();

  const handleClickCreateFromRateCard = useCallback(() => {
    router.push(`/admin/val5000/create-surveys`);
  }, [router]);

  const handleClickManageReviews = useCallback(() => {
    router.push(`/admin/val5000/surveys`);
  }, [router]);

  const handleClickManageAttempts = useCallback(() => {
    router.push(`/admin/val5000/validations`);
  }, [router]);

  const handleClickManagePermissions = useCallback(() => {
    router.push(`/admin/val5000/permissions`);
  }, [router]);

  const handleClickValidatorPerformance = useCallback(() => {
    router.push(`/admin/val5000/performance`);
  }, [router]);

  const handleClickAlertValidators = useCallback(() => {
    router.push(`/admin/val5000/alerts`);
  }, [router]);

  return (
    <Card css={{ padding: "$11", width: "$3-of-5", margin: "0 auto" }}>
      <Stack css={{ gap: "$9", fontSize: "$lg" }}>
        <Text thin>Create Surveys</Text>
        <Button
          css={{ padding: "$7 $20" }}
          color="brand"
          size="large"
          onClick={handleClickCreateFromRateCard}
        >
          <Text thin nowrap>
            Choose Ratecard
          </Text>
        </Button>
        <Text thin>Control Validation Process</Text>
        <ButtonGroup fill>
          <Button
            css={{ width: "200px" }}
            color="accent"
            size="large"
            onClick={handleClickManageReviews}
          >
            <Text thin nowrap>
              Manage Surveys
            </Text>
          </Button>
          <Button
            css={{ width: "200px" }}
            color="accent"
            size="large"
            onClick={handleClickManagePermissions}
          >
            <Text thin nowrap>
              Manage Permissions
            </Text>
          </Button>
          <Button
            css={{ width: "200px" }}
            color="accent"
            size="large"
            onClick={handleClickAlertValidators}
          >
            <Text thin nowrap>
              Alert Validators
            </Text>
          </Button>
        </ButtonGroup>
        <Text thin>See Feedback</Text>
        <ButtonGroup fill>
          <Button color="accent" size="large" onClick={handleClickManageAttempts}>
            <Text thin nowrap>
              Manage Validations
            </Text>
          </Button>
          <Button color="accent" size="large" onClick={handleClickValidatorPerformance}>
            <Text thin nowrap>
              Validator Performance
            </Text>
          </Button>
        </ButtonGroup>
      </Stack>
    </Card>
  );
};

DashboardPage.displayName = "DashboardPage";

export default DashboardPage;
