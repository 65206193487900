import React from "react";
import PropTypes from "prop-types";
import Modal from "../../ratecards/components/Modal";
import { observer } from "mobx-react";
import ContainerSection from "../../ratecards/components/ContainerSection";
import Loadable from "../../ratecards/components/Loadable";
import SelectableItem from "../../ratecards/components/SelectableItem";
import InstantSearchBox from "../../ratecards/components/InstantSearchBox";
import SelectableItemsPaginatedList from "../../ratecards/components/SelectableItemsPaginatedList";
import { ModalButtons, ModalTitle } from "../../ratecards/components/FilterModal";
import Spacer from "../../../components/lib/Spacer";
import Inline from "../../../components/lib/Inline";
import Button from "../../../components/lib/Button";

const TagsList = observer((props) => {
  return (
    <div>
      <SelectableItemsPaginatedList
        items={props.filterState.tagsView}
        itemsPerPage={8}
        loading={props.loading}
        renderItem={(tag) => (
          <SelectableItem
            key={tag.tagId}
            item={tag}
            name="share-user"
            value={tag.name}
            selected={tag.selected}
            onChange={props.filterState.setTagSelectedValue}
          >
            <div>{tag.name}</div>
          </SelectableItem>
        )}
        loadingView={
          <div className="ratecards-list-select-all-section" style={{ margin: "40px 0" }}>
            <span className="text-center">No Tags available.</span>
          </div>
        }
      />
    </div>
  );
});

const ApplyTagFilterModal = (props) => (
  <Modal
    show={props.filterState.tagModal.show}
    onHide={props.filterState.tagModal.hideModal}
    innerStyle={{ width: 550 }}
  >
    <ContainerSection className="header">
      <ModalTitle>Apply Tags</ModalTitle>
    </ContainerSection>
    <Loadable isLoading={props.filterState.tagNetwork.loading}>
      <ContainerSection className="container-section no-border">
        <Inline css={{ gap: "$2" }}>
          <InstantSearchBox
            placeholder="Search Tags..."
            onSearch={props.filterState.tagsOnInstantSearch}
            value={props.filterState.tagsInstantSearchValue}
          />
          <Spacer />
          <Button onClick={props.filterState.tagsOnSelectAll}>Select All</Button>
          <Button onClick={props.filterState.tagsOnDeselectAll}>Deselect All</Button>
        </Inline>
      </ContainerSection>
      {
        <TagsList
          filterState={props.filterState}
          loading={props.filterState.tagNetwork.loading}
        />
      }
      <ContainerSection className="footer">
        <ModalButtons>
          <Spacer />
          <Button
            color="brand"
            size="large"
            onClick={props.applyTagsEvent}
            disabled={props.filterState.selectedTag === 0}
          >
            {props.btnText}
          </Button>
          <Button size="large" onClick={props.filterState.tagModal.hideModal}>
            Cancel
          </Button>
        </ModalButtons>
      </ContainerSection>

      {props.noTags}
    </Loadable>
  </Modal>
);

ApplyTagFilterModal.propTypes = {
  filterState: PropTypes.object.isRequired,
  applyTagsEvent: PropTypes.func.isRequired,
  btnText: PropTypes.string,
};

export default observer(ApplyTagFilterModal);
