import React from "react";
import Stitches from "@stitches/react";

import { styled, CSS } from "../../stitches.config";
import Box from "./Box";
import { getLinkBaseStyles } from "./Link";

type BaseColor = "primary" | "success" | "warning" | "danger";

function getColorSpecificStyles(baseColor: BaseColor) {
  return {
    backgroundColor: `$${baseColor}Lightest`,
    border: `1px solid $${baseColor}Lighter`,
    color: `$${baseColor}Darkest`,

    // overriding any kind of reset.css
    ["& *:is(h1, h2, h3, h4, h5, h6)," +
    ".pt-ui & *:is(h1, h2, h3, h4, h5, h6)," +
    ".pt-ui .pt-private-index & *:is(h1, h2, h3, h4, h5, h6)," +
    ".ratecards & *:is(h1, h2, h3, h4, h5, h6)," +
    "#v7-stitches-content & *:is(h1, h2, h3, h4, h5, h6)"]: {
      color: `$${baseColor}Darkest`,
    },

    // overriding any kind of reset.css
    "& a, .pt-ui & a, .pt-ui .pt-private-index & a, .ratecards & a, #v7-stitches-content & a":
      {
        ...getLinkBaseStyles(baseColor),
        textDecoration: "underline !important",
      },
  };
}

const StyledAlert = styled(Box, {
  padding: "$4",
  borderRadius: "$rounded",

  variants: {
    color: {
      primary: getColorSpecificStyles("primary"),
      success: getColorSpecificStyles("success"),
      warning: getColorSpecificStyles("warning"),
      danger: getColorSpecificStyles("danger"),
    },
    fill: {
      true: {
        width: "$full",
      },
    },
  },

  defaultVariants: {
    color: "primary",
  },
});

interface CustomAlertProps {
  color?: "primary" | "success" | "warning" | "danger" | undefined;
  css?: CSS;
}

export type AlertProps = Stitches.VariantProps<typeof StyledAlert> &
  CustomAlertProps &
  React.ComponentPropsWithRef<"div">;

const Alert = (props: AlertProps): React.ReactElement => <StyledAlert {...props} />;

Alert.displayName = "Alert";
Alert.defaultProps = {
  color: "primary",
};

export default Alert;
