// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import { observer } from "mobx-react";
import CheckBox from "../../../shared/CheckBox";
import classNames from "classnames";
import { TagItemWithoutDelete } from "../../../ratecards/components/TagItem";

const RegionListItem = observer(
  class extends Component {
    static contextTypes = {
      router: PropTypes.object,
    };

    constructor(props) {
      super(props);

      this.handleViewDetail = this.handleViewDetail.bind(this);
      this.deleteTag = this.deleteTag.bind(this);
      this.state = {
        updated: false,
      };
    }

    handleViewDetail() {
      this.context.router.push({
        pathname: `/admin/regions/${this.props.region.regionId}`,
        query: this.context.router.query,
      });
    }

    deleteTag(event: object, tag_id) {
      event.preventDefault();
      event.stopPropagation();
      event.cancelBubble = true;
      event.returnValue = false;

      // this.props.store.deleteSingleTags({tagId : tag_id,contentId:this.props.region.regionId})
      //   .then(res => {
      //     console.log('result', res);
      //     if(!res)
      //       return
      //     this.props.region.tags.forEach((item,index) => {
      //       if(item.tagId === tag_id){
      //         this.props.region.tags.splice(index,1);
      //       }
      //     });
      //     this.setState({
      //       updated : true
      //     })
      //   });
    }

    render() {
      const props = this.props;
      const region = props.region;
      const editing = region.viewState.editing;
      const selected = region.viewState.selected;
      const name = region.name;
      const country_name = region.country.name;
      const id = region.regionId;

      const createdDisplay = region.created.format("MMMM D, YYYY");
      let ownerName = "You";
      let onItemClick = this.props.onClick || this.handleViewDetail;
      let editingSection = null;
      let tagSection = null;
      //let sharedByYou = shared;

      if (String(region.user.userId) !== String(props.store.currentUser.userId)) {
        ownerName = region.owner;
        //sharedByYou = false;
      }
      if (region.user.email !== localStorage.getItem("PeopleTickerUserEmail")) {
        ownerName = region.owner;
      }

      if (region.tags.length > 0 && !this.props.store.isTagView) {
        tagSection = region.tags.map((tag) => (
          <TagItemWithoutDelete key={tag.tagId} tag={tag} onClick={this.deleteTag} />
        ));
      }

      if (editing) {
        onItemClick = region.toggleSelected;
        editingSection = (
          <CheckBox
            type={props.store.allowMultipleItemSelection ? "checkbox" : "radio"}
            name="list-item-select"
            value={name}
            selected={selected}
            style={{ width: 34 }}
            onClick={onItemClick}
          />
        );
      }

      return (
        <div className="ratecard-list-item-container">
          <div
            className={classNames("ratecard-list-item", { selected: selected })}
            onClick={onItemClick}
          >
            <div className="ratecard-list-item-left ellipsis">
              {editingSection}
              <div className="ratecard-label-container ellipsis">
                <div className="ratecard-label ellipsis">
                  <span className="ellipsis">{name}</span>
                </div>
                <div className="ratecard-meta ellipsis">
                  <span className="item-subtext-muted">Created by </span>
                  <span className="item-subtext-accent">{ownerName} </span>
                  <span className="item-subtext-muted">on </span>
                  <span className="item-subtext-accent">{createdDisplay}</span>
                </div>
              </div>
            </div>
            <div className="ratecard-list-item-right">
              <div className="ratecard-info w-300 text-center">
                <header data-testid="ID-header-regions">ID</header>
                <div>{id}</div>
              </div>
              <div className="ratecard-info w-300 text-center">
                <header data-testid="country-header-regions">Country</header>
                <div>{country_name}</div>
              </div>
              {!editing && (
                <div className="rc-center">
                  {
                    <Link to={`/admin/regions/${id}`} className="btn btn-default">
                      View
                    </Link>
                  }
                </div>
              )}
            </div>
            <div className={classNames("tagbox", { "p-left-34": editing })}>
              {tagSection}
            </div>
          </div>
        </div>
      );
    }
  }
);

RegionListItem.propTypes = {
  store: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default RegionListItem;
