/**
 * @generated SignedSource<<d6e6f2692ae2bd8a3e5c31b01f0f9a25>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminClientJobsQuery$variables = {};
export type AdminClientJobsQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"AdminClientJobs_viewer">;
  } | null;
};
export type AdminClientJobsQuery = {
  response: AdminClientJobsQuery$data;
  variables: AdminClientJobsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "Literal",
  "name": "filters",
  "value": {}
},
v1 = {
  "kind": "Literal",
  "name": "first",
  "value": 10
},
v2 = {
  "kind": "Literal",
  "name": "section",
  "value": "ADMIN"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasNextPage",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endCursor",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientId",
  "storageKey": null
},
v12 = {
  "kind": "Literal",
  "name": "order",
  "value": [
    {
      "direction": "ASC",
      "field": "NAME"
    }
  ]
},
v13 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v12/*: any*/),
  (v2/*: any*/)
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v17 = [
  "filters"
],
v18 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v12/*: any*/)
],
v19 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "kind": "Literal",
    "name": "order",
    "value": [
      {
        "direction": "ASC",
        "field": "USERNAME"
      }
    ]
  },
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminClientJobsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AdminClientJobs_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AdminClientJobsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "kind": "Literal",
                "name": "order",
                "value": [
                  {
                    "direction": "DESC",
                    "field": "CREATE_DATE"
                  }
                ]
              },
              (v2/*: any*/)
            ],
            "concreteType": "JobNodeTypeConnection",
            "kind": "LinkedField",
            "name": "jobs",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPreviousPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startCursor",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "JobNodeTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobNodeType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "jobId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "jobTitle",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "jobLabel",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "jobDescription",
                        "storageKey": null
                      },
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LegacyUserNode",
                        "kind": "LinkedField",
                        "name": "createdBy",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TagNode",
                        "kind": "LinkedField",
                        "name": "tags",
                        "plural": true,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "tagId",
                            "storageKey": null
                          },
                          (v9/*: any*/),
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "LegacyUserNode",
                            "kind": "LinkedField",
                            "name": "owner",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ClientNode",
                                "kind": "LinkedField",
                                "name": "client",
                                "plural": false,
                                "selections": [
                                  (v11/*: any*/),
                                  (v9/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "jobs(filters:{},first:10,order:[{\"direction\":\"DESC\",\"field\":\"CREATE_DATE\"}],section:\"ADMIN\")"
          },
          {
            "alias": null,
            "args": (v13/*: any*/),
            "concreteType": "TagNodeConnection",
            "kind": "LinkedField",
            "name": "tags",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TagNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TagNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v9/*: any*/),
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v15/*: any*/)
                ],
                "storageKey": null
              },
              (v16/*: any*/)
            ],
            "storageKey": "tags(filters:{},first:10,order:[{\"direction\":\"ASC\",\"field\":\"NAME\"}],section:\"ADMIN\")"
          },
          {
            "alias": null,
            "args": (v13/*: any*/),
            "filters": (v17/*: any*/),
            "handle": "connection",
            "key": "FilterAndSortTags_tags",
            "kind": "LinkedHandle",
            "name": "tags"
          },
          {
            "alias": null,
            "args": (v18/*: any*/),
            "concreteType": "ClientTypeNodeConnection",
            "kind": "LinkedField",
            "name": "allClients",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ClientTypeNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClientTypeNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v11/*: any*/),
                      (v9/*: any*/),
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v15/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "allClients(filters:{},first:10,order:[{\"direction\":\"ASC\",\"field\":\"NAME\"}])"
          },
          {
            "alias": null,
            "args": (v18/*: any*/),
            "filters": (v17/*: any*/),
            "handle": "connection",
            "key": "FilterAndSortClients_allClients",
            "kind": "LinkedHandle",
            "name": "allClients"
          },
          {
            "alias": null,
            "args": (v19/*: any*/),
            "concreteType": "AuthorNodeConnection",
            "kind": "LinkedField",
            "name": "authors",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AuthorNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuthorNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastName",
                        "storageKey": null
                      },
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v15/*: any*/)
                ],
                "storageKey": null
              },
              (v16/*: any*/)
            ],
            "storageKey": "authors(filters:{},first:10,order:[{\"direction\":\"ASC\",\"field\":\"USERNAME\"}],section:\"ADMIN\")"
          },
          {
            "alias": null,
            "args": (v19/*: any*/),
            "filters": (v17/*: any*/),
            "handle": "connection",
            "key": "FilterAndSortCreatedBy_authors",
            "kind": "LinkedHandle",
            "name": "authors"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c16b57ae1dd7eedbcae3eaba640d4b07",
    "id": null,
    "metadata": {},
    "name": "AdminClientJobsQuery",
    "operationKind": "query",
    "text": "query AdminClientJobsQuery {\n  viewer {\n    ...AdminClientJobs_viewer\n  }\n}\n\nfragment AdminClientJobs_viewer on Viewer {\n  jobs(first: 10, filters: {}, order: [{field: CREATE_DATE, direction: DESC}], section: ADMIN) {\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      startCursor\n      endCursor\n    }\n    totalCount\n    edges {\n      node {\n        id\n        jobId\n        ...Job_job\n      }\n    }\n  }\n  ...FilterAndSortTags_viewer\n  ...FilterAndSortClients_viewer\n  ...FilterAndSortCreatedBy2_viewer\n}\n\nfragment FilterAndSortClients_viewer on Viewer {\n  allClients(first: 10, filters: {}, order: [{direction: ASC, field: NAME}]) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalCount\n    edges {\n      node {\n        id\n        clientId\n        name\n        __typename\n      }\n      cursor\n    }\n  }\n}\n\nfragment FilterAndSortCreatedBy2_viewer on Viewer {\n  authors(first: 10, filters: {}, order: [{direction: ASC, field: USERNAME}], section: ADMIN) {\n    edges {\n      node {\n        id\n        userId\n        firstName\n        lastName\n        username\n        email\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment FilterAndSortTags_viewer on Viewer {\n  tags(first: 10, filters: {}, order: [{direction: ASC, field: NAME}], section: ADMIN) {\n    edges {\n      node {\n        id\n        name\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment Job_job on JobNodeType {\n  id\n  jobId\n  jobTitle\n  jobLabel\n  jobDescription\n  created\n  createdBy {\n    username\n  }\n  tags {\n    id\n    ...Tag_tag\n  }\n}\n\nfragment Tag_tag on TagNode {\n  id\n  tagId\n  name\n  created\n  owner {\n    userId\n    username\n    client {\n      clientId\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3b6854e3ef6d0f1b21037f8fa448921b";

export default node;
