import React, { useCallback } from "react";

import Icon from "../../../../components/lib/Icon";
import Text from "../../../../components/lib/Text";
import TextCrop from "../../../validator5K_admin/components/TextCrop";
import Button from "../../../../components/lib/Button";
import RateBlock from "./RateBlock";
import ReviewsPeckingOrderTableBase from "./base";
import { SimpleLink } from "../../../../components/lib/Link";
import { THead, TH, TR, TD } from "../../../../components/lib/Table";
import { REVIEW_TYPES, JOBS_ORDERING_KEYS } from "../../types";
import { getJobRegion, getFirstJobData } from "./utils";

import type { ReviewDataMap, ReviewsDataOrderedMap } from "../../types";
import type { FuncGetAllReviewsOrdered, ReviewsPeckingOrderTableBaseProps } from "./base";
import { useVal5KAdminContext } from "../../context/Val5KAdminContext";

export default function ReviewsPeckingOrderTable(
  props: ReviewsPeckingOrderTableBaseProps
) {
  const { router } = useVal5KAdminContext();

  // utils

  const handleEnterRow = useCallback(
    (reviewData: ReviewDataMap) => {
      const reviewId = reviewData.get("id");
      router.push(`/admin/val5000/reviews/${reviewId}`);
    },
    [router]
  );

  const getAllReviewsOrdered: FuncGetAllReviewsOrdered = useCallback(
    (reviewsGroupData, dataOrderingKey = JOBS_ORDERING_KEYS.MIN_VALUE, asc = true) => {
      return reviewsGroupData.sortBy((reviewData) => {
        const reviewType = reviewData?.get("review_type");
        const firstJobData = getFirstJobData(reviewData);

        let value = null;
        if (reviewType === REVIEW_TYPES.CLASSIC) {
          if (dataOrderingKey === JOBS_ORDERING_KEYS.MIN_VALUE) {
            const lowRateResultData = firstJobData.get("rate_results").first();

            value = lowRateResultData?.get("pay_rate_min");
            if (!value) {
              return null;
            }
          } else if (dataOrderingKey === JOBS_ORDERING_KEYS.MAX_VALUE) {
            const highRateResultData = firstJobData.get("rate_results").last();

            value = highRateResultData?.get("pay_rate_max");
            if (!value) {
              return null;
            }
          }
        } else if (reviewType === REVIEW_TYPES.FILL_THE_BLANK) {
          value = null;
        }

        if (value == null) {
          return null;
        }

        return value * (asc ? 1 : -1);
      }) as unknown as ReviewsDataOrderedMap;
    },
    []
  );

  // render methods

  const tableHead = (
    <THead>
      <TR>
        <TH>Details</TH>
        <TH>Title</TH>
        <TH>Location</TH>
        <TH>Industry</TH>
        <TH>Is Active</TH>
        <TH>
          Lowest Wage
          <br />
          (least amount of experience)
        </TH>
        <TH>
          Highest Wage
          <br />
          (most amount of experience)
        </TH>
      </TR>
    </THead>
  );

  const renderTableRow = useCallback(
    (reviewData: ReviewDataMap) => {
      const currentReviewId = props.reviewId;
      const reviewId = reviewData.get("id");
      const reviewIsActive = reviewData.get("is_active", false);
      const firstJobData = getFirstJobData(reviewData);
      const jobIndustry = firstJobData.get("industry_name");
      const jobTitle = firstJobData.get("title");
      const jobDescription = firstJobData.get("description");

      return (
        <TR key={reviewId}>
          <TD>
            {reviewId === currentReviewId ? (
              <SimpleLink href="#top">current page</SimpleLink>
            ) : (
              <Button
                icon="chevron-left"
                color="brand"
                variant="outlined"
                size="extra-small"
                title="See survey details."
                onClick={() => handleEnterRow(reviewData)}
              />
            )}
          </TD>
          <TD>
            <Text underline>
              <TextCrop
                mode="modal"
                title={jobTitle}
                text={jobTitle}
                fullText={jobDescription}
                emptyStub=""
              />
            </Text>
          </TD>
          <TD>
            <TextCrop
              mode="tooltip"
              title="Location"
              text={getJobRegion(firstJobData)}
              emptyStub=""
            />
          </TD>
          <TD>
            <TextCrop mode="tooltip" title="Industry" text={jobIndustry} emptyStub="" />
          </TD>
          <TD>
            {reviewIsActive ? (
              <Icon css={{ color: "$success" }} icon="check" />
            ) : (
              <Icon css={{ color: "$danger" }} icon="times" />
            )}
          </TD>
          <TD>
            <RateBlock reviewData={reviewData} rateValue="min" />
          </TD>
          <TD>
            <RateBlock reviewData={reviewData} rateValue="max" />
          </TD>
        </TR>
      );
    },
    [props.reviewId, handleEnterRow]
  );

  return (
    <ReviewsPeckingOrderTableBase
      {...props}
      tableHead={tableHead}
      renderTableRow={renderTableRow}
      getAllReviewsOrdered={getAllReviewsOrdered}
      emptyDataText="no surveys found"
    />
  );
}

ReviewsPeckingOrderTable.displayName = "ReviewsPeckingOrderTable";
