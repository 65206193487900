import React, { useCallback } from "react";

import Stack from "../../../components/lib/Stack";
import Grid from "../../../components/lib/Grid";
import Box from "../../../components/lib/Box";
import Inline from "../../../components/lib/Inline";
import Divider from "../../../components/lib/Divider";
import { styled } from "../../../stitches.config";

type WizardStepProps = {
  isActive?: boolean;
  onResetWizard?: () => void;
  onGoToNextStep?: () => void;
};

type WizardChildType = React.ReactElement<WizardStepProps> | React.ReactElement;

const StepNumber = styled(Inline, {
  width: "40px",
  height: "40px",
  paddingLeft: "$3",
  fontSize: "$2xl",
  border: "2px solid $white",
  borderRadius: "50%",
  backgroundColor: "$brand",
  color: "$white",
});

type VerticalWizardProps = {
  step: number;
  onChange: (nextStep: number) => void;
  onReset: () => void;
  children: WizardChildType[] | WizardChildType;
};

const VerticalWizard = (props: VerticalWizardProps) => {
  const { step, children, onChange, onReset } = props;

  // handlers

  const handleGoToTheStep = useCallback(
    (nextStep) => {
      if (step !== nextStep && onChange) {
        onChange(nextStep);
      }
    },
    [step, onChange]
  );

  const handleResetWizard = useCallback(() => {
    onChange && onChange(VerticalWizard.defaultProps.step);
    onReset && onReset();
  }, [onChange, onReset]);

  // rendering

  return (
    <Stack css={{ alignItems: "flex-start" }}>
      {React.Children.map(children, (child: WizardChildType, idx) => {
        if (idx >= step) {
          return null;
        }

        const showProgress = idx < step - 1 ? "$full" : "0";

        return (
          <Grid
            key={idx}
            css={{
              width: "$full",
              paddingLeft: "$4",
              gridTemplateColumns: "40px auto",
              gap: "$8",
            }}
          >
            <Box css={{ position: "relative" }}>
              <StepNumber>{idx + 1}</StepNumber>
              <Divider
                orientation="vertical"
                css={{
                  position: "absolute",
                  left: "19px",
                  border: "none",
                  borderRadius: "$rounded",
                  backgroundColor: "$brand",
                  width: "2px",
                  height: showProgress,
                  transition: "height 0.5s ease-in-out",
                }}
              />
            </Box>
            <Box>
              {React.cloneElement(
                child,
                Object.assign({}, child.props, {
                  isActive: idx === step - 1,
                  onGoToNextStep:
                    idx === step - 1 ? () => handleGoToTheStep(idx + 2) : undefined,
                  onResetWizard: handleResetWizard,
                })
              )}
            </Box>
          </Grid>
        );
      })}
    </Stack>
  );
};
VerticalWizard.displayName = "VerticalWizard";
VerticalWizard.defaultProps = {
  step: 1,
};

export default VerticalWizard;
