/**
 * @generated SignedSource<<c63cf2bfdfcb15dbb26a8d2ee5f92c7c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TagsContentTypeEnums = "JOB_LIBRARY" | "NEGOTIATION_WORKSHEET" | "PROJECT_STATS" | "RATE_CARD" | "REGIONS" | "SAVED_SEARCHES" | "%future added value";
export type TagsFiltersInput = {
  clientIdIn?: ReadonlyArray<string | null> | null;
  clientidIn?: ReadonlyArray<number | null> | null;
  fromDate?: string | null;
  isShared?: boolean | null;
  nameIContains?: string | null;
  ownerId?: ReadonlyArray<string | null> | null;
  toDate?: string | null;
  updatedFromDate?: string | null;
  updatedToDate?: string | null;
};
export type FilterAndSortTagsFetchAllQuery$variables = {
  contentType: TagsContentTypeEnums;
  filters?: TagsFiltersInput | null;
};
export type FilterAndSortTagsFetchAllQuery$data = {
  readonly viewer: {
    readonly tags: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        } | null;
      } | null>;
    } | null;
  } | null;
};
export type FilterAndSortTagsFetchAllQuery = {
  response: FilterAndSortTagsFetchAllQuery$data;
  variables: FilterAndSortTagsFetchAllQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contentType"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "contentType",
            "variableName": "contentType"
          },
          {
            "kind": "Variable",
            "name": "filters",
            "variableName": "filters"
          },
          {
            "kind": "Literal",
            "name": "section",
            "value": "ADMIN"
          }
        ],
        "concreteType": "TagNodeConnection",
        "kind": "LinkedField",
        "name": "tags",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TagNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TagNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FilterAndSortTagsFetchAllQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "FilterAndSortTagsFetchAllQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "e72598b850ce936ddc190268e9c92fd4",
    "id": null,
    "metadata": {},
    "name": "FilterAndSortTagsFetchAllQuery",
    "operationKind": "query",
    "text": "query FilterAndSortTagsFetchAllQuery(\n  $filters: TagsFiltersInput\n  $contentType: TagsContentTypeEnums!\n) {\n  viewer {\n    tags(filters: $filters, section: ADMIN, contentType: $contentType) {\n      edges {\n        node {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8b1c8548bcb310a3172b4c41f8094d6c";

export default node;
