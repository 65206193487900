import React from "react";
import { Set, Map, List } from "immutable";

import { styled } from "../../../stitches.config";

import Box from "../../../components/lib/Box";
import Inline from "../../../components/lib/Inline";
import Icon from "../../../components/lib/Icon";
import { TableConfig as TableConfigModal } from "../../../components/tables";
import {
  isSchemaChangedPredicate as isSchemaChangedPredicateBase,
  processColumnFunction as processColumnFunctionBase,
} from "../../../components/tables/Table";
import { emptyMap, emptyOrderedMap, emptySet } from "../../../constants";
import { useModalState } from "../../../utils/hooks";

import { TableFilterableEditableRestful } from "./ExtendedRestfulTables";
import MarketRatesTableConfigModal, {
  MarketRatesTableConfigOptionsList,
} from "./MarketRatesTableConfig";
import CompareWithMarketTableConfigModal from "./CompareWithMarketTableConfig";
import { contractorValuesColumnsSpecs } from "./ContractorValuesTableSpecs";
import {
  allMarketValuesColumnsSpecs,
  guruRatesColumnsSpecs,
  intermediateRatesColumnsSpecs,
  juniorRatesColumnsSpecs,
  leadRatesColumnsSpecs,
  matchedMarketValuesColumnsSpecs,
  seniorRatesColumnsSpecs,
} from "./MarketValuesTableSpecs";
// import PayNBillChart from './PayNBillChart';
// import MarkupChart from './MarkupChart';
// import PayRatesStatsTableColumn from './PayRatesStatsTableColumn';
// import BillRatesStatsTableColumn from './BillRatesStatsTableColumn';
// import AnnualRatesStatsTableColumn from './AnnualRatesStatsTableColumn';
// import MarkupStatsTableColumn from './MarkupStatsTableColumn';
// import MarkupAmountStatsTableColumn from './MarkupAmountStatsTableColumn';

import { usePLIContext } from "../context";
import {
  ALL_TABLE_TABS_TYPES,
  TABLE_TABS_TYPES,
  TableColumnsGroupSpecsObject,
  TABLE_TABS_TYPES_TYPE,
  CONTRACTOR_STATUSES,
} from "../types";

import type { ImmutableSet } from "../../../types/immutable";
import type {
  ContractorsTableTabsVisibleColumnsMap,
  ContractorDataMap,
  TableColumnSpecsObject,
  ContractorsDataOrderedMap,
  ContractorsTableDataStateObject,
} from "../types";
import type { TableFilterableEditableRestfulProps } from "../../../components/tables/TableFilterableRestful";
import type {
  ColumnConfigObject,
  GroupConfigObject,
  RestfulTableData,
  RowData,
  SchemaConfigObject,
  TableConfigOptionsList,
  VisibleColumnsSet,
} from "../../../components/tables/types";
import type {
  ColumnLikeElement,
  GroupLikeElement,
} from "../../../components/tables/Schema";
import { SetterOrUpdater } from "recoil";
import { CheckedState } from "../../../components/lib/Checkbox";
import { rowIdGetter } from "../../../components/tables/utils";

// eslint-disable-next-line
// const dataPattern = new RegExp("^data:image\/png;base64,");
// eslint-disable-next-line
// const urlPattern = new RegExp("(http|ftp|https)://[\w-]+(\.[\w-]*)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?");

// function preprocessImageData(data) {
//   if (typeof data === 'string') {
//     if (dataPattern.test(data)) {
//       return {bytes: data.replace(dataPattern, '')}
//     } else if (urlPattern.test(data)) {
//       return {url: data};
//     }
//   }

//   return null;
// }

export const contractorsTableSpecs: TableColumnSpecsObject<ContractorDataMap>[] =
  contractorValuesColumnsSpecs
    .concat(matchedMarketValuesColumnsSpecs)
    .concat(allMarketValuesColumnsSpecs);

const groupedMarketValuesColumnsSpecs: TableColumnsGroupSpecsObject<ContractorDataMap>[] =
  [
    {
      type: "group",
      uniqueKey: "__junior_rates",
      title: "Level I Rates",
      columns: juniorRatesColumnsSpecs,
    },
    {
      type: "group",
      uniqueKey: "__intermediate_rates",
      title: "Level II Rates",
      columns: intermediateRatesColumnsSpecs,
    },
    {
      type: "group",
      uniqueKey: "__senior_rates",
      title: "Level III Rates",
      columns: seniorRatesColumnsSpecs,
    },
    {
      type: "group",
      uniqueKey: "__lead_rates",
      title: "Level IV Rates",
      columns: leadRatesColumnsSpecs,
    },
    {
      type: "group",
      uniqueKey: "__guru_rates",
      title: "Level V Rates",
      columns: guruRatesColumnsSpecs,
    },
  ];

export const groupedContractorsTableColumnsSpecs: TableColumnsGroupSpecsObject<ContractorDataMap>[] =
  [
    {
      type: "group",
      uniqueKey: "__your_data",
      title: "Your Data / Comparison",
      columns: contractorValuesColumnsSpecs.concat(matchedMarketValuesColumnsSpecs),
    },
    ...groupedMarketValuesColumnsSpecs,
  ];

const compareWithMarketColumnsWhiteList = Set([
  "market_analysis__refresh_timestamp",
  "category",
  "source",
  "rate_type",
  "worker_type_title",
  "job_title",
  "job_family",
  "processed__job_collection_title",
  "processed__job_collection_id",
  "processed__job_title_id",
  "processed__job_title_match_type",
  "processed__needs_review",
  "market_analysis__collection_limits_client_accepted",
  "market_analysis__location_limits_modeled",
  "market_analysis__confidence_score",
  "processed__industry_title",
  "city",
  "state",
  "country",
  "region",
  "is_global_supplier_search",
  "market_analysis__market_level",
  //
  "pay_rate_savings",
  "pay_rate",
  "market_analysis__market_pay_rate_avg",
  "market_analysis__market_pay_rate_min",
  "market_analysis__market_pay_rate_mid",
  "market_analysis__market_pay_rate_max",
  "market_analysis__market_pay_rate_avg_variance",
  "market_analysis__market_pay_rate_min_variance",
  "market_analysis__market_pay_rate_mid_variance",
  "market_analysis__market_pay_rate_max_variance",
  //
  "bill_rate_savings",
  "bill_rate",
  "market_analysis__market_bill_rate_avg",
  "market_analysis__market_bill_rate_min",
  "market_analysis__market_bill_rate_mid",
  "market_analysis__market_bill_rate_max",
  "market_analysis__market_bill_rate_avg_variance",
  "market_analysis__market_bill_rate_min_variance",
  "market_analysis__market_bill_rate_mid_variance",
  "market_analysis__market_bill_rate_max_variance",
  //
  "markup",
  "market_analysis__market_markup_avg",
  "market_analysis__market_markup_min",
  "market_analysis__market_markup_mid",
  "market_analysis__market_markup_max",
  "market_analysis__market_markup_avg_variance",
  "market_analysis__market_markup_min_variance",
  "market_analysis__market_markup_mid_variance",
  "market_analysis__market_markup_max_variance",
  //
  "annual_salary_savings",
  "annual_salary",
  "burden_perc",
  "total_cost",
  "market_analysis__market_annual_salary_avg",
  "market_analysis__market_annual_salary_min",
  "market_analysis__market_annual_salary_mid",
  "market_analysis__market_annual_salary_max",
  "market_analysis__market_annual_salary_avg_variance",
  "market_analysis__market_annual_salary_min_variance",
  "market_analysis__market_annual_salary_mid_variance",
  "market_analysis__market_annual_salary_max_variance",
  //
  "supplier",
]) as any as ImmutableSet<string>;

const spendRatesColumnsWhiteList = Set([
  "category",
  "rate_type",
  "worker_type_title",
  "job_title",
  "city",
  "state",
  "country",
  "region",
  "bill_rate",
  "pay_rate",
  "markup",
  "annual_salary",
  "burden_perc",
  "total_cost",
]) as any as ImmutableSet<string>;

const marketRatesColumnsWhiteList = Set([
  "market_analysis__refresh_timestamp",
  "category",
  "rate_type",
  "worker_type_title",
  "job_title",
  "city",
  "state",
  "country",
  "region",
  // junior
  "market_analysis__junior_pay_rate_avg",
  "market_analysis__junior_pay_rate_min",
  "market_analysis__junior_pay_rate_mid",
  "market_analysis__junior_pay_rate_max",
  "market_analysis__junior_markup_avg",
  "market_analysis__junior_markup_min",
  "market_analysis__junior_markup_mid",
  "market_analysis__junior_markup_max",
  "market_analysis__junior_bill_rate_avg",
  "market_analysis__junior_bill_rate_min",
  "market_analysis__junior_bill_rate_mid",
  "market_analysis__junior_bill_rate_max",
  // intermediate
  "market_analysis__intermediate_pay_rate_avg",
  "market_analysis__intermediate_pay_rate_min",
  "market_analysis__intermediate_pay_rate_mid",
  "market_analysis__intermediate_pay_rate_max",
  "market_analysis__intermediate_markup_avg",
  "market_analysis__intermediate_markup_min",
  "market_analysis__intermediate_markup_mid",
  "market_analysis__intermediate_markup_max",
  "market_analysis__intermediate_bill_rate_avg",
  "market_analysis__intermediate_bill_rate_min",
  "market_analysis__intermediate_bill_rate_mid",
  "market_analysis__intermediate_bill_rate_max",
  // senior
  "market_analysis__senior_pay_rate_avg",
  "market_analysis__senior_pay_rate_min",
  "market_analysis__senior_pay_rate_mid",
  "market_analysis__senior_pay_rate_max",
  "market_analysis__senior_markup_avg",
  "market_analysis__senior_markup_min",
  "market_analysis__senior_markup_mid",
  "market_analysis__senior_markup_max",
  "market_analysis__senior_bill_rate_avg",
  "market_analysis__senior_bill_rate_min",
  "market_analysis__senior_bill_rate_mid",
  "market_analysis__senior_bill_rate_max",
  // lead
  "market_analysis__lead_pay_rate_avg",
  "market_analysis__lead_pay_rate_min",
  "market_analysis__lead_pay_rate_mid",
  "market_analysis__lead_pay_rate_max",
  "market_analysis__lead_markup_avg",
  "market_analysis__lead_markup_min",
  "market_analysis__lead_markup_mid",
  "market_analysis__lead_markup_max",
  "market_analysis__lead_bill_rate_avg",
  "market_analysis__lead_bill_rate_min",
  "market_analysis__lead_bill_rate_mid",
  "market_analysis__lead_bill_rate_max",
  // guru
  "market_analysis__guru_pay_rate_avg",
  "market_analysis__guru_pay_rate_min",
  "market_analysis__guru_pay_rate_mid",
  "market_analysis__guru_pay_rate_max",
  "market_analysis__guru_markup_avg",
  "market_analysis__guru_markup_min",
  "market_analysis__guru_markup_mid",
  "market_analysis__guru_markup_max",
  "market_analysis__guru_bill_rate_avg",
  "market_analysis__guru_bill_rate_min",
  "market_analysis__guru_bill_rate_mid",
  "market_analysis__guru_bill_rate_max",
]) as any as ImmutableSet<string>;

const customConfigColumnsWhiteList = Set([
  "id",
  "bill_rate_savings",
  "pay_rate_savings",
  "annual_salary_savings",
  "date_engaged",
  "market_analysis__refresh_timestamp",
  "upload__title",
  "category",
  "source",
  "rate_type",
  "worker_type_title",
  "job_title",
  "job_family",
  "processed__job_collection_title",
  "processed__job_collection_id",
  "processed__job_title_id",
  "processed__job_collection_public_title",
  "processed__job_collection_public_title_description",
  "processed__industry_title",
  "processed__job_title_match_type",
  "processed__needs_review",
  "market_analysis__collection_limits_client_accepted",
  "market_analysis__location_limits_modeled",
  "market_analysis__confidence_score",
  "market_analysis__has_surveys",
  "market_analysis__has_validations",
  "job_description",
  "city",
  "state",
  "country",
  "region",
  "is_global_supplier_search",
  "market_analysis__market_level",
  "pay_rate",
  "market_analysis__market_pay_rate_max",
  "market_analysis__market_pay_rate_max_variance",
  "markup",
  "markup_amount",
  "market_analysis__market_markup_max",
  "market_analysis__market_markup_max_variance",
  "bill_rate",
  "market_analysis__market_bill_rate_max",
  "market_analysis__market_bill_rate_max_variance",
  "annual_salary",
  "burden_perc",
  "total_cost",
  "market_analysis__market_annual_salary_max",
  "market_analysis__market_annual_salary_max_variance",
  "manager",
  "supplier",
  "company_name",
  "bonus_amount",
]) as any as ImmutableSet<string>;

export const defaultCompareWithMarketVisibleColumnsKeys = Set([
  "market_analysis__refresh_timestamp",
  "category",
  "source",
  "rate_type",
  "worker_type_title",
  "job_title",
  "processed__job_collection_title",
  "processed__job_collection_id",
  "processed__job_title_match_type",
  "processed__needs_review",
  "market_analysis__collection_limits_client_accepted",
  "market_analysis__location_limits_modeled",
  "market_analysis__confidence_score",
  "processed__industry_title",
  "city",
  "state",
  "country",
  "region",
  "is_global_supplier_search",
  "market_analysis__market_level",
  "supplier",
]) as any as ImmutableSet<string>;

export const defaultSpendRatesVisibleColumnsKeys = spendRatesColumnsWhiteList;
export const defaultMarketRatesVisibleColumnsKeys = marketRatesColumnsWhiteList;
export const defaultCustomConfigVisibleColumnsKeys = customConfigColumnsWhiteList;

export const defaultCustomConfigVisibleColumnsKeysForAdmin = Set([
  "category",
  "city",
  "state",
  "country",
  "job_title",
  "job_description",
  "rate_type",
  "worker_type_title",
  "source",
  "processed__job_collection_title",
  "processed__job_collection_id",
  "processed__industry_title",
  "processed__job_title_match_type",
  "processed__needs_review",
  "market_analysis__collection_limits_client_accepted",
  "market_analysis__location_limits_modeled",
  "market_analysis__confidence_score",
  "market_analysis__has_surveys",
  "market_analysis__has_validations",
  "upload__title",
  "is_global_supplier_search",
]) as any as ImmutableSet<string>;

export const adminAllowedOnlyKeys = Set([
  "job_family",
  "is_global_supplier_search",
  "source",
  "processed__job_collection_title",
  "processed__job_collection_id",
  "processed__job_title_id",
  "processed__job_collection_public_title",
  "processed__job_collection_public_title_description",
  "processed__industry_title",
  "processed__job_title_match_type",
  "processed__needs_review",
  "market_analysis__collection_limits_client_accepted",
  "market_analysis__location_limits_modeled",
  "market_analysis__confidence_score",
  "market_analysis__has_surveys",
  "market_analysis__has_validations",
]) as any as ImmutableSet<string>;

export const getAdminAllowedOnlyKeys = (
  visibleKeysSet: ImmutableSet<string>
): ImmutableSet<string> => {
  return visibleKeysSet.filter(
    (key) => !adminAllowedOnlyKeys.includes(key!)
  ) as ImmutableSet<string>;
};
export const getAdminAllowedOnlyConfigOptions = (
  optionsList: TableConfigOptionsList
): TableConfigOptionsList => {
  return optionsList.filter(
    (option) => !adminAllowedOnlyKeys.includes(option.get("uniqueKey"))
  );
};

export const compareWithMarketConfigOptions = List(
  contractorsTableSpecs
    .filter(({ uniqueKey }) => compareWithMarketColumnsWhiteList.includes(uniqueKey))
    .map(({ uniqueKey, title }) => Map({ uniqueKey, title }))
) as TableConfigOptionsList;
export const spendRatesConfigOptions = List(
  contractorsTableSpecs
    .filter(({ uniqueKey }) => spendRatesColumnsWhiteList.includes(uniqueKey))
    .map(({ uniqueKey, title }) => Map({ uniqueKey, title }))
) as TableConfigOptionsList;
export const marketRatesConfigOptions = List(
  contractorsTableSpecs
    .filter(({ uniqueKey }) => marketRatesColumnsWhiteList.includes(uniqueKey))
    .map(({ uniqueKey, title, valueLevel, valueType, valueSubtype }) =>
      Map({
        uniqueKey,
        title,
        valueLevel,
        valueType,
        valueSubtype,
      })
    )
) as MarketRatesTableConfigOptionsList;
export const customConfigOptions = List(
  contractorsTableSpecs
    .filter(({ uniqueKey }) => customConfigColumnsWhiteList.includes(uniqueKey))
    .map(({ uniqueKey, title }) => Map({ uniqueKey, title }))
) as TableConfigOptionsList;

const showTableColumnsConfigTooltip = "Click to configure columns set";

const TabButton = styled("button", {
  padding: "0 $4",
  whiteSpace: "nowrap",
  background: "none",
  border: "none",
  userSelect: "none",
  variants: {
    disabled: {
      true: {
        color: "$primaryLight !important",
        cursor: "not-allowed !important",
        "&:hover": {
          textDecoration: "none",
        },
      },
    },
    active: {
      true: {
        color: "$brand !important",
        "& .svg-inline--fa:hover": {
          transform: "scale(1.2)",
        },
      },
    },
  },
  defaultVariants: {
    disabled: false,
    active: false,
  },
});

const TabsPannel = styled(Box, {
  position: "absolute",
  right: 0,
  top: "-37px",
  padding: "$2 0",
  display: "inline-block",
  backgroundColor: "$white",
  borderWidth: "1px 1px 0",
  borderStyle: "solid",
  borderColor: "$primaryLight",
  cursor: "pointer",

  [`& ${TabButton}`]: {
    borderRight: "1px solid $primaryLight",
    "&:last-child": {
      borderRight: 0,
    },
  },
});

const getVisibleColumns = (props: ContractorsTableProps) =>
  props.visibleColumns || emptyMap;

const getCompareWithMarketVisibleColumnsKeys = (
  props: ContractorsTableProps
): VisibleColumnsSet => {
  const compareWithMarketVisibleColumnsKeys =
    getVisibleColumns(props).get(
      TABLE_TABS_TYPES.COMPARE_WITH_MARKET,
      props.compareWithMarketVisibleColumnsKeys
    ) || (emptySet as VisibleColumnsSet);

  return props.isPTAdmin
    ? compareWithMarketVisibleColumnsKeys
    : getAdminAllowedOnlyKeys(compareWithMarketVisibleColumnsKeys);
};

const getSpendRatesVisibleColumnsKeys = (
  props: ContractorsTableProps
): VisibleColumnsSet => {
  const spendRatesVisibleColumnsKeys =
    getVisibleColumns(props).get(
      TABLE_TABS_TYPES.SPEND_RATES,
      props.spendRatesVisibleColumnsKeys
    ) || emptySet;

  return props.isPTAdmin
    ? spendRatesVisibleColumnsKeys
    : getAdminAllowedOnlyKeys(spendRatesVisibleColumnsKeys);
};

const getMarketRatesVisibleColumnsKeys = (
  props: ContractorsTableProps
): VisibleColumnsSet => {
  const marketRatesVisibleColumnsKeys =
    getVisibleColumns(props).get(
      TABLE_TABS_TYPES.MARKET_RATES,
      props.marketRatesVisibleColumnsKeys
    ) || emptySet;

  return props.isPTAdmin
    ? marketRatesVisibleColumnsKeys
    : getAdminAllowedOnlyKeys(marketRatesVisibleColumnsKeys);
};

const getCustomConfigVisibleColumnsKeys = (
  props: ContractorsTableProps
): VisibleColumnsSet => {
  const customConfigVisibleColumnsKeys =
    getVisibleColumns(props).get(
      TABLE_TABS_TYPES.CUSTOM_CONFIG,
      props.customConfigVisibleColumnsKeys
    ) || emptySet;

  return props.isPTAdmin
    ? customConfigVisibleColumnsKeys
    : getAdminAllowedOnlyKeys(customConfigVisibleColumnsKeys);
};

const getVisibleColumnsKeysForTab = (
  action: TABLE_TABS_TYPES_TYPE,
  props: ContractorsTableProps
): VisibleColumnsSet => {
  let visibleColumns = emptySet;

  if (action === TABLE_TABS_TYPES.COMPARE_WITH_MARKET) {
    visibleColumns = getCompareWithMarketVisibleColumnsKeys(props);
  } else if (action === TABLE_TABS_TYPES.SPEND_RATES) {
    visibleColumns = getSpendRatesVisibleColumnsKeys(props);
  } else if (action === TABLE_TABS_TYPES.MARKET_RATES) {
    visibleColumns = getMarketRatesVisibleColumnsKeys(props);
  } else if (action === TABLE_TABS_TYPES.CUSTOM_CONFIG) {
    visibleColumns = getCustomConfigVisibleColumnsKeys(props);
  }

  return visibleColumns;
};

const getVisibleColumnsKeysForActiveTab = (
  props: ContractorsTableProps
): VisibleColumnsSet => {
  return getVisibleColumnsKeysForTab(props.activeTab, props);
};

const getCompareWithMarketConfigOptions = (
  props: ContractorsTableProps
): TableConfigOptionsList => {
  const { isPTAdmin, compareWithMarketConfigOptions } = props;
  return isPTAdmin
    ? compareWithMarketConfigOptions
    : getAdminAllowedOnlyConfigOptions(compareWithMarketConfigOptions);
};

const getSpendRatesConfigOptions = (
  props: ContractorsTableProps
): TableConfigOptionsList => {
  const { isPTAdmin, spendRatesConfigOptions } = props;
  return isPTAdmin
    ? spendRatesConfigOptions
    : getAdminAllowedOnlyConfigOptions(spendRatesConfigOptions);
};

const getMarketRatesConfigOptions = (
  props: ContractorsTableProps
): MarketRatesTableConfigOptionsList => {
  const { isPTAdmin, marketRatesConfigOptions } = props;
  return isPTAdmin
    ? marketRatesConfigOptions
    : getAdminAllowedOnlyConfigOptions(marketRatesConfigOptions);
};

const getCustomConfigOptions = (props: ContractorsTableProps): TableConfigOptionsList => {
  const { isPTAdmin, customConfigOptions } = props;
  return isPTAdmin
    ? customConfigOptions
    : getAdminAllowedOnlyConfigOptions(customConfigOptions);
};

const isSchemaChangedPredicate = (
  tableProps: ContractorsTableProps,
  nextTableProps: ContractorsTableProps
): boolean => {
  const { actions } = tableProps;

  for (let i = 0; i < actions.length; i++) {
    const action = actions[i];
    const visibleColumnsKeys = getVisibleColumnsKeysForTab(action, tableProps);
    const nextVisibleColumnsKeys = getVisibleColumnsKeysForTab(action, nextTableProps);

    if (!visibleColumnsKeys.equals(nextVisibleColumnsKeys)) {
      return true;
    }
  }

  return isSchemaChangedPredicateBase(tableProps.children, nextTableProps.children);
};

const processColumnFunction = (
  columnElement: ColumnLikeElement<ContractorDataMap>,
  tableProps: ContractorsTableProps
): ColumnConfigObject<ContractorDataMap> | null => {
  if (columnElement && columnElement.props) {
    if (!!columnElement.props.fixed) {
      return processColumnFunctionBase(columnElement);
    }

    if (columnElement.props.uniqueKey) {
      const visibleColumns = getVisibleColumnsKeysForActiveTab(tableProps);

      if (
        visibleColumns.size === 0 ||
        visibleColumns.includes(columnElement.props.uniqueKey)
      ) {
        return processColumnFunctionBase(columnElement);
      }
    }
  }

  return null;
};

export const processGroupFunction = (
  groupElement: GroupLikeElement<ContractorDataMap>,
  tableProps: ContractorsTableProps,
  idxOffset: number,
  processColumn: typeof processColumnFunction = processColumnFunction
): [ColumnConfigObject<ContractorDataMap>[], GroupConfigObject] => {
  const { children, ...restGroupProps } = groupElement.props;
  const columnsConfigs: ColumnConfigObject<ContractorDataMap>[] = [];
  const groupConfig: GroupConfigObject = {
    ...restGroupProps,
    columns: [],
  };

  let idx = 0;
  React.Children.forEach(children, (columnElement) => {
    if (columnElement && columnElement.props?.children == null) {
      const columnConfig = processColumn(columnElement, tableProps);

      if (columnConfig) {
        columnsConfigs.push(columnConfig);
        groupConfig.columns.push(idxOffset + idx);
        idx++;
      }
    }
  });

  return [columnsConfigs, groupConfig];
};

export const processSchemaFunction = (
  tableProps: ContractorsTableProps,
  processColumn: typeof processColumnFunction = processColumnFunction,
  processGroup: typeof processGroupFunction = processGroupFunction
): SchemaConfigObject<ContractorDataMap> => {
  const { children } = tableProps;
  const schemaConfig = {
    columns: [],
    groups: [],
  } as SchemaConfigObject<ContractorDataMap>;

  React.Children.forEach(children, (element) => {
    if (element && element.props?.children == null) {
      const columnConfig = processColumn(
        element as ColumnLikeElement<ContractorDataMap>,
        tableProps
      );

      if (columnConfig) {
        schemaConfig.groups.push({
          uniqueKey: columnConfig.uniqueKey,
          title: "",
          columns: [schemaConfig.columns.length],
        });
        schemaConfig.columns.push(columnConfig);
      }
    } else if (element) {
      const [columnsConfigs, group] = processGroup(
        element as GroupLikeElement<ContractorDataMap>,
        tableProps,
        schemaConfig.columns.length,
        processColumn
      );

      if (columnsConfigs.length > 0) {
        schemaConfig.columns = schemaConfig.columns.concat(columnsConfigs);
        schemaConfig.groups.push(group);
      }
    }
  });

  return schemaConfig;
};

const useTableSchemaState = (
  tableProps: ContractorsTableProps,
  isSchemaChanged: typeof isSchemaChangedPredicate = isSchemaChangedPredicate,
  processSchema: typeof processSchemaFunction = processSchemaFunction,
  processColumn: typeof processColumnFunction = processColumnFunction,
  processGroup: typeof processGroupFunction = processGroupFunction
): [
  SchemaConfigObject<ContractorDataMap>,
  React.Dispatch<React.SetStateAction<SchemaConfigObject<ContractorDataMap>>>
] => {
  const [schemaState, setSchemaState] = React.useState<
    SchemaConfigObject<ContractorDataMap>
  >(processSchema(tableProps, processColumn, processGroup));

  const prevPropsRef = React.useRef(tableProps);
  React.useEffect(() => {
    if (isSchemaChanged(prevPropsRef.current, tableProps)) {
      setSchemaState(processSchema(tableProps, processColumn, processGroup));
    }
    prevPropsRef.current = tableProps;
  }, [tableProps, isSchemaChanged, processSchema, processColumn, processGroup]);

  return [schemaState, setSchemaState];
};

export type TableConfigChangesObject = {
  activeTab: TABLE_TABS_TYPES_TYPE;
  visibleColumns?: ContractorsTableTabsVisibleColumnsMap;
  itemsPerPage?: number;
};

export interface ContractorsTableProps
  extends Omit<TableFilterableEditableRestfulProps, "schema"> {
  isPTAdmin: boolean;
  visibleColumns: ContractorsTableTabsVisibleColumnsMap;
  actions: TABLE_TABS_TYPES_TYPE[];
  activeTab: TABLE_TABS_TYPES_TYPE;
  //
  allowedActions: TABLE_TABS_TYPES_TYPE[];
  compareWithMarketVisibleColumnsKeys: VisibleColumnsSet;
  spendRatesVisibleColumnsKeys: VisibleColumnsSet;
  marketRatesVisibleColumnsKeys: VisibleColumnsSet;
  customConfigVisibleColumnsKeys: VisibleColumnsSet;
  //
  compareWithMarketConfigOptions: TableConfigOptionsList;
  spendRatesConfigOptions: TableConfigOptionsList;
  marketRatesConfigOptions: MarketRatesTableConfigOptionsList;
  customConfigOptions: TableConfigOptionsList;
  //
  onChangeTableConfig: (changes: TableConfigChangesObject) => Promise<void>;
}

export const ContractorsTable = (props: ContractorsTableProps) => {
  const {
    actions,
    activeTab,
    visibleColumns,
    allowedActions,
    //
    compareWithMarketVisibleColumnsKeys,
    spendRatesVisibleColumnsKeys,
    marketRatesVisibleColumnsKeys,
    customConfigVisibleColumnsKeys,
    //
    compareWithMarketConfigOptions,
    spendRatesConfigOptions,
    marketRatesConfigOptions,
    customConfigOptions,
    //
    onChangeTableConfig,
    ...tableProps
  } = props;
  const { data, itemsPerPage } = tableProps;
  const { showLoader, hideLoader } = usePLIContext();

  // effects

  React.useEffect(() => {
    hideLoader();
  }, [data, activeTab, visibleColumns, hideLoader]);

  // state

  const [schemaState] = useTableSchemaState(props);
  const {
    modalState: compareWithMarketConfigModalState,
    showModal: showCompareWithMarketConfigModal,
    closeModal: closeCompareWithMarketConfigModal,
  } = useModalState();
  const {
    modalState: spendRatesConfigModalState,
    showModal: showSpendRatesConfigModal,
    closeModal: closeSpendRatesConfigModal,
  } = useModalState();
  const {
    modalState: marketRatesConfigModalState,
    showModal: showMarketRatesConfigModal,
    closeModal: closeMarketRatesConfigModal,
  } = useModalState();
  const {
    modalState: customConfigModalState,
    showModal: showCustomConfigModal,
    closeModal: closeCustomConfigModal,
  } = useModalState();

  // handlers

  const handleChangeTableConfig = React.useCallback(
    async (
      tab: TABLE_TABS_TYPES_TYPE,
      visibleColumnsSet: VisibleColumnsSet | null,
      itemsPerPage: number | null
    ) => {
      if (actions.indexOf(tab) >= 0 && onChangeTableConfig) {
        await showLoader();

        const changesObject: TableConfigChangesObject = { activeTab: tab };

        if (visibleColumnsSet != null) {
          changesObject["visibleColumns"] = visibleColumns.set(tab, visibleColumnsSet);
        }
        if (itemsPerPage != null) {
          changesObject["itemsPerPage"] = itemsPerPage;
        }

        setTimeout(() => onChangeTableConfig(changesObject), 0);
      }
    },
    [actions, visibleColumns, onChangeTableConfig, showLoader]
  );

  const handleActivateCompareWithMarketTab = React.useCallback(async () => {
    if (activeTab !== TABLE_TABS_TYPES.COMPARE_WITH_MARKET) {
      await handleChangeTableConfig(TABLE_TABS_TYPES.COMPARE_WITH_MARKET, null, null);
    }
  }, [activeTab, handleChangeTableConfig]);

  const handleActivateSpendRatesTab = React.useCallback(async () => {
    if (activeTab !== TABLE_TABS_TYPES.SPEND_RATES) {
      await handleChangeTableConfig(TABLE_TABS_TYPES.SPEND_RATES, null, null);
    }
  }, [activeTab, handleChangeTableConfig]);

  const handleActivateMarketRatesTab = React.useCallback(async () => {
    if (activeTab !== TABLE_TABS_TYPES.MARKET_RATES) {
      await handleChangeTableConfig(TABLE_TABS_TYPES.MARKET_RATES, null, null);
    }
  }, [activeTab, handleChangeTableConfig]);

  const handleActivateCustomConfigTab = React.useCallback(async () => {
    if (activeTab !== TABLE_TABS_TYPES.CUSTOM_CONFIG) {
      await handleChangeTableConfig(TABLE_TABS_TYPES.CUSTOM_CONFIG, null, null);
    }
  }, [activeTab, handleChangeTableConfig]);

  // rendering

  let compareWithMarketTab = null;
  if (actions.indexOf(TABLE_TABS_TYPES.COMPARE_WITH_MARKET) >= 0) {
    const isActive = activeTab === TABLE_TABS_TYPES.COMPARE_WITH_MARKET;

    compareWithMarketTab = (
      <TabButton active={isActive} onClick={handleActivateCompareWithMarketTab}>
        Compare With Market&nbsp;&nbsp;
        <Icon
          icon="cog"
          onClick={isActive ? showCompareWithMarketConfigModal : undefined}
          title={showTableColumnsConfigTooltip}
        />
      </TabButton>
    );
  }

  let spendRatesTab = null;
  if (actions.indexOf(TABLE_TABS_TYPES.SPEND_RATES) >= 0) {
    const isActive = activeTab === TABLE_TABS_TYPES.SPEND_RATES;

    spendRatesTab = (
      <TabButton active={isActive} onClick={handleActivateSpendRatesTab}>
        Current Spend Rates&nbsp;&nbsp;
        <Icon
          icon="cog"
          onClick={isActive ? showSpendRatesConfigModal : undefined}
          title={showTableColumnsConfigTooltip}
        />
      </TabButton>
    );
  }

  let marketRatesTab = null;
  if (actions.indexOf(TABLE_TABS_TYPES.MARKET_RATES) >= 0) {
    const isActive = activeTab === TABLE_TABS_TYPES.MARKET_RATES;

    marketRatesTab = (
      <TabButton active={isActive} onClick={handleActivateMarketRatesTab}>
        Market Rates&nbsp;&nbsp;
        <Icon
          icon="cog"
          onClick={isActive ? showMarketRatesConfigModal : undefined}
          title={showTableColumnsConfigTooltip}
        />
      </TabButton>
    );
  }

  let customConfigTab = null;
  if (actions.indexOf(TABLE_TABS_TYPES.CUSTOM_CONFIG) >= 0) {
    const isActive = activeTab === TABLE_TABS_TYPES.CUSTOM_CONFIG;

    customConfigTab = (
      <TabButton active={isActive} onClick={handleActivateCustomConfigTab}>
        Custom Table Config&nbsp;&nbsp;
        <Icon
          icon="cog"
          onClick={isActive ? showCustomConfigModal : undefined}
          title={showTableColumnsConfigTooltip}
        />
      </TabButton>
    );
  }

  let compareWithMarketConfigModal = null;
  let spendRatesConfigModal = null;
  let marketRatesConfigModal = null;
  let customTabConfigModal = null;

  if (actions.indexOf(TABLE_TABS_TYPES.COMPARE_WITH_MARKET) >= 0) {
    const compareWithMarketVisibleColumnsKeys =
      getCompareWithMarketVisibleColumnsKeys(props);
    const compareWithMarketConfigOptions = getCompareWithMarketConfigOptions(props);
    const compareWithMarketConfigOptionsKeys = compareWithMarketConfigOptions
      .map((option) => option.get("uniqueKey"))
      .toSet();

    compareWithMarketConfigModal = (
      <CompareWithMarketTableConfigModal
        options={compareWithMarketConfigOptions}
        visibleColumns={compareWithMarketVisibleColumnsKeys.filter((key: string) =>
          compareWithMarketConfigOptionsKeys.includes(key)
        )}
        itemsPerPage={itemsPerPage}
        onChange={(...args) =>
          handleChangeTableConfig(TABLE_TABS_TYPES.COMPARE_WITH_MARKET, ...args)
        }
        show={compareWithMarketConfigModalState}
        onHide={closeCompareWithMarketConfigModal}
      />
    );
  }

  if (actions.indexOf(TABLE_TABS_TYPES.SPEND_RATES) >= 0) {
    const spendRatesVisibleColumnsKeys = getSpendRatesVisibleColumnsKeys(props);
    const spendRatesConfigOptions = getSpendRatesConfigOptions(props);
    const spendRatesConfigOptionsKeys = spendRatesConfigOptions
      .map((option) => option.get("uniqueKey"))
      .toSet();

    spendRatesConfigModal = (
      <TableConfigModal
        options={spendRatesConfigOptions}
        visibleColumns={spendRatesVisibleColumnsKeys.filter((key: string) =>
          spendRatesConfigOptionsKeys.includes(key)
        )}
        itemsPerPage={itemsPerPage}
        onChange={(...args) =>
          handleChangeTableConfig(TABLE_TABS_TYPES.SPEND_RATES, ...args)
        }
        show={spendRatesConfigModalState}
        onHide={closeSpendRatesConfigModal}
        optionsGridCss={{
          gap: "$1",
          gridAutoFlow: "column",
          gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
          gridTemplateRows: "repeat(7, minmax(0, 1fr))",
          "@lg": {
            gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
            gridTemplateRows: "repeat(5, minmax(0, 1fr))",
          },
        }}
      />
    );
  }

  if (actions.indexOf(TABLE_TABS_TYPES.MARKET_RATES) >= 0) {
    const marketRatesVisibleColumnsKeys = getMarketRatesVisibleColumnsKeys(props);
    const marketRatesConfigOptions = getMarketRatesConfigOptions(props);
    const marketRatesConfigOptionsKeys = marketRatesConfigOptions
      .map((option) => option.get("uniqueKey"))
      .toSet();

    marketRatesConfigModal = (
      <MarketRatesTableConfigModal
        options={marketRatesConfigOptions}
        visibleColumns={marketRatesVisibleColumnsKeys.filter((key: string) =>
          marketRatesConfigOptionsKeys.includes(key)
        )}
        itemsPerPage={itemsPerPage}
        onChange={(...args) =>
          handleChangeTableConfig(TABLE_TABS_TYPES.MARKET_RATES, ...args)
        }
        show={marketRatesConfigModalState}
        onHide={closeMarketRatesConfigModal}
      />
    );
  }

  if (actions.indexOf(TABLE_TABS_TYPES.CUSTOM_CONFIG) >= 0) {
    const customConfigVisibleColumnsKeys = getCustomConfigVisibleColumnsKeys(props);
    const customConfigOptions = getCustomConfigOptions(props);
    const customConfigOptionsKeys = customConfigOptions
      .map((option) => option.get("uniqueKey"))
      .toSet();

    customTabConfigModal = (
      <TableConfigModal
        options={customConfigOptions}
        visibleColumns={customConfigVisibleColumnsKeys.filter((key: string) =>
          customConfigOptionsKeys.includes(key)
        )}
        itemsPerPage={itemsPerPage}
        onChange={(...args) =>
          handleChangeTableConfig(TABLE_TABS_TYPES.CUSTOM_CONFIG, ...args)
        }
        show={customConfigModalState}
        onHide={closeCustomConfigModal}
      />
    );
  }

  const hasSomeActions = actions.filter((a) => allowedActions.indexOf(a) >= 0).length > 0;

  const result = (
    <Box
      css={{
        position: "relative",
        marginTop: "40px",
        fontSize: "14px",
      }}
    >
      {compareWithMarketConfigModal}
      {spendRatesConfigModal}
      {marketRatesConfigModal}
      {customTabConfigModal}

      {hasSomeActions && (
        <TabsPannel>
          <Inline nogap>
            {compareWithMarketTab}
            {spendRatesTab}
            {marketRatesTab}
            {customConfigTab}
          </Inline>
        </TabsPannel>
      )}

      <TableFilterableEditableRestful {...tableProps} schema={schemaState} />
    </Box>
  );

  return result;
};

ContractorsTable.displayName = "ContractorsTable";
ContractorsTable.defaultProps = {
  ...TableFilterableEditableRestful.defaultProps,
  // TODO rename to availableTabs
  actions: [],
  activeTab: TABLE_TABS_TYPES.COMPARE_WITH_MARKET,
  visibleColumns: emptyMap,
  itemsPerPage: 50,
  //
  // TODO this prop is redundant, needs refactoring
  allowedActions: ALL_TABLE_TABS_TYPES,
  compareWithMarketVisibleColumnsKeys: defaultCompareWithMarketVisibleColumnsKeys, // immutable Set
  spendRatesVisibleColumnsKeys: defaultSpendRatesVisibleColumnsKeys, // immutable Set
  marketRatesVisibleColumnsKeys: defaultMarketRatesVisibleColumnsKeys, // immutable Set
  customConfigVisibleColumnsKeys: defaultCustomConfigVisibleColumnsKeys, // immutable Set
  //
  compareWithMarketConfigOptions: compareWithMarketConfigOptions,
  spendRatesConfigOptions: spendRatesConfigOptions,
  marketRatesConfigOptions: marketRatesConfigOptions,
  customConfigOptions: customConfigOptions,
};

export type FlagSelectType = "isSelectingRows" | "isBulkUpdateSelectingRows";
export type StartRowsSelectionHandler = (flagSelect: FlagSelectType) => void;
export type StopRowsSelectionHandler = (flagSelect: FlagSelectType) => void;
export type SelectRowHandler = (rowData: ContractorDataMap, value: CheckedState) => void;
export type SelectAllRowsOnThePageHandler = (value: CheckedState) => void;

export function useContractorsSelectionHandlers(
  setContractorsDataState: SetterOrUpdater<ContractorsTableDataStateObject>,
  selectedRows: ContractorsDataOrderedMap,
  availableRows: RestfulTableData<ContractorDataMap>
) {
  const { showModalWarning, closeConfirmationModal } = usePLIContext();

  const handleStartRowsSelection = React.useCallback<StartRowsSelectionHandler>(
    (flagSelect) => {
      closeConfirmationModal();
      setContractorsDataState((prevState: ContractorsTableDataStateObject) => ({
        ...prevState,
        [flagSelect]: true,
        selectedRows: emptyOrderedMap as unknown as ContractorsDataOrderedMap,
      }));
    },
    [setContractorsDataState, closeConfirmationModal]
  );

  const handleStopRowsSelection = React.useCallback<StopRowsSelectionHandler>(
    (flagSelect) => {
      setContractorsDataState((prevState: ContractorsTableDataStateObject) => ({
        ...prevState,
        [flagSelect]: false,
        selectedRows: emptyOrderedMap as unknown as ContractorsDataOrderedMap,
      }));
    },
    [setContractorsDataState]
  );

  const handleSelectRow = React.useCallback(
    (rowData: ContractorDataMap, value: CheckedState) => {
      const rowId = rowData.get("id");

      if (rowId != null && rowData != null) {
        const jobTitle = rowData.get("job_title");
        const status = rowData.get("status");

        if (value) {
          if (status !== CONTRACTOR_STATUSES.FINISHED) {
            showModalWarning(
              `Row #${rowId} "${jobTitle}" can't be selected for validation, it should be successfully mapped first.`
            );
            return;
          }
        }

        setContractorsDataState((prevState: ContractorsTableDataStateObject) => ({
          ...prevState,
          selectedRows: value
            ? selectedRows.set(rowId, rowData)
            : selectedRows.delete(rowId),
        }));
      }
    },
    [selectedRows, setContractorsDataState, showModalWarning]
  );

  const handleSelectAllRowsOnThePage = React.useCallback(
    (value: CheckedState) => {
      if (availableRows.size > 0) {
        const approvedRows = availableRows.filter(
          (item: ContractorDataMap) =>
            item.get("status") === CONTRACTOR_STATUSES.CLEAN ||
            item.get("status") === CONTRACTOR_STATUSES.FINISHED
        );

        if (value && approvedRows.size < availableRows.size) {
          showModalWarning(
            `Some rows can't be selected for validation, they might have inappropriate processing status.`
          );
        }

        setContractorsDataState((prevState: ContractorsTableDataStateObject) => ({
          ...prevState,
          selectedRows: value
            ? selectedRows.merge(approvedRows as ContractorsDataOrderedMap)
            : selectedRows.filter(
                (item: ContractorDataMap) => approvedRows.get(item.get("id")) == null
              ),
        }));
      }
    },
    [selectedRows, availableRows, setContractorsDataState, showModalWarning]
  );

  return {
    handleStartRowsSelection,
    handleStopRowsSelection,
    handleSelectRow,
    handleSelectAllRowsOnThePage,
  };
}

type RefreshPageDataFunc = (withProcessing: boolean) => Promise<any>;

export function useEditingRowState<RD extends RowData>(
  refreshPageData?: RefreshPageDataFunc
) {
  const { isPTAdmin } = usePLIContext();
  const [editingRowId, setEditingRowId] = React.useState<number | null>(null);

  const startRowEditing = React.useCallback(
    (rowData: RD) => {
      const rowId = rowIdGetter(rowData);
      let nextEditingRowId: number | null = rowId;

      if (rowId != null && isPTAdmin) {
        if (rowId === editingRowId) {
          nextEditingRowId = null;
        }

        if (nextEditingRowId !== editingRowId) {
          setEditingRowId(nextEditingRowId);
        }
      }
    },
    [editingRowId, isPTAdmin]
  );

  const stopRowEditing = React.useCallback(
    async (refresh: boolean = false) => {
      setEditingRowId(null);
      return refresh && refreshPageData ? refreshPageData(false) : Promise.resolve();
    },
    [refreshPageData, setEditingRowId]
  );

  return {
    editingRowId,
    startRowEditing,
    stopRowEditing,
  };
}

/**
 * Provides stats tables and charts above the contractors table
 * !!! Doesn't used currently, it was used in the partials/ContractorsList.jsx component !!!
 */
// class ContractorsTableWithChartsAndStats extends ContractorsTableWithActions {
//   static displayName = 'ContractorsTableWithChartsAndStats'
//   static propTypes = {
//     ...ContractorsTableWithActions.propTypes,
//     rates: PropTypes.object
//   }

//   ratesDataProvider(queryArgs) {
//     return this.context.flux
//         .getActions('privateIndex')
//         .getRatesList(queryArgs);
//   }

//   _onApplyFilter(filterKey, filterValue) {
//     // merge filters
//     const { filters: currentFilters } = this._getSource();
//     const filters = this.mergeFilters(currentFilters, filterKey, filterValue);
//     let columnsFiltersQueries = emptyOrderedMap;
//     let filtersQuery = emptyOrderedMap;

//     // get real query args for the Django backend
//     [filtersQuery, columnsFiltersQueries] = this.transformFilters(filters, true);

//     return Promise.all([
//       this.ratesDataProvider(filtersQuery.toJS()),
//       super._onApplyFilter(filterKey, filterValue)
//     ]).then(data => {
//       this.context.hideModal();
//       return data[0];
//     });
//   }

//   _onClearFilters(...args) {
//     return Promise.all([
//       this.ratesDataProvider(),
//       super._onClearFilters.apply(this, args)
//     ]).then(data => {
//       this.context.hideModal();
//       return data[0];
//     });
//   }

//   _onChangePage(...args) {
//     return super._onChangePage.apply(this, args)
//                 .then(() => this.context.hideModal());
//   }

//   _onGetChartsSnapshot = () => {
//     const leftChart = this.refs.left_chart;
//     const rightChart = this.refs.right_chart;
//     const exportOptions = {
//       type: 'image/png',
//       scale: 1
//     };
//     const chartOptions = {
//       chart: {
//         backgroundColor: '#FFFFFF',
//         borderColor: '#000000',
//         borderRadius: 3,
//         borderWidth: 1
//       },
//       plotOptions: {
//         area: {
//           dataLabels: {
//             enabled: true
//           }
//         }
//       }
//     };

//     return Promise.all([
//       leftChart.exportToBase64(exportOptions, chartOptions),
//       rightChart.exportToBase64(exportOptions, chartOptions),
//     ]).then(data => ([
//       preprocessImageData(data[0]),
//       preprocessImageData(data[1])
//     ]));
//   }

//   render() {
//     const { rates } = this.props;

//     return (
//       <div>
//         <Panel header="Private Index">
//           <div className="pt-stats-table">
//             <Row>
//               <Col md={2} lg={1} className="pt-stats-table__titles text-center hidden-xs hidden-sm">
//                 <div>Low</div>
//                 <div>High</div>
//                 <div>Median</div>
//                 <div>Average</div>
//               </Col>
//               <Col sm={6} md={2}>
//                 <PayRatesStatsTableColumn className="pt-stats-table__pay-rate" data={rates} />
//               </Col>
//               <Col sm={6} md={2}>
//                 <BillRatesStatsTableColumn className="pt-stats-table__bill-rate" data={rates} />
//               </Col>
//               <Col sm={6} md={2}>
//                 <MarkupStatsTableColumn className="pt-stats-table__markup" data={rates} />
//               </Col>
//               <Col sm={6} md={2}>
//                 <MarkupAmountStatsTableColumn className="pt-stats-table__markup-amount" data={rates} />
//               </Col>
//               <Col sm={12} md={2} lg={3}>
//                 <AnnualRatesStatsTableColumn className="pt-stats-table__annual-salary" data={rates} />
//               </Col>
//             </Row>
//           </div>
//         </Panel>

//         <Row>
//           <Col sm={12} md={6}>
//             <PayNBillChart
//                 ref="left_chart"
//                 data={rates} />
//           </Col>
//           <Col sm={12} md={6}>
//             <MarkupChart
//                 ref="right_chart"
//                 data={rates} />
//           </Col>
//         </Row>

//         { super.render() }
//       </div>
//     );
//   }
// }
