// Class required for displaying details in the expanded view of the RateCards
import { extendObservable } from "mobx";
export class PunchOutClass {
  constructor(store, object, ratecardId, searchId, buyrateId) {
    this.store = store;
    this.punchoutId = null;
    this.levelName = null;
    this.levelId = null;
    this.billRateAvg = 0;
    this.billRateMax = 0;
    this.billRateMid = 0;
    this.billRateMin = 0;
    this.markupPctAvg = 0;
    this.markupPctMax = 0;
    this.markupPctMid = 0;
    this.markupPctMin = 0;
    this.payRateMin = 0;
    this.payRateMid = 0;
    this.payRateMax = 0;
    this.payRateAvg = 0;
    this.rateCardId = null;
    this.searchId = null;
    this.buyrateId = null;
    // Required for selecting a punchout

    extendObservable(this, {
      viewState: {
        selected: false,
        alreadyAdded: false,
      },
    });

    if (object) {
      this.levelName = object.level.value;
      this.levelRomanNumeral = object.level.romanNumeral;
      this.levelId = object.level.legacyId;
      this.billRateAvg = object.billRateAvg || 0;
      this.billRateMax = object.billRateMax || 0;
      this.billRateMid = object.billRateMid || 0;
      this.billRateMin = object.billRateMin || 0;
      this.markupPctAvg = object.markupPctAvg || 0;
      this.markupPctMax = object.markupPctMax || 0;
      this.markupPctMid = object.markupPctMid || 0;
      this.markupPctMin = object.markupPctMin || 0;
      this.payRateMin = object.payRateMin || 0;
      this.payRateMid = object.payRateMid || 0;
      this.payRateMax = object.payRateMax || 0;
      this.payRateAvg = object.payRateAvg || 0;
      this.punchoutId = object.punchoutId;
      this.rateCardId = ratecardId || null;
      this.searchId = searchId || null;
      this.buyrateId = buyrateId || null;
    }
  }

  static fromJS(store, object) {
    return new PunchOutClass(store, object);
  }
}

// BuyRateClass need for assembling the buyrates achieved from the Searches of a RateCard
export class BuyRateClass {
  constructor(store, object, ratecardId, searchId) {
    this.store = store;
    this.buyrateId = null;
    this.name = null;

    extendObservable(this, {
      punchout: [],
      punchouts: null,
    });

    if (object) {
      this.buyrateId = object.buyrateId || null;
      this.name = object.name || null;
      this.punchouts = object.punchouts || null;
      if (this.punchouts.length !== 0) {
        this.punchout = this.punchouts.map((po) => {
          const punchouts = new PunchOutClass(
            this,
            po,
            ratecardId,
            searchId,
            this.buyrateId
          );

          return punchouts;
        });
      }
    }
  }

  static fromJS(store, object) {
    return new BuyRateClass(store, object);
  }
}

// Need for RateCardSearchDetails required for displaying in the expanded view of the RateCards displayed while creating a worksheet
export default class RateCardSearchDetails {
  constructor(store, object, rateCardId) {
    this.store = store;
    this.searchId = null;
    this.name = null;
    this.state = null;
    this.country = null;
    this.currency = null;
    this.jobLabel = null;

    extendObservable(this, {
      buyrates: [],
      buyrate: [],
    });

    if (object) {
      this.searchId = object.searchId || null;
      this.jobLabel = object.job.jobLabel || null;
      this.name = object.job.jobTitle || null;
      this.state = object.state || null;
      this.country = object.country || null;
      this.currency = object.currency || null;
      this.buyrates = object.buyrates || null;

      if (this.buyrates.length !== 0) {
        this.buyrate = this.buyrates.map((br) => {
          const buyRates = new BuyRateClass(this, br, rateCardId, this.searchId);

          return buyRates;
        });
      }
    }
  }

  static fromJS(store, object) {
    return new RateCardSearchDetails(store, object);
  }
}
