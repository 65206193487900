import { css, styled } from "../../stitches.config";

export const inputBorderStyle = css({
  borderRadius: "$rounded",
  border: "2px solid $primaryLight",
  transitionProperty: "background-color, border-color, color, fill, stroke",
  transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
  transitionDuration: "150ms",
  "&:hover, &:focus": {
    outline: "none",
    boxShadow: "none",
  },
});

export const TextArea = styled("textarea", inputBorderStyle, {
  borderWidth: 1,
  "&:disabled": {
    opacity: 0.55,
    backgroundColor: "$primaryLighter",
  },
  variants: {
    color: {
      primary: {
        backgroundColor: "transparent",
        borderColor: "$primaryLight",
        color: "$primaryDarker",
        "&::placeholder": {
          color: "$primary",
        },
        "&:not([disabled]):focus": {
          borderColor: "$brand",
        },
      },
      danger: {
        backgroundColor: "transparent",
        borderColor: "$danger",
        color: "$primaryDarker",
        "&::placeholder": {
          color: "$primary",
        },
        "&:not([disabled]):focus": {
          borderColor: "$dangerDark",
        },
      },
    },
    size: {
      "extra-small": {
        padding: "$1 $2",
        fontSize: "$xs",
        lineHeight: "$xs",
      },
      small: {
        padding: "$1_5 $2_5",
        fontSize: "$sm",
        lineHeight: "$sm",
      },
      normal: {
        padding: "calc($2 + 1px) calc($3 + 1px)",
        fontSize: "$base",
        lineHeight: "$base",
      },
      large: {
        padding: "$2_5 $4",
        fontSize: "$lg",
        lineHeight: "$lg",
      },
      "extra-large": {
        padding: "$3 $4",
        fontSize: "$2xl",
        lineHeight: "$2xl",
      },
    },
    fill: {
      true: {
        width: "100%",
      },
    },
    deep: {
      true: {
        backgroundColor: "$secondaryLightest",
        borderColor: "$secondaryLightest",
      },
    },
  },
  defaultVariants: {
    color: "primary",
    size: "normal",
    fill: false,
    deep: false,
  },
});
TextArea.displayName = "TextArea";

export default TextArea;
