// @flow
import * as React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import ContainerSection from "../../../ratecards/components/ContainerSection";
import LoadingIndicator from "../../../shared/LoadingIndicator";
import CheckBox from "../../../shared/CheckBox";

const PceRateCardDetail = ({ store, expanded, createstore, ratecardId }) => {
  const network = store.network;
  const searchList = store.rateCardSearch;

  const className = classNames("search-peek", {
    "search-peek-expand": expanded,
    "search-peek-collapse": !expanded,
  });

  let tableContent = null;
  if (network.loading) {
    tableContent = <LoadingIndicator />;
  } else if (network.error) {
    tableContent = (
      <div className="pt-error-message">
        <h2>Error</h2>
        <pre>
          <code>{JSON.stringify(network.error, null, 2)}</code>
        </pre>
      </div>
    );
  } else if (searchList.length === 0) {
    tableContent = (
      <div className="ratecards-table-empty">
        {Object.keys(store.appliedFilters).length > 0 ? (
          <div>No Hourly Searches Found</div>
        ) : (
          <div>No Hourly Searches Found</div>
        )}
      </div>
    );
  } else {
    tableContent = searchList.map((sl) => (
      <RateCardSearches
        key={sl.searchId}
        search={sl}
        createstore={createstore}
        ratecardId={ratecardId}
        store={store}
      />
    ));
  }

  return (
    <div className={className}>
      <div className="search-peek-body">
        <ContainerSection className="overlap-b-padding no-border search-peek-levels flex-wrap">
          {tableContent}
        </ContainerSection>
      </div>
    </div>
  );
};

const RateCardSearches = observer((props) => {
  const search = props.search;
  const name = search.name;
  const state = search.state;
  const country = search.country;
  const buyrates = search.buyrate;

  let buyratesList = null;
  // Display error message when No buy rate is found
  if (buyrates.length === 0) {
    buyratesList = "No Buy Rate Found";
  } else {
    buyratesList = buyrates.map((buyrate) => (
      <RateCardSearchDetails
        key={buyrate.buyrateId}
        buyRate={buyrate}
        createstore={props.createstore}
        ratecardId={props.ratecardId}
        searchId={search.searchId}
        store={props.store}
      />
    ));
  }

  return (
    <div className="view">
      <div className="rc-container bring-forward">
        <div className="info_head saved-search-details">
          <h2>{name}</h2>
          <div className="search-title ellipsis">
            <span className="item-subtext-accent">{state}</span>
            <span className="item-subtext-muted"> in</span>
            <span className="item-subtext-accent"> {country}</span>
          </div>
        </div>
        <div className="clearfix" />
        {buyratesList}
      </div>
    </div>
  );
});

const RateCardSearchDetails = observer((props) => {
  const buyrate = props.buyRate;
  const name = buyrate.name;
  const id = buyrate.buyrateId;
  const punchouts = buyrate.punchout;

  let punchOutList = null;
  let punchoutTable = null;
  let rowListLengthArray = [1, 2, 3];
  let rowList = rowListLengthArray.map((row) => <Rows key={row} />);

  if (punchouts.length === 0) {
    punchoutTable = "No Available punchouts";
  } else {
    punchOutList = punchouts.map((buyRatepunchout) => (
      <Punchouts
        key={buyRatepunchout.levelId}
        punchout={buyRatepunchout}
        createstore={props.createstore}
        buyrateId={id}
        ratecardId={props.ratecardId}
        searchId={props.searchId}
        store={props.store}
      />
    ));
    punchoutTable = (
      <table className="no-spacing table table_label" cellSpacing="0">
        <thead className="">
          <tr>
            <th className="col-sm-1">Level</th>
            <th className="col-sm-3">Pay Rate</th>
            <th className="col-sm-3">Suggested Markup</th>
            <th className="col-sm-3">Suggested Bill Rate</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td />
            {rowList}
          </tr>
          {punchOutList}
        </tbody>
      </table>
    );
  }

  return (
    <div className="clearfix ratecard_new_table">
      <h4>{name}</h4>
      <br />
      {punchoutTable}
      <div className="clearfix" />
      <div className="clearfix" />
    </div>
  );
});

// Display punchout row with checkbox and the rest of the details
const Punchouts = observer((props) => {
  const punchout = props.punchout;
  const level = punchout.levelName;
  const pcstore = props.store;
  const selected = punchout.viewState.selected;

  return (
    <tr>
      <td>
        <CheckBox
          type="checkbox"
          name="list-item-select"
          value={level}
          selected={selected}
          style={{
            width: 34,
          }}
          onClick={(e) => pcstore.togglePunchoutSelect(punchout)}
        />{" "}
        {level}
      </td>
      <td>
        <span className="col-sm-3">{punchout.payRateAvg}</span>
        <span className="col-sm-3">{punchout.payRateMin}</span>
        <span className="col-sm-3">{punchout.payRateMid}</span>
        <span className="col-sm-3">{punchout.payRateMax}</span>
      </td>
      <td>
        <span className="col-sm-3">{punchout.markupPctAvg}%</span>
        <span className="col-sm-3">{punchout.markupPctMin}%</span>
        <span className="col-sm-3">{punchout.markupPctMid}%</span>
        <span className="col-sm-3">{punchout.markupPctMax}%</span>
      </td>
      <td>
        <span className="col-sm-3">{punchout.billRateAvg}</span>
        <span className="col-sm-3">{punchout.billRateMin}</span>
        <span className="col-sm-3">{punchout.billRateMid}</span>
        <span className="col-sm-3">{punchout.billRateMax}</span>
      </td>
    </tr>
  );
});

const Rows = observer((props) => {
  return (
    <td>
      <span className="col-sm-3">Avg</span>
      <span className="col-sm-3">Min</span>
      <span className="col-sm-3">Mid</span>
      <span className="col-sm-3">Max</span>
    </td>
  );
});

export default observer(PceRateCardDetail);
