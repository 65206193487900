/**
 * @generated SignedSource<<d0e884a1789d641aa79ca8bbccf38519>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SettingsMenuSearchResutls_viewer$data = {
  readonly savedsearch: {
    readonly id: string;
    readonly rateType: number | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"CurrencyDropdown_viewer">;
  readonly " $fragmentType": "SettingsMenuSearchResutls_viewer";
};
export type SettingsMenuSearchResutls_viewer$key = {
  readonly " $data"?: SettingsMenuSearchResutls_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingsMenuSearchResutls_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingsMenuSearchResutls_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurrencyDropdown_viewer"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "searchId"
        }
      ],
      "concreteType": "SaveSearchNode",
      "kind": "LinkedField",
      "name": "savedsearch",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rateType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "40c89bd86b5cb5c106f909b5a1d061b6";

export default node;
