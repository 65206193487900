import * as React from "react";
import { styled } from "../stitches.config";

const StyledH3 = styled("h3", {
  marginBottom: "$2 !important",
});

type Props = {
  children?: React.ReactNode;
  text?: string;
};

function PageTitle(props: Props): JSX.Element {
  return (
    <StyledH3>
      {props.text}
      {props.children}
    </StyledH3>
  );
}

export default PageTitle;
