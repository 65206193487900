// @flow
import * as React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { FetchGraphQL, UpdateLoginState } from "../../App";
import SessionInfo from "../../models/SessionInfo";
import Message from "../../models/Message";
import type MobXStore from "../../stores/mobx/MobXStore";
import TextInput from "../../components/lib/TextInput";
import Stack from "../../components/lib/Stack";
import Button from "../../components/lib/Button";
import { Link } from "../../components/lib/Link";
import Box from "../../components/lib/Box";
import { styled } from "../../stitches.config";
import Logo from "../../components/Logo";
import Inline, { InlineElements } from "../../components/lib/Inline";
import Footer from "../../components/lib/Footer";

export const LoginBox = styled("div", {
  "&.box-login": {
    borderTopColor: "$brand",
    "& .box-header": { backgroundColor: "$secondaryLight" },
  },
});

type Props = {
  store: MobXStore,
  router: Object,
  location: {
    query: Object,
  },
  fetchGraphQL: FetchGraphQL,
  updateLoginState: UpdateLoginState,
};

type State = {
  showForgotPasswordForm: boolean,
  username: string,
  password: string,
  loginMessages: Message[],
  email: string,
  forgotPasswordMessages: Message[],
  loading: boolean,
  loginReqLimitReached: boolean,
};

const loginQuery = `
mutation LoginMutation($loginData : LoginInput) {
  loginUser(loginData: $loginData) {
    user {
      userId
      username
      firstName
      lastName
      email
      termsOfAgreement
      roles
      legacySession
      client {
        id
        legacyId
        title
        legacyClient {
          perSearchPricing
          isClientJobLibrary
          ratecardSubscriptionFlag
          termsOfAgreement
        }
      }
    }
    errors {
      __typename
      ...on UserNamePassWordRequiredError{
        message
      }
      ...on InvalidCredentialError{
        message
      }
      ...on UserLoginFormError{
        message
      }
    }
  }
}
`;

type loginQueryResponse = {
  data?: ?{
    loginUser: ?{
      user: ?{
        userId: string,
        username: string,
        firstName: string,
        lastName: string,
        email: string,
        termsOfAgreement: boolean,
        roles: string[],
        legacySession: string,
        client: ?{
          id: string,
          legacyId: string,
          title: string,
          legacyClient: ?{
            perSearchPricing: boolean,
            isClientJobLibrary: boolean,
            ratecardSubscriptionFlag: boolean,
            termsOfAgreement: boolean,
          },
        },
      },
      errors: {
        __typename: string,
        message: string,
      }[],
    },
  },
  errors?: {
    message: string,
  }[],
};

const forgotPasswordQuery = `
  mutation forgotPasswordMutation($input : ForgotPasswordInput){
    forgotPasswordUser(forgotPasswordData:$input){
      ok
      errors{
        __typename
        ...on RequireFieldsError{
          message
        }
        ...on UserEmailMissingError{
          message
        }
        ...on UsernameDoesNotExistsError{
          message
        }
        ...on CannotSendEmailError {
          message
        }
      }
    }
  }
`;

type forgotPasswordQueryResponse = {
  data?: ?{
    forgotPasswordUser: ?{
      ok: boolean,
      errors: {
        __typename: string,
        message: string,
      }[],
    },
  },
  errors?: {
    message: string,
  }[],
};

class LoginForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showForgotPasswordForm: false,
      username: "",
      password: "",
      loginMessages: [],
      email: "",
      forgotPasswordMessages: [],
      loading: false,
      loginReqLimitReached: false,
    };
    window.localStorage.clear();
  }

  showForgotPasswordForm = () => {
    this.setState({ showForgotPasswordForm: true, loginReqLimitReached: false });
  };

  showLoginForm = () => {
    let loginMessages = this.state.loginMessages.slice();
    loginMessages.splice(0, 1);
    this.setState({
      showForgotPasswordForm: false,
      loginReqLimitReached: false,
      loginMessages,
    });
  };

  hideForgotPasswordForm = () => {
    let loginMessages = this.state.loginMessages.slice();
    loginMessages.splice(0, 1);
    this.setState({
      showForgotPasswordForm: false,
      loginReqLimitReached: false,
      loginMessages,
    });
  };

  handleSubmitForgotPassword = (e: Event) => {
    e.preventDefault();
    let messages = [];

    this.setState({ loading: true });
    this.props
      .fetchGraphQL(forgotPasswordQuery, {
        input: {
          username: this.state.username,
        },
      })
      .then((data: forgotPasswordQueryResponse) => {
        // Check for GraphQL level errors
        if (data.errors) {
          data.errors.forEach(({ message }) => {
            messages.push(
              new Message(
                "danger",
                "An unexpected error has occured resetting your email. Please contact support"
              )
            );
          });
        } else {
          if (
            data.data !== null &&
            data.data !== undefined &&
            data.data.forgotPasswordUser !== null &&
            data.data.forgotPasswordUser !== undefined
          ) {
            // Check mutation level errors
            if (data.data.forgotPasswordUser.errors) {
              data.data.forgotPasswordUser.errors.forEach((error) => {
                messages.push(new Message("important", error.message));
              });
            } else {
              // Handle success!
              const forgotPasswordUserData = data.data.forgotPasswordUser;
              if (forgotPasswordUserData.ok) {
                this.setState({
                  showForgotPasswordForm: true,
                  forgotPasswordMessages: [
                    new Message(
                      "success",
                      "please check the email registered with this account to continue."
                    ),
                  ],
                  loading: false,
                });
                return;
              } else {
                // Not ok forgot password
                if (forgotPasswordUserData.errors) {
                  forgotPasswordUserData.errors.forEach((error) => {
                    messages.push(new Message("danger", error.message));
                  });
                }
              }
              return;
            }
          }
        }
        this.setState({
          showForgotPasswordForm: true,
          forgotPasswordMessages: messages,
          loading: false,
        });
      })
      .catch((error: any) => {
        this.setState({
          showForgotPasswordForm: true,
          forgotPasswordMessages: [
            new Message(
              "danger",
              "An unexpected error has occured resetting your email. Please contact support"
            ),
          ],
          loading: false,
        });
      });
  };

  handleSubmitLogin = (e: Event) => {
    e.preventDefault();
    let messages = [];

    const { username, password } = this.state;
    if (username === "") {
      messages.push(new Message("warning", "Username is required"));
    }
    if (password === "") {
      messages.push(new Message("warning", "Password is required"));
    }

    if (username === "" || password === "") {
      this.setState({ loginMessages: messages });
      return; // Stop trying to login
    }

    let loginData: {
      username: string,
      password: string,
      forceV7?: boolean,
    } = {
      username,
      password,
    };

    // False if undefined or 0
    const useV7 =
      this.props.location.query["v7"] !== undefined
        ? this.props.location.query["v7"] !== 0
        : false;

    if (useV7) {
      loginData["forceV7"] = true;
    }

    this.setState({ loading: true });

    this.props
      .fetchGraphQL(loginQuery, {
        loginData,
      })
      .then((data: loginQueryResponse) => {
        // Check for GraphQL level errors
        if (data.errors) {
          data.errors.forEach(({ message }) => {
            messages.push(new Message("important", `An API error occured: "${message}"`));
          });
        } else {
          // Check for data
          if (data.data && data.data.loginUser) {
            // Check mutation level errors
            if (data.data.loginUser.errors) {
              data.data.loginUser.errors.forEach((error) => {
                messages.push(new Message("important", error.message));
              });
            } else {
              // Handle success!
              const loginUserData = data.data.loginUser;

              this.setState({ loginMessages: [], loading: false });
              const sessionInfo = new SessionInfo(loginUserData);
              this.props.updateLoginState(true, null, sessionInfo);

              const showEula =
                (loginUserData.user?.client?.legacyClient?.termsOfAgreement &&
                  loginUserData.user?.termsOfAgreement === false) ||
                false;
              if (showEula) {
                this.props.router.push({ pathname: "/eula" });
              } else {
                this.props.router.replace("/");
              }

              // NOTE: Initialize pendo as early as possible.
              if (window.pendo) {
                window.pendo.initialize({
                  visitor: {
                    id: loginUserData.user.userId,
                    email: loginUserData.user.email,
                    firstName: loginUserData.user.firstName,
                    lastName: loginUserData.user.lastName,
                    roles: loginUserData.user.roles,
                  },
                  account: {
                    id: loginUserData.user.client.id,
                    name: loginUserData.user.client.title,
                    isClientJobLibrary:
                      loginUserData.user.client.legacyClient.isClientJobLibrary,
                    perSearchPricing:
                      loginUserData.user.client.legacyClient.perSearchPricing,
                  },
                });
              }

              return;
            }
          }
        }
        this.setState({ loginMessages: messages, loading: false });
      })
      .catch((error: any) => {
        if (error.response && error.response.status === 401) {
          this.setState({
            loginMessages: [
              new Message("danger", "Wrong username or password, please try again."),
            ],
            loginReqLimitReached: false,
            loading: false,
          });
        } else if (error.response && error.response.status === 403) {
          this.setState({
            loginMessages: [
              new Message(
                "danger",
                "It looks like you're having trouble logging into Pay Intel. Please reset your password to access your account."
              ),
            ],
            loginReqLimitReached: true,
            loading: false,
          });
        } else {
          this.setState({
            loginMessages: [
              new Message(
                "danger",
                "There was a problem talking to our servers, please try again later."
              ),
            ],
            loginReqLimitReached: false,
            loading: false,
          });
        }
      });
  };

  render() {
    const {
      showForgotPasswordForm,
      username,
      password,
      loginMessages,
      forgotPasswordMessages,
      loading,
      loginReqLimitReached,
    } = this.state;

    return (
      <LoginPage>
        <div className="pt-ui box-container">
          {!showForgotPasswordForm && !loginReqLimitReached && (
            <form onSubmit={this.handleSubmitLogin}>
              <LoginBox className="box-login">
                <div className="box-header">
                  <div className="box-header-bg" />
                  <h1 data-testid="account-login-heading">Account Login</h1>
                </div>
                <Stack>
                  <TextInput
                    fill
                    id="usernameTextInput"
                    name="usernameInput"
                    type="text"
                    maxLength="64"
                    placeholder="Username"
                    disabled={loading}
                    value={username}
                    onChange={(e) => {
                      this.setState({ username: e.currentTarget.value });
                    }}
                  />
                  <TextInput
                    fill
                    id="passTextInput"
                    name="PasswordInput"
                    type="password"
                    placeholder="Password"
                    disabled={loading}
                    value={password}
                    onChange={(e) => {
                      this.setState({ password: e.currentTarget.value });
                    }}
                  />
                </Stack>
                {loginMessages.map((message: Message, i) => {
                  // Using message as key, which isn't great, but index isn't unique.
                  // To fix make change to type(Message) so it contains some unique thing like date
                  return (
                    <div
                      key={message.message}
                      className={classNames(
                        "alert alert-dismissable fade-in pt-user-alert",
                        {
                          "alert-danger": message.type !== "success",
                          "alert-success": message.type === "success",
                        }
                      )}
                    >
                      <span
                        className="close"
                        aria-label="close"
                        onClick={() => {
                          // Copy and remove given index
                          let loginMessages = this.state.loginMessages.slice();
                          loginMessages.splice(i, 1);
                          this.setState({
                            loginMessages,
                          });
                        }}
                      >
                        &times;
                      </span>
                      <strong>{message.messageLabel}</strong>
                      {message.message}
                    </div>
                  );
                })}
                <div className="form-buttons">
                  <Button type="submit" size="large" color="brand" disabled={loading}>
                    {loading ? (
                      <FontAwesomeIcon icon="spinner" spin />
                    ) : (
                      <span>Sign In</span>
                    )}
                  </Button>
                </div>

                <div className="box-footer">
                  <div>
                    <Link
                      as="a"
                      href="#forgot"
                      css={{
                        color: "$accent",
                      }}
                      onClick={this.showForgotPasswordForm}
                    >
                      Forgot your password?
                    </Link>
                  </div>
                </div>
              </LoginBox>
            </form>
          )}
          {showForgotPasswordForm && (
            <form onSubmit={this.handleSubmitForgotPassword}>
              <LoginBox className="box-login">
                <div className="box-header">
                  <div className="box-header-bg" />
                  <h1 data-testid="password-recovery-heading">Password Recovery</h1>
                </div>
                <TextInput
                  fill
                  id="forgot"
                  name="reset-username"
                  type="text"
                  maxLength="64"
                  placeholder="Enter Username"
                  disabled={loading}
                  value={username}
                  onChange={(e) => {
                    this.setState({ username: e.currentTarget.value });
                  }}
                />
                {forgotPasswordMessages.map((message, i) => {
                  return (
                    <div
                      key={i}
                      className={classNames(
                        "alert alert-dismissable fade-in pt-user-alert",
                        {
                          "alert-danger": message.type !== "success",
                          "alert-success": message.type === "success",
                        }
                      )}
                    >
                      <Link
                        as="a"
                        href="#close"
                        className="close"
                        aria-label="close"
                        onClick={() => {
                          let forgotPasswordMessages =
                            this.state.forgotPasswordMessages.slice();
                          forgotPasswordMessages.splice(i, 1);
                          this.setState({ forgotPasswordMessages });
                        }}
                      >
                        &times;
                      </Link>
                      <strong>
                        {message.messageLabel === "danger"
                          ? "Important"
                          : message.messageLabel}
                      </strong>{" "}
                      {message.message}
                    </div>
                  );
                })}
                <InlineElements className="form-buttons">
                  <Button
                    disabled={!username || loading}
                    size="large"
                    color="brand"
                    type="submit"
                    loading={loading}
                    text="Submit"
                  />
                  <Button
                    size="large"
                    type="button"
                    text="Cancel"
                    disabled={loading}
                    onClick={this.hideForgotPasswordForm}
                  />
                </InlineElements>
              </LoginBox>
            </form>
          )}

          {loginReqLimitReached && (
            <form onSubmit={this.handleSubmitLogin}>
              <LoginBox className="box-login">
                <div className="box-header">
                  <div className="box-header-bg" />
                  <h1>Account Login</h1>
                </div>
                <div className="text-center">
                  <h4>Having Trouble?</h4>
                </div>

                {loginMessages.map((message: Message, i) => {
                  return (
                    <div
                      key={message.message}
                      style={{
                        margin: "35px 0px 20px 0",
                        textAlign: "center",
                        fontSize: "0.85em",
                      }}
                    >
                      {message.message}
                    </div>
                  );
                })}
                <div className="form-buttons">
                  <Button
                    type="submit"
                    size="large"
                    color="brand"
                    onClick={this.showForgotPasswordForm}
                    style={{ fontSize: "0.95em" }}
                  >
                    Reset Password
                  </Button>
                </div>

                <div className="box-footer">
                  <div>
                    <Link
                      as="a"
                      href="#forgot"
                      css={{
                        color: "$accent",
                      }}
                      onClick={this.showLoginForm}
                    >
                      Back to login page
                    </Link>
                  </div>
                </div>
              </LoginBox>
            </form>
          )}
        </div>
      </LoginPage>
    );
  }
}

export const LoginPage = (props) => {
  const { children } = props;
  return (
    <div className="login-page">
      <Inline
        className="login-header"
        css={{
          ".login-page &.login-header": {
            justifyContent: "center",
            paddingBottom: 60,
            height: 180,
            backgroundColor: "$secondary",
          },
        }}
      >
        <a className="logo" href="https://ratepoint.pro-unlimited.com/login">
          <Logo />
        </a>
      </Inline>

      <Box
        className="login-body"
        css={{ ".login-page &.login-body": { borderBottomColor: "$brand" } }}
      >
        {children}
      </Box>

      <Box css={{ marginTop: "-50px" }}>
        <Footer />
      </Box>
    </div>
  );
};

export default LoginForm;
