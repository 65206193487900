import React from "react";
import { ImmutableSet } from "../../../types/immutable";
import { emptySet } from "../constants";

export type SelectMultipleRowsContextObject<T = number> = {
  selecting: boolean;
  selectedIds: ImmutableSet<T>;
  allIds: ImmutableSet<T>;
  onChangeSelected: (selectedIds: ImmutableSet<T>) => void;
  isAllSelected: boolean;
};

export const SelectMultipleRowsContext =
  React.createContext<SelectMultipleRowsContextObject>({
    selecting: false,
    selectedIds: emptySet,
    allIds: emptySet,
    onChangeSelected: (ids) => {},
    isAllSelected: false,
  });

export const useSelectMultipleRowsContext = () =>
  React.useContext(SelectMultipleRowsContext);
