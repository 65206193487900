import React, { useCallback } from "react";
import AsyncSelect from "react-select/lib/Async";

import { reactSelectTheme, reactSelectStyles } from "../../../../constants/reactSelect";
//@ts-expect-error
import { logGraphQLError, logAsyncOperationError } from "../../../../utils/logging";

import type {
  FetchGraphQLAPIFunc,
  FetchGraphQLAPIResponse,
} from "../../../../types/fetch";

export type ClientData = {
  clientId: number;
  clientName: string;
};

type BaseAsyncSelectProps = React.ComponentProps<typeof AsyncSelect<ClientData>>;
type AsyncSelectPropsWithout = Omit<BaseAsyncSelectProps, "loadOptions">;

type AllClientsResponse = {
  viewer: {
    allClients: {
      edges: {
        node: {
          clientId: number;
          clientName: string;
        };
      }[];
    };
  };
};

export type ClientSelectProps = AsyncSelectPropsWithout & {
  fetchGraphQL: FetchGraphQLAPIFunc;
  value: BaseAsyncSelectProps["value"];
  onChange: BaseAsyncSelectProps["onChange"];
};

function ClientSelect(props: ClientSelectProps) {
  const { fetchGraphQL, ...rest } = props;

  const getOptionValue = (option: ClientData) => String(option.clientId);
  const getOptionLabel = (option: ClientData) => option.clientName;
  const formatOptionLabel = (option: ClientData) =>
    `#${option.clientId} - ${option.clientName}`;

  const loadOptions = useCallback(
    async (value: string) => {
      try {
        const response: FetchGraphQLAPIResponse<AllClientsResponse> = await fetchGraphQL(
          `query fetchAllClients($searchTerm: String) {
          viewer {
            allClients(filters: {activeFlag: true, nameIContains: $searchTerm}) {
              edges {
                node {
                  clientId
                  clientName: name
                }
              }
            }
          }
        }`,
          { searchTerm: value }
        );
        if (logGraphQLError("fetchAllClients", response.data)) return [];

        return response.data.data.viewer.allClients.edges.map((edge) => edge.node);
      } catch (err: any) {
        logAsyncOperationError("fetchAllClients", err);
        throw err;
      }
    },
    [fetchGraphQL]
  );

  return (
    <AsyncSelect<ClientData>
      className={rest.className}
      inputId={rest.id}
      loadOptions={loadOptions}
      defaultOptions={true}
      cacheOptions
      noOptionsMessage={() => "No clients found for your input..."}
      value={rest.value}
      onChange={rest.onChange}
      styles={rest.styles ?? reactSelectStyles}
      theme={rest.theme ?? reactSelectTheme}
      placeholder={rest.placeholder ?? "Type to find your client..."}
      isLoading={rest.loading ?? false}
      isDisabled={rest.disabled ?? false}
      isMulti={rest.multi ?? false}
      isSearchable={rest.searchable ?? true}
      isClearable={rest.clearable ?? false}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      formatOptionLabel={formatOptionLabel}
    />
  );
}

ClientSelect.displayName = "ClientSelect";

export default ClientSelect;
