// @flow

import React, { Component } from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BatchSearch, { BATCH_SEARCH_JL_STATUS } from "../../../models/BatchSearch";
import { BatchSearchJLListStore } from "../../../stores/mobx/BatchSearchListStore";
import CheckBox from "../../shared/CheckBox";
import Tooltip from "../../../components/lib/Tooltip";
import {
  CardListItem,
  CardListItemLabel,
  CardListSubText,
  SubTextAccent,
} from "../../../components/lib/Card";
import LinkButton from "../../../components/lib/LinkButton";
import Button from "../../../components/lib/Button";
import { InlineElements } from "../../../components/lib/Inline";

type Props = {
  router: Object,
  batchSearch: BatchSearch,
  store: BatchSearchJLListStore,
  onClick: (SyntheticMouseEvent<HTMLElement>) => void,
};

class BatchSearchListItem extends Component<Props> {
  handleViewDetail: () => void;
  handleRerun: () => void;

  constructor(props: Props) {
    super(props);

    this.handleViewDetail = this.handleViewDetail.bind(this);
    this.handleRerun = this.handleRerun.bind(this);
  }

  handleViewDetail() {
    this.props.store.router.push(`/batch-searches/${this.props.batchSearch.id}`);
  }

  handleRerun(event: Event) {
    event.stopPropagation();
    const { store, batchSearch } = this.props;
    store.rerunBatchSearch(batchSearch.id);

    // this.context.router.push('/embed/batch-search/create');
  }

  render() {
    const props = this.props;

    const batchSearch: BatchSearch = props.batchSearch;

    const editing = batchSearch.viewState.editing;
    const selected = batchSearch.viewState.selected;
    const name = batchSearch.rateCardLabel;
    const createdDisplay = batchSearch.createdDisplay;
    const searches = batchSearch.searchesTotal;
    const status = batchSearch.status;
    const statusDisplay = batchSearch.statusDisplay;
    const id = batchSearch.id;

    let onItemClick = this.props.onClick || this.handleViewDetail;
    let editingSection = null;

    if (editing) {
      onItemClick = batchSearch.toggleSelected;
      editingSection = (
        <CheckBox
          type={props.store.allowMultipleItemSelection ? "checkbox" : "radio"}
          name="list-item-select"
          value={name}
          selected={selected}
          style={{ width: 34 }}
          onClick={onItemClick}
        />
      );
    }

    const statusSection = (
      <div style={{ width: 44 }}>
        <Tooltip
          id="status-tooltip"
          side="right"
          sideOffset={-15}
          content={statusDisplay}
        >
          <div>
            {status === BATCH_SEARCH_JL_STATUS.CREATED && (
              <FontAwesomeIcon icon="tasks" className="sc-status created" />
            )}
            {status === BATCH_SEARCH_JL_STATUS.QUEUED && (
              <FontAwesomeIcon icon="tasks" className="sc-status queued" />
            )}
            {status === BATCH_SEARCH_JL_STATUS.CREATING_RATE_CARD && (
              <FontAwesomeIcon icon="tasks" className="sc-status creating" />
            )}
            {status === BATCH_SEARCH_JL_STATUS.RUNNING && (
              <FontAwesomeIcon icon="play-circle" className="sc-status running" />
            )}
            {status === BATCH_SEARCH_JL_STATUS.FINISHED && (
              <FontAwesomeIcon icon="check" className="sc-status finished" />
            )}
            {status === BATCH_SEARCH_JL_STATUS.FINISHED_WITH_FAILURES && (
              <FontAwesomeIcon
                icon="exclamation-triangle"
                className="sc-status finished-errors"
              />
            )}
            {status === BATCH_SEARCH_JL_STATUS.FAILED && (
              <FontAwesomeIcon icon="exclamation-circle" className="sc-status failed" />
            )}
          </div>
        </Tooltip>
      </div>
    );

    return (
      <CardListItem
        selected={selected}
        onClick={onItemClick}
        css={{ display: "flex", flexWrap: "wrap" }}
        role="listitem"
      >
        <div className="ratecard-list-item-left ellipsis">
          {editingSection}
          {statusSection}
          <div className="ratecard-label-container ellipsis">
            <div className="ratecard-label ellipsis">
              <CardListItemLabel>{name}</CardListItemLabel>
            </div>
            <CardListSubText className="ratecard-meta ellipsis">
              Created on <SubTextAccent>{createdDisplay}</SubTextAccent>
            </CardListSubText>
          </div>
        </div>
        <div className="ratecard-list-item-right">
          <div className="ratecard-info text-center">
            <header data-testid="searches-header-jl">Searches</header>
            <div>{searches}</div>
          </div>

          {!editing && (
            <div className="rc-center">
              <InlineElements>
                <LinkButton to={`/batch-searches/${id}`}>Details</LinkButton>
                <Button icon="play-circle" onClick={this.handleRerun}>
                  Run Again
                </Button>
              </InlineElements>
            </div>
          )}
        </div>
      </CardListItem>
    );
  }
}

export default observer(BatchSearchListItem);
