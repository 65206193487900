import { useEffect } from "react";
import { assign, createMachine } from "xstate";
import { useMachine } from "@xstate/react";
import xstateLogger from "../utils/xstateLogger";

export type FilterMachineContextType = {
  filters: { [key: string]: any | undefined };
};

type AddFilterEventType = {
  type: "ADD_FILTER";
  field: string;
  value: string | string[] | number | number[] | boolean;
};

type RemoveFilterEventType = {
  type: "REMOVE_FILTER";
  field: string;
};

type RemoveAllFiltersEventType = {
  type: "REMOVE_ALL_FILTERS";
};

const rcListFilterMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QCUDCAZAlrALgMUwBscwAnAWQEMBjAC0wDswA6AVwYDMiTTIBiAIIARIQH08ASXQAVAKLIA2gAYAuolAAHAPaxMOTFobqQAD0QBWAIznmAFgBsADgDs5gDQgAnokeXm5pUClAE57Z1tbS3tggGYAXziPNCxcAmIyKjpGFi503ghBEXEpOUVVY21dfUNjMwRLS1tme3tbJXsAJncvREtHG3NzB2DnS3DI6PjEkGTsfG4MmnomZlyefmRZcgB5ADVZUQF0dGKZeQBlZTUkEEq9AyMbupjbGOZLLo9vBA7wu3auqFxlFggkkhg5mkeJlljkFvk+JsdvtTqUrhUdPcak9EC83h9ut8xs5mMFzDFLC8whEQVNwSl5nkYdlVvCNls9gdJGdFJZrppMdVHqA6rYOl9ejEbEohtFRsDJglpgwtBA4MZZql4cymBiqg9aogALT2CUIE3MIJW62BZxgmYQrVMpYs9hrMiQPVY4WmRAtezMDr2cydQmIDpKN7BDqhEZjGmK6aaxnQl0rd35L1Cw31F7+WKuM0dFyWpTORwdDrxiagpVAA */
  createMachine(
    {
      predictableActionArguments: true, // includes some action-specific fixes that's gonna be standard in XState v5, including "preserveActionOrder"
      tsTypes: {} as import("./rcListFilterMachine.typegen").Typegen0,
      schema: {
        context: {} as FilterMachineContextType,
        events: {} as
          | AddFilterEventType
          | RemoveFilterEventType
          | RemoveAllFiltersEventType,
      },
      context: {
        filters: {},
      },
      id: "RCListFilterMachine",
      initial: "unfiltered",
      states: {
        unfiltered: {
          on: {
            ADD_FILTER: {
              actions: "addFilter",
              target: "filtered",
            },
          },
        },
        filtered: {
          on: {
            ADD_FILTER: {
              actions: "addFilter",
              target: "filtered",
            },
            REMOVE_ALL_FILTERS: {
              actions: "removeAllFilters",
              target: "unfiltered",
            },
            REMOVE_FILTER: [
              {
                actions: "removeFilter",
                cond: "hasSingleFilter",
                target: "unfiltered",
              },
              {
                target: "filtered",
              },
            ],
          },
        },
      },
    },
    {
      actions: {
        addFilter: assign({
          filters: (ctx, event) => ({
            ...ctx.filters,
            [event.field]: event.value,
          }),
        }),
        removeAllFilters: assign({
          filters: (ctx, event) => ({}),
        }),
        removeFilter: assign({
          filters: (ctx, event) => {
            const { [event.field]: value, ...withoutFilter } = ctx.filters;
            return withoutFilter;
          },
        }),
      },
      guards: {
        hasSingleFilter: (ctx) => Object.keys(ctx.filters).length === 1,
      },
    }
  );

export function useRCListFilterMachine(machineName: string) {
  const [rcListFilterState, sendToRCListFilterMachine, rcListFilterService] =
    useMachine(rcListFilterMachine);

  // state machine logging
  useEffect(() => {
    // @ts-ignore
    const subscription = rcListFilterService.subscribe((state) =>
      // @ts-ignore
      xstateLogger(state, machineName)
    );
    return subscription.unsubscribe;
  }, [machineName, rcListFilterService]);

  return {
    rcListFilterState,
    sendToRCListFilterMachine,
    rcListFilterService,
  };
}

export default rcListFilterMachine;
