import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type * as Stitches from "@stitches/react";
import * as React from "react";
import type * as LinkTypes from "react-router";
import { Link } from "react-router";
import { config, styled } from "../../stitches.config";
import { buttonStyleConfig } from "./Button";
import Spinner from "./Spinner";

// @ts-ignore
const StyledLink = styled(Link, buttonStyleConfig, {
  compoundVariants: [
    {
      size: "ptNormal",
      variant: "filled",
      color: "primary",
      css: {
        padding: "5px calc($2_5 + 1px)",
      },
    },
    {
      size: "ptNormal",
      variant: "filled",
      color: "gray",
      css: {
        padding: "5px calc($2_5 + 1px)",
      },
    },
    {
      variant: "filled",
      color: "gray",
      css: {
        color: "$brandDark !important",
        backgroundColor: "white",
        borderColor: "$brand",
        borderWidth: 1,
        "&:hover": {
          borderWidth: 1,
          color: "$textLight !important",
          backgroundColor: "$brandDark",
          borderColor: "$brandDark",
        },
        "&:focus, &:active": {
          color: "$textLight !important",
          backgroundColor: "$brandDark",
          borderColor: "$brandDark",
          "$$pt-ringColor": "$colors$brandDark",
          "$$pt-ringOffsetColor": "$colors$brandLightest",
        },
      },
    },
    {
      variant: "filled",
      color: "primary",
      css: {
        color: "$brandDark !important",
        backgroundColor: "white",
        borderColor: "$brand",
        borderWidth: 1,
        "&:hover": {
          borderWidth: 1,
          color: "$textLight !important",
          backgroundColor: "$brandDark",
          borderColor: "$brandDark",
        },
        "&:focus, &:active": {
          color: "$textLight !important",
          backgroundColor: "$brandDark",
          borderColor: "$brandDark",
          "$$pt-ringColor": "$colors$brandDark",
          "$$pt-ringOffsetColor": "$colors$brandLightest",
        },
      },
    },
    {
      variant: "filled",
      color: "green",
      css: {
        color: "$textLight !important",
        backgroundColor: "$brand",
        borderColor: "$brand",
        "&:hover": {
          backgroundColor: "$brandDark",
          borderColor: "$brandDark",
        },
        "&:focus": {
          "$$pt-ringColor": "$colors$brandDark",
          "$$pt-ringOffsetColor": "$colors$brandLightest",
        },
      },
    },
    {
      variant: "filled",
      color: "brand",
      css: {
        color: "$textLight !important",
        backgroundColor: "$brand",
        borderColor: "$brand",
        "&:hover": {
          backgroundColor: "$brandDark",
          borderColor: "$brandDark",
        },
        "&:focus": {
          "$$pt-ringColor": "$colors$brandDark",
          "$$pt-ringOffsetColor": "$colors$brandLightest",
        },
      },
    },
    {
      variant: "filled",
      color: "yellow",
      css: {
        color: "$textLight !important",
        backgroundColor: "$accent",
        borderColor: "$accent",
        "&:hover": {
          backgroundColor: "$accentDark",
          borderColor: "$accentDark",
        },
        "&:focus": {
          "$$pt-ringColor": "$colors$accentDark",
          "$$pt-ringOffsetColor": "$colors$accentLightest",
        },
      },
    },
    {
      variant: "filled",
      color: "accent",
      css: {
        color: "$textLight !important",
        backgroundColor: "$accent",
        borderColor: "$accent",
        "&:hover": {
          backgroundColor: "$accentDark",
          borderColor: "$accentDark",
        },
        "&:focus": {
          "$$pt-ringColor": "$colors$accentDark",
          "$$pt-ringOffsetColor": "$colors$accentLightest",
        },
      },
    },
    {
      variant: "filled",
      color: "red",
      css: {
        color: "$textLight !important",
        backgroundColor: "$danger",
        borderColor: "$danger",
        "&:hover": {
          backgroundColor: "$dangerDark",
          borderColor: "$dangerDark",
        },
        "&:focus": {
          "$$pt-ringColor": "$colors$dangerDark",
          "$$pt-ringOffsetColor": "$colors$dangerLightest",
        },
      },
    },
    {
      variant: "filled",
      color: "danger",
      css: {
        color: "$textLight !important",
        backgroundColor: "$danger",
        borderColor: "$danger",
        "&:hover": {
          backgroundColor: "$dangerDark",
          borderColor: "$dangerDark",
        },
        "&:focus": {
          "$$pt-ringColor": "$colors$dangerDark",
          "$$pt-ringOffsetColor": "$colors$dangerLightest",
        },
      },
    },
    {
      variant: "filled",
      color: "blue",
      css: {
        color: "white",
        backgroundColor: "$ptBlue500",
        borderColor: "$ptBlue500",
        "&:hover": {
          backgroundColor: "$ptBlue400",
          borderColor: "$ptBlue400",
        },
        "&:focus": {
          "$$pt-ringColor": "$colors$ptBlue500",
          "$$pt-ringOffsetColor": "$colors$ptBlue50",
        },
      },
    },
    {
      variant: "outlined",
      color: "gray",
      css: {
        color: "$text !important",
        backgroundColor: "transparent",
        borderColor: "$primary",
        "&:hover": {
          color: "$textLight !important",
          backgroundColor: "$primaryDark",
          borderColor: "$primaryDark",
        },
        "&:focus": {
          color: "$textLight !important",
          backgroundColor: "$primaryDark",
          borderColor: "$primaryDark",
          "$$pt-ringColor": "$colors$primaryDark",
          "$$pt-ringOffsetColor": "$colors$primaryLightest",
        },
      },
    },
    {
      variant: "outlined",
      color: "primary",
      css: {
        color: "$text !important",
        backgroundColor: "transparent",
        borderColor: "$primary",
        "&:hover": {
          color: "$textLight !important",
          backgroundColor: "$primaryDark",
          borderColor: "$primaryDark",
        },
        "&:focus, &:active": {
          color: "$textLight !important",
          backgroundColor: "$primaryDark",
          borderColor: "$primaryDark",
          "$$pt-ringColor": "$colors$primaryDark",
          "$$pt-ringOffsetColor": "$colors$primaryLightest",
        },
      },
    },
    {
      variant: "outlined",
      color: "green",
      css: {
        color: "$brandDark !important",
        backgroundColor: "transparent",
        borderColor: "$brand",
        "&:hover": {
          color: "$textLight !important",
          backgroundColor: "$brandDark",
          borderColor: "$brandDark",
        },
        "&:focus, &:active": {
          color: "$textLight !important",
          backgroundColor: "$brandDark",
          borderColor: "$brandDark",
          "$$pt-ringColor": "$colors$brandDark",
          "$$pt-ringOffsetColor": "$colors$brandLightest",
        },
      },
    },
    {
      variant: "outlined",
      color: "brand",
      css: {
        color: "$brandDark !important",
        backgroundColor: "transparent",
        borderColor: "$brand",
        "&:hover": {
          color: "$textLight !important",
          backgroundColor: "$brandDark",
          borderColor: "$brandDark",
        },
        "&:focus, &:active": {
          color: "$textLight !important",
          backgroundColor: "$brandDark",
          borderColor: "$brandDark",
          "$$pt-ringColor": "$colors$brandDark",
          "$$pt-ringOffsetColor": "$colors$brandLightest",
        },
      },
    },
    {
      variant: "outlined",
      color: "yellow",
      css: {
        color: "$accentDark !important",
        backgroundColor: "transparent",
        borderColor: "$accent",
        "&:hover": {
          color: "$textLight !important",
          backgroundColor: "$accentDark",
          borderColor: "$accentDark",
        },
        "&:focus, &:active": {
          color: "$textLight !important",
          backgroundColor: "$accentDark",
          borderColor: "$accentDark",
          "$$pt-ringColor": "$colors$accentDark",
          "$$pt-ringOffsetColor": "$colors$accentLightest",
        },
      },
    },
    {
      variant: "outlined",
      color: "accent",
      css: {
        color: "$accentDark !important",
        backgroundColor: "transparent",
        borderColor: "$accent",
        "&:hover": {
          color: "$textLight !important",
          backgroundColor: "$accentDark",
          borderColor: "$accentDark",
        },
        "&:focus, &:active": {
          color: "$textLight !important",
          backgroundColor: "$accentDark",
          borderColor: "$accentDark",
          "$$pt-ringColor": "$colors$accentDark",
          "$$pt-ringOffsetColor": "$colors$accentLightest",
        },
      },
    },
    {
      variant: "outlined",
      color: "red",
      css: {
        color: "$dangerDark !important",
        backgroundColor: "transparent",
        borderColor: "$danger",
        "&:hover": {
          color: "$textLight !important",
          backgroundColor: "$dangerDark",
          borderColor: "$dangerDark",
        },
        "&:focus, &:active": {
          color: "$textLight !important",
          backgroundColor: "$dangerDark",
          borderColor: "$dangerDark",
          "$$pt-ringColor": "$colors$dangerDark",
          "$$pt-ringOffsetColor": "$colors$dangerLightest",
        },
      },
    },
    {
      variant: "outlined",
      color: "danger",
      css: {
        color: "$dangerDark !important",
        backgroundColor: "transparent",
        borderColor: "$danger",
        "&:hover": {
          color: "$textLight !important",
          backgroundColor: "$dangerDark",
          borderColor: "$dangerDark",
        },
        "&:focus, &:active": {
          color: "$textLight !important",
          backgroundColor: "$dangerDark",
          borderColor: "$dangerDark",
          "$$pt-ringColor": "$colors$dangerDark",
          "$$pt-ringOffsetColor": "$colors$dangerLightest",
        },
      },
    },
    {
      variant: "outlined",
      color: "blue",
      css: {
        color: "$ptBlue600 !important",
        backgroundColor: "transparent",
        borderColor: "$ptBlue600",
        "&:hover": {
          color: "$ptBlue900 !important",
          backgroundColor: "$ptBlue400",
          borderColor: "$ptBlue400",
        },
        "&:focus": {
          color: "$ptBlue900 !important",
          backgroundColor: "$ptBlue500",
          borderColor: "$ptBlue500",
          "$$pt-ringColor": "$colors$ptBlue500",
          "$$pt-ringOffsetColor": "$colors$ptBlue50",
        },
      },
    },
    {
      variant: "light",
      color: "gray",
      css: {
        color: "$ptBlack500 !important",
        backgroundColor: "$primaryLight",
        borderColor: "$primaryLight",
        "&:hover": {
          color: "$ptBlack700 !important",
          backgroundColor: "$ptGray600",
          borderColor: "$ptGray600",
        },
        "&:focus": {
          color: "$ptBlack700 !important",
          backgroundColor: "$ptGray600",
          borderColor: "$ptGray600",
          "$$pt-ringColor": "$colors$ptBlack400",
          "$$pt-ringOffsetColor": "$colors$ptGray100",
        },
      },
    },
    {
      variant: "light",
      color: "green",
      css: {
        color: "$ptGreen700 !important",
        backgroundColor: "$ptGreen200",
        borderColor: "$ptGreen200",
        "&:hover": {
          color: "$ptGreen900 !important",
          backgroundColor: "$ptGreen300",
          borderColor: "$ptGreen300",
        },
        "&:focus": {
          color: "$ptGreen900 !important",
          backgroundColor: "$ptGreen300",
          borderColor: "$ptGreen300",
          "$$pt-ringColor": "$colors$ptGreen500",
          "$$pt-ringOffsetColor": "$colors$ptGreen50",
        },
      },
    },
    {
      variant: "light",
      color: "yellow",
      css: {
        color: "$ptYellow800 !important",
        backgroundColor: "$ptYellow200",
        borderColor: "$ptYellow200",
        "&:hover": {
          color: "$ptYellow900 !important",
          backgroundColor: "$ptYellow300",
          borderColor: "$ptYellow300",
        },
        "&:focus": {
          color: "$ptYellow900 !important",
          backgroundColor: "$ptYellow300",
          borderColor: "$ptYellow300",
          "$$pt-ringColor": "$colors$ptYellow500",
          "$$pt-ringOffsetColor": "$colors$ptYellow50",
        },
      },
    },
    {
      variant: "light",
      color: "red",
      css: {
        color: "$ptRed500 !important",
        backgroundColor: "$ptRed100",
        borderColor: "$ptRed100",
        "&:hover": {
          color: "$ptRed700 !important",
          backgroundColor: "$ptRed200",
          borderColor: "$ptRed200",
        },
        "&:focus": {
          color: "$ptRed700 !important",
          backgroundColor: "$ptRed200",
          borderColor: "$ptRed200",
          "$$pt-ringColor": "$colors$ptRed500",
          "$$pt-ringOffsetColor": "$colors$ptRed50",
        },
      },
    },
    {
      variant: "light",
      color: "blue",
      css: {
        color: "$ptBlue700 !important",
        backgroundColor: "$ptBlue200",
        borderColor: "$ptBlue200",
        "&:hover": {
          color: "$ptBlue900 !important",
          backgroundColor: "$ptBlue300",
          borderColor: "$ptBlue300",
        },
        "&:focus": {
          color: "$ptBlue900 !important",
          backgroundColor: "$ptBlue300",
          borderColor: "$ptBlue300",
          "$$pt-ringColor": "$colors$ptBlue500",
          "$$pt-ringOffsetColor": "$colors$ptBlue50",
        },
      },
    },
    {
      variant: "text",
      color: "gray",
      css: {
        color: "$ptBlack500 !important",
        backgroundColor: "transparent",
        borderColor: "transparent",
        "&:hover": {
          color: "$ptBlack700 !important",
          backgroundColor: "transparent",
          borderColor: "$primaryLight",
        },
        "&:focus": {
          color: "$ptBlack700 !important",
          backgroundColor: "transparent",
          borderColor: "transparent",
          "$$pt-ringColor": "$colors$ptBlack400",
          "$$pt-ringOffsetColor": "$colors$ptGray100",
        },
      },
    },
    {
      variant: "text",
      color: "green",
      css: {
        color: "$ptGreen600 !important",
        backgroundColor: "transparent",
        borderColor: "transparent",
        "&:hover": {
          color: "$ptGreen800 !important",
          backgroundColor: "transparent",
          borderColor: "$ptGreen200",
        },
        "&:focus": {
          color: "$ptGreen800 !important",
          backgroundColor: "transparent",
          borderColor: "transparent",
          "$$pt-ringColor": "$colors$ptGreen500",
          "$$pt-ringOffsetColor": "$colors$ptGreen50",
        },
      },
    },
    {
      variant: "text",
      color: "yellow",
      css: {
        color: "$ptYellow600 !important",
        backgroundColor: "transparent",
        borderColor: "transparent",
        "&:hover": {
          color: "$ptYellow800 !important",
          backgroundColor: "transparent",
          borderColor: "$ptYellow200",
        },
        "&:focus": {
          color: "$ptYellow800 !important",
          backgroundColor: "transparent",
          borderColor: "transparent",
          "$$pt-ringColor": "$colors$ptYellow500",
          "$$pt-ringOffsetColor": "$colors$ptYellow50",
        },
      },
    },
    {
      variant: "text",
      color: "red",
      css: {
        color: "$ptRed500 !important",
        backgroundColor: "transparent",
        borderColor: "transparent",
        "&:hover": {
          color: "$ptRed700 !important",
          backgroundColor: "transparent",
          borderColor: "$ptRed100",
        },
        "&:focus": {
          color: "$ptRed700 !important",
          backgroundColor: "transparent",
          borderColor: "transparent",
          "$$pt-ringColor": "$colors$ptRed500",
          "$$pt-ringOffsetColor": "$colors$ptRed50",
        },
      },
    },
    {
      variant: "text",
      color: "blue",
      css: {
        color: "$ptBlue600 !important",
        backgroundColor: "transparent",
        borderColor: "transparent",
        "&:hover": {
          color: "$ptBlue800 !important",
          backgroundColor: "transparent",
          borderColor: "$ptBlue200",
        },
        "&:focus": {
          color: "$ptBlue800 !important",
          backgroundColor: "transparent",
          borderColor: "transparent",
          "$$pt-ringColor": "$colors$ptBlue500",
          "$$pt-ringOffsetColor": "$colors$ptBlue50",
        },
      },
    },
  ],
});

interface UtilLinkButtonProps {
  css?: Stitches.CSS<typeof config>;
  as?: React.ElementType;
  text?: string;
  loading?: boolean;
  loadingText?: string;
  icon?: IconProp;
  iconRight?: IconProp;
  target?: string;
}

type LinkButtonProps = React.PropsWithChildren<UtilLinkButtonProps> &
  Stitches.VariantProps<typeof StyledLink> &
  LinkTypes.LinkProps;

export default function LinkButton(props: LinkButtonProps) {
  const { text, icon, iconRight, loading, loadingText, size, children, ...rest } = props;

  return (
    <StyledLink size={size} {...rest} role="button">
      {loading && (
        <>
          <Spinner size={size} />
          {loadingText && <div>{loadingText}</div>}
        </>
      )}
      {!loading && (
        <>
          {icon && <FontAwesomeIcon icon={icon} />}
          <div>
            {text}
            {children}
          </div>
          {iconRight && <FontAwesomeIcon icon={iconRight} />}
        </>
      )}
    </StyledLink>
  );
}
