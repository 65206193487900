// @flow

import { observable } from "mobx";
import FilterObject, { DjangoFilterObject } from "../../models/Filter";
import Sort from "../../models/Sort";
import type { FilterColumn } from "../../models/Filter";
import type { FetchAPI, FetchGraphQL, FetchGraphQLVariables } from "../../App";

function isObject(obj) {
  // This works because, according to the MDN docs:
  // The Object constructor creates an object wrapper for the given value.
  // If the value is null or undefined, it will create and return an empty
  // object, otherwise, it will return an object of a type that corresponds
  // to the given value. If the value is an object already, it will return the value.
  return obj === Object(obj);
}

// Alternative to previous function
// function isObject(val) {
//     if (val === null) { return false;}
//     return ((typeof val === 'function') || (typeof val === 'object'));
// }

export const addIdToPayload = function (payload: Array<Object>): Array<Object> {
  let processed = observable.map({});
  payload.forEach((item, i) => {
    if (isObject(item)) {
      processed.set(String(i), {
        id: String(i),
        ...item,
      });
    } else {
      processed.set(String(i), {
        id: String(i),
        value: item,
      });
    }
  });

  return processed;
};

export const consolidateAppliedSorts = function (appliedSorts, args) {
  const sorts = Object.entries(appliedSorts);
  sorts.forEach(([column, sort]) => {
    args.push(sort.queryArg);
  });
};

export const consolidateAppliedFilters = function (
  appliedFilters: { [key: FilterColumn]: FilterObject },
  params: Array,
  args: Array,
  vars: Object
) {
  const filters = Object.entries(appliedFilters);
  filters.forEach(([column, filter]) => {
    params.push(filter.queryParam);
    args.push(filter.queryArg);
    Object.assign(vars, filter.queryVars);
  });
};

/*
 * returns object of type:
 * { 'ordering', ['-created', '-id'] }
 */
export const consolidateAppliedSortsForDjango = function (appliedSorts: {
  [key: FilterColumn]: Sort,
}) {
  const results = [];

  Object.values(appliedSorts).forEach((sort: Sort) => {
    if (sort && sort.queryArg) {
      results.push(sort.queryArg);
    }
  });

  return results.length ? { ordering: results } : {};
};

/**
 *  returns object of form {[lookup_key]: [value]}
 *  { 'rate_type': 'hourly',
 *    'pay_rate__gte': 50 }
 */
export const consolidateAppliedFiltersForDjango = function (appliedFilters: {
  [key: FilterColumn]: DjangoFilterObject,
}) {
  const result = {};

  Object.values(appliedFilters).forEach((filterObject: DjangoFilterObject) => {
    Object.keys(filterObject).forEach((lookupPath: string) => {
      const value: mixed = filterObject[lookupPath];

      if (lookupPath && value) {
        result[lookupPath] = value; // Example { 'rate_type__in': ['hourly', 'annual'] }
      }
    });
  });

  return result;
};

export function flattenDataProp(r: Object): ?Object {
  // for some reason after we added axios the response object had
  // the actual graphql data prop nested inside another data prop (response.data.data)
  // I think its because axios does something along the lines of:
  //  axiosResponse.data = actualResponse.json()
  // anyways we're just checking and flattening that
  if (!r) return null;
  if (r.data && r.data.data) r.data = r.data.data;
  if (!r.data && typeof r.json === "function") r.data = r.json();
  return r;
}

export const createTasteGraphQLWrapper = function (fetchAPI: FetchAPI): FetchGraphQL {
  // we're creating a wrapper function around fetchAPI that resembles the fetchGraphQL signature
  // to call the taste graphQL endpoint as if we were using the fetchGraphQL itself. This way
  // it'll be easier to migrate to the real fetchGraphQL later.
  // This is mainly used on JobLibrary stores.
  return function (
    query: string,
    variables: FetchGraphQLVariables,
    cancelToken?: Object | null = null
  ): Promise<any> {
    return fetchAPI("taste/", { query, variables }, cancelToken).then((r) => {
      return flattenDataProp(r);
    });
  };
};
