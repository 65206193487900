import React from "react";
import { List, Map } from "immutable";

import BaseStatusSpecificContractorsTable, {
  columnsWhiteList,
  baseColumnsSpecs,
} from "./BaseStatusSpecificContractorsTable";
import { CONTRACTOR_STATUSES_LABELS, CONTRACTOR_STATUSES_TYPE } from "../types";

import type { ContractorDataMap, TableColumnSpecsObject } from "../types";
import type { TableConfigOptionsList } from "../../../components/tables/types";

export const columnsSpecs: TableColumnSpecsObject<ContractorDataMap>[] = [
  {
    type: "column",
    uniqueKey: "status",
    title: "Status",
    getter: (row: ContractorDataMap) => row.get("status"),
    formatter: (value: CONTRACTOR_STATUSES_TYPE, row: ContractorDataMap) => (
      <strong>{CONTRACTOR_STATUSES_LABELS[value]}</strong>
    ),
    filterable: false,
    sortable: false,
  } as TableColumnSpecsObject,
]
  .concat(baseColumnsSpecs)
  .filter(({ uniqueKey }) => columnsWhiteList.includes(uniqueKey));

const NeedApprovalContractorsTable = (
  props: React.ComponentProps<typeof BaseStatusSpecificContractorsTable>
) => {
  return <BaseStatusSpecificContractorsTable {...props} />;
};
NeedApprovalContractorsTable.displayName = "NeedApprovalContractorsTable";
NeedApprovalContractorsTable.defaultProps = {
  ...BaseStatusSpecificContractorsTable.defaultProps,
  customConfigOptions: List(
    columnsSpecs.map(({ uniqueKey, title }) => Map({ uniqueKey, title }))
  ) as TableConfigOptionsList,
};
NeedApprovalContractorsTable.getTableId = (userId: number, programId: number) =>
  `user-${userId}-program-${programId}-need-approval-contractors-table`;

export default NeedApprovalContractorsTable;
