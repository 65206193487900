import PropTypes from "prop-types";

const conversionOptionsShape = {
  hoursInDay: PropTypes.number,
  hoursInWeek: PropTypes.number,
  hoursInMonth: PropTypes.number,
};

export const JSConversionOptions = PropTypes.shape(conversionOptionsShape);
export const CurrencySymbol = PropTypes.string;
