// @flow
import type { IndustryType } from "../../constants/ptStore";
import type { FetchGraphQL } from "../../App";

const allIndustries = [
  { id: 1, title: "All", faIcon: "th" },
  { id: 2, title: "Academia", faIcon: "graduation-cap" },
  { id: 3, title: "Aerospace and Defense", faIcon: "plane" },
  { id: 4, title: "Agricultural", faIcon: "leaf" },
  { id: 5, title: "Computers and Technology", faIcon: "desktop" },
  { id: 17, title: "Conglomerate", faIcon: "cubes" },
  { id: 6, title: "Consumer Goods", faIcon: "shopping-cart" },
  { id: 7, title: "Financial", faIcon: "dollar-sign" },
  { id: 8, title: "Government", faIcon: "university" },
  { id: 9, title: "Healthcare", faIcon: "stethoscope" },
  { id: 10, title: "Insurance", faIcon: "umbrella" },
  { id: 11, title: "Manufacturing", faIcon: "cogs" },
  { id: 12, title: "Oil and Gas", faIcon: "tint" },
  { id: 13, title: "Pharmaceuticals", faIcon: "flask" },
  { id: 14, title: "Power and Utilities", faIcon: "bolt" },
  { id: 16, title: "Professional Services / Consulting", faIcon: "briefcase" },
  { id: 15, title: "Telecommunications", faIcon: "signal" },
];

function normaliseString(str: string): string {
  if (!str) return str;
  return str
    .trim()
    .toLowerCase()
    .split(" ")
    .map((i) => i.trim())
    .filter((i) => i && i)
    .join("_");
}

export default class IndustriesStore {
  fetchGraphQL: FetchGraphQL;
  industriesById: { [key: number]: Object };
  industriesByTitle: { [key: string]: Object };

  constructor() {
    this.industriesById = {};
    this.industriesByTitle = {};

    allIndustries.forEach((industry) => {
      const { id, title } = industry;

      this.industriesById[id] = industry;
      this.industriesByTitle[normaliseString(title)] = industry;
    });
  }

  getIndustryById(id: number): IndustryType {
    const industry = this.industriesById[id];

    return industry ? { ...industry } : null;
  }

  getIndustryByTitle(title: string): IndustryType {
    const industry = this.industriesByTitle[normaliseString(title)];

    return industry ? { ...industry } : null;
  }

  getAllIndustries(): IndustryType[] {
    return allIndustries.map((i) => ({ ...i }));
  }

  filterIndustries(search: string): IndustryType[] {
    const results = [];

    allIndustries.forEach((item) => {
      if (item.title.toLowerCase().startsWith(search.toLowerCase())) {
        results.push({ ...item });
      }
    });

    return results;
  }
}
