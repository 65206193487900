import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/lib/Async";

import { reactSelectStyles, reactSelectTheme } from "../../constants/reactSelect";
import { logGraphQLError, logAsyncOperationError } from "../../utils/logging";

const RegionSelect = (props) => {
  const loadOptions = async (inputValue) => {
    try {
      const response = await props.fetchGraphQL(
        `query fetchAllRegions($searchParam: String!) {
          viewer {
            regions(search: $searchParam) {
              edges {
                node {
                  name
                  region_id: regionId
                  country {
                    location_id: apiLocationId
                    name
                  }
                }
              }
            }
          }
        }`,
        { searchParam: inputValue }
      );

      if (logGraphQLError("fetchAllRegions", response.data)) return [];

      const data = response.data.data.viewer.regions.edges || [];

      return data.map((item) => ({
        name: item.node.name,
        region_id: item.node.region_id,
        country_id: item.node.country.location_id,
        country_name: item.node.country.name,
      }));
    } catch (err) {
      logAsyncOperationError("fetchAllRegions", err);
      throw err;
    }
  };

  return (
    <AsyncSelect
      className={props.className}
      inputId={props.id}
      loadOptions={loadOptions}
      cacheOptions
      noOptionsMessage={(inputValue) => "No regions found for your input..."}
      value={props.value}
      onChange={props.onChange}
      styles={props.styles}
      theme={props.theme}
      placeholder={props.placeholder}
      isLoading={props.loading}
      isDisabled={props.disabled}
      isMulti={props.multi}
      isSearchable={props.searchable}
      isClearable={props.clearable}
      getOptionValue={(option) => option.region_id}
      formatOptionLabel={(option) => option.name}
    />
  );
};

RegionSelect.propTypes = {
  id: PropTypes.string,
  fetchGraphQL: PropTypes.func.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  multi: PropTypes.bool,
  searchable: PropTypes.bool,
  clearable: PropTypes.bool,
  styles: PropTypes.object,
  theme: PropTypes.func,
  className: PropTypes.string,
};
RegionSelect.defaultProps = {
  placeholder: "Type to find your region...",
  loading: false,
  disabled: false,
  multi: false,
  searchable: true,
  clearable: false,
  styles: reactSelectStyles,
  theme: reactSelectTheme,
};

export default RegionSelect;
