import React from "react";
import { useGlobalContext } from "../../../globalContext";
// @ts-ignore
import CreateNegotiationWorksheet from "../../negotiation_worksheets/CreateNegotiationWorksheet";

export function RsNegotiationWorksheet() {
  const { store, sessionInfo, location } = useGlobalContext();
  return (
    <CreateNegotiationWorksheet
      store={store}
      sessionInfo={sessionInfo}
      location={location}
    />
  );
}
