import React from "react";
import { List, Map } from "immutable";

import BaseStatusSpecificContractorsTable, {
  columnsWhiteList,
  baseColumnsSpecs,
} from "./BaseStatusSpecificContractorsTable";
import { emptyMap } from "../../../constants";
import Text from "../../../components/lib/Text";
import { CONTRACTOR_STATUSES_LABELS, CONTRACTOR_STATUSES_TYPE } from "../types";

import type { ContractorDataMap, TableColumnSpecsObject } from "../types";
import type { TableConfigOptionsList } from "../../../components/tables/types";

export const columnsSpecs: TableColumnSpecsObject<ContractorDataMap>[] = [
  {
    type: "column",
    uniqueKey: "status",
    title: "Status",
    getter: (row: ContractorDataMap) => row.get("status"),
    formatter: (value: CONTRACTOR_STATUSES_TYPE, row: ContractorDataMap) => {
      const errors = row.get("errors") || emptyMap;
      const warnings = row.get("warnings") || emptyMap;
      const totalProblems = errors.size + warnings.size;

      return (
        <Text as="strong" color="negative">
          {CONTRACTOR_STATUSES_LABELS[value]} ({totalProblems} problems)
        </Text>
      );
    },
    filterable: false,
    sortable: false,
  } as TableColumnSpecsObject,
]
  .concat(baseColumnsSpecs)
  .filter(({ uniqueKey }) => columnsWhiteList.includes(uniqueKey));

const FailedContractorsTable = (
  props: React.ComponentProps<typeof BaseStatusSpecificContractorsTable>
) => {
  return <BaseStatusSpecificContractorsTable {...props} />;
};

FailedContractorsTable.displayName = "FailedContractorsTable";
FailedContractorsTable.defaultProps = {
  ...BaseStatusSpecificContractorsTable.defaultProps,
  customConfigOptions: List(
    columnsSpecs.map(({ uniqueKey, title }) => Map({ uniqueKey, title }))
  ) as TableConfigOptionsList,
};
FailedContractorsTable.getTableId = (userId: number, programId: number) =>
  `user-${userId}-program-${programId}-failed-contractors-table`;

export default FailedContractorsTable;
