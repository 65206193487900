// @flow
import { action } from "mobx";
import FilterObject, { MarkUpRangeFilter, FilterWithSort } from "../Filter";
import type { FilterColumn } from "../Filter";
import Sort from "../Sort";
import SortState from "../SortState";

export default class MarkUpOnFilter extends MarkUpRangeFilter(FilterWithSort) {
  constructor(
    store: Object,
    column: FilterColumn,
    applyFilter: (FilterColumn, FilterObject) => void,
    applySort: (FilterColumn, Sort) => void,
    removeFilter: (FilterColumn) => void,
    removeSort: (FilterColumn) => void
  ) {
    super(store, column);

    this.allowMultipleItemSelection = false; // only one item selected at a time

    this.applyFilter = applyFilter;
    this.applySort = applySort;
    this.removeFilter = removeFilter;
    this.removeSort = removeSort;

    // Used for PCE, for PCE it is CREATED for rest it is CREATE_DATE
    this.sortState = new SortState("TOTAL_MARKUP_PERCENT");

    this.buildQueryFilter = action(this.buildQueryFilter.bind(this));
  }

  buildQueryFilter() {
    if (!this.fromMarkUpAmt && !this.toMarkUpAmt) {
      // no value selected, just return
      return null;
    }

    if (
      this.filter &&
      this.filter.queryVars.fromMarkUpAmt === this.fromMarkUpAmt &&
      this.filter.queryVars.toMarkUpAmt === this.toMarkUpAmt
    ) {
      return this.filter; // no change
    }

    let params = [];
    let args = [];
    let vars = {};

    if (this.fromMarkUpAmt) {
      params.push("$totalMarkupPctFrom: Float");
      args.push("totalMarkupPctFrom: $totalMarkupPctFrom");
      vars.totalMarkupPctFrom = this.fromMarkUpAmt;
    }

    if (this.toMarkUpAmt) {
      params.push("$totalMarkupPctTo: Float");
      args.push("totalMarkupPctTo: $totalMarkupPctTo");
      vars.totalMarkupPctTo = this.toMarkUpAmt;
    }

    return new FilterObject(params.join(", "), args.join(", "), vars);
  }
}
