import React, { useEffect, useRef, ComponentProps, memo } from "react";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import BellCurve from "highcharts/modules/histogram-bellcurve";
import debounce from "lodash/debounce";

import Box from "../lib/Box";

HighchartsMore(Highcharts);
BellCurve(Highcharts);

type BoxProps = Omit<ComponentProps<typeof Box>, "ref">;

export type HighchartProps = {
  options: Highcharts.Options;
  callback?: Highcharts.ChartCallbackFunction;
} & BoxProps;

Highcharts.setOptions({
  chart: {
    backgroundColor: "transparent",
  },
  lang: {
    decimalPoint: ".",
    thousandsSep: ",",
  },
  credits: { enabled: false },
});

export function Chart(props: HighchartProps) {
  const { options, callback, ...boxProps } = props;
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const renderChart = debounce(() => {
      if (containerRef.current) {
        while (containerRef.current.firstChild) {
          containerRef.current.removeChild(containerRef.current.firstChild);
        }
        Highcharts.chart(containerRef.current, options, callback);
      }
    }, 400);

    renderChart();
    window.addEventListener("resize", renderChart);
    return () => window.removeEventListener("resize", renderChart);
  }, [options, callback]);

  return <Box ref={containerRef} css={{ width: "$full" }} {...boxProps} />;
}

export default memo(Chart);
