import React, { useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";

import { useVal5KPublicContext } from "../../context/Val5KPublicContext";
import { useTableSchemaState } from "../../../../components/tables/Table";
import {
  renderTableColumnsSpecs,
  TableFilterableRestful,
} from "../../../private_index/components/ExtendedRestfulTables";
import { surveyListTableGlobalState } from "../../globalState";
import surveysListTableConfig from "./SurveysListTableConfig";

import type { TableFilterableRestfulProps } from "../../../../components/tables/TableFilterableRestful";
import type { DjangoPaginatedResponse } from "../../../../types/django";
import type { SurveyListTableDataStateObject } from "../../globalState";
import { ReviewDataMap } from "../../types";
import { getSurveyUrlPath } from "../../utils";

interface SurveysListTableProps extends Omit<TableFilterableRestfulProps, "schema"> {}

export const SurveysListTable = (props: SurveysListTableProps) => {
  const [schema] = useTableSchemaState(props.children);
  return <TableFilterableRestful schema={schema} {...props} />;
};

SurveysListTable.displayName = "SurveysListTable";

type SurveysListTableViewProps = {
  dataProvider: () => Promise<SurveyListTableDataStateObject>;
  filtersDataProvider: () => Promise<DjangoPaginatedResponse<any>>;
};

const SurveysListTableView = (props: SurveysListTableViewProps) => {
  const { dataProvider, filtersDataProvider } = props;

  const { router } = useVal5KPublicContext();

  // state

  const tableState = useRecoilValue<SurveyListTableDataStateObject>(
    surveyListTableGlobalState
  );

  //  handlers

  const handleRowClick = useCallback(
    async (reviewData: ReviewDataMap) => {
      const surveyPath = getSurveyUrlPath(reviewData);

      if (surveyPath) {
        router.push(surveyPath);
      }
    },
    [router]
  );

  // render

  const columns = useMemo(() => renderTableColumnsSpecs(surveysListTableConfig), []);

  return (
    <SurveysListTable
      multimode
      highlighted
      onRowClick={handleRowClick}
      dataProvider={dataProvider}
      filtersDataProvider={filtersDataProvider}
      {...tableState}
    >
      {columns}
    </SurveysListTable>
  );
};

SurveysListTableView.displayName = "SurveysListTableView";

export default SurveysListTableView;
