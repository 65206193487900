// @flow
import * as React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckBox from "../../shared/CheckBox";
import classNames from "classnames";
import numeral from "numeral";
import {
  CardListItem,
  CardListItemLabel,
  CardListSubText,
  SubTextAccent,
} from "../../../components/lib/Card";
import Box from "../../../components/lib/Box";
import TagItem from "../../ratecards/components/TagItem";
import Inline from "../../../components/lib/Inline";
import { CONTRACT, FTE } from "../../../constants/rateTypes";

/**
 * DraftSearchesListItem component for draft searches list item
 */

type Props = {
  store: object,
  rateCard: object,
  onClick: func,
};

const DraftSearchesListItem = observer(
  class extends React.Component<Props> {
    static contextTypes = {
      router: PropTypes.object,
    };
    /**
   * constructor
   * @param {props} props The first number.

   */
    constructor(props) {
      super(props);

      this.handleViewDetail = this.handleViewDetail.bind(this);
    }
    /**
     * handleViewDetail - redirect to detail page
     * @return {void}
     */
    handleViewDetail() {
      this.context.router.push({
        pathname: `/searches/${this.props.rateCard.searchId}`,
        query: this.context.router.query,
      });
    }
    /**
     * Render draft search
     * @return {XML}
     */
    render() {
      const props = this.props;

      const rateCard = props.rateCard;

      //const editing = rateCard.viewState.editing;
      const selected = rateCard.viewState.selected;
      const searchId = rateCard.searchId;
      const job = rateCard.job;
      const city = rateCard.city;
      const state = rateCard.state;
      const country = rateCard.country;
      const createdDisplay = rateCard.createdDate.format("MMMM D, YYYY");
      const rates = rateCard.rates;
      const rateType = rateCard.rateType;
      const showMarkupAndBillRate = rateType === 1;
      const symbol = rateCard.symbol;
      const marketRate = rateCard.marketRate;
      const tags = rateCard.tags;

      // Handle "missing rates" (typically during migration)
      const emptyType = [0, 0, 0, 0, 0];
      const payRateAvg = rates ? rates.payRate.avg : emptyType;
      const markupAvg = rates ? rates.markupPct.avg : emptyType;
      const billRateAvg = rates ? rates.billRate.avg : emptyType;
      const average = (arr) => arr.reduce((p, c) => p + c, 0) / arr.length;
      const payRateAvgTotal = average(payRateAvg);
      const markupAvgTotal = average(markupAvg);
      const billRateAvgTotal = average(billRateAvg);
      const payRateChanges = payRateAvgTotal - marketRate.payRate;
      const markupChanges = markupAvgTotal - marketRate.markupPct;
      const billRateChanges = billRateAvgTotal - marketRate.billRate;
      let startPayBrack,
        endPayBrack,
        startMarkBrack,
        endMarkBrack,
        startBillBrack,
        endBillBrack = null;
      if (Math.abs(payRateChanges.toFixed(2)) !== 0) {
        startPayBrack = "(";
        endPayBrack = ")";
      }
      if (Math.abs(markupChanges.toFixed(2)) !== 0) {
        startMarkBrack = "(";
        endMarkBrack = ")";
      }
      if (Math.abs(billRateChanges.toFixed(2)) !== 0) {
        startBillBrack = "(";
        endBillBrack = ")";
      }
      //const id = rateCard.searchId;

      let ownerName = "You";
      let onItemClick = this.props.onClick || this.handleViewDetail;
      let editingSection = null;
      var addPlusSign = "+";

      let tagSection;
      if (tags?.length > 0) {
        tagSection = tags.map((tag) => <TagItem key={tag.tagId} tag={tag} />);
      }

      onItemClick = rateCard.toggleSelected;
      editingSection = (
        <CheckBox
          type={props.store.allowMultipleItemSelection ? "checkbox" : "radio"}
          name="list-item-select"
          value={searchId}
          selected={selected}
          style={{ width: 34 }}
          onClick={onItemClick}
        />
      );

      return (
        <CardListItem
          selected={selected}
          onClick={onItemClick}
          css={{ display: "flex", flexWrap: "wrap" }}
        >
          <div className="ratecard-list-item-left ellipsis">
            {editingSection}
            <div className="ratecard-label-container ellipsis">
              <div className="ratecard-label ellipsis">
                <div className="ellipsis">
                  <CardListItemLabel>{job.jobLabel}</CardListItemLabel>
                  <CardListSubText>
                    <SubTextAccent>{job.jobTitle}</SubTextAccent> in{" "}
                    <SubTextAccent>
                      {[city, state, country].filter((l) => Boolean(l)).join(", ")}
                    </SubTextAccent>
                  </CardListSubText>
                  <CardListSubText>
                    Created by <SubTextAccent>{ownerName}</SubTextAccent> on{" "}
                    <SubTextAccent>{createdDisplay}</SubTextAccent>
                  </CardListSubText>

                  {/*<div className={classNames("tagbox", { "p-left-34": editing })}>{tagSection}</div>*/}
                </div>
              </div>
            </div>
          </div>
          <Inline css={{ gap: 0, display: "inline-flex" }}>
            {!rates && (
              <div className="list-rate">
                <div className="ratecard-info text-center">
                  <header>Status</header>
                  <div>Migrating</div>
                </div>
              </div>
            )}
            {rates && (
              <div className="list-rate">
                <div className="ratecard-info text-center">
                  <header>Pay Rate</header>
                  <div>{symbol + numeral(payRateAvgTotal).format("0,0.00")}</div>
                  {Math.abs(payRateChanges.toFixed(2)) !== 0 && (
                    <Box
                      as="span"
                      css={{
                        fontSize: "$xs",
                        lineHeight: "$xs",
                        "& svg": { fontSize: "10px", marginRight: "$1" },
                      }}
                    >
                      {startPayBrack}
                      <span
                        className={classNames(
                          { good: payRateChanges > 0 },
                          { bad: payRateChanges < 0 }
                        )}
                      >
                        <FontAwesomeIcon
                          icon="play"
                          rotation={
                            payRateChanges === 0 || payRateChanges === "0.00"
                              ? null
                              : payRateChanges > 0
                              ? 270
                              : 90
                          }
                          style={{ fontSize: "10px !important" }}
                        />
                        {payRateChanges > 0 ? addPlusSign : ""}
                        {payRateChanges < 0 ? "-" : ""}
                        {Math.abs(payRateChanges.toFixed(2))}
                      </span>
                      {endPayBrack}
                    </Box>
                  )}
                </div>
              </div>
            )}
            {rates && showMarkupAndBillRate && (
              <div className=" list-rate">
                <div className="ratecard-info text-center">
                  <header>Markup</header>
                  <div>{markupAvgTotal.toFixed(2) + " %"}</div>
                  {Math.abs(markupChanges.toFixed(2)) !== 0 && (
                    <Box
                      as="span"
                      css={{
                        fontSize: "$xs",
                        lineHeight: "$xs",
                        "& svg": { fontSize: "10px", marginRight: "$1" },
                      }}
                    >
                      {startMarkBrack}
                      <span
                        className={classNames(
                          { good: markupChanges > 0 },
                          { bad: markupChanges < 0 }
                        )}
                      >
                        <FontAwesomeIcon
                          icon="play"
                          rotation={
                            markupChanges === 0 || markupChanges === "0.00"
                              ? null
                              : markupChanges > 0
                              ? 270
                              : 90
                          }
                          style={{ fontSize: "10px !important" }}
                        />
                        {markupChanges > 0 ? addPlusSign : ""}
                        {markupChanges < 0 ? "-" : ""}
                        {Math.abs(markupChanges.toFixed(2))}
                      </span>
                      {endMarkBrack}
                    </Box>
                  )}
                </div>
              </div>
            )}
            {rates && showMarkupAndBillRate && (
              <div className=" list-rate">
                <div className="ratecard-info text-center">
                  <header>Bill Rate</header>
                  <div>{symbol + numeral(billRateAvgTotal).format("0,0.00")}</div>
                  {Math.abs(billRateChanges.toFixed(2)) !== 0 && (
                    <Box
                      as="span"
                      css={{
                        fontSize: "$xs",
                        lineHeight: "$xs",
                        "& svg": { fontSize: "10px", marginRight: "$1" },
                      }}
                    >
                      {startBillBrack}
                      <span
                        className={classNames(
                          { good: billRateChanges > 0 },
                          { bad: billRateChanges < 0 }
                        )}
                      >
                        <FontAwesomeIcon
                          icon="play"
                          rotation={
                            billRateChanges === 0 || billRateChanges === "0.00"
                              ? null
                              : billRateChanges > 0
                              ? 270
                              : 90
                          }
                          style={{ fontSize: "10px !important" }}
                        />
                        {billRateChanges > 0 ? addPlusSign : ""}
                        {billRateChanges < 0 ? "-" : ""}
                        {Math.abs(billRateChanges.toFixed(2))}
                      </span>
                      {endBillBrack}
                    </Box>
                  )}
                </div>
              </div>
            )}
            <div className="ratecard-info text-center">
              <header>Rate Type</header>
              <div>{rateType === CONTRACT.value ? CONTRACT.label : FTE.label}</div>
            </div>
          </Inline>
          <Box className="tagbox" css={{ paddingLeft: 34 }}>
            {tagSection}
          </Box>
        </CardListItem>
      );
    }
  }
);

export default DraftSearchesListItem;
