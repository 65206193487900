// @flow

import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  key?: string,
  text?: string,
};

class EmptyRow extends Component<Props> {
  props: Props;
  render() {
    return (
      <div key={this.props.key} className="cjl-flex-table row list-item empty">
        <div className="column">
          <span>
            <FontAwesomeIcon icon="info-circle" className="icon" />{" "}
            {this.props.text || "No items to show"}"
          </span>
        </div>
      </div>
    );
  }
}

export default EmptyRow;
