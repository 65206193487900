import React from "react";

import Button from "../../../components/lib/Button";
import Text from "../../../components/lib/Text";
import { CardListItem, CardListItemLabel } from "../../../components/lib/Card";

export const OverviewItem = ({ label, content }) => {
  return (
    <CardListItem>
      <CardListItemLabel>{label}</CardListItemLabel>
      <Text dangerouslySetInnerHTML={{ __html: content }} />
    </CardListItem>
  );
};

const Overview = (props) => {
  let { store } = props;
  let { isExpanded } = props;
  let buttonText = "";
  let buttonIcon;
  const canExpand = store.worksheetSchema.overviews.length > 0;

  buttonText = canExpand && (isExpanded ? "Collapse" : "Expand");
  buttonIcon = canExpand && (isExpanded ? "chevron-up" : "chevron-down");

  let collapseExpandButton = (
    <Button onClick={store.collapseExpand} color="brand" iconRight={buttonIcon}>
      {buttonText}
    </Button>
  );

  return (
    <div>
      <span className="icon border">
        {collapseExpandButton && (
          <div className="pt-card__heading__controls">{collapseExpandButton}</div>
        )}
        <h1 className="pt-card__heading__title">Overview</h1>
      </span>
      <div className="news-box">
        {isExpanded &&
          store.worksheetSchema.overviews.map((overview) => {
            return (
              <OverviewItem
                key={overview.id}
                label={overview.label}
                content={overview.content}
              />
            );
          })}
      </div>
    </div>
  );
};

export default Overview;
