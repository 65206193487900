import React, { useRef, useEffect, useState, useCallback } from "react";
import { Set } from "immutable";

import Box from "../../../components/lib/Box";
import Stack from "../../../components/lib/Stack";
import Alert from "../../../components/lib/Alert";
import { Column } from "../../../components/tables";

import { djangoPaginationKey, djangoPaginationSizeKey } from "../../../constants";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";

import { TickerContentLoader } from "../../../components/lib/TickerLoader";
import {
  Card,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
  CardBody,
} from "../../../components/lib/Card";
import { InstructionsCheckMark } from "../lib/CheckMark";
import { NavigationButton, IconButton } from "../../../components/lib/Button";

import NeedApprovalContractorsTable, {
  columnsSpecs,
} from "../components/NeedApprovalContractorsTable";
import AdminContractorEditor from "../components/AdminContractorEditor";
import {
  PARAMS_FOR_BULK_UPDATE,
  PARAMS_FOR_BULK_UPDATE_TYPE,
} from "../components/AdminContractorBulkEditor";
import RunningProcessingsAlert from "../components/RunningProcessingsAlert";

import { ContractorsTableViewProps } from "./FailedContractorsList";

import { useNeedApprovalContractorsTableGlobalState } from "../globalState";
import { useScrollTo } from "../hooks";
import { transformContractorsData } from "../dataConverters";
import { CONTRACTOR_STATUSES, CONTRACTOR_STATUSES_TYPE } from "../types";

import type {
  ContractorDataMap,
  ContractorsTableDataProvider,
  ContractorDataObject,
  ContractorsTableDataStateObject,
  ContractorsTableFiltersDataProvider,
} from "../types";
import type { CommonProgramChildPageProps } from "../ProgramDataProvider";
import type { RowEditorComponent } from "../../../components/tables/types";
import type { FetchAPIResponse } from "../../../types/fetch";
import type { DjangoPaginatedResponse } from "../../../types/django";
import type { ImmutableSet } from "../../../types/immutable";
import { ColumnElement } from "../../../components/tables/Schema";
import { renderTableColumnsSpecs } from "../components/ExtendedRestfulTables";
import { TableConfigChangesObject } from "../components/ContractorsTable";
import BulkUpdateContractorsButton from "../components/BulkUpdateContractorsButton";
import { BulkRerunContractorsButton } from "../components/BulkRerunContractorsButton";
import BulkDeleteContractorsButton from "../components/BulkDeleteContractorsButton";
import { rowIdGetter } from "../../../components/tables/utils";
import { useRefreshRequest } from "../../../utils/hooks";
import { usePLIContext } from "../context";

const allowedStatusesForBulkUpdate = Set([
  CONTRACTOR_STATUSES.NEED_APPROVAL,
]) as unknown as ImmutableSet<CONTRACTOR_STATUSES_TYPE>;

const allowedParametersForBulkUpdate = Set([
  PARAMS_FOR_BULK_UPDATE.INDUSTRY,
  PARAMS_FOR_BULK_UPDATE.GLOBAL_SUPPLIER_SEARCH,
  // below are all parameters available under specific data/filters conditions
  PARAMS_FOR_BULK_UPDATE.COLLECTION,
  PARAMS_FOR_BULK_UPDATE.DESCRIPTION,
  PARAMS_FOR_BULK_UPDATE.LOCATION,
  PARAMS_FOR_BULK_UPDATE.REGION,
  PARAMS_FOR_BULK_UPDATE.WORKER_TYPE,
]) as any as ImmutableSet<PARAMS_FOR_BULK_UPDATE_TYPE>;

const allowedStatusesForBulkRerun = allowedStatusesForBulkUpdate;

const allowedStatusesForBulkDelete = allowedStatusesForBulkUpdate;

const instructionsBlock = (
  <Box>
    <h3>Here you can:</h3>
    <h3>
      <InstructionsCheckMark /> Browse all pending approval contractors data.
    </h3>
    <h3>
      <InstructionsCheckMark /> Enter editor (wrench button) and approve contractor data.
    </h3>
    <h3>
      <InstructionsCheckMark /> Approve a set of rows in one go by using Bulk Update form.
    </h3>
  </Box>
);

type NavigationButtonProps = {
  onGoBackToCreateIndex: () => void;
};

const PageHeadBlock = (props: NavigationButtonProps) => {
  const { onGoBackToCreateIndex } = props;

  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft>
          <h2>Pending Approval Contractors List</h2>
        </CardActionsLeft>
        <CardActionsRight>
          <NavigationButton icon="arrow-left" onClick={onGoBackToCreateIndex}>
            Back To Create Index
          </NavigationButton>
        </CardActionsRight>
      </CardActions>
      <CardBody>{instructionsBlock}</CardBody>
    </Card>
  );
};
PageHeadBlock.displayName = "PageHeadBlock";

type NoPermissionsBlockProps = {
  onGoBackToCreateIndex: () => void;
};

const NoPermissionsBlock = (props: NoPermissionsBlockProps) => {
  const { onGoBackToCreateIndex } = props;

  return (
    <Alert color="warning">
      <Stack fill css={{ alignItems: "start" }}>
        <h4>You have no permissions to access this page </h4>
        <NavigationButton
          icon="arrow-left"
          color="warning"
          onClick={onGoBackToCreateIndex}
        >
          Back To Create Index
        </NavigationButton>
      </Stack>
    </Alert>
  );
};
NoPermissionsBlock.displayName = "NoPermissionsBlock";

const ContractorsTableView = (props: ContractorsTableViewProps) => {
  const {
    editingRowId,
    contractorsData,
    contractorsDataProvider,
    contractorsFiltersDataProvider,
    //
    onStartRowEditing,
    onApplyRowUpdate,
    onCancelRowEditing,
    onDeleteRow,
    onChangeTableConfig,
  } = props;
  const { isPTAdmin } = usePLIContext();

  // scroll to table top

  const scrollToRef = useScrollTo([contractorsData.activePage]);

  const renderRowActions = (rowData: ContractorDataMap) => {
    return (
      <span>
        {isPTAdmin && (
          <IconButton
            icon="wrench"
            color="brand"
            variant="outlined"
            title="Fix Contractor Data"
            onClick={() => onStartRowEditing(rowData)}
          />
        )}
      </span>
    );
  };

  const tableColumns: ColumnElement<ContractorDataMap>[] = [
    <Column key="__fix" uniqueKey="__fix" title="Fix" getter={renderRowActions} fixed />,
  ].concat(renderTableColumnsSpecs(columnsSpecs));

  return (
    <Box ref={scrollToRef}>
      <h5>
        <small>* Table contains values in original currency</small>
      </h5>
      <NeedApprovalContractorsTable
        isPTAdmin={isPTAdmin}
        rowIdGetter={rowIdGetter}
        editable={isPTAdmin}
        editorImpl={AdminContractorEditor as RowEditorComponent<ContractorDataMap>}
        selectedRowId={editingRowId}
        onEditApply={editingRowId != null ? onApplyRowUpdate : undefined}
        onEditCancel={editingRowId != null ? onCancelRowEditing : undefined}
        onDeleteRow={editingRowId != null ? onDeleteRow : undefined}
        multimode
        dataProvider={contractorsDataProvider}
        filtersDataProvider={contractorsFiltersDataProvider}
        onChangeTableConfig={onChangeTableConfig}
        {...contractorsData}
      >
        {tableColumns}
      </NeedApprovalContractorsTable>
    </Box>
  );
};
ContractorsTableView.displayName = "ContractorsTableView";

const NeedApprovalContractorsList = (props: CommonProgramChildPageProps) => {
  const { router, fetchM8FilteringAPI, showModalError, userId, isPTAdmin, programId } =
    props;

  // state

  const tableId = NeedApprovalContractorsTable.getTableId(userId, programId);
  const [contractorsData, setContractorsDataState, resetContractorsDataState] =
    useNeedApprovalContractorsTableGlobalState(tableId);
  const isContractorsDataLoaded = contractorsData.loaded;

  const [editingRowId, setEditingRowIdState] = useState<number | null>(null);

  // data fetch functions

  const fetchContractorsData: ContractorsTableDataProvider = useCallback(
    async (urlQuery = {}, filtersQuery = {}, nextStateUpdates = {}) => {
      try {
        const response: FetchAPIResponse<DjangoPaginatedResponse<ContractorDataObject>> =
          await fetchM8FilteringAPI(`programs/${programId}/contractors/filtered/`, {
            params: urlQuery,
            data: {
              ...filtersQuery,
              status__in: [CONTRACTOR_STATUSES.NEED_APPROVAL],
            },
          });
        const nextDataState: Partial<ContractorsTableDataStateObject> =
          transformContractorsData(response.data, nextStateUpdates);

        setContractorsDataState((prevState: ContractorsTableDataStateObject) => ({
          ...prevState,
          ...nextDataState,
          loaded: true,
        }));

        return nextDataState;
      } catch (err: any) {
        logAsyncOperationError("fetchContractorsList", err);
        showModalError(
          "Error occurred while loading contractors list. Please, try again later."
        );
        throw err;
      }
    },
    [setContractorsDataState, programId, fetchM8FilteringAPI, showModalError]
  );

  const fetchContractorsFiltersData: ContractorsTableFiltersDataProvider = useCallback(
    async (urlQuery = {}, filtersQuery = {}) => {
      try {
        const response: FetchAPIResponse<DjangoPaginatedResponse<any>> =
          await fetchM8FilteringAPI(
            `programs/${programId}/contractors/values/filtered/`,
            {
              params: urlQuery,
              data: {
                ...filtersQuery,
                status__in: [CONTRACTOR_STATUSES.NEED_APPROVAL],
              },
            }
          );

        return response.data;
      } catch (err: any) {
        logAsyncOperationError("fetchContractorsListFilterValues", err);
        showModalError(
          "Error occurred while loading filter values. Please, try again later."
        );
        throw err;
      }
    },
    [programId, fetchM8FilteringAPI, showModalError]
  );

  // utility functions

  const [processingsRefreshRequestId, refreshProcessingsData] = useRefreshRequest();

  const refreshContractorsData = useCallback(async () => {
    return fetchContractorsData(
      {
        [djangoPaginationKey]: contractorsData.activePage,
        [djangoPaginationSizeKey]: contractorsData.itemsPerPage,
      },
      contractorsData.filtersQuery.toJS()
    );
  }, [
    contractorsData.activePage,
    contractorsData.itemsPerPage,
    contractorsData.filtersQuery,
    fetchContractorsData,
  ]);

  const refreshPageData = useCallback(
    async (withProcessing: boolean = true) => {
      if (isPTAdmin && withProcessing) {
        refreshProcessingsData();
      }
      return await refreshContractorsData();
    },
    [refreshContractorsData, refreshProcessingsData, isPTAdmin]
  );

  // effects

  const contractorsDataLoadedRef = useRef<boolean>(false);

  useEffect(() => {
    if (!contractorsDataLoadedRef.current) {
      refreshPageData(false);
      contractorsDataLoadedRef.current = true;
    }
  }, [refreshPageData, contractorsDataLoadedRef]);

  useEffect(() => resetContractorsDataState, [resetContractorsDataState]);

  // handlers

  const handleGoBackToCreateIndex = useCallback(() => {
    router.push(`/private-index/programs/${programId}/indexes/create`);
  }, [router, programId]);

  const handleStartRowEditing = useCallback(
    (rowData: ContractorDataMap) => {
      const rowId = rowData.get("id");
      let nextEditingRowId: number | null = rowId;

      if (rowId != null && isPTAdmin) {
        if (rowId === editingRowId) {
          nextEditingRowId = null;
        }

        if (nextEditingRowId !== editingRowId) {
          setEditingRowIdState(nextEditingRowId);
        }
      }
    },
    [editingRowId, isPTAdmin, setEditingRowIdState]
  );

  const handleApplyRowUpdate = useCallback(
    async () => refreshPageData(),
    [refreshPageData]
  );

  const handleCancelRowEditing = useCallback(
    async (refresh: boolean = false) => {
      setEditingRowIdState(null);
      return refresh ? refreshPageData(false) : Promise.resolve();
    },
    [refreshPageData, setEditingRowIdState]
  );

  const handleDeleteRow = useCallback(
    () => handleCancelRowEditing(true),
    [handleCancelRowEditing]
  );

  const handleChangeTableConfig = React.useCallback(
    async (changes: TableConfigChangesObject) => {
      setContractorsDataState((prevState) => ({
        ...prevState,
        ...changes,
      }));
    },
    [setContractorsDataState]
  );

  if (!isPTAdmin) {
    return <NoPermissionsBlock onGoBackToCreateIndex={handleGoBackToCreateIndex} />;
  }

  const contractorsTableBlock = (
    <Card css={{ width: "100%" }}>
      <CardActions>
        <CardActionsLeft />
        <CardActionsRight>
          {isPTAdmin && (
            <BulkRerunContractorsButton
              icon="sync"
              size="small"
              loadingText="Rerun Index"
              disabled={
                !isContractorsDataLoaded ||
                !contractorsData.itemsCount ||
                editingRowId != null
              }
              allowedStatusesForRerun={allowedStatusesForBulkRerun}
              contractorsFiltersQuery={contractorsData.filtersQuery}
              currentPage={contractorsData.activePage}
              itemsTotal={contractorsData.itemsCount}
              itemsPerPage={contractorsData.itemsPerPage}
              itemsOnCurrentPage={contractorsData.data.size}
              onRefreshIsDone={refreshProcessingsData}
            >
              Rerun Index
            </BulkRerunContractorsButton>
          )}
          {isPTAdmin && (
            <BulkUpdateContractorsButton
              icon="wrench"
              size="small"
              loadingText="Bulk Update"
              disabled={
                !isContractorsDataLoaded ||
                !contractorsData.itemsCount ||
                editingRowId != null
              }
              allowedStatusesForUpdate={allowedStatusesForBulkUpdate}
              allowedParametersForUpdate={allowedParametersForBulkUpdate}
              contractorsFiltersQuery={contractorsData.filtersQuery}
              onUpdateIsDone={refreshPageData}
            >
              Bulk Update
            </BulkUpdateContractorsButton>
          )}
          {isPTAdmin && (
            <BulkDeleteContractorsButton
              icon={["far", "trash-alt"]}
              color="danger"
              size="small"
              loadingText="Bulk Delete"
              disabled={
                !isContractorsDataLoaded ||
                !contractorsData.itemsCount ||
                editingRowId != null
              }
              allowedStatusesForDelete={allowedStatusesForBulkDelete}
              contractorsFiltersQuery={contractorsData.filtersQuery}
              onDeleteIsDone={refreshPageData}
            >
              Bulk Delete
            </BulkDeleteContractorsButton>
          )}
        </CardActionsRight>
      </CardActions>

      <CardBody>
        <Stack fill css={{ alignItems: "stretch" }}>
          {isPTAdmin && (
            <RunningProcessingsAlert
              programId={programId}
              refreshRequestId={processingsRefreshRequestId}
              onChangeProcessingsNumber={() => refreshPageData(false)}
            />
          )}
          {!isContractorsDataLoaded ? (
            <Box css={{ minHeight: "200px" }}>
              <TickerContentLoader />
            </Box>
          ) : (
            <ContractorsTableView
              contractorsData={contractorsData}
              editingRowId={editingRowId}
              onStartRowEditing={handleStartRowEditing}
              onApplyRowUpdate={handleApplyRowUpdate}
              onCancelRowEditing={handleCancelRowEditing}
              onDeleteRow={handleDeleteRow}
              onChangeTableConfig={handleChangeTableConfig}
              contractorsDataProvider={fetchContractorsData}
              contractorsFiltersDataProvider={fetchContractorsFiltersData}
            />
          )}
        </Stack>
      </CardBody>
    </Card>
  );

  return (
    <Stack>
      <PageHeadBlock onGoBackToCreateIndex={handleGoBackToCreateIndex} />
      {contractorsTableBlock}
    </Stack>
  );
};
NeedApprovalContractorsList.displayName = "NeedApprovalContractorsList";

export default NeedApprovalContractorsList;
