// @flow

import { extendObservable, action } from "mobx";
import FilterObject, {
  FilterWithSort,
  ValueSetFilter,
  FilterCriteriaLoader,
  IViewItem,
} from "../Filter";
import type FilterColumn from "../Filter";
import { GraphQLQuery } from "../GraphQL";
import Sort from "../Sort";
import SortState from "../SortState";

export class Country implements IViewItem {
  country: string;
  selected: string;

  constructor(object: Object) {
    this.country = object.country;
    this.id = object.id;

    extendObservable(this, {
      selected: object.selected,
    });
  }
}

export default class CountryFilter extends FilterCriteriaLoader(
  ValueSetFilter(FilterWithSort)
) {
  constructor(
    store: Object,
    column: FilterColumn,
    filterCriteriaQuery: (FilterColumn) => GraphQLQuery,
    processPayload: (FilterColumn, Object) => Array<Object>,
    applyFilter: (FilterColumn, FilterObject) => void,
    applySort: (FilterColumn, Sort) => void,
    removeFilter: (FilterColumn) => void,
    removeSort: (FilterColumn) => void,
    reloadCriteria: boolean = true
  ) {
    super(store, column);

    // extendObservable(this, {
    //   strictCountries: true,
    // });

    this.filterCriteriaQuery = filterCriteriaQuery;
    this.processPayload = processPayload;
    this.applyFilter = applyFilter;
    this.applySort = applySort;
    this.removeFilter = removeFilter;
    this.removeSort = removeSort;

    if (this.column === "worksheetCountry") this.sortState = new SortState("COUNTRY");
    else this.sortState = new SortState("COUNTRY_NAME");

    this.reloadCriteria = reloadCriteria;

    this.viewItemClass = Country;

    this.onInstantSearch = action(this.onInstantSearch.bind(this));
    this.buildQueryFilter = action(this.buildQueryFilter.bind(this));
  }

  onInstantSearch(value: string) {
    if (super.onInstantSearch) super.onInstantSearch(value);

    if (!this.instantSearchValue) {
      this.viewItems = this.unfilteredViewItems;
      return;
    }

    this.viewItems = this.unfilteredViewItems.filter((item) => {
      const location = item.country.toLowerCase();
      const query = this.instantSearchValue.toLowerCase();

      // this works because ~ is the binary inverse of a number and ~ -1 = 0
      return ~location.indexOf(query);
    });
  }

  buildQueryFilter() {
    const owners = this.selectedValues
      .entries()
      .filter((entry) => entry[1] === true)
      .map((entry) => this.criteria.get(entry[0]).country);

    if (!owners.length) {
      return null;
    }

    let params = [];
    let args = [];
    let vars = {};

    if (owners.length) {
      params.push("$countries: [String]");
      args.push("countries: $countries");
      vars.countries = owners.map((userId) => {
        return userId;
      });
    }

    return new FilterObject(params.join(", "), args.join(", "), vars);
  }
}
