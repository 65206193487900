// @flow

import React, { Component } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingIndicator from "../../ratecards/components/LoadingIndicator";
import JobsByTagChart from "./JobsByTagChart";
import { OverlayTrigger, Popover } from "react-bootstrap";
import RateCardDetailStore from "../../../stores/mobx/RateCardDetailStore";

type Props = {
  store: RateCardDetailStore,
};

class JobsByTagPanel extends Component<Props> {
  _renderHintPopover = () => {
    return (
      <Popover
        className="pt-ui-popover"
        id="popover-positioned-top"
        title="About This Chart"
      >
        {/*<p className="text-xx-small">*/}
        {/*<strong>Purpose</strong>*/}
        {/*</p>*/}
        <p className="text-xx-small">
          Shows how many jobs there are for each Tag on the Rate Card's searches.
        </p>
      </Popover>
    );
  };

  render() {
    const props = this.props;
    const store = props.store;
    const state = store.jobsByTagPieState;
    const loading = store.network.loading || state.network.loading;

    const pieChartData = state.chartData ? state.chartData.data : [];

    return (
      <div className="panel panel-default no-margin">
        <div className="panel-heading">
          <h3 className="panel-title">
            Jobs by Tag
            <OverlayTrigger placement="top" overlay={this._renderHintPopover()}>
              <FontAwesomeIcon
                icon="question-circle"
                className="icon"
                style={{ marginLeft: ".25em" }}
              />
            </OverlayTrigger>
          </h3>
        </div>
        <div className="panel-body">
          <div style={{ position: "relative", height: 400, overflow: "hidden" }}>
            {!loading && <JobsByTagChart data={pieChartData} />}
            <div
              className={classNames({
                "loading-indicator-container": true,
                show: loading,
              })}
            >
              <LoadingIndicator
                style={{
                  height: 400,
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(JobsByTagPanel);
