// @flow
import React, { Component } from "react";
import type { Node, ChildrenArray } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { extendObservable, action, computed } from "mobx";
import { observer } from "mobx-react";
import { ModalButtons } from "../../views/ratecards/components/FilterModal";
import Spacer from "../lib/Spacer";
import Button from "../lib/Button";
import Box from "../lib/Box";
import { styled } from "../../stitches.config";

const StepHeader = styled("div", {
  padding: "20px 40px",
  margin: "-20px",
  display: "block",
  lineHeight: "12px",
  zIndex: "0",
  backgroundColor: "$primaryLighter",
  color: "$primaryDark",

  "& > label": {
    fontSize: "12px",
    marginBottom: "0",
  },

  "& > header": {
    fontSize: "21px",
  },
  "& .fa": {
    fontSize: "10px",
  },
  variants: {
    active: {
      true: {
        backgroundColor: "$brand",
        color: "$brandLightest",
      },
    },
    completed: {
      true: {
        backgroundColor: "$brandDark",
        color: "$brandLighter",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "$brand",
        },
      },
    },
  },
});

class WizardState {
  currentStep: number;
  previousStep: number;
  steps: number;
  canGoForward: boolean;
  canGoBackward: boolean;
  disableNavigation: boolean;

  next: () => void;
  previous: () => void;
  goTo: (number) => void;
  first: () => void;
  last: () => void;
  setSteps: (number) => void;
  reset: () => void;

  constructor() {
    extendObservable(this, {
      steps: 0,
      currentStep: 1,
      previousStep: 0,
      disableNavigation: false,
      canGoForward: computed(() => this.currentStep < this.steps),
      canGoBackward: computed(() => this.currentStep > 1),
    });

    this.next = action(this.next.bind(this));
    this.previous = action(this.previous.bind(this));
    this.first = action(this.first.bind(this));
    this.last = action(this.last.bind(this));
    this.goTo = action(this.goTo.bind(this));
    this.setSteps = action(this.setSteps.bind(this));
    this.reset = action(this.reset.bind(this));
  }

  setSteps(steps: number) {
    this.steps = steps;
  }

  next(): void {
    if (!this.canGoForward) return;

    this.previousStep = this.currentStep;
    this.currentStep = this.currentStep + 1;
  }

  previous(): void {
    if (!this.canGoBackward) return;

    this.previousStep = this.currentStep;
    this.currentStep = this.currentStep - 1;
  }

  first(): void {
    this.previousStep = this.currentStep;
    this.currentStep = 1;
  }

  last(): void {
    this.previousStep = this.currentStep;
    this.currentStep = this.steps;
  }

  goTo(step: number): void {
    if (step === this.currentStep) return;
    if (step < 1 || step > this.steps) return;

    this.previousStep = this.currentStep;
    this.currentStep = step;
  }

  reset(): void {
    this.previousStep = 0;
    this.currentStep = 1;
    window.scrollTo(0, 0);
  }
}

type WizardStepProps = {
  children: Node | ChildrenArray<any>,
  title: string,
  forward?: boolean,
  show?: boolean,
  hide?: boolean,
};

const WizardStep = observer(
  class extends Component<WizardStepProps> {
    static defaultProps = {
      forward: true,
      show: false,
      hide: false,
    };

    render() {
      const { show, hide, children, forward } = this.props;

      if (!show && !hide) return null;

      const classes = classNames("rc-wizard-step", {
        "animate-show-forward": show && forward,
        "animate-hide-forward": hide && forward,
        "animate-show-backward": show && !forward,
        "animate-hide-backward": hide && !forward,
      });

      return <div className={classes}> {children} </div>;
    }
  }
);

type WizardProps = {
  children: any,
  wizardState: WizardState,
  validateStep: (number) => boolean,
  onCurrentStepChange?: (number) => void,
  onComplete?: () => any,
  onCancel?: () => void,
  lastStepButtonText?: string,
};

class Wizard extends Component<WizardProps> {
  handleContinueButtonOnClick: () => void;
  handleGoBackButtonOnClick: () => void;
  handleGoToButtonOnClick: (number) => void;
  handleCancelButtonOnClick: () => void;

  constructor(props: WizardProps) {
    super(props);

    this.handleContinueButtonOnClick = this.handleContinueButtonOnClick.bind(this);
    this.handleGoBackButtonOnClick = this.handleGoBackButtonOnClick.bind(this);
    this.handleGoToButtonOnClick = this.handleGoToButtonOnClick.bind(this);
    this.handleCancelButtonOnClick = this.handleCancelButtonOnClick.bind(this);
  }

  handleContinueButtonOnClick(): void {
    const wizardState = this.props.wizardState;
    if (!this.props.validateStep(wizardState.currentStep)) return;

    if (wizardState.canGoForward) {
      wizardState.next();
      if (this.props.onCurrentStepChange) {
        this.props.onCurrentStepChange(wizardState.currentStep);
      }
    } else if (this.props.onComplete) {
      this.props.onComplete();
    }
  }

  handleGoBackButtonOnClick(): void {
    const wizardState = this.props.wizardState;

    if (wizardState.canGoBackward) {
      wizardState.previous();
      if (this.props.onCurrentStepChange) {
        this.props.onCurrentStepChange(wizardState.currentStep);
      }
    } else if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  handleGoToButtonOnClick(step: number): void {
    const wizardState = this.props.wizardState;
    wizardState.goTo(step);
    if (this.props.onCurrentStepChange) {
      this.props.onCurrentStepChange(wizardState.currentStep);
    }
  }

  handleCancelButtonOnClick() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  UNSAFE_componentWillMount() {
    this.props.wizardState.setSteps(this.props.children.length);
  }

  buildHeader(children: WizardStep[], wizState: WizardState) {
    const { currentStep } = wizState;
    const header = [];

    children.forEach((wizStep: WizardStep, index: number) => {
      const completed = index + 1 < currentStep;
      const active = index + 1 === currentStep;
      // const classes = classNames("wizard-step-header", {
      //   completed: completed,
      //   active: active
      // });

      let stateIcon = null;
      if (active) stateIcon = <FontAwesomeIcon icon="flag" className="icon right" />;
      else if (completed)
        stateIcon = <FontAwesomeIcon icon="check" className="icon right" />;

      header.push(
        <StepHeader
          active={active}
          completed={completed}
          key={`step-header-${index}`}
          onClick={completed ? this.handleGoToButtonOnClick.bind(this, index + 1) : null}
        >
          <label>
            Step {index + 1} {stateIcon}
          </label>
          <header>{wizStep.props.title}</header>
        </StepHeader>
      );

      if (index < children.length - 1) {
        header.push(
          <div
            key={`step-header-sep-${index}`}
            className={classNames("intersection", { disabled: currentStep < index + 1 })}
          >
            <FontAwesomeIcon icon="arrow-right" />
          </div>
        );
      }
    });

    return header;
  }

  render() {
    const { children, wizardState } = this.props;
    const { previousStep, currentStep, canGoForward } = wizardState;
    const forward = previousStep < currentStep;

    const renderPrevStep =
      Boolean(previousStep) && previousStep > 0 && previousStep !== currentStep;
    return (
      <div className="rc-wizard">
        <Box
          css={{
            borderBottom: "1px solid #eceff1",
            position: "relative",
            display: "block",
            padding: 20,
          }}
          className="rc-wizard-header"
        >
          {this.buildHeader(children, wizardState)}
        </Box>
        <Box
          className="rc-wizard-content"
          css={{
            borderBottom: "1px solid #eceff1",
            position: "relative",
            display: "block",
            padding: 20,
            ".pt-ui &": { fontSize: "$base", lineHeight: "$base" },
          }}
        >
          {renderPrevStep &&
            React.cloneElement(children[previousStep - 1], {
              key: previousStep,
              hide: true,
              forward: forward,
            })}
          {React.cloneElement(children[currentStep - 1], {
            key: currentStep,
            show: true,
            forward: forward,
          })}
        </Box>
        <ModalButtons
          css={{
            padding: "$5",
            backgroundColor: "$primaryLighter",
            borderBottomRightRadius: "$rounded",
            borderBottomLeftRadius: "$rounded",
          }}
        >
          <Button size="large" onClick={this.handleCancelButtonOnClick}>
            Cancel
          </Button>
          <Spacer />
          <Button
            size="large"
            onClick={this.handleGoBackButtonOnClick}
            disabled={wizardState.disableNavigation}
          >
            Previous
          </Button>
          {canGoForward ? (
            <Button
              color="brand"
              size="large"
              iconRight="arrow-right"
              onClick={this.handleContinueButtonOnClick}
              disabled={wizardState.disableNavigation}
            >
              Next
            </Button>
          ) : (
            <Button
              color="brand"
              size="large"
              onClick={this.handleContinueButtonOnClick}
              disabled={wizardState.disableNavigation}
            >
              {this.props.lastStepButtonText ? this.props.lastStepButtonText : "Finish"}
            </Button>
          )}
        </ModalButtons>
      </div>
    );
  }
}

export default observer(Wizard);

export { WizardStep, WizardState };
