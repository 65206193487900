// @flow
import * as React from "react";
import AsyncSelect from "react-select/lib/Async";
import { reactSelectStyles, reactSelectTheme } from "../../constants/reactSelect";

type country = { locationId: number, name: string };
type countryNode = { node: country };
type countryOption = { value: number, label: string };

type Props = {
  CountryTitle: (string) => Promise<{ countries: Array<countryNode> }>,
  onChange: (Array<country>) => void,
  value?: Array<countryOption>,
  disabled: boolean,
};

class MultiCountrySelect extends React.Component<Props> {
  getOptions = (input: string, callback: (Array<countryOption>) => void) => {
    var countryList = [];
    this.props
      .CountryTitle(input)
      .then((response) => {
        response.countries.forEach((e) => {
          var optionItem = {};
          optionItem["value"] = e.node.locationId;
          optionItem["label"] = e.node.name.toString();
          countryList.push(optionItem);
        });
        callback(countryList);
      })
      .catch(function (error) {
        callback([]);
      });
  };

  handleChange = (value: Array<countryOption>) => {
    if (value) {
      this.props.onChange(
        value.map((country: countryOption) => ({
          locationId: country.value,
          name: country.label,
        }))
      );
    } else {
      this.props.onChange([]);
    }
  };

  render() {
    return (
      <div>
        <AsyncSelect
          name="country"
          placeholder="Country"
          defaultOptions
          cacheOptions={false}
          loadOptions={this.getOptions}
          value={this.props.value}
          backspaceRemoves={true}
          onChange={this.handleChange}
          openMenuOnFocus
          isMulti
          isDisabled={this.props.disabled}
          styles={reactSelectStyles}
          theme={reactSelectTheme}
        />
      </div>
    );
  }
}

export default MultiCountrySelect;
