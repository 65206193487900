// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import LoadingIndicator from "../../shared/LoadingIndicator";
import LocationListItem from "./LocationListItem";
import { LocationListComponentStore } from "../../../stores/mobx/LocationListStore";
import ToggleButton from "../../ratecards/components/ToggleButton";
import { FILTER_COLUMN } from "../../../models/Filter";
import FilterModal from "../../ratecards/components/FilterModal";
import ContainerSection from "../../ratecards/components/ContainerSection";
import ListContainer from "../../ratecards/components/ListContainer";
import Button from "../../../components/lib/Button";
import Inline from "../../../components/lib/Inline";
import Pagination from "../../../components/lib/Pagination";
import { CardAlert, CardAlertLink } from "../../../components/lib/Card";

class LocationList extends Component {
  constructor(props) {
    super(props);

    this.changeCurrent = this.changeCurrent.bind(this);
  }

  changeCurrent(value) {
    let store: LocationListComponentStore = this.props.store;
    store.pagination.handleCurrentPage(value);
  }

  componentDidMount() {
    this.props.store.pagination.goFetch();
  }

  render() {
    const store: LocationListComponentStore = this.props.store;
    const network = store.network;
    const fillContainer = this.props.fillContainer;
    const fixedHeight = this.props.fixedHeight;
    let selectAllSection = null;
    let editClass = "";

    let listBodyStyles = {};
    if (fixedHeight) {
      listBodyStyles.height = fixedHeight;
      listBodyStyles.overflowY = "auto";
    }

    let selectAllOnPageSection = (
      <Inline>
        <Button onClick={store.selectAllOnPageItem}>Select All</Button>
        <Button onClick={store.deselectAllPage}>Deselect All</Button>
      </Inline>
    );

    if (store.allOnPageSelected) {
      selectAllSection = (
        <CardAlert>
          <span>
            All <strong>{store.totalCount}</strong> Location selected.{" "}
          </span>
          &nbsp;
          <CardAlertLink key="clear-selections-link" onClick={store.clearAllSelections}>
            Clear all selections
          </CardAlertLink>
        </CardAlert>
      );
    }

    if (store.allOnPageSelected && !store.allSelected) {
      selectAllSection = (
        <CardAlert>
          <span>All Location on this page selected.</span>
          <CardAlertLink key="select-all-item" onClick={store.selectAllPage}>
            Select all <strong>{store.totalCount}</strong> Location.
          </CardAlertLink>
          <CardAlertLink key="clear-selections-link" onClick={store.clearAllSelections}>
            Clear all selections
          </CardAlertLink>
        </CardAlert>
      );
    }

    let tableLeftActionBar = null;
    if (store.isEditing) {
      tableLeftActionBar = (
        <nav className="header-left-nav">
          {store.allowMultipleItemSelection && selectAllOnPageSection}
        </nav>
      );
    }

    const regions = store.regionsListView;
    let tableContent = null;
    if (network.loading) {
      tableContent = <LoadingIndicator />;
    } else if (network.error) {
      // TODO: replace this with a more friendly error message
      tableContent = (
        <div className="pt-error-message">
          <h2>Error</h2>
          <pre>
            <code>{JSON.stringify(network.error, null, 2)}</code>
          </pre>
        </div>
      );
    } else if (regions.length === 0) {
      tableContent = (
        <div className="ratecards-table-empty">
          <div>
            No Location Found
            <p>Create a new Location using the button above or by searching.</p>
          </div>
        </div>
      );
    } else {
      tableContent = regions.map((region) => (
        <LocationListItem key={region.id} store={store} region={region} />
      ));
    }

    if (store.isEditing) {
      editClass = "view";
    } else if (!store.isEditing) {
      editClass = "rt";
    }

    return (
      <div className={editClass}>
        <div className="ratecards-list">
          {store.hasLocationTypeFilter && (
            <FilterModal title="Location Type" filterState={store.hasLocationTypeFilter}>
              {store.hasLocationTypeFilter.network.loading && <LoadingIndicator />}
              {!store.hasLocationTypeFilter.network.loading && (
                <ContainerSection>
                  <p>Filter by a specific Location Type:</p>
                  <ListContainer>
                    {store.hasLocationTypeFilter.viewItems.map((item) => (
                      <ToggleButton
                        style={{ marginRight: "10px" }}
                        key={item.id}
                        large={true}
                        item={item}
                        type="radio"
                        name="rate-type"
                        value={item.value}
                        selected={item.selected}
                        onChange={store.hasLocationTypeFilter.setSelectedValue}
                      >
                        {item.value}
                      </ToggleButton>
                    ))}
                  </ListContainer>
                </ContainerSection>
              )}
            </FilterModal>
          )}
          <header
            className={classNames("ratecards-table-header", {
              active: store.isFiltered,
            })}
          >
            {tableLeftActionBar}
            <nav className="header-right-nav atl-1">
              {store.isFiltered && (
                <div className="btn btn-yellow" onClick={store.clearFilters}>
                  Clear All Filters & Sorts
                </div>
              )}
              {store.hasLocationTypeFilter && (
                <ToggleButton
                  borderless={true}
                  name="rate-cards-filter"
                  value={FILTER_COLUMN.LOCATION_TYPE}
                  selected={Boolean(store.appliedFilters[FILTER_COLUMN.LOCATION_TYPE])}
                  onClick={store.hasLocationTypeFilter.onShowModal}
                >
                  Type
                  <span> ▼</span>
                </ToggleButton>
              )}
            </nav>
          </header>
          <div
            className={classNames("ratecards-table", {
              "ratecards-table-fill-container": fillContainer,
            })}
          >
            <div style={listBodyStyles}>
              {selectAllSection}
              {tableContent}
            </div>
          </div>
        </div>
        {store.pagination.pageCount > 0 ? (
          <Pagination
            options={{
              variant: "full",
              currentPage: store.pagination.currentPage,
              numPages: store.pagination.pageCount,
            }}
            onPageClick={(pageEvent) => {
              if (pageEvent.type === "first") {
                this.changeCurrent(1);
              }
              if (pageEvent.type === "last") {
                this.changeCurrent(store.pagination.pageCount);
              }
              if (pageEvent.type === "next") {
                this.changeCurrent(
                  Math.min(store.pagination.currentPage + 1, store.pagination.pageCount)
                );
              }
              if (pageEvent.type === "prev") {
                this.changeCurrent(Math.max(store.pagination.currentPage - 1, 1));
              }
              if (pageEvent.type === "page") {
                this.changeCurrent(pageEvent.page);
              }
            }}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

LocationList.propTypes = {
  store: PropTypes.object.isRequired,
  fixedHeight: PropTypes.number,
  fillContainer: PropTypes.bool,
};

export default observer(LocationList);
