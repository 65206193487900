// @flow

import React from "react";
import type { Element } from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../../ratecards/components/Modal";
import ModalState from "../../../models/ModalState";
import { ModalButtons, ModalTitle } from "../../ratecards/components/FilterModal";
import Button from "../../../components/lib/Button";

type Props = {
  modalState: ModalState,
  onRemoveInvalidJobs: () => void,
  children: Element<any>,
};

const InvalidJobsErrorModal = (props: Props) => {
  const { modalState, children } = props;

  return (
    <Modal
      show={modalState.show}
      onHide={modalState.hideModal}
      innerStyle={{ width: "80vw" }}
    >
      <div className="container-section header">
        <ModalTitle>Error</ModalTitle>
      </div>
      <div className="container-section">
        <div className="flex">
          <div>
            <FontAwesomeIcon
              icon="exclamation-circle"
              className="text-danger"
              style={{ fontSize: 38, marginRight: 20 }}
            />
          </div>
          <div>
            <p>
              Could not run Batch Search. The following jobs are not allowed in the
              specified location.
            </p>
            <div style={{ maxHeight: "80vh", overflowY: "auto" }}>{children}</div>
          </div>
        </div>
      </div>
      <div className="container-section footer">
        <ModalButtons>
          <Button color="accent" size="large" onClick={props.onRemoveInvalidJobs}>
            Remove These Jobs From Batch Search
          </Button>
          <Button className="large" onClick={modalState.hideModal}>
            Manually Change The Jobs
          </Button>
        </ModalButtons>
      </div>
    </Modal>
  );
};

export default observer(InvalidJobsErrorModal);
