// @flow
import R from "ramda";
import { extendObservable, action, runInAction, computed, observable } from "mobx";
import moment from "moment";
import axios from "axios";
import ProjectCostEstimates from "../../models/ProjectCostEstimate";
import RateCardSearchDetails from "../../models/RateCardSearchesDetails";
import CurrentUser from "../../models/User";
import NetworkState from "../../models/NetworkState";
import PaginationState from "../../models/PaginationState";
import FilterObject, { FILTER_COLUMN } from "../../models/Filter";
import Sort, { SORT_DIRECTION } from "../../models/Sort";
import {
  ProjectCostFilter,
  CreatedByFilter,
  CreatedOnFilter,
  TagsFilter,
} from "../../models/FilterState";
import ModalState from "../../models/ModalState";
import { consolidateAppliedFilters, consolidateAppliedSorts } from "./SupportFunctions";
import type { PageQuery, PaginationInfo } from "../../models/PaginationState";
import type { FilterColumn } from "../../models/Filter";
import type { GraphQLQuery } from "../../models/GraphQL";
import ExportOptionsState from "../../models/ExportOptionsState";
import MessageState from "../../models/MessageState";
import { browserHistory } from "react-router";
import ApplyTagState from "../../models/ApplyTagState";
import type { FetchGraphQL, FetchAPI } from "../../App";
import { CONTRACT } from "../../constants/rateTypes";

// Required for Created by filter
const ownersCriteriaQuery = `
  query authorList {
    viewer {
      authors {
        edges {
          node {
            userId
            firstName
            lastName
            username
            email
          }
        }
      }
    }
  }
  `;

const tagsFilterCriteria = `
query getTags {
 viewer{
   tagsFilterCriteria{
     tagnames(contentType: PROJECT_STATS){
       tag
     }
   }
 }
}
`;

export class ProjectCostEstimateComponentStore {
  network: NetworkState;
  pagination: PaginationState;

  currentUser: CurrentUser;
  isEditing: ?boolean;
  allOnPageSelected: boolean;
  allSelected: boolean;
  allSelectedfilter: boolean;
  allowMultipleItemSelection: boolean;

  projectCosts: ProjectCostEstimates[];
  projectCostsViewState: Object;
  projectCostsView: ProjectCostEstimates[];
  projectCounts: any;
  selectedPunchouts: any;

  projectCostFilter: ProjectCostFilter;
  createdByFilter: CreatedByFilter;
  createdOnFilter: CreatedOnFilter;
  tagsFilter: TagsFilter;

  defaultFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedSorts: {
    [key: FilterColumn]: Sort,
  };
  appliedSortsOrder: Array<FilterColumn>;
  isFiltered: boolean;

  getProjects: (PageQuery) => Promise<PaginationInfo>;
  applyDefaultFilter: (FilterColumn, FilterObject) => void;
  handleStartEdit: () => void;
  handleStopEdit: () => void;
  applySort: (FilterColumn, Sort) => void;
  removeSort: (FilterColumn) => void;
  applyDefaultSort: () => void;
  toDateRange: () => void;

  toggleSelectAllPage: (Object) => void;
  selectAllPage: (Event) => void;
  deselectAllPage: (Event) => void;
  toggleAllItems: () => void;
  clearAllSelections: () => void;
  getSelectedProjects: () => Array<string>;

  getFilterCriteriaQuery: (FilterColumn) => GraphQLQuery;
  processFilterCriteria: (FilterColumn, Object) => Array<Object>;
  applyFilter: (FilterColumn, FilterObject) => void;
  removeFilter: (FilterColumn) => void;
  filterProjects: () => void;
  clearFilters: () => void;
  applyTagState: ApplyTagState;
  isTagView: ?boolean;
  setTagViewFn: () => void;
  selectAllOnPageItem: () => void;
  newPCEName: string;
  showRenameModal: () => void;
  renameModal: ModalState;
  onNewPCENameChange: () => void;
  getFirstSelectedPCE: () => void;
  messaging: MessageState;
  clearAllSelectionsOnTagView: () => void;
  fetchGraphQL: FetchGraphQL;

  constructor(fetchGraphQL: FetchGraphQL) {
    this.fetchGraphQL = fetchGraphQL;

    this.getProjects = action(this.getProjects.bind(this));
    this.getFilterCriteriaQuery = action(this.getFilterCriteriaQuery.bind(this));
    this.processFilterCriteria = action(this.processFilterCriteria.bind(this));
    this.applyFilter = action(this.applyFilter.bind(this));
    this.applySort = action(this.applySort.bind(this));
    this.removeFilter = action(this.removeFilter.bind(this));
    this.removeSort = action(this.removeSort.bind(this));
    this.toDateRange = action(this.toDateRange.bind(this));
    this.setTagViewFn = action(this.setTagViewFn.bind(this));

    extendObservable(this, {
      network: new NetworkState(),
      pagination: new PaginationState(this.getProjects),
      projectCosts: [],
      projectCounts: 0,
      selectedPunchouts: observable.map({}),
      projectCostsViewState: observable.map({}),
      projectCostsView: computed(() => {
        return this.projectCosts.map((projectCost) => {
          if (this.projectCostsViewState.has(projectCost.projectCostId)) {
            projectCost.viewState = this.projectCostsViewState.get(
              projectCost.projectCostId
            );

            return projectCost;
          }
          return projectCost;
        });
      }),
      isEditing: null, // we start with null so some view elements be hidden initially
      isFiltered: false,
      allSelected: false,
      allowMultipleItemSelection: true,
      selectedCount: computed(() => {
        const selectedValues = this.projectCostsView.map(
          (projectCost) => projectCost.viewState.selected
        );

        if (this.allSelected) {
          return this.pagination.totalCount;
        }

        let count = 0;

        selectedValues.forEach((v) => {
          if (v) {
            count += 1;
          }
        });

        return count;
      }),
      allOnPageSelected: computed(() => {
        const allTrue = R.all(R.equals(true));
        const selectedValues = this.projectCostsView.map(
          (projectCost) => projectCost.viewState.selected
        );

        if (selectedValues.length === 0) {
          return false;
        }

        return allTrue(selectedValues);
      }),
      defaultFilters: {},
      appliedFilters: {},
      appliedSorts: {},
      appliedSortsOrder: observable.shallow([]),
      projectCostFilter: new ProjectCostFilter(
        this,
        FILTER_COLUMN.PROJECT_LABEL,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      createdByFilter: new CreatedByFilter(
        this,
        FILTER_COLUMN.CREATED_BY,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      createdOnFilter: new CreatedOnFilter(
        this,
        FILTER_COLUMN.PC_DATE_RANGE, // New filter type only for PCE because the date type is CREATED
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      applyTagState: new ApplyTagState(fetchGraphQL, this),
      allSelectedfilter: false,
      tagsFilter: new TagsFilter(
        this,
        FILTER_COLUMN.TAGS,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      isTagView: null,
      renameModal: new ModalState(),
      newPCEName: "",
      messaging: new MessageState(),
    });

    this.currentUser = null;
    this.handleStartEdit = action(this.handleStartEdit.bind(this));
    this.handleStopEdit = action(this.handleStopEdit.bind(this));
    this.filterProjects = action(this.filterProjects.bind(this));
    this.clearFilters = action(this.clearFilters.bind(this));
    this.applyDefaultSort = action(this.applyDefaultSort.bind(this));
    this.applyDefaultFilter = action(this.applyDefaultFilter.bind(this));
    this.toggleSelectAllPage = action(this.toggleSelectAllPage.bind(this));
    this.selectAllPage = action(this.selectAllPage.bind(this));
    this.deselectAllPage = action(this.deselectAllPage.bind(this));
    this.toggleAllItems = action(this.toggleAllItems.bind(this));
    this.clearAllSelections = action(this.clearAllSelections.bind(this));
    this.getSelectedProjects = action(this.getSelectedProjects.bind(this));
    this.selectAllOnPageItem = action(this.selectAllOnPageItem.bind(this));
    this.applyDefaultSort();
    this.showRenameModal = action(this.showRenameModal.bind(this));
    this.onNewPCENameChange = action(this.onNewPCENameChange.bind(this));
    this.getFirstSelectedPCE = action(this.getFirstSelectedPCE.bind(this));
    this.clearAllSelectionsOnTagView = action(
      this.clearAllSelectionsOnTagView.bind(this)
    );
  }

  showRenameModal() {
    this.messaging.removeAll();
    const pce = this.getFirstSelectedPCE();
    if (!pce) return;
    this.newPCEName = pce.name;
    this.renameModal.showModal();
  }

  toDateRange(date) {
    var val = true;
    if (this.createdOnFilter.fromDate) {
      val = !date.isBetween(
        this.createdOnFilter.fromDate,
        moment(new Date()),
        "days",
        "[]"
      );
    }
    return val;
  }

  onNewPCENameChange(e) {
    this.messaging.removeAll();
    this.newPCEName = e.target.value;
  }

  getFirstSelectedPCE() {
    for (let j = 0; j < this.projectCostsView.length; j++) {
      if (this.projectCosts[j].viewState.selected) return this.projectCosts[j];
    }

    return null;
  }

  canExport() {
    const exportRole = "No Export Allowed";
    if (
      this.currentUser &&
      this.currentUser.roles &&
      this.currentUser.roles.indexOf(exportRole) > -1
    ) {
      return false;
    }
    return true;
  }

  setTagViewFn() {
    this.isTagView = true;
    this.isEditing = true;
  }

  applyDefaultSort() {
    this.createdOnFilter.sortState.direction = SORT_DIRECTION.DESC;
    this.createdOnFilter.sortState.field = "CREATED";
    this.createdOnFilter.sort = this.createdOnFilter.buildQuerySort();
    this.applySort(this.createdOnFilter.column, this.createdOnFilter.sort);
  }

  filterProjects() {
    return this.pagination.goFetch(null);
  }

  handleStartEdit() {
    this.isEditing = true;
    this.projectCostsView.forEach((projectCost) => projectCost.toggleEdit());
  }

  handleStopEdit() {
    this.isEditing = false;
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.projectCostsViewState.forEach((viewState) => {
      viewState.selected = false;
      viewState.editing = false;
    });
  }

  applyFilter(column: FilterColumn, filter: FilterObject) {
    this.appliedFilters[column] = filter;
    this.isFiltered = true;
  }

  applyDefaultFilter(column: FilterColumn, filter: FilterObject) {
    this.defaultFilters[column] = filter;
  }

  applySort(column: FilterColumn, sort: Sort) {
    this.appliedSorts[column] = sort;

    const index = this.appliedSortsOrder.indexOf(column);
    if (index === -1) this.appliedSortsOrder.push(column);
  }

  removeFilter(column: FilterColumn) {
    delete this.appliedFilters[column];

    let entries = Object.entries(this.appliedFilters);
    if (!entries.length) this.isFiltered = false;
  }

  removeSort(column: FilterColumn) {
    delete this.appliedSorts[column];

    const index = this.appliedSortsOrder.indexOf(column);
    if (index > -1) this.appliedSortsOrder.splice(index, 1);
  }

  // Clear all the requested filters
  clearFilters() {
    this.projectCostFilter = new ProjectCostFilter(
      this,
      FILTER_COLUMN.RATE_CARD_LABEL,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.createdByFilter = new CreatedByFilter(
      this,
      FILTER_COLUMN.CREATED_BY,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.createdOnFilter = new CreatedOnFilter(
      this,
      FILTER_COLUMN.PC_DATE_RANGE,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.tagsFilter = new TagsFilter(
      this,
      FILTER_COLUMN.TAGS,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );

    this.appliedFilters = observable({});
    this.appliedSorts = observable({});
    this.appliedSortsOrder.length = 0;
    this.isFiltered = false;
    this.applyDefaultSort();

    return this.pagination.goFetch(null); // Call pagination once the filter is cleared, load the DOM
  }

  getFilterCriteriaQuery(column: FilterColumn): GraphQLQuery {
    switch (column) {
      case FILTER_COLUMN.CREATED_BY:
        return {
          query: ownersCriteriaQuery,
          variables: {},
        };

      case FILTER_COLUMN.TAGS:
        return {
          query: tagsFilterCriteria,
          variables: {},
        };

      default:
        return null;
    }
  }

  processFilterCriteria(column: FilterColumn, payload: Object): ?Array<Object> {
    switch (column) {
      case FILTER_COLUMN.CREATED_BY:
        const owners: [
          {
            userId: string,
            username: string,
          }
        ] = payload.data.viewer.authors.edges;
        let processedOwners = observable.map({});
        owners.forEach((owner) => {
          processedOwners.set(String(owner.node.userId), {
            id: String(owner.node.userId),
            ...owner,
          });
        });

        return processedOwners;

      case FILTER_COLUMN.TAGS:
        const tags: [
          {
            tag: string,
          }
        ] = payload.data.viewer.tagsFilterCriteria.tagnames;
        let processedTag = observable.map({});
        tags.forEach((tag, index) => {
          processedTag.set(String(index), {
            id: String(index),
            ...tag,
          });
        });
        return processedTag;

      default:
        return null;
    }
  }

  toggleSelectAllPage(e: Object) {
    if (!this.allowMultipleItemSelection) return;

    const setValue = !this.allOnPageSelected;

    this.projectCostsView.forEach((project) => {
      project.toggleSelected(e, null, null, setValue);
    });

    // When All items selected flag is up, clear selection
    if (setValue === false && this.allSelected) this.allSelected = false;
  }

  // Select all the project
  selectAllPage(e: Event) {
    this.allSelected = true;
    this.allSelectedfilter = true;
  }

  selectAllOnPageItem(e: Event) {
    this.projectCostsView.forEach((project) => {
      project.toggleSelected(e, null, null, true);
    });
  }

  // Deselect all the project
  deselectAllPage(e: Event) {
    this.projectCostsView.forEach((project) => {
      project.toggleSelected(e, null, null, false);
    });

    this.allSelected = false;
    this.allSelectedfilter = false;
  }

  toggleAllItems() {
    if (!this.allowMultipleItemSelection) return;

    this.allSelected = !this.allSelected;

    if (this.allSelected === false) {
      this.projectCostsView.forEach((value) => {
        value.viewState.selected = false;
      });
    }
  }

  clearAllSelections() {
    this.isTagView = false;
    //    this.isEditing = false;
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.projectCostsView.forEach((value) => {
      value.viewState.selected = false;
    });
  }

  clearAllSelectionsOnTagView() {
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.projectCostsView.forEach((value) => {
      value.viewState.selected = false;
    });
  }

  // Collect all the selected projects for editing
  getSelectedProjects(): Array<string> {
    const projects = this.projectCostsViewState;
    let selectedProjects = [];

    projects.forEach((value, key) => {
      if (value.selected) {
        selectedProjects.push(key);
      }
    });
    return selectedProjects;
  }

  async getProjects(pageQuery: PageQuery): Promise<PaginationInfo> {
    let params: string[] = pageQuery.params;
    let args = pageQuery.args;
    let variables = pageQuery.variables;
    let filtersCriteria: string[] = [];

    let sortCriteria: string[] = [];
    consolidateAppliedSorts(this.appliedSorts, sortCriteria);

    consolidateAppliedFilters(this.appliedFilters, params, filtersCriteria, variables);

    consolidateAppliedFilters(this.defaultFilters, params, filtersCriteria, variables);

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");
    const queryFiltersCriteria = filtersCriteria.join(", ");
    const querySortCriteria = sortCriteria.join(", ");

    const query = `
      query project (${queryParams}){
       viewer {
        user{
            firstName
            lastName
            userId
            username
            email
            roles
          }
         projectCosts(${queryArgs}, filters: { ${queryFiltersCriteria} }, order: [${querySortCriteria}]) {
           edges {
             node {
               name
               projectId
               tags{
                name
                tagId
               }
               user {
                 firstName
                 lastName
                 userId
               }
               created
             }
           }
           totalCount
         }
       }
      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting Projects", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Projects query", res);
      }

      return e;
    }

    return runInAction("getProjects--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get Projects query", res)) {
        // TODO: Display user friendly error message
        return {
          totalCount: 0,
        };
      }
      const pc = res.data.viewer.projectCosts.edges;
      this.currentUser = new CurrentUser(this, res.data.viewer.user);
      this.projectCounts = res.data.viewer.projectCosts.totalCount;

      this.projectCosts = pc.map((pce) => {
        const projectCost = new ProjectCostEstimates(this, pce.node);

        if (!this.projectCostsViewState.has(projectCost.projectCostId)) {
          this.projectCostsViewState.set(projectCost.projectCostId, {
            selected: this.allSelected,
            editing: this.isEditing,
          });
        } else {
          const selectedValue = this.allSelected
            ? true
            : this.projectCostsViewState.get(projectCost.projectCostId).selected;

          this.projectCostsViewState.set(projectCost.projectCostId, {
            selected: selectedValue,
            editing: this.isEditing,
          });
        }

        projectCost.viewState = this.projectCostsViewState.get(projectCost.projectCostId);

        return projectCost;
      });

      return {
        totalCount: res.data.viewer.projectCosts.totalCount,
      };
    });
  }
}

export default class ProjectCostEstimateStore extends ProjectCostEstimateComponentStore {
  showHelpModal: boolean;
  showExportModal: boolean;
  exportFileName: string;
  exportType: string;
  allowExpand: boolean;
  rateCardSearch: RateCardSearchDetails[];
  messaging: MessageState;

  helpModal: ModalState;
  exportModal: ModalState;
  confirmDeleteModal: ModalState;

  exportModalState: ExportOptionsState;

  selectedExcel: boolean;
  selectedCSV: boolean;

  showHelp: () => void;
  hideHelp: () => void;
  showExport: () => void;
  hideExport: () => void;
  deleteProjects: () => void;
  export: () => void;
  PCEtoggleExpand: () => void;
  getRateCardDetail: () => void;
  validateExportOptions: (modalState: ExportOptionsState) => void;
  togglePunchoutSelect: () => void;
  selectProjects: () => void;
  changeDateFormat: () => void;
  changeExportFileName: (Object) => void;
  handlePunchoutEditStop: () => void;
  selectedProjectStats: () => void;
  applyTags: () => void;
  deleteSingleTags: () => void;
  renameProject: () => void;
  fetchAPI: FetchAPI;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI) {
    super(fetchGraphQL);
    this.fetchAPI = fetchAPI;

    // Default name for exported file
    extendObservable(this, {
      exportFileName: "exported_pce_" + Math.floor(Math.random() * 9999999 + 1000000),
      exportType: "excel", // Default type for Export
      messaging: new MessageState(),
      helpModal: new ModalState(),
      exportModal: new ModalState(),
      confirmDeleteModal: new ModalState(),
      networkShareUsers: new NetworkState(),
      allowExpand: true,
      selectedExcel: true,
      selectedCSV: false,
      rateCardSearch: [],
      punchoutArray: [],
      punchoutViewState: observable.map({}),
      punchoutArrayView: computed(() => {
        return this.punchoutArray.map((punchout) => {
          if (this.punchoutViewState.has(punchout.punchoutId)) {
            punchout.viewState = this.punchoutViewState.get(punchout.punchoutId);

            return punchout;
          }
          return punchout;
        });
      }),
    });

    this.showHelp = action(this.showHelp.bind(this));
    this.hideHelp = action(this.hideHelp.bind(this));
    this.showExport = action(this.showExport.bind(this));
    this.hideExport = action(this.hideExport.bind(this));
    this.PCEtoggleExpand = action(this.PCEtoggleExpand.bind(this));
    this.getRateCardDetail = action(this.getRateCardDetail.bind(this));
    this.togglePunchoutSelect = action(this.togglePunchoutSelect.bind(this));
    this.handlePunchoutEditStop = action(this.handlePunchoutEditStop.bind(this));
    this.selectedProjectStats = action(this.selectedProjectStats.bind(this));
    this.deleteProjects = action(this.deleteProjects.bind(this));
    this.selectProjects = action(this.selectProjects.bind(this));
    this.changeDateFormat = action(this.changeDateFormat.bind(this));
    this.export = action(this.export.bind(this));
    this.changeExportFileName = action(this.changeExportFileName.bind(this));
    this.handleExcelTypeChange = action(this.handleExcelTypeChange.bind(this));
    this.applyTags = action(this.applyTags.bind(this));
    this.deleteSingleTags = action(this.deleteSingleTags.bind(this));
    this.renameProject = action(this.renameProject.bind(this));
  }

  changeExportFileName(e: Object) {
    this.exportFileName = e.target.value;
  }

  handleExcelTypeChange(value) {
    this.selectedCSV = !this.selectedCSV;
    this.selectedExcel = !this.selectedExcel;
    this.exportType = value;
  }

  showHelp() {
    this.showHelpModal = true;
  }

  hideHelp() {
    this.showHelpModal = false;
  }

  showExport() {
    this.showExportModal = true;
  }

  hideExport() {
    this.showExportModal = false;
  }

  changeDateFormat(date) {
    var formatted_date = new Date(date);
    var year = formatted_date.getFullYear() + "";
    var month = formatted_date.getMonth() + 1 + "";
    var day = formatted_date.getDate() + "";
    var dateFormat = year + "-" + month + "-" + day;

    return dateFormat;
  }

  selectProjects(type) {
    if (this.network.loading) {
      return;
    }

    let params = [];
    let args = [];
    let vars = {};
    let searchCriteria = "";

    if (this.allSelected) {
      consolidateAppliedFilters(this.appliedFilters, params, args, vars);
      searchCriteria = {
        searchParam: vars,
      };
    } else {
      const selectedProjects = this.getSelectedProjects();

      if (!selectedProjects) {
        console.error("Cannot " + type + " projects: No Project selected");
        return;
      }
      searchCriteria = {
        projectId: selectedProjects,
      };
    }

    return searchCriteria;
  }

  async applyTags() {
    let params = [];
    let filterargs = [];
    let searchargs = [];
    let args = [];
    let vars = {};

    const taglist = this.applyTagState.getSelectedTagList();
    if (!taglist || !taglist.length) {
      console.error("Cannot Apply tags to PCE: No PCE selected");
      return;
    }
    params.push("$tagIds: [Int]!");
    filterargs.push("tagIds: $tagIds");
    vars.tagIds = taglist;

    if (this.allSelectedfilter) {
      consolidateAppliedFilters(this.appliedFilters, params, args, vars);
      params.push("$exclude: [ID]");
      args.push("exclude: $exclude");
      vars.exclude = [];
      // vars.exclude = vars.exclude.filter(function(item, pos) {
      //   return vars.exclude.indexOf(item) == pos;
      // })
    } else {
      const itemList = this.getSelectedProjects();
      if (!itemList || !itemList.length) {
        console.error("Cannot apply tag PCE: No PCE selected");
        return;
      }
      params.push("$only: [ID]");
      args.push("only: $only");
      vars.only = itemList;
    }

    if (this.projectCostFilter.textToLookFor) {
      params.push("$nameIContains: String");
      args.push("nameIContains:$nameIContains");
      vars.nameIContains = this.projectCostFilter.textToLookFor;
    }

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
      mutation applyTags(${queryParams}){
       applyTagsToProjects(input:{${filterargs}, filters: { ${queryArgs}}, ${searchargs}}) {
         ok
          errors {
           __typename
           ... on TagIdRequiredError {
             message
           }
           ... on TagIdsNotExistsError {
             message
           }
           ... on InvalidInputError {
             message
           }
           ... on ContentDoesNotExistsError {
             message
           }
         }
       }

      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Apply Tags to Selected PCE", e);
      // TODO: Display user friendly error message
      return;
    }
    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }
    this.handleStopEdit();

    runInAction("applyTags--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("applyTags", res)) {
        // TODO: Display user friendly error message
        return;
      }
      this.applyTagState.tagModal.hideModal();
      this.pagination.goFetch(null);
    });
  }

  // Method for deleting projects
  async deleteProjects(id) {
    let searchCriteria = {};
    let projectArray = [];
    let parameters = {};

    // if id is present (delete from the Details page)
    if (id) {
      projectArray[0] = id;
      searchCriteria.projectId = projectArray;
    } else {
      // else collect from the listings
      searchCriteria = this.selectProjects("delete");
    }

    if (searchCriteria.projectId !== null && searchCriteria.projectId !== undefined) {
      parameters.projectId = JSON.parse(
        JSON.stringify(searchCriteria.projectId).replace(/"/g, "")
      );
    }

    if (
      searchCriteria.searchParam !== null &&
      searchCriteria.searchParam !== undefined &&
      "nameFilterText" in searchCriteria.searchParam
    ) {
      parameters.search = searchCriteria.searchParam.nameFilterText;
    }

    if (
      searchCriteria.searchParam !== null &&
      searchCriteria.searchParam !== undefined &&
      "fromDate" in searchCriteria.searchParam &&
      "toDate" in searchCriteria.searchParam
    ) {
      parameters.fromDate = this.changeDateFormat(searchCriteria.searchParam.fromDate);
      parameters.toDate = this.changeDateFormat(searchCriteria.searchParam.toDate);
    }

    if (
      searchCriteria.searchParam !== null &&
      searchCriteria.searchParam !== undefined &&
      "tagnameIn" in searchCriteria.searchParam
    ) {
      parameters.tags = searchCriteria.searchParam.tagnameIn;
    }

    const query = `
      mutation deleteProject($input : DeleteProjectInput!){
       deleteProject(input: $input){
         ok
       }
      }
    `;

    const variables = {
      input: parameters,
    };

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Deleting selected Projects", e);
      return;
    }
    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }
    this.handleStopEdit();

    runInAction("deleteProject--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("deleteProject", res)) {
        return;
      }
      if (id) {
        browserHistory.push("/project-cost-estimate"); // If deleted from the details page move to the listings page
      } else {
        this.pagination.goFetch(null);
      }
    });
  }

  export() {
    var searchCriteria = this.selectProjects("export");

    let parameters = "";

    // Get only the selectProject
    if (searchCriteria.projectId !== null && searchCriteria.projectId !== undefined) {
      parameters = `{"only": [${searchCriteria.projectId}]}`;
    }

    if (
      searchCriteria.searchParam !== null &&
      searchCriteria.searchParam !== undefined &&
      "nameFilterText" in searchCriteria.searchParam
    ) {
      parameters = `{"search": "${searchCriteria.searchParam.nameFilterText}"}`;
    }

    if (
      searchCriteria.searchParam !== null &&
      searchCriteria.searchParam !== undefined &&
      "fromDate" in searchCriteria.searchParam &&
      "toDate" in searchCriteria.searchParam
    ) {
      parameters = `{"fromDate": "${searchCriteria.searchParam.fromDate}","toDate": "${searchCriteria.searchParam.toDate}" }`;
    }

    if (
      searchCriteria.searchParam !== null &&
      searchCriteria.searchParam !== undefined &&
      "tagnameIn" in searchCriteria.searchParam
    ) {
      parameters.tags = searchCriteria.searchParam.tagnameIn;
    }

    var fileName = this.exportFileName;
    var params;
    var url = `projects/basic/list/export/${this.exportType}/`;

    if (parameters === "") {
      params = `{"fileName": "${fileName}", "projectFilters": {${parameters}}}`;
    } else {
      params = `{"fileName": "${fileName}", "projectFilters": ${parameters}}`;
    }

    this.fetchAPI(url, JSON.parse(params))
      .then((res) => res.json())
      .then((json) => {
        window.location.href = json.url;
      })
      .catch((e) => {
        console.error("Error downloading excel", e);
        throw e; // Prevent success action from running
      });
    this.handleStopEdit();
  }

  selectedProjectStats(projectstats) {
    this.selectedPunchouts = projectstats;
  }

  // Expand and collapse Rate card details
  PCEtoggleExpand(ratecard, rateCards) {
    rateCards.forEach((rc) => {
      if (rc.ratecardId === ratecard.ratecardId) {
        rc.viewState.expanded = !rc.viewState.expanded;
        if (rc.viewState.expanded) {
          this.getRateCardDetail(ratecard.ratecardId);
        }
      } else {
        rc.viewState.expanded = false;
      }
    });
  }

  togglePunchoutSelect(selpunchout) {
    if (this.punchoutViewState.has(selpunchout.punchoutId)) {
      let punchout = this.punchoutViewState.get(selpunchout.punchoutId);
      punchout.selected = !punchout.selected;
    }
  }

  handlePunchoutEditStop() {
    this.punchoutArray = [];
    this.punchoutViewState.forEach((punchout) => {
      punchout.selected = false;
      punchout.alreadyAdded = false;
    });
  }

  async getRateCardDetail(rateCardId) {
    const variables = {
      id: rateCardId,
      rateType: CONTRACT.label,
      hasBuyRate: true,
    };

    const query = `
      query rateCardDetail($id: Int!, $rateType: JobRateType!, $hasBuyRate: Boolean) {
      viewer {
        rateCardSearches(id: $id, filters: {rateType: $rateType, hasBuyRate: $hasBuyRate}) {
          edges {
            node {
              searchId
              city
              state
              country
              region {
                name
              }
              currency {
                iso
              }
              industry {
                value
              }
              job {
                jobLabel
                jobTitle
                jobDescription
              }
              jobDescription
              createdDate
              buyrates {
                name
                buyrateId
                punchouts {
                  level {
                    value
                    legacyId
                    romanNumeral
                  }
                  punchoutId
                  payRateMin
                  payRateMax
                  markupPct
                  billRateMin
                  billRateMax
                }
              }
            }
          }
        }
      }
    }
    `;
    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting RateCard Details", e);
      if (res !== null) {
        this.network.logGraphQLError("Get RateCard Details query", res);
      }

      return e;
    }

    return runInAction("getRateCardDetail--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get RateCard Details query", res)) {
        return {};
      }

      const rcsd = res.data.viewer.rateCardSearches.edges;

      this.rateCardSearch = rcsd.map((rcd) => {
        const rCSearchDetail = new RateCardSearchDetails(this, rcd.node, rateCardId);

        rCSearchDetail.buyrate.forEach((rateBuyrate) => {
          rateBuyrate.punchout.forEach((punchout) => {
            this.punchoutArray.push(punchout);
          });
        });

        this.punchoutArray.forEach((punch) => {
          if (!this.punchoutViewState.has(punch.punchoutId)) {
            this.punchoutViewState.set(punch.punchoutId, {
              selected: false,
              alreadyAdded: false,
            });
          } else {
            const selectedValue = this.allPunchoutSelected
              ? true
              : this.punchoutViewState.get(punch.punchoutId).selected;

            this.punchoutViewState.set(punch.punchoutId, {
              selected: selectedValue,
              alreadyAdded: false,
            });
          }
        });

        rCSearchDetail.punchoutArray = this.punchoutArray;

        if (this.selectedPunchouts.length > 0) {
          for (let i = 0; i <= this.selectedPunchouts.length - 1; i++) {
            for (let j = 0; j <= this.punchoutArray.length - 1; j++) {
              const { rateCard, search, buyRate, level } = this.selectedPunchouts[i];
              const punchoutArrayElement = this.punchoutArray[j];
              if (
                rateCard &&
                rateCard.ratecardId === punchoutArrayElement.rateCardId &&
                search &&
                search.searchId === punchoutArrayElement.searchId &&
                buyRate &&
                buyRate.buyrateId === punchoutArrayElement.buyrateId &&
                level &&
                level.levelId === punchoutArrayElement.levelId
              ) {
                if (this.punchoutViewState.has(punchoutArrayElement.punchoutId)) {
                  let punchout = this.punchoutViewState.get(
                    punchoutArrayElement.punchoutId
                  );
                  punchout.selected = true;
                  punchout.alreadyAdded = true;
                }
                break;
              }
            }
          }
        }

        return rCSearchDetail;
      });
    });
  }

  async deleteSingleTags(content) {
    if (this.network.loading) {
      return;
    }

    // let params = [];
    // let args = [];
    // let vars = {};

    //   params.push("$tagIds: [Int]");
    //   args.push("tagIds: $tagIds");
    //   vars.tagIds.push(tag_id);

    const query = `
    mutation removeTagsFromContent{
      removeTagsFromContent(input: {tagIds : [${content.tagId}],contentType :PROJECT_STATS,contentId:${content.contentId}}){
        ok
        }
      }
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, null);
    } catch (e) {
      this.network.handleError("Deleting selected Tag", e);
      // TODO: Display user friendly error message
      return;
    }

    //this.handleStopEdit();
    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    runInAction("deleteSingleTags--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("deleteTag", res)) {
        // TODO: Display user friendly error message
        return;
      }

      //this.pagination.goFetch(null);
    });
    return {
      result: res.data.removeTagsFromContent.ok,
    };
  }

  async renameProject() {
    if (this.network.loading) {
      return;
    }

    this.messaging.removeAll();

    if (!this.newPCEName.trim() || this.newPCEName === this.getFirstSelectedPCE().name) {
      this.messaging.createMessage("info", "Please enter a New Project Name.");
      return;
    }

    const query = `
      mutation renameProject ($projectId: Int!, $name: String!){
       renameProject(input: {projectId: $projectId, name: $name}) {
         ok
         errors{
          __typename
          ...on ProjectDoesNotExistsError{
            message
          }
          ...on NoPermissionError{
            message
          }
          ...on NameAlreadyExistError{
            message
          }
         }
       }
      }
      `;
    const variables = {
      projectId: this.getSelectedProjects()[0],
      name: this.newPCEName,
    };

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Updating Name", e);
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    runInAction("renameProject--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Filter criteria query", res)) {
        return;
      }
      if (res.data.renameProject.errors) {
        this.messaging.removeAll();
        this.messaging.createMessage("error", res.data.renameProject.errors[0].message);
        return;
      }

      this.handleStopEdit();
      this.pagination.goFetch(null);
      this.renameModal.hideModal();
    });
  }
}
