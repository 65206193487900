import React, { useRef, useMemo, useCallback, useState } from "react";
import { List } from "immutable";

import { styled, CSS } from "../../../stitches.config";
import { TR as TRBase, TH as THBase } from "../../lib/Table";
import Icon from "../../lib/Icon";
import Text from "../../lib/Text";
import FiltersPopup from "./FiltersPopup";
import { OrderDirections, emptyList, emptyOrderedMap } from "../constants";
import Inline from "../../lib/Inline";
import { Popover, PopoverContent, PopoverAnchor, PopoverPortal } from "../../lib/Popover";
import {
  ComparatorsConfig,
  DataProvider,
  FilterConfigMap,
  FilterOptionsList,
  FilterPopupSizesType,
  FiltersConfigOrderedMap,
  FiltersDataProviderRestful,
  FiltersQueryOrderedMap,
  FilterTypesType,
  OrderDirectionsType,
  OrderIconsObject,
  RowData,
  RowIdGetterFunction,
  ValueGetterFunc,
} from "../types";
import { Initializable, OnApplyFilterFunction } from "./types";

export const TR = styled(TRBase, {
  whiteSpace: "nowrap",
});

const activeHeadCSS = {
  cursor: "pointer",
  userSelect: "none",
  transition: "color 0.3s ease",

  "&:hover, &:active, &:focus": {
    color: "$primaryDark",
  },
};

export const TH = styled(THBase, {
  padding: "$2_5 $5",
  backgroundColor: "$primaryLightest",
  borderRight: "1px solid $primaryLight",
  borderBottom: "1px solid $primaryLight",
  textAlign: "center",
  verticalAlign: "middle",
  whiteSpace: "nowrap",
  color: "$primary",

  "&:last-child": {
    borderRight: "0",
  },

  variants: {
    sortable: {
      true: activeHeadCSS,
    },
    filterable: {
      true: activeHeadCSS,
    },
  },

  defaultVariants: {
    sortable: false,
    filterable: false,
  },
});

const HeadTitle = styled(Text, {
  display: "inline-block",
  whiteSpace: "nowrap",
});
HeadTitle.displayName = "HeadTitle";

const IconsLine = styled(Text, {
  display: "inline-block",
  position: "relative",
  minWidth: "$2_5",
  fontSize: "$sm",
});
IconsLine.displayName = "IconsLine";

const FilterOrder = styled(Text, {
  fontSize: "$2xs",
});
FilterOrder.displayName = "FilterOrder";

const filterIcon = <Icon icon="filter" css={{ fontSize: "$xs" }} />;
const orderIcons = {
  [OrderDirections.NO]: <Icon icon="sort" css={{ fontSize: "$xs" }} />,
  [OrderDirections.ASC]: <Icon icon="sort-up" css={{ fontSize: "$xs" }} />,
  [OrderDirections.DSC]: <Icon icon="sort-down" css={{ fontSize: "$xs" }} />,
};

type TableHeadRowImplProps = React.ComponentPropsWithoutRef<typeof TR>;

export const TableHeadRowImpl = (props: TableHeadRowImplProps) => <TR {...props} />;
TableHeadRowImpl.displayName = "TableHeadRowImpl";

type TableHeadCellImplProps = React.ComponentPropsWithoutRef<typeof TH>;

export const TableHeadCellImpl = (props: TableHeadCellImplProps) => <TH {...props} />;
TableHeadCellImpl.displayName = "TableHeadCellImpl";

interface TableHeadCellImplSortableProps extends TableHeadCellImplProps {
  sortable: boolean;
  orderDirection: OrderDirectionsType;
  orderIcons: OrderIconsObject;
}

export function TableHeadCellImplSortable(props: TableHeadCellImplSortableProps) {
  const { sortable, orderDirection, orderIcons, children, ...rest } = props;

  return (
    <TH sortable={sortable} {...rest}>
      <Inline fill nowrap css={{ gap: "$0_5", justifyContent: "center" }}>
        <HeadTitle>{children}</HeadTitle>
        {sortable && (
          <IconsLine>
            {sortable && (orderIcons[orderDirection] || orderIcons[OrderDirections.NO])}
          </IconsLine>
        )}
      </Inline>
    </TH>
  );
}

TableHeadCellImplSortable.displayName = "TableHeadCellImplSortable";
TableHeadCellImplSortable.defaultProps = {
  sortable: false,
  orderIcons: orderIcons,
};

interface TableHeadCellImplFilterableProps<RD = RowData> extends TableHeadCellImplProps {
  uniqueKey: string;
  idGetter: RowIdGetterFunction<RD>;
  getter: ValueGetterFunc<RD>;
  comparators?: ComparatorsConfig;
  dataProvider: DataProvider<RD> | FiltersDataProviderRestful;
  dataProviderFilters: FiltersConfigOrderedMap | FiltersQueryOrderedMap;
  // features switches
  filterable?: boolean;
  sortable?: boolean;
  restful?: boolean;
  // filter props
  filter: FilterConfigMap;
  filterType?: FilterTypesType;
  filterIdx?: number | null;
  filterKey?: string;
  filterOptions?: FilterOptionsList;
  filterExtraProps?: Object;
  onApplyFilter: OnApplyFilterFunction;
  // icons impl
  orderIcons: OrderIconsObject;
  filterIcon: React.ReactNode;
  featuresIcon: React.ReactNode;
  //
  popupSize?: FilterPopupSizesType;
  css?: CSS;
}

export function TableHeadCellImplFilterable<RD = RowData>(
  props: TableHeadCellImplFilterableProps<RD>
) {
  const {
    uniqueKey,
    getter,
    idGetter,
    comparators,
    dataProvider,
    dataProviderFilters,
    restful,
    filterable,
    sortable,
    filter,
    filterType,
    filterKey,
    filterIdx,
    filterOptions,
    filterExtraProps,
    onApplyFilter,
    popupSize,
    orderIcons,
    filterIcon,
    featuresIcon,
    children,
    ...rest
  } = props;

  // state

  const [openState, setOpenState] = useState(false);

  // refs

  const popupRef = useRef<Initializable>(null);

  // handlers

  const handlePopupShow = useCallback(() => {
    // popupRef.current?.initialize?.();
    setOpenState(true);
  }, []);

  const handlePopupClose = useCallback(() => {
    setOpenState(false);
  }, []);

  const handlePopupAutoFocus = useCallback(() => {
    popupRef.current?.initialize?.();
  }, []);

  const handleApplyFilter: OnApplyFilterFunction = useCallback(
    (...args) => {
      handlePopupClose();
      onApplyFilter(...args);
    },
    [handlePopupClose, onApplyFilter]
  );

  // utils

  const icons = useMemo(() => {
    if (filter.size) {
      const orderDirection = (filter.getIn(["order", "direction"]) ||
        OrderDirections.NO) as OrderDirectionsType;
      const filterValues = (filter.getIn(["filter", "values"]) || emptyList) as List<any>;

      if (orderDirection === OrderDirections.NO && filterValues.size === 0) {
        return featuresIcon;
      } else {
        return (
          <Inline fill nowrap nogap>
            {orderDirection !== OrderDirections.NO && orderIcons[orderDirection]}
            {filterValues.size > 0 && filterIcon}
            {filterValues.size > 0 && filterIdx != null && (
              <FilterOrder>{filterIdx + 1}</FilterOrder>
            )}
          </Inline>
        );
      }
    }
  }, [filter, orderIcons, filterIcon, filterIdx, featuresIcon]);

  return (
    <TH
      filterable={filterable}
      sortable={sortable}
      title="click to configure filters"
      onClick={filterable || sortable ? handlePopupShow : undefined}
      {...rest}
    >
      <Popover open={openState}>
        <PopoverAnchor>
          <Inline fill nowrap css={{ gap: "$0_5", justifyContent: "center" }}>
            <HeadTitle>{children}</HeadTitle>
            {(filterable || sortable) && <IconsLine>{icons}</IconsLine>}
          </Inline>
        </PopoverAnchor>
        <PopoverPortal>
          <PopoverContent
            onEscapeKeyDown={handlePopupClose}
            onInteractOutside={handlePopupClose}
            onOpenAutoFocus={handlePopupAutoFocus}
            avoidCollisions={true}
          >
            {(filterable || sortable) && (
              <FiltersPopup
                ref={popupRef}
                uniqueKey={uniqueKey}
                getter={getter}
                idGetter={idGetter}
                comparators={comparators}
                dataProvider={dataProvider}
                dataProviderFilters={dataProviderFilters}
                filterType={filterType}
                filterKey={filterKey}
                filterOptions={filterOptions}
                filterExtraProps={filterExtraProps}
                filter={filter}
                filterable={filterable}
                sortable={sortable}
                restful={restful}
                onApply={handleApplyFilter}
                onCancel={handlePopupClose}
                isVisible={openState}
                popupSize={popupSize}
              />
            )}
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    </TH>
  );
}

TableHeadCellImplFilterable.displayName = "TableHeadCellImplFilterable";
TableHeadCellImplFilterable.defaultProps = {
  dataProviderFilters: emptyOrderedMap,
  filterable: false,
  sortable: false,
  restful: false,
  orderIcons: orderIcons,
  filterIcon: filterIcon,
  featuresIcon: orderIcons[OrderDirections.NO],
};
