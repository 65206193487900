import React, { useState } from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
// relay
import { JobTitleSearchResults_savesearch$key } from "./__generated__/JobTitleSearchResults_savesearch.graphql";
// lib
import Inline from "../../../components/lib/Inline";
import Text from "../../../components/lib/Text";
import Tag from "../../../components/lib/Tag";
import { styled } from "../../../stitches.config";
import RsJobDescriptionDialog from "../components/RsJobDescriptionDialog";
import { SimpleLink } from "../../../components/lib/Link";
import { JobTittleSkills } from "../JobTittleSkills";
import RsSearchBySkillsDialog from "../components/RsSearchBySkillsDialog";
import { Placeholder } from "../../../components/lib/Placeholder";
import { useGlobalContext } from "../../../globalContext";
import { NotFound } from "../../../utils/errorTypes";

function getAllCommaSkills(skills: string[]) {
  if (skills && skills.length > 0) {
    return skills.join(", ");
  } else {
    return "";
  }
}

const Section = styled("section", {
  display: "flex",
  flex: 3,
  flexDirection: "column",
  gap: "$1",
});

const fragment = graphql`
  fragment JobTitleSearchResults_savesearch on Viewer
  @argumentDefinitions(searchId: { type: "Int!" }) {
    savedsearch(id: $searchId) {
      id
      searchId
      isGlobalSupplierSearch
      job {
        id
        jobTitleId
        jobTitle
        jobLabel
        collectionId
      }
      jobDescription
      locationFullLabel
      industry {
        id
        legacyId
        value
      }
      region {
        regionId @required(action: NONE)
        name @required(action: NONE)
      }
      rateType
      workerTypeId
      workerTypeName
      rateCard {
        legacyId
        name
      }
    }
  }
`;

type Props = {
  savesearch: JobTitleSearchResults_savesearch$key;
};

export function JobTitle(props: Props) {
  const { savesearch } = props;

  const data = useFragment(fragment, savesearch);
  const [skills, setSkills] = useState<string[]>([]);

  const { clientId, perSearchPricing, isClientJobLibrary } = useGlobalContext();

  const search = data?.savedsearch;
  const job = search?.job;
  const jobDescription = search?.jobDescription?.trim() || "";
  const rateType = search?.rateType === 1 ? "Contract" : "FTE";

  if (!search) {
    // will be catch by NotFoundBoundary component
    throw new NotFound("Record not found");
  }

  return (
    <Section id="rate-search-result-job-title">
      <Inline>
        <Text as="h3" noMargin color="dark">
          {job?.jobLabel}
        </Text>
        <Tag variant="outlined" size="extra-small" text={rateType} />
      </Inline>
      {perSearchPricing && (
        <Text noMargin color="primary" as="p" data-testid="ratesearch-result-jobtitle">
          {job?.jobTitle}
        </Text>
      )}
      <Text>
        {search.region?.name || search?.locationFullLabel} - {search?.industry?.value}
      </Text>

      {search?.isGlobalSupplierSearch && (
        <Text as="b" color="positive">
          Global Supplier Search
        </Text>
      )}
      {search?.workerTypeName && <Text>Worker Type: {search.workerTypeName}</Text>}

      {jobDescription && (
        <RsJobDescriptionDialog
          jobLabel={job?.jobLabel || job?.jobTitle!}
          jobDescription={jobDescription}
        >
          <SimpleLink>View Job Description</SimpleLink>
        </RsJobDescriptionDialog>
      )}

      {isClientJobLibrary && jobDescription && (
        <>
          <JobTittleSkills
            jobDescription={jobDescription}
            handleGetSkills={setSkills}
            showCommaSkills={true}
            showSkillLabel={false}
          />
          {skills && skills.length > 0 && (
            <RsSearchBySkillsDialog
              preJobDescription={jobDescription}
              preJobTitle={job?.jobTitle!}
              preSkills={getAllCommaSkills(skills)}
              clientId={clientId}
              perSearchPricing={perSearchPricing}
            >
              <SimpleLink>View job titles with similar skillset</SimpleLink>
            </RsSearchBySkillsDialog>
          )}
        </>
      )}
    </Section>
  );
}

export function JobTitleLoader() {
  return (
    <Section css={{ gap: "$3" }}>
      <Placeholder bgColor="$primaryLight" width="55%" height={40} />
      <Placeholder bgColor="$primaryLight" width="40%" height={14} />
      <Placeholder bgColor="$primaryLight" width="90%" height={14} />
      <Placeholder bgColor="$primaryLight" width="90%" height={14} />
      <Placeholder bgColor="$primaryLight" width="75%" height={14} />
    </Section>
  );
}
