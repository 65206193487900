// @flow

import { extendObservable } from "mobx";
import PunchOutRow from "./PunchOutRow";
import moment from "moment";
import type Search from "./Search";
import NetworkState from "./NetworkState";

export type PunchOutRowByLevel = Array<?PunchOutRow>;
class User {
  id: string;
  userId: string;
  username: string;
  firstName: string;
  lastName: string;

  constructor(object) {
    this.id = object.id;
    this.userId = object.userId;
    this.username = object.username;
    this.firstName = object.firstName;
    this.lastName = object.lastName;
  }
}

export default class BuyRate {
  id: number;
  createdDate: Object;
  name: ?string;
  jobLabel: ?string;
  jobTitle: ?string;
  createdBy: ?User;
  punchOutRows: Array<?PunchOutRow>;
  search: ?Search;
  viewState: { selected: boolean, editing: boolean, expanded: boolean };
  network: NetworkState;

  constructor(object: Object) {
    this.network = new NetworkState();
    this.id = object.buyrateId;
    this.createdDate = moment(object.created);
    this.search = null;
    this.createdBy = null;

    if (object.createdBuy) {
      this.createdBy = new User(object.createdBuy);
    }

    extendObservable(this, {
      name: object ? object.name : null,
      jobLabel: object ? object.jobLabel : null,
      jobTitle: object ? object.jobTitle : null,
      punchOutRows: [null, null, null, null, null],
    });

    if (object.punchouts) this.mapPunchOutRows(object.punchouts || []);
  }

  assignPunchOutRow = (data: Object) => {
    const punchOutRow = this.punchOutRows[data.levelId];
    if (!punchOutRow) return;

    Object.assign(punchOutRow, data);
  };

  mapPunchOutRows = (dataItems: Object[]) => {
    dataItems.forEach((item: Object) => {
      const punchOutRow = this.punchOutRows[item.levelId - 1];
      if (punchOutRow) {
        Object.assign(punchOutRow, item);
      } else {
        this.punchOutRows[item.levelId - 1] = new PunchOutRow(item);
      }
    });
  };

  toggleEdit = () => {
    this.viewState.editing = !this.viewState.editing;
  };
}
