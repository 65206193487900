import { RestfulTableDataStateObject } from "../components/tables/types";
import type { DjangoPaginatedResponse } from "../types/django";

interface ConvertedDjangoResponse<DT>
  extends Partial<Omit<RestfulTableDataStateObject, "data" | "itemsCount">> {
  data: DT;
  itemsCount: number;
}

export type BaseDjangoResponseConverter<InT, OutT> = (
  converter: (list: InT[]) => OutT,
  emptyDataImpl: OutT,
  data: DjangoPaginatedResponse<InT> | Array<InT>,
  extraStoredParameters?: Object
) => ConvertedDjangoResponse<OutT>;

export type SpecificDjangoResponseConverter<InT, OutT> = (
  data: Parameters<BaseDjangoResponseConverter<InT, OutT>>[2],
  extraStoredParameters?: Parameters<BaseDjangoResponseConverter<InT, OutT>>[3]
) => ConvertedDjangoResponse<OutT>;

export function baseDjangoResponseConverter<InT, OutT>(
  converter: (list: InT[]) => OutT,
  emptyDataImpl: OutT,
  data: DjangoPaginatedResponse<InT> | Array<InT>,
  extraStoredParameters?: Object
): ConvertedDjangoResponse<OutT> {
  const paginatedData = data as DjangoPaginatedResponse<InT>;

  if (paginatedData && Array.isArray(paginatedData.results)) {
    // paginated response
    return Object.assign({}, extraStoredParameters, {
      data:
        paginatedData.results.length > 0
          ? converter(paginatedData.results)
          : emptyDataImpl,
      itemsCount: paginatedData.count ?? 0,
    });
  }

  const notPaginatedData = data as Array<InT>;

  if (Array.isArray(notPaginatedData) && notPaginatedData.length) {
    // not paginated response
    return Object.assign({}, extraStoredParameters, {
      data: notPaginatedData.length > 0 ? converter(notPaginatedData) : emptyDataImpl,
      itemsCount: notPaginatedData.length || 0,
    });
  }

  return Object.assign({}, extraStoredParameters, {
    data: emptyDataImpl,
    itemsCount: 0,
  });
}

interface ValidationErrorObject {
  row: number;
  col: number;
  error: string;
}

interface DjangoValidationErrorObject {
  response?: {
    status: number;
    json(): Promise<string | ValidationErrorObject[]>;
  };
}

export const extractDjangoHTTP400Messages = async (err: DjangoValidationErrorObject) => {
  let messages: string[] = [];

  if (err && err?.response?.status === 400) {
    let errors: string | string[] | ValidationErrorObject[] = await err.response.json();

    if (typeof errors === "string") {
      errors = JSON.parse(errors);
    }
    if (!Array.isArray(errors)) {
      errors = [errors] as string[] | ValidationErrorObject[];
    }

    errors.forEach((item) => {
      if (typeof item === "string") {
        messages.push(item);
      } else if (typeof item === "object") {
        const { row, col, error } = item;

        if (error) {
          let message = `Row: ${row}`;

          if (col) {
            message += `, Col: ${col}`;
          }

          messages.push(message + ` - ${error}`);
        }
      }

      return null;
    });
  }

  return messages;
};
