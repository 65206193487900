// @flow
import React from "react";
import classNames from "classnames";
import Messager from "./Messager";

/*
 * Hint: OverlayedMessager component is positioned absolutely.
 * Hint: Parent component of OverlayedMessager should have "position: relative" positioning.
 */
export default class OverlayMessager extends Messager {
  static displayName = "OverlayMessager";

  constructor(props: Object) {
    super(props);

    this.state = Object.assign(this.state || {}, {
      isOverlayVisible: false,
    });
  }

  _showMessage() {
    const _arguments = arguments;
    const _super = super._showMessage;

    return new Promise((resolve, reject) => {
      this.setState({ isOverlayVisible: true }, () => {
        // show overlay
        _super.apply(this, _arguments).then(() => {
          // show message
          this.setState({ isOverlayVisible: false }, resolve); // hide overlay
        }, reject);
      });
    });
  }

  _hideMessage() {
    const _super = super._hideMessage;

    return new Promise((resolve, reject) => {
      _super
        .call(this)
        .then(() => {
          // hide message
          this.setState({ isOverlayVisible: false }, resolve); // hide overlay
        })
        .catch(reject);
    });
  }

  render() {
    const { className, style } = this.props;
    const { isOverlayVisible } = this.state;
    const containerClassNames = classNames(className, {
      "pt-overlay-messager": true,
      "pt-overlay-messager--visible": isOverlayVisible,
    });

    return (
      <div className={containerClassNames} style={style}>
        {super.render()}
      </div>
    );
  }
}

export class OverlayMessagerStateless extends Messager {
  static displayName = "OverlayMessagerStateless";

  render() {
    const { visible, className, style, children } = this.props;
    const containerClassNames = classNames(className, {
      "pt-overlay-messager": true,
      "pt-overlay-messager--visible": visible,
    });

    return (
      <div
        className={containerClassNames}
        style={style}
        children={visible ? children : undefined}
      />
    );
  }
}
