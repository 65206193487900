import { RATE_TYPES, RATE_TYPES_LABELS } from "./types";

import type { JobDataMap, CurrencyDataMap, RATE_TYPES_TYPE } from "./types";

type RateTypeParametersType = {
  rateType: RATE_TYPES_TYPE;
  rateMultiplier: number;
};

export function getJobRateTypeParameters(
  jobData: JobDataMap,
  rateType: RATE_TYPES_TYPE | null = null
): RateTypeParametersType {
  let rateMultiplier = 1;

  if (rateType == null) {
    rateType = jobData.get("rate_type", RATE_TYPES.HOURLY);
  }

  if (rateType === RATE_TYPES.HOURLY) {
    rateMultiplier = jobData.get("hourly_multiplier");
  } else if (rateType === RATE_TYPES.DAILY) {
    rateMultiplier = jobData.get("daily_multiplier");
  } else if (rateType === RATE_TYPES.WEEKLY) {
    rateMultiplier = jobData.get("weekly_multiplier");
  } else if (rateType === RATE_TYPES.MONTHLY) {
    rateMultiplier = jobData.get("monthly_multiplier");
  }

  return { rateType, rateMultiplier };
}

export function getRateTypeLabel(
  firstJobRateType: RATE_TYPES_TYPE,
  displayRateType: RATE_TYPES_TYPE,
  firstJobRateMultiplier: number | null
): string {
  const displayRateTypeLabel =
    displayRateType != null && displayRateType !== firstJobRateType
      ? `(displayed as ${RATE_TYPES_LABELS[displayRateType]})`
      : `(${firstJobRateMultiplier}h)`;

  if (firstJobRateType !== RATE_TYPES.ANNUAL) {
    return `${RATE_TYPES_LABELS[firstJobRateType]} ${displayRateTypeLabel}`;
  }
  return RATE_TYPES_LABELS[firstJobRateType];
}

export function getDisplayCurrencyChanged(
  displayCurrencyCode: string,
  reviewCurrencyCode: string
): boolean {
  return !!(
    displayCurrencyCode &&
    reviewCurrencyCode &&
    displayCurrencyCode !== reviewCurrencyCode
  );
}

export function needRateTypeConversionPredicate(
  rateType: RATE_TYPES_TYPE,
  rateMultiplier: number
): boolean {
  return (
    !!rateMultiplier &&
    rateMultiplier !== 1 &&
    (rateType === RATE_TYPES.DAILY ||
      rateType === RATE_TYPES.WEEKLY ||
      rateType === RATE_TYPES.MONTHLY)
  );
}

export function needCurrencyConversionPredicate(
  currencyData: CurrencyDataMap,
  displayCurrencyData: CurrencyDataMap
): boolean {
  return !!(
    currencyData?.get("code") &&
    displayCurrencyData?.get("code") &&
    currencyData?.get("code") !== displayCurrencyData?.get("code")
  );
}
