import React, { useMemo } from "react";
import Stack from "../../../../components/lib/Stack";
import Text from "../../../../components/lib/Text";
import Button from "../../../../components/lib/Button";
import Highchart from "../../../../components/charts/Highchart";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { BellCurveBillRates_savedsearch$key } from "./__generated__/BellCurveBillRates_savedsearch.graphql";
import { getBellCurveConfig } from "./bellcurve";
import { useSearchResultsStore } from "../../../../stores/SearchResults";
import Inline from "../../../../components/lib/Inline";

const fragment = graphql`
  fragment BellCurveBillRates_savedsearch on SaveSearchNode {
    id
    currency {
      id
      iso @required(action: NONE)
    }
    rates {
      rawBillRates @required(action: NONE)
      perDiem @required(action: NONE)
      rawMin @required(action: NONE)
      rawAvg @required(action: NONE)
      rawMid @required(action: NONE)
      rawMax @required(action: NONE)
    }
  }
`;

type Props = {
  isContract: boolean;
  savedsearch: BellCurveBillRates_savedsearch$key;
};

export function BellCurveBillRates({ savedsearch, isContract }: Props) {
  const data = useFragment(fragment, savedsearch);
  const rateFrequency = useSearchResultsStore((s) => s.rateFrequency);
  const multiplier = useSearchResultsStore(
    (s) => s.rateFrequencyMultipliers[s.rateFrequency]
  );
  const setRateFrequency = useSearchResultsStore((s) => s.setRateFrequency);
  const fromCurrency = data?.currency?.iso || "USD";
  const toCurrency = useSearchResultsStore((s) => s.currency);
  const calculateRate = useSearchResultsStore((s) => s.calculateRate);

  const formatRate = calculateRate(
    fromCurrency,
    toCurrency,
    isContract ? rateFrequency : "Annually",
    true
  );

  const perDiem = data?.rates?.perDiem || null;

  const options = useMemo(() => {
    if (!data?.rates || multiplier <= 0) return {};

    return getBellCurveConfig(data?.rates.rawBillRates as number[], (rate?: number) => {
      return calculateRate(
        fromCurrency,
        toCurrency,
        isContract ? rateFrequency : "Annually",
        false
      )(rate, true, isContract);
    });
  }, [
    data,
    multiplier,
    calculateRate,
    fromCurrency,
    toCurrency,
    rateFrequency,
    isContract,
  ]);

  if (!data || !data.rates) {
    console.error("Healthcare Bell Cuve: Missing data");
    return null;
  }

  return (
    <Stack fill nogap css={{ alignItems: "flex-start", position: "relative" }}>
      <Stack
        css={{
          alignItems: "flex-start",
          justifyContent: "flex-start",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 2,
        }}
      >
        <Stack nogap>
          <Text noMargin as="h4">
            Market Rates
          </Text>
          <Text color="primary">Average Bill Rate</Text>
        </Stack>

        <Stack nogap css={{ alignItems: "flex-start", paddingBottom: "$2" }}>
          <Text noMargin as="h1">
            {formatRate(data.rates.rawAvg, true, isContract)}
          </Text>
          {perDiem && (
            <Text color="positive">
              <Text css={{ fontWeight: "$semibold" }}>
                {formatRate(perDiem, true, false)}
              </Text>
              &nbsp;Daily allowance, <br />
              Includes food and lodging.
            </Text>
          )}
        </Stack>

        {isContract && (
          <Inline>
            <Button
              onClick={() => setRateFrequency("Hourly")}
              color={rateFrequency === "Hourly" ? "brand" : "primary"}
              variant={rateFrequency === "Hourly" ? "filled" : "outlined"}
            >
              Hourly
            </Button>
            <Button
              color={rateFrequency === "Annually" ? "brand" : "primary"}
              variant={rateFrequency === "Annually" ? "filled" : "outlined"}
              onClick={() => setRateFrequency("Annually")}
            >
              Annually
            </Button>
          </Inline>
        )}
      </Stack>
      <Stack
        css={{
          gap: "0",
          justifyContent: "space-between",
          width: "$full",
          "& div": { width: "$full" },
        }}
      >
        <Highchart options={options} />
      </Stack>

      <Inline
        css={{
          width: "$full",
          justifyContent: "space-evenly",
          fontSize: "$base",
        }}
      >
        <Text css={{ paddingLeft: "$8 !important" }}>Minimun</Text>
        <Text>Average</Text>
        <Text css={{ paddingRight: "$8 !important" }}>Maximum</Text>
      </Inline>
    </Stack>
  );
}
