import { useRefetchableFragment } from "react-relay";
import React, { useState } from "react";
import graphql from "babel-plugin-relay/macro";
// lib
import Button from "../../../../components/lib/Button";
import LinkButton from "../../../../components/lib/LinkButton";
import Stack from "../../../../components/lib/Stack";
// relay
import { ResultOptions_viewer$key } from "./__generated__/ResultOptions_viewer.graphql";
import { ResultOptionsResultsRefetch } from "./__generated__/ResultOptionsResultsRefetch.graphql";
// hooks
import { useSearchId } from "../../hooks/useSearchId";
// components
import RsAddToRateCardDialog from "./RsAddToRateCardDialog";
import { useGlobalContext } from "../../../../globalContext";
import { useSearchResultsStore } from "../../../../stores/SearchResults";

const buttonCss = { height: "40px", width: "100%", maxWidth: 240 };
const stackCss = {
  alignItems: "flex-end",
  flex: 1,
  gap: "$4",
  justifyContent: "center",
};

const fragment = graphql`
  fragment ResultOptions_viewer on Viewer
  @refetchable(queryName: "ResultOptionsResultsRefetch")
  @argumentDefinitions(searchId: { type: "Int!" }) {
    savedsearch(id: $searchId) {
      id
      rates {
        rawBillRates
      }
      rateCard {
        id
        legacyId
        name
      }
    }
  }
`;

type Props = {
  viewer: ResultOptions_viewer$key | null;
};

export function ResultOptions({ viewer }: Props) {
  const { searchId } = useSearchId();
  const { userId } = useGlobalContext();
  const [loading, setLoading] = useState(false);

  const params = useSearchResultsStore((s) => s.getURLSearchParams().toString());

  const [data, refetch] = useRefetchableFragment<
    ResultOptionsResultsRefetch,
    ResultOptions_viewer$key
  >(fragment, viewer);

  const ratecard = data?.savedsearch?.rateCard;

  const billRates = data?.savedsearch?.rates?.rawBillRates;
  const showQuantiles = billRates && Array.isArray(billRates) && billRates.length > 0;

  function onAddedToRatecard() {
    refetch(
      { searchId },
      {
        fetchPolicy: "network-only",
        onComplete() {
          setLoading(false);
        },
      }
    );
  }

  return (
    <Stack fill css={stackCss}>
      {ratecard ? (
        <LinkButton
          variant="filled"
          color="brand"
          css={buttonCss}
          to={`/ratecards/${ratecard.legacyId}`}
        >
          Rate Card: {ratecard.name}
        </LinkButton>
      ) : (
        <RsAddToRateCardDialog
          searchIds={[searchId]}
          onSaveChanges={() => setLoading(true)}
          onRateCardAdded={onAddedToRatecard}
          currentUserId={userId}
        >
          <Button
            loading={loading}
            loadingText="Loading..."
            disabled={loading}
            variant="filled"
            color="brand"
            css={buttonCss}
          >
            Add to Rate Card
          </Button>
        </RsAddToRateCardDialog>
      )}
      <LinkButton
        target="_blank"
        to={`/api/search-export/${searchId}${showQuantiles ? "?showQuantiles=true" : ""}`}
        css={buttonCss}
      >
        Export to .XLSX
      </LinkButton>
      <LinkButton
        target="_blank"
        /* @ts-ignore */
        download={`search-results-${searchId}.pdf`}
        to={`/api/pdf-export/rate-search/${searchId}?${params}`}
        css={buttonCss}
      >
        Download PDF
      </LinkButton>
    </Stack>
  );
}

export function ResultOptionsLoader() {
  const { searchId } = useSearchId();
  return (
    <Stack fill css={stackCss}>
      <Button
        loading
        loadingText="Loading..."
        disabled
        variant="filled"
        color="brand"
        css={buttonCss}
      />
      <LinkButton target="_blank" to={`/api/search-export/${searchId}`} css={buttonCss}>
        Export to .XLSX
      </LinkButton>
    </Stack>
  );
}
