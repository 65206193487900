// @flow
export type MessageType = "success" | "info" | "warning" | "danger" | "important";

export default class Message {
  type: MessageType;
  message: string;
  messageLabel: ?string;

  constructor(type: MessageType, message: string) {
    this.type = type;
    this.message = message;
    switch (this.type) {
      case "success":
        this.messageLabel = "Success! ";
        break;
      case "info":
        this.messageLabel = "Info! ";
        break;
      case "warning":
        this.messageLabel = "Warning! ";
        break;
      case "danger":
        this.messageLabel = "Important! ";
        break;
      case "important": // Ref T4729
        // Change back to danger so styles work if dependant on type
        this.type = "danger";
        this.messageLabel = "Important! ";
        break;
      default:
        break;
    }
  }
}
