import React, { useCallback } from "react";
import { useRecoilState } from "recoil";

import FileInput from "../../../components/lib/FileInput";
// @ts-expect-error
import IndustrySelect from "../../../components/selects/IndustrySelect";
// @ts-expect-error
import type MobXStore from "../../../stores/mobx/MobXStore";
import { usePLIContext } from "../context";
import TextInput from "../../../components/lib/TextInput";
import { ButtonGroupRight } from "../../../components/lib/ButtonGroup";
import { Button } from "../../../components/lib/Button";
import TextArea from "../../../components/lib/TextArea";
import {
  Checkbox,
  CheckboxIndicator,
  LabelInline,
} from "../../../components/lib/Checkbox";
import { contractorsUploadFormGlobalState } from "../globalState";
import { extractDjangoHTTP400Messages } from "../../../utils/django";
import { contractorsUploadToImmutableMap } from "../dataConverters";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";
import {
  Card,
  CardHeader,
  CardBody,
  CardHeaderTitle,
  CardActions,
  CardActionsRight,
  CardActionsLeft,
} from "../../../components/lib/Card";
import Stack from "../../../components/lib/Stack";
import Inline from "../../../components/lib/Inline";
import Icon from "../../../components/lib/Icon";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogTitle,
  DialogClose,
  DialogDescription,
} from "../lib/Dialog";
import Tooltip from "../lib/Tooltip";
import { reactSelectStyles } from "../constants";

import type {
  ContractorsUploadDataMap,
  ContractorsUploadDataObject,
  Values,
} from "../types";
import type { FetchAPIResponse } from "../../../types/fetch";

export const FILE_UPLOADING_STATES = {
  INITIAL: 1,
  PROCESSING: 2,
  SUCCESS: 3,
  FAILURE: 4,
} as const;

export type FILE_UPLOADING_STATES_TYPE = Values<typeof FILE_UPLOADING_STATES>;

const ALLOWED_FILE_EXTENTIONS = {
  XLSX: "xlsx",
  CSV: "csv",
} as const;

type ALLOWED_FILE_EXTENTIONS_TYPE = Values<typeof ALLOWED_FILE_EXTENTIONS>;

const allowedExcelMimeTypes = [
  // these all are old excel formats not supported by the lib openpyxl
  // 'application/excel',
  // 'application/x-excel',
  // 'application/x-msexcel',
  // 'application/vnd.ms-excel',
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
const allowedCsvMimeTypes = ["text/csv"];
export const allowedMimeTypesMatcher: {
  [key in ALLOWED_FILE_EXTENTIONS_TYPE]: string[];
} = {
  // 'xls': allowedExcelMimeTypes,
  xlsx: allowedExcelMimeTypes,
  csv: allowedCsvMimeTypes,
};
const allAllowedMimeTypes = [...allowedExcelMimeTypes, ...allowedCsvMimeTypes];

interface UploadContractorsRequestUrlArgs {
  program_id?: number;
  file_name: string;
  title: string;
  industry_id: number;
  autofill_description: boolean;
}

interface UploadFormBlockProps {
  store: MobXStore;
  programId?: number;
  fileSelectTooltipText: React.ReactNode;
  onFileUploaded: (uploadData: ContractorsUploadDataMap) => void;
}

const UploadFormBlock = (props: UploadFormBlockProps) => {
  const { store, programId, fileSelectTooltipText, onFileUploaded } = props;
  const { fetchM8API, showModalError, showModalWarning, hideModal } = usePLIContext();

  // state

  const [uploadFormState, setUploadFormState] = useRecoilState(
    contractorsUploadFormGlobalState
  );
  const { title, industry, file, autofillDescription, uploadingState, detailsText } =
    uploadFormState;
  const industryId = industry?.id ?? null;

  const isProcessing = uploadingState === FILE_UPLOADING_STATES.PROCESSING;
  const disableInputs = isProcessing;
  const disableUploadButton = isProcessing || file == null;
  const hasFailures =
    uploadingState === FILE_UPLOADING_STATES.FAILURE && detailsText != null;

  // utils

  const validateFormState = useCallback(() => {
    if (title == null) {
      showModalWarning("Please enter a Title.");
      return false;
    }
    if (industryId == null) {
      showModalWarning("Please select an Industry.");
      return false;
    }
    if (file == null) {
      showModalWarning("File should be provided.");
      return false;
    }

    return true;
  }, [title, file, industryId, showModalWarning]);

  const uploadContractorsSpreadsheet = useCallback(
    async (
      fileStringB64: string,
      fileMimeType: string,
      queryArgs: UploadContractorsRequestUrlArgs
    ): Promise<ContractorsUploadDataMap> => {
      const response: FetchAPIResponse<ContractorsUploadDataObject> = await fetchM8API(
        `uploads/`,
        {
          method: "post",
          data: fileStringB64,
          params: queryArgs,
          headers: { "content-type": fileMimeType },
        }
      );
      return contractorsUploadToImmutableMap(response.data);
    },
    [fetchM8API]
  );

  // handlers

  const handleTitleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newTitle = e.target.value;
      setUploadFormState((prevState) => ({
        ...prevState,
        title: newTitle || null,
      }));
    },
    [setUploadFormState]
  );

  const handleFileReset = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // @ts-ignore
      event.target.value = null;
      setUploadFormState((prevState) => ({
        ...prevState,
        file: null,
        detailsText: null,
      }));
    },
    [setUploadFormState]
  );

  const handleFileChange = useCallback(
    (newFiles: FileList | null, event: React.ChangeEvent<HTMLInputElement>) => {
      if (!newFiles?.length) return handleFileReset(event);

      const newFile = newFiles[0];
      const newFileName = newFile.name;
      const newFileExt = newFileName
        ? (newFileName.split(".").slice(-1)[0] as ALLOWED_FILE_EXTENTIONS_TYPE)
        : null;
      let newFileMimeType = newFile.type;

      if (!newFileMimeType && newFileExt && allowedMimeTypesMatcher[newFileExt]) {
        newFileMimeType = allowedMimeTypesMatcher[newFileExt][0];
      }

      if (newFileMimeType && allAllowedMimeTypes.indexOf(newFileMimeType) < 0) {
        showModalError("Provided file type is not supported");
        return;
      } else if (
        !newFileMimeType &&
        newFileExt &&
        Object.keys(allowedMimeTypesMatcher).indexOf(newFileExt) < 0
      ) {
        showModalError("Provided file type is not supported");
        return;
      }

      setUploadFormState((prevState) => ({
        ...prevState,
        file: newFile,
      }));
    },
    [setUploadFormState, showModalError, handleFileReset]
  );

  const handleFileApiIsNotSupported = useCallback(() => {
    showModalWarning(
      <span>
        <strong>File API</strong> is not supported by your browser.
        <br />
        Use modern browser to be able to upload file via this form.
      </span>
    );
  }, [showModalWarning]);

  const handleIndustryChange = useCallback(
    (newIndustry: { id: number; name: string }) => {
      if (newIndustry.id !== industryId) {
        setUploadFormState((prevState) => ({
          ...prevState,
          industry: newIndustry,
        }));
      }
    },
    [industryId, setUploadFormState]
  );

  const handleAutofillDescriptionChange = useCallback(
    (value: boolean) => {
      setUploadFormState((prevState) => ({
        ...prevState,
        autofillDescription: value,
      }));
    },
    [setUploadFormState]
  );

  const handleFileReaderOnerror = useCallback(() => {
    setUploadFormState((prevState) => ({
      ...prevState,
      detailsText: "File has been changed. Please reattach the file one more time.",
    }));
  }, [setUploadFormState]);

  const handleFileReaderOnload = useCallback(
    async (fileName: string, fileMimeType: string, event: ProgressEvent<FileReader>) => {
      const fileStringB64: string | null = event.target?.result
        ? (event.target.result as string).split("base64,")[1]
        : null;

      if (fileStringB64 == null) {
        showModalError("Can't read selected file");
        return;
      }

      const queryArgs: UploadContractorsRequestUrlArgs = {
        file_name: fileName || "",
        title: (title || "").trim(),
        industry_id: industryId!,
        autofill_description: autofillDescription,
      };

      if (programId != null) {
        queryArgs["program_id"] = programId;
      }

      setUploadFormState((prevState) => ({
        ...prevState,
        uploadingState: FILE_UPLOADING_STATES.PROCESSING,
      }));

      try {
        const uploadData = await uploadContractorsSpreadsheet(
          fileStringB64!,
          fileMimeType,
          queryArgs
        );

        await hideModal();
        onFileUploaded(uploadData);

        setUploadFormState((prevState) => ({
          ...prevState,
          uploadingState: FILE_UPLOADING_STATES.SUCCESS,
          detailsText: null,
          file: null,
          title: null,
          industry: null,
          autofillDescription: false,
        }));
      } catch (err: any) {
        await hideModal();

        let messagesPromise: Promise<string[]> = Promise.resolve([]);
        if (err?.response?.status === 400) {
          messagesPromise = extractDjangoHTTP400Messages(err);
        } else if (err?.response?.status > 400) {
          messagesPromise = Promise.resolve(["Something went wrong."]);
          logAsyncOperationError("uploadSpreadsheet", err);
          showModalError("Error occurred while spreadsheet uploading.");
        }

        messagesPromise.then((messages) => {
          setUploadFormState((prevState) => ({
            ...prevState,
            uploadingState: FILE_UPLOADING_STATES.FAILURE,
            detailsText: messages.length
              ? `Please fix the following errors and reattach the file.\n${messages.join(
                  "\n"
                )}`
              : null,
          }));
        });
      }
    },
    [
      programId,
      title,
      industryId,
      autofillDescription,
      uploadContractorsSpreadsheet,
      onFileUploaded,
      setUploadFormState,
      showModalError,
      hideModal,
    ]
  );

  const handleFileUpload = useCallback(() => {
    if (!validateFormState()) return;

    if (!file) return;

    const reader = new FileReader();
    const fileName = file.name;
    const fileExt = fileName
      ? (fileName.split(".").slice(-1)[0] as ALLOWED_FILE_EXTENTIONS_TYPE)
      : null;
    let fileMimeType = file.type;

    if (!fileMimeType && fileExt && allowedMimeTypesMatcher[fileExt]) {
      fileMimeType = allowedMimeTypesMatcher[fileExt][0];
    }
    if (!fileMimeType) return;

    reader.onload = (e: ProgressEvent<FileReader>) =>
      handleFileReaderOnload(fileName, fileMimeType, e);
    reader.onerror = () => handleFileReaderOnerror();
    reader.readAsDataURL(file);
  }, [file, validateFormState, handleFileReaderOnload, handleFileReaderOnerror]);

  return (
    <Card fill>
      <CardHeader>
        <CardHeaderTitle as="h3">Upload Form</CardHeaderTitle>
      </CardHeader>

      <CardBody>
        <Stack
          css={{
            width: "100%",
            "@md": { width: "60%" },
            "@lg": { width: "50%" },
            alignItems: "stretch",
            gap: "$6",
          }}
        >
          <Stack fill nogap css={{ alignItems: "start" }}>
            <label>
              <span>*Title: </span>
              <Tooltip side="right" content="Enter a Unique Title for tracking purposes.">
                <span>
                  <Icon icon={["far", "question-circle"]} size="sm" />
                </span>
              </Tooltip>
            </label>
            <TextInput
              fill
              value={uploadFormState.title || ""}
              placeholder="Name upload..."
              onChange={handleTitleChange}
              disabled={disableInputs}
            />
          </Stack>

          <Stack fill nogap css={{ alignItems: "start" }}>
            <label>
              <span>*File: </span>
              <Dialog>
                <DialogTrigger asChild>
                  <Icon
                    size="sm"
                    icon={["far", "question-circle"]}
                    title={"click to see details"}
                    css={{ cursor: "pointer" }}
                  />
                </DialogTrigger>
                <DialogContent>
                  <DialogTitle asChild>
                    <Inline css={{ justifyContent: "space-between" }}>
                      <h4>All Features And Limitations</h4>
                      <DialogClose asChild>
                        <Icon icon="times" />
                      </DialogClose>
                    </Inline>
                  </DialogTitle>
                  <DialogDescription
                    as="div"
                    css={{ whiteSpace: "pre-wrap", color: "inherit" }}
                  >
                    {fileSelectTooltipText}
                  </DialogDescription>
                </DialogContent>
              </Dialog>
            </label>
            <FileInput
              color="brand"
              value={file}
              onChange={handleFileChange}
              onNotSupported={handleFileApiIsNotSupported}
            >
              Select File
            </FileInput>
          </Stack>

          <Stack fill nogap css={{ alignItems: "start" }}>
            <label>
              <span>*Industry: </span>
              <Tooltip
                side="right"
                content="Select an Industry for a specific industry for data results; or select All."
              >
                <span>
                  <Icon icon={["far", "question-circle"]} size="sm" />
                </span>
              </Tooltip>
            </label>
            <IndustrySelect
              className="full-width"
              store={store.industriesStore}
              value={industry}
              onChange={handleIndustryChange}
              styles={reactSelectStyles}
            />
          </Stack>

          <Stack fill nogap css={{ alignItems: "start" }}>
            <LabelInline>
              <Checkbox
                checked={autofillDescription}
                onCheckedChange={handleAutofillDescriptionChange}
              >
                <CheckboxIndicator>
                  <Icon icon="check" />
                </CheckboxIndicator>
              </Checkbox>
              autofill missing job description
            </LabelInline>
          </Stack>

          <Stack fill nogap css={{ alignItems: "start" }}>
            <label>
              <span>Upload Details: </span>
              <Tooltip
                side="right"
                content="(Read Only) Displays validation errors and description."
              >
                <span>
                  <Icon icon={["far", "question-circle"]} size="sm" />
                </span>
              </Tooltip>
            </label>
            <TextArea
              data-testid="upload-details-textbox"
              css={{
                minHeight: "150px",
                maxHeight: "300px",
                borderWeight: "1px",
                fontWeight: "$light",
              }}
              value={detailsText ?? ""}
              color={hasFailures ? "danger" : "primary"}
              readOnly
              fill
            />
          </Stack>
        </Stack>
      </CardBody>
      <CardActions>
        <CardActionsLeft />
        <CardActionsRight>
          <ButtonGroupRight>
            {isProcessing && <span>uploading...</span>}
            <Button
              css={{ width: "205px" }}
              icon="upload"
              color="brand"
              size="large"
              disabled={disableUploadButton}
              onClick={!disableUploadButton ? handleFileUpload : undefined}
            >
              Upload File
            </Button>
          </ButtonGroupRight>
        </CardActionsRight>
      </CardActions>
    </Card>
  );
};

export default UploadFormBlock;
