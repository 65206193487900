// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import Pagination from "../../../components/lib/Pagination";
import LoadingIndicator from "../../shared/LoadingIndicator";
import NotificationsListItem from "./NotificationsListItem";
import { NotificationsListComponentStore } from "../../../stores/mobx/NotificationsListStore";

class NotificationsList extends Component {
  constructor(props) {
    super(props);

    this.changeCurrent = this.changeCurrent.bind(this);
  }

  changeCurrent(value) {
    let store: NotificationsListComponentStore = this.props.store;
    store.pagination.handleCurrentPage(value);
  }

  componentDidMount() {
    this.props.store.pagination.goFetch();
  }

  componentWillUnmount() {
    this.props.store.clearFilters();
  }

  render() {
    const store: NotificationsListComponentStore = this.props.store;

    const network = store.network;
    const fillContainer = this.props.fillContainer;
    const fixedHeight = this.props.fixedHeight;

    let listBodyStyles = {};
    if (fixedHeight) {
      listBodyStyles.height = fixedHeight;
      listBodyStyles.overflowY = "auto";
    }

    const notifications = store.notificationListView;

    let tableContent = null;
    if (network.loading) {
      tableContent = <LoadingIndicator />;
    } else if (network.error) {
      // TODO: replace this with a more friendly error message
      tableContent = (
        <div className="pt-error-message">
          <h2>Error</h2>
          <pre>
            <code>{JSON.stringify(network.error, null, 2)}</code>
          </pre>
        </div>
      );
    } else if (notifications.length === 0) {
      tableContent = (
        <div className="ratecards-table-empty">
          <div>
            No notifications Found
            {notifications}
            <p>{/* Create a new Schedule using the button above or by searching.*/}</p>
          </div>
        </div>
      );
    } else {
      tableContent = notifications.map((notification) => (
        <NotificationsListItem
          key={notification.id}
          store={store}
          notification={notification}
        />
      ));
    }

    return (
      <div className="ratecards-list">
        <div
          className={classNames("ratecards-table", {
            "ratecards-table-fill-container": fillContainer,
          })}
        >
          <div style={listBodyStyles}>{tableContent}</div>
        </div>
        {store.pagination.pageCount > 0 ? (
          <Pagination
            options={{
              variant: "full",
              currentPage: store.pagination.currentPage,
              numPages: store.pagination.pageCount,
            }}
            onPageClick={(pageEvent) => {
              if (pageEvent.type === "first") {
                this.changeCurrent(1);
              }
              if (pageEvent.type === "last") {
                this.changeCurrent(store.pagination.pageCount);
              }
              if (pageEvent.type === "next") {
                this.changeCurrent(
                  Math.min(store.pagination.currentPage + 1, store.pagination.pageCount)
                );
              }
              if (pageEvent.type === "prev") {
                this.changeCurrent(Math.max(store.pagination.currentPage - 1, 1));
              }
              if (pageEvent.type === "page") {
                this.changeCurrent(pageEvent.page);
              }
            }}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

NotificationsList.propTypes = {
  store: PropTypes.object.isRequired,
  fixedHeight: PropTypes.number,
  fillContainer: PropTypes.bool,
};

export default observer(NotificationsList);
