import React, { useCallback } from "react";

import Stack from "../../../../components/lib/Stack";
import Button from "../../../../components/lib/Button";
import PromiseButton from "../../../../components/lib/PromiseButton";
import Text from "../../../../components/lib/Text";
import Icon from "../../../../components/lib/Icon";
import { dateTimeFormatterExt, decimalFormatter } from "../../constants";
import { ButtonGroupRight } from "../../../../components/lib/ButtonGroup";
import {
  Card,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
  CardHeaderTitle,
  CardBody,
} from "../../../../components/lib/Card";
import {
  RATE_TYPES_LABELS,
  REVIEW_STATUS_TYPES,
  REVIEW_STATUS_TYPES_LABELS,
} from "../../types";
import { useAttemptDataContext } from "../../context/AttemptDataContext";
// @ts-expect-error
import { logAsyncOperationError } from "../../../../utils/logging";

import type { AttemptDataObject } from "../../types";
import { useVal5KAdminContext } from "../../context/Val5KAdminContext";

const PageHeadBlock = () => {
  const { router, fetchArgusAPI, showModalError } = useVal5KAdminContext();
  const {
    attemptData: attempt,
    validatorString,
    completedTimestamp,
    reviewData: review,
    reviewStatus,
    firstJobData: firstJob,
    isReadyForPayment,
    readyForPaymentTimestamp,
    isPaid,
    paidTimestamp,
    isAnalysisCompleted,
    analysisCompletedTimestamp,
    isArchived,
    archivedTimestamp,
    isFreeValidation,
    totalReward,
    createdTimestamp,
    updatedTimestamp,
    refreshAttemptItemData,
  } = useAttemptDataContext();

  const reviewId = review.get("id");
  const firstJobRateType = firstJob.get("rate_type");
  const isThreeLevelBanding = review.get("is_three_level_banding", false);

  // handlers

  const handleBackToList = useCallback(() => {
    router.push("/admin/val5000/validations");
  }, [router]);

  const handleUpdateReviewAttempt = useCallback(
    async (payload: Partial<AttemptDataObject>) => {
      try {
        await fetchArgusAPI(`attempts/${attempt.get("id")}/`, {
          method: "patch",
          data: payload,
        });
        await refreshAttemptItemData();
      } catch (err: any) {
        logAsyncOperationError("handleUpdateReviewAttempt", err);
        showModalError(
          "Error occurred while updating user attempt. Please, try again later."
        );
      }
    },
    [attempt, refreshAttemptItemData, fetchArgusAPI, showModalError]
  );

  // render

  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft>
          <CardHeaderTitle>
            {review.get("title") ? (
              <Stack nogap css={{ alignItems: "flex-start" }}>
                {review.get("title")}, {RATE_TYPES_LABELS[firstJobRateType]}
                <small>validated by {validatorString}</small>
              </Stack>
            ) : (
              `Review ${reviewId}`
            )}
          </CardHeaderTitle>
        </CardActionsLeft>
        <CardActionsRight>
          <Button
            icon="arrow-left"
            size="normal"
            color="brand"
            onClick={handleBackToList}
          >
            Back To List
          </Button>
        </CardActionsRight>
      </CardActions>
      <CardBody>
        <Stack css={{ alignItems: "flex-end" }}>
          <ButtonGroupRight>
            {!isFreeValidation && (
              <PromiseButton
                color="brand"
                variant="outlined"
                css={{ width: "$56" }}
                iconRight={
                  isReadyForPayment ? ["far", "check-square"] : ["far", "square"]
                }
                size="small"
                onClick={() =>
                  handleUpdateReviewAttempt({ ready_for_payment: !isReadyForPayment })
                }
                disabled={reviewStatus !== REVIEW_STATUS_TYPES.REVIEWED}
                loadingText="Mark As Ready For Payment"
              >
                Mark As Ready For Payment
              </PromiseButton>
            )}
            {!isFreeValidation && (
              <PromiseButton
                color="brand"
                variant="outlined"
                size="small"
                css={{ width: "$40" }}
                iconRight={isPaid ? ["far", "check-square"] : ["far", "square"]}
                onClick={() => handleUpdateReviewAttempt({ paid: !isPaid })}
                disabled={!isReadyForPayment}
                loadingText="Mark As Paid"
              >
                Mark As Paid
              </PromiseButton>
            )}
            <PromiseButton
              color="brand"
              variant="outlined"
              size="small"
              css={{ width: "$56" }}
              iconRight={
                isAnalysisCompleted ? ["far", "check-square"] : ["far", "square"]
              }
              onClick={() =>
                handleUpdateReviewAttempt({ analysis_completed: !isAnalysisCompleted })
              }
              disabled={
                reviewStatus !== REVIEW_STATUS_TYPES.REVIEWED &&
                reviewStatus !== REVIEW_STATUS_TYPES.SKIPPED
              }
              loadingText="Mark As Analysis Completed"
            >
              Mark As Analysis Completed
            </PromiseButton>
            <PromiseButton
              color="brand"
              variant="outlined"
              size="small"
              css={{ width: "$40" }}
              iconRight={isArchived ? ["far", "check-square"] : ["far", "square"]}
              onClick={() => handleUpdateReviewAttempt({ archived: !isArchived })}
              disabled={
                reviewStatus !== REVIEW_STATUS_TYPES.REVIEWED &&
                reviewStatus !== REVIEW_STATUS_TYPES.SKIPPED
              }
              loadingText="Mark As Archived"
            >
              Mark As Archived
            </PromiseButton>
          </ButtonGroupRight>
          <Stack nogap css={{ alignItems: "flex-end" }}>
            <Text nowrap>
              <Text>Status: </Text>
              {reviewStatus === REVIEW_STATUS_TYPES.REVIEWED ? (
                <Text color="positive">
                  <Icon icon="check" />
                </Text>
              ) : (
                ""
              )}
              {` ${REVIEW_STATUS_TYPES_LABELS[reviewStatus]}`}
            </Text>
            {isThreeLevelBanding && (
              <Text nowrap>
                <Text>Three level banding: </Text>
                <Text color="positive">
                  <Icon icon="check" />
                </Text>{" "}
                Yes
              </Text>
            )}
            {isFreeValidation && (
              <Text nowrap>
                <Text>Free validation: </Text>
                <Text color="positive">
                  <Icon icon="check" />
                </Text>{" "}
                Yes
              </Text>
            )}
            {!isFreeValidation && totalReward && (
              <Text nowrap>
                <Text>Reward for validation: </Text>
                {`$ ${decimalFormatter(totalReward)}`}
              </Text>
            )}
            <Text nowrap>
              <Text>Validator: </Text>
              {validatorString}
            </Text>
            {createdTimestamp && (
              <Text nowrap>
                <Text>Started at: </Text>
                {dateTimeFormatterExt(createdTimestamp)}
              </Text>
            )}
            {updatedTimestamp && (
              <Text nowrap>
                <Text>Last Updated at: </Text>
                {dateTimeFormatterExt(updatedTimestamp)}
              </Text>
            )}
            {completedTimestamp && (
              <Text nowrap>
                <Text>Completed at: </Text>
                {dateTimeFormatterExt(completedTimestamp)}
              </Text>
            )}
            {!isFreeValidation && readyForPaymentTimestamp && (
              <Text nowrap>
                <Text>Marked as Ready For Payment at: </Text>
                {dateTimeFormatterExt(readyForPaymentTimestamp)}
              </Text>
            )}
            {!isFreeValidation && paidTimestamp && (
              <Text nowrap>
                <Text>Marked as Paid at: </Text>
                {dateTimeFormatterExt(paidTimestamp)}
              </Text>
            )}
            {analysisCompletedTimestamp && (
              <Text nowrap>
                <Text>Marked as Analysis Completed at: </Text>
                {dateTimeFormatterExt(analysisCompletedTimestamp)}
              </Text>
            )}
            {archivedTimestamp && (
              <Text nowrap>
                <Text>Marked as Archived at: </Text>
                {dateTimeFormatterExt(archivedTimestamp)}
              </Text>
            )}
          </Stack>
        </Stack>
      </CardBody>
    </Card>
  );
};

PageHeadBlock.displayName = "PageHeadBlock";

export default PageHeadBlock;
