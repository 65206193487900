// @flow
// Filter only for the name of the project.
import { action } from "mobx";

import FilterObject, { FilterWithSort, ContainsTextFilter } from "../Filter";
import type { FilterColumn } from "../Filter";
import Sort from "../Sort";
import SortState from "../SortState";

export default class ProjectCostFilter extends ContainsTextFilter(FilterWithSort) {
  constructor(
    store: Object,
    column: FilterColumn,
    applyFilter: (FilterColumn, FilterObject) => void,
    applySort: (FilterColumn, Sort) => void,
    removeFilter: (FilterColumn) => void,
    removeSort: (FilterColumn) => void
  ) {
    super(store, column);

    this.applyFilter = applyFilter;
    this.applySort = applySort;
    this.removeFilter = removeFilter;
    this.removeSort = removeSort;
    // sort state only for PCE, the data column in CREATED for rest it is CREATE_DATE
    this.sortState = new SortState("CREATED");

    this.buildQueryFilter = action(this.buildQueryFilter.bind(this));
    this.onApply = action(this.onApply.bind(this));
  }

  buildQueryFilter() {
    if (!this.textToLookFor || !this.textToLookFor.trim()) {
      return null;
    }

    return new FilterObject(
      "$nameFilterText: String!",
      "nameIContains: $nameFilterText",
      {
        nameFilterText: this.textToLookFor,
      }
    );
  }
}
