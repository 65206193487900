// @flow

import { extendObservable, action, runInAction, computed } from "mobx";

import CustomBuyRatesExportOptions, {
  RATE_TYPE_FREQUENCY,
} from "./CustomBuyRatesExportOptions";
import NetworkState from "./NetworkState";
import ModalState from "./ModalState";
import { filterFloat } from "./SupportFunctions";
import type { FetchGraphQL } from "../App";
import debounce from "lodash/debounce";

export const MARKUP_SOURCE = {
  MARKET: "market",
  LIBRARY: "library",
  CUSTOM: "custom",
};

export class LibraryMarkup {
  id: number;
  name: string;
  percent: number;

  constructor(payloadMarkup: Object) {
    this.id = parseInt(payloadMarkup.worksheetId, 10);
    this.name = payloadMarkup.worksheetName;
    this.percent = payloadMarkup.totalMarkupPercent;
  }

  toString = (): string => {
    return `${this.name} (${this.percent}%)`;
  };
}

export default class CustomBuyRatesExportModalState {
  networkState: NetworkState;
  modalState: ModalState;
  exportOptions: CustomBuyRatesExportOptions;

  markupSource: string;
  markupSearchValue: string;
  markupsFromLibrary: Array<LibraryMarkup>;
  selectedMarkupOption: ?Object;
  selectedMarkupOptionError: string;
  exportFunction: (options: CustomBuyRatesExportOptions) => void;

  customMarkupInputValue: string;
  customMarkupInputValueError: string;
  rateMultiplierInputValue: string;
  rateMultiplierInputValueError: string;
  isValid: boolean;
  showCurrencyTypeOptions: boolean;

  onChangeFileName: (SyntheticInputEvent<HTMLInputElement>) => void;
  onChangeFileTemplate: (string) => void;
  onChangeRateFrequencyType: (string) => void;
  onChangeCurrencyType: (string) => void;
  onChangeRateMultiplier: (SyntheticInputEvent<HTMLInputElement>) => void;
  onChangeMarkupSource: (string) => void;
  onChangeMarkupFromLibrary: (LibraryMarkup) => void;
  onChangeCustomMarkup: (SyntheticInputEvent<HTMLInputElement>) => void;
  onMarkupSearchValueChange: (string) => void;
  getMarkupsFromLibrary: () => void;
  createMarkupsOptions: (cb: (data: Object[]) => void) => void;
  validate: () => void;
  onExport: (Event) => void;
  onCancel: (Event) => void;

  fetchGraphQL: FetchGraphQL;

  constructor(
    fetchGraphQL: FetchGraphQL,
    exportFunction: (options: CustomBuyRatesExportOptions) => void
  ) {
    this.fetchGraphQL = fetchGraphQL;

    extendObservable(this, {
      markupsFromLibrary: [],
      selectedMarkupOption: null,
      selectedMarkupOptionError: "",
      markupSearchValue: "",
      markupSource: MARKUP_SOURCE.LIBRARY,
      customMarkupInputValue: "0",
      customMarkupInputValueError: null,
      rateMultiplierInputValue: "1",
      rateMultiplierInputValueError: "",
      showCurrencyTypeOptions: false,
      isValid: computed(() => {
        return (
          !this.customMarkupInputValueError &&
          !this.rateMultiplierInputValueError &&
          !this.selectedMarkupOptionError
        );
      }),
    });

    this.exportFunction = exportFunction;

    this.networkState = new NetworkState();
    this.modalState = new ModalState();
    this.exportOptions = new CustomBuyRatesExportOptions();

    this.onChangeFileName = action(this.onChangeFileName.bind(this));
    this.onChangeFileTemplate = action(this.onChangeFileTemplate.bind(this));
    this.onChangeRateFrequencyType = action(this.onChangeRateFrequencyType.bind(this));
    this.onChangeRateMultiplier = action(this.onChangeRateMultiplier.bind(this));
    this.onChangeMarkupSource = action(this.onChangeMarkupSource.bind(this));
    this.onChangeMarkupFromLibrary = action(this.onChangeMarkupFromLibrary.bind(this));
    this.onChangeCustomMarkup = action(this.onChangeCustomMarkup.bind(this));
    this.onChangeCurrencyType = action(this.onChangeCurrencyType.bind(this));
    this.getMarkupsFromLibrary = action(this.getMarkupsFromLibrary.bind(this));
    this.createMarkupsOptions = action(this.createMarkupsOptions.bind(this));
    this.onMarkupSearchValueChange = action(this.onMarkupSearchValueChange.bind(this));
    this.validate = action(this.validate.bind(this));
    // this.getMarkupsOptions = action(this.getMarkupsOptions.bind(this));
    this.onExport = action(this.onExport.bind(this));
    this.onCancel = action(this.onCancel.bind(this));
  }

  onMarkupSearchValueChange(searchValue: string): void {
    this.markupSearchValue = searchValue;
    this.getMarkupsFromLibraryDebounced();
  }

  getMarkupsFromLibraryDebounced = debounce((value: string): void => {
    this.getMarkupsFromLibrary();
  }, 500);

  onChangeFileTemplate(newValue: string) {
    this.exportOptions.fileTemplate = newValue;
  }

  onChangeRateFrequencyType(newValue: string) {
    this.exportOptions.rateFrequencyType = newValue;
    this.rateMultiplierInputValueError = "";

    switch (this.exportOptions.rateFrequencyType) {
      case RATE_TYPE_FREQUENCY.HOURLY:
        this.exportOptions.rateMultiplier = 1;
        break;
      case RATE_TYPE_FREQUENCY.DAILY:
        this.exportOptions.rateMultiplier = 8;
        break;
      case RATE_TYPE_FREQUENCY.WEEKLY:
        this.exportOptions.rateMultiplier = 40;
        break;
      case RATE_TYPE_FREQUENCY.MONTHLY:
        this.exportOptions.rateMultiplier = 160;
        break;
      default:
        this.exportOptions.rateMultiplier = 1;
        break;
    }

    this.rateMultiplierInputValue = this.exportOptions.rateMultiplier.toString();
  }

  onChangeRateMultiplier(e: SyntheticInputEvent<HTMLInputElement>) {
    this.rateMultiplierInputValue = e.target.value;
    this.exportOptions.rateMultiplier = filterFloat(this.rateMultiplierInputValue);

    if (isNaN(this.exportOptions.rateMultiplier)) {
      this.exportOptions.rateMultiplier = 0;
      this.rateMultiplierInputValueError =
        '"' + e.target.value + '" is not a valid number.';
    } else {
      this.rateMultiplierInputValueError = "";
    }
  }

  onChangeMarkupSource(newValue: string) {
    this.markupSource = newValue;
    this.customMarkupInputValueError = "";
    this.customMarkupInputValue = "0";
    this.selectedMarkupOption = null;
    this.selectedMarkupOptionError = "";
    this.exportOptions.markupPercent = 0;
    this.exportOptions.useMarkupsFromRateCard = newValue === MARKUP_SOURCE.MARKET;
    this.exportOptions.markupSource = this.markupSource;
  }

  onChangeMarkupFromLibrary(markupOption: LibraryMarkup) {
    this.exportOptions.markupPercent = markupOption.percent;
    this.selectedMarkupOption = markupOption;
  }

  onChangeCustomMarkup(e: SyntheticInputEvent<HTMLInputElement>) {
    this.customMarkupInputValue = e.target.value;
    this.exportOptions.markupPercent = filterFloat(this.customMarkupInputValue);

    if (isNaN(this.exportOptions.markupPercent)) {
      this.exportOptions.markupPercent = 0;
      this.customMarkupInputValueError =
        '"' + e.target.value + '" is not a valid number.';
    } else {
      this.customMarkupInputValueError = "";
    }
  }

  onChangeCurrencyType(newValue: string) {
    this.exportOptions.currencyType = newValue;
  }

  onChangeFileName(e: SyntheticInputEvent<HTMLInputElement>) {
    this.exportOptions.fileName = e.target.value;
  }

  validate() {
    let error = false;

    if (
      this.markupSource === MARKUP_SOURCE.LIBRARY &&
      !this.exportOptions.markupPercent
    ) {
      this.selectedMarkupOptionError = "Please select a markup from your library";
      error = true;
    }

    if (this.markupSource === MARKUP_SOURCE.CUSTOM && !this.exportOptions.markupPercent) {
      this.customMarkupInputValueError = "Please enter a custom markup value";
      error = true;
    }

    if (!this.exportOptions.rateMultiplier || this.exportOptions.rateMultiplier < 1) {
      this.rateMultiplierInputValueError =
        "Multiplier value must be greater or equal to 1";
      error = true;
    }

    return error;
  }

  onExport(e: Event) {
    if (this.validate()) return;

    this.modalState.hideModal();
    this.exportFunction(this.exportOptions);
  }

  onCancel(e: Event) {
    this.modalState.hideModal();
  }

  createMarkupsOptions(cb: (data: Object[]) => void) {
    let options = this.markupsFromLibrary.map((markup) => {
      let name = markup.name;
      if (!name.trim()) name = "";
      return {
        value: markup.id,
        label: `${name} (${markup.percent}%)`,
        percent: markup.percent,
        clearableValue: false,
      };
    });

    cb(options);
  }

  // getMarkupsOptions(input: Object, cb: Object) {
  //   this.getMarkupsFromLibrary(input, cb).then();
  // }

  getMarkupsFromLibrary() {
    // if (this.networkState.loading) return;

    // if (this.markupsFromLibrary && this.markupsFromLibrary.length) {
    //   this.createMarkupsOptions(cb);
    // return;
    // }

    const query = `
    query getMarkupsFromLibrary($filters: WorksheetsFiltersInput) {
      viewer {
        negotiationWorksheets(filters: $filters, order: {field: NAME, direction: ASC}) {
          edges {
            node {
              worksheetName
              totalMarkupPercent
              worksheetId
              createdBy {
                userId
              }
            }
          }
        }
      }
    }
    `;

    const filters = this.markupSearchValue
      ? { nameIContains: this.markupSearchValue }
      : null;

    this.networkState.loading = true;
    this.networkState.error = null;

    this.fetchGraphQL(query, { filters }).then(
      (response) => {
        runInAction("getMarkupsFromLibrary--success", () => {
          this.networkState.loading = false;
          this.networkState.error = null;
          if (this.networkState.logGraphQLError("updateRatesOnSearches", response)) {
            // TODO: Display user friendly error message
            return;
          }

          this.markupsFromLibrary = response.data.viewer.negotiationWorksheets.edges.map(
            (edge) => {
              return new LibraryMarkup(edge.node);
            }
          );

          // this.createMarkupsOptions(cb);
        });
      },
      (e) => {
        this.networkState.handleError("Updating Rates on Selected Searches", e);
        // this.networkState.logGraphQLError(payload);
        // TODO: Display user friendly error message
        // TODO: Handle Request cancellation
      }
    );
  }
}
