import React, { MouseEventHandler, useMemo } from "react";
import graphql from "babel-plugin-relay/macro";
import { MultiSelect } from "../../../components/lib/MultiSelect";
import { Region } from "../types";
import { RegionsField_viewer$key } from "./__generated__/RegionsField_viewer.graphql";
import Box from "../../../components/lib/Box";
import { Selected, Selections } from "./styled";
import { FormControl, Label, LabelWrapper } from "../../../components/lib/Label";
import { Placeholder } from "../../../components/lib/Placeholder";
import { useFragment } from "react-relay";
import Icon from "../../../components/lib/Icon";
import { isNonNullable } from "../../../utils/hashmap";

const fragment = graphql`
  fragment RegionsField_viewer on Viewer {
    regions(order: [{ field: NAME }]) {
      edges {
        node {
          name
          regionId @required(action: NONE)
          locations {
            locationId @required(action: NONE)
            name
            parent {
              locationId @required(action: NONE)
              name
            }
          }
        }
      }
    }
  }
`;

type Props = {
  viewer: RegionsField_viewer$key;
  regions: Region[];
  disabledRegions?: Region[];
  setRegions(regs: Region[]): void;
  hasLocations: boolean;
};

export function RegionsField(props: Props) {
  const { viewer, regions, setRegions, hasLocations, disabledRegions = [] } = props;
  const data = useFragment(fragment, viewer);

  const options = useMemo(
    () => data?.regions?.edges.map((edge) => edge?.node).filter(isNonNullable) || [],
    [data]
  );

  function clear() {
    setRegions([]);
  }

  function onSelect(region: Region) {
    if (region && !regions.some((r) => r === region)) {
      setRegions([...regions, region]);
    }
  }

  function unselect(region: Region): MouseEventHandler<HTMLButtonElement> {
    return (e) => {
      e.stopPropagation();
      setRegions(regions.filter((r) => r.regionId !== region.regionId));
    };
  }

  return (
    <Box>
      <MultiSelect
        disabled={hasLocations}
        icon="globe"
        selected={regions}
        disabledOptions={disabledRegions}
        label="Region (Optional)"
        id="regionSelect"
        placeholder="Select region"
        readOnly
        options={options}
        renderOption={(o) => o?.name}
        getKey={(o) => String(o?.regionId)}
        onClearSelections={clear}
        noResultsRender="No options."
        onSelect={onSelect}
        renderAddon={(opts) => (
          <Selections>
            {opts.map((o) => (
              <Selected
                key={o.regionId}
                onClick={unselect(o)}
                title={`Unselect ${o.name}`}
              >
                <span>{o.name}</span>
                <Icon size="sm" icon="close" />
              </Selected>
            ))}
          </Selections>
        )}
      />
    </Box>
  );
}

export function RegionsFieldLoader() {
  return (
    <Box>
      <FormControl fill>
        <LabelWrapper>
          <Label>Region</Label>
        </LabelWrapper>
        <Placeholder height={44} />
      </FormControl>
    </Box>
  );
}
