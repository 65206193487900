// @flow

import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBox from "../../ratecards/components/SearchBox";
import NegotiationWorksheetList from "./components/NegotiationWorksheetList";
import Modal from "../../ratecards/components/Modal";
import classNames from "classnames";

/**
 * List component for negotiation worksheet list page
 */
class List extends Component {
  componentDidMount() {
    this.props.store.negotiationWorksheetListPTStore.pagination.goFetch();

    // reset to null so some elements visible while editing start hidden
  }
  /**
   * Render  Worksheet list page
   * @return {XML}
   */
  render() {
    const store = this.props.store.negotiationWorksheetListPTStore;
    return (
      <div className="view list">
        <div className="rc-container bring-forward">
          <div className="header-action-bar">
            <h2>Negotiation Worksheets Admin</h2>
            <p className="subtext" />
            <div className="clearfix" />
          </div>
          <div className="header-action-bar marginTopzero">
            <div className="pull-left">
              <SearchBox
                value={store.negotiationFilter.textToLookFor}
                onChange={store.negotiationFilter.onTextToLookForChange}
                onSearch={store.negotiationFilter.onApply}
                style={{
                  width: "260px",
                }}
              />
            </div>
            {
              <div
                className="btn-group pull-right rc-center"
                style={{
                  height: 40,
                }}
              >
                {store.isEditing && (
                  <button className="btn btn-yellow" onClick={store.handleStopEdit}>
                    Stop Editing
                  </button>
                )}
                {!store.isEditing && (
                  <div>
                    <button className="btn btn-green" onClick={store.handleStartEdit}>
                      <FontAwesomeIcon icon="edit" fixedWidth className="icon" />
                      Edit
                    </button>
                    <button className="btn" onClick={store.helpModal.showModal}>
                      <FontAwesomeIcon
                        icon="question-circle"
                        fixedWidth
                        className="icon"
                      />
                      Help
                    </button>
                  </div>
                )}
              </div>
            }
            <div className="clearfix" />
          </div>
          <NegotiationWorksheetList store={store} />
        </div>
        {
          <div
            className={classNames("ratecards-edit-actions", {
              hidden: store.isEditing === null,
              "bottom-appear": store.isEditing === true,
              "bottom-disappear": store.isEditing === false,
            })}
          >
            <button
              className="btn"
              disabled={store.selectedCount === 0}
              onClick={store.exportNegotiationWorksheets}
            >
              <FontAwesomeIcon icon="download" fixedWidth className="icon" /> Export
            </button>
          </div>
        }
        {
          <div
            className={classNames("rc-container-backdrop", {
              hidden: store.isEditing === null,
              "fade-in": store.isEditing === true,
              "fade-out": store.isEditing === false,
            })}
          />
        }

        <Modal show={store.helpModal.show} onHide={store.helpModal.hideModal}>
          <div className="container-section header">
            <h4>Help</h4>
          </div>
          <div className="container-section footer">
            <p>Here you can see all of your Worksheets...</p>
          </div>
        </Modal>
      </div>
    );
  }
}

List.propTypes = {
  store: PropTypes.object.isRequired,
};

export default observer(List);
