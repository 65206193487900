import { emptyMap, emptyOrderedMap } from "../../../../constants";
import { JOBS_ORDERING_TYPES, RATE_TYPES } from "../../types";

import type {
  JobDataMap,
  JobsDataOrderedMap,
  Values,
  RatesFeedbackOrderedMap,
  JOBS_ORDERING_TYPES_TYPE,
} from "../../types";
import type { ImmutableSet } from "../../../../types/immutable";

export const LEVEL_KEYS = {
  LOW: "low",
  HIGH: "high",
} as const;

export type LEVEL_KEYS_TYPE = Values<typeof LEVEL_KEYS>;

export type SelectedJobIDsType = ImmutableSet<number>;

export const getAllJobsOrdered = (
  jobsData: JobsDataOrderedMap,
  dataOrderingKey: JOBS_ORDERING_TYPES_TYPE = JOBS_ORDERING_TYPES.PAY_RATE_MIN,
  asc: boolean = true,
  feedbackData?: RatesFeedbackOrderedMap
): JobsDataOrderedMap => {
  return jobsData.sortBy((jobData) => {
    const jobId = jobData?.get("id")!;

    return (
      (dataOrderingKey === JOBS_ORDERING_TYPES.PAY_RATE_MIN
        ? getLevelRatesFeedbackForJob(jobsData, jobId, LEVEL_KEYS.LOW, feedbackData).get(
            dataOrderingKey
          ) ||
          getLevelRateResultsForJob(jobsData, jobId, LEVEL_KEYS.LOW).get(dataOrderingKey)
        : getLevelRatesFeedbackForJob(jobsData, jobId, LEVEL_KEYS.HIGH, feedbackData).get(
            dataOrderingKey
          ) ||
          getLevelRateResultsForJob(jobsData, jobId, LEVEL_KEYS.HIGH).get(
            dataOrderingKey
          )) * (asc ? 1 : -1)
    );
  }) as JobsDataOrderedMap;
};

export const getAllJobsIdsSet = (jobsData: JobsDataOrderedMap): SelectedJobIDsType => {
  return jobsData
    .toList()
    .map((i) => i?.get("id"))
    .toSet() as unknown as SelectedJobIDsType;
};

const getJob = (jobsData: JobsDataOrderedMap, jobId: number) => {
  return jobsData.get(jobId) ?? emptyMap;
};

const getAllRateResultsForJob = (jobsData: JobsDataOrderedMap, jobId: number) => {
  return (getJob(jobsData, jobId).get("rate_results") ?? emptyOrderedMap).sortBy((v) =>
    v?.get("level")
  );
};

export const getLevelRateResultsForJob = (
  jobsData: JobsDataOrderedMap,
  jobId: number,
  level: LEVEL_KEYS_TYPE
) => {
  const allJobRates = getAllRateResultsForJob(jobsData, jobId);
  return level === LEVEL_KEYS.LOW
    ? allJobRates.first() ?? emptyMap
    : allJobRates.last() ?? emptyMap;
};

export const getLowHighRateResultsForJob = (
  jobsData: JobsDataOrderedMap,
  jobId: number
) => {
  const lowLevelRates = getLevelRateResultsForJob(jobsData, jobId, LEVEL_KEYS.LOW);
  const highLevelRates = getLevelRateResultsForJob(jobsData, jobId, LEVEL_KEYS.HIGH);
  return {
    lowLevelRates,
    highLevelRates,
  };
};

export const getLevelRatesFeedbackForJob = (
  jobsData: JobsDataOrderedMap,
  jobId: number,
  level: LEVEL_KEYS_TYPE,
  feedbackData?: RatesFeedbackOrderedMap
) => {
  const levelRates = getLevelRateResultsForJob(jobsData, jobId, level);
  const jobRatesFeedback =
    feedbackData?.filter((i) => i.get("job") === jobId) ?? emptyOrderedMap;

  return levelRates && jobRatesFeedback && levelRates.size && jobRatesFeedback.size
    ? jobRatesFeedback.get(levelRates.get("id")) ?? emptyMap
    : emptyMap;
};

export const getLowHighRatesFeedbackForJob = (
  jobsData: JobsDataOrderedMap,
  jobId: number,
  feedbackData?: RatesFeedbackOrderedMap
) => {
  const lowLevelRatesFeedback = getLevelRatesFeedbackForJob(
    jobsData,
    jobId,
    LEVEL_KEYS.LOW,
    feedbackData
  );
  const highLevelRatesFeedback = getLevelRatesFeedbackForJob(
    jobsData,
    jobId,
    LEVEL_KEYS.HIGH,
    feedbackData
  );
  return {
    lowLevelRatesFeedback,
    highLevelRatesFeedback,
  };
};

export const getJobCurrencyData = (jobData: JobDataMap) => {
  return jobData.get("currency_data") ?? emptyMap;
};

export const getJobRateTypeParameters = (jobData: JobDataMap) => {
  const rateType = jobData.get("rate_type") ?? RATE_TYPES.HOURLY;
  let rateMultiplier = 1;

  if (rateType === RATE_TYPES.HOURLY) {
    rateMultiplier = jobData.get("hourly_multiplier");
  } else if (rateType === RATE_TYPES.DAILY) {
    rateMultiplier = jobData.get("daily_multiplier");
  } else if (rateType === RATE_TYPES.WEEKLY) {
    rateMultiplier = jobData.get("weekly_multiplier");
  } else if (rateType === RATE_TYPES.MONTHLY) {
    rateMultiplier = jobData.get("monthly_multiplier");
  }

  return { rateType, rateMultiplier };
};
