/**
 * @generated SignedSource<<aa08d2a96244e8d359370784b55a51c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useAutofillJobTitleQuery$variables = {
  searchParam?: string | null;
};
export type useAutofillJobTitleQuery$data = {
  readonly viewer: {
    readonly jobTitles: {
      readonly results: ReadonlyArray<{
        readonly clientJobLibraryInfo: {
          readonly adhocCountries: ReadonlyArray<number | null> | null;
          readonly certifiedCountries: ReadonlyArray<number | null> | null;
        } | null;
        readonly collection: number | null;
        readonly id: string | null;
        readonly isJobLabel: boolean | null;
        readonly shareInfo: {
          readonly isMine: boolean | null;
          readonly sharedBy: {
            readonly firstName: string | null;
            readonly lastName: string | null;
          } | null;
        } | null;
        readonly title: string | null;
      } | null> | null;
    } | null;
  } | null;
};
export type useAutofillJobTitleQuery = {
  response: useAutofillJobTitleQuery$data;
  variables: useAutofillJobTitleQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchParam"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "page",
            "value": 1
          },
          {
            "kind": "Literal",
            "name": "pageSize",
            "value": 25
          },
          {
            "kind": "Variable",
            "name": "search",
            "variableName": "searchParam"
          }
        ],
        "concreteType": "JobTitleListNode",
        "kind": "LinkedField",
        "name": "jobTitles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JobTitleNode",
            "kind": "LinkedField",
            "name": "results",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isJobLabel",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "collection",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ShareInfoType",
                "kind": "LinkedField",
                "name": "shareInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isMine",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SharedByType",
                    "kind": "LinkedField",
                    "name": "sharedBy",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ClientJobLibraryTasteInfoNode",
                "kind": "LinkedField",
                "name": "clientJobLibraryInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "adhocCountries",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "certifiedCountries",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useAutofillJobTitleQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useAutofillJobTitleQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a82065823d846d36e8da10135611a3ba",
    "id": null,
    "metadata": {},
    "name": "useAutofillJobTitleQuery",
    "operationKind": "query",
    "text": "query useAutofillJobTitleQuery(\n  $searchParam: String\n) {\n  viewer {\n    jobTitles(search: $searchParam, page: 1, pageSize: 25) {\n      results {\n        id\n        title\n        isJobLabel\n        collection\n        shareInfo {\n          isMine\n          sharedBy {\n            firstName\n            lastName\n          }\n        }\n        clientJobLibraryInfo {\n          adhocCountries\n          certifiedCountries\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "32d8d7242b713fec997ad5b736cca41d";

export default node;
