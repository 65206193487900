import React from "react";
import { styled, keyframes } from "../../stitches.config";

import Center from "./Center";
import Overlay from "./Overlay";

export type ShowLoaderFunc = () => Promise<void>;
export type HideLoaderFunc = () => Promise<void>;

const bounce = keyframes({
  "25%": { transform: "translateY(9px)" },
  "50%": { transform: "translateY(18px) scale(1, 0.9)" },
  "75%": { transform: "translateY(9px)" },
  "100%": { transform: "translateY(0)" },
});

const shadow = keyframes({
  "100%": { transform: "scale(1, 1)" },
  "50%": { transform: "scale(1.2, 1)" },
});

const TickerLoader = styled("div", {
  width: "64px",
  height: "100px",
  position: "relative",
  margin: "auto",
  "&:before": {
    content: '""',
    width: "60px",
    height: "10px",
    background: "#000",
    opacity: 0.1,
    position: "absolute",
    top: "73px",
    left: "2px",
    borderRadius: "50%",
    animation: `${shadow} 0.5s linear infinite`,
  },
  "&:after": {
    content: '""',
    width: "0",
    height: "0",
    borderLeft: "32px solid transparent",
    borderRight: "32px solid transparent",
    borderBottom: "60px solid $brandLight",
    animation: `${bounce} 0.5s linear infinite`,
    position: "absolute",
    top: "0",
    left: "0",
  },
});
TickerLoader.displayName = "TickerLoader";

export function TickerPageLoader({ overlay = false }) {
  return (
    <Center
      css={{ position: "fixed", top: 0, bottom: 0, left: 0, right: 0, zIndex: "$10" }}
    >
      {overlay ? (
        <>
          <Overlay />
          <TickerLoader />
        </>
      ) : (
        <TickerLoader />
      )}
    </Center>
  );
}
TickerPageLoader.displayName = "TickerPageLoader";

export function TickerContentLoader({ overlay = false }) {
  return (
    <Center
      css={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: "$10" }}
    >
      {overlay ? (
        <>
          <Overlay />
          <TickerLoader />
        </>
      ) : (
        <TickerLoader />
      )}
    </Center>
  );
}
TickerContentLoader.displayName = "TickerContentLoader";

export default TickerLoader;
