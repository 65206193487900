// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react";
import { Link } from "react-router";
import Modal from "../../ratecards/components/Modal";
import { TagItemWithoutDelete } from "../../ratecards/components/TagItem";

const JobDetail = observer((props) => {
  const store = props.store;

  let owner,
    jobId,
    jobLabel,
    createdDate,
    tagSection = null;

  if (store.network.loading) {
    jobId = "...";
    owner = "...";
    createdDate = "...";
    jobLabel = "...";
  } else if (store.jobDetail) {
    jobId = store.jobDetail.jobId;
    owner = store.jobDetail.owner.fullname;
    createdDate = store.jobDetail.created.format("MMMM D, YYYY");
    jobLabel = store.jobDetail.jobLabel;
    tagSection = store.jobDetail.tags.map((tag) => (
      <TagItemWithoutDelete key={tag.tagId} tag={tag} />
    ));
  }

  var actionBar = <nav className="action-bar" />;

  return (
    <div className="top">
      <div className="above">
        <div className="ratecard-label-header ellipsis max-width-80">
          <h2 className="ellipsis">{jobLabel}</h2>
        </div>
        <nav className="back-bar">
          <Link to={`/admin/jobs/`} className="btn btn-green">
            <FontAwesomeIcon icon="arrow-left" fixedWidth className="icon" />
            Go Back
          </Link>
          <button className="btn" onClick={store.showHelp}>
            <FontAwesomeIcon icon="question-circle" fixedWidth className="icon" />
            Help
          </button>
        </nav>
      </div>
      <div className="below">
        <div className="ratecard-meta ellipsis">
          <div className="ratecard-owner ellipsis">
            <FontAwesomeIcon icon="key" fixedWidth className="icon" /> {jobId}
          </div>
          <div className="ratecard-owner ellipsis">
            <FontAwesomeIcon icon="user" fixedWidth className="icon" /> {owner}
          </div>
          <div className="ratecard-created-at ellipsis">
            <FontAwesomeIcon icon="calendar-alt" fixedWidth className="icon" /> Created{" "}
            {createdDate}
          </div>
          {/*          <div className="ratecard-created-at ellipsis">
            <FontAwesomeIcon icon="calendar-alt" fixedWidth className="icon"/> {" "}
            Date Modified : {modifiedDate}
          </div>*/}
        </div>
        {actionBar}
      </div>
      <div className="tagbox">{tagSection}</div>
      <Modal show={store.showHelpModal} onHide={store.hideHelp}>
        <div className="container-section header">
          <h4>Help</h4>
        </div>
        <div className="container-section footer">
          <p>Here you can see all the details of the Job</p>
        </div>
      </Modal>
    </div>
  );
});

/**
 * JobDetail component for job_labels detail page
 */
class Detail extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor() {
    super();
    this.deleteTag = this.deleteTag.bind(this);
  }
  /**
   * componentDidMount
   * @return {void}
   */
  UNSAFE_componentWillMount() {
    this.props.store.jobLabelDetailAdminStore.jobId = this.props.router.params.jobId;
    this.props.store.jobLabelDetailAdminStore.router = this.context.router;
    this.props.store.jobLabelDetailAdminStore.getJobDetails();
  }

  deleteTag(e: object, tag_id) {
    // this.props.store.locationListStore.deleteSingleTags({
    //   tagId : tag_id,
    //   contentId:this.props.router.params.regionId,
    //   contentType:"JOBS"
    // });
  }
  /**
   * Render rate card detail page
   * @return {XML}
   */
  render() {
    const store = this.props.store.jobLabelDetailAdminStore;

    return (
      <div className="view">
        <div className="rc-container show-overflow">
          <JobDetail store={store} router={this.props.router} onClick={this.deleteTag} />
        </div>
        <div className="rc-container bring-forward">
          {
            <div className="header-action-bar">
              <span>Job Detail</span>
              <div className="clearfix" />
            </div>
          }
          <div className="row userjob">
            <div className="col-sm-6 col-md-6 col-lg-6">
              <div className="form-group">
                <label>Job Title</label>
                <div className="input_group">
                  <input
                    type="text"
                    disabled={true}
                    className="form-control"
                    value={store.newJobTitle}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6">
              <div className="form-group">
                <label>Description</label>
                <div className="input_group">
                  {!store.isEditing ? (
                    <textarea
                      className="form-control"
                      rows="5"
                      value={store.newJobDescription}
                      onChange={store.changeJobDescriptionText}
                      disabled="true"
                    />
                  ) : (
                    <p
                      dangerouslySetInnerHTML={{ __html: store.jobDescription }}
                      className="font-p-14"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(Detail);
