import React from "react";
import { fromJS } from "immutable";

import Icon from "../../../../components/lib/Icon";
import TextCrop from "../../../validator5K_admin/components/TextCrop";
import Text from "../../../../components/lib/Text";
import { decimalFormatter, dedupeCSVString } from "../../constants";
import Inline from "../../../../components/lib/Inline";
import { RATE_TYPES_LABELS, RATE_TYPES_OPTIONS } from "../../../validator5K_admin/types";
import { FilterTypes } from "../../../../components/tables/types";

import type { ReviewDataMap, TableColumnSpecsObject } from "../../types";

const surveyListTableConfig: TableColumnSpecsObject<ReviewDataMap>[] = [
  {
    type: "column",
    uniqueKey: "title",
    title: "Title",
    getter: (row) => row.get("title"),
    formatter: (value, source) => {
      const groupID = source.get("reviews_group_id");
      return (
        <Inline nowrap css={{ gap: "$2" }}>
          {groupID && (
            <Text color="brand">
              <Icon icon="list" title="This survey is a member of a group." />
            </Text>
          )}
          <TextCrop
            mode="tooltip"
            title="Title"
            text={dedupeCSVString(value)}
            emptyStub=""
          />
        </Inline>
      );
    },
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "jobs__rate_type",
    title: "Rate Type",
    getter: (row) => row.get("rate_type"),
    formatter: (value) => RATE_TYPES_LABELS[value as keyof typeof RATE_TYPES_LABELS],
    filterType: FilterTypes.ENUMERATION,
    filterOptions: fromJS(RATE_TYPES_OPTIONS),
  },
  {
    type: "column",
    uniqueKey: "countries_string",
    title: "Country",
    getter: (row) => row.get("countries_string"),
    formatter: (value) => {
      return (
        <TextCrop
          mode="tooltip"
          title="Country"
          text={dedupeCSVString(value)}
          emptyStub=""
        />
      );
    },
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "states_string",
    title: "State/Province",
    getter: (row) => row.get("states_string"),
    formatter: (value) => (
      <TextCrop mode="tooltip" title="State" text={dedupeCSVString(value)} emptyStub="" />
    ),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "cities_string",
    title: "City",
    getter: (row) => row.get("cities_string"),
    formatter: (value) => (
      <TextCrop mode="tooltip" title="City" text={dedupeCSVString(value)} emptyStub="" />
    ),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "regions_string",
    title: "Region",
    getter: (row) => row.get("regions_string"),
    formatter: (value) => (
      <TextCrop
        mode="tooltip"
        title="Region"
        text={dedupeCSVString(value)}
        emptyStub=""
      />
    ),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "industries_string",
    title: "Industry",
    getter: (row) => row.get("industries_string"),
    formatter: (value) => (
      <TextCrop
        mode="tooltip"
        title="Industry"
        text={dedupeCSVString(value)}
        emptyStub=""
      />
    ),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "categories_string",
    title: "Category",
    getter: (row) => row.get("categories_string"),
    formatter: (value) => (
      <TextCrop
        mode="tooltip"
        title="Category"
        text={dedupeCSVString(value)}
        emptyStub=""
      />
    ),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "total_reward",
    title: "Reward",
    getter: (row) => row.get("total_reward"),
    formatter: (value) => (value ? decimalFormatter(value, false, "$") : "No Reward"),
    filterType: FilterTypes.NUMBERS_RANGE,
  },
];

export default surveyListTableConfig;
