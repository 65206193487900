import React, { ChangeEventHandler, MouseEventHandler, ComponentProps } from "react";
import { styled } from "../../stitches.config";
import Box from "./Box";

const scaleIn = {
  opacity: 1,
  transform: "scale(1)",
};

function getInteractionCss(color: "primary" | "brand" | "accent") {
  return {
    border: `1px solid $${color}Light`,
    backgroundColor: `$${color}Lightest`,
    outline: "none",
    ...scaleIn,
  };
}

const Wrapper = styled(Box, {
  display: "flex",
  gap: "$4",
  borderRadius: "$rounded",
  padding: "$2 $3",
  variants: {
    color: {
      primary: {
        backgroundColor: "$primaryLightest",
        border: "solid 1px $primaryLight",
      },
      brand: {
        backgroundColor: "$brandLightest",
        border: "solid 1px $brandLight",
      },
    },
    disabled: {
      true: {
        backgroundColor: "$primaryLighter",
        border: "solid 1px $primaryLight",
      },
      false: {},
    },
  },
});

type WrapperProps = ComponentProps<typeof Wrapper>;

const RadioLabel = styled("label", {
  display: "flex",
  cursor: "pointer",
  flex: 1,
  justifyContent: "center",
  position: "relative",
  padding: "$2 $3",
  margin: 0,
  color: "$text",
  fontWeight: "$medium",
  fontSize: "$base",
  span: {
    position: "relative",
  },
  "input[type='radio']": {
    appearance: "none",
    borderRadius: "$rounded",
    margin: "0 !important",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    padding: "$2",
    cursor: "pointer",
    border: "1px solid transparent",
    transition: "all .3s ease",
    opacity: 0,
    transform: "scale(0.9)",
  },
  variants: {
    color: {
      primary: {
        "&:hover input[type='radio']": getInteractionCss("accent"),
        "input[type='radio']": {
          "&:hover": getInteractionCss("accent"),
          "&:focus": getInteractionCss("accent"),
          "&:checked": {
            border: "1px solid $primary",
            backgroundColor: "$white",
            ...scaleIn,
          },
        },
      },
      brand: {
        "&:hover input[type='radio']": getInteractionCss("primary"),
        "input[type='radio']": {
          "&:hover": getInteractionCss("primary"),
          "&:focus": getInteractionCss("primary"),
          "&:checked": {
            border: "1px solid $brand",
            backgroundColor: "$white",
            ...scaleIn,
          },
        },
      },
    },
    disabled: {
      true: {
        cursor: "not-allowed",
        pointerEvents: "none",
        color: "$primary",
        "input[type='radio']": {
          pointerEvents: "none",
          cursor: "not-allowed",
          border: `none`,
          "&:checked": {
            border: `none`,
            backgroundColor: `$primaryLighter`,
          },
        },
        "input[type='radio']:checked": {
          border: "1px solid $primary",
        },
        span: { pointerEvents: "none", color: "$primaryDark", cursor: "not-allowed" },
        "input[type='radio']:checked ~ span": {
          color: "$primaryDarker",
          pointerEvents: "none",
        },
      },
      false: {},
    },
  },
});

export type GroupRadioButtonsProps<T> = {
  value?: T;
  color?: "primary" | "brand";
  name: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  wrapperProps?: Omit<WrapperProps, "color">;
  buttons: {
    label: string;
    value: T;
    onClick?: MouseEventHandler<HTMLLabelElement>;
    id?: string;
  }[];
};

export function GroupRadioButtons<T extends string | number>(
  props: GroupRadioButtonsProps<T>
) {
  const {
    value,
    onChange,
    buttons,
    name,
    wrapperProps = {},
    color = "primary",
    disabled = false,
  } = props;
  return (
    <Wrapper color={color} disabled={disabled} {...wrapperProps}>
      {buttons.map((btn) => (
        <RadioLabel
          disabled={disabled}
          key={btn.id ?? btn.value}
          onClick={btn.onClick}
          color={color}
        >
          <input
            name={name}
            type="radio"
            value={btn.value}
            checked={value !== undefined ? value === btn.value : undefined}
            onChange={onChange}
            disabled={disabled}
            id={btn.id}
          />
          <span>{btn.label}</span>
        </RadioLabel>
      ))}
    </Wrapper>
  );
}
