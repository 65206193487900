// @flow
// Store used for creating a Worksheet
import { extendObservable, action, runInAction } from "mobx";
import MessageState from "../../models/MessageState";
import ProjectCostEstimateStore from "./ProjectCostEstimateStore";
import UnattachedSearchStore from "./UnattachedSearchStore";
import RateCardListStore from "./RateCardListStore";
import { browserHistory } from "react-router";
import type { FetchGraphQL, FetchAPI } from "../../App";

export default class ProjectCostEstimateCreateStore {
  router: Object;
  pcLabel: string;
  messaging: MessageState;
  pceStore: ProjectCostEstimateStore;
  searchStore: UnattachedSearchStore;
  rateCardsStore: RateCardListStore;
  punchoutArray: Array<any>;
  projectStore: ProjectCostEstimateStore;

  createProjectCostEstimate: () => void;
  createworksheet: () => void;
  changePCLabelText: (Object) => void;
  fetchGraphQL: FetchGraphQL;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI, apiServerUrl: string) {
    this.fetchGraphQL = fetchGraphQL;

    extendObservable(this, {
      apiServerUrl,
      pcLabel: "",
      messaging: new MessageState(),
      searchStore: new UnattachedSearchStore(fetchGraphQL),
      rateCardsStore: new RateCardListStore(fetchGraphQL, fetchAPI, apiServerUrl),
      pceStore: new ProjectCostEstimateStore(fetchGraphQL, fetchAPI),
      punchoutArray: [],
      projectStore: null,
    });

    this.router = null;

    this.createProjectCostEstimate = action(this.createProjectCostEstimate.bind(this));
    this.changePCLabelText = action(this.changePCLabelText.bind(this));
    this.createworksheet = action(this.createworksheet.bind(this));
  }

  // OnChange method called when searching a project
  changePCLabelText(e: Object) {
    this.pcLabel = e.target.value;
  }

  // Creating the project, pass the selected punchouts
  createProjectCostEstimate(selectedArray, pcestore) {
    if (this.searchStore.network.loading) {
      return;
    }

    // Project name is required
    this.messaging.removeAll();
    if (this.pcLabel === "") {
      this.messaging.createMessage("info", "Please enter a label for your Estimate.");
      return;
    }

    selectedArray = selectedArray.filter(
      (punchout, index, self) =>
        self.findIndex((t) => t.punchoutId === punchout.punchoutId) === index
    );

    selectedArray.forEach((punchout) => {
      if (punchout.viewState.selected) {
        this.punchoutArray.push(punchout);
      }
    });

    this.projectStore = pcestore;

    // Check if any punchout is selected
    if (this.punchoutArray.length > 0) {
      this.createworksheet(this.punchoutArray);
    } else {
      this.messaging.createMessage(
        "error",
        "Please select punchouts to add to your Estimate."
      );
      return;
    }
  }

  // GraphQL invoke for creating projects
  async createworksheet(selpunchouts) {
    if (this.searchStore.network.loading) {
      return;
    }

    let vars = {};
    vars.statsData = [];

    selpunchouts.forEach((punchout) => {
      let records = {};
      records.rateCardId = punchout.rateCardId;
      records.searchId = punchout.searchId;
      records.buyRateId = punchout.buyrateId;
      records.levelId = punchout.levelId;
      records.billableHours = 0;
      records.avgBillRate = punchout.billRateAvg;
      records.peopleNeeded = 0;
      vars.statsData.push(records);
    });

    const query = `
      mutation createProject($name: String!, $statsData: [StatsData]!) {
        createProject(input: {name: $name, statsData: $statsData}){
          project {
            projectId
            name
            projectStats {
              peopleNeeded
              projectStatId
              avgBillRate
            }
          }errors{
          __typename
          ...on NameAlreadyExistError{
            message
          }
          ...on NameEmptyError{
            message
          }
          ...on TagIdsNotExistsError{
            message
          }
         }
        }
      }
    `;

    const variables = Object.assign({ name: this.pcLabel }, vars);

    this.searchStore.network.loading = true;
    let res = null;

    // Call a GraphQL POST request for creating Worksheet
    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.searchStore.network.handleError("Creating Worksheet", e);
      return;
    }
    if (res.errors) {
      console.error("Errors", res.errors);
      this.searchStore.network.loading = false;
      return;
    }
    this.searchStore.network.loading = false;

    runInAction("createworksheet--success", () => {
      this.searchStore.network.loading = false;
      this.searchStore.network.error = null;

      if (this.searchStore.network.logGraphQLError("createworksheet", res)) {
        this.punchoutArray = [];
        this.projectStore.punchoutArrayView.forEach((punch) => {
          if (punch.viewState.selected) {
            punch.viewState.selected = false;
          }
        });

        this.punchoutArray.forEach((punchout) => {
          if (punchout.viewState.selected) {
            punchout.viewState.selected = false;
          }
        });
        if (this.messaging.messages.length > 0) {
          for (var i = 0; i < this.messaging.messages.length; i++) {
            this.messaging.removeMessage(this.messaging.messages[i]);
          }
        }
      }
      if (res.data.createProject.errors) {
        this.messaging.removeAll();
        this.messaging.createMessage("error", res.data.createProject.errors[0].message);
        return;
      }
      const projectId = res.data.createProject.project.projectId;
      browserHistory.push("/project-cost-estimate/" + projectId); // forward to the project detail page after creating a Worksheet
    });
  }
}
