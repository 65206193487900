import { useState } from "react";
import { useSetRecoilState } from "recoil";

import {
  searchAllocatorToMap,
  transformSearchAllocatorUserObject,
} from "./dataConverters";
import { useSearchAllocatorContext } from "./context/SearchAllocatorContext";
// @ts-expect-error
import { logAsyncOperationError } from "../../utils/logging";
import { useInitialFetch } from "../../utils/hooks";
import {
  searchAllocatorGlobalState,
  type SearchAllocatorGlobalState,
} from "./globalState";

import type { FetchGraphQLAPIResponse } from "../../types/fetch";
import type {
  SearchAllocatorResourceResponse,
  SearchAllocatorClientDataObject,
} from "./types";

const searchAllocatorQuery = `{
  viewer {
    user {
      client {
        resourcesUsed {
          type
          used
        }
        tiers {
          id
          amount
          resource
        }
        resourceAllocations(resourceType : SEARCH) {
          id
          balance
          resource
        }
        resourcePrices {
          id
          resource
          price
        }
        users {
          id
          username
          firstName
          lastName
          isActive
          legacyRoles {
            name
            isActive
          }
          resourceAllocations(resourceType : SEARCH) {
            id
            balance
            resource
            allocated
            searchesUsed
          }
        }
      }
    }
  }
}`;

export const useSearchAllocatorData = () => {
  const { fetchGraphQL, showModalError } = useSearchAllocatorContext();

  const setSearchAllocatorGlobalState = useSetRecoilState<SearchAllocatorGlobalState>(
    searchAllocatorGlobalState
  );

  const fetchData = async () => {
    try {
      const response: FetchGraphQLAPIResponse<SearchAllocatorResourceResponse> =
        await fetchGraphQL(searchAllocatorQuery);
      const client = response.data.data.viewer?.user?.client;
      const mappedData = searchAllocatorToMap(client);
      setSearchAllocatorGlobalState({
        searchAllocatorData: mappedData,
        searchAllocatorDataLoaded: true,
      });
    } catch (err: unknown) {
      logAsyncOperationError("useSearchAllocatorData", err);
      showModalError(`Error occurred while loading search allocator data.`);
      setSearchAllocatorGlobalState({
        searchAllocatorData: null,
        searchAllocatorDataLoaded: false,
      });
    }
  };

  useInitialFetch(fetchData);
};

const queryMutation = `mutation TransferResource($input : TrapsTransferResourceInput!)
{
  trapsTransferResource(input: $input) {
    result {
      ... on Success {
        id
      }
      ...on Error {
        error
      }
    }
  }
}`;

const searchAllocatorUsersQuery = `{
  viewer {
    user {
      client {
        users {
          id
          username
          firstName
          lastName
          isActive
          legacyRoles {
            name
            isActive
          }
          resourceAllocations(resourceType : SEARCH) {
            id
            balance
            resource
            allocated
            searchesUsed
          }
        }
      }
    }
  }
}`;

export const useMutationSearchAllocatorData = (
  source: string,
  destination: string,
  amount: number
) => {
  const { fetchGraphQL, showModalError } = useSearchAllocatorContext();
  const [isLoading, setLoading] = useState(false);
  const setSearchAllocatorData = useSetRecoilState<SearchAllocatorGlobalState>(
    searchAllocatorGlobalState
  );

  const mutationData = async () => {
    try {
      setLoading(true);
      await fetchGraphQL(queryMutation, {
        input: {
          source,
          destination,
          amount,
        },
      });

      const response: FetchGraphQLAPIResponse<SearchAllocatorResourceResponse> =
        await fetchGraphQL(searchAllocatorUsersQuery);
      const client = response.data.data.viewer?.user?.client;

      const users = client["users"].map(transformSearchAllocatorUserObject);

      setSearchAllocatorData((prevData) => ({
        ...prevData,
        searchAllocatorData: {
          ...prevData.searchAllocatorData,
          users,
        } as SearchAllocatorClientDataObject,
      }));
    } catch (err: unknown) {
      logAsyncOperationError("useMutationSearchAllocatorData", err);
      showModalError(`Error occurred while mutation search allocator data.`);
    } finally {
      setLoading(false);
    }
  };

  return { mutationData, isLoading };
};
