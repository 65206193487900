import React from "react";
import Icon from "./lib/Icon";
import Tooltip from "./lib/Tooltip";
import Text from "./lib/Text";

/**
 * TODO: set add proper Tooltip
 */
export function RoleTypeTooltip() {
  return (
    <Tooltip
      arrowProps={{ offset: 5 }}
      content={
        <Text>
          Travel workers get reimbursed for meals and lodging expenses incurred during
          their business trips.
        </Text>
      }
    >
      <span>
        <Icon css={{ cursor: "pointer" }} icon="question-circle" />
      </span>
    </Tooltip>
  );
}
