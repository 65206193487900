// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router";
import Modal from "../../ratecards/components/Modal";
import SearchList from "./components/SearchList";
import RateCardsList from "../../ratecards/components/RateCardsList";
import ContainerSection from "../../ratecards/components/ContainerSection";
import SearchBox from "../../ratecards/components/SearchBox";
import classNames from "classnames";
import ExportModal from "../../ratecards/components/ExportModal";
import CustomBuyRatesExportModal from "../../ratecards/components/CustomBuyRatesExportModal";
import { TagItemWithoutDelete } from "../../ratecards/components/TagItem";

const RateCardDetail = observer((props) => {
  const store = props.store;
  let name,
    ratecardId,
    owner,
    tagSection,
    createdDate = null;

  if (store.network.loading) {
    name = "...";
    ratecardId = "...";
    owner = "...";
    createdDate = "...";
  } else if (store.rateCard) {
    name = store.rateCard.name;
    ratecardId = store.rateCard.ratecardId;
    owner = store.rateCard.owner.fullname;
    createdDate = store.rateCard.createdDisplay;
    tagSection = store.rateCard.tags.map((tag) => (
      <TagItemWithoutDelete
        key={tag.tagId}
        tag={tag}
        onClick={(e) => {
          props.onClick(e, tag.tagId);
        }}
      />
    ));
  }

  let actionBar = null;
  if (!store.network.loading && !store.network.error)
    actionBar = (
      <nav className="action-bar flex-auto">
        {/*store.currentUser && store.currentUser.permissions.allowExportRateCardsData && <Dropdown bsStyle="default" title="Export" noCaret id="dropdown-no-caret">
          <Dropdown.Toggle noCaret>
            <FontAwesomeIcon icon="download" fixedWidth className="icon"/>
            Export ▼
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <MenuItem eventKey="1" onSelect={store.exportRateCardModal.modalState.showModal}>
              All Searches
            </MenuItem>
            {store.hasOnlyHourlySearches && <MenuItem eventKey="2" onSelect={store.customExportRateCardModal.modalState.showModal}>
              Custom Buy Rates
            </MenuItem>}
          </Dropdown.Menu>
        </Dropdown>
*/}
      </nav>
    );

  return (
    <div className="top">
      <div className="above">
        <div className="ratecard-label-header ellipsis max-width-80">
          <h2 className="ellipsis">{name}</h2>
          {/*shared && sharedByYou && <OverlayTrigger placement="top" overlay={sharedTooltip}>
            <span className="tag text-small align-top">
              <FontAwesomeIcon icon="share" fixedWidth className="icon"/>
              By You
            </span>
          </OverlayTrigger>
}
          {shared && !sharedByYou && <OverlayTrigger placement="top" overlay={sharedTooltip}>
            <span className="tag text-small align-top">
              <FontAwesomeIcon icon="share" fixedWidth flip="horizontal" className="icon"/>
              To You
            </span>
          </OverlayTrigger>
*/}
        </div>
        <nav className="back-bar">
          <Link to={`/admin/ratecards/`} className="btn btn-green">
            <FontAwesomeIcon icon="arrow-left" fixedWidth className="icon" />
            Go Back
          </Link>
          <button className="btn" onClick={store.showHelp}>
            <FontAwesomeIcon icon="question-circle" fixedWidth className="icon" />
            Help
          </button>
        </nav>
      </div>
      <div className="below">
        <div className="ratecard-meta ellipsis">
          <div className="ratecard-owner ellipsis">
            <FontAwesomeIcon icon="key" fixedWidth className="icon" /> {ratecardId}
          </div>
          <div className="ratecard-owner ellipsis">
            <FontAwesomeIcon icon="user" fixedWidth className="icon" /> {owner}
          </div>
          <div className="ratecard-created-at ellipsis">
            <FontAwesomeIcon icon="calendar-alt" fixedWidth className="icon" />{" "}
            {createdDate}
          </div>
        </div>
        {actionBar}
      </div>
      <div className="tagbox">{tagSection}</div>
    </div>
  );
});

const MoveSearchesModal = observer((props) => {
  let store = props.store;

  return (
    <Modal
      show={store.moveSearchesModal.show}
      onHide={store.moveSearchesModal.hideModal}
      innerStyle={{
        width: "100% !important",
      }}
    >
      <div className="container-section header">
        <h4>Choose Rate Card to move searches to</h4>
      </div>
      <div className="col-md-4 col-sm-6 col-xs-12">
        <SearchBox
          value={store.rateCardsListStore.rateCardLabelFilter.textToLookFor}
          onChange={store.rateCardsListStore.rateCardLabelFilter.onTextToLookForChange}
          onSearch={store.rateCardsListStore.rateCardLabelFilter.onApply}
          style={{ width: "260px" }}
        />
      </div>
      <div style={{ "overflow-x": "hidden", height: "350px" }}>
        <RateCardsList store={store.rateCardsListStore} />
      </div>
      <ContainerSection className="footer">
        <div className="pull-right">
          <button className="btn btn-lg" onClick={store.moveSearchesModal.hideModal}>
            Cancel
          </button>
          <button className="btn btn-lg btn-green" onClick={store.confirmMoveSearches}>
            Move
          </button>
        </div>
      </ContainerSection>
    </Modal>
  );
});

/**
 * RateCardDetail component for rate card detail page
 */
class Detail extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor() {
    super();
    this.deleteTag = this.deleteTag.bind(this);
  }
  /**
   * componentDidMount
   * @return {void}
   */
  componentDidMount() {
    this.props.store.rateCardDetailPTStore.rateCardId =
      this.props.router.params.rateCardId;
    this.props.store.rateCardDetailPTStore.router = this.context.router;
    this.props.store.rateCardDetailPTStore.clearFilters();
    this.props.store.rateCardDetailPTStore.applyDefaultSort();
    this.props.store.rateCardDetailPTStore.pagination.goFetch();
    //  this.props.store.savedSearchesListStore.pagination.goFetch();

    // reset to null so some elements visible while editing start hidden
    //    this.props.store.savedSearchesListStore.isEditing = null;
    //    this.props.store.savedSearchesListStore.getAllRateCardsList();

    // reset to null so some elements visible while editing start hidden
    this.props.store.rateCardDetailPTStore.isEditing = null;
  }

  deleteTag(e: object, tag_id) {}
  /**
   * Render rate card detail page
   * @return {XML}
   */
  render() {
    const store = this.props.store.rateCardDetailPTStore; //    const rateSearchStore = this.props.store.rateSearchStore; //    const savedSearchStore = this.props.store.savedSearchesListStore; //const draftSearchStore = this.props.store; //const draftsSavedSearchListStore = this.props.store.draftsSavedSearchListStore;
    /*    const rateSearchStore = this.props.store.rateSearchStore;
     */ return (
      <div className="view">
        <div className="rc-container show-overflow">
          <RateCardDetail
            store={store}
            router={this.props.router}
            onClick={this.deleteTag}
          />
        </div>
        <div className="rc-container bring-forward">
          <div className="header-action-bar">
            <h4
              className="pull-left"
              style={{
                lineHeight: "30px",
              }}
            >
              Searches on this Rate Card
            </h4>
            {!store.network.loading && !store.network.error && (
              <div className="btn-group pull-right">
                {store.isEditing && (
                  <button className="btn btn-yellow" onClick={store.handleStopEdit}>
                    Stop Editing
                  </button>
                )}
                {/*!store.isEditing && <button className="btn btn-green" onClick={store.handleStartEdit}>
                <FontAwesomeIcon icon="edit" fixedWidth className="icon"/>
                Edit
              </button>*/}
              </div>
            )}
            <div className="clearfix" />
          </div>

          {!store.network.error && (
            <div className="margin-neg-20">
              <SearchList store={store} />
            </div>
          )}

          {store.network.error && (
            <div className="pt-error-message fade-in">
              <h2>Error</h2>
              <pre>
                <code>{JSON.stringify(store.network.error, null, 2)}</code>
              </pre>
            </div>
          )}
        </div>
        <div
          className={classNames("search-edit-actions", {
            hidden: store.isEditing === null,
            "bottom-appear": store.isEditing === true,
            "bottom-disappear": store.isEditing === false,
          })}
        >
          {store.currentUser &&
            store.currentUser.permissions.allowExportRateCardsData && (
              <button
                className="btn"
                disabled={store.selectedCount === 0}
                onClick={store.exportSearchesModal.modalState.showModal}
              >
                <FontAwesomeIcon icon="download" fixedWidth className="icon" />
                Export
              </button>
            )}
        </div>
        <div
          className={classNames("rc-container-backdrop", {
            hidden: store.isEditing === null,
            "fade-in": store.isEditing === true,
            "fade-out": store.isEditing === false,
          })}
        />
        <Modal show={store.showHelpModal} onHide={store.hideHelp}>
          <div className="container-section header">
            <h4>Help</h4>
          </div>
          <div className="container-section footer">
            <p>Here you can see all the searches on this Rate Card</p>
          </div>
        </Modal>
        <MoveSearchesModal store={store} />
        {/*        <AddSearchesModal store={store} rateSearchStore={rateSearchStore} draftSearchStore={draftSearchStore} draftsSavedSearchListComponentStore = {draftsSavedSearchListStore}/>
         */}{" "}
        <ExportModal exportState={store.exportRateCardModal} />
        <ExportModal exportState={store.exportSearchesModal} />
        <CustomBuyRatesExportModal exportState={store.customExportRateCardModal} />
      </div>
    );
  }
}

export default observer(Detail);
