//@flow
import React, { Component } from "react";
import { observer } from "mobx-react";
import JobLibraryRequestList from "./JobLibraryRequestList";
import type MobXStore from "../../stores/mobx/MobXStore";
import SessionInfo from "../../models/SessionInfo";
import Inline from "../../components/lib/Inline";
import Box from "../../components/lib/Box";
import { Link } from "../../components/lib/Link";
import { styled } from "../../../src/stitches.config";

const JobLibCreateOption = styled(Link, {
  background: "#ffffff !important",
  border: "1px solid #e6e6e6",
  borderRadius: "2px",
  color: "#bcd500 !important",
  width: "276px",
  height: "188px",
  padding: "20px",
  margin: "0",
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
  justifyContent: "center",
  cursor: "pointer",
  letterSpacing: "0.2px",
  wordSpacing: "1.2px",
  textDecoration: "none",
  "&:hover": {
    border: "1px solid $brand",
    textDecoration: "none !important",
  },
});

const JobLibOptionHead = styled(Inline, {
  fontSize: "22px",
  fontWeight: "600",
  marginBottom: "10px",
  color: "$brand",
});

const JobLibOptionText = styled(Inline, {
  fontSize: "14px",
  color: "#aaaaaa",
  fontWeight: 300,
  marginBottom: "0",
});

const OptionSeparator = styled("div", {
  overflow: "visible",
  width: "1px",
  height: "200px",
  margin: "0 60px",
  borderLeft: "1px dashed #ccc",
  position: "relative",
});

const SeparatorOr = styled("span", {
  position: "absolute",
  color: "#aaa",
  backgroundColor: "#f5f5f5",
  borderRadius: "100px",
  top: "80px",
  left: "-30px",
  width: "60px",
  height: "40px",
  textAlign: "center",
  border: "1px dashed #ccc",
  lineHeight: "38px",
  fontSize: "16px",
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
});

type Props = {
  store: MobXStore,
  sessionInfo: SessionInfo,
};

class JobLibraryRequests extends Component<Props> {
  render() {
    const { client } = this.props.sessionInfo || {};
    const hasJobLibrarySubscription = client
      ? client.ratecardSubscriptionFlag || false
      : false;

    return (
      <Box css={{ width: "100%" }}>
        <Inline css={{ justifyContent: "center", marginBottom: "40px" }}>
          {!hasJobLibrarySubscription && (
            <JobLibCreateOption to={"/job-library/library-requests/create/"}>
              <JobLibOptionHead data-testid="create-library-submit-request">
                Submit Request
              </JobLibOptionHead>
              <JobLibOptionText>
                You can upload a file here so our support team can certify your job titles
                and place in a library for access.
              </JobLibOptionText>
            </JobLibCreateOption>
          )}
          {!hasJobLibrarySubscription && (
            <OptionSeparator>
              <SeparatorOr>OR</SeparatorOr>
            </OptionSeparator>
          )}
          <JobLibCreateOption to={"/job-library/create-custom/"}>
            <JobLibOptionHead data-testid="create-library-create-your-own">
              Create Your Own
            </JobLibOptionHead>
            <JobLibOptionText>
              Pick from all the job titles you have access to and create your own custom
              library.
            </JobLibOptionText>
          </JobLibCreateOption>
        </Inline>

        <div className="cjl-centered-view-container">
          {!hasJobLibrarySubscription && (
            <div className="cjl-centered-view cjl-library-detail">
              <header className="cjl-detail-name">Current Library Requests</header>
              <br />
              <JobLibraryRequestList
                store={this.props.store.jobLibraryRequestListStore}
              />
            </div>
          )}
        </div>
      </Box>
    );
  }
}

export default observer(JobLibraryRequests);
