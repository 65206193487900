import React, { useEffect, useState, useRef, useCallback } from "react";
import { Set, OrderedMap } from "immutable";

import { useProgramContext } from "../ProgramDataProvider";
import DiscoverySearchResultCounter from "../components/DiscoverySearchResultCounter";
import DiscoverySearchResultDonut from "../components/DiscoverySearchResultDonut";
import RunningProcessingsAlert from "../components/RunningProcessingsAlert";
import AdminContractorEditor from "../components/AdminContractorEditor";
import JobTitleValidationsView from "../components/JobTitleValidationsView";
import Box from "../../../components/lib/Box";
import Inline from "../../../components/lib/Inline";
import Stack from "../../../components/lib/Stack";
import Grid from "../../../components/lib/Grid";
import Alert from "../../../components/lib/Alert";
import Icon from "../../../components/lib/Icon";
import BulkUpdateButton from "../components/BulkUpdateButton";
import {
  Card,
  CardHeader,
  CardHeaderTitle,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
  CardBody,
} from "../../../components/lib/Card";
import { NavigationButton, IconButton } from "../../../components/lib/Button";
import { ButtonGroup } from "../../../components/lib/ButtonGroup";
import {
  PARAMS_FOR_BULK_UPDATE,
  PARAMS_FOR_BULK_UPDATE_TYPE,
} from "../components/AdminContractorBulkEditor";
import {
  defaultCustomConfigVisibleColumnsKeysForAdmin,
  ContractorsTable,
  ContractorsTableProps,
  groupedContractorsTableColumnsSpecs,
  TableConfigChangesObject,
  useContractorsSelectionHandlers,
  useEditingRowState,
} from "../components/ContractorsTable";
import { Checkbox, CheckboxIndicator } from "../../../components/lib/Checkbox";
import {
  transformContractorsData,
  discoverySearchResultToImmutableMap,
} from "../dataConverters";
import { Column, Group } from "../../../components/tables";
import { useDiscoverySearchContractorsTableGlobalState } from "../globalState";
import { useScrollTo } from "../hooks";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";
import { TickerContentLoader } from "../../../components/lib/TickerLoader";
import { styled } from "../../../stitches.config";
import Center from "../../../components/lib/Center";
import { renderGroupedTableColumnsSpecs } from "../components/ExtendedRestfulTables";
import {
  CONTRACTOR_STATUSES,
  ALL_TABLE_TABS_TYPES,
  CONTRACTOR_STATUSES_TYPE,
} from "../types";
import { djangoPaginationKey, djangoPaginationSizeKey, emptyMap } from "../constants";

import type {
  ContractorsTableDataProvider,
  ContractorsTableFiltersDataProvider,
  DiscoverySearchResultMap,
  DiscoverySearchResultObject,
} from "../types";
import type { ChartDataItemObject } from "../components/DiscoverySearchResultDonut";
import type {
  ContractorDataObject,
  ContractorDataMap,
  ContractorsTableDataStateObject,
} from "../types";
import type { CommonProgramChildPageProps } from "../ProgramDataProvider";
import type {
  FiltersQueryOrderedMap,
  RowEditorComponent,
} from "../../../components/tables/types";
import type { ImmutableSet } from "../../../types/immutable";
import type { FetchAPIResponse } from "../../../types/fetch";
import type { DjangoPaginatedResponse } from "../../../types/django";
import type { GroupElement } from "../../../components/tables/Schema";
import type { ContractorsTableViewProps } from "./StoredIndexCreate";
import { BulkRerunContractorsButton } from "../components/BulkRerunContractorsButton";
import BulkDeleteContractorsButton from "../components/BulkDeleteContractorsButton";
import BulkExportContractorsButton from "../components/BulkExportContractorsButton";
import BulkCreateSurveysButtons from "../components/BulkCreateSurveysButtons";
import { rowIdGetter } from "../../../components/tables/utils";
import { useRefreshRequest } from "../../../utils/hooks";
import { usePLIContext } from "../context";

const allowedStatusesForBulkUpdate = Set([
  CONTRACTOR_STATUSES.FINISHED,
]) as unknown as ImmutableSet<CONTRACTOR_STATUSES_TYPE>;

const allowedParametersForBulkUpdate = Set([
  PARAMS_FOR_BULK_UPDATE.INDUSTRY,
  PARAMS_FOR_BULK_UPDATE.EXP_LEVEL,
  // IMPORTANT: Per Marc - need more manual control on Accepted flag, should be always available
  PARAMS_FOR_BULK_UPDATE.LIMITS_ACCEPTED,
  PARAMS_FOR_BULK_UPDATE.GLOBAL_SUPPLIER_SEARCH,
  // below are all parameters available under specific data/filters conditions
  PARAMS_FOR_BULK_UPDATE.COLLECTION,
  PARAMS_FOR_BULK_UPDATE.DESCRIPTION,
  PARAMS_FOR_BULK_UPDATE.LOCATION,
  PARAMS_FOR_BULK_UPDATE.REGION,
  PARAMS_FOR_BULK_UPDATE.WORKER_TYPE,
]) as any as ImmutableSet<PARAMS_FOR_BULK_UPDATE_TYPE>;

const allowedStatusesForBulkRerun = Set([
  CONTRACTOR_STATUSES.FINISHED,
]) as unknown as ImmutableSet<CONTRACTOR_STATUSES_TYPE>;

const allowedStatusesForBulkDelete = Set([
  CONTRACTOR_STATUSES.FINISHED,
]) as unknown as ImmutableSet<CONTRACTOR_STATUSES_TYPE>;

const allowedStatusesForExport = Set([
  CONTRACTOR_STATUSES.FINISHED,
]) as unknown as ImmutableSet<CONTRACTOR_STATUSES_TYPE>;

const JobLabel = styled("label", {
  fontWeight: "$bold !important", // TODO: delete "!important" when we get rid of the parent css ".ratecards"
  color: "$ptBlack900",
});

const JobDescription = styled("pre", {
  paddingLeft: "$3_5",
  border: 0,
  borderLeft: "1px solid $primaryLight",
  backgroundColor: "white",
  fontSize: "$sm",
  color: "$ptBlack300",
  marginBottom: 0,
  fontStyle: "italic",
  whiteSpace: "pre-wrap",
});

const CSSDiscoverySearchResultCounter = {
  [`& ${Inline}:nth-child(1)`]: {
    fontSize: "$5xl",
    color: "$ptBlack900",
    fontWeight: "$semibold",
  },
  [`& ${Inline}:nth-child(2)`]: { fontSize: "$2xl" },
  [`& ${Inline}:nth-child(3)`]: { fontSize: "$lg" },
};

const DiscoverySearchContractorsTable = (props: ContractorsTableProps) => (
  <ContractorsTable {...props} />
);
DiscoverySearchContractorsTable.displayName = "DiscoverySearchContractorsTable";
DiscoverySearchContractorsTable.defaultProps = ContractorsTable.defaultProps;
DiscoverySearchContractorsTable.getTableId = (
  userId: number,
  programId: number,
  collectionId: number
): string =>
  `user-${userId}-program-${programId}-collection-${collectionId}-discovery-search-contractors-table`;

type BackToDashboardButtonProps = React.ComponentProps<typeof NavigationButton> & {
  programId: number;
};

const BackToDashboardButton = (props: BackToDashboardButtonProps) => {
  const { programId, ...rest } = props;
  const { router } = usePLIContext();

  return (
    <NavigationButton
      icon="arrow-left"
      onClick={() => {
        router.push(`/private-index/programs/${programId}/discovery-dashboard`);
      }}
      {...rest}
    >
      Back To Dashboard
    </NavigationButton>
  );
};

BackToDashboardButton.displayName = "BackToDashboardButton";

interface PageHeadBlockProps {
  programId: number;
  searchResult: DiscoverySearchResultMap;
}

const PageHeadBlock = (props: PageHeadBlockProps) => {
  const { searchResult, programId } = props;

  const jobCollectionPublicTitle =
    searchResult.get("job_collection_public_title") || searchResult.get("first_label");
  const jobCollectionPublicTitleDescription =
    searchResult.get("job_collection_public_title_description") ||
    searchResult.get("first_label_description");

  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft>
          <CardHeaderTitle>{jobCollectionPublicTitle}</CardHeaderTitle>
        </CardActionsLeft>
        <CardActionsRight>
          <BackToDashboardButton programId={programId} />
        </CardActionsRight>
      </CardActions>

      {!!jobCollectionPublicTitleDescription && (
        <CardBody>
          <JobLabel>PeopleTicker Library Job Description:</JobLabel>
          <JobDescription>{jobCollectionPublicTitleDescription}</JobDescription>
        </CardBody>
      )}
    </Card>
  );
};

PageHeadBlock.displayName = "PageHeadBlock";

type PageBottomBlockProps = {
  programId: number;
};

const PageBottomBlock = (props: PageBottomBlockProps) => {
  const { programId } = props;

  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft />
        <CardActionsRight>
          <BackToDashboardButton programId={programId} />
        </CardActionsRight>
      </CardActions>
    </Card>
  );
};

PageBottomBlock.displayName = "PageBottomBlock";

type VisualizationsBlockProps = {
  searchResult: DiscoverySearchResultMap;
  volumeData: VolumesDataState;
};

const VisualizationsBlock = (props: VisualizationsBlockProps) => {
  const { searchResult, volumeData } = props;

  const rowsCount = searchResult.get("rows_count") || 0;
  const labelsCount = searchResult.get("labels_count") || 0;
  const locationsCount = searchResult.get("locations_count") || 0;
  const regionsCount = searchResult.get("regions_count") || 0;
  const suppliersCount = searchResult.get("suppliers_count") || 0;
  const managersCount = searchResult.get("managers_count") || 0;

  const volumeDataLoaded = !!volumeData.loaded;

  return (
    <Card fill>
      <CardHeader>
        <CardHeaderTitle as="h3">Visualize Your Data</CardHeaderTitle>
      </CardHeader>

      <CardBody>
        {!volumeDataLoaded && (
          <Box css={{ minHeight: "200px" }}>
            <TickerContentLoader />
          </Box>
        )}
        {volumeDataLoaded && (
          <Grid
            css={{
              gridTemplateColumns: "minmax(0, 80%)",
              "@lg": {
                gridTemplateColumns: "minmax(0, 1fr) minmax(0, 1fr)",
              },
              gap: "$10 0",
            }}
          >
            {volumeDataLoaded && (
              <Grid
                css={{
                  gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
                  border: "1px solid $primaryLight",
                  padding: "$4 $12",
                  gap: "0 $10",
                  "@md": {
                    maxWidth: "350px",
                    marginLeft: "$10",
                  },
                }}
              >
                <DiscoverySearchResultCounter
                  css={CSSDiscoverySearchResultCounter}
                  counter={rowsCount}
                  icon={<Icon icon="list-ul" />}
                  label="Rows"
                />
                <DiscoverySearchResultCounter
                  css={CSSDiscoverySearchResultCounter}
                  counter={labelsCount}
                  icon={<Icon icon="list-alt" />}
                  label="Labels"
                />
                <DiscoverySearchResultCounter
                  css={CSSDiscoverySearchResultCounter}
                  counter={locationsCount}
                  icon={<Icon icon="map-marker" />}
                  label="Locations"
                />
                <DiscoverySearchResultCounter
                  css={CSSDiscoverySearchResultCounter}
                  counter={regionsCount}
                  icon={<Icon icon="globe-americas" />}
                  label="Regions"
                />
                <DiscoverySearchResultCounter
                  css={CSSDiscoverySearchResultCounter}
                  counter={suppliersCount}
                  icon={<Icon icon="briefcase" />}
                  label="Suppliers"
                />
                <DiscoverySearchResultCounter
                  css={CSSDiscoverySearchResultCounter}
                  counter={managersCount}
                  icon={<Icon icon="users" />}
                  label="Managers"
                />
              </Grid>
            )}
            {/* TODO redo the adaptive approach for the following donuts */}
            {volumeDataLoaded && locationsCount > 0 && (
              <DiscoverySearchResultDonut
                title="Volume By Location"
                data={volumeData.volumeByLocationData ?? []}
              />
            )}
            {volumeDataLoaded && regionsCount > 0 && (
              <DiscoverySearchResultDonut
                title="Volume By Region"
                data={volumeData.volumeByRegionData ?? []}
              />
            )}
            {volumeDataLoaded && suppliersCount > 0 && (
              <DiscoverySearchResultDonut
                title="Volume By Supplier"
                data={volumeData.volumeBySupplierData ?? []}
              />
            )}
            {volumeDataLoaded && managersCount > 0 && (
              <DiscoverySearchResultDonut
                title="Volume By Manager"
                data={volumeData.volumeByManagerData ?? []}
              />
            )}
          </Grid>
        )}
      </CardBody>
    </Card>
  );
};

VisualizationsBlock.displayName = "VisualizationsBlock";

const ContractorsTableView = (props: ContractorsTableViewProps) => {
  const {
    editingRowId,
    checkingValidationsRowId,
    contractorsData,
    contractorsDataProvider,
    contractorsFiltersDataProvider,
    //
    onStartRowEditing,
    onCancelRowEditing,
    onApplyRowUpdate,
    onSeeRowValidations,
    onCloseRowValidations,
    onDeleteRow,
    onSelectRow,
    onSelectAllRowsOnThePage,
    onChangeTableConfig,
  } = props;
  const { isSelectingRows, isBulkUpdateSelectingRows, selectedRows } = contractorsData;
  const isEditingRow = editingRowId != null;
  const isCheckingRowValidations = checkingValidationsRowId != null;

  const { router, isPTAdmin } = usePLIContext();
  const { programId, isPreviewMode } = useProgramContext();

  const scrollToRef = useScrollTo([contractorsData.activePage]);

  const renderSelectRowCheckbox = (rowData: ContractorDataMap) => {
    const rowId = rowData.get("id");

    return (
      <Center>
        <Checkbox
          checked={selectedRows.get(rowId) != null}
          onCheckedChange={(isChecked) => onSelectRow(rowData, isChecked)}
          css={{ display: "inline-flex" }}
        >
          <CheckboxIndicator>
            <Icon icon="check" />
          </CheckboxIndicator>
        </Checkbox>
      </Center>
    );
  };

  const renderSelectAllRowsCheckbox = () => {
    const selectedIds = selectedRows.keySeq().toSet();
    const availableIds = contractorsData.data.keySeq().toSet();
    const value = availableIds.isSubset(selectedIds);

    return (
      <Checkbox
        checked={value}
        onCheckedChange={onSelectAllRowsOnThePage}
        css={{ display: "inline-flex" }}
      >
        <CheckboxIndicator>
          <Icon icon="check" />
        </CheckboxIndicator>
      </Checkbox>
    );
  };

  const renderRowActions = (rowData: ContractorDataMap) => {
    const rowId = rowData.get("id");

    const handleGoToMakeSearchPage = () => {
      router.push(
        `/private-index/programs/${programId}/contractors/${rowId}/market-search`
      );
    };

    return (
      <ButtonGroup css={{ justifyContent: "center" }}>
        <IconButton
          icon="search"
          color="brand"
          variant="outlined"
          title="Show Market Search Detail"
          onClick={handleGoToMakeSearchPage}
          disabled={
            isSelectingRows ||
            isBulkUpdateSelectingRows ||
            isEditingRow ||
            isCheckingRowValidations
          }
        />
        {isPTAdmin && !isPreviewMode && (
          <IconButton
            icon="tasks"
            color="brand"
            variant="outlined"
            title="See All Relevant Validations Feedback"
            onClick={() => onSeeRowValidations(rowData)}
            disabled={
              isSelectingRows ||
              isBulkUpdateSelectingRows ||
              isEditingRow ||
              (isCheckingRowValidations && checkingValidationsRowId !== rowId)
            }
          />
        )}
        {isPTAdmin && !isPreviewMode && (
          <IconButton
            icon="wrench"
            color="brand"
            variant="outlined"
            title="Edit Mappings"
            onClick={() => onStartRowEditing(rowData)}
            disabled={
              isSelectingRows ||
              isBulkUpdateSelectingRows ||
              isCheckingRowValidations ||
              (isEditingRow && editingRowId !== rowId)
            }
          />
        )}
      </ButtonGroup>
    );
  };

  const tableGroups: GroupElement<ContractorDataMap>[] = [
    <Group key="__actions" uniqueKey="__actions" title="">
      {isPTAdmin && (isSelectingRows || isBulkUpdateSelectingRows) && (
        <Column
          uniqueKey="__selecting"
          title={renderSelectAllRowsCheckbox}
          getter={renderSelectRowCheckbox}
          fixed
        />
      )}
      <Column uniqueKey="__actions" title="Actions" getter={renderRowActions} fixed />
    </Group>,
  ].concat(renderGroupedTableColumnsSpecs(groupedContractorsTableColumnsSpecs));

  return (
    <Box ref={scrollToRef}>
      <h5>
        <small>* Table contains values in original currency</small>
      </h5>
      <DiscoverySearchContractorsTable
        isPTAdmin={isPTAdmin}
        rowIdGetter={rowIdGetter}
        editable={isPTAdmin}
        editorImpl={
          isEditingRow
            ? (AdminContractorEditor as RowEditorComponent<ContractorDataMap>)
            : isCheckingRowValidations
            ? (JobTitleValidationsView as RowEditorComponent<ContractorDataMap>)
            : undefined
        }
        selectedRowId={
          isEditingRow
            ? editingRowId
            : isCheckingRowValidations
            ? checkingValidationsRowId
            : undefined
        }
        onEditApply={isEditingRow ? onApplyRowUpdate : undefined}
        onEditCancel={
          isEditingRow
            ? onCancelRowEditing
            : isCheckingRowValidations
            ? onCloseRowValidations
            : undefined
        }
        onDeleteRow={isEditingRow ? onDeleteRow : undefined}
        multimode
        actions={ALL_TABLE_TABS_TYPES}
        customConfigVisibleColumnsKeys={
          isPTAdmin
            ? defaultCustomConfigVisibleColumnsKeysForAdmin
            : DiscoverySearchContractorsTable.defaultProps.customConfigVisibleColumnsKeys
        }
        dataProvider={contractorsDataProvider}
        filtersDataProvider={contractorsFiltersDataProvider}
        onChangeTableConfig={onChangeTableConfig}
        {...contractorsData}
      >
        {tableGroups}
      </DiscoverySearchContractorsTable>
    </Box>
  );
};

ContractorsTableView.displayName = "ContractorsTableView";

// utils

type BaseVolumeChardDataItem = {
  job_collection_id: number;
  rows_count: number;
};
type VolumeByLocationItemObject = BaseVolumeChardDataItem & {
  city: string;
  state: string;
  country: string;
  location_id: string;
};
type VolumeByRegionItemObject = BaseVolumeChardDataItem & {
  region: string;
  region_id: number;
};
type VolumeByManagerItemObject = BaseVolumeChardDataItem & {
  manager: string;
  manager_lower: string;
};
type VolumeBySupplierItemObject = BaseVolumeChardDataItem & {
  supplier: string;
  supplier_lower: string;
};
type VolumeByLocationDataArray = VolumeByLocationItemObject[];
type VolumeByRegionDataArray = VolumeByRegionItemObject[];
type VolumeByManagerDataArray = VolumeByManagerItemObject[];
type VolumeBySupplierDataArray = VolumeBySupplierItemObject[];

const volumeByLocationDataTransformer = (
  item: VolumeByLocationItemObject
): ChartDataItemObject => {
  const locationString = [item["city"], item["state"], item["country"]]
    .filter((i) => i && i)
    .join(", ");
  return {
    id: item["location_id"],
    name: locationString,
    y: item["rows_count"] || 0,
  };
};

const volumeByRegionDataTransformer = (
  item: VolumeByRegionItemObject
): ChartDataItemObject => {
  return {
    id: item["region_id"],
    name: item["region"],
    y: item["rows_count"] || 0,
  };
};

const volumeBySupplierDataTransformer = (
  item: VolumeBySupplierItemObject
): ChartDataItemObject => {
  return {
    id: item["supplier_lower"],
    name: item["supplier"],
    y: item["rows_count"] || 0,
  };
};

const volumeByManagerDataTransformer = (
  item: VolumeByManagerItemObject
): ChartDataItemObject => {
  return {
    id: item["manager_lower"],
    name: item["manager"],
    y: item["rows_count"] || 0,
  };
};

type SearchResultState = {
  data: DiscoverySearchResultMap;
  loaded: boolean;
};

type VolumesDataState = {
  volumeByLocationData: ChartDataItemObject[] | null;
  volumeByRegionData: ChartDataItemObject[] | null;
  volumeBySupplierData: ChartDataItemObject[] | null;
  volumeByManagerData: ChartDataItemObject[] | null;
  loaded: boolean;
};

const DiscoverySearchResultDetails = (props: CommonProgramChildPageProps) => {
  const {
    userId,
    isPTAdmin,
    programId,
    isPreviewMode,
    router,
    fetchM8API,
    fetchM8FilteringAPI,
    params,
    showModalError,
    showModalWarning,
  } = props;

  const collectionId = parseInt("" + params.collectionId, 10);

  // state

  const [searchResultState, setSearchResultState] = useState<SearchResultState>({
    data: emptyMap as DiscoverySearchResultMap,
    loaded: false,
  });
  const [volumesDataState, setVolumesDataState] = useState<VolumesDataState>({
    volumeByLocationData: null,
    volumeByRegionData: null,
    volumeBySupplierData: null,
    volumeByManagerData: null,
    loaded: false,
  });
  const tableId = DiscoverySearchContractorsTable.getTableId(
    userId,
    programId,
    collectionId
  );
  const [contractorsData, setContractorsDataState, resetContractorsDataState] =
    useDiscoverySearchContractorsTableGlobalState(tableId);

  // derivatives

  const {
    isSelectingRows,
    isBulkUpdateSelectingRows,
    loaded: isContractorsDataLoaded,
  } = contractorsData;
  const noContractorsData = !contractorsData.data.size && !contractorsData.filters.size;

  const baseFiltersQuery: FiltersQueryOrderedMap = React.useMemo(() => {
    return OrderedMap({
      status__in: [CONTRACTOR_STATUSES.FINISHED],
      upload__is_active: true,
      program__id: programId,
      processed__job_collection_id: collectionId,
    }) as unknown as FiltersQueryOrderedMap;
  }, [programId, collectionId]);

  const resultingFiltersQuery: FiltersQueryOrderedMap = React.useMemo(() => {
    return baseFiltersQuery.merge(contractorsData.filtersQuery);
  }, [baseFiltersQuery, contractorsData.filtersQuery]);

  // data fetch functions

  const fetchContractorsData: ContractorsTableDataProvider = useCallback(
    async (urlQuery = {}, filtersQuery = {}, nextStateUpdates = {}) => {
      try {
        const response: FetchAPIResponse<DjangoPaginatedResponse<ContractorDataObject>> =
          await fetchM8FilteringAPI(`programs/${programId}/contractors/filtered/`, {
            params: urlQuery,
            data: {
              ...baseFiltersQuery.toJS(),
              ...filtersQuery,
            },
          });
        const nextDataState: Partial<ContractorsTableDataStateObject> =
          transformContractorsData(response.data, nextStateUpdates);

        setContractorsDataState((prevDataState: ContractorsTableDataStateObject) => ({
          ...prevDataState,
          ...nextDataState,
          loaded: true,
        }));

        return nextDataState;
      } catch (err: any) {
        logAsyncOperationError("fetchContractorsList", err);
        showModalError(
          "Error occurred while retrieving contractors list. Please, try again later."
        );
        throw err;
      }
    },
    [
      programId,
      baseFiltersQuery,
      setContractorsDataState,
      fetchM8FilteringAPI,
      showModalError,
    ]
  );

  const fetchContractorsFiltersData: ContractorsTableFiltersDataProvider = useCallback(
    async (urlQuery = {}, filtersQuery = {}) => {
      try {
        const response: FetchAPIResponse<DjangoPaginatedResponse<any>> =
          await fetchM8FilteringAPI(
            `programs/${programId}/contractors/values/filtered/`,
            {
              params: urlQuery,
              data: {
                ...baseFiltersQuery.toJS(),
                ...filtersQuery,
              },
            }
          );

        return response.data;
      } catch (err: any) {
        logAsyncOperationError("fetchContractorsListFilterValues", err);
        showModalError(
          "Error occurred while retrieving filter values. Please, try again later."
        );
        throw err;
      }
    },
    [programId, baseFiltersQuery, fetchM8FilteringAPI, showModalError]
  );

  const fetchDiscoverySearchResultDetails = useCallback(async () => {
    try {
      const response: FetchAPIResponse<DiscoverySearchResultObject> = await fetchM8API(
        `programs/${programId}/discovery_search/${collectionId}/`
      );
      const data: DiscoverySearchResultMap = discoverySearchResultToImmutableMap(
        response.data
      );

      setSearchResultState((prevState) => ({
        ...prevState,
        loaded: true,
        data,
      }));

      return data;
    } catch (err: any) {
      logAsyncOperationError("fetchDiscoverySearchData", err);
      showModalError("Error occurred while retrieving discovery search details.");
    }
  }, [collectionId, programId, fetchM8API, showModalError]);

  const fetchDiscoverySearchResultVolumesData = useCallback(async () => {
    if (collectionId == null) return null;

    type VolumesResponseData = {
      volume_by_location: VolumeByLocationDataArray;
      volume_by_region: VolumeByRegionDataArray;
      volume_by_supplier: VolumeBySupplierDataArray;
      volume_by_manager: VolumeByManagerDataArray;
    };

    try {
      const response: FetchAPIResponse<VolumesResponseData> = await fetchM8API(
        `programs/${programId}/discovery_search/${collectionId}/volumes_data/`
      );

      const volumeByLocationData = (response.data["volume_by_location"] ?? []).map(
        volumeByLocationDataTransformer
      );
      const volumeByRegionData = (response.data["volume_by_region"] ?? []).map(
        volumeByRegionDataTransformer
      );
      const volumeBySupplierData = (response.data["volume_by_supplier"] ?? []).map(
        volumeBySupplierDataTransformer
      );
      const volumeByManagerData = (response.data["volume_by_manager"] ?? []).map(
        volumeByManagerDataTransformer
      );

      setVolumesDataState((prevState: VolumesDataState) => ({
        ...prevState,
        volumeByLocationData,
        volumeByRegionData,
        volumeBySupplierData,
        volumeByManagerData,
        loaded: true,
      }));
    } catch (err: any) {
      logAsyncOperationError("fetchDiscoverySearchVolumesData", err);
      showModalError("Error occurred while getting discovery search volumes data.");
    }
  }, [programId, collectionId, fetchM8API, showModalError]);

  // utility functions

  const [processingsRefreshRequestId, refreshProcessingsData] = useRefreshRequest();

  const refreshContractorsData = useCallback(async () => {
    return fetchContractorsData(
      {
        [djangoPaginationKey]: contractorsData.activePage,
        [djangoPaginationSizeKey]: contractorsData.itemsPerPage,
      },
      contractorsData.filtersQuery.toJS()
    );
  }, [
    contractorsData.activePage,
    contractorsData.itemsPerPage,
    contractorsData.filtersQuery,
    fetchContractorsData,
  ]);

  const refreshPageData = useCallback(
    async (withProcessing: boolean = true) => {
      if (isPTAdmin && withProcessing) {
        refreshProcessingsData();
      }
      fetchDiscoverySearchResultDetails();
      fetchDiscoverySearchResultVolumesData();
      return await refreshContractorsData();
    },
    [
      isPTAdmin,
      refreshContractorsData,
      fetchDiscoverySearchResultDetails,
      refreshProcessingsData,
      fetchDiscoverySearchResultVolumesData,
    ]
  );

  // effects

  const pageDataLoadedRef = useRef(false);

  useEffect(() => {
    if (!pageDataLoadedRef.current) {
      refreshPageData(false);
      pageDataLoadedRef.current = true;
    }
  }, [refreshPageData, pageDataLoadedRef]);

  useEffect(() => {
    return resetContractorsDataState;
  }, [resetContractorsDataState]);

  // handlers

  const handleChangeProcessingsNumber = useCallback(
    () => refreshPageData(false),
    [refreshPageData]
  );

  const {
    editingRowId,
    startRowEditing: handleStartRowEditing,
    stopRowEditing: handleCancelRowEditing,
  } = useEditingRowState(refreshPageData);
  const isEditingRow = editingRowId != null;

  const {
    editingRowId: checkingValidationsRowId,
    startRowEditing: handleSeeRowValidations,
    stopRowEditing: handleCloseRowValidations,
  } = useEditingRowState();

  const handleApplyRowUpdate = useCallback(
    async () => refreshPageData(),
    [refreshPageData]
  );

  const handleDeleteRow = useCallback(
    () => handleCancelRowEditing(true),
    [handleCancelRowEditing]
  );

  const {
    handleStartRowsSelection,
    handleStopRowsSelection,
    handleSelectRow,
    handleSelectAllRowsOnThePage,
  } = useContractorsSelectionHandlers(
    setContractorsDataState,
    contractorsData.selectedRows,
    contractorsData.data
  );

  const handleCreateSurveys = useCallback(() => {
    if (!contractorsData.selectedRows.size) {
      showModalWarning("Please, select some rows for validation.");
      return;
    }

    router.push(
      `/private-index/programs/${programId}/contractors/validations/create?fromPage=discovery-dashboard&collectionId=${collectionId}`
    );
  }, [
    contractorsData.selectedRows.size,
    router,
    collectionId,
    programId,
    showModalWarning,
  ]);

  const handleChangeTableConfig = React.useCallback(
    async (changes: TableConfigChangesObject) => {
      setContractorsDataState((prevState) => ({
        ...prevState,
        ...changes,
      }));
    },
    [setContractorsDataState]
  );

  const contractorsTableBlock = (
    <Card fill>
      <CardActions>
        <CardActionsLeft>
          <CardHeaderTitle as="h3">Dive Into Details</CardHeaderTitle>
        </CardActionsLeft>

        <CardActionsRight>
          {!isSelectingRows && !isBulkUpdateSelectingRows && (
            <BulkExportContractorsButton
              icon={["far", "file-excel"]}
              size="small"
              loadingText="Export to Excel"
              disabled={!isContractorsDataLoaded || noContractorsData}
              contractorsFiltersQuery={resultingFiltersQuery}
              allowedStatusesForExport={allowedStatusesForExport}
              currentPage={contractorsData.activePage}
              itemsTotal={contractorsData.itemsCount}
              itemsPerPage={contractorsData.itemsPerPage}
            >
              Export to Excel
            </BulkExportContractorsButton>
          )}
          {isPTAdmin && !isPreviewMode && !isBulkUpdateSelectingRows && (
            <BulkCreateSurveysButtons
              selectedRows={contractorsData.selectedRows}
              isSelectingRows={contractorsData.isSelectingRows}
              isEditingRow={isEditingRow}
              isContractorsDataLoaded={isContractorsDataLoaded}
              itemsTotal={contractorsData.itemsCount}
              onStartRowsSelection={() => handleStartRowsSelection("isSelectingRows")}
              onStopRowsSelection={() => handleStopRowsSelection("isSelectingRows")}
              onCreateSurveys={handleCreateSurveys}
            />
          )}

          {isPTAdmin &&
            !isPreviewMode &&
            !isSelectingRows &&
            !isBulkUpdateSelectingRows && (
              <BulkRerunContractorsButton
                icon="sync"
                size="small"
                loadingText="Rerun Index"
                disabled={
                  !isContractorsDataLoaded || !contractorsData.itemsCount || isEditingRow
                }
                allowedStatusesForRerun={allowedStatusesForBulkRerun}
                contractorsFiltersQuery={resultingFiltersQuery}
                currentPage={contractorsData.activePage}
                itemsTotal={contractorsData.itemsCount}
                itemsPerPage={contractorsData.itemsPerPage}
                itemsOnCurrentPage={contractorsData.data.size}
                onRefreshIsDone={refreshProcessingsData}
              >
                Rerun Index
              </BulkRerunContractorsButton>
            )}
          {isPTAdmin && !isPreviewMode && !isSelectingRows && (
            <BulkUpdateButton
              selectedRows={contractorsData.selectedRows}
              isBulkUpdateSelectingRows={contractorsData.isBulkUpdateSelectingRows}
              isEditingRow={isEditingRow}
              isContractorsDataLoaded={isContractorsDataLoaded}
              itemsTotal={contractorsData.itemsCount}
              allowedStatusesForUpdate={allowedStatusesForBulkUpdate}
              allowedParametersForUpdate={allowedParametersForBulkUpdate}
              contractorsFiltersQuery={contractorsData.filtersQuery}
              onStartRowsSelection={() =>
                handleStartRowsSelection("isBulkUpdateSelectingRows")
              }
              onStopRowsSelection={() =>
                handleStopRowsSelection("isBulkUpdateSelectingRows")
              }
              onUpdateIsDone={refreshPageData}
            />
          )}

          {isPTAdmin &&
            !isPreviewMode &&
            !isSelectingRows &&
            !isBulkUpdateSelectingRows && (
              <BulkDeleteContractorsButton
                icon={["far", "trash-alt"]}
                color="danger"
                size="small"
                loadingText="Bulk Delete"
                disabled={
                  !isContractorsDataLoaded || !contractorsData.itemsCount || isEditingRow
                }
                allowedStatusesForDelete={allowedStatusesForBulkDelete}
                contractorsFiltersQuery={resultingFiltersQuery}
                onDeleteIsDone={refreshPageData}
              >
                Bulk Delete
              </BulkDeleteContractorsButton>
            )}
        </CardActionsRight>
      </CardActions>

      <CardBody>
        <Stack fill css={{ alignItems: "stretch" }}>
          {isPTAdmin && (
            <RunningProcessingsAlert
              programId={programId}
              refreshRequestId={processingsRefreshRequestId}
              onChangeProcessingsNumber={handleChangeProcessingsNumber}
            />
          )}
          {!isContractorsDataLoaded ? (
            <Box css={{ minHeight: "200px" }}>
              <TickerContentLoader />
            </Box>
          ) : (
            <ContractorsTableView
              editingRowId={editingRowId}
              checkingValidationsRowId={checkingValidationsRowId}
              contractorsData={contractorsData}
              contractorsDataProvider={fetchContractorsData}
              contractorsFiltersDataProvider={fetchContractorsFiltersData}
              onStartRowEditing={handleStartRowEditing}
              onCancelRowEditing={handleCancelRowEditing}
              onApplyRowUpdate={handleApplyRowUpdate}
              onSeeRowValidations={handleSeeRowValidations}
              onCloseRowValidations={handleCloseRowValidations}
              onDeleteRow={handleDeleteRow}
              onSelectRow={handleSelectRow}
              onSelectAllRowsOnThePage={handleSelectAllRowsOnThePage}
              onChangeTableConfig={handleChangeTableConfig}
            />
          )}
        </Stack>
      </CardBody>
    </Card>
  );

  if (!searchResultState.loaded) {
    return (
      <Box css={{ minHeight: "200px" }}>
        <TickerContentLoader />
      </Box>
    );
  } else if (!searchResultState.data || !searchResultState.data.size) {
    return (
      <Alert color="warning">
        <Stack fill css={{ alignItems: "start" }}>
          <h4>Search result not found </h4>
          <BackToDashboardButton color="warning" programId={programId} />
        </Stack>
      </Alert>
    );
  }

  return (
    <Stack>
      <PageHeadBlock searchResult={searchResultState.data} programId={programId} />
      <VisualizationsBlock
        searchResult={searchResultState.data}
        volumeData={volumesDataState}
      />
      {contractorsTableBlock}
      <PageBottomBlock programId={programId} />
    </Stack>
  );
};

DiscoverySearchResultDetails.displayName = "DiscoverySearchResultDetails";

export default DiscoverySearchResultDetails;
