import React from "react";
import DocumentTitle from "react-document-title";
import { RecoilRoot } from "recoil";

import SearchAllocatorContext, {
  SearchAllocatorContextObject,
} from "./context/SearchAllocatorContext";
import { omitObjectKeys } from "../../utils/object";
import { useGlobalContext } from "../../globalContext";
import { PageContaier } from "../../components/lib/Container";

type SearchAllocatorProps = {
  children: React.ReactElement;
};

function SearchAllocator(props: SearchAllocatorProps) {
  const { children } = props;

  const globalContextValues = useGlobalContext();

  const searchAllocatorContextValues = {
    ...omitObjectKeys(globalContextValues, ["fetchAPINew", "fetchGraphQLNew"]),
    //
    fetchAPI: globalContextValues["fetchAPINew"],
    fetchGraphQL: globalContextValues["fetchGraphQLNew"],
  } as SearchAllocatorContextObject;

  return (
    <PageContaier withPaddingTop>
      <DocumentTitle title="Search Allocator" />
      <SearchAllocatorContext.Provider value={searchAllocatorContextValues}>
        <RecoilRoot>
          {React.cloneElement(React.Children.only(children), globalContextValues)}
        </RecoilRoot>
      </SearchAllocatorContext.Provider>
    </PageContaier>
  );
}

SearchAllocator.displayName = "SearchAllocator";

export default SearchAllocator;
