// @flow

import R from "ramda";
import { extendObservable, action, runInAction, computed, observable } from "mobx";
import axios from "axios";
import moment from "moment";
import PaginationState from "../../../models/PaginationState";
import NetworkState from "../../../models/NetworkState";
import CurrentUser from "../../../models/User";
import type { PageQuery, PaginationInfo } from "../../../models/PaginationState";
import CountryFilter from "../../../models/FilterState/CountryFilter";
import NegotiationWorksheet from "../../../models/NegotiationWorksheet";
import FilterObject, { FILTER_COLUMN } from "../../../models/Filter";
import {
  CreatedOnFilter,
  MarkUpOnFilter,
  RegionFilter,
  PayTypeFilter,
  TagsFilter,
  UserFilter,
  ClientUserFilter,
} from "../../../models/FilterState";
import {
  addIdToPayload,
  consolidateAppliedFilters,
  consolidateAppliedSorts,
} from "../SupportFunctions";
import ModalState from "../../../models/ModalState";
import Sort, { SORT_DIRECTION } from "../../../models/Sort";
import ApplyTagState from "../../../models/ApplyTagState";
import type { FetchGraphQL, FetchAPI } from "../../../App";

const negotiationWorksheetFilterCriteria = `
query getCountries {
 viewer {
     negotiationWorksheetFilterCriteria {
       countries(section : ADMIN) {
         country
     }
   }
 }
}
`;

const payTypeWorksheetFilterCriteria = `
query getPayTypes {
 viewer {
     negotiationWorksheetFilterCriteria {
      payTypes
   }
 }
}
`;

const tagsFilterCriteria = `
query getTags {
 viewer{
   tagsFilterCriteria{
     tagnames(contentType: NEGOTIATION_WORKSHEET, section : ADMIN){
       tag
     }
   }
 }
}
`;

const userCriteriaQuery = `
query authorList {
  viewer {
    authors(section : ADMIN) {
      edges {
        node {
          userId
          firstName
          lastName
          username
          email
        }
      }
    }
  }
}
`;

const clientUserCriteriaQuery = `
query allClients {
  viewer {
    allClients(order : [{direction : ASC, field: NAME}]) {
      edges {
        node {
          clientId
          name
        }
      }
    }
  }
}
`;

export class NegotiationWorksheetListComponentStore {
  showHelpModal: boolean;
  tagsViewState: Object;
  getWorksheets: (PageQuery) => Promise<PaginationInfo>;
  network: NetworkState;
  pagination: PaginationState;
  totalCount: any;
  negotiationList: NegotiationWorksheet[];
  negotiationListView: NegotiationWorksheet[];
  negotiationListViewState: Object;
  createdOnFilter: CreatedOnFilter;
  markUpOnFilter: MarkUpOnFilter;
  currentUser: CurrentUser;
  defaultFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedSorts: {
    [key: FilterColumn]: Sort,
  };
  isFiltered: boolean;
  appliedSortsOrder: Array<FilterColumn>;
  showHelp: () => void;
  hideHelp: () => void;
  applyDefaultFilter: (FilterColumn, FilterObject) => void;
  applyFilter: (FilterColumn, FilterObject) => void;
  removeFilter: (FilterColumn) => void;
  applySort: (FilterColumn, Sort) => void;
  removeSort: (FilterColumn) => void;
  clearFilters: () => void;
  isEditing: ?boolean;
  handleStartEdit: () => void;
  handleStopEdit: () => void;
  toggleSelectAllPage: (Object) => void;
  selectAllPage: (Event) => void;
  deselectAllPage: (Event) => void;
  clearAllSelections: () => void;
  allSelected: boolean;
  allOnPageSelected: boolean;
  allowMultipleItemSelection: boolean;
  confirmDeleteModal: ModalState;
  helpModal: ModalState;
  getSelectednegotiationList: () => Array<string>;
  getSelectedTagList: () => Array<number>;
  countryFilter: CountryFilter;
  tagsFilter: TagsFilter;
  getFilterCriteriaQuery: (FilterColumn) => GraphQLQuery;
  processFilterCriteria: (FilterColumn, Object) => Array<Object>;
  payTypeFilter: PayTypeFilter;
  negotiationFilter: RegionFilter;
  userFilter: UserFilter;
  clientUserFilter: ClientUserFilter;
  applyDefaultSort: () => void;
  unSelectedNegotiation: [];
  tagModal: ModalState;
  showTagModal: () => void;
  applyTagState: ApplyTagState;
  isTagView: ?boolean;
  setTagViewFn: () => void;
  selectAllOnPageItem: () => void;
  toDateRange: () => void;
  fetchGraphQL: FetchGraphQL;

  constructor(fetchGraphQL: FetchGraphQL) {
    this.fetchGraphQL = fetchGraphQL;
    this.getWorksheets = action(this.getWorksheets.bind(this));
    this.applyFilter = action(this.applyFilter.bind(this));
    this.applySort = action(this.applySort.bind(this));
    this.removeFilter = action(this.removeFilter.bind(this));
    this.removeSort = action(this.removeSort.bind(this));
    this.toDateRange = action(this.toDateRange.bind(this));
    this.unSelectedNegotiation = [];

    extendObservable(this, {
      helpModal: new ModalState(),
      network: new NetworkState(),
      tagNetwork: new NetworkState(),
      pagination: new PaginationState(this.getWorksheets),
      totalCount: 0,
      negotiationList: [],
      negotiationListViewState: observable.map({}),
      negotiationListView: computed(() => {
        return this.negotiationList.map((region) => {
          if (this.negotiationListViewState.has(region.regionId)) {
            region.viewState = this.negotiationListViewState.get(region.regionId);

            return region;
          }

          return region;
        });
      }),
      allOnPageSelected: computed(() => {
        const allTrue = R.all(R.equals(true));
        const selectedValues = this.negotiationListView.map(
          (negotiationListView) => negotiationListView.viewState.selected
        );

        if (selectedValues.length === 0) {
          return false;
        }

        return allTrue(selectedValues);
      }),
      appliedSortsOrder: observable.shallow([]),
      createdOnFilter: new CreatedOnFilter(
        this,
        FILTER_COLUMN.WORKSHEET_DATE,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      markUpOnFilter: new MarkUpOnFilter(
        this,
        FILTER_COLUMN.MARKUP_AMT,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      negotiationFilter: new RegionFilter(
        this,
        FILTER_COLUMN.REGION_LABEL,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      countryFilter: new CountryFilter(
        this,
        FILTER_COLUMN.WORKSHEET_COUNTRY,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      tagsFilter: new TagsFilter(
        this,
        FILTER_COLUMN.TAGS,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      userFilter: new UserFilter(
        this,
        FILTER_COLUMN.NW_USER,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      clientUserFilter: new ClientUserFilter(
        this,
        FILTER_COLUMN.NW_CLIENT_USER,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      payTypeFilter: new PayTypeFilter(
        this,
        FILTER_COLUMN.PAY_TYPE,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.removeFilter
      ),
      defaultFilters: {},
      appliedFilters: {},
      appliedSorts: {},
      isFiltered: false,
      isEditing: null,
      allowMultipleItemSelection: true,
      selectedCount: computed(() => {
        const selectedValues = this.negotiationListView.map(
          (worksheet) => worksheet.viewState.selected
        );

        if (this.allSelected) {
          return this.pagination.totalCount;
        }

        let count = 0;

        selectedValues.forEach((v) => {
          if (v) {
            count += 1;
          }
        });

        return count;
      }),
      allSelected: false,
      allSelectedfilter: false,
      confirmDeleteModal: new ModalState(),
      applyTagState: new ApplyTagState(fetchGraphQL),
      isTagView: null,
    });

    this.currentUser = null;
    this.applyDefaultFilter = action(this.applyDefaultFilter.bind(this));
    this.handleStartEdit = action(this.handleStartEdit.bind(this));
    this.handleStopEdit = action(this.handleStopEdit.bind(this));
    this.clearFilters = action(this.clearFilters.bind(this));
    this.toggleSelectAllPage = action(this.toggleSelectAllPage.bind(this));
    this.selectAllPage = action(this.selectAllPage.bind(this));
    this.deselectAllPage = action(this.deselectAllPage.bind(this));
    this.clearAllSelections = action(this.clearAllSelections.bind(this));
    this.getSelectednegotiationList = action(this.getSelectednegotiationList.bind(this));
    this.getFilterCriteriaQuery = action(this.getFilterCriteriaQuery.bind(this));
    this.processFilterCriteria = action(this.processFilterCriteria.bind(this));
    this.showHelp = action(this.showHelp.bind(this));
    this.hideHelp = action(this.hideHelp.bind(this));
    this.applyDefaultSort = action(this.applyDefaultSort.bind(this));
    this.setTagViewFn = action(this.setTagViewFn.bind(this));
    this.selectAllOnPageItem = action(this.selectAllOnPageItem.bind(this));
    this.applyDefaultSort();
  }

  setTagViewFn() {
    this.isTagView = true;
    this.isEditing = true;
  }

  toDateRange(date) {
    var val = true;
    if (this.createdOnFilter.fromDate) {
      val = !date.isBetween(
        this.createdOnFilter.fromDate,
        moment(new Date()),
        "days",
        "[]"
      );
    }
    return val;
  }

  applyDefaultSort() {
    this.createdOnFilter.sortState.direction = SORT_DIRECTION.DESC;
    this.createdOnFilter.sortState.field = "CREATE_DATE";
    this.createdOnFilter.sort = this.createdOnFilter.buildQuerySort();
    this.applySort(this.createdOnFilter.column, this.createdOnFilter.sort);
  }

  showHelp() {
    this.showHelpModal = true;
  }

  hideHelp() {
    this.showHelpModal = false;
  }

  handleStartEdit() {
    this.isEditing = true;
    this.negotiationListView.forEach((region) => region.toggleEdit());
  }

  handleStopEdit() {
    this.isEditing = false;
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.negotiationListViewState.forEach((viewState) => {
      viewState.selected = false;
      viewState.editing = false;
    });
  }

  toggleSelectAllPage(e: Object) {
    if (!this.allowMultipleItemSelection) return;

    const setValue = !this.allOnPageSelected;

    this.negotiationListView.forEach((region) => {
      region.toggleSelected(e, null, null, setValue);
    });

    // When All items selected flag is up, clear selection
    if (setValue === false && this.allSelected) this.allSelected = false;
  }

  selectAllPage(e: Event) {
    this.unSelectedNegotiation = [];
    this.allSelected = true;
    this.allSelectedfilter = true;
  }

  selectAllOnPageItem(e: Event) {
    this.negotiationListView.forEach((region) => {
      region.toggleSelected(e, null, null, true);
    });
  }

  deselectAllPage(e: Event) {
    this.negotiationListView.forEach((region) => {
      region.toggleSelected(e, null, null, false);
    });

    this.allSelected = false;
    this.allSelectedfilter = false;
  }

  clearAllSelections() {
    this.isTagView = false;
    //  this.isEditing = false;
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.negotiationListViewState.forEach((value) => {
      value.selected = false;
    });
  }

  applyFilter(column: FilterColumn, filter: FilterObject) {
    this.appliedFilters[column] = filter;
    this.isFiltered = true;
  }

  applyDefaultFilter(column: FilterColumn, filter: FilterObject) {
    this.defaultFilters[column] = filter;
  }

  removeFilter(column: FilterColumn) {
    delete this.appliedFilters[column];

    let entries = Object.entries(this.appliedFilters);
    if (!entries.length) this.isFiltered = false;
  }

  applySort(column: FilterColumn, sort: Sort) {
    this.appliedSorts[column] = sort;

    const index = this.appliedSortsOrder.indexOf(column);
    if (index === -1) this.appliedSortsOrder.push(column);
  }

  removeSort(column: FilterColumn) {
    delete this.appliedSorts[column];

    const index = this.appliedSortsOrder.indexOf(column);
    if (index > -1) this.appliedSortsOrder.splice(index, 1);
  }

  //clear all filters
  clearFilters() {
    this.countryFilter = new CountryFilter(
      this,
      FILTER_COLUMN.WORKSHEET_COUNTRY,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.tagsFilter = new TagsFilter(
      this,
      FILTER_COLUMN.TAGS,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.negotiationFilter = new RegionFilter(
      this,
      FILTER_COLUMN.REGION_LABEL,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.createdOnFilter = new CreatedOnFilter(
      this,
      FILTER_COLUMN.WORKSHEET_DATE,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.markUpOnFilter = new MarkUpOnFilter(
      this,
      FILTER_COLUMN.MARKUP_AMT,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.payTypeFilter = new PayTypeFilter(
      this,
      FILTER_COLUMN.PAY_TYPE,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.removeFilter
    );
    this.userFilter = new UserFilter(
      this,
      FILTER_COLUMN.NW_USER,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.clientUserFilter = new ClientUserFilter(
      this,
      FILTER_COLUMN.NW_CLIENT_USER,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );

    this.appliedFilters = observable({});
    this.appliedSorts = observable({});
    this.appliedSortsOrder.length = 0;
    this.isFiltered = false;
    this.applyDefaultSort();

    return this.pagination.goFetch(null);
  }

  //creating country filter query
  getFilterCriteriaQuery(column: FilterColumn): GraphQLQuery {
    switch (column) {
      case FILTER_COLUMN.WORKSHEET_COUNTRY:
        return {
          query: negotiationWorksheetFilterCriteria,
          variables: {},
        };

      case FILTER_COLUMN.PAY_TYPE:
        return {
          query: payTypeWorksheetFilterCriteria,
          variables: {},
        };

      case FILTER_COLUMN.TAGS:
        return {
          query: tagsFilterCriteria,
          variables: {},
        };

      case FILTER_COLUMN.NW_USER:
        return {
          query: userCriteriaQuery,
          variables: {},
        };

      case FILTER_COLUMN.NW_CLIENT_USER:
        return {
          query: clientUserCriteriaQuery,
          variables: {},
        };

      default:
        return null;
    }
  }

  //process country filter result data
  processFilterCriteria(column: FilterColumn, payload: Object): Array<any> {
    switch (column) {
      case FILTER_COLUMN.WORKSHEET_COUNTRY:
        const countries: [
          {
            country: string,
          }
        ] = payload.data.viewer.negotiationWorksheetFilterCriteria.countries;
        let processedCountry = observable.map({});
        countries.forEach((country, index) => {
          processedCountry.set(String(index), {
            id: String(index),
            ...country,
          });
        });
        return processedCountry;

      case FILTER_COLUMN.PAY_TYPE:
        const payTypes: [String] =
          payload.data.viewer.negotiationWorksheetFilterCriteria.payTypes;
        return addIdToPayload(payTypes);

      case FILTER_COLUMN.TAGS:
        const tags: [
          {
            tag: string,
          }
        ] = payload.data.viewer.tagsFilterCriteria.tagnames;
        let processedTag = observable.map({});
        tags.forEach((tag, index) => {
          processedTag.set(String(index), {
            id: String(index),
            ...tag,
          });
        });
        return processedTag;
      case FILTER_COLUMN.NW_USER:
        const users: [
          {
            userId: string,
            username: string,
          }
        ] = payload.data.viewer.authors.edges;
        let processedUsers = observable.map({});
        users.forEach((owner) => {
          processedUsers.set(String(owner.node.userId), {
            id: String(owner.node.userId),
            ...owner,
          });
        });

        return processedUsers;

      case FILTER_COLUMN.NW_CLIENT_USER:
        const clientowners: [
          {
            clientId: string,
            name: string,
          }
        ] = payload.data.viewer.allClients.edges;
        let processedClientOwners = observable.map({});
        clientowners.forEach((owner) => {
          processedClientOwners.set(owner.node.clientId, {
            id: owner.node.clientId,
            ...owner,
          });
        });

        return processedClientOwners;

      default:
        return null;
    }
  }

  toggleAllItems() {
    if (!this.allowMultipleItemSelection) return;

    this.allSelected = !this.allSelected;

    if (this.allSelected === false) {
      this.projectCostsView.forEach((value) => {
        value.viewState.selected = false;
      });
    }
  }

  getSelectednegotiationList(): Array<string> {
    const negotiationList = this.negotiationListViewState;

    let selectednegotiationList = [];

    negotiationList.forEach((value, key) => {
      if (value.selected) {
        selectednegotiationList.push(key);
      }
    });

    return selectednegotiationList;
  }

  //function for get worksheet list
  async getWorksheets(pageQuery: PageQuery): Promise<PaginationInfo> {
    let res = null;
    let params: string[] = pageQuery.params;
    let args = pageQuery.args;
    let variables = pageQuery.variables;
    let filtersCriteria: string[] = [];
    let sortCriteria: string[] = [];

    consolidateAppliedSorts(this.appliedSorts, sortCriteria);

    consolidateAppliedFilters(this.appliedFilters, params, filtersCriteria, variables);

    // NOTE: applied filters could override default filters, handle this if needed
    consolidateAppliedFilters(this.defaultFilters, params, filtersCriteria, variables);

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");
    const queryFiltersCriteria = filtersCriteria.join(", ");
    const querySortCriteria = sortCriteria.join(", ");

    const query = `
      query worksheetlist(${queryParams}){
        viewer{
          user{
            firstName
            lastName
            userId
            username
            email
          }
          negotiationWorksheets(${queryArgs},filters: { ${queryFiltersCriteria} }, order: [${querySortCriteria}], section: ADMIN){
            totalCount
            edges{
              node{
                worksheetId
                worksheetName
                created
                totalMarkupPercent
                payType
                createdBy {
                  firstName
                  lastName
                  email
                  userId
                }
                country {
                  name
                }
                tags{
                  name
                  tagId
                }
              }
            }
          }
          }
        }
      `;

    this.network.loading = true;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting Worksheets", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Worksheets query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getWorksheets--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get worksheet query", res)) {
        // TODO: Display user friendly error message
        return {
          totalCount: 0,
        };
      }

      this.currentUser = new CurrentUser(this, res.data.viewer.user);
      const worksheets = res.data.viewer.negotiationWorksheets.edges;
      this.totalCount = res.data.viewer.negotiationWorksheets.totalCount;
      // // TODO: Deserialize this properly...
      this.negotiationList = worksheets.map((work) => {
        const worksheet = new NegotiationWorksheet(this, work.node);

        if (!this.negotiationListViewState.has(worksheet.worksheetId)) {
          this.negotiationListViewState.set(worksheet.worksheetId, {
            selected: this.allSelected,
            editing: this.isEditing,
          });
        } else {
          const selectedValue = this.allSelected
            ? true
            : this.negotiationListViewState.get(worksheet.worksheetId).selected;

          this.negotiationListViewState.set(worksheet.worksheetId, {
            selected: selectedValue,
            editing: this.isEditing,
          });
        }

        worksheet.viewState = this.negotiationListViewState.get(worksheet.worksheetId);

        return worksheet;
      });
      return {
        totalCount: res.data.viewer.negotiationWorksheets.totalCount,
      };
    });
  }
}

export default class NegotiationWorksheetListStore extends NegotiationWorksheetListComponentStore {
  selectWorksheets: () => void;
  exportNegotiationWorksheets: () => void;
  fetchAPI: FetchAPI;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI) {
    super(fetchGraphQL);
    this.fetchAPI = fetchAPI;

    this.selectWorksheets = action(this.selectWorksheets.bind(this));
    this.exportNegotiationWorksheets = action(
      this.exportNegotiationWorksheets.bind(this)
    );
  }

  selectWorksheets(type) {
    if (this.network.loading) {
      return;
    }

    // let params = [];
    //let args = [];
    //let vars = {};
    let searchCriteria = "";
    const selectednegotiationList = this.getSelectednegotiationList();

    if (!selectednegotiationList) {
      console.error("Cannot " + type + " jobs: No Worksheets selected");
      return;
    }
    searchCriteria = {
      worksheetId: selectednegotiationList,
    };

    return searchCriteria;
  }

  exportNegotiationWorksheets() {
    if (this.network.loading) {
      return;
    }

    let params = [];
    let args = [];
    let vars = {};
    let exportURL = "negotiation_worksheets/basic/list/export/excel/";
    // Change the Filename
    var fileName =
      "export_negotiation_worksheets_" + Math.floor(Math.random() * 9999999 + 1000000);
    let parameters = "";
    let exparams = "";

    if (this.allSelectedfilter) {
      consolidateAppliedFilters(this.appliedFilters, params, args, vars);
      vars.exclude = [];
      parameters = JSON.stringify(vars);
      if (parameters === "") {
        exparams = `{"fileName": "${fileName}","section":"admin", "worksheetFilters": {${parameters}}}`;
      } else {
        exparams = `{"fileName": "${fileName}","section":"admin", "worksheetFilters": ${parameters}}`;
      }
    } else {
      const selectedNegotiations = this.getSelectednegotiationList();
      if (!selectedNegotiations || !selectedNegotiations.length) {
        console.error("Cannot export Worksheet: No Worksheet selected");
        return;
      }
      vars.only = selectedNegotiations;
      parameters = JSON.stringify(vars);
      if (parameters === "") {
        exparams = `{"fileName": "${fileName}","section":"admin", "worksheetFilters": {${parameters}}}`;
      } else {
        exparams = `{"fileName": "${fileName}","section":"admin","worksheetFilters": ${parameters}}`;
      }
    }

    this.fetchAPI(exportURL, JSON.parse(exparams))
      .then((res) => {
        window.location.href = res.data.url;
        this.handleStopEdit();
      })
      .catch((e) => {
        console.error("Error downloading excel", e);
        throw e;
      });
  }
}
