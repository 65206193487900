// @flow
import * as React from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "./components/Modal";
import Loadable from "./components/Loadable";
import InstantSearchBox from "./components/InstantSearchBox";
import Info from "./components/Info";
import ContainerSection from "./components/ContainerSection";
import RateCardsList from "./components/RateCardsList";
import ExportModal from "./components/ExportModal";
import SelectableItemsList from "./components/SelectableItemsList";
import SelectableItem from "./components/SelectableItem";
import RateCardsListHelpModal from "./components/RateCardsListHelpModal";
import MobXStore from "../../stores/mobx/MobXStore";
import { FILE_TYPE, EXPORT_TYPE } from "../../models/ExportOptions";
import ApplyTagFilterModal from "../shared/modals/ApplyTagFilterModal";
import ErrorModal from "../../components/modals/ErrorModal";
import Container from "../../components/lib/Container";
import {
  Card,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
  CardHeader,
  CardHeaderSubTitle,
  CardHeaderTitle,
} from "../../components/lib/Card";
import Button from "../../components/lib/Button";
import LinkButton from "../../components/lib/LinkButton";
import SearchBox from "../../components/lib/SearchBox";
import EditActions from "../../components/lib/EditActions";
import { ModalButtons, ModalTitle } from "./components/FilterModal";
import TextInput from "../../components/lib/TextInput";
import Inline from "../../components/lib/Inline";
import Spacer from "../../components/lib/Spacer";

/**
 * ShareWithUsersList - user list for share by filter type
 * @return {void}
 */
const ShareWithUsersList = observer((props) => {
  return (
    <div>
      <SelectableItemsList>
        {props.filterState.shareUsersView.map((user, i) => (
          <SelectableItem
            key={user.username}
            item={user}
            name="share-user"
            value={user.username}
            selected={user.selected}
            onChange={props.filterState.setSharedUserSelectedValue}
          >
            <div>
              {user.firstName} {user.lastName}
            </div>
            <div className="text-x-small">
              <FontAwesomeIcon icon="user" className="icon no-margin" />
              {user.username}
            </div>
          </SelectableItem>
        ))}
      </SelectableItemsList>
      <ContainerSection className="info">
        <p className="text-muted text-x-small no-margin pull-left">
          <FontAwesomeIcon icon="info-circle" fixedWidth className="icon" />
          <em> Stop sharing by leaving all Users unselected.</em>
        </p>
        <p className="text-muted text-x-small no-margin no-border pull-right">
          <em>
            <strong>{props.filterState.shareUsersSelectedCount} </strong>selected,{" "}
            <strong>{props.filterState.shareUsersTotalCount} </strong>total
          </em>
        </p>
        <div className="clearfix" />
      </ContainerSection>
    </div>
  );
});

type Props = {
  store: MobXStore,
  router: Object,
};

class List extends React.Component<Props, null> {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.store.rateCardListStore.updateRequestFrom("ratecard");
    this.props.store.rateCardListStore.pagination.goFetch();

    // reset to null so some elements visible while editing start hidden
    if (!this.props.store.rateCardListStore.isTagView) {
      this.props.store.rateCardListStore.isEditing = null;
    }
    // clean rate card details view state
    this.props.store.rateCardDetailStore.rateCardId = null;
    this.props.store.rateCardDetailStore.rateCard = null;
  }

  // componentWillUnmount() {
  //   this.props.store.rateCardListStore.clearFilters();
  //   this.props.store.rateCardListStore.clearAllSelections();
  //   this.props.store.rateCardListStore.applyDefaultSort();
  // }

  render() {
    const store = this.props.store.rateCardListStore;

    return (
      <Container
        css={{
          paddingTop: "$4",
          paddingLeft: 0,
          paddingRight: 0,
          width: "100%",
        }}
      >
        <Card css={{ zIndex: 2 }}>
          {!store.isTagView && (
            <CardHeader>
              <CardHeaderTitle>Rate Cards</CardHeaderTitle>
              <CardHeaderSubTitle>View and manage your rate cards.</CardHeaderSubTitle>
            </CardHeader>
          )}
          <div className="header-action-bar marginTopzero">
            <CardActions>
              <CardActionsLeft>
                <SearchBox
                  value={store.rateCardLabelFilter.textToLookFor}
                  onChange={store.rateCardLabelFilter.onTextToLookForChange}
                  onSubmit={store.rateCardLabelFilter.onApply}
                  style={{ width: "260px" }}
                />
              </CardActionsLeft>
              {!store.isTagView && (
                <CardActionsRight>
                  {store.isEditing && (
                    <Button color="accent" onClick={store.handleStopEdit}>
                      Stop Editing
                    </Button>
                  )}
                  {!store.isEditing && (
                    <>
                      <Button color="brand" icon="edit" onClick={store.handleStartEdit}>
                        Edit
                      </Button>
                      <LinkButton color="brand" icon="plus" to="/ratecards/create">
                        New Rate Card
                      </LinkButton>
                      <Button icon="question-circle" onClick={store.helpModal.showModal}>
                        Help
                      </Button>
                    </>
                  )}
                </CardActionsRight>
              )}
            </CardActions>
          </div>
          <RateCardsList store={store} router={this.props.router} />
        </Card>
        {!store.isTagView && (
          <EditActions hidden={store.isEditing === null} show={Boolean(store.isEditing)}>
            {store.hasOwnership && (
              <Button
                icon="edit"
                disabled={store.selectedCount !== 1}
                onClick={store.showRenameRateCardModal}
              >
                Rename
              </Button>
            )}
            {store.hasOwnership && localStorage.getItem("shareRateCards") !== "false" && (
              <Button
                icon="share"
                disabled={store.selectedCount !== 1}
                onClick={store.share}
                data-testid="share-button"
              >
                Share
              </Button>
            )}
            {store.hasOwnership && (
              <Button
                icon="sync"
                disabled={store.selectedCount !== 1}
                onClick={store.confirmUpdateRatesModal.showModal}
              >
                Update Rates
              </Button>
            )}
            {store.hasOwnership && (
              <Button
                icon="undo"
                disabled={store.selectedCount !== 1}
                onClick={store.confirmUndoUpdateRatesModal.showModal}
              >
                Undo Last Update
              </Button>
            )}
            {store.canExport() && (
              <Button
                icon="download"
                disabled={store.selectedCount === 0}
                onClick={() => {
                  store.exportModal.disableFileTypeOptions = store.selectedCount > 1;
                  store.exportModal.exportOptions.fileType = FILE_TYPE.SPREADSHEET;

                  store.exportModal.disableExportTypeOptions = store.selectedCount > 1;
                  store.exportModal.exportOptions.exportType =
                    store.selectedCount > 1 ? EXPORT_TYPE.LIST : EXPORT_TYPE.DETAILED;

                  store.exportModal.info =
                    store.selectedCount > 1
                      ? "Options not available for multiple Rate Cards. Exported file will be an excel with the list of selected items."
                      : null;

                  store.exportModal.modalState.showModal();
                }}
              >
                Export
              </Button>
            )}
            {store.hasOwnership && (
              <Button
                disabled={store.selectedCount === 0}
                onClick={store.applyTagState.showTagModal}
              >
                Apply Tag
              </Button>
            )}
            {store.hasOwnership && (
              <Button
                color="danger"
                icon="trash-alt"
                disabled={store.selectedCount === 0}
                onClick={store.confirmDeleteModal.showModal}
              >
                Delete
              </Button>
            )}
          </EditActions>
        )}

        <RateCardsListHelpModal rateCardListStore={store} />
        <Modal
          show={store.renameRateCardModal.show}
          onHide={store.renameRateCardModal.hideModal}
        >
          <ContainerSection className="header">
            <ModalTitle>Rename Rate Card</ModalTitle>
          </ContainerSection>
          <ContainerSection>
            <p>Name of the Rate Card:</p>
            <TextInput
              style={{ width: "100%" }}
              value={store.newRateCardName}
              maxLength="250"
              onChange={store.onNewRateCardNameChange}
              data-testid="rename-field"
            />
            <div className="pt-user-messages">
              {store.renameMessaging.messages.length > 0 && (
                <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                  <a
                    href="#close"
                    className="close"
                    data-dismiss="alert"
                    aria-label="close"
                    onClick={store.renameMessaging.removeMessage.bind(
                      store,
                      store.renameMessaging.messages[0]
                    )}
                  >
                    &times;
                  </a>
                  <strong>{store.renameMessaging.messages[0].messageLabel}</strong>
                  {store.renameMessaging.messages[0].message}
                </div>
              )}
            </div>
          </ContainerSection>
          <ContainerSection className="footer">
            <ModalButtons>
              <Button color="brand" size="large" onClick={store.renameRateCard}>
                Rename Rate Card
              </Button>
              <Button
                size="large"
                onClick={() => {
                  store.renameMessaging.removeAll();
                  store.renameRateCardModal.hideModal();
                }}
              >
                Cancel
              </Button>
            </ModalButtons>
          </ContainerSection>
        </Modal>
        <Modal
          show={store.shareModal.show}
          onHide={store.shareModal.hideModal}
          innerStyle={{ width: 550 }}
        >
          <ContainerSection className="header">
            <ModalTitle>Share Rate Cards</ModalTitle>
          </ContainerSection>
          <Loadable isLoading={store.networkShareUsers.loading}>
            <ContainerSection className="container-section no-border">
              <p>Choose Users to share with:</p>
              <Inline>
                <InstantSearchBox
                  onSearch={store.shareUsersOnInstantSearch}
                  value={store.shareUsersInstantSearchValue}
                />
                <Spacer />
                <Button onClick={store.shareUsersOnSelectAll}>Select All</Button>
                <Button onClick={store.shareUsersOnDeselectAll}>Deselect All</Button>
              </Inline>
            </ContainerSection>
            <ShareWithUsersList filterState={store} />
            <ContainerSection className="footer">
              <ModalButtons>
                <Button color="brand" size="large" onClick={store.performShare}>
                  Share With Selected Users
                </Button>
                <Button size="large" onClick={store.shareModal.hideModal}>
                  Cancel
                </Button>
              </ModalButtons>
            </ContainerSection>
          </Loadable>
        </Modal>
        <Modal
          show={store.confirmUpdateRatesModal.show}
          onHide={store.confirmUpdateRatesModal.hideModal}
        >
          <div className="container-section header">
            <ModalTitle>Confirmation Needed</ModalTitle>
          </div>
          <div className="container-section">
            <p>
              Are you sure you want to update the rates of all Searches in the selected
              Rate Cards?
            </p>
          </div>
          <div className="container-section footer">
            <ModalButtons>
              <Button
                color="brand"
                size="large"
                onClick={(e) => {
                  store.confirmUpdateRatesModal.hideModal();
                  store.refreshRateCards();
                }}
              >
                Yes, Update Rate Cards
              </Button>
              <Button size="large" onClick={store.confirmUpdateRatesModal.hideModal}>
                No, Cancel
              </Button>
            </ModalButtons>
          </div>
        </Modal>
        <Modal
          show={store.confirmUndoUpdateRatesModal.show}
          onHide={store.confirmUndoUpdateRatesModal.hideModal}
        >
          <div className="container-section header">
            <ModalTitle>Confirmation Needed</ModalTitle>
          </div>
          <div className="container-section">
            <p>
              Are you sure you want to undo the last <em>"Update Rates"</em> action on all
              Searches in the selected Rate Cards?
            </p>
            <Info icon="info-circle" iconClass="icon text-muted text-x-small">
              <p className="text-muted text-x-small">
                <em>
                  This action is only effective once after each "Update Rates" action.
                  Searches that have already been reverted to it's previous state will not
                  be affected.
                </em>
              </p>
            </Info>
          </div>
          <div className="container-section footer">
            <ModalButtons>
              <Button
                color="brand"
                size="large"
                onClick={(e) => {
                  store.confirmUndoUpdateRatesModal.hideModal();
                  store.undoLastUpdateRateCards();
                }}
              >
                Yes, Undo Last Update
              </Button>
              <Button size="large" onClick={store.confirmUndoUpdateRatesModal.hideModal}>
                No, Cancel
              </Button>
            </ModalButtons>
          </div>
        </Modal>
        <Modal
          show={store.confirmDeleteModal.show}
          onHide={store.confirmDeleteModal.hideModal}
        >
          <div className="container-section header">
            <ModalTitle>Confirmation Needed</ModalTitle>
          </div>
          <div className="container-section">
            <p>Are you sure you want to delete the selected Rate Cards?</p>
            <p>This will also delete all Punchouts in these Rate Cards.</p>
            <p className="text-danger">
              <FontAwesomeIcon icon="exclamation-triangle" />{" "}
              <em>This action cannot be undone!</em>
            </p>
          </div>
          <div className="container-section footer">
            <ModalButtons>
              <Button
                color="danger"
                size="large"
                onClick={() => {
                  store.confirmDeleteModal.hideModal();
                  store.deleteRateCards();
                }}
              >
                Yes, Delete Rate Cards
              </Button>
              <Button size="large" onClick={store.confirmDeleteModal.hideModal}>
                No, Cancel
              </Button>
            </ModalButtons>
          </div>
        </Modal>
        <ExportModal exportState={store.exportModal} />
        <ApplyTagFilterModal
          filterState={store.applyTagState}
          applyTagsEvent={store.applyTags}
          btnText="Apply Tag to Ratecards"
        />
        <ErrorModal modalState={store.errorModal} errorMessage={store.errorMessage} />
      </Container>
    );
  }
}

export default observer(List);
