// @flow
import ProjectCostEstimates from "../../models/ProjectCostEstimate";
import { extendObservable, action, runInAction, observable } from "mobx";
import CurrentUser from "../../models/User";
import NetworkState from "../../models/NetworkState";
import axios from "axios";
import ModalState from "../../models/ModalState";
import MessageState from "../../models/MessageState";
import ApplyTagState from "../../models/ApplyTagState";
import type ProjectCostEstimateStore from "./ProjectCostEstimateStore";
import type { FetchGraphQL, FetchAPI } from "../../App";
import TagList from "../../models/TagList";

export default class ProjectCostEstimateDetailStore {
  currentUser: CurrentUser;
  showHelpModal: boolean;
  showRateCardModal: boolean;
  projectId: ?number;
  project: ?ProjectCostEstimates;
  projectStats: Array<Object>;
  network: NetworkState;
  getProjectDetails: () => void;
  showHelp: () => void;
  hideHelp: () => void;
  showRateCard: () => void;
  hideRateCard: () => void;
  changePeopleNeededForPunchout: () => void;
  changeBillRates: () => void;
  onFocusOutFn: () => void;
  onFocusOutPeopleNeeded: () => void;
  onFocusOutBillableHours: () => void;
  changeBillableHoursForPunchout: () => void;
  removePunchout: () => void;
  updateProjectStats: () => void;
  exchangeRates: ?{
    rates?: {
      USD: number,
      [string]: number,
    },
  };
  getExchangeRates: (void) => Promise<any>;

  renameProjectModal: ModalState;
  exportProjectModal: ModalState;
  newProjectName: string;
  showRenameProjectModal: () => void;
  showExportModal: () => void;
  renameProject: () => void;
  updateProject: () => void;
  onNewProjectNameChange: (Event) => void;
  changeExportFileName: (Object) => void;
  export: () => void;

  confirmDeleteModal: ModalState;

  selectedExcel: boolean;
  selectedCSV: boolean;
  exportFileName: string;
  exportType: string;
  messaging: MessageState;
  applyTags: () => void;
  applyTagState: ApplyTagState;
  deleteSingleTags: () => void;
  selectedTags: Object;
  fetchGraphQL: FetchGraphQL;
  fetchAPI: FetchAPI;

  removeTags: (SyntheticEvent<HTMLElement>, number[]) => Promise<any>;
  removeTag: (SyntheticEvent<HTMLElement>, number) => Promise<any>;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI) {
    this.fetchGraphQL = fetchGraphQL;
    this.fetchAPI = fetchAPI;
    this.getProjectDetails = action(this.getProjectDetails.bind(this));
    this.showHelp = action(this.showHelp.bind(this));
    this.hideHelp = action(this.hideHelp.bind(this));
    this.showRateCard = action(this.showRateCard.bind(this));
    this.hideRateCard = action(this.hideRateCard.bind(this));

    this.onNewProjectNameChange = action(this.onNewProjectNameChange.bind(this));
    this.showRenameProjectModal = action(this.showRenameProjectModal.bind(this));
    this.showExportModal = action(this.showExportModal.bind(this));
    this.renameProject = action(this.renameProject.bind(this));
    this.changePeopleNeededForPunchout = action(
      this.changePeopleNeededForPunchout.bind(this)
    );
    this.changeBillRates = action(this.changeBillRates.bind(this));
    this.onFocusOutFn = action(this.onFocusOutFn.bind(this));
    this.onFocusOutPeopleNeeded = action(this.onFocusOutPeopleNeeded.bind(this));
    this.onFocusOutBillableHours = action(this.onFocusOutBillableHours.bind(this));
    this.changeBillableHoursForPunchout = action(
      this.changeBillableHoursForPunchout.bind(this)
    );
    this.removePunchout = action(this.removePunchout.bind(this));
    this.updateProject = action(this.updateProject.bind(this));
    this.changeExportFileName = action(this.changeExportFileName.bind(this));
    this.handleExcelTypeChange = action(this.handleExcelTypeChange.bind(this));
    this.export = action(this.export.bind(this));
    this.updateProjectStats = action(this.updateProjectStats.bind(this));
    this.applyTags = action(this.applyTags.bind(this));
    this.deleteSingleTags = action(this.deleteSingleTags.bind(this));
    this.removeTags = action(this.removeTags.bind(this));
    this.removeTag = action(this.removeTag.bind(this));
    this.getExchangeRates = action(this.getExchangeRates.bind(this));

    extendObservable(this, {
      exportFileName: "exported_pce_" + Math.floor(Math.random() * 9999999 + 1000000), // Default name for  exporting file
      exportType: "excel", // Default export type
      showHelpModal: false, // Don't display the help modal on page load
      showRateCardModal: false,
      network: new NetworkState(),
      projectId: null,
      project: null,

      // Rename Project
      newProjectName: "",
      renameProjectModal: new ModalState(), // Rename project modal
      exportProjectModal: new ModalState(), // Export project modal
      projectStats: [],
      exchangeRates: null,
      selectedExcel: true, // Default Export Type selected
      selectedCSV: false,
      messaging: new MessageState(),
      applyTagState: new ApplyTagState(fetchGraphQL, this),
      // selectedTags: computed(() => {
      //   const selectedTags = observable.map({});
      //   if (!this.project) return selectedTags;
      //   if (this.project.tags.length === 0) return selectedTags;
      //
      //   this.project.tags.forEach((tag: TagList) => {
      //     selectedTags.set(tag.tagId, tag);
      //   });
      //
      //   return selectedTags;
      // })
    });
  }

  canExport() {
    const exportRole = "No Export Allowed";
    if (
      this.currentUser &&
      this.currentUser.roles &&
      this.currentUser.roles.indexOf(exportRole) > -1
    ) {
      return false;
    }
    return true;
  }

  // method for changing Exported filename
  changeExportFileName(e: Object) {
    this.exportFileName = e.target.value;
  }

  // Select the export type
  handleExcelTypeChange(value: string) {
    this.selectedCSV = !this.selectedCSV;
    this.selectedExcel = !this.selectedExcel;
    this.exportType = value;
  }

  // Method for exporting the project
  export() {
    if (!this.projectId) {
      throw new Error("No projectId set");
    }
    var url = `projects/${this.projectId}/export/${this.exportType}/`;

    return this.fetchAPI(url, {
      fileName: this.exportFileName,
    })
      .then((res) => {
        window.location.href = res.data.url;
      })
      .catch((e) => {
        console.error("Error downloading excel", e);
        throw e;
      });
  }

  showHelp() {
    this.showHelpModal = true;
  }

  hideHelp() {
    this.showHelpModal = false;
  }

  showRateCard(pceStore: ProjectCostEstimateStore, projectStats) {
    pceStore.handlePunchoutEditStop();
    pceStore.selectedProjectStats(projectStats);
    this.showRateCardModal = true;
  }

  hideRateCard() {
    this.showRateCardModal = false;
  }

  // Change the project name
  onNewProjectNameChange(e: Event) {
    this.newProjectName = e.target.value;
  }

  showRenameProjectModal() {
    this.messaging.removeAll();
    this.newProjectName = this.project.name;
    this.renameProjectModal.showModal();
  }

  showExportModal() {
    this.exportProjectModal.showModal();
  }

  // update the people required
  changePeopleNeededForPunchout(e: Event, punchout) {
    for (var i = this.projectStats.length - 1; i >= 0; i--) {
      if (this.projectStats[i].projectStatId === punchout.projectStatId) {
        const re = /^[0-9]+?$/;
        if (e.target.value === "" || re.test(e.target.value)) {
          this.projectStats[i].peopleNeeded = e.target.value;
          var id = "peopleNeeded" + punchout.projectStatId;
          document.getElementById(id).value = e.target.value;
        } else return false;
      }
    }
  }

  // update bill rates
  changeBillRates(e: Event, punchout) {
    for (var i = this.projectStats.length - 1; i >= 0; i--) {
      if (this.projectStats[i].projectStatId === punchout.projectStatId) {
        var value;
        const re = /^[0-9]+(\.[0-9]+)?$/; // regex for allowing positive integers before and after decimal
        const regex = /^(\d+\.)$/; // regex allowing nothing after a decimal
        const regex2 = /^\.$/; // regex if only . is provided
        var id = "billrate" + punchout.projectStatId;
        if (
          e.target.value.startsWith("0") &&
          !e.target.value.startsWith("0.") &&
          re.test(e.target.value)
        ) {
          value = Number(e.target.value);
        } else if (
          e.target.value === "" ||
          re.test(e.target.value) ||
          regex.test(e.target.value)
        ) {
          value = e.target.value;
        } else if (regex2.test(e.target.value)) {
          // Add 0 before decimal in this situation
          value = 0 + e.target.value;
        } else return false;

        if (e.target.value.indexOf(".") > -1) {
          if (e.target.value.length - e.target.value.indexOf(".") - 1 <= 2) {
            this.projectStats[i].avgBillRate = value;
            document.getElementById(id).value = value;
          } else {
            return false;
          }
        } else {
          this.projectStats[i].avgBillRate = value;
          document.getElementById(id).value = value;
        }
      }
    }
  }

  // update bill rates when key is out of the text field
  onFocusOutFn(e: Event, punchout) {
    for (var i = this.projectStats.length - 1; i >= 0; i--) {
      if (this.projectStats[i].projectStatId === punchout.projectStatId) {
        const regex = /^(\d+\.)$/; // regex allowing nothing after a decimal
        var id = "billrate" + punchout.projectStatId;
        // For blank and 0 return 0.0
        if (e.target.value === "" || e.target.value === "0") {
          document.getElementById(id).value = "0.0";
          this.projectStats[i].avgBillRate = "0.0";
          // Add 0 after decimal in this condition
        } else if (regex.test(document.getElementById(id).value)) {
          document.getElementById(id).value = e.target.value.replace(/^(\d+\.)$/, "$10");
          this.projectStats[i].avgBillRate = e.target.value.replace(/^(\d+\.)$/, "$10");
        }
      }
    }
  }

  onFocusOutPeopleNeeded(e: Event, punchout, type) {
    for (var i = this.projectStats.length - 1; i >= 0; i--) {
      if (this.projectStats[i].projectStatId === punchout.projectStatId) {
        var id = "peopleNeeded" + punchout.projectStatId;
        // For blank and 0 return 0
        if (e.target.value === "") {
          document.getElementById(id).value = "0";
          this.projectStats[i].peopleNeeded = "0";
        }
      }
    }
  }

  onFocusOutBillableHours(e: Event, punchout, type) {
    for (var i = this.projectStats.length - 1; i >= 0; i--) {
      if (this.projectStats[i].projectStatId === punchout.projectStatId) {
        var id = "billableHours" + punchout.projectStatId;
        // For blank and 0 return 0
        if (e.target.value === "") {
          document.getElementById(id).value = "0";
          this.projectStats[i].billableHours = "0";
        }
      }
    }
  }

  // update the billableHours required
  changeBillableHoursForPunchout(e: Event, punchout) {
    for (var i = this.projectStats.length - 1; i >= 0; i--) {
      if (this.projectStats[i].projectStatId === punchout.projectStatId) {
        const re = /^[0-9]+?$/;
        if (e.target.value === "" || re.test(e.target.value)) {
          this.projectStats[i].billableHours = e.target.value;
          var id = "billableHours" + punchout.projectStatId;
          document.getElementById(id).value = e.target.value;
        } else return false;
      }
    }
  }

  // Remove punchouts from a project
  removePunchout(e: Event, punchout) {
    for (var i = this.projectStats.length - 1; i >= 0; i--) {
      if (this.projectStats[i].projectStatId === punchout.projectStatId) {
        this.projectStats.splice(i, 1);
      }
    }
  }

  // Update the project Stats
  updateProjectStats(stats: Array<Object>) {
    if (stats.length > 0) {
      stats.forEach((punchout) => {
        if (punchout.viewState.selected && !punchout.viewState.alreadyAdded) {
          var projectStats = {};
          var rateCard = {};
          var level = {};
          var buyRate = {};
          var search = {};
          var job = {};
          var projectkey = "key" + punchout.punchoutId;
          rateCard.ratecardId = punchout.rateCardId;
          level.levelId = punchout.levelId;
          level.value = punchout.levelName;
          level.romanNumeral = punchout.levelRomanNumeral;
          buyRate.buyrateId = punchout.store.buyrateId;
          search.searchId = punchout.store.store.searchId;
          job.jobLabel = punchout.store.store.jobLabel;
          job.jobTitle = punchout.store.store.name;
          projectStats.peopleNeeded = 0;
          projectStats.billableHours = 0;
          projectStats.avgBillRate = punchout.billRateAvg;
          search.job = job;
          search.region = punchout.store.store.region;
          search.city = punchout.store.store.city;
          search.state = punchout.store.store.state;
          search.country = punchout.store.store.country;
          search.currency = punchout.store.store.currency;
          projectStats.rateCard = rateCard;
          projectStats.level = level;
          projectStats.buyRate = buyRate;
          projectStats.search = search;
          projectStats.projectStatId = projectkey;
          this.projectStats.push(projectStats);
        }
        if (!punchout.viewState.selected && punchout.viewState.alreadyAdded) {
          for (var i = this.projectStats.length - 1; i >= 0; i--) {
            if (
              this.projectStats[i].buyRate.buyrateId === punchout.buyrateId &&
              this.projectStats[i].level.levelId === punchout.levelId
            ) {
              this.projectStats.splice(i, 1);
            }
          }
        }
      });
    }
  }

  // Call Mutation for updating the project (Adding/ updating the punchouts from a project)
  async updateProject() {
    let vars = {};
    vars.statsData = [];
    this.projectStats.forEach((punchout) => {
      let records = {};
      records.rateCardId = punchout.rateCard ? punchout.rateCard.ratecardId : null;
      records.searchId = punchout.search ? punchout.search.searchId : null;
      records.buyRateId = punchout.buyRate ? punchout.buyRate.buyrateId : null;
      records.levelId = punchout.level.levelId;
      records.billableHours = parseInt(punchout.billableHours, 10);
      records.avgBillRate = punchout.avgBillRate;
      records.peopleNeeded = punchout.peopleNeeded;
      vars.statsData.push(records);
    });

    const query = `
      mutation updateProject($projectId : Int!, $statsData: [StatsData]) {
        updateProject(input: {projectId: $projectId, statsData: $statsData}){
          project {
            projectId
            name
            tags{
                name
                tagId
               }
            user{
              firstName
              lastName
            }
            created
            projectStats {
              rateCard{
                ratecardId
              }
              level {
               levelId
               value
              }
              projectStatId
              peopleNeeded
              billableHours
              avgBillRate
              buyRate{
                buyrateId
              }
              search {
                searchId
                job {
                  jobLabel
                  jobTitle
                }
                state
                country
                currency{
                  iso
                }
              }
            }
          }
        }
      }
    `;

    const variables = Object.assign(
      {
        projectId: parseInt(this.projectId, 10),
      },
      vars
    );

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Updating Project", e);
      if (res !== null) {
        this.network.logGraphQLError("Updating Project query", res);
      }

      return e;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      // What does a searchStore have to do with this?
      this.searchStore.network.loading = false;
      return;
    }

    return runInAction("updateProject--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Updating Project query", res)) {
        return {};
      }

      const pc = res.data.updateProject.project;
      this.project = new ProjectCostEstimates(this, pc);
      this.projectStats = this.project.projectStats;
    });
  }

  async getExchangeRates(): Promise<any> {
    if (this.network.loading)
      return Promise.reject("Canceled. Store is loading other data.");

    this.network.loading = true;
    let res = null;
    try {
      res = await this.fetchAPI("projects/exchange-rates/", null, null, "GET");
    } catch (e) {
      this.network.loading = false;
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting Exchange Rates", e);
      return Promise.reject(e);
    }

    return runInAction("getExchangeRates--success", () => {
      this.network.loading = false;
      this.exchangeRates = res.data;
    });
  }

  // Get project details when page loads
  async getProjectDetails() {
    let res = null;
    if (!/^\d+$/.test(this.projectId || "")) {
      if (this.router) {
        this.router.push({
          pathname: "/404NotFound",
          query: this.router.query,
        });
      }
      return res;
    }
    const variables = {
      id: parseInt(this.projectId, 10),
    };
    const query = `
      query projectDetail($id: Int!) {
        viewer {
          user {
            firstName
            lastName
            userId
            username
            email
            roles
          }
          projectCost(id: $id) {
            projectId
            name
            tags {
              name
              tagId
            }
            user {
              firstName
              lastName
            }
            created
            projectStats {
              rateCard {
                ratecardId
              }
              level {
                levelId
                romanNumeral
                value
              }
              projectStatId
              peopleNeeded
              billableHours
              avgBillRate
              buyRate {
                buyrateId
              }
              search {
                searchId
                job {
                  jobLabel
                  jobTitle
                }
                region {
                  name
                }
                city
                state
                country
                currency {
                  iso
                }
              }
            }
          }
        }
      }`;

    this.network.loading = true;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting Project Details", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Project Details query", res);
      }
      return e;
    }

    return runInAction("getProjects--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get Project Details query", res)) {
        if (res.data.viewer && !res.data.viewer.projectCost) {
          if (this.router) {
            this.router.push({
              pathname: "/404NotFound",
              query: this.router.query,
            });
          }
          return;
        }
        return {};
      }
      this.selectedTags = observable.map({});
      this.currentUser = new CurrentUser(this, res.data.viewer.user);
      res.data.viewer.projectCost.tags.forEach((item) => {
        this.selectedTags.set(item.tagId, item);
      });
      const pc = res.data.viewer.projectCost;
      this.project = new ProjectCostEstimates(this, pc);
      this.projectStats = this.project.projectStats;

      this.getExchangeRates();
    });
  }

  // Mutation for renaming the project
  async renameProject() {
    if (this.network.loading) {
      return;
    }

    this.messaging.removeAll();

    if (!this.newProjectName.trim() || this.newProjectName === this.project.name) {
      this.messaging.createMessage("info", "Please enter a New Project Name.");
      return;
    }

    const query = `
      mutation renameProject ($projectId: Int!, $name: String!){
       renameProject(input: {projectId: $projectId, name: $name}) {
         ok
         errors{
          __typename
          ...on ProjectDoesNotExistsError{
            message
          }
          ...on NoPermissionError{
            message
          }
          ...on NameAlreadyExistError{
            message
          }
         }
       }
      }
      `;
    const variables = {
      projectId: this.projectId,
      name: this.newProjectName,
    };

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Updating Name", e);
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.searchStore.network.loading = false;
      return;
    }

    runInAction("renameProject--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Filter criteria query", res)) {
        return;
      }
      if (res.data.renameProject.errors) {
        this.messaging.removeAll();
        this.messaging.createMessage("error", res.data.renameProject.errors[0].message);
        return;
      }

      this.renameProjectModal.hideModal();
      this.project.name = this.newProjectName;
    });
  }

  async applyTags() {
    let params = [];
    let filterargs = [];
    let searchargs = [];
    let args = [];
    let vars = {};

    const taglist = this.applyTagState.getSelectedTagList();
    if (!taglist || !taglist.length) {
      console.error("Cannot Apply tags to PCE: No PCE selected");
      return;
    }
    params.push("$tagIds: [Int]!");
    filterargs.push("tagIds: $tagIds");
    vars.tagIds = taglist;
    params.push("$only: [ID]");
    args.push("only: $only");
    vars.only = [this.projectId];

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
      mutation applyTags(${queryParams}){
       applyTagsToProjects(input:{${filterargs}, filters: { ${queryArgs}}, ${searchargs}}) {
         ok
          errors {
           __typename
           ... on TagIdRequiredError {
             message
           }
           ... on TagIdsNotExistsError {
             message
           }
           ... on InvalidInputError {
             message
           }
           ... on ContentDoesNotExistsError {
             message
           }
         }
       }

      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Apply Tags to Selected Worksheets", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    runInAction("applyTags--success", () => {
      this.applyTagState.tagModal.hideModal();
      this.getProjectDetails();
    });
  }

  async deleteSingleTags(content) {
    const query = `
    mutation removeTagsFromContent{
      removeTagsFromContent(input: {tagIds : [${content.tagId}],contentType :${content.contentType},contentId:${content.contentId}}){
        ok
        }
      }
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, null);
    } catch (e) {
      // TODO: Display user friendly error message
      console.log("error", e);
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }

    runInAction("deleteSingleTags--success", () => {
      // debugger
      this.project.tags.forEach((item, index) => {
        if (content.tagId === item.tagId) {
          this.project.tags.splice(index, 1);
          this.selectedTags.delete(item.tagId);
        }
      });
      this.project = new ProjectCostEstimates(this, this.project);
    });
  }

  toJSON() {
    return {};
  }

  async removeTags(e: SyntheticEvent<HTMLElement>, tagIds: number[]): Promise<any> {
    if (this.network.loading) return;
    if (!this.projectId) return;

    const projectId = this.projectId;

    const query = `
    mutation removeTagsFromContent{
      removeTagsFromContent(
        input: {tagIds : [${tagIds.join(", ")}], 
        contentType: PROJECT_STATS, 
        contentId: ${projectId}}
        ){
          ok
          errors {
            __typename
          }
        }
      }
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, null);
    } catch (e) {
      // TODO: Display user friendly error message
      console.error("error", e);
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }

    return runInAction("removeTags--success", () => {
      const project = this.project;
      if (!project) return;
      if (!project.tags) return;

      project.tags = project.tags.filter((item: TagList) => {
        return !tagIds.includes(item.tagId);
      });
    });
  }

  async removeTag(e: SyntheticEvent<HTMLElement>, tagId: number): Promise<any> {
    return await this.removeTags(e, [tagId]);
  }
}
