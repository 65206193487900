/**
 * @generated SignedSource<<3ae5f9f6351233907c1cc942583d5add>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RegionsField_viewer$data = {
  readonly regions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly locations: ReadonlyArray<{
          readonly locationId: number;
          readonly name: string | null;
          readonly parent: {
            readonly locationId: number;
            readonly name: string | null;
          } | null;
        } | null> | null;
        readonly name: string;
        readonly regionId: number;
      } | null;
    } | null>;
  } | null;
  readonly " $fragmentType": "RegionsField_viewer";
};
export type RegionsField_viewer$key = {
  readonly " $data"?: RegionsField_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"RegionsField_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locationId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RegionsField_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "order",
          "value": [
            {
              "field": "NAME"
            }
          ]
        }
      ],
      "concreteType": "NewRegionNodeConnection",
      "kind": "LinkedField",
      "name": "regions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "NewRegionNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "NewRegionNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "RequiredField",
                  "field": {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "regionId",
                    "storageKey": null
                  },
                  "action": "NONE",
                  "path": "regions.edges.node.regionId"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LocationNode",
                  "kind": "LinkedField",
                  "name": "locations",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": (v1/*: any*/),
                      "action": "NONE",
                      "path": "regions.edges.node.locations.locationId"
                    },
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "LocationNode",
                      "kind": "LinkedField",
                      "name": "parent",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v1/*: any*/),
                          "action": "NONE",
                          "path": "regions.edges.node.locations.parent.locationId"
                        },
                        (v0/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "regions(order:[{\"field\":\"NAME\"}])"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "15079695750489900171b1cd3c83eb22";

export default node;
