/**
 * @generated SignedSource<<b449c28543028dfb3094992624802d8d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useSearchDetailsQuery$variables = {
  fromDateTime?: string | null;
  industryId?: string | null;
  jobTitle?: string | null;
  toDateTime?: string | null;
};
export type useSearchDetailsQuery$data = {
  readonly viewer: {
    readonly jobTitles: {
      readonly results: ReadonlyArray<{
        readonly clientJobLibraryInfo: {
          readonly adhocCountries: ReadonlyArray<number | null> | null;
          readonly certifiedCountries: ReadonlyArray<number | null> | null;
        } | null;
        readonly collection: number | null;
        readonly id: string;
        readonly isHealthcare: boolean | null;
        readonly isJobLabel: boolean | null;
        readonly shareInfo: {
          readonly isMine: boolean | null;
          readonly sharedBy: {
            readonly firstName: string | null;
            readonly lastName: string | null;
          } | null;
        } | null;
        readonly title: string;
      } | null> | null;
    } | null;
    readonly savedsearches: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly currency: {
            readonly country: {
              readonly locationId: number;
            };
          } | null;
          readonly locationFullLabel: string;
          readonly locationId: number | null;
          readonly locationLabel: string | null;
          readonly region: {
            readonly locations: ReadonlyArray<{
              readonly locationId: number;
              readonly name: string | null;
              readonly parent: {
                readonly locationId: number;
                readonly name: string | null;
              } | null;
            } | null> | null;
            readonly name: string;
            readonly regionId: number;
          } | null;
          readonly searchId: number;
        };
      } | null>;
    } | null;
  } | null;
};
export type useSearchDetailsQuery = {
  response: useSearchDetailsQuery$data;
  variables: useSearchDetailsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fromDateTime"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "industryId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jobTitle"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "toDateTime"
},
v4 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "fromDateTime",
        "variableName": "fromDateTime"
      },
      {
        "items": [
          {
            "kind": "Variable",
            "name": "industries.0",
            "variableName": "industryId"
          }
        ],
        "kind": "ListValue",
        "name": "industries"
      },
      {
        "items": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "jobTitle",
                "variableName": "jobTitle"
              }
            ],
            "kind": "ObjectValue",
            "name": "jobTitles.0"
          }
        ],
        "kind": "ListValue",
        "name": "jobTitles"
      },
      {
        "kind": "Variable",
        "name": "toDateTime",
        "variableName": "toDateTime"
      }
    ],
    "kind": "ObjectValue",
    "name": "filters"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "searchId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locationId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locationFullLabel",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locationLabel",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "regionId",
  "storageKey": null
},
v11 = [
  {
    "kind": "Literal",
    "name": "page",
    "value": 1
  },
  {
    "kind": "Literal",
    "name": "pageSize",
    "value": 25
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "jobTitle"
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isJobLabel",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "collection",
  "storageKey": null
},
v16 = {
  "alias": "isHealthcare",
  "args": null,
  "kind": "ScalarField",
  "name": "showQuantiles",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "ShareInfoType",
  "kind": "LinkedField",
  "name": "shareInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isMine",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SharedByType",
      "kind": "LinkedField",
      "name": "sharedBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "ClientJobLibraryTasteInfoNode",
  "kind": "LinkedField",
  "name": "clientJobLibraryInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adhocCountries",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "certifiedCountries",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useSearchDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "SaveSearchNodeConnection",
            "kind": "LinkedField",
            "name": "savedsearches",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SaveSearchNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": {
                      "alias": null,
                      "args": null,
                      "concreteType": "SaveSearchNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v5/*: any*/),
                          "action": "NONE",
                          "path": "viewer.savedsearches.edges.node.searchId"
                        },
                        (v6/*: any*/),
                        {
                          "kind": "RequiredField",
                          "field": (v7/*: any*/),
                          "action": "NONE",
                          "path": "viewer.savedsearches.edges.node.locationFullLabel"
                        },
                        (v8/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "CurrencyNode",
                          "kind": "LinkedField",
                          "name": "currency",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "RequiredField",
                              "field": {
                                "alias": null,
                                "args": null,
                                "concreteType": "CountryListNode",
                                "kind": "LinkedField",
                                "name": "country",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "RequiredField",
                                    "field": (v6/*: any*/),
                                    "action": "NONE",
                                    "path": "viewer.savedsearches.edges.node.currency.country.locationId"
                                  }
                                ],
                                "storageKey": null
                              },
                              "action": "NONE",
                              "path": "viewer.savedsearches.edges.node.currency.country"
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "NewRegionNode",
                          "kind": "LinkedField",
                          "name": "region",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "RequiredField",
                              "field": (v9/*: any*/),
                              "action": "NONE",
                              "path": "viewer.savedsearches.edges.node.region.name"
                            },
                            {
                              "kind": "RequiredField",
                              "field": (v10/*: any*/),
                              "action": "NONE",
                              "path": "viewer.savedsearches.edges.node.region.regionId"
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "LocationNode",
                              "kind": "LinkedField",
                              "name": "locations",
                              "plural": true,
                              "selections": [
                                {
                                  "kind": "RequiredField",
                                  "field": (v6/*: any*/),
                                  "action": "NONE",
                                  "path": "viewer.savedsearches.edges.node.region.locations.locationId"
                                },
                                (v9/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "LocationNode",
                                  "kind": "LinkedField",
                                  "name": "parent",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "kind": "RequiredField",
                                      "field": (v6/*: any*/),
                                      "action": "NONE",
                                      "path": "viewer.savedsearches.edges.node.region.locations.parent.locationId"
                                    },
                                    (v9/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    "action": "NONE",
                    "path": "viewer.savedsearches.edges.node"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v11/*: any*/),
            "concreteType": "JobTitleListNode",
            "kind": "LinkedField",
            "name": "jobTitles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobTitleNode",
                "kind": "LinkedField",
                "name": "results",
                "plural": true,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v12/*: any*/),
                    "action": "NONE",
                    "path": "viewer.jobTitles.results.id"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v13/*: any*/),
                    "action": "NONE",
                    "path": "viewer.jobTitles.results.title"
                  },
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "useSearchDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "SaveSearchNodeConnection",
            "kind": "LinkedField",
            "name": "savedsearches",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SaveSearchNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SaveSearchNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CurrencyNode",
                        "kind": "LinkedField",
                        "name": "currency",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CountryListNode",
                            "kind": "LinkedField",
                            "name": "country",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v12/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NewRegionNode",
                        "kind": "LinkedField",
                        "name": "region",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "LocationNode",
                            "kind": "LinkedField",
                            "name": "locations",
                            "plural": true,
                            "selections": [
                              (v6/*: any*/),
                              (v9/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "LocationNode",
                                "kind": "LinkedField",
                                "name": "parent",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  (v9/*: any*/),
                                  (v12/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v12/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v11/*: any*/),
            "concreteType": "JobTitleListNode",
            "kind": "LinkedField",
            "name": "jobTitles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobTitleNode",
                "kind": "LinkedField",
                "name": "results",
                "plural": true,
                "selections": [
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "27667bc4f2dc2b6626e406b9e723a096",
    "id": null,
    "metadata": {},
    "name": "useSearchDetailsQuery",
    "operationKind": "query",
    "text": "query useSearchDetailsQuery(\n  $jobTitle: String\n  $industryId: ID\n  $fromDateTime: DateTime\n  $toDateTime: DateTime\n) {\n  viewer {\n    savedsearches(filters: {jobTitles: [{jobTitle: $jobTitle}], industries: [$industryId], fromDateTime: $fromDateTime, toDateTime: $toDateTime}) {\n      edges {\n        node {\n          searchId\n          locationId\n          locationFullLabel\n          locationLabel\n          currency {\n            country {\n              locationId\n              id\n            }\n            id\n          }\n          region {\n            name\n            regionId\n            locations {\n              locationId\n              name\n              parent {\n                locationId\n                name\n                id\n              }\n              id\n            }\n            id\n          }\n          id\n        }\n      }\n    }\n    jobTitles(search: $jobTitle, page: 1, pageSize: 25) {\n      results {\n        id\n        title\n        isJobLabel\n        collection\n        isHealthcare: showQuantiles\n        shareInfo {\n          isMine\n          sharedBy {\n            firstName\n            lastName\n          }\n        }\n        clientJobLibraryInfo {\n          adhocCountries\n          certifiedCountries\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f990540b4f72334e7cb8656b5b18bc9f";

export default node;
