// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react";
import ExportOptionsState from "../../../models/ExportOptionsState";
import {
  CURRENCY_TYPE,
  EXPORT_TYPE,
  FILE_TYPE,
  LEVELS_TYPE,
} from "../../../models/ExportOptions";
import Modal from "./Modal";
import ContainerSection from "./ContainerSection";
import CheckBox from "../../shared/CheckBox";
import moment from "moment";
import { ModalButtons, ModalTitle } from "./FilterModal";
import TextInput from "../../../components/lib/TextInput";
import Inline from "../../../components/lib/Inline";
import Button from "../../../components/lib/Button";
import Tooltip from "../../../components/lib/Tooltip";
import { styled } from "../../../stitches.config";

const CheckBoxGroup = styled(Inline, { "& > label": { flex: 0.5 } });

class ExportModal extends Component {
  componentDidMount() {
    // default file name
    const now = moment();
    this.props.exportState.exportOptions.fileName = [
      "export",
      now.format("YYYYMMDD"),
      now.format("HHmmss"),
    ].join("-");
  }

  render() {
    const exportState: ExportOptionsState = this.props.exportState;
    const levelsToolTip = (
      <>
        Level I (1-5 years) -> Levels I & II
        <br />
        Level II (5-10 years) -> Levels III & IV
        <br />
        Level III (10+ years) -> Levels IV & V
        <br />
      </>
    );

    return (
      <Modal show={exportState.modalState.show} onHide={exportState.modalState.hideModal}>
        <ContainerSection className="header">
          <ModalTitle>Export</ModalTitle>
        </ContainerSection>
        <ContainerSection>
          <label>
            File Name: <em className="text-x-small text-muted">(Optional)</em>
          </label>
          <TextInput
            style={{ width: "100%" }}
            value={exportState.exportOptions.fileName}
            onChange={exportState.onChangeFileName}
          />
        </ContainerSection>
        {exportState.showFileTypeOptions && (
          <ContainerSection>
            <p>File Format:</p>
            <CheckBoxGroup>
              <CheckBox
                type="radio"
                name="file-type"
                value={FILE_TYPE.SPREADSHEET}
                selected={exportState.exportOptions.fileType === FILE_TYPE.SPREADSHEET}
                onChange={exportState.onChangeFileType}
              >
                <div>Microsoft Excel</div>
              </CheckBox>
              <CheckBox
                type="radio"
                name="file-type"
                value={FILE_TYPE.CSV}
                selected={exportState.exportOptions.fileType === FILE_TYPE.CSV}
                onChange={exportState.onChangeFileType}
              >
                <div>Comma Separated Values</div>
              </CheckBox>
            </CheckBoxGroup>
            {exportState.disableFileTypeOptions && (
              <div className="rc-shroud rc-center" />
            )}
          </ContainerSection>
        )}
        {exportState.showLevelTypeOptions && (
          <ContainerSection>
            <p>Levels:</p>
            <CheckBoxGroup>
              <CheckBox
                type="radio"
                name="levels-type"
                value={LEVELS_TYPE.THREE_LEVELS}
                selected={
                  exportState.exportOptions.levelType === LEVELS_TYPE.THREE_LEVELS
                }
                onChange={exportState.onChangeLevelsType}
              >
                <Tooltip sido="top" content={levelsToolTip}>
                  <div>3 Levels</div>
                </Tooltip>
              </CheckBox>
              <CheckBox
                type="radio"
                name="levels-type"
                value={LEVELS_TYPE.DEFAULT}
                selected={exportState.exportOptions.levelType === LEVELS_TYPE.DEFAULT}
                onChange={exportState.onChangeLevelsType}
              >
                <div>Standard</div>
              </CheckBox>
            </CheckBoxGroup>
          </ContainerSection>
        )}
        {exportState.showExportTypeOptions && (
          <ContainerSection>
            <p>Export Type:</p>
            <CheckBoxGroup>
              <CheckBox
                type="radio"
                name="export-type"
                value={EXPORT_TYPE.DETAILED}
                selected={exportState.exportOptions.exportType === EXPORT_TYPE.DETAILED}
                onChange={exportState.onChangeExportType}
              >
                <div>Rates Export</div>
              </CheckBox>
              <CheckBox
                type="radio"
                name="export-type"
                value={EXPORT_TYPE.LIST}
                selected={exportState.exportOptions.exportType === EXPORT_TYPE.LIST}
                onChange={exportState.onChangeExportType}
              >
                <div>Items Export</div>
              </CheckBox>
            </CheckBoxGroup>
            {exportState.disableExportTypeOptions && (
              <div className="rc-shroud rc-center" />
            )}
          </ContainerSection>
        )}
        {exportState.showCurrencyTypeOptions && (
          <ContainerSection>
            <p>Currency Type:</p>
            <CheckBoxGroup>
              <CheckBox
                type="radio"
                name="currency-type"
                value={CURRENCY_TYPE.DEFAULT}
                selected={
                  exportState.exportOptions.currencyType === CURRENCY_TYPE.DEFAULT
                }
                onChange={exportState.onChangeCurrencyType}
              >
                <div>Default</div>
              </CheckBox>
              <CheckBox
                type="radio"
                name="currency-type"
                value={CURRENCY_TYPE.USD}
                selected={exportState.exportOptions.currencyType === CURRENCY_TYPE.USD}
                onChange={exportState.onChangeCurrencyType}
              >
                <div>USD</div>
              </CheckBox>
            </CheckBoxGroup>
            {exportState.disableCurrencyTypeOptions && (
              <div className="rc-shroud rc-center" />
            )}
          </ContainerSection>
        )}
        {exportState.info && (
          <ContainerSection classNme="info">
            <p className="text-muted text-x-small no-margin">
              <FontAwesomeIcon icon="info-circle" fixedWidth className="icon" />{" "}
              <em> {exportState.info}</em>
            </p>
          </ContainerSection>
        )}
        <ContainerSection className="footer">
          <ModalButtons>
            <Button color="brand" size="large" onClick={exportState.onExport}>
              Export
            </Button>
            <Button size="large" onClick={exportState.onCancel}>
              Cancel
            </Button>
          </ModalButtons>
        </ContainerSection>
      </Modal>
    );
  }
}

ExportModal.propTypes = {
  exportState: PropTypes.object.isRequired,
};

ExportModal.defaultProps = {
  exportState: new ExportOptionsState(),
};

export default observer(ExportModal);
