import React from "react";

import { styled } from "../../../stitches.config";
import Icon from "../../../components/lib/Icon";
import Inline from "../../../components/lib/Inline";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogClose,
  DialogDescription,
} from "../lib/Dialog";
import Text from "../../../components/lib/Text";

const Pre = styled("pre", {
  width: "100%",
  border: 0,
  borderRadius: 0,
  backgroundColor: "inherit",
  fontSize: "$sm",
  color: "inherit",
  marginBottom: 0,
  fontStyle: "italic",
  whiteSpace: "pre-wrap",
});

interface DescriptionProps {
  text: string;
  hint: string;
  charLimit: number;
}

const Description = (props: DescriptionProps) => {
  const { text, hint, charLimit } = props;

  const [showState, setShowState] = React.useState<boolean>(false);

  const handleShow = React.useCallback(() => setShowState(true), []);
  const handleClose = React.useCallback(() => setShowState(false), []);

  if (text) {
    const overlay = (
      <Dialog onOpenChange={handleClose} open={showState}>
        <DialogContent>
          <DialogTitle asChild>
            <Inline css={{ justifyContent: "space-between" }}>
              <h4>Description</h4>
              <DialogClose asChild>
                <Icon icon="times" />
              </DialogClose>
            </Inline>
          </DialogTitle>
          <DialogDescription asChild>
            <Pre>{text}</Pre>
          </DialogDescription>
        </DialogContent>
      </Dialog>
    );

    return text.length > charLimit - 3 ? (
      <Text title={hint} css={{ cursor: "pointer" }}>
        <span onClick={handleShow}>{text.slice(0, charLimit - 3) + "..."}</span>
        {overlay}
      </Text>
    ) : (
      <span>{text}</span>
    );
  } else {
    return <span>No Description</span>;
  }
};

Description.displayName = "Description";

Description.defaultProps = {
  hint: "Click to see full description",
  charLimit: 50,
};

export default Description;
