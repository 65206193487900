import React, { useCallback, useEffect, useImperativeHandle, useState } from "react";
import moment from "moment";

import Box from "../../../components/lib/Box";
import Text from "../../../components/lib/Text";
import Inline from "../../../components/lib/Inline";
import Icon from "../../../components/lib/Icon";
import PromiseButton from "../../../components/lib/PromiseButton";
import { TableFilterableRestful } from "./TableFilterableRestful";
import { useTableSchemaState } from "../../../components/tables/Table";
import { Column } from "../../../components/tables";
import {
  FilterTypes,
  djangoPaginationKey,
  djangoPaginationSizeKey,
  djangoOrderingKey,
} from "../../../components/tables/constants";
import TextCrop from "../../validator5K_admin/components/TextCrop";
import { PT_LOCAL_TIMEZONE, decimalFormatter, DATE_TIME_FORMAT } from "../constants";
import { renderTableColumnsSpecs } from "../../private_index/components/ExtendedRestfulTables";
import { RestfulTableDataStateObject, restfulTableConfigDefaults } from "../globalState";
import { transformAttemptsData } from "../dataConverters";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";
import { useInitialFetch } from "../../../utils/hooks";
import { TickerContentLoader } from "../../../components/lib/TickerLoader";
import { emptyOrderedMap } from "../../../constants";
import PaymentReportExportConfig from "./PaymentReportExportConfig";
import {
  SOURCE_TYPES,
  REVIEW_STATUS_TYPES,
  READY_FOR_PAYMENT_TYPES,
  PAYMENT_REPORT_TYPES,
} from "../types";

import type {
  TableColumnSpecsObject,
  AttemptDataObject,
  AttemptDataMap,
  AttemptsDataOrderedMap,
  RewardsSummaryDataObject,
  GenericPaymentReportParamsObject,
  ByValidatorPaymentReportParamsObject,
  ByCountryPaymentReportParamsObject,
  ByClientPaymentReportParamsObject,
  PAYMENT_REPORT_TYPES_TYPE,
} from "../types";
import type { TableFilterableRestfulProps } from "../../../components/tables/TableFilterableRestful";
import type { FetchAPIResponse } from "../../../types/fetch";
import type { DjangoPaginatedResponse } from "../../../types/django";
import type {
  FiltersQueryObject,
  UrlQueryObject,
} from "../../../components/tables/types";
import { rowIdGetter } from "../../../components/tables/utils";
import { useVal5KAdminContext } from "../context/Val5KAdminContext";

type RewardsSummaryBlockProps = {
  rewardsSummary?: RewardsSummaryDataObject;
  rewardsSummaryText?: string;
};

const RewardsSummaryBlock = (props: RewardsSummaryBlockProps) => {
  const { rewardsSummaryText = `Total reward`, rewardsSummary } = props;

  if (rewardsSummary == null) return null;

  const { total_reward_count: totalCount, total_reward_total: totalReward } =
    rewardsSummary;

  if (totalCount <= 0 || totalReward <= 0) return null;

  return (
    <Inline fill css={{ marginTop: "$6", gap: "$2" }}>
      <Text>
        {rewardsSummaryText} ({totalCount} rows):
      </Text>
      <Box css={{ backgroundColor: "$brand", padding: "$2 $10", color: "$textLight" }}>
        <Text>{decimalFormatter(totalReward, false, "$")}</Text>
      </Box>
    </Inline>
  );
};
RewardsSummaryBlock.displayName = "RewardsSummaryBlock";

export const paymentReportTableSpecs: TableColumnSpecsObject<AttemptDataMap>[] = [
  {
    type: "column",
    uniqueKey: "review__id",
    title: "Review ID",
    getter: (row) => row.getIn(["review", "id"]),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "review__title",
    title: "Review Title",
    getter: (row) => row.getIn(["review", "title"]),
    formatter: (value) => (
      <TextCrop mode="tooltip" title="Title" text={value} emptyStub="" />
    ),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "review__source_title",
    title: "Ratecard/Upload Name",
    getter: (row) => {
      const title = row.getIn(["review", "source_title"]);
      const typeTitle =
        row.getIn(["review", "source_type"]) === SOURCE_TYPES.FROM_RATECARD
          ? "Ratecard"
          : "Upload";
      return `${title} (${typeTitle})`;
    },
    formatter: (value) => (
      <TextCrop mode="tooltip" title="Title" text={value} emptyStub="" />
    ),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "review__countries_string",
    title: "Country",
    getter: (row) => row.getIn(["review", "countries_string"]),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "review__client_name",
    title: "Client",
    getter: (row) => row.getIn(["review", "client_name"]),
    formatter: (value, row) => {
      return value ? `${value} (#${row.getIn(["review", "client_id"])})` : "";
    },
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "first_name",
    title: "First Name",
    getter: (row) => row.get("first_name"),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "last_name",
    title: "Last Name",
    getter: (row) => row.get("last_name"),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "email",
    title: "Email",
    getter: (row) => row.get("email"),
    formatter: (value) => (
      <TextCrop mode="tooltip" title="Title" text={value} emptyStub="" />
    ),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "completed_timestamp",
    title: "Completed At",
    getter: (row) => row.get("completed_timestamp"),
    formatter: (value) =>
      value != null ? moment.tz(value, PT_LOCAL_TIMEZONE).format(DATE_TIME_FORMAT) : "",
    filterType: FilterTypes.DATES_RANGE,
    filterExtraProps: {
      timezone: "US/Eastern",
      onChangeDatesFormat: moment.defaultFormat,
    },
  },
  {
    type: "column",
    uniqueKey: "total_reward",
    title: "Reward",
    getter: (row) => row.get("total_reward"),
    formatter: (value) => decimalFormatter(value),
    filterType: FilterTypes.NUMBERS_RANGE,
  },
];

type PaymentReportTableProps = Omit<TableFilterableRestfulProps, "schema">;

const PaymentReportTable = (props: PaymentReportTableProps) => {
  const [schema] = useTableSchemaState(props.children);
  return <TableFilterableRestful {...props} schema={schema} />;
};
PaymentReportTable.displayName = "PaymentReportTable";

const getGroupUniqueKey = (
  rowData: AttemptDataMap,
  reportType: PAYMENT_REPORT_TYPES_TYPE
): string => {
  let key: string | null = null;

  switch (reportType) {
    case PAYMENT_REPORT_TYPES.BY_VALIDATOR:
      key = rowData.get("email");
      break;
    case PAYMENT_REPORT_TYPES.BY_COUNTRY:
      key = rowData.getIn(["review", "countries_string"]);
      break;
    case PAYMENT_REPORT_TYPES.BY_CLIENT:
      key = "" + (rowData.getIn(["review", "client_id"]) || "");
      break;
  }

  return key ? key.toLowerCase() : "unknown";
};

const getGroupPaymentReportFiltersQuery = (
  reportParams: GenericPaymentReportParamsObject
): FiltersQueryObject => {
  const { reportType } = reportParams;

  if (reportType === PAYMENT_REPORT_TYPES.BY_VALIDATOR && "email" in reportParams) {
    const { email } = reportParams as ByValidatorPaymentReportParamsObject;
    return { email };
  }

  if (
    reportType === PAYMENT_REPORT_TYPES.BY_COUNTRY &&
    "countriesString" in reportParams
  ) {
    const { countryName: countriesString } =
      reportParams as ByCountryPaymentReportParamsObject;
    return { review__countries_string: countriesString };
  }

  if (reportType === PAYMENT_REPORT_TYPES.BY_CLIENT && "clientId" in reportParams) {
    const { clientId } = reportParams as ByClientPaymentReportParamsObject;
    if (clientId != null) {
      return {
        review__client_id__isnull: false,
        review__client_id: clientId,
      };
    } else {
      return {
        review__client_id__isnull: true,
        review__client_name__isnull: true,
      };
    }
  }

  return {};
};

export const getBasePaymentReportFiltersQuery = (
  reportFromDate: string,
  reportToDate: string
): FiltersQueryObject => {
  const filtersQuery: FiltersQueryObject = {
    review_status: REVIEW_STATUS_TYPES.REVIEWED,
    ready_for_payment: READY_FOR_PAYMENT_TYPES.READY,
    free_validation: false,
    total_reward__isnull: false,
    total_reward__gt: 0,
  };

  if (reportFromDate && reportToDate) {
    filtersQuery["completed_timestamp__range"] = [
      moment.tz(reportFromDate, "EST").hour(0).minute(0).second(0).utc().format(),
      moment.tz(reportToDate, "EST").hour(23).minute(59).second(59).utc().format(),
    ];
  }

  return filtersQuery;
};

type IsPaidButtonProps = {
  reportType: PAYMENT_REPORT_TYPES_TYPE;
  value: boolean;
  rowData: AttemptDataMap;
  onClick: (
    value: boolean,
    rowData: AttemptDataMap,
    groupKey: string
  ) => Promise<unknown>;
};

const IsPaidButton = (props: IsPaidButtonProps) => {
  const { value, reportType, rowData, onClick } = props;

  return (
    <PromiseButton
      size="small"
      iconRight={value ? "check-square" : ["far", "square"]}
      onClick={() => onClick(!value, rowData, getGroupUniqueKey(rowData, reportType))}
      css={{ width: "100px" }}
    >
      {value ? "Paid" : "Not Paid"}
    </PromiseButton>
  );
};
IsPaidButton.displayName = "IsPaidButton";

export type PaymentReportTableViewRefType = {
  refreshTable: () => void;
};

type PaymentReportTableViewProps = {
  groupName?: string;
  groupSummaryTitle?: string;
  showModalExport: boolean;
  onCloseModalExport: () => void;
  onChangeRowPaidFlag: (
    value: boolean,
    rowData: AttemptDataMap,
    groupKey: string | null
  ) => Promise<unknown>;
  groupSpecificFiltersQuery?: FiltersQueryObject;
} & GenericPaymentReportParamsObject;

const PaymentReportTableView = React.forwardRef(
  (
    props: PaymentReportTableViewProps,
    ref: React.ForwardedRef<PaymentReportTableViewRefType>
  ) => {
    const {
      groupName,
      groupSummaryTitle,
      showModalExport,
      onCloseModalExport,
      onChangeRowPaidFlag,
      groupSpecificFiltersQuery,
      ...reportParams
    } = props;
    const { reportType } = reportParams;

    const { showModalError, sessionInfo, fetchArgusFilteringAPI } =
      useVal5KAdminContext();

    // table state

    const [paymentTableState, setPaymentTableState] = useState<
      RestfulTableDataStateObject<AttemptDataMap>
    >({
      ...restfulTableConfigDefaults,
      itemsPerPage: 10,
      data: emptyOrderedMap as unknown as AttemptsDataOrderedMap,
      itemsCount: 0,
      loaded: false,
    });
    const { loaded, ...restTableState } = paymentTableState;

    const [summaryData, setSummary] = useState<RewardsSummaryDataObject>();

    // fetch functions

    const getResultingFiltersQuery = useCallback(
      (filtersQuery: FiltersQueryObject = {}) => {
        return {
          ...getBasePaymentReportFiltersQuery(
            reportParams.reportFromDate,
            reportParams.reportToDate
          ),
          ...getGroupPaymentReportFiltersQuery(reportParams),
          ...filtersQuery,
        };
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    const fetchPaymentReportData = useCallback(
      async (urlQuery = {}, filtersQuery = {}, nextStateUpdates = {}) => {
        try {
          const response: FetchAPIResponse<DjangoPaginatedResponse<AttemptDataObject>> =
            await fetchArgusFilteringAPI(`attempts/filtered/`, {
              params: urlQuery,
              data: getResultingFiltersQuery(filtersQuery),
            });

          const nextDataState = transformAttemptsData(response.data, nextStateUpdates);

          setPaymentTableState((prevDataState) => ({
            ...prevDataState,
            ...nextDataState,
            loaded: true,
          }));

          return nextDataState;
        } catch (err: any) {
          logAsyncOperationError("fetchPaymentReportData", err);
          showModalError(
            "Error occurred while loading payment report data. Please, try again later."
          );
          throw err;
        }
      },
      [
        getResultingFiltersQuery,
        setPaymentTableState,
        fetchArgusFilteringAPI,
        showModalError,
      ]
    );

    const fetchPaymentReportFiltersData = useCallback(
      async (urlQuery: UrlQueryObject = {}, filtersQuery: FiltersQueryObject = {}) => {
        try {
          const response: FetchAPIResponse<DjangoPaginatedResponse<any> | any[]> =
            await fetchArgusFilteringAPI(`attempts/values/filtered/`, {
              params: urlQuery,
              data: getResultingFiltersQuery(filtersQuery),
            });
          return response.data;
        } catch (err: any) {
          logAsyncOperationError("fetchPaymentReportFilterData", err);
          showModalError(
            "Error occurred while loading filter values. Please, try again later."
          );
          throw err;
        }
      },
      [getResultingFiltersQuery, fetchArgusFilteringAPI, showModalError]
    );

    const fetchRewardsSummary = useCallback(
      async (filtersQuery: FiltersQueryObject = {}) => {
        try {
          const response: FetchAPIResponse<RewardsSummaryDataObject> =
            await fetchArgusFilteringAPI("attempts/rewards-summary-values/filtered/", {
              method: "post",
              data: getResultingFiltersQuery(filtersQuery),
            });
          setSummary(response.data);
        } catch (err: any) {
          logAsyncOperationError("fetchRewardsSummary", err);
          showModalError(
            "Error occurred while loading rewards summary. Please, try again later."
          );
        }
      },
      [getResultingFiltersQuery, showModalError, fetchArgusFilteringAPI]
    );

    // utility functions

    const refreshPaymentReportData = useCallback(async () => {
      return fetchPaymentReportData(
        {
          [djangoPaginationKey]: paymentTableState.activePage,
          [djangoPaginationSizeKey]: paymentTableState.itemsPerPage,
        },
        paymentTableState.filtersQuery.toJS()
      );
    }, [
      paymentTableState.filtersQuery,
      paymentTableState.activePage,
      paymentTableState.itemsPerPage,
      fetchPaymentReportData,
    ]);

    useImperativeHandle(ref, () => ({ refreshTable: refreshPaymentReportData }));

    // handlers

    const handleExportError = useCallback(
      (err: Error) => {
        logAsyncOperationError("exportPaymentReport", err);
        showModalError("Error occurred while exporting, try again later.");
      },
      [showModalError]
    );

    const handleGetExportQueryArgs = useCallback(
      (exportCurrentPage: boolean = false): UrlQueryObject | null => {
        const queryArgs: UrlQueryObject = {
          "x-session-id": sessionInfo.legacySession,
        };

        const orderingValue = paymentTableState.filtersQuery.get(djangoOrderingKey);
        if (orderingValue) {
          queryArgs[djangoOrderingKey] = orderingValue;
        }

        if (groupName) {
          queryArgs["__group-name"] = groupName;
        }

        if (exportCurrentPage) {
          queryArgs[djangoPaginationKey] = paymentTableState.activePage;
          queryArgs[djangoPaginationSizeKey] = paymentTableState.itemsPerPage;
        }

        return Object.keys(queryArgs).length > 0 ? queryArgs : null;
      },
      [
        paymentTableState.activePage,
        paymentTableState.filtersQuery,
        paymentTableState.itemsPerPage,
        groupName,
        sessionInfo.legacySession,
      ]
    );

    const handleGetExportFormData = useCallback((): FiltersQueryObject => {
      const filtersQuery = getResultingFiltersQuery(
        paymentTableState.filtersQuery.toJS()
      );
      if (paymentTableState.filtersQuery.has(djangoOrderingKey)) {
        delete filtersQuery[djangoOrderingKey];
      }
      return filtersQuery;
    }, [paymentTableState.filtersQuery, getResultingFiltersQuery]);

    // effects

    useEffect(() => {
      fetchRewardsSummary(paymentTableState.filtersQuery.toJS());
    }, [paymentTableState.filtersQuery, fetchRewardsSummary]);

    // initial data fetch

    useInitialFetch(refreshPaymentReportData);

    // rendering

    const renderIsPaidButton = useCallback(
      (value: boolean, rowData: AttemptDataMap) => (
        <IsPaidButton
          value={value}
          rowData={rowData}
          reportType={reportType}
          onClick={onChangeRowPaidFlag}
        />
      ),
      [reportType, onChangeRowPaidFlag]
    );

    if (!loaded) {
      return (
        <Box css={{ minHeight: "200px", position: "relative" }}>
          <TickerContentLoader />
        </Box>
      );
    }

    const columns = renderTableColumnsSpecs(paymentReportTableSpecs).concat(
      <Column
        key="paid"
        uniqueKey="paid"
        title="Is Paid"
        getter={(rowData) => rowData.get("paid", false)}
        formatter={renderIsPaidButton}
        fixed
      />
    );

    return (
      <>
        <Box fill>
          <Text thin>
            <Icon icon="info-circle" /> All timestamps are displayed in EST local time.
          </Text>
        </Box>
        <PaymentReportTable
          multimode
          rowIdGetter={rowIdGetter}
          bodyEmptyText="No data"
          disablePagination={false}
          dataProvider={fetchPaymentReportData}
          filtersDataProvider={fetchPaymentReportFiltersData}
          {...restTableState}
        >
          {columns}
        </PaymentReportTable>
        <RewardsSummaryBlock
          rewardsSummary={summaryData}
          rewardsSummaryText={groupSummaryTitle}
        />
        <PaymentReportExportConfig
          show={showModalExport}
          itemsPerPage={paymentTableState.itemsPerPage}
          itemsTotal={paymentTableState.itemsCount}
          getQueryArgs={handleGetExportQueryArgs}
          getFormData={handleGetExportFormData}
          onClose={onCloseModalExport}
          onError={handleExportError}
        />
      </>
    );
  }
);
PaymentReportTableView.displayName = "PaymentReportTableView";

export default PaymentReportTableView;
