import React from "react";
import Box from "../../../components/lib/Box";
import {
  TableFilterableRestfulProps,
  TableFilterableEditableRestfulProps,
} from "../../../components/tables/TableFilterableRestful";
import {
  TableFilterableRestful as TableFilterableRestfulBase,
  TableFilterableEditableRestful as TableFilterableEditableRestfulBase,
} from "../../../components/tables";
import { styled } from "../../../stitches.config";

import type {
  DataProviderRestful,
  FiltersDataProviderRestful,
} from "../../../components/tables/types";
import { useGlobalContext } from "../../../globalContext";

export const TabButton = styled("button", {
  padding: "0 $4",
  whiteSpace: "nowrap",
  background: "none",
  border: "none",
  userSelect: "none",
  variants: {
    disabled: {
      true: {
        color: "$primaryLight !important",
        cursor: "not-allowed !important",
        "&:hover": {
          textDecoration: "none",
        },
      },
    },
    active: {
      true: {
        color: "$brand !important",
        "& .svg-inline--fa:hover": {
          transform: "scale(1.2)",
        },
      },
    },
  },
  defaultVariants: {
    disabled: false,
    active: false,
  },
});

export const TabsPanel = styled(Box, {
  position: "absolute",
  right: 0,
  top: "-37px",
  padding: "$2 0",
  display: "inline-block",
  backgroundColor: "$white",
  borderWidth: "1px 1px 0",
  borderStyle: "solid",
  borderColor: "$primaryLight",
  cursor: "pointer",

  [`& ${TabButton}`]: {
    borderRight: "1px solid $primaryLight",
    "&:last-child": {
      borderRight: 0,
    },
  },
});

function useDataProvidersWithLoaders(
  tableProps: TableFilterableRestfulProps
): [DataProviderRestful, FiltersDataProviderRestful] {
  const { dataProvider: dataProviderBase, filtersDataProvider: filtersDataProviderBase } =
    tableProps;
  const { showLoader, hideLoader } = useGlobalContext();

  const dataProvider: DataProviderRestful = React.useCallback(
    async (...args) => {
      try {
        await showLoader();
        return await dataProviderBase(...args);
      } catch (err) {
        console.error("Error during table data fetching:", { err });
        throw err;
      } finally {
        hideLoader();
      }
    },
    [dataProviderBase, showLoader, hideLoader]
  );

  const filtersDataProvider: FiltersDataProviderRestful = React.useCallback(
    async (...args) => {
      try {
        return await filtersDataProviderBase(...args);
      } catch (err) {
        console.error("Error during filter data fetching:", { err });
        throw err;
      }
    },
    [filtersDataProviderBase]
  );

  return [dataProvider, filtersDataProvider];
}

export const TableFilterableRestful = (props: TableFilterableRestfulProps) => {
  const [dataProvider, filtersDataProvider] = useDataProvidersWithLoaders(props);

  return (
    <TableFilterableRestfulBase
      {...props}
      dataProvider={dataProvider}
      filtersDataProvider={filtersDataProvider}
    />
  );
};

TableFilterableRestful.displayName = "TableFilterableRestful";
TableFilterableRestful.defaultProps = TableFilterableRestfulBase.defaultProps;

export const TableFilterableEditableRestful = (
  props: TableFilterableEditableRestfulProps
) => {
  const [dataProvider, filtersDataProvider] = useDataProvidersWithLoaders(props);

  return (
    <TableFilterableEditableRestfulBase
      {...props}
      dataProvider={dataProvider}
      filtersDataProvider={filtersDataProvider}
    />
  );
};

TableFilterableEditableRestful.displayName = "TableFilterableEditableRestful";
TableFilterableEditableRestful.defaultProps =
  TableFilterableEditableRestfulBase.defaultProps;
