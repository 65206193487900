import R from "ramda";
import {
  PAY_TYPES,
  RATE_TYPE_OPTIONS,
  RATE_TYPES,
} from "../../../constants/negotiationWorksheet";

/**
 * Grabs a field's value. Curryable.
 */
export default R.curryN(
  3,
  (
    unsavedValues,
    values,
    field,
    useFieldDefaultValue = true,
    computeValue = true,
    currentState = null
  ) => {
    // FOR DEBUGGING
    // if (field.key.includes("margin")) {
    //   const stateUnsavedValue = unsavedValues.find(s => s.hasOwnProperty(field.key));
    //   const storeValue = values.find(s => s.hasOwnProperty(field.key));
    //   console.log(
    //     field.key,
    //     "| unsavedValues:",
    //     stateUnsavedValue ? stateUnsavedValue[field.key] : "not set",
    //     "| store.values:",
    //     storeValue ? storeValue[field.key] : "not set",
    //     "| field.value:",
    //     field.value
    //   );
    // }

    // console.log("field:", field);
    let fieldReadOnly = false;
    let fieldVal;
    if (field.fieldTypeConfig.readOnly) fieldReadOnly = field.fieldTypeConfig.readOnly;
    const fieldKey = typeof field === "string" ? field : field.key;
    const readOnly =
      typeof field === "string"
        ? false
        : fieldReadOnly && field.fieldType !== "scriptedPercentage";
    let val = undefined;

    if (unsavedValues.length === undefined) {
      val = unsavedValues[fieldKey];
      // console.log(fieldKey, "unsavedValue", val);
    } else {
      fieldVal = unsavedValues.find(function (obj) {
        return obj.hasOwnProperty(fieldKey);
      });
      // console.log(fieldKey, "fieldVal", fieldVal);
      if (fieldVal) {
        val = fieldVal[fieldKey];
      }
    }

    // console.log(fieldKey, val);

    let value = readOnly ? undefined : val;
    if (!readOnly && values && value === undefined) value = values[fieldKey];
    if (value === undefined && useFieldDefaultValue === true) value = field.value;

    // HACK: Get scriptedPercentage fields working
    // val is null when user has not entered in a value of their own, their value
    // overrides the scriptedPercentage, but this differs from scriptedPercentage behavior
    // these fields should always be readOnly sigh.
    // console.log("unsavedValues", JSON.stringify(unsavedValues.toJSON()));
    // console.log("values", JSON.stringify(values.toJSON()));
    // console.log("field", JSON.stringify(field));
    // console.log("currentState", currentState);
    if (
      field.fieldType === "scriptedPercentage" &&
      val === null &&
      currentState &&
      computeValue &&
      currentState.payType === PAY_TYPES.PAY_RATE
    ) {
      let code = field.fieldTypeConfig.code.trim();
      let calcPercentageFn = new Function("payRate", code); // eslint-disable-line no-new-func
      let payRate = {
        hourly: 1,
        daily: 1,
        weekly: 1,
        monthly: 1,
      };

      if (currentState.rateType !== RATE_TYPES.HOURLY) {
        payRate.hourly = RATE_TYPE_OPTIONS[currentState.rateType].convertToHourly(
          currentState.payValue,
          {
            hoursInDay: 8,
          }
        );
      } else {
        payRate.hourly = currentState.payValue;
      }

      payRate.daily = payRate.hourly * 8;
      payRate.weekly = payRate.hourly * 40;
      payRate.monthly = payRate.hourly * 160;

      try {
        let returnValue = calcPercentageFn(payRate);

        if (!returnValue) {
          returnValue = 0;
        }

        returnValue = +returnValue;
        if (isNaN(returnValue)) {
          returnValue = 0;
        }

        value = +Math.max(returnValue, 0).toFixed(2);
      } catch (e) {
        value = 0;
      }
    }

    if (
      field.fieldType === "factorMultiple" &&
      computeValue &&
      typeof value !== "number"
    ) {
      // console.log(fieldKey, "multiply value:", value);
      value = value.integer * value.multiplier;
    }

    // console.log(fieldKey, value, field.fieldType);
    return value;
  }
);
