// @flow
import * as React from "react";
import { createFragmentContainer, QueryRenderer, useRelayEnvironment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import LoadingIndicator from "../views/shared/LoadingIndicator";
import type { RelayPaginationProp } from "react-relay/ReactRelayTypes";
import type { RenderProps } from "react-relay/ReactRelayQueryRenderer";
import type { UserRegionsContainerSection_viewer as Viewer } from "./__generated__/UserRegionsContainerSection_viewer.graphql";
import type { UserRegionsContainerSectionQueryResponse } from "./__generated__/UserRegionsContainerSectionQuery.graphql";
import ContainerSection from "../views/shared/ContainerSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SelectableItemsList from "../views/ratecards/components/SelectableItemsList";

type UserRegionsContainerSectionCommonProps = {|
  // nothing here yet
|};

type UserRegionsContainerSectionImplProps = {|
  relay: RelayPaginationProp,
  viewer: Viewer,
  ...UserRegionsContainerSectionCommonProps,
|};

export function UserRegionsContainerSectionImpl(
  props: UserRegionsContainerSectionImplProps
): React.Node {
  const { viewer } = props;
  const { userDetail } = viewer;
  const { username, regions } = userDetail || {};
  const { totalCount = 0, edges = [] } = regions || {};

  return (
    <ContainerSection style={{ minWidth: 640, minHeight: 487 }}>
      {!totalCount && (
        <>
          <p>
            Sorry! We didn't find any regions owned by <strong>{username}</strong>.
          </p>
          <p>Click "Back" to select a different user.</p>
        </>
      )}
      {(totalCount || 0) > 0 && (
        <>
          <p>
            We've found{" "}
            <strong>
              {totalCount} {totalCount === 1 ? "region" : "regions"}
            </strong>{" "}
            owned by <strong>{username}</strong>
          </p>
          <p>
            If this is what you want to copy click on <strong>"Start Copy"</strong>.
          </p>
          <SelectableItemsList>
            {edges.map((regionEdge) => {
              const { node: region } = regionEdge || {};
              if (!region)
                return <div className="text-danger">Could not render item</div>;

              const countryName = region.country ? region.country.name || "" : "";
              const locationCount = region.locations ? region.locations.length : 0;

              return (
                <div key={region.regionId} style={{ padding: "8px 16px" }}>
                  <div className="flex center-items">
                    <div style={{ fontSize: "1.75em", marginRight: 12 }}>
                      <FontAwesomeIcon icon="map-marked" className="icon no-margin" />
                    </div>
                    <div style={{ flex: "1" }}>
                      <div style={{ lineHeight: "1em" }}>{region.name}</div>
                      <div className="text-x-small">{countryName}</div>
                    </div>
                    <div>
                      <div className="text-x-small">
                        {locationCount} {locationCount === 1 ? "Location" : "Locations"}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </SelectableItemsList>
        </>
      )}
    </ContainerSection>
  );
}

export const UserRegionsContainerSectionFragmentContainer = createFragmentContainer(
  UserRegionsContainerSectionImpl,
  {
    viewer: graphql`
      fragment UserRegionsContainerSection_viewer on Viewer
      @argumentDefinitions(userId: { type: "Int!" }) {
        userDetail(id: $userId) {
          userId
          username
          regions {
            totalCount
            edges {
              node {
                regionId
                name
                country {
                  name
                }
                locations {
                  __typename
                }
              }
            }
          }
        }
      }
    `,
  }
);

type UserRegionsContainerSectionProps = {|
  userId: number,
  visible?: boolean,
  ...UserRegionsContainerSectionCommonProps,
|};

function UserRegionsContainerSection(
  props: UserRegionsContainerSectionProps
): React.Node {
  const { userId, visible, ...commonProps } = props;
  const relayEnvironment = useRelayEnvironment();

  if (!userId || !visible) return null;

  return (
    <QueryRenderer
      environment={relayEnvironment}
      query={graphql`
        query UserRegionsContainerSectionQuery($userId: Int!) {
          viewer {
            ...UserRegionsContainerSection_viewer @arguments(userId: $userId)
          }
        }
      `}
      variables={{ userId, filters: { createdBy: [userId] } }}
      render={({
        error,
        props: renderProps,
      }: RenderProps<UserRegionsContainerSectionQueryResponse>): any => {
        if (error) {
          console.error(error);
          return <div>Error!</div>;
        }

        const { viewer } = renderProps || {};
        if (!viewer) {
          return (
            <ContainerSection style={{ minWidth: 640, minHeight: 487 }}>
              <p>Loading user regions...</p>
              <LoadingIndicator />
            </ContainerSection>
          );
        }

        return (
          <UserRegionsContainerSectionFragmentContainer
            viewer={viewer}
            {...commonProps}
          />
        );
      }}
    />
  );
}

export default UserRegionsContainerSection;
