import React, { useCallback, useState, useMemo } from "react";
import { List } from "immutable";

import Stack from "../../../components/lib/Stack";
import Box from "../../../components/lib/Box";
import Text from "../../../components/lib/Text";
import Button from "../../../components/lib/Button";
import PromiseButton from "../../../components/lib/PromiseButton";
import Icon from "../../../components/lib/Icon";
import TextArea from "../../../components/lib/TextArea";
import StickersSelect from "./selects/StickersSelect";
import CountrySelect from "./selects/CountrySelect";
import AlertValidatorSelect from "./selects/AlertValidatorSelect";
import VerticalWizard from "./VerticalWizard";
import { emptyList } from "../../../constants";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";

import type {
  CountryDataMap,
  CountriesDataList,
  ValidatorSelectFormDataMap,
  ValidatorSelectFormDataList,
} from "../types";
import type { ImmutableList } from "../../../types/immutable";
import type { FetchAPIResponse } from "../../../types/fetch";
import { useVal5KAdminContext } from "../context/Val5KAdminContext";

const defaultAlertText = `Hi There!
We have some surveys for you to validate.
Visit our Validations Page and check for the following countries: [countries_list].
Thanks for the effort and time that you invest.`;

type ChooseCountriesStepProps = {
  value: CountriesDataList;
  isActive?: boolean;
  onChange: (value: CountriesDataList) => void;
  onGoToNextStep?: () => void;
};

const ChooseCountriesStep = (props: ChooseCountriesStepProps) => {
  const { value, isActive, onChange, onGoToNextStep } = props;

  // handlers

  const handleCountrySelect = useCallback(
    (item: CountryDataMap) => {
      if (item?.size && onChange) {
        const itemId = item.get("id");

        if (!value.find((i: CountryDataMap) => i.get("id") === itemId)) {
          onChange(value.push(item));
        }
      }
    },
    [value, onChange]
  );

  const handleCountryRemove = useCallback(
    (item: CountryDataMap) => {
      if (item?.size) {
        const itemId = item.get("id");

        if (value.first()) {
          onChange(value.filter((i) => i.get("id") !== itemId));
        }
      }
    },
    [value, onChange]
  );

  // rendering

  const getStickerValue = useCallback((item: CountryDataMap) => {
    const title = item.get("title");
    const locationCode = item.get("location_code");

    return (
      <Text>
        <Icon icon="map-marker-alt" /> {title} {locationCode && ` (${locationCode})`}
      </Text>
    );
  }, []);

  return (
    <Stack css={{ alignItems: "flex-start" }}>
      <Text as="h4">
        Choose countries you interested in, and we will find a list of all relevant
        validators to be informed:
      </Text>
      <Stack css={{ alignItems: "flex-start", width: "$1-of-2" }}>
        <StickersSelect
          selectedItems={value}
          noItemsSelectedText="No countries selected."
          getStickerValue={getStickerValue}
          onRemove={handleCountryRemove}
        />
        <CountrySelect values={value} onSelect={handleCountrySelect} />
      </Stack>
      {isActive && (
        <Button
          size="large"
          color="brand"
          iconRight="arrow-right"
          onClick={onGoToNextStep}
          disabled={!value?.size}
        >
          Go To The Next Step
        </Button>
      )}
    </Stack>
  );
};
ChooseCountriesStep.displayName = "ChooseCountriesStep";

type ChooseValidatorsStepProps = {
  value: ValidatorSelectFormDataList;
  countriesIds: ImmutableList<number>;
  onChange: (value: ValidatorSelectFormDataList) => void;
  onGoToNextStep?: () => void;
  isActive?: boolean;
};

const ChooseValidatorsStep = (props: ChooseValidatorsStepProps) => {
  const { value, countriesIds, isActive, onChange, onGoToNextStep } = props;
  const { fetchArgusAPI, showModalError } = useVal5KAdminContext();

  // handlers

  const handleValidatorsSelect = useCallback(
    (item: ValidatorSelectFormDataList | ValidatorSelectFormDataMap) => {
      if (item && onChange) {
        if (item instanceof List) {
          onChange(item as ValidatorSelectFormDataList);
        } else {
          const itemId = (item as ValidatorSelectFormDataMap).get("email");
          if (!value.find((i) => i.get("email") === itemId)) {
            onChange(value.push(item as ValidatorSelectFormDataMap)); // include
          } else {
            onChange(value.filter((i) => i.get("email") !== itemId)); // exclude
          }
        }
      }
    },
    [value, onChange]
  );

  return (
    <Stack css={{ alignItems: "flex-start" }}>
      <Text as="h4">Choose validators you would like to inform.</Text>

      <Stack css={{ alignItems: "flex-start", width: "$1-of-2" }}>
        <Box css={{ minHeight: "$5" }}>
          {!!value?.size && (
            <Text>
              <Text bold>{value.size}</Text> validators selected
            </Text>
          )}
        </Box>

        <AlertValidatorSelect
          values={value}
          countriesIds={countriesIds}
          onSelect={handleValidatorsSelect}
          showModalError={showModalError}
          fetchArgusAPI={fetchArgusAPI}
        />
      </Stack>

      {isActive && (
        <Button
          size="large"
          color="brand"
          iconRight="arrow-right"
          onClick={onGoToNextStep}
          disabled={!value || !value.size}
        >
          Go To The Next Step
        </Button>
      )}
    </Stack>
  );
};
ChooseValidatorsStep.displayName = "ChooseValidatorsStep";

type EditAlertTextStepProps = {
  value: string;
  hasAllRequiredData: boolean;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSendAlerts: () => Promise<void>;
  onResetWizard?: () => void;
};

const EditAlertTextStep = (props: EditAlertTextStepProps) => {
  const { value, hasAllRequiredData, onResetWizard, onSendAlerts, onChange } = props;

  const [sent, setSent] = useState<boolean>(false);

  const handleSendAlerts = useCallback(async () => {
    await onSendAlerts();
    setSent(true);
  }, [onSendAlerts]);

  return (
    <Stack css={{ alignItems: "flex-start" }}>
      <Text as="h4">
        Edit alert text if needed.
        <br />
        Please note, [countries_list] placeholder will be replaced with actual countries
        list eligible for every validator. DO NOT edit this placeholder.
      </Text>

      <TextArea fill css={{ minHeight: "150px" }} value={value} onChange={onChange} />

      {hasAllRequiredData && !sent && (
        <PromiseButton
          color="accent"
          size="large"
          icon="paper-plane"
          onClick={handleSendAlerts}
        >
          Send Alerts
        </PromiseButton>
      )}

      {sent && (
        <Button size="large" icon="play-circle" onClick={onResetWizard}>
          Create More Alerts
        </Button>
      )}
    </Stack>
  );
};
EditAlertTextStep.displayName = "EditAlertTextStep";
EditAlertTextStep.defaultProps = {
  hasAllRequiredData: false,
};

type WizardStateType = {
  step: number;
  countriesList: CountriesDataList;
  validatorsList: ValidatorSelectFormDataList;
  alertText: string;
};

const defaultWizardState = {
  step: 1,
  countriesList: emptyList,
  validatorsList: emptyList,
  alertText: defaultAlertText,
};

type ValidatorAlertWizardPropsType = {
  onFinish: () => void;
  onRefreshTable: () => void;
};

const ValidatorAlertWizard = (props: ValidatorAlertWizardPropsType) => {
  const { onFinish, onRefreshTable } = props;
  const { showModalSuccess, showModalError, fetchArgusAPI } = useVal5KAdminContext();

  // state

  const [state, setState] = useState<WizardStateType>(defaultWizardState);

  const hasAllRequiredData = !!(
    state.countriesList?.size &&
    state.validatorsList?.size &&
    state.alertText
  );
  const countriesIdsList = useMemo(
    () => state.countriesList?.map((i) => i.get("id")),
    [state.countriesList]
  );

  // handlers

  const handleChangeWizardStep = useCallback((step) => {
    setState((prevState) => ({
      ...prevState,
      step,
    }));
  }, []);

  const handleResetWizard = useCallback(() => {
    setState(defaultWizardState);
    window.scrollTo(0, 0);
    onFinish?.();
  }, [onFinish]);

  const handleChangeCountriesList = useCallback(
    (value: CountriesDataList) => {
      if (value !== state.countriesList) {
        setState((prevState) => ({
          ...prevState,
          countriesList: value,
        }));
      }
    },
    [state.countriesList]
  );

  const handleChangeValidatorsList = useCallback(
    (value: ValidatorSelectFormDataList) => {
      if (value !== state.validatorsList) {
        setState((prevState) => ({
          ...prevState,
          validatorsList: value,
        }));
      }
    },
    [state.validatorsList]
  );

  const handleChangeAlertText = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const value = e.target.value;
      if (value !== state.alertText) {
        setState((prevState) => ({
          ...prevState,
          alertText: value,
        }));
      }
    },
    [state.alertText]
  );

  const handleSendAlerts = useCallback(async () => {
    if (!hasAllRequiredData) return;
    try {
      const response: FetchAPIResponse<{ count: number }> = await fetchArgusAPI(
        `alerts/send/`,
        {
          method: "post",
          data: {
            countries: state.countriesList.toJS(),
            validators: state.validatorsList.toJS(),
            text: state.alertText,
          },
        }
      );
      onRefreshTable?.();
      const count = response?.data?.count ?? 0;
      showModalSuccess(`${count} validators have been processed.`);
    } catch (err: any) {
      logAsyncOperationError("sendAlerts", err);
      showModalError("Error occurred while sending alerts. Please, try again later.");
    }
  }, [
    hasAllRequiredData,
    state.countriesList,
    state.validatorsList,
    state.alertText,
    onRefreshTable,
    fetchArgusAPI,
    showModalError,
    showModalSuccess,
  ]);

  // rendering

  const chooseCountriesStep = (
    <ChooseCountriesStep
      value={state.countriesList}
      onChange={handleChangeCountriesList}
    />
  );
  const chooseValidatorsStep = (
    <ChooseValidatorsStep
      value={state.validatorsList}
      countriesIds={countriesIdsList}
      onChange={handleChangeValidatorsList}
    />
  );
  const editTextStep = (
    <EditAlertTextStep
      value={state.alertText}
      hasAllRequiredData={hasAllRequiredData}
      onChange={handleChangeAlertText}
      onSendAlerts={handleSendAlerts}
    />
  );

  return (
    <VerticalWizard
      step={state.step}
      onChange={handleChangeWizardStep}
      onReset={handleResetWizard}
    >
      {chooseCountriesStep}
      {chooseValidatorsStep}
      {editTextStep}
    </VerticalWizard>
  );
};

export default ValidatorAlertWizard;
