import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-overlays";
import classNames from "classnames";

const BasicModal = (props) => (
  <Modal
    aria-labelledby="model-label"
    className={classNames("pt-filter-modal", {
      showFadeIn: props.show,
      hideFadeOut: !props.show,
    })}
    backdropClassName="pt-filter-modal-backdrop"
    show={props.show}
    onHide={props.onHide}
    style={props.style}
  >
    <div
      className={classNames(props.className, "pt-ui pt-filter-modal-inner", {
        "model-sm": !props.fullScreen,
      })}
      style={props.innerStyle}
    >
      {props.children}
    </div>
  </Modal>
);

BasicModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  style: PropTypes.object,
  innerStyle: PropTypes.object,
  className: PropTypes.string,
  fullScreen: PropTypes.bool,
};
BasicModal.defaultProps = {
  style: {},
  innerStyle: {},
  className: "",
  fullScreen: false,
};

export default BasicModal;
