/**
 * @generated SignedSource<<54e37b986136c130630b4d13dc32dca9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserRegionsContainerSection_viewer$data = {
  readonly userDetail: {
    readonly regions: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly country: {
            readonly name: string | null;
          } | null;
          readonly locations: ReadonlyArray<{
            readonly __typename: "LocationNode";
          } | null> | null;
          readonly name: string;
          readonly regionId: number | null;
        } | null;
      } | null>;
      readonly totalCount: number | null;
    } | null;
    readonly userId: number;
    readonly username: string;
  } | null;
  readonly " $fragmentType": "UserRegionsContainerSection_viewer";
};
export type UserRegionsContainerSection_viewer$key = {
  readonly " $data"?: UserRegionsContainerSection_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserRegionsContainerSection_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserRegionsContainerSection_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "userId"
        }
      ],
      "concreteType": "AdminUserNode",
      "kind": "LinkedField",
      "name": "userDetail",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "NewRegionNodeConnection",
          "kind": "LinkedField",
          "name": "regions",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "NewRegionNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "NewRegionNode",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "regionId",
                      "storageKey": null
                    },
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "LocationNode",
                      "kind": "LinkedField",
                      "name": "country",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "LocationNode",
                      "kind": "LinkedField",
                      "name": "locations",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "3b96a639533bd2a92e36c4c86d54bd81";

export default node;
