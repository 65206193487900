// @flow
import { extendObservable, action } from "mobx";
import FilterObject, { ValueSetFilter, FilterWithSort, IViewItem } from "../Filter";
import type { FilterColumn } from "../Filter";
import Sort from "../Sort";
import SortState from "../SortState";

class StatusCriterionItem implements IViewItem {
  id: string;
  value: string;
  selected: string;
  status: string;

  constructor(object: Object) {
    this.id = object.id;
    this.value = object.value;
    this.status = object.status;

    extendObservable(this, {
      selected: object.selected,
    });
  }
}

export default class StatusFilter extends ValueSetFilter(FilterWithSort) {
  constructor(
    store: Object,
    column: FilterColumn,
    applyFilter: (FilterColumn, FilterObject) => void,
    applySort: (FilterColumn, Sort) => void,
    removeFilter: (FilterColumn) => void,
    removeSort: (FilterColumn) => void
  ) {
    super(store, column);

    this.allowMultipleItemSelection = false; // only one item selected at a time

    this.applyFilter = applyFilter;
    this.removeFilter = removeFilter;
    this.applySort = applySort;
    this.removeSort = removeSort;

    this.sortState = new SortState("USER_FIRST_NAME");

    this.viewItemClass = StatusCriterionItem;

    // create criteria and view items
    this.criteria.set("1", { id: "1", value: "Success", status: "SUCCESS" });
    this.criteria.set("2", { id: "2", value: "Failure", status: "FAILURE" });

    extendObservable(this, {
      viewItems: this.buildViewItems(this.criteria),
    });

    this.buildQueryFilter = action(this.buildQueryFilter.bind(this));
  }

  buildQueryFilter() {
    const sharedSelectedValues = this.viewItems
      .filter((item) => item.selected)
      .map((item) => item.status);

    if (!sharedSelectedValues.length) {
      // no value selected, just return
      return null;
    }

    if (sharedSelectedValues.length > 1) {
      // NOTE: Both Yes/No selected, just return
      console.error("Unable to build Rate Type filter: More than one value selected");
      return null;
    }

    const status = sharedSelectedValues[0];

    if (this.filter && this.filter.queryVars.status === status) return this.filter; // no change

    return new FilterObject("$status: SearchLogTypeEnum!", "status: $status", {
      status: status,
    });
  }
}
