// @flow

import * as React from "react";
import { observer } from "mobx-react";

type Props = {
  className: string,
  children?: React.Node,
  style?: Object,
};

const ContainerSection = (props: Props) => (
  <div className={`container-section ${props.className}`} style={props.style}>
    {props.children}
  </div>
);

ContainerSection.defaultProps = {
  className: "",
};

export default observer(ContainerSection);
