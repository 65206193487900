import React from "react";
import Stitches from "@stitches/react";
import Spinner from "./Spinner";
import Icon from "./Icon";
import { styled, css } from "../../stitches.config";
import type { CSS } from "../../stitches.config";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";

export const focusRing = css({
  "$$pt-shadow": "0 0 #000",
  "$$pt-ringColor": "#000",
  "$$pt-ringOffsetWidth": "2px",
  "$$pt-ringOffsetColor": "#000",
  "$$pt-ringOffsetShadow": "0 0 0 $$pt-ringOffsetWidth $$pt-ringOffsetColor",
  "$$pt-ringShadow": "0 0 0 calc(2px + $$pt-ringOffsetWidth) $$pt-ringColor",
  "&:focus": {
    boxShadow: "$$pt-ringOffsetShadow, $$pt-ringShadow, $$pt-shadow",
    outline: "none",
    textDecoration: "none",
  },
});

export const buttonTransition = css({
  transitionProperty: "background-color, border-color, color, fill, stroke, box-shadow",
  transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
  transitionDuration: "150ms",
});

export const buttonBaseStyles = css(focusRing, buttonTransition, {
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  fontWeight: "$normal",
  borderRadius: "$rounded",
  borderWidth: 2,
  borderStyle: "solid",
  outline: "none",
  "&:hover": {
    borderWidth: 2,
    borderStyle: "solid",
    outline: "none",
  },
  "&:disabled": {
    opacity: 0.5,
    cursor: "not-allowed",
  },
});

export const buttonStyleConfig = css(buttonBaseStyles, {
  variants: {
    size: {
      "extra-small": {
        gap: "$2",
        padding: "$1 $2",
        fontSize: "$xs",
        lineHeight: "$xs",
      },
      small: {
        gap: "$2_5",
        padding: "$1_5 $2_5",
        fontSize: "$sm",
        lineHeight: "$sm",
      },
      normal: {
        gap: "$3",
        padding: "$2 $4",
        fontSize: "$base",
        lineHeight: "$base",
      },
      ptNormal: {
        gap: "$1_5",
        padding: "4px $2_5",
        fontSize: "14px",
        lineHeight: "normal",
      },
      large: {
        gap: "$3_5",
        padding: "$2_5 $5",
        fontSize: "$lg",
        lineHeight: "$lg",
      },
      "extra-large": {
        gap: "$4",
        padding: "$3 $5",
        fontSize: "$2xl",
        lineHeight: "$2xl",
      },
    },
    shape: {
      rounded: {
        borderRadius: "100px",
        padding: "$1 $4",
      },
      circular: {
        borderRadius: "100px",
      },
    },
    variant: {
      filled: {},
      outlined: {},
      light: {},
      text: {},
    },
    color: {
      gray: {},
      primary: {},
      green: {},
      brand: {},
      yellow: {},
      accent: {},
      red: {},
      success: {},
      warning: {},
      danger: {},
      blue: {},
    },
  },
  compoundVariants: [
    {
      size: "ptNormal",
      variant: "filled",
      color: "primary",
      css: {
        padding: "5px calc($2_5 + 1px)",
      },
    },
    {
      size: "ptNormal",
      variant: "filled",
      color: "gray",
      css: {
        padding: "5px calc($2_5 + 1px)",
      },
    },
    {
      size: "large",
      variant: "filled",
      color: "primary",
      css: {
        padding: "calc($2_5 + 1px) calc($5 + 1px)",
      },
    },
    {
      size: "large",
      variant: "filled",
      color: "gray",
      css: {
        padding: "calc($2_5 + 1px) calc($5 + 1px)",
      },
    },
    {
      variant: "filled",
      color: "gray",
      css: {
        color: "$brandDark",
        backgroundColor: "white",
        borderColor: "$brand",
        borderWidth: 1,
        "&:hover": {
          color: "$textLight",
          backgroundColor: "$brandDark",
          borderColor: "$brandDark",
          borderWidth: 1,
        },
        "&:focus, &:active": {
          color: "$textLight",
          backgroundColor: "$brandDark",
          borderColor: "$brandDark",
          "$$pt-ringColor": "$colors$brandDark",
          "$$pt-ringOffsetColor": "$colors$brandLightest",
        },
      },
    },
    {
      variant: "filled",
      color: "primary",
      css: {
        color: "$brandDark",
        backgroundColor: "white",
        borderColor: "$brand",
        borderWidth: 1,
        "&:hover": {
          color: "$textLight",
          backgroundColor: "$brandDark",
          borderColor: "$brandDark",
          borderWidth: 1,
        },
        "&:focus, &:active": {
          color: "$textLight",
          backgroundColor: "$brandDark",
          borderColor: "$brandDark",
          "$$pt-ringColor": "$colors$brandDark",
          "$$pt-ringOffsetColor": "$colors$brandLightest",
        },
      },
    },
    {
      variant: "filled",
      color: "green",
      css: {
        color: "$textLight",
        backgroundColor: "$brand",
        borderColor: "$brand",
        "&:hover": {
          backgroundColor: "$brandDark",
          borderColor: "$brandDark",
        },
        "&:focus": {
          "$$pt-ringColor": "$colors$brandDark",
          "$$pt-ringOffsetColor": "$colors$brandLightest",
        },
      },
    },
    {
      variant: "filled",
      color: "brand",
      css: {
        color: "$textLight",
        backgroundColor: "$brand",
        borderColor: "$brand",
        "&:hover": {
          backgroundColor: "$brandDark",
          borderColor: "$brandDark",
        },
        "&:focus": {
          "$$pt-ringColor": "$colors$brandDark",
          "$$pt-ringOffsetColor": "$colors$brandLightest",
        },
      },
    },
    {
      variant: "filled",
      color: "yellow",
      css: {
        color: "$textLight",
        backgroundColor: "$accent",
        borderColor: "$accent",
        "&:hover": {
          backgroundColor: "$accentDark",
          borderColor: "$accentDark",
        },
        "&:focus": {
          "$$pt-ringColor": "$colors$accentDark",
          "$$pt-ringOffsetColor": "$colors$accentLightest",
        },
      },
    },
    {
      variant: "filled",
      color: "accent",
      css: {
        color: "$textLight",
        backgroundColor: "$accent",
        borderColor: "$accent",
        "&:hover": {
          backgroundColor: "$accentDark",
          borderColor: "$accentDark",
        },
        "&:focus": {
          "$$pt-ringColor": "$colors$accentDark",
          "$$pt-ringOffsetColor": "$colors$accentLightest",
        },
      },
    },
    {
      variant: "filled",
      color: "warning",
      css: {
        color: "$textLight",
        backgroundColor: "$warning",
        borderColor: "$warning",
        "&:hover": {
          backgroundColor: "$warningDark",
          borderColor: "$warningDark",
        },
        "&:focus": {
          "$$pt-ringColor": "$colors$warningDark",
          "$$pt-ringOffsetColor": "$colors$warningLightest",
        },
      },
    },
    {
      variant: "filled",
      color: "success",
      css: {
        color: "$textLight",
        backgroundColor: "$success",
        borderColor: "$success",
        "&:hover": {
          backgroundColor: "$successDark",
          borderColor: "$successDark",
        },
        "&:focus": {
          "$$pt-ringColor": "$colors$successDark",
          "$$pt-ringOffsetColor": "$colors$successLightest",
        },
      },
    },
    {
      variant: "filled",
      color: "red",
      css: {
        color: "$textLight",
        backgroundColor: "$danger",
        borderColor: "$danger",
        "&:hover": {
          backgroundColor: "$dangerDark",
          borderColor: "$dangerDark",
        },
        "&:focus": {
          "$$pt-ringColor": "$colors$dangerDark",
          "$$pt-ringOffsetColor": "$colors$dangerLightest",
        },
      },
    },
    {
      variant: "filled",
      color: "danger",
      css: {
        color: "$textLight",
        backgroundColor: "$danger",
        borderColor: "$danger",
        "&:hover": {
          backgroundColor: "$dangerDark",
          borderColor: "$dangerDark",
        },
        "&:focus": {
          "$$pt-ringColor": "$colors$dangerDark",
          "$$pt-ringOffsetColor": "$colors$dangerLightest",
        },
      },
    },
    {
      variant: "filled",
      color: "blue",
      css: {
        color: "white",
        backgroundColor: "$ptBlue500",
        borderColor: "$ptBlue500",
        "&:hover": {
          backgroundColor: "$ptBlue400",
          borderColor: "$ptBlue400",
        },
        "&:focus": {
          "$$pt-ringColor": "$colors$ptBlue500",
          "$$pt-ringOffsetColor": "$colors$ptBlue50",
        },
      },
    },
    {
      variant: "outlined",
      color: "gray",
      css: {
        color: "$text",
        backgroundColor: "transparent",
        borderColor: "$primary",
        "&:hover": {
          color: "$textLight",
          backgroundColor: "$primaryDark",
          borderColor: "$primaryDark",
        },
        "&:focus": {
          color: "$textLight",
          backgroundColor: "$primaryDark",
          borderColor: "$primaryDark",
          "$$pt-ringColor": "$colors$primaryDark",
          "$$pt-ringOffsetColor": "$colors$primaryLightest",
        },
      },
    },
    {
      variant: "outlined",
      color: "primary",
      css: {
        color: "$text",
        backgroundColor: "transparent",
        borderColor: "$primary",
        "&:hover": {
          color: "$textLight",
          backgroundColor: "$primaryDark",
          borderColor: "$primaryDark",
        },
        "&:focus, &:active": {
          color: "$textLight",
          backgroundColor: "$primaryDark",
          borderColor: "$primaryDark",
          "$$pt-ringColor": "$colors$primaryDark",
          "$$pt-ringOffsetColor": "$colors$primaryLightest",
        },
      },
    },
    {
      variant: "outlined",
      color: "green",
      css: {
        color: "$brandDark",
        backgroundColor: "transparent",
        borderColor: "$brand",
        "&:hover": {
          color: "$textLight",
          backgroundColor: "$brandDark",
          borderColor: "$brandDark",
        },
        "&:focus, &:active": {
          color: "$textLight",
          backgroundColor: "$brandDark",
          borderColor: "$brandDark",
          "$$pt-ringColor": "$colors$brandDark",
          "$$pt-ringOffsetColor": "$colors$brandLightest",
        },
      },
    },
    {
      variant: "outlined",
      color: "brand",
      css: {
        color: "$brandDark",
        backgroundColor: "transparent",
        borderColor: "$brand",
        "&:hover": {
          color: "$textLight",
          backgroundColor: "$brandDark",
          borderColor: "$brandDark",
        },
        "&:focus, &:active": {
          color: "$textLight",
          backgroundColor: "$brandDark",
          borderColor: "$brandDark",
          "$$pt-ringColor": "$colors$brandDark",
          "$$pt-ringOffsetColor": "$colors$brandLightest",
        },
      },
    },
    {
      variant: "outlined",
      color: "yellow",
      css: {
        color: "$accentDark",
        backgroundColor: "transparent",
        borderColor: "$accent",
        "&:hover": {
          color: "$textLight",
          backgroundColor: "$accentDark",
          borderColor: "$accentDark",
        },
        "&:focus, &:active": {
          color: "$textLight",
          backgroundColor: "$accentDark",
          borderColor: "$accentDark",
          "$$pt-ringColor": "$colors$accentDark",
          "$$pt-ringOffsetColor": "$colors$accentLightest",
        },
      },
    },
    {
      variant: "outlined",
      color: "accent",
      css: {
        color: "$accentDark",
        backgroundColor: "transparent",
        borderColor: "$accent",
        "&:hover": {
          color: "$textLight",
          backgroundColor: "$accentDark",
          borderColor: "$accentDark",
        },
        "&:focus, &:active": {
          color: "$textLight",
          backgroundColor: "$accentDark",
          borderColor: "$accentDark",
          "$$pt-ringColor": "$colors$accentDark",
          "$$pt-ringOffsetColor": "$colors$accentLightest",
        },
      },
    },
    {
      variant: "outlined",
      color: "warning",
      css: {
        color: "$warningDark",
        backgroundColor: "transparent",
        borderColor: "$warning",
        "&:hover": {
          color: "$textLight",
          backgroundColor: "$warningDark",
          borderColor: "$warningDark",
        },
        "&:focus, &:active": {
          color: "$textLight",
          backgroundColor: "$warningDark",
          borderColor: "$warningDark",
          "$$pt-ringColor": "$colors$warningDark",
          "$$pt-ringOffsetColor": "$colors$warningLightest",
        },
      },
    },
    {
      variant: "outlined",
      color: "success",
      css: {
        color: "$successDark",
        backgroundColor: "transparent",
        borderColor: "$success",
        "&:hover": {
          color: "$textLight",
          backgroundColor: "$successDark",
          borderColor: "$successDark",
        },
        "&:focus, &:active": {
          color: "$textLight",
          backgroundColor: "$successDark",
          borderColor: "$successDark",
          "$$pt-ringColor": "$colors$successDark",
          "$$pt-ringOffsetColor": "$colors$successLightest",
        },
      },
    },
    {
      variant: "outlined",
      color: "red",
      css: {
        color: "$dangerDark",
        backgroundColor: "transparent",
        borderColor: "$danger",
        "&:hover": {
          color: "$textLight",
          backgroundColor: "$dangerDark",
          borderColor: "$dangerDark",
        },
        "&:focus, &:active": {
          color: "$textLight",
          backgroundColor: "$dangerDark",
          borderColor: "$dangerDark",
          "$$pt-ringColor": "$colors$dangerDark",
          "$$pt-ringOffsetColor": "$colors$dangerLightest",
        },
      },
    },
    {
      variant: "outlined",
      color: "danger",
      css: {
        color: "$dangerDark",
        backgroundColor: "transparent",
        borderColor: "$danger",
        "&:hover": {
          color: "$textLight",
          backgroundColor: "$dangerDark",
          borderColor: "$dangerDark",
        },
        "&:focus, &:active": {
          color: "$textLight",
          backgroundColor: "$dangerDark",
          borderColor: "$dangerDark",
          "$$pt-ringColor": "$colors$dangerDark",
          "$$pt-ringOffsetColor": "$colors$dangerLightest",
        },
      },
    },
    {
      variant: "outlined",
      color: "blue",
      css: {
        color: "$ptBlue600",
        backgroundColor: "transparent",
        borderColor: "$ptBlue600",
        "&:hover": {
          color: "$ptBlue900",
          backgroundColor: "$ptBlue400",
          borderColor: "$ptBlue400",
        },
        "&:focus": {
          color: "$ptBlue900",
          backgroundColor: "$ptBlue500",
          borderColor: "$ptBlue500",
          "$$pt-ringColor": "$colors$ptBlue500",
          "$$pt-ringOffsetColor": "$colors$ptBlue50",
        },
      },
    },
    {
      variant: "light",
      color: "gray",
      css: {
        color: "$ptBlack500",
        backgroundColor: "$primaryLight",
        borderColor: "$primaryLight",
        "&:hover": {
          color: "$ptBlack700",
          backgroundColor: "$ptGray600",
          borderColor: "$ptGray600",
        },
        "&:focus": {
          color: "$ptBlack700",
          backgroundColor: "$ptGray600",
          borderColor: "$ptGray600",
          "$$pt-ringColor": "$colors$primaryFocusRing",
          "$$pt-ringOffsetColor": "$colors$primaryFocusRingOffset",
        },
      },
    },
    {
      variant: "light",
      color: "green",
      css: {
        color: "$ptGreen700",
        backgroundColor: "$ptGreen200",
        borderColor: "$ptGreen200",
        "&:hover": {
          color: "$ptGreen900",
          backgroundColor: "$ptGreen300",
          borderColor: "$ptGreen300",
        },
        "&:focus": {
          color: "$ptGreen900",
          backgroundColor: "$ptGreen300",
          borderColor: "$ptGreen300",
          "$$pt-ringColor": "$colors$ptGreen500",
          "$$pt-ringOffsetColor": "$colors$ptGreen50",
        },
      },
    },
    {
      variant: "light",
      color: "success",
      css: {
        color: "$successDark",
        backgroundColor: "$successLightest",
        borderColor: "$successLightest",
        "&:hover": {
          color: "$successDarker",
          backgroundColor: "$successLighter",
          borderColor: "$successLighter",
        },
        "&:focus": {
          color: "$successDarker",
          backgroundColor: "$successLighter",
          borderColor: "$successLighter",
          "$$pt-ringColor": "$colors$successDark",
          "$$pt-ringOffsetColor": "$colors$successLightest",
        },
      },
    },

    {
      variant: "light",
      color: "yellow",
      css: {
        color: "$ptYellow800",
        backgroundColor: "$ptYellow200",
        borderColor: "$ptYellow200",
        "&:hover": {
          color: "$ptYellow900",
          backgroundColor: "$ptYellow300",
          borderColor: "$ptYellow300",
        },
        "&:focus": {
          color: "$ptYellow900",
          backgroundColor: "$ptYellow300",
          borderColor: "$ptYellow300",
          "$$pt-ringColor": "$colors$ptYellow500",
          "$$pt-ringOffsetColor": "$colors$ptYellow50",
        },
      },
    },
    {
      variant: "light",
      color: "red",
      css: {
        color: "$ptRed500",
        backgroundColor: "$ptRed100",
        borderColor: "$ptRed100",
        "&:hover": {
          color: "$ptRed700",
          backgroundColor: "$ptRed200",
          borderColor: "$ptRed200",
        },
        "&:focus": {
          color: "$ptRed700",
          backgroundColor: "$ptRed200",
          borderColor: "$ptRed200",
          "$$pt-ringColor": "$colors$ptRed500",
          "$$pt-ringOffsetColor": "$colors$ptRed50",
        },
      },
    },
    {
      variant: "light",
      color: "danger",
      css: {
        color: "$ptRed500",
        backgroundColor: "$ptRed100",
        borderColor: "$ptRed100",
        "&:hover": {
          color: "$ptRed700",
          backgroundColor: "$ptRed200",
          borderColor: "$ptRed200",
        },
        "&:focus": {
          color: "$ptRed700",
          backgroundColor: "$ptRed200",
          borderColor: "$ptRed200",
          "$$pt-ringColor": "$colors$ptRed500",
          "$$pt-ringOffsetColor": "$colors$ptRed50",
        },
      },
    },
    {
      variant: "light",
      color: "blue",
      css: {
        color: "$ptBlue700",
        backgroundColor: "$ptBlue200",
        borderColor: "$ptBlue200",
        "&:hover": {
          color: "$ptBlue900",
          backgroundColor: "$ptBlue300",
          borderColor: "$ptBlue300",
        },
        "&:focus": {
          color: "$ptBlue900",
          backgroundColor: "$ptBlue300",
          borderColor: "$ptBlue300",
          "$$pt-ringColor": "$colors$ptBlue500",
          "$$pt-ringOffsetColor": "$colors$ptBlue50",
        },
      },
    },
    {
      variant: "text",
      color: "gray",
      css: {
        color: "$text",
        backgroundColor: "transparent",
        borderColor: "transparent",
        "&:hover": {
          color: "$ptBlack700",
          backgroundColor: "transparent",
          borderColor: "$primaryLight",
        },
        "&:focus": {
          color: "$ptBlack700",
          backgroundColor: "transparent",
          borderColor: "transparent",
          "$$pt-ringColor": "$colors$primaryFocusRing",
          "$$pt-ringOffsetColor": "$colors$primaryFocusRingOffset",
        },
      },
    },
    {
      variant: "text",
      color: "primary",
      css: {
        color: "$primaryDark",
        backgroundColor: "transparent",
        borderColor: "transparent",
        "&:hover": {
          color: "$primaryDarkest",
          backgroundColor: "transparent",
          borderColor: "$primaryLighter",
        },
        "&:focus": {
          color: "$primaryDarkest",
          backgroundColor: "transparent",
          borderColor: "transparent",
          "$$pt-ringColor": "$colors$primary",
          "$$pt-ringOffsetColor": "$colors$primaryLightest",
        },
      },
    },
    {
      variant: "text",
      color: "green",
      css: {
        color: "$ptGreen600",
        backgroundColor: "transparent",
        borderColor: "transparent",
        "&:hover": {
          color: "$ptGreen800",
          backgroundColor: "transparent",
          borderColor: "$ptGreen200",
        },
        "&:focus": {
          color: "$ptGreen800",
          backgroundColor: "transparent",
          borderColor: "transparent",
          "$$pt-ringColor": "$colors$ptGreen500",
          "$$pt-ringOffsetColor": "$colors$ptGreen50",
        },
      },
    },
    {
      variant: "text",
      color: "success",
      css: {
        color: "$successDark",
        backgroundColor: "transparent",
        borderColor: "transparent",
        "&:hover": {
          color: "$successDarkest",
          backgroundColor: "transparent",
          borderColor: "$successLighter",
        },
        "&:focus": {
          color: "$successDarkest",
          backgroundColor: "transparent",
          borderColor: "transparent",
          "$$pt-ringColor": "$colors$success",
          "$$pt-ringOffsetColor": "$colors$successLightest",
        },
      },
    },
    {
      variant: "text",
      color: "yellow",
      css: {
        color: "$ptYellow600",
        backgroundColor: "transparent",
        borderColor: "transparent",
        "&:hover": {
          color: "$ptYellow800",
          backgroundColor: "transparent",
          borderColor: "$ptYellow200",
        },
        "&:focus": {
          color: "$ptYellow800",
          backgroundColor: "transparent",
          borderColor: "transparent",
          "$$pt-ringColor": "$colors$ptYellow500",
          "$$pt-ringOffsetColor": "$colors$ptYellow50",
        },
      },
    },
    {
      variant: "text",
      color: "warning",
      css: {
        color: "$warningDark",
        backgroundColor: "transparent",
        borderColor: "transparent",
        "&:hover": {
          color: "$warningDarkest",
          backgroundColor: "transparent",
          borderColor: "$warningLighter",
        },
        "&:focus": {
          color: "$warningDarkest",
          backgroundColor: "transparent",
          borderColor: "transparent",
          "$$pt-ringColor": "$colors$warning",
          "$$pt-ringOffsetColor": "$colors$warningLightest",
        },
      },
    },
    {
      variant: "text",
      color: "red",
      css: {
        color: "$ptRed500",
        backgroundColor: "transparent",
        borderColor: "transparent",
        "&:hover": {
          color: "$ptRed700",
          backgroundColor: "transparent",
          borderColor: "$ptRed100",
        },
        "&:focus": {
          color: "$ptRed700",
          backgroundColor: "transparent",
          borderColor: "transparent",
          "$$pt-ringColor": "$colors$ptRed500",
          "$$pt-ringOffsetColor": "$colors$ptRed50",
        },
      },
    },
    {
      variant: "text",
      color: "danger",
      css: {
        color: "$dangerDark",
        backgroundColor: "transparent",
        borderColor: "transparent",
        "&:hover": {
          color: "$dangerDarkest",
          backgroundColor: "transparent",
          borderColor: "$dangerLighter",
        },
        "&:focus": {
          color: "$dangerDarkest",
          backgroundColor: "transparent",
          borderColor: "transparent",
          "$$pt-ringColor": "$colors$danger",
          "$$pt-ringOffsetColor": "$colors$dangerLightest",
        },
      },
    },
    {
      variant: "text",
      color: "blue",
      css: {
        color: "$ptBlue600",
        backgroundColor: "transparent",
        borderColor: "transparent",
        "&:hover": {
          color: "$ptBlue800",
          backgroundColor: "transparent",
          borderColor: "$ptBlue200",
        },
        "&:focus": {
          color: "$ptBlue800",
          backgroundColor: "transparent",
          borderColor: "transparent",
          "$$pt-ringColor": "$colors$ptBlue500",
          "$$pt-ringOffsetColor": "$colors$ptBlue50",
        },
      },
    },
  ],
  defaultVariants: {
    size: "ptNormal",
    variant: "filled",
    color: "primary",
  },
});

const StyledButton = styled("button", buttonStyleConfig);

export type IconName = IconProp;

interface UtilButtonProps {
  as?: React.ElementType;
  css?: CSS;
  text?: string;
  loading?: boolean;
  loadingText?: string;
  // NOTE: We use IconName over IconProp (more correct) to help fix autocomplete usability but think we're hitting some vscode bugs still
  icon?: IconName;
  iconRight?: IconName;
}

export type ButtonProps = Stitches.VariantProps<typeof StyledButton> &
  UtilButtonProps &
  React.ComponentPropsWithRef<"button">;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, forwardedRef) => {
    const {
      text,
      icon,
      iconRight,
      loading,
      loadingText: incomingLoadingText,
      onClick,
      size,
      disabled,
      children,
      ...rest
    } = props;
    const loadingText =
      incomingLoadingText !== undefined ? incomingLoadingText : children;

    return (
      <StyledButton
        onClick={loading || disabled ? undefined : onClick}
        size={size}
        disabled={disabled}
        {...rest}
        ref={forwardedRef}
      >
        {loading && (
          <>
            <Spinner size={size} />
            {loadingText && <div>{loadingText}</div>}
          </>
        )}
        {!loading && (
          <>
            {icon && <Icon icon={icon} />}
            {Boolean(text || children) && (
              <div>
                {text}
                {children}
              </div>
            )}
            {iconRight && <Icon icon={iconRight} />}
          </>
        )}
      </StyledButton>
    );
  }
);
Button.displayName = "Button";

export type IconButtonProps = Omit<ButtonProps, "children">;

export const IconButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, forwardedRef) => {
    const { css, children, ...rest } = props;

    return (
      <Button
        size="normal"
        css={{
          padding: "$1",
          ...css,
        }}
        {...rest}
        ref={forwardedRef}
      />
    );
  }
);
IconButton.displayName = "IconButton";

export type NavigationButtonProps = ButtonProps;

export const NavigationButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, forwardedRef) => {
    return <Button size="normal" color="brand" {...props} ref={forwardedRef} />;
  }
);
NavigationButton.displayName = "NavigationButton";

export default Button;
