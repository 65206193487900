/**
 * @generated SignedSource<<3f115dd0863f694d5e28ba858ee81484>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NavBar2MarkAllAsReadMutation$variables = {};
export type NavBar2MarkAllAsReadMutation$data = {
  readonly markAllNotificationAsRead: {
    readonly ok: boolean | null;
  } | null;
};
export type NavBar2MarkAllAsReadMutation = {
  response: NavBar2MarkAllAsReadMutation$data;
  variables: NavBar2MarkAllAsReadMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "input",
        "value": {}
      }
    ],
    "concreteType": "MarkAllNotificationAsReadPayload",
    "kind": "LinkedField",
    "name": "markAllNotificationAsRead",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      }
    ],
    "storageKey": "markAllNotificationAsRead(input:{})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NavBar2MarkAllAsReadMutation",
    "selections": (v0/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NavBar2MarkAllAsReadMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "8700e3e0ec0c550f0e975406191cb30b",
    "id": null,
    "metadata": {},
    "name": "NavBar2MarkAllAsReadMutation",
    "operationKind": "mutation",
    "text": "mutation NavBar2MarkAllAsReadMutation {\n  markAllNotificationAsRead(input: {}) {\n    ok\n  }\n}\n"
  }
};
})();

(node as any).hash = "9e37905c040eccf1f590d162dd5b3305";

export default node;
