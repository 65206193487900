// @flow

import React from "react";
import { extendObservable, action, runInAction } from "mobx";
import { observer } from "mobx-react";
import NetworkState from "../../models/NetworkState";
import ModalState from "../../models/ModalState";
import JobLibraryTitle from "../../models/JobLibraryTitle";
import type MobXStore from "./MobXStore";
import type { FetchAPI, FetchGraphQL } from "../../App";
import { createTasteGraphQLWrapper } from "./SupportFunctions";
import Button from "../../components/lib/Button";

export default class JobLibraryEditCustomStore {
  fetchTasteGraphQL: FetchGraphQL;
  fetchGraphQL: FetchGraphQL;
  fetchAPI: FetchAPI;
  mobXStore: MobXStore;
  router: ?Object;
  network: NetworkState;
  successModal: ModalState;
  previousLocation: string;
  libraryId: string;
  libraryTitle: string;
  libraryDescription: ?string;
  libraryLoaded: boolean;
  title: string;
  description: string;
  libraryCurrentTitle: string;
  libraryCurrentDescription: string;
  selectedCount: number;
  titlesToAdd: number[];
  titlesToRemove: number[];
  titleError: string;
  confirmEmptyLibraryModal: ModalState;

  getLibrary: (string) => void;
  editLibrary: () => void;
  reset: () => void;
  showSuccess: () => void;
  isValid: () => void;
  goBack: () => void;

  handleSaveLibrary: () => void;
  handleConfirmEmptyLibraryCancel: () => void;
  handleConfirmEmptyLibrarySubmit: () => void;

  onTitleChange: (SyntheticEvent<HTMLInputElement>) => void;
  onDescriptionChange: (SyntheticEvent<HTMLInputElement>) => void;

  addRemoveClientJobTitleAction: (JobLibraryTitle) => any;
  handleAddRemoveClientJobTitleActionOnClick: (JobLibraryTitle) => void;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI, mobXStore: MobXStore) {
    this.fetchTasteGraphQL = createTasteGraphQLWrapper(fetchAPI);
    this.fetchGraphQL = fetchGraphQL;
    this.fetchAPI = fetchAPI;
    this.mobXStore = mobXStore;

    extendObservable(this, {
      successModal: new ModalState(),
      network: new NetworkState(),
      libraryLoaded: false,
      title: "",
      titleError: "",
      description: "",
      selectedCount: 0,
      titlesToAdd: [],
      titlesToRemove: [],
      previousLocation: "/job-library/library-requests/",
      confirmEmptyLibraryModal: new ModalState(),
    });

    this.router = null;

    this.getLibrary = action(this.getLibrary.bind(this));
    this.editLibrary = action(this.editLibrary.bind(this));
    this.reset = action(this.reset.bind(this));
    this.showSuccess = action(this.showSuccess.bind(this));
    this.isValid = action(this.isValid.bind(this));
    this.goBack = action(this.goBack.bind(this));
    this.onTitleChange = action(this.onTitleChange.bind(this));
    this.onDescriptionChange = action(this.onDescriptionChange.bind(this));
    this.addRemoveClientJobTitleAction = action(
      this.addRemoveClientJobTitleAction.bind(this)
    );
    this.handleAddRemoveClientJobTitleActionOnClick = action(
      this.handleAddRemoveClientJobTitleActionOnClick.bind(this)
    );
    this.handleSaveLibrary = action(this.handleSaveLibrary.bind(this));
    this.handleConfirmEmptyLibraryCancel = action(
      this.handleConfirmEmptyLibraryCancel.bind(this)
    );
    this.handleConfirmEmptyLibrarySubmit = action(
      this.handleConfirmEmptyLibrarySubmit.bind(this)
    );
  }

  handleConfirmEmptyLibraryCancel() {
    this.confirmEmptyLibraryModal.hideModal();
  }

  handleConfirmEmptyLibrarySubmit() {
    this.confirmEmptyLibraryModal.hideModal();
    this.editLibrary();
  }

  handleSaveLibrary(e: Event) {
    e.preventDefault();
    if (this.network.loading) {
      // NOTE: Debounces editLibrary
      return;
    }

    if (!this.isValid()) return;

    const selectedClientTitles =
      this.mobXStore.jobLibraryTitleListStore.getSelectedClientJobTitles();
    const userHasNotSelectedAnyTitles =
      !selectedClientTitles || selectedClientTitles.length === 0;
    const libraryWasEmptyToBeginWith = !this.selectedCount;
    if (userHasNotSelectedAnyTitles && libraryWasEmptyToBeginWith) {
      this.confirmEmptyLibraryModal.showModal();
      return;
    }

    this.editLibrary();
  }

  handleAddRemoveClientJobTitleActionOnClick(clientJobTitle: JobLibraryTitle) {
    if (clientJobTitle.viewState.selected) {
      // mark for removal if it's not marked for addition
      const indexFound = this.titlesToAdd.indexOf(clientJobTitle.id);
      if (indexFound >= 0) {
        this.titlesToAdd.splice(indexFound, 1);
      } else {
        this.titlesToRemove.push(clientJobTitle.id);
      }

      this.selectedCount -= 1;
    } else {
      // mark for addition if it's not marked for removal
      const indexFound = this.titlesToRemove.indexOf(clientJobTitle.id);
      if (indexFound >= 0) {
        this.titlesToRemove.splice(indexFound, 1);
      } else {
        this.titlesToAdd.push(clientJobTitle.id);
      }

      this.selectedCount += 1;
    }

    clientJobTitle.toggleSelected();
  }

  addRemoveClientJobTitleAction(clientJobTitle: JobLibraryTitle) {
    const AddRemoveAction = observer((props: Object) => (
      <Button
        color="brand"
        key={props.key}
        style={{ width: "100%" }}
        onClick={(e) => {
          e.stopPropagation();
          if (props.onClick) props.onClick(props.clientJobTitle);
        }}
      >
        {props.clientJobTitle.viewState.selected ? "Remove" : "Add"}
      </Button>
    ));

    return (
      <AddRemoveAction
        key={clientJobTitle.id}
        clientJobTitle={clientJobTitle}
        onClick={this.handleAddRemoveClientJobTitleActionOnClick}
      />
    );
  }

  async getLibrary(libraryId: string) {
    if (!libraryId) return;
    this.libraryId = libraryId;

    if (this.network.loading) return;

    const query = `
      query editCustomLibraryGetLibrary($libraryId: ID!){
        clientLibrary(databaseId: $libraryId) {
          databaseId
          name
          description
          clientJobTitles(first: 0) {
            totalCount
          }
        }
      }
    `;

    const variables = {
      libraryId: this.libraryId,
    };

    this.network.loading = true;
    let res: ?Object = null;

    try {
      res = await this.fetchTasteGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Getting Library", e);
      // TODO: Display user friendly error message
      return;
    }

    runInAction("getLibrary--success", () => {
      this.network.loading = false;
      this.network.error = null;

      if (this.network.logGraphQLError("getLibrary", res)) {
        // TODO: Display user friendly error message
        return;
      }

      if (res) {
        const { name, description, clientJobTitles } = res.data.clientLibrary;

        this.libraryLoaded = true;
        this.libraryTitle = name;
        this.libraryDescription = description;
        this.title = name;
        this.description = description;
        this.selectedCount = clientJobTitles.totalCount;
      }
    });
  }

  async editLibrary() {
    if (this.network.loading) {
      // NOTE: Debounces editLibrary
      return;
    }

    if (!this.isValid()) return;

    const query = `
      mutation editCustomLibrary(
        $libraryId: ID!,
        $libraryTitle: String,
        $libraryDescription: String,
        $clientJobTitlesToAdd: ClientJobTitleFiltersInput
        $clientJobTitlesToRemove: ClientJobTitleFiltersInput
        ){
        editCustomClientLibrary(input: {
            libraryId: $libraryId,
            libraryTitle: $libraryTitle,
            libraryDescription: $libraryDescription
            clientJobTitlesToAdd: $clientJobTitlesToAdd
            clientJobTitlesToRemove: $clientJobTitlesToRemove
          }) {

          success {
            message
          }

          errors {
            __typename
            ...on ErrorBase {
              message
            }
          }

          library {
            databaseId
          }
        }
      }
    `;

    const variables: {
      libraryId: string,
      libraryTitle?: string,
      libraryDescription?: string,
      clientJobTitlesToAdd?: any,
      clientJobTitlesToRemove?: any,
    } = {
      libraryId: this.libraryId,
    };

    if (this.libraryCurrentTitle !== this.title) {
      variables.libraryTitle = this.title;
    }

    if (this.libraryCurrentDescription !== this.description) {
      variables.libraryDescription = this.description;
    }

    if (this.titlesToAdd.length > 0) {
      variables.clientJobTitlesToAdd = { only: this.titlesToAdd };
    }

    if (this.titlesToRemove.length > 0) {
      variables.clientJobTitlesToRemove = { only: this.titlesToRemove };
    }

    this.network.loading = true;
    let res: ?Object = null;

    try {
      res = await this.fetchTasteGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Edit Library", e);
      // TODO: Display user friendly error message
      return;
    }

    runInAction("editLibrary--success", () => {
      this.network.loading = false;
      this.network.error = null;

      if (this.network.logGraphQLError("requestLibrary", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.mobXStore.jobLibraryTitleListStore.handleStopEdit();
      this.reset();
      this.mobXStore.jobLibraryListStore.pagination.goFetch().then(() => {
        if (res && this.router) {
          this.router.push(
            `/job-library/view/${res.data.editCustomClientLibrary.library.databaseId}/`
          );
        }
      });
    });
  }

  reset() {
    this.title = "";
    this.description = "";
    this.libraryTitle = "";
    this.libraryDescription = "";
    this.selectedCount = 0;
    this.titlesToAdd = [];
    this.titlesToRemove = [];
    this.successModal.hideModal();
  }

  isValid() {
    if (!this.title) this.titleError = "A title is required.";
    else this.titleError = "";

    return !this.titleError;
  }

  goBack() {
    if (this.router) {
      this.router.push({
        pathname: this.previousLocation,
        query: this.router.query,
      });
    }
  }

  showSuccess() {
    this.successModal.showModal();
  }

  onTitleChange(e: SyntheticEvent<HTMLInputElement>) {
    this.title = e.currentTarget.value;
    this.titleError = "";
  }

  onDescriptionChange(e: SyntheticEvent<HTMLInputElement>) {
    this.description = e.currentTarget.value;
  }
}
