import React from "react";

import Box from "../../lib/Box";
import { CheckboxItem } from "../../lib/Checkbox";
import { emptyOrderedMap, emptyList } from "../../../constants";
import { CSS } from "../../../stitches.config";
import { ImmutableList, ImmutableOrderedMap } from "../../../types/immutable";

export type ValueObject<T = any> = { key: string; value: T };
export type ValuesOrderedMap<T = any> = ImmutableOrderedMap<string, ValueObject<T>>;
export type SelectedValuesList<T = any> = ImmutableList<T>;
export type OnSelectValuesFunction = (values: SelectedValuesList) => void;

type ValuesCheckListProps = {
  values: ValuesOrderedMap;
  selectedValues: SelectedValuesList;
  onSelect: OnSelectValuesFunction;
  css?: CSS;
};

const ValuesCheckList = (props: ValuesCheckListProps) => {
  const { values, selectedValues, onSelect, css } = props;

  const handleItemChanged = React.useCallback(
    (value, isDelete) => {
      const valueIdx = isDelete ? selectedValues.indexOf(value) : -1;

      onSelect(
        isDelete && valueIdx >= 0
          ? selectedValues.delete(valueIdx)
          : selectedValues.push(value)
      );
    },
    [selectedValues, onSelect]
  );

  return (
    <>
      {values.toArray().map((item: ValueObject, idx: number) => {
        const { key, value } = item!;
        const isSelected = selectedValues.includes(value);

        return (
          <Box key={key || idx} css={{ width: "max-content", ...css }}>
            <CheckboxItem
              checked={isSelected}
              onCheckedChange={() => handleItemChanged(value, isSelected)}
            >
              {value}
            </CheckboxItem>
          </Box>
        );
      })}
    </>
  );
};
ValuesCheckList.displayName = "ValuesCheckList";
ValuesCheckList.defaultProps = {
  values: emptyOrderedMap,
  selectedValues: emptyList,
};

export default ValuesCheckList;
