import React, { useCallback, useState, useMemo } from "react";

import Icon from "../../../components/lib/Icon";
import Inline from "../../../components/lib/Inline";
import Text from "../../../components/lib/Text";
import Tooltip from "../../../components/lib/Tooltip";
import Box from "../../../components/lib/Box";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogClose,
  DialogDescription,
} from "../../../components/lib/Dialog";
import type { Values } from "../types";

const COMPONENT_MODES = {
  TOOLTIP: "tooltip",
  MODAL: "modal",
} as const;

type COMPONENT_MODES_TYPE = Values<typeof COMPONENT_MODES>;

type OptionalProps = Partial<{
  title: string;
  mode: COMPONENT_MODES_TYPE;
  hint: string;
  emptyStub: string;
  charLimit: number;
  label: React.ReactNode;
}>;

type TextProps = OptionalProps & {
  text: string;
  fullText?: never;
};

type FullTextProps = OptionalProps & {
  fullText: string | React.ReactElement;
  text?: string;
};

type Props = TextProps | FullTextProps;

export default function TextCrop(props: Props) {
  const {
    text,
    fullText,
    label,
    title = "Description",
    charLimit = 50,
    emptyStub = "No Description",
    hint = "Click to see full description",
    mode = COMPONENT_MODES.MODAL,
  } = props;

  const [showState, setShowState] = useState(false);

  const handleShow = useCallback(() => setShowState(true), [setShowState]);
  const handleHide = useCallback(() => setShowState(false), [setShowState]);

  const isCharLimitExceed = useMemo(() => {
    if (charLimit == null || text == null) return false;
    if (text == null) return false;
    return text.length > charLimit - 3;
  }, [text, charLimit]);

  if (!text && !label) {
    return <span>{emptyStub}</span>;
  }

  if (text && isCharLimitExceed) {
    if (mode === COMPONENT_MODES.MODAL) {
      return (
        <Text title={hint} css={{ cursor: "pointer" }}>
          <Text onClick={handleShow}>{text.slice(0, charLimit - 3) + "..."}</Text>

          <Dialog onOpenChange={handleHide} open={showState}>
            <DialogContent>
              <DialogTitle asChild>
                <Inline css={{ justifyContent: "space-between" }}>
                  <Text as="h4">{title}</Text>
                  <DialogClose asChild>
                    <Icon icon="times" />
                  </DialogClose>
                </Inline>
              </DialogTitle>
              <DialogDescription
                css={{ whiteSpace: "pre-wrap", backgroundColor: "inherit" }}
              >
                {fullText || text}
              </DialogDescription>
            </DialogContent>
          </Dialog>
        </Text>
      );
    } else if (mode === COMPONENT_MODES.TOOLTIP) {
      return (
        <Tooltip
          side="top"
          content={
            <Box css={{ width: 250, textAlign: "center", padding: "$1_5" }}>{text}</Box>
          }
        >
          <Text>{`${text.slice(0, charLimit - 3)}...`}</Text>
        </Tooltip>
      );
    }
  } else if (mode === COMPONENT_MODES.MODAL && fullText) {
    return (
      <Text title={hint} css={{ cursor: "pointer" }}>
        <Text onClick={handleShow}>{text || label}</Text>

        <Dialog onOpenChange={handleHide} open={showState}>
          <DialogContent>
            <DialogTitle asChild>
              <Inline css={{ justifyContent: "space-between" }}>
                <Text as="h4">{title}</Text>
                <DialogClose asChild>
                  <Icon icon="times" />
                </DialogClose>
              </Inline>
            </DialogTitle>
            <DialogDescription
              css={{ whiteSpace: "pre-wrap", backgroundColor: "inherit" }}
            >
              {fullText}
            </DialogDescription>
          </DialogContent>
        </Dialog>
      </Text>
    );
  }

  return <Text>{text}</Text>;
}

TextCrop.displayName = "TextCrop";
