import React from "react";

import { useSearchInputState, ValuesBlock } from "./BaseValuesFilter";
import {
  ValuesCheckFilter,
  ValuesCheckFilterProps,
  useValuesSource,
  loadingBlock,
} from "./ValuesCheckFilter";
import ValuesList from "./ValuesList";
import { ValuesOrderedMap } from "./ValuesCheckList";
import Box from "../../lib/Box";
import Stack from "../../lib/Stack";
import { FilterTypes } from "../constants";
import { RowData } from "../types";
import { Activatable } from "./types";

type ValuesListBlockProps = {
  emptyHint: React.ReactNode;
  values: ValuesOrderedMap;
  loading?: boolean;
  onScroll?: React.UIEventHandler<HTMLDivElement>;
};

export const ValuesListBlock = (props: ValuesListBlockProps) => {
  const { emptyHint, values, loading, onScroll } = props;

  return (
    <Box fill>
      <Stack
        fill
        onScroll={onScroll}
        css={{
          alignItems: "stretch",
          height: "170px",
          padding: "$2 $3",
          border: "1px solid $primaryLight",
          borderRadius: "$rounded",
          gap: "$2",
          overflow: "auto",
        }}
      >
        {values.size === 0 && (loading ? loadingBlock : emptyHint)}
        {values.size > 0 && <ValuesList values={values} />}
        {values.size > 0 && loading && loadingBlock}
      </Stack>
    </Box>
  );
};
ValuesListBlock.displayName = "ValuesListBlock";

type ValuesIContainsFilterProps<RD = RowData> = ValuesCheckFilterProps<RD>;

const ValuesIContainsFilter = React.forwardRef(
  <RD = RowData,>(
    props: ValuesIContainsFilterProps<RD>,
    ref: React.ForwardedRef<Activatable>
  ) => {
    const {
      filter,
      onChange,
      emptyHint,
      searchTimeout,
      dataProvider,
      dataProviderFilters,
      getter,
      idGetter,
      comparators,
    } = props;

    const orderDirection = filter.getIn(["order", "direction"]);
    const searchValue = filter.getIn(["filter", "search"]);

    const { makeSearch, setSearchInputRef, setSearchFocus } = useSearchInputState(
      searchValue,
      filter,
      FilterTypes.VALUES_ICONTAINS,
      onChange
    );

    const { values, valuesSource } = useValuesSource(
      dataProvider,
      dataProviderFilters,
      searchValue,
      orderDirection,
      comparators,
      idGetter,
      getter
    );

    React.useEffect(() => {
      valuesSource();
    }, [searchValue, valuesSource]);

    React.useImperativeHandle(ref, () => ({
      initialize: valuesSource,
      focus: setSearchFocus,
    }));

    return (
      <ValuesBlock
        searchValue={searchValue}
        searchTimeout={searchTimeout!}
        inputRef={setSearchInputRef}
        onSearch={makeSearch}
      >
        <ValuesListBlock values={values} emptyHint={emptyHint} />
      </ValuesBlock>
    );
  }
) as {
  <RD = RowData>(
    props: ValuesIContainsFilterProps<RD> & { ref?: React.ForwardedRef<Activatable> }
  ): React.ReactElement;
  displayName: string;
  defaultProps: Partial<ValuesIContainsFilterProps>;
};

ValuesIContainsFilter.displayName = "ValuesIContainsFilter";
ValuesIContainsFilter.defaultProps = ValuesCheckFilter.defaultProps;

export default ValuesIContainsFilter;
