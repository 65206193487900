// @flow
import React, { Component } from "react";
import { observer } from "mobx-react";
import PlainHighChart from "../../ratecards/components/PlainHighChart";
import numeral from "numeral";

type Props = {
  maxData: number[],
  minData?: ?(number[]),
  levelColor: string,
  secondaryColor: string,
  currencySymbol?: ?string,
  binStart: number,
  binSize: number,
};

function hexToRgb(hex) {
  if (!hex) return null;

  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

const SalaryHistogramChart = observer(
  class extends Component<Props> {
    render() {
      const props = this.props;
      const primaryRGB = hexToRgb(props.levelColor);
      const secondaryRGB = hexToRgb(props.secondaryColor);

      const primaryRGBA = primaryRGB
        ? `rgba(${primaryRGB.r}, ${primaryRGB.g}, ${primaryRGB.b}, .6)`
        : null;
      const secondaryRGBA = secondaryRGB
        ? `rgba(${secondaryRGB.r}, ${secondaryRGB.g}, ${secondaryRGB.b}, .6)`
        : null;

      const currencySymbol = props.currencySymbol || "";

      let chartConfig = {
        chart: {
          type: "column",
          zoomType: "xy",
        },
        title: null,
        xAxis: {
          // title: {
          //   enabled: true,
          //   text: "Salary"
          // },
          // crosshair: true,
          // endOnTick: true,
          // startOnTick: true,
          // showLastLabel: true,
          // tickAmount: tickAmount,
          // min: props.binStart
          tickInterval: props.binSize,
          // labels: {
          //   formatter: function() {
          //     const x = numeral(this.value).format("Oa");
          //     return `${currencySymbol} ${x}`;
          //   }
          // }
        },
        yAxis: {
          title: {
            text: "Job Count",
          },
          min: 0,
          allowDecimals: false,
        },
        plotOptions: {
          column: {
            pointPlacement: "between",
            pointStart: props.binStart,
            pointRange: props.binSize,
            pointInterval: props.binSize,
            pointPadding: 0,
            borderWidth: 0,
            groupPadding: 0,
            shadow: false,
            grouping: false,
          },
        },
        tooltip: {
          shared: true,
          formatter: function () {
            const rangeStart = currencySymbol + " " + numeral(this.x).format("0,0.00");
            const rangeEnd =
              currencySymbol +
              " " +
              numeral(Number(this.x) + props.binSize).format("0,0.00");

            let tooltip = `<b>${rangeStart} - ${rangeEnd}</b><br/>`;
            this.points.forEach((point) => {
              const seriesName = point.series.name;
              tooltip += `${seriesName}: <b>${point.y} Jobs</b><br/>`;
            });

            return tooltip;
          },
        },
        series: [
          {
            name: "Min Salary",
            color: secondaryRGBA,
            data: props.minData,
            // marker: {
            //   states: {
            //     hover: {
            //       fillColor: props.secondaryColor
            //     }
            //   }
            // }
          },
          {
            name: "Max Salary",
            color: primaryRGBA,
            data: props.maxData,
            // marker: {
            //   states: {
            //     hover: {
            //       fillColor: props.levelColor
            //     }
            //   }
            // }
          },
        ],
      };

      return (
        <div className="chart-container" style={{ height: 426, width: "100%" }}>
          <PlainHighChart options={chartConfig} style={{ height: 426, width: "100%" }} />
        </div>
      );
    }
  }
);

export default SalaryHistogramChart;
