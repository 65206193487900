import React, { useCallback } from "react";

import Text from "../../lib/Text";
import Stack from "../../lib/Stack";
import Icon from "../../lib/Icon";
import { CheckboxItem } from "../../lib/Checkbox";
import Inline from "../../lib/Inline";
import { OrderDirections } from "../constants";
import { FilterConfigMap } from "../types";
import { OnChangeFilterValueFunction } from "./types";

type OrderFilterProps = {
  filter: FilterConfigMap;
  onChange: OnChangeFilterValueFunction;
};

const OrderFilter = (props: OrderFilterProps) => {
  const { filter, onChange } = props;

  const direction = filter.getIn(["order", "direction"]);

  const handleChangeOrder = useCallback(
    (value) => {
      const newValue = value !== direction ? value : OrderDirections.NO;

      onChange(filter.setIn(["order", "direction"], newValue) as FilterConfigMap);
    },
    [direction, filter, onChange]
  );

  return (
    <Stack css={{ alignItems: "flex-start", gap: "$2" }}>
      <span>Select sorting direction:</span>
      <CheckboxItem
        checked={direction === OrderDirections.ASC}
        onCheckedChange={() => handleChangeOrder(OrderDirections.ASC)}
        css={{ marginLeft: "$2" }}
      >
        <Inline css={{ gap: "$2" }}>
          <Icon icon="sort-alpha-up" css={{ color: "$primary" }} />
          <Text css={{ fontWeight: "$semibold" }}>Sort Ascending</Text>
        </Inline>
      </CheckboxItem>

      <CheckboxItem
        checked={direction === OrderDirections.DSC}
        onCheckedChange={() => handleChangeOrder(OrderDirections.DSC)}
        css={{ marginLeft: "$2" }}
      >
        <Inline css={{ gap: "$2" }}>
          <Icon icon="sort-alpha-down" css={{ color: "$primary" }} />
          <Text css={{ fontWeight: "$semibold" }}>Sort Descending</Text>
        </Inline>
      </CheckboxItem>
    </Stack>
  );
};
OrderFilter.displayName = "OrderFilter";

export default OrderFilter;
