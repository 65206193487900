import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import update from "immutability-helper";
import R from "ramda";
import debounce from "lodash.debounce";
import Highcharts from "highcharts/highstock";

let darkTheme = {
  lang: {
    decimalPoint: ".",
    thousandsSep: ",",
  },
  colors: [
    "#8DE0A6",
    "#4EB3DE",
    "#FCF09F",
    "#F27C7C",
    "#eeaaee",
    "#DE528C",
    "#7798BF",
    "#aaeeee",
    "#55BF3B",
    "#DF5353",
    "#7798BF",
  ],
  chart: {
    spacing: 30,
    backgroundColor: {
      linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
      stops: [
        [0, "#424242"],
        [1, "#424242"],
      ],
    },
    style: {
      fontFamily: "'Unica One', sans-serif",
    },
    plotBorderColor: "#606063",
  },
  title: {
    style: {
      color: "#F4F4F4",
      // textTransform: 'uppercase',
      fontSize: "18px",
    },
  },
  subtitle: {
    style: {
      color: "#F4F4F4",
      // textTransform: 'uppercase'
    },
  },
  xAxis: {
    gridLineWidth: 0,
    gridLineColor: "#707073",
    labels: {
      style: {
        color: "#9E9E9E",
        // fontSize: '14px'
      },
    },
    lineColor: "#707073",
    minorGridLineColor: "#505053",
    tickColor: "#707073",
    title: {
      style: {
        color: "#A0A0A3",
      },
    },
  },
  yAxis: {
    gridLineWidth: 1,
    gridLineColor: "#707073",
    labels: {
      style: {
        color: "#9E9E9E",
        // fontSize: '14px'
      },
    },
    lineColor: "#707073",
    minorGridLineColor: "#505053",
    tickColor: "#707073",
    tickWidth: 1,
    title: {
      style: {
        color: "#A0A0A3",
      },
    },
  },
  tooltip: {
    backgroundColor: "rgba(51, 51, 51, 0.85)",
    style: {
      color: "#F4F4F4",
    },
    headerFormat: '<span style="font-size: 14px"><b>{point.key}</b></span><br/><br/>',
  },
  plotOptions: {
    lineWidth: 2,
    series: {
      dataLabels: {
        color: "#B0B0B3",
      },
      marker: {
        lineColor: "#333",
      },
    },
    boxplot: {
      fillColor: "#505053",
    },
    candlestick: {
      lineColor: "white",
    },
    errorbar: {
      color: "#F4F4F4",
    },
  },
  legend: {
    itemStyle: {
      color: "#E0E0E3",
    },
    itemHoverStyle: {
      color: "#F4F4F4",
    },
    itemHiddenStyle: {
      color: "#606063",
    },
  },
  credits: {
    style: {
      color: "#666",
    },
  },
  labels: {
    style: {
      color: "#707073",
    },
  },

  drilldown: {
    activeAxisLabelStyle: {
      color: "#F0F0F3",
    },
    activeDataLabelStyle: {
      color: "#F0F0F3",
    },
  },

  navigation: {
    buttonOptions: {
      symbolStroke: "#DDDDDD",
      theme: {
        fill: "#505053",
      },
    },
  },

  // scroll charts
  rangeSelector: {
    buttonTheme: {
      fill: "#505053",
      stroke: "#000000",
      style: {
        color: "#CCC",
      },
      states: {
        hover: {
          fill: "#707073",
          stroke: "#000000",
          style: {
            color: "white",
          },
        },
        select: {
          fill: "#000003",
          stroke: "#000000",
          style: {
            color: "white",
          },
        },
      },
    },
    inputBoxBorderColor: "#505053",
    inputStyle: {
      backgroundColor: "#333",
      color: "silver",
    },
    labelStyle: {
      color: "silver",
    },
  },

  navigator: {
    handles: {
      backgroundColor: "#666",
      borderColor: "#AAA",
    },
    outlineColor: "#CCC",
    maskFill: "rgba(255,255,255,0.1)",
    series: {
      color: "#7798BF",
      lineColor: "#A6C7ED",
    },
    xAxis: {
      gridLineColor: "#FFF",
    },
  },

  scrollbar: {
    barBackgroundColor: "#808083",
    barBorderColor: "#808083",
    buttonArrowColor: "#CCC",
    buttonBackgroundColor: "#606063",
    buttonBorderColor: "#606063",
    rifleColor: "#FFF",
    trackBackgroundColor: "#404043",
    trackBorderColor: "#404043",
  },

  // special colors for some of the
  legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
  background2: "#505053",
  dataLabelsColor: "#B0B0B3",
  textColor: "#C0C0C0",
  contrastTextColor: "#F0F0F3",
  maskColor: "rgba(255,255,255,0.3)",
};

let defaultTheme = {
  lang: {
    decimalPoint: ".",
    thousandsSep: ",",
  },
  colors: [
    "#BED146",
    "#7cb5ec",
    "#A2B624",
    "#7798BF",
    "#F49D54",
    "#82C141",
    "#BC3F6B",
    "#aaeeee",
    "#90ee7e",
    "#aaeeee",
    "#ff0066",
    "#eeaaee",
  ],

  chart: {
    spacing: 30,
    style: {
      fontFamily: "Arial, sans-serif",
      color: "#444444",
    },
    backgroundColor: "#FFF",
  },
  title: {
    style: {
      color: "inherit",
      // textTransform: 'uppercase',
      fontSize: "18px",
    },
  },
  tooltip: {
    backgroundColor: "rgba(251, 251, 251, 0.95)",
    style: {
      color: "#333333",
    },
    headerFormat: '<span style="font-size: 14px"><b>{point.key}</b></span><br/><br/>',
  },
  legend: {
    itemStyle: {
      color: "#888",
      fontWeight: "normal",
    },
    itemHoverStyle: {
      color: "#666",
      fontWeight: "normal",
    },
    itemHiddenStyle: {
      color: "#CCC",
      fontWeight: "normal",
    },
  },
  labels: {
    style: {
      color: "#888",
    },
  },
  xAxis: {
    gridLineWidth: 1,
    gridLineColor: "#F3F3F3",
    lineColor: "#F3F3F3",
    minorGridLineColor: "#F3F3F3",
    tickColor: "#F3F3F3",
    tickWidth: 1,
    title: {
      style: {
        color: "#888",
      },
    },
  },
  yAxis: {
    gridLineColor: "#F3F3F3",
    lineColor: "#F3F3F3",
    minorGridLineColor: "#F3F3F3",
    tickColor: "#F3F3F3",
    tickWidth: 1,
    title: {
      style: {
        color: "#888",
      },
    },
  },
  legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
  background2: "#505053",
  dataLabelsColor: "#B0B0B3",
  textColor: "#C0C0C0",
  contrastTextColor: "#F0F0F3",
  maskColor: "rgba(255,255,255,0.3)",
};

let _highChartsOptionsUpdated = false;

export default class PlainHighStockChart extends Component {
  static displayName = "PlainHighStockChart";
  static propTypes = {
    options: PropTypes.object,
    theme: PropTypes.string,
    // series: PropTypes.array
  };

  static defaultProps = {
    options: {},
    theme: "default", // values: 'default', 'dark'
    style: {
      width: "100%",
      overflow: "visible",
    },
  };

  componentDidMount() {
    this._chart = null;
    this._debouncedUpdate = debounce(
      () => {
        if (this._chart) {
          // TODO: This chart expects something like 12 series of data
          this._chart.series[0].setData(this.props.series);
        }
      },
      250,
      { maxWait: 250 }
    );
    this.renderOrUpdateChart();
  }

  componentDidUpdate(prevProps) {
    this.renderOrUpdateChart(prevProps);
  }

  componentWillUnmount() {
    if (this._chart) {
      this._chart.destroy();
      this._chart = null;
    }
    if (this._debouncedUpdate) {
      this._debouncedUpdate.cancel();
      this._debouncedUpdate = null;
    }
  }

  _getOrInitChart(useOldChart = false) {
    if (this._chart && useOldChart) {
      return [false, this._chart];
    }

    Highcharts.win = window;

    if (this.props.theme === "dark") {
      Highcharts.theme = darkTheme;
    } else {
      Highcharts.theme = defaultTheme; // this is not setting default theme, we have to create the default theme
    }
    Highcharts.setOptions(Highcharts.theme);

    const domNode = ReactDOM.findDOMNode(this);

    let options = this.props.options;
    if (!options.chart) {
      options = update(options, { chart: { $set: {} } });
    }

    options = update(options, {
      chart: { renderTo: { $set: domNode } },
    });

    if (_highChartsOptionsUpdated) {
      _highChartsOptionsUpdated = true;
    }

    if (this._debouncedUpdate) {
      this._debouncedUpdate.cancel();
    }

    this._chart = new Highcharts.stockChart(options);
    return [true, this._chart];
  }

  renderOrUpdateChart(prevProps = {}) {
    const [isNewChart, chart] = this._getOrInitChart(
      R.equals(prevProps.options, this.props.options) &&
        this.props.type === prevProps.type
    );

    if (!isNewChart && !R.equals(prevProps.series, this.props.series)) {
      if (this._debouncedUpdate) {
        this._debouncedUpdate();
      } else {
        chart.series[0].setData(this.props.series);
      }
    }
  }

  render() {
    return <div style={this.props.style} className={this.props.className} />;
  }
}
