// @flow

import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBox from "../../ratecards/components/SearchBox";
import ProjectCostEstimateList from "./components/ProjectCostEstimatesList";
import Modal from "../../ratecards/components/Modal";
import classNames from "classnames";

/**
 * ShareWithUsersList - user list for share by filter type
 * @return {void}
 */

/**
 * List component for project cost estimate list page
 */
class List extends Component {
  componentDidMount() {
    this.props.store.projectCostEstimatePTStore.pagination.goFetch();
    this.props.store.projectCostEstimatePTStore.projectCostId = null;
    this.props.store.projectCostEstimatePTStore.projectCost = null;
  }
  /**
   * Render project Cost list page
   * @return {XML}
   */
  render() {
    const store = this.props.store.projectCostEstimatePTStore;

    return (
      <div className="view list">
        <div className="rc-container bring-forward">
          <div className="header-action-bar">
            <h2>Project Cost Estimate Admin</h2>
            <p className="subtext" />
            <div className="clearfix" />
          </div>
          <div className="header-action-bar marginTopzero">
            <div className="pull-left">
              <SearchBox
                value={store.projectCostFilter.textToLookFor}
                onChange={store.projectCostFilter.onTextToLookForChange}
                onSearch={store.projectCostFilter.onApply}
                style={{
                  width: "260px",
                }}
              />
            </div>
            {
              <div
                className="btn-group pull-right rc-center"
                style={{
                  height: 40,
                }}
              >
                {store.isEditing && (
                  <button className="btn btn-yellow" onClick={store.handleStopEdit}>
                    Stop Editing
                  </button>
                )}
                {!store.isEditing && (
                  <div>
                    <button className="btn btn-green" onClick={store.handleStartEdit}>
                      <FontAwesomeIcon icon="edit" fixedWidth className="icon" /> Edit
                    </button>
                    <button className="btn" onClick={store.helpModal.showModal}>
                      <FontAwesomeIcon
                        icon="question-circle"
                        fixedWidth
                        className="icon"
                      />{" "}
                      Help
                    </button>
                  </div>
                )}
              </div>
            }
            <div className="clearfix" />
          </div>
          <ProjectCostEstimateList store={store} />
        </div>

        {
          <div
            className={classNames("ratecards-edit-actions", {
              hidden: store.isEditing === null,
              "bottom-appear": store.isEditing === true,
              "bottom-disappear": store.isEditing === false,
            })}
          >
            <button
              className="btn"
              disabled={store.selectedCount === 0}
              onClick={store.export}
            >
              <FontAwesomeIcon icon="download" fixedWidth className="icon" />
              Export
            </button>
          </div>
        }
        {
          <div
            className={classNames("rc-container-backdrop", {
              hidden: store.isEditing === null,
              "fade-in": store.isEditing === true,
              "fade-out": store.isEditing === false,
            })}
          />
        }
        <Modal show={store.helpModal.show} onHide={store.helpModal.hideModal}>
          <div className="container-section header">
            <h4>Help</h4>
          </div>
          <div className="container-section">
            <p>
              The Project Cost Estimate Worksheets you have created are listed below. By
              going to the Details page you will be able to add/remove jobs to that
              current project.
            </p>
          </div>
          <div className="container-section footer">
            <div className="flex center-items">
              <div className="flex-fill" />
              <button className="btn btn-lg" onClick={store.helpModal.hideModal}>
                Close
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

List.propTypes = {
  store: PropTypes.object.isRequired,
};

export default observer(List);
