import React from "react";

import { emptyMap, emptyOrderedMap } from "../../../../constants";
import { useReviewDataContext } from "../../../validator5K_admin/context/ReviewDataContext";
import { useAttemptDataContext } from "../AttemptDataContext";

import {
  RatesFeedbackOrderedMap,
  RateResultDataObject,
  RateResultsOrderedMap,
  RATE_FEEDBACK_STATUS_TYPES,
  RatesFeedbackDataObject,
  RatesFeedbackDataMap,
  AttemptDataMap,
} from "../../types";
import { ImmutableArbitraryMap } from "../../../../types/immutable";
import { checkMissingClassicRatesFeedback } from "../../utils";

type EditingRatesPartsMap = ImmutableArbitraryMap<
  RateResultDataObject["id"],
  "rates" | "comment"
>;

type ClassicRatesFeedbackStateObject = {
  ratesFeedback: RatesFeedbackOrderedMap;
  editingRatesIds: EditingRatesPartsMap;
};

export type ClassicRatesFeedbackContextObject = ClassicRatesFeedbackStateObject & {
  rateResults: RateResultsOrderedMap;
  //
  hasUncommittedChanges: boolean;
  hasMissingRateFeedback: boolean;
  isAllRateCardsSkipped: boolean;
  //
  startEditingFeedback: (
    rateResultId: RateResultDataObject["id"],
    part: "rates" | "comment"
  ) => void;
  stopEditingFeedback: (rateResultId: RateResultDataObject["id"]) => void;
  updateFeedbackState: (
    rateResultId: RateResultDataObject["id"],
    data: Partial<RatesFeedbackDataObject>
  ) => void;
  updateAttemptFeedbackState: (
    rateResultId: RateResultDataObject["id"],
    data: RatesFeedbackDataMap
  ) => void;
};

export const ClassicRatesFeedbackContext =
  React.createContext<ClassicRatesFeedbackContextObject>({
    rateResults: emptyOrderedMap, // in local currency
    ratesFeedback: emptyOrderedMap, // in local currency
    editingRatesIds: emptyMap,
    //
    hasUncommittedChanges: false,
    hasMissingRateFeedback: false,
    isAllRateCardsSkipped: false,
    //
    startEditingFeedback: undefined,
    stopEditingFeedback: undefined,
    updateFeedbackState: undefined,
    updateAttemptFeedbackState: undefined,
  } as unknown as ClassicRatesFeedbackContextObject);

export const useClassicRatesFeedbackContext = () => {
  return React.useContext(ClassicRatesFeedbackContext);
};

export const useCreateClassicRatesFeedbackContext =
  (): ClassicRatesFeedbackContextObject => {
    const { firstJobRateResults: rateResults } = useReviewDataContext();
    const {
      attemptData: initialAttemptData,
      ratesFeedback: initialRatesFeedback,
      updateAttemptDataState,
    } = useAttemptDataContext();

    // state

    const [state, setState] = React.useState<ClassicRatesFeedbackStateObject>({
      ratesFeedback: initialRatesFeedback, // in local currency
      editingRatesIds: emptyMap as unknown as EditingRatesPartsMap,
    });
    const { ratesFeedback, editingRatesIds } = state;

    // effects

    // Handles the case when user skipping the whole survey and the form need to cleanup prev feedback state
    const prevInitialRatesFeedbackRef = React.useRef(initialRatesFeedback);
    React.useEffect(() => {
      if (
        ratesFeedback?.size > 0 &&
        prevInitialRatesFeedbackRef.current?.size > 0 &&
        initialRatesFeedback?.size === 0
      ) {
        setState((prevState) => ({
          ...prevState,
          ratesFeedback: initialRatesFeedback,
        }));
      }
      prevInitialRatesFeedbackRef.current = initialRatesFeedback;
    }, [initialRatesFeedback, ratesFeedback]);

    // derivatives

    const hasUncommittedChanges: boolean = React.useMemo(
      () => !!editingRatesIds?.size,
      [editingRatesIds]
    );

    const hasMissingRateFeedback = React.useMemo(
      () => checkMissingClassicRatesFeedback(rateResults, ratesFeedback),
      [rateResults, ratesFeedback]
    );

    const isAllRateCardsSkipped = React.useMemo(() => {
      const allFeedbacksIdsSet = ratesFeedback.keySeq().toSetSeq();
      const skippedFeedbacksIdsSet = ratesFeedback
        .filter((item) => item?.get("status") === RATE_FEEDBACK_STATUS_TYPES.DONT_KNOW)
        .keySeq()
        .toSetSeq();
      return skippedFeedbacksIdsSet.equals(allFeedbacksIdsSet);
    }, [ratesFeedback]);

    // handlers

    const startEditingFeedback = React.useCallback(
      (rateResultId: RateResultDataObject["id"], part: "rates" | "comment") => {
        if (!editingRatesIds.has(rateResultId)) {
          setState((prevState) => ({
            ...prevState,
            editingRatesIds: prevState.editingRatesIds.set(rateResultId, part),
          }));
        }
      },
      [editingRatesIds]
    );

    const stopEditingFeedback = React.useCallback(
      (rateResultId: RateResultDataObject["id"]) => {
        if (editingRatesIds.has(rateResultId)) {
          setState((prevState) => ({
            ...prevState,
            // roll-back feedback to the prev state
            ratesFeedback: prevState.ratesFeedback.set(
              rateResultId,
              initialRatesFeedback.get(rateResultId)
            ),
            editingRatesIds: prevState.editingRatesIds.delete(rateResultId),
          }));
        }
      },
      [editingRatesIds, initialRatesFeedback]
    );

    const updateFeedbackState = React.useCallback(
      (
        rateResultId: RateResultDataObject["id"],
        data: Partial<RatesFeedbackDataObject>
      ) => {
        setState((prevState) => {
          const prevRatesFeedback =
            prevState.ratesFeedback.get(rateResultId) ??
            (emptyMap as RatesFeedbackDataMap);
          const nextRatesFeedback = prevRatesFeedback.merge(data) as RatesFeedbackDataMap;

          return {
            ...prevState,
            ratesFeedback: prevState.ratesFeedback.set(rateResultId, nextRatesFeedback),
          };
        });
      },
      []
    );

    const updateAttemptFeedbackState = React.useCallback(
      (
        rateResultId: RateResultDataObject["id"],
        ratesFeedbackData: RatesFeedbackDataMap
      ) => {
        if (initialRatesFeedback.get(rateResultId) !== ratesFeedbackData) {
          updateAttemptDataState(
            initialAttemptData.set(
              "rates_feedback",
              initialRatesFeedback.set(
                rateResultId,
                ratesFeedbackData
              ) as RatesFeedbackOrderedMap
            ) as AttemptDataMap
          );
        }
      },
      [initialAttemptData, initialRatesFeedback, updateAttemptDataState]
    );

    return {
      rateResults,
      ratesFeedback,
      editingRatesIds,
      hasUncommittedChanges,
      hasMissingRateFeedback,
      isAllRateCardsSkipped,
      //
      startEditingFeedback,
      stopEditingFeedback,
      updateFeedbackState,
      updateAttemptFeedbackState,
    };
  };

export default ClassicRatesFeedbackContext;
