// @flow
import * as React from "react";
import AsyncSelect from "react-select/lib/Async";
import { reactSelectStyles, reactSelectTheme } from "../../constants/reactSelect";

type role = { roleId: number, name: string };
type roleNode = { node: role };
type roleOption = { value: number, label: string };

type Props = {
  RoleTitle: (string) => Promise<{ roles: Array<roleNode> }>,
  value?: roleOption,
  disabled?: boolean,
};

type State = {
  selected: false,
};

class MultiRoleSelect extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selected: false,
    };
  }

  getOptions = (input: string, callback: (Array<roleOption>) => void) => {
    var roleList = [];
    this.props
      .RoleTitle(input)
      .then((response) => {
        response.roles.forEach((e) => {
          var optionItem = {};
          optionItem["value"] = e.node.roleId;
          optionItem["label"] = e.node.name.toString();
          roleList.push(optionItem);
        });
        callback(roleList);
      })
      .catch(function (error) {
        callback([]);
      });
  };

  handleChange = (value: ?Array<roleOption>, actionMeta: Object) => {
    if (value) {
      this.props.onChange(
        value.map((role: roleOption) => ({
          roleId: role.value,
          name: role.label,
        }))
      );
    } else {
      this.props.onChange([]);
    }
  };

  render() {
    return (
      <div>
        <AsyncSelect
          name="role"
          placeholder="Role"
          defaultOptions
          cacheOptions={false}
          loadOptions={this.getOptions}
          value={this.props.value}
          backspaceRemoves={true}
          onChange={(value, labelKey) => {
            this.handleChange(value, labelKey);
          }}
          openMenuOnFocus
          isMulti
          isDisabled={this.props.disabled}
          styles={reactSelectStyles}
          theme={reactSelectTheme}
        />
      </div>
    );
  }
}

export default MultiRoleSelect;
