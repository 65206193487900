// @flow

import { extendObservable, action, runInAction } from "mobx";
import MessageState from "../../models/MessageState";
import { browserHistory } from "react-router";
import NetworkState from "../../models/NetworkState";
import {
  PAY_TYPE_OPTIONS,
  SALARY_RATE_TYPE_OPTIONS,
  RATE_TYPE_OPTIONS,
  PAY_TYPES,
  LEGACY_COUNTRIES_IDS,
} from "../../constants/negotiationWorksheet";
import {
  fieldValueTypeEq,
  workerTypesContains,
  all,
  rateTypeContains,
} from "../../views/negotiation_worksheets/utils/filter";
import sumFieldsReducer from "../../views/negotiation_worksheets/utils/sumFieldsReducer";
import fieldValueGetter from "../../views/negotiation_worksheets/utils/fieldValueGetter";
import { Map } from "immutable";
import type MobXStore from "./MobXStore";
import type { FetchGraphQL, FetchAPI } from "../../App";
import checkFieldUpdated from "../../views/negotiation_worksheets/utils/checkFieldUpdated";
import { sanitizeInput } from "../../utils/dom";

// import type MobXStore from "./MobXStore";

export default class NegotiationWorksheetCreateStore {
  mobxStore: MobXStore;
  network: NetworkState;
  updateNetwork: NetworkState;
  changeCountryText: () => void;
  changeStateText: () => void;
  country: string;
  state: string;
  countryid: number;
  stateid: number;
  worksheetName: string;
  changeWorksheetNameText: () => void;
  worksheetId: number;
  messaging: MessageState;
  getSchema: (any, any, ?string, any) => Promise<any>;
  worksheetSchema: Object;
  locationId: number;
  isExpanded: boolean;
  collapseExpand: () => void;
  selectedWorkerType: Object;
  setPayValue: () => void;
  payValue: string;
  payType: number;
  rateType: number;
  workerTypeKey: string;
  setPayType: () => void;
  setWorkerType: () => void;
  payTypeBillRateDisabled: boolean;
  unsavedValues: Array<any>;
  calculateMarkup: () => void;
  sumFieldsByValueType: () => void;
  sumFieldsByValueTypeForSalary: () => void;
  saveWorksheet: () => void;
  getPayType: () => void;
  calculatePayAndBillRate: () => void;
  values: Array<any>;
  fieldUpdatedValue: Array<any>;
  valUpdated: boolean;
  getValuesToSave: () => void;
  getFieldsForSalaryType: () => void;
  getFieldsForWorkerType: () => void;
  canSave: () => void;
  canShowCreateBtn: () => void;
  resetValues: () => void;
  updateWorksheet: () => void;
  updateWorksheetSchema: () => void;
  isLatest: boolean;
  hasUnsavedChanges: () => void;
  tempObj: Object;
  stateList: Array<any>;
  checkLegacy: () => void;
  checkLegacyStd: () => void;
  checkLegacyNonStd: () => void;
  apiStateId: number;
  undoQueue: Array<any>;
  redoQueue: Array<any>;
  currentState: Object;
  maxUndoStackSize: number;
  canUndo: () => void;
  canRedo: () => void;
  undo: () => void;
  redo: () => void;
  _queueSwap: () => void;
  mapElements: () => void;
  displayState: boolean;
  stateSelected: boolean;
  revertChanges: () => void;
  fetchGraphQL: FetchGraphQL;
  fetchAPI: FetchAPI;
  setFieldValue: (field: Object, value: any) => void;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI, mobxStore: MobXStore) {
    this.mobxStore = mobxStore;
    this.fetchGraphQL = fetchGraphQL;
    this.fetchAPI = fetchAPI;
    this.changeCountryText = action(this.changeCountryText.bind(this));
    this.changeStateText = action(this.changeStateText.bind(this));
    this.changeWorksheetNameText = action(this.changeWorksheetNameText.bind(this));
    this.getSchema = action(this.getSchema.bind(this));
    this.collapseExpand = action(this.collapseExpand.bind(this));
    this.setPayValue = action(this.setPayValue.bind(this));
    this.setPayType = action(this.setPayType.bind(this));
    this.setWorkerType = action(this.setWorkerType.bind(this));
    this.saveWorksheet = action(this.saveWorksheet.bind(this));
    this.calculateMarkup = action(this.calculateMarkup.bind(this));
    this.sumFieldsByValueType = action(this.sumFieldsByValueType.bind(this));
    this.sumFieldsByValueTypeForSalary = action(
      this.sumFieldsByValueTypeForSalary.bind(this)
    );
    this.getPayType = action(this.getPayType.bind(this));
    this.calculatePayAndBillRate = action(this.calculatePayAndBillRate.bind(this));
    this.getValuesToSave = action(this.getValuesToSave.bind(this));
    this.getFieldsForWorkerType = action(this.getFieldsForWorkerType.bind(this));
    this.getFieldsForSalaryType = action(this.getFieldsForSalaryType.bind(this));
    this.canSave = action(this.canSave.bind(this));
    this.canShowCreateBtn = action(this.canShowCreateBtn.bind(this));
    this.resetValues = action(this.resetValues.bind(this));
    this.updateWorksheet = action(this.updateWorksheet.bind(this));
    this.updateWorksheetSchema = action(this.updateWorksheetSchema.bind(this));
    this.hasUnsavedChanges = action(this.hasUnsavedChanges.bind(this));
    this.checkLegacyStd = action(this.checkLegacyStd.bind(this));
    this.checkLegacyNonStd = action(this.checkLegacyNonStd.bind(this));
    this.canUndo = action(this.canUndo.bind(this));
    this.canRedo = action(this.canRedo.bind(this));
    this.undo = action(this.undo.bind(this));
    this.redo = action(this.redo.bind(this));
    this._queueSwap = action(this._queueSwap.bind(this));
    this.mapElements = action(this.mapElements.bind(this));
    this.checkLegacy = action(this.checkLegacy.bind(this));
    this.revertChanges = action(this.revertChanges.bind(this));
    this.setFieldValue = action(this.setFieldValue.bind(this));

    extendObservable(this, {
      country: "",
      state: "",
      countryid: "",
      stateid: "",
      worksheetName: "",
      worksheetId: "",
      network: new NetworkState(),
      updateNetwork: new NetworkState(),
      messaging: new MessageState(),
      worksheetSchema: {},
      locationId: "",
      isExpanded: true,
      selectedWorkerType: {},
      payValue: null,
      payType: null, // It's not used. currentState.payType is the only thing updated
      rateType: null,
      workerTypeKey: "",
      payTypeBillRateDisabled: false,
      values: [],
      unsavedValues: [],
      fieldUpdatedValue: [],
      valUpdated: false,
      isLatest: true,
      stateList: [],
      tempObj: {},
      apiStateId: "",
      maxUndoStackSize: 20,
      undoQueue: [],
      redoQueue: [],
      currentState: {},
      displayState: true,
      stateSelected: false,
    });
  }

  hasUnsavedChanges() {
    return !(JSON.stringify(this.tempObj) === JSON.stringify(this.currentState));
  }

  revertChanges() {
    const localInitialState = {};

    Object.keys(this.tempObj).forEach((key) => {
      if (key === "unsavedValues") {
        localInitialState["unsavedValues"] = Object.assign(
          {},
          this.tempObj.unsavedValues
        );
      } else {
        localInitialState[key] = this.tempObj[key];
      }
    });

    this.currentState = localInitialState;
  }

  changeCountryText(e: { id: number, title: string, apiLocationId: number | string }) {
    // FUCK
    this.valUpdated = !this.valUpdated;
    if (e && e.id) {
      this.resetValues();
      this.country = e.title;
      this.countryid = e.id;
      if (!this.checkLegacy()) {
        this.getSchema(e.apiLocationId, "latest");
      }
      if (this.checkLegacy()) {
        this.getStates("");
        this.state = "";
        this.stateid = "";
        this.apiStateId = "";
        this.stateSelected = false;
        this.displayState = true;
      }
      this.locationId = e.apiLocationId;
    } else {
      this.country = "";
      this.countryid = "";
    }
  }

  changeStateText(e: Object) {
    this.currentState.unsavedValues = [];
    this.setPayType(this, null);
    this.setRateType(this, null);
    this.setPayValue(this, null);
    this.currentState.workerTypeKey = "";
    this.selectedWorkerType = {};
    this.worksheetSchema = {};
    this.payTypeBillRateDisabled = false;
    this.valUpdated = false;
    this.state = "";
    this.stateid = "";

    if (e && e.id) {
      this.currentState.unsavedValues = [];
      this.apiStateId = "";
      this.values = [];
      this.valUpdated = !this.valUpdated;
      this.state = e.title;
      this.stateid = e.id;
      this.apiStateId = e.apiLocationId;
      this.stateSelected = true;
      this.getSchema(this.locationId, "latest", "create", e.apiLocationId);
    }
  }

  _queueSwap = (from, to, value) => {
    const valueMoved = from[from.length - 1];
    from.pop();
    to.push(value);

    return valueMoved;
  };

  canUndo = () => {
    const { undoQueue, currentState } = this;
    return (
      undoQueue.length > 0 &&
      !(JSON.stringify(undoQueue[0]) === JSON.stringify(currentState))
    );
  };

  canRedo = () => {
    const { redoQueue } = this;
    return redoQueue.length > 0;
  };

  undo = () => {
    let valueMoved = this._queueSwap(this.undoQueue, this.redoQueue, this.currentState);

    this.currentState = valueMoved;
    //this.undoQueue.push(valueMoved);
    this.valUpdated = !this.valUpdated;

    if (this.currentState && !this.currentState.workerTypeKey) {
      this.selectedWorkerType = {};
    } else if (this.currentState && this.currentState.workerTypeKey) {
      this.selectedWorkerType = this.worksheetSchema.workerTypes.find(
        (wt) => wt.key === this.currentState.workerTypeKey
      );
      if (this.checkLegacyNonStd()) {
        this.displayState = false;
      } else if (this.checkLegacyStd()) {
        this.displayState = true;
      }
    }
  };

  redo = () => {
    let valueMoved = this._queueSwap(this.redoQueue, this.undoQueue, this.currentState);
    this.currentState = valueMoved;
    //this.redoQueue.push(valueMoved);
    if (this.currentState && !this.currentState.workerTypeKey) {
      this.selectedWorkerType = {};
    } else if (this.currentState && this.currentState.workerTypeKey) {
      this.selectedWorkerType = this.worksheetSchema.workerTypes.find(
        (wt) => wt.key === this.currentState.workerTypeKey
      );
      if (this.checkLegacyNonStd()) {
        this.displayState = false;
      } else if (this.checkLegacyStd()) {
        this.displayState = true;
      }
    }
    this.valUpdated = !this.valUpdated;
  };

  setPayValue = (store, payValue, type = "create") => {
    if (store) {
      if (type === "create") {
        var localCurrentState = {};
        var key;
        for (key in store.currentState) {
          localCurrentState[key] = store.currentState[key];
        }
        store.undoQueue.push(localCurrentState);
      }
      store.currentState.payValue = payValue;
    }
    // this.payValue = payValue ? payValue.toFixed(2) : payValue;
  };

  setPayType = (store, payType, type = "create") => {
    if (store) {
      if (type === "create") {
        var localCurrentState = {};
        var key;
        for (key in store.currentState) {
          localCurrentState[key] = store.currentState[key];
        }
        store.undoQueue.push(localCurrentState);
      }
      store.currentState.payType = payType;
    }
    // this.payType = payType;
  };

  setRateType = (store, rateType, type = "create") => {
    if (store) {
      if (type === "create") {
        var localCurrentState = {};
        var key;
        for (key in store.currentState) {
          localCurrentState[key] = store.currentState[key];
        }
        store.undoQueue.push(localCurrentState);
      }
      store.currentState.rateType = rateType;
    }
  };

  setWorkerType = (store, workerTypeKey, type = "create") => {
    if (store) {
      var localCurrentState = {};
      var key;
      for (key in store.currentState) {
        localCurrentState[key] = store.currentState[key];
      }
      store.undoQueue.push(localCurrentState);
      if (type === "create") {
        store.currentState.unsavedValues = store.worksheetSchema.fields.map(
          this.mapElements.bind(this)
        );
      }
      store.currentState.workerTypeKey = workerTypeKey;
      store.selectedWorkerType = store.worksheetSchema.workerTypes.find(
        (wt) => wt.key === workerTypeKey
      );
      if (!this.checkLegacy()) {
        this.displayState = false;
      } else if (this.checkLegacyStd()) {
        this.displayState = true;
      }
    }
  };

  checkLegacy() {
    return LEGACY_COUNTRIES_IDS.indexOf(this.countryid) !== -1;
  }

  checkLegacyStd() {
    if (this.currentState.payType === 3) {
      return LEGACY_COUNTRIES_IDS.indexOf(this.countryid) !== -1;
    } else {
      return (
        LEGACY_COUNTRIES_IDS.indexOf(this.countryid) !== -1 &&
        this.selectedWorkerType &&
        this.selectedWorkerType.id &&
        this.selectedWorkerType.key === "standard"
      );
    }
  }

  checkLegacyNonStd() {
    if (this.currentState.payType === 3) {
      return LEGACY_COUNTRIES_IDS.indexOf(this.countryid) !== -1;
    } else {
      return (
        LEGACY_COUNTRIES_IDS.indexOf(this.countryid) !== -1 &&
        this.selectedWorkerType &&
        this.selectedWorkerType.id &&
        this.selectedWorkerType.key !== "standard"
      );
    }
  }

  changeWorksheetNameText(e: Object) {
    this.worksheetName = sanitizeInput(e.target.value);
  }

  collapseExpand() {
    this.isExpanded = !this.isExpanded;
  }

  resetValues = () => {
    this.tempObj = {};
    this.currentState.unsavedValues = [];
    this.setPayType(this, null);
    this.setRateType(this, null);
    this.setPayValue(this, null);
    this.currentState.workerTypeKey = "";
    this.worksheetSchema = {};
    this.locationId = "";
    this.selectedWorkerType = {};
    this.payTypeBillRateDisabled = false;
    this.values = [];
    this.valUpdated = false;
  };

  getPayType() {
    const { payType } = this.currentState;
    const { payTypeBillRateDisabled } = this;
    return payTypeBillRateDisabled ? PAY_TYPES.PAY_RATE : payType;
  }

  sumFieldsByValueType(valueType, initial = 0) {
    // console.log("sumFieldsByValueType Called");
    if (this.checkLegacyStd() && this.apiStateId) {
      // console.log("this.checkLegacyStd() && this.apiStateId === true");
      const reducedValue = this.worksheetSchema.fields
        .filter(
          all(
            fieldValueTypeEq(valueType),
            workerTypesContains(this.selectedWorkerType.id)
          )
        )
        // .filter(rec => rec.states.includes(parseInt(this.apiStateId, 10)))
        .reduce(
          sumFieldsReducer(
            fieldValueGetter(this.currentState.unsavedValues, this.values)
          ),
          initial
        );
      // console.log("reducedValue:", reducedValue);
      return this.worksheetSchema.fields ? reducedValue : 0.0;
    } else {
      // console.log("values:", this.values);
      const reducedValue = this.worksheetSchema.fields
        .filter(
          all(
            fieldValueTypeEq(valueType),
            workerTypesContains(this.selectedWorkerType.id)
          )
        )
        .reduce((accumulator, field) => {
          const value = fieldValueGetter(
            this.currentState.unsavedValues,
            this.values,
            field,
            true,
            true,
            this.currentState
          );
          // if (field.key.includes("margin")) {
          //   console.log(field.key, "| sumFieldsByValueType:", value);
          // }
          return !value ? accumulator : accumulator + value;
        }, initial);
      // console.log("reducedValue:", reducedValue);
      return this.worksheetSchema.fields ? reducedValue : 0.0;
    }
  }

  sumFieldsByValueTypeForSalary(valueType, initial = 0) {
    if (!this.worksheetSchema.fields) return initial;

    if (this.checkLegacyStd() && this.apiStateId) {
      const fields = this.worksheetSchema.fields.filter(
        all(fieldValueTypeEq(valueType), rateTypeContains("salary"))
      );
      // .filter(rec => rec.states.includes(parseInt(this.apiStateId, 10)))
      const result = fields.reduce(
        sumFieldsReducer(fieldValueGetter(this.currentState.unsavedValues, this.values)),
        initial
      );
      // console.log("fields:", fields);
      // console.log("result:", result);
      return result;
    } else {
      const fields = this.worksheetSchema.fields.filter(
        all(fieldValueTypeEq(valueType), rateTypeContains("salary"))
      );
      const res = fields.reduce(
        sumFieldsReducer(fieldValueGetter(this.currentState.unsavedValues, this.values)),
        initial
      );
      // console.log("fields:", fields);
      // console.log("res:", res);
      return res;
    }
  }

  /**
   * Checks to see if this worksheet can be saved ( all values have been selected properly ).
   */
  canSave() {
    const { payValue } = this.currentState;
    const payType = this.getPayType();
    return payType !== null && payValue !== null && payValue > 0;
  }

  canShowCreateBtn() {
    const { workerTypeKey } = this.currentState;
    const payType = this.getPayType();
    return workerTypeKey ? true : false || payType === 3;
  }

  calculateMarkup() {
    const m = this.sumFieldsByValueTypeForSalary("markup");
    // console.log("m:", m);
    return this.currentState.payType === 3
      ? m.toFixed(2)
      : parseFloat(this.sumFieldsByValueType("markup").toFixed(2));
  }

  calculatePayAndBillRate() {
    const { rateType, payValue } = this.currentState;
    const payType = this.getPayType();

    if (!rateType && payType !== 3) return {};

    const totalMarkup = this.calculateMarkup();
    const totalTax =
      this.currentState.payType === 3
        ? this.sumFieldsByValueTypeForSalary("tax").toFixed(2)
        : this.sumFieldsByValueType("tax").toFixed(2);
    const convertToHourly =
      payType === 3
        ? SALARY_RATE_TYPE_OPTIONS.convertToHourly
        : RATE_TYPE_OPTIONS[rateType].convertToHourly;
    let billRateHourly, payRateHourly, isEditable;

    if (payType === PAY_TYPES.PAY_RATE) {
      payRateHourly = convertToHourly(payValue, this.worksheetSchema.conversionOptions);
      billRateHourly = payRateHourly * (1 + totalMarkup / 100.0);
    } else {
      billRateHourly = convertToHourly(payValue, this.worksheetSchema.conversionOptions);
      payRateHourly = billRateHourly / (1 + totalMarkup / 100.0);
    }
    isEditable = this.worksheetSchema.fields.fieldTypeConfig;

    return {
      payType,
      totalMarkup,
      payValue,
      totalTax,
      payRateHourly,
      billRateHourly,
      billRateHourlyAfterTax: (1 + totalTax / 100.0) * billRateHourly,
      isEditable,
    };
  }

  async countryTitle(searchText) {
    var countryText = searchText === undefined ? "" : searchText;

    const variables = {
      nameIContains: countryText,
      schemaOnly: true,
      field: "NAME",
      direction: "ASC",
      locationTypeId: 3,
    };

    /* locationTypeId
      1: Cities
      2: States
      3: Country
    */

    const query = `
      query countries($locationTypeId:Int,$nameIContains:String,$schemaOnly: Boolean,$field:LocationSortField!, $direction:SortDirection){
        viewer{
          allLocations (filters: {locationTypeId: $locationTypeId,nameIContains:$nameIContains},schemaOnly: $schemaOnly,order: [{field: $field, direction: $direction}]) {
                edges{
                  node{
                    locationId
                    name
                apiLocationId
                  }
                }
              }
        }
      }
    `;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
      // should hit get request
    } catch (e) {
      console.error("Error in getting country title", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }
    runInAction("countryTitle--success", () => {});
    return {
      countries: res.data.viewer.allLocations.edges,
    };
  }

  async getStates(searchText) {
    let stateText = searchText === undefined ? "" : searchText;

    const query = `
      query locations {
       viewer {
         allLocations (filters: {locationTypeId: 2, nameIContains:"${stateText}", parentId:${this.countryid}}, order: [{field: NAME, direction: ASC}]) {
           edges {
             node {
               apiLocationId
               name
               locationId
             }
           }
         }
       }
      }
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, null);
      // should hit get request
    } catch (e) {
      console.error("Error editing job", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }
    runInAction("getStates--success", () => {
      // HACK: Remove extra locations for uk/india until we fix T4925
      this.stateList = res.data.viewer.allLocations.edges.filter((l) => {
        return (
          l.node.locationId !== 38383 &&
          l.node.locationId !== 37862 &&
          l.node.locationId !== 665 &&
          l.node.locationId !== 657 &&
          l.node.locationId !== 666
        );
      });
    });
    return {
      states: res.data.viewer.allLocations.edges.filter((l) => {
        return (
          l.node.locationId !== 38383 &&
          l.node.locationId !== 37862 &&
          l.node.locationId !== 665 &&
          l.node.locationId !== 657 &&
          l.node.locationId !== 666
        );
      }),
    };
  }

  async getSchema(locationId: any, version: any, type: ?string = "create", stateId: any) {
    let res = null;

    try {
      // console.log("getSchema req:", locationId, version, type, stateId);
      res = await this.fetchAPI(
        `ccc/v2/worksheet-schema/${locationId}/${version}/`,
        null,
        null,
        "GET"
      );
    } catch (e) {
      // TODO: Show error page on errors instead
      console.log("Error", e);
    }

    if (stateId) {
      try {
        // TODO verify the input attrs properly
        const stateURL = `ccc/v2/worksheet-schema/${stateId}/${version}/`;
        res = await this.fetchAPI(stateURL, null, null, "GET");
      } catch (e) {
        // TODO: Handle errors cleanly in case of state schema 404
        console.log("Error", e);
      }
    }

    // console.log("getSchema res:", res);
    const data = res.data;
    if (data.errors) {
      console.error("Errors", data.errors);
      //return;
      return;
    }
    runInAction("getSchema--success", () => {
      this.worksheetSchema = data;
      this.isLatest = data.isLatest;
      if (type && type === "detail") {
        const detailStore = this.mobxStore.negotiationWorksheetDetailStore;
        detailStore.setValues();
      }
    });
    return {
      schema: res,
    };
  }

  mapElements(val) {
    var hash = {};
    hash[val.key] = val.value;
    return hash;
  }

  getFieldsForSalaryType() {
    return this.worksheetSchema.fields.filter(rateTypeContains("salary"));
  }

  getFieldsForWorkerType(workerTypeId) {
    return this.worksheetSchema.fields.filter(workerTypesContains(workerTypeId));
  }

  getValuesToSave() {
    const workerTypeId = this.selectedWorkerType.id;
    const payType = this.getPayType();

    if (payType !== 3 && workerTypeId === null) return null;

    // const fields = payType ===3 ? this.getFieldsForSalaryType() : this.getFieldsForWorkerType(workerTypeId);

    const fields = this.worksheetSchema.fields;

    // Build a map of the values that we need to save to re-create this worksheet state.
    const existingValues = this.values;
    const getFieldValue = fieldValueGetter(
      this.currentState.unsavedValues,
      existingValues
    );

    return Map().withMutations((values) => {
      fields.forEach((field) => {
        const fieldKey = field.key;
        let value = getFieldValue(field, false, false);
        if (value !== undefined) values.set(fieldKey, value);
        else {
          values.set(fieldKey, field.value);
        }
      });
    });
  }

  async updateWorksheet(detail) {
    if (this.updateNetwork.loading) {
      return;
    }

    let jsonData = {};
    jsonData.rateType =
      this.currentState.payType === 3
        ? "Annually"
        : RATE_TYPE_OPTIONS[this.currentState.rateType].label;
    jsonData.payType = PAY_TYPE_OPTIONS[this.currentState.payType].enumValue;
    jsonData.payValue = this.currentState.payValue;
    jsonData.location = this.locationId;
    jsonData.version = this.worksheetSchema.version;
    jsonData.workerType = this.currentState.workerTypeKey;
    jsonData.values = JSON.stringify(this.getValuesToSave());

    let vars = {};
    if (this.checkLegacyStd() && this.stateid) {
      vars.stateId = this.stateid;
      vars.isIc = false;
    } else if (this.checkLegacyNonStd()) {
      vars.isIc = true;
    }

    vars.worksheetId = detail.worksheetId;
    vars.jsonData = jsonData;
    vars.rateType =
      this.currentState.payType === 3
        ? "Annually"
        : RATE_TYPE_OPTIONS[this.currentState.rateType].label;
    vars.payType = PAY_TYPE_OPTIONS[this.currentState.payType].enumValue;
    vars.totalMarkupPercent = this.calculateMarkup();

    const query = `
      mutation updateNegotiationWorksheet($worksheetId: Int!,$name: String!,$jsonData:NegotiationWorksheetJsonType!,$payType:PayTypeEnum!,
        $totalMarkupPercent: Float!,$rateType: RateTypeEnum!, $stateId: Int, $isIc: Boolean) {
        updateNegotiationWorksheet(input:{worksheetId: $worksheetId, name:$name,jsonData:$jsonData,rateType:$rateType,totalMarkupPercent:$totalMarkupPercent,payType:$payType,stateId:$stateId,isIc:$isIc}) {
          worksheet{
            worksheetId
          }
          errors{
            __typename
          }
        }
      }
    `;

    const variables = Object.assign({ name: detail.worksheetName }, vars);

    this.updateNetwork.loading = true;
    let res = null;

    // Call a GraphQL POST request for creating Worksheet
    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.updateNetwork.handleError("Updating Worksheet", e);
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.updateNetwork.loading = false;
      return;
    }

    this.updateNetwork.loading = false;

    runInAction("updateWorksheet--success", () => {
      this.updateNetwork.loading = false;
      this.updateNetwork.error = null;
      const detailStore = this.mobxStore.negotiationWorksheetDetailStore;
      detailStore.fetchWorksheet();
    });
  }

  async updateWorksheetSchema(worksheetId) {
    if (this.updateNetwork.loading) {
      return;
    }
    // console.log("updateWorksheetSchema req:", worksheetId);

    let variables = {
      worksheetId,
    };
    const query = `
      mutation updateNegotiationWorksheetSchema($worksheetId: Int!) {
        updateNegotiationWorksheetSchema(input: { worksheetId: $worksheetId }) {
          worksheet {
            worksheetId
          }
          errors{
            __typename
          }
        }
      }
    `;

    this.updateNetwork.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.updateNetwork.handleError("Updating Worksheet Schema", e);
      return;
    }

    // console.log("updateWorksheetSchema res:", res);

    if (res.errors) {
      console.error("Errors", res.errors);
      this.updateNetwork.loading = false;
      return;
    }

    this.updateNetwork.loading = false;

    runInAction("updateWorksheet--success", () => {
      this.updateNetwork.loading = false;
      this.updateNetwork.error = null;
    });
  }

  async saveWorksheet() {
    if (this.network.loading || !this.canSave()) {
      return;
    }

    // Project name is required
    if (this.worksheetName === "") {
      this.messaging.createMessage("info", "Please enter a label for your Worksheet.");
      return;
    }

    let jsonData = {};
    jsonData.rateType =
      this.currentState.payType === 3
        ? "Annually"
        : RATE_TYPE_OPTIONS[this.currentState.rateType].label;
    jsonData.payType = PAY_TYPE_OPTIONS[this.currentState.payType].enumValue;
    jsonData.payValue = this.currentState.payValue;
    jsonData.location = this.apiStateId ? this.apiStateId : this.locationId;
    jsonData.version = this.worksheetSchema.version;
    jsonData.workerType = this.currentState.workerTypeKey;
    jsonData.values = JSON.stringify(this.getValuesToSave());

    let vars = {};
    vars.countryId = this.countryid;
    if (this.stateid) {
      vars.stateId = this.stateid;
    }
    vars.jsonData = jsonData;
    vars.isIc = this.stateid ? false : true;
    vars.rateType =
      this.currentState.payType === 3
        ? "Annually"
        : RATE_TYPE_OPTIONS[this.currentState.rateType].label;
    vars.payType = PAY_TYPE_OPTIONS[this.currentState.payType].enumValue;
    vars.totalMarkupPercent = this.calculateMarkup();

    const query = `
      mutation createworksheet($name: String!,$countryId:Int!,$jsonData:NegotiationWorksheetJsonType!,$payType:PayTypeEnum!,
        $totalMarkupPercent: Float!,$rateType: RateTypeEnum!, $stateId: Int, $isIc: Boolean) {
        createNegotiationWorksheet(input:{name:$name,countryId:$countryId,jsonData:$jsonData,rateType:$rateType,totalMarkupPercent:$totalMarkupPercent,payType:$payType, stateId:$stateId, isIc:$isIc}) {
          worksheet{
            worksheetId
          }
          errors {
           __typename
           ... on NameAlreadyExistError {
             message
           }
           ... on NameEmptyError {
             message
           }
           ... on InvalidCountryIdError {
             message
           }
           ... on InvalidStateIdError {
             message
           }
           ... on TagIdsNotExistsError {
             message
           }
         }
        }
      }
    `;

    const variables = Object.assign({ name: this.worksheetName }, vars);

    this.network.loading = true;
    let res = null;

    // Call a GraphQL POST request for creating Worksheet
    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Creating Worksheet", e);
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    this.network.loading = false;

    runInAction("saveWorksheet--success", () => {
      this.network.loading = false;
      this.network.error = null;

      if (res.data.createNegotiationWorksheet.worksheet) {
        const worksheetId = res.data.createNegotiationWorksheet.worksheet.worksheetId;
        browserHistory.push("/negotiation-worksheets/" + worksheetId); // forward to details page after saving the worksheet
      }
      if (res.data.createNegotiationWorksheet.errors) {
        this.messaging.removeAll();
        this.messaging.createMessage(
          "error",
          res.data.createNegotiationWorksheet.errors[0].message
        );
        return;
      }
    });
  }

  setFieldValue(field: Object, value: any) {
    this.valUpdated = !this.valUpdated;
    var localCurrentState = {};
    var lastUnsavedValues = [];
    var key;

    if (this.currentState.unsavedValues.length === undefined) {
      for (key in this.currentState.unsavedValues) {
        let hash = {};
        hash[key] = this.currentState.unsavedValues[key];
        lastUnsavedValues.push(hash);
      }
    } else {
      for (var i = 0; i < this.currentState.unsavedValues.length; i++) {
        let hash = {};
        hash[Object.keys(this.currentState.unsavedValues[i])[0]] = Object.values(
          this.currentState.unsavedValues[i]
        )[0];
        lastUnsavedValues.push(hash);
      }
    }

    for (key in this.currentState) {
      if (key === "unsavedValues") {
        localCurrentState["unsavedValues"] = lastUnsavedValues;
      } else {
        localCurrentState[key] = this.currentState[key];
      }
    }
    this.undoQueue.push(localCurrentState);
    var newField = {};
    newField[field.key] = value;
    if (this.currentState.unsavedValues.length === undefined) {
      if (this.currentState.unsavedValues.hasOwnProperty(field.key)) {
        this.currentState.unsavedValues[field.key] = value;
      } else if (value) {
        this.currentState.unsavedValues[field.key] = value;
      }
    } else {
      let index = this.currentState.unsavedValues.findIndex((x) =>
        x.hasOwnProperty(field.key)
      );
      const fieldUpdated = checkFieldUpdated(
        this.currentState.unsavedValues,
        this.values,
        field,
        value
      );

      if (fieldUpdated) {
        if (
          this.currentState.unsavedValues.find(function (obj) {
            return obj.hasOwnProperty(field.key);
          })
        ) {
          this.currentState.unsavedValues.find(function (obj) {
            return obj.hasOwnProperty(field.key);
          })[field.key] = value;
        } else {
          this.currentState.unsavedValues.push(newField);
        }
      } else {
        if (index !== -1) {
          this.currentState.unsavedValues.splice(index, 1);
        }
      }
    }
  }
}
