import React, { useState, useCallback, useMemo } from "react";

import Button from "../../../components/lib/Button";
import NumberInput from "../../../components/lib/NumberInput";
import TextInput from "../../../components/lib/TextInput";
import Inline from "../../../components/lib/Inline";
import Stack from "../../../components/lib/Stack";
import Grid from "../../../components/lib/Grid";
import Text from "../../../components/lib/Text";
import Alert from "../../../components/lib/Alert";
import PromiseButton from "../../../components/lib/PromiseButton";
import ClientSelect from "./selects/ClientSelect";

import { ButtonGroupRight } from "../../../components/lib/ButtonGroup";
import { CheckboxItem } from "../../../components/lib/Checkbox";

import type { CloseConfirmationModalFunc } from "../../../components/ConfirmationModal";
import type { FetchGraphQLAPIFunc } from "../../../types/fetch";
import type { Nullable } from "../../../types/generic";
import type { ClientData, ClientSelectProps } from "./selects/ClientSelect";

type ReviewsBulkUpdateState = Nullable<{
  isFreeValidation: boolean;
  isActive: boolean;
  rewardPerJob: string;
  maxAttemptsNumber: string;
  category: string;
  clientData: ClientData;
}>;

type ReviewsBulkUpdateStateKeys = keyof ReviewsBulkUpdateState;

export type ReviewsBulkUpdateDataObject = Partial<{
  free_validation: boolean;
  is_active: boolean;
  reward_per_job: number | string;
  max_attempts: number | string;
  category: string;
  client_id: number;
  client_name: string;
}>;

type ReviewsBulkUpdateFormProps = {
  itemsCount: number;
  fetchGraphQL: FetchGraphQLAPIFunc;
  onApply: (data: ReviewsBulkUpdateDataObject) => void;
  onCancel: CloseConfirmationModalFunc;
};

const ReviewsBulkUpdateForm = (props: ReviewsBulkUpdateFormProps) => {
  const { itemsCount, fetchGraphQL, onApply, onCancel } = props;
  const isBigUpdate = itemsCount > 100;

  // state

  const [state, setState] = useState<ReviewsBulkUpdateState>({
    isFreeValidation: null,
    isActive: null,
    rewardPerJob: null,
    maxAttemptsNumber: null,
    category: null,
    clientData: null,
  });

  // utils

  const hasChanges = useMemo(() => {
    return Object.values(state).findIndex((value) => value != null) >= 0;
  }, [state]);

  // handlers

  const handleChangeIsFreeValidation = useCallback(
    (value) => {
      if (value !== state.isFreeValidation) {
        setState((prevState) => ({
          ...prevState,
          isFreeValidation: value,
          rewardPerJob: value ? null : state.rewardPerJob,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          isFreeValidation: null,
          rewardPerJob: null,
        }));
      }
    },
    [state.isFreeValidation, state.rewardPerJob]
  );

  const handleChangeIsActive = useCallback(
    (value) => {
      setState((prevState) => ({
        ...prevState,
        isActive: value !== state.isActive ? value : null,
      }));
    },
    [state.isActive]
  );

  const handleChangeRewardPerJob = useCallback(
    (value) => {
      if (value !== state.rewardPerJob) {
        setState((prevState) => ({
          ...prevState,
          rewardPerJob: value,
          isFreeValidation: !value,
        }));
      }
    },
    [state.rewardPerJob]
  );

  const handleChangeClient: ClientSelectProps["onChange"] = useCallback((value) => {
    if (value) {
      setState((prevState) => ({
        ...prevState,
        clientData: value,
      }));
    }
  }, []);

  const handleChangeStateParameter = useCallback(
    (key: ReviewsBulkUpdateStateKeys, value: number | string | null) => {
      if (value !== state[key]) {
        setState((prevState) => ({
          ...prevState,
          [key]: value,
        }));
      }
    },
    [state]
  );

  const handleApply = useCallback(() => {
    const data: Partial<ReviewsBulkUpdateDataObject> = {};

    if (state.isFreeValidation != null) {
      data["free_validation"] = state.isFreeValidation;
    }
    if (state.isActive != null) {
      data["is_active"] = state.isActive;
    }
    if (state.rewardPerJob != null) {
      data["reward_per_job"] = state.rewardPerJob
        ? parseFloat(state.rewardPerJob)
        : state.rewardPerJob;
    }
    if (state.maxAttemptsNumber != null) {
      data["max_attempts"] = state.maxAttemptsNumber
        ? parseInt(state.maxAttemptsNumber)
        : state.maxAttemptsNumber;
    }
    if (state.category != null) {
      data["category"] = state.category;
    }
    if (state.clientData != null) {
      data["client_id"] = state.clientData["clientId"];
      data["client_name"] = state.clientData["clientName"];
    }

    return onApply(data);
  }, [state, onApply]);

  return (
    <Stack fill css={{ alignItems: "flex-start" }}>
      <Text>Use the form below to change some parameters for the list of surveys.</Text>

      {isBigUpdate && (
        <Alert fill color="warning">
          <Text>
            <Text bold underline italic>
              IMPORTANT
            </Text>
            : You are about to update{" "}
            <big>
              <b>{itemsCount}</b>
            </big>{" "}
            surveys.
          </Text>
        </Alert>
      )}
      <Alert fill color="primary">
        <Text>
          <Text bold underline italic>
            IMPORTANT
          </Text>
          : New reward value will take place for the new validations only, all the passed
          validations will keep the old reward value.
        </Text>
      </Alert>

      <Grid
        css={{
          gridTemplateColumns: "repeat(2, auto)",
          gridColumnGap: "$2",
          gridRowGap: "$3",
          alignItems: "center",
          width: "$full",
        }}
      >
        <Text underline>Mark Survey As Free</Text>
        <Inline>
          <CheckboxItem
            checked={state.isFreeValidation === true}
            onCheckedChange={useCallback(
              () => handleChangeIsFreeValidation(true),
              [handleChangeIsFreeValidation]
            )}
          >
            Free
          </CheckboxItem>
          <CheckboxItem
            checked={state.isFreeValidation === false}
            onCheckedChange={useCallback(
              () => handleChangeIsFreeValidation(false),
              [handleChangeIsFreeValidation]
            )}
          >
            Not free
          </CheckboxItem>
        </Inline>
        <Text underline>Make Survey Active/Inactive</Text>
        <Inline>
          <CheckboxItem
            checked={state.isActive === true}
            onCheckedChange={useCallback(
              () => handleChangeIsActive(true),
              [handleChangeIsActive]
            )}
          >
            Active
          </CheckboxItem>
          <CheckboxItem
            checked={state.isActive === false}
            onCheckedChange={useCallback(
              () => handleChangeIsActive(false),
              [handleChangeIsActive]
            )}
          >
            Inactive
          </CheckboxItem>
        </Inline>

        <Text underline>Reward Per Job</Text>
        <Inline>
          <NumberInput
            fill
            value={state.rewardPerJob ?? ""}
            onChange={useCallback(
              (e) => handleChangeRewardPerJob(e.target.valueAsNumber),
              [handleChangeRewardPerJob]
            )}
          />
        </Inline>

        <Text underline>Max Attempts Number</Text>
        <Inline>
          <NumberInput
            fill
            value={state.maxAttemptsNumber ?? ""}
            onChange={useCallback(
              (e) =>
                handleChangeStateParameter("maxAttemptsNumber", e.target.valueAsNumber),
              [handleChangeStateParameter]
            )}
          />
        </Inline>

        <Text underline>Category (clarifies job title in addition to industry)</Text>
        <Inline>
          <TextInput
            fill
            value={state.category ?? ""}
            onChange={useCallback(
              (e) => handleChangeStateParameter("category", e.target.value),
              [handleChangeStateParameter]
            )}
          />
        </Inline>

        <Text underline>Client</Text>
        <Inline>
          <ClientSelect
            placeholder=""
            value={state.clientData}
            onChange={handleChangeClient}
            fetchGraphQL={fetchGraphQL}
          />
        </Inline>
      </Grid>
      <ButtonGroupRight fill reverse>
        <Button color="gray" size="normal" onClick={onCancel}>
          Close
        </Button>
        <PromiseButton
          color="green"
          size="normal"
          loadingText="Apply Changes"
          disabled={!hasChanges}
          onClick={handleApply}
        >
          Apply Changes
        </PromiseButton>
      </ButtonGroupRight>
    </Stack>
  );
};

ReviewsBulkUpdateForm.displayName = "ReviewsBulkUpdateForm";

export default ReviewsBulkUpdateForm;
