import React, { useEffect, useRef, useCallback, useState } from "react";
import moment from "moment";
import { useRecoilState } from "recoil";

import Pagination from "../../../components/lib/Pagination";
import { usePLIContext } from "../context";
import ProcessingProgressBar from "./ProcessingProgressBar";
import Box from "../../../components/lib/Box";
import Inline from "../../../components/lib/Inline";
import {
  Card,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
  CardBody,
  CardHeaderTitle,
} from "../../../components/lib/Card";
import { styled } from "../../../stitches.config";
import { AutoSearchBox, AutoSearchBoxProps } from "../../../components/lib/SearchBox";
import { Button, IconButton } from "../../../components/lib/Button";
import { ButtonGroupRight } from "../../../components/lib/ButtonGroup";
import { useIntervalDataRefresh, useScrollTo } from "../hooks";
import { RerunUploadModal } from "./RerunIndexModals";
import {
  ContractorsUploadsListGlobalState,
  contractorsUploadsListGlobalState,
} from "../globalState";
import { transformContractorsUploadsData } from "../dataConverters";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";
import { TickerContentLoader } from "../../../components/lib/TickerLoader";
import { getChangingPageNumber } from "../../../components/tables/utils";
import Stack from "../../../components/lib/Stack";
import Text from "../../../components/lib/Text";
import Center from "../../../components/lib/Center";
import Icon from "../../../components/lib/Icon";
import { SimpleLink } from "../../../components/lib/Link";
import {
  djangoOrderingKey,
  djangoPaginationKey,
  djangoPaginationSizeKey,
  djangoSearchKey,
  emptyMap,
} from "../constants";
import { ContractorsUploadsDataList, PROCESSING_STATUSES } from "../types";

import type { PageEvent } from "../../../components/lib/Pagination";
import type { ContractorsUploadDataMap, ContractorsUploadDataObject } from "../types";
import type { FetchAPIResponse } from "../../../types/fetch";
import type { DjangoPaginatedResponse } from "../../../types/django";
import { UrlQueryObject } from "../../../components/tables/types";
import PromiseButton from "../../../components/lib/PromiseButton";

const Row = styled(Inline, {
  justifyContent: "space-around",
  padding: "$3 $6",
  borderBottom: "1px solid $primaryLight",
  "&:last-child": {
    borderBottom: 0,
  },
});

const Cell = styled("div", {
  flex: 1,
  variants: {
    nowrap: {
      true: {
        whiteSpace: "nowrap",
      },
    },
    ellipsis: {
      true: {
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
  },
  defaultVariants: {
    nowrap: true,
    ellipsis: true,
  },
});

const DATETIME_FORMAT = "dddd, MMMM Do YYYY, h:mm:ss a";
const dateTimeFormatter = (value: Date) => moment.utc(value).format(DATETIME_FORMAT);

interface RecentUploadItemRegularProps {
  uploadData: ContractorsUploadDataMap;
}

const RecentUploadItemRegular = (props: RecentUploadItemRegularProps) => {
  const { uploadData } = props;
  const title = uploadData.get("title");
  const fileName = uploadData.get("file_name");
  const createdTimestamp = uploadData.get("created");
  const datasetLength = uploadData.get("dataset_length");

  return (
    <Row nowrap>
      <Cell>
        <h4>
          {title} <small>({dateTimeFormatter(createdTimestamp)})</small>
        </h4>
      </Cell>
      <Cell>
        <Icon icon={["far", "file-excel"]} css={{ color: "brand" }} /> {fileName}{" "}
        <small>({datasetLength} items)</small>
      </Cell>
    </Row>
  );
};
RecentUploadItemRegular.displayName = "RecentUploadItemRegular";

interface RecentUploadItemAdminProps extends RecentUploadItemRegularProps {
  onDeleteUpload: (upload: ContractorsUploadDataMap) => void;
  onRerunUpload: (upload: ContractorsUploadDataMap) => void;
  onStopUpload: (upload: ContractorsUploadDataMap) => void;
  onGoToUploadDetailsPage: (upload: ContractorsUploadDataMap) => void;
}

const RecentUploadItemAdmin = (props: RecentUploadItemAdminProps): JSX.Element => {
  const {
    uploadData,
    onDeleteUpload,
    onRerunUpload,
    onStopUpload,
    onGoToUploadDetailsPage,
  } = props;
  const { userId: sessionUserId } = usePLIContext();

  const title = uploadData.get("title");
  const fileName = uploadData.get("file_name");
  const fileURL = uploadData.get("file");
  const createdTimestamp = uploadData.get("created");
  const datasetLength = uploadData.get("dataset_length");
  const processing = uploadData.get("processing") || emptyMap;
  const isActive = uploadData.get("is_active") || false;
  const userId = uploadData.get("user_id");
  const userName = uploadData.get("user_name");
  const status = processing.get("status") || PROCESSING_STATUSES.FINISHED;

  const handleDeleteUpload = React.useCallback(
    () => onDeleteUpload(uploadData),
    [uploadData, onDeleteUpload]
  );
  const handleRerunUpload = React.useCallback(
    () => onRerunUpload(uploadData),
    [uploadData, onRerunUpload]
  );
  const handleGoToDetailsPage = React.useCallback(
    () => onGoToUploadDetailsPage(uploadData),
    [uploadData, onGoToUploadDetailsPage]
  );
  const handleStopUpload = React.useCallback(
    () => onStopUpload(uploadData),
    [uploadData, onStopUpload]
  );

  return (
    <Row nowrap>
      <Cell css={{ flexGrow: 5 }}>
        <h4>
          {title} <small>({dateTimeFormatter(createdTimestamp)})</small>
        </h4>
      </Cell>
      <Cell css={{ flexGrow: 2 }}>
        {userName && (
          <Text>
            <Icon icon="user" css={{ color: "$brand" }} /> {userName}
            {sessionUserId === userId ? " (you)" : ""}
          </Text>
        )}
      </Cell>
      <Cell css={{ flexGrow: 4 }}>
        <SimpleLink href={fileURL} download>
          <Icon icon={["far", "file-excel"]} /> {fileName} ({datasetLength} items)
        </SimpleLink>
      </Cell>
      <Cell ellipsis={false}>
        <ProcessingProgressBar processing={processing} />
      </Cell>
      <Cell ellipsis={false}>
        {isActive ? (
          <span title="Uploaded data is available for the client.">
            <Text as={Icon} color="positive" icon="eye" size="sm" /> Visible
          </span>
        ) : (
          <span title="Uploaded data is not available for the client.">
            <Text as={Icon} color="negative" icon="eye-slash" size="sm" /> Hidden
          </span>
        )}
      </Cell>
      <Cell ellipsis={false}>
        <ButtonGroupRight css={{ flexWrap: "nowrap" }}>
          {(status === PROCESSING_STATUSES.FINISHED ||
            status === PROCESSING_STATUSES.FAILED ||
            status === PROCESSING_STATUSES.STOPPED) && (
            <>
              <IconButton
                icon="trash-alt"
                color="danger"
                variant="outlined"
                title="Delete upload."
                onClick={handleDeleteUpload}
              />
              <IconButton
                icon="sync"
                color="accent"
                variant="outlined"
                title="Rerun upload."
                onClick={handleRerunUpload}
              />
              <IconButton
                icon="list"
                color="brand"
                variant="outlined"
                title="See details."
                onClick={handleGoToDetailsPage}
              />
            </>
          )}
          {status === PROCESSING_STATUSES.RUNNING && (
            <IconButton
              icon={["far", "stop-circle"]}
              color="accent"
              variant="outlined"
              title="Stop upload."
              onClick={handleStopUpload}
            />
          )}
        </ButtonGroupRight>
      </Cell>
    </Row>
  );
};
RecentUploadItemAdmin.displayName = "RecentUploadItemAdmin";

const needUploadsRefresh = (uploadsList: ContractorsUploadsDataList): boolean => {
  return (
    uploadsList.find(
      (item) => item.get("processing")?.get("status") === PROCESSING_STATUSES.RUNNING
    ) != null
  );
};

type RerunModalState = {
  show: boolean;
  rowData: ContractorsUploadDataMap | null;
};

type RecentUploadsBlockProps = {
  isPTAdmin: boolean;
  programId?: number;
  refreshRequestId?: number;
};

const RecentUploadsBlock = (props: RecentUploadsBlockProps): JSX.Element => {
  const { isPTAdmin, programId, refreshRequestId } = props;
  const {
    router,
    fetchM8API,
    showModalError,
    showLoader,
    hideLoader,
    showConfirmationModal,
    closeConfirmationModal,
  } = usePLIContext();

  // state

  const [uploadsListState, setUploadsListState] = useRecoilState(
    contractorsUploadsListGlobalState
  );
  const {
    data,
    activePage,
    itemsCount,
    itemsPerPage,
    loaded: isLoaded,
    search,
  } = uploadsListState;

  const hasNoData = (!data || !data.size) && search == null;
  const nothingFound = (!data || !data.size) && search != null;
  const pagesNumber = Math.ceil(itemsCount / itemsPerPage) || 1;

  // modals

  const [rerunModalState, setRerunModalState] = useState<RerunModalState>({
    show: false,
    rowData: null,
  });

  const showRerunUploadModal = useCallback((row) => {
    setRerunModalState({
      show: true,
      rowData: row,
    });
  }, []);

  const hideRerunUploadModal = useCallback(() => {
    setRerunModalState({
      show: false,
      rowData: null,
    });
  }, []);

  // scroll to table top

  const scrollToRef = useScrollTo([uploadsListState.activePage]);

  // fetch data

  type RequestQueryArgs = { [key: string]: any };
  type UploadsListResponse = FetchAPIResponse<
    DjangoPaginatedResponse<ContractorsUploadDataObject>
  >;

  const fetchUploadsData = useCallback(
    async (
      queryArgs: UrlQueryObject = {},
      extraStoredParameters: Partial<ContractorsUploadsListGlobalState> = {}
    ) => {
      let resultingQueryArgs: RequestQueryArgs = {
        [djangoSearchKey]: uploadsListState.search,
        [djangoPaginationKey]: uploadsListState.activePage,
        [djangoPaginationSizeKey]: uploadsListState.itemsPerPage,
        [djangoOrderingKey]: "-created",
        ...queryArgs,
      };
      if (isPTAdmin && programId != null) {
        resultingQueryArgs["program_id"] = programId;
      }

      try {
        const response: UploadsListResponse = await fetchM8API(`uploads/`, {
          params: resultingQueryArgs,
        });
        const data: Partial<ContractorsUploadsListGlobalState> =
          transformContractorsUploadsData(response.data, extraStoredParameters);

        setUploadsListState((prevState) => ({
          ...prevState,
          ...data,
          loaded: true,
        }));

        return data;
      } catch (err: any) {
        logAsyncOperationError("fetchUploadsList", err);
        showModalError("Error occurred while loading uploads list.");
      }
    },
    [
      uploadsListState.search,
      uploadsListState.activePage,
      uploadsListState.itemsPerPage,
      programId,
      isPTAdmin,
      setUploadsListState,
      fetchM8API,
      showModalError,
    ]
  );

  // utils

  const refreshUploadsData = fetchUploadsData;

  // effects

  const uploadsDataLoadedRef = useRef(false);
  useEffect(() => {
    if (!uploadsDataLoadedRef.current) {
      refreshUploadsData();
      uploadsDataLoadedRef.current = true;
    }
  }, [refreshUploadsData]);

  const prevRefreshRequestIdRef = useRef(refreshRequestId);
  useEffect(() => {
    if (prevRefreshRequestIdRef.current !== refreshRequestId) {
      prevRefreshRequestIdRef.current = refreshRequestId;
      refreshUploadsData();
    }
  }, [refreshRequestId, refreshUploadsData]);

  useIntervalDataRefresh(refreshUploadsData, uploadsListState.data, needUploadsRefresh);

  // handlers

  const handleGoToUploadDetailsPage = useCallback(
    (upload: ContractorsUploadDataMap) => {
      const uploadId = upload.get("id");

      if (programId != null && uploadId != null) {
        router.push(
          `/private-index/programs/${programId}/contractors/uploads/${uploadId}`
        );
      }
    },
    [router, programId]
  );

  const handleSearchChange = useCallback(
    (value: AutoSearchBoxProps["value"]) => {
      if (value !== search) {
        setUploadsListState((prevState) => ({
          ...prevState,
          search: value as string,
        }));
      }
    },
    [search, setUploadsListState]
  );

  const handleSearchClick = useCallback(
    async (value: AutoSearchBoxProps["value"]) => {
      await showLoader();
      await fetchUploadsData(
        {
          [djangoSearchKey]: value == null ? uploadsListState.search : value,
          [djangoPaginationKey]: 1,
        },
        { search: value as string, activePage: 1 }
      );
      hideLoader();
    },
    [uploadsListState.search, fetchUploadsData, showLoader, hideLoader]
  );

  const handleSearchEnter = useCallback(
    async (value: AutoSearchBoxProps["value"]) => {
      await handleSearchClick(value);
    },
    [handleSearchClick]
  );

  const handleChangePage = useCallback(
    async (pageEvent: PageEvent) => {
      const pageNumber = getChangingPageNumber(
        pageEvent,
        uploadsListState.activePage,
        pagesNumber,
        1
      );

      if (pageNumber != null && pageNumber !== uploadsListState.activePage) {
        await showLoader();
        await fetchUploadsData(
          { [djangoPaginationKey]: pageNumber },
          { activePage: pageNumber }
        );
        hideLoader();
      }
    },
    [pagesNumber, uploadsListState.activePage, fetchUploadsData, showLoader, hideLoader]
  );

  const handleDeleteUpload = useCallback(
    async (upload: ContractorsUploadDataMap) => {
      const uploadId = upload.get("id");

      try {
        await fetchM8API(`uploads/${uploadId}/`, {
          method: "delete",
          headers: { "content-type": "application/x-www-form-urlencoded" },
        });
        await refreshUploadsData();
        closeConfirmationModal();
      } catch (err: any) {
        logAsyncOperationError("deleteUpload", err);
        showModalError(`Error occurred while deleting upload #${uploadId}.`);
      }
    },
    [refreshUploadsData, fetchM8API, showModalError, closeConfirmationModal]
  );

  const handleConfirmDeleteUpload = useCallback(
    (upload: ContractorsUploadDataMap) => {
      const id = upload.get("id");
      const title = upload.get("title");
      const header = "Confirm delete action";
      const message = (
        <span>
          Do you really want to delete the following upload?
          <br />
          <b>{`- #${id} ${title}`}</b>
        </span>
      );
      const footer = (
        <ButtonGroupRight fill css={{ flexDirection: "row-reverse" }}>
          <Button size="large" onClick={closeConfirmationModal}>
            No, Cancel
          </Button>
          <PromiseButton
            icon="trash-alt"
            loadingText="Delete"
            color="danger"
            size="large"
            onClick={() => handleDeleteUpload(upload)}
          >
            Yes, Delete
          </PromiseButton>
        </ButtonGroupRight>
      );

      showConfirmationModal(message, header, footer);
    },
    [handleDeleteUpload, showConfirmationModal, closeConfirmationModal]
  );

  const handleStopUpload = useCallback(
    async (upload: ContractorsUploadDataMap) => {
      const uploadId = upload.get("id");

      try {
        await fetchM8API(`uploads/${uploadId}/stop/`, {
          method: "post",
          data: null,
          headers: { "content-type": "application/x-www-form-urlencoded" },
        });
        await refreshUploadsData();
        closeConfirmationModal();
      } catch (err: any) {
        logAsyncOperationError("stopUploadProcessing", err);
        showModalError(`Error occurred while stopping upload #${uploadId}.`);
      }
    },
    [refreshUploadsData, fetchM8API, showModalError, closeConfirmationModal]
  );

  const handleConfirmStopUpload = useCallback(
    (upload: ContractorsUploadDataMap) => {
      const id = upload.get("id");
      const title = upload.get("title");
      const datasetLength = upload.get("dataset_length");
      const header = "Confirm stop upload action";
      const message = (
        <div>
          <span>
            You are about to stop following upload. Please confirm this action in order to
            proceed.
            <br />
            <b>{`- #${id} ${title} (${datasetLength} rows)`}</b>
          </span>
        </div>
      );
      const footer = (
        <ButtonGroupRight fill css={{ flexDirection: "row-reverse" }}>
          <Button size="large" onClick={closeConfirmationModal}>
            No, Cancel
          </Button>
          <PromiseButton
            icon="ban"
            color="accent"
            size="large"
            loadingText="Stop"
            onClick={() => handleStopUpload(upload)}
          >
            Yes, Stop
          </PromiseButton>
        </ButtonGroupRight>
      );

      showConfirmationModal(message, header, footer);
    },
    [handleStopUpload, showConfirmationModal, closeConfirmationModal]
  );

  const handleRerunUpload = useCallback(
    async (
      upload: ContractorsUploadDataMap,
      includeRemapping: boolean = false
    ): Promise<void> => {
      const uploadId = upload.get("id");

      try {
        await fetchM8API(`uploads/${uploadId}/rerun/`, {
          method: "post",
          params: { __include_remapping: includeRemapping },
          data: null,
          headers: { "content-type": "application/x-www-form-urlencoded" },
        });
        await refreshUploadsData();
        hideRerunUploadModal();
      } catch (err: any) {
        logAsyncOperationError("rerunUploadProcessing", err);
        showModalError(`Error occurred while rerunning upload #${uploadId}.`);
      }
    },
    [refreshUploadsData, hideRerunUploadModal, fetchM8API, showModalError]
  );

  const handleGoToProcessingsPage = useCallback(() => {
    router.push(`/private-index/programs/${programId}/contractors/processings`);
  }, [programId, router]);

  return (
    <Card fill>
      <RerunUploadModal
        show={rerunModalState.show}
        uploadData={rerunModalState.rowData}
        onRerun={handleRerunUpload}
        onHide={hideRerunUploadModal}
      />

      <CardActions>
        <CardActionsLeft>
          <CardHeaderTitle as="h3">Recent Uploads</CardHeaderTitle>
        </CardActionsLeft>
        <CardActionsRight>
          <Button
            css={{ width: "205px" }}
            color="brand"
            size="small"
            title="See All System Processings"
            onClick={handleGoToProcessingsPage}
          >
            See All System Processings
          </Button>
        </CardActionsRight>
      </CardActions>

      <CardBody>
        <Stack css={{ alignItems: "stretch", gap: "$6" }}>
          {isLoaded && (
            <AutoSearchBox
              css={{ borderWidth: "1px" }}
              value={search as AutoSearchBoxProps["value"]}
              placeholder="Search by upload name..."
              onChange={handleSearchChange}
              onSubmit={handleSearchEnter}
            />
          )}
          {isLoaded && hasNoData && (
            <Center>
              <Text thin italic>
                No uploads so far
              </Text>
            </Center>
          )}
          {isLoaded && !hasNoData && nothingFound && (
            <Center>
              <Text thin italic>
                Nothing found for "{search}".
              </Text>
            </Center>
          )}

          {isLoaded && !hasNoData && !nothingFound && pagesNumber > 1 && (
            <Pagination
              ref={scrollToRef}
              css={{ padding: "0" }}
              options={{
                variant: "full",
                currentPage: activePage,
                numPages: pagesNumber,
              }}
              onPageClick={handleChangePage}
            />
          )}

          {!isLoaded && (
            <Box fill css={{ minHeight: "200px" }}>
              <TickerContentLoader />
            </Box>
          )}

          {isLoaded && !hasNoData && !nothingFound && data.size > 0 && (
            <Box
              fill
              css={{
                border: "1px solid $primaryLight",
                borderRadius: "$rounded",
              }}
            >
              {data
                .toArray()
                .map((uploadData, idx) =>
                  isPTAdmin ? (
                    <RecentUploadItemAdmin
                      key={idx}
                      uploadData={uploadData}
                      onRerunUpload={showRerunUploadModal}
                      onStopUpload={handleConfirmStopUpload}
                      onDeleteUpload={handleConfirmDeleteUpload}
                      onGoToUploadDetailsPage={handleGoToUploadDetailsPage}
                    />
                  ) : (
                    <RecentUploadItemRegular key={idx} uploadData={uploadData} />
                  )
                )}
            </Box>
          )}

          {isLoaded &&
            !hasNoData &&
            !nothingFound &&
            data.size >= 20 &&
            pagesNumber > 1 && (
              <Pagination
                ref={scrollToRef}
                css={{ padding: "0" }}
                options={{
                  variant: "full",
                  currentPage: activePage,
                  numPages: pagesNumber,
                }}
                onPageClick={handleChangePage}
              />
            )}
        </Stack>
      </CardBody>
    </Card>
  );
};

RecentUploadsBlock.displayName = "RecentUploadsBlock";

export default RecentUploadsBlock;
