import React from "react";

import { useAttemptDataContext } from "./AttemptDataContext";

import type { Values } from "../types";

export const UI_PAGES = {
  RATES_FORM: "RATES_FORM",
  COMMENT_FORM: "COMMENT_FORM",
} as const;

type UI_PAGES_TYPE = Values<typeof UI_PAGES>;

export type ValidationStateObject = {
  page: UI_PAGES_TYPE;
  comment: string | null;
  dontBlockUI: boolean;
};

export type ValidationContextObject = ValidationStateObject & {
  updateValidationState: (stateUpdates: Partial<ValidationStateObject>) => void;
  goBackToRatesPage: () => void;
  goToCommentPage: () => void;
};

export const ValidationContext = React.createContext<ValidationContextObject>({
  page: UI_PAGES.RATES_FORM,
  comment: null, // final comment after all rates are done
  dontBlockUI: false, // needed for letting use enter their final comment, UI is going to be finalized right after that
  //
  updateValidationState: undefined,
  //
  goBackToRatesPage: undefined,
  goToCommentPage: undefined,
} as unknown as ValidationContextObject);

export const useValidationContext = () => {
  return React.useContext(ValidationContext);
};

export const useCreateValidationContext = (): ValidationContextObject => {
  const { attemptData } = useAttemptDataContext();

  const [validationState, setValidationState] = React.useState<ValidationStateObject>({
    page: UI_PAGES.RATES_FORM,
    comment: attemptData.get("comment") ?? null,
    dontBlockUI: false,
  });

  const updateValidationState = (stateUpdates: Partial<ValidationStateObject>) => {
    setValidationState((prevState) => ({
      ...prevState,
      ...stateUpdates,
    }));
  };

  const goBackToRatesPage = React.useCallback(() => {
    updateValidationState({ page: UI_PAGES.RATES_FORM });
  }, []);

  const goToCommentPage = React.useCallback(() => {
    updateValidationState({
      page: UI_PAGES.COMMENT_FORM,
      dontBlockUI: true,
    });
  }, []);

  return {
    ...validationState,
    updateValidationState,
    goBackToRatesPage,
    goToCommentPage,
  };
};

export default ValidationContext;
