import React from "react";

import Icon from "../../../../../components/lib/Icon";
import { RatesButton } from "./ButtonStyle";

import type { RatesFeedbackDataMap } from "../../../types";
import { useClassicRatesItemFeedbackContext } from "../../../context/feedback/ClassicRatesItemFeedbackContext";

type Props = {
  feedback?: RatesFeedbackDataMap;
  disabled: boolean;
  editable: boolean;
  onClick: () => void;
};

export default function RatesFeedbackEditButton(props: Props) {
  const { disabled, editable, onClick } = props;
  const { hasAnyRatesFeedback } = useClassicRatesItemFeedbackContext();

  return (
    <RatesButton
      css={{
        backgroundColor: hasAnyRatesFeedback ? "$accentLighter" : undefined,
        "&:hover": { backgroundColor: disabled ? undefined : "$accentLight" },
      }}
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
    >
      <Icon icon="chart-column" /> {editable ? "Save" : "Edit"} Rates
    </RatesButton>
  );
}

RatesFeedbackEditButton.displayName = "RatesFeedbackEditButton";
