import React from "react";

import Button, { IconButton } from "../../../components/lib/Button";
import Tooltip from "../../../components/lib/Tooltip";
import Text from "../../../components/lib/Text";
import Box from "../../../components/lib/Box";
import Alert from "../../../components/lib/Alert";
import { ButtonGroupRight } from "../../../components/lib/ButtonGroup";
import { usePLIContext } from "../context";

import type { ContractorsDataOrderedMap } from "../types";
import type {
  CloseConfirmationModalFunc,
  ShowConfirmationModalFunc,
} from "../../../components/ConfirmationModal";

export type BulkCreateSurveysButtonsProps = {
  selectedRows: ContractorsDataOrderedMap;
  isSelectingRows: boolean;
  isEditingRow: boolean;
  isContractorsDataLoaded: boolean;
  itemsTotal: number;
  onStartRowsSelection: React.MouseEventHandler<HTMLButtonElement>;
  onStopRowsSelection: React.MouseEventHandler<HTMLButtonElement>;
  onCreateSurveys: React.MouseEventHandler<HTMLButtonElement>;
};

export function BulkCreateSurveysButtons(props: BulkCreateSurveysButtonsProps) {
  const {
    selectedRows,
    isSelectingRows,
    isEditingRow,
    isContractorsDataLoaded,
    itemsTotal,
    onStartRowsSelection,
    onStopRowsSelection,
    onCreateSurveys,
  } = props;

  const { showConfirmationModal, closeConfirmationModal } = usePLIContext();

  return (
    <>
      <Tooltip
        side="top"
        open={isSelectingRows}
        content={
          <Text centered as={Box} css={{ width: 155 }}>
            Select some rows for validation below and click this button
          </Text>
        }
      >
        <Button
          icon="tasks"
          size="small"
          color={isSelectingRows ? "brand" : undefined}
          css={{ position: "relative" }}
          onClick={
            isSelectingRows
              ? onCreateSurveys
              : () =>
                  showValidationsConfirmation(
                    onStartRowsSelection,
                    showConfirmationModal,
                    closeConfirmationModal
                  )
          }
          disabled={
            !isContractorsDataLoaded ||
            !itemsTotal ||
            isEditingRow ||
            (isSelectingRows && selectedRows.size === 0)
          }
        >
          &nbsp;
          {isSelectingRows ? "Create Validations" : "Make Validations"}
        </Button>
      </Tooltip>
      {isSelectingRows && (
        <Button icon="times" size="small" onClick={onStopRowsSelection}>
          Cancel
        </Button>
      )}
    </>
  );
}

const showValidationsConfirmation = (
  startRowsSelection: React.MouseEventHandler<HTMLButtonElement>,
  showConfirmationModal: ShowConfirmationModalFunc,
  closeConfirmationModal: CloseConfirmationModalFunc
) => {
  const header = "Make Validations";
  const message = (
    <div>
      <span>
        You are about to create new validations in Val5000 using Private Index data.
      </span>
      <br />
      <span>
        Click <b>"Make New Validations"</b> in order to continue.
      </span>
      <br />
      <br />
      <Alert color="warning">
        <span>
          <Text bold underline italic>
            IMPORTANT!
          </Text>
          &nbsp; You can always check on any existing Val5000 Validations feedback by
          using the&nbsp;&nbsp;
          <IconButton
            icon="tasks"
            color="brand"
            variant="outlined"
            title="Validations Feedback"
            tabIndex={-1}
          />
          &nbsp;&nbsp;row's button.
        </span>
      </Alert>
    </div>
  );
  const footer = (
    <ButtonGroupRight fill css={{ flexDirection: "row-reverse" }}>
      <Button size="large" onClick={closeConfirmationModal}>
        Cancel
      </Button>
      <Button size="large" color="brand" onClick={startRowsSelection}>
        Yes, Make New Validations
      </Button>
    </ButtonGroupRight>
  );

  showConfirmationModal(message, header, footer);
};

export default BulkCreateSurveysButtons;
