import React from "react";

import TextArea from "../../../../components/lib/TextArea";
import Stack from "../../../../components/lib/Stack";
import { ButtonGroupRight } from "../../../../components/lib/ButtonGroup";
import Button from "../../../../components/lib/Button";
import PromiseButton from "../../../../components/lib/PromiseButton";
import Inline, { InlineElements } from "../../../../components/lib/Inline";
import Text from "../../../../components/lib/Text";
import NumberInput from "../../../../components/lib/NumberInput";
import { compareDecimals } from "../../constants";

const commentBoxStyles = {
  minHeight: "100px",
  border: "none",
  padding: 0,
};

type CommentFormProps = Omit<
  React.ComponentProps<typeof TextArea>,
  "value" | "onChange"
> & {
  comment: string | null;
  onApply: (value: string | null) => Promise<void>;
  onCancel: () => void;
};

export const CommentForm = (props: CommentFormProps) => {
  const { comment, onApply, onCancel } = props;

  // state
  const [value, setValue] = React.useState<string | null>(comment);

  // handlers
  const handleChangeValue = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setValue(e.target.value);
  const handleApplyClick = (e: React.MouseEvent<HTMLButtonElement>) => onApply(value);

  return (
    <Stack>
      <TextArea
        fill
        size="small"
        placeholder="Enter comment here"
        css={commentBoxStyles}
        value={value ?? ""}
        onChange={handleChangeValue}
      />
      <ButtonGroupRight fill css={{ flexDirection: "row-reverse" }}>
        <Button size="normal" onClick={onCancel}>
          Cancel
        </Button>
        <PromiseButton size="normal" color="brand" onClick={handleApplyClick}>
          Apply Feedback
        </PromiseButton>
      </ButtonGroupRight>
    </Stack>
  );
};

CommentForm.defaultName = "CommentForm";

export type CommentNRatesValuesType = {
  payRateMin: number;
  payRateMax: number;
  comment: string | null;
};

type CommentNRatesFormProps = CommentNRatesValuesType & {
  currencySymbol: string;
  onApply: (values: CommentNRatesValuesType) => Promise<void>;
  onCancel: () => void;
};

export const CommentNRatesForm = (props: CommentNRatesFormProps) => {
  const { payRateMin, payRateMax, currencySymbol, comment, onApply, onCancel } = props;

  // state

  const [message, setMessage] = React.useState<string | null>(null);
  const [state, setState] = React.useState<CommentNRatesValuesType>({
    payRateMin,
    payRateMax,
    comment,
  });

  const updateState = React.useCallback(
    (key, value) => setState((prevState) => ({ ...prevState, [key]: value })),
    []
  );

  // utils

  const validateFeedback = React.useCallback(() => {
    if ((!state.payRateMin && !state.payRateMax) || !state.comment) {
      setMessage(
        "At least one feedback should be provided for the wages along with a comment."
      );
      return false;
    }

    if (
      compareDecimals(payRateMin, state.payRateMin) === 0 &&
      compareDecimals(payRateMax, state.payRateMax) === 0
    ) {
      setMessage(
        'You clicked "Disagree". At least one feedback should be provided for the wages.'
      );
      return false;
    }

    if (
      state.payRateMin &&
      state.payRateMax &&
      compareDecimals(state.payRateMin, state.payRateMax) >= 0
    ) {
      setMessage("Lowest Wage value should be less then Highest Wage value.");
      return false;
    }

    return true;
  }, [state.payRateMin, state.payRateMax, state.comment, payRateMin, payRateMax]);

  // handlers

  const handleApplyClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!validateFeedback()) return;
    onApply(state);
  };

  return (
    <Stack css={{ alignItems: "flex-start" }}>
      {/* low rate */}
      <Inline fill css={{ justifyContent: "space-between" }}>
        <Text>Lowest Wage:</Text>
        <InlineElements>
          <Text>{currencySymbol}</Text>
          <NumberInput
            value={state.payRateMin ?? ""}
            onChange={(e) => updateState("payRateMin", e.target.valueAsNumber)}
          />
        </InlineElements>
      </Inline>

      {/* high rate */}
      <Inline fill css={{ justifyContent: "space-between" }}>
        <Text>Highest Wage:</Text>
        <InlineElements>
          <Text>{currencySymbol}</Text>
          <NumberInput
            value={state.payRateMax ?? ""}
            onChange={(e) => updateState("payRateMax", e.target.valueAsNumber)}
          />
        </InlineElements>
      </Inline>

      {/* comment */}
      <TextArea
        fill
        size="small"
        placeholder="Enter comment here"
        css={commentBoxStyles}
        value={state.comment ?? ""}
        onChange={(e) => updateState("comment", e.target.value)}
      />

      <div>{message && <Text color="negative">{message}</Text>}</div>

      {/* buttons */}
      <ButtonGroupRight fill css={{ flexDirection: "row-reverse" }}>
        <Button size="normal" onClick={onCancel}>
          Cancel
        </Button>
        <PromiseButton size="normal" color="brand" onClick={handleApplyClick}>
          Apply Feedback
        </PromiseButton>
      </ButtonGroupRight>
    </Stack>
  );
};
