import React from "react";
import { usePaginationFragment } from "react-relay";
// @ts-ignore
import graphql from "babel-plugin-relay/macro";
import { parseAbsoluteToLocal, DateFormatter } from "@internationalized/date";

import Text from "../../../components/lib/Text";
import Center from "../../../components/lib/Center";
import Tag from "../../../components/lib/Tag";
import Tooltip from "../../../components/lib/Tooltip";
import Pagination from "../../../components/lib/Pagination";
import LinkButton from "../../../components/lib/LinkButton";
import {
  CardFooter,
  CardList,
  CardListItem,
  CardListItemLeft,
  CardListItemName,
  CardListItemRight,
  CardListSubText,
  Info,
  InfoData,
  InfoLabel,
  SubTextAccent,
} from "../../../components/lib/Card";

import type { RateCardsList_viewer$key } from "./__generated__/RateCardsList_viewer.graphql";
import type { SortMachineContextType } from "../../../machines/rcListSortMachine";
import type { FilterMachineContextType } from "../../../machines/rcListFilterMachine";

const DATE_FORMATTER = new DateFormatter("en-US", { dateStyle: "long" });

type RateCardsListProps = {
  viewer: RateCardsList_viewer$key;
  search: string;
  filters: FilterMachineContextType["filters"];
  sortFieldsMap: SortMachineContextType["sortFieldsMap"];
  editing: boolean;
  pageSize?: number;
};

function RateCardsList(props: RateCardsListProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { search, filters, sortFieldsMap, editing, pageSize = 10 } = props;
  const {
    data: viewer,
    refetch,
    hasNext,
    loadNext,
    hasPrevious,
    loadPrevious,
  } = usePaginationFragment(
    graphql`
      fragment RateCardsList_viewer on Viewer
      @refetchable(queryName: "RateCardsListPaginationQuery")
      @argumentDefinitions(
        after: { type: "String" }
        before: { type: "String" }
        first: { type: "Int" }
        last: { type: "Int" }
        search: { type: "String" }
        filters: { type: "RateCardsFiltersInput" }
        order: { type: "[RateCardSortInput]" }
      ) {
        user {
          userId
        }
        allRateCards(
          after: $after
          before: $before
          first: $first
          last: $last
          search: $search
          filters: $filters
          order: $order
        ) @connection(key: "RateCardsList_allRateCards", handler: "connection_window") {
          edges {
            node {
              id
              ratecardId
              shared
              tags {
                name
                tagId
              }
              owner {
                firstName
                lastName
                userId
              }
              name
              createdDate
              searchCount
              locationCount
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
          totalCount
        }
      }
    `,
    props.viewer
  );

  const edges = viewer.allRateCards?.edges ?? [];
  const itemsCount = viewer.allRateCards?.totalCount ?? 0;
  const hasFiltersApplied = Object.keys(filters).length > 0 || !!search;
  const needsPagination =
    viewer.allRateCards?.pageInfo.hasNextPage ||
    viewer.allRateCards?.pageInfo.hasPreviousPage;

  const prevFiltersRef = React.useRef(filters);
  const prevSortFieldsRef = React.useRef(sortFieldsMap);
  const prevSearchRef = React.useRef(search);

  React.useEffect(() => {
    const hasFiltersChanged = filters !== prevFiltersRef.current;
    const hasSortsChanged = sortFieldsMap !== prevSortFieldsRef.current;
    const hasSearchChanged = search !== prevSearchRef.current;

    if (hasFiltersChanged || hasSortsChanged || hasSearchChanged) {
      prevFiltersRef.current = filters;
      prevSortFieldsRef.current = sortFieldsMap;
      prevSearchRef.current = search;

      refetch(
        { search, filters, order: Array.from(sortFieldsMap.values()) },
        { fetchPolicy: "network-only" }
      );
    }
  }, [refetch, search, filters, sortFieldsMap]);

  return (
    <>
      <CardList>
        {itemsCount === 0 && hasFiltersApplied && (
          <Center css={{ padding: "$10" }}>
            <Text nowrap color="light" css={{ fontSize: "$3xl" }}>
              No Rate Cards match your current filters
            </Text>
          </Center>
        )}
        {itemsCount === 0 && !hasFiltersApplied && (
          <Center css={{ padding: "$10" }}>
            <Text nowrap color="light" css={{ fontSize: "$3xl" }}>
              No Rate Cards so far
            </Text>
          </Center>
        )}
        {itemsCount > 0 &&
          edges.map((i) => {
            if (i?.node) {
              return (
                <CardListItem
                  key={i.node.id}
                  css={{
                    // TODO: style this properly
                    gridTemplateColumns: "minmax(350px, 2fr) minmax(350px, 1fr)",
                  }}
                >
                  <CardListItemLeft>
                    <CardListItemName>
                      {i.node.name}{" "}
                      {i.node.shared && (
                        <Tooltip content="Shared..." side="top">
                          <Tag
                            size="extra-small"
                            text={
                              viewer.user.userId === i.node.owner.userId
                                ? "By You"
                                : "To You"
                            }
                            icon="share"
                            color="yellow"
                            {...(viewer.user.userId !== i.node.owner.userId && {
                              iconProps: {
                                flip: "horizontal",
                              },
                            })}
                          />
                        </Tooltip>
                      )}
                    </CardListItemName>
                    <CardListSubText>
                      Created by{" "}
                      <SubTextAccent>
                        {[i.node.owner.firstName, i.node.owner.lastName]
                          .filter((i) => i)
                          .join(" ")}
                      </SubTextAccent>{" "}
                      on{" "}
                      <SubTextAccent>
                        {DATE_FORMATTER.format(
                          parseAbsoluteToLocal(i.node.createdDate).toDate()
                        )}
                      </SubTextAccent>
                    </CardListSubText>
                  </CardListItemLeft>
                  <CardListItemRight>
                    <Info>
                      <InfoLabel>Searches</InfoLabel>
                      <InfoData>{i.node.searchCount}</InfoData>
                    </Info>
                    <Info>
                      <InfoLabel>Locations</InfoLabel>
                      <InfoData>{i.node.locationCount}</InfoData>
                    </Info>
                    {!editing && (
                      <LinkButton to={`/ratecards/${i.node.ratecardId}`}>View</LinkButton>
                    )}
                  </CardListItemRight>
                </CardListItem>
              );
            }

            return null;
          })}
      </CardList>
      <CardFooter>
        {itemsCount > 0 && needsPagination && (
          <Pagination
            options={{ variant: "compact" }}
            onPageClick={(pageEvent) => {
              if (pageEvent.type === "first") {
                refetch(
                  { first: pageSize, after: null, before: null, last: null },
                  { fetchPolicy: "network-only" }
                );
              }
              if (pageEvent.type === "last") {
                refetch(
                  { last: pageSize, before: null, first: null, after: null },
                  { fetchPolicy: "network-only" }
                );
              }
              if (pageEvent.type === "next") {
                if (hasNext) {
                  loadNext(pageSize);
                }
              }
              if (pageEvent.type === "prev") {
                if (hasPrevious) {
                  loadPrevious(pageSize);
                }
              }
            }}
          />
        )}
      </CardFooter>
    </>
  );
}
RateCardsList.displayName = "RateCardList";
RateCardsList.defaultProps = {
  pageSize: 10,
};

export default RateCardsList;
