import React, { useContext, useMemo } from "react";

import JobFeedbackDetailsBlock from "./JobFeedbackDetailsBlock";

import { SummaryTable } from "../SummaryTable";
import { RateDisplayContext } from "../../context/RateDisplayContext";
import { RATE_TYPES } from "../../types";

import type { JobFeedbackDetailsBlockProps } from "./JobFeedbackDetailsBlock";
import type { SummaryKeysType, SummaryTableProps } from "../SummaryTable";
import type { RateDisplayContextObject } from "../../context/RateDisplayContext";

const summaryKeys: SummaryKeysType = {
  highKeys: ["pay_rate_max", "markup_max", "bill_rate_max"],
  lowKeys: ["pay_rate_min", "markup_min", "bill_rate_min"],
  highAnnualKeys: ["annual_salary_max"],
  lowAnnualKeys: ["annual_salary_min"],
};

type PickSummaryTableProps = Pick<
  SummaryTableProps,
  "summaryData" | "rateType" | "currencySymbol" | "convertToValue"
>;
type FillInTheBlanksDetailsContentProps = JobFeedbackDetailsBlockProps &
  PickSummaryTableProps;

export default function FillInTheBlanksDetailsContent(
  props: FillInTheBlanksDetailsContentProps
) {
  const {
    title,
    jobIsRegionBased,
    jobLocationString,
    jobRegionString,
    currencyCode,
    currencyName,
    industries,
    rateTypeLabel,
    validatorString,
    validationTimestamp,
    questions,
    questionsFeedback,
    summaryData,
    rateType,
    currencySymbol,
    convertToValue,
  } = props;

  const { displayRateType } = useContext<RateDisplayContextObject>(RateDisplayContext);

  const requiresConversion = useMemo(
    () =>
      rateType === RATE_TYPES.HOURLY &&
      (displayRateType === RATE_TYPES.DAILY ||
        displayRateType === RATE_TYPES.WEEKLY ||
        displayRateType === RATE_TYPES.MONTHLY),
    [rateType, displayRateType]
  );

  return (
    <JobFeedbackDetailsBlock
      title={title}
      jobIsRegionBased={jobIsRegionBased}
      jobLocationString={jobLocationString}
      jobRegionString={jobRegionString}
      currencyCode={currencyCode}
      currencyName={currencyName}
      industries={industries}
      rateTypeLabel={rateTypeLabel}
      validatorString={validatorString}
      validationTimestamp={validationTimestamp}
      questions={questions}
      questionsFeedback={questionsFeedback}
    >
      <SummaryTable
        summaryData={summaryData}
        summaryKeys={summaryKeys}
        rateType={rateType}
        displayRateType={displayRateType}
        currencySymbol={currencySymbol}
        needConvertedValue={requiresConversion}
        convertToValue={convertToValue}
      />
    </JobFeedbackDetailsBlock>
  );
}

FillInTheBlanksDetailsContent.displayName = "FillInTheBlanksDetailsContent";
