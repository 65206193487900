/**
 * Various functions to aide in filtering fields.
 */

import R from "ramda";
import fields from "../components/fields";

export const fieldEq = R.curryN(3, (fieldName, value, field) => {
  return field[fieldName] === value;
});

export const fieldContains = R.curryN(3, (fieldName, value, field) => {
  var x = field[fieldName];
  return x !== undefined && x.length !== 0 && x.includes(value);
});

export const valueEq = R.curryN(2, (fieldValueGetter, value, field) => {
  return fieldValueGetter(field) === value;
});

export const fieldValueTypeEq = R.curryN(3, (value, field) => {
  const { valueType } = fields[field.fieldType];
  return valueType === value;
});

export const fieldTypeEq = fieldEq("fieldType");
export const workerTypesContains = fieldContains("workerTypes");
export const rateTypeContains = fieldEq("rateType");
export const workerTypeEq = fieldEq("workerType");
export const idEq = fieldEq("id");

export const truthy = (x) => !!x;
export const notNull = (x) => x !== null;

export const all = (...args) => R.allPass([...args]);
export const any = (...args) => R.anyPass([...args]);
