// @flow

import React, { Component } from "react";
import { observer } from "mobx-react";
import SelectableItem from "../../ratecards/components/SelectableItem";
import SelectableItemsList from "../../ratecards/components/SelectableItemsList";
import ContainerSection from "../../ratecards/components/ContainerSection";
import FlagIcon from "../../../components/FlagIcon";
import CCCCountry from "../../../models/CCCCountry";

interface Store {
  storeCountries: Array<CCCCountry>;
  toggleStoreCountrySelected: (CCCCountry) => void;
  selectedStoreCountriesCount: number;
  totalStoreCountries: number;
}

type Props = {
  store: Store,
};

class SelectStoreCountriesList extends Component<Props> {
  render() {
    const store: Store = this.props.store;

    const countries = store.storeCountries.map((country: CCCCountry) => {
      return (
        <SelectableItem
          key={country.databaseId}
          item={country}
          name="store-country"
          value={country.databaseId}
          selected={country.selected}
          onChange={store.toggleStoreCountrySelected}
        >
          <div title={country.title}>
            <FlagIcon code={country.code} /> {country.title}
          </div>
        </SelectableItem>
      );
    });

    const empty = <div style={{ padding: 20 }}>No Countries</div>;

    return (
      <div>
        <SelectableItemsList>
          {countries.length > 0 ? countries : empty}
        </SelectableItemsList>
        <ContainerSection className="info">
          <p className="text-muted text-x-small no-margin no-border pull-right">
            <em>
              <strong>{store.selectedStoreCountriesCount}</strong> selected,{" "}
              <strong>{store.totalStoreCountries}</strong> total
            </em>
          </p>
          <div className="clearfix" />
        </ContainerSection>
      </div>
    );
  }
}

export default observer(SelectStoreCountriesList);
