import React, { useEffect, useState } from "react";
import { fetchQuery, useRelayEnvironment } from "react-relay";
// @ts-ignore
import graphql from "babel-plugin-relay/macro";
import Button from "../../../components/lib/Button";
import { Checkbox, CheckboxIndicator } from "../../../components/lib/Checkbox";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "../../../components/lib/Dialog";
import Icon from "../../../components/lib/Icon";
import Inline from "../../../components/lib/Inline";
import Stack from "../../../components/lib/Stack";
import Text from "../../../components/lib/Text";
import { styled } from "../../../stitches.config";
import { Location } from "../types";
import RsLocationSelect from "./RsLocationSelect";
import {
  RsLocationSearchDialogCityQuery,
  RsLocationSearchDialogCityQuery$data,
} from "./__generated__/RsLocationSearchDialogCityQuery.graphql";
import {
  RsLocationSearchDialogCountryQuery,
  RsLocationSearchDialogCountryQuery$data,
} from "./__generated__/RsLocationSearchDialogCountryQuery.graphql";
import { RsLocationSearchDialogLocationsByIdQuery } from "./__generated__/RsLocationSearchDialogLocationsByIdQuery.graphql";
import {
  RsLocationSearchDialogStateQuery,
  RsLocationSearchDialogStateQuery$data,
} from "./__generated__/RsLocationSearchDialogStateQuery.graphql";

function concatIntArray(arr1: number[], arr2: number[]): number[] {
  return arr1.concat(arr2);
}

interface RsLocationSearchDialogProps {
  children: any;
  locations: Location[];
  certifiedCountries: number[];
  adhocCountries: number[];
  countryIds?: number[] | undefined;
  onLocationChange: (locations: Location[]) => void;
}

let cache: RsLocationSearchDialogCountryQuery$data = { viewer: null };

// https://github.com/relay-tools/relay-compiler-language-typescript/issues/64#issuecomment-511765083
type NoRefs<T> = T extends Record<string, unknown>
  ? Omit<T, " $refType" | " $fragmentRefs">
  : T;

type ExtractCountryNode<T extends { countries: ReadonlyArray<any> | null } | null> =
  NoRefs<NonNullable<NonNullable<T>["countries"]>[0]>;

type Country = ExtractCountryNode<RsLocationSearchDialogCountryQuery$data["viewer"]>;

type ExtractStateNode<
  T extends { states: { results: ReadonlyArray<any> | null } | null } | null
> = NoRefs<NonNullable<NonNullable<NonNullable<T>["states"]>["results"]>[0]>;

type State = ExtractStateNode<RsLocationSearchDialogStateQuery$data["viewer"]>;

type ExtractCityNode<
  T extends { cities: { results: ReadonlyArray<any> | null } | null } | null
> = NoRefs<NonNullable<NonNullable<NonNullable<T>["cities"]>["results"]>[0]>;

type City = ExtractCityNode<RsLocationSearchDialogCityQuery$data["viewer"]>;

function RsLocationSearchDialog(props: RsLocationSearchDialogProps) {
  const { children } = props;
  const relayEnvironment = useRelayEnvironment();

  const [locations, setLocations] = useState<Location[]>([]);

  const [activeCountry, setActiveCountry] = useState<Country>();
  const [activeState, setActiveState] = useState<State>();
  const [activeCity, setActiveCity] = useState<City>();

  const [countryList, setCountryList] = useState<Readonly<Country[]>>([]);
  const [stateList, setStateList] = useState<State[]>([]);
  const [cityList, setCityList] = useState<City[]>([]);

  useEffect(() => {
    if (cache?.viewer?.countries) {
      setCountryList(cache.viewer.countries);
      setActiveCountry(null);
      setStateList([]);
      setActiveState(null);
      setCityList([]);
      setActiveCity(null);
      return;
    }

    let filters = {};
    const countryIds = concatIntArray(props.certifiedCountries, props.adhocCountries);
    if (countryIds && countryIds.length > 0) {
      filters = { apiLocationIdIn: countryIds };
    }

    fetchQuery<RsLocationSearchDialogCountryQuery>(relayEnvironment, COUNTRY_QUERY, {
      filters: filters,
    }).subscribe({
      next: (response) => {
        cache = response;
        const countries = response?.viewer?.countries || [];
        setCountryList(countries);
        setActiveCountry(null);
        setStateList([]);
        setActiveState(null);
        setCityList([]);
        setActiveCity(null);
      },
      error: () => {},
    });
  }, [props.certifiedCountries, props.adhocCountries, relayEnvironment]);

  useEffect(() => {
    if (activeCountry?.locationId) {
      const id = activeCountry.locationId;

      fetchQuery<RsLocationSearchDialogStateQuery>(relayEnvironment, STATE_QUERY, {
        id: String(id),
      }).subscribe({
        next: (response) => {
          const states = response?.viewer?.states?.results || [];
          setStateList([...states]);
          setActiveState(null);
          setCityList([]);
          setActiveCity(null);
        },
        error: () => {},
      });
    } else {
      setStateList([]);
    }
  }, [activeCountry, relayEnvironment]);

  useEffect(() => {
    if (activeState?.id) {
      const id = activeState.id;

      fetchQuery<RsLocationSearchDialogCityQuery>(relayEnvironment, CITY_QUERY, {
        id: id,
      }).subscribe({
        next: (response) => {
          const cities = response?.viewer?.cities?.results || [];
          setCityList([...cities]);
          setActiveCity(null);
        },
        error: () => {},
      });
    } else {
      setStateList([]);
    }
  }, [activeState, relayEnvironment]);

  function handleLocationChange(newLocations: Location[]) {
    setLocations(newLocations);
  }

  function isLocationSelected(locationId: number) {
    return locations.filter((l) => l?.locationId + "" === locationId + "").length > 0;
  }

  function onCountryClick(country: any) {
    setActiveCountry({ ...country });
  }

  function onStateClick(state: any) {
    setActiveState({ ...state });
  }

  function onCityClick(city: any) {
    setActiveCity({ ...city });
  }

  function onCountryCheck(country: any, checked: boolean) {
    const locationId = country.locationId;
    updateLocation(locationId, checked, country.isoCode);
  }

  function onStateCheck(state: any, checked: boolean) {
    const locationId = state.id;
    updateLocation(locationId, checked, activeCountry?.isoCode);
  }

  function onCityCheck(city: any, checked: boolean) {
    const locationId = city.id;
    updateLocation(locationId, checked, activeCountry?.isoCode);
  }

  function updateLocation(
    locationId: number,
    checked: boolean,
    countryCode?: string | null
  ) {
    if (checked) {
      fetchQuery<RsLocationSearchDialogLocationsByIdQuery>(
        relayEnvironment,
        LOCATIONS_BY_ID_QUERY,
        { ids: [String(locationId)] }
      ).subscribe({
        next: (response) => {
          const locationsByIds = response?.viewer?.locationsByIds;
          if (locationsByIds?.length) {
            const location = locationsByIds[0];

            const newLocation: Location = {
              fullTitle: location?.fullTitle ?? "",
              fullSubtitle: location?.fullSubtitle || null,
              countryId: location?.countryId || null,
              locationId: location?.locationId ?? "0",
            };

            setLocations([...locations, newLocation]);
          }
        },
        error: () => {},
      });
    } else {
      const listWithDeletedLocation = locations.filter(
        (l) => l?.locationId + "" !== locationId + ""
      );
      setLocations([...listWithDeletedLocation]);
    }
  }

  function onSave() {
    props.onLocationChange(locations);
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <span>{children}</span>
      </DialogTrigger>
      <DialogContent
        css={{
          width: "95vw",
          maxWidth: "1400px",
          padding: "$4",
        }}
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <Stack fill css={{ alignItems: "flex-start", gap: "$4" }}>
          <Text as="h3" noMargin>
            Advanced Location Search
          </Text>

          <RsLocationSelect
            value={locations}
            onChange={(locations: Location[]) => handleLocationChange(locations)}
            countryIds={props.countryIds}
            certifiedCountries={props.certifiedCountries}
            adhocCountries={props.adhocCountries}
          />

          <Inline fill css={{ height: "540px", gap: "0px" }}>
            <SelectItemList>
              {countryList.map((country) => {
                if (country) {
                  return (
                    <SelectItem
                      key={country.locationId}
                      id={country.locationId!}
                      name={country.name!}
                      active={activeCountry?.locationId + "" === country.locationId + ""}
                      selected={isLocationSelected(country.locationId!)}
                      onClick={() => onCountryClick(country)}
                      onCheck={(checked) => onCountryCheck(country, checked)}
                    />
                  );
                }

                return null;
              })}
            </SelectItemList>
            <SelectItemList>
              {stateList.map((state) => {
                if (state) {
                  return (
                    <SelectItem
                      key={state.id}
                      id={Number(state.id!)}
                      name={state.title!}
                      active={activeState?.id + "" === state.id + ""}
                      selected={isLocationSelected(Number(state.id!))}
                      onClick={() => onStateClick(state)}
                      onCheck={(checked) => onStateCheck(state, checked)}
                    />
                  );
                }
                return null;
              })}
            </SelectItemList>
            <SelectItemList>
              {cityList.map((city) => {
                if (city) {
                  return (
                    <SelectItem
                      key={city.id}
                      id={Number(city.id!)}
                      name={city.title!}
                      active={activeCity?.id + "" === city.id + ""}
                      selected={isLocationSelected(Number(city.id!))}
                      onClick={() => onCityClick(city)}
                      onCheck={(checked) => onCityCheck(city, checked)}
                      hideArrow={true}
                    />
                  );
                }
                return null;
              })}
            </SelectItemList>
          </Inline>

          <Inline fill css={{ justifyContent: "flex-end" }}>
            <DialogClose asChild>
              <Button variant="outlined" color="brand" size="small">
                Close
              </Button>
            </DialogClose>
            <DialogClose asChild>
              <Button onClick={onSave} variant="filled" color="brand" size="small">
                Save Location
              </Button>
            </DialogClose>
          </Inline>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

const SelectItemList = styled(Stack, {
  border: "1px solid #ddd",
  height: "100%",
  overflowY: "auto",
  flex: 1,
  gap: "0px",
});

const SelectItemContainer = styled(Inline, {
  width: "100%",
  padding: "10px 20px",
  cursor: "pointer",
  fontSize: "18px",
  fontWeight: "400",

  "&:hover": {
    backgroundColor: "$brandLightest",
  },

  variants: {
    selected: {
      true: {
        backgroundColor: "$brandLightest",
      },
    },
  },
});

const SelectItemName = styled("div", {
  variants: {
    selected: {
      true: {
        color: "$brand",
      },
    },
  },
});

const RightArrowIcon = styled(Icon, {
  color: "$primaryLight",
  marginLeft: "auto",

  variants: {
    active: {
      true: {
        fontSize: "50",
        color: "$brand",
      },
    },
  },
});

interface SelectItemProps {
  name: string;
  id: number;
  selected: boolean;
  active: boolean;
  onClick: any;
  onCheck: any;
  hideArrow?: boolean;
}

function SelectItem(props: SelectItemProps) {
  function onClick() {
    props.onClick();
  }

  function onCheck(checked: boolean) {
    props.onCheck(checked);
  }

  return (
    <SelectItemContainer onClick={() => onClick()} selected={props.selected}>
      <Checkbox checked={props.selected} onCheckedChange={onCheck}>
        <CheckboxIndicator>
          <Icon icon={"check"} />
        </CheckboxIndicator>
      </Checkbox>
      <SelectItemName selected={props.selected}>{props.name}</SelectItemName>
      {!props.hideArrow && (
        <RightArrowIcon icon={"chevron-right"} active={props.active}></RightArrowIcon>
      )}
    </SelectItemContainer>
  );
}

export default RsLocationSearchDialog;

const COUNTRY_QUERY = graphql`
  query RsLocationSearchDialogCountryQuery($filters: LocationCountryFiltersInput) {
    viewer {
      countries(filters: $filters) {
        name
        locationId
        isoCode
      }
    }
  }
`;

const STATE_QUERY = graphql`
  query RsLocationSearchDialogStateQuery($id: ID!) {
    viewer {
      states(id: $id) {
        results {
          id
          title
        }
      }
    }
  }
`;

const CITY_QUERY = graphql`
  query RsLocationSearchDialogCityQuery($id: ID!) {
    viewer {
      cities(id: $id) {
        results {
          id
          title
        }
      }
    }
  }
`;

const LOCATIONS_BY_ID_QUERY = graphql`
  query RsLocationSearchDialogLocationsByIdQuery($ids: [String]) {
    viewer {
      locationsByIds(ids: $ids) {
        type
        fullTitle
        fullSubtitle
        countryId
        locationId
        lat
        lon
      }
    }
  }
`;
