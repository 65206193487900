import React from "react";
import PropTypes from "prop-types";
import { FormControl, InputGroup } from "react-bootstrap";
import NumberInput from "../utils/NumberInput";

import {
  RATE_TYPE_OPTIONS,
  SALARY_RATE_TYPE_OPTIONS,
} from "../../../../constants/negotiationWorksheet";

import * as NegotiationWorksheetPropTypes from "../../../../prop_types/NegotationWorksheet";

export const valueType = "markup";

export class Field extends React.Component {
  static propTypes = {
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
  };

  shouldComponentUpdate(nextProps) {
    return nextProps.value !== this.props.value;
  }

  render() {
    return (
      <NumberInput
        step="any"
        addonAfter="%"
        min={0}
        disabled={this.props.disabled}
        {...this.props}
      />
    );
  }
}

export class CalculatedValue extends React.Component {
  static propTypes = {
    value: PropTypes.number.isRequired,
    payRateHourly: PropTypes.number.isRequired,
    conversionOptions: NegotiationWorksheetPropTypes.JSConversionOptions.isRequired,
    currencySymbol: NegotiationWorksheetPropTypes.CurrencySymbol.isRequired,
  };

  shouldComponentUpdate(nextProps) {
    const props = this.props;
    return (
      nextProps.payType !== props.payType ||
      nextProps.payRateHourly !== props.payRateHourly ||
      nextProps.conversionOptions !== props.conversionOptions ||
      nextProps.currencySymbol !== props.currencySymbol ||
      nextProps.value !== props.value
    );
  }

  render() {
    const { value, payType, payRateHourly, rateType, conversionOptions, currencySymbol } =
      this.props;
    const calculatedValue =
      payType === 3
        ? SALARY_RATE_TYPE_OPTIONS.convertFromHourly(
            (value / 100) * payRateHourly,
            conversionOptions
          )
        : RATE_TYPE_OPTIONS[rateType].convertFromHourly(
            (value / 100) * payRateHourly,
            conversionOptions
          );

    return (
      <InputGroup>
        <InputGroup.Addon>{currencySymbol}</InputGroup.Addon>
        <FormControl type="number" value={calculatedValue.toFixed(2)} disabled={true} />
      </InputGroup>
    );
  }
}
