// @flow

import R from "ramda";
import { extendObservable, action, runInAction, computed, observable } from "mobx";
import axios from "axios";
import PaginationState from "../../models/PaginationState";
import NetworkState from "../../models/NetworkState";
import type { PageQuery, PaginationInfo } from "../../models/PaginationState";
import FilterObject, { FILTER_COLUMN } from "../../models/Filter";
import { TagContentFilter } from "../../models/FilterState";
import { consolidateAppliedFilters, consolidateAppliedSorts } from "./SupportFunctions";
import ModalState from "../../models/ModalState";
import ContentTagList from "../../models/ContentTagList";
import type MobXStore from "./MobXStore";
import type { FetchGraphQL } from "../../App";

const tagsFilterCriteria = `
query getNotificationCount {
 viewer{
   notifications{
     totalCount
   }
 }
}
`;

export class ContentListComponentStore {
  getTagDetail: (PageQuery) => Promise<PaginationInfo>;
  network: NetworkState;
  pagination: PaginationState;
  totalCount: any;
  tagDetailList: ContentTagList[];
  tagDetailListView: ContentTagList[];
  tagDetailListViewState: Object;
  defaultFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedSorts: {
    [key: FilterColumn]: Sort,
  };
  isFiltered: boolean;
  appliedSortsOrder: Array<FilterColumn>;
  applyDefaultFilter: (FilterColumn, FilterObject) => void;
  applyFilter: (FilterColumn, FilterObject) => void;
  removeFilter: (FilterColumn) => void;
  applySort: (FilterColumn, Sort) => void;
  removeSort: (FilterColumn) => void;
  clearFilters: () => void;
  isEditing: ?boolean;
  handleStartEdit: () => void;
  handleStopEdit: () => void;
  toggleSelectAllPage: (Object) => void;
  selectAllPage: (Event) => void;
  selectAllOnPageItem: () => void;
  deselectAllPage: (Event) => void;
  clearAllSelections: () => void;
  allSelected: boolean;
  allOnPageSelected: boolean;
  allowMultipleItemSelection: boolean;
  getSelectedTagContentList: () => Array<string>;
  tagId: any;
  unSelectedTagContent: [];
  getFilterCriteriaQuery: (FilterColumn) => GraphQLQuery;
  processFilterCriteria: (FilterColumn, Object) => Array<Object>;
  tagContentFilter: TagContentFilter;
  confirmLocationDeleteModal: ModalState;
  fetchGraphQL: FetchGraphQL;

  constructor(fetchGraphQL: FetchGraphQL) {
    this.fetchGraphQL = fetchGraphQL;
    this.router = null;
    this.getTagDetail = action(this.getTagDetail.bind(this));
    this.applyFilter = action(this.applyFilter.bind(this));
    this.applySort = action(this.applySort.bind(this));
    this.removeFilter = action(this.removeFilter.bind(this));
    this.removeSort = action(this.removeSort.bind(this));
    this.getFilterCriteriaQuery = action(this.getFilterCriteriaQuery.bind(this));
    this.processFilterCriteria = action(this.processFilterCriteria.bind(this));
    this.selectAllOnPageItem = action(this.selectAllOnPageItem.bind(this));
    this.unSelectedTagContent = [];

    extendObservable(this, {
      tagId: "",
      network: new NetworkState(),
      pagination: new PaginationState(this.getTagDetail),
      totalCount: 0,
      tagDetailList: [],
      allSelected: false,
      showLocationPopup: false,
      tagDetailListViewState: observable.map({}),
      tagDetailListView: computed(() => {
        return this.tagDetailList.map((tagD) => {
          if (this.tagDetailListViewState.has(tagD.tagContentId)) {
            tagD.viewState = this.tagDetailListViewState.get(tagD.tagContentId);

            return tagD;
          }

          return tagD;
        });
      }),
      allOnPageSelected: computed(() => {
        const allTrue = R.all(R.equals(true));
        const selectedValues = this.tagDetailListView.map(
          (tagDetailListView) => tagDetailListView.viewState.selected
        );

        if (selectedValues.length === 0) {
          return false;
        }

        return allTrue(selectedValues);
      }),
      appliedSortsOrder: observable.shallow([]),
      defaultFilters: {},
      appliedFilters: {},
      appliedSorts: {},
      isFiltered: false,
      isEditing: null,
      allowMultipleItemSelection: true,
      selectedCount: computed(() => {
        const selectedValues = this.tagDetailListView.map(
          (region) => region.viewState.selected
        );

        if (this.allSelected) {
          return this.pagination.totalCount;
        }

        let count = 0;

        selectedValues.forEach((v) => {
          if (v) {
            count += 1;
          }
        });

        return count;
      }),
      tagContentFilter: new TagContentFilter(
        this,
        FILTER_COLUMN.TAG_CONTENT,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      confirmLocationDeleteModal: new ModalState(),
    });

    this.applyDefaultFilter = action(this.applyDefaultFilter.bind(this));
    this.handleStartEdit = action(this.handleStartEdit.bind(this));
    this.handleStopEdit = action(this.handleStopEdit.bind(this));
    this.clearFilters = action(this.clearFilters.bind(this));
    this.toggleSelectAllPage = action(this.toggleSelectAllPage.bind(this));
    this.selectAllPage = action(this.selectAllPage.bind(this));
    this.deselectAllPage = action(this.deselectAllPage.bind(this));
    this.clearAllSelections = action(this.clearAllSelections.bind(this));
    this.getSelectedTagContentList = action(this.getSelectedTagContentList.bind(this));
  }

  getFilterCriteriaQuery(column: FilterColumn): GraphQLQuery {
    switch (column) {
      case FILTER_COLUMN.TAG_CONTENT:
        return {
          query: tagsFilterCriteria,
          variables: {},
        };

      default:
        return null;
    }
  }

  //process country filter result data
  processFilterCriteria(column: FilterColumn, payload: Object): Array<any> {
    switch (column) {
      case FILTER_COLUMN.TAG_CONTENT:
        const tags: [
          {
            value: string,
          }
        ] = [
          { value: "JOB_LIBRARY" },
          { value: "NEGOTIATION_WORKSHEET" },
          { value: "PROJECT_STATS" },
          { value: "RATE_CARD" },
          { value: "REGIONS" },
          { value: "SAVED_SEARCHES" },
        ];
        let processedTag = observable.map({});
        tags.forEach((value, index) => {
          processedTag.set(String(index), {
            id: String(index),
            ...value,
          });
        });
        return processedTag;

      default:
        return null;
    }
  }

  handleStartEdit() {
    this.isEditing = true;
    this.tagDetailListView.forEach((tagD) => tagD.toggleEdit());
  }

  handleStopEdit() {
    this.isEditing = false;
    this.allSelected = false;
    this.tagDetailListViewState.forEach((viewState) => {
      viewState.selected = false;
      viewState.editing = false;
    });
  }

  toggleSelectAllPage(e: Object) {
    if (!this.allowMultipleItemSelection) return;

    const setValue = !this.allOnPageSelected;

    this.tagDetailListView.forEach((tagD) => {
      tagD.toggleSelected(e, null, null, setValue);
    });

    // When All items selected flag is up, clear selection
    if (setValue === false && this.allSelected) this.allSelected = false;
  }

  selectAllPage(e: Event) {
    this.allSelected = true;
  }

  selectAllOnPageItem(e: Event) {
    this.unSelectedTagContent = [];
    this.tagDetailListView.forEach((tagD) => {
      tagD.toggleSelected(e, null, null, true);
    });
  }

  deselectAllPage(e: Event) {
    this.tagDetailListView.forEach((tagD) => {
      tagD.toggleSelected(e, null, null, false);
    });

    this.allSelected = false;
  }

  clearAllSelections() {
    this.allSelected = false;
    this.tagDetailListViewState.forEach((value) => {
      value.selected = false;
    });
  }

  applyFilter(column: FilterColumn, filter: FilterObject) {
    this.appliedFilters[column] = filter;
    this.isFiltered = true;
  }

  applyDefaultFilter(column: FilterColumn, filter: FilterObject) {
    this.defaultFilters[column] = filter;
  }

  removeFilter(column: FilterColumn) {
    delete this.appliedFilters[column];

    let entries = Object.entries(this.appliedFilters);
    if (!entries.length) this.isFiltered = false;
  }

  applySort(column: FilterColumn, sort: Sort) {
    this.appliedSorts[column] = sort;

    const index = this.appliedSortsOrder.indexOf(column);
    if (index === -1) this.appliedSortsOrder.push(column);
  }

  removeSort(column: FilterColumn) {
    delete this.appliedSorts[column];

    const index = this.appliedSortsOrder.indexOf(column);
    if (index > -1) this.appliedSortsOrder.splice(index, 1);
  }

  //clear all filters
  clearFilters() {
    this.tagContentFilter = new TagContentFilter(
      this,
      FILTER_COLUMN.TAG_CONTENT,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );

    this.appliedFilters = observable({});
    this.appliedSorts = observable({});
    this.appliedSortsOrder.length = 0;
    this.isFiltered = false;

    return this.pagination.goFetch(null);
  }

  toggleAllItems() {
    if (!this.allowMultipleItemSelection) return;

    this.allSelected = !this.allSelected;

    if (this.allSelected === false) {
      this.projectCostsView.forEach((value) => {
        value.viewState.selected = false;
      });
    }
  }

  getSelectedTagContentList(): Array<string> {
    const tagContList = this.tagDetailListViewState;

    let selectedtagConList = [];

    tagContList.forEach((value, key) => {
      if (value.selected) {
        selectedtagConList.push(parseInt(key, 10));
      }
    });

    return selectedtagConList;
  }

  //function for get region Detail
  async getTagDetail(pageQuery: PageQuery): Promise<PaginationInfo> {
    let res = null;
    let params: string[] = pageQuery.params;
    let args = pageQuery.args;
    let variables = pageQuery.variables;

    let filtersCriteria: string[] = [];

    variables.id = this.tagId;

    let sortCriteria: string[] = [];
    consolidateAppliedSorts(this.appliedSorts, sortCriteria);

    consolidateAppliedFilters(this.appliedFilters, params, filtersCriteria, variables);

    // NOTE: applied filters could override default filters, handle this if needed
    consolidateAppliedFilters(this.defaultFilters, params, filtersCriteria, variables);

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    let queryFiltersCriteria = filtersCriteria.join(", ");

    const query = `query tagDetailList(${queryParams}) {
            viewer {
              tag(id: ${this.tagId}, ${queryFiltersCriteria}) {
                tagContents(${queryArgs}) {
                  totalCount
                  edges {
                    node {
                      tagContentId
                      contents {
                        __typename
                        ... on RegionTagNode {
                          regionId
                          name
                          country {
                            name
                          }
                        }
                        ... on NegotiationWorksheetTagNode {
                          worksheetName
                          worksheetId
                          created
                          country {
                            name
                          }
                        }
                        ... on RateCardTagNode {
                          ratecardName
                          ratecardId
                        }
                        ... on SearchTagNode {
                          searchId
                          job {
                            jobLabel
                            jobTitle
                          }
                        }
                        ... on ProjectTagNode {
                          name
                          projectId
                        }
                        ... on JobTagNode {
                          jobLabel
                          created
                          jobId
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `;

    this.network.loading = true;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting Regions", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Regions query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getTagDetail--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get Region query", res)) {
        // TODO: Display user friendly error message
        return {
          totalCount: 0,
        };
      }

      const tags = res.data.viewer.tag.tagContents.edges;
      this.totalCount = res.data.viewer.tag.tagContents.totalCount;

      // // TODO: Deserialize this properly...
      this.tagDetailList = tags.map((tagD) => {
        const tagContent = new ContentTagList(this, tagD.node);
        if (!this.tagDetailListViewState.has(tagContent.tagContentId)) {
          this.tagDetailListViewState.set(tagContent.tagContentId, {
            selected: this.allSelected,
            editing: this.isEditing,
          });
        } else {
          const selectedValue = this.allSelected
            ? true
            : this.tagDetailListViewState.get(tagContent.tagContentId).selected;

          this.tagDetailListViewState.set(tagContent.tagContentId, {
            selected: selectedValue,
            editing: this.isEditing,
          });
        }

        tagContent.viewState = this.tagDetailListViewState.get(tagContent.tagContentId);

        return tagContent;
      });
      return {
        totalCount: res.data.viewer.tag.tagContents.totalCount,
      };
    });
  }
}

export default class ContentListStore extends ContentListComponentStore {
  deleteTagContent: () => void;
  store: MobXStore;
  constructor(fetchGraphQL: FetchGraphQL, store: MobXStore) {
    super(fetchGraphQL);
    this.store = store;
  }

  async deleteTagContent() {
    let params = [];
    let filterargs = [];
    let vars = {};

    if (this.allSelected) {
      consolidateAppliedFilters(this.appliedFilters, params, filterargs, vars);
      params.push("$exclude: [Int]!");
      filterargs.push("exclude: $exclude");
      vars.exclude = this.unSelectedTagContent;
    } else {
      const selectedtagConList = this.getSelectedTagContentList();
      if (!selectedtagConList || !selectedtagConList.length) {
        console.error("Cannot delete Tags: No tag selected");
        return;
      }
      params.push("$only: [Int]!");
      filterargs.push("only: $only");
      vars.only = selectedtagConList;
    }

    const queryParams = params.join(", ");
    const query = `
      mutation deleteTagContent(${queryParams}){
       removeTagContents(input:{${filterargs}, tagId:${this.tagId}}) {
         ok
       }
      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Deleting selected tag content", e);
      // TODO: Display user friendly error message
      return;
    }
    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }
    this.store.tagManagementDetailStore.handleStopEdit();
    runInAction("deleteTagContent--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("deleteTagContent", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.pagination.goFetch(null);
    });
  }
}
