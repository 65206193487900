import { StylesConfig } from "react-select/lib/styles";
import { Theme } from "react-select/lib/types";
import { theme } from "../stitches.config";

export function reactSelectTheme(baseTheme: Theme) {
  return {
    ...baseTheme,
    colors: {
      ...baseTheme.colors,
      primary: theme.colors.brand.computedValue,
      primary75: theme.colors.brandLight.computedValue,
      primary50: theme.colors.brandLighter.computedValue,
      primary25: theme.colors.brandLightest.computedValue,
    },
  };
}

export const reactSelectStyles: StylesConfig = {
  control: (provided, state) => ({
    ...provided,
    borderWidth: 1,
    fontSize: 16,
    paddingTop: 3,
    paddingBottom: 3,
    lineHeight: "1.5em",
    borderColor: state.isDisabled ? "#ECECEC" : "#D4D4D8",
    backgroundColor: state.isDisabled ? "#ECEFF1" : undefined,
    cursor: state.isDisabled ? "not-allowed" : "unset",
    opacity: state.isDisabled ? "0.6" : 1,
    "&:focus-within": {
      boxShadow: `0 0 0 ${theme.colors.brand}`,
    },
    "&:disabled": {
      borderColor: "#ECECEC",
      color: "#999999",
      cursor: "not-allowed",
      backgroundColor: "#eceff1",
      opacity: "0.6",
    },
  }),
  menuList: (provided, state) => ({
    ...provided,
    fontSize: 16,
    lineHeight: "1.5em",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: 16,
    lineHeight: "1.5em",
    color: "#ccc",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: 16,
    lineHeight: "1.5em",
  }),
  input: (provided, state) => ({
    ...provided,
    fontSize: 16,
    lineHeight: "1.5em",
    paddingTop: 2,
    paddingBottom: 2,
  }),
  container: (provided, state) => ({
    ...provided,
    width: "100%",
  }),
};
