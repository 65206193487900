import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react";
import NewRateSearchForm from "./NewRateSearchForm";
import LoadingIndicator from "../shared/LoadingIndicator";
import RateDetail from "./RateDetail";
import classNames from "classnames";
import ErrorModal from "../../components/modals/ErrorModal";

const Tab = observer((props) => {
  const {
    title,
    subtitle,
    location,
    industry,
    active,
    disabled,
    onClick,
    icon,
    id,
    index,
  } = props;
  return (
    <li
      id={id}
      role="tab"
      aria-labelledby={`tabTitle-${id || index}`}
      className={classNames("rate-search-tab", { active, disabled })}
      onClick={disabled ? null : onClick}
    >
      {disabled && <div className="disabled-mask" />}
      <div className="rate-search-tab-content">
        <div className="icon-container">{icon}</div>
        <div className="label-container">
          <div
            id={`tabTitle-${id || index}`}
            className="title"
            title={title}
            data-toggle="tab"
          >
            {title}
          </div>
          {subtitle && (
            <div className="subtitle" title={subtitle}>
              {subtitle}
            </div>
          )}
          {location && (
            <div className="subtitle" title={location}>
              {location}
            </div>
          )}
          {industry && (
            <div className="subtitle" title={industry}>
              {industry}
            </div>
          )}
        </div>
      </div>
    </li>
  );
});

class RateSearchForm extends Component {
  componentDidMount() {
    this.props.store.rateSearchStore.displayNewForm();
  }

  render() {
    const rateSearchStore = this.props.store.rateSearchStore;
    const scheduledSearchCreateStore = this.props.store.scheduledSearchCreateStore;
    const savedSearchDStore = this.props.store.savedSearchesDetailStore;
    const savedSearchLStore = this.props.store.savedSearchesListStore;
    // HACK: Variable is not unused, needed to force re-render on rateSearchStore.handleCancelRateSearchLabel
    // eslint-disable-next-line no-unused-vars
    const updateview = rateSearchStore.updateview;

    let rateSearchData = JSON.parse(localStorage.getItem("ratesearchdata"));

    return (
      <div className="ratesearch">
        <section className="main-wrap">
          <aside className="main-sidebar equalHW">
            <section className="sidebar">
              <ul className="sidebar-menu" id="sidebarmenu">
                <Tab
                  id="rateSearchTab"
                  title="New Search"
                  subtitle="Find market rates for a job title"
                  active={rateSearchStore.newForm}
                  onClick={() => rateSearchStore.displayNewForm()}
                  icon={<FontAwesomeIcon icon="search" className="tab-icon" />}
                />
                {/*<li*/}
                {/*  className={rateSearchStore.newForm ? "active" : ""}*/}
                {/*  id="rateSearch"*/}
                {/*  onClick={() => rateSearchStore.displayNewForm()}*/}
                {/*>*/}
                {/*  <div className="flex center-items">*/}
                {/*    <button type="button" className="remove-list">*/}
                {/*      <FontAwesomeIcon icon="search" className="alignSearch pt-search-icon" />*/}
                {/*      /!* <i className="alignSearch fa fa-search pt-search-icon" aria-hidden="true" /> *!/*/}
                {/*    </button>*/}
                {/*    <div data-toggle="tab">*/}
                {/*      <div className="ratesearchtext search-result-tab">New Search</div>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</li>*/}
                {!rateSearchStore.networkDetailRateSearch.loading &&
                  Boolean(!rateSearchData || rateSearchData.length === 0) && (
                    <Tab
                      id="noResultsTab"
                      disabled
                      title="Results"
                      subtitle="Search results will appear here"
                      icon={<FontAwesomeIcon icon="info-circle" className="tab-icon" />}
                      onClick={() => rateSearchStore.displayNewForm()}
                    />
                  )}
                {rateSearchData &&
                  rateSearchData.length > 0 &&
                  rateSearchData.map((data, index) => {
                    const { city, state, country, regionName } = data.location;
                    const location = [regionName, city, state, country]
                      .filter((p) => Boolean(p))
                      .join(", ");
                    return (
                      <Tab
                        key={index}
                        index={index}
                        title={`Results for: ${data.jobLabel}`}
                        industry={data.industry.name}
                        location={location}
                        active={rateSearchStore.selectedTab === index + 1}
                        // disabled={rateSearchStore.networkRateDetailSearch.loading}
                        onClick={(e) =>
                          rateSearchStore.apiCallForLocalRateSearch(e, data, index + 1)
                        }
                        icon={
                          <button
                            data-testid="closeSearchTabButton"
                            type="button"
                            className="close-tab-button remove-list"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              rateSearchStore.handleCancelRateSearchLabel(e, index);
                            }}
                          >
                            <FontAwesomeIcon icon="times-circle" className="tab-icon" />
                          </button>
                        }
                      />
                    );
                    // <li key={index}>
                    //   <button
                    //     type="button"
                    //     className="remove-list"
                    //     onClick={() => rateSearchStore.handleCancelRateSearchLabel(index)}
                    //   >
                    //     <FontAwesomeIcon icon="times-circle" />
                    //   </button>
                    //   <a
                    //     href={"#" + index.toString()}
                    //     className="search-result-tab"
                    //     onClick={e => rateSearchStore.apiCallForLocalRateSearch(e, data)}
                    //     data-toggle="tab"
                    //   >
                    //     {jobStore.searchName(data.jobTitle, data.industry.name, data.location.country)}
                    //   </a>
                    // </li>
                  })}
                {rateSearchStore.networkDetailRateSearch.loading && <LoadingIndicator />}
              </ul>
            </section>
          </aside>
          {rateSearchStore.newForm && (
            <NewRateSearchForm
              rateSearchStore={rateSearchStore}
              fetchGraphQL={this.props.fetchGraphQL}
              jobLabelListStore={this.props.store.jobLabelListStore}
              scheduledSearchCreateStore={scheduledSearchCreateStore}
              clientPerSearchPricing={this.props.sessionInfo.client.perSearchPricing}
              clientIsClientJobLibrary={this.props.sessionInfo.client.isClientJobLibrary}
              clientPspStoreFlag={this.props.sessionInfo.client.pspStoreFlag}
            />
          )}
          {rateSearchStore.networkRateDetailSearch.loading && (
            <div className="newForm">
              {" "}
              <LoadingIndicator />{" "}
            </div>
          )}
          {rateSearchStore.rateDetail &&
            rateSearchStore.localRateSearchData.length > 0 && (
              <div className="rateSearchDetail">
                <RateDetail
                  rateSearchStore={rateSearchStore}
                  rateSearchData={rateSearchData}
                  mobxStore={this.props.store}
                  routerLocation={this.props.location}
                  updateview={rateSearchStore.updateview}
                  draftResults={false}
                  rateData={rateSearchStore.localRateSearchData}
                  savedSearchDStore={savedSearchDStore}
                  savedSearchLStore={savedSearchLStore}
                  clientPerSearchPricing={this.props.sessionInfo.client.perSearchPricing}
                  clientIsClientJobLibrary={
                    this.props.sessionInfo.client.isClientJobLibrary
                  }
                  clientIsJobLibrarySubscription={
                    this.props.sessionInfo.client.jobLibrarySubscriptionFlag
                  }
                  clientIsRatecardSubscription={
                    this.props.sessionInfo.client.ratecardSubscriptionFlag
                  }
                  sessionInfo={this.props.sessionInfo}
                />
              </div>
            )}
        </section>
        <ErrorModal
          modalState={rateSearchStore.errorModal}
          errorMessage={rateSearchStore.errorMessage}
        />
      </div>
    );
  }
}

RateSearchForm.propTypes = {
  store: PropTypes.object.isRequired,
};

export default observer(RateSearchForm);
