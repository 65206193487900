// @flow

import R from "ramda";
import { extendObservable, action, runInAction, computed, observable } from "mobx";
import axios from "axios";

import JobLibraryTitleRequest from "../../models/JobLibraryTitleRequest";
import CurrentUser from "../../models/User";
import {
  JobLibraryTitleRequestTitleFilter,
  JobLibraryTitleRequestOnlyStatusFilter,
} from "../../models/FilterState";
import NetworkState from "../../models/NetworkState";
import { CursorPaginationState } from "../../models/PaginationState";
import FilterObject, { FILTER_COLUMN } from "../../models/Filter";
import Sort, { SORT_DIRECTION } from "../../models/Sort";
import ModalState from "../../models/ModalState";
import {
  consolidateAppliedFilters,
  consolidateAppliedSorts,
  createTasteGraphQLWrapper,
} from "./SupportFunctions";
import type { PageQuery, PaginationInfo } from "../../models/PaginationState";
import type { FilterColumn } from "../../models/Filter";
import type { GraphQLQuery } from "../../models/GraphQL";
import SortState from "../../models/SortState";
import { TITLE_REQUEST_SORT_FIELD } from "../../constants/jobLibraryTitleRequest";
import type { FetchAPI, FetchGraphQL } from "../../App";
import SessionInfo from "../../models/SessionInfo";

const statusCriteriaQuery = `
query {
  clientJobTitleRequestsFilterCriteria {
    statusChoices {
      value
      name
      verboseName
    }
  }
}
`;

type StatusCriterion = {
  value: number,
  name: string,
  verboseName: string,
};

export class JobLibraryTitleRequestListComponentStore {
  fetchTasteGraphQL: FetchGraphQL;
  fetchGraphQL: FetchGraphQL;
  fetchAPI: FetchAPI;
  network: NetworkState;
  pagination: CursorPaginationState;
  sessionInfo: ?SessionInfo;

  clientJobTitleRequests: JobLibraryTitleRequest[];
  clientJobTitleRequestsViewState: Object;
  clientJobTitleRequestsView: JobLibraryTitleRequest[];

  currentUser: CurrentUser;
  isEditing: ?boolean;
  allOnPageSelected: boolean;
  allSelected: boolean;
  allowMultipleItemSelection: boolean;
  allowExpand: boolean;

  clientJobTitleRequestTitleFilter: JobLibraryTitleRequestTitleFilter;
  clientJobTitleRequestOnlyStatusFilter: JobLibraryTitleRequestOnlyStatusFilter;

  defaultFilters: { [key: FilterColumn]: FilterObject };
  appliedFilters: { [key: FilterColumn]: FilterObject };
  appliedSorts: { [key: FilterColumn]: Sort };
  appliedSortsOrder: Array<FilterColumn>;
  isFiltered: boolean;
  resultsForText: string;
  totalCount: number;

  getJobLibraryTitleRequests: (PageQuery) => Promise<PaginationInfo>;
  applyDefaultFilter: () => void;
  handleStartEdit: () => void;
  handleStopEdit: () => void;
  toggleSelectAllPage: (SyntheticMouseEvent<HTMLElement>) => void;
  selectAllPage: (SyntheticMouseEvent<HTMLElement>) => void;
  deselectAllPage: (SyntheticMouseEvent<HTMLElement>) => void;
  toggleAllItems: () => void;
  clearAllSelections: () => void;
  getSelectedJobLibraryTitleRequests: () => Array<string>;
  getFirstSelectedJobLibraryTitleRequest: () => Object;

  getFilterCriteriaQuery: (column: FilterColumn) => GraphQLQuery;
  processFilterCriteria: (column: FilterColumn, Object) => Array<Object>;
  applyFilter: (FilterColumn, FilterObject) => void;
  removeFilter: (column: FilterColumn) => void;
  applySort: (FilterColumn, Sort) => void;
  removeSort: (column: FilterColumn) => void;
  filterJobLibraryTitleRequests: () => void;
  clearFilters: () => void;
  applyDefaultSort: () => void;
  onSortByField: (?string, ?boolean) => void;
  sortState: SortState;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI) {
    this.fetchTasteGraphQL = createTasteGraphQLWrapper(fetchAPI);
    this.fetchGraphQL = fetchGraphQL;
    this.fetchAPI = fetchAPI;

    // NOTE: Bound early to pass into pagination & filter state
    this.getJobLibraryTitleRequests = action(this.getJobLibraryTitleRequests.bind(this));

    this.getFilterCriteriaQuery = action(this.getFilterCriteriaQuery.bind(this));
    this.processFilterCriteria = action(this.processFilterCriteria.bind(this));
    this.applyFilter = action(this.applyFilter.bind(this));
    this.applySort = action(this.applySort.bind(this));
    this.removeFilter = action(this.removeFilter.bind(this));
    this.removeSort = action(this.removeSort.bind(this));
    this.onSortByField = action(this.onSortByField.bind(this));

    extendObservable(this, {
      network: new NetworkState(),
      pagination: new CursorPaginationState(this.getJobLibraryTitleRequests),
      clientJobTitleRequests: [],
      clientJobTitleRequestsViewState: observable.map({}),
      clientJobTitleRequestsView: computed(() => {
        return this.clientJobTitleRequests.map((clientJobTitleRequest) => {
          if (this.clientJobTitleRequestsViewState.has(clientJobTitleRequest.id)) {
            clientJobTitleRequest.viewState = this.clientJobTitleRequestsViewState.get(
              clientJobTitleRequest.id
            );

            return clientJobTitleRequest;
          }

          return clientJobTitleRequest;
        });
      }),
      totalCount: 0,
      resultsForText: "",
      isEditing: null, // we start with null so some view elements be hidden initially
      isFiltered: false,
      allSelected: false,
      allowMultipleItemSelection: true,
      allowExpand: true,
      selectedCount: computed(() => {
        if (this.allSelected) {
          return this.pagination.totalCount;
        }

        const selectedValues = this.clientJobTitleRequestsView.filter(
          (clientJobTitleRequest) => {
            return (
              clientJobTitleRequest.viewState && clientJobTitleRequest.viewState.selected
            );
          }
        );

        return selectedValues.length;
      }),
      allOnPageSelected: computed(() => {
        const allTrue = R.all(R.equals(true));
        const selectedValues = this.clientJobTitleRequestsView.map(
          (clientJobTitleRequest) =>
            clientJobTitleRequest.viewState && clientJobTitleRequest.viewState.selected
        );

        if (selectedValues.length === 0) {
          return false;
        }

        return allTrue(selectedValues);
      }),
      sortState: new SortState(TITLE_REQUEST_SORT_FIELD.CREATED, SORT_DIRECTION.DESC),
      defaultFilters: {},
      appliedFilters: {},
      appliedSorts: {},
      appliedSortsOrder: observable.shallow([]),
      clientJobTitleRequestTitleFilter: new JobLibraryTitleRequestTitleFilter(
        this,
        FILTER_COLUMN.CLIENT_JOB_TITLE_REQUEST_TITLE,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      clientJobTitleRequestOnlyStatusFilter: new JobLibraryTitleRequestOnlyStatusFilter(
        this,
        FILTER_COLUMN.CLIENT_JOB_TITLE_REQUEST_ONLY_STATUS,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.removeFilter
      ),
    });

    this.currentUser = null;

    this.handleStartEdit = action(this.handleStartEdit.bind(this));
    this.handleStopEdit = action(this.handleStopEdit.bind(this));
    this.toggleSelectAllPage = action(this.toggleSelectAllPage.bind(this));
    this.selectAllPage = action(this.selectAllPage.bind(this));
    this.deselectAllPage = action(this.deselectAllPage.bind(this));
    this.toggleAllItems = action(this.toggleAllItems.bind(this));
    this.clearAllSelections = action(this.clearAllSelections.bind(this));
    this.getSelectedJobLibraryTitleRequests = action(
      this.getSelectedJobLibraryTitleRequests.bind(this)
    );
    this.getFirstSelectedJobLibraryTitleRequest = action(
      this.getFirstSelectedJobLibraryTitleRequest.bind(this)
    );

    this.filterJobLibraryTitleRequests = action(
      this.filterJobLibraryTitleRequests.bind(this)
    );
    this.clearFilters = action(this.clearFilters.bind(this));
    this.applyDefaultSort = action(this.applyDefaultSort.bind(this));
    this.applyDefaultFilter = action(this.applyDefaultFilter.bind(this));

    this.applyDefaultSort();
    this.applyDefaultFilter();
  }

  onSortByField(field?: string, caseSensitive: boolean = false) {
    const defaultField = TITLE_REQUEST_SORT_FIELD.CREATED;
    const defaultDirection = SORT_DIRECTION.DESC;

    // reset sort if no field is passed
    if (!field) {
      // remove sorts
      this.appliedSorts = observable({});
      this.appliedSortsOrder.length = 0;

      // remove sort state
      this.sortState = new SortState(defaultField, defaultDirection);
      caseSensitive = true; // this is required when sorting by date
    } else {
      // remove previous sort
      if (this.sortState) this.removeSort(this.sortState.field);

      // cycle field sort: asc -> desc -> default

      // new sort, sort asc
      if (!this.sortState || this.sortState.field !== field) {
        // create sort state (default SORT_DIRECTION.ASC)
        this.sortState = new SortState(field, SORT_DIRECTION.ASC);
      } else if (
        !this.sortState.direction ||
        this.sortState.direction === SORT_DIRECTION.ASC
      ) {
        // sort desc
        this.sortState.setSort(SORT_DIRECTION.DESC);
      } else if (this.sortState.direction === SORT_DIRECTION.DESC) {
        // reset sort
        // remove sort state
        if (field === defaultField && defaultDirection === SORT_DIRECTION.DESC)
          this.sortState = new SortState(defaultField, SORT_DIRECTION.ASC);
        else this.sortState = new SortState(defaultField, defaultDirection);
        caseSensitive = true; // this is required when sorting by date
      }
    }

    // apply sort
    const sortField = this.sortState.field;
    const sortDirection = this.sortState.direction || SORT_DIRECTION.ASC;
    const sortSensitive = caseSensitive ? "true" : "false";
    const sort = new Sort(
      "{field: " +
        sortField +
        ", direction: " +
        sortDirection +
        ", caseSensitive: " +
        sortSensitive +
        "}"
    );
    this.applySort(this.sortState.field, sort);

    // fetch
    this.pagination.goFetch();
  }

  applyDefaultSort() {
    this.sortState = new SortState(TITLE_REQUEST_SORT_FIELD.CREATED, SORT_DIRECTION.DESC);
    const caseSensitive = true; // this is required when sorting by date
    const sortField = this.sortState.field;
    const sortDirection = this.sortState.direction || SORT_DIRECTION.ASC;
    const sortSensitive = caseSensitive ? "true" : "false";
    const sort = new Sort(
      "{field: " +
        sortField +
        ", direction: " +
        sortDirection +
        ", caseSensitive: " +
        sortSensitive +
        "}"
    );
    this.applySort(this.sortState.field, sort);

    // if (this.clientJobTitleRequestTitleFilter.sortState)
    //   this.clientJobTitleRequestTitleFilter.sortState.direction = SORT_DIRECTION.ASC;
    //
    // this.clientJobTitleRequestTitleFilter.sort = this.clientJobTitleRequestTitleFilter.buildQuerySort();
    //
    // if (this.clientJobTitleRequestTitleFilter.sort)
    //   this.applySort(this.clientJobTitleRequestTitleFilter.column, this.clientJobTitleRequestTitleFilter.sort);
  }

  applyDefaultFilter() {
    const initialSelectedStatus = [0, 1, 2, 3]; // PROCESSING, PENDING
    this.clientJobTitleRequestOnlyStatusFilter.filter =
      this.clientJobTitleRequestOnlyStatusFilter.createFilterObject(
        initialSelectedStatus
      );
    this.applyFilter(
      this.clientJobTitleRequestOnlyStatusFilter.column,
      this.clientJobTitleRequestOnlyStatusFilter.filter
    );
  }

  handleStartEdit() {
    this.isEditing = true;
    this.allowExpand = false;
    this.clientJobTitleRequestsView.forEach((clientJobTitleRequest) =>
      clientJobTitleRequest.toggleEdit()
    );
  }

  handleStopEdit() {
    this.isEditing = false;
    this.allSelected = false;
    this.allowExpand = true;
    this.clientJobTitleRequestsViewState.forEach((viewState) => {
      viewState.selected = false;
      viewState.editing = false;
    });
  }

  toggleSelectAllPage(e: SyntheticMouseEvent<HTMLElement>) {
    if (!this.allowMultipleItemSelection) return;

    const setValue = !this.allOnPageSelected;

    this.clientJobTitleRequestsView.forEach((clientJobTitleRequest) => {
      clientJobTitleRequest.toggleSelected(e, null, null, setValue);
    });

    // When All items selected flag is up, clear selection
    if (setValue === false && this.allSelected) this.allSelected = false;
  }

  selectAllPage(e: SyntheticMouseEvent<HTMLElement>) {
    this.clientJobTitleRequestsView.forEach((clientJobTitleRequest) => {
      clientJobTitleRequest.toggleSelected(e, null, null, true);
    });
  }

  deselectAllPage(e: SyntheticMouseEvent<HTMLElement>) {
    this.clientJobTitleRequestsView.forEach((clientJobTitleRequest) => {
      clientJobTitleRequest.toggleSelected(e, null, null, false);
    });

    this.allSelected = false;
  }

  toggleAllItems() {
    if (!this.allowMultipleItemSelection) return;

    this.allSelected = !this.allSelected;

    if (this.allSelected === false) {
      this.clientJobTitleRequestsViewState.forEach((value) => {
        value.selected = false;
      });
    }
  }

  clearAllSelections() {
    this.allSelected = false;
    this.clientJobTitleRequestsViewState.forEach((value) => {
      value.selected = false;
    });
  }

  getFilterCriteriaQuery(column: FilterColumn): GraphQLQuery {
    switch (column) {
      case FILTER_COLUMN.CLIENT_JOB_TITLE_REQUEST_ONLY_STATUS:
        return {
          query: statusCriteriaQuery,
          variables: {},
        };

      default:
        return null;
    }
  }

  processFilterCriteria(column: FilterColumn, payload: Object): ?Array<Object> {
    switch (column) {
      case FILTER_COLUMN.CLIENT_JOB_TITLE_REQUEST_ONLY_STATUS:
        const statusChoices: [StatusCriterion] =
          payload.data.clientJobTitleRequestsFilterCriteria.statusChoices;
        let processedStatusChoices = observable.map({});
        statusChoices.forEach((status) => {
          processedStatusChoices.set(String(status.value), {
            id: String(status.value),
            ...status,
          });
        });

        return processedStatusChoices;

      default:
        return null;
    }
  }

  applyFilter(column: FilterColumn, filter: FilterObject) {
    this.appliedFilters[column] = filter;
    this.isFiltered = true;
  }

  removeFilter(column: FilterColumn) {
    delete this.appliedFilters[column];

    let entries = Object.entries(this.appliedFilters);
    if (!entries.length) this.isFiltered = false;
  }

  applySort(column: FilterColumn, sort: Sort) {
    this.appliedSorts[column] = sort;

    const index = this.appliedSortsOrder.indexOf(column);
    if (index === -1) this.appliedSortsOrder.push(column);
  }

  removeSort(column: FilterColumn) {
    delete this.appliedSorts[column];

    const index = this.appliedSortsOrder.indexOf(column);
    if (index > -1) this.appliedSortsOrder.splice(index, 1);
  }

  clearFilters() {
    this.clientJobTitleRequestTitleFilter = new JobLibraryTitleRequestTitleFilter(
      this,
      FILTER_COLUMN.CLIENT_JOB_TITLE_REQUEST_TITLE,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );

    this.clientJobTitleRequestOnlyStatusFilter =
      new JobLibraryTitleRequestOnlyStatusFilter(
        this,
        FILTER_COLUMN.CLIENT_JOB_TITLE_REQUEST_ONLY_STATUS,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.removeFilter
      );

    this.appliedFilters = observable({});
    this.appliedSorts = observable({});
    this.appliedSortsOrder.length = 0;
    this.isFiltered = false;

    return this.pagination.goFetch();
  }

  filterJobLibraryTitleRequests() {
    return this.pagination.goFetch();
  }

  getSelectedJobLibraryTitleRequests(): Array<string> {
    const clientJobTitleRequests = this.clientJobTitleRequestsViewState;

    let selectedJobLibraryTitleRequests = [];

    clientJobTitleRequests.forEach((value, key) => {
      if (value.selected) {
        selectedJobLibraryTitleRequests.push(key);
      }
    });

    return selectedJobLibraryTitleRequests;
  }

  getFirstSelectedJobLibraryTitleRequest() {
    for (let j = 0; j < this.clientJobTitleRequestsView.length; j++) {
      const viewState = this.clientJobTitleRequests[j].viewState;
      if (viewState && viewState.selected) return this.clientJobTitleRequests[j];
    }

    return null;
  }

  async getJobLibraryTitleRequests(pageQuery: PageQuery): Promise<?PaginationInfo> {
    let params: string[] = pageQuery.params;
    let args = pageQuery.args;
    let variables = pageQuery.variables;
    let filtersCriteria: string[] = [];
    let query = {};

    let sortCriteria: string[] = [];
    consolidateAppliedSorts(this.appliedSorts, sortCriteria);

    consolidateAppliedFilters(this.appliedFilters, params, filtersCriteria, variables);

    // NOTE: applied filters could override default filters, handle this if needed
    consolidateAppliedFilters(this.defaultFilters, params, filtersCriteria, variables);

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");
    const queryFiltersCriteria = filtersCriteria.join(", ");
    const querySortCriteria = sortCriteria.join(", ");

    query.query = `
      query getJobLibraryTitleRequests(${queryParams}) {
        clientJobTitleRequests(${queryArgs}, filters: { ${queryFiltersCriteria} }, order: [${querySortCriteria}]) {
          totalCount
          pageInfo {
            startCursor
            endCursor
          }
          edges {
            node {
              id
              databaseId
              title
              status
              statusVerbose
              created
              modified
              totalCertifiedCountries
            }
          }
        }
        
        allRequests: clientJobTitleRequests(first: 0) {
          totalCount
        }
      }
    `;
    query.variables = variables;

    if (this.network.loading === true) {
      // console.log('Canceling ClientJobTiles list request');
      return null;
    }

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchTasteGraphQL(query.query, query.variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        // console.log('Request Canceled JobLibraryTitleRequestListStore');
        return e;
      }

      this.network.handleError("Getting Client Job Title Requests", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Client Job Title Requests query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getJobLibraryTitleRequests--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get Client Job Title Requests query", res)) {
        // TODO: Display user friendly error message
        return { totalCount: 0, startCursor: "", endCursor: "" };
      }

      if (!res) return { totalCount: 0, startCursor: "", endCursor: "" };

      // this.currentUser = new CurrentUser(this, res.data.viewer.user);

      const requests = res.data.clientJobTitleRequests.edges;

      // TODO: Deserialize this properly...
      this.clientJobTitleRequests = requests.map((request) => {
        const clientJobTitleRequest = new JobLibraryTitleRequest(this, request.node);

        if (!this.clientJobTitleRequestsViewState.has(clientJobTitleRequest.id)) {
          this.clientJobTitleRequestsViewState.set(clientJobTitleRequest.id, {
            selected: this.allSelected,
            editing: this.isEditing,
            expanded: false,
          });
        } else {
          const selectedValue = this.allSelected
            ? true
            : this.clientJobTitleRequestsViewState.get(clientJobTitleRequest.id).selected;

          this.clientJobTitleRequestsViewState.set(clientJobTitleRequest.id, {
            selected: selectedValue,
            editing: this.isEditing,
            expanded: false,
          });
        }

        clientJobTitleRequest.viewState = this.clientJobTitleRequestsViewState.get(
          clientJobTitleRequest.id
        );

        return clientJobTitleRequest;
      });

      if (this.isFiltered && this.clientJobTitleRequestTitleFilter.textToLookFor) {
        this.resultsForText = this.clientJobTitleRequestTitleFilter.textToLookFor;
      } else {
        this.resultsForText = "";
      }

      if (res.data.allRequests) {
        this.totalCount = res.data.allRequests.totalCount;
      }

      return {
        totalCount: res.data.clientJobTitleRequests.totalCount,
        startCursor: res.data.clientJobTitleRequests.pageInfo.startCursor,
        endCursor: res.data.clientJobTitleRequests.pageInfo.endCursor,
      };
    });
  }
}

export default class JobLibraryTitleRequestListStore extends JobLibraryTitleRequestListComponentStore {
  showHelpModal: boolean;

  helpModal: ModalState;
  confirmDeleteModal: ModalState;

  showHelp: () => void;
  hideHelp: () => void;
  viewInMap: () => void;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI) {
    super(fetchGraphQL, fetchAPI);

    extendObservable(this, {
      newJobLibraryTitleRequestName: "",
      helpModal: new ModalState(),
      confirmUpdateRatesModal: new ModalState(),
      confirmUndoUpdateRatesModal: new ModalState(),
      confirmDeleteModal: new ModalState(),
    });

    this.showHelp = action(this.showHelp.bind(this));
    this.hideHelp = action(this.hideHelp.bind(this));

    this.viewInMap = action(this.viewInMap.bind(this));
  }

  showHelp() {
    this.showHelpModal = true;
  }

  hideHelp() {
    this.showHelpModal = false;
  }

  viewInMap() {
    const selectedJobLibraryTitleRequests = this.getSelectedJobLibraryTitleRequests();

    if (selectedJobLibraryTitleRequests.length === 0) {
      return;
    }

    if (!this.sessionInfo || !this.sessionInfo.user) return;

    const sessionId = this.sessionInfo.legacySession || "";
    const userId = this.sessionInfo.user.userId;
    const params = `?s=${sessionId}&u=${userId}&RCID=${selectedJobLibraryTitleRequests[0]}&SEARCH_ID=undefined`;
    const mapURL = "https://qa.peopleticker.com/pages/rate_cards.html";

    window.location = mapURL + params;
  }
}
