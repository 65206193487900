import React from "react";

import Stack from "../../../../components/lib/Stack";
import Text from "../../../../components/lib/Text";
import Icon from "../../../../components/lib/Icon";
import { THead, TBody, TR, TH, TD } from "../../../../components/lib/Table";
import { TableInfo } from "./RatesBase";
import {
  RATE_FEEDBACK_STATUS_TYPES,
  SKILLS_LEVELS,
  SKILLS_LEVELS_3L,
  SKILLS_LEVELS_3L_TYPE,
  SKILLS_LEVELS_TYPE,
} from "../../types";

export const checkCurrencyConversionIsRequired = (
  currencyDataCode: string,
  displayCurrencyDataCode: string
) => {
  return !!(
    currencyDataCode &&
    displayCurrencyDataCode &&
    currencyDataCode !== displayCurrencyDataCode
  );
};

// export const checkRateTypeConversionIsRequired = (
//   displayRateType: RATE_TYPES_TYPE,
//   displayRateMultiplier: number,
// ) => {
//   // TODO
// };

export const classicSkillsLevelsInfoText = (
  <Stack css={{ alignItems: "flex-start" }}>
    <Text>
      There is often a correlation between levels and years of experience as follows.
    </Text>
    <TableInfo highlighted={false}>
      <THead>
        <TR>
          <TH>Level</TH>
          <TH>May correlate with</TH>
        </TR>
      </THead>
      <TBody>
        <TR>
          <TD>1</TD>
          <TD>1 - 3 years</TD>
        </TR>
        <TR>
          <TD>2</TD>
          <TD>3 - 5 years</TD>
        </TR>
        <TR>
          <TD>3</TD>
          <TD>5 - 7 years</TD>
        </TR>
        <TR>
          <TD>4</TD>
          <TD>7 – 10 years</TD>
        </TR>
        <TR>
          <TD>5</TD>
          <TD>Top Earners (10+)</TD>
        </TR>
      </TBody>
    </TableInfo>
  </Stack>
);

export const threeLevelBandingSkillsLevelsInfoText = (
  <Stack css={{ alignItems: "flex-start" }}>
    <Text>
      There is often a correlation between levels and years of experience as follows.
    </Text>
    <TableInfo highlighted={false}>
      <THead>
        <TR>
          <TH>Level</TH>
          <TH>May correlate with</TH>
        </TR>
      </THead>
      <TBody>
        <TR>
          <TD>1</TD>
          <TD>1 - 5 years</TD>
        </TR>
        <TR>
          <TD>2</TD>
          <TD>5 - 10 years</TD>
        </TR>
        <TR>
          <TD>3</TD>
          <TD>10+ years</TD>
        </TR>
      </TBody>
    </TableInfo>
  </Stack>
);

export const statusIconItems = {
  [RATE_FEEDBACK_STATUS_TYPES.NOT_VALIDATED]: (
    <Text css={{ fontSize: "$6xl", color: "$primaryLighter" }}>
      <Icon icon="exclamation" />
    </Text>
  ),
  [RATE_FEEDBACK_STATUS_TYPES.APPROVED]: (
    <Text css={{ fontSize: "$6xl", color: "$successLighter" }}>
      <Icon icon="check" />
    </Text>
  ),
  [RATE_FEEDBACK_STATUS_TYPES.DENIED]: (
    <Text css={{ fontSize: "$6xl", color: "$dangerLighter" }}>
      <Icon icon="times" />
    </Text>
  ),
  [RATE_FEEDBACK_STATUS_TYPES.DONT_KNOW]: (
    <Text css={{ fontSize: "$6xl", color: "$warningLighter" }}>
      <Icon icon="question" />
    </Text>
  ),
};

export function getExpLevelInfo(
  expLevel: SKILLS_LEVELS_TYPE | SKILLS_LEVELS_3L_TYPE,
  isThreeLevelBanding: boolean = false
) {
  const expText5Levels = {
    [SKILLS_LEVELS.JUNIOR]: "(1 - 3 years of experience)",
    [SKILLS_LEVELS.INTERMEDIATE]: "(3 - 5 years of experience)",
    [SKILLS_LEVELS.SENIOR]: "(5 - 7 years of experience)",
    [SKILLS_LEVELS.LEAD]: "(7 - 10 years of experience)",
    [SKILLS_LEVELS.GURU]: "(10+ years of experience)",
  };
  const expText3Levels = {
    [SKILLS_LEVELS_3L.LOW]: "(1 - 5 years of experience)",
    [SKILLS_LEVELS_3L.MEDIUM]: "(5 - 10 years of experience)",
    [SKILLS_LEVELS_3L.HIGH]: "(10+ years of experience)",
  };

  return isThreeLevelBanding
    ? expText3Levels[expLevel as SKILLS_LEVELS_3L_TYPE]
    : expText5Levels[expLevel as SKILLS_LEVELS_TYPE];
}
