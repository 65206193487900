// @flow
import { extendObservable, action } from "mobx";
import ModalState from "./ModalState";

class ConvertCurrencyState {
  store: Object;
  modal: ModalState;

  fetch: () => void;
  onApply: () => void;

  applied: boolean;
  convertCurrencyToUSD: boolean;

  constructor(store: Object) {
    this.store = store;
    this.modal = new ModalState();

    extendObservable(this, {
      applied: false,
      convertCurrencyToUSD: false,
    });

    this.fetch = action(this.fetch.bind(this));
    this.onApply = action(this.onApply.bind(this));
  }

  fetch() {
    this.store.pagination.goFetch(false, false, null);
  }

  onApply() {
    this.applied = this.convertCurrencyToUSD;
    this.modal.hideModal();
    this.fetch();
  }
}

export default ConvertCurrencyState;
