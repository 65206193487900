// @flow
import * as React from "react";
import { observer } from "mobx-react";
import RateCardListings from "./components/RateCardListings";
import ReactDOM from "react-dom";
import type MobXStore from "../../stores/mobx/MobXStore";
import { Card, CardHeaderTitle, CardHeader } from "../../components/lib/Card";
import Container from "../../components/lib/Container";
import Box from "../../components/lib/Box";
import TextInput from "../../components/lib/TextInput";
import { ModalButtons } from "../ratecards/components/FilterModal";
import LinkButton from "../../components/lib/LinkButton";
import Button from "../../components/lib/Button";

type Props = {
  store: MobXStore,
};

class Create extends React.Component<Props, null> {
  componentDidMount() {
    this.props.store.projectCostEstimateCreateStore.rateCardsStore.pagination.goFetch();
    this.props.store.projectCostEstimateCreateStore.pcLabel = "";
    this.props.store.projectCostEstimateCreateStore.punchoutArray = [];
    this.props.store.projectCostEstimateStore.handlePunchoutEditStop();
    if (this.props.store.projectCostEstimateStore.selectedPunchouts.length > 0) {
      this.props.store.projectCostEstimateStore.selectedPunchouts.length = 0;
    }
    if (this.props.store.projectCostEstimateCreateStore.messaging.messages.length > 0) {
      this.props.store.projectCostEstimateCreateStore.messaging.messages.length = 0;
      this.props.store.projectCostEstimateCreateStore.messaging.removeMessage.bind(
        this.props.store.projectCostEstimateCreateStore,
        this.props.store.projectCostEstimateCreateStore.messaging.messages[0]
      );
    }
    this.props.store.rateCardListStore.clearFilters();
  }

  componentDidUpdate() {
    if (this.props.store.projectCostEstimateCreateStore.messaging.messages.length > 0) {
      ReactDOM.findDOMNode(this.refs.pcLable).focus();
    }
  }

  render() {
    const rcstore = this.props.store.rateCardListStore;
    const pcestore = this.props.store.projectCostEstimateStore;
    const store = this.props.store.projectCostEstimateCreateStore;

    return (
      <Container
        css={{
          paddingTop: "$4",
          paddingLeft: 0,
          paddingRight: 0,
          width: "100%",
        }}
      >
        <Card>
          <CardHeader>
            <CardHeaderTitle>Create a new estimate</CardHeaderTitle>
          </CardHeader>
          <Box css={{ padding: "15px 20px" }}>
            <label htmlFor="pcLabel" className="block">
              Estimate name:
            </label>
            <TextInput
              id="buyRatesLabel"
              type="text"
              maxLength="250"
              css={{
                width: "300px",
              }}
              value={store.pcLabel}
              onChange={store.changePCLabelText}
              ref="pcLable"
            />
          </Box>
          <Box className="pt-user-messages" css={{ padding: "0 20px" }}>
            {store.messaging.messages.length > 0 && (
              <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                <a
                  href="#close"
                  className="close"
                  data-dismiss="alert"
                  aria-label="close"
                  onClick={store.messaging.removeMessage.bind(
                    store,
                    store.messaging.messages[0]
                  )}
                >
                  &times;
                </a>
                <strong>{store.messaging.messages[0].messageLabel}</strong>
                {store.messaging.messages[0].message}
              </div>
            )}
          </Box>

          <RateCardListings
            store={rcstore}
            pcestore={pcestore}
            createstore={store}
            requestFrom="pce"
            showHeader={true}
          />

          <ModalButtons
            css={{
              padding: "$5",
              backgroundColor: "$primaryLighter",
              borderBottomRightRadius: "$rounded",
              borderBottomLeftRadius: "$rounded",
            }}
          >
            <LinkButton size="large" to="/project-cost-estimate">
              Cancel
            </LinkButton>
            <Button
              size="large"
              color="brand"
              onClick={(e) =>
                store.createProjectCostEstimate(pcestore.punchoutArrayView, pcestore)
              }
            >
              Create Estimate
            </Button>
          </ModalButtons>
        </Card>
      </Container>
    );
  }
}

export default observer(Create);
