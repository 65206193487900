// @flow
import React from "react";
import { observer } from "mobx-react";
import type MobXStore from "../../stores/mobx/MobXStore";
import SessionInfo from "../../models/SessionInfo";
import PSPDetail from "./per_search_pricing/Detail";
import JLDetail from "./job_library/Detail";

type Props = {
  store: MobXStore,
  sessionInfo: SessionInfo,
  router: Object,
};

const Detail = (props: Props) => {
  const sessionInfo = props.sessionInfo;
  const client = sessionInfo.client;
  const isJobLibraryClient = client && client.isClientJobLibrary;

  if (isJobLibraryClient) {
    return (
      <JLDetail
        store={props.store}
        sessionInfo={props.sessionInfo}
        router={props.router}
      />
    );
  } else {
    return (
      <PSPDetail
        store={props.store}
        sessionInfo={props.sessionInfo}
        router={props.router}
      />
    );
  }
};

export default observer(Detail);
