// @flow
import * as React from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RegionList from "./components/RegionList";
import SearchBox from "../../ratecards/components/SearchBox";
import Modal from "../../ratecards/components/Modal";
import classNames from "classnames";

const List = (props) => {
  const store = props.store.regionListPTStore;

  return (
    <div className="view list clients">
      <div className="rc-container bring-forward">
        <div className="header-action-bar">
          <h2>Regions Admin</h2>
          <p className="subtext" />
          <div className="clearfix" />
        </div>
        <div className="header-action-bar marginTopzero">
          <div className="pull-left">
            <SearchBox
              value={store.regionFilter.textToLookFor}
              onChange={store.regionFilter.onTextToLookForChange}
              onSearch={store.regionFilter.onApply}
              style={{
                width: "260px",
              }}
            />
          </div>
          <div className="btn-group pull-right rc-center">
            {store.isEditing && !store.isTagView && (
              <button className="btn btn-yellow" onClick={store.handleStopEdit}>
                Stop Editing{" "}
              </button>
            )}
            {!store.isEditing && !store.isTagView && (
              <div>
                <button className="btn btn-green" onClick={store.handleStartEdit}>
                  <FontAwesomeIcon icon="edit" fixedWidth className="icon" />
                  Edit
                </button>
                <button className="btn" onClick={store.helpModal.showModal}>
                  <FontAwesomeIcon icon="question-circle" fixedWidth className="icon" />
                  Help
                </button>
              </div>
            )}
          </div>
          <div className="clearfix" />
        </div>
        <RegionList store={store} />
      </div>

      {!store.isTagView && (
        <div
          className={classNames("ratecards-edit-actions", {
            hidden: store.isEditing === null,
            "bottom-appear": store.isEditing === true,
            "bottom-disappear": store.isEditing === false,
          })}
        >
          <button
            className="btn"
            disabled={store.selectedCount === 0}
            onClick={store.exportRegions}
          >
            <FontAwesomeIcon icon="download" fixedWidth className="icon" /> Export
          </button>
        </div>
      )}
      {!store.isTagView && (
        <div
          className={classNames("rc-container-backdrop", {
            hidden: store.isEditing === null,
            "fade-in": store.isEditing === true,
            "fade-out": store.isEditing === false,
          })}
        />
      )}

      <Modal show={store.helpModal.show} onHide={store.helpModal.hideModal}>
        <div className="container-section header">
          <h4>Help</h4>
        </div>
        <div className="container-section footer">
          <p>Here you can see all of your regions...</p>
        </div>
      </Modal>
    </div>
  );
};

export default observer(List);
