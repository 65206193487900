import React from "react";

import Text from "../../../../components/lib/Text";
import { getRegionString, getLocationString } from "../../constants";
import { REVIEW_STATUS_TYPES, RATE_TYPES } from "../../types";
import { emptyMap, emptyOrderedMap } from "../../../../constants";

import type {
  JobDataMap,
  JobsDataOrderedMap,
  AttemptDataMap,
  REVIEW_STATUS_TYPES_TYPE,
  ReviewDataMap,
  RATE_TYPES_TYPE,
} from "../../types";

export function getJobRegion(jobData: JobDataMap): string {
  const jobLocation = getLocationString(jobData);
  const isRowRegionBased = !!jobData.get("region");

  let jobRegion = getRegionString(jobData);
  if (isRowRegionBased && jobRegion && !jobRegion.toLowerCase().includes("region")) {
    jobRegion += " (region)";
  }

  return isRowRegionBased ? jobRegion : jobLocation;
}

export function getAttemptStatus(
  attemptData: AttemptDataMap
): [REVIEW_STATUS_TYPES_TYPE | null, JSX.Element] {
  const attemptStatus = attemptData.get("review_status");
  const attemptStatusString =
    attemptStatus === REVIEW_STATUS_TYPES.REVIEWED ? (
      <Text color="positive">completed</Text>
    ) : attemptStatus === REVIEW_STATUS_TYPES.SKIPPED ? (
      <Text css={{ color: "$warning" }}>skipped</Text>
    ) : attemptStatus === REVIEW_STATUS_TYPES.NOT_REVIEWED ? (
      <Text>in progress</Text>
    ) : (
      <Text>not started</Text>
    );

  return [attemptStatus, attemptStatusString];
}

export function getFirstJobData(reviewData?: ReviewDataMap): JobDataMap {
  if (!reviewData) {
    return emptyMap as unknown as JobDataMap;
  }

  return (
    reviewData.get("jobs", emptyOrderedMap as unknown as JobsDataOrderedMap).first() ||
    emptyMap
  );
}

export function getUnitsString(rateType: RATE_TYPES_TYPE) {
  return rateType === RATE_TYPES.ANNUAL
    ? "year"
    : rateType === RATE_TYPES.MONTHLY
    ? "month"
    : rateType === RATE_TYPES.WEEKLY
    ? "week"
    : rateType === RATE_TYPES.DAILY
    ? "day"
    : rateType === RATE_TYPES.HOURLY
    ? "hour"
    : "";
}

export function getJobRateTypeParameters(
  jobData: JobDataMap,
  rateType: RATE_TYPES_TYPE | null = null
) {
  let rateMultiplier = 1;

  if (rateType == null) {
    rateType = jobData.get("rate_type", RATE_TYPES.HOURLY);
  }

  if (rateType === RATE_TYPES.HOURLY) {
    rateMultiplier = jobData.get("hourly_multiplier");
  } else if (rateType === RATE_TYPES.DAILY) {
    rateMultiplier = jobData.get("daily_multiplier");
  } else if (rateType === RATE_TYPES.WEEKLY) {
    rateMultiplier = jobData.get("weekly_multiplier");
  } else if (rateType === RATE_TYPES.MONTHLY) {
    rateMultiplier = jobData.get("monthly_multiplier");
  }

  return { rateType, rateMultiplier };
}
