import React from "react";

import ImageInput from "../../../components/lib/ImageInput";
import Stack from "../../../components/lib/Stack";
import TextInput from "../../../components/lib/TextInput";
import TextArea from "../../../components/lib/TextArea";
import Inline from "../../../components/lib/Inline";
import Icon from "../../../components/lib/Icon";
import { RadioGroup, RadioGroupItem } from "../../../components/lib/RadioGroup";
import { styled } from "../../../stitches.config";
import { ShowModalFunc } from "../../../components/modals/MessageModal";
import {
  PROGRAM_STATUSES,
  PROGRAM_STATUSES_LABELS,
  PROGRAM_STATUSES_TYPE,
  Values,
} from "../types";

export const EDITOR_MODES = {
  CREATE: "create",
  UPDATE: "update",
} as const;

export type EDITOR_MODES_TYPE = Values<typeof EDITOR_MODES>;

const StepID = styled("span", {
  fontSize: "$4xl",
  fontWeight: "$semibold",
  color: "$brandLight",
  padding: "0 $4 !important",
});

const StepContainer = styled(Stack, {
  alignItems: "flex-start",
  gap: "$1",
});

export type FormFieldKey = "title" | "description" | "icon" | "status";

type ProgramEditorFormPropTypes = {
  programId: number;
  mode: EDITOR_MODES_TYPE;
  invalid: boolean;
  title: string;
  description: string;
  icon: string | File;
  status: PROGRAM_STATUSES_TYPE;
  onChange: (
    key: FormFieldKey,
    value: "" | string | PROGRAM_STATUSES_TYPE | File
  ) => void;
  showModalError: ShowModalFunc;
};

const ProgramEditorForm = (props: ProgramEditorFormPropTypes) => {
  const { title, description, icon, status, mode, invalid, onChange, showModalError } =
    props;

  // handlers
  const handleChangeTitle = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => onChange("title", event.target.value),
    [onChange]
  );
  const handleChangeDescription = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) =>
      onChange("description", event.target.value),
    [onChange]
  );

  const handleChangeIcon = React.useCallback(
    (file: "" | string | File) => onChange("icon", file),
    [onChange]
  );
  const handleChangeStatus = React.useCallback(
    (value: PROGRAM_STATUSES_TYPE) => onChange("status", value),
    [onChange]
  );

  const handleFileApiIsNotSupported = React.useCallback(
    () =>
      showModalError(
        <span>
          <strong>File API</strong> is not supported by your browser.
          <br />
          Use modern browser to be able to upload file via this form.
        </span>
      ),
    [showModalError]
  );
  const handleFileTypeIsNotSupported = React.useCallback(
    () => showModalError("Provided file type is not supported."),
    [showModalError]
  );

  return (
    <Stack fill css={{ alignItems: "stretch" }}>
      <span>
        <Icon icon="info-circle" /> - <small>Required fields marked with *</small>
      </span>

      <Inline>
        {mode === EDITOR_MODES.CREATE && <StepID>1</StepID>}
        <StepContainer fill>
          <label>Index Name *:</label>
          <TextInput
            color={invalid ? "danger" : "primary"}
            value={title}
            onChange={handleChangeTitle}
            data-testid="index-name-text-input"
          />
        </StepContainer>
      </Inline>

      <Inline>
        {mode === EDITOR_MODES.CREATE && <StepID>2</StepID>}
        <StepContainer fill>
          <label>Index Description *:</label>
          <TextArea
            color={invalid ? "danger" : "primary"}
            name="description"
            value={description}
            onChange={handleChangeDescription}
            data-testid="index-description-text-input"
          />
        </StepContainer>
      </Inline>

      <Inline>
        {mode === EDITOR_MODES.CREATE && <StepID>3</StepID>}
        <StepContainer fill>
          <label>Index Icon:</label>
          <ImageInput
            name="logo"
            value={icon}
            invalid={invalid}
            onChange={handleChangeIcon}
            onNotSupported={handleFileApiIsNotSupported}
            onBadFileMimeType={handleFileTypeIsNotSupported}
          />
        </StepContainer>
      </Inline>

      <Inline>
        {mode === EDITOR_MODES.CREATE && <StepID>4</StepID>}
        <StepContainer fill>
          <label>Index Status *:</label>
          <RadioGroup value={status} onValueChange={handleChangeStatus}>
            <Stack css={{ alignItems: "start", gap: "$1" }}>
              <RadioGroupItem value={PROGRAM_STATUSES.PENDING}>
                {PROGRAM_STATUSES_LABELS[PROGRAM_STATUSES.PENDING]}
              </RadioGroupItem>
              <RadioGroupItem value={PROGRAM_STATUSES.ACTIVE}>
                {PROGRAM_STATUSES_LABELS[PROGRAM_STATUSES.ACTIVE]}
              </RadioGroupItem>
              <RadioGroupItem value={PROGRAM_STATUSES.INACTIVE}>
                {PROGRAM_STATUSES_LABELS[PROGRAM_STATUSES.INACTIVE]}
              </RadioGroupItem>
            </Stack>
          </RadioGroup>
        </StepContainer>
      </Inline>
    </Stack>
  );
};

ProgramEditorForm.displayName = "ProgramEditorForm";

export default ProgramEditorForm;
