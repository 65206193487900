// @flow

import PropTypes from "prop-types";

import React from "react";
import { observer } from "mobx-react";
import ResultLevelCardPayRateSection from "../components/ResultLevelCardPayRateSection";
import ResultLevelCardMarkupSection from "../components/ResultLevelCardMarkupSection";
import ResultLevelCardBillRateSection from "../components/ResultLevelCardBillRateSection";
import { LEVEL_ROMAN_NUMERAL } from "../../../constants/levels";
import { LEVEL_SELECTOR } from "../../../constants/css";
import { LEVEL_SPARKLINE_MONTH_FORMAT } from "../../../constants/dateFormats";
import { buildMonthsList, buildData } from "../components/SupportFunctions";

const ResultLevelCard = (props) => {
  let store = props.store;
  const globalStore = store.mobxStore.globalStateStore;
  const contingentRateFrequency = globalStore.selectedContingentRateDisplayOption;
  let resultLevel = props.resultLevel;
  let search = store.search;

  let rateType = search.frequency.title.toLowerCase();
  let levelNumeral = LEVEL_ROMAN_NUMERAL[resultLevel.levelName];
  // let levelAbbreviation = LEVEL_ABBREVIATION[resultLevel.levelName];
  let levelSelector = LEVEL_SELECTOR[resultLevel.levelName];

  let months = buildMonthsList(
    -1 * store.RESULTS_HISTORY_MONTHS_TO_LOAD,
    LEVEL_SPARKLINE_MONTH_FORMAT
  );

  let data = buildData(resultLevel, "payRateMax", months);
  let payRateOverTime = data.ratesData;
  let missingPayRateOverTime = data.missingRates;
  let payRateChangeOverTime = data.changesData;
  let missingPayRateChangeOverTime = data.missingChanges;

  let billRateOverTime = null;
  let missingBillRateOverTime = null;
  let billRateChangeOverTime = null;
  let missingBillRateChangeOverTime = null;
  let rateMultiplier = 1;

  if (rateType === "contract") {
    rateMultiplier = globalStore.rateMultiplier || 1;
    data = buildData(resultLevel, "billRateMax", months);
    billRateOverTime = data.ratesData;
    missingBillRateOverTime = data.missingRates;
    billRateChangeOverTime = data.changesData;
    missingBillRateChangeOverTime = data.missingChanges;
  }

  return (
    <div className="panel panel-default panel-results level-card">
      <div className="panel-heading">
        <div className="flex-row no-padding flex-align-baseline">
          <h3 className="panel-title">Level {levelNumeral} </h3>
          <div className="flex-fill" />
          <span className="sample-set-info">
            {resultLevel.numResults} - Equalized Sample Set
          </span>
        </div>
      </div>
      <div className="panel-body">
        <div className="flex-row no-padding flex-align-start">
          <div style={{ marginRight: 10 }}>
            <div className={`level-icon ${levelSelector}`}>
              <header>{levelNumeral}</header>
            </div>
          </div>
          <div>
            <h4 className="level-card-job-label">{search.label}</h4>
            <label className="level-card-location">{search.locationDetail()}</label>
          </div>
        </div>
        <br />

        <ResultLevelCardPayRateSection
          store={store}
          resultLevel={resultLevel}
          months={months}
          ratesHistoryData={payRateOverTime}
          ratesMissingData={missingPayRateOverTime}
          changesHistoryData={payRateChangeOverTime}
          changesMissingData={missingPayRateChangeOverTime}
          rateMultiplier={rateMultiplier}
          contingentRateFrequency={contingentRateFrequency}
        />

        {rateType === "contract" && (
          <ResultLevelCardMarkupSection store={store} resultLevel={resultLevel} />
        )}

        {rateType === "contract" && (
          <ResultLevelCardBillRateSection
            store={store}
            resultLevel={resultLevel}
            months={months}
            ratesHistoryData={billRateOverTime}
            ratesMissingData={missingBillRateOverTime}
            changesHistoryData={billRateChangeOverTime}
            changesMissingData={missingBillRateChangeOverTime}
            rateMultiplier={rateMultiplier}
            contingentRateFrequency={contingentRateFrequency}
          />
        )}
      </div>
    </div>
  );
};

ResultLevelCard.propTypes = {
  store: PropTypes.object.isRequired,
  resultLevel: PropTypes.object.isRequired,
};

export default observer(ResultLevelCard);
