// @flow

import React from "react";
import { observer } from "mobx-react";
import ToggleButton, { ToggleButtonGroup } from "../../ratecards/components/ToggleButton";
import { LIBRARY_DISPLAY_OPTIONS } from "../../../stores/mobx/JobLibraryListStore";
import type JobLibraryListStore from "../../../stores/mobx/JobLibraryListStore";

type Props = {
  store: JobLibraryListStore,
};

const LibraryDisplayOptions = (props: Props) => {
  const store = props.store;

  return (
    <ToggleButtonGroup>
      <ToggleButton
        className="show-libraries"
        type="radio"
        value={LIBRARY_DISPLAY_OPTIONS.LIBRARY}
        selected={store.libraryDisplayValue === LIBRARY_DISPLAY_OPTIONS.LIBRARY}
        onClick={store.handleLibraryDisplayValue}
      >
        Libraries
      </ToggleButton>
      <ToggleButton
        className="show-countries"
        type="radio"
        value={LIBRARY_DISPLAY_OPTIONS.COUNTRY_LIBRARY}
        selected={store.libraryDisplayValue === LIBRARY_DISPLAY_OPTIONS.COUNTRY_LIBRARY}
        onClick={store.handleLibraryDisplayValue}
      >
        Country Libraries
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default observer(LibraryDisplayOptions);
