import { useCallback, useMemo } from "react";
import { Map } from "immutable";
import { useSearchId } from "./useSearchId";
import { useSearchResultsStore } from "../../../stores/SearchResults";
import { isNonNullable } from "../../../utils/hashmap";
import { PayDifference_viewer$data } from "../RsResults/PayDifference/__generated__/PayDifference_viewer.graphql";

const RATE_LEVEL = 3 as const;

function calculatePayDifference(isBaseRate: boolean, rate: number, baseRate: number) {
  let payDifference = 0;
  try {
    if (!isBaseRate) {
      payDifference = ((rate - baseRate) / baseRate) * 100;
      payDifference = Math.round(payDifference + Number.EPSILON);
      // if it is zero let's show one decimal point
      if (payDifference === 0) {
        payDifference = ((rate - baseRate) / baseRate) * 100;
        payDifference = Number(payDifference.toFixed(1));
      }
    }
  } catch (error) {
    return 0;
  }
  return payDifference;
}

type SavedSearchNode = NonNullable<
  NonNullable<
    NonNullable<PayDifference_viewer$data["savedsearches"]>["edges"][number]
  >["node"]
>;

type MarketRate = NonNullable<NonNullable<SavedSearchNode["marketrates"]>[number]>;

function getMidPayRateByLevel(marketRates?: MarketRate[], level: number = 3) {
  const marketRate = marketRates?.find((mr) => mr?.level?.legacyId === level);
  return marketRate?.payRateMid || 0;
}

export type PayDifferenceSet = ReturnType<typeof usePayDifference>;

export function usePayDifference(data: PayDifference_viewer$data) {
  const { searchId } = useSearchId();

  const toCurrency = useSearchResultsStore((s) => s.currency);
  const rateFrequency = useSearchResultsStore((s) => s.rateFrequency);
  const getRate = useSearchResultsStore((s) => s.calculateRate);

  const calculateRate = useCallback(
    (fromCurrency: string, rate: number, withFormat = false) => {
      return getRate(
        fromCurrency,
        toCurrency,
        rateFrequency,
        !withFormat
      )(rate, withFormat);
    },
    [getRate, toCurrency, rateFrequency]
  );

  return useMemo(() => {
    if (!data?.savedsearches?.edges.length) return [];

    const searches =
      data.savedsearches?.edges.map((e) => e?.node).filter(isNonNullable) || [];
    // gets the base rate based on the main search id
    const baseSearch = searches.find((node) => node.searchId === searchId);
    const baseFromCurrency = baseSearch?.currency?.iso || "USD";
    const baseRarketrRates = baseSearch?.marketrates;
    const baseMarketRates = baseRarketrRates?.filter(isNonNullable) || [];
    const baseRate = Number(
      calculateRate(baseFromCurrency, getMidPayRateByLevel(baseMarketRates, RATE_LEVEL))
    );

    // Creates a hashmap in order to remove duplicates
    // Also calculates pay difference for each location/region and adds it to the set
    return searches
      .reduce((searcheMap, search) => {
        const key = (search.region ? search.region.regionId : search.locationId)!;
        return searcheMap.set(key, search);
      }, Map<number, SavedSearchNode>())
      .map((search) => {
        if (!search) return null;
        const key = (search.region ? search.region.regionId : search.locationId)!;
        // currency of the rate search
        const fromCurrency = search.currency?.iso || "USD";

        const isBaseRate = baseSearch?.region?.regionId
          ? search.region?.regionId === baseSearch?.region?.regionId
          : search.locationId === baseSearch?.locationId;

        const marketRates = search.marketrates?.filter(isNonNullable) || [];
        const rate = Number(
          calculateRate(fromCurrency, getMidPayRateByLevel(marketRates, RATE_LEVEL))
        );

        const rateWithFormat = calculateRate(
          fromCurrency,
          getMidPayRateByLevel(marketRates, RATE_LEVEL),
          true
        );

        const payDifference = calculatePayDifference(isBaseRate, rate, baseRate);

        const rateString =
          payDifference > 0
            ? `${rateWithFormat} / ${payDifference}% more`
            : payDifference < 0
            ? `${rateWithFormat} / ${payDifference * -1}% less`
            : `${rateWithFormat}`;

        return {
          key,
          isBaseRate,
          rateString,
          payDifference,
          ...search,
        };
      })
      .sortBy((s) => s?.payDifference)
      .toArray()
      .filter(isNonNullable);
  }, [searchId, data, calculateRate]);
}
