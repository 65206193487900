import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import R from "ramda";
import { fromJS, Map, List } from "immutable";

import BaseRestfulDatasetListView from "./base/BaseRestfulDatasetListView";
import { AutoSearchBox } from "../lib/SearchBox";
import { RadioGroup, RadioGroupItem } from "../lib/RadioGroup";
import Enum from "../../utils/enumerations";
import { baseDjangoResponseConverter } from "../../utils/django";
import {
  djangoPaginationKey,
  djangoPaginationSizeKey,
  djangoSearchKey,
  emptyList,
} from "../../constants";
import Text from "../lib/Text";
import Box from "../lib/Box";
import Inline from "../lib/Inline";

// constants
const baseListViewClassName = "pt-base-list-view";
export const SEARCH_MODES = new Enum(
  ["SEARCH_BY_COLLECTION", "Search by Collection", "search_by_collection"],
  ["SEARCH_BY_JOB_TITLE", "Search by Job Title", "search_by_job_title"]
);

// converters
const collectionToImmutableMap = (item = {}) => {
  return fromJS({
    ...item,
    created: !R.isNil(item["created"]) ? new Date(item["created"]) : null,
  });
};
const collectionsListToImmutableList = (list = []) => {
  return new List(list.map(collectionToImmutableMap));
};
const transformCollectionsData = R.partial(
  baseDjangoResponseConverter,
  collectionsListToImmutableList,
  emptyList
);

// component
export default class JobCollectionSelect extends BaseRestfulDatasetListView {
  static displayName = "JobCollectionSelect";
  static propTypes = {
    ...BaseRestfulDatasetListView.propTypes,
    value: PropTypes.instanceOf(Map),
    onSelect: PropTypes.func,
    fetchTasteAPI: PropTypes.func.isRequired,
    showModalError: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = Object.assign(this.state || {}, {
      mode: SEARCH_MODES.SEARCH_BY_COLLECTION,
    });
  }

  // utils

  setLoadingState = async (value) => {
    return new Promise((resolve) => this.setState({ loading: value }, resolve));
  };

  // handlers

  _onChangeSelectMode = (value) => {
    if (this.state.mode !== value) {
      this.setState({ mode: value }, () => this._onMakeSearch(this.state.search));
    }
  };

  // api methods

  dataSource = async (search, page, pageSize) => {
    const { fetchTasteAPI, showModalError } = this.props;
    const { mode } = this.state;
    let path = null;
    const queryArgs = {
      [djangoPaginationKey]: page,
      [djangoPaginationSizeKey]: pageSize,
    };

    // if (search) queryArgs[djangoSearchKey] = search;
    queryArgs[djangoSearchKey] = search;

    if (mode === SEARCH_MODES.SEARCH_BY_COLLECTION) {
      path = "job_title_collection/search/";
      queryArgs["is_active"] = true;
    } else if (mode === SEARCH_MODES.SEARCH_BY_JOB_TITLE) {
      path = "job_title_collection/search/by_job_title/";
      queryArgs["collection__is_active"] = true;
    }

    try {
      await this.setLoadingState(true);
      const response = await fetchTasteAPI(path, { params: queryArgs });
      return transformCollectionsData(response.data);
    } catch (err) {
      console.error("Error occurred while loading collections list:", { err });
      showModalError && showModalError("Please, try again later.", "Error!", null);
    } finally {
      await this.setLoadingState(false);
    }
  };

  // handlers

  _onSelectItem = (value) => {
    const { onSelect, disabled } = this.props;

    if (!disabled && onSelect) onSelect(value);
  };

  // render methods

  renderSearchInput() {
    const { searchPlaceholder, disabled } = this.props;
    const { search, mode } = this.state;

    return (
      <div>
        <AutoSearchBox
          value={search || ""}
          css={{
            width: "$full",
            borderWidth: "1px",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            backgroundColor: "transparent",
          }}
          placeholder={searchPlaceholder}
          onChange={this._onSearchChange}
          onSubmit={this._onMakeSearch}
          disabled={disabled}
        />
        <Box
          css={{
            width: "$full",
            border: "1px solid $primaryLight",
            borderTop: 0,
            borderBottom: 0,
            padding: "$1_5 $4",
            textAlign: "center",
          }}
        >
          <RadioGroup value={mode} onValueChange={this._onChangeSelectMode}>
            <Inline css={{ justifyContent: "center" }}>
              <RadioGroupItem
                disabled={disabled}
                value={SEARCH_MODES.SEARCH_BY_COLLECTION}
              >
                {SEARCH_MODES.getLabel(SEARCH_MODES.SEARCH_BY_COLLECTION)}
              </RadioGroupItem>
              <RadioGroupItem
                disabled={disabled}
                value={SEARCH_MODES.SEARCH_BY_JOB_TITLE}
              >
                {SEARCH_MODES.getLabel(SEARCH_MODES.SEARCH_BY_JOB_TITLE)}
              </RadioGroupItem>
            </Inline>
          </RadioGroup>
        </Box>
      </div>
    );
  }

  renderItemText(idx, item) {
    const { disabled } = this.props;
    const id = item.get("id");
    const title = item.get("title");
    const source = item.get("source");

    return (
      <span>
        <Text as="strong" css={{ color: disabled ? "$primary" : "$brand" }}>
          #{id}
        </Text>{" "}
        - {title}
        {source && ` (${source})`}
      </span>
    );
  }

  renderItemRow(idx, item) {
    const { value, disabled } = this.props;
    const valueId = value && value.size && value.get("id");
    const itemId = item && item.size && item.get("id");
    const isItemSelected = valueId === itemId;

    return (
      <Box
        key={idx}
        onClick={!disabled ? R.partial(this._onSelectItem, item) : undefined}
        css={{
          width: "$full",
          backgroundColor: isItemSelected
            ? disabled
              ? "$primaryLighter"
              : "$brandLighter"
            : "inherit",
          color: disabled ? "$primary" : "inherit",
        }}
      >
        {super.renderItemRow(idx, item)}
      </Box>
    );
  }

  render() {
    const { disabled, className } = this.props;
    const componentClassName = classNames(className, baseListViewClassName, {
      [baseListViewClassName + "--disabled"]: disabled,
    });

    return (
      <div className={componentClassName}>
        {this.renderSearchInput()}
        {this.renderResultsList()}
      </div>
    );
  }
}
