/**
 * @generated SignedSource<<87cb88946029b7e0490674ff67e54699>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type RsLocationSelectQuery$variables = {
  countryIds?: ReadonlyArray<number | null> | null;
  searchParam: string;
};
export type RsLocationSelectQuery$data = {
  readonly viewer: {
    readonly locations: ReadonlyArray<{
      readonly countryCode: string | null;
      readonly countryId: number | null;
      readonly fullSubtitle: string | null;
      readonly fullTitle: string | null;
      readonly lat: string | null;
      readonly locationId: string | null;
      readonly lon: string | null;
      readonly subtitle: string | null;
      readonly title: string | null;
      readonly type: string | null;
    } | null> | null;
  } | null;
};
export type RsLocationSelectQuery = {
  response: RsLocationSelectQuery$data;
  variables: RsLocationSelectQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "countryIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchParam"
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "countryIds",
            "variableName": "countryIds"
          },
          {
            "kind": "Variable",
            "name": "search",
            "variableName": "searchParam"
          }
        ],
        "concreteType": "LocationListNode",
        "kind": "LinkedField",
        "name": "locations",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subtitle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullTitle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullSubtitle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "countryId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "locationId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "countryCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lat",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lon",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RsLocationSelectQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "RsLocationSelectQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "bb3f684bc966479b16804cfc2e6d0cb1",
    "id": null,
    "metadata": {},
    "name": "RsLocationSelectQuery",
    "operationKind": "query",
    "text": "query RsLocationSelectQuery(\n  $searchParam: String!\n  $countryIds: [Int]\n) {\n  viewer {\n    locations(search: $searchParam, countryIds: $countryIds) {\n      title\n      subtitle\n      type\n      fullTitle\n      fullSubtitle\n      countryId\n      locationId\n      countryCode\n      lat\n      lon\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "85a1a1e837421b673ce34c9fb8d6554d";

export default node;
