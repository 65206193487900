import { StateCreator, create } from "zustand";
import { devtools } from "zustand/middleware";
import { GssCountries } from "../constants/gssCountries";
import { CONTRACT } from "../constants/rateTypes";
import { isNonNullable } from "../utils/hashmap";
import {
  CountryLibrary,
  FindRateInput,
  Industry,
  JobLibrary,
  JobRateType,
  JobTitle,
  Location,
  Region,
  RoleType,
  WorkerType,
} from "../views/ratesearch2/types";

type State = {
  jobTitle: JobTitle | null;
  jobLabel: string;
  regions: Region[];
  industries: Industry[];
  locations: Location[];
  isGss: boolean | undefined | null;
  rateType: JobRateType;
  roleType: RoleType;
  workerType: WorkerType | null;
  description: string;
  isSideMenu: boolean;
  jobLibrary: JobLibrary | null;
  countryLibrary: CountryLibrary | null;
};

type Actions = {
  reset(): void;
  setJobTitle(jobTitle: JobTitle | null): void;
  setJobLabel(jobTitle: string): void;
  setRegions(regions: Region[]): void;
  setIndustries(industries: Industry[]): void;
  setLocations(locations: Location[]): void;
  setIsGss(isGss: boolean): void;
  setRateType(rateType: JobRateType): void;
  setRoleType(roleType: RoleType): void;
  setWorkerType(workerType: WorkerType | null): void;
  setDescription(description: string): void;
  setIsSideMenu(isSideMenu: boolean): void;
  setLibrary(jobLibrary: JobLibrary | null, countryLibrary: CountryLibrary | null): void;
  getSupportedCountries(): {
    certified: number[];
    adhoc: number[];
    all: number[];
  };
  showWorkerType(): boolean;
  showGss(): boolean;
  canSubmitSearchRate(): boolean;
  getMutationInput(): FindRateInput;
  hasRegions(): boolean;
  hasLocations(): boolean;
  getSelectedGssRegions(): Region[];
};

const initialState: State = {
  jobTitle: null,
  jobLabel: "",
  regions: [],
  industries: [],
  locations: [],
  isGss: false,
  rateType: CONTRACT.label,
  roleType: "Registry",
  workerType: null,
  description: "",
  isSideMenu: false,
  jobLibrary: null,
  countryLibrary: null,
};

const middlewares = (initializer: StateCreator<State & Actions, []>) =>
  devtools(initializer, { name: "Rate_Search_Form_Store" });

/**
 * Contains state and actions for rate-search form components.
 *
 * Why using a store?
 * * Helps with reducing prop drilling.
 * * Achieves fewer re-renders by better selecting the state needed in the sub-components.
 * * Removes the need of having to use 10 `useState` and a few `useEffect` in the main component.
 * * Encapsulates state and its logic in one place.
 */
export const useRateSearchFormStore = create<State & Actions>()(
  middlewares((set, get) => ({
    ...initialState,
    setJobTitle(jobTitle) {
      set({ jobTitle });
    },
    setRoleType(roleType) {
      /**
       * If the user selects "Traveler", they CAN NOT select "FTE".
       * The option for "Contract" is the only one that applies to "Traveler".
       *
       * If the user selects "Registry", they can select either "FTE" or "Contract"
       */
      if (roleType === "Traveler") {
        set({ roleType, rateType: "Contract" });
      } else {
        set({ roleType });
      }
    },
    setJobLabel(jobLabel) {
      set({ jobLabel });
    },
    setRegions(regions) {
      set({ regions });
    },
    setIndustries(industries) {
      set({ industries });
    },
    setLocations(locations) {
      set({ locations });
    },
    setIsGss(isGss) {
      set({ isGss });
    },
    setRateType(rateType) {
      set({ rateType });
    },
    setWorkerType(workerType) {
      set({ workerType });
    },
    setDescription(description) {
      set({ description });
    },
    setIsSideMenu(isSideMenu) {
      set({ isSideMenu });
    },
    setLibrary(jobLibrary, countryLibrary) {
      set({ jobLibrary, countryLibrary, jobTitle: null, regions: [], locations: [] });
    },
    reset() {
      set(initialState);
    },
    getSupportedCountries() {
      const library = get().jobTitle?.clientJobLibraryInfo;
      const certified = library?.certifiedCountries?.filter(isNonNullable) || [];
      const adhoc = library?.adhocCountries?.filter(isNonNullable) || [];
      return {
        certified,
        adhoc,
        all: certified.concat(adhoc),
      };
    },
    showWorkerType() {
      const { locations, regions, industries, rateType, jobTitle } = get();
      const regionsSelected: boolean = regions.length > 0;
      const selectedCountries = locations.filter((l) => l.countryId);

      if (jobTitle?.isHealthcare) return false;

      return (
        !regionsSelected &&
        selectedCountries.length === 1 &&
        industries.length === 1 &&
        rateType === CONTRACT.label
      );
    },
    getSelectedGssRegions() {
      const { regions } = get();
      return regions.filter((r) => {
        if (!r.locations) return false;
        return r.locations.some((loc) => {
          if (!loc) return false;
          const isGssLocation = GssCountries.has(loc.locationId);
          const hasGssParent = GssCountries.has(loc.parent?.locationId ?? 0);
          return isGssLocation || hasGssParent;
        });
      });
    },
    showGss() {
      const { regions, locations, getSelectedGssRegions } = get();

      const gssRegions = getSelectedGssRegions();
      const regionsSelected = regions.length > 0;
      const gssLocations = locations.filter((l) =>
        GssCountries.has(Number(l?.countryId))
      );

      const isGssRegionEnabled =
        regions.length !== 0 && gssRegions.length === regions.length;

      const isGssLocationEnabled =
        locations.length > 0 &&
        gssLocations.length === locations.length &&
        !regionsSelected;

      return isGssLocationEnabled || isGssRegionEnabled;
    },
    canSubmitSearchRate() {
      const { jobTitle, locations, regions, industries } = get();
      const hasJobTitle = Boolean(jobTitle?.id);
      const hasLocationAndRegion = locations.length > 0 || regions.length > 0;
      const hasIndustry = industries.length > 0;
      return hasJobTitle && hasLocationAndRegion && hasIndustry;
    },
    getMutationInput() {
      const {
        jobTitle,
        jobLabel,
        locations,
        regions,
        industries,
        description,
        isGss,
        rateType,
        roleType,
        workerType,
        showGss,
      } = get();

      if (!jobTitle) throw new Error("No jobTitle selected");

      const data: FindRateInput = {
        jobTitle: jobTitle.title,
        jobTitleId: Number(jobTitle?.id),
        jobLabel,
        gss: showGss() && isGss,
        description: description || " ",
        rateType,
        locationIds: locations.map((l) => l.locationId),
        industries: industries.map((i) => i.legacyId),
        regionIds: regions.map((r) => r.regionId),
      };

      if (jobTitle.isHealthcare) {
        data.showQuantiles = true;
        data.isTravel = roleType === "Traveler";
      } else if (workerType) {
        data.workerTypeId = workerType.workerTypeId;
        data.workerTypeName = workerType.name;
      }

      return data;
    },
    hasRegions() {
      const { regions } = get();
      return regions.length > 0;
    },
    hasLocations() {
      const { locations } = get();
      return locations.length > 0;
    },
  }))
);
