// @flow

import type { SortDirection } from "../models/Sort";

interface RelayPaginationInfo {
  +count: number;
  +cursor: ?string;
}

interface RelayFragmentVariables {
  +filters: any;
  +order: any;
}

/**
 * Default function for getting query variables for a relay pagination container.
 * It expects a defined query arguments: cursor, count, filters, order. If you
 * use a different name for any of these arguments you should implement a custom
 * function.
 * @param props
 * @param paginationInfo
 * @param fragmentVars
 * @returns {{cursor: ?string, count: number, filters: *, order: *}}
 */
export function getVariables(
  props: Object,
  paginationInfo: RelayPaginationInfo,
  fragmentVars: RelayFragmentVariables
) {
  const { count, cursor } = paginationInfo;
  const { filters, order } = fragmentVars;
  return { count, cursor, filters, order };
}

export interface SortObject {
  +field: string;
  +direction?: SortDirection;
  +caseSensitive?: boolean;
}

export function updateSortingOrder(
  newSort: ?SortObject,
  currentSort: ?SortObject,
  sortingOrder: Array<string>
): Array<string> {
  if (newSort && !currentSort) {
    return [...sortingOrder, newSort.field];
  }

  if (!newSort && currentSort) {
    return sortingOrder.filter((v) => v !== currentSort.field);
  }

  return sortingOrder;
}

export function getSortingList(
  sortingObjects: Array<SortObject | null>,
  sortingOrder: Array<string>
): ?Array<SortObject> {
  const sorts: Array<SortObject> = [];
  sortingObjects.forEach((s) => {
    if (s) sorts.push(s);
  });
  sorts.sort((item1, item2) => {
    const index1 = sortingOrder.indexOf(item1?.field);
    const index2 = sortingOrder.indexOf(item2?.field);
    return index1 - index2;
  });
  return sorts.length > 0 ? sorts : null;
}
