// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Pagination extends Component {
  goToCurrentPage: () => void;

  constructor(props) {
    super(props);

    this.goToCurrentPage = this.goToCurrentPage.bind(this);
  }

  goToCurrentPage(activePage) {
    const { handleCurrentPage } = this.props;
    // console.log("go to page:", activePage);

    if (handleCurrentPage) {
      handleCurrentPage(activePage);
    }
  }

  render() {
    const { begin, end, isEndRange, pageCount, firstPage, currentPage } = this.props;

    let nextpage = null;
    let onClickFirst = () => {};
    let onClickBackward = () => {};
    let onClickLast = () => {};
    let onClickForward = () => {};

    if (currentPage >= pageCount) nextpage = currentPage;
    else nextpage = currentPage + 1;

    if (currentPage > 1) {
      onClickFirst = this.goToCurrentPage.bind(this, firstPage);
      onClickBackward = this.goToCurrentPage.bind(this, currentPage - 1);
    }

    if (currentPage < pageCount) {
      onClickLast = this.goToCurrentPage.bind(this, pageCount);
      onClickForward = this.goToCurrentPage.bind(this, nextpage);
    }
    // console.log("currentPage:", currentPage, "| pageCount:", pageCount);

    let pages = [];

    if (isEndRange) {
      for (let i = begin; i <= end; i++) {
        if (i === this.props.currentPage) {
          pages.push(
            <div key={i} className="pt-page-box--active">
              {i}
            </div>
          );
        } else {
          pages.push(
            <div
              key={i}
              className="pt-page-box"
              onClick={this.goToCurrentPage.bind(this, i)}
            >
              {i}
            </div>
          );
        }
      }
    } else {
      let midpoint = Math.ceil((end - begin) / 2) + begin;

      for (let i = begin; i <= midpoint; i++) {
        if (i === this.props.currentPage) {
          pages.push(
            <div key={i} className="pt-page-box--active">
              {i}
            </div>
          );

          continue;
        }

        pages.push(
          <div
            key={i}
            className="pt-page-box"
            onClick={this.goToCurrentPage.bind(this, i)}
          >
            {i}
          </div>
        );
      }

      pages.push(
        <div key={"collapse"} className="pt-page-box">
          <div className="pt-page-box--collapse">•••</div>
        </div>
      );
      pages.push(
        <div
          key={pageCount}
          className="pt-page-box"
          onClick={this.goToCurrentPage.bind(this, pageCount)}
        >
          {pageCount}
        </div>
      );
    }

    return (
      <div className="pt-pagination-outer">
        <div id="pt-first-page" className="pt-first-page" onClick={onClickFirst}>
          <FontAwesomeIcon
            icon="chevron-left"
            fixedWidth
            className="pt-first-page-left-chevron"
          />
          <span className="pt-first-page-text">First Page</span>
        </div>
        <div id="pt-prev-page" className="pt-prev-page" onClick={onClickBackward}>
          <FontAwesomeIcon
            icon="chevron-left"
            fixedWidth
            className="pt-page-left-chevron"
          />
        </div>
        {pages}
        <div id="pt-next-page" className="pt-next-page" onClick={onClickForward}>
          <FontAwesomeIcon
            icon="chevron-right"
            fixedWidth
            className="pt-page-right-chevron"
          />
        </div>
        <div id="pt-last-page" className="pt-last-page" onClick={onClickLast}>
          <span className="pt-last-page-text">Last Page</span>
          <FontAwesomeIcon
            icon="chevron-right"
            fixedWidth
            className="pt-last-page-right-chevron"
          />
        </div>
      </div>
    );
  }
}

Pagination.propTypes = {
  pageCount: PropTypes.number,
  pagesToShow: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  begin: PropTypes.number.isRequired,
  end: PropTypes.number.isRequired,
  isEndRange: PropTypes.bool.isRequired,
  handleCurrentPage: PropTypes.func,
  firstPage: PropTypes.number,
};

export default observer(Pagination);
