import React from "react";
import { Set } from "immutable";

import {
  ContractorsTable,
  adminAllowedOnlyKeys as baseAdminAllowedOnlyKeys,
  ContractorsTableProps,
} from "./ContractorsTable";
import { contractorValuesColumnsSpecs } from "./ContractorValuesTableSpecs";
import { matchedMarketValuesColumnsSpecs } from "./MarketValuesTableSpecs";
import { TABLE_TABS_TYPES } from "../types";

import type { ImmutableSet } from "../../../types/immutable";

export const adminAllowedOnlyKeys = baseAdminAllowedOnlyKeys;
export const baseColumnsSpecs = contractorValuesColumnsSpecs.concat(
  matchedMarketValuesColumnsSpecs
);
export const columnsWhiteList = Set([
  "status",
  "id",
  "date_engaged",
  "upload__title",
  "category",
  "job_title",
  "processed__job_collection_title",
  "processed__job_collection_id",
  "processed__job_title_id",
  "processed__job_title_match_type",
  "processed__industry_title",
  "job_description",
  "city",
  "state",
  "country",
  "region",
  "pay_rate",
  "markup",
  "markup_amount",
  "bill_rate",
  "annual_salary",
  "burden_perc",
  "manager",
  "supplier",
  "company_name",
  "bonus_amount",
  "is_global_supplier_search",
]) as any as ImmutableSet<string>;

const BaseStatusSpecificContractorsTable = (props: ContractorsTableProps) => (
  <ContractorsTable {...props} />
);
BaseStatusSpecificContractorsTable.defaultProps = {
  ...ContractorsTable.defaultProps,
  actions: [TABLE_TABS_TYPES.CUSTOM_CONFIG],
  activeTab: TABLE_TABS_TYPES.CUSTOM_CONFIG,
  customConfigVisibleColumnsKeys: columnsWhiteList, // immutable Set
};

export default BaseStatusSpecificContractorsTable;
