import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

import RequestMoreModal from "./RequestMoreModal";

class SearchCounter extends Component {
  static propTypes = {
    searches: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    cost: PropTypes.number,
    searchRequestSent: PropTypes.bool,
    hasUnlimitedSearchCount: PropTypes.bool,
  };

  state = {
    showRequestMore: false,
  };

  _handleRequestMore = (e) => {
    this.setState({
      showRequestMore: true,
    });
  };

  _close = (e) => {
    this.setState({
      showRequestMore: false,
    });
  };

  render() {
    const { searches, cost, searchRequestSent, hasUnlimitedSearchCount } = this.props;

    return (
      <div className="search_counter">
        <RequestMoreModal
          show={this.state.showRequestMore}
          onHide={this._close}
          searchRequestSent={searchRequestSent}
        />
        Using <span className="searches_cost">{cost}</span> of{" "}
        <span className="searches_left">{searches}</span> searches remaining
        {!hasUnlimitedSearchCount && (
          <Button bsStyle="link" onClick={this._handleRequestMore}>
            Request more searches
          </Button>
        )}
      </div>
    );
  }
}

export default SearchCounter;
