import React, { useState } from "react";

import Text from "../../components/lib/Text";
import Center from "../../components/lib/Center";
import Overlay from "../../components/lib/Overlay";
import { InlineElements } from "../../components/lib/Inline";
import NumberInput from "../../components/lib/NumberInput";
import Button from "../../components/lib/Button";
import { useMutationSearchAllocatorData } from "./hooks";

import type { SearchAllocatorInputObject } from "./types";

function OverlayContainer(props: React.PropsWithChildren<{}>) {
  return (
    <Overlay css={{ backgroundColor: "rgba(256, 256, 256, .9)" }}>
      <Center
        css={{
          width: "$full",
          height: "$full",
          padding: "$4",
        }}
      >
        {props.children}
      </Center>
    </Overlay>
  );
}

type LayerIsOverProps = {
  isAdd: boolean;
};

export function LayerIsOver(props: LayerIsOverProps) {
  const { isAdd } = props;

  const actionType = isAdd ? "add" : "remove";

  return (
    <OverlayContainer>
      <Text size="lg">Drop to {actionType} searches to this user...</Text>
    </OverlayContainer>
  );
}

type LayerIsDropProps = {
  isAdd: boolean;
  searchAllocatorInput: SearchAllocatorInputObject;
  onCancel: () => void;
};

export function LayerIsDrop(props: LayerIsDropProps) {
  const { isAdd, searchAllocatorInput, onCancel } = props;

  const [value, setValue] = useState(searchAllocatorInput.amount);

  const actionLabel = isAdd ? "Add" : "Remove";
  const actionType = actionLabel.toLowerCase();

  const { mutationData, isLoading } = useMutationSearchAllocatorData(
    searchAllocatorInput.source,
    searchAllocatorInput.destination,
    value
  );

  const handleSubmit = async () => {
    await mutationData();
    onCancel();
  };

  return (
    <OverlayContainer>
      <Text size="lg">Choose # of searches to {actionType}:</Text>
      <InlineElements nowrap fill>
        <NumberInput
          fill
          autoFocus
          value={value}
          disabled={isLoading}
          onChange={(e) =>
            setValue(Number.isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber)
          }
        />
        <Button color="danger" disabled={isLoading} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          color="success"
          loading={isLoading}
          disabled={value === 0 || value > searchAllocatorInput.amount}
          onClick={handleSubmit}
        >
          {actionLabel}
        </Button>
      </InlineElements>
    </OverlayContainer>
  );
}
