import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SORT_DIRECTION } from "../../../models/Sort";
import ToggleButton from "./ToggleButton";
import { InlineElements } from "../../../components/lib/Inline";

const SortControls = (props) => {
  let sortAscIcon = `sort-${props.sortType}-down`;
  let sortDescIcon = `sort-${props.sortType}-up`;

  return (
    <InlineElements>
      <ToggleButton
        type="radio"
        name="sort"
        value={SORT_DIRECTION.ASC}
        selected={props.filterState.sortState.direction === SORT_DIRECTION.ASC}
        onChange={() => props.filterState.sortState.setSort(SORT_DIRECTION.ASC)}
      >
        <FontAwesomeIcon icon={sortAscIcon} fixedWidth className="icon" />
        {props.ascText}
      </ToggleButton>

      <ToggleButton
        type="radio"
        name="sort"
        value={SORT_DIRECTION.DESC}
        selected={props.filterState.sortState.direction === SORT_DIRECTION.DESC}
        onChange={() => props.filterState.sortState.setSort(SORT_DIRECTION.DESC)}
      >
        <FontAwesomeIcon icon={sortDescIcon} fixedWidth className="icon" />
        {props.descText}
      </ToggleButton>
    </InlineElements>
  );
};

SortControls.propTypes = {
  filterState: PropTypes.object.isRequired,
  sortType: PropTypes.string,
  ascText: PropTypes.string,
  descText: PropTypes.string,
};

SortControls.defaultProps = {
  sortType: "alpha", // valid values: 'alpha', 'numeric', 'amount'
  ascText: "Sort A to Z",
  descText: "Sort Z to A",
};

export default observer(SortControls);
