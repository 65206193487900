import React, { useCallback, useRef } from "react";
import { useRecoilValue } from "recoil";

import Stack from "../../../components/lib/Stack";
import Text from "../../../components/lib/Text";
import Icon from "../../../components/lib/Icon";
import TableExportConfig from "../components/TableExportConfig";
import ValidatorSelectForm from "../components/ValidatorSelectForm";
import Button, { NavigationButton } from "../../../components/lib/Button";
import {
  PermissionsTableView,
  PermissionsTableAPI,
} from "../components/PermissionsTable";
import {
  permissionsTableGlobalState,
  PermissionsTableDataStateObject,
} from "../globalState";
import {
  Card,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
  CardBody,
  CardHeaderTitle,
} from "../../../components/lib/Card";
import { useModalState } from "../../../utils/hooks";
import {
  djangoOrderingKey,
  djangoPaginationKey,
  djangoPaginationSizeKey,
} from "../../../components/tables/constants";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";
import { permissionToImmutableMap } from "../dataConverters";

import type { ValidatorSelectFormDataMap, PermissionDataObject } from "../types";
import type {
  UrlQueryObject,
  FiltersQueryObject,
} from "../../../components/tables/types";
import { ButtonGroupRight } from "../../../components/lib/ButtonGroup";
import { FetchAPIResponse } from "../../../types/fetch";
import { omitObjectKeys } from "../../../utils/object";
import { useVal5KAdminContext } from "../context/Val5KAdminContext";

const defaultPermissionsParameters = {
  perms_repr: {
    countries: {
      allowed_none: true,
      allowed_all: false,
      allowed_items: [],
    },
    industries: {
      allowed_none: true,
      allowed_all: false,
      allowed_items: [],
    },
  },
};

type BackToDashboardBlockProps = {
  title?: string;
};

const BackToDashboardBlock = (props: BackToDashboardBlockProps) => {
  const { title } = props;
  const { router } = useVal5KAdminContext();

  const handleBackToDashboard = useCallback(
    () => router.push(`/admin/val5000`),
    [router]
  );

  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft>
          {title && <CardHeaderTitle>{title}</CardHeaderTitle>}
        </CardActionsLeft>
        <CardActionsRight>
          <NavigationButton
            icon="arrow-left"
            onClick={handleBackToDashboard}
            css={{ width: "190px" }}
          >
            Back to Dashboard
          </NavigationButton>
        </CardActionsRight>
      </CardActions>
    </Card>
  );
};
BackToDashboardBlock.displayName = "BackToDashboardBlock";

const PermissionsListPage = () => {
  const {
    showModalError,
    sessionInfo,
    fetchArgusAPI,
    showConfirmationModal,
    closeConfirmationModal,
    showModalWarning,
    showLoader,
    hideLoader,
  } = useVal5KAdminContext();

  // table state

  const tableState = useRecoilValue<PermissionsTableDataStateObject>(
    permissionsTableGlobalState
  );
  const { itemsCount, itemsPerPage, activePage, filtersQuery } = tableState;

  // refs

  const permissionsTableRef = useRef<PermissionsTableAPI>(null);

  // modals

  const {
    modalState: exportModalState,
    showModal: showExportModal,
    closeModal: closeExportModal,
  } = useModalState();

  // handlers

  const handleGetExportQueryArgs = useCallback(
    (exportCurrentPage: boolean = false): UrlQueryObject | null => {
      const queryArgs: UrlQueryObject = {};

      if (filtersQuery.has(djangoOrderingKey)) {
        queryArgs[djangoOrderingKey] = filtersQuery.get(djangoOrderingKey);
      }

      if (sessionInfo.legacySession) {
        queryArgs["x-session-id"] = sessionInfo.legacySession;
      }

      if (exportCurrentPage) {
        queryArgs[djangoPaginationKey] = activePage;
        queryArgs[djangoPaginationSizeKey] = itemsPerPage;
      }

      return Object.keys(queryArgs).length > 0 ? queryArgs : null;
    },
    [activePage, filtersQuery, itemsPerPage, sessionInfo.legacySession]
  );

  const handleGetExportFormData = useCallback((): FiltersQueryObject => {
    return omitObjectKeys(filtersQuery.toJS(), [
      djangoOrderingKey,
      djangoPaginationKey,
      djangoPaginationSizeKey,
    ]);
  }, [filtersQuery]);

  const handleCreatePermissions = useCallback(
    async (validatorData: ValidatorSelectFormDataMap) => {
      if (validatorData && validatorData.size) {
        const email = validatorData.get("email");
        const firstName = validatorData.get("first_name");
        const lastName = validatorData.get("last_name");

        if (!email || !firstName || !lastName) return;

        closeConfirmationModal();
        showLoader();

        try {
          const response: FetchAPIResponse<PermissionDataObject> = await fetchArgusAPI(
            `permissions/`,
            {
              method: "post",
              data: {
                email: email,
                first_name: firstName,
                last_name: lastName,
                ...defaultPermissionsParameters,
              },
            }
          );

          const data = permissionToImmutableMap(response.data);

          if (data && data.size) {
            const justCreated = data.get("just_created") || false;

            if (!justCreated) {
              permissionsTableRef.current?.selectRow(data);
              showModalWarning(
                <Text>
                  We already have permissions object for email{" "}
                  <Text bold italic>
                    {email}
                  </Text>
                  .
                  <br />
                  Please, continue editing existing object.
                </Text>
              );
            }

            await permissionsTableRef.current?.refresh();
            hideLoader();
          }
        } catch (err: any) {
          hideLoader();
          logAsyncOperationError("createPermissionsObject", err);
          showModalError(
            "Error occurred while creating permissions object. Please, try again later."
          );
          return;
        }
      }
    },
    [
      showLoader,
      hideLoader,
      fetchArgusAPI,
      showModalError,
      showModalWarning,
      closeConfirmationModal,
    ]
  );

  const handleSelectValidator = useCallback(() => {
    const header = "Create new permissions object for the validator.";
    const message = (
      <ValidatorSelectForm
        onSelect={handleCreatePermissions}
        fetchArgusAPI={fetchArgusAPI}
        showModalError={showModalError}
      />
    );
    const footer = (
      <ButtonGroupRight fill>
        <Button size="large" onClick={closeConfirmationModal}>
          Cancel
        </Button>
      </ButtonGroupRight>
    );

    showConfirmationModal(message, header, footer);
  }, [
    fetchArgusAPI,
    showModalError,
    showConfirmationModal,
    closeConfirmationModal,
    handleCreatePermissions,
  ]);

  return (
    <Stack>
      <BackToDashboardBlock title="Manage Permissions" />

      <Card fill>
        <CardActions>
          <CardActionsLeft>
            <CardHeaderTitle as="h3">Validators Permissions List</CardHeaderTitle>
          </CardActionsLeft>
          <CardActionsRight>
            <Button icon={["far", "file-excel"]} size="small" onClick={showExportModal}>
              Export
            </Button>
            <Button icon="plus" size="small" onClick={handleSelectValidator}>
              Create New Object
            </Button>
          </CardActionsRight>
        </CardActions>
        <CardBody>
          <Stack css={{ gap: "$1", alignItems: "start" }}>
            <Text thin>
              <Icon icon="info-circle" />
              &nbsp; We identify validator permissions object by provided email. Only one
              permissions object could be created for the single email (validator).
            </Text>
            <Text thin>
              <Icon icon="info-circle" />
              &nbsp; Often validators make typos in names and emails. It doesn't matter
              which name you choose, try to choose correct email value for permissions
              object.
            </Text>
          </Stack>
          <PermissionsTableView ref={permissionsTableRef} />
        </CardBody>
      </Card>

      <BackToDashboardBlock />

      <TableExportConfig
        show={exportModalState}
        endpoint="v1/permissions/export/"
        getQueryArgs={handleGetExportQueryArgs}
        getFormData={handleGetExportFormData}
        itemsTotal={itemsCount}
        itemsPerPage={itemsCount} // whole data export only
        onClose={closeExportModal}
      />
    </Stack>
  );
};

PermissionsListPage.displayName = "PermissionsListPage";

export default PermissionsListPage;
