import React, { useCallback, useState } from "react";
import queryString from "query-string";

import Inline from "../../../../components/lib/Inline";
import RestfulDatasetListView, {
  DataSourceFunc,
  RenderItemFunc,
} from "./BaseRestfulDatasetListView";
import SelectItemRow from "../../lib/SelectItemRow";
import { SimpleLink } from "../../../../components/lib/Link";
import { CheckboxItem } from "../../../../components/lib/Checkbox";
import {
  djangoPaginationKey,
  djangoPaginationSizeKey,
  djangoSearchKey,
  emptyList,
} from "../../../../constants";
import { transformValidatorsData } from "../../dataConverters";
// @ts-expect-error
import { logAsyncOperationError } from "../../../../utils/logging";

import type { ImmutableList } from "../../../../types/immutable";
import type { FetchAPIFunc, FetchAPIResponse } from "../../../../types/fetch";
import type { UrlQueryObject } from "../../../../components/tables/types";
import type {
  ValidatorSelectFormDataList,
  ValidatorSelectFormDataMap,
  ValidatorSelectFormObject,
} from "../../types";
import type { ShowModalFunc } from "../../../../components/modals/MessageModal";
import type { DjangoPaginatedResponse } from "../../../../types/django";

type AlertValidatorSelectProps = {
  values: ValidatorSelectFormDataList;
  countriesIds: ImmutableList<number>;
  fetchArgusAPI: FetchAPIFunc;
  disabled?: boolean;
  showModalError: ShowModalFunc;
  onSelect: (item: ValidatorSelectFormDataList | ValidatorSelectFormDataMap) => void;
};

const AlertValidatorSelect = (props: AlertValidatorSelectProps) => {
  const {
    values,
    disabled = false,
    countriesIds,
    fetchArgusAPI,
    showModalError,
    onSelect,
  } = props;

  const [data, setData] = useState<ValidatorSelectFormDataList>(emptyList);

  const dataSource: DataSourceFunc<ValidatorSelectFormDataList> = useCallback(
    async (search, page, pageSize) => {
      const queryArgs: UrlQueryObject = {};

      if (page) {
        queryArgs[djangoPaginationKey] = page;
      }
      if (pageSize) {
        queryArgs[djangoPaginationSizeKey] = pageSize;
      }
      if (search) {
        queryArgs[djangoSearchKey] = search;
      }

      try {
        const response: FetchAPIResponse<
          DjangoPaginatedResponse<ValidatorSelectFormObject>
        > = await fetchArgusAPI(`alerts/validators/`, {
          method: "post",
          params: queryString.stringify(queryArgs),
          data: { countries_ids: countriesIds.toJS() },
        });
        const data = transformValidatorsData(response.data);
        setData(data.data);
        return data;
      } catch (err: any) {
        logAsyncOperationError("AlertValidatorSelect", err);
        showModalError("Error occurred while loading validators list.");
      }
    },
    [countriesIds, showModalError, fetchArgusAPI]
  );

  const handleSelect = useCallback(
    (item: ValidatorSelectFormDataMap) => {
      if (!disabled) onSelect?.(item);
    },
    [disabled, onSelect]
  );

  const handleSelectAll = useCallback(() => {
    if (data.size) {
      onSelect?.(data);
    }
  }, [data, onSelect]);

  const handleUnselectAll = useCallback(() => {
    onSelect?.(emptyList);
  }, [onSelect]);

  const renderItem: RenderItemFunc<ValidatorSelectFormDataMap> = useCallback(
    (item) => {
      const email = item.get("email");
      const firstName = item.get("first_name");
      const lastName = item.get("last_name");

      const valuesEmail = values?.size ? values.map((i) => i.get("email")) : emptyList;
      const itemEmail = item?.size && item.get("email");
      const itemSelected = !!(
        itemEmail != null &&
        valuesEmail.size &&
        valuesEmail.includes(itemEmail)
      );

      return (
        <SelectItemRow disabled={disabled}>
          <CheckboxItem
            css={{ width: "$full", gap: "$2" }}
            checked={itemSelected}
            disabled={disabled}
            onCheckedChange={() => handleSelect(item)}
          >
            {`${firstName} ${lastName} (${email})`}
          </CheckboxItem>
        </SelectItemRow>
      );
    },
    [values, disabled, handleSelect]
  );

  const renderAction = useCallback(() => {
    return (
      <Inline
        fill
        nowrap
        css={{
          padding: "$2",
          border: "1px solid $primaryLight",
          borderTop: "none",
          justifyContent: "center",
        }}
      >
        <SimpleLink onClick={handleSelectAll}>Select all</SimpleLink>
        <SimpleLink onClick={handleUnselectAll}>Unselect all</SimpleLink>
      </Inline>
    );
  }, [handleSelectAll, handleUnselectAll]);

  return (
    <RestfulDatasetListView
      dataSource={dataSource}
      renderAction={renderAction}
      renderItem={renderItem}
    />
  );
};
AlertValidatorSelect.displayName = "AlertValidatorSelect";

export default AlertValidatorSelect;
