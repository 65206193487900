// @flow

import React from "react";
import { observer } from "mobx-react";
import ToggleButton, { ToggleButtonGroup } from "../../ratecards/components/ToggleButton";

const LevelViewDisplayOptions = (props) => {
  const store = props.store;
  const onOptionChange = props.onOptionChange;
  return (
    <ToggleButtonGroup>
      <ToggleButton
        name="level-options"
        type="radio"
        value={store.SHOW_THREE_LEVELS_VIEW_OPTION}
        selected={store.showThreeLevelsView}
        onClick={onOptionChange}
      >
        3 Level View
      </ToggleButton>
      <ToggleButton
        name="level-options"
        type="radio"
        value={store.SHOW_FIVE_LEVEL_VIEW_OPTION}
        selected={store.showFiveLevelView}
        onClick={onOptionChange}
      >
        Standard View
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default observer(LevelViewDisplayOptions);
