import * as React from "react";
import { observer } from "mobx-react";
import DraftSearchList from "../saved_searches/DraftSearchList";
import { Card, CardHeader, CardHeaderTitle } from "../../components/lib/Card";
import TextInput from "../../components/lib/TextInput";
import { ModalButtons } from "./components/FilterModal";
import LinkButton from "../../components/lib/LinkButton";
import Button from "../../components/lib/Button";
import Container from "../../components/lib/Container";
import Box from "../../components/lib/Box";
import Divider from "../../components/lib/Divider";

/**
 * Create component for rate card
 */
class Create extends React.Component {
  constructor(props) {
    super(props);

    this.labelInputRef = React.createRef();
  }

  /**
   * componentDidMount
   * @return {void}
   */
  componentDidMount() {
    this.props.store.rateCardCreateStore.draftsSearchStore.pagination.goFetch();
    this.props.store.draftsSavedSearchListStore.rateCardLabel = "";
    this.props.store.draftsSavedSearchListStore.rateCards = [];
    this.props.store.draftsSavedSearchListStore.deSelectAll();
    this.props.store.rateCardCreateStore.draftsSearchStore.isEditing = true;

    this.props.store.draftsSavedSearchListStore.clearFilters();
    //deleting message state if any
    this.props.store.draftsSavedSearchListStore.messaging.messages = [];
  }

  componentDidUpdate() {
    if (this.props.store.draftsSavedSearchListStore.messaging.messages.length > 0) {
      this.labelInputRef.current?.focus();
      console.log("ref", this.labelInputRef.current);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }
  /**
   * Render rate card create page
   * @return {XML}
   */
  render() {
    const store = this.props.store.draftsSavedSearchListStore;
    return (
      <Container
        css={{
          paddingTop: "$4",
          paddingLeft: 0,
          paddingRight: 0,
          width: "100%",
        }}
      >
        <Card>
          <CardHeader>
            <CardHeaderTitle>Create Rate Card</CardHeaderTitle>
          </CardHeader>
          <Box css={{ padding: "15px 20px" }}>
            <label htmlFor="rateCardLabel" className="block">
              Rate Card Label:
            </label>
            <TextInput
              id="rateCardLabel"
              data-testid="ratecard-label"
              type="text"
              maxLength="250"
              css={{
                width: "300px",
              }}
              value={store.rateCardLabel}
              onChange={store.changeRateCardLabelText}
              ref={this.labelInputRef}
            />
          </Box>
          <Box className="pt-user-messages" css={{ padding: "0 20px" }}>
            {store.messaging.messages.length > 0 && (
              <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                <a
                  href="#close"
                  className="close"
                  data-dismiss="alert"
                  aria-label="close"
                  onClick={store.messaging.removeMessage.bind(
                    store,
                    store.messaging.messages[0]
                  )}
                >
                  &times;
                </a>
                <strong>{store.messaging.messages[0].messageLabel}</strong>
                {store.messaging.messages[0].message}
              </div>
            )}
          </Box>
          <Divider css={{ borderColor: "$primaryLighter" }} />
          {/*<div className="header-action-bar">
            <h2 className="ellipsis">Available Searches</h2>
          </div>*/}
          <DraftSearchList store={this.props.store} showHeader={true} />
          <Box
            css={{
              backgroundColor: "$primaryLighter",
              padding: "15px 20px",
              borderBottomLeftRadius: "$rounded",
              borderBottomRightRadius: "$rounded",
            }}
          >
            <ModalButtons>
              <Button
                color="brand"
                size="large"
                onClick={store.createRateCard}
                data-testid="create-ratecard-btn"
              >
                Create Rate Card
              </Button>
              <LinkButton size="large" to="/ratecards">
                Cancel
              </LinkButton>
            </ModalButtons>
          </Box>
        </Card>
      </Container>
    );
  }
}

export default observer(Create);
