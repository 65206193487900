import React, { useCallback, useEffect } from "react";
import moment from "moment";
import { useRecoilState } from "recoil";

import Box from "../../../components/lib/Box";
import TextCrop from "../../validator5K_admin/components/TextCrop";
import {
  FilterTypes,
  djangoPaginationKey,
  djangoPaginationSizeKey,
} from "../../../components/tables/constants";
import { dateTimeFormatter, dedupeCSVString } from "../constants";
import { useTableSchemaState } from "../../../components/tables/Table";
import { alertsTableGlobalState } from "../globalState";
import { transformAlertsData } from "../dataConverters";
import { TableFilterableEditableRestful } from "./TableFilterableRestful";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";
import { renderTableColumnsSpecs } from "../../private_index/components/ExtendedRestfulTables";
import { TickerContentLoader } from "../../../components/lib/TickerLoader";
import { RefreshRequestId } from "../../../utils/hooks";

import type { FetchAPIResponse } from "../../../types/fetch";
import type { DjangoPaginatedResponse } from "../../../types/django";
import type { AlertsTableDataStateObject } from "../globalState";
import type { TableFilterableRestfulProps } from "../../../components/tables/TableFilterableRestful";
import type { TableColumnSpecsObject, AlertDataMap, AlertDataObject } from "../types";
import {
  DataProviderRestful,
  FiltersDataProviderRestful,
} from "../../../components/tables/types";
import { useVal5KAdminContext } from "../context/Val5KAdminContext";

const alertsTableSpecs: TableColumnSpecsObject<AlertDataMap>[] = [
  {
    type: "column",
    uniqueKey: "created",
    title: "Created At",
    getter: (row) => row.get("created"),
    formatter: (value) => dateTimeFormatter(value),
    filterType: FilterTypes.DATES_RANGE,
    filterExtraProps: {
      datesFormat: moment.defaultFormat,
    },
  },
  {
    type: "column",
    uniqueKey: "email",
    title: "Sent To",
    getter: (row) => row.get("email"),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "countries_string",
    title: "Found Surveys For",
    getter: (row) => row.get("countries_string"),
    formatter: (value) => (
      <TextCrop
        mode="tooltip"
        title="Alert About New Surveys For Following Countries"
        text={dedupeCSVString(value) ?? ""}
        emptyStub=""
      />
    ),
    filterType: FilterTypes.VALUES_ICONTAINS,
  },
  {
    type: "column",
    uniqueKey: "text",
    title: "Text",
    getter: (row) => row.get("text"),
    formatter: (value) => (
      <TextCrop
        mode="modal"
        title="Alert Text"
        hint="Click in order to see full alert text"
        text={value}
        emptyStub=""
      />
    ),
    filterType: FilterTypes.VALUES_ICONTAINS,
  },
];

interface AlertsTableProps extends Omit<TableFilterableRestfulProps, "schema"> {}

const AlertsTable = (props: AlertsTableProps) => {
  const [schema] = useTableSchemaState(props.children);
  return (
    <Box css={{ position: "relative" }}>
      <TableFilterableEditableRestful {...props} schema={schema} />
    </Box>
  );
};
AlertsTable.displayName = "AlertsTable";

type AlertsTableViewProps = {
  refreshRequestId: RefreshRequestId;
};

const AlertsTableView = (props: AlertsTableViewProps) => {
  const { refreshRequestId } = props;
  const { fetchArgusFilteringAPI, showModalError } = useVal5KAdminContext();

  // table state

  const [alertsTableState, setAlertsTableState] =
    useRecoilState<AlertsTableDataStateObject>(alertsTableGlobalState);
  const { loaded, ...restTableState } = alertsTableState;

  // table data sources

  const fetchAlertsData: DataProviderRestful<AlertDataMap> = useCallback(
    async (urlQuery = {}, filtersQuery = {}, nextStateUpdates = {}) => {
      try {
        const response: FetchAPIResponse<DjangoPaginatedResponse<AlertDataObject>> =
          await fetchArgusFilteringAPI(`alerts/filtered/`, {
            params: urlQuery,
            data: filtersQuery,
          });

        const nextDataState: Partial<AlertsTableDataStateObject> = transformAlertsData(
          response.data,
          nextStateUpdates
        );

        setAlertsTableState((prevDataState) => ({
          ...prevDataState,
          ...nextDataState,
          loaded: true,
        }));

        return nextDataState;
      } catch (err: any) {
        logAsyncOperationError("fetchAlertsData", err);
        showModalError(
          "Error occurred while loading alerts list. Please, try again later."
        );
        throw err;
      }
    },
    [setAlertsTableState, fetchArgusFilteringAPI, showModalError]
  );

  const fetchAlertsFiltersData: FiltersDataProviderRestful = useCallback(
    async (urlQuery = {}, filtersQuery = {}) => {
      try {
        const response: FetchAPIResponse<DjangoPaginatedResponse<any>> =
          await fetchArgusFilteringAPI(`alerts/values/filtered/`, {
            params: urlQuery,
            data: filtersQuery,
          });
        return response.data;
      } catch (err: any) {
        logAsyncOperationError("fetchAlertsFiltersData", err);
        showModalError(
          "Error occurred while loading filter values. Please, try again later."
        );
        throw err;
      }
    },
    [fetchArgusFilteringAPI, showModalError]
  );

  // utility functions

  const refreshAlertsData = useCallback(async () => {
    return fetchAlertsData(
      {
        [djangoPaginationKey]: alertsTableState.activePage,
        [djangoPaginationSizeKey]: alertsTableState.itemsPerPage,
      },
      alertsTableState.filtersQuery.toJS()
    );
  }, [
    alertsTableState.filtersQuery,
    alertsTableState.activePage,
    alertsTableState.itemsPerPage,
    fetchAlertsData,
  ]);

  // initial data fetch / refresh

  useEffect(() => {
    refreshAlertsData();
  }, [refreshRequestId, refreshAlertsData]);

  // rendering

  if (!loaded) {
    return (
      <Box css={{ minHeight: "200px", position: "relative" }}>
        <TickerContentLoader />
      </Box>
    );
  }

  const columns = renderTableColumnsSpecs(alertsTableSpecs);

  return (
    <AlertsTable
      multimode
      bodyEmptyText="No data"
      disablePagination={false}
      dataProvider={fetchAlertsData}
      filtersDataProvider={fetchAlertsFiltersData}
      {...restTableState}
    >
      {columns}
    </AlertsTable>
  );
};
AlertsTableView.displayName = "AlertsTableView";

export default AlertsTableView;
