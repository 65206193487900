import React from "react";

import { styled } from "../../../stitches.config";
import { TH as BaseTH, TR } from "./TableHeadImpl";

const TH = styled(BaseTH, {
  backgroundColor: "$primaryLightest",
  textAlign: "left",
});

export const TableGroupRowImpl = (props: React.ComponentProps<typeof TR>) => (
  <TR {...props} />
);
TableGroupRowImpl.displayName = "TableGroupRowImpl";

export const TableGroupCellImpl = (props: React.ComponentProps<typeof TH>) => (
  <TH {...props} />
);
TableGroupCellImpl.displayName = "TableGroupCellImpl";
