/**
 * @generated SignedSource<<ed3a01baef6660333e46f61aa4e828c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AuthorFiltersInput = {
  clientId?: string | null;
  emailIContains?: string | null;
  exclude?: ReadonlyArray<string | null> | null;
  firstNameIContains?: string | null;
  isActive?: boolean | null;
  lastNameIContains?: string | null;
  only?: ReadonlyArray<string | null> | null;
  usernameIContains?: string | null;
};
export type FilterAndSortCreatedByFetchAllQuery$variables = {
  filters?: AuthorFiltersInput | null;
};
export type FilterAndSortCreatedByFetchAllQuery$data = {
  readonly viewer: {
    readonly authors: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly userId: number;
        } | null;
      } | null>;
    } | null;
  } | null;
};
export type FilterAndSortCreatedByFetchAllQuery = {
  response: FilterAndSortCreatedByFetchAllQuery$data;
  variables: FilterAndSortCreatedByFetchAllQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "filters",
            "variableName": "filters"
          },
          {
            "kind": "Literal",
            "name": "section",
            "value": "ADMIN"
          }
        ],
        "concreteType": "AuthorNodeConnection",
        "kind": "LinkedField",
        "name": "authors",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthorNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AuthorNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "userId",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FilterAndSortCreatedByFetchAllQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FilterAndSortCreatedByFetchAllQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d8fceaa252cf2bf65557725dc1ff801d",
    "id": null,
    "metadata": {},
    "name": "FilterAndSortCreatedByFetchAllQuery",
    "operationKind": "query",
    "text": "query FilterAndSortCreatedByFetchAllQuery(\n  $filters: AuthorFiltersInput\n) {\n  viewer {\n    authors(filters: $filters, section: ADMIN) {\n      edges {\n        node {\n          id\n          userId\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "32059bc326a6eab70a22024c0189cfdb";

export default node;
