// @flow

import { action } from "mobx";
import moment from "moment";

export default class RegionList {
  store: Object;
  id: number;
  regionId: ?number;
  name: ?string;
  created: any;
  createdDisplay: ?string;
  country: ?Object;
  countryName: ?string;
  countryCode: ?string;
  countryId: ?number;
  user: ?Object;
  owner: ?string;
  tags: ?Object;
  shared: ?boolean;
  searchCount: number; // number of searches with this region
  viewState: ?{
    editing: boolean,
    selected: boolean,
  };
  toggleEdit: () => void;
  toggleSelected: (SyntheticEvent<HTMLElement>, ?any, ?any, ?boolean) => void;
  destroy: () => void;
  toString: () => string;

  constructor(store: Object, object: Object) {
    this.store = store;
    this.regionId = null;
    this.country = null;
    this.name = null;
    this.created = null;
    this.createdDisplay = null;
    this.user = null;
    this.countryName = null;
    this.countryCode = null;
    this.countryId = null;
    this.owner = null;
    this.tags = null;
    this.shared = null;
    this.searchCount = 0;
    // providing options for selecting and editing
    this.viewState = {
      selected: false,
      editing: false,
    };

    if (object) {
      if (!object.regionId) throw new Error("Cannot instantiate Region without an ID!");
      this.id = object.regionId;
      this.regionId = object.regionId || null;
      this.country = object.country || null;
      this.name = object.name || null;
      this.created = moment(object.created) || null;
      this.createdDisplay = this.created
        ? `Created ${this.created.format("MMMM D, YYYY")}`
        : "";
      this.user = object.user || {};
      this.countryName = object.country.name || null;
      this.owner = object.user
        ? `${object.user.firstName} ${object.user.lastName}`
        : null;
      this.tags = object.tags || {};
      this.shared = object.isShared || null;

      if (this.country) {
        this.countryName = this.country.name;
        this.countryCode = this.country.isoCode;
        this.countryId = this.country.locationId;
      }

      if (object.searches && object.searches.totalCount) {
        this.searchCount = object.searches.totalCount;
      }
    }

    this.toggleEdit = action(this.toggleEdit.bind(this));
    this.toggleSelected = action(this.toggleSelected.bind(this));
    this.destroy = action(this.destroy.bind(this));
  }

  toggleEdit() {
    const viewState = this.store.regionsListViewState.get(this.regionId);
    viewState.editing = !viewState.editing;
  }

  toggleSelected(
    e: SyntheticEvent<HTMLElement>,
    foo?: any = null,
    bar?: any = null,
    setValue?: ?boolean = null
  ) {
    // NOTE: ignore foo and bar arguments
    const viewState = this.store.regionsListViewState.get(this.regionId);

    if (setValue !== null) {
      viewState.selected = setValue;
      return;
    } else {
      viewState.selected = !viewState.selected;

      if (!viewState.selected && this.store.allSelected) {
        this.store.allSelected = false;
      }
    }

    if (this.regionId) {
      if (viewState.selected === false) {
        this.store.unSelectedRegions.push(this.regionId.toString());
      } else {
        for (let i = 0; i < this.store.unSelectedRegions.length; i++) {
          if (this.store.unSelectedRegions[i] === this.regionId) {
            this.store.unSelectedRegions.splice(i, 1);
          }
        }
      }
    }

    if (!this.store.allowMultipleItemSelection) {
      // deselect all other jobs
      this.store.regionsListViewState.forEach((viewState) => {
        if (this.viewState === viewState) return;

        viewState.selected = false;
      });
    }
  }

  destroy() {
    this.store.regionLists.remove(this);
  }

  toJS() {
    return {
      regionId: this.regionId,
      country: this.country,
      name: this.name,
    };
  }

  toString() {
    return this.name || "<-- Unknown Name -->";
  }

  static fromJS(store: Object, object: Object) {
    return new RegionList(store, object);
  }
}
