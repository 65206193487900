import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { styled, CSS } from "../../stitches.config";
import * as React from "react";
import Icon from "./Icon";
import Box from "./Box";
import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

export type CheckedState = CheckboxPrimitive.CheckedState;

export const Checkbox = styled(CheckboxPrimitive.Root, {
  all: "unset",
  backgroundColor: "$primaryLight",
  width: "1em",
  height: "1em",
  borderRadius: 2,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "$accent",
  },
  '&[data-state="checked"]': {
    backgroundColor: "$accent",
  },
  "&:disabled": {
    backgroundColor: "$primaryLight",
    cursor: "not-allowed",
  },
});
Checkbox.displayName = "Checkbox";

export const CheckboxIndicator = styled(CheckboxPrimitive.Indicator, {
  color: "white",
  width: "0.75em !important",
  height: "0.75em !important",
  svg: {
    width: "0.75em !important",
    height: "0.75em !important",
    verticalAlign: "0.5em !important",
  },
});
CheckboxIndicator.displayName = "CheckboxIndicator";

export const Label = styled("label", {
  color: "$text",
  fontSize: "16px",
  fontWeight: "normal",
  lineHeight: 1,
  marginBottom: 0,
  userSelect: "none",
  cursor: "pointer",

  variants: {
    disabled: {
      true: {
        color: "$primary",
        cursor: "not-allowed",
      },
    },
    bold: {
      true: {
        fontWeight: "$semibold",
      },
    },
  },

  defaultVariants: {
    disabled: false,
    bold: false,
  },
});
Label.displayName = "Label";

export const LabelInline = styled(Label, {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexWrap: "wrap",
  gap: "$3",

  variants: {
    nowrap: {
      true: {
        flexWrap: "nowrap",
        whiteSpace: "nowrap",
      },
    },
  },
});
LabelInline.displayName = "LabelInline";

export interface CheckboxItemProps extends CheckboxPrimitive.CheckboxProps {
  icon?: FontAwesomeIconProps["icon"];
  css?: CSS;
}

export const CheckboxItem = (props: CheckboxItemProps) => {
  const {
    children,
    id,
    css,
    icon = "check",
    checked,
    disabled = false,
    onCheckedChange,
  } = props;

  return (
    <LabelInline nowrap css={css} disabled={disabled} htmlFor={id}>
      <Checkbox
        id={id}
        onCheckedChange={onCheckedChange}
        checked={checked}
        disabled={disabled}
      >
        <CheckboxIndicator>
          <Icon icon={icon} />
        </CheckboxIndicator>
      </Checkbox>
      <Box>{children}</Box>
    </LabelInline>
  );
};
CheckboxItem.displayName = "CheckboxItem";
