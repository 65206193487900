import * as React from "react";
import { styled } from "../../stitches.config";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import {
  slideDownAndFade,
  slideLeftAndFade,
  slideRightAndFade,
  slideUpAndFade,
} from "./Animations";

const StyledContent = styled(TooltipPrimitive.Content, {
  borderRadius: 4,
  padding: "10px 15px",
  fontSize: "$base",
  lineHeight: "$sm",
  color: "$textLight",
  backgroundColor: "$secondary",
  boxShadow:
    "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
  "@media (prefers-reduced-motion: no-preference)": {
    animationDuration: "400ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    willChange: "transform, opacity",
    '&[data-state="delayed-open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
  variants: {
    light: {
      true: {
        backgroundColor: "$white",
        color: "$primary",
        border: "solid 1px $primaryDark",
      },
      false: {},
    },
  },
  defaultVariants: {
    light: false,
  },
});

const StyledArrow = styled(TooltipPrimitive.Arrow, {
  fill: "$secondary",
});

// Exports
export const TooltipProvider = TooltipPrimitive.Provider;
export const TooltipRoot = TooltipPrimitive.Root;
export const TooltipTrigger = TooltipPrimitive.Trigger;
export const TooltipContent = StyledContent;

type RootProps = React.ComponentPropsWithoutRef<typeof TooltipRoot>;
type ContentProps = Omit<
  React.ComponentPropsWithoutRef<typeof TooltipContent>,
  "asChild" | "content"
>;
type ArrowProps = Omit<React.ComponentPropsWithoutRef<typeof StyledArrow>, "asChild">;
type TooltipProps = {
  content: any;
  arrowProps?: ArrowProps;
  contentProps?: ContentProps;
};

export type Props = React.PropsWithChildren<TooltipProps> & RootProps & ContentProps;

const Tooltip = (props: Props) => {
  const {
    children,
    content,
    defaultOpen,
    open,
    onOpenChange,
    delayDuration = 100,
    "aria-label": ariaLabel,
    portalled,
    side,
    sideOffset = 5,
    align,
    alignOffset,
    avoidCollisions,
    collisionTolerance,
    arrowProps = {},
    contentProps = {},
  } = props;

  return (
    <TooltipRoot
      defaultOpen={defaultOpen}
      open={open}
      onOpenChange={onOpenChange}
      delayDuration={delayDuration}
    >
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <StyledContent
        aria-label={ariaLabel}
        portalled={portalled}
        side={side}
        sideOffset={sideOffset}
        align={align}
        alignOffset={alignOffset}
        avoidCollisions={avoidCollisions}
        collisionTolerance={collisionTolerance}
        {...contentProps}
      >
        {content}
        <StyledArrow {...arrowProps} />
      </StyledContent>
    </TooltipRoot>
  );
};

export default Tooltip;
