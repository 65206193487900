// @flow

import { extendObservable } from "mobx";
import { LEVEL_ROMAN_NUMERAL } from "../constants/levels";

export default class PunchOutRow {
  id: number;
  legacyId: number;
  levelId: number;
  level: string;
  levelName: string;
  levelRomanNumeral: string;
  payRateMin: ?number;
  payRateMax: ?number;
  markupPct: ?number;
  billRateMin: ?number;
  billRateMax: ?number;
  salaryMin: ?number;
  salaryMax: ?number;

  constructor(object: Object) {
    this.id = object.legacyId || object.punchoutId || object.id;
    this.legacyId = object.legacyId || object.punchoutId || object.id;
    this.levelId = object.levelId;
    this.level = object.level;
    this.levelName = object.levelName;
    this.levelRomanNumeral =
      object.levelRomanNumeral || LEVEL_ROMAN_NUMERAL[object.levelName];

    extendObservable(this, {
      payRateMin: object.payRateMin || null,
      payRateMax: object.payRateMax || null,
      markupPct: object.markupPct || null,
      billRateMin: object.billRateMin || null,
      billRateMax: object.billRateMax || null,
      salaryMin: object.salaryMin || null,
      salaryMax: object.salaryMax || null,
    });
  }
}
