import React from "react";
import { fromJS } from "immutable";
import moment from "moment";

import Icon from "../../../../components/lib/Icon";
import { FilterTypes } from "../../../../components/tables/types";
import TextCrop from "../../../validator5K_admin/components/TextCrop";
import Text from "../../../../components/lib/Text";
import { DATE_TIME_FORMAT, decimalFormatter, dedupeCSVString } from "../../constants";
import { PAID_TYPES_OPTIONS } from "../../types";

import type { AttemptDataMap, TableColumnSpecsObject } from "../../types";

export const surveysHistoryTableColumns: TableColumnSpecsObject<AttemptDataMap>[] = [
  {
    type: "column",
    uniqueKey: "completed_timestamp",
    title: "Completed At",
    getter: (row) => row.get("completed_timestamp"),
    formatter: (value) => (value ? moment.utc(value).format(DATE_TIME_FORMAT) : ""),
    filterType: FilterTypes.DATES_RANGE,
  },
  {
    type: "column",
    uniqueKey: "review__title",
    title: "Title",
    getter: (row) => row.getIn(["review", "title"]),
    formatter: (value) => {
      return (
        <TextCrop
          mode="tooltip"
          title="Title"
          text={dedupeCSVString(value)}
          emptyStub=""
        />
      );
    },
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "review__countries_string",
    title: "Country",
    getter: (row) => row.getIn(["review", "countries_string"]),
    formatter: (value) => (
      <TextCrop
        mode="tooltip"
        title="Country"
        text={dedupeCSVString(value)}
        emptyStub=""
      />
    ),
    filterType: FilterTypes.VALUES_CHECKLIST,
    popupSize: "wide",
  },
  {
    type: "column",
    uniqueKey: "review__states_string",
    title: "State",
    getter: (row) => row.getIn(["review", "states_string"]),
    formatter: (value) => (
      <TextCrop mode="tooltip" title="State" text={dedupeCSVString(value)} emptyStub="" />
    ),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "review__cities_string",
    title: "City",
    getter: (row) => row.getIn(["review", "cities_string"]),
    formatter: (value) => (
      <TextCrop mode="tooltip" title="City" text={dedupeCSVString(value)} emptyStub="" />
    ),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "review__industries_string",
    title: "Industries",
    getter: (row) => row.getIn(["review", "industries_string"]),
    formatter: (value) => (
      <TextCrop
        mode="tooltip"
        title="Industries"
        text={dedupeCSVString(value)}
        emptyStub=""
      />
    ),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "review__categories_string",
    title: "Categories",
    getter: (row) => row.getIn(["review", "categories_string"]),
    formatter: (value) => (
      <TextCrop
        mode="tooltip"
        title="Categories"
        text={dedupeCSVString(value)}
        emptyStub=""
      />
    ),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "total_reward",
    title: "Reward",
    getter: (row) => row.get("total_reward"),
    formatter: (value) => (value ? decimalFormatter(value, false, "$") : "No Reward"),
    filterType: FilterTypes.NUMBERS_RANGE,
  },
  {
    type: "column",
    uniqueKey: "considered_as_paid",
    title: "Paid",
    getter: (row) => row.get("considered_as_paid"),
    formatter: (value) =>
      !!value ? (
        <Text color="positive">
          Yes <Icon icon="check" />
        </Text>
      ) : null,
    sortable: false,
    filterType: FilterTypes.ENUMERATION,
    filterOptions: fromJS(PAID_TYPES_OPTIONS),
  },
];
