import graphql from "babel-plugin-relay/macro";
import { debounce } from "lodash";
import React, { MouseEventHandler, useCallback, useEffect, useState } from "react";
// LIB
import Box from "../../../components/lib/Box";
import Icon from "../../../components/lib/Icon";
import { SimpleLink } from "../../../components/lib/Link";
import { MultiSelect } from "../../../components/lib/MultiSelect";
import Tag from "../../../components/lib/Tag";
import Tooltip from "../../../components/lib/Tooltip";
import { Selected, Selections } from "./styled";
// relay
import { useLazyRelayQuery } from "../../../hooks/useRelayQuery";
import { LocationsFieldQuery } from "./__generated__/LocationsFieldQuery.graphql";
// compoenents
import { useRateSearchFormStore } from "../../../stores/RateSearchForm";
import { isNonNullable } from "../../../utils/hashmap";
import RsLocationSearchDialog from "../components/RsLocationSearchDialog";
import { jobTitleObserver, onLocationsOrIndustriesChange } from "../observers";
import { Location } from "../types";

function getLocationLabel(location: Location, isSideMenu: boolean) {
  if (isSideMenu) {
    return location.fullTitle.split(",")[0];
  }
  return location?.fullSubtitle
    ? `${location?.fullTitle}, ${location?.fullSubtitle}`
    : location.fullTitle!;
}

const gql = graphql`
  query LocationsFieldQuery($searchParam: String!, $countryIds: [Int]) {
    viewer {
      locations(search: $searchParam, countryIds: $countryIds) {
        fullTitle @required(action: NONE)
        fullSubtitle
        countryId
        locationId @required(action: NONE)
      }
    }
  }
`;

type Props = {
  isSideMenu?: boolean;
  shouldClear?: boolean;
  locations: Location[];
  disabledLocations?: Location[];
  setLocations(loc: Location[]): void;
  hasRegions: boolean;
};

export function LocationsField(props: Props) {
  const {
    isSideMenu = false,
    shouldClear = false,
    locations,
    setLocations,
    hasRegions,
    disabledLocations = [],
  } = props;
  const [options, setOptions] = useState<Location[]>([]);
  const industries = useRateSearchFormStore((s) => s.industries);
  const jobCountries = useRateSearchFormStore((s) => s.getSupportedCountries());

  /* Reverted */
  // const { isClientJobLibrary } = useGlobalContext();

  // const uncertifiedLocations = locations.filter(
  //   ({ countryId }) => !(countryId && jobCountries?.certified.includes(countryId))
  // );
  // const uncertifiedErrorMsg =
  //   isClientJobLibrary && uncertifiedLocations?.length > 0
  //     ? `Only certified locations are allowed, please remove the following: ${uncertifiedLocations
  //         ?.map(({ fullTitle }) => fullTitle)
  //         .join(", ")}`
  //     : undefined;

  const saveLocations = useCallback(
    (locations: Location[]) => {
      onLocationsOrIndustriesChange.emit({ locations, industries });
      setLocations(locations);
    },
    [setLocations, industries]
  );

  const clear = useCallback(() => {
    saveLocations([]);
    setOptions([]);
  }, [saveLocations]);

  function onSelect(location: Location | Location[]) {
    if (Array.isArray(location)) {
      saveLocations(location);
      return;
    }
    if (location && !locations.some((l) => l.locationId === location.locationId)) {
      saveLocations([...locations, location]);
    }
  }

  function unselect(location: Location): MouseEventHandler<HTMLButtonElement> {
    return (e) => {
      e.stopPropagation();
      const newSelections = locations.filter((l) => l.locationId !== location.locationId);
      saveLocations(newSelections);
      if (newSelections.length === 0) {
        setOptions([]);
      }
    };
  }

  // !important: Unable to useRefetchableFragment, no useTransition available.
  const { send, loading } = useLazyRelayQuery<LocationsFieldQuery>({
    gql,
    onComplete(data) {
      setOptions(data?.viewer?.locations?.filter(isNonNullable) || []);
    },
  });

  function onInputValueChange(searchParam: string) {
    if (searchParam) {
      send({ searchParam, countryIds: jobCountries.all });
    }
  }

  useEffect(() => {
    function listener() {
      if (shouldClear) clear();
    }
    const unsubscribe = jobTitleObserver.subscribe(listener);
    return () => unsubscribe();
  }, [shouldClear, clear]);

  return (
    <Box>
      <MultiSelect
        id="locationSelect"
        icon="map-marker-alt"
        loading={loading}
        disabled={hasRegions}
        disabledOptions={disabledLocations}
        onInputValueChange={debounce(onInputValueChange, 250)}
        selected={locations}
        options={options}
        onSelect={onSelect}
        onClearSelections={clear}
        required
        label="Location"
        placeholder="Select an location"
        getKey={(o) => o.locationId!}
        renderOption={(o) => (
          <>
            {getLocationLabel(o, false)}
            <span>
              {jobCountries.certified.includes(o.countryId!) && (
                <Tag
                  icon="globe-americas"
                  text="Certified"
                  size="extra-small"
                  color="blue"
                />
              )}
              {jobCountries.adhoc.includes(o.countryId!) && (
                <Tag
                  icon="globe-americas"
                  text="Noncertified"
                  color="red"
                  size="extra-small"
                />
              )}
            </span>
          </>
        )}
        renderAddon={(opts) => (
          <Selections>
            {opts.map((o) => (
              <Selected
                key={o.locationId}
                onClick={unselect(o)}
                title={`Unselect ${o.fullTitle}, ${o.fullSubtitle}`}
              >
                <span>{getLocationLabel(o, isSideMenu)}</span>
                <Icon size="sm" icon="close" />
              </Selected>
            ))}
          </Selections>
        )}
        extraLabel={
          <RsLocationSearchDialog
            locations={locations}
            certifiedCountries={jobCountries.certified}
            adhocCountries={jobCountries.adhoc}
            onLocationChange={onSelect}
          >
            <Tooltip
              contentProps={{ style: { minWidth: "300px", maxWidth: "300px" } }}
              content='Selecting "Advanced" displays the Countries that the Job Title is available to search. Selecting the Country will then list the locations for selection.'
            >
              <SimpleLink>
                Advanced <Icon icon="question-circle" />
              </SimpleLink>
            </Tooltip>
          </RsLocationSearchDialog>
        }
      />

      {/* Reverted */}
      {/* {uncertifiedErrorMsg && <Text color="negative">{uncertifiedErrorMsg}</Text>} */}
    </Box>
  );
}
