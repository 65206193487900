import React, { useCallback, useRef } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { fromJS } from "immutable";

import { styled } from "../../../stitches.config";
import { usePLIContext } from "../context";

import { TableFilterableRestfulProps } from "../../../components/tables/TableFilterableRestful";
import { useTableSchemaState } from "../../../components/tables/Table";
import { Column } from "../../../components/tables";

import Button from "../../../components/lib/Button";
import PromiseButton from "../../../components/lib/PromiseButton";
import { ButtonGroup } from "../../../components/lib/ButtonGroup";
import Inline from "../../../components/lib/Inline";
import Alert from "../../../components/lib/Alert";
import Text from "../../../components/lib/Text";
import Box from "../../../components/lib/Box";

import { djangoPaginationKey, djangoPaginationSizeKey } from "../../../constants";
import { emptyList, FilterTypes } from "../../../components/tables/constants";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";

import { TickerContentLoader } from "../../../components/lib/TickerLoader";
import Tooltip from "../lib/Tooltip";

import {
  PROGRAM_STATUSES,
  PROGRAM_STATUSES_LABELS,
  PROGRAM_ACCESS_STATUSES,
  PROGRAM_STATUSES_TYPE,
} from "../types";
import { programGlobalState, programsTableGlobalState } from "../globalState";
import { transformProgramsData } from "../dataConverters";
import { useScrollTo } from "../hooks";
import { PROGRAM_STATUSES_OPTIONS } from "../constants";

import { renderTableColumnsSpecs, TableFilterableRestful } from "./ExtendedRestfulTables";

import type {
  ProgramDataMap,
  ProgramDataObject,
  ProgramAccessDataMap,
  TableColumnSpecsObject,
} from "../types";
import type {
  DataProviderRestful,
  FiltersDataProviderRestful,
  RestfulTableDataStateObject,
} from "../../../components/tables/types";
import type { ImmutableList } from "../../../types/immutable";
import type { FetchAPIResponse } from "../../../types/fetch";
import type { DjangoPaginatedResponse } from "../../../types/django";

const Logo = styled("img", {
  border: "1px solid $primaryLight",
  borderRadius: "$sm",
  maxWidth: "90px",
  height: "auto",
  maxHeight: "30px",

  variants: {
    preview: {
      false: {
        maxWidth: "180px",
        maxHeight: "60px",
        border: "none",
      },
    },
  },

  defaultVariants: {
    preview: false,
  },
});

export const programsTableColumnsSpecs: TableColumnSpecsObject<ProgramDataMap>[] = [
  {
    type: "column",
    uniqueKey: "icon",
    title: "Logo",
    getter: (row) => row.get("icon"),
    formatter: (value) => {
      return value ? (
        <Tooltip
          side="right"
          content={<Logo src={value} alt="index icon" />}
          arrowProps={{ width: 0 }}
          contentProps={{
            css: { padding: 0, borderRadius: 0, backgroundColor: "$white" },
          }}
        >
          <Logo preview src={value} alt="index icon" />
        </Tooltip>
      ) : null;
    },
    css: { minWidth: "100px", maxWidth: "150px" },
    headCss: { width: "50px", padding: "5px" },
    sortable: false,
    filterable: false,
  },
  {
    type: "column",
    uniqueKey: "title",
    title: "Name",
    getter: (row) => row.get("title"),
    formatter: (value) => (
      <Tooltip side="top" content={value}>
        <span>{value}</span>
      </Tooltip>
    ),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "status",
    title: "Status",
    getter: (row: ProgramDataMap) => row.get("status"),
    formatter: (value: PROGRAM_STATUSES_TYPE) => (
      <Text
        color={
          value === PROGRAM_STATUSES.ACTIVE
            ? "positive"
            : value === PROGRAM_STATUSES.INACTIVE
            ? "negative"
            : undefined
        }
      >
        {PROGRAM_STATUSES_LABELS[value]}
      </Text>
    ),
    filterType: FilterTypes.ENUMERATION,
    filterOptions: fromJS(PROGRAM_STATUSES_OPTIONS),
    sortable: false,
    css: { width: "100px" },
    headCss: { width: "100px" },
  },
];

type ProgramsTableProps = Omit<TableFilterableRestfulProps, "schema">;

export const ProgramsTable = (props: ProgramsTableProps) => {
  const [schema] = useTableSchemaState(props.children);
  return <TableFilterableRestful {...props} schema={schema} />;
};

ProgramsTable.displayName = "ProgramsTable";
ProgramsTable.defaultProps = {
  ...TableFilterableRestful.defaultProps,
  itemsPerPage: 10,
};

const AccessRequestCounter = styled("span", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  right: "-$2",
  top: "-$2",
  minWidth: "$5",
  height: "$5",
  borderRadius: "$full",
  border: "1px solid $textLight",
  fontSize: "$xs",
  fontWeight: "$semibold",
  background: "$danger",
  color: "$textLight",
});

type ProgramsTableRowActionsProps = {
  row: ProgramDataMap;
  refreshProgramsData: () => void;
};

const ProgramsTableRowActions = (props: ProgramsTableRowActionsProps) => {
  const { row, refreshProgramsData } = props;

  const {
    router,
    userId,
    isClientAdmin,
    isPTAdmin,
    isRegularUser,
    fetchM8API,
    showModalError,
  } = usePLIContext();

  const programId = row.get("id");
  const access = row.get("access") ?? emptyList;
  const status = row.get("status");

  let approvedRequestsForUser = emptyList as ImmutableList<ProgramAccessDataMap>;
  let declinedRequestsForUser = emptyList as ImmutableList<ProgramAccessDataMap>;
  let pendingRequestsForUser = emptyList as ImmutableList<ProgramAccessDataMap>;
  let pendingRequestsForProgram = emptyList as ImmutableList<ProgramAccessDataMap>;
  let userHasNoAccessRequests = true;
  let userHasApprovedAccessRequests = false;
  let userHasDeclinedAccessRequests = false;
  let programHasPendingAccessRequests = false;
  let userHasPendingAccessRequests = false;

  if (access.size > 0) {
    approvedRequestsForUser = access.filter(
      (item) =>
        item.get("user_id") === userId &&
        item.get("status") === PROGRAM_ACCESS_STATUSES.APPROVED
    );
    declinedRequestsForUser = access.filter(
      (item) =>
        item.get("user_id") === userId &&
        item.get("status") === PROGRAM_ACCESS_STATUSES.DECLINED
    );
    pendingRequestsForProgram = access.filter(
      (item) => item.get("status") === PROGRAM_ACCESS_STATUSES.PENDING
    );
    pendingRequestsForUser = pendingRequestsForProgram.filter(
      (item) => item.get("user_id") === userId
    );

    if (approvedRequestsForUser.size > 0) userHasApprovedAccessRequests = true;
    if (declinedRequestsForUser.size > 0) userHasDeclinedAccessRequests = true;
    if (pendingRequestsForProgram.size > 0) programHasPendingAccessRequests = true;
    if (pendingRequestsForUser.size > 0) userHasPendingAccessRequests = true;
    if (
      userHasApprovedAccessRequests ||
      userHasDeclinedAccessRequests ||
      userHasPendingAccessRequests
    ) {
      userHasNoAccessRequests = false;
    }
  }

  const setProgramDetailsGlobalState = useSetRecoilState(programGlobalState);

  const handleGoToProgramEditor = useCallback(() => {
    setProgramDetailsGlobalState({
      programData: row,
      programDataLoaded: false,
    });
    router.push(`/private-index/programs/${programId}/edit`);
  }, [row, setProgramDetailsGlobalState, programId, router]);

  const handleGoToPermissionsEditor = useCallback(() => {
    setProgramDetailsGlobalState({
      programData: row,
      programDataLoaded: false,
    });
    router.push(`/private-index/programs/${programId}/edit-permissions`);
  }, [row, setProgramDetailsGlobalState, programId, router]);

  const handleGoToProgramDetails = useCallback(() => {
    setProgramDetailsGlobalState({
      programData: row,
      programDataLoaded: false,
    });
    router.push(`/private-index/programs/${programId}`);
  }, [row, setProgramDetailsGlobalState, programId, router]);

  const handleRequestAccess = useCallback(async () => {
    try {
      await fetchM8API(`programs/${programId}/access_list/`, { method: "post" });
      refreshProgramsData();
    } catch (err: any) {
      logAsyncOperationError("createProgramAccessRequest", err);
      showModalError("Error occurred while requesting access to a program");
    }
  }, [refreshProgramsData, programId, fetchM8API, showModalError]);

  return (
    <ButtonGroup
      css={{
        justifyContent: "center",
        gap: "$1_5",
        "& button": { minWidth: "80px", width: "fit-content" },
      }}
    >
      {(isClientAdmin || isPTAdmin) && (
        <Button
          icon="users"
          color="brand"
          variant="outlined"
          onClick={handleGoToPermissionsEditor}
        >
          Users
          {programHasPendingAccessRequests && (
            <AccessRequestCounter>{pendingRequestsForProgram.size}</AccessRequestCounter>
          )}
        </Button>
      )}
      {isPTAdmin && (
        <Button
          icon="cog"
          color="brand"
          variant="outlined"
          onClick={handleGoToProgramEditor}
        >
          Settings
        </Button>
      )}
      {(isPTAdmin ||
        (status === PROGRAM_STATUSES.ACTIVE &&
          (isClientAdmin || userHasApprovedAccessRequests))) && (
        <Button
          icon="list"
          color="brand"
          variant="outlined"
          onClick={handleGoToProgramDetails}
        >
          Data
        </Button>
      )}
      {isRegularUser && userHasPendingAccessRequests && (
        <Alert color="warning" css={{ padding: "$2" }}>
          Pending approval
        </Alert>
      )}
      {isRegularUser &&
        userHasDeclinedAccessRequests &&
        !userHasPendingAccessRequests &&
        !userHasApprovedAccessRequests && (
          <Alert color="danger" css={{ padding: "$1 $2" }}>
            <Inline nowrap css={{ gap: "$2" }}>
              <span>Request declined</span>
              <PromiseButton
                icon="user-plus"
                color="danger"
                variant="outlined"
                loadingText="Request Access"
                onClick={handleRequestAccess}
              >
                Request Access
              </PromiseButton>
            </Inline>
          </Alert>
        )}
      {isRegularUser && userHasNoAccessRequests && (
        <PromiseButton
          icon="user-plus"
          color="brand"
          variant="outlined"
          loadingText="Request Access"
          onClick={handleRequestAccess}
        >
          Request Access
        </PromiseButton>
      )}
    </ButtonGroup>
  );
};

interface ProgramsTableState extends RestfulTableDataStateObject<ProgramDataMap> {
  loaded: boolean;
}

const ProgramsTableView = () => {
  const { fetchM8FilteringAPI, showModalError } = usePLIContext();

  // table state

  const [programsTableState, setProgramsTableState] = useRecoilState<ProgramsTableState>(
    programsTableGlobalState
  );

  const emptyData = !programsTableState.data || !programsTableState.data.size;
  const noFiltersApplied =
    !programsTableState.filters || !programsTableState.filters.size;
  const hasNoData = emptyData && noFiltersApplied;

  // table data sources

  const fetchProgramsData: DataProviderRestful<ProgramDataMap> = useCallback(
    async (urlQuery = {}, filtersQuery = {}, nextStateUpdates = {}) => {
      try {
        const response: FetchAPIResponse<DjangoPaginatedResponse<ProgramDataObject>> =
          await fetchM8FilteringAPI("programs/filtered/", {
            params: urlQuery,
            data: filtersQuery,
          });
        const nextDataState: Partial<ProgramsTableState> = transformProgramsData(
          response.data,
          nextStateUpdates
        );

        setProgramsTableState((prevState) => ({
          ...prevState,
          ...nextDataState,
          loaded: true,
        }));

        return nextDataState;
      } catch (err: any) {
        logAsyncOperationError("fetchProgramsList", err);
        showModalError(
          "Error occurred while retrieving programs data. Please, try again later."
        );
        throw err;
      }
    },
    [setProgramsTableState, fetchM8FilteringAPI, showModalError]
  );

  const fetchProgramsFiltersData: FiltersDataProviderRestful = useCallback(
    async (urlQuery = {}, filtersQuery = {}) => {
      try {
        const response: FetchAPIResponse<Array<any>> = await fetchM8FilteringAPI(
          "programs/values/filtered/",
          {
            params: urlQuery,
            data: filtersQuery,
          }
        );

        return response.data;
      } catch (err: any) {
        logAsyncOperationError("fetchProgramsListFilterValues", err);
        showModalError(
          "Error occurred while retrieving programs filters values. Please, try again later."
        );
        throw err;
      }
    },
    [fetchM8FilteringAPI, showModalError]
  );

  const refreshProgramsData = useCallback(() => {
    return fetchProgramsData(
      {
        [djangoPaginationKey]: programsTableState.activePage,
        [djangoPaginationSizeKey]: programsTableState.itemsPerPage,
      },
      programsTableState.filtersQuery.toJS()
    );
  }, [
    programsTableState.activePage,
    programsTableState.itemsPerPage,
    programsTableState.filtersQuery,
    fetchProgramsData,
  ]);

  // initial data fetch

  const programsTableLoadedRef = useRef<boolean>(false);

  React.useEffect(() => {
    if (!programsTableLoadedRef.current) {
      refreshProgramsData();
      programsTableLoadedRef.current = true;
    }
  }, [refreshProgramsData, programsTableLoadedRef]);

  // scroll to the top upon every page change

  const scrollToRef = useScrollTo([programsTableState.activePage]);

  // rendering

  const renderRowActions = React.useCallback(
    (value: any, row: ProgramDataMap) => (
      <ProgramsTableRowActions row={row} refreshProgramsData={refreshProgramsData} />
    ),
    [refreshProgramsData]
  );

  if (!programsTableState.loaded) {
    return (
      <Box css={{ minHeight: "200px" }}>
        <TickerContentLoader />
      </Box>
    );
  }
  if (hasNoData) {
    return (
      <Alert color="warning">
        <h5>No indexes so far</h5>
      </Alert>
    );
  }

  const columns = renderTableColumnsSpecs(programsTableColumnsSpecs).concat(
    <Column
      key="__actions"
      uniqueKey="__actions"
      title="Manage"
      formatter={renderRowActions}
      css={{ width: "300px", padding: "$2 $4" }}
      headCss={{ width: "300px" }}
    />
  );

  return (
    <Box ref={scrollToRef}>
      <ProgramsTable
        dataProvider={fetchProgramsData}
        filtersDataProvider={fetchProgramsFiltersData}
        multimode
        {...programsTableState}
      >
        {columns}
      </ProgramsTable>
    </Box>
  );
};

ProgramsTableView.displayName = "ProgramsTableView";

export default ProgramsTableView;
