import React, { useState, useMemo, useCallback } from "react";

import Inline from "../../../components/lib/Inline";
import Stack from "../../../components/lib/Stack";
import Grid from "../../../components/lib/Grid";
import Text from "../../../components/lib/Text";
import Button from "../../../components/lib/Button";
import Alert from "../../../components/lib/Alert";
import { ButtonGroupRight } from "../../../components/lib/ButtonGroup";
import { CheckboxItem } from "../../../components/lib/Checkbox";

import type { Nullable } from "../../../types/generic";
import type { Values } from "../types";
import type { CloseConfirmationModalFunc } from "../../../components/ConfirmationModal";
import PromiseButton from "../../../components/lib/PromiseButton";

type AttemptsBulkUpdateState = Nullable<{
  isReadyForPayment: boolean;
  isPaid: boolean;
  isAnalysisCompleted: boolean;
  isArchived: boolean;
}>;

type AttemptsBulkUpdateStateKeys = keyof AttemptsBulkUpdateState;

export type AttemptsBulkUpdateDataObject = Partial<{
  ready_for_payment: boolean;
  paid: boolean;
  analysis_completed: boolean;
  archived: boolean;
}>;

type AttemptsBulkUpdateFormProps = {
  itemsCount: number;
  onApply: (data: AttemptsBulkUpdateDataObject) => void;
  onCancel: CloseConfirmationModalFunc;
};

const AttemptsBulkUpdateForm = (props: AttemptsBulkUpdateFormProps) => {
  const { itemsCount, onApply, onCancel } = props;
  const isBigUpdate = itemsCount > 100;

  // state

  const [state, setState] = useState<AttemptsBulkUpdateState>({
    isReadyForPayment: null,
    isPaid: null,
    isAnalysisCompleted: null,
    isArchived: null,
  });

  // utils

  const hasChanges = useMemo(() => {
    return Object.values(state).findIndex((value) => value != null) >= 0;
  }, [state]);

  // handlers

  const handleChangeState = useCallback(
    (key: AttemptsBulkUpdateStateKeys, value: Values<AttemptsBulkUpdateState>) => {
      setState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    },
    [setState]
  );

  const handleApply = useCallback(() => {
    const data: AttemptsBulkUpdateDataObject = {};

    if (state.isReadyForPayment != null) {
      data["ready_for_payment"] = state.isReadyForPayment;
    }
    if (state.isPaid != null) {
      data["paid"] = state.isPaid;
    }
    if (state.isAnalysisCompleted != null) {
      data["analysis_completed"] = state.isAnalysisCompleted;
    }
    if (state.isArchived != null) {
      data["archived"] = state.isArchived;
    }

    return onApply(data);
  }, [state, onApply]);

  return (
    <Stack fill css={{ alignItems: "flex-start" }}>
      <Text>Use the form below to change some parameters for the list of attempts.</Text>

      {isBigUpdate && (
        <Alert fill color="warning">
          <Text>
            <Text bold underline italic>
              IMPORTANT
            </Text>
            : You are about to update{" "}
            <big>
              <b>{itemsCount}</b>
            </big>{" "}
            attempts.
          </Text>
        </Alert>
      )}

      <Grid
        css={{
          gridTemplateColumns: "repeat(2, auto)",
          gridColumnGap: "$2",
          gridRowGap: "$3",
          alignItems: "center",
          width: "$full",
        }}
      >
        <Text underline>Mark As Ready For Payment:</Text>
        <Inline nowrap>
          <CheckboxItem
            checked={state.isReadyForPayment === true}
            onCheckedChange={useCallback(
              () => handleChangeState("isReadyForPayment", true),
              [handleChangeState]
            )}
          >
            Ready For Payment
          </CheckboxItem>
          <CheckboxItem
            checked={state.isReadyForPayment === false}
            onCheckedChange={useCallback(
              () => handleChangeState("isReadyForPayment", false),
              [handleChangeState]
            )}
          >
            Not Ready For Payment
          </CheckboxItem>
        </Inline>

        <Text underline>Mark As Paid:</Text>
        <Inline nowrap>
          <CheckboxItem
            checked={state.isPaid === true}
            onCheckedChange={useCallback(
              () => handleChangeState("isPaid", true),
              [handleChangeState]
            )}
          >
            Paid
          </CheckboxItem>
          <CheckboxItem
            checked={state.isPaid === false}
            onCheckedChange={useCallback(
              () => handleChangeState("isPaid", false),
              [handleChangeState]
            )}
          >
            Not Paid
          </CheckboxItem>
        </Inline>

        <Text underline>Mark As Analysis Completed:</Text>
        <Inline nowrap>
          <CheckboxItem
            checked={state.isAnalysisCompleted === true}
            onCheckedChange={useCallback(
              () => handleChangeState("isAnalysisCompleted", true),
              [handleChangeState]
            )}
          >
            Analysis Completed
          </CheckboxItem>
          <CheckboxItem
            checked={state.isAnalysisCompleted === false}
            onCheckedChange={useCallback(
              () => handleChangeState("isAnalysisCompleted", false),
              [handleChangeState]
            )}
          >
            Not Analysis Completed
          </CheckboxItem>
        </Inline>

        <Text underline>Mark As Archived:</Text>
        <Inline nowrap>
          <CheckboxItem
            checked={state.isArchived === true}
            onCheckedChange={useCallback(
              () => handleChangeState("isArchived", true),
              [handleChangeState]
            )}
          >
            Archived
          </CheckboxItem>
          <CheckboxItem
            checked={state.isArchived === false}
            onCheckedChange={useCallback(
              () => handleChangeState("isArchived", false),
              [handleChangeState]
            )}
          >
            Not Archived
          </CheckboxItem>
        </Inline>
      </Grid>
      <ButtonGroupRight fill reverse>
        <Button color="gray" size="normal" onClick={onCancel}>
          Close
        </Button>
        <PromiseButton
          color="green"
          size="normal"
          loadingText="Apply Changes"
          disabled={!hasChanges}
          onClick={handleApply}
        >
          Apply Changes
        </PromiseButton>
      </ButtonGroupRight>
    </Stack>
  );
};
AttemptsBulkUpdateForm.displayName = "AttemptsBulkUpdateForm";

export default AttemptsBulkUpdateForm;
