// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react";
import CheckBox from "../../shared/CheckBox";
import TagItem from "./TagItem";
import {
  CardListItem,
  CardListItemLabel,
  CardListSubText,
  SubTextAccent,
} from "../../../components/lib/Card";
import LinkButton from "../../../components/lib/LinkButton";
import Tooltip from "../../../components/lib/Tooltip";
import Spacer from "../../../components/lib/Spacer";

class RateCardListItem extends Component {
  handleViewDetail: () => void;

  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.handleViewDetail = this.handleViewDetail.bind(this);
    this.deleteTag = this.deleteTag.bind(this);
    this.state = {
      updated: false,
    };
  }

  handleViewDetail() {
    this.context.router.push({
      pathname: `/ratecards/${this.props.rateCard.ratecardId}`,
      query: this.context.router.query,
    });
  }

  deleteTag(event: object, tag_id) {
    event.preventDefault();
    event.stopPropagation();
    event.cancelBubble = true;
    event.returnValue = false;

    this.props.store
      .deleteSingleTags({
        tagId: tag_id,
        contentId: this.props.rateCard.ratecardId,
      })
      .then((res) => {
        if (!res.result) return;
        this.props.rateCard.tags.forEach((item, index) => {
          if (item.tagId === tag_id) {
            this.props.rateCard.tags.splice(index, 1);
          }
        });
        this.setState({
          updated: true,
        });
      });
  }

  handleToggleSelected = (...args) => {
    // avoid executing toggle multiple times
    // wile the event bubbles up
    console.log(args, typeof args[0].preventDefault === "function");
    const e = args.find((arg) => typeof arg.preventDefault === "function");
    if (!e || e.isDefaultPrevented()) return;
    if (e) e.preventDefault();

    if (this.props.rateCard.toggleSelected) {
      this.props.rateCard.toggleSelected();
    }
  };

  render() {
    const props = this.props;

    const rateCard = props.rateCard;
    if (!rateCard) return <code>unable to render rate card item</code>;

    const editing = rateCard.viewState.editing;
    const selected = rateCard.viewState.selected;
    // console.log(`${rateCard.name}: ${selected}`);
    const name = rateCard.name;
    const createdDisplay = rateCard.createdDate.format("MMMM D, YYYY");
    // const searches = rateCard.searches;
    // const locations = rateCard.locations;
    const shared = rateCard.shared;
    const ratecardId = rateCard.ratecardId;
    const searchCount = rateCard.searchCount;
    const locationCount = rateCard.locationCount;
    let ownerName = "You";
    let sharedByYou = shared;
    const tags = rateCard.tags;
    let tagSection = null;

    if (
      String(rateCard.owner.clientId) !== String(props.store.currentUser?.userId || "")
    ) {
      ownerName = rateCard.owner.fullname;
      sharedByYou = false;
    }
    if (rateCard.owner.email !== localStorage.getItem("PeopleTickerUserEmail")) {
      ownerName = rateCard.owner.fullname;
    }

    let onItemClick = this.props.onClick || this.handleViewDetail;
    let editingSection = null;

    if (this.props.editing) {
      onItemClick = this.handleToggleSelected;
      editingSection = (
        <CheckBox
          id={`checkbox-${ratecardId}`}
          type={props.store.allowMultipleItemSelection ? "checkbox" : "radio"}
          name={`list-item-select-${ratecardId}`}
          value={name}
          selected={selected}
          style={{ width: 34 }}
          onClick={onItemClick} // causes rateCard.toggleSelected to execute multiple times
        />
      );
    }
    if (tags.length > 0 && !this.props.store.isTagView) {
      tagSection = tags.map((tag) => (
        <TagItem
          key={tag.tagId}
          tag={tag}
          onClick={this.deleteTag}
          hasOwnerShip={!sharedByYou && shared}
        />
      ));
    }

    const sharedTooltip = "Shared...";

    return (
      <CardListItem
        selected={selected}
        onClick={onItemClick}
        css={{ display: "flex", flexWrap: "wrap" }}
        role="listitem"
      >
        <div className="ratecard-list-item-left ellipsis">
          {editingSection}
          <div className="ratecard-label-container width100perc">
            <div className="ratecard-label ellipsis">
              <CardListItemLabel
                htmlFor={this.props.editing ? `checkbox-${ratecardId}` : null}
              >
                {name}
              </CardListItemLabel>
              {shared && sharedByYou && (
                <Tooltip side="top" content={sharedTooltip}>
                  <span className="tag text-x-small align-top">
                    <FontAwesomeIcon icon="share" fixedWidth className="icon" /> By You
                  </span>
                </Tooltip>
              )}
              {shared && !sharedByYou && (
                <Tooltip side="top" content={sharedTooltip}>
                  <span className="tag text-x-small align-top">
                    <FontAwesomeIcon
                      icon="share"
                      fixedWidth
                      flip="horizontal"
                      className="icon"
                    />{" "}
                    To You
                  </span>
                </Tooltip>
              )}
            </div>
            <CardListSubText>
              Created by <SubTextAccent>{ownerName}</SubTextAccent> on{" "}
              <SubTextAccent>{createdDisplay}</SubTextAccent>
            </CardListSubText>
          </div>
        </div>
        <div className="ratecard-list-item-right">
          {/*<div className="ratecard-info text-center">*/}
          {/*<header>Shared</header>*/}
          {/*<div>{ shared ? "Yes" : "No"}</div>*/}
          {/*</div>*/}
          <div className="rc-center">
            <div className="ratecard-info text-center">
              <header data-testid="searches-header-ratecard">Searches</header>
              <div>{searchCount}</div>
            </div>
            <div className="ratecard-info text-center">
              <header data-testid="locations-header-ratecard">Locations</header>
              <div>{locationCount}</div>
            </div>
            {!editing && <LinkButton to={`/ratecards/${ratecardId}`}>View</LinkButton>}
          </div>
        </div>
        <div className="tagbox">
          {editing && <Spacer css={{ flex: "0 1 auto", width: 34 }} />}
          {tagSection}
        </div>
      </CardListItem>
    );
  }
}

RateCardListItem.propTypes = {
  store: PropTypes.object.isRequired,
  rateCard: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default observer(RateCardListItem);
