// @flow
// Pagination constants

import { IconProp } from "@fortawesome/fontawesome-svg-core";

export const PAGINATION_CONSTANTS = {
  per_page: 10,
  current_page: 1,
  maximum_pages_to_show: 5,
};

// User Feeds Count

export const USER_FEEDS_COUNTS = 10;

// Industry images required in Dashboard

export const INDUSTRY_IMAGE_CONSTANTS = {
  All: "All.jpg",
  Academia: "Academia.jpg",
  "Aerospace and Defense": "Aerospace_and_Defense.jpg",
  Agricultural: "Agricultural.jpg",
  "Computers and Technology": "Computers_and_Technology.jpg",
  "Consumer Goods": "Consumer_Goods.jpg",
  Financial: "Financial.jpg",
  Government: "Government.jpg",
  Healthcare: "Healthcare.jpg",
  Insurance: "Insurance.jpg",
  Manufacturing: "Manufacturing.jpg",
  "Oil and Gas": "Oil_and_Gas.jpg",
  Pharmaceuticals: "Pharmaceuticals.jpg",
  "Power and Utilities": "Power_and_Utilities.jpg",
  Telecommunications: "Telecommunications.jpg",
  "Professional Services / Consulting": "Professional_Services_Consulting.jpg",
  Conglomerate: "Conglomerate.jpg",
};

export type AppMenuItem = {
  key: string;
  to: string;
  icon: IconProp;
  label: string;
  description: string;
  requiredRoles: string[];
  requiredClientFlags: { flag: string; value: any }[];
};

export const roleV6User = "Version 6 Users";
export const rolePTAdmin = "Admin";
export const roleClientAdmin = "Client Admin";
export const rolePSPAdmin = "Per Search Pricing Admin";
export const roleReports = "Reports";

export const CLIENT_JOB_LIBRARY_FLAG = "isClientJobLibrary";
export const PER_SEARCH_PRICING_FLAG = "perSearchPricing";

export const APPS: { [k: string]: AppMenuItem } = {
  DASHBOARD: {
    key: "HOME",
    to: "/",
    icon: "tachometer-alt",
    label: "Dashboard",
    requiredRoles: [roleV6User],
    requiredClientFlags: [],
    description: "Industry news, ratecards, and punchouts.",
  },
  RATE_SEARCH: {
    key: "RATE_SEARCH",
    to: "/rate-search",
    icon: "desktop",
    label: "Rate Search",
    requiredRoles: [roleV6User],
    requiredClientFlags: [],
    description: "Search a job and compare to other searches.",
  },
  BATCH_SEARCH: {
    key: "Batch Search",
    // TODO: Change this to batch search
    to: "/batch-searches",
    icon: "search",
    label: "Batch Search",
    requiredRoles: [roleV6User],
    requiredClientFlags: [],
    description: "Batch up many searches to run and notify you on completion.",
  },
  JOB_LIBRARY: {
    key: "JOB_LIBRARY",
    to: "/job-library/view",
    icon: "book",
    label: "Job Library",
    requiredRoles: [roleV6User],
    requiredClientFlags: [{ flag: CLIENT_JOB_LIBRARY_FLAG, value: true }],
    description: "View all your specific title and descriptions.",
  },
  RATE_CARD: {
    key: "RATE_CARD",
    to: "/ratecards",
    icon: "list-alt",
    label: "Rate Cards",
    requiredRoles: [roleV6User],
    requiredClientFlags: [],
    description: "Ratecards made or shared to you",
  },
  SAVED_SEARCHES: {
    key: "SAVED_SEARCHES",
    to: "/saved-searches",
    icon: "list",
    label: "Searches",
    requiredRoles: [roleV6User],
    requiredClientFlags: [],
    description: "Any search you've made across scheduled search or rate search.",
  },
  NEGOTIATION_WORKSHEET: {
    key: "NEGOTIATION_WORKSHEET",
    to: "/negotiation-worksheets",
    icon: "flag",
    label: "Negotiation Worksheets",
    requiredRoles: [roleV6User],
    requiredClientFlags: [],
    description: "Determine costs using our country specific worksheets.",
  },
  JOB_LABELS: {
    key: "JOBS_LABELS",
    to: "/job-labels",
    icon: "database",
    label: "Job Labels",
    requiredRoles: [roleV6User],
    requiredClientFlags: [{ flag: CLIENT_JOB_LIBRARY_FLAG, value: false }],
    description: "View all your specific title and descriptions (job labels).",
  },
  TAG_MANAGEMENT: {
    key: "TAG_MANAGEMENT",
    to: "/tag",
    icon: "tag",
    label: "Tags",
    requiredRoles: [roleV6User],
    requiredClientFlags: [],
    description: "Manage tags used to organize your rate cards, searches, and more.",
  },
  PROJECT_COST_ESTIMATE: {
    key: "PROJECT_COST_ESTIMATE",
    to: "/project-cost-estimate",
    icon: "dollar-sign",
    label: "Project Cost Estimate",
    requiredRoles: [roleV6User],
    requiredClientFlags: [],
    description:
      "Use your search results along with punchouts to determine project cost estimates.",
  },
  SEARCH_ALLOCATOR: {
    key: "SEARCH_ALLOCATOR",
    to: "/search-allocator",
    icon: "database",
    label: "Search Allocator",
    requiredRoles: [roleV6User, rolePSPAdmin],
    requiredClientFlags: [{ flag: PER_SEARCH_PRICING_FLAG, value: true }],
    description: "View your searches used and allocated to your users.",
  },
  //   LABOR_INDEX: {
  //     key: "LABOR_INDEX",
  //     to: "/labor-index",
  //     icon: "database",
  //     label: "Labor Index",
  //     requiredRoles: [roleV6User],
  //     requiredClientFlags: [],
  //     description: "Access the labor index." // TODO: determine text
  //   },
  PRIVATE_INDEX: {
    key: "PRIVATE_INDEX",
    to: "/private-index/programs",
    icon: "database",
    label: "Private Index",
    requiredRoles: [roleV6User],
    requiredClientFlags: [],
    description: "Access the private index to compare your workforce to the market.",
  },
  PUNCHOUTS: {
    key: "PUNCHOUTS",
    to: "/punchouts",
    icon: "chart-column",
    label: "Punchouts and Market Comparison",
    requiredRoles: [roleV6User],
    requiredClientFlags: [],
    description: "Create and manage punchouts linked to your market results.",
  },
  REGION: {
    key: "REGION",
    to: "/regions",
    icon: "map-marker",
    label: "Regions",
    requiredRoles: [roleV6User],
    requiredClientFlags: [],
    description: "Create and manage regional locations to search by.",
  },
  NOTIFICATIONS: {
    key: "NOTIFICATIONS",
    to: "/notifications",
    icon: "bell",
    label: "Notifications",
    requiredRoles: [roleV6User],
    requiredClientFlags: [],
    description: "Review notifications.",
  },
  LEARNING_CENTER: {
    key: "LEARNING_CENTER",
    to: "/learning-center",
    icon: "eye",
    label: "Learning Center",
    requiredRoles: [],
    requiredClientFlags: [],
    description: "Training videos to help you use peopleticker.",
  },
  PT_ADMIN_REGION: {
    key: "PT_ADMIN_REGION",
    to: "/admin/regions",
    icon: "map-marker",
    label: "Regions Admin",
    requiredRoles: [roleV6User, rolePTAdmin],
    requiredClientFlags: [],
    description: "Manage all regions in the system",
  },
  PT_ADMIN_SAVED_SEARCHES: {
    key: "PT_ADMIN_SEARCHES",
    to: "/admin/searches",
    icon: "edit",
    label: "Searches Admin",
    requiredRoles: [roleV6User, rolePTAdmin],
    requiredClientFlags: [],
    description: "Manage all searches in the system (rate search and scheduled search).",
  },
  PT_ADMIN_RATE_CARD: {
    key: "PT_ADMIN_RATE_CARD",
    to: "/admin/ratecards",
    icon: "list-alt",
    label: "Rate Cards Admin",
    requiredRoles: [roleV6User, rolePTAdmin],
    requiredClientFlags: [],
    description: "Manage all rate cards in the system.",
  },
  // PT_ADMIN_SCHEDULED_SEARCH: {
  //   key: "PT_ADMIN_SCHEDULED_SEARCH",
  //   to: "/admin/scheduled_search",
  //   icon: "search",
  //   label: "Scheduled Searches Admin",
  //   requiredRoles: [roleV6User, rolePTAdmin],
  //   requiredClientFlags: [],
  //   description: "Manage all scheduled searches in the system."
  // },
  PT_ADMIN_TAG_MANAGEMENT: {
    key: "PT_ADMIN_TAG_MANAGEMENT",
    to: "/admin/tags",
    icon: "tag",
    label: "Tags Admin",
    requiredRoles: [roleV6User, rolePTAdmin],
    requiredClientFlags: [],
    description: "Manage all tags in the system.",
  },
  PT_ADMIN_PROJECT_COST_ESTIMATE: {
    key: "PT_ADMIN_PROJECT_COST_ESTIMATE",
    to: "/admin/project-cost-estimate",
    icon: "dollar-sign",
    label: "Project Cost Estimate Admin",
    requiredRoles: [roleV6User, rolePTAdmin],
    requiredClientFlags: [],
    description: "Managed all PCEs in the system.",
  },
  PT_ADMIN_NEGOTIATION_WORKSHEET: {
    key: "PT_ADMIN_NEGOTIATION_WORKSHEET",
    to: "/admin/worksheets",
    icon: "flag",
    label: "Negotiation Worksheets Admin",
    requiredRoles: [roleV6User, rolePTAdmin],
    requiredClientFlags: [],
    description: "Manage all negotiation worksheets in the system.",
  },
  PT_ADMIN_JOB_LABELS: {
    key: "PT_ADMIN_JOBS_LIBRARY",
    to: "/admin/jobs",
    icon: "database",
    label: "Job Labels Admin",
    requiredRoles: [roleV6User, rolePTAdmin],
    requiredClientFlags: [],
    description: "Manage all Job Labels in the system.",
  },
  PT_ADMIN_USERS: {
    key: "PT_ADMIN_USERS",
    to: "/admin/users",
    icon: "user",
    label: "Users Admin",
    requiredRoles: [roleV6User, rolePTAdmin],
    requiredClientFlags: [],
    description: "Manage all users in the system.",
  },
  PT_ADMIN_CLIENTS: {
    key: "PT_ADMIN_CLIENTS",
    to: "/admin/clients",
    icon: "user",
    label: "Clients Admin",
    requiredRoles: [roleV6User, rolePTAdmin],
    requiredClientFlags: [],
    description: "Manage all clients in the system.",
  },
  PT_ADMIN_PUNCHOUTS: {
    key: "PT_ADMIN_PUNCHOUTS",
    to: "/admin/punchouts",
    icon: "chart-column",
    label: "Punchouts Admin",
    requiredRoles: [roleV6User, rolePTAdmin],
    requiredClientFlags: [],
    description: "Manage all punchouts in the system.",
  },
  PT_ADMIN_USER_MANAGEMENT: {
    key: "PT_ADMIN_USER_MANAGEMENT",
    to: "/admin/user-management",
    icon: "users",
    label: "User Stats Admin",
    requiredRoles: [roleV6User, rolePTAdmin],
    requiredClientFlags: [],
    description: "Manage and view statistics on all users in the system.",
  },
  REPORT: {
    key: "REPORT",
    to: "/reports",
    icon: "chart-column",
    label: "Search History",
    requiredRoles: [roleV6User, "Reports"],
    requiredClientFlags: [],
    description: "Search History",
  },
  PT_ADMIN_VAL5000: {
    key: "PT_ADMIN_VAL5000",
    to: "/admin/val5000",
    icon: "user-check",
    label: "Validator 5000",
    requiredRoles: [roleV6User, rolePTAdmin],
    requiredClientFlags: [],
    description: "",
  },
};
