import React from "react";

import Button from "../../../../components/lib/Button";
import RateTypeSelector from "../../components/RateTypeSelector";
import DisplayCurrencySelector from "../../components/DisplayCurrencySelector";
import RatesTableOrderedWithFeedbackPreview from "../../components/RatesTableOrdered/RatesTableOrderedWithFeedbackPreview";
import {
  Card,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
  CardHeaderTitle,
  CardBody,
} from "../../../../components/lib/Card";
import { RATE_TYPES } from "../../types";
import { emptyOrderedMap } from "../../../../constants";
import { useAttemptDataContext } from "../../context/AttemptDataContext";
import { useRateDisplayContext } from "../../context/RateDisplayContext";

function ValidatedTitlesBlock() {
  const {
    displayRateType,
    displayCurrencyData,
    isDisplayCurrencyChanged,
    changeDisplayRateType: handleChangeRateType,
    changeDisplayCurrency: handleChangeCurrency,
    resetDisplayCurrency: handleResetCurrency,
  } = useRateDisplayContext();

  const { firstJobData, attemptData, jobsData } = useAttemptDataContext();

  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft>
          <CardHeaderTitle as="h3">Validated Titles</CardHeaderTitle>
        </CardActionsLeft>
        <CardActionsRight>
          {firstJobData.get("rate_type") !== RATE_TYPES.ANNUAL && (
            <RateTypeSelector
              rateType={displayRateType}
              job={firstJobData}
              onChange={handleChangeRateType}
            />
          )}
          {displayCurrencyData.size > 0 && !isDisplayCurrencyChanged && (
            <DisplayCurrencySelector
              currencyData={displayCurrencyData}
              onChange={handleChangeCurrency}
            />
          )}
          {displayCurrencyData.size > 0 && isDisplayCurrencyChanged && (
            <Button color="brand" variant="outlined" onClick={handleResetCurrency}>
              Reset Currency Back
            </Button>
          )}
        </CardActionsRight>
      </CardActions>
      <CardBody>
        <RatesTableOrderedWithFeedbackPreview
          jobsData={jobsData}
          feedbackData={attemptData.get("rates_feedback") ?? emptyOrderedMap}
        />
      </CardBody>
    </Card>
  );
}

ValidatedTitlesBlock.displayName = "ValidatedTitlesBlock";

export default ValidatedTitlesBlock;
