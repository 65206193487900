import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";

class RequestMoreModal extends Component {
  static contextTypes = {
    flux: PropTypes.object,
  };

  static propTypes = {
    searchRequestSent: PropTypes.bool,
  };

  state = {
    searchesRequested: 0,
    comment: "",
  };

  _handleSearchesRequested = (e) => {
    const value = +e.target.value;

    if (value < 0) {
      return;
    }

    this.setState({
      searchesRequested: value,
    });
  };

  _handleComment = (e) => {
    const value = e.target.value;

    this.setState({
      comment: value,
    });
  };

  _handleSearchRequest = (e) => {
    const comment = this.state.comment;
    const searchesRequested = this.state.searchesRequested;

    this.context.flux
      .getActions("scheduledSearch")
      .requestTransferResource(searchesRequested, comment);
  };

  render() {
    const { searchRequestSent, show, onHide } = this.props;

    let modalBody = (
      <Modal.Body>
        <div className="form-group">
          <label>I would like</label>
          <div className="btn-group">
            <button
              className="btn btn-default"
              onClick={() => this.setState({ searchesRequested: 10 })}
            >
              10
            </button>
            <button
              className="btn btn-default"
              onClick={() => this.setState({ searchesRequested: 25 })}
            >
              25
            </button>
            <button
              className="btn btn-default"
              onClick={() => this.setState({ searchesRequested: 50 })}
            >
              50
            </button>
            <button
              className="btn btn-default"
              onClick={() => this.setState({ searchesRequested: 100 })}
            >
              100
            </button>
          </div>
          <div className="input-group">
            <input
              className="form-control"
              type="number"
              onChange={this._handleSearchesRequested}
              value={this.state.searchesRequested}
              placeholder="e.x. 100"
              aria-describedby="requestSearchesTextAddon"
              min="0"
              step="1"
            />
            <span className="input-group-addon">Searches</span>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="">Reason (optional)</label>
          <textarea
            className="form-control"
            onChange={this._handleComment}
            value={this.state.comment}
            placeholder="Please enter the reason for the request."
          />
        </div>
      </Modal.Body>
    );
    let modalFooter = (
      <Modal.Footer>
        <Button onClick={onHide}>Cancel</Button>
        <Button
          bsStyle="primary"
          disabled={this.state.searchesRequested === 0 || searchRequestSent === true}
          onClick={this._handleSearchRequest}
        >
          Request
        </Button>
      </Modal.Footer>
    );

    if (searchRequestSent === true) {
      modalBody = (
        <Modal.Body>
          Your request for searches has been sent to your client administrator. You will
          receive an email notification and your searches upon approval.
        </Modal.Body>
      );
      modalFooter = (
        <Modal.Footer>
          <Button onClick={onHide}>Close</Button>
        </Modal.Footer>
      );
    }

    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Request More Searches</Modal.Title>
        </Modal.Header>
        {modalBody}
        {modalFooter}
      </Modal>
    );
  }
}

export default RequestMoreModal;
