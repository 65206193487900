import React from "react";

import Icon from "../../../../components/lib/Icon";
import Text from "../../../../components/lib/Text";
import { InlineElements } from "../../../../components/lib/Inline";
import { RATE_FEEDBACK_STATUS_TYPES } from "../../types";

import type { RATE_FEEDBACK_STATUS_TYPES_TYPE } from "../../types";

type FeedbackStatusBlockProps = {
  feedbackStatus: RATE_FEEDBACK_STATUS_TYPES_TYPE;
  feedbackComment: string | null;
};

export default function FeedbackStatusBlock(props: FeedbackStatusBlockProps) {
  const { feedbackStatus, feedbackComment } = props;

  return (
    <InlineElements css={{ justifyContent: "center" }}>
      {feedbackStatus === RATE_FEEDBACK_STATUS_TYPES.APPROVED && (
        <Text color="positive">
          <Icon icon="check" />
        </Text>
      )}
      {feedbackStatus === RATE_FEEDBACK_STATUS_TYPES.DENIED && (
        <Text color="negative">
          <Icon icon="times" />
        </Text>
      )}
      {feedbackStatus === RATE_FEEDBACK_STATUS_TYPES.DONT_KNOW && (
        <Text color="accent">
          <Icon icon="question" />
        </Text>
      )}
      {feedbackComment && <Icon icon={["far", "commenting"]} />}
    </InlineElements>
  );
}

FeedbackStatusBlock.displayName = "FeedbackStatusBlock";
