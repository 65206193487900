// @flow

import React, { Component } from "react";
import { observer } from "mobx-react";
import NonIdealState from "./components/NonIdealState";
import type MobXStore from "../../stores/mobx/MobXStore";

type Router = {
  replace: ({ pathname: string, state: Object }) => void,
  location: { state: Object },
};

type Props = {
  store: MobXStore,
  params: { libraryId: string },
  router: Router,
};

class JobLibraryDetailRedirect extends Component<Props> {
  props: Props;

  componentDidMount() {
    const router = this.props.router;
    const store = this.props.store;
    const topLibrary =
      store.jobLibraryListStore.clientLibraries.length > 0
        ? store.jobLibraryListStore.clientLibraries[0]
        : null;

    if (topLibrary && topLibrary.id) {
      // Go to the top library and pass state
      router.replace({
        pathname: `/job-library/view/${topLibrary.id}/`,
        state: router.location.state,
      });
    }
  }

  render() {
    return (
      <NonIdealState
        icon="circle-notch"
        iconClasses="fa-spin"
        title="Loading"
        description="Please wait while data it's being transferred."
      />
    );
  }
}

export default observer(JobLibraryDetailRedirect);
