// @flow
import { extendObservable, action, runInAction } from "mobx";
import LoginUser from "../../models/LoginUser";
import NetworkState from "../../models/NetworkState";
// import { browserHistory } from "react-router";
import MessageState from "../../models/MessageState";
import type { FetchGraphQL } from "../../App";
import type MobXStore from "./MobXStore";
import SessionInfo from "../../models/SessionInfo";

export default class DashboardStore {
  mobXStore: MobXStore;
  sessionInfo: ?SessionInfo;
  network: NetworkState;
  clientDetailNetwork: NetworkState;
  networkSignup: NetworkState;
  historyNetwork: NetworkState;
  networkResetPassword: NetworkState;
  token: string;
  showResetSuccessMsg: string;
  menuDrawerOpen: boolean;
  profileDrawerOpen: boolean;
  notificationDrawerOpen: boolean;

  fetchGraphQL: FetchGraphQL;
  getSignupdata: () => void;
  // loginUser: () => void;
  resetPassword: () => void;
  forgotPassword: () => void;
  changeNewPassword: () => void;
  changeConfirmPassword: () => void;
  router: ?Object;
  clientId: string;
  messaging: MessageState;
  signUpMessage: MessageState;
  emailMessage: MessageState;
  validateEmail: (string) => boolean;
  newPassword: string;
  confirmPassword: string;

  constructor(fetchGraphQL: FetchGraphQL, mobXStore: MobXStore) {
    this.mobXStore = mobXStore;
    this.router = null;
    this.fetchGraphQL = fetchGraphQL;
    this.sessionInfo = null; // this should be set on componentDidMount

    // NOTE: Bound early to pass into pagination & filter state
    this.getSignupdata = action(this.getSignupdata.bind(this));
    // this.loginUser = action(this.loginUser.bind(this));
    this.resetPassword = action(this.resetPassword.bind(this));
    this.forgotPassword = action(this.forgotPassword.bind(this));
    this.validateEmail = action(this.validateEmail.bind(this));
    this.changeNewPassword = action(this.changeNewPassword.bind(this));
    this.changeConfirmPassword = action(this.changeConfirmPassword.bind(this));

    extendObservable(this, {
      network: new NetworkState(),
      networkSignup: new NetworkState(),
      networkResetPassword: new NetworkState(),
      clientDetailNetwork: new NetworkState(),
      showResetSuccessMsg: "",
      clientId: "",
      menuDrawerOpen: false,
      profileDrawerOpen: false,
      notificationDrawerOpen: false,
      messaging: new MessageState(),
      signUpMessage: new MessageState(),
      emailMessage: new MessageState(),
      token: "",
      newPassword: "",
      confirmPassword: "",
    });
  }

  changeNewPassword(e: Event) {
    this.newPassword = e.target.value;
  }

  changeConfirmPassword(e: Event) {
    this.confirmPassword = e.target.value;
  }

  validateEmail(email: string): boolean {
    const emailRegex =
      /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return emailRegex.test(email);
  }

  async getSignupdata() {
    let variables = {
      signupData: {
        username: this.username,
        password: this.password,
        email: this.email,
        firstName: this.firstname,
        lastName: this.lastname,
        clientId: this.clientId,
      },
    };
    let query = `
      mutation SignupUser($signupData : SignupInput) {
        signupUser(signupData:$signupData) {
          user {
            firstName
            lastName
            email
            clientId
            username
            userId
            shareRateCards
            isPtAdmin
            roles
          }
          errors{
            __typename
            ...on UsernameAlreadyExistsError{
              message
            }
            ...on InvalidClientIdError{
              message
            }
          }
        }
      }
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      console.error("Error getting signupData", e);
      this.networkSignup.loading = false;
      this.networkSignup.error = e;
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      this.networkSignup.loading = false;

      console.error("Errors", res.errors);
      this.networkSignup.error = {
        message: res.errors[0].message,
      };

      return;
    }

    runInAction("getSignupdata--success", () => {
      if (res.data.signupUser.errors) {
        this.signUpMessage.removeAll();
        this.signUpMessage.createMessage("info", res.data.signupUser.errors[0].message);
        return;
      }

      // $FlowFixMe: Type the res value once we abstract gql calls.
      this.loginuser = new LoginUser(this, this.loginuser);
      this.loginuser.login_firstname = res.data.signupUser.user.firstName;
      this.loginuser.login_lastname = res.data.signupUser.user.lastName;
      this.loginuser.login_email = res.data.signupUser.user.email;
      this.loginuser.login_username = res.data.signupUser.user.username;
      this.userId = res.data.signupUser.user.userId;
      localStorage.setItem(
        "PeopleTickerUserName",
        res.data.signupUser.user.firstName + " " + res.data.signupUser.user.lastName
      );
      localStorage.setItem("PeopleTickerUserEmail", res.data.signupUser.user.email);
      localStorage.setItem("isPtAdmin", res.data.signupUser.user.isPtAdmin);
      localStorage.setItem("roles", JSON.stringify(res.data.signupUser.user.roles));
      localStorage.setItem(
        "shareRateCards",
        JSON.stringify(res.data.signupUser.user.shareRateCards)
      );

      window.location.reaload("/");
      // if (this.router) {
      //   this.router.push("/");
      // } else {
      //   window.location = "/";
      // }
    });

    return {
      user: res.data.signupUser.user,
    };
  }

  async loginAsUser(user_id: string) {
    this.messaging.removeAll();

    this.network.loading = true;

    const query = `
        mutation LoginAsUser($userId: ID!){
          loginAsUser(userId: $userId){
            user{
              userId
              username
              firstName
              lastName
              termsOfAgreement
              roles
              legacySession
              client {
                id
                legacyId
                title
                legacyClient {
                  perSearchPricing
                  isClientJobLibrary
                }
              }
            }
            errors{
              __typename
              ...on UserDoesNotExistsError{
                message
              }
              ...on InvalidCredentialError{
                message
              }
            }
          }
        }
        `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, {
        userId: user_id,
      });
    } catch (e) {
      console.error("Error login user", e);
      this.network.loading = false;
      this.network.error = e;
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      this.network.loading = false;

      console.error("Errors", res.errors);
      this.network.error = {
        message: res.errors[0].message,
      };

      return;
    }

    runInAction("loginAsUser--success", () => {
      if (res.data.loginAsUser.errors) {
        this.messaging.removeAll();
        this.messaging.createMessage("info", res.data.loginAsUser.errors[0].message);
        return;
      }
    });
    // NOTE: Clear any stored ratesearches after login to new user
    // TODO: Clear other state that should not be kept around
    localStorage.removeItem("ratesearchdata");
    window.location.assign("/");
    // should we even return anything here?
    return {
      user: res.data.loginAsUser.user,
    };
  }

  async resetPassword() {
    this.showResetSuccessMsg = null;

    const variables = {
      resetPasswordData: {
        newPassword: this.newPassword,
        token: this.token,
      },
    };
    const query = `
          mutation resetPasswordUser($resetPasswordData : ResetPasswordInput){
            resetPasswordUser(resetPasswordData:$resetPasswordData){
              ok
              errors {
                __typename
                ... on InvalidVerificationTokenError {
                  message
                }
                ... on InvalidPasswordError {
                  message
                }
              }
            }
          }
        `;
    this.networkResetPassword.loading = true;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      console.error("Error change password", e);
      this.networkResetPassword.loading = false;
      this.networkResetPassword.error = e;
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.data.resetPasswordUser.errors) {
      this.networkResetPassword.loading = false;

      this.networkResetPassword.error = {
        message: res.data.resetPasswordUser.errors[0].message,
      };

      return;
    }

    runInAction("resetPassword--success", () => {
      this.networkResetPassword.loading = false;

      this.showResetSuccessMsg = "Your Password has been Reset. Please login again";

      //browserHistory.push('/login');

      // this.router.push({
      //   pathname: `/menu`,
      // });
    });

    return {
      user: res.data.resetPasswordUser,
    };
  }

  async forgotPassword(username: string) {
    // if (!this.validateEmail(forgotPassEmail.trim())) {
    //   this.emailMessage.createMessage("info", "Please enter a valid Primary Email.");
    //   return;
    // }

    const variables = {
      input: {
        username: username,
      },
    };
    const query = `
      mutation forgotPasswordMutation($input : ForgotPasswordInput){
      forgotPasswordUser(forgotPasswordData:$input){
        ok
        errors{
          __typename
          ...on RequireFieldsError{
            message
          }
          ...on UserEmailMissingError{
            message
          }
          ...on UsernameDoesNotExistsError{
            message
          }
        }
      }
     }
    `;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      console.error("Error in forgot password request", e);
      this.network.loading = false;
      this.network.error = e;
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      this.network.loading = false;
      console.error("Errors", res.errors);
      this.network.error = {
        message: "Error in forgot password request, please try again later.",
      };

      return;
    }

    // runInAction("forgotPassword--success", () => {
    // if (res.data.forgotPasswordUser.errors) {
    //   this.emailMessage.removeAll();
    //   this.emailMessage.createMessage("error", res.data.forgotPasswordUser.errors[0].message);
    //   return;
    // }
    // this.network.error = {
    //   message: "A link to reset your Password has been sent to your Email Id"
    // };
    // this.forgot_pass_email = "";
    // });

    console.log("looking for errors in:", res);

    if (res.data.forgotPasswordUser.errors) {
      const requiredFieldError = res.data.forgotPasswordUser.errors.find(
        (e) => e.__typename === "RequireFieldsError"
      );
      if (requiredFieldError) {
        return {
          response: false,
          message: { message: "Username field is required!", messageLabel: "danger" },
        };
      }
      const doesNotExistError = res.data.forgotPasswordUser.errors.find(
        (e) => e.__typename === "UsernameDoesNotExistsError"
      );
      if (doesNotExistError) {
        return {
          response: false,
          message: { message: "Username was not found!", messageLabel: "danger" },
        };
      }
      const noEmailError = res.data.forgotPasswordUser.errors.find(
        (e) => e.__typename === "UserEmailMissingError"
      );
      if (noEmailError) {
        return {
          response: false,
          message: { message: "User has no email address!", messageLabel: "danger" },
        };
      }
    }

    return {
      response: res.data.forgotPasswordUser.ok,
    };
  }
}
