// @flow

import { extendObservable, action, runInAction, observable, computed } from "mobx";
import type { ObservableMap } from "mobx";
import { GraphQLQuery } from "./GraphQL";
import Sort from "./Sort";
import ModalState from "./ModalState";
import SortState from "./SortState";
import NetworkState from "./NetworkState";
import type { FetchGraphQL } from "../App";

export const FILTER_COLUMN = {
  RATE_CARD_LABEL: "rateCardLabel",
  RATE_TYPE: "rateType",
  JOB_LABEL: "jobLabel",
  JOB_TITLE: "jobTitle",
  LOCATION: "location",
  COUNTRY: "country",
  INDUSTRY: "industry",
  CATEGORY: "category",
  CREATED_BY: "createdBy",
  DATE_RANGE: "dateRange",
  SHARED: "shared",
  EXCLUDE: "except",
  CLIENT_JOB_TITLE_CLIENT_TITLE: "clientJobTitleClientTitle",
  CLIENT_JOB_TITLE_IN_ANY_LIBRARY: "clientJobTitleInAnyLibrary",
  CLIENT_JOB_TITLE_SELECTED_IDS: "clientJobTitleSelectedIds",
  CLIENT_JOB_TITLE_REQUEST_TITLE: "clientJobTitleRequestTitle",
  CLIENT_JOB_TITLE_REQUEST_ONLY_STATUS: "clientJobTitleRequestOnlyStatus",
  CLIENT_LIBRARY_NAME: "clientLibraryName",
  CLIENT_LIBRARY_CLIENT_TITLE: "clientLibraryClientTitle",
  CLIENT_LIBRARY_NAME_OR_CLIENT_JOB_TITLE: "clientLibraryNameOrClientJobTitle",
  BATCH_SEARCH_RATE_CARD_LABEL: "rate_card_label",
  DRAFT: "draft",
  PROJECT_LABEL: "pcLabel",
  PC_DATE_RANGE: "pcDateRange",
  REGION_LABEL: "regionLabel",
  LOCATION_TYPE: "locationType",
  REGION_DATE_RANGE: "regionDateRange",
  CURRENCY_TYPE: "currencyType",
  PUNCHOUTS_DATE_RANGE: "punchoutsDateRange",
  PAY_TYPE: "payType",
  MARKUP_AMT: "markUpAmt",
  WORKSHEET_DATE: "workSheet",
  WORKSHEET_COUNTRY: "worksheetCountry",
  TAGS: "tags",
  TAG_CONTENT: "tagContent",
  USER: "user",
  CLIENT_USER: "clientUser",
  ACTIVE: "active",
  CLIENT_USER_LIST: "clientUserList",
  NW_CLIENT_USER: "nwClinetList",
  NW_USER: "nwUser",
  SEARCH_LOG_DATE: "searchLogDate",
  SEARCH_LOG_USER: "searchLogUser",
};

export const MODULE_MAP = {
  JobTagNode: {
    column: "jobLabel",
    id: "jobId",
    type: "Job",
    link: "job-labels",
  },
  RateCardTagNode: {
    column: "ratecardName",
    id: "ratecardId",
    type: "Rate Card",
    link: "ratecards",
  },
  SearchTagNode: {
    column: "job",
    id: "searchId",
    type: "Saved Search",
    link: "searches",
  },
  ProjectTagNode: {
    column: "name",
    id: "projectId",
    type: "Project Cost Estimation",
    link: "project-cost-estimate",
  },
  NegotiationWorksheetTagNode: {
    column: "worksheetName",
    id: "worksheetId",
    type: "Negotiation Worksheet",
    link: "negotiation-worksheets",
  },
  RegionTagNode: {
    column: "name",
    id: "regionId",
    type: "Region",
    link: "regions",
  },
};

export const CONTENT_TYPE = {
  JOB_LIBRARY: "Job",
  NEGOTIATION_WORKSHEET: "Negotiation Worksheet",
  PROJECT_STATS: "Project Cost Estimate",
  RATE_CARD: "Rate Card",
  REGIONS: "Region",
  SAVED_SEARCHES: "Saved Search",
};

export const NOTIFICATION_CONTENT_TYPE = {
  SHARE_RATE_CARD: "ratecards",
  SHARE_TAG: "tag",
  MARKET_CHANGES_ALERT: "punchouts",
  SCHEDULE_SEARCH_COMPLETION: "ratecards",
  EXPORT_COMPLETE: "api/export",
};

export type FilterColumn = string;

export default class FilterObject {
  queryParam: string;
  queryArg: string;
  queryVars: Object;

  constructor(queryParam: string, queryArg: string, queryVars: Object) {
    this.queryParam = queryParam;
    this.queryArg = queryArg;
    this.queryVars = queryVars;
  }
}

export type DjangoFilterObject = { [key: string]: mixed }; // { 'pay_rate__gte': 50.45 }

export interface IViewItem {
  id: string;
  selected: boolean;
}

class FilterBase {
  store: Object;
  modal: ModalState;

  column: FilterColumn;
  filter: ?FilterObject;

  applyFilter: (FilterColumn, FilterObject) => void;
  removeFilter: (FilterColumn) => void;

  +buildQueryFilter: () => ?FilterObject;
  +onApply: () => void;
  +onReset: () => void;
  +softReset: () => void;
  +softResetFilter: () => void;
  +onShowModal: (Event) => void;
  +fetchGraphQL: FetchGraphQL;
  +fetch: () => void;

  constructor(store: Object, column: FilterColumn) {
    this.store = store;
    this.modal = new ModalState();

    this.fetchGraphQL = store.fetchGraphQL;

    this.filter = null;
    this.column = column;

    this.onShowModal = action(this.onShowModal.bind(this));
    this.fetch = action(this.fetch.bind(this));
    this.buildQueryFilter = action(this.buildQueryFilter.bind(this));
    this.onApply = action(this.onApply.bind(this));
    this.onReset = action(this.onReset.bind(this));
    this.softReset = action(this.softReset.bind(this));
    this.softResetFilter = action(this.softResetFilter.bind(this));
  }

  fetch() {
    this.store.pagination.goFetch(null);
  }

  onShowModal(event: Event) {
    this.modal.showModal();
  }

  buildQueryFilter() {
    return new FilterObject("", "", {});
  }

  onApply() {
    const filter = this.buildQueryFilter();

    if (!filter) {
      this.softResetFilter();
    } else {
      // apply filter
      this.filter = filter;
      this.applyFilter(this.column, filter);
    }

    this.modal.hideModal();
    this.fetch();
  }

  onReset() {
    if (!this.filter) {
      this.softReset();
      return;
    }

    let doFetch = false;

    if (this.filter) {
      this.softResetFilter();
      doFetch = true;
    }

    this.modal.hideModal();
    if (doFetch) {
      this.fetch();
    }
  }

  softReset() {
    this.softResetFilter();
  }

  softResetFilter() {
    this.filter = null;
    this.removeFilter(this.column);
  }
}

export class FilterWithSort extends FilterBase {
  sortState: ?SortState;
  sort: ?Sort;

  applySort: (FilterColumn, Sort) => void;
  removeSort: (FilterColumn) => void;

  buildQuerySort: () => ?Sort;
  onApply: () => void;
  onReset: () => void;
  softReset: () => void;
  softResetSort: () => void;

  constructor(store: Object, column: FilterColumn) {
    super(store, column);

    this.sort = null;
    this.sortState = null;

    this.buildQuerySort = action(this.buildQuerySort.bind(this));
    this.softResetSort = action(this.softResetSort.bind(this));
  }

  buildQuerySort() {
    if (!this.sortState || !this.sortState.direction) return null;

    return new Sort(
      "{field: " + this.sortState.field + ", direction: " + this.sortState.direction + "}"
    );
  }

  onApply() {
    const filter = this.buildQueryFilter();
    const sort = this.buildQuerySort();

    if (!filter) {
      this.softResetFilter();
    } else {
      // apply filter
      this.filter = filter;
      this.applyFilter(this.column, filter);
    }

    if (!sort) {
      this.softResetSort();
    } else {
      this.sort = sort;
      this.applySort(this.column, sort);
    }

    this.modal.hideModal();
    this.fetch();
  }

  onReset() {
    if (!this.filter && !this.sort) {
      this.softReset();
      return;
    }

    let doFetch = false;

    if (this.filter) {
      this.softResetFilter();
      doFetch = true;
    }

    if (this.sort) {
      this.softResetSort();
      doFetch = true;
    }

    this.modal.hideModal();
    if (doFetch) {
      this.fetch();
    }
  }

  softReset() {
    this.softResetFilter();
    this.softResetSort();
  }

  softResetSort() {
    if (this.sortState) {
      this.sortState.resetSort();
    }

    this.sort = null;
    this.removeSort(this.column);
  }
}

export const ContainsTextFilter = (SuperClass: typeof FilterBase = FilterBase) =>
  class extends SuperClass {
    textToLookFor: string; // text to look for
    idToLookFor: Array<string | number>; // id's to look for

    onTextToLookForChange: (string) => void;
    onIdToLookForChange: (Array<string | number>) => void;
    softResetFilter: () => void;

    constructor(store: Object, column: FilterColumn) {
      super(store, column);

      extendObservable(this, {
        textToLookFor: "",
        idToLookFor: [],
      });

      this.onTextToLookForChange = action(this.onTextToLookForChange.bind(this));
      this.onIdToLookForChange = action(this.onIdToLookForChange.bind(this));
      this.softResetFilter = action(this.softResetFilter.bind(this));
    }

    onTextToLookForChange(value: string) {
      this.textToLookFor = value;
    }
    onIdToLookForChange(value: Array<string | number>) {
      this.idToLookFor = value;
    }

    softResetFilter() {
      if (super.softResetFilter) super.softResetFilter();

      this.textToLookFor = "";
    }
  };

export const DateRangeFilter = (SuperClass: typeof FilterBase = FilterBase) =>
  class extends SuperClass {
    fromDate: ?Date;
    toDate: ?Date;
    fromFocused: boolean;
    toFocused: boolean;

    fromDateChange: (Object) => void;
    toDateChange: (Object) => void;
    fromFocusedChange: (Object) => void;
    toFocusedChange: (Object) => void;
    softResetFilter: () => void;

    constructor(store: Object, column: FilterColumn) {
      super(store, column);

      extendObservable(this, {
        fromDate: null,
        toDate: null,
        fromFocused: false,
        toFocused: false,
      });

      this.fromDateChange = action(this.fromDateChange.bind(this));
      this.toDateChange = action(this.toDateChange.bind(this));
      this.fromFocusedChange = action(this.fromFocusedChange.bind(this));
      this.toFocusedChange = action(this.toFocusedChange.bind(this));
      this.softResetFilter = action(this.softResetFilter.bind(this));
    }

    fromDateChange(date: Object) {
      this.fromDate = date;
    }

    toDateChange(date: Object) {
      this.toDate = date;
    }

    fromFocusedChange({ focused }: { focused: boolean }) {
      this.fromFocused = focused;
    }

    toFocusedChange({ focused }: { focused: boolean }) {
      this.toFocused = focused;
    }

    softResetFilter() {
      if (super.softResetFilter) super.softResetFilter();

      this.fromDate = null;
      this.toDate = null;
      this.fromFocused = false;
      this.toFocused = false;
    }
  };

export const MarkUpRangeFilter = (SuperClass: typeof FilterBase = FilterBase) =>
  class extends SuperClass {
    fromMarkUpAmt: ?number;
    toMarkUpAmt: ?number;

    fromMarkUpAmtChange: (Object) => void;
    toMarkUpAmtChange: (Object) => void;
    softResetFilter: () => void;

    constructor(store: Object, column: FilterColumn) {
      super(store, column);

      extendObservable(this, {
        fromMarkUpAmt: null,
        toMarkUpAmt: null,
      });

      this.fromMarkUpAmtChange = action(this.fromMarkUpAmtChange.bind(this));
      this.toMarkUpAmtChange = action(this.toMarkUpAmtChange.bind(this));
      this.softResetFilter = action(this.softResetFilter.bind(this));
    }

    fromMarkUpAmtChange(e: Object) {
      this.fromMarkUpAmt = e.target.value;
    }

    toMarkUpAmtChange(e: Object) {
      this.toMarkUpAmt = e.target.value;
    }

    softResetFilter() {
      if (super.softResetFilter) super.softResetFilter();

      this.fromMarkUpAmt = null;
      this.toMarkUpAmt = null;
    }
  };

/**
 * Use it when the filter allows to pick one or more values from a set of values
 */
export const ValueSetFilter = (SuperClass: typeof FilterBase = FilterBase) =>
  class extends SuperClass {
    selectedValues: Object; // a map {key:label.id, value: true|false} // {item id, if its selected or not}
    criteria: Object;
    viewItems: Array<IViewItem>;
    viewItemClass: any;
    instantSearchValue: string;
    totalValuesCount: number;
    selectedValuesCount: number;

    buildViewItems: (ObservableMap) => Array<any>;
    setSelectedValue: (Object) => void;
    onSelectAll: () => void;
    onDeselectAll: () => void;
    softResetFilter: () => void;
    onInstantSearch: (string) => void;

    allowMultipleItemSelection: boolean; // set to false if only one item at at time should be selected

    constructor(store: Object, column: FilterColumn) {
      super(store, column);

      // set to false on subclass if only one item at at time should be selected
      this.allowMultipleItemSelection = true;

      extendObservable(this, {
        selectedValues: observable.map({}),
        criteria: observable.map({}),
        viewItems: [],
        instantSearchValue: "",
        totalValuesCount: computed(() => {
          if (!this.criteria) return 0;

          if (this.criteria.entries) return this.criteria.entries().length;

          return this.criteria.length;
        }),
        selectedValuesCount: computed(() => {
          const selected = this.selectedValues
            .entries()
            .filter((entry) => entry[1] === true);
          return selected.length;
        }),
      });

      this.viewItemClass = Object;

      this.buildViewItems = action(this.buildViewItems.bind(this));
      this.setSelectedValue = action(this.setSelectedValue.bind(this));
      this.softResetFilter = action(this.softResetFilter.bind(this));
      this.onSelectAll = action(this.onSelectAll.bind(this));
      this.onDeselectAll = action(this.onDeselectAll.bind(this));
      this.onInstantSearch = action(this.onInstantSearch.bind(this));
    }

    buildViewItems(criteria: ObservableMap) {
      return criteria.values().map((item) => {
        item.selected = false;
        if (this.selectedValues.has(item.id)) {
          item.selected = this.selectedValues.get(item.id);
        }
        return new this.viewItemClass(item);
      });
    }

    setSelectedValue(item: Object) {
      if (this.allowMultipleItemSelection) {
        item.selected = !item.selected;
        this.selectedValues.set(item.id, item.selected);
      } else {
        // only one item selected at a time
        this.viewItems.forEach((viewItem) => {
          viewItem.selected = viewItem === item;
          this.selectedValues.set(item.id, item.selected);
        });
      }
    }

    onSelectAll() {
      this.viewItems.forEach((viewItem) => {
        this.selectedValues.set(viewItem.id, true);
        viewItem.selected = true;
      });
    }

    onDeselectAll() {
      this.viewItems.forEach((viewItem) => {
        this.selectedValues.set(viewItem.id, false);
        viewItem.selected = false;
      });
    }

    onInstantSearch(value: string) {
      this.instantSearchValue = value;
    }

    softResetFilter() {
      if (super.softResetFilter) super.softResetFilter();

      this.selectedValues = observable.map({});
      this.viewItems.forEach((item) => (item.selected = false));
      this.instantSearchValue = "";
      this.viewItems = this.buildViewItems(this.criteria);
    }
  };

export const FilterCriteriaLoader = (SuperClass: any = ValueSetFilter) =>
  class extends SuperClass {
    network: NetworkState;
    reloadCriteria: boolean;

    filterCriteriaQuery: (FilterColumn) => GraphQLQuery;
    processPayload: (FilterColumn, Object) => ?Array<Object>;

    fetchFilterCriteria: () => void;
    onShowModal: (Event) => void;

    constructor(store: Object, column: FilterColumn) {
      super(store, column);

      extendObservable(this, {
        selectedValues: observable.map({}),
        criteria: observable.map({}),
        viewItems: [],
      });

      this.network = new NetworkState();
      this.reloadCriteria = false;

      this.fetchFilterCriteria = action(this.fetchFilterCriteria.bind(this));
      this.onShowModal = action(this.onShowModal.bind(this));
    }

    async fetchFilterCriteria() {
      if (this.network.loading) return;

      this.network.loading = true;
      this.network.error = null;

      let payload = null;

      try {
        const queryObject = this.filterCriteriaQuery(this.column);
        payload = await this.fetchGraphQL(queryObject.query, queryObject.variables);
      } catch (e) {
        this.network.handleError("Getting filter criteria", e);
        // TODO: Display user friendly error message
        return;
      }

      runInAction("fetchFilterCriteria--success", () => {
        this.network.loading = false;
        this.network.error = null;

        // NOTE: Type refinement for flow
        if (payload === null) {
          return;
        }

        if (this.network.logGraphQLError("Filter criteria query", payload)) {
          // TODO: Display user friendly error message
          return;
        }

        this.criteria = this.processPayload(this.column, payload);

        this.unfilteredViewItems = this.buildViewItems(this.criteria);

        this.viewItems = this.unfilteredViewItems;
      });
    }

    onShowModal(event: Event) {
      if (!this.criteria.size || (this.criteria.size && this.reloadCriteria))
        this.fetchFilterCriteria();
      this.modal.showModal();
    }
  };
