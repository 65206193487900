// @flow

import React, { Component } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { action } from "mobx";
import { TITLE_REQUEST_STATUS } from "../../constants/jobLibraryTitleRequest";
import FlagIcon from "../../components/FlagIcon";
import JobLibraryTitleRequest from "../../models/JobLibraryTitleRequest";
import type { Element } from "react";
import CCCCountry from "../../models/CCCCountry";

const PLACEHOLDER = {
  status: (
    <div
      className="cjl-title-request-status processing"
      style={{ height: 27, width: 60 }}
    />
  ),
  title: <div className="loading-gradient" style={{ height: 25, width: "100%" }} />,
  totalCountries: (
    <div className="loading-gradient" style={{ height: 25, width: 50, marginLeft: 30 }} />
  ),
  created: <div className="loading-gradient" style={{ height: 25, width: 80 }} />,
  selected: false,
};

type RowProps = {
  // clientRawJobTitle: RawJobTitle,
  // clientTitle: Element<any>,
  // mappedTitle: Element<any>,
  // description: ?string,
  // showCountries: boolean,
  // countries: ?Element<any> | ?(Element<any>[]),
  // onRequestCountry?: JobLibraryTitle => void,
  // onViewRateCards?: JobLibraryTitle => void,
  // adHocCountries: number,
  // adHocCountries: CCCCountry[],
  status: string | Element<any>,
  title: string | Element<any>,
  description?: ?string,
  userMessage?: ?string,
  totalCountries: number | Element<any>,
  created: string | Element<any>,
  certifiedCountries: CCCCountry[],
  actions?: ?any,
  loading?: boolean,
  selected?: boolean,
  expandable?: boolean,
  expanded?: boolean,
  collapsed?: boolean,
  onExpand?: () => void,
};

const TableRow = observer(
  class extends Component<RowProps> {
    handleListItemClick: () => void;

    constructor(props: RowProps) {
      super(props);

      this.handleListItemClick = this.handleListItemClick.bind(this);
    }

    handleListItemClick() {
      if (this.props.expandable && this.props.onExpand) {
        this.props.onExpand();
      }
    }

    render() {
      const props = this.props;
      const classes = classNames("cjl-flex-table row list-item", {
        selected: props.selected,
        expandable: props.expandable,
        expanded: props.expanded,
      });

      const collapsibleContentClasses = classNames("collapsible-content", {
        collapsed: !props.expanded,
        expanded: props.expanded,
      });

      const caretClasses = classNames({ expanded: props.expanded });
      return (
        <div className="cjl-flex-table row">
          <div className="cjl-flex-table">
            <div className={classes} onClick={this.handleListItemClick}>
              <div className="column auto cjl-caret-col">
                <FontAwesomeIcon icon="play" className={caretClasses} />
              </div>
              <div className="column auto cjl-title-request-status-col">
                {props.status}
              </div>
              <div className="column cjl-job-title-col">
                <div className="client-title">{props.title}</div>
              </div>
              <div className="column auto cjl-countries-count-col">
                {props.totalCountries}
              </div>
              <div className="column auto cjl-date-col">{props.created}</div>
              {props.actions && (
                <div className="column auto cjl-job-title-actions-col">
                  <div className="cjl-button-bar">{props.actions}</div>
                </div>
              )}
            </div>

            <div className={collapsibleContentClasses}>
              <div className="cjl-title-desc pt-job-description">
                <div>
                  <label className="cjl-list-item-name">Requested Countries</label>
                  {props.certifiedCountries && props.certifiedCountries.length > 0 && (
                    <div className="flex-wrap" style={{ margin: -3 }}>
                      {props.certifiedCountries &&
                        props.certifiedCountries.map((country) => (
                          <span
                            key={country.id}
                            className="cjl-job-title-status cjl-status-tag certified"
                          >
                            {country.iso3166Alpha2 && (
                              <FlagIcon code={country.iso3166Alpha2} />
                            )}{" "}
                            {country.title}
                          </span>
                        ))}
                    </div>
                  )}

                  {props.certifiedCountries && props.certifiedCountries.length === 0 && (
                    <div className="text-wrap">
                      <span className="text-muted">-- No Countries --</span>
                    </div>
                  )}
                </div>
                <br />
                <label className="cjl-list-item-name">Description</label>
                {props.description && (
                  <pre className="text-wrap">{props.description}</pre>
                )}
                {!props.description && (
                  <div className="text-wrap">
                    <span className="text-muted">-- No Description --</span>
                  </div>
                )}
                <br />
                <label className="cjl-list-item-name">User Message</label>
                {props.userMessage && (
                  <pre className="text-wrap">{props.userMessage}</pre>
                )}
                {!props.userMessage && (
                  <div className="text-wrap">
                    <span className="text-muted">-- No User Message --</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
);

export { TableRow };

type Props = {
  libraryTitleRequest?: JobLibraryTitleRequest,
  expandable?: boolean,
  actions?: ?(any[]),
};

const LibraryTitleRequestTableRow = observer(
  class extends Component<Props> {
    handleRowOnExpand: () => void;

    constructor(props: Props) {
      super(props);

      this.handleRowOnExpand = action(this.handleRowOnExpand.bind(this));
    }

    handleRowOnExpand() {
      if (this.props.expandable && this.props.libraryTitleRequest) {
        this.props.libraryTitleRequest.toggleExpanded();
      }
    }

    render() {
      const props = this.props;
      const libraryTitleRequest = props.libraryTitleRequest;
      const actions = props.actions;

      let tableProps = PLACEHOLDER;
      if (libraryTitleRequest) {
        let status = (
          <div className="cjl-title-request-status processing">
            {libraryTitleRequest.statusVerbose}
          </div>
        );
        if (libraryTitleRequest.status === TITLE_REQUEST_STATUS.PENDING) {
          status = (
            <div className="cjl-title-request-status pending">
              {libraryTitleRequest.statusVerbose}
            </div>
          );
        } else if (libraryTitleRequest.status === TITLE_REQUEST_STATUS.APPROVED) {
          status = (
            <div className="cjl-title-request-status approved">
              {libraryTitleRequest.statusVerbose}
            </div>
          );
        }
        if (libraryTitleRequest.status === TITLE_REQUEST_STATUS.DENIED) {
          status = (
            <div className="cjl-title-request-status denied">
              {libraryTitleRequest.statusVerbose}
            </div>
          );
        }

        const viewState = libraryTitleRequest.viewState;
        tableProps = {
          status: status,
          title: libraryTitleRequest.title || "",
          description: libraryTitleRequest.description,
          userMessage: libraryTitleRequest.userMessage,
          totalCountries: libraryTitleRequest.totalCertifiedCountries || 0,
          certifiedCountries: libraryTitleRequest.certifiedCountries || [],
          created: libraryTitleRequest.createdDisplay || "",
          selected: viewState ? viewState.selected : false,
          expanded: viewState ? viewState.expanded : false,
          expandable: this.props.expandable,
          actions: actions,
          onExpand: this.handleRowOnExpand,
        };
      }

      return <TableRow {...tableProps} />;
    }
  }
);

export default LibraryTitleRequestTableRow;
