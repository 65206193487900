// @flow
import * as React from "react";
import ContainerSection from "../views/ratecards/components/ContainerSection";
import Modal from "../views/ratecards/components/Modal";
import type { SortDirection } from "../models/Sort";

const FilterModal = (props: {
  show: boolean,
  disabled?: boolean,
  title?: ?string,
  isCreatedFilter: boolean,
  children: React.ChildrenArray<any>,
  onHide: () => void,
  onApply: () => Promise<any>,
  onReset: () => void,
  selectedValuesCount: number,
  sortDirection?: ?SortDirection,
  innerStyle?: {},
}) => (
  <Modal show={props.show} onHide={props.onHide} innerStyle={props.innerStyle}>
    {props.title && (
      <ContainerSection className="header">
        <h4>{props.title}</h4>
      </ContainerSection>
    )}
    {props.children}
    <ContainerSection className="footer">
      <div className="pull-right">
        {!props.isCreatedFilter && (
          <button
            className="btn btn-lg btn-green"
            onClick={props.onApply}
            disabled={
              props.selectedValuesCount === 0 && !Boolean(props.sortDirection)
              // (props.filterState.sortState ? !props.filterState.sortState.direction : true)
            }
          >
            Apply
          </button>
        )}
        {props.isCreatedFilter && (
          <button
            className="btn btn-lg btn-green"
            onClick={props.onApply}
            disabled={
              props.disabled
              // !props.filterState.fromDate &&
              // !props.filterState.toDate &&
              // (props.filterState.sortState ? !props.filterState.sortState.direction : true)
            }
          >
            Apply
          </button>
        )}
        <button
          className="btn btn-lg btn-default"
          disabled={props.disabled}
          onClick={props.onReset}
        >
          Clear
        </button>
        <button className="btn btn-lg btn-default" onClick={props.onHide}>
          Close
        </button>
      </div>
    </ContainerSection>
  </Modal>
);

export default FilterModal;
