import React, { useCallback, MouseEventHandler } from "react";
import { useRecoilValue } from "recoil";

// @ts-expect-error
import csvTemplateHref from "../../../assets/templates/upload_rate_card_template.csv";
// @ts-expect-error
import excelTemplateHref from "../../../assets/templates/upload_rate_card_template.xlsx";

import {
  Card,
  CardHeader,
  CardBody,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
  CardHeaderTitle,
} from "../../../components/lib/Card";
import Icon from "../../../components/lib/Icon";
import Stack from "../../../components/lib/Stack";
import { NavigationButton, Button } from "../../../components/lib/Button";
import { ButtonGroupRight } from "../../../components/lib/ButtonGroup";
import Box from "../../../components/lib/Box";
import { SimpleLink } from "../../../components/lib/Link";
import RecentUploadsBlock from "../components/RecentUploadsBlock";
import ContractorsUploadFormBlock, {
  FILE_UPLOADING_STATES,
} from "../components/ContractorsUploadFormBlock";
import {
  contractorsUploadFormGlobalState,
  contractorsUploadsListGlobalState,
} from "../globalState";

import type { CommonChildPageProps } from "../PrivateIndex";
import { supportEmail } from "../../../constants";

const fileSelectTooltipText = (
  <div>
    <div>
      <label>
        <u>File Requirements:</u>
      </label>
      <ul>
        <li>Supported file formats: csv, xlsx</li>
        <li>Maximum file size: 10 megabytes</li>
      </ul>
    </div>

    <div>
      <label>
        <u>Required Data:</u>
      </label>
      <ul>
        <li>Required columns are marked with an asterisk in the templates.</li>
        <li>
          <span>These columns are always required:</span>
          <ul>
            <li>
              <b>
                <i>Effective Date</i>
              </b>
            </li>
            <li>
              <b>
                <i>Job Label</i>
              </b>
            </li>
          </ul>
        </li>
        <li>
          <span>Following columns have some special rules for usages:</span>
          <ul>
            <li>
              One single location type per row - Regular Location (
              <b>
                <i>Country/State/City</i>
              </b>
              ) or{" "}
              <b>
                <i>Region</i>
              </b>
              .
            </li>
            <li>
              One rate type per row - "Hourly" or "Annual" – do not include the two types
              together in the same row.
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <div>
      <label>
        <u>Value Constraints:</u>
      </label>
      <ul>
        <li>
          "Effective Date" value formats must be: MM/DD/YYYY, MM-DD-YYYY, MM.DD.YYYY.
        </li>
      </ul>
    </div>

    <div>
      <label>
        <u>Automatic Calculations:</u>
      </label>
      <ul>
        <li>
          Any hourly rate value (Pay Rate/Markup/Bill Rate) will be calculated if other
          two are provided (example: calculate "Bill Rate" value based on the provided
          "Pay Rate" and "Markup" values).
        </li>
      </ul>
    </div>
  </div>
);

type PageHeadBlockProps = {
  disableButtons: boolean;
  onBackToProgramsList: MouseEventHandler<HTMLButtonElement>;
};

const PageHeadBlock = (props: PageHeadBlockProps) => {
  const { disableButtons, onBackToProgramsList } = props;

  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft>
          <CardHeaderTitle>Create New Index From Spreadsheet</CardHeaderTitle>
        </CardActionsLeft>
        <CardActionsRight>
          <NavigationButton
            icon="arrow-left"
            onClick={!disableButtons ? onBackToProgramsList : undefined}
            disabled={disableButtons}
          >
            Back to Indexes List
          </NavigationButton>
        </CardActionsRight>
      </CardActions>
    </Card>
  );
};
PageHeadBlock.displayName = "PageHeadBlock";

type PageBottomBlockProps = PageHeadBlockProps;

const PageBottomBlock = (props: PageBottomBlockProps) => {
  const { disableButtons, onBackToProgramsList } = props;

  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft />
        <CardActionsRight>
          <NavigationButton
            icon="arrow-left"
            onClick={!disableButtons ? onBackToProgramsList : undefined}
            disabled={disableButtons}
          >
            Back to Indexes List
          </NavigationButton>
        </CardActionsRight>
      </CardActions>
    </Card>
  );
};
PageBottomBlock.displayName = "PageBottomBlock";

export const InstructionsBlock = () => (
  <Card fill>
    <CardHeader>
      <CardHeaderTitle as="h3">Instructions</CardHeaderTitle>
    </CardHeader>
    <CardBody css={{ color: "$primary" }}>
      <h3>- Download one of the provided templates for the preferred file format </h3>
      <h3>- Enter data and upload using the form below</h3>
      <h3>- Supported file formats: csv, xlsx</h3>
      <h3>- Maximum file size - 10 megabytes</h3>
      <h3>- Required fields are marked with an asterisk in the templates</h3>
      <h3>
        - One rate type per upload - Hourly or Annual – do not include the two types
        together in the same upload
      </h3>
    </CardBody>
  </Card>
);
InstructionsBlock.displayName = "InstructionsBlock";

type TemplatesBlockProps = {
  excelTemplateHref: string;
  csvTemplateHref: string;
};

export const TemplatesBlock = (props: TemplatesBlockProps) => {
  const { excelTemplateHref, csvTemplateHref } = props;

  return (
    <Card fill>
      <CardHeader>
        <CardHeaderTitle as="h3">Templates</CardHeaderTitle>
      </CardHeader>

      <CardBody>
        <h4>
          Excel -{" "}
          <SimpleLink href={excelTemplateHref} download>
            template.xlsx
          </SimpleLink>
        </h4>
        <h4>
          CSV -{" "}
          <SimpleLink href={csvTemplateHref} download>
            template.csv
          </SimpleLink>
        </h4>
      </CardBody>
    </Card>
  );
};
TemplatesBlock.displayName = "TemplatesBlock";

const RegularUploadPage = (props: CommonChildPageProps) => {
  const {
    store,
    router,
    isPTAdmin,
    hideModal,
    showConfirmationModal,
    closeConfirmationModal,
  } = props;

  // state

  const { uploadingState } = useRecoilValue(contractorsUploadFormGlobalState);
  const { data: uploadsList } = useRecoilValue(contractorsUploadsListGlobalState);

  const disableButtons = uploadingState === FILE_UPLOADING_STATES.PROCESSING;

  // handlers

  const handleBackToProgramsList = useCallback(() => {
    closeConfirmationModal();
    router.push("/private-index/programs");
  }, [router, closeConfirmationModal]);

  const handleFileUploaded = useCallback(() => {
    const header = "Your data has been successfully uploaded.";
    const message = (
      <Box
        css={{
          "& a": {
            display: "block",
            width: "$full",
            textAlign: "center",
          },
        }}
      >
        <p>
          Your file has been successfully uploaded and will be reviewed by our
          compensation analysts. A Pay Intel analyst will contact you about providing a
          Private Labor Index containing a market comparison analysis of your rates. You
          can keep track of the status of your Labor Index on the page below.
        </p>
        <br />
        <h3>
          <a href="tel:1-844-887-5501">
            <Icon icon="phone" /> 1-844-887-5501
          </a>
        </h3>
        <div>
          <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
        </div>
      </Box>
    );
    const footer = (
      <ButtonGroupRight fill>
        <Button onClick={handleBackToProgramsList}>Ok</Button>
      </ButtonGroupRight>
    );

    hideModal();
    showConfirmationModal(message, header, footer);
  }, [handleBackToProgramsList, hideModal, showConfirmationModal]);

  return (
    <Stack>
      <PageHeadBlock
        disableButtons={disableButtons}
        onBackToProgramsList={handleBackToProgramsList}
      />
      <InstructionsBlock />
      <TemplatesBlock
        excelTemplateHref={excelTemplateHref}
        csvTemplateHref={csvTemplateHref}
      />
      <ContractorsUploadFormBlock
        store={store}
        fileSelectTooltipText={fileSelectTooltipText}
        onFileUploaded={handleFileUploaded}
      />
      <RecentUploadsBlock isPTAdmin={isPTAdmin} />
      {uploadsList?.size >= 20 && (
        <PageBottomBlock
          disableButtons={disableButtons}
          onBackToProgramsList={handleBackToProgramsList}
        />
      )}
    </Stack>
  );
};

RegularUploadPage.displayName = "RegularUploadPage";

export default RegularUploadPage;
