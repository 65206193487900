import * as React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "../../../stitches.config";

const Container = styled("span", {
  color: "$accentDark",
  // backgroundColor: "initial",
  // margin: "-4px 0 -4px -8px",
  // padding: "2px 4px",
  // font-size: "inherit",
});

const AppliedOrder = styled("span", {
  color: "$accentDark",
  // marginRight: 2,
  // marginLeft: 2
});

class SortIndicator extends React.Component {
  static displayName = "SortIndicator";
  static propTypes = {
    sortColumn: PropTypes.string.isRequired,
    sortsOrder: PropTypes.object,
    sort: PropTypes.object,
    sortType: PropTypes.oneOf(["alpha", "numeric", "amount"]),
  };
  static defaultProps = {
    sortType: "alpha",
    visible: true,
  };

  isDescending() {
    return this.props.sort
      ? this.props.sort.queryArg.replace(/ /g, "").indexOf("direction:DESC") >= 0
      : false;
  }

  render() {
    const { sort, sortsOrder, sortColumn, sortType } = this.props;

    if (!sortsOrder) return <span />;

    const order = sortsOrder.indexOf(sortColumn);
    const showOrder = sortsOrder.length > 1 && order > -1;
    const isDescending = this.isDescending();
    const icon = isDescending ? `sort-${sortType}-up` : `sort-${sortType}-down`;

    return (
      <Container css={{ display: !sort ? "none" : "inline" }}>
        <AppliedOrder css={{ display: !showOrder ? "none" : "inline" }}>
          {order + 1}
        </AppliedOrder>
        <FontAwesomeIcon icon={icon} className="icon" />
      </Container>
    );
  }
}

class DjangoSortIndicator extends SortIndicator {
  static displayName = "DjangoSortIndicator";

  isDescending() {
    return this.props.sort && this.props.sort.queryArg.startsWith("-");
  }
}
DjangoSortIndicator = observer(DjangoSortIndicator);

export default observer(SortIndicator);
export { DjangoSortIndicator };
