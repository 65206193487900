import { action } from "mobx";
import moment from "moment";

export default class NegotiationWorksheet {
  constructor(store, object) {
    this.store = store;

    if (object) {
      this.worksheetId = object.worksheetId || null;
      this.country = object.country || {};
      this.worksheetName = object.worksheetName || null;
      this.created = moment(object.created) || null;
      this.createdDisplay = `Created ${this.created.format("MMMM D, YYYY")}`;
      this.totalMarkupPercent = object.totalMarkupPercent || null;
      this.payType = object.payType || null;
      this.owner = object.createdBy || {};
      this.payRate = object.payRate || null;
      this.state = object.state || null;
      this.isIc = object.isIc || {};
      this.markupType = object.markupType || null;
      this.worksheetRevisions = object.worksheetRevisions || null;
      this.tags = object.tags || {};
      this.createdBy = object.createdBy || {};
    }

    this.toggleEdit = action(this.toggleEdit.bind(this));
    this.toggleSelected = action(this.toggleSelected.bind(this));
    this.destroy = action(this.destroy.bind(this));
  }

  toggleEdit() {
    const viewState = this.store.negotiationListViewState.get(this.worksheetId);
    viewState.editing = !viewState.editing;
  }

  toggleSelected(e, ignore1 = null, ignore2 = null, setValue = null) {
    const viewState = this.store.negotiationListViewState.get(this.worksheetId);

    if (setValue !== null) {
      viewState.selected = setValue;
      return;
    } else {
      viewState.selected = !viewState.selected;

      if (!viewState.selected && this.store.allSelected) {
        this.store.allSelected = false;
      }
    }

    if (!this.store.allowMultipleItemSelection) {
      // deselect all other jobs
      this.store.negotiationListViewState.forEach((viewState) => {
        if (this.viewState === viewState) return;

        viewState.selected = false;
      });
    }
  }

  destroy() {
    this.store.negotiationList.remove(this);
  }

  toJS() {
    return {
      regionId: this.regionId,
      name: this.name,
      country: this.country,
    };
  }

  static fromJS(store, object) {
    return new NegotiationWorksheet(store, object);
  }
}
