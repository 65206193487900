import React from "react";

import Stack from "../../../../components/lib/Stack";
import Grid from "../../../../components/lib/Grid";
import Alert from "../../../../components/lib/Alert";
import Button from "../../../../components/lib/Button";
import Text from "../../../../components/lib/Text";
import DisplayCurrencySelector from "../../components/DisplayCurrencySelector";
import RateTypeSelector from "../../components/RateTypeSelector";
import RatesWithFeedbackPreview from "../../components/Rates/RatesWithFeedbackPreview";
import {
  Card,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
  CardHeaderTitle,
  CardBody,
} from "../../../../components/lib/Card";
import { RATE_TYPES, RateResultsOrderedMap } from "../../types";
import { emptyMap, emptyOrderedMap } from "../../../../constants";
import { useAttemptDataContext } from "../../context/AttemptDataContext";
import { useReviewDataContext } from "../../context/ReviewDataContext";
import { useRateDisplayContext } from "../../context/RateDisplayContext";

function RatesValidationBlock() {
  const {
    displayRateType,
    displayCurrencyData,
    isDisplayCurrencyChanged,
    changeDisplayRateType: handleChangeRateType,
    changeDisplayCurrency: handleChangeCurrency,
    resetDisplayCurrency: handleResetCurrency,
  } = useRateDisplayContext();

  const { attemptData } = useAttemptDataContext();
  const { firstJobData, firstJobRateType, isThreeLevelBanding } = useReviewDataContext();

  const ratesFeedback = attemptData.get("rates_feedback") || emptyOrderedMap;
  const jobRatesFeedback = ratesFeedback.filter(
    (item) => item.get("job") === firstJobData.get("id")
  );
  const jobRateResults = firstJobData.get(
    "rate_results",
    emptyOrderedMap as unknown as RateResultsOrderedMap
  );

  if (jobRateResults.size === 0) {
    return null;
  }

  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft>
          <CardHeaderTitle as="h3">Validated Rates</CardHeaderTitle>
        </CardActionsLeft>
        <CardActionsRight>
          {firstJobRateType !== RATE_TYPES.ANNUAL && (
            <RateTypeSelector
              rateType={displayRateType}
              job={firstJobData}
              onChange={handleChangeRateType}
            />
          )}
          {displayCurrencyData.size > 0 && !isDisplayCurrencyChanged && (
            <DisplayCurrencySelector
              currencyData={displayCurrencyData}
              onChange={handleChangeCurrency}
            />
          )}
          {displayCurrencyData.size > 0 && isDisplayCurrencyChanged && (
            <Button color="brand" variant="outlined" onClick={handleResetCurrency}>
              Reset Currency Back
            </Button>
          )}
        </CardActionsRight>
      </CardActions>
      <CardBody>
        <Stack css={{ alignItems: "start", width: "$full" }}>
          {isThreeLevelBanding && (
            <Alert fill color="warning">
              <Text bold>IMPORTANT:</Text> This survey has three level banding results to
              validate.
            </Alert>
          )}
          <Grid
            css={{
              width: "$full",
              gridGap: "$8",
              alignItems: "center",
              fontSize: "$base",
              gridTemplateColumns: "repeat(1, auto)",
              "@md": {
                gridTemplateColumns: "repeat(2, auto)",
              },
              "@lg": {
                gridTemplateColumns: "repeat(3, auto)",
              },
            }}
          >
            {jobRateResults.toArray().map((rateResult) => {
              const rateResultId = rateResult.get("id");

              return (
                <RatesWithFeedbackPreview
                  key={rateResultId}
                  rateResult={rateResult}
                  rateFeedback={jobRatesFeedback.get(rateResultId) ?? emptyMap}
                />
              );
            })}
          </Grid>
        </Stack>
      </CardBody>
    </Card>
  );
}

RatesValidationBlock.displayName = "RatesValidationBlock";

export default RatesValidationBlock;
