import React from "react";
import { styled } from "../../../../stitches.config";
// lib
import Inline from "../../../../components/lib/Inline";
import Stack from "../../../../components/lib/Stack";
import Text from "../../../../components/lib/Text";

const AvgLine = styled("div", {
  height: "$3",
  display: "flex",
  width: "$12",
  justifyContent: "center",
  "& span": {
    display: "block",
    height: "$3",
    borderRight: "1px dashed white",
    borderLeft: "1px dashed white",
  },
});

const AvgCardSm = styled(Stack, {
  alignItems: "start",
  gap: "$2",
  padding: "$6",
  borderTop: "solid 1px $primaryLight",
  "@md": {
    borderTop: "none",
    borderRight: "solid 1px $primaryLight",
  },
  "&:first-child": {
    borderTop: "none",
    "@md": {
      paddingLeft: 0,
    },
  },
  "&:last-child": {
    "@md": {
      paddingRight: 0,
      borderRight: 0,
    },
  },
});

const AvgChartColor = styled("div", {
  height: "$3",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  variants: {
    variant: {
      brand: {
        background: "linear-gradient(to left, $brandLight, $brandDark, $brandLight)",
      },
      blue: {
        background: "linear-gradient(225deg, #0E83FE 0%, #41CCD2 100%)",
      },
      green: {
        background: "linear-gradient(225deg, #85CBA1 0%, #A2F4C3 100%)",
      },
      yellow: {
        background: "linear-gradient(225deg, #B19B02 0%, #F9E558 100%)",
      },
    },
  },
});

const Details = styled(Inline, {
  justifyContent: "space-between",
  fontSize: "$xs",
  flexWrap: "nowrap",
  "& .title": {
    fontWeight: "$semibold",
  },
  "& > div": {
    alignItems: "center",
    textAlign: "center",
  },
});

const Heading = styled(Stack, {
  justifyContent: "flex-start",
  textAlign: "center",
  gap: "$1",
  alignItems: "center",
});

type AverageRateChartSmProps = {
  title: string;
  medianRate: string;
  minRate: string;
  maxRate: string;
  variant: "blue" | "green" | "yellow" | "brand";
};

export function AverageRateChartSm(props: AverageRateChartSmProps) {
  const { medianRate, maxRate, minRate, title, variant } = props;
  const [rate, suffix] = medianRate.split("/");
  return (
    <AvgCardSm>
      <Heading>
        <Text color="primary">{title}</Text>
        <Text noMargin as="h4">
          {suffix ? (
            <>
              {rate}
              <sup>/{suffix}</sup>
            </>
          ) : (
            medianRate
          )}
        </Text>
      </Heading>

      <AvgChartColor variant={variant}>
        <AvgLine>
          <span />
        </AvgLine>
        <AvgLine>
          <span />
        </AvgLine>
        <AvgLine>
          <span />
        </AvgLine>
      </AvgChartColor>

      <Details fill>
        <Stack nogap>
          <Text color="dark" className="title">
            Min
          </Text>
          <Text color="dark">{minRate}</Text>
        </Stack>
        <Stack nogap>
          <Text color="dark" className="title">
            Median
          </Text>
          <Text color="dark">{medianRate}</Text>
        </Stack>
        <Stack nogap>
          <Text color="dark" className="title">
            Max
          </Text>
          <Text color="dark">{maxRate}</Text>
        </Stack>
      </Details>
    </AvgCardSm>
  );
}
