import React from "react";
// LIB
import Inline from "../../../components/lib/Inline";
import { SimpleLink } from "../../../components/lib/Link";
import Box from "../../../components/lib/Box";
// components
import { LibraryDialog } from "./LibraryDialog";
import RsSearchBySkillsDialog from "../components/RsSearchBySkillsDialog";
import { JobTitleInfo } from "../types";
import { useGlobalContext } from "../../../globalContext";
import { useRateSearchFormStore } from "../../../stores/RateSearchForm";

type Props = {
  onResetJobOptions(): void;
  handleGetSearchResults: (jobTitleInfo: JobTitleInfo) => void;
};

export function JobTitleModals(props: Props) {
  const { onResetJobOptions, handleGetSearchResults } = props;
  const { sessionInfo, isClientJobLibrary, perSearchPricing } = useGlobalContext();
  const jobLibrary = useRateSearchFormStore((s) => s.jobLibrary);
  const countryLibrary = useRateSearchFormStore((s) => s.countryLibrary);

  if (!perSearchPricing) {
    return (
      <Inline css={{ gap: "$1" }}>
        <LibraryDialog onResetJobOptions={onResetJobOptions}>
          <SimpleLink data-testid="job-library-link">
            Job Library
            {jobLibrary?.name && ": " + jobLibrary?.name}
            {countryLibrary?.title && ": " + countryLibrary?.title}
          </SimpleLink>
        </LibraryDialog>
        {isClientJobLibrary && (
          <>
            <Box>or</Box>
            <RsSearchBySkillsDialog
              handleGetSearchResults={handleGetSearchResults}
              clientId={sessionInfo?.client?.id}
              perSearchPricing={perSearchPricing}
            >
              <SimpleLink data-testid="search-by-skills-link">
                Search by Skills
              </SimpleLink>
            </RsSearchBySkillsDialog>
          </>
        )}
      </Inline>
    );
  } else if (isClientJobLibrary) {
    return (
      <RsSearchBySkillsDialog
        handleGetSearchResults={handleGetSearchResults}
        clientId={sessionInfo?.client?.id}
        perSearchPricing={perSearchPricing}
      >
        <SimpleLink>Search by Skills</SimpleLink>
      </RsSearchBySkillsDialog>
    );
  }

  return null;
}
