// @flow
import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToggleButton from "../views/ratecards/components/ToggleButton";
import type { SortIconKind } from "./SortIcon";

type Props = {
  text: string,
  selected: boolean,
  onClick: ("DESC") => void,
  large?: boolean,
  kind?: "radio" | "checkbox",
  iconKind?: SortIconKind,
};

function SortDescToggleButton(props: Props): React.Node {
  const {
    text,
    selected,
    onClick,
    large = false,
    kind = "radio",
    iconKind = "alpha",
  } = props;

  return (
    <ToggleButton
      large={large}
      type={kind}
      name="sortDesc"
      item="DESC"
      value="DESC"
      selected={selected}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={`sort-${iconKind}-up`} className="icon" /> {text}
    </ToggleButton>
  );
}

export default SortDescToggleButton;
