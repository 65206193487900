// @flow
import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToggleButton from "../views/ratecards/components/ToggleButton";
import type { SortIconKind } from "./SortIcon";

type Props = {
  text: string,
  selected: boolean,
  onClick: ("ASC") => void,
  large?: boolean,
  kind?: "radio" | "checkbox",
  iconKind?: SortIconKind,
};

function SortAscToggleButton(props: Props): React.Node {
  const {
    text,
    selected,
    onClick,
    large = false,
    kind = "radio",
    iconKind = "alpha",
  } = props;

  return (
    <ToggleButton
      large={large}
      type={kind}
      name="sortAsc"
      item="ASC"
      value="ASC"
      selected={selected}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={`sort-${iconKind}-down`} className="icon" /> {text}
    </ToggleButton>
  );
}

export default SortAscToggleButton;
