import React from "react";
import ReactDOM from "react-dom";
import { List } from "immutable";

import { styled } from "../../../stitches.config";
import { AutoSearchBox } from "../../lib/SearchBox";
import Icon from "../../lib/Icon";
import { FilterTypes, emptyList } from "../constants";
import Stack from "../../lib/Stack";
import Inline from "../../lib/Inline";
import Text from "../../lib/Text";
import { FilterConfigMap, FilterTypesType } from "../types";
import { OnChangeFilterValueFunction } from "./types";

export const useSearchInputState = (
  search: string,
  filter: FilterConfigMap,
  filterType: FilterTypesType,
  onChange: OnChangeFilterValueFunction
) => {
  const makeSearch = React.useCallback(
    (value) => {
      if (value === search) {
        return;
      }

      if (filterType === FilterTypes.VALUES_ICONTAINS) {
        onChange(
          filter
            .setIn(["filter", "search"], value)
            .setIn(["filter", "type"], FilterTypes.VALUES_ICONTAINS)
            .setIn(
              ["filter", "values"],
              value ? List([value]) : emptyList
            ) as FilterConfigMap
        );
      } else if (filterType === FilterTypes.VALUES_CHECKLIST) {
        onChange(
          filter
            .setIn(["filter", "search"], value)
            .setIn(["filter", "type"], FilterTypes.VALUES_CHECKLIST) as FilterConfigMap
        );
      }
    },
    [search, filter, filterType, onChange]
  );

  let searchInputRef: React.ReactInstance | null = null;
  const setSearchInputRef: React.RefCallback<HTMLInputElement> = (reactComponent) =>
    (searchInputRef = reactComponent);
  const setSearchFocus = () => {
    if (searchInputRef) {
      const inputNode = ReactDOM.findDOMNode(searchInputRef) as HTMLInputElement;
      inputNode && inputNode.focus();
    }
  };

  return {
    makeSearch,
    setSearchInputRef,
    setSearchFocus,
  };
};

export const ShortcutButton = styled(Text, {
  cursor: "pointer",
  color: "$primary",
  "& .fa-check": {
    color: "$success",
  },
  "& .fa-times": {
    color: "$danger",
  },
  "&:hover": {
    color: "$primaryDarker",
    "& .fa-check": {
      color: "$successDarker",
    },
    "& .fa-times": {
      color: "$dangerDarker",
    },
  },
  "&:active": {
    transform: "scale(1.02)",
  },
});

type ValuesBlockProps = {
  searchValue: string;
  searchTimeout: number;
  inputRef: React.RefCallback<HTMLInputElement>;
  maxSelectItems?: number;
  itemsCount?: number;
  onSearch: (searchTerm: string | null) => void;
  onSelectAll?: React.MouseEventHandler<HTMLSpanElement>;
  onUnselectAll?: React.MouseEventHandler<HTMLSpanElement>;
  children: React.ReactElement;
};

export const ValuesBlock = (props: ValuesBlockProps) => {
  const {
    searchValue: initialSearchValue,
    searchTimeout,
    inputRef,
    onSearch,
    onSelectAll,
    onUnselectAll,
    maxSelectItems,
    itemsCount,
    children,
  } = props;

  const placeholder =
    onSelectAll && onUnselectAll ? "Select values range: " : "Enter query text:";

  const [searchValue, setSearchValue] = React.useState(initialSearchValue);

  return (
    <Stack fill css={{ alignItems: "stretch", gap: "$2" }}>
      <span>{placeholder}</span>
      <Stack fill css={{ alignItems: "stretch", paddingLeft: "$2", gap: "$2" }}>
        <AutoSearchBox
          ref={inputRef}
          placeholder="Search for values"
          value={searchValue || ""}
          timeout={searchTimeout}
          onChange={(value) => setSearchValue(value as string)}
          onSubmit={(value) => onSearch(value as string)}
          css={{ width: "$full" }}
        />

        {onSelectAll && onUnselectAll && (
          <Inline fill>
            <ShortcutButton onClick={onSelectAll}>
              <Icon icon="check" />
              &nbsp;
              <span>
                {maxSelectItems != null &&
                itemsCount != null &&
                maxSelectItems < itemsCount
                  ? `Check first ${maxSelectItems} items`
                  : "Check all"}
              </span>
            </ShortcutButton>
            <ShortcutButton onClick={onUnselectAll}>
              <Icon icon="times" />
              &nbsp;
              <span>Uncheck all</span>
            </ShortcutButton>
          </Inline>
        )}

        {children}
      </Stack>
    </Stack>
  );
};
ValuesBlock.displayName = "ValuesBlock";
