import React, { useEffect, useState } from "react";
import { fetchAPINew, FetchAPIOptionsType } from "../../../utils/fetch";
import {
  searchBySkillMatchQuery,
  searchBySkillMatchQueryNoClient,
  SKILL_MIN_SIMILARITY,
  SKILL_TOP_K,
} from "../rsSearchBySkillsUtil";
import Inline from "../../../components/lib/Inline";
import Stack from "../../../components/lib/Stack";
import Button from "../../../components/lib/Button";
import Box from "../../../components/lib/Box";
import RsSkillsWithJobDescription from "../JobTittleSkills";
import RsViewSkills from "./RsViewSkills";
import Text from "../../../components/lib/Text";
import { styled } from "../../../stitches.config";
import { DialogClose } from "../../../components/lib/Dialog";

const Textarea = styled("textarea", {
  borderRadius: "$rounded",
  border: "solid 1px $primaryLight",
  fontSize: "$base",
  padding: "$4",
  width: "100%",
  resize: "vertical",
});

const RsSearchBySkillsHeader = ({
  fetchJobResultsHandler,
  preJobDescription = "",
  preJobTitle = "",
  preSkills = "",
  clientId,
  perSearchPricing,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [jobDescription, setJobDescription] = useState(preJobDescription);

  useEffect(() => {
    if (jobDescription === "") {
      return;
    }

    let ignore = false;

    let jobVariables = {
      jobTitle: "",
      jobDescription: jobDescription,
      topK: SKILL_TOP_K,
      minSimilarity: SKILL_MIN_SIMILARITY,
      serviceType: perSearchPricing ? "public" : "total",
    };

    if (!perSearchPricing) {
      jobVariables["clientId"] = clientId;
    }

    async function startFetchingJobs() {
      const resultingOptions: FetchAPIOptionsType = {
        headers: {
          accept: "application/json",
          // "x-session-id": legacySession,
        },
        method: "POST",
        body: {
          query: perSearchPricing
            ? searchBySkillMatchQueryNoClient
            : searchBySkillMatchQuery,
          variables: jobVariables,
        },
      };
      const json = await fetchAPINew(`/api/taste/graphql/`, resultingOptions);
      if (!ignore) {
        if (json?.["data"]?.["data"]?.["semanticTitleMatch"]) {
          fetchJobResultsHandler(json["data"]["data"]["semanticTitleMatch"]);
        } else {
          fetchJobResultsHandler([]);
        }
      }
    }

    startFetchingJobs();

    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobDescription]);

  const onChangeSearchInput = (event) => {
    setSearchInput(event.target.value);
  };

  const onSearch = () => {
    setJobDescription(searchInput);
  };

  const getSkills = () => {
    if (preSkills !== "") {
      return preSkills.split(",");
    }
  };

  return (
    <Box css={{ width: "inherit", display: "flex" }}>
      {preJobDescription === "" && (
        <Stack css={{ width: "inherit", alignItems: "flex-start", gap: "$4" }}>
          <Text as="h3" noMargin color="dark">
            Search by Skills
          </Text>

          <Textarea
            placeholder="Enter skills as a free text, or enter job description"
            rows={3}
            value={searchInput}
            onBlur={onSearch}
            onChange={onChangeSearchInput}
            css={{
              width: "100%",
              borderRadius: "$rounded",
            }}
            data-testid="search-by-skills-text-area"
          />

          <Inline
            fill
            css={{
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={onSearch}
              variant="filled"
              color="brand"
              size="small"
              disabled={searchInput === ""}
            >
              Search
            </Button>
            <DialogClose asChild>
              <Button variant="outlined" color="brand" size="small">
                Close
              </Button>
            </DialogClose>
          </Inline>
          {jobDescription !== "" && (
            <RsSkillsWithJobDescription
              showSkillLabel={false}
              jobDescription={jobDescription}
            />
          )}
        </Stack>
      )}
      {preSkills !== "" && (
        <Stack css={{ alignItems: "flex-start", marginBottom: "$3" }}>
          <h4>{`Jobs similar to ${preJobTitle}`}</h4>
          <RsViewSkills skills={getSkills()} />
        </Stack>
      )}
    </Box>
  );
};

export default RsSearchBySkillsHeader;
