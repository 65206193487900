import React from "react";
import {
  Checkbox,
  CheckboxIndicator,
  LabelInline,
} from "../../../components/lib/Checkbox";
import Icon from "../../../components/lib/Icon";
import { useRateSearchFormStore } from "../../../stores/RateSearchForm";

export function GssCheckbox() {
  const isGss = useRateSearchFormStore((s) => s.isGss);
  const setIsGss = useRateSearchFormStore((s) => s.setIsGss);
  const showGss = useRateSearchFormStore((s) => s.showGss());

  if (!showGss) {
    return null;
  }

  return (
    <LabelInline>
      <Checkbox checked={Boolean(isGss)} onCheckedChange={setIsGss}>
        <CheckboxIndicator>
          <Icon icon="check" />
        </CheckboxIndicator>
      </Checkbox>
      Global Supplier Search
    </LabelInline>
  );
}
