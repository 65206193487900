/**
 * @generated SignedSource<<01543bbb2ff418df7a82f0cf83eff7fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PayDifferenceMapRefetch$variables = {
  locationIDs?: ReadonlyArray<string | null> | null;
};
export type PayDifferenceMapRefetch$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"PayDifferenceMap_viewer">;
  } | null;
};
export type PayDifferenceMapRefetch = {
  response: PayDifferenceMapRefetch$data;
  variables: PayDifferenceMapRefetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": ([]/*: any*/),
    "kind": "LocalArgument",
    "name": "locationIDs"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PayDifferenceMapRefetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "locationIDs",
                "variableName": "locationIDs"
              }
            ],
            "kind": "FragmentSpread",
            "name": "PayDifferenceMap_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PayDifferenceMapRefetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "ids",
                "variableName": "locationIDs"
              }
            ],
            "concreteType": "LocationListLookupNode",
            "kind": "LinkedField",
            "name": "locationsByIds",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "locationId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lat",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lon",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e6d22bca6fd69edcf9ea68cfefd9f737",
    "id": null,
    "metadata": {},
    "name": "PayDifferenceMapRefetch",
    "operationKind": "query",
    "text": "query PayDifferenceMapRefetch(\n  $locationIDs: [String] = []\n) {\n  viewer {\n    ...PayDifferenceMap_viewer_1wnYSS\n  }\n}\n\nfragment PayDifferenceMap_viewer_1wnYSS on Viewer {\n  locationsByIds(ids: $locationIDs) {\n    locationId\n    lat\n    lon\n  }\n}\n"
  }
};
})();

(node as any).hash = "e9ef48d6b7777354485487b810b087ff";

export default node;
