import React from "react";
import TickerLoader from "../../../components/lib/TickerLoader";

const Loadable = (props) => {
  const { isLoading, ...restProps } = props;

  return isLoading ? <TickerLoader /> : <div {...restProps} />;
};

export default Loadable;
