import React, { Suspense, useCallback, useEffect, useMemo } from "react";
// relay
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ResultTabs_viewer$key } from "./__generated__/ResultTabs_viewer.graphql";
// LIB
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../components/lib/Tabs";
import Stack from "../../../components/lib/Stack";
// components
import { MedianPayChart } from "./MedianPayChart";
import { RateDetail } from "./RateDetail";
import { PayDifference, PayDifferenceLoader } from "./PayDifference";
import { RsNegotiationWorksheet } from "./RsNegotiationWorksheet";
import { PayChartLoader } from "./MedianPayChart/PayChartLoader";
import { useRateSearchFormStore } from "../../../stores/RateSearchForm";
import { useSearchDetails } from "../hooks/useSearchDetails";
import moment from "moment";
import { Placeholder } from "../../../components/lib/Placeholder";
import Inline from "../../../components/lib/Inline";
import { useSearchResultsStore } from "../../../stores/SearchResults";
import { jobTitleObserver } from "../observers";
import { HiringOverview } from "./HiringIntelligence";
import HiringIntelLoader from "./HiringIntelligence/Loader";
import { BellCurveBillRates } from "./Healthcare/BellCurveBillRates";

const SearchResultTabs = ["SearchResult", "NegotiationWorksheet"] as const;

const fragment = graphql`
  fragment ResultTabs_viewer on Viewer @argumentDefinitions(searchId: { type: "Int!" }) {
    ...PayDifference_viewer
    countries {
      id @required(action: NONE)
      locationId @required(action: NONE)
      isoCode @required(action: NONE)
      name @required(action: NONE)
    }
    savedsearch(id: $searchId) {
      id
      rateType
      workerTypeId
      workerTypeName
      createdDate
      currency {
        iso @required(action: NONE)
      }
      region {
        regionId @required(action: NONE)
      }
      job {
        jobTitleId
        jobTitle
      }
      locationId
      industry {
        id
        legacyId
        value
      }
      ...MedianPayChart_savedsearch
      ...RateDetail_savedsearch
      ...BellCurveBillRates_savedsearch
    }
  }
`;

type Props = {
  viewer: ResultTabs_viewer$key;
};

export function ResultTabs({ viewer }: Props) {
  const viewerData = useFragment(fragment, viewer);

  const jobTitleName = viewerData.savedsearch?.job.jobTitle;
  const isContract = viewerData.savedsearch?.rateType === 1;
  const jobTitleId = viewerData.savedsearch?.job.jobTitleId;
  const industry = viewerData.savedsearch?.industry;
  const createdDate = viewerData.savedsearch?.createdDate;
  const locationId = viewerData.savedsearch?.locationId;
  const isRegionSearch = Boolean(viewerData.savedsearch?.region?.regionId);

  const isCountryLocationSearch = useMemo(() => {
    const countries = viewerData.countries ?? [];
    return countries.some((country) => country?.locationId === locationId);
  }, [locationId, viewerData]);

  const workerType = useMemo(
    () => ({
      description: null,
      name: viewerData.savedsearch?.workerTypeName || null,
      workerTypeId: viewerData.savedsearch?.workerTypeId || null,
    }),
    [viewerData]
  );

  const setJobTitle = useRateSearchFormStore((s) => s.setJobTitle);
  const setIndustries = useRateSearchFormStore((s) => s.setIndustries);
  const setLocations = useRateSearchFormStore((s) => s.setLocations);
  const setRegions = useRateSearchFormStore((s) => s.setRegions);
  const setRateType = useRateSearchFormStore((s) => s.setRateType);
  const setWorkerType = useRateSearchFormStore((s) => s.setWorkerType);
  const setCurrency = useSearchResultsStore((s) => s.setCurrency);

  if (!jobTitleName || !jobTitleId || !industry?.legacyId || !createdDate) {
    throw new Error("Record not found");
  }

  // Sends query to get all the data nedeed to initialize the side menu form
  const { jobTitle, locations, regions, searchIDs } = useSearchDetails({
    jobTitleId,
    jobTitle: jobTitleName,
    industryId: String(industry?.legacyId),
    toDateTime: moment(createdDate).add(8, "seconds").toISOString(),
    fromDateTime: moment(createdDate).subtract(8, "seconds").toISOString(),
  });
  // initializes the side menu form
  const setSearchDetails = useCallback(() => {
    setJobTitle(jobTitle);
    jobTitleObserver.emit(jobTitle);
    setIndustries(industry ? [industry] : []);
    setRegions(regions || []);
    setRateType(isContract ? "Contract" : "FTE");
    setWorkerType(workerType);
    // Since locations reset when JobTitle changes,
    // we need to add a little delay, otherwise this initialization won't work.
    setTimeout(() => setLocations(locations || []), 5);
  }, [
    jobTitle,
    industry,
    setJobTitle,
    setIndustries,
    locations,
    regions,
    setLocations,
    setRegions,
    isContract,
    setRateType,
    workerType,
    setWorkerType,
  ]);

  // TODO: fix set state in use useEffect - find a better way.
  useEffect(() => {
    if (jobTitleId) {
      setSearchDetails();
    }
  }, [jobTitleId, setSearchDetails]);

  useEffect(() => {
    if (viewerData.savedsearch?.currency) {
      setCurrency(viewerData.savedsearch.currency.iso);
    }
  }, [setCurrency, viewerData]);

  if (!viewerData.savedsearch) {
    return null;
  }

  const showPayDifference = locations.length > 1;

  return (
    <Tabs defaultValue={SearchResultTabs[0]} css={{ width: "100%" }}>
      <TabsList css={{ width: "400px" }}>
        <TabsTrigger value={SearchResultTabs[0]} css={{ cursor: "pointer" }}>
          Search Results
        </TabsTrigger>
        {isContract && (
          <TabsTrigger value={SearchResultTabs[1]} css={{ cursor: "pointer" }}>
            Negotiation Worksheet
          </TabsTrigger>
        )}
      </TabsList>

      <TabsContent value={SearchResultTabs[0]}>
        <Stack css={{ alignItems: "flex-start", flex: 1, gap: "$8" }}>
          {jobTitle?.isHealthcare ? (
            <BellCurveBillRates
              isContract={isContract}
              savedsearch={viewerData.savedsearch}
            />
          ) : (
            <MedianPayChart savedsearch={viewerData.savedsearch} />
          )}

          {!isRegionSearch && locationId && jobTitleId && (
            <Suspense fallback={<HiringIntelLoader />}>
              <HiringOverview
                jobTitleId={jobTitleId}
                locationId={locationId}
                isCountryLocationSearch={isCountryLocationSearch}
              />
            </Suspense>
          )}

          {!jobTitle?.isHealthcare && <RateDetail savedsearch={viewerData.savedsearch} />}

          {showPayDifference && (
            <Suspense fallback={<PayDifferenceLoader />}>
              <PayDifference
                isHealthcare={jobTitle?.isHealthcare ?? false}
                isContract={isContract}
                searchIDs={searchIDs}
                viewer={viewerData}
                setSearchDetails={setSearchDetails}
              />
            </Suspense>
          )}
        </Stack>
      </TabsContent>

      {isContract && (
        <TabsContent value={SearchResultTabs[1]}>
          <RsNegotiationWorksheet />
        </TabsContent>
      )}
    </Tabs>
  );
}

export function ResultTabsLoader() {
  return (
    <Tabs defaultValue={SearchResultTabs[0]} css={{ width: "100%" }}>
      <TabsList css={{ width: "400px" }}>
        <TabsTrigger value={SearchResultTabs[0]}>Search Results</TabsTrigger>
      </TabsList>
      <TabsContent value={SearchResultTabs[0]}>
        <Stack css={{ alignItems: "flex-start", flex: 1, gap: "$8" }}>
          <PayChartLoader />
          <HiringIntelLoader />
          <Inline fill css={{ justifyContent: "flex-end", alignItems: "flex-end" }}>
            <Placeholder height="$6" width="$10" />
            <Placeholder height="$6" width="$10" />
            <Placeholder height="$6" width="$10" />
          </Inline>
          <Placeholder height={310} />
        </Stack>
      </TabsContent>
    </Tabs>
  );
}
