import { Table as TableBase, TH, TD } from "../../../components/lib/Table";
import { styled } from "../../../stitches.config";

const Table = styled(TableBase, {
  [`& ${TH}`]: {
    textAlign: "center !important",
  },

  variants: {
    center: {
      true: {
        [`& ${TD}`]: {
          textAlign: "center !important",
        },
      },
      false: {
        [`& ${TD}`]: {
          textAlign: "left !important",
        },
      },
    },

    withBorder: {
      true: {
        "& > *": {
          border: "1px solid $primaryLight !important",
        },

        [`& ${TH}, & ${TD}`]: {
          border: "1px solid $primaryLight !important",
        },
      },
    },

    highlightFirstColumn: {
      true: {
        [`${TD}:not(:first-child)`]: {
          backgroundColor: "$white",
        },

        [`& ${TH}, & ${TD}`]: {
          backgroundColor: "$primaryLightest",
        },
      },
    },
  },

  defaultVariants: {
    center: false,
    withBorder: true,
    highlightFirstColumn: false,
  },
});
Table.displayName = "Table";

export default Table;
