// @flow

import React, { Component } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingIndicator from "../../ratecards/components/LoadingIndicator";
import JobsByCountryChoroplethMap from "./JobsByCountryChoroplethMap";
import { OverlayTrigger, Popover } from "react-bootstrap";
import RateCardDetailStore from "../../../stores/mobx/RateCardDetailStore";

type Props = {
  store: RateCardDetailStore,
};

class JobsByCountryChloropethPanel extends Component<Props> {
  _renderHintPopover = () => {
    return (
      <Popover
        className="pt-ui-popover"
        id="popover-positioned-top"
        title="About This Chart"
      >
        <p className="text-xx-small">
          Shows how the jobs on the rate card are distributed across the United States.
        </p>
        <h5>Restrictions</h5>
        <p className="text-xx-small">
          Only jobs on the rate card that are within the United States are presented on
          this chart. Jobs at a national level or region are excluded.
        </p>
      </Popover>
    );
  };

  render() {
    const props = this.props;
    const store = props.store;
    const state = store.jobsByCountryChoroplethState;
    const loading = store.network.loading || state.network.loading;

    return (
      <div className="panel panel-default no-margin">
        <div className="panel-heading">
          <div className="flex center-items">
            <div>
              <h3 className="panel-title">
                US Job Count by State
                <OverlayTrigger placement="top" overlay={this._renderHintPopover()}>
                  <FontAwesomeIcon
                    icon="question-circle"
                    className="icon"
                    style={{ marginLeft: ".25em" }}
                  />
                </OverlayTrigger>
              </h3>
            </div>
            <div style={{ flexGrow: 1 }} />
          </div>
        </div>
        <div className="panel-body" style={{ padding: 0 }}>
          <div style={{ position: "relative", height: 425, overflow: "hidden" }}>
            {!loading && (
              <JobsByCountryChoroplethMap
                usOnly={state.chartData.usOnly}
                geojson={state.chartData.geojson}
              />
            )}
            {loading && (
              <div
                className={classNames({
                  "loading-indicator-container": true,
                  show: loading,
                })}
              >
                <LoadingIndicator
                  style={{
                    height: 425,
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default observer(JobsByCountryChloropethPanel);
