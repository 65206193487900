// @flow

import PunchOutRowState from "../../../models/PunchOutRowState";
import { LEVEL_SPARKLINE_MONTH_FORMAT } from "../../../constants/dateFormats";
import moment from "moment";
import numeral from "numeral";
import Search from "../../../models/Search";
import type { PunchOutRowByLevel } from "../../../models/BuyRate";
import BuyRateState from "../../../models/BuyRateState";
import PunchOutRow from "../../../models/PunchOutRow";
import type { FetchGraphQL } from "../../../App";

// ----------------------------------------------------------------------------
//
// Internal functions
//
// ----------------------------------------------------------------------------
function fillDataGaps(data) {
  let ratesData = data.ratesData;
  let changesData = data.changesData;
  let missingRates = [];
  let missingChanges = [];

  let rateValue = null;
  let changeValue = null;
  let nextIndex = 0;
  let prevIndex = 0;
  let prevValueFound = false;
  let nextValueFound = false;

  for (let currentIndex = 0; currentIndex < ratesData.length; currentIndex++) {
    rateValue = ratesData[currentIndex];
    changeValue = changesData[currentIndex];
    nextIndex = currentIndex + 1;
    prevIndex = currentIndex - 1;
    prevValueFound = false;
    nextValueFound = false;

    if (rateValue !== null) {
      if (nextIndex < ratesData.length) {
        if (ratesData[nextIndex] === null) {
          missingRates.push(rateValue);
          missingChanges.push(changeValue);
          continue;
        }
      }

      if (prevIndex >= 0) {
        if (ratesData[prevIndex] === null) {
          missingRates.push(rateValue);
          missingChanges.push(changeValue);
          continue;
        }
      }

      missingRates.push(null);
      missingChanges.push(null);
      continue;
    }

    // find previous value and use that
    while (prevIndex >= 0 && !prevValueFound) {
      prevValueFound = ratesData[prevIndex] !== null;
      prevIndex--;
    }

    if (prevValueFound) {
      prevIndex++; // because it decrements before breaking the while loop
      missingRates.push(ratesData[prevIndex]);
      missingChanges.push(changesData[prevIndex]);
      continue;
    }

    // or find next value and use that
    while (nextIndex < ratesData.length && !nextValueFound) {
      nextValueFound = ratesData[nextIndex] !== null;
      nextIndex++;
    }

    if (nextValueFound) {
      nextIndex--; // because it increments before breaking the while loop
      missingRates.push(ratesData[nextIndex]);
      missingChanges.push(changesData[nextIndex]);
      continue;
    }

    // if there are no values return a fix point
    missingRates.push(10);
    missingChanges.push(10);
  }

  data.missingRates = missingRates;
  data.missingChanges = missingChanges;

  return data;
}

function findResultsForMonth(resultLevel, month, monthFormat) {
  // find history element for current month
  return resultLevel.history.find((historyItem) => {
    // since resultLevel.history is sorted by default
    // on the creation date from newest to oldest,
    // if there is more than one value for the same month,
    // the first element to match is the latest value for the month
    let createdDate = moment(historyItem.createdDate);
    return createdDate.format(monthFormat) === month;
  });
}

// ----------------------------------------------------------------------------
//
// External functions
//
// ----------------------------------------------------------------------------
export const CHART_MONTHFORMAT = "MMM, YYYY";

export const buildMonthsList = function (monthCount: number, format: string): Object[] {
  let top = monthCount < 0 ? 1 : monthCount;
  let count = monthCount < 0 ? monthCount + 1 : 0; // add 1 to include current month
  let months = [];

  while (count < top) months.push(moment().add(count++, "months").format(format));

  return months;
};

export const buildData = function (
  resultLevel: Object,
  propertyName: string,
  months: Object[]
) {
  let ratesData = [];
  let changesData = [];
  let referenceValue = resultLevel[propertyName];
  let change = 0;

  let data = {
    ratesData: ratesData,
    changesData: changesData,
    missingRates: [],
    missingChanges: [],
  };

  if (!resultLevel) return data;

  for (let currentIndex = 0; currentIndex < months.length; currentIndex++) {
    let month = months[currentIndex];
    let resultsForMonthFound = findResultsForMonth(
      resultLevel,
      month,
      LEVEL_SPARKLINE_MONTH_FORMAT
    );

    if (resultsForMonthFound) {
      ratesData.push(resultsForMonthFound[propertyName]);
      change = resultsForMonthFound[propertyName] - referenceValue;
      changesData.push(parseFloat(change.toFixed(2)));
      continue;
    }

    if (currentIndex > 0 && currentIndex < months.length) {
      let resultsForPrevMonth = findResultsForMonth(
        resultLevel,
        months[currentIndex - 1]
      );
      if (!resultsForPrevMonth) {
        ratesData.push(null);
        changesData.push(null);
        continue;
      }

      let resultsForNextMonth = findResultsForMonth(
        resultLevel,
        months[currentIndex + 1]
      );
      if (!resultsForNextMonth) {
        ratesData.push(null);
        changesData.push(null);
        continue;
      }

      let halfDifference =
        (resultsForNextMonth[propertyName] - resultsForPrevMonth[propertyName]) / 2;
      let value = resultsForPrevMonth[propertyName] + parseFloat(halfDifference);
      ratesData.push(parseFloat(value.toFixed(2)));

      change = value - referenceValue;
      changesData.push(parseFloat(change.toFixed(2)));
      continue;
    }

    // no results for this month
    ratesData.push(null);
    changesData.push(null);
  }

  fillDataGaps(data);

  return data;
};

export const getPunchOutRowStates = function (
  punchOutRows: PunchOutRowByLevel,
  fetchGraphQL: FetchGraphQL,
  search: Search,
  props: { buyRateState: BuyRateState }
): { rowStates: PunchOutRowState[], blanks: boolean[], addRowState: ?PunchOutRowState } {
  let rowStates = [];
  let blanks = [];
  punchOutRows.forEach((punchOut: ?PunchOutRow, index: number) => {
    if (punchOut)
      rowStates.push(
        new PunchOutRowState(fetchGraphQL, search, props.buyRateState, punchOut)
      );
    else blanks.push(true);
  });

  let addRowState = null;
  if (blanks.length > 0) {
    addRowState = new PunchOutRowState(fetchGraphQL, search, props.buyRateState, null);
    // By default if there are no rates on this buy rate
    // we'll show the first row in input mode to create the rates
    if (rowStates.length === 0) addRowState.enterInputMode();
  }

  // the add option will take one blank space so...
  blanks.pop();
  return { rowStates, blanks, addRowState };
};

export const shouldShowCurrency = function (resultLevel: Object): boolean {
  // Hide currency symbols on level card details
  // if number are too large so the design doesn't break
  // 00,000.00 is the max, bigger than this, hide symbols
  let biggestNumberString = "";
  if (resultLevel.billRateMax && resultLevel.billRateMax > 0)
    biggestNumberString = numeral(resultLevel.billRateMax).format("0,0.00");
  else biggestNumberString = numeral(resultLevel.payRateMax).format("0,0.00");

  return biggestNumberString.length < 14;
};

export const getCurrency = function (search: Search): string {
  if (!search.currency) return "";

  return search.currency.symbol;
};
