import React from "react";
import R from "ramda";
import SingleValuePicker from "./controls/SingleValuePicker";
import PureRenderImmutableComponent from "../../../utils/immutableRender";
import {
  PAY_TYPE_OPTIONS,
  RATE_TYPE_OPTIONS,
  PAY_TYPES,
} from "../../../constants/negotiationWorksheet";
import TextInput from "../../../components/lib/TextInput";

const rateTypeOptions = R.values(RATE_TYPE_OPTIONS);
const payTypeOptions = R.values(PAY_TYPE_OPTIONS);

export default class RateAndPayTypePicker extends PureRenderImmutableComponent {
  render() {
    var payValue = this.props.payValue;
    const {
      currencySymbol,
      payType,
      rateType,
      setPayValue,
      payTypeBillRateDisabled,
      salaryFieldsCheck,
      setPayType,
      setRateType,
      workerTypeKey,
      workerTypes,
      setWorkerType,
      enableSalaryRateTypeOption,
    } = this.props;

    const enabledPayTypeOptions = enableSalaryRateTypeOption
      ? payTypeOptions
      : payTypeOptions.filter((x) => x.value !== PAY_TYPES.SALARY_RATE);
    const payTypeOptionsFiltered = payTypeBillRateDisabled
      ? salaryFieldsCheck === 0
        ? enabledPayTypeOptions.filter(
            (x) => x.value !== PAY_TYPES.BILL_RATE && x.value !== PAY_TYPES.SALARY_RATE
          )
        : enabledPayTypeOptions.filter((x) => x.value !== PAY_TYPES.BILL_RATE)
      : salaryFieldsCheck === 0
      ? enabledPayTypeOptions.filter((x) => x.value !== PAY_TYPES.SALARY_RATE)
      : enabledPayTypeOptions;
    var payTypeView = null;
    var rateTypeView = null;
    var workerTypeView = null;
    var payValueInput = "";

    if (payValue != null) {
      payTypeView = (
        <ListGroupItem noPadding noBorder>
          <SingleValuePicker
            label="Rate Details"
            options={payTypeOptionsFiltered}
            value={parseInt(payType, 10)}
            onChange={setPayType}
          />
        </ListGroupItem>
      );
      rateTypeView = (
        <ListGroupItem noPadding noBorder>
          <SingleValuePicker
            options={rateTypeOptions}
            value={parseInt(rateType, 10)}
            onChange={setRateType}
          />
        </ListGroupItem>
      );
      workerTypeView = (
        <ListGroupItem noPadding noBorder>
          <SingleValuePicker
            label="Worker Type"
            value={workerTypeKey}
            options={workerTypes.map((type) => ({
              label: type.label,
              value: type.key,
            }))}
            onChange={setWorkerType}
          />
        </ListGroupItem>
      );
      // HACK: Emulate the old behavior of NumberInput component for TextInput below.
      if (!Number.isNaN(parseFloat(payValue))) {
        payValueInput = parseFloat(payValue);
      }
    }

    return (
      <div>
        <span className="icon border">
          <h1 className="pt-card__heading__title">
            Choose Rate Details &amp; Worker Type
          </h1>
        </span>
        <div className="news-box">
          <ListGroup>
            <ListGroupItem>
              <TextInput
                addon={currencySymbol}
                value={payValueInput}
                min={0}
                step="0.01"
                fill
                type="number"
                onChange={(e) => {
                  // HACK: Emulate the old behavior when setting the state for pay value.
                  // TODO: Replace this whole parent component with new number input components, toggles, etc
                  if (e.target.value === "") {
                    setPayValue("");
                    return;
                  }

                  if (e.target.value !== "") {
                    let numVal = parseFloat(e.target.value);

                    if (!Number.isNaN(numVal)) {
                      setPayValue(numVal);
                    }
                  }
                }}
                placeholder="Enter Amount"
              />
            </ListGroupItem>
            {payTypeView}
            {payType && payType !== 3 && rateTypeView}
            {payType !== 3 && rateType && workerTypeView}
          </ListGroup>
        </div>
      </div>
    );
  }
}

export const ListGroup = ({ children }) => {
  return <ul className="pt-card__list-group">{children}</ul>;
};

export const ListGroupItem = (props) => {
  const { children } = props;
  return <li>{children}</li>;
};
