import React from "react";
import { today, getLocalTimeZone } from "@internationalized/date";

import Inline from "./Inline";
import { SimpleLink } from "./Link";
import { Dialog, DialogContent, DialogDescription, DialogTrigger } from "./Dialog";
import { styled } from "../../stitches.config";
import QsPrivacyPolicyDialog from "../../views/quick_search/QsPrivacyPolicyDialog";
import QsBrowserRequirements from "../../views/quick_search/QsBrowserRequirements";

const BigDialogContent = styled(DialogContent, {
  "@md": {
    minWidth: "400px",
    maxWidth: "700px",
  },
  "@lg": {
    minWidth: "400px",
    maxWidth: "900px",
  },
  "@xl": {
    maxWidth: "1200px",
  },
});

function PrivacyPolicy() {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <span>Privacy Policy</span>
      </DialogTrigger>
      <BigDialogContent
        onOpenAutoFocus={(e) => e.preventDefault()}
        onCloseAutoFocus={(e) => e.preventDefault()}
      >
        <DialogDescription asChild>
          <QsPrivacyPolicyDialog />
        </DialogDescription>
      </BigDialogContent>
    </Dialog>
  );
}

function BrowserRequirements() {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <span>Supported Browsers</span>
      </DialogTrigger>
      <BigDialogContent
        onOpenAutoFocus={(e) => e.preventDefault()}
        onCloseAutoFocus={(e) => e.preventDefault()}
      >
        <DialogDescription asChild>
          <QsBrowserRequirements />
        </DialogDescription>
      </BigDialogContent>
    </Dialog>
  );
}

function Footer() {
  return (
    <Inline
      data-testid="copyright"
      css={{
        gap: "$2",
        justifyContent: "center",
        padding: "$4 0",
        marginTop: "50px",
        color: "$secondaryLighter",
        cursor: "pointer",
        borderTop: "1px solid #c9d1d7",
        fontSize: "13px",
        textTransform: "uppercase",
      }}
    >
      <span>© COPYRIGHT {today(getLocalTimeZone()).year}</span>
      <SimpleLink
        href="https://magnitglobal.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        MAGNIT
      </SimpleLink>
      |
      <SimpleLink>
        <PrivacyPolicy />
      </SimpleLink>
      |
      <SimpleLink>
        <BrowserRequirements />
      </SimpleLink>
    </Inline>
  );
}

Footer.displayName = "Footer";

export default Footer;
