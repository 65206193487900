// @flow

import React from "react";
import { observer } from "mobx-react";
import ToggleButton, { ToggleButtonGroup } from "../../ratecards/components/ToggleButton";

const DataDisplayOptions = (props) => {
  let store = props.store;
  return (
    <ToggleButtonGroup>
      <ToggleButton
        name="show-options"
        type="radio"
        value={store.SHOW_RATES_OPTION}
        selected={store.showRates}
        onClick={store.onShowOptionChange}
      >
        Rates
      </ToggleButton>
      <ToggleButton
        name="show-options"
        type="radio"
        value={store.SHOW_DELTAS_OPTION}
        selected={store.showDeltas}
        onClick={store.onShowOptionChange}
      >
        Change
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default observer(DataDisplayOptions);
