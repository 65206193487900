/**
 * @generated SignedSource<<290e544c18a7a089c3b787659a7cb4c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NavBar2_user$data = {
  readonly client: {
    readonly legacyClient: {
      readonly isClientJobLibrary: boolean;
      readonly jobLibrarySubscriptionFlag: boolean;
      readonly perSearchPricing: boolean;
      readonly pspStoreFlag: boolean;
    } | null;
    readonly title: string;
  } | null;
  readonly roles: ReadonlyArray<string | null> | null;
  readonly username: string;
  readonly " $fragmentType": "NavBar2_user";
};
export type NavBar2_user$key = {
  readonly " $data"?: NavBar2_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"NavBar2_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NavBar2_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roles",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Client",
      "kind": "LinkedField",
      "name": "client",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LegacyClientAllFieldsNode",
          "kind": "LinkedField",
          "name": "legacyClient",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "perSearchPricing",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isClientJobLibrary",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "jobLibrarySubscriptionFlag",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pspStoreFlag",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "b4278c6fc7d694b85a56c570c7e0fa8a";

export default node;
