// @flow
import React, { Component } from "react";
import type { Element } from "react";
import { Dropdown, MenuItem } from "react-bootstrap";
import AutosizeInput from "react-input-autosize";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TagValue } from "./MultiDownshiftComponents";

type Props = {
  items: any[],
  selectedItem?: any,
  inputValue: string,
  itemToString: (any) => string,
  onInputValueChange: (string) => void,
  onSelectedItemChange: (any) => void,
  renderItem?: (any) => Element<any>,
  loading?: boolean,
};

type State = {
  isOpen: boolean,
};

class SingleDownshift extends Component<Props, State> {
  _input: ?HTMLInputElement;
  _dropdown: ?HTMLElement;

  onItemClick: (any, Event, number) => void;
  onInputChange: (SyntheticInputEvent<HTMLInputElement>) => void;
  onTagBlur: (Event, any) => void;
  onRemoveTag: (any) => void;
  onInputKeyDown: (Event) => void;
  onDropDownKeyDown: (Event) => void;
  onWrapperClick: (SyntheticMouseEvent<HTMLDivElement>) => void;
  popValue: () => void;
  focusOnInput: () => void;
  inputRef: (?HTMLInputElement) => void;
  _handleDocumentClick: (SyntheticMouseEvent<HTMLElement>) => void;

  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onTagBlur = this.onTagBlur.bind(this);
    this.onRemoveTag = this.onRemoveTag.bind(this);
    this.onInputKeyDown = this.onInputKeyDown.bind(this);
    this.onDropDownKeyDown = this.onDropDownKeyDown.bind(this);
    this.onWrapperClick = this.onWrapperClick.bind(this);
    this.onItemClick = this.onItemClick.bind(this);
    this.popValue = this.popValue.bind(this);
    this.focusOnInput = this.focusOnInput.bind(this);
    this.inputRef = this.inputRef.bind(this);
    this._handleDocumentClick = this._handleDocumentClick.bind(this);
  }

  componentDidMount() {
    window.document.addEventListener("click", this._handleDocumentClick);
  }

  componentWillUnmount() {
    window.document.removeEventListener("click", this._handleDocumentClick);
  }

  _handleDocumentClick(e: SyntheticMouseEvent<HTMLElement>) {
    // $FlowFixMe ignoring e.target "incompatible type" error
    if (this._dropdown && !this._dropdown.contains(e.target)) {
      this.setState({ isOpen: false });
    }
  }

  onItemClick(item: any, event: Event, itemIndex: number) {
    if (this.props.onSelectedItemChange) {
      this.props.onSelectedItemChange(item);
    }
    if (this.props.onInputValueChange) {
      this.props.onInputValueChange("");
    }
    this.focusOnInput();
    this.setState({ isOpen: false });
  }

  onInputChange(event: SyntheticInputEvent<HTMLInputElement>) {
    if (this.props.onInputValueChange) {
      this.props.onInputValueChange(event.currentTarget.value);
    }
  }

  onInputFocus = () => {
    this.setState({ isOpen: true });
  };

  onTagBlur(e: Event, item: any) {
    // we don't need this for now
    // const { onItemChanged } = this.props;
    // if (onItemChanged) {
    //   onItemChanged(item);
    // }
  }

  onRemoveTag(tagItem: any) {
    if (this.props.onSelectedItemChange) {
      this.props.onSelectedItemChange(null);
    }
  }

  onInputKeyDown(event: Event & { keyCode: number }) {
    switch (event.keyCode) {
      case 8: // backspace
        if (!this.props.inputValue) {
          event.preventDefault();
          this.popValue();
        }
        return;
      case 9: // tab
        // we don't need this for now
        // if (this.props.inputValue && this.props.onItemAdd) {
        //   this.props.onItemAdd(this.props.inputValue);
        // }
        //
        // event.preventDefault();
        // event.stopPropagation();
        if (this.props.inputValue && this.props.onInputValueChange) {
          this.props.onInputValueChange("");
        }
        this.setState({ isOpen: false });
        return;
      case 27: // esc
        if (this.props.inputValue && this.props.onInputValueChange) {
          this.props.onInputValueChange("");
        }
        this.setState({ isOpen: false });
        return;
      case 46: // delete
        // we don't need this for now
        // if (!this.props.inputValue) {
        //   event.preventDefault();
        //   this.popValue();
        // }
        return;
      case 188: // comma
        // we don't need this for now
        // if (!this.props.inputValue) {
        //   event.preventDefault();
        // } else if (this.props.onItemAdd) {
        //   this.props.onItemAdd(this.props.inputValue);
        // }
        break;
      default:
        const exceptKeys = [
          16, // shift
          17, // ctrl
          18, // alt/opt
          91, // Windows Key / Left ⌘ / Chromebook Search key
          93, // Windows Menu / Right ⌘
        ];

        if (!exceptKeys.includes(event.keyCode)) {
          this.setState({ isOpen: true });
        }
        return;
    }
    event.preventDefault();
  }

  onDropDownKeyDown(event: Event & { keyCode: number, key: string }) {
    switch (event.keyCode) {
      case 8: // backspace
        this.focusOnInput();
        this.setState({ isOpen: true });
        break;
      case 9: // tab
        if (this.props.inputValue && this.props.onInputValueChange) {
          this.props.onInputValueChange("");
        }
        this.setState({ isOpen: false });
        return;
      case 27: // Escape
        this.focusOnInput();
        this.setState({ isOpen: false });
        if (this.props.onInputValueChange) {
          this.props.onInputValueChange("");
        }
        break;
      default: // means Enter, ArrowDown, Delete, etc...
        if (event.key.length > 1) return;
        if (event.key.match(/[a-zA-Z0-9\-_,.'"`]/g)) {
          this.focusOnInput();
          this.setState({ isOpen: true });
          return;
        }
        break;
    }
  }

  popValue() {
    if (this.props.onSelectedItemChange) {
      this.props.onSelectedItemChange(null);
    }
  }

  onWrapperClick(e: SyntheticMouseEvent<HTMLDivElement>) {
    // $FlowFixMe ignoring e.target.id "property id missing" error
    const wrapperId = e.target.id;
    if (wrapperId === "single-downshift-dropdown" || e.target === this._input) {
      this.focusOnInput();
      this.setState({ isOpen: true });
      e.stopPropagation();
      e.preventDefault();
    }
  }

  focusOnInput() {
    if (!this._input) return;

    this._input.focus();
    // $FlowFixMe ignoring _input.getInput "missing in HTMLElement" error
    if (typeof this._input.getInput === "function") {
      this._input.getInput().focus();
    }
  }

  inputRef(c: ?HTMLInputElement) {
    this._input = c;
  }

  render() {
    const { itemToString, items, selectedItem, renderItem, inputValue, loading } =
      this.props;
    const tagItems = selectedItem ? [selectedItem] : [];
    const showSearchHint =
      !loading &&
      (!items || !items.length) &&
      (!Boolean(inputValue) || !inputValue.length);
    const showNoItemsHint = !loading && (!items || !items.length) && Boolean(inputValue);

    const _inputProps = {
      value: inputValue,
      ref: this.inputRef,
      inputClassName: "multi-downshift-input",
      onChange: this.onInputChange,
      onKeyDown: this.onInputKeyDown,
      onFocus: this.onInputFocus,
    };

    return (
      <div
        style={{ width: "100%" }}
        ref={(element) => {
          this._dropdown = element;
        }}
      >
        <Dropdown
          id="single-downshift-dropdown"
          className="multi-downshift-dropdown"
          open={this.state.isOpen}
          onToggle={(e) => {}}
        >
          <div
            bsRole="toggle"
            className="ds-input-wrapper"
            onClick={this.onWrapperClick}
            style={{ position: "relative" }}
          >
            {tagItems.map((item, index) => {
              const tag = {
                value: itemToString(item),
                element: renderItem ? renderItem(item) : itemToString(item),
                index,
                item,
              };
              return (
                <TagValue
                  key={`Tag-${tag.index}`}
                  onBlur={this.onTagBlur}
                  onRemove={this.onRemoveTag}
                  tag={tag}
                />
              );
            })}
            <AutosizeInput {..._inputProps} />
            {this.props.loading && (
              <FontAwesomeIcon
                icon="circle-notch"
                fixedWidth
                spin
                className="cjl-input-icon-right"
              />
            )}
          </div>

          <Dropdown.Menu onKeyDown={this.onDropDownKeyDown}>
            {items.map((item, index) => {
              const isSelected = selectedItem === item;
              return (
                <MenuItem
                  key={`item-${index}`}
                  eventKey={index}
                  onSelect={this.onItemClick.bind(this, item)}
                  className={classNames("cjl-detail-menu-item", {
                    "is-active": isSelected,
                  })}
                >
                  <span>
                    <FontAwesomeIcon
                      icon="check"
                      className="icon"
                      style={{ visibility: isSelected ? "visible" : "hidden" }}
                    />{" "}
                    {renderItem ? renderItem(item) : itemToString(item)}
                  </span>
                </MenuItem>
              );
            })}
            {!items.length && (
              <MenuItem key="no-results-item" eventKey={0} disabled>
                {loading && (
                  <span style={{ padding: "0 20px" }}>
                    <em>Loading...</em>
                  </span>
                )}
                {showSearchHint && (
                  <span style={{ padding: "0 20px" }}>
                    <em>Start typing to find titles...</em>
                  </span>
                )}
                {showNoItemsHint && (
                  <span style={{ padding: "0 20px" }}>
                    <em>-- No Items --</em>
                  </span>
                )}
              </MenuItem>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}

export default SingleDownshift;
