// @flow
import React, { Component } from "react";
import type { Element } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "../../../../src/stitches.config";

const FlagTag = styled("div", {
  margin: "2px",
  padding: "1px 7px",
  borderRadius: "3px",
  border: "1px solid $brandLight",
  backgroundColor: "$brandLightest",
  color: "#575656",
  fontSize: "13px",
});

export type TagItem = {
  value: string,
  item: any,
  index: number,
  element: Element<any> | string,
};

type TagProps = {
  tag: TagItem,
  onRemove: (TagItem) => void,
};

type State = {
  editing: boolean,
  value: string,
};

type Props = {
  tag: TagItem,
  onRemove: (TagItem) => void,
};

class TagValue extends Component<Props, State> {
  state: State;
  _input: HTMLInputElement;

  onDoubleClick: (SyntheticMouseEvent<HTMLElement>) => void;
  onBlur: (SyntheticEvent<HTMLElement>) => void;
  onChange: (SyntheticEvent<HTMLElement>) => void;
  onRemove: (SyntheticEvent<HTMLElement>) => void;
  inputRef: (?HTMLInputElement) => void;

  constructor(props: TagProps) {
    super(props);

    this.state = {
      editing: false,
      value: props.tag.value,
    };

    this.onDoubleClick = this.onDoubleClick.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.inputRef = this.inputRef.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps: TagProps) {
    if (nextProps.tag !== this.props.tag) {
      this.setState({ value: nextProps.tag.value });
    }
  }

  onDoubleClick(e: SyntheticMouseEvent<HTMLElement>) {
    // we don't need this for now
    // this.setState({ editing: true });
  }

  onBlur(e: SyntheticEvent<HTMLElement>) {
    // we don't need this for now
    // const { onBlur, tag } = this.props;
    // this.setState({ editing: false });
    // if (onBlur) {
    //   onBlur(e, { ...tag, value: this.state.value });
    // }
  }

  onChange(e: SyntheticEvent<HTMLElement>) {
    // we don't need this for now
    // this.setState({ value: e.target.value });
  }

  onRemove(e: SyntheticEvent<HTMLElement>) {
    const { onRemove, tag } = this.props;
    if (onRemove) {
      onRemove(tag);
    }
  }

  inputRef(c: HTMLInputElement) {
    this._input = c;
  }

  render() {
    const { editing } = this.state;

    const inputProps = {
      type: "text",
      value: this.props.tag.value,
      ref: this.inputRef,
      className: "ds-tag-input",
      onChange: this.onChange,
      onBlur: this.onBlur,
    };

    return editing ? (
      <input {...inputProps} />
    ) : (
      <FlagTag onDoubleClick={this.onDoubleClick}>
        {this.props.tag.element}{" "}
        <FontAwesomeIcon
          onClick={this.onRemove}
          icon="times"
          fixedWidth
          className="icon"
          style={{ cursor: "pointer" }}
        />
      </FlagTag>
    );
  }
}

export { TagValue };
