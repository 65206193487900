// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SortIndicator from "./SortIndicator";
import { FILTER_COLUMN } from "../../../models/Filter";
import FilterModal, { ModalTitle } from "./FilterModal";
import SortControls from "./SortControls";
import ListContainer from "./ListContainer";
import Pagination from "../../../components/lib/Pagination";
import LoadingIndicator from "../../shared/LoadingIndicator";
import RateCardListItem from "./RateCardListItem";
import { SingleDatePicker } from "../../../components/SingleDatePicker";
import { RateCardListComponentStore } from "../../../stores/mobx/RateCardListStore";
import InstantSearchBox from "./InstantSearchBox";
import SelectableItem from "./SelectableItem";
import ToggleButton from "./ToggleButton";
import ContainerSection from "./ContainerSection";
import SelectableItemsList from "./SelectableItemsList";
import TagsFilter from "../../filters/TagsFilter";
import {
  CardAlert,
  CardAlertLink,
  CardFilter,
  CardFilters,
} from "../../../components/lib/Card";
import Button from "../../../components/lib/Button";
import Inline, { InlineElements } from "../../../components/lib/Inline";
import Spacer from "../../../components/lib/Spacer";

const CreatedByCriteriaList = observer((props) => {
  return (
    <div>
      <SelectableItemsList listState={props.filterState}>
        {props.filterState.viewItems.map((owner, i) => (
          <SelectableItem
            key={owner.username}
            item={owner}
            name="owner-filter"
            value={owner.username}
            selected={owner.selected}
            onChange={props.filterState.setSelectedValue}
          >
            <div>
              {owner.firstName} {owner.lastName}{" "}
            </div>
            <div className="text-x-small">
              <em>
                <FontAwesomeIcon icon="user" className="icon no-margin" />{" "}
                {owner.username}
              </em>
            </div>
          </SelectableItem>
        ))}
      </SelectableItemsList>
      <ContainerSection className="info">
        <p className="text-muted text-x-small no-margin pull-right">
          <em>
            <strong>{props.filterState.selectedValuesCount} </strong>selected,{" "}
            <strong>{props.filterState.totalValuesCount} </strong>total
          </em>
        </p>
        <div className="clearfix" />
      </ContainerSection>
    </div>
  );
});

class RateCardsList extends Component {
  handleBackToLibrary: () => void;
  constructor(props) {
    super(props);

    this.changeCurrent = this.changeCurrent.bind(this);
    this.handleBackToLibrary = this.handleBackToLibrary.bind(this);
  }

  changeCurrent(value) {
    let store: RateCardListComponentStore = this.props.store;
    store.pagination.handleCurrentPage(value);
  }

  handleBackToLibrary() {
    let store: RateCardListComponentStore = this.props.store;

    store.backToLibrary(this.props.router);
  }

  componentDidMount() {
    this.props.store.rateCardsView.forEach((rateCard) => {
      rateCard.viewState.selected = false;
    });
    //this.props.store.pagination.goFetch();
  }

  render() {
    const store: RateCardListComponentStore = this.props.store;
    const network = store.network;
    const fillContainer = this.props.fillContainer;
    const fixedHeight = this.props.fixedHeight;
    const maximumDate = new Date();

    let listBodyStyles = {};
    if (fixedHeight) {
      listBodyStyles.height = fixedHeight;
      listBodyStyles.overflowY = "auto";
    }

    let libraryFilterSection = null;
    if (store.libraryTitleId) {
      libraryFilterSection = (
        <CardAlert>
          <span>
            Showing Rate Cards that contain the title "{store.libraryTitleName}" from your
            library.{" "}
          </span>
          &nbsp;
          <CardAlertLink css={{ marginRight: 0 }} onClick={this.handleBackToLibrary}>
            Go back to libraries
          </CardAlertLink>
          &nbsp;
          <span>or</span>
          &nbsp;
          <CardAlertLink
            key="clear-selections-link"
            onClick={store.removeLibraryTitleFilter}
          >
            Clear this filter
          </CardAlertLink>
        </CardAlert>
      );
    }

    let selectAllSection = null;
    let selectAllOnPageSection = (
      <>
        <Button onClick={store.selectAllOnPageItem}>Select All</Button>
        <Button onClick={store.deselectAllPage}>Deselect All</Button>
      </>
    );

    if (store.allOnPageSelected && store.allowMultipleItemSelection) {
      selectAllSection = (
        <CardAlert>
          <span>
            All &nbsp;
            <strong>{store.rateCardCounts}</strong>
            &nbsp; Rate Cards selected.{" "}
          </span>
          &nbsp;
          {!store.isTagView && (
            <CardAlertLink key="clear-selections-link" onClick={store.clearAllSelections}>
              Clear all selections
            </CardAlertLink>
          )}
          {store.isTagView && (
            <CardAlertLink
              key="clear-selections-link"
              onClick={store.clearAllSelectionsOnTagView}
            >
              Clear all selections
            </CardAlertLink>
          )}
        </CardAlert>
      );
    }

    if (
      store.allOnPageSelected &&
      !store.allSelected &&
      store.allowMultipleItemSelection
    ) {
      selectAllSection = (
        <CardAlert>
          <span>All Rate Cards on this page selected. &nbsp;</span>
          &nbsp;
          <CardAlertLink key="select-all-item" onClick={store.selectAllPage}>
            Select all &nbsp;
            <strong>{store.rateCardCounts}</strong>
            &nbsp; Rate Cards.
          </CardAlertLink>
          &nbsp;&nbsp;
          {!store.isTagView && (
            <CardAlertLink key="clear-selections-link" onClick={store.clearAllSelections}>
              Clear all selections
            </CardAlertLink>
          )}
          {store.isTagView && (
            <CardAlertLink
              key="clear-selections-link"
              onClick={store.clearAllSelectionsOnTagView}
            >
              Clear all selections
            </CardAlertLink>
          )}
        </CardAlert>
      );
    }

    let tableLeftActionBar = null;
    if (store.isEditing) {
      tableLeftActionBar = (
        <>{store.allowMultipleItemSelection && selectAllOnPageSection}</>
      );
    }

    const rateCards = store.rateCardsView;
    let tableContent = null;
    if (network.loading) {
      tableContent = <LoadingIndicator />;
    } else if (network.error) {
      // TODO: replace this with a more friendly error message
      tableContent = (
        <div className="pt-error-message">
          <h2>Error</h2>
          <pre>
            <code>{JSON.stringify(network.error, null, 2)}</code>
          </pre>
        </div>
      );
    } else if (rateCards.length === 0) {
      tableContent = (
        <div className="ratecards-table-empty">
          {Object.keys(store.appliedFilters).length > 0 ? (
            <div>No Rate Cards match your current filters</div>
          ) : (
            <div>
              No Rate Cards
              <p>Create a new Rate Card using the button above or by searching.</p>
            </div>
          )}
        </div>
      );
    } else {
      tableContent = rateCards.map((rateCard) => (
        <RateCardListItem
          key={rateCard.ratecardId}
          store={store}
          rateCard={rateCard}
          editing={store.isEditing}
        />
      ));
    }

    return (
      <div>
        {store.hasRateTypeFilter && (
          <FilterModal title="Rate Type" filterState={store.hasRateTypeFilter}>
            {store.hasRateTypeFilter.network.loading && <LoadingIndicator />}
            {!store.hasRateTypeFilter.network.loading && (
              <ContainerSection>
                <p>Filter by a specific Rate Type:</p>
                <InlineElements>
                  {store.hasRateTypeFilter.viewItems.map((item) => (
                    <ToggleButton
                      key={item.id}
                      large={true}
                      item={item}
                      type="radio"
                      name="rate-type"
                      value={item.value}
                      selected={item.selected}
                      onChange={store.hasRateTypeFilter.setSelectedValue}
                    >
                      {item.value}
                    </ToggleButton>
                  ))}
                </InlineElements>
              </ContainerSection>
            )}
          </FilterModal>
        )}
        {store.createdByFilter && (
          <FilterModal
            filterState={store.createdByFilter}
            innerStyle={{
              width: 550,
            }}
          >
            {store.createdByFilter.network.loading && <LoadingIndicator />}
            {!store.createdByFilter.network.loading && (
              <div>
                <ContainerSection className="header overlap-t-padding">
                  <ModalTitle className="pull-left">Created By</ModalTitle>
                  <div className="pull-right">
                    <SortControls filterState={store.createdByFilter} />
                  </div>
                  <div className="clearfix" />
                </ContainerSection>
                <ContainerSection className="no-border">
                  <p>Filter by specific Users:</p>
                  <Inline css={{ gap: "$2" }}>
                    <InstantSearchBox
                      onSearch={store.createdByFilter.onInstantSearch}
                      value={store.createdByFilter.instantSearchValue}
                    />
                    <Spacer />
                    <Button onClick={store.createdByFilter.onSelectAll}>
                      Select All
                    </Button>
                    <Button onClick={store.createdByFilter.onDeselectAll}>
                      Deselect All
                    </Button>
                  </Inline>
                </ContainerSection>
                <CreatedByCriteriaList
                  filterState={store.createdByFilter}
                  store={store}
                />
              </div>
            )}
          </FilterModal>
        )}
        {store.sharedFilter && (
          <FilterModal filterState={store.sharedFilter}>
            <div>
              <ContainerSection className="header overlap-t-padding">
                <ModalTitle className="pull-left">Shared</ModalTitle>
                <div className="pull-right">
                  <SortControls filterState={store.sharedFilter} />
                </div>
                <div className="clearfix" />
              </ContainerSection>
              <ContainerSection>
                <p>Filter by a specific Sharing State:</p>
                <ListContainer>
                  {store.sharedFilter.viewItems.map((item) => (
                    <ToggleButton
                      key={item.id}
                      large={true}
                      item={item}
                      type="radio"
                      name="shared-state"
                      value={item.value}
                      selected={item.selected}
                      onChange={store.sharedFilter.setSelectedValue}
                    >
                      {item.value}
                    </ToggleButton>
                  ))}
                </ListContainer>
              </ContainerSection>
            </div>
          </FilterModal>
        )}
        {store.createdOnFilter && (
          <FilterModal filterState={store.createdOnFilter}>
            <div>
              <ContainerSection className="header overlap-t-padding">
                <ModalTitle className="pull-left">Created On</ModalTitle>
                <div className="pull-right">
                  <SortControls
                    filterState={store.createdOnFilter}
                    sortType="numeric"
                    ascText="Sort Oldest First"
                    descText="Sort Newest First"
                  />
                </div>
                <div className="clearfix" />
              </ContainerSection>
              <ContainerSection>
                <p>Filter by a specific period:</p>
                <div className="pt-range-filter">
                  <div className="pt-range-filter__item">
                    <p>From:</p>
                    <SingleDatePicker
                      id="from_date"
                      placeholder="Start Date"
                      date={store.createdOnFilter.fromDate}
                      numberOfMonths={1}
                      isOutsideRange={(day) => day.isAfter(maximumDate)}
                      focused={store.createdOnFilter.fromFocused}
                      onDateChange={store.createdOnFilter.fromDateChange}
                      onFocusChange={store.createdOnFilter.fromFocusedChange}
                      displayFormat={() => "MM/DD/YYYY"}
                      hideKeyboardShortcutsPanel={true}
                    />
                  </div>
                  <div className="pt-range-filter__item">
                    <p>To:</p>
                    <SingleDatePicker
                      id="to_date"
                      placeholder="End Date"
                      date={store.createdOnFilter.toDate}
                      numberOfMonths={1}
                      isOutsideRange={(day) => store.toDateRange(day)}
                      focused={store.createdOnFilter.toFocused}
                      onDateChange={store.createdOnFilter.toDateChange}
                      onFocusChange={store.createdOnFilter.toFocusedChange}
                      displayFormat={() => "MM/DD/YYYY"}
                      hideKeyboardShortcutsPanel={true}
                    />
                  </div>
                </div>
              </ContainerSection>
            </div>
          </FilterModal>
        )}
        {store.tagsFilter && (
          <TagsFilter
            tagsFilter={store.tagsFilter}
            network={store.tagsFilter.network.loading}
            instantSearchValue={store.tagsFilter.instantSearchValue}
            onInstantSearch={store.tagsFilter.onInstantSearch}
          />
        )}

        {libraryFilterSection}
        <CardFilters filtered={store.isFiltered}>
          {tableLeftActionBar}
          <Spacer />
          {store.isFiltered && (
            <Button color="accent" onClick={store.clearFilters}>
              Clear All Filters & Sorts
            </Button>
          )}
          {store.tagsFilter && (
            <CardFilter
              name="search-list-filter"
              value={FILTER_COLUMN.TAGS}
              filtered={Boolean(store.appliedFilters[FILTER_COLUMN.TAGS])}
              onClick={store.tagsFilter.onShowModal}
            >
              <SortIndicator
                sortColumn={FILTER_COLUMN.TAGS}
                sortsOrder={store.appliedSortsOrder}
                sort={store.appliedSorts[FILTER_COLUMN.TAGS]}
              />
              Tags <span> ▼</span>
            </CardFilter>
          )}
          {store.hasRateTypeFilter && (
            <CardFilter
              name="rate-cards-filter"
              value={FILTER_COLUMN.RATE_TYPE}
              filtered={Boolean(store.appliedFilters[FILTER_COLUMN.RATE_TYPE])}
              onClick={store.hasRateTypeFilter.onShowModal}
              data-testid="rate-type-filter"
            >
              Rate Type
              <span> ▼</span>
            </CardFilter>
          )}
          {store.createdByFilter && (
            <CardFilter
              name="rate-cards-filter"
              value={FILTER_COLUMN.CREATED_BY}
              filtered={Boolean(store.appliedFilters[FILTER_COLUMN.CREATED_BY])}
              onClick={store.createdByFilter.onShowModal}
              data-testid="created-by-filter"
            >
              <SortIndicator
                sortColumn={FILTER_COLUMN.CREATED_BY}
                sortsOrder={store.appliedSortsOrder}
                sort={store.appliedSorts[FILTER_COLUMN.CREATED_BY]}
              />
              Created By
              <span> ▼</span>
            </CardFilter>
          )}
          {store.sharedFilter && (
            <CardFilter
              name="rate-cards-filter"
              value={FILTER_COLUMN.SHARED}
              filtered={Boolean(store.appliedFilters[FILTER_COLUMN.SHARED])}
              onClick={store.sharedFilter.onShowModal}
              data-testid="shared-filter"
            >
              <SortIndicator
                sortColumn={FILTER_COLUMN.SHARED}
                sortsOrder={store.appliedSortsOrder}
                sort={store.appliedSorts[FILTER_COLUMN.SHARED]}
              />
              Shared
              <span> ▼</span>
            </CardFilter>
          )}
          {store.createdOnFilter && (
            <CardFilter
              name="rate-cards-filter"
              value={FILTER_COLUMN.DATE_RANGE}
              filtered={Boolean(store.appliedFilters[FILTER_COLUMN.DATE_RANGE])}
              onClick={store.createdOnFilter.onShowModal}
              data-testid="created-on-filter"
            >
              <SortIndicator
                sortType="numeric"
                sortColumn={FILTER_COLUMN.DATE_RANGE}
                sortsOrder={store.appliedSortsOrder}
                sort={store.appliedSorts[FILTER_COLUMN.DATE_RANGE]}
              />
              Created On
              <span> ▼</span>
            </CardFilter>
          )}
        </CardFilters>
        <div
          className={classNames("ratecards-table", {
            "ratecards-table-fill-container": fillContainer,
          })}
        >
          <div style={listBodyStyles}>
            {selectAllSection}
            {tableContent}
          </div>
        </div>
        {store.pagination.pageCount > 0 ? (
          <Pagination
            options={{
              variant: "full",
              currentPage: store.pagination.currentPage,
              numPages: store.pagination.pageCount,
            }}
            onPageClick={(pageEvent) => {
              if (pageEvent.type === "first") {
                this.changeCurrent(1);
              }
              if (pageEvent.type === "last") {
                this.changeCurrent(store.pagination.pageCount);
              }
              if (pageEvent.type === "next") {
                this.changeCurrent(
                  Math.min(store.pagination.currentPage + 1, store.pagination.pageCount)
                );
              }
              if (pageEvent.type === "prev") {
                this.changeCurrent(Math.max(store.pagination.currentPage - 1, 1));
              }
              if (pageEvent.type === "page") {
                this.changeCurrent(pageEvent.page);
              }
            }}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

RateCardsList.propTypes = {
  rateCardListComponentStore: PropTypes.object,
  fixedHeight: PropTypes.number,
  fillContainer: PropTypes.bool,
};

export default observer(RateCardsList);
