// @flow

import * as React from "react";
import { observer } from "mobx-react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LibraryTitleTable from "./LibraryTitleTable";
import LibraryTitleTableRow from "./LibraryTitleTableRow";
import EmptyRow from "./EmptyRow";
import LibrarySearch from "./LibrarySearch";
import numeral from "numeral";
import type JobLibraryTitleListStore from "../../stores/mobx/JobLibraryTitleListStore";
import Modal from "../../components/modals/Modal";
import NonIdealState from "./components/NonIdealState";
import SelectStoreCountriesList from "./components/SelectStoreCountriesList";
import { Link } from "../../components/lib/Link";
import Pagination from "../../components/lib/Pagination";

function times(n, f) {
  const result = [];
  while (n-- > 0) {
    result.push(f(n));
  }

  return result;
}

type Props = {
  store: JobLibraryTitleListStore,
  totalAvailability?: number,
  totalTitles?: number,
  isCountryLibrary?: boolean,
  selectedCount?: number,
  itemActions?: ?Array<(Object) => void>,
  router?: Object,
  showLatestSearch: boolean,
  some?: String,
};

class JobLibraryTitleList extends React.Component<Props> {
  props: Props;

  handleOnSearch: () => void;
  handleOnClearSearch: () => void;

  static defaultProps = {
    selectedCount: 0,
    showLatestSearch: false,
  };

  constructor(props: Props) {
    super(props);

    this.handleOnSearch = this.handleOnSearch.bind(this);
    this.handleOnClearSearch = this.handleOnClearSearch.bind(this);
  }

  handleOnSearch() {
    const store = this.props.store;
    store.defaultToLibraryFilter = false;
    store.clientJobTitleTitleFilter.onApply();
  }

  handleOnClearSearch() {
    const store = this.props.store;
    store.defaultToLibraryFilter = false;
    store.clientJobTitleTitleFilter.onReset();
  }

  handleOnDeleteTitle = () => {
    const store = this.props.store;
    store.confirmDeleteTitleModal.hideModal();
    store.deleteTitleConfirmed();
  };

  changeCurrent = (value: number) => {
    let store: BatchSearchJLListComponentStore = this.props.store;
    store.pagination.handleCurrentPage(value);
  };

  render() {
    const store: JobLibraryTitleListStore = this.props.store;
    const selectedCount = this.props.selectedCount;
    const clientJobTitlesView = store.clientJobTitlesView;

    const setClearSearch = store.appliedFilters.hasOwnProperty(
      store.clientJobTitleTitleFilter.column
    );

    const totalJobs = this.props.totalAvailability;
    const totalTitles = this.props.totalTitles;
    const totalMatches = store.pagination.totalCount;
    return (
      <div>
        <div className="cjl-flex-table">
          <div className="cjl-flex-table row double-vertical-space">
            <div className="column auto no-padding-left">
              <LibrarySearch
                style={{ width: 300 }}
                className="cjl-title-list-search"
                placeholder="Search Titles..."
                value={store.clientJobTitleTitleFilter.textToLookFor}
                onChange={store.clientJobTitleTitleFilter.onTextToLookForChange}
                onSearch={this.handleOnSearch}
                onClear={setClearSearch ? this.handleOnClearSearch : null}
              />
            </div>
            <div className="column">
              <span className="caption">
                {store.isFiltered && (
                  <span>{numeral(totalMatches).format("0,0")} Title Matches</span>
                )}
              </span>
            </div>
            <div className="column auto">
              <span className="caption">
                {store.isEditing && !store.isFilteredBySelectedTitles && (
                  <span>
                    <Link css={{ margin: "8px" }} onClick={store.filterBySelectedTitles}>
                      {numeral(selectedCount || store.selectedCount).format("0,0")}{" "}
                      Selected
                    </Link>{" "}
                    <span style={{ marginRight: 10 }}>/</span>
                    <span>{numeral(store.totalCount).format("0,0")} Titles</span>
                  </span>
                )}
                <span style={{ display: "inline-flex", alignItems: "center" }}>
                  {store.isEditing && store.isFilteredBySelectedTitles && (
                    <span>
                      <span>
                        {numeral(selectedCount || store.selectedCount).format("0,0")}{" "}
                        Selected
                      </span>
                      <span style={{ marginLeft: 10, marginRight: 10 }}>/</span>
                      <span>
                        {numeral(store.pagination.totalCount).format("0,0")} Titles
                      </span>
                      <Link
                        css={{ margin: "0 8px" }}
                        onClick={store.removeFilterBySelectedTitles}
                      >
                        Show All
                      </Link>
                    </span>
                  )}
                  {store.isEditing &&
                    store.isFilteredBySelectedTitles &&
                    store.selectedCount > 0 && <span>|</span>}
                  {store.selectedCount > 0 && (
                    <Link css={{ marginLeft: "8px" }} onClick={store.clearAllSelections}>
                      Clear Selection
                    </Link>
                  )}
                </span>
                {!store.isEditing && !this.props.isCountryLibrary && (
                  <span>
                    <span>
                      {numeral(totalTitles).format("0,0")}{" "}
                      {totalTitles === 1 ? "Title" : "Titles"}
                    </span>
                    <span style={{ marginLeft: 10, marginRight: 10 }}>/</span>
                    <span>
                      {numeral(totalJobs).format("0,0")}{" "}
                      {totalJobs === 1 ? "Jobs" : "Jobs"}
                    </span>
                    {/*<span style={{ marginLeft: 10, marginRight: 10 }}>/</span>*/}
                  </span>
                )}
                {!store.isEditing && this.props.isCountryLibrary && (
                  <span>
                    {numeral(totalTitles).format("0,0")}{" "}
                    {totalTitles === 1 ? "Job" : "Jobs"}
                  </span>
                )}
                {!store.isEditing && (
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="left"
                    overlay={
                      <Popover
                        id="popover-trigger-hover"
                        title="Title, Job and count definition"
                      >
                        {!this.props.isCountryLibrary && (
                          <span>
                            A <strong>Title</strong> is a job title and job description
                            <hr />
                          </span>
                        )}
                        A <strong>Job</strong> is a <strong>Title</strong> searchable in a{" "}
                        <strong>Country</strong>.
                        <hr />
                        <strong>Jobs</strong> = Sum of the number of searchable countries
                        for each <strong>Title</strong> in the job library.
                      </Popover>
                    }
                  >
                    <FontAwesomeIcon
                      icon="question-circle"
                      fixedWidth
                      style={{ marginLeft: 10 }}
                    />
                  </OverlayTrigger>
                )}
              </span>
            </div>
          </div>
        </div>
        {store.isFiltered && store.resultsForText && (
          <div className="cjl-search-query">
            <span className="cjl-label">RESULTS FOR:</span>{" "}
            <span>&ldquo;{store.resultsForText}&rdquo;</span>
          </div>
        )}
        <LibraryTitleTable
          actions={Boolean(this.props.itemActions)}
          store={store}
          showCountries={!this.props.isCountryLibrary}
          showLatestSearch={this.props.showLatestSearch}
        >
          {/* Show Items */}
          {!store.network.loading &&
            clientJobTitlesView &&
            clientJobTitlesView.length > 0 &&
            clientJobTitlesView.map((item) => {
              const actions = this.props.itemActions
                ? this.props.itemActions.map((itemAction) => itemAction(item))
                : null;
              return (
                <LibraryTitleTableRow
                  key={item.id}
                  jobLibraryTitle={item}
                  // totalCountries={totalCountries}
                  actions={actions}
                  expandable={true}
                  store={store}
                  router={this.props.router}
                  showCountries={!this.props.isCountryLibrary}
                  showLatestSearch={this.props.showLatestSearch}
                />
              );
            })}
          {/* If no items to show */}
          {!store.network.loading &&
            (!clientJobTitlesView || clientJobTitlesView.length === 0) && (
              <EmptyRow text="No Titles" />
            )}
          {/* If its loading */}
          {store.network.loading &&
            // generate placeholder items
            times(store.clientJobTitles.length || 5, (i) => (
              <LibraryTitleTableRow key={i} />
            ))}
          <Pagination
            options={{
              variant: "full",
              currentPage: store.pagination.currentPage,
              numPages: store.pagination.pageCount,
            }}
            onPageClick={(pageEvent) => {
              if (pageEvent.type === "first") {
                this.changeCurrent(1);
              }
              if (pageEvent.type === "last") {
                this.changeCurrent(store.pagination.pageCount);
              }
              if (pageEvent.type === "next") {
                this.changeCurrent(
                  Math.min(store.pagination.currentPage + 1, store.pagination.pageCount)
                );
              }
              if (pageEvent.type === "prev") {
                this.changeCurrent(Math.max(store.pagination.currentPage - 1, 1));
              }
              if (pageEvent.type === "page") {
                this.changeCurrent(pageEvent.page);
              }
            }}
          />
        </LibraryTitleTable>
        <Modal
          show={store.confirmDeleteTitleModal.show}
          onHide={store.confirmDeleteTitleModal.hideModal}
        >
          <div className="container-section header">
            <h4>Confirmation Needed</h4>
          </div>
          <div className="container-section">
            <p>Are you sure you want to delete this title from your main Library?</p>
            <p>
              This will permanently delete the title along with all search data associated
              with it. Rate Cards that become empty after deleting the search data will
              also be deleted. Your subscription price will be updated to reflect these
              changes.
            </p>
            <p className="text-danger">
              <span className="fa fa-warning fa-fw" />{" "}
              <em>This action cannot be undone!</em>
            </p>
          </div>
          <div className="container-section footer">
            <div className="pull-right">
              <button
                className="btn btn-lg btn-danger"
                onClick={this.handleOnDeleteTitle}
              >
                Yes, Delete Title
              </button>
              <button
                className="btn btn-lg"
                onClick={store.confirmDeleteTitleModal.hideModal}
              >
                No, Cancel
              </button>
            </div>
          </div>
        </Modal>

        <Modal show={store.addCountryModal.show} onHide={store.addCountryModal.hideModal}>
          <div className="container-section header">
            <h4>Add Countries</h4>
          </div>
          <div style={{ minHeight: 333 }}>
            {!store.storeCountriesNetwork.loading && (
              <SelectStoreCountriesList store={store} />
            )}
            {store.storeCountriesNetwork.loading && (
              <NonIdealState
                icon="circle-notch"
                iconClasses="fa-spin"
                title="Loading"
                description="Please wait while data it's being transferred."
              />
            )}
            {store.addCountriesError && (
              <p className="text-danger text-x-small">
                <FontAwesomeIcon icon="exclamation-circle" className="text-danger" />{" "}
                {store.addCountriesError}
              </p>
            )}
          </div>
          <div className="container-section footer">
            <div className="pull-right">
              <button
                className="btn btn-lg btn-green"
                onClick={store.confirmAddCountries}
                disabled={
                  store.storeCountriesNetwork.loading ||
                  store.selectedStoreCountriesCount === 0
                }
              >
                Add Countries
              </button>
              <button className="btn btn-lg" onClick={store.addCountryModal.hideModal}>
                Cancel
              </button>
            </div>
          </div>
        </Modal>

        <Modal
          show={store.removeCountryModal.show}
          onHide={store.removeCountryModal.hideModal}
        >
          <div className="container-section header">
            <h4>Remove Countries</h4>
          </div>
          <div>
            {!store.storeCountriesNetwork.loading && (
              <SelectStoreCountriesList store={store} />
            )}
            {store.storeCountriesNetwork.loading && (
              <NonIdealState
                icon="circle-notch"
                iconClasses="fa-spin"
                title="Loading"
                description="Please wait while data it's being transferred."
              />
            )}
            {store.removeCountriesError && (
              <p className="text-danger text-x-small">
                <span className="fa fa-exclamation-circle text-danger" />{" "}
                {store.removeCountriesError}
              </p>
            )}
          </div>
          <div className="container-section">
            <p>
              This will permanently delete the selected countries along with all search
              data associated with it. Rate Cards that become empty after deleting the
              search data will also be deleted. Your subscription price will be updated to
              reflect these changes.
            </p>
            <p className="text-danger">
              <span className="fa fa-warning fa-fw" />{" "}
              <em>This action cannot be undone!</em>
            </p>
          </div>
          <div className="container-section footer">
            <div className="pull-right">
              <button
                className="btn btn-lg btn-danger"
                onClick={store.confirmRemoveCountries}
                disabled={
                  store.storeCountriesNetwork.loading ||
                  store.selectedStoreCountriesCount === 0
                }
              >
                Remove Countries
              </button>
              <button className="btn btn-lg" onClick={store.removeCountryModal.hideModal}>
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default observer(JobLibraryTitleList);
