// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import LoadingIndicator from "../../shared/LoadingIndicator";
import RegionListItem from "./RegionListItem";
import { RegionListComponentStore } from "../../../stores/mobx/RegionListStore";
import ToggleButton from "../../ratecards/components/ToggleButton";
import SortIndicator from "../../ratecards/components/SortIndicator";
import { FILTER_COLUMN } from "../../../models/Filter";
import { SingleDatePicker } from "../../../components/SingleDatePicker";
import CreatedOnFilter from "../../filters/CreatedOnFilter";
import CountryFilter from "../../filters/CountryFilter";
import TagsFilter from "../../filters/TagsFilter";
import Pagination from "../../../components/lib/Pagination";
import Button from "../../../components/lib/Button";
import Inline from "../../../components/lib/Inline";
import { CardAlert, CardAlertLink } from "../../../components/lib/Card";

class RegionList extends Component {
  constructor(props) {
    super(props);

    this.changeCurrent = this.changeCurrent.bind(this);
  }

  changeCurrent(value) {
    let store: RegionListComponentStore = this.props.store;
    store.pagination.handleCurrentPage(value);
  }

  componentDidMount() {
    this.props.store.pagination.goFetch();
  }

  /**
   * componentWillUnmount
   * @return {void}
   */
  componentWillUnmount() {
    this.props.store.clearFilters();
    this.props.store.clearAllSelections();
    this.props.store.applyDefaultSort();
  }

  render() {
    const store: RegionListComponentStore = this.props.store;
    const network = store.network;
    const fillContainer = this.props.fillContainer;
    const fixedHeight = this.props.fixedHeight;
    const maximumDate = new Date();
    let listBodyStyles = {};
    if (fixedHeight) {
      listBodyStyles.height = fixedHeight;
      listBodyStyles.overflowY = "auto";
    }

    let selectAllSection = null;
    let selectAllOnPageSection = (
      <Inline css={{ gap: "$2" }}>
        <Button onClick={store.selectAllOnPageItem}>Select All</Button>
        <Button onClick={store.deselectAllPage}>Deselect All</Button>
      </Inline>
    );

    if (store.allSelected) {
      selectAllSection = (
        <CardAlert>
          <span>
            All <strong>{store.totalCount}</strong> Regions selected.{" "}
          </span>
          {!store.isTagView && (
            <CardAlertLink key="clear-selections-link" onClick={store.clearAllSelections}>
              Clear all selections
            </CardAlertLink>
          )}
          {store.isTagView && (
            <CardAlertLink
              key="clear-selections-link"
              onClick={store.clearAllSelectionsOnTagView}
            >
              Clear all selections
            </CardAlertLink>
          )}
        </CardAlert>
      );
    }

    if (store.allOnPageSelected && !store.allSelected) {
      selectAllSection = (
        <CardAlert>
          <span>All Regions on this page selected.</span>
          <CardAlertLink key="select-all-item" onClick={store.selectAllPage}>
            Select all <strong>{store.totalCount}</strong> Regions.
          </CardAlertLink>
          {!store.isTagView && (
            <CardAlertLink key="clear-selections-link" onClick={store.clearAllSelections}>
              Clear all selections
            </CardAlertLink>
          )}
          {store.isTagView && (
            <CardAlertLink
              key="clear-selections-link"
              onClick={store.clearAllSelectionsOnTagView}
            >
              Clear all selections
            </CardAlertLink>
          )}
        </CardAlert>
      );
    }

    let tableLeftActionBar = null;
    if (store.isEditing) {
      tableLeftActionBar = (
        <nav className="header-left-nav">
          {store.allowMultipleItemSelection && selectAllOnPageSection}
        </nav>
      );
    }

    const regions = store.regionsListView;
    let tableContent = null;
    if (network.loading) {
      tableContent = <LoadingIndicator />;
    } else if (network.error) {
      // TODO: replace this with a more friendly error message
      tableContent = (
        <div className="pt-error-message">
          <h2>Error</h2>
          <pre>
            <code>{JSON.stringify(network.error, null, 2)}</code>
          </pre>
        </div>
      );
    } else if (regions.length === 0) {
      tableContent = (
        <div className="ratecards-table-empty">
          <div>
            No Region Found
            <p>Create a new Region using the button above or by searching.</p>
          </div>
        </div>
      );
    } else {
      tableContent = regions.map((region) => (
        <RegionListItem key={region.regionId} store={store} region={region} />
      ));
    }

    var fromDate = (
      <SingleDatePicker
        id="from_date"
        date={store.createdOnFilter.fromDate}
        numberOfMonths={1}
        isOutsideRange={(day) => day.isAfter(maximumDate)}
        focused={store.createdOnFilter.fromFocused}
        onDateChange={store.createdOnFilter.fromDateChange}
        onFocusChange={store.createdOnFilter.fromFocusedChange}
      />
    );

    var toDate = (
      <SingleDatePicker
        id="to_date"
        date={store.createdOnFilter.toDate}
        numberOfMonths={1}
        isOutsideRange={(day) => store.toDateRange(day)}
        focused={store.createdOnFilter.toFocused}
        onDateChange={store.createdOnFilter.toDateChange}
        onFocusChange={store.createdOnFilter.toFocusedChange}
      />
    );

    return (
      <>
        {store.createdOnFilter && (
          <CreatedOnFilter
            createdOnFilter={store.createdOnFilter}
            fromDate={fromDate}
            toDate={toDate}
          />
        )}
        {store.countryFilter && (
          <CountryFilter
            countryFilter={store.countryFilter}
            network={store.countryFilter.network.loading}
            instantSearchValue={store.countryFilter.instantSearchValue}
            onInstantSearch={store.countryFilter.onInstantSearch}
          />
        )}
        {store.tagsFilter && (
          <TagsFilter
            tagsFilter={store.tagsFilter}
            network={store.tagsFilter.network.loading}
            instantSearchValue={store.tagsFilter.instantSearchValue}
            onInstantSearch={store.tagsFilter.onInstantSearch}
          />
        )}

        <header
          className={classNames("ratecards-table-header", {
            active: store.isFiltered,
          })}
        >
          {tableLeftActionBar}
          <nav className="header-right-nav atl-1">
            {store.isFiltered && (
              <Button color="accent" onClick={store.clearFilters}>
                Clear All Filters &amp; Sorts
              </Button>
            )}
            {store.tagsFilter && (
              <ToggleButton
                borderless={true}
                name="search-list-filter"
                value={FILTER_COLUMN.TAGS}
                selected={Boolean(store.appliedFilters[FILTER_COLUMN.TAGS])}
                onClick={store.tagsFilter.onShowModal}
              >
                <SortIndicator
                  sortColumn={FILTER_COLUMN.TAGS}
                  sortsOrder={store.appliedSortsOrder}
                  sort={store.appliedSorts[FILTER_COLUMN.TAGS]}
                />
                Tags <span> ▼</span>
              </ToggleButton>
            )}
            {store.countryFilter && (
              <ToggleButton
                borderless={true}
                name="search-list-filter"
                value={FILTER_COLUMN.COUNTRY}
                selected={Boolean(store.appliedFilters[FILTER_COLUMN.COUNTRY])}
                onClick={store.countryFilter.onShowModal}
              >
                <SortIndicator
                  sortColumn={FILTER_COLUMN.COUNTRY}
                  sortsOrder={store.appliedSortsOrder}
                  sort={store.appliedSorts[FILTER_COLUMN.COUNTRY]}
                />
                Country <span> ▼</span>
              </ToggleButton>
            )}
            {store.createdOnFilter && (
              <ToggleButton
                borderless={true}
                name="rate-cards-filter"
                value={FILTER_COLUMN.REGION_DATE_RANGE}
                selected={Boolean(store.appliedFilters[FILTER_COLUMN.REGION_DATE_RANGE])}
                onClick={store.createdOnFilter.onShowModal}
              >
                <SortIndicator
                  sortType="numeric"
                  sortColumn={FILTER_COLUMN.REGION_DATE_RANGE}
                  sortsOrder={store.appliedSortsOrder}
                  sort={store.appliedSorts[FILTER_COLUMN.REGION_DATE_RANGE]}
                />
                Created On
                <span> ▼</span>
              </ToggleButton>
            )}
          </nav>
        </header>
        <div
          className={classNames("ratecards-table", {
            "ratecards-table-fill-container": fillContainer,
          })}
        >
          <div style={listBodyStyles}>
            {selectAllSection}
            {tableContent}
          </div>
        </div>
        {store.pagination.pageCount > 0 && (
          <Pagination
            options={{
              variant: "full",
              currentPage: store.pagination.currentPage,
              numPages: store.pagination.pageCount,
            }}
            onPageClick={(pageEvent) => {
              if (pageEvent.type === "first") {
                this.changeCurrent(1);
              }
              if (pageEvent.type === "last") {
                this.changeCurrent(store.pagination.pageCount);
              }
              if (pageEvent.type === "next") {
                this.changeCurrent(
                  Math.min(store.pagination.currentPage + 1, store.pagination.pageCount)
                );
              }
              if (pageEvent.type === "prev") {
                this.changeCurrent(Math.max(store.pagination.currentPage - 1, 1));
              }
              if (pageEvent.type === "page") {
                this.changeCurrent(pageEvent.page);
              }
            }}
          />
        )}
      </>
    );
  }
}

RegionList.propTypes = {
  store: PropTypes.object.isRequired,
  fixedHeight: PropTypes.number,
  fillContainer: PropTypes.bool,
};

export default observer(RegionList);
