// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router";
import Modal from "../../ratecards/components/Modal";
import ContainerSection from "../../ratecards/components/ContainerSection";
import LoadingIndicator from "../../shared/LoadingIndicator";
import RateCardListings from "./components/RateCardListings";
import { TagItemWithoutDelete } from "../../ratecards/components/TagItem";

const PCEDetail = observer((props) => {
  const store = props.store;

  let name,
    projectId,
    owner,
    tagSection,
    createdDate = null;

  if (store.network.loading) {
    name = "...";
    projectId = "...";
    owner = "...";
    createdDate = "...";
  } else if (store.project) {
    name = store.project.name;
    projectId = store.project.projectCostId;
    owner = store.project.owner;
    createdDate = store.project.createdDate.format("MMMM D, YYYY");
    tagSection = store.project.tags.map((tag) => (
      <TagItemWithoutDelete
        key={tag.tagId}
        tag={tag}
        onClick={(e) => {
          props.onClick(e, tag.tagId);
        }}
      />
    ));
  }

  var actionBar = (
    <nav className="action-bar">
      <button className="btn" onClick={store.export}>
        <FontAwesomeIcon icon="download" fixedWidth className="icon" />
        Export
      </button>
    </nav>
  );

  return (
    <div className="top">
      <div className="above">
        <div className="ratecard-label-header ellipsis max-width-80">
          <h2 className="ellipsis">{name}</h2>
        </div>
        <nav className="back-bar">
          <Link to={`/admin/project-cost-estimate/`} className="btn btn-green">
            <FontAwesomeIcon icon="arrow-left" fixedWidth className="icon" />
            Go Back
          </Link>
          <button className="btn" onClick={store.showHelp}>
            <FontAwesomeIcon icon="question-circle" fixedWidth className="icon" />
            Help
          </button>
        </nav>
      </div>
      <div className="below">
        <div className="ratecard-meta ellipsis">
          <div className="ratecard-owner ellipsis">
            <FontAwesomeIcon icon="key" fixedWidth className="icon" /> {projectId}
          </div>
          <div className="ratecard-owner ellipsis">
            <FontAwesomeIcon icon="user" fixedWidth className="icon" /> {owner}
          </div>
          <div className="ratecard-created-at ellipsis">
            <FontAwesomeIcon icon="calendar-alt" fixedWidth className="icon" />{" "}
            {createdDate}
          </div>
        </div>
        {actionBar}
      </div>
      <div className="tagbox">{tagSection}</div>
      <Modal show={store.showHelpModal} onHide={store.hideHelp}>
        <div className="container-section header">
          <h4>Help</h4>
        </div>
        <div className="container-section footer">
          <p>Here you can see all the Details related to this Project.</p>
        </div>
      </Modal>
    </div>
  );
});

/**
 * PCEDetail component for project cost detail page
 */
class Detail extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor() {
    super();
    this.deleteTag = this.deleteTag.bind(this);
  }

  /**
   * componentDidMount
   * @return {void}
   */
  componentDidMount() {
    this.props.store.projectCostEstimateDetailPTStore.projectId =
      this.props.router.params.projectCostEstimateId;
    this.props.store.projectCostEstimateDetailPTStore.router = this.context.router;
    this.props.store.projectCostEstimateDetailPTStore.getProjectDetails();
  }

  deleteTag(e: object, tag_id) {
    // this.props.store.projectCostEstimateDetailPTStore.deleteSingleTags({
    //   tagId : tag_id,
    //   contentId:this.props.router.params.projectCostEstimateId,
    //   contentType:"PROJECT_STATS"
    // });
  }

  /**
   * Render PCE detail page
   * @return {XML}
   */

  getExchangeRates(localCurrency, rate) {
    const store = this.props.store.projectCostEstimateDetailPTStore;
    var exchangeRatesHash = null;
    var exchangeCurrency = 0.0;
    var currency = "USD";
    if (localCurrency) currency = localCurrency.iso;
    if (store.exchangeRates) exchangeRatesHash = JSON.parse(store.exchangeRates).rates;
    if (exchangeRatesHash) exchangeCurrency = rate / exchangeRatesHash[currency];

    return exchangeCurrency;
  }

  render() {
    const store = this.props.store.projectCostEstimateDetailPTStore;
    const pceStore = this.props.store.projectCostEstimateStore;
    const rcstore = this.props.store.rateCardListStore;
    const network = store.network;
    let totalPeopleNeeded = 0;
    let totalBillableHours = 0;
    let totalCostEstimate = 0.0;
    var projectStats = store.projectStats;

    for (var i = projectStats.length - 1; i >= 0; i--) {
      totalPeopleNeeded += parseInt(projectStats[i].peopleNeeded, 10) || 0;
      totalBillableHours += parseFloat(projectStats[i].billableHours) || 0;
      totalCostEstimate +=
        projectStats[i].peopleNeeded *
        projectStats[i].billableHours *
        this.getExchangeRates(
          projectStats[i].search.currency,
          projectStats[i].avgBillRate
        ).toFixed(2);
    }

    totalCostEstimate = totalCostEstimate
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");

    let tableContent = null;
    let tableRows = null;
    if (network.loading) {
      tableContent = <LoadingIndicator />;
    } else if (network.error) {
      tableContent = (
        <div className="pt-error-message">
          <h2>Error</h2>
          <pre>
            <code>{network.error[0].message}</code>
          </pre>
        </div>
      );
    } else if (!projectStats.length > 0) {
      tableContent = (
        <div>
          <table
            className="no-spacing table table_label"
            cellSpacing="0"
            style={{
              textAlign: "center",
            }}
          >
            <thead className="">
              <tr>
                <th className="col-sm-1">Job Label</th>
                <th className="col-sm-2">Job Title</th>
                <th className="col-sm-1">Job Location</th>
                <th className="col-sm-1">Exp. Level</th>
                <th className="col-sm-1">Bill Rate (Local Currency)</th>
                <th className="col-sm-1">Bill Rate (Common Currency)</th>
                <th className="col-sm-1">Quantity</th>
                <th className="col-sm-1">Billable Hours</th>
                <th className="col-sm-1">Cost Estimate</th>
                <th className="col-sm-1" />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="col-sm-1">No Available Punchouts</td>
              </tr>
            </tbody>
          </table>
          <table className="no-spacing table table_label" cellSpacing="0">
            <thead className="">
              <tr>
                <th className="col-sm-1" />
                <th className="col-sm-2" />
                <th className="col-sm-1" />
                <th className="col-sm-1" />
                <th className="col-sm-1" />
                <th className="col-sm-1">Total</th>
                <th className="col-sm-1">{totalPeopleNeeded}</th>
                <th className="col-sm-1">{totalBillableHours}</th>
                <th className="col-sm-1">${totalCostEstimate}</th>
                <th className="col-sm-1" />
              </tr>
            </thead>
          </table>
          <nav className="action-bar">
            {/*<button className="btn btn-green" onClick={store.updateProject}>
            Save to Worksheet
          </button>*/}
          </nav>
        </div>
      );
    } else {
      tableRows = projectStats.map((punchout) => (
        <PCEPunchoutDetails
          key={punchout.projectStatId}
          punchout={punchout}
          store={store}
          usd={this.getExchangeRates(punchout.search.currency, punchout.avgBillRate)}
        />
      ));

      tableContent = (
        <div>
          <table
            className="no-spacing table table_label"
            cellSpacing="0"
            style={{
              textAlign: "center",
            }}
          >
            <thead className="">
              <tr>
                <th className="col-sm-1">Job Label</th>
                <th className="col-sm-2">Job Title</th>
                <th className="col-sm-1">Job Location</th>
                <th className="col-sm-1">Exp. Level</th>
                <th className="col-sm-1">Bill Rate (Local Currency)</th>
                <th className="col-sm-1">Bill Rate (Common Currency)</th>
                <th className="col-sm-1">Quantity</th>
                <th className="col-sm-1">Billable Hours</th>
                <th className="col-sm-1">Cost Estimate</th>
                <th className="col-sm-1" />
              </tr>
            </thead>
            <tbody>{tableRows}</tbody>
          </table>
          <table className="no-spacing table table_label" cellSpacing="0">
            <thead className="">
              <tr>
                <th className="col-sm-1" />
                <th className="col-sm-2" />
                <th className="col-sm-1" />
                <th className="col-sm-1" />
                <th className="col-sm-1" />
                <th className="col-sm-1">Total</th>
                <th className="col-sm-1">{totalPeopleNeeded}</th>
                <th className="col-sm-1">{totalBillableHours}</th>
                <th className="col-sm-1">${totalCostEstimate}</th>
                <th className="col-sm-1" />
              </tr>
            </thead>
          </table>
          <nav className="action-bar">
            {/*<button className="btn btn-green" onClick={store.updateProject}>
            Save to Worksheet
          </button>*/}
          </nav>
        </div>
      );
    }

    return (
      <div className="view">
        <div className="rc-container show-overflow">
          <PCEDetail
            store={store}
            pceStore={pceStore}
            router={this.props.router}
            onClick={this.deleteTag}
          />
        </div>
        <div className="rc-container bring-forward">
          <div className="header-action-bar">
            {/*<div className="btn-group pull-right">
              <button className="btn btn-green" onClick={e => {
                store.showRateCard(pceStore, store.projectStats)
              }}>
                <FontAwesomeIcon icon="plus" fixedWidth className="icon"></i>
                <span>
                  Edit</span>
              </button>
            </div>*/}
            <div className="clearfix" />
          </div>
          {tableContent}
        </div>

        <Modal
          show={store.showRateCardModal}
          onHide={store.hideRateCard}
          innerStyle={{
            width: "100% !important",
          }}
        >
          <div className="container-section header">
            <h4>Available Rate Cards</h4>
          </div>
          <div className="rc-viewport overlap-lr-padding">
            <div style={{ overflow: "auto", height: "450px" }}>
              <RateCardListings
                store={rcstore}
                pcestore={pceStore}
                createstore={store}
                requestFrom="pce"
              />
            </div>
          </div>
          <ContainerSection className="footer">
            <div className="pull-right">
              <button className="btn btn-lg btn-default" onClick={store.hideRateCard}>
                Cancel
              </button>
              <button
                className="btn btn-lg btn-green"
                onClick={(e) => {
                  store.updateProjectStats(pceStore.punchoutArrayView);
                  store.hideRateCard();
                }}
              >
                Update Worksheet
              </button>
            </div>
          </ContainerSection>
        </Modal>
      </div>
    );
  }
}

const PCEPunchoutDetails = (props) => {
  const punchout = props.punchout;
  const store = props.store;
  var usd = props.usd.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
  var estimatedCost = (
    props.usd.toFixed(2) *
    punchout.peopleNeeded *
    punchout.billableHours
  )
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
  var billRateId = "billrate" + punchout.projectStatId;
  var peopleNeededId = "peopleNeeded" + punchout.projectStatId;
  var billableHoursId = "billableHours" + punchout.projectStatId;
  var state = "";
  var country = punchout.search.country;
  if (punchout.search.state) {
    state = punchout.search.state + ", ";
  }
  var loc = state + country;

  return (
    <tr>
      <td className="col-sm-1">{punchout.search.job.jobLabel}</td>
      <td className="col-sm-1">{punchout.search.job.jobTitle}</td>
      <td className="col-sm-1">{loc}</td>
      <td className="col-sm-1">{punchout.level.value}</td>
      <td className="col-sm-1">
        <input
          type="text"
          className="rc-input-box"
          id={billRateId}
          style={{
            width: "100%",
          }}
          value={punchout.avgBillRate}
          disabled="true"
          onChange={(e) => store.changeBillRates(e, punchout)}
          onBlur={(e) => store.onFocusOutFn(e, punchout)}
        />
      </td>
      <td className="col-sm-1">${usd}</td>
      <td className="col-sm-1">
        <input
          type="text"
          className="rc-input-box"
          id={peopleNeededId}
          style={{
            width: "70%",
          }}
          value={punchout.peopleNeeded}
          disabled="true"
          onChange={(e) => store.changePeopleNeededForPunchout(e, punchout)}
          onBlur={(e) => store.onFocusOutPeopleNeeded(e, punchout)}
        />
      </td>
      <td className="col-sm-1">
        <input
          type="text"
          className="rc-input-box"
          id={billableHoursId}
          style={{
            width: "70%",
          }}
          value={punchout.billableHours}
          disabled="true"
          onChange={(e) => store.changeBillableHoursForPunchout(e, punchout)}
          onBlur={(e) => store.onFocusOutBillableHours(e, punchout)}
        />
      </td>
      <td className="col-sm-1">${estimatedCost}</td>
    </tr>
  );
};

export default observer(Detail);
