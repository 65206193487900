// @flow

import { extendObservable } from "mobx";

export default class CCCCountry {
  store: Object;
  id: string;
  databaseId: number;
  isActive: ?boolean;
  title: ?string;
  iso3166Alpha2: ?string;
  iso3166Alpha3: ?string;
  iso3166Numeric: ?number;
  code: string;
  selected: boolean;

  constructor(store: Object, payloadObject: Object) {
    this.store = store;
    this.title = null;
    this.isActive = null;
    this.iso3166Alpha2 = null;
    this.iso3166Alpha3 = null;
    this.iso3166Numeric = null;
    this.code = "";

    if (payloadObject) {
      if (!payloadObject.databaseId)
        throw new Error("Cannot instantiate Country without an ID!");
      this.id = payloadObject.databaseId;
      this.databaseId = payloadObject.databaseId;
      this.title = payloadObject.title;
      this.isActive = payloadObject.isActive;
      this.iso3166Alpha2 = payloadObject.iso3166Alpha2;
      this.iso3166Alpha3 = payloadObject.iso3166Alpha3;
      this.iso3166Numeric = payloadObject.iso3166Numeric;
      this.code = payloadObject.iso3166Alpha2 || "";
    }

    extendObservable(this, {
      selected: false,
    });
  }
}
