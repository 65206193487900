import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { shouldComponentUpdate } from "../utils/immutableRender";

export default class Messager extends React.Component {
  static displayName = "Messager";
  static propTypes = {
    colored: PropTypes.bool,
    withIcons: PropTypes.bool,
    showTiming: PropTypes.number,
    fadeTiming: PropTypes.number,
    icons: PropTypes.object,
    className: PropTypes.string,
  };

  static defaultProps = {
    colored: true,
    withIcons: true,
    showTiming: 4000, // 4 sec
    fadeTiming: 500, // 0.5 sec
  };

  static MESSAGE_TYPES = {
    SUCCESS: "success",
    FAILURE: "failure",
    WARNING: "warning",
    HINT: "hint",
  };

  static MESSAGE_ICONS = {
    success: '<span class="fa fa-check"/>',
    warning: '<span class="fa fa-exclamation-triangle"/>',
    failure: '<span class="fa fa-exclamation-triangle"/>',
    hint: '<span class="fa fa-comment"/>',
  };

  state = {
    visible: false,
    color: null,
  };

  constructor(props) {
    super(props);
    this._last = Promise.resolve(); // last promise in queue
    this._resolveCurrent = null; // func to resolve current in queue
    this._rejectCurrent = null; // func to reject current in queue
  }

  shouldComponentUpdate = shouldComponentUpdate;

  componentWillUnmount() {
    this.cancelAll();
  }

  message(html, type = null, showIcon = true, withTiming = true, autoclose = true) {
    const last = this._last;
    // add to queue
    this._last = new Promise((resolve, reject) => {
      last
        .then(() => {
          this._resolveCurrent = resolve;
          this._rejectCurrent = reject;
          this._showMessage(html, type, showIcon, withTiming, autoclose)
            .then(resolve)
            .catch(reject);
        })
        .catch(reject);
    }).catch((err) => {}); // to avoid "Unhandled promise rejection" error

    return this._last;
  }

  success(html, showIcon, withTiming, autoclose) {
    return this.message(
      html,
      this.constructor.MESSAGE_TYPES.SUCCESS,
      showIcon,
      withTiming,
      autoclose
    );
  }

  warning(html, showIcon, withTiming, autoclose) {
    return this.message(
      html,
      this.constructor.MESSAGE_TYPES.WARNING,
      showIcon,
      withTiming,
      autoclose
    );
  }

  failure(html, showIcon, withTiming, autoclose) {
    return this.message(
      html,
      this.constructor.MESSAGE_TYPES.FAILURE,
      showIcon,
      withTiming,
      autoclose
    );
  }

  hint(html, showIcon, withTiming, autoclose) {
    return this.message(
      html,
      this.constructor.MESSAGE_TYPES.HINT,
      showIcon,
      withTiming,
      autoclose
    );
  }

  hide() {
    if (this._timeoutId) {
      clearTimeout(this._timeoutId);
      this._timeoutId = null;
    }
    this._last = this._hideMessage().then(() => {
      if (this._resolveCurrent) this._resolveCurrent();
    });

    return this._last;
  }

  cancelAll(hide = false) {
    if (this._timeoutId) {
      clearTimeout(this._timeoutId);
      this._timeoutId = null;
    }
    if (hide) {
      return this._hideMessage().then(() => {
        if (this._rejectCurrent) {
          this._rejectCurrent();
          this._last = Promise.resolve();
        }
      });
    }
    if (this._rejectCurrent) {
      this._rejectCurrent();
      this._last = Promise.resolve();
    }
    return Promise.resolve();
  }

  _showMessage(html, type, showIcon, withTiming, autoclose) {
    const holderElement = ReactDOM.findDOMNode(this.refs.holder);
    const { showTiming, fadeTiming, children } = this.props;
    const icons = this.props.icons || this.constructor.MESSAGE_ICONS;

    return new Promise((resolve) => {
      if (this.props.withIcons && showIcon && type) {
        html = [html, icons[type]].join("");
      }
      type = type || null;

      if (!React.Children.count(children)) {
        holderElement.innerHTML = html;
      }

      this.setState({ visible: true, color: type }, () => {
        if (autoclose) {
          this._timeoutId = setTimeout(
            () => {
              // wait for fading + existanse time
              this._timeoutId = null;
              this._hideMessage().then(resolve);
            },
            withTiming ? fadeTiming + showTiming : fadeTiming
          );
        }
      });
    });
  }

  _hideMessage() {
    const { fadeTiming } = this.props;

    return new Promise((resolve) => {
      this.setState({ visible: false }, () => {
        setTimeout(() => resolve(), fadeTiming); // wait for fading time only and resolve promise
      });
    });
  }

  render() {
    const { colored, withIcons, showTiming, fadeTiming, className, children, ...props } =
      this.props;
    const { visible, color } = this.state;
    const resultingClassNames = classNames(
      className,
      "text-x-small",
      { "pt-messager": true, "pt-messager--visible": !!visible },
      color && { ["pt-messager--" + color]: !!colored && !!color }
    );

    return (
      <div className={resultingClassNames} {...props}>
        <span className="pt-messager__holder" ref="holder">
          {children}
        </span>
      </div>
    );
  }
}
