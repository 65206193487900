import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react";
import numeral from "numeral";

const DetailsTableRowData = observer((props) => {
  return (
    <td className="text-right">
      <div className={classNames({ hidden: !props.store.showRates })}>
        {props.prefix} {numeral(props.value).format("0,0.00")}
        {props.suffix}
      </div>
      <div
        className={classNames(props.changeType, {
          hidden: !props.store.showDeltas,
        })}
      >
        {props.prefix} {numeral(props.change).format("+0,0.00")}
        {props.suffix}
      </div>
    </td>
  );
});

const DetailsTableRow = observer((props) => {
  return (
    <tr className={classNames({ hidden: props.hidden })}>
      {!props.hideIcon && (
        <td className="ticker-cell text-right">
          <FontAwesomeIcon
            icon={props.changeIcon}
            fixedWidth
            className={"ticker " + props.changeType}
          />
        </td>
      )}
      <td>{props.text}</td>
      <DetailsTableRowData
        store={props.store}
        prefix={props.prefix}
        suffix={props.suffix}
        value={props.value}
        change={props.change}
        changeType={props.changeType}
      />
    </tr>
  );
});

DetailsTableRow.propTypes = {
  store: PropTypes.object.isRequired,
  // Supposed to be a string, but actually can be React Element (like Span)
  text: PropTypes.string.isRequired,
  value: PropTypes.number,
  change: PropTypes.number,
  changeType: PropTypes.string,
  changeIcon: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  hideIcon: PropTypes.bool,
  hidden: PropTypes.bool,
};

DetailsTableRow.defaultProps = {
  value: 0,
  change: 0,
};

export default DetailsTableRow;
