import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../ratecards/components/Modal";
import ContainerSection from "../ratecards/components/ContainerSection";
import classNames from "classnames";
import ContentList from "./components/ContentList";
import RegionListTag from "../region/List";
import JobListTag from "../job_labels/List";
import RateCardListTag from "../ratecards/List";
import SavedSearchesListTag from "../saved_searches/List";
import PCEListTag from "../project-cost-estimate/List";
import NWSListTag from "../negotiation_worksheets/List";
import SelectableItem from "../ratecards/components/SelectableItem";
import SelectableItemsList from "../ratecards/components/SelectableItemsList";
import InstantSearchBox from "../ratecards/components/InstantSearchBox";
import Loadable from "../ratecards/components/Loadable";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Button from "../../components/lib/Button";
import LinkButton from "../../components/lib/LinkButton";
import { styled } from "../../../src/stitches.config";
import Inline from "../../components/lib/Inline";

const ActionBar = styled("nav", {
  display: "flex",
  alignItems: "center",
  gap: "$3",
  justifyContent: "flex-end",
});

const ModalMd = styled(Modal, {
  minWidth: "1100px",
});

const ShareWithUsersList = observer((props) => {
  return (
    <div>
      <SelectableItemsList>
        {props.filterState.shareUsersView.map((user, i) => (
          <SelectableItem
            key={user.username}
            item={user}
            name="share-user"
            value={user.username}
            selected={user.selected}
            onChange={props.filterState.setSharedUserSelectedValue}
          >
            <div>
              {user.firstName} {user.lastName}
            </div>
            <div className="text-x-small">
              <em>
                <FontAwesomeIcon icon="user" className="icon no-margin" />
                {user.username}
              </em>
            </div>
          </SelectableItem>
        ))}
      </SelectableItemsList>
      <ContainerSection className="info">
        <p className="text-muted text-x-small no-margin pull-left">
          <FontAwesomeIcon icon="info-circle" fixedWidth className="icon" />
          <em> Stop sharing by leaving all Users unselected.</em>
        </p>
        <p className="text-muted text-x-small no-margin no-border pull-right">
          <em>
            <strong>{props.filterState.shareUsersSelectedCount} </strong>selected,{" "}
            <strong>{props.filterState.shareUsersTotalCount} </strong>total
          </em>
        </p>
        <div className="clearfix" />
      </ContainerSection>
    </div>
  );
});

const TagDetail = observer((props) => {
  const store = props.store;

  let owner,
    tagName,
    shared,
    createdDate = null;

  let sharedByYou = false;

  if (store.network.loading) {
    tagName = "...";
    owner = "...";
    createdDate = "...";
    shared = false;
  } else if (store.tagDetail) {
    tagName = `${store.tagDetail.name}`;
    owner = store.tagDetail.owner.fullname;
    createdDate = store.tagDetail.createdDisplay;
    shared = store.tagDetail.shared;

    if (String(store.tagDetail.owner.clientId) !== String(store.currentUser.userId)) {
      sharedByYou = true;
    }
  }

  const sharedTooltip = <Tooltip id="shared-icon-tooltip">Shared...</Tooltip>;

  let actionBar = null;
  if (!store.network.loading && !store.network.error && !sharedByYou)
    actionBar = (
      <ActionBar>
        {store.hasOwnership && (
          <Button onClick={store.showRenameTagModal}>
            <FontAwesomeIcon icon="edit" fixedWidth className="icon" />
            Rename
          </Button>
        )}
        {store.hasOwnership && (
          <Button onClick={store.share}>
            <FontAwesomeIcon icon="share" fixedWidth className="icon" /> Share
          </Button>
        )}
        {store.hasOwnership && (
          <Button color="danger" onClick={store.confirmDeleteModal.showModal}>
            <FontAwesomeIcon icon="trash-alt" fixedWidth className="icon" />
            Delete
          </Button>
        )}
      </ActionBar>
    );

  return (
    <div className="top">
      <div className="above">
        <div className="ratecard-label-header ellipsis max-width-80">
          <h2 className="ellipsis">{tagName}</h2>
          {shared && !sharedByYou && (
            <OverlayTrigger placement="top" overlay={sharedTooltip}>
              <span className="tag text-small align-top">
                <FontAwesomeIcon icon="share" fixedWidth className="icon" />
                By You
              </span>
            </OverlayTrigger>
          )}
          {shared && sharedByYou && (
            <OverlayTrigger placement="top" overlay={sharedTooltip}>
              <span className="tag text-small align-top">
                <FontAwesomeIcon
                  icon="share"
                  fixedWidth
                  flip="horizontal"
                  className="icon"
                />
                To You
              </span>
            </OverlayTrigger>
          )}
        </div>
        <ActionBar>
          <LinkButton color="brand" to={`/tag/`}>
            <FontAwesomeIcon icon="arrow-left" fixedWidth className="icon" />
            Go Back
          </LinkButton>
          <Button onClick={store.showHelpModal.showModal}>
            <FontAwesomeIcon icon="question-circle" fixedWidth className="icon" />
            Help
          </Button>
        </ActionBar>
      </div>
      <div className="below">
        <div className="ratecard-meta ellipsis">
          <div className="ratecard-owner ellipsis">
            <FontAwesomeIcon icon="user" fixedWidth className="icon" /> {owner}
          </div>
          <div className="ratecard-created-at ellipsis">
            <FontAwesomeIcon icon="calendar-alt" fixedWidth className="icon" />{" "}
            {createdDate}
          </div>
        </div>
        {actionBar}
      </div>
      <Modal show={store.showHelpModal.show} onHide={store.showHelpModal.hideModal}>
        <div className="container-section header">
          <h4>Help</h4>
        </div>
        <div className="container-section footer">
          <p>Here you can see all the tag details</p>
        </div>
      </Modal>
      <Modal show={store.renameTagModal.show} onHide={store.renameTagModal.hideModal}>
        <ContainerSection className="header">
          <h4>Rename tag</h4>
        </ContainerSection>
        <ContainerSection>
          <p>Name of the Tag:</p>
          <input
            type="text"
            className="rc-input-box"
            style={{
              width: "100%",
            }}
            value={store.newTagName}
            onChange={store.onNewTagNameChange}
          />
          <div className="pt-user-messages">
            {store.messaging.messages.length > 0 && (
              <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                <a
                  href="#close"
                  className="close"
                  data-dismiss="alert"
                  aria-label="close"
                  onClick={store.messaging.removeMessage.bind(
                    store,
                    store.messaging.messages[0]
                  )}
                >
                  &times;
                </a>
                <strong>{store.messaging.messages[0].messageLabel}</strong>
                {store.messaging.messages[0].message}
              </div>
            )}
          </div>
        </ContainerSection>
        <ContainerSection className="footer">
          <Inline css={{ gap: "$2" }}>
            <Button size="large" onClick={store.renameTagModal.hideModal}>
              Cancel
            </Button>
            <Button color="brand" size="large" onClick={store.renameTag}>
              Rename Tag
            </Button>
          </Inline>
        </ContainerSection>
      </Modal>
      <Modal
        show={store.confirmDeleteModal.show}
        onHide={store.confirmDeleteModal.hideModal}
      >
        <div className="container-section header">
          <h4>Confirmation Needed</h4>
        </div>
        <div className="container-section">
          <p>Are you sure you want to delete this Tag?</p>
          <Inline css={{ color: "$danger" }}>
            <FontAwesomeIcon icon="exclamation-triangle" />{" "}
            <em>This action cannot be undone!</em>
          </Inline>
        </div>
        <div className="container-section footer">
          <Inline>
            <Button size="large" onClick={store.confirmDeleteModal.hideModal}>
              No, Cancel
            </Button>
            <Button
              size="large"
              color="danger"
              onClick={(e) => {
                store.confirmDeleteModal.hideModal();
                store.deleteTag();
              }}
            >
              Yes, Delete Tag
            </Button>
          </Inline>
        </div>
      </Modal>
      <Modal
        show={store.shareModal.show}
        onHide={store.shareModal.hideModal}
        innerStyle={{ width: 550 }}
      >
        <ContainerSection className="header">
          <h4>Share Tags</h4>
        </ContainerSection>
        <Loadable isLoading={store.networkShareUsers.loading}>
          <ContainerSection className="container-section no-border">
            <p>Choose Users to share with:</p>
            <div>
              <div className="pull-left">
                <InstantSearchBox
                  onSearch={store.shareUsersOnInstantSearch}
                  value={store.shareUsersInstantSearchValue}
                />
              </div>
              <Inline
                css={{ justifyContent: "flex-end", gap: "$2" }}
                style={{ height: 40 }}
              >
                <Button onClick={store.shareUsersOnSelectAll}>Select All</Button>
                <Button onClick={store.shareUsersOnDeselectAll}>Deselect All</Button>
              </Inline>
              <div className="clearfix" />
            </div>
          </ContainerSection>
          <ShareWithUsersList filterState={store} />
          <ContainerSection className="footer">
            <Inline css={{ justifyContent: "flex-end", gap: "$2" }}>
              <Button size="large" onClick={store.shareModal.hideModal}>
                Cancel
              </Button>
              <Button color="brand" size="large" onClick={store.performShare}>
                Share With Selected Users
              </Button>
            </Inline>
          </ContainerSection>
        </Loadable>
      </Modal>
    </div>
  );
});

const AddSearchesModal = observer((props) => {
  let store = props.tagDetailStore;
  let parentStore = props.parentStore;

  return (
    <ModalMd
      show={store.searchAddTagModal.show}
      onHide={store.searchAddTagModal.hideModal}
      innerStyle={{
        width: "100% !important",
      }}
    >
      <div className="container-section header">
        <h4>Available Searches</h4>
      </div>
      <div className="rc-viewport overlap-lr-padding">
        <div className="headrtfix">
          <SavedSearchesListTag store={parentStore} />
        </div>
      </div>

      <ContainerSection className="footer">
        <Inline css={{ justifyContent: "flex-end", gap: "$2" }}>
          <Button
            size="large"
            onClick={(e) => {
              parentStore.savedSearchesListStore.clearFilters();
              parentStore.savedSearchesListStore.clearAllSelections();
              store.searchAddTagModal.hideModal();
            }}
          >
            Cancel
          </Button>
          <Button
            size="large"
            color="brand"
            onClick={(e) => {
              store.onAddSearchesClick(parentStore.savedSearchesListStore.rateCardsView);
            }}
          >
            Add to Selected Searches
          </Button>
        </Inline>
      </ContainerSection>
    </ModalMd>
  );
});

const AddRegionsModal = observer((props) => {
  let store = props.store;
  let regionListStore = props.regionListStore;
  let tagStore = props.tagDetailStore;

  return (
    <ModalMd
      show={tagStore.regionAddTagModal.show}
      onHide={tagStore.regionAddTagModal.hideModal}
      innerStyle={{
        width: "100% !important",
      }}
    >
      <div className="container-section header">
        <h4>Available Regions</h4>
      </div>
      <div className="rc-viewport overlap-lr-padding">
        <div className="modal-container headrtfix">
          <RegionListTag store={store} />
        </div>
      </div>

      <ContainerSection className="footer">
        <Inline css={{ justifyContent: "flex-end", gap: "$2" }}>
          <Button
            size="large"
            onClick={(e) => {
              regionListStore.clearFilters();
              regionListStore.clearAllSelections();
              tagStore.regionAddTagModal.hideModal();
            }}
          >
            Cancel
          </Button>
          <Button
            color="brand"
            size="large"
            onClick={(e) => {
              tagStore.onAddRegionsClick(regionListStore.regionsListView);
            }}
          >
            Add to Selected Regions
          </Button>
        </Inline>
      </ContainerSection>
    </ModalMd>
  );
});

const AddJobsModal = observer((props) => {
  let store = props.store;
  let jobStore = props.jobLabelListStore;
  let tagStore = props.tagDetailStore;

  return (
    <ModalMd
      show={tagStore.jobAddTagModal.show}
      onHide={tagStore.jobAddTagModal.hideModal}
      innerStyle={{
        width: "100% !important",
      }}
    >
      <div className="container-section header">
        <h4>Available Jobs</h4>
      </div>
      <div className="rc-viewport overlap-lr-padding">
        <div className="modal-container headrtfix">
          <JobListTag store={store} />
        </div>
      </div>

      <ContainerSection className="footer">
        <Inline css={{ justifyContent: "flex-end", gap: "$2" }}>
          <Button
            size="large"
            onClick={(e) => {
              jobStore.clearFilters();
              jobStore.clearAllSelections();
              tagStore.jobAddTagModal.hideModal();
            }}
          >
            Cancel
          </Button>
          <Button
            color="brand"
            size="large"
            onClick={(e) => {
              tagStore.onAddJobsClick(jobStore.jobsListView);
            }}
          >
            Add to Selected Jobs
          </Button>
        </Inline>
      </ContainerSection>
    </ModalMd>
  );
});

const AddRateCardModal = observer((props) => {
  let store = props.store;
  let rateCardListStore = props.rateCardStore;
  let tagStore = props.tagDetailStore;

  return (
    <ModalMd
      show={tagStore.rateCardAddTagModal.show}
      onHide={tagStore.rateCardAddTagModal.hideModal}
      innerStyle={{
        width: "100% !important",
      }}
    >
      <div className="container-section header">
        <h4>Available RateCards</h4>
      </div>
      <div className="rc-viewport overlap-lr-padding">
        <div className="modal-container headrtfix">
          <RateCardListTag store={store} />
        </div>
      </div>

      <ContainerSection className="footer">
        <Inline css={{ justifyContent: "flex-end", gap: "$2" }}>
          <Button
            colorr="brand"
            size="large"
            onClick={(e) => {
              rateCardListStore.clearAllSelections();
              rateCardListStore.clearFilters();
              tagStore.rateCardAddTagModal.hideModal();
            }}
          >
            Cancel
          </Button>
          <Button
            color="brand"
            size="large"
            onClick={(e) => {
              tagStore.onAddRateCardClick(rateCardListStore.rateCardsView);
            }}
          >
            Add to Selected RateCard
          </Button>
        </Inline>
      </ContainerSection>
    </ModalMd>
  );
});

const AddProjectCostModal = observer((props) => {
  let store = props.store;
  let projectCostEstimateStore = props.projectCostEstimateStore;
  let tagStore = props.tagDetailStore;

  return (
    <ModalMd
      show={tagStore.pceAddTagModal.show}
      onHide={tagStore.pceAddTagModal.hideModal}
      innerStyle={{
        width: "100% !important",
      }}
    >
      <div className="container-section header">
        <h4>Available Projects</h4>
      </div>
      <div className="rc-viewport overlap-lr-padding">
        <div className="modal-container headrtfix">
          <PCEListTag store={store} />
        </div>
      </div>

      <ContainerSection className="footer">
        <Inline css={{ justifyContent: "flex-end", gap: "$2" }}>
          <Button
            size="large"
            onClick={(e) => {
              projectCostEstimateStore.clearAllSelections();
              projectCostEstimateStore.clearFilters();
              tagStore.pceAddTagModal.hideModal();
            }}
          >
            Cancel
          </Button>
          <Button
            color="brand"
            size="large"
            onClick={(e) => {
              tagStore.onAddPCEClick(projectCostEstimateStore.projectCostsView);
            }}
          >
            Add to Selected Projects
          </Button>
        </Inline>
      </ContainerSection>
    </ModalMd>
  );
});

const AddNWSModal = observer((props) => {
  let store = props.store;
  let negotiationWorksheetListStore = props.negotiationWorksheetListStore;
  let tagStore = props.tagDetailStore;

  return (
    <ModalMd
      show={tagStore.nwsAddTagModal.show}
      onHide={tagStore.nwsAddTagModal.hideModal}
      innerStyle={{
        width: "100% !important",
      }}
    >
      <div className="header container-section header">
        <h4>Available Worksheets</h4>
      </div>
      <div className="rc-viewport overlap-lr-padding">
        <div className="modal-container headrtfix">
          <NWSListTag store={store} />
        </div>
      </div>

      <ContainerSection className="footer">
        <Inline css={{ justifyContent: "flex-end", gap: "$2" }}>
          <Button
            size="large"
            onClick={(e) => {
              negotiationWorksheetListStore.clearAllSelections();
              negotiationWorksheetListStore.clearFilters();
              tagStore.nwsAddTagModal.hideModal();
            }}
          >
            Cancel
          </Button>
          <Button
            color="brand"
            size="large"
            onClick={(e) => {
              tagStore.onAddNWSClick(negotiationWorksheetListStore.negotiationListView);
            }}
          >
            Add to Selected Worksheets
          </Button>
        </Inline>
      </ContainerSection>
    </ModalMd>
  );
});

class Detail extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  componentDidMount() {
    const store = this.props.store.tagManagementDetailStore;
    store.handleStopEdit();
    store.tagId = this.props.router.params.tagId;
    store.router = this.context.router;
    store.isEditing = null;
    store.fetchTagDetail();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const store = this.props.store.tagManagementDetailStore;
    store.handleStopEdit();
    store.tagId = this.props.router.params.tagId;
    store.router = this.context.router;
    store.isEditing = null;
    store.fetchTagDetail();
  }

  componentWillUnmount() {
    this.props.store.tagManagementDetailStore.router = null;
  }

  render() {
    const store = this.props.store.tagManagementDetailStore;
    const parentStore = this.props.store;
    const regionListStore = this.props.store.regionListStore;
    const jobStore = this.props.store.jobLabelListStore;
    const rateCardListStore = this.props.store.rateCardListStore;
    const projectCostEstimateStore = this.props.store.projectCostEstimateStore;
    const negotiationWorksheetListStore = this.props.store.negotiationWorksheetListStore;
    const contentListStore = this.props.store.contentListStore;

    // TODO: Might be needed, probably should be removed
    // let sharedByYou = false;
    // if (store.tagDetail) {
    //   if (
    //     String(store.tagDetail.owner.clientId) !==
    //     String(store.currentUser.userId)
    //   ) {
    //     sharedByYou = true;
    //   }
    // }

    return (
      <div className="view">
        <div className="rc-container show-overflow">
          <TagDetail store={store} router={this.props.router} />
        </div>

        <div className="rc-container bring-forward">
          <div className="header-action-bar">
            <h4
              className="pull-left"
              style={{
                lineHeight: "30px",
              }}
            >
              Items attached to this tag
            </h4>
            {!store.network.loading && !store.network.error && (
              <div className="btn-group pull-right">
                {store.isEditing && (
                  <Button color="accent" onClick={store.handleStopEdit}>
                    Stop Editing
                  </Button>
                )}
                {!store.isEditing && (
                  <Button color="brand" onClick={store.handleStartEdit}>
                    <FontAwesomeIcon icon="edit" fixedWidth className="icon" />
                    Edit
                  </Button>
                )}
              </div>
            )}
            <div className="clearfix" />
          </div>

          {!store.network.error && (
            <div>
              <ContentList
                store={contentListStore}
                tagStore={store}
                router={this.props.router}
              />
            </div>
          )}
          {store.network.error && (
            <div className="pt-error-message fade-in">
              <h2>Error</h2>
              <pre>
                <code>{JSON.stringify(store.network.error, null, 2)}</code>
              </pre>
            </div>
          )}
        </div>

        <div
          className={classNames("search-edit-actions", {
            hidden: store.isEditing === null,
            "bottom-appear": store.isEditing === true,
            "bottom-disappear": store.isEditing === false,
          })}
        >
          <Inline css={{ gap: "$2" }}>
            {
              <Button
                icon="plus"
                onClick={(e) => {
                  parentStore.savedSearchesListStore.setTagViewFn();
                  store.searchAddTagModal.showModal();
                }}
              >
                Add to Searches
              </Button>
            }
            {
              <Button
                icon="plus"
                onClick={(e) => {
                  regionListStore.setTagViewFn();
                  store.regionAddTagModal.showModal();
                }}
              >
                Add to Regions
              </Button>
            }
            {
              <Button
                icon="plus"
                onClick={(e) => {
                  jobStore.setTagViewFn();
                  store.jobAddTagModal.showModal();
                }}
              >
                Add to Jobs
              </Button>
            }
            {
              <Button
                icon="plus"
                onClick={(e) => {
                  rateCardListStore.setTagViewFn();
                  store.rateCardAddTagModal.showModal();
                }}
              >
                Add to Rate Card
              </Button>
            }
            {
              <Button
                icon="plus"
                onClick={(e) => {
                  projectCostEstimateStore.setTagViewFn();
                  store.pceAddTagModal.showModal();
                }}
              >
                Add to Project Cost Estimate
              </Button>
            }
            {
              <Button
                icon="plus"
                onClick={(e) => {
                  negotiationWorksheetListStore.setTagViewFn();
                  store.nwsAddTagModal.showModal();
                }}
              >
                Add to Worksheets
              </Button>
            }
            {contentListStore.isEditing && (
              <Button
                icon="trash-alt"
                color="danger"
                disabled={contentListStore.selectedCount === 0}
                onClick={contentListStore.confirmLocationDeleteModal.showModal}
              >
                Delete
              </Button>
            )}
          </Inline>
        </div>
        <AddSearchesModal tagDetailStore={store} parentStore={parentStore} />
        <AddRegionsModal
          store={parentStore}
          regionListStore={regionListStore}
          tagDetailStore={store}
        />
        <AddJobsModal
          store={parentStore}
          jobLabelListStore={jobStore}
          tagDetailStore={store}
        />
        <AddRateCardModal
          store={parentStore}
          rateCardStore={rateCardListStore}
          tagDetailStore={store}
        />
        <AddProjectCostModal
          store={parentStore}
          projectCostEstimateStore={projectCostEstimateStore}
          tagDetailStore={store}
        />
        <AddNWSModal
          store={parentStore}
          negotiationWorksheetListStore={negotiationWorksheetListStore}
          tagDetailStore={store}
        />
        <Modal
          show={contentListStore.confirmLocationDeleteModal.show}
          onHide={contentListStore.confirmLocationDeleteModal.hideModal}
        >
          <div className="container-section header">
            <h4>Confirmation Needed</h4>
          </div>
          <div className="container-section">
            <p>Are you sure you want to remove the selected components?</p>
            <Inline css={{ color: "$danger" }}>
              <FontAwesomeIcon icon="exclamation-triangle" />{" "}
              <em>This action cannot be undone!</em>
            </Inline>
          </div>
          <div className="container-section footer">
            <Inline css={{ justifyContent: "flex-end" }}>
              <Button
                size="large"
                onClick={contentListStore.confirmLocationDeleteModal.hideModal}
              >
                No, Cancel
              </Button>
              <Button
                size="large"
                color="danger"
                onClick={(e) => {
                  contentListStore.confirmLocationDeleteModal.hideModal();
                  contentListStore.deleteTagContent();
                }}
              >
                Yes, Remove Components
              </Button>
            </Inline>
          </div>
        </Modal>
      </div>
    );
  }
}

export default observer(Detail);
