// @flow strict

import * as React from "react";
import { observer } from "mobx-react";

type Props = {
  className?: string,
  children?: React.Node,
};

const ContainerSection = (props: Props) => (
  <div className={`container-section ${props.className || ""}`}>{props.children}</div>
);

export default observer(ContainerSection);
