import React, { useCallback } from "react";
import queryString from "query-string";

import Icon from "../../../../components/lib/Icon";
import RestfulDatasetListView, {
  DataSourceFunc,
  RenderItemFunc,
} from "./BaseRestfulDatasetListView";
import SelectItemRow from "../../lib/SelectItemRow";
import {
  djangoPaginationKey,
  djangoPaginationSizeKey,
  djangoSearchKey,
  emptyList,
} from "../../../../constants";
// @ts-expect-error
import { logAsyncOperationError } from "../../../../utils/logging";
import { transformCountriesData } from "../../dataConverters";

import type { UrlQueryObject } from "../../../../components/tables/types";
import type { FetchAPIResponse } from "../../../../types/fetch";
import type { DjangoPaginatedResponse } from "../../../../types/django";
import type { CountryDataObject, CountryDataMap, CountriesDataList } from "../../types";
import { useVal5KAdminContext } from "../../context/Val5KAdminContext";

type CountrySelectProps = {
  values: CountriesDataList;
  disabled?: boolean;
  searchPlaceholder?: string;
  noItemsFoundText?: string;
  onSelect: (item: CountryDataMap) => void;
};

const CountrySelect = (props: CountrySelectProps) => {
  const {
    values,
    searchPlaceholder,
    noItemsFoundText,
    disabled = false,
    onSelect,
  } = props;

  const { fetchCCCInternalAPI, showModalError } = useVal5KAdminContext();

  const dataSource: DataSourceFunc<CountriesDataList> = useCallback(
    async (search, page, pageSize) => {
      const queryArgs: UrlQueryObject = {};

      queryArgs["category"] = "COUNTRY";

      if (page) {
        queryArgs[djangoPaginationKey] = page;
      }
      if (pageSize) {
        queryArgs[djangoPaginationSizeKey] = pageSize;
      }
      if (search) {
        queryArgs[djangoSearchKey] = search;
      }

      try {
        const response: FetchAPIResponse<DjangoPaginatedResponse<CountryDataObject>> =
          await fetchCCCInternalAPI(`locations/`, {
            params: queryString.stringify(queryArgs),
          });
        return transformCountriesData(response.data);
      } catch (err: any) {
        logAsyncOperationError("CountrySelect", err);
        showModalError(`Error occurred while loading country list data.`);
      }
    },
    [showModalError, fetchCCCInternalAPI]
  );

  const renderItem: RenderItemFunc<CountryDataMap> = useCallback(
    (item) => {
      const title = item.get("title");
      const locationCode = item.get("location_code");

      const valueIds = values?.size ? values.map((i) => i.get("id")) : emptyList;
      const itemId = item?.size && item.get("id");
      const isSelected = !!(itemId != null && valueIds.size && valueIds.includes(itemId));

      return (
        <SelectItemRow
          selected={isSelected}
          disabled={disabled}
          onClick={() => !disabled && onSelect?.(item)}
        >
          <Icon icon="map-marker-alt" css={{ color: disabled ? "$primary" : "$brand" }} />
          {title} {locationCode && ` (${locationCode})`}
        </SelectItemRow>
      );
    },
    [values, disabled, onSelect]
  );

  return (
    <RestfulDatasetListView
      searchPlaceholder={searchPlaceholder}
      noItemsFoundText={noItemsFoundText}
      disabled={disabled}
      dataSource={dataSource}
      renderItem={renderItem}
    />
  );
};
CountrySelect.displayName = "CountrySelect";

export default CountrySelect;
