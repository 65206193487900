// @flow
import { extendObservable, action, runInAction, observable, computed } from "mobx";
import axios from "axios";
import NetworkState from "../../models/NetworkState";
import ModalState from "../../models/ModalState";
import TagList from "../../models/TagList";
import MessageState from "../../models/MessageState";
import CurrentUser from "../../models/User";
import { consolidateAppliedFilters } from "./SupportFunctions";
import type MobXStore from "./MobXStore";
import type { FetchGraphQL } from "../../App";

// type Owner = { userId: string, username: string };
class User {
  id: string;
  userId: string;
  username: string;
  firstName: string;
  lastName: string;
  selected: string;

  constructor(object: Object) {
    this.id = object.userId;
    this.userId = object.userId;
    this.username = object.username;
    this.firstName = object.firstName;
    this.lastName = object.lastName;

    extendObservable(this, {
      selected: object.selected,
    });
  }
}

class TagManagementDetailStore {
  store: MobXStore;
  router: Object;
  network: NetworkState;
  confirmDeleteModal: ModalState;
  negDetail: NegotiationWorksheet;
  renameTagModal: ModalState;
  onNewTagNameChange: (Event) => void;
  messaging: MessageState;
  showRenameTagModal: () => void;
  tagId: number;
  showHelpModal: ModalState;
  handleStartEdit: () => void;
  handleStopEdit: () => void;
  isEditing: ?boolean;
  updatedSearches: Array<any>;
  updatedRegions: Array<any>;
  onAddSearchesClick: () => void;
  applyTagToSearchList: () => void;
  searchAddTagModal: ModalState;
  regionAddTagModal: ModalState;
  jobAddTagModal: ModalState;
  onAddJobsClick: () => void;
  updatedJobs: Array<any>;
  rateCardAddTagModal: ModalState;
  onAddRateCardClick: () => void;
  updatedRateCards: Array<any>;
  pceAddTagModal: ModalState;
  updatedPCE: Array<any>;
  nwsAddTagModal: ModalState;
  updatedNWS: Array<any>;
  shareModal: ModalState;
  getShareUsers: () => void;
  share: () => void;
  networkShareUsers: NetworkState;
  shareUsers: User[];
  shareUsersViewState: Object;
  shareUsersInstantSearchValue: string;
  shareUsersTotalCount: number;
  shareUsersSelectedCount: number;
  shareUsersOnSelectAll: () => void;
  shareUsersOnDeselectAll: () => void;
  setSharedUserSelectedValue: () => void;
  shareUsersOnInstantSearch: () => void;
  performShare: () => void;
  currentUser: CurrentUser;
  tagOwnerShip: () => void;
  hasOwnership: boolean;
  fetchGraphQL: FetchGraphQL;

  constructor(fetchGraphQL: FetchGraphQL, store: MobXStore) {
    this.fetchGraphQL = fetchGraphQL;
    this.store = store;
    this.router = null;

    extendObservable(this, {
      tagId: null,
      search: null,
      confirmDeleteModal: new ModalState(),
      network: new NetworkState(),
      tagDetail: null,
      renameTagModal: new ModalState(),
      newTagName: "",
      messaging: new MessageState(),
      showHelpModal: new ModalState(),
      isEditing: false,
      updatedSearches: [],
      updatedRegions: [],
      updatedJobs: [],
      updatedRateCards: [],
      searchAddTagModal: new ModalState(),
      regionAddTagModal: new ModalState(),
      jobAddTagModal: new ModalState(),
      rateCardAddTagModal: new ModalState(),
      pceAddTagModal: new ModalState(),
      updatedPCE: [],
      updatedNWS: [],
      nwsAddTagModal: new ModalState(),
      shareModal: new ModalState(),
      networkShareUsers: new NetworkState(),
      shareUsers: observable.shallow([]),
      shareUsersViewState: observable.map({}),
      shareUsersInstantSearchValue: "",
      shareUsersView: [],
      shareUsersHasNoSelection: computed(() => {
        return this.shareUsersViewState.entries().length === 0;
      }),
      shareUsersTotalCount: computed(() => {
        if (!this.shareUsers) return 0;

        return this.shareUsers.length;
      }),
      shareUsersSelectedCount: computed(() => {
        if (!this.shareUsersViewState) return 0;

        const selected = this.shareUsersViewState
          .entries()
          .filter((entry) => entry[1] === true);
        return selected.length;
      }),
      hasOwnership: false,
    });
    this.currentUser = null;
    this.fetchTagDetail = action(this.fetchTagDetail.bind(this));
    this.deleteTag = action(this.deleteTag.bind(this));
    this.onNewTagNameChange = action(this.onNewTagNameChange.bind(this));
    this.showRenameTagModal = action(this.showRenameTagModal.bind(this));
    this.renameTag = action(this.renameTag.bind(this));
    this.handleStopEdit = action(this.handleStopEdit.bind(this));
    this.handleStartEdit = action(this.handleStartEdit.bind(this));
    this.onAddSearchesClick = action(this.onAddSearchesClick.bind(this));
    this.applyTagToSearchList = action(this.applyTagToSearchList.bind(this));
    this.onAddJobsClick = action(this.onAddJobsClick.bind(this));
    this.onAddRateCardClick = action(this.onAddRateCardClick.bind(this));
    this.onAddPCEClick = action(this.onAddPCEClick.bind(this));
    this.onAddNWSClick = action(this.onAddNWSClick.bind(this));
    this.getShareUsers = action(this.getShareUsers.bind(this));
    this.share = action(this.share.bind(this));
    this.shareUsersOnSelectAll = action(this.shareUsersOnSelectAll.bind(this));
    this.shareUsersOnDeselectAll = action(this.shareUsersOnDeselectAll.bind(this));
    this.setSharedUserSelectedValue = action(this.setSharedUserSelectedValue.bind(this));
    this.shareUsersOnInstantSearch = action(this.shareUsersOnInstantSearch.bind(this));
    this.performShare = action(this.performShare.bind(this));
    this.tagOwnerShip = action(this.tagOwnerShip.bind(this));
  }

  onNewTagNameChange(e: Event) {
    this.newTagName = e.target.value;
  }

  showRenameTagModal() {
    this.messaging.removeAll();
    this.newTagName = this.tagDetail.name;
    this.renameTagModal.showModal();
  }

  handleStartEdit() {
    this.isEditing = true;
    this.store.contentListStore.handleStartEdit();
  }

  handleStopEdit() {
    this.isEditing = false;
    this.store.contentListStore.handleStopEdit();
  }

  share() {
    this.getShareUsers();
    this.shareUsersInstantSearchValue = "";
    this.shareModal.showModal();
  }

  shareUsersOnInstantSearch(value: string) {
    this.shareUsersInstantSearchValue = value;

    if (!this.shareUsersInstantSearchValue) {
      this.shareUsersView = this.shareUsers;
      return;
    }

    this.shareUsersView = this.shareUsers.filter((user) => {
      const firstName = user.firstName.toLowerCase();
      const lastName = user.lastName.toLowerCase();
      const username = user.username.toLowerCase();
      const query = this.shareUsersInstantSearchValue.toLowerCase();

      // this works because ~ is the binary inverse of a number and ~ -1 = 0
      return (
        ~firstName.indexOf(query) || ~lastName.indexOf(query) || ~username.indexOf(query)
      );
    });
  }

  shareUsersOnSelectAll(e: Object) {
    this.shareUsersView.forEach((user) => {
      this.shareUsersViewState.set(user.userId, true);
      user.selected = true;
    });
  }

  shareUsersOnDeselectAll(e: Object) {
    this.shareUsersView.forEach((user) => {
      this.shareUsersViewState.set(user.userId, false);
      user.selected = false;
    });
  }

  setSharedUserSelectedValue(user: Object) {
    const oldSelectedValue = this.shareUsersViewState.get(user.userId);

    if (oldSelectedValue) {
      this.shareUsersViewState.set(user.userId, !oldSelectedValue);
      user.selected = !oldSelectedValue;
    } else {
      this.shareUsersViewState.set(user.userId, true);
      user.selected = true;
    }
  }

  tagOwnerShip() {
    if (this.tagDetail.owner && this.tagDetail.owner.clientId) {
      this.hasOwnership =
        String(this.tagDetail.owner.clientId) === String(this.currentUser.userId);
    } else {
      this.hasOwnership = true;
    }
  }

  onAddSearchesClick(searchesView) {
    if (searchesView.length > 0) {
      searchesView.forEach((search) => {
        if (search.viewState.selected) {
          this.updatedSearches.push(String(search.searchId));
        }
      });
    }
    this.applyTagToSearchList();
  }

  async applyTagToSearchList() {
    //let searchquery = '';
    //et parameters = {};

    let params = [];
    let filterargs = [];
    let searchargs = [];
    let args = [];
    let vars = {};

    const taglist = [this.tagId];
    if (!taglist || !taglist.length) {
      console.error("Cannot Apply tags to RateCards: No RateCards selected");
      return;
    }
    params.push("$tagIds: [Int]!");
    filterargs.push("tagIds: $tagIds");
    vars.tagIds = taglist;

    if (this.store.savedSearchesListStore.allSelected) {
      params.push("$exclude: [ID]");
      args.push("exclude: $exclude");
      vars.exclude = [];
      consolidateAppliedFilters(
        this.store.savedSearchesListStore.appliedFilters,
        params,
        args,
        vars
      );
    } else {
      const itemList = this.updatedSearches;
      if (!itemList || !itemList.length) {
        console.error("Cannot apply tag  to Search: No Search selected");
        return;
      }
      params.push("$only: [ID]");
      args.push("only: $only");
      vars.only = itemList;
    }

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
      mutation applyTags(${queryParams}){
       applyTagsToSearches(input:{${filterargs}, filters: { ${queryArgs}}, ${searchargs}}) {
         ok
          errors {
           __typename
           ... on TagIdRequiredError {
             message
           }
           ... on TagIdsNotExistsError {
             message
           }
           ... on InvalidInputError {
             message
           }
           ... on ContentDoesNotExistsError {
             message
           }
         }
       }

      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Apply Tags to Selected Searches", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    this.searchAddTagModal.hideModal();
    this.handleStopEdit();
    this.store.savedSearchesListStore.clearFilters();
    this.store.savedSearchesListStore.clearAllSelections();

    runInAction("applyTags--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("applyTags", res)) {
        // TODO: Display user friendly error message
        return;
      }
      this.updatedSearches = [];
      this.store.contentListStore.pagination.goFetch();
    });
  }

  onAddRegionsClick(regionsView) {
    if (regionsView.length > 0) {
      regionsView.forEach((region) => {
        if (region.viewState.selected) {
          this.updatedRegions.push(String(region.regionId));
        }
      });
    }
    this.applyTagToRegions();
  }

  async applyTagToRegions() {
    // let searchquery = '';
    // let parameters = {};

    let params = [];
    let filterargs = [];
    let searchargs = [];
    let args = [];
    let vars = {};

    const taglist = [this.tagId];
    if (!taglist || !taglist.length) {
      console.error("Cannot Apply tags to Regions: No Region selected");
      return;
    }
    params.push("$tagIds: [Int]!");
    filterargs.push("tagIds: $tagIds");
    vars.tagIds = taglist;

    if (this.store.regionListStore.allSelected) {
      params.push("$exclude: [ID]");
      args.push("exclude: $exclude");
      vars.exclude = [];
      consolidateAppliedFilters(
        this.store.regionListStore.appliedFilters,
        params,
        args,
        vars
      );
    } else {
      const itemList = this.updatedRegions;
      if (!itemList || !itemList.length) {
        console.error("Cannot apply tag  to Region: No Region selected");
        return;
      }
      params.push("$only: [ID]");
      args.push("only: $only");
      vars.only = itemList;
    }

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
      mutation applyTags(${queryParams}){
       applyTagsToRegions(input:{${filterargs}, filters: { ${queryArgs}}, ${searchargs}}) {
         ok
          errors {
           __typename
           ... on TagIdRequiredError {
             message
           }
           ... on TagIdsNotExistsError {
             message
           }
           ... on InvalidInputError {
             message
           }
           ... on ContentDoesNotExistsError {
             message
           }
         }
       }

      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Apply Tags to Selected Regions", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    this.regionAddTagModal.hideModal();
    this.handleStopEdit();
    this.store.regionListStore.clearFilters();
    this.store.regionListStore.clearAllSelections();

    runInAction("applyTags--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("applyTags", res)) {
        // TODO: Display user friendly error message
        return;
      }
      this.updatedRegions = [];
      this.store.contentListStore.pagination.goFetch();
    });
  }

  onAddJobsClick(jobsView) {
    if (jobsView.length > 0) {
      jobsView.forEach((job) => {
        if (job.viewState.selected) {
          this.updatedJobs.push(String(job.jobId));
        }
      });
    }
    this.applyTagToJobs();
  }

  async applyTagToJobs() {
    // let searchquery = '';
    // let parameters = {};

    let params = [];
    let filterargs = [];
    let searchargs = [];
    let args = [];
    let vars = {};

    const taglist = [this.tagId];
    if (!taglist || !taglist.length) {
      console.error("Cannot Apply tags to Jobs: No Jobs selected");
      return;
    }
    params.push("$tagIds: [Int]!");
    filterargs.push("tagIds: $tagIds");
    vars.tagIds = taglist;

    if (this.store.jobLabelListStore.allSelected) {
      params.push("$exclude: [ID]");
      args.push("exclude: $exclude");
      vars.exclude = [];
      consolidateAppliedFilters(
        this.store.jobLabelListStore.appliedFilters,
        params,
        args,
        vars
      );
    } else {
      const itemList = this.updatedJobs;
      if (!itemList || !itemList.length) {
        console.error("Cannot apply tag to Jobs: No Job selected");
        return;
      }
      params.push("$only: [ID]");
      args.push("only: $only");
      vars.only = itemList;
    }

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
      mutation applyTags(${queryParams}){
       applyTagsToJobs(input:{${filterargs}, filters: { ${queryArgs}}, ${searchargs}}) {
         ok
          errors {
           __typename
           ... on TagIdRequiredError {
             message
           }
           ... on TagIdsNotExistsError {
             message
           }
           ... on InvalidInputError {
             message
           }
           ... on ContentDoesNotExistsError {
             message
           }
         }
       }

      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Apply Tags to Selected Jobs", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    this.jobAddTagModal.hideModal();
    this.handleStopEdit();
    this.store.jobLabelListStore.clearFilters();
    this.store.jobLabelListStore.clearAllSelections();

    runInAction("applyTags--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("applyTags", res)) {
        // TODO: Display user friendly error message
        return;
      }
      this.updatedJobs = [];
      this.store.contentListStore.pagination.goFetch();
    });
  }

  onAddRateCardClick(rateCardView) {
    if (rateCardView.length > 0) {
      rateCardView.forEach((rate) => {
        if (rate.viewState.selected) {
          this.updatedRateCards.push(String(rate.ratecardId));
        }
      });
    }
    this.applyTagToRateCards();
  }

  async applyTagToRateCards() {
    // let searchquery = '';
    // let parameters = {};

    let params = [];
    let filterargs = [];
    let searchargs = [];
    let args = [];
    let vars = {};

    const taglist = [this.tagId];
    if (!taglist || !taglist.length) {
      console.error("Cannot Apply tags to Ratecards: No Ratecards selected");
      return;
    }
    params.push("$tagIds: [Int]!");
    filterargs.push("tagIds: $tagIds");
    vars.tagIds = taglist;

    if (this.store.rateCardListStore.allSelected) {
      params.push("$exclude: [ID]");
      args.push("exclude: $exclude");
      vars.exclude = [];
      consolidateAppliedFilters(
        this.store.rateCardListStore.appliedFilters,
        params,
        args,
        vars
      );
    } else {
      const itemList = this.updatedRateCards;
      if (!itemList || !itemList.length) {
        console.error("Cannot apply tag to Ratecards: No Ratecard selected");
        return;
      }
      params.push("$only: [ID]");
      args.push("only: $only");
      vars.only = itemList;
    }

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
      mutation applyTags(${queryParams}){
       applyTagsToRatecards(input:{${filterargs}, filters: { ${queryArgs}}, ${searchargs}}) {
         ok
          errors {
           __typename
           ... on TagIdRequiredError {
             message
           }
           ... on TagIdsNotExistsError {
             message
           }
           ... on InvalidInputError {
             message
           }
           ... on ContentDoesNotExistsError {
             message
           }
         }
       }

      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Apply Tags to Selected Ratecards", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    this.rateCardAddTagModal.hideModal();
    this.handleStopEdit();
    this.store.rateCardListStore.clearFilters();
    this.store.rateCardListStore.clearAllSelections();

    runInAction("applyTags--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("applyTags", res)) {
        // TODO: Display user friendly error message
        return;
      }
      this.updatedRateCards = [];
      this.store.contentListStore.pagination.goFetch();
    });
  }

  onAddPCEClick(projectCostsView) {
    if (projectCostsView.length > 0) {
      projectCostsView.forEach((pce) => {
        if (pce.viewState.selected) {
          this.updatedPCE.push(String(pce.projectCostId));
        }
      });
    }
    this.applyTagToPCE();
  }

  async applyTagToPCE() {
    // let searchquery = '';
    // let parameters = {};

    let params = [];
    let filterargs = [];
    let searchargs = [];
    let args = [];
    let vars = {};

    const taglist = [this.tagId];
    if (!taglist || !taglist.length) {
      console.error("Cannot Apply tags to Projects: No Projects selected");
      return;
    }
    params.push("$tagIds: [Int]!");
    filterargs.push("tagIds: $tagIds");
    vars.tagIds = taglist;

    if (this.store.projectCostEstimateStore.allSelected) {
      params.push("$exclude: [ID]");
      args.push("exclude: $exclude");
      vars.exclude = [];
      consolidateAppliedFilters(
        this.store.projectCostEstimateStore.appliedFilters,
        params,
        args,
        vars
      );
    } else {
      const itemList = this.updatedPCE;
      if (!itemList || !itemList.length) {
        console.error("Cannot apply tag to Projects: No Project selected");
        return;
      }
      params.push("$only: [ID]");
      args.push("only: $only");
      vars.only = itemList;
    }

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
      mutation applyTags(${queryParams}){
       applyTagsToProjects(input:{${filterargs}, filters: { ${queryArgs}}, ${searchargs}}) {
         ok
          errors {
           __typename
           ... on TagIdRequiredError {
             message
           }
           ... on TagIdsNotExistsError {
             message
           }
           ... on InvalidInputError {
             message
           }
           ... on ContentDoesNotExistsError {
             message
           }
         }
       }

      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Apply Tags to Selected Projects", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    this.pceAddTagModal.hideModal();
    this.handleStopEdit();
    this.store.projectCostEstimateStore.clearFilters();
    this.store.projectCostEstimateStore.clearAllSelections();

    runInAction("applyTags--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("applyTags", res)) {
        // TODO: Display user friendly error message
        return;
      }
      this.updatedPCE = [];
      this.store.contentListStore.pagination.goFetch();
    });
  }

  onAddNWSClick(negotiationListView) {
    if (negotiationListView.length > 0) {
      negotiationListView.forEach((nws) => {
        if (nws.viewState.selected) {
          this.updatedNWS.push(String(nws.worksheetId));
        }
      });
    }
    this.applyTagToNWS();
  }

  async applyTagToNWS() {
    // let searchquery = '';
    // let parameters = {};

    let params = [];
    let filterargs = [];
    let searchargs = [];
    let args = [];
    let vars = {};

    const taglist = [this.tagId];
    if (!taglist || !taglist.length) {
      console.error("Cannot Apply tags to Worksheets: No Worksheets selected");
      return;
    }
    params.push("$tagIds: [Int]!");
    filterargs.push("tagIds: $tagIds");
    vars.tagIds = taglist;

    if (this.store.negotiationWorksheetListStore.allSelected) {
      params.push("$exclude: [ID]");
      args.push("exclude: $exclude");
      vars.exclude = [];
      consolidateAppliedFilters(
        this.store.negotiationWorksheetListStore.appliedFilters,
        params,
        args,
        vars
      );
    } else {
      const itemList = this.updatedNWS;
      if (!itemList || !itemList.length) {
        console.error("Cannot apply tag to Worksheets: No Worksheet selected");
        return;
      }
      params.push("$only: [ID]");
      args.push("only: $only");
      vars.only = itemList;
    }

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
      mutation applyTags(${queryParams}){
       applyTagsToNegotiationWorksheets(input:{${filterargs}, filters: { ${queryArgs}}, ${searchargs}}) {
         ok
          errors {
           __typename
           ... on TagIdRequiredError {
             message
           }
           ... on TagIdsNotExistsError {
             message
           }
           ... on InvalidInputError {
             message
           }
           ... on ContentDoesNotExistsError {
             message
           }
         }
       }

      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Apply Tags to Selected Worksheets", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    this.nwsAddTagModal.hideModal();
    this.handleStopEdit();
    this.store.negotiationWorksheetListStore.clearFilters();
    this.store.negotiationWorksheetListStore.clearAllSelections();

    runInAction("applyTags--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("applyTags", res)) {
        // TODO: Display user friendly error message
        return;
      }
      this.updatedNWS = [];
      this.store.contentListStore.pagination.goFetch();
    });
  }

  async fetchTagDetail() {
    let res = null;
    if (!/^\d+$/.test(this.tagId)) {
      if (this.router) {
        this.router.push({
          pathname: "/404NotFound",
          query: this.router.query,
        });
      }
      return res;
    }
    let params: string[] = [];
    let args = [];
    let variables = {};

    let tagId = this.tagId;

    params.push("$id: Int!");
    args.push("id: $id");
    variables.id = tagId;

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
      query tagDetail(${queryParams}) {
        viewer {
          user{
              firstName
              lastName
              userId
              username
              email
            }
          tag(${queryArgs}) {
            name
            created
            tagId
            owner{
              firstName
              lastName
              email
              userId
            }
            sharedWith{
              firstName
              lastName
              email
              userId
            }
          }
         }
        }
        `;

    this.network.error = null;
    this.network.loading = true;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting Tag Detail", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Tag Detail query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    runInAction("fetchTag--success", () => {
      this.network.loading = false;
      this.network.error = null;
      //const node = res.data.viewer.tag;
      if (this.network.logGraphQLError("Get Search query", res)) {
        // TODO: Display user friendly error message
        return;
      }

      if (!res.data.viewer.tag) {
        if (this.router) {
          this.router.push({
            pathname: "/404NotFound",
            query: this.router.query,
          });
        }
        return;
      }
      this.currentUser = new CurrentUser(this, res.data.viewer.user);
      this.tagDetail = new TagList(this, res.data.viewer.tag);
      this.tagOwnerShip();
    });
  }

  async deleteTag() {
    // let searchquery = '';
    let parameters = {};

    parameters.filters = {};
    parameters.only = [this.tagId];

    const query = `
      mutation deleteTag($input : DeleteTagsInput!){
       deleteTags(input: $input){
         ok
       }
      }
    `;

    const variables = {
      input: parameters,
    };

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Deleting selected tag", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    runInAction("deleteTag--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("deleteTag", res)) {
        // TODO: Display user friendly error message
        return;
      }

      if (this.router) {
        this.router.push({
          pathname: "/tag",
          query: this.router.query,
        });
      }
    });
  }

  //rename Worksheet
  async renameTag() {
    if (this.network.loading) {
      return;
    }

    this.messaging.removeAll();

    if (!this.newTagName.trim()) {
      this.messaging.createMessage("info", "Please enter a New Tag Name.");
      return;
    }

    const query = `
      mutation renameTag($tagId: Int!, $name: String!){
        updateTag(input: {name: $name, tagId: $tagId}){
          tag{
            tagId,
            name
          }
            errors {
            __typename
            ... on TagNameAlreadyExistError {
              message
            }
            ... on TagNameEmptyError {
              message
            }
            ... on TagDoesNotExistsError {
              message
            }
         }
        }
      }
    `;
    const variables = {
      tagId: this.tagId,
      name: this.newTagName,
    };

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Getting worksheet detail", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    // this.handleStopEdit();

    runInAction("renameTag--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Filter criteria query", res)) {
        // TODO: Display user friendly error message
        return;
      }
      if (res.data.updateTag.errors) {
        this.messaging.removeAll();
        this.messaging.createMessage("error", res.data.updateTag.errors[0].message);
        return;
      }
      this.tagDetail.name = res.data.updateTag.tag.name;
      this.renameTagModal.hideModal();
    });
  }

  async getShareUsers() {
    let excludeCurrentUser = [this.currentUser.userId];

    const query = `

    query authorList {
      viewer {
        authors(order:[{field: FIRST_NAME}, {field: LAST_NAME}], filters:{exclude:${excludeCurrentUser}}) {
          edges {
            node {
              userId
              firstName
              lastName
              username
              email
            }
          }
        }
      }
    }
    `;

    const variables = {
      tagId: this.tagId,
    };

    this.networkShareUsers.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.networkShareUsers.handleError("Getting Users for sharing", e);
      // TODO: Display user friendly error message
      return;
    }

    runInAction("getShareUsers--success", () => {
      this.networkShareUsers.loading = false;
      this.networkShareUsers.error = null;
      if (this.networkShareUsers.logGraphQLError("Filter criteria query", res)) {
        // TODO: Display user friendly error message
        // 'There was an error retrieving the data for this page. Please reload and try again later.',
        return;
      }

      // $FlowFixMe: Type the res value once we abstract gql calls.
      const users = res.data.viewer.authors.edges;
      const currentlySharedUsers = this.tagDetail.sharedWith;

      this.networkShareUsers.loading = false;
      this.networkShareUsers.error = null;

      // set as selected the users that are currently shared with
      this.shareUsersViewState = observable.map({});
      currentlySharedUsers.forEach((edge) => {
        this.shareUsersViewState.set(edge.userId, true);
      });

      // TODO: Deserialize this properly...
      this.shareUsers = users.map((edge) => {
        const user = new User(edge.node);

        if (!user.firstName) user.firstName = "";
        if (!user.lastName) user.lastName = "";

        if (this.shareUsersViewState.has(user.userId)) {
          user.selected = this.shareUsersViewState.get(user.userId);
        } else {
          user.selected = false;
        }

        return user;
      });

      this.shareUsersView = this.shareUsers;
    });
  }

  async performShare() {
    if (this.network.loading) {
      return;
    }
    const selectedUserIds = this.shareUsersViewState
      .entries()
      .filter((entry) => entry[1] === true)
      .map((entry) => parseInt(entry[0], 10));

    const query = `
      mutation shareTags($userIds: [Int]!, $tagId: Int!) {
        shareTag(input: { tagId:$tagId, userIds: $userIds }) {
           shareTags {
             user{
               firstName,
               lastName,
               username
             },
            tag{
              name
            }
           }
         }
      }
    `;

    const variables = {
      userIds: selectedUserIds,
      tagId: this.tagId,
    };

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Sharing Rate Card", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    this.shareModal.hideModal();
    this.handleStopEdit();

    runInAction("performShare--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("shareRateCard", res)) {
        // TODO: Display user friendly error message
        return;
      }
      this.fetchTagDetail();
      //this.pagination.goFetch(null);
    });
  }
}

export default TagManagementDetailStore;
