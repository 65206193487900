// @flow
import * as React from "react";
import { CancelToken } from "axios";
import { RelayEnvironmentProvider } from "react-relay";
import { Environment } from "relay-runtime";
import { fetchGraphQL, fetchAPI, fetchAPINew, fetchGraphQLNew } from "./utils/fetch";
import type { FetchAPIOptionsType } from "./utils/fetch";
// $FlowFixMe: Every route and etc. must be strict first
import Routes from "./Routes.jsx";
// $FlowFixMe: Need to make every store strict
import MobXStore from "./stores/mobx/MobXStore";
import SessionInfo from "./models/SessionInfo";
import TickerLoader from "./components/lib/TickerLoader";
import Center from "./components/lib/Center";
import Container from "./components/lib/Container";
import { createRelayEnvironment } from "./relayEnvironment";
import Button from "./components/lib/Button";
import { supportEmail } from "./constants";

type Props = {
  reactrURL: string,
  services: Object,
};

type State = {
  loggedIn: boolean | null,
  sessionInfo: SessionInfo | null,
  errorMessage: string | null,
};

// $FlowFixMe
export type FetchGraphQLVariables = ?{
  +[string]: string | number | boolean | Object | Array<any> | null,
};
export type FetchGraphQL = (
  query: string,
  variables: FetchGraphQLVariables,
  cancelToken?: CancelToken | null
  // $FlowFixMe
) => Promise<any>;
export type FetchAPI = (
  path: string,
  // $FlowFixMe
  json: ?Object,
  cancelToken?: CancelToken | null,
  method?: "POST" | "GET"
  // $FlowFixMe
) => Promise<any>;
export type UpdateLoginState = (boolean, string | null, SessionInfo | null) => void;
export type Logout = () => void;

// TODO: wrapper function to handle HTTP errors
// TODO: wrapper function to handle GraphQL errors
// TODO: wrapper function to handle GraphQL App Mutation errors

class App extends React.Component<Props, State> {
  relayEnvironment: Environment;
  store: MobXStore;
  fetchAPI: FetchAPI;
  fetchGraphQL: FetchGraphQL;
  fetchAPINew: FetchAPI;
  fetchGraphQLNew: FetchGraphQL;
  checkUserLogin: () => void;
  updateLoginState: UpdateLoginState;
  logout: Logout;

  constructor(props: Props) {
    super(props);

    this.relayEnvironment = createRelayEnvironment(this.fetchGraphQLNew);
    this.store = new MobXStore(this.relayEnvironment, this.fetchGraphQL, this.fetchAPI);
    this.state = {
      loggedIn: null,
      errorMessage: null,
      sessionInfo: null,
    };

    this.checkUserLogin();
  }

  // $FlowFixMe: Must be allowed to accept Object
  fetchAPI = (
    path: string,
    json: ?Object,
    cancelToken: CancelToken | null = null,
    method: "POST" | "GET" = "POST"
  ) => {
    return fetchAPI(`/api/${path}`, json, cancelToken, method).then((res) => {
      if (res.status === 403) {
        console.warn("fetchAPI", "User is not allowed to access this.");
      }
      if (res.status === 401) {
        console.warn("fetchAPI", "User is not authorized or needs to login.");
        this.setState({ loggedIn: false });
      }
      return Promise.resolve(res);
    });
  };

  fetchGraphQL = (
    query: string,
    variables: FetchGraphQLVariables,
    cancelToken: CancelToken | null = null
  ) => {
    return fetchGraphQL("/api/graphql/", query, variables, cancelToken)
      .then((res) => {
        // TODO: Make error type consistent.
        // Currently it's hard to write .catch() because it could be an error or anything else really.
        const json = res.data;
        if (!json) {
          console.error("No data", res);
          return Promise.reject(
            new Error("There was a problem parsing the data from the server.")
          );
        }
        if (json.errors) {
          console.error("GraphQL Error", json.errors);
          return Promise.reject(json.errors);
        }
        return Promise.resolve(json);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          console.warn("fetchGraphQL", "User is not allowed to access this.");
          // TODO: Handle nullable sessionInfo and sessionInfo.user
          let oldUserId =
            this.state?.sessionInfo && this.state?.sessionInfo?.user?.userId;
          if (
            error.response?.data?.errors &&
            error.response?.data?.errors[0]?.user_id &&
            error.response?.data?.errors[0]?.user_id !== oldUserId
          ) {
            this.setState({
              errorMessage: `You are logged-in as user #${error.response.data.errors[0].user_id} which does not have access to perform this action.`,
            });
          }
          throw error;
        }

        if (error.response && error.response.status === 401) {
          console.warn("fetchGraphQL", "User is not authorized or needs to login.");
          this.logout();

          throw error;
        }

        throw error;
      });
  };

  // $FlowFixMe: Must be allowed to accept Object
  fetchAPINew = async (path: string, options?: FetchAPIOptionsType) => {
    try {
      return await fetchAPINew(`/${path}`, options);
    } catch (error) {
      if (error?.response?.status === 403) {
        console.warn("fetchAPI", "User is not allowed to access this.");
      }
      if (error?.response?.status === 401) {
        console.warn("fetchAPI", "User is not authorized or needs to login.");
        this.logout();
      }
      throw error;
    }
  };

  fetchGraphQLNew = async (
    query: string,
    variables: FetchGraphQLVariables,
    options?: FetchAPIOptionsType
  ) => {
    let response;

    try {
      response = await fetchGraphQLNew("/api/graphql/", query, variables, options);
    } catch (error) {
      if (error?.response?.status === 403) {
        console.warn("fetchGraphQL", "User is not allowed to access this.");

        const oldUserId = this.state.sessionInfo?.user?.userId;
        const responseJSON = await error.response.json();
        const responseUseId = responseJSON?.errors?.[0]?.user_id;

        if (responseUseId !== oldUserId) {
          this.setState({
            errorMessage: `You are logged-in as user #${responseJSON.errors[0].user_id} which does not have access to perform this action.`,
          });
        }
      }
      if (error?.response?.status === 401) {
        console.warn("fetchGraphQL", "User is not authorized or needs to login.");
        this.logout();
      }
      throw error;
    }

    if (!response?.data) {
      console.error("fetchGraphQL", "No data returned.", response);
      throw new Error("There was a problem parsing the data from the server.");
    }

    return response;
  };

  async checkUserLogin() {
    try {
      const res = await fetchGraphQL(
        "/api/graphql/",
        `query checkUserLogin {
          viewer {
            user {
              userId
              username
              firstName
              lastName
              email
              termsOfAgreement
              roles
              legacySession
              client {
                id
                legacyId
                title
                legacyClient {
                  perSearchPricing
                  isClientJobLibrary
                  jobLibrarySubscriptionFlag
                  ratecardSubscriptionFlag
                  pspStoreFlag
                }
              }
            }
          }
        }`,
        null
      );

      const data = res.data["data"];
      // If anything is null/error then consider not logged in.
      // TODO: Handle edge case of server being down or overloaded and keep login to null
      if (data && data.viewer && data.viewer.user && data.viewer.user.userId) {
        if (window.pendo) {
          window.pendo.initialize({
            visitor: {
              id: data.viewer.user.userId,
              email: data.viewer.user.email,
              firstName: data.viewer.user.firstName,
              lastName: data.viewer.user.lastName,
              roles: data.viewer.user.roles,
            },
            account: {
              id: data.viewer.user.client.id,
              name: data.viewer.user.client.title,
              perSearchPricing: data.viewer.user.client.legacyClient.perSearchPricing,
              isClientJobLibrary: data.viewer.user.client.legacyClient.isClientJobLibrary,
            },
          });
        }

        this.updateLoginState(true, null, new SessionInfo(data.viewer));
      } else {
        this.logout();
      }
    } catch (error) {
      console.error("checkUserLogin ", error);

      if (error?.response?.status === 401) this.logout();
      else if (error?.response?.status === 403)
        this.setState({
          errorMessage: error.response.data.errors[0].message,
        });
      else if (error.message === "Network Error")
        this.setState({
          errorMessage: "We're unable to reach the server.",
        });
      else
        this.setState({
          errorMessage: "We're sorry, something went wrong.",
        });
    }
  }

  updateLoginState = (
    loggedIn: boolean,
    loginErrorMessage: string | null,
    sessionInfo: SessionInfo | null
  ) => {
    let oldUserId = this.state.sessionInfo?.user?.userId ?? null;

    this.setState(
      {
        loggedIn,
        errorMessage: loginErrorMessage,
        sessionInfo,
      },
      () => {
        const newSessionInfo = this.state.sessionInfo;

        // update MobX store
        this.store.legacySessionId = newSessionInfo?.legacySession ?? null;

        // re-create MobX store if user has changed
        if (newSessionInfo?.user?.userId !== oldUserId) {
          this.store = new MobXStore(
            this.relayEnvironment,
            this.fetchGraphQL,
            this.fetchAPI
          );
        }

        // logout: clean up localStorage, relay, MobX store
        if (loggedIn === false && loginErrorMessage === null && newSessionInfo === null) {
          try {
            // Note: Invalidate the store state upon logout, should be done in logout mutation eventually with useMutation
            this.relayEnvironment.commitUpdate((storeProxy) => {
              storeProxy.invalidateStore();
            });
          } catch (error) {
            console.error("Error invalidating Relay store");
          }

          localStorage.clear();
          this.store = new MobXStore(
            this.relayEnvironment,
            this.fetchGraphQL,
            this.fetchAPI
          );
        }
      }
    );
  };

  logout = () => {
    this.updateLoginState(false, null, null);
  };

  render() {
    if (this.state.errorMessage !== null) {
      return (
        <div>
          <Container
            css={{
              paddingLeft: "$4",
              paddingRight: "$4",
              maxWidth: "1506px",
            }}
          >
            <h1>
              {this.state.errorMessage
                ? this.state.errorMessage
                : "We're sorry, but we're having trouble talking to our servers."}
            </h1>
            <p>
              Please check your internet connection and click the button below to retry.
              If that does not work please try to refresh the page.
            </p>
            <p>
              If you cannot fix the problem do not hesitate to contact us at{" "}
              <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
            </p>
            <Button size="large" onClick={this.checkUserLogin}>
              Click here to Retry
            </Button>
          </Container>
        </div>
      );
    }

    if (this.state.loggedIn === null || this.relayEnvironment == null) {
      return (
        <Center
          css={{ position: "fixed", top: 0, bottom: 0, left: 0, right: 0, zIndex: "$10" }}
        >
          <TickerLoader />
        </Center>
      );
    }

    return (
      <RelayEnvironmentProvider environment={this.relayEnvironment}>
        <Routes
          loggedIn={this.state.loggedIn}
          store={this.store}
          fetchAPI={this.fetchAPI}
          fetchGraphQL={this.fetchGraphQL}
          fetchAPINew={this.fetchAPINew}
          fetchGraphQLNew={this.fetchGraphQLNew}
          sessionInfo={this.state.sessionInfo}
          updateLoginState={this.updateLoginState}
          reactrURL={this.props.reactrURL}
          services={this.props.services}
        />
      </RelayEnvironmentProvider>
    );
  }
}

export default App;
