// @flow
import React, { Component } from "react";
import { observer } from "mobx-react";
import type MobXStore from "../../stores/mobx/MobXStore";
import SessionInfo from "../../models/SessionInfo";
import JLCreate from "./job_library/Create.jsx";
import PSPCreate from "./per_search_pricing/Create.jsx";

type Props = {
  store: MobXStore,
  sessionInfo: SessionInfo,
  router: Object,
};

class Create extends Component<Props> {
  render() {
    const props = this.props;
    const sessionInfo = props.sessionInfo;
    const client = sessionInfo.client;
    const isJobLibraryClient = client && client.isClientJobLibrary;

    if (isJobLibraryClient) {
      return (
        <JLCreate
          store={props.store}
          sessionInfo={props.sessionInfo}
          router={props.router}
        />
      );
    } else {
      return (
        <PSPCreate
          store={props.store}
          sessionInfo={props.sessionInfo}
          router={props.router}
        />
      );
    }
  }
}

export default observer(Create);
