// @flow

import * as React from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SelectableItem from "../ratecards/components/SelectableItem";
import SelectableItemsList from "../ratecards/components/SelectableItemsList";
import ContainerSection from "../ratecards/components/ContainerSection";
import SearchBox from "../ratecards/components/SearchBox";
import InstantSearchBox from "../ratecards/components/InstantSearchBox";
import RateCardsList from "./components/TagManagementList";
import Loadable from "../ratecards/components/Loadable";
import Modal from "../ratecards/components/Modal";
import classNames from "classnames";
import ExportModal from "../ratecards/components/ExportModal";
import type MobXStore from "../../stores/mobx/MobXStore";
import Button from "../../components/lib/Button";
import LinkButton from "../../components/lib/LinkButton";
import Inline from "../../components/lib/Inline";

const ShareWithUsersList = observer((props: {}) => {
  return (
    <div>
      <SelectableItemsList>
        {props.filterState.shareUsersView.map((user, i) => (
          <SelectableItem
            key={user.username}
            item={user}
            name="share-user"
            value={user.username}
            selected={user.selected}
            onChange={props.filterState.setSharedUserSelectedValue}
          >
            <div>
              {user.firstName} {user.lastName}
            </div>
            <div className="text-x-small">
              <em>
                <FontAwesomeIcon icon="user" className="icon no-margin" />
                {user.username}
              </em>
            </div>
          </SelectableItem>
        ))}
      </SelectableItemsList>
      <ContainerSection className="info">
        <p className="text-muted text-x-small no-margin pull-left">
          <FontAwesomeIcon icon="info-circle" fixedWidth className="icon" />
          <em> Stop sharing by leaving all Users unselected.</em>
        </p>
        <p className="text-muted text-x-small no-margin no-border pull-right">
          <em>
            <strong>{props.filterState.shareUsersSelectedCount} </strong>selected,{" "}
            <strong>{props.filterState.shareUsersTotalCount} </strong>total
          </em>
        </p>
        <div className="clearfix" />
      </ContainerSection>
    </div>
  );
});

type Props = {
  store: MobXStore,
};

class List extends React.Component<Props> {
  componentDidMount() {
    window.scrollTo(0, 0);
    //this.props.store.tagManagementListStore.updateRequestFrom('ratecard');
    this.props.store.tagManagementListStore.pagination.goFetch();

    // reset to null so some elements visible while editing start hidden
    this.props.store.tagManagementListStore.isEditing = null;
  }

  componentWillUnmount() {
    this.props.store.tagManagementListStore.clearFilters();
    this.props.store.tagManagementListStore.clearAllSelections();
    this.props.store.tagManagementListStore.applyDefaultSort();
  }

  render() {
    const store = this.props.store.tagManagementListStore;

    return (
      <div className="view list">
        <div className="rc-container bring-forward">
          <div className="header-action-bar">
            <h2>Tags</h2>
            <p className="subtext">Manage tags and review tagged content</p>
            <div className="clearfix" />
          </div>
          <div className="header-action-bar marginTopzero">
            <div className="pull-left">
              <SearchBox
                value={store.tagLabelFilter.textToLookFor}
                onChange={store.tagLabelFilter.onTextToLookForChange}
                onSearch={store.tagLabelFilter.onApply}
                style={{ width: "260px" }}
              />
            </div>
            <div className="btn-group pull-right rc-center" style={{ height: 40 }}>
              {store.isEditing && (
                <Button color="accent" onClick={store.handleStopEdit}>
                  Stop Editing
                </Button>
              )}
              {!store.isEditing && (
                <Inline>
                  <Button color="brand" onClick={store.handleStartEdit}>
                    <FontAwesomeIcon icon="edit" fixedWidth /> Edit
                  </Button>
                  <LinkButton to="/tag/create" color="brand">
                    <FontAwesomeIcon icon="plus" fixedWidth /> Create Tag
                  </LinkButton>
                  <Button color="primary" onClick={store.helpModal.showModal}>
                    <FontAwesomeIcon icon="question-circle" fixedWidth />
                    Help
                  </Button>
                </Inline>
              )}
            </div>
            <div className="clearfix" />
          </div>
          <RateCardsList store={store} />
        </div>

        <div
          className={classNames("ratecards-edit-actions", {
            hidden: store.isEditing === null,
            "bottom-appear": store.isEditing === true,
            "bottom-disappear": store.isEditing === false,
          })}
        >
          <Inline>
            {store.hasOwnership && (
              <Button
                disabled={store.selectedCount !== 1}
                onClick={store.showRenameTagModal}
              >
                <FontAwesomeIcon icon="edit" fixedWidth className="icon" /> Rename
              </Button>
            )}
            {store.hasOwnership && (
              <Button disabled={store.selectedCount !== 1} onClick={store.share}>
                <FontAwesomeIcon icon="share" fixedWidth className="icon" /> Share
              </Button>
            )}
            {store.hasOwnership && (
              <Button
                color="danger"
                disabled={store.selectedCount === 0}
                onClick={store.confirmDeleteModal.showModal}
              >
                <FontAwesomeIcon icon="trash-alt" fixedWidth className="icon" /> Delete
              </Button>
            )}
          </Inline>
        </div>

        <div
          className={classNames("rc-container-backdrop", {
            hidden: store.isEditing === null,
            "fade-in": store.isEditing === true,
            "fade-out": store.isEditing === false,
          })}
        />

        <Modal show={store.helpModal.show} onHide={store.helpModal.hideModal}>
          <div className="container-section header">
            <h4>Tags Help</h4>
          </div>
          <div className="container-section footer">
            <p>
              This page displays the tags you have created. A tag allows you to organize
              all of your content in the PeopleTicker such as your jobs, searches, and
              ratecards by the chosen tag. For example, you might create a tag for an RFP
              project or tag all of your IT jobs with the "IT" tag. Please Note: Category
              has been renamed TAGS allowing for expanded use across the product.
            </p>
          </div>
        </Modal>
        <Modal show={store.renameTagModal.show} onHide={store.renameTagModal.hideModal}>
          <ContainerSection className="header">
            <h4>Rename Tag</h4>
          </ContainerSection>
          <ContainerSection>
            <p>Name of the Tag:</p>
            <input
              type="text"
              className="rc-input-box"
              style={{ width: "100%" }}
              value={store.newTagName}
              maxLength="250"
              onChange={store.onNewTagNameChange}
            />
            <div className="pt-user-messages">
              {store.messaging.messages.length > 0 && (
                <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                  <a
                    href="#close"
                    className="close"
                    data-dismiss="alert"
                    aria-label="close"
                    onClick={store.messaging.removeMessage.bind(
                      store,
                      store.messaging.messages[0]
                    )}
                  >
                    &times;
                  </a>
                  <strong>{store.messaging.messages[0].messageLabel}</strong>
                  {store.messaging.messages[0].message}
                </div>
              )}
            </div>
          </ContainerSection>
          <ContainerSection className="footer">
            <Inline>
              <Button size="large" onClick={store.renameTagModal.hideModal}>
                Cancel
              </Button>
              <Button color="brand" size="large" onClick={store.renameTag}>
                Rename Tag
              </Button>
            </Inline>
          </ContainerSection>
        </Modal>
        <Modal
          show={store.shareModal.show}
          onHide={store.shareModal.hideModal}
          innerStyle={{ width: 550 }}
        >
          <ContainerSection className="header">
            <h4>Share Tags</h4>
          </ContainerSection>
          <Loadable isLoading={store.networkShareUsers.loading}>
            <ContainerSection className="container-section no-border">
              <p>Choose Users to share with:</p>
              <div>
                <div className="pull-left">
                  <InstantSearchBox
                    onSearch={store.shareUsersOnInstantSearch}
                    value={store.shareUsersInstantSearchValue}
                  />
                </div>
                <Inline
                  css={{ justifyContent: "flex-end", gap: "$2" }}
                  style={{ height: 40 }}
                >
                  <Button color="gray" onClick={store.shareUsersOnSelectAll}>
                    Select All
                  </Button>
                  <Button color="gray" onClick={store.shareUsersOnDeselectAll}>
                    Deselect All
                  </Button>
                </Inline>
                <div className="clearfix" />
              </div>
            </ContainerSection>
            <ShareWithUsersList filterState={store} />
            <ContainerSection className="footer">
              <Inline css={{ justifyContent: "flex-end", gap: "10px" }}>
                <Button size="large" onClick={store.shareModal.hideModal}>
                  Cancel
                </Button>
                <Button size="large" color="brand" onClick={store.performShare}>
                  Share With Selected Users
                </Button>
              </Inline>
            </ContainerSection>
          </Loadable>
        </Modal>
        <Modal
          show={store.confirmDeleteModal.show}
          onHide={store.confirmDeleteModal.hideModal}
        >
          <div className="container-section header">
            <h4>Confirmation Needed</h4>
          </div>
          <div className="container-section">
            <p>Are you sure you want to delete the selected Tags?</p>
            {/*<p>This will also delete all Punchouts in these Rate Cards.</p>*/}
            <p className="text-danger">
              <FontAwesomeIcon icon="exclamation-triangle" />{" "}
              <em>This action cannot be undone!</em>
            </p>
          </div>
          <div className="container-section footer">
            <div className="pull-right">
              <button className="btn btn-lg" onClick={store.confirmDeleteModal.hideModal}>
                No, Cancel
              </button>
              <button
                className="btn btn-lg btn-danger"
                onClick={(e) => {
                  store.confirmDeleteModal.hideModal();
                  store.deleteTags();
                }}
              >
                Yes, Delete Tags
              </button>
            </div>
          </div>
        </Modal>
        <ExportModal exportState={store.exportModal} />
      </div>
    );
  }
}

export default observer(List);
