// @flow

import React from "react";
import type { Element } from "react";
import { extendObservable, action, runInAction, computed } from "mobx";
import { observer } from "mobx-react";
import NetworkState from "../../models/NetworkState";
import ModalState from "../../models/ModalState";
import JobLibraryTitle from "../../models/JobLibraryTitle";
import type MobXStore from "./MobXStore";
import CCCCountry from "../../models/CCCCountry";
import matchSorter from "match-sorter";
import type { FetchAPI, FetchGraphQL } from "../../App";
import { createTasteGraphQLWrapper } from "./SupportFunctions";

export default class JobLibraryRequestCountryStore {
  fetchTasteGraphQL: FetchGraphQL;
  fetchGraphQL: FetchGraphQL;
  fetchAPI: FetchAPI;
  mobXStore: MobXStore;
  router: ?Object;
  previousLocation: string;
  network: NetworkState;
  successModal: ModalState;
  errorMessage: Element<any>;
  errorModal: ModalState;
  confirmEmptyLibraryModal: ModalState;

  // countries
  userCountriesNetwork: NetworkState;
  selectedCountries: CCCCountry[];
  defaultSelectedCountries: ?(string[]);
  selectedCountriesError: string;
  userCountryCodes: string[];
  cccCountries: CCCCountry[];
  viewCountries: CCCCountry[];
  filteredCountries: CCCCountry[];
  loadingCountries: boolean;
  requestNewCountries: boolean;
  countryFilterQuery: string;
  getCCCCountries: () => Promise<any>;
  getUserCountries: () => Promise<any>;
  selectDefaultCountries: () => void;
  onCountryFilterQueryChange: (string) => void;
  onAddSelectedCountry: (CCCCountry) => void;
  onRemoveSelectedCountry: (CCCCountry) => void;
  onSelectAllCountries: (Event) => void;
  onSelectNoneCountries: (Event) => void;
  onRequestNewCountriesChange: (Event) => void;

  // user message
  userMessage: string;
  onUserMessageChange: (string) => void;

  // client job titles
  addRemoveJobLibraryTitleAction: (JobLibraryTitle) => any;

  // request form
  onLoadConfig: ?{
    filterByTitles?: JobLibraryTitle[],
    libraryId?: string,
  };
  load: () => void;
  reset: () => void;
  showSuccess: () => void;
  isValid: () => void;
  goBack: () => void;
  handleSubmitRequest: () => void;
  submitRequest: () => void;
  handleConfirmEmptyLibraryCancel: () => void;
  requestCountryForTitle: (JobLibraryTitle) => void;
  requestCountryForLibrary: (string) => void;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI, mobXStore: MobXStore) {
    this.fetchTasteGraphQL = createTasteGraphQLWrapper(fetchAPI);
    this.fetchGraphQL = fetchGraphQL;
    this.fetchAPI = fetchAPI;
    this.mobXStore = mobXStore;
    this.router = null;

    extendObservable(this, {
      network: new NetworkState(),
      userCountriesNetwork: new NetworkState(),
      previousLocation: "/job-library/new-title-request/",
      // countries
      userCountryCodes: [],
      cccCountries: [],
      selectedCountries: [],
      defaultSelectedCountries: null,
      selectedCountriesError: "",
      // filteredCountries: [],
      loadingCountries: false,
      countryFilterQuery: "",
      requestNewCountries: true,
      viewCountries: computed(() => {
        if (this.requestNewCountries) {
          // show all countries
          return [...this.cccCountries];
        }

        // show only user countries
        return this.cccCountries.filter((item) => {
          return this.userCountryCodes.includes(
            item.iso3166Alpha2 ? item.iso3166Alpha2 : ""
          );
        });
      }),
      filteredCountries: computed(() => {
        return this.countryFilterQuery.trim()
          ? matchSorter(this.viewCountries, this.countryFilterQuery.trim(), {
              keys: ["title"],
            })
          : this.viewCountries;
      }),
      // user message
      userMessage: "",
      // request
      successModal: new ModalState(),
      confirmEmptyLibraryModal: new ModalState(),
      errorModal: new ModalState(),
      errorMessage: null,
      onLoadConfig: null,
    });

    // countries
    this.getUserCountries = action(this.getUserCountries.bind(this));
    this.getCCCCountries = action(this.getCCCCountries.bind(this));
    this.onCountryFilterQueryChange = action(this.onCountryFilterQueryChange.bind(this));
    this.onAddSelectedCountry = action(this.onAddSelectedCountry.bind(this));
    this.onRemoveSelectedCountry = action(this.onRemoveSelectedCountry.bind(this));
    this.onSelectAllCountries = action(this.onSelectAllCountries.bind(this));
    this.onSelectNoneCountries = action(this.onSelectNoneCountries.bind(this));
    this.selectDefaultCountries = action(this.selectDefaultCountries.bind(this));
    this.onRequestNewCountriesChange = action(
      this.onRequestNewCountriesChange.bind(this)
    );
    // user message
    this.onUserMessageChange = action(this.onUserMessageChange.bind(this));
    // client job titles
    this.addRemoveJobLibraryTitleAction = action(
      this.addRemoveJobLibraryTitleAction.bind(this)
    );
    // request
    this.submitRequest = action(this.submitRequest.bind(this));
    this.load = action(this.load.bind(this));
    this.reset = action(this.reset.bind(this));
    this.showSuccess = action(this.showSuccess.bind(this));
    this.isValid = action(this.isValid.bind(this));
    this.goBack = action(this.goBack.bind(this));
    this.handleSubmitRequest = action(this.handleSubmitRequest.bind(this));
    this.handleConfirmEmptyLibraryCancel = action(
      this.handleConfirmEmptyLibraryCancel.bind(this)
    );
    this.requestCountryForTitle = action(this.requestCountryForTitle.bind(this));
    this.requestCountryForLibrary = action(this.requestCountryForLibrary.bind(this));
  }

  // ------------------------------------------------------------
  //
  //   Countries
  //
  // ------------------------------------------------------------

  selectDefaultCountries() {
    if (!this.defaultSelectedCountries) return;

    const defaults = this.defaultSelectedCountries.slice() || [];
    this.selectedCountries = this.cccCountries.filter((country) => {
      return defaults.includes(String(country.id) || "");
    });
  }

  onCountryFilterQueryChange(filterQuery: string) {
    this.countryFilterQuery = filterQuery;
    this.selectedCountriesError = "";
  }

  onAddSelectedCountry(country: CCCCountry) {
    this.selectedCountries.push(country);
    this.countryFilterQuery = "";
    this.selectedCountriesError = "";
  }

  onRemoveSelectedCountry(country: CCCCountry) {
    this.selectedCountries = this.selectedCountries.filter((c) => c !== country);
  }

  onSelectAllCountries(e: Event) {
    e.preventDefault();
    this.selectedCountries = this.viewCountries;
  }

  onSelectNoneCountries(e: Event) {
    e.preventDefault();
    this.selectedCountries = [];
  }

  onRequestNewCountriesChange(e: Event) {
    this.requestNewCountries = !this.requestNewCountries;
    this.countryFilterQuery = "";

    if (!this.requestNewCountries) {
      // remove countries that user don't have access to from selection
      this.selectedCountries = this.selectedCountries.filter((item) => {
        const selectedCountryCode = item.iso3166Alpha2 ? item.iso3166Alpha2 : "";
        return this.userCountryCodes.includes(selectedCountryCode);
      });
    }
  }

  async getUserCountries(): Promise<any> {
    // TODO: Cancel the request somehow
    // if (this.userCountriesNetwork.loading === true) {
    //   this.sevenApi.cancelCurrentRequest();
    // }

    const query = `
      query getUserCountries {
        viewer {
          currentLegacyUser {
            allowedCountries(order: [{field: NAME}]) {
              edges {
                node {
                  ...userCountry
                }
              }
            }
          }
        }
      }

      fragment userCountry on LocationNode {
        isoCode
      }
    `;

    this.userCountriesNetwork.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, {});
    } catch (e) {
      // if (this.sevenApi.axios.isCancel(e)) {
      //   return e;
      // }

      this.userCountriesNetwork.handleError("Getting Client UserCountries", e);
      if (res !== null) {
        this.userCountriesNetwork.logGraphQLError("Get Client UserCountries query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getUserCountries--success", () => {
      this.userCountriesNetwork.loading = false;
      this.userCountriesNetwork.error = null;
      if (
        this.userCountriesNetwork.logGraphQLError("Get Client UserCountries query", res)
      ) {
        // TODO: Display user friendly error message
        return;
      }

      if (!res) {
        return Promise.reject();
      }

      const userCountries = res.data.viewer.currentLegacyUser.allowedCountries.edges;

      this.userCountryCodes = userCountries.map((userCountry) => {
        return userCountry.node.isoCode;
      });

      return this.userCountryCodes;
    });
  }

  async getCCCCountries(): Promise<any> {
    if (this.network.loading) {
      // NOTE: Debounces
      return;
    }

    if (this.cccCountries.length) return;

    const query = `
      query requestCCCCountries {
        cccCountries(order: [{field: TITLE}]) {
          edges {
            node {
              databaseId
              title
              iso3166Alpha2
            }
          }
        }
      }
    `;

    this.network.loading = true;
    this.loadingCountries = true;
    let res: ?Object = null;

    try {
      res = await this.fetchGraphQL(query, {});
    } catch (e) {
      this.network.handleError("Requesting CCC Countries", e);
      // TODO: Display user friendly error message
      return;
    }

    return runInAction("getCCCCountries--success", () => {
      this.network.loading = false;
      this.network.error = null;
      this.loadingCountries = false;

      if (this.network.logGraphQLError("requestCCCCountries", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.network.loading = false;

      if (!res || !res.data) return;

      let cccCountries = [];
      if (res.data.cccCountries) {
        cccCountries = res.data.cccCountries.edges.map((edge) => {
          return new CCCCountry(this, edge.node);
        });
      }

      this.cccCountries = cccCountries;

      // if (this.requestNewCountries) {
      //   filter new countries for user
      // this.filteredCountries = this.cccCountries.filter(item => {
      //   return !this.userCountryCodes.includes(item.iso3166Alpha2 ? item.iso3166Alpha2 : "");
      // });
      // } else {
      //   filter user countries
      //   this.filteredCountries = this.cccCountries.filter(item => {
      //   return this.userCountryCodes.includes(item.iso3166Alpha2 ? item.iso3166Alpha2 : "");
      // });
      // }

      this.selectDefaultCountries();
    });
  }

  // ------------------------------------------------------------
  //
  //   UserMessage
  //
  // ------------------------------------------------------------

  onUserMessageChange(value: string) {
    this.userMessage = value;
  }

  // ------------------------------------------------------------
  //
  //   Request
  //
  // ------------------------------------------------------------

  requestCountryForTitle(clientJobTitle: JobLibraryTitle): void {
    this.reset();
    this.onLoadConfig = {
      filterByTitles: [clientJobTitle],
    };
  }

  requestCountryForLibrary(libraryId: string): void {
    this.reset();
    this.onLoadConfig = {
      libraryId: libraryId,
    };
  }

  handleConfirmEmptyLibraryCancel() {
    this.confirmEmptyLibraryModal.hideModal();
  }

  handleSubmitRequest(e: Event) {
    e.preventDefault();
    if (this.network.loading) {
      // NOTE: Debounces submitRequest
      return;
    }

    if (!this.isValid()) return;

    this.submitRequest();
  }

  addRemoveJobLibraryTitleAction(clientJobTitle: JobLibraryTitle) {
    const AddRemoveAction = observer((props: { clientJobTitle: JobLibraryTitle }) => (
      <button
        // key={props.key}
        className="cjl-button"
        style={{ width: "100%" }}
        onClick={(e) => {
          e.stopPropagation();
          props.clientJobTitle.toggleSelected();
        }}
      >
        {props.clientJobTitle.viewState.selected ? "Deselect" : "Select"}
      </button>
    ));

    return <AddRemoveAction key={clientJobTitle.id} clientJobTitle={clientJobTitle} />;
  }

  load() {
    // $FlowFixMe Stupid undefined...
    this.getUserCountries().then(() => this.getCCCCountries());

    const jobTitleListStore = this.mobXStore.jobLibraryTitleListStore;
    jobTitleListStore.handleStartEdit();
    jobTitleListStore.libraryId = null;
    jobTitleListStore.selectTitlesByLibraryId = null;

    if (this.onLoadConfig) {
      const { libraryId, filterByTitles } = this.onLoadConfig;
      if (libraryId) {
        jobTitleListStore.libraryId = libraryId ? parseInt(libraryId, 10) : null;
        jobTitleListStore.selectTitlesByLibraryId = libraryId || null;
        jobTitleListStore.allSelected = true;
      }
      if (filterByTitles) {
        filterByTitles.forEach((title) => {
          title.viewState.selected = true;
          jobTitleListStore.clientJobTitlesViewState.set(title.id, title.viewState);
        });
      }
      jobTitleListStore.filterBySelectedTitles();

      this.onLoadConfig = null;
    } else {
      jobTitleListStore.hardRefresh();
    }
  }

  reset() {
    this.userMessage = "";
    this.successModal.hideModal();
    this.selectedCountries = [];
    this.mobXStore.jobLibraryTitleListStore.clearAllSelections();
  }

  isValid() {
    if (!this.selectedCountries.length) {
      this.selectedCountriesError = "Select at least one country.";
      return false;
    } else {
      this.selectedCountriesError = "";
    }

    const selectedClientTitles =
      this.mobXStore.jobLibraryTitleListStore.getSelectedClientJobTitles();
    if (!selectedClientTitles || selectedClientTitles.length === 0) {
      this.confirmEmptyLibraryModal.showModal();
      return false;
    }

    return true;
  }

  goBack() {
    if (this.router) {
      this.router.push({
        pathname: this.previousLocation,
        query: this.router.query,
      });
    }
  }

  showSuccess() {
    this.successModal.showModal();
  }

  async submitRequest() {
    if (this.network.loading) {
      // NOTE: Debounces submitRequest
      return;
    }

    if (!this.isValid()) return;

    let query = {};
    query.operationName = "requestCountries";
    query.query = `
      mutation requestCountries(
        $jobTitleMappingIds: [ID]!,
        $cccCountryIds: [ID]!,
        $newCountries: Boolean,
        $userMessage: String
      ){
        createClientJobTitleCountryRequest(input: {
          jobTitleMappingIds: $jobTitleMappingIds,
          cccCountryIds: $cccCountryIds,
          isCountryRequest: $newCountries,
          userMessage: $userMessage
        }) {

          success {
            message
          }

          errors {
            ...on NotAllowedForCurrentUserError {
              message
            }
            ...on RequiredFieldEmptyError {
              message
            }
            ...on DoesNotExistError {
              message
            }
          }
        }
      }
    `;

    const selectedClientTitleIds =
      this.mobXStore.jobLibraryTitleListStore.getSelectedClientJobTitles();
    query.variables = {
      jobTitleMappingIds: selectedClientTitleIds,
      cccCountryIds: this.selectedCountries.map((c) => c.id),
      newCountries: this.requestNewCountries,
      userMessage: this.userMessage,
    };

    this.network.loading = true;
    let res: ?Object = null;

    try {
      res = await this.fetchGraphQL(query.query, query.variables);
    } catch (e) {
      this.network.handleError("Requesting Job Title", e);
      // TODO: Display user friendly error message
      return;
    }

    runInAction("submitRequest--success", () => {
      this.network.loading = false;
      this.network.error = null;

      if (this.network.logGraphQLError("requestCountries", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.showSuccess();
    });
  }
}
