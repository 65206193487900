import SessionInfo from "../../models/SessionInfo";

export const SKILL_TOP_K = 15;
export const SKILL_MIN_SIMILARITY = 0.5;
export const JOB_DES_MIN_WORDS = 30;
export const MIN_NUM_SKILLS = 5;

/**
 * @deprecated
 * Use isClientJobLibrary from useGlobalContext instead
 */
export const getSkillFeatureFlag = (client: SessionInfo["client"]) => {
  if (client?.isClientJobLibrary) {
    return true;
  } else {
    return false;
  }
};

export const getAllCommaSkills = (skills: string[]) => {
  if (skills && skills.length > 0) {
    return skills.join(", ");
  } else {
    return "";
  }
};

export const searchBySkillMatchQuery = `query SemanticTitleMatch($jobTitle: String!, $jobDescription: String!, $topK: Int!, $minSimilarity: Float!, $serviceType: String!, $clientId: Int!) {
  semanticTitleMatch(jobTitle: $jobTitle, jobDescription: $jobDescription, topK: $topK, minSimilarity: $minSimilarity, serviceType: $serviceType, clientId: $clientId) {
    rawJobTitleIdMatched
    jobSimilarity
    descriptionMatched
    collectionTitle
    titleMatched
    clientJobTitle {
      clientRawJobTitle {
        databaseId
        title
        description
      }
      certifiedCountries {
        edges{
          node{
            country{
              databaseId
              title
            }
          }
        }
      }
      adhocCountries {
        edges{
          node{
            country{
              databaseId
              title
            }
          }
        }
      }
    }
  }
}
`;

export const searchBySkillMatchQueryNoClient = `query SemanticTitleMatch($jobTitle: String!, $jobDescription: String!, $topK: Int!, $minSimilarity: Float!, $serviceType: String!) {
  semanticTitleMatch(jobTitle: $jobTitle, jobDescription: $jobDescription, topK: $topK, minSimilarity: $minSimilarity, serviceType: $serviceType) {
    rawJobTitleIdMatched
    jobSimilarity
    descriptionMatched
    collectionTitle
    titleMatched
  }
}
`;
