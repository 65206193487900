import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import { observer } from "mobx-react";
import CheckBox from "../../../shared/CheckBox";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const UserListItem = observer(
  class extends Component {
    static contextTypes = {
      router: PropTypes.object,
    };

    constructor(props) {
      super(props);

      this.handleViewDetail = this.handleViewDetail.bind(this);
    }

    handleViewDetail() {
      this.context.router.push({
        pathname: `/admin/users/${this.props.user.userId}`,
        query: this.context.router.query,
      });
    }

    render() {
      const props = this.props;
      const userDetail = props.user;
      const editing = userDetail.viewState.editing;
      const selected = userDetail.viewState.selected;
      const name = `${userDetail.firstName} ${userDetail.lastName}`;
      const email = userDetail.email;
      const username = userDetail.username;
      const clientName = userDetail.client[0].name;
      const id = userDetail.userId;
      // const searchLimit = userDetail.searchLimit;
      const active = userDetail.active;

      let onItemClick = this.props.onClick || this.handleViewDetail;
      let editingSection = null;
      //let sharedByYou = shared;

      if (editing) {
        onItemClick = userDetail.toggleSelected;
        editingSection = (
          <CheckBox
            type={props.store.allowMultipleItemSelection ? "checkbox" : "radio"}
            name="list-item-select"
            value={name}
            selected={selected}
            style={{ width: 34 }}
            onClick={onItemClick}
          />
        );
      }

      return (
        <div className="ratecard-list-item-container">
          <div
            className={classNames("ratecard-list-item", { selected: selected })}
            onClick={onItemClick}
          >
            <div className="ratecard-list-item-left ellipsis">
              {editingSection}
              <div className="ratecard-label-container ellipsis">
                <div className="ratecard-label ellipsis">
                  <span className="ellipsis">{username}</span>
                </div>
                {
                  <div className="ratecard-meta ellipsis">
                    <span className="item-subtext-muted">Email </span>
                    <span className="item-subtext-accent">{email} </span>
                    <span className="item-subtext-muted">for client </span>
                    <span className="item-subtext-accent">{clientName}</span>
                  </div>
                }
              </div>
            </div>
            <div className="ratecard-list-item-right">
              {active && (
                <div className="ratecard-info w-300 text-center text-green">
                  <div className="flex center-items">
                    <FontAwesomeIcon icon="check-circle" fixedWidth className="icon" />{" "}
                    <div>Active</div>
                  </div>
                </div>
              )}
              {!active && (
                <div className="ratecard-info w-300 text-center text-danger">
                  <div className="flex center-items">
                    <FontAwesomeIcon icon="times-circle" fixedWidth className="icon" />{" "}
                    <div>Inactive</div>
                  </div>
                </div>
              )}
              <div className="ratecard-info w-300 text-center">
                <header data-testid="ID-header-users">ID</header>
                <div>{id}</div>
              </div>
              {!editing && (
                <div className="rc-center">
                  {
                    <Link to={`/admin/users/${id}`} className="btn btn-default">
                      View
                    </Link>
                  }
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
);

UserListItem.propTypes = {
  store: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default UserListItem;
