import React from "react";
import { OrderedMap } from "immutable";

import { ValuesBlock, useSearchInputState } from "./BaseValuesFilter";
import ValuesCheckList, {
  ValuesOrderedMap,
  SelectedValuesList,
  OnSelectValuesFunction,
  ValueObject,
} from "./ValuesCheckList";
import Alert from "../../lib/Alert";
import Stack from "../../lib/Stack";
import Text from "../../lib/Text";
import Center from "../../lib/Center";
import { emptyList, emptyOrderedMap, FilterTypes } from "../constants";
import { Activatable, OnChangeFilterValueFunction } from "./types";
import {
  ComparatorsConfig,
  DataProvider,
  FilterConfigMap,
  FiltersConfigOrderedMap,
  OrderDirections,
  OrderDirectionsType,
  RowData,
  RowIdGetterFunction,
  ValueGetterFunc,
} from "../types";
import { ImmutableList, ImmutableOrderedMap } from "../../../types/immutable";

export const useValuesSource = <RD = RowData,>(
  dataProvider: DataProvider<RD>,
  dataProviderFilters: FiltersConfigOrderedMap,
  searchValue: string,
  orderDirection: OrderDirectionsType,
  comparators: ComparatorsConfig,
  idGetter: RowIdGetterFunction<RD>,
  getter: ValueGetterFunc<RD>
) => {
  const [valuesState, setValuesState] = React.useState<ValuesOrderedMap>(
    emptyOrderedMap as ValuesOrderedMap
  );

  const valuesSource = React.useCallback(() => {
    const originalTableData = dataProvider(dataProviderFilters);
    const allValuesList = originalTableData.map((item, idx) => {
      const key = idGetter(item);
      const value = getter(item, idx!);

      if (value == null) return value;

      return [value, { key, value }];
    }) as ImmutableList<[string, ValueObject]>;
    const filteredValuesList = allValuesList.filter((item: [string, ValueObject]) => {
      if (item == null) return false;

      const { value } = item[1];

      if (searchValue) {
        return (
          value != null &&
          ("" + value).toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
        );
      }
      return true;
    });
    let sortedValuesList = filteredValuesList;

    if (orderDirection && orderDirection !== OrderDirections.NO) {
      const comparator = comparators[orderDirection];

      if (comparator) {
        sortedValuesList = sortedValuesList.sort((itemA, itemB) => {
          const { value: valueA } = itemA[1];
          const { value: valueB } = itemB[1];
          return comparator(valueA, valueB);
        });
      }
    }

    const valuesOrderedMap = OrderedMap(sortedValuesList) as any as ImmutableOrderedMap<
      string,
      ValueObject
    >;

    setValuesState(valuesOrderedMap);
  }, [
    searchValue,
    orderDirection,
    dataProvider,
    dataProviderFilters,
    getter,
    idGetter,
    comparators,
  ]);

  return {
    values: valuesState,
    valuesSource,
  };
};

export const useSelectValuesHandlers = (
  selectedValues: SelectedValuesList,
  filter: FilterConfigMap,
  onChange: OnChangeFilterValueFunction
) => {
  const selectValues = React.useCallback(
    (values) => {
      onChange(
        filter
          .setIn(["filter", "values"], values)
          .setIn(["filter", "type"], FilterTypes.VALUES_CHECKLIST) as FilterConfigMap
      );
    },
    [filter, onChange]
  );

  const handleSelectValues = React.useCallback(
    (values: SelectedValuesList) => {
      if (values !== selectedValues) {
        selectValues(values);
      }
    },
    [selectedValues, selectValues]
  );

  const handleUnselectAllValues = React.useCallback(
    () => selectValues(emptyList),
    [selectValues]
  );

  return {
    handleSelectValues,
    handleUnselectAllValues,
  };
};

export const loadingBlock = (
  <Center as={Text} thin>
    loading...
  </Center>
);

type ValuesCheckListBlockProps = {
  emptyHint: React.ReactNode;
  values: ValuesOrderedMap;
  selectedValues: SelectedValuesList;
  loading?: boolean;
  maxSelectItems?: number;
  showAlert?: boolean;
  onSelect: OnSelectValuesFunction;
  onScroll?: React.UIEventHandler<HTMLDivElement>;
};

export const ValuesCheckListBlock = (props: ValuesCheckListBlockProps) => {
  const {
    emptyHint,
    values,
    selectedValues,
    loading,
    maxSelectItems,
    showAlert,
    onSelect,
    onScroll,
  } = props;

  return (
    <Stack fill nogap css={{ alignItems: "stretch" }}>
      {!!maxSelectItems && showAlert && (
        <Alert
          color="danger"
          css={{
            position: "absolute",
            zIndex: 2,
            width: "$full",
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            textAlign: "center",
          }}
        >
          Can't select more than {maxSelectItems} items
        </Alert>
      )}
      <Stack
        fill
        onScroll={onScroll}
        css={{
          alignItems: "stretch",
          height: "170px",
          padding: "$2 $3",
          border: "1px solid $primaryLight",
          borderRadius: "$rounded",
          gap: "$2",
          overflow: "auto",
        }}
      >
        {values.size === 0 && (loading ? loadingBlock : emptyHint)}
        {values.size > 0 && (
          <ValuesCheckList
            values={values}
            selectedValues={selectedValues}
            onSelect={onSelect}
          />
        )}
        {values.size > 0 && loading && loadingBlock}
      </Stack>
    </Stack>
  );
};
ValuesCheckListBlock.displayName = "ValuesCheckListBlock";

export interface BaseValuesCheckFilterProps<RD = RowData> {
  filter: FilterConfigMap;
  onChange: OnChangeFilterValueFunction;
  emptyHint?: React.ReactNode;
  searchTimeout?: number;
  getter: ValueGetterFunc<RD>;
  idGetter: RowIdGetterFunction<RD>;
  comparators: ComparatorsConfig;
  isVisible: boolean;
}

export interface ValuesCheckFilterProps<RD = RowData>
  extends BaseValuesCheckFilterProps<RD> {
  dataProvider: DataProvider<RD>;
  dataProviderFilters: FiltersConfigOrderedMap;
}

export const ValuesCheckFilter = React.forwardRef(
  <RD = RowData,>(
    props: ValuesCheckFilterProps<RD>,
    ref: React.ForwardedRef<Activatable>
  ) => {
    const {
      filter,
      onChange,
      emptyHint,
      searchTimeout,
      dataProvider,
      dataProviderFilters,
      getter,
      idGetter,
      comparators,
    } = props;

    const selectedValues = filter.getIn(["filter", "values"]);
    const orderDirection = filter.getIn(["order", "direction"]);
    const searchValue = filter.getIn(["filter", "search"]);

    const { makeSearch, setSearchInputRef, setSearchFocus } = useSearchInputState(
      searchValue,
      filter,
      FilterTypes.VALUES_CHECKLIST,
      onChange
    );

    const { values, valuesSource } = useValuesSource(
      dataProvider,
      dataProviderFilters,
      searchValue,
      orderDirection,
      comparators,
      idGetter,
      getter
    );
    const allValuesList = React.useMemo(
      () => values.keySeq().toList(),
      [values]
    ) as SelectedValuesList;

    const { handleSelectValues, handleUnselectAllValues } = useSelectValuesHandlers(
      selectedValues,
      filter,
      onChange
    );

    React.useEffect(() => {
      valuesSource();
    }, [valuesSource]);

    React.useImperativeHandle(ref, () => ({
      initialize: valuesSource,
      focus: setSearchFocus,
    }));

    return (
      <ValuesBlock
        onSelectAll={() => handleSelectValues(allValuesList)}
        onUnselectAll={handleUnselectAllValues}
        searchValue={searchValue}
        searchTimeout={searchTimeout!}
        inputRef={setSearchInputRef}
        onSearch={makeSearch}
      >
        <ValuesCheckListBlock
          values={values}
          selectedValues={selectedValues}
          emptyHint={emptyHint!}
          onSelect={handleSelectValues}
        />
      </ValuesBlock>
    );
  }
) as {
  <RD = RowData>(
    props: ValuesCheckFilterProps<RD> & { ref: React.ForwardedRef<Activatable> }
  ): React.ReactElement;
  displayName: string;
  defaultProps: Partial<ValuesCheckFilterProps>;
};

ValuesCheckFilter.displayName = "ValuesCheckFilter";
ValuesCheckFilter.defaultProps = {
  dataProviderFilters: emptyOrderedMap as FiltersConfigOrderedMap,
  searchTimeout: 1000,
  emptyHint: (
    <Center as={Text} thin>
      no matches found
    </Center>
  ),
};

export default ValuesCheckFilter;
