// @flow

import { action, extendObservable } from "mobx";
import { CONTINGENT_RATE_TYPE } from "../../views/ratecards/components/RateDisplayToggle";
import ModalState from "../../models/ModalState";
import numeral from "numeral";

import { useSearchResultsStore } from "../SearchResults";

type SyntheticEvent = {
  currentTarget: {
    value: string,
  },
};

export default class GlobalStateStore {
  // contingent rate frequency display options
  selectedContingentRateDisplayOption: string;
  rateMultiplier: number;
  dailyMultiplier: number;
  weeklyMultiplier: number;
  monthlyMultiplier: number;
  onContingentRatesDisplayChange: (string) => void;
  onCustomDailyMultiplierChange: (SyntheticEvent) => void;
  onCustomWeeklyMultiplierChange: (SyntheticEvent) => void;
  onCustomMonthlyMultiplierChange: (SyntheticEvent) => void;
  customMultipliersModal: ModalState;
  customDailyMultiplier: string;
  customWeeklyMultiplier: string;
  customMonthlyMultiplier: string;
  customDailyMultiplierInvalid: boolean;
  customWeeklyMultiplierInvalid: boolean;
  customMonthlyMultiplierInvalid: boolean;
  onApplyCustomMultipliers: () => void;
  onCancelCustomMultipliers: () => void;
  onResetCustomMultipliers: () => void;

  constructor() {
    extendObservable(this, {
      // contingent rate frequency display options
      customMultipliersModal: new ModalState(),
      selectedContingentRateDisplayOption: CONTINGENT_RATE_TYPE.HOURLY,
      rateMultiplier: 1,
      dailyMultiplier: 8,
      weeklyMultiplier: 40,
      monthlyMultiplier: 160,
      customDailyMultiplier: "8",
      customWeeklyMultiplier: "40",
      customMonthlyMultiplier: "160",
      customDailyMultiplierInvalid: false,
      customWeeklyMultiplierInvalid: false,
      customMonthlyMultiplierInvalid: false,
    });

    // contingent rate frequency display options
    this.onContingentRatesDisplayChange = action(
      this.onContingentRatesDisplayChange.bind(this)
    );
    this.onCustomDailyMultiplierChange = action(
      this.onCustomDailyMultiplierChange.bind(this)
    );
    this.onCustomWeeklyMultiplierChange = action(
      this.onCustomWeeklyMultiplierChange.bind(this)
    );
    this.onCustomMonthlyMultiplierChange = action(
      this.onCustomMonthlyMultiplierChange.bind(this)
    );
    this.onApplyCustomMultipliers = action(this.onApplyCustomMultipliers.bind(this));
    this.onCancelCustomMultipliers = action(this.onCancelCustomMultipliers.bind(this));
    this.onResetCustomMultipliers = action(this.onResetCustomMultipliers.bind(this));
  }

  // ------------------------------------------------------------
  //
  //   contingent rate frequency display options
  //
  // ------------------------------------------------------------

  onCustomDailyMultiplierChange(event: SyntheticEvent) {
    this.customDailyMultiplier = event.currentTarget.value;
  }

  onCustomWeeklyMultiplierChange(event: SyntheticEvent) {
    this.customWeeklyMultiplier = event.currentTarget.value;
  }

  onCustomMonthlyMultiplierChange(event: SyntheticEvent) {
    this.customMonthlyMultiplier = event.currentTarget.value;
  }

  onApplyCustomMultipliers() {
    this.customMultipliersModal.hideModal();

    this.dailyMultiplier = numeral(this.customDailyMultiplier).value() || 0;
    this.weeklyMultiplier = numeral(this.customWeeklyMultiplier).value() || 0;
    this.monthlyMultiplier = numeral(this.customMonthlyMultiplier).value() || 0;

    this.customDailyMultiplier = this.dailyMultiplier.toString();
    this.customWeeklyMultiplier = this.weeklyMultiplier.toString();
    this.customMonthlyMultiplier = this.monthlyMultiplier.toString();

    this.onContingentRatesDisplayChange(this.selectedContingentRateDisplayOption);
  }

  onCancelCustomMultipliers() {
    this.customMultipliersModal.hideModal();

    this.customDailyMultiplier = this.dailyMultiplier.toString();
    this.customWeeklyMultiplier = this.weeklyMultiplier.toString();
    this.customMonthlyMultiplier = this.monthlyMultiplier.toString();
  }

  onResetCustomMultipliers() {
    this.customMultipliersModal.hideModal();

    this.dailyMultiplier = 8;
    this.weeklyMultiplier = 40;
    this.monthlyMultiplier = 160;
    this.customDailyMultiplier = "8";
    this.customWeeklyMultiplier = "40";
    this.customMonthlyMultiplier = "160";

    this.onContingentRatesDisplayChange(this.selectedContingentRateDisplayOption);
  }

  onContingentRatesDisplayChange(option: string) {
    useSearchResultsStore.setState({
      rateFrequency: option.charAt(0).toUpperCase() + option.slice(1).toLowerCase(),
    });

    this.selectedContingentRateDisplayOption = option;
    switch (option) {
      case CONTINGENT_RATE_TYPE.HOURLY:
        this.rateMultiplier = 1;
        break;
      case CONTINGENT_RATE_TYPE.DAILY:
        this.rateMultiplier = this.dailyMultiplier;
        break;
      case CONTINGENT_RATE_TYPE.WEEKLY:
        this.rateMultiplier = this.weeklyMultiplier;
        break;
      case CONTINGENT_RATE_TYPE.MONTHLY:
        this.rateMultiplier = this.monthlyMultiplier;
        break;
      default:
        this.rateMultiplier = 1;
        break;
    }
  }
}
