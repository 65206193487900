// @flow
import * as React from "react";
import AsyncSelect from "react-select/lib/Async";
import { reactSelectStyles, reactSelectTheme } from "../../constants/reactSelect";

type Props = {
  onChange: (any) => void,
  disabled?: boolean,
  Clients: (string) => Promise<{
    clients: Array<{ node: { clientId: number, name: string } }>,
  }>,
  value?: { value: number, label: string } | null,
};

type clientNode = { node: { clientId: number, name: string } };
// type clientItem = { value: clientNode, label: string };

class ClientSelect extends React.Component<Props> {
  getOptions = (input: any, callback: (any) => void) => {
    this.props
      .Clients(input)
      .then((response) => {
        const cachedClientsList = response.clients.map((client: clientNode) => ({
          value: client.node.clientId,
          label: client.node.name,
        }));
        callback(cachedClientsList);
      })
      .catch(function (error) {
        // TODO: Raise or handle error
        callback(error);
      });
  };
  handleChange = (value: { value: number, label: string }, actionMeta: Object) => {
    // TODO: value.value might not be a number
    this.props.onChange({
      clientId: value.value,
      name: value.label,
    });
  };
  render() {
    return (
      <AsyncSelect
        name="client"
        placeholder="Select Client"
        openMenuOnFocus
        defaultOptions
        cacheOptions={false}
        loadOptions={this.getOptions}
        value={this.props.value}
        backspaceRemoves={true}
        onChange={this.handleChange}
        isDisabled={this.props.disabled}
        styles={reactSelectStyles}
        theme={reactSelectTheme}
      />
    );
  }
}

export default ClientSelect;
