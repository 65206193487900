import React, { useState, useEffect, useCallback } from "react";
import { Set } from "immutable";

import InlineValuePicker from "../pickers/InlineValuePicker";
import Button from "../lib/Button";
import Icon from "../lib/Icon";
import { ButtonGroup, ButtonGroupRight } from "../lib/ButtonGroup";
import { emptySet } from "../../constants";
import { CheckboxItem } from "../lib/Checkbox";
import Box from "../lib/Box";
import Stack from "../lib/Stack";
import Text from "../lib/Text";
import Grid from "../lib/Grid";
import {
  Dialog,
  DialogActions,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogPortal,
} from "../lib/Dialog";
import Inline from "../lib/Inline";
import {
  TableConfigOptionsList,
  VisibleColumnsSet,
  VisibleColumnsChangeHandler,
} from "./types";
import { CSS } from "../../stitches.config";

/*
 *  returns options ordered horizontally in 3 columns
 */
export const getOrderedOptions = (
  options: TableConfigOptionsList,
  desc: boolean = false
): TableConfigOptionsList => {
  return options
    .filter((o) => !!(o.get("uniqueKey") && o.get("title")))
    .sortBy(
      (o) => o.get("title"),
      (v1, v2) => {
        const result = v1 === v2 ? 0 : v1 > v2 ? 1 : -1;
        return desc ? -result : result;
      }
    );
};

type VisibleColumnsOptionsProps<T = string> = {
  title?: string;
  options: TableConfigOptionsList<T>;
  value: VisibleColumnsSet<T> | T | null;
  allValues?: VisibleColumnsSet<T>;
  onChange: VisibleColumnsChangeHandler<T>;
  disabled?: boolean;
  gridCss?: CSS;
};

export const VisibleColumnsOptions = <T = string,>(
  props: VisibleColumnsOptionsProps<T>
) => {
  const {
    title,
    options,
    value: selectedValue,
    onChange,
    allValues,
    disabled = false,
    gridCss = {
      gap: "$2",
      gridAutoFlow: "row",
      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
      "@sm": { gridTemplateColumns: "repeat(3, minmax(0, 1fr))" },
      "@lg": { gridTemplateColumns: "repeat(4, minmax(0, 1fr))" },
    },
  } = props;

  const isSelected = React.useCallback(
    (value: T) => {
      return Set.isSet(selectedValue)
        ? (selectedValue as VisibleColumnsSet<T>).includes(value)
        : (selectedValue as T | null) === value;
    },
    [selectedValue]
  );

  return (
    <Stack fill nogap css={{ alignItems: "stretch" }}>
      {title && (
        <Text as="label" color="dark" underline>
          {title}:
        </Text>
      )}
      <Grid css={gridCss}>
        {options.toArray().map((option) => {
          const optionKey = option.get("uniqueKey");
          const optionTitle = option.get("title");

          return (
            <CheckboxItem
              key={"" + optionKey}
              checked={isSelected(optionKey)}
              onCheckedChange={onChange.bind(undefined, optionKey)}
              disabled={disabled}
            >
              {optionTitle}
            </CheckboxItem>
          );
        })}
        {allValues && (
          <CheckboxItem
            checked={
              Set.isSet(selectedValue) &&
              (selectedValue as VisibleColumnsSet<T>).equals(allValues)
            }
            onCheckedChange={onChange.bind(undefined, allValues)}
            disabled={disabled}
          >
            All {title}
          </CheckboxItem>
        )}
      </Grid>
    </Stack>
  );
};

VisibleColumnsOptions.displayName = "VisibleColumnsOptions";

type VisibleColumnsBlockProps = {
  onSelectAll: React.MouseEventHandler<HTMLButtonElement>;
  onUnselectAll: React.MouseEventHandler<HTMLButtonElement>;
};

export const VisibleColumnsBlock = (
  props: React.PropsWithChildren<VisibleColumnsBlockProps>
) => {
  const { onSelectAll, onUnselectAll, children } = props;

  return (
    <Stack fill css={{ alignItems: "stretch" }}>
      <Text as="h4" color="dark">
        Choose visible columns for table:
      </Text>
      <ButtonGroup>
        <Button color="brand" variant="outlined" size="ptNormal" onClick={onSelectAll}>
          Select all
        </Button>
        <Button color="brand" variant="outlined" size="ptNormal" onClick={onUnselectAll}>
          Unselect all
        </Button>
      </ButtonGroup>
      <Box>{children}</Box>
    </Stack>
  );
};
VisibleColumnsBlock.displayName = "VisibleColumnsBlock";

const itemsPerPageOptions = [
  { value: 20, label: "20" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
  { value: 200, label: "200" },
  { value: 300, label: "300" },
  { value: 400, label: "400" },
  { value: 500, label: "500" },
];

type ItemsPerPageBlockProps = {
  itemsPerPage: number;
  onChange: (value: number) => void;
};

export const ItemsPerPageBlock = (props: ItemsPerPageBlockProps) => {
  const { itemsPerPage, onChange } = props;

  return (
    <Stack fill css={{ alignItems: "start" }}>
      <Text as="h4" color="dark">
        Choose number of rows per page for table:
      </Text>
      <InlineValuePicker
        label="Rows Number"
        options={itemsPerPageOptions}
        value={itemsPerPage}
        onChange={onChange}
      />
    </Stack>
  );
};

type TableConfigModalProps = {
  show: boolean;
  disabled: boolean;
  onApply: () => void;
  onHide: () => void;
};

export const TableConfigModal = (
  props: React.PropsWithChildren<TableConfigModalProps>
) => {
  const { show, disabled, onApply, onHide, children } = props;

  const handleApply = React.useCallback(
    (...args: any[]) => {
      onApply();
    },
    [onApply]
  );

  const handleHide = React.useCallback(
    (...args: any[]) => {
      onHide();
    },
    [onHide]
  );

  return (
    <Dialog open={show} onOpenChange={handleHide}>
      <DialogPortal>
        <DialogContent>
          <DialogTitle asChild>
            <Inline css={{ justifyContent: "space-between" }}>
              <h4>Table Configuration</h4>
              <DialogClose asChild>
                <Icon icon="times" />
              </DialogClose>
            </Inline>
          </DialogTitle>
          <DialogDescription asChild css={{ backgroundColor: "inherit" }}>
            <div>{children}</div>
          </DialogDescription>
          <DialogActions>
            <ButtonGroupRight fill>
              <Button
                color="brand"
                size="large"
                onClick={!disabled ? handleApply : undefined}
                disabled={disabled}
              >
                Apply
              </Button>
              <Button size="large" onClick={handleHide}>
                Cancel
              </Button>
            </ButtonGroupRight>
          </DialogActions>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
TableConfigModal.displayName = "TableConfigModal";

// custom hook
export const useTableConfigState = <T = string,>(
  defaultVisibleColumns: VisibleColumnsSet<T>,
  defaultItemsPerPage: number,
  allColumnsOptions: TableConfigOptionsList<T>
) => {
  // state
  const [visibleColumns, setVisibleColumns] = useState(defaultVisibleColumns);
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage);

  // effects
  useEffect(() => {
    setItemsPerPage(defaultItemsPerPage);
  }, [setItemsPerPage, defaultItemsPerPage]);

  // derivatives
  const hasVisibleColumnsChanges =
    visibleColumns.size !== 0 && !visibleColumns.equals(defaultVisibleColumns);
  const hasItemsPerPageChanges = itemsPerPage !== defaultItemsPerPage;
  const hasChanges = hasItemsPerPageChanges || hasVisibleColumnsChanges;

  // handlers
  const handleChangeVisibleColumns = useCallback(
    (value, isSelected) => {
      setVisibleColumns(
        isSelected ? visibleColumns.add(value) : visibleColumns.delete(value)
      );
    },
    [visibleColumns, setVisibleColumns]
  );
  const handleSelectAllColumns = useCallback(
    () =>
      setVisibleColumns(
        Set(
          allColumnsOptions.map<T>((o) => o.get("uniqueKey"))
        ) as unknown as VisibleColumnsSet<T>
      ),
    [allColumnsOptions, setVisibleColumns]
  );
  const handleUnselectAllColumns = useCallback(
    () => setVisibleColumns(emptySet),
    [setVisibleColumns]
  );
  const handleChangeItemsPerPage = setItemsPerPage;

  return {
    visibleColumns,
    itemsPerPage,
    setVisibleColumns,
    setItemsPerPage,
    hasVisibleColumnsChanges,
    hasItemsPerPageChanges,
    hasChanges,
    handleChangeVisibleColumns,
    handleSelectAllColumns,
    handleUnselectAllColumns,
    handleChangeItemsPerPage,
  };
};

export interface TableConfigProps {
  show: boolean;
  visibleColumns: VisibleColumnsSet;
  itemsPerPage: number;
  options: TableConfigOptionsList;
  onHide: () => void;
  onChange: (
    visibleColumns: VisibleColumnsSet | null,
    itemsPerPage: number | null
  ) => void;
  optionsGridCss?: CSS;
}

export const TableConfig = (props: TableConfigProps) => {
  const {
    visibleColumns: defaultVisibleColumns,
    itemsPerPage: defaultItemsPerPage,
    show,
    options,
    onHide,
    onChange,
    optionsGridCss = {
      gap: "$1",
      gridAutoFlow: "column",
      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
      gridTemplateRows: "repeat(26, minmax(0, 1fr))",
      "@lg": {
        gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
        gridTemplateRows: "repeat(18, minmax(0, 1fr))",
      },
    },
  } = props;

  const orderedOptions = React.useMemo(() => getOrderedOptions(options), [options]);

  // state
  const {
    visibleColumns,
    itemsPerPage,
    setVisibleColumns,
    setItemsPerPage,
    hasVisibleColumnsChanges,
    hasItemsPerPageChanges,
    hasChanges,
    handleChangeVisibleColumns,
    handleSelectAllColumns,
    handleUnselectAllColumns,
    handleChangeItemsPerPage,
  } = useTableConfigState(defaultVisibleColumns, defaultItemsPerPage, options);

  const handleApplyChanges = useCallback(() => {
    onHide();
    onChange(
      hasVisibleColumnsChanges ? visibleColumns : null,
      hasItemsPerPageChanges ? itemsPerPage : null
    );
  }, [
    onChange,
    onHide,
    hasVisibleColumnsChanges,
    hasItemsPerPageChanges,
    itemsPerPage,
    visibleColumns,
  ]);

  const handleCancelChanges = useCallback(() => {
    onHide();
    setVisibleColumns(defaultVisibleColumns);
    setItemsPerPage(defaultItemsPerPage);
  }, [
    onHide,
    setItemsPerPage,
    setVisibleColumns,
    defaultItemsPerPage,
    defaultVisibleColumns,
  ]);

  return (
    <TableConfigModal
      show={show}
      onApply={handleApplyChanges}
      onHide={handleCancelChanges}
      disabled={!hasChanges}
    >
      <Stack fill>
        <VisibleColumnsBlock
          onSelectAll={handleSelectAllColumns}
          onUnselectAll={handleUnselectAllColumns}
        >
          <VisibleColumnsOptions
            options={orderedOptions}
            value={visibleColumns}
            onChange={handleChangeVisibleColumns}
            gridCss={optionsGridCss}
          />
        </VisibleColumnsBlock>
        <ItemsPerPageBlock
          itemsPerPage={itemsPerPage}
          onChange={handleChangeItemsPerPage}
        />
      </Stack>
    </TableConfigModal>
  );
};
TableConfig.displayName = "TableConfig";

export default TableConfig;
