// @flow
import * as React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type SearchBoxProps = {
  small?: boolean,
  iconLeft?: string,
  style?: ?{},
  placeholder?: ?string,
  initialValue?: ?string,
  onSearch: (string) => Promise<any>,
};
class SearchBox extends React.Component<
  SearchBoxProps,
  {
    value: string,
    disabled: boolean,
  }
> {
  constructor(props: SearchBoxProps) {
    super(props);
    this.state = {
      value: props.initialValue || "",
      disabled: false,
    };
  }

  handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      value: e.target.value,
    });
  };

  handleSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState(
      {
        disabled: true,
      },
      async () => {
        await this.props.onSearch(this.state.value);

        this.setState({
          disabled: false,
        });
      }
    );
  };

  render() {
    const iconStyles = this.state.disabled ? { opacity: "0.4" } : {};
    const classes = classNames("pt-search", {
      small: this.props.small,
      "icon-left": this.props.iconLeft,
    });

    return (
      <form onSubmit={this.handleSubmit}>
        <div className={classes} style={this.props.style}>
          <input
            type="text"
            disabled={this.state.disabled}
            placeholder={this.props.placeholder}
            name="search-box"
            className="pt-search-box pt-search-box--open"
            value={this.state.value}
            onChange={this.handleChange}
          />
          <FontAwesomeIcon
            icon="search"
            className="pt-search-icon"
            style={iconStyles}
            onClick={this.handleSubmit}
            fixedWidth
          />
        </div>
      </form>
    );
  }
}

export default SearchBox;
