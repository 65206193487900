import { Map } from "immutable";

export function isNonNullable<T>(item: T | null | undefined): item is T {
  return Boolean(item);
}

/**
 * Creates a hashmap of a given array of objects, uses an attribute of the same object as the key
 */
export function createMap<K extends keyof T, V, T extends Record<string | number, V>>(
  array: T[],
  key: K
) {
  return array.reduce((map, element) => {
    const value = element[key];
    return map.set(value, element);
  }, Map<T[K], T>());
}
