import React from "react";

import TooltipBase, { Props } from "../../../components/lib/Tooltip";

const Tooltip = (props: Props) => {
  const { contentProps, ...rest } = props;
  const contentPropsCSS = contentProps?.css || {};

  return (
    <TooltipBase
      contentProps={{
        ...contentProps,
        css: {
          padding: "$1_5",
          fontSize: "inherit",
          lineHeight: "inherit",
          ...contentPropsCSS,
        },
      }}
      {...rest}
    />
  );
};

export default Tooltip;
