import type {
  ImmutableArbitraryMap,
  ImmutableList,
  ImmutableMap,
  ImmutableOrderedMap,
} from "../../types/immutable";
import type { Nullable } from "../../types/generic";
import type {
  ColumnConfigObject,
  FiltersConfigOrderedMap,
  FiltersDataProviderRestful,
  FiltersQueryObject,
  FiltersQueryOrderedMap,
  GroupConfigObject,
  RestfulTableConfigObject,
  RestfulTableDataStateObject,
  RowData,
  UrlQueryObject,
  ValueFormatterFunc,
  VisibleColumnsSet,
} from "../../components/tables/types";
import type { CurrencyData } from "../../components/selects/CurrencySelect";

import {
  CONTRACT,
  FTE,
  RATE_TYPES_2_TYPE as RATE_TYPES_TYPE,
} from "../../constants/rateTypes";
import { RATE_TYPES_2 as RATE_TYPES } from "../../constants/rateTypes";

export type { RATE_TYPES_2_TYPE as RATE_TYPES_TYPE } from "../../constants/rateTypes";
export { RATE_TYPES_2 as RATE_TYPES } from "../../constants/rateTypes";

export type Values<T> = T[keyof T];

export const RATE_TYPES_LABELS = {
  [RATE_TYPES.CONTRACT]: CONTRACT.label,
  [RATE_TYPES.FTE]: FTE.label,
};

export const CONTINGENT_VALUES_TYPES = {
  PAY_RATE: "pay_rate",
  MARKUP: "markup",
  BILL_RATE: "bill_rate",
} as const;

export type CONTINGENT_VALUES_TYPES_TYPE = Values<typeof CONTINGENT_VALUES_TYPES>;

export const CONTINGENT_VALUES_TYPES_LABELS = {
  [CONTINGENT_VALUES_TYPES.PAY_RATE]: "Pay Rate",
  [CONTINGENT_VALUES_TYPES.MARKUP]: "Markup",
  [CONTINGENT_VALUES_TYPES.BILL_RATE]: "Bill Rate",
};

export const ALL_VALUES_TYPES = {
  PAY_RATE: "pay_rate",
  MARKUP: "markup",
  BILL_RATE: "bill_rate",
  ANNUAL_SALARY: "annual_salary",
} as const;

export type ALL_VALUES_TYPES_TYPE = Values<typeof ALL_VALUES_TYPES>;

export const ALL_VALUES_TYPES_LABELS = {
  [ALL_VALUES_TYPES.PAY_RATE]: "Pay Rate",
  [ALL_VALUES_TYPES.MARKUP]: "Markup",
  [ALL_VALUES_TYPES.BILL_RATE]: "Bill Rate",
  [ALL_VALUES_TYPES.ANNUAL_SALARY]: "Annual Salary",
};

export const VALUES_SUBTYPES = {
  MIN: "min",
  MID: "mid",
  AVG: "avg",
  MAX: "max",
} as const;

export type VALUES_SUBTYPES_TYPE = Values<typeof VALUES_SUBTYPES>;

export const VALUES_SUBTYPES_LABELS = {
  [VALUES_SUBTYPES.MIN]: "Min",
  [VALUES_SUBTYPES.MID]: "Mid",
  [VALUES_SUBTYPES.AVG]: "Avg",
  [VALUES_SUBTYPES.MAX]: "Max",
};

export const SKILLS_LEVELS = {
  JUNIOR: 0,
  INTERMEDIATE: 1,
  SENIOR: 2,
  LEAD: 3,
  GURU: 4,
} as const;

export type SKILLS_LEVELS_TYPE = Values<typeof SKILLS_LEVELS>;

export const SKILLS_LEVELS_KEYS = {
  [SKILLS_LEVELS.JUNIOR]: "junior",
  [SKILLS_LEVELS.INTERMEDIATE]: "intermediate",
  [SKILLS_LEVELS.SENIOR]: "senior",
  [SKILLS_LEVELS.LEAD]: "lead",
  [SKILLS_LEVELS.GURU]: "guru",
};

export const SKILLS_LEVELS_LABELS = {
  [SKILLS_LEVELS.JUNIOR]: "Junior",
  [SKILLS_LEVELS.INTERMEDIATE]: "Intermediate",
  [SKILLS_LEVELS.SENIOR]: "Senior",
  [SKILLS_LEVELS.LEAD]: "Lead",
  [SKILLS_LEVELS.GURU]: "Guru",
};

export const SKILLS_LEVELS_ROMAN_LABELS = {
  [SKILLS_LEVELS.JUNIOR]: "I",
  [SKILLS_LEVELS.INTERMEDIATE]: "II",
  [SKILLS_LEVELS.SENIOR]: "III",
  [SKILLS_LEVELS.LEAD]: "IV",
  [SKILLS_LEVELS.GURU]: "V",
};

export const SKILLS_LEVELS_NUMERAL_LABELS = {
  [SKILLS_LEVELS.JUNIOR]: "1",
  [SKILLS_LEVELS.INTERMEDIATE]: "2",
  [SKILLS_LEVELS.SENIOR]: "3",
  [SKILLS_LEVELS.LEAD]: "4",
  [SKILLS_LEVELS.GURU]: "5",
};

export const DEVICE_TYPES = {
  DESKTOP: "desktop",
  TABLET: "tablet",
  MOBILE: "mobile",
} as const;

export type DEVICE_TYPES_TYPE = Values<typeof DEVICE_TYPES>;

export const JOB_TITLE_MATCH_TYPES = {
  EXACT_MATCH: 1,
  FUZZY_MATCH: 2,
  FIXED_MANUALLY: 3,
} as const;

export type JOB_TITLE_MATCH_TYPES_TYPE = Values<typeof JOB_TITLE_MATCH_TYPES>;

export const JOB_TITLE_MATCH_TYPES_LABELS = {
  [JOB_TITLE_MATCH_TYPES.EXACT_MATCH]: "Exact Match",
  [JOB_TITLE_MATCH_TYPES.FUZZY_MATCH]: "Broad Match",
  [JOB_TITLE_MATCH_TYPES.FIXED_MANUALLY]: "Fixed Manually",
};

export const TABLE_TABS_TYPES = {
  COMPARE_WITH_MARKET: "compare_with_market",
  SPEND_RATES: "spend_rates",
  MARKET_RATES: "market_rates",
  CUSTOM_CONFIG: "custom_config",
} as const;

export type TABLE_TABS_TYPES_TYPE = Values<typeof TABLE_TABS_TYPES>;

export const ALL_TABLE_TABS_TYPES = [
  TABLE_TABS_TYPES.COMPARE_WITH_MARKET,
  TABLE_TABS_TYPES.SPEND_RATES,
  TABLE_TABS_TYPES.MARKET_RATES,
  TABLE_TABS_TYPES.CUSTOM_CONFIG,
];

export const CONTRACTOR_STATUSES = {
  PENDING: 1,
  CLEAN: 2,
  FAILED: 3,
  FINISHED: 4,
  NEED_APPROVAL: 5,
} as const;

export type CONTRACTOR_STATUSES_TYPE = Values<typeof CONTRACTOR_STATUSES>;

export const CONTRACTOR_STATUSES_LABELS = {
  [CONTRACTOR_STATUSES.PENDING]: "Pending",
  [CONTRACTOR_STATUSES.CLEAN]: "Clean",
  [CONTRACTOR_STATUSES.FAILED]: "Failed",
  [CONTRACTOR_STATUSES.FINISHED]: "Finished",
  [CONTRACTOR_STATUSES.NEED_APPROVAL]: "Needs Approval",
};

export const ALL_CONTRACTOR_STATUSES_VALUES: CONTRACTOR_STATUSES_TYPE[] =
  Object.values(CONTRACTOR_STATUSES);

export const PROCESSING_STATUSES = {
  RUNNING: 1,
  FINISHED: 2,
  FAILED: 3,
  STOPPED: 4,
} as const;

export type PROCESSING_STATUSES_TYPE = Values<typeof PROCESSING_STATUSES>;

export const PROCESSING_STATUSES_LABELS = {
  [PROCESSING_STATUSES.RUNNING]: "Running",
  [PROCESSING_STATUSES.FINISHED]: "Finished",
  [PROCESSING_STATUSES.FAILED]: "Failed",
  [PROCESSING_STATUSES.STOPPED]: "Stopped",
};

export const PROCESSING_TYPES = {
  CONTRACTORS: 1,
  CONTRACTORS_UPLOAD: 2,
  CREATE_RATECARD: 3,
} as const;

export type PROCESSING_TYPES_TYPE = Values<typeof PROCESSING_TYPES>;

export const PROCESSING_TYPES_LABELS = {
  [PROCESSING_TYPES.CONTRACTORS]: "Contractors Refresh",
  [PROCESSING_TYPES.CONTRACTORS_UPLOAD]: "Contractors Upload",
  [PROCESSING_TYPES.CREATE_RATECARD]: "Create Ratecard",
};

export const PROGRAM_STATUSES = {
  PENDING: 1,
  ACTIVE: 2,
  INACTIVE: 3,
} as const;

export type PROGRAM_STATUSES_TYPE = Values<typeof PROGRAM_STATUSES>;

export const PROGRAM_STATUSES_LABELS = {
  [PROGRAM_STATUSES.PENDING]: "Pending",
  [PROGRAM_STATUSES.ACTIVE]: "Active",
  [PROGRAM_STATUSES.INACTIVE]: "Inactive",
};

export const EXPORT_TYPES = {
  DATA_EXPORT: 1,
  ANALYSIS_FILE_EXPORT: 2,
} as const;

export type EXPORT_TYPES_TYPE = Values<typeof EXPORT_TYPES>;

export const EXPORT_STATUSES = {
  PENDING: 1,
  RUNNING: 2,
  FINISHED: 3,
  FAILED: 4,
} as const;

export type EXPORT_STATUSES_TYPE = Values<typeof EXPORT_STATUSES>;

export const PROGRAM_ACCESS_STATUSES = {
  PENDING: 1,
  APPROVED: 2,
  DECLINED: 3,
} as const;

export type PROGRAM_ACCESS_STATUSES_TYPE = Values<typeof PROGRAM_ACCESS_STATUSES>;

export const PROGRAM_ACCESS_STATUSES_LABELS = {
  [PROGRAM_ACCESS_STATUSES.PENDING]: "Pending",
  [PROGRAM_ACCESS_STATUSES.APPROVED]: "Approved",
  [PROGRAM_ACCESS_STATUSES.DECLINED]: "Declined",
};

export const PROGRAM_ADMIN_ROLES = {
  CLIENT_ADMIN: 1,
  PT_ADMIN: 2,
} as const;

export type PROGRAM_ADMIN_ROLES_TYPE = Values<typeof PROGRAM_ADMIN_ROLES>;

// Client data types

export type ClientDataObject = {
  client_id: number;
  name: string;
};
export type ClientDataMap = ImmutableMap<ClientDataObject>;

// Program data types

export type ProgramDataObject = {
  id: number;
  client_id: number;
  user_id: number;
  user_name: string;
  created: Date;
  updated: Date;
  title: string;
  description: string;
  icon: string;
  is_sample: boolean;
  status: PROGRAM_STATUSES_TYPE;
  // relations
  stored_indexes: StoredIndexesDataList;
  access: ProgramAccessDataList;
};
export type ProgramDataMap = ImmutableMap<ProgramDataObject>;
export type ProgramsDataList = ImmutableList<ProgramDataMap>;
export type ProgramsDataOrderedMap = ImmutableOrderedMap<number, ProgramDataMap>;

// Program Admin data types

export type ProgramAdminDataObject = {
  id: number;
  user_id: number;
  user_name: string;
  email: string;
  role: PROGRAM_ADMIN_ROLES_TYPE;
  created: Date;
  updated: Date;
};
export type ProgramAdminDataMap = ImmutableMap<ProgramAdminDataObject>;

// Program Access data types

export type ProgramAccessDataObject = {
  id: number;
  user_id: number;
  user_name: string;
  status: PROGRAM_ACCESS_STATUSES_TYPE;
  created: Date;
  updated: Date;
};
export type ProgramAccessDataMap = ImmutableMap<ProgramAccessDataObject>;
export type ProgramAccessDataList = ImmutableList<ProgramAccessDataMap>;
export type ProgramAccessDataOrderedMap = ImmutableOrderedMap<
  number,
  ProgramAccessDataMap
>;

// Upload data types

export type ContractorsUploadDataObject = {
  id: number;
  user_id: number;
  user_name: string;
  created: Date;
  updated: Date;
  is_active: boolean;
  title: string;
  industry_id: number;
  autofill_description: boolean;
  file_name: string;
  file: string;
  dataset_length: number;
  status: PROCESSING_STATUSES_TYPE;
  // relations
  client: ClientDataMap;
  processing: ProcessingDataMap;
};
export type ContractorsUploadDataMap = ImmutableMap<ContractorsUploadDataObject>;
export type ContractorsUploadsDataList = ImmutableList<ContractorsUploadDataMap>;
export type ContractorsUploadsDataOrderedMap = ImmutableOrderedMap<
  number,
  ContractorsUploadDataMap
>;

// Collection/Title mappings data types

export type CollectionPublicTitleMappingsObject = Nullable<{
  job_collection_public_title: string;
  job_collection_public_title_id: number;
  job_collection_public_title_description: string;
}>;
export type CollectionPublicTitleMappingsMap =
  ImmutableMap<CollectionPublicTitleMappingsObject>;

export type CollectionMappingsObject = Nullable<{
  job_collection_id: number;
  job_collection_title: string;
  job_collection_slug: string;
  job_collection_url: string | null;
  job_title_id: number;
  job_title_match_type: JOB_TITLE_MATCH_TYPES_TYPE;
  job_title_created: boolean;
  taste_cleanup_required: boolean;
}> &
  CollectionPublicTitleMappingsObject;
export type CollectionMappingsMap = ImmutableMap<CollectionMappingsObject>;

// Location/Region/Currency mappings data types

export type CurrencyMappingsObject = Nullable<{
  currency_id: number;
  currency_code: string;
  currency_name: string;
  currency_symbol: string;
  currency_factor: number;
}>;
export type CurrencyMappingsMap = ImmutableMap<CurrencyMappingsObject>;

export type LocationMappingsObject = Nullable<{
  location_id: string;
  country_id: number;
}> &
  CurrencyMappingsObject;
export type LocationMappingsMap = ImmutableMap<LocationMappingsObject>;

export type RegionMappingsObject = Nullable<{
  region_id: number;
  country_id: number;
}> &
  CurrencyMappingsObject;
export type RegionMappingsMap = ImmutableMap<RegionMappingsObject>;

// Industry mappings data types

export type IndustryMappingsObject = Nullable<{
  industry_id: number;
  industry_title: string;
}>;
export type IndustryMappingsMap = ImmutableMap<IndustryMappingsObject>;

// Worker Type mappings types

export type WorkerTypesMappingsObject = Nullable<{
  worker_type_id: number;
  worker_type_title: string;
}>;
export type WorkerTypesMappingsMap = ImmutableMap<WorkerTypesMappingsObject>;

// Overall contractor mappings data types

export type ProcessedContractorDataObject = CollectionMappingsObject &
  LocationMappingsObject &
  RegionMappingsObject &
  WorkerTypesMappingsObject &
  IndustryMappingsObject & {
    created: Date;
    updated: Date;
    needs_review: boolean;
  };
export type ProcessedContractorDataMap = ImmutableMap<ProcessedContractorDataObject>;

// Market search data types

export type MarketSearchRatesDataObject = {
  pay_rate_min: number;
  pay_rate_mid: number;
  pay_rate_avg: number;
  pay_rate_max: number;

  bill_rate_min: number;
  bill_rate_mid: number;
  bill_rate_avg: number;
  bill_rate_max: number;

  markup_min: number;
  markup_mid: number;
  markup_avg: number;
  markup_max: number;
};
export type MarketSearchResultObject = MarketSearchRatesDataObject & {
  level: SKILLS_LEVELS_TYPE;
};
export type MarketSearchResultMap = ImmutableMap<MarketSearchResultObject>;
export type MarketSearchResultsList = ImmutableList<MarketSearchResultMap>;

// Market analysis data types

export type MarketAnalysisResultObject = {
  market_pay_rate_min: number;
  market_pay_rate_mid: number;
  market_pay_rate_avg: number;
  market_pay_rate_max: number;

  market_markup_min: number;
  market_markup_mid: number;
  market_markup_avg: number;
  market_markup_max: number;

  market_bill_rate_min: number;
  market_bill_rate_mid: number;
  market_bill_rate_avg: number;
  market_bill_rate_max: number;

  market_annual_salary_min: number;
  market_annual_salary_mid: number;
  market_annual_salary_avg: number;
  market_annual_salary_max: number;
};

export type MarketAnalysisVariancesResultObject = {
  market_pay_rate_min_variance: number;
  market_pay_rate_mid_variance: number;
  market_pay_rate_avg_variance: number;
  market_pay_rate_max_variance: number;

  market_markup_min_variance: number;
  market_markup_mid_variance: number;
  market_markup_avg_variance: number;
  market_markup_max_variance: number;

  market_bill_rate_min_variance: number;
  market_bill_rate_mid_variance: number;
  market_bill_rate_avg_variance: number;
  market_bill_rate_max_variance: number;

  market_annual_salary_min_variance: number;
  market_annual_salary_mid_variance: number;
  market_annual_salary_avg_variance: number;
  market_annual_salary_max_variance: number;
};

export type MarketAnalysisSavingsResultObject = {
  pay_rate_potential_savings: number;
  pay_rate_achieved_savings: number;
  bill_rate_potential_savings: number;
  bill_rate_achieved_savings: number;
  annual_salary_potential_savings: number;
  annual_salary_achieved_savings: number;
};

export type AllLevelsDatasetLengthObject = {
  junior_dataset_length: number;
  intermediate_dataset_length: number;
  senior_dataset_length: number;
  lead_dataset_length: number;
  guru_dataset_length: number;
};

export type AllMarketRatesObject = {
  junior_pay_rate_min: number;
  junior_pay_rate_mid: number;
  junior_pay_rate_avg: number;
  junior_pay_rate_max: number;

  junior_bill_rate_min: number;
  junior_bill_rate_mid: number;
  junior_bill_rate_avg: number;
  junior_bill_rate_max: number;

  junior_markup_min: number;
  junior_markup_mid: number;
  junior_markup_avg: number;
  junior_markup_max: number;

  //

  intermediate_pay_rate_min: number;
  intermediate_pay_rate_mid: number;
  intermediate_pay_rate_avg: number;
  intermediate_pay_rate_max: number;

  intermediate_bill_rate_min: number;
  intermediate_bill_rate_mid: number;
  intermediate_bill_rate_avg: number;
  intermediate_bill_rate_max: number;

  intermediate_markup_min: number;
  intermediate_markup_mid: number;
  intermediate_markup_avg: number;
  intermediate_markup_max: number;

  //

  senior_pay_rate_min: number;
  senior_pay_rate_mid: number;
  senior_pay_rate_avg: number;
  senior_pay_rate_max: number;

  senior_bill_rate_min: number;
  senior_bill_rate_mid: number;
  senior_bill_rate_avg: number;
  senior_bill_rate_max: number;

  senior_markup_min: number;
  senior_markup_mid: number;
  senior_markup_avg: number;
  senior_markup_max: number;

  //

  lead_pay_rate_min: number;
  lead_pay_rate_mid: number;
  lead_pay_rate_avg: number;
  lead_pay_rate_max: number;

  lead_bill_rate_min: number;
  lead_bill_rate_mid: number;
  lead_bill_rate_avg: number;
  lead_bill_rate_max: number;

  lead_markup_min: number;
  lead_markup_mid: number;
  lead_markup_avg: number;
  lead_markup_max: number;

  //

  guru_pay_rate_min: number;
  guru_pay_rate_mid: number;
  guru_pay_rate_avg: number;
  guru_pay_rate_max: number;

  guru_bill_rate_min: number;
  guru_bill_rate_mid: number;
  guru_bill_rate_avg: number;
  guru_bill_rate_max: number;

  guru_markup_min: number;
  guru_markup_mid: number;
  guru_markup_avg: number;
  guru_markup_max: number;
};

export type Val5000ValidationsObject = {
  active_surveys_count: number;
  inactive_surveys_count: number;
  total_surveys_count: number;
  validations_count: number;
};

export type MarketAnalysisDataObject = Nullable<MarketAnalysisResultObject> &
  Nullable<MarketAnalysisVariancesResultObject> &
  Nullable<MarketAnalysisSavingsResultObject> &
  Nullable<AllMarketRatesObject> &
  Nullable<AllLevelsDatasetLengthObject> &
  Nullable<Val5000ValidationsObject> & {
    market_level: SKILLS_LEVELS_TYPE | null;
    is_level_fixed: boolean;
    //
    created: Date;
    updated: Date;
    refresh_timestamp: Date;
  };
export type MarketAnalysisDataMap = ImmutableMap<MarketAnalysisDataObject>;

// Contractor data types

export type ProcessingMessageItemObject = { message: string };
export type ProcessingMessageItemMap = ImmutableMap<ProcessingMessageItemObject>;
export type ProcessingMessagesMap = ImmutableArbitraryMap<
  string,
  ProcessingMessageItemMap
>;

export type ContractorDataObject = {
  id: number;
  date_engaged: Date;
  category: string | null;
  source: string | null;
  job_family: string | null;
  job_title: string;
  job_description: string;
  country: string;
  state: string | null;
  city: string | null;
  region: string | null;
  is_global_supplier_search: false;
  rate_type: RATE_TYPES_TYPE;
  worker_type_title: string;
  pay_rate: number | null;
  markup: number | null;
  markup_amount: number | null;
  bill_rate: number | null;
  total_cost: number | null;
  annual_salary: number | null;
  burden_perc: number | null;
  manager: string | null;
  supplier: string | null;
  company_name: string | null;
  bonus_amount: number | null;
  //
  exp_level?: number | null;
  // processing
  status: CONTRACTOR_STATUSES_TYPE;
  errors: ProcessingMessagesMap | null;
  warnings: ProcessingMessagesMap | null;
  // relations
  client: ClientDataMap;
  upload: ContractorsUploadDataMap | null;
  processed: ProcessedContractorDataMap | null;
  market_analysis: MarketAnalysisDataMap | null;
};
export type ContractorDataMap = ImmutableMap<ContractorDataObject>;
export type ContractorsDataList = ImmutableList<ContractorDataMap>;
export type ContractorsDataOrderedMap = ImmutableOrderedMap<number, ContractorDataMap>;

// Stored index data types

export type StoredIndexTrendDataRates = {
  pay_rate_low: number;
  pay_rate_median: number;
  pay_rate_high: number;
  pay_rate_average: number;
  markup_low: number;
  markup_median: number;
  markup_high: number;
  markup_average: number;
  bill_rate_low: number;
  bill_rate_median: number;
  bill_rate_high: number;
  bill_rate_average: number;
  annual_salary_low: number;
  annual_salary_median: number;
  annual_salary_high: number;
  annual_salary_average: number;
};

export type StoredIndexTrendDataObject = Nullable<StoredIndexTrendDataRates> & {
  created: Date;
  updated: Date;
  freshness_timestamp: Date;
  dataset_length: number;
  available_dataset_length: number;
};

export type StoredIndexTrendDataMap = ImmutableMap<StoredIndexTrendDataObject>;

export type StoredIndexDataObject = {
  id: number;
  user_id: number;
  user_name: string;
  created: Date;
  updated: Date;
  is_active: boolean;
  title: string;
  display_currency_code: string;
  filters: FiltersConfigOrderedMap;
  dj_filters: FiltersQueryOrderedMap;
  visible_columns: ContractorsTableTabsVisibleColumnsMap;
  items_per_page: number;
  active_tab: TABLE_TABS_TYPES_TYPE;
  is_duplicate: boolean;
  // relations
  client: ClientDataMap;
  first_trend_data: StoredIndexTrendDataMap;
  last_trend_data: StoredIndexTrendDataMap;
  contractors: ContractorsDataList;
};
export type StoredIndexDataMap = ImmutableMap<StoredIndexDataObject>;
export type StoredIndexesDataList = ImmutableList<StoredIndexDataMap>;
export type StoredIndexesDataOrderedMap = ImmutableOrderedMap<number, StoredIndexDataMap>;

export type StoredIndexDataCreateView = {
  userId: number;
  userName: string;
  filters: FiltersConfigOrderedMap;
  filtersQuery: FiltersQueryOrderedMap;
  visibleColumns: ContractorsTableTabsVisibleColumnsMap;
  itemsPerPage: number;
  activeTab: TABLE_TABS_TYPES_TYPE;
};

export type StoredIndexDuplicateData = {
  new_index_id: number;
  new_index_name: string;
};
export type StoredIndexDuplicateMap = ImmutableMap<StoredIndexDuplicateData>;

// Processing data types

export type ProcessingDataObject = {
  id: number;
  client_id: number;
  client_name: string;
  user_id: number;
  user_name: string;
  program_id: number;
  program_title: string;
  stored_index_id: number | null;
  upload_id: number | null;
  created: Date;
  updated: Date;
  type: PROCESSING_TYPES_TYPE;
  status: PROCESSING_STATUSES_TYPE;
  running_items: number;
  finished_items: number;
  failed_items: number;
  total_items: number;
  started_at: Date;
  finished_at: Date | null;
  time_spent: number | null;
  estimated_item_time: number | null;
  estimated_time_left: number | null;
  queue_position_number: number | null;
  // relations
  program: ProgramDataMap;
  stored_index: StoredIndexDataMap | null;
  upload?: ContractorsUploadDataMap | null;
};
export type ProcessingDataMap = ImmutableMap<ProcessingDataObject>;
export type ProcessingsDataList = ImmutableList<ProcessingDataMap>;
export type ProcessingsDataOrderedMap = ImmutableOrderedMap<number, ProcessingDataMap>;

// Export data types       TODO move to the export modal component

export type ExportDataObject = {
  id: string;
  type: EXPORT_TYPES_TYPE;
  status: EXPORT_STATUSES_TYPE;
  file_name: string;
  file_url: string;
  dataset_length: number;
  three_levels_banding: boolean;
  page: number | null;
  page_size: number | null;
  created: Date;
  updated: Date;
  started_at: Date;
  finished_at: Date | null;
};

export type ExportDataMap = ImmutableMap<ExportDataObject>;
export type ExportsDataList = ImmutableList<ExportDataMap>;

// Sample Videos data types

export type SampleVideoDataObject = {
  id: number;
  title: string;
  src: string;
  thumbnail_src?: string;
  vimeo_src?: string;
};
export type SampleVideoDataMap = ImmutableMap<SampleVideoDataObject>;
export type SampleVideosDataList = ImmutableList<SampleVideoDataMap>;

// Currencies data types

export type CurrencyObject = CurrencyData;
export type CurrencyMap = ImmutableMap<CurrencyObject>;
export type AllCurrenciesMap = ImmutableArbitraryMap<string, CurrencyMap>;

// Discovery search data types

export type DiscoverySearchResultObject = {
  job_collection_id: number;
  job_collection_title: string;
  job_collection_public_title_id: number | null;
  job_collection_public_title: string | null;
  job_collection_public_title_description: string | null;
  first_label: string | null;
  first_label_description: string | null;
  labels_count: number;
  labels_string: string;
  locations_count: number;
  regions_count: number;
  suppliers_count: number;
  managers_count: number;
  rows_count: number;
};
export type DiscoverySearchResultMap = ImmutableMap<DiscoverySearchResultObject>;
export type DiscoverySearchResultsList = ImmutableList<DiscoverySearchResultMap>;

export type DiscoverySearchResultTotalsObject = Nullable<{
  rows_count: number;
  labels_count: number;
  locations_count: number;
  regions_count: number;
  suppliers_count: number;
  managers_count: number;
}>;
export type DiscoverySearchResultTotalsMap =
  ImmutableMap<DiscoverySearchResultTotalsObject>;

// Jobs validation data types

export type JobValidationErrorObject = {
  row: number;
  col: string;
  error: string;
};
export type JobValidationErrorMap = ImmutableMap<JobValidationErrorObject>;
export type JobValidationErrorsList = ImmutableList<JobValidationErrorMap>;

export type JobValidationWarningObject = {
  row: number;
  col: string;
  warning: string;
};
export type JobValidationWarningMap = ImmutableMap<JobValidationWarningObject>;
export type JobValidationWarningsList = ImmutableList<JobValidationWarningMap>;

export interface JobValidationDataObject {
  rate_type: RATE_TYPES_TYPE;
  rate_type_string: string;
  category: string | null;
  source: string | null;
  title: string;
  description: string;
  //
  industry_id: number;
  industry_name: string;
  //
  country: string;
  state: string | null;
  city: string | null;
  country_id: number;
  locations_ids: string[] | null;
  region: string | null;
  region_id: number | null;
  is_global_supplier_search: boolean;
  //
  currency_code?: string;
  currency_id?: number;
  currency_name?: string;
  currency_symbol?: string;
  //
  job_title_id: number | null;
  collection_id: number | null;
  collection_title: string | null;
  collection_slug: string;
  collection_public_title: string;
  collection_public_description: string;
  use_public_title: boolean;
  collection_removed?: boolean;
  //
  errors?: JobValidationErrorsList;
  warnings?: JobValidationWarningsList;
}
export type JobValidationDataMap = ImmutableMap<JobValidationDataObject>;
export type JobsValidationsOrderedMap = ImmutableOrderedMap<number, JobValidationDataMap>;
export type JobValidationDataList = ImmutableList<JobValidationDataMap>;

// Contractors table structures

export type ContractorsTableTabsVisibleColumnsMap = ImmutableArbitraryMap<
  TABLE_TABS_TYPES_TYPE,
  VisibleColumnsSet
>;

export type ContractorsTableConfigObject = RestfulTableConfigObject & {
  activeTab: TABLE_TABS_TYPES_TYPE;
  visibleColumns: ContractorsTableTabsVisibleColumnsMap;
};
export type ContractorsTableConfigMap = ImmutableMap<ContractorsTableConfigObject>;

export type ContractorsTableDataStateObject =
  RestfulTableDataStateObject<ContractorDataMap> & {
    activeTab: TABLE_TABS_TYPES_TYPE;
    visibleColumns: ContractorsTableTabsVisibleColumnsMap;
    isSelectingRows: boolean;
    isBulkUpdateSelectingRows: boolean;
    selectedRows: ContractorsDataOrderedMap;
    loaded: boolean;
  };

export type ContractorsTableDataProvider = (
  urlQuery: UrlQueryObject,
  filtersQuery: FiltersQueryObject,
  nextStateUpdates?: Partial<ContractorsTableDataStateObject>
) => Promise<Partial<ContractorsTableDataStateObject>>;
export type ContractorsTableFiltersDataProvider = FiltersDataProviderRestful;

export interface TableColumnsGroupSpecsObject<RD = RowData>
  extends Omit<GroupConfigObject, "columns"> {
  type: "group";
  columns: TableColumnSpecsObject<RD>[];
}
export interface TableColumnSpecsObject<RD = RowData> extends ColumnConfigObject<RD> {
  type: "column";
  valueLevel?: SKILLS_LEVELS_TYPE; // for market rates columns only
  valueType?: CONTINGENT_VALUES_TYPES_TYPE; // for market rates columns only
  valueSubtype?: VALUES_SUBTYPES_TYPE; // for market rates columns only
}

export type DecimalValueFormatterFunc = ValueFormatterFunc<ContractorDataMap, number>;
