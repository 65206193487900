// @flow

import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
// import Headlines from "./components/Headlines";
import RecentActivity from "./components/RecentActivity";
import RateCardTrack from "./components/RateCardTrack";
import RateCardSearches from "./components/RateCardSearches";
// import Industry from "./components/Industry";
import Modal from "../ratecards/components/Modal";
import ContainerSection from "../ratecards/components/ContainerSection";
import SingleSelect from "../../components/selects/SingleSelect";
import RateCardsList from "../ratecards/components/RateCardsList";
import SearchBox from "../ratecards/components/SearchBox";
// import FeaturedBuyRate from "./components/FeaturedBuyRate";
import PunchoutsList from "./components/PunchoutsList";
import MobXStore from "../../stores/mobx/MobXStore.js";
import IndexStore from "../../stores/mobx/IndexStore.js";
import SessionInfo from "../../models/SessionInfo";
import Button from "../../components/lib/Button";
import { Card } from "../../components/lib/Card";
import Inline from "../../components/lib/Inline";

type Props = {
  store: MobXStore,
  sessionInfo: SessionInfo,
};

class Index extends Component<Props> {
  constructor(props: Props) {
    super(props);

    const store: IndexStore = props.store.indexStore;
    store.savedSearch = [];
    store.searches = [];
    store.userFeeds = [];
    store.featuredRateCard = "";
    store.featuredSearch = "";
    store.featuredPunchout = "";
    store.selectedSearchId = "";
    store.selectedPunchoutId = "";
    store.feed = [];
    store.changes = [];
    store.punchoutList = [];
    store.feedIndustry = [{ id: 1, name: "All" }];
    store.indValue = [{ id: 1, name: "All" }];
    store.rateCards = [];
    store.counter = 0;
    store.featuredBuyRate = "";
    store.featuredBuyRatename = "";
  }

  change(store: IndexStore) {
    store.changeCounter();
  }

  handleIndustryChange(industries: any[], store: IndexStore) {
    store.updateIndustryValue(industries);
  }

  componentDidMount() {
    const user = this.props.sessionInfo.user;
    let showBlocks = false;
    if (user && (user.isV6User() || user.isPTAdmin())) {
      showBlocks = true;
    }
    if (showBlocks) {
      this.props.store.indexStore.getFeaturedRateCard();
      this.props.store.indexStore.getRecentActivities();
      this.props.store.indexStore.getFeaturedBuyRate();
    }
    this.props.store.indexStore.getFeed();
    this.props.store.indexStore.getUserFeed();
    const feeds = localStorage.getItem("userFeeds");
    if (!feeds) this.props.store.indexStore.getUserFeed();
    //this.props.store.indexStore.getFeaturedPunchout();

    // setInterval(this.change.bind(this,this.props.store.indexStore), 5000);
  }

  render() {
    const store = this.props.store.indexStore;
    let selectedIndustries = [];
    let rateCardTrack = "";
    const user = this.props.sessionInfo.user;
    let showBlocks = false;
    if (user && (user.isV6User() || user.isPTAdmin())) {
      showBlocks = true;
    }

    store.indValue.map((e) => {
      if (e) {
        var object = {
          value: e.id,
          label: e.name,
        };
        selectedIndustries.push(object);
      }
      return true;
    });

    if (store.featuredSearch) {
      rateCardTrack = (
        <div className="rc-container">
          <RateCardTrack
            store={store}
            featuredSearch={store.featuredSearch}
            changes={store.changes}
          />
        </div>
      );
    }

    return (
      <div className="view list">
        <div className="row">
          <div className="col-xs-6">
            {/* <Industry
              store={store}
              industry={store.feed}
              counter={store.counter}
              displayFeedIndustry={store.displayFeedIndustry}
            />
            <div className="rc-container">
              <Headlines store={store} />
            </div> */}
            {showBlocks && (
              <div className="rc-container">
                <RecentActivity savedSearches={store.savedSearch} store={store} />
              </div>
            )}
          </div>
          {showBlocks && (
            <div className="col-xs-6">
              {rateCardTrack}
              <Card css={{ padding: "20px", marginTop: "15px" }}>
                <RateCardSearches
                  store={store}
                  searches={store.searches}
                  featuredSearch={store.featuredSearch}
                  pagination={store.pagination}
                  displayRateCard={store.displayRateCard}
                />
              </Card>
              {/* <Card css={{ padding: "20px", marginTop: "15px" }}>
                <FeaturedBuyRate
                  store={store}
                  searches={store.searches}
                  featuredSearch={store.featuredSearch}
                  pagination={store.pagination}
                  displayPunchouts={store.displayPunchouts}
                />
              </Card> */}
            </div>
          )}
        </div>

        <Modal
          show={store.feedIndustryModal.show}
          onHide={store.feedIndustryModal.hideModal}
        >
          <ContainerSection className="header">
            <h4>Choose Feeds Industry</h4>
          </ContainerSection>
          <ContainerSection>
            <label htmlFor="industry" className="block">
              Industry:
            </label>
            <div className="dashboard-cur">
              <SingleSelect
                options={store.feedIndustry}
                text="Industry"
                name="industry"
                onChange={(industry) => this.handleIndustryChange([industry], store)}
                value={store.indValue[0]}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
              />
            </div>
          </ContainerSection>
          <ContainerSection className="footer">
            <Inline>
              <Button size="large" onClick={store.feedIndustryModal.hideModal}>
                Cancel
              </Button>
              <Button
                size="large"
                color="brand"
                onClick={store.saveFeedIndustry}
                disabled={store.indValue.length === 0}
              >
                Save Changes
              </Button>
            </Inline>
          </ContainerSection>
        </Modal>

        <Modal
          show={store.featuredRateCardModal.show}
          onHide={store.featuredRateCardModal.hideModal}
          innerStyle={{
            width: "90vw",
            maxHeight: "90vh",
          }}
        >
          <div className="container-section header">
            <h4>Choose Rate Card to Track</h4>
          </div>

          <div className="rc-viewport overlap-lr-padding">
            <ContainerSection className="overlap-t-padding">
              <div className="header-action-bar">
                <SearchBox
                  value={store.rateCardsListStore.rateCardLabelFilter.textToLookFor}
                  onChange={
                    store.rateCardsListStore.rateCardLabelFilter.onTextToLookForChange
                  }
                  onSearch={store.rateCardsListStore.rateCardLabelFilter.onApply}
                  style={{ width: "260px" }}
                />
              </div>

              <RateCardsList store={store.rateCardsListStore} />
            </ContainerSection>
          </div>
          <ContainerSection className="footer">
            <Inline css={{ justifyContent: "flex-end" }}>
              <Button size="large" onClick={store.featuredRateCardModal.hideModal}>
                Cancel
              </Button>
              <Button color="brand" size="large" onClick={store.setFeaturedRateCard}>
                Save Changes
              </Button>
            </Inline>
          </ContainerSection>
        </Modal>

        <Modal
          show={store.featuredBuyRateModal.show}
          onHide={store.featuredBuyRateModal.hideModal}
          innerStyle={{
            width: "90vw",
            maxHeight: "90vh",
          }}
        >
          <div className="container-section header">
            <h4>Choose Punchout to Track</h4>
          </div>
          <div className="rc-viewport overlap-lr-padding">
            <ContainerSection className="overlap-t-padding">
              <div className="header-action-bar">
                <SearchBox
                  value={
                    store.dashboardPunchoutsListStore.punchoutLabelFilter.textToLookFor
                  }
                  onChange={
                    store.dashboardPunchoutsListStore.punchoutLabelFilter
                      .onTextToLookForChange
                  }
                  onSearch={store.dashboardPunchoutsListStore.punchoutLabelFilter.onApply}
                  style={{ width: "260px" }}
                />
              </div>
              <PunchoutsList store={store.dashboardPunchoutsListStore} />
            </ContainerSection>
          </div>
          <ContainerSection className="footer">
            <Inline css={{ justifyContent: "flex-end" }}>
              <Button size="large" onClick={store.featuredBuyRateModal.hideModal}>
                Cancel
              </Button>
              <Button size="large" color="brand" onClick={store.setFeaturedBuyRate}>
                Save Changes
              </Button>
            </Inline>
          </ContainerSection>
        </Modal>
      </div>
    );
  }
}

Index.propTypes = {
  store: PropTypes.object.isRequired,
};

export default observer(Index);
