import graphql from "babel-plugin-relay/macro";
import React, { useMemo } from "react";
// design-system
import Inline from "../../../components/lib/Inline";
import Stack from "../../../components/lib/Stack";
import Tag from "../../../components/lib/Tag";
// relay
import { useFragment } from "react-relay";
import { Label } from "../../../components/lib/Checkbox";
import { Placeholder } from "../../../components/lib/Placeholder";
import { useRateSearchFormStore } from "../../../stores/RateSearchForm";
import { createMap, isNonNullable } from "../../../utils/hashmap";
import { SupportedCountries_viewer$key } from "./__generated__/SupportedCountries_viewer.graphql";

const gql = graphql`
  fragment SupportedCountries_viewer on Viewer {
    countries {
      id @required(action: NONE)
      isoCode @required(action: NONE)
      name @required(action: NONE)
      locationId @required(action: NONE)
    }
  }
`;

type Props = {
  viewer: SupportedCountries_viewer$key;
  isSideMenu: boolean;
};

export function SupportedCountries({ viewer, isSideMenu }: Props) {
  const data = useFragment(gql, viewer);

  const jobCountries = useRateSearchFormStore((s) => s.getSupportedCountries());

  const { certified, adhoc } = useMemo(() => {
    const countries = (data.countries || []).filter(isNonNullable);
    const hashmap = createMap(countries, "locationId");

    return {
      certified: jobCountries.certified
        .filter((id) => hashmap.has(id))
        .map((id) => hashmap.get(id)),
      adhoc: jobCountries.adhoc
        .filter((id) => hashmap.has(id))
        .map((id) => hashmap.get(id)),
    };
  }, [data, jobCountries]);

  const size = isSideMenu ? "extra-small" : "small";

  if (!certified?.length && !adhoc?.length) {
    return null;
  }

  return (
    <Stack fill css={{ alignItems: "flex-start" }}>
      <Label>Supported Countries</Label>
      <Inline css={{ gap: "$2" }}>
        {certified && certified?.length > 0 && (
          <Tag icon="globe-americas" size={size} color="brand">
            {certified.map((country) => country?.name).join(", ")}
          </Tag>
        )}
        {adhoc && adhoc?.length > 0 && (
          <Tag icon="globe-americas" color="danger" size={size}>
            {adhoc.map((country) => country?.name).join(", ")}
          </Tag>
        )}
      </Inline>
    </Stack>
  );
}

export function SupportedCountriesLoader() {
  return (
    <Stack fill css={{ alignItems: "flex-start", gap: "$3" }}>
      <Label>Supported Countries</Label>
      <Inline css={{ gap: "$2" }}>
        <Placeholder height={36} width={120} css={{ borderRadius: "$full" }} />
        <Placeholder height={36} width={200} css={{ borderRadius: "$full" }} />
      </Inline>
    </Stack>
  );
}
