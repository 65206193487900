// @flow
import { extendObservable, action, runInAction } from "mobx";
import axios from "axios";
import NetworkState from "../../../models/NetworkState";
import ModalState from "../../../models/ModalState";
import TagList from "../../../models/TagList";
import MessageState from "../../../models/MessageState";
import CurrentUser from "../../../models/User";
import type MobXStore from "../MobXStore";
import type { FetchGraphQL } from "../../../App";

class TagManagementDetailStore {
  router: Object;
  network: NetworkState;
  confirmDeleteModal: ModalState;
  negDetail: NegotiationWorksheet;
  renameTagModal: ModalState;
  onNewTagNameChange: (Event) => void;
  messaging: MessageState;
  showRenameTagModal: () => void;
  tagId: number;
  showHelpModal: ModalState;
  handleStartEdit: () => void;
  handleStopEdit: () => void;
  isEditing: ?boolean;
  updatedSearches: Array<any>;
  updatedRegions: Array<any>;
  onAddSearchesClick: () => void;
  searchAddTagModal: ModalState;
  regionAddTagModal: ModalState;
  jobAddTagModal: ModalState;
  onAddJobsClick: () => void;
  updatedJobs: Array<any>;
  rateCardAddTagModal: ModalState;
  onAddRateCardClick: () => void;
  updatedRateCards: Array<any>;
  pceAddTagModal: ModalState;
  updatedPCE: Array<any>;
  nwsAddTagModal: ModalState;
  updatedNWS: Array<any>;
  shareModal: ModalState;
  networkShareUsers: NetworkState;
  currentUser: CurrentUser;
  tagOwnerShip: () => void;
  hasOwnership: boolean;
  store: MobXStore;
  fetchGraphQL: FetchGraphQL;

  constructor(fetchGraphQL: FetchGraphQL, store: MobXStore) {
    this.fetchGraphQL = fetchGraphQL;
    this.store = store;
    this.router = null;

    extendObservable(this, {
      tagId: null,
      search: null,
      confirmDeleteModal: new ModalState(),
      network: new NetworkState(),
      tagDetail: null,
      renameTagModal: new ModalState(),
      newTagName: "",
      messaging: new MessageState(),
      showHelpModal: new ModalState(),
      isEditing: false,
      updatedSearches: [],
      updatedRegions: [],
      updatedJobs: [],
      updatedRateCards: [],
      searchAddTagModal: new ModalState(),
      regionAddTagModal: new ModalState(),
      jobAddTagModal: new ModalState(),
      rateCardAddTagModal: new ModalState(),
      pceAddTagModal: new ModalState(),
      updatedPCE: [],
      updatedNWS: [],
      nwsAddTagModal: new ModalState(),
      shareModal: new ModalState(),
      networkShareUsers: new NetworkState(),
      hasOwnership: false,
    });
    this.currentUser = null;
    this.fetchTagDetail = action(this.fetchTagDetail.bind(this));
    this.onNewTagNameChange = action(this.onNewTagNameChange.bind(this));
    this.handleStopEdit = action(this.handleStopEdit.bind(this));
    this.handleStartEdit = action(this.handleStartEdit.bind(this));
    this.tagOwnerShip = action(this.tagOwnerShip.bind(this));
  }

  onNewTagNameChange(e: Event) {
    this.newTagName = e.target.value;
  }

  handleStartEdit() {
    this.isEditing = true;
    this.store.contentListStore.handleStartEdit();
  }

  handleStopEdit() {
    this.isEditing = false;
    this.store.contentListStore.handleStopEdit();
  }

  tagOwnerShip() {
    if (this.tagDetail.owner && this.tagDetail.owner.userId) {
      this.hasOwnership =
        String(this.tagDetail.owner.userId) === String(this.currentUser.userId);
    } else {
      this.hasOwnership = true;
    }
  }

  async fetchTagDetail() {
    let res = null;
    if (!/^\d+$/.test(this.tagId)) {
      if (this.router) {
        this.router.push({
          pathname: "/404NotFound",
          query: this.router.query,
        });
      }
      return res;
    }
    let params: string[] = [];
    let args = [];
    let variables = {};
    //let filtersCriteria: string[] = [];

    let tagId = this.tagId;

    //let sortCriteria: string[] = [];

    params.push("$id: Int!");
    args.push("id: $id");
    variables.id = tagId;

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");
    //const queryFiltersCriteria = filtersCriteria.join(', ');

    const query = `
      query tagDetail(${queryParams}) {
        viewer {
          user{
              firstName
              lastName
              userId
              username
              email
            }
          tag(${queryArgs},section : ADMIN) {
            name
            created
            tagId
            owner{
              firstName
              lastName
              email
              userId
            }
            sharedWith{
              firstName
              lastName
              email
              userId
            }
          }
         }
        }
        `;

    this.network.error = null;
    this.network.loading = true;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting Tag Detail", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Tag Detail query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    runInAction("fetchTag--success", () => {
      this.network.loading = false;
      this.network.error = null;
      //const node = res.data.viewer.tag;
      if (this.network.logGraphQLError("Get Search query", res)) {
        // TODO: Display user friendly error message
        return;
      }
      if (!res.data.viewer.tag) {
        if (this.router) {
          this.router.push({
            pathname: "/404NotFound",
            query: this.router.query,
          });
        }
        return;
      }
      this.currentUser = new CurrentUser(this, res.data.viewer.user);
      this.tagDetail = new TagList(this, res.data.viewer.tag);
      this.tagOwnerShip();
    });
  }
}

export default TagManagementDetailStore;
