// @flow

import R from "ramda";
import { extendObservable, action, runInAction, computed, observable } from "mobx";
import React from "react";
import type { Element } from "react";
import BatchSearch from "../../models/BatchSearch";
import CurrentUser from "../../models/User";
import { BatchSearchRateCardLabelFilter } from "../../models/FilterState";
import NetworkState from "../../models/NetworkState";
import PaginationState from "../../models/PaginationState";
import FilterObject, { FILTER_COLUMN } from "../../models/Filter";
import Sort from "../../models/Sort";
import ModalState from "../../models/ModalState";
import { consolidateAppliedFilters, createTasteGraphQLWrapper } from "./SupportFunctions";
import type { DjangoFilterObject, FilterColumn } from "../../models/Filter";
import type { GraphQLQuery } from "../../models/GraphQL";
import type { FetchAPI, FetchGraphQL } from "../../App";
import type MobXStore from "./MobXStore";
import type { PageQuery, PaginationInfo } from "../../models/PaginationState";

export class BatchSearchJLListComponentStore {
  router: ?Object;
  fetchTasteGraphQL: FetchGraphQL;
  fetchGraphQL: FetchGraphQL;
  fetchAPI: FetchAPI;
  mobXStore: MobXStore;
  network: NetworkState;
  pagination: PaginationState;

  batchSearches: BatchSearch[];
  batchSearchesViewState: Object;
  batchSearchesView: BatchSearch[];

  currentUser: CurrentUser;
  isEditing: ?boolean;
  allOnPageSelected: boolean;
  allSelected: boolean;
  allowMultipleItemSelection: boolean;
  selectedCount: number;

  batchSearchRateCardLabelFilter: BatchSearchRateCardLabelFilter;

  defaultFilters: { [key: FilterColumn]: DjangoFilterObject };
  appliedFilters: { [key: FilterColumn]: DjangoFilterObject };
  appliedSorts: { [key: FilterColumn]: Sort };
  appliedSortsOrder: Array<FilterColumn>;
  isFiltered: boolean;

  getBatchSearches: (PageQuery) => Promise<PaginationInfo>;
  applyDefaultFilter: (FilterColumn, FilterObject) => void;
  handleStartEdit: () => void;
  handleNewBatchSearch: () => void;
  handleStopEdit: () => void;
  toggleSelectAllPage: (Object) => void;
  selectAllPage: (Event) => void;
  deselectAllPage: (Event) => void;
  toggleAllItems: () => void;
  clearAllSelections: () => void;
  getSelectedBatchSearches: () => Array<string>;
  getFirstSelectedBatchSearch: () => Object;

  getFilterCriteriaQuery: (FilterColumn) => GraphQLQuery;
  processFilterCriteria: (FilterColumn, Object) => Array<Object>;
  applyFilter: (FilterColumn, FilterObject) => void;
  removeFilter: (FilterColumn) => void;
  applySort: (FilterColumn, Sort) => void;
  removeSort: (FilterColumn) => void;
  filterBatchSearches: () => void;
  clearFilters: () => void;
  applyDefaultSort: () => void;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI, mobXStore: MobXStore) {
    this.router = null;
    this.fetchTasteGraphQL = createTasteGraphQLWrapper(fetchAPI);
    this.fetchGraphQL = fetchGraphQL;
    this.fetchAPI = fetchAPI;
    this.mobXStore = mobXStore;

    // NOTE: Bound early to pass into pagination & filter state
    this.getBatchSearches = action(this.getBatchSearches.bind(this));

    this.getFilterCriteriaQuery = action(this.getFilterCriteriaQuery.bind(this));
    this.processFilterCriteria = action(this.processFilterCriteria.bind(this));
    this.applyFilter = action(this.applyFilter.bind(this));
    this.applySort = action(this.applySort.bind(this));
    this.removeFilter = action(this.removeFilter.bind(this));
    this.removeSort = action(this.removeSort.bind(this));

    extendObservable(this, {
      network: new NetworkState(),
      pagination: new PaginationState(this.getBatchSearches, 25),
      batchSearches: [],
      batchSearchesViewState: observable.map({}),
      batchSearchesView: computed(() => {
        return this.batchSearches.map((batchSearch) => {
          if (this.batchSearchesViewState.has(batchSearch.id)) {
            batchSearch.viewState = this.batchSearchesViewState.get(batchSearch.id);

            return batchSearch;
          }

          return batchSearch;
        });
      }),
      isEditing: null, // we start with null so some view elements be hidden initially
      isFiltered: false,
      allSelected: false,
      allowMultipleItemSelection: true,
      selectedCount: computed(() => {
        const selectedValues = this.batchSearchesView.map(
          (batchSearch) => batchSearch.viewState.selected
        );

        if (this.allSelected) {
          return this.pagination.totalCount;
        }

        let count = 0;

        selectedValues.forEach((v) => {
          if (v) {
            count += 1;
          }
        });

        return count;
      }),
      allOnPageSelected: computed(() => {
        const allTrue = R.all(R.equals(true));
        const selectedValues = this.batchSearchesView.map(
          (batchSearch) => batchSearch.viewState.selected
        );

        if (selectedValues.length === 0) {
          return false;
        }

        return allTrue(selectedValues);
      }),
      defaultFilters: {},
      appliedFilters: {},
      appliedSorts: {},
      appliedSortsOrder: observable.shallow([]),
      batchSearchRateCardLabelFilter: new BatchSearchRateCardLabelFilter(
        this,
        FILTER_COLUMN.BATCH_SEARCH_RATE_CARD_LABEL,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
    });

    this.currentUser = null;

    this.applyDefaultFilter = action(this.applyDefaultFilter.bind(this));
    this.handleStartEdit = action(this.handleStartEdit.bind(this));
    this.handleNewBatchSearch = action(this.handleNewBatchSearch.bind(this));
    this.handleStopEdit = action(this.handleStopEdit.bind(this));
    this.toggleSelectAllPage = action(this.toggleSelectAllPage.bind(this));
    this.selectAllPage = action(this.selectAllPage.bind(this));
    this.deselectAllPage = action(this.deselectAllPage.bind(this));
    this.toggleAllItems = action(this.toggleAllItems.bind(this));
    this.clearAllSelections = action(this.clearAllSelections.bind(this));
    this.getSelectedBatchSearches = action(this.getSelectedBatchSearches.bind(this));
    this.getFirstSelectedBatchSearch = action(
      this.getFirstSelectedBatchSearch.bind(this)
    );

    this.filterBatchSearches = action(this.filterBatchSearches.bind(this));
    this.clearFilters = action(this.clearFilters.bind(this));
    this.applyDefaultSort = action(this.applyDefaultSort.bind(this));

    this.applyDefaultSort();
  }

  applyDefaultSort() {
    // this.createdOnFilter.sortState.direction = SORT_DIRECTION.DESC;
    // this.createdOnFilter.sort = this.createdOnFilter.buildQuerySort();
    // this.applySort(this.createdOnFilter.column, this.createdOnFilter.sort);
  }

  handleStartEdit() {
    this.isEditing = true;
    this.batchSearchesView.forEach((batchSearch) => batchSearch.toggleEdit());
  }

  handleNewBatchSearch() {
    this.mobXStore.batchSearchJLCreateStore.resetAll();
    if (!this.router) throw new Error("Can't navigate to create view. Router not set.");
    this.router.push("/batch-searches/create");
  }

  handleStopEdit() {
    this.isEditing = false;
    this.allSelected = false;
    this.batchSearchesViewState.forEach((viewState) => {
      viewState.selected = false;
      viewState.editing = false;
    });
  }

  toggleSelectAllPage(e: Object) {
    if (!this.allowMultipleItemSelection) return;

    const setValue = !this.allOnPageSelected;

    this.batchSearchesView.forEach((batchSearch) => {
      batchSearch.toggleSelected(e, null, null, setValue);
    });

    // When All items selected flag is up, clear selection
    if (setValue === false && this.allSelected) this.allSelected = false;
  }

  selectAllPage(e: Event) {
    this.batchSearchesView.forEach((batchSearch) => {
      batchSearch.toggleSelected(e, null, null, true);
    });
  }

  deselectAllPage(e: Event) {
    this.batchSearchesView.forEach((batchSearch) => {
      batchSearch.toggleSelected(e, null, null, false);
    });

    this.allSelected = false;
  }

  toggleAllItems() {
    if (!this.allowMultipleItemSelection) return;

    this.allSelected = !this.allSelected;

    if (this.allSelected === false) {
      this.batchSearchesViewState.forEach((value) => {
        value.selected = false;
      });
    }
  }

  clearAllSelections() {
    this.allSelected = false;
    this.batchSearchesViewState.forEach((value) => {
      value.selected = false;
    });
  }

  getFilterCriteriaQuery(column: FilterColumn): GraphQLQuery {
    switch (column) {
      // case FILTER_COLUMN.RATE_TYPE:
      //   return {
      //     query: rateTypesCriteriaQuery,
      //     variables: {},
      //   };
      //
      // case FILTER_COLUMN.CREATED_BY:
      //   return {
      //     query: ownersCriteriaQuery,
      //     variables: {},
      //   };

      default:
        return null;
    }
  }

  processFilterCriteria(column: FilterColumn, payload: Object): ?Array<Object> {
    switch (column) {
      default:
        return null;
    }
  }

  applyFilter(column: FilterColumn, filter: FilterObject) {
    this.appliedFilters[column] = filter;
    this.isFiltered = true;
  }

  applyDefaultFilter(column: FilterColumn, filter: FilterObject) {
    this.defaultFilters[column] = filter;
  }

  removeFilter(column: FilterColumn) {
    delete this.appliedFilters[column];

    let entries = Object.entries(this.appliedFilters);
    if (!entries.length) this.isFiltered = false;
  }

  applySort(column: FilterColumn, sort: Sort) {
    this.appliedSorts[column] = sort;

    const index = this.appliedSortsOrder.indexOf(column);
    if (index === -1) this.appliedSortsOrder.push(column);
  }

  removeSort(column: FilterColumn) {
    delete this.appliedSorts[column];

    const index = this.appliedSortsOrder.indexOf(column);
    if (index > -1) this.appliedSortsOrder.splice(index, 1);
  }

  clearFilters() {
    this.batchSearchRateCardLabelFilter = new BatchSearchRateCardLabelFilter(
      this,
      FILTER_COLUMN.BATCH_SEARCH_RATE_CARD_LABEL,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );

    this.appliedFilters = observable({});
    this.appliedSorts = observable({});
    this.appliedSortsOrder.length = 0;
    this.isFiltered = false;

    return this.pagination.goFetch();
  }

  filterBatchSearches() {
    return this.pagination.goFetch();
  }

  getSelectedBatchSearches(): Array<string> {
    const batchSearches = this.batchSearchesViewState;

    let selectedBatchSearches = [];

    batchSearches.forEach((value, key) => {
      if (value.selected) {
        selectedBatchSearches.push(key);
      }
    });

    return selectedBatchSearches;
  }

  getFirstSelectedBatchSearch() {
    for (let j = 0; j < this.batchSearchesView.length; j++) {
      if (this.batchSearches[j].viewState.selected) return this.batchSearches[j];
    }

    return null;
  }

  async getBatchSearches(pageQuery: PageQuery): Promise<PaginationInfo> {
    // let result = null;
    let params: string[] = pageQuery.params;
    let args = pageQuery.args;
    let variables = pageQuery.variables;
    let filtersCriteria: string[] = [];

    consolidateAppliedFilters(this.appliedFilters, params, filtersCriteria, variables);

    // NOTE: applied filters could override default filters, handle this if needed
    consolidateAppliedFilters(this.defaultFilters, params, filtersCriteria, variables);

    if (FILTER_COLUMN.BATCH_SEARCH_RATE_CARD_LABEL in this.appliedFilters) {
      const labelFilter = this.appliedFilters[FILTER_COLUMN.BATCH_SEARCH_RATE_CARD_LABEL];
      params.push("$search: String!");
      args.push("search: $search");
      Object.assign(variables, labelFilter);
    }

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");
    const queryFiltersCriteria = filtersCriteria.join(", ");

    const query = `
      query getBatchSearches(${queryParams}){
          viewer{
            batchSearches(${queryArgs}, ${queryFiltersCriteria} ){
              totalCount
              results{
                uuid
                rateCardId
                rateCardLabel
                status
                statusDisplay
                finished
                searchesTotal
                searchesFailed
                searchesQueued
                searchesRunning
                searchesFinished
                created
                updated
              }
            }
          }
        }
      `;

    this.network.loading = true;
    let data: ?Object = null;

    try {
      data = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (this.network.isCancelError(e)) {
        return e;
      }

      this.network.handleError("Getting Scheduled Searches", e);

      // TODO: Display user friendly error message
      console.error(e);
      return e;
    }

    return runInAction("getBatchSearches--success", () => {
      this.network.loading = false;
      this.network.error = null;

      if (data) {
        // this.currentUser = new CurrentUser(this, res.data.viewer.user);
        const searches = data.data.viewer.batchSearches.results || [];

        this.batchSearches = searches.map((search) => {
          const batchSearch = new BatchSearch(this, search);

          if (!this.batchSearchesViewState.has(batchSearch.id)) {
            this.batchSearchesViewState.set(batchSearch.id, {
              selected: this.allSelected,
              editing: this.isEditing,
            });
          } else {
            const selectedValue = this.allSelected
              ? true
              : this.batchSearchesViewState.get(batchSearch.id).selected;

            this.batchSearchesViewState.set(batchSearch.id, {
              selected: selectedValue,
              editing: this.isEditing,
            });
          }

          batchSearch.viewState = this.batchSearchesViewState.get(batchSearch.id);

          return batchSearch;
        });

        return {
          totalCount: data.data.viewer.batchSearches.totalCount,
        };
      }

      return { totalCount: 0 };
    });
  }
}

export class BatchSearchJLListStore extends BatchSearchJLListComponentStore {
  router: {
    push: (any) => void,
  };
  helpModal: ModalState;
  showHelpModal: boolean;

  confirmDeleteModal: ModalState;
  errorModal: ModalState;
  errorMessage: Element<any>;

  showHelp: () => void;
  hideHelp: () => void;
  deleteBatchSearches: () => void;
  rerunBatchSearch: (string) => void;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI, mobXStore: MobXStore) {
    super(fetchGraphQL, fetchAPI, mobXStore);

    extendObservable(this, {
      helpModal: new ModalState(),
      confirmDeleteModal: new ModalState(),
      errorModal: new ModalState(),
      errorMessage: null,
    });

    this.showHelp = action(this.showHelp.bind(this));
    this.hideHelp = action(this.hideHelp.bind(this));
    this.deleteBatchSearches = action(this.deleteBatchSearches.bind(this));
    this.rerunBatchSearch = action(this.rerunBatchSearch.bind(this));
  }

  showHelp() {
    this.showHelpModal = true;
  }

  hideHelp() {
    this.showHelpModal = false;
  }

  async deleteBatchSearches() {
    let params = [];
    let args = [];
    let vars = {};

    const selectedIDs = this.getSelectedBatchSearches();
    if (!selectedIDs || !selectedIDs.length) {
      console.error("Cannot delete Batch Search: No Batch Search selected");
      return;
    }
    params.push("$batchSearchUuids: [String]!");
    args.push("batchSearchIds: $batchSearchUuids");
    vars.batchSearchUuids = selectedIDs;

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
      mutation deleteBatchSearch(${queryParams}){
       deleteBatchSearch(input:{${queryArgs}}) {
         ok

         errors {
           __typename
           ...on RunningOrQueuedBatchSearchError {
             message
           }
         }
       }
      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Deleting selected Batch Searches", e);
      // TODO: Display user friendly error message
      return;
    }

    runInAction("deleteBatchSearches--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (!res) {
        this.errorMessage = (
          <p>
            Sorry! we could not delete the selected Batch Searches. There was an error
            during the operation.
          </p>
        );
        this.errorModal.showModal();
        return;
      } else if (res.data.deleteBatchSearch.errors) {
        const errors = res.data.deleteBatchSearch.errors;
        const runningBatchSearchError = errors.find(
          (err) => err.__typename === "RunningOrQueuedBatchSearchError"
        );
        if (runningBatchSearchError) {
          this.errorMessage = <p>Cannot delete queued or running Batch Searches!</p>;
          this.errorModal.showModal();
          return;
        } else {
          console.error("Error Deleting Batch Search:", errors);
        }
      } else if (res.status === "error") {
        this.errorMessage = (
          <p>Sorry! we could not delete the selected Batch Searches. {res.message}</p>
        );
        this.errorModal.showModal();
        return;
      }

      this.handleStopEdit();
      this.pagination.goFetch();
    });
  }

  rerunBatchSearch(searchUUID: string): void {
    this.mobXStore.batchSearchJLCreateStore.rerunBatchSearch(searchUUID);
    if (this.router) this.router.push("/batch-searches/create");
  }
}

export class BatchSearchPSPListComponentStore {
  fetchTasteGraphQL: FetchGraphQL;
  fetchGraphQL: FetchGraphQL;
  fetchAPI: FetchAPI;
  mobXStore: MobXStore;
  network: NetworkState;
  pagination: PaginationState;

  batchSearches: BatchSearch[];
  batchSearchesViewState: Object;
  batchSearchesView: BatchSearch[];

  currentUser: CurrentUser;
  isEditing: ?boolean;
  allOnPageSelected: boolean;
  allSelected: boolean;
  allowMultipleItemSelection: boolean;
  selectedCount: number;

  batchSearchRateCardLabelFilter: BatchSearchRateCardLabelFilter;

  defaultFilters: { [key: FilterColumn]: DjangoFilterObject };
  appliedFilters: { [key: FilterColumn]: DjangoFilterObject };
  appliedSorts: { [key: FilterColumn]: Sort };
  appliedSortsOrder: Array<FilterColumn>;
  isFiltered: boolean;

  getBatchSearches: (PageQuery) => Promise<PaginationInfo>;
  applyDefaultFilter: (FilterColumn, FilterObject) => void;
  handleStartEdit: () => void;
  handleStopEdit: () => void;
  toggleSelectAllPage: (Object) => void;
  selectAllPage: (Event) => void;
  deselectAllPage: (Event) => void;
  toggleAllItems: () => void;
  clearAllSelections: () => void;
  getSelectedBatchSearches: () => Array<string>;
  getFirstSelectedBatchSearch: () => Object;

  getFilterCriteriaQuery: (FilterColumn) => GraphQLQuery;
  processFilterCriteria: (FilterColumn, Object) => Array<Object>;
  applyFilter: (FilterColumn, FilterObject) => void;
  removeFilter: (FilterColumn) => void;
  applySort: (FilterColumn, Sort) => void;
  removeSort: (FilterColumn) => void;
  filterBatchSearches: () => void;
  clearFilters: () => void;
  applyDefaultSort: () => void;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI, mobXStore: MobXStore) {
    this.fetchTasteGraphQL = createTasteGraphQLWrapper(fetchAPI);
    this.fetchGraphQL = fetchGraphQL;
    this.fetchAPI = fetchAPI;
    this.mobXStore = mobXStore;

    // NOTE: Bound early to pass into pagination & filter state
    this.getBatchSearches = action(this.getBatchSearches.bind(this));

    this.getFilterCriteriaQuery = action(this.getFilterCriteriaQuery.bind(this));
    this.processFilterCriteria = action(this.processFilterCriteria.bind(this));
    this.applyFilter = action(this.applyFilter.bind(this));
    this.applySort = action(this.applySort.bind(this));
    this.removeFilter = action(this.removeFilter.bind(this));
    this.removeSort = action(this.removeSort.bind(this));

    extendObservable(this, {
      network: new NetworkState(),
      pagination: new PaginationState(this.getBatchSearches, 25),
      batchSearches: [],
      batchSearchesViewState: observable.map({}),
      batchSearchesView: computed(() => {
        return this.batchSearches.map((batchSearch) => {
          if (this.batchSearchesViewState.has(batchSearch.id)) {
            batchSearch.viewState = this.batchSearchesViewState.get(batchSearch.id);

            return batchSearch;
          }

          return batchSearch;
        });
      }),
      isEditing: null, // we start with null so some view elements be hidden initially
      isFiltered: false,
      allSelected: false,
      allowMultipleItemSelection: true,
      selectedCount: computed(() => {
        const selectedValues = this.batchSearchesView.map(
          (batchSearch) => batchSearch.viewState.selected
        );

        if (this.allSelected) {
          return this.pagination.totalCount;
        }

        let count = 0;

        selectedValues.forEach((v) => {
          if (v) {
            count += 1;
          }
        });

        return count;
      }),
      allOnPageSelected: computed(() => {
        const allTrue = R.all(R.equals(true));
        const selectedValues = this.batchSearchesView.map(
          (batchSearch) => batchSearch.viewState.selected
        );

        if (selectedValues.length === 0) {
          return false;
        }

        return allTrue(selectedValues);
      }),
      defaultFilters: {},
      appliedFilters: {},
      appliedSorts: {},
      appliedSortsOrder: observable.shallow([]),
      batchSearchRateCardLabelFilter: new BatchSearchRateCardLabelFilter(
        this,
        FILTER_COLUMN.BATCH_SEARCH_RATE_CARD_LABEL,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
    });

    this.currentUser = null;

    this.applyDefaultFilter = action(this.applyDefaultFilter.bind(this));
    this.handleStartEdit = action(this.handleStartEdit.bind(this));
    this.handleStopEdit = action(this.handleStopEdit.bind(this));
    this.toggleSelectAllPage = action(this.toggleSelectAllPage.bind(this));
    this.selectAllPage = action(this.selectAllPage.bind(this));
    this.deselectAllPage = action(this.deselectAllPage.bind(this));
    this.toggleAllItems = action(this.toggleAllItems.bind(this));
    this.clearAllSelections = action(this.clearAllSelections.bind(this));
    this.getSelectedBatchSearches = action(this.getSelectedBatchSearches.bind(this));
    this.getFirstSelectedBatchSearch = action(
      this.getFirstSelectedBatchSearch.bind(this)
    );

    this.filterBatchSearches = action(this.filterBatchSearches.bind(this));
    this.clearFilters = action(this.clearFilters.bind(this));
    this.applyDefaultSort = action(this.applyDefaultSort.bind(this));

    this.applyDefaultSort();
  }

  applyDefaultSort() {
    // this.createdOnFilter.sortState.direction = SORT_DIRECTION.DESC;
    // this.createdOnFilter.sort = this.createdOnFilter.buildQuerySort();
    // this.applySort(this.createdOnFilter.column, this.createdOnFilter.sort);
  }

  handleStartEdit() {
    this.isEditing = true;
    this.batchSearchesView.forEach((batchSearch) => batchSearch.toggleEdit());
  }

  handleStopEdit() {
    this.isEditing = false;
    this.allSelected = false;
    this.batchSearchesViewState.forEach((viewState) => {
      viewState.selected = false;
      viewState.editing = false;
    });
  }

  toggleSelectAllPage(e: Object) {
    if (!this.allowMultipleItemSelection) return;

    const setValue = !this.allOnPageSelected;

    this.batchSearchesView.forEach((batchSearch) => {
      batchSearch.toggleSelected(e, null, null, setValue);
    });

    // When All items selected flag is up, clear selection
    if (setValue === false && this.allSelected) this.allSelected = false;
  }

  selectAllPage(e: Event) {
    this.batchSearchesView.forEach((batchSearch) => {
      batchSearch.toggleSelected(e, null, null, true);
    });
  }

  deselectAllPage(e: Event) {
    this.batchSearchesView.forEach((batchSearch) => {
      batchSearch.toggleSelected(e, null, null, false);
    });

    this.allSelected = false;
  }

  toggleAllItems() {
    if (!this.allowMultipleItemSelection) return;

    this.allSelected = !this.allSelected;

    if (this.allSelected === false) {
      this.batchSearchesViewState.forEach((value) => {
        value.selected = false;
      });
    }
  }

  clearAllSelections() {
    this.allSelected = false;
    this.batchSearchesViewState.forEach((value) => {
      value.selected = false;
    });
  }

  getFilterCriteriaQuery(column: FilterColumn): GraphQLQuery {
    switch (column) {
      // case FILTER_COLUMN.RATE_TYPE:
      //   return {
      //     query: rateTypesCriteriaQuery,
      //     variables: {},
      //   };
      //
      // case FILTER_COLUMN.CREATED_BY:
      //   return {
      //     query: ownersCriteriaQuery,
      //     variables: {},
      //   };

      default:
        return null;
    }
  }

  processFilterCriteria(column: FilterColumn, payload: Object): ?Array<Object> {
    switch (column) {
      default:
        return null;
    }
  }

  applyFilter(column: FilterColumn, filter: FilterObject) {
    this.appliedFilters[column] = filter;
    this.isFiltered = true;
  }

  applyDefaultFilter(column: FilterColumn, filter: FilterObject) {
    this.defaultFilters[column] = filter;
  }

  removeFilter(column: FilterColumn) {
    delete this.appliedFilters[column];

    let entries = Object.entries(this.appliedFilters);
    if (!entries.length) this.isFiltered = false;
  }

  applySort(column: FilterColumn, sort: Sort) {
    this.appliedSorts[column] = sort;

    const index = this.appliedSortsOrder.indexOf(column);
    if (index === -1) this.appliedSortsOrder.push(column);
  }

  removeSort(column: FilterColumn) {
    delete this.appliedSorts[column];

    const index = this.appliedSortsOrder.indexOf(column);
    if (index > -1) this.appliedSortsOrder.splice(index, 1);
  }

  clearFilters() {
    this.batchSearchRateCardLabelFilter = new BatchSearchRateCardLabelFilter(
      this,
      FILTER_COLUMN.BATCH_SEARCH_RATE_CARD_LABEL,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );

    this.appliedFilters = observable({});
    this.appliedSorts = observable({});
    this.appliedSortsOrder.length = 0;
    this.isFiltered = false;

    return this.pagination.goFetch();
  }

  filterBatchSearches() {
    return this.pagination.goFetch();
  }

  getSelectedBatchSearches(): Array<string> {
    const batchSearches = this.batchSearchesViewState;

    let selectedBatchSearches = [];

    batchSearches.forEach((value, key) => {
      if (value.selected) {
        selectedBatchSearches.push(key);
      }
    });

    return selectedBatchSearches;
  }

  getFirstSelectedBatchSearch() {
    for (let j = 0; j < this.batchSearchesView.length; j++) {
      if (this.batchSearches[j].viewState.selected) return this.batchSearches[j];
    }

    return null;
  }

  async getBatchSearches(pageQuery: PageQuery): Promise<PaginationInfo> {
    // let result = null;
    let params: string[] = pageQuery.params;
    let args = pageQuery.args;
    let variables = pageQuery.variables;
    let filtersCriteria: string[] = [];

    consolidateAppliedFilters(this.appliedFilters, params, filtersCriteria, variables);

    // NOTE: applied filters could override default filters, handle this if needed
    consolidateAppliedFilters(this.defaultFilters, params, filtersCriteria, variables);

    if (FILTER_COLUMN.BATCH_SEARCH_RATE_CARD_LABEL in this.appliedFilters) {
      const labelFilter = this.appliedFilters[FILTER_COLUMN.BATCH_SEARCH_RATE_CARD_LABEL];
      params.push("$search: String!");
      args.push("search: $search");
      Object.assign(variables, labelFilter);
    }

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");
    const queryFiltersCriteria = filtersCriteria.join(", ");

    const query = `
      query getBatchSearches(${queryParams}){
        viewer{
          batchSearches(${queryArgs}, ${queryFiltersCriteria} ){
            totalCount
            results{
              uuid
              rateCardId
              rateCardLabel
              status
              statusDisplay
              finished
              searchesTotal
              searchesFailed
              searchesQueued
              searchesRunning
              searchesFinished
              created
              updated
            }
          }
        }
      }
    `;

    this.network.loading = true;
    let data: ?Object = null;

    try {
      data = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (this.network.isCancelError(e)) {
        return e;
      }

      this.network.handleError("Getting Scheduled Searches", e);

      // TODO: Display user friendly error message
      console.error(e);
      return e;
    }

    return runInAction("getBatchSearches--success", () => {
      this.network.loading = false;
      this.network.error = null;

      if (data) {
        // this.currentUser = new CurrentUser(this, res.data.viewer.user);
        const searches = data.data.viewer.batchSearches.results || [];

        this.batchSearches = searches.map((search) => {
          const batchSearch = new BatchSearch(this, search);

          if (!this.batchSearchesViewState.has(batchSearch.id)) {
            this.batchSearchesViewState.set(batchSearch.id, {
              selected: this.allSelected,
              editing: this.isEditing,
            });
          } else {
            const selectedValue = this.allSelected
              ? true
              : this.batchSearchesViewState.get(batchSearch.id).selected;

            this.batchSearchesViewState.set(batchSearch.id, {
              selected: selectedValue,
              editing: this.isEditing,
            });
          }

          batchSearch.viewState = this.batchSearchesViewState.get(batchSearch.id);

          return batchSearch;
        });

        return {
          totalCount: data.data.viewer.batchSearches.totalCount,
        };
      }

      return { totalCount: 0 };
    });
  }
}

export class BatchSearchPSPListStore extends BatchSearchPSPListComponentStore {
  router: {
    push: (any) => void,
  };
  helpModal: ModalState;
  showHelpModal: boolean;

  confirmDeleteModal: ModalState;
  errorModal: ModalState;
  errorMessage: Element<any>;

  showHelp: () => void;
  hideHelp: () => void;
  deleteBatchSearches: () => void;
  rerunBatchSearch: (string) => void;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI, mobXStore: MobXStore) {
    super(fetchGraphQL, fetchAPI, mobXStore);

    extendObservable(this, {
      helpModal: new ModalState(),
      confirmDeleteModal: new ModalState(),
      errorModal: new ModalState(),
      errorMessage: null,
    });

    this.showHelp = action(this.showHelp.bind(this));
    this.hideHelp = action(this.hideHelp.bind(this));
    this.deleteBatchSearches = action(this.deleteBatchSearches.bind(this));
    this.rerunBatchSearch = action(this.rerunBatchSearch.bind(this));
  }

  showHelp() {
    this.showHelpModal = true;
  }

  hideHelp() {
    this.showHelpModal = false;
  }

  async deleteBatchSearches() {
    let params = [];
    let args = [];
    let vars = {};

    const selectedIDs = this.getSelectedBatchSearches();
    if (!selectedIDs || !selectedIDs.length) {
      console.error("Cannot delete Batch Search: No Batch Search selected");
      return;
    }
    params.push("$scheduledSearchUuids: [String]!");
    args.push("batchSearchIds: $scheduledSearchUuids");
    vars.scheduledSearchUuids = selectedIDs;

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
      mutation deleteBatchSearch(${queryParams}){
       deleteBatchSearch(input:{${queryArgs}}) {
         ok

         errors {
           __typename
           ...on RunningOrQueuedBatchSearchError {
             message
           }
         }
       }
      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Deleting selected Batch Searches", e);
      // TODO: Display user friendly error message
      return;
    }

    runInAction("deleteBatchSearches--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (!res) {
        this.errorMessage = (
          <p>
            Sorry! we could not delete the selected Batch Searches. There was an error
            during the operation.
          </p>
        );
        this.errorModal.showModal();
        return;
      } else if (res.data.deleteBatchSearch.errors) {
        const errors = res.data.deleteBatchSearch.errors;
        const runningBatchSearchError = errors.find(
          (err) => err.__typename === "RunningOrQueuedBatchSearchError"
        );
        if (runningBatchSearchError) {
          this.errorMessage = <p>Cannot delete queued or running Batch Searches!</p>;
          this.errorModal.showModal();
          return;
        } else {
          console.error("Error Deleting Batch Search:", errors);
        }
      } else if (res.status === "error") {
        this.errorMessage = (
          <p>Sorry! we could not delete the selected Batch Searches. {res.message}</p>
        );
        this.errorModal.showModal();
        return;
      }

      this.handleStopEdit();
      this.pagination.goFetch();
    });
  }

  rerunBatchSearch(searchUUID: string): void {
    this.mobXStore.batchSearchPSPCreateStore.rerunBatchSearch(searchUUID);
    if (this.router) this.router.push("/batch-searches/create");
  }
}
