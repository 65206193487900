// @flow

import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import DraftSearchesList from "./components/DraftSearchesList";
import {
  CardActions,
  CardActionsLeft,
  CardHeader,
  CardHeaderTitle,
} from "../../components/lib/Card";
import SearchBox from "../../components/lib/SearchBox";
import Box from "../../components/lib/Box";

/**
 * DraftSearchList component for draft search
 */
class DraftSearchList extends Component {
  /**
   * constructor
   * @param {props} props The first number.

   */
  constructor(props) {
    super(props);
    this.state = {
      existingRateCardId: null,
    };
  }
  /**
   * componentDidMount
   * @return {void}
   */
  componentDidMount() {
    this.props.store.draftsSavedSearchListStore.pagination.goFetch();

    // reset to null so some elements visible while editing start hidden
    this.props.store.draftsSavedSearchListStore.isEditing = null;
  }
  /**
   * updateExistingRateCardId - update rate card id for add to rate card feature
   * @return {void}
   */
  updateExistingRateCardId(e) {
    this.setState({ existingRateCardId: e.searchId });
  }
  /**
   * Render Draft Searches List
   * @return {XML}
   */
  render() {
    const store = this.props.store.draftsSavedSearchListStore;
    const rateSearchStore = this.props.store.rateSearchStore;
    return (
      <>
        <Box>
          {this.props.showHeader && (
            <CardHeader>
              <CardHeaderTitle>Available Searches</CardHeaderTitle>
            </CardHeader>
          )}
          <div className="header-action-bar marginTopzero">
            <CardActions>
              <CardActionsLeft>
                <SearchBox
                  value={store.jobSearchFilter.textToLookFor}
                  onChange={store.jobSearchFilter.onTextToLookForChange}
                  onSubmit={store.jobSearchFilter.onApply}
                  css={{ width: "300px" }}
                />
              </CardActionsLeft>
            </CardActions>
          </div>
          <DraftSearchesList
            editing={"null"}
            store={store}
            rateSearchStore={rateSearchStore}
          />
        </Box>

        {/*
         NOTE: following code is not needed since this component
         is not being used as a standalone page
        */}

        {/*<div*/}
        {/*  className={classNames("ratecards-edit-actions", {*/}
        {/*    hidden: store.isEditing === null*/}
        {/*  })}*/}
        {/*>*/}
        {/*  <button className="btn" disabled={store.selectedCount === 0} onClick={store.addToRateCardModal.showModal}>*/}
        {/*    Add to Rate Card*/}
        {/*  </button>*/}
        {/*  <button*/}
        {/*    className="btn btn-danger"*/}
        {/*    disabled={store.selectedCount === 0}*/}
        {/*    onClick={store.confirmDeleteModal.showModal}*/}
        {/*  >*/}
        {/*    <FontAwesomeIcon icon="trash-alt" fixedWidth className="icon" /> Delete*/}
        {/*  </button>*/}
        {/*</div>*/}

        {/*<div*/}
        {/*  className={classNames("rc-container-backdrop", {*/}
        {/*    hidden: store.isEditing === null*/}
        {/*  })}*/}
        {/*/>*/}

        {/*<Modal show={store.helpModal.show} onHide={store.helpModal.hideModal}>*/}
        {/*  <div className="container-section header">*/}
        {/*    <h4>Help</h4>*/}
        {/*  </div>*/}
        {/*  <div className="container-section footer">*/}
        {/*    <p>Here you can see all of your Saved Searches ...</p>*/}
        {/*  </div>*/}
        {/*</Modal>*/}

        {/*<Modal show={store.addToRateCardModal.show} onHide={store.addToRateCardModal.hideModal}>*/}
        {/*  <SelectableItemsList>*/}
        {/*    {store.allRateCards.map((ratecard, i) => (*/}
        {/*      <SelectableItem*/}
        {/*        key={ratecard.searchId}*/}
        {/*        item={ratecard}*/}
        {/*        name="ratecardId"*/}
        {/*        value={ratecard.searchId}*/}
        {/*        selected={this.state.existingRateCardId === ratecard.searchId ? true : false}*/}
        {/*        onChange={e => this.updateExistingRateCardId(e)}*/}
        {/*      >*/}
        {/*        <div>{ratecard.name}</div>*/}
        {/*      </SelectableItem>*/}
        {/*    ))}*/}
        {/*  </SelectableItemsList>*/}
        {/*  <div className="clearfix" />*/}
        {/*  <div className="container-section footer">*/}
        {/*    <div className="pull-right">*/}
        {/*      <button className="btn btn-lg" onClick={store.addToRateCardModal.hideModal}>*/}
        {/*        Cancel*/}
        {/*      </button>*/}
        {/*      <button*/}
        {/*        className="btn btn-lg"*/}
        {/*        disabled={this.state.existingRateCardId ? false : true}*/}
        {/*        onClick={e => {*/}
        {/*          store.addToRateCardModal.hideModal();*/}
        {/*          // call API here*/}
        {/*          store.addSavedSearchToRateCard(this.state.existingRateCardId);*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        Add*/}
        {/*      </button>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</Modal>*/}

        {/*<Modal show={store.confirmDeleteModal.show} onHide={store.confirmDeleteModal.hideModal}>*/}
        {/*  <div className="container-section header">*/}
        {/*    <h4>Confirmation Needed</h4>*/}
        {/*  </div>*/}
        {/*  <div className="container-section">*/}
        {/*    <p>Are you sure you want to delete the selected Saved Search?</p>*/}
        {/*    <p className="text-danger">*/}
        {/*      <FontAwesomeIcon icon="exclamation-triangle" /> <em>This action cannot be undone!</em>*/}
        {/*    </p>*/}
        {/*  </div>*/}
        {/*  <div className="container-section footer">*/}
        {/*    <div className="pull-right">*/}
        {/*      <button className="btn btn-lg" onClick={store.confirmDeleteModal.hideModal}>*/}
        {/*        No, Cancel*/}
        {/*      </button>*/}
        {/*      <button*/}
        {/*        className="btn btn-lg btn-danger"*/}
        {/*        onClick={e => {*/}
        {/*          store.confirmDeleteModal.hideModal();*/}
        {/*          store.deleteRateCards();*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        Yes, Delete Saved Search*/}
        {/*      </button>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</Modal>*/}
      </>
    );
  }
}

DraftSearchList.propTypes = { store: PropTypes.object.isRequired };

export default observer(DraftSearchList);
