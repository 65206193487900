import React from "react";

import Box from "../../lib/Box";
import Pagination, { PageEvent } from "../../lib/Pagination";

type TablePaginationProps = {
  activePage: number;
  itemsCount: number;
  itemsPerPage: number;
  disablePagination?: boolean;
  onChangePage: (e: PageEvent) => void;
};

export const TablePagination = (props: TablePaginationProps) => {
  const { activePage, itemsCount, itemsPerPage, disablePagination, onChangePage } = props;
  const pagesNumber = Math.ceil(itemsCount / itemsPerPage);

  if (pagesNumber <= 1 || disablePagination) return null;

  return (
    // IMPORTANT: use single <Center> wrapper here in case scrolling issues
    <Box css={{ height: "62px" }}>
      <Box css={{ position: "absolute", left: 0, right: 0, width: "$full" }}>
        <Pagination
          options={{
            variant: "full",
            currentPage: activePage,
            numPages: pagesNumber,
          }}
          onPageClick={onChangePage}
        />
      </Box>
    </Box>
  );
};
TablePagination.displayName = "TablePagination";
TablePagination.defaultProps = {
  disablePagination: false,
};
