import React from "react";
import Group from "./Group";

const GroupList = (props) => {
  const {
    groups,
    fields,
    workerTypeId,
    currencySymbol,
    store,
    payRateHourly,
    billRateHourly,
    conversionOptions,
    valUpdated,
  } = props;
  return (
    <div>
      {groups.map((group) => (
        <Group
          store={store}
          key={group.id}
          group={group}
          gpfields={fields}
          workerTypeId={workerTypeId}
          currencySymbol={currencySymbol}
          payRateHourly={payRateHourly}
          billRateHourly={billRateHourly}
          conversionOptions={conversionOptions}
          valUpdated={valUpdated}
        />
      ))}
    </div>
  );
};

export default GroupList;
