import { keyframes } from "../../stitches.config";

export const slideUpAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(0.5rem)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

export const slideRightAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(-0.5rem)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

export const slideDownAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(-0.5rem)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

export const slideLeftAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(0.5rem)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

export const scaleIn = keyframes({
  "0%": {
    opacity: 0,
    transform: " scale(0.9)",
  },
  "100%": {
    opacity: 1,
    transform: " scale(1)",
  },
});

export const scaleOut = keyframes({
  "0%": {
    opacity: 1,
    transform: "scale(1)",
  },
  "100%": {
    opacity: 0,
    transform: "scale(0.9)",
  },
});

export const overlayShow = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
});
