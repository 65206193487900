import React, { useEffect, useRef, useState, useCallback } from "react";
import { Set, fromJS } from "immutable";

import { FilterTypes } from "../../../components/tables/constants";
import { ButtonGroupRight, ButtonGroup } from "../../../components/lib/ButtonGroup";
import Box from "../../../components/lib/Box";
import Stack from "../../../components/lib/Stack";
import Icon from "../../../components/lib/Icon";
import {
  Card,
  CardBody,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
  CardHeaderTitle,
} from "../../../components/lib/Card";
import Text from "../../../components/lib/Text";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";
import { useModalState, useRefreshRequest } from "../../../utils/hooks";

import { Column, Group } from "../../../components/tables";
import {
  djangoPaginationKey,
  djangoPaginationSizeKey,
  emptyMap,
} from "../../../constants";

import SaveIndexModal from "../components/SaveIndexModal";
import AdminContractorEditor from "../components/AdminContractorEditor";
import JobTitleValidationsView from "../components/JobTitleValidationsView";
import {
  PARAMS_FOR_BULK_UPDATE,
  PARAMS_FOR_BULK_UPDATE_TYPE,
} from "../components/AdminContractorBulkEditor";
import RunningProcessingsAlert from "../components/RunningProcessingsAlert";
import {
  ContractorsTable,
  defaultCustomConfigVisibleColumnsKeysForAdmin,
  groupedContractorsTableColumnsSpecs,
  TableConfigChangesObject,
  useContractorsSelectionHandlers,
  useEditingRowState,
} from "../components/ContractorsTable";

import { TickerContentLoader } from "../../../components/lib/TickerLoader";
import { InstructionsCheckMark } from "../lib/CheckMark";
import { CheckedState } from "../../../components/lib/Checkbox";
import { NavigationButton, IconButton, Button } from "../../../components/lib/Button";
import {
  selectRowCheckbox,
  selectAllRowsCheckbox,
} from "../components/ContractorsTableSelectCheckbox";

import { useProgramContext } from "../ProgramDataProvider";
import { useAllContractorsTableGlobalState } from "../globalState";
import { useIntervalDataRefresh, useScrollTo } from "../hooks";
import { hasAnyProcessingContractorsPredicate } from "../utils";

import {
  ALL_CONTRACTOR_STATUSES_VALUES,
  ALL_TABLE_TABS_TYPES,
  CONTRACTOR_STATUSES,
  CONTRACTOR_STATUSES_TYPE,
} from "../types";
import { transformContractorsData } from "../dataConverters";

import {
  FailedContractorsAlert,
  NeedApprovalContractorsAlert,
} from "../components/ContractorsAlerts";
import { renderGroupedTableColumnsSpecs } from "../components/ExtendedRestfulTables";
import { BulkRerunContractorsButton } from "../components/BulkRerunContractorsButton";
import BulkDeleteContractorsButton from "../components/BulkDeleteContractorsButton";
import BulkExportContractorsButton from "../components/BulkExportContractorsButton";
import BulkCreateSurveysButtons from "../components/BulkCreateSurveysButtons";
import BulkUpdateButton from "../components/BulkUpdateButton";
import { rowIdGetter } from "../../../components/tables/utils";
import { usePLIContext } from "../context";

import type { ImmutableList, ImmutableMap, ImmutableSet } from "../../../types/immutable";
import type { FetchAPIResponse } from "../../../types/fetch";
import type { DjangoPaginatedResponse } from "../../../types/django";
import type { GroupLikeElement } from "../../../components/tables/Schema";
import type { RowEditorComponent } from "../../../components/tables/types";
import type { ContractorsTableProps } from "../components/ContractorsTable";
import type { CommonProgramChildPageProps } from "../ProgramDataProvider";
import type {
  ContractorDataMap,
  ContractorDataObject,
  ContractorsTableDataProvider,
  ContractorsTableDataStateObject,
  ContractorsTableFiltersDataProvider,
  ProcessingMessagesMap,
} from "../types";

export const CONTRACTORS_TABLE_STATUS_OPTIONS = [
  {
    label: "Processing",
    value: [CONTRACTOR_STATUSES.PENDING, CONTRACTOR_STATUSES.CLEAN],
  },
  { label: "Failed", value: CONTRACTOR_STATUSES.FAILED },
  { label: "Finished", value: CONTRACTOR_STATUSES.FINISHED },
  { label: "Needs Approval", value: CONTRACTOR_STATUSES.NEED_APPROVAL },
];

const allowedStatusesForBulkUpdate = Set([
  CONTRACTOR_STATUSES.FAILED,
  CONTRACTOR_STATUSES.NEED_APPROVAL,
  CONTRACTOR_STATUSES.FINISHED,
]) as unknown as ImmutableSet<CONTRACTOR_STATUSES_TYPE>;

const allowedParametersForBulkUpdate = Set([
  PARAMS_FOR_BULK_UPDATE.INDUSTRY,
  PARAMS_FOR_BULK_UPDATE.EXP_LEVEL,
  // IMPORTANT: Per Marc - need more manual control on Accepted flag, should be always available
  PARAMS_FOR_BULK_UPDATE.LIMITS_ACCEPTED,
  PARAMS_FOR_BULK_UPDATE.GLOBAL_SUPPLIER_SEARCH,
  // below are all parameters available under specific data/filters conditions
  PARAMS_FOR_BULK_UPDATE.COLLECTION,
  PARAMS_FOR_BULK_UPDATE.DESCRIPTION,
  PARAMS_FOR_BULK_UPDATE.LOCATION,
  PARAMS_FOR_BULK_UPDATE.REGION,
  PARAMS_FOR_BULK_UPDATE.WORKER_TYPE,
]) as any as ImmutableSet<PARAMS_FOR_BULK_UPDATE_TYPE>;

const allowedStatusesForBulkRerun = Set(
  ALL_CONTRACTOR_STATUSES_VALUES
) as unknown as ImmutableSet<CONTRACTOR_STATUSES_TYPE>;

const allowedStatusesForBulkDelete = Set([
  CONTRACTOR_STATUSES.FAILED,
  CONTRACTOR_STATUSES.NEED_APPROVAL,
  CONTRACTOR_STATUSES.FINISHED,
]) as any as ImmutableSet<CONTRACTOR_STATUSES_TYPE>;

const allowedStatusesForExport = Set([
  CONTRACTOR_STATUSES.FINISHED,
]) as any as ImmutableSet<CONTRACTOR_STATUSES_TYPE>;

const StoredIndexContractorsTable = (props: ContractorsTableProps) => (
  <ContractorsTable {...props} />
);
StoredIndexContractorsTable.displayName = "StoredIndexContractorsTable";
StoredIndexContractorsTable.defaultProps = ContractorsTable.defaultProps;
StoredIndexContractorsTable.getTableId = (userId: number, programId: number): string =>
  `user-${userId}-program-${programId}-create-stored-index-contractors-table`;

export type ProcessingsCheckObject = {
  total_processing_ids_num: number;
  page_processing_ids_num: number;
};

export type BulkDeleteCheckResultObject = {
  ids_to_delete: ImmutableList<number>;
  ids_to_delete_count: number;
};
export type BulkDeleteCheckResultMap = ImmutableMap<BulkDeleteCheckResultObject>;

export type BulkUpdateCheckResultObject = {
  ids_to_update: ImmutableList<number>;
  unique_titles_count: number;
  region_based_data_count: number;
  location_based_data_count: number;
  not_approved_data_found: boolean;
  unique_countries_count: number;
  unique_industries_count: number;
  // worker type specific fields
  worker_type_country_id: number;
  worker_type_industry_id: number;
};
export type BulkUpdateCheckResultMap = ImmutableMap<BulkUpdateCheckResultObject>;

type PageHeadBlockProps = {
  handleGoToUploadPage: () => void;
  handleGoBack: () => void;
  disableButtons?: boolean;
};

const PageHeadBlock = (props: PageHeadBlockProps) => {
  const { disableButtons, handleGoToUploadPage, handleGoBack } = props;
  const { isPTAdmin } = usePLIContext();

  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft>
          <CardHeaderTitle>Create New Index View</CardHeaderTitle>
        </CardActionsLeft>
        <CardActionsRight>
          {isPTAdmin && (
            <NavigationButton
              icon="upload"
              onClick={handleGoToUploadPage}
              disabled={disableButtons}
            >
              Upload Data
            </NavigationButton>
          )}
          <NavigationButton
            icon="arrow-left"
            onClick={handleGoBack}
            disabled={disableButtons}
          >
            Back To Index Page
          </NavigationButton>
        </CardActionsRight>
      </CardActions>

      <CardBody>
        <h3>
          This page includes all data you have imported.
          <br />
          Here you can:
        </h3>
        <h3>
          <InstructionsCheckMark /> Browse all index data.
        </h3>
        <h3>
          <InstructionsCheckMark /> Create filtered custom index views using table below.
        </h3>
        <h3>
          <InstructionsCheckMark /> Save views and/or export to Excel.
        </h3>
      </CardBody>
    </Card>
  );
};
PageHeadBlock.displayName = "PageHeadBlock";
PageHeadBlock.defaultProps = {
  disableButtons: false,
};

type PageBottomBlockProps = PageHeadBlockProps;

const PageBottomBlock = (props: PageBottomBlockProps) => {
  const { disableButtons, handleGoToUploadPage, handleGoBack } = props;
  const { isPTAdmin } = usePLIContext();

  return (
    <Card fill>
      <CardBody css={{ padding: "$4" }}>
        <ButtonGroupRight>
          {isPTAdmin && (
            <NavigationButton
              icon="upload"
              onClick={handleGoToUploadPage}
              disabled={disableButtons}
            >
              Upload Data
            </NavigationButton>
          )}
          <NavigationButton
            icon="arrow-left"
            onClick={handleGoBack}
            disabled={disableButtons}
          >
            Back To Index Page
          </NavigationButton>
        </ButtonGroupRight>
      </CardBody>
    </Card>
  );
};
PageBottomBlock.displayName = "PageBottomBlock";
PageBottomBlock.defaultProps = {
  disableButtons: false,
};

export type ContractorsTableViewProps = {
  editingRowId: number | null;
  checkingValidationsRowId: number | null;
  contractorsData: ContractorsTableDataStateObject;
  contractorsDataProvider: ContractorsTableDataProvider;
  contractorsFiltersDataProvider: ContractorsTableFiltersDataProvider;
  //
  onStartRowEditing: (rowData: ContractorDataMap) => void;
  onCancelRowEditing: ContractorsTableProps["onEditCancel"];
  onApplyRowUpdate: ContractorsTableProps["onEditApply"];
  onSeeRowValidations: (rowData: ContractorDataMap) => void;
  onCloseRowValidations: ContractorsTableProps["onEditCancel"];
  onDeleteRow: ContractorsTableProps["onDeleteRow"];
  onSelectRow: (rowData: ContractorDataMap, isChecked: CheckedState) => void;
  onSelectAllRowsOnThePage: (value: CheckedState) => void;
  onChangeTableConfig: ContractorsTableProps["onChangeTableConfig"];
};

const ContractorsTableView = (props: ContractorsTableViewProps) => {
  const {
    editingRowId,
    checkingValidationsRowId,
    contractorsData,
    contractorsDataProvider,
    contractorsFiltersDataProvider,
    //
    onStartRowEditing,
    onCancelRowEditing,
    onApplyRowUpdate,
    onSeeRowValidations,
    onCloseRowValidations,
    onDeleteRow,
    onSelectRow,
    onSelectAllRowsOnThePage,
    onChangeTableConfig,
  } = props;
  const { isSelectingRows, isBulkUpdateSelectingRows } = contractorsData;
  const isEditingRow = editingRowId != null;
  const isCheckingRowValidations = checkingValidationsRowId != null;

  const { router, isPTAdmin } = usePLIContext();
  const { programId } = useProgramContext();

  // scroll to table top
  const scrollToRef = useScrollTo([contractorsData.activePage]);

  const renderRowVisibilityStatus = (rowData: ContractorDataMap) => {
    const status = rowData.get("status");
    const uploadIsActive = rowData.getIn(["upload", "is_active"], false);

    if (status !== CONTRACTOR_STATUSES.FINISHED) {
      return (
        <Text
          as={Icon}
          icon="eye-slash"
          color="negative"
          title="Not available for clients (row's processing is not finished)."
          size="sm"
        />
      );
    } else if (!uploadIsActive) {
      return (
        <Text
          as={Icon}
          icon="eye-slash"
          color="negative"
          title="Not available for clients (upload is not visible)."
          size="sm"
        />
      );
    }

    return (
      <Text
        as={Icon}
        icon="eye"
        color="positive"
        title="Available for clients."
        size="sm"
      />
    );
  };

  const renderRowActions = (rowData: ContractorDataMap) => {
    const rowId = rowData.get("id");
    const status = rowData.get("status");
    const isFinished = status === CONTRACTOR_STATUSES.FINISHED;
    const isProcessing =
      status === CONTRACTOR_STATUSES.PENDING || status === CONTRACTOR_STATUSES.CLEAN;

    const handleGoToMakeSearchPage = () => {
      router.push(
        `/private-index/programs/${programId}/contractors/${rowId}/market-search`
      );
    };

    return (
      <ButtonGroup css={{ justifyContent: "center" }}>
        <IconButton
          icon="search"
          color="brand"
          variant="outlined"
          title={isFinished ? "Show Market Search Detail" : "Pending"}
          onClick={isFinished ? handleGoToMakeSearchPage : undefined}
          disabled={
            !isFinished || isSelectingRows || isEditingRow || isCheckingRowValidations
          }
        />
        {isPTAdmin && (
          <IconButton
            icon="tasks"
            color="brand"
            variant="outlined"
            title="Validations Feedback"
            onClick={() => onSeeRowValidations(rowData)}
            disabled={
              !isFinished ||
              isSelectingRows ||
              isEditingRow ||
              (isCheckingRowValidations && checkingValidationsRowId !== rowId)
            }
          />
        )}
        {isPTAdmin && (
          <IconButton
            icon="wrench"
            color="brand"
            variant="outlined"
            title="Edit Mappings"
            onClick={() => onStartRowEditing(rowData)}
            disabled={
              isProcessing ||
              isSelectingRows ||
              isCheckingRowValidations ||
              (isEditingRow && editingRowId !== rowId)
            }
          />
        )}
      </ButtonGroup>
    );
  };

  const renderRowStatus = (
    status: CONTRACTOR_STATUSES_TYPE,
    rowData: ContractorDataMap
  ) => {
    const errors = rowData.get("errors") || (emptyMap as ProcessingMessagesMap);
    const warnings = rowData.get("warnings") || (emptyMap as ProcessingMessagesMap);

    if (status === CONTRACTOR_STATUSES.FINISHED) {
      return <Text as={Icon} icon="check" color="positive" />;
    } else if (status === CONTRACTOR_STATUSES.FAILED) {
      if (errors.size || warnings.size) {
        return <Text color="negative">{errors.size + warnings.size} Problems</Text>;
      }
      return <Text color="negative">Failed</Text>;
    } else if (status === CONTRACTOR_STATUSES.NEED_APPROVAL) {
      return <span>Needs Approval</span>;
    } else {
      return <span>Processing...</span>;
    }
  };

  const tableGroups: GroupLikeElement<ContractorDataMap>[] = [
    <Group key="__actions" uniqueKey="__actions" title="">
      {isPTAdmin &&
        (isSelectingRows || isBulkUpdateSelectingRows ? (
          <Column
            uniqueKey="__selecting"
            title={selectAllRowsCheckbox(
              contractorsData.selectedRows,
              contractorsData,
              onSelectAllRowsOnThePage
            )}
            getter={(rowData: ContractorDataMap) =>
              selectRowCheckbox(rowData, contractorsData.selectedRows, onSelectRow)
            }
            fixed
          />
        ) : (
          <Column
            uniqueKey="__visibility"
            title=""
            getter={renderRowVisibilityStatus}
            fixed
          />
        ))}
      <Column uniqueKey="__actions" title="Actions" getter={renderRowActions} fixed />
      {isPTAdmin && (
        <Column
          uniqueKey="status"
          title="Status"
          getter={(row) => row.get("status")}
          formatter={renderRowStatus}
          filterType={FilterTypes.ENUMERATION}
          filterOptions={fromJS(CONTRACTORS_TABLE_STATUS_OPTIONS)}
          filterable
          sortable
          fixed
        />
      )}
    </Group>,
  ].concat(renderGroupedTableColumnsSpecs(groupedContractorsTableColumnsSpecs));

  return (
    <Box ref={scrollToRef}>
      <h5>
        <small>* Table contains values in original currency</small>
      </h5>
      <StoredIndexContractorsTable
        isPTAdmin={isPTAdmin}
        rowIdGetter={rowIdGetter}
        editable={isPTAdmin}
        editorImpl={
          isEditingRow
            ? (AdminContractorEditor as RowEditorComponent<ContractorDataMap>)
            : isCheckingRowValidations
            ? (JobTitleValidationsView as RowEditorComponent<ContractorDataMap>)
            : undefined
        }
        selectedRowId={
          isEditingRow
            ? editingRowId
            : isCheckingRowValidations
            ? checkingValidationsRowId
            : undefined
        }
        onEditApply={isEditingRow ? onApplyRowUpdate : undefined}
        onEditCancel={
          isEditingRow
            ? onCancelRowEditing
            : isCheckingRowValidations
            ? onCloseRowValidations
            : undefined
        }
        onDeleteRow={isEditingRow ? onDeleteRow : undefined}
        multimode
        actions={ALL_TABLE_TABS_TYPES}
        customConfigVisibleColumnsKeys={
          isPTAdmin
            ? defaultCustomConfigVisibleColumnsKeysForAdmin
            : StoredIndexContractorsTable.defaultProps.customConfigVisibleColumnsKeys
        }
        onChangeTableConfig={onChangeTableConfig}
        dataProvider={contractorsDataProvider}
        filtersDataProvider={contractorsFiltersDataProvider}
        {...contractorsData}
      >
        {tableGroups}
      </StoredIndexContractorsTable>
    </Box>
  );
};
ContractorsTableView.displayName = "ContractorsTableView";

const StoredIndexCreate = (props: CommonProgramChildPageProps) => {
  const {
    userId,
    userName,
    programId,
    isPTAdmin,
    isPreviewMode,
    router,
    fetchM8FilteringAPI,
    showModalError,
    showModalWarning,
  } = props;

  // state

  const tableId = StoredIndexContractorsTable.getTableId(userId, programId);
  const [contractorsData, setContractorsDataState] =
    useAllContractorsTableGlobalState(tableId);
  const [failedContractorsCount, setFailedContractorsCountState] = useState<number>(0);
  const [needApprovalContractorsCount, setNeedApprovalContractorsCountState] =
    useState<number>(0);

  // derivatives

  const hasContractorsDataToSave = contractorsData.data.size > 0;
  const pagesNumber =
    Math.ceil(contractorsData.itemsCount / contractorsData.itemsPerPage) || 1;
  const {
    isSelectingRows,
    isBulkUpdateSelectingRows,
    selectedRows,
    loaded: isContractorsDataLoaded,
  } = contractorsData;
  let isContractorsDataFiltered =
    contractorsData.filters && contractorsData.filters.size > 0;
  if (contractorsData.filters.size === 1 && contractorsData.filters.has("status")) {
    isContractorsDataFiltered = false;
  }

  // modals

  const {
    modalState: saveIndexModalState,
    showModal: showSaveIndexModal,
    closeModal: closeSaveIndexModal,
  } = useModalState();

  // data fetch functions

  const fetchFailedContractorsCount = useCallback(async () => {
    try {
      const response: FetchAPIResponse<DjangoPaginatedResponse<ContractorDataObject>> =
        await fetchM8FilteringAPI(`programs/${programId}/contractors/filtered/`, {
          params: { [djangoPaginationKey]: 1, [djangoPaginationSizeKey]: 1 },
          data: { status: CONTRACTOR_STATUSES.FAILED },
        });
      const data: Partial<ContractorsTableDataStateObject> = transformContractorsData(
        response.data
      );
      setFailedContractorsCountState(data.itemsCount!);
    } catch (err: any) {
      logAsyncOperationError("fetchFailedContractorsNumber", err);
      showModalError("Error occurred while loading failed contractors list.");
    }
  }, [programId, setFailedContractorsCountState, fetchM8FilteringAPI, showModalError]);

  const fetchNeedApprovalContractorsCount = useCallback(async () => {
    try {
      const response: FetchAPIResponse<DjangoPaginatedResponse<ContractorDataObject>> =
        await fetchM8FilteringAPI(`programs/${programId}/contractors/filtered/`, {
          params: { [djangoPaginationKey]: 1, [djangoPaginationSizeKey]: 1 },
          data: { status: CONTRACTOR_STATUSES.NEED_APPROVAL },
        });
      const data: Partial<ContractorsTableDataStateObject> = transformContractorsData(
        response.data
      );
      setNeedApprovalContractorsCountState(data.itemsCount!);
    } catch (err: any) {
      logAsyncOperationError("fetchPendingApprovalContractorsNumber", err);
      showModalError("Error occurred while loading pending approval contractors list.");
    }
  }, [
    programId,
    setNeedApprovalContractorsCountState,
    fetchM8FilteringAPI,
    showModalError,
  ]);

  const allowedStatusList = React.useMemo(() => {
    return isPTAdmin
      ? [
          CONTRACTOR_STATUSES.PENDING,
          CONTRACTOR_STATUSES.FAILED,
          CONTRACTOR_STATUSES.NEED_APPROVAL,
          CONTRACTOR_STATUSES.CLEAN,
          CONTRACTOR_STATUSES.FINISHED,
        ]
      : [CONTRACTOR_STATUSES.FINISHED];
  }, [isPTAdmin]);

  const fetchContractorsData: ContractorsTableDataProvider = useCallback(
    async (urlQuery = {}, filtersQuery = {}, nextStateUpdates = {}) => {
      try {
        const response: FetchAPIResponse<DjangoPaginatedResponse<ContractorDataObject>> =
          await fetchM8FilteringAPI(`programs/${programId}/contractors/filtered/`, {
            params: urlQuery,
            data: { status__in: allowedStatusList, ...filtersQuery },
          });
        const nextDataState = transformContractorsData(response.data, nextStateUpdates);

        setContractorsDataState((prevDataState: ContractorsTableDataStateObject) => ({
          ...prevDataState,
          ...nextDataState,
          loaded: true,
        }));

        return nextDataState;
      } catch (err: any) {
        logAsyncOperationError("fetchContractorsList", err);
        showModalError(
          "Error occurred while retrieving contractors list. Please, try again later."
        );
        throw err;
      }
    },
    [
      programId,
      allowedStatusList,
      fetchM8FilteringAPI,
      setContractorsDataState,
      showModalError,
    ]
  );

  const fetchContractorsFiltersData: ContractorsTableFiltersDataProvider = useCallback(
    async (urlQuery = {}, filtersQuery = {}) => {
      try {
        const response: FetchAPIResponse<DjangoPaginatedResponse<any>> =
          await fetchM8FilteringAPI(
            `programs/${programId}/contractors/values/filtered/`,
            {
              params: urlQuery,
              data: { status__in: allowedStatusList, ...filtersQuery },
            }
          );

        return response.data;
      } catch (err: any) {
        logAsyncOperationError("fetchContractorsListFilterValues", err);
        showModalError(
          "Error occurred while retrieving filter values. Please, try again later."
        );
        throw err;
      }
    },
    [programId, allowedStatusList, fetchM8FilteringAPI, showModalError]
  );

  // utility functions

  const [processingsRefreshRequestId, refreshProcessingsData] = useRefreshRequest();

  const refreshContractorsData = useCallback(async () => {
    return fetchContractorsData(
      {
        [djangoPaginationKey]: contractorsData.activePage,
        [djangoPaginationSizeKey]: contractorsData.itemsPerPage,
      },
      contractorsData.filtersQuery.toJS()
    );
  }, [
    contractorsData.activePage,
    contractorsData.itemsPerPage,
    contractorsData.filtersQuery,
    fetchContractorsData,
  ]);

  const refreshPageData = useCallback(
    async (withProcessing: boolean = true) => {
      if (isPTAdmin) {
        if (withProcessing) {
          refreshProcessingsData();
        }
        fetchFailedContractorsCount();
        fetchNeedApprovalContractorsCount();
      }
      return await refreshContractorsData();
    },
    [
      isPTAdmin,
      refreshProcessingsData,
      fetchFailedContractorsCount,
      fetchNeedApprovalContractorsCount,
      refreshContractorsData,
    ]
  );
  const refreshPageDataNoProcessing = React.useCallback(async () => {
    return await refreshPageData(false);
  }, [refreshPageData]);

  // effects

  const contractorsDataLoadedRef = useRef(false);

  useEffect(() => {
    if (!contractorsDataLoadedRef.current) {
      refreshPageData(false);
      contractorsDataLoadedRef.current = true;
    }
  }, [refreshPageData]);

  useIntervalDataRefresh(
    refreshContractorsData,
    contractorsData.data,
    hasAnyProcessingContractorsPredicate
  );

  // handlers

  const handleGoBackToProgramDetails = useCallback(
    () => router.push(`/private-index/programs/${programId}`),
    [router, programId]
  );

  const handleGoToUploadContractors = useCallback(
    () => router.push(`/private-index/programs/${programId}/contractors/upload`),
    [router, programId]
  );

  const {
    handleStartRowsSelection,
    handleStopRowsSelection,
    handleSelectRow,
    handleSelectAllRowsOnThePage,
  } = useContractorsSelectionHandlers(
    setContractorsDataState,
    contractorsData.selectedRows,
    contractorsData.data
  );

  const handleCreateSurveys = React.useCallback(() => {
    if (!selectedRows.size) {
      showModalWarning(`Please, select some rows for validation.`);
      return;
    }
    router.push(
      `/private-index/programs/${programId}/contractors/validations/create?fromPage=stored-index-create`
    );
  }, [selectedRows.size, programId, router, showModalWarning]);

  const handleChangeTableConfig = React.useCallback(
    async (changes: TableConfigChangesObject) => {
      setContractorsDataState((prevState) => ({
        ...prevState,
        ...changes,
      }));
    },
    [setContractorsDataState]
  );

  const {
    editingRowId,
    startRowEditing: handleStartRowEditing,
    stopRowEditing: handleCancelRowEditing,
  } = useEditingRowState(refreshPageData);
  const isEditingRow = editingRowId != null;

  const {
    editingRowId: checkingValidationsRowId,
    startRowEditing: handleSeeRowValidations,
    stopRowEditing: handleCloseRowValidations,
  } = useEditingRowState();

  const handleApplyRowUpdate = useCallback(
    async () => refreshPageData(),
    [refreshPageData]
  );

  const handleDeleteRow = useCallback(
    () => handleCancelRowEditing(true),
    [handleCancelRowEditing]
  );

  // rendering

  const contractorsTableBlock = (
    <Card css={{ width: "100%" }}>
      <CardActions>
        <CardActionsLeft />
        <CardActionsRight>
          {!isSelectingRows && !isBulkUpdateSelectingRows && !isPreviewMode && (
            <Button
              icon={["far", "save"]}
              size="small"
              onClick={showSaveIndexModal}
              disabled={
                !isContractorsDataLoaded ||
                !isContractorsDataFiltered ||
                !hasContractorsDataToSave
              }
            >
              Create View
            </Button>
          )}
          {!isSelectingRows && !isBulkUpdateSelectingRows && (
            <BulkExportContractorsButton
              icon={["far", "file-excel"]}
              size="small"
              loadingText="Export to Excel"
              disabled={!isContractorsDataLoaded || !contractorsData.itemsCount}
              contractorsFiltersQuery={contractorsData.filtersQuery}
              allowedStatusesForExport={allowedStatusesForExport}
              currentPage={contractorsData.activePage}
              itemsTotal={contractorsData.itemsCount}
              itemsPerPage={contractorsData.itemsPerPage}
            >
              Export to Excel
            </BulkExportContractorsButton>
          )}
          {isPTAdmin && !isPreviewMode && !isBulkUpdateSelectingRows && (
            <BulkCreateSurveysButtons
              selectedRows={contractorsData.selectedRows}
              isSelectingRows={contractorsData.isSelectingRows}
              isEditingRow={isEditingRow}
              isContractorsDataLoaded={isContractorsDataLoaded}
              itemsTotal={contractorsData.itemsCount}
              onStartRowsSelection={() => handleStartRowsSelection("isSelectingRows")}
              onStopRowsSelection={() => handleStopRowsSelection("isSelectingRows")}
              onCreateSurveys={handleCreateSurveys}
            />
          )}
          {isPTAdmin &&
            !isPreviewMode &&
            !isSelectingRows &&
            !isBulkUpdateSelectingRows && (
              <BulkRerunContractorsButton
                icon="sync"
                size="small"
                loadingText="Rerun Index"
                disabled={
                  !isContractorsDataLoaded || !contractorsData.itemsCount || isEditingRow
                }
                allowedStatusesForRerun={allowedStatusesForBulkRerun}
                contractorsFiltersQuery={contractorsData.filtersQuery}
                currentPage={contractorsData.activePage}
                itemsTotal={contractorsData.itemsCount}
                itemsPerPage={contractorsData.itemsPerPage}
                itemsOnCurrentPage={contractorsData.data.size}
                onRefreshIsDone={refreshProcessingsData}
              >
                Rerun Index
              </BulkRerunContractorsButton>
            )}
          {isPTAdmin && !isPreviewMode && !isSelectingRows && (
            <BulkUpdateButton
              selectedRows={contractorsData.selectedRows}
              isBulkUpdateSelectingRows={contractorsData.isBulkUpdateSelectingRows}
              isEditingRow={isEditingRow}
              isContractorsDataLoaded={isContractorsDataLoaded}
              itemsTotal={contractorsData.itemsCount}
              allowedStatusesForUpdate={allowedStatusesForBulkUpdate}
              allowedParametersForUpdate={allowedParametersForBulkUpdate}
              contractorsFiltersQuery={contractorsData.filtersQuery}
              onStartRowsSelection={() =>
                handleStartRowsSelection("isBulkUpdateSelectingRows")
              }
              onStopRowsSelection={() =>
                handleStopRowsSelection("isBulkUpdateSelectingRows")
              }
              onUpdateIsDone={refreshPageData}
            />
          )}
          {isPTAdmin &&
            !isPreviewMode &&
            !isSelectingRows &&
            !isBulkUpdateSelectingRows && (
              <BulkDeleteContractorsButton
                icon={["far", "trash-alt"]}
                color="danger"
                size="small"
                loadingText="Bulk Delete"
                disabled={
                  !isContractorsDataLoaded || !contractorsData.itemsCount || isEditingRow
                }
                allowedStatusesForDelete={allowedStatusesForBulkDelete}
                contractorsFiltersQuery={contractorsData.filtersQuery}
                onDeleteIsDone={refreshPageData}
              >
                Bulk Delete
              </BulkDeleteContractorsButton>
            )}
        </CardActionsRight>
      </CardActions>

      <CardBody>
        <Stack fill css={{ alignItems: "stretch" }}>
          {isPTAdmin && (
            <RunningProcessingsAlert
              programId={programId}
              refreshRequestId={processingsRefreshRequestId}
              onChangeProcessingsNumber={refreshPageDataNoProcessing}
            />
          )}
          {isPTAdmin && <FailedContractorsAlert count={failedContractorsCount} />}
          {isPTAdmin && (
            <NeedApprovalContractorsAlert count={needApprovalContractorsCount} />
          )}
          {!isContractorsDataLoaded ? (
            <Box css={{ minHeight: "200px", position: "relative" }}>
              <TickerContentLoader />
            </Box>
          ) : (
            <ContractorsTableView
              editingRowId={editingRowId}
              checkingValidationsRowId={checkingValidationsRowId}
              contractorsData={contractorsData}
              contractorsDataProvider={fetchContractorsData}
              contractorsFiltersDataProvider={fetchContractorsFiltersData}
              onStartRowEditing={handleStartRowEditing}
              onCancelRowEditing={handleCancelRowEditing}
              onApplyRowUpdate={handleApplyRowUpdate}
              onSeeRowValidations={handleSeeRowValidations}
              onCloseRowValidations={handleCloseRowValidations}
              onDeleteRow={handleDeleteRow}
              onSelectRow={handleSelectRow}
              onSelectAllRowsOnThePage={handleSelectAllRowsOnThePage}
              onChangeTableConfig={handleChangeTableConfig}
            />
          )}
        </Stack>
      </CardBody>
    </Card>
  );

  return (
    <Stack>
      <SaveIndexModal
        userId={userId}
        userName={userName}
        filters={contractorsData.filters}
        filtersQuery={contractorsData.filtersQuery}
        visibleColumns={contractorsData.visibleColumns}
        itemsPerPage={contractorsData.itemsPerPage}
        activeTab={contractorsData.activeTab}
        show={saveIndexModalState}
        onClose={closeSaveIndexModal}
      />

      <PageHeadBlock
        handleGoToUploadPage={handleGoToUploadContractors}
        handleGoBack={handleGoBackToProgramDetails}
        disableButtons={isSelectingRows}
      />

      {contractorsTableBlock}

      {pagesNumber > 1 && (
        <PageBottomBlock
          handleGoToUploadPage={handleGoToUploadContractors}
          handleGoBack={handleGoBackToProgramDetails}
          disableButtons={isSelectingRows}
        />
      )}
    </Stack>
  );
};

StoredIndexCreate.displayName = "StoredIndexCreate";

export default StoredIndexCreate;
