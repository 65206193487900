import React from "react";

import Stack from "../../../../components/lib/Stack";
import Text from "../../../../components/lib/Text";
import { TR, TD } from "../../../../components/lib/Table";
import { RatesBase, RatesTitleBase } from "./RatesBase";
import { statusIconItems } from "./utils";
import {
  getFilteredValueTypesDesc,
  decimalFormatter,
  percentFormatter,
} from "../../constants";
import {
  RATE_TYPES,
  RATE_TYPES_LABELS,
  RATE_FEEDBACK_STATUS_TYPES,
  RATE_FEEDBACK_STATUS_TYPES_LABELS,
} from "../../types";
import { useReviewDataContext } from "../../context/ReviewDataContext";
import { useRateDisplayContext } from "../../context/RateDisplayContext";

import type { RatesDataKey } from "../../types";
import { RatesProps, RatesRowProps, RatesTitleProps } from "./types";

function RatesFeedback(props: RatesProps) {
  const { rateFeedback } = props;

  if (!rateFeedback?.size) return null;

  const status = rateFeedback.get("status") || RATE_FEEDBACK_STATUS_TYPES.NOT_VALIDATED;
  const comment = rateFeedback.get("comment");
  const statusLabel = RATE_FEEDBACK_STATUS_TYPES_LABELS[status];

  return (
    <Stack css={{ alignItems: "flex-start", padding: "$4" }}>
      <Text css={{ fontWeight: "$semibold" }}>{statusLabel}</Text>
      <Text>{comment || "No Comment"}</Text>
    </Stack>
  );
}

RatesFeedback.displayName = "RatesFeedback";

function RatesTitle(props: RatesTitleProps) {
  const { expLevel, rateFeedback } = props;

  const {
    firstJobCurrencyData: currencyData,
    firstJobRateType: rateType,
    isThreeLevelBanding,
  } = useReviewDataContext();
  const { displayRateType, displayCurrencyData } = useRateDisplayContext();

  const status = rateFeedback?.get("status") || RATE_FEEDBACK_STATUS_TYPES.NOT_VALIDATED;
  const statusIcon = statusIconItems[status];

  let rateTypeLabel = RATE_TYPES_LABELS[rateType];
  const resultingCurrencyData = displayCurrencyData.size
    ? displayCurrencyData
    : currencyData;
  const resultingCurrencyName = resultingCurrencyData.get("name");

  if (
    rateType === RATE_TYPES.DAILY ||
    rateType === RATE_TYPES.WEEKLY ||
    rateType === RATE_TYPES.MONTHLY
  ) {
    rateTypeLabel = RATE_TYPES_LABELS[RATE_TYPES.HOURLY];
  }
  if (
    displayRateType === RATE_TYPES.DAILY ||
    displayRateType === RATE_TYPES.WEEKLY ||
    displayRateType === RATE_TYPES.MONTHLY
  ) {
    rateTypeLabel = RATE_TYPES_LABELS[displayRateType];
  }

  return (
    <RatesTitleBase
      expLevelLabel={expLevel}
      rateTypeLabel={rateTypeLabel}
      currencyLabel={resultingCurrencyName}
      isThreeLevelBanding={isThreeLevelBanding}
      statusIcon={statusIcon}
    />
  );
}

RatesTitle.displayName = "RatesTitle";

function RatesRow(props: RatesRowProps) {
  const { valueSubtype, valueSubtypeTitle, rateResult, rateFeedback } = props;

  const { firstJobRateType, requiredRates } = useReviewDataContext();
  const { convertToDisplayCurrency, convertToDisplayRateType, resultingCurrencySymbol } =
    useRateDisplayContext();

  return (
    <TR>
      <TD>{valueSubtypeTitle}</TD>
      {getFilteredValueTypesDesc(firstJobRateType, requiredRates.toJS()).map(
        ({ type: valueType }) => {
          const valueKey = `${valueType}_${valueSubtype}` as unknown as RatesDataKey;
          let initialValue: number = rateResult.get(valueKey);
          let feedbackValue: number | null = rateFeedback?.get(valueKey) ?? null;

          if (valueType === "pay_rate" || valueType === "bill_rate") {
            if (firstJobRateType !== RATE_TYPES.ANNUAL) {
              initialValue = convertToDisplayRateType(initialValue);
              feedbackValue = convertToDisplayRateType(feedbackValue);
            }

            initialValue = convertToDisplayCurrency(initialValue);
            feedbackValue = convertToDisplayCurrency(feedbackValue);
          }

          const renderedInitialValue =
            valueType === "markup"
              ? percentFormatter(initialValue, false, "%")
              : decimalFormatter(initialValue, false, resultingCurrencySymbol);
          const renderedFeedbackValue =
            valueType === "markup"
              ? percentFormatter(feedbackValue, false, "%")
              : decimalFormatter(feedbackValue, false, resultingCurrencySymbol);

          return (
            <TD key={valueType}>
              <span>
                {feedbackValue != null && (
                  <>
                    {feedbackValue > initialValue! ? (
                      <Text color="positive">{renderedFeedbackValue}</Text>
                    ) : feedbackValue < initialValue! ? (
                      <Text color="negative">{renderedFeedbackValue}</Text>
                    ) : (
                      <Text>{renderedFeedbackValue}</Text>
                    )}
                  </>
                )}
                {feedbackValue != null ? (
                  <s>{renderedInitialValue || "none"}</s>
                ) : (
                  renderedInitialValue
                )}
              </span>
            </TD>
          );
        }
      )}
    </TR>
  );
}

export default function RatesWithFeedbackPreview(props: RatesProps) {
  const { rateResult, rateFeedback } = props;
  const { firstJobRateType, requiredRates } = useReviewDataContext();

  return (
    <RatesBase
      rateResult={rateResult}
      rateFeedback={rateFeedback}
      rateType={firstJobRateType}
      requiredRates={requiredRates}
      ratesTitleImpl={RatesTitle}
      ratesRowImpl={RatesRow}
      ratesFeedbackImpl={RatesFeedback}
    />
  );
}

RatesWithFeedbackPreview.displayName = "RatesWithFeedbackPreview";
