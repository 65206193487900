import React from "react";

import PromiseButton from "../../../components/lib/PromiseButton";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";
import { djangoPaginationKey, djangoPaginationSizeKey } from "../../../constants";

import { useProgramContext } from "../ProgramDataProvider";
import { usePLIContext } from "../context";
import { useModalState } from "../../../utils/hooks";
import { secondsToIntervalString } from "../constants";
import { contractorsProcessingToImmutableMap } from "../dataConverters";

import { RerunIndexModal } from "./RerunIndexModals";

import type { ButtonProps } from "../../../components/lib/Button";
import type {
  ProcessingDataMap,
  CONTRACTOR_STATUSES_TYPE,
  ProcessingDataObject,
} from "../types";
import type {
  FiltersQueryObject,
  FiltersQueryOrderedMap,
  UrlQueryObject,
} from "../../../components/tables/types";
import type { ImmutableSet } from "../../../types/immutable";
import type { FetchAPIResponse } from "../../../types/fetch";
import { useStoredIndexContext } from "../StoredIndexDataProvider";

export type BulkRerunContractorsButtonProps = Omit<ButtonProps, "onClick"> & {
  allowedStatusesForRerun: ImmutableSet<CONTRACTOR_STATUSES_TYPE>;
  contractorsFiltersQuery: FiltersQueryOrderedMap;
  currentPage: number;
  itemsTotal: number;
  itemsPerPage: number;
  itemsOnCurrentPage: number;
  onRefreshIsDone: () => Promise<any>;
};

export function BulkRerunContractorsButton(props: BulkRerunContractorsButtonProps) {
  const {
    allowedStatusesForRerun,
    contractorsFiltersQuery,
    currentPage,
    itemsTotal,
    itemsPerPage,
    itemsOnCurrentPage,
    onRefreshIsDone,
    ...restProps
  } = props;

  const {
    router,
    isPTAdmin,
    userEmail,
    showModalSuccess,
    showModalWarning,
    showModalError,
    fetchM8FilteringAPI,
  } = usePLIContext();
  const { programId } = useProgramContext();
  const { indexId } = useStoredIndexContext();

  // state

  const {
    modalState: rerunIndexModalState,
    showModal: showRerunIndexModal,
    closeModal: closeRerunIndexModal,
  } = useModalState();

  // handlers

  const handleGoToProcessingsList = React.useCallback(() => {
    router.push(`/private-index/programs/${programId}/contractors/processings`);
  }, [router, programId]);

  const handleRerunIndex = React.useCallback(
    async (
      currentPageOnly: boolean,
      includeRemapping: boolean,
      informOnEmail: string | null
    ): Promise<ProcessingDataMap | void> => {
      if (!isPTAdmin) {
        showModalWarning("You have no permissions to rerun indexes");
        return;
      }

      const urlQuery: UrlQueryObject = {};
      if (currentPageOnly) {
        urlQuery[djangoPaginationKey] = currentPage;
        urlQuery[djangoPaginationSizeKey] = itemsPerPage;
      }
      if (informOnEmail) {
        urlQuery["__inform_on_email"] = informOnEmail;
      }
      if (includeRemapping) {
        urlQuery["__include_remapping"] = includeRemapping;
      }
      if (indexId != null) {
        urlQuery["__stored_index"] = indexId;
      }
      const filtersQuery: FiltersQueryObject = {
        status__in: allowedStatusesForRerun.toJS(),
        ...contractorsFiltersQuery.toJS(),
      };

      try {
        const response: FetchAPIResponse<ProcessingDataObject> =
          await fetchM8FilteringAPI(`programs/${programId}/processings/run/`, {
            params: urlQuery,
            data: filtersQuery,
          });
        const processing: ProcessingDataMap = contractorsProcessingToImmutableMap(
          response.data
        );

        await onRefreshIsDone?.();
        closeRerunIndexModal();

        if (processing && processing.size) {
          const count = processing.get("total_items");
          const estimatedTimeLeft = processing.get("estimated_time_left");

          showModalSuccess(
            `${count ? `${count} rows` : "Data"} sent to processing.` +
              (estimatedTimeLeft != null
                ? ` It should take ${secondsToIntervalString(estimatedTimeLeft)}.`
                : "")
          );
        }

        return processing;
      } catch (err: any) {
        logAsyncOperationError("runContractorsProcessing", err);
        showModalError("Error occurred while rerunning index");
      }
    },
    [
      currentPage,
      itemsPerPage,
      contractorsFiltersQuery,
      allowedStatusesForRerun,
      closeRerunIndexModal,
      isPTAdmin,
      indexId,
      programId,
      onRefreshIsDone,
      showModalSuccess,
      showModalWarning,
      showModalError,
      fetchM8FilteringAPI,
    ]
  );

  return (
    <>
      <PromiseButton
        onClick={itemsTotal > 0 ? showRerunIndexModal : undefined}
        {...restProps}
      />
      <RerunIndexModal
        show={rerunIndexModalState}
        email={userEmail}
        itemsTotal={itemsTotal}
        itemsOnPage={itemsOnCurrentPage}
        onRerun={handleRerunIndex}
        onSeeProcessings={handleGoToProcessingsList}
        onHide={closeRerunIndexModal}
      />
    </>
  );
}

BulkRerunContractorsButton.displayName = "BulkRerunContractorsButton";

export default BulkRerunContractorsButton;
