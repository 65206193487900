import React, { useMemo } from "react";
import { styled } from "../../../../stitches.config";
// lib
import Inline from "../../../../components/lib/Inline";
import Text from "../../../../components/lib/Text";
import Icon from "../../../../components/lib/Icon";
import Tooltip from "../../../../components/lib/Tooltip";
import Tag from "../../../../components/lib/Tag";

import {
  Card,
  CardContent as BaseCardContent,
  CardHeading,
} from "./HiringDifficultyCard";
import Stack from "../../../../components/lib/Stack";

const CardContent = styled(BaseCardContent, {
  justifyContent: "space-between",
  overflow: "visible",
});

const AvgCandidateInfo = styled(Inline, {
  flexWrap: "nowrap",
  alignItems: "flex-end",
  gap: "$1",
});

type HiringIntelStatsCardProps = {
  title: string;
  description: string;
  heading: string;
  candidateAvg: number;
  nationalCandidateAvg: number;
  isCountryLocationSearch: boolean;
};

export function HiringIntelStatsCard(props: HiringIntelStatsCardProps) {
  const {
    title,
    description,
    heading,
    candidateAvg,
    nationalCandidateAvg,
    isCountryLocationSearch,
  } = props;

  const percentageDiff = useMemo(() => {
    if (!candidateAvg || !nationalCandidateAvg) return 0;

    const diff = candidateAvg - nationalCandidateAvg;
    const diffPercentage = (diff / nationalCandidateAvg) * 100;

    if (!isNaN(diffPercentage)) {
      return Math.trunc(Number(diffPercentage.toFixed(2)));
    } else {
      return 0;
    }
  }, [candidateAvg, nationalCandidateAvg]);

  const tagColor =
    percentageDiff > 0 ? "success" : percentageDiff < 0 ? "danger" : "brand";
  const tagIcon =
    percentageDiff > 0 ? "chevron-up" : percentageDiff < 0 ? "chevron-down" : "minus";

  return (
    <Card>
      <CardHeading fill nowrap>
        <Text color="primary">{title}</Text>
        <Tooltip arrowProps={{ offset: 2 }} content={<Text>{description}</Text>}>
          <Text color="primary">
            <Icon icon="info-circle" />
          </Text>
        </Tooltip>
      </CardHeading>
      <CardContent fill nowrap>
        <AvgCandidateInfo>
          {candidateAvg === 0 ? (
            <Inline css={{ paddingBottom: "$2", gap: "$2" }}>
              <Icon icon="circle-exclamation" color="grey" size="lg" />
              <Text css={{ fontSize: "$base" }} color="primary">
                No data available
              </Text>
            </Inline>
          ) : (
            <>
              <Text
                css={{ fontSize: "$5xl", lineHeight: "$4xl !important" }}
                color="dark"
              >
                {Math.floor(candidateAvg)}
              </Text>
              <Text>{heading}</Text>
            </>
          )}
        </AvgCandidateInfo>
        {!isCountryLocationSearch && (
          <Stack nogap css={{ alignItems: "flex-end" }}>
            <Tag icon={tagIcon} color={tagColor} size="extra-small">
              <Text nowrap>{percentageDiff} %</Text>
            </Tag>
            <Text as="small" color="primary">
              vs. National Average
            </Text>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
}
