// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import Pagination from "../../../components/lib/Pagination";
import LoadingIndicator from "../../shared/LoadingIndicator";
import NegotiationWorksheetListItem from "./NegotiationWorksheetListItem";
import ToggleButton from "../../ratecards/components/ToggleButton";
import SortIndicator from "../../ratecards/components/SortIndicator";
import { FILTER_COLUMN } from "../../../models/Filter";
import { SingleDatePicker } from "../../../components/SingleDatePicker";
import CreatedOnFilter from "../../filters/CreatedOnFilter";
import CountryFilter from "../../filters/CountryFilter";
import PayTypeFilter from "../../filters/PayTypeFilter";
import MarkUpOnFilter from "../../filters/MarkUpAmtFilter";
import TagsFilter from "../../filters/TagsFilter";
import Button from "../../../components/lib/Button";
import Inline from "../../../components/lib/Inline";
import { CardAlert, CardAlertLink, CardFilters } from "../../../components/lib/Card";
import Box from "../../../components/lib/Box";
import NumberInput from "../../../components/lib/NumberInput";

class NegotiationWorksheetList extends Component {
  constructor(props) {
    super(props);

    this.changeCurrent = this.changeCurrent.bind(this);
  }

  // Change the current value of the pagination
  changeCurrent(value) {
    let store: NegotiationWorksheetListComponentStore = this.props.store;
    store.pagination.handleCurrentPage(value);
  }

  render() {
    const store: NegotiationWorksheetListComponentStore = this.props.store;
    const network = store.network;
    const fillContainer = this.props.fillContainer;
    const maximumDate = new Date();

    // NOTE: default sort is by name but there's no filter button for that
    // const defaultSort = Object.keys(store.appliedSorts).length === 0 ? { queryArg: "direction:DESC" } : null;

    let listBodyStyles = {};
    let selectAllSection = null;
    let selectAllOnPageSection = (
      <Inline css={{ gap: "$2" }}>
        <Button onClick={store.selectAllOnPageItem}>Select All</Button>
        <Button onClick={store.deselectAllPage}>Deselect All</Button>
      </Inline>
    );

    if (store.allSelected) {
      selectAllSection = (
        <CardAlert>
          <span>
            All &nbsp;
            <strong>{store.totalCount}</strong>
            &nbsp; Worksheets selected.
          </span>
          {!store.isTagView && (
            <CardAlertLink key="clear-selections-link" onClick={store.clearAllSelections}>
              Clear all selections
            </CardAlertLink>
          )}
          {store.isTagView && (
            <CardAlertLink
              key="clear-selections-link"
              onClick={store.clearAllSelectionsOnTagView}
            >
              Clear all selections
            </CardAlertLink>
          )}
        </CardAlert>
      );
    }

    if (store.allOnPageSelected && !store.allSelected) {
      selectAllSection = (
        <CardAlert>
          <span>All Worksheets on this page selected.</span>
          <CardAlertLink key="select-all-item" onClick={store.selectAllPage}>
            Select all <strong>{store.totalCount}</strong> Worksheets.
          </CardAlertLink>
          {!store.isTagView && (
            <CardAlertLink key="clear-selections-link" onClick={store.clearAllSelections}>
              Clear all selections
            </CardAlertLink>
          )}
          {store.isTagView && (
            <CardAlertLink
              key="clear-selections-link"
              onClick={store.clearAllSelectionsOnTagView}
            >
              Clear all selections
            </CardAlertLink>
          )}
        </CardAlert>
      );
    }

    let tableLeftActionBar = null;
    if (store.isEditing) {
      tableLeftActionBar = (
        <Box css={{ marginRight: "auto" }}>
          {store.allowMultipleItemSelection && selectAllOnPageSection}
        </Box>
      );
    }

    const worksheets = store.negotiationListView;

    let tableContent = null;
    if (network.loading) {
      tableContent = <LoadingIndicator />;
    } else if (network.error) {
      tableContent = (
        <div className="pt-error-message">
          <h2>Error</h2>
          <pre>
            <code>{JSON.stringify(network.error, null, 2)}</code>
          </pre>
        </div>
      );
    } else if (worksheets.length === 0) {
      tableContent = (
        <div className="ratecards-table-empty">
          {Object.keys(store.appliedFilters).length > 0 ? (
            <div>No Worksheets match your current filters</div>
          ) : (
            <div>
              No Worksheets
              <p>Create a new Worksheet using the button above or by searching.</p>
            </div>
          )}
        </div>
      );
    } else {
      tableContent = worksheets.map((worksheet) => (
        <NegotiationWorksheetListItem
          key={worksheet.worksheetId}
          store={store}
          worksheet={worksheet}
          editing={store.isEditing}
        />
      ));
    }

    let fromDate = (
      <SingleDatePicker
        id="from_date"
        date={store.createdOnFilter.fromDate}
        numberOfMonths={1}
        isOutsideRange={(day) => day.isAfter(maximumDate)}
        focused={store.createdOnFilter.fromFocused}
        onDateChange={store.createdOnFilter.fromDateChange}
        onFocusChange={store.createdOnFilter.fromFocusedChange}
      />
    );

    let toDate = (
      <SingleDatePicker
        id="to_date"
        date={store.createdOnFilter.toDate}
        numberOfMonths={1}
        isOutsideRange={(day) => store.toDateRange(day)}
        focused={store.createdOnFilter.toFocused}
        onDateChange={store.createdOnFilter.toDateChange}
        onFocusChange={store.createdOnFilter.toFocusedChange}
      />
    );

    let fromMarkUp = (
      <NumberInput
        id="from_markup"
        value={store.markUpOnFilter.fromMarkUpAmt}
        onChange={store.markUpOnFilter.fromMarkUpAmtChange}
        min="0"
      />
    );

    let toMarkUp = (
      <NumberInput
        id="to"
        value={store.markUpOnFilter.toMarkUpAmt}
        onChange={store.markUpOnFilter.toMarkUpAmtChange}
        min="0"
      />
    );

    let payTypeList = (
      <Inline css={{ gap: "$1" }}>
        {store.payTypeFilter.viewItems.map((item) => (
          <ToggleButton
            key={item.id}
            large={true}
            item={item}
            type="radio"
            name="rate-type"
            value={item.value}
            selected={item.selected}
            onChange={store.payTypeFilter.setSelectedValue}
          >
            {item.value}
          </ToggleButton>
        ))}
      </Inline>
    );

    return (
      <>
        {store.createdOnFilter && (
          <CreatedOnFilter
            createdOnFilter={store.createdOnFilter}
            fromDate={fromDate}
            toDate={toDate}
          />
        )}
        {store.countryFilter && (
          <CountryFilter
            countryFilter={store.countryFilter}
            network={store.countryFilter.network.loading}
            instantSearchValue={store.countryFilter.instantSearchValue}
            onInstantSearch={store.countryFilter.onInstantSearch}
          />
        )}
        {store.payTypeFilter && (
          <PayTypeFilter
            payTypeFilter={store.payTypeFilter}
            payTypeList={payTypeList}
            network={store.payTypeFilter.network.loading}
          />
        )}
        {store.markUpOnFilter && (
          <MarkUpOnFilter
            markUpOnFilter={store.markUpOnFilter}
            fromMarkUp={fromMarkUp}
            toMarkUp={toMarkUp}
          />
        )}
        {store.tagsFilter && (
          <TagsFilter
            tagsFilter={store.tagsFilter}
            network={store.tagsFilter.network.loading}
            instantSearchValue={store.tagsFilter.instantSearchValue}
            onInstantSearch={store.tagsFilter.onInstantSearch}
          />
        )}
        <CardFilters filtered={store.isFiltered}>
          {tableLeftActionBar}
          {store.isFiltered && (
            <Button color="accent" onClick={store.clearFilters}>
              Clear All Filters &amp; Sorts
            </Button>
          )}
          {store.tagsFilter && (
            <ToggleButton
              borderless={true}
              name="search-list-filter"
              value={FILTER_COLUMN.TAGS}
              selected={Boolean(store.appliedFilters[FILTER_COLUMN.TAGS])}
              onClick={store.tagsFilter.onShowModal}
            >
              <SortIndicator
                sortColumn={FILTER_COLUMN.TAGS}
                sortsOrder={store.appliedSortsOrder}
                sort={store.appliedSorts[FILTER_COLUMN.TAGS]}
              />
              Tags <span> ▼</span>
            </ToggleButton>
          )}
          {store.payTypeFilter && (
            <ToggleButton
              borderless={true}
              name="rate-cards-filter"
              value={FILTER_COLUMN.PAY_TYPE}
              selected={Boolean(store.appliedFilters[FILTER_COLUMN.PAY_TYPE])}
              onClick={store.payTypeFilter.onShowModal}
            >
              Worksheet Type
              <span> ▼</span>
            </ToggleButton>
          )}
          {store.countryFilter && (
            <ToggleButton
              borderless={true}
              name="search-list-filter"
              value={FILTER_COLUMN.WORKSHEET_COUNTRY}
              selected={Boolean(store.appliedFilters[FILTER_COLUMN.WORKSHEET_COUNTRY])}
              onClick={store.countryFilter.onShowModal}
            >
              <SortIndicator
                sortColumn={FILTER_COLUMN.WORKSHEET_COUNTRY}
                sortsOrder={store.appliedSortsOrder}
                sort={store.appliedSorts[FILTER_COLUMN.WORKSHEET_COUNTRY]}
              />
              Country <span> ▼</span>
            </ToggleButton>
          )}
          {store.createdOnFilter && (
            <ToggleButton
              borderless={true}
              name="rate-cards-filter"
              value={FILTER_COLUMN.WORKSHEET_DATE}
              selected={Boolean(store.appliedFilters[FILTER_COLUMN.WORKSHEET_DATE])}
              onClick={store.createdOnFilter.onShowModal}
            >
              <SortIndicator
                sortType="numeric"
                sortColumn={FILTER_COLUMN.WORKSHEET_DATE}
                sortsOrder={store.appliedSortsOrder}
                sort={store.appliedSorts[FILTER_COLUMN.WORKSHEET_DATE]}
              />
              Created On
              <span> ▼</span>
            </ToggleButton>
          )}
          {store.markUpOnFilter && (
            <ToggleButton
              borderless={true}
              name="rate-cards-filter"
              value={FILTER_COLUMN.MARKUP_AMT}
              selected={Boolean(store.appliedFilters[FILTER_COLUMN.MARKUP_AMT])}
              onClick={store.markUpOnFilter.onShowModal}
            >
              <SortIndicator
                sortType="numeric"
                sortColumn={FILTER_COLUMN.MARKUP_AMT}
                sortsOrder={store.appliedSortsOrder}
                sort={store.appliedSorts[FILTER_COLUMN.MARKUP_AMT]}
              />
              Markup
              <span> ▼</span>
            </ToggleButton>
          )}
        </CardFilters>
        <div
          className={classNames("ratecards-table", {
            "ratecards-table-fill-container": fillContainer,
          })}
        >
          <div style={listBodyStyles}>
            {selectAllSection}
            {tableContent}
          </div>
        </div>
        {store.pagination.pageCount > 0 && (
          <Pagination
            options={{
              variant: "full",
              currentPage: store.pagination.currentPage,
              numPages: store.pagination.pageCount,
            }}
            onPageClick={(pageEvent) => {
              if (pageEvent.type === "first") {
                this.changeCurrent(1);
              }
              if (pageEvent.type === "last") {
                this.changeCurrent(store.pagination.pageCount);
              }
              if (pageEvent.type === "next") {
                this.changeCurrent(
                  Math.min(store.pagination.currentPage + 1, store.pagination.pageCount)
                );
              }
              if (pageEvent.type === "prev") {
                this.changeCurrent(Math.max(store.pagination.currentPage - 1, 1));
              }
              if (pageEvent.type === "page") {
                this.changeCurrent(pageEvent.page);
              }
            }}
          />
        )}
      </>
    );
  }
}

NegotiationWorksheetList.propTypes = {
  store: PropTypes.object.isRequired,
  fillContainer: PropTypes.bool,
};

export default observer(NegotiationWorksheetList);
