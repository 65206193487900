// @flow
import moment from "moment";
import { action } from "mobx";

export const BATCH_SEARCH_JL_STATUS = {
  CREATED: 0,
  QUEUED: 1,
  CREATING_RATE_CARD: 2,
  RUNNING: 3,
  FINISHED: 4,
  FINISHED_WITH_FAILURES: 5,
  FAILED: 6,
};

export const BATCH_SEARCH_PSP_STATUS = {
  QUEUED: 0,
  CREATING_RATE_CARD: 1,
  RUNNING: 2,
  FINISHED: 3,
  FINISHED_WITH_FAILURES: 4,
  FAILED: 5,
};

export default class BatchSearch {
  // sample of payloadObject data {
  // uuid: "31f173b2-4bc2-4a9c-92aa-cfc9a1be2ea0"
  // status: 3
  // status_display: "Finished"
  // rate_card_id: 156575
  // rate_card_label: "dl testing"
  // searches_queued: 0
  // searches_running: 0
  // searches_finished: 1
  // searches_failed: 0
  // searches_total: 1
  // created: "2018-02-07T17:34:37.655268Z"
  // updated: "2018-02-07T17:34:38.400144Z"
  // finished: "2018-02-07T17:34:38.399988Z"
  // }

  store: Object;
  id: string;
  uuid: string;
  status: ?number;
  statusDisplay: ?string;
  rateCardId: ?number;
  rateCardLabel: ?string;
  searchesQueued: ?number;
  searchesRunning: ?number;
  searchesFinished: ?number;
  searchesFailed: ?number;
  searchesTotal: ?number;
  created: ?Object;
  updated: ?Object;
  finished: ?Object;
  createdDisplay: ?string;

  viewState: {
    selected: boolean,
    editing: boolean,
  };
  toggleEdit: () => void;
  toggleSelected: (
    e: Object,
    ignore1?: ?string,
    ignore2?: ?string,
    setValue?: ?boolean
  ) => void;
  destroy: () => void;

  constructor(store: Object, payloadObject: Object) {
    this.store = store;
    // this.id = null;
    this.status = null;
    this.statusDisplay = null;
    this.rateCardId = null;
    this.rateCardLabel = null;
    this.searchesQueued = null;
    this.searchesRunning = null;
    this.searchesFinished = null;
    this.searchesFailed = null;
    this.searchesTotal = null;
    this.created = null;
    this.updated = null;
    this.finished = null;
    this.createdDisplay = null;
    this.viewState = {
      selected: false,
      editing: false,
    };

    if (payloadObject) {
      this.id = payloadObject.uuid;
      this.uuid = payloadObject.uuid;
      this.status = payloadObject.status;
      this.statusDisplay = payloadObject.statusDisplay;
      this.rateCardId = payloadObject.rateCardId;
      this.rateCardLabel = payloadObject.rateCardLabel;
      this.searchesQueued = payloadObject.searchesQueued;
      this.searchesRunning = payloadObject.searchesRunning;
      this.searchesFinished = payloadObject.searchesFinished;
      this.searchesFailed = payloadObject.searchesFailed;
      this.searchesTotal = payloadObject.searchesTotal;
      this.created = moment(payloadObject.created);
      this.updated = moment(payloadObject.updated);
      this.finished = moment(payloadObject.finished);
      if (this.created) {
        this.createdDisplay = `${this.created.format("MMMM D, YYYY, h:mm a")}`;
      }
    }

    this.toggleEdit = action(this.toggleEdit.bind(this));
    this.toggleSelected = action(this.toggleSelected.bind(this));
    this.destroy = action(this.destroy.bind(this));
  }

  toggleEdit() {
    const viewState = this.store.batchSearchesViewState.get(this.id);

    viewState.editing = !viewState.editing;
  }

  toggleSelected(
    e: Object,
    ignore1: ?string = null,
    ignore2: ?string = null,
    setValue: ?boolean = null
  ) {
    const viewState = this.store.batchSearchesViewState.get(this.id);

    if (setValue !== null) {
      viewState.selected = setValue;
      return;
    } else {
      viewState.selected = !viewState.selected;

      if (!viewState.selected && this.store.allSelected) {
        this.store.allSelected = false;
      }
    }

    if (!this.store.allowMultipleItemSelection) {
      // deselect all other rate cards
      this.store.batchSearchesViewState.forEach((viewState) => {
        if (this.viewState === viewState) return;

        viewState.selected = false;
      });
    }
  }

  destroy() {
    this.store.batchSearches.remove(this);
  }

  static fromJS(store: Object, object: Object) {
    return new BatchSearch(store, object);
  }
}
