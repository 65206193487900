import * as React from "react";

interface PageContentProps {
  children: React.ReactNode;
}

function PageContent(props: PageContentProps) {
  return <div id="v7-stitches-content">{props.children}</div>;
}

export default PageContent;
