import React, { useState, useCallback } from "react";

import {
  Card,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
  CardHeaderTitle,
  CardBody,
} from "../../../components/lib/Card";
import ReviewsPeckingOrderTable, {
  AttemptsPeckingOrderTable,
} from "./ReviewsPeckingOrderTable";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";
import { transformReviewsData } from "../dataConverters";
import { emptyOrderedMap } from "../../../constants";
import { TickerContentLoader } from "../../../components/lib/TickerLoader";
import { useInitialFetch } from "../../../utils/hooks";
import { useReviewDataContext } from "../context/ReviewDataContext";
import { useAttemptDataContext } from "../context/AttemptDataContext";
import { useVal5KAdminContext } from "../context/Val5KAdminContext";

import type { ReviewDataObject, ReviewsDataOrderedMap } from "../types";
import type { FetchAPIResponse } from "../../../types/fetch";
import type { DjangoPaginatedResponse } from "../../../types/django";
import type { UrlQueryObject } from "../../../components/tables/types";

const ReviewsPeckingOrderBlock = () => {
  const { fetchArgusFilteringAPI, showModalError } = useVal5KAdminContext();
  const { validatorEmail } = useAttemptDataContext();
  const { reviewId, reviewsGroupId } = useReviewDataContext();

  const [reviewsGroupData, setReviewsGroupData] = useState<ReviewsDataOrderedMap>(
    emptyOrderedMap as unknown as ReviewsDataOrderedMap
  );

  const fetchGroupedReviewsList = useCallback(async () => {
    const queryArgs: UrlQueryObject = {
      reviews_group_id: reviewsGroupId,
    };

    if (validatorEmail) {
      queryArgs["email"] = validatorEmail;
    }

    try {
      const response: FetchAPIResponse<DjangoPaginatedResponse<ReviewDataObject>> =
        await fetchArgusFilteringAPI(`reviews/grouped/filtered/`, { params: queryArgs });
      const data = transformReviewsData(response.data);

      setReviewsGroupData(data.data);
    } catch (err: any) {
      logAsyncOperationError("fetchGroupedReviewsList", err);
      showModalError(
        "Error occurred while loading reviews grouped list. Please, try again later."
      );
      throw err;
    }
  }, [
    reviewsGroupId,
    validatorEmail,
    setReviewsGroupData,
    fetchArgusFilteringAPI,
    showModalError,
  ]);

  // initial data fetch

  useInitialFetch(fetchGroupedReviewsList);

  // render

  if (!reviewsGroupData.size) {
    return <TickerContentLoader />;
  }

  const PeckingOrderTableImpl = validatorEmail
    ? AttemptsPeckingOrderTable
    : ReviewsPeckingOrderTable;

  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft>
          <CardHeaderTitle as="h3">
            Pecking Order Surveys List {validatorEmail ? `(${validatorEmail})` : ""}
          </CardHeaderTitle>
        </CardActionsLeft>
        <CardActionsRight />
      </CardActions>
      <CardBody>
        <PeckingOrderTableImpl
          reviewId={reviewId}
          reviewsGroupId={reviewsGroupId}
          reviewsData={reviewsGroupData}
        />
      </CardBody>
    </Card>
  );
};
ReviewsPeckingOrderBlock.displayName = "ReviewsPeckingOrderBlock";

export default ReviewsPeckingOrderBlock;
