import React, { useEffect } from "react";
import graphql from "babel-plugin-relay/macro";
import { useRefetchableFragment } from "react-relay";
import { DescriptionField_viewer$key } from "./__generated__/DescriptionField_viewer.graphql";
import { DescriptionFieldRefetch } from "./__generated__/DescriptionFieldRefetch.graphql";
import {
  FormControl,
  Label,
  LabelWrapper,
  RequiredSign,
} from "../../../components/lib/Label";
import { styled } from "../../../stitches.config";
import { Placeholder } from "../../../components/lib/Placeholder";
import { useRateSearchFormStore } from "../../../stores/RateSearchForm";
import { useGlobalContext } from "../../../globalContext";
import { jobTitleObserver } from "../observers";
import { JobTitle } from "../types";

const height = "200px";

const Textarea = styled("textarea", {
  borderRadius: "$rounded",
  border: "solid 1px $primaryLight",
  fontSize: "$base",
  padding: "$4",
  width: "100%",
  height,
  resize: "vertical",
  "&:disabled": {
    backgroundColor: "$primaryLighter",
  },
});

const gql = graphql`
  fragment DescriptionField_viewer on Viewer
  @refetchable(queryName: "DescriptionFieldRefetch")
  @argumentDefinitions(
    jobTitleId: { type: "Int", defaultValue: 0 }
    skipQuery: { type: "Boolean", defaultValue: true }
  ) {
    jobTitle(id: $jobTitleId) @skip(if: $skipQuery) {
      description
    }
  }
`;

type Props = {
  viewer: DescriptionField_viewer$key;
  isSideMenu: boolean;
};

export function DescriptionField(props: Props) {
  const { viewer, isSideMenu } = props;

  const { perSearchPricing, isClientJobLibrary } = useGlobalContext();

  const [data, refetch] = useRefetchableFragment<
    DescriptionFieldRefetch,
    DescriptionField_viewer$key
  >(gql, viewer);

  const jobTitle = useRateSearchFormStore((s) => s.jobTitle);
  const setDescription = useRateSearchFormStore((s) => s.setDescription);
  const description = useRateSearchFormStore((s) => s.description);

  const disabled = (perSearchPricing && jobTitle?.isJobLabel) || isClientJobLibrary;

  useEffect(() => {
    const initialDescription = data?.jobTitle?.description || "";
    setDescription(initialDescription);
  }, [data, setDescription]);

  useEffect(() => {
    function listener(jobTitle: JobTitle | null | undefined) {
      if (jobTitle?.id) {
        const jobTitleId = Number(jobTitle.id);
        refetch({ jobTitleId, skipQuery: false });
      }
    }
    jobTitleObserver.subscribe(listener);
    return () => jobTitleObserver.unsubscribe(listener);
  }, [refetch]);

  if ((disabled && !description) || isSideMenu) {
    return null;
  }

  return (
    <FormControl fill>
      <LabelWrapper>
        <Label>
          Job Description
          <RequiredSign>&nbsp;*</RequiredSign>
        </Label>
      </LabelWrapper>
      <Textarea
        value={description}
        disabled={disabled}
        onChange={(e) => setDescription(e.target.value)}
        data-testid="job-description-field"
      />
    </FormControl>
  );
}

export function DescriptionLoader() {
  return (
    <FormControl fill>
      <LabelWrapper>
        <Label>
          Job Description
          <RequiredSign>&nbsp;*</RequiredSign>
        </Label>
      </LabelWrapper>
      <Placeholder height={height} />
    </FormControl>
  );
}
