import React from "react";

import Stack from "../../../../components/lib/Stack";
import Grid from "../../../../components/lib/Grid";
import Button from "../../../../components/lib/Button";
import PromiseButton from "../../../../components/lib/PromiseButton";
import Text from "../../../../components/lib/Text";
import { SimpleLink } from "../../../../components/lib/Link";
import {
  Card,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
  CardHeaderTitle,
  CardBody,
} from "../../../../components/lib/Card";
import { TextOverflow } from "../../../../components/lib/Text";
import { useReviewDataContext } from "../../context/ReviewDataContext";
import ReviewPrimaryParametersBlock from "../../components/ReviewPrimaryParametersBlock";
import ReviewSecondaryParametersBlock from "./ReviewSecondaryParametersBlock";
import { ReplacementsAlert, SurveysGroupAlert } from "../../components/Alerts";

type SurveyParametersBlockProps = {
  editing: boolean;
  jobTitle: string;
  jobDescription: string;
  onChangeJobDescription: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onChangeJobTitle: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onResolveReplacements: () => void;
  onEdit: () => void;
  onApplyEdit: (params: { jobTitle: string; jobDescription: string }) => Promise<void>;
  onCancelEdit: () => void;
};

const ReviewParametersBlock = (props: SurveyParametersBlockProps) => {
  const {
    editing,
    jobTitle,
    jobDescription,
    onChangeJobDescription,
    onChangeJobTitle,
    onResolveReplacements,
    onEdit,
    onApplyEdit,
    onCancelEdit,
  } = props;

  const {
    reviewLink,
    reviewsGroupId,
    isEditable,
    isClassicSurvey,
    isFillInTheBlankSurvey,
  } = useReviewDataContext();

  const handleApply = React.useCallback(async () => {
    await onApplyEdit({ jobTitle, jobDescription });
  }, [jobTitle, jobDescription, onApplyEdit]);

  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft>
          <CardHeaderTitle as="h3">Survey Parameters</CardHeaderTitle>
        </CardActionsLeft>
        <CardActionsRight>
          {(isClassicSurvey || isFillInTheBlankSurvey) && isEditable && !editing && (
            <Button icon={["far", "edit"]} onClick={onEdit}>
              Edit
            </Button>
          )}
          {(isClassicSurvey || isFillInTheBlankSurvey) && isEditable && editing && (
            <PromiseButton color="success" icon="check" onClick={handleApply}>
              Apply
            </PromiseButton>
          )}

          {(isClassicSurvey || isFillInTheBlankSurvey) && isEditable && editing && (
            <Button color="danger" icon="times" onClick={onCancelEdit}>
              Cancel
            </Button>
          )}
        </CardActionsRight>
      </CardActions>
      <CardBody>
        <Stack css={{ alignItems: "flex-start" }}>
          {reviewLink && (
            <TextOverflow>
              <Text css={{ fontSize: "$base" }}>Link To The Survey:</Text>
              <SimpleLink
                href={reviewLink}
                target="_blank"
                rel="noreferrer"
                css={{ marginLeft: "$2" }}
              >
                {reviewLink}
              </SimpleLink>
            </TextOverflow>
          )}

          {(isClassicSurvey || isFillInTheBlankSurvey) && (
            <ReplacementsAlert onClick={onResolveReplacements} />
          )}

          {(isClassicSurvey || isFillInTheBlankSurvey) && !!reviewsGroupId && (
            <SurveysGroupAlert />
          )}

          <Grid
            css={{
              width: "$full",
              gridTemplateColumns: "$auto",
              gridAutoFlow: "row",
              "@lg": {
                gridAutoFlow: "column",
                gridTemplateColumns: "$auto $3-of-5",
              },
            }}
          >
            <ReviewPrimaryParametersBlock
              isEditable={true}
              isEditing={editing}
              jobTitle={jobTitle}
              jobDescription={jobDescription}
              onChangeJobDescription={onChangeJobDescription}
              onChangeJobTitle={onChangeJobTitle}
            />
            <ReviewSecondaryParametersBlock />
          </Grid>
        </Stack>
      </CardBody>
    </Card>
  );
};

ReviewParametersBlock.displayName = "ReviewParametersBlock";

export default ReviewParametersBlock;
