import React, { useState, useCallback } from "react";

import Stack from "../../../../components/lib/Stack";
import Button from "../../../../components/lib/Button";
import Inline from "../../../../components/lib/Inline";
import Text from "../../../../components/lib/Text";
import TextInput from "../../../../components/lib/TextInput";
import PromiseButton from "../../../../components/lib/PromiseButton";
import { ButtonGroupRight } from "../../../../components/lib/ButtonGroup";

type ReviewJobTitleBulkUpdateFormProps = {
  defaultValue: string | null;
  onApply: (params: { jobTitle: string }) => Promise<void>;
  onCancel: () => void;
};

const ReviewJobTitleBulkUpdateForm = (props: ReviewJobTitleBulkUpdateFormProps) => {
  const { defaultValue, onApply, onCancel } = props;

  const [jobTitle, setJobTitle] = useState<string>(defaultValue || "");

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setJobTitle(event.target.value);
  }, []);

  const handleApply = useCallback(async () => {
    await onApply({ jobTitle });
  }, [jobTitle, onApply]);

  return (
    <Stack>
      <Inline nowrap fill>
        <Text nowrap>Job Title:</Text>
        <TextInput fill value={jobTitle} onChange={handleChange} />
      </Inline>

      <ButtonGroupRight fill css={{ flexDirection: "row-reverse" }}>
        <Button size="large" onClick={onCancel}>
          Cancel
        </Button>
        <PromiseButton
          color="brand"
          size="large"
          loadingText="Apply"
          onClick={handleApply}
        >
          Apply
        </PromiseButton>
      </ButtonGroupRight>
    </Stack>
  );
};
ReviewJobTitleBulkUpdateForm.displayName = "ReviewJobTitleBulkUpdateForm";

export default ReviewJobTitleBulkUpdateForm;
