import React from "react";
import moment from "moment-timezone";

import { SingleDatePicker } from "../../SingleDatePicker";
import { FilterTypes, emptyList, djangoDateFormat } from "../constants";
import Stack from "../../lib/Stack";
import Grid from "../../lib/Grid";
import { FilterConfigMap } from "../types";
import { Focusable, OnChangeFilterValueFunction } from "./types";
import { CSS } from "../../../stitches.config";

const FieldIndex = {
  FROM: 0,
  TO: 1,
};

type DatesFilterProps = {
  filter: FilterConfigMap;
  onChange: OnChangeFilterValueFunction;
  datesFormat?: string;
  timezone?: string;
  css?: CSS;
};

const DatesFilter = React.forwardRef(
  (props: DatesFilterProps, ref: React.ForwardedRef<Focusable>) => {
    const { filter, onChange, datesFormat, css } = props;

    const selectedValues = filter.getIn(["filter", "values"]);
    const fromValue = selectedValues.get(FieldIndex.FROM)
      ? moment.utc(selectedValues.get(FieldIndex.FROM), datesFormat)
      : null;
    const toValue = selectedValues.get(FieldIndex.TO)
      ? moment.utc(selectedValues.get(FieldIndex.TO), datesFormat)
      : null;

    // interface methods

    const firstInputRef = React.useRef<HTMLInputElement>(null);

    React.useImperativeHandle(ref, () => ({
      focus: () => firstInputRef?.current?.focus(),
    }));

    // handlers

    const handleChange = React.useCallback(
      (fieldIdx, value: moment.Moment) => {
        const currentValue = selectedValues.get(fieldIdx);
        const otherValue = selectedValues.get(
          fieldIdx === FieldIndex.FROM ? FieldIndex.TO : FieldIndex.FROM
        );
        const resultingValue = value
          ? value
              .utc(true)
              .hour(fieldIdx === FieldIndex.FROM ? 0 : 23)
              .minute(fieldIdx === FieldIndex.FROM ? 0 : 59)
              .second(fieldIdx === FieldIndex.FROM ? 0 : 59)
              .millisecond(fieldIdx === FieldIndex.FROM ? 0 : 999)
              .format(datesFormat)
          : undefined;

        if (!resultingValue && !otherValue) {
          onChange(
            filter
              .setIn(["filter", "values"], emptyList)
              .setIn(["filter", "type"], FilterTypes.DATES_RANGE) as FilterConfigMap
          );
        } else if (resultingValue !== currentValue) {
          onChange(
            filter
              .setIn(["filter", "values"], selectedValues.set(fieldIdx, resultingValue))
              .setIn(["filter", "type"], FilterTypes.DATES_RANGE) as FilterConfigMap
          );
        }
      },
      [filter, selectedValues, datesFormat, onChange]
    );

    return (
      <Stack fill css={{ alignItems: "stretch", gap: "$2", ...css }}>
        <span>Select dates range:</span>
        <Grid
          css={{
            paddingLeft: "$2",
            gridTemplateColumns: "40px auto",
            alignItems: "center",
            gap: "$2",
          }}
        >
          <label>From: </label>
          <SingleDatePicker
            ref={firstInputRef}
            date={fromValue}
            onDateChange={(value: moment.Moment) => handleChange(FieldIndex.FROM, value)}
          />
          <label>To: </label>
          <SingleDatePicker
            date={toValue ? moment.utc(toValue, datesFormat) : null}
            onDateChange={(value: moment.Moment) => handleChange(FieldIndex.TO, value)}
          />
        </Grid>
      </Stack>
    );
  }
);

DatesFilter.displayName = "DatesFilter";
DatesFilter.defaultProps = {
  datesFormat: djangoDateFormat,
  timezone: "UTC",
};

export default DatesFilter;
