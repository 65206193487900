// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import LinkButton from "../../components/lib/LinkButton";
import Modal from "../ratecards/components/Modal";
import ContainerSection from "../ratecards/components/ContainerSection";
import RateAndPayTypePicker from "./components/RateAndPayTypePicker";
import GroupList from "./components/GroupList";
import Overview from "./components/Overview";
import GroupChartList from "./components/GroupChartList";
import CalculatedResults from "./components/CalculatedResults";
import CalculatedResultsForSalary from "./components/CalculatedResultsForSalary";
import WorkerTypeCard from "./components/WorkerTypeCard";
import { fieldTypeEq, rateTypeContains } from "./utils/filter";
import { BottomControls, BottomControlGroup } from "../../components/material";
import ResultSummary from "./components/ResultSummary";
import ResultsSummaryForSalary from "./components/ResultsSummaryForSalary";
import exportToExcel from "./utils/exportToExcel";
import SalaryExportToExcel from "./utils/SalarytypeExportToExcel";
import DownloadPOSTButton from "../../components/buttons/DownloadPOSTButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TagItem from "../ratecards/components/TagItem";
import ApplyTagFilterModal from "../shared/modals/ApplyTagFilterModal";
import LoadingIndicator from "../shared/LoadingIndicator";
import Inline from "../../components/lib/Inline";
import Button from "../../components/lib/Button";

const WorksheetDetail = observer((props) => {
  const store = props.store;
  const createStore = props.createStore;
  let location,
    worksheetName,
    owner,
    tagSection,
    createdDate = null;

  if (store.network.loading) {
    location = "...";
    worksheetName = "...";
    createdDate = "...";
    owner = "...";
  } else if (store.negDetail) {
    if (createStore.checkLegacyStd()) {
      location = `${store.negDetail.state.name}, ${store.negDetail.country.name}`;
    } else {
      location = `${store.negDetail.country.name}`;
    }
    worksheetName = store.negDetail.worksheetName;
    createdDate = store.negDetail.createdDisplay;
    owner = `${store.negDetail.owner.firstName} ${store.negDetail.owner.lastName}`;
    tagSection = store.negDetail.tags.map((tag) => (
      <TagItem
        key={tag.tagId}
        tag={tag}
        onClick={(event) => {
          props.onClick(event, tag.tagId);
        }}
      />
    ));
  }
  const { unsavedValues, rateType } = props.createStore.currentState;
  const { worksheetSchema, selectedWorkerType, values } = props.createStore;
  const calculatedResults = props.createStore.calculatePayAndBillRate(
    worksheetSchema.conversionOptions
  );
  //const { payRateHourly, billRateHourly, totalMarkup, totalTax, billRateHourlyAfterTax } = calculatedResults;
  const workerType = selectedWorkerType;

  let actionBar = null;
  if (!store.network.loading && !store.network.error)
    actionBar = (
      <Inline>
        <Button
          disabled={store.selectedCount === 0}
          onClick={store.applyTagState.showTagModal}
        >
          Apply Tag
        </Button>
        {
          <Button icon="edit" onClick={store.showRenameWorksheetModal}>
            Rename
          </Button>
        }
        {store.canExport() && props.createStore.currentState.payType !== 3 && (
          <DownloadPOSTButton
            className=""
            style={{ bordeRadius: "4px", height: "30px", fontSize: "14px" }}
            service="excelsior"
            endpoint="excel-export/"
            getFormData={() =>
              exportToExcel(
                worksheetSchema,
                values,
                unsavedValues,
                worksheetName,
                calculatedResults,
                rateType,
                workerType,
                location
              )
            }
          >
            <FontAwesomeIcon icon="download" /> Export
          </DownloadPOSTButton>
        )}
        {store.canExport() && props.createStore.currentState.payType === 3 && (
          <DownloadPOSTButton
            className=""
            style={{ bordeRadius: "4px", height: "30px", fontSize: "14px" }}
            service="excelsior"
            endpoint="excel-export/"
            getFormData={() =>
              SalaryExportToExcel(
                worksheetSchema,
                values,
                unsavedValues,
                worksheetName,
                calculatedResults,
                rateType,
                workerType
              )
            }
          >
            <FontAwesomeIcon icon="download" /> Export
          </DownloadPOSTButton>
        )}

        {
          <Button
            color="danger"
            icon="trash-alt"
            onClick={store.confirmDeleteModal.showModal}
          >
            Delete
          </Button>
        }
      </Inline>
    );

  return (
    <div className="top">
      <div className="above">
        <div className="ratecard-label-header ellipsis max-width-80">
          <h2 className="ellipsis">{worksheetName} </h2>
        </div>
        <Inline>
          <LinkButton color="brand" to={`/negotiation-worksheets/`}>
            <FontAwesomeIcon icon="arrow-left" fixedWidth className="icon" />
            <span>Go Back</span>
          </LinkButton>
          {/*<button className="btn" onClick={store.showHelp}>*/}
          {/*  <FontAwesomeIcon icon="question-circle" fixedWidth className="icon" />*/}
          {/*  <span>Help</span>*/}
          {/*</button>*/}
        </Inline>
      </div>
      <div className="below">
        <div className="ratecard-meta ellipsis">
          <div className="ratecard-owner ellipsis">
            <FontAwesomeIcon icon="user" fixedWidth className="icon" />
            <span />
            <span />
            <span>{owner}</span>
          </div>
          <div className="ratecard-created-at ellipsis">
            <FontAwesomeIcon icon="calendar-alt" fixedWidth className="icon" />
            <span />
            <span />
            <span> {createdDate}</span>
          </div>
          <div className="ratecard-created-at ellipsis">
            <FontAwesomeIcon icon="map-marker" fixedWidth className="icon" />
            <span />
            <span />
            <span>{location}</span>
          </div>
        </div>
        {actionBar}
      </div>
      <div className="tagbox">{tagSection}</div>
      <Modal show={store.showHelpModal.show} onHide={store.showHelpModal.hideModal}>
        <div className="container-section header">
          <h4>Help</h4>
        </div>
        <div className="container-section footer">
          <p>Here you can see your Worksheet</p>
        </div>
      </Modal>
      <Modal
        show={store.renameWorksheetModal.show}
        onHide={store.renameWorksheetModal.hideModal}
      >
        <ContainerSection className="header">
          <h4>Rename Worksheet</h4>
        </ContainerSection>
        <ContainerSection>
          <p>Name of the Worksheet:</p>
          <input
            type="text"
            className="rc-input-box"
            style={{
              width: "100%",
            }}
            value={store.newWorksheetName}
            onChange={store.onNewWorksheetNameChange}
          />
          <div className="pt-user-messages">
            {store.messaging.messages.length > 0 && (
              <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                <a
                  href="#close"
                  className="close"
                  data-dismiss="alert"
                  aria-label="close"
                  onClick={store.messaging.removeMessage.bind(
                    store,
                    store.messaging.messages[0]
                  )}
                >
                  &times;
                </a>
                <strong>{store.messaging.messages[0].messageLabel}</strong>
                {store.messaging.messages[0].message}
              </div>
            )}
          </div>
        </ContainerSection>
        <ContainerSection className="footer">
          <Inline>
            <Button size="large" onClick={store.renameWorksheetModal.hideModal}>
              Cancel
            </Button>
            <Button size="large" color="brand" onClick={store.renameNegotiationWorksheet}>
              Rename Worksheet
            </Button>
          </Inline>
        </ContainerSection>
      </Modal>
      <Modal
        show={store.confirmDeleteModal.show}
        onHide={store.confirmDeleteModal.hideModal}
      >
        <div className="container-section header">
          <h4>Confirmation Needed</h4>
        </div>
        <div className="container-section">
          <p>Are you sure you want to delete this worksheet?</p>
          <p className="text-danger">
            <FontAwesomeIcon icon="exclamation-triangle" />{" "}
            <em>This action cannot be undone!</em>
          </p>
        </div>
        <div className="container-section footer">
          <Inline>
            <Button size="large" onClick={store.confirmDeleteModal.hideModal}>
              No, Cancel
            </Button>
            <Button
              color="danger"
              size="large"
              onClick={(e) => {
                store.confirmDeleteModal.hideModal();
                store.deleteWorksheet(store.negDetail.worksheetId);
              }}
            >
              Yes, Delete Worksheet
            </Button>
          </Inline>
        </div>
      </Modal>
      <ApplyTagFilterModal
        filterState={store.applyTagState}
        applyTagsEvent={store.applyTags}
        btnText="Apply Tag to Worksheets"
      />
    </div>
  );
});

class Detail extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor() {
    super();
    this.deleteTag = this.deleteTag.bind(this);
  }

  componentDidMount() {
    const store = this.props.store.negotiationWorksheetDetailStore;
    const createStore = this.props.store.negotiationWorksheetCreateStore;
    store.worksheetId = this.props.router.params.worksheetId;
    store.router = this.context.router;
    store.fetchWorksheet();
    createStore.undoQueue = [];
    createStore.redoQueue = [];
    createStore.currentState = {};
  }

  deleteTag(e: object, tag_id) {
    this.props.store.negotiationWorksheetDetailStore.deleteSingleTags({
      tagId: tag_id,
      contentId: this.props.router.params.worksheetId,
      contentType: "NEGOTIATION_WORKSHEET",
    });
  }

  getFieldKeySetSeqForSalaryType() {
    return this.getFieldsForSalaryType()
      ? this.getFieldsForSalaryType().map((field) => field.key)
      : [];
  }

  setScriptedPercentage() {
    return this.hasFieldOfType("scriptedPercentage");
  }

  hasFieldOfType(fieldType) {
    const { worksheetSchema } = this.props.store.negotiationWorksheetCreateStore;

    if (!worksheetSchema.fields) return null;

    return worksheetSchema.fields.some(fieldTypeEq(fieldType));
  }

  getFieldsForSalaryType() {
    const { worksheetSchema } = this.props.store.negotiationWorksheetCreateStore;

    if (!worksheetSchema.fields) return null;

    return worksheetSchema.fields.filter(rateTypeContains("salary"));
  }

  render() {
    const store = this.props.store.negotiationWorksheetDetailStore;
    const createStore = this.props.store.negotiationWorksheetCreateStore;
    const salaryFieldsCheck = this.getFieldKeySetSeqForSalaryType();
    //const payTypeBillRateDisabled = this.setScriptedPercentage();
    const conversionOptions =
      createStore.worksheetSchema && createStore.worksheetSchema.conversionOptions
        ? createStore.worksheetSchema.conversionOptions
        : "";
    const calculatedResults = createStore.calculatePayAndBillRate(conversionOptions);
    const {
      payRateHourly,
      billRateHourly,
      totalMarkup,
      totalTax,
      billRateHourlyAfterTax,
    } = calculatedResults;
    //const {unsavedValues} = createStore.currentState;
    const { valUpdated } = createStore;
    let saveText = `Saved (Revision ${store.revisionNumber})`;
    if (!createStore.canSave()) saveText = `Saved (Revision ${store.revisionNumber})`;
    else if (createStore.hasUnsavedChanges()) saveText = "Save New Revision";

    return (
      <div className="view">
        <div className="rc-container show-overflow">
          <WorksheetDetail
            store={store}
            router={this.props.router}
            createStore={createStore}
            onClick={this.deleteTag}
          />
        </div>
        {store.network.error && (
          <div className="rc-container bring-forward">
            <div className="pt-error-message fade-in">
              <h2>Error</h2>
              <pre>
                <code>{JSON.stringify(store.network.error, null, 2)}</code>
              </pre>
            </div>
          </div>
        )}
        {!store.isLatest && (
          <div className="clearfix">
            <div className="alert alert-warning alert-dismissable fade in pt-user-alert pull-left ws-alert">
              There are updates available to this worksheet, use the "update schema"
              button to get the next version of this worksheet.
              {createStore.hasUnsavedChanges() && (
                <span>
                  <br />
                  <strong>IMPORTANT: </strong>You have unsaved changes. Save before
                  updating the schema if you want to keep your changes.
                </span>
              )}
            </div>
            <Button
              color="brand"
              size="large"
              className="pull-right"
              style={{ marginTop: "10px" }}
              onClick={store.updateSchema}
            >
              Update Schema
            </Button>
          </div>
        )}

        <div className="rc-container bring-forward">
          <div className="header-action-bar">
            <div className="row">
              {!createStore.worksheetSchema.currencySymbol && <LoadingIndicator />}
              <div className="col-xs-6">
                {createStore.worksheetSchema.currencySymbol && (
                  <div className="rc-container">
                    <RateAndPayTypePicker
                      payTypeBillRateDisabled={createStore.payTypeBillRateDisabled}
                      store={createStore}
                      currencySymbol={createStore.worksheetSchema.currencySymbol}
                      payValue={createStore.currentState.payValue}
                      salaryFieldsCheck={salaryFieldsCheck.length}
                      setPayValue={createStore.setPayValue.bind(this, createStore)}
                      setPayType={createStore.setPayType.bind(this, createStore)}
                      setRateType={createStore.setRateType.bind(this, createStore)}
                      payType={createStore.currentState.payType}
                      rateType={
                        createStore.currentState.payType === 3
                          ? ""
                          : createStore.currentState.rateType
                      }
                      workerTypeKey={
                        createStore.currentState.payType === 3
                          ? ""
                          : createStore.currentState.workerTypeKey
                      }
                      setWorkerType={createStore.setWorkerType.bind(this, createStore)}
                      workerTypes={createStore.worksheetSchema.workerTypes}
                    />
                  </div>
                )}
                {(createStore.currentState.rateType &&
                  createStore.selectedWorkerType.id) ||
                createStore.currentState.payType === 3 ? (
                  <GroupList
                    groups={createStore.worksheetSchema.groups}
                    fields={createStore.worksheetSchema.fields}
                    workerTypeId={createStore.selectedWorkerType.id}
                    currencySymbol={createStore.worksheetSchema.currencySymbol}
                    payRateHourly={payRateHourly}
                    billRateHourly={billRateHourly}
                    conversionOptions={conversionOptions}
                    store={createStore}
                    valUpdated={valUpdated}
                  />
                ) : (
                  ""
                )}
                {createStore.currentState.rateType &&
                  createStore.selectedWorkerType.id &&
                  createStore.currentState.payType !== 3 && (
                    <CalculatedResults
                      store={createStore}
                      payRateHourly={parseFloat(payRateHourly)}
                      conversionOptions={conversionOptions}
                      totalMarkup={parseFloat(totalMarkup)}
                      billRateHourly={parseFloat(billRateHourly)}
                      currencySymbol={createStore.worksheetSchema.currencySymbol}
                      totalTax={parseFloat(totalTax)}
                      billRateHourlyAfterTax={parseFloat(billRateHourlyAfterTax)}
                    />
                  )}
                {createStore.currentState.payType === 3 && (
                  <CalculatedResultsForSalary
                    conversionOptions={conversionOptions}
                    totalMarkup={parseFloat(totalMarkup)}
                    totalTax={parseFloat(totalTax)}
                    currencySymbol={createStore.worksheetSchema.currencySymbol}
                    payValue={createStore.currentState.payValue}
                  />
                )}
              </div>
              <div className="col-xs-6">
                {createStore.selectedWorkerType &&
                  createStore.selectedWorkerType.id &&
                  createStore.currentState.rateType &&
                  createStore.currentState.payType !== 3 && (
                    <div className="rc-container">
                      <WorkerTypeCard workerType={createStore.selectedWorkerType} />
                    </div>
                  )}
                {createStore.worksheetSchema.overviews && (
                  <div className="rc-container">
                    <Overview store={createStore} isExpanded={createStore.isExpanded} />
                  </div>
                )}
                {(createStore.currentState.rateType &&
                  createStore.selectedWorkerType.id) ||
                createStore.currentState.payType === 3 ? (
                  <GroupChartList
                    groups={createStore.worksheetSchema.groups}
                    store={createStore}
                    payRateHourly={payRateHourly}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <div className="rc-container no-overflow">
                  <BottomControls height="50px">
                    {createStore.currentState.rateType &&
                      createStore.selectedWorkerType.id &&
                      createStore.currentState.payType !== 3 && (
                        <ResultSummary
                          currencySymbol={createStore.worksheetSchema.currencySymbol}
                          rateType={createStore.currentState.rateType}
                          payRateHourly={parseFloat(payRateHourly)}
                          billRateHourly={parseFloat(billRateHourly)}
                          totalTax={parseFloat(totalTax)}
                          totalMarkup={parseFloat(totalMarkup)}
                          billRateHourlyAfterTax={parseFloat(billRateHourlyAfterTax)}
                          conversionOptions={conversionOptions}
                          store={createStore}
                        />
                      )}
                    {createStore.payType === 3 && (
                      <ResultsSummaryForSalary
                        currencySymbol={createStore.worksheetSchema.currencySymbol}
                        payValue={createStore.currentState.payValue}
                        totalMarkup={parseFloat(totalMarkup)}
                        totalTax={parseFloat(totalTax)}
                        conversionOptions={conversionOptions}
                        store={createStore}
                      />
                    )}
                    <BottomControlGroup>
                      <Inline css={{ justifyContent: "flex-end" }}>
                        <Button
                          color="brand"
                          icon="fa-fast-backward"
                          disabled={!createStore.hasUnsavedChanges()}
                          onClick={createStore.revertChanges}
                        >
                          {createStore.hasUnsavedChanges()
                            ? "Revert Changes"
                            : "No Changes To Revert"}
                        </Button>
                        <Button
                          color="brand"
                          icon="undo"
                          disabled={!createStore.canUndo()}
                          onClick={createStore.undo}
                        >
                          Undo
                        </Button>
                        <Button
                          color="brand"
                          icon="redo"
                          disabled={!createStore.canRedo()}
                          onClick={createStore.redo}
                        >
                          Redo
                        </Button>
                      </Inline>
                    </BottomControlGroup>
                  </BottomControls>
                  <ContainerSection className="footer">
                    <Inline css={{ justifyContent: "flex-end" }}>
                      <LinkButton size="large" to="/negotiation-worksheets">
                        Cancel
                      </LinkButton>
                      <Button
                        disabled={
                          !createStore.canSave() || !createStore.hasUnsavedChanges()
                        }
                        color="brand"
                        size="large"
                        onClick={(e) => {
                          createStore.updateWorksheet(store.negDetail);
                        }}
                      >
                        {saveText}
                      </Button>
                    </Inline>
                  </ContainerSection>
                </div>
              </div>
            </div>

            <div className="clearfix" />
          </div>
        </div>
      </div>
    );
  }
}

export default observer(Detail);
