const punctuationOrMathSymbolRegExp =
  // eslint-disable-next-line
  /[!\"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~\u002B\u00D7\u00F7]/;
const numberRegExp = /[0-9]/;

export function validateEmail(value: string) {
  const re =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(value);
}

export function validateUserName(value: string) {
  /**
   * Validate username
   * O'Riordan
   * øØřŘlle
   *
   * Invalid username
   * =Test
   * 123Admin
   */

  if (value.length === 0) return false;

  const firstChar = value.charAt(0);
  if (numberRegExp.test(firstChar)) return false;
  if (punctuationOrMathSymbolRegExp.test(firstChar)) return false;

  return true;
}
