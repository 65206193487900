import React, { useCallback } from "react";
import Select from "react-select";

import Box from "../../../components/lib/Box";
import { RATE_TYPE_OPTIONS_2_BY_ID } from "../../../constants/rateTypes";
import { reactSelectStyles, reactSelectTheme } from "../../../constants/reactSelect";

type ValueData = { id: string; title: string };

type RateTypeSelectProps = {
  value: ValueData;
  onChange: Select<ValueData>["props"]["onChange"];
};

export default function RateTypeSelect(props: RateTypeSelectProps) {
  const { value, onChange } = props;

  const getOptions = useCallback(() => {
    const options = Object.values(RATE_TYPE_OPTIONS_2_BY_ID);
    return options.map((items) => ({ id: String(items.value), title: items.label }));
  }, []);

  return (
    <Box fill>
      <Select<ValueData>
        options={getOptions()}
        value={value}
        onChange={onChange}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.title}
        style={reactSelectStyles}
        theme={reactSelectTheme}
      />
    </Box>
  );
}

RateTypeSelect.displayName = "RateTypeSelect";
