// @flow strict

import axios, { CancelToken } from "axios";
import { getCookie } from "./cookie";
import { buildUrlQueryString } from "./url";

/**
 * TODO: improve and refactor to typescript
 * * Only one function fetch is needed
 * * Remove Axios
 * * Simplify code
 */

// $FlowFixMe
type GraphQLVariables = ?{
  +[string]: string | number | boolean | Object | Array<any> | null,
};

const isValidBodyObject = (value) =>
  // (typeof USVString !== "undefined" && value instanceof USVString) ||
  // (typeof BufferSource !== "undefined" && value instanceof BufferSource) ||
  // (typeof ReadableStream !== "undefined" && value instanceof ReadableStream) ||
  (typeof Blob !== "undefined" && value instanceof Blob) ||
  (typeof FormData !== "undefined" && value instanceof FormData) ||
  (typeof URLSearchParams !== "undefined" && value instanceof URLSearchParams);

/*
 *  !IMPORTANT: Obsolete approach using axios (use native fetch API impl below)
 */
export const fetchAPI = (
  url: string,
  // $FlowFixMe
  json: ?Object = null,
  cancelToken?: CancelToken | null = null,
  method: "POST" | "GET" = "POST"
) => {
  const csrftoken = getCookie("csrftoken");
  let headers = {
    "content-type": "application/json",
  };
  if (csrftoken) {
    // $FlowFixMe no need to type the headers
    headers["X-CSRFToken"] = csrftoken;
  }
  return axios({
    method: method.toLowerCase(),
    url,
    responseType: "json",
    data: json,
    withCredentials: true,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    cancelToken,
  });
};

/*
 *  !IMPORTANT: Obsolete approach using axios (use native fetch API impl below)
 */
export const fetchGraphQL = (
  url: string,
  query: string,
  variables: GraphQLVariables,
  cancelToken?: CancelToken | null = null
) => {
  return fetchAPI(
    url,
    {
      query,
      variables,
    },
    cancelToken
  );
};

type HTTPMethodType = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
// $FlowFixMe
type HTTPBodyType = Object | Blob | FormData | URLSearchParams | ReadableStream;
export type FetchAPIOptionsType = {
  method?: HTTPMethodType,
  // $FlowFixMe
  params?: Object,
  body?: HTTPBodyType,
  data?: HTTPBodyType, // compatibility with axios, ideally to use "body" (temporary)
  // $FlowFixMe
  headers?: Object,
  signal?: AbortSignal,
  // see other options in the Fetch API docs - https://developer.mozilla.org/en-US/docs/Web/API/fetch
};

export class HTTPError extends Error {
  response: Response;
  status: number;
  statusText: string;

  constructor(response: Response) {
    super(`Bad HTTP status code: ${response.status} ${response.statusText}`);

    this.name = "HTTPError";
    this.response = response;
    this.status = response.status;
    this.statusText = response.statusText;
  }
}

export const fetchAPINew = async (path: string, options?: FetchAPIOptionsType) => {
  // $FlowFixMe
  options = options || {};

  // resolve method
  if (options?.method) {
    // $FlowFixMe
    options.method = options.method.toUpperCase();
  }

  // resolve headers

  const csrfToken = getCookie("csrftoken");
  const headers = options?.headers || {};
  if (csrfToken) {
    // $FlowFixMe no need to type the headers
    headers["X-CSRFToken"] = csrfToken;
  }
  if (!headers["content-type"]) {
    headers["content-type"] = "application/json;charset=UTF-8";
  }
  if (!headers["accept"]) {
    headers["accept"] = "application/json";
  }
  // $FlowFixMe
  options["headers"] = headers;

  const isJSONRequest = headers?.["content-type"]?.toLowerCase().indexOf("json") >= 0;
  const isJSONResponse = headers?.["accept"]?.toLowerCase().indexOf("json") >= 0;

  // resolve request body

  // compatibility with axios way of calling APIs
  if (options?.data != null) {
    options.body = options.data;
    delete options.data;
  }
  if (options?.body != null && isJSONRequest && !isValidBodyObject(options.body)) {
    options.body = JSON.stringify(options.body);
  }

  // resolve URL and query parameters

  const params = options?.params || {};
  const urlQueryString = buildUrlQueryString(params);
  const url = urlQueryString ? `${path}?${urlQueryString}` : path;

  // make request

  // $FlowFixMe
  const response = await fetch(url, {
    mode: "same-origin",
    credentials: "same-origin",
    ...options,
  });

  // compatibility with axios way of calling APIs
  if (!response.ok) {
    throw new HTTPError(response);
  }
  if (isJSONResponse) {
    // IMPORTANT: made by using text() and manual parsing instead of json() directly
    // IMPORTANT: json() raises an error if response body is empty, which sometimes unacceptable
    const bodyText = await response.text();
    // $FlowFixMe
    response.data = bodyText ? JSON.parse(bodyText) : null;
  }

  return response;
};

export const fetchGraphQLNew = async (
  path: string,
  query: string,
  variables: GraphQLVariables,
  options?: FetchAPIOptionsType
) => {
  return fetchAPINew(path, {
    method: "POST",
    ...(options || {}),
    body: { query, variables },
  });
};

export default {
  fetchAPI,
  fetchGraphQL,
  fetchAPINew,
  fetchGraphQLNew,
};
