import React from "react";
import { observer } from "mobx-react";
import ToggleButton, { ToggleButtonGroup } from "../../ratecards/components/ToggleButton";

const DataItemsDisplayOptions = (props) => {
  let store = props.store;
  return (
    <ToggleButtonGroup>
      <ToggleButton
        name="show-options"
        type="radio"
        value={store.hideMin}
        selected={!store.hideMin}
        onClick={store.onHideMinChange}
      >
        Min
      </ToggleButton>
      {!store.search.showThreeLevelsView && (
        <ToggleButton
          name="show-options"
          type="radio"
          value={store.hideMedian}
          selected={!store.hideMedian}
          onClick={store.onHideMedianChange}
        >
          Median
        </ToggleButton>
      )}
      <ToggleButton
        name="show-options"
        type="radio"
        value={store.hideAverage}
        selected={!store.hideAverage}
        onClick={store.onHideAverageChange}
      >
        Average
      </ToggleButton>
      <ToggleButton
        name="show-options"
        type="radio"
        value={store.hideMax}
        selected={!store.hideMax}
        onClick={store.onHideMaxChange}
      >
        Max
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default observer(DataItemsDisplayOptions);
