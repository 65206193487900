import { styled } from "../../stitches.config";

const Box = styled("div", {
  flex: "0 1 auto",
  variants: {
    fill: {
      true: {
        flex: "1 1 auto",
        width: "$full",
      },
    },
    nowrap: {
      true: {
        flexWrap: "nowrap",
      },
    },
  },
  defaultVariants: {
    fill: false,
    nowrap: false,
  },
});

Box.displayName = "Box";

export default Box;
