import React, { useMemo } from "react";

import Icon from "../../../components/lib/Icon";
import Stack from "../../../components/lib/Stack";
import Text from "../../../components/lib/Text";
import TextInput from "../../../components/lib/TextInput";
import TextArea from "../../../components/lib/TextArea";
import ParametersLine from "../lib/ParametersLine";
import { REVIEW_TYPES_LABELS, SOURCE_TYPES_LABELS } from "../types";
import {
  getRegionString,
  getLocationString,
  dedupeLocationValues,
  decimalFormatter,
} from "../constants";
import {
  getRateTypeLabel,
  getJobRateTypeParameters,
  getDisplayCurrencyChanged,
} from "../utils";
import { useReviewDataContext } from "../context/ReviewDataContext";
import { useRateDisplayContext } from "../context/RateDisplayContext";

type ReviewParametersProps = {
  isEditable?: boolean;
  isEditing?: boolean;
  jobTitle?: string | null;
  jobDescription?: string | null;
  onChangeJobTitle?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeJobDescription?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

const ReviewPrimaryParametersBlock = (props: ReviewParametersProps) => {
  const {
    isEditable = false,
    isEditing = false,
    jobTitle,
    jobDescription,
    onChangeJobTitle,
    onChangeJobDescription,
  } = props;

  const {
    reviewData,
    displayCurrencyData: reviewCurrencyData,
    reviewTitle,
    reviewType,
    isActive,
    sourceType,
    clientId,
    clientName,
    ratecardId,
    ratecardName,
    programName,
    storedIndexName,
    isThreeLevelBanding,
    isFreeValidation,
    totalReward,
    industriesString,
    categoriesString,
    firstJobData,
    firstJobRateType,
    //
    maxAttempts,
    attemptsMade,
    attemptsLeft,
    //
    isOrderedSurvey,
    isPrivateIndexSource,
    isRateCardSource,
  } = useReviewDataContext();

  const { displayRateType, displayCurrencyData } = useRateDisplayContext();

  const jobRegionString = useMemo(() => getRegionString(firstJobData), [firstJobData]);
  const jobLocationString = useMemo(
    () => getLocationString(firstJobData),
    [firstJobData]
  );
  const reviewCountriesString = useMemo(
    () => dedupeLocationValues(reviewData.get("countries_string")),
    [reviewData]
  );

  const reviewCurrencyCode = reviewCurrencyData.get("code");
  const reviewCurrencyName = reviewCurrencyData.get("name");
  const displayCurrencyCode = displayCurrencyData.get("code");
  const displayCurrencyName = displayCurrencyData.get("name");

  const isDisplayCurrencyChanged = useMemo(
    () => getDisplayCurrencyChanged(displayCurrencyCode, reviewCurrencyCode),
    [displayCurrencyCode, reviewCurrencyCode]
  );

  const jobRateMultiplier = useMemo(
    () => getJobRateTypeParameters(firstJobData, firstJobRateType),
    [firstJobData, firstJobRateType]
  ).rateMultiplier;

  const rateTypeLabel = useMemo(
    () => getRateTypeLabel(firstJobRateType, displayRateType, jobRateMultiplier),
    [firstJobRateType, displayRateType, jobRateMultiplier]
  );

  return (
    <Stack fill css={{ alignItems: "stretch" }}>
      <Stack css={{ alignItems: "flex-start", gap: "$1", fontSize: "$base" }}>
        <ParametersLine>
          <Text>Survey Type: </Text>
          <Text>{REVIEW_TYPES_LABELS[reviewType]}</Text>
        </ParametersLine>
        <ParametersLine>
          <Text>Source Type: </Text>
          <Text>{SOURCE_TYPES_LABELS[sourceType]}</Text>
        </ParametersLine>
        <ParametersLine>
          <Text>Rate Type: </Text>
          <Text>{rateTypeLabel}</Text>
        </ParametersLine>
        {isOrderedSurvey && (
          <ParametersLine>
            <Text>Title: </Text>
            <Text>{reviewTitle}</Text>
          </ParametersLine>
        )}
        {!isOrderedSurvey && (
          <ParametersLine>
            <Text>Job Title:</Text>
            {isEditable && isEditing ? (
              <TextInput
                fill
                value={jobTitle || ""}
                placeholder="Enter title here"
                onChange={onChangeJobTitle}
              />
            ) : (
              <Text>{jobTitle}</Text>
            )}
          </ParametersLine>
        )}
        {!isOrderedSurvey && firstJobData.get("industry_name") && (
          <ParametersLine>
            <Text>Industry: </Text>
            <Text>{firstJobData.get("industry_name")}</Text>
          </ParametersLine>
        )}
        {isOrderedSurvey && industriesString && (
          <ParametersLine>
            <Text>Industry: </Text>
            <Text>{industriesString}</Text>
          </ParametersLine>
        )}
        {categoriesString && (
          <ParametersLine>
            <Text>Category: </Text>
            <Text>{categoriesString}</Text>
          </ParametersLine>
        )}
        {!isOrderedSurvey && jobRegionString && (
          <ParametersLine>
            <Text>Region: </Text>
            <Text>{jobRegionString}</Text>
          </ParametersLine>
        )}
        {!isOrderedSurvey && !jobRegionString && jobLocationString && (
          <ParametersLine>
            <Text>Location: </Text>
            <Text>{jobLocationString}</Text>
          </ParametersLine>
        )}
        {isOrderedSurvey && reviewCountriesString && (
          <ParametersLine>
            <Text>Country:</Text>
            <Text>{reviewCountriesString}</Text>
          </ParametersLine>
        )}
        {reviewCurrencyCode && (
          <ParametersLine>
            <Text>Currency: </Text>
            <Text>
              <span>{reviewCurrencyName}</span>
              {isDisplayCurrencyChanged && (
                <span> (displayed as {displayCurrencyName})</span>
              )}
            </Text>
          </ParametersLine>
        )}
        {clientId && (
          <ParametersLine>
            <Text>Client: </Text>
            <Text>{`${clientName} (#${clientId})`}</Text>
          </ParametersLine>
        )}
        {isRateCardSource && (
          <ParametersLine>
            <Text>Rate Card: </Text>
            <Text>
              {ratecardName ? ratecardName : ""}
              {ratecardId != null ? ` (#${ratecardId})` : ""}
            </Text>
          </ParametersLine>
        )}
        {isPrivateIndexSource && programName && (
          <ParametersLine>
            <Text>Private Index / View: </Text>
            <Text>
              {`${programName}`}
              {storedIndexName ? ` / ${storedIndexName}` : null}
            </Text>
          </ParametersLine>
        )}
        {!isOrderedSurvey && (
          <ParametersLine>
            <Text>Global Supplier Search: </Text>
            <Text>
              {firstJobData.get("is_global_supplier_search", false) ? "Yes" : "No"}
            </Text>
          </ParametersLine>
        )}
      </Stack>

      {!isOrderedSurvey && (
        <Stack css={{ alignItems: "flex-start", gap: "$2" }}>
          <Text size="base">Job Description: </Text>
          <TextArea
            fill
            rows={10}
            value={jobDescription || ""}
            placeholder="Enter description here"
            onChange={isEditable ? onChangeJobDescription : undefined}
            disabled={!isEditable || !isEditing}
            css={{ resize: "vertical" }}
          />
        </Stack>
      )}

      <Stack css={{ alignItems: "flex-start", gap: "$1" }}>
        <Text as="h4">Other Parameters:</Text>
        {isActive ? (
          <Text>
            <Icon icon="check" className="positive" /> Active
          </Text>
        ) : (
          <Text>
            <Icon icon="times" className="negative" /> Not active
          </Text>
        )}
        {maxAttempts != null && (
          <Text>
            <Icon icon="check" className="positive" />
            &nbsp;
            <span>{attemptsLeft} validations available</span>
            {attemptsMade > 0 && <span>(max validations number is {maxAttempts})</span>}
          </Text>
        )}
        {isThreeLevelBanding && (
          <Text>
            <Icon icon="check" className="positive" /> Three level banding
          </Text>
        )}
        {isFreeValidation && (
          <Text>
            <Icon icon="check" className="positive" /> Free validation
          </Text>
        )}
        {!isFreeValidation && totalReward && (
          <Text>
            <Icon icon="check" className="positive" /> Reward for validation -{" "}
            {`$ ${decimalFormatter(totalReward)}`}
          </Text>
        )}
      </Stack>
    </Stack>
  );
};

ReviewPrimaryParametersBlock.displayName = "ReviewPrimaryParametersBlock";

export default ReviewPrimaryParametersBlock;
