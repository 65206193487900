import React from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import { observer } from "mobx-react";
import ContainerSection from "./ContainerSection";
import { styled } from "../../../stitches.config";
import Button from "../../../components/lib/Button";
import Inline from "../../../components/lib/Inline";

export const ModalTitle = styled("h4", {
  color: "$text",
});
ModalTitle.displayName = "ModalTitle";

export const ModalButtons = styled(Inline, {
  gap: "$2",
  justifyContent: "flex-end",
  "& > button, & > [role='button']": { minWidth: 120 },
});
ModalButtons.displayName = "ModalButtons";

const FilterModal = (props) => (
  <Modal
    show={props.filterState.modal.show}
    onHide={props.filterState.modal.hideModal}
    innerStyle={props.innerStyle}
  >
    {props.title && (
      <ContainerSection className="header">
        <ModalTitle>{props.title}</ModalTitle>
      </ContainerSection>
    )}
    {props.children}
    <ContainerSection className="footer">
      <ModalButtons>
        {!props.filterState.isCreatedFilter && (
          <Button
            color="brand"
            size="large"
            onClick={props.filterState.onApply}
            disabled={
              props.filterState.selectedValuesCount === 0 &&
              (props.filterState.sortState
                ? !props.filterState.sortState.direction
                : true)
            }
          >
            Apply
          </Button>
        )}
        {props.filterState.isCreatedFilter && (
          <Button
            color="brand"
            size="large"
            onClick={props.filterState.onApply}
            disabled={
              !props.filterState.fromDate &&
              !props.filterState.toDate &&
              (props.filterState.sortState
                ? !props.filterState.sortState.direction
                : true)
            }
          >
            Apply
          </Button>
        )}
        <Button size="large" onClick={props.filterState.onReset}>
          Clear
        </Button>
        <Button size="large" onClick={props.filterState.modal.hideModal}>
          Close
        </Button>
      </ModalButtons>
    </ContainerSection>
  </Modal>
);

FilterModal.propTypes = {
  filterState: PropTypes.object.isRequired,
  title: PropTypes.string,
};

export default observer(FilterModal);
