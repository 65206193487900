// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../ratecards/components/Modal";
import ContainerSection from "../ratecards/components/ContainerSection";
import classNames from "classnames";
import LocationList from "./components/LocationList";
import ApplyTagFilterModal from "../shared/modals/ApplyTagFilterModal";
import TagItem from "../ratecards/components/TagItem";
import RegionLocationModal from "../shared/modals/RegionLocationModal";
import SelectableItemsPaginatedList from "../ratecards/components/SelectableItemsPaginatedList";
import LoadingIndicator from "../shared/LoadingIndicator";
import LinkButton from "../../components/lib/LinkButton";
import Button from "../../components/lib/Button";
import Inline from "../../components/lib/Inline";
import { CardAlert, CardAlertLink } from "../../components/lib/Card";
import TextInput from "../../components/lib/TextInput";

const RegionDetail = observer((props) => {
  const store = props.store;
  const regionCreateStore = props.regionCreateStore;
  let tagSection = null;

  let owner,
    regionName,
    createdDate = null;

  let countryName = store.regionDetail.countryName;
  if (store.network.loading) {
    regionName = "...";
    owner = "...";
    createdDate = "...";
  } else if (store.regionDetail) {
    regionName = `${store.regionDetail.name}`;
    owner = store.regionDetail.owner;
    createdDate = store.regionDetail.createdDisplay;
    //tagSection = store.regionDetail.tags.map(tag => (<TagItem key={tag.tagId} tag={tag} onClick={ () => {props.onClick(event,tag.tagId)}}/>));
  }

  if (store.regionDetail.tags) {
    tagSection = store.regionDetail.tags.map((tag) => (
      <TagItem
        key={tag.tagId}
        tag={tag}
        onClick={(event) => {
          props.onClick(event, tag.tagId);
        }}
      />
    ));
  }

  let actionBar = null;
  if (!store.network.loading && !store.network.error)
    actionBar = (
      <Inline>
        <Button onClick={store.applyTagModal}>Apply Tag</Button>
        {
          <Button icon="edit" onClick={store.showRenameRegionModal}>
            Rename
          </Button>
        }
        {
          <Button
            onClick={() =>
              store.openLocation(regionCreateStore, props.router.params.regionId)
            }
          >
            <FontAwesomeIcon icon="edit" fixedWidth className="icon" />
            Add New Location
          </Button>
        }
        {
          <Button color="danger" onClick={store.confirmRegionDeletion}>
            <FontAwesomeIcon icon="trash-alt" fixedWidth className="icon" />
            Delete
          </Button>
        }
      </Inline>
    );

  return (
    <div className="top">
      <div className="above">
        <div className="ratecard-label-header ellipsis max-width-80">
          <h2 className="ellipsis">{regionName}</h2>
        </div>
        <Inline>
          <LinkButton color="brand" to={`/regions/`}>
            <FontAwesomeIcon icon="arrow-left" fixedWidth className="icon" />
            Go Back
          </LinkButton>
          <Button icon="question-circle" onClick={store.showHelp}>
            Help
          </Button>
        </Inline>
      </div>
      <div className="below">
        <div className="ratecard-meta ellipsis">
          <div className="ratecard-owner ellipsis">
            <FontAwesomeIcon icon="user" fixedWidth className="icon" /> {owner}
          </div>
          <div className="ratecard-created-at ellipsis">
            <FontAwesomeIcon icon="calendar-alt" fixedWidth className="icon" />{" "}
            {createdDate}
          </div>
          <div className="ratecard-created-at ellipsis">
            <FontAwesomeIcon icon="map-marker" fixedWidth className="icon" />{" "}
            {countryName}
          </div>
        </div>
        {actionBar}
      </div>
      <div className="tagbox">{tagSection}</div>
      <Modal show={store.showHelpModal} onHide={store.hideHelp}>
        <div className="container-section header">
          <h4>Help</h4>
        </div>
        <div className="container-section footer">
          <p>Here you can see all the locations in this Region</p>
        </div>
      </Modal>
      <Modal
        show={store.renameRegionModal.show}
        onHide={store.renameRegionModal.hideModal}
      >
        <ContainerSection className="header">
          <h4>Rename Region</h4>
        </ContainerSection>
        <ContainerSection>
          <p>Name of the Region:</p>
          <TextInput
            type="text"
            fill="true"
            value={store.newRegionName}
            onChange={store.onNewRegionNameChange}
          />
          <div className="pt-user-messages">
            {store.messaging.messages.length > 0 && (
              <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                <a
                  href="#close"
                  className="close"
                  data-dismiss="alert"
                  aria-label="close"
                  onClick={store.messaging.removeMessage.bind(
                    store,
                    store.messaging.messages[0]
                  )}
                >
                  &times;
                </a>
                <strong>{store.messaging.messages[0].messageLabel}</strong>
                {store.messaging.messages[0].message}
              </div>
            )}
          </div>
        </ContainerSection>
        <ContainerSection className="footer">
          <Inline>
            <Button size="large" onClick={store.renameRegionModal.hideModal}>
              Cancel
            </Button>
            <Button color="brand" size="large" onClick={store.renameRegion}>
              Rename Region
            </Button>
          </Inline>
        </ContainerSection>
      </Modal>
      <Modal
        show={store.confirmDeleteModal.show}
        onHide={store.confirmDeleteModal.hideModal}
      >
        <div className="container-section header">
          <h4>Confirmation Needed</h4>
        </div>
        <div className="container-section">
          <p>
            Are you sure you want to delete this Region? If you delete this regions, all
            rate searches associated with the regions will be deleted.
          </p>
          <Inline css={{ color: "$danger" }}>
            <FontAwesomeIcon icon="exclamation-triangle" />{" "}
            <em>This action cannot be undone!</em>
          </Inline>
          <p>Searches Affected:</p>
          <div
            className="flex-row no-padding text-x-small flex-align-center"
            style={{ color: "#565656", marginBottom: 6 }}
          >
            <div className="text-muted">Title and Region</div>
            <div className="flex-fill" />
            <div className="text-muted">Rate Card</div>
          </div>
          <SelectableItemsPaginatedList
            items={store.searchesAffectedByDeletion}
            itemsPerPage={5}
            loading={store.deleteRegionNetwork.loading}
            loadingView={<LoadingIndicator />}
            noItemsView={
              <CardAlert>
                <CardAlertLink>No searches will be affected.</CardAlertLink>
              </CardAlert>
            }
            renderItem={(search) => (
              <label
                key={search.databaseId}
                className="rc-selectable-item"
                style={{ cursor: "default" }}
              >
                <div className="flex-row no-padding text-x-small flex-align-center">
                  <div>
                    <div style={{ color: "#565656" }}>{search.jobLabel}</div>
                    <div>{search.regionName}</div>
                  </div>
                  <div className="flex-fill" />
                  <div>
                    {search.rateCardName || (
                      <em className="text-muted">-- No Rate Card --</em>
                    )}
                  </div>
                </div>
              </label>
            )}
          />
        </div>
        <div className="container-section footer">
          <Inline css={{ justifyContent: "flex-end" }}>
            <Button size="large" onClick={store.confirmDeleteModal.hideModal}>
              No, Cancel
            </Button>
            <Button
              color="danger"
              size="large"
              onClick={(e) => {
                store.confirmDeleteModal.hideModal();
                store.deleteRegion();
              }}
            >
              Yes, Delete Region
            </Button>
          </Inline>
        </div>
      </Modal>

      <ApplyTagFilterModal
        filterState={store.applyTagState}
        applyTagsEvent={store.applyTags}
        noTags={store.noTagsMessage}
        btnText="Apply Tag to Region"
      />
    </div>
  );
});

/**
 * RegionDetail component for rate card detail page
 */
class Detail extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor() {
    super();
    this.deleteTag = this.deleteTag.bind(this);
  }

  /**
   * componentDidMount
   * @return {void}
   */
  UNSAFE_componentWillMount() {
    this.props.store.locationListStore.regionId = this.props.router.params.regionId;
    this.props.store.locationListStore.router = this.context.router;
    // this.props.store.rateCardDetailStore.clearFilters();
    // this.props.store.rateCardDetailStore.applyDefaultSort();
    console.log(this.props.store.locationListStore);
    this.props.store.locationListStore.getRegionDetail();
    console.log(this.props.store.locationListStore);
    // reset to null so some elements visible while editing start hidden
    this.props.store.locationListStore.isEditing = null;
  }

  deleteTag(e: object, tag_id) {
    this.props.store.locationListStore.deleteSingleTags({
      tagId: tag_id,
      contentId: this.props.router.params.regionId,
      contentType: "REGIONS",
    });
  }

  /**
   * Render rate card detail page
   * @return {XML}
   */
  render() {
    const store = this.props.store.locationListStore;
    const regionCreateStore = this.props.store.regionCreateStore;

    return (
      <div className="view">
        <div className="rc-container show-overflow">
          <RegionDetail
            store={store}
            router={this.props.router}
            regionCreateStore={regionCreateStore}
            onClick={this.deleteTag}
          />
        </div>

        <div className="rc-container bring-forward">
          <div className="header-action-bar">
            <h4
              className="pull-left"
              style={{
                lineHeight: "30px",
              }}
            >
              Locations in this Region
            </h4>
            {!store.network.loading && !store.network.error && (
              <div className="btn-group pull-right">
                {store.isEditing && (
                  <Button color="accent" onClick={store.handleStopEdit}>
                    Stop Editing
                  </Button>
                )}
                {!store.isEditing && (
                  <Button color="brand" icon="edit" onClick={store.handleStartEdit}>
                    Edit
                  </Button>
                )}
              </div>
            )}
            <div className="clearfix" />
          </div>

          {!store.network.error && (
            <div>
              <LocationList store={store} />
            </div>
          )}

          {store.network.error && (
            <div className="pt-error-message fade-in">
              <h2>Error</h2>
              <pre>
                <code>{JSON.stringify(store.network.error, null, 2)}</code>
              </pre>
            </div>
          )}
        </div>

        <div
          className={classNames("search-edit-actions", {
            hidden: store.isEditing === null,
            "bottom-appear": store.isEditing === true,
            "bottom-disappear": store.isEditing === false,
          })}
        >
          {store.isEditing && (
            <Button
              color="danger"
              icon="trash-alt"
              disabled={store.selectedCount === 0}
              onClick={store.confirmLocationDeleteModal.showModal}
            >
              Delete
            </Button>
          )}
        </div>

        <div
          className={classNames("rc-container-backdrop", {
            hidden: store.isEditing === null,
            "fade-in": store.isEditing === true,
            "fade-out": store.isEditing === false,
          })}
        />

        <Modal
          show={store.confirmLocationDeleteModal.show}
          onHide={store.confirmLocationDeleteModal.hideModal}
        >
          <div className="container-section header">
            <h4>Confirmation Needed</h4>
          </div>
          <div className="container-section">
            <p>Are you sure you want to delete the selected Locations?</p>
            <Inline css={{ color: "$danger" }}>
              <FontAwesomeIcon icon="exclamation-triangle" />{" "}
              <em>This action cannot be undone!</em>
            </Inline>
          </div>
          <div className="container-section footer">
            <Inline css={{ justifyContent: "flex-end" }}>
              <Button size="large" onClick={store.confirmLocationDeleteModal.hideModal}>
                No, Cancel
              </Button>
              <Button
                color="danger"
                size="large"
                onClick={(e) => {
                  store.confirmLocationDeleteModal.hideModal();
                  store.deleteLocations();
                }}
              >
                Yes, Delete Locations
              </Button>
            </Inline>
          </div>
        </Modal>

        <Modal show={store.errorModal.show} onHide={store.errorModal.hideModal}>
          <div className="container-section header">
            <h4>Error</h4>
          </div>
          <div className="container-section">{store.errorMessage}</div>
          <div className="container-section footer">
            <div className="pull-right">
              <button className="btn btn-lg" onClick={store.errorModal.hideModal}>
                Close
              </button>
            </div>
          </div>
        </Modal>

        <RegionLocationModal
          store={regionCreateStore}
          button={
            <Button
              size="large"
              color="brand"
              onClick={() => regionCreateStore.updateRegionLocations()}
            >
              Update Region
            </Button>
          }
        />
      </div>
    );
  }
}

export default observer(Detail);
