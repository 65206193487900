import { styled } from "../../stitches.config";
import Stack from "./Stack";

export const FormControl = styled(Stack, {
  position: "relative",
  alignItems: "flex-start",
  gap: "$0",
  "& .multiselect-input-wrapper": {
    cursor: "text",
  },
  variants: {
    disabled: {
      true: {
        cursor: "not-allowed",
        label: {
          opacity: "0.55",
          cursor: "not-allowed !important",
        },
        input: {
          cursor: "not-allowed !important",
        },
      },
      false: {},
    },
  },
});

export const Label = styled("label", {
  fontSize: "$sm",
  fontWeight: "$normal",
});

export const LabelWrapper = styled("div", {
  display: "inline-flex",
  justifyContent: "space-between",
  width: "100%",
  variants: {
    disabled: {
      true: {
        cursor: "not-allowed",
      },
      false: {},
    },
  },
});

export const RequiredSign = styled("span", {
  fontSize: "$sm",
  fontWeight: "$bold",
  color: "$danger",
  verticalAlign: "top",
});
