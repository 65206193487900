/**
 * @generated SignedSource<<88c4caf18ebcef1658af8f88e63be3be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ResultOptions_viewer$data = {
  readonly savedsearch: {
    readonly id: string;
    readonly rateCard: {
      readonly id: string;
      readonly legacyId: number;
      readonly name: string | null;
    } | null;
    readonly rates: {
      readonly rawBillRates: ReadonlyArray<number | null> | null;
    } | null;
  } | null;
  readonly " $fragmentType": "ResultOptions_viewer";
};
export type ResultOptions_viewer$key = {
  readonly " $data"?: ResultOptions_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ResultOptions_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "viewer"
      ],
      "operation": require('./ResultOptionsResultsRefetch.graphql')
    }
  },
  "name": "ResultOptions_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "searchId"
        }
      ],
      "concreteType": "SaveSearchNode",
      "kind": "LinkedField",
      "name": "savedsearch",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "RateSearchResponse",
          "kind": "LinkedField",
          "name": "rates",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rawBillRates",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RateCardNode",
          "kind": "LinkedField",
          "name": "rateCard",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "legacyId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "a58d148c517004e1056484b37e3523ff";

export default node;
