// @flow

import { extendObservable, action } from "mobx";
import type { SortDirection } from "./Sort";

export default class SortState {
  direction: ?SortDirection;
  field: string;

  setSort: (SortDirection) => void;
  resetSort: () => void;

  constructor(field: string, direction: ?SortDirection = null) {
    this.field = field;

    extendObservable(this, {
      direction: direction,
    });

    this.setSort = action(this.setSort.bind(this));
    this.resetSort = action(this.resetSort.bind(this));
  }

  setSort(direction: SortDirection) {
    this.direction = direction;
  }

  resetSort() {
    this.direction = null;
  }
}
