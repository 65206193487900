// @flow
import React, { Component } from "react";
import type { Element } from "react";
import { observer } from "mobx-react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContainerSection from "../ratecards/components/ContainerSection";
import Modal from "../ratecards/components/Modal";
import TextInput from "./components/TextInput";
import TextArea from "./components/TextArea";
import CCCCountry from "../../models/CCCCountry";
import MultiDownshift from "./components/MultiDownshift";
import NonIdealState from "./components/NonIdealState";
import FlagIcon from "../../components/FlagIcon";
import classNames from "classnames";
import JobLibrary from "../../models/JobLibrary";
import SingleDownshift from "./components/SingleDownshift";
import RawJobTitle from "../../models/RawJobTitle";
import type MobXStore from "../../stores/mobx/MobXStore";
import SessionInfo from "../../models/SessionInfo";
import Container from "../../components/lib/Container";
import Button from "../../components/lib/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../components/lib/DropdownMenu";
import { styled } from "../../stitches.config";
import Inline from "../../components/lib/Inline";

const RequestCountryCheckbox = styled("span", {
  color: "$accent",
});

type Props = {
  store: MobXStore,
  sessionInfo: SessionInfo,
  router: Object,
  location: {
    query?: { title?: string, country?: string },
    state?: { previousPath?: string },
  },
};

class ClientJobTitleRequest extends Component<Props> {
  handleGoBack: () => void;

  constructor(props: Props) {
    super(props);

    this.handleGoBack = this.handleGoBack.bind(this);

    const store = props.store.jobLibraryTitleRequestCreateStore;

    store.previousLocation = "/job-library/title-requests";

    if (props.location && props.location.state && props.location.state.previousPath) {
      store.previousLocation = props.location.state.previousPath;
    }

    store.successModal.hideModal();
    store.reset();
    store
      .getUserCountries()
      .then(() => store.getCCCCountries())
      .then(() => store.getLibraries());

    if (this.props.location.query && this.props.location.query.title) {
      store.title = this.props.location.query.title;
      store.description = "Title in library.";
      store.userMessage =
        "This title is already on my library. I just need the selected countries certified.";
    }

    if (this.props.location.query && this.props.location.query.country) {
      store.defaultSelectedCountries = [this.props.location.query.country];
    }
  }

  componentDidMount() {
    this.props.store.jobLibraryTitleRequestCreateStore.router = this.props.router;
  }

  handleGoBack() {
    // this was used to go back to the old reactr rate search
    // if (this.props.location.query && this.props.location.query.b) {
    //   // get path to go back to
    //   const path = this.props.location.query["b"];
    //   // get session params
    //   const session = this.props.location.query["x-session-id"] || "";
    //   // get user params
    //   const userId = this.props.location.query["u"] || "";
    //
    //   window.location = path + "?x-session-id=" + session + "&s=" + session + "&u=" + userId;
    // } else {
    //   this.props.router.goBack();
    // }

    this.props.router.goBack();
  }

  renderCountryItem = (country: CCCCountry): Element<any> => {
    return (
      <span>
        {country.iso3166Alpha2 && <FlagIcon code={country.iso3166Alpha2} />}{" "}
        {country.title}
      </span>
    );
  };

  renderLibraryItem = (library: JobLibrary): Element<any> => {
    const iconStyle = {
      marginRight: 5,
      fontSize: 16,
      display: "inline-block",
      textAlign: "center",
    };
    return (
      <span>
        <FontAwesomeIcon icon="book" style={iconStyle} /> {library.name}
      </span>
    );
  };

  renderRawJobTitleItem = (title: RawJobTitle): Element<any> => {
    return <span>{title.title}</span>;
  };

  render() {
    const store = this.props.store.jobLibraryTitleRequestCreateStore;
    const client = this.props.sessionInfo.client;
    const clientId = client ? client.legacyId : null;

    const titleSuggestionClients = [
      10187, // chris_xpto
      18884, // Kelly - MSP Library
    ];
    const allowSuggestedTitleSelection = titleSuggestionClients.includes(clientId);

    const countriesErrorTooltip = (
      <Tooltip id="titleErrorTooltip" placement="top">
        {store.selectedCountriesError}
      </Tooltip>
    );

    return (
      <Container>
        <div className="cjl-centered-form" style={{ minWidth: 600 }}>
          {store.network.loading && (
            <NonIdealState
              icon="circle-notch"
              iconClasses="fa-spin"
              title="Loading"
              description="Please wait while data it's being transferred."
            />
          )}
          {!store.network.loading && (
            <div>
              <button className="cjl-form-close-button" onClick={this.handleGoBack}>
                <FontAwesomeIcon icon="times" />
              </button>
              <h2 className="cjl-form-title">Request New Job Title</h2>
              <p className="cjl-p">
                To have a new Job Title created please submit the form below. You'll
                receive a notification via email when your new title is available.
              </p>

              <TextInput
                id="titleInput"
                label="TITLE"
                value={store.title}
                error={store.titleError}
                onChange={store.onTitleChange}
              />

              <TextArea
                id="descriptionInput"
                label="DESCRIPTION"
                value={store.description}
                error={store.descriptionError}
                onChange={store.onDescriptionChange}
                minHeight={150}
                maxWidth={468}
              />

              <div className="form-group">
                <div className="flex" style={{ justifyContent: "space-between" }}>
                  <label htmlFor="libraryDescription1" className="cjl-label">
                    CERTIFY COUNTRIES
                  </label>
                  <div className="cjl-label no-wrap">
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          id="cjl-label-dropdown-countries-selection"
                          text={`${store.selectedCountries.length} SELECTED `}
                        />
                      </DropdownMenuTrigger>
                      <DropdownMenuContent side="bottom">
                        <DropdownMenuItem
                          eventKey="1"
                          onSelect={store.onSelectAllCountries}
                        >
                          Select All ({store.filteredCountries.length} Countries)
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          eventKey="2"
                          onSelect={store.onSelectNoneCountries}
                        >
                          Clear Selection
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </div>
                <div style={{ position: "relative" }}>
                  <MultiDownshift
                    items={[...store.filteredCountries]}
                    selectedItems={[...store.selectedCountries]}
                    inputValue={store.countryFilterQuery}
                    itemToString={(country: CCCCountry) => country.title || ""}
                    onInputValueChange={store.onCountryFilterQueryChange}
                    onItemAdd={store.onAddSelectedCountry}
                    onItemRemove={store.onRemoveSelectedCountry}
                    renderItem={this.renderCountryItem}
                  />
                  {Boolean(store.selectedCountriesError) && (
                    <OverlayTrigger placement="top" overlay={countriesErrorTooltip}>
                      <FontAwesomeIcon
                        icon="info-circle"
                        fixedWidth
                        className="icon text-danger cjl-input-icon-right"
                      />
                    </OverlayTrigger>
                  )}
                </div>

                <div className="flex">
                  <div
                    className="cjl-label"
                    style={{ fontSize: 12, marginRight: 5, cursor: "pointer" }}
                    onClick={store.onRequestNewCountriesChange}
                  >
                    <RequestCountryCheckbox>
                      <FontAwesomeIcon
                        icon={store.requestNewCountries ? "check-square" : "square"}
                        className={classNames("none", {
                          selected: store.requestNewCountries,
                        })}
                      />
                    </RequestCountryCheckbox>
                  </div>
                  <div
                    className="cjl-label"
                    style={{ fontSize: 12, cursor: "pointer" }}
                    onClick={store.onRequestNewCountriesChange}
                  >
                    Request New Country
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="newCountryTooltip">
                          When checked you'll be able to select countries that you don't
                          already have
                        </Tooltip>
                      }
                    >
                      <FontAwesomeIcon icon="question-circle" style={{ marginLeft: 5 }} />
                    </OverlayTrigger>
                  </div>
                </div>
              </div>

              {allowSuggestedTitleSelection && (
                <div className="form-group">
                  <div className="flex" style={{ justifyContent: "space-between" }}>
                    <label htmlFor="libraryDescription1" className="cjl-label">
                      SUGGESTED TITLE <span className="cjl-thin">(OPTIONAL)</span>
                    </label>
                    {store.selectedRawJobTitle && (
                      <div className="cjl-label no-wrap">
                        <Button
                          id="cjl-label-dropdown-countries-selection"
                          pullRight
                          onClick={store.onShowDescription}
                        >
                          View Description
                        </Button>
                      </div>
                    )}
                  </div>
                  <div style={{ position: "relative" }}>
                    <SingleDownshift
                      items={store.rawJobTitles}
                      selectedItem={store.selectedRawJobTitle}
                      inputValue={store.rawJobTitlesFilterQuery}
                      itemToString={(title: RawJobTitle) => title.title || ""}
                      onInputValueChange={store.onRawJobTitlesFilterQueryChange}
                      onSelectedItemChange={store.onSelectedRawJobTitleChange}
                      renderItem={this.renderRawJobTitleItem}
                      loading={store.rawJobTitlesSelectNetwork.loading}
                    />
                  </div>
                </div>
              )}

              <div className="form-group">
                <div className="flex" style={{ justifyContent: "space-between" }}>
                  <label htmlFor="libraryDescription1" className="cjl-label">
                    ADD TO LIBRARIES
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="newCountryTooltip">
                          Titles will always be added to the main library regardless of
                          your selection
                        </Tooltip>
                      }
                    >
                      <FontAwesomeIcon
                        icon="question-circle"
                        style={{ marginLeft: 5, marginRight: 5, fontSize: 12 }}
                      />
                    </OverlayTrigger>
                    <span className="cjl-thin">(OPTIONAL)</span>
                  </label>
                  <div className="cjl-label no-wrap">
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          id="cjl-label-dropdown-libraries-selection"
                          text={`${store.selectedLibraries.length} SELECTED `}
                        />
                      </DropdownMenuTrigger>
                      <DropdownMenuContent side="bottom">
                        <DropdownMenuItem
                          eventKey="1"
                          onSelect={store.onSelectAllLibraries}
                        >
                          Select All ({store.filteredLibraries.length} Libraries)
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          eventKey="2"
                          onSelect={store.onSelectNoneLibraries}
                        >
                          Clear Selection
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </div>
                <div style={{ position: "relative" }}>
                  <MultiDownshift
                    items={[...store.filteredLibraries]}
                    selectedItems={[...store.selectedLibraries]}
                    inputValue={store.libraryFilterQuery}
                    itemToString={(library: JobLibrary) => library.name || ""}
                    onInputValueChange={store.onLibraryFilterQueryChange}
                    onItemAdd={store.onAddSelectedLibrary}
                    onItemRemove={store.onRemoveSelectedLibrary}
                    renderItem={this.renderLibraryItem}
                  />
                </div>
              </div>

              {/*<TextInputAutocomplete*/}
              {/*  id="categoryAutocomplete"*/}
              {/*  label={*/}
              {/*    <span>*/}
              {/*      CATEGORY <span className="cjl-thin">(OPTIONAL)</span>*/}
              {/*    </span>*/}
              {/*  }*/}
              {/*  value={store.category}*/}
              {/*  onChange={store.onCategoryChange}*/}
              {/*  autocompleteItems={store.categoriesFound}*/}
              {/*  loading={store.categoriesAutocompleteNetwork.loading}*/}
              {/*/>*/}

              <TextArea
                id="messageInput"
                label={
                  <span>
                    MESSAGE <span className="cjl-thin">(OPTIONAL)</span>
                  </span>
                }
                value={store.userMessage}
                onChange={store.onUserMessageChange}
                minHeight={84}
                maxWidth={468}
                placeholder="Additional information..."
              />
              <div
                className="cjl-info-text cjl-thin cjl-label"
                style={{ fontSize: 13, marginTop: -10 }}
              >
                <em>
                  <FontAwesomeIcon icon="info-circle" /> If you would like to add
                  additional information regarding either why you are requesting this job
                  title or pertinent information as to its importance; you may enter that
                  here.
                </em>
              </div>

              <br />
              <div className="cjl-button-bar">
                <div className="cjl-spacer" />

                <Button size="large" color="brand" onClick={store.submitRequest}>
                  Submit Request
                  <FontAwesomeIcon icon="chevron-right" />
                </Button>
              </div>

              <Modal show={store.successModal.show} onHide={store.successModal.hideModal}>
                <ContainerSection className="header">
                  <h4>Success!</h4>
                </ContainerSection>
                <ContainerSection>
                  <p>
                    The Job Title Request has been submitted. We will contact you when
                    request will be available for search.
                  </p>
                </ContainerSection>
                <ContainerSection className="footer">
                  <Inline css={{ justifyContent: "flex-end" }}>
                    <Button
                      color="brand"
                      size="large"
                      onClick={() => {
                        store.successModal.hideModal();
                        store.reset();
                      }}
                    >
                      Request Another Title
                    </Button>{" "}
                    <Button size="large" onClick={this.handleGoBack}>
                      Go Back
                    </Button>
                  </Inline>
                </ContainerSection>
              </Modal>
              <Modal
                show={store.titleDescriptionModal.show}
                onHide={store.titleDescriptionModal.hideModal}
                innerStyle={{ width: 600 }}
              >
                <div className="container-section header">
                  <h4>
                    {store.selectedRawJobTitle
                      ? store.selectedRawJobTitle.title
                      : "Selection Required"}
                  </h4>
                </div>
                <div className="rc-viewport overlap-lr-padding">
                  {store.selectedRawJobTitle &&
                  store.selectedRawJobTitle.network.loading ? (
                    <NonIdealState
                      icon="circle-notch"
                      iconClasses="fa-spin"
                      title="Loading"
                      description="Please wait while data it's being transferred."
                    />
                  ) : (
                    <div className="cjl-title-desc pt-job-description">
                      <pre className="text-wrap">
                        {store.selectedRawJobTitle
                          ? store.selectedRawJobTitle.description
                          : "No title has been selected..."}
                      </pre>
                    </div>
                  )}
                </div>
                <div className="container-section footer">
                  <div className="pull-right">
                    <button
                      className="btn btn-lg"
                      onClick={store.titleDescriptionModal.hideModal}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </Modal>
            </div>
          )}
        </div>
      </Container>
    );
  }
}

export default observer(ClientJobTitleRequest);
