export const CLIENT_JOB_TITLE_SORT_FIELD = {
  CLIENT_TITLE: "CLIENT_TITLE",
  CREATED: "CREATED",
  CERTIFIED_COUNTRIES: "CERTIFIED_COUNTRIES_COUNT",
  NON_CERTIFIED_COUNTRIES: "NON_CERTIFIED_COUNTRIES_COUNT",
  // This sort field is for UI use only. When this sort field
  // is selected we should actually sort on
  // CERTIFIED_COUNTRIES and NON_CERTIFIED_COUNTRIES at the same time
  COUNTRIES: "COUNTRIES",
};
