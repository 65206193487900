import React from "react";

import Stack from "../../../../components/lib/Stack";
import Alert from "../../../../components/lib/Alert";
import { QuestionsFeedbackViewer } from "../../components/QuestionsTable";
import { JOB_FEEDBACK_STATUS_TYPES } from "../../types";
import { useAttemptDataContext } from "../../context/AttemptDataContext";
import { useReviewDataContext } from "../../context/ReviewDataContext";

import {
  FillTheBlankHourlyFeedbackPreview,
  FillTheBlankSalaryFeedbackPreview,
} from "../../components/FillTheBlankFeedbackPreview";
import Text from "../../../../components/lib/Text";
import Box from "../../../../components/lib/Box";

const AttemptSecondaryParametersBlock = () => {
  const { questionsData, instructions, isAnnualRateSurvey, isFillInTheBlankSurvey } =
    useReviewDataContext();
  const { comment, jobFeedbackStatus, questionsFeedbackData, jobFeedbackData } =
    useAttemptDataContext();

  const isJobValidationSkipped =
    jobFeedbackStatus === JOB_FEEDBACK_STATUS_TYPES.DONT_KNOW;

  return (
    <Stack css={{ width: "$full", alignItems: "flex-start" }}>
      {isFillInTheBlankSurvey && isJobValidationSkipped && (
        <Alert fill color="warning">
          Validator marked this title as <strong>"I don't know"</strong>
        </Alert>
      )}

      {isFillInTheBlankSurvey && !isAnnualRateSurvey && (
        <FillTheBlankHourlyFeedbackPreview jobFeedbackData={jobFeedbackData} />
      )}

      {isFillInTheBlankSurvey && isAnnualRateSurvey && (
        <FillTheBlankSalaryFeedbackPreview jobFeedbackData={jobFeedbackData} />
      )}

      {instructions ? (
        <Alert fill css={{ flexGrow: 0 }} color="warning">
          <b>Instructions:</b> {instructions}
        </Alert>
      ) : (
        <Alert fill css={{ flexGrow: 0 }} color="warning">
          <b>Instructions:</b> No special instructions configured for the survey.
        </Alert>
      )}

      {questionsData?.size > 0 ? (
        <Stack fill css={{ flexGrow: 0, alignItems: "flex-start", gap: "$2" }}>
          <Text as="h4">Additional Questions:</Text>
          <QuestionsFeedbackViewer
            questions={questionsData}
            feedback={questionsFeedbackData}
          />
        </Stack>
      ) : (
        <Alert fill css={{ flexGrow: 0 }} color="warning">
          <b>Additional Questions:</b> No questions configured for the survey.
        </Alert>
      )}

      {comment ? (
        <Stack fill css={{ flexGrow: 0, alignItems: "flex-start", gap: "$2" }}>
          <Text as="h4">Extra comment:</Text>
          <Box fill css={{ padding: "$4", backgroundColor: "$brandLighter" }}>
            {comment}
          </Box>
        </Stack>
      ) : (
        <Alert fill css={{ flexGrow: 0 }}>
          No extra comments left by the validator.
        </Alert>
      )}
    </Stack>
  );
};

AttemptSecondaryParametersBlock.displayName = "AttemptSecondaryParametersBlock";

export default AttemptSecondaryParametersBlock;
