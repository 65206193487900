// @flow
import * as React from "react";
import Select from "react-select";
import { reactSelectStyles, reactSelectTheme } from "../../constants/reactSelect";

type WorkerType = {
  id: number,
  name: string,
  description: string,
};

type Option = {
  value: number,
  label: string,
};

type Props = {
  workerTypes: Array<WorkerType>,
  value: ?WorkerType,
  onChange: (?WorkerType) => void,
  id?: string,
  disabled?: boolean,
  isLoading?: boolean,
  placeholder?: string,
};

function WorkerTypeSelectNew(props: Props) {
  const options: Array<Option> = props.workerTypes.map((wt) => ({
    value: wt.id,
    label: wt.name,
  }));

  const option: ?Option = props.value
    ? options.find((o) => o.value === props.value?.id)
    : null;

  const handleChange = (option: Option, actionMeta: any) => {
    // console.log('value', value);
    // console.log('actionMeta', actionMeta);

    const workerType: ?WorkerType = props.workerTypes.find(
      (wt) => wt.id === option?.value
    );

    props.onChange(workerType);
  };

  return (
    <Select
      inputId={props.id || "workerTypeSelect"}
      name="workerTypes"
      placeholder={props.placeholder || "Select Worker Type"}
      isLoading={props.isLoading}
      isDisabled={props.disabled}
      isClearable
      openMenuOnFocus
      options={options}
      value={option}
      onChange={handleChange}
      styles={reactSelectStyles}
      theme={reactSelectTheme}
    />
  );
}

export default WorkerTypeSelectNew;
