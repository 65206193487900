import React, { useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";

import Box from "../../../components/lib/Box";
import Inline from "../../../components/lib/Inline";
import Text from "../../../components/lib/Text";
import TableExportConfig from "../components/TableExportConfig";
import { TableFilterableRestful } from "./TableFilterableRestful";
import { useTableSchemaState } from "../../../components/tables/Table";
import {
  FilterTypes,
  djangoOrderingKey,
  djangoPaginationKey,
  djangoPaginationSizeKey,
} from "../../../components/tables/constants";
import { decimalFormatter } from "../constants";
import {
  totalFeesPaidTableGlobalState,
  TotalFeesPaidTableDataStateObject,
} from "../globalState";
import { useInitialFetch } from "../../../utils/hooks";
import { renderTableColumnsSpecs } from "../../private_index/components/ExtendedRestfulTables";
import { transformTotalFeesPaidData } from "../dataConverters";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";
import { TickerContentLoader } from "../../../components/lib/TickerLoader";

import type { UrlQueryObject } from "../../../components/tables/types";
import type { RestfulTableDataStateObject } from "../globalState";
import type { TotalFeesPaidDataObject } from "../types";
import type {
  TableColumnSpecsObject,
  TotalFeesPaidDataMap,
  TotalFeesPaidSummaryDataObject,
} from "../types";
import type { TableFilterableRestfulProps } from "../../../components/tables/TableFilterableRestful";
import type { FetchAPIResponse } from "../../../types/fetch";
import type { DjangoPaginatedResponse } from "../../../types/django";
import { omitObjectKeys } from "../../../utils/object";
import { rowIdGetter } from "../../../components/tables/utils";
import { useVal5KAdminContext } from "../context/Val5KAdminContext";

export const totalFeesPaidTableSpecs: TableColumnSpecsObject<TotalFeesPaidDataMap>[] = [
  {
    type: "column",
    uniqueKey: "first_name",
    title: "First Name",
    getter: (row) => row.get("first_name"),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "last_name",
    title: "Last Name",
    getter: (row) => row.get("last_name"),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "email",
    title: "Email",
    getter: (row) => row.get("email"),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "count",
    title: "Total Surveys Validated",
    getter: (row) => row.get("count"),
    filterType: FilterTypes.NUMBERS_RANGE,
  },
  {
    type: "column",
    uniqueKey: "fee_summ",
    title: "Total Fees Paid",
    getter: (row) => row.get("fee_summ"),
    formatter: (value) => decimalFormatter(value, false, "$"),
    filterType: FilterTypes.NUMBERS_RANGE,
  },
];

type TotalFeesPaidTableProps = Omit<TableFilterableRestfulProps, "schema">;

const TotalFeesPaidTable = (props: TotalFeesPaidTableProps) => {
  const [schema] = useTableSchemaState(props.children);
  return <TableFilterableRestful {...props} schema={schema} />;
};
TotalFeesPaidTable.displayName = "TotalFeesPaidTable";

type TotalFeesPaidTableViewProps = {
  modalState: boolean;
  closeModal: () => void;
};

const TotalFeesPaidTableView = (props: TotalFeesPaidTableViewProps) => {
  const { modalState, closeModal } = props;
  const { sessionInfo, fetchArgusFilteringAPI, showModalError } = useVal5KAdminContext();

  // table state

  const [tableState, setTableState] = useRecoilState<RestfulTableDataStateObject>(
    totalFeesPaidTableGlobalState
  );
  const { loaded, ...restTableState } = tableState;
  const { itemsCount, itemsPerPage, activePage, filtersQuery } = restTableState;

  const [rewardsSummary, setRewardsSummary] = useState<TotalFeesPaidSummaryDataObject>({
    total_count: 0,
    total_fee_summ: 0,
  });

  // table data source

  const fetchTotalFeesPaidData = useCallback(
    async (urlQuery = {}, filtersQuery = {}, nextStateUpdates = {}) => {
      try {
        const response: FetchAPIResponse<
          DjangoPaginatedResponse<TotalFeesPaidDataObject>
        > = await fetchArgusFilteringAPI(`total-fees-paid/filtered/`, {
          params: urlQuery,
          data: { ...filtersQuery },
        });

        const nextDataState: Partial<TotalFeesPaidTableDataStateObject> =
          transformTotalFeesPaidData(response.data, nextStateUpdates);

        setTableState((prevDataState) => ({
          ...prevDataState,
          ...nextDataState,
          loaded: true,
        }));
        return nextDataState;
      } catch (err: any) {
        logAsyncOperationError("fetchTotalFeesPaidData", err);
        showModalError(
          "Error occurred while loading total fees paid list. Please, try again later."
        );
        throw err;
      }
    },
    [setTableState, fetchArgusFilteringAPI, showModalError]
  );

  const fetchTotalFeesPaidDataFilters = useCallback(
    async (urlQuery = {}, filtersQuery = {}) => {
      try {
        const response: FetchAPIResponse<DjangoPaginatedResponse<any>> =
          await fetchArgusFilteringAPI(`total-fees-paid/values/filtered/`, {
            params: urlQuery,
            data: { ...filtersQuery },
          });
        return response.data;
      } catch (err: any) {
        logAsyncOperationError("fetchTotalFeesPaidFilterValues", err);
        showModalError(
          "Error occurred while loading filter values. Please, try again later."
        );
        throw err;
      }
    },
    [fetchArgusFilteringAPI, showModalError]
  );

  const fetchTotalFeesPaidRewardsSummary = useCallback(async () => {
    try {
      const response: FetchAPIResponse<TotalFeesPaidSummaryDataObject> =
        await fetchArgusFilteringAPI("total-fees-paid/summary/filtered/", {
          method: "post",
          data: tableState.filtersQuery.toJS(),
        });

      setRewardsSummary((prevData) => ({
        ...prevData,
        ...response.data,
      }));
    } catch (err: any) {
      logAsyncOperationError("fetchTotalFeesPaidSummaryData", err);
      showModalError(
        "Error occurred while loading total fees paid summary data. Please, try again later."
      );
    }
  }, [tableState.filtersQuery, fetchArgusFilteringAPI, showModalError]);

  // handlers

  const handleGetExportQueryArgs = useCallback(
    (exportCurrentPage: boolean = false): UrlQueryObject | null => {
      const queryArgs: UrlQueryObject = {};

      if (sessionInfo.legacySession) {
        queryArgs["x-session-id"] = sessionInfo.legacySession;
      }

      if (filtersQuery.has(djangoOrderingKey)) {
        queryArgs[djangoOrderingKey] = filtersQuery.get(djangoOrderingKey);
      }

      if (exportCurrentPage) {
        queryArgs[djangoPaginationKey] = activePage;
        queryArgs[djangoPaginationSizeKey] = itemsPerPage;
      }

      return Object.keys(queryArgs).length > 0 ? queryArgs : null;
    },
    [activePage, itemsPerPage, filtersQuery, sessionInfo.legacySession]
  );

  const handleGetExportFormData = useCallback(() => {
    return omitObjectKeys(filtersQuery.toJS(), [
      djangoOrderingKey,
      djangoPaginationKey,
      djangoPaginationSizeKey,
    ]);
  }, [filtersQuery]);

  // utility functions

  const refreshTotalFeesPaidData = useCallback(async () => {
    return fetchTotalFeesPaidData(
      {
        [djangoPaginationKey]: tableState.activePage,
        [djangoPaginationSizeKey]: tableState.itemsPerPage,
      },
      tableState.filtersQuery.toJS()
    );
  }, [
    tableState.filtersQuery,
    tableState.activePage,
    tableState.itemsPerPage,
    fetchTotalFeesPaidData,
  ]);

  // initial data fetch

  useInitialFetch(refreshTotalFeesPaidData);

  // effects

  useEffect(() => {
    fetchTotalFeesPaidRewardsSummary();
  }, [tableState.filtersQuery, fetchTotalFeesPaidRewardsSummary]);

  // rendering

  const columns = renderTableColumnsSpecs(totalFeesPaidTableSpecs);

  if (!loaded) {
    return (
      <Box css={{ minHeight: "200px", position: "relative" }}>
        <TickerContentLoader />
      </Box>
    );
  }

  return (
    <>
      <TotalFeesPaidTable
        multimode
        rowIdGetter={rowIdGetter}
        bodyEmptyText="No data"
        disablePagination={false}
        dataProvider={fetchTotalFeesPaidData}
        filtersDataProvider={fetchTotalFeesPaidDataFilters}
        {...restTableState}
      >
        {columns}
      </TotalFeesPaidTable>
      {rewardsSummary.total_count > 0 && rewardsSummary.total_fee_summ && (
        <Inline fill css={{ marginTop: "$6", gap: "$2" }}>
          <Text color="dark"> Total fees paid ({rewardsSummary.total_count} rows):</Text>
          <Box
            css={{ backgroundColor: "$brand", padding: "$2 $10", color: "$textLight" }}
          >
            <Text>{decimalFormatter(rewardsSummary.total_fee_summ, false, "$")}</Text>
          </Box>
        </Inline>
      )}
      <TableExportConfig
        show={modalState}
        itemsPerPage={itemsPerPage}
        itemsTotal={itemsCount}
        endpoint={"v1/total-fees-paid/export/"}
        getQueryArgs={handleGetExportQueryArgs}
        getFormData={handleGetExportFormData}
        onClose={closeModal}
      />
    </>
  );
};
TotalFeesPaidTableView.displayName = "TotalFeesPaidTableView";

export default TotalFeesPaidTableView;
