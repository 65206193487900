import { Map, fromJS } from "immutable";

/**
 * Utility function that takes an object list, and converts it to a map that is keyed by a specific field in the map.
 */
export default function byKey(
  objectList,
  idGetter = (it) => it.id,
  containerClass = Map,
  valueFn = fromJS
) {
  return new containerClass(objectList.map((it) => [idGetter(it), valueFn(it)]));
}
