// @flow
import React, { Component } from "react";
import { observer } from "mobx-react";
import PlainHighChart from "../../ratecards/components/PlainHighChart";
import Search from "../../../models/Search";
import moment from "moment";
import numeral from "numeral";

type RateData = {
  ratesData: number[],
  missingRates: number[],
  changesData: number[],
  missingChanges: number[],
};

type Props = {
  search: Search,
  currencySymbol: string,
  payRateMinData: RateData,
  payRateMaxData: RateData,
  payRateAvgData: RateData,
  billRateMinData: ?RateData,
  billRateMaxData: ?RateData,
  billRateAvgData: ?RateData,
  months: string[],
  levelColor: string,
};

const ResultsTrendChart = observer(
  class extends Component {
    props: Props;

    getChartData(
      months,
      avgRates: number[],
      minRates: number[],
      maxRates: number[],
      minRatesMissing: number[],
      maxRatesMissing: number[]
    ): { averages: Array<number[]>, ranges: Array<number[]>, missing: Array<number[]> } {
      const averagesData: Array<number[]> = [];
      const rangesData: Array<number[]> = [];
      const missingData: Array<number[]> = [];
      for (let i = 0; i < months.length; i++) {
        averagesData.push([moment(months[i], "MMM, YYYY").valueOf(), avgRates[i]]);
        rangesData.push([
          moment(months[i], "MMM, YYYY").valueOf(),
          minRates[i],
          maxRates[i],
        ]);
        missingData.push([
          moment(months[i], "MMM, YYYY").valueOf(),
          minRatesMissing[i],
          maxRatesMissing[i],
        ]);
      }

      return { averages: averagesData, ranges: rangesData, missing: missingData };
    }

    getRangeSeries(
      seriesName: string,
      ranges: Array<number[]>,
      color: string,
      linkedTo?: string
    ) {
      return {
        id: encodeURIComponent(seriesName),
        name: seriesName,
        data: ranges,
        type: "arearange",
        lineWidth: 0,
        linkedTo: linkedTo,
        color: color,
        fillOpacity: 0.2,
        zIndex: 0,
        marker: {
          enabled: true,
        },
      };
    }

    getAvgSeries(
      seriesName: string,
      averages: Array<number[]>,
      color: string,
      linkedTo?: string
    ) {
      return {
        id: encodeURIComponent(seriesName),
        name: seriesName,
        data: averages,
        zIndex: 1,
        linkedTo: linkedTo,
        color: color,
        marker: {
          fillColor: "white",
          lineWidth: 2,
          lineColor: color,
        },
      };
    }

    render() {
      const months = this.props.months;
      const currency = this.props.currencySymbol;
      const billRateColor = this.props.levelColor;
      const payRateColor = "#ecd04b";
      const missingRateColor = "#ddd";

      // Pay Rate Series
      const payRateAvgRates = this.props.payRateAvgData.ratesData;
      const payRateMinRates = this.props.payRateMinData.ratesData;
      const payRateMaxRates = this.props.payRateMaxData.ratesData;
      const payRateMinRatesMissing = this.props.payRateMinData.missingRates;
      const payRateMaxRatesMissing = this.props.payRateMaxData.missingRates;
      const payRateChartData = this.getChartData(
        months,
        payRateAvgRates,
        payRateMinRates,
        payRateMaxRates,
        payRateMinRatesMissing,
        payRateMaxRatesMissing
      );
      const payRateAvgSeries = this.getAvgSeries(
        "Pay Rate Avg",
        payRateChartData.averages,
        payRateColor
      );
      const payRateRangeSeries = this.getRangeSeries(
        "Pay Rate Range",
        payRateChartData.ranges,
        payRateColor,
        payRateAvgSeries.id
      );
      const payRateRangeMissingSeries = this.getRangeSeries(
        "Pay Rate NA",
        payRateChartData.missing,
        missingRateColor,
        payRateAvgSeries.id
      );

      const series = [payRateAvgSeries, payRateRangeSeries, payRateRangeMissingSeries];

      const minNonZeroValues = payRateMinRates.filter((val) => Boolean(val));
      const dataMin = minNonZeroValues.length > 0 ? Math.min(...minNonZeroValues) : 0;

      // Bill Rate Series
      let dataMax = 0;
      if (
        this.props.billRateAvgData &&
        this.props.billRateMinData &&
        this.props.billRateMaxData
      ) {
        const billRateAvgRates = this.props.billRateAvgData.ratesData;
        const billRateMinRates = this.props.billRateMinData.ratesData;
        const billRateMaxRates = this.props.billRateMaxData.ratesData;
        const billRateMinRatesMissing = this.props.billRateMinData.missingRates;
        const billRateMaxRatesMissing = this.props.billRateMaxData.missingRates;
        const billRateChartData = this.getChartData(
          months,
          billRateAvgRates,
          billRateMinRates,
          billRateMaxRates,
          billRateMinRatesMissing,
          billRateMaxRatesMissing
        );
        const billRateAvgSeries = this.getAvgSeries(
          "Bill Rate Avg",
          billRateChartData.averages,
          billRateColor
        );
        const billRateRangeSeries = this.getRangeSeries(
          "Bill Rate Range",
          billRateChartData.ranges,
          billRateColor,
          billRateAvgSeries.id
        );
        const billRateRangeMissingSeries = this.getRangeSeries(
          "Bill Rate NA",
          billRateChartData.missing,
          missingRateColor,
          billRateAvgSeries.id
        );

        series.unshift(
          billRateAvgSeries,
          billRateRangeSeries,
          billRateRangeMissingSeries
        );

        const maxNonZeroValues = billRateMaxRates.filter((val) => Boolean(val));
        dataMax = maxNonZeroValues.length > 0 ? Math.max(...maxNonZeroValues) : 0;
      } else {
        const maxNonZeroValues = payRateMaxRates.filter((val) => Boolean(val));
        dataMax = maxNonZeroValues.length > 0 ? Math.max(...maxNonZeroValues) : 0;
      }

      const yMin = Math.floor(dataMin - (dataMax - dataMin) * 0.1);
      const yMax = Math.ceil(dataMax + (dataMax - dataMin) * 0.1);

      let chartConfig = {
        colors: [billRateColor, payRateColor],
        lang: {
          thousandsSep: ",",
        },
        chart: {
          zoomType: "xy",
          height: 460,
        },
        title: null,
        xAxis: {
          type: "datetime",
          // categories: months,
          labels: {
            style: {
              fontSize: "14px",
            },
          },
        },
        yAxis: {
          // Primary yAxis
          labels: {
            // format: `${currency} {value}`,
            formatter: function () {
              const y = numeral(this.value).format("0,0.00");
              return `${currency} ${y}`;
            },
            style: {
              fontSize: "14px",
            },
          },
          title: {
            text: null,
            format: `${currency} {value}`,
          },

          min: yMin,
          max: yMax,
        },

        tooltip: {
          crosshairs: true,
          shared: true,
          hideDelay: 0,
          valuePrefix: currency,
          xDateFormat: "%B %Y",
          style: {
            fontSize: "14px",
          },
          pointFormatter: function () {
            const seriesName = this.series.name;
            const color = this.series.color;
            const low = numeral(this.low).format("0,0.00");
            const high = numeral(this.high).format("0,0.00");
            const y = numeral(this.y).format("0,0.00");

            // Not Available series point
            if (seriesName.endsWith("NA")) {
              // don't render if linked series has value
              if (!this.series.linkedParent.data[this.index].isNull) return null;

              const name = seriesName.replace(" NA", "");
              return `<span style="color:${color}">●</span> ${name}: <b>Not Available</b><br/>`;
            }

            // Avg series point
            if (seriesName.endsWith("Avg")) {
              return `<span style="color:${color}">●</span> ${seriesName}: <b>${currency} ${y}</b><br/>`;
            }

            // Range series point
            return `<span style="color:${color}">●</span> ${seriesName}: <b>${currency} ${low}</b> - <b>${currency} ${high}</b><br/>`;
          },
        },

        series: series,
      };

      return (
        <div className="chart-container" style={{ height: 440 }}>
          <PlainHighChart options={chartConfig} />
        </div>
      );
    }
  }
);

export default ResultsTrendChart;
