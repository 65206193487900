// @flow
import { extendObservable, action, runInAction, computed, observable } from "mobx";
import axios from "axios";
import PaginationState from "../../models/PaginationState";
import NetworkState from "../../models/NetworkState";
import type { PageQuery, PaginationInfo } from "../../models/PaginationState";
import NotificationsList from "../../models/NotificationsList";
import FilterObject, { FILTER_COLUMN } from "../../models/Filter";
import { NotificationsFilter } from "../../models/FilterState";
import { consolidateAppliedFilters } from "./SupportFunctions";
import ModalState from "../../models/ModalState";
import type MobXStore from "./MobXStore";
import type { FetchGraphQL } from "../../App";

export class NotificationsListComponentStore {
  fetchGraphQL: FetchGraphQL;
  mobxStore: MobXStore;
  showHelpModal: boolean;
  getNotifications: (PageQuery) => Promise<PaginationInfo>;
  network: NetworkState;
  pagination: PaginationState;
  totalCount: any;
  unreadNotificationCount: any;
  notificationList: NotificationsList[];
  notificationListForCurtain: NotificationsList[];
  notificationListView: NotificationsList[];
  notificationFilter: NotificationsFilter;
  getUnreadNotificationCount: () => Promise<any>;
  getNotificationListForCurtain: () => Promise<any>;

  defaultFilters: {
    [key: typeof FILTER_COLUMN]: FilterObject,
  };
  appliedFilters: {
    [key: typeof FILTER_COLUMN]: FilterObject,
  };
  appliedSorts: {
    [key: typeof FILTER_COLUMN]: Sort,
  };
  isFiltered: boolean;
  appliedSortsOrder: Array<typeof FILTER_COLUMN>;
  showHelp: () => void;
  hideHelp: () => void;
  applyFilter: (typeof FILTER_COLUMN, FilterObject) => void;
  removeFilter: (FilterColumn) => void;
  removeSort: (FilterColumn) => void;
  clearFilters: () => void;
  allowMultipleItemSelection: boolean;
  confirmDeleteModal: ModalState;
  helpModal: ModalState;
  toggleNotificationCurtain: boolean;
  handleOpenNotificationCurtain: () => void;

  constructor(fetchGraphQL: FetchGraphQL, mobxStore: MobXStore) {
    this.mobxStore = mobxStore;
    this.fetchGraphQL = fetchGraphQL;
    this.getNotifications = action(this.getNotifications.bind(this));
    this.getUnreadNotificationCount = action(this.getUnreadNotificationCount.bind(this));
    this.getNotificationListForCurtain = action(
      this.getNotificationListForCurtain.bind(this)
    );

    this.applyFilter = action(this.applyFilter.bind(this));
    this.applySort = action(this.applySort.bind(this));
    this.removeFilter = action(this.removeFilter.bind(this));
    this.removeSort = action(this.removeSort.bind(this));
    this.handleOpenNotificationCurtain = action(
      this.handleOpenNotificationCurtain.bind(this)
    );

    extendObservable(this, {
      helpModal: new ModalState(),
      network: new NetworkState(),
      pagination: new PaginationState(this.getNotifications),
      totalCount: 0,
      unreadNotificationCount: 0,
      notificationList: [],
      notificationListForCurtain: [],
      notificationListView: computed(() => {
        return this.notificationList.map((notification) => {
          return notification;
        });
      }),
      appliedSortsOrder: observable.shallow([]),
      notificationFilter: new NotificationsFilter(
        this,
        FILTER_COLUMN.NOTIFICATION_LABEL,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      defaultFilters: {},
      appliedFilters: {},
      appliedSorts: {},
      isFiltered: false,
      allowMultipleItemSelection: true,
      confirmDeleteModal: new ModalState(),
      toggleNotificationCurtain: false,
    });

    this.showHelp = action(this.showHelp.bind(this));
    this.hideHelp = action(this.hideHelp.bind(this));
  }

  showHelp() {
    this.showHelpModal = true;
  }

  hideHelp() {
    this.showHelpModal = false;
  }

  applyFilter(column: FilterColumn, filter: FilterObject) {
    this.appliedFilters[column] = filter;
    this.isFiltered = true;
  }

  removeFilter(column: FilterColumn) {
    delete this.appliedFilters[column];

    let entries = Object.entries(this.appliedFilters);
    if (!entries.length) this.isFiltered = false;
  }

  applySort(column: FilterColumn, sort: Sort) {
    this.appliedSorts[column] = sort;

    const index = this.appliedSortsOrder.indexOf(column);
    if (index === -1) this.appliedSortsOrder.push(column);
  }

  removeSort(column: FilterColumn) {
    delete this.appliedSorts[column];

    const index = this.appliedSortsOrder.indexOf(column);
    if (index > -1) this.appliedSortsOrder.splice(index, 1);
  }

  clearFilters() {
    this.notificationFilter = new NotificationsFilter(
      this,
      FILTER_COLUMN.NOTIFICATION_LABEL,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );

    this.appliedFilters = observable({});
    this.appliedSorts = observable({});
    this.appliedSortsOrder.length = 0;
    this.isFiltered = false;

    return this.pagination.goFetch(null);
  }

  handleOpenNotificationCurtain() {
    this.toggleNotificationCurtain = !this.toggleNotificationCurtain;
    if (this.toggleNotificationCurtain) {
      this.getNotificationListForCurtain();
    }
    this.mobxStore.dashboardStore.menuDrawerOpen = false;
    this.mobxStore.dashboardStore.profileDrawerOpen = false;
  }

  //function for get Notifications list
  async getNotifications(pageQuery: PageQuery): Promise<PaginationInfo> {
    let result = null;
    let params: string[] = pageQuery.params;
    let args = pageQuery.args;
    let variables = pageQuery.variables;
    let filtersCriteria: string[] = [];

    consolidateAppliedFilters(this.appliedFilters, params, filtersCriteria, variables);

    consolidateAppliedFilters(this.defaultFilters, params, filtersCriteria, variables);

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");
    const queryFiltersCriteria = filtersCriteria.join(", ");

    const query = `
      query getNotifications(${queryParams}){
          viewer{
            notifications(${queryArgs}, ${queryFiltersCriteria}) {
              totalCount
              unreadNotificationCount
              edges {
                node {
                  id
                  created
                  isRead
                  message
                  contentObject {
                   notificationType
                   contentId
                 }
                }
              }
            }
          }
        }
      `;

    this.network.loading = true;

    try {
      result = await this.fetchGraphQL(query, variables);
    } catch (e) {
      console.log("error", e);
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting Scheduled Search", e);
      if (result !== null) {
        this.network.logGraphQLError("Get Scheduled Search query", result);
      }
      return e;
    }

    return runInAction("getNotifications--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get Scheduled Searches query", result)) {
        return {
          totalCount: 0,
        };
      }

      const notifications = result.data.viewer.notifications.edges;
      this.totalCount = result.data.viewer.notifications.totalCount;
      this.unreadNotificationCount =
        result.data.viewer.notifications.unreadNotificationCount;
      this.notificationList = notifications.map((notif) => {
        const notification = new NotificationsList(this, notif.node);
        return notification;
      });

      return {
        totalCount: result.data.viewer.notifications.totalCount,
      };
    });
  }

  async getUnreadNotificationCount(): Promise<any> {
    const query = `
    query getUnreadNotificationCount {
      viewer {
        notifications(first: 10) {
         totalCount
         unreadNotificationCount
        }
      }
    }
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, null);
    } catch (e) {
      console.error("Error getting unread notification count", e);
      throw e;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }

    runInAction("getUnreadNotificationCount--success", () => {
      this.totalCount = res.data.viewer.notifications.totalCount;
      this.unreadNotificationCount =
        res.data.viewer.notifications.unreadNotificationCount;
      return this.unReadNotificationCountForBell;
    });
    return {
      unreadNotificationCount: res.data.viewer.notifications.unreadNotificationCount,
    };
  }

  async getNotificationListForCurtain(): Promise<any> {
    const query = `
    query getNotifications {
      viewer {
        notifications(first: 10) {
         totalCount
         unreadNotificationCount
         edges {
            node {
              id
              created
              isRead
              message
              contentObject {
               notificationType
               contentId
             }
            }
          }
        }
      }
    }
    `;

    let res = null;
    this.network.loading = true;

    try {
      res = await this.fetchGraphQL(query, null);
    } catch (e) {
      console.error("Error getting unread notification count", e);
      throw e;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    runInAction("getNotificationListForCurtain--success", () => {
      this.network.loading = false;
      const notifications = res.data.viewer.notifications.edges;
      this.totalCount = res.data.viewer.notifications.totalCount;
      this.unreadNotificationCount =
        res.data.viewer.notifications.unreadNotificationCount;

      this.notificationListForCurtain = notifications.map((notif) => {
        const notification = new NotificationsList(this, notif.node);
        return notification;
      });
    });
    return {
      unreadNotificationCount: res.data.viewer.notifications.unreadNotificationCount,
    };
  }
}

export default class NotificationsListStore extends NotificationsListComponentStore {
  markAllNotificationAsRead: (string) => void;
  markNotificationAsRead: (number) => void;

  constructor(fetchGraphQL: FetchGraphQL, mobxStore: MobXStore) {
    super(fetchGraphQL, mobxStore);

    this.markAllNotificationAsRead = action(this.markAllNotificationAsRead.bind(this));
    this.markNotificationAsRead = action(this.markNotificationAsRead.bind(this));
  }

  async markAllNotificationAsRead(action: string) {
    const query = `
      mutation markAllNotificationAsRead{
       markAllNotificationAsRead(input:{}) {
         ok
       }
      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, null);
    } catch (e) {
      this.network.handleError("Marking all notification as read", e);
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    runInAction("markAllNotificationAsRead--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("markAllNotificationAsRead", res)) {
        return;
      }
      this.unreadNotificationCount = 0;
      if (action && action === "curtain") {
        this.getNotificationListForCurtain();
      } else {
        this.pagination.goFetch(null);
      }
    });
  }

  async markNotificationAsRead(notificationId: number) {
    const query = `
      mutation markNotificationAsRead{
       markNotificationAsRead(input:{notificationId:${notificationId}}) {
         ok
       }
      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, null);
    } catch (e) {
      this.network.handleError("Marking all notification as read", e);
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    runInAction("markNotificationAsRead--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("markNotificationAsRead", res)) {
        return;
      }
      this.unreadNotificationCount = this.unreadNotificationCount - 1;
    });
  }
}
