import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LocationSelectNew from "../../../components/selects/LocationSelectNew";
import Modal from "../../ratecards/components/Modal";
import ContainerSection from "../../ratecards/components/ContainerSection";
import InfiniteScroll from "react-infinite-scroller";
import { observer } from "mobx-react";
import SelectableItemsList from "../../../components/SelectableItemsList";
import SelectableItem from "../../ratecards/components/SelectableItem";

function remove_duplicates(objectsArray) {
  var usedObjects = {};

  for (var i = objectsArray.length - 1; i >= 0; i--) {
    var so = JSON.stringify(objectsArray[i]);

    if (usedObjects[so]) {
      objectsArray.splice(i, 1);
    } else {
      usedObjects[so] = true;
    }
  }

  return objectsArray;
}

class LocationModal extends Component {
  render() {
    const store = this.props.store;
    const rateSearchStore = this.props.rateSearchStore;

    var selectedLocations = [];
    var statesList = [];
    var citiesList = [];

    var locationsUnique = remove_duplicates(store.locations);
    locationsUnique.forEach((e) => {
      if (e) {
        var label = e.country;
        if (e.state && e.state.length > 0) {
          label = e.state + ", " + label;
        }
        if (e.city && e.city.length > 0) {
          label = e.city + ", " + label;
        }
        var object = {
          value: e.locationId,
          label: label,
          countryId: e.countryId,
        };
        selectedLocations.push(object);
      }
    });

    store.countryStates.forEach((state, index) => {
      const isSelected =
        selectedLocations.find(
          (val) => parseInt(val.value, 10) === parseInt(state.id, 10)
        ) !== undefined;
      statesList.push(
        <SelectableItem
          key={index}
          ignoreBodyClick={true}
          item={state}
          value={state.title}
          selected={isSelected}
          highlighted={store.stateSelected && store.stateID === state.id}
          onChange={(state) => {
            if (isSelected) {
              store.setLocationClick(
                state.id,
                state.title,
                "state",
                true // remove
              );
            } else {
              store.setLocationClick(state.id, state.title, "state");
            }
            store.cities(state.id, state.title);
          }}
        >
          <div
            className="flex-row flex-align-center no-padding"
            onClick={() => store.cities(state.id, state.title)}
          >
            <span className="flex-fill" style={{ flexBasis: "84%" }} title={state.title}>
              {state.title}
            </span>
            {store.stateSelected && store.stateID === state.id ? (
              <FontAwesomeIcon icon="chevron-right" className="text-green fa-fw" />
            ) : (
              <FontAwesomeIcon
                icon="angle-right"
                className="text-muted fa-fw"
                style={{ opacity: 0.4 }}
              />
            )}
          </div>
        </SelectableItem>
      );
    });

    store.stateCities.forEach((city, index) => {
      const isSelected = selectedLocations.find(
        (val) => parseInt(val.value, 10) === parseInt(city.id, 10)
      );
      citiesList.push(
        <SelectableItem
          key={index}
          ignoreBodyClick={true}
          item={city}
          value={city.title}
          selected={Boolean(isSelected)}
          onChange={(city) => {
            if (isSelected) {
              store.setLocationClick(
                city.id,
                city.title,
                "city",
                true // remove
              );
            } else {
              store.setLocationClick(city.id, city.title, "city");
            }
            store.selectedCity(city.id, city.title);
          }}
        >
          <div
            className="flex-row flex-align-center no-padding"
            onClick={() => store.selectedCity(city.id, city.title)}
          >
            <span className="flex-fill" style={{ flexBasis: "84%" }} title={city.title}>
              {city.title}
            </span>
          </div>
        </SelectableItem>
      );
    });

    return (
      <Modal
        show={store.showLocationPopup}
        onHide={store.closeModel}
        innerStyle={{
          width: "70% !important",
        }}
        fullScreen={true}
      >
        <ContainerSection className="header">
          <h4>Search Location</h4>
        </ContainerSection>
        <ContainerSection>
          <div data-testid="location-modal-location-select">
            <LocationSelectNew
              locationTitle={(param) => rateSearchStore.locationTitle(param)}
              clientIsClientJobLibrary={this.props.clientIsClientJobLibrary || false}
              certifiedCountries={rateSearchStore.certifiedCountries}
              adhocCountries={rateSearchStore.adhocCountries}
              value={selectedLocations}
              onChange={(locations) => store.onLocationChange(locations)}
            />
          </div>
          <div className="select-list" style={{ minWidth: "70vw" }}>
            <div className="col-sm-4">
              <SelectableItemsList
                className="no-margin"
                style={{ overflowY: "scroll", height: 360 }}
              >
                {store.countriesList.map((country, index) => {
                  const isSelected =
                    selectedLocations.find(
                      (val) =>
                        parseInt(val.value, 10) === parseInt(country.locationId, 10)
                    ) !== undefined;
                  return (
                    <SelectableItem
                      key={index}
                      ignoreBodyClick={true}
                      item={country}
                      value={country.name}
                      selected={isSelected}
                      highlighted={
                        store.countrySelected && store.countryID === country.locationId
                      }
                      onChange={(country) => {
                        if (isSelected) {
                          store.setLocationClick(
                            country.locationId,
                            country.name,
                            "country",
                            true // remove
                          );
                        } else {
                          store.setLocationClick(
                            country.locationId,
                            country.name,
                            "country"
                          );
                        }
                        store.states(country.locationId, country.name);
                      }}
                    >
                      <div
                        className="flex-row flex-align-center no-padding"
                        onClick={() => store.states(country.locationId, country.name)}
                      >
                        <span
                          className="flex-fill"
                          style={{ flexBasis: "84%" }}
                          title={country.name}
                        >
                          {country.name}
                        </span>
                        {store.countrySelected &&
                        store.countryID === country.locationId ? (
                          <FontAwesomeIcon
                            icon="chevron-right"
                            className="text-green fa-fw"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon="angle-right"
                            className="text-muted fa-fw"
                            style={{ opacity: 0.4 }}
                          />
                        )}
                      </div>
                    </SelectableItem>
                  );
                })}
              </SelectableItemsList>
            </div>
            <div className="col-sm-4">
              {store.countrySelected ? (
                <SelectableItemsList
                  className="no-margin"
                  style={{ overflowY: "scroll", height: 360 }}
                >
                  <InfiniteScroll
                    loadMore={store.getStates}
                    hasMore={store.loadMoreStates}
                    useWindow={false}
                    ref={(scrollState) => {
                      store.scrollState = scrollState;
                    }}
                  >
                    {statesList}
                  </InfiniteScroll>
                </SelectableItemsList>
              ) : (
                ""
              )}
            </div>
            <div className="col-sm-4">
              {store.stateSelected ? (
                <SelectableItemsList
                  className="no-margin"
                  style={{ overflowY: "scroll", height: 360 }}
                >
                  <InfiniteScroll
                    loadMore={store.getCities}
                    hasMore={store.loadMoreCities}
                    useWindow={false}
                    ref={(scroll) => {
                      store.scroll = scroll;
                    }}
                  >
                    {citiesList}
                  </InfiniteScroll>
                </SelectableItemsList>
              ) : (
                ""
              )}
            </div>
            <div className="clearfix" />
          </div>
          <div className="clearfix" />
        </ContainerSection>
        <ContainerSection className="footer">
          <div className="pull-right">
            <button className="btn btn-lg btn-default" onClick={store.closeModel}>
              Cancel
            </button>
            <button className="btn btn-lg btn-green" onClick={store.saveLocation}>
              Save Location
            </button>
          </div>
        </ContainerSection>
      </Modal>
    );
  }
}

LocationModal.propTypes = {
  store: PropTypes.object.isRequired,
  rateSearchStore: PropTypes.object.isRequired,
};

export default observer(LocationModal);
