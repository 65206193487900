import React, { useCallback, useEffect, useMemo } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";

import { TickerPageLoader } from "../../../components/lib/TickerLoader";
import { emptyList, emptyMap, emptyOrderedMap } from "../../../constants";
import { reviewToImmutableMap } from "../dataConverters";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";
import AlertWithButton from "../../../components/AlertWithButton";
import { reviewGlobalState } from "../globalState";
import { baseUrlPath } from "../../validator5K/constants";

import {
  ReviewDataMap,
  ReviewDataObject,
  REVIEW_TYPES_TYPE,
  REVIEW_PROCESSING_STATUS_TYPES_TYPE,
  SOURCE_TYPES_TYPE,
  REQUIRED_RATES_LIST,
  JobDataMap,
  JobsDataOrderedMap,
  REVIEW_TYPES,
  SOURCE_TYPES,
  RATE_TYPES_TYPE,
  RATE_TYPES,
  CurrencyDataMap,
  QuestionsOrderedMap,
  AttemptsDataOrderedMap,
  RateResultsOrderedMap,
} from "../types";
import type { FetchAPIResponse } from "../../../types/fetch";
import type { CommonChildPageProps } from "../Val5KAdmin";
import type { ReviewGlobalState } from "../globalState";
import { useVal5KAdminContext } from "./Val5KAdminContext";
import { getJobRateTypeParameters } from "../utils";

type RefreshReviewDataFuncType = () => Promise<ReviewDataMap | undefined>;

function getReviewLink(reviewType: REVIEW_TYPES_TYPE, id: string): string {
  let urlPath = "";
  if (reviewType === REVIEW_TYPES.CLASSIC) {
    urlPath = `${baseUrlPath}/surveys/${id}/classic`;
  } else if (reviewType === REVIEW_TYPES.FILL_THE_BLANK) {
    urlPath = `${baseUrlPath}/surveys/${id}/fill-the-blank`;
  } else if (reviewType === REVIEW_TYPES.ORDERED) {
    urlPath = `${baseUrlPath}/surveys/${id}/ordered`;
  } else {
    return "";
  }

  return new URL(urlPath, globalThis.location.origin).toString();
}

export type ReviewDataContextObject = {
  reviewData: ReviewDataMap;
  refreshReviewData: RefreshReviewDataFuncType;
  //
  reviewId: string;
  reviewTitle: string;
  reviewType: REVIEW_TYPES_TYPE;
  isActive: boolean;
  sourceType: SOURCE_TYPES_TYPE;
  clientId: number;
  clientName: string;
  ratecardId: number | null;
  ratecardName: string | null;
  programName: string | null;
  storedIndexName: string | null;
  totalReward: number;
  instructions: string | null;
  reviewsGroupId: string;
  reviewLink: string;
  industriesString: string;
  categoriesString: string;
  countriesString: string;
  processingStatus: REVIEW_PROCESSING_STATUS_TYPES_TYPE;
  requiredRates: REQUIRED_RATES_LIST;
  //
  jobsData: JobsDataOrderedMap;
  firstJobData: JobDataMap;
  questionsData: QuestionsOrderedMap;
  //
  attemptsData: AttemptsDataOrderedMap;
  attemptsMade: number;
  attemptsLeft: number | null;
  maxAttempts: number;
  //
  isAnnualRateSurvey: boolean;
  isThreeLevelBanding: boolean;
  isFreeValidation: boolean;
  isEditable: boolean;
  //
  isClassicSurvey: boolean;
  isFillInTheBlankSurvey: boolean;
  isOrderedSurvey: boolean;
  //
  isPrivateIndexSource: boolean;
  isRateCardSource: boolean;
  isUploadSource: boolean;
  //
  firstJobId: number;
  firstJobTitle: string;
  firstJobRateType: RATE_TYPES_TYPE;
  firstJobRateMultiplier: number;
  firstJobRateResults: RateResultsOrderedMap;
  //
  firstJobCurrencyData: CurrencyDataMap; // original currency coming from job location
  displayCurrencyData: CurrencyDataMap; // display currency that's been set on a survey level at the time it's being created
};

export const ReviewDataContext = React.createContext<ReviewDataContextObject>({
  reviewData: undefined as unknown as ReviewDataMap,
  refreshReviewData: undefined as unknown as RefreshReviewDataFuncType,
  //
  reviewId: undefined as unknown as string,
  reviewTitle: undefined as unknown as string,
  reviewType: undefined as unknown as REVIEW_TYPES_TYPE,
  isActive: undefined as unknown as boolean,
  sourceType: undefined as unknown as SOURCE_TYPES_TYPE,
  clientId: undefined as unknown as number,
  clientName: undefined as unknown as string,
  ratecardId: undefined as unknown as number,
  ratecardName: undefined as unknown as string,
  programName: undefined as unknown as string,
  storedIndexName: undefined as unknown as string,
  totalReward: undefined as unknown as number,
  instructions: undefined as unknown as string,
  reviewsGroupId: undefined as unknown as string,
  reviewLink: undefined as unknown as string,
  industriesString: undefined as unknown as string,
  categoriesString: undefined as unknown as string,
  countriesString: undefined as unknown as string,
  processingStatus: undefined as unknown as REVIEW_PROCESSING_STATUS_TYPES_TYPE,
  requiredRates: undefined as unknown as REQUIRED_RATES_LIST,
  //
  jobsData: undefined as unknown as JobsDataOrderedMap,
  firstJobData: undefined as unknown as JobDataMap,
  questionsData: undefined as unknown as QuestionsOrderedMap,
  //
  attemptsData: undefined as unknown as AttemptsDataOrderedMap,
  attemptsMade: undefined as unknown as number,
  attemptsLeft: undefined as unknown as number,
  maxAttempts: undefined as unknown as number,
  //
  isAnnualRateSurvey: undefined as unknown as boolean,
  isThreeLevelBanding: undefined as unknown as boolean,
  isFreeValidation: undefined as unknown as boolean,
  isEditable: undefined as unknown as boolean,
  //
  isClassicSurvey: undefined as unknown as boolean,
  isFillInTheBlankSurvey: undefined as unknown as boolean,
  isOrderedSurvey: undefined as unknown as boolean,
  //
  isPrivateIndexSource: undefined as unknown as boolean,
  isRateCardSource: undefined as unknown as boolean,
  isUploadSource: undefined as unknown as boolean,
  //
  firstJobId: undefined as unknown as number,
  firstJobTitle: undefined as unknown as string,
  firstJobRateType: undefined as unknown as RATE_TYPES_TYPE,
  firstJobRateMultiplier: undefined as unknown as number,
  firstJobRateResults: undefined as unknown as RateResultsOrderedMap,
  //
  firstJobCurrencyData: undefined as unknown as CurrencyDataMap,
  displayCurrencyData: undefined as unknown as CurrencyDataMap,
});

export function getReviewDataContextValues(
  reviewData: ReviewDataMap,
  refreshReviewData?: RefreshReviewDataFuncType
): ReviewDataContextObject {
  const attemptsData = reviewData.get("attempts") ?? emptyOrderedMap;
  const maxAttempts = reviewData.get("max_attempts");
  const attemptsMade = attemptsData?.size || 0;
  const attemptsLeft =
    maxAttempts != null
      ? maxAttempts > attemptsMade
        ? maxAttempts - attemptsMade
        : 0
      : null;

  const reviewType = reviewData.get("review_type");
  const isClassicSurvey = reviewType === REVIEW_TYPES.CLASSIC;
  const isFillInTheBlankSurvey = reviewType === REVIEW_TYPES.FILL_THE_BLANK;
  const isOrderedSurvey = reviewType === REVIEW_TYPES.ORDERED;

  const sourceType = reviewData.get("source_type");
  const isPrivateIndexSource = sourceType === SOURCE_TYPES.FROM_PRIVATE_INDEX;
  const isRateCardSource = sourceType === SOURCE_TYPES.FROM_RATECARD;
  const isUploadSource = sourceType === SOURCE_TYPES.FROM_UPLOAD;

  const firstJobData =
    reviewData.get("jobs", emptyOrderedMap as unknown as JobsDataOrderedMap)?.first() ??
    emptyMap;
  const firstJobId = firstJobData.get("id");
  const firstJobTitle = firstJobData.get("title");
  const { rateType: firstJobRateType, rateMultiplier: firstJobRateMultiplier } =
    getJobRateTypeParameters(firstJobData);
  const firstJobCurrencyData = firstJobData.get("currency_data") ?? emptyMap;
  const displayCurrencyData =
    reviewData.get("display_currency_data") ?? firstJobCurrencyData;
  const firstJobRateResults = firstJobData.get("rate_results") ?? emptyOrderedMap;
  const isAnnualRateSurvey = firstJobRateType === RATE_TYPES.ANNUAL;

  const categoriesString = isOrderedSurvey
    ? reviewData.get("categories_string")
    : firstJobData.get("category");
  const industriesString = isOrderedSurvey
    ? reviewData.get("industries_string")
    : firstJobData.get("industry_name");

  return {
    reviewData,
    refreshReviewData:
      refreshReviewData || ((() => undefined) as unknown as RefreshReviewDataFuncType),
    //
    reviewId: reviewData.get("id"),
    reviewTitle: reviewData.get("title"),
    reviewType: reviewType,
    sourceType: sourceType,
    isActive: reviewData.get("is_active", false),
    clientId: reviewData.get("client_id"),
    clientName: reviewData.get("client_name"),
    ratecardId: reviewData.get("ratecard_id"),
    ratecardName: reviewData.get("ratecard_name"),
    programName: reviewData.get("program_name"),
    storedIndexName: reviewData.get("stored_index_name"),
    totalReward: reviewData.get("total_reward"),
    instructions: reviewData.get("instructions"),
    reviewsGroupId: reviewData.get("reviews_group_id"),
    reviewLink: getReviewLink(reviewType, reviewData.get("id")),
    industriesString: industriesString,
    categoriesString: categoriesString,
    countriesString: reviewData.get("countries_string"),
    processingStatus: reviewData.get("processing_status"),
    requiredRates: reviewData.get("required_rates") ?? emptyList,
    //
    isAnnualRateSurvey,
    isThreeLevelBanding: reviewData.get("is_three_level_banding", false),
    isFreeValidation: reviewData.get("free_validation", false),
    isEditable: attemptsData.size === 0,
    //
    jobsData: reviewData.get("jobs") ?? emptyOrderedMap,
    firstJobData,
    questionsData: reviewData.get("questions", emptyList),
    //
    attemptsData,
    attemptsMade,
    attemptsLeft,
    maxAttempts,
    //
    isClassicSurvey,
    isFillInTheBlankSurvey,
    isOrderedSurvey,
    //
    isPrivateIndexSource,
    isRateCardSource,
    isUploadSource,
    //
    firstJobId,
    firstJobTitle,
    firstJobRateType,
    firstJobRateMultiplier,
    firstJobRateResults,
    //
    firstJobCurrencyData,
    displayCurrencyData: displayCurrencyData,
  };
}

type ReviewDataContextProviderProps = CommonChildPageProps & {
  params: {
    reviewId: string;
  };
  children: React.ReactElement;
};

const ReviewDataContextProvider = (props: ReviewDataContextProviderProps) => {
  const { params, router, fetchArgusAPI, showModalError, children } = props;

  const reviewId = params.reviewId;

  // global review state

  const [{ reviewData, reviewDataLoaded }, setReviewGlobalState] =
    useRecoilState<ReviewGlobalState>(reviewGlobalState);
  const resetReviewGlobalState = useResetRecoilState(reviewGlobalState);

  // data fetch

  const fetchReviewData = useCallback(async () => {
    try {
      const response: FetchAPIResponse<ReviewDataObject> = await fetchArgusAPI(
        `reviews/${reviewId}/`
      );
      const data: ReviewDataMap = reviewToImmutableMap(response.data);

      setReviewGlobalState({
        reviewData: data,
        reviewDataLoaded: true,
      });

      return data;
    } catch (err: any) {
      logAsyncOperationError("fetchReviewItemData", err);
      showModalError(`Error occurred while loading review #${reviewId} data.`);
    }
  }, [reviewId, setReviewGlobalState, fetchArgusAPI, showModalError]);

  // handlers

  const handleBackToList = useCallback(
    () => router.push("/admin/val5000/surveys"),
    [router]
  );

  // effects

  useEffect(() => {
    fetchReviewData();
    return resetReviewGlobalState;
  }, [fetchReviewData, resetReviewGlobalState]);

  // context

  const globalContextValues = useVal5KAdminContext();

  const reviewContextValues = useMemo(
    () => getReviewDataContextValues(reviewData, fetchReviewData),
    [reviewData, fetchReviewData]
  );

  // render

  if (!reviewDataLoaded) {
    return <TickerPageLoader />;
  }

  if (!reviewData.size) {
    return (
      <AlertWithButton
        head="Requested survey data is not found."
        onButtonClick={handleBackToList}
      />
    );
  }

  if (!reviewData.get("jobs")?.first()?.size) {
    return (
      <AlertWithButton
        head="No job data found for the survey."
        onButtonClick={handleBackToList}
      />
    );
  }

  return (
    <ReviewDataContext.Provider value={reviewContextValues}>
      {React.cloneElement(React.Children.only(children), {
        ...globalContextValues,
        ...reviewContextValues,
      })}
    </ReviewDataContext.Provider>
  );
};

ReviewDataContextProvider.displayName = "ReviewDataContextProvider";

export const useReviewDataContext = () => React.useContext(ReviewDataContext);

export default ReviewDataContextProvider;
