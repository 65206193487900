import React from "react";

import Inline from "../../components/lib/Inline";
import Text from "../../components/lib/Text";
import Icon from "../../components/lib/Icon";
import Stack from "../../components/lib/Stack";
import Button from "../../components/lib/Button";
import { ButtonGroupRight } from "../../components/lib/ButtonGroup";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogClose,
  DialogDescription,
  DialogActions,
} from "../../components/lib/Dialog";

type Props = {
  show: boolean;
  onClose: () => void;
};

export default function SearchAllocatorHelpModal(props: Props) {
  const { show, onClose } = props;

  return (
    <Dialog open={show} onOpenChange={onClose}>
      <DialogContent css={{ minWidth: "700px" }}>
        <DialogTitle asChild>
          <Inline css={{ justifyContent: "space-between" }}>
            <Text as="h4">Search Allocator Help</Text>
            <DialogClose asChild>
              <Icon icon="times" css={{ cursor: "pointer" }} />
            </DialogClose>
          </Inline>
        </DialogTitle>
        <DialogDescription css={{ backgroundColor: "inherit" }} asChild>
          <Stack css={{ fontSize: "$sm", alignItems: "flex-start" }}>
            <h4>Summary</h4>
            <p>
              The search allocator is a tool that allows the per search administrator to:
            </p>
            <ol>
              <li>
                View the number of searches allocated and searches used for each user.
              </li>
              <li>Buy more searches using a credit card.</li>
              <li>Transfer searches from your client pool of searches to any user.</li>
              <li>Transfer any searches from one user to another.</li>
              <li>Return unused searches to the client pool.</li>
            </ol>
            <h4>Searches</h4>
            <p>
              As a client administrator you can move searches from your{" "}
              <strong>organization search pool</strong> to any <strong>user</strong> in
              your organization
            </p>
            <p>
              You can view your organization's search total at the top of the page. Any
              users assigned the role of 'Per Search Admin' will be able to manage this
              pool of searches among and between users
            </p>
            <p>
              You can drag the box to any user which will display a field allowing you to
              enter how many searches you wish to allocate to that user. You can also
              allocate searches from a user back to your client pool or allocate to
              another user.
            </p>
            <h4>Sorting</h4>
            <p>You can sort your users by selecting:</p>
            <ol>
              <li>
                <strong>Searches Allocated</strong> - Users will be sorted from those with
                the most searches left to the least
              </li>
              <li>
                <strong>Searches Used</strong> - Users will be sorted from those with the
                most searches used to the least
              </li>
              <li>
                <strong>Usernames (A-Z)</strong> - Sorted in alphabetical order from A to
                Z
              </li>
              <li>
                <strong>Usernames (Z-A)</strong> - Sorted in reverse-alphabetical order
                from Z to A
              </li>
            </ol>
            <h4>Contact Us</h4>
            <p>
              If you have additional questions or need assistance, please feel free to
              contact us at{" "}
              <a href="mailto:ratepoint@prounlimited.com">ratepoint@prounlimited.com</a>
            </p>
          </Stack>
        </DialogDescription>
        <DialogActions>
          <ButtonGroupRight fill>
            <Button size="large" onClick={onClose}>
              Close
            </Button>
          </ButtonGroupRight>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
