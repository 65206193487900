import React from "react";
import { styled } from "../../../../stitches.config";
import graphql from "babel-plugin-relay/macro";
// relay
import { HeadingForm_user$key } from "./__generated__/HeadingForm_user.graphql";
// primitives
import Inline from "../../../../components/lib/Inline";
import Stack from "../../../../components/lib/Stack";
import Button from "../../../../components/lib/Button";
import Text from "../../../../components/lib/Text";
// components
import { RequestMoreDialog } from "./RequestMoreDialog";
import { BuyMoreSearchesDialog } from "./BuyMoreSearchesDialog";
import { useFragment } from "react-relay";
import { useGlobalContext } from "../../../../globalContext";

const TextWrapper = styled("div", {
  gap: "$1_5",
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "column",
});

const gql = graphql`
  fragment HeadingForm_user on User {
    resourceAllocations(resourceType: SEARCH) {
      id
      balance
      isUnlimited
    }
  }
`;

type Props = {
  user: HeadingForm_user$key;
};

/**
 * Rate search form's heading
 */
export function Heading(props: Props) {
  const { user } = props;
  const data = useFragment(gql, user);

  const { perSearchPricing, pspStoreFlag } = useGlobalContext();

  const resourceAllocations = data?.resourceAllocations;
  const searchesRemaining = resourceAllocations?.[0]?.balance;
  const isUnlimitedSearch = resourceAllocations?.[0]?.isUnlimited || false;

  return (
    <Inline fill css={{ justifyContent: "space-between", alignItems: "flex-start" }}>
      <TextWrapper css={{ alignItems: "flex-start" }}>
        <Text as="h3" noMargin color="dark">
          Start Your Rate Search
        </Text>
        <Text color="primary">Search to view market rates</Text>
      </TextWrapper>
      <TextWrapper css={{ alignItems: "flex-end" }}>
        {perSearchPricing && (
          <Stack nogap>
            {pspStoreFlag ? (
              <BuyMoreSearchesDialog>
                <Button variant="outlined" size="extra-small" color="brand">
                  Buy More
                </Button>
              </BuyMoreSearchesDialog>
            ) : (
              <RequestMoreDialog>
                <Button variant="outlined" size="small" color="brand">
                  Request More
                </Button>
              </RequestMoreDialog>
            )}
            <Text color="primary">
              {isUnlimitedSearch ? "Unlimited" : searchesRemaining} Searches Remaining
            </Text>
          </Stack>
        )}
      </TextWrapper>
    </Inline>
  );
}
