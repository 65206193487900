import PropTypes from "prop-types";
import React, { Component } from "react";
import { observer } from "mobx-react";
import PlainHighChart from "../../ratecards/components/PlainHighChart";
// import numeral from 'numeral';
// import classNames from 'classnames';
// import LoadingIndicator from '../../ratecards/components/LoadingIndicator';
// import Change from './Change';
// import {Link} from 'react-router';

const ResultsByLevelChart = observer(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }

    componentDidCatch(error, errorInfo) {
      console.log("Caught error:", error);
      console.log("Caught error info:", errorInfo);
    }

    static getDerivedStateFromError(error) {
      return { hasError: true };
    }

    render() {
      if (this.state.hasError) {
        return <div></div>;
      }

      const search = this.props.search;
      let payAvg = [];
      let payMinMax = [];
      let billAvg = [];
      let billMinMax = [];

      let levelNames = [];

      let resultsByLevel = search.resultsByLevel;
      for (let levelName in resultsByLevel) {
        if (!resultsByLevel.hasOwnProperty(levelName)) continue;
        if (!resultsByLevel[levelName]) continue;

        let results = resultsByLevel[levelName];
        levelNames.push("Level " + results.levelRomanNumeral);
        payAvg.push(results.payRateAvg);
        billAvg.push(results.billRateAvg);

        payMinMax.push([results.payRateMin, results.payRateMax]);
        billMinMax.push([results.billRateMin, results.billRateMax]);
      }

      let currency = "$";
      if (search.currency) {
        currency = search.currency.symbol;
      }

      let rateType = search.frequency.title.toLowerCase();
      let seriesLabel = "Pay Rate";

      if (rateType === "fte") {
        seriesLabel = "Annual Salary";
      }

      let series = [];

      // Pay Rate Series
      series.push({
        name: `Avg ${seriesLabel}`,
        type: "spline",
        data: payAvg,
        tooltip: {
          pointFormat:
            '<span style="font-weight: bold; color: {series.color}">{series.name}: </span>' +
            '<span style="color: #6D7578; font-weight: bold;">' +
            currency +
            " {point.y:.2f}</span>",
        },
      });

      // Pay Rate Min, Max Series
      series.push({
        name: "Min, Max Pay",
        type: "errorbar",
        data: payMinMax,
        tooltip: {
          pointFormat:
            '<br/><span style="color: #8F8F8F;">Min: </span><span style="color: #6D7578;">' +
            currency +
            " {point.low:.2f}</span><br/>" +
            '<span style="color: #8F8F8F;">Max: </span><span style="color: #6D7578;">' +
            currency +
            " {point.high:.2f}</span><br/>",
        },
      });

      // Add Bill Rate Series only if HOURLY and Standard view (5 - levels)
      if (rateType === "contract" && !search.showThreeLevelsView) {
        // Bill Rate Series
        series.push({
          name: "Avg Bill Rate",
          type: "spline",
          data: billAvg,
          tooltip: {
            pointFormat:
              '<span style="visibility: hidden;">_</span><br/>' +
              '<span style="font-weight: bold; padding-top: 10px; color: {series.color}">{series.name}: </span>' +
              '<span style="color: #6D7578; font-weight: bold;">' +
              currency +
              " {point.y:.2f}</span>",
          },
        });

        // Bill Rate Min, Max Series
        series.push({
          name: "Min, Max Bill",
          type: "errorbar",
          data: billMinMax,
          tooltip: {
            pointFormat:
              '<br/><span style="color: #8F8F8F;">Min: </span><span style="color: #6D7578;">' +
              currency +
              " {point.low:.2f}</span><br/>" +
              '<span style="color: #8F8F8F;">Max: </span><span style="color: #6D7578;">' +
              currency +
              " {point.high:.2f}</span><br/>",
          },
        });
      }

      let chartConfig = {
        lang: {
          thousandSep: ",",
        },
        chart: {
          zoomType: "xy",
          height: 460,
          type: "spline",
        },
        title: null,
        xAxis: [
          {
            categories: levelNames,
            labels: {
              style: {
                fontSize: "14px",
              },
            },
          },
        ],
        yAxis: [
          {
            // Primary yAxis
            labels: {
              format: `${currency} {value}`,
              style: {
                fontSize: "14px",
              },
            },
            title: {
              text: null,
              format: `${currency} {value}`,
              // style: {
              //   color: '#c2d646'
              // }
            },
          },
        ],

        tooltip: {
          shared: true,
          hideDelay: 0,
          shape: "callout",
          headerFormat: "",
          borderColor: "#6D7578",
          borderRadius: 5,
          style: {
            fontSize: "14px",
          },
          positioner: function () {
            return { x: 110, y: 60 };
          },
        },

        plotOptions: {
          errorbar: {
            color: "#6D7578",
            stemColor: null,
            stemDashStyle: "Solid",
            stemWidth: 2,
            whiskerColor: null,
            whiskerLength: "35 %",
            whiskerWidth: 1,
          },
        },

        series: series,
      };

      return (
        <div className="chart-container pay-rate-vs-bill-rate-chart">
          <PlainHighChart options={chartConfig} theme="default" />
        </div>
      );
    }
  }
);

ResultsByLevelChart.propTypes = {
  search: PropTypes.object.isRequired,
};

export default ResultsByLevelChart;
