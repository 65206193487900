import React from "react";
import { defaultComparators } from "./constants";

import type { ColumnConfigObject, GroupConfigObject, RowData } from "./types";

export interface ColumnProps<RD = RowData> extends ColumnConfigObject<RD> {
  children?: undefined;
}

// This component won't be rendered to the DOM,
// it acts like a properties holder, and implemented as a Component for developers usability
export const Column = <RD = RowData>(props: ColumnProps<RD>) => null;
Column.displayName = "Column";
Column.defaultProps = {
  title: "",
  getter: (rowData: any) => null,
  formatter: (value: any) => value,
  comparators: defaultComparators,
  sortable: false,
  filterable: false,
  fixed: false,
};

export interface GroupProps<RD = RowData> extends Omit<GroupConfigObject, "columns"> {
  children: Array<ColumnLikeElement<RD> | null | false> | ColumnLikeElement<RD>;
}

// This component won't be rendered to the DOM,
// it acts like a properties holder, and implemented as a Component for developers usability
export const Group = <RD = RowData>(props: GroupProps<RD>) => null;
Group.displayName = "Group";
Group.defaultProps = {
  title: "",
};

export type ColumnElement<RD = RowData> = React.ReactElement<
  ColumnProps<RD>,
  typeof Column<RD>
>;
export type GroupElement<RD = RowData> = React.ReactElement<
  GroupProps<RD>,
  typeof Group<RD>
>;

// folloing two types for Column/Group components extension
export type ColumnLikeElement<RD = RowData> = React.ReactElement<
  ColumnProps<RD>,
  React.FunctionComponent<ColumnProps<RD>>
>;
export type GroupLikeElement<RD = RowData> = React.ReactElement<
  GroupProps<RD>,
  React.FunctionComponent<GroupProps<RD>>
>;
