/**
 * @generated SignedSource<<325bb869994d90c7c24fac6780a7fdf4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientFiltersInput = {
  activeFlag?: boolean | null;
  exclude?: ReadonlyArray<string | null> | null;
  nameIContains?: string | null;
  only?: ReadonlyArray<string | null> | null;
};
export type FilterAndSortClientsQuery$variables = {
  count?: number | null;
  cursor?: string | null;
  filters?: ClientFiltersInput | null;
};
export type FilterAndSortClientsQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"FilterAndSortClients_viewer">;
  } | null;
};
export type FilterAndSortClientsQuery = {
  response: FilterAndSortClientsQuery$data;
  variables: FilterAndSortClientsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v3 = {
  "kind": "Variable",
  "name": "filters",
  "variableName": "filters"
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v3/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Literal",
    "name": "order",
    "value": [
      {
        "direction": "ASC",
        "field": "NAME"
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FilterAndSortClientsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v3/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "FilterAndSortClients_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "FilterAndSortClientsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "ClientTypeNodeConnection",
            "kind": "LinkedField",
            "name": "allClients",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ClientTypeNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClientTypeNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "clientId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "filters": [
              "filters"
            ],
            "handle": "connection",
            "key": "FilterAndSortClients_allClients",
            "kind": "LinkedHandle",
            "name": "allClients"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a075ea8db97c1ee6eca9194160904875",
    "id": null,
    "metadata": {},
    "name": "FilterAndSortClientsQuery",
    "operationKind": "query",
    "text": "query FilterAndSortClientsQuery(\n  $filters: ClientFiltersInput\n  $count: Int\n  $cursor: String\n) {\n  viewer {\n    ...FilterAndSortClients_viewer_4DMzEc\n  }\n}\n\nfragment FilterAndSortClients_viewer_4DMzEc on Viewer {\n  allClients(first: $count, after: $cursor, filters: $filters, order: [{direction: ASC, field: NAME}]) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalCount\n    edges {\n      node {\n        id\n        clientId\n        name\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "59890c6822b1eb54c7cd5b966726f6bd";

export default node;
