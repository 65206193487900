import { useEffect, useState } from "react";

import { CountryLibrary, JobLibrary } from "../../types";
import { useGlobalContext } from "../../../../globalContext";

let CountryLibraryCache: CountryLibrary[] | null = null;

export function useFetchCountryLibrary() {
  const { fetchAPI } = useGlobalContext();
  const [options, setOptions] = useState<CountryLibrary[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const countryLibraryQuery = `
      query getCountryLibraries {
        countryLibraries {
          edges {
            node {
              databaseId
              title
            }
          }
        }
      }
    `;

    if (CountryLibraryCache) {
      setOptions(CountryLibraryCache);
      setLoading(false);
      return;
    }

    // TODO: fix fetch inside useEffect, move to relay.
    fetchAPI("taste/", { query: countryLibraryQuery, variables: {} } as any).then(
      (res: any) => {
        const countryLibraryEdges = res?.data?.data?.countryLibraries?.edges;
        const countryLibraries = countryLibraryEdges.map((cle: any) => cle.node);
        setOptions(countryLibraries);
        CountryLibraryCache = countryLibraries;
        setLoading(false);
      }
    );
  }, [fetchAPI]);

  return { options, loading };
}

let JobLibraryCache: JobLibrary[] | null = null;

export function useFetchJobLibrary() {
  const { fetchAPI } = useGlobalContext();
  const [options, setOptions] = useState<JobLibrary[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const clientLibraryQuery = `
      query getClientLibraries {
        clientLibraries(order: {field: NAME, direction: ASC}) {
          edges {
            node {
              databaseId
              name
            }
          }
        }
      }
    `;
    if (JobLibraryCache) {
      setOptions(JobLibraryCache);
      setLoading(false);
      return;
    }
    // TODO: fix fetch inside useEffect, move to relay.
    fetchAPI("taste/", { query: clientLibraryQuery, variables: {} } as any).then(
      (res: any) => {
        const clientLibraryEdges = res?.data?.data?.clientLibraries?.edges;
        const clientLibraries = clientLibraryEdges.map((cle: any) => cle.node);
        setOptions(clientLibraries);
        JobLibraryCache = clientLibraries;
        setLoading(false);
      }
    );
  }, [fetchAPI]);

  return { options, loading };
}
