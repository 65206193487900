import graphql from "babel-plugin-relay/macro";
import React, { useState } from "react";
import { useFragment, useMutation } from "react-relay";
// globals
import { styled } from "../../../stitches.config";
// relay
import { SubmitRateSearchMutation } from "./__generated__/SubmitRateSearchMutation.graphql";
import { SubmitRateSearch_user$key } from "./__generated__/SubmitRateSearch_user.graphql";
// primitives
import Button from "../../../components/lib/Button";
import Stack from "../../../components/lib/Stack";
import Text from "../../../components/lib/Text";
import { useGlobalContext } from "../../../globalContext";
import { useRateSearchFormStore } from "../../../stores/RateSearchForm";
import { recentSearchesObserver } from "../observers";

const Content = styled("div", {
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  "@container RsSideMenu (max-width: 500px)": {
    flexDirection: "column",
  },
});

const SubmitRateSearch_fragment = graphql`
  fragment SubmitRateSearch_user on User {
    client {
      legacyClient {
        perSearchPricing
      }
    }
    resourceAllocations(resourceType: SEARCH) {
      id
      balance
      isUnlimited
    }
  }
`;

const SubmitRateSearch_mutaion = graphql`
  mutation SubmitRateSearchMutation($input: FindRateInput!) {
    ratesearches(input: $input) {
      searches {
        ratesearches {
          jobTitle
          jobLabel
          results {
            searchId
          }
        }
      }
    }
  }
`;

type Props = {
  user: SubmitRateSearch_user$key;
  isSideMenu: boolean;
};

export function SubmitRateSearch({ user, isSideMenu }: Props) {
  const { router } = useGlobalContext();

  const [error, setError] = useState<Error | null>(null);

  const [commitMutation, isInFlight] = useMutation<SubmitRateSearchMutation>(
    SubmitRateSearch_mutaion
  );

  const data = useFragment(SubmitRateSearch_fragment, user);

  const perSearchPricing = data?.client?.legacyClient?.perSearchPricing || false;
  const searchesRemaining = data?.resourceAllocations?.[0]?.balance || 0;
  const isUnlimitedSearch = data?.resourceAllocations?.[0]?.isUnlimited || false;
  const getMutationInput = useRateSearchFormStore((s) => s.getMutationInput);
  const canSubmitSearchRate = useRateSearchFormStore((s) => s.canSubmitSearchRate());

  // REVERTED
  // const locations = useRateSearchFormStore((s) => s.locations);
  // const jobCountries = useRateSearchFormStore((s) => s.getSupportedCountries());
  // const uncertifiedLocations = locations.filter(
  //   ({ countryId }) => !(countryId && jobCountries?.certified.includes(countryId))
  // );

  const noSearchAvailable =
    perSearchPricing && !isUnlimitedSearch && searchesRemaining === 0;

  function onSubmit() {
    if (canSubmitSearchRate) {
      commitMutation({
        variables: { input: getMutationInput() },
        onCompleted(response) {
          const rateSearches = response?.ratesearches?.searches?.ratesearches || [];
          if (rateSearches.length > 0) {
            const searchIds = rateSearches
              .map((reateSearch) => reateSearch?.results?.[0]?.searchId)
              .filter(Boolean) as number[];

            recentSearchesObserver.emit();
            router.push({
              pathname: "/rate-search-result",
              query: { searchIds: searchIds },
            });
          }
        },
        onError(e) {
          setError(e);
          console.error("Error SubmitRateSearchMutation", e);
        },
      });
    }
  }

  let ErrorMessage = <div />;

  if (error) {
    ErrorMessage = (
      <Stack nogap css={{ alignItems: "flex-start", paddingBottom: "$2" }}>
        <Text css={{ fontSize: "$base", fontWeight: "$semibold" }} color="negative">
          {error.name}
        </Text>
        <Text css={{ fontSize: "$base" }}>{error.message}</Text>
      </Stack>
    );
  } else if (noSearchAvailable) {
    ErrorMessage = (
      <Stack nogap css={{ alignItems: "flex-start", paddingBottom: "$2" }}>
        <Text color="negative">
          Search submission is unavailable as there are no remaining searches.
        </Text>
      </Stack>
    );
  }

  return (
    <Content>
      {ErrorMessage}
      <Button
        onClick={onSubmit}
        variant="filled"
        color="brand"
        size={isSideMenu ? "small" : "normal"}
        loading={isInFlight}
        disabled={
          isInFlight || !canSubmitSearchRate || noSearchAvailable
          // (uncertifiedLocations.length > 0 && isClientJobLibrary)
        }
        loadingText="Loading Results"
        text="Get Results"
      />
    </Content>
  );
}
