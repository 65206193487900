import type {
  SearchAllocatorClientDataObject,
  SearchAllocatorUserDataObject,
  SearchAllocatorResourceClientResponse,
} from "./types";

// users converts

export const transformSearchAllocatorUserObject = (
  item: SearchAllocatorUserDataObject
): SearchAllocatorUserDataObject => {
  let resourceAllocationId: string | null = null;
  let searchesAllocated = 0;
  let searchesRemaining = 0;
  let searchesUsed = 0;

  if (item.resourceAllocations.length > 0) {
    resourceAllocationId = item.resourceAllocations[0].id;
    searchesAllocated = item.resourceAllocations[0].allocated;
    searchesRemaining = item.resourceAllocations[0].balance;
    searchesUsed = item.resourceAllocations[0].searchesUsed;
  }

  item["resourceAllocationId"] = resourceAllocationId;
  item["searchesAllocated"] = searchesAllocated;
  item["searchesRemaining"] = searchesRemaining;
  item["searchesUsed"] = searchesUsed;

  return item;
};

// search allocator converters

export const searchAllocatorToMap = (
  item: SearchAllocatorResourceClientResponse
): SearchAllocatorClientDataObject => {
  const resourceAllocationId = item.resourceAllocations[0].id;
  const resourceAllocation = item.resourceAllocations[0].balance;
  const resourcePrice = item.resourcePrices[0].price;
  const resourceUsed = item.resourcesUsed[0].used;
  const tier = item.tiers[0].amount;
  const users = item["users"].map(transformSearchAllocatorUserObject);

  return {
    resourceAllocationId,
    resourceAllocation,
    resourcePrice,
    resourceUsed,
    tier,
    users,
  };
};
