import React from "react";

import Stack from "../../../../components/lib/Stack";
import {
  Card,
  CardActions,
  CardActionsLeft,
  CardBody,
  CardHeaderTitle,
} from "../../../../components/lib/Card";
import Text from "../../../../components/lib/Text";
import Icon from "../../../../components/lib/Icon";
import SurveysList from "./SurveysList";

export const SurveysListPage = () => (
  <Stack fill>
    <Card fill>
      <CardActions>
        <CardActionsLeft>
          <CardHeaderTitle as="h3">Surveys To Validate</CardHeaderTitle>
        </CardActionsLeft>
      </CardActions>
      <CardBody>
        <Stack fill css={{ gap: "$4", alignItems: "start" }}>
          <Stack fill css={{ gap: "$1", alignItems: "start" }}>
            <Text thin>
              <Icon icon="info-circle" /> Please sort, search, or filter by any number of
              columns to locate roles to validate. We have provided Title and Industry to
              better help guide you. Simply click on a row to begin.
            </Text>
            <Text thin>
              <Icon icon="info-circle" /> As you complete surveys, they will automatically
              be removed from the list.
            </Text>
            <Text thin>
              <Icon icon="info-circle" /> We monitor and review all validation activity.
              Please carefully consider the wage data that you are validating.
            </Text>
          </Stack>
          <SurveysList />
        </Stack>
      </CardBody>
    </Card>
  </Stack>
);

SurveysListPage.displayName = "SurveysListPage";

export default SurveysListPage;
