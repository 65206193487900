import React from "react";

import { emptyMap, emptyOrderedMap } from "../../../../constants";
import { useReviewDataContext } from "../../../validator5K_admin/context/ReviewDataContext";
import { useAttemptDataContext } from "../AttemptDataContext";

import {
  RatesFeedbackOrderedMap,
  RateResultDataObject,
  RATE_FEEDBACK_STATUS_TYPES,
  RatesFeedbackDataObject,
  RatesFeedbackDataMap,
  AttemptDataMap,
  JobsDataOrderedMap,
} from "../../types";
import { checkMissingOrderedRatesFeedback } from "../../utils";

type OrderedRatesFeedbackStateObject = {
  feedbackData: RatesFeedbackOrderedMap;
};

export type OrderedRatesFeedbackContextObject = OrderedRatesFeedbackStateObject & {
  jobsData: JobsDataOrderedMap;
  //
  hasMissingRateFeedback: boolean;
  isAllJobsSkipped: boolean;
  //
  updateFeedbackState: (
    rateResultId: RateResultDataObject["id"],
    data: Partial<RatesFeedbackDataObject>
  ) => void;
  updateAttemptFeedbackState: (
    rateResultId: RateResultDataObject["id"],
    data: RatesFeedbackDataMap
  ) => void;
};

export const OrderedRatesFeedbackContext =
  React.createContext<OrderedRatesFeedbackContextObject>({
    jobsData: emptyOrderedMap,
    feedbackData: emptyOrderedMap,
    //
    hasMissingRateFeedback: false,
    isAllJobsSkipped: false,
    //
    updateFeedbackState: undefined,
    updateAttemptFeedbackState: undefined,
  } as unknown as OrderedRatesFeedbackContextObject);

export const useOrderedRatesFeedbackContext = () =>
  React.useContext(OrderedRatesFeedbackContext);

export const useCreateOrderedRatesFeedbackContext =
  (): OrderedRatesFeedbackContextObject => {
    const { jobsData } = useReviewDataContext();
    const {
      attemptData: initialAttemptData,
      ratesFeedback: initialFeedbackData,
      updateAttemptDataState,
    } = useAttemptDataContext();

    // state

    const [feedbackState, setFeedbackState] =
      React.useState<RatesFeedbackOrderedMap>(initialFeedbackData);

    // derivatives

    const hasMissingRateFeedback = React.useMemo(
      () => checkMissingOrderedRatesFeedback(jobsData, feedbackState),
      [feedbackState, jobsData]
    );

    const isAllJobsSkipped = React.useMemo(() => {
      const allFeedbacksIdsSet = feedbackState.keySeq().toSetSeq();
      const skippedFeedbacksIdsSet = feedbackState
        .filter((item) => item.get("status") === RATE_FEEDBACK_STATUS_TYPES.DONT_KNOW)
        .keySeq()
        .toSetSeq();
      return skippedFeedbacksIdsSet.equals(allFeedbacksIdsSet);
    }, [feedbackState]);

    // handlers

    const updateFeedbackState = React.useCallback(
      (
        rateResultId: RateResultDataObject["id"],
        data: Partial<RatesFeedbackDataObject>
      ) => {
        // merge new parameters into the feedback state
        setFeedbackState((prevState) =>
          prevState.set(
            rateResultId,
            prevState
              .get(rateResultId, emptyMap as RatesFeedbackDataMap)
              .merge(data) as RatesFeedbackDataMap
          )
        );
      },
      []
    );

    const updateAttemptFeedbackState = React.useCallback(
      (rateResultId: RateResultDataObject["id"], feedbackData: RatesFeedbackDataMap) => {
        if (initialFeedbackData.get(rateResultId) !== feedbackData) {
          updateAttemptDataState(
            initialAttemptData.set(
              "rates_feedback",
              initialFeedbackData.set(
                rateResultId,
                feedbackData
              ) as RatesFeedbackOrderedMap
            ) as AttemptDataMap
          );
        }
      },
      [initialAttemptData, initialFeedbackData, updateAttemptDataState]
    );

    return {
      jobsData,
      feedbackData: feedbackState,
      hasMissingRateFeedback: hasMissingRateFeedback,
      isAllJobsSkipped: isAllJobsSkipped,
      //
      updateFeedbackState,
      updateAttemptFeedbackState,
    };
  };

export default OrderedRatesFeedbackContext;
