import React, { Fragment } from "react";
// lig
import Divider from "../../../../components/lib/Divider";
import Inline from "../../../../components/lib/Inline";
import Stack from "../../../../components/lib/Stack";
import Text from "../../../../components/lib/Text";
import { styled } from "../../../../stitches.config";
// store
import {
  useSearchResultsStore,
  MarketRateType,
  FilterRateFrequency,
  MarketRateValue,
  MarketRateValues,
} from "../../../../stores/SearchResults";
// relay
import graphql from "babel-plugin-relay/macro";
import {
  DetailCards_savedsearch$key,
  DetailCards_savedsearch$data,
} from "./__generated__/DetailCards_savedsearch.graphql";
import { useFragment } from "react-relay";

const Grid = styled("section", {
  display: "grid",
  width: "100%",
  gridTemplateColumns: "repeat(3, 1fr)",
  gridTemplateRows: "min-content",
  gridGap: "$6",
});

const RateCard = styled("article", {
  backgroundColor: "$primaryLightest",
  padding: "$4",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  border: "solid 1px $primaryLight",
  borderRadius: "$rounded",
  borderLeft: "solid 7px $primaryLight",
  variants: {
    variant: {
      I: {
        borderLeftColor: "#1276b9",
      },
      II: {
        borderLeftColor: "#a83877",
      },
      III: {
        borderLeftColor: "#339862",
      },
      IV: {
        borderLeftColor: "#743490",
      },
      V: {
        borderLeftColor: "#4b1858",
      },
    },
  },
  defaultVariants: {
    variant: "I",
  },
});

const romans = ["I", "II", "III", "IV", "V"] as const;

function getRateSuffix(
  isCotract: boolean,
  isNotMarkup: boolean,
  rateFrequency: FilterRateFrequency
) {
  const cases: Record<FilterRateFrequency, string> = {
    Hourly: "per hour",
    Daily: "per day",
    Weekly: "per week",
    Monthly: "per month",
  };
  return isCotract && isNotMarkup ? cases[rateFrequency] : null;
}

type MarketRateKeys = keyof Omit<
  NonNullable<
    NonNullable<NonNullable<DetailCards_savedsearch$data>["marketrates"]>[number]
  >,
  "id"
>;

const getSelectedRateByValue = (rate: MarketRateType) => (value: MarketRateValue) => {
  const cases: Record<MarketRateType, Record<MarketRateValue, MarketRateKeys>> = {
    PayRate: {
      Min: "payRateMin",
      Median: "payRateMid",
      Max: "payRateMax",
      Average: "payRateAvg",
    },
    Markup: {
      Min: "markupPctMin",
      Median: "markupPctMid",
      Max: "markupPctMax",
      Average: "markupPctAvg",
    },
    BillRate: {
      Min: "billRateMin",
      Median: "billRateMid",
      Max: "billRateMax",
      Average: "billRateAvg",
    },
  };
  return cases[rate][value];
};

const titleRightMap: Record<MarketRateType, string> = {
  PayRate: "Pay Rate",
  Markup: "Markup",
  BillRate: "Bill Rate",
};

const rateSubHeaderMap: Record<MarketRateType, string> = {
  PayRate: "Max Pay Rate",
  Markup: "Max Markup",
  BillRate: "Max Bill Rate",
};

const fargment = graphql`
  fragment DetailCards_savedsearch on SaveSearchNode {
    id
    rateType @required(action: NONE)
    currency {
      id
      iso @required(action: NONE)
    }
    marketrates {
      id
      # pay rate
      payRateMin
      payRateMid
      payRateMax
      payRateAvg
      # bill rate
      billRateMin
      billRateMid
      billRateMax
      billRateAvg
      # markup %
      markupPctMin
      markupPctMid
      markupPctMax
      markupPctAvg
    }
  }
`;

type RsRateDetailCardsProps = {
  selectedMarketRateType: MarketRateType;
  savedsearch: DetailCards_savedsearch$key;
};

export function DetailCards(props: RsRateDetailCardsProps) {
  const { savedsearch, selectedMarketRateType } = props;
  const data = useFragment(fargment, savedsearch);
  const isContract = data?.rateType === 1;
  const isNotMarkup = selectedMarketRateType !== "Markup";

  const fromCurrency = data?.currency?.iso || "USD";
  const toCurrency = useSearchResultsStore((s) => s.currency);
  const rateFrequency = useSearchResultsStore((s) => s.rateFrequency);
  const formatRate = useSearchResultsStore((s) => {
    const freq = isContract ? rateFrequency : "Hourly";
    return s.calculateRate(fromCurrency, toCurrency, freq, !isContract);
  });

  const titleRight = titleRightMap[selectedMarketRateType];
  const rateSubHeader = rateSubHeaderMap[selectedMarketRateType];
  const suffix = getRateSuffix(isContract, isNotMarkup, rateFrequency);

  const isMarkup = selectedMarketRateType === "Markup";
  const selectRate = getSelectedRateByValue(selectedMarketRateType);

  if (!data) return null;

  return (
    <Grid>
      {data.marketrates?.map((marketRate, index) => {
        if (!marketRate) return null;
        const maxRate = isMarkup
          ? `${marketRate[selectRate("Max")]}%`
          : formatRate(marketRate[selectRate("Max")]);
        return (
          <RateCard key={marketRate?.id} variant={romans[index]}>
            <Stack css={{ gap: "$2" }}>
              <Inline fill css={{ flexWrap: "nowrap" }}>
                <Text as="b" css={{ flex: 2, fontSize: "$base" }}>
                  {"Level " + romans[index]}
                </Text>
                <Text style={{ flex: 3 }}>{titleRight}</Text>
              </Inline>
              <Divider />
              <Inline fill css={{ flexWrap: "nowrap" }}>
                <Stack style={{ flex: 2 }} css={{ gap: "$2" }}>
                  <Stack css={{ textAlign: "center", gap: "0" }}>
                    <Text as="b" css={{ fontSize: "$base" }}>
                      {maxRate}
                    </Text>
                    <Text as="b" css={{ fontSize: "$base" }}>
                      {rateSubHeader}
                    </Text>
                  </Stack>
                  <Text>{suffix}</Text>
                </Stack>
                <Stack style={{ flex: 3 }} css={{ gap: "$2" }}>
                  {MarketRateValues.map((title, idx) => {
                    const rate = isMarkup
                      ? `${marketRate[selectRate(title)]}%`
                      : formatRate(marketRate[selectRate(title)]);
                    return (
                      <Fragment key={`${title}-${rate}`}>
                        <Inline
                          fill
                          css={{ justifyContent: "space-between", flexWrap: "nowrap" }}
                        >
                          <Text>{title}:</Text>
                          <Text>{rate}</Text>
                        </Inline>
                        {idx < MarketRateValues.length - 1 && <Divider />}
                      </Fragment>
                    );
                  })}
                </Stack>
              </Inline>
            </Stack>
          </RateCard>
        );
      })}
    </Grid>
  );
}
