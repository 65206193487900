// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SortIndicator from "../../ratecards/components/SortIndicator";
import { FILTER_COLUMN } from "../../../models/Filter";
import FilterModal from "../../ratecards/components/FilterModal";
import SortControls from "../../ratecards/components/SortControls";
import Pagination from "../../../components/lib/Pagination";
import LoadingIndicator from "../../shared/LoadingIndicator";
import { SingleDatePicker } from "../../../components/SingleDatePicker";
import { ProjectCostEstimateComponentStore } from "../../../stores/mobx/ProjectCostEstimateStore";
import InstantSearchBox from "../../ratecards/components/InstantSearchBox";
import ProjectCostEstimateListItem from "./ProjectCostEstimatesListItem";
import SelectableItem from "../../ratecards/components/SelectableItem";
import ContainerSection from "../../ratecards/components/ContainerSection";
import SelectableItemsList from "../../ratecards/components/SelectableItemsList";
import TagsFilter from "../../filters/TagsFilter";
import { InlineElements } from "../../../components/lib/Inline";
import Button from "../../../components/lib/Button";
import { CardFilters, CardFilter } from "../../../components/lib/Card";
import Inline from "../../../components/lib/Inline";

/**
 * CreatedByCriteriaList - user list for created by filter
 * @return {XML}
 */

const CreatedByCriteriaList = observer((props) => {
  return (
    <div>
      <SelectableItemsList listState={props.filterState}>
        {props.filterState.viewItems.map((owner, i) => (
          <SelectableItem
            key={owner.username}
            item={owner}
            name="owner-filter"
            value={owner.username}
            selected={owner.selected}
            onChange={props.filterState.setSelectedValue}
          >
            <div>
              {owner.firstName} {owner.lastName}{" "}
            </div>
            <div className="text-x-small">
              <em>
                <FontAwesomeIcon icon="user" className="icon no-margin" />{" "}
                {owner.username}
              </em>
            </div>
          </SelectableItem>
        ))}
      </SelectableItemsList>
      <ContainerSection className="info">
        <p className="text-muted text-x-small no-margin pull-right">
          <em>
            <strong>{props.filterState.selectedValuesCount} </strong>
            selected, <strong>{props.filterState.totalValuesCount} </strong>
            total
          </em>
        </p>
        <div className="clearfix" />
      </ContainerSection>
    </div>
  );
});

/**
 * ProjectCostEstimateList component
 */
class ProjectCostEstimateList extends Component {
  /**
   * Render Project Lists
   * @return {XML}
   */
  constructor(props) {
    super(props);

    this.changeCurrent = this.changeCurrent.bind(this);
  }

  // Change the current value of the pagination
  changeCurrent(value) {
    let store: ProjectCostEstimateComponentStore = this.props.store;
    store.pagination.handleCurrentPage(value);
  }

  render() {
    const store: ProjectCostEstimateComponentStore = this.props.store;

    const network = store.network;
    const fillContainer = this.props.fillContainer;
    const fixedHeight = this.props.fixedHeight;
    const maximumDate = new Date();

    let listBodyStyles = {};
    if (fixedHeight) {
      listBodyStyles.height = fixedHeight;
      listBodyStyles.overflowY = "auto";
    }

    let selectAllSection = null;
    let selectAllOnPageSection = (
      <Inline>
        <Button onClick={store.selectAllOnPageItem}>Select All</Button>
        <Button onClick={store.deselectAllPage}>Deselect All</Button>
      </Inline>
    );

    if (store.allOnPageSelected) {
      selectAllSection = (
        <div className="ratecards-list-select-all-section">
          <span>
            All &nbsp;
            <strong>{store.projectCounts}</strong>
            &nbsp; Projects selected.{" "}
          </span>
          &nbsp;
          {!store.isTagView && (
            <span
              className="pt-link ratecards-all-items"
              key="clear-selections-link"
              onClick={store.clearAllSelections}
            >
              Clear all selections
            </span>
          )}
          {store.isTagView && (
            <span
              className="pt-link ratecards-all-items"
              key="clear-selections-link"
              onClick={store.clearAllSelectionsOnTagView}
            >
              Clear all selections
            </span>
          )}
        </div>
      );
    }

    if (store.allOnPageSelected && !store.allSelected) {
      selectAllSection = (
        <div className="ratecards-list-select-all-section">
          <span>All Projects on this page selected. &nbsp;</span>
          &nbsp;
          <span
            className="pt-link ratecards-all-items"
            key="select-all-item"
            onClick={store.selectAllPage}
          >
            Select all &nbsp;
            <strong>{store.projectCounts}</strong>
            &nbsp; Projects.
          </span>
          &nbsp;&nbsp;
          {!store.isTagView && (
            <span
              className="pt-link ratecards-all-items"
              key="clear-selections-link"
              onClick={store.clearAllSelections}
            >
              Clear all selections
            </span>
          )}
          {store.isTagView && (
            <span
              className="pt-link ratecards-all-items"
              key="clear-selections-link"
              onClick={store.clearAllSelectionsOnTagView}
            >
              Clear all selections
            </span>
          )}
        </div>
      );
    }

    let tableLeftActionBar = null;
    if (store.isEditing) {
      tableLeftActionBar = (
        <nav className="header-left-nav">
          {store.allowMultipleItemSelection && selectAllOnPageSection}
        </nav>
      );
    }

    const pceList = store.projectCostsView;
    let tableContent = null;
    if (network.loading) {
      tableContent = <LoadingIndicator />;
    } else if (network.error) {
      tableContent = (
        <div className="pt-error-message">
          <h2>Error</h2>
          <pre>
            <code>{JSON.stringify(network.error, null, 2)}</code>
          </pre>
        </div>
      );
    } else if (pceList.length === 0) {
      tableContent = (
        <div className="ratecards-table-empty">
          {Object.keys(store.appliedFilters).length > 0 ? (
            <div>No Project match your current filters</div>
          ) : (
            <div>
              No Project
              <p>
                Create a new Project Worksheet using the button above or by searching.
              </p>
            </div>
          )}
        </div>
      );
    } else {
      tableContent = pceList.map((pce) => (
        <ProjectCostEstimateListItem
          key={pce.projectCostId}
          store={store}
          pce={pce}
          editing={store.isEditing}
        />
      ));
    }

    return (
      <div>
        {store.tagsFilter && (
          <TagsFilter
            tagsFilter={store.tagsFilter}
            network={store.tagsFilter.network.loading}
            instantSearchValue={store.tagsFilter.instantSearchValue}
            onInstantSearch={store.tagsFilter.onInstantSearch}
          />
        )}
        {store.createdByFilter && (
          <FilterModal
            filterState={store.createdByFilter}
            innerStyle={{
              width: 550,
            }}
          >
            {store.createdByFilter.network.loading && <LoadingIndicator />}
            {!store.createdByFilter.network.loading && (
              <div>
                <ContainerSection className="header overlap-t-padding">
                  <h4 className="pull-left">Created By</h4>
                  <div className="pull-right">
                    <SortControls filterState={store.createdByFilter} />
                  </div>
                  <div className="clearfix" />
                </ContainerSection>
                <ContainerSection className="no-border">
                  <p>Filter by specific Users:</p>
                  <div>
                    <div className="pull-left">
                      <InstantSearchBox
                        onSearch={store.createdByFilter.onInstantSearch}
                        value={store.createdByFilter.instantSearchValue}
                      />
                    </div>
                    <div
                      className="pull-right rc-center"
                      style={{
                        height: 40,
                      }}
                    >
                      <button className="btn" onClick={store.createdByFilter.onSelectAll}>
                        Select All
                      </button>
                      <button
                        className="btn"
                        onClick={store.createdByFilter.onDeselectAll}
                      >
                        Deselect All
                      </button>
                    </div>
                    <div className="clearfix" />
                  </div>
                </ContainerSection>
                <CreatedByCriteriaList
                  filterState={store.createdByFilter}
                  store={store}
                />
              </div>
            )}
          </FilterModal>
        )}
        {store.createdOnFilter && (
          <FilterModal filterState={store.createdOnFilter}>
            <div>
              <ContainerSection className="header overlap-t-padding">
                <h4 className="pull-left">Created On</h4>
                <div className="pull-right">
                  <SortControls
                    filterState={store.createdOnFilter}
                    sortType="numeric"
                    ascText="Sort Oldest First"
                    descText="Sort Newest First"
                  />
                </div>
                <div className="clearfix" />
              </ContainerSection>
              <ContainerSection>
                <p>Filter by a specific period:</p>
                <div className="pt-range-filter">
                  <div className="pt-range-filter__item">
                    <p>From:</p>
                    <SingleDatePicker
                      id="from_date"
                      date={store.createdOnFilter.fromDate}
                      numberOfMonths={1}
                      isOutsideRange={(day) => day.isAfter(maximumDate)}
                      focused={store.createdOnFilter.fromFocused}
                      onDateChange={store.createdOnFilter.fromDateChange}
                      onFocusChange={store.createdOnFilter.fromFocusedChange}
                    />
                  </div>
                  <div className="pt-range-filter__item">
                    <p>To:</p>
                    <SingleDatePicker
                      id="to_date"
                      date={store.createdOnFilter.toDate}
                      numberOfMonths={1}
                      isOutsideRange={(day) => store.toDateRange(day)}
                      focused={store.createdOnFilter.toFocused}
                      onDateChange={store.createdOnFilter.toDateChange}
                      onFocusChange={store.createdOnFilter.toFocusedChange}
                    />
                  </div>
                </div>
              </ContainerSection>
            </div>
          </FilterModal>
        )}

        <CardFilters filtered={store.isFiltered}>
          {tableLeftActionBar}
          <InlineElements>
            {store.isFiltered && (
              <Button color="accent" onClick={store.clearFilters}>
                Clear All Filters & Sorts
              </Button>
            )}
            {store.tagsFilter && (
              <CardFilter
                filtered={Boolean(store.appliedFilters[FILTER_COLUMN.TAGS])}
                name="search-list-filter"
                value={FILTER_COLUMN.TAGS}
                selected={Boolean(store.appliedFilters[FILTER_COLUMN.TAGS])}
                onClick={store.tagsFilter.onShowModal}
              >
                <SortIndicator
                  sortColumn={FILTER_COLUMN.TAGS}
                  sortsOrder={store.appliedSortsOrder}
                  sort={store.appliedSorts[FILTER_COLUMN.TAGS]}
                />
                Tags <span> ▼</span>
              </CardFilter>
            )}
            {/*store.createdByFilter && <ToggleButton borderless={true} name="pce-filter" value={FILTER_COLUMN.CREATED_BY} selected={Boolean(store.appliedFilters[FILTER_COLUMN.CREATED_BY])} onClick={store.createdByFilter.onShowModal}>
              <SortIndicator sortColumn={FILTER_COLUMN.CREATED_BY} sortsOrder={store.appliedSortsOrder} sort={store.appliedSorts[FILTER_COLUMN.CREATED_BY]}/>
              Created by
              <span> ▼</span>
            </ToggleButton>*/}
            {store.createdOnFilter && (
              <CardFilter
                name="pce-filter"
                value={FILTER_COLUMN.PC_DATE_RANGE}
                selected={Boolean(store.appliedFilters[FILTER_COLUMN.PC_DATE_RANGE])}
                onClick={store.createdOnFilter.onShowModal}
                filtered={Boolean(store.appliedFilters[FILTER_COLUMN.PC_DATE_RANGE])}
              >
                <SortIndicator
                  sortType="numeric"
                  sortColumn={FILTER_COLUMN.PC_DATE_RANGE}
                  sortsOrder={store.appliedSortsOrder}
                  sort={store.appliedSorts[FILTER_COLUMN.PC_DATE_RANGE]}
                />
                Created On
                <span> ▼</span>
              </CardFilter>
            )}
          </InlineElements>
        </CardFilters>

        <div
          className={classNames("ratecards-table", {
            "ratecards-table-fill-container": fillContainer,
          })}
        >
          <div style={listBodyStyles}>
            {selectAllSection}
            {tableContent}
          </div>
        </div>
        {store.pagination.pageCount > 0 ? (
          <Pagination
            options={{
              variant: "full",
              currentPage: store.pagination.currentPage,
              numPages: store.pagination.pageCount,
            }}
            onPageClick={(pageEvent) => {
              if (pageEvent.type === "first") {
                this.changeCurrent(1);
              }
              if (pageEvent.type === "last") {
                this.changeCurrent(store.pagination.pageCount);
              }
              if (pageEvent.type === "next") {
                this.changeCurrent(
                  Math.min(store.pagination.currentPage + 1, store.pagination.pageCount)
                );
              }
              if (pageEvent.type === "prev") {
                this.changeCurrent(Math.max(store.pagination.currentPage - 1, 1));
              }
              if (pageEvent.type === "page") {
                this.changeCurrent(pageEvent.page);
              }
            }}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

ProjectCostEstimateList.propTypes = {
  store: PropTypes.object.isRequired,
  fixedHeight: PropTypes.number,
  fillContainer: PropTypes.bool,
};

export default observer(ProjectCostEstimateList);
