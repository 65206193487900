import React from "react";

import {
  Card,
  CardActions,
  CardActionsLeft,
  CardHeaderTitle,
  CardBody,
} from "../../../../components/lib/Card";
import Stack from "../../../../components/lib/Stack";
import Text from "../../../../components/lib/Text";
import Icon from "../../../../components/lib/Icon";
import SurveysHistoryList from "./SurveysHistoryList";
import TotalReward from "./TotalReward";
import { CommonChildPageProps } from "../../Val5KPublicEntry";

export const SurveysHistoryPage = (props: CommonChildPageProps) => (
  <Card fill>
    <CardActions>
      <CardActionsLeft>
        <CardHeaderTitle as="h3">Validations History</CardHeaderTitle>
      </CardActionsLeft>
    </CardActions>
    <CardBody>
      <Stack fill css={{ gap: "$4", alignItems: "start" }}>
        <Text thin>
          <Icon icon="info-circle" /> All timestamps are displayed in UTC timezone.
        </Text>
        <Stack fill css={{ gap: "$2", alignItems: "start" }}>
          <SurveysHistoryList />
          <TotalReward />
        </Stack>
      </Stack>
    </CardBody>
  </Card>
);

SurveysHistoryPage.displayName = "SurveysHistoryPage";

export default SurveysHistoryPage;
