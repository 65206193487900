// @flow
import { extendObservable, action } from "mobx";
import NetworkState from "../../../models/NetworkState";
import type { FetchAPI } from "../../../App";

class ReportStore {
  network: NetworkState;
  fetchAPI: FetchAPI;

  constructor(fetchAPI: FetchAPI) {
    this.fetchAPI = fetchAPI;

    extendObservable(this, {
      network: new NetworkState(),
    });

    this.exportReports = action(this.exportReports.bind(this));
  }

  exportReports() {
    if (this.network.loading) {
      return;
    }

    const exportURL = "ratecards/client/search_history/export/csv/";
    this.fetchAPI(exportURL)
      .then((res) => {
        window.location.href = res.data.url;
      })
      .catch((e) => {
        console.log("Error downloading excel", e);
        throw e;
      });
  }
}
export default ReportStore;
