import React from "react";
import { observer } from "mobx-react";
import SelectableItemsList from "../ratecards/components/SelectableItemsList";
import SelectableItem from "../ratecards/components/SelectableItem";
import ContainerSection from "../ratecards/components/ContainerSection";
import FilterModal, { ModalTitle } from "../ratecards/components/FilterModal";
import LoadingIndicator from "../shared/LoadingIndicator";
import SortControls from "../ratecards/components/SortControls";
import InstantSearchBox from "../ratecards/components/InstantSearchBox";
import Button from "../../components/lib/Button";
import Inline from "../../components/lib/Inline";
import Spacer from "../../components/lib/Spacer";

const JobLabelCriteriaList = observer((props) => (
  <div>
    <SelectableItemsList className="overlap-tb-padding">
      {props.filterState.viewItems.map((item, i) => (
        <SelectableItem
          key={item.id}
          item={item}
          name="job-label-filter"
          value={item.jobLabel}
          selected={item.selected}
          onChange={props.filterState.setSelectedValue}
        >
          <div title={item.jobLabel}>{item.jobLabel}</div>
        </SelectableItem>
      ))}
    </SelectableItemsList>
    <ContainerSection className="info">
      <p className="text-muted text-x-small no-margin pull-right">
        <em>
          <strong>{props.filterState.selectedValuesCount} </strong>
          selected, <strong>{props.filterState.totalValuesCount} </strong>
          total
        </em>
      </p>
      <div className="clearfix" />
    </ContainerSection>
  </div>
));

const JobLabelFilter = ({ jobLabel, network, onInstantSearch, instantSearchValue }) => {
  return (
    <FilterModal filterState={jobLabel}>
      {network && <LoadingIndicator />}
      {!network && (
        <div>
          <ContainerSection className="header overlap-t-padding">
            <ModalTitle className="pull-left">Job Label</ModalTitle>
            <div className="pull-right">
              <SortControls filterState={jobLabel} />
            </div>
            <div className="clearfix" />
          </ContainerSection>
          <ContainerSection className="no-border">
            <p>Filter by specific Job Labels:</p>
            <Inline css={{ gap: "$2" }}>
              <InstantSearchBox onSearch={onInstantSearch} value={instantSearchValue} />
              <Spacer />
              <Button onClick={jobLabel.onSelectAll}>Select All</Button>
              <Button onClick={jobLabel.onDeselectAll}>Deselect All</Button>
            </Inline>
          </ContainerSection>
          <JobLabelCriteriaList filterState={jobLabel} />
        </div>
      )}
    </FilterModal>
  );
};

export default JobLabelFilter;
