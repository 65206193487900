export const RATE_TYPES = {
  HOURLY: "hourly",
  ANNUAL: "annual",
};

export type CONTRACT_RATE_TYPE = 1;
export type FTE_RATE_TYPE = 2;

export type RATE_TYPES_2_TYPE = CONTRACT_RATE_TYPE | FTE_RATE_TYPE;

// HINT: casting to literal types here to make Rate Type values and their usage more strict
export const CONTRACT = { label: "Contract", value: 1 } as const;
export const FTE = { label: "FTE", value: 2 } as const;

export const RATE_TYPES_2 = {
  CONTRACT: CONTRACT.value,
  FTE: FTE.value,
} as const;

export const RATE_TYPE_OPTIONS_2 = {
  CONTRACT,
  FTE,
} as const;

export const RATE_TYPE_OPTIONS_2_BY_ID = {
  [CONTRACT.value]: CONTRACT,
  [FTE.value]: FTE,
} as const;
