import React, { useCallback, useState, useEffect } from "react";
import { Set } from "immutable";

import Stack from "../../../../components/lib/Stack";
import Box from "../../../../components/lib/Box";
import Text from "../../../../components/lib/Text";
import Icon from "../../../../components/lib/Icon";
import TextArea from "../../../../components/lib/TextArea";
import Tooltip from "../../../private_index/lib/Tooltip";
import Button from "../../../../components/lib/Button";
import WrapperBlock from "./WrapperBlock";
import ActionsBlock from "./ActionsBlock";
import LaborValuesChooseBlock from "./LaborValuesChooseBlock";
import OtherSurveyBlock from "./OtherSurveyBlock";
import useQuestions from "./useQuestions";
import InputsPair from "../../components/InputsPair";
import InputsList from "../../components/InputsList";
import { QuestionsEditor } from "../../components/QuestionsTable";
// @ts-expect-error
import SingleSelect from "../../../../components/selects/SingleSelect";
import TextCrop from "../../../validator5K_admin/components/TextCrop";
import {
  classicSkillsLevelsInfoText,
  threeLevelBandingSkillsLevelsInfoText,
} from "../../components/Rates";
// @ts-expect-error
import { logAsyncOperationError } from "../../../../utils/logging";
import CurrencySelect, {
  CurrencyData,
} from "../../../../components/selects/CurrencySelect";
import InlineValuePicker, {
  InlineMultiValuePicker,
} from "../../../../components/pickers/InlineValuePicker";
import { CheckboxItem } from "../../../../components/lib/Checkbox";
import ClientSelect from "../../components/selects/ClientSelect";
import RateCardSingleSelect, {
  RateCardNodeType,
  ChangeFuncType,
} from "../../components/selects/RateCardSingleSelect";
import {
  REVIEW_TYPES,
  REVIEW_TYPES_LABELS,
  LEVELS_PICKER_OPTIONS,
  LEVELS_PICKER_OPTIONS_SHORT,
  RATES_PICKER_OPTIONS,
  CONTINGENT_RATE_TYPES,
  REVIEW_TYPES_OPTIONS,
  GENERAL_RATE_TYPES,
  VALUE_TYPES,
} from "../../types";
import { reactSelectStyles } from "../../constants";
import {
  Container,
  StepID,
  Content,
  Label,
} from "../../../private_index/components/SubmitContractorsWizardPage";
import { emptyList, emptySet } from "../../../../constants";
import { reviewsListToImmutableList } from "../../dataConverters";
import { ButtonGroupRight } from "../../../../components/lib/ButtonGroup";

import type { ValueType } from "react-select/lib/types";
import type {
  REVIEW_TYPES_TYPE,
  CONTINGENT_RATE_TYPES_TYPE,
  SKILLS_LEVELS_TYPE,
  RATE_TYPES_TYPE,
  ReviewDataObject,
  QuestionsOrderedMap,
} from "../../types";
import type { ImmutableSet, ImmutableList } from "../../../../types/immutable";
import type { PickerOptionObject } from "../../../../components/pickers/InlineValuePicker";
import type { FetchAPIResponse, FetchGraphQLAPIResponse } from "../../../../types/fetch";
import type {
  ClientData,
  ClientSelectProps,
} from "../../components/selects/ClientSelect";
import { useVal5KAdminContext } from "../../context/Val5KAdminContext";

const defaultAttemptsNumber = 5;
const defaultReviewTypeValue: { value: REVIEW_TYPES_TYPE; label: string } = {
  value: REVIEW_TYPES.CLASSIC,
  label: REVIEW_TYPES_LABELS[REVIEW_TYPES.CLASSIC],
};

const allPossibleLevelsValue = Set(
  (Object.values(LEVELS_PICKER_OPTIONS) as PickerOptionObject<number>[]).map(
    (option) => option.value
  )
) as unknown as ImmutableSet<number>;

const defaultSelectedLevelsValue = allPossibleLevelsValue;
const defaultThreeLevelBandingSelectedLevelsValues = Set(
  (Object.values(LEVELS_PICKER_OPTIONS_SHORT) as PickerOptionObject<number>[]).map(
    (option) => option.value
  )
) as unknown as ImmutableSet<number>;

const defaultSelectedRatesValue: ImmutableSet<string> = RATES_PICKER_OPTIONS.PAY_RATE_ONLY
  .value as unknown as ImmutableSet<string>;
const allPossibleRatesValue: ImmutableSet<string> = RATES_PICKER_OPTIONS.ALL
  .value as unknown as ImmutableSet<string>;
const defaultHourlyRateMultiplier = 1;
const defaultDailyRateMultiplier = 8;
const defaultWeeklyRateMultiplier = 40;
const defaultMonthlyRateMultiplier = 160;

// Rate Card keys

type PayRatesKeysType = "payRateMin" | "payRateMax" | "payRateAvg" | "payRateMid";
type BillRatesKeysType = "billRateMin" | "billRateMax" | "billRateAvg" | "billRateMid";
type MarkupKeysType = "markupPctMin" | "markupPctMax" | "markupPctAvg" | "markupPctMid";

const payRatesKeys: PayRatesKeysType[] = [
  "payRateMin",
  "payRateMax",
  "payRateAvg",
  "payRateMid",
];

const billRatesKeys: BillRatesKeysType[] = [
  "billRateMin",
  "billRateMax",
  "billRateAvg",
  "billRateMid",
];

const markupKeys: MarkupKeysType[] = [
  "markupPctMin",
  "markupPctMax",
  "markupPctAvg",
  "markupPctMid",
];

const requiredRatesKeys = payRatesKeys;

const replacementsTooltipContent = (
  <Text centered as={Box} css={{ width: 200 }}>
    <strong>Use words replacements in order to hide sensitive client's data.</strong>
    <br />
    <span>This mechanism only able to swap whole words.</span>
  </Text>
);

type RateCardMarketRatesGraphQLType = {
  level: { legacyId: number };
  billRateAvg: number;
  billRateMax: number;
  billRateMid: number;
  billRateMin: number;
  markupPctAvg: number;
  markupPctMax: number;
  markupPctMid: number;
  markupPctMin: number;
  payRateAvg: number;
  payRateMax: number;
  payRateMid: number;
  payRateMin: number;
};

type RateCardSearchesGraphQLType = {
  city: string;
  country: string;
  currency: {
    alpha: string;
    iso: string;
    legacyId: number;
    symbol: string;
  };
  industry: {
    legacyId: number;
    value: string;
  };
  job: {
    collectionId: number | null;
    jobCategory: string | null;
    jobDescription: string;
    jobTitle: string;
  };
  marketrates: RateCardMarketRatesGraphQLType[];
  rateType: RATE_TYPES_TYPE;
  searchId: number;
  region: string | null;
  state: string | null;
};

type RateCardSearchesGraphQLNodeType = {
  edges: { node: RateCardSearchesGraphQLType }[];
};

type RateCardDataGraphQLType = {
  rateCardDetail: RateCardNodeType;
  rateCardSearches: RateCardSearchesGraphQLNodeType;
};

type RateCardDataGraphQLResponse = {
  viewer: RateCardDataGraphQLType;
};

type DisplayCurrencyType = {
  display_currency_id: number;
  display_currency_code: string;
  display_currency_name: string;
  display_currency_symbol: string;
};

type LoadRateCardDataFullReturnType = Promise<
  | {
      data: RateCardDataGraphQLType;
      validationResult: boolean;
      searchesTotal: number;
      providedLevels?: ImmutableSet<number>;
      providedRates?: ImmutableSet<string>;
    }
  | undefined
>;

export type ReviewTypeOption = {
  value: REVIEW_TYPES_TYPE;
  label: string;
};

type ReplacementItem = ImmutableList<string>;
type ReplacementsList = ImmutableList<ReplacementItem>;

export interface ReviewFromRatecardFromState {
  clientData: ClientData | null;
  rateCardData: RateCardNodeType | null;
  rateCardDataFullPromise: LoadRateCardDataFullReturnType | null;
  rateCardSearchesTotal: number;
  rateCardProvidedLevels: ImmutableSet<number>;
  rateCardProvidedRates: ImmutableSet<string>;
  reviewType: ReviewTypeOption;
  reviewTitle: string | null;
  isThreeLevelBanding: boolean;
  selectedLevels: ImmutableSet<number>;
  selectedRates: ImmutableSet<string>;
  displayRateType: CONTINGENT_RATE_TYPES_TYPE;
  displayCurrency: CurrencyData | null;
  hourlyRateMultiplier: number;
  dailyRateMultiplier: number | null;
  weeklyRateMultiplier: number | null;
  monthlyRateMultiplier: number | null;
  replacements: ReplacementsList;
  questions: QuestionsOrderedMap;
  isFreeValidation: boolean;
  isActive: boolean;
  rewardPerJob: number | null;
  maxAttemptsNumber: number | null;
  instructions: string | null;
  created: boolean;
}

const ReviewFromRatecardForm = () => {
  const {
    currenciesData,
    fetchArgusAPI,
    fetchGraphQL,
    showModalError,
    showModalWarning,
    showModalSuccess,
    showConfirmationModal,
    closeConfirmationModal,
  } = useVal5KAdminContext();

  // state

  const [pageState, setPageState] = useState<ReviewFromRatecardFromState>({
    clientData: null,
    rateCardData: null,
    rateCardDataFullPromise: null,
    rateCardSearchesTotal: 0,
    rateCardProvidedLevels: emptySet,
    rateCardProvidedRates: emptySet,
    reviewType: defaultReviewTypeValue,
    reviewTitle: null,
    isThreeLevelBanding: false,
    selectedLevels: defaultSelectedLevelsValue,
    selectedRates: defaultSelectedRatesValue,
    displayRateType: CONTINGENT_RATE_TYPES.HOURLY,
    displayCurrency: null,
    hourlyRateMultiplier: defaultHourlyRateMultiplier,
    dailyRateMultiplier: defaultDailyRateMultiplier,
    weeklyRateMultiplier: defaultWeeklyRateMultiplier,
    monthlyRateMultiplier: defaultMonthlyRateMultiplier,
    replacements: emptyList,
    questions: emptyList,
    isFreeValidation: false,
    isActive: true,
    rewardPerJob: null,
    maxAttemptsNumber: defaultAttemptsNumber,
    instructions: null,
    created: false,
  });

  const questions = useQuestions();

  useEffect(() => {
    setPageState((prevState) => ({
      ...prevState,
      questions,
    }));
  }, [questions]);

  // handlers

  const handleRateCardReset = useCallback(() => {
    setPageState((prevState) => ({
      ...prevState,
      rateCardData: null,
      rateCardDataFullPromise: null,
      rateCardProvidedLevels: emptySet,
      rateCardProvidedRates: emptySet,
      rateCardSearchesTotal: 0,
    }));
    closeConfirmationModal();
    window.scrollTo(0, 0);
  }, [closeConfirmationModal]);

  const handleFailedModal = useCallback(
    (
      generalMessages: string[],
      noRatesMessages: string[],
      noRequiredRatesMessages: string[],
      onClick: () => void
    ) => {
      const header = "Selected Rate Card Has Failed Some Checks";
      const message = (
        <Stack css={{ alignItems: "flex-start", gap: "$1" }}>
          {generalMessages.length > 0 && (
            <>
              <Text bold underline italic>
                Selected Rate Card has some general problems:
              </Text>
              {generalMessages.map((message, idx) => (
                <Text key={idx}>
                  &middot; {message}
                  <br />
                </Text>
              ))}
            </>
          )}
          {noRatesMessages.length > 0 && (
            <>
              <Text bold underline italic>
                Following searches has no rates inside:
              </Text>
              {noRatesMessages.map((message, idx) => (
                <Text key={idx}>
                  &middot; {message}
                  <br />
                </Text>
              ))}
            </>
          )}
          {noRequiredRatesMessages.length > 0 && (
            <>
              <Text bold underline italic>
                Following searches has lack of some required values (Pay Rates):
              </Text>
              {noRequiredRatesMessages.map((message, idx) => (
                <Text key={idx}>
                  &middot; {message}
                  <br />
                </Text>
              ))}
            </>
          )}
        </Stack>
      );
      const footer = (
        <ButtonGroupRight fill css={{ flexDirection: "row-reverse" }}>
          <Button size="large" color="brand" onClick={onClick}>
            Select Another Rate Card (Reset)
          </Button>
          <Button size="large" color="brand" onClick={closeConfirmationModal}>
            Select Later
          </Button>
        </ButtonGroupRight>
      );

      return showConfirmationModal(message, header, footer);
    },
    [showConfirmationModal, closeConfirmationModal]
  );

  const validateRateCardDataFull = useCallback(
    (data: RateCardDataGraphQLType, isThreeLevelBanding: boolean = false) => {
      // shallowly check searches first
      if (data?.rateCardDetail == null) {
        showModalError("Rate card data not found. Please, contact support.");
        return {
          validationResult: false,
          searchesTotal: 0,
        };
      } else if (
        data.rateCardSearches?.edges == null ||
        !data.rateCardSearches.edges.length
      ) {
        showModalError("Rate Searches data not found. Please, contact support.");
        return {
          validationResult: false,
          searchesTotal: 0,
        };
      }

      const rateCardData = data.rateCardDetail;
      const searchesData = data.rateCardSearches.edges.map((edge) => edge.node);

      if (searchesData.length < rateCardData.searchCount) {
        showModalError(
          "Can't retrieve some Searches for the Rate Card. Please, contact support."
        );
      }

      let validationResult = true;
      let providedLevels = emptySet as ImmutableSet<number>;
      let providedRates = emptySet as ImmutableSet<string>;
      const generalMessages: string[] = [];
      const noRatesMessages: string[] = [];
      const noRequiredRatesMessages: string[] = [];

      // check every search in the Rate Card
      searchesData.forEach((searchData) => {
        const rateType = searchData["rateType"];
        const searchId = searchData["searchId"];
        const searchTitle = searchData["job"]["jobTitle"];
        const searchResults = searchData["marketrates"] || [];

        if (searchId === null) {
          generalMessages.push(`- search "${searchTitle}" has no unique ID (searchId)`);
          validationResult = false;
          return;
        }
        if (searchTitle === null) {
          generalMessages.push(`- search #${searchId} has no Job Title (job.jobTitle)`);
          validationResult = false;
          return;
        }
        if (searchResults == null || !searchResults.length) {
          noRatesMessages.push(`- search #${searchId} "${searchTitle}"`);
          validationResult = false;
          return;
        }

        let noPayRates = false;
        let noBillRates = false;
        let noMarkups = false;
        let noRequiredRates = false;

        // check every search result (every exp level)
        searchResults.forEach((resultData: RateCardMarketRatesGraphQLType) => {
          const expLevel = resultData.level.legacyId;

          if (expLevel != null && !providedLevels.contains(expLevel)) {
            providedLevels = providedLevels.add(expLevel);
          }

          // can't find any pay rates for the exp level
          if (payRatesKeys.filter((k) => !!resultData[k]).length === 0) {
            noPayRates = true;
          }
          // can't find any bill rates for the exp level
          if (billRatesKeys.filter((k) => !!resultData[k]).length === 0) {
            noBillRates = true;
          }
          // can't find any markups for the exp level
          if (markupKeys.filter((k) => !!resultData[k]).length === 0) {
            noMarkups = true;
          }

          // can't find any of required values
          if (
            requiredRatesKeys.filter((k) => !!resultData[k]).length !==
            requiredRatesKeys.length
          ) {
            noRequiredRates = true;
          }
        });

        if (!noPayRates) providedRates = providedRates.add(VALUE_TYPES.PAY_RATE);
        if (rateType !== GENERAL_RATE_TYPES.ANNUAL) {
          if (!noBillRates) providedRates = providedRates.add(VALUE_TYPES.BILL_RATE);
          if (!noMarkups) providedRates = providedRates.add(VALUE_TYPES.MARKUP);
        }

        if (noRequiredRates) {
          noRequiredRatesMessages.push(`- search #${searchId} "${searchTitle}"`);
          validationResult = false;
        }
      });

      if (isThreeLevelBanding && !providedLevels.equals(allPossibleLevelsValue)) {
        generalMessages.push(
          "- three level banding surveys can't be created out of selected rate card, it has no enough expertise levels data"
        );
      }

      if (
        generalMessages.length ||
        noRatesMessages.length ||
        noRequiredRatesMessages.length
      ) {
        handleFailedModal(
          generalMessages,
          noRatesMessages,
          noRequiredRatesMessages,
          handleRateCardReset
        );
      }

      return {
        validationResult,
        providedLevels,
        providedRates,
        searchesTotal: rateCardData.searchCount,
      };
    },
    [showModalError, handleRateCardReset, handleFailedModal]
  );

  const loadRateCardDataFull = useCallback(
    async (
      rateCardId: number,
      searchCount: number,
      selectedLevels: ImmutableSet<number>,
      selectedRates: ImmutableSet<string>,
      isThreeLevelBanding: boolean,
      reviewType: ReviewTypeOption
    ) => {
      const query = `
      query getRateCardData($rateCardId: Int!, $searchCount: Int!) {
        viewer {
          rateCardDetail(id: $rateCardId, section: ADMIN) {
            id: ratecardId
            name
            searchCount
          }
          rateCardSearches(id: $rateCardId, first: $searchCount) {
            edges {
              node {
                searchId
                job {
                  jobTitle
                  jobDescription
                  jobCategory {
                    description
                  }
                  collectionId
                }
                rateType
                country
                state
                city
                locationId
                region {
                  name
                  regionId
                  country {
                    name
                    apiLocationId
                  }
                }
                currency {
                  legacyId
                  alpha
                  iso
                  symbol
                }
                industry {
                  legacyId
                  value
                }
                marketrates {
                  level {
                    legacyId
                  }
                  billRateMin
                  billRateMid
                  billRateMax
                  billRateAvg
                  payRateMin
                  payRateMid
                  payRateMax
                  payRateAvg
                  markupPctMin
                  markupPctMid
                  markupPctMax
                  markupPctAvg
                }
              }
            }
          }
        }
      }
    `;
      const variables = { rateCardId, searchCount };

      try {
        const response: FetchGraphQLAPIResponse<RateCardDataGraphQLResponse> =
          await fetchGraphQL(query, variables);
        const data = response.data.data.viewer;

        const { validationResult, providedLevels, providedRates, searchesTotal } =
          validateRateCardDataFull(data, isThreeLevelBanding);

        const newState: Partial<ReviewFromRatecardFromState> = {
          rateCardSearchesTotal: searchesTotal || 0,
          rateCardProvidedLevels: providedLevels || emptySet,
          rateCardProvidedRates: providedRates || emptySet,
        };

        // Classic Surveys - adjust rates pickers to properly match to provided values

        let levelsPickerCorrected = false;
        let ratesPickerCorrected = false;

        if (reviewType && reviewType.value === REVIEW_TYPES.CLASSIC) {
          if (
            !isThreeLevelBanding &&
            providedLevels &&
            !selectedLevels.intersect(providedLevels).equals(selectedLevels)
          ) {
            newState.selectedLevels = selectedLevels.intersect(providedLevels);
            levelsPickerCorrected = true;
          }
          if (
            selectedRates.equals(allPossibleRatesValue) &&
            providedRates &&
            providedRates.size < selectedRates.size
          ) {
            newState.selectedRates = defaultSelectedRatesValue;
            ratesPickerCorrected = true;
          }
        }
        setPageState((prevPageState) => ({
          ...prevPageState,
          ...newState,
        }));

        if (reviewType && reviewType.value === REVIEW_TYPES.CLASSIC) {
          if (levelsPickerCorrected) {
            showModalWarning(
              "Required Levels picker has been adjusted to better match to the provided Rate Card values.",
              "Form Correction!",
              10_000
            );
          }
          if (ratesPickerCorrected) {
            showModalWarning(
              "Required Rates picker has been adjusted to better match to the provided Rate Card values.",
              "Form Correction!",
              10_000
            );
          }
        }

        return {
          data,
          validationResult,
          searchesTotal,
          providedLevels,
          providedRates,
        };
      } catch (err: any) {
        logAsyncOperationError("loadRateCardDataFull", err);
        showModalError("Error occurred while loading Rate Card data.");
      }
    },
    [fetchGraphQL, showModalError, showModalWarning, validateRateCardDataFull]
  );

  const handleChange = useCallback(
    (updatePageState: Partial<ReviewFromRatecardFromState>) => {
      setPageState((prevPageState) => ({
        ...prevPageState,
        ...updatePageState,
      }));
    },
    []
  );

  const handleLevelChange = useCallback(
    (value: SKILLS_LEVELS_TYPE) => {
      const { rateCardData, isThreeLevelBanding, selectedRates, reviewType } = pageState;
      let { selectedLevels } = pageState;

      if (value) {
        selectedLevels = selectedLevels.includes(value)
          ? selectedLevels.delete(value)
          : selectedLevels.add(value);
        if (!selectedLevels.size) {
          selectedLevels = selectedLevels.add(LEVELS_PICKER_OPTIONS.SENIOR.value);
          showModalWarning(`You should provide at least one expertise level.`, "Hint!");
        }

        setPageState((prevState) => ({
          ...prevState,
          rateCardDataFullPromise: rateCardData
            ? loadRateCardDataFull(
                rateCardData.id,
                rateCardData.searchCount,
                isThreeLevelBanding ? allPossibleLevelsValue : selectedLevels,
                selectedRates,
                isThreeLevelBanding,
                reviewType
              )
            : null,
          selectedLevels,
        }));
      }
    },
    [loadRateCardDataFull, pageState, showModalWarning]
  );

  const handleChangeClient: ClientSelectProps["onChange"] = useCallback((value) => {
    if (value) {
      setPageState((prevState) => ({
        ...prevState,
        clientData: value,
      }));
    }
  }, []);

  const handleThreeLevelBandingChange = useCallback(() => {
    const { rateCardData, selectedRates, reviewType } = pageState;
    const newThreeLevelBandingValue = !pageState.isThreeLevelBanding;
    const newLevelsValue = newThreeLevelBandingValue
      ? defaultThreeLevelBandingSelectedLevelsValues
      : defaultSelectedLevelsValue;

    setPageState((prevState) => ({
      ...prevState,
      isThreeLevelBanding: newThreeLevelBandingValue,
      selectedLevels: newLevelsValue,
      rateCardDataFullPromise: rateCardData
        ? loadRateCardDataFull(
            rateCardData.id,
            rateCardData.searchCount,
            newThreeLevelBandingValue ? allPossibleLevelsValue : newLevelsValue,
            selectedRates,
            newThreeLevelBandingValue,
            reviewType
          )
        : null,
    }));
  }, [pageState, loadRateCardDataFull]);

  const handleSelectedRatesChange = useCallback(
    (value: ImmutableSet<string>) => {
      const {
        rateCardData,
        isThreeLevelBanding,
        selectedLevels,
        selectedRates,
        reviewType,
      } = pageState;

      if (!value.equals(selectedRates)) {
        setPageState((prevState) => ({
          ...prevState,
          rateCardDataFullPromise: rateCardData
            ? loadRateCardDataFull(
                rateCardData.id,
                rateCardData.searchCount,
                isThreeLevelBanding ? allPossibleLevelsValue : selectedLevels,
                value,
                isThreeLevelBanding,
                reviewType
              )
            : null,
          selectedRates: value,
        }));
      }
    },
    [pageState, loadRateCardDataFull]
  );

  const handleReviewTypeChange = useCallback(
    (value: ReviewTypeOption) => {
      const isThreeLevelBanding = false;
      const newState: Partial<ReviewFromRatecardFromState> = {
        reviewType: value,
        isThreeLevelBanding,
      };

      if (value.value === REVIEW_TYPES.FILL_THE_BLANK) {
        newState["displayRateType"] = CONTINGENT_RATE_TYPES.HOURLY;
        newState["hourlyRateMultiplier"] = defaultHourlyRateMultiplier;
        newState["dailyRateMultiplier"] = defaultDailyRateMultiplier;
        newState["weeklyRateMultiplier"] = defaultWeeklyRateMultiplier;
        newState["monthlyRateMultiplier"] = defaultMonthlyRateMultiplier;
      }

      if (value.value !== REVIEW_TYPES.ORDERED) {
        newState["questions"] = pageState.questions;
      }

      const rateCardData = pageState.rateCardData;

      if (value.value === REVIEW_TYPES.FILL_THE_BLANK) {
        newState["selectedLevels"] = allPossibleLevelsValue;
        newState["selectedRates"] = allPossibleRatesValue;
      } else if (value.value === REVIEW_TYPES.CLASSIC) {
        newState["selectedLevels"] = defaultSelectedLevelsValue;
        newState["selectedRates"] = defaultSelectedRatesValue;

        if (rateCardData !== null && rateCardData.id !== null) {
          newState["rateCardDataFullPromise"] = loadRateCardDataFull(
            rateCardData.id,
            rateCardData.searchCount,
            defaultSelectedLevelsValue,
            defaultSelectedRatesValue,
            isThreeLevelBanding,
            value
          );
        }
      } else if (value.value === REVIEW_TYPES.ORDERED) {
        newState["selectedLevels"] = allPossibleLevelsValue;
        newState["selectedRates"] = allPossibleRatesValue;
        newState["questions"] = emptyList;

        if (rateCardData !== null && rateCardData.id !== null) {
          newState["rateCardDataFullPromise"] = loadRateCardDataFull(
            rateCardData.id,
            rateCardData.searchCount,
            allPossibleLevelsValue,
            allPossibleRatesValue,
            isThreeLevelBanding,
            value
          );
        }
      }

      setPageState((prevState) => ({ ...prevState, ...newState }));
    },
    [pageState.questions, pageState.rateCardData, loadRateCardDataFull]
  );

  const handleRateCardChange: ChangeFuncType = useCallback(
    (value) => {
      if (value) {
        const rateCardId = value.id;
        const searchCount = value.searchCount;
        const { isThreeLevelBanding, selectedLevels, selectedRates, reviewType } =
          pageState;

        setPageState((prevState) => ({
          ...prevState,
          rateCardData: value,
          rateCardDataFullPromise: loadRateCardDataFull(
            rateCardId,
            searchCount,
            isThreeLevelBanding ? allPossibleLevelsValue : selectedLevels,
            selectedRates,
            isThreeLevelBanding,
            reviewType
          ),
        }));
      }
    },
    [pageState, loadRateCardDataFull]
  );

  const handleClearForm = useCallback(() => {
    setPageState((prevState) => ({
      ...prevState,
      clientData: null,
      reviewType: defaultReviewTypeValue,
      reviewTitle: null,
      rateCardData: null,
      rateCardDataFullPromise: null,
      rateCardProvidedLevels: emptySet,
      rateCardProvidedRates: emptySet,
      rateCardSearchesTotal: 0,
      isThreeLevelBanding: false,
      selectedLevels: defaultSelectedLevelsValue,
      selectedRates: defaultSelectedRatesValue,
      displayRateType: CONTINGENT_RATE_TYPES.HOURLY,
      displayCurrency: null,
      hourlyRateMultiplier: defaultHourlyRateMultiplier,
      dailyRateMultiplier: defaultDailyRateMultiplier,
      weeklyRateMultiplier: defaultWeeklyRateMultiplier,
      monthlyRateMultiplier: defaultMonthlyRateMultiplier,
      replacements: emptyList,
      questions: emptyList,
      isFreeValidation: false,
      isActive: true,
      rewardPerJob: null,
      maxAttemptsNumber: defaultAttemptsNumber,
      instructions: null,
    }));
    window.scrollTo(0, 0);
  }, []);

  const createJobReviewsFromRateCard = async () => {
    const {
      clientData,
      rateCardData,
      reviewType,
      reviewTitle,
      isThreeLevelBanding,
      selectedLevels,
      selectedRates,
      displayCurrency,
      replacements,
      questions,
      isFreeValidation,
      isActive,
      rewardPerJob,
      maxAttemptsNumber,
      instructions,
    } = pageState;

    let displayRateTypeData = {};
    const displayCurrencyData: Partial<DisplayCurrencyType> = {};

    if (
      reviewType &&
      (reviewType.value === REVIEW_TYPES.CLASSIC ||
        reviewType.value === REVIEW_TYPES.ORDERED)
    ) {
      displayRateTypeData = {
        display_rate_type: pageState.displayRateType,
        daily_multiplier: pageState.dailyRateMultiplier || defaultDailyRateMultiplier,
        weekly_multiplier: pageState.weeklyRateMultiplier || defaultWeeklyRateMultiplier,
        monthly_multiplier:
          pageState.monthlyRateMultiplier || defaultMonthlyRateMultiplier,
      };
    }

    if (displayCurrency && Object.keys(displayCurrency).length) {
      displayCurrencyData["display_currency_id"] = displayCurrency["id"];
      displayCurrencyData["display_currency_code"] = displayCurrency["code"];
      displayCurrencyData["display_currency_name"] = displayCurrency["name"];
      displayCurrencyData["display_currency_symbol"] = displayCurrency["symbol"];
    }

    let rateCardDataFullPromise = pageState.rateCardDataFullPromise;
    if (!rateCardDataFullPromise && rateCardData) {
      rateCardDataFullPromise = loadRateCardDataFull(
        rateCardData.id,
        rateCardData.searchCount,
        isThreeLevelBanding ? allPossibleLevelsValue : selectedLevels,
        selectedRates,
        isThreeLevelBanding,
        reviewType
      );
    }

    const rateCardDataFull = await rateCardDataFullPromise;
    if (!rateCardDataFull?.validationResult) return null;

    try {
      const postData = {
        client_id: clientData?.clientId,
        client_name: clientData?.clientName,
        ratecard_id: rateCardDataFull?.data.rateCardDetail.id,
        ratecard_name: rateCardDataFull?.data.rateCardDetail.name,
        ratecard_searches: rateCardDataFull?.data.rateCardSearches.edges.map(
          (i) => i.node
        ),
        review_type: reviewType?.value,
        review_title: reviewTitle,
        is_three_level_banding: isThreeLevelBanding,
        required_levels: selectedLevels.toJS(),
        required_rates: selectedRates.toJS(),
        free_validation: isFreeValidation,
        is_active: isActive,
        reward_per_job: rewardPerJob,
        max_attempts: maxAttemptsNumber,
        replacements: replacements.toJS(),
        questions: questions
          .filter((q) => q.get("included", false))
          .toList()
          .toJS(),
        instructions,
        ...displayRateTypeData,
        ...displayCurrencyData,
      };

      const response: FetchAPIResponse<ReviewDataObject[]> = await fetchArgusAPI(
        `reviews/create_from_ratecard/`,
        {
          method: "post",
          data: postData,
        }
      );

      const data = reviewsListToImmutableList(response.data);
      if (data?.size) {
        showModalSuccess(`${data.size} surveys successfully created.`);
      }

      setPageState((prevState) => ({ ...prevState, created: true }));
      handleClearForm();
    } catch (err: any) {
      logAsyncOperationError("createJobReviewsFromRateCard", err);
      showModalError("Error occurred while creating surveys list.");
    }
  };

  const handleSubmit = async () => {
    const { reviewType, selectedLevels, rateCardData, isFreeValidation, rewardPerJob } =
      pageState;

    if (!rateCardData) {
      showModalWarning("Please select a Rate Card.", "Missing Rate Card Data!");
      return null;
    }
    if (
      reviewType &&
      reviewType.value === REVIEW_TYPES.CLASSIC &&
      (!selectedLevels || !selectedLevels.size)
    ) {
      showModalWarning(
        "Please select Expertise Levels to be included in new surveys.",
        "Missing Exp Levels Parameters!"
      );
      return null;
    }
    if (!isFreeValidation && !rewardPerJob) {
      showModalWarning(
        "Please enter Reward Per Job value or otherwise mark surveys as Free.",
        "Missing Reward Parameters!"
      );
      return null;
    }

    return createJobReviewsFromRateCard();
  };

  // render

  return (
    <WrapperBlock>
      <Container>
        <StepID>1</StepID>
        <Content>
          <Label>
            Select a ratecard as a source for the surveys (searches by Rate Card Name) *:
          </Label>
          <RateCardSingleSelect
            value={pageState.rateCardData}
            onChange={handleRateCardChange}
          />
        </Content>
      </Container>

      <Container>
        <StepID>2</StepID>
        <Content>
          <Label>Select a client, to associate resulting surveys with it:</Label>
          <ClientSelect
            fetchGraphQL={fetchGraphQL}
            value={pageState.clientData}
            onChange={handleChangeClient}
            styles={reactSelectStyles}
          />
        </Content>
      </Container>

      <Container>
        <StepID>3</StepID>
        <Content>
          <Label>Select the type of survey to create:</Label>
          <SingleSelect
            className="full-width"
            options={REVIEW_TYPES_OPTIONS}
            styles={reactSelectStyles}
            value={pageState.reviewType}
            onChange={handleReviewTypeChange}
          />
          {pageState.reviewType.value === REVIEW_TYPES.CLASSIC && (
            <>
              <Label>
                Select levels to be included into new surveys&nbsp;
                <TextCrop
                  mode="modal"
                  title="Depth Of Capability"
                  label={<Icon icon={["far", "question-circle"]} />}
                  fullText={
                    pageState.isThreeLevelBanding
                      ? threeLevelBandingSkillsLevelsInfoText
                      : classicSkillsLevelsInfoText
                  }
                  hint="Click to see details"
                />
                &nbsp;:
              </Label>
              <CheckboxItem
                checked={pageState.isThreeLevelBanding}
                onCheckedChange={handleThreeLevelBandingChange}
              >
                Use 3 level banding
              </CheckboxItem>

              <InlineMultiValuePicker
                options={
                  pageState.isThreeLevelBanding
                    ? Object.values(LEVELS_PICKER_OPTIONS_SHORT)
                    : Object.values(LEVELS_PICKER_OPTIONS)
                }
                label="Levels"
                value={pageState.selectedLevels.toArray()}
                onChange={handleLevelChange}
              />
            </>
          )}
          {pageState.reviewType.value === REVIEW_TYPES.CLASSIC && (
            <>
              <Label>
                For contingent labor surveys choose values to be included into new
                surveys:
              </Label>
              <InlineValuePicker
                options={Object.values(RATES_PICKER_OPTIONS)}
                label="Rates"
                value={pageState.selectedRates}
                onChange={handleSelectedRatesChange}
              />
            </>
          )}
          {(pageState.reviewType.value === REVIEW_TYPES.CLASSIC ||
            pageState.reviewType.value === REVIEW_TYPES.ORDERED) && (
            <LaborValuesChooseBlock
              displayRateType={pageState.displayRateType}
              hourlyRateMultiplier={pageState.hourlyRateMultiplier}
              dailyRateMultiplier={pageState.dailyRateMultiplier}
              weeklyRateMultiplier={pageState.weeklyRateMultiplier}
              monthlyRateMultiplier={pageState.monthlyRateMultiplier}
              onChange={handleChange}
            />
          )}
          <Label>
            Select display currency (leaving it blank will display rates in local
            currencies for every Job Title):
          </Label>
          <CurrencySelect
            className="full-width"
            currenciesData={currenciesData.toList().toJS()}
            value={pageState.displayCurrency}
            onChange={(value: ValueType<CurrencyData>) =>
              handleChange({ displayCurrency: value as CurrencyData })
            }
            styles={reactSelectStyles}
          />
        </Content>
      </Container>

      <Container>
        <StepID>4</StepID>
        <Content>
          <Label>
            Configure words replacements:&nbsp;
            <Tooltip side="right" content={replacementsTooltipContent}>
              <span>
                <Icon icon={["far", "question-circle"]} />
              </span>
            </Tooltip>
          </Label>
          <InputsList
            labels={["Replace", "With"]}
            values={pageState.replacements}
            inputImpl={InputsPair}
            onChange={(value: ReplacementsList) => handleChange({ replacements: value })}
          />
        </Content>
      </Container>

      <Container>
        <StepID>5</StepID>
        <Content>
          <OtherSurveyBlock
            reviewType={pageState.reviewType}
            reviewTitle={pageState.reviewTitle}
            isFreeValidation={pageState.isFreeValidation}
            isActive={pageState.isActive}
            rewardPerJob={pageState.rewardPerJob}
            rateCardSearchesTotal={pageState.rateCardSearchesTotal}
            maxAttemptsNumber={pageState.maxAttemptsNumber}
            onChange={handleChange}
          />
        </Content>
      </Container>

      <Container>
        <StepID>6</StepID>
        <Content>
          <Label>Enter any special instructions (they will appear for every job):</Label>
          <TextArea
            fill
            rows={5}
            value={pageState.instructions || ""}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              handleChange({ instructions: e.target.value })
            }
          />
        </Content>
      </Container>

      <Container>
        <StepID>7</StepID>
        <Content>
          <Label>
            Select optional questions to appear with each job to be validated:
          </Label>
          <QuestionsEditor
            questions={pageState.questions}
            onChange={(value: QuestionsOrderedMap) => handleChange({ questions: value })}
          />
        </Content>
      </Container>

      <ActionsBlock
        created={pageState.created}
        onClear={handleClearForm}
        onSubmit={handleSubmit}
      />
    </WrapperBlock>
  );
};
ReviewFromRatecardForm.displayName = "ReviewFromRatecardForm";

export default ReviewFromRatecardForm;
