import React from "react";

import Grid from "../../../../components/lib/Grid";
import Text from "../../../../components/lib/Text";
import TextInput from "../../../../components/lib/TextInput";
import NumberInput from "../../../../components/lib/NumberInput";
import Inline from "../../../../components/lib/Inline";
import Icon from "../../../../components/lib/Icon";
import { Checkbox, CheckboxIndicator } from "../../../../components/lib/Checkbox";
import { Label } from "../../../private_index/components/SubmitContractorsWizardPage";
import { decimalFormatter } from "../../constants";

import { REVIEW_TYPES } from "../../types";
import type { ReviewTypeOption, ReviewFromRatecardFromState } from "./index";

type OtherSurveyBlockProps = {
  reviewType: ReviewTypeOption;
  reviewTitle: string | null;
  isFreeValidation: boolean;
  isActive: boolean;
  rewardPerJob: number | null;
  rateCardSearchesTotal: number;
  maxAttemptsNumber: number | null;
  onChange: (updatePageState: Partial<ReviewFromRatecardFromState>) => void;
};

export default function OtherSurveyBlock(props: OtherSurveyBlockProps) {
  const {
    reviewType,
    reviewTitle,
    isFreeValidation,
    isActive,
    rewardPerJob,
    rateCardSearchesTotal,
    maxAttemptsNumber,
    onChange,
  } = props;

  return (
    <>
      <Label>Other survey properties:</Label>
      <Grid
        css={{
          gridTemplateColumns: "auto auto",
          justifyContent: "flex-start",
          gap: "$2",
          [`& ${Text}`]: {
            justifySelf: "flex-end",
            alignSelf: "center",
          },
        }}
      >
        {reviewType.value === REVIEW_TYPES.ORDERED && (
          <>
            <Text>name the future survey</Text>
            <TextInput
              size="small"
              css={{ padding: "$2px", borderRadius: "$rounded" }}
              value={reviewTitle ?? ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChange({ reviewTitle: e.target.value })
              }
            />
          </>
        )}
        <Text>mark survey as free</Text>
        <Checkbox
          checked={isFreeValidation}
          onCheckedChange={(value: boolean) =>
            onChange({ isFreeValidation: value, rewardPerJob: null })
          }
        >
          <CheckboxIndicator>
            <Icon icon="check" />
          </CheckboxIndicator>
        </Checkbox>

        <Text
          css={{
            color: isFreeValidation ? "$primaryLight" : "$text",
          }}
        >
          reward per job
        </Text>
        <Inline>
          <NumberInput
            size="small"
            value={rewardPerJob ?? ""}
            disabled={isFreeValidation}
            color={isFreeValidation ? "primary" : rewardPerJob ? "primary" : "danger"}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange({
                rewardPerJob: Number.isNaN(e.target.valueAsNumber)
                  ? null
                  : e.target.valueAsNumber,
              })
            }
          />
          {rateCardSearchesTotal !== null && rewardPerJob !== null && (
            <Text thin>
              {decimalFormatter(rateCardSearchesTotal * rewardPerJob, false, "$")} total
            </Text>
          )}
        </Inline>

        <Text>pause after given attempts number</Text>
        <NumberInput
          size="small"
          value={maxAttemptsNumber ?? ""}
          color={maxAttemptsNumber ? "primary" : "danger"}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange({
              maxAttemptsNumber: Number.isNaN(e.target.valueAsNumber)
                ? null
                : e.target.valueAsNumber,
            })
          }
        />

        <Text>make all survey inactive by default</Text>
        <Checkbox
          checked={!isActive}
          onCheckedChange={(value: boolean) => onChange({ isActive: !value })}
        >
          <CheckboxIndicator>
            <Icon icon="check" />
          </CheckboxIndicator>
        </Checkbox>
      </Grid>
    </>
  );
}

OtherSurveyBlock.displayName = "OtherSurveyBlock";
