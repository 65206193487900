// @flow

import R from "ramda";
import { extendObservable, action, runInAction, computed, observable } from "mobx";
import moment from "moment";
import TagModel from "../../models/TagList";
import CurrentUser from "../../models/User";
import {
  RateCardLabelFilter,
  CreatedByFilter,
  SharedFilter,
  CreatedOnFilter,
} from "../../models/FilterState";
import NetworkState from "../../models/NetworkState";
import PaginationState from "../../models/PaginationState";
import FilterObject, { FILTER_COLUMN } from "../../models/Filter";
import Sort, { SORT_DIRECTION } from "../../models/Sort";
import ModalState from "../../models/ModalState";
import {
  addIdToPayload,
  consolidateAppliedFilters,
  consolidateAppliedSorts,
} from "./SupportFunctions";
import type { PageQuery, PaginationInfo } from "../../models/PaginationState";
import type { FilterColumn } from "../../models/Filter";
import type { GraphQLQuery } from "../../models/GraphQL";
import MessageState from "../../models/MessageState";
import { browserHistory } from "react-router";
import axios from "axios";
import type { FetchGraphQL } from "../../App";

const ownersCriteriaQuery = `
query authorList {
  viewer {
    authors {
      edges {
        node {
          userId
          firstName
          lastName
          username
          email
        }
      }
    }
  }
}
`;

const rateTypesCriteriaQuery = `
query {
  viewer {
    rateCardsFilterCriteria {
      rateTypes
    }
  }
}
`;

// type Owner = { userId: string, username: string };
class User {
  id: string;
  userId: string;
  username: string;
  firstName: string;
  lastName: string;
  selected: string;

  constructor(object: Object) {
    this.id = object.userId;
    this.userId = object.userId;
    this.username = object.username;
    this.firstName = object.firstName;
    this.lastName = object.lastName;

    extendObservable(this, {
      selected: object.selected,
    });
  }
}

export class TagManagementListComponentStore {
  fetchGraphQL: FetchGraphQL;
  network: NetworkState;
  pagination: PaginationState;

  tags: TagModel[];
  tagsViewState: Object;
  tagsView: TagModel[];
  tagCounts: any;

  currentUser: CurrentUser;
  isEditing: ?boolean;
  allOnPageSelected: boolean;
  allSelected: boolean;
  allSelectedfilter: boolean;
  allowMultipleItemSelection: boolean;

  tagLabelFilter: RateCardLabelFilter;
  createdByFilter: CreatedByFilter;
  sharedFilter: SharedFilter;
  createdOnFilter: CreatedOnFilter;
  requestFrom: boolean;

  defaultFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedSorts: {
    [key: FilterColumn]: Sort,
  };
  appliedSortsOrder: Array<FilterColumn>;
  isFiltered: boolean;
  hasOwnership: boolean;
  selectedTagId: null;
  sevenUrl: string;
  unSelectedTag: [];

  getTags: (PageQuery) => Promise<PaginationInfo>;
  applyDefaultFilter: (FilterColumn, FilterObject) => void;
  handleStartEdit: () => void;
  handleStopEdit: () => void;
  toggleSelectAllPage: (Object) => void;
  selectAllPage: (Event) => void;
  deselectAllPage: (Event) => void;
  toggleAllItems: () => void;
  clearAllSelections: () => void;
  getSelectedTags: () => Array<number>;
  getFirstSelectedTag: () => Object;
  toDateRange: () => void;
  getFilterCriteriaQuery: (FilterColumn) => GraphQLQuery;
  processFilterCriteria: (FilterColumn, Object) => Array<Object>;
  applyFilter: (FilterColumn, FilterObject) => void;
  removeFilter: (FilterColumn) => void;
  applySort: (FilterColumn, Sort) => void;
  removeSort: (FilterColumn) => void;
  filterTags: () => void;
  clearFilters: () => void;
  applyDefaultSort: () => void;
  updateRequestFrom: () => void;
  SingleSelectedTag: () => void;
  messaging: MessageState;
  createMessaging: MessageState;
  tagLabel: string;
  createTag: () => void;
  changeTagLabelText: (Object) => void;
  selectAllOnPageItem: () => void;

  constructor(fetchGraphQL: FetchGraphQL) {
    this.fetchGraphQL = fetchGraphQL;

    // NOTE: Bound early to pass into pagination & filter state
    this.getTags = action(this.getTags.bind(this));

    this.getFilterCriteriaQuery = action(this.getFilterCriteriaQuery.bind(this));
    this.processFilterCriteria = action(this.processFilterCriteria.bind(this));
    this.applyFilter = action(this.applyFilter.bind(this));
    this.applySort = action(this.applySort.bind(this));
    this.removeFilter = action(this.removeFilter.bind(this));
    this.removeSort = action(this.removeSort.bind(this));
    this.createTag = action(this.createTag.bind(this));
    this.changeTagLabelText = action(this.changeTagLabelText.bind(this));
    this.toDateRange = action(this.toDateRange.bind(this));
    this.unSelectedTag = [];

    extendObservable(this, {
      network: new NetworkState(),
      pagination: new PaginationState(this.getTags),
      tagLabel: "",
      tags: [],
      tagCounts: 0,
      hasOwnership: true,
      selectedTagId: null,
      tagsViewState: observable.map({}),
      tagsView: computed(() => {
        return this.tags.map((tag) => {
          if (this.tagsViewState.has(tag.tagId)) {
            tag.viewState = this.tagsViewState.get(tag.tagId);

            return tag;
          }

          return tag;
        });
      }),
      isEditing: null, // we start with null so some view elements be hidden initially
      isFiltered: false,
      allSelected: false,
      allSelectedfilter: false,
      allowMultipleItemSelection: true,
      requestFrom: false,
      selectedCount: computed(() => {
        const selectedValues = this.tagsView.map((tag) => tag.viewState.selected);

        if (this.allSelected) {
          return this.pagination.totalCount;
        }

        let count = 0;

        selectedValues.forEach((v) => {
          if (v) {
            count += 1;
          }
        });

        return count;
      }),
      allOnPageSelected: computed(() => {
        const allTrue = R.all(R.equals(true));
        const selectedValues = this.tagsView.map((tag) => tag.viewState.selected);

        if (selectedValues.length === 0) {
          return false;
        }

        return allTrue(selectedValues);
      }),
      defaultFilters: {},
      appliedFilters: {},
      appliedSorts: {},
      appliedSortsOrder: observable.shallow([]),
      tagLabelFilter: new RateCardLabelFilter(
        this,
        FILTER_COLUMN.RATE_CARD_LABEL,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      createdByFilter: new CreatedByFilter(
        this,
        FILTER_COLUMN.CREATED_BY,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      sharedFilter: new SharedFilter(
        this,
        FILTER_COLUMN.SHARED,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      createdOnFilter: new CreatedOnFilter(
        this,
        FILTER_COLUMN.DATE_RANGE,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      messaging: new MessageState(),
      createMessaging: new MessageState(),
    });

    this.currentUser = null;

    this.applyDefaultFilter = action(this.applyDefaultFilter.bind(this));
    this.handleStartEdit = action(this.handleStartEdit.bind(this));
    this.handleStopEdit = action(this.handleStopEdit.bind(this));
    this.toggleSelectAllPage = action(this.toggleSelectAllPage.bind(this));
    this.selectAllPage = action(this.selectAllPage.bind(this));
    this.deselectAllPage = action(this.deselectAllPage.bind(this));
    this.toggleAllItems = action(this.toggleAllItems.bind(this));
    this.clearAllSelections = action(this.clearAllSelections.bind(this));
    this.getSelectedTags = action(this.getSelectedTags.bind(this));
    this.getFirstSelectedTag = action(this.getFirstSelectedTag.bind(this));

    this.filterTags = action(this.filterTags.bind(this));
    this.clearFilters = action(this.clearFilters.bind(this));
    this.applyDefaultSort = action(this.applyDefaultSort.bind(this));
    this.updateRequestFrom = action(this.updateRequestFrom.bind(this));
    this.SingleSelectedTag = action(this.SingleSelectedTag.bind(this));
    this.selectAllOnPageItem = action(this.selectAllOnPageItem.bind(this));

    this.applyDefaultSort();
  }

  updateRequestFrom(request) {
    if (request && request === "pce") this.requestFrom = true;
    else {
      this.requestFrom = false;
    }
  }

  SingleSelectedTag(tag) {
    let viewState = this.tagsViewState.get(tag.tagId).selected;

    if (!viewState) {
      this.selectedTagId = tag.tagId;
    } else {
      this.selectedTagId = null;
    }

    let checkOwnership = true;
    if (this.allSelected || this.allOnPageSelected) {
      this.hasOwnership = true;
      return;
    }

    this.tagsView.forEach((value) => {
      if (
        String(value.owner.clientId) !== String(this.currentUser.userId) &&
        value.viewState.selected
      ) {
        this.hasOwnership = false;
        checkOwnership = false;
        return;
      }
    });
    if (checkOwnership) {
      if (!viewState && tag.owner && tag.owner.clientId) {
        this.hasOwnership =
          String(tag.owner.clientId) === String(this.currentUser.userId);
      } else {
        this.hasOwnership = true;
      }
    }
  }

  applyDefaultSort() {
    this.createdOnFilter.sortState.direction = SORT_DIRECTION.DESC;
    this.createdOnFilter.sort = this.createdOnFilter.buildQuerySort();
    this.applySort(this.createdOnFilter.column, this.createdOnFilter.sort);
  }

  handleStartEdit() {
    this.isEditing = true;
    this.tagsView.forEach((tag) => tag.toggleEdit());
  }

  handleStopEdit() {
    this.isEditing = false;
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.tagsViewState.forEach((viewState) => {
      viewState.selected = false;
      viewState.editing = false;
    });
  }

  toggleSelectAllPage(e: Object) {
    if (!this.allowMultipleItemSelection) return;

    const setValue = !this.allOnPageSelected;

    this.tagsView.forEach((tag) => {
      tag.toggleSelected(e, null, null, setValue);
    });

    // When All items selected flag is up, clear selection
    if (setValue === false && this.allSelected) this.allSelected = false;
  }

  selectAllPage(e: Event) {
    this.hasOwnership = true;
    this.unSelectedTag = [];
    this.allSelected = true;
    this.allSelectedfilter = true;
  }

  selectAllOnPageItem(e: Event) {
    this.tagsView.forEach((tag) => {
      tag.toggleSelected(e, null, null, true);
    });
  }

  deselectAllPage(e: Event) {
    this.tagsView.forEach((tag) => {
      tag.toggleSelected(e, null, null, false);
    });

    this.allSelected = false;
    this.allSelectedfilter = false;
  }

  toggleAllItems() {
    if (!this.allowMultipleItemSelection) return;

    this.allSelected = !this.allSelected;

    if (this.allSelected === false) {
      this.tagsViewState.forEach((value) => {
        value.selected = false;
      });
    }
  }

  clearAllSelections() {
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.tagsViewState.forEach((value) => {
      value.selected = false;
    });
  }

  getFilterCriteriaQuery(column: FilterColumn): GraphQLQuery {
    switch (column) {
      case FILTER_COLUMN.RATE_TYPE:
        return {
          query: rateTypesCriteriaQuery,
          variables: {},
        };

      case FILTER_COLUMN.CREATED_BY:
        return {
          query: ownersCriteriaQuery,
          variables: {},
        };

      default:
        return null;
    }
  }

  processFilterCriteria(column: FilterColumn, payload: Object): ?Array<Object> {
    switch (column) {
      case FILTER_COLUMN.RATE_TYPE:
        const rateTypes: [String] = payload.data.viewer.rateCardsFilterCriteria.rateTypes;
        return addIdToPayload(rateTypes);

      case FILTER_COLUMN.CREATED_BY:
        const owners: [
          {
            userId: string,
            username: string,
          }
        ] = payload.data.viewer.authors.edges;
        let processedOwners = observable.map({});
        owners.forEach((owner) => {
          processedOwners.set(String(owner.node.userId), {
            id: String(owner.node.userId),
            ...owner,
          });
        });

        return processedOwners;

      default:
        return null;
    }
  }

  applyFilter(column: FilterColumn, filter: FilterObject) {
    this.appliedFilters[column] = filter;
    this.isFiltered = true;
  }

  applyDefaultFilter(column: FilterColumn, filter: FilterObject) {
    this.defaultFilters[column] = filter;
  }

  removeFilter(column: FilterColumn) {
    delete this.appliedFilters[column];

    let entries = Object.entries(this.appliedFilters);
    if (!entries.length) this.isFiltered = false;
  }

  applySort(column: FilterColumn, sort: Sort) {
    this.appliedSorts[column] = sort;

    const index = this.appliedSortsOrder.indexOf(column);
    if (index === -1) this.appliedSortsOrder.push(column);
  }

  removeSort(column: FilterColumn) {
    delete this.appliedSorts[column];

    const index = this.appliedSortsOrder.indexOf(column);
    if (index > -1) this.appliedSortsOrder.splice(index, 1);
  }

  clearFilters() {
    this.tagLabelFilter = new RateCardLabelFilter(
      this,
      FILTER_COLUMN.RATE_CARD_LABEL,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.createdByFilter = new CreatedByFilter(
      this,
      FILTER_COLUMN.CREATED_BY,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.sharedFilter = new SharedFilter(
      this,
      FILTER_COLUMN.SHARED,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.createdOnFilter = new CreatedOnFilter(
      this,
      FILTER_COLUMN.DATE_RANGE,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );

    this.appliedFilters = observable({});
    this.appliedSorts = observable({});
    this.appliedSortsOrder.length = 0;
    this.isFiltered = false;
    this.applyDefaultSort();

    return this.pagination.goFetch(null);
  }

  filterTags() {
    return this.pagination.goFetch(null);
  }

  getSelectedTags(): Array<number> {
    const tags = this.tagsViewState;

    let selectedTags = [];

    tags.forEach((value, key) => {
      if (value.selected) {
        selectedTags.push(parseInt(key, 10));
      }
    });

    return selectedTags;
  }

  getFirstSelectedTag() {
    for (let j = 0; j < this.tagsView.length; j++) {
      if (this.tags[j].viewState.selected) return this.tags[j];
    }

    return null;
  }

  changeTagLabelText(e: Object) {
    this.tagLabel = e.target.value;
  }

  async createTag() {
    let vars = {};

    this.createMessaging.removeAll();
    if (this.tagLabel === "") {
      this.createMessaging.createMessage("info", "Please enter a label for your tag.");
      return;
    }

    vars.name = this.tagLabel;

    const query = `
    mutation createTag($name: String!) {
      createTag(input: {name: $name}){
        tags{
            tagId,
            owner{
            firstName,
            lastName,
            email,
            termsOfAgreement
          },
          name,
          created
        }
          errors {
           __typename
           ... on TagNameEmptyError {
             message
           }
           ... on TagNameAlreadyExistError {
             message
           }
           ... on TagDoesNotExistsError {
             message
           }
         }
      }
      }
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      // TODO: Display user friendly error message
      throw e;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }

    runInAction("createTag--success", () => {
      if (res.data.createTag.errors) {
        this.createMessaging.removeAll();
        this.createMessaging.createMessage("error", res.data.createTag.errors[0].message);
        return;
      }
      this.tagLabel = "";
      //this.pagination.goFetch(null);
      browserHistory.push("/tag");
    });
  }

  async getTags(pageQuery: PageQuery): Promise<PaginationInfo> {
    let params: string[] = pageQuery.params;
    let args = pageQuery.args;
    let variables = pageQuery.variables;
    let filtersCriteria: string[] = [];

    let sortCriteria: string[] = [];
    consolidateAppliedSorts(this.appliedSorts, sortCriteria);

    consolidateAppliedFilters(this.appliedFilters, params, filtersCriteria, variables);

    // NOTE: applied filters could override default filters, handle this if needed
    consolidateAppliedFilters(this.defaultFilters, params, filtersCriteria, variables);

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");
    const queryFiltersCriteria = filtersCriteria.join(", ");
    const querySortCriteria = sortCriteria.join(", ");

    const query = `
        query getTag(${queryParams}){
          viewer{
              user{
              firstName
              lastName
              userId
              username
              email
            }
            tags(${queryArgs}, filters: { ${queryFiltersCriteria} }, order: [${querySortCriteria}]){
              totalCount
              edges{
                node{
                  name
                  created
                  tagId
                  owner{
                    firstName
                    lastName
                    email
                    userId
                  }
                  sharedWith{
                    firstName
                    lastName
                    email
                    userId
                  }
                }
              }
            }
          }
        }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting Rate Cards", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Rate Card query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getTags--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get Rate Cards query", res)) {
        // TODO: Display user friendly error message
        return {
          totalCount: 0,
          startCursor: "",
          endCursor: "",
        };
      }

      this.currentUser = new CurrentUser(this, res.data.viewer.user);

      // // $FlowFixMe: Type the res value once we abstract gql calls.
      const cards = res.data.viewer.tags.edges;

      this.tagCounts = res.data.viewer.tags.totalCount;
      // // TODO: Deserialize this properly...
      this.tags = cards.map((card) => {
        const tag = new TagModel(this, card.node);
        // rateCard.searches = card.node.searches.totalCount;
        // rateCard.locations = card.node.locations;
        if (!this.tagsViewState.has(tag.tagId)) {
          this.tagsViewState.set(tag.tagId, {
            selected: this.allSelected,
            editing: this.isEditing,
            expanded: false,
          });
        } else {
          const selectedValue = this.allSelected
            ? true
            : this.tagsViewState.get(tag.tagId).selected;

          this.tagsViewState.set(tag.tagId, {
            selected: selectedValue,
            editing: this.isEditing,
            expanded: false,
          });
        }

        tag.viewState = this.tagsViewState.get(tag.tagId);

        return tag;
      });

      return {
        totalCount: res.data.viewer.tags.totalCount,
      };
    });
  }
}

export default class TagManagementListStore extends TagManagementListComponentStore {
  showHelpModal: boolean;
  newTagName: string;

  shareUsers: User[];
  shareUsersViewState: Object;
  shareUsersInstantSearchValue: string;
  shareUsersTotalCount: number;
  shareUsersSelectedCount: number;

  helpModal: ModalState;
  confirmUpdateRatesModal: ModalState;
  confirmUndoUpdateRatesModal: ModalState;
  confirmDeleteModal: ModalState;
  renameTagModal: ModalState;
  shareModal: ModalState;
  networkShareUsers: NetworkState;

  showRenameTagModal: () => void;
  renameTag: () => void;
  onNewTagNameChange: (Event) => void;
  shareUsersOnSelectAll: () => void;
  shareUsersOnDeselectAll: () => void;
  shareUsersOnInstantSearch: (string) => void;
  getShareUsers: () => void;
  share: () => void;
  setSharedUserSelectedValue: (object) => void;
  performShare: () => void;
  deleteTags: () => void;

  constructor(fetchGraphQL: FetchGraphQL) {
    super(fetchGraphQL);

    // Bound early to pass into export state

    extendObservable(this, {
      newTagName: "",
      helpModal: new ModalState(),
      renameTagModal: new ModalState(),
      confirmUpdateRatesModal: new ModalState(),
      confirmUndoUpdateRatesModal: new ModalState(),
      confirmDeleteModal: new ModalState(),
      shareModal: new ModalState(),
      networkShareUsers: new NetworkState(),
      shareUsers: observable.shallow([]),
      shareUsersViewState: observable.map({}),
      shareUsersInstantSearchValue: "",
      shareUsersView: [],
      shareUsersHasNoSelection: computed(() => {
        return this.shareUsersViewState.entries().length === 0;
      }),
      shareUsersTotalCount: computed(() => {
        if (!this.shareUsers) return 0;

        return this.shareUsers.length;
      }),
      shareUsersSelectedCount: computed(() => {
        if (!this.shareUsersViewState) return 0;

        const selected = this.shareUsersViewState
          .entries()
          .filter((entry) => entry[1] === true);
        return selected.length;
      }),
    });

    this.showRenameTagModal = action(this.showRenameTagModal.bind(this));
    this.renameTag = action(this.renameTag.bind(this));
    this.onNewTagNameChange = action(this.onNewTagNameChange.bind(this));

    this.getShareUsers = action(this.getShareUsers.bind(this));
    this.shareUsersOnSelectAll = action(this.shareUsersOnSelectAll.bind(this));
    this.shareUsersOnDeselectAll = action(this.shareUsersOnDeselectAll.bind(this));
    this.setSharedUserSelectedValue = action(this.setSharedUserSelectedValue.bind(this));
    this.shareUsersOnInstantSearch = action(this.shareUsersOnInstantSearch.bind(this));
    this.share = action(this.share.bind(this));
    this.performShare = action(this.performShare.bind(this));
    this.deleteTags = action(this.deleteTags.bind(this));
  }

  showRenameTagModal() {
    const tag = this.getFirstSelectedTag();
    if (!tag) return;

    this.newTagName = tag.name;
    this.renameTagModal.showModal();
  }

  onNewTagNameChange(e: Event) {
    this.newTagName = e.target.value;
  }

  shareUsersOnInstantSearch(value: string) {
    this.shareUsersInstantSearchValue = value;

    if (!this.shareUsersInstantSearchValue) {
      this.shareUsersView = this.shareUsers;
      return;
    }

    this.shareUsersView = this.shareUsers.filter((user) => {
      const firstName = user.firstName.toLowerCase();
      const lastName = user.lastName.toLowerCase();
      const username = user.username.toLowerCase();
      const query = this.shareUsersInstantSearchValue.toLowerCase();

      // this works because ~ is the binary inverse of a number and ~ -1 = 0
      return (
        ~firstName.indexOf(query) || ~lastName.indexOf(query) || ~username.indexOf(query)
      );
    });
  }

  shareUsersOnSelectAll(e: Object) {
    this.shareUsersView.forEach((user) => {
      this.shareUsersViewState.set(user.userId, true);
      user.selected = true;
    });
  }

  shareUsersOnDeselectAll(e: Object) {
    this.shareUsersView.forEach((user) => {
      this.shareUsersViewState.set(user.userId, false);
      user.selected = false;
    });
  }

  setSharedUserSelectedValue(user: Object) {
    const oldSelectedValue = this.shareUsersViewState.get(user.userId);

    if (oldSelectedValue) {
      this.shareUsersViewState.set(user.userId, !oldSelectedValue);
      user.selected = !oldSelectedValue;
    } else {
      this.shareUsersViewState.set(user.userId, true);
      user.selected = true;
    }
  }

  share() {
    this.shareUsersInstantSearchValue = "";
    this.getShareUsers();
    this.shareModal.showModal();
  }

  async renameTag() {
    if (this.network.loading) {
      return;
    }

    this.messaging.removeAll();

    if (!this.newTagName.trim()) {
      this.messaging.createMessage("info", "Please enter a New Tag Name.");
      return;
    }

    const selectedTags = this.getSelectedTags();
    if (selectedTags.length !== 1) {
      console.error("One and only one Tag must be selected");
      return;
    }

    const query = `
    mutation updateTag($tagId: Int!, $name: String!){
      updateTag(input: {name: $name, tagId: $tagId}){
        tag{
          tagId,
          name,
          created,
          modified
        }
        errors{
          __typename
          ...on TagNameEmptyError{
            message
          }
          ...on TagNameAlreadyExistError{
            message
          }
          ...on TagDoesNotExistsError{
            message
          }
        }
       }
      }
    `;
    const variables = {
      tagId: selectedTags[0],
      name: this.newTagName,
    };

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Renaming Rate Card", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    runInAction("renameTag--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("editTagName", res)) {
        // TODO: Display user friendly error message
        return;
      }

      if (res.data.updateTag.errors) {
        this.messaging.removeAll();
        this.messaging.createMessage("info", res.data.updateTag.errors[0].message);
        return;
      }

      this.renameTagModal.hideModal();
      this.handleStopEdit();
      this.pagination.goFetch(null);
    });
  }

  async getShareUsers() {
    const tag = this.getFirstSelectedTag();
    let excludeCurrentUser = [this.currentUser.userId];
    if (!tag) {
      console.error("One and only one Rate Card must be selected");
      return;
    }

    const query = `
    query authorList {
      viewer {
        authors(order:[{field: FIRST_NAME}, {field: LAST_NAME}], filters:{exclude:${excludeCurrentUser}}) {
          edges {
            node {
              userId
              firstName
              lastName
              username
              email
            }
          }
        }
      }
    }
    `;

    const variables = {
      tagId: tag.tagId,
    };

    this.networkShareUsers.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.networkShareUsers.handleError("Getting Users for sharing", e);
      // TODO: Display user friendly error message
      return;
    }

    runInAction("getShareUsers--success", () => {
      this.networkShareUsers.loading = false;
      this.networkShareUsers.error = null;
      if (this.networkShareUsers.logGraphQLError("Filter criteria query", res)) {
        // TODO: Display user friendly error message
        // 'There was an error retrieving the data for this page. Please reload and try again later.',
        return;
      }

      // $FlowFixMe: Type the res value once we abstract gql calls.
      const users = res.data.viewer.authors.edges;
      const selectedTags = this.getSelectedTags();
      let currentlySharedUsers = [];
      this.tags.forEach((item) => {
        if (selectedTags[0] === item.tagId) {
          currentlySharedUsers = item.sharedWith;
        }
      });

      this.networkShareUsers.loading = false;
      this.networkShareUsers.error = null;

      // set as selected the users that are currently shared with
      this.shareUsersViewState = observable.map({});
      currentlySharedUsers.forEach((edge) => {
        this.shareUsersViewState.set(edge.userId, true);
      });

      // TODO: Deserialize this properly...
      this.shareUsers = users.map((edge) => {
        const user = new User(edge.node);

        if (!user.firstName) user.firstName = "";
        if (!user.lastName) user.lastName = "";

        if (this.shareUsersViewState.has(user.userId)) {
          user.selected = this.shareUsersViewState.get(user.userId);
        } else {
          user.selected = false;
        }

        return user;
      });

      this.shareUsersView = this.shareUsers;
    });
  }

  async performShare() {
    if (this.network.loading) {
      return;
    }
    const selectedUserIds = this.shareUsersViewState
      .entries()
      .filter((entry) => entry[1] === true)
      .map((entry) => parseInt(entry[0], 10));

    const query = `
      mutation shareTags($userIds: [Int]!, $tagId: Int!) {
        shareTag(input: { tagId:$tagId, userIds: $userIds }) {
           shareTags {
             user{
               firstName,
               lastName,
               username
             },
            tag{
              name
            }
           }
         }
      }
    `;

    const variables = {
      userIds: selectedUserIds,
      tagId: this.getSelectedTags()[0],
    };

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Sharing Rate Card", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    this.shareModal.hideModal();
    this.handleStopEdit();

    runInAction("performShare--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("shareRateCard", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.pagination.goFetch(null);
    });
  }

  toDateRange(date) {
    var val = true;
    if (this.createdOnFilter.fromDate) {
      val = !date.isBetween(
        this.createdOnFilter.fromDate,
        moment(new Date()),
        "days",
        "[]"
      );
    }
    return val;
  }

  async deleteTags() {
    if (this.network.loading) {
      return;
    }

    let params = [];
    let args = [];
    let queryArgs = [];
    let vars = {};

    consolidateAppliedFilters(this.appliedFilters, params, queryArgs, vars);

    if (this.allSelected) {
      params.push("$exclude: [Int]");
      args.push("exclude: $exclude");
      vars.exclude = []; // no exclusions means all will be processed if there are no other filters

      // include current filters
    } else {
      const selectedTags = this.getSelectedTags();
      if (!selectedTags || !selectedTags.length) {
        console.error("Cannot delete Tags: No Tags selected");
        return;
      }
      params.push("$only: [Int]");
      args.push("only: $only");
      vars.only = selectedTags;
    }

    const query = `
    mutation deleteTags(${params}){
      deleteTags(input: {${args},filters: { ${queryArgs}}}){
      ok
      }
      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Deleting selected Rate Cards", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    this.handleStopEdit();

    runInAction("deleteTags--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("deleteTag", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.pagination.goFetch(null);
    });
  }
}
