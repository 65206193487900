import { styled } from "../../stitches.config";

const Center = styled("div", {
  display: "grid",
  placeItems: "center",
});

Center.displayName = "Center";

export default Center;
