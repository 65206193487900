import React, { SetStateAction } from "react";

import Button from "../../components/lib/Button";
import LinkButton from "../../components/lib/LinkButton";
import Stack from "../../components/lib/Stack";
import Inline from "../../components/lib/Inline";
import HTMLSelect from "../../components/lib/HTMLSelect";
import { useSearchAllocatorContext } from "./context/SearchAllocatorContext";

import { Label, LabelWrapper } from "../../components/lib/Label";
import { ButtonGroup } from "../../components/lib/ButtonGroup";

import type {
  StateFilterType,
  SortByFilterTypeLitteral,
  TargetFilterTypeLitteral,
} from "./filters";

type Props = {
  showHelpModal: () => void;
  showBuyModal: () => void;
  setFilters: (value: SetStateAction<StateFilterType>) => void;
};

export default function (props: Props) {
  const { showHelpModal, showBuyModal, setFilters } = props;

  const { sessionInfo } = useSearchAllocatorContext();

  // handlers

  const handleTargetFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value as TargetFilterTypeLitteral;
    setFilters((prevState) => ({
      ...prevState,
      targetFilter: value,
    }));
  };

  const handleSortByFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value as SortByFilterTypeLitteral;
    setFilters((prevState) => ({
      ...prevState,
      sortBy: value,
    }));
  };

  return (
    <Stack
      fill
      css={{
        alignItems: "flex-end",
      }}
    >
      <ButtonGroup>
        <Button onClick={showHelpModal}>Help</Button>
        <LinkButton
          as="a"
          //@ts-ignore
          download="search-usage-report.xlsx"
          target="_self"
          href={`/api/traps/report?x-session-id=${sessionInfo.legacySession}`}
        >
          Export
        </LinkButton>
        <Button onClick={showBuyModal} color="success">
          Buy More Searches
        </Button>
      </ButtonGroup>

      <Inline fill css={{ flexWrap: "nowrap" }}>
        <LabelWrapper>
          <Label>Sort:</Label>
        </LabelWrapper>
        <HTMLSelect fill onChange={handleSortByFilterChange}>
          <option value="searchesAllocated">Searches Allocated</option>
          <option value="searchesRemainingAsc">Searches Remaining (Ascending)</option>
          <option value="searchesRemainingDesc">Searches Remaining (Descending)</option>
          <option value="searchesUsed">Searches Used</option>
          <option value="usernameAZ">Username (A - Z)</option>
          <option value="usernameZA">Username (Z - A)</option>
        </HTMLSelect>
      </Inline>

      <Inline fill nowrap>
        <LabelWrapper>
          <Label>Users:</Label>
        </LabelWrapper>
        <HTMLSelect fill onChange={handleTargetFilterChange}>
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
          <option value="all">All</option>
        </HTMLSelect>
      </Inline>
    </Stack>
  );
}
