// @ts-ignore
import { styled } from "../../stitches.config";
import React from "react";
import Stack from "../../components/lib/Stack";
import Box from "../../components/lib/Box";

const SectionHead = styled("h4", {
  color: "$brand",
  fontSize: "18px",
});

export function QsPrivacyPolicyDialog(props: any) {
  return (
    <Box css={{ a: { color: "$brandDark !important" }, padding: "30px" }}>
      <Stack css={{ alignItems: "start", padding: "40px", color: "#7a7a7a" }}>
        <SectionHead>Pay Intel Privacy Notice - Magnit, Inc.</SectionHead>
        <p>(Updated Jan 25, 2023)</p>
        <p>
          Magnit, Inc. and Pay Intel (collectively, Magnit) are committed to protecting
          the privacy of our users. We want to provide a safe and secure user experience.
          We endeavor to ensure that the information you submit to us remains private and
          use it only for the purposes as set forth herein. The following sets forth the
          Privacy Notice (“Privacy Notice” or “Notice”) for Magnit Pay Intel websites (
          <a href="https://ratepoint.pro-unlimited.com">
            https://ratepoint.pro-unlimited.com
          </a>
          , <a href="https://app.peopleticker.com">https://app.peopleticker.com</a>,{" "}
          <a href="https://www.ratepointbi.com/RatePoint">
            https://www.ratepointbi.com/RatePoint
          </a>
          ).
        </p>

        <SectionHead>Privacy Policy</SectionHead>

        <p>Magnit adheres to the following: </p>
        <ul>
          <li>
            <p>
              We collect, retain and use user personal data for legitimate business
              purposes only. We limit the personal data we collect to that which we
              believe is appropriate and necessary to manage user needs, provide our
              services and comply with applicable laws.
            </p>
          </li>
          <li>
            <p>We strive to maintain the accuracy of personal data.</p>
          </li>
          <li>
            <p>
              Our employees are required to protect personal data. We have internal
              policies and programs designed to protect personal data.{" "}
            </p>
          </li>
          <li>
            <p>We limit the internal and external disclosure of personal data.</p>
          </li>
        </ul>

        <SectionHead>Categories of Information Collected and Processed</SectionHead>
        <p>
          Magnit may collect personal information about visitors to our websites,
          including usernames and other contact details shared with us when users call,
          write to, or contact us using our websites, requesting information about
          Magnit’s services, such as Pay Intel. Magnit may collect personal information to
          perform a contract with the user, or to implement pre-contractual measures taken
          at the user’s request. For example, to provide access to Pay Intel, the
          following details may be collected for account creation: username, first name,
          last name, e-mail address, phone number, and address.
        </p>

        <SectionHead>The Reasons We Process Your Personal Data</SectionHead>
        <p>
          User personal data is collected and transferred to provide users with access to
          Magnit services. For example, users may create accounts to access Pay Intel
          services. Account creation requires some collection of personal data.
        </p>

        <SectionHead>How We Share Personal Data</SectionHead>
        <p>
          For the purposes described in this Notice, Magnit may disclose the personal data
          of users:
        </p>
        <ul>
          <li>
            <p>
              To any of our related companies (including Magnit, Inc. and its
              subsidiaries);
            </p>
          </li>
          <li>
            <p>
              To government agencies or individuals appointed by a government responsible
              for the investigation and resolution of disputes or complaints; or
            </p>
          </li>
          <li>
            <p>
              To third party contractors who perform services on our behalf, such as
              reviewing and developing our business systems, procedures and infrastructure
              (including testing or upgrading our computer systems);
            </p>
          </li>
          <li>
            <p>
              To comply with our legal obligations, regulations or contracts, or to
              respond to a court order, administrative or judicial process, such as
              subpoena, government audit or search warrant;
            </p>
          </li>
          <li>
            <p>
              To comply with our legal obligations, regulations or contracts, or to
              respond to a court order, administrative or judicial process, such as
              subpoena, government audit or search warrant;
            </p>
          </li>
          <li>
            <p>Otherwise in accordance with your consent.</p>
          </li>
        </ul>

        <p>
          <u>Magnit does not:</u>
        </p>

        <ul>
          <li>
            <p>Use the data collected in any form of automated decision-making.</p>
          </li>
          <li>
            <p>
              Collect or process “special categories of personal data” as that term is
              used in the EU General Data Protection Regulation (GDPR).
            </p>
          </li>
          <li>
            <p>Sell your personal data to any third parties. </p>
          </li>
          <li>
            <p>
              Collect or process personal data of persons who are less than 16 years old.
            </p>
          </li>
        </ul>

        <SectionHead>Security of Your Personal Data</SectionHead>
        <p>
          Magnit is committed to keeping your personal information secure. To help protect
          your personal data from unauthorized access, use, or disclosure, we employ a
          range of security technologies and procedures. For example, we store your
          personal information on computer servers with restricted access that are housed
          in secure facilities, and we encrypt some highly confidential or sensitive
          personal information when we send it.
        </p>

        <SectionHead>Where We Store and Process Data</SectionHead>
        <p>
          Magnit and its clients work on a global scale, so personal data may be
          transmitted to countries other than those where it was obtained. Since we are
          based in the United States, we regularly move information gathered in other
          countries to the United States for processing. We will ensure that the transfer
          of your personal data to another country complies with all relevant laws and
          regulations.{" "}
        </p>

        <SectionHead>Cookies </SectionHead>
        <p>
          To make this site work properly, we sometimes place small data files called
          cookies on your device. Most big websites do this too.
        </p>
        <p>
          <b>What are cookies?</b>
        </p>
        <p>
          A cookie is a small text file that a website saves on your computer or mobile
          device when you visit the site. It enables the website to remember your actions
          and preferences (such as login, language, font size and other display
          preferences) over a period of time, so you don’t have to keep re-entering them
          whenever you come back to the site or browse from one page to another.{" "}
        </p>
        <p>
          <b>How do we use cookies?</b>
        </p>
        <p>
          The cookies that may be used on Pay Intel fall into the categories described
          below. These descriptions can help you determine if and how you would like to
          interact with our websites and other online services.
        </p>

        <p>STRICTLY NECESSARY COOKIES</p>
        <p>
          These cookies are essential in order to enable you to navigate this website and
          use certain features. Without these strictly necessary cookies, the online
          services you have asked for, like shopping baskets or producing your bill at
          checkout, cannot be provided.
        </p>
        <p>PERFORMANCE COOKIES</p>
        <p>
          We (or service providers operating on our behalf) may place our performance
          cookies on your device. The information collected using our performance cookies
          is used by us or for our benefit, to improve our website experience or for other
          statistics gathering purposes. Performance cookies are used to generate
          aggregated, anonymous information about how you and other users use this website
          and its features.
        </p>
        <p>FUNCTIONALITY COOKIES</p>
        <p>
          We (or service providers operating on our behalf) may place our functionality
          cookies on your device. We do not share information collected using our
          functionality cookies with our advertisers or other third parties. Our
          functionality cookies are used to remember choices you make (such as language
          preference, country location, or other online settings) and provide the
          personalized or enhanced features that you select.
        </p>
        <p>
          <b>How to control cookies</b>
        </p>
        <p>
          You can control and/or delete cookies as you wish – for details, see
          www.aboutcookies.org. You can delete all cookies that are already on your
          computer and you can set most browsers to prevent them from being placed. If you
          do this, however, you may have to manually adjust some preferences every time
          you visit a site and some services and functionalities may not work.
        </p>
        <p>
          Magnit does not track its users when they cross to third party websites, and
          does not use targeted advertising, and therefore does not respond to Do Not
          Track signals.
        </p>
        <p>
          Pay Intel sites contain links to other websites. Magnit is not responsible for
          the privacy practices or the content of such other websites. The privacy
          policies applicable to such other websites may differ substantially from this
          Privacy Notice, so we advise users to read them before using those websites.
          Magnit will not be liable for any use of those websites.
        </p>

        <SectionHead>
          For Residents of the European Union, United Kingdom, European Economic Area and
          Switzerland{" "}
        </SectionHead>
        <p>
          With respect to the personal data of individuals in the European Union, United
          Kingdom, European Economic Area and Switzerland, please see <b>Appendix A</b>{" "}
          below.{" "}
        </p>

        <SectionHead>For Residents of California</SectionHead>
        <p>
          With respect to the personal data of California residents, please see{" "}
          <b>Appendix B</b> below.{" "}
        </p>

        <SectionHead>Our Retention of Personal Data</SectionHead>
        <p>
          Personal data will be stored according to the applicable laws or regulatory
          requirements and kept as long as is necessary to fulfill the purposes for which
          it was collected. Generally, this means that your personal data will be retained
          as documented in our corporate data retention schedule, contracts, and
          applicable supplemental documents.
        </p>

        <SectionHead>Updates and Changes to this Privacy Notice</SectionHead>
        <p>
          We will update this document if our privacy policies change so that you are
          aware of what information we gather, how it is used, and under what conditions
          it may be revealed. Magnit reserves the right to alter, amend, or adjust this
          Notice at any time and without notice. This Notice is effective as of the date
          that appears at the top of this document. In the event of any questions about
          our privacy policies or related matters, please contact us at{" "}
          <a href="mailto:privacy@prounlimited.com">privacy@prounlimited.com</a>.
        </p>

        <SectionHead>
          <span style={{ color: "#7a7a7a" }}>Appendix A:</span> For Residents of the
          European Union, United Kingdom, European Economic Area and Switzerland{" "}
        </SectionHead>
        <p>
          With respect to the personal data of individuals in the European Union, United
          Kingdom, European Economic Area and Switzerland, Magnit has contracts with
          Standards Contractual Clauses in place with clients, staffing suppliers and
          others that serve as the mechanism for the lawful transfer of personal data from
          those regions to other countries such as the United States.{" "}
        </p>
        <p>
          Magnit also complies with the EU-US Privacy Shield Framework and Swiss-US
          Privacy Shield Framework as set forth by the U.S. Department of Commerce
          regarding the collection, use, and retention of personal information transferred
          from the European Union, the United Kingdom, and/or Switzerland, respectively,
          to the United States. However, Magnit does not rely on the EU-U.S. Privacy
          Shield Framework as a legal basis for transfers of personal data in light of the
          judgment of the EU Court of Justice in Case C-311/18. Magnit has certified to
          the Department of Commerce that it adheres to the Privacy Shield Principles. To
          learn more about the Privacy Shield program, and to view our certification,
          please visit{" "}
          <a href="https://www.privacyshield.gov/">https://www.privacyshield.gov/</a>.{" "}
        </p>

        <ul style={{ listStyleType: "none" }}>
          <li>
            <u>
              <p>Recourse, Enforcement and Liability</p>
            </u>
          </li>
        </ul>

        <p>
          Magnit is subject to the authority of the United States Federal Trade
          Commission, which has the right to enforce Magnit compliance with the Privacy
          Shield Programs.{" "}
        </p>

        <p>
          <ol type="a">
            <li>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <i>EU- US Privacy Shield:</i> Magnit has further committed to cooperating
                with EU and UK data protection authorities (“DPAs”) with regard to
                unresolved EU- US Privacy Shield complaints concerning personal data
                transferred from the EU as described below. If a data subject does not
                receive timely acknowledgment from us of their complaint, or if we have
                not addressed the complaint to their satisfaction, they should contact the
                EU or UK DPAs (as applicable) for more information or to file a complaint.
                The services of the EU and UK DPAs are provided at no cost to data
                subjects.
              </p>
            </li>
            <li>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <i>Swiss- US Privacy Shield:</i> Magnit is also committed to cooperating
                with the Swiss Federal Data Protection and Information Commissioner. When
                covering non-HR data received from Switzerland, Magnit is committed to
                cooperating with and complying with the advice of the Commissioner.
              </p>
            </li>
            <li>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Magnit
                is committed to cooperating with the EU and UK DPAs and the Swiss Federal
                Data Protection and Information Commissioner (FDPIC) and complying with
                the advice given by such authorities with regard to human resources data
                transferred from the EU, UK and/or Switzerland in the context of the
                employment relationship.
              </p>
            </li>
            <li>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In
                compliance with Privacy Shield Principles, Magnit commits to resolve
                complaints about our collection or use of your personal information. EU,
                UK and Swiss individuals with inquiries or complaints regarding our
                Privacy Shield policy should first contact Magnit at:
              </p>
            </li>
            <li style={{ listStyleType: "none" }}>
              <ul style={{ listStyleType: "none" }}>
                <li>
                  <p>
                    Magnit, Inc. <br></br>
                    Email:{" "}
                    <a href="mailto:privacy@prounlimited.com">
                      privacy@prounlimited.com
                    </a>{" "}
                    <br></br>
                    1150 Iron Point Road, Ste. 100 <br></br>Folsom, CA 95630 <br></br>
                    United States
                  </p>
                </li>
              </ul>
            </li>

            <li style={{ listStyleType: "none" }}>
              <p>
                Our EU representative for GDPR compliance purposes may be contacted at:
              </p>
              <ul style={{ listStyleType: "none" }}>
                <li>
                  <p>
                    Magnit Global Netherlands BV<br></br>
                    Email:{" "}
                    <a href="mailto:privacy@prounlimited.com">
                      privacy@prounlimited.com
                    </a>{" "}
                    <br></br>
                    Kranenburgweg 135-A, 2583 ER <br></br>
                    The Hague<br></br> Netherlands
                  </p>
                </li>
              </ul>
            </li>
            <li style={{ listStyleType: "none" }}>
              <p>
                If you do not receive timely acknowledgement of your complaint from us, or
                if we have not addressed your complaint to your satisfaction, you can at
                any time lodge a complaint with the data protection authority of the EEA
                country where you live or with the data protection authority of the
                country or state where the Magnit controller that collected your personal
                data has its registered seat. A list of the national data protection
                authorities can be found here:
                <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
                  http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                </a>
              </p>
            </li>
            <li style={{ listStyleType: "none" }}>
              <p>
                Under certain conditions, you may invoke binding arbitration for
                complaints regarding Privacy Shield compliance that are not resolved by
                following the above complaint procedures. More information on binding
                arbitration can be found here:{" "}
                <a href="https://www.privacyshield.gov/article?id=ANNEX-I-introduction">
                  https://www.privacyshield.gov/article?id=ANNEX-I-introduction
                </a>
              </p>
            </li>
          </ol>
        </p>

        <ul style={{ listStyleType: "none" }}>
          <li>
            <u>
              <p>Your Data Subject Rights</p>
            </u>
          </li>
        </ul>

        <p>
          EU and UK users may have certain rights under applicable data protection laws,
          including the EU GDPR and local legal implementation of that Regulation. This
          includes the rights to:
        </p>

        <ul>
          <li>
            <p>Make a request for access to and a copy of your personal information.;</p>
          </li>
          <li>
            <p>
              Request the rectification (or correction) of any incorrect personal data;
            </p>
          </li>
          <li>
            <p>
              Request the erasure (or deletion) of personal data that is no longer needed
              to serve the purposes for which it was obtained, or that Magnit does not
              need to keep for other legitimate purposes.
            </p>
          </li>
          <li>
            <p>Restrict or object to your personal data being processed.; and</p>
          </li>
          <li>
            <p>
              Request that your personal data be ported (transferred) to another
              organization.
            </p>
          </li>
        </ul>
        <p>
          These rights can be applied differently depending on the type of data involved
          and Magnit's specific legal justification for processing personal data.
        </p>
        <p>
          Please contact{" "}
          <a href="mailto:privacy@prounlimited.com">privacy@prounlimited.com</a> if you
          want to exercise one of the above privileges. Any requests will be considered
          and responded to in compliance with relevant data privacy laws. Please be aware
          that we may ask you for detailed details in order to verify your identity and
          right of access, as well as to search for and provide you with the personal data
          we have about you. We may charge you a fair fee to access your personal data in
          limited circumstances; however, we will notify you of any fees in advance.
        </p>
        <p>
          You have the right to revoke your consent at any time if we are depending on
          your consent to process your personal data. Please notice, however, that the
          lawfulness of the processing prior to the revocation of your consent will not be
          affected.{" "}
        </p>
        <p>
          EU and UK users can also contact the Data Protection Officer at the following
          address with questions about how we handle personal data:
        </p>
        <p>
          <ul style={{ listStyleType: "none" }}>
            <li>
              <p>
                Magnit, Inc. <br></br>
                Email:{" "}
                <a href="mailto:privacy@prounlimited.com">
                  privacy@prounlimited.com
                </a>{" "}
                <br></br>
                1150 Iron Point Road, Ste. 100 <br></br>Folsom, CA 95630 <br></br>
                United States
              </p>
            </li>
          </ul>
        </p>

        <SectionHead>
          <span style={{ color: "#7a7a7a" }}>Appendix B:</span> For California Residents{" "}
        </SectionHead>
        <p>
          The main body of this Privacy Notice provides information to California
          residents whose personal information is processed pursuant to the California
          Consumer Privacy Act of 2018 (“CCPA”). For purposes of this Privacy Notice, the
          term “personal data” has the same meaning as the term “personal information” is
          defined in the CCPA. This Privacy Notice:
        </p>

        <ul>
          <li>
            <p>
              Describes the categories of personal information Magnit collects from you
              (see “Categories of Information Collected and Processed”; and{" "}
            </p>
          </li>
          <li>
            <p>
              Describes the purposes for which that information is collected (see “Why We
              Process Personal Data” and “How and Why We Share Personal Data” above).{" "}
            </p>
          </li>
          <p>
            If you have questions about your personal information or our CCPA compliance
            program, please contact us:
          </p>
          <p>
            <ul style={{ listStyleType: "none" }}>
              <li>
                <p>
                  Magnit, Inc. <br></br>
                  Email:{" "}
                  <a href="mailto:privacy@prounlimited.com">
                    privacy@prounlimited.com
                  </a>{" "}
                  <br></br>
                  1150 Iron Point Road, Ste. 100 <br></br>Folsom, CA 95630 <br></br>
                  United States
                </p>
              </li>
            </ul>
          </p>
        </ul>
      </Stack>
    </Box>
  );
}

export default QsPrivacyPolicyDialog;
