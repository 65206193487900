// @flow
import { extendObservable, action } from "mobx";
import FilterObject, { ValueSetFilter, FilterCriteriaLoader, IViewItem } from "../Filter";
import type { FilterColumn } from "../Filter";
import { GraphQLQuery } from "../GraphQL";

class StatusItem implements IViewItem {
  id: string;
  value: number;
  name: string;
  verboseName: string;
  selected: boolean;

  constructor(object: Object) {
    this.id = object.value;
    this.value = object.value;
    this.name = object.name;
    this.verboseName = object.verboseName;

    extendObservable(this, {
      selected: object.selected,
    });
  }
}

export default class JobLibraryTitleRequestOnlyStatusFilter extends FilterCriteriaLoader(
  ValueSetFilter()
) {
  buildQueryFilter: () => FilterObject;
  createFilterObject: (selectedStatusValues: number[]) => FilterObject;
  onInstantSearch: (string) => void;

  buildQueryFilter: () => ?FilterObject;
  onApply: () => void;
  onReset: () => void;
  softReset: () => void;
  softResetSort: () => void;

  constructor(
    store: Object,
    column: FilterColumn,
    filterCriteriaQuery: (FilterColumn) => GraphQLQuery,
    processPayload: (FilterColumn, Object) => Array<Object>,
    applyFilter: (FilterColumn, FilterObject) => void,
    removeFilter: (FilterColumn) => void,
    reloadCriteria: boolean = false
  ) {
    super(store, column);

    // this.api = new TasteApi(store.fluxStore);

    this.filterCriteriaQuery = filterCriteriaQuery;
    this.processPayload = processPayload;
    this.applyFilter = applyFilter;
    this.removeFilter = removeFilter;

    this.reloadCriteria = reloadCriteria;

    this.viewItemClass = StatusItem;

    this.buildQueryFilter = action(this.buildQueryFilter.bind(this));
    this.createFilterObject = action(this.createFilterObject.bind(this));
    this.onInstantSearch = action(this.onInstantSearch.bind(this));
  }

  onInstantSearch(value: string) {
    if (super.onInstantSearch) super.onInstantSearch(value);

    if (!this.instantSearchValue) {
      this.viewItems = this.unfilteredViewItems;
      return;
    }

    this.viewItems = this.unfilteredViewItems.filter((library) => {
      const verboseName = library.verboseName.toLowerCase();
      const query = this.instantSearchValue.toLowerCase();

      // this works because ~ is the binary inverse of a number and ~ -1 = 0
      return ~verboseName.indexOf(query);
    });
  }

  createFilterObject(selectedStatusValues: number[]) {
    return new FilterObject(
      "$selectedStatusValues: [Int]!",
      "statusIn: $selectedStatusValues",
      {
        selectedStatusValues: selectedStatusValues,
      }
    );
  }

  buildQueryFilter() {
    const selectedStatusValues = this.selectedValues
      .entries()
      .filter((entry) => entry[1] === true)
      .map((entry) => this.criteria.get(entry[0]).value);

    if (!selectedStatusValues.length) {
      return null;
    }

    return this.createFilterObject(selectedStatusValues);
  }
}
