/**
 * @generated SignedSource<<1dbd111b23fb3f4315b198307639c516>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MedianPayChart_savedsearch$data = {
  readonly currency: {
    readonly id: string;
    readonly iso: string;
  } | null;
  readonly id: string;
  readonly marketrates: ReadonlyArray<{
    readonly billRateMax: number | null;
    readonly billRateMid: number | null;
    readonly billRateMin: number | null;
    readonly id: string;
    readonly level: {
      readonly legacyId: number | null;
      readonly value: string | null;
    } | null;
    readonly markupPctMax: number | null;
    readonly markupPctMid: number | null;
    readonly markupPctMin: number | null;
    readonly payRateMax: number | null;
    readonly payRateMid: number | null;
    readonly payRateMin: number | null;
  } | null> | null;
  readonly rateType: number;
  readonly " $fragmentType": "MedianPayChart_savedsearch";
} | null;
export type MedianPayChart_savedsearch$key = {
  readonly " $data"?: MedianPayChart_savedsearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"MedianPayChart_savedsearch">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MedianPayChart_savedsearch",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rateType",
        "storageKey": null
      },
      "action": "NONE",
      "path": "rateType"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CurrencyNode",
      "kind": "LinkedField",
      "name": "currency",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "iso",
            "storageKey": null
          },
          "action": "NONE",
          "path": "currency.iso"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MarketRatesNode",
      "kind": "LinkedField",
      "name": "marketrates",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "LevelType",
          "kind": "LinkedField",
          "name": "level",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "legacyId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "payRateMin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "payRateMid",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "payRateMax",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "billRateMin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "billRateMid",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "billRateMax",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "markupPctMin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "markupPctMid",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "markupPctMax",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SaveSearchNode",
  "abstractKey": null
};
})();

(node as any).hash = "5f47377e8b1edb282528a4e8edc6e399";

export default node;
