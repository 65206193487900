import React, { useCallback, useState } from "react";

import Button, { ButtonProps } from "./Button";
import { useIsMountedRef } from "../../utils/hooks";

export interface PromiseButtonProps extends ButtonProps {
  buttonImpl?: typeof Button;
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement>) => Promise<any>)
    | ButtonProps["onClick"];
}

function PromiseButton(props: PromiseButtonProps) {
  const { buttonImpl, onClick, ...rest } = props;
  const ButtonImpl: React.ComponentType<ButtonProps> = buttonImpl || Button;

  const [showLoading, setShowLoading] = useState(false);
  const isMountedRef = useIsMountedRef();

  const handleOnClick = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>): Promise<any> => {
      if (onClick == null) return;

      setShowLoading(true);
      try {
        return await onClick(event);
      } finally {
        if (isMountedRef.current) {
          setShowLoading(false);
        }
      }
    },
    [onClick, isMountedRef]
  );

  return (
    <ButtonImpl {...rest} loading={showLoading} onClick={handleOnClick}>
      {props.children}
    </ButtonImpl>
  );
}

export default PromiseButton;
