import React, { useCallback } from "react";
import { useRecoilValue } from "recoil";

// @ts-expect-error
import csvTemplateHref from "../../../assets/templates/upload_rate_card_admin_template.csv";
// @ts-expect-error
import excelTemplateHref from "../../../assets/templates/upload_rate_card_admin_template.xlsx";

import Box from "../../../components/lib/Box";
import Stack from "../../../components/lib/Stack";
import Alert from "../../../components/lib/Alert";
import { NavigationButton } from "../../../components/lib/Button";
import { ButtonGroupRight } from "../../../components/lib/ButtonGroup";
import {
  Card,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
  CardHeaderTitle,
} from "../../../components/lib/Card";
import RecentUploadsBlock from "../components/RecentUploadsBlock";
import ContractorsUploadFormBlock, {
  FILE_UPLOADING_STATES,
} from "../components/ContractorsUploadFormBlock";
import { InstructionsBlock, TemplatesBlock } from "./RegularUploadPage";
import {
  contractorsUploadFormGlobalState,
  contractorsUploadsListGlobalState,
} from "../globalState";

import type { ContractorsUploadDataMap } from "../types";
import type { CommonProgramChildPageProps } from "../ProgramDataProvider";

const fileSelectTooltipText = (
  <Box css={{ fontSize: "$sm" }}>
    <div>
      <label>
        <u>File Requirements:</u>
      </label>
      <ul>
        <li>Supported file formats: csv, xlsx</li>
        <li>Maximum file size: 10 megabytes</li>
      </ul>
    </div>

    <div>
      <label>
        <u>Required Data:</u>
      </label>
      <ul>
        <li>Admins may omit rates columns.</li>
        <li>Required columns are marked with an asterisk in the templates.</li>
        <li>
          <span>These columns are always required:</span>
          <ul>
            <li>
              <b>
                <i>Effective Date</i>
              </b>
            </li>
            <li>
              <b>
                <i>Job Label</i>
              </b>
            </li>
          </ul>
        </li>
        <li>
          <span>Following columns have some special rules for usages:</span>
          <ul>
            <li>
              One single location type per row - Regular Location (
              <b>
                <i>Country/State/City</i>
              </b>
              ) or{" "}
              <b>
                <i>Region</i>
              </b>
              .
            </li>
            <li>
              One rate type per row - "Hourly" or "Annual" – do not include the two types
              together in the same row.
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <div>
      <label>
        <u>Value Constraints:</u>
      </label>
      <ul>
        <li>
          "Expertise Level" values must be: Junior, Intermediate, Senior, Lead, Guru, 1,
          2, 3, 4, 5, I, II, III, IV, V.
        </li>
        <li>
          "Effective Date" value formats must be: MM/DD/YYYY, MM-DD-YYYY, MM.DD.YYYY.
        </li>
        <li>
          Supported "Global Supplier Search" flag values: t, T, true, True, TRUE, 1, f, F,
          false, False, FALSE, 0
        </li>
      </ul>
    </div>

    <div>
      <label>
        <u>Automatic Calculations:</u>
      </label>
      <ul>
        <li>
          Any hourly rate value (Pay Rate/Markup/Bill Rate) will be calculated if other
          two are provided (example: calculate "Bill Rate" value based on the provided
          "Pay Rate" and "Markup" values).
        </li>
        <li>
          "Total Cost" can be calculated in two ways, based on provided Bill Rate value
          (assuming 2000 working hours per year) or based on "Annual Salary" and "Burden
          %".
        </li>
      </ul>
    </div>

    <div>
      <label>
        <u>Hidden columns:</u>
      </label>
      <ul>
        <li>
          "Source" column can be specified to rank job titles matching the given source
          name higher on an upload (text value).
        </li>
        <li>
          "Industry" column can be specified to override the provided industry value in
          the Upload Form (text value).
        </li>
        <li>
          "Expertise Level" column can be specified to force particular row to be mapped
          to that level when calculating savings.
        </li>
        <li>
          "Global Supplier Search" column can be specified to make a row get searched as a
          global supplier search.
        </li>
        <li>
          "Job Family" column can be specified to adjust mapping algorithm to match titles
          against some specific set of collections gathered into a family (text value).
        </li>
        <li>
          "Map To Collection" column can be specified to force a particular row to be
          mapped to some specific collection (collection ID value).
        </li>
      </ul>
    </div>

    <span>
      <b>
        <u>Important:</u>
      </b>{" "}
      Empty columns and rows won't be taken into account during upload.
    </span>
  </Box>
);

type NoPermissionsBlockProps = {
  onGoToProgramDetails: () => void;
};

const NoPermissionsBlock = (props: NoPermissionsBlockProps) => {
  const { onGoToProgramDetails } = props;

  return (
    <Alert color="warning">
      <Stack fill css={{ alignItems: "start" }}>
        <h4>You have no permissions to access this page </h4>
        <NavigationButton
          icon="arrow-left"
          color="warning"
          onClick={onGoToProgramDetails}
        >
          Back to Index Page
        </NavigationButton>
      </Stack>
    </Alert>
  );
};
NoPermissionsBlock.displayName = "NoPermissionsBlock";

type PageHeadBlockProps = {
  disableButtons: boolean;
  onGoToCreateIndex: () => void;
  onGoToProgramDetails: () => void;
};

const PageHeadBlock = (props: PageHeadBlockProps) => {
  const { disableButtons, onGoToCreateIndex, onGoToProgramDetails } = props;

  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft>
          <CardHeaderTitle>Index Data Upload</CardHeaderTitle>
        </CardActionsLeft>
        <CardActionsRight>
          <ButtonGroupRight>
            <NavigationButton
              icon="plus"
              onClick={!disableButtons ? onGoToCreateIndex : undefined}
              disabled={disableButtons}
            >
              Create View
            </NavigationButton>
            <NavigationButton
              icon="arrow-left"
              onClick={!disableButtons ? onGoToProgramDetails : undefined}
              disabled={disableButtons}
            >
              Back to Index Page
            </NavigationButton>
          </ButtonGroupRight>
        </CardActionsRight>
      </CardActions>
    </Card>
  );
};
PageHeadBlock.displayName = "PageHeadBlock";

type PageBottomBlockProps = PageHeadBlockProps;

const PageBottomBlock = (props: PageBottomBlockProps) => {
  const { disableButtons, onGoToCreateIndex, onGoToProgramDetails } = props;

  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft />
        <CardActionsRight>
          <NavigationButton
            icon="plus"
            onClick={!disableButtons ? onGoToCreateIndex : undefined}
            disabled={disableButtons}
          >
            Create View
          </NavigationButton>
          <NavigationButton
            icon="arrow-left"
            onClick={!disableButtons ? onGoToProgramDetails : undefined}
            disabled={disableButtons}
          >
            Back to Index Page
          </NavigationButton>
        </CardActionsRight>
      </CardActions>
    </Card>
  );
};
PageBottomBlock.displayName = "PageBottomBlock";

const AdminUploadPage = (props: CommonProgramChildPageProps) => {
  const { store, router, isPTAdmin, programId, showModalSuccess, hideModal } = props;

  const { uploadingState } = useRecoilValue(contractorsUploadFormGlobalState);
  const { data: uploadsList } = useRecoilValue(contractorsUploadsListGlobalState);

  const [uploadsRefreshRequestId, setUploadsRefreshRequestId] = React.useState<number>();
  const refreshUploadsData = useCallback(
    () => setUploadsRefreshRequestId(new Date().getTime()),
    [setUploadsRefreshRequestId]
  );

  const disableButtons = uploadingState === FILE_UPLOADING_STATES.PROCESSING;

  // handlers

  const handleGoToProgramDetails = useCallback(
    () => router.push(`/private-index/programs/${programId}`),
    [router, programId]
  );

  const handleGoToCreateIndex = useCallback(
    () => router.push(`/private-index/programs/${programId}/indexes/create`),
    [router, programId]
  );

  const handleFileUploaded = useCallback(
    async (uploadData: ContractorsUploadDataMap) => {
      const datasetLength = uploadData.get("dataset_length") || 0;

      refreshUploadsData();
      await hideModal();

      if (datasetLength) {
        showModalSuccess(
          <span>{`${datasetLength} contractors rows successfully uploaded and will be processed shortly. `}</span>
        );
      }
    },
    [refreshUploadsData, showModalSuccess, hideModal]
  );

  if (!isPTAdmin)
    return <NoPermissionsBlock onGoToProgramDetails={handleGoToProgramDetails} />;

  return (
    <Stack>
      <PageHeadBlock
        disableButtons={disableButtons}
        onGoToCreateIndex={handleGoToCreateIndex}
        onGoToProgramDetails={handleGoToProgramDetails}
      />
      <InstructionsBlock />
      <TemplatesBlock
        excelTemplateHref={excelTemplateHref}
        csvTemplateHref={csvTemplateHref}
      />
      <ContractorsUploadFormBlock
        store={store}
        programId={programId}
        fileSelectTooltipText={fileSelectTooltipText}
        onFileUploaded={handleFileUploaded}
      />
      <RecentUploadsBlock
        isPTAdmin={isPTAdmin}
        programId={programId}
        refreshRequestId={uploadsRefreshRequestId}
      />
      {uploadsList?.size >= 20 && (
        <PageBottomBlock
          disableButtons={disableButtons}
          onGoToCreateIndex={handleGoToCreateIndex}
          onGoToProgramDetails={handleGoToProgramDetails}
        />
      )}
    </Stack>
  );
};
AdminUploadPage.displayName = "AdminUploadPage";

export default AdminUploadPage;
