// @flow

import React from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HourlyPunchOutLevelRow from "./HourlyPunchOutLevelRow";
import { getPunchOutRowStates } from "./SupportFunctions";
import BuyRateState from "../../../models/BuyRateState";
import PunchOutRowState from "../../../models/PunchOutRowState";
import Tooltip from "../../../components/lib/Tooltip";

const HourlyPunchOutsTableView = (props: { buyRateState: BuyRateState }) => {
  const fetchGraphQL = props.buyRateState.fetchGraphQL;
  const search = props.buyRateState.search;
  const buyRate = props.buyRateState.buyRate;
  if (!buyRate) return <code>Unable to render</code>;

  const punchOutRows = buyRate.punchOutRows;

  const { rowStates, blanks, addRowState } = getPunchOutRowStates(
    punchOutRows,
    fetchGraphQL,
    search,
    props
  );

  return (
    <table className="table buy-rate-level-data">
      <thead>
        <tr>
          <th />
          <th className="hidden-md hidden-lg">Rates</th>
          <th className="hidden-xs hidden-sm" />
          <th
            className="hidden-xs hidden-sm text-right fixed-size"
            data-testid="min-pay-header"
          >
            Min Pay
          </th>
          <th
            className="hidden-xs hidden-sm text-right fixed-size"
            data-testid="max-pay-header"
          >
            Max Pay
          </th>
          <th
            className="hidden-xs hidden-sm text-right fixed-size"
            data-testid="markup-header"
          >
            Markup{" "}
            <Tooltip side="top" content="System Default used: Max Markup">
              <span>
                <FontAwesomeIcon icon="info-circle" style={{ marginLeft: 5 }} />
              </span>
            </Tooltip>
          </th>
          <th
            className="hidden-xs hidden-sm text-right fixed-size"
            data-testid="min-bill-header"
          >
            Min Bill
          </th>
          <th
            className="hidden-xs hidden-sm nte-column text-right fixed-size"
            data-testid="nte-bill-header"
          >
            NTE Bill
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        {rowStates.map((rowState: PunchOutRowState, index: number) => (
          <HourlyPunchOutLevelRow key={index} modalState={rowState} />
        ))}
        {addRowState && <HourlyPunchOutLevelRow modalState={addRowState} />}
        {blanks.map((item, index) => (
          <tr key={index}>
            <td colSpan="8" className="buy-rate-level-place-holder" />
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default observer(HourlyPunchOutsTableView);
