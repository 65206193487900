import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckBox from "../../shared/CheckBox";
import classNames from "classnames";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Inline from "../../../components/lib/Inline";
import LinkButton from "../../../components/lib/LinkButton";
import { styled } from "../../../../src/stitches.config";

const RateCardListItem = styled("div", {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  cursor: "pointer",
  margin: "0",
  padding: "20px",
  transition: "all 200ms",
  "&:hover": {
    backgroundColor: "$brandLightest",
  },
});

const RateCardMeta = styled("div", {
  minWidth: "0",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontSize: "16px",
  lineHeight: "18px",
  paddingBottom: "2px",
  marginTop: "-2px",
  display: "flex",
  gap: "$1",
});

const RateCardLabel = styled("div", {
  color: "$brand",
  fontSize: "24px",
  transition: "all 200ms",
  marginBottom: "2px",
  padding: "2px 0",
  lineHeight: "1.2",
  minWidth: "0",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "inline-block",
  maxWidth: "100%",
});

const TagManagementListItem = observer(
  class extends Component {
    static contextTypes = {
      router: PropTypes.object,
    };

    constructor(props) {
      super(props);

      this.handleViewDetail = this.handleViewDetail.bind(this);
    }

    handleViewDetail() {
      this.context.router.push({
        pathname: `/tag/${this.props.tag.tagId}`,
        query: this.context.router.query,
      });
    }

    render() {
      const props = this.props;

      const tag = props.tag;

      const editing = tag.viewState.editing;
      const selected = tag.viewState.selected;
      const name = tag.name;
      const createdDisplay = tag.createdDate.format("MMMM D, YYYY");
      // const searches = rateCard.searches;
      // const locations = rateCard.locations;
      const shared = tag.shared;
      const id = tag.tagId;
      // const searchCount = rateCard.searchCount;
      // const locationCount = rateCard.locationCount;
      let ownerName = "You";
      let sharedByYou = shared;
      if (String(tag.owner.clientId) !== String(props.store.currentUser.userId)) {
        ownerName = tag.owner.fullname;
        sharedByYou = false;
      }
      if (tag.owner.email !== localStorage.getItem("PeopleTickerUserEmail")) {
        ownerName = tag.owner.fullname;
      }

      let onItemClick = this.props.onClick || this.handleViewDetail;
      let editingSection = null;

      if (editing) {
        onItemClick = tag.toggleSelected;
        editingSection = (
          <CheckBox
            type={props.store.allowMultipleItemSelection ? "checkbox" : "radio"}
            name="list-item-select"
            value={name}
            selected={selected}
            style={{ width: 34 }}
            onClick={onItemClick}
          />
        );
      }

      const sharedTooltip = <Tooltip id="shared-icon-tooltip">Shared...</Tooltip>;

      return (
        <div className="ratecard-list-item-container">
          <RateCardListItem
            className={classNames("none", { selected: selected })}
            onClick={onItemClick}
          >
            <div className="ratecard-list-item-left ellipsis">
              {editingSection}
              <div className="ratecard-label-container width-90 ellipsis">
                <div>
                  <RateCardLabel>{name}</RateCardLabel>
                  {shared && sharedByYou && (
                    <OverlayTrigger placement="top" overlay={sharedTooltip}>
                      <span className="tag text-x-small align-top">
                        <FontAwesomeIcon icon="share" fixedWidth className="icon" /> By
                        You
                      </span>
                    </OverlayTrigger>
                  )}
                  {shared && !sharedByYou && (
                    <OverlayTrigger placement="top" overlay={sharedTooltip}>
                      <span className="tag text-x-small align-top">
                        <FontAwesomeIcon
                          icon="share"
                          fixedWidth
                          flip="horizontal"
                          className="icon"
                        />{" "}
                        To You
                      </span>
                    </OverlayTrigger>
                  )}
                </div>
                <RateCardMeta>
                  <Inline css={{ color: "$primary" }}>Created by </Inline>
                  <Inline css={{ color: "$primaryDark" }}>{ownerName}</Inline>
                  <Inline css={{ color: "$primary" }}>on </Inline>
                  <Inline css={{ color: "$primaryDark" }}>{createdDisplay}</Inline>
                </RateCardMeta>
              </div>
            </div>
            <div className="ratecard-list-item-right">
              {/*<div className="ratecard-info text-center">*/}
              {/*<header>Shared</header>*/}
              {/*<div>{ shared ? "Yes" : "No"}</div>*/}
              {/*</div>*/}
              <div className="rc-center">
                {/*<div className="ratecard-info ">
                 <header>Searches</header>
                 <div className = "text-center">{searchCount}</div>
              </div>
              <div className="ratecard-info">
                 <header>Locations</header>
                 <div className = "text-center">{locationCount}</div>
              </div>*/}
                {!editing && <LinkButton to={`/tag/${id}`}>View</LinkButton>}
              </div>
            </div>
          </RateCardListItem>
        </div>
      );
    }
  }
);

TagManagementListItem.propTypes = {
  store: PropTypes.object.isRequired,
  rateCard: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default TagManagementListItem;
