// @flow

import { extendObservable, action } from "mobx";
import axios, { CancelToken, CancelTokenSource } from "axios";
import { logAsyncOperationError, logGraphQLError } from "../utils/logging";

export class AxiosAbortController {
  source: CancelTokenSource;

  constructor() {
    this.source = CancelToken.source();
  }

  aborted = (): boolean => {
    return Boolean(this.source.token.reason);
  };

  cancel = (message?: string) => {
    if (!this.aborted()) {
      this.source.cancel(message);
    }
  };
}

export default class NetworkState {
  debug: boolean;
  loading: boolean;
  error: ?Error;
  cancelTokenSource: ?CancelTokenSource;
  abortController: ?AxiosAbortController;

  logGraphQLError: (operationName: string, payload: ?Object) => boolean; // true if there was an error
  toJSON: () => Object;

  constructor(initialState: ?Object) {
    this.debug = process.env.NODE_ENV !== "production";
    this.cancelTokenSource = null;
    this.abortController = null;

    extendObservable(this, {
      loading: false,
      error: null,
    });

    if (initialState) {
      this.loading = initialState.loading;
      this.error = initialState.error;
    }

    /* $FlowFixMe: handleError should be (operationName: string, e: Error, loading?: boolean = false) => void
       But pycharm cannot parse the type it seems */
    this.handleError = action(this.handleError.bind(this));
    this.logGraphQLError = action(this.logGraphQLError.bind(this));
    this.toJSON = action(this.toJSON.bind(this));
  }

  handleError(operationName: string, error: Error, loading?: boolean = false): boolean {
    this.loading = loading;
    this.error = error;

    return logAsyncOperationError(operationName, error);
  }

  /**
   * Logs the graphql payload errors and returns true if any
   * are found.
   */
  logGraphQLError(operationName: string, payload?: ?Object = null): boolean {
    const errors = logGraphQLError(operationName, payload);

    if (errors) this.error = new Error(errors);

    return !!errors;
  }

  toJSON() {
    return {
      loading: this.loading,
      error: this.error,
    };
  }

  getCancelToken = (): CancelToken => {
    if (this.cancelTokenSource && this.loading && this.debug) {
      console.warn(
        "A new CancelToken was generated while an operation was still loading!",
        this.cancelTokenSource
      );
    }

    this.cancelTokenSource = CancelToken.source();
    return this.cancelTokenSource.token;
  };

  cancel = (message?: string): void => {
    if (this.cancelTokenSource) {
      // console.log("cancel()", this.cancelTokenSource);
      this.cancelTokenSource.cancel(message);
      this.cancelTokenSource = null;
      this.loading = false;
    }
  };

  isCancelError = (e: any): boolean => {
    return axios.isCancel(e);
  };

  setLoading = (value: boolean): ?CancelToken => {
    this.loading = value;

    if (this.loading) {
      this.getCancelToken();
    } else {
      this.cancelTokenSource = null;
    }

    return this.cancelTokenSource?.token;
  };

  createAbortController = (abortCurrent: boolean = true): AxiosAbortController => {
    if (abortCurrent) this.abort();
    this.abortController = new AxiosAbortController();
    return this.abortController;
  };

  abort = (message?: string) => {
    if (this.abortController) {
      this.abortController.cancel(message);
      this.loading = false;
    }
  };
}
