// @flow
import React, { Component } from "react";
import { observer } from "mobx-react";
import PlainHighChart from "../../ratecards/components/PlainHighChart";

type PieChartData = {
  name: string,
  y?: number,
  sliced?: boolean,
  selected?: boolean,
};

type Props = {
  data: ?(PieChartData[]),
};

const JobsByTagChart = observer(
  class extends Component<Props> {
    render() {
      const data = this.props.data;
      let chartConfig = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
        },
        title: null,
        tooltip: {
          pointFormat: "<b>{point.percentage:.1f}%</b> ({point.y} Jobs)",
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              distance: 20,
              enabled: true,
              format: "<b>{point.name}</b>",
              style: {
                textOverflow: "ellipsis",
              },
            },
          },
        },
        series: [
          {
            name: "Jobs",
            colorByPoint: true,
            data: data,
          },
        ],
      };

      return (
        <div className="chart-container" style={{ height: 426, width: "100%" }}>
          <PlainHighChart options={chartConfig} style={{ height: 426, width: "100%" }} />
        </div>
      );
    }
  }
);

export default JobsByTagChart;
