import React from "react";
// @ts-ignore
import { ModalButtons, ModalTitle } from "../../views/ratecards/components/FilterModal";
import Inline from "../lib/Inline";
import Icon from "../lib/Icon";
import Button from "../lib/Button";
import Modal from "./Modal";

const ModalType = {
  Error: "error",
  Warning: "warning",
  Success: "success",
  Info: "info",
} as const;

type ModalTypeType = (typeof ModalType)[keyof typeof ModalType];

const defaultIconCssProps = { fontSize: "$4xl" };
const iconProps = {
  [ModalType.Error]: {
    icon: "exclamation-circle",
    css: { color: "$danger", ...defaultIconCssProps },
  },
  [ModalType.Warning]: {
    icon: "exclamation-circle",
    css: { color: "$accent", ...defaultIconCssProps },
  },
  [ModalType.Success]: {
    icon: "check-circle",
    css: { color: "$brand", ...defaultIconCssProps },
  },
  [ModalType.Info]: {
    icon: "info-circle",
    css: { color: "$brand", ...defaultIconCssProps },
  },
} as const;

export type HideModalFunc = () => Promise<void>;
export type ShowModalFunc = (
  message: React.ReactNode,
  header?: React.ReactNode,
  timeout?: number | null
) => Promise<void>;
export type InternalShowModalFunc = (
  modalType: ModalTypeType,
  message: React.ReactNode,
  header: React.ReactNode,
  timeout: number | null
) => Promise<void>;

export type MessageModalAPI = {
  hideModal: HideModalFunc;
  showError: ShowModalFunc;
  showWarning: ShowModalFunc;
  showSuccess: ShowModalFunc;
  showInfo: ShowModalFunc;
};

interface ModalProps {
  style?: any;
}
interface ModalState {
  modalShow: boolean;
  modalType: ModalTypeType | null;
  modalHeader: React.ReactNode | null;
  modalMessage: React.ReactNode | null;
}

/**
 * More generic type of modal allowing to display different kind of messages (success/failure/info) by using this only single modal.
 * See some real example in [src/views/private_index/PrivateIndex.jsx]
 */
const MessageModal = React.forwardRef<MessageModalAPI, React.PropsWithRef<ModalProps>>(
  (props, ref): JSX.Element => {
    const { style } = props;
    const [{ modalShow, modalType, modalHeader, modalMessage }, setState] =
      React.useState<ModalState>({
        modalShow: false,
        modalType: null,
        modalHeader: null,
        modalMessage: null,
      });
    const timeoutIdRef = React.useRef<number | null>(null);

    const hideModal: HideModalFunc = React.useCallback(async () => {
      return new Promise((resolve) => {
        if (timeoutIdRef.current != null) {
          clearTimeout(timeoutIdRef.current);
          timeoutIdRef.current = null;
        }
        setState({
          modalShow: false,
          modalType: null,
          modalHeader: null,
          modalMessage: null,
        });
        setTimeout(resolve, 0);
      });
    }, []);

    const showModal: InternalShowModalFunc = React.useCallback(
      async (modalType, message, header, timeout = null) => {
        if (modalShow) {
          await hideModal();
        }

        return await new Promise((resolve) => {
          setState({
            modalShow: true,
            modalType: modalType,
            modalHeader: header,
            modalMessage: message,
          });
          if (timeout != null) {
            timeoutIdRef.current = setTimeout(async () => {
              await hideModal();
              setTimeout(resolve, 0);
            }, timeout) as unknown as number;
          } else {
            setTimeout(resolve, 0);
          }
        });
      },
      [modalShow, hideModal]
    );

    const showError: ShowModalFunc = React.useCallback(
      async (message, header = "Error", timeout = null) => {
        return showModal(ModalType.Error, message, header, timeout);
      },
      [showModal]
    );

    const showWarning: ShowModalFunc = React.useCallback(
      async (message, header = "Warning", timeout = 5000) => {
        return showModal(ModalType.Warning, message, header, timeout);
      },
      [showModal]
    );

    const showSuccess: ShowModalFunc = React.useCallback(
      async (message, header = "Done", timeout = 5000) => {
        return showModal(ModalType.Success, message, header, timeout);
      },
      [showModal]
    );

    const showInfo: ShowModalFunc = React.useCallback(
      async (message, header = "Information", timeout = 5000) => {
        return showModal(ModalType.Info, message, header, timeout);
      },
      [showModal]
    );

    React.useImperativeHandle(ref, () => ({
      hideModal,
      showError,
      showWarning,
      showSuccess,
      showInfo,
    }));

    return (
      <Modal
        show={modalShow}
        onHide={hideModal}
        innerStyle={{ minWidth: "300px", ...(style ?? {}) }}
      >
        <div className="container-section header">
          <ModalTitle>{modalHeader}</ModalTitle>
        </div>
        <div className="container-section">
          <Inline fill nowrap css={{ alignItems: "center" }}>
            <Icon {...iconProps[modalType ?? ModalType.Info]} />
            <div>{modalMessage}</div>
          </Inline>
        </div>
        <div className="container-section footer">
          <ModalButtons>
            <Button size="small" onClick={hideModal}>
              Close
            </Button>
          </ModalButtons>
        </div>
      </Modal>
    );
  }
);
MessageModal.displayName = "MessageModal";

export function useMessageModalRef() {
  const modalMessagerRef = React.useRef<MessageModalAPI>(null);

  const showModalError: ShowModalFunc = React.useCallback(
    async (...args) => modalMessagerRef.current?.showError(...args),
    [modalMessagerRef]
  );

  const showModalWarning: ShowModalFunc = React.useCallback(
    async (...args) => modalMessagerRef.current?.showWarning(...args),
    [modalMessagerRef]
  );

  const showModalSuccess: ShowModalFunc = React.useCallback(
    async (...args) => modalMessagerRef.current?.showSuccess(...args),
    [modalMessagerRef]
  );

  const showModalInfo: ShowModalFunc = React.useCallback(
    async (...args) => modalMessagerRef.current?.showInfo(...args),
    [modalMessagerRef]
  );

  const hideModal: HideModalFunc = React.useCallback(
    async () => modalMessagerRef.current?.hideModal(),
    [modalMessagerRef]
  );

  return {
    modalMessagerRef,
    showModalError,
    showModalWarning,
    showModalSuccess,
    showModalInfo,
    hideModal,
  };
}

export default MessageModal;
