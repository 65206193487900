import * as React from "react";
import AsyncSelect from "react-select/lib/AsyncCreatable";
import { reactSelectStyles, reactSelectTheme } from "../../../constants/reactSelect";
import { ActionMeta, OptionsType, ValueType } from "react-select/lib/types";
import { Option } from "react-select/lib/filters";
import { MultiValueGenericProps } from "react-select/lib/components/MultiValue";
import { debounce } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormatOptionLabelMeta } from "react-select/lib/Select";

const MultiValueLabel = (props: MultiValueGenericProps<Option>) => {
  const { data } = props;
  return (
    <div
      className="no-wrap flex"
      style={{
        alignItems: "center",
        gap: 8,
        padding: "4px 4px 4px 8px",
      }}
    >
      <FontAwesomeIcon icon="tag" size="sm" />
      <div
        style={{
          maxWidth: 400,
          whiteSpace: "pre-wrap",
          wordWrap: "normal",
          lineHeight: "1.125em",
        }}
      >
        {data.label}
      </div>
    </div>
  );
};

interface Tag {
  tagId: number;
  name: string;
}

const mapToOptions = (tags: ReadonlyArray<Tag>): Array<Option> => {
  if (!tags) return [];

  return tags.map((t) => {
    return {
      value: t.tagId.toString(),
      label: t.name || "-- No name --",
      data: t,
    };
  });
};

type OptionsCallback = (options: OptionsType<Option>) => void;
type LoadOptionsHandler = (
  inputValue: string,
  callback: OptionsCallback
) => Promise<any> | void;

type Props = {
  id: string | undefined;
  selectedTags: ReadonlyArray<Tag> | undefined;
  getTags: (query: string) => Promise<Array<Tag>>;
  onChange?: ((tags: Array<Tag>) => void) | undefined;
  clientIsClientJobLibrary: boolean | undefined;
  onCreate: ((name: string) => Promise<Array<Tag>>) | undefined;
  disabled: boolean | undefined;
};

function BatchSearchTagSelect(props: Props): React.ReactNode {
  const { id, selectedTags, getTags, onChange, onCreate, disabled } = props;

  const value = mapToOptions(selectedTags || []);

  const loadOptions: LoadOptionsHandler = (
    inputValue: string,
    callback: OptionsCallback
  ) => {
    getTags(inputValue)
      .then((tags) => callback(mapToOptions(tags)))
      .catch(() => callback([]));
  };

  const loadOptionsDebounced: LoadOptionsHandler = debounce(loadOptions, 250, {
    trailing: true,
    leading: false,
  });

  const handleChange = (value: ValueType<Option>, _actionMeta: ActionMeta) => {
    if (onChange) {
      onChange((value as Array<Option>).map((v) => v.data));
    }
  };

  const formatOptionLabel = (
    option: Option,
    _selectCtx: FormatOptionLabelMeta<Option>
  ) => {
    // return option.name || option.fullTitle || "-- No name --";

    // @ts-ignore magic prop __isNew__
    const isNewOption = !option.data && option["__isNew__"];

    return (
      <span
        key={option.value}
        className="no-wrap flex"
        style={{
          alignItems: "center",
          gap: 8,
          padding: "2px 4px",
        }}
      >
        <FontAwesomeIcon icon={isNewOption ? "plus" : "tag"} size="sm" />
        <div
          style={{
            maxWidth: 400,
            whiteSpace: "pre-wrap",
            wordWrap: "normal",
            lineHeight: "1.125em",
          }}
        >
          {option.label}
        </div>
      </span>
    );
  };

  const filterOption = (option: Option): boolean => {
    if (!value) return true;
    return !Boolean(value.find((l) => l.data.tagId === option.data.tagId));
  };

  const handleCreateOption = (inputValue: string) => {
    if (!onCreate) return;

    onCreate(inputValue).then(() => {});
  };

  return (
    <AsyncSelect
      components={{ MultiValueLabel }}
      inputId={id || "tagSelect"}
      name="tags"
      placeholder="Type to find or create tags..."
      isMulti
      cacheOptions={false}
      value={value}
      loadOptions={loadOptionsDebounced}
      onChange={handleChange}
      noOptionsMessage={(_inputValue) => "No tags found for your input..."}
      styles={reactSelectStyles}
      theme={reactSelectTheme}
      formatOptionLabel={formatOptionLabel}
      filterOption={filterOption}
      onCreateOption={handleCreateOption}
      createOptionPosition="first"
      isDisabled={disabled}
    />
  );
}

export default BatchSearchTagSelect;
