// @flow

import { extendObservable, action, runInAction } from "mobx";
import CurrentUser from "../../models/User";
import NetworkState from "../../models/NetworkState";
import MessageState from "../../models/MessageState";
import ApplyTagState from "../../models/ApplyTagState";
import { browserHistory } from "react-router";
import ModalState from "../../models/ModalState";
import SavedSearchesListStore from "./SavedSearchesListStore";
import type { FetchGraphQL } from "../../App";

export default class SavedSearchesDetailStore {
  store: SavedSearchesListStore;
  currentUser: CurrentUser;
  showHelpModal: boolean;
  network: NetworkState;
  updateview: boolean;
  showHelp: () => void;
  hideHelp: () => void;
  addToRateCard: () => void;
  closeAddToRateCardModal: () => void;
  addSavedSearchToRateCard: () => void;
  showAddToRateCardModal: boolean;
  messaging: MessageState;
  showHistogram: boolean;
  toggleHistogram: () => void;
  useExistingBuyRate: () => void;
  existing: boolean;
  buyRateData: Array<any>;
  saveNotes: () => void;
  saveBuyRate: () => void;
  useBuyRate: () => void;
  setBuyRateName: () => void;
  showNotes: () => void;
  closeNotes: () => void;
  createAnotherBuyRate: () => void;
  setBuyRateNotes: () => void;
  deleteBuyRate: () => void;
  addPunchouts: () => void;
  setPunchoutData: () => void;
  deletePunchout: () => void;
  tempNotes: String;
  applyTags: () => void;
  deleteSingleTags: () => void;
  applyTagState: ApplyTagState;
  deleteRateCards: () => void;
  confirmDeleteModal: ModalState;
  buyRatesValid: boolean;
  fetchGraphQL: FetchGraphQL;

  constructor(
    fetchGraphQL: FetchGraphQL,
    savedSearchesListStore: SavedSearchesListStore
  ) {
    this.fetchGraphQL = fetchGraphQL;
    this.store = savedSearchesListStore;
    this.showHelp = action(this.showHelp.bind(this));
    this.hideHelp = action(this.hideHelp.bind(this));
    this.addToRateCard = action(this.addToRateCard.bind(this));
    this.closeAddToRateCardModal = action(this.closeAddToRateCardModal.bind(this));
    this.addSavedSearchToRateCard = action(this.addSavedSearchToRateCard.bind(this));
    this.toggleHistogram = action(this.toggleHistogram.bind(this));
    this.useExistingBuyRate = action(this.useExistingBuyRate.bind(this));
    this.saveBuyRate = action(this.saveBuyRate.bind(this));
    this.useBuyRate = action(this.useBuyRate.bind(this));
    this.setBuyRateName = action(this.setBuyRateName.bind(this));
    this.showNotes = action(this.showNotes.bind(this));
    this.closeNotes = action(this.closeNotes.bind(this));
    this.saveNotes = action(this.saveNotes.bind(this));
    this.createAnotherBuyRate = action(this.createAnotherBuyRate.bind(this));
    this.setBuyRateNotes = action(this.setBuyRateNotes.bind(this));
    this.deleteBuyRate = action(this.deleteBuyRate.bind(this));
    this.addPunchouts = action(this.addPunchouts.bind(this));
    this.setPunchoutData = action(this.setPunchoutData.bind(this));
    this.deletePunchout = action(this.deletePunchout.bind(this));
    this.applyTags = action(this.applyTags.bind(this));
    this.deleteSingleTags = action(this.deleteSingleTags.bind(this));
    this.deleteRateCards = action(this.deleteRateCards.bind(this));

    extendObservable(this, {
      showHelpModal: false, // Don't display the help modal on page load
      network: new NetworkState(),
      messaging: new MessageState(),
      showAddToRateCardModal: false,
      showHistogram: true,
      tempNotes: "",
      updateview: true,
      buyRateData: [
        { name: "", showNotes: false, notes: "", index: 0, punchoutsList: [] },
      ],
      existing: false,
      applyTagState: new ApplyTagState(fetchGraphQL, this),
      confirmDeleteModal: new ModalState(),
      buyRatesValid: false,
    });
  }

  showHelp() {
    this.showHelpModal = true;
  }

  hideHelp() {
    this.showHelpModal = false;
  }

  addToRateCard() {
    const listStore = this.store;
    listStore.rateCardsListStore.allowMultipleItemSelection = false;
    listStore.rateCardsListStore.isEditing = true;
    listStore.rateCardsListStore.pagination.goFetch();
    this.showAddToRateCardModal = true;
  }

  /**
   * toggleHistogram
   * @return {void}
   */
  toggleHistogram() {
    this.showHistogram = !this.showHistogram;
  }

  /**
   * addSavedSearchToRateCard - call add saved search data to rate card API
   * @return {void}
   */
  addSavedSearchToRateCard(searchId) {
    this.store.addSavedSearchToRateCard(searchId);
  }

  /**
   * closeAddToRateCardModal - close add to rate card modal
   * @return {void}
   */
  closeAddToRateCardModal() {
    this.showAddToRateCardModal = false;
  }

  setPunchoutData(buyrateIndex, index, value, field) {
    if (value === "" || value.match(/^[0-9]+(\.[0-9]+)?$/)) {
      if (field !== "levelId") {
        var splittedField = field.split(".");
        this.buyRateData[buyrateIndex].punchoutsList[index][splittedField[0]][
          splittedField[1]
        ] = value;
      } else {
        this.buyRateData[buyrateIndex].punchoutsList[index].levelId = value;
      }
      this.buyRatesValid = this.validateBuyrate(this.buyRateData[buyrateIndex]);
    } else {
      return;
    }
    this.updateview = !this.updateview;
  }

  validateBuyrate(buyrate) {
    let nameValid = buyrate.name ? true : false;
    let billRateValid = false;
    let markupAmtValid = false;
    let markupPctValid = false;
    let payRateValid = false;

    // Huge HACK, this whole function should be rewritten
    let i = 0;
    let isEmptyBillRate = (k) => buyrate.punchoutsList[i].billRate[k] !== "";
    let isEmptyMarkupAmt = (k) => buyrate.punchoutsList[i].markupAmt[k] !== "";
    let isEmptyMarkupPct = (k) => buyrate.punchoutsList[i].markupPct[k] !== "";
    let isEmptyPayRate = (k) => buyrate.punchoutsList[i].payRate[k] !== "";

    for (i = 0; i < buyrate.punchoutsList.length; i++) {
      billRateValid = Object.keys(buyrate.punchoutsList[i].billRate).every(
        isEmptyBillRate
      );
      markupAmtValid = Object.keys(buyrate.punchoutsList[i].markupAmt).every(
        isEmptyMarkupAmt
      );
      markupPctValid = Object.keys(buyrate.punchoutsList[i].markupPct).every(
        isEmptyMarkupPct
      );
      payRateValid = Object.keys(buyrate.punchoutsList[i].payRate).every(isEmptyPayRate);
      if (
        !nameValid ||
        !billRateValid ||
        !markupAmtValid ||
        !markupPctValid ||
        !payRateValid
      ) {
        break;
      }
    }
    return nameValid && billRateValid && markupAmtValid && markupPctValid && payRateValid;
  }

  onFocusOutFn(e, buyrateIndex, index, field) {
    var splittedField = field.split(".");
    var val =
      this.buyRateData[buyrateIndex].punchoutsList[index][splittedField[0]][
        splittedField[1]
      ].toString();
    if (!val.match(/^[0-9]+(\.[0-9]+)?$/)) {
      e.target.parentElement.classList.add("has-error");
    } else {
      e.target.parentElement.classList.remove("has-error");
    }
  }

  deletePunchout(buyrateIndex, index) {
    this.buyRatesValid = true;
    if (this.buyRateData[buyrateIndex].punchoutsList.length <= 1) {
      return;
    } else {
      this.buyRateData[buyrateIndex].punchoutsList.splice(index, 1);
    }
    this.updateview = !this.updateview;
  }

  useBuyRate(data) {
    this.existing = true;
    var customBuyRate = [];
    data.forEach((buyrate, index) => {
      var newpunch = [];

      for (var i = 0; i < buyrate.punchouts.length; i++) {
        newpunch.push({
          billRate: {
            min: buyrate.punchouts[i].billRateMin,
            max: buyrate.punchouts[i].billRateMax,
            mid: buyrate.punchouts[i].billRateMid,
            avg: buyrate.punchouts[i].billRateAvg,
          },
          markupAmt: {
            min: buyrate.punchouts[i].markupAmtMin,
            max: buyrate.punchouts[i].markupAmtMax,
            mid: buyrate.punchouts[i].markupAmtMid,
            avg: buyrate.punchouts[i].markupAmtAvg,
          },
          markupPct: {
            min: buyrate.punchouts[i].markupPctMin,
            max: buyrate.punchouts[i].markupPctMax,
            mid: buyrate.punchouts[i].markupPctMid,
            avg: buyrate.punchouts[i].markupPctAvg,
          },
          payRate: {
            min: buyrate.punchouts[i].payRateMin,
            max: buyrate.punchouts[i].payRateMax,
            mid: buyrate.punchouts[i].payRateMid,
            avg: buyrate.punchouts[i].payRateAvg,
          },
          levelId: buyrate.punchouts[i].level.legacyId,
        });
      }

      var newBuyRate = {
        name: buyrate.name,
        showNotes: false,
        notes: buyrate.notes,
        index: index,
        punchoutsList: newpunch,
      };
      customBuyRate.push(newBuyRate);
    });

    this.buyRateData = customBuyRate;
    this.updateview = !this.updateview;
  }

  errorClass(index) {
    return this.buyRateData[index].name === "" ? "has-error" : "";
  }

  setBuyRateName(index, value) {
    if (this.buyRateData.length === 0) {
      this.buyRateData = [
        { name: "", showNotes: false, notes: "", index: 0, punchoutsList: [] },
      ];
    }
    this.buyRateData[index].name = value;
    this.updateview = !this.updateview;
  }

  /**
   * showNotes - show notes popup
   * @return {void}
   */
  showNotes(index) {
    this.buyRateData[index].showNotes = true;
    this.updateview = !this.updateview;
  }
  /**
   * closeNotes - close notes popup
   * @return {void}
   */
  closeNotes(index) {
    this.tempNotes = "";
    this.buyRateData[index].showNotes = false;
    this.updateview = !this.updateview;
  }

  saveNotes(index) {
    this.buyRateData[index].notes = this.tempNotes;
    this.closeNotes(index);
    this.buyRatesValid = true;
  }

  deleteBuyRate(index) {
    this.buyRatesValid = true;
    this.buyRateData.splice(index, 1);
    this.updateview = !this.updateview;
  }

  addPunchouts(index) {
    this.buyRatesValid = false;
    var existingLevel = this.buyRateData[index].punchoutsList.length;
    var punch = this.createPunchoutList(existingLevel + 1);
    for (var i = 0; i < punch.length; i++) {
      this.buyRateData[index].punchoutsList.push(punch[i]);
    }
    this.updateview = !this.updateview;
  }

  createAnotherBuyRate(dataToDisplay) {
    this.buyRatesValid = false;
    var punch = this.createPunchoutList(1);
    var existingLength = this.buyRateData.length;
    var date = new Date();
    date = date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
    var newBuyRate = {
      name: dataToDisplay.job.jobLabel + " " + date,
      showNotes: false,
      notes: "",
      index: existingLength + 1,
      punchoutsList: punch,
    };
    this.buyRateData.push(newBuyRate);
    this.updateview = !this.updateview;
  }

  createPunchoutList(levelId) {
    var punchoutsList = [];
    var billRate = {};
    var markupPct = {};
    var markupAmt = {};
    var payRate = {};
    billRate.min = "";
    billRate.max = "";
    billRate.mid = "";
    billRate.avg = "";
    markupPct.min = "";
    markupPct.max = "";
    markupPct.mid = "";
    markupPct.avg = "";
    markupAmt.min = "";
    markupAmt.max = "";
    markupAmt.mid = "";
    markupAmt.avg = "";
    payRate.min = "";
    payRate.max = "";
    payRate.mid = "";
    payRate.avg = "";
    punchoutsList.push({
      billRate,
      markupAmt,
      markupPct,
      payRate,
      levelId: levelId,
    });
    return punchoutsList;
  }

  setBuyRateNotes(e) {
    this.tempNotes = e.target.value;
    this.updateview = !this.updateview;
  }

  useExistingBuyRate(value) {
    this.buyRatesValid = true;
    this.existing = true;
    let existingBuyRates = [];
    let data = value.rates;

    for (let i = 0; i < 5; i++) {
      existingBuyRates.push({
        billRate: {
          min: data.billRate.min[i],
          max: data.billRate.max[i],
          mid: data.billRate.mid[i],
          avg: data.billRate.avg[i],
        },
        markupAmt: {
          min: data.markupAmt.min[i],
          max: data.markupAmt.max[i],
          mid: data.markupAmt.mid[i],
          avg: data.markupAmt.avg[i],
        },
        markupPct: {
          min: data.markupPct.min[i],
          max: data.markupPct.max[i],
          mid: data.markupPct.mid[i],
          avg: data.markupPct.avg[i],
        },
        payRate: {
          min: data.payRate.min[i],
          max: data.payRate.max[i],
          mid: data.payRate.mid[i],
          avg: data.payRate.avg[i],
        },
        levelId: i + 1,
      });
    }

    let date = new Date();
    date = date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();

    this.setBuyRateName(0, value.job.jobLabel + " " + date);
    this.buyRateData[0].punchoutsList = existingBuyRates;
    let punchList = document.getElementsByClassName("punchout-list");
    for (let i = 0; i < punchList.length; i++) {
      for (let j = 0; j < punchList[i].childElementCount; j++) {
        if (
          punchList[i].childNodes.length > 0 &&
          punchList[i].childNodes[j].childNodes.length > 0 &&
          punchList[i].childNodes[j].childNodes[1]
        ) {
          punchList[i].childNodes[j].childNodes[1].classList.remove("has-error");
          if (punchList[i].childNodes[j].childNodes[1].childNodes) {
            punchList[i].childNodes[j].childNodes[1].childNodes[0].classList.remove(
              "has-error"
            );
          }
        }
      }
    }

    this.updateview = !this.updateview;
  }

  async saveBuyRate(searchId) {
    var customBuyRate = [];
    this.buyRateData.forEach((buyrate) => {
      var newpunch = [];
      buyrate.punchoutsList.forEach((punch) => {
        newpunch.push({
          billRateMin: punch.billRate.min,
          billRateMax: punch.billRate.max,
          billRateMid: punch.billRate.mid,
          billRateAvg: punch.billRate.avg,
          markupAmtAvg: punch.markupAmt.avg,
          markupAmtMax: punch.markupAmt.max,
          markupAmtMid: punch.markupAmt.mid,
          markupAmtMin: punch.markupAmt.mid,
          markupPctAvg: punch.markupPct.avg,
          markupPctMax: punch.markupPct.max,
          markupPctMid: punch.markupPct.mid,
          markupPctMin: punch.markupPct.min,
          payRateAvg: punch.payRate.avg,
          payRateMax: punch.payRate.max,
          payRateMid: punch.payRate.mid,
          payRateMin: punch.payRate.min,
          levelId: punch.levelId,
        });
      });
      var newBuyRate = {
        name: buyrate.name,
        notes: buyrate.notes,
        punchouts: newpunch,
      };
      customBuyRate.push(newBuyRate);
    });

    const variables = {
      searchId: searchId,
      buyRates: customBuyRate,
    };

    const query = `
         mutation createUpdateDeletedBuyRate($searchId: Int!, $buyRates : [BuyRateInput]){
          createUpdateDeleteBuyRate(input: {searchId: $searchId, buyrates: $buyRates}){
            buyRates{
              name,
              notes,
              punchouts{
                billRateMin,
                billRateMax,
                payRateMin,
                payRateMax,
                markupPct
              },
              buyrateId
            }
          }
        }
        `;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
      // should hit get request
    } catch (e) {
      console.error("Error in saving buy rate", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }

    runInAction("saveBuyRate--success", () => {
      this.buyRatesValid = false;
      this.updateview = !this.updateview;
    });

    return {
      res: res,
    };
  }

  async applyTags(searchId) {
    let params = [];
    let filterargs = [];
    let searchargs = [];
    let args = [];
    let vars = {};

    const taglist = this.applyTagState.getSelectedTagList();
    if (!taglist || !taglist.length) {
      console.error("Cannot Apply tags to Saved Searches: No Saved Searches selected");
      return;
    }
    params.push("$tagIds: [Int]!");
    filterargs.push("tagIds: $tagIds");
    vars.tagIds = taglist;
    params.push("$only: [ID]");
    args.push("only: $only");
    vars.only = [searchId];

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
      mutation applyTags(${queryParams}){
       applyTagsToSearches(input:{${filterargs}, filters: { ${queryArgs}}, ${searchargs}}) {
         ok
          errors {
           __typename
           ... on TagIdRequiredError {
             message
           }
           ... on TagIdsNotExistsError {
             message
           }
           ... on InvalidInputError {
             message
           }
           ... on ContentDoesNotExistsError {
             message
           }
         }
       }

      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Apply Tags to Selected Worksheets", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    runInAction("applyTags--success", () => {
      this.applyTagState.tagModal.hideModal();
      this.store.savedSearchDetail(searchId);
    });
  }

  async deleteSingleTags(content) {
    const query = `
    mutation removeTagsFromContent{
      removeTagsFromContent(input: {tagIds : [${content.tagId}],contentType :${content.contentType},contentId:${content.contentId}}){
        ok
        }
      }
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, {});
    } catch (e) {
      // TODO: Display user friendly error message
      console.log("error", e);
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }

    runInAction("deleteSingleTags--success", () => {
      // debugger
      this.store.savedSearchTags.forEach((item, index) => {
        if (content.tagId === item.tagId) {
          this.store.savedSearchTags.splice(index, 1);
          this.store.selectedTags.delete(item.tagId);
        }
      });
      //this.project = new ProjectCostEstimates(this, this.project);
    });
  }

  async deleteRateCards(searchId) {
    if (this.network.loading) {
      return;
    }

    let params = [];
    let filterargs = [];
    let args = [];
    let vars = {};

    params.push("$only: [String]!");
    filterargs.push("only: $only");
    vars.only = [searchId];

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
    mutation deleteRateCardSearch(${queryParams}){
         deleteRateCardSearch(input: {${filterargs}, filters: { ${queryArgs} }}){
           ok
         }
       }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Deleting selected Rate Cards", e);
      // TODO: Display user friendly error message
      return;
    }

    runInAction("deleteRateCards--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("deleteRateCard", res)) {
        // TODO: Display user friendly error message
        return;
      }

      browserHistory.push({ pathname: "/saved-searches" });
      //this.pagination.goFetch(null);
    });
  }

  toJSON() {
    return {};
  }
}
