import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingIndicator from "../../shared/LoadingIndicator";
import { observer } from "mobx-react";
import numeral from "numeral";
import { Link } from "../../../components/lib/Link";
import {
  shouldShowCurrency,
  getCurrency,
} from "../../searches/components/SupportFunctions";
import Pagination from "../../../components/lib/Pagination";
import Button from "../../../components/lib/Button";

class RateCardSearches extends Component {
  constructor(props) {
    super(props);

    this.changeCurrent = this.changeCurrent.bind(this);
  }

  // Change the current value of the pagination
  changeCurrent(value) {
    // this.props.pagination.handleCurrentPage(value);
    this.props.store.pagination.handleCurrentPage(0);
  }

  render() {
    var store = this.props.store;
    var network = store.getSearchesNetwork;
    var searches = this.props.searches;
    let tableContent = null;
    let tableRow = null;
    let name = store.featuredRateCard ? store.featuredRateCard.name : "";

    if (network.loading) {
      tableContent = <LoadingIndicator />;
    } else if (network.error) {
      tableContent = (
        <div className="clearfix">{JSON.stringify(network.error, null, 2)}</div>
      );
    } else if (searches.length === 0) {
      tableContent = <div className="clearfix">No Searches Found</div>;
    } else {
      tableRow = searches.map((ss) => (
        <Searches
          key={ss.searchId}
          search={ss}
          featuredSearch={this.props.featuredSearch}
          store={store}
        />
      ));
      tableContent = (
        <div>
          <div className="table-responsive">
            <table
              className="no-spacing table table-bordered table-striped"
              cellSpacing="0"
              data-testid="ratecard-searches-list"
            >
              <thead className="">
                <tr>
                  <th className="col-sm-1">Job Title</th>
                  <th className="col-sm-1">Job Label</th>
                  <th className="col-sm-1">Location</th>
                  <th className="col-sm-1">Records</th>
                  <th className="col-sm-1">Industry</th>
                  <th className="col-sm-1">Level III Average</th>
                  <th className="col-sm-1">Changes</th>
                  <th className="col-sm-1">Actions</th>
                </tr>
              </thead>
              <tbody>{tableRow}</tbody>
            </table>
          </div>
          {store.pagination.pageCount > 0 ? (
            <Pagination
              options={{
                variant: "full",
                currentPage: store.pagination.currentPage,
                numPages: store.pagination.pageCount,
              }}
              onPageClick={(pageEvent) => {
                if (pageEvent.type === "first") {
                  this.changeCurrent(1);
                }
                if (pageEvent.type === "last") {
                  this.changeCurrent(store.pagination.pageCount);
                }
                if (pageEvent.type === "next") {
                  this.changeCurrent(
                    Math.min(store.pagination.currentPage + 1, store.pagination.pageCount)
                  );
                }
                if (pageEvent.type === "prev") {
                  this.changeCurrent(Math.max(store.pagination.currentPage - 1, 1));
                }
                if (pageEvent.type === "page") {
                  this.changeCurrent(pageEvent.page);
                }
              }}
            />
          ) : (
            ""
          )}
        </div>
      );
    }

    return (
      <div>
        <h3>
          Rate Card : {name}{" "}
          <Link onClick={this.props.displayRateCard}>
            <FontAwesomeIcon
              icon="cog"
              fixedWidth
              className="setings"
              data-testid="rate-card-searches-settings"
            />
          </Link>
        </h3>
        <br />
        {tableContent}
      </div>
    );
  }
}

const Searches = observer((props) => {
  const search = props.search;
  // let location = [];
  let selectedFeaturedSearch = props.featuredSearch;
  let avg = null;
  let avgChange = 0.0;
  let currencySymbol = "";
  let changeIcon = "minus";
  let showCurrency = shouldShowCurrency(search);
  if (showCurrency) currencySymbol = getCurrency(search);
  let colorChangeClass = "";
  let addPlusSign = "";

  let searchAction = (
    <Button color="brand" onClick={(e) => props.store.updateSearchId(search.searchId)}>
      Graph
    </Button>
  );

  if (selectedFeaturedSearch.searchId === search.searchId) {
    searchAction = <span className="btn btn-danger"> Selected </span>;
  }

  if (search.resultsByLevel.Senior) {
    avg = search.resultsByLevel.Senior.payRateAvg;
    avgChange = search.resultsByLevel.Senior.change.payRateAvgChange;
    // changeIcon = search.resultsByLevel.Senior.change.payRateAvgChangeIcon;
  }

  // if (search.location.city) {
  //   location.push(search.location.city);
  // }
  // if (search.location.state) {
  //   location.push(search.location.state);
  // }
  // if (search.location.country) {
  //   location.push(search.location.country);
  // }
  const loc = search.locationFullLabel;

  // if (avgChange !== 0 && avgChange !== "0.00") {
  //   changeClassName = `fa ${changeIcon}`;
  // }

  if (avgChange > 0) {
    addPlusSign = "+";
    changeIcon = "play";
    colorChangeClass = "good";
  } else if (avgChange < 0) {
    changeIcon = "play";
    colorChangeClass = "bad";
  }

  return (
    <tr>
      <td className="col-sm-1">
        <span>{search.title}</span>
      </td>
      <td className="col-sm-1">
        <span>{search.label}</span>
      </td>
      <td className="col-sm-1">
        <span>{loc}</span>
      </td>
      <td className="col-sm-1">
        <span>{search.numResults}</span>
      </td>
      <td className="col-sm-1">
        <span>{search.industry.title}</span>
      </td>
      <td className="col-sm-1 nw">
        <span>
          {currencySymbol} {numeral(avg).format("0,0.00")}
        </span>
      </td>
      <td className="col-sm-1 nw">
        <span className={colorChangeClass}>
          <FontAwesomeIcon
            icon={changeIcon}
            rotation={
              avgChange === 0 || avgChange === "0.00" ? null : avgChange > 0 ? 270 : 90
            }
          />{" "}
          {addPlusSign}
          {numeral(avgChange).format("0,0.00")}
        </span>
      </td>
      <td className="col-sm-1">{searchAction}</td>
    </tr>
  );
});

export default observer(RateCardSearches);
