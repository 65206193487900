export default class RateSearchData {
  constructor(store, object) {
    this.store = store;
    this.industry = null;
    this.location = null;
    this.results = null;
    this.jobTitle = null;
    this.jobDescription = null;
    this.jobLabel = null;
    this.currency = null;

    if (object) {
      this.industry = object.industry || null;
      this.location = object.location || null;
      this.results = object.results || null;
      this.jobTitle = object.jobTitle || null;
      this.jobDescription = object.jobDescription || null;
      this.jobLabel = object.jobLabel || null;
      this.currency = object.currency || null;
    }
  }

  toJS() {
    return {
      industry: this.industry,
      location: this.location,
      results: this.results,
      jobTitle: this.jobTitle,
      jobDescription: this.jobDescription,
      jobLabel: this.jobLabel,
      currency: this.currency,
    };
  }

  static fromJS(store, object) {
    return new RateSearchData(store, object);
  }
}
