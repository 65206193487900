import React, { useCallback } from "react";

import Icon from "../../../../components/lib/Icon";
import RestfulDatasetListView, {
  DataSourceFunc,
  RenderItemFunc,
} from "./BaseRestfulDatasetListView";
import SelectItemRow from "../../lib/SelectItemRow";
import { industriesListToImmutableList } from "../../dataConverters";
import { emptyList } from "../../../../constants";

import type { IconProps } from "../../../../components/lib/Icon";
import type { ImmutableMap, ImmutableList } from "../../types";

export type IndustryObject = {
  id: number;
  title: string;
  faIcon: IconProps["icon"];
};

export type IndustryDataMap = ImmutableMap<IndustryObject>;
export type IndustryDataList = ImmutableList<IndustryDataMap>;

const allIndustries: IndustryObject[] = [
  { id: 1, title: "All", faIcon: "th" },
  { id: 2, title: "Academia", faIcon: "graduation-cap" },
  { id: 3, title: "Aerospace and Defense", faIcon: "plane" },
  { id: 4, title: "Agricultural", faIcon: "leaf" },
  { id: 5, title: "Computers and Technology", faIcon: "desktop" },
  { id: 6, title: "Consumer Goods", faIcon: "shopping-cart" },
  { id: 7, title: "Financial", faIcon: "dollar-sign" },
  { id: 8, title: "Government", faIcon: "university" },
  { id: 9, title: "Healthcare", faIcon: "stethoscope" },
  { id: 10, title: "Insurance", faIcon: "umbrella" },
  { id: 11, title: "Manufacturing", faIcon: "cogs" },
  { id: 12, title: "Oil and Gas", faIcon: "tint" },
  { id: 13, title: "Pharmaceuticals", faIcon: "flask" },
  { id: 14, title: "Power and Utilities", faIcon: "bolt" },
  { id: 15, title: "Telecommunications", faIcon: "signal" },
  { id: 16, title: "Professional Services / Consulting", faIcon: "briefcase" },
  { id: 17, title: "Conglomerate", faIcon: "cubes" },
];

type IndustriesSelectProps = {
  values: IndustryDataList;
  disabled?: boolean;
  searchPlaceholder?: string;
  noItemsFoundText?: string;
  onSelect: (item: IndustryDataMap) => void;
};

const IndustriesSelect = (props: IndustriesSelectProps) => {
  const {
    values,
    searchPlaceholder,
    noItemsFoundText,
    disabled = false,
    onSelect,
  } = props;

  const dataSource: DataSourceFunc<IndustryDataList> = useCallback(
    async (search, page, pageSize) => {
      const allIndustriesList: IndustryDataList =
        industriesListToImmutableList(allIndustries);

      if (!search) {
        return {
          itemsCount: allIndustriesList.size,
          data: allIndustriesList,
        };
      }

      const results = allIndustriesList.filter((i) => {
        const itemTitle = i.get("title");

        if (!itemTitle) return false;

        return itemTitle.toLowerCase().startsWith(search.toLowerCase());
      });

      return {
        itemsCount: results.size,
        data: results,
      };
    },
    []
  );

  // render methods

  const renderItem: RenderItemFunc<IndustryDataMap> = useCallback(
    (item) => {
      const title = item.get("title");
      const iconName = item.get("faIcon");

      const valueIds = values?.size
        ? values.map((i: IndustryDataMap) => i.get("id"))
        : emptyList;
      const itemId = item?.size && item.get("id");
      const isSelected = !!(itemId != null && valueIds.size && valueIds.includes(itemId));

      return (
        <SelectItemRow
          selected={isSelected}
          disabled={disabled}
          onClick={() => !disabled && onSelect?.(item)}
        >
          <Icon
            icon={iconName}
            css={{ color: disabled ? "$primary" : "$brand", width: "25px" }}
          />
          {title}
        </SelectItemRow>
      );
    },
    [values, disabled, onSelect]
  );

  return (
    <RestfulDatasetListView
      searchPlaceholder={searchPlaceholder}
      noItemsFoundText={noItemsFoundText}
      disabled={disabled}
      dataSource={dataSource}
      renderItem={renderItem}
    />
  );
};
IndustriesSelect.displayName = "IndustriesMultiSelect";

export default IndustriesSelect;
