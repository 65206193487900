// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckBox from "../../shared/CheckBox";
import classNames from "classnames";
import TagItem from "../../ratecards/components/TagItem";
import numeral from "numeral";
import LinkButton from "../../../components/lib/LinkButton";
import {
  CardListItem,
  CardListItemLabel,
  CardListSubText,
  SubTextAccent,
} from "../../../components/lib/Card";
import Box from "../../../components/lib/Box";
import Inline from "../../../components/lib/Inline";
import { RATE_TYPE_OPTIONS_2_BY_ID } from "../../../constants/rateTypes";

/**
 * SavedSearchesItem component for Saved Searches List page
 */
const SavedSearchesItem = observer(
  class extends Component {
    static contextTypes = {
      router: PropTypes.object,
    };
    /**
   * constructor
   * @param {props} props The first number.

   */
    constructor(props) {
      super(props);
      this.handleViewDetail = this.handleViewDetail.bind(this);
      this.deleteTag = this.deleteTag.bind(this);
      this.state = {
        updated: false,
      };
    }

    deleteTag(event: object, tag_id) {
      event.preventDefault();
      event.stopPropagation();
      event.cancelBubble = true;
      event.returnValue = false;
      this.props.store
        .deleteSingleTags({
          tagId: tag_id,
          contentId: this.props.rateCard.searchId,
        })
        .then((res) => {
          if (!res.result) return;
          this.props.rateCard.tags.forEach((item, index) => {
            if (item.tagId === tag_id) {
              this.props.rateCard.tags.splice(index, 1);
            }
          });
          this.setState({
            updated: true,
          });
        });
    }
    /**
     * handleViewDetail- redirect to detail page
     * @return {void}
     */
    handleViewDetail() {
      this.context.router.push({
        pathname: `/searches/${this.props.rateCard.searchId}`,
        query: this.context.router.query,
      });
    }
    /**
     * Render Saved Searches List Item
     * @return {XML}
     */
    render() {
      const props = this.props;
      const rateCard = props.rateCard;

      const isHealthcare =
        Array.isArray(rateCard.rates.rawBillRates) &&
        rateCard.rates.rawBillRates.length > 0 &&
        rateCard.rates.rawAvg > 0;

      const editing = rateCard.viewState.editing;
      const selected = rateCard.viewState.selected;
      //const searchId = rateCard.searchId;
      const job = rateCard.job;
      const city = rateCard.city;
      const state = rateCard.state;
      const country = rateCard.country;
      const region = rateCard.region;
      const rateType = rateCard.rateType;
      const showMarkupAndBillRate = rateType === 1;
      const isContract = rateType === 1;
      const symbol = rateCard.symbol;
      const createdDisplay = rateCard.createdDate.format("MMMM D, YYYY");
      // const rates = rateCard.rates;
      const marketRate = rateCard.marketRate;
      const marketChanges = rateCard.marketChanges.find(
        (change) => change.level.legacyId === 3
      );

      // Handle "missing rates" (typically during migration)
      const payRateAvgTotal = marketRate.payRate; // this is the avg value from level III
      const markupAvgTotal = marketRate.markupPct; // this is the avg value from level III
      const billRateAvgTotal = marketRate.billRate; // this is the avg value from level III
      let payRateChanges = null;
      let markupChanges = null;
      let billRateChanges = null;

      let startPayBrack,
        endPayBrack,
        startMarkBrack,
        endMarkBrack,
        startBillBrack,
        endBillBrack = null;

      if (marketChanges) {
        payRateChanges = marketChanges.payRateAvg - payRateAvgTotal;
        markupChanges = marketChanges.markupPctAvg - markupAvgTotal;
        billRateChanges = marketChanges.billRateAvg - billRateAvgTotal;

        if (Math.abs(payRateChanges.toFixed(2)) !== 0) {
          startPayBrack = "(";
          endPayBrack = ")";
        }
        if (Math.abs(markupChanges.toFixed(2)) !== 0) {
          startMarkBrack = "(";
          endMarkBrack = ")";
        }
        if (Math.abs(billRateChanges.toFixed(2)) !== 0) {
          startBillBrack = "(";
          endBillBrack = ")";
        }
      }

      const id = rateCard.searchId;
      let ownerName = "You";

      let onItemClick = this.props.onClick || this.handleViewDetail;
      let editingSection = null;

      const tags = rateCard.tags;
      let tagSection = null;
      let addPlusSign = "+";

      if (tags.length > 0 && !this.props.store.isTagView) {
        tagSection = tags.map((tag) => (
          <TagItem key={tag.tagId} tag={tag} onClick={this.deleteTag} />
        ));
      }

      if (editing) {
        onItemClick = rateCard.toggleSelected;
        // TODO: Verify using id for value prop here makes sense
        editingSection = (
          <CheckBox
            type={props.store.allowMultipleItemSelection ? "checkbox" : "radio"}
            name="list-item-select"
            value={id}
            selected={selected}
            style={{ width: 34 }}
            onClick={onItemClick}
          />
        );
      }
      return (
        <CardListItem
          selected={selected}
          onClick={onItemClick}
          css={{ display: "flex", flexWrap: "wrap" }}
        >
          <div className="ratecard-list-item-left ellipsis">
            {editingSection}
            <div className="ratecard-label-container ellipsis">
              <div className="ratecard-label ellipsis">
                <div className="ellipsis">
                  <CardListItemLabel>{job.jobLabel}</CardListItemLabel>
                  <CardListSubText>
                    <SubTextAccent>{job.jobTitle}</SubTextAccent> in{" "}
                    <SubTextAccent>
                      {region
                        ? region.name
                        : [city, state, country].filter((l) => Boolean(l)).join(", ")}
                    </SubTextAccent>
                  </CardListSubText>
                  <CardListSubText>
                    Created by <SubTextAccent>{ownerName}</SubTextAccent> on{" "}
                    <SubTextAccent>{createdDisplay}</SubTextAccent>
                  </CardListSubText>

                  {/*<div className={classNames("tagbox", { "p-left-34": editing })}>{tagSection}</div>*/}
                </div>
              </div>
            </div>
          </div>

          <Inline css={{ gap: 0, display: "inline-flex" }}>
            {rateCard.isGSS && (
              <div className="list-rate">
                <div className="ratecard-info text-center">
                  <div>
                    <header>&nbsp; </header>
                    <FontAwesomeIcon icon="globe" fixedWidth className="icon" /> GSS
                  </div>
                </div>
              </div>
            )}
            {rateCard.marketChanges.length === 0 && (
              <div className="list-rate">
                <div className="ratecard-info text-center">
                  <header>Status</header>
                  <div>Migrating</div>
                </div>
              </div>
            )}
            {isHealthcare && (
              <div className="ratecard-info text-center">
                <header>Average Bill Rate</header>
                <div>
                  {symbol +
                    numeral(rateCard.rates.rawAvg).format(isContract ? "0,0.00" : "0,0")}
                </div>
              </div>
            )}
            {!isHealthcare && marketChanges && (
              <div className="ratecard-info text-center">
                <header>Pay Rate</header>
                <div>{symbol + numeral(payRateAvgTotal).format("0,0.00")}</div>
                {Math.abs(payRateChanges.toFixed(2)) !== 0 && (
                  <Box
                    as="span"
                    css={{
                      fontSize: "$xs",
                      lineHeight: "$xs",
                      "& svg": { fontSize: "10px", marginRight: "$1" },
                    }}
                  >
                    {startPayBrack}
                    <span
                      className={classNames(
                        { good: payRateChanges > 0 },
                        { bad: payRateChanges < 0 }
                      )}
                    >
                      <FontAwesomeIcon
                        icon="play"
                        rotation={
                          payRateChanges === 0 || payRateChanges === "0.00"
                            ? null
                            : payRateChanges > 0
                            ? 270
                            : 90
                        }
                        style={{ fontSize: "10px !important" }}
                      />
                      {payRateChanges > 0 ? addPlusSign : ""}
                      {payRateChanges < 0 ? "-" : ""}
                      {Math.abs(payRateChanges.toFixed(2))}
                    </span>
                    {endPayBrack}
                  </Box>
                )}
              </div>
            )}
            {!isHealthcare && marketChanges && showMarkupAndBillRate && (
              <div className="ratecard-info text-center">
                <header>Markup</header>
                <div>{markupAvgTotal.toFixed(2) + " %"}</div>
                {Math.abs(markupChanges.toFixed(2)) !== 0 && (
                  <Box
                    as="span"
                    css={{
                      fontSize: "$xs",
                      lineHeight: "$xs",
                      "& svg": { fontSize: "10px", marginRight: "$1" },
                    }}
                  >
                    {startMarkBrack}
                    <span
                      className={classNames(
                        { good: markupChanges > 0 },
                        { bad: markupChanges < 0 }
                      )}
                    >
                      <FontAwesomeIcon
                        icon="play"
                        rotation={
                          markupChanges === 0 || markupChanges === "0.00"
                            ? null
                            : markupChanges > 0
                            ? 270
                            : 90
                        }
                        style={{ fontSize: "10px !important" }}
                      />
                      {markupChanges > 0 ? addPlusSign : ""}
                      {markupChanges < 0 ? "-" : ""}
                      {Math.abs(markupChanges.toFixed(2))}
                    </span>
                    {endMarkBrack}
                  </Box>
                )}
              </div>
            )}
            {!isHealthcare && marketChanges && showMarkupAndBillRate && (
              <div className="ratecard-info text-center">
                <header>Bill Rate</header>
                <div>{symbol + numeral(billRateAvgTotal).format("0,0.00")}</div>
                {Math.abs(billRateChanges.toFixed(2)) !== 0 && (
                  <Box
                    as="span"
                    css={{
                      fontSize: "$xs",
                      lineHeight: "$xs",
                      "& svg": { fontSize: "10px", marginRight: "$1" },
                    }}
                  >
                    {startBillBrack}
                    <span
                      className={classNames(
                        { good: billRateChanges > 0 },
                        { bad: billRateChanges < 0 }
                      )}
                    >
                      <FontAwesomeIcon
                        icon="play"
                        rotation={
                          billRateChanges === 0 || billRateChanges === "0.00"
                            ? null
                            : billRateChanges > 0
                            ? 270
                            : 90
                        }
                        style={{ fontSize: "10px !important" }}
                      />
                      {billRateChanges > 0 ? addPlusSign : ""}
                      {billRateChanges < 0 ? "-" : ""}
                      {Math.abs(billRateChanges.toFixed(2))}
                    </span>
                    {endBillBrack}
                  </Box>
                )}
              </div>
            )}

            <div className="ratecard-info text-center">
              <header>Rate Type</header>
              <div>{RATE_TYPE_OPTIONS_2_BY_ID[rateType].label}</div>
            </div>
            <div className="rc-center">
              {!editing && (
                <LinkButton color="primary" to={`/searches/${id}`}>
                  View
                </LinkButton>
              )}
            </div>
          </Inline>
          <Box className="tagbox" css={{ paddingLeft: editing ? 34 : 0 }}>
            {tagSection}
          </Box>
        </CardListItem>
      );
    }
  }
);

SavedSearchesItem.propTypes = {
  store: PropTypes.object.isRequired,
  rateCard: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default SavedSearchesItem;
