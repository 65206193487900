import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import CheckBox from "../../shared/CheckBox";
import Inline from "../../../components/lib/Inline";
import { styled } from "../../../../src/stitches.config";

const RateCardListItem = styled("div", {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  margin: "0",
  padding: "20px",
  transition: "all 200ms",
});

const RateCardLabel = styled("div", {
  color: "$brand",
  transition: "all 200ms",
  lineHeight: "1.2",
  minWidth: "0",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "inline-block",
  maxWidth: "100%",
  width: "60ch",
});

const LocationListItem = observer(
  class extends Component {
    render() {
      const props = this.props;
      const region = props.region;
      const editing = region.viewState.editing;
      const selected = region.viewState.selected;
      const name = region.name;
      const type = region.locationType.value;

      let onItemClick = this.props.onClick;
      let editingSection = null;
      if (editing) {
        onItemClick = region.toggleSelected;
        editingSection = (
          <CheckBox
            type={props.store.allowMultipleItemSelection ? "checkbox" : "radio"}
            name="list-item-select"
            value={name}
            selected={selected}
            style={{ width: 34 }}
            onClick={onItemClick}
          />
        );
      }

      return (
        <div className="ratecard-list-item-container ws-table">
          <RateCardListItem selected={selected} onClick={onItemClick}>
            <Inline css={{ gap: "$1" }}>
              {editingSection}
              <RateCardLabel>{name}</RateCardLabel>
            </Inline>
            <div className="ratecard-list-item-right">
              <div className="ratecard-info w-300 text-center">
                <header>Type</header>
                <div>{type}</div>
              </div>
            </div>
          </RateCardListItem>
        </div>
      );
    }
  }
);

LocationListItem.propTypes = {
  store: PropTypes.object.isRequired,
  //rateCard: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default LocationListItem;
