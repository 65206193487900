import React from "react";
import { useRecoilState } from "recoil";

import {
  SurveyHistoryTableDataStateObject,
  surveyHistoryTableGlobalState,
} from "../../globalState";
import { surveysHistoryTableColumns } from "./SurveysHistoryTableConfig";
import { renderTableColumnsSpecs } from "../../../private_index/components/ExtendedRestfulTables";
import { TableFilterableRestful } from "../../../../views/private_index/components/ExtendedRestfulTables";
import { useTableSchemaState } from "../../../../components/tables/Table";

import type { DjangoPaginatedResponse } from "../../../../types/django";
import type { RestfulTableDataStateObject } from "../../../../components/tables/types";
import type { AttemptDataMap } from "../../types";
import type { TableFilterableRestfulProps } from "../../../../components/tables/TableFilterableRestful";

interface SurveysHistoryTableProps extends Omit<TableFilterableRestfulProps, "schema"> {}

const SurveysHistoryTable = (props: SurveysHistoryTableProps) => {
  const [schema] = useTableSchemaState(props.children);
  return <TableFilterableRestful schema={schema} {...props} />;
};

SurveysHistoryTable.displayName = "SurveysHistoryTable";

type SurveysHistoryTableViewProps = {
  dataProvider: () => Promise<RestfulTableDataStateObject<AttemptDataMap>>;
  filtersDataProvider: () => Promise<DjangoPaginatedResponse<any>>;
};

const SurveysHistoryTableView = (props: SurveysHistoryTableViewProps) => {
  const { dataProvider, filtersDataProvider } = props;

  // state

  const [tableState] = useRecoilState<SurveyHistoryTableDataStateObject>(
    surveyHistoryTableGlobalState
  );

  // render

  const columns = React.useMemo(
    () => renderTableColumnsSpecs(surveysHistoryTableColumns),
    []
  );

  return (
    <SurveysHistoryTable
      multimode
      dataProvider={dataProvider}
      filtersDataProvider={filtersDataProvider}
      {...tableState}
    >
      {columns}
    </SurveysHistoryTable>
  );
};

SurveysHistoryTableView.displayName = "SurveysHistoryTableView";

export default SurveysHistoryTableView;
