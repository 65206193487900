import React from "react";

import { globalContextDefaults } from "../../../globalContext";
import { omitObjectKeys } from "../../../utils/object";

import type { GlobalContextObject } from "../../../globalContext";
import type { AuthDataObject } from "../types";

export type Val5KPublicContextObject = Omit<
  GlobalContextObject,
  "fetchAPINew" | "fetchGraphQLNew"
> & {
  navigateToSurveysList: () => void;
  auth: AuthDataObject;
};

const Val5KPublicContext = React.createContext<Val5KPublicContextObject>({
  ...omitObjectKeys(globalContextDefaults, ["fetchAPINew", "fetchGraphQLNew"]),
  navigateToSurveysList: () => {},
  auth: {},
} as Val5KPublicContextObject);

export function useVal5KPublicContext() {
  return React.useContext(Val5KPublicContext);
}

export default Val5KPublicContext;
