import { action } from "mobx";
import moment from "moment";
import { RATE_TYPE } from "../constants/search";

export default class ScheduledSearchList {
  constructor(store, object) {
    this.store = store;
    this.uuid = null;
    this.rateCardId = null;
    this.rateCardLabel = null;
    this.jobsTotal = 0;
    this.status = null;
    this.created = null;
    this.rateTypeValue = null;
    this.createdDisplay = null;
    this.jobs = [];
    /*this.user = null;
		this.owner = null;*/
    // providing options for selecting and editing
    this.viewState = {
      selected: false,
      editing: false,
    };

    if (object) {
      this.uuid = object.uuid || null;
      this.rateCardId = object.rateCardId || null;
      this.rateCardLabel = object.rateCardLabel || null;
      this.jobsTotal = object.searchesTotal || 0;
      this.created = moment(object.created) || null;
      this.status = object.statusDisplay || null;
      this.jobs = object.jobs || [];
      this.createdDisplay = `Created ${this.created.format("MMMM D, YYYY")}`;
      const getKey = (obj, val) => Object.keys(obj).find((key) => obj[key] === val);
      this.rateTypeValue = getKey(RATE_TYPE, object.rateType);
      /*this.user = object.user || {};
      this.owner = `${object.user.firstName} ${object.user.lastName}` || null;*/
    }

    this.toggleEdit = action(this.toggleEdit.bind(this));
    this.toggleSelected = action(this.toggleSelected.bind(this));
    this.destroy = action(this.destroy.bind(this));
  }

  toggleEdit() {
    const viewState = this.store.scheduledSearchListViewState.get(this.uuid);
    viewState.editing = !viewState.editing;
  }

  toggleSelected(e, ignore1 = null, ignore2 = null, setValue = null) {
    const viewState = this.store.scheduledSearchListViewState.get(this.uuid);

    if (setValue !== null) {
      viewState.selected = setValue;
      return;
    } else {
      viewState.selected = !viewState.selected;

      if (!viewState.selected && this.store.allSelected) {
        this.store.allSelected = false;
      }
    }

    if (viewState.selected === false) {
      this.store.unSelectedSchedules.push(this.uuid.toString());
    } else {
      for (var i = 0; i < this.store.unSelectedSchedules.length; i++) {
        if (this.store.unSelectedSchedules[i] === this.uuid) {
          this.store.unSelectedSchedules.splice(i, 1);
        }
      }
    }

    if (!this.store.allowMultipleItemSelection) {
      // deselect all other jobs
      this.store.scheduledSearchListViewState.forEach((viewState) => {
        if (this.viewState === viewState) return;

        viewState.selected = false;
      });
    }
  }

  destroy() {
    this.store.scheduledSearchLists.remove(this);
  }

  toJS() {
    return {
      uuid: this.uuid,
      rateCardId: this.rateCardId,
      rateCardLabel: this.rateCardLabel,
      jobsTotal: this.jobsTotal,
    };
  }

  static fromJS(store, object) {
    return new ScheduledSearchList(store, object);
  }
}
