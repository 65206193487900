import numeral from "numeral";

export function formatAnnualRate(value, symbol) {
  return `${symbol}${numeral(value).format("0,0")}`;
}

export function formatAnnualRateShort(value, symbol) {
  return `${symbol}${numeral(value).format("0,0a.[00]")}`;
}

export function formatHourlyRate(value, symbol) {
  return `${symbol}${numeral(value).format("0,0.00")}`;
}

export function formatRate(rateType, value, symbol) {
  switch (rateType) {
    // Hourly
    case 1:
    default:
      return formatHourlyRate(value, symbol);
    // Annual
    case 2:
      return formatAnnualRate(value, symbol);
  }
}
