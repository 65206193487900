import React from "react";
// import { observer } from "mobx-react";
import fieldTypes from "./fields";
import FieldLabel from "./fields/FieldLabel";
import checkFieldUpdated from "../utils/checkFieldUpdated";
import fields from "../components/fields";
import { Row, Col } from "react-bootstrap";
import {
  Card,
  CardHeading,
  CardListGroup,
  CardListGroupItem,
} from "../../../components/material";
import fieldValueGetter from "../utils/fieldValueGetter";
import * as filter from "../utils/filter";
import PureRenderImmutableComponent from "../../../utils/immutableRender";
import {
  RATE_TYPE_OPTIONS,
  RATE_TYPES,
  SALARY_RATE_TYPE_OPTIONS,
} from "../../../constants/negotiationWorksheet";

class Group extends PureRenderImmutableComponent {
  /**
   * Grabs the field value for a given field.
   */
  _getFieldValue(store, field) {
    return fieldValueGetter(
      store.currentState.unsavedValues,
      store.values,
      field,
      true,
      false
    );
  }

  /**
   * Checks to see if the field is updated from the latest saved (or default) value.
   */
  _isFieldUpdated(store, field, value = null) {
    // store.isFieldUpdated(field, value);
    return checkFieldUpdated(
      store.currentState.unsavedValues,
      store.values,
      field,
      value
    );
  }

  /**
   * Updates the field value state (or deletes it from the state if it's the same as the last saved / default value).
   */
  _updateFieldValue(store, field, value) {
    // if (field.key.includes("margin")) console.log(field.key, "changed:", value);
    store.valUpdated = !store.valUpdated;
    var localCurrentState = {};
    var lastUnsavedValues = [];
    var key;

    if (store.currentState.unsavedValues.length === undefined) {
      for (key in store.currentState.unsavedValues) {
        let hash = {};
        hash[key] = store.currentState.unsavedValues[key];
        lastUnsavedValues.push(hash);
      }
    } else {
      for (var i = 0; i < store.currentState.unsavedValues.length; i++) {
        let hash = {};
        hash[Object.keys(store.currentState.unsavedValues[i])[0]] = Object.values(
          store.currentState.unsavedValues[i]
        )[0];
        lastUnsavedValues.push(hash);
      }
    }

    for (key in store.currentState) {
      if (key === "unsavedValues") {
        localCurrentState["unsavedValues"] = lastUnsavedValues;
      } else {
        localCurrentState[key] = store.currentState[key];
      }
    }
    store.undoQueue.push(localCurrentState);
    const fieldUpdated = this._isFieldUpdated(store, field, value);
    var newField = {};
    newField[field.key] = value;
    if (store.currentState.unsavedValues.length === undefined) {
      if (store.currentState.unsavedValues.hasOwnProperty(field.key)) {
        store.currentState.unsavedValues[field.key] = value;
      } else if (value) {
        store.currentState.unsavedValues[field.key] = value;
      }
    } else {
      let index = store.currentState.unsavedValues.findIndex((x) =>
        x.hasOwnProperty(field.key)
      );

      if (fieldUpdated) {
        if (
          store.currentState.unsavedValues.find(function (obj) {
            return obj.hasOwnProperty(field.key);
          })
        ) {
          store.currentState.unsavedValues.find(function (obj) {
            return obj.hasOwnProperty(field.key);
          })[field.key] = value;
        } else {
          store.currentState.unsavedValues.push(newField);
        }
      } else {
        if (index !== -1) {
          store.currentState.unsavedValues.splice(index, 1);
        }
      }
    }
  }

  mapElements(field) {
    let hash = {};
    hash[field.key] = field.value;
    return hash;
  }

  render() {
    const { group, workerTypeId, gpfields, store } = this.props;

    const fields =
      store.currentState.payType === 3
        ? gpfields
            .filter((field) => group.fields.includes(field.id))
            .filter(filter.all(filter.rateTypeContains("salary")))
        : gpfields
            .filter((field) => group.fields.includes(field.id))
            .filter(filter.all(filter.notNull, filter.workerTypesContains(workerTypeId)));

    // store.values = fields.map(this.mapElements.bind(this));

    if (!fields.length) return null;

    return (
      <Card>
        <CardHeading title={group.label} tooltip={group.description} />
        <CardListGroup>{fields.map(this._renderField.bind(this))}</CardListGroup>
      </Card>
    );
  }

  _renderField(field) {
    const { store, payRateHourly, billRateHourly, conversionOptions, currencySymbol } =
      this.props;
    const { Field, CalculatedValue } = fieldTypes[field.fieldType];
    store.values.push(this.mapElements(field));

    var fieldReadOnly = false;
    if (field.fieldTypeConfig.readOnly) {
      fieldReadOnly = field.fieldTypeConfig.readOnly;
    } else {
      fieldReadOnly = false;
    }

    let value = this._getFieldValue(store, field, conversionOptions);

    if (field.fieldType === "scriptedPercentage") {
      const payRate = {
        hourly: payRateHourly,
        daily: RATE_TYPE_OPTIONS[RATE_TYPES.DAILY].convertFromHourly(
          payRateHourly,
          conversionOptions
        ),
        weekly: RATE_TYPE_OPTIONS[RATE_TYPES.WEEKLY].convertFromHourly(
          payRateHourly,
          conversionOptions
        ),
        monthly: RATE_TYPE_OPTIONS[RATE_TYPES.MONTHLY].convertFromHourly(
          payRateHourly,
          conversionOptions
        ),
        annualy: SALARY_RATE_TYPE_OPTIONS.convertFromHourly(
          payRateHourly,
          conversionOptions
        ),
      };
      const calculate = fields.scriptedPercentage.calculateValue;
      value = calculate(field, payRate);
    }

    return (
      <CardListGroupItem key={field.id}>
        <Row>
          <Col sm={4}>
            <FieldLabel field={field} isUpdated={this._isFieldUpdated(store, field)} />
          </Col>
          <Col sm={4}>
            {fieldReadOnly}
            <Field
              id={`${field.key}-input`}
              value={value}
              onBlur={this.pushUndoState}
              currencySymbol={currencySymbol}
              onChange={this._updateFieldValue.bind(this, store, field)}
              disabled={fieldReadOnly}
            />
          </Col>
          <Col sm={4}>
            <CalculatedValue
              value={value}
              payType={store.currentState.payType}
              rateType={store.currentState.rateType}
              payRateHourly={parseFloat(payRateHourly)}
              billRateHourly={parseFloat(billRateHourly)}
              currencySymbol={currencySymbol}
              conversionOptions={conversionOptions}
            />
          </Col>
        </Row>
      </CardListGroupItem>
    );
  }
}

export default Group;
