import React from "react";

import Grid from "../../../../components/lib/Grid";
import Stack from "../../../../components/lib/Stack";
import Text from "../../../../components/lib/Text";
import Alert from "../../../../components/lib/Alert";
import SurveyPrimaryParametersBlock from "./SurveyPrimaryParametersBlock";
import QuestionsBlock from "../../components/QuestionsBlock";
import { SimpleLink } from "../../../../components/lib/Link";
import { useReviewDataContext } from "../../../validator5K_admin/context/ReviewDataContext";
import { baseUrlPath } from "../../constants";

type Props = {
  secondaryParametersBlock?: React.ReactElement;
};

export default function SurveyParametersBlock(props: Props) {
  const { secondaryParametersBlock } = props;
  const { reviewId, reviewsGroupId, instructions } = useReviewDataContext();

  // render

  let warning = null;
  if (reviewsGroupId != null) {
    const peckingOrderPath = `${baseUrlPath}/surveys/${reviewId}/pecking-order`;

    warning = (
      <Alert fill color="warning">
        <Text as="h3" css={{ marginTop: 0 }}>
          This survey is a part of a group.
        </Text>
        <Text>
          Feel free to check other surveys in the group.&nbsp;
          <SimpleLink href={peckingOrderPath}>See surveys pecking order.</SimpleLink>
        </Text>
      </Alert>
    );
  }

  return (
    <Stack css={{ alignItems: "flex-start" }}>
      {warning}
      {instructions && (
        <Alert fill color="warning">
          <Text as="h3" css={{ marginTop: 0 }}>
            Extra instructions.
          </Text>
          {instructions}
        </Alert>
      )}
      <Grid
        css={{
          width: "$full",
          gridTemplateColumns: "$auto",
          gridAutoFlow: "row",
          gap: "$6",
          "@md": {
            gridAutoFlow: "column",
            gridTemplateColumns: "$auto $3-of-5",
          },
        }}
      >
        <SurveyPrimaryParametersBlock />
        <Stack fill css={{ alignItems: "flex-start" }}>
          {secondaryParametersBlock}
          <QuestionsBlock />
        </Stack>
      </Grid>
    </Stack>
  );
}

SurveyParametersBlock.displayName = "SurveyParametersBlock";
