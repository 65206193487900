/**
 * @generated SignedSource<<037000df85cb657cc7fe8fe2b1ab9fa7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PayDifferenceRefetchQuery$variables = {
  searchIDs?: ReadonlyArray<string | null> | null;
};
export type PayDifferenceRefetchQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"PayDifference_viewer">;
  } | null;
};
export type PayDifferenceRefetchQuery = {
  response: PayDifferenceRefetchQuery$data;
  variables: PayDifferenceRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": ([]/*: any*/),
    "kind": "LocalArgument",
    "name": "searchIDs"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "regionId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locationId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PayDifferenceRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "searchIDs",
                "variableName": "searchIDs"
              }
            ],
            "kind": "FragmentSpread",
            "name": "PayDifference_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PayDifferenceRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "resourceType",
                    "value": "SEARCH"
                  }
                ],
                "concreteType": "TrapsResourceAllocation",
                "kind": "LinkedField",
                "name": "resourceAllocations",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "balance",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isUnlimited",
                    "storageKey": null
                  }
                ],
                "storageKey": "resourceAllocations(resourceType:\"SEARCH\")"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "order",
                "value": [
                  {
                    "field": "NAME"
                  }
                ]
              }
            ],
            "concreteType": "NewRegionNodeConnection",
            "kind": "LinkedField",
            "name": "regions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NewRegionNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NewRegionNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LocationNode",
                        "kind": "LinkedField",
                        "name": "locations",
                        "plural": true,
                        "selections": [
                          (v4/*: any*/),
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "LocationNode",
                            "kind": "LinkedField",
                            "name": "parent",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v2/*: any*/),
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "regions(order:[{\"field\":\"NAME\"}])"
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "ids",
                "value": []
              }
            ],
            "concreteType": "LocationListLookupNode",
            "kind": "LinkedField",
            "name": "locationsByIds",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lat",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lon",
                "storageKey": null
              }
            ],
            "storageKey": "locationsByIds(ids:[])"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CountryListNode",
            "kind": "LinkedField",
            "name": "countries",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isoCode",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "only",
                    "variableName": "searchIDs"
                  }
                ],
                "kind": "ObjectValue",
                "name": "filters"
              }
            ],
            "concreteType": "SaveSearchNodeConnection",
            "kind": "LinkedField",
            "name": "savedsearches",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SaveSearchNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SaveSearchNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "searchId",
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "locationFullLabel",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "country",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "locationLabel",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CurrencyNode",
                        "kind": "LinkedField",
                        "name": "currency",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "iso",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NewRegionNode",
                        "kind": "LinkedField",
                        "name": "region",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RateSearchResponse",
                        "kind": "LinkedField",
                        "name": "rates",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rawMin",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rawAvg",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rawMid",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rawMax",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MarketRatesNode",
                        "kind": "LinkedField",
                        "name": "marketrates",
                        "plural": true,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "LevelType",
                            "kind": "LinkedField",
                            "name": "level",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "legacyId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "value",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "payRateMin",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "payRateMid",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "payRateMax",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "payRateAvg",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "billRateMin",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "billRateMid",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "billRateMax",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "billRateAvg",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "markupPctMin",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "markupPctMid",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "markupPctMax",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "markupPctAvg",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0f5a07ca2acd37fd41a4e8bb17e4161f",
    "id": null,
    "metadata": {},
    "name": "PayDifferenceRefetchQuery",
    "operationKind": "query",
    "text": "query PayDifferenceRefetchQuery(\n  $searchIDs: [ID] = []\n) {\n  viewer {\n    ...PayDifference_viewer_2oQv0f\n  }\n}\n\nfragment ModifyLocationsDialog_viewer on Viewer {\n  user {\n    resourceAllocations(resourceType: SEARCH) {\n      id\n      balance\n      isUnlimited\n    }\n  }\n  ...RegionsField_viewer\n}\n\nfragment PayDifferenceMap_viewer on Viewer {\n  locationsByIds(ids: []) {\n    locationId\n    lat\n    lon\n  }\n}\n\nfragment PayDifference_viewer_2oQv0f on Viewer {\n  ...ModifyLocationsDialog_viewer\n  ...PayDifferenceMap_viewer\n  countries {\n    id\n    locationId\n    isoCode\n    name\n  }\n  savedsearches(filters: {only: $searchIDs}) {\n    edges {\n      node {\n        searchId\n        locationId\n        locationFullLabel\n        country\n        locationLabel\n        currency {\n          id\n          iso\n        }\n        region {\n          name\n          regionId\n          id\n        }\n        rates {\n          rawMin\n          rawAvg\n          rawMid\n          rawMax\n        }\n        marketrates {\n          id\n          level {\n            legacyId\n            value\n          }\n          payRateMin\n          payRateMid\n          payRateMax\n          payRateAvg\n          billRateMin\n          billRateMid\n          billRateMax\n          billRateAvg\n          markupPctMin\n          markupPctMid\n          markupPctMax\n          markupPctAvg\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment RegionsField_viewer on Viewer {\n  regions(order: [{field: NAME}]) {\n    edges {\n      node {\n        name\n        regionId\n        locations {\n          locationId\n          name\n          parent {\n            locationId\n            name\n            id\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5c9ab9c4e7bf1aaab490ebff56f012a0";

export default node;
