// @flow

import React from "react";
import { observer } from "mobx-react";
import { RATE_TYPES } from "../../../constants/rateTypes";
import AnnualPunchOutsTableView from "./AnnualPunchOutsTableView";
import HourlyPunchOutsTableView from "./HourlyPunchOutsTableView";
import BuyRateState from "../../../models/BuyRateState";

const PunchOutRatesTab = (props: { buyRateState: BuyRateState }) => {
  let search = props.buyRateState.search;
  const rateType = search.frequency.title.toLowerCase();

  if (rateType === RATE_TYPES.ANNUAL)
    return <AnnualPunchOutsTableView buyRateState={props.buyRateState} />;
  return <HourlyPunchOutsTableView buyRateState={props.buyRateState} />;
};

export default observer(PunchOutRatesTab);
