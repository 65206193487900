// @flow

import R from "ramda";
import { extendObservable, action, runInAction, computed, observable } from "mobx";
import axios from "axios";
import PaginationState from "../../../models/PaginationState";
import CurrentUser from "../../../models/User";
import NetworkState from "../../../models/NetworkState";
import type { PageQuery, PaginationInfo } from "../../../models/PaginationState";
import ClientList from "../../../models/ClientList";
import FilterObject, { FILTER_COLUMN } from "../../../models/Filter";
import { CreatedOnFilter, RegionFilter, ActiveFilter } from "../../../models/FilterState";
import { consolidateAppliedFilters, consolidateAppliedSorts } from "../SupportFunctions";
import ModalState from "../../../models/ModalState";
import Sort, { SORT_DIRECTION } from "../../../models/Sort";
import ApplyTagState from "../../../models/ApplyTagState";
import type { FetchGraphQL, FetchAPI } from "../../../App";

export class ClientListComponentStore {
  showHelpModal: boolean;
  getClients: (PageQuery) => Promise<PaginationInfo>;
  network: NetworkState;
  pagination: PaginationState;
  totalCount: any;
  clientsList: ClientList[];
  clientsListView: ClientList[];
  clientsListViewState: Object;
  createdOnFilter: CreatedOnFilter;
  clientFilter: RegionFilter;
  activeFilter: ActiveFilter;
  currentUser: CurrentUser;
  defaultFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedSorts: {
    [key: FilterColumn]: Sort,
  };
  isFiltered: boolean;
  appliedSortsOrder: Array<FilterColumn>;
  unSelectedClients: [];

  showHelp: () => void;
  hideHelp: () => void;
  applyDefaultFilter: (FilterColumn, FilterObject) => void;
  applyFilter: (FilterColumn, FilterObject) => void;
  removeFilter: (FilterColumn) => void;
  applySort: (FilterColumn, Sort) => void;
  removeSort: (FilterColumn) => void;
  clearFilters: () => void;
  isEditing: ?boolean;
  handleStartEdit: () => void;
  handleStopEdit: () => void;
  toggleSelectAllPage: (Object) => void;
  selectAllPage: (Event) => void;
  deselectAllPage: (Event) => void;
  clearAllSelections: () => void;
  allSelected: boolean;
  allSelectedfilter: boolean;
  allOnPageSelected: boolean;
  allowMultipleItemSelection: boolean;
  confirmDeleteModal: ModalState;
  helpModal: ModalState;
  getSelectedClientsList: () => Array<string>;
  getFilterCriteriaQuery: (FilterColumn) => GraphQLQuery;
  processFilterCriteria: (FilterColumn, Object) => Array<Object>;
  applyDefaultSort: () => void;
  applyTagState: ApplyTagState;
  isTagView: ?boolean;
  selectAllOnPageItem: () => void;
  fetchGraphQL: FetchGraphQL;

  constructor(fetchGraphQL: FetchGraphQL) {
    this.fetchGraphQL = fetchGraphQL;
    this.getClients = action(this.getClients.bind(this));

    this.applyFilter = action(this.applyFilter.bind(this));
    this.applySort = action(this.applySort.bind(this));
    this.removeFilter = action(this.removeFilter.bind(this));
    this.removeSort = action(this.removeSort.bind(this));
    this.unSelectedClients = [];

    extendObservable(this, {
      helpModal: new ModalState(),
      network: new NetworkState(),
      pagination: new PaginationState(this.getClients),
      totalCount: 0,
      clientsList: [],
      allSelected: false,
      allSelectedfilter: false,
      clientsListViewState: observable.map({}),
      clientsListView: computed(() => {
        return this.clientsList.map((client) => {
          if (this.clientsListViewState.has(client.id)) {
            client.viewState = this.clientsListViewState.get(client.id);

            return client;
          }

          return client;
        });
      }),
      allOnPageSelected: computed(() => {
        const allTrue = R.all(R.equals(true));
        const selectedValues = this.clientsListView.map(
          (clientsListView) => clientsListView.viewState.selected
        );

        if (selectedValues.length === 0) {
          return false;
        }

        return allTrue(selectedValues);
      }),
      appliedSortsOrder: observable.shallow([]),
      createdOnFilter: new CreatedOnFilter(
        this,
        FILTER_COLUMN.REGION_DATE_RANGE,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      clientFilter: new RegionFilter(
        this,
        FILTER_COLUMN.REGION_LABEL,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      activeFilter: new ActiveFilter(
        this,
        FILTER_COLUMN.SHARED,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      defaultFilters: {},
      appliedFilters: {},
      appliedSorts: {},
      isFiltered: false,
      isEditing: null,
      allowMultipleItemSelection: true,
      selectedCount: computed(() => {
        const selectedValues = this.clientsListView.map(
          (client) => client.viewState.selected
        );

        if (this.allSelected) {
          return this.pagination.totalCount;
        }

        let count = 0;

        selectedValues.forEach((v) => {
          if (v) {
            count += 1;
          }
        });

        return count;
      }),
      confirmDeleteModal: new ModalState(),
      applyTagState: new ApplyTagState(fetchGraphQL),
      isTagView: null,
    });

    this.currentUser = null;
    this.applyDefaultFilter = action(this.applyDefaultFilter.bind(this));
    this.handleStartEdit = action(this.handleStartEdit.bind(this));
    this.handleStopEdit = action(this.handleStopEdit.bind(this));
    this.clearFilters = action(this.clearFilters.bind(this));
    this.toggleSelectAllPage = action(this.toggleSelectAllPage.bind(this));
    this.selectAllPage = action(this.selectAllPage.bind(this));
    this.deselectAllPage = action(this.deselectAllPage.bind(this));
    this.clearAllSelections = action(this.clearAllSelections.bind(this));
    this.getSelectedClientsList = action(this.getSelectedClientsList.bind(this));
    this.getFilterCriteriaQuery = action(this.getFilterCriteriaQuery.bind(this));
    this.processFilterCriteria = action(this.processFilterCriteria.bind(this));
    this.showHelp = action(this.showHelp.bind(this));
    this.hideHelp = action(this.hideHelp.bind(this));
    this.applyDefaultSort = action(this.applyDefaultSort.bind(this));
    this.selectAllOnPageItem = action(this.selectAllOnPageItem.bind(this));
    this.applyDefaultSort();
  }

  applyDefaultSort() {
    this.activeFilter.sortState.direction = SORT_DIRECTION.ASC;
    this.activeFilter.sort = this.activeFilter.buildQuerySort();
    this.applySort(this.activeFilter.column, this.activeFilter.sort);
  }

  showHelp() {
    this.showHelpModal = true;
  }

  hideHelp() {
    this.showHelpModal = false;
  }

  handleStartEdit() {
    this.isEditing = true;
    this.clientsListView.forEach((client) => client.toggleEdit());
  }

  handleStopEdit() {
    this.isEditing = false;
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.clientsListViewState.forEach((viewState) => {
      viewState.selected = false;
      viewState.editing = false;
    });
  }

  toggleSelectAllPage(e: Object) {
    if (!this.allowMultipleItemSelection) return;

    const setValue = !this.allOnPageSelected;

    this.clientsListView.forEach((client) => {
      client.toggleSelected(e, null, null, setValue);
    });

    // When All items selected flag is up, clear selection
    if (setValue === false && this.allSelected) this.allSelected = false;
  }

  selectAllPage(e: Event) {
    this.unSelectedClients = [];
    this.allSelected = true;
    this.allSelectedfilter = true;
  }

  selectAllOnPageItem(e: Event) {
    this.clientsListView.forEach((client) => {
      client.toggleSelected(e, null, null, true);
    });
  }

  deselectAllPage(e: Event) {
    this.clientsListView.forEach((client) => {
      client.toggleSelected(e, null, null, false);
    });

    this.allSelected = false;
    this.allSelectedfilter = false;
  }

  clearAllSelections() {
    this.isTagView = false;
    //  this.isEditing = false;
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.clientsListViewState.forEach((value) => {
      value.selected = false;
      //value.editing = false;
    });
  }

  applyFilter(column: FilterColumn, filter: FilterObject) {
    this.appliedFilters[column] = filter;
    this.isFiltered = true;
  }

  applyDefaultFilter(column: FilterColumn, filter: FilterObject) {
    this.defaultFilters[column] = filter;
  }

  removeFilter(column: FilterColumn) {
    delete this.appliedFilters[column];

    let entries = Object.entries(this.appliedFilters);
    if (!entries.length) this.isFiltered = false;
  }

  applySort(column: FilterColumn, sort: Sort) {
    this.appliedSorts[column] = sort;

    const index = this.appliedSortsOrder.indexOf(column);
    if (index === -1) this.appliedSortsOrder.push(column);
  }

  removeSort(column: FilterColumn) {
    delete this.appliedSorts[column];

    const index = this.appliedSortsOrder.indexOf(column);
    if (index > -1) this.appliedSortsOrder.splice(index, 1);
  }

  //clear all filters
  clearFilters() {
    this.clientFilter = new RegionFilter(
      this,
      FILTER_COLUMN.REGION_LABEL,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.createdOnFilter = new CreatedOnFilter(
      this,
      FILTER_COLUMN.REGION_DATE_RANGE,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.activeFilter = new ActiveFilter(
      this,
      FILTER_COLUMN.SHARED,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );

    this.appliedFilters = observable({});
    this.appliedSorts = observable({});
    this.appliedSortsOrder.length = 0;
    this.isFiltered = false;
    this.applyDefaultSort();

    return this.pagination.goFetch(null);
  }

  //creating country filter query
  getFilterCriteriaQuery(column: FilterColumn): GraphQLQuery {
    switch (column) {
      default:
        return null;
    }
  }

  //process country filter result data
  processFilterCriteria(column: FilterColumn, payload: Object): Array {
    switch (column) {
      default:
        return null;
    }
  }

  toggleAllItems() {
    if (!this.allowMultipleItemSelection) return;

    this.allSelected = !this.allSelected;

    if (this.allSelected === false) {
      this.projectCostsView.forEach((value) => {
        value.viewState.selected = false;
      });
    }
  }

  getSelectedClientsList(): Array<string> {
    const clientsList = this.clientsListViewState;

    let selectedClientsList = [];

    clientsList.forEach((value, key) => {
      if (value.selected) {
        selectedClientsList.push(key);
      }
    });

    return selectedClientsList;
  }

  //function for get client list
  async getClients(pageQuery: PageQuery): Promise<PaginationInfo> {
    let res = null;
    let params: string[] = pageQuery.params;
    let args = pageQuery.args;
    let variables = pageQuery.variables;
    let filtersCriteria: string[] = [];

    let sortCriteria: string[] = [];
    consolidateAppliedSorts(this.appliedSorts, sortCriteria);
    consolidateAppliedFilters(this.appliedFilters, params, filtersCriteria, variables);

    // NOTE: applied filters could override default filters, handle this if needed
    consolidateAppliedFilters(this.defaultFilters, params, filtersCriteria, variables);

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");
    const queryFiltersCriteria = filtersCriteria.join(", ");
    const querySortCriteria = sortCriteria.join(", ");
    //debugger
    const query = `
      query getClients(${queryParams}){
        viewer{
          allClients(${queryArgs},filters: { ${queryFiltersCriteria} }, order: [${querySortCriteria}]){
            totalCount
            edges{
              node{
                clientId
                name
                activeFlag
                isClientJobLibrary
                perSearchPricing
                isClientJobLibrary
                perSearchPricing
                totalUserCount
              }
            }
          }
        }
      }
      `;

    this.network.loading = true;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      console.log("error", e);
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting clients", e);
      if (res !== null) {
        this.network.logGraphQLError("Get clients query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getclients--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get client query", res)) {
        // TODO: Display user friendly error message
        return {
          totalCount: 0,
        };
      }
      //this.currentUser = new CurrentUser(this, res.data.viewer.user);
      const clients = res.data.viewer.allClients.edges;
      this.totalCount = res.data.viewer.allClients.totalCount;
      // // TODO: Deserialize this properly...
      this.clientsList = clients.map((reg) => {
        const client = new ClientList(this, reg.node);

        if (!this.clientsListViewState.has(client.id)) {
          this.clientsListViewState.set(client.id, {
            selected: this.allSelected,
            editing: this.isEditing,
          });
        } else {
          const selectedValue = this.allSelected
            ? true
            : this.clientsListViewState.get(client.id).selected;

          this.clientsListViewState.set(client.id, {
            selected: selectedValue,
            editing: this.isEditing,
          });
        }

        client.viewState = this.clientsListViewState.get(client.id);

        return client;
      });
      return {
        totalCount: res.data.viewer.allClients.totalCount,
      };
    });
  }
}

export default class ClientListStore extends ClientListComponentStore {
  exportClients: () => void;
  fetchAPI: FetchAPI;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI) {
    super(fetchGraphQL);
    this.fetchAPI = fetchAPI;

    // Bound early to pass into export state
    this.exportClients = action(this.exportClients.bind(this));
  }

  exportClients() {
    if (this.network.loading) {
      return;
    }

    let params = [];
    let args = [];
    let vars = {};
    let exportURL = "registration/basic/list/export/excel/";
    // Change the Filename
    var fileName = "export_clients_" + Math.floor(Math.random() * 9999999 + 1000000);
    let parameters = "";
    let exparams = "";

    if (this.allSelectedfilter) {
      consolidateAppliedFilters(this.appliedFilters, params, args, vars);
      vars.exclude = [];
      parameters = JSON.stringify(vars);
      if (parameters === "") {
        exparams = `{"fileName": "${fileName}","section":"admin","clientFilters": {${parameters}}}`;
      } else {
        exparams = `{"fileName": "${fileName}","section":"admin","clientFilters": ${parameters}}`;
      }
    } else {
      const selectedClients = this.getSelectedClientsList();
      if (!selectedClients || !selectedClients.length) {
        console.error("Cannot export Clients: No Clients selected");
        return;
      }
      vars.only = selectedClients;
      parameters = JSON.stringify(vars);
      if (parameters === "") {
        exparams = `{"fileName": "${fileName}","section":"admin" ,"clientFilters": {${parameters}}}`;
      } else {
        exparams = `{"fileName": "${fileName}","section":"admin" ,"clientFilters": ${parameters}}`;
      }
    }

    this.fetchAPI(exportURL, JSON.parse(exparams))
      .then((res) => {
        window.location.href = res.data.url;
        this.handleStopEdit();
      })
      .catch((e) => {
        console.error("Error downloading excel", e);
        // TODO: Handle errors properly
        throw e; // Prevent success action from running
      });
  }
}
