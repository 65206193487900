import React from "react";
import PropTypes from "prop-types";
import R from "ramda";

import PureRenderImmutableComponent from "../../../../utils/immutableRender";
import { styled } from "../../../../stitches.config";
import Stack from "../../../../components/lib/Stack";

const OptionList = styled("ul", {
  marginTop: "10px",
  marginBottom: "10px",
  margin: "0",
  padding: "0",
  textAlign: "center",
  fontSize: "14px",
  tableLayout: "fixed",
  borderTop: "1px solid $primaryLighter",
  borderBottom: "1px solid $primaryLighter",
  textDecoration: "none",
  display: "table",
  width: "100%",
});

const Option = styled("li", {
  padding: "10px",
  display: "table-cell",
  textTransform: "uppercase",
  borderRight: "1px solid $primaryLighter",
  cursor: "pointer",
  transition: "background-color 200ms",
  "&:hover": {
    backgroundColor: "$brandLightest",
  },
  "&:last-of-type": {
    borderRight: 0,
  },
  variants: {
    selected: {
      true: {
        backgroundColor: "$brandLighter",
      },
    },
  },
});

export default class SingleValuePicker extends PureRenderImmutableComponent {
  static propTypes = {
    label: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.node.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        hint: PropTypes.string,
      })
    ).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    className: PropTypes.string,
  };

  state = {
    value: this.props.value ? this.props.value : null,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        value: nextProps.value,
      });
    }
  }

  _handleClick = (value) => {
    const currentValue = this.state.value;

    if (value !== currentValue) {
      this.setState({ value }, () => {
        if (this.props.onChange) {
          this.props.onChange(value);
        }
      });
    }
  };

  render() {
    const { label, options, value: currentValue } = this.props;
    return (
      <Stack css={{ alignItems: "flex-start", gap: "$2" }}>
        {label && <h4>{label}</h4>}
        <OptionList role="listbox">
          {options.map(({ label, value, hint }) => {
            const selected = currentValue === value;

            return (
              <Option
                role="option"
                aria-selected={selected}
                selected={selected}
                key={value}
                onClick={R.partial(this._handleClick, value)}
                title={hint || label}
              >
                {label}
              </Option>
            );
          })}
        </OptionList>
      </Stack>
    );
  }
}
