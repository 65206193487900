// @flow
import { extendObservable } from "mobx";

export const FILE_TYPE = {
  SPREADSHEET: "excel",
  CSV: "csv",
};

export const LEVELS_TYPE = {
  DEFAULT: "default",
  THREE_LEVELS: "three_levels",
};

export const EXPORT_TYPE = {
  LIST: "basic",
  DETAILED: "expanded",
};

export const CURRENCY_TYPE = {
  DEFAULT: "default",
  USD: "usd",
};

export default class ExportOptions {
  fileName: string;
  fileType: string;
  levelType: string;
  exportType: string;
  currencyType: string;

  constructor(
    exportType: string = EXPORT_TYPE.DETAILED,
    fileType: string = FILE_TYPE.SPREADSHEET,
    levelType: string = LEVELS_TYPE.DEFAULT,
    currencyType: string = CURRENCY_TYPE.DEFAULT,
    fileName: string = null
  ) {
    extendObservable(this, {
      exportType,
      fileType,
      levelType,
      currencyType,
      fileName,
    });
  }
}
