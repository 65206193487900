// @flow
import React from "react";
import { observer } from "mobx-react";
import type MobXStore from "../../stores/mobx/MobXStore";
import SessionInfo from "../../models/SessionInfo";
import PSPList from "./per_search_pricing/List";
import JLList from "./job_library/List";

type Props = {
  store: MobXStore,
  sessionInfo: SessionInfo,
  router: Object,
};

const List = (props: Props) => {
  const sessionInfo = props.sessionInfo;
  const client = sessionInfo.client;
  const isJobLibraryClient = client && client.isClientJobLibrary;

  if (isJobLibraryClient) {
    return (
      <JLList store={props.store} sessionInfo={props.sessionInfo} router={props.router} />
    );
  } else {
    return (
      <PSPList
        store={props.store}
        sessionInfo={props.sessionInfo}
        router={props.router}
      />
    );
  }
};

export default observer(List);
