import React, { useState, useCallback, useMemo } from "react";
import { fromJS } from "immutable";

import Stack from "../../../components/lib/Stack";
import Text from "../../../components/lib/Text";
import Table from "../lib/Table";
import { THead, TBody, TR, TH, TD } from "../../../components/lib/Table";
import { decimalFormatter } from "../constants";
import { RATE_TYPES, RATE_TYPES_LABELS } from "../types";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";
import { useInitialFetch } from "../../../utils/hooks";
import { emptyMap } from "../../../constants";

import type {
  RATE_TYPES_TYPE,
  RatesSummaryValuesObject,
  RatesSummaryValuesMap,
} from "../types";
import type { FiltersQueryObject } from "../../../components/tables/types";
import type { FetchAPIFunc, FetchAPIResponse } from "../../../types/fetch";
import type { ImmutableMap } from "../../../types/immutable";
import type { ShowModalFunc } from "../../../components/modals/MessageModal";

type SummaryDataKey = `${string}_min` | `${string}_max`;
type SummaryData = {
  [key: SummaryDataKey]: number | null;
};

type SummaryDataMap = ImmutableMap<SummaryData>;

export type SummaryKeysType = {
  highKeys: SummaryDataKey[];
  lowKeys: SummaryDataKey[];
  highAnnualKeys: SummaryDataKey[];
  lowAnnualKeys: SummaryDataKey[];
};

const summaryKeys: SummaryKeysType = {
  highKeys: ["pay_rate_max__max", "markup_max__max", "bill_rate_max__max"],
  lowKeys: ["pay_rate_min__min", "markup_min__min", "bill_rate_min__min"],
  highAnnualKeys: ["annual_salary_max__max"],
  lowAnnualKeys: ["annual_salary_min__min"],
};

export type SummaryTableProps = {
  summaryData: SummaryDataMap;
  summaryKeys: SummaryKeysType;
  displayRateType: RATE_TYPES_TYPE;
  rateType: RATE_TYPES_TYPE;
  currencySymbol: string;
  needConvertedValue: boolean;
  convertToValue: (value: number) => number | null;
};

export const SummaryTable = (props: SummaryTableProps) => {
  const {
    summaryData,
    summaryKeys,
    currencySymbol,
    displayRateType,
    rateType,
    needConvertedValue,
    convertToValue,
  } = props;

  const feedbackSummaryKeys = [
    {
      label: "High",
      keysMatcher: {
        [RATE_TYPES.HOURLY]: summaryKeys.highKeys,
        [RATE_TYPES.ANNUAL]: summaryKeys.highAnnualKeys,
        [RATE_TYPES.DAILY]: summaryKeys.highKeys,
        [RATE_TYPES.WEEKLY]: summaryKeys.highKeys,
        [RATE_TYPES.MONTHLY]: summaryKeys.highKeys,
      },
    },
    {
      label: "Low",
      keysMatcher: {
        [RATE_TYPES.HOURLY]: summaryKeys.lowKeys,
        [RATE_TYPES.ANNUAL]: summaryKeys.lowAnnualKeys,
        [RATE_TYPES.DAILY]: summaryKeys.lowKeys,
        [RATE_TYPES.WEEKLY]: summaryKeys.lowKeys,
        [RATE_TYPES.MONTHLY]: summaryKeys.lowKeys,
      },
    },
  ];

  const renderRowValue = (key: SummaryDataKey) => {
    const value = summaryData.get(key);
    const isContingentValue = key.startsWith("pay_rate") || key.startsWith("bill_rate");
    const convertedValue =
      value && isContingentValue && needConvertedValue ? convertToValue(value) : null;

    return (
      <>
        {value != null ? decimalFormatter(value, false, currencySymbol) : "-"}
        {convertedValue != null
          ? ` (${decimalFormatter(
              convertedValue,
              false,
              currencySymbol
            )} ${RATE_TYPES_LABELS[displayRateType].toLowerCase()})`
          : ""}
      </>
    );
  };

  return (
    <Table highlighted={false} center highlightFirstColumn>
      <THead>
        <TR>
          <TH />
          {rateType !== RATE_TYPES.ANNUAL && <TH>Pay Rate</TH>}
          {rateType !== RATE_TYPES.ANNUAL && <TH>Markup</TH>}
          {rateType !== RATE_TYPES.ANNUAL && <TH>Bill Rate</TH>}
          {rateType === RATE_TYPES.ANNUAL && <TH>Annual Salary</TH>}
        </TR>
      </THead>
      <TBody>
        {feedbackSummaryKeys.map(({ label, keysMatcher }, idx) => {
          const keys = keysMatcher[rateType] || [];
          return (
            <TR key={idx}>
              <TD>{label}</TD>
              {keys.map((key, idx) => (
                <TD key={idx}>{renderRowValue(key as SummaryDataKey)}</TD>
              ))}
            </TR>
          );
        })}
      </TBody>
    </Table>
  );
};
SummaryTable.displayName = "SummaryTable";

type SummaryTableViewProps = {
  rateType: RATE_TYPES_TYPE;
  displayRateType: RATE_TYPES_TYPE;
  displayRateMultiplier: number | null;
  currencySymbol: string;
  filtersQuery: FiltersQueryObject;
  fetchArgusFilteringAPI: FetchAPIFunc;
  showModalError: ShowModalFunc;
};

const SummaryTableView = (props: SummaryTableViewProps) => {
  const {
    rateType,
    displayRateType,
    displayRateMultiplier,
    currencySymbol,
    filtersQuery,
    fetchArgusFilteringAPI,
    showModalError,
  } = props;

  const [summaryDataState, setSummaryDataState] = useState<RatesSummaryValuesMap>(
    emptyMap as unknown as RatesSummaryValuesMap
  );

  const requiresConversion = useMemo(
    () =>
      displayRateType === RATE_TYPES.DAILY ||
      displayRateType === RATE_TYPES.WEEKLY ||
      displayRateType === RATE_TYPES.MONTHLY,
    [displayRateType]
  );

  const needConvertedValue = useMemo(
    () => !!(requiresConversion && displayRateMultiplier && displayRateMultiplier !== 1),
    [requiresConversion, displayRateMultiplier]
  );

  const convertToValue = useCallback(
    (value: number) => (!displayRateMultiplier ? null : value * displayRateMultiplier),
    [displayRateMultiplier]
  );

  const fetchSummaryData = useCallback(async () => {
    try {
      const response: FetchAPIResponse<RatesSummaryValuesObject> =
        await fetchArgusFilteringAPI(`job-feedback/rates-summary-values/filtered/`, {
          data: filtersQuery,
        });
      const data = fromJS(response.data);
      setSummaryDataState(data);
    } catch (err: any) {
      logAsyncOperationError("fetchSummaryData", err);
      showModalError("Error occurred while retrieving validations summary data");
    }
  }, [filtersQuery, fetchArgusFilteringAPI, showModalError]);

  // initial data fetch

  useInitialFetch(fetchSummaryData);

  // rendering

  const hasSummaryData = useMemo(
    () => summaryDataState.filter((v) => v != null).size > 0,
    [summaryDataState]
  );
  if (!hasSummaryData) {
    return null;
  }

  return (
    <Stack
      fill
      css={{
        alignItems: "flex-start",
        width: "$full",
        "@sm": { width: "75%" },
        "@md": { width: "50%" },
      }}
    >
      <Text as="h5">All feedback summary:</Text>
      <SummaryTable
        summaryData={summaryDataState}
        summaryKeys={summaryKeys}
        rateType={rateType}
        displayRateType={displayRateType}
        currencySymbol={currencySymbol}
        needConvertedValue={needConvertedValue}
        convertToValue={convertToValue}
      />
    </Stack>
  );
};

SummaryTableView.displayName = "SummaryTableView";

export default SummaryTableView;
