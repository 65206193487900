import * as React from "react";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import type * as Stitches from "@stitches/react";
import { styled, config } from "../../stitches.config";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";

const Wrapper = styled("div", {
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  border: "2px solid",
  borderRadius: "$full",
  fontWeight: "$semibold",
  variants: {
    size: {
      "extra-small": {
        gap: "$2",
        padding: "$1 $2",
        fontSize: "$xs",
        lineHeight: "$xs",
      },
      small: {
        gap: "$2_5",
        padding: "$1_5 $2_5",
        fontSize: "$sm",
        lineHeight: "$sm",
      },
      normal: {
        gap: "$3",
        padding: "$2 $4",
        fontSize: "$base",
        lineHeight: "$base",
      },
      large: {
        gap: "$3_5",
        padding: "$2_5 $5",
        fontSize: "$lg",
        lineHeight: "$lg",
      },
      "extra-large": {
        gap: "$4",
        padding: "$3 $5",
        fontSize: "$2xl",
        lineHeight: "$2xl",
      },
    },
    color: {
      gray: {},
      success: {},
      danger: {},
      brand: {},
      green: {},
      yellow: {},
      red: {},
      blue: {},
    },
    variant: {
      filled: {},
      outlined: {},
      light: {},
    },
  },
  compoundVariants: [
    {
      variant: "filled",
      color: "gray",
      css: {
        color: "$ptGray300",
        backgroundColor: "$ptBlack400",
        borderColor: "$ptBlack400",
      },
    },
    {
      variant: "filled",
      color: "green",
      css: {
        color: "$ptGreen50",
        backgroundColor: "$ptGreen500",
        borderColor: "$ptGreen500",
      },
    },
    {
      variant: "filled",
      color: "yellow",
      css: {
        color: "$ptYellow900",
        backgroundColor: "$ptYellow500",
        borderColor: "$ptYellow500",
      },
    },
    {
      variant: "filled",
      color: "red",
      css: {
        color: "$ptRed50",
        backgroundColor: "$ptRed500",
        borderColor: "$ptRed500",
      },
    },
    {
      variant: "filled",
      color: "blue",
      css: {
        color: "$ptBlue900",
        backgroundColor: "$ptBlue500",
        borderColor: "$ptBlue500",
      },
    },
    {
      variant: "outlined",
      color: "gray",
      css: {
        color: "$ptBlack500",
        backgroundColor: "transparent",
        borderColor: "$ptBlack400",
      },
    },
    {
      variant: "outlined",
      color: "green",
      css: {
        color: "$ptGreen600",
        backgroundColor: "transparent",
        borderColor: "$ptGreen500",
      },
    },
    {
      variant: "outlined",
      color: "yellow",
      css: {
        color: "$ptYellow600",
        backgroundColor: "transparent",
        borderColor: "$ptYellow500",
      },
    },
    {
      variant: "outlined",
      color: "red",
      css: {
        color: "$ptRed500",
        backgroundColor: "transparent",
        borderColor: "$ptRed400",
      },
    },
    {
      variant: "outlined",
      color: "blue",
      css: {
        color: "$ptBlue600",
        backgroundColor: "transparent",
        borderColor: "$ptBlue500",
      },
    },
    {
      variant: "light",
      color: "gray",
      css: {
        color: "$ptBlack500",
        backgroundColor: "$primaryLight",
        borderColor: "$primaryLight",
      },
    },
    {
      variant: "light",
      color: "green",
      css: {
        color: "$ptGreen700",
        backgroundColor: "$ptGreen200",
        borderColor: "$ptGreen200",
      },
    },
    {
      variant: "light",
      color: "yellow",
      css: {
        color: "$ptYellow800",
        backgroundColor: "$ptYellow200",
        borderColor: "$ptYellow200",
      },
    },
    {
      variant: "light",
      color: "red",
      css: {
        color: "$ptRed500",
        backgroundColor: "$ptRed100",
        borderColor: "$ptRed100",
      },
    },
    {
      variant: "light",
      color: "blue",
      css: {
        color: "$ptBlue700",
        backgroundColor: "$ptBlue200",
        borderColor: "$ptBlue200",
      },
    },
    {
      variant: "light",
      color: "success",
      css: {
        color: "$successDarker",
        backgroundColor: "$successLightest",
        borderColor: "$successLightest",
      },
    },
    {
      variant: "light",
      color: "danger",
      css: {
        color: "$dangerDarker",
        backgroundColor: "$dangerLightest",
        borderColor: "$dangerLightest",
      },
    },
    {
      variant: "light",
      color: "danger",
      css: {
        color: "$dangerDarker",
        backgroundColor: "$dangerLightest",
        borderColor: "$dangerLightest",
      },
    },
    {
      variant: "light",
      color: "brand",
      css: {
        color: "$brandDarker",
        backgroundColor: "$brandLightest",
        borderColor: "$brandLightest",
      },
    },
  ],
  defaultVariants: {
    size: "normal",
    color: "gray",
    variant: "light",
  },
});

type BaseProps = React.ComponentPropsWithoutRef<"div"> &
  Stitches.VariantProps<typeof Wrapper>;

interface WrapperProps extends BaseProps {
  css?: Stitches.CSS<typeof config>;
}

export interface TagProps extends WrapperProps {
  text?: string;
  icon?: IconProp;
  iconRight?: IconProp;
  iconProps?: Omit<FontAwesomeIconProps, "icon">;
  children?: React.ReactNode;
}

const Tag = React.forwardRef<HTMLDivElement, TagProps>((props, ref) => {
  const { text, icon, iconRight, iconProps, children, ...wrapperProps } = props;

  return (
    <Wrapper {...wrapperProps} ref={ref}>
      {icon && <FontAwesomeIcon {...iconProps} icon={icon} />}
      {Boolean(children || text) && (
        <div>
          {text}
          {children}
        </div>
      )}
      {iconRight && <FontAwesomeIcon icon={iconRight} />}
    </Wrapper>
  );
});

export default Tag;
