import React, { Component } from "react";
import PropTypes from "prop-types";
import CountrySelectNew from "../../components/selects/CountrySelectNew";
import ContainerSection from "../ratecards/components/ContainerSection";
import RegionLocationModal from "../shared/modals/RegionLocationModal";
import { observer } from "mobx-react";
import TextInput from "../../components/lib/TextInput";
import LinkButton from "../../components/lib/LinkButton";
import Button from "../../components/lib/Button";
import Inline from "../../components/lib/Inline";
import { styled } from "../../../src/stitches.config";

const SelectLocations = styled("div", {
  border: "2px solid $primaryLight",
  borderRadius: "4px",
  padding: "10px 16px",
  fontSize: "medium",
  "&:hover": {
    backgroundColor: "$brandLightest",
    cursor: "pointer",
  },
});

/**
 * Create component for Region
 */
class Create extends Component {
  /**
   * componentDidMount
   * @return {void}
   */
  componentDidMount() {
    this.props.store.regionCreateStore.regionName = "";
    // if (this.props.store.regionCreateStore.messaging.messages.length > 0) {
    //   this.props.store.regionCreateStore.messaging.messages.length = 0;
    //   this.props.store.regionCreateStore.messaging.removeMessage.bind(this.props.store.regionCreateStore, this.props.store.regionCreateStore.messaging.messages[0])
    // }
    this.props.store.regionCreateStore.messaging.removeAll();
    this.props.store.regionCreateStore.countryMessaging.removeAll();
    this.props.store.regionCreateStore.regionMessaging.removeAll();
    this.props.store.regionCreateStore.selectedLocations = [];
    this.props.store.regionCreateStore.viewLocations = [];
    this.props.store.regionCreateStore.country = null;
    this.props.store.regionCreateStore.countryid = null;
    this.props.store.regionCreateStore.stateList = [];
    this.props.store.regionCreateStore.cityList = [];
  }

  componentWillUnmount() {
    this.props.store.regionCreateStore.country = null;
  }

  UNSAFE_componentWillMount() {
    this.props.store.regionCreateStore.country = null;
  }

  /**
   * Render rate card create page
   * @return {XML}
   */
  render() {
    const regionCreateStore = this.props.store.regionCreateStore;
    var locTags = [];

    if (regionCreateStore.calculateTags(regionCreateStore.viewLocations) !== "") {
      regionCreateStore
        .calculateTags(regionCreateStore.viewLocations)
        .split("|")
        .map((e) => {
          if (e !== " ") {
            locTags.push(
              <div>
                <span>{e}</span>
              </div>
            );
          }
          return locTags;
        });
    }

    return (
      <div className="view list regions">
        <div className="rc-container">
          <div className="above">
            <div className="ratecard-label-header ellipsis max-width-80 mb20">
              <h2 className="ellipsis">Create Region</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-sm-4 col-md-4">
              <label htmlFor="regionName" className="block">
                Region Name:
              </label>
              <TextInput
                size="normal"
                id="regionName"
                type="text"
                maxLength="250"
                fill="true"
                value={regionCreateStore.regionName}
                onChange={regionCreateStore.changeRegionNameText}
                data-testid="region-name-field"
              />
              {regionCreateStore.regionMessaging.messages.length > 0 && (
                <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                  <a
                    href="#close"
                    className="close"
                    data-dismiss="alert"
                    aria-label="close"
                    onClick={regionCreateStore.regionMessaging.removeMessage.bind(
                      regionCreateStore,
                      regionCreateStore.regionMessaging.messages[0]
                    )}
                  >
                    &times;
                  </a>
                  <strong>
                    {regionCreateStore.regionMessaging.messages[0].messageLabel}
                  </strong>
                  {regionCreateStore.regionMessaging.messages[0].message}
                </div>
              )}
            </div>
            <div className="col-lg-4 col-sm-4 col-md-4 placeholderfix">
              <label htmlFor="Availableincountry" className="block">
                Available Countries
              </label>
              <CountrySelectNew
                CountryTitle={(param) => regionCreateStore.countryTitle(param)}
                onChange={(value) => regionCreateStore.changeCountryText(value)}
                value={regionCreateStore.country}
              />
              {regionCreateStore.countryMessaging.messages.length > 0 && (
                <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                  <a
                    href="#close"
                    className="close"
                    data-dismiss="alert"
                    aria-label="close"
                    onClick={regionCreateStore.countryMessaging.removeMessage.bind(
                      regionCreateStore,
                      regionCreateStore.countryMessaging.messages[0]
                    )}
                  >
                    &times;
                  </a>
                  <strong>
                    {regionCreateStore.countryMessaging.messages[0].messageLabel}
                  </strong>
                  {regionCreateStore.countryMessaging.messages[0].message}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            {regionCreateStore.countryid && (
              <div className="view list" style={{ marginTop: 18 }}>
                <div className="col-lg-8 col-sm-8 col-md-8">
                  <label htmlFor="location" className="block">
                    Locations
                  </label>
                  <SelectLocations
                    onClick={regionCreateStore.showModel}
                    placeholder="Search"
                  >
                    {locTags}
                    {locTags.length === 0 && (
                      <Inline css={{ color: "$primary", fontSize: "18px" }}>
                        <em>-- Click to Select --</em>
                      </Inline>
                    )}
                  </SelectLocations>
                  {regionCreateStore.messaging.messages.length > 0 && (
                    <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                      <a
                        href="#close"
                        className="close"
                        data-dismiss="alert"
                        aria-label="close"
                        onClick={regionCreateStore.messaging.removeMessage.bind(
                          regionCreateStore,
                          regionCreateStore.messaging.messages[0]
                        )}
                      >
                        &times;
                      </a>
                      <strong>
                        {regionCreateStore.messaging.messages[0].messageLabel}
                      </strong>
                      {regionCreateStore.messaging.messages[0].message}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <RegionLocationModal
            store={regionCreateStore}
            button={
              <Button size="large" color="brand" onClick={regionCreateStore.saveLocation}>
                Save Location
              </Button>
            }
          />

          <br />
          <br />
          <br />
          <br />
          <ContainerSection className="footer">
            <Inline css={{ justifyContent: "flex-end" }}>
              <LinkButton size="large" to="/regions">
                Cancel
              </LinkButton>
              <Button
                size="large"
                color="brand"
                onClick={() => regionCreateStore.saveRegion()}
              >
                Create Region
              </Button>
            </Inline>
          </ContainerSection>
        </div>
      </div>
    );
  }
}

Create.propTypes = {
  store: PropTypes.object.isRequired,
};

export default observer(Create);
