// @flow

import React, { Component } from "react";
import { Alert } from "react-bootstrap";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BatchSearchPSPCreateStore } from "../../../stores/mobx/BatchSearchCreateStore";
import { RATE_TYPE } from "../../../constants/search";
import ToggleButton from "../../ratecards/components/ToggleButton";
import LoadingIndicator from "../../ratecards/components/LoadingIndicator";
import { InlineElements } from "../../../components/lib/Inline";
import Inline from "../../../components/lib/Inline";
import RateTypeTooltip from "../../../components/RateTypeTooltip";

type Props = {
  store: BatchSearchPSPCreateStore,
};

class WizardStep01SelectRateType extends Component<Props> {
  componentDidMount() {
    // this.props.store.batchSearchCreateStore.searchStore.pagination.goFetch();
  }

  render() {
    const store = this.props.store;

    if (store.network.loading) {
      return <LoadingIndicator />;
    }

    return (
      <div style={{ minHeight: 310, maxWidth: 1200, margin: "auto" }}>
        <Alert bsStyle="info">
          If you wish to perform rate searches for multiple Job Titles and multiple
          Locations, complete this wizard and Pay Intel will perform the multiple searches
          for you. You will receive a notification upon completion.
        </Alert>
        <div className="form pt-ss-title-form">
          <div className="form-group flex-container align-items-center justify-content-center">
            <div>
              <Inline
                className="control-label rc-control-label"
                css={{ padding: "$2", gap: "$1" }}
              >
                Select a Rate Type <RateTypeTooltip />
              </Inline>
              <div className="rc-checkbox-group form-horizontal">
                <InlineElements>
                  {store.rateTypes.map((item) => (
                    <ToggleButton
                      key={item.value}
                      large={true}
                      item={item}
                      type="radio"
                      name="rate-type"
                      value={item.value}
                      selected={
                        store.selectedRateType &&
                        item.value === store.selectedRateType.value
                      }
                      onChange={store.onRateTypeChange}
                    >
                      {item.value === RATE_TYPE.HOURLY ? (
                        <FontAwesomeIcon icon="hourglass" className="icon" />
                      ) : (
                        <FontAwesomeIcon icon="calendar" className="icon" />
                      )}
                      <span>{item.label}</span>
                    </ToggleButton>
                  ))}
                </InlineElements>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(WizardStep01SelectRateType);
