// @flow

import React from "react";
import type { Element } from "react";
import { extendObservable, action, runInAction } from "mobx";
import { observer } from "mobx-react";
import NetworkState from "../../models/NetworkState";
import ModalState from "../../models/ModalState";
import JobLibraryTitle from "../../models/JobLibraryTitle";
import type MobXStore from "./MobXStore";
import type { FetchAPI, FetchGraphQL } from "../../App";
import { createTasteGraphQLWrapper } from "./SupportFunctions";
import Button from "../../components/lib/Button";

export default class JobLibraryCreateCustomStore {
  fetchTasteGraphQL: FetchGraphQL;
  fetchGraphQL: FetchGraphQL;
  fetchAPI: FetchAPI;
  mobXStore: MobXStore;
  router: ?Object;
  network: NetworkState;
  successModal: ModalState;
  previousLocation: string;
  title: string;
  description: string;
  titleError: string;
  confirmEmptyLibraryModal: ModalState;
  errorModal: ModalState;
  errorMessage: Element<any>;

  createLibrary: () => void;
  reset: () => void;
  showSuccess: () => void;
  isValid: () => void;
  goBack: () => void;

  handleSaveLibrary: () => void;
  handleConfirmEmptyLibraryCancel: () => void;
  handleConfirmEmptyLibrarySubmit: () => void;

  onTitleChange: (SyntheticInputEvent<HTMLInputElement>) => void;
  onDescriptionChange: (SyntheticInputEvent<HTMLInputElement>) => void;

  addRemoveClientJobTitleAction: (JobLibraryTitle) => any;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI, mobXStore: MobXStore) {
    this.fetchTasteGraphQL = createTasteGraphQLWrapper(fetchAPI);
    this.fetchGraphQL = fetchGraphQL;
    this.fetchAPI = fetchAPI;
    this.mobXStore = mobXStore;

    extendObservable(this, {
      successModal: new ModalState(),
      network: new NetworkState(),
      title: "",
      titleError: "",
      description: "",
      previousLocation: "/job-library/library-requests/",
      confirmEmptyLibraryModal: new ModalState(),
      errorModal: new ModalState(),
      errorMessage: null,
    });

    this.router = null;

    this.createLibrary = action(this.createLibrary.bind(this));
    this.reset = action(this.reset.bind(this));
    this.showSuccess = action(this.showSuccess.bind(this));
    this.isValid = action(this.isValid.bind(this));
    this.goBack = action(this.goBack.bind(this));
    this.onTitleChange = action(this.onTitleChange.bind(this));
    this.onDescriptionChange = action(this.onDescriptionChange.bind(this));
    this.addRemoveClientJobTitleAction = action(
      this.addRemoveClientJobTitleAction.bind(this)
    );
    this.handleSaveLibrary = action(this.handleSaveLibrary.bind(this));
    this.handleConfirmEmptyLibraryCancel = action(
      this.handleConfirmEmptyLibraryCancel.bind(this)
    );
    this.handleConfirmEmptyLibrarySubmit = action(
      this.handleConfirmEmptyLibrarySubmit.bind(this)
    );
  }

  handleConfirmEmptyLibraryCancel() {
    this.confirmEmptyLibraryModal.hideModal();
  }

  handleConfirmEmptyLibrarySubmit() {
    this.confirmEmptyLibraryModal.hideModal();
    this.createLibrary();
  }

  handleSaveLibrary(e: Event) {
    e.preventDefault();
    if (this.network.loading) {
      // NOTE: Debounces createLibrary
      return;
    }

    if (!this.isValid()) return;

    const selectedClientTitles =
      this.mobXStore.jobLibraryTitleListStore.getSelectedClientJobTitles();
    if (!selectedClientTitles || selectedClientTitles.length === 0) {
      this.confirmEmptyLibraryModal.showModal();
      return;
    }

    this.createLibrary();
  }

  addRemoveClientJobTitleAction(clientJobTitle: JobLibraryTitle) {
    const AddRemoveAction = observer((props: Object) => (
      <Button
        color="brand"
        key={props.key}
        style={{ width: "100%" }}
        onClick={(e) => {
          e.stopPropagation();
          props.clientJobTitle.toggleSelected();
        }}
      >
        {props.clientJobTitle.viewState.selected ? "Remove" : "Add"}
      </Button>
    ));

    return <AddRemoveAction key={clientJobTitle.id} clientJobTitle={clientJobTitle} />;
  }

  async createLibrary() {
    if (this.network.loading) {
      // NOTE: Debounces createLibrary
      return;
    }

    if (!this.isValid()) return;

    //TODO: get clientJobTitles filters from list store
    const selectedClientTitles =
      this.mobXStore.jobLibraryTitleListStore.getSelectedClientJobTitles();
    const clientJobTitles = { only: selectedClientTitles };

    const query = `
      mutation createCustomLibrary($libraryTitle: String!, $libraryDescription: String, $clientJobTitles: ClientJobTitleFiltersInput!){
        createCustomClientLibrary(input: {
            libraryTitle: $libraryTitle,
            libraryDescription: $libraryDescription
            clientJobTitles: $clientJobTitles
          }) {

          success {
            message
          }

          errors {
            __typename
            ...on NotAllowedForCurrentUserError {
              message
            }
            ...on RequiredFieldEmptyError {
              message
            }
          }
          
          library {
            databaseId
          }
        }
      }
    `;

    const variables = {
      libraryTitle: this.title,
      libraryDescription: this.description,
      clientJobTitles: clientJobTitles,
    };

    this.network.loading = true;
    let res: ?Object = null;

    try {
      res = await this.fetchTasteGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Requesting Library", e);
      // TODO: Display user friendly error message
      return;
    }

    runInAction("createLibrary--success", () => {
      this.network.loading = false;
      this.network.error = null;

      if (this.network.logGraphQLError("createCustomClientLibrary", res)) {
        let errors = null;
        if (!errors && res) errors = res.errors;
        if (!errors && res && res.data) errors = res.data.errors;
        if (!errors && res && res.data && res.data.createCustomClientLibrary) {
          errors = res.data.createCustomClientLibrary.errors;
        }

        if (
          errors &&
          errors.length > 0 &&
          errors[0].__typename === "AlreadyExistsError"
        ) {
          this.errorMessage = (
            <p>
              Sorry! Your Library could not be created. A library with the same name
              already exists for your client.
            </p>
          );
          this.errorModal.showModal();
          return;
        } else {
          this.errorMessage = (
            <p>
              Sorry! Your Library could not be created. There was an error during the
              operation.
            </p>
          );
          this.errorModal.showModal();
          return;
        }
      }

      this.mobXStore.jobLibraryTitleListStore.handleStopEdit();
      this.reset();
      this.mobXStore.jobLibraryListStore.pagination.goFetch().then(() => {
        if (res && this.router) {
          this.router.push(
            `/job-library/view/${res.data.createCustomClientLibrary.library.databaseId}/`
          );
        }
      });
    });
  }

  reset() {
    this.title = "";
    this.description = "";
    this.successModal.hideModal();
  }

  isValid() {
    if (!this.title) this.titleError = "A title is required.";
    else this.titleError = "";

    return !this.titleError;
  }

  goBack() {
    if (this.router) {
      this.router.push({
        pathname: this.previousLocation,
        query: this.router.query,
      });
    }
  }

  showSuccess() {
    this.successModal.showModal();
  }

  onTitleChange(e: SyntheticInputEvent<HTMLInputElement>) {
    this.title = e.currentTarget.value;
    this.titleError = "";
  }

  onDescriptionChange(e: SyntheticInputEvent<HTMLInputElement>) {
    this.description = e.currentTarget.value;
  }
}
