import React from "react";
import { observer } from "mobx-react";
import Modal from "../ratecards/components/Modal";
import ContainerSection from "../ratecards/components/ContainerSection";
import { ModalButtons, ModalTitle } from "../ratecards/components/FilterModal";
import TextInput from "../../components/lib/TextInput";
import TextArea from "../../components/lib/TextArea";
import Button from "../../components/lib/Button";

class RequestMoreSearchesModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      numSearches: "0",
      reason: "",
    };
  }

  handleChange = (key, e) => {
    this.setState({
      [key]: e.target.value,
    });
  };

  handleSearchesRequest = () => {
    let numSearches = parseInt(this.state.numSearches, 10);
    let reason = this.state.reason;

    if (numSearches === 0) {
      return;
    }

    this.props.rateSearchStore.requestSearches(numSearches, reason).then(() => {
      this.props.modalState.hideModal();
    });
  };

  render() {
    const props = this.props;
    const state = this.state;

    return (
      <Modal
        show={props.modalState.show}
        onHide={props.modalState.hideModal}
        innerStyle={{ width: "500px" }}
      >
        <div className="rc-viewport overlap-lr-padding overlap-tb-padding">
          <ContainerSection className="header">
            <ModalTitle>Request More Searches</ModalTitle>
          </ContainerSection>
          <ContainerSection>
            <div className="form-group">
              <label htmlFor="psp-rs__num-searches">I would like</label>
              <TextInput
                fill
                type="number"
                id="psp-rs__num-searches"
                min="0"
                step="1"
                placeholder="e.x. 100"
                addonRight="Searches"
                value={state.numSearches}
                onChange={this.handleChange.bind(this, "numSearches")}
              />
            </div>
            <div className="form-group">
              <label htmlFor="psp-rs__reason">Reason (optional)</label>
              <TextArea
                fill
                css={{ resize: "none" }}
                name="psp-rs__reason"
                id="psp-rs__reason"
                rows="3"
                placeholder="Please enter the reason for the request."
                value={state.reason}
                onChange={this.handleChange.bind(this, "reason")}
              />
            </div>
          </ContainerSection>
          <ContainerSection className="footer">
            <ModalButtons>
              <Button color="brand" size="large" onClick={this.handleSearchesRequest}>
                Request
              </Button>
              <Button size="large" onClick={props.modalState.hideModal}>
                Cancel
              </Button>
            </ModalButtons>
          </ContainerSection>
        </div>
      </Modal>
    );
  }
}

export default observer(RequestMoreSearchesModal);
