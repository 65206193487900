import React from "react";
import Icon from "./lib/Icon";
import Tooltip from "./lib/Tooltip";
import Text from "./lib/Text";

export function RateTypeTooltip() {
  return (
    <Tooltip
      arrowProps={{ offset: 5 }}
      content={
        <Text>
          Contract – a temporary hire, i.e. staff augmentation, contract worker,
          temporary.
          <br />
          FTE – direct employee of the company.
        </Text>
      }
    >
      <span>
        <Icon css={{ cursor: "pointer" }} icon="question-circle" />
      </span>
    </Tooltip>
  );
}

RateTypeTooltip.displayName = "RateTypeTooltip";

export default RateTypeTooltip;
