import * as TabsPrimitive from "@radix-ui/react-tabs";
import { styled } from "../../stitches.config";

const StyledTabs = styled(TabsPrimitive.Root, {
  display: "flex",
  flexDirection: "column",
  width: 300,
});

const StyledList = styled(TabsPrimitive.List, {
  flexShrink: 0,
  display: "flex",
});

const StyledTrigger = styled(TabsPrimitive.Trigger, {
  all: "unset",
  fontFamily: "inherit",
  backgroundColor: "white",
  padding: "0 20px",
  height: 45,
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: 15,
  lineHeight: 1,
  color: "$primary",
  userSelect: "none",
  "&:first-child": { borderTopLeftRadius: 6 },
  "&:last-child": { borderTopRightRadius: 6 },
  "&:hover": { color: "$brand" },
  '&[data-state="active"]': {
    color: "$brandDark",
    borderTop: "solid 1px $primaryLight",
    borderLeft: "solid 1px $primaryLight",
    borderRight: "solid 1px $primaryLight",
  },
  '&[data-state="inactive"]': {
    color: "$primaryDarker",
    backgroundColor: "$primaryLighter",
  },
  "&:focus": { position: "relative", boxShadow: `none` },
});

const StyledContent = styled(TabsPrimitive.Content, {
  flexGrow: 1,
  padding: 20,
  backgroundColor: "white",
  borderBottomLeftRadius: 6,
  borderBottomRightRadius: 6,
  outline: "none",
  border: "solid 1px $primaryLight",
  "&:focus": { boxShadow: `none` },
});

// Exports
export const Tabs = StyledTabs;
export const TabsList = StyledList;
export const TabsTrigger = StyledTrigger;
export const TabsContent = StyledContent;
