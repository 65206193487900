import React, { useImperativeHandle, useRef, useCallback } from "react";

import NumberInput from "../../lib/NumberInput";
import { isNumber } from "../utils";
import { emptyList, FilterTypes } from "../constants";
import Grid from "../../lib/Grid";
import Stack from "../../lib/Stack";
import { Focusable, OnChangeFilterValueFunction } from "./types";
import { FilterConfigMap } from "../types";
import { CSS } from "../../../stitches.config";

const FieldIndex = {
  FROM: 0,
  TO: 1,
} as const;

type NumbersFilterProps = {
  filter: FilterConfigMap;
  onChange: OnChangeFilterValueFunction;
  css?: CSS;
};

const NumbersFilter = React.forwardRef(
  (props: NumbersFilterProps, ref: React.ForwardedRef<Focusable>) => {
    const { filter, onChange, css } = props;

    const selectedValues = filter.getIn(["filter", "values"]).toList();
    const fromValue = isNumber(selectedValues.get(FieldIndex.FROM))
      ? selectedValues.get(FieldIndex.FROM)
      : "";
    const toValue = isNumber(selectedValues.get(FieldIndex.TO))
      ? selectedValues.get(FieldIndex.TO)
      : "";

    // interface methods

    const firstInputRef = useRef<HTMLInputElement>(null);

    const focus = useCallback(() => {
      // const inputNode = ReactDOM.findDOMNode(firstInputRef?.current);
      firstInputRef?.current?.focus?.();
    }, []);

    useImperativeHandle(ref, () => ({ focus }));

    // handlers

    const handleChange = useCallback(
      (fieldNumber: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const currentFieldIdx =
          fieldNumber === FieldIndex.FROM ? FieldIndex.FROM : FieldIndex.TO;
        const otherFieldIdx =
          fieldNumber === FieldIndex.FROM ? FieldIndex.TO : FieldIndex.FROM;
        const value = isNumber(event.currentTarget.value)
          ? event.currentTarget.value
          : undefined;

        if (!isNumber(value) && !isNumber(selectedValues.get(otherFieldIdx))) {
          onChange(
            filter
              .setIn(["filter", "values"], emptyList)
              .setIn(["filter", "type"], FilterTypes.NUMBERS_RANGE) as FilterConfigMap
          );
        } else if (value !== selectedValues.get(currentFieldIdx)) {
          onChange(
            filter
              .setIn(["filter", "values"], selectedValues.set(currentFieldIdx, value))
              .setIn(["filter", "type"], FilterTypes.NUMBERS_RANGE) as FilterConfigMap
          );
        }
      },
      [filter, selectedValues, onChange]
    );

    const handleFromChange = React.useMemo(
      () => handleChange.bind(undefined, FieldIndex.FROM),
      [handleChange]
    );
    const handleToChange = React.useMemo(
      () => handleChange.bind(undefined, FieldIndex.TO),
      [handleChange]
    );

    return (
      <Stack fill css={{ alignItems: "stretch", gap: "$2", ...css }}>
        <span>Select numbers range:</span>
        <Grid
          css={{
            paddingLeft: "$2",
            gridTemplateColumns: "40px auto",
            alignItems: "center",
            gap: "$2",
          }}
        >
          <label>From: </label>
          <NumberInput
            ref={firstInputRef}
            value={fromValue}
            onChange={handleFromChange}
          />
          <label>To: </label>
          <NumberInput value={toValue} onChange={handleToChange} />
        </Grid>
      </Stack>
    );
  }
);
NumbersFilter.displayName = "NumbersFilter";

export default NumbersFilter;
