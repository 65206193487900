import React from "react";
import { createMachine } from "xstate";
import { useMachine } from "@xstate/react";
import xstateLogger from "../utils/xstateLogger";

export type SelectionSet<T> = Set<T>;

type SelectionMachineContextType<T> = {
  selection: SelectionSet<T>;
};

type SelectItemEventType<T> = {
  type: "SELECT_ITEM";
  item: T;
};
type DeselectItemEventType<T> = {
  type: "DESELECT_ITEM";
  item: T;
};
type SelectAllItemsEventType<T> = {
  type: "SELECT_ALL";
  items: T[];
};
type DeselectAllItemsEventType = {
  type: "DESELECT_ALL";
};

// State machine to model the selection of items from a list UI without pagination
// Stores item ids as the selection state
export function createSelectionMachine<T>() {
  return (
    /** @xstate-layout N4IgpgJg5mDOIC5SzAGzAYwC4EsD2AdgLICGGAFjgWAHQ4ToDEAygKIAyrAwgCoD6ASR6siAbQAMAXUSgADnlg5chGSAAeiAIwA2TQBoQAT0QAmEwHYaATgAcNkzc0mArAF9XBlOmz5iZStR0DGAsHNz8AILs7BLSSCDyisoEqhoIOvpGiAAs5prWzgDMTm4eIF6YyaQUVLQkqKjMaJWQjAAirGycvILCYlKqiUq+qTl2NOZW2jbmzgbGCIXmJtbm2s7aLu6ezT6E1QF1DU3eWK0dXeF8UTED8UPJo+m684hLK1ZrG1tlFXt+NUCfzOEFC3X4QhEsUGCmGKniaRsVmcNGczmy01mrwQJis2Qm5kKVmRRWceWyhW25V2VX8tRowPOnTCPUh-TiclhjwRYxsEymMzmWQQum01mymmy4k2pR2p18B3pjNBFxZEL6ok0HISXJGPIQNmKNBc2M0yP55gphXEhTJkocVOBCrpQJprUuPRu0PuuvhoDS6zF0pNwty+WRxR+csqzsBtGV7WZ4Ou0W9nKSev9iG0hT5kpsFKxwsN7jKBDwEDgqid+xdtHo6BhGb96hyJlNJnENEKJgy2WchsKQ8K2UdNNjhxo9UabogTbhKX1I6sNE04k03yFCylKNxjnM5nEg+H5jH8trcYZs-n3KzCCsktRNmcViWW7etgmznEaJsNrtFJnjGF6HDematggAC02jYtBpauEAA */
    createMachine(
      {
        context: { selection: new Set<T>() },
        tsTypes: {} as import("./selectionMachine.typegen").Typegen0,
        schema: {
          context: {} as SelectionMachineContextType<T>,
          events: {} as
            | SelectItemEventType<T>
            | DeselectItemEventType<T>
            | SelectAllItemsEventType<T>
            | DeselectAllItemsEventType,
        },
        predictableActionArguments: true,
        id: "selectionMachine",
        initial: "idle",
        states: {
          idle: {
            on: {
              SELECT_ITEM: {
                target: "selected",
                actions: "selectItem",
              },
              SELECT_ALL: {
                target: "allSelected",
                actions: "selectAllItems",
              },
            },
          },
          allSelected: {
            on: {
              DESELECT_ITEM: {
                target: "selected",
                actions: "deSelectItem",
              },
              DESELECT_ALL: {
                target: "idle",
                actions: "deSelectAllItems",
              },
            },
          },
          selected: {
            on: {
              SELECT_ITEM: {
                target: "selected",
                actions: "selectItem",
                internal: false,
              },
              DESELECT_ITEM: [
                {
                  target: "idle",
                  cond: "hasSingleSelection",
                  actions: "deSelectItem",
                },
                {
                  target: "selected",
                  actions: "deSelectItem",
                  internal: false,
                },
              ],
              SELECT_ALL: {
                target: "allSelected",
                actions: "selectAllItems",
              },
              DESELECT_ALL: {
                target: "idle",
                actions: "deSelectAllItems",
              },
            },
          },
        },
      },
      {
        actions: {
          selectItem: (ctx, event) => {
            ctx.selection = ctx.selection.add(event.item);
            return ctx;
          },
          deSelectItem: (ctx, event) => {
            ctx.selection.delete(event.item);
            return ctx;
          },
          selectAllItems: (ctx, event) => {
            return (ctx.selection = new Set(event.items));
          },
          deSelectAllItems: (ctx, event) => {
            return (ctx.selection = new Set());
          },
        },
        guards: {
          hasSingleSelection: (ctx) => ctx.selection.size === 1,
        },
      }
    )
  );
}

export function useSelectionMachine<T>(
  allItemsGetter: () => Array<T>,
  machineName?: string
) {
  const selectionMachine = React.useMemo(() => createSelectionMachine<T>(), []);
  const [selectionState, send, selectionService] = useMachine(selectionMachine);

  // state machine logging
  React.useEffect(() => {
    // @ts-ignore
    const subscription = selectionService.subscribe((state) =>
      // @ts-ignore
      xstateLogger(state, machineName)
    );
    return subscription.unsubscribe;
  }, [machineName, selectionService]);

  const handleSelectAll = React.useCallback(() => {
    send({ type: "SELECT_ALL", items: allItemsGetter() });
  }, [allItemsGetter, send]);

  const handleDeSelectAll = React.useCallback(() => {
    send({ type: "DESELECT_ALL" });
  }, [send]);

  const handleCheckedChange = React.useCallback(
    (checked: boolean, item: T) => {
      if (checked) {
        send({ type: "SELECT_ITEM", item });
      } else {
        send({ type: "DESELECT_ITEM", item });
      }
    },
    [send]
  );

  return {
    selectionState,
    send,
    handleSelectAll,
    handleDeSelectAll,
    handleCheckedChange,
  };
}
