import React, { useMemo, useCallback, useState } from "react";
import moment from "moment";

import Stack from "../../../../components/lib/Stack";
import Box from "../../../../components/lib/Box";
import Text from "../../../../components/lib/Text";
import SummaryTableView from "../SummaryTable";
import { dateTimeFormatter, decimalFormatter, percentFormatter } from "../../constants";
import {
  RATE_TYPES,
  RATE_TYPES_LABELS,
  ALL_CONTINGENT_RATE_TYPES_VALUES,
} from "../../types";
import {
  djangoPaginationKey,
  djangoPaginationSizeKey,
  FilterTypes,
} from "../../../../components/tables/constants";
import { emptyMap, emptyOrderedMap } from "../../../../constants";
import { TableFilterableRestful } from "../TableFilterableRestful";
import { transformAggregatedJobFeedbackData } from "../../dataConverters";
// @ts-expect-error
import { logAsyncOperationError } from "../../../../utils/logging";
import { useTableSchemaState } from "../../../../components/tables/Table";
import { rowIdGetter } from "../../../../components/tables/utils";
import { Column } from "../../../../components/tables";
import { IconButton } from "../../../../components/lib/Button";
import { renderTableColumnsSpecs } from "../../../private_index/components/ExtendedRestfulTables";
import { useInitialFetch } from "../../../../utils/hooks";
import { restfulTableConfigDefaults } from "../../globalState";
import { TickerContentLoader } from "../../../../components/lib/TickerLoader";

import type {
  RATE_TYPES_TYPE,
  TableColumnSpecsObject,
  OverallJobFeedbackDataMap,
  OverallJobFeedbackDataOrderedMap,
  CurrencyDataMap,
  OverallJobFeedbackDataObject,
} from "../../types";
import type { TableFilterableRestfulProps } from "../../../../components/tables/TableFilterableRestful";
import type { RestfulTableDataStateObject } from "../../globalState";
import type { FetchAPIFunc, FetchAPIResponse } from "../../../../types/fetch";
import type { DjangoPaginatedResponse } from "../../../../types/django";
import type { FiltersQueryObject } from "../../../../components/tables/types";
import type { ShowModalFunc } from "../../../../components/modals/MessageModal";

const convertibleContingentValues = [
  "pay_rate_min",
  "pay_rate_max",
  "bill_rate_min",
  "bill_rate_max",
];

const contingentKeys = [
  "attempt__created",
  "attempt__review__client_name",
  "attempt__first_name",
  "attempt__last_name",
  "job__title",
  "job__source",
  "job__country",
  "job__state",
  "job__city",
  "job__region",
  "job__industry_name",
  "job__rate_type",
  "validated_skipped_total",
  "pay_rate_min",
  "pay_rate_max",
  "markup_min",
  "markup_max",
  "bill_rate_min",
  "bill_rate_max",
] as const;

const annualKeys = [
  "attempt__created",
  "attempt__review__client_name",
  "attempt__first_name",
  "attempt__last_name",
  "job__title",
  "job__source",
  "job__country",
  "job__state",
  "job__city",
  "job__region",
  "job__industry_name",
  "validated_skipped_total",
  "annual_salary_min",
  "annual_salary_max",
] as const;

type TableSpecsMatherType = {
  [key in RATE_TYPES_TYPE]: readonly string[];
};

const tableSpecsMatcher: TableSpecsMatherType = {
  [RATE_TYPES.HOURLY]: contingentKeys,
  [RATE_TYPES.ANNUAL]: annualKeys,
  [RATE_TYPES.DAILY]: contingentKeys,
  [RATE_TYPES.WEEKLY]: contingentKeys,
  [RATE_TYPES.MONTHLY]: contingentKeys,
};

type ConvertibleValueFormatterExtraParams = {
  displayRateType?: RATE_TYPES_TYPE;
  displayRateMultiplier?: number;
};

function convertibleValueFormatter(
  value: number | null,
  row: OverallJobFeedbackDataMap,
  extraParams: ConvertibleValueFormatterExtraParams = {}
) {
  if (value == null) return value;

  const { displayRateType, displayRateMultiplier } = extraParams;
  const currencySymbol = row.getIn(["job", "currency_symbol"]);

  if (
    (displayRateType === RATE_TYPES.DAILY ||
      displayRateType === RATE_TYPES.WEEKLY ||
      displayRateType === RATE_TYPES.MONTHLY) &&
    displayRateMultiplier &&
    displayRateMultiplier !== 1
  ) {
    const convertedValue = value * displayRateMultiplier;
    const displayRateTypeLabel = RATE_TYPES_LABELS[displayRateType];

    return (
      `${decimalFormatter(value, false, currencySymbol)} ` +
      `(${decimalFormatter(convertedValue, false, currencySymbol)}` +
      `${displayRateTypeLabel ? ` ${displayRateTypeLabel.toLowerCase()}` : ""})`
    );
  }

  return decimalFormatter(value, false, currencySymbol);
}

export const jobFeedbackTableSpecs: TableColumnSpecsObject<OverallJobFeedbackDataMap>[] =
  [
    {
      type: "column",
      uniqueKey: "attempt__created",
      title: "Validation Date",
      getter: (row) => row.getIn(["attempt", "created"]),
      formatter: (value) => dateTimeFormatter(value),
      filterType: FilterTypes.DATES_RANGE,
      filterExtraProps: {
        datesFormat: moment.defaultFormat,
      },
    },
    {
      type: "column",
      uniqueKey: "attempt__review__client_name",
      title: "Client",
      getter: (row) => row.getIn(["attempt", "review", "client_name"]),
      formatter: (value, row) => {
        const clientId = row.getIn(["attempt", "review", "client_id"]);
        if (clientId && value) {
          return `${value} (#${clientId})`;
        }
        return value;
      },
      filterType: FilterTypes.VALUES_CHECKLIST,
    },
    {
      type: "column",
      uniqueKey: "attempt__first_name",
      title: "First Name",
      getter: (row) => row.getIn(["attempt", "first_name"]),
      filterType: FilterTypes.VALUES_CHECKLIST,
    },
    {
      type: "column",
      uniqueKey: "attempt__last_name",
      title: "Last Name",
      getter: (row) => row.getIn(["attempt", "last_name"]),
      filterType: FilterTypes.VALUES_CHECKLIST,
    },
    {
      type: "column",
      uniqueKey: "job__source",
      title: "Source",
      getter: (row) => row.getIn(["job", "source"]),
      filterType: FilterTypes.VALUES_CHECKLIST,
    },
    {
      type: "column",
      uniqueKey: "job__title",
      title: "Job Title",
      getter: (row) => row.getIn(["job", "title"]),
      filterType: FilterTypes.VALUES_CHECKLIST,
    },
    {
      type: "column",
      uniqueKey: "job__country",
      title: "Country",
      getter: (row) => row.getIn(["job", "country"]),
      filterType: FilterTypes.VALUES_ICONTAINS,
      filterable: false,
      sortable: false,
    },
    {
      type: "column",
      uniqueKey: "job__state",
      title: "State/Province",
      getter: (row) => row.getIn(["job", "state"]),
      filterType: FilterTypes.VALUES_CHECKLIST,
    },
    {
      type: "column",
      uniqueKey: "job__city",
      title: "City",
      getter: (row) => row.getIn(["job", "city"]),
      filterType: FilterTypes.VALUES_CHECKLIST,
    },
    {
      type: "column",
      uniqueKey: "job__region",
      title: "Region",
      getter: (row) => row.getIn(["job", "region"]),
      filterType: FilterTypes.VALUES_CHECKLIST,
    },
    {
      type: "column",
      uniqueKey: "job__industry_name",
      title: "Industry",
      getter: (row) => row.getIn(["job", "industry_name"]),
      filterType: FilterTypes.VALUES_CHECKLIST,
    },
    {
      type: "column",
      uniqueKey: "validated_skipped_total",
      title: "Validated/Skipped/Total",
      getter: (row) => row,
      formatter: (value, row) => {
        const values = [
          row.get("validated_number"),
          row.get("skipped_number"),
          row.get("total_number"),
        ];
        if (values.filter((i) => i != null).length === 0) return "";
        return values.join("/");
      },
      sortable: false,
      filterable: false,
    },
    {
      type: "column",
      uniqueKey: "pay_rate_min",
      title: "Pay Rate Low",
      getter: (row) => row.get("pay_rate_min"),
      formatter: convertibleValueFormatter,
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "pay_rate_max",
      title: "Pay Rate High",
      getter: (row) => row.get("pay_rate_max"),
      formatter: convertibleValueFormatter,
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "markup_min",
      title: "Markup Low",
      getter: (row) => row.get("markup_min"),
      formatter: (value) => percentFormatter(value),
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "markup_max",
      title: "Markup High",
      getter: (row) => row.get("markup_max"),
      formatter: (value) => percentFormatter(value),
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "bill_rate_min",
      title: "Bill Rate Low",
      getter: (row) => row.get("bill_rate_min"),
      formatter: convertibleValueFormatter,
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "bill_rate_max",
      title: "Bill Rate High",
      getter: (row) => row.get("bill_rate_max"),
      formatter: convertibleValueFormatter,
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "annual_salary_min",
      title: "Annual Salary Low",
      getter: (row) => row.get("annual_salary_min"),
      formatter: (value, row) => {
        const currencySymbol = row.getIn(["job", "currency_symbol"]);
        return decimalFormatter(value, false, currencySymbol);
      },
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "annual_salary_max",
      title: "Annual Salary High",
      getter: (row) => row.get("annual_salary_max"),
      formatter: (value, row) => {
        const currencySymbol = row.getIn(["job", "currency_symbol"]);
        return decimalFormatter(value, false, currencySymbol);
      },
      filterType: FilterTypes.NUMBERS_RANGE,
    },
  ];

type JobFeedbackTableProps = Omit<TableFilterableRestfulProps, "schema">;

const JobFeedbackTable = (props: JobFeedbackTableProps) => {
  const [schema] = useTableSchemaState(props.children);
  return <TableFilterableRestful {...props} schema={schema} />;
};
JobFeedbackTable.displayName = "JobFeedbackTable";

type JobsFeedbackTableViewProps = {
  jobTitle: string;
  jobTitleCollection: string | null;
  jobTitleCollectionId: number | null;
  jobCountry: string | null;
  jobCountryId: number | null;
  jobIndustry: string | null;
  jobIndustryId: number | null;
  rateType: RATE_TYPES_TYPE;
  currencyData: CurrencyDataMap;
  isThreeLevelBanding: boolean;
  displayRateType: RATE_TYPES_TYPE;
  displayRateMultiplier: number | null;
  onClickDetails: (feedback: OverallJobFeedbackDataMap) => Promise<void>;
  fetchArgusFilteringAPI: FetchAPIFunc;
  showModalError: ShowModalFunc;
};

const JobFeedbackTableView = (props: JobsFeedbackTableViewProps) => {
  const {
    jobTitle,
    jobTitleCollection,
    jobTitleCollectionId,
    jobCountry,
    jobCountryId,
    jobIndustry,
    jobIndustryId,
    rateType,
    currencyData,
    isThreeLevelBanding,
    displayRateType,
    displayRateMultiplier,
    onClickDetails,
    fetchArgusFilteringAPI,
    showModalError,
  } = props;

  // table state

  const [tableState, setTableState] = useState<RestfulTableDataStateObject>({
    ...restfulTableConfigDefaults,
    data: emptyOrderedMap as unknown as OverallJobFeedbackDataOrderedMap,
    itemsCount: 0,
    itemsPerPage: 10,
    loaded: false,
  });
  const { loaded, ...restTableState } = tableState;
  const { filtersQuery } = restTableState;

  const getCommonFiltersQuery = useCallback(
    (nextFiltersQuery: FiltersQueryObject = {}) => {
      if (jobTitleCollectionId) {
        nextFiltersQuery["job__collection_id"] = jobTitleCollectionId;
      } else if (jobTitleCollection) {
        nextFiltersQuery["job__collection_title"] = jobTitleCollection;
      }
      if (jobCountryId) {
        nextFiltersQuery["job__country_id"] = jobCountryId;
      } else if (jobCountry) {
        nextFiltersQuery["job__country__icontains"] = jobCountry;
      }
      if (jobIndustryId) {
        nextFiltersQuery["job__industry_id__in"] = [jobIndustryId, 1]; // 1 is "All" industry
      } else if (jobIndustry) {
        nextFiltersQuery["job__industry_name__in"] = [jobIndustry, "All"];
      }
      nextFiltersQuery["job__review__is_three_level_banding"] = isThreeLevelBanding;

      const hasRateTypeFilters =
        Object.keys(nextFiltersQuery).filter((i) => i.startsWith("job__rate_type"))
          .length > 0;

      if (rateType === RATE_TYPES.ANNUAL) {
        nextFiltersQuery["job__rate_type"] = rateType;
      } else if (!hasRateTypeFilters) {
        nextFiltersQuery["job__rate_type__in"] = ALL_CONTINGENT_RATE_TYPES_VALUES;
      }

      return nextFiltersQuery;
    },
    [
      jobTitleCollection,
      jobTitleCollectionId,
      jobCountry,
      jobCountryId,
      jobIndustry,
      jobIndustryId,
      isThreeLevelBanding,
      rateType,
    ]
  );

  const commonFiltersQuery = useMemo(
    () => getCommonFiltersQuery(filtersQuery.toJS()),
    [filtersQuery, getCommonFiltersQuery]
  );

  // fetch data

  const fetchJobFeedbackData = useCallback(
    async (urlQuery = {}, filtersQuery = {}, nextStateUpdates = {}) => {
      try {
        const response: FetchAPIResponse<
          DjangoPaginatedResponse<OverallJobFeedbackDataObject>
        > = await fetchArgusFilteringAPI(`job-feedback/filtered/`, {
          params: urlQuery,
          data: getCommonFiltersQuery(filtersQuery),
        });
        const nextDataState: Partial<
          RestfulTableDataStateObject<OverallJobFeedbackDataMap>
        > = transformAggregatedJobFeedbackData(response.data, nextStateUpdates);

        setTableState((prevDataState) => ({
          ...prevDataState,
          ...nextDataState,
          loaded: true,
        }));

        return nextDataState;
      } catch (err: any) {
        logAsyncOperationError("fetchJobFeedbackData", err);
        showModalError(
          "Error occurred while loading job feedback data. Please try again later."
        );
        throw err;
      }
    },
    [getCommonFiltersQuery, setTableState, fetchArgusFilteringAPI, showModalError]
  );

  const fetchJobFeedbackFiltersData = useCallback(
    async (urlQuery = {}, filtersQuery = {}) => {
      try {
        const response: FetchAPIResponse<DjangoPaginatedResponse<any>> =
          await fetchArgusFilteringAPI(`job-feedback/values/filtered/`, {
            params: urlQuery,
            data: getCommonFiltersQuery(filtersQuery),
          });
        return response.data;
      } catch (err: any) {
        logAsyncOperationError("fetchJobFeedbackFilterData", err);
        showModalError(
          "Error occurred while loading filter values. Please, try again later."
        );
        throw err;
      }
    },
    [getCommonFiltersQuery, fetchArgusFilteringAPI, showModalError]
  );

  const refreshJobFeedbackData = useCallback(async () => {
    return fetchJobFeedbackData(
      {
        [djangoPaginationKey]: tableState.activePage,
        [djangoPaginationSizeKey]: tableState.itemsPerPage,
      },
      tableState.filtersQuery.toJS()
    );
  }, [
    tableState.activePage,
    tableState.itemsPerPage,
    tableState.filtersQuery,
    fetchJobFeedbackData,
  ]);

  // initial table data load

  useInitialFetch(refreshJobFeedbackData);

  // rendering

  const renderDetailsButton = useCallback(
    (feedback: OverallJobFeedbackDataMap) => {
      return (
        <IconButton
          color="brand"
          variant="outlined"
          icon="info"
          title="See Details"
          onClick={() => onClickDetails(feedback)}
          css={{ width: "$6", height: "$6", fontSize: "$xs" }}
        />
      );
    },
    [onClickDetails]
  );

  const columnsWhiteList = useMemo(() => tableSpecsMatcher[rateType] || [], [rateType]);

  const columnsWhiteListSpecs = useMemo(
    () =>
      jobFeedbackTableSpecs.filter((specObject) =>
        columnsWhiteList.includes(specObject.uniqueKey)
      ),
    [columnsWhiteList]
  );

  const resultingColumnsSpecs = useMemo(() => {
    return columnsWhiteListSpecs.map((spec) => {
      if (
        convertibleContingentValues.includes(spec.uniqueKey) &&
        spec?.formatter != null
      ) {
        const { formatter } = spec;

        spec.formatter = (...args: any[]) => {
          // @ts-ignore @ts2307
          return formatter(...args, { displayRateType, displayRateMultiplier });
        };
      }
      return spec;
    });
  }, [columnsWhiteListSpecs, displayRateType, displayRateMultiplier]);

  const tableTitle = useMemo(() => {
    let title = `All validators feedback found in the system for the titles similar to "${jobTitle}"`;
    if (jobCountry) {
      title += ` and country "${jobCountry}"`;
    }
    if (jobIndustryId && jobIndustry) {
      title +=
        jobIndustryId === 1
          ? ` and industry "${jobIndustry}"`
          : ` and industries "${jobIndustry}" and "All"`;
    }
    title += ":";
    return title;
  }, [jobTitle, jobCountry, jobIndustryId, jobIndustry]);

  if (!loaded) {
    return (
      <Box css={{ minHeight: "200px", minWidth: "100px", position: "relative" }}>
        <TickerContentLoader />
      </Box>
    );
  }

  const columns = [
    <Column
      key="_details"
      uniqueKey="_details"
      title="Details"
      getter={renderDetailsButton}
      fixed
    />,
    ...renderTableColumnsSpecs(resultingColumnsSpecs),
  ];

  return (
    <Stack
      fill
      css={{
        alignItems: "flex-start",
        [`& td`]: {
          textAlign: "center",
        },
      }}
    >
      <Text as="h5">{tableTitle}</Text>

      <JobFeedbackTable
        multimode
        rowIdGetter={rowIdGetter}
        bodyEmptyText="No data"
        disablePagination={false}
        dataProvider={fetchJobFeedbackData}
        filtersDataProvider={fetchJobFeedbackFiltersData}
        {...restTableState}
      >
        {columns}
      </JobFeedbackTable>
      <SummaryTableView
        rateType={rateType}
        displayRateType={displayRateType}
        displayRateMultiplier={displayRateMultiplier}
        currencySymbol={(currencyData || emptyMap).get("symbol")}
        filtersQuery={commonFiltersQuery}
        fetchArgusFilteringAPI={fetchArgusFilteringAPI}
        showModalError={showModalError}
      />
    </Stack>
  );
};
JobFeedbackTableView.displayName = "JobFeedbackTableView";
JobFeedbackTableView.defaultProps = {
  currencyData: emptyMap,
  isThreeLevelBanding: false,
};

export default JobFeedbackTableView;
