import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SALARY_RATE_TYPE_OPTIONS } from "../../../constants/negotiationWorksheet";

import { BottomControlGroup } from "../../../components/material";
import { ButtonToolbar } from "react-bootstrap";

const ResultSummaryForSalary = (props) => {
  const { currencySymbol, payValue, totalMarkup, conversionOptions } = props;
  const markupHourly = payValue * (totalMarkup / 100);
  //const {selectedWorkerType } = this.props.store;
  // const unsavedValues = this.props.store.currentState;

  //const workerType = selectedWorkerType;
  //const calculatedResults = this.props.store.calculatePayAndBillRate();

  return (
    <BottomControlGroup className="pt-worksheet__bottom-controls__summary">
      <span
        className="pt-worksheet__bottom-controls__summary__pay-rate"
        title="Base Salary"
      >
        {currencySymbol}
        {payValue}
      </span>
      <FontAwesomeIcon icon="times" />
      <span
        className="pt-worksheet__bottom-controls__summary__total-markup"
        title="Benefits & Placement Fee"
      >
        {parseFloat(totalMarkup).toFixed(2)}%
      </span>
      <span className="pt-worksheet__bottom-controls__summary__equals"> = </span>
      <span
        className="pt-worksheet__bottom-controls__summary__bill-rate"
        title="Total Cost Of Employee"
      >
        {currencySymbol}
        {parseInt(
          SALARY_RATE_TYPE_OPTIONS.convertFromHourly(
            markupHourly,
            conversionOptions
          ).toFixed(2),
          10
        ) + parseInt(payValue, 10)}
      </span>
      <ButtonToolbar className="pull-right">
        {/*<DownloadPOSTButton
          className="pull-left"
          service="excelsior"
          endpoint="excel-export/"
          getFormData={() => exportToExcel(worksheetSchema, values, unsavedValues, worksheetName, calculatedResults, rateType, workerType)}>
          <FontAwesomeIcon name='download'/> Export to Excel
        </DownloadPOSTButton>*/}
      </ButtonToolbar>
    </BottomControlGroup>
  );
};

export default ResultSummaryForSalary;
