// @flow

import type { Element } from "react";
import React, { Component } from "react";
import { Alert, OverlayTrigger, Popover } from "react-bootstrap";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { WorkerType } from "../../../stores/mobx/BatchSearchCreateStore";
import {
  BatchSearchJLCreateStore,
  BatchSearchJobListItem,
} from "../../../stores/mobx/BatchSearchCreateStore";
import type { InfiniteScrollSelectProps } from "../../../components/selects/InfiniteScrollSelect";
import InfiniteScrollSelect from "../../../components/selects/InfiniteScrollSelect";
import RawJobTitle from "../../../models/RawJobTitle";
import Location from "../../../models/LocationList";
import LocationList, { LOCATION_TYPE } from "../../../models/LocationList";
import LinearVerticalWizard, {
  VerticalWizardStep,
} from "../../../components/wizards/LinearVerticalWizard";
import InstantSearchBox from "../../ratecards/components/InstantSearchBox";
import RawJobTitleListItem from "./RawJobTitleListItem";
import Loadable from "../../ratecards/components/Loadable";
import { WizardState } from "../../../components/wizards/Wizard";
import Pagination from "../../../components/lib/Pagination";
import FlagIcon from "../../../components/FlagIcon";
import JobLibrary from "../../../models/JobLibrary";
import Industry from "../../../models/Industry";
import RegionList from "../../../models/RegionList";
import Box from "../../../components/lib/Box";
import Button from "../../../components/lib/Button";
import { InlineElements } from "../../../components/lib/Inline";
import Tooltip from "../../../components/lib/Tooltip";
import { Link } from "../../../components/lib/Link";
import CheckBox from "../../shared/CheckBox";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../components/lib/DropdownMenu";
import Spacer from "../../../components/lib/Spacer";
import { Table, THead, TH, TBody, TR, TD } from "../../../components/lib/Table";
import { Checkbox, CheckboxIndicator } from "../../../components/lib/Checkbox";

const LibraryInfiniteScrollSelect = (props: InfiniteScrollSelectProps<JobLibrary>) => (
  <InfiniteScrollSelect {...props} />
);
const LocationInfiniteScrollSelect = (props: InfiniteScrollSelectProps<LocationList>) => (
  <InfiniteScrollSelect {...props} />
);
const RegionInfiniteScrollSelect = (props: InfiniteScrollSelectProps<RegionList>) => (
  <InfiniteScrollSelect {...props} />
);
const IndustryInfiniteScrollSelect = (props: InfiniteScrollSelectProps<Industry>) => (
  <InfiniteScrollSelect {...props} />
);
const WorkerTypeInfiniteScrollSelect = (props: InfiniteScrollSelectProps<WorkerType>) => (
  <InfiniteScrollSelect {...props} />
);

type Props = {
  store: BatchSearchJLCreateStore,
};

class WizardStep02TitlesAndLocations extends Component<Props> {
  _selectLibraryField: ?HTMLElement;
  getLibraryFieldRef: (?HTMLElement) => void;
  renderJobTitleSelection: (RawJobTitle) => Element<any>;
  renderJobTitleItem: (
    RawJobTitle,
    ?string,
    ?(string, string) => Element<any>
  ) => Element<any>;
  renderCountryItem: (
    Location,
    ?string,
    ?(string, string) => Element<any>
  ) => Element<any>;
  renderLocationItem: (
    Location,
    ?string,
    ?(string, string) => Element<any>
  ) => Element<any>;
  _renderLocationWithCountryIcon: (
    Location,
    ?string,
    ?string,
    ?(string, string) => Element<any>
  ) => Element<any>;
  handleAddJobButtonClick: () => void;
  handleAddMoreButtonClick: () => void;
  handleAddMoreClearButtonClick: () => void;
  handleLocationTypeChange: (number) => void;
  handleRemoveJobButtonClick: (BatchSearchJobListItem) => void;
  handleEditJobButtonClick: (BatchSearchJobListItem) => void;
  handleItemSelectedChange: (BatchSearchJobListItem) => void;
  handleKeyPress: (Event) => void;
  handleViewAddedClick: (Event) => void;

  constructor(props: Props) {
    super(props);

    this.getLibraryFieldRef = this.getLibraryFieldRef.bind(this);
    this.renderJobTitleItem = this.renderJobTitleItem.bind(this);
    this.renderJobTitleSelection = this.renderJobTitleSelection.bind(this);
    this.renderCountryItem = this.renderCountryItem.bind(this);
    this.renderLocationItem = this.renderLocationItem.bind(this);
    this._renderLocationWithCountryIcon = this._renderLocationWithCountryIcon.bind(this);
    this.handleLocationTypeChange = this.handleLocationTypeChange.bind(this);
    this.handleAddJobButtonClick = this.handleAddJobButtonClick.bind(this);
    this.handleAddMoreButtonClick = this.handleAddMoreButtonClick.bind(this);
    this.handleAddMoreClearButtonClick = this.handleAddMoreClearButtonClick.bind(this);
    this.handleEditJobButtonClick = this.handleEditJobButtonClick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleViewAddedClick = this.handleViewAddedClick.bind(this);
    this.handleItemSelectedChange = this.handleItemSelectedChange.bind(this);
  }

  componentDidMount() {
    this.props.store.getUserCountries();
    this.props.store.getLibraries();
    this.props.store.getIndustries();

    if (this._selectLibraryField && this._selectLibraryField.focus)
      this._selectLibraryField.focus();
    window.scrollTo(0, 0);
  }

  handleKeyPress(e: KeyboardEvent) {
    if (e.key.toLowerCase() === "h" && e.ctrlKey && e.shiftKey) {
      this.props.store.showDescriptions = !this.props.store.showDescriptions;
      console.log("show descriptions:", this.props.store.showDescriptions);
    }
  }

  handleViewAddedClick(e: Event) {
    this.props.store.showAddJobTitlesWizard = false;
  }

  UNSAFE_componentWillMount() {
    window.addEventListener("keypress", this.handleKeyPress);
  }

  componentWillUnmount() {
    window.removeEventListener("keypress", this.handleKeyPress);
  }

  getLibraryFieldRef(element: ?HTMLElement): void {
    this._selectLibraryField = element;
  }

  handleAddJobButtonClick(): void {
    const { store } = this.props;
    store.addJobToBatchSearch();
  }

  handleAddMoreButtonClick(): void {
    const { store } = this.props;
    store.addMoreJobs();
  }

  handleAddMoreClearButtonClick(): void {
    const { store } = this.props;
    store.addMoreJobs(true);
  }

  handleLocationTypeChange(value: number): void {
    this.props.store.onLocationTypeChange(value);

    if (value === LOCATION_TYPE.REGION) {
      this.props.store.getRegions();
    }
  }

  handleRemoveJobButtonClick(listItem: BatchSearchJobListItem): void {
    this.props.store.removeJobFromSearch(listItem);
  }

  handleEditJobButtonClick(listItem: BatchSearchJobListItem): void {
    this.props.store.editJobToSearch(listItem);
  }

  handleItemSelectedChange(listItem: BatchSearchJobListItem) {
    listItem.setSelected(!listItem.viewState.selected);
  }

  handleSelectAllJobsOnClick = () => {
    this.props.store.jobsToSearchItemsToggleAllSelected();
  };

  renderJobTitleSelection(jobTitle: RawJobTitle): Element<any> {
    return this.renderJobTitleItem(jobTitle);
  }

  renderJobTitleItem(
    jobTitle: RawJobTitle,
    term?: ?string = null,
    highlight?: ?(string, string) => Element<any> = null
  ): Element<any> {
    const title = term && highlight ? highlight(jobTitle.title, term) : jobTitle.title;
    return (
      <span className="no-wrap flex">
        {title}
        {jobTitle.libraryInfo && (
          <span className="flex center-items">
            {jobTitle.libraryInfo.adhocCountries.length > 0 &&
              jobTitle.isJobLabel &&
              jobTitle.sharedInfo &&
              jobTitle.sharedInfo.sharedBy && (
                <span className="pt-ui ss-tag">
                  <FontAwesomeIcon icon="user" />{" "}
                  {jobTitle.sharedInfo.sharedBy.fullName()}
                </span>
              )}
            {jobTitle.libraryInfo.certifiedCountries &&
              jobTitle.libraryInfo.certifiedCountries.length > 0 && (
                <Tooltip side="top" id="gss-tooltip" content="Certified Countries">
                  <span className="pt-ui ss-tag tag-green">
                    <FontAwesomeIcon icon="globe" />{" "}
                    {jobTitle.libraryInfo.certifiedCountries.length}
                  </span>
                </Tooltip>
              )}
            {jobTitle.libraryInfo.adhocCountries &&
              jobTitle.libraryInfo.adhocCountries.length > 0 && (
                <Tooltip side="top" id="gss-tooltip" content="Ad-Hoc Countries">
                  <span className="pt-ui ss-tag tag-red">
                    <FontAwesomeIcon icon="globe" />{" "}
                    {jobTitle.libraryInfo.adhocCountries.length}
                  </span>
                </Tooltip>
              )}
            {(!jobTitle.libraryInfo.certifiedCountries ||
              jobTitle.libraryInfo.certifiedCountries.length === 0) &&
              (!jobTitle.libraryInfo.adhocCountries ||
                jobTitle.libraryInfo.adhocCountries.length === 0) && (
                <span className="pt-ui ss-tag tag-yellow">
                  <span className="">
                    <FontAwesomeIcon icon="exclamation-circle" /> No Countries
                  </span>
                </span>
              )}
          </span>
        )}
      </span>
    );
  }

  renderCountryItem(
    location: Location,
    term?: ?string = null,
    highlight?: ?(string, string) => Element<any> = null
  ): Element<any> {
    return this._renderLocationWithCountryIcon(
      location,
      location.countryCode,
      term,
      highlight
    );
  }

  renderLocationItem(
    location: Location,
    term?: ?string = null,
    highlight?: ?(string, string) => Element<any> = null
  ): Element<any> {
    const store = this.props.store;
    const selectedUserCountry = store.selectedUserCountry;
    const countryCode =
      selectedUserCountry && selectedUserCountry.countryCode
        ? selectedUserCountry.countryCode
        : "";

    return this._renderLocationWithCountryIcon(location, countryCode, term, highlight);
  }

  _renderLocationWithCountryIcon(
    location: Location,
    countryCode: string = "",
    term?: ?string = null,
    highlight?: ?(string, string) => Element<any> = null
  ): Element<any> {
    let title =
      term && highlight ? highlight(location.toString(), term) : location.toString();
    if (location.highlight) {
      title = (
        <span>
          <span
            dangerouslySetInnerHTML={{
              __html: location.highlight.title.replace(/u>/g, "mark>"),
            }}
          />{" "}
          {location.highlight && location.highlight.subtitle !== "" && (
            <span
              dangerouslySetInnerHTML={{
                __html: "(" + location.highlight.subtitle.replace(/u>/g, "mark>") + ")",
              }}
              className="item-subtext-muted text-xx-small"
            />
          )}
        </span>
      );
    }
    return (
      <InlineElements
        as="span"
        css={{
          flexWrap: "nowrap",
          ".pt-ui &": { fontSize: "$base", lineHeight: "$base" },
        }}
      >
        {countryCode && <FlagIcon code={countryCode} />} {title}
      </InlineElements>
    );
  }

  renderBatchSearchJobItem(item: BatchSearchJobListItem): Element<any> {
    const store = this.props.store;
    return (
      <TR key={item.id} selected={item.viewState.selected}>
        <TD
          style={{ width: 30 }}
          onClick={this.handleItemSelectedChange.bind(this, item)}
        >
          <Checkbox checked={item.viewState.selected}>
            <CheckboxIndicator>
              <FontAwesomeIcon icon="check" />
            </CheckboxIndicator>
          </Checkbox>
        </TD>
        <TD onClick={this.handleItemSelectedChange.bind(this, item)}>{item.titleId}</TD>
        <TD onClick={this.handleItemSelectedChange.bind(this, item)}>{item.title}</TD>
        <TD onClick={this.handleItemSelectedChange.bind(this, item)}>
          {item.searchedTitle}
        </TD>
        <TD onClick={this.handleItemSelectedChange.bind(this, item)}>{item.category}</TD>
        <TD onClick={this.handleItemSelectedChange.bind(this, item)}>{item.industry}</TD>
        <TD onClick={this.handleItemSelectedChange.bind(this, item)}>
          {item.countryCode && <FlagIcon code={item.countryCode} />}
          {item.location}
        </TD>
        <TD onClick={this.handleItemSelectedChange.bind(this, item)}>
          {item.workerType}
        </TD>
        {store.showGSSColumn && (
          <TD
            className="text-center"
            onClick={this.handleItemSelectedChange.bind(this, item)}
          >
            {item.isGSS && <FontAwesomeIcon icon="check" />}
          </TD>
        )}
        <TD className="text-center options" style={{ width: 70 }}>
          <InlineElements css={{ justifyItems: "center", justifyContent: "center" }}>
            <Tooltip side="top" id="gss-tooltip" content="Edit">
              <Box css={{ color: "$brand" }}>
                <FontAwesomeIcon
                  icon="edit"
                  onClick={this.handleEditJobButtonClick.bind(this, item)}
                />
              </Box>
            </Tooltip>
            <Tooltip side="top" id="gss-tooltip" content="Remove">
              <Box css={{ color: "$danger" }}>
                <FontAwesomeIcon
                  icon="trash"
                  onClick={this.handleRemoveJobButtonClick.bind(this, item)}
                />
              </Box>
            </Tooltip>
          </InlineElements>
        </TD>
      </TR>
    );
  }

  _renderGSSPopover = () => {
    return (
      <Popover
        className="pt-ui-popover"
        id="popover-positioned-top"
        title="Global Supplier Search"
      >
        <p className="text-xx-small">
          <strong>Important Information</strong>
        </p>
        <p className="text-xx-small">
          Select this if you would like results that are intended for a larger percentage
          of data from Global suppliers.
        </p>
      </Popover>
    );
  };

  _renderGSSTooltipIcon = () => {
    return (
      <OverlayTrigger placement="top" overlay={this._renderGSSPopover()}>
        <FontAwesomeIcon
          icon="question-circle"
          className="icon"
          style={{ marginLeft: ".2em" }}
        />
      </OverlayTrigger>
    );
  };

  // _renderFilterPopOver = () => {
  //   return (
  //     <Popover id="filters-popover" title="Popover bottom">
  //       <strong>Holy guacamole!</strong> Check this info.
  //     </Popover>
  //   );
  // };

  render() {
    const store = this.props.store;

    const selectedUserCountry = store.selectedUserCountry;
    const canContinueWithLastAddedTitles =
      (store.selectedLocation || store.selectedRegion) &&
      store.jobTitlesSelectedCount > 0;
    const showTitles =
      store.addTitleWizard.currentStep >= 3 &&
      store.selectedUserCountry &&
      store.jobTitles.length > 0;
    const searchTitlesEnabled =
      store.addTitleWizard.currentStep >= 3 && store.selectedUserCountry;
    const selectLibraryEnabled = searchTitlesEnabled && !store.creatingFromLibrary;
    const showNoTitlesFound =
      store.addTitleWizard.currentStep === 3 &&
      store.selectedUserCountry &&
      store.jobTitles.length === 0;
    const showNoTitlesAvailableButLocationRequired =
      store.addTitleWizard.currentStep === 2 &&
      store.selectedUserCountry &&
      store.jobTitles.length === 0;
    const showTitlesAvailableButLocationRequired =
      store.addTitleWizard.currentStep === 2 &&
      store.selectedUserCountry &&
      store.jobTitles.length > 0;
    const canAddSelectedTitles =
      store.selectedUserCountry &&
      (store.selectedLocation || store.selectedRegion) &&
      store.selectedIndustry &&
      store.jobTitlesSelectedCount > 0;
    return (
      <div>
        <div className="container-section overlap-tb-padding no-border">
          <div style={{ maxWidth: 1200, margin: "auto" }}>
            {store.showAddJobTitlesWizard && (
              <LinearVerticalWizard
                wizardState={store.addTitleWizard}
                validateStep={store.addTitleValidateStep}
                onCurrentStepChange={store.addTitleOnCurrentStepChange}
                // onComplete={store.createAndRunBatchSearch}
                // lastStepButtonText="Run Batch Search"
              >
                <VerticalWizardStep
                  showDescription={store.showDescriptions}
                  title="Pick a Country"
                  description={
                    <p>
                      This will help you narrow down titles and locations to those
                      available in the selected country.
                    </p>
                  }
                >
                  <div className="form">
                    <div className="form-group">
                      <div style={{ width: 400 }}>
                        <LocationInfiniteScrollSelect
                          name="Countries"
                          items={store.userCountries}
                          selectedItem={store.selectedUserCountry}
                          inputValue={store.userCountrySearch}
                          onInputValueChange={store.onUserCountrySearchChange}
                          onSelectedItemChange={store.onSelectedUserCountryChange}
                          renderItem={this.renderCountryItem}
                          renderSelection={this.renderCountryItem}
                        />
                      </div>
                      <div className="control-info-text">
                        <FontAwesomeIcon icon="info-circle" className="icon" />
                        <em>
                          Don't see the Country you're looking for? You can{" "}
                          <Link
                            style={{ cursor: "pointer" }}
                            // query={{ b: window.location.pathname, s: window.location.search }}
                            onClick={store.onRequestJobTitle}
                          >
                            Request a Country.
                          </Link>
                        </em>
                      </div>
                      {store.creatingFromLibrary && (
                        <div className="control-info-text">
                          <FontAwesomeIcon icon="info-circle" className="icon" />
                          <em>
                            While creating a Batch Search from a library only countries
                            for that library are available.
                          </em>
                        </div>
                      )}
                    </div>
                  </div>
                </VerticalWizardStep>

                <VerticalWizardStep
                  showDescription={store.showDescriptions}
                  title={
                    <span>
                      Pick a Location and Industry{" "}
                      {selectedUserCountry && (
                        <span>in {selectedUserCountry.fullTitle} </span>
                      )}
                      {selectedUserCountry && selectedUserCountry.countryCode && (
                        <FlagIcon code={selectedUserCountry.countryCode} />
                      )}
                    </span>
                  }
                  description={
                    <p>
                      Rates can vary greatly for the same title depending on location and
                      industry.
                    </p>
                  }
                >
                  <div className="form">
                    <div className="form-group">
                      <div className="">
                        <Box
                          className="rc-checkbox-group form-horizontal"
                          css={{
                            ".pt-ui & label": {
                              fontSize: "$lg",
                              paddingTop: 10,
                            },
                          }}
                        >
                          <CheckBox
                            style={{ width: 150 }}
                            type="radio"
                            name="locationType"
                            value={LOCATION_TYPE.LOCATION}
                            selected={store.locationType === LOCATION_TYPE.LOCATION}
                            onChange={this.handleLocationTypeChange}
                          >
                            Locations
                          </CheckBox>
                          <CheckBox
                            style={{ width: 150 }}
                            type="radio"
                            name="locationType"
                            value={LOCATION_TYPE.REGION}
                            selected={store.locationType === LOCATION_TYPE.REGION}
                            onChange={this.handleLocationTypeChange}
                          >
                            Regions
                          </CheckBox>
                        </Box>
                        {store.locationType === LOCATION_TYPE.LOCATION && (
                          <div style={{ width: 400 }}>
                            <LocationInfiniteScrollSelect
                              name="locations"
                              disabled={
                                !store.selectedUserCountry &&
                                store.addTitleWizard.currentStep >= 2
                              }
                              items={store.locations}
                              selectedItem={store.selectedLocation}
                              inputValue={store.locationSearch}
                              onInputValueChange={store.onLocationSearchChange}
                              onSelectedItemChange={store.onSelectedLocationChange}
                              hasMoreItems={Boolean(store.locationsNextPage)}
                              loadMoreItems={store.locationsLoadFirstPage}
                              renderItem={this.renderLocationItem}
                            />
                          </div>
                        )}
                        {store.locationType === LOCATION_TYPE.REGION && (
                          <div style={{ width: 400 }}>
                            <RegionInfiniteScrollSelect
                              disabled={
                                !store.selectedUserCountry &&
                                store.addTitleWizard.currentStep >= 2
                              }
                              items={store.regions}
                              selectedItem={store.selectedRegion}
                              inputValue={store.regionSearch}
                              onInputValueChange={store.onRegionSearchChange}
                              onSelectedItemChange={store.onSelectedRegionChange}
                              // hasMoreItems={Boolean(store.locationsNextPage)}
                              // loadMoreItems={store.locationsLoadFirstPage}
                              // searchPlaceholder="Please type 2 or more characters..."
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {store.showGSSOption && (
                      <div className="form-group">
                        <Box
                          className="control-info-text"
                          css={{
                            ".pt-ui & label": {
                              fontSize: "$lg",
                            },
                          }}
                        >
                          <CheckBox
                            type="checkbox"
                            name="iss"
                            value="gss"
                            selected={store.isGSS}
                            onChange={store.onIsGSSChange}
                          >
                            Global Supplier Search {this._renderGSSTooltipIcon()}
                          </CheckBox>
                        </Box>
                      </div>
                    )}

                    <div className="form-group">
                      <label className="rc-control-label control-label">Industry</label>
                      <div style={{ width: 400 }}>
                        <IndustryInfiniteScrollSelect
                          name="industries"
                          disabled={
                            !store.selectedUserCountry &&
                            store.addTitleWizard.currentStep >= 2
                          }
                          items={store.industries}
                          selectedItem={store.selectedIndustry}
                          inputValue={store.industrySearch}
                          onInputValueChange={store.onIndustrySearchChange}
                          onSelectedItemChange={store.onSelectedIndustryChange}
                        />
                      </div>
                    </div>

                    {store.showWorkerTypes && (
                      <div className="form-group">
                        <label className="rc-control-label control-label">
                          Worker Type <span className="text-muted">(Optional)</span>
                        </label>
                        <div style={{ width: 400 }}>
                          <WorkerTypeInfiniteScrollSelect
                            name="workerTypes"
                            clearable
                            showSearchInput={false}
                            disabled={!store.showWorkerTypes}
                            items={store.workerTypes}
                            selectedItem={store.selectedWorkerType}
                            onSelectedItemChange={store.onSelectedWorkerTypeChange}
                          />
                        </div>
                        {store.selectedWorkerType && (
                          <div className="control-info-text" style={{ width: 400 }}>
                            <strong>Worker Type Description: </strong>{" "}
                            {store.selectedWorkerType.description}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </VerticalWizardStep>

                <VerticalWizardStep
                  showDescription={store.showDescriptions}
                  title={
                    <span>
                      Select Titles{" "}
                      {selectedUserCountry && (
                        <span>in {selectedUserCountry.fullTitle} </span>
                      )}
                      {selectedUserCountry && selectedUserCountry.countryCode && (
                        <FlagIcon code={selectedUserCountry.countryCode} />
                      )}
                    </span>
                  }
                  description={
                    <p>
                      You can add multiple titles at once. Though we don't recommend
                      adding more than 2000 items per Batch Search due to performance
                      reasons.
                    </p>
                  }
                >
                  <div className="form">
                    <div className="form-group">
                      <div className="flex-container align-items-center">
                        <InstantSearchBox
                          style={{ width: 300, marginRight: 20 }}
                          onSearch={store.onJobTitleSearchChange}
                          value={store.jobTitleSearch}
                          disabled={!searchTitlesEnabled}
                          placeholder="Search..."
                        />
                        <div style={{ width: 300, marginRight: 20 }}>
                          <LibraryInfiniteScrollSelect
                            name="libraries"
                            innerRef={this.getLibraryFieldRef}
                            disabled={!selectLibraryEnabled}
                            items={store.clientLibraries}
                            selectedItem={store.selectedLibrary}
                            inputValue={store.librarySearch}
                            onInputValueChange={store.onLibrarySearchChange}
                            onSelectedItemChange={store.onSelectedLibraryChange}
                            placeholder="Filter by Library"
                          />
                        </div>
                        <div style={{ marginRight: 20 }}>
                          <label className="control-label rc-control-label no-margin text-x-small">
                            <em>
                              {/*{store.jobTitlesSelected.size} Selected <span style={{ margin: "0 8px" }}>/</span>{" "}*/}
                              {store.jobTitlesTotalMatchesCount} Title Matches
                            </em>
                          </label>
                        </div>
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button
                              id="batch-search-titles-selection-dropdown"
                              // bsStyle={store.jobTitlesSelected.size > 0 ? "success" : "warning"}
                              text={`${store.jobTitlesSelected.size} Selected ▼`}
                              disabled={!showTitles}
                            />
                          </DropdownMenuTrigger>
                          <DropdownMenuContent>
                            <DropdownMenuItem
                              text={`Select Page (${store.jobTitles.length} Titles)`}
                              onClick={store.onJobTitlesSelectAllPage}
                            />
                            <DropdownMenuItem
                              text={`Select Matches (${store.jobTitlesTotalMatchesCount} Titles)`}
                              onClick={store.onJobTitlesSelectAllMatches}
                            />
                            <DropdownMenuSeparator />
                            <DropdownMenuItem
                              text="Clear Selection"
                              onClick={store.onJobTitlesClearSelection}
                            />
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </div>

                      <div className="control-info-text">
                        <FontAwesomeIcon icon="info-circle" className="icon" />
                        <em>
                          Don't see the title you're looking for? You can{" "}
                          <Link
                            style={{ cursor: "pointer" }}
                            // query={{ b: window.location.pathname, s: window.location.search }}
                            onClick={store.onRequestJobTitle}
                          >
                            Request a Title.
                          </Link>
                        </em>
                      </div>
                    </div>
                    <div className="form-group">
                      <Loadable isLoading={store.jobTitlesNetwork.loading}>
                        <div className="search-table ss-job-title-table no-margin">
                          {showTitles &&
                            store.jobTitles.map((jobTitle) => (
                              <RawJobTitleListItem
                                key={jobTitle.id}
                                rawJobTitle={jobTitle}
                              />
                            ))}
                          {showNoTitlesFound && (
                            <Alert
                              bsStyle="default"
                              style={{ minHeight: 400, margin: 0 }}
                            >
                              <p>No titles found!</p>
                            </Alert>
                          )}
                          {showNoTitlesAvailableButLocationRequired && (
                            <Alert
                              bsStyle="default"
                              style={{ minHeight: 400, margin: 0 }}
                            >
                              <p>No titles found!</p>
                            </Alert>
                          )}
                          {showTitlesAvailableButLocationRequired && (
                            <Alert
                              bsStyle="default"
                              style={{ minHeight: 400, margin: 0 }}
                            >
                              <p>
                                {store.jobTitlesTotalMatchesCount} titles available but
                                you need to select a location and industry first.
                              </p>
                            </Alert>
                          )}
                          {!store.selectedUserCountry && (
                            <Alert
                              bsStyle="default"
                              style={{ minHeight: 400, margin: 0 }}
                            >
                              <p>No Country selected</p>
                            </Alert>
                          )}
                        </div>
                        {showTitles && (
                          <Pagination
                            options={{
                              variant: "full",
                              currentPage: store.jobTitlesPagination.currentPage,
                              numPages: store.jobTitlesPagination.pageCount,
                            }}
                            onPageClick={(pageEvent) => {
                              if (pageEvent.type === "first") {
                                store.jobTitlesPagination.handleCurrentPage(1);
                              }
                              if (pageEvent.type === "last") {
                                store.jobTitlesPagination.handleCurrentPage(
                                  store.jobTitlesPagination.pageCount
                                );
                              }
                              if (pageEvent.type === "next") {
                                store.jobTitlesPagination.handleCurrentPage(
                                  Math.min(
                                    store.jobTitlesPagination.currentPage + 1,
                                    store.jobTitlesPagination.pageCount
                                  )
                                );
                              }
                              if (pageEvent.type === "prev") {
                                store.jobTitlesPagination.handleCurrentPage(
                                  Math.max(store.jobTitlesPagination.currentPage - 1, 1)
                                );
                              }
                              if (pageEvent.type === "page") {
                                store.jobTitlesPagination.handleCurrentPage(
                                  pageEvent.page
                                );
                              }
                            }}
                            // hasLoaded={store.jobTitlesPagination.hasLoaded}
                            // pageCount={store.jobTitlesPagination.pageCount}
                            // pagesToShow={store.jobTitlesPagination.maxPagesToShow}
                            // currentPage={store.jobTitlesPagination.currentPage}
                            // handleCurrentPage={store.jobTitlesPagination.handleCurrentPage}
                            // begin={store.jobTitlesPagination.begin}
                            // end={store.jobTitlesPagination.end}
                            // isEndRange={store.jobTitlesPagination.isEndRange}
                            // firstPage={store.jobTitlesPagination.firstPage}
                          />
                        )}
                      </Loadable>
                    </div>
                  </div>
                </VerticalWizardStep>

                <VerticalWizardStep
                  showDescription={store.showDescriptions}
                  title="Add to Batch Search"
                  description={
                    <p>This will create Search items with your selections above.</p>
                  }
                >
                  <div className="form">
                    <div className="form-group">
                      <Button
                        size="large"
                        color={canAddSelectedTitles ? "accent" : "primary"}
                        onClick={this.handleAddJobButtonClick}
                        disabled={!canAddSelectedTitles}
                        data-testid="add-search-items-and-review-button"
                      >
                        <div className="flex-container" style={{ textAlign: "left" }}>
                          <div style={{ marginRight: 8 }}>
                            <FontAwesomeIcon icon="plus" className="icon" />
                          </div>
                          <div>
                            Add {store.jobTitlesSelected.size} Search Items And Go To
                            Review
                            <div className="control-info-text text-thin text-xx-small">
                              <em>Don't worry. You can come back and add more items</em>
                            </div>
                          </div>
                        </div>
                      </Button>
                    </div>
                    <label className="control-label rc-control-label">
                      <em>
                        You've added {store.jobsToSearchItemsTotalCount}{" "}
                        {store.jobsToSearchItemsTotalCount === 1
                          ? "Search item "
                          : "Search items "}
                        so far
                      </em>
                    </label>
                    {store.jobsToSearchItemsTotalCount > 0 && (
                      <div style={{ marginTop: 6 }}>
                        <Button icon="th-list" onClick={this.handleViewAddedClick}>
                          View Added Items
                        </Button>
                      </div>
                    )}
                  </div>
                </VerticalWizardStep>
              </LinearVerticalWizard>
            )}
            {!store.showAddJobTitlesWizard && (
              <LinearVerticalWizard
                wizardState={new WizardState()}
                validateStep={() => true}
              >
                <VerticalWizardStep
                  showDescription={store.showDescriptions}
                  static={true}
                  title="Review added searches"
                  description={
                    <p>
                      Make sure you've got everything you want. Feel free to change or
                      remove items at any time.
                    </p>
                  }
                >
                  <div className="form">
                    {store.jobsToSearch.length === 0 && (
                      <Alert bsStyle="warning">
                        <h4 style={{ marginBottom: 15 }}>
                          No jobs have been added to your search.
                        </h4>

                        <Button onClick={this.handleAddMoreClearButtonClick}>
                          <div style={{ textAlign: "left" }}>
                            Add Jobs To Batch Search
                            <div className="control-info-text text-thin text-xx-small">
                              <em>
                                Select Country, Location, Industry
                                <br />
                                and Titles to add
                              </em>
                            </div>
                          </div>
                        </Button>
                      </Alert>
                    )}
                    {store.jobsToSearch.length > 0 && (
                      <div>
                        <div className="form-group">
                          <InlineElements css={{ paddingBottom: "$2" }}>
                            <div>
                              <label
                                className="control-label rc-control-label"
                                style={{ marginBottom: 10 }}
                              >
                                <em>
                                  {store.jobsToSearchItemsTotalCount}{" "}
                                  {store.jobsToSearchItemsTotalCount === 1
                                    ? "Search item "
                                    : "Search items "}
                                  added so far
                                </em>
                              </label>
                            </div>
                            <Spacer />
                            {/*<InstantSearchBox*/}
                            {/*placeholder="Search by title..."*/}
                            {/*small*/}
                            {/*iconLeft*/}
                            {/*style={{ marginRight: 10 }}*/}
                            {/*/>*/}
                            {store.jobsToSearchAppliedFilters.length > 0 && (
                              <Button
                                color="accent"
                                icon="close"
                                onClick={store.clearJobsToSearchFilters}
                              >
                                Clear Filters
                              </Button>
                            )}
                            <Button
                              icon="filter"
                              onClick={store.jobsToSearchFiltersModal.showModal}
                            >
                              Filters
                              {store.jobsToSearchAppliedFilters.length > 0 && (
                                <span>{`(${store.jobsToSearchItemsView.length} Match${
                                  store.jobsToSearchItemsView.length === 1 ? "" : "es"
                                })`}</span>
                              )}
                            </Button>
                            <DropdownMenu>
                              <DropdownMenuTrigger asChild>
                                <Button
                                  id="batch-search-job-options-dropdown"
                                  icon="ellipsis-v"
                                >
                                  More Options
                                </Button>
                              </DropdownMenuTrigger>
                              <DropdownMenuContent>
                                {canContinueWithLastAddedTitles && (
                                  <DropdownMenuItem
                                    eventKey="1"
                                    onSelect={this.handleAddMoreButtonClick}
                                  >
                                    <div style={{ textAlign: "left" }}>
                                      Pick Up Where You Left Off
                                      <div className="control-info-text text-thin text-xx-small">
                                        <em>
                                          Your last selected Country, Location, <br />
                                          Industry and Titles will be preserved
                                        </em>
                                      </div>
                                    </div>
                                  </DropdownMenuItem>
                                )}
                                {canContinueWithLastAddedTitles && (
                                  <DropdownMenuSeparator />
                                )}
                                {canContinueWithLastAddedTitles && (
                                  <DropdownMenuItem
                                    eventKey="2"
                                    onSelect={this.handleAddMoreClearButtonClick}
                                  >
                                    <div style={{ textAlign: "left" }}>
                                      Need to Start Over? Add a Location etc?
                                      <div className="control-info-text text-thin text-xx-small">
                                        <em>
                                          Select new Country, Location, Industry
                                          <br />
                                          and Titles to add
                                        </em>
                                      </div>
                                    </div>
                                  </DropdownMenuItem>
                                )}
                                {canContinueWithLastAddedTitles && (
                                  <DropdownMenuSeparator />
                                )}
                                {!canContinueWithLastAddedTitles && (
                                  <DropdownMenuItem
                                    eventKey="3"
                                    onSelect={this.handleAddMoreClearButtonClick}
                                  >
                                    <div style={{ textAlign: "left" }}>
                                      Add More Titles
                                      <div className="control-info-text text-thin text-xx-small">
                                        <em>
                                          Select new Country, Location, Industry
                                          <br />
                                          and Titles to add
                                        </em>
                                      </div>
                                    </div>
                                  </DropdownMenuItem>
                                )}
                                {!canContinueWithLastAddedTitles && (
                                  <DropdownMenuSeparator />
                                )}
                                {store.jobsToSearchItemsAllInSameCountry && (
                                  <DropdownMenuItem
                                    eventKey="4"
                                    onSelect={store.addNewLocationForAllJobs}
                                  >
                                    Add New Location For All Titles
                                  </DropdownMenuItem>
                                )}
                                {store.jobsToSearchItemsSelectedAllInSameCountry && (
                                  <DropdownMenuItem
                                    eventKey="5"
                                    onSelect={store.changeLocationForAllSelectedJobs}
                                  >
                                    Change Location For Selected Items
                                  </DropdownMenuItem>
                                )}
                                {store.jobsToSearchItemsSelectedCount > 0 && (
                                  <DropdownMenuItem
                                    eventKey="6"
                                    onSelect={store.bulkChangeIndustryModal.showModal}
                                  >
                                    Change Industry For Selected Items
                                  </DropdownMenuItem>
                                )}
                                {store.jobsToSearchItemsSelectedCount > 0 && (
                                  <DropdownMenuItem
                                    eventKey="7"
                                    onSelect={store.bulkRemoveJobsModal.showModal}
                                  >
                                    Remove Selected Items
                                  </DropdownMenuItem>
                                )}
                              </DropdownMenuContent>
                            </DropdownMenu>
                          </InlineElements>
                          <Table>
                            <THead>
                              <TR>
                                <TH
                                  css={{ width: 30 }}
                                  onClick={this.handleSelectAllJobsOnClick}
                                >
                                  <Checkbox checked={store.jobsToSearchItemsAllSelected}>
                                    <CheckboxIndicator>
                                      <FontAwesomeIcon icon="check" />
                                    </CheckboxIndicator>
                                  </Checkbox>
                                </TH>
                                <TH>ID</TH>
                                <TH>Title</TH>
                                <TH>Searched Title</TH>
                                <TH>Category</TH>
                                <TH>Industry</TH>
                                <TH>Location</TH>
                                <TH>Worker Type</TH>
                                {store.showGSSColumn && (
                                  <TH css={{ width: 64 }}>
                                    GSS {this._renderGSSTooltipIcon()}
                                  </TH>
                                )}
                                <TH css={{ width: 70 }}>Actions</TH>
                              </TR>
                            </THead>
                            <TBody>
                              {store.jobsToSearchItemsView.map(
                                (job: BatchSearchJobListItem) => {
                                  return this.renderBatchSearchJobItem(job);
                                }
                              )}
                            </TBody>
                          </Table>
                        </div>
                      </div>
                    )}
                  </div>
                </VerticalWizardStep>
              </LinearVerticalWizard>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default observer(WizardStep02TitlesAndLocations);
