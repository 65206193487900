// @flow
import { extendObservable, action } from "mobx";
import FilterObject, { ValueSetFilter, FilterCriteriaLoader, IViewItem } from "../Filter";
import type { FilterColumn } from "../Filter";
import { GraphQLQuery } from "../GraphQL";

class LocationTypeCriterionItem implements IViewItem {
  id: string;
  value: string;
  selected: string;

  constructor(object: Object) {
    this.id = object.id;
    this.value = object.value;

    extendObservable(this, {
      selected: object.selected,
    });
  }
}

export default class LocationTypeFilter extends FilterCriteriaLoader(ValueSetFilter()) {
  constructor(
    store: Object,
    column: FilterColumn,
    filterCriteriaQuery: (FilterColumn) => GraphQLQuery,
    processPayload: (FilterColumn, Object) => Array<Object>,
    applyFilter: (FilterColumn, FilterObject) => void,
    removeFilter: (FilterColumn) => void,
    reloadCriteria: boolean = false
  ) {
    super(store, column);

    this.allowMultipleItemSelection = false; // only one item selected at a time

    this.filterCriteriaQuery = filterCriteriaQuery;
    this.processPayload = processPayload;
    this.applyFilter = applyFilter;
    this.removeFilter = removeFilter;

    this.reloadCriteria = reloadCriteria;

    this.viewItemClass = LocationTypeCriterionItem;

    this.buildQueryFilter = action(this.buildQueryFilter.bind(this));
  }

  buildQueryFilter() {
    const rateTypes = this.viewItems
      .filter((item) => item.selected)
      .map((item) => item.value);

    if (!rateTypes.length) {
      // console.error('Unable to build Rate Type filter: No value selected');
      return null;
    }

    if (rateTypes.length > 1) {
      // NOTE: Both Hourly/Annual selected, just return
      console.error("Unable to build Rate Type filter: More than one value selected");
      return null;
    }

    if (this.filter && this.filter.queryVars.rateType === rateTypes[0])
      return this.filter;

    return new FilterObject("$rateType: JobRateType", "rateType: $rateType", {
      rateType: rateTypes[0],
    });
  }
}
