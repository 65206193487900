import React from "react";

import Icon from "../../../../../components/lib/Icon";
import { RATE_FEEDBACK_STATUS_TYPES } from "../../../types";
import { RatesButton } from "./ButtonStyle";

import type { RATE_FEEDBACK_STATUS_TYPES_TYPE } from "../../../types";

type Props = {
  status: RATE_FEEDBACK_STATUS_TYPES_TYPE;
  onClick: () => void;
};

export default function RatesFeedbackDisagreeButton(props: Props) {
  const { status, onClick } = props;
  const isDeniedStatus = status === RATE_FEEDBACK_STATUS_TYPES.DENIED;

  return (
    <RatesButton
      css={{
        backgroundColor: isDeniedStatus ? "$dangerLightest" : undefined,
        "&:hover": { backgroundColor: "$dangerLighter" },
      }}
      onClick={onClick}
    >
      <Icon icon="close" /> Disagree
    </RatesButton>
  );
}

RatesFeedbackDisagreeButton.displayName = "RatesFeedbackDisagreeButton";
