/**
 * @generated SignedSource<<2dbb31a5b07d5660e3984728d494da0f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RecentSearches_viewer$data = {
  readonly recentSearches: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly createdDate: string;
        readonly id: string;
        readonly industry: {
          readonly id: string;
          readonly value: string;
        } | null;
        readonly isGlobalSupplierSearch: boolean | null;
        readonly job: {
          readonly jobLabel: string;
          readonly jobTitle: string | null;
          readonly jobTitleId: number | null;
        };
        readonly locationFullLabel: string | null;
        readonly name: string | null;
        readonly rateType: number | null;
        readonly region: {
          readonly name: string;
          readonly regionId: number | null;
        } | null;
        readonly searchId: number | null;
        readonly workerTypeName: string | null;
      } | null;
    } | null>;
  } | null;
  readonly " $fragmentType": "RecentSearches_viewer";
};
export type RecentSearches_viewer$key = {
  readonly " $data"?: RecentSearches_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecentSearches_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "viewer"
      ],
      "operation": require('./RecentSearchesRefetch.graphql')
    }
  },
  "name": "RecentSearches_viewer",
  "selections": [
    {
      "alias": "recentSearches",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 9
        },
        {
          "kind": "Literal",
          "name": "order",
          "value": [
            {
              "direction": "DESC",
              "field": "CREATE_DATE"
            }
          ]
        }
      ],
      "concreteType": "SaveSearchNodeConnection",
      "kind": "LinkedField",
      "name": "savedsearches",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SaveSearchNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SaveSearchNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "searchId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdDate",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rateType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "locationFullLabel",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isGlobalSupplierSearch",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "workerTypeName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "IndustryNode",
                  "kind": "LinkedField",
                  "name": "industry",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "value",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "NewRegionNode",
                  "kind": "LinkedField",
                  "name": "region",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "regionId",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "JobResponse",
                  "kind": "LinkedField",
                  "name": "job",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "jobTitle",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "jobLabel",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "jobTitleId",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "savedsearches(first:9,order:[{\"direction\":\"DESC\",\"field\":\"CREATE_DATE\"}])"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "798823995f6ea1d47676e46eedf0f685";

export default node;
