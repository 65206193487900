import React, { Suspense } from "react";
import { useLazyLoadQuery } from "react-relay";
import { InjectedRouter, RouterState } from "react-router";
// @ts-ignore
import graphql from "babel-plugin-relay/macro";

import Container from "../../components/lib/Container";
import Inline from "../../components/lib/Inline";
import Box from "../../components/lib/Box";
import Button from "../../components/lib/Button";
import SearchBox from "../../components/lib/SearchBox";
import { NavBar } from "../../components/NavBar2";
import Footer from "../../components/lib/Footer";
import EditActions from "../../components/lib/EditActions";
import { TickerPageLoader, TickerContentLoader } from "../../components/lib/TickerLoader";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "../../components/lib/Dialog";
import {
  Card,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
  CardAlert,
  CardFilters,
  CardHeader,
  CardHeaderSubTitle,
  CardHeaderTitle,
} from "../../components/lib/Card";

import {
  FilterByTagDialog,
  useFilterByTagDialogState,
} from "../../components/FilterByTagDialog";
import { RateTypeFilterDialog } from "../../components/RateTypeFilterDialog";
import {
  FilterByAuthorDialog,
  useFilterByAuthorDialogState,
} from "../../components/FilterByAuthorDialog";
import {
  FilterByDateDialog,
  useFilterByDateDialogState,
} from "../../components/FilterByDateDialog";
import { SharedFilterDialog } from "../../components/SharedFilterDialog";

import RateCardsList from "./components/RateCardsList";

import { useRCListFilterMachine } from "../../machines/rcListFilterMachine";
import { useRCListSortMachine } from "../../machines/rcListSortMachine";

import type { RateCardsStitchesQuery as RateCardsStitchesQueryType } from "./__generated__/RateCardsStitchesQuery.graphql";
import type { FilterByTagDialog_viewer$key } from "../../components/__generated__/FilterByTagDialog_viewer.graphql";
import type { FilterByAuthorDialog_viewer$key } from "../../components/__generated__/FilterByAuthorDialog_viewer.graphql";

function RateCardsHelpDialog() {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button icon="question-circle">Help</Button>
      </DialogTrigger>
      <DialogContent
        onOpenAutoFocus={(e) => e.preventDefault()}
        onCloseAutoFocus={(e) => e.preventDefault()}
      >
        <DialogTitle>Rate Cards Help</DialogTitle>
        <DialogDescription asChild>
          <div>
            <p>
              This page contains all of the Rate Cards you have access to. Click{" "}
              <b>New Rate Card</b> to add a new Rate Card from your draft searches. Click{" "}
              <b>View</b> to view, edit or delete searches in a Rate Card.
            </p>
            <h4>Edit Mode</h4>
            <p>
              Perform bulk editing, sharing, exports, and updating on your Rate Cards
              using the <b>Edit</b> button. The <b>Edit</b> mode will also allow you to
              share specific rate cards among your company users. To export the entire
              rate card to Excel/CSV formats, click <b>Edit</b> and then select the rate
              cards you would like to export.
            </p>
          </div>
        </DialogDescription>
        <Inline
          css={{
            justifyContent: "right",
            padding: "20px",
          }}
        >
          <DialogClose asChild>
            <Button>Close</Button>
          </DialogClose>
        </Inline>
      </DialogContent>
    </Dialog>
  );
}

type RateCardsStitchesQueryProps = {
  router: RouterState & InjectedRouter;
  logout: () => void;
};

function RateCardsStitchesQuery(props: RateCardsStitchesQueryProps) {
  const [editing, setEditing] = React.useState<boolean | null>(null);
  const [searchInputState, setSearchInputState] = React.useState<string>("");
  const [appliedSearchState, setAppliedSearchState] = React.useState<string>("");

  const data = useLazyLoadQuery<RateCardsStitchesQueryType>(
    graphql`
      query RateCardsStitchesQuery {
        viewer {
          user {
            ...NavBar2_user
          }
          ...NavBar2NotificationsDropdown_notifications
          ...RateCardsList_viewer
            @arguments(first: 10, order: { field: CREATE_DATE, direction: DESC })
          ...FilterByTagDialog_viewer @arguments(first: 100)
          ...FilterByAuthorDialog_viewer @arguments(first: 100)
        }
      }
    `,
    {}
  );

  // applied filters/sorts state

  const { rcListFilterState, sendToRCListFilterMachine } =
    useRCListFilterMachine("RCListFilterMachine");
  const { rcListSortState, sendToRCListSortMachine } =
    useRCListSortMachine("RCListSortMachine");

  // filter by tag dialog state

  const {
    handleSelectAllTags,
    handleDeSelectAllTags,
    handleTagCheckedChange,
    handleTagSearchTermChange,
    handleFilterByTagDialogStateReset,
    ...filterByTagState
  } = useFilterByTagDialogState(
    {
      searchTerm: "",
      // TODO derive initial selection state
    },
    data.viewer as unknown as FilterByTagDialog_viewer$key,
    "TagsSelectionMachine"
  );

  // filter by author dialog state

  const {
    handleSelectAllAuthors,
    handleDeSelectAllAuthors,
    handleAuthorCheckedChange,
    handleAuthorSearchTermChange,
    handleSortByAuthorDirectionChange,
    handleFilterByAuthorDialogStateReset,
    ...filterByAuthorState
  } = useFilterByAuthorDialogState(
    {
      searchTerm: "",
      sortDirection:
        rcListSortState.context.sortFieldsMap.get("OWNER_FIRST_NAME")?.direction ??
        undefined,
      // TODO derive initial selection state
    },
    data.viewer as unknown as FilterByAuthorDialog_viewer$key,
    "AuthorsSelectionMachine"
  );

  // filter by date dialog state

  const {
    handleFromDateChange,
    handleToDateChange,
    handleSortByDateDirectionChange,
    handleFilterByDateDialogStateReset,
    ...filterByDateState
  } = useFilterByDateDialogState({
    sortDirection:
      rcListSortState.context.sortFieldsMap.get("CREATE_DATE")?.direction ?? undefined,
    fromDate: rcListFilterState.context.filters["fromDate"],
    toDate: rcListFilterState.context.filters["toDate"],
  });

  // handlers

  const handleClearAllFiltersAndSorts = React.useCallback(() => {
    setSearchInputState("");
    setAppliedSearchState("");
    sendToRCListFilterMachine({ type: "REMOVE_ALL_FILTERS" });
    sendToRCListSortMachine({ type: "REMOVE_ALL_SORT_FIELDS" });
    handleFilterByTagDialogStateReset();
    handleFilterByAuthorDialogStateReset();
    handleFilterByDateDialogStateReset();
  }, [
    sendToRCListFilterMachine,
    sendToRCListSortMachine,
    handleFilterByTagDialogStateReset,
    handleFilterByAuthorDialogStateReset,
    handleFilterByDateDialogStateReset,
  ]);

  const handleSearchInputChange = React.useCallback(
    (value: string | undefined) => {
      const cleanValue = value ?? "";

      if (cleanValue !== searchInputState) {
        setSearchInputState(cleanValue);
      }
    },
    [searchInputState]
  );

  const handleSearchInputSubmit = React.useCallback(
    (value: string | undefined) => {
      const cleanValue = value ?? "";

      handleSearchInputChange(cleanValue);
      setAppliedSearchState(cleanValue);
    },
    [handleSearchInputChange]
  );

  const handleApplyFilterByTag = React.useCallback(() => {
    if (filterByTagState.selectionState.context.selection.size > 0) {
      sendToRCListFilterMachine({
        type: "ADD_FILTER",
        field: "tagnameIn",
        value: Array.from(filterByTagState.selectionState.context.selection.values()),
      });
    } else {
      sendToRCListFilterMachine({ type: "REMOVE_FILTER", field: "tagnameIn" });
    }
  }, [filterByTagState.selectionState.context.selection, sendToRCListFilterMachine]);

  const handleClearFilterByTag = React.useCallback(() => {
    handleDeSelectAllTags();
    sendToRCListFilterMachine({ type: "REMOVE_FILTER", field: "tagnameIn" });
  }, [handleDeSelectAllTags, sendToRCListFilterMachine]);

  const handleApplyFilterByAuthor = React.useCallback(() => {
    if (filterByAuthorState.selectionState.context.selection.size > 0) {
      sendToRCListFilterMachine({
        type: "ADD_FILTER",
        field: "ownerId",
        value: Array.from(filterByAuthorState.selectionState.context.selection.values()),
      });
    } else {
      sendToRCListFilterMachine({ type: "REMOVE_FILTER", field: "ownerId" });
    }

    if (
      filterByAuthorState.sortDirection != null &&
      filterByAuthorState.sortDirection !==
        rcListSortState.context.sortFieldsMap.get("OWNER_FIRST_NAME")?.direction
    ) {
      sendToRCListSortMachine({
        type: "ADD_SORT_FIELD",
        field: "OWNER_FIRST_NAME",
        direction: filterByAuthorState.sortDirection,
      });
      sendToRCListSortMachine({
        type: "ADD_SORT_FIELD",
        field: "OWNER_LAST_NAME",
        direction: filterByAuthorState.sortDirection,
      });
    } else if (
      filterByAuthorState.sortDirection == null &&
      rcListSortState.context.sortFieldsMap.get("OWNER_FIRST_NAME") != null
    ) {
      sendToRCListSortMachine({ type: "REMOVE_SORT_FIELD", field: "OWNER_FIRST_NAME" });
      sendToRCListSortMachine({ type: "REMOVE_SORT_FIELD", field: "OWNER_LAST_NAME" });
    }
  }, [
    filterByAuthorState.selectionState.context.selection,
    filterByAuthorState.sortDirection,
    rcListSortState.context.sortFieldsMap,
    sendToRCListFilterMachine,
    sendToRCListSortMachine,
  ]);

  const handleClearFilterByAuthor = React.useCallback(() => {
    handleFilterByAuthorDialogStateReset();
    sendToRCListFilterMachine({ type: "REMOVE_FILTER", field: "ownerId" });
    sendToRCListSortMachine({ type: "REMOVE_SORT_FIELD", field: "OWNER_FIRST_NAME" });
    sendToRCListSortMachine({ type: "REMOVE_SORT_FIELD", field: "OWNER_LAST_NAME" });
  }, [
    sendToRCListFilterMachine,
    sendToRCListSortMachine,
    handleFilterByAuthorDialogStateReset,
  ]);

  const handleApplyFilterByDate = React.useCallback(() => {
    if (
      filterByDateState.fromDate != null &&
      filterByDateState.fromDate !== rcListFilterState.context.filters["fromDate"]
    ) {
      sendToRCListFilterMachine({
        type: "ADD_FILTER",
        field: "fromDate",
        value: filterByDateState.fromDate,
      });
    } else if (
      filterByDateState.fromDate == null &&
      rcListFilterState.context.filters["fromDate"] != null
    ) {
      sendToRCListFilterMachine({ type: "REMOVE_FILTER", field: "fromDate" });
    }

    if (
      filterByDateState.toDate != null &&
      filterByDateState.toDate !== rcListFilterState.context.filters["toDate"]
    ) {
      sendToRCListFilterMachine({
        type: "ADD_FILTER",
        field: "toDate",
        value: filterByDateState.toDate,
      });
    } else if (
      filterByDateState.toDate == null &&
      rcListFilterState.context.filters["toDate"] != null
    ) {
      sendToRCListFilterMachine({ type: "REMOVE_FILTER", field: "toDate" });
    }

    if (
      filterByDateState.sortDirection != null &&
      filterByDateState.sortDirection !==
        rcListSortState.context.sortFieldsMap.get("CREATE_DATE")?.direction
    ) {
      sendToRCListSortMachine({
        type: "ADD_SORT_FIELD",
        field: "CREATE_DATE",
        direction: filterByDateState.sortDirection,
      });
    } else if (
      filterByDateState.sortDirection == null &&
      rcListSortState.context.sortFieldsMap.get("CREATE_DATE") != null
    ) {
      sendToRCListSortMachine({ type: "REMOVE_SORT_FIELD", field: "CREATE_DATE" });
    }
  }, [
    filterByDateState.fromDate,
    filterByDateState.toDate,
    filterByDateState.sortDirection,
    rcListFilterState.context.filters,
    rcListSortState.context.sortFieldsMap,
    sendToRCListFilterMachine,
    sendToRCListSortMachine,
  ]);

  const handleClearFilterByDate = React.useCallback(() => {
    handleFilterByDateDialogStateReset();
    sendToRCListFilterMachine({ type: "REMOVE_FILTER", field: "fromDate" });
    sendToRCListFilterMachine({ type: "REMOVE_FILTER", field: "toDate" });
    sendToRCListSortMachine({ type: "REMOVE_SORT_FIELD", field: "CREATE_DATE" });
  }, [
    sendToRCListFilterMachine,
    sendToRCListSortMachine,
    handleFilterByDateDialogStateReset,
  ]);

  const handleApplyRateTypeFilter = React.useCallback(() => {
    sendToRCListFilterMachine({ type: "ADD_FILTER", field: "rateType", value: "foo" });
  }, [sendToRCListFilterMachine]);

  const handleClearRateTypeFilter = React.useCallback(() => {
    sendToRCListFilterMachine({ type: "REMOVE_FILTER", field: "rateType" });
  }, [sendToRCListFilterMachine]);

  const handleApplySharedFilter = React.useCallback(() => {
    sendToRCListFilterMachine({ type: "ADD_FILTER", field: "isShared", value: "foo" });
  }, [sendToRCListFilterMachine]);

  const handleClearSharedFilter = React.useCallback(() => {
    sendToRCListFilterMachine({ type: "REMOVE_FILTER", field: "isShared" });
  }, [sendToRCListFilterMachine]);

  if (data.viewer == null) {
    return null;
  }

  const isFiltered = rcListFilterState.matches("filtered");
  const isSorted = rcListSortState.matches("sorted");
  const isSortedByDateOnly =
    isSorted &&
    rcListSortState.context.sortFieldsMap.size === 1 &&
    rcListSortState.context.sortFieldsMap.get("CREATE_DATE") != null;
  const showClearAllFiltersButton = isFiltered || (isSorted && !isSortedByDateOnly);

  return (
    <>
      <NavBar
        user={data.viewer.user}
        viewer={data.viewer}
        router={props.router}
        logout={props.logout}
      />
      <Container
        css={{
          paddingTop: "$4",
          paddingLeft: "$4",
          paddingRight: "$4",
          maxWidth: "1506px",
        }}
      >
        <Card css={{ zIndex: "2" }}>
          <CardHeader>
            <CardHeaderTitle>Rate Cards</CardHeaderTitle>
            <CardHeaderSubTitle>View and manage your rate cards.</CardHeaderSubTitle>
          </CardHeader>
          <CardActions>
            <CardActionsLeft>
              <SearchBox
                value={searchInputState}
                onChange={handleSearchInputChange}
                onSubmit={handleSearchInputSubmit}
                css={{ width: 260 }}
              />
            </CardActionsLeft>
            <CardActionsRight>
              {!editing ? (
                <>
                  <Button icon="edit" color="brand" onClick={() => setEditing(!editing)}>
                    Edit
                  </Button>
                  <Button icon="plus" color="brand">
                    New Rate Card
                  </Button>
                  <RateCardsHelpDialog />
                </>
              ) : (
                <Button color="accent" onClick={() => setEditing(!editing)}>
                  Stop Editing
                </Button>
              )}
            </CardActionsRight>
          </CardActions>
          <CardFilters
            filtered={
              rcListFilterState.matches("filtered") || rcListSortState.matches("sorted")
            }
          >
            {showClearAllFiltersButton && (
              <Button color="accent" onClick={handleClearAllFiltersAndSorts}>
                Clear All Filters &amp; Sorts
              </Button>
            )}
            <FilterByTagDialog
              title="Tags"
              viewer={data.viewer}
              filtered={rcListFilterState.context.filters["tagnameIn"] !== undefined}
              searchTerm={filterByTagState.searchTerm}
              selectedTags={filterByTagState.selectionState.context.selection}
              onChangeSearchTerm={handleTagSearchTermChange}
              onSelectAll={handleSelectAllTags}
              onDeSelectAll={handleDeSelectAllTags}
              onCheckedChange={handleTagCheckedChange}
              onApply={handleApplyFilterByTag}
              onClear={handleClearFilterByTag}
              disableApply={filterByTagState.selectionState.matches("idle")}
            />
            <RateTypeFilterDialog
              filtered={rcListFilterState.context.filters["rateType"] !== undefined}
              onApply={handleApplyRateTypeFilter}
              onClear={handleClearRateTypeFilter}
            />
            <SharedFilterDialog
              filtered={rcListFilterState.context.filters["shared"] !== undefined}
              onApply={handleApplySharedFilter}
              onClear={handleClearSharedFilter}
            />
            <FilterByAuthorDialog
              title="Created By"
              viewer={data.viewer}
              filtered={rcListFilterState.context.filters["ownerId"] !== undefined}
              sorted={
                rcListSortState.context.sortFieldsMap.get("OWNER_FIRST_NAME")
                  ?.direction ?? undefined
              }
              searchTerm={filterByAuthorState.searchTerm}
              sortDirection={filterByAuthorState.sortDirection}
              selectedAuthors={filterByAuthorState.selectionState.context.selection}
              onChangeSortDirection={handleSortByAuthorDirectionChange}
              onChangeSearchTerm={handleAuthorSearchTermChange}
              onSelectAll={handleSelectAllAuthors}
              onDeSelectAll={handleDeSelectAllAuthors}
              onCheckedChange={handleAuthorCheckedChange}
              onApply={handleApplyFilterByAuthor}
              onClear={handleClearFilterByAuthor}
              disableApply={filterByAuthorState.selectionState.matches("idle")}
            />
            <FilterByDateDialog
              title="Created On"
              filtered={
                rcListFilterState.context.filters["fromDate"] !== undefined ||
                rcListFilterState.context.filters["toDate"] !== undefined
              }
              sorted={
                rcListSortState.context.sortFieldsMap.get("CREATE_DATE")?.direction ??
                undefined
              }
              sortDirecton={filterByDateState.sortDirection}
              fromDate={filterByDateState.fromDate}
              toDate={filterByDateState.toDate}
              onChangeSortDirection={handleSortByDateDirectionChange}
              onChangeFromDate={handleFromDateChange}
              onChangeToDate={handleToDateChange}
              onApply={handleApplyFilterByDate}
              onClear={handleClearFilterByDate}
            />
          </CardFilters>
          {!!appliedSearchState && (
            <CardAlert>
              Viewing filtered ratecards containing the term "{appliedSearchState}"
            </CardAlert>
          )}
          <Suspense
            fallback={
              <Box css={{ height: "300px", position: "relative" }}>
                <TickerContentLoader />
              </Box>
            }
          >
            <RateCardsList
              viewer={data.viewer}
              search={appliedSearchState}
              filters={rcListFilterState.context.filters}
              sortFieldsMap={rcListSortState.context.sortFieldsMap}
              editing={!!editing}
            />
          </Suspense>
        </Card>
        <EditActions hidden={editing === null} show={Boolean(editing)}>
          <Button icon="download" text="Export" />
          <Button icon="trash" color="danger" text="Delete" />
        </EditActions>
      </Container>
      <Footer />
    </>
  );
}
RateCardsStitchesQuery.displayName = "RateCardsStitchesQuery";

type RateCardStitchesProps = {
  fetchGraphQL: (query: string, variables: null) => Promise<Response>;
  updateLoginState: (
    loggedIn: boolean,
    loginErrorMessage: string | null,
    sessionInfo: null
  ) => void;
  router: RouterState & InjectedRouter;
};

function RateCardStitches(props: RateCardStitchesProps) {
  // TODO this peace of code duplicates on every major page of each section of the app
  // TODO might b better to make it reusable somewhere on the top level
  function logout() {
    props
      .fetchGraphQL("mutation logout{ logoutUser{ ok } }", null)
      .then((res) => {
        props.updateLoginState(false, null, null);
        props.router.push({
          pathname: "/login",
        });
      })
      .catch((e) => {
        console.error("Error logging user out", e);
        props.updateLoginState(false, null, null);
        props.router.push({
          pathname: "/login",
        });
      });
  }

  return (
    <Suspense fallback={<TickerPageLoader />}>
      <RateCardsStitchesQuery {...props} logout={logout} />
    </Suspense>
  );
}
RateCardStitches.displayName = "RateCardStitches";

export default RateCardStitches;
