// @flow

import React, { Component } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";

import SortIndicator from "../../ratecards/components/SortIndicator";
import { FILTER_COLUMN } from "../../../models/Filter";
import FilterModal, { ModalTitle } from "../../ratecards/components/FilterModal";
import SortControls from "../../ratecards/components/SortControls";
import LoadingIndicator from "../../ratecards/components/LoadingIndicator";
import BatchSearchListItem from "./BatchSearchListItem";
import { BatchSearchJLListComponentStore } from "../../../stores/mobx/BatchSearchListStore";
import ContainerSection from "../../ratecards/components/ContainerSection";
import Button from "../../../components/lib/Button";
import {
  CardAlert,
  CardAlertLink,
  CardFilter,
  CardFilters,
} from "../../../components/lib/Card";
import TextInput from "../../../components/lib/TextInput";
import Spacer from "../../../components/lib/Spacer";
import Pagination from "../../../components/lib/Pagination";

type Props = {
  store: BatchSearchJLListComponentStore,
  fillContainer?: boolean,
  fixedHeight?: number,
};

class BatchSearchList extends Component<Props> {
  changeCurrent = (value: number) => {
    let store: BatchSearchJLListComponentStore = this.props.store;
    store.pagination.handleCurrentPage(value);
  };

  render() {
    const store: BatchSearchJLListComponentStore = this.props.store;
    const network = store.network;
    const fillContainer = this.props.fillContainer;
    const fixedHeight = this.props.fixedHeight;

    let listBodyStyles = {};
    if (fixedHeight) {
      listBodyStyles.height = fixedHeight;
      listBodyStyles.overflowY = "auto";
    }

    let selectAllSection = null;
    let selectAllOnPageSection = (
      <>
        <Button onClick={store.selectAllPage}>Select All</Button>
        <Button onClick={store.deselectAllPage}>Deselect All</Button>
      </>
    );

    if (store.allOnPageSelected) {
      selectAllSection = (
        <CardAlert>
          {!store.allSelected && <span>All Batch Searches on this page selected. </span>}
          &nbsp;
          {!store.allSelected && (
            <CardAlertLink onClick={store.toggleAllItems}>
              Select all <strong>{store.pagination.totalCount}</strong> Batch Searches.{" "}
            </CardAlertLink>
          )}
          &nbsp;
          {store.allSelected && (
            <span>
              All <strong>{store.pagination.totalCount}</strong> Batch Searches selected.{" "}
            </span>
          )}
          &nbsp;
          <CardAlertLink key="clear-selections-link" onClick={store.clearAllSelections}>
            Clear all selections
          </CardAlertLink>
        </CardAlert>
      );
    }

    let tableLeftActionBar = null;
    if (store.isEditing) {
      tableLeftActionBar = (
        <>{store.allowMultipleItemSelection && selectAllOnPageSection}</>
      );
    }

    const batchSearches = store.batchSearchesView;

    let tableContent = null;
    if (network.loading) {
      tableContent = <LoadingIndicator />;
    } else if (network.error) {
      // TODO: replace this with a more friendly error message
      tableContent = (
        <div className="pt-error-message">
          <h2>Error</h2>
          <pre>
            <code>{JSON.stringify(network.error, null, 2)}</code>
          </pre>
        </div>
      );
    } else if (batchSearches.length === 0) {
      tableContent = (
        <div className="ratecards-table-empty">
          {Object.keys(store.appliedFilters).length > 0 ? (
            <div>No Batch Searches match your current filters</div>
          ) : (
            <div>
              No Batch Searches
              <p>Create a new Batch Search using the button above or by searching.</p>
            </div>
          )}
        </div>
      );
    } else {
      tableContent = batchSearches.map((batchSearch) => (
        // $FlowFixMe cast store to BatchSearchJLListStore
        <BatchSearchListItem
          key={batchSearch.id}
          store={store}
          batchSearch={batchSearch}
          editing={store.isEditing}
        />
      ));
    }

    return (
      <div>
        {store.batchSearchRateCardLabelFilter && (
          <FilterModal filterState={store.batchSearchRateCardLabelFilter}>
            <div>
              <ContainerSection className="header overlap-t-padding">
                <ModalTitle className="pull-left">Rate Card Label</ModalTitle>
                <div className="pull-right">
                  <SortControls filterState={store.batchSearchRateCardLabelFilter} />
                </div>
                <div className="clearfix" />
              </ContainerSection>
              <ContainerSection>
                <label>Search for:</label>
                <TextInput
                  fill
                  value={store.batchSearchRateCardLabelFilter.textToLookFor}
                  onChange={(e) =>
                    store.batchSearchRateCardLabelFilter.onTextToLookForChange(
                      e.currentTarget.value
                    )
                  }
                />
              </ContainerSection>
            </div>
          </FilterModal>
        )}

        <CardFilters filtered={store.isFiltered}>
          {tableLeftActionBar}
          <Spacer />
          {store.isFiltered && (
            <Button color="accent" onClick={store.clearFilters}>
              Clear
            </Button>
          )}
          {store.batchSearchRateCardLabelFilter && (
            <CardFilter
              name="rate-cards-filter"
              value={FILTER_COLUMN.BATCH_SEARCH_RATE_CARD_LABEL}
              filtered={Boolean(
                store.appliedFilters[FILTER_COLUMN.BATCH_SEARCH_RATE_CARD_LABEL]
              )}
              onClick={store.batchSearchRateCardLabelFilter.onShowModal}
            >
              <SortIndicator
                sortColumn={FILTER_COLUMN.BATCH_SEARCH_RATE_CARD_LABEL}
                sortsOrder={store.appliedSortsOrder}
                sort={store.appliedSorts[FILTER_COLUMN.BATCH_SEARCH_RATE_CARD_LABEL]}
              />
              Rate Card Label <span> ▼</span>
            </CardFilter>
          )}
        </CardFilters>
        <div
          className={classNames("ratecards-table", {
            "ratecards-table-fill-container": fillContainer,
          })}
        >
          <div style={listBodyStyles}>
            {selectAllSection}
            {tableContent}
          </div>
        </div>
        <Pagination
          options={{
            variant: "full",
            currentPage: store.pagination.currentPage,
            numPages: store.pagination.pageCount,
          }}
          onPageClick={(pageEvent) => {
            if (pageEvent.type === "first") {
              this.changeCurrent(1);
            }
            if (pageEvent.type === "last") {
              this.changeCurrent(store.pagination.pageCount);
            }
            if (pageEvent.type === "next") {
              this.changeCurrent(
                Math.min(store.pagination.currentPage + 1, store.pagination.pageCount)
              );
            }
            if (pageEvent.type === "prev") {
              this.changeCurrent(Math.max(store.pagination.currentPage - 1, 1));
            }
            if (pageEvent.type === "page") {
              this.changeCurrent(pageEvent.page);
            }
          }}
          // pageCount={store.pagination.pageCount}
          // pagesToShow={store.pagination.maxPagesToShow}
          // currentPage={store.pagination.currentPage}
          // begin={store.pagination.begin}
          // end={store.pagination.end}
          // isEndRange={store.pagination.isEndRange}
          // handleCurrentPage={this.changeCurrent}
          // firstPage={store.pagination.firstPage}
        />
      </div>
    );
  }
}

export default observer(BatchSearchList);
