/**
 * @generated SignedSource<<f371d46cf5913d585d41f4b70056cccf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ExportStatus = "FAILED" | "FINISHED" | "PENDING" | "PROCESSING" | "%future added value";
export type ExportModalQuery$variables = {
  exportId: string;
};
export type ExportModalQuery$data = {
  readonly viewer: {
    readonly export: {
      readonly created: string;
      readonly expiresAt: string;
      readonly exportFile: string | null;
      readonly id: string;
      readonly modified: string;
      readonly status: ExportStatus | null;
    } | null;
  } | null;
};
export type ExportModalQuery = {
  response: ExportModalQuery$data;
  variables: ExportModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "exportId"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "exportId"
          }
        ],
        "concreteType": "ExportNode",
        "kind": "LinkedField",
        "name": "export",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "modified",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "expiresAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "exportFile",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExportModalQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExportModalQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e3717f27668627021e4243395564b46f",
    "id": null,
    "metadata": {},
    "name": "ExportModalQuery",
    "operationKind": "query",
    "text": "query ExportModalQuery(\n  $exportId: ID!\n) {\n  viewer {\n    export(id: $exportId) {\n      id\n      status\n      created\n      modified\n      expiresAt\n      exportFile\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "af2628ae025b5071c039892a7bf9fd32";

export default node;
