export {
  DATE_TIME_FORMAT,
  DECIMAL_FORMAT_EDIT,
  decimalFormatter,
  percentFormatter,
  compareDecimals,
  dedupeCSVString,
  convertToCurrency,
  getLocationString,
  getRegionString,
  getFilteredValueTypesDesc,
  getFilteredRatesKeys,
  emptyMap,
} from "../validator5K_admin/constants";

export const baseUrlPath = "/skills-village/validate-rates";
