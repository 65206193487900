import moment from "moment";
import { Set } from "immutable";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { calculateExchangeRate, formatCurrency } from "../utils/currency";

const exchangeRatesEndpoint =
  "https://openexchangerates.org/api/latest.json?base=USD&app_id=ad25fa5a33b1401c8118ca9ace10896d";

function isExchangeRateResponse(data: unknown): data is ExchangeRateResponse {
  if (!data) return false;
  return (
    typeof data === "object" &&
    "base" in data &&
    typeof data.base === "string" &&
    "timestamp" in data &&
    typeof data.timestamp === "number" &&
    "rates" in data &&
    typeof data.rates === "object"
  );
}

export const MarketRateValues = ["Max", "Median", "Average", "Min"] as const;
export type MarketRateValue = (typeof MarketRateValues)[number];

export const MarketRateTypes = ["PayRate", "Markup", "BillRate"] as const;
export type MarketRateType = (typeof MarketRateTypes)[number];

export const FilterRateFrequencies = [
  "Hourly",
  "Daily",
  "Weekly",
  "Monthly",
  "Annually",
] as const;
export type FilterRateFrequency = (typeof FilterRateFrequencies)[number];

export function getRateFreqAbbreviation(rateFrequency: FilterRateFrequency): string {
  const cases: Record<typeof rateFrequency, string> = {
    Hourly: "hr",
    Daily: "day",
    Weekly: "wk",
    Monthly: "mo",
    Annually: "yr",
  };
  return cases[rateFrequency];
}

type ExchangeRateResponse = {
  base: "USD";
  timestamp: number;
  rates: Record<string, number>;
};

export type State = {
  exchangeRates: ExchangeRateResponse | null;
  rateFrequencyMultipliers: Record<FilterRateFrequency, number>;
  rateFrequency: FilterRateFrequency;
  currency: string;
  isLoading: boolean;
  searchIDs: Set<number>;
  searchDetailsUI: {
    view: "Table View" | "Card View";
    tabId: "Pay Rate" | "Bill Rate" | "Markup";
    cardVariant: "marketchanges" | "marketrates";
  };
  comparisonTableUI: {
    value: MarketRateValue;
    type: "Pay Rate" | "Bill Rate" | "Markup";
  };
};

export type Actions = {
  initializeRates(): Promise<void>;
  changeRateFreqMultipliers(key: FilterRateFrequency, value: number): void;
  resetRateFreqMultipliers(): void;
  hasMultipliersChanged(): boolean;
  setRateFrequency(value: FilterRateFrequency): void;
  setCurrency(value: string): void;
  setSearchIDs(searchIDs: Set<number>): void;
  /**
   * Calculates market rates:
   *
   * Multiplies a given hourly rate by the selected frequency multiplayer
   * and exchange rates of the current selected currency
   */
  calculateRate(
    fromCurrency: string,
    toCurrency: string,
    frequency: FilterRateFrequency | "Annually",
    excludeSuffix?: boolean
  ): (rate?: number | null, withFormat?: boolean, withMultiplier?: boolean) => string;
  setSearchDetailsUI(searchDetailsUI: Partial<State["searchDetailsUI"]>): void;
  setComparisonTableUI(comparisonTableUI: Partial<State["comparisonTableUI"]>): void;
  getURLSearchParams(): URLSearchParams;
};

const initialState: State = {
  exchangeRates: null,
  searchDetailsUI: {
    view: "Table View",
    tabId: "Markup",
    cardVariant: "marketrates",
  },
  comparisonTableUI: {
    value: "Min",
    type: "Markup",
  },
  rateFrequencyMultipliers: {
    Hourly: 1,
    Daily: 8,
    Weekly: 40,
    Monthly: 160,
    Annually: 1920,
  },
  rateFrequency: "Hourly",
  currency: "USD",
  isLoading: false,
  searchIDs: Set<number>(),
};

export const useSearchResultsStore = create<State & Actions>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        getURLSearchParams() {
          const state = get();
          const params = {
            rateFrequency: state.rateFrequency,
            currency: state.currency,
            searchDetailsView: state.searchDetailsUI.view,
            searchDetailsTabId: state.searchDetailsUI.tabId,
            searchDetailsCardVariant: state.searchDetailsUI.cardVariant,
            comparisonTableType: state.comparisonTableUI.type,
            comparisonTableValue: state.comparisonTableUI.value,
            dailyMultp: String(state.rateFrequencyMultipliers.Daily),
            weeklyMultp: String(state.rateFrequencyMultipliers.Weekly),
            monthlyMultp: String(state.rateFrequencyMultipliers.Monthly),
            annuallyMultp: String(state.rateFrequencyMultipliers.Annually),
          };
          return new URLSearchParams(params);
        },
        setComparisonTableUI(comparisonTableUI) {
          const state = get().comparisonTableUI;
          const { value = state.value, type = state.type } = comparisonTableUI;
          set({ comparisonTableUI: { value, type } });
        },
        setSearchDetailsUI(ui) {
          const state = get().searchDetailsUI;
          set({ searchDetailsUI: { ...state, ...ui } });
        },
        async initializeRates() {
          const { exchangeRates, isLoading } = get();
          let expired = false;

          if (exchangeRates) {
            const timestamp = moment.unix(exchangeRates.timestamp);
            expired = timestamp.isAfter(moment());
          }

          if (expired || isLoading) return;

          set({ isLoading: true });
          const response = await fetch(exchangeRatesEndpoint);
          const json = await response.json();
          if (isExchangeRateResponse(json)) {
            set({
              exchangeRates: {
                ...json,
                timestamp: moment.unix(json.timestamp).add(4, "weeks").unix(),
              },
            });
          }
          set({ isLoading: false });
        },
        changeRateFreqMultipliers(key, value) {
          const { rateFrequencyMultipliers } = get();
          set({
            rateFrequencyMultipliers: {
              ...rateFrequencyMultipliers,
              [key]: value,
            },
          });
        },
        resetRateFreqMultipliers() {
          set({ rateFrequencyMultipliers: initialState.rateFrequencyMultipliers });
        },
        setSearchIDs(searchIDs) {
          set({ searchIDs });
        },
        hasMultipliersChanged() {
          const { rateFrequencyMultipliers: current } = get();
          const { rateFrequencyMultipliers: initial } = initialState;
          return (
            initial.Hourly !== current.Hourly ||
            initial.Daily !== current.Daily ||
            initial.Weekly !== current.Weekly ||
            initial.Monthly !== current.Monthly
          );
        },
        setRateFrequency(rateFrequency) {
          set({ rateFrequency });
        },
        setCurrency(currency) {
          set({ currency });
        },
        calculateRate(fromCurrency, toCurrency, rateFrequency, excludeSuffix = false) {
          const { exchangeRates: baseExchange } = get();

          const exchangeRate = baseExchange?.rates
            ? calculateExchangeRate(fromCurrency, toCurrency, baseExchange?.rates)
            : 0;

          return (rate, withFormat = true, withMultiplier = true) => {
            const { rateFrequencyMultipliers } = get();
            let calculated: number = rate || 0;
            const multiplier = withMultiplier
              ? rateFrequencyMultipliers[rateFrequency]
              : 1;
            const fractionDigits = rateFrequency === "Hourly" ? 2 : 0;

            // multiplies Hourly rate by selected pay rate and exchange rates
            calculated = calculated * exchangeRate * multiplier;

            let result = calculated.toFixed(fractionDigits);

            if (withFormat)
              result = formatCurrency(calculated, toCurrency, fractionDigits);

            if (!excludeSuffix)
              result = result + "/" + getRateFreqAbbreviation(rateFrequency);

            return result;
          };
        },
      }),
      {
        name: "__Search_Results_Store__",
        version: 1,
        partialize(state) {
          // do not persist: searchIDs
          const { searchIDs, ...partial } = state;
          return partial;
        },
      }
    ),
    { name: "Search_Results_Store" }
  )
);
