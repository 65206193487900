import { atom } from "recoil";

import type { SearchAllocatorClientDataObject } from "./types";

export type SearchAllocatorGlobalState = {
  searchAllocatorData: SearchAllocatorClientDataObject | null;
  searchAllocatorDataLoaded: boolean;
};

export const searchAllocatorGlobalState = atom<SearchAllocatorGlobalState>({
  key: "searchAllocatorGlobalState",
  default: {
    searchAllocatorData: null,
    searchAllocatorDataLoaded: false,
  },
});
