import React from "react";
// lib
import Stack from "../../../../components/lib/Stack";
import Text from "../../../../components/lib/Text";
// relay
import graphql from "babel-plugin-relay/macro";
import { HiringDifficultyCard } from "./HiringDifficultyCard";
import { useLazyLoadQuery } from "react-relay";
import { HiringIntelligenceQuery } from "./__generated__/HiringIntelligenceQuery.graphql";
import Icon from "../../../../components/lib/Icon";
import { styled } from "@stitches/react";
import Tooltip from "../../../../components/lib/Tooltip";
import Grid from "../../../../components/lib/Grid";
import { HiringIntelStatsCard } from "./HiringIntelStatsCard";

const CardHeading = styled("div", {
  display: "flex",
  gap: "$2",
});

const gridCss = {
  gridTemplateRows: "min-content",
  gridGap: 0,
  width: "$full",
  gridTemplateColumns: "1fr",
  "@md": {
    gridTemplateColumns: "repeat(3, 1fr)",
  },
};

type Props = {
  locationId: number;
  jobTitleId: number;
  isCountryLocationSearch: boolean;
};

export function HiringOverview(props: Props) {
  const { locationId, jobTitleId, isCountryLocationSearch } = props;
  const gql = graphql`
    query HiringIntelligenceQuery($locationId: Int!, $terms: String) {
      viewer {
        hiringIntel(locationId: $locationId, terms: $terms) {
          countryId
          avgLengthOfAssignment
          avgCandidateSubmittalTime
          nationalAvgCandidateSubmittalTime
          candidatePool
          nationalCandidatePool
          hiringDifficulty
          difficultyLabel
        }
      }
    }
  `;

  const { viewer } = useLazyLoadQuery<HiringIntelligenceQuery>(gql, {
    locationId: locationId!,
    terms: "taste_title_id_" + jobTitleId?.toString(),
  });

  return (
    <Stack fill css={{ gap: "$4" }}>
      <Stack nogap fill css={{ alignItems: "flex-start" }}>
        <CardHeading>
          <Text noMargin as="h4">
            Hiring Overview
          </Text>
          <Tooltip
            arrowProps={{ offset: 2 }}
            content={
              <Text>
                The data presented on these charts is across all experience levels.
              </Text>
            }
          >
            <Icon icon="info-circle" css={{ marginTop: "$1", marginLeft: "$2" }} />
          </Tooltip>
        </CardHeading>
      </Stack>
      <Grid css={gridCss}>
        <HiringDifficultyCard
          title="Hiring Difficulty"
          hiringDifficulty={viewer?.hiringIntel?.hiringDifficulty ?? 0}
          difficultyLabel={viewer?.hiringIntel?.difficultyLabel ?? "Unknown"}
        />
        <HiringIntelStatsCard
          title="Candidate Pool"
          description="Avg Candidates For Every Job Posting"
          heading="Candidates"
          candidateAvg={viewer?.hiringIntel?.candidatePool ?? 0}
          nationalCandidateAvg={viewer?.hiringIntel?.nationalCandidatePool ?? 0}
          isCountryLocationSearch={isCountryLocationSearch}
        />
        <HiringIntelStatsCard
          title="Average Submittal Time"
          description="Avg Submittal Time for candidates"
          heading="Days"
          candidateAvg={viewer?.hiringIntel?.avgCandidateSubmittalTime ?? 0}
          nationalCandidateAvg={
            viewer?.hiringIntel?.nationalAvgCandidateSubmittalTime ?? 0
          }
          isCountryLocationSearch={isCountryLocationSearch}
        />
      </Grid>
    </Stack>
  );
}
