import { styled } from "../../../stitches.config";
import Inline from "../../../components/lib/Inline";

const SelectItemRow = styled(Inline, {
  cursor: "pointer",
  overflowY: "hidden",
  padding: "$1_5 $3",
  width: "100%",
  flexWrap: "nowrap",
  gap: "$2",

  "&:active": {
    backgroundColor: "$brand",
    color: "$text",
  },

  "&:hover": {
    backgroundColor: "$primaryLighter",
    color: "$text",
  },

  variants: {
    selected: {
      true: {
        backgroundColor: "$accent",
        color: "$white",

        "&:hover": {
          backgroundColor: "$accent",
          color: "$white",
        },
      },
    },

    disabled: {
      true: {
        color: "$primary",
        cursor: "not-allowed",

        "&:hover": {
          backgroundColor: "transparent",
          color: "$primary",
        },
      },
    },
  },

  compoundVariants: [
    {
      selected: true,
      disabled: true,
      css: {
        backgroundColor: "$primaryLight !important",
        color: "$primary !important",
      },
    },
  ],

  defaultVariants: {
    selected: false,
    disabled: false,
  },
});

SelectItemRow.displayName = "SelectRow";

export default SelectItemRow;
