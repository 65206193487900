import React, { useState, useEffect, useCallback } from "react";
import moment from "moment-timezone";

import { styled } from "../../../stitches.config";
// @ts-expect-error
import Messager from "../../../components/Messager";
import { usePLIContext } from "../context";
import Icon from "../../../components/lib/Icon";
import Box from "../../../components/lib/Box";
import Stack from "../../../components/lib/Stack";
import Inline from "../../../components/lib/Inline";
import Grid from "../../../components/lib/Grid";
import LinkButton from "../../../components/lib/LinkButton";
import { ButtonGroupRight } from "../../../components/lib/ButtonGroup";
import Alert from "../../../components/lib/Alert";
import { Button } from "../../../components/lib/Button";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogClose,
  DialogDescription,
  DialogActions,
} from "../lib/Dialog";
import {
  djangoPaginationKey,
  djangoPaginationSizeKey,
  emptyList,
  emptyMap,
} from "../../../constants";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";
import { exportItemToImmutableMap, exportsListToImmutableList } from "../dataConverters";
import { useIntervalDataRefresh, useMessagerRef } from "../hooks";
import { useProgramContext } from "../ProgramDataProvider";
import { useStoredIndexContext } from "../StoredIndexDataProvider";
import { omitObjectKeys } from "../../../utils/object";
import { FiltersQueryOrderedMap } from "../../../components/tables/types";
import { timePassedFormatter } from "../constants";
import {
  EXPORT_TYPES,
  EXPORT_STATUSES,
  ExportsDataList,
  EXPORT_TYPES_TYPE,
} from "../types";

import type { ExportDataMap, ExportDataObject } from "../types";
import type { ImmutableList } from "../../../types/immutable";
import type { FetchAPIResponse } from "../../../types/fetch";
import type { DjangoPaginatedResponse } from "../../../types/django";

const Text = styled("span", {
  variants: {
    light: {
      true: {
        fontWeight: "$light",
      },
    },
    bold: {
      true: {
        fontWeight: "$bold",
      },
    },
    danger: {
      true: {
        color: "$danger",
      },
    },
    gray: {
      true: {
        color: "$primaryDark",
      },
    },
    brand: {
      true: {
        color: "$brandLight",
      },
    },
    underline: {
      true: {
        textDecoration: "underline",
      },
    },
    italic: {
      true: {
        fontStyle: "italic",
      },
    },
  },
});

type ExportDataResponse = {
  data: ExportDataObject;
};

type ExportDataRequestPayload = {
  type: EXPORT_TYPES_TYPE;
  three_levels_banding: boolean;
  dj_filters: { [key: string]: any };
  page?: number;
  page_size?: number;
  stored_index?: number;
  timezone?: string;
};

type MakeExportFunc = (
  exportType: EXPORT_TYPES_TYPE,
  exportCurrentPage: boolean,
  use3LevelsBanding: boolean
) => Promise<ExportDataMap | undefined>;

interface DataExportBlockProps {
  itemsTotal: number;
  itemsPerPage: number;
  onMakeExport: MakeExportFunc;
}

const DataExportBlock = (props: DataExportBlockProps) => {
  const { itemsTotal, itemsPerPage, onMakeExport } = props;
  const isSinglePageExport = itemsTotal <= itemsPerPage;

  return (
    <Stack css={{ alignItems: "flex-start", "& h5": { margin: 0 } }}>
      <Stack css={{ alignItems: "flex-start", gap: "$2" }}>
        <h5>Following options available for Data exporting:</h5>
        {!isSinglePageExport && (
          <LinkButton
            as="a"
            // @ts-ignore
            to={undefined}
            variant="outlined"
            color="brand"
            icon={["far", "file-excel"]}
            text="Export current page only."
            onClick={() => onMakeExport(EXPORT_TYPES.DATA_EXPORT, true, false)}
          />
        )}
        <LinkButton
          as="a"
          // @ts-ignore
          to={undefined}
          variant="outlined"
          color="brand"
          icon={["far", "file-excel"]}
          text="Export whole list."
          onClick={() => onMakeExport(EXPORT_TYPES.DATA_EXPORT, false, false)}
        />
      </Stack>
      <Stack css={{ alignItems: "flex-start", gap: "$2" }}>
        <h5>Following options available for 3 Level Banding Data exporting:</h5>
        {!isSinglePageExport && (
          <LinkButton
            as="a"
            // @ts-ignore
            to={undefined}
            variant="outlined"
            color="brand"
            icon={["far", "file-excel"]}
            text="Export current page only (with 3 Level Banding)."
            onClick={() => onMakeExport(EXPORT_TYPES.DATA_EXPORT, true, true)}
          />
        )}
        <LinkButton
          as="a"
          // @ts-ignore
          to={undefined}
          variant="outlined"
          color="brand"
          icon={["far", "file-excel"]}
          text="Export whole list (with 3 Level Banding)."
          onClick={() => onMakeExport(EXPORT_TYPES.DATA_EXPORT, false, true)}
        />
      </Stack>
    </Stack>
  );
};

DataExportBlock.displayName = "DataExportBlock";

interface AnalysisExportBlockProps {
  itemsTotal: number;
  itemsPerPage: number;
  onMakeExport: MakeExportFunc;
}

const AnalysisExportBlock = (props: AnalysisExportBlockProps) => {
  const { itemsTotal, itemsPerPage, onMakeExport } = props;
  const isSinglePageExport = itemsTotal <= itemsPerPage;

  return (
    <Stack css={{ alignItems: "flex-start", gap: "$2", "& h5": { margin: 0 } }}>
      <h5>Following options available for Analysis File exporting:</h5>
      {!isSinglePageExport && (
        <LinkButton
          as="a"
          // @ts-ignore
          to={undefined}
          variant="outlined"
          color="brand"
          icon={["far", "file-excel"]}
          text="Export current page only."
          onClick={() => onMakeExport(EXPORT_TYPES.ANALYSIS_FILE_EXPORT, true, false)}
        />
      )}
      <LinkButton
        as="a"
        // @ts-ignore
        to={undefined}
        variant="outlined"
        color="brand"
        icon={["far", "file-excel"]}
        text="Export whole list."
        onClick={() => onMakeExport(EXPORT_TYPES.ANALYSIS_FILE_EXPORT, false, false)}
      />
    </Stack>
  );
};

AnalysisExportBlock.displayName = "AnalysisExportBlock";

type LastExportsBlockProps = {
  exportsList: ImmutableList<ExportDataMap>;
  storedIndexId?: number;
};

const LastExportsBlock = (props: LastExportsBlockProps) => {
  const { storedIndexId, exportsList } = props;

  return (
    <Stack fill css={{ alignItems: "flex-start", gap: 0 }}>
      <h5>Last month exports list:</h5>
      {(!exportsList || !exportsList.size) && (
        <Box css={{ paddingLeft: "$2_5" }}>
          <Text light gray>
            No exports in previous month.
          </Text>
        </Box>
      )}
      <Grid
        css={{
          width: "$full",
          gridTemplateColumns: "minmax(0, 50%) minmax(0, 30%) minmax(0, 20%)",
          justifyContent: "center",
          justifyItems: "start",
          marginTop: "$2",
          paddingLeft: "$3",
        }}
      >
        {!!exportsList &&
          !!exportsList.size &&
          exportsList.map((item: ExportDataMap) => {
            const exportId = item.get("id");
            const exportType = item.get("type");
            const fileName = item.get("file_name");
            const fileUrl = item.get("file_url");
            const status = item.get("status");
            const finishedAt = item.get("finished_at");
            const page = item.get("page");
            const pageSize = item.get("page_size");
            const isCurrentPage = page != null && pageSize != null;
            const isThreeLevelsBanding = item.get("three_levels_banding", false);
            const isFailed = status === EXPORT_STATUSES.FAILED;
            const isFinished = status === EXPORT_STATUSES.FINISHED;
            const isInProcessing = !isFailed && !isFinished;

            let fileLine = (
              <Text danger={isFailed} brand={isFinished}>
                <Icon
                  icon={isInProcessing ? "spinner" : ["far", "file-excel"]}
                  className={isInProcessing ? "fa-spin" : undefined}
                />
                &nbsp;
                {fileName}
              </Text>
            );
            let parametersLine = (
              <Text light danger={isFailed}>
                {exportType === EXPORT_TYPES.DATA_EXPORT && isThreeLevelsBanding
                  ? "3 levels"
                  : "5 levels"}
                {isCurrentPage
                  ? ", current page"
                  : `, whole ${storedIndexId != null ? " view" : " index"}`}
                {" export"}
              </Text>
            );
            let statusLine = (
              <Text light danger={isFailed} css={{ justifySelf: "end" }}>
                {status === EXPORT_STATUSES.PENDING
                  ? "queuing..."
                  : status === EXPORT_STATUSES.RUNNING
                  ? "exporting..."
                  : status === EXPORT_STATUSES.FINISHED
                  ? `${timePassedFormatter(finishedAt)} ago`
                  : status === EXPORT_STATUSES.FAILED
                  ? "failed"
                  : ""}
              </Text>
            );

            return (
              <React.Fragment key={exportId}>
                {status === EXPORT_STATUSES.FINISHED ? (
                  <a href={fileUrl} download>
                    {fileLine}
                  </a>
                ) : (
                  fileLine
                )}
                {parametersLine}
                {statusLine}
              </React.Fragment>
            );
          })}
      </Grid>
    </Stack>
  );
};

LastExportsBlock.displayName = "LastExportsBlock";

const hasRunningExports = (data: ExportsDataList): boolean => {
  return (
    data.find(
      (item: ExportDataMap) =>
        item.get("status") === EXPORT_STATUSES.PENDING ||
        item.get("status") === EXPORT_STATUSES.RUNNING
    ) != null
  );
};

type ExportIndexModalProps = {
  show: boolean;
  activePage: number;
  itemsPerPage: number;
  itemsTotal: number;
  filtersQuery: FiltersQueryOrderedMap;
  onClose: () => void;
  refreshInterval: number;
};

const ExportIndexModal = (props: ExportIndexModalProps) => {
  const {
    show,
    itemsTotal,
    itemsPerPage,
    filtersQuery,
    activePage,
    onClose,
    refreshInterval,
  } = props;

  const { fetchM8API, isPTAdmin } = usePLIContext();
  const { programId } = useProgramContext();
  const { indexId: storedIndexId } = useStoredIndexContext();

  // state

  const [exportsList, setExportsList] = useState<ExportsDataList>(
    emptyList as ExportsDataList
  );

  // messager

  const { messagerRef, showError, showSuccess, showHint } = useMessagerRef();

  // handlers

  const handleMakeExport = useCallback(
    async (
      exportType: EXPORT_TYPES_TYPE,
      exportCurrentPage: boolean = false,
      use3LevelsBanding: boolean = false
    ): Promise<ExportDataMap | undefined> => {
      if (!itemsTotal) return;

      const payload: ExportDataRequestPayload = {
        type: exportType,
        three_levels_banding: use3LevelsBanding,
        timezone: moment.tz.guess(),
        dj_filters:
          filtersQuery !== (emptyMap as unknown as FiltersQueryOrderedMap)
            ? omitObjectKeys(filtersQuery.toJS(), [
                djangoPaginationKey,
                djangoPaginationSizeKey,
              ])
            : {},
      };
      if (exportCurrentPage) {
        payload[djangoPaginationKey] = activePage;
        payload[djangoPaginationSizeKey] = itemsPerPage;
      }
      if (storedIndexId != null) {
        payload["stored_index"] = storedIndexId;
      }

      showHint("processing...");

      try {
        const response: ExportDataResponse = await fetchM8API(
          `programs/${programId}/exports/`,
          {
            method: "post",
            data: payload,
          }
        );
        const data: ExportDataMap = exportItemToImmutableMap(response.data);

        setExportsList(exportsList.unshift(data));
        showSuccess("sent for export");

        return data;
      } catch (err: any) {
        logAsyncOperationError("runExport", err);
        showError("Error occurred while exporting, try again later.");
      }
    },
    [
      exportsList,
      storedIndexId,
      programId,
      filtersQuery,
      activePage,
      itemsPerPage,
      itemsTotal,
      fetchM8API,
      showError,
      showHint,
      showSuccess,
    ]
  );

  // utils

  const refreshExportsList = useCallback(async () => {
    const queryArgs: { [key: string]: any } = {
      page: 1,
      page_size: 10,
    };

    if (storedIndexId != null) {
      queryArgs["stored_index__id"] = storedIndexId;
    } else {
      queryArgs["stored_index__isnull"] = true;
    }

    type ExportsListResponse = FetchAPIResponse<
      DjangoPaginatedResponse<ExportDataObject>
    >;

    try {
      const response: ExportsListResponse = await fetchM8API(
        `programs/${programId}/exports/`,
        {
          params: queryArgs,
        }
      );
      const data: ExportsDataList = exportsListToImmutableList(response.data.results);

      setExportsList(data);
    } catch (err: any) {
      logAsyncOperationError("fetchExportsList", err);
    }
  }, [fetchM8API, storedIndexId, programId]);

  // effects

  useIntervalDataRefresh(
    refreshExportsList,
    exportsList,
    hasRunningExports,
    refreshInterval
  );

  useEffect(() => {
    show && refreshExportsList();
  }, [show, refreshExportsList]);

  const hasData = itemsTotal != null && itemsTotal > 0;

  return (
    <Dialog open={show} onOpenChange={onClose}>
      <DialogContent css={{ minWidth: "700px" }}>
        <DialogTitle asChild>
          <Inline css={{ justifyContent: "space-between" }}>
            <Box>
              <h4>Export to Excel.</h4>
            </Box>
            <DialogClose asChild>
              <Icon icon="times" />
            </DialogClose>
          </Inline>
        </DialogTitle>
        <DialogDescription asChild>
          <Stack css={{ fontSize: "$sm", alignItems: "flex-start" }}>
            {!hasData && <h5>No data to export.</h5>}
            {hasData && (
              <Alert color="warning">
                <Text bold underline italic>
                  Please Note:
                </Text>
                &nbsp;
                <span>
                  Exporting the whole list could take awhile depending on the size of the
                  output.
                </span>
              </Alert>
            )}
            {hasData && (
              <DataExportBlock
                itemsTotal={itemsTotal}
                itemsPerPage={itemsPerPage}
                onMakeExport={handleMakeExport}
              />
            )}
            {hasData && isPTAdmin && (
              <AnalysisExportBlock
                itemsTotal={itemsTotal}
                itemsPerPage={itemsPerPage}
                onMakeExport={handleMakeExport}
              />
            )}

            <Box css={{ width: "$full", position: "relative" }}>
              <LastExportsBlock storedIndexId={storedIndexId} exportsList={exportsList} />
              <Box css={{ position: "absolute", top: "$3", right: 0 }}>
                <Messager ref={messagerRef} />
              </Box>
            </Box>
          </Stack>
        </DialogDescription>
        <DialogActions>
          <ButtonGroupRight fill>
            <Button size="large" onClick={onClose}>
              Close
            </Button>
          </ButtonGroupRight>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

ExportIndexModal.displayName = "ExportIndexModal";
ExportIndexModal.defaultProps = {
  filtersQuery: emptyMap,
  activePage: 1,
  itemsTotal: 0,
  itemsPerPage: 0,
  isPTAdmin: false,
  refreshInterval: 2000,
};

export default ExportIndexModal;
