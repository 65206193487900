import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Link } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../../ratecards/components/Modal";
import ContainerSection from "../../ratecards/components/ContainerSection";
import UserList from "../user_management/components/UserList";
import classNames from "classnames";
import CheckBox from "../../shared/CheckBox";

const ClinetDetail = observer((props) => {
  const store = props.store;

  let owner,
    clientId,
    active,
    link,
    activeTxt = null;

  if (store.network.loading) {
    owner = "...";
    clientId = "...";
    activeTxt = "...";
  } else if (store.clientDetail) {
    owner = store.clientDetail.name;
    clientId = store.clientDetail.id;
    active = store.clientDetail.active;
    activeTxt = active ? " Active" : " Inactive";
    link = `${window.location.origin}/signup/${store.clientDetail.encodedClientId}`;

    /*    modifiedDate = store.jobDetail.modified.format('MMMM D, YYYY');
    createdDate = store.jobDetail.created.format('MMMM D, YYYY');
    jobLabel=store.jobDetail.jobLabel;*/
  }

  var actionBar = (
    <nav className="action-bar">
      {!active && (
        <button className="btn btn-green" onClick={(e) => store.showActiveModal(true)}>
          <FontAwesomeIcon icon="flag" fixedWidth className="icon" />
          Activate
        </button>
      )}
      {active && (
        <button className="btn btn-danger" onClick={(e) => store.showActiveModal(false)}>
          <FontAwesomeIcon icon="flag" fixedWidth className="icon" />
          Deactivate
        </button>
      )}
    </nav>
  );

  return (
    <div className="top">
      <div className="above">
        <div className="ratecard-label-header ellipsis max-width-80">
          <h2 className="ellipsis">{owner}</h2>
        </div>
        <nav className="back-bar">
          <Link to={`/admin/clients/`} className="btn btn-green">
            <FontAwesomeIcon icon="arrow-left" fixedWidth className="icon" />
            Go Back
          </Link>
          <button className="btn" onClick={store.showHelp}>
            <FontAwesomeIcon icon="question-circle" fixedWidth className="icon" />
            Help
          </button>
        </nav>
      </div>
      <div className="below">
        <div className="ratecard-meta ellipsis">
          <div className="ratecard-owner ellipsis">
            <FontAwesomeIcon icon="key" fixedWidth className="icon" /> {clientId}
          </div>
          {!active && (
            <div className="ratecard-owner ellipsis">
              <font color="red">
                <FontAwesomeIcon icon="times-circle" fixedWidth className="icon" />{" "}
              </font>
              {activeTxt}
            </div>
          )}
          {active && (
            <div className="ratecard-owner ellipsis">
              <font color="green">
                <FontAwesomeIcon icon="check-circle" fixedWidth className="icon" />{" "}
              </font>{" "}
              {activeTxt}
            </div>
          )}
          <div className="ratecard-owner ellipsis">
            <div>
              <FontAwesomeIcon icon="external-link-alt" fixedWidth className="icon" />{" "}
              Signup Link:
            </div>
          </div>
          {!store.network.loading && (
            <div className="ratecard-owner ellipsis">
              <div style={{ padding: "10px 0" }}>
                <code
                  className="pt-code-block copy-enabled"
                  id="signupLink"
                  onClick={store.copySignupLink}
                  onMouseEnter={store.signupLinkMouseEnter}
                  onMouseLeave={store.signupLinkMouseExit}
                >
                  {link}
                </code>
              </div>
              <small style={{ opacity: store.copyHint ? "1" : "0", animate: "all" }}>
                {store.copyHint || "hidden"}
              </small>
            </div>
          )}
        </div>
      </div>
      <div clientName="below">{actionBar}</div>
      <Modal show={store.showHelpModal} onHide={store.hideHelp}>
        <div className="container-section header">
          <h4>Help</h4>
        </div>
        <div className="container-section footer">
          <p>Here you can see all the details of the Client</p>
        </div>
      </Modal>
      <Modal
        show={store.confirmActivateDeactivateModal.show}
        onHide={store.confirmActivateDeactivateModal.hideModal}
      >
        <div className="container-section header">
          <h4>Confirmation Needed</h4>
        </div>
        <div className="container-section">
          <p>
            Are you sure you want to{" "}
            {store.activeDeactiveBtnFlag ? "Activate" : "Deactivate"} this Client?
          </p>
          {/*<p className="text-danger">
              <FontAwesomeIcon icon="exclamation-triangle"/> {" "}
              <em>This action cannot be undone!</em>
            </p>*/}
        </div>
        <div className="container-section footer">
          <div className="pull-right">
            <button
              className="btn btn-lg"
              onClick={store.confirmActivateDeactivateModal.hideModal}
            >
              No, Cancel
            </button>
            <button
              className="btn btn-lg btn-danger"
              onClick={(e) => {
                store.confirmActivateDeactivateModal.hideModal();
                store.toggleActiveUser(store.activeDeactiveBtnFlag);
              }}
            >
              Yes, {store.activeDeactiveBtnFlag ? "Activate" : "Deactivate"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
});

const ClientStats = observer((props) => {
  const store = props.store;
  let totalSearchesCount,
    totalJobLebelsCount,
    totalRatecardsCount,
    totalProjectsCount,
    totalNegotiationWorksheetsCount,
    totalUserCount,
    totalActiveUserCount,
    totalInactiveUserCount = null;

  if (store.network.loading) {
    totalSearchesCount = "...";
    totalJobLebelsCount = "...";
    totalRatecardsCount = "...";
    totalProjectsCount = "...";
    totalNegotiationWorksheetsCount = "...";
    totalUserCount = "...";
    totalActiveUserCount = "...";
    totalInactiveUserCount = "...";
  } else if (store.clientDetail) {
    totalSearchesCount = store.clientDetail.totalSearchesCount;
    totalJobLebelsCount = store.clientDetail.totalJobLebelsCount;
    totalRatecardsCount = store.clientDetail.totalRatecardsCount;
    totalProjectsCount = store.clientDetail.totalProjectsCount;
    totalNegotiationWorksheetsCount = store.clientDetail.totalNegotiationWorksheetsCount;
    totalUserCount = store.clientDetail.totalUserCount;
    totalActiveUserCount = store.clientDetail.totalActiveUserCount;
    totalInactiveUserCount = store.clientDetail.totalInactiveUserCount;
  }

  return (
    <div className="top">
      <div className="ratecard-list-item-container wstable">
        <div className={classNames("ratecard-list-item")}>
          <div className="ratecard-list-item-left">Statistics</div>
          <div className="ratecard-list-item-right">
            <div className="ratecard-info w-300 text-center">
              <header>Search</header>
              <div>{totalSearchesCount}</div>
            </div>
            <div className="ratecard-info w-300 text-center">
              <header>Jobs</header>
              <div>{totalJobLebelsCount}</div>
            </div>
            <div className="ratecard-info w-300 text-center">
              <header>Ratecard</header>
              <div>{totalRatecardsCount}</div>
            </div>
            <div className="ratecard-info w-300 text-center">
              <header>PCE</header>
              <div>{totalProjectsCount}</div>
            </div>
            <div className="ratecard-info w-300 text-center">
              <header>Worksheet</header>
              <div>{totalNegotiationWorksheetsCount}</div>
            </div>
            <div className="ratecard-info w-300 text-center">
              <header>Users</header>
              <div>{totalUserCount}</div>
            </div>
            <div className="ratecard-info w-300 text-center">
              <header>Active</header>
              <div>{totalActiveUserCount}</div>
            </div>
            <div className="ratecard-info w-300 text-center">
              <header>Inactive</header>
              <div>{totalInactiveUserCount}</div>
            </div>
          </div>
        </div>
      </div>
      {/*<div className="below">
        <div className="ratecard-meta ellipsis">
          <div className="ratecard-owner ellipsis">
            Search: {totalSearchesCount}
          </div>
        </div>
      </div>
      <div className="below">
        <div className="ratecard-meta ellipsis">
          <div className="ratecard-owner ellipsis">
            Jobs: {totalJobLebelsCount}
          </div>
        </div>
      </div>
      <div className="below">
        <div className="ratecard-meta ellipsis">
          <div className="ratecard-owner ellipsis">
            Ratecard: {totalRatecardsCount}
          </div>
        </div>
      </div>
      <div className="below">
        <div className="ratecard-meta ellipsis">
          <div className="ratecard-owner ellipsis">
            PCE: {totalProjectsCount}
          </div>
        </div>
      </div>
      <div className="below">
        <div className="ratecard-meta ellipsis">
          <div className="ratecard-owner ellipsis">
            Worksheet: {totalNegotiationWorksheetsCount}
          </div>
        </div>
      </div>
      <div className="below">
        <div className="ratecard-meta ellipsis">
          <div className="ratecard-owner ellipsis">
            Users: {totalUserCount}
          </div>
        </div>
      </div>
      <div className="below">
        <div className="ratecard-meta ellipsis">
          <div className="ratecard-owner ellipsis">
            Active: {totalActiveUserCount}
          </div>
        </div>
      </div>
      <div className="below">
        <div className="ratecard-meta ellipsis">
          <div className="ratecard-owner ellipsis">
            Inactive: {totalInactiveUserCount}
          </div>
        </div>
      </div>*/}
    </div>
  );
});

/**
 * JobDetail component for job_labels detail page
 */
class Detail extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  /**
   * componentDidMount
   * @return {void}
   */
  UNSAFE_componentWillMount() {
    this.props.store.clientDetailPTStore.clientId = this.props.router.params.clientId;
    this.props.store.userManagementListPTStore.clientId =
      this.props.router.params.clientId;
    this.props.store.clientDetailPTStore.router = this.context.router;
    this.props.store.clientDetailPTStore.getClientDetails();
  }

  componentWillUnmount() {
    this.props.store.clientDetailPTStore.isUpdateEditing = false;
  }

  /**
   * Render rate card detail page
   * @return {XML}
   */
  render() {
    const store = this.props.store.clientDetailPTStore;
    const liststore = this.props.store.userManagementListPTStore;

    return (
      <div className="view clients">
        <div className="rc-container show-overflow">
          <ClinetDetail store={store} router={this.props.router} />
        </div>
        {/*<div className="header-action-bar">
          <h2 className="ellipsis">Statistics</h2>
        </div>*/}

        {/*        <div className="header-action-bar">
          <h2 className="ellipsis">Settings</h2>
        </div>*/}
        <div className="rc-container show-overflow">
          <div className="header-action-bar">
            <h4>Client Detail</h4>
            <div className="btn-group pull-right">
              {store.isUpdateEditing && (
                <button className="btn btn-yellow" onClick={store.stopEditing}>
                  Stop Editing{" "}
                </button>
              )}
              {!store.isUpdateEditing && (
                <button
                  className="btn btn-green"
                  onClick={(e) => {
                    store.startEditing();
                  }}
                >
                  <span>
                    <FontAwesomeIcon icon="edit" fixedWidth className="icon" />
                    Edit
                  </span>
                </button>
              )}
            </div>
            <div className="clearfix" />
          </div>
          <div className="row userjob">
            <div className="col-sm-6 col-md-6 col-lg-6">
              <div className="form-group">
                <label>Name</label>
                <div className="input_group">
                  <input
                    type="text"
                    min="0"
                    disabled={!store.isUpdateEditing}
                    className="form-control"
                    value={store.clientName}
                    onChange={store.setClientName}
                  />
                  {store.messaging.messages.length > 0 && (
                    <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                      <a
                        href="#close"
                        className="close"
                        data-dismiss="alert"
                        aria-label="close"
                        onClick={store.messaging.removeMessage.bind(
                          store,
                          store.messaging.messages[0]
                        )}
                      >
                        &times;
                      </a>
                      <strong>{store.messaging.messages[0].messageLabel}</strong>
                      {store.messaging.messages[0].message}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group">
            <label>Search Limit</label>
            <div className="input_group">
              <input type="number" min="0" disabled={!store.isUpdateEditing} className="form-control"  value={store.searchLimit} onChange={store.setSearchLimit}/>
            </div>
          </div>
        </div>*/}
          </div>
          <div className="row">
            {/*<div className="col-sm-4 col-md-4 col-lg-4">
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <label>Limited Searches</label>
                </div>
              </div>
              <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <CheckBox type="radio"
                    name="list-item-select"
                    value="true"
                    selected={store.limitedSearchFlag}
                    onClick={store.setLimitedSearchFlag}
                    labelName={'Yes'}
                    disabled={!store.isUpdateEditing}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <CheckBox type="radio"
                    name="list-item-select"
                    value="false"
                    selected={!store.limitedSearchFlag}
                    onClick={store.setLimitedSearchFlag}
                    labelName={'No'}
                    disabled={!store.isUpdateEditing}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>*/}
            <div className="col-sm-4 col-md-4 col-lg-4">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <label>Share Rate Card Flag</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <CheckBox
                          type="radio"
                          name="list-item-select"
                          value="true"
                          selected={store.shareRatecardsFlag}
                          onClick={store.setShareRatecardsFlag}
                          labelName={"Yes"}
                          disabled={!store.isUpdateEditing}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <CheckBox
                          type="radio"
                          name="list-item-select"
                          value="false"
                          selected={!store.shareRatecardsFlag}
                          onClick={store.setShareRatecardsFlag}
                          labelName={"No"}
                          disabled={!store.isUpdateEditing}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <label>Per Search Pricing</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <CheckBox
                          type="radio"
                          name="list-item-select"
                          value="true"
                          selected={store.perSearchPricingFlag}
                          onClick={store.setSearchPricingFlag}
                          labelName={"Yes"}
                          disabled={!store.isUpdateEditing}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <CheckBox
                          type="radio"
                          name="list-item-select"
                          value="false"
                          selected={!store.perSearchPricingFlag}
                          onClick={store.setSearchPricingFlag}
                          labelName={"No"}
                          disabled={!store.isUpdateEditing}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <label>Client Library</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <CheckBox
                          type="radio"
                          name="list-item-select"
                          value="true"
                          selected={store.isClientLibrary}
                          onClick={store.setClientLibraryFlag}
                          labelName={"Yes"}
                          disabled={!store.isUpdateEditing}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <CheckBox
                          type="radio"
                          name="list-item-select"
                          value="false"
                          selected={!store.isClientLibrary}
                          onClick={store.setClientLibraryFlag}
                          labelName={"No"}
                          disabled={!store.isUpdateEditing}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4 col-md-4 col-lg-4">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <label>Has Numeric Levels On Export</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <CheckBox
                          type="radio"
                          name="list-item-select"
                          value="true"
                          selected={store.hasNumericLevelsOnExport}
                          onClick={store.setHasNumericLevelsOnExport}
                          labelName={"Yes"}
                          disabled={!store.isUpdateEditing}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <CheckBox
                          type="radio"
                          name="list-item-select"
                          value="false"
                          selected={!store.hasNumericLevelsOnExport}
                          onClick={store.setHasNumericLevelsOnExport}
                          labelName={"No"}
                          disabled={!store.isUpdateEditing}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <label>Terms of Agreement (EULA)</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <CheckBox
                          type="radio"
                          name="list-item-select"
                          value="true"
                          selected={store.termsOfAgreement}
                          onClick={store.setTermsOfAgreement}
                          labelName={"Yes"}
                          disabled={!store.isUpdateEditing}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <CheckBox
                          type="radio"
                          name="list-item-select"
                          value="false"
                          selected={!store.termsOfAgreement}
                          onClick={store.setTermsOfAgreement}
                          labelName={"No"}
                          disabled={!store.isUpdateEditing}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-4 col-md-4 col-lg-4">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <label>Refresh Eligible</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <CheckBox
                          type="radio"
                          name="list-item-select"
                          value="true"
                          selected={store.isRefreshEligible}
                          onClick={store.setRefreshEligible}
                          labelName={"Yes"}
                          disabled={!store.isUpdateEditing}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <CheckBox
                          type="radio"
                          name="list-item-select"
                          value="false"
                          selected={!store.isRefreshEligible}
                          onClick={store.setRefreshEligible}
                          labelName={"No"}
                          disabled={!store.isUpdateEditing}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {store.isUpdateEditing && (
            <ContainerSection className="footer">
              <div className="pull-right">
                <button
                  className="btn btn-lg btn-default"
                  onClick={(e) => {
                    store.stopEditing();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-lg btn-green"
                  onClick={(e) => {
                    store.saveEditedClientDetail();
                  }}
                >
                  Update Client Detail
                </button>
              </div>
            </ContainerSection>
          )}
        </div>
        <div className="rc-container show-overflow">
          <ClientStats store={store} router={this.props.router} />
        </div>
        {/*<div className="header-action-bar">
          <h2 className="ellipsis">Users</h2>
        </div>*/}
        <div className="rc-container bring-forward">
          <h2 className="ellipsis">Users</h2>
          <div className="header-action-bar">
            {/*<div className="pull-left">
              <SearchBox value={liststore.userFilter.textToLookFor} onChange={liststore.userFilter.onTextToLookForChange} onSearch={liststore.userFilter.onApply} style={{
                  width: "260px"
                }}/>
            </div>*/}
            {/*(!liststore.network.loading && !liststore.network.error) && <div className="btn-group pull-right">
              {liststore.isEditing && <button className="btn btn-yellow" onClick={liststore.handleStopEdit}>
                Stop Editing
              </button>}
              {!liststore.isEditing && <button className="btn btn-green" onClick={liststore.handleStartEdit}>
                <FontAwesomeIcon icon="edit" fixedWidth className="icon"/>
                Edit
              </button>}
            </div>*/}
            <div className="clearfix" />
          </div>

          {!liststore.network.error && (
            <div>
              <UserList store={liststore} />
            </div>
          )}

          {liststore.network.error && (
            <div className="pt-error-message fade-in">
              <h2>Error</h2>
              <pre>
                <code>{JSON.stringify(liststore.network.error, null, 2)}</code>
              </pre>
            </div>
          )}
        </div>

        <div
          className={classNames("search-edit-actions", {
            hidden: liststore.isEditing === null,
            "bottom-appear": liststore.isEditing === true,
            "bottom-disappear": liststore.isEditing === false,
          })}
        >
          {liststore.isEditing && (
            <button className="btn btn-danger" disabled={liststore.selectedCount === 0}>
              <FontAwesomeIcon icon="trash-alt" fixedWidth className="icon" />
              Export
            </button>
          )}
        </div>

        <div
          className={classNames("rc-container-backdrop", {
            hidden: liststore.isEditing === null,
            "fade-in": liststore.isEditing === true,
            "fade-out": liststore.isEditing === false,
          })}
        />
      </div>
    );
  }
}

export default observer(Detail);
