export default class Region {
  constructor(store, object) {
    this.id = "";
    this.name = "";

    if (object) {
      this.id = object.legacyId || null;
      this.name = object.value || null;
    }
  }

  toJS() {
    return {
      id: this.id,
      name: this.name,
    };
  }
}
