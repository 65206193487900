import React, { useEffect, useState } from "react";
import { fetchQuery, useMutation, useRelayEnvironment } from "react-relay";
// @ts-ignore
import graphql from "babel-plugin-relay/macro";
import Button from "../../../../components/lib/Button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "../../../../components/lib/Dialog";
import Inline from "../../../../components/lib/Inline";
import {
  RadioGroup,
  RadioGroupIndicator,
  RadioGroupItem,
  RadioGroupRadio,
} from "../../../../components/lib/RadioGroup";
import Stack from "../../../../components/lib/Stack";
import { RsAddToRateCardDialogRateCardListQuery } from "./__generated__/RsAddToRateCardDialogRateCardListQuery.graphql";
import TextInput from "../../../../components/lib/TextInput";
import { RsAddToRateCardDialogRateCardMutation } from "./__generated__/RsAddToRateCardDialogRateCardMutation.graphql";
import Text from "../../../../components/lib/Text";
import Tag from "../../../../components/lib/Tag";
import moment from "moment";
import Pagination, {
  PageEvent,
  PageEventTypeEnum,
} from "../../../../components/lib/Pagination";
import { styled } from "../../../../stitches.config";
import { ExtractRelayEdgeNode } from "../../../../components/NavBar2";
import { sanitizeInput } from "../../../../utils/dom";

type RateCard = ExtractRelayEdgeNode<
  NonNullable<
    RsAddToRateCardDialogRateCardListQuery["response"]["viewer"]
  >["allRateCards"]
>;

// TODO: move to the rate search results persisted store
export const LAST_RATE_CARD_SESSION_VAIABLE = "lastRateCard";
// TODO: move to the rate search results persisted store
function getLastRateCard(): RateCard {
  let ratecard: any = window.localStorage.getItem(LAST_RATE_CARD_SESSION_VAIABLE);
  if (ratecard) {
    ratecard = JSON.parse(ratecard);
  } else {
    ratecard = {};
  }
  return ratecard;
}
// TODO: move to the rate search results persisted store
function setLastRateCard(rateCard: RateCard) {
  window.localStorage.setItem(LAST_RATE_CARD_SESSION_VAIABLE, JSON.stringify(rateCard));
}

interface RsAddToRateCardDialogProps {
  searchIds: number[];
  currentUserId: number;
  children: any;
  onSaveChanges?(): void;
  onRateCardAdded: (rateCard: RateCard) => void;
}

enum AddType {
  existing,
  new,
  same,
}

const SelectableRow = styled("label", {
  height: "40px",
  cursor: "pointer",
  ":hover": {
    backgroundColor: "$brandLightest",
  },
});

function RsAddToRateCardDialog(props: RsAddToRateCardDialogProps) {
  const { children } = props;
  const relayEnvironment = useRelayEnvironment();

  const [addType, setAddType] = useState(AddType.existing);
  const [inputSearchText, setInputSearchText] = useState("");
  const [searchText, setSearchText] = useState("");
  const [rateCards, setRateCards] = useState<RateCard[]>([]);
  const [selectedRateCardId, setSelectedRateCardId] = useState(0);
  const [newRateCardName, setNewRateCardName] = useState("");

  const lastRateCard = getLastRateCard();

  const [currentPageState, setCurrentPageState] = useState(1);
  const [totalPageNum, setTotalPageNum] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    const offset = currentPageState;
    fetchQuery<RsAddToRateCardDialogRateCardListQuery>(
      relayEnvironment,
      RATE_CARD_LIST_QUERY,
      { offset: offset, first: itemsPerPage, nameFilterText: searchText }
    ).subscribe({
      next: (response) => {
        const rateCards = response?.viewer?.allRateCards?.edges
          .filter((edge) => edge?.node)
          .map((edge) => edge?.node) as NonNullable<RateCard>[];

        if (rateCards) setRateCards(rateCards);

        const totalCount = response?.viewer?.allRateCards?.totalCount;
        if (totalCount) setTotalPageNum(Math.ceil(totalCount / itemsPerPage));
      },
      error: () => {},
    });
  }, [searchText, currentPageState, relayEnvironment]);

  const [rateCardCommit] = useMutation<RsAddToRateCardDialogRateCardMutation>(
    SAVE_SEARCH_TO_RATE_CARD_MUTATION
  );

  function onAddTypeChange(addType: AddType) {
    setAddType(addType);
  }

  function onSearchInputChange(searchText: string) {
    setInputSearchText(searchText);
  }

  function onSearchClick() {
    setSearchText(inputSearchText);
    setCurrentPageState(1);
  }

  function clearSearchClick() {
    setInputSearchText("");
    setSearchText("");
    setCurrentPageState(1);
  }

  function onRateCardChange(ratecardId: number) {
    setSelectedRateCardId(ratecardId);
  }

  function onNewRateCardNameChange(name: string) {
    setNewRateCardName(sanitizeInput(name));
  }

  function handlePageClick(pageEvent: PageEvent) {
    let currentPage = currentPageState;
    const newPage =
      pageEvent.type === PageEventTypeEnum.FIRST
        ? 1
        : pageEvent.type === PageEventTypeEnum.PREV
        ? Math.max(currentPage - 1, 1)
        : pageEvent.type === PageEventTypeEnum.NEXT
        ? Math.min(currentPage + 1, totalPageNum)
        : pageEvent.type === PageEventTypeEnum.LAST
        ? totalPageNum
        : pageEvent.type === PageEventTypeEnum.PAGE
        ? pageEvent.page
        : 1;

    setCurrentPageState(newPage);
  }

  function onSaveChanges() {
    if (props.onSaveChanges) props.onSaveChanges();

    let variables;
    if (addType === AddType.existing) {
      if (!selectedRateCardId) return;
      variables = { existingRateCardId: selectedRateCardId, only: props.searchIds };
    } else if (addType === AddType.new) {
      if (!newRateCardName) return;
      variables = { newRateCardName: newRateCardName, only: props.searchIds };
    } else if (addType === AddType.same) {
      if (!lastRateCard?.ratecardId) return;
      variables = { existingRateCardId: lastRateCard.ratecardId, only: props.searchIds };
    }

    rateCardCommit({
      variables: variables,
      onCompleted(response) {
        const rateCard = response?.saveSearchesToRateCard?.rateCard;
        if (rateCard) {
          setLastRateCard(rateCard);
          props.onRateCardAdded(rateCard);
        }
      },
      onError(error) {
        console.error("Error on rateCardCommit:", error);
      },
    });
  }

  function onOpenChangeHandler(isOpen: boolean) {
    if (isOpen) {
      if (lastRateCard?.ratecardId) {
        setAddType(AddType.same);
      }
    }
  }

  return (
    <Dialog onOpenChange={onOpenChangeHandler}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent css={{ width: "1000px", maxWidth: "1000px", padding: "25px" }}>
        <Stack fill css={{ alignItems: "flex-start", gap: "25px" }}>
          <Stack css={{ alignItems: "flex-start", gap: "0px" }}>
            <Text as="h3" noMargin>
              Add to Rate Card
            </Text>
            <Text>Choose Rate Card to add searches to</Text>
          </Stack>

          <RadioGroup value={addType} onValueChange={onAddTypeChange}>
            <Inline>
              <RadioGroupItem id="at1" value={AddType.existing}>
                Use Existing Rate Card
              </RadioGroupItem>
              <RadioGroupItem id="at2" value={AddType.new}>
                Create New Rate Card
              </RadioGroupItem>
              {lastRateCard && lastRateCard.ratecardId && (
                <RadioGroupItem id="at3" value={AddType.same}>
                  Same Rate Card I Used Last Time
                </RadioGroupItem>
              )}
            </Inline>
          </RadioGroup>

          {addType === AddType.existing && (
            <>
              <Inline>
                <TextInput
                  value={inputSearchText}
                  onChange={(e) => onSearchInputChange(e.target.value)}
                  onKeyDown={(e) => (e.key === "Enter" ? onSearchClick() : null)}
                  placeholder="Search Rate Cards"
                  iconRight="search"
                  iconRightClicked={() => onSearchClick()}
                  css={{ width: "370px" }}
                />
                {searchText && (
                  <Button
                    onClick={() => clearSearchClick()}
                    variant="filled"
                    color="brand"
                    size={"normal"}
                  >
                    Clear Search
                  </Button>
                )}
              </Inline>

              <Stack fill css={{ alignItems: "flex-start", gap: "5px" }}>
                <Inline fill css={{ gap: "0px" }}>
                  <div style={{ flex: "6%" }}></div>
                  <div style={{ flex: "40%" }}>Rate Card Name</div>
                  <div style={{ flex: "18%" }}></div>
                  <div style={{ flex: "18%" }}>Created by</div>
                  <div style={{ flex: "18%" }}>Created</div>
                </Inline>

                <RadioGroup onValueChange={onRateCardChange} style={{ width: "100%" }}>
                  {rateCards?.length > 0 &&
                    rateCards.map((rateCard) => {
                      const sharedByYou = rateCard.owner?.userId === props.currentUserId;
                      return (
                        <SelectableRow
                          htmlFor={String(rateCard.ratecardId)}
                          style={{ width: "100%" }}
                          key={rateCard.ratecardId}
                        >
                          <Inline
                            fill
                            css={{
                              alignItems: "center",
                              gap: "0px",
                              height: "100%",
                              padding: "0px 15px",
                            }}
                          >
                            <div style={{ flex: "6%" }}>
                              <RadioGroupRadio
                                value={rateCard.ratecardId}
                                id={String(rateCard.ratecardId)}
                              >
                                <RadioGroupIndicator />
                              </RadioGroupRadio>
                            </div>
                            <div style={{ flex: "40%" }}>{rateCard.name}</div>
                            <div style={{ flex: "18%" }}>
                              {rateCard.shared && sharedByYou && (
                                <Tag size={"extra-small"}>Shared By You</Tag>
                              )}
                              {rateCard.shared && !sharedByYou && (
                                <Tag size={"extra-small"}>Shared With You</Tag>
                              )}
                            </div>
                            <div style={{ flex: "18%" }}>
                              {rateCard.owner.firstName + " " + rateCard.owner.lastName}
                            </div>
                            <div style={{ flex: "18%" }}>
                              {moment(rateCard.createdDate).format("MM/DD/YYYY")}
                            </div>
                          </Inline>
                        </SelectableRow>
                      );
                    })}
                </RadioGroup>

                <Inline fill css={{ justifyContent: "center" }}>
                  <Pagination
                    options={{
                      variant: "full",
                      numPages: totalPageNum,
                      currentPage: currentPageState,
                    }}
                    onPageClick={(e) => handlePageClick(e)}
                  />
                </Inline>
              </Stack>
            </>
          )}

          {addType === AddType.new && (
            <div>
              <TextInput
                onChange={(e) => onNewRateCardNameChange(e.target.value)}
                placeholder="Enter Rate Card name..."
                css={{ width: "370px" }}
              />
            </div>
          )}

          {addType === AddType.same && (
            <Stack fill css={{ alignItems: "flex-start", gap: "5px" }}>
              <Inline fill css={{ gap: "0px" }}>
                <div style={{ flex: "6%" }}></div>
                <div style={{ flex: "40%" }}>Rate Card Name</div>
                <div style={{ flex: "18%" }}></div>
                <div style={{ flex: "18%" }}>Created by</div>
                <div style={{ flex: "18%" }}>Created</div>
              </Inline>

              <Inline fill css={{ gap: "0px" }}>
                <div style={{ flex: "6%" }}></div>
                <div style={{ flex: "40%" }}>{lastRateCard.name}</div>
                <div style={{ flex: "18%" }}>
                  {!lastRateCard.shared && <Tag size={"extra-small"}>Shared By You</Tag>}
                  {lastRateCard.shared && <Tag size={"extra-small"}>Shared With You</Tag>}
                </div>
                <div style={{ flex: "18%" }}>
                  {lastRateCard.owner.firstName + " " + lastRateCard.owner.lastName}
                </div>
                <div style={{ flex: "18%" }}>
                  {moment(lastRateCard.createdDate).format("MM/DD/YYYY")}
                </div>
              </Inline>
            </Stack>
          )}

          <Inline fill css={{ justifyContent: "right", padding: "20px" }}>
            <DialogClose asChild>
              <Button variant="outlined" color="brand" size={"large"}>
                Cancel
              </Button>
            </DialogClose>
            <DialogClose asChild>
              <Button
                onClick={() => onSaveChanges()}
                variant="filled"
                color="brand"
                size={"large"}
              >
                Submit
              </Button>
            </DialogClose>
          </Inline>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default RsAddToRateCardDialog;

const RATE_CARD_LIST_QUERY = graphql`
  query RsAddToRateCardDialogRateCardListQuery(
    $offset: Int!
    $first: Int!
    $nameFilterText: String!
  ) {
    viewer {
      allRateCards(
        offset: $offset
        first: $first
        filters: { nameIContains: $nameFilterText }
        order: [{ field: CREATE_DATE, direction: DESC }]
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        totalCount
        edges {
          node {
            id
            ratecardId
            shared
            tags {
              name
              tagId
            }
            owner {
              firstName
              lastName
              email
              userId
              termsOfAgreement
            }
            name
            createdDate
            updateDate
            searchCount
            locationCount
          }
        }
      }
    }
  }
`;

const SAVE_SEARCH_TO_RATE_CARD_MUTATION = graphql`
  mutation RsAddToRateCardDialogRateCardMutation(
    $existingRateCardId: String
    $newRateCardName: String
    $only: [String]!
  ) {
    saveSearchesToRateCard(
      input: {
        only: $only
        filters: {}
        existingRatecard: $existingRateCardId
        newRatecard: $newRateCardName
      }
    ) {
      errors {
        __typename
      }
      rateCard {
        ratecardId
        id
        shared
        tags {
          name
          tagId
        }
        owner {
          firstName
          lastName
          email
          userId
          termsOfAgreement
        }
        name
        createdDate
        updateDate
        searchCount
        locationCount
      }
    }
  }
`;
