import React, { useEffect, useCallback, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { styled } from "../../../stitches.config";

import Pagination from "../../../components/lib/Pagination";
import type { PageEvent } from "../../../components/lib/Pagination";
import Stack from "../../../components/lib/Stack";
import Box from "../../../components/lib/Box";
import Divider from "../../../components/lib/Divider";
import Inline from "../../../components/lib/Inline";
import Alert from "../../../components/lib/Alert";
import SaveIndexForm from "../components/SaveIndexForm";
import { AutoSearchBox, SearchBoxProps } from "../../../components/lib/SearchBox";
import Icon from "../../../components/lib/Icon";
import { ButtonGroup, ButtonGroupRight } from "../../../components/lib/ButtonGroup";
import Text from "../../../components/lib/Text";
import { getChangingPageNumber } from "../../../components/tables/utils";

import {
  djangoSearchKey,
  djangoPaginationKey,
  djangoPaginationSizeKey,
  djangoOrderingKey,
} from "../../../constants";
import { emptyMap } from "../../../constants";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";

import PromiseButton from "../../../components/lib/PromiseButton";
import { Button, NavigationButton, IconButton } from "../../../components/lib/Button";
import { TickerPageLoader } from "../../../components/lib/TickerLoader";
import {
  Card,
  CardBody,
  CardHeaderTitle,
  CardHeaderSubTitle,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
} from "../../../components/lib/Card";

import {
  StoredIndexesListGlobalState,
  storedIndexesListGlobalState,
} from "../globalState";
import {
  storedIndexesListToImmutableOrderedMap,
  storedIndexDuplicateToImmutableMap,
} from "../dataConverters";
import { useProgramContext } from "../ProgramDataProvider";
import { useScrollTo, useMessagerRef } from "../hooks";
import { useModalState } from "../../../utils/hooks";
import { dateTimeFormatter } from "../constants";
import {
  PROGRAM_STATUSES,
  PROGRAM_STATUSES_LABELS,
  StoredIndexesDataOrderedMap,
} from "../types";

import type {
  ProgramDataMap,
  StoredIndexDataMap,
  StoredIndexTrendDataMap,
  StoredIndexDataObject,
} from "../types";
import type { CommonProgramChildPageProps } from "../ProgramDataProvider";
import type { UrlQueryObject } from "../../../components/tables/types";
import type { FetchAPIResponse } from "../../../types/fetch";
import type { DjangoPaginatedResponse } from "../../../types/django";
import { usePLIContext } from "../context";
import type { StoredIndexDataCreateView } from "../types";

const InlineNoWrap = styled(Inline, {
  flexWrap: "nowrap",
  alignItems: "start",
});

InlineNoWrap.displayName = "InlineNoWrap";

const ProgramIcon = styled("img", {
  display: "inline-block",
  verticalAlign: "middle",
  marginRight: "$4",
  border: "1px solid $primaryLight",
  borderRadius: "$sm",
  maxWidth: "130px",
  height: "auto",
  maxHeight: "35px",
});

ProgramIcon.displayName = "ProgramIcon";

type NoProgramFoundProps = {
  onBackToProgramsList: () => void;
};

const NoProgramFound = (props: NoProgramFoundProps) => {
  const { onBackToProgramsList } = props;

  return (
    <Alert color="warning">
      <Stack fill css={{ alignItems: "start" }}>
        <h4>Index not found</h4>
        <NavigationButton
          icon="arrow-left"
          color="warning"
          onClick={onBackToProgramsList}
        >
          Back to Indexes List
        </NavigationButton>
      </Stack>
    </Alert>
  );
};

NoProgramFound.displayName = "NoProgramFound";

type PageHeadBlockProps = {
  programData: ProgramDataMap;
  onGoBackToProgramsList: () => void;
};

const PageHeadBlock = (props: PageHeadBlockProps) => {
  const { onGoBackToProgramsList, programData } = props;

  const { isPTAdmin, router } = usePLIContext();
  const { isPreviewMode } = useProgramContext();

  const programTitle = programData.get("title");
  const programDescription = programData.get("description");
  const programIconUrl = programData.get("icon");
  const programStatus = programData.get("status");
  const programId = programData.get("id");

  // handlers
  const handleGoToDiscoveryDashboard = useCallback(() => {
    router.push(`/private-index/programs/${programId}/discovery-dashboard`);
  }, [programId, router]);

  const handleGoToUploadContractors = useCallback(() => {
    router.push(`/private-index/programs/${programId}/contractors/upload`);
  }, [programId, router]);

  const handleGoToCreateIndex = useCallback(() => {
    router.push(`/private-index/programs/${programId}/indexes/create`);
  }, [programId, router]);

  const isActiveLabel = PROGRAM_STATUSES_LABELS[programStatus].toLowerCase();
  const header = (
    <CardHeaderTitle>
      {programIconUrl && <ProgramIcon alt="icon" src={programIconUrl} />}
      {programTitle}
      {isPTAdmin && (
        <Text
          as="small"
          css={{
            color:
              programStatus === PROGRAM_STATUSES.ACTIVE
                ? "$success"
                : programStatus === PROGRAM_STATUSES.INACTIVE
                ? "$danger"
                : "$primary",
          }}
        >
          &nbsp;({isActiveLabel})
        </Text>
      )}
    </CardHeaderTitle>
  );

  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft>{header}</CardActionsLeft>
        <CardActionsRight>
          <NavigationButton icon="arrow-left" onClick={onGoBackToProgramsList}>
            Back To Indexes List
          </NavigationButton>
        </CardActionsRight>
      </CardActions>

      <CardBody>
        <CardHeaderTitle as="h4">Description:</CardHeaderTitle>
        <CardHeaderSubTitle css={{ paddingLeft: "$4" }}>
          {programDescription}
        </CardHeaderSubTitle>
        <InlineNoWrap
          css={{
            marginTop: "$6",
            flexDirection: "column",
            justifyContent: "space-between",
            "@lg": {
              flexDirection: "row",
            },
          }}
        >
          {!isPreviewMode && (
            <InlineNoWrap>
              <NavigationButton
                css={{ whiteSpace: "nowrap" }}
                icon="plus"
                onClick={handleGoToCreateIndex}
              >
                Create View
              </NavigationButton>
              <h5>
                Create a new view.
                <br />
                This will be a data cut from the overall data index.
              </h5>
            </InlineNoWrap>
          )}
          {!isPreviewMode && isPTAdmin && (
            <InlineNoWrap>
              <NavigationButton
                css={{ whiteSpace: "nowrap" }}
                icon="upload"
                onClick={handleGoToUploadContractors}
              >
                Upload Data
              </NavigationButton>
              <h5>
                Compare your data to the market.
                <br />
                Data uploaded will be available in the “All Data” view.
              </h5>
            </InlineNoWrap>
          )}
          {isPTAdmin && (
            <InlineNoWrap>
              <NavigationButton
                css={{ whiteSpace: "nowrap" }}
                icon="search"
                onClick={handleGoToDiscoveryDashboard}
              >
                Discovery Dashboard
              </NavigationButton>
              <h5>
                Discover your data in the best way.
                <br />
              </h5>
            </InlineNoWrap>
          )}
        </InlineNoWrap>
      </CardBody>
    </Card>
  );
};

PageHeadBlock.displayName = "PageHeadBlock";

type StoredIndexesListDataProvider = (
  queryArgs: UrlQueryObject,
  extraStoredParameters?: Partial<StoredIndexesListGlobalState>
) => Promise<StoredIndexesDataOrderedMap | undefined>;

type TopPaginationBlockProps = {
  pagesNumber: number;
  activePage: number;
  loadStoredIndexesList: StoredIndexesListDataProvider;
  onChangePage: (pageEvent: PageEvent) => void;
};

const TopPaginationBlock = (props: TopPaginationBlockProps) => {
  const { activePage, pagesNumber, onChangePage, loadStoredIndexesList } = props;

  const { showLoader, hideLoader } = usePLIContext();

  const [storedIndexesListState, setStoredIndexesListState] = useRecoilState(
    storedIndexesListGlobalState
  );
  const { search, itemsPerPage } = storedIndexesListState;

  const makeSearch = useCallback(
    async (value: string) => {
      await showLoader();
      await loadStoredIndexesList(
        {
          [djangoSearchKey]: value ?? search,
          [djangoPaginationKey]: 1,
          [djangoPaginationSizeKey]: itemsPerPage,
        },
        { activePage: 1 }
      );
      await hideLoader();
    },
    [search, itemsPerPage, loadStoredIndexesList, showLoader, hideLoader]
  );

  const handleSearchChange = useCallback(
    (value: SearchBoxProps["value"]) => {
      if (value !== search) {
        setStoredIndexesListState((prevState) => ({
          ...prevState,
          search: value as string,
        }));
      }
    },
    [search, setStoredIndexesListState]
  );

  const handleSearchEnter = useCallback(
    (value: SearchBoxProps["value"]) => makeSearch(value as string),
    [makeSearch]
  );

  // scroll to the top of the list

  const scrollToRef = useScrollTo([activePage]);

  return (
    <Card
      ref={scrollToRef}
      css={{
        width: "100%",
        borderBottomLeftRadius: "0",
        borderBottomRightRadius: "0",
      }}
    >
      <CardBody css={{ padding: "$4" }}>
        <AutoSearchBox
          css={{ width: "100%" }}
          value={search}
          placeholder="Search by name..."
          onChange={handleSearchChange}
          onSubmit={handleSearchEnter}
        />
      </CardBody>
      {pagesNumber > 1 && (
        <>
          <Divider />
          <CardActions css={{ justifyContent: "center" }}>
            <Pagination
              css={{ padding: "0" }}
              options={{
                variant: "full",
                currentPage: activePage,
                numPages: pagesNumber,
              }}
              onPageClick={onChangePage}
            />
          </CardActions>
        </>
      )}
    </Card>
  );
};

TopPaginationBlock.displayName = "TopPaginationBlock";

type BottomPaginationBlockProps = {
  activePage: number;
  pagesNumber: number;
  onChangePage: (pageEvent: PageEvent) => void;
};

const BottomPaginationBlock = (props: BottomPaginationBlockProps) => {
  const { activePage, pagesNumber, onChangePage } = props;

  return (
    <Card
      css={{
        width: "100%",
        borderTopLeftRadius: "0",
        borderTopRightRadius: "0",
      }}
    >
      <Pagination
        options={{
          variant: "full",
          currentPage: activePage,
          numPages: pagesNumber,
        }}
        onPageClick={onChangePage}
      />
    </Card>
  );
};

BottomPaginationBlock.displayName = "BottomPaginationBlock";

type BottomBlockProps = {
  onGoBackToProgramsList: () => void;
};

const BottomBlock = (props: BottomBlockProps) => {
  const { onGoBackToProgramsList } = props;

  return (
    <Card css={{ width: "100%", padding: "$4" }}>
      <ButtonGroupRight>
        <NavigationButton icon="arrow-left" onClick={onGoBackToProgramsList}>
          Back To Indexes List
        </NavigationButton>
      </ButtonGroupRight>
    </Card>
  );
};

BottomBlock.displayName = "BottomBlock";

type StoredIndexesListProps = {
  onDeleteIndex: (item: StoredIndexDataMap) => void;
  onShowSaveIndexDuplicateModal: (item: StoredIndexDataMap) => void;
  onUpdateStoredIndexParams: (
    item: StoredIndexDataMap,
    params: { is_active: boolean }
  ) => void;
};

const StoredIndexesListBlock = (props: StoredIndexesListProps) => {
  const { onDeleteIndex, onShowSaveIndexDuplicateModal, onUpdateStoredIndexParams } =
    props;

  const {
    userId: sessionUserId,
    isRegularUser,
    isClientAdmin,
    isPTAdmin,
    currenciesData,
    router,
  } = usePLIContext();
  const { programId, isPreviewMode, isSample } = useProgramContext();

  const storedIndexesListState = useRecoilValue(storedIndexesListGlobalState);
  const { data, search } = storedIndexesListState;

  const handleShowIndex = (indexId: number) => {
    router.push(`/private-index/programs/${programId}/indexes/${indexId}`);
  };

  if (!data?.size && !search)
    return (
      <Box fill>
        <Text italic thin>
          No views saved so far
        </Text>
      </Box>
    );

  if (!data?.size && search)
    return (
      <Box fill>
        <Text italic thin>
          Nothing found for "{search}"
        </Text>
      </Box>
    );

  const indexesList = data?.toArray().map((item: StoredIndexDataMap) => {
    const id = item.get("id");
    const title = item.get("title");
    const isActive = item.get("is_active");
    const created = item.get("created");
    const userId = item.get("user_id");
    const userName = item.get("user_name");
    const displayCurrencyCode = item.get("display_currency_code");
    const displayCurrencyName = currenciesData.get(displayCurrencyCode)?.get("name");
    const displayCurrencySymbol = currenciesData.get(displayCurrencyCode)?.get("symbol");
    const lastTrendData =
      item.get("last_trend_data") || (emptyMap as StoredIndexTrendDataMap);
    const datasetLength = lastTrendData.get("dataset_length") || 0;
    const availableDatasetLength = lastTrendData.get("available_dataset_length") || 0;
    const hasAnyTrendsData = lastTrendData.size > 0;
    const freshness_timestamp = lastTrendData.get("freshness_timestamp");
    const isOwned =
      userId != null &&
      sessionUserId != null &&
      parseInt("" + sessionUserId, 10) === parseInt("" + userId, 10);
    const isDuplicate = item.get("is_duplicate");

    return (
      <Card key={id} css={{ width: "100%", borderRadius: "0" }}>
        <CardActions>
          <CardActionsLeft>
            <h3>
              {title}&nbsp;&nbsp;
              {userId != null &&
                (isOwned || isPTAdmin) &&
                (isActive ? (
                  <Text
                    as="small"
                    css={{ color: "$success" }}
                    title="Available for other users"
                  >
                    visible
                  </Text>
                ) : (
                  <Text
                    as="small"
                    css={{ color: "$danger" }}
                    title="Not available for other users"
                  >
                    hidden
                  </Text>
                ))}
            </h3>
          </CardActionsLeft>
          <CardActionsRight>
            {userId != null &&
              !isSample &&
              !isPreviewMode &&
              ((isRegularUser && isOwned) || isPTAdmin || isClientAdmin) && (
                <IconButton
                  size="normal"
                  variant="text"
                  icon="copy"
                  title={
                    isDuplicate ? "Can't copy because view is duplicate" : "Copy View"
                  }
                  onClick={() => onShowSaveIndexDuplicateModal(item)}
                  disabled={isDuplicate}
                />
              )}
            {userId != null &&
              !isPreviewMode &&
              ((isRegularUser && isOwned) || isPTAdmin || isClientAdmin) && (
                <IconButton
                  size="normal"
                  variant="text"
                  color="danger"
                  icon="trash-alt"
                  title="Delete View"
                  onClick={() => onDeleteIndex(item)}
                />
              )}
          </CardActionsRight>
        </CardActions>
        <CardBody>
          {userId != null && userName != null && (
            <div>
              <b>Created by: </b>
              <i>
                <Icon icon="user" /> {userName}
                {isOwned ? " (you)" : ""}
              </i>
            </div>
          )}
          {created != null && (
            <div>
              <b>Created at: </b>
              <i>{dateTimeFormatter(created)}</i>
            </div>
          )}
          {freshness_timestamp != null && (
            <div>
              <b>Freshness date: </b>
              <i>{dateTimeFormatter(freshness_timestamp)}</i>
            </div>
          )}
          {displayCurrencyCode && (
            <div>
              <b>Display currency: </b>
              {displayCurrencyName && <i>{displayCurrencyName} </i>}
              {displayCurrencySymbol && (
                <i>
                  {" "}
                  (<b>{displayCurrencySymbol}</b>)
                </i>
              )}
            </div>
          )}
          <div>
            <div>
              <b>Items count:</b>{" "}
              <i>{isPTAdmin ? datasetLength : availableDatasetLength}</i>
            </div>
            {isPTAdmin && (
              <div>
                <b>Available items count:</b> <i>{availableDatasetLength}</i>
              </div>
            )}
          </div>
          <ButtonGroup css={{ marginTop: "$4" }}>
            {(isDuplicate || hasAnyTrendsData) && (
              <Button
                icon="chart-column"
                color="brand"
                size="small"
                onClick={() => handleShowIndex(id)}
                css={{ width: "130px" }}
              >
                View Data
              </Button>
            )}
            {((isRegularUser && isOwned) || isPTAdmin || isClientAdmin) &&
              userId != null &&
              !isPreviewMode &&
              isActive && (
                <PromiseButton
                  icon="eye-slash"
                  color="danger"
                  variant="outlined"
                  size="small"
                  loadingText="Hide View"
                  onClick={() => onUpdateStoredIndexParams(item, { is_active: false })}
                  css={{ width: "160px" }}
                >
                  Hide View
                </PromiseButton>
              )}
            {((isRegularUser && isOwned) || isPTAdmin || isClientAdmin) &&
              userId != null &&
              !isPreviewMode &&
              !isActive && (
                <PromiseButton
                  icon="eye"
                  color="success"
                  variant="outlined"
                  size="small"
                  loadingText="Make View Visible"
                  onClick={() => onUpdateStoredIndexParams(item, { is_active: true })}
                  css={{ width: "160px" }}
                >
                  Make View Visible
                </PromiseButton>
              )}
          </ButtonGroup>
        </CardBody>
      </Card>
    );
  });

  return <>{indexesList}</>;
};

StoredIndexesListBlock.displayName = "StoredIndexesListBlock";

type StoredIndexDataCreateViewState = StoredIndexDataCreateView & {
  id: number;
  title: string;
};

const ProgramDetails = (props: CommonProgramChildPageProps) => {
  const {
    router,
    fetchM8API,
    showModalError,
    showLoader,
    hideLoader,
    showConfirmationModal,
    closeConfirmationModal,
  } = usePLIContext();
  const { programId, programData } = props;

  const [storedIndexesListState, setStoredIndexesListState] = useRecoilState(
    storedIndexesListGlobalState
  );
  const {
    activePage,
    itemsPerPage,
    itemsCount,
    search,
    loaded: isIndexesLoaded,
  } = storedIndexesListState;
  const pagesNumber = Math.ceil(itemsCount / itemsPerPage) || 1;

  // state

  const [saveDuplicateState, setSaveDuplicateState] =
    useState<StoredIndexDataCreateViewState>();

  // modals

  const {
    modalState: saveIndexDuplicateModalState,
    showModal: showSaveIndexDuplicateModal,
    closeModal: closeSaveIndexDuplicateModal,
  } = useModalState();

  // messager

  const { showError } = useMessagerRef();

  // data retrieval

  const loadStoredIndexesList: StoredIndexesListDataProvider = useCallback(
    async (queryArgs = {}, extraStoredParameters = {}) => {
      try {
        const response: FetchAPIResponse<DjangoPaginatedResponse<StoredIndexDataObject>> =
          await fetchM8API(`programs/${programId}/stored_indexes/`, {
            params: {
              [djangoOrderingKey]: "-created",
              ...queryArgs,
            },
          });
        const data: StoredIndexesDataOrderedMap = storedIndexesListToImmutableOrderedMap(
          response.data.results
        );

        await setStoredIndexesListState((prevState) => ({
          ...prevState,
          ...extraStoredParameters,
          data: data,
          itemsCount: response.data.count,
          loaded: true,
        }));

        return data;
      } catch (err: any) {
        logAsyncOperationError("fetchStoredIndexesList", err);
        showModalError("Error occurred while loading stored indexes list.");
      }
    },
    [programId, setStoredIndexesListState, fetchM8API, showModalError]
  );

  const refreshStoredIndexesList = useCallback(() => {
    return loadStoredIndexesList({
      [djangoSearchKey]: search,
      [djangoPaginationKey]: activePage,
      [djangoPaginationSizeKey]: itemsPerPage,
    });
  }, [search, activePage, itemsPerPage, loadStoredIndexesList]);

  // handlers

  const handleGoBackToProgramsList = useCallback(() => {
    router.push("/private-index/programs");
  }, [router]);

  const handleChangePage = useCallback(
    (pageEvent: PageEvent) => {
      const pageNumber = getChangingPageNumber(pageEvent, activePage, pagesNumber, 1);

      if (pageNumber != null && pageNumber !== activePage) {
        showLoader();
        loadStoredIndexesList(
          {
            [djangoSearchKey]: search,
            [djangoPaginationKey]: pageNumber,
            [djangoPaginationSizeKey]: itemsPerPage,
          },
          { activePage: pageNumber }
        ).then(() => hideLoader());
      }
    },
    [
      search,
      pagesNumber,
      activePage,
      itemsPerPage,
      loadStoredIndexesList,
      showLoader,
      hideLoader,
    ]
  );

  const handleUpdateStoredIndexParams = useCallback(
    async (item: StoredIndexDataMap, data: Partial<StoredIndexDataObject> = {}) => {
      const indexId = item.get("id");

      try {
        await fetchM8API(`programs/${programId}/stored_indexes/${indexId}/`, {
          data: data,
          method: "patch",
        });
        await refreshStoredIndexesList();
      } catch (err: any) {
        logAsyncOperationError("updateStoredIndexData", err);
        showModalError(`Error occurred while index update #${indexId}.`);
      }
    },
    [programId, refreshStoredIndexesList, fetchM8API, showModalError]
  );

  const handleDeleteIndex = useCallback(
    async (item: StoredIndexDataMap) => {
      const indexId = item.get("id");

      try {
        await fetchM8API(`programs/${programId}/stored_indexes/${indexId}/`, {
          method: "delete",
        });
        await refreshStoredIndexesList();
        await closeConfirmationModal();
      } catch (err: any) {
        logAsyncOperationError("deleteStoredIndex", err);
        showModalError(`Error occurred during index deletion #${indexId}.`);
      }
    },
    [
      programId,
      refreshStoredIndexesList,
      closeConfirmationModal,
      fetchM8API,
      showModalError,
    ]
  );

  const handleConfirmDeleteIndex = useCallback(
    (item: StoredIndexDataMap) => {
      const id = item.get("id");
      const title = item.get("title");
      const header = "Confirm delete action";
      const message = (
        <div>
          <span>
            Do you really want to delete the following view? <br />
            <b>{`- #${id} ${title}`}</b>
          </span>
        </div>
      );
      const footer = (
        <ButtonGroupRight fill css={{ flexDirection: "row-reverse" }}>
          <Button onClick={closeConfirmationModal} size="large">
            No, Cancel
          </Button>
          <PromiseButton
            loadingText="Delete"
            icon="trash-alt"
            color="danger"
            size="large"
            onClick={() => handleDeleteIndex(item)}
          >
            Yes, Delete View
          </PromiseButton>
        </ButtonGroupRight>
      );

      return showConfirmationModal(message, header, footer);
    },
    [handleDeleteIndex, showConfirmationModal, closeConfirmationModal]
  );

  const handleShowSaveIndexDuplicateModal = useCallback(
    (item: StoredIndexDataMap) => {
      showSaveIndexDuplicateModal();
      setSaveDuplicateState({
        id: item.get("id"),
        title: item.get("title"),
        userId: item.get("user_id"),
        userName: item.get("user_name"),
        filters: item.get("filters"),
        filtersQuery: item.get("dj_filters"),
        visibleColumns: item.get("visible_columns"),
        itemsPerPage: item.get("items_per_page"),
        activeTab: item.get("active_tab"),
      });
    },
    [showSaveIndexDuplicateModal]
  );

  const handleSaveIndexDuplicateModal = useCallback(
    (item: StoredIndexDataCreateViewState) => {
      return async (title: string, isVisible: boolean) => {
        const storedIndexId = item.id;
        const payload = {
          title: title,
          is_active: isVisible,
        };

        try {
          const response: FetchAPIResponse = await fetchM8API(
            `programs/${programId}/stored_indexes/${storedIndexId}/save_as_duplicate/`,
            {
              method: "post",
              data: payload,
            }
          );

          const data = storedIndexDuplicateToImmutableMap(response.data);
          await refreshStoredIndexesList();
          return data;
        } catch (err: any) {
          logAsyncOperationError("createStoredIndexDataDuplicate", err);
          showError("Error occurred while creating a duplicate Index View");
        }
      };
    },
    [refreshStoredIndexesList, fetchM8API, programId, showError]
  );

  // initial data fetch
  useEffect(() => {
    if (!isIndexesLoaded) {
      refreshStoredIndexesList();
    }
  }, [isIndexesLoaded, refreshStoredIndexesList, setStoredIndexesListState]);

  // final cleanup
  useEffect(() => {
    return () => {
      setStoredIndexesListState((prevState) => ({
        ...prevState,
        loaded: false,
      }));
    };
  }, [setStoredIndexesListState]);

  if (!isIndexesLoaded) return <TickerPageLoader />;
  if (!programData || !programData.size) {
    return <NoProgramFound onBackToProgramsList={handleGoBackToProgramsList} />;
  }
  if (isIndexesLoaded)
    return (
      <Stack>
        {saveIndexDuplicateModalState && saveDuplicateState && (
          <SaveIndexForm
            show={saveIndexDuplicateModalState}
            modalTitle={`Copy View: "${saveDuplicateState.title}"`}
            onClick={handleSaveIndexDuplicateModal(saveDuplicateState)}
            onClose={closeSaveIndexDuplicateModal}
          />
        )}
        <PageHeadBlock
          programData={programData}
          onGoBackToProgramsList={handleGoBackToProgramsList}
        />
        <TopPaginationBlock
          pagesNumber={pagesNumber}
          activePage={activePage}
          loadStoredIndexesList={loadStoredIndexesList}
          onChangePage={handleChangePage}
        />
        <StoredIndexesListBlock
          onDeleteIndex={handleConfirmDeleteIndex}
          onShowSaveIndexDuplicateModal={handleShowSaveIndexDuplicateModal}
          onUpdateStoredIndexParams={handleUpdateStoredIndexParams}
        />
        {isIndexesLoaded && pagesNumber > 1 && (
          <BottomPaginationBlock
            pagesNumber={pagesNumber}
            activePage={activePage}
            onChangePage={handleChangePage}
          />
        )}
        {pagesNumber > 1 && (
          <BottomBlock onGoBackToProgramsList={handleGoBackToProgramsList} />
        )}
      </Stack>
    );
};

ProgramDetails.displayName = "ProgramDetails";

export default ProgramDetails;
