import React, { useMemo } from "react";

import TextInput from "../../../components/lib/TextInput";
import Inline from "../../../components/lib/Inline";

type InputsPairLabels = string[];
type InputsPairValues = string[];

const ensurePair = (
  items: InputsPairLabels | InputsPairValues
): InputsPairLabels | InputsPairValues => {
  switch (items.length) {
    case 0:
      return ["", ""];
    case 1:
      return [items[0], items[0]];
    default:
      return items;
  }
};

type ValueInputProps = {
  label: string;
  value: string;
  disabled?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

function ValueInput(props: ValueInputProps) {
  const { label, value, disabled, onChange } = props;

  const isValid = useMemo(() => !!(value?.length && value.length > 1), [value]);

  return (
    <Inline>
      <label>{label}</label>
      <TextInput
        size="extra-small"
        css={{ width: "300px", background: "transparent" }}
        value={value}
        color={isValid ? "primary" : "danger"}
        disabled={disabled}
        onChange={onChange}
      />
    </Inline>
  );
}

type InputsPairProps = {
  labels: InputsPairLabels;
  values: InputsPairValues;
  disabled?: boolean;
  onChange?: (values: InputsPairValues) => void;
};

export default function InputsPair(props: InputsPairProps) {
  const { values, labels, disabled = false, onChange } = props;

  const [valueLeft, valueRight] = useMemo(() => ensurePair(values), [values]);
  const [labelLeft, labelRight] = useMemo(() => ensurePair(labels), [labels]);

  return (
    <Inline css={{ gap: "$6" }}>
      <ValueInput
        label={labelLeft}
        value={valueLeft}
        disabled={disabled}
        onChange={(e) => onChange?.([e.target.value, valueRight])}
      />
      <ValueInput
        label={labelRight}
        value={valueRight}
        disabled={disabled}
        onChange={(e) => onChange?.([valueLeft, e.target.value])}
      />
    </Inline>
  );
}

InputsPair.displayName = "InputsPair";
