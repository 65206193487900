import React, { useCallback } from "react";

import { usePLIContext } from "../context";
import SaveIndexForm from "./SaveIndexForm";
import { storedIndexToImmutableMap } from "../dataConverters";
import { useProgramContext } from "../ProgramDataProvider";
import { useMessagerRef } from "../hooks";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";

import type { StoredIndexDataObject, StoredIndexDataCreateView } from "../types";
import type {
  FiltersConfigOrderedMap,
  FiltersQueryOrderedMap,
} from "../../../components/tables/types";
import type { FetchAPIResponse } from "../../../types/fetch";

type SaveIndexModalProps = StoredIndexDataCreateView & {
  show: boolean;
  onClose: () => void;
};

const SaveIndexModal = (props: SaveIndexModalProps) => {
  const {
    userId,
    userName,
    filters,
    filtersQuery,
    visibleColumns,
    itemsPerPage,
    activeTab,
    show,
    onClose,
  } = props;

  const { fetchM8API } = usePLIContext();
  const { programId } = useProgramContext();

  // messager

  const { showError } = useMessagerRef();

  // handlers

  const handleCreateView = useCallback(
    async (title: string, isVisible: boolean) => {
      // delete "status" from filter
      const cleanFilters: FiltersConfigOrderedMap = filters.delete("status");
      const cleanFiltersQuery: FiltersQueryOrderedMap = filtersQuery
        .delete("status")
        .delete("status__in");

      // validate
      if (filters.size === 1 && cleanFilters.size === 0) {
        showError('Can\'t use "status" column as the only filter.');
        return;
      }
      if (
        userId == null ||
        cleanFilters == null ||
        cleanFiltersQuery == null ||
        cleanFilters.size === 0 ||
        cleanFiltersQuery.size === 0
      ) {
        return;
      }

      // TODO check input value against XSS attacks

      const payload = {
        title: title,
        is_active: isVisible,
        user_id: userId,
        user_name: userName,
        filters: JSON.stringify(cleanFilters.entrySeq().toJS()),
        dj_filters: JSON.stringify(cleanFiltersQuery.entrySeq().toJS()),
        visible_columns:
          visibleColumns && visibleColumns.size
            ? JSON.stringify(visibleColumns.toJS())
            : null,
        items_per_page: itemsPerPage,
        active_tab: activeTab,
      };

      try {
        const response: FetchAPIResponse<StoredIndexDataObject> = await fetchM8API(
          `programs/${programId}/stored_indexes/`,
          {
            method: "post",
            data: payload,
          }
        );
        const data = storedIndexToImmutableMap(response.data);

        return data;
      } catch (err: any) {
        logAsyncOperationError("saveStoredIndexData", err);
        showError("Error occurred while creating a new Index View:");
      }
    },
    [
      programId,
      userId,
      userName,
      itemsPerPage,
      visibleColumns,
      activeTab,
      filters,
      filtersQuery,
      fetchM8API,
      showError,
    ]
  );

  return <SaveIndexForm show={show} onClick={handleCreateView} onClose={onClose} />;
};

SaveIndexModal.displayName = "SaveIndexModal";

export default SaveIndexModal;
