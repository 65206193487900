import { Environment, Network, RecordSource, Store } from "relay-runtime";
import { handlerProvider } from "./utils/relay_windowed_connection_handler";

import type { RequestParameters, Variables } from "relay-runtime";
import type { FetchGraphQLAPIFunc } from "./types/fetch";
import type { Environment as EnvironmentType } from "react-relay";

let relayEnvironment: EnvironmentType | undefined = undefined;

// The resulting Relay Environment is a singleton object and it lives during the whole app lifecycle.
// The resulting singleton object is stored and always available in the Global React Context object.
export function createRelayEnvironment(fetchFunc: FetchGraphQLAPIFunc): EnvironmentType {
  if (relayEnvironment != null) return relayEnvironment;

  async function relayFetchFunc(operation: RequestParameters, variables: Variables) {
    if (operation.text != null) {
      const response = await fetchFunc(
        operation.text, // GQL query
        variables, // GQL variables
        { params: { operation: operation.name } } // URL arguments
      );
      return response.data;
    } else {
      throw new Error("Relay Environment: Using query ID not supported");
    }
  }

  relayEnvironment = new Environment({
    network: Network.create(relayFetchFunc),
    store: new Store(new RecordSource()),
    handlerProvider,
  }) as unknown as EnvironmentType;

  return relayEnvironment;
}
