import React, { ReactNode } from "react";
import Button from "../../../../components/lib/Button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "../../../../components/lib/Dialog";
import Inline from "../../../../components/lib/Inline";
import LinkButton from "../../../../components/lib/LinkButton";
import Stack from "../../../../components/lib/Stack";
import Text from "../../../../components/lib/Text";

interface Props {
  children: ReactNode;
}

export function BuyMoreSearchesDialog({ children }: Props) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <span>{children}</span>
      </DialogTrigger>
      <DialogContent css={{ width: "500px", maxWidth: "500px", padding: "25px" }}>
        <Stack fill css={{ alignItems: "flex-start", gap: "$4" }}>
          <Text as="h3" noMargin>
            Buy More Searches
          </Text>

          <Text as="p">
            Please visit our Search Allocator page to purchase more searches.
          </Text>

          <Inline fill css={{ justifyContent: "right" }}>
            <DialogClose asChild>
              <Button variant="outlined" color="brand" size="small">
                Close
              </Button>
            </DialogClose>
            <DialogClose asChild>
              <LinkButton
                to="/search-allocator"
                variant="filled"
                color="brand"
                size="small"
              >
                Go to Search Allocator
              </LinkButton>
            </DialogClose>
          </Inline>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
