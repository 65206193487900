// @flow

import R from "ramda";
import { extendObservable, action, runInAction, computed, observable } from "mobx";
import axios from "axios";

import JobLibraryRequest from "../../models/JobLibraryRequest";
import CurrentUser from "../../models/User";
import NetworkState from "../../models/NetworkState";
import FilterObject from "../../models/Filter";
import Sort, { SORT_DIRECTION } from "../../models/Sort";
import ModalState from "../../models/ModalState";
import { CursorPaginationState } from "../../models/PaginationState";
import {
  consolidateAppliedFilters,
  consolidateAppliedSorts,
  createTasteGraphQLWrapper,
} from "./SupportFunctions";
import type { PageQuery, PaginationInfo } from "../../models/PaginationState";
import type { FilterColumn } from "../../models/Filter";
import type { GraphQLQuery } from "../../models/GraphQL";
import SortState from "../../models/SortState";
import { LIBRARY_REQUEST_SORT_FIELD } from "../../constants/jobLibraryRequest";
import type { FetchAPI, FetchGraphQL } from "../../App";
import type MobXStore from "./MobXStore";

export class JobLibraryRequestListComponentStore {
  fetchTasteGraphQL: FetchGraphQL;
  fetchGraphQL: FetchGraphQL;
  fetchAPI: FetchAPI;
  mobXStore: MobXStore;
  network: NetworkState;
  pagination: CursorPaginationState;

  jobLibraryRequests: JobLibraryRequest[];
  jobLibraryRequestsViewState: Object;
  jobLibraryRequestsView: JobLibraryRequest[];

  currentUser: CurrentUser;
  isEditing: ?boolean;
  allOnPageSelected: boolean;
  allSelected: boolean;
  allowMultipleItemSelection: boolean;
  allowExpand: boolean;

  // jobLibraryRequestTitleFilter: ClientLibraryRequestTitleFilter;
  // jobLibraryRequestOnlyStatusFilter: ClientLibraryRequestOnlyStatusFilter;

  defaultFilters: { [key: FilterColumn]: FilterObject };
  appliedFilters: { [key: FilterColumn]: FilterObject };
  appliedSorts: { [key: FilterColumn]: Sort };
  appliedSortsOrder: Array<FilterColumn>;
  isFiltered: boolean;

  getJobLibraryRequests: (PageQuery) => Promise<PaginationInfo>;
  applyDefaultFilter: () => void;
  handleStartEdit: () => void;
  handleStopEdit: () => void;
  toggleSelectAllPage: (SyntheticMouseEvent<HTMLElement>) => void;
  selectAllPage: (SyntheticMouseEvent<HTMLElement>) => void;
  deselectAllPage: (SyntheticMouseEvent<HTMLElement>) => void;
  toggleAllItems: () => void;
  clearAllSelections: () => void;
  getSelectedJobLibraryRequests: () => Array<string>;
  getFirstSelectedJobLibraryRequest: () => Object;

  getFilterCriteriaQuery: (column: FilterColumn) => GraphQLQuery;
  processFilterCriteria: (column: FilterColumn, Object) => Array<Object>;
  applyFilter: (FilterColumn, FilterObject) => void;
  removeFilter: (column: FilterColumn) => void;
  applySort: (FilterColumn, Sort) => void;
  removeSort: (column: FilterColumn) => void;
  filterJobLibraryRequests: () => void;
  clearFilters: () => void;
  applyDefaultSort: () => void;
  onSortByField: (?string, ?boolean) => void;
  sortState: SortState;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI, mobXStore: MobXStore) {
    this.fetchTasteGraphQL = createTasteGraphQLWrapper(fetchAPI);
    this.fetchGraphQL = fetchGraphQL;
    this.fetchAPI = fetchAPI;
    this.mobXStore = mobXStore;

    // NOTE: Bound early to pass into pagination & filter state
    this.getJobLibraryRequests = action(this.getJobLibraryRequests.bind(this));

    this.getFilterCriteriaQuery = action(this.getFilterCriteriaQuery.bind(this));
    this.processFilterCriteria = action(this.processFilterCriteria.bind(this));
    this.applyFilter = action(this.applyFilter.bind(this));
    this.applySort = action(this.applySort.bind(this));
    this.removeFilter = action(this.removeFilter.bind(this));
    this.removeSort = action(this.removeSort.bind(this));
    this.onSortByField = action(this.onSortByField.bind(this));

    extendObservable(this, {
      network: new NetworkState(),
      pagination: new CursorPaginationState(this.getJobLibraryRequests),
      jobLibraryRequests: [],
      jobLibraryRequestsViewState: observable.map({}),
      jobLibraryRequestsView: computed(() => {
        return this.jobLibraryRequests.map((jobLibraryRequest) => {
          if (this.jobLibraryRequestsViewState.has(jobLibraryRequest.id)) {
            jobLibraryRequest.viewState = this.jobLibraryRequestsViewState.get(
              jobLibraryRequest.id
            );

            return jobLibraryRequest;
          }

          return jobLibraryRequest;
        });
      }),
      isEditing: null, // we start with null so some view elements be hidden initially
      isFiltered: false,
      allSelected: false,
      allowMultipleItemSelection: true,
      allowExpand: true,
      selectedCount: computed(() => {
        if (this.allSelected) {
          return this.pagination.totalCount;
        }

        const selectedValues = this.jobLibraryRequestsView.filter(
          (jobLibraryRequest) =>
            jobLibraryRequest.viewState && jobLibraryRequest.viewState.selected
        );

        return selectedValues.length;
      }),
      allOnPageSelected: computed(() => {
        const allTrue = R.all(R.equals(true));
        const selectedValues = this.jobLibraryRequestsView.map(
          (jobLibraryRequest) =>
            jobLibraryRequest.viewState && jobLibraryRequest.viewState.selected
        );

        if (selectedValues.length === 0) {
          return false;
        }

        return allTrue(selectedValues);
      }),
      defaultFilters: {},
      appliedFilters: {},
      appliedSorts: {},
      appliedSortsOrder: observable.shallow([]),
      sortState: new SortState(LIBRARY_REQUEST_SORT_FIELD.CREATED, SORT_DIRECTION.DESC),
      // jobLibraryRequestTitleFilter: new ClientLibraryRequestTitleFilter(
      //   this,
      //   FILTER_COLUMN.CLIENT_JOB_TITLE_REQUEST_TITLE,
      //   this.applyFilter,
      //   this.applySort,
      //   this.removeFilter,
      //   this.removeSort
      // ),
      // jobLibraryRequestOnlyStatusFilter: new ClientLibraryRequestOnlyStatusFilter(
      //   this,
      //   FILTER_COLUMN.CLIENT_JOB_TITLE_REQUEST_ONLY_STATUS,
      //   this.getFilterCriteriaQuery,
      //   this.processFilterCriteria,
      //   this.applyFilter,
      //   this.removeFilter
      // ),
    });

    // this.pagination.itemsPerPage = 10;
    this.currentUser = null;

    this.handleStartEdit = action(this.handleStartEdit.bind(this));
    this.handleStopEdit = action(this.handleStopEdit.bind(this));
    this.toggleSelectAllPage = action(this.toggleSelectAllPage.bind(this));
    this.selectAllPage = action(this.selectAllPage.bind(this));
    this.deselectAllPage = action(this.deselectAllPage.bind(this));
    this.toggleAllItems = action(this.toggleAllItems.bind(this));
    this.clearAllSelections = action(this.clearAllSelections.bind(this));
    this.getSelectedJobLibraryRequests = action(
      this.getSelectedJobLibraryRequests.bind(this)
    );
    this.getFirstSelectedJobLibraryRequest = action(
      this.getFirstSelectedJobLibraryRequest.bind(this)
    );

    this.filterJobLibraryRequests = action(this.filterJobLibraryRequests.bind(this));
    this.clearFilters = action(this.clearFilters.bind(this));
    this.applyDefaultSort = action(this.applyDefaultSort.bind(this));
    this.applyDefaultFilter = action(this.applyDefaultFilter.bind(this));

    this.applyDefaultSort();
    this.applyDefaultFilter();
  }

  onSortByField(field?: string, caseSensitive: boolean = false) {
    const defaultField = LIBRARY_REQUEST_SORT_FIELD.CREATED;
    const defaultDirection = SORT_DIRECTION.DESC;

    // reset sort if no field is passed
    if (!field) {
      // remove sorts
      this.appliedSorts = observable({});
      this.appliedSortsOrder.length = 0;

      // remove sort state
      this.sortState = new SortState(defaultField, defaultDirection);
      caseSensitive = true; // this is required when sorting by date
    } else {
      // remove previous sort
      if (this.sortState) this.removeSort(this.sortState.field);

      // cycle field sort: asc -> desc -> default

      // new sort, sort asc
      if (!this.sortState || this.sortState.field !== field) {
        // create sort state (default SORT_DIRECTION.ASC)
        this.sortState = new SortState(field, SORT_DIRECTION.ASC);
      } else if (
        !this.sortState.direction ||
        this.sortState.direction === SORT_DIRECTION.ASC
      ) {
        // sort desc
        this.sortState.setSort(SORT_DIRECTION.DESC);
      } else if (this.sortState.direction === SORT_DIRECTION.DESC) {
        // reset sort
        // remove sort state
        if (field === defaultField && defaultDirection === SORT_DIRECTION.DESC)
          this.sortState = new SortState(defaultField, SORT_DIRECTION.ASC);
        else this.sortState = new SortState(defaultField, defaultDirection);
        caseSensitive = true; // this is required when sorting by date
      }
    }

    // apply sort
    const sortField = this.sortState.field;
    const sortDirection = this.sortState.direction || SORT_DIRECTION.ASC;
    const sortSensitive = caseSensitive ? "true" : "false";
    const sort = new Sort(
      "{field: " +
        sortField +
        ", direction: " +
        sortDirection +
        ", caseSensitive: " +
        sortSensitive +
        "}"
    );
    this.applySort(this.sortState.field, sort);

    // fetch
    this.pagination.goFetch();
  }

  applyDefaultSort() {
    this.sortState = new SortState(
      LIBRARY_REQUEST_SORT_FIELD.CREATED,
      SORT_DIRECTION.DESC
    );
    const caseSensitive = true; // this is required when sorting by date
    const sortField = this.sortState.field;
    const sortDirection = this.sortState.direction || SORT_DIRECTION.ASC;
    const sortSensitive = caseSensitive ? "true" : "false";
    const sort = new Sort(
      "{field: " +
        sortField +
        ", direction: " +
        sortDirection +
        ", caseSensitive: " +
        sortSensitive +
        "}"
    );
    this.applySort(this.sortState.field, sort);

    // this.jobLibraryRequestTitleFilter.sortState.direction = SORT_DIRECTION.ASC;
    // this.jobLibraryRequestTitleFilter.sort = this.jobLibraryRequestTitleFilter.buildQuerySort();
    // this.applySort(this.jobLibraryRequestTitleFilter.column, this.jobLibraryRequestTitleFilter.sort);
  }

  applyDefaultFilter() {
    // let initialSelectedStatus = [0, 1];  // PROCESSING, PENDING
    // this.jobLibraryRequestOnlyStatusFilter.filter = this.jobLibraryRequestOnlyStatusFilter.createFilterObject(initialSelectedStatus);
    // this.applyFilter(this.jobLibraryRequestOnlyStatusFilter.column, this.jobLibraryRequestOnlyStatusFilter.filter);
  }

  handleStartEdit() {
    this.isEditing = true;
    this.allowExpand = false;
    this.jobLibraryRequestsView.forEach((jobLibraryRequest) =>
      jobLibraryRequest.toggleEdit()
    );
  }

  handleStopEdit() {
    this.isEditing = false;
    this.allSelected = false;
    this.allowExpand = true;
    this.jobLibraryRequestsViewState.forEach((viewState) => {
      viewState.selected = false;
      viewState.editing = false;
    });
  }

  toggleSelectAllPage(e: SyntheticMouseEvent<HTMLElement>) {
    if (!this.allowMultipleItemSelection) return;

    const setValue = !this.allOnPageSelected;

    this.jobLibraryRequestsView.forEach((jobLibraryRequest) => {
      jobLibraryRequest.toggleSelected(e, null, null, setValue);
    });

    // When All items selected flag is up, clear selection
    if (setValue === false && this.allSelected) this.allSelected = false;
  }

  selectAllPage(e: SyntheticMouseEvent<HTMLElement>) {
    this.jobLibraryRequestsView.forEach((jobLibraryRequest) => {
      jobLibraryRequest.toggleSelected(e, null, null, true);
    });
  }

  deselectAllPage(e: SyntheticMouseEvent<HTMLElement>) {
    this.jobLibraryRequestsView.forEach((jobLibraryRequest) => {
      jobLibraryRequest.toggleSelected(e, null, null, false);
    });

    this.allSelected = false;
  }

  toggleAllItems() {
    if (!this.allowMultipleItemSelection) return;

    this.allSelected = !this.allSelected;

    if (this.allSelected === false) {
      this.jobLibraryRequestsViewState.forEach((value) => {
        value.selected = false;
      });
    }
  }

  clearAllSelections() {
    this.allSelected = false;
    this.jobLibraryRequestsViewState.forEach((value) => {
      value.selected = false;
    });
  }

  getFilterCriteriaQuery(column: FilterColumn): GraphQLQuery {
    switch (column) {
      // case FILTER_COLUMN.CLIENT_JOB_TITLE_REQUEST_ONLY_STATUS:
      //   return {
      //     query: statusCriteriaQuery,
      //     variables: {}
      //   };

      default:
        return null;
    }
  }

  processFilterCriteria(column: FilterColumn, payload: Object): ?Array<Object> {
    switch (column) {
      // case FILTER_COLUMN.CLIENT_JOB_TITLE_REQUEST_ONLY_STATUS:
      //   const statusChoices: [StatusCriterion] = payload.data.jobLibraryRequestsFilterCriteria.statusChoices;
      //   let processedStatusChoices = observable.map({});
      //   statusChoices.forEach(status => {
      //     processedStatusChoices.set(String(status.value), {
      //       id: String(status.value),
      //       ...status
      //     });
      //   });
      //
      //   return processedStatusChoices;

      default:
        return null;
    }
  }

  applyFilter(column: FilterColumn, filter: FilterObject) {
    this.appliedFilters[column] = filter;
    this.isFiltered = true;
  }

  removeFilter(column: FilterColumn) {
    delete this.appliedFilters[column];

    let entries = Object.entries(this.appliedFilters);
    if (!entries.length) this.isFiltered = false;
  }

  applySort(column: FilterColumn, sort: Sort) {
    this.appliedSorts[column] = sort;

    const index = this.appliedSortsOrder.indexOf(column);
    if (index === -1) this.appliedSortsOrder.push(column);
  }

  removeSort(column: FilterColumn) {
    delete this.appliedSorts[column];

    const index = this.appliedSortsOrder.indexOf(column);
    if (index > -1) this.appliedSortsOrder.splice(index, 1);
  }

  clearFilters() {
    // this.jobLibraryRequestTitleFilter = new ClientLibraryRequestTitleFilter(
    //   this,
    //   FILTER_COLUMN.CLIENT_JOB_TITLE_REQUEST_TITLE,
    //   this.applyFilter,
    //   this.applySort,
    //   this.removeFilter,
    //   this.removeSort
    // );
    //
    // this.jobLibraryRequestOnlyStatusFilter = new ClientLibraryRequestOnlyStatusFilter(
    //   this,
    //   FILTER_COLUMN.CLIENT_JOB_TITLE_REQUEST_ONLY_STATUS,
    //   this.getFilterCriteriaQuery,
    //   this.processFilterCriteria,
    //   this.applyFilter,
    //   this.removeFilter
    // );

    this.appliedFilters = observable({});
    this.appliedSorts = observable({});
    this.appliedSortsOrder.length = 0;
    this.isFiltered = false;

    return this.pagination.goFetch();
  }

  filterJobLibraryRequests() {
    return this.pagination.goFetch();
  }

  getSelectedJobLibraryRequests(): Array<string> {
    const jobLibraryRequests = this.jobLibraryRequestsViewState;

    let selectedJobLibraryRequests = [];

    jobLibraryRequests.forEach((value, key) => {
      if (value.selected) {
        selectedJobLibraryRequests.push(key);
      }
    });

    return selectedJobLibraryRequests;
  }

  getFirstSelectedJobLibraryRequest() {
    for (let j = 0; j < this.jobLibraryRequestsView.length; j++) {
      const viewState = this.jobLibraryRequests[j].viewState;
      if (viewState && viewState.selected) return this.jobLibraryRequests[j];
    }

    return null;
  }

  async getJobLibraryRequests(pageQuery: PageQuery): Promise<?PaginationInfo> {
    let params: string[] = pageQuery.params;
    let args = pageQuery.args;
    let variables = pageQuery.variables;
    let filtersCriteria: string[] = [];
    let query = {};

    let sortCriteria: string[] = [];
    consolidateAppliedSorts(this.appliedSorts, sortCriteria);

    consolidateAppliedFilters(this.appliedFilters, params, filtersCriteria, variables);

    // NOTE: applied filters could override default filters, handle this if needed
    consolidateAppliedFilters(this.defaultFilters, params, filtersCriteria, variables);

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");
    const queryFiltersCriteria = filtersCriteria.join(", ");
    const querySortCriteria = sortCriteria.join(", ");

    query.query = `
      query getClientLibraryRequests(${queryParams}) {
        clientLibraryRequests(${queryArgs}, filters: { ${queryFiltersCriteria} }, order: [${querySortCriteria}]) {
          totalCount
          pageInfo {
            startCursor
            endCursor
          }
          edges {
            node {
              id
              databaseId
              title
              attachmentFileName
              created
              status
              statusVerbose
              totalCertifiedCountries
            }
          }
        }
      }
    `;
    query.variables = variables;

    if (this.network.loading === true) {
      // console.log('Canceling ClientJobTiles list request');
      return null;
    }

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchTasteGraphQL(query.query, query.variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        // console.log('Request Canceled ClientLibraryRequestListStore');
        return e;
      }

      this.network.handleError("Getting Client Library Requests", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Client Library Requests query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getJobLibraryRequests--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get Client Library Requests query", res)) {
        // TODO: Display user friendly error message
        return { totalCount: 0, startCursor: "", endCursor: "" };
      }

      if (!res) return { totalCount: 0, startCursor: "", endCursor: "" };

      // this.currentUser = new CurrentUser(this, res.data.viewer.user);

      const requests = res.data.clientLibraryRequests.edges;

      // TODO: Deserialize this properly...
      this.jobLibraryRequests = requests.map((request) => {
        const jobLibraryRequest = new JobLibraryRequest(this, request.node);

        if (!this.jobLibraryRequestsViewState.has(jobLibraryRequest.id)) {
          this.jobLibraryRequestsViewState.set(jobLibraryRequest.id, {
            selected: this.allSelected,
            editing: this.isEditing,
            expanded: false,
          });
        } else {
          const selectedValue = this.allSelected
            ? true
            : this.jobLibraryRequestsViewState.get(jobLibraryRequest.id).selected;

          this.jobLibraryRequestsViewState.set(jobLibraryRequest.id, {
            selected: selectedValue,
            editing: this.isEditing,
            expanded: false,
          });
        }

        jobLibraryRequest.viewState = this.jobLibraryRequestsViewState.get(
          jobLibraryRequest.id
        );

        return jobLibraryRequest;
      });

      return {
        totalCount: res.data.clientLibraryRequests.totalCount,
        startCursor: res.data.clientLibraryRequests.pageInfo.startCursor,
        endCursor: res.data.clientLibraryRequests.pageInfo.endCursor,
      };
    });
  }
}

export default class JobLibraryRequestListStore extends JobLibraryRequestListComponentStore {
  showHelpModal: boolean;

  helpModal: ModalState;
  confirmDeleteModal: ModalState;

  showHelp: () => void;
  hideHelp: () => void;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI, mobXStore: MobXStore) {
    super(fetchGraphQL, fetchAPI, mobXStore);

    extendObservable(this, {
      newClientLibraryRequestName: "",
      helpModal: new ModalState(),
      confirmUpdateRatesModal: new ModalState(),
      confirmUndoUpdateRatesModal: new ModalState(),
      confirmDeleteModal: new ModalState(),
    });

    this.showHelp = action(this.showHelp.bind(this));
    this.hideHelp = action(this.hideHelp.bind(this));
  }

  showHelp() {
    this.showHelpModal = true;
  }

  hideHelp() {
    this.showHelpModal = false;
  }
}
