import React, { useState } from "react";
import { DndContext } from "@dnd-kit/core";
import { useRecoilValue } from "recoil";

import Stack from "../../components/lib/Stack";
import Divider from "../../components/lib/Divider";
import UserList from "./UserList";
import Header from "./Header";
import { TickerPageLoader } from "../../components/lib/TickerLoader";
import {
  searchAllocatorGlobalState,
  type SearchAllocatorGlobalState,
} from "./globalState";
import { useSearchAllocatorData } from "./hooks";
import { defaultFilters, applyUserFilters, type StateFilterType } from "./filters";

export default function SearchAllocator() {
  const [filters, setFilters] = useState<StateFilterType>(defaultFilters);

  // fetch data
  useSearchAllocatorData();

  const { searchAllocatorData, searchAllocatorDataLoaded: loaded } =
    useRecoilValue<SearchAllocatorGlobalState>(searchAllocatorGlobalState);

  if (!loaded || searchAllocatorData === null) {
    return <TickerPageLoader />;
  }

  // data

  const resourceAllocationId = searchAllocatorData["resourceAllocationId"];
  const searchesUsed = searchAllocatorData["resourceUsed"];
  const searchCount = searchAllocatorData["resourceAllocation"];
  const searchPrice = searchAllocatorData["resourcePrice"];
  const searchTier = searchAllocatorData["tier"];
  const users = searchAllocatorData["users"];

  // user filters

  const usersWithFilters = applyUserFilters(users, filters);

  return (
    <DndContext>
      <Stack>
        <Header
          resourceAllocationId={resourceAllocationId}
          searchPrice={searchPrice}
          searchTier={searchTier}
          searchesUsed={searchesUsed}
          searchCount={searchCount}
          setFilters={setFilters}
        />
        <Divider />
        <UserList users={usersWithFilters} />
      </Stack>
    </DndContext>
  );
}
