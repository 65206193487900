import React, { useCallback } from "react";

import ReviewsPeckingOrderTableBase from "./base";
import { emptyMap, emptyOrderedMap } from "../../../../constants";
import { REVIEW_TYPES, RATE_TYPES, JOBS_ORDERING_KEYS } from "../../types";
import { getFirstJobData, getJobRateTypeParameters } from "./utils";

import type {
  ReviewDataMap,
  ReviewsDataOrderedMap,
  RateResultsOrderedMap,
  RatesFeedbackDataMap,
  JobFeedbackDataMap,
} from "../../types";
import type { FuncGetAllReviewsOrdered, ReviewsPeckingOrderTableBaseProps } from "./base";

type ReviewsPeckingOrderTableWithFeedbackProps = ReviewsPeckingOrderTableBaseProps & {
  tableHead: React.ReactElement;
  renderTableRow: (reviewData: ReviewDataMap) => React.ReactElement;
};

export default function ReviewsPeckingOrderTableWithFeedback(
  props: ReviewsPeckingOrderTableWithFeedbackProps
) {
  const { tableHead, renderTableRow } = props;

  const getAllReviewsOrdered: FuncGetAllReviewsOrdered = useCallback(
    (reviewsGroupData, dataOrderingKey = JOBS_ORDERING_KEYS.MIN_VALUE, asc = true) => {
      return reviewsGroupData.sortBy((reviewData) => {
        const reviewType = reviewData?.get("review_type");
        const firstJobData = getFirstJobData(reviewData);
        const { rateType } = getJobRateTypeParameters(firstJobData);

        const rateResults = firstJobData.get(
          "rate_results",
          emptyOrderedMap as unknown as RateResultsOrderedMap
        );

        let value: number | null = null;
        let feedbackValue: number | null = null;

        if (reviewType === REVIEW_TYPES.CLASSIC) {
          if (dataOrderingKey === JOBS_ORDERING_KEYS.MIN_VALUE) {
            const lowRateResultData = rateResults.first() || emptyMap;
            const rateResultId = lowRateResultData.get("id");
            const rateFeedbackData =
              (reviewData?.getIn([
                "attempt",
                "rates_feedback",
                rateResultId,
              ]) as unknown as RatesFeedbackDataMap) || emptyMap;

            value = lowRateResultData.get("pay_rate_min");
            feedbackValue = rateFeedbackData.get("pay_rate_min");
          } else if (dataOrderingKey === JOBS_ORDERING_KEYS.MAX_VALUE) {
            const highRateResultData = rateResults.last() || emptyMap;
            const rateResultId = highRateResultData.get("id");
            const rateFeedbackData =
              (reviewData?.getIn([
                "attempt",
                "rates_feedback",
                rateResultId,
              ]) as unknown as RatesFeedbackDataMap) || emptyMap;

            value = highRateResultData.get("pay_rate_max");
            feedbackValue = rateFeedbackData.get("pay_rate_max");
          }
        } else if (reviewType === REVIEW_TYPES.FILL_THE_BLANK) {
          const jobId = firstJobData.get("id");
          const jobFeedbackData =
            (reviewData?.getIn([
              "attempt",
              "jobs_feedback",
              jobId,
            ]) as unknown as JobFeedbackDataMap) || emptyMap;

          value = null;

          if (dataOrderingKey === JOBS_ORDERING_KEYS.MIN_VALUE) {
            feedbackValue = jobFeedbackData.get(
              rateType === RATE_TYPES.ANNUAL ? "annual_salary_min" : "pay_rate_min"
            );
          } else if (dataOrderingKey === JOBS_ORDERING_KEYS.MAX_VALUE) {
            feedbackValue = jobFeedbackData.get(
              rateType === RATE_TYPES.ANNUAL ? "annual_salary_max" : "pay_rate_max"
            );
          }
        }

        const resultValue = feedbackValue || value;
        return resultValue === null ? null : resultValue * (asc ? 1 : -1);
      }) as unknown as ReviewsDataOrderedMap;
    },
    []
  );

  return (
    <ReviewsPeckingOrderTableBase
      {...props}
      tableHead={tableHead}
      renderTableRow={renderTableRow}
      getAllReviewsOrdered={getAllReviewsOrdered}
    />
  );
}
ReviewsPeckingOrderTableWithFeedback.displayName = "ReviewsPeckingOrderTableWithFeedback";
