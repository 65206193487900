/**
 * @generated SignedSource<<abaef9638b16dac45581cc9a189172af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ClientFiltersInput = {
  activeFlag?: boolean | null;
  exclude?: ReadonlyArray<string | null> | null;
  nameIContains?: string | null;
  only?: ReadonlyArray<string | null> | null;
};
export type FilterAndSortClientsFetchAllClientsQuery$variables = {
  filters?: ClientFiltersInput | null;
};
export type FilterAndSortClientsFetchAllClientsQuery$data = {
  readonly viewer: {
    readonly allClients: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        } | null;
      } | null>;
    } | null;
  } | null;
};
export type FilterAndSortClientsFetchAllClientsQuery = {
  response: FilterAndSortClientsFetchAllClientsQuery$data;
  variables: FilterAndSortClientsFetchAllClientsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "filters",
            "variableName": "filters"
          }
        ],
        "concreteType": "ClientTypeNodeConnection",
        "kind": "LinkedField",
        "name": "allClients",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientTypeNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ClientTypeNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FilterAndSortClientsFetchAllClientsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FilterAndSortClientsFetchAllClientsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6fc905977cbbde334eb5e9b2c64f4d1a",
    "id": null,
    "metadata": {},
    "name": "FilterAndSortClientsFetchAllClientsQuery",
    "operationKind": "query",
    "text": "query FilterAndSortClientsFetchAllClientsQuery(\n  $filters: ClientFiltersInput\n) {\n  viewer {\n    allClients(filters: $filters) {\n      edges {\n        node {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "47b76b2ca35686bbefd23d44e716c157";

export default node;
