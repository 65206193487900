/**
 * @generated SignedSource<<cf4aaacb7a62b9f09656bc8d315f4cdc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ResultTabs_viewer$data = {
  readonly countries: ReadonlyArray<{
    readonly id: string;
    readonly isoCode: string;
    readonly locationId: number;
    readonly name: string;
  } | null> | null;
  readonly savedsearch: {
    readonly createdDate: string;
    readonly currency: {
      readonly iso: string;
    } | null;
    readonly id: string;
    readonly industry: {
      readonly id: string;
      readonly legacyId: number;
      readonly value: string;
    } | null;
    readonly job: {
      readonly jobTitle: string | null;
      readonly jobTitleId: number | null;
    };
    readonly locationId: number | null;
    readonly rateType: number | null;
    readonly region: {
      readonly regionId: number;
    } | null;
    readonly workerTypeId: number | null;
    readonly workerTypeName: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"BellCurveBillRates_savedsearch" | "MedianPayChart_savedsearch" | "RateDetail_savedsearch">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"PayDifference_viewer">;
  readonly " $fragmentType": "ResultTabs_viewer";
};
export type ResultTabs_viewer$key = {
  readonly " $data"?: ResultTabs_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ResultTabs_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locationId",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ResultTabs_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PayDifference_viewer"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CountryListNode",
      "kind": "LinkedField",
      "name": "countries",
      "plural": true,
      "selections": [
        {
          "kind": "RequiredField",
          "field": (v0/*: any*/),
          "action": "NONE",
          "path": "countries.id"
        },
        {
          "kind": "RequiredField",
          "field": (v1/*: any*/),
          "action": "NONE",
          "path": "countries.locationId"
        },
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isoCode",
            "storageKey": null
          },
          "action": "NONE",
          "path": "countries.isoCode"
        },
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          "action": "NONE",
          "path": "countries.name"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "searchId"
        }
      ],
      "concreteType": "SaveSearchNode",
      "kind": "LinkedField",
      "name": "savedsearch",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rateType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workerTypeId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workerTypeName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CurrencyNode",
          "kind": "LinkedField",
          "name": "currency",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "iso",
                "storageKey": null
              },
              "action": "NONE",
              "path": "savedsearch.currency.iso"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "NewRegionNode",
          "kind": "LinkedField",
          "name": "region",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "regionId",
                "storageKey": null
              },
              "action": "NONE",
              "path": "savedsearch.region.regionId"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "JobResponse",
          "kind": "LinkedField",
          "name": "job",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "jobTitleId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "jobTitle",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "IndustryNode",
          "kind": "LinkedField",
          "name": "industry",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "legacyId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MedianPayChart_savedsearch"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RateDetail_savedsearch"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BellCurveBillRates_savedsearch"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "3d79561873e27e12ac0608b9288322ce";

export default node;
