import R from "ramda";

/**
 * Reduces to the sum of all the values of a field.
 * `fieldValueGetter` is a function that takes a field and returns it's value or undefined.
 */
export default R.curryN(3, (fieldValueGetter, accumulator, field) => {
  const value = fieldValueGetter(field);
  return value === undefined || value === null ? accumulator : accumulator + value;
});
