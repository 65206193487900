import React from "react";
import debounce from "lodash/debounce";
import Highchart from "../../../../components/charts/Highchart";

type HiringDifficultyChartProps = {
  hiringDifficulty: number;
};

const getColorScheme = (hiringDifficulty: number) => {
  let colorScheme: string[] = [];
  if (hiringDifficulty! < 11) {
    colorScheme = [
      "#78edb1",
      "#e4e5e8",
      "#e4e5e8",
      "#e4e5e8",
      "#e4e5e8",
      "#e4e5e8",
      "#e4e5e8",
      "#e4e5e8",
    ];
  } else if (hiringDifficulty! < 26) {
    colorScheme = [
      "#78edb1",
      "#34cc73",
      "#e4e5e8",
      "#e4e5e8",
      "#e4e5e8",
      "#e4e5e8",
      "#e4e5e8",
      "#e4e5e8",
    ];
  } else if (hiringDifficulty! < 41) {
    colorScheme = [
      "#ffbb5b",
      "#ffbb5b",
      "#ffb837",
      "#e4e5e8",
      "#e4e5e8",
      "#e4e5e8",
      "#e4e5e8",
      "#e4e5e8",
    ];
  } else if (hiringDifficulty! < 51) {
    colorScheme = [
      "#ffbb5b",
      "#ffbb5b",
      "#ffb837",
      "#ffb837",
      "#e4e5e8",
      "#e4e5e8",
      "#e4e5e8",
      "#e4e5e8",
    ];
  } else if (hiringDifficulty! < 61) {
    colorScheme = [
      "#f57d61",
      "#f57d61",
      "#f57d61",
      "#f06752",
      "#f06752",
      "#e4e5e8",
      "#e4e5e8",
      "#e4e5e8",
    ];
  } else if (hiringDifficulty! < 76) {
    colorScheme = [
      "#f57d61",
      "#f57d61",
      "#f57d61",
      "#f06752",
      "#f06752",
      "#f06752",
      "#e4e5e8",
      "#e4e5e8",
    ];
  } else if (hiringDifficulty! < 92) {
    colorScheme = [
      "#f57d6a",
      "#f57d6a",
      "#f57d6a",
      "#f06752",
      "#f06752",
      "#f06752",
      "#ad4838",
      "#e4e5e8",
    ];
  } else if (hiringDifficulty! > 91) {
    colorScheme = [
      "#f57d6a",
      "#f57d6a",
      "#f57d6a",
      "#f06752",
      "#f06752",
      "#f06752",
      "#ad4838",
      "#ad4838",
    ];
  } else {
    colorScheme = [
      "#e4e5e8",
      "#e4e5e8",
      "#e4e5e8",
      "#e4e5e8",
      "#e4e5e8",
      "#e4e5e8",
      "#e4e5e8",
      "#e4e5e8",
    ];
  }
  return colorScheme;
};

const getChartOptions = (hiringDifficulty: number): Highcharts.Options => {
  return {
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    chart: {
      spacing: [10, 10, 10, 10],
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false,
        },
        startAngle: -90,
        endAngle: 90,
        center: ["50%", "50%"],
        size: "200%",
      },
      series: {
        enableMouseTracking: false,
      },
    },
    series: [
      {
        type: "pie",
        name: "",
        innerSize: "70%",
        colors: getColorScheme(hiringDifficulty),
        data: [
          ["", 12],
          ["", 12],
          ["", 12],
          ["", 12],
          ["", 12],
          ["", 12],
          ["", 12],
          ["", 12],
        ],
      },
    ],
  };
};

function HiringDifficultyChart(props: HiringDifficultyChartProps) {
  const { hiringDifficulty } = props;

  // key forces Highcharts component to re-render
  const [key, setNewKey] = React.useState<number>(0);
  const debouncedSetNewKey = debounce(() => setNewKey((oldKey) => oldKey + 1), 1000);

  React.useEffect(() => {
    window.addEventListener("resize", debouncedSetNewKey);
    return () => window.removeEventListener("resize", debouncedSetNewKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Highchart
      key={key}
      options={getChartOptions(hiringDifficulty)}
      style={{ height: "100px" }}
    />
  );
}

export default HiringDifficultyChart;
