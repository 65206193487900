// @flow

import React from "react";
import { observer } from "mobx-react";
import BuyRateState from "../../models/BuyRateState";
import RateSearchStore from "../../stores/mobx/RateSearchStore";
import PunchOutCard from "../searches/components/PunchOutCard";

const PunchOutsView = (props: { rateSearchStore: RateSearchStore }) => {
  const buyRates = props.rateSearchStore.searches.buyRates;
  return (
    <div>
      <div className="row">
        {buyRates &&
          buyRates.map((item) => (
            <PunchOutCard
              key={item.name}
              buyRateState={
                new BuyRateState(
                  props.rateSearchStore.fetchGraphQL,
                  props.rateSearchStore.fetchAPI,
                  props.rateSearchStore.searches,
                  item
                )
              }
            />
          ))}
        <PunchOutCard
          buyRateState={
            new BuyRateState(
              props.rateSearchStore.fetchGraphQL,
              props.rateSearchStore.fetchAPI,
              props.rateSearchStore.searches,
              null
            )
          }
        />
      </div>
    </div>
  );
};

export default observer(PunchOutsView);
