// @flow

import React from "react";
import { observer } from "mobx-react";
import { BatchSearchJLListStore } from "../../../stores/mobx/BatchSearchListStore";
import EditActions from "../../../components/lib/EditActions";
import Button from "../../../components/lib/Button";

const BatchSearchListEditActions = (props: { store: BatchSearchJLListStore }) => {
  const store = props.store;

  return (
    <EditActions hidden={store.isEditing === null} show={Boolean(store.isEditing)}>
      <Button
        color="danger"
        icon="trash-alt"
        disabled={store.selectedCount === 0}
        onClick={store.confirmDeleteModal.showModal}
      >
        Delete
      </Button>
    </EditActions>
  );
};

export default observer(BatchSearchListEditActions);
