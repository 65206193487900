// @flow

import R from "ramda";
import { extendObservable, action, runInAction, computed, observable } from "mobx";
import moment from "moment";
import Search from "../../models/Search";
import NetworkState from "../../models/NetworkState";
import PaginationState from "../../models/PaginationState";
import type { PageQuery, PaginationInfo } from "../../models/PaginationState";
import type { GraphQLQuery } from "../../models/GraphQL";
import {
  JobFilter,
  LocationFilterIContains,
  CreatedOnFilter,
} from "../../models/FilterState";
import IndustryFilter from "../../models/FilterState/IndustryFilter";
import LocationFilter, {
  LOCATION_FILTER_TYPE,
} from "../../models/FilterState/LocationFilter";
import type { FilterColumn } from "../../models/Filter";
import FilterObject, { FILTER_COLUMN } from "../../models/Filter";
import { SearchListStore } from "./interfaces/SearchListStore";
import {
  addIdToPayload,
  consolidateAppliedFilters,
  consolidateAppliedSorts,
} from "./SupportFunctions";
import Sort, { SORT_DIRECTION } from "../../models/Sort";
import ConvertCurrencyState from "../../models/ConvertCurrencyState";
import type { FetchGraphQL } from "../../App";

const jobLabelsFilterCriteriaQuery = `
  query {
    viewer {
      userUnattachedSearchesFilterCriteria {
        jobLabels {
          jobLabel
        }
      }
    }
  }
`;

const jobTitlesFilterCriteriaQuery = `
  query {
    viewer {
      userUnattachedSearchesFilterCriteria {
        jobTitles {
          jobTitle
        }
      }
    }
  }
`;

const industriesFilterCriteriaQuery = `
  query {
    viewer {
      userUnattachedSearchesFilterCriteria {
        industries {
          legacyId
          name
        }
      }
    }
  }
`;

const locationsFilterCriteriaQuery = `
  query {
    viewer {
      userUnattachedSearchesFilterCriteria {
        countries {
          country
        }
        states {
          state
        }
        cities {
          city
        }
        regions {
          region
        }
      }
    }
  }
`;

const rateTypesFilterCriteriaQuery = `
  query {
    viewer {
      userUnattachedSearchesFilterCriteria {
        rateTypes
      }
    }
  }
`;

export default class UnattachedSearchStore implements SearchListStore {
  network: NetworkState;
  pagination: PaginationState;
  allOnPageSelected: boolean;
  allSelected: boolean;
  allowMultipleItemSelection: boolean;
  isEditing: ?boolean;
  searches: Search[];
  searchesViewState: Object;
  searchesView: Search[];
  rateTypeFilter: RateTypeFilter;
  jobLabelFilter: JobLabelFilter;
  jobTitleFilter: JobTitleFilter;
  locationFilter: LocationFilter;
  locationSearchFilter: LocationFilterIContains;
  createdOnFilter: CreatedOnFilter;
  industryFilter: IndustryFilter;
  appliedFilters: { [key: FilterColumn]: FilterObject };
  appliedSorts: { [key: FilterColumn]: Sort };
  appliedSortsOrder: Array<FilterColumn>;
  isFiltered: boolean;

  // prettier-ignore
  getSearches: (PageQuery) => Promise<PaginationInfo>;
  // prettier-ignore
  toggleSelectAllPage: (Event) => void;
  selectAllPage: (Event) => void;
  deselectAllPage: (Event) => void;
  // prettier-ignore
  toggleSelected: (Search) => void;
  // prettier-ignore
  toggleExpanded: (Search) => void;
  toggleAllItems: () => void;
  clearAllSelections: () => void;
  applyFilter: (FilterColumn, FilterObject) => void;
  removeFilter: (FilterColumn) => void;
  applySort: (FilterColumn, Sort) => void;
  removeSort: (FilterColumn) => void;
  clearFilters: () => void;
  // prettier-ignore
  getFilterCriteriaQuery: (FilterColumn) => GraphQLQuery;
  processFilterCriteria: (FilterColumn, Object) => Array<Object>;
  fetchGraphQL: FetchGraphQL;

  constructor(fetchGraphQL: FetchGraphQL) {
    this.fetchGraphQL = fetchGraphQL;
    // NOTE: Bound early to pass into pagination & filter states
    this.getSearches = action(this.getSearches.bind(this));
    this.applyFilter = action(this.applyFilter.bind(this));
    this.applySort = action(this.applySort.bind(this));
    this.removeFilter = action(this.removeFilter.bind(this));
    this.removeSort = action(this.removeSort.bind(this));
    this.getFilterCriteriaQuery = action(this.getFilterCriteriaQuery.bind(this));
    this.processFilterCriteria = action(this.processFilterCriteria.bind(this));

    extendObservable(this, {
      network: new NetworkState(),
      pagination: new PaginationState(this.getSearches),
      isEditing: null,
      isFiltered: false,
      allowExpand: false,
      allowViewDetails: false,
      allowMultipleItemSelection: true,
      allOnPageSelected: computed(() => {
        const allTrue = R.all(R.equals(true));
        const selectedValues = this.searchesView.map((search) => {
          return search.viewState.selected;
        });

        if (selectedValues.length === 0) {
          return false;
        }

        return allTrue(selectedValues);
      }),
      allSelected: false,
      searches: [],
      searchesViewState: observable.map({}),
      searchesView: computed(() => {
        return this.searches.map((search) => {
          if (this.searchesViewState.has(search.id)) {
            search.viewState = this.searchesViewState.get(search.id);

            return search;
          }

          return search;
        });
      }),
      appliedFilters: {},
      appliedSorts: {},
      appliedSortsOrder: observable.shallow([]),
      jobFilter: new JobFilter(
        this,
        FILTER_COLUMN.JOB_LABEL,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),

      locationSearchFilter: new LocationFilterIContains(
        this,
        FILTER_COLUMN.LOCATION,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      createdOnFilter: new CreatedOnFilter(
        this,
        FILTER_COLUMN.DATE_RANGE,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      // Convert Currency modal
      convertCurrencyState: new ConvertCurrencyState(this),
    });

    this.toggleSelectAllPage = action(this.toggleSelectAllPage.bind(this));
    this.selectAllPage = action(this.selectAllPage.bind(this));
    this.deselectAllPage = action(this.deselectAllPage.bind(this));
    this.toggleSelected = action(this.toggleSelected.bind(this));
    this.toggleExpanded = action(this.toggleExpanded.bind(this));
    this.toggleAllItems = action(this.toggleAllItems.bind(this));
    this.clearAllSelections = action(this.clearAllSelections.bind(this));
    this.clearFilters = action(this.clearFilters.bind(this));
    this.applyDefaultSort = action(this.applyDefaultSort.bind(this));
    this.applyDefaultSort();
  }

  getFilterCriteriaQuery(column: FilterColumn): GraphQLQuery {
    switch (column) {
      case FILTER_COLUMN.JOB_LABEL:
        return { query: jobLabelsFilterCriteriaQuery, variables: null };

      case FILTER_COLUMN.RATE_TYPE:
        return { query: rateTypesFilterCriteriaQuery, variables: null };

      case FILTER_COLUMN.JOB_TITLE:
        return { query: jobTitlesFilterCriteriaQuery, variables: null };

      case FILTER_COLUMN.INDUSTRY:
        return { query: industriesFilterCriteriaQuery, variables: null };

      case FILTER_COLUMN.LOCATION:
        return { query: locationsFilterCriteriaQuery, variables: null };

      default:
        break;
    }
  }

  processFilterCriteria(column: FilterColumn, payload: Object): Array<Object> {
    switch (column) {
      case FILTER_COLUMN.JOB_LABEL:
        const jobLabels: [{ jobLabel: String }] =
          payload.data.viewer.userUnattachedSearchesFilterCriteria.jobLabels;
        return addIdToPayload(jobLabels);

      case FILTER_COLUMN.RATE_TYPE:
        const rateTypes: [String] =
          payload.data.viewer.userUnattachedSearchesFilterCriteria.rateTypes;
        return addIdToPayload(rateTypes);

      case FILTER_COLUMN.JOB_TITLE:
        const jobTitles: [{ jobTitle: String }] =
          payload.data.viewer.userUnattachedSearchesFilterCriteria.jobTitles;
        return addIdToPayload(jobTitles);

      case FILTER_COLUMN.INDUSTRY:
        const industries: [{ name: String, legacyId: String }] =
          payload.data.viewer.userUnattachedSearchesFilterCriteria.industries;

        let processedIndustries = observable.map({});
        industries.forEach((industry) => {
          processedIndustries.set(String(industry.legacyId), {
            id: String(industry.legacyId),
            ...industry,
          });
        });

        return processedIndustries;

      case FILTER_COLUMN.LOCATION:
        const regions: [{ region: String }] =
          payload.data.viewer.userUnattachedSearchesFilterCriteria.regions;

        const countries: [{ country: String }] =
          payload.data.viewer.userUnattachedSearchesFilterCriteria.countries;

        const states: [{ state: String }] =
          payload.data.viewer.userUnattachedSearchesFilterCriteria.states;

        const cities: [{ city: String }] =
          payload.data.viewer.userUnattachedSearchesFilterCriteria.cities;

        let processedLocations = observable.map({});
        regions.forEach((item, i) => {
          processedLocations.set(LOCATION_FILTER_TYPE.REGION + String(i), {
            id: LOCATION_FILTER_TYPE.REGION + String(i),
            type: LOCATION_FILTER_TYPE.REGION,
            location: item.region,
          });
        });

        countries.forEach((item, i) => {
          processedLocations.set(LOCATION_FILTER_TYPE.COUNTRY + String(i), {
            id: LOCATION_FILTER_TYPE.COUNTRY + String(i),
            type: LOCATION_FILTER_TYPE.COUNTRY,
            location: item.country,
          });
        });

        states.forEach((item, i) => {
          processedLocations.set(LOCATION_FILTER_TYPE.STATE + String(i), {
            id: LOCATION_FILTER_TYPE.STATE + String(i),
            type: LOCATION_FILTER_TYPE.STATE,
            location: item.state,
          });
        });

        cities.forEach((item, i) => {
          processedLocations.set(LOCATION_FILTER_TYPE.CITY + String(i), {
            id: LOCATION_FILTER_TYPE.CITY + String(i),
            type: LOCATION_FILTER_TYPE.CITY,
            location: item.city,
          });
        });

        return processedLocations;

      default:
        return [];
    }
  }

  applyFilter(column: FilterColumn, filter: FilterObject) {
    this.appliedFilters[column] = filter;
    this.isFiltered = true;
  }

  removeFilter(column: FilterColumn) {
    delete this.appliedFilters[column];

    if (!Object.entries(this.appliedFilters).length) this.isFiltered = false;
  }

  applySort(column: FilterColumn, sort: Sort) {
    this.appliedSorts[column] = sort;

    const index = this.appliedSortsOrder.indexOf(column);
    if (index === -1) this.appliedSortsOrder.push(column);
  }
  applyDefaultSort() {
    this.createdOnFilter.sortState.direction = SORT_DIRECTION.DESC;
    this.createdOnFilter.sort = this.createdOnFilter.buildQuerySort();
    this.applySort(this.createdOnFilter.column, this.createdOnFilter.sort);
  }

  removeSort(column: FilterColumn) {
    delete this.appliedSorts[column];

    const index = this.appliedSortsOrder.indexOf(column);
    if (index > -1) this.appliedSortsOrder.splice(index, 1);
  }

  clearFilters() {
    this.jobFilter = new JobFilter(
      this,
      FILTER_COLUMN.JOB_LABEL,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );

    this.locationSearchFilter = new LocationFilterIContains(
      this,
      FILTER_COLUMN.LOCATION,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.createdOnFilter = new CreatedOnFilter(
      this,
      FILTER_COLUMN.DATE_RANGE,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );

    this.appliedFilters = observable({});
    this.appliedSorts = observable({});
    this.appliedSortsOrder.length = 0;
    this.isFiltered = false;

    return this.pagination.goFetch(null);
  }

  toggleSelected(search: Search) {
    const viewState = this.searchesViewState.get(search.id);

    viewState.selected = !viewState.selected;

    if (viewState.selected === false && this.allSelected) {
      this.allSelected = false;
    }

    if (!this.allowMultipleItemSelection) {
      // deselect all other rate cards
      this.searchesViewState.forEach((currentViewState) => {
        if (currentViewState === viewState) return;

        currentViewState.selected = false;
      });
    }
  }

  toggleExpanded(search: Search) {
    search.viewState.expanded = true;
  }

  toggleSelectAllPage(e: Event) {
    if (!this.allowMultipleItemSelection) return;

    const setValue = !this.allOnPageSelected;

    this.searchesView.forEach((search) => {
      search.viewState.selected = setValue;
    });
  }

  selectAllPage(e: Event) {
    if (!this.allowMultipleItemSelection) return;

    this.searchesView.forEach((search) => {
      search.viewState.selected = true;
    });
  }

  deselectAllPage(e: Event) {
    this.searchesView.forEach((search) => {
      search.viewState.selected = false;
    });

    this.allSelected = false;
  }

  toggleAllItems() {
    if (!this.allowMultipleItemSelection) return;

    this.allSelected = !this.allSelected;

    if (this.allSelected === false) {
      this.searchesViewState.forEach((value) => {
        value.selected = false;
      });
    }
  }

  clearAllSelections() {
    this.allSelected = false;
    this.searchesViewState.forEach((value) => {
      value.selected = false;
    });
  }

  getSelectedSearches() {
    const searches = this.searchesViewState;

    let selectedSearches = [];

    searches.forEach((value, key) => {
      if (value.selected) {
        selectedSearches.push(key);
      }
    });

    return selectedSearches;
  }

  async getSearches(pageQuery: PageQuery): Promise<PaginationInfo> {
    let params: Array<any> = pageQuery.params;
    let args: { [id: string]: string[] } = {
      filters: [],
      searches: pageQuery.args,
    };

    let variables = pageQuery.variables;
    let filtersCriteria: string[] = [];
    let filters: { [id: string]: Array<any> } = {
      filters: [],
      searches: [],
    };

    let orders = [];

    consolidateAppliedSorts(this.appliedSorts, orders);

    consolidateAppliedFilters(this.appliedFilters, params, filtersCriteria, variables);

    Object.keys(filters).forEach((filter) => {
      if (filters[filter].length > 0) {
        args[filter].push(`filters: { ${filters[filter].join(", ")}}`);
      }
    });

    if (orders.length > 0) {
      args.searches.push(`order: [${orders.join(", ")}]`);
    }

    params = params.join(",");
    args = args.filters.concat(args.searches).join(", ");
    const queryFiltersCriteria = filtersCriteria.join(", ");

    const query = `
      query rateCardList (${params}) {
        viewer {
          savedsearches(offset: $offset, first: $first, filters: {isDraft: true, ${queryFiltersCriteria}}) {
            edges {
              node {
                rateType
                searchId
                job {
                  jobLabel
                  jobTitle
                }
                city
                country
                state
                createdDate
                industry {
                  legacyId
                  value
                }
              }
            }
            totalCount
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
          }
        }
      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      // TODO: Handle Request cancellation
      this.network.handleError("Getting Unattached Searches", e);
      // TODO: Display user friendly error message
      return { totalCount: 0, startCursor: "", endCursor: "" };
    }

    runInAction("getSearches--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get Unattached Searches query", res)) {
        // TODO: Display user friendly error message
        return { totalCount: 0, startCursor: "", endCursor: "" };
      }

      if (!res) return { totalCount: 0, startCursor: "", endCursor: "" };

      const searches = res.data.viewer.savedsearches.edges;
      const searchesFilterCriteria: Object =
        res.data.viewer.userUnattachedSearchesFilterCriteria;
      if (searchesFilterCriteria) this.createFilterCriteria(searchesFilterCriteria);
      this.searches = searches.map((edge) => {
        const search = new Search(this, edge.node);

        search.title = edge.node.job.jobTitle;

        // NOTE: When a search has no job label, signal that
        if (edge.node.jobLabel === "") {
          search.label = "Job Label Not Available";
        } else {
          search.label = edge.node.jobLabel;
        }

        search.location = {
          country: edge.node.country,
          state: edge.node.state,
          city: edge.node.city,
        };
        // search.industry = {
        //   title: edge.node.industryName
        // };
        search.frequency = edge.node.rateType;
        search.created = moment(edge.node.createdDate);
        search.createdDisplay = `Created ${search.created.format("MMMM D, YYYY")}`;
        //search.region = edge.node.region;

        if (!this.searchesViewState.has(search.id)) {
          this.searchesViewState.set(search.id, {
            selected: this.allSelected,
            editing: true,
          });
        } else {
          const selectedValue = this.allSelected
            ? true
            : this.searchesViewState.get(search.id).selected;

          this.searchesViewState.set(search.id, {
            selected: selectedValue,
            editing: true,
          });
        }

        return search;
      });
    });

    return {
      totalCount: res.data.viewer.savedsearches.totalCount,
      startCursor: res.data.viewer.savedsearches.pageInfo.startCursor,
      endCursor: res.data.viewer.savedsearches.pageInfo.endCursor,
    };
  }
}
