/**
 * @generated SignedSource<<3952cdea31673d9908b6470a8e14c3d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NavBar2NotificationsDropdown_notifications$data = {
  readonly notifications: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly contentObject: {
          readonly contentId: number | null;
          readonly notificationType: string | null;
        } | null;
        readonly created: string;
        readonly id: string;
        readonly isRead: boolean;
        readonly message: string;
      } | null;
    } | null>;
    readonly unreadNotificationCount: number | null;
  } | null;
  readonly " $fragmentType": "NavBar2NotificationsDropdown_notifications";
};
export type NavBar2NotificationsDropdown_notifications$key = {
  readonly " $data"?: NavBar2NotificationsDropdown_notifications$data;
  readonly " $fragmentSpreads": FragmentRefs<"NavBar2NotificationsDropdown_notifications">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "viewer"
      ],
      "operation": require('./NavBar2NotificationsRefetchQuery.graphql')
    }
  },
  "name": "NavBar2NotificationsDropdown_notifications",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 10
        }
      ],
      "concreteType": "NotificationNodeConnection",
      "kind": "LinkedField",
      "name": "notifications",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unreadNotificationCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "NotificationNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "NotificationNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "created",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isRead",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "message",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "NotificationContentObjectNode",
                  "kind": "LinkedField",
                  "name": "contentObject",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "notificationType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "contentId",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "notifications(first:10)"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "69cb85d85a2b8fa73a74c0397c5a03bd";

export default node;
