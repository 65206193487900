/**
 * @generated SignedSource<<1a0433202305a2e660be53cc979356a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DetailCards_savedsearch$data = {
  readonly currency: {
    readonly id: string;
    readonly iso: string;
  } | null;
  readonly id: string;
  readonly marketrates: ReadonlyArray<{
    readonly billRateAvg: number | null;
    readonly billRateMax: number | null;
    readonly billRateMid: number | null;
    readonly billRateMin: number | null;
    readonly id: string;
    readonly markupPctAvg: number | null;
    readonly markupPctMax: number | null;
    readonly markupPctMid: number | null;
    readonly markupPctMin: number | null;
    readonly payRateAvg: number | null;
    readonly payRateMax: number | null;
    readonly payRateMid: number | null;
    readonly payRateMin: number | null;
  } | null> | null;
  readonly rateType: number;
  readonly " $fragmentType": "DetailCards_savedsearch";
} | null;
export type DetailCards_savedsearch$key = {
  readonly " $data"?: DetailCards_savedsearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"DetailCards_savedsearch">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DetailCards_savedsearch",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rateType",
        "storageKey": null
      },
      "action": "NONE",
      "path": "rateType"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CurrencyNode",
      "kind": "LinkedField",
      "name": "currency",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "iso",
            "storageKey": null
          },
          "action": "NONE",
          "path": "currency.iso"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MarketRatesNode",
      "kind": "LinkedField",
      "name": "marketrates",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "payRateMin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "payRateMid",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "payRateMax",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "payRateAvg",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "billRateMin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "billRateMid",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "billRateMax",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "billRateAvg",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "markupPctMin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "markupPctMid",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "markupPctMax",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "markupPctAvg",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SaveSearchNode",
  "abstractKey": null
};
})();

(node as any).hash = "1f07c11da59e18388d408212d00954fe";

export default node;
