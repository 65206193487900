// @ts-ignore
import { styled } from "../../stitches.config";
import React from "react";
import Stack from "../../components/lib/Stack";
import Box from "../../components/lib/Box";

const TableHead = styled("span", {
  color: "$brand",
  fontSize: "16px",
  display: "flex",
  width: "100%",
  fontWeight: "600",
});

const TableContent = styled(Box, {
  width: "100%",
  border: "1px solid $accentLight",
  display: "flex",
  padding: "10px",
  marginBottom: "-17px",
  fontSize: "16px",
});

export function QsBrowserRequirements(props: any) {
  return (
    <Box css={{ a: { color: "$brandDark !important" }, padding: "30px" }}>
      <Stack
        css={{
          alignItems: "start",
          padding: "40px",
          color: "#7a7a7a",
          marginBottom: "30px",
        }}
      >
        <p>
          Magnit supports the following browsers. If you are not using one of these
          browsers (or versions) to interact with our website, please download or upgrade
          to a new browser or supported version by contacting your IT department. In
          addition, it is required that you have JavaScript and Cookies enabled. Turn off
          Compatibility View mode (if you are using Internet Explorer) for an optimal
          experience.
        </p>

        <TableHead>
          <Box css={{ width: "50%" }}>Web Browser</Box>
          <Box css={{ width: "50%" }}>Version</Box>
        </TableHead>

        <TableContent>
          <Box css={{ width: "50%" }}>Microsoft Edge</Box>
          <Box>latest version</Box>
        </TableContent>
        <TableContent>
          <Box css={{ width: "50%" }}>Mozilla Firefox</Box>
          <Box>latest version</Box>
        </TableContent>
        <TableContent>
          <Box css={{ width: "50%" }}>Apple Safari</Box>
          <Box>latest version</Box>
        </TableContent>
        <TableContent>
          <Box css={{ width: "50%" }}>Google Chrome</Box>
          <Box>latest version</Box>
        </TableContent>

        <p></p>

        <p>
          PLEASE NOTE: To provide our customers the most effective and secure online
          experience, Magnit is continually upgrading Pay Intel. As we add new features
          and enhancements to our service, older browser versions may not support these
          new standards. Upgrading to the latest version will enhance your experience on
          our website. In addition, many older versions of web browsers contain known
          defects and security vulnerabilities. Upgrading to the latest browser versions
          will help protect against security risks.
        </p>
      </Stack>
    </Box>
  );
}

export default QsBrowserRequirements;
