// @flow

import React, { Component } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilterOptionProps from "./supportClasses/FilterOptionProps";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../components/lib/DropdownMenu";
import Button from "../../components/lib/Button";
import { styled } from "../../../src/stitches.config";

const MenuTrigger = styled(Button, {
  margin: "4px",
  padding: "0 10px",
});

const MenuContent = styled(DropdownMenuContent, {
  minWidth: "160px",
  padding: "2px 0px",
});

const ENTER_KEY = 13;

type Props = {
  onSearch?: (SyntheticEvent<HTMLButtonElement> | string) => void,
  onChange?: (string) => void,
  onClear?: ?() => void,
  value?: string,
  placeholder: string,
  selectedFilterOption?: FilterOptionProps,
  filterOptions?: FilterOptionProps[],
  className?: string | string[],
  disabled?: boolean,
  style?: Object,
};

type State = {
  boxOpen: true,
};

const LibrarySearch = observer(
  class extends Component<Props, State> {
    props: Props;
    state: State;

    handleClearOnClick = (e: SyntheticEvent<HTMLButtonElement>) => {
      if (this.props.onClear) this.props.onClear();
    };

    // TODO: Fix the bindings on these
    handleSearchToggle = (e: SyntheticEvent<HTMLButtonElement>) => {
      if (this.state.boxOpen === false) {
        this.setState({
          boxOpen: true,
        });

        return;
      }

      const { onSearch } = this.props;

      if (onSearch) {
        onSearch(e);
      }
    };

    handleSearchKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
      if (e.keyCode !== ENTER_KEY) {
        return;
      }

      const onSearch = this.props.onSearch;

      if (onSearch) {
        onSearch(this.props.value);
      }
    };

    handleSearchOnClick = (e: SyntheticMouseEvent<HTMLButtonElement>) => {
      const onSearch = this.props.onSearch;

      if (onSearch) {
        onSearch(this.props.value);
      }
    };

    handleOnChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
      const onChange = this.props.onChange;

      if (onChange) {
        onChange(e.currentTarget.value);
      }
    };

    render() {
      return (
        <div
          className={classNames("cjl-search", this.props.className)}
          style={this.props.style}
        >
          <div className="cjl-search-input-container">
            {this.props.onClear ? (
              <FontAwesomeIcon
                icon="times"
                fixedWidth
                className="cjl-search-clear-btn"
                onClick={this.handleClearOnClick}
              />
            ) : (
              <FontAwesomeIcon icon="search" className="cjl-search-icon" />
            )}
            <input
              type="text"
              name="search-box"
              className="cjl-search-input"
              placeholder={this.props.placeholder}
              value={this.props.value}
              onChange={this.handleOnChange}
              onKeyDown={this.handleSearchKeyDown}
              disabled={this.props.disabled}
            />

            {this.props.filterOptions && this.props.selectedFilterOption && (
              <DropdownMenu id="dropdown-search-filter-menu">
                <DropdownMenuTrigger asChild>
                  <MenuTrigger color="brand" iconRight="caret-down" size="extra-small">
                    {this.props.selectedFilterOption.label}
                  </MenuTrigger>
                </DropdownMenuTrigger>
                <MenuContent side="bottom" align="start">
                  {this.props.filterOptions.map((filterOptionProps) => (
                    <DropdownMenuItem
                      key={filterOptionProps.key}
                      onSelect={filterOptionProps.onSelect}
                    >
                      {filterOptionProps.label}
                    </DropdownMenuItem>
                  ))}
                </MenuContent>
              </DropdownMenu>
            )}
          </div>

          <button className="cjl-search-btn" onClick={this.handleSearchOnClick}>
            Search
          </button>
        </div>
      );
    }
  }
);

export default LibrarySearch;
