import React from "react";
import { CheckedState } from "@radix-ui/react-checkbox";

import Icon from "../../../components/lib/Icon";
import Inline from "../../../components/lib/Inline";
import Stack from "../../../components/lib/Stack";
import Text from "../../../components/lib/Text";
import Box from "../../../components/lib/Box";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogClose,
  DialogDescription,
  DialogActions,
} from "../lib/Dialog";
import {
  Checkbox,
  CheckboxIndicator,
  LabelInline,
} from "../../../components/lib/Checkbox";
import Button from "../../../components/lib/Button";
import { ButtonGroupRight } from "../../../components/lib/ButtonGroup";

import { PROCESSING_TYPES } from "../types";
import { dateTimeFormatter } from "../constants";

import type { ProcessingDataMap, ContractorsUploadDataMap } from "../types";
import PromiseButton from "../../../components/lib/PromiseButton";

type UseBooleanStateHook = () => [
  boolean, // state value
  () => void, // reset state
  (value: CheckedState) => void // state change handler
];

const useBooleanState: UseBooleanStateHook = () => {
  const [state, setState] = React.useState<boolean>(false);
  const resetState = React.useCallback(() => setState(false), []);
  const handleCheckboxStateChange = React.useCallback(
    (value: CheckedState) => {
      setState(value as boolean);
    },
    [setState]
  );

  return [state, resetState, handleCheckboxStateChange];
};

interface RerunRowModalProps {
  show: boolean;
  onHide: () => void;
  onRerun: (includeRemapping: boolean) => Promise<any>;
}

export const RerunRowModal = (props: RerunRowModalProps) => {
  const { show, onHide, onRerun } = props;

  const [includeRemapping, resetIncludeRemapping, handleIncludeRemappingChange] =
    useBooleanState();

  const handleRerunRow = React.useCallback(
    () => onRerun(includeRemapping),
    [includeRemapping, onRerun]
  );

  React.useEffect(() => {
    if (!show) resetIncludeRemapping();
  }, [show, resetIncludeRemapping]);

  return (
    <Dialog open={show} onOpenChange={onHide}>
      <DialogContent>
        <DialogTitle asChild>
          <Inline css={{ justifyContent: "space-between" }}>
            <Box>
              <h4>Rerun The Row</h4>
            </Box>
            <DialogClose asChild>
              <Icon icon="times" />
            </DialogClose>
          </Inline>
        </DialogTitle>
        <DialogDescription asChild>
          <Stack css={{ alignItems: "flex-start" }}>
            <span>
              You are about to rerun the row. This procedure will rerun market search for
              the row, and optionally can make data remapping.
            </span>
            <LabelInline bold={false}>
              <Checkbox
                checked={includeRemapping}
                onCheckedChange={handleIncludeRemappingChange}
              >
                <CheckboxIndicator>
                  <Icon icon="check" />
                </CheckboxIndicator>
              </Checkbox>
              Perform remapping.
            </LabelInline>
          </Stack>
        </DialogDescription>
        <DialogActions>
          <ButtonGroupRight fill>
            <PromiseButton
              color="brand"
              size="large"
              loadingText="Rerun The Row"
              onClick={handleRerunRow}
            >
              Yes, Rerun The Row
            </PromiseButton>
            <Button size="large" onClick={onHide}>
              Close
            </Button>
          </ButtonGroupRight>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

RerunRowModal.displayName = "RerunRowModal";

export interface RerunIndexModalProps {
  email: string;
  show: boolean;
  itemsTotal: number;
  itemsOnPage: number;
  onRerun: (
    currentPageOnly: boolean,
    includeRemapping: boolean,
    informOnEmail: string | null
  ) => Promise<any>;
  onSeeProcessings: () => void;
  onHide: () => void;
}

export const RerunIndexModal = (props: RerunIndexModalProps) => {
  const { email, show, itemsTotal, itemsOnPage, onRerun, onSeeProcessings, onHide } =
    props;

  // state

  const [sendEmail, resetSendEmail, handleSendEmailChange] = useBooleanState();
  const [includeRemapping, resetIncludeRemapping, handleIncludeRemappingChange] =
    useBooleanState();

  // handlers

  const handleRerunWholeIndex = React.useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      await onRerun(false, includeRemapping, sendEmail ? email : null);
    },
    [onRerun, includeRemapping, sendEmail, email]
  );

  const handleRerunCurrentPage = React.useCallback(
    async (event) => {
      event.preventDefault();
      await onRerun(true, includeRemapping, sendEmail ? email : null);
    },
    [onRerun, includeRemapping, sendEmail, email]
  );
  // effects

  React.useEffect(() => {
    if (!show) {
      resetSendEmail();
      resetIncludeRemapping();
    }
  }, [show, resetSendEmail, resetIncludeRemapping]);

  return (
    <Dialog open={show} onOpenChange={onHide}>
      <DialogContent>
        <DialogTitle asChild>
          <Inline css={{ justifyContent: "space-between" }}>
            <Box>
              <h4>Rerun Index</h4>
            </Box>
            <DialogClose asChild>
              <Icon icon="times" />
            </DialogClose>
          </Inline>
        </DialogTitle>
        <DialogDescription asChild>
          <Stack css={{ alignItems: "flex-start" }}>
            <Box>
              You are about to rerun an index. This procedure will remap all uploaded job
              titles (optional), and rerun market comparison for them. Big indexes could
              take time to process whole data. Use table filters in order to reduce the
              amount of data to process.
            </Box>

            <Stack css={{ alignItems: "stretch", gap: "$2" }}>
              <PromiseButton
                color="brand"
                size="small"
                variant="outlined"
                loadingText={`Rerun whole index${
                  itemsTotal ? ` (${itemsTotal} rows)` : ""
                }`}
                onClick={handleRerunWholeIndex}
              >
                {`Rerun whole index${itemsTotal ? ` (${itemsTotal} rows)` : ""}`}
              </PromiseButton>

              {itemsTotal > itemsOnPage && (
                <PromiseButton
                  color="brand"
                  size="small"
                  variant="outlined"
                  loadingText={`Rerun current page only${
                    itemsOnPage ? ` (${itemsOnPage} rows)` : ""
                  }`}
                  onClick={handleRerunCurrentPage}
                >
                  {`Rerun current page only${
                    itemsOnPage ? ` (${itemsOnPage} rows)` : ""
                  }`}
                </PromiseButton>
              )}
            </Stack>

            <Stack css={{ alignItems: "flex-start", gap: "$0_5" }}>
              <LabelInline bold={false}>
                <Checkbox
                  checked={includeRemapping}
                  onCheckedChange={handleIncludeRemappingChange}
                >
                  <CheckboxIndicator>
                    <Icon icon="check" />
                  </CheckboxIndicator>
                </Checkbox>
                Perform remapping.
              </LabelInline>

              {email && (
                <LabelInline bold={false}>
                  <Checkbox checked={sendEmail} onCheckedChange={handleSendEmailChange}>
                    <CheckboxIndicator>
                      <Icon icon="check" />
                    </CheckboxIndicator>
                  </Checkbox>
                  Send me an email when it's done.
                </LabelInline>
              )}
            </Stack>
          </Stack>
        </DialogDescription>
        <DialogActions>
          <ButtonGroupRight fill>
            <Button color="brand" size="large" onClick={onSeeProcessings}>
              See All Rerun Requests
            </Button>
            <Button size="large" onClick={onHide}>
              Close
            </Button>
          </ButtonGroupRight>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

RerunIndexModal.displayName = "RerunIndexModal";

interface RerunProcessingModalProps {
  show: boolean;
  processingData: ProcessingDataMap | null;
  onRerun: (row: ProcessingDataMap, includeRemapping: boolean) => Promise<any>;
  onHide: () => void;
}

export const RerunProcessingModal = (props: RerunProcessingModalProps) => {
  const { show, processingData, onRerun, onHide } = props;

  // state

  const [includeRemapping, resetIncludeRemapping, handleIncludeRemappingChange] =
    useBooleanState();

  // handlers

  const handleRerunProcessing = React.useCallback(async () => {
    await onRerun(processingData!, includeRemapping);
  }, [processingData, includeRemapping, onRerun]);

  // effects

  React.useEffect(() => {
    if (!show) {
      resetIncludeRemapping();
    }
  }, [show, resetIncludeRemapping]);

  if (!processingData || !processingData.size) {
    return null;
  }

  const id = processingData.get("id");
  const type = processingData.get("type");
  const startedAt = processingData.get("started_at");
  const totalItems = processingData.get("total_items");

  return (
    <Dialog open={show} onOpenChange={onHide}>
      <DialogContent>
        <DialogTitle asChild>
          <Inline css={{ justifyContent: "space-between" }}>
            <Box>
              <h4>Confirm Rerun Action</h4>
            </Box>
            <DialogClose asChild>
              <Icon icon="times" />
            </DialogClose>
          </Inline>
        </DialogTitle>
        <DialogDescription asChild>
          <Stack css={{ alignItems: "flex-start" }}>
            <span>
              You are about to rerun following processing. Please confirm this action in
              order to proceed.
            </span>
            <Text bold>{`- #${id}, ${totalItems} rows (started at ${dateTimeFormatter(
              startedAt
            )})`}</Text>
            {(type === PROCESSING_TYPES.CONTRACTORS ||
              type === PROCESSING_TYPES.CONTRACTORS_UPLOAD) && (
              <LabelInline bold={false}>
                <Checkbox
                  checked={includeRemapping}
                  onCheckedChange={handleIncludeRemappingChange}
                >
                  <CheckboxIndicator>
                    <Icon icon="check" />
                  </CheckboxIndicator>
                </Checkbox>
                Perform remapping.
              </LabelInline>
            )}
          </Stack>
        </DialogDescription>
        <DialogActions>
          <ButtonGroupRight fill>
            <PromiseButton
              icon="sync-alt"
              color="brand"
              size="large"
              loadingText="Rerun"
              onClick={handleRerunProcessing}
            >
              Yes, Rerun
            </PromiseButton>
            <Button size="large" onClick={onHide}>
              Close
            </Button>
          </ButtonGroupRight>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

RerunProcessingModal.displayName = "RerunProcessingModal";

interface RerunUploadModalProps {
  show: boolean;
  uploadData: ContractorsUploadDataMap | null;
  onRerun: (
    upload: ContractorsUploadDataMap,
    includeRemapping?: boolean
  ) => Promise<void>;
  onHide: () => void;
}

export const RerunUploadModal = (props: RerunUploadModalProps) => {
  const { show, uploadData, onHide, onRerun } = props;

  // state

  const [includeRemapping, resetIncludeRemapping, handleIncludeRemappingChange] =
    useBooleanState();

  // handlers

  const handleRerunUpload = React.useCallback(async () => {
    await onRerun(uploadData!, includeRemapping);
  }, [uploadData, includeRemapping, onRerun]);

  // effects

  React.useEffect(() => {
    if (!show) {
      resetIncludeRemapping();
    }
  }, [show, resetIncludeRemapping]);

  if (!uploadData || !uploadData.size) {
    return null;
  }

  const id = uploadData.get("id");
  const title = uploadData.get("title");
  const datasetLength = uploadData.get("dataset_length");

  return (
    <Dialog open={show} onOpenChange={onHide}>
      <DialogContent>
        <DialogTitle asChild>
          <Inline css={{ justifyContent: "space-between" }}>
            <Box>
              <h4>Confirm Rerun Upload Action</h4>
            </Box>
            <DialogClose asChild>
              <Icon icon="times" />
            </DialogClose>
          </Inline>
        </DialogTitle>
        <DialogDescription asChild>
          <Stack css={{ alignItems: "flex-start" }}>
            <span>
              You are about to rerun following upload. Please confirm this action in order
              to proceed.
            </span>
            <Text bold>{`- #${id} ${title} (${datasetLength} rows)`}</Text>
            <LabelInline bold={false}>
              <Checkbox
                checked={includeRemapping}
                onCheckedChange={handleIncludeRemappingChange}
              >
                <CheckboxIndicator>
                  <Icon icon="check" />
                </CheckboxIndicator>
              </Checkbox>
              Perform remapping.
            </LabelInline>
          </Stack>
        </DialogDescription>
        <DialogActions>
          <ButtonGroupRight fill>
            <PromiseButton
              icon="sync-alt"
              color="brand"
              size="large"
              loadingText="Rerun"
              onClick={handleRerunUpload}
            >
              Yes, Rerun
            </PromiseButton>
            <Button size="large" onClick={onHide}>
              Close
            </Button>
          </ButtonGroupRight>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

RerunUploadModal.displayName = "RerunUploadModal";
