import React, { useCallback, useImperativeHandle } from "react";
import { useRecoilState } from "recoil";

import Text from "../../../components/lib/Text";
import Box from "../../../components/lib/Box";
import Button, { IconButton } from "../../../components/lib/Button";
import { ButtonGroupRight } from "../../../components/lib/ButtonGroup";
import TextCrop from "../../validator5K_admin/components/TextCrop";
import {
  FilterTypes,
  djangoPaginationKey,
  djangoPaginationSizeKey,
  emptyMap,
  emptyList,
} from "../../../components/tables/constants";
import ValidatorPermissionsEditor from "./ValidatorPermissionsEditor";
import { TableFilterableEditableRestful } from "./TableFilterableRestful";
import { Column } from "../../../components/tables";
import { useTableSchemaState } from "../../../components/tables/Table";
import { permissionsTableGlobalState } from "../globalState";
import { dedupeCSVString } from "../constants";
import { transformPermissionsData } from "../dataConverters";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";
import { useInitialFetch } from "../../../utils/hooks";
import { TickerContentLoader } from "../../../components/lib/TickerLoader";
import { renderTableColumnsSpecs } from "../../private_index/components/ExtendedRestfulTables";

import type { FetchAPIResponse } from "../../../types/fetch";
import type { DjangoPaginatedResponse } from "../../../types/django";
import type { TableFilterableEditableRestfulProps } from "../../../components/tables/TableFilterableRestful";
import type {
  TableColumnSpecsObject,
  PermissionDataMap,
  PermissionDataObject,
} from "../types";
import {
  RowEditorComponent,
  RestfulTableDataStateObject,
} from "../../../components/tables/types";
import PromiseButton from "../../../components/lib/PromiseButton";
import { rowIdGetter } from "../../../components/tables/utils";
import { useEditingRowState } from "../../../components/tables/hooks";
import { useVal5KAdminContext } from "../context/Val5KAdminContext";

export const permissionsTableSpecs: TableColumnSpecsObject<PermissionDataMap>[] = [
  {
    type: "column",
    uniqueKey: "email",
    title: "Email",
    getter: (row) => row.get("email"),
    formatter: (value) => (
      <TextCrop
        mode="tooltip"
        title="Email"
        text={dedupeCSVString(value) ?? ""}
        emptyStub=""
      />
    ),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "first_name",
    title: "First Name",
    getter: (row) => row.get("first_name"),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "last_name",
    title: "Last Name",
    getter: (row) => row.get("last_name"),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "allowed_countries_string",
    title: "Countries To Validate",
    getter: (row) => row.get("allowed_countries_string"),
    formatter: (value, row) => {
      const hasUploadsOrRatecards =
        row.get("allowed_ratecards_string") || row.get("allowed_uploads_string");
      const text = (
        <TextCrop
          mode="tooltip"
          title="Countries To Validate"
          text={dedupeCSVString(value) ?? ""}
          emptyStub=""
        />
      );
      return hasUploadsOrRatecards ? <s>{text}</s> : text;
    },
    filterType: FilterTypes.VALUES_ICONTAINS,
  },
  {
    type: "column",
    uniqueKey: "allowed_industries_string",
    title: "Industries To Validate",
    getter: (row) => row.get("allowed_industries_string"),
    formatter: (value, row) => {
      const hasUploadsOrRatecards =
        row.get("allowed_ratecards_string") || row.get("allowed_uploads_string");
      const text = (
        <TextCrop
          mode="tooltip"
          title="Industries To Validate"
          text={dedupeCSVString(value) ?? ""}
          emptyStub=""
        />
      );
      return hasUploadsOrRatecards ? <s>{text}</s> : text;
    },
    filterType: FilterTypes.VALUES_ICONTAINS,
  },
  {
    type: "column",
    uniqueKey: "allowed_ratecards_string",
    title: "Rate Cards To Validate",
    getter: (row) => row.get("allowed_ratecards_string"),
    formatter: (value) => (
      <TextCrop
        mode="tooltip"
        title="Rate Cards To Validate"
        text={dedupeCSVString(value) ?? ""}
        emptyStub=""
      />
    ),
    filterType: FilterTypes.VALUES_ICONTAINS,
  },
  {
    type: "column",
    uniqueKey: "allowed_uploads_string",
    title: "Uploads To Validate",
    getter: (row) => row.get("allowed_uploads_string"),
    formatter: (value) => (
      <TextCrop
        mode="tooltip"
        title="Uploads To Validate"
        text={dedupeCSVString(value) ?? ""}
        emptyStub=""
      />
    ),
    filterType: FilterTypes.VALUES_ICONTAINS,
  },
];

export type PermissionsTableAPI = {
  refresh: () => void;
  selectRow: (rowData: PermissionDataMap) => void;
};

type PermissionTableProps = Omit<TableFilterableEditableRestfulProps, "schema">;

const PermissionsTable = (props: PermissionTableProps) => {
  const [schema] = useTableSchemaState(props.children);
  return <TableFilterableEditableRestful {...props} schema={schema} />;
};
PermissionsTable.displayName = "PermissionsTable";

export const PermissionsTableView = React.forwardRef((props: any, ref) => {
  const {
    fetchArgusAPI,
    fetchArgusFilteringAPI,
    showModalWarning,
    showModalError,
    showConfirmationModal,
    closeConfirmationModal,
  } = useVal5KAdminContext();

  useImperativeHandle(ref, () => ({
    refresh: refreshPermissionsData,
    selectRow: (rowData: PermissionDataMap) => startRowEditing(rowData),
  }));

  // table state

  const [permissionsTableState, setPermissionsTableState] = useRecoilState(
    permissionsTableGlobalState
  );
  const { loaded, ...restTableState } = permissionsTableState;

  // table data sources

  const fetchPermissionsData = useCallback(
    async (urlQuery = {}, filtersQuery = {}, nextStateUpdates = {}) => {
      try {
        const response: FetchAPIResponse<DjangoPaginatedResponse<PermissionDataObject>> =
          await fetchArgusFilteringAPI(`permissions/filtered/`, {
            params: urlQuery,
            data: filtersQuery,
          });

        const nextDataState: Partial<RestfulTableDataStateObject<PermissionDataMap>> =
          transformPermissionsData(response.data, nextStateUpdates);

        setPermissionsTableState((prevDataState) => ({
          ...prevDataState,
          ...nextDataState,
          loaded: true,
        }));

        return nextDataState;
      } catch (err: any) {
        logAsyncOperationError("fetchPermissionsData", err);
        showModalError(
          "Error occurred while loading permissions list. Please, try again later."
        );
        throw err;
      }
    },
    [setPermissionsTableState, fetchArgusFilteringAPI, showModalError]
  );

  const fetchPermissionsFiltersData = useCallback(
    async (urlQuery = {}, filtersQuery = {}) => {
      try {
        const response: FetchAPIResponse<DjangoPaginatedResponse<any>> =
          await fetchArgusFilteringAPI(`permissions/values/filtered/`, {
            params: urlQuery,
            data: filtersQuery,
          });
        return response.data;
      } catch (err: any) {
        logAsyncOperationError("fetchPermissionsFiltersData", err);
        showModalError(
          "Error occurred while loading filter values. Please, try again later."
        );
        throw err;
      }
    },
    [fetchArgusFilteringAPI, showModalError]
  );

  // utility functions

  const refreshPermissionsData = useCallback(async () => {
    return fetchPermissionsData(
      {
        [djangoPaginationKey]: permissionsTableState.activePage,
        [djangoPaginationSizeKey]: permissionsTableState.itemsPerPage,
      },
      permissionsTableState.filtersQuery.toJS()
    );
  }, [
    permissionsTableState.filtersQuery,
    permissionsTableState.activePage,
    permissionsTableState.itemsPerPage,
    fetchPermissionsData,
  ]);

  // table editing row state

  const { editingRowId, startRowEditing, stopRowEditing } =
    useEditingRowState<PermissionDataMap>(refreshPermissionsData);

  // initial data fetch

  useInitialFetch(refreshPermissionsData);

  // utils

  const validatePermissionsData = useCallback(
    (data: PermissionDataMap) => {
      if (!data?.size) {
        showModalWarning("Empty permissions data provided");
        return false;
      }

      const permsRepr = data.get("perms_repr") || emptyMap;
      const countriesData = permsRepr.get("countries") || emptyMap;
      const selectedNoneCountries = countriesData.get("allowed_none") || false;
      const selectedAllCountries = countriesData.get("allowed_all") || false;
      const selectedCountries = countriesData.get("allowed_items") || emptyList;

      if (
        countriesData?.size &&
        !selectedNoneCountries &&
        !selectedAllCountries &&
        !selectedCountries.size
      ) {
        showModalWarning(
          `You should pick up some countries for validation,
         or choose completely different option above.`
        );
        return false;
      }

      const industriesData = permsRepr.get("industries") || emptyMap;
      const selectedNoneIndustries = industriesData.get("allowed_none") || false;
      const selectedAllIndustries = industriesData.get("allowed_all") || false;
      const selectedIndustries = industriesData.get("allowed_items") || emptyList;

      if (
        industriesData?.size &&
        !selectedNoneIndustries &&
        !selectedAllIndustries &&
        !selectedIndustries.size
      ) {
        showModalWarning(
          `You should pick up some industries for validation,
         or choose completely different option above.`
        );
        return false;
      }

      return true;
    },
    [showModalWarning]
  );

  // handlers

  const handleRemoveObject = useCallback(
    async (data: PermissionDataMap) => {
      try {
        await fetchArgusAPI(`permissions/${data.get("id")}/`, { method: "delete" });
        await stopRowEditing(true);
        await closeConfirmationModal();
      } catch (err: any) {
        logAsyncOperationError("deletePermissionsObject", err);
        showModalError(
          "Error occurred while deleting permissions object. Please, try again later."
        );
      }
    },
    [stopRowEditing, closeConfirmationModal, fetchArgusAPI, showModalError]
  );

  const handleConfirmRemoveObject = useCallback(
    (data: PermissionDataMap) => {
      const email = data.get("email");
      const header = "Remove permissions object.";
      const footer = (
        <ButtonGroupRight fill css={{ flexDirection: "row-reverse" }}>
          <Button size="large" onClick={closeConfirmationModal}>
            No, Cancel
          </Button>
          <PromiseButton
            icon="trash-alt"
            loadingText="Delete"
            color="danger"
            size="large"
            onClick={() => handleRemoveObject(data)}
          >
            Yes, Delete
          </PromiseButton>
        </ButtonGroupRight>
      );
      const message = (
        <Text>
          You are about to delete permissions object for email "{email}". Please, confirm
          the operation.
        </Text>
      );

      showConfirmationModal(message, header, footer);
    },
    [handleRemoveObject, showConfirmationModal, closeConfirmationModal]
  );

  const handlePermsChange = useCallback(
    async (data: PermissionDataMap) => {
      if (validatePermissionsData(data)) {
        try {
          await fetchArgusAPI(`permissions/${data.get("id")}/`, {
            method: "patch",
            data: data.toJS(),
          });
          await stopRowEditing(true);
        } catch (err: any) {
          logAsyncOperationError("updatePermissionsObject", err);
          showModalError(
            "Error occurred while updating permissions object. Please, try again later."
          );
        }
      }
    },
    [stopRowEditing, fetchArgusAPI, validatePermissionsData, showModalError]
  );

  // rendering

  const renderActionsButtons = useCallback(
    (rowData: PermissionDataMap) => {
      return (
        <IconButton
          variant="outlined"
          size="small"
          icon="wrench"
          title="edit permissions"
          color="brand"
          onClick={() => startRowEditing(rowData)}
        />
      );
    },
    [startRowEditing]
  );

  const columns = [
    <Column
      key="_edit"
      uniqueKey="_edit"
      title="Edit"
      getter={renderActionsButtons}
      fixed
    />,
  ].concat(renderTableColumnsSpecs(permissionsTableSpecs));

  if (!loaded) {
    return (
      <Box css={{ minHeight: "200px", position: "relative" }}>
        <TickerContentLoader />
      </Box>
    );
  }

  return (
    <Box css={{ marginTop: "$4" }}>
      <PermissionsTable
        multimode
        editable
        editorImpl={ValidatorPermissionsEditor as RowEditorComponent<PermissionDataMap>}
        selectedRowId={editingRowId}
        rowIdGetter={rowIdGetter}
        bodyEmptyText="No permissions found"
        dataProvider={fetchPermissionsData}
        filtersDataProvider={fetchPermissionsFiltersData}
        onEditCancel={stopRowEditing}
        onEditApply={handlePermsChange}
        onDeleteRow={handleConfirmRemoveObject}
        {...restTableState}
      >
        {columns}
      </PermissionsTable>
    </Box>
  );
});
PermissionsTableView.displayName = "PermissionsTableView";
