// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Link } from "react-router";
import Modal from "../../ratecards/components/Modal";
import RateAndPayTypePicker from "../../negotiation_worksheets/components/RateAndPayTypePicker";
import GroupList from "../../negotiation_worksheets/components/GroupList";
import Overview from "../../negotiation_worksheets/components/Overview";
import GroupChartList from "../../negotiation_worksheets/components/GroupChartList";
import CalculatedResults from "../../negotiation_worksheets/components/CalculatedResults";
import CalculatedResultsForSalary from "../../negotiation_worksheets/components/CalculatedResultsForSalary";
import WorkerTypeCard from "../../negotiation_worksheets/components/WorkerTypeCard";
import { fieldTypeEq, rateTypeContains } from "../../negotiation_worksheets/utils/filter";
import { BottomControls } from "../../../components/material";
import ResultSummary from "../../negotiation_worksheets/components/ResultSummary";
import ResultsSummaryForSalary from "../../negotiation_worksheets/components/ResultsSummaryForSalary";
import exportToExcel from "../../negotiation_worksheets/utils/exportToExcel";
import SalaryExportToExcel from "../../negotiation_worksheets/utils/SalarytypeExportToExcel";
import DownloadPOSTButton from "../../../components/buttons/DownloadPOSTButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TagItemWithoutDelete } from "../../ratecards/components/TagItem";
import LoadingIndicator from "../../shared/LoadingIndicator";

const WorksheetDetail = observer((props) => {
  const store = props.store;
  const createStore = props.createStore;
  let location,
    worksheetId,
    worksheetName,
    owner,
    tagSection,
    createdDate = null;

  if (store.network.loading) {
    location = "...";
    worksheetName = "...";
    createdDate = "...";
    owner = "...";
    worksheetId = "...";
  } else if (store.negDetail) {
    if (createStore.checkLegacyStd()) {
      location = `${store.negDetail.state.name}, ${store.negDetail.country.name}`;
    } else {
      location = `${store.negDetail.country.name}`;
    }
    worksheetName = store.negDetail.worksheetName;
    worksheetId = store.negDetail.worksheetId;
    createdDate = store.negDetail.createdDisplay;
    owner = `${store.negDetail.owner.firstName} ${store.negDetail.owner.lastName}`;
    tagSection = store.negDetail.tags.map((tag) => (
      <TagItemWithoutDelete
        key={tag.tagId}
        tag={tag}
        onClick={(e) => {
          props.onClick(e, tag.tagId);
        }}
      />
    ));
  }
  const { unsavedValues, rateType } = props.createStore.currentState;
  const { worksheetSchema, selectedWorkerType, values } = props.createStore;
  const calculatedResults = props.createStore.calculatePayAndBillRate(
    worksheetSchema.conversionOptions
  );
  const workerType = selectedWorkerType;

  let actionBar = null;
  if (!store.network.loading && !store.network.error)
    actionBar = (
      <nav className="action-bar">
        {props.createStore.currentState.payType !== 3 && (
          <DownloadPOSTButton
            className=""
            service="excelsior"
            endpoint="excel-export/"
            getFormData={() =>
              exportToExcel(
                worksheetSchema,
                values,
                unsavedValues,
                worksheetName,
                calculatedResults,
                rateType,
                workerType
              )
            }
          >
            <FontAwesomeIcon icon="download" /> Export
          </DownloadPOSTButton>
        )}
        {props.createStore.currentState.payType === 3 && (
          <DownloadPOSTButton
            className=""
            service="excelsior"
            endpoint="excel-export/"
            getFormData={() =>
              SalaryExportToExcel(
                worksheetSchema,
                values,
                unsavedValues,
                worksheetName,
                calculatedResults,
                rateType,
                workerType
              )
            }
          >
            <FontAwesomeIcon icon="download" /> Export to Excel
          </DownloadPOSTButton>
        )}
      </nav>
    );

  return (
    <div className="top">
      <div className="above">
        <div className="ratecard-label-header ellipsis max-width-80">
          <h2 className="ellipsis">{worksheetName}</h2>
        </div>
        <nav className="back-bar">
          <Link to={`/admin/worksheets/`} className="btn btn-green">
            <FontAwesomeIcon icon="arrow-left" fixedWidth className="icon" />
            Go Back
          </Link>
          <button className="btn" onClick={store.showHelp}>
            <FontAwesomeIcon icon="question-circle" fixedWidth className="icon" />
            Help
          </button>
        </nav>
      </div>
      <div className="below">
        <div className="ratecard-meta ellipsis">
          <div className="ratecard-owner ellipsis">
            <FontAwesomeIcon icon="key" fixedWidth className="icon" /> {worksheetId}
          </div>
          <div className="ratecard-owner ellipsis">
            <FontAwesomeIcon icon="user" fixedWidth className="icon" /> {owner}
          </div>
          <div className="ratecard-created-at ellipsis">
            <FontAwesomeIcon icon="calendar-alt" fixedWidth className="icon" />{" "}
            {createdDate}
          </div>
          <div className="ratecard-created-at ellipsis">
            <FontAwesomeIcon icon="map-marker" fixedWidth className="icon" /> {location}
          </div>
        </div>
        {actionBar}
      </div>
      <div className="tagbox">{tagSection}</div>
      <Modal show={store.showHelpModal.show} onHide={store.showHelpModal.hideModal}>
        <div className="container-section header">
          <h4>Help</h4>
        </div>
        <div className="container-section footer">
          <p>Here you can see your Worksheet</p>
        </div>
      </Modal>
    </div>
  );
});

/**
 * RegionDetail component for rate card detail page
 */
class Detail extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor() {
    super();
    this.deleteTag = this.deleteTag.bind(this);
  }

  /**
   * componentDidMount
   * @return {void}
   */
  componentDidMount() {
    const store = this.props.store.negotiationWorksheetDetailPTStore;
    const createStore = this.props.store.negotiationWorksheetCreatePTStore;
    store.worksheetId = this.props.router.params.worksheetId;
    store.router = this.context.router;
    store.fetchWorksheet();
    createStore.undoQueue = [];
    createStore.redoQueue = [];
    createStore.currentState = {};
  }

  deleteTag(e: object, tag_id) {}

  getFieldKeySetSeqForSalaryType() {
    return this.getFieldsForSalaryType()
      ? this.getFieldsForSalaryType().map((field) => field.key)
      : [];
  }

  setScriptedPercentage() {
    return this.hasFieldOfType("scriptedPercentage");
  }

  hasFieldOfType(fieldType) {
    const { worksheetSchema } = this.props.store.negotiationWorksheetCreatePTStore;

    if (!worksheetSchema.fields) return null;

    return worksheetSchema.fields.some(fieldTypeEq(fieldType));
  }

  getFieldsForSalaryType() {
    const { worksheetSchema } = this.props.store.negotiationWorksheetCreatePTStore;

    if (!worksheetSchema.fields) return null;

    return worksheetSchema.fields.filter(rateTypeContains("salary"));
  }

  /**
   * Render Worksheet detail page
   * @return {XML}
   */
  render() {
    const store = this.props.store.negotiationWorksheetDetailPTStore;
    const createStore = this.props.store.negotiationWorksheetCreatePTStore;
    const salaryFieldsCheck = this.getFieldKeySetSeqForSalaryType();
    const conversionOptions =
      createStore.worksheetSchema && createStore.worksheetSchema.conversionOptions
        ? createStore.worksheetSchema.conversionOptions
        : "";
    const calculatedResults = createStore.calculatePayAndBillRate(conversionOptions);
    const {
      payRateHourly,
      billRateHourly,
      totalMarkup,
      totalTax,
      billRateHourlyAfterTax,
    } = calculatedResults;
    const { valUpdated } = createStore;

    return (
      <div className="view">
        <div className="rc-container show-overflow">
          <WorksheetDetail
            store={store}
            router={this.props.router}
            createStore={createStore}
            onClick={this.deleteTag}
          />
        </div>
        {store.network.error && (
          <div className="rc-container bring-forward">
            <div className="pt-error-message fade-in">
              <h2>Error</h2>
              <pre>
                <code>{JSON.stringify(store.network.error, null, 2)}</code>
              </pre>
            </div>
          </div>
        )}
        {/*!store.isLatest && <div className="clearfix">
          <div className="alert alert-danger alert-dismissable fade in pt-user-alert pull-left ws-alert">
            This is not the latest schema
          </div>
          <button className="btn btn-lg btn-green pull-right" onClick={() => {createStore.resetValues();
              createStore.getSchema(store.apiLocationId, 'latest', "detail");}}>
            Update Schema
          </button>
        </div>*/}

        <div className="rc-container bring-forward">
          <div className="header-action-bar">
            <div className="row">
              {!createStore.worksheetSchema.currencySymbol && <LoadingIndicator />}
              <div className="col-xs-6">
                {createStore.worksheetSchema.currencySymbol && (
                  <div className="rc-container">
                    <RateAndPayTypePicker
                      payTypeBillRateDisabled={createStore.payTypeBillRateDisabled}
                      store={createStore}
                      currencySymbol={createStore.worksheetSchema.currencySymbol}
                      payValue={createStore.currentState.payValue}
                      salaryFieldsCheck={salaryFieldsCheck.length}
                      setPayValue={createStore.setPayValue.bind(this, createStore)}
                      setPayType={createStore.setPayType.bind(this, createStore)}
                      setRateType={createStore.setRateType.bind(this, createStore)}
                      payType={createStore.currentState.payType}
                      rateType={
                        createStore.currentState.payType === 3
                          ? ""
                          : createStore.currentState.rateType
                      }
                      workerTypeKey={
                        createStore.currentState.payType === 3
                          ? ""
                          : createStore.currentState.workerTypeKey
                      }
                      setWorkerType={createStore.setWorkerType.bind(this, createStore)}
                      workerTypes={createStore.worksheetSchema.workerTypes}
                    />
                  </div>
                )}
                {(createStore.currentState.rateType &&
                  createStore.selectedWorkerType.id) ||
                createStore.currentState.payType === 3 ? (
                  <GroupList
                    groups={createStore.worksheetSchema.groups}
                    fields={createStore.worksheetSchema.fields}
                    workerTypeId={createStore.selectedWorkerType.id}
                    currencySymbol={createStore.worksheetSchema.currencySymbol}
                    payRateHourly={payRateHourly}
                    billRateHourly={billRateHourly}
                    conversionOptions={conversionOptions}
                    store={createStore}
                    valUpdated={valUpdated}
                  />
                ) : (
                  ""
                )}
                {createStore.currentState.rateType &&
                  createStore.selectedWorkerType.id &&
                  createStore.currentState.payType !== 3 && (
                    <CalculatedResults
                      store={createStore}
                      payRateHourly={parseFloat(payRateHourly)}
                      conversionOptions={conversionOptions}
                      totalMarkup={parseFloat(totalMarkup)}
                      billRateHourly={parseFloat(billRateHourly)}
                      currencySymbol={createStore.worksheetSchema.currencySymbol}
                      totalTax={parseFloat(totalTax)}
                      billRateHourlyAfterTax={parseFloat(billRateHourlyAfterTax)}
                    />
                  )}
                {createStore.currentState.payType === 3 && (
                  <CalculatedResultsForSalary
                    conversionOptions={conversionOptions}
                    totalMarkup={parseFloat(totalMarkup)}
                    currencySymbol={createStore.worksheetSchema.currencySymbol}
                    payValue={createStore.currentState.payValue}
                  />
                )}
              </div>
              <div className="col-xs-6">
                {createStore.selectedWorkerType.id &&
                  createStore.currentState.rateType &&
                  createStore.currentState.payType !== 3 && (
                    <div className="rc-container">
                      <WorkerTypeCard workerType={createStore.selectedWorkerType} />
                    </div>
                  )}
                {createStore.worksheetSchema.overviews && (
                  <div className="rc-container">
                    <Overview store={createStore} isExpanded={createStore.isExpanded} />
                  </div>
                )}
                {(createStore.currentState.rateType &&
                  createStore.selectedWorkerType.id) ||
                createStore.currentState.payType === 3 ? (
                  <GroupChartList
                    groups={createStore.worksheetSchema.groups}
                    store={createStore}
                    payRateHourly={payRateHourly}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>

            {createStore.payType && (
              <div className="row">
                <div className="col-xs-12">
                  <div className="rc-container no-overflow">
                    <BottomControls height="50px">
                      {createStore.currentState.rateType &&
                        createStore.selectedWorkerType.id &&
                        createStore.currentState.payType !== 3 && (
                          <ResultSummary
                            currencySymbol={createStore.worksheetSchema.currencySymbol}
                            rateType={createStore.currentState.rateType}
                            payRateHourly={parseFloat(payRateHourly)}
                            billRateHourly={parseFloat(billRateHourly)}
                            totalTax={parseFloat(totalTax)}
                            totalMarkup={parseFloat(totalMarkup)}
                            billRateHourlyAfterTax={parseFloat(billRateHourlyAfterTax)}
                            conversionOptions={conversionOptions}
                            store={createStore}
                          />
                        )}
                      {createStore.payType === 3 && (
                        <ResultsSummaryForSalary
                          currencySymbol={createStore.worksheetSchema.currencySymbol}
                          payValue={createStore.currentState.payValue}
                          totalMarkup={parseFloat(totalMarkup)}
                          conversionOptions={conversionOptions}
                          store={createStore}
                        />
                      )}
                    </BottomControls>
                  </div>
                </div>
              </div>
            )}
            <div className="clearfix" />
          </div>
        </div>
      </div>
    );
  }
}

export default observer(Detail);
