import { styled } from "../../stitches.config";

const stylesConfig = {
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: "100%",
  height: "100%",
  variants: {
    color: {
      black: {
        backgroundColor: "rgba(0, 0, 0, .5)",
      },
      white: {
        backgroundColor: "rgba(256, 256, 256, .5)",
      },
    },
  },
  defaultVariants: {
    color: "white",
  },
};
const Overlay = styled("div", stylesConfig);
Overlay.displayName = "Overlay";

export default Overlay;
