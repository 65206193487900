import React, { ReactNode } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTrigger,
} from "../../../components/lib/Dialog";
import { styled } from "../../../stitches.config";
import Stack from "../../../components/lib/Stack";
import Icon from "../../../components/lib/Icon";
import Text from "../../../components/lib/Text";
import RsSkillsWithJobDescription from "../JobTittleSkills";
import { useGlobalContext } from "../../../globalContext";

interface RsJobDescriptionDialogProps {
  jobLabel: string;
  jobDescription: string;
  children: ReactNode;
}

const IconButton = styled("button", {
  background: "none",
  fontSize: "18px",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  color: "rgba(42,57,79,0.6)",
  position: "relative",
  top: "20px",
  right: "-650px",
  cursor: "pointer",

  "&:hover": { color: "$primaryDark" },
});

function RsJobDescriptionDialog(props: RsJobDescriptionDialogProps) {
  const { children, jobDescription, jobLabel } = props;
  const { isClientJobLibrary } = useGlobalContext();

  return (
    <Dialog>
      <DialogTrigger asChild>
        <span>{children}</span>
      </DialogTrigger>
      <DialogContent>
        <DialogClose asChild>
          <IconButton>
            <Icon icon={"times"} />
          </IconButton>
        </DialogClose>
        <DialogDescription
          asChild
          css={{ minHeight: 500, padding: "40px", background: "#ffffff", minWidth: 700 }}
        >
          <Stack nogap css={{ alignItems: "flex-start" }}>
            <Stack css={{ alignItems: "flex-start", gap: "$5" }}>
              <Text as="h3" noMargin>
                {jobLabel}
              </Text>
              {isClientJobLibrary && props.jobDescription && (
                <RsSkillsWithJobDescription
                  jobDescription={jobDescription}
                  showSkillLabel={false}
                />
              )}
            </Stack>
            <Stack css={{ alignItems: "flex-start", marginTop: "$4" }}>
              <Text as="h4" color="dark" noMargin>
                Job Description:
              </Text>
              <Text as="p" css={{ fontSize: "$base", whiteSpace: "pre-line" }}>
                {props.jobDescription}
              </Text>
            </Stack>
          </Stack>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
}

export default RsJobDescriptionDialog;
