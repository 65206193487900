// @flow
import React from "react";
import Button from "../../components/lib/Button";
import { styled } from "../../../src/stitches.config";

const HelpButton = styled(Button);

type Props = {
  onClick: (SyntheticMouseEvent<>) => void,
};

export default (props: Props) => (
  <HelpButton onClick={props.onClick} color="brand" shape="circular">
    <svg
      width="11px"
      height="18px"
      viewBox="0 0 11 18"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M3.684 15.3h2.763V18H3.684v-2.7zM5.526 0c4.928.198 7.074 5.058 4.145 8.703-.765.9-1.999 1.494-2.607 2.25-.617.747-.617 1.647-.617 2.547H3.684c0-1.503 0-2.772.617-3.672.608-.9 1.842-1.431 2.607-2.025C9.137 5.787 8.584 2.934 5.526 2.7 4 2.7 2.763 3.909 2.763 5.4H0C0 2.418 2.474 0 5.526 0z"
        fillRule="nonzero"
        fill="#FFF"
      />
    </svg>
  </HelpButton>
);
