import React, { useMemo } from "react";

import Stack from "../../../../components/lib/Stack";
import Button from "../../../../components/lib/Button";
import Alert from "../../../../components/lib/Alert";
import Text from "../../../../components/lib/Text";
import DisplayCurrencySelector from "../../components/DisplayCurrencySelector";
import {
  Card,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
  CardHeaderTitle,
  CardBody,
} from "../../../../components/lib/Card";
import RatesTableOrdered from "../../components/RatesTableOrdered/RatesTableOrdered";
import { useReviewDataContext } from "../../context/ReviewDataContext";
import { RATE_TYPES_LABELS } from "../../types";
import { ReplacementsAlert } from "../../components/Alerts";
import { getJobRateTypeParameters } from "../../utils";

import type { ImmutableSet } from "../../../../types/immutable";
import { useRateDisplayContext } from "../../context/RateDisplayContext";
import { getAllJobsIdsSet } from "../../components/RatesTableOrdered/utils";
import { SelectMultipleRowsContext } from "../../context/SelectMultipleRowsContext";

type OrderedTitleValidationBlockProps = {
  editing: boolean;
  editingJobsIds: ImmutableSet<number>;
  onEdit: () => void;
  onChangeJobsTitle: () => void;
  onChangeJobsDescription: () => void;
  onCancelEdit: () => void;
  onResolveReplacements: () => void;
  onSelectJobsForEditing: (selectedJobsIds: ImmutableSet<number>) => void;
};

const OrderedTitleValidationBlock = (props: OrderedTitleValidationBlockProps) => {
  const {
    editing,
    editingJobsIds,
    onEdit,
    onChangeJobsTitle,
    onChangeJobsDescription,
    onCancelEdit,
    onResolveReplacements,
    onSelectJobsForEditing,
  } = props;

  const { jobsData, firstJobData, isEditable } = useReviewDataContext();
  const {
    displayCurrencyData,
    isDisplayCurrencyChanged,
    changeDisplayCurrency: handleChangeCurrency,
    resetDisplayCurrency: handleResetCurrency,
  } = useRateDisplayContext();

  const jobRateType = firstJobData.get("rate_type");
  const jobRateMultiplier = useMemo(
    () => getJobRateTypeParameters(firstJobData, jobRateType),
    [firstJobData, jobRateType]
  ).rateMultiplier;

  // context

  const allRowsIdsSet = useMemo(() => getAllJobsIdsSet(jobsData), [jobsData]);
  const isAllRowsSelected = allRowsIdsSet.equals(editingJobsIds);
  const selectRowsContextValues = React.useMemo(
    () => ({
      selecting: editing,
      selectedIds: editingJobsIds,
      allIds: allRowsIdsSet,
      onChangeSelected: onSelectJobsForEditing,
      isAllSelected: isAllRowsSelected,
    }),
    [editing, editingJobsIds, allRowsIdsSet, isAllRowsSelected, onSelectJobsForEditing]
  );

  // render

  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft>
          <CardHeaderTitle as="h3">Titles For Validation</CardHeaderTitle>
        </CardActionsLeft>
        <CardActionsRight>
          {!editing && displayCurrencyData.size > 0 && !isDisplayCurrencyChanged && (
            <DisplayCurrencySelector
              currencyData={displayCurrencyData}
              onChange={handleChangeCurrency}
            />
          )}
          {!editing && displayCurrencyData.size > 0 && isDisplayCurrencyChanged && (
            <Button color="brand" variant="outlined" onClick={handleResetCurrency}>
              Reset Currency Back
            </Button>
          )}
          {isEditable && !editing && (
            <Button color="brand" variant="outlined" icon="pencil-alt" onClick={onEdit}>
              Edit Jobs Parameters
            </Button>
          )}

          {isEditable && editing && (
            <Button
              color="brand"
              variant="outlined"
              icon="pencil-alt"
              onClick={onChangeJobsTitle}
              disabled={editing && editingJobsIds.size === 0}
            >
              Change Jobs Title
            </Button>
          )}
          {isEditable && editing && (
            <Button
              color="brand"
              variant="outlined"
              icon="pencil-alt"
              onClick={onChangeJobsDescription}
              disabled={editing && editingJobsIds.size === 0}
            >
              Change Jobs Description
            </Button>
          )}
          {isEditable && editing && (
            <Button icon="times" color="danger" variant="outlined" onClick={onCancelEdit}>
              Finish Editing
            </Button>
          )}
        </CardActionsRight>
      </CardActions>
      <CardBody>
        <Stack css={{ alignItems: "flex-start" }}>
          <ReplacementsAlert onClick={onResolveReplacements} />
          {isEditable && editing && (
            <Alert fill color="warning">
              Choose some jobs below to apply future changes to.
            </Alert>
          )}
          {!editing && jobRateMultiplier != null && jobRateMultiplier > 1 && (
            <Alert fill color="warning">
              Table below contains{" "}
              <Text bold>{RATE_TYPES_LABELS[jobRateType].toUpperCase()}</Text> values.
            </Alert>
          )}
          <SelectMultipleRowsContext.Provider value={selectRowsContextValues}>
            <RatesTableOrdered jobsData={jobsData} />
          </SelectMultipleRowsContext.Provider>
        </Stack>
      </CardBody>
    </Card>
  );
};

OrderedTitleValidationBlock.displayName = "OrderedTitleValidationBlock";

export default OrderedTitleValidationBlock;
