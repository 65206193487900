import React, { useCallback } from "react";
import { useRecoilState } from "recoil";
import queryString from "query-string";

import ReviewsTableView from "../components/ReviewsTable";
import ReviewsBulkUpdateForm, {
  ReviewsBulkUpdateDataObject,
} from "../components/ReviewsBulkUpdateForm";
import Stack from "../../../components/lib/Stack";
import Button from "../../../components/lib/Button";
import Text from "../../../components/lib/Text";
import Icon from "../../../components/lib/Icon";
import { SimpleLink as Link } from "../../../components/lib/Link";
import {
  djangoPaginationKey,
  djangoPaginationSizeKey,
} from "../../../components/tables/constants";
import {
  Card,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
  CardHeaderTitle,
  CardBody,
} from "../../../components/lib/Card";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";
import { reviewsTableGlobalState } from "../globalState";
import { transformReviewsData } from "../dataConverters";

import type { ReviewsTableDataStateObject } from "../globalState";
import type { FetchAPIResponse } from "../../../types/fetch";
import type { DjangoPaginatedResponse } from "../../../types/django";
import type { ReviewDataObject } from "../types";
import { useVal5KAdminContext } from "../context/Val5KAdminContext";

type PageHeadBlockProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const PageHeadBlock = (props: PageHeadBlockProps) => {
  const { onClick } = props;
  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft>
          <CardHeaderTitle>Manage Surveys</CardHeaderTitle>
        </CardActionsLeft>
        <CardActionsRight>
          <Button icon="arrow-left" size="normal" color="brand" onClick={onClick}>
            Back to Dashboard
          </Button>
        </CardActionsRight>
      </CardActions>
    </Card>
  );
};
PageHeadBlock.displayName = "PageHeadBlock";

type PageBottomBlockProps = {
  pagesNumber: number;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const PageBottomBlock = (props: PageBottomBlockProps) => {
  const { pagesNumber, onClick } = props;

  if (pagesNumber > 1) {
    return (
      <Card fill>
        <CardActions>
          <CardActionsLeft />
          <CardActionsRight>
            <Button icon="arrow-left" size="normal" color="brand" onClick={onClick}>
              Back to Dashboard
            </Button>
          </CardActionsRight>
        </CardActions>
      </Card>
    );
  }

  return null;
};
PageBottomBlock.displayName = "PageBottomBlock";

const ReviewsListPage = () => {
  const {
    router,
    fetchArgusAPI,
    fetchGraphQL,
    showModalError,
    showModalSuccess,
    showConfirmationModal,
    closeConfirmationModal,
    surveysValidationUIUrl,
  } = useVal5KAdminContext();

  const [reviewsTableState, setReviewsTableState] =
    useRecoilState<ReviewsTableDataStateObject>(reviewsTableGlobalState);
  const pagesNumber =
    Math.ceil(reviewsTableState.itemsCount / reviewsTableState.itemsPerPage) || 1;

  const handleBackToDashboard = useCallback(() => {
    router.push(`/admin/val5000`);
  }, [router]);

  const handleBulkUpdate = useCallback(
    async (data: Partial<ReviewsBulkUpdateDataObject> = {}) => {
      if (!data || !Object.keys(data).length) return;

      try {
        const payload = {
          __update_parameters: JSON.stringify(data),
          ...reviewsTableState.filtersQuery.toJS(),
        };

        const response: FetchAPIResponse<DjangoPaginatedResponse<ReviewDataObject>> =
          await fetchArgusAPI(`reviews/bulk_update/`, {
            method: "post",
            headers: { "content-type": "application/x-www-form-urlencoded" },
            params: {
              [djangoPaginationKey]: reviewsTableState.activePage,
              [djangoPaginationSizeKey]: reviewsTableState.itemsPerPage,
            },
            data: queryString.stringify(payload),
          });

        const nextDataState = transformReviewsData(response.data);

        setReviewsTableState((prevDataState) => ({
          ...prevDataState,
          ...nextDataState,
        }));

        closeConfirmationModal();
        showModalSuccess(`${nextDataState.itemsCount} rows have been updated.`);
      } catch (err: any) {
        logAsyncOperationError("bulkUpdateReviewsList", err);
        showModalError(
          "Error occurred while bulk surveys update. Please, try again later."
        );
      }
    },
    [
      reviewsTableState.activePage,
      reviewsTableState.itemsPerPage,
      reviewsTableState.filtersQuery,
      setReviewsTableState,
      fetchArgusAPI,
      closeConfirmationModal,
      showModalSuccess,
      showModalError,
    ]
  );

  const handleShowBulkUpdateForm = useCallback(() => {
    const header = (
      <Text>
        Surveys Bulk Update&nbsp;
        {reviewsTableState.itemsCount ? (
          <small>({reviewsTableState.itemsCount} rows selected)</small>
        ) : (
          ""
        )}
      </Text>
    );
    const message = (
      <ReviewsBulkUpdateForm
        itemsCount={reviewsTableState.itemsCount}
        onCancel={closeConfirmationModal}
        onApply={handleBulkUpdate}
        fetchGraphQL={fetchGraphQL}
      />
    );

    showConfirmationModal(message, header);
  }, [
    reviewsTableState.itemsCount,
    handleBulkUpdate,
    showConfirmationModal,
    closeConfirmationModal,
    fetchGraphQL,
  ]);

  return (
    <Stack>
      <PageHeadBlock onClick={handleBackToDashboard} />
      <Card fill>
        <CardActions>
          <CardActionsLeft>
            <CardHeaderTitle as="h3">Surveys List</CardHeaderTitle>
          </CardActionsLeft>
          <CardActionsRight>
            <Button
              icon={["far", "edit"]}
              size="small"
              onClick={handleShowBulkUpdateForm}
            >
              Bulk Update
            </Button>
          </CardActionsRight>
        </CardActions>
        <CardBody>
          <Stack css={{ gap: "$1", alignItems: "start" }}>
            <Text thin css={{ fontSize: "$sm" }}>
              <Icon icon="info-circle" /> Table below has default filtering, it displays
              "Active" reviews only.
            </Text>
            {surveysValidationUIUrl && (
              <Text css={{ fontSize: "$sm" }}>
                <Text thin>
                  <Icon icon="info-circle" /> Link to the surveys validation UI -
                </Text>
                &nbsp;
                <Link href={surveysValidationUIUrl} target="_blank" rel="noreferrer">
                  {surveysValidationUIUrl}
                </Link>
              </Text>
            )}
          </Stack>
          <ReviewsTableView />
        </CardBody>
      </Card>
      <PageBottomBlock pagesNumber={pagesNumber} onClick={handleBackToDashboard} />
    </Stack>
  );
};

ReviewsListPage.displayName = "ReviewsListPage";

export default ReviewsListPage;
