import React, { useCallback, useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { useRecoilState } from "recoil";
import queryString from "query-string";

// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";
import Icon from "../../../components/lib/Icon";
import { SimpleLink as Link } from "../../../components/lib/Link";
import Box from "../../../components/lib/Box";
import Stack from "../../../components/lib/Stack";
import Button, { NavigationButton } from "../../../components/lib/Button";
import Text from "../../../components/lib/Text";
import Inline from "../../../components/lib/Inline";
import PaymentReportForm from "../components/PaymentReportForm";
import AttemptsBulkUpdateForm from "../components/AttemptsBulkUpdateForm";
import QuestionsExportConfig from "../components/QuestionsExportConfig";
import RatesExportConfig from "../components/RatesExportConfig";
import {
  Card,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
  CardBody,
  CardHeaderTitle,
} from "../../../components/lib/Card";
import { attemptsTableGlobalState } from "../globalState";
import { AttemptsTableView } from "../components/AttemptsTable";
import { useModalState } from "../../../utils/hooks";
import {
  djangoOrderingKey,
  djangoPaginationKey,
  djangoPaginationSizeKey,
} from "../../../components/tables/constants";
import { decimalFormatter } from "../constants";
import { transformAttemptsData } from "../dataConverters";

import type {
  UrlQueryObject,
  FiltersQueryObject,
} from "../../../components/tables/types";
import type { AttemptsTableDataStateObject } from "../globalState";
import type {
  RewardsSummaryDataObject,
  PAYMENT_REPORT_TYPES_TYPE,
  AttemptDataObject,
} from "../types";
import type { FetchAPIResponse } from "../../../types/fetch";
import type { DjangoPaginatedResponse } from "../../../types/django";
import type { QUESTIONS_ORDERING_TYPES_TYPE } from "../components/QuestionsExportConfig";
import type { RATES_ORDERING_TYPES_TYPE } from "../components/RatesExportConfig";
import type { PaymentReportFormQueryArgs } from "../components/PaymentReportForm";
import type { AttemptsBulkUpdateDataObject } from "../components/AttemptsBulkUpdateForm";
import { useVal5KAdminContext } from "../context/Val5KAdminContext";

type BackToDashboardBlockProps = {
  title?: string;
};

const BackToDashboardBlock = (props: BackToDashboardBlockProps) => {
  const { title } = props;
  const { router } = useVal5KAdminContext();

  const handleBackToDashboard = useCallback(
    () => router.push(`/admin/val5000`),
    [router]
  );

  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft>
          {title && <CardHeaderTitle>{title}</CardHeaderTitle>}
        </CardActionsLeft>
        <CardActionsRight>
          <NavigationButton
            icon="arrow-left"
            onClick={handleBackToDashboard}
            css={{ width: "190px" }}
          >
            Back to Dashboard
          </NavigationButton>
        </CardActionsRight>
      </CardActions>
    </Card>
  );
};
BackToDashboardBlock.displayName = "BackToDashboardBlock";

type OrderingType = QUESTIONS_ORDERING_TYPES_TYPE | RATES_ORDERING_TYPES_TYPE;

const AttemptsListPage = () => {
  const {
    router,
    showModalSuccess,
    showModalError,
    sessionInfo,
    fetchArgusFilteringAPI,
    showConfirmationModal,
    closeConfirmationModal,
    surveysValidationUIUrl,
  } = useVal5KAdminContext();

  const [attemptsTableState, setAttemptsTableState] =
    useRecoilState<AttemptsTableDataStateObject>(attemptsTableGlobalState);
  const { itemsCount, itemsPerPage, activePage, filtersQuery } = attemptsTableState;
  const pagesNumber = Math.ceil(itemsCount / itemsPerPage) || 1;

  const summaryRef = useRef(null);
  const [rewardsSummary, setRewardsSummary] = useState({
    totalCount: 0,
    totalReward: 0,
  });

  // modals

  const {
    modalState: questionExportModalState,
    showModal: showQuestionExportModal,
    closeModal: closeQuestionExportModal,
  } = useModalState();

  const {
    modalState: rateExportModalState,
    showModal: showRateExportModal,
    closeModal: closeRateExportModal,
  } = useModalState();

  const {
    modalState: paymentReportModalState,
    showModal: showPaymentReportModal,
    closeModal: closePaymentReportModal,
  } = useModalState();

  // fetch functions

  const fetchAttemptsRewardsSummary = useCallback(async () => {
    const response: FetchAPIResponse<RewardsSummaryDataObject> =
      await fetchArgusFilteringAPI("attempts/rewards-summary-values/filtered/", {
        method: "post",
        data: attemptsTableState.filtersQuery.toJS(),
      });

    setRewardsSummary((prevData) => ({
      ...prevData,
      totalCount: response.data.total_reward_count,
      totalReward: response.data.total_reward_total,
    }));
  }, [attemptsTableState.filtersQuery, fetchArgusFilteringAPI]);

  // effects

  useEffect(() => {
    fetchAttemptsRewardsSummary();
  }, [attemptsTableState.filtersQuery, fetchAttemptsRewardsSummary]);

  // handlers

  const handleGetExportQueryArgs = useCallback(
    (
      ordering: OrderingType,
      exportCurrentPage: boolean = false
    ): UrlQueryObject | null => {
      const queryArgs: UrlQueryObject = {};

      const orderingValue = filtersQuery.get(djangoOrderingKey);
      if (orderingValue) {
        queryArgs[djangoOrderingKey] = orderingValue;
      }

      if (sessionInfo.legacySession) {
        queryArgs["x-session-id"] = sessionInfo.legacySession;
      }

      if (ordering) {
        queryArgs["__export-ordering"] = ordering;
      }

      if (exportCurrentPage) {
        queryArgs[djangoPaginationKey] = activePage;
        queryArgs[djangoPaginationSizeKey] = itemsPerPage;
      }

      return Object.keys(queryArgs).length > 0 ? queryArgs : null;
    },
    [activePage, filtersQuery, itemsPerPage, sessionInfo.legacySession]
  );

  const handleGetExportFormData = useCallback((): FiltersQueryObject => {
    const queryArgs: FiltersQueryObject = filtersQuery.toJS();

    if (filtersQuery.has(djangoOrderingKey)) {
      delete queryArgs[djangoOrderingKey];
    }

    return queryArgs;
  }, [filtersQuery]);

  const handleExportError = useCallback(
    (err: Error) => {
      logAsyncOperationError("exportAttemptsListPage", err);
      showModalError("Error occurred while exporting, try again later.");
    },
    [showModalError]
  );

  type PaymentReportQuery = {
    report_type: PAYMENT_REPORT_TYPES_TYPE;
    date_from: string;
    date_to: string;
    "x-session-id"?: string;
  };

  const handleMakeReport = useCallback(
    (reportParameters: PaymentReportFormQueryArgs) => {
      const { reportType, reportFromDate, reportToDate } = reportParameters;

      if (reportFromDate && reportToDate) {
        const queryArgs: PaymentReportQuery = {
          report_type: reportType,
          date_from: reportFromDate,
          date_to: reportToDate,
        };

        if (sessionInfo.legacySession) {
          queryArgs["x-session-id"] = sessionInfo.legacySession;
        }

        router.push(`/admin/val5000/payment-report?${queryString.stringify(queryArgs)}`);
      }
    },
    [router, sessionInfo.legacySession]
  );

  const handleBulkUpdate = useCallback(
    async (data: AttemptsBulkUpdateDataObject = {}) => {
      if (!data || !Object.keys(data).length) return;

      try {
        const payload = {
          __update_parameters: JSON.stringify(data),
          ...attemptsTableState.filtersQuery.toJS(),
        };

        const response: FetchAPIResponse<DjangoPaginatedResponse<AttemptDataObject>> =
          await fetchArgusFilteringAPI(`attempts/bulk_update/`, {
            params: {
              [djangoPaginationKey]: attemptsTableState.activePage,
              [djangoPaginationSizeKey]: attemptsTableState.itemsPerPage,
            },
            data: payload,
          });

        const nextDataState = transformAttemptsData(response.data);

        setAttemptsTableState((prevDataState) => ({
          ...prevDataState,
          ...nextDataState,
        }));

        showModalSuccess(`${nextDataState.itemsCount} rows have been updated.`);
      } catch (err: any) {
        logAsyncOperationError("bulkUpdateAttemptsList", err);
        showModalError(
          "Error occurred while attempts list bulk update. Please, try again later."
        );
      } finally {
        closeConfirmationModal();
      }
    },
    [
      attemptsTableState.activePage,
      attemptsTableState.filtersQuery,
      attemptsTableState.itemsPerPage,
      setAttemptsTableState,
      fetchArgusFilteringAPI,
      closeConfirmationModal,
      showModalSuccess,
      showModalError,
    ]
  );

  const handleShowBulkUpdateForm = useCallback(() => {
    const header = (
      <Text>
        Bulk Update User Attempts&nbsp;
        {attemptsTableState.itemsCount ? (
          <small>({attemptsTableState.itemsCount} rows selected)</small>
        ) : (
          ""
        )}
      </Text>
    );
    const message = (
      <AttemptsBulkUpdateForm
        itemsCount={attemptsTableState.itemsCount}
        onCancel={closeConfirmationModal}
        onApply={handleBulkUpdate}
      />
    );

    showConfirmationModal(message, header);
  }, [
    attemptsTableState.itemsCount,
    handleBulkUpdate,
    closeConfirmationModal,
    showConfirmationModal,
  ]);

  const handleScrollToSummary: React.MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      e?.preventDefault();
      if (summaryRef.current == null) return;

      const summaryElement = ReactDOM.findDOMNode(summaryRef.current) as Element | null;

      summaryElement?.scrollIntoView?.();
    },
    []
  );

  return (
    <Stack>
      <QuestionsExportConfig
        show={questionExportModalState}
        itemsTotal={itemsCount}
        itemsPerPage={itemsPerPage}
        getQueryArgs={handleGetExportQueryArgs}
        getFormData={handleGetExportFormData}
        onClose={closeQuestionExportModal}
        onError={handleExportError}
      />
      <RatesExportConfig
        show={rateExportModalState}
        itemsTotal={itemsCount}
        itemsPerPage={itemsPerPage}
        getQueryArgs={handleGetExportQueryArgs}
        getFormData={handleGetExportFormData}
        onClose={closeRateExportModal}
        onError={handleExportError}
      />
      <PaymentReportForm
        show={paymentReportModalState}
        onMakeReport={handleMakeReport}
        onClose={closePaymentReportModal}
      />
      <BackToDashboardBlock title="Manage Validations" />
      <Card fill>
        <CardActions>
          <CardActionsLeft>
            <CardHeaderTitle as="h3">Validations List</CardHeaderTitle>
          </CardActionsLeft>
          <CardActionsRight>
            <Button
              icon={["far", "file-excel"]}
              size="small"
              onClick={showQuestionExportModal}
            >
              Export Questions Feedback
            </Button>
            <Button
              icon={["far", "file-excel"]}
              size="small"
              onClick={showRateExportModal}
            >
              Export Rates Feedback
            </Button>
            <Button
              icon={["far", "edit"]}
              size="small"
              onClick={handleShowBulkUpdateForm}
            >
              Bulk Update
            </Button>
          </CardActionsRight>
        </CardActions>
        <CardBody>
          <Stack css={{ gap: "$1", alignItems: "start" }}>
            {surveysValidationUIUrl && (
              <Text>
                <Text thin>
                  <Icon icon="info-circle" />
                  &nbsp; Link to the surveys validation UI -
                </Text>
                &nbsp;
                <Link
                  href={surveysValidationUIUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {surveysValidationUIUrl}
                </Link>
              </Text>
            )}
            <Text>
              <Text nowrap thin>
                <Icon icon="info-circle" />
                &nbsp; See the total reward value earned by our validators -
              </Text>
              &nbsp;
              <Link href="#summary" onClick={handleScrollToSummary}>
                see total reward
              </Link>
            </Text>
            <Text nowrap thin>
              <Icon icon="info-circle" />
              &nbsp; Table below has default filtering, it doesn't display "Skipped"
              validations or marked as "Analysis Completed".
            </Text>
          </Stack>
          <AttemptsTableView />
        </CardBody>
        {rewardsSummary.totalCount > 0 && rewardsSummary.totalReward && (
          <CardBody ref={summaryRef} css={{ paddingTop: 0 }}>
            <Inline fill css={{ justifyContent: "space-between" }}>
              <Inline css={{ gap: "$2" }}>
                <Text color="dark">Total reward ({rewardsSummary.totalCount} rows):</Text>
                <Box
                  css={{
                    backgroundColor: "$brand",
                    padding: "$2 $10",
                    color: "$textLight",
                  }}
                >
                  <Text>{decimalFormatter(rewardsSummary.totalReward, false, "$")}</Text>
                </Box>
              </Inline>
              <NavigationButton
                icon="chart-bar"
                onClick={showPaymentReportModal}
                css={{ width: "190px" }}
              >
                Payment Report
              </NavigationButton>
            </Inline>
          </CardBody>
        )}
      </Card>
      {pagesNumber > 1 && <BackToDashboardBlock />}
    </Stack>
  );
};
AttemptsListPage.displayName = "AttemptsListPage";

export default AttemptsListPage;
