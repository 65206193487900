export const RATE_TYPES = {
  NULL: 0,
  HOURLY: 1,
  DAILY: 2,
  WEEKLY: 3,
  MONTHLY: 4,
  ANNUALLY: 5,
};

export const PAY_TYPES = {
  PAY_RATE: 1,
  BILL_RATE: 2,
  SALARY_RATE: 3,
};

export const LEGACY_COUNTRIES_IDS = [840, 843, 844];

export const SALARY_RATE_TYPE_OPTIONS = {
  label: "Annually",
  unitLabel: "Annual",
  value: RATE_TYPES.ANNUALLY,
  convertToHourly: (unit) => unit,
  convertFromHourly: (unit) => unit,
};

export const PAY_TYPE_OPTIONS = {
  [PAY_TYPES.PAY_RATE]: {
    key: 1,
    label: "Pay Rate",
    enumValue: "PayRate",
    value: PAY_TYPES.PAY_RATE,
  },
  [PAY_TYPES.BILL_RATE]: {
    key: 2,
    label: "Bill Rate",
    enumValue: "BillRate",
    value: PAY_TYPES.BILL_RATE,
  },
  [PAY_TYPES.SALARY_RATE]: {
    key: 3,
    label: "Salary Rate",
    enumValue: "Salary",
    value: PAY_TYPES.SALARY_RATE,
  },
};

export const RATE_TYPE_OPTIONS = {
  [RATE_TYPES.HOURLY]: {
    key: 1,
    label: "Hourly",
    unitLabel: "Hour",
    value: RATE_TYPES.HOURLY,
    convertToHourly: (unit) => unit,
    convertFromHourly: (unit) => unit,
  },

  [RATE_TYPES.DAILY]: {
    key: 2,
    label: "Daily",
    unitLabel: "Day",
    value: RATE_TYPES.DAILY,
    convertToHourly: (unit, { hoursInDay = 8 }) => unit / hoursInDay,
    convertFromHourly: (unit, { hoursInDay = 8 }) => unit * hoursInDay,
  },

  [RATE_TYPES.WEEKLY]: {
    key: 3,
    label: "Weekly",
    unitLabel: "Week",
    value: RATE_TYPES.WEEKLY,
    convertToHourly: (unit, { hoursInWeek = 40 }) => unit / hoursInWeek,
    convertFromHourly: (unit, { hoursInWeek = 40 }) => unit * hoursInWeek,
  },

  [RATE_TYPES.MONTHLY]: {
    key: 4,
    label: "Monthly",
    unitLabel: "Month",
    value: RATE_TYPES.MONTHLY,
    convertToHourly: (unit, { hoursInMonth = 160 }) => unit / hoursInMonth,
    convertFromHourly: (unit, { hoursInMonth = 160 }) => unit * hoursInMonth,
  },
};
