import React from "react";

const FilterButton = (props) => (
  <div className="btn-filter" onClick={props.onClick || null}>
    <span className="children">
      {props.children} <i>▼</i>
    </span>
  </div>
);

export default FilterButton;
