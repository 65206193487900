import React, { ReactNode, Suspense, useCallback } from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
// relay
import { FormRateSearch_viewer$key } from "./__generated__/FormRateSearch_viewer.graphql";
// store
import { useRateSearchFormStore } from "../../../stores/RateSearchForm";
// components
import { Heading } from "./Heading";
import { JobTitleField } from "./JobTitleField";
import { IndustriesField } from "./IndustriesField";
import JobLabelField from "./JobLabelField";
import { LocationsField } from "./LocationsField";
import { RegionsField, RegionsFieldLoader } from "./RegionsField";
import { GssCheckbox } from "./GssCheckbox";
import { RateTypeGroup } from "./RateTypeGroup";
import { RoleTypeGroup } from "./RoleTypeGroup";
import { WorkerTypeField, WorkerTypeFieldLoader } from "./WorkerTypeField";
import { DescriptionField, DescriptionLoader } from "./DescriptionField";
import RsSkillsWithJobDescription, { JobTittleSkillsLoader } from "../JobTittleSkills";
import { SupportedCountries, SupportedCountriesLoader } from "./SupportedCountries";
import { useAutofillJobTitle } from "../hooks/useAutofillJobTitle";
import { SubmitRateSearch } from "./SubmitRateSearch";
import { FieldWrapper, FormCard, FormStack } from "./styled";
import { useGlobalContext } from "../../../globalContext";
import { jobTitleObserver } from "../observers";

const fragment = graphql`
  fragment FormRateSearch_viewer on Viewer {
    user {
      ...HeadingForm_user
      ...SubmitRateSearch_user
    }
    ...IndustriesField_viewer
    ...WorkerTypeField_viewer
    ...RegionsField_viewer
    ...SupportedCountries_viewer
    ...DescriptionField_viewer
  }
`;

type RsFormProps = {
  viewer: FormRateSearch_viewer$key;
  SideMenuHeading?: ReactNode;
  isSideMenu: boolean;
};

export function Form(props: RsFormProps) {
  const { viewer, SideMenuHeading = null, isSideMenu } = props;
  const jobTitleId = useRateSearchFormStore((s) => s.jobTitle?.id);
  const setJobTitle = useRateSearchFormStore((s) => s.setJobTitle);
  const description = useRateSearchFormStore((s) => s.description);
  const locations = useRateSearchFormStore((s) => s.locations);
  const setLocations = useRateSearchFormStore((s) => s.setLocations);
  const regions = useRateSearchFormStore((s) => s.regions);
  const setRegions = useRateSearchFormStore((s) => s.setRegions);

  const data = useFragment(fragment, viewer);

  const { isClientJobLibrary } = useGlobalContext();

  const onJobTitleChange = useCallback<typeof setJobTitle>(
    (job) => {
      setJobTitle(job);
      jobTitleObserver.emit(job);
    },
    [setJobTitle]
  );

  useAutofillJobTitle(onJobTitleChange);

  return (
    <FormCard>
      <FormStack>
        {SideMenuHeading || <Heading user={data.user} />}
        <FieldWrapper>
          <JobTitleField onJobTitleChange={onJobTitleChange} isSideMenu={isSideMenu} />
          <JobLabelField />
          <IndustriesField viewer={data} />
          <LocationsField
            shouldClear
            hasRegions={regions.length > 0}
            locations={locations}
            setLocations={setLocations}
            isSideMenu={isSideMenu}
          />
          <Suspense fallback={<RegionsFieldLoader />}>
            <RegionsField
              hasLocations={locations.length > 0}
              viewer={data}
              regions={regions}
              setRegions={setRegions}
            />
          </Suspense>
          <Suspense fallback={<WorkerTypeFieldLoader />}>
            <WorkerTypeField viewer={data} />
          </Suspense>
        </FieldWrapper>

        <FieldWrapper>
          <RoleTypeGroup />
          <RateTypeGroup />
        </FieldWrapper>

        <GssCheckbox />

        <Suspense fallback={isSideMenu ? null : <DescriptionLoader />}>
          <DescriptionField isSideMenu={isSideMenu} viewer={data} />
        </Suspense>

        {jobTitleId && isClientJobLibrary && description && !isSideMenu && (
          <Suspense fallback={<JobTittleSkillsLoader />}>
            <RsSkillsWithJobDescription jobDescription={description} />
          </Suspense>
        )}

        <Suspense fallback={<SupportedCountriesLoader />}>
          <SupportedCountries isSideMenu={isSideMenu} viewer={data} />
        </Suspense>
        <SubmitRateSearch isSideMenu={isSideMenu} user={data.user} />
      </FormStack>
    </FormCard>
  );
}
