import React from "react";

export type SelectSingleRowContextObject<T = number> = {
  selectedId: T | null;
  onChangeSelected: (selectedId: T | null) => void;
};

export const SelectSingleRowContext = React.createContext<SelectSingleRowContextObject>({
  selectedId: null,
  onChangeSelected: (selectedId) => {},
});

export const useSelectSingleRowContext = () => React.useContext(SelectSingleRowContext);
