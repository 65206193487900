import React, { Suspense, useEffect } from "react";
import graphql from "babel-plugin-relay/macro";
import { PayDifferenceMap_viewer$key } from "./__generated__/PayDifferenceMap_viewer.graphql";
import { PayDifferenceMapRefetch } from "./__generated__/PayDifferenceMapRefetch.graphql";
// @ts-ignore
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";
import Tooltip from "../../../../components/lib/Tooltip";
import Stack from "../../../../components/lib/Stack";
import Text from "../../../../components/lib/Text";
import { MapErrorBoundary } from "./MapErrorBoundary";
import { MapConfig, useMapConfig } from "./mapConfigs";
import { PayDifferenceSet } from "../../hooks/usePayDifference";
import { useRefetchableFragment } from "react-relay";
import { Country } from "../../types";
import { isNonNullable } from "../../../../utils/hashmap";

// ComposableMap has wrong types so we just ignore them for now
const SimpleMap = ComposableMap as any;

const baseGeographyStyle = {
  fill: "#dcdcdc",
  stroke: "#ffffff",
  strokeWidth: 1,
  outline: "none",
};

const geographyStyles = {
  default: baseGeographyStyle,
  hover: baseGeographyStyle,
  pressed: baseGeographyStyle,
};

const fragment = graphql`
  fragment PayDifferenceMap_viewer on Viewer
  @refetchable(queryName: "PayDifferenceMapRefetch")
  @argumentDefinitions(locationIDs: { type: "[String]", defaultValue: [] }) {
    locationsByIds(ids: $locationIDs) {
      locationId @required(action: NONE)
      lat @required(action: NONE)
      lon @required(action: NONE)
    }
  }
`;

type Props = {
  activeLocationId: number;
  viewer: PayDifferenceMap_viewer$key;
  searches: PayDifferenceSet;
  setActiveLocationId: (newLocation: number) => void;
};

function MapMarkers(props: Props & { mapConfig: MapConfig }) {
  const { searches, activeLocationId, setActiveLocationId, viewer, mapConfig } = props;

  // Lat and Lon are not exposed on savedsearches so another query needs to be done.
  const [data, refetch] = useRefetchableFragment<
    PayDifferenceMapRefetch,
    PayDifferenceMap_viewer$key
  >(fragment, viewer);

  /**
   * TODO: Fix refetch in useEffect:
   * ! Make latitude and longitude available from `Viewer.savedsearches` query.
   * ! Use some sort of sub/unsub pattern.
   *
   * * `locationsByIds` is initially empty.
   * * this `useEffect` lazy loads `locationsByIds` when savedsearches (searches) are updated.
   */
  useEffect(() => {
    refetch({
      locationIDs: searches
        .map((s) => s.locationId)
        .filter(isNonNullable)
        .map(String),
    });
  }, [searches, refetch]);

  function onMouseOverHandler(location: PayDifferenceSet[number]) {
    setActiveLocationId(location.locationId || 0);
  }

  function onMouseOutHandler() {
    setActiveLocationId(0);
  }

  function handleFillColor(location: PayDifferenceSet[number]) {
    if (location.payDifference > 0) {
      return "#BC2828";
    } else if (location.payDifference < 0) {
      return "#0CCE6B";
    } else {
      return "#0377fc";
    }
  }

  return (
    <>
      {searches.map((search) => {
        if (search.region || !search.rateString) return null;

        const location = data.locationsByIds?.find(
          (l) => Number(l?.locationId) === search.locationId
        );

        if (!location) return null;

        const [rate, sufix, percentage] = search.rateString.split("/");
        const isActive = search.locationId === activeLocationId;
        return (
          <Marker
            key={search.key}
            onMouseEnter={() => onMouseOverHandler(search)}
            onMouseLeave={() => onMouseOutHandler()}
            coordinates={[Number(location?.lon), Number(location?.lat)]}
          >
            <circle
              style={{ cursor: "pointer" }}
              fill={handleFillColor(search)}
              fillOpacity={isActive ? "0.4" : "0.2"}
              r={mapConfig.markerScale * 1.25}
            />
            <Tooltip
              contentProps={{ light: true }}
              open={isActive}
              content={
                <Stack id={search.locationId + "-popover"} nogap>
                  <Text color="dark" as="b">
                    {rate}
                    <sup>/{sufix}</sup> {percentage ? `/ ${percentage}` : null}
                  </Text>
                  <Text css={{ fontSize: "$sm" }}>{search.locationFullLabel}</Text>
                </Stack>
              }
            >
              <circle
                style={{ cursor: "pointer" }}
                fill={handleFillColor(search)}
                fillOpacity={search.locationId === activeLocationId ? "0.8" : "0.4"}
                r={mapConfig.markerScale}
              />
            </Tooltip>
          </Marker>
        );
      })}
    </>
  );
}

export function PayDifferenceMap(props: Props & { countries: Country[] }) {
  const { countries, ...mapProps } = props;
  const mapConfig = useMapConfig(props.searches, countries);
  return (
    <MapErrorBoundary>
      <SimpleMap
        style={{ overflow: "visible" }}
        projection={mapConfig.projection}
        projectionConfig={mapConfig}
      >
        <Geographies geography={mapConfig.topoURL}>
          {({ geographies }: any) =>
            geographies.map((geo: any, index: any) => (
              <Geography key={index} geography={geo} style={geographyStyles} />
            ))
          }
        </Geographies>
        <Suspense fallback={null}>
          <MapMarkers {...mapProps} mapConfig={mapConfig} />
        </Suspense>
      </SimpleMap>
    </MapErrorBoundary>
  );
}
