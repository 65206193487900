import React from "react";
import classNames from "classnames";
// @ts-expect-error
import numeral from "numeral";
import { Values } from "../types";

const baseClassName = "pt-pi-savings-bar";

const SHORT_DECIMAL_FORMAT = "0,0.[00]a";
const DECIMAL_FORMAT = "0,0.[00]";
const decimalFormatter = (
  value: number,
  formatString = DECIMAL_FORMAT,
  units: string | null = null
) => {
  return value != null
    ? (units ? ` ${units} ` : "") + numeral(value).format(formatString)
    : "";
};

export const SAVINGS_BAR_CHART_SIZES = {
  SMALL: "small",
  REGULAR: "regular",
} as const;

export type SAVINGS_BAR_CHART_SIZES_TYPE = Values<typeof SAVINGS_BAR_CHART_SIZES>;

interface SavingsBarChartProps {
  value: number;
  minValue: number;
  maxValue: number;
  currencySymbol?: string | null;
  size?: SAVINGS_BAR_CHART_SIZES_TYPE;
}

const SavingsBarChart = (props: SavingsBarChartProps) => {
  const { value, minValue, maxValue, currencySymbol, size } = props;

  const barTotalLength =
    Math.max(value, minValue, maxValue) - Math.min(value, minValue, maxValue);

  // values ticks position
  let minValueTickPosition = 0;
  let maxValueTickPosition = "100%";
  let valueTickPosition: number | string = 0;
  if (value < minValue) {
    // minValueTickPosition = '' + ((minValue - value) / barTotalLength * 100) + '%';
    minValueTickPosition = 0;
    valueTickPosition = 0;
  } else if (value > maxValue) {
    // maxValueTickPosition = '' + ((maxValue - minValue) / barTotalLength * 100) + '%';
    maxValueTickPosition = "100%";
    valueTickPosition = "100%";
  } else {
    valueTickPosition = "" + ((value - minValue) / barTotalLength) * 100 + "%";
  }

  // limits line (gray)
  let limitsLinePosition = minValueTickPosition;
  // let limitsLineWidth = '' + (Math.abs(maxValue - minValue) / barTotalLength * 100) + '%'
  let limitsLineWidth = "100%";

  // savings lines (green/red)
  let potentialSavingsLinePosition = 0;
  let potentialSavingsLineWidth: string | number = 0;
  let achievedSavingsLinePosition: string | number = 0;
  let achievedSavingsLineWidth: string | number = 0;
  if (value < minValue) {
    achievedSavingsLinePosition = valueTickPosition;
    achievedSavingsLineWidth = "" + ((maxValue - value) / barTotalLength) * 100 + "%";
  } else if (value > maxValue) {
    potentialSavingsLinePosition = minValueTickPosition;
    potentialSavingsLineWidth = "" + ((value - minValue) / barTotalLength) * 100 + "%";
  } else {
    potentialSavingsLinePosition = minValueTickPosition;
    potentialSavingsLineWidth = "" + ((value - minValue) / barTotalLength) * 100 + "%";
    achievedSavingsLinePosition = valueTickPosition;
    achievedSavingsLineWidth = "" + ((maxValue - value) / barTotalLength) * 100 + "%";
  }

  let formatString = DECIMAL_FORMAT;
  if (size === SAVINGS_BAR_CHART_SIZES.SMALL) {
    formatString = SHORT_DECIMAL_FORMAT;
  }

  const componentClassName = classNames(baseClassName, {
    [baseClassName + "--" + size]: !!size,
  });

  return (
    <div className={componentClassName}>
      <div className={baseClassName + "__bar"}>
        <div
          className={baseClassName + "__limits-line"}
          style={{ left: limitsLinePosition, width: limitsLineWidth }}
        />
        <div
          className={baseClassName + "__pot-savings-line"}
          style={{ left: potentialSavingsLinePosition, width: potentialSavingsLineWidth }}
        />
        <div
          className={baseClassName + "__ach-savings-line"}
          style={{ left: achievedSavingsLinePosition, width: achievedSavingsLineWidth }}
        />

        <div
          className={baseClassName + "__min-value-tick"}
          style={{ bottom: "0", left: minValueTickPosition }}
        >
          <div className={baseClassName + "__relative-wrapper"}>
            <div className={baseClassName + "__value-min"}>
              <span>
                <b>{decimalFormatter(minValue, formatString, currencySymbol)}</b> min
              </span>
            </div>
          </div>
        </div>
        <div
          className={baseClassName + "__value-tick"}
          style={{ top: "0", left: valueTickPosition }}
        >
          <div className={baseClassName + "__relative-wrapper"}>
            <div className={baseClassName + "__value-bottom"}>
              <div className={baseClassName + "__value-centerer"}>
                <div>
                  <b>{decimalFormatter(value, formatString, currencySymbol)}</b> your rate
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={baseClassName + "__max-value-tick"}
          style={{ bottom: "0", left: maxValueTickPosition }}
        >
          <div className={baseClassName + "__relative-wrapper"}>
            <div className={baseClassName + "__value-max"}>
              <span>
                <b>{decimalFormatter(maxValue, formatString, currencySymbol)}</b> max
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SavingsBarChart.displayName = "SavingsBarChart";
SavingsBarChart.defaultProps = {
  currencySymbol: null,
  size: SAVINGS_BAR_CHART_SIZES.REGULAR,
};

export default SavingsBarChart;
