// @flow

import R from "ramda";
import { extendObservable, action, runInAction, computed, observable } from "mobx";
import axios from "axios";
import PaginationState from "../../../models/PaginationState";
import NetworkState from "../../../models/NetworkState";
import type { PageQuery, PaginationInfo } from "../../../models/PaginationState";
import LocationList from "../../../models/LocationList";
import RegionList from "../../../models/RegionList";
import FilterObject, { FILTER_COLUMN } from "../../../models/Filter";
import { HasLocationTypeFilter } from "../../../models/FilterState";
import {
  addIdToPayload,
  consolidateAppliedFilters,
  consolidateAppliedSorts,
} from "../SupportFunctions";
import ModalState from "../../../models/ModalState";
import MessageState from "../../../models/MessageState";
import ApplyTagState from "../../../models/ApplyTagState";
import type { FetchGraphQL } from "../../../App";

const locationTypesCriteriaQuery = `
query {
  viewer {
    rateCardsFilterCriteria {
      rateTypes
    }
  }
}
`;

export class LocationListComponentPTStore {
  getRegionDetail: (PageQuery) => Promise<PaginationInfo>;
  network: NetworkState;
  pagination: PaginationState;
  totalCount: any;
  regionsList: LocationList[];
  regionsListView: LocationList[];
  regionsListViewState: Object;
  createdOnFilter: CreatedOnFilter;
  showLocationPopup: boolean;
  showModel: () => void;
  closeModel: () => void;
  defaultFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedSorts: {
    [key: FilterColumn]: Sort,
  };
  isFiltered: boolean;
  appliedSortsOrder: Array<FilterColumn>;
  applyDefaultFilter: (FilterColumn, FilterObject) => void;
  applyFilter: (FilterColumn, FilterObject) => void;
  removeFilter: (FilterColumn) => void;
  applySort: (FilterColumn, Sort) => void;
  removeSort: (FilterColumn) => void;
  clearFilters: () => void;
  allSelected: boolean;
  allOnPageSelected: boolean;
  allowMultipleItemSelection: boolean;
  confirmDeleteModal: ModalState;
  confirmLocationDeleteModal: ModalState;
  getSelectedRegionsList: () => Array<string>;
  regionFilter: RegionFilter;
  countryFilter: CountryFilter;
  regionId: number;
  regionDetail: RegionList;
  renameRegionModal: ModalState;
  hasLocationTypeFilter: HasLocationTypeFilter;
  getFilterCriteriaQuery: (FilterColumn) => GraphQLQuery;
  processFilterCriteria: (FilterColumn, Object) => Array<Object>;
  showHelp: () => void;
  hideHelp: () => void;
  showHelpModal: boolean;
  messaging: MessageState;
  fetchGraphQL: FetchGraphQL;

  constructor(fetchGraphQL: FetchGraphQL) {
    this.fetchGraphQL = fetchGraphQL;
    this.router = null;
    this.getRegionDetail = action(this.getRegionDetail.bind(this));
    this.showHelp = action(this.showHelp.bind(this));
    this.hideHelp = action(this.hideHelp.bind(this));
    this.applyFilter = action(this.applyFilter.bind(this));
    this.applySort = action(this.applySort.bind(this));
    this.removeFilter = action(this.removeFilter.bind(this));
    this.removeSort = action(this.removeSort.bind(this));
    // this.renameRegion = action(this.renameRegion.bind(this));
    this.getFilterCriteriaQuery = action(this.getFilterCriteriaQuery.bind(this));
    this.processFilterCriteria = action(this.processFilterCriteria.bind(this));
    // this.showModel = action(this.showModel.bind(this));
    // this.closeModel = action(this.closeModel.bind(this));
    // this.openLocation = action(this.openLocation.bind(this));

    extendObservable(this, {
      showHelpModal: false,
      network: new NetworkState(),
      pagination: new PaginationState(this.getRegionDetail),
      totalCount: 0,
      regionsList: [],
      regionDetail: {},
      showLocationPopup: false,
      regionId: null,
      regionsListViewState: observable.map({}),
      regionsListView: computed(() => {
        return this.regionsList.map((region) => {
          if (this.regionsListViewState.has(region.regionId)) {
            region.viewState = this.regionsListViewState.get(region.regionId);

            return region;
          }

          return region;
        });
      }),
      allOnPageSelected: computed(() => {
        const allTrue = R.all(R.equals(true));
        const selectedValues = this.regionsListView.map(
          (regionsListView) => regionsListView.viewState.selected
        );

        if (selectedValues.length === 0) {
          return false;
        }

        return allTrue(selectedValues);
      }),
      appliedSortsOrder: observable.shallow([]),
      defaultFilters: {},
      appliedFilters: {},
      appliedSorts: {},
      isFiltered: false,
      // isEditing: null,
      allowMultipleItemSelection: true,
      selectedCount: computed(() => {
        const selectedValues = this.regionsListView.map(
          (region) => region.viewState.selected
        );

        if (this.allSelected) {
          return this.pagination.totalCount;
        }

        let count = 0;

        selectedValues.forEach((v) => {
          if (v) {
            count += 1;
          }
        });

        return count;
      }),
      allSelected: false,
      confirmDeleteModal: new ModalState(),
      confirmLocationDeleteModal: new ModalState(),
      renameRegionModal: new ModalState(),
      newRegionName: "",
      hasLocationTypeFilter: new HasLocationTypeFilter(
        this,
        FILTER_COLUMN.LOCATION_TYPE,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.removeFilter
      ),
      messaging: new MessageState(),
      applyTagState: new ApplyTagState(fetchGraphQL, this),
    });

    this.applyDefaultFilter = action(this.applyDefaultFilter.bind(this));
    this.clearFilters = action(this.clearFilters.bind(this));
    this.getSelectedRegionsList = action(this.getSelectedRegionsList.bind(this));
  }

  applyFilter(column: FilterColumn, filter: FilterObject) {
    this.appliedFilters[column] = filter;
    this.isFiltered = true;
  }

  applyDefaultFilter(column: FilterColumn, filter: FilterObject) {
    this.defaultFilters[column] = filter;
  }

  removeFilter(column: FilterColumn) {
    delete this.appliedFilters[column];

    let entries = Object.entries(this.appliedFilters);
    if (!entries.length) this.isFiltered = false;
  }

  applySort(column: FilterColumn, sort: Sort) {
    this.appliedSorts[column] = sort;

    const index = this.appliedSortsOrder.indexOf(column);
    if (index === -1) this.appliedSortsOrder.push(column);
  }

  removeSort(column: FilterColumn) {
    delete this.appliedSorts[column];

    const index = this.appliedSortsOrder.indexOf(column);
    if (index > -1) this.appliedSortsOrder.splice(index, 1);
  }

  //clear all filters
  clearFilters() {
    this.hasLocationTypeFilter = new HasLocationTypeFilter(
      this,
      FILTER_COLUMN.LOCATION_TYPE,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.removeFilter
    );

    this.appliedFilters = observable({});
    this.appliedSorts = observable({});
    this.appliedSortsOrder.length = 0;
    this.isFiltered = false;

    return this.pagination.goFetch(null);
  }

  getFilterCriteriaQuery(column: FilterColumn): GraphQLQuery {
    switch (column) {
      case FILTER_COLUMN.LOCATION_TYPE:
        return {
          query: locationTypesCriteriaQuery,
          variables: {},
        };

      default:
        return null;
    }
  }

  processFilterCriteria(column: FilterColumn, payload: Object): ?Array<Object> {
    switch (column) {
      case FILTER_COLUMN.LOCATION_TYPE:
        const rateTypes: [String] = ["City", "State"];
        return addIdToPayload(rateTypes);

      default:
        return null;
    }
  }

  toggleAllItems() {
    if (!this.allowMultipleItemSelection) return;

    this.allSelected = !this.allSelected;

    if (this.allSelected === false) {
      this.projectCostsView.forEach((value) => {
        value.viewState.selected = false;
      });
    }
  }

  getSelectedRegionsList(): Array<string> {
    const regionsList = this.regionsListViewState;

    let selectedregionsList = [];

    regionsList.forEach((value, key) => {
      if (value.selected) {
        selectedregionsList.push(key);
      }
    });

    return selectedregionsList;
  }

  showHelp() {
    this.showHelpModal = true;
  }

  hideHelp() {
    this.showHelpModal = false;
  }

  //function for get region Detail
  async getRegionDetail(pageQuery: PageQuery): Promise<PaginationInfo> {
    let res = null;
    if (!/^\d+$/.test(this.regionId)) {
      if (this.router) {
        this.router.push({
          pathname: "/404NotFound",
          query: this.router.query,
        });
      }
      return res;
    }
    let params: string[] = [];
    let args = [];
    let variables = {};
    let filtersCriteria: string[] = [];

    let sortCriteria: string[] = [];
    consolidateAppliedSorts(this.appliedSorts, sortCriteria);

    consolidateAppliedFilters(this.appliedFilters, params, filtersCriteria, variables);

    // NOTE: applied filters could override default filters, handle this if needed
    //consolidateAppliedFilters(this.defaultFilters, params, filtersCriteria, variables);

    params.push("$id: Int!");
    args.push("id: $id");
    variables.id = this.regionId;

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");
    const queryFiltersCriteria = filtersCriteria.join(", ");

    const query = `
      query regiondetail(${queryParams}) {
               viewer {
                 region (${queryArgs},filters: { ${queryFiltersCriteria} }, section: ADMIN){
                   name
                   regionId
                  created
                  user {
                    firstName
                    lastName
                  }
                   country{
                     name
                     locationId
                   }
                   tags{
                    name
                    tagId
                   }
                   locations {
                    locationId
                     name
                     locationType {
                       value
                       locationTypeId
                     }
                   }
                 }
               }
              }

             `;

    this.network.loading = true;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting Regions", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Regions query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getRegionDetail--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get Region query", res)) {
        // TODO: Display user friendly error message
        if (!res.data.viewer.region) {
          if (this.router) {
            this.router.push({
              pathname: "/404NotFound",
              query: this.router.query,
            });
          }
          return;
        }
        // return {
        //   totalCount: 0
        // };
      }

      const regions = res.data.viewer.region.locations;
      this.regionDetail = new RegionList(this, res.data.viewer.region);
      this.totalCount = regions.length;
      // // TODO: Deserialize this properly...
      this.regionsList = regions.map((reg) => {
        const region = new LocationList(this, reg);

        if (!this.regionsListViewState.has(region.id)) {
          this.regionsListViewState.set(region.id, {
            selected: this.allSelected,
            editing: this.isEditing,
          });
        } else {
          const selectedValue = this.allSelected
            ? true
            : this.regionsListViewState.get(region.id).selected;

          this.regionsListViewState.set(region.id, {
            selected: selectedValue,
            editing: this.isEditing,
          });
        }

        region.viewState = this.regionsListViewState.get(region.id);

        return region;
      });
      return {
        totalCount: res.data.viewer.region.locations.length,
      };
    });
  }
}

export default class LocationListStore extends LocationListComponentPTStore {}
