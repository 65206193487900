import React from "react";

import { styled, CSS } from "../../../stitches.config";
import { TR as TRBase, TD as TDBase } from "../../lib/Table";
import Box from "../../lib/Box";

export const TR = styled(TRBase, {
  borderBottom: "1px solid $primaryLight",
  "&:last-child": {
    borderBottom: 0,
  },
});

export const TD = styled(TDBase, {
  textAlign: "center",
});

type TableBodyRowImplProps = {
  selected?: boolean;
  failed?: boolean;
  onClick?: (e: React.SyntheticEvent) => void;
  children: React.ReactNode;
  css?: CSS;
};

export const TableBodyRowImpl = (props: TableBodyRowImplProps) => {
  const { selected, failed, children, onClick, css } = props;

  return (
    <TR selected={selected} failed={failed} onClick={onClick} css={css}>
      {children}
    </TR>
  );
};

TableBodyRowImpl.displayName = "TableBodyRowImpl";
TableBodyRowImpl.defaultProps = {
  selected: false,
  failed: false,
};

type TableBodyCellImplProps = {
  selected?: boolean;
  failed?: boolean;
  onClick?: (e: React.SyntheticEvent) => void;
  children: React.ReactNode;
  css?: CSS;
};

export const TableBodyCellImpl = (props: TableBodyCellImplProps) => {
  const { selected, failed, children, onClick, css } = props;

  return (
    <TD selected={selected} failed={failed} onClick={onClick} css={css}>
      {children}
    </TD>
  );
};

TableBodyCellImpl.displayName = "TableBodyCellImpl";
TableBodyCellImpl.defaultProps = {
  selected: false,
  failed: false,
};

export const TableBodyEmptyCellImpl = (props: TableBodyCellImplProps) => {
  const { selected, failed, children, onClick, css } = props;

  return (
    <TD
      selected={selected}
      failed={failed}
      colSpan={999}
      tabIndex={0}
      onClick={onClick}
      css={{
        height: "47px",
        lineHeight: "47px",
        verticalAlign: "top !important",
        padding: 0,
        ...css,
      }}
    >
      <Box
        css={{
          position: "absolute",
          left: 0,
          right: 0,
          textAlign: "center",
          verticalAlign: "middle",
        }}
      >
        {children}
      </Box>
    </TD>
  );
};

TableBodyEmptyCellImpl.displayName = "TableBodyEmptyCellImpl";
TableBodyEmptyCellImpl.defaultProps = TableBodyCellImpl.defaultProps;
