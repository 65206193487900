import React from "react";
import { formatRate } from "../../utils/MoneyUtils";
import classNames from "classnames";

export default class ArrowChart extends React.Component {
  render() {
    let { data } = this.props,
      { symbol } = data.currency;

    return (
      <div className={classNames("arrow-chart", this.props.className)}>
        <div className="arrow-chart-your-rate" data-testid="rates-data-median-value">
          Median {data.rateType === 1 ? "Contract" : "FTE"} Pay Rate:{" "}
          {formatRate(data.rateType, data.mid, symbol)}
        </div>
        <ul className="arrow-chart-top-labels">
          <li>{formatRate(data.rateType, data.percentiles[10], symbol)}</li>
          <li>{formatRate(data.rateType, data.percentiles[25], symbol)}</li>
          <li>
            <div className="arrow" />
          </li>
          <li>{formatRate(data.rateType, data.percentiles[75], symbol)}</li>
          <li>{formatRate(data.rateType, data.percentiles[90], symbol)}</li>
        </ul>
        <ul className="arrow-chart-graph">
          <li />
          <li />
          <li />
          <li />
          <li />
          <li />
        </ul>
        <ul className="arrow-chart-labels">
          <li>10%</li>
          <li>25%</li>
          <li>50%</li>
          <li>75%</li>
          <li>90%</li>
        </ul>
      </div>
    );
  }
}
