import PropTypes from "prop-types";
import React, { Component } from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

class CheckBox extends Component {
  handleOnChange = (e) => {
    if (this.props.onChange) {
      if (!this.props.item && this.props.value) this.props.onChange(this.props.value);
      else if (this.props.item && !this.props.value)
        this.props.onChange(this.props.value);
      else if (this.props.item && this.props.value)
        this.props.onChange(this.props.item, this.props.value);
      else this.props.onChange(e);
    }
  };

  handleOnClick = (e) => {
    if (this.props.onClick) {
      if (!this.props.item && this.props.value) this.props.onClick(this.props.value);
      else if (this.props.item && !this.props.value) this.props.onClick(this.props.value);
      else if (this.props.item && this.props.value)
        this.props.onClick(this.props.item, this.props.value);
      else this.props.onClick(e);
    }
  };

  render() {
    const props = this.props;

    return (
      <label
        key={props.key}
        className={classNames("rc-checkbox-component", { selected: props.selected })}
        style={props.style}
      >
        <input
          type={props.type}
          value={props.value}
          checked={props.selected}
          onChange={this.handleOnChange}
          onClick={this.handleOnClick}
          data-testid="search-items-checkbox"
        />
        <div className="media no-margin">
          <div className="media-left media-top">
            {props.type === "checkbox" && (
              <FontAwesomeIcon
                icon={props.selected ? "check-square" : "square"}
                className={classNames("rc-check-icon", {
                  selected: props.selected,
                })}
              />
            )}
            {props.type === "radio" && (
              <FontAwesomeIcon
                icon={props.selected ? "dot-circle" : "circle"}
                className={classNames("rc-radio-icon", {
                  selected: props.selected,
                })}
              />
            )}
          </div>
          <div className="media-body">{props.children}</div>
        </div>
      </label>
    );
  }
}

CheckBox.propTypes = {
  item: PropTypes.object,
  type: PropTypes.string, // radio, checkbox
  name: PropTypes.string,
  value: PropTypes.any,
  selected: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

CheckBox.defaultProps = {
  type: "checkbox",
  selected: false,
  onChange: (e) => e,
  onClick: (e) => e,
};

export default observer(CheckBox);
