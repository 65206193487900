import React from "react";

import type { CSS } from "../../../stitches.config";
import Stack from "../../../components/lib/Stack";
import Inline from "../../../components/lib/Inline";
import Icon from "../../../components/lib/Icon";

interface DiscoverySearchResultCounterProps {
  counter: number;
  icon: React.ReactElement<typeof Icon>;
  label: string;
  css?: CSS;
}

const DiscoverySearchResultCounter = ({
  counter,
  icon,
  label,
  css,
}: DiscoverySearchResultCounterProps) => {
  return (
    <Stack
      css={{
        gap: "$1",
        [`& ${Inline}:nth-child(1)`]: { fontSize: "$xl" },
        [`& ${Inline}:nth-child(2)`]: { fontSize: "$lg" },
        [`& ${Inline}:nth-child(3)`]: { fontSize: "$sm" },
        ...css,
      }}
    >
      <Inline>{counter}</Inline>
      <Inline>{icon}</Inline>
      <Inline>{label || " "}</Inline>
    </Stack>
  );
};

DiscoverySearchResultCounter.displayName = "DiscoverySearchResultCounter";
DiscoverySearchResultCounter.defaultProps = {
  counter: 0,
  icon: <Icon icon="question-circle" />,
  label: null,
};

export default DiscoverySearchResultCounter;
