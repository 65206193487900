// @flow

import PropTypes from "prop-types";

import React from "react";
import { observer } from "mobx-react";

const Container = (props) => (
  <div className={`rc-container ${props.className}`}>{props.children}</div>
);

Container.propTypes = {
  className: PropTypes.string,
};

Container.defaultProps = {
  className: "",
};

export default observer(Container);
