import React from "react";
import moment from "moment";

import { styled, CSS } from "../stitches.config";
import { inputBorderStyle } from "./lib/TextInput";

const DateField = styled("input", inputBorderStyle, {
  padding: "calc($2 + 1px) calc($3 + 1px)",
  fontSize: "$base",
  lineHeight: "$base",
  borderWidth: 1,
  "&:focus": {
    borderColor: "$brand",
  },
});

export type OnChangeDateHandler = (value: moment.Moment) => void;
export interface SingleDatePickerProps {
  id?: string;
  date?: string | moment.Moment | null;
  onDateChange?: OnChangeDateHandler;
  css?: CSS;
}

export const SingleDatePicker = React.forwardRef<HTMLInputElement, SingleDatePickerProps>(
  (props, ref) => {
    const { id, date, onDateChange, css } = props;

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
      (event) => {
        const dateValue = event.currentTarget.value;
        if (dateValue && onDateChange) {
          onDateChange(moment(dateValue));
        }
      },
      [onDateChange]
    );

    let controlledDate = "";
    if (moment.isMoment(date)) {
      controlledDate = date.format("YYYY-MM-DD");
    }

    return (
      <DateField
        ref={ref}
        type="date"
        id={id}
        value={controlledDate}
        onChange={handleChange}
        css={css}
      />
    );
  }
);
SingleDatePicker.displayName = "SingleDatePicker";

export default SingleDatePicker;
