import React from "react";
import moment from "moment-timezone";

import Text from "../../../components/lib/Text";
import Stack from "../../../components/lib/Stack";
import Table from "../lib/Table";
import { THead, TBody, TR, TH, TD } from "../../../components/lib/Table";
import { isNumber } from "../../../components/tables/utils";
import { decimalFormatter, DATE_TIME_FORMAT } from "../constants";
import { SKILLS_LEVELS, SKILLS_LEVELS_NUMERAL_OPTIONS } from "../types";

import type { CollectionLimitsMap } from "../types";
import { emptyList } from "../../../constants";

type RatesLimitsViewerProps = {
  limitsData: CollectionLimitsMap;
};

const RatesLimitsViewer = (props: RatesLimitsViewerProps) => {
  const { limitsData } = props;
  const freshnessTimestamp = limitsData.get("updated");
  const freshnessString =
    freshnessTimestamp != null
      ? moment(freshnessTimestamp).format(DATE_TIME_FORMAT)
      : null;
  const currencySymbol = limitsData.getIn(["country_info", "currency_symbol"], null);

  return (
    <Stack nogap css={{ alignItems: "flex-start" }}>
      <Table highlighted={false} center highlightFirstColumn>
        <THead>
          <TR>
            <TH>Limits</TH>
            <TH>Low</TH>
            <TH>High</TH>
            {SKILLS_LEVELS_NUMERAL_OPTIONS.map(({ value, label }) => {
              return <TH key={value}>{label}</TH>;
            })}
          </TR>
        </THead>
        <TBody>
          {[
            { type: "hourly", title: "Hourly" } as const,
            { type: "salary", title: "Annual" } as const,
          ].map(({ type, title }, idx) => {
            const levelsLimits = limitsData.getIn(["levelsLimits", type]) ?? emptyList;
            const lowLimitValue = limitsData.get(`${type}_low`);
            const highLimitValue = limitsData.get(`${type}_high`);

            return (
              <TR key={idx}>
                <TD>{title}</TD>
                <TD>
                  {isNumber(lowLimitValue)
                    ? decimalFormatter(lowLimitValue, false, currencySymbol)
                    : lowLimitValue}
                </TD>
                <TD>
                  {isNumber(highLimitValue)
                    ? decimalFormatter(highLimitValue, false, currencySymbol)
                    : highLimitValue}
                </TD>
                {Object.keys(SKILLS_LEVELS).map((key, idx) => {
                  const low = levelsLimits.getIn([idx, "low"]);
                  const high = levelsLimits.getIn([idx, "high"]);

                  return isNumber(low) && isNumber(high) ? (
                    <TD key={key}>
                      {decimalFormatter(low, false, currencySymbol)}
                      {" - "}
                      {decimalFormatter(high, false, currencySymbol)}
                    </TD>
                  ) : (
                    <TD key={key} />
                  );
                })}
              </TR>
            );
          })}
        </TBody>
      </Table>
      {freshnessString && (
        <Text thin size="xs">
          last updated: {freshnessString}
        </Text>
      )}
    </Stack>
  );
};

RatesLimitsViewer.displayName = "RatesLimitsViewer";

export default RatesLimitsViewer;
