// @flow

import React from "react";
import { observer } from "mobx-react";
import numeral from "numeral";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LEVEL_ROMAN_NUMERAL } from "../../../constants/levels";
import PunchOutRowState from "../../../models/PunchOutRowState";
import { PunchoutButton, PunchoutButtonGroup, TDForm } from "./HourlyPunchOutLevelRow";
import HTMLSelect from "../../../components/lib/HTMLSelect";
import TextInput from "../../../components/lib/TextInput";

type Props = {
  modalState: PunchOutRowState,
};

const AnnualPunchOutLevelRow = (props: Props) => {
  if (props.modalState.createMode && !props.modalState.modal.show) {
    return (
      <tr>
        <td className="buy-rate-level-icon" />
        <td colSpan="8" className="text-right">
          <PunchoutButtonGroup>
            <PunchoutButton color="brand" onClick={props.modalState.enterInputMode}>
              <FontAwesomeIcon icon="plus" className="icon" /> Add Level
            </PunchoutButton>
          </PunchoutButtonGroup>
        </td>
      </tr>
    );
  }

  let collapsedStateClass = "collapsed";
  if (!props.modalState.isCollapsed) collapsedStateClass = "expanded";

  let salaryMin = 0;
  let salaryMax = 0;

  if (props.modalState.punchOutRow) {
    salaryMin = props.modalState.punchOutRow.salaryMin;
    salaryMax = props.modalState.punchOutRow.salaryMax;
  }

  let currency = "";
  if (props.modalState.search.currency) {
    currency = props.modalState.search.currency.symbol;
  }

  let defaultView = (
    <tr>
      <td className={`buy-rate-level-icon ${props.modalState.getLevelSelector()}`}>
        {props.modalState.getLevelRomanNumeral()}
      </td>
      <td className="text-muted hidden-xs hidden-sm">
        Level {props.modalState.getLevelRomanNumeral()}
      </td>
      <td className="text-muted text-right fixed-size hidden-xs hidden-sm">
        {currency} {numeral(salaryMin).format("0,0.00")}
      </td>
      <td className="nte-column text-right fixed-size">
        <div className="hidden-xs hidden-sm">
          {currency} {numeral(salaryMax).format("0,0.00")}
        </div>
        {/* MOBILE VIEW */}
        <div className="hidden-md hidden-lg">
          <table className="xs-nte-table no-padding no-margin">
            <tbody>
              <tr>
                <td className="nte-column text-left">NTE Salary:</td>
                <td className="text-right">
                  {currency} {numeral(salaryMax).format("0,0.00")}
                </td>
              </tr>
            </tbody>
          </table>
          {/* COLLAPSIBLE VALUES */}
          <div
            className={`hidden-md hidden-lg buy-rate-collapsible ${collapsedStateClass}`}
          >
            <table className="table level-data buy-rates">
              <thead />
              <tbody className="text-muted">
                <tr>
                  <td className="text-left">Min Salary:</td>
                  <td className="text-right">
                    {currency} {numeral(salaryMin).format("0,0.00")}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </td>
      <td className="text-right">
        <PunchoutButtonGroup>
          <div className="hidden-xs hidden-sm hidden-ml">
            <PunchoutButton color="brand" onClick={props.modalState.enterInputMode}>
              <FontAwesomeIcon icon="edit" />
            </PunchoutButton>
            <PunchoutButton color="danger" onClick={props.modalState.delete}>
              <FontAwesomeIcon icon="trash-alt" className="danger-icon" />
            </PunchoutButton>
          </div>
          <div className="visible-ml">
            <PunchoutButton color="brand" onClick={props.modalState.enterInputMode}>
              <FontAwesomeIcon icon="edit" className="icon" /> Edit
            </PunchoutButton>
            <PunchoutButton color="danger" onClick={props.modalState.delete}>
              <FontAwesomeIcon icon="trash-alt" className="icon" /> Delete
            </PunchoutButton>
          </div>
          <div className="hidden-md hidden-lg">
            <PunchoutButton
              color="brand"
              onClick={props.modalState.toggleCollapsed}
              className="btn-sm-device"
            >
              <FontAwesomeIcon icon="chevron-down" className={collapsedStateClass} />
            </PunchoutButton>
            <div className={`text-right buy-rate-collapsible ${collapsedStateClass}`}>
              <PunchoutButton
                color="brand"
                className="first"
                onClick={props.modalState.enterInputMode}
              >
                <FontAwesomeIcon icon="edit" />
              </PunchoutButton>
              <PunchoutButton color="danger" onClick={props.modalState.delete}>
                <FontAwesomeIcon icon="trash-alt" />
              </PunchoutButton>
            </div>
          </div>
        </PunchoutButtonGroup>
      </td>
    </tr>
  );

  let inputView = (
    <tr className="inline-input">
      {!props.modalState.createMode && (
        <td className={`buy-rate-level-icon ${props.modalState.getLevelSelector()}`}>
          {props.modalState.getLevelRomanNumeral()}
        </td>
      )}
      {props.modalState.createMode && (
        <td
          className={`buy-rate-level-icon ${props.modalState.getLevelSelector()} hidden-xs hidden-sm`}
        >
          {props.modalState.getLevelRomanNumeral()}
        </td>
      )}
      {props.modalState.createMode && (
        <TDForm
          className={`buy-rate-level-icon ${props.modalState.getLevelSelector()} text-muted hidden-md hidden-lg`}
        >
          <HTMLSelect
            name="level"
            id="levelSelect"
            value={props.modalState.level}
            onChange={props.modalState.onLevelChange}
          >
            {props.modalState.getMissingLevels().map((level) => (
              <option key={level} value={level}>
                {LEVEL_ROMAN_NUMERAL[level]}
              </option>
            ))}
          </HTMLSelect>
        </TDForm>
      )}
      {props.modalState.createMode ? (
        <TDForm className="text-muted hidden-xs hidden-sm">
          <HTMLSelect
            name="level"
            id="levelSelect"
            value={props.modalState.level}
            onChange={props.modalState.onLevelChange}
          >
            {props.modalState.getMissingLevels().map((level) => (
              <option key={level} value={level}>
                {LEVEL_ROMAN_NUMERAL[level]}
              </option>
            ))}
          </HTMLSelect>
        </TDForm>
      ) : (
        <td className="text-muted hidden-xs hidden-sm">
          Level {props.modalState.getLevelRomanNumeral()}
        </td>
      )}
      <TDForm className="text-muted text-right fixed-size hidden-xs hidden-sm">
        <TextInput
          fill
          deep
          addon={currency}
          css={{ ".pt-ui & input": { textAlign: "right" } }}
          color={
            props.modalState.annualSalaryMinInputValueError !== null
              ? "danger"
              : "primary"
          }
          id="billRateMinInput"
          placeholder="Amount"
          value={props.modalState.annualSalaryMinInputValue}
          onChange={props.modalState.onAnnualSalaryMinChange}
        />
      </TDForm>
      <TDForm className="nte-column text-right fixed-size">
        <div className="hidden-xs hidden-sm">
          <TextInput
            fill
            deep
            addon={currency}
            css={{ ".pt-ui & input": { textAlign: "right" } }}
            color={
              props.modalState.annualSalaryMaxInputValueError !== null
                ? "danger"
                : "primary"
            }
            id="billRateMaxInput"
            placeholder="Amount"
            value={props.modalState.annualSalaryMaxInputValue}
            onChange={props.modalState.onAnnualSalaryMaxChange}
          />
        </div>
        {/* MOBILE VIEW */}
        <div className="hidden-md hidden-lg">
          <table className="xs-nte-table no-padding no-margin">
            <tbody>
              <tr className="xs-nte-row-input">
                <td className="nte-column text-left">NTE Bill:</td>
                <TDForm className="nte text-right">
                  <TextInput
                    fill
                    deep
                    addon={currency}
                    css={{ ".pt-ui & input": { textAlign: "right" } }}
                    color={
                      props.modalState.annualSalaryMaxInputValueError !== null
                        ? "danger"
                        : "primary"
                    }
                    id="billRateMaxInput"
                    placeholder="Amount"
                    value={props.modalState.annualSalaryMaxInputValue}
                    onChange={props.modalState.onAnnualSalaryMaxChange}
                  />
                </TDForm>
              </tr>
            </tbody>
          </table>
          {/* COLLAPSIBLE VALUES */}
          <div
            className={`hidden-md hidden-lg buy-rate-collapsible ${collapsedStateClass}`}
          >
            <table className="table level-data buy-rates input">
              <thead />
              <tbody className="text-muted">
                <tr>
                  <td className="text-left">Min Bill:</td>
                  <TDForm className="text-right">
                    <TextInput
                      fill
                      deep
                      addon={currency}
                      css={{ ".pt-ui & input": { textAlign: "right" } }}
                      color={
                        props.modalState.annualSalaryMinInputValueError !== null
                          ? "danger"
                          : "primary"
                      }
                      id="billRateMinInput"
                      placeholder="Amount"
                      value={props.modalState.annualSalaryMinInputValue}
                      onChange={props.modalState.onAnnualSalaryMinChange}
                    />
                  </TDForm>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </TDForm>
      <td className="text-right">
        <PunchoutButtonGroup>
          <div className="hidden-xs hidden-sm hidden-ml">
            <PunchoutButton color="brand" onClick={props.modalState.submit}>
              <FontAwesomeIcon icon="check" />
            </PunchoutButton>
            <PunchoutButton color="primary" onClick={props.modalState.resetAndClose}>
              <FontAwesomeIcon icon="ban" />
            </PunchoutButton>
          </div>
          <div className="visible-ml">
            <PunchoutButton color="brand" onClick={props.modalState.submit}>
              <FontAwesomeIcon icon="check" className="icon" /> Save
            </PunchoutButton>
            <PunchoutButton color="primary" onClick={props.modalState.resetAndClose}>
              <FontAwesomeIcon icon="ban" className="icon" /> Cancel
            </PunchoutButton>
          </div>
          <div className="hidden-md hidden-lg">
            <PunchoutButton
              color="brand"
              onClick={props.modalState.toggleCollapsed}
              className="btn-sm-device"
            >
              <FontAwesomeIcon icon="chevron-down" className={collapsedStateClass} />
            </PunchoutButton>
            <div className={`text-right buy-rate-collapsible ${collapsedStateClass}`}>
              <PunchoutButton
                color="brand"
                onClick={props.modalState.submit}
                className="first"
              >
                <FontAwesomeIcon icon="check" />
              </PunchoutButton>
              <PunchoutButton color="primary" onClick={props.modalState.resetAndClose}>
                <FontAwesomeIcon icon="ban" className="danger-icon" />
              </PunchoutButton>
            </div>
          </div>
        </PunchoutButtonGroup>
      </td>
    </tr>
  );

  if (props.modalState.modal.show) return inputView;

  return defaultView;
};

export default observer(AnnualPunchOutLevelRow);
