export const TITLE_REQUEST_STATUS = {
  PROCESSING: "PROCESSING",
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  DENIED: "DENIED",
};

export const TITLE_REQUEST_SORT_FIELD = {
  TITLE: "TITLE",
  CREATED: "CREATED_DATE",
  STATUS: "STATUS",
};
