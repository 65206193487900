import React, { useMemo, useCallback, createContext } from "react";

import {
  CurrencyDataMap,
  JobsDataOrderedMap,
  RATE_TYPES,
  RATE_TYPES_TYPE,
  ReviewDataMap,
} from "../types";
import { getDisplayCurrencyChanged, getJobRateTypeParameters } from "../utils";
import { convertToCurrency, emptyMap, emptyOrderedMap } from "../constants";
import { checkCurrencyConversionIsRequired } from "../components/Rates/utils";

export type RateDisplayState = {
  displayRateType: RATE_TYPES_TYPE;
  displayCurrencyData: CurrencyDataMap;
};

type ChangeRateTypeHandler = (value: RATE_TYPES_TYPE) => void;
type ChangeCurrencyHandler = (value: CurrencyDataMap) => void;
type ResetRateTypeHandler = () => void;
type ResetCurrencyHandler = () => void;

export function useRateDisplayState(
  displayRateType: RATE_TYPES_TYPE,
  displayCurrencyData: CurrencyDataMap
) {
  const [state, setState] = React.useState<RateDisplayState>({
    displayRateType,
    displayCurrencyData,
  });

  // handlers

  const changeDisplayRateType: ChangeRateTypeHandler = useCallback(
    (value) => {
      if (value !== state.displayRateType) {
        setState((prevState) => ({
          ...prevState,
          displayRateType: value,
        }));
      }
    },
    [state.displayRateType]
  );

  const changeDisplayCurrency: ChangeCurrencyHandler = useCallback(
    (value) => {
      if (value?.get("code") !== state.displayCurrencyData?.get("code")) {
        setState((prevState) => ({
          ...prevState,
          displayCurrencyData: value,
        }));
      }
    },
    [state.displayCurrencyData]
  );

  const resetDisplayRateType: ResetRateTypeHandler = useCallback(
    () => setState((prevState) => ({ ...prevState, displayRateType })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const resetDisplayCurrency: ResetCurrencyHandler = useCallback(
    () => setState((prevState) => ({ ...prevState, displayCurrencyData })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    displayRateType: state.displayRateType,
    displayCurrencyData: state.displayCurrencyData,
    changeDisplayRateType,
    changeDisplayCurrency,
    resetDisplayRateType,
    resetDisplayCurrency,
  };
}

export type RateDisplayContextObject = RateDisplayState & {
  displayRateMultiplier: number;
  //
  isDisplayCurrencyChanged: boolean;
  isDisplayRateTypeChanged: boolean;
  isCurrencyConversionRequired: boolean;
  isRateTypeConversionRequired: boolean;
  //
  changeDisplayRateType: ChangeRateTypeHandler;
  changeDisplayCurrency: ChangeCurrencyHandler;
  resetDisplayRateType: ResetRateTypeHandler;
  resetDisplayCurrency: ResetCurrencyHandler;
  //
  firstJobRateType: RATE_TYPES_TYPE;
  firstJobRateMultiplier: number;
  firstJobCurrencyData: CurrencyDataMap;
  //
  resultingCurrencyData: CurrencyDataMap;
  resultingCurrencySymbol: string;
  //
  convertToDisplayCurrency: <T extends number | null | undefined>(
    value: T,
    precision?: number
  ) => T;
  convertFromDisplayCurrency: <T extends number | null | undefined>(
    value: T,
    precision?: number
  ) => T;
  convertToDisplayRateType: <T extends number | null | undefined>(
    value: T,
    rateType?: RATE_TYPES_TYPE,
    rateMultiplier?: number
  ) => T;
};

export const RateDisplayContext = createContext<RateDisplayContextObject>({
  displayRateType: undefined,
  displayRateMultiplier: undefined,
  displayCurrencyData: undefined,
  //
  isDisplayCurrencyChanged: false,
  isDisplayRateTypeChanged: false,
  isCurrencyConversionRequired: false,
  isRateTypeConversionRequired: false,
  //
  changeDisplayRateType: undefined,
  changeDisplayCurrency: undefined,
  resetDisplayRateType: undefined,
  resetDisplayCurrency: undefined,
  //
  firstJobRateType: undefined,
  firstJobRateMultiplier: undefined,
  firstJobCurrencyData: undefined,
  //
  resultingCurrencyData: undefined,
  resultingCurrencySymbol: undefined,
  //
  convertToDisplayCurrency: () => {},
  convertFromDisplayCurrency: () => {},
  convertToDisplayRateType: () => {},
} as unknown as RateDisplayContextObject);

type RateDisplayContextProviderProps = React.PropsWithChildren<{
  reviewData: ReviewDataMap;
}>;

// IMPORTANT: this context impl is shared between admin and public UIs
function RateDisplayContextProvider(props: RateDisplayContextProviderProps) {
  const { reviewData } = props;

  const firstJobData =
    reviewData.get("jobs", emptyOrderedMap as JobsDataOrderedMap)?.first() ?? emptyMap;
  const firstJobCurrencyData = firstJobData.get("currency_data") ?? emptyMap;
  const initialDisplayCurrencyData =
    reviewData.get("display_currency_data") ?? firstJobCurrencyData;
  const { rateType: firstJobRateType, rateMultiplier: firstJobRateMultiplier } =
    getJobRateTypeParameters(firstJobData);

  // rate display state

  const {
    displayRateType,
    displayCurrencyData,
    changeDisplayRateType,
    changeDisplayCurrency,
    resetDisplayRateType,
    resetDisplayCurrency,
  } = useRateDisplayState(firstJobRateType, initialDisplayCurrencyData);

  // derivatives

  const { rateMultiplier: displayRateMultiplier } = useMemo(
    () => getJobRateTypeParameters(firstJobData, displayRateType),
    [displayRateType, firstJobData]
  );

  const isDisplayCurrencyChanged = useMemo(
    () =>
      getDisplayCurrencyChanged(
        initialDisplayCurrencyData.get("code"),
        displayCurrencyData.get("code")
      ),
    [displayCurrencyData, initialDisplayCurrencyData]
  );

  const isCurrencyConversionRequired: boolean = useMemo(
    () =>
      checkCurrencyConversionIsRequired(
        firstJobCurrencyData.get("code"),
        displayCurrencyData.get("code")
      ),
    [firstJobCurrencyData, displayCurrencyData]
  );

  const convertToDisplayCurrency = useCallback(
    (value, precision?: number) => {
      return isCurrencyConversionRequired
        ? convertToCurrency(
            value,
            firstJobCurrencyData.toJS(),
            displayCurrencyData.toJS(),
            precision
          )
        : value;
    },
    [isCurrencyConversionRequired, displayCurrencyData, firstJobCurrencyData]
  );

  const convertFromDisplayCurrency = useCallback(
    (value, precision?: number) => {
      return isCurrencyConversionRequired
        ? convertToCurrency(
            value,
            displayCurrencyData.toJS(),
            firstJobCurrencyData.toJS(),
            precision
          )
        : value;
    },
    [isCurrencyConversionRequired, displayCurrencyData, firstJobCurrencyData]
  );

  const resultingCurrencyData = isCurrencyConversionRequired
    ? displayCurrencyData
    : firstJobCurrencyData;
  const resultingCurrencySymbol = resultingCurrencyData?.get("symbol");

  const isDisplayRateTypeChanged = useMemo(
    () => displayRateType != null && firstJobRateType !== displayRateType,
    [displayRateType, firstJobRateType]
  );

  const checkIsRateTypeConversionRequired = useCallback(
    (rate_type: RATE_TYPES_TYPE = firstJobRateType) =>
      displayRateType != null && displayRateType !== rate_type,
    // needRateTypeConversionPredicate(displayRateType, displayRateMultiplier)
    [displayRateType, firstJobRateType]
  );

  const isRateTypeConversionRequired = useMemo(
    () => checkIsRateTypeConversionRequired(firstJobRateType),
    [checkIsRateTypeConversionRequired, firstJobRateType]
  );

  const convertToDisplayRateType = useCallback(
    (
      value,
      rateType: RATE_TYPES_TYPE = firstJobRateType,
      rateMultiplier: number = firstJobRateMultiplier
    ) => {
      if (value == null) return value;
      if (displayRateMultiplier == null) return value;

      if (checkIsRateTypeConversionRequired(rateType)) {
        // convert back to hourly values
        const isOriginalDailyWeeklyMonthly =
          rateType === RATE_TYPES.DAILY ||
          rateType === RATE_TYPES.WEEKLY ||
          rateType === RATE_TYPES.MONTHLY;
        if (isOriginalDailyWeeklyMonthly && rateMultiplier && rateMultiplier !== 1) {
          value = value / rateMultiplier;
        }

        // convert to display (daily, weekly, monthly) values
        const isNextDailyWeeklyMonthly =
          displayRateType === RATE_TYPES.DAILY ||
          displayRateType === RATE_TYPES.WEEKLY ||
          displayRateType === RATE_TYPES.MONTHLY;
        if (
          isNextDailyWeeklyMonthly &&
          displayRateMultiplier &&
          displayRateMultiplier !== 1
        ) {
          value = value * displayRateMultiplier;
        }
      }

      return value;
    },
    [
      displayRateType,
      displayRateMultiplier,
      firstJobRateType,
      firstJobRateMultiplier,
      checkIsRateTypeConversionRequired,
    ]
  );

  // context values

  const rateDisplayContextValues = useMemo<RateDisplayContextObject>(
    () => ({
      displayRateType,
      displayRateMultiplier,
      displayCurrencyData,
      isDisplayCurrencyChanged,
      isDisplayRateTypeChanged,
      isCurrencyConversionRequired,
      isRateTypeConversionRequired,
      changeDisplayRateType,
      changeDisplayCurrency,
      resetDisplayRateType,
      resetDisplayCurrency,
      firstJobRateType,
      firstJobRateMultiplier,
      firstJobCurrencyData,
      resultingCurrencyData,
      resultingCurrencySymbol,
      convertToDisplayCurrency,
      convertFromDisplayCurrency,
      convertToDisplayRateType,
    }),
    [
      displayRateType,
      displayRateMultiplier,
      displayCurrencyData,
      isDisplayCurrencyChanged,
      isCurrencyConversionRequired,
      isDisplayRateTypeChanged,
      isRateTypeConversionRequired,
      changeDisplayRateType,
      changeDisplayCurrency,
      resetDisplayRateType,
      resetDisplayCurrency,
      firstJobRateType,
      firstJobRateMultiplier,
      firstJobCurrencyData,
      resultingCurrencyData,
      resultingCurrencySymbol,
      convertToDisplayCurrency,
      convertFromDisplayCurrency,
      convertToDisplayRateType,
    ]
  );

  return (
    <RateDisplayContext.Provider value={rateDisplayContextValues}>
      {props.children}
    </RateDisplayContext.Provider>
  );
}
RateDisplayContextProvider.displayName = "RateDisplayContextProvider";

export const useRateDisplayContext = () => React.useContext(RateDisplayContext);

export default RateDisplayContextProvider;
