import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
// relay
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { IndustriesField_viewer$key } from "./__generated__/IndustriesField_viewer.graphql";
// LIB
import Icon from "../../../components/lib/Icon";
import { Selected, Selections } from "./styled";
import Box from "../../../components/lib/Box";
import { MultiSelect } from "../../../components/lib/MultiSelect";
// components
import { useRateSearchFormStore } from "../../../stores/RateSearchForm";
import { Industry, JobTitle } from "../types";
import { jobTitleObserver, onLocationsOrIndustriesChange } from "../observers";
import { isNonNullable } from "../../../utils/hashmap";

const gql = graphql`
  fragment IndustriesField_viewer on Viewer {
    industries {
      edges {
        node {
          id
          legacyId
          value
        }
      }
    }
  }
`;

type Props = {
  viewer: IndustriesField_viewer$key;
};

export function IndustriesField(props: Props) {
  const { viewer } = props;

  const [disabled, setDisabled] = useState(false);

  const industries = useRateSearchFormStore((s) => s.industries);
  const locations = useRateSearchFormStore((s) => s.locations);
  const setIndustries = useRateSearchFormStore((s) => s.setIndustries);
  const data = useFragment(gql, viewer);

  const options = useMemo(
    () => data.industries?.edges?.map((edge) => edge?.node).filter(isNonNullable) ?? [],
    [data]
  );

  const saveIndustries = useCallback(
    (industries: Industry[]) => {
      onLocationsOrIndustriesChange.emit({ locations, industries });
      setIndustries(industries);
    },
    [setIndustries, locations]
  );

  const clear = useCallback(() => {
    saveIndustries([]);
  }, [saveIndustries]);

  const onSelect = useCallback(
    (industry: Industry) => {
      if (industry && !industries.some((i) => i.legacyId === industry.legacyId)) {
        saveIndustries([...industries, industry]);
      }
    },
    [saveIndustries, industries]
  );

  const unselect = useCallback(
    (industry: Industry): MouseEventHandler<HTMLButtonElement> => {
      return (e) => {
        e.stopPropagation();
        if (disabled) return;
        saveIndustries(industries.filter((i) => i.legacyId !== industry.legacyId));
      };
    },
    [saveIndustries, industries, disabled]
  );

  useEffect(() => {
    function listener(jobTitle: JobTitle | null) {
      const healthcare = options.find(
        (industry) =>
          industry.value.toLowerCase() === "healthcare" && industry.legacyId === 9
      );

      if (!jobTitle || !jobTitle.isHealthcare || !healthcare) {
        if (disabled) setDisabled(false);
        return;
      }

      saveIndustries([healthcare]);
      setDisabled(true);
    }

    const unsubscribe = jobTitleObserver.subscribe(listener);
    return () => unsubscribe();
  }, [options, saveIndustries, disabled]);

  return (
    <Box>
      <MultiSelect
        icon="industry"
        id="industrySelect"
        selected={industries}
        options={options}
        onSelect={onSelect}
        onClearSelections={clear}
        label="Industry"
        placeholder="Select an industry"
        disabled={disabled}
        readOnly
        required
        getKey={(o) => o.id}
        renderOption={(o) => o.value}
        renderAddon={(opts) => (
          <Selections>
            {opts.map((o) => (
              <Selected
                key={o.legacyId}
                onClick={unselect(o)}
                disabled={disabled}
                title={`Unselect ${o.value}`}
              >
                <span>{o.value}</span>
                {!disabled && <Icon size="sm" icon="close" />}
              </Selected>
            ))}
          </Selections>
        )}
      />
    </Box>
  );
}
