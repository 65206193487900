import React, { useEffect, useCallback, useRef } from "react";
import ReactDOM from "react-dom";

// @ts-expect-error
import Messager from "../../components/Messager";
// @ts-expect-error
import { logAsyncOperationError } from "../../utils/logging";
import { useGlobalContext } from "../../globalContext";

import { currenciesListToImmutableMap } from "./dataConverters";
import { emptyOrderedMap } from "./constants";

import type { FetchAPIResponse } from "../../types/fetch";
import type { AllCurrenciesMap, CurrencyObject } from "./types";

// start periodic data refresh if incoming data meets some condition (used in tables refresh mechanism)
export function useIntervalDataRefresh<T>(
  refreshFunc: () => void,
  currentData: T,
  predicate: (data: T) => boolean,
  timeout: number = 2000
): void {
  useEffect(() => {
    if (predicate(currentData)) {
      const intervalId = setInterval(refreshFunc, timeout);

      return () => clearInterval(intervalId);
    }
  }, [currentData, refreshFunc, predicate, timeout]);
}

function getElement(node: React.ReactInstance | null | undefined): Element | null {
  if (node == null) return null;

  if (node instanceof React.Component) {
    return ReactDOM.findDOMNode(node) as Element;
  }

  return node;
}

// scroll to some html element upon dependencies change
export function useScrollTo(dependencies: any[]): React.RefCallback<HTMLElement> {
  const isFirstCall = useRef<boolean>(true);

  const needsScroll = () => {
    if (isFirstCall.current) {
      isFirstCall.current = false;
      return false;
    }
    return true;
  };

  const scrollToRef = useCallback((node: React.ReactInstance | null | undefined) => {
    const element = getElement(node);

    needsScroll() && element?.scrollIntoView?.();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return scrollToRef;
}

export function useMessagerRef() {
  const messagerRef = useRef<Messager>(null);

  const showError = useCallback((message: string, autoclose: boolean = false) => {
    messagerRef.current?.cancelAll?.();
    messagerRef.current?.failure?.(message, true, true, autoclose);
  }, []);

  const showWarning = useCallback((message: string, autoclose: boolean = false) => {
    messagerRef.current?.cancelAll?.();
    messagerRef.current?.warning?.(message, true, true, autoclose);
  }, []);

  const showSuccess = useCallback((message: string) => {
    messagerRef.current?.cancelAll?.();
    messagerRef.current?.success?.(message);
  }, []);

  const showHint = useCallback((message: string, autoclose: boolean = false) => {
    messagerRef.current?.cancelAll?.();
    messagerRef.current?.hint?.(message, false, true, autoclose);
  }, []);

  const hideMessage = useCallback(() => {
    messagerRef.current?.hide?.();
  }, []);

  return {
    messagerRef,
    showError,
    showWarning,
    showSuccess,
    showHint,
    hideMessage,
  };
}

export function useAllCurrenciesData() {
  const { fetchCCCV1API, showModalError } = useGlobalContext();

  // currencies data state
  const [currenciesDataState, setCurrenciesDataState] = React.useState({
    loaded: false,
    currenciesData: emptyOrderedMap as unknown as AllCurrenciesMap,
  });

  // data fetch funcs
  const fetchCurrenciesData = useCallback(async () => {
    try {
      const response: FetchAPIResponse<CurrencyObject[]> = await fetchCCCV1API(
        "our-currencies/"
      );
      const currenciesData: AllCurrenciesMap = currenciesListToImmutableMap(
        response.data
      );

      setCurrenciesDataState({
        loaded: true,
        currenciesData,
      });
    } catch (err) {
      logAsyncOperationError("fetchCurrenciesData", err);
      showModalError("Error occurred while loading currencies data.");
    }
  }, [setCurrenciesDataState, fetchCCCV1API, showModalError]);

  // effects

  React.useEffect(() => {
    fetchCurrenciesData();
  }, [fetchCurrenciesData]);

  return currenciesDataState;
}
