// @flow

import React, { Component } from "react";
import { observer } from "mobx-react";
import SearchList from "./components/SearchList";
import RateDisplayToggle from "./components/RateDisplayToggle";
import type MobXStore from "../../stores/mobx/MobXStore";
import Box from "../../components/lib/Box";
import { ModalTitle } from "./components/FilterModal";
import Button from "../../components/lib/Button";
import Inline from "../../components/lib/Inline";

type Props = {
  router: Object,
  store: MobXStore,
};

class DetailList extends Component<Props> {
  componentDidMount() {
    const store = this.props.store.rateCardDetailStore;
    store.router = this.props.router;
  }

  render() {
    const store = this.props.store.rateCardDetailStore;
    const globalStore = this.props.store.globalStateStore;
    const rateMultiplier = globalStore.rateMultiplier;
    const contingentRateFrequency = globalStore.selectedContingentRateDisplayOption;

    return (
      <Box className="rc-container" css={{ zIndex: 2 }}>
        <div className="header-action-bar">
          <ModalTitle css={{ lineHeight: "30px" }}>Searches on this Rate Card</ModalTitle>
          {!store.network.loading && !store.network.error && (
            <div className="btn-group pull-right">
              {store.isEditing && (
                <Button color="accent" onClick={store.handleStopEdit}>
                  Stop Editing
                </Button>
              )}
              {!store.isEditing && (
                <Inline css={{ gap: "$2" }}>
                  <RateDisplayToggle
                    selectedOption={globalStore.selectedContingentRateDisplayOption}
                    onChange={globalStore.onContingentRatesDisplayChange}
                    onConfig={globalStore.customMultipliersModal.showModal}
                  />
                  <Button color="brand" icon="edit" onClick={store.handleStartEdit}>
                    Edit
                  </Button>
                </Inline>
              )}
            </div>
          )}
          <div className="clearfix" />
        </div>

        {!store.network.error && (
          <SearchList
            store={store}
            rateMultiplier={rateMultiplier}
            contingentRateFrequency={contingentRateFrequency}
          />
        )}
        {store.network.error && (
          <div className="pt-error-message fade-in">
            <h2>Error</h2>
            <pre>
              <code>{JSON.stringify(store.network.error, null, 2)}</code>
            </pre>
          </div>
        )}
      </Box>
    );
  }
}

export default observer(DetailList);
