// @flow

import React from "react";
import { observer } from "mobx-react";
import { BatchSearchJLListStore } from "../../../stores/mobx/BatchSearchListStore";
import Modal from "../../ratecards/components/Modal";
import { ModalButtons, ModalTitle } from "../../ratecards/components/FilterModal";
import Button from "../../../components/lib/Button";

const BatchSearchListHelpModal = (props: { store: BatchSearchJLListStore }) => {
  const store = props.store;

  return (
    <Modal show={store.helpModal.show} onHide={store.helpModal.hideModal}>
      <div className="container-section header">
        <ModalTitle>Batch Search Help</ModalTitle>
      </div>
      <div className="container-section">
        <p>Batch Search can be used to gather wage information for many jobs at once.</p>
        <p>
          Create a new batch search and choose your jobs, locations and the Rate Card you
          would like the results to appear in. Our system will then perform the searches
          for you and email you once its done analyzing your jobs.
        </p>
        <p>
          Get started by clicking on <strong>New Batch Search</strong>
        </p>
      </div>
      <div className="container-section footer">
        <ModalButtons>
          <Button size="large" onClick={store.helpModal.hideModal}>
            Ok
          </Button>
        </ModalButtons>
      </div>
    </Modal>
  );
};

export default observer(BatchSearchListHelpModal);
