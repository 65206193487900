import graphql from "babel-plugin-relay/macro";
import { useAutofillJobTitleQuery } from "./__generated__/useAutofillJobTitleQuery.graphql";
import { JobTitle } from "../types";
import { useLazyLoadQuery } from "react-relay";
import { useEffect } from "react";
import { useGlobalContext } from "../../../globalContext";

const gql = graphql`
  query useAutofillJobTitleQuery($searchParam: String) {
    viewer {
      jobTitles(search: $searchParam, page: 1, pageSize: 25) {
        results {
          id
          title
          isJobLabel
          collection
          shareInfo {
            isMine
            sharedBy {
              firstName
              lastName
            }
          }
          clientJobLibraryInfo {
            adhocCountries
            certifiedCountries
          }
        }
      }
    }
  }
`;

export function useAutofillJobTitle(onJobTitleChange: (job: JobTitle) => void) {
  const { location } = useGlobalContext();

  const autofillJobTitle = location?.state?.autofillJobTitle;

  // Test data: id: "241773", title: "Java Developer"
  const title: string = autofillJobTitle?.title || "";
  const titleId: string = autofillJobTitle?.id || "";
  const shouldFetch = Boolean(title && titleId);

  const data = useLazyLoadQuery<useAutofillJobTitleQuery>(
    gql,
    { searchParam: title },
    {
      fetchPolicy: shouldFetch ? "store-or-network" : "store-only",
    }
  );

  useEffect(() => {
    const results = data.viewer?.jobTitles?.results || null;
    if (results) {
      const job = results.find((jt) => jt?.id === String(titleId));
      if (job && job.id) {
        onJobTitleChange(job as JobTitle);
      }
    }
  }, [data, titleId, onJobTitleChange]);
}
