import { action } from "mobx";
import moment from "moment";

export default class JobListTest {
  constructor(store, object) {
    this.store = store;
    this.jobId = null;
    this.jobTitleId = null;
    this.jobLabel = null;
    this.jobTitle = null;
    this.jobDescription = null;
    this.owner = null;
    this.created = null;
    this.modified = null;
    this.createdDisplay = null;
    this.tags = null;
    this.viewState = {
      selected: false,
      editing: false,
    };
    this.createdBy = null;

    if (object) {
      this.jobId = object.jobId || null;
      this.jobTitleId = object.jobTitleId || null;
      this.jobLabel = object.jobLabel || null;
      this.jobTitle = object.jobTitle || null;
      this.jobDescription = object.jobDescription || null;
      this.owner = {
        fullname: `${object.createdBy.firstName} ${object.createdBy.lastName}` || null,
        clientId: object.userId || null,
        email: object.createdBy.email || null,
      };
      this.created = moment(object.created);
      this.modified = moment(object.modified);
      this.createdDisplay = `Created ${this.created.format("MMMM D, YYYY")}`;
      this.tags = object.tags || {};
      this.createdBy = object.createdBy || {};
    }

    this.toggleEdit = action(this.toggleEdit.bind(this));
    this.toggleSelected = action(this.toggleSelected.bind(this));
    this.destroy = action(this.destroy.bind(this));
  }

  toggleEdit() {
    const viewState = this.store.jobsListViewState.get(this.jobId);
    viewState.editing = !viewState.editing;
  }

  toggleSelected(e, ignore1 = null, ignore2 = null, setValue = null) {
    const viewState = this.store.jobsListViewState.get(this.jobId);

    if (setValue !== null) {
      viewState.selected = setValue;
      return;
    } else {
      viewState.selected = !viewState.selected;

      if (!viewState.selected && this.store.allSelected) {
        this.store.allSelected = false;
      }
    }

    if (!this.store.allowMultipleItemSelection) {
      // deselect all other jobs
      this.store.jobsListViewState.forEach((viewState) => {
        if (this.viewState === viewState) return;

        viewState.selected = false;
      });
    }
  }

  destroy() {
    this.store.jobLists.remove(this);
  }

  toJS() {
    return {
      jobId: this.jobId,
      jobLabel: this.jobLabel,
      jobTitle: this.jobTitle,
      jobDescription: this.jobDescription,
    };
  }

  static fromJS(store, object) {
    return new JobListTest(store, object);
  }
}
