// @flow

import { extendObservable, action, runInAction } from "mobx";
import MessageState from "../../models/MessageState";
import { browserHistory } from "react-router";
import NetworkState from "../../models/NetworkState";
import type { FetchGraphQL } from "../../App";

/**
 * Remove duplicates from array of objects.
 * @param {objectsArray} array.
 * @returns {objectsArray} The object array removing duplicates.
 */
function remove_duplicates(objectsArray) {
  var usedObjects = {};
  for (var i = objectsArray.length - 1; i >= 0; i--) {
    var so = JSON.stringify(objectsArray[i]);
    if (usedObjects[so]) {
      objectsArray.splice(i, 1);
    } else {
      usedObjects[so] = true;
    }
  }
  return objectsArray;
}

export default class RegionCreateStore {
  network: NetworkState;
  updateNetwork: NetworkState;
  citiesNetwork: NetworkState;
  changeCountryText: () => void;
  country: String;
  countryid: number;
  showLocationPopup: boolean;
  showModel: () => void;
  closeModel: () => void;
  stateList: [];
  cityList: [];
  citySelected: boolean;
  loadMoreCities: boolean;
  selectedState: Object;
  selectCity: () => void;
  selectedCity: Object;
  selectedLocations: Array<any>;
  viewLocations: Array<any>;
  calculateTags: () => void;
  resetLocation: () => void;
  regionName: String;
  setLocationClick: () => void;
  changeRegionNameText: () => void;
  citiesList: [];
  scrollState: Object;
  resetForm: () => void;
  locationTitle: () => void;
  onLocationChange: () => void;
  location: Object;
  regionId: "";
  setCountry: () => void;
  updateRegion: () => void;
  regionDetailStore: "";
  messaging: MessageState;
  regionMessaging: MessageState;
  countryMessaging: MessageState;
  updateRegionLocations: () => void;
  fetchGraphQL: FetchGraphQL;

  constructor(fetchGraphQL: FetchGraphQL) {
    this.fetchGraphQL = fetchGraphQL;
    this.changeCountryText = action(this.changeCountryText.bind(this));
    this.showModel = action(this.showModel.bind(this));
    this.locationTitle = action(this.locationTitle.bind(this));
    this.closeModel = this.closeModel.bind(this);
    this.selectCity = this.selectCity.bind(this);
    this.saveLocation = this.saveLocation.bind(this);
    this.calculateTags = this.calculateTags.bind(this);
    this.resetLocation = this.resetLocation.bind(this);
    this.changeRegionNameText = this.changeRegionNameText.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.onLocationChange = this.onLocationChange.bind(this);
    this.setCountry = this.setCountry.bind(this);
    this.updateRegion = this.updateRegion.bind(this);
    this.updateRegionLocations = this.updateRegionLocations.bind(this);
    this.setLocationClick = action(this.setLocationClick.bind(this));

    extendObservable(this, {
      country: "",
      countryid: null,
      showLocationPopup: false,
      stateList: [],
      cityList: [],
      loadMoreCities: false,
      citySelected: false,
      selectedState: {},
      selectedCity: {},
      selectedLocations: [],
      viewLocations: [],
      regionName: "",
      scrollState: {},
      region: {},
      regionId: "",
      network: new NetworkState(),
      updateNetwork: new NetworkState(),
      citiesNetwork: new NetworkState(),
      messaging: new MessageState(),
      regionMessaging: new MessageState(),
      countryMessaging: new MessageState(),
      regionDetailStore: "",
    });
  }

  selectCity(name, id) {
    this.citySelected = !this.citySelected;
    this.selectedCity.name = name;
    this.selectedCity.id = id;
  }

  setCountry(country, regionId, regionDetailStore) {
    this.resetForm();
    this.country = country.name;
    this.countryid = country.locationId;
    this.getStates(this.countryid);
    this.regionId = regionId;
    this.regionDetailStore = regionDetailStore;
  }

  changeCountryText(e: Object) {
    this.countryMessaging.removeAll();
    this.resetForm();
    this.viewLocations = [];
    if (e && e.id) {
      this.country = e.title;
      this.countryid = e.id;
      this.getStates(this.countryid);
    } else {
      this.country = "";
      this.countryid = null;
    }
  }

  /**
   * calculateTags - calclucate location tags to show
   * @return {void}
   */

  calculateTags(value) {
    if (value.length > 0) {
      var selectedLocationsNew = remove_duplicates(value);
      var locationInput = "";
      selectedLocationsNew.forEach((region) => {
        locationInput =
          selectedLocationsNew.length === 1
            ? locationInput.concat(region.title)
            : locationInput.concat(region.title + " | ");
      });
      return locationInput;
    } else {
      return "";
    }
  }

  resetForm() {
    this.country = "";
    this.countryid = null;
    this.stateList = [];
    this.cityList = [];
    this.selectedLocations = [];
  }

  changeRegionNameText(e: Object) {
    this.regionMessaging.removeAll();
    this.regionName = e.target.value;
  }

  updateRegionLocations() {
    this.saveLocation();
    this.updateRegion();
  }

  saveLocation() {
    this.showLocationPopup = false;
    let locLocations = [];
    this.selectedLocations = this.selectedLocations.filter(
      (loc, index, self) =>
        self.findIndex((t) => t.locationId === loc.locationId) === index
    );
    this.selectedLocations.forEach((val) => {
      locLocations.push(val);
    });
    locLocations = remove_duplicates(locLocations);
    this.viewLocations = locLocations;
    this.resetLocation();
  }

  resetLocation() {
    this.selectedState = {};
    this.selectedCity = {};
    this.cityList = [];
  }

  onLocationChange(region) {
    var loc = [];
    region.map((reg) => {
      loc.push({
        title: reg.name,
        locationId: parseInt(reg.locationId, 10),
        state: {},
        city: {},
      });
      return true;
    });
    this.selectedLocations = loc;
  }

  setLocationClick(id, name, type, addRemove) {
    if (type === "state") {
      if (addRemove === "add") {
        this.selectedState.name = name;
        this.selectedState.id = id;
        this.selectedLocations.push({
          type,
          title: name,
          state: this.selectedState,
          locationId: id,
          city: "",
        });

        this.selectedLocations = this.selectedLocations.filter(
          (loc) => loc.type !== "city" || loc.state.id !== id
        );
      }
    } else if (type === "city") {
      if (addRemove === "add") {
        this.selectedCity.name = name;
        this.selectedCity.id = id;
        let locationTitle =
          (this.selectedCity.id ? this.selectedCity.name + ", " : "") +
          (this.selectedState.id ? this.selectedState.name : "");
        this.selectedLocations.push({
          type,
          title: locationTitle,
          state: this.selectedState,
          locationId: id,
          city: this.selectedCity,
        });

        this.selectedLocations = this.selectedLocations.filter(
          (loc) => loc.locationId !== this.selectedState?.id
        );
      }
    }
    if (addRemove === "remove") {
      this.selectedLocations.forEach((item, index) => {
        if (item.locationId === id) {
          this.selectedLocations.splice(index, 1);
        }
      });
    }
    this.selectedLocations = remove_duplicates(this.selectedLocations);
  }

  showModel() {
    this.messaging.removeAll();
    this.showLocationPopup = true;
  }

  closeModel() {
    var newLoc = [];
    this.showLocationPopup = false;
    this.viewLocations.forEach((val) => {
      newLoc.push(val);
    });
    this.selectedLocations = newLoc;
    this.resetLocation();
  }

  async countryTitle(searchText) {
    var countryText = searchText === undefined ? "" : searchText;

    const variables = {
      searchParam: countryText,
    };
    const query = `
      query countries{
         viewer{
           allLocations (filters: {locationTypeId: 3,nameIContains:"${countryText}"}, order: [{field: NAME, direction: ASC}]){
  		     edges {
  		       node {
  		         locationId
  		         name
  		         isActive
  		       }
		       }
		      }
        }
      }
    `;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
      // should hit get request
    } catch (e) {
      console.error("Error in getting country title", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }
    runInAction("countryTitle--success", () => {});
    return {
      countries: res.data.viewer.allLocations.edges,
    };
  }

  async getStates(country_id) {
    const query = `
			query getStates {
			 viewer {
			   allLocations (
			     isActiveOnly: false, 
			     filters: {locationTypeId: 2, hasChildren: true, parentId:${country_id}}, 
			     order: [{field: NAME, direction: ASC}]
			   ) {
			     edges {
			       node {
			         locationId
			         name
			         isActive
			       }
			     }
			   }
			 }
			}
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, null);
      // should hit get request
    } catch (e) {
      console.error("Error editing job", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }
    runInAction("getStates--success", () => {
      this.stateList = res.data.viewer.allLocations.edges;
    });
  }

  async getCities(state_id, name) {
    if (this.citiesNetwork.loading) return;
    this.citiesNetwork.loading = true;
    this.selectedState.name = name;
    this.selectedState.id = state_id;

    const query = `
			query getCities($stateId: Int!) {
			 viewer {
			   allLocations (filters: {locationTypeId: 1, parentId: $stateId}, order: [{field: NAME, direction: ASC}]) {
			     edges {
			       node {
			         parent {
                 locationId
               }
			         locationId
			         name
			       }
			     }
			   }
			 }
		 }
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, { stateId: state_id });
    } catch (e) {
      this.citiesNetwork.loading = false;
      console.error("Error editing job", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }
    if (res.errors) {
      this.citiesNetwork.loading = false;
      console.error("Errors", res.errors);
      return;
    }

    runInAction("getCities--success", () => {
      this.citiesNetwork.loading = false;
      this.cityList = res.data.viewer.allLocations.edges;
    });
  }

  async updateRegion() {
    let vars = {};

    let locationIds = [];

    this.selectedLocations = this.selectedLocations.filter(
      (loc, index, self) =>
        self.findIndex((t) => t.locationId === loc.locationId) === index
    );

    this.selectedLocations.forEach((item) => {
      item.cityId ? locationIds.push(item.cityId) : locationIds.push(item.locationId);
    });

    vars.regionId = this.regionId;
    vars.locationIds = locationIds;

    const query = `
      mutation addLocation($regionId: Int!, $locationIds: [Int]!) {
       addLocation(input: {regionId: $regionId, locationIds: $locationIds}) {
         region {
           locations {
             name
             locationType {
               value
             }
           }
         }
       }
      }
      `;

    this.updateNetwork.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      console.error("Error updating Regions", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.updateNetwork.loading = false;
      return;
    }

    runInAction("updateRegion--success", () => {
      this.resetForm();
      this.updateNetwork.loading = false;
      this.updateNetwork.error = null;
      this.regionDetailStore.pagination.goFetch();
    });
  }

  async saveRegion() {
    let vars = {};
    let locationIds = [];

    this.selectedLocations = this.selectedLocations.filter(
      (loc, index, self) =>
        self.findIndex((t) => t.locationId === loc.locationId) === index
    );

    this.selectedLocations.forEach((item) => {
      item.cityId ? locationIds.push(item.cityId) : locationIds.push(item.locationId);
    });

    this.regionMessaging.removeAll();
    this.messaging.removeAll();
    this.countryMessaging.removeAll();

    // Region name is required
    if (!this.regionName.trim()) {
      this.regionMessaging.createMessage("info", "Please enter a Region Name.");
      return;
    }

    if (!this.countryid) {
      this.countryMessaging.createMessage("info", "Please select a Country.");
      return;
    }

    const query = `
      mutation createregion($name: String!, $countryId: Int!, $locationIds: [Int]!) {
        createRegion(input: {name: $name, countryId: $countryId, locationIds: $locationIds}){
          region {
           regionId
          }
          errors{
            __typename
            ...on NameEmptyError{
              message
            }
            ...on NameAlreadyExistError{
              message
            }
            ...on InvalidCountryIdError{
              message
            }
            ...on NoLocationIdsError{
              message
            }
            ...on StateCityInCountryError{
              message
            }
          }
        }
      }
      `;

    vars.countryId = this.countryid;
    vars.locationIds = locationIds;
    const variables = Object.assign({ name: this.regionName }, vars);

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      console.error("Error creating Regions", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }
    if (res.errors) {
      if (this.messaging.messages.length > 0) {
        for (var i = 0; i < this.messaging.messages.length; i++) {
          this.messaging.removeMessage(this.messaging.messages[i]);
        }
      }
      this.messaging.createMessage("error", res.errors[0].message);
      return;
    }

    runInAction("saveRegion--success", () => {
      this.network.loading = false;
      this.network.error = null;
      // var that=this;
      if (res.data.createRegion.errors) {
        this.messaging.removeAll();
        this.messaging.createMessage("error", res.data.createRegion.errors[0].message);
        //setTimeout(function () {document.getElementById('alert_msg').style.display='none';that.messaging.removeMessage.bind(that, that.messaging.messages[0]);}, 3000);
        return;
      }
      const regionId = res.data.createRegion.region.regionId;
      this.resetForm();
      browserHistory.push("/regions/" + regionId); // forward to the region detail page after creating a region
    });
  }

  async locationTitle(searchText) {
    const variables = {
      searchParam: searchText === undefined ? "" : searchText,
      countryId: this.countryid,
    };
    const query = `
      query childlocations($searchParam: String!, $countryId : Int!) {
         viewer {
           allLocations (filters: {nameIContains: $searchParam, countryId: $countryId}, first: 100) {
             edges {
               node {
                 name
                 locationId
                 locationType {
                   value
                 }
                 parent {
                   id
                   name
                 }
               }
             }
           }
         }
       }
      `;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
      // should hit get request
    } catch (e) {
      console.error("Error in getting location title", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }
    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }
    runInAction("locationTitle--success", () => {});

    return {
      locationTitle: res.data.viewer.allLocations.edges,
    };
  }
}
