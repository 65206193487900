/**
 * @generated SignedSource<<73feba7430c1d05972a1d064a28f11c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserRegionsContainerSectionQuery$variables = {
  userId: number;
};
export type UserRegionsContainerSectionQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"UserRegionsContainerSection_viewer">;
  } | null;
};
export type UserRegionsContainerSectionQuery = {
  response: UserRegionsContainerSectionQuery$data;
  variables: UserRegionsContainerSectionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserRegionsContainerSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "userId",
                "variableName": "userId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "UserRegionsContainerSection_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserRegionsContainerSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "userId"
              }
            ],
            "concreteType": "AdminUserNode",
            "kind": "LinkedField",
            "name": "userDetail",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "NewRegionNodeConnection",
                "kind": "LinkedField",
                "name": "regions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NewRegionNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NewRegionNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "regionId",
                            "storageKey": null
                          },
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "LocationNode",
                            "kind": "LinkedField",
                            "name": "country",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "LocationNode",
                            "kind": "LinkedField",
                            "name": "locations",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ab2fa129a593d2abd6cc895d13066e8b",
    "id": null,
    "metadata": {},
    "name": "UserRegionsContainerSectionQuery",
    "operationKind": "query",
    "text": "query UserRegionsContainerSectionQuery(\n  $userId: Int!\n) {\n  viewer {\n    ...UserRegionsContainerSection_viewer_1xxw8p\n  }\n}\n\nfragment UserRegionsContainerSection_viewer_1xxw8p on Viewer {\n  userDetail(id: $userId) {\n    userId\n    username\n    regions {\n      totalCount\n      edges {\n        node {\n          regionId\n          name\n          country {\n            name\n            id\n          }\n          locations {\n            __typename\n            id\n          }\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "7cabdaf7f10ff189a98f698f97dbfc8f";

export default node;
