import React, { useCallback, useEffect, useRef, useState } from "react";
import { fromJS, Set } from "immutable";

import Text from "../../../components/lib/Text";
import Inline from "../../../components/lib/Inline";
import { SimpleLink } from "../../../components/lib/Link";
import { GroupElement } from "../../../components/tables/Schema";
import Box from "../../../components/lib/Box";
import Stack from "../../../components/lib/Stack";
import Icon from "../../../components/lib/Icon";
import { Column, Group } from "../../../components/tables";
import CurrencySelect, {
  CurrencyData,
  CurrencySelectProps,
} from "../../../components/selects/CurrencySelect";
import { ButtonGroupRight, ButtonGroup } from "../../../components/lib/ButtonGroup";

// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";

import {
  ContractorsTable,
  defaultCustomConfigVisibleColumnsKeysForAdmin,
  groupedContractorsTableColumnsSpecs,
  useContractorsSelectionHandlers,
  useEditingRowState,
} from "../components/ContractorsTable";
import type {
  ContractorsTableProps,
  SelectAllRowsOnThePageHandler,
  SelectRowHandler,
  StartRowsSelectionHandler,
  StopRowsSelectionHandler,
  TableConfigChangesObject,
} from "../components/ContractorsTable";
import {
  FailedContractorsAlert,
  NeedApprovalContractorsAlert,
} from "../components/ContractorsAlerts";
import {
  PARAMS_FOR_BULK_UPDATE,
  PARAMS_FOR_BULK_UPDATE_TYPE,
} from "../components/AdminContractorBulkEditor";
import SavingsDonut, {
  SavingsDataMap,
  SavingsDataObject,
} from "../components/SavingsDonut";
import CreateRateCardForm, {
  CreateRateCardFormProps,
} from "../components/CreateRateCardForm";
import { renderGroupedTableColumnsSpecs } from "../components/ExtendedRestfulTables";
import {
  selectRowCheckbox,
  selectAllRowsCheckbox,
} from "../components/ContractorsTableSelectCheckbox";
import JobTitleValidationsView from "../components/JobTitleValidationsView";
import RunningProcessingsAlert from "../components/RunningProcessingsAlert";
import AdminContractorEditor from "../components/AdminContractorEditor";
// import IndexTrendValues from '../components/IndexTrendValues';
// import StoredIndexSpendByManagerChart from '../components/StoredIndexSpendByManagerChart';
// import StoredIndexVolumeByLocationChart from '../components/StoredIndexVolumeByLocationChart';
// import StoredIndexEngagementTrendChart from '../components/StoredIndexEngagementTrendChart';
// import StoredIndexRateBreakdownChart from '../components/StoredIndexRateBreakdownChart';
// import StoredIndexMarkupsChart from '../components/StoredIndexMarkupsChart';

import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogTitle,
  DialogDescription,
  DialogClose,
} from "../lib/Dialog";
import { InstructionsCheckMark } from "../lib/CheckMark";
import { TickerContentLoader } from "../../../components/lib/TickerLoader";
import TextInput from "../../../components/lib/TextInput";
import {
  Card,
  CardBody,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
} from "../../../components/lib/Card";
import { NavigationButton, IconButton, Button } from "../../../components/lib/Button";

import { useStoredIndexContext } from "../StoredIndexDataProvider";
import {
  useResetStoredIndexContractorsTableGlobalState,
  useStoredIndexContractorsTableGlobalState,
  useStoredIndexGlobalState,
} from "../globalState";
import {
  savingsDonutDataToImmutableMap,
  storedIndexToImmutableMap,
  transformContractorsData,
} from "../dataConverters";
import { useScrollTo } from "../hooks";
import {
  TABLE_TABS_TYPES,
  ALL_TABLE_TABS_TYPES,
  CONTRACTOR_STATUSES,
  CONTRACTOR_STATUSES_TYPE,
} from "../types";
import { useProgramContext } from "../ProgramDataProvider";
import {
  dateTimeFormatter,
  djangoPaginationKey,
  djangoPaginationSizeKey,
  emptySet,
  emptyMap,
  emptyOrderedMap,
  reactSelectStyles,
} from "../constants";
import { BulkRerunContractorsButton } from "../components/BulkRerunContractorsButton";
import BulkDeleteContractorsButton from "../components/BulkDeleteContractorsButton";
import BulkExportContractorsButton from "../components/BulkExportContractorsButton";
import BulkCreateSurveysButtons from "../components/BulkCreateSurveysButtons";
import BulkUpdateButton from "../components/BulkUpdateButton";
import { rowIdGetter } from "../../../components/tables/utils";
import { useRefreshRequest } from "../../../utils/hooks";
import { usePLIContext } from "../context";

import type {
  ColumnsFiltersConfigOrderedMap,
  FiltersQueryOrderedMap,
  RowEditorComponent,
} from "../../../components/tables/types";
import type { InputProps } from "../../../components/lib/TextInput";
import type { FetchAPIResponse } from "../../../types/fetch";
import type { DjangoPaginatedResponse } from "../../../types/django";
import type { ImmutableMap, ImmutableSet } from "../../../types/immutable";
import type { CommonStoredIndexChildPageProps } from "../StoredIndexDataProvider";
import type {
  ContractorDataMap,
  ContractorDataObject,
  ContractorsTableDataProvider,
  ContractorsTableDataStateObject,
  ContractorsTableFiltersDataProvider,
  StoredIndexDataMap,
  StoredIndexDataObject,
  StoredIndexTrendDataMap,
} from "../types";
import type { ContractorsTableViewProps } from "./StoredIndexCreate";

const allowedStatusesForBulkUpdate = Set([
  CONTRACTOR_STATUSES.FINISHED,
]) as unknown as ImmutableSet<CONTRACTOR_STATUSES_TYPE>;

const allowedParametersForBulkUpdate = Set([
  PARAMS_FOR_BULK_UPDATE.INDUSTRY,
  PARAMS_FOR_BULK_UPDATE.EXP_LEVEL,
  // IMPORTANT: Per Marc - need more manual control on Accepted flag, should be always available
  PARAMS_FOR_BULK_UPDATE.LIMITS_ACCEPTED,
  PARAMS_FOR_BULK_UPDATE.GLOBAL_SUPPLIER_SEARCH,
  // below are all parameters available under specific data/filters conditions
  PARAMS_FOR_BULK_UPDATE.COLLECTION,
  PARAMS_FOR_BULK_UPDATE.DESCRIPTION,
  PARAMS_FOR_BULK_UPDATE.LOCATION,
  PARAMS_FOR_BULK_UPDATE.REGION,
  PARAMS_FOR_BULK_UPDATE.WORKER_TYPE,
]) as any as ImmutableSet<PARAMS_FOR_BULK_UPDATE_TYPE>;

const allowedStatusesForBulkRerun = Set([
  CONTRACTOR_STATUSES.PENDING,
  CONTRACTOR_STATUSES.CLEAN,
  CONTRACTOR_STATUSES.NEED_APPROVAL,
  CONTRACTOR_STATUSES.FINISHED,
  CONTRACTOR_STATUSES.FAILED,
]) as unknown as ImmutableSet<CONTRACTOR_STATUSES_TYPE>;

const allowedStatusesForBulkDelete = Set([
  CONTRACTOR_STATUSES.FINISHED,
]) as unknown as ImmutableSet<CONTRACTOR_STATUSES_TYPE>;

const allowedStatusesForExport = Set([
  CONTRACTOR_STATUSES.FINISHED,
]) as any as ImmutableSet<CONTRACTOR_STATUSES_TYPE>;

const compareWithMarketVisibleColumnsKeysAnnual =
  ContractorsTable.defaultProps.compareWithMarketVisibleColumnsKeys.filter(
    (key: string) => ["rate_type"].indexOf(key) < 0
  );
const spendRatesVisibleColumnsKeysAnnual =
  ContractorsTable.defaultProps.spendRatesVisibleColumnsKeys.filter(
    (key: string) => ["rate_type", "bill_rate", "pay_rate", "markup"].indexOf(key) < 0
  );
const compareWithMarketVisibleColumnsKeysHourly =
  ContractorsTable.defaultProps.compareWithMarketVisibleColumnsKeys.filter(
    (key: string) => ["rate_type"].indexOf(key) < 0
  );
const spendRatesVisibleColumnsKeysHourly =
  ContractorsTable.defaultProps.spendRatesVisibleColumnsKeys.filter(
    (key: string) => ["rate_type", "annual_salary", "burden_perc"].indexOf(key) < 0
  );
const marketRatesVisibleColumnsKeysHourly =
  ContractorsTable.defaultProps.marketRatesVisibleColumnsKeys.filter(
    (k: string) => k !== "rate_type"
  );

const marketRatesVisibleColumnsKeysAnnual = marketRatesVisibleColumnsKeysHourly;
const customConfigVisibleColumnsKeysHourly =
  ContractorsTable.defaultProps.customConfigVisibleColumnsKeys.filter(
    (key: string) =>
      [
        "annual_salary_savings",
        "rate_type",
        "annual_salary",
        "burden_perc",
        "market_analysis__market_annual_salary_max",
        "market_analysis__market_annual_salary_max_variance",
      ].indexOf(key) < 0
  );
const customConfigVisibleColumnsKeysAnnual =
  ContractorsTable.defaultProps.customConfigVisibleColumnsKeys.filter(
    (key: string) =>
      [
        "bill_rate_savings",
        "pay_rate_savings",
        "markup_savings",
        "rate_type",
        "pay_rate",
        "market_analysis__market_pay_rate_max",
        "market_analysis__market_pay_rate_max_variance",
        "markup",
        "markup_amount",
        "market_analysis__market_markup_max",
        "market_analysis__market_markup_max_variance",
        "bill_rate",
        "market_analysis__market_bill_rate_max",
        "market_analysis__market_bill_rate_max_variance",
      ].indexOf(key) < 0
  );

const StoredIndexContractorsTable = (props: ContractorsTableProps) => (
  <ContractorsTable {...props} />
);
StoredIndexContractorsTable.displayName = "StoredIndexContractorsTable";
StoredIndexContractorsTable.defaultProps = ContractorsTable.defaultProps;
StoredIndexContractorsTable.getTableId = (
  userId: number,
  programId: number,
  indexId: number
): string => {
  return `user-${userId}-program-${programId}-index-${indexId}-contractors-table`;
};

interface TopPageBlockProps {
  indexData: StoredIndexDataMap;
  isPreviewMode?: boolean;
  disableButtons?: boolean;
  onUpdateIndexParams: (data: { is_active: boolean }) => void;
  onGoBackToProgramDetailsPage: () => void;
}

const TopPageBlock = (props: TopPageBlockProps) => {
  const {
    indexData,
    isPreviewMode,
    disableButtons,
    onUpdateIndexParams,
    onGoBackToProgramDetailsPage,
  } = props;
  const {
    isClientAdmin,
    isPTAdmin,
    isRegularUser,
    userId: sessionUserId,
  } = usePLIContext();

  const created = indexData.get("created");
  const freshness_timestamp = indexData.getIn(["last_trend_data", "freshness_timestamp"]);
  const userId = indexData.get("user_id");
  const isActive = indexData.get("is_active");
  const isOwned = userId != null && sessionUserId != null && sessionUserId === userId;

  let initialTitle = indexData.get("title");
  if (initialTitle && !initialTitle.toLowerCase().endsWith("view")) {
    initialTitle = initialTitle + " View";
  }

  return (
    <Card fill>
      <CardActions css={{ alignItems: "baseline" }}>
        <CardActionsLeft>
          <h2>
            {initialTitle}
            {((isRegularUser && isOwned) || isPTAdmin || isClientAdmin) &&
              userId != null &&
              (isActive ? (
                <Text as="small" color="positive">
                  {" "}
                  (visible)
                </Text>
              ) : (
                <Text as="small" color="negative">
                  {" "}
                  (hidden)
                </Text>
              ))}
            {created != null && (
              <small>
                <br />
                created at {dateTimeFormatter(created)}
              </small>
            )}
            {freshness_timestamp != null && (
              <small>
                <br />
                freshness date {dateTimeFormatter(freshness_timestamp)}
              </small>
            )}
          </h2>
        </CardActionsLeft>
        <CardActionsRight>
          {((isRegularUser && isOwned) || isPTAdmin || isClientAdmin) &&
            userId != null &&
            !isPreviewMode && (
              <NavigationButton
                icon={isActive ? "eye-slash" : "eye"}
                color={isActive ? "danger" : "success"}
                variant="outlined"
                disabled={disableButtons}
                onClick={() =>
                  onUpdateIndexParams({ is_active: isActive ? false : true })
                }
              >
                {isActive ? "Hide View" : "Make View Visible"}
              </NavigationButton>
            )}
          <NavigationButton
            icon="arrow-left"
            color="green"
            onClick={onGoBackToProgramDetailsPage}
          >
            Back To Index Page
          </NavigationButton>
        </CardActionsRight>
      </CardActions>
    </Card>
  );
};
TopPageBlock.displayName = "TopPageBlock";
TopPageBlock.defaultProps = {
  isPreviewMode: false,
  disableButtons: false,
};

interface BottomPageBlockProps {
  onGoBackToProgramDetailsPage: () => void;
}

const BottomPageBlock = (props: BottomPageBlockProps) => {
  const { onGoBackToProgramDetailsPage } = props;

  return (
    <Card fill css={{ padding: "$4" }}>
      <ButtonGroupRight>
        <NavigationButton
          icon="arrow-left"
          color="green"
          onClick={onGoBackToProgramDetailsPage}
        >
          Back To Index Page
        </NavigationButton>
      </ButtonGroupRight>
    </Card>
  );
};
BottomPageBlock.displayName = "BottomPageBlock";

type SavingsDataState = {
  savingsData: SavingsDataMap;
  savingsDataLoaded: boolean;
};

interface MainIndexParametersBlockProps {
  isEditing: boolean;
  indexTitle: string;
  displayCurrencyCode: string;
  failedContractorsCount: number;
  needApprovalContractorsCount: number;
  processingsRefreshRequestId: number | null;
  onTitleChange: InputProps["onChange"];
  onDisplayCurrencyChange: (value: CurrencyData) => void;
  onRefreshPageData: (withProcessing?: boolean) => Promise<any>;
}

const MainIndexParametersBlock = (props: MainIndexParametersBlockProps) => {
  const {
    isEditing,
    indexTitle,
    displayCurrencyCode,
    failedContractorsCount,
    needApprovalContractorsCount,
    processingsRefreshRequestId,
    onTitleChange,
    onDisplayCurrencyChange,
    onRefreshPageData,
  } = props;

  const {
    userId,
    isClientAdmin,
    isPTAdmin,
    isRegularUser,
    showModalError,
    fetchM8FilteringAPI,
    currenciesData: allCurrenciesData,
  } = usePLIContext();
  const { programId } = useProgramContext();
  const { indexId, indexIsOwned } = useStoredIndexContext();

  const displayCurrencyData = allCurrenciesData.get(displayCurrencyCode);
  const displayCurrencySymbol = displayCurrencyData?.get("symbol");

  // state

  const tableId = StoredIndexContractorsTable.getTableId(userId, programId, indexId);
  const contractorsData = useStoredIndexContractorsTableGlobalState(tableId)[0];

  const [{ savingsData, savingsDataLoaded }, setSavingsDataState] =
    useState<SavingsDataState>({
      savingsData: emptyMap as SavingsDataMap,
      savingsDataLoaded: false,
    });

  // fetch data

  const fetchSavingsData = useCallback(async () => {
    try {
      const response: FetchAPIResponse<SavingsDataObject> = await fetchM8FilteringAPI(
        `programs/${programId}/savings/donut_dataset/`,
        {
          params: { __display_currency_code: displayCurrencyCode },
          data: {
            ...contractorsData.filtersQuery.toJS(),
            status__in: CONTRACTOR_STATUSES.FINISHED,
          },
        }
      );
      const data: SavingsDataMap = savingsDonutDataToImmutableMap(response.data);

      setSavingsDataState({
        savingsData: data,
        savingsDataLoaded: true,
      });

      return data;
    } catch (err: any) {
      setSavingsDataState((prevDataState) => ({
        ...prevDataState,
        savingsDataLoaded: true,
      }));
      logAsyncOperationError("fetchSavingsData", err);
      showModalError("Error occurred while retrieving savings data.");
    }
  }, [
    programId,
    displayCurrencyCode,
    contractorsData.filtersQuery,
    setSavingsDataState,
    fetchM8FilteringAPI,
    showModalError,
  ]);

  // effects

  useEffect(() => {
    fetchSavingsData();
  }, [
    programId,
    displayCurrencyCode,
    contractorsData.itemsCount,
    contractorsData.filtersQuery,
    fetchSavingsData,
  ]);

  // handlers

  const handleChangeProcessingsNumber = useCallback(
    () => onRefreshPageData(false),
    [onRefreshPageData]
  );

  const savingsDetailsModalContent = (
    <Box css={{ fontSize: "$sm" }}>
      <h5>
        The Savings Per Year chart represents the sum of all savings values for the
        dataset displayed in the table below.
      </h5>
      <br />
      <h5>Here are some specifics about the calculation process:</h5>
      <InstructionsCheckMark css={{ fontSize: "$2xl" }} /> All provided types of data
      (contingent/annual) are taken into account during calculation.
      <br />
      <InstructionsCheckMark css={{ fontSize: "$2xl" }} /> Contingent savings is
      calculated using the constant of 2000 working hours per year.
      <br />
      <InstructionsCheckMark css={{ fontSize: "$2xl" }} /> All calculations performed
      using the selected display currency (values are currency converted when necessary).
      <br />
    </Box>
  );

  const disableInputs =
    !((isRegularUser && indexIsOwned) || isPTAdmin || isClientAdmin) || !isEditing;

  return (
    <Stack fill css={{ alignItems: "stretch" }}>
      {isPTAdmin && (
        <RunningProcessingsAlert
          programId={programId}
          storedIndexId={indexId}
          refreshRequestId={processingsRefreshRequestId}
          onChangeProcessingsNumber={handleChangeProcessingsNumber}
        />
      )}
      {isPTAdmin && <FailedContractorsAlert count={failedContractorsCount} />}
      {isPTAdmin && <NeedApprovalContractorsAlert count={needApprovalContractorsCount} />}

      <Stack
        css={{
          justifyContent: "space-around",
          alignItems: "stretch",
          gap: "$8",
          "@lg": {
            flexDirection: "row",
          },
        }}
      >
        <Box
          css={{
            "@lg": { width: "50%" },
          }}
        >
          <SavingsDonut
            displayCurrencySymbol={displayCurrencySymbol}
            savingsData={savingsData}
            savingsDataLoaded={contractorsData.loaded && savingsDataLoaded}
          />
        </Box>
        <Box css={{ flexGrow: 1 }}>
          <Stack css={{ alignItems: "stretch" }}>
            <Box>
              <label>View Name: </label>
              <TextInput
                fill
                color={indexTitle ? "primary" : "danger"}
                value={indexTitle}
                onChange={onTitleChange}
                disabled={disableInputs}
              />
            </Box>
            <Box>
              <label>Display Currency:</label>
              <CurrencySelect
                currenciesData={allCurrenciesData.toList().toJS()}
                value={displayCurrencyData ? displayCurrencyData.toJS() : null}
                onChange={onDisplayCurrencyChange as CurrencySelectProps["onChange"]}
                disabled={disableInputs}
                // @ts-expect-error
                styles={reactSelectStyles}
              />
            </Box>
            <Box>
              <Dialog>
                <DialogTrigger asChild>
                  <SimpleLink>
                    <Icon icon="info-circle" /> How are savings per year calculated
                  </SimpleLink>
                </DialogTrigger>
                <DialogContent>
                  <DialogTitle asChild>
                    <Inline css={{ justifyContent: "space-between" }}>
                      <h4>How are Savings Per Year calculated</h4>
                      <DialogClose asChild>
                        <Icon icon="times" />
                      </DialogClose>
                    </Inline>
                  </DialogTitle>
                  <DialogDescription
                    as="div"
                    css={{ whiteSpace: "pre-wrap", color: "inherit" }}
                  >
                    {savingsDetailsModalContent}
                  </DialogDescription>
                </DialogContent>
              </Dialog>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
};
MainIndexParametersBlock.displayName = "MainIndexParametersBlock";
MainIndexParametersBlock.defaultProps = {
  isEditing: false,
};

const ContractorsTableView = (props: ContractorsTableViewProps) => {
  const {
    editingRowId,
    checkingValidationsRowId,
    contractorsData,
    contractorsDataProvider,
    contractorsFiltersDataProvider,
    //
    onStartRowEditing,
    onCancelRowEditing,
    onApplyRowUpdate,
    onSeeRowValidations,
    onCloseRowValidations,
    onDeleteRow,
    onSelectRow,
    onSelectAllRowsOnThePage,
    onChangeTableConfig,
  } = props;
  const { isSelectingRows, isBulkUpdateSelectingRows } = contractorsData;
  const isEditingRow = editingRowId != null;

  const { router, isPTAdmin } = usePLIContext();
  const { programId, isPreviewMode } = useProgramContext();
  const { indexData } = useStoredIndexContext();

  const lastTrendData =
    indexData.get("last_trend_data") ?? (emptyMap as StoredIndexTrendDataMap);
  const hasBillRateValues = lastTrendData.get("bill_rate_low");
  const hasPayRateValues = lastTrendData.get("pay_rate_low");
  const hasHourlyValues = hasBillRateValues || hasPayRateValues;
  const hasAnnualValues = lastTrendData.get("annual_salary_low");

  let compareWithMarketVisibleColumnsKeys =
    StoredIndexContractorsTable.defaultProps.compareWithMarketVisibleColumnsKeys;
  let spendRatesVisibleColumnsKeys =
    StoredIndexContractorsTable.defaultProps.spendRatesVisibleColumnsKeys;
  let marketRatesVisibleColumnsKeys =
    StoredIndexContractorsTable.defaultProps.marketRatesVisibleColumnsKeys;
  let customConfigVisibleColumnsKeys =
    StoredIndexContractorsTable.defaultProps.customConfigVisibleColumnsKeys;

  if (hasHourlyValues && !hasAnnualValues) {
    compareWithMarketVisibleColumnsKeys = compareWithMarketVisibleColumnsKeysHourly;
    spendRatesVisibleColumnsKeys = spendRatesVisibleColumnsKeysHourly;
    marketRatesVisibleColumnsKeys = marketRatesVisibleColumnsKeysHourly;
    customConfigVisibleColumnsKeys = customConfigVisibleColumnsKeysHourly;
  } else if (hasAnnualValues && !hasHourlyValues) {
    compareWithMarketVisibleColumnsKeys = compareWithMarketVisibleColumnsKeysAnnual;
    spendRatesVisibleColumnsKeys = spendRatesVisibleColumnsKeysAnnual;
    marketRatesVisibleColumnsKeys = marketRatesVisibleColumnsKeysAnnual;
    customConfigVisibleColumnsKeys = customConfigVisibleColumnsKeysAnnual;
  }
  if (isPTAdmin) {
    customConfigVisibleColumnsKeys = defaultCustomConfigVisibleColumnsKeysForAdmin;
  }

  // scroll to table top
  const scrollToRef = useScrollTo([contractorsData.activePage]);

  const handleGoToMakeSearch = useCallback(
    (rowData: ContractorDataMap) => {
      const contractorId = rowData.get("id");
      router.push(
        `/private-index/programs/${programId}/contractors/${contractorId}/market-search`
      );
    },
    [programId, router]
  );

  const renderRowVisibilityStatus = (rowData: ContractorDataMap) => {
    const uploadIsActive = rowData.get("upload")?.get("is_active") ?? false;
    const indexIsActive = indexData.get("is_active", false);
    const reason =
      !uploadIsActive && !indexIsActive
        ? "both upload and view are not visible"
        : !uploadIsActive
        ? "upload is not visible"
        : !indexIsActive
        ? "view is not visible"
        : "";

    return uploadIsActive && indexIsActive ? (
      <Text as={Icon} icon="eye" color="positive" title="Available for clients" />
    ) : (
      <Text
        as={Icon}
        icon="eye-slash"
        color="negative"
        title={`Not available for clients (${reason})`}
      />
    );
  };

  const renderRowActions = (rowData: ContractorDataMap) => {
    const rowId = rowData.get("id");
    const status = rowData.get("status");
    const isProcessed = status === CONTRACTOR_STATUSES.FINISHED;
    const isProcessing =
      status === CONTRACTOR_STATUSES.PENDING || status === CONTRACTOR_STATUSES.CLEAN;

    return (
      <ButtonGroup css={{ justifyContent: "center" }}>
        <IconButton
          icon={"search"}
          color="brand"
          variant="outlined"
          title={isProcessed ? "Show Market Search Detail" : "Pending"}
          onClick={isProcessed ? () => handleGoToMakeSearch(rowData) : undefined}
          disabled={
            !isProcessed ||
            isSelectingRows ||
            isBulkUpdateSelectingRows ||
            isEditingRow ||
            checkingValidationsRowId != null
          }
        />
        {isPTAdmin && !isPreviewMode && (
          <IconButton
            icon="tasks"
            color="brand"
            variant="outlined"
            title="See All Relevant Validations Feedback"
            onClick={() => onSeeRowValidations(rowData)}
            disabled={
              isProcessing ||
              isSelectingRows ||
              isBulkUpdateSelectingRows ||
              isEditingRow ||
              (checkingValidationsRowId != null && checkingValidationsRowId !== rowId)
            }
          />
        )}
        {isPTAdmin && !isPreviewMode && (
          <IconButton
            icon="wrench"
            color="brand"
            variant="outlined"
            title="Edit mappings"
            onClick={() => onStartRowEditing(rowData)}
            disabled={
              isProcessing ||
              isSelectingRows ||
              isBulkUpdateSelectingRows ||
              checkingValidationsRowId != null ||
              (isEditingRow && editingRowId !== rowId)
            }
          />
        )}
      </ButtonGroup>
    );
  };

  const tableGroups: Array<false | GroupElement<ContractorDataMap>> = [
    isPTAdmin && (
      <Group key="__flags" uniqueKey="__flags" title="">
        {isSelectingRows || isBulkUpdateSelectingRows ? (
          <Column
            uniqueKey="__selecting"
            title={selectAllRowsCheckbox(
              contractorsData.selectedRows,
              contractorsData,
              onSelectAllRowsOnThePage
            )}
            getter={(rowData: ContractorDataMap) =>
              selectRowCheckbox(rowData, contractorsData.selectedRows, onSelectRow)
            }
            fixed
          />
        ) : (
          <Column
            uniqueKey="__visibility"
            title=""
            getter={renderRowVisibilityStatus}
            fixed
          />
        )}
      </Group>
    ),
    <Group key="__actions" uniqueKey="__actions" title="Actions">
      <Column uniqueKey="__actions" title="Actions" getter={renderRowActions} fixed />
    </Group>,
  ].concat(renderGroupedTableColumnsSpecs(groupedContractorsTableColumnsSpecs));

  return (
    <Box ref={scrollToRef}>
      <h5>
        <small>* Table contains values in original currency</small>
      </h5>
      <StoredIndexContractorsTable
        isPTAdmin={isPTAdmin}
        rowIdGetter={rowIdGetter}
        editable={isPTAdmin}
        editorImpl={
          isEditingRow
            ? (AdminContractorEditor as RowEditorComponent<ContractorDataMap>)
            : checkingValidationsRowId != null
            ? (JobTitleValidationsView as RowEditorComponent<ContractorDataMap>)
            : null
        }
        selectedRowId={
          isEditingRow
            ? editingRowId
            : checkingValidationsRowId != null
            ? checkingValidationsRowId
            : undefined
        }
        onEditApply={isEditingRow ? onApplyRowUpdate : undefined}
        onEditCancel={
          isEditingRow
            ? onCancelRowEditing
            : checkingValidationsRowId != null
            ? onCloseRowValidations
            : undefined
        }
        onDeleteRow={isEditingRow ? onDeleteRow : undefined}
        multimode
        actions={ALL_TABLE_TABS_TYPES}
        compareWithMarketVisibleColumnsKeys={compareWithMarketVisibleColumnsKeys}
        spendRatesVisibleColumnsKeys={spendRatesVisibleColumnsKeys}
        marketRatesVisibleColumnsKeys={marketRatesVisibleColumnsKeys}
        customConfigVisibleColumnsKeys={customConfigVisibleColumnsKeys}
        dataProvider={contractorsDataProvider}
        filtersDataProvider={contractorsFiltersDataProvider}
        onChangeTableConfig={onChangeTableConfig}
        {...contractorsData}
      >
        {tableGroups}
      </StoredIndexContractorsTable>
    </Box>
  );
};
ContractorsTableView.displayName = "ContractorsTableView";

interface StoredIndexEditorBlockProps
  extends Omit<ContractorsTableViewProps, "contractorsData"> {
  isEditingParameters: boolean;
  hasChanges: boolean;
  indexTitle: string;
  displayCurrencyCode: string;
  failedContractorsCount: number;
  needApprovalContractorsCount: number;
  processingsRefreshRequestId: number | null;
  onCreateRateCardConfirmation: () => void;
  onUndoIndexChanges: () => void;
  onSaveIndexChanges: () => void;
  onCancelIndexChanges: () => void;
  onStartEditingIndex: () => void;
  onTitleChange: MainIndexParametersBlockProps["onTitleChange"];
  onDisplayCurrencyChange: (value: CurrencyData) => void;
  onRefreshPageData: MainIndexParametersBlockProps["onRefreshPageData"];
  onRefreshProcessingsData: () => Promise<any>;
  onStartRowsSelection: StartRowsSelectionHandler;
  onStopRowsSelection: StopRowsSelectionHandler;
  onSelectRow: SelectRowHandler;
  onSelectAllRowsOnThePage: SelectAllRowsOnThePageHandler;
  onCreateSurveys: () => void;
}

const StoredIndexEditorBlock = (props: StoredIndexEditorBlockProps) => {
  const {
    isEditingParameters: isEditingParametersState,
    hasChanges,
    indexTitle,
    displayCurrencyCode,
    editingRowId,
    checkingValidationsRowId,
    failedContractorsCount,
    needApprovalContractorsCount,
    processingsRefreshRequestId,
    onStartRowsSelection,
    onStopRowsSelection,
    onSelectRow,
    onSelectAllRowsOnThePage,
    onCreateSurveys,
    onDeleteRow,
    onSeeRowValidations,
    onCloseRowValidations,
    onStartRowEditing,
    onCancelRowEditing,
    onApplyRowUpdate,
    onCreateRateCardConfirmation,
    onUndoIndexChanges,
    onSaveIndexChanges,
    onCancelIndexChanges,
    onStartEditingIndex,
    onTitleChange,
    onDisplayCurrencyChange,
    onRefreshPageData,
    onRefreshProcessingsData,
    //
    contractorsDataProvider,
    contractorsFiltersDataProvider,
    onChangeTableConfig,
  } = props;

  const isEditingParameters = isEditingParametersState || hasChanges;
  const isEditingRow = editingRowId != null;

  const { isPTAdmin, userId: sessionUserId } = usePLIContext();
  const { programId, isPreviewMode } = useProgramContext();
  const { indexId, indexIsPublic, indexIsEditingAllowed } = useStoredIndexContext();

  // state

  const tableId = StoredIndexContractorsTable.getTableId(
    sessionUserId,
    programId,
    indexId
  );
  const contractorsData = useStoredIndexContractorsTableGlobalState(tableId)[0];
  const {
    isSelectingRows,
    loaded: isContractorsDataLoaded,
    isBulkUpdateSelectingRows,
  } = contractorsData;

  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft>
          <h3>
            View Editor
            {(indexIsPublic || isPreviewMode || !indexIsEditingAllowed) && (
              <small>
                {" "}
                (<Icon icon="eye" /> View only)
              </small>
            )}
          </h3>
        </CardActionsLeft>
        <CardActionsRight>
          {!isSelectingRows && !isBulkUpdateSelectingRows && !isEditingParameters && (
            <BulkExportContractorsButton
              icon={["far", "file-excel"]}
              size="small"
              loadingText="Export to Excel"
              disabled={!isContractorsDataLoaded || !contractorsData.itemsCount}
              allowedStatusesForExport={allowedStatusesForExport}
              contractorsFiltersQuery={contractorsData.filtersQuery}
              currentPage={contractorsData.activePage}
              itemsTotal={contractorsData.itemsCount}
              itemsPerPage={contractorsData.itemsPerPage}
            >
              Export to Excel
            </BulkExportContractorsButton>
          )}
          {isPTAdmin &&
            !isPreviewMode &&
            !isEditingParameters &&
            !isSelectingRows &&
            !isBulkUpdateSelectingRows && (
              <Button
                icon="arrow-circle-up"
                size="small"
                onClick={onCreateRateCardConfirmation}
                disabled={!isContractorsDataLoaded || !contractorsData.itemsCount}
              >
                Create Rate Card
              </Button>
            )}
          {isPTAdmin && !isPreviewMode && !isBulkUpdateSelectingRows && (
            <BulkCreateSurveysButtons
              selectedRows={contractorsData.selectedRows}
              isSelectingRows={contractorsData.isSelectingRows}
              isEditingRow={isEditingRow}
              isContractorsDataLoaded={isContractorsDataLoaded}
              itemsTotal={contractorsData.itemsCount}
              onStartRowsSelection={() => onStartRowsSelection("isSelectingRows")}
              onStopRowsSelection={() => onStopRowsSelection("isSelectingRows")}
              onCreateSurveys={onCreateSurveys}
            />
          )}
          {isPTAdmin &&
            !isPreviewMode &&
            !isSelectingRows &&
            !isBulkUpdateSelectingRows &&
            !isEditingParameters && (
              <BulkRerunContractorsButton
                icon="sync"
                size="small"
                loadingText="Rerun Index"
                disabled={
                  !isContractorsDataLoaded || !contractorsData.itemsCount || isEditingRow
                }
                allowedStatusesForRerun={allowedStatusesForBulkRerun}
                contractorsFiltersQuery={contractorsData.filtersQuery}
                currentPage={contractorsData.activePage}
                itemsTotal={contractorsData.itemsCount}
                itemsPerPage={contractorsData.itemsPerPage}
                itemsOnCurrentPage={contractorsData.data.size}
                onRefreshIsDone={onRefreshProcessingsData}
              >
                Rerun Index
              </BulkRerunContractorsButton>
            )}
          {isPTAdmin && !isPreviewMode && !isSelectingRows && (
            <BulkUpdateButton
              selectedRows={contractorsData.selectedRows}
              isBulkUpdateSelectingRows={contractorsData.isBulkUpdateSelectingRows}
              isEditingRow={isEditingRow}
              isContractorsDataLoaded={isContractorsDataLoaded}
              itemsTotal={contractorsData.itemsCount}
              allowedStatusesForUpdate={allowedStatusesForBulkUpdate}
              allowedParametersForUpdate={allowedParametersForBulkUpdate}
              contractorsFiltersQuery={contractorsData.filtersQuery}
              onStartRowsSelection={() =>
                onStartRowsSelection("isBulkUpdateSelectingRows")
              }
              onStopRowsSelection={() => onStopRowsSelection("isBulkUpdateSelectingRows")}
              onUpdateIsDone={onRefreshPageData}
            />
          )}
          {isPTAdmin &&
            !isPreviewMode &&
            !isSelectingRows &&
            !isBulkUpdateSelectingRows && (
              <BulkDeleteContractorsButton
                icon={["far", "trash-alt"]}
                color="danger"
                size="small"
                loadingText="Bulk Delete"
                disabled={
                  !isContractorsDataLoaded || !contractorsData.itemsCount || isEditingRow
                }
                allowedStatusesForDelete={allowedStatusesForBulkDelete}
                contractorsFiltersQuery={contractorsData.filtersQuery}
                onDeleteIsDone={onRefreshPageData}
              >
                Bulk Delete
              </BulkDeleteContractorsButton>
            )}
          {indexIsEditingAllowed &&
            !isEditingParameters &&
            !indexIsPublic &&
            !isPreviewMode &&
            !isSelectingRows &&
            !isBulkUpdateSelectingRows && (
              <Button icon={["far", "edit"]} size="small" onClick={onStartEditingIndex}>
                Edit View Settings
              </Button>
            )}
          {hasChanges && (
            <Button icon="undo" color="accent" size="small" onClick={onUndoIndexChanges}>
              Restore View Settings
            </Button>
          )}
          {indexIsEditingAllowed &&
            isEditingParameters &&
            !indexIsPublic &&
            !isPreviewMode &&
            !isSelectingRows &&
            !isBulkUpdateSelectingRows && (
              <Button
                icon={["far", "save"]}
                color="success"
                size="small"
                onClick={hasChanges ? onSaveIndexChanges : undefined}
                disabled={!hasChanges}
              >
                Save Changes
              </Button>
            )}
          {indexIsEditingAllowed &&
            isEditingParameters &&
            !indexIsPublic &&
            !isPreviewMode &&
            !isSelectingRows &&
            !isBulkUpdateSelectingRows && (
              <Button icon="times" size="small" onClick={onCancelIndexChanges}>
                Cancel Editing
              </Button>
            )}
        </CardActionsRight>
      </CardActions>

      <CardBody>
        <Stack css={{ alignItems: "stretch", gap: "$8" }}>
          <MainIndexParametersBlock
            isEditing={isEditingParameters}
            indexTitle={indexTitle}
            displayCurrencyCode={displayCurrencyCode}
            failedContractorsCount={failedContractorsCount}
            needApprovalContractorsCount={needApprovalContractorsCount}
            processingsRefreshRequestId={processingsRefreshRequestId}
            onTitleChange={onTitleChange}
            onDisplayCurrencyChange={onDisplayCurrencyChange}
            onRefreshPageData={onRefreshPageData}
          />
          {!isContractorsDataLoaded ? (
            <Box css={{ minHeight: "200px", position: "relative" }}>
              <TickerContentLoader />
            </Box>
          ) : (
            <ContractorsTableView
              editingRowId={editingRowId}
              checkingValidationsRowId={checkingValidationsRowId}
              contractorsData={contractorsData}
              contractorsDataProvider={contractorsDataProvider}
              contractorsFiltersDataProvider={contractorsFiltersDataProvider}
              onStartRowEditing={onStartRowEditing}
              onCancelRowEditing={onCancelRowEditing}
              onApplyRowUpdate={onApplyRowUpdate}
              onSeeRowValidations={onSeeRowValidations}
              onCloseRowValidations={onCloseRowValidations}
              onDeleteRow={onDeleteRow}
              onSelectRow={onSelectRow}
              onSelectAllRowsOnThePage={onSelectAllRowsOnThePage}
              onChangeTableConfig={onChangeTableConfig}
            />
          )}
        </Stack>
      </CardBody>
    </Card>
  );
};
StoredIndexEditorBlock.displayName = "StoredIndexEditorBlock";
StoredIndexEditorBlock.defaultProps = {
  isEditing: false,
  hasChanges: false,
  editingRowId: null,
  checkingValidationsRowId: null,
};

const StoredIndexDetails = (props: CommonStoredIndexChildPageProps) => {
  const {
    router,
    programId,
    isPreviewMode,
    indexTableConfig,
    indexData,
    indexId,
    indexTitle,
    indexDisplayCurrencyCode,
    userId: sessionUserId,
    userEmail,
    isPTAdmin,
    fetchM8API,
    fetchM8FilteringAPI,
    fetchGraphQL,
    showModalError,
    showModalWarning,
    showModalSuccess,
    showLoader,
    hideLoader,
    showConfirmationModal,
    closeConfirmationModal,
  } = props;

  // state

  // stored index state
  const setStoredIndexGlobalState = useStoredIndexGlobalState()[1];
  // contractors table state
  const tableId = StoredIndexContractorsTable.getTableId(
    sessionUserId,
    programId,
    indexId
  );
  const [contractorsData, setContractorsDataState] =
    useStoredIndexContractorsTableGlobalState(tableId);
  const resetStoredIndexContractorsTableGlobalState =
    useResetStoredIndexContractorsTableGlobalState();
  // failed and pending approval alerts state
  const [failedContractorsCount, setFailedContractorsCountState] = useState<number>(0);
  const [needApprovalContractorsCount, setNeedApprovalContractorsCountState] =
    useState<number>(0);
  // index params editing state
  const [isEditing, setIsEditingState] = useState<boolean>(false);
  const [titleState, setTitleState] = useState<string>(indexTitle);
  const [displayCurrencyCodeState, setDisplayCurrencyCodeState] = useState<string>(
    indexDisplayCurrencyCode
  );

  // has changes
  const hasChanges = React.useMemo((): boolean => {
    const initialTitle = indexTitle;
    const currentTitle = titleState;
    const initialCurrencyCode = indexDisplayCurrencyCode;
    const currentCurrencyCode = displayCurrencyCodeState;
    const initialFilters = indexTableConfig.filters || emptyOrderedMap;
    const currentFilters = contractorsData.filters || emptyOrderedMap;
    const initialItemsPerPage = indexTableConfig.itemsPerPage;
    const currentItemsPerPage = contractorsData.itemsPerPage;
    const initialActiveTab = indexTableConfig.activeTab;
    const currentActiveTab = contractorsData.activeTab;

    if (initialTitle !== currentTitle) {
      return true;
    }
    if (initialCurrencyCode !== currentCurrencyCode) {
      return true;
    }
    if (initialItemsPerPage !== currentItemsPerPage) {
      return true;
    }
    if (initialActiveTab !== currentActiveTab) {
      return true;
    }

    const initialVisibleColumns = indexTableConfig.visibleColumns || emptyMap;
    const currentVisibleColumns = contractorsData.visibleColumns || emptyMap;
    const initialCompareWithMarketVisibleColumns =
      initialVisibleColumns.get(TABLE_TABS_TYPES.COMPARE_WITH_MARKET) || emptySet;
    const initialSpendRatesVisibleColumns =
      initialVisibleColumns.get(TABLE_TABS_TYPES.SPEND_RATES) || emptySet;
    const initialMarketRatesVisibleColumns =
      initialVisibleColumns.get(TABLE_TABS_TYPES.MARKET_RATES) || emptySet;
    const initialCustomConfigVisibleColumns =
      initialVisibleColumns.get(TABLE_TABS_TYPES.CUSTOM_CONFIG) || emptySet;
    const currentCompareWithMarketVisibleColumns =
      currentVisibleColumns.get(TABLE_TABS_TYPES.COMPARE_WITH_MARKET) || emptySet;
    const currentSpendRatesVisibleColumns =
      currentVisibleColumns.get(TABLE_TABS_TYPES.SPEND_RATES) || emptySet;
    const currentMarketRatesVisibleColumns =
      currentVisibleColumns.get(TABLE_TABS_TYPES.MARKET_RATES) || emptySet;
    const currentCustomConfigVisibleColumns =
      currentVisibleColumns.get(TABLE_TABS_TYPES.CUSTOM_CONFIG) || emptySet;

    if (
      !initialCompareWithMarketVisibleColumns.equals(
        currentCompareWithMarketVisibleColumns
      )
    ) {
      return true;
    }
    if (!initialSpendRatesVisibleColumns.equals(currentSpendRatesVisibleColumns)) {
      return true;
    }
    if (!initialMarketRatesVisibleColumns.equals(currentMarketRatesVisibleColumns)) {
      return true;
    }
    if (!initialCustomConfigVisibleColumns.equals(currentCustomConfigVisibleColumns)) {
      return true;
    }

    const initialFiltersString = JSON.stringify(initialFilters.entrySeq().toJS());
    const currentFiltersString = JSON.stringify(currentFilters.entrySeq().toJS());

    if (initialFiltersString !== currentFiltersString) {
      return true;
    }

    return false;
  }, [
    indexTitle,
    titleState,
    indexDisplayCurrencyCode,
    displayCurrencyCodeState,
    indexTableConfig.filters,
    contractorsData.filters,
    indexTableConfig.itemsPerPage,
    contractorsData.itemsPerPage,
    indexTableConfig.activeTab,
    contractorsData.activeTab,
    indexTableConfig.visibleColumns,
    contractorsData.visibleColumns,
  ]);

  // fetch data functions

  const filtersQueryRef = useRef<FiltersQueryOrderedMap>(contractorsData.filtersQuery);

  useEffect(() => {
    if (contractorsData.filtersQuery !== filtersQueryRef.current) {
      filtersQueryRef.current = contractorsData.filtersQuery;
    }
  }, [contractorsData.filtersQuery]);

  const fetchContractorsData: ContractorsTableDataProvider = useCallback(
    async (urlQuery = {}, filtersQuery = {}, nextStateUpdates = {}) => {
      try {
        const response: FetchAPIResponse<DjangoPaginatedResponse<ContractorDataObject>> =
          await fetchM8FilteringAPI(`programs/${programId}/contractors/filtered/`, {
            params: urlQuery,
            data: { ...filtersQuery, status__in: [CONTRACTOR_STATUSES.FINISHED] },
          });
        const nextDataState: Partial<ContractorsTableDataStateObject> =
          transformContractorsData(response.data, nextStateUpdates);

        setContractorsDataState((prevDataState: ContractorsTableDataStateObject) => ({
          ...prevDataState,
          ...nextDataState,
          loaded: true,
        }));

        return nextDataState;
      } catch (err: any) {
        logAsyncOperationError("fetchContractorsList", err);
        showModalError(
          "Error occurred while retrieving contractors list. Please, try again later."
        );
        throw err;
      }
    },
    [programId, setContractorsDataState, fetchM8FilteringAPI, showModalError]
  );

  const fetchContractorsFiltersData: ContractorsTableFiltersDataProvider = useCallback(
    async (urlQuery = {}, filtersQuery = {}) => {
      try {
        const response: FetchAPIResponse<DjangoPaginatedResponse<any>> =
          await fetchM8FilteringAPI(
            `programs/${programId}/contractors/values/filtered/`,
            {
              params: urlQuery,
              data: { ...filtersQuery, status__in: [CONTRACTOR_STATUSES.FINISHED] },
            }
          );

        return response.data;
      } catch (err: any) {
        logAsyncOperationError("fetchContractorsListFilterValues", err);
        showModalError(
          "Error occurred while retrieving filter values. Please, try again later."
        );
        throw err;
      }
    },
    [programId, showModalError, fetchM8FilteringAPI]
  );

  const fetchFailedContractorsCount = useCallback(async () => {
    try {
      const response: FetchAPIResponse<DjangoPaginatedResponse<ContractorDataObject>> =
        await fetchM8FilteringAPI(`programs/${programId}/contractors/filtered/`, {
          params: { [djangoPaginationKey]: 1, [djangoPaginationSizeKey]: 1 },
          data: {
            ...contractorsData.filtersQuery.toJS(),
            status__in: [CONTRACTOR_STATUSES.FAILED],
          },
        });
      const data: Partial<ContractorsTableDataStateObject> = transformContractorsData(
        response.data
      );

      setFailedContractorsCountState(data.itemsCount!);
    } catch (err: any) {
      logAsyncOperationError("fetchFailedContractorsNumber", err);
      showModalError("Error occurred while loading failed contractors list.");
    }
  }, [
    programId,
    contractorsData.filtersQuery,
    setFailedContractorsCountState,
    fetchM8FilteringAPI,
    showModalError,
  ]);

  const fetchNeedApprovalContractorsCount = useCallback(async () => {
    try {
      const response: FetchAPIResponse<DjangoPaginatedResponse<ContractorDataObject>> =
        await fetchM8FilteringAPI(`programs/${programId}/contractors/filtered/`, {
          params: { [djangoPaginationKey]: 1, [djangoPaginationSizeKey]: 1 },
          data: {
            ...contractorsData.filtersQuery.toJS(),
            status__in: [CONTRACTOR_STATUSES.NEED_APPROVAL],
          },
        });
      const data: Partial<ContractorsTableDataStateObject> = transformContractorsData(
        response.data
      );

      setNeedApprovalContractorsCountState(data.itemsCount!);
    } catch (err: any) {
      logAsyncOperationError("fetchPendingApprovalContractorsNumber", err);
      showModalError("Error occurred while loading pending approval contractors list.");
    }
  }, [
    programId,
    contractorsData.filtersQuery,
    setNeedApprovalContractorsCountState,
    fetchM8FilteringAPI,
    showModalError,
  ]);

  // utility functions

  const [processingsRefreshRequestId, refreshProcessingsData] = useRefreshRequest();

  const refreshContractorsData = useCallback(async () => {
    return fetchContractorsData(
      {
        [djangoPaginationKey]: contractorsData.activePage,
        [djangoPaginationSizeKey]: contractorsData.itemsPerPage,
      },
      contractorsData.filtersQuery.toJS()
    );
  }, [
    contractorsData.activePage,
    contractorsData.itemsPerPage,
    contractorsData.filtersQuery,
    fetchContractorsData,
  ]);

  const refreshPageData = useCallback(
    async (withProcessing: boolean = true) => {
      if (isPTAdmin) {
        if (withProcessing) {
          refreshProcessingsData();
        }
        fetchFailedContractorsCount();
        fetchNeedApprovalContractorsCount();
      }
      return await refreshContractorsData();
    },
    [
      isPTAdmin,
      refreshProcessingsData,
      fetchFailedContractorsCount,
      fetchNeedApprovalContractorsCount,
      refreshContractorsData,
    ]
  );

  // initial data load

  const isLoadedRef = useRef<boolean>(false);

  useEffect(() => {
    if (!isLoadedRef.current) {
      isLoadedRef.current = true;
      refreshPageData(false);
    }
  }, [refreshPageData]);

  // final cleanup

  useEffect(() => {
    return () => resetStoredIndexContractorsTableGlobalState();
  }, [resetStoredIndexContractorsTableGlobalState]);

  // handlers

  const handleGoBackToProgramDetailsPage = useCallback(() => {
    router.push(`/private-index/programs/${programId}`);
  }, [programId, router]);

  const {
    handleStartRowsSelection,
    handleStopRowsSelection,
    handleSelectRow,
    handleSelectAllRowsOnThePage,
  } = useContractorsSelectionHandlers(
    setContractorsDataState,
    contractorsData.selectedRows,
    contractorsData.data
  );

  const handleCreateSurveys = useCallback(() => {
    if (!contractorsData.selectedRows.size) {
      showModalWarning(`Please, select some rows for validation.`);
      return;
    }
    router.push(
      `/private-index/programs/${programId}/indexes/${indexId}/validations/create?fromPage=stored-index-details`
    );
  }, [contractorsData.selectedRows.size, router, programId, indexId, showModalWarning]);

  type RateCardObject = { ratecard_id: number };
  type RateCardMap = ImmutableMap<RateCardObject>;

  const handleCreateRateCard: CreateRateCardFormProps["onCreateRateCard"] = useCallback(
    async (rateCardParams) => {
      const rateCardName = rateCardParams.rateCardName;
      const legacyUserId = rateCardParams.legacyUser!.userId;
      const emailMe = rateCardParams.emailMe;
      const email = emailMe ? userEmail : null;

      try {
        const response: FetchAPIResponse<RateCardObject> = await fetchM8API(
          `programs/${programId}/stored_indexes/${indexId}/run_create_ratecard/`,
          {
            method: "post",
            data: {
              legacy_user_id: legacyUserId,
              ratecard_name: rateCardName,
              inform_on_email: email,
            },
          }
        );
        const data: RateCardMap = fromJS(response.data);
        const rateCardId = data.get("ratecard_id");

        closeConfirmationModal();

        if (rateCardId != null) {
          refreshProcessingsData();
          showModalSuccess(
            <span>
              The Rate Card #{rateCardId} "{rateCardName || ""}" is created.
              <br />
              The Rate Card Searches import is launched in the background and could take
              some time, depending on the dataset size.
              <br />
              <br />
              Please see the progress bar on top of this page in order to be up to date
              with the process status.
              <br />
            </span>,
            "The Rate Card is created!",
            25000
          );
        }

        return data;
      } catch (err: any) {
        logAsyncOperationError("createRateCard", err);
        showModalError(
          "Error occurred while creating a Rate Card based on the displayed index. See console for more info."
        );
      }
    },
    [
      programId,
      indexId,
      userEmail,
      refreshProcessingsData,
      closeConfirmationModal,
      fetchM8API,
      showModalSuccess,
      showModalError,
    ]
  );

  const handleCreateRateCardConfirmation = useCallback(() => {
    const header = "Create Rate Card";
    const message = (
      <CreateRateCardForm
        fetchGraphQL={fetchGraphQL}
        onCreateRateCard={handleCreateRateCard}
        onClose={closeConfirmationModal}
      />
    );

    showConfirmationModal(message, header);
  }, [handleCreateRateCard, showConfirmationModal, closeConfirmationModal, fetchGraphQL]);

  const handleStartEditingIndex = useCallback(() => {
    setIsEditingState((prevState) => (!prevState ? true : prevState));
  }, [setIsEditingState]);

  const handleTitleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setTitleState((prevState) => (prevState !== value ? value : prevState));
    },
    [setTitleState]
  );

  const handleDisplayCurrencyChange = useCallback(
    (value: CurrencyData) => {
      if (value?.code) {
        setDisplayCurrencyCodeState((prevState) =>
          prevState !== value["code"] ? value["code"] : prevState
        );
      }
    },
    [setDisplayCurrencyCodeState]
  );

  interface StoredIndexUpdateObject
    extends Omit<StoredIndexDataObject, "filters" | "dj_filters" | "visible_columns"> {
    filters: string | null;
    dj_filters: string | null;
    visible_columns: string | null;
  }

  const handleUpdateIndexParams = useCallback(
    async (payload: Partial<StoredIndexUpdateObject> = {}) => {
      showLoader();

      try {
        const response: FetchAPIResponse<StoredIndexDataObject> = await fetchM8API(
          `programs/${programId}/stored_indexes/${indexId}/`,
          {
            method: "patch",
            data: payload,
          }
        );
        const data: StoredIndexDataMap = storedIndexToImmutableMap(response.data);

        setStoredIndexGlobalState((prevState) => ({
          ...prevState,
          indexData: data,
          indexDataLoaded: true,
        }));

        hideLoader();
        showModalSuccess(
          <Text>
            View has been successfully updated{" "}
            <Text as={Icon} color="positive" icon="check" size="lg" />
          </Text>,
          "Success!",
          2000
        );

        return data;
      } catch (err: any) {
        logAsyncOperationError("updateStoredIndexData", err);
        hideLoader();
        showModalError("Error occurred while View updating.");
      }
    },
    [
      programId,
      indexId,
      setStoredIndexGlobalState,
      fetchM8API,
      showLoader,
      hideLoader,
      showModalSuccess,
      showModalError,
    ]
  );

  const handleSaveIndexChanges = async () => {
    const { filters, filtersQuery, visibleColumns, itemsPerPage, activeTab } =
      contractorsData;

    if (!titleState) {
      return await showModalWarning("Please, specify some name for the View.");
    }
    if (!displayCurrencyCodeState) {
      return await showModalWarning("Please, specify some currency to show results in.");
    }

    if (
      sessionUserId != null &&
      filters != null &&
      filtersQuery != null &&
      filters.size > 0 &&
      filtersQuery.size > 0
    ) {
      const payload = {
        title: titleState,
        display_currency_code: displayCurrencyCodeState,
        user_id: sessionUserId,
        filters: JSON.stringify(filters.entrySeq().toJS()),
        dj_filters: JSON.stringify(filtersQuery.entrySeq().toJS()),
        visible_columns: visibleColumns?.size
          ? JSON.stringify(visibleColumns.toJS())
          : null,
        items_per_page: itemsPerPage,
        active_tab: activeTab,
      };

      await handleUpdateIndexParams(payload);
      setIsEditingState(false);
    }
  };

  const handleUndoIndexChanges = async () => {
    if (!hasChanges) return;

    const initialTitle = indexTitle;
    const initialCurrencyCode = indexDisplayCurrencyCode;
    const initialFilters =
      indexTableConfig.filters || StoredIndexContractorsTable.defaultProps.filters;
    const initialFiltersQuery =
      indexTableConfig.filtersQuery ||
      StoredIndexContractorsTable.defaultProps.filtersQuery;
    const initialVisibleColumns = indexTableConfig.visibleColumns;
    const initialItemsPerPage = indexTableConfig.itemsPerPage;
    const initialActiveTab = indexTableConfig.activeTab;

    showLoader();
    setTitleState(initialTitle);
    setDisplayCurrencyCodeState(initialCurrencyCode);

    try {
      return await fetchContractorsData(
        { [djangoPaginationKey]: 1, [djangoPaginationSizeKey]: initialItemsPerPage },
        initialFiltersQuery.toJS(),
        {
          activePage: 1,
          filters: initialFilters,
          filtersQuery: initialFiltersQuery,
          columnsFiltersQueries:
            emptyOrderedMap as unknown as ColumnsFiltersConfigOrderedMap,
          itemsPerPage: initialItemsPerPage,
          visibleColumns: initialVisibleColumns,
          activeTab: initialActiveTab,
        }
      );
    } finally {
      hideLoader();
    }
  };

  const handleCancelIndexChanges = () => {
    setIsEditingState(false);
    handleUndoIndexChanges();
  };

  const {
    editingRowId,
    startRowEditing: handleStartRowEditing,
    stopRowEditing: handleCancelRowEditing,
  } = useEditingRowState(refreshPageData);

  const {
    editingRowId: checkingValidationsRowId,
    startRowEditing: handleSeeRowValidations,
    stopRowEditing: handleCloseRowValidations,
  } = useEditingRowState();

  const handleApplyRowUpdate = useCallback(
    async () => refreshPageData(),
    [refreshPageData]
  );

  const handleDeleteRow = useCallback(
    async () => handleCancelRowEditing(true),
    [handleCancelRowEditing]
  );

  const handleChangeTableConfig = React.useCallback(
    async (changes: TableConfigChangesObject) => {
      setContractorsDataState((prevState) => ({
        ...prevState,
        ...changes,
      }));
    },
    [setContractorsDataState]
  );

  // rendering

  return (
    <Stack>
      <TopPageBlock
        indexData={indexData}
        isPreviewMode={isPreviewMode}
        disableButtons={
          contractorsData.isSelectingRows ||
          contractorsData.isBulkUpdateSelectingRows ||
          isEditing
        }
        onUpdateIndexParams={handleUpdateIndexParams}
        onGoBackToProgramDetailsPage={handleGoBackToProgramDetailsPage}
      />

      <StoredIndexEditorBlock
        isEditingParameters={isEditing}
        hasChanges={hasChanges}
        indexTitle={titleState}
        displayCurrencyCode={displayCurrencyCodeState}
        editingRowId={editingRowId}
        checkingValidationsRowId={checkingValidationsRowId}
        failedContractorsCount={failedContractorsCount}
        needApprovalContractorsCount={needApprovalContractorsCount}
        processingsRefreshRequestId={processingsRefreshRequestId}
        onStartRowsSelection={handleStartRowsSelection}
        onStopRowsSelection={handleStopRowsSelection}
        onSelectRow={handleSelectRow}
        onSelectAllRowsOnThePage={handleSelectAllRowsOnThePage}
        onCreateSurveys={handleCreateSurveys}
        onSeeRowValidations={handleSeeRowValidations}
        onCloseRowValidations={handleCloseRowValidations}
        onStartRowEditing={handleStartRowEditing}
        onCancelRowEditing={handleCancelRowEditing}
        onApplyRowUpdate={handleApplyRowUpdate}
        onDeleteRow={handleDeleteRow}
        onCreateRateCardConfirmation={handleCreateRateCardConfirmation}
        onUndoIndexChanges={handleUndoIndexChanges}
        onSaveIndexChanges={handleSaveIndexChanges}
        onCancelIndexChanges={handleCancelIndexChanges}
        onStartEditingIndex={handleStartEditingIndex}
        onTitleChange={handleTitleChange}
        onDisplayCurrencyChange={handleDisplayCurrencyChange}
        onRefreshPageData={refreshPageData}
        onRefreshProcessingsData={refreshProcessingsData}
        contractorsDataProvider={fetchContractorsData}
        contractorsFiltersDataProvider={fetchContractorsFiltersData}
        onChangeTableConfig={handleChangeTableConfig}
      />

      <BottomPageBlock onGoBackToProgramDetailsPage={handleGoBackToProgramDetailsPage} />
    </Stack>
  );
};

StoredIndexDetails.displayName = "StoredIndexDetails";

// loadSpendByManagerHourlyData() {
//   const { index, params } = this.props;
//   const programId = parseInt(params.programId, 10);

//   this.context.flux
//       .getActions('privateIndex')
//       .getSpendByManagerHourlyDataForStoredIndex(programId, index.get('id'))
//       .then(data => {
//         if (data && data.size) {
//           this.setState({spendByManagerData: data});
//         }
//         return data;
//       })
//       .catch(err => {
//         this.context.showModalError('server error');
//         console.error('Error occurred while loading spend by manager data:', err);
//       });
// }

// loadVolumeByLocationData() {
//   const { index, params } = this.props;
//   const programId = parseInt(params.programId, 10);

//   this.context.flux
//       .getActions('privateIndex')
//       .getVolumeByLocationDataForStoredIndex(programId, index.get('id'))
//       .then(data => {
//         if (data && data.size) {
//           this.setState({volumeByLocationData: data});
//         }
//         return data;
//       })
//       .catch(err => {
//         this.context.showModalError('server error');
//         console.error('Error occurred while loading volume by location data:', err);
//       });
// }

// loadRatesData() {
//   const { index, params } = this.props;
//   const programId = parseInt(params.programId, 10);
//   const queryArgs = {'rate_type': RATE_TYPES.CONTRACT};

//   this.context.flux
//       .getActions('privateIndex')
//       .getStoredIndexRatesList(programId, index.get('id'), queryArgs)
//       .then(data => data && data.rates && this.setState({ratesData: data.rates}))
//       .catch(err => {
//         this.context.showModalError('server error');
//         console.error('Error occurred while loading contractors rates data for stored index:', err);
//       });
// }

// const fetchGraphsData = async () => {
//   return await Promise.all([
//     this.loadSpendByManagerHourlyData(),
//     this.loadVolumeByLocationData(),
//     this.loadRatesData(),
//   ]);
// };

// const { spendByManagerData, volumeByLocationData, ratesData } = this.state;
// const displayCurrencyName = currencies.getIn([displayCurrencyCode, 'name']);
// const displayCurrencySymbol = currencies.getIn([displayCurrencyCode, 'symbol']);
// const pagesNumber = Math.ceil(itemsCount / itemsPerPage) || 1;
// const datasetLength = lastTrendData.get('dataset_length') || 0;
// const hasMarkupValues = lastTrendData.get('markup_low');
// const hasAllHourlyValues = hasBillRateValues && hasPayRateValues && hasMarkupValues;

/*  Index Trends

        <Row className={baseClassName + '__trend-values'}>
          <Col xs={12}>
            <h4>Trend Values</h4>
          </Col>
          <Col xs={12}>
            <div>
              { displayCurrencyCode && (
                  <span>
                    <b>Display currency: </b>
                    { displayCurrencyName && (
                        <i>{ displayCurrencyName } </i>
                      ) }
                    { displayCurrencySymbol && (
                        <i> (<b>{ displayCurrencySymbol }</b>)</i>
                      ) }
                  </span>
                ) }
                <span>
                  <br/>
                  <b>Items count: </b>
                  <i>{ datasetLength }</i>
                </span>
            </div>
            <IndexTrendValues
                index={index}
                currencies={currencies} />
          </Col>
        </Row>
*/

/*  Spend By Manager Graph

        <Row className={baseClassName + '__spend-by-manager'}>
          <Col xs={12}>
            <h4>Hourly Spend By Manager</h4>
          </Col>
          { R.isNil(spendByManagerData) && (
              <Col xs={12}>
                <span>loading spend by manager data ...</span>
              </Col>
            ) }
          { !R.isNil(spendByManagerData) && spendByManagerData.size === 0 && (
              <Col xs={12}>
                <Alert color='warning'>
                  <h4>Can't find spend by manager data.</h4>
                </Alert>
              </Col>
            ) }
          { !R.isNil(spendByManagerData) && spendByManagerData.size > 0 && (
              <Col xs={12}>
                <StoredIndexSpendByManagerChart
                        data={spendByManagerData} />
              </Col>
            ) }
        </Row>
*/

/*  Engagement Trend Graph

        <Row className={baseClassName + '__engagement-trend'}>
          <Col xs={12}>
            <h4>{ `Bill Rate Engagement Trend (${displayCurrencySymbol})` }</h4>
          </Col>
          <Col xs={12}>
            { R.isNil(ratesData) && (
                <span>loading rates data ...</span>
              ) }
            { !R.isNil(ratesData) && ratesData.size === 0 && (
                <Alert color='warning'>
                  <h4>Can't find rates data.</h4>
                </Alert>
              ) }
            { !R.isNil(ratesData) && ratesData.size > 0 && (
                <StoredIndexEngagementTrendChart
                    index={index}
                    currencies={currencies}
                    data={ratesData}
                    xValueFormatter={v => v.getTime()} />
              ) }
          </Col>
        </Row>
*/

/*  Volume by location Graph

        <Row className={baseClassName + '__volume-by-location'}>
          <Col xs={12}>
            <h4>Volume By State</h4>
          </Col>
          { R.isNil(volumeByLocationData) && (
              <Col xs={12}>
                <span>loading volume by location data ...</span>
              </Col>
            ) }
          { !R.isNil(volumeByLocationData) && volumeByLocationData.size === 0 && (
              <Col xs={12}>
                <Alert color='warning'>
                  <h4>Can't find volume data.</h4>
                </Alert>
              </Col>
            ) }
          { !R.isNil(volumeByLocationData) && volumeByLocationData.size > 0 &&
                volumeByLocationData
                  .toArray()
                  .map(item => {
                    const key = item.get('country_id');

                    return (
                      <Col xs={12} key={key}>
                        <StoredIndexVolumeByLocationChart
                            data={item}
                            color='#4682b4' />
                      </Col>
                    );
                  }) }
        </Row>
*/

/*  Rate Breakdown Graph

          { hasAllHourlyValues && (
            <Row className={baseClassName + '__rate-breakdown'}>
              <Col xs={12}>
                <h4>{ `Resource Rate Breakdown (${displayCurrencySymbol})` }</h4>
              </Col>
              <Col xs={12}>
                { R.isNil(ratesData) && (
                    <span>loading rates data ...</span>
                  ) }
                { !R.isNil(ratesData) && ratesData.size === 0 && (
                    <Alert color='warning'>
                      <h4>Can't find rates data.</h4>
                    </Alert>
                  ) }
                { !R.isNil(ratesData) && ratesData.size > 0 && (
                    <StoredIndexRateBreakdownChart
                        index={index}
                        currencies={currencies}
                        data={ratesData} />
                  ) }
              </Col>
            </Row>
          ) }

*/

/*  Markups graph

          { hasMarkupValues && (
            <Row className={baseClassName + '__markups'}>
              <Col xs={12}>
                <h4>Markups (%)</h4>
              </Col>
              <Col xs={12}>
                { R.isNil(ratesData) && (
                    <span>loading rates data ...</span>
                  ) }
                { !R.isNil(ratesData) && ratesData.size === 0 && (
                    <Alert color='warning'>
                      <h4>Can't find rates data.</h4>
                    </Alert>
                  ) }
                { !R.isNil(ratesData) && ratesData.size > 0 && (
                    <StoredIndexMarkupsChart
                        data={ratesData} />
                  ) }
              </Col>
            </Row>
          ) }
*/

// export default connectToStores(StoredIndexDetails, {
//   deviceType: (store) => ({deviceType: store.getDeviceType()}),
//   legacyUser: store => ({legacyUser: store.getCredentials()}),
//   currencies: store => ({currencies: store.getAllCurrenciesList()}),
//   privateIndex: (store, { params }) => {
//     const fullUserInfo = store.getFullUserInfo() || emptyMap;
//     const programId = parseInt(params.programId, 10);
//     const indexId = parseInt(params.indexId, 10);
//     const userId = fullUserInfo.get('userId');
//     const contractorsTableId = !R.isNil(userId) ? ContractorsTable.getTableId(userId, programId, indexId) : null;
//     const failedContractorsTableId = !R.isNil(userId) ? FailedContractorsTable.getTableId(userId, programId) : null;
//     const needApprovalContractorsTableId = !R.isNil(userId) ? NeedApprovalContractorsTable.getTableId(userId, programId) : null;
//
//     return {
//       ...store.getProgramsAdminParametersForUser(),
//       index: store.getStoredIndexById(indexId),
//       contractorsData: store.restoreStoredIndexContractorsData(contractorsTableId),
//       failedContractorsData: store.restoreFailedContractorsData(failedContractorsTableId),
//       needApprovalContractorsData: store.restoreNeedApprovalContractorsData(needApprovalContractorsTableId),
//       runningProcessingsData: store.getRunningProcessingsData(),
//       fullUserInfo,
//     };
//   }
// });

export default StoredIndexDetails;
