// @flow

import { extendObservable, computed, runInAction, action } from "mobx";
import { CountryFilterCriterion, LevelFilterCriterion } from "../RateCard";
import NetworkState from "../NetworkState";
import type { FetchGraphQL } from "../../App";

export class SalaryHistogramStateData {
  currency: ?string;
  binStart: ?number;
  binSize: ?number;
  minSeries: ?(number[]);
  maxSeries: ?(number[]);

  constructor(payloadData: ?Object) {
    this.currency = null;
    this.binStart = null;
    this.binSize = null;
    this.minSeries = null;
    this.maxSeries = null;

    if (payloadData) {
      this.currency = payloadData.currency;
      this.binStart = payloadData.binStart;
      this.binSize = payloadData.binSize;
      this.minSeries = payloadData.minSeries;
      this.maxSeries = payloadData.maxSeries;
    }
  }
}

export default class SalaryHistogramState {
  rateCardStore: Object;
  fetchGraphQL: FetchGraphQL;
  network: NetworkState;
  rateCardId: number;
  chartData: SalaryHistogramStateData;
  countries: CountryFilterCriterion[];
  countriesView: CountryFilterCriterion[];
  selectedCountry: ?CountryFilterCriterion;
  countrySearch: string;
  onCountrySearchChange: (string) => void;
  onSelectedCountryChange: (?CountryFilterCriterion) => void;
  levels: LevelFilterCriterion[];
  selectedLevel: ?LevelFilterCriterion;
  onSelectedLevelChange: (?LevelFilterCriterion) => void;
  load: (?boolean) => Promise<any>;

  constructor(store: Object, fetchGraphQL: FetchGraphQL) {
    this.rateCardStore = store;
    this.fetchGraphQL = fetchGraphQL;

    extendObservable(this, {
      network: new NetworkState(),
      chartData: new SalaryHistogramStateData(null),
      countries: [],
      levels: [],
      selectedCountry: null,
      selectedLevel: null,
      countrySearch: "",
      countriesView: computed(() => {
        if (!this.countrySearch) return [...this.countries];

        return this.countries.filter((country: CountryFilterCriterion) => {
          return country
            .toString()
            .toLowerCase()
            .includes(this.countrySearch.toLowerCase());
        });
      }),
    });

    this.onCountrySearchChange = action(this.onCountrySearchChange.bind(this));
    this.onSelectedCountryChange = action(this.onSelectedCountryChange.bind(this));
    this.onSelectedLevelChange = action(this.onSelectedLevelChange.bind(this));
    this.load = action(this.load.bind(this));
  }

  onCountrySearchChange(value: string) {
    this.countrySearch = value;
  }

  onSelectedCountryChange(country: ?CountryFilterCriterion) {
    this.selectedCountry = country;
    this.load(true);
  }

  onSelectedLevelChange(level: ?LevelFilterCriterion) {
    this.selectedLevel = level;
    this.load(true);
  }

  async load(fullReload: boolean = false): Promise<any> {
    this.network.loading = true;

    if (!fullReload && this.chartData) return;

    const query = `
    query getRateCardVisualizations($rateCardId: Int!, $country: String!, $levelId: Int!) {
      viewer {
        rateCard(legacyId: $rateCardId) {
          visualizations {
            salaryHistogram(country: $country, levelId: $levelId) {
              currency
              binStart
              binSize
              minSeries
              maxSeries
            }
          }
        }
      }
    }
    `;

    if (!this.selectedCountry) return;
    if (!this.selectedLevel) return;

    const variables = {
      rateCardId: this.rateCardStore.rateCardId,
      country: this.selectedCountry.country,
      levelId: this.selectedLevel.id,
    };

    let payload: ?Object;
    try {
      payload = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Getting Rate Card Visualizations", e);
      // TODO: Display user friendly error message
      return;
    }

    runInAction("getVisualizations--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("getRateCardVisualizations", payload)) {
        // TODO: Display user friendly error message
        return;
      }

      // NOTE: Flow refinement
      if (payload == null) {
        return;
      }

      this.chartData = new SalaryHistogramStateData(
        payload.data.viewer.rateCard.visualizations.salaryHistogram
      );
    });
  }
}
