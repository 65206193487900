import React, { useCallback, useState, useRef } from "react";
import moment from "moment";
import { Map } from "immutable";

import Stack from "../../../components/lib/Stack";
import Button from "../../../components/lib/Button";
import Text from "../../../components/lib/Text";
import {
  Card,
  CardBody,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
  CardHeaderTitle,
} from "../../../components/lib/Card";
import { PAYMENT_REPORT_TYPES, PAYMENT_REPORT_TYPES_LABELS } from "../types";
import { emptyOrderedMap } from "../../../constants";
import PaymentReportTableView, {
  getBasePaymentReportFiltersQuery,
} from "../components/PaymentReportTable";
import PaymentReportBlock from "../components/PaymentReportBlock";
import { useInitialFetch, useModalState } from "../../../utils/hooks";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";

import type { PaymentReportTableViewRefType } from "../components/PaymentReportTable";
import type {
  PAYMENT_REPORT_TYPES_TYPE,
  AttemptDataMap,
  ByClientPaymentReportParamsMap,
  ByCountryPaymentReportParamsMap,
  ByValidatorPaymentReportParamsMap,
  GenericPaymentReportParamsMap,
} from "../types";
import type { ImmutableMap, ImmutableOrderedMap } from "../../../types/immutable";
import type { FetchAPIResponse } from "../../../types/fetch";
import { useVal5KAdminContext } from "../context/Val5KAdminContext";

type BackToAttemptsListBlockProps = {
  title?: JSX.Element;
};

const BackToAttemptsListBlock = (props: BackToAttemptsListBlockProps) => {
  const { title } = props;
  const { router } = useVal5KAdminContext();

  const handleBack = useCallback(() => {
    router.push(`/admin/val5000/validations`);
  }, [router]);

  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft>{title}</CardActionsLeft>
        <CardActionsRight>
          <Button icon="arrow-left" size="normal" color="brand" onClick={handleBack}>
            Back to Attempts List
          </Button>
        </CardActionsRight>
      </CardActions>
    </Card>
  );
};
BackToAttemptsListBlock.displayName = "BackToAttemptsListBlock";

type GetGroupsParametersFunc<DT, RP extends GenericPaymentReportParamsMap> = (
  data: DT,
  reportType: PAYMENT_REPORT_TYPES_TYPE,
  reportFromDate: string,
  reportToDate: string
) => GroupsParametersOrderedMap<RP>;

export type SingleGroupParametersMap<RP extends GenericPaymentReportParamsMap> =
  ImmutableMap<{
    title: string;
    reportParams: RP;
  }>;

export type GroupsParametersOrderedMap<RP extends GenericPaymentReportParamsMap> =
  ImmutableOrderedMap<string | number, SingleGroupParametersMap<RP>>;

const getByValidatorGroupsParameters: GetGroupsParametersFunc<
  string[][],
  ByValidatorPaymentReportParamsMap
> = (data, reportType, reportFromDate, reportToDate) => {
  let groupsParamsOrderedMap =
    emptyOrderedMap as unknown as GroupsParametersOrderedMap<ByValidatorPaymentReportParamsMap>;

  data.forEach((row) => {
    if (row?.length === 3) {
      const email: string = row[0].toLowerCase();
      const firstName: string = row[1];
      const lastName: string = row[2];
      const title = `${firstName} ${lastName} (${email})`;
      const reportParamsMap = Map({
        email,
        firstName,
        lastName,
        reportType,
        reportFromDate,
        reportToDate,
      }) as unknown as ByValidatorPaymentReportParamsMap;

      groupsParamsOrderedMap = groupsParamsOrderedMap.set(
        email,
        Map({
          title,
          reportParams: reportParamsMap,
        }) as unknown as SingleGroupParametersMap<ByValidatorPaymentReportParamsMap>
      );
    }
  });

  return groupsParamsOrderedMap;
};

const getByCountryGroupsParameters: GetGroupsParametersFunc<
  string[],
  ByCountryPaymentReportParamsMap
> = (data, reportType, reportFromDate, reportToDate) => {
  let groupsOrderedMap =
    emptyOrderedMap as unknown as GroupsParametersOrderedMap<ByCountryPaymentReportParamsMap>;

  data.forEach((countryName: string) => {
    const reportParamsMap = Map({
      countryName,
      reportType,
      reportFromDate,
      reportToDate,
    }) as unknown as ByCountryPaymentReportParamsMap;

    groupsOrderedMap = groupsOrderedMap.set(
      countryName.toLowerCase(),
      Map({
        title: countryName,
        reportParams: reportParamsMap,
      }) as unknown as SingleGroupParametersMap<ByCountryPaymentReportParamsMap>
    );
  });

  return groupsOrderedMap;
};

const getByClientGroupsParameters: GetGroupsParametersFunc<
  [number, string][],
  ByClientPaymentReportParamsMap
> = (data, reportType, reportFromDate, reportToDate) => {
  let groupsOrderedMap =
    emptyOrderedMap as unknown as GroupsParametersOrderedMap<ByClientPaymentReportParamsMap>;

  data.forEach((row) => {
    if (row && row.length === 2) {
      const clientId = row[0];
      const clientName = row[1];
      const title = `${clientName} (#${clientId})`;
      const reportParamsMap = Map({
        clientId,
        clientName,
        reportType,
        reportFromDate,
        reportToDate,
      }) as unknown as ByClientPaymentReportParamsMap;

      groupsOrderedMap = groupsOrderedMap.set(
        clientId,
        Map({
          title,
          reportParams: reportParamsMap,
        }) as unknown as SingleGroupParametersMap<ByClientPaymentReportParamsMap>
      );
    }
  });

  groupsOrderedMap = groupsOrderedMap.set(
    "none",
    Map({
      title: "Not Attached Surveys (No Client)",
      reportParams: Map({
        clientId: null,
        clientName: null,
        reportType,
        reportFromDate,
        reportToDate,
      }) as unknown as ByClientPaymentReportParamsMap,
    }) as SingleGroupParametersMap<ByClientPaymentReportParamsMap>
  );

  return groupsOrderedMap;
};

const paymentReportParametersMatcher = {
  [PAYMENT_REPORT_TYPES.BY_VALIDATOR]: [
    { __resultset: ["email", "first_name", "last_name"] },
    getByValidatorGroupsParameters,
  ],
  [PAYMENT_REPORT_TYPES.BY_COUNTRY]: [
    { __resultset: ["review__countries_string"] },
    getByCountryGroupsParameters,
  ],
  [PAYMENT_REPORT_TYPES.BY_CLIENT]: [
    { __resultset: ["review__client_id", "review__client_name"] },
    getByClientGroupsParameters,
  ],
} as const;

const PaymentReportPage = () => {
  const { location, fetchArgusAPI, fetchArgusFilteringAPI, showModalError } =
    useVal5KAdminContext();

  const groupTablesRef = useRef<{
    [key: `table-${string}`]: PaymentReportTableViewRefType;
  }>({});

  const reportType: PAYMENT_REPORT_TYPES_TYPE = parseInt(
    location.query.report_type,
    10
  ) as PAYMENT_REPORT_TYPES_TYPE;
  const reportFromDate: string = location.query.date_from;
  const reportToDate: string = location.query.date_to;

  // state

  const [groupsOrderedMap, setGroupsOrderedMap] = useState<
    GroupsParametersOrderedMap<GenericPaymentReportParamsMap>
  >(
    emptyOrderedMap as unknown as GroupsParametersOrderedMap<GenericPaymentReportParamsMap>
  );

  const {
    modalState: exportModalState,
    showModal: showExportModal,
    closeModal: closeExportModal,
  } = useModalState();

  // fetch functions

  const fetchGroupsParameters = useCallback(async () => {
    const [filtersQuery, getGroupsParametersFunc] =
      paymentReportParametersMatcher[reportType];

    try {
      // response is not paginated, since we don't provide pagination params
      const response: FetchAPIResponse<any[]> = await fetchArgusFilteringAPI(
        `attempts/values/filtered/`,
        {
          params: {},
          data: {
            ...getBasePaymentReportFiltersQuery(reportFromDate, reportToDate),
            ...filtersQuery,
          },
        }
      );
      const groupsList = response.data;

      if (groupsList?.length) {
        const groupsParameters = getGroupsParametersFunc(
          groupsList,
          reportType,
          reportFromDate,
          reportToDate
        );

        if (groupsParameters.size > 0) {
          setGroupsOrderedMap(groupsParameters);
        }
      }
    } catch (err: any) {
      logAsyncOperationError("fetchPaymentReportGroupsData", err);
      showModalError(
        "Error occurred while loading groups data. Please, try again later."
      );
    }
  }, [reportType, reportFromDate, reportToDate, fetchArgusFilteringAPI, showModalError]);

  // initial groups data fetch

  useInitialFetch(fetchGroupsParameters);

  // handlers

  const handleChangeRowPaidFlag = useCallback(
    async (value: boolean, rowData: AttemptDataMap, groupKey: string | null = null) => {
      const attemptId = rowData.get("id");

      try {
        await fetchArgusAPI(`attempts/${attemptId}/`, {
          method: "patch",
          data: { paid: value },
        });

        const overallTableComponent = groupTablesRef.current?.["table-overall"];
        await overallTableComponent?.refreshTable();

        if (groupKey) {
          const groupTableComponent = groupTablesRef.current?.[`table-${groupKey}`];
          await groupTableComponent?.refreshTable();
        }
      } catch (err: any) {
        logAsyncOperationError("changeAttemptIsPaidFlag", err);
        showModalError(
          "Error occurred while changing paid flag. Please, try again later."
        );
      }
    },
    [fetchArgusAPI, showModalError]
  );

  const periodString = React.useMemo(
    () =>
      [
        moment.utc(reportFromDate).format("MM/DD/YYYY"),
        moment.utc(reportToDate).format("MM/DD/YYYY"),
      ].join(" - "),
    [reportFromDate, reportToDate]
  );

  // renders

  const headerTitle = React.useMemo(
    () => (
      <CardHeaderTitle>
        <Text>
          <Text nowrap color="brand">
            {PAYMENT_REPORT_TYPES_LABELS[reportType]}
          </Text>{" "}
          <Text nowrap>Payment Report For The Period</Text>{" "}
          <Text nowrap css={{ color: "$primaryDark" }} as="small">
            {periodString}
          </Text>
        </Text>
      </CardHeaderTitle>
    ),
    [reportType, periodString]
  );

  return (
    <Stack>
      <BackToAttemptsListBlock title={headerTitle} />

      <Card fill>
        <CardActions>
          <CardActionsLeft>
            <CardHeaderTitle as="h3">
              Overall Validations Table For Payment
            </CardHeaderTitle>
          </CardActionsLeft>
          <CardActionsRight>
            <Button icon={["far", "file-excel"]} size="small" onClick={showExportModal}>
              Export
            </Button>
          </CardActionsRight>
        </CardActions>
        <CardBody>
          <PaymentReportTableView
            ref={(comp) => comp && (groupTablesRef.current["table-overall"] = comp)}
            reportType={reportType}
            reportFromDate={reportFromDate}
            reportToDate={reportToDate}
            showModalExport={exportModalState}
            onCloseModalExport={closeExportModal}
            onChangeRowPaidFlag={handleChangeRowPaidFlag}
          />
        </CardBody>
      </Card>

      <Card fill>
        <CardActions>
          <CardActionsLeft>
            <CardHeaderTitle as="h3">Grouped Tables</CardHeaderTitle>
          </CardActionsLeft>
          <CardActionsRight />
        </CardActions>
        <CardBody>
          {groupsOrderedMap
            .mapEntries(([key, groupParams], idx) => {
              const title = groupParams.get("title");
              const reportParams = groupParams.get("reportParams").toJS();

              return [
                key,
                <PaymentReportBlock
                  key={key}
                  ref={(comp) => comp && (groupTablesRef.current[`table-${key}`] = comp)}
                  title={title}
                  collapsed={idx !== 0}
                  onChangeRowPaidFlag={handleChangeRowPaidFlag}
                  {...reportParams}
                />,
              ];
            })
            .toArray()}
        </CardBody>
      </Card>

      <BackToAttemptsListBlock />
    </Stack>
  );
};
PaymentReportPage.displayName = "PaymentReportPage";

export default PaymentReportPage;
