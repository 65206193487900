import React, { useCallback } from "react";

import Button, { ButtonProps } from "../../../components/lib/Button";
import BulkUpdateContractorsButton, {
  BulkUpdateContractorsButtonProps,
} from "../components/BulkUpdateContractorsButton";
import { ButtonGroupRight } from "../../../components/lib/ButtonGroup";
import { usePLIContext } from "../context";

import type { ContractorsDataOrderedMap } from "../types";
import type {
  CloseConfirmationModalFunc,
  ShowConfirmationModalFunc,
} from "../../../components/ConfirmationModal";
import type { ImmutableList } from "../../../types/immutable";

export type BulkUpdateButtonProps = {
  selectedRows: ContractorsDataOrderedMap;
  isBulkUpdateSelectingRows: boolean;
  isEditingRow: boolean;
  isContractorsDataLoaded: boolean;
  itemsTotal: number;
  onStartRowsSelection: React.MouseEventHandler<HTMLButtonElement>;
  onStopRowsSelection: React.MouseEventHandler<HTMLButtonElement>;
} & BulkUpdateContractorsButtonProps;

export default function BulkUpdateButton(props: BulkUpdateButtonProps) {
  const {
    selectedRows,
    isBulkUpdateSelectingRows,
    isEditingRow,
    isContractorsDataLoaded,
    itemsTotal,
    allowedStatusesForUpdate,
    allowedParametersForUpdate,
    contractorsFiltersQuery,
    onStartRowsSelection,
    onStopRowsSelection,
    onUpdateIsDone,
  } = props;

  const { showConfirmationModal, closeConfirmationModal } = usePLIContext();

  const selectedIds = selectedRows.keySeq().toList() as unknown as ImmutableList<number>;

  const sharedButtonProps: ButtonProps = {
    icon: "wrench",
    size: "small",
    color: isBulkUpdateSelectingRows ? "brand" : undefined,
    disabled:
      !isContractorsDataLoaded ||
      !itemsTotal ||
      isEditingRow ||
      (isBulkUpdateSelectingRows && selectedRows.size === 0),
  };

  // handlers

  const handleUpdateIsDone = useCallback(
    async (e) => {
      await onUpdateIsDone();
      onStopRowsSelection(e);
    },
    [onUpdateIsDone, onStopRowsSelection]
  );

  return (
    <>
      {!isBulkUpdateSelectingRows && (
        <Button
          {...sharedButtonProps}
          onClick={() =>
            showValidationsConfirmation(
              onStartRowsSelection,
              showConfirmationModal,
              closeConfirmationModal
            )
          }
        >
          Make Bulk Update
        </Button>
      )}

      {isBulkUpdateSelectingRows && (
        <>
          <BulkUpdateContractorsButton
            {...sharedButtonProps}
            css={{ position: "relative" }}
            loadingText="Bulk Update"
            allowedStatusesForUpdate={allowedStatusesForUpdate}
            allowedParametersForUpdate={allowedParametersForUpdate}
            contractorsFiltersQuery={contractorsFiltersQuery}
            selectedIds={selectedIds}
            onUpdateIsDone={handleUpdateIsDone}
          >
            Bulk Update
          </BulkUpdateContractorsButton>

          <Button icon="times" size="small" onClick={onStopRowsSelection}>
            Cancel
          </Button>
        </>
      )}
    </>
  );
}

const showValidationsConfirmation = (
  startRowsSelection: React.MouseEventHandler<HTMLButtonElement>,
  showConfirmationModal: ShowConfirmationModalFunc,
  closeConfirmationModal: CloseConfirmationModalFunc
) => {
  const header = "Bulk Update";
  const message = (
    <div>
      <span>You can select specific rows in the table to update only those rows.</span>
      <br />
      <span>
        Click <b>"Bulk Update"</b> in order to continue.
      </span>
    </div>
  );
  const footer = (
    <ButtonGroupRight fill css={{ flexDirection: "row-reverse" }}>
      <Button size="large" onClick={closeConfirmationModal}>
        Cancel
      </Button>
      <Button size="large" color="brand" onClick={startRowsSelection}>
        Yes, Bulk Update
      </Button>
    </ButtonGroupRight>
  );

  showConfirmationModal(message, header, footer);
};
