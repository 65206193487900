import React from "react";

import Grid from "../../../../components/lib/Grid";
import Stack from "../../../../components/lib/Stack";
import Button from "../../../../components/lib/Button";
import ReviewPrimaryParametersBlock from "../../components/ReviewPrimaryParametersBlock";
import AttemptSecondaryParametersBlock from "./AttemptSecondaryParametersBlock";
import RateTypeSelector from "../../components/RateTypeSelector";
import DisplayCurrencySelector from "../../components/DisplayCurrencySelector";
import Text from "../../../../components/lib/Text";
import { SimpleLink } from "../../../../components/lib/Link";
import {
  Card,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
  CardHeaderTitle,
  CardBody,
} from "../../../../components/lib/Card";
import { SurveysGroupAlert } from "../../components/Alerts";
import { useReviewDataContext } from "../../context/ReviewDataContext";
import { useRateDisplayContext } from "../../context/RateDisplayContext";

function AttemptParametersBlock() {
  const {
    displayRateType,
    displayCurrencyData,
    isDisplayCurrencyChanged,
    changeDisplayRateType: handleChangeRateType,
    changeDisplayCurrency: handleChangeCurrency,
    resetDisplayCurrency: handleResetCurrency,
  } = useRateDisplayContext();

  const {
    firstJobData,
    reviewsGroupId,
    reviewLink,
    isClassicSurvey,
    isFillInTheBlankSurvey,
    isAnnualRateSurvey,
  } = useReviewDataContext();

  const jobTitle = firstJobData.get("title");
  const jobDescription = firstJobData.get("description");

  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft>
          <CardHeaderTitle as="h3">Survey Parameters</CardHeaderTitle>
        </CardActionsLeft>
        <CardActionsRight>
          {isFillInTheBlankSurvey && !isAnnualRateSurvey && (
            <RateTypeSelector
              rateType={displayRateType}
              job={firstJobData}
              onChange={handleChangeRateType}
            />
          )}
          {isFillInTheBlankSurvey &&
            displayCurrencyData.size > 0 &&
            !isDisplayCurrencyChanged && (
              <DisplayCurrencySelector
                currencyData={displayCurrencyData}
                onChange={handleChangeCurrency}
              />
            )}
          {isFillInTheBlankSurvey &&
            displayCurrencyData.size > 0 &&
            isDisplayCurrencyChanged && (
              <Button color="brand" variant="outlined" onClick={handleResetCurrency}>
                Reset Currency Back
              </Button>
            )}
        </CardActionsRight>
      </CardActions>
      <CardBody>
        <Stack css={{ alignItems: "flex-start" }}>
          {reviewLink && (
            <Text>
              Link To The Survey:&nbsp;
              <SimpleLink href={reviewLink} target="_blank" rel="noreferrer">
                {reviewLink}
              </SimpleLink>
            </Text>
          )}

          {(isClassicSurvey || isFillInTheBlankSurvey) && !!reviewsGroupId && (
            <SurveysGroupAlert />
          )}

          <Grid
            css={{
              width: "$full",
              gridTemplateColumns: "$auto",
              gridAutoFlow: "row",
              "@md": {
                gridAutoFlow: "column",
                gridTemplateColumns: "$auto $3-of-5",
              },
            }}
          >
            <ReviewPrimaryParametersBlock
              jobTitle={jobTitle}
              jobDescription={jobDescription}
            />
            <AttemptSecondaryParametersBlock />
          </Grid>
        </Stack>
      </CardBody>
    </Card>
  );
}

AttemptParametersBlock.displayName = "AttemptParametersBlock";

export default AttemptParametersBlock;
