/**
 * @generated SignedSource<<23a8722873d2e6ecd57f94f0620571a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentRateSearch_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"JobTitleSearchResults_savesearch" | "ResultOptions_viewer" | "ResultTabs_viewer" | "SettingsMenuSearchResutls_viewer">;
  readonly " $fragmentType": "ContentRateSearch_viewer";
};
export type ContentRateSearch_viewer$key = {
  readonly " $data"?: ContentRateSearch_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentRateSearch_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "searchId",
    "variableName": "searchId"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentRateSearch_viewer",
  "selections": [
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "JobTitleSearchResults_savesearch"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "ResultOptions_viewer"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "SettingsMenuSearchResutls_viewer"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "ResultTabs_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "62da3353c3089e7ccb986302bd05c1a6";

export default node;
