// @flow

import React, { Component } from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingIndicator from "../ratecards/components/LoadingIndicator";
import Container from "../ratecards/components/Container";
import graphql from "babel-plugin-relay/macro";
import ContainerSection from "../ratecards/components/ContainerSection";
import Modal from "../ratecards/components/Modal";
import LevelCardsSection from "./components/LevelCardsSection";
import RatesByLevelSection from "./components/RatesByLevelSection";
import PunchOutsView from "./components/PunchOutsView";
import type MobXStore from "../../stores/mobx/MobXStore";
import RatesTrendModal from "./components/RatesTrendModal";
import RateCardsList from "../ratecards/components/RateCardsList";
import TagItem from "../ratecards/components/TagItem";
import HistogramChart from "../../components/rate_search/HistogramChart";
import ApplyTagFilterModal from "../shared/modals/ApplyTagFilterModal";
import SearchDetailStore from "../../stores/mobx/SearchDetailStore";
import LevelsGuideModal from "../../components/modals/LevelsGuideModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ModalButtons, ModalTitle } from "../ratecards/components/FilterModal";
import Button from "../../components/lib/Button";
import TextInput from "../../components/lib/TextInput";
import Box from "../../components/lib/Box";
import SearchBox from "../../components/lib/SearchBox";
import { CardHeaderTitle } from "../../components/lib/Card";
import { InlineElements } from "../../components/lib/Inline";
import { Link } from "../../components/lib/Link";
import Inline from "../../components/lib/Inline";
import Text from "../../components/lib/Text";
import Stack from "../../components/lib/Stack";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogClose,
} from "../../components/lib/Dialog";
import RateTypeTooltip from "../../components/RateTypeTooltip";
import RsSkillsWithJobDescription from "../ratesearch2/JobTittleSkills.tsx";
import { getSkillFeatureFlag } from "../ratesearch2/rsSearchBySkillsUtil";
import { useLazyLoadQuery } from "react-relay";
import { BellCurveBillRates } from "../ratesearch2/RsResults/Healthcare/BellCurveBillRates";
import LinkButton from "../../components/lib/LinkButton";
import { useSearchResultsStore } from "../../stores/SearchResults";

const SearchDetailsQuery = graphql`
  query DetailsQuery($searchID: Int!) {
    viewer {
      savedsearch(id: $searchID) {
        rateType
        rates {
          rawBillRates
        }
        ...BellCurveBillRates_savedsearch
      }
    }
  }
`;

type ModalProps = {
  store: SearchDetailStore,
};

const Modals = observer((props: ModalProps) => {
  const store = props.store;
  const globalStore = store.mobxStore.globalStateStore;
  return (
    <div>
      <Modal
        show={store.confirmUpdateRatesOnSearchModal.show}
        onHide={store.confirmUpdateRatesOnSearchModal.hideModal}
      >
        <ContainerSection className="header">
          <ModalTitle>Confirmation Needed</ModalTitle>
        </ContainerSection>
        <ContainerSection>
          <p>Are you sure you want to update the rates on this Search?</p>
        </ContainerSection>
        <ContainerSection className="footer">
          <ModalButtons>
            <Button
              size="large"
              color="brand"
              onClick={() => {
                store.confirmUpdateRatesOnSearchModal.search.updateRates(true);
                store.confirmUpdateRatesOnSearchModal.hideModal();
              }}
            >
              Yes, Update Rates
            </Button>
            <Button
              size="large"
              onClick={store.confirmUpdateRatesOnSearchModal.hideModal}
            >
              No, Cancel
            </Button>
          </ModalButtons>
        </ContainerSection>
      </Modal>

      <Modal
        show={store.confirmUndoUpdateRatesOnSearchModal.show}
        onHide={store.confirmUndoUpdateRatesOnSearchModal.hideModal}
      >
        <ContainerSection className="header">
          <ModalTitle>Confirmation Needed</ModalTitle>
        </ContainerSection>
        <ContainerSection>
          <p>Are you sure you want to Update the rates on this Search?</p>
        </ContainerSection>
        <ContainerSection className="footer">
          <ModalButtons>
            <Button
              size="large"
              color="brand"
              onClick={() => {
                store.confirmUndoUpdateRatesOnSearchModal.search.undoUpdateRates(true);
                store.confirmUndoUpdateRatesOnSearchModal.hideModal();
              }}
            >
              Yes, Undo Last Update
            </Button>
            <Button
              size="large"
              onClick={store.confirmUndoUpdateRatesOnSearchModal.hideModal}
            >
              No, Cancel
            </Button>
          </ModalButtons>
        </ContainerSection>
      </Modal>

      <Modal
        show={store.confirmDeleteModal.show}
        onHide={store.confirmDeleteModal.hideModal}
      >
        <ContainerSection className="header">
          <ModalTitle>Confirmation Needed</ModalTitle>
        </ContainerSection>
        <ContainerSection>
          <p>Are you sure you want to delete this Search?</p>
          <p className="text-danger">
            <FontAwesomeIcon icon="exclamation-triangle" />{" "}
            <em>This action cannot be undone!</em>
          </p>
        </ContainerSection>
        <ContainerSection className="footer">
          <ModalButtons>
            <Button
              size="large"
              color="danger"
              onClick={() => {
                store.confirmDeleteModal.hideModal();
                store.deleteSearch();
              }}
            >
              Yes, Delete Search
            </Button>{" "}
            <Button size="large" onClick={store.confirmDeleteModal.hideModal}>
              No, Cancel
            </Button>
          </ModalButtons>
        </ContainerSection>
      </Modal>

      <Modal
        show={globalStore.customMultipliersModal.show}
        onHide={globalStore.onCancelCustomMultipliers}
      >
        <ContainerSection className="header">
          <ModalTitle>Custom Multipliers</ModalTitle>
        </ContainerSection>
        <ContainerSection>
          <label>Change multipliers for daily, weekly and monthly rates.</label>
        </ContainerSection>
        <ContainerSection>
          <div className="flex center-items">
            <div style={{ width: 90 }}>
              <label>Daily:</label>
            </div>
            <div style={{ flex: "1 1 auto" }}>
              <TextInput
                fill
                color={globalStore.customDailyMultiplierInvalid ? "danger" : "primary"}
                value={globalStore.customDailyMultiplier}
                onChange={globalStore.onCustomDailyMultiplierChange}
              />
            </div>
          </div>
        </ContainerSection>
        <ContainerSection>
          <div className="flex center-items">
            <div style={{ width: 90 }}>
              <label>Weekly:</label>
            </div>
            <div style={{ flex: "1 1 auto" }}>
              <TextInput
                fill
                color={globalStore.customWeeklyMultiplierInvalid ? "danger" : "primary"}
                value={globalStore.customWeeklyMultiplier}
                onChange={globalStore.onCustomWeeklyMultiplierChange}
              />
            </div>
          </div>
        </ContainerSection>
        <ContainerSection>
          <div className="flex center-items">
            <div style={{ width: 90 }}>
              <label>Monthly:</label>
            </div>
            <div style={{ flex: "1 1 auto" }}>
              <TextInput
                fill
                color={globalStore.customMonthlyMultiplierInvalid ? "danger" : "primary"}
                value={globalStore.customMonthlyMultiplier}
                onChange={globalStore.onCustomMonthlyMultiplierChange}
              />
            </div>
          </div>
        </ContainerSection>
        <ContainerSection className="footer">
          <ModalButtons>
            <Button
              size="large"
              color="brand"
              onClick={globalStore.onApplyCustomMultipliers}
            >
              Apply
            </Button>
            <Button size="large" onClick={globalStore.onResetCustomMultipliers}>
              Reset
            </Button>
            <Button size="large" onClick={globalStore.onCancelCustomMultipliers}>
              Cancel
            </Button>
          </ModalButtons>
        </ContainerSection>
      </Modal>

      <Modal
        show={store.addToRateCardModal.show}
        onHide={store.addToRateCardModal.hideModal}
        innerStyle={{ width: "100%" }}
        fullScreen={true}
      >
        <div className="container-section header">
          <ModalTitle>Choose Rate Card to add search to</ModalTitle>
        </div>

        <div className="rc-viewport overlap-lr-padding">
          <ContainerSection className="overlap-t-padding">
            <div className="header-action-bar">
              <SearchBox
                value={store.rateCardsListStore.rateCardLabelFilter.textToLookFor}
                onChange={
                  store.rateCardsListStore.rateCardLabelFilter.onTextToLookForChange
                }
                onSubmit={store.rateCardsListStore.rateCardLabelFilter.onApply}
                style={{ width: "260px" }}
              />
            </div>
          </ContainerSection>
          <Box css={{ margin: "0 -20px" }}>
            <RateCardsList store={store.rateCardsListStore} />
          </Box>
        </div>
        <ContainerSection className="footer">
          <ModalButtons>
            <Button
              size="large"
              color="brand"
              disabled={store.networkAddtoRateCard.loading}
              onClick={() => {
                store.addSearchToRateCard().then(store.addToRateCardModal.hideModal);
              }}
            >
              Add
            </Button>
            <Button size="large" onClick={store.addToRateCardModal.hideModal}>
              Cancel
            </Button>
          </ModalButtons>
        </ContainerSection>
      </Modal>
      <ApplyTagFilterModal
        filterState={store.applyTagState}
        applyTagsEvent={store.applyTagsToDetail}
        btnText="Add Tags to Search"
      />

      <RatesTrendModal
        search={store.search}
        show={store.ratesTrendModal.show}
        loading={store.network.loading}
        onClose={store.ratesTrendModal.hideModal}
        levelName={store.selectedLevel}
        sampleSizeItems={store.sampleSizeItems}
        selectedSampleSize={store.selectedSampleSize}
        onSelectedSampleSizeChange={store.onSelectedSampleSizeChange}
      />

      <LevelsGuideModal
        show={store.levelsGuideModal.show}
        onHide={store.levelsGuideModal.hideModal}
      />
    </div>
  );
});

function DownloadPDF({ search, showBellcurve }) {
  const params = useSearchResultsStore((s) => s.getURLSearchParams());

  if (showBellcurve) {
    return (
      <LinkButton
        as="a"
        variant="outlined"
        color="brand"
        target="_blank"
        download={`search-details-${search.id}.pdf`}
        href={`/api/pdf-export/searches/${search.id}?${params}`}
      >
        Download PDF
      </LinkButton>
    );
  }

  params.set("searchDetailsView", "Card View");

  const markupParams = new URLSearchParams(params);
  markupParams.set("searchDetailsTabId", "Markup");

  const billParams = new URLSearchParams(params);
  billParams.set("searchDetailsTabId", "Bill Rate");

  const payParams = new URLSearchParams(params);
  payParams.set("searchDetailsTabId", "Pay Rate");

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outlined" color="brand">
          Download PDF
        </Button>
      </DialogTrigger>
      <DialogContent>
        <Stack css={{ padding: "$4", alignItems: "flex-start", gap: "$8" }}>
          <Stack css={{ alignItems: "flex-start", gap: "$1" }}>
            <Text as="h3" css={{ margin: "$0" }}>
              Download PDF
            </Text>
            <Text as="p">Please select the rate to use for the PDF export</Text>
          </Stack>

          <Inline
            css={{ justifyContent: "center", alignItems: "center", width: "$full" }}
          >
            <DialogClose asChild>
              <LinkButton
                as="a"
                variant="outlined"
                color="brand"
                target="_blank"
                size="normal"
                download={`search-details-${search.id}.pdf`}
                href={`/api/pdf-export/searches/${search.id}?${markupParams}`}
              >
                Markup
              </LinkButton>
            </DialogClose>
            <DialogClose asChild>
              <LinkButton
                as="a"
                variant="outlined"
                color="brand"
                target="_blank"
                size="normal"
                download={`search-details-${search.id}.pdf`}
                href={`/api/pdf-export/searches/${search.id}?${billParams}`}
              >
                Bill Rate
              </LinkButton>
            </DialogClose>
            <DialogClose asChild>
              <LinkButton
                as="a"
                variant="outlined"
                color="brand"
                target="_blank"
                size="normal"
                download={`search-details-${search.id}-markup.pdf`}
                href={`/api/pdf-export/searches/${search.id}?${payParams}`}
              >
                Pay Rate
              </LinkButton>
            </DialogClose>
          </Inline>

          <DialogClose asChild>
            <Button variant="text">Close</Button>
          </DialogClose>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

type Props = {
  store: MobXStore,
  router: Object,
  bellcurve: any,
  showBellcurve: Boolean,
};

class Detail extends Component<Props> {
  constructor(props) {
    super(props);
    this.pdfTargetRef = React.createRef();
  }

  componentDidMount() {
    this.props.store.searchDetailStore.router = this.props.router;
    const searchId = parseInt(this.props.router.params.searchId);
    this.props.store.searchDetailStore.fetchSearch(searchId);

    // Reset state when page is mounted to make sure both stores are in sync
    const { setRateFrequency, setSearchDetailsUI } = useSearchResultsStore.getState();
    setRateFrequency("Hourly");
    setSearchDetailsUI({ cardVariant: "marketrates" });
  }

  render() {
    const searchDetailStore = this.props.store.searchDetailStore;
    if (searchDetailStore.network.loading) {
      return <LoadingIndicator />;
    }

    if (searchDetailStore.network.error) {
      return (
        <div className="pt-error-message">
          <h2>An error has occurred, please try again later</h2>
          <pre>
            <code>{JSON.stringify(searchDetailStore.network.error, null, 2)}</code>
          </pre>
        </div>
      );
    }

    let tagSection = "No tags attached.";
    const search = searchDetailStore.search;

    if (!search) return <code>Unable to render search info</code>;

    if (search && search.tags && search.tags.length > 0) {
      tagSection = search.tags.map((tag) => (
        <TagItem
          key={tag.tagId}
          tag={tag}
          hasOwnerShip={searchDetailStore.hasOwnership}
          onClick={searchDetailStore.removeTag}
        />
      ));
    }
    return (
      <div className="view search" ref={this.pdfTargetRef}>
        <Container>
          <header className="top">
            <div className="above">
              <CardHeaderTitle>{search.label}</CardHeaderTitle>
              <InlineElements>
                <Button
                  color="brand"
                  icon="arrow-left"
                  onClick={() => {
                    this.props.router.goBack();
                  }}
                >
                  Go Back
                </Button>
                <DownloadPDF search={search} showBellcurve={this.props.showBellcurve} />
                {search.isDraft && (
                  <Button onClick={searchDetailStore.addToRateCard}>
                    Add to Rate Card
                  </Button>
                )}
                {searchDetailStore.hasOwnership && (
                  <Button onClick={searchDetailStore.applyTagState.showTagModal}>
                    Add Tags
                  </Button>
                )}
                {searchDetailStore.hasOwnership && (
                  <Button
                    color="danger"
                    icon="trash-alt"
                    onClick={searchDetailStore.confirmDeleteModal.showModal}
                  >
                    Delete
                  </Button>
                )}
              </InlineElements>
            </div>
          </header>

          <div className="row">
            <div
              className={
                this.props.showBellcurve ? "col-lg-12 col-md-12" : "col-lg-7 col-md-12"
              }
            >
              <div className="row">
                {/* JOB DESCRIPTION PANEL */}
                <div className="col-sm-9">
                  <div className="panel panel-default panel-details">
                    <div className="panel-heading">
                      <h3 className="panel-title">Job Description</h3>
                    </div>
                    <div className="panel-body pt-job-description">
                      <pre className="text-wrap">
                        {search.jobDescription && search.jobDescription.trim()
                          ? search.jobDescription
                          : "No Job Description"}
                      </pre>
                    </div>
                  </div>
                  {getSkillFeatureFlag(this.props.sessionInfo?.client) && (
                    <div className="panel panel-default panel-details">
                      <div className="panel-heading">
                        <h3 className="panel-title">Skills</h3>
                      </div>
                      <div className="panel-body pt-job-description">
                        <Box css={{ fontSize: "$base" }}>
                          <RsSkillsWithJobDescription
                            jobDescription={search.jobDescription}
                            showSkillLabel={false}
                          />
                        </Box>
                      </div>
                    </div>
                  )}
                </div>
                {/* DETAILS PANEL */}
                <div className="col-sm-3">
                  <div className="panel panel-default panel-details">
                    <div className="panel-heading">
                      <h3 className="panel-title">Details</h3>
                    </div>
                    <div className="panel-body">
                      <div className="row">
                        {search.isGSS && (
                          <div className="col-sm-12">
                            <section className="section-detail text-small">
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="gssTooltip">
                                    Global Supplier Search
                                  </Tooltip>
                                }
                              >
                                <div>
                                  <FontAwesomeIcon
                                    icon="globe"
                                    fixedWidth
                                    className="icon"
                                  />{" "}
                                  GSS
                                </div>
                              </OverlayTrigger>
                            </section>
                          </div>
                        )}
                        <div className="col-sm-12">
                          <section className="section-detail text-small">
                            <Inline className="text-xx-small" css={{ gap: "$1" }}>
                              Rate Type
                              <RateTypeTooltip />
                            </Inline>
                            {search.frequency.title}
                          </section>
                        </div>
                        {search.workerTypeName && (
                          <div className="col-sm-12">
                            <section className="section-detail text-small">
                              <header className="text-xx-small">Worker Type</header>
                              {search.workerTypeName}
                            </section>
                          </div>
                        )}
                        {/* TODO: Category replaced by tags */}
                        {/*<div className="col-sm-12">*/}
                        {/*<section className="section-detail text-small">*/}
                        {/*<header className="text-xx-small">Category</header>*/}
                        {/*{searchDetailStore.search.category}*/}
                        {/*</section>*/}
                        {/*</div>*/}
                        <div className="col-sm-12">
                          <section className="section-detail text-small">
                            <header className="text-xx-small">Industry</header>
                            {search.industry.title}
                          </section>
                        </div>
                        {searchDetailStore.mobxStore && (
                          <div className="col-sm-12">
                            <section className="section-detail text-small">
                              <header className="text-xx-small">Rate Card</header>
                              {search && search.rateCardId ? (
                                search.rateCardName ? (
                                  <Link to={`/ratecards/${search.rateCardId}/`}>
                                    {search.rateCardName}
                                  </Link>
                                ) : (
                                  "N/A"
                                )
                              ) : search.rateCardName ? (
                                search.rateCardName
                              ) : (
                                "N/A"
                              )}
                            </section>
                          </div>
                        )}
                        <div className="col-sm-12">
                          <section className="section-detail text-small">
                            <header className="text-xx-small">Job Title</header>
                            {search.title}
                          </section>
                        </div>
                        <div className="col-sm-12">
                          <section className="section-detail text-small">
                            <header className="text-xx-small">Created By</header>
                            {search.ownerLogin}
                          </section>
                        </div>
                        <div className="col-sm-12">
                          <section className="section-detail text-small">
                            <header className="text-xx-small">Created On</header>
                            {search.created.format("MMMM DD, YYYY")}
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* CHART */}
            {!this.props.showBellcurve && (
              <RatesByLevelSection store={searchDetailStore} />
            )}
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="panel panel-default panel-details">
                <div className="panel-heading">
                  <h3 className="panel-title">Tags</h3>
                </div>
                <div className="panel-body">
                  <div className="tagbox">{tagSection}</div>
                </div>
              </div>
            </div>
          </div>
        </Container>

        <Container>
          {this.props.showBellcurve && this.props.bellcurve ? (
            this.props.bellcurve
          ) : (
            <LevelCardsSection store={searchDetailStore} />
          )}
        </Container>

        {search.ratesData && search.ratesData.length > 0 && (
          <Container>
            <HistogramChart active={true} results={search.ratesData} />
          </Container>
        )}

        {searchDetailStore.hasOwnership && (
          <Container>
            <ContainerSection className="header">
              <CardHeaderTitle>Punchouts</CardHeaderTitle>
            </ContainerSection>
            <PunchOutsView searchDetailStore={searchDetailStore} />
          </Container>
        )}

        <Modals store={searchDetailStore} />
      </div>
    );
  }
}

function withBellCurve(Component) {
  return function DetailsPage(props) {
    const data = useLazyLoadQuery(SearchDetailsQuery, {
      searchID: props.router.params.searchId,
    });
    const billRates = data.viewer.savedsearch.rates.rawBillRates;
    const showBellcurve = billRates && Array.isArray(billRates) && billRates.length > 0;
    let bellcurve = null;
    if (showBellcurve) {
      bellcurve = (
        <BellCurveBillRates
          savedsearch={data.viewer.savedsearch}
          isContract={data.viewer.savedsearch.rateType === 1}
        />
      );
    }

    return <Component {...props} bellcurve={bellcurve} showBellcurve={showBellcurve} />;
  };
}

export default withBellCurve(observer(Detail));
