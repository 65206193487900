import slugify from "../../../utils/slugify";
import { SALARY_RATE_TYPE_OPTIONS } from "../../../constants/negotiationWorksheet";
import fieldValueGetter from "../utils/fieldValueGetter";
import * as filter from "../utils/filter";
import fieldTypes from "../components/fields";
import WorkbookBuilder from "../../../utils/excelsior";

export default function (
  worksheetSchema,
  worksheetData,
  unsavedValues,
  worksheetName,
  calculatedResults,
  rateType,
  workerType
) {
  const filename = slugify(worksheetName) + ".xlsx";

  const workbook = new WorkbookBuilder(filename);
  workbook
    .addFormat("currency", {
      num_format: `"${worksheetSchema.currencySymbol} "#,##0.00_-`,
    })
    .addFormat("percentage", { num_format: "0.000%" })
    .addFormat("bold", { bold: true, align: "right" })
    .addFormat("bolder", { bold: true, font_size: "12", align: "right" })
    .addFormat("groupLabel", {
      bold: true,
      font_size: "13",
      font_color: "#92a140",
      align: "right",
    });

  const conversionOptions = worksheetSchema.conversionOptions;
  const convert = (value) =>
    SALARY_RATE_TYPE_OPTIONS.convertFromHourly(value, conversionOptions);

  const billRate = convert(calculatedResults.billRateHourly);
  const payRate = convert(calculatedResults.payRateHourly);

  const worksheet = workbook
    .addWorksheet("Worksheet")
    .setColumnWidth(0, 50)
    .setColumnWidth(2, 12)

    .writeRow({ v: "Worksheet Label", f: "bold" }, null, worksheetName)
    .writeEmptyRows()

    .writeRow({ v: "Selected Location", f: "bold" }, null, worksheetSchema.location.title)
    .writeEmptyRows()

    .writeRow(
      { v: "Bill Rate", f: "bolder" },
      null,
      {
        v: billRate,
        f: "currency",
      },
      `/${SALARY_RATE_TYPE_OPTIONS.unitLabel.toLowerCase()}`
    );

  if (calculatedResults.totalTax)
    worksheet.writeRow({ v: "Bill Rate w/ Tax", f: "bolder" }, null, {
      v: convert(calculatedResults.billRateHourlyAfterTax),
      f: "currency",
    });

  worksheet.writeEmptyRows();

  const existingData = worksheetData && worksheetData.values;
  const getFieldValue = fieldValueGetter(unsavedValues, existingData);
  const fieldMap = worksheetSchema.fields;

  worksheetSchema.groups.forEach((group) => {
    const fields = fieldMap
      .filter((field) => group.fields.includes(field.id))
      .filter(filter.all(filter.rateTypeContains("salary")));

    if (fields.length === 0) return;
    if (group.fields.length === 0) return;

    worksheet.writeRow({ v: group.label, f: "groupLabel" });

    fields.forEach((field) => {
      const fieldValue = getFieldValue(field);
      const { valueType } = fieldTypes[field.fieldType];
      const payValue = valueType === "markup" ? payRate : billRate;

      worksheet.writeRow(
        { v: field.label, f: "bold" },
        null,
        { v: fieldValue / 100, f: "percentage" },
        null,
        {
          v: (fieldValue / 100) * payValue,
          f: "currency",
        }
      );
    });

    worksheet.writeEmptyRows();
  });

  worksheet
    .writeRow({ v: "Summary", f: "groupLabel" })
    .writeRow(
      { v: "Total Markup", f: "bold" },
      null,
      { v: calculatedResults.totalMarkup / 100, f: "percentage" },
      null,
      { v: (calculatedResults.totalMarkup / 100) * payRate, f: "currency" }
    )
    .writeEmptyRows();

  if (calculatedResults.totalTax)
    worksheet
      .writeRow(
        { v: "Total Tax", f: "bold" },
        null,
        { v: calculatedResults.totalTax / 100, f: "percentage" },
        null,
        {
          v: (calculatedResults.totalTax / 100) * billRate,
          f: "currency",
        }
      )
      .writeEmptyRows();

  worksheet.writeRow(
    { v: "Pay Rate", f: "bolder" },
    null,
    { v: payRate, f: "currency" },
    `/${SALARY_RATE_TYPE_OPTIONS.unitLabel.toLowerCase()}`
  );

  return workbook.toJSON();
}
