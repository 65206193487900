import { styled } from "../../stitches.config";

const Divider = styled("hr", {
  margin: 0,
  borderStyle: "solid",
  border: "none",
  variants: {
    orientation: {
      horizontal: {
        height: "0",
        width: "$full",
        borderTop: "1px solid $primaryLight",
      },
      vertical: {
        height: "$full",
        width: "1px",
        borderRight: "1px solid $primaryLight",
      },
    },
  },
  defaultVariants: {
    orientation: "horizontal",
  },
});

Divider.displayName = "Divider";

export default Divider;
