import React, { useState, useCallback } from "react";
import { fromJS, Set } from "immutable";

import { emptySet, emptyList } from "../../../constants";
import {
  VisibleColumnsOptions,
  getOrderedOptions,
  VisibleColumnsBlock,
  TableConfigModal,
  useTableConfigState,
  ItemsPerPageBlock,
  TableConfigProps,
} from "../../../components/tables/TableConfig";
import Stack from "../../../components/lib/Stack";

import { ALL_VALUES_SUBTYPES_OPTIONS_LIST } from "./CompareWithMarketTableConfig";
import {
  CONTINGENT_VALUES_TYPES,
  CONTINGENT_VALUES_TYPES_TYPE,
  CONTINGENT_VALUES_TYPES_LABELS,
  VALUES_SUBTYPES,
  SKILLS_LEVELS,
  SKILLS_LEVELS_ROMAN_LABELS,
  VALUES_SUBTYPES_TYPE,
  SKILLS_LEVELS_TYPE,
} from "../types";

import type {
  TableConfigOptionObject,
  TableConfigOptionsList,
  VisibleColumnsSet,
} from "../../../components/tables/types";
import type { ImmutableList, ImmutableMap, ImmutableSet } from "../../../types/immutable";

const ALL_CONTINGENT_VALUES_TYPES_OPTIONS: TableConfigOptionObject<CONTINGENT_VALUES_TYPES_TYPE>[] =
  [
    {
      uniqueKey: CONTINGENT_VALUES_TYPES.PAY_RATE,
      title: CONTINGENT_VALUES_TYPES_LABELS[CONTINGENT_VALUES_TYPES.PAY_RATE],
    },
    {
      uniqueKey: CONTINGENT_VALUES_TYPES.MARKUP,
      title: CONTINGENT_VALUES_TYPES_LABELS[CONTINGENT_VALUES_TYPES.MARKUP],
    },
    {
      uniqueKey: CONTINGENT_VALUES_TYPES.BILL_RATE,
      title: CONTINGENT_VALUES_TYPES_LABELS[CONTINGENT_VALUES_TYPES.BILL_RATE],
    },
  ];

const ALL_CONTINGENT_VALUES_TYPES_OPTIONS_LIST = fromJS(
  ALL_CONTINGENT_VALUES_TYPES_OPTIONS
) as TableConfigOptionsList;

const ALL_SKILLS_LEVELS_OPTIONS: TableConfigOptionObject<SKILLS_LEVELS_TYPE>[] = [
  {
    uniqueKey: SKILLS_LEVELS.JUNIOR,
    title: SKILLS_LEVELS_ROMAN_LABELS[SKILLS_LEVELS.JUNIOR],
  },
  {
    uniqueKey: SKILLS_LEVELS.INTERMEDIATE,
    title: SKILLS_LEVELS_ROMAN_LABELS[SKILLS_LEVELS.INTERMEDIATE],
  },
  {
    uniqueKey: SKILLS_LEVELS.SENIOR,
    title: SKILLS_LEVELS_ROMAN_LABELS[SKILLS_LEVELS.SENIOR],
  },
  {
    uniqueKey: SKILLS_LEVELS.LEAD,
    title: SKILLS_LEVELS_ROMAN_LABELS[SKILLS_LEVELS.LEAD],
  },
  {
    uniqueKey: SKILLS_LEVELS.GURU,
    title: SKILLS_LEVELS_ROMAN_LABELS[SKILLS_LEVELS.GURU],
  },
];

const ALL_SKILLS_LEVELS_OPTIONS_LIST = fromJS(
  ALL_SKILLS_LEVELS_OPTIONS
) as TableConfigOptionsList<SKILLS_LEVELS_TYPE>;

const ALL_CONTINGENT_VALUES_TYPES_SET = Set([
  CONTINGENT_VALUES_TYPES.PAY_RATE,
  CONTINGENT_VALUES_TYPES.MARKUP,
  CONTINGENT_VALUES_TYPES.BILL_RATE,
]) as unknown as SelectedRateTypesSet;

const ALL_VALUES_SUBTYPES_VALUES_SET = Set([
  VALUES_SUBTYPES.MIN,
  VALUES_SUBTYPES.MID,
  VALUES_SUBTYPES.AVG,
  VALUES_SUBTYPES.MAX,
]) as unknown as SelectedRateSubtypesSet;

const ALL_SKILLS_LEVELS_VALUES_SET = Set([
  SKILLS_LEVELS.JUNIOR,
  SKILLS_LEVELS.INTERMEDIATE,
  SKILLS_LEVELS.SENIOR,
  SKILLS_LEVELS.LEAD,
  SKILLS_LEVELS.GURU,
]) as unknown as SelectedExpLevelsSet;

export type MarketRatesTableConfigOptionObject = TableConfigOptionObject & {
  valueLevel?: SKILLS_LEVELS_TYPE;
  valueType?: CONTINGENT_VALUES_TYPES_TYPE;
  valueSubtype?: VALUES_SUBTYPES_TYPE;
};
export type MarketRatesTableConfigOptionMap =
  ImmutableMap<MarketRatesTableConfigOptionObject>;
export type MarketRatesTableConfigOptionsList =
  ImmutableList<MarketRatesTableConfigOptionMap>;

type SelectedExpLevelsSet = ImmutableSet<SKILLS_LEVELS_TYPE>;
type SelectedRateTypesSet = ImmutableSet<CONTINGENT_VALUES_TYPES_TYPE>;
type SelectedRateSubtypesSet = ImmutableSet<VALUES_SUBTYPES_TYPE>;

type SelectedRatesParametersObject = {
  selectedLevels: SelectedExpLevelsSet;
  selectedValueTypes: SelectedRateTypesSet;
  selectedValueSubtypes: SelectedRateSubtypesSet;
};

const getGeneralOptionsAndSelectedRatesParameters = (
  options: MarketRatesTableConfigOptionsList,
  visibleColumns: VisibleColumnsSet
) => {
  let generalOptions = emptyList as MarketRatesTableConfigOptionsList;
  let selectedLevels = emptySet as SelectedExpLevelsSet;
  let selectedValueTypes = emptySet as SelectedRateTypesSet;
  let selectedValueSubtypes = emptySet as SelectedRateSubtypesSet;

  options.forEach((option) => {
    const { uniqueKey, valueLevel, valueType, valueSubtype } = option.toJS();

    if (valueLevel == null && valueType == null && valueSubtype == null) {
      generalOptions = generalOptions.push(option);
    } else {
      if (visibleColumns.hasIn([uniqueKey])) {
        selectedLevels = selectedLevels.add(valueLevel!);
        selectedValueTypes = selectedValueTypes.add(valueType!);
        selectedValueSubtypes = selectedValueSubtypes.add(valueSubtype!);
      }
    }
  });

  const selectedRatesParameters: SelectedRatesParametersObject = {
    selectedLevels,
    selectedValueTypes,
    selectedValueSubtypes,
  };

  return { generalOptions, selectedRatesParameters };
};

const getSelectedRatesColumnsKeys = (
  options: MarketRatesTableConfigOptionsList,
  selectedRatesParameters: SelectedRatesParametersObject
): VisibleColumnsSet => {
  const { selectedLevels, selectedValueTypes, selectedValueSubtypes } =
    selectedRatesParameters;
  let keys = emptySet as VisibleColumnsSet;

  options.forEach((option) => {
    const { uniqueKey, valueLevel, valueType, valueSubtype } = option.toJS();

    if (
      selectedLevels.hasIn([valueLevel]) &&
      selectedValueTypes.hasIn([valueType]) &&
      selectedValueSubtypes.hasIn([valueSubtype])
    ) {
      keys = keys.add(uniqueKey);
    }
  });

  return keys;
};

export interface MarketRatesTableConfigProps extends TableConfigProps {
  options: MarketRatesTableConfigOptionsList;
}

const MarketRatesTableConfig = (props: MarketRatesTableConfigProps) => {
  const {
    visibleColumns: initialVisibleColumns,
    itemsPerPage: initialItemsPerPage,
    show,
    options,
    onHide,
    onChange,
  } = props;

  const { generalOptions, selectedRatesParameters: initialSelectedRatesParameters } =
    React.useMemo(() => {
      return getGeneralOptionsAndSelectedRatesParameters(options, initialVisibleColumns);
    }, [options, initialVisibleColumns]);
  const generalOptionsOrdered = React.useMemo(
    () => getOrderedOptions(generalOptions),
    [generalOptions]
  );

  const generalOptionsKeysSet = React.useMemo(() => {
    return Set(
      generalOptions.map<string>((o) => o.get("uniqueKey"))
    ) as unknown as VisibleColumnsSet;
  }, [generalOptions]);
  const allOptionsKeysSet = React.useMemo(() => {
    return Set(
      options.map<string>((o) => o.get("uniqueKey"))
    ) as unknown as VisibleColumnsSet;
  }, [options]);

  // state

  const {
    visibleColumns,
    itemsPerPage,
    setVisibleColumns,
    setItemsPerPage,
    hasVisibleColumnsChanges,
    hasItemsPerPageChanges,
    hasChanges,
    handleChangeVisibleColumns,
    handleChangeItemsPerPage,
  } = useTableConfigState(initialVisibleColumns, initialItemsPerPage, options);

  const [selectedRatesParameters, setSelectedRatesParameters] =
    useState<SelectedRatesParametersObject>(initialSelectedRatesParameters);

  const handleChangeSelectedRatesParameters = useCallback(
    (parameterType: keyof SelectedRatesParametersObject, keys, isSelected: boolean) => {
      if (!Array.isArray(keys) && !Set.isSet(keys)) keys = [keys];

      // resolve next rates parameters (UI widgets) state
      const selectedRatesParametersItem = isSelected
        ? selectedRatesParameters[parameterType].merge(keys)
        : selectedRatesParameters[parameterType].subtract(keys);

      let nextSelectedRatesParameters: SelectedRatesParametersObject;
      if (selectedRatesParametersItem.size === 0) {
        nextSelectedRatesParameters = {
          selectedLevels: emptySet as SelectedExpLevelsSet,
          selectedValueTypes: emptySet as SelectedRateTypesSet,
          selectedValueSubtypes: emptySet as SelectedRateSubtypesSet,
        } as SelectedRatesParametersObject;
      } else {
        nextSelectedRatesParameters = {
          ...selectedRatesParameters,
          [parameterType]: selectedRatesParametersItem,
        } as SelectedRatesParametersObject;
      }

      // resolve next visible columns state, derive from selected rates parameters
      const nextRatesVisibleColumns = getSelectedRatesColumnsKeys(
        options,
        nextSelectedRatesParameters
      );
      // exclude previous visible rates columns configuration in order to merge in the new one below
      const cleanVisibleColumns = visibleColumns.intersect(generalOptionsKeysSet);
      const nextVisibleColumns =
        nextRatesVisibleColumns.size > 0
          ? cleanVisibleColumns.merge(nextRatesVisibleColumns)
          : cleanVisibleColumns;

      setSelectedRatesParameters(nextSelectedRatesParameters);
      setVisibleColumns(nextVisibleColumns);
    },
    [
      selectedRatesParameters,
      options,
      generalOptionsKeysSet,
      visibleColumns,
      setVisibleColumns,
      setSelectedRatesParameters,
    ]
  );

  const handleSelectAllColumns = useCallback(() => {
    setVisibleColumns(allOptionsKeysSet);
    setSelectedRatesParameters({
      selectedLevels: ALL_SKILLS_LEVELS_VALUES_SET,
      selectedValueTypes: ALL_CONTINGENT_VALUES_TYPES_SET,
      selectedValueSubtypes: ALL_VALUES_SUBTYPES_VALUES_SET,
    });
  }, [allOptionsKeysSet, setVisibleColumns, setSelectedRatesParameters]);

  const handleUnselectAllColumns = useCallback(() => {
    setVisibleColumns(emptySet);
    setSelectedRatesParameters({
      selectedLevels: emptySet as SelectedExpLevelsSet,
      selectedValueTypes: emptySet as SelectedRateTypesSet,
      selectedValueSubtypes: emptySet as SelectedRateSubtypesSet,
    });
  }, [setVisibleColumns]);

  const handleApplyChanges = useCallback(() => {
    onHide();
    onChange(
      hasVisibleColumnsChanges ? visibleColumns : null,
      hasItemsPerPageChanges ? itemsPerPage : null
    );
  }, [
    onChange,
    onHide,
    hasVisibleColumnsChanges,
    hasItemsPerPageChanges,
    itemsPerPage,
    visibleColumns,
  ]);

  const handleCancelChanges = useCallback(() => {
    onHide();
    setVisibleColumns(initialVisibleColumns);
    setSelectedRatesParameters(initialSelectedRatesParameters);
    setItemsPerPage(initialItemsPerPage);
  }, [
    onHide,
    setItemsPerPage,
    setVisibleColumns,
    setSelectedRatesParameters,
    initialItemsPerPage,
    initialVisibleColumns,
    initialSelectedRatesParameters,
  ]);

  return (
    <TableConfigModal
      show={show}
      onApply={handleApplyChanges}
      onHide={handleCancelChanges}
      disabled={!hasChanges}
    >
      <Stack fill>
        <VisibleColumnsBlock
          onSelectAll={handleSelectAllColumns}
          onUnselectAll={handleUnselectAllColumns}
        >
          <Stack fill css={{ alignItems: "stretch" }}>
            <VisibleColumnsOptions
              title="Your Data / Comparison"
              options={generalOptionsOrdered}
              value={visibleColumns}
              onChange={handleChangeVisibleColumns}
              gridCss={{
                gap: "$2",
                gridAutoFlow: "column",
                gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
                gridTemplateRows: "repeat(4, minmax(0, 1fr))",
                "@lg": {
                  gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
                  gridTemplateRows: "repeat(3, minmax(0, 1fr))",
                },
              }}
            />
            <VisibleColumnsOptions
              title="Levels"
              options={ALL_SKILLS_LEVELS_OPTIONS_LIST}
              value={selectedRatesParameters.selectedLevels}
              onChange={(...args) =>
                handleChangeSelectedRatesParameters("selectedLevels", ...args)
              }
              allValues={ALL_SKILLS_LEVELS_VALUES_SET}
              gridCss={{
                gap: "$1",
                gridAutoFlow: "row",
                gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
                "@lg": { gridTemplateColumns: "repeat(7, minmax(0, 1fr))" },
              }}
            />
            <VisibleColumnsOptions
              title="Market Rates"
              options={ALL_CONTINGENT_VALUES_TYPES_OPTIONS_LIST}
              value={selectedRatesParameters.selectedValueTypes}
              onChange={(...args) =>
                handleChangeSelectedRatesParameters("selectedValueTypes", ...args)
              }
              allValues={ALL_CONTINGENT_VALUES_TYPES_SET}
            />
            <VisibleColumnsOptions
              title="Market Values"
              options={ALL_VALUES_SUBTYPES_OPTIONS_LIST}
              value={selectedRatesParameters.selectedValueSubtypes}
              onChange={(...args) =>
                handleChangeSelectedRatesParameters("selectedValueSubtypes", ...args)
              }
              allValues={ALL_VALUES_SUBTYPES_VALUES_SET}
            />
          </Stack>
        </VisibleColumnsBlock>
        <ItemsPerPageBlock
          itemsPerPage={itemsPerPage}
          onChange={handleChangeItemsPerPage}
        />
      </Stack>
    </TableConfigModal>
  );
};

MarketRatesTableConfig.displayName = "MarketRatesTableConfig";

export default MarketRatesTableConfig;
