// @flow

import React, { Component } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JobLibraryRequest from "../../models/JobLibraryRequest";
import FlagIcon from "../../components/FlagIcon";
import { styled } from "../../../src/stitches.config";

const FlagTag = styled("span", {
  margin: "3px !important",
  padding: "5px 6px !important",
  borderRadius: "3px",
  backgroundColor: "$brandLightest",
  color: "$brand",
  fontSize: "12px",
  fontWeight: "700",
  textAlign: "left",
  minWidth: "auto",
});

type RowProps = {
  request?: JobLibraryRequest,
  expandable: boolean,
};

class JobLibraryRequestTableRow extends Component<RowProps> {
  static defaultProps = {
    expandable: true,
    onExpand: null,
  };

  handleListItemClick: () => void;

  constructor(props: RowProps) {
    super(props);

    this.handleListItemClick = this.handleListItemClick.bind(this);
  }

  handleListItemClick() {
    if (this.props.request && this.props.expandable) {
      this.props.request.toggleExpanded();
    }
  }

  renderPlaceHolder = () => {
    return (
      <div className="cjl-flex-table row">
        <div className="cjl-flex-table">
          <div className="cjl-flex-table row list-item">
            <div className="column auto cjl-caret-col">
              <FontAwesomeIcon icon="play" />
            </div>
            <div className="column auto cjl-library-request-status-col">
              <div className="loading-gradient" style={{ height: 27, width: 60 }} />
            </div>
            <div className="column">
              <div className="loading-gradient" style={{ height: 25, width: "100%" }} />
            </div>
            <div className="column auto cjl-has-file-col">
              <div className="loading-gradient" style={{ height: 25, width: 55 }} />
            </div>
            <div className="column auto cjl-countries-count-col">
              <div className="loading-gradient" style={{ height: 25, width: 80 }} />
            </div>
            <div className="column auto cjl-date-col">
              <div className="loading-gradient" style={{ height: 25, width: 80 }} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const props = this.props;
    const request = props.request;
    if (!request) return this.renderPlaceHolder();
    const viewState = request.viewState || { selected: false, expanded: false };

    const classes = classNames("cjl-flex-table row list-item", {
      selected: viewState.selected,
      expanded: viewState.expanded,
      expandable: props.expandable,
    });

    const collapsibleContentClasses = classNames("collapsible-content", {
      collapsed: !viewState.expanded,
      expanded: viewState.expanded,
    });

    const caretClasses = classNames({ expanded: viewState.expanded });

    const statusClasses = classNames("cjl-library-request-status", {
      processing: request.status === "PROCESSING",
      pending: request.status === "PENDING",
      approved: request.status === "APPROVED",
      denied: request.status === "DENIED",
    });

    return (
      <div className="cjl-flex-table row">
        <div className="cjl-flex-table">
          <div className={classes} onClick={this.handleListItemClick}>
            <div className="column auto cjl-caret-col">
              <FontAwesomeIcon icon="play" className={caretClasses} />
            </div>
            <div className="column auto cjl-library-request-status-col">
              <div className={statusClasses}>{request.statusVerbose}</div>
            </div>
            <div className="column">{request.title}</div>
            <div className="column auto cjl-has-file-col">
              {request.attachmentFileName ? "Yes" : "No"}
            </div>
            <div className="column auto cjl-countries-count-col">
              {request.totalCertifiedCountries}
            </div>
            <div className="column auto cjl-date-col">{request.createdDisplay}</div>
          </div>

          <div className={collapsibleContentClasses}>
            <div className="cjl-title-desc pt-job-description">
              <div>
                <label className="cjl-list-item-name">Requested Countries</label>
                {request.certifiedCountries && request.certifiedCountries.length > 0 && (
                  <div className="flex-wrap" style={{ margin: -3 }}>
                    {request.certifiedCountries &&
                      request.certifiedCountries.map((country) => (
                        <FlagTag key={country.id}>
                          {country.iso3166Alpha2 && (
                            <FlagIcon code={country.iso3166Alpha2} />
                          )}{" "}
                          {country.title}
                        </FlagTag>
                      ))}
                  </div>
                )}

                {request.certifiedCountries &&
                  request.certifiedCountries.length === 0 && (
                    <div className="text-wrap">
                      <span className="text-muted">-- No Countries --</span>
                    </div>
                  )}
              </div>
              <br />
              <div>
                <label className="cjl-list-item-name">Attached File Name</label>
                {request.attachmentFileName && (
                  <pre className="text-wrap">{request.attachmentFileName}</pre>
                )}
                {!request.attachmentFileName && (
                  <div className="text-wrap">
                    <span className="text-muted">-- No File Attached --</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(JobLibraryRequestTableRow);
