import React, { useCallback } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";

import Text from "../../components/lib/Text";
import Alert from "../../components/lib/Alert";
import Stack from "../../components/lib/Stack";

// @ts-expect-error
import { logAsyncOperationError } from "../../utils/logging";
import { emptyMap } from "../../constants";

import { NavigationButton } from "../../components/lib/Button";
import { TickerPageLoader } from "../../components/lib/TickerLoader";

import { programToImmutableMap } from "./dataConverters";
import { programGlobalState } from "./globalState";

import type { ProgramDataMap, ProgramDataObject } from "./types";
import type { FetchAPIResponse } from "../../types/fetch";
import type { CommonChildPageProps } from "./PrivateIndex";
import { usePLIContext } from "./context";

export type ProgramContextObject = {
  programData: ProgramDataMap;
  programId: number;
  programTitle: string;
  isPreviewMode: boolean;
  isSample: boolean;
};

export const ProgramContext = React.createContext<ProgramContextObject>({
  programData: undefined as unknown as ProgramDataMap,
  programId: undefined as unknown as number,
  programTitle: undefined as unknown as string,
  isPreviewMode: undefined as unknown as boolean,
  isSample: undefined as unknown as boolean,
});

export function useProgramContext() {
  return React.useContext(ProgramContext);
}

interface ProgramDataProviderProps extends CommonChildPageProps {
  children: React.ReactElement;
}

const ProgramDataProvider = (props: ProgramDataProviderProps) => {
  const { router, params, children } = props;
  const { fetchM8API, showModalError } = usePLIContext();

  // global program state
  const [{ programData, programDataLoaded }, setProgramGlobalState] =
    useRecoilState(programGlobalState);
  const resetProgramGlobalState = useResetRecoilState(programGlobalState);

  const programId = parseInt(params.programId, 10);
  const programTitle = !!programData ? programData.get("title") : undefined;

  // data fetch func
  const fetchProgramData = useCallback(async () => {
    try {
      const response: FetchAPIResponse<ProgramDataObject> = await fetchM8API(
        `programs/${programId}/`
      );
      const data: ProgramDataMap = programToImmutableMap(response.data);

      setProgramGlobalState({
        programData: data,
        programDataLoaded: true,
      });

      return data;
    } catch (err: any) {
      if (err?.response?.status === 404) {
        setProgramGlobalState({
          programData: emptyMap as ProgramDataMap,
          programDataLoaded: true,
        });
      } else {
        logAsyncOperationError("fetchProgramData", err);
        showModalError(`Error occurred while loading index #${programId} data.`);
      }
    }
  }, [programId, setProgramGlobalState, fetchM8API, showModalError]);

  // initial data fetch and final cleanup
  React.useEffect(() => {
    fetchProgramData();
    return resetProgramGlobalState;
  }, [fetchProgramData, resetProgramGlobalState]);

  // context
  const pliContextValues = usePLIContext();

  // handlers
  const handleGoBackToProgramsListPage = useCallback(() => {
    router.push("/private-index/programs");
  }, [router]);

  if (!programDataLoaded) {
    return <TickerPageLoader />;
  }
  if (programData == null || programData?.get("id") !== programId) {
    return (
      <Alert color="warning">
        <Stack css={{ alignItems: "start" }}>
          <Text as="h4">No index data found</Text>
          <NavigationButton
            icon="arrow-left"
            color="warning"
            onClick={handleGoBackToProgramsListPage}
          >
            Back to Indexes List
          </NavigationButton>
        </Stack>
      </Alert>
    );
  }

  const currentClientId = pliContextValues.clientId;
  const programClientId = programData!.get("client_id");
  const isPreviewMode =
    currentClientId != null &&
    programClientId != null &&
    currentClientId !== programClientId;
  const isSample = programData!.get("is_sample");
  const programContextValues = {
    programData: programData!,
    programId: programId!,
    programTitle: programTitle!,
    isPreviewMode: isPreviewMode!,
    isSample: isSample!,
  };

  return (
    <ProgramContext.Provider value={programContextValues}>
      {React.cloneElement(React.Children.only(children), {
        ...pliContextValues,
        ...programContextValues,
      })}
    </ProgramContext.Provider>
  );
};

ProgramDataProvider.displayName = "ProgramDataProvider";

export interface CommonProgramChildPageProps extends CommonChildPageProps {
  programId: number;
  programTitle: string;
  programData: ProgramDataMap;
  isPreviewMode: boolean;
  isSample: boolean;
}

export default ProgramDataProvider;
