// @flow

import { extendObservable, action, runInAction, Observable } from "mobx";
import JobCategory from "../../models/JobCategory";
import MessageState from "../../models/MessageState";
import NetworkState from "../../models/NetworkState";
import UnattachedSearchStore from "./UnattachedSearchStore";
import DraftsSavedSearchListStore from "./DraftsSavedSearchListStore";
import { consolidateAppliedFilters } from "./SupportFunctions";
import type { FetchGraphQL, FetchAPI } from "../../App";

export default class RateCardCreateStore {
  router: ?Object;
  rateCardLabel: string;
  messaging: MessageState;
  networkJobCategories: NetworkState;
  jobCategoryId: ?number;
  jobCategoryName: string;
  jobCategories: JobCategory[];
  searchStore: UnattachedSearchStore;
  draftsSearchStore: DraftsSavedSearchListStore;

  getCategories: () => void;
  createRateCard: () => void;
  changeRateCardLabelText: (Object) => void;
  fetchGraphQL: FetchGraphQL;

  constructor(
    fetchGraphQL: FetchGraphQL,
    fetchAPI: FetchAPI,
    legacySessionId: Observable<string | null>
  ) {
    // NOTE: Bound early to pass into pagination state
    this.getCategories = action(this.getCategories.bind(this));

    this.fetchGraphQL = fetchGraphQL;

    extendObservable(this, {
      rateCardLabel: "",
      jobCategoryId: null,
      jobCategoryName: "",
      searchStore: new UnattachedSearchStore(fetchGraphQL),
      draftsSearchStore: new DraftsSavedSearchListStore(fetchGraphQL, fetchAPI),
      messaging: new MessageState(),
      networkJobCategories: new NetworkState(),
      jobCategories: [],
    });
    this.router = null;

    this.createRateCard = action(this.createRateCard.bind(this));
    this.changeRateCardLabelText = action(this.changeRateCardLabelText.bind(this));
  }

  changeRateCardLabelText(e: Object) {
    this.rateCardLabel = e.target.value;
  }

  async createRateCard() {
    if (this.searchStore.network.loading) {
      // NOTE: Debounces createRateCard
      return;
    }

    if (this.rateCardLabel === "") {
      this.messaging.createMessage("info", "Please enter a label for your ratecard.");
      return;
    }

    // NOTE: Job Category is not needed when creating a ratecard from unattached searches.
    // if (this.jobCategoryId == null) {
    //   this.messaging.createMessage('info', 'Please select a job category for your ratecard.');
    //   return;
    // }

    let params = [];
    let args = [];
    let vars = {};
    if (this.searchStore.allSelected) {
      params.push("$exclude: [ID]!");
      args.push("exclude: $exclude");
      vars.exclude = []; // no exclusions means all will be processed if there are no other filters

      // include current filters
      consolidateAppliedFilters(this.searchStore.appliedFilters, params, args, vars);
    } else {
      let selectedSearches = this.searchStore.getSelectedSearches();
      if (!selectedSearches) selectedSearches = [];

      params.push("$only: [ID]!");
      args.push("only: $only");
      vars.only = selectedSearches;
    }

    const query = `
      mutation createRateCard($name: String!) {
        createRateCards(input: {name: $name}){
          rateCard{
            ratecardId,
            owner{
              firstName,
              lastName,
              email,
              termsOfAgreement
            },
            name,
            createdDate,
            updateDate
          }
        }
      }
    `;

    const variables = Object.assign({ name: this.rateCardLabel }, vars);

    this.searchStore.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.searchStore.network.handleError("Creating Rate Card", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.searchStore.network.loading = false;
      return;
    }

    this.searchStore.network.loading = false;

    runInAction("createRateCard--success", () => {
      this.searchStore.network.loading = false;
      this.searchStore.network.error = null;
      if (this.searchStore.network.logGraphQLError("createRateCard", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.rateCardLabel = "";
      if (this.router) {
        this.router.push({
          pathname: `/ratecards/`,
          query: this.router.query,
        });
      }
    });
  }

  async getCategories(): Promise<void> {
    const query = `
      query getCategories {
        viewer {
          jobCategories {
            totalCount
            pageInfo {
              startCursor
              endCursor
            }
            edges {
              node {
                id
                legacyId
                name
                createdDate
              }
            }
          }
        }
      }
    `;

    this.networkJobCategories.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, {});
    } catch (e) {
      this.networkJobCategories.handleError("Getting Job Categories", e);
      // TODO: Display user friendly error message
      return;
    }

    runInAction("getCategories--success", () => {
      this.networkJobCategories.loading = false;
      this.networkJobCategories.error = null;
      if (this.networkJobCategories.logGraphQLError("Get Job Categories query", res)) {
        // TODO: Display user friendly error message
        return;
      }

      // $FlowFixMe: Type the res value once we abstract gql calls.
      const jobCategories = res.data.viewer.jobCategories.edges;
      this.jobCategories = jobCategories.map((edge) => {
        return new JobCategory(edge.node.legacyId, edge.node.name);
      });
    });
  }
}
