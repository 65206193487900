// @flow

import { extendObservable, action } from "mobx";

import Message from "./Message";
import type { MessageType } from "./Message";

export default class MessageState {
  messages: Message[];

  createMessage: (type: MessageType, message: string) => void;
  removeMessage: (message: Message) => void;
  removeAll: () => void;

  constructor() {
    extendObservable(this, {
      messages: [],
    });

    this.createMessage = action(this.createMessage.bind(this));
    this.removeMessage = action(this.removeMessage.bind(this));
    this.removeAll = action(this.removeAll.bind(this));
  }

  createMessage(type: MessageType, message: string) {
    this.messages.push(new Message(type, message));
  }

  removeMessage(message: Message) {
    // $FlowFixMe: Fix this when MobX 3.0 is released with flow types for ObservableArray.
    this.messages.remove(message);
  }

  removeAll() {
    this.messages = [];
  }
}
