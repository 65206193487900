/**
 * @generated SignedSource<<01fe1c27577d70a670c86ee0642fb89a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type JobTitleFieldQuery$variables = {
  countryId?: string | null;
  libraryId?: string | null;
  searchParam?: string | null;
};
export type JobTitleFieldQuery$data = {
  readonly viewer: {
    readonly jobTitles: {
      readonly results: ReadonlyArray<{
        readonly clientJobLibraryInfo: {
          readonly adhocCountries: ReadonlyArray<number | null> | null;
          readonly certifiedCountries: ReadonlyArray<number | null> | null;
        } | null;
        readonly collection: number | null;
        readonly id: string;
        readonly isHealthcare: boolean | null;
        readonly isJobLabel: boolean | null;
        readonly shareInfo: {
          readonly isMine: boolean | null;
          readonly sharedBy: {
            readonly firstName: string | null;
            readonly lastName: string | null;
          } | null;
        } | null;
        readonly title: string;
      } | null> | null;
    } | null;
  } | null;
};
export type JobTitleFieldQuery = {
  response: JobTitleFieldQuery$data;
  variables: JobTitleFieldQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "countryId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "libraryId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchParam"
},
v3 = [
  {
    "kind": "Variable",
    "name": "countryId",
    "variableName": "countryId"
  },
  {
    "kind": "Variable",
    "name": "libraryId",
    "variableName": "libraryId"
  },
  {
    "kind": "Literal",
    "name": "page",
    "value": 1
  },
  {
    "kind": "Literal",
    "name": "pageSize",
    "value": 25
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "searchParam"
  }
],
v4 = {
  "alias": "isHealthcare",
  "args": null,
  "kind": "ScalarField",
  "name": "showQuantiles",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isJobLabel",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "collection",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "ShareInfoType",
  "kind": "LinkedField",
  "name": "shareInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isMine",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SharedByType",
      "kind": "LinkedField",
      "name": "sharedBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "ClientJobLibraryTasteInfoNode",
  "kind": "LinkedField",
  "name": "clientJobLibraryInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adhocCountries",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "certifiedCountries",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "JobTitleFieldQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "JobTitleListNode",
            "kind": "LinkedField",
            "name": "jobTitles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobTitleNode",
                "kind": "LinkedField",
                "name": "results",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "RequiredField",
                    "field": (v5/*: any*/),
                    "action": "NONE",
                    "path": "viewer.jobTitles.results.id"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v6/*: any*/),
                    "action": "NONE",
                    "path": "viewer.jobTitles.results.title"
                  },
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "JobTitleFieldQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "JobTitleListNode",
            "kind": "LinkedField",
            "name": "jobTitles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobTitleNode",
                "kind": "LinkedField",
                "name": "results",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "25c4918eebee2f30d8bd7bbd08957917",
    "id": null,
    "metadata": {},
    "name": "JobTitleFieldQuery",
    "operationKind": "query",
    "text": "query JobTitleFieldQuery(\n  $searchParam: String\n  $libraryId: ID\n  $countryId: ID\n) {\n  viewer {\n    jobTitles(search: $searchParam, page: 1, pageSize: 25, libraryId: $libraryId, countryId: $countryId) {\n      results {\n        isHealthcare: showQuantiles\n        id\n        title\n        isJobLabel\n        collection\n        shareInfo {\n          isMine\n          sharedBy {\n            firstName\n            lastName\n          }\n        }\n        clientJobLibraryInfo {\n          adhocCountries\n          certifiedCountries\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "59a3aec10fd650b916e8b0a0324e2a00";

export default node;
