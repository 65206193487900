import React, { Suspense, useEffect, useMemo, useState } from "react";
import { Set } from "immutable";
// relay
import graphql from "babel-plugin-relay/macro";
// LIB
import Inline from "../../../../components/lib/Inline";
import Stack from "../../../../components/lib/Stack";
import Text from "../../../../components/lib/Text";
// relay
import { useRefetchableFragment } from "react-relay";
import { PayDifference_viewer$key } from "./__generated__/PayDifference_viewer.graphql";
import { PayDifferenceRefetchQuery } from "./__generated__/PayDifferenceRefetchQuery.graphql";
// utils
import { usePayDifference } from "../../hooks/usePayDifference";
// components
import { PayDifferenceMap } from "./PayDifferenceMap";
import { PayDifferenceList, PayDifferenceListLoader } from "./PayDifferenceList";
import { isNonNullable } from "../../../../utils/hashmap";
import { Placeholder } from "../../../../components/lib/Placeholder";
import { payDifferenceObserver } from "../../observers";
import { DifferecenByLocationTable } from "./DifferecenByLocationTable";
import { ComparisonTable } from "../Healthcare/ComparisonTable";

const wrapperCss = { alignItems: "flex-start", justifyContent: "flex-start" };

const PayDifference_viewer = graphql`
  fragment PayDifference_viewer on Viewer
  @refetchable(queryName: "PayDifferenceRefetchQuery")
  @argumentDefinitions(searchIDs: { type: "[ID]", defaultValue: [] }) {
    ...ModifyLocationsDialog_viewer
    ...PayDifferenceMap_viewer
    countries {
      id @required(action: NONE)
      locationId @required(action: NONE)
      isoCode @required(action: NONE)
      name @required(action: NONE)
    }
    savedsearches(filters: { only: $searchIDs }) {
      edges {
        node {
          searchId @required(action: NONE)
          locationId
          locationFullLabel @required(action: NONE)
          country @required(action: NONE)
          locationLabel
          currency {
            id
            iso @required(action: NONE)
          }
          region {
            name @required(action: NONE)
            regionId @required(action: NONE)
          }
          rates {
            rawMin @required(action: NONE)
            rawAvg @required(action: NONE)
            rawMid @required(action: NONE)
            rawMax @required(action: NONE)
          }
          marketrates {
            id @required(action: NONE)
            level @required(action: NONE) {
              legacyId
              value @required(action: NONE)
            }
            # pay rate
            payRateMin @required(action: NONE)
            payRateMid @required(action: NONE)
            payRateMax @required(action: NONE)
            payRateAvg @required(action: NONE)
            # bill rate
            billRateMin @required(action: NONE)
            billRateMid @required(action: NONE)
            billRateMax @required(action: NONE)
            billRateAvg @required(action: NONE)
            # markup %
            markupPctMin @required(action: NONE)
            markupPctMid @required(action: NONE)
            markupPctMax @required(action: NONE)
            markupPctAvg @required(action: NONE)
          }
        }
      }
    }
  }
`;

type Props = {
  setSearchDetails(): void;
  searchIDs: Set<number>;
  viewer: PayDifference_viewer$key;
  isContract: boolean;
  isHealthcare: boolean;
};

export function PayDifference(props: Props) {
  const { viewer, setSearchDetails, searchIDs, isContract, isHealthcare } = props;
  const [activeLocationId, setActiveLocationId] = useState(0);

  const [data, refetch] = useRefetchableFragment<
    PayDifferenceRefetchQuery,
    PayDifference_viewer$key
  >(PayDifference_viewer, viewer);

  const searches = usePayDifference(data);
  const countries = useMemo(() => data.countries?.filter(isNonNullable) || [], [data]);

  /**
   * TODO: Fix refetch in useEffect.
   */
  useEffect(() => {
    refetch({ searchIDs: searchIDs.toArray().map(String) });
  }, [refetch, searchIDs]);

  useEffect(() => {
    function listener(newIDs?: Set<number>) {
      if (newIDs) {
        refetch({ searchIDs: searchIDs.concat(newIDs).toArray().map(String) });
      }
    }
    payDifferenceObserver.subscribe(listener);
    return () => payDifferenceObserver.unsubscribe(listener);
  }, [refetch, searchIDs]);

  return (
    <>
      <Inline fill>
        <Suspense fallback={<PayDifferenceLoader />}>
          <Inline fill css={{ gap: "$4", ...wrapperCss }}>
            <Stack
              css={{
                flex: 1,
                maxWidth: 385,
                ...wrapperCss,
              }}
            >
              <Stack nogap css={wrapperCss}>
                <Text as="h4" noMargin>
                  % Pay Difference by Location
                </Text>
                <Text color="primary">
                  Based on median rate across all experience levels.
                </Text>
                <Text color="primary">Regions are not displayed.</Text>
              </Stack>

              <PayDifferenceList
                viewer={data}
                searches={searches}
                setSearchDetails={setSearchDetails}
                activeLocationId={activeLocationId}
                setActiveLocationId={setActiveLocationId}
              />
            </Stack>
            <Stack css={{ flex: 1, overflow: "hidden" }}>
              <PayDifferenceMap
                viewer={data}
                countries={countries}
                searches={searches}
                activeLocationId={activeLocationId}
                setActiveLocationId={setActiveLocationId}
              />
            </Stack>
          </Inline>
        </Suspense>
      </Inline>
      {isHealthcare ? (
        <ComparisonTable payDifference={searches} />
      ) : (
        <DifferecenByLocationTable isContract={isContract} payDifference={searches} />
      )}
    </>
  );
}

export function PayDifferenceLoader() {
  return (
    <Inline fill css={{ gap: "$4", ...wrapperCss }}>
      <Stack
        css={{
          flex: 1,
          maxWidth: 385,
          ...wrapperCss,
        }}
      >
        <Stack nogap css={wrapperCss}>
          <Text as="h4" noMargin>
            % Pay Difference by Location
          </Text>
          <Text color="primary">Based on median rate across all experience levels.</Text>
          <Text color="primary">Regions are not displayed.</Text>
        </Stack>

        <PayDifferenceListLoader />
      </Stack>

      <Stack css={{ flex: 1, overflow: "hidden", padding: "$10" }}>
        <Placeholder width="100$" height={350} />
      </Stack>
    </Inline>
  );
}
