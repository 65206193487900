import * as React from "react";
import { styled } from "../../stitches.config";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import Inline from "./Inline";
import { overlayShow, scaleIn } from "./Animations";

interface DialogProps extends DialogPrimitive.DialogProps {
  children: React.ReactNode;
}

export function Dialog(props: DialogProps): JSX.Element {
  return <DialogPrimitive.Root {...props}>{props.children}</DialogPrimitive.Root>;
}

export const DialogOverlay = styled(DialogPrimitive.Overlay, {
  backgroundColor: "rgba(0, 0, 0, .5)",
  position: "fixed",
  inset: 0,
  overflowY: "auto",
  zIndex: 99999999999,
  animation: `${overlayShow} 200ms`,
});
DialogOverlay.displayName = "DailogOverlay";

const InnerDialogContent = styled(DialogPrimitive.Content, {
  width: "fit-content",
  minWidth: "200px",
  maxWidth: "90%",
  "@md": {
    minWidth: "300px",
    maxWidth: "500px",
  },
  "@lg": {
    minWidth: "400px",
    maxWidth: "700px",
  },
  overflowY: "auto",
  margin: "100px auto",
  borderRadius: 4,
  backgroundColor: "white",
  boxShadow: "0 1px 1px 1px rgb(0 0 0 / 10%)",
  animation: `${scaleIn} 200ms`,
});

InnerDialogContent.displayName = "InnerDialogContent";

export const DialogContent = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithRef<typeof InnerDialogContent>
>((props, ref) => (
  <DialogPortal>
    <DialogOverlay>
      <InnerDialogContent ref={ref} {...props}>
        {props.children}
      </InnerDialogContent>
    </DialogOverlay>
  </DialogPortal>
));
DialogContent.displayName = "DialogContent";

export const InnerDialogTitle = styled(DialogPrimitive.DialogTitle, {
  margin: 0,
  borderBottom: "1px solid $primaryLight",
  padding: "$5",
  color: "$text",
});

InnerDialogTitle.displayName = "DialogTitle";

export const DialogTitle = (
  props: React.ComponentPropsWithoutRef<typeof InnerDialogTitle>
) => <InnerDialogTitle {...props} />;

DialogTitle.displayName = "DialogTitle";

export const DialogDescription = styled(DialogPrimitive.Description, {
  padding: "$5",
  color: "$text",
  backgroundColor: "$primaryLighter", //"#f3f3f3"
});
DialogDescription.displayName = "DialogDescription";

export const DialogActions = styled(Inline, {
  backgroundColor: "$primaryLighter",
  padding: "$5",
  defaultVariants: {
    fill: true,
  },
});

export const DialogTrigger = DialogPrimitive.Trigger;
DialogTrigger.displayName = "DialogTrigger";

export const DialogClose = DialogPrimitive.Close;
DialogClose.displayName = "DialogClose";

export const DialogPortal = DialogPrimitive.Portal;
DialogPortal.displayName = "DialogPortal";
