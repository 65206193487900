// @flow

import React from "react";

import PropTypes from "prop-types";

import R from "ramda";
import classNames from "classnames";
import { observer } from "mobx-react";
import numeral from "numeral";
import ResultLevelCardSectionSparkLineChart from "../components/ResultLevelCardSectionSparkLineChart";
import ResultLevelCardPayRateDetailsTable from "../components/ResultLevelCardPayRateDetailsTable";
import { RATE_TYPES } from "../../../constants/rateTypes";
import { LEVEL_COLOR, VALUE_CHANGE_COLOR } from "../../../constants/css";
import { shouldShowCurrency, getCurrency } from "../components/SupportFunctions";
import { CONTINGENT_RATE_TYPE } from "../../ratecards/components/RateDisplayToggle";

class ResultLevelCardPayRateSection extends React.Component {
  static defaultProps = {
    hideSparkLine: false,
    hideChangeIcons: false,
  };

  _handleShowRateTrend = () => {
    const store = this.props.store;
    store.onShowRatesTrend(
      this.props.resultLevel.levelName,
      store.selectedSampleSize.value
    );
  };

  render() {
    const props = this.props;
    let store = props.store;
    let resultLevel = props.resultLevel;
    let months = props.months;

    let levelColor = LEVEL_COLOR[resultLevel.levelName];
    let positiveColor = VALUE_CHANGE_COLOR.positive;
    let negativeColor = VALUE_CHANGE_COLOR.negative;

    let currencySymbol = "";
    let showCurrency = shouldShowCurrency(resultLevel);
    if (showCurrency) currencySymbol = props.currency || getCurrency(store.search);

    let payRateSectionTitle = "Pay Rate";
    let rateType = props.rateType || store.search.frequency.title.toLowerCase();
    if (rateType === RATE_TYPES.ANNUAL) {
      payRateSectionTitle = "Annual Salary";
    }

    const rateMultiplier = props.rateMultiplier || 1;
    const contingentRateFrequency = props.contingentRateFrequency;

    let contingent_label = "per hour";

    if (contingentRateFrequency === CONTINGENT_RATE_TYPE.DAILY) {
      contingent_label = "per day";
    } else if (contingentRateFrequency === CONTINGENT_RATE_TYPE.WEEKLY) {
      contingent_label = "per week";
    } else if (contingentRateFrequency === CONTINGENT_RATE_TYPE.MONTHLY) {
      contingent_label = "per month";
    }

    return (
      <div className={classNames("row", { hidden: store.hidePayRate })}>
        <div className="col-xs-12">
          <table className="table level-data">
            <thead>
              <tr>
                <th>Max {payRateSectionTitle}</th>
                <th
                  colSpan="3"
                  className="text-right"
                  onClick={this._handleShowRateTrend}
                  style={{ cursor: "pointer" }}
                >
                  {!props.hideSparkLine && (
                    <ResultLevelCardSectionSparkLineChart
                      store={store}
                      months={months}
                      ratesHistoryData={props.ratesHistoryData}
                      ratesMissingData={props.ratesMissingData}
                      changesHistoryData={props.changesHistoryData}
                      changesMissingData={props.changesMissingData}
                      currencySymbol={currencySymbol}
                      seriesColor={levelColor}
                      positiveColor={positiveColor}
                      negativeColor={negativeColor}
                      rateMultiplier={rateMultiplier}
                    />
                  )}
                </th>
              </tr>
            </thead>
            <tbody className="text-muted">
              <tr>
                <td className="max-value">
                  <div className="max-value-wrapper">
                    <header>
                      <h4>
                        {currencySymbol}{" "}
                        {numeral(resultLevel.payRateMax * rateMultiplier).format(
                          "0,0.00"
                        )}
                      </h4>
                      <h5 className="unit">Max {payRateSectionTitle}</h5>
                    </header>
                    {rateType === RATE_TYPES.HOURLY && (
                      <div className="caption">{contingent_label}</div>
                    )}
                    {rateType === RATE_TYPES.ANNUAL && (
                      <div className="caption">per year</div>
                    )}
                  </div>
                </td>
                <td colSpan="3" className="no-padding">
                  <ResultLevelCardPayRateDetailsTable
                    store={store}
                    search={store.search}
                    resultLevel={resultLevel}
                    currency={currencySymbol}
                    hideChangeIcons={
                      !R.isNil(props.hideChangeIcons)
                        ? props.hideChangeIcons
                        : !store.search.needsRatesUpdate
                    }
                    rateMultiplier={rateMultiplier}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

ResultLevelCardPayRateSection.propTypes = {
  store: PropTypes.object.isRequired,
  resultLevel: PropTypes.object.isRequired,
  rateType: PropTypes.oneOf(Object.values(RATE_TYPES)),
  currency: PropTypes.string,
  months: PropTypes.array.isRequired,
  ratesHistoryData: PropTypes.array.isRequired,
  ratesMissingData: PropTypes.array.isRequired,
  changesHistoryData: PropTypes.array.isRequired,
  changesMissingData: PropTypes.array.isRequired,
  hideSparkLine: PropTypes.bool,
  hideChangeIcons: PropTypes.bool,
  rateMultiplier: PropTypes.number,
  contingentRateFrequency: PropTypes.string,
};

export default observer(ResultLevelCardPayRateSection);
