import { useCallback, useEffect, useState } from "react";

import { questionsListToImmutableOrderedMap } from "../../dataConverters";
// @ts-expect-error
import { logAsyncOperationError } from "../../../../utils/logging";
import { emptyOrderedMap } from "../../../../constants";

import type { FetchAPIResponse } from "../../../../types/fetch";
import type { QuestionDataObject, QuestionsOrderedMap } from "../../types";
import { useVal5KAdminContext } from "../../context/Val5KAdminContext";

export default function useQuestions() {
  const { fetchArgusAPI, showModalError } = useVal5KAdminContext();

  const [questions, setQuestions] = useState<QuestionsOrderedMap>(
    emptyOrderedMap as unknown as QuestionsOrderedMap
  );

  const fetchPredefinedQuestionsList = useCallback(async () => {
    try {
      const response: FetchAPIResponse<QuestionDataObject[]> = await fetchArgusAPI(
        "predefined-questions/"
      );
      const data: QuestionsOrderedMap = questionsListToImmutableOrderedMap(response.data);

      if (data?.size) {
        setQuestions(data);
      }
    } catch (err: any) {
      logAsyncOperationError("fetchPredefinedQuestionsList", err);
      showModalError("Error occurred while retrieving predefined questions list.");
    }
  }, [fetchArgusAPI, showModalError]);

  useEffect(() => {
    if (questions.size === 0) {
      fetchPredefinedQuestionsList();
    }
  }, [fetchPredefinedQuestionsList, questions.size]);

  return questions;
}
