// @flow
import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingIndicator from "../../shared/LoadingIndicator";
import numeral from "numeral";
import ToggleButton from "../../ratecards/components/ToggleButton";
import { observer } from "mobx-react";
import {
  shouldShowCurrency,
  getCurrency,
} from "../../searches/components/SupportFunctions";
import PlainHighStockChart from "../../ratecards/components/PlainHighStockChart";

const RateCardTrackItem = observer((props) => {
  const resultLevel = props.resultLevel;
  const featuredSearch = props.featuredSearch;

  let min = 0.0;
  let mid = 0.0;
  let max = 0.0;
  let avg = 0.0;
  let numResult = 0.0;
  let deltaObject = resultLevel.change;
  let change = "";
  let changeIcon = "";
  let currencySymbol = "";
  let showCurrency = shouldShowCurrency(featuredSearch);
  if (showCurrency) currencySymbol = getCurrency(featuredSearch);
  let suffix = "";
  let changeClassColor = "";
  let addPlusSign = "";

  if (featuredSearch.showPayRate) {
    min = resultLevel.payRateMin;
    mid = resultLevel.payRateMid;
    max = resultLevel.payRateMax;
    avg = resultLevel.payRateAvg;
    change = deltaObject.payRateAvgChange;
    changeIcon = deltaObject.payRateAvgChangeIcon;
    numResult = resultLevel.numResults;
  } else if (featuredSearch.showMarkup) {
    min = resultLevel.markupPctMin;
    mid = resultLevel.markupPctMid;
    max = resultLevel.markupPctMax;
    avg = resultLevel.markupPctAvg;
    change = deltaObject.markupPctAvgChange;
    changeIcon = deltaObject.markupPctAvgChangeIcon;
    currencySymbol = "";
    suffix = "%";
    numResult = resultLevel.numResults;
  } else if (featuredSearch.showBillRate) {
    min = resultLevel.billRateMin;
    mid = resultLevel.billRateMid;
    max = resultLevel.billRateMax;
    avg = resultLevel.billRateAvg;
    change = deltaObject.billRateAvgChange;
    changeIcon = deltaObject.billRateAvgChangeIcon;
    numResult = resultLevel.numResults;
  }

  // if (change !== 0) {
  //   changeClassName = `fa ${changeIcon}`;
  // }

  if (change > 0) {
    changeClassColor = "good";
    changeIcon = "play";
    addPlusSign = "+";
  } else if (change < 0) {
    changeClassColor = "bad";
    changeIcon = "play";
  }

  let levelDisplay = resultLevel.level.value;
  if (levelDisplay === "Junior") {
    levelDisplay = "I";
  }
  if (levelDisplay === "Mid") {
    levelDisplay = "II";
  }
  if (levelDisplay === "Senior") {
    levelDisplay = "III";
  }
  if (levelDisplay === "Lead") {
    levelDisplay = "IV";
  }
  if (levelDisplay === "Guru") {
    levelDisplay = "V";
  }

  return (
    <tr>
      <td style={{ textAlign: "center !important" }}>
        <span>{levelDisplay}</span>
      </td>
      <td className="nw">
        <span>{numeral(numResult).format("0,0")}</span>
      </td>
      <td className="nw">
        <span>
          {currencySymbol} {numeral(min).format("0,0.00")} {suffix}
        </span>
      </td>
      <td className="nw">
        <span>
          {currencySymbol} {numeral(mid).format("0,0.00")} {suffix}
        </span>
      </td>
      <td className="nw">
        <span>
          {currencySymbol} {numeral(max).format("0,0.00")} {suffix}
        </span>
      </td>
      <td className="nw">
        <span>
          {currencySymbol} {numeral(avg).format("0,0.00")} {suffix}
        </span>
      </td>
      <td className="nw">
        <span className={changeClassColor}>
          <FontAwesomeIcon
            icon={changeIcon}
            rotation={change === 0 || change === "0.00" ? null : change > 0 ? 270 : 90}
          />{" "}
          {addPlusSign}
          {numeral(change).format("0,0.00")}
        </span>
      </td>
    </tr>
  );
});

const RateCardTrack = ({ featuredSearch, store, changes }) => {
  const network = store.featuredSearchNetwork;
  // let location = [];
  let loc = "";
  let jobTitle = "";
  let changesTable = "";
  let tabs = "";

  if (featuredSearch) {
    const { Junior, Mid, Senior, Lead, Guru } = featuredSearch.resultsByLevel;
    // if (featuredSearch.location.city) {
    //   location.push(featuredSearch.location.city);
    // }
    // if (featuredSearch.location.state) {
    //   location.push(featuredSearch.location.state);
    // }
    // if (featuredSearch.location.country) {
    //   location.push(featuredSearch.location.country);
    // }
    loc = featuredSearch.locationFullLabel;
    jobTitle = featuredSearch.title;

    if (network.loading) {
      changesTable = <LoadingIndicator />;
    } else if (network.error) {
      changesTable = (
        <div className="pt-error-message">
          <h2>Error</h2>
          <pre>
            <code>{JSON.stringify(network.error, null, 2)}</code>
          </pre>
        </div>
      );
    } else {
      const rateType = featuredSearch.frequency;
      const showSectionOptions = rateType === 1;

      let payRateAvgData = {
        Junior: [],
        Mid: [],
        Senior: [],
        Lead: [],
        Guru: [],
      };

      let currency = null;
      changes.forEach((change) => {
        if (
          currency === null &&
          change.search &&
          change.search.currency &&
          change.search.currency.symbol
        ) {
          currency = change.search.currency.symbol;
        }

        const date = new Date(change.created);

        payRateAvgData[change.level.value].push([
          Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
          change.payRateAvg,
        ]);
      });

      // If still null, fall back to $
      if (currency === null) {
        currency = "$";
      }

      const sort = (a, b) => a[0] - b[0];
      payRateAvgData.Junior.sort(sort);
      payRateAvgData.Mid.sort(sort);
      payRateAvgData.Senior.sort(sort);
      payRateAvgData.Lead.sort(sort);
      payRateAvgData.Guru.sort(sort);

      let dataSets = [];

      // Only adding a level if it has results allows line to show while "shared" still = true
      if (payRateAvgData.Junior.length > 0) {
        dataSets.push({
          name: "Level I",
          data: payRateAvgData.Junior,
          color: "#4a802f",
        });
      }
      if (payRateAvgData.Mid.length > 0) {
        dataSets.push({
          name: "Level II",
          data: payRateAvgData.Mid,
          color: "#346db1",
        });
      }
      if (payRateAvgData.Senior.length > 0) {
        dataSets.push({
          name: "Level III",
          data: payRateAvgData.Senior,
          color: "#72af3c",
        });
      }
      if (payRateAvgData.Lead.length > 0) {
        dataSets.push({
          name: "Level IV",
          data: payRateAvgData.Lead,
          color: "#4a9ae8",
        });
      }
      if (payRateAvgData.Guru.length > 0) {
        dataSets.push({
          name: "Level V",
          data: payRateAvgData.Guru,
          color: "#326b37",
        });
      }

      let chartConfig = {
        chart: {},
        rangeSelector: {
          inputEnabled: false,
          selected: 3,
        },
        tooltip: {
          useHTML: true,
          pointFormat: `<span style="color:{series.color}">{series.name}</span>: <b> ${currency}{point.y}</b><br/>`,
          valueDecimals: 2,
          shared: true,
          split: false,
        },
        credits: {
          enabled: false,
        },
        series: dataSets,
      };
      tabs = (
        <div className="container-section">
          {showSectionOptions && (
            <div className="btn-toggle-group pull-right">
              <ToggleButton
                name="show-options"
                type="radio"
                value={featuredSearch.SHOW_PAY_RATE_OPTION}
                selected={featuredSearch.showPayRate}
                onClick={featuredSearch.handleLevelPeekDisplayOptionChange}
              >
                Pay Rate
              </ToggleButton>
              <ToggleButton
                name="show-options"
                type="radio"
                value={featuredSearch.SHOW_MARKUP_OPTION}
                selected={featuredSearch.showMarkup}
                onClick={featuredSearch.handleLevelPeekDisplayOptionChange}
              >
                Markup
              </ToggleButton>
              <ToggleButton
                name="show-options"
                type="radio"
                value={featuredSearch.SHOW_BILL_RATE_OPTION}
                selected={featuredSearch.showBillRate}
                onClick={featuredSearch.handleLevelPeekDisplayOptionChange}
              >
                Bill Rate
              </ToggleButton>
            </div>
          )}
        </div>
      );
      changesTable = (
        <div>
          <PlainHighStockChart
            options={chartConfig}
            style={{ height: 426, width: "100%" }}
          />

          <div className="clearfix">
            <br />
            <br />
            <table
              className="no-spacing table table-bordered table-striped table-responsive"
              cellSpacing="0"
              data-testid="rate-changes-list"
            >
              <thead className="">
                <tr>
                  <th>Level</th>
                  <th>Records</th>
                  <th>Min</th>
                  <th>Mid</th>
                  <th>Max</th>
                  <th>Average</th>
                  <th>Change</th>
                </tr>
              </thead>
              <tbody>
                {Junior && (
                  <RateCardTrackItem
                    featuredSearch={featuredSearch}
                    resultLevel={Junior}
                  />
                )}
                {Mid && (
                  <RateCardTrackItem featuredSearch={featuredSearch} resultLevel={Mid} />
                )}
                {Senior && (
                  <RateCardTrackItem
                    featuredSearch={featuredSearch}
                    resultLevel={Senior}
                  />
                )}
                {Lead && (
                  <RateCardTrackItem featuredSearch={featuredSearch} resultLevel={Lead} />
                )}
                {Guru && (
                  <RateCardTrackItem featuredSearch={featuredSearch} resultLevel={Guru} />
                )}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }

  return (
    <div>
      <span className="icon border">
        <FontAwesomeIcon
          icon="user"
          style={{ float: "left", marginRight: "5px", fontSize: "15px" }}
        />
        <h5>Job Title: {jobTitle}</h5>
      </span>
      <hr />
      <span className="icon border">
        <FontAwesomeIcon
          icon="map-marker"
          style={{ float: "left", marginRight: "5px", fontSize: "15px" }}
        />
        <h5>Location: {loc}</h5>
      </span>
      {tabs}
      <div className="table-responsive">{changesTable}</div>
    </div>
  );
};

export default observer(RateCardTrack);
