import React, { useCallback } from "react";

import Text from "../../../../components/lib/Text";
import TextCrop from "../../../validator5K_admin/components/TextCrop";
import { IconButton } from "../../../../components/lib/Button";
import ReviewsPeckingOrderTableWithFeedback from "../../../validator5K_admin/components/ReviewsPeckingOrderTable/ReviewsPeckingOrderTableWithFeedback";
import RateBlock from "../../../validator5K_admin/components/ReviewsPeckingOrderTable/RateBlock";
import {
  getJobRegion,
  getAttemptStatus,
  getFirstJobData,
} from "../../../validator5K_admin/components/ReviewsPeckingOrderTable/utils";
import { THead, TH, TR, TD } from "../../../../components/lib/Table";
import { emptyMap } from "../../../../constants";

import {
  REVIEW_STATUS_TYPES,
  type AttemptDataMap,
  type ReviewDataMap,
} from "../../types";
import type { ReviewsPeckingOrderTableBaseProps } from "../../../validator5K_admin/components/ReviewsPeckingOrderTable/base";
import { useVal5KPublicContext } from "../../context/Val5KPublicContext";
import { getSurveyUrlPath } from "../../utils";
import { useAttemptDataContext } from "../../context/AttemptDataContext";
import RateDisplayContextProvider from "../../../validator5K_admin/context/RateDisplayContext";

type SurveysPeckingOrderTableRowProps = {
  reviewData: ReviewDataMap;
};

const SurveysPeckingOrderTableRow = (props: SurveysPeckingOrderTableRowProps) => {
  const { reviewData } = props;
  const { router } = useVal5KPublicContext();
  const { updateAttemptData } = useAttemptDataContext();

  const reviewId = reviewData.get("id");
  const firstJobData = getFirstJobData(reviewData);
  const jobIndustry = firstJobData.get("industry_name");
  const jobTitle = firstJobData.get("title");
  const jobDescription = firstJobData.get("description");

  const attemptData =
    reviewData.get("attempt") ?? (emptyMap as unknown as AttemptDataMap);
  const attemptId = attemptData.get("id");
  const hasAttempt = attemptId != null;
  const [attemptStatus, attemptStatusString] = getAttemptStatus(attemptData);

  const disableEditing =
    !!attemptData.get("analysis_completed", false) || !!attemptData.get("paid", false);

  // handlers

  const handleGoBackToSurvey = async () => {
    const surveyPath = getSurveyUrlPath(reviewData);

    if (surveyPath) {
      // move attempt status back to in-progress
      if (hasAttempt && attemptStatus !== REVIEW_STATUS_TYPES.NOT_REVIEWED) {
        await updateAttemptData(attemptId, {
          review_status: REVIEW_STATUS_TYPES.NOT_REVIEWED,
        });
      }
      router.push(surveyPath);
    }
  };

  // render

  return (
    <TR key={reviewId}>
      <TD>
        <IconButton
          icon="chevron-left"
          variant="outlined"
          color="brand"
          title={
            disableEditing ? "" : attemptStatus == null ? "Start survey." : "Edit survey."
          }
          disabled={disableEditing}
          onClick={handleGoBackToSurvey}
          css={{ width: "30px", height: "30px" }}
        />
      </TD>
      <TD>
        <Text underline>
          <TextCrop
            mode="modal"
            title={jobTitle}
            text={jobTitle}
            fullText={jobDescription}
            emptyStub=""
          />
        </Text>
      </TD>
      <TD>
        <TextCrop
          mode="tooltip"
          title="Location"
          text={getJobRegion(firstJobData)}
          emptyStub=""
        />
      </TD>
      <TD>
        <TextCrop mode="tooltip" title="Industry" text={jobIndustry} emptyStub="" />
      </TD>
      <TD>{attemptStatusString}</TD>
      <TD>
        <RateBlock reviewData={reviewData} rateValue="min" />
      </TD>
      <TD>
        <RateBlock reviewData={reviewData} rateValue="max" />
      </TD>
    </TR>
  );
};

type SurveysPeckingOrderTableProps = ReviewsPeckingOrderTableBaseProps;

export default function SurveysPeckingOrderTable(props: SurveysPeckingOrderTableProps) {
  const tableHead = (
    <THead>
      <TR>
        <TH>Edit</TH>
        <TH>Title</TH>
        <TH>Location</TH>
        <TH>Industry</TH>
        <TH>Status</TH>
        <TH>
          Lowest Wage
          <br />
          (least amount of experience)
        </TH>
        <TH>
          Highest Wage
          <br />
          (most amount of experience)
        </TH>
      </TR>
    </THead>
  );

  const renderTableRow = useCallback(
    (reviewData: ReviewDataMap) => (
      <RateDisplayContextProvider reviewData={reviewData}>
        <SurveysPeckingOrderTableRow key={reviewData.get("id")} reviewData={reviewData} />
      </RateDisplayContextProvider>
    ),
    []
  );

  return (
    <ReviewsPeckingOrderTableWithFeedback
      {...props}
      tableHead={tableHead}
      renderTableRow={renderTableRow}
      emptyDataText="no surveys found."
    />
  );
}

SurveysPeckingOrderTableRow.displayName = "SurveysPeckingOrderTable";
