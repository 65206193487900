// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import CheckBox from "../shared/CheckBox";
import classNames from "classnames";
import TagItem from "../ratecards/components/TagItem";
import {
  CardListItem,
  CardListItemLabel,
  CardListItemLeft,
  CardListItemRight,
  CardListSubText,
  SubTextAccent,
  Info,
  InfoData,
  InfoLabel,
} from "../../components/lib/Card";
import LinkButton from "../../components/lib/LinkButton";
import Inline from "../../components/lib/Inline";

/**
 * JobListItem component to display items in
 */
const JobListItem = observer(
  class extends Component {
    static contextTypes = {
      router: PropTypes.object,
    };
    /**
   * constructor
   * @param {props} props The first number.

   */
    constructor(props) {
      super(props);
      this.state = {
        updated: false,
      };
      this.deleteTag = this.deleteTag.bind(this);
      this.handleViewDetail = this.handleViewDetail.bind(this);
    }

    handleViewDetail() {
      this.context.router.push({
        pathname: `/job-labels/${this.props.job.jobId}`,
        query: this.context.router.query,
      });
    }

    deleteTag(event: object, tag_id) {
      event.preventDefault();
      event.stopPropagation();
      event.cancelBubble = true;
      event.returnValue = false;

      this.props.store
        .deleteSingleTags({ tagId: tag_id, contentId: this.props.job.jobId })
        .then((res) => {
          if (!res.result) return;
          this.props.job.tags.forEach((item, index) => {
            if (item.tagId === tag_id) {
              this.props.job.tags.splice(index, 1);
            }
          });
          this.setState({
            updated: true,
          });
        });
    }

    /**
     * Render Job List item
     * @return {XML}
     */
    render() {
      const props = this.props;
      const store = props.store;

      const job = props.job;

      const editing = job.viewState.editing;
      const selected = job.viewState.selected;
      const jobLabel = job.jobLabel;
      const jobDescription = job.jobDescription;
      const jobTitle = job.jobTitle;
      const createdDisplay = job.created.format("MMMM D, YYYY");
      let onItemClick = this.props.onClick || this.handleViewDetail;

      let ownerName = "You";
      let tagSection = null;
      let tags = job.tags;

      if (job.owner.email !== localStorage.getItem("PeopleTickerUserEmail")) {
        ownerName = job.owner.fullname;
      }

      let editingSection = null;

      if (tags.length > 0 && !this.props.store.isTagView) {
        tagSection = tags.map((tag) => (
          <TagItem key={tag.tagId} tag={tag} onClick={this.deleteTag} />
        ));
      }

      if (editing) {
        onItemClick = job.toggleSelected;
        editingSection = (
          <CheckBox
            type={store.allowMultipleItemSelection ? "checkbox" : "radio"}
            name="list-item-select"
            value={jobLabel}
            selected={selected}
            style={{
              width: 34,
            }}
            onClick={onItemClick}
          />
        );
      }
      return (
        <CardListItem selected={selected} onClick={onItemClick}>
          <CardListItemLeft>
            <Inline css={{ gap: 0, flexWrap: "nowrap" }}>
              {editingSection}
              <div>
                <CardListItemLabel>{jobLabel}</CardListItemLabel>
                <CardListSubText>
                  Created by <SubTextAccent>{ownerName}</SubTextAccent> on{" "}
                  <SubTextAccent>{createdDisplay}</SubTextAccent>
                </CardListSubText>
                <CardListSubText css={{ maxWidth: "60ch" }}>
                  {jobDescription}
                </CardListSubText>
              </div>
            </Inline>
            <div className={classNames("tagbox", { "p-left-34": editing })}>
              {tagSection}
            </div>
          </CardListItemLeft>
          <CardListItemRight>
            <Info css={{ width: "25ch" }}>
              <InfoLabel>Job Title</InfoLabel>
              <InfoData>{jobTitle}</InfoData>
            </Info>
            {!editing && (
              <LinkButton to={`/job-labels/${props.job.jobId}`}>View</LinkButton>
            )}
          </CardListItemRight>
        </CardListItem>
      );
    }
  }
);

JobListItem.propTypes = {
  store: PropTypes.object.isRequired,
  job: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default JobListItem;
