import React from "react";
import { observer } from "mobx-react";
import SelectableItemsList from "../ratecards/components/SelectableItemsList";
import SelectableItem from "../ratecards/components/SelectableItem";
import ContainerSection from "../ratecards/components/ContainerSection";
import FilterModal from "../ratecards/components/FilterModal";
import LoadingIndicator from "../shared/LoadingIndicator";
import SortControls from "../ratecards/components/SortControls";
import InstantSearchBox from "../ratecards/components/InstantSearchBox";
import Button from "../../components/lib/Button";
import Inline from "../../components/lib/Inline";

const CountyCriteriaList = observer((props) => (
  <div>
    {props.filterState.viewItems.length !== 0 && (
      <SelectableItemsList className="overlap-tb-padding" listState={props.filterState}>
        {props.filterState.viewItems.map((item, i) => (
          <SelectableItem
            key={item.id}
            item={item}
            name="location-filter"
            value={item.country}
            selected={item.selected}
            onChange={props.filterState.setSelectedValue}
          >
            <div title={item.country}>{item.country}</div>
          </SelectableItem>
        ))}
      </SelectableItemsList>
    )}
    {props.filterState.viewItems.length === 0 && (
      <SelectableItemsList className="overlap-tb-padding" listState={props.filterState}>
        <h4 className="text-center">No Countries Available</h4>
      </SelectableItemsList>
    )}
  </div>
));

const CountryFilter = ({
  countryFilter,
  network,
  onInstantSearch,
  instantSearchValue,
}) => {
  return (
    <FilterModal filterState={countryFilter}>
      {network && <LoadingIndicator />}
      {!network && (
        <div>
          <ContainerSection className="header overlap-t-padding">
            <h4 className="pull-left">Country</h4>
            <div className="pull-right">
              <SortControls filterState={countryFilter} />
            </div>
            <div className="clearfix" />
          </ContainerSection>
          <ContainerSection className="no-border">
            <p>Filter by specific Country:</p>
            <div>
              <div className="pull-left">
                <InstantSearchBox onSearch={onInstantSearch} value={instantSearchValue} />
              </div>
              <Inline css={{ height: 40, gap: "$2", justifyContent: "flex-end" }}>
                <Button onClick={countryFilter.onSelectAll}>Select All</Button>
                <Button onClick={countryFilter.onDeselectAll}>Deselect All</Button>
              </Inline>
              <div className="clearfix" />
            </div>
          </ContainerSection>
          <CountyCriteriaList filterState={countryFilter} />
        </div>
      )}
    </FilterModal>
  );
};

export default CountryFilter;
