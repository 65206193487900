import React from "react";
import Inline from "./lib/Inline";
import Button from "./lib/Button";
import { FilterDialog, FilterDialogProps } from "./FilterDialog";

export interface SharedFilterDialogProps {
  filtered?: boolean;
  onApply: FilterDialogProps["onApply"];
  onClear: FilterDialogProps["onClear"];
}

export function SharedFilterDialog(props: SharedFilterDialogProps) {
  return (
    <FilterDialog
      title="Shared"
      description={
        <Inline css={{ gap: "$2" }}>
          <Button variant="outlined" color="brand">
            Shared
          </Button>
          <Button variant="outlined" color="brand">
            Not Shared
          </Button>
        </Inline>
      }
      filtered={props.filtered}
      onApply={props.onApply}
      onClear={props.onClear}
    />
  );
}
