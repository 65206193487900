// @flow

import R from "ramda";
import { extendObservable, action, runInAction, computed, observable } from "mobx";
import moment from "moment";
import axios from "axios";
import ProjectCostEstimates from "../../../models/ProjectCostEstimate";
import RateCardSearchDetails from "../../../models/RateCardSearchesDetails";
import CurrentUser from "../../../models/User";
import NetworkState from "../../../models/NetworkState";
import PaginationState from "../../../models/PaginationState";
import FilterObject, { FILTER_COLUMN } from "../../../models/Filter";
import Sort, { SORT_DIRECTION } from "../../../models/Sort";
import {
  ProjectCostFilter,
  CreatedByFilter,
  CreatedOnFilter,
  TagsFilter,
  UserFilter,
  ClientUserFilter,
} from "../../../models/FilterState";
import ModalState from "../../../models/ModalState";
import { consolidateAppliedFilters, consolidateAppliedSorts } from "../SupportFunctions";
import type { PageQuery, PaginationInfo } from "../../../models/PaginationState";
import type { FilterColumn } from "../../../models/Filter";
import type { GraphQLQuery } from "../../../models/GraphQL";
import ExportOptionsState from "../../../models/ExportOptionsState";
import type { FetchGraphQL, FetchAPI } from "../../../App";

// Required for Created by filter
const ownersCriteriaQuery = `
  query authorList {
    viewer {
      authors(section : ADMIN) {
        edges {
          node {
            userId
            firstName
            lastName
            username
            email
          }
        }
      }
    }
  }
  `;

const tagsFilterCriteria = `
query getTags {
 viewer{
   tagsFilterCriteria{
     tagnames(contentType: PROJECT_STATS, section : ADMIN){
       tag
     }
   }
 }
}
`;

const userCriteriaQuery = `
query authorList {
  viewer {
    authors {
      edges {
        node {
          userId
          firstName
          lastName
          username
          email
        }
      }
    }
  }
}
`;

const clientUserCriteriaQuery = `
query allClients {
  viewer {
    allClients(order : [{direction : ASC, field: NAME}]) {
      edges {
        node {
          clientId
          name
        }
      }
    }
  }
}
`;

export class ProjectCostEstimateComponentStore {
  network: NetworkState;
  pagination: PaginationState;

  currentUser: CurrentUser;
  isEditing: ?boolean;
  allOnPageSelected: boolean;
  allSelected: boolean;
  allSelectedfilter: boolean;
  allowMultipleItemSelection: boolean;

  projectCosts: ProjectCostEstimates[];
  projectCostsViewState: Object;
  projectCostsView: ProjectCostEstimates[];
  projectCounts: any;
  selectedPunchouts: any;

  projectCostFilter: ProjectCostFilter;
  createdByFilter: CreatedByFilter;
  createdOnFilter: CreatedOnFilter;
  userFilter: UserFilter;
  clientUserFilter: ClientUserFilter;
  tagsFilter: TagsFilter;

  defaultFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedSorts: {
    [key: FilterColumn]: Sort,
  };
  appliedSortsOrder: Array<FilterColumn>;
  isFiltered: boolean;

  getProjects: (PageQuery) => Promise<PaginationInfo>;
  applyDefaultFilter: (FilterColumn, FilterObject) => void;
  handleStartEdit: () => void;
  handleStopEdit: () => void;
  applySort: (FilterColumn, Sort) => void;
  removeSort: (FilterColumn) => void;
  applyDefaultSort: () => void;

  toggleSelectAllPage: (Object) => void;
  selectAllPage: (Event) => void;
  deselectAllPage: (Event) => void;
  toggleAllItems: () => void;
  clearAllSelections: () => void;
  getSelectedProjects: () => Array<string>;

  getFilterCriteriaQuery: (FilterColumn) => GraphQLQuery;
  processFilterCriteria: (FilterColumn, Object) => Array<Object>;
  applyFilter: (FilterColumn, FilterObject) => void;
  removeFilter: (FilterColumn) => void;
  filterProjects: () => void;
  clearFilters: () => void;
  selectAllOnPageItem: () => void;
  toDateRange: () => void;
  fetchGraphQL: FetchGraphQL;

  constructor(fetchGraphQL: FetchGraphQL) {
    this.fetchGraphQL = fetchGraphQL;
    this.getProjects = action(this.getProjects.bind(this));
    this.getFilterCriteriaQuery = action(this.getFilterCriteriaQuery.bind(this));
    this.processFilterCriteria = action(this.processFilterCriteria.bind(this));
    this.applyFilter = action(this.applyFilter.bind(this));
    this.applySort = action(this.applySort.bind(this));
    this.removeFilter = action(this.removeFilter.bind(this));
    this.removeSort = action(this.removeSort.bind(this));
    this.toDateRange = action(this.toDateRange.bind(this));

    extendObservable(this, {
      network: new NetworkState(),
      pagination: new PaginationState(this.getProjects),
      projectCosts: [],
      projectCounts: 0,
      selectedPunchouts: observable.map({}),
      projectCostsViewState: observable.map({}),
      projectCostsView: computed(() => {
        return this.projectCosts.map((projectCost) => {
          if (this.projectCostsViewState.has(projectCost.projectCostId)) {
            projectCost.viewState = this.projectCostsViewState.get(
              projectCost.projectCostId
            );

            return projectCost;
          }
          return projectCost;
        });
      }),
      isEditing: null, // we start with null so some view elements be hidden initially
      isFiltered: false,
      allSelected: false,
      allowMultipleItemSelection: true,
      selectedCount: computed(() => {
        const selectedValues = this.projectCostsView.map(
          (projectCost) => projectCost.viewState.selected
        );

        if (this.allSelected) {
          return this.pagination.totalCount;
        }

        let count = 0;

        selectedValues.forEach((v) => {
          if (v) {
            count += 1;
          }
        });

        return count;
      }),
      allOnPageSelected: computed(() => {
        const allTrue = R.all(R.equals(true));
        const selectedValues = this.projectCostsView.map(
          (projectCost) => projectCost.viewState.selected
        );

        if (selectedValues.length === 0) {
          return false;
        }

        return allTrue(selectedValues);
      }),
      defaultFilters: {},
      appliedFilters: {},
      appliedSorts: {},
      appliedSortsOrder: observable.shallow([]),
      projectCostFilter: new ProjectCostFilter(
        this,
        FILTER_COLUMN.PROJECT_LABEL,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      createdByFilter: new CreatedByFilter(
        this,
        FILTER_COLUMN.CREATED_BY,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      createdOnFilter: new CreatedOnFilter(
        this,
        FILTER_COLUMN.PC_DATE_RANGE, // New filter type only for PCE because the date type is CREATED
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      allSelectedfilter: false,
      tagsFilter: new TagsFilter(
        this,
        FILTER_COLUMN.TAGS,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      userFilter: new UserFilter(
        this,
        FILTER_COLUMN.USER,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      clientUserFilter: new ClientUserFilter(
        this,
        FILTER_COLUMN.CLIENT_USER,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
    });

    this.currentUser = null;
    this.handleStartEdit = action(this.handleStartEdit.bind(this));
    this.handleStopEdit = action(this.handleStopEdit.bind(this));
    this.filterProjects = action(this.filterProjects.bind(this));
    this.clearFilters = action(this.clearFilters.bind(this));
    this.applyDefaultSort = action(this.applyDefaultSort.bind(this));
    this.applyDefaultFilter = action(this.applyDefaultFilter.bind(this));
    this.toggleSelectAllPage = action(this.toggleSelectAllPage.bind(this));
    this.selectAllPage = action(this.selectAllPage.bind(this));
    this.deselectAllPage = action(this.deselectAllPage.bind(this));
    this.toggleAllItems = action(this.toggleAllItems.bind(this));
    this.clearAllSelections = action(this.clearAllSelections.bind(this));
    this.getSelectedProjects = action(this.getSelectedProjects.bind(this));
    this.selectAllOnPageItem = action(this.selectAllOnPageItem.bind(this));
    this.applyDefaultSort();
  }

  applyDefaultSort() {
    this.createdOnFilter.sortState.direction = SORT_DIRECTION.DESC;
    this.createdOnFilter.sortState.field = "CREATED";
    this.createdOnFilter.sort = this.createdOnFilter.buildQuerySort();
    this.applySort(this.createdOnFilter.column, this.createdOnFilter.sort);
  }

  toDateRange(date) {
    var val = true;
    if (this.createdOnFilter.fromDate) {
      val = !date.isBetween(
        this.createdOnFilter.fromDate,
        moment(new Date()),
        "days",
        "[]"
      );
    }
    return val;
  }

  filterProjects() {
    return this.pagination.goFetch(null);
  }

  handleStartEdit() {
    this.isEditing = true;
    this.projectCostsView.forEach((projectCost) => projectCost.toggleEdit());
  }

  handleStopEdit() {
    this.isEditing = false;
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.projectCostsViewState.forEach((viewState) => {
      viewState.selected = false;
      viewState.editing = false;
    });
  }

  applyFilter(column: FilterColumn, filter: FilterObject) {
    this.appliedFilters[column] = filter;
    this.isFiltered = true;
  }

  applyDefaultFilter(column: FilterColumn, filter: FilterObject) {
    this.defaultFilters[column] = filter;
  }

  applySort(column: FilterColumn, sort: Sort) {
    this.appliedSorts[column] = sort;

    const index = this.appliedSortsOrder.indexOf(column);
    if (index === -1) this.appliedSortsOrder.push(column);
  }

  removeFilter(column: FilterColumn) {
    delete this.appliedFilters[column];

    let entries = Object.entries(this.appliedFilters);
    if (!entries.length) this.isFiltered = false;
  }

  removeSort(column: FilterColumn) {
    delete this.appliedSorts[column];

    const index = this.appliedSortsOrder.indexOf(column);
    if (index > -1) this.appliedSortsOrder.splice(index, 1);
  }

  // Clear all the requested filters
  clearFilters() {
    this.projectCostFilter = new ProjectCostFilter(
      this,
      FILTER_COLUMN.RATE_CARD_LABEL,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.createdByFilter = new CreatedByFilter(
      this,
      FILTER_COLUMN.CREATED_BY,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.createdOnFilter = new CreatedOnFilter(
      this,
      FILTER_COLUMN.PC_DATE_RANGE,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.tagsFilter = new TagsFilter(
      this,
      FILTER_COLUMN.TAGS,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.userFilter = new UserFilter(
      this,
      FILTER_COLUMN.USER,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.clientUserFilter = new ClientUserFilter(
      this,
      FILTER_COLUMN.CLIENT_USER,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.appliedFilters = observable({});
    this.appliedSorts = observable({});
    this.appliedSortsOrder.length = 0;
    this.isFiltered = false;
    this.applyDefaultSort();

    return this.pagination.goFetch(null); // Call pagination once the filter is cleared, load the DOM
  }

  getFilterCriteriaQuery(column: FilterColumn): GraphQLQuery {
    switch (column) {
      case FILTER_COLUMN.CREATED_BY:
        return {
          query: ownersCriteriaQuery,
          variables: {},
        };

      case FILTER_COLUMN.TAGS:
        return {
          query: tagsFilterCriteria,
          variables: {},
        };
      case FILTER_COLUMN.USER:
        return {
          query: userCriteriaQuery,
          variables: {},
        };

      case FILTER_COLUMN.CLIENT_USER:
        return {
          query: clientUserCriteriaQuery,
          variables: {},
        };

      default:
        return null;
    }
  }

  processFilterCriteria(column: FilterColumn, payload: Object): ?Array<Object> {
    switch (column) {
      case FILTER_COLUMN.CREATED_BY:
        const owners: [
          {
            userId: string,
            username: string,
          }
        ] = payload.data.viewer.authors.edges;
        let processedOwners = observable.map({});
        owners.forEach((owner) => {
          processedOwners.set(String(owner.node.userId), {
            id: String(owner.node.userId),
            ...owner,
          });
        });

        return processedOwners;

      case FILTER_COLUMN.TAGS:
        const tags: [
          {
            tag: string,
          }
        ] = payload.data.viewer.tagsFilterCriteria.tagnames;
        let processedTag = observable.map({});
        tags.forEach((tag, index) => {
          processedTag.set(String(index), {
            id: String(index),
            ...tag,
          });
        });
        return processedTag;

      case FILTER_COLUMN.USER:
        const users: [
          {
            userId: string,
            username: string,
          }
        ] = payload.data.viewer.authors.edges;
        let processedUsers = observable.map({});
        users.forEach((owner) => {
          processedUsers.set(String(owner.node.userId), {
            id: String(owner.node.userId),
            ...owner,
          });
        });

        return processedUsers;

      case FILTER_COLUMN.CLIENT_USER:
        const clientowners: [
          {
            clientId: string,
            name: string,
          }
        ] = payload.data.viewer.allClients.edges;
        let processedClientOwners = observable.map({});
        clientowners.forEach((owner) => {
          processedClientOwners.set(owner.node.clientId, {
            id: owner.node.clientId,
            ...owner,
          });
        });

        return processedClientOwners;

      default:
        return null;
    }
  }

  toggleSelectAllPage(e: Object) {
    if (!this.allowMultipleItemSelection) return;

    const setValue = !this.allOnPageSelected;

    this.projectCostsView.forEach((project) => {
      project.toggleSelected(e, null, null, setValue);
    });

    // When All items selected flag is up, clear selection
    if (setValue === false && this.allSelected) this.allSelected = false;
  }

  // Select all the project
  selectAllPage(e: Event) {
    this.allSelected = true;
    this.allSelectedfilter = true;
  }

  selectAllOnPageItem(e: Event) {
    this.projectCostsView.forEach((project) => {
      project.toggleSelected(e, null, null, true);
    });
  }

  // Deselect all the project
  deselectAllPage(e: Event) {
    this.projectCostsView.forEach((project) => {
      project.toggleSelected(e, null, null, false);
    });

    this.allSelected = false;
    this.allSelectedfilter = false;
  }

  toggleAllItems() {
    if (!this.allowMultipleItemSelection) return;

    this.allSelected = !this.allSelected;

    if (this.allSelected === false) {
      this.projectCostsView.forEach((value) => {
        value.viewState.selected = false;
      });
    }
  }

  clearAllSelections() {
    this.isTagView = false;
    //  this.isEditing = false;
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.projectCostsView.forEach((value) => {
      value.viewState.selected = false;
    });
  }

  // Collect all the selected projects for editing
  getSelectedProjects(): Array<string> {
    const projects = this.projectCostsViewState;
    let selectedProjects = [];

    projects.forEach((value, key) => {
      if (value.selected) {
        selectedProjects.push(key);
      }
    });
    return selectedProjects;
  }

  async getProjects(pageQuery: PageQuery): Promise<PaginationInfo> {
    let params: string[] = pageQuery.params;
    let args = pageQuery.args;
    let variables = pageQuery.variables;
    let filtersCriteria: string[] = [];

    let sortCriteria: string[] = [];
    consolidateAppliedSorts(this.appliedSorts, sortCriteria);

    consolidateAppliedFilters(this.appliedFilters, params, filtersCriteria, variables);

    consolidateAppliedFilters(this.defaultFilters, params, filtersCriteria, variables);

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");
    const queryFiltersCriteria = filtersCriteria.join(", ");
    const querySortCriteria = sortCriteria.join(", ");

    const query = `
      query project (${queryParams}){
       viewer {
         projectCosts(${queryArgs}, filters: { ${queryFiltersCriteria} }, order: [${querySortCriteria}], section: ADMIN) {
           edges {
             node {
               name
               projectId
               tags{
                name
                tagId
               }
               user {
                 firstName
                 lastName
               }
               created
             }
           }
           totalCount
         }
       }
      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting Projects", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Projects query", res);
      }

      return e;
    }

    return runInAction("getProjects--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get Projects query", res)) {
        // TODO: Display user friendly error message
        return {
          totalCount: 0,
        };
      }
      const pc = res.data.viewer.projectCosts.edges;

      this.projectCounts = res.data.viewer.projectCosts.totalCount;

      this.projectCosts = pc.map((pce) => {
        const projectCost = new ProjectCostEstimates(this, pce.node);

        if (!this.projectCostsViewState.has(projectCost.projectCostId)) {
          this.projectCostsViewState.set(projectCost.projectCostId, {
            selected: this.allSelected,
            editing: this.isEditing,
          });
        } else {
          const selectedValue = this.allSelected
            ? true
            : this.projectCostsViewState.get(projectCost.projectCostId).selected;

          this.projectCostsViewState.set(projectCost.projectCostId, {
            selected: selectedValue,
            editing: this.isEditing,
          });
        }

        projectCost.viewState = this.projectCostsViewState.get(projectCost.projectCostId);

        return projectCost;
      });

      return {
        totalCount: res.data.viewer.projectCosts.totalCount,
      };
    });
  }
}

export default class ProjectCostEstimateStore extends ProjectCostEstimateComponentStore {
  showHelpModal: boolean;
  showExportModal: boolean;
  exportFileName: string;
  allowExpand: boolean;
  rateCardSearch: RateCardSearchDetails[];

  helpModal: ModalState;
  confirmDeleteModal: ModalState;

  exportModalState: ExportOptionsState;

  showHelp: () => void;
  hideHelp: () => void;
  showExport: () => void;
  hideExport: () => void;
  export: () => void;
  PCEtoggleExpand: () => void;
  getRateCardDetail: () => void;
  validateExportOptions: (modalState: ExportOptionsState) => void;
  togglePunchoutSelect: () => void;
  selectProjects: () => void;
  changeDateFormat: () => void;
  handlePunchoutEditStop: () => void;
  selectedProjectStats: () => void;
  fetchAPI: FetchAPI;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI) {
    super(fetchGraphQL);

    // Default name for exported file
    extendObservable(this, {
      exportFileName: "exported_pce_" + Math.floor(Math.random() * 9999999 + 1000000),
      helpModal: new ModalState(),
      confirmDeleteModal: new ModalState(),
      networkShareUsers: new NetworkState(),
      allowExpand: true,
      rateCardSearch: [],
      punchoutArray: [],
      punchoutViewState: observable.map({}),
      punchoutArrayView: computed(() => {
        return this.punchoutArray.map((punchout) => {
          if (this.punchoutViewState.has(punchout.punchoutId)) {
            punchout.viewState = this.punchoutViewState.get(punchout.punchoutId);

            return punchout;
          }
          return punchout;
        });
      }),
    });

    this.showHelp = action(this.showHelp.bind(this));
    this.hideHelp = action(this.hideHelp.bind(this));
    this.showExport = action(this.showExport.bind(this));
    this.hideExport = action(this.hideExport.bind(this));
    this.PCEtoggleExpand = action(this.PCEtoggleExpand.bind(this));
    this.getRateCardDetail = action(this.getRateCardDetail.bind(this));
    this.togglePunchoutSelect = action(this.togglePunchoutSelect.bind(this));
    this.handlePunchoutEditStop = action(this.handlePunchoutEditStop.bind(this));
    this.selectedProjectStats = action(this.selectedProjectStats.bind(this));
    this.selectProjects = action(this.selectProjects.bind(this));
    this.changeDateFormat = action(this.changeDateFormat.bind(this));
    this.export = action(this.export.bind(this));
  }

  showHelp() {
    this.showHelpModal = true;
  }

  hideHelp() {
    this.showHelpModal = false;
  }

  showExport() {
    this.showExportModal = true;
  }

  hideExport() {
    this.showExportModal = false;
  }

  changeDateFormat(date) {
    var formatted_date = new Date(date);
    var year = formatted_date.getFullYear() + "";
    var month = formatted_date.getMonth() + 1 + "";
    var day = formatted_date.getDate() + "";
    var dateFormat = year + "-" + month + "-" + day;

    return dateFormat;
  }

  selectProjects(type) {
    if (this.network.loading) {
      return;
    }

    let params = [];
    let args = [];
    let vars = {};
    let searchCriteria = "";

    if (this.allSelected) {
      consolidateAppliedFilters(this.appliedFilters, params, args, vars);
      searchCriteria = {
        searchParam: vars,
      };
    } else {
      const selectedProjects = this.getSelectedProjects();

      if (!selectedProjects) {
        console.error("Cannot " + type + " projects: No Project selected");
        return;
      }
      searchCriteria = {
        projectId: selectedProjects,
      };
    }

    return searchCriteria;
  }

  export() {
    let parameters = "";
    let params = [];
    let args = [];
    let vars = {};
    var fileName = this.exportFileName;
    let exparams = "";
    // Get only the selectProject

    if (this.allSelectedfilter) {
      // debugger
      consolidateAppliedFilters(this.appliedFilters, params, args, vars);
      vars.exclude = [];
      if (vars.nameFilterText) {
        vars.search = vars.nameFilterText;
        delete vars.nameFilterText;
      }
      parameters = JSON.stringify(vars);
      if (parameters === "") {
        exparams = `{"fileName": "${fileName}",  "section":"admin", "projectFilters": {${parameters}}}`;
      } else {
        exparams = `{"fileName": "${fileName}", "section":"admin", "projectFilters": ${parameters}}`;
      }
    } else {
      const selectedProjects = this.getSelectedProjects();
      if (!selectedProjects || !selectedProjects.length) {
        console.error("Cannot export Regions: No Regions selected");
        return;
      }
      vars.only = selectedProjects;
      parameters = JSON.stringify(vars);
      if (parameters === "") {
        exparams = `{"fileName": "${fileName}", "section":"admin", "projectFilters": {${parameters}}}`;
      } else {
        exparams = `{"fileName": "${fileName}","section":"admin", "projectFilters": ${parameters}}`;
      }
    }

    var url = "projects/basic/list/export/excel/";

    this.fetchAPI(url, JSON.parse(exparams))
      .then((res) => {
        window.location.href = res.data.url;
        this.handleStopEdit();
      })
      .catch((e) => {
        console.error("Error downloading excel", e);
        throw e;
      });
  }

  selectedProjectStats(projectstats) {
    this.selectedPunchouts = projectstats;
  }

  // Expand and collapse Rate card details
  PCEtoggleExpand(ratecard, rateCards) {
    rateCards.forEach((rc) => {
      if (rc.ratecardId === ratecard.ratecardId) {
        rc.viewState.expanded = !rc.viewState.expanded;
        if (rc.viewState.expanded) {
          this.getRateCardDetail(ratecard.ratecardId);
        }
      } else {
        rc.viewState.expanded = false;
      }
    });
  }

  togglePunchoutSelect(selpunchout) {
    if (this.punchoutViewState.has(selpunchout.punchoutId)) {
      let punchout = this.punchoutViewState.get(selpunchout.punchoutId);
      punchout.selected = !punchout.selected;
    }
  }

  handlePunchoutEditStop() {
    this.punchoutArray = [];
    this.punchoutViewState.forEach((punchout) => {
      punchout.selected = false;
      punchout.alreadyAdded = false;
    });
  }

  async getRateCardDetail(rateCardId) {
    const variables = {
      id: rateCardId,
      rateType: "Contract",
      hasBuyRate: true,
    };

    const query = `
      query rateCardDetail($id: Int!, $rateType: JobRateType!, $hasBuyRate: Boolean) {
       viewer {
          rateCardSearches(id: $id,filters: {rateType: $rateType, hasBuyRate: $hasBuyRate}) {
           edges {
             node {
               searchId
               state
               country
               currency{
                 iso
               }
               industry {
                 value
               }
               job {
                 jobLabel
                 jobTitle
                 jobDescription
               }
               jobDescription
               createdDate
               buyrates {
                 name
                 buyrateId
                 punchouts {
                   level{
                      value
                      legacyId
                   }
                   punchoutId
                   payRateMin
                   payRateMax
                   markupPct
                   billRateMin
                   billRateMax
                 }
               }
             }
           }
         }
       }
      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting RateCard Details", e);
      if (res !== null) {
        this.network.logGraphQLError("Get RateCard Details query", res);
      }

      return e;
    }

    return runInAction("getRateCardDetail--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get RateCard Details query", res)) {
        return {};
      }

      const rcsd = res.data.viewer.rateCardSearches.edges;

      this.rateCardSearch = rcsd.map((rcd) => {
        const rCSearchDetail = new RateCardSearchDetails(this, rcd.node, rateCardId);

        rCSearchDetail.buyrate.forEach((rateBuyrate) => {
          rateBuyrate.punchout.forEach((punchout) => {
            this.punchoutArray.push(punchout);
          });
        });

        this.punchoutArray.forEach((punch) => {
          if (!this.punchoutViewState.has(punch.punchoutId)) {
            this.punchoutViewState.set(punch.punchoutId, {
              selected: false,
              alreadyAdded: false,
            });
          } else {
            const selectedValue = this.allPunchoutSelected
              ? true
              : this.punchoutViewState.get(punch.punchoutId).selected;

            this.punchoutViewState.set(punch.punchoutId, {
              selected: selectedValue,
              alreadyAdded: false,
            });
          }
        });

        rCSearchDetail.punchoutArray = this.punchoutArray;

        if (this.selectedPunchouts.length > 0) {
          for (var i = 0; i <= this.selectedPunchouts.length - 1; i++) {
            for (var j = 0; j <= this.punchoutArray.length - 1; j++) {
              if (
                this.selectedPunchouts[i].rateCard.ratecardId ===
                  this.punchoutArray[j].rateCardId &&
                this.selectedPunchouts[i].search.searchId ===
                  this.punchoutArray[j].searchId &&
                this.selectedPunchouts[i].buyRate.buyrateId ===
                  this.punchoutArray[j].buyrateId &&
                this.selectedPunchouts[i].level.levelId === this.punchoutArray[j].levelId
              ) {
                if (this.punchoutViewState.has(this.punchoutArray[j].punchoutId)) {
                  let punchout = this.punchoutViewState.get(
                    this.punchoutArray[j].punchoutId
                  );
                  punchout.selected = true;
                  punchout.alreadyAdded = true;
                }
                break;
              }
            }
          }
        }

        return rCSearchDetail;
      });
    });
  }
}
