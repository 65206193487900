import React, { Component } from "react";
import AsyncSelect from "react-select/lib/Async";
import { reactSelectStyles, reactSelectTheme } from "../../constants/reactSelect";

class ClientJobLibrarySelect extends Component {
  constructor(props) {
    super(props);

    this.loadOptions = this.loadOptions.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  loadOptions(input, callback) {
    this.props
      .getClientLibraries()
      .then((clientLibraries) => {
        // NOTE: Special Value to search on all libraries
        clientLibraries.unshift({
          value: 0,
          label: "-- All Libraries --",
        });

        callback(clientLibraries);
      })
      .catch(function (error) {
        console.log("Error fetching client libraries", error);
        callback([]);
      });
  }

  handleChange(value, actionMeta) {
    if (actionMeta.action === "select-option") {
      this.props.onChange(value);
    } else {
      this.props.onChange(null);
    }
  }

  render() {
    return (
      <AsyncSelect
        inputId={this.props.id || "librarySelect"}
        name="clientLibraries"
        placeholder="-- All Libraries --"
        backspaceRemovesValue
        openMenuOnFocus
        defaultOptions
        loadOptions={this.loadOptions}
        onChange={this.handleChange}
        styles={reactSelectStyles}
        theme={reactSelectTheme}
      />
    );
  }
}

export default ClientJobLibrarySelect;
