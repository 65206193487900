export const LEVEL_SELECTOR = {
  Junior: "junior",
  Mid: "mid",
  Senior: "senior",
  Lead: "leader",
  Guru: "guru",
};

export const LEVEL_COLOR = {
  // Keep these values in sync with
  // file: /src/scss/views/ratecards/_ratecards.css
  // vars: $junior-color, $mid-color... etc
  Junior: "#0E5D92",
  Mid: "#882D60",
  Senior: "#28784D",
  Lead: "#5B2971",
  Guru: "#2E0F36",
};

export const VALUE_CHANGE_COLOR = {
  // Keep these values in sync with
  // file: /src/scss/views/ratecards/_ratecards.css -
  // vars: $positive-change-color, $negative-change-color
  positive: "#A2B624",
  negative: "#F24E4E",

  Junior: {
    positive: "#E2F376",
    negative: "#F24E4E",
  },
  Mid: {
    positive: "#E2F376",
    negative: "#F24E4E",
  },
  Senior: {
    positive: "#E2F376",
    negative: "#F24E4E",
  },
  Lead: {
    positive: "#E2F376",
    negative: "#F24E4E",
  },
  Guru: {
    positive: "#E2F376",
    negative: "#F24E4E",
  },
};
