import React from "react";
import { fromJS } from "immutable";

import {
  TableFilterableRestful as TableFilterableRestfulBase,
  TableFilterableEditableRestful as TableFilterableEditableRestfulBase,
} from "../../../components/tables";
import {
  Column,
  ColumnLikeElement,
  Group,
  GroupLikeElement,
} from "../../../components/tables/Schema";
import {
  TableFilterableEditableRestfulProps,
  TableFilterableRestfulProps,
} from "../../../components/tables/TableFilterableRestful";
import { emptySet } from "../../../constants";
import { useGlobalContext } from "../../../globalContext";

import type {
  DataProviderRestful,
  FiltersDataProviderRestful,
  RowData,
} from "../../../components/tables/types";
import type { TableColumnsGroupSpecsObject, TableColumnSpecsObject } from "../types";

export function filterTableSpecs(specs = [], visibleKeys = emptySet) {
  return specs.filter(({ key }) => {
    return visibleKeys && visibleKeys.size > 0 ? visibleKeys.includes(key) : true;
  });
}

function useDataProvidersWithLoaders(
  tableProps: TableFilterableRestfulProps
): [DataProviderRestful, FiltersDataProviderRestful] {
  const { dataProvider: dataProviderBase, filtersDataProvider: filtersDataProviderBase } =
    tableProps;
  const { showLoader, hideLoader } = useGlobalContext();

  const dataProvider: DataProviderRestful = React.useCallback(
    async (...args) => {
      try {
        await showLoader();
        return await dataProviderBase(...args);
      } catch (err) {
        console.error("Error during table data fetching:", { err });
        throw err;
      } finally {
        hideLoader();
      }
    },
    [dataProviderBase, showLoader, hideLoader]
  );

  const filtersDataProvider: FiltersDataProviderRestful = React.useCallback(
    async (...args) => {
      try {
        return await filtersDataProviderBase(...args);
      } catch (err) {
        console.error("Error during filter data fetching:", { err });
        throw err;
      }
    },
    [filtersDataProviderBase]
  );

  return [dataProvider, filtersDataProvider];
}

export const TableFilterableRestful = (props: TableFilterableRestfulProps) => {
  const [dataProvider, filtersDataProvider] = useDataProvidersWithLoaders(props);

  return (
    <TableFilterableRestfulBase
      {...props}
      dataProvider={dataProvider}
      filtersDataProvider={filtersDataProvider}
    />
  );
};

TableFilterableRestful.displayName = "TableFilterableRestful";
TableFilterableRestful.defaultProps = TableFilterableRestfulBase.defaultProps;

export const TableFilterableEditableRestful = (
  props: TableFilterableEditableRestfulProps
) => {
  const [dataProvider, filtersDataProvider] = useDataProvidersWithLoaders(props);

  return (
    <TableFilterableEditableRestfulBase
      {...props}
      dataProvider={dataProvider}
      filtersDataProvider={filtersDataProvider}
    />
  );
};

TableFilterableEditableRestful.displayName = "TableFilterableEditableRestful";
TableFilterableEditableRestful.defaultProps =
  TableFilterableEditableRestfulBase.defaultProps;

export function renderTableColumnsSpecs<RD = RowData>(
  columnsSpecs: TableColumnSpecsObject<RD>[]
): ColumnLikeElement<RD>[] {
  return columnsSpecs.reduce(
    (
      acc: ColumnLikeElement<RD>[],
      columnSpecs: TableColumnSpecsObject<RD>
    ): ColumnLikeElement<RD>[] => {
      return acc.concat(
        <Column
          key={columnSpecs.uniqueKey}
          uniqueKey={columnSpecs.uniqueKey}
          css={columnSpecs.css}
          headCss={columnSpecs.headCss}
          title={columnSpecs.title}
          getter={columnSpecs.getter}
          formatter={columnSpecs.formatter}
          sortable={columnSpecs.sortable == null ? true : columnSpecs.sortable}
          filterable={columnSpecs.filterable == null ? true : columnSpecs.filterable}
          filterType={columnSpecs.filterType}
          filterKey={columnSpecs.filterKey}
          filterOptions={fromJS(columnSpecs.filterOptions)}
          filterExtraProps={columnSpecs.filterExtraProps}
          popupSize={columnSpecs.popupSize}
          fixed={columnSpecs.fixed}
        />
      );
    },
    [] as ColumnLikeElement<RD>[]
  );
}

export function renderGroupedTableColumnsSpecs<RD = RowData>(
  groupsSpecs: TableColumnsGroupSpecsObject<RD>[]
): GroupLikeElement<RD>[] {
  return groupsSpecs.reduce(
    (
      acc: GroupLikeElement<RD>[],
      groupSpecs: TableColumnsGroupSpecsObject<RD>
    ): GroupLikeElement<RD>[] => {
      if (!groupSpecs) return acc;

      const columnsSpecs = groupSpecs.columns || [];
      const columns: ColumnLikeElement<RD>[] = renderTableColumnsSpecs(columnsSpecs);

      if (columns.length === 0) return acc;

      return acc.concat(
        <Group
          key={groupSpecs.uniqueKey}
          uniqueKey={groupSpecs.uniqueKey}
          title={groupSpecs.title}
          css={groupSpecs.css}
        >
          {columns}
        </Group>
      );
    },
    [] as GroupLikeElement<RD>[]
  );
}
