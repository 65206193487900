import { styled } from "../../stitches.config";

const Container = styled("div", {
  padding: "0 $8",
  maxWidth: "$screenMax",
  margin: "0 auto",
});

Container.displayName = "Container";
export default Container;

/**
 * Page wrapper component.
 *
 * This component overwrites some legacy CSS.
 */
export const PageContaier = styled(Container, {
  width: "100%",
  paddingLeft: "0px",
  paddingRight: "0px",
  minHeight: "calc(100vh - 162px)",
  // Overwrites legacy CSS
  color: "$text",
  fontSize: "14px",
  fontFamily: `"Source Sans Pro", sans-serif`,
  // Variants
  variants: {
    withPaddingTop: {
      true: {
        paddingTop: "$4",
      },
      false: {},
    },
  },
});
