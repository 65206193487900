import { styled } from "@stitches/react";
import React, { Suspense, useEffect } from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
// design-system
import { Link } from "../../../components/lib/Link";
import Stack from "../../../components/lib/Stack";
import Text from "../../../components/lib/Text";
// relay
import { SideMenu_viewer$key } from "./__generated__/SideMenu_viewer.graphql";
import { Form } from "../RsForm/Form";
import { useRateSearchFormStore } from "../../../stores/RateSearchForm";
import { RsRecentSearches, RsRecentSearchesLoader } from "../RecentSearches";

const fragment = graphql`
  fragment SideMenu_viewer on Viewer {
    ...FormRateSearch_viewer
    ...RecentSearches_viewer
  }
`;

const Aside = styled("aside", {
  flex: 1,
  height: "calc(100vh - 60px)",
  backgroundColor: "$white",
  containerName: "RsSideMenu",
  containerType: "inline-size",
  minWidth: 380,
  maxWidth: 450,
  overflowY: "auto",
  border: "solid 1px $primaryLight",
  boxShadow: "$lg",
  position: "sticky",
  top: "60px",
  bottom: "0px",
  "@media(max-width: 1200px)": {
    display: "none",
  },
});

type Props = {
  viewer: SideMenu_viewer$key;
};

export function SideMenu({ viewer }: Props) {
  const data = useFragment(fragment, viewer);
  const setIsSideMenu = useRateSearchFormStore((s) => s.setIsSideMenu);

  useEffect(() => {
    setIsSideMenu(true);
    return () => setIsSideMenu(false);
  }, [setIsSideMenu]);

  return (
    <Aside>
      <Form
        isSideMenu
        viewer={data}
        SideMenuHeading={
          <Stack css={{ alignItems: "flex-start", gap: 0, marginBottom: "$2" }}>
            <Text as="h4" noMargin>
              Rate Search
            </Text>
            <Text color="primary">Search to view market rates</Text>
            <Link to="/rate-search">New Rate Search</Link>
          </Stack>
        }
      />
      <Suspense fallback={<RsRecentSearchesLoader pageSize={3} />}>
        <RsRecentSearches viewer={data} pageSize={9} />
      </Suspense>
    </Aside>
  );
}
