import React from "react";
import Inline from "./lib/Inline";
import { FilterDialog, FilterDialogProps } from "./FilterDialog";
import { RadioGroup, RadioGroupItem } from "./lib/RadioGroup";
import { CONTRACT, FTE } from "../constants/rateTypes";

export interface RateTypeFilterDialogProps {
  filtered?: boolean;
  onApply: FilterDialogProps["onApply"];
  onClear: FilterDialogProps["onClear"];
}

export function RateTypeFilterDialog(props: RateTypeFilterDialogProps) {
  return (
    <FilterDialog
      title="Rate Type"
      filtered={props.filtered}
      description={
        <RadioGroup defaultValue="any">
          <Inline css={{ gap: "$2" }}>
            <RadioGroupItem value="any">Any</RadioGroupItem>
            <RadioGroupItem value={CONTRACT.value}>{CONTRACT.label}</RadioGroupItem>
            <RadioGroupItem value={FTE.value}>{FTE.label}</RadioGroupItem>
          </Inline>
        </RadioGroup>
      }
      onApply={props.onApply}
      onClear={props.onClear}
    />
  );
}
