import React, { useState, useCallback, useEffect, useMemo } from "react";
// @ts-ignore
import numeral from "numeral";

import Inline from "../../components/lib/Inline";
import Text from "../../components/lib/Text";
import Icon from "../../components/lib/Icon";
import Button from "../../components/lib/Button";
import { ButtonGroupRight } from "../../components/lib/ButtonGroup";

import SearchAllocatorBuyModal from "./SearchAllocatorBuyModal";
import PaymentOptions from "./PaymentOptions";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogClose,
  DialogDescription,
  DialogActions,
} from "../../components/lib/Dialog";

const PROCESSING_FEE = 0;

function calculateTotal(searchPrice: number, searchPurchaseCount: number) {
  const total = numeral(searchPrice * searchPurchaseCount * (1 + PROCESSING_FEE)).format(
    "0,0.00"
  );

  return total;
}

type PureViewPagerProps = {
  searchPrice: number;
  searchTier: number;
  nextPage: () => void;
  page: number;
};

function PureViewPager(props: PureViewPagerProps) {
  const { page, nextPage, searchTier, searchPrice } = props;

  const [searchPurchaseCount, setSearchPurchaseCount] = useState(0);

  const total = useMemo(
    () => calculateTotal(searchPrice, searchPurchaseCount),
    [searchPrice, searchPurchaseCount]
  );

  if (page === 0) {
    return (
      <SearchAllocatorBuyModal
        searchPrice={searchPrice}
        searchTier={searchTier}
        nextPage={nextPage}
        total={total}
        searchPurchaseCount={searchPurchaseCount}
        setSearchPurchaseCount={setSearchPurchaseCount}
      />
    );
  }

  if (page === 1) {
    return <PaymentOptions searchPurchaseCount={searchPurchaseCount} total={total} />;
  }

  return <></>;
}

type Props = {
  show: boolean;
  searchPrice: number;
  searchTier: number;
  onClose: () => void;
};

export default function SearchPlanMoal(props: Props) {
  const { show, searchPrice, searchTier, onClose } = props;

  const [page, setPage] = useState(0);

  const isFirstPage = page === 0;

  const nextPage = useCallback(() => {
    setPage(page + 1);
  }, [page]);

  const prevPage = useCallback(() => {
    if (isFirstPage) return;

    setPage(page - 1);
  }, [isFirstPage, page]);

  useEffect(() => {
    return setPage(0);
  }, [show]);

  return (
    <Dialog open={show} onOpenChange={onClose}>
      <DialogContent css={{ minWidth: "700px" }}>
        <DialogTitle asChild>
          <Inline css={{ justifyContent: "space-between" }}>
            <Text as="h4">Buy More Searches</Text>
            <DialogClose asChild>
              <Icon icon="times" css={{ cursor: "pointer" }} />
            </DialogClose>
          </Inline>
        </DialogTitle>
        <DialogDescription css={{ backgroundColor: "inherit" }} asChild>
          <PureViewPager
            searchPrice={searchPrice}
            searchTier={searchTier}
            nextPage={nextPage}
            page={page}
          />
        </DialogDescription>
        <DialogActions>
          <ButtonGroupRight
            fill
            css={{ justifyContent: !isFirstPage ? "space-between" : "flex-end" }}
          >
            {!isFirstPage && (
              <Button size="large" onClick={prevPage}>
                Prev
              </Button>
            )}
            <Button size="large" onClick={onClose}>
              Close
            </Button>
          </ButtonGroupRight>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
