import React from "react";

import Grid from "../../../../components/lib/Grid";
import SurveyItemBase from "./SurveyItemBase";
import StatusMessagerBlock from "./StatusMessagerBlock";
import RatesBlock from "./RatesBlock";
import RatesWithFeedback from "../../components/Rates/RatesWithFeedback";
import { REVIEW_STATUS_TYPES } from "../../types";
import { useCreateOrUpdateQuestionsFeedbackAPI } from "../../hooks";

import { useAttemptDataContext } from "../../context/AttemptDataContext";
import QuestionsFeedbackContext, {
  useCreateQuestionsFeedbackContext,
} from "../../context/feedback/QuestionsFeedbackContext";
import ValidationContext, {
  useCreateValidationContext,
} from "../../context/ValidationContext";
import ClassicRatesFeedbackContext, {
  useCreateClassicRatesFeedbackContext,
} from "../../context/feedback/ClassicRatesFeedbackContext";
import { emptyMap } from "../../constants";

export function SurveyItemPageClassic() {
  const { attemptId, updateAttemptData } = useAttemptDataContext();

  // state

  const ratesFeedbackContext = useCreateClassicRatesFeedbackContext();
  const questionsFeedbackContext = useCreateQuestionsFeedbackContext();
  const validationContext = useCreateValidationContext();

  // fetch

  const createOrUpdateQuestionsFeedback = useCreateOrUpdateQuestionsFeedbackAPI();

  // handlers

  const isValidFeedback =
    !ratesFeedbackContext.hasUncommittedChanges &&
    !ratesFeedbackContext.hasMissingRateFeedback &&
    !questionsFeedbackContext.hasMissingRequiredQuestionsFeedback;

  const handleSubmitFeedback = React.useCallback(async () => {
    if (!isValidFeedback) return;

    await createOrUpdateQuestionsFeedback(questionsFeedbackContext.questionsFeedback);
    await updateAttemptData(attemptId, {
      review_status: ratesFeedbackContext.isAllRateCardsSkipped
        ? REVIEW_STATUS_TYPES.SKIPPED
        : REVIEW_STATUS_TYPES.REVIEWED,
    });
  }, [
    isValidFeedback,
    ratesFeedbackContext.isAllRateCardsSkipped,
    questionsFeedbackContext.questionsFeedback,
    attemptId,
    createOrUpdateQuestionsFeedback,
    updateAttemptData,
  ]);

  // render

  const rateCardsList = ratesFeedbackContext.rateResults.toArray().map((rateResult) => {
    const rateResultId = rateResult.get("id");
    return (
      <RatesWithFeedback
        key={rateResultId}
        rateResult={rateResult}
        rateFeedback={ratesFeedbackContext.ratesFeedback.get(rateResultId) ?? emptyMap}
      />
    );
  });

  const rateCardsGrid = (
    <Grid
      css={{
        width: "$full",
        maxWidth: "$full",
        gridGap: "$8",
        alignItems: "center",
        fontSize: "$base",
        gridTemplateColumns: "repeat(1, auto)",
        "@md": {
          gridTemplateColumns: "repeat(2, auto)",
        },
        "@lg": {
          gridTemplateColumns: "repeat(3, auto)",
        },
      }}
    >
      {rateCardsList}
    </Grid>
  );

  const ratesBlock = (
    <RatesBlock>
      {rateCardsGrid}
      <StatusMessagerBlock
        hasUncommittedChanges={ratesFeedbackContext.hasUncommittedChanges}
        hasMissingRatesFeedback={ratesFeedbackContext.hasMissingRateFeedback}
        hasMissingQuestionsFeedback={
          questionsFeedbackContext.hasMissingRequiredQuestionsFeedback
        }
      />
    </RatesBlock>
  );

  return (
    <ClassicRatesFeedbackContext.Provider value={ratesFeedbackContext}>
      <QuestionsFeedbackContext.Provider value={questionsFeedbackContext}>
        <ValidationContext.Provider value={validationContext}>
          <SurveyItemBase
            ratesBlock={ratesBlock}
            hasNoRatesFeedback={!ratesFeedbackContext.ratesFeedback.size}
            hasNoQuestionsFeedback={!questionsFeedbackContext.questionsFeedback.size}
            disableSubmit={!isValidFeedback}
            onSubmitFeedback={handleSubmitFeedback}
          />
        </ValidationContext.Provider>
      </QuestionsFeedbackContext.Provider>
    </ClassicRatesFeedbackContext.Provider>
  );
}

SurveyItemPageClassic.displayName = "SurveyItemPageClassic";

export default SurveyItemPageClassic;
