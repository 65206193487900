import React, { useCallback } from "react";
import { useRecoilState } from "recoil";

import Box from "../../../../components/lib/Box";
import { TickerContentLoader } from "../../../../components/lib/TickerLoader";
import { useVal5KPublicContext } from "../../context/Val5KPublicContext";
import {
  surveyListTableGlobalState,
  type SurveyListTableDataStateObject,
} from "../../globalState";
import { DjangoPaginatedResponse } from "../../../../types/django";
import { FetchAPIResponse } from "../../../../types/fetch";
import { useInitialFetch } from "../../../../utils/hooks";
// @ts-expect-error
import { logAsyncOperationError } from "../../../../utils/logging";
import { transformReviewsData } from "../../dataConverters";
import { djangoPaginationKey, djangoPaginationSizeKey } from "../../../../constants";
import SurveyListTable from "./SurveysListTable";

import type { ReviewDataObject } from "../../types";

const SurveysList = () => {
  const { auth, fetchArgusFilteringAPI, showModalError } = useVal5KPublicContext();

  // state

  const [surveyListTableState, setSurveyListTableState] =
    useRecoilState<SurveyListTableDataStateObject>(surveyListTableGlobalState);
  const { activePage, itemsPerPage, filtersQuery, loaded } = surveyListTableState;

  // data fetch funcs

  const fetchSurveyListData = useCallback(
    async (urlQuery = {}, filtersQuery = {}, nextStateUpdates = {}) => {
      try {
        const response: FetchAPIResponse<DjangoPaginatedResponse<ReviewDataObject>> =
          await fetchArgusFilteringAPI(`surveys/filtered/`, {
            params: {
              _email: auth.email,
              ...urlQuery,
            },
            data: filtersQuery,
          });

        // Transform the the response as per our state, mainly convert to order immutable
        const nextDataState: Partial<SurveyListTableDataStateObject> =
          transformReviewsData(response.data, nextStateUpdates);

        setSurveyListTableState((prevDataState) => ({
          ...prevDataState,
          ...nextDataState,
          loaded: true,
        }));

        return { ...surveyListTableState, ...nextDataState };
      } catch (err: any) {
        logAsyncOperationError("fetchSurveyListData", err);
        showModalError(
          "Error occurred while loading survey list. Please, try again later."
        );
        throw err;
      }
    },
    [
      auth.email,
      surveyListTableState,
      setSurveyListTableState,
      fetchArgusFilteringAPI,
      showModalError,
    ]
  );

  const fetchSurveyListFiltersData = useCallback(
    async (urlQuery = {}, filtersQuery = {}) => {
      try {
        const response: FetchAPIResponse<DjangoPaginatedResponse<any>> =
          await fetchArgusFilteringAPI(`surveys/values/filtered/`, {
            params: {
              _email: auth.email,
              ...urlQuery,
            },
            data: filtersQuery,
          });

        return response.data;
      } catch (err: any) {
        logAsyncOperationError("fetchSurveyListFiltersData", err);
        showModalError(
          "Error occurred while loading filter values. Please, try again later."
        );
        throw err;
      }
    },
    [auth.email, fetchArgusFilteringAPI, showModalError]
  );

  // utils

  const refreshSurveyList = useCallback(async () => {
    return await fetchSurveyListData(
      {
        [djangoPaginationKey]: activePage,
        [djangoPaginationSizeKey]: itemsPerPage,
      },
      filtersQuery.toJS()
    );
  }, [activePage, itemsPerPage, filtersQuery, fetchSurveyListData]);

  // effects

  useInitialFetch(refreshSurveyList);

  // render

  if (!loaded) {
    return (
      <Box fill css={{ minHeight: "200px", position: "relative" }}>
        <TickerContentLoader />
      </Box>
    );
  }

  return (
    <SurveyListTable
      dataProvider={fetchSurveyListData}
      filtersDataProvider={fetchSurveyListFiltersData}
    />
  );
};

SurveysList.displayName = "SurveysList";

export default SurveysList;
