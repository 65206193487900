import React from "react";
// relay
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { RateDetail_savedsearch$key } from "./__generated__/RateDetail_savedsearch.graphql";
// lib
import Icon from "../../../../components/lib/Icon";
import Inline from "../../../../components/lib/Inline";
import Stack from "../../../../components/lib/Stack";
import Button from "../../../../components/lib/Button";
import { IconToggle } from "../../../../components/lib/Toggle";
// store
import { MarketRateType, useSearchResultsStore } from "../../../../stores/SearchResults";
// components
import RsLevelsGuideDialog from "../../components/RsLevelsGuideDialog";
import { DetailCards } from "./DetailCards";
import { DetailTable } from "./DetailTable";
import Tooltip from "../../../private_index/lib/Tooltip";

const fragment = graphql`
  fragment RateDetail_savedsearch on SaveSearchNode {
    id
    rateType
    ...DetailTable_savedsearch
    ...DetailCards_savedsearch
  }
`;

type Props = {
  savedsearch: RateDetail_savedsearch$key;
};

export function RateDetail({ savedsearch }: Props) {
  const uiState = useSearchResultsStore((s) => s.searchDetailsUI);
  const setUiState = useSearchResultsStore((s) => s.setSearchDetailsUI);

  const data = useFragment(fragment, savedsearch);
  const isContract = data?.rateType === 1;

  return (
    <Stack css={{ alignItems: "flex-start", width: "100%" }}>
      <Inline
        css={{
          justifyContent: "space-between",
          width: "100%",
          gap: "$64",
          flexWrap: "nowrap",
        }}
      >
        {uiState.view === "Card View" && isContract ? (
          <Inline>
            <Button
              color="brand"
              onClick={() => setUiState({ tabId: "Pay Rate" })}
              variant={uiState.tabId === "Pay Rate" ? "filled" : "outlined"}
            >
              Pay Rate
            </Button>
            <Button
              color="brand"
              onClick={() => setUiState({ tabId: "Markup" })}
              variant={uiState.tabId === "Markup" ? "filled" : "outlined"}
            >
              Markup
            </Button>
            <Button
              onClick={() => setUiState({ tabId: "Bill Rate" })}
              color="brand"
              variant={uiState.tabId === "Bill Rate" ? "filled" : "outlined"}
            >
              Bill Rate
            </Button>
          </Inline>
        ) : (
          <span />
        )}

        <Inline>
          <Tooltip content="Details table">
            <IconToggle
              icon="table"
              onClick={() => setUiState({ view: "Table View" })}
              active={uiState.view === "Table View"}
            />
          </Tooltip>
          <Tooltip content="Cards breakdown">
            <IconToggle
              icon="th"
              onClick={() => setUiState({ view: "Card View" })}
              active={uiState.view === "Card View"}
            />
          </Tooltip>
          <RsLevelsGuideDialog>
            <Button variant="text">
              <Icon icon="info-circle" css={{ cursor: "pointer", fontSize: "$xl" }} />
            </Button>
          </RsLevelsGuideDialog>
        </Inline>
      </Inline>

      {uiState.view === "Table View" && <DetailTable savedsearch={data} />}

      {uiState.view === "Card View" && (
        <DetailCards
          savedsearch={data}
          selectedMarketRateType={uiState.tabId.replace(" ", "") as MarketRateType}
        />
      )}
    </Stack>
  );
}
