// @flow
import { extendObservable, action } from "mobx";
import FilterObject, { ValueSetFilter, FilterWithSort, IViewItem } from "../Filter";
import type { FilterColumn } from "../Filter";
import Sort from "../Sort";
import SortState from "../SortState";

class DraftCriterionItem implements IViewItem {
  id: string;
  value: string;
  selected: string;
  draft: boolean;

  constructor(object: Object) {
    this.id = object.id;
    this.value = object.value;
    this.draft = object.draft;

    extendObservable(this, {
      selected: object.selected,
    });
  }
}

export default class DraftFilter extends ValueSetFilter(FilterWithSort) {
  constructor(
    store: Object,
    column: FilterColumn,
    applyFilter: (FilterColumn, FilterObject) => void,
    applySort: (FilterColumn, Sort) => void,
    removeFilter: (FilterColumn) => void,
    removeSort: (FilterColumn) => void
  ) {
    super(store, column);

    this.allowMultipleItemSelection = false; // only one item selected at a time

    this.applyFilter = applyFilter;
    this.removeFilter = removeFilter;
    this.applySort = applySort;
    this.removeSort = removeSort;

    this.sortState = new SortState("IS_DRAFT");

    this.viewItemClass = DraftCriterionItem;

    // create criteria and view items
    this.criteria.set("1", { id: "1", value: "Draft", draft: true });
    this.criteria.set("2", { id: "2", value: "Not Draft", draft: false });

    extendObservable(this, {
      viewItems: this.buildViewItems(this.criteria),
    });

    this.buildQueryFilter = action(this.buildQueryFilter.bind(this));
  }

  buildQueryFilter() {
    const draftSelectedValues = this.viewItems
      .filter((item) => item.selected)
      .map((item) => item.draft);

    if (!draftSelectedValues.length) {
      // no value selected, just return
      return null;
    }

    if (draftSelectedValues.length > 1) {
      // NOTE: Both Yes/No selected, just return
      console.error("Unable to build Rate Type filter: More than one value selected");
      return null;
    }

    const draft = draftSelectedValues[0];

    if (this.filter && this.filter.queryVars.draft === draft) return this.filter; // no change

    return new FilterObject("$isDraft: Boolean!", "isDraft: $isDraft", {
      isDraft: draft,
    });
  }
}
