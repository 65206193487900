import React from "react";
import { Alert } from "react-bootstrap";
import DocumentTitle from "react-document-title";
import Button from "../../components/lib/Button";
import Box from "../../components/lib/Box";

// For now this is just a really stupid HoC.
export default class NotFound extends React.Component {
  goBack = () => {
    if (this.props.router.getCurrentLocation().pathname === "/404NotFound") {
      window.history.go(-1);
    }
    this.props.router.goBack();
  };

  render() {
    return (
      <div className="row-fluid error-page">
        <DocumentTitle title="404 - Route Not Found" />
        <div className="col-md-12">
          <Alert bsStyle="danger">
            <h1>404 - Page Not Found</h1>
            <p>Sorry, could not find a page at this URL.</p>
            <Box css={{ paddingTop: "$4" }}>
              <Button
                size="normal"
                icon="arrow-left"
                text="Back"
                css={{ minWidth: 100 }}
                onClick={this.goBack}
              />
            </Box>
          </Alert>
        </div>
      </div>
    );
  }
}
