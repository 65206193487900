// @flow
import React, { Component } from "react";
import type { Element } from "react";
import { Dropdown, MenuItem } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AutosizeInput from "react-input-autosize";
import classNames from "classnames";
import { TagValue } from "./MultiDownshiftComponents";

type Props = {
  items: any[],
  selectedItems: any[],
  inputValue: string,
  itemToString: (any) => string,
  onInputValueChange: (string) => void,
  onItemAdd: (any) => void,
  onItemRemove: (any) => void,
  renderItem?: (any) => Element<any>,
};

type State = {
  isOpen: boolean,
};

class MultiDownshift extends Component<Props, State> {
  _input: ?HTMLInputElement;
  _dropdown: ?HTMLElement;

  onItemClick: (any, Event, number) => void;
  onInputChange: (SyntheticEvent<HTMLInputElement>) => void;
  onTagBlur: (Event, any) => void;
  onRemoveTag: (any) => void;
  onInputKeyDown: (Event) => void;
  onDropDownKeyDown: (Event) => void;
  onWrapperClick: (SyntheticMouseEvent<HTMLElement>) => void;
  popValue: () => void;
  focusOnInput: () => void;
  inputRef: (?HTMLInputElement) => void;
  _handleDocumentClick: (SyntheticMouseEvent<HTMLElement>) => void;

  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onTagBlur = this.onTagBlur.bind(this);
    this.onRemoveTag = this.onRemoveTag.bind(this);
    this.onInputKeyDown = this.onInputKeyDown.bind(this);
    this.onDropDownKeyDown = this.onDropDownKeyDown.bind(this);
    this.onWrapperClick = this.onWrapperClick.bind(this);
    this.onItemClick = this.onItemClick.bind(this);
    this.popValue = this.popValue.bind(this);
    this.focusOnInput = this.focusOnInput.bind(this);
    this.inputRef = this.inputRef.bind(this);
    this._handleDocumentClick = this._handleDocumentClick.bind(this);
  }

  componentDidMount() {
    window.document.addEventListener("click", this._handleDocumentClick);
  }

  componentWillUnmount() {
    window.document.removeEventListener("click", this._handleDocumentClick);
  }

  _handleDocumentClick(e: SyntheticMouseEvent<HTMLElement>) {
    // $FlowFixMe ignoring e.target type error
    if (!this._dropdown.contains(e.target)) {
      this.setState({ isOpen: false });
    }
  }

  onItemClick(item: any, event: Event, itemIndex: number) {
    const isSelected = this.props.selectedItems.includes(item);
    if (!isSelected && this.props.onItemAdd) {
      this.props.onItemAdd(item);
    } else if (isSelected && this.props.onItemRemove) {
      this.props.onItemRemove(item);
    }
  }

  onInputChange(event: SyntheticEvent<HTMLInputElement>) {
    if (this.props.onInputValueChange) {
      this.props.onInputValueChange(event.currentTarget.value);
    }
  }

  onInputFocus = () => {
    this.setState({ isOpen: true });
  };

  onTagBlur(e: Event, item: any) {
    // we don't need this for now
    // const { onItemChanged } = this.props;
    // if (onItemChanged) {
    //   onItemChanged(item);
    // }
  }

  onRemoveTag(tagItem: any) {
    this.props.onItemRemove(tagItem.item);
  }

  onInputKeyDown(event: Event & { keyCode: number }) {
    switch (event.keyCode) {
      case 8: // backspace
        if (!this.props.inputValue) {
          event.preventDefault();
          this.popValue();
        }
        return;
      case 9: // tab
        // we don't need this for now
        // if (this.props.inputValue && this.props.onItemAdd) {
        //   this.props.onItemAdd(this.props.inputValue);
        // }
        //
        // event.preventDefault();
        // event.stopPropagation();
        if (this.props.inputValue && this.props.onInputValueChange) {
          this.props.onInputValueChange("");
        }
        this.setState({ isOpen: false });
        return;
      case 27: // esc
        if (this.props.inputValue && this.props.onInputValueChange) {
          this.props.onInputValueChange("");
        }

        this.setState({ isOpen: false });
        return;
      case 46: // delete
        // we don't need this for now
        // if (!this.props.inputValue) {
        //   event.preventDefault();
        //   this.popValue();
        // }
        return;
      case 188: // comma
        // we don't need this for now
        // if (!this.props.inputValue) {
        //   event.preventDefault();
        // } else if (this.props.onItemAdd) {
        //   this.props.onItemAdd(this.props.inputValue);
        // }
        break;
      default:
        const exceptKeys = [
          16, // shift
          17, // ctrl
          18, // alt/opt
          91, // Windows Key / Left ⌘ / Chromebook Search key
          93, // Windows Menu / Right ⌘
        ];

        if (!exceptKeys.includes(event.keyCode)) {
          this.setState({ isOpen: true });
        }
        return;
    }
    event.preventDefault();
  }

  onDropDownKeyDown(event: Event & { keyCode: number, key: string }) {
    switch (event.keyCode) {
      case 8: // backspace
        this.focusOnInput();
        break;
      case 9: // tab
        if (this.props.inputValue && this.props.onInputValueChange) {
          this.props.onInputValueChange("");
        }
        this.setState({ isOpen: false });
        return;
      case 27: // Escape
        this.setState({ isOpen: false });
        this.focusOnInput();
        if (this.props.onInputValueChange) {
          this.props.onInputValueChange("");
        }
        break;
      default: // means Enter, ArrowDown, Delete, etc...
        if (event.key.length > 1) return;
        if (event.key.match(/[a-zA-Z0-9\-_,.'"`]/g)) {
          this.focusOnInput();
          return;
        }
        break;
    }
  }

  popValue() {
    const { selectedItems, onItemRemove } = this.props;
    if (onItemRemove) {
      onItemRemove(selectedItems[selectedItems.length - 1]);
    }
  }

  onWrapperClick(e: SyntheticMouseEvent<HTMLElement>) {
    // $FlowFixMe ignoring e.target.id missing id property error
    if (e.target.id === "multi-downshift-dropdown" || e.target === this._input) {
      this.focusOnInput();
      e.stopPropagation();
      e.preventDefault();
    }
  }

  focusOnInput() {
    if (!this._input) return;

    this._input.focus();
    // $FlowFixMe ignoring this._input.getInput missing getInput property error
    if (typeof this._input.getInput === "function") {
      this._input.getInput().focus();
      this.setState({ isOpen: true });
    }
  }

  inputRef(c: HTMLInputElement) {
    this._input = c;
  }

  render() {
    const { itemToString, items, selectedItems, renderItem } = this.props;

    const _inputProps = {
      value: this.props.inputValue,
      ref: this.inputRef,
      inputClassName: "multi-downshift-input",
      onChange: this.onInputChange,
      onKeyDown: this.onInputKeyDown,
      onFocus: this.onInputFocus,
    };

    return (
      <div
        style={{ width: "100%" }}
        ref={(element: ?HTMLElement) => {
          this._dropdown = element;
        }}
      >
        <Dropdown
          id="multi-downshift-dropdown"
          className="multi-downshift-dropdown"
          open={this.state.isOpen}
          onToggle={(e) => {}}
        >
          <div
            bsRole="toggle"
            className="ds-input-wrapper"
            onClick={this.onWrapperClick}
            data-testid="country-dropdown"
          >
            {selectedItems.map((item, index) => {
              const tag = {
                value: itemToString(item),
                element: renderItem ? renderItem(item) : itemToString(item),
                index,
                item,
              };
              return (
                <TagValue
                  key={`Tag-${tag.index}`}
                  onBlur={this.onTagBlur}
                  onRemove={this.onRemoveTag}
                  tag={tag}
                />
              );
            })}
            <AutosizeInput {..._inputProps} />
          </div>

          <Dropdown.Menu onKeyDown={this.onDropDownKeyDown}>
            {items.map((item, index) => {
              const isSelected = selectedItems.includes(item);
              return (
                <MenuItem
                  key={`item-${index}`}
                  eventKey={index}
                  onSelect={this.onItemClick.bind(this, item)}
                >
                  <span
                    className={classNames("cjl-detail-menu-item", {
                      "is-active": isSelected,
                    })}
                  >
                    <FontAwesomeIcon
                      icon={"check"}
                      fixedWidth
                      style={{ visibility: isSelected ? "visible" : "hidden" }}
                      className="icon"
                    />{" "}
                    {renderItem ? renderItem(item) : itemToString(item)}
                  </span>
                </MenuItem>
              );
            })}
            {!items.length && (
              <MenuItem key="no-results-item" eventKey={0} disabled>
                <span style={{ padding: "0 20px" }}>-- No items --</span>
              </MenuItem>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}

export default MultiDownshift;
