import React, { useState, useCallback } from "react";

// @ts-expect-error
import Messager from "../../../components/Messager";
import { usePLIContext } from "../context";
import PromiseButton from "../../../components/lib/PromiseButton";
import Icon from "../../../components/lib/Icon";
import TextInput from "../../../components/lib/TextInput";
import Box from "../../../components/lib/Box";
import Stack from "../../../components/lib/Stack";
import Inline from "../../../components/lib/Inline";
import Text from "../../../components/lib/Text";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogClose,
  DialogDescription,
  DialogActions,
} from "../lib/Dialog";
import { ButtonGroupRight } from "../../../components/lib/ButtonGroup";
import { Button } from "../../../components/lib/Button";
import {
  Checkbox,
  CheckboxIndicator,
  LabelInline,
} from "../../../components/lib/Checkbox";
import { useMessagerRef } from "../hooks";

import type { ImmutableMap } from "../../../types/immutable";

type CreateViewFunc = (
  title: string,
  isVisible: boolean
) => Promise<ImmutableMap<any> | undefined>;

type SaveIndexFormProps = {
  show: boolean;
  modalTitle?: string | null;
  onClick: CreateViewFunc;
  onClose: () => void;
};

type SaveIndexFormState = {
  title: string | null;
  isVisible: boolean;
};

function SaveIndexForm(props: SaveIndexFormProps) {
  const { show, onClick, onClose } = props;

  const { showModalSuccess } = usePLIContext();

  const modalTitle = props.modalTitle || "Create a new View";

  // state

  const [state, setState] = useState<SaveIndexFormState>({
    title: null,
    isVisible: true,
  });

  const resetState = React.useCallback(
    () =>
      setState({
        title: null,
        isVisible: true,
      }),
    []
  );

  // messager

  const { messagerRef } = useMessagerRef();

  // handlers

  const handleModalSuccess = useCallback(() => {
    showModalSuccess(
      <span>
        View <b>"{state.title}"</b> has just been created.
      </span>
    );
  }, [state.title, showModalSuccess]);

  const handleResetAndClose = useCallback(() => {
    resetState();
    onClose();
  }, [resetState, onClose]);

  const handleCreateView = useCallback(async () => {
    if (!state.title) {
      return;
    }

    const data = await onClick(state.title, state.isVisible);
    if (data && data.size > 0) {
      handleResetAndClose();
      handleModalSuccess();
    }
  }, [state.title, state.isVisible, onClick, handleResetAndClose, handleModalSuccess]);

  const handleTitleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setState((prevState) => ({ ...prevState, title: value }));
  }, []);

  const handleIsVisibleChange = useCallback(
    (value: boolean) =>
      setState((prevState) => ({
        ...prevState,
        isVisible: value,
      })),
    []
  );

  return (
    <Dialog open={show} onOpenChange={handleResetAndClose}>
      <DialogContent>
        <DialogTitle asChild>
          <Inline css={{ justifyContent: "space-between", minWidth: "500px" }}>
            <Box>
              <Text as="h4">{modalTitle}</Text>
            </Box>
            <DialogClose asChild>
              <Icon icon="times" />
            </DialogClose>
          </Inline>
        </DialogTitle>
        <DialogDescription asChild>
          <Stack nogap css={{ fontSize: "$sm", alignItems: "flex-start" }}>
            <label>Name: </label>
            <TextInput
              fill
              size="small"
              css={{ marginBottom: "$2" }}
              value={state.title || ""}
              onChange={handleTitleChange}
            />
            <LabelInline>
              <Checkbox checked={state.isVisible} onCheckedChange={handleIsVisibleChange}>
                <CheckboxIndicator>
                  <Icon icon="check" />
                </CheckboxIndicator>
              </Checkbox>
              make view visible for other users
            </LabelInline>
            <Messager ref={messagerRef} />
          </Stack>
        </DialogDescription>
        <DialogActions>
          <ButtonGroupRight fill css={{ flexDirection: "row-reverse" }}>
            <Button size="large" onClick={handleResetAndClose}>
              Close
            </Button>
            <PromiseButton
              size="large"
              color="brand"
              loadingText="Create View"
              disabled={!state.title}
              onClick={handleCreateView}
            >
              Create View
            </PromiseButton>
          </ButtonGroupRight>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

SaveIndexForm.displayName = "SaveIndexForm";

export default SaveIndexForm;
