// @flow

import React from "react";
import { observer } from "mobx-react";
import { BatchSearchPSPListStore } from "../../../stores/mobx/BatchSearchListStore";
import {
  CardActions,
  CardActionsLeft,
  CardActionsRight,
} from "../../../components/lib/Card";
import SearchBox from "../../../components/lib/SearchBox";
import Button from "../../../components/lib/Button";
import LinkButton from "../../../components/lib/LinkButton";

const BatchSearchListHeader = (props: {
  batchSearchListStore: BatchSearchPSPListStore,
}) => {
  const batchSearchListStore = props.batchSearchListStore;

  return (
    <div className="header-action-bar marginTopzero">
      <CardActions>
        <CardActionsLeft>
          <SearchBox
            value={batchSearchListStore.batchSearchRateCardLabelFilter.textToLookFor}
            onChange={
              batchSearchListStore.batchSearchRateCardLabelFilter.onTextToLookForChange
            }
            onSubmit={batchSearchListStore.batchSearchRateCardLabelFilter.onApply}
            css={{ width: "260px" }}
          />
        </CardActionsLeft>
        <CardActionsRight>
          {batchSearchListStore.isEditing && (
            <Button color="accent" onClick={batchSearchListStore.handleStopEdit}>
              Stop Editing
            </Button>
          )}
          {!batchSearchListStore.isEditing && (
            <>
              <Button
                color="brand"
                icon="edit"
                onClick={batchSearchListStore.handleStartEdit}
              >
                Edit
              </Button>
              <LinkButton to="/batch-searches/create" color="brand" icon="plus">
                New Batch Search
              </LinkButton>
              <Button
                icon="question-circle"
                onClick={batchSearchListStore.helpModal.showModal}
              >
                Help
              </Button>
            </>
          )}
        </CardActionsRight>
      </CardActions>
    </div>
  );
};

export default observer(BatchSearchListHeader);
