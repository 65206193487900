import React, { useCallback, useState } from "react";
import { useInitialFetch } from "../../../../utils/hooks";
import { FetchAPIResponse } from "../../../../types/fetch";
import { useVal5KPublicContext } from "../../context/Val5KPublicContext";
import { reviewsListToImmutableOrderedMap } from "../../dataConverters";
import {
  ReviewDataObject,
  ReviewsDataOrderedMap,
} from "../../../validator5K_admin/types";
import { emptyOrderedMap } from "../../../../constants";
// @ts-expect-error
import { logAsyncOperationError } from "../../../../utils/logging";
import SurveysPeckingOrderTable from "./SurveysPeckingOrderTable";
import Box from "../../../../components/lib/Box";
import { TickerContentLoader } from "../../../../components/lib/TickerLoader";
import { useReviewDataContext } from "../../../validator5K_admin/context/ReviewDataContext";

const SurveysPeckingOrderList = () => {
  const { fetchArgusFilteringAPI, showModalError, auth } = useVal5KPublicContext();
  const { reviewId, reviewsGroupId } = useReviewDataContext();
  const { email } = auth ?? {};

  //  state

  const [loading, setLoading] = useState<boolean>(true);
  const [reviewsData, setReviewsData] = useState<ReviewsDataOrderedMap>(
    emptyOrderedMap as ReviewsDataOrderedMap
  );

  // data fetch methods

  const fetchSurveysPeckingOrderList = useCallback(async () => {
    try {
      const response: FetchAPIResponse<ReviewDataObject[]> = await fetchArgusFilteringAPI(
        `surveys/grouped/filtered/`,
        {
          params: {
            email,
            reviews_group_id: reviewsGroupId,
          },
          data: {},
        }
      );
      const data = reviewsListToImmutableOrderedMap(response.data);
      setReviewsData(data);
    } catch (err: any) {
      logAsyncOperationError("fetchSurveyPeckingOrderList", err);
      showModalError(
        "Error occurred while loading surveys list. Please, try again later."
      );
      throw err;
    } finally {
      setLoading(false);
    }
  }, [email, reviewsGroupId, fetchArgusFilteringAPI, showModalError]);

  // effects

  useInitialFetch(fetchSurveysPeckingOrderList);

  // render

  if (loading) {
    return (
      <Box css={{ minHeight: "200px", position: "relative" }}>
        <TickerContentLoader />
      </Box>
    );
  }

  return (
    <SurveysPeckingOrderTable
      reviewId={reviewId}
      reviewsGroupId={reviewsGroupId}
      reviewsData={reviewsData}
    />
  );
};

SurveysPeckingOrderList.displayName = "SurveysPeckingOrderList";

export default SurveysPeckingOrderList;
