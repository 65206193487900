import React from "react";
import Stack from "../../../../components/lib/Stack";
import Tag from "../../../../components/lib/Tag";
import { PayDifferenceSet } from "../../hooks/usePayDifference";
import { styled } from "@stitches/react";
import classNames from "classnames";
import { useGlobalContext } from "../../../../globalContext";
import { ModifyLocationsDialog } from "./ModifyLocationsDialog";
import { Placeholder } from "../../../../components/lib/Placeholder";
import { ModifyLocationsDialog_viewer$key } from "./__generated__/ModifyLocationsDialog_viewer.graphql";

// styled

const LocationBtn = styled("button", {
  padding: "$1 $2",
  background: "none",
  border: "solid 1px transparent",
  borderRadius: "$rounded",
  color: "$brand",
  width: "100%",
  fontSize: "$sm",
  display: "flex",
  gap: "$6",
  justifyContent: "space-between",
  alignItems: "center",
  transition: "all 300ms ease",
  cursor: "help",
  "& span.location-name": {
    textAlign: "left",
  },
  "&.active": {
    backgroundColor: "$brandLightest",
    border: "solid 1px $brandLighter",
  },
});

const Wrapper = styled(Stack, {
  alignItems: "flex-start",
  justifyContent: "space-between",
});

// INTERNALS

function getLocation(node: PayDifferenceSet[number]) {
  if (node.region) {
    return node.region.name;
  }
  return node.locationFullLabel;
}

type Props = {
  activeLocationId: number;
  viewer: ModifyLocationsDialog_viewer$key;
  searches: PayDifferenceSet;
  setActiveLocationId(newLocation: number): void;
  setSearchDetails(): void;
};

export function PayDifferenceList(props: Props) {
  const { searches, setActiveLocationId, activeLocationId, setSearchDetails, viewer } =
    props;

  const { perSearchPricing } = useGlobalContext();

  return (
    <Wrapper fill>
      <Stack fill css={{ gap: "$2" }}>
        {searches.map((node) => (
          <LocationBtn
            key={node.key}
            className={classNames(
              !node.region && node.locationId === activeLocationId && "active"
            )}
            onMouseEnter={() => {
              if (node.locationId) setActiveLocationId(node.locationId);
            }}
            onMouseLeave={() => {
              if (node.locationId) setActiveLocationId(0);
            }}
          >
            <span className="location-name">{getLocation(node)}</span>
            <Tag
              icon={
                node.payDifference > 0
                  ? "chevron-up"
                  : node.payDifference < 0
                  ? "chevron-down"
                  : "minus"
              }
              size="extra-small"
              color={
                node.payDifference > 0
                  ? "danger"
                  : node.payDifference < 0
                  ? "success"
                  : "brand"
              }
            >
              <span style={{ width: "10%", textAlign: "right" }}>
                {node.payDifference >= 0 && <span>{node.payDifference}%</span>}
                {node.payDifference < 0 && <span>{node.payDifference * -1}%</span>}
              </span>
            </Tag>
          </LocationBtn>
        ))}
      </Stack>

      {!perSearchPricing && (
        <ModifyLocationsDialog viewer={viewer} setSearchDetails={setSearchDetails} />
      )}
    </Wrapper>
  );
}

export function PayDifferenceListLoader() {
  return (
    <Wrapper fill>
      <Stack fill css={{ gap: "$4", padding: "$4" }}>
        <Placeholder width="100%" height={16} />
        <Placeholder width="100%" height={16} />
        <Placeholder width="100%" height={16} />
        <Placeholder width="100%" height={16} />
      </Stack>
    </Wrapper>
  );
}
