import React from "react";

import Text from "../../../../components/lib/Text";
import { TR, TD } from "../../../../components/lib/Table";
import {
  decimalFormatter,
  percentFormatter,
  getFilteredValueTypesDesc,
  emptyMap,
} from "../../constants";
import {
  RATE_TYPES,
  RATE_TYPES_LABELS,
  SKILLS_LEVELS_3L_NUMERAL_LABELS,
  SKILLS_LEVELS_NUMERAL_LABELS,
} from "../../types";
import { getExpLevelInfo } from "./utils";
import { RatesBase, RatesTitleBase } from "./RatesBase";

import type {
  RatesDataKey,
  RatesFeedbackDataMap,
  SKILLS_LEVELS_3L_TYPE,
  SKILLS_LEVELS_TYPE,
} from "../../types";
import { RatesProps, RatesTitleProps, RatesRowProps } from "./types";
import { useReviewDataContext } from "../../context/ReviewDataContext";
import { useRateDisplayContext } from "../../context/RateDisplayContext";

function RatesRow(props: RatesRowProps) {
  const { valueSubtype: valueType, valueSubtypeTitle: title, rateResult } = props;

  const { firstJobRateType, requiredRates } = useReviewDataContext();
  const { resultingCurrencySymbol, convertToDisplayCurrency, convertToDisplayRateType } =
    useRateDisplayContext();

  return (
    <TR>
      <TD>{title}</TD>
      {getFilteredValueTypesDesc(firstJobRateType, requiredRates.toJS()).map(
        ({ type }) => {
          const key = [type, valueType].join("_") as unknown as RatesDataKey;
          let value: number | null = rateResult.get(key);

          if (type === "pay_rate" || type === "bill_rate") {
            if (firstJobRateType !== RATE_TYPES.ANNUAL) {
              value = convertToDisplayRateType(value);
            }
            value = convertToDisplayCurrency(value);
          }

          return (
            <TD key={type}>
              <Text>
                {type === "markup"
                  ? percentFormatter(value, false, "%")
                  : decimalFormatter(value, false, resultingCurrencySymbol)}
              </Text>
            </TD>
          );
        }
      )}
    </TR>
  );
}

RatesRow.displayName = "RatesRow";

function RatesTitle(props: RatesTitleProps) {
  const { expLevel } = props;

  const {
    firstJobCurrencyData,
    firstJobRateType: rateType,
    firstJobRateMultiplier: rateMultiplier,
    isThreeLevelBanding,
    displayCurrencyData: reviewCurrencyData,
  } = useReviewDataContext();
  const { displayRateType, displayRateMultiplier, displayCurrencyData } =
    useRateDisplayContext();

  // resolve currency

  const resultingCurrencyData = displayCurrencyData.size
    ? displayCurrencyData
    : reviewCurrencyData.size
    ? reviewCurrencyData
    : firstJobCurrencyData;
  const resultingCurrencyName = resultingCurrencyData.get("name");

  // resolve rate type

  let rateTypeLabel: string | React.ReactElement = RATE_TYPES_LABELS[rateType];

  if (
    rateType === RATE_TYPES.DAILY ||
    rateType === RATE_TYPES.WEEKLY ||
    rateType === RATE_TYPES.MONTHLY
  ) {
    rateTypeLabel = (
      <Text>
        {RATE_TYPES_LABELS[rateType]}
        {rateMultiplier != null ? ` (${rateMultiplier}h)` : ""}
      </Text>
    );
  }

  if (
    displayRateType === RATE_TYPES.HOURLY ||
    displayRateType === RATE_TYPES.DAILY ||
    displayRateType === RATE_TYPES.WEEKLY ||
    displayRateType === RATE_TYPES.MONTHLY
  ) {
    rateTypeLabel = (
      <Text>
        {RATE_TYPES_LABELS[displayRateType]}
        {displayRateMultiplier != null && displayRateMultiplier > 1
          ? ` (${displayRateMultiplier}h)`
          : ""}
      </Text>
    );
  }

  // resolve Exp level

  const expLevelLabel = isThreeLevelBanding
    ? SKILLS_LEVELS_3L_NUMERAL_LABELS[expLevel as SKILLS_LEVELS_3L_TYPE]
    : SKILLS_LEVELS_NUMERAL_LABELS[expLevel as SKILLS_LEVELS_TYPE];
  const expLevelInfo = getExpLevelInfo(expLevel, isThreeLevelBanding);

  return (
    <RatesTitleBase
      isThreeLevelBanding={isThreeLevelBanding}
      expLevelLabel={expLevelLabel}
      expLevelInfo={expLevelInfo}
      rateTypeLabel={rateTypeLabel}
      currencyLabel={resultingCurrencyName}
    />
  );
}

RatesTitle.displayName = "RatesTitle";

export default function Rates(props: RatesProps) {
  const { rateResult } = props;
  const { requiredRates, firstJobRateType } = useReviewDataContext();

  return (
    <RatesBase
      rateResult={rateResult}
      rateFeedback={emptyMap as RatesFeedbackDataMap}
      rateType={firstJobRateType}
      requiredRates={requiredRates}
      ratesTitleImpl={RatesTitle}
      ratesRowImpl={RatesRow}
    />
  );
}

Rates.displayName = "Rates";
