// @flow strict

// TODO: Implement word list based passwords

export default function randPasswordStr(minLength = 8) {
  const characterSets = [
    "ABCDEFGHIJKLMNOPQRSTUVWXYZ", // uppercase letters
    "abcdefghijklmnopqrstuvwxyz", // lowercase letters
    "0123456789", // numbers
    `!"#$%&'()*+,-./:;<=>?@[\\]^_\`{|}~`, // special characters
  ];

  let password = "";

  // Helper function to shuffle a string
  function shuffleString(str) {
    let array = str.split("");
    let currentIndex = array.length;
    let temporaryValue, randomIndex;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array.join("");
  }

  // Generate characters randomly until the password reaches the minimum length of 8
  while (password.length < minLength) {
    let randomSetIndex = Math.floor(Math.random() * characterSets.length);
    let randomSet = characterSets[randomSetIndex];
    let randomChar = randomSet[Math.floor(Math.random() * randomSet.length)];
    password += randomChar;
  }

  // Shuffle the password characters for added randomness
  password = shuffleString(password);

  return password;
}
