// @flow
import FilterObject from "../Filter";
import RateTypeFilter from "./RateTypeFilter";

export default class HasLocationTypeFilter extends RateTypeFilter {
  buildQueryFilter() {
    const rateTypes = this.viewItems
      .filter((item) => item.selected)
      .map((item) => item.value);

    if (!rateTypes.length) {
      // console.error('Unable to build Rate Type filter: No value selected');
      return null;
    }

    if (rateTypes.length > 1) {
      // NOTE: Both Hourly/Annual selected, just return
      console.error("Unable to build Rate Type filter: More than one value selected");
      return null;
    }

    if (this.filter && this.filter.queryVars.rateType === rateTypes[0])
      return this.filter;

    return new FilterObject("$locationTypeId: Int", "locationTypeId: $locationTypeId", {
      locationTypeId: rateTypes[0] === "City" ? 1 : 2,
    });
  }
}
