// @flow
import * as React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBox from "../../components/lib/SearchBox";
import ProjectCostEstimateList from "./components/ProjectCostEstimatesList";
import Modal from "../ratecards/components/Modal";
import CheckBox from "../shared/CheckBox";
import ApplyTagFilterModal from "../shared/modals/ApplyTagFilterModal";
import ContainerSection from "../ratecards/components/ContainerSection";
import type MobXStore from "../../stores/mobx/MobXStore";
import Button from "../../components/lib/Button";
import LinkButton from "../../components/lib/LinkButton";
import {
  Card,
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubTitle,
  CardActionsLeft,
  CardActionsRight,
  CardActions,
} from "../../components/lib/Card";
import Container from "../../components/lib/Container";
import EditActions from "../../components/lib/EditActions";
import { ModalButtons, ModalTitle } from "../ratecards/components/FilterModal";
import TextInput from "../../components/lib/TextInput";
import Divider from "../../components/lib/Divider";

type Props = {
  store: MobXStore,
};

class List extends React.Component<Props, null> {
  componentDidMount() {
    this.props.store.projectCostEstimateStore.pagination.goFetch();

    // reset to null so some elements visible while editing start hidden
    if (!this.props.store.projectCostEstimateStore.isTagView) {
      this.props.store.projectCostEstimateStore.isEditing = null;
    }

    // clean rate card details view state
    this.props.store.projectCostEstimateStore.projectCostId = null;
    this.props.store.projectCostEstimateStore.projectCost = null;
  }

  /**
   * componentWillUnmount
   * @return {void}
   */
  componentWillUnmount() {
    this.props.store.projectCostEstimateStore.clearFilters();
    this.props.store.projectCostEstimateStore.clearAllSelections();
    this.props.store.projectCostEstimateStore.applyDefaultSort();
  }
  /**
   * Render project Cost list page
   * @return {XML}
   */
  render() {
    const store = this.props.store.projectCostEstimateStore;
    const selectedExcel = store.selectedExcel;
    const selectedCSV = store.selectedCSV;

    return (
      <Container
        css={{
          paddingTop: "$4",
          width: "100%",
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <Card css={{ zIndex: 2 }}>
          {!store.isTagView && (
            <CardHeader>
              <CardHeaderTitle>Project Cost Estimate</CardHeaderTitle>
              <CardHeaderSubTitle>
                Project estimates can be created from the punchouts / buy rates from your
                ratecards.
              </CardHeaderSubTitle>
            </CardHeader>
          )}
          <div className="header-action-bar marginTopzero">
            <CardActions>
              <CardActionsLeft>
                <SearchBox
                  value={store.projectCostFilter.textToLookFor}
                  onChange={store.projectCostFilter.onTextToLookForChange}
                  onSubmit={store.projectCostFilter.onApply}
                  style={{
                    width: "260px",
                  }}
                />
              </CardActionsLeft>
              {!store.isTagView && (
                <CardActionsRight>
                  {store.isEditing && (
                    <Button onClick={store.handleStopEdit} color="brand">
                      Stop Editing
                    </Button>
                  )}
                  {!store.isEditing && (
                    <>
                      <Button color="brand" icon="edit" onClick={store.handleStartEdit}>
                        Edit
                      </Button>
                      <LinkButton
                        to="/project-cost-estimate/create"
                        color="brand"
                        icon="plus"
                      >
                        New Estimate
                      </LinkButton>
                      <Button icon="question-circle" onClick={store.helpModal.showModal}>
                        Help
                      </Button>
                    </>
                  )}
                </CardActionsRight>
              )}
            </CardActions>
          </div>
          <Divider css={{ borderColor: "$primaryLighter" }} />
          <ProjectCostEstimateList store={store} />
        </Card>

        {!store.isTagView && (
          <EditActions hidden={store.isEditing === null} show={Boolean(store.isEditing)}>
            <Button
              icon="edit"
              disabled={store.selectedCount !== 1}
              onClick={store.showRenameModal}
            >
              Rename
            </Button>
            {store.canExport() && (
              <Button
                icon="edit"
                disabled={store.selectedCount === 0}
                onClick={store.exportModal.showModal}
              >
                Export
              </Button>
            )}
            <Button
              disabled={store.selectedCount === 0}
              onClick={store.applyTagState.showTagModal}
            >
              Apply Tag
            </Button>
            <Button
              color="danger"
              icon="trash-alt"
              disabled={store.selectedCount === 0}
              onClick={store.confirmDeleteModal.showModal}
            >
              Delete
            </Button>
          </EditActions>
        )}
        <Modal show={store.helpModal.show} onHide={store.helpModal.hideModal}>
          <ContainerSection className="header">
            <ModalTitle>Help</ModalTitle>
          </ContainerSection>
          <ContainerSection>
            <p>
              The Project Cost Estimate Worksheets you have created are listed below. By
              going to the Details page you will be able to add/remove jobs to that
              current project.
            </p>
          </ContainerSection>
          <ContainerSection className="footer">
            <ModalButtons>
              <Button size="large" onClick={store.helpModal.hideModal}>
                Close
              </Button>
            </ModalButtons>
          </ContainerSection>
        </Modal>
        <Modal show={store.exportModal.show} onHide={store.exportModal.hideModal}>
          <ContainerSection className="header">
            <ModalTitle>Export</ModalTitle>
          </ContainerSection>
          <ContainerSection>
            {store.messaging.messages.length > 0 && (
              <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                <a
                  href="#close"
                  className="close"
                  data-dismiss="alert"
                  aria-label="close"
                  onClick={store.messaging.removeMessage.bind(
                    store,
                    store.messaging.messages[0]
                  )}
                >
                  &times;
                </a>
                <strong>{store.messaging.messages[0].messageLabel}</strong>
                {store.messaging.messages[0].message}
              </div>
            )}
            <p>File Name:</p>
            <TextInput
              maxLength="250"
              style={{
                width: "300px",
              }}
              value={store.exportFileName}
              onChange={store.changeExportFileName}
            />
          </ContainerSection>
          <div className="container-section">
            <p>File Format:</p>
            <CheckBox
              children="Microsoft Excel (Default)"
              type="radio"
              name="chooseExcelType"
              value="excel"
              selected={selectedExcel}
              onClick={store.handleExcelTypeChange.bind(this, "excel")}
            />
            <CheckBox
              children="Comma Separated Values"
              type="radio"
              name="chooseExcelType"
              value="csv"
              selected={selectedCSV}
              onClick={store.handleExcelTypeChange.bind(this, "csv")}
            />
          </div>
          <ContainerSection className="footer">
            <ModalButtons>
              <Button size="large" onClick={store.exportModal.hideModal}>
                Cancel
              </Button>
              <Button
                size="large"
                color="brand"
                onClick={(e) => {
                  store.exportModal.hideModal();
                  store.export();
                }}
              >
                Export
              </Button>
            </ModalButtons>
          </ContainerSection>
        </Modal>

        <Modal
          show={store.confirmDeleteModal.show}
          onHide={store.confirmDeleteModal.hideModal}
        >
          <ContainerSection className="header">
            <ModalTitle>Confirmation Needed</ModalTitle>
          </ContainerSection>
          <ContainerSection>
            <p>Are you sure you want to delete the selected Projects?</p>
            <p className="text-danger">
              <FontAwesomeIcon icon="exclamation-triangle" />{" "}
              <em>This action cannot be undone!</em>
            </p>
          </ContainerSection>
          <ContainerSection className="footer">
            <ModalButtons>
              <Button size="large" onClick={store.confirmDeleteModal.hideModal}>
                No, Cancel
              </Button>
              <Button
                size="large"
                color="danger"
                onClick={(e) => {
                  store.confirmDeleteModal.hideModal();
                  store.deleteProjects();
                }}
              >
                Yes, Delete Projects
              </Button>
            </ModalButtons>
          </ContainerSection>
        </Modal>
        <Modal show={store.renameModal.show} onHide={store.renameModal.hideModal}>
          <ContainerSection className="header">
            <ModalTitle>Rename Estimate</ModalTitle>
          </ContainerSection>
          <ContainerSection>
            <p>Name of the Estimate:</p>
            <TextInput
              maxLength="250"
              style={{ width: "100%" }}
              value={store.newPCEName}
              onChange={store.onNewPCENameChange}
            />
            <div className="pt-user-messages">
              {store.messaging.messages.length > 0 && (
                <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                  <a
                    href="#close"
                    className="close"
                    data-dismiss="alert"
                    aria-label="close"
                    onClick={store.messaging.removeMessage.bind(
                      store,
                      store.messaging.messages[0]
                    )}
                  >
                    &times;
                  </a>
                  <strong>{store.messaging.messages[0].messageLabel}</strong>
                  {store.messaging.messages[0].message}
                </div>
              )}
            </div>
          </ContainerSection>
          <ContainerSection className="footer">
            <ModalButtons>
              <Button size="large" onClick={store.renameModal.hideModal}>
                Cancel
              </Button>
              <Button size="large" color="brand" onClick={store.renameProject}>
                Rename Estimate
              </Button>
            </ModalButtons>
          </ContainerSection>
        </Modal>
        <ApplyTagFilterModal
          filterState={store.applyTagState}
          applyTagsEvent={store.applyTags}
          btnText="Apply Tag to Project Cost Est."
        />
      </Container>
    );
  }
}

List.propTypes = {
  store: PropTypes.object.isRequired,
};

export default observer(List);
