// @flow
import React from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TagList from "../../../models/TagList";
import { styled } from "../../../stitches.config";
import Button from "../../../components/lib/Button";

const Tag = styled("div", {
  border: "none",
  fontSize: "$sm",
  lineHeight: "$sm",
  padding: "4px 8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "$2",
  margin: "5px 5px 0 0",
  background: "$primaryLight",
  color: "$primaryDarkest",
  borderRadius: "$rounded",
  '& [data-comp-id="tag-close-button"]': {
    padding: "6px 9px",
    margin: "-6px -8px -6px 0",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    backgroundColor: "$primaryLight",
    borderColor: "$primaryLight",
    color: "$danger",
    "&:hover": {
      backgroundColor: "$danger",
      borderColor: "$danger",
      color: "$textLight",
    },
  },
});

type Props = {
  tag: TagList,
  hasOwnerShip: boolean,
  onClick: (SyntheticMouseEvent<HTMLElement>, number) => any,
};

const TagItem = observer((props: Props) => (
  <Tag>
    <FontAwesomeIcon icon="tag" /> {props.tag.name}
    {props.hasOwnerShip && props.onClick && (
      <Button
        data-comp-id="tag-close-button"
        icon="times"
        onClick={(event) => {
          props.onClick(event, props.tag.tagId);
        }}
      />
    )}
  </Tag>
));

TagItem.defaultProps = {
  hasOwnerShip: true,
};

export default TagItem;

export const TagItemWithoutDelete = observer((props) => (
  <div className="toast-cover">
    <FontAwesomeIcon icon="tag" /> {props.tag.name}
    <span />
  </div>
));
