import React from "react";
import Select from "react-select";

import { reactSelectStyles, reactSelectTheme } from "../../constants/reactSelect";

export interface CurrencyData {
  id: number;
  symbol: string;
  code: string;
  name: string;
  factor: number;
}

export interface CurrencySelectProps
  extends Pick<
    React.ComponentProps<typeof Select<CurrencyData>>,
    "value" | "onChange" | "name" | "placeholder" | "className" | "styles" | "theme"
  > {
  currenciesData: CurrencyData[];
  disabled?: boolean;
  multi?: boolean;
  searchable?: boolean;
}

const CurrencySelect = (props: CurrencySelectProps) => {
  const formatOptionLabel = (option: CurrencyData) => (
    <span>
      {option["name"]} ({option["code"]}, {option["symbol"]})
    </span>
  );

  return (
    <Select
      className={props.className}
      options={props.currenciesData}
      value={props.value}
      onChange={props.onChange}
      name={props.name}
      placeholder={props.placeholder}
      isDisabled={props.disabled}
      isMulti={props.multi}
      isSearchable={props.searchable}
      styles={props.styles}
      theme={props.theme}
      getOptionValue={(option) => option.code}
      getOptionLabel={(option) => option.name}
      formatOptionLabel={formatOptionLabel}
    />
  );
};

CurrencySelect.displayName = "CurrencySelect";
CurrencySelect.defaultProps = {
  placeholder: "Select currency...",
  disabled: false,
  multi: false,
  searchable: true,
  styles: reactSelectStyles,
  theme: reactSelectTheme,
};

export default CurrencySelect;
