import React from "react";
import { Map } from "immutable";

import Stack from "../../../components/lib/Stack";
import Box from "../../../components/lib/Box";
import JobFeedbackBlock from "../../validator5K_admin/components/JobFeedback";
import { usePLIContext } from "../context";
import { ButtonGroup } from "../../../components/lib/ButtonGroup";
import { Button } from "../../../components/lib/Button";
import { emptyMap } from "../../../constants";

import type {
  ContractorDataMap,
  CurrencyMap,
  ProcessedContractorDataMap,
} from "../types";

interface JobTitleValidationsViewProps {
  programId: number;
  data: ContractorDataMap;
  onCancel: () => void;
}

const JobTitleValidationsView = (props: JobTitleValidationsViewProps) => {
  const { data: contractorData, onCancel } = props;
  const { fetchArgusAPI, fetchArgusFilteringAPI, showModalError } = usePLIContext();

  const mappingsData =
    contractorData.get("processed") ?? (emptyMap as ProcessedContractorDataMap);
  const jobTitle = contractorData.get("job_title");
  const jobTitleCollection = mappingsData.get("job_collection_title");
  const jobTitleCollectionId = mappingsData.get("job_collection_id");
  const jobCountry = contractorData.get("country");
  const jobCountryId = mappingsData.get("country_id");
  const jobIndustry = mappingsData.get("industry_title");
  const jobIndustryId = mappingsData.get("industry_id");
  const rateType = contractorData.get("rate_type");

  const currencyData = React.useMemo(
    () =>
      Map({
        id: mappingsData.get("currency_id"),
        code: mappingsData.get("currency_code"),
        name: mappingsData.get("currency_name"),
        symbol: mappingsData.get("currency_symbol"),
        factor: mappingsData.get("currency_factor"),
      }) as unknown as CurrencyMap,
    [mappingsData]
  );

  return (
    <Stack
      fill
      css={{
        alignItems: "stretch",
        padding: "$6",
        gap: "$6",
        width: "$full",
        maxWidth: "1300px",
        background: "$white",
      }}
    >
      <Box css={{ width: "$full" }}>
        <JobFeedbackBlock
          jobTitle={jobTitle}
          jobTitleCollection={jobTitleCollection}
          jobTitleCollectionId={jobTitleCollectionId}
          jobCountry={jobCountry}
          jobCountryId={jobCountryId}
          jobIndustry={jobIndustry}
          jobIndustryId={jobIndustryId}
          rateType={rateType}
          currencyData={currencyData}
          fetchArgusAPI={fetchArgusAPI}
          fetchArgusFilteringAPI={fetchArgusFilteringAPI}
          showModalError={showModalError}
          disableButtons
          embedded
        />
      </Box>
      <ButtonGroup>
        <Button size="normal" title="Close" onClick={onCancel}>
          Close
        </Button>
      </ButtonGroup>
    </Stack>
  );
};

JobTitleValidationsView.displayName = "JobTitleValidationsView";

export default JobTitleValidationsView;
