import React from "react";

import Text from "../../../../components/lib/Text";

import {
  getFilteredValueTypesDesc,
  decimalFormatter,
  percentFormatter,
} from "../../constants";
import { useReviewDataContext } from "../../../validator5K_admin/context/ReviewDataContext";
import { useClassicRatesItemFeedbackContext } from "../../context/feedback/ClassicRatesItemFeedbackContext";

import { styled } from "../../../../stitches.config";
import type { RatesRowProps } from "../../../validator5K_admin/components/Rates/types";
import { RATE_TYPES, type RatesDataKey } from "../../types";
import EditableNumberInput from "./EditableNumberInput";
import Box from "../../../../components/lib/Box";
import { isFiniteNumber } from "../../../../components/lib/NumberInput";
import { useRateDisplayContext } from "../../../validator5K_admin/context/RateDisplayContext";

const StaticInlineBox = styled(Box, {
  display: "inline-box",
  height: "24px",
  lineHeight: "24px",
  whiteSpace: "nowrap",
});

function RatesRow(props: RatesRowProps) {
  const { valueSubtype, valueSubtypeTitle } = props;

  const { requiredRates, firstJobRateType: rateType } = useReviewDataContext();
  const {
    rateResult, // in local currency
    rateFeedback, // in local currency
    initialRateFeedback, // in local currency
    isRatesEditable,
    updateFeedbackState,
  } = useClassicRatesItemFeedbackContext();
  const {
    resultingCurrencySymbol,
    convertToDisplayCurrency,
    convertFromDisplayCurrency,
  } = useRateDisplayContext();

  const handleRateChange = React.useCallback(
    (key: RatesDataKey, e: React.ChangeEvent<HTMLInputElement>) => {
      const rawValue = e.target.valueAsNumber;
      const clearValue = isFiniteNumber(rawValue) ? rawValue : null;
      let convertedValue = clearValue;

      if (!key.startsWith("markup")) {
        convertedValue = convertFromDisplayCurrency(clearValue);
      }

      if (convertedValue !== rateFeedback.get(key)) {
        updateFeedbackState({ [key]: convertedValue });
      }
    },
    [rateFeedback, updateFeedbackState, convertFromDisplayCurrency]
  );

  return (
    <tr>
      <td>{valueSubtypeTitle}</td>
      {getFilteredValueTypesDesc(rateType, requiredRates.toJS()).map(
        ({ type: valueType }) => {
          const key = `${valueType}_${valueSubtype}` as unknown as RatesDataKey;
          const isEditableKey = key.endsWith("min") || key.endsWith("max");

          let initialValue: number = rateResult.get(key);
          let feedbackValue: number | null = initialRateFeedback?.get(key) ?? null;
          let changedValue = rateFeedback?.get(key) ?? null;

          // convert to display currency, cause we store in original currency in DB
          if (valueType !== "markup") {
            initialValue = convertToDisplayCurrency(initialValue);
            feedbackValue = convertToDisplayCurrency(feedbackValue);
            changedValue = convertToDisplayCurrency(changedValue);
          }

          const renderedInitialValue =
            valueType === "markup"
              ? percentFormatter(initialValue, false, "%")
              : decimalFormatter(initialValue, false, resultingCurrencySymbol);
          const renderedFeedbackValue =
            valueType === "markup"
              ? percentFormatter(feedbackValue, false, "%")
              : decimalFormatter(feedbackValue, false, resultingCurrencySymbol);

          return (
            <td key={valueType}>
              <StaticInlineBox>
                {isRatesEditable ? (
                  <EditableNumberInput
                    size="extra-small"
                    value={changedValue}
                    initialValue={feedbackValue ?? initialValue}
                    onChange={(e) => handleRateChange(key, e)}
                    editable={isEditableKey}
                    disabled={!isEditableKey}
                    css={{
                      height: "24px",
                      lineHeight: "24px",
                      paddingTop: 0,
                      paddingBottom: 0,
                      maxWidth: rateType === RATE_TYPES.ANNUAL ? "200px" : "80px",
                      "& input::-webkit-outer-spin-button": {
                        "-webkit-appearance": "none",
                      },
                      "& input::-webkit-inner-spin-button": {
                        "-webkit-appearance": "none",
                      },
                      "& input[type=number]": {
                        fontSize: "16px",
                        "-moz-appearance": "textfield",
                      },
                    }}
                  />
                ) : (
                  <Text
                    css={{
                      display: "inline-block",
                      position: "relative",
                    }}
                  >
                    {feedbackValue != null ? (
                      <s>{renderedInitialValue ?? "none"}</s>
                    ) : (
                      renderedInitialValue
                    )}
                    {feedbackValue != null && (
                      <Text
                        color={
                          feedbackValue > initialValue!
                            ? "positive"
                            : feedbackValue < initialValue!
                            ? "negative"
                            : undefined
                        }
                        css={{
                          display: "inline-block",
                          position: "absolute",
                          top: "-15px",
                          left: "-20px",
                        }}
                      >
                        {renderedFeedbackValue}
                      </Text>
                    )}
                  </Text>
                )}
              </StaticInlineBox>
            </td>
          );
        }
      )}
    </tr>
  );
}

export default RatesRow;
