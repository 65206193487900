import React, { useCallback, useEffect } from "react";

import Inline from "../../../components/lib/Inline";
import Box from "../../../components/lib/Box";
import Stack from "../../../components/lib/Stack";
import Icon from "../../../components/lib/Icon";
import Text from "../../../components/lib/Text";
import Button from "../../../components/lib/Button";
import Alert from "../../../components/lib/Alert";
import DownloadPOSTButtonExt from "../../private_index/components/DownloadPOSTButtonExt";
import { RadioGroup, RadioGroupItem } from "../../../components/lib/RadioGroup";
import { ButtonGroupRight } from "../../../components/lib/ButtonGroup";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogClose,
  DialogDescription,
  DialogActions,
} from "../../../components/lib/Dialog";

import type { Values } from "../types";
import type {
  FiltersQueryObject,
  UrlQueryObject,
} from "../../../components/tables/types";

const RATES_ORDERING_TYPES = {
  BY_RATECARD: "job__review__source_title, job__review__id, job__title",
  BY_TITLE: "job__title, job__review__id, job__review__countries_string",
  BY_COUNTRY: "job__review__countries_string, job__review__id, job__title",
};

export type RATES_ORDERING_TYPES_TYPE = Values<typeof RATES_ORDERING_TYPES>;

type RateExportOrderingBlockProps = {
  ordering: any;
  onChange: (value: any) => void;
};

const RatesExportOrderingBlock = (props: RateExportOrderingBlockProps) => {
  const { ordering, onChange } = props;

  return (
    <Stack css={{ alignItems: "flex-start", gap: "$2" }}>
      <Text bold>Select the way of ordering exported data</Text>
      <RadioGroup defaultValue={ordering} onValueChange={onChange}>
        <Inline css={{ gap: "$2" }}>
          <RadioGroupItem value={RATES_ORDERING_TYPES.BY_RATECARD}>
            By Rate Card Name / By Upload Name
          </RadioGroupItem>
          <RadioGroupItem value={RATES_ORDERING_TYPES.BY_TITLE}>
            By Job Title
          </RadioGroupItem>
          <RadioGroupItem value={RATES_ORDERING_TYPES.BY_COUNTRY}>
            By Country
          </RadioGroupItem>
        </Inline>
      </RadioGroup>
    </Stack>
  );
};
RatesExportOrderingBlock.displayName = "RatesExportOrderingBlock";

type getQueryArgsTypeFunc = (
  ordering: RATES_ORDERING_TYPES_TYPE,
  isCurrentPageOnly: boolean
) => UrlQueryObject | null;

type RatesExportConfigProps = {
  show: boolean;
  itemsTotal: number;
  itemsPerPage: number;
  getQueryArgs: getQueryArgsTypeFunc;
  getFormData: () => FiltersQueryObject;
  onClose: () => void;
  onError: (err: Error) => void;
};

const RatesExportConfig = (props: RatesExportConfigProps) => {
  const { show, itemsTotal, itemsPerPage, getQueryArgs, getFormData, onClose, onError } =
    props;
  const hasData = itemsTotal > 0;
  const isSinglePageExport = hasData && itemsTotal <= itemsPerPage;

  // state

  const [ordering, setOrdering] = React.useState(RATES_ORDERING_TYPES.BY_RATECARD);

  // effects

  useEffect(() => {
    if (!show) {
      setOrdering(RATES_ORDERING_TYPES.BY_RATECARD);
    }
  }, [show]);

  // handlers

  const handleError = useCallback(
    (err: Error) => {
      onClose();
      onError(err);
    },
    [onError, onClose]
  );

  return (
    <Dialog open={show} onOpenChange={onClose}>
      <DialogContent css={{ minWidth: "700px" }}>
        <DialogTitle asChild>
          <Inline css={{ justifyContent: "space-between" }}>
            <Box>
              <Text as="h4">Export Validators Feedback to Excel.</Text>
            </Box>
            <DialogClose asChild>
              <Icon icon="times" />
            </DialogClose>
          </Inline>
        </DialogTitle>
        <DialogDescription css={{ backgroundColor: "inherit" }} asChild>
          <Stack css={{ alignItems: "flex-start", gap: "$4" }}>
            {!hasData && <Text bold>No data to export.</Text>}
            {hasData && (
              <Alert fill color="warning">
                <Text>
                  <Text bold underline italic>
                    Please Note
                  </Text>
                  : Exporting questions list could take awhile depending on the size of
                  the output.
                </Text>
              </Alert>
            )}
            {hasData && (
              <RatesExportOrderingBlock ordering={ordering} onChange={setOrdering} />
            )}
            {hasData && (
              <Stack css={{ alignItems: "flex-start", gap: "$2" }}>
                <Text bold>Export data using following options:</Text>
                {!isSinglePageExport && (
                  <DownloadPOSTButtonExt
                    size="small"
                    icon={["far", "file-excel"]}
                    color="brand"
                    variant="outlined"
                    loadingText="Export feedback for current page only."
                    service="argus"
                    endpoint="v1/attempts/rates-feedback/export/"
                    getQueryArgs={() => getQueryArgs(ordering, true)}
                    getFormData={getFormData}
                    onSuccess={onClose}
                    onError={handleError}
                  >
                    Export feedback for current page only.
                  </DownloadPOSTButtonExt>
                )}
                <DownloadPOSTButtonExt
                  size="small"
                  icon={["far", "file-excel"]}
                  color="brand"
                  variant="outlined"
                  loadingText="Export feedback for the whole list of surveys."
                  service="argus"
                  endpoint="v1/attempts/rates-feedback/export/"
                  getQueryArgs={() => getQueryArgs(ordering, false)}
                  getFormData={getFormData}
                  onSuccess={onClose}
                  onError={handleError}
                >
                  Export feedback for the whole list of surveys.
                </DownloadPOSTButtonExt>
              </Stack>
            )}
          </Stack>
        </DialogDescription>
        <DialogActions>
          <ButtonGroupRight fill>
            <Button size="large" onClick={onClose}>
              Close
            </Button>
          </ButtonGroupRight>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
RatesExportConfig.displayName = "RatesExportConfig";

export default RatesExportConfig;
