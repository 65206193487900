import React from "react";

import Text from "../../../components/lib/Text";

import { FilterTypes } from "../../../components/tables/constants";
import {
  decimalFormatter as baseDecimalFormatter,
  percentFormatter as basePercentFormatter,
} from "../constants";
import {
  RATE_TYPES,
  CONTINGENT_VALUES_TYPES,
  VALUES_SUBTYPES,
  SKILLS_LEVELS,
} from "../types";

import type {
  ContractorDataMap,
  DecimalValueFormatterFunc,
  TableColumnSpecsObject,
} from "../types";

const unitsGetter = (row: ContractorDataMap): string | null =>
  row.getIn(["processed", "currency_symbol"]) ?? null;
const decimalFormatter: DecimalValueFormatterFunc = (value, row) =>
  baseDecimalFormatter(value, false, unitsGetter(row));
const percentFormatter: DecimalValueFormatterFunc = (value, row) =>
  basePercentFormatter(value, false);
const varianceDecimalFormatter: DecimalValueFormatterFunc = (value, row) =>
  baseDecimalFormatter(value, true, unitsGetter(row));
const variancePercentFormatter: DecimalValueFormatterFunc = (value, row) =>
  basePercentFormatter(value, true);
const coloredVarianceFormatter = (
  formatter: DecimalValueFormatterFunc,
  value: number,
  row: ContractorDataMap
): React.ReactChild | null => {
  if (value == null) return value;
  return (
    <Text color={value >= 0 ? "negative" : "positive"}>{formatter(value, row)}</Text>
  );
};
const coloredVarianceDecimalFormatter = coloredVarianceFormatter.bind(
  undefined,
  varianceDecimalFormatter
);
const coloredVariancePercentFormatter = coloredVarianceFormatter.bind(
  undefined,
  variancePercentFormatter
);
const makeItStrongFormatter = (value: React.ReactChild | null) =>
  value != null ? <strong>{value}</strong> : null;

export const juniorRatesColumnsSpecs: TableColumnSpecsObject<ContractorDataMap>[] = [
  {
    type: "column",
    uniqueKey: "market_analysis__junior_bill_rate_avg",
    title: "Level I - Avg Bill Rate",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT
        ? row.getIn(["market_analysis", "junior_bill_rate_avg"])
        : null,
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.JUNIOR,
    valueType: CONTINGENT_VALUES_TYPES.BILL_RATE,
    valueSubtype: VALUES_SUBTYPES.AVG,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__junior_bill_rate_min",
    title: "Level I - Min Bill Rate",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT
        ? row.getIn(["market_analysis", "junior_bill_rate_min"])
        : null,
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.JUNIOR,
    valueType: CONTINGENT_VALUES_TYPES.BILL_RATE,
    valueSubtype: VALUES_SUBTYPES.MIN,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__junior_bill_rate_mid",
    title: "Level I - Mid Bill Rate",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT
        ? row.getIn(["market_analysis", "junior_bill_rate_mid"])
        : null,
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.JUNIOR,
    valueType: CONTINGENT_VALUES_TYPES.BILL_RATE,
    valueSubtype: VALUES_SUBTYPES.MID,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__junior_bill_rate_max",
    title: "Level I - Max Bill Rate",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT
        ? row.getIn(["market_analysis", "junior_bill_rate_max"])
        : null,
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.JUNIOR,
    valueType: CONTINGENT_VALUES_TYPES.BILL_RATE,
    valueSubtype: VALUES_SUBTYPES.MAX,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__junior_pay_rate_avg",
    title: "Level I - Avg Pay Rate",
    getter: (row) => row.getIn(["market_analysis", "junior_pay_rate_avg"]),
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.JUNIOR,
    valueType: CONTINGENT_VALUES_TYPES.PAY_RATE,
    valueSubtype: VALUES_SUBTYPES.AVG,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__junior_pay_rate_min",
    title: "Level I - Min Pay Rate",
    getter: (row) => row.getIn(["market_analysis", "junior_pay_rate_min"]),
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.JUNIOR,
    valueType: CONTINGENT_VALUES_TYPES.PAY_RATE,
    valueSubtype: VALUES_SUBTYPES.MIN,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__junior_pay_rate_mid",
    title: "Level I - Mid Pay Rate",
    getter: (row) => row.getIn(["market_analysis", "junior_pay_rate_mid"]),
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.JUNIOR,
    valueType: CONTINGENT_VALUES_TYPES.PAY_RATE,
    valueSubtype: VALUES_SUBTYPES.MID,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__junior_pay_rate_max",
    title: "Level I - Max Pay Rate",
    getter: (row) => row.getIn(["market_analysis", "junior_pay_rate_max"]),
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.JUNIOR,
    valueType: CONTINGENT_VALUES_TYPES.PAY_RATE,
    valueSubtype: VALUES_SUBTYPES.MAX,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__junior_markup_avg",
    title: "Level I - Avg Markup",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT
        ? row.getIn(["market_analysis", "junior_markup_avg"])
        : null,
    formatter: percentFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.JUNIOR,
    valueType: CONTINGENT_VALUES_TYPES.MARKUP,
    valueSubtype: VALUES_SUBTYPES.AVG,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__junior_markup_min",
    title: "Level I - Min Markup",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT
        ? row.getIn(["market_analysis", "junior_markup_min"])
        : null,
    formatter: percentFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.JUNIOR,
    valueType: CONTINGENT_VALUES_TYPES.MARKUP,
    valueSubtype: VALUES_SUBTYPES.MIN,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__junior_markup_mid",
    title: "Level I - Mid Markup",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT
        ? row.getIn(["market_analysis", "junior_markup_mid"])
        : null,
    formatter: percentFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.JUNIOR,
    valueType: CONTINGENT_VALUES_TYPES.MARKUP,
    valueSubtype: VALUES_SUBTYPES.MID,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__junior_markup_max",
    title: "Level I - Max Markup",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT
        ? row.getIn(["market_analysis", "junior_markup_max"])
        : null,
    formatter: percentFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.JUNIOR,
    valueType: CONTINGENT_VALUES_TYPES.MARKUP,
    valueSubtype: VALUES_SUBTYPES.MAX,
  },
];

export const intermediateRatesColumnsSpecs: TableColumnSpecsObject<ContractorDataMap>[] =
  [
    {
      type: "column",
      uniqueKey: "market_analysis__intermediate_bill_rate_avg",
      title: "Level II - Avg Bill Rate",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.CONTRACT
          ? row.getIn(["market_analysis", "intermediate_bill_rate_avg"])
          : null,
      formatter: decimalFormatter,
      filterType: FilterTypes.NUMBERS_RANGE,
      valueLevel: SKILLS_LEVELS.INTERMEDIATE,
      valueType: CONTINGENT_VALUES_TYPES.BILL_RATE,
      valueSubtype: VALUES_SUBTYPES.AVG,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__intermediate_bill_rate_min",
      title: "Level II - Min Bill Rate",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.CONTRACT
          ? row.getIn(["market_analysis", "intermediate_bill_rate_min"])
          : null,
      formatter: decimalFormatter,
      filterType: FilterTypes.NUMBERS_RANGE,
      valueLevel: SKILLS_LEVELS.INTERMEDIATE,
      valueType: CONTINGENT_VALUES_TYPES.BILL_RATE,
      valueSubtype: VALUES_SUBTYPES.MIN,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__intermediate_bill_rate_mid",
      title: "Level II - Mid Bill Rate",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.CONTRACT
          ? row.getIn(["market_analysis", "intermediate_bill_rate_mid"])
          : null,
      formatter: decimalFormatter,
      filterType: FilterTypes.NUMBERS_RANGE,
      valueLevel: SKILLS_LEVELS.INTERMEDIATE,
      valueType: CONTINGENT_VALUES_TYPES.BILL_RATE,
      valueSubtype: VALUES_SUBTYPES.MID,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__intermediate_bill_rate_max",
      title: "Level II - Max Bill Rate",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.CONTRACT
          ? row.getIn(["market_analysis", "intermediate_bill_rate_max"])
          : null,
      formatter: decimalFormatter,
      filterType: FilterTypes.NUMBERS_RANGE,
      valueLevel: SKILLS_LEVELS.INTERMEDIATE,
      valueType: CONTINGENT_VALUES_TYPES.BILL_RATE,
      valueSubtype: VALUES_SUBTYPES.MAX,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__intermediate_pay_rate_avg",
      title: "Level II - Avg Pay Rate",
      getter: (row) => row.getIn(["market_analysis", "intermediate_pay_rate_avg"]),
      formatter: decimalFormatter,
      filterType: FilterTypes.NUMBERS_RANGE,
      valueLevel: SKILLS_LEVELS.INTERMEDIATE,
      valueType: CONTINGENT_VALUES_TYPES.PAY_RATE,
      valueSubtype: VALUES_SUBTYPES.AVG,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__intermediate_pay_rate_min",
      title: "Level II - Min Pay Rate",
      getter: (row) => row.getIn(["market_analysis", "intermediate_pay_rate_min"]),
      formatter: decimalFormatter,
      filterType: FilterTypes.NUMBERS_RANGE,
      valueLevel: SKILLS_LEVELS.INTERMEDIATE,
      valueType: CONTINGENT_VALUES_TYPES.PAY_RATE,
      valueSubtype: VALUES_SUBTYPES.MIN,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__intermediate_pay_rate_mid",
      title: "Level II - Mid Pay Rate",
      getter: (row) => row.getIn(["market_analysis", "intermediate_pay_rate_mid"]),
      formatter: decimalFormatter,
      filterType: FilterTypes.NUMBERS_RANGE,
      valueLevel: SKILLS_LEVELS.INTERMEDIATE,
      valueType: CONTINGENT_VALUES_TYPES.PAY_RATE,
      valueSubtype: VALUES_SUBTYPES.MID,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__intermediate_pay_rate_max",
      title: "Level II - Max Pay Rate",
      getter: (row) => row.getIn(["market_analysis", "intermediate_pay_rate_max"]),
      formatter: decimalFormatter,
      filterType: FilterTypes.NUMBERS_RANGE,
      valueLevel: SKILLS_LEVELS.INTERMEDIATE,
      valueType: CONTINGENT_VALUES_TYPES.PAY_RATE,
      valueSubtype: VALUES_SUBTYPES.MAX,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__intermediate_markup_avg",
      title: "Level II - Avg Markup",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.CONTRACT
          ? row.getIn(["market_analysis", "intermediate_markup_avg"])
          : null,
      formatter: percentFormatter,
      filterType: FilterTypes.NUMBERS_RANGE,
      valueLevel: SKILLS_LEVELS.INTERMEDIATE,
      valueType: CONTINGENT_VALUES_TYPES.MARKUP,
      valueSubtype: VALUES_SUBTYPES.AVG,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__intermediate_markup_min",
      title: "Level II - Min Markup",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.CONTRACT
          ? row.getIn(["market_analysis", "intermediate_markup_min"])
          : null,
      formatter: percentFormatter,
      filterType: FilterTypes.NUMBERS_RANGE,
      valueLevel: SKILLS_LEVELS.INTERMEDIATE,
      valueType: CONTINGENT_VALUES_TYPES.MARKUP,
      valueSubtype: VALUES_SUBTYPES.MIN,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__intermediate_markup_mid",
      title: "Level II - Mid Markup",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.CONTRACT
          ? row.getIn(["market_analysis", "intermediate_markup_mid"])
          : null,
      formatter: percentFormatter,
      filterType: FilterTypes.NUMBERS_RANGE,
      valueLevel: SKILLS_LEVELS.INTERMEDIATE,
      valueType: CONTINGENT_VALUES_TYPES.MARKUP,
      valueSubtype: VALUES_SUBTYPES.MID,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__intermediate_markup_max",
      title: "Level II - Max Markup",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.CONTRACT
          ? row.getIn(["market_analysis", "intermediate_markup_max"])
          : null,
      formatter: percentFormatter,
      filterType: FilterTypes.NUMBERS_RANGE,
      valueLevel: SKILLS_LEVELS.INTERMEDIATE,
      valueType: CONTINGENT_VALUES_TYPES.MARKUP,
      valueSubtype: VALUES_SUBTYPES.MAX,
    },
  ];

export const seniorRatesColumnsSpecs: TableColumnSpecsObject<ContractorDataMap>[] = [
  {
    type: "column",
    uniqueKey: "market_analysis__senior_bill_rate_avg",
    title: "Level III - Avg Bill Rate",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT
        ? row.getIn(["market_analysis", "senior_bill_rate_avg"])
        : null,
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.SENIOR,
    valueType: CONTINGENT_VALUES_TYPES.BILL_RATE,
    valueSubtype: VALUES_SUBTYPES.AVG,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__senior_bill_rate_min",
    title: "Level III - Min Bill Rate",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT
        ? row.getIn(["market_analysis", "senior_bill_rate_min"])
        : null,
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.SENIOR,
    valueType: CONTINGENT_VALUES_TYPES.BILL_RATE,
    valueSubtype: VALUES_SUBTYPES.MIN,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__senior_bill_rate_mid",
    title: "Level III - Mid Bill Rate",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT
        ? row.getIn(["market_analysis", "senior_bill_rate_mid"])
        : null,
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.SENIOR,
    valueType: CONTINGENT_VALUES_TYPES.BILL_RATE,
    valueSubtype: VALUES_SUBTYPES.MID,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__senior_bill_rate_max",
    title: "Level III - Max Bill Rate",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT
        ? row.getIn(["market_analysis", "senior_bill_rate_max"])
        : null,
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.SENIOR,
    valueType: CONTINGENT_VALUES_TYPES.BILL_RATE,
    valueSubtype: VALUES_SUBTYPES.MAX,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__senior_pay_rate_avg",
    title: "Level III - Avg Pay Rate",
    getter: (row) => row.getIn(["market_analysis", "senior_pay_rate_avg"]),
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.SENIOR,
    valueType: CONTINGENT_VALUES_TYPES.PAY_RATE,
    valueSubtype: VALUES_SUBTYPES.AVG,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__senior_pay_rate_min",
    title: "Level III - Min Pay Rate",
    getter: (row) => row.getIn(["market_analysis", "senior_pay_rate_min"]),
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.SENIOR,
    valueType: CONTINGENT_VALUES_TYPES.PAY_RATE,
    valueSubtype: VALUES_SUBTYPES.MIN,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__senior_pay_rate_mid",
    title: "Level III - Mid Pay Rate",
    getter: (row) => row.getIn(["market_analysis", "senior_pay_rate_mid"]),
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.SENIOR,
    valueType: CONTINGENT_VALUES_TYPES.PAY_RATE,
    valueSubtype: VALUES_SUBTYPES.MID,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__senior_pay_rate_max",
    title: "Level III - Max Pay Rate",
    getter: (row) => row.getIn(["market_analysis", "senior_pay_rate_max"]),
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.SENIOR,
    valueType: CONTINGENT_VALUES_TYPES.PAY_RATE,
    valueSubtype: VALUES_SUBTYPES.MAX,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__senior_markup_avg",
    title: "Level III - Avg Markup",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT
        ? row.getIn(["market_analysis", "senior_markup_avg"])
        : null,
    formatter: percentFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.SENIOR,
    valueType: CONTINGENT_VALUES_TYPES.MARKUP,
    valueSubtype: VALUES_SUBTYPES.AVG,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__senior_markup_min",
    title: "Level III - Min Markup",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT
        ? row.getIn(["market_analysis", "senior_markup_min"])
        : null,
    formatter: percentFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.SENIOR,
    valueType: CONTINGENT_VALUES_TYPES.MARKUP,
    valueSubtype: VALUES_SUBTYPES.MIN,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__senior_markup_mid",
    title: "Level III - Mid Markup",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT
        ? row.getIn(["market_analysis", "senior_markup_mid"])
        : null,
    formatter: percentFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.SENIOR,
    valueType: CONTINGENT_VALUES_TYPES.MARKUP,
    valueSubtype: VALUES_SUBTYPES.MID,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__senior_markup_max",
    title: "Level III - Max Markup",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT
        ? row.getIn(["market_analysis", "senior_markup_max"])
        : null,
    formatter: percentFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.SENIOR,
    valueType: CONTINGENT_VALUES_TYPES.MARKUP,
    valueSubtype: VALUES_SUBTYPES.MAX,
  },
];

export const leadRatesColumnsSpecs: TableColumnSpecsObject<ContractorDataMap>[] = [
  {
    type: "column",
    uniqueKey: "market_analysis__lead_bill_rate_avg",
    title: "Level IV - Avg Bill Rate",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT
        ? row.getIn(["market_analysis", "lead_bill_rate_avg"])
        : null,
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.LEAD,
    valueType: CONTINGENT_VALUES_TYPES.BILL_RATE,
    valueSubtype: VALUES_SUBTYPES.AVG,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__lead_bill_rate_min",
    title: "Level IV - Min Bill Rate",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT
        ? row.getIn(["market_analysis", "lead_bill_rate_min"])
        : null,
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.LEAD,
    valueType: CONTINGENT_VALUES_TYPES.BILL_RATE,
    valueSubtype: VALUES_SUBTYPES.MIN,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__lead_bill_rate_mid",
    title: "Level IV - Mid Bill Rate",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT
        ? row.getIn(["market_analysis", "lead_bill_rate_mid"])
        : null,
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.LEAD,
    valueType: CONTINGENT_VALUES_TYPES.BILL_RATE,
    valueSubtype: VALUES_SUBTYPES.MID,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__lead_bill_rate_max",
    title: "Level IV - Max Bill Rate",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT
        ? row.getIn(["market_analysis", "lead_bill_rate_max"])
        : null,
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.LEAD,
    valueType: CONTINGENT_VALUES_TYPES.BILL_RATE,
    valueSubtype: VALUES_SUBTYPES.MAX,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__lead_pay_rate_avg",
    title: "Level IV - Avg Pay Rate",
    getter: (row) => row.getIn(["market_analysis", "lead_pay_rate_avg"]),
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.LEAD,
    valueType: CONTINGENT_VALUES_TYPES.PAY_RATE,
    valueSubtype: VALUES_SUBTYPES.AVG,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__lead_pay_rate_min",
    title: "Level IV - Min Pay Rate",
    getter: (row) => row.getIn(["market_analysis", "lead_pay_rate_min"]),
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.LEAD,
    valueType: CONTINGENT_VALUES_TYPES.PAY_RATE,
    valueSubtype: VALUES_SUBTYPES.MIN,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__lead_pay_rate_mid",
    title: "Level IV - Mid Pay Rate",
    getter: (row) => row.getIn(["market_analysis", "lead_pay_rate_mid"]),
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.LEAD,
    valueType: CONTINGENT_VALUES_TYPES.PAY_RATE,
    valueSubtype: VALUES_SUBTYPES.MID,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__lead_pay_rate_max",
    title: "Level IV - Max Pay Rate",
    getter: (row) => row.getIn(["market_analysis", "lead_pay_rate_max"]),
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.LEAD,
    valueType: CONTINGENT_VALUES_TYPES.PAY_RATE,
    valueSubtype: VALUES_SUBTYPES.MAX,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__lead_markup_avg",
    title: "Level IV - Avg Markup",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT
        ? row.getIn(["market_analysis", "lead_markup_avg"])
        : null,
    formatter: percentFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.LEAD,
    valueType: CONTINGENT_VALUES_TYPES.MARKUP,
    valueSubtype: VALUES_SUBTYPES.AVG,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__lead_markup_min",
    title: "Level IV - Min Markup",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT
        ? row.getIn(["market_analysis", "lead_markup_min"])
        : null,
    formatter: percentFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.LEAD,
    valueType: CONTINGENT_VALUES_TYPES.MARKUP,
    valueSubtype: VALUES_SUBTYPES.MIN,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__lead_markup_mid",
    title: "Level IV - Mid Markup",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT
        ? row.getIn(["market_analysis", "lead_markup_mid"])
        : null,
    formatter: percentFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.LEAD,
    valueType: CONTINGENT_VALUES_TYPES.MARKUP,
    valueSubtype: VALUES_SUBTYPES.MID,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__lead_markup_max",
    title: "Level IV - Max Markup",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT
        ? row.getIn(["market_analysis", "lead_markup_max"])
        : null,
    formatter: percentFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.LEAD,
    valueType: CONTINGENT_VALUES_TYPES.MARKUP,
    valueSubtype: VALUES_SUBTYPES.MAX,
  },
];

export const guruRatesColumnsSpecs: TableColumnSpecsObject<ContractorDataMap>[] = [
  {
    type: "column",
    uniqueKey: "market_analysis__guru_bill_rate_avg",
    title: "Level V - Avg Bill Rate",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT
        ? row.getIn(["market_analysis", "guru_bill_rate_avg"])
        : null,
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.GURU,
    valueType: CONTINGENT_VALUES_TYPES.BILL_RATE,
    valueSubtype: VALUES_SUBTYPES.AVG,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__guru_bill_rate_min",
    title: "Level V - Min Bill Rate",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT
        ? row.getIn(["market_analysis", "guru_bill_rate_min"])
        : null,
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.GURU,
    valueType: CONTINGENT_VALUES_TYPES.BILL_RATE,
    valueSubtype: VALUES_SUBTYPES.MIN,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__guru_bill_rate_mid",
    title: "Level V - Mid Bill Rate",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT
        ? row.getIn(["market_analysis", "guru_bill_rate_mid"])
        : null,
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.GURU,
    valueType: CONTINGENT_VALUES_TYPES.BILL_RATE,
    valueSubtype: VALUES_SUBTYPES.MID,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__guru_bill_rate_max",
    title: "Level V - Max Bill Rate",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT
        ? row.getIn(["market_analysis", "guru_bill_rate_max"])
        : null,
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.GURU,
    valueType: CONTINGENT_VALUES_TYPES.BILL_RATE,
    valueSubtype: VALUES_SUBTYPES.MAX,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__guru_pay_rate_avg",
    title: "Level V - Avg Pay Rate",
    getter: (row) => row.getIn(["market_analysis", "guru_pay_rate_avg"]),
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.GURU,
    valueType: CONTINGENT_VALUES_TYPES.PAY_RATE,
    valueSubtype: VALUES_SUBTYPES.AVG,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__guru_pay_rate_min",
    title: "Level V - Min Pay Rate",
    getter: (row) => row.getIn(["market_analysis", "guru_pay_rate_min"]),
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.GURU,
    valueType: CONTINGENT_VALUES_TYPES.PAY_RATE,
    valueSubtype: VALUES_SUBTYPES.MIN,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__guru_pay_rate_mid",
    title: "Level V - Mid Pay Rate",
    getter: (row) => row.getIn(["market_analysis", "guru_pay_rate_mid"]),
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.GURU,
    valueType: CONTINGENT_VALUES_TYPES.PAY_RATE,
    valueSubtype: VALUES_SUBTYPES.MID,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__guru_pay_rate_max",
    title: "Level V - Max Pay Rate",
    getter: (row) => row.getIn(["market_analysis", "guru_pay_rate_max"]),
    formatter: decimalFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.GURU,
    valueType: CONTINGENT_VALUES_TYPES.PAY_RATE,
    valueSubtype: VALUES_SUBTYPES.MAX,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__guru_markup_avg",
    title: "Level V - Avg Markup",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT
        ? row.getIn(["market_analysis", "guru_markup_avg"])
        : null,
    formatter: percentFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.GURU,
    valueType: CONTINGENT_VALUES_TYPES.MARKUP,
    valueSubtype: VALUES_SUBTYPES.AVG,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__guru_markup_min",
    title: "Level V - Min Markup",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT
        ? row.getIn(["market_analysis", "guru_markup_min"])
        : null,
    formatter: percentFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.GURU,
    valueType: CONTINGENT_VALUES_TYPES.MARKUP,
    valueSubtype: VALUES_SUBTYPES.MIN,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__guru_markup_mid",
    title: "Level V - Mid Markup",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT
        ? row.getIn(["market_analysis", "guru_markup_mid"])
        : null,
    formatter: percentFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.GURU,
    valueType: CONTINGENT_VALUES_TYPES.MARKUP,
    valueSubtype: VALUES_SUBTYPES.MID,
  },
  {
    type: "column",
    uniqueKey: "market_analysis__guru_markup_max",
    title: "Level V - Max Markup",
    getter: (row) =>
      row.get("rate_type") === RATE_TYPES.CONTRACT
        ? row.getIn(["market_analysis", "guru_markup_max"])
        : null,
    formatter: percentFormatter,
    filterType: FilterTypes.NUMBERS_RANGE,
    valueLevel: SKILLS_LEVELS.GURU,
    valueType: CONTINGENT_VALUES_TYPES.MARKUP,
    valueSubtype: VALUES_SUBTYPES.MAX,
  },
];

export const allMarketValuesColumnsSpecs: TableColumnSpecsObject<ContractorDataMap>[] =
  juniorRatesColumnsSpecs.concat(
    intermediateRatesColumnsSpecs,
    seniorRatesColumnsSpecs,
    leadRatesColumnsSpecs,
    guruRatesColumnsSpecs
  );

export const matchedMarketValuesColumnsSpecs: TableColumnSpecsObject<ContractorDataMap>[] =
  [
    {
      type: "column",
      uniqueKey: "market_analysis__market_pay_rate_avg",
      title: "Avg. Market Pay Rate",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.FTE
          ? null
          : row.getIn(["market_analysis", "market_pay_rate_avg"]),
      formatter: decimalFormatter,
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__market_pay_rate_min",
      title: "Min. Market Pay Rate",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.FTE
          ? null
          : row.getIn(["market_analysis", "market_pay_rate_min"]),
      formatter: decimalFormatter,
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__market_pay_rate_mid",
      title: "Mid. Market Pay Rate",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.FTE
          ? null
          : row.getIn(["market_analysis", "market_pay_rate_mid"]),
      formatter: decimalFormatter,
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__market_pay_rate_max",
      title: "Max. Market Pay Rate",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.FTE
          ? null
          : row.getIn(["market_analysis", "market_pay_rate_max"]),
      formatter: decimalFormatter,
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__market_pay_rate_avg_variance",
      title: "Pay Rate Comparison",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.FTE
          ? null
          : row.getIn(["market_analysis", "market_pay_rate_avg_variance"]),
      formatter: (value, row) =>
        makeItStrongFormatter(coloredVarianceDecimalFormatter(value, row)),
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__market_pay_rate_min_variance",
      title: "Pay Rate Comparison",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.FTE
          ? null
          : row.getIn(["market_analysis", "market_pay_rate_min_variance"]),
      formatter: (value, row) =>
        makeItStrongFormatter(coloredVarianceDecimalFormatter(value, row)),
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__market_pay_rate_mid_variance",
      title: "Pay Rate Comparison",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.FTE
          ? null
          : row.getIn(["market_analysis", "market_pay_rate_mid_variance"]),
      formatter: (value, row) =>
        makeItStrongFormatter(coloredVarianceDecimalFormatter(value, row)),
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__market_pay_rate_max_variance",
      title: "Pay Rate Comparison",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.FTE
          ? null
          : row.getIn(["market_analysis", "market_pay_rate_max_variance"]),
      formatter: (value, row) =>
        makeItStrongFormatter(coloredVarianceDecimalFormatter(value, row)),
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__market_markup_avg",
      title: "Avg. Market Markup",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.FTE
          ? null
          : row.getIn(["market_analysis", "market_markup_avg"]),
      formatter: percentFormatter,
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__market_markup_min",
      title: "Min. Market Markup",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.FTE
          ? null
          : row.getIn(["market_analysis", "market_markup_min"]),
      formatter: percentFormatter,
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__market_markup_mid",
      title: "Mid. Market Markup",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.FTE
          ? null
          : row.getIn(["market_analysis", "market_markup_mid"]),
      formatter: percentFormatter,
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__market_markup_max",
      title: "Max. Market Markup",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.FTE
          ? null
          : row.getIn(["market_analysis", "market_markup_max"]),
      formatter: percentFormatter,
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__market_markup_avg_variance",
      title: "Markup Comparison",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.FTE
          ? null
          : row.getIn(["market_analysis", "market_markup_avg_variance"]),
      formatter: (value, row) =>
        makeItStrongFormatter(coloredVariancePercentFormatter(value, row)),
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__market_markup_min_variance",
      title: "Markup Comparison",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.FTE
          ? null
          : row.getIn(["market_analysis", "market_markup_min_variance"]),
      formatter: (value, row) =>
        makeItStrongFormatter(coloredVariancePercentFormatter(value, row)),
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__market_markup_mid_variance",
      title: "Markup Comparison",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.FTE
          ? null
          : row.getIn(["market_analysis", "market_markup_mid_variance"]),
      formatter: (value, row) =>
        makeItStrongFormatter(coloredVariancePercentFormatter(value, row)),
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__market_markup_max_variance",
      title: "Markup Comparison",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.FTE
          ? null
          : row.getIn(["market_analysis", "market_markup_max_variance"]),
      formatter: (value, row) =>
        makeItStrongFormatter(coloredVariancePercentFormatter(value, row)),
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__market_bill_rate_avg",
      title: "Avg. Market Bill Rate",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.FTE
          ? null
          : row.getIn(["market_analysis", "market_bill_rate_avg"]),
      formatter: decimalFormatter,
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__market_bill_rate_min",
      title: "Min. Market Bill Rate",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.FTE
          ? null
          : row.getIn(["market_analysis", "market_bill_rate_min"]),
      formatter: decimalFormatter,
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__market_bill_rate_mid",
      title: "Mid. Market Bill Rate",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.FTE
          ? null
          : row.getIn(["market_analysis", "market_bill_rate_mid"]),
      formatter: decimalFormatter,
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__market_bill_rate_max",
      title: "Max. Market Bill Rate",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.FTE
          ? null
          : row.getIn(["market_analysis", "market_bill_rate_max"]),
      formatter: decimalFormatter,
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__market_bill_rate_avg_variance",
      title: "Bill Rate Comparison",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.FTE
          ? null
          : row.getIn(["market_analysis", "market_bill_rate_avg_variance"]),
      formatter: (value, row) =>
        makeItStrongFormatter(coloredVarianceDecimalFormatter(value, row)),
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__market_bill_rate_min_variance",
      title: "Bill Rate Comparison",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.FTE
          ? null
          : row.getIn(["market_analysis", "market_bill_rate_min_variance"]),
      formatter: (value, row) =>
        makeItStrongFormatter(coloredVarianceDecimalFormatter(value, row)),
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__market_bill_rate_mid_variance",
      title: "Bill Rate Comparison",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.FTE
          ? null
          : row.getIn(["market_analysis", "market_bill_rate_mid_variance"]),
      formatter: (value, row) =>
        makeItStrongFormatter(coloredVarianceDecimalFormatter(value, row)),
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__market_bill_rate_max_variance",
      title: "Bill Rate Comparison",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.FTE
          ? null
          : row.getIn(["market_analysis", "market_bill_rate_max_variance"]),
      formatter: (value, row) =>
        makeItStrongFormatter(coloredVarianceDecimalFormatter(value, row)),
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      // this column proxies another column's value
      type: "column",
      uniqueKey: "market_analysis__market_annual_salary_avg",
      // filteruniqueKey: "market_analysis__market_pay_rate_avg",
      title: "Avg. Market Annual Salary",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.FTE
          ? row.getIn(["market_analysis", "market_pay_rate_avg"])
          : null,
      formatter: decimalFormatter,
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      // this column proxies another column's value
      type: "column",
      uniqueKey: "market_analysis__market_annual_salary_min",
      // filteruniqueKey: "market_analysis__market_pay_rate_min",
      title: "Min. Market Annual Salary",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.FTE
          ? row.getIn(["market_analysis", "market_pay_rate_min"])
          : null,
      formatter: decimalFormatter,
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      // this column proxies another column's value
      type: "column",
      uniqueKey: "market_analysis__market_annual_salary_mid",
      // filteruniqueKey: "market_analysis__market_pay_rate_mid",
      title: "Mid. Market Annual Salary",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.FTE
          ? row.getIn(["market_analysis", "market_pay_rate_mid"])
          : null,
      formatter: decimalFormatter,
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      // this column proxies another column's value
      type: "column",
      uniqueKey: "market_analysis__market_annual_salary_max",
      // filteruniqueKey: "market_analysis__market_pay_rate_max",
      title: "Max. Market Annual Salary",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.FTE
          ? row.getIn(["market_analysis", "market_pay_rate_max"])
          : null,
      formatter: decimalFormatter,
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__market_annual_salary_avg_variance",
      title: "Annual Salary Comparison",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.FTE
          ? row.getIn(["market_analysis", "market_annual_salary_avg_variance"])
          : null,
      formatter: (value, row) =>
        makeItStrongFormatter(coloredVarianceDecimalFormatter(value, row)),
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__market_annual_salary_min_variance",
      title: "Annual Salary Comparison",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.FTE
          ? row.getIn(["market_analysis", "market_annual_salary_min_variance"])
          : null,
      formatter: (value, row) =>
        makeItStrongFormatter(coloredVarianceDecimalFormatter(value, row)),
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__market_annual_salary_mid_variance",
      title: "Annual Salary Comparison",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.FTE
          ? row.getIn(["market_analysis", "market_annual_salary_mid_variance"])
          : null,
      formatter: (value, row) =>
        makeItStrongFormatter(coloredVarianceDecimalFormatter(value, row)),
      filterType: FilterTypes.NUMBERS_RANGE,
    },
    {
      type: "column",
      uniqueKey: "market_analysis__market_annual_salary_max_variance",
      title: "Annual Salary Comparison",
      getter: (row) =>
        row.get("rate_type") === RATE_TYPES.FTE
          ? row.getIn(["market_analysis", "market_annual_salary_max_variance"])
          : null,
      formatter: (value, row) =>
        makeItStrongFormatter(coloredVarianceDecimalFormatter(value, row)),
      filterType: FilterTypes.NUMBERS_RANGE,
    },
  ];
