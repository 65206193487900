// @flow

import { extendObservable, action, runInAction } from "mobx";
import numeral from "numeral";
import ModalState from "./ModalState";
import NetworkState from "./NetworkState";
import PunchOutRow from "./PunchOutRow";
import BuyRateState from "./BuyRateState";
import BuyRate from "./BuyRate";
import Search from "./Search";
import { LEVEL_ROMAN_NUMERAL, LEVEL_ABBREVIATION } from "../constants/levels";
import { LEVEL_COLOR, LEVEL_SELECTOR } from "../constants/css";
import { filterFloat } from "./SupportFunctions";
import type { FetchGraphQL } from "../App";

const query = `
mutation create($buyRateId: Int!,
                $levelId: Int!,
                $payRateMin: Float!,
                $payRateMax: Float!,
                $markupPct: Float!,
                $billRateMin: Float!,
                $billRateMax: Float!,
                $salaryMin: Float!,
                $salaryMax: Float!,
                ){
  createOrUpdatePunchOut(input: {
      buyRateId: $buyRateId,
      levelId: $levelId,
      payRateMin: $payRateMin,
      payRateMax: $payRateMax,
      markupPct: $markupPct,
      billRateMin: $billRateMin,
      billRateMax: $billRateMax,
      salaryMin: $salaryMin,
      salaryMax: $salaryMax,
    }) {

    punchOut {
      punchoutId
      levelId
      levelName
      levelRomanNumeral
      payRateMin
      payRateMax
      markupPct
      billRateMin
      billRateMax
      salaryMin
      salaryMax
    }

    errors {
      __typename
      ...on DoesNotExistError {
        message
        id
      }
    }
  }
}
`;

const deleteQuery = `
mutation delete($punchOutId: Int!){
  deletePunchOut(input: {
      punchOutId: $punchOutId,
    }) {

    errors {
      __typename
      ...on DoesNotExistError {
        message
      }
    }
  }
}
`;

type PunchOutRowStateProperty =
  | "payRateMin"
  | "payRateMax"
  | "markupPct"
  | "billRateMin"
  | "billRateMax"
  | "annualSalaryMin"
  | "annualSalaryMax";

export default class PunchOutRowState {
  $key: string;
  $value: ?string | ?number;

  fetchGraphQL: FetchGraphQL;
  modal: ModalState; // if modal is showing, the row is in input mode

  search: Search;
  buyRateState: BuyRateState;
  punchOutRow: ?PunchOutRow;
  submitRequired: boolean;
  levelNames: string[];

  // observables
  level: ?string;
  payRateMin: number;
  payRateMax: number;
  markupPct: number;
  billRateMin: number;
  billRateMax: number;
  annualSalaryMin: number;
  annualSalaryMax: number;
  payRateMinInputValue: ?string;
  payRateMaxInputValue: ?string;
  markupPctInputValue: ?string;
  billRateMinInputValue: ?string;
  billRateMaxInputValue: ?string;
  annualSalaryMinInputValue: ?string;
  annualSalaryMaxInputValue: ?string;
  payRateMinInputValueError: ?string;
  payRateMaxInputValueError: ?string;
  markupPctInputValueError: ?string;
  billRateMinInputValueError: ?string;
  billRateMaxInputValueError: ?string;
  annualSalaryMinInputValueError: ?string;
  annualSalaryMaxInputValueError: ?string;
  networkState: ?NetworkState;
  createMode: boolean; // determines if we have to create or update
  isCollapsed: boolean;

  onLevelChange: (SyntheticInputEvent<HTMLInputElement>) => void;
  onPayRateMinChange: (SyntheticInputEvent<HTMLInputElement>) => void;
  onPayRateMaxChange: (SyntheticInputEvent<HTMLInputElement>) => void;
  onMarkupPctChange: (SyntheticInputEvent<HTMLInputElement>) => void;
  onBillRateMinChange: (SyntheticInputEvent<HTMLInputElement>) => void;
  onBillRateMaxChange: (SyntheticInputEvent<HTMLInputElement>) => void;
  onAnnualSalaryMinChange: (SyntheticInputEvent<HTMLInputElement>) => void;
  onAnnualSalaryMaxChange: (SyntheticInputEvent<HTMLInputElement>) => void;
  onNumericPropertyChange: (string, SyntheticInputEvent<HTMLInputElement>) => void;
  enterInputMode: () => void;
  leaveInputMode: () => void;
  toggleCollapsed: () => void;
  showAddView: () => boolean;
  getMissingLevels: () => string[];
  getExistingLevels: () => string[];
  getLevelSelector: () => string;
  getLevelAbbreviation: () => string;
  getLevelRomanNumeral: () => string;
  getLevelColor: () => string;
  setNetworkStateError: (?Object) => void;
  setNetworkStateLoading: (boolean) => void;
  getNetworkStateLoading: () => boolean;
  validate: () => boolean;
  submit: (SyntheticKeyboardEvent<HTMLElement>) => void;
  submitOnKeyPress: (SyntheticKeyboardEvent<HTMLElement>) => void;
  delete: () => void;
  resetAndClose: () => void;
  reset: () => void;

  constructor(
    fetchGraphQL: FetchGraphQL,
    search: Search,
    buyRateState: BuyRateState,
    punchOutRow: ?PunchOutRow
  ) {
    this.fetchGraphQL = fetchGraphQL;
    this.modal = new ModalState();
    this.search = search;
    this.buyRateState = buyRateState;
    this.punchOutRow = punchOutRow;
    this.levelNames = Object.keys(LEVEL_ROMAN_NUMERAL);

    const levelDefault =
      this.findDefaultLevel(punchOutRow, buyRateState.buyRate) || this.levelNames[0];

    let payRateMinDefault = 0;
    let payRateMaxDefault = 0;
    let markupPctDefault = 0;
    let billRateMinDefault = 0;
    let billRateMaxDefault = 0;
    let annualSalaryMinDefault = 0;
    let annualSalaryMaxDefault = 0;

    if (punchOutRow) {
      payRateMinDefault = punchOutRow.payRateMin;
      payRateMaxDefault = punchOutRow.payRateMax;
      markupPctDefault = punchOutRow.markupPct;
      billRateMinDefault = punchOutRow.billRateMin;
      billRateMaxDefault = punchOutRow.billRateMax;
      annualSalaryMinDefault = punchOutRow.salaryMin;
      annualSalaryMaxDefault = punchOutRow.salaryMax;
    } else {
      const searchLevelRates = search.resultsByLevel[levelDefault];
      if (levelDefault && searchLevelRates) {
        payRateMinDefault = searchLevelRates.payRateMin;
        payRateMaxDefault = searchLevelRates.payRateMax;
        markupPctDefault = searchLevelRates.markupPctMax;
        billRateMinDefault = searchLevelRates.billRateMin;
        billRateMaxDefault = searchLevelRates.billRateMax;
        annualSalaryMinDefault = searchLevelRates.payRateMin;
        annualSalaryMaxDefault = searchLevelRates.payRateMax;
      }
    }

    extendObservable(this, {
      level: levelDefault,
      payRateMin: payRateMinDefault,
      payRateMax: payRateMaxDefault,
      markupPct: markupPctDefault,
      billRateMin: billRateMinDefault,
      billRateMax: billRateMaxDefault,
      annualSalaryMin: annualSalaryMinDefault,
      annualSalaryMax: annualSalaryMaxDefault,
      payRateMinInputValue: numeral(payRateMinDefault).format("0.00"),
      payRateMaxInputValue: numeral(payRateMaxDefault).format("0.00"),
      markupPctInputValue: numeral(markupPctDefault).format("0.00"),
      billRateMinInputValue: numeral(billRateMinDefault).format("0.00"),
      billRateMaxInputValue: numeral(billRateMaxDefault).format("0.00"),
      annualSalaryMinInputValue: numeral(annualSalaryMinDefault).format("0.00"),
      annualSalaryMaxInputValue: numeral(annualSalaryMaxDefault).format("0.00"),
      payRateMinInputValueError: null,
      payRateMaxInputValueError: null,
      markupPctInputValueError: null,
      billRateMinInputValueError: null,
      billRateMaxInputValueError: null,
      annualSalaryMinInputValueError: null,
      annualSalaryMaxInputValueError: null,
      networkState: new NetworkState(),
      createMode: !punchOutRow,
      isCollapsed: true,
    });

    this.onLevelChange = action(this.onLevelChange.bind(this));
    this.onPayRateMinChange = action(this.onPayRateMinChange.bind(this));
    this.onPayRateMaxChange = action(this.onPayRateMaxChange.bind(this));
    this.onMarkupPctChange = action(this.onMarkupPctChange.bind(this));
    this.onBillRateMinChange = action(this.onBillRateMinChange.bind(this));
    this.onBillRateMaxChange = action(this.onBillRateMaxChange.bind(this));
    this.onAnnualSalaryMinChange = action(this.onAnnualSalaryMinChange.bind(this));
    this.onAnnualSalaryMaxChange = action(this.onAnnualSalaryMaxChange.bind(this));
    this.onNumericPropertyChange = action(this.onNumericPropertyChange.bind(this));
    this.enterInputMode = action(this.enterInputMode.bind(this));
    this.leaveInputMode = action(this.leaveInputMode.bind(this));
    this.toggleCollapsed = action(this.toggleCollapsed.bind(this));
    this.showAddView = action(this.showAddView.bind(this));
    this.getMissingLevels = action(this.getMissingLevels.bind(this));
    this.getExistingLevels = action(this.getExistingLevels.bind(this));
    this.getLevelSelector = action(this.getLevelSelector.bind(this));
    this.getLevelAbbreviation = action(this.getLevelAbbreviation.bind(this));
    this.getLevelRomanNumeral = action(this.getLevelRomanNumeral.bind(this));
    this.getLevelColor = action(this.getLevelColor.bind(this));
    this.setNetworkStateError = action(this.setNetworkStateError.bind(this));
    this.setNetworkStateLoading = action(this.setNetworkStateLoading.bind(this));
    this.getNetworkStateLoading = action(this.getNetworkStateLoading.bind(this));
    this.validate = action(this.validate.bind(this));
    this.submit = action(this.submit.bind(this));
    this.submitOnKeyPress = action(this.submitOnKeyPress.bind(this));
    this.delete = action(this.delete.bind(this));
    this.resetAndClose = action(this.resetAndClose.bind(this));
    this.reset = action(this.reset.bind(this));

    let existingLevels = this.getExistingLevels();
    this.submitRequired = existingLevels.length === 0;
  }

  getLevelName = (levelId: number): string => {
    return this.levelNames[levelId - 1];
  };

  getLevelId = (levelName: string): number => {
    return this.levelNames.indexOf(levelName) + 1;
  };

  findDefaultLevel = (punchOutRow: ?PunchOutRow, buyRate: ?BuyRate): ?string => {
    if (!buyRate) return this.levelNames[0];

    if (punchOutRow) {
      return this.getLevelName(punchOutRow.levelId);
    }

    // if we have no punchOutRow,
    // look for the default level to take market values from.
    // the first empty level found should be the default
    const firstEmptyLevel = buyRate.punchOutRows.indexOf(null);
    if (firstEmptyLevel === -1) return null; // all levels filled
    return this.levelNames[firstEmptyLevel];
  };

  setNetworkStateError(error: ?Object) {
    this.buyRateState.networkState.error = error;
  }

  setNetworkStateLoading(loading: boolean) {
    this.buyRateState.networkState.loading = loading;
  }

  getNetworkStateLoading() {
    return this.buyRateState.networkState.loading;
  }

  onNumericPropertyChange(
    property: PunchOutRowStateProperty,
    e: SyntheticInputEvent<HTMLInputElement>
  ) {
    const inputValueProperty = `${property}InputValue`;
    const inputErrorProperty = `${property}InputValueError`;

    this[inputValueProperty] = e.target.value;
    this[property] = filterFloat(e.target.value);

    if (isNaN(this[property])) {
      this[property] = 0;
      this[inputValueProperty] = "0";
    } else this[inputErrorProperty] = null;
  }

  onLevelChange(e: SyntheticInputEvent<HTMLInputElement>) {
    this.level = e.target.value;
    if (this.createMode) {
      // Pre-fill punchout values with selected level market values

      const searchLevelRates = this.search.resultsByLevel[this.level];
      this.payRateMin = searchLevelRates ? searchLevelRates.payRateMin || 0 : 0;
      this.payRateMax = searchLevelRates ? searchLevelRates.payRateMax || 0 : 0;
      this.markupPct = searchLevelRates ? searchLevelRates.markupPctMax || 0 : 0;
      this.billRateMin = searchLevelRates ? searchLevelRates.billRateMin || 0 : 0;
      this.billRateMax = searchLevelRates ? searchLevelRates.billRateMax || 0 : 0;
      this.annualSalaryMin = searchLevelRates ? searchLevelRates.payRateMin || 0 : 0;
      this.annualSalaryMax = searchLevelRates ? searchLevelRates.payRateMax || 0 : 0;

      this.payRateMinInputValue = this.payRateMin ? this.payRateMin.toString() : "";
      this.payRateMaxInputValue = this.payRateMax ? this.payRateMax.toString() : "";
      this.markupPctInputValue = this.markupPct ? this.markupPct.toString() : "";
      this.billRateMinInputValue = this.billRateMin ? this.billRateMin.toString() : "";
      this.billRateMaxInputValue = this.billRateMax ? this.billRateMax.toString() : "";
      this.annualSalaryMinInputValue = this.annualSalaryMin
        ? this.annualSalaryMin.toString()
        : "";
      this.annualSalaryMaxInputValue = this.annualSalaryMax
        ? this.annualSalaryMax.toString()
        : "";
    }
  }

  onPayRateMinChange(e: SyntheticInputEvent<HTMLInputElement>) {
    this.onNumericPropertyChange("payRateMin", e);

    this.billRateMin = parseFloat(
      (this.payRateMin + (this.payRateMin * this.markupPct) / 100).toFixed(2)
    );
    this.billRateMinInputValue = this.billRateMin.toString();
    this.validate();
  }

  onPayRateMaxChange(e: SyntheticInputEvent<HTMLInputElement>) {
    this.onNumericPropertyChange("payRateMax", e);

    this.billRateMax = parseFloat(
      (this.payRateMax + (this.payRateMax * this.markupPct) / 100).toFixed(2)
    );
    this.billRateMaxInputValue = this.billRateMax.toString();
    this.validate();
  }

  onMarkupPctChange(e: SyntheticInputEvent<HTMLInputElement>) {
    this.onNumericPropertyChange("markupPct", e);

    this.billRateMin = parseFloat(
      (this.payRateMin + (this.payRateMin * this.markupPct) / 100).toFixed(2)
    );
    this.billRateMinInputValue = this.billRateMin.toString();

    this.billRateMax = parseFloat(
      (this.payRateMax + (this.payRateMax * this.markupPct) / 100).toFixed(2)
    );
    this.billRateMaxInputValue = this.billRateMax.toString();

    this.validate();
  }

  onBillRateMinChange(e: SyntheticInputEvent<HTMLInputElement>) {
    this.onNumericPropertyChange("billRateMin", e);

    // this.payRateMin = parseFloat((this.billRateMin - this.billRateMin * this.markupPct / 100).toFixed(2));
    // this.payRateMinInputValue = this.payRateMin.toString();
    this.validate();
  }

  onBillRateMaxChange(e: SyntheticInputEvent<HTMLInputElement>) {
    this.onNumericPropertyChange("billRateMax", e);

    // this.payRateMax = parseFloat((this.billRateMax - this.billRateMax * this.markupPct / 100).toFixed(2));
    // this.payRateMaxInputValue = this.payRateMax.toString();
    this.validate();
  }

  onAnnualSalaryMinChange(e: SyntheticInputEvent<HTMLInputElement>) {
    this.onNumericPropertyChange("annualSalaryMin", e);

    this.validate();
  }

  onAnnualSalaryMaxChange(e: SyntheticInputEvent<HTMLInputElement>) {
    this.onNumericPropertyChange("annualSalaryMax", e);

    this.validate();
  }

  toggleCollapsed() {
    if (this.getNetworkStateLoading()) return;

    if (this.submitRequired) return;

    this.isCollapsed = !this.isCollapsed;
    if (this.isCollapsed) this.resetAndClose();
  }

  enterInputMode() {
    this.modal.showModal();

    // if we're creating a new row,
    // select the first available level by default
    if (this.createMode) {
      let missingLevels = this.getMissingLevels();
      this.level = missingLevels[0];
    }

    // if screen size changes while modal is showing
    // the item should be expanded.
    this.isCollapsed = false;
  }

  leaveInputMode() {
    if (this.submitRequired) return;

    this.modal.hideModal();
  }

  showAddView() {
    // Show the add view if we have to create
    // and the modal is hidden
    return this.createMode && !this.modal.show;
  }

  getMissingLevels() {
    const missingLevels = [];
    const buyRate = this.buyRateState.buyRate;
    const punchOutRows = buyRate ? buyRate.punchOutRows : [];

    punchOutRows.forEach((punchOutRow: ?PunchOutRow, index: number) => {
      if (punchOutRow) return;

      const levelName = this.levelNames[index];
      missingLevels.push(levelName);
    });

    return missingLevels;
  }

  getExistingLevels() {
    const existingLevels = [];
    const buyRate = this.buyRateState.buyRate;
    const punchOutRows = buyRate ? buyRate.punchOutRows : [];

    punchOutRows.forEach((punchOutRow: ?PunchOutRow, index: number) => {
      if (!punchOutRow) return;

      const levelName = this.levelNames[index];
      existingLevels.push(levelName);
    });

    return existingLevels;
  }

  getLevelSelector() {
    let levelName = null;
    if (this.modal.show) levelName = this.level || this.levelNames[0];

    if (this.punchOutRow) levelName = this.getLevelName(this.punchOutRow.levelId);

    return levelName ? LEVEL_SELECTOR[levelName] : null;
  }

  getLevelAbbreviation() {
    let levelName = null;
    if (this.modal.show) levelName = this.level || this.levelNames[0];

    if (this.punchOutRow) levelName = this.getLevelName(this.punchOutRow.levelId);

    return levelName ? LEVEL_ABBREVIATION[levelName] : null;
  }

  getLevelRomanNumeral() {
    let levelName = null;
    if (this.modal.show) levelName = this.level || this.levelNames[0];

    if (this.punchOutRow) levelName = this.getLevelName(this.punchOutRow.levelId);

    return levelName ? LEVEL_ROMAN_NUMERAL[levelName] : null;
  }

  getLevelColor() {
    let levelName = null;
    if (this.modal.show) levelName = this.level || this.levelNames[0];

    if (this.punchOutRow) levelName = this.getLevelName(this.punchOutRow.levelId);

    return levelName ? LEVEL_COLOR[levelName] : null;
  }

  validate() {
    if (!isNaN(this.payRateMinInputValue))
      this.payRateMinInputValueError =
        this.payRateMin > this.payRateMax
          ? "Minimum Pay Rate cannot exceed Maximum Pay Rate"
          : null;

    if (!isNaN(this.payRateMaxInputValue))
      this.payRateMaxInputValueError =
        this.payRateMax < this.payRateMin
          ? "Maximum Pay Rate cannot be less than Minimum Pay Rate"
          : null;

    if (!isNaN(this.billRateMinInputValue))
      this.billRateMinInputValueError =
        this.billRateMin > this.billRateMax
          ? "Minimum Bill Rate cannot exceed Maximum Bill Rate"
          : null;

    if (!isNaN(this.billRateMaxInputValue))
      this.billRateMaxInputValueError =
        this.billRateMax < this.billRateMin
          ? "Maximum Bill Rate cannot be less than Minimum Bill Rate"
          : null;

    if (!isNaN(this.annualSalaryMinInputValue))
      this.annualSalaryMinInputValueError =
        this.annualSalaryMin > this.annualSalaryMax
          ? "Minimum Annual Salary cannot exceed Maximum Annual Salary"
          : null;

    if (!isNaN(this.annualSalaryMaxInputValue))
      this.annualSalaryMaxInputValueError =
        this.annualSalaryMax < this.annualSalaryMin
          ? "Maximum Annual Salary cannot be less than Minimum Annual Salary"
          : null;

    return (
      !this.payRateMinInputValueError &&
      !this.payRateMaxInputValueError &&
      !this.markupPctInputValueError &&
      !this.billRateMinInputValueError &&
      !this.billRateMaxInputValueError &&
      !this.annualSalaryMinInputValueError &&
      !this.annualSalaryMaxInputValueError
    );
  }

  submitOnKeyPress(event: SyntheticKeyboardEvent<HTMLElement>) {
    if (event.which === 13) {
      // enter
      this.submit(event);
    }

    if (event.which === 27) {
      // escape
      this.resetAndClose();
    }
  }

  async submit(event: SyntheticKeyboardEvent<HTMLElement>) {
    if (event) event.preventDefault();

    const buyRate = this.buyRateState.buyRate;
    const levelName = this.level;
    if (!buyRate) return;
    if (!levelName) return;

    if (this.getNetworkStateLoading()) return;

    if (!this.validate()) return;

    this.setNetworkStateLoading(true);
    let payload = null;
    try {
      payload = await this.fetchGraphQL(query, {
        buyRateId: buyRate.id,
        levelId: this.getLevelId(levelName),
        payRateMin: this.payRateMin,
        payRateMax: this.payRateMax,
        markupPct: this.markupPct,
        billRateMin: this.billRateMin,
        billRateMax: this.billRateMax,
        salaryMin: this.annualSalaryMin,
        salaryMax: this.annualSalaryMax,
      });
    } catch (e) {
      this.buyRateState.networkState.handleError("Create or Update Buy Rate", e);
      // TODO: Display user friendly error message
      return;
    }

    runInAction("submit--success", () => {
      this.setNetworkStateLoading(false);
      this.setNetworkStateError(null);
      if (
        this.buyRateState.networkState.logGraphQLError("createOrUpdateBuyRate", payload)
      ) {
        // TODO: Display user friendly error message
        return;
      }

      if (!payload) return;

      if (this.createMode) {
        let punchOutData = payload.data.createOrUpdatePunchOut.punchOut;
        let newPunchOut = new PunchOutRow(punchOutData);
        buyRate.punchOutRows[newPunchOut.levelId - 1] = newPunchOut;
      } else if (this.punchOutRow) {
        this.punchOutRow.payRateMin = this.payRateMin;
        this.punchOutRow.payRateMax = this.payRateMax;
        this.punchOutRow.markupPct = this.markupPct;
        this.punchOutRow.billRateMin = this.billRateMin;
        this.punchOutRow.billRateMax = this.billRateMax;
        this.punchOutRow.salaryMin = this.annualSalaryMin;
        this.punchOutRow.salaryMax = this.annualSalaryMax;
      }

      this.resetAndClose();
    });
  }

  async delete() {
    if (this.getNetworkStateLoading()) return;

    const punchOutRow = this.punchOutRow;
    if (!punchOutRow) return;

    this.setNetworkStateLoading(true);
    let payload = null;
    try {
      payload = await this.fetchGraphQL(deleteQuery, {
        punchOutId: punchOutRow.id,
      });
    } catch (e) {
      this.buyRateState.networkState.handleError("Delete Buy Rate", e);
      // TODO: Display user friendly error message
      return;
    }

    runInAction("submit--success", () => {
      this.setNetworkStateLoading(false);
      this.setNetworkStateError(null);
      if (this.buyRateState.networkState.logGraphQLError("deletePunchOutRow", payload)) {
        // TODO: Display user friendly error message
        return;
      }

      const buyRate = this.buyRateState.buyRate;
      if (buyRate) {
        this.buyRateState.deletedPunchOutRow =
          buyRate.punchOutRows[punchOutRow.levelId - 1];
        buyRate.punchOutRows[punchOutRow.levelId - 1] = null;
      }

      this.resetAndClose();
    });
  }

  resetAndClose() {
    if (this.getNetworkStateLoading()) return;

    if (this.submitRequired) return;

    this.reset();
    this.leaveInputMode();
  }

  reset() {
    this.level = this.levelNames[0];
    this.payRateMin = 0;
    this.payRateMax = 0;
    this.markupPct = 0;
    this.billRateMin = 0;
    this.billRateMax = 0;
    this.annualSalaryMin = 0;
    this.annualSalaryMax = 0;

    if (this.punchOutRow) {
      this.level = this.punchOutRow.levelName;
      this.payRateMin = this.punchOutRow.payRateMin || 0;
      this.payRateMax = this.punchOutRow.payRateMax || 0;
      this.markupPct = this.punchOutRow.markupPct || 0;
      this.billRateMin = this.punchOutRow.billRateMin || 0;
      this.billRateMax = this.punchOutRow.billRateMax || 0;
      this.annualSalaryMin = this.punchOutRow.salaryMin || 0;
      this.annualSalaryMax = this.punchOutRow.salaryMax || 0;
    } else {
      this.level = this.findDefaultLevel(this.punchOutRow, this.buyRateState.buyRate);
      if (!this.level) return; // all levels have been filled
      const searchLevelRates = this.search.resultsByLevel[this.level];
      this.payRateMin = searchLevelRates ? searchLevelRates.payRateMin || 0 : 0;
      this.payRateMax = searchLevelRates ? searchLevelRates.payRateMax || 0 : 0;
      this.markupPct = searchLevelRates ? searchLevelRates.markupPctMax || 0 : 0;
      this.billRateMin = searchLevelRates ? searchLevelRates.billRateMin || 0 : 0;
      this.billRateMax = searchLevelRates ? searchLevelRates.billRateMax || 0 : 0;
      this.annualSalaryMin = searchLevelRates ? searchLevelRates.payRateMin || 0 : 0;
      this.annualSalaryMax = searchLevelRates ? searchLevelRates.payRateMax || 0 : 0;
    }

    this.payRateMinInputValue = this.payRateMin.toString();
    this.payRateMaxInputValue = this.payRateMax.toString();
    this.markupPctInputValue = this.markupPct.toString();
    this.billRateMinInputValue = this.billRateMin.toString();
    this.billRateMaxInputValue = this.billRateMax.toString();
    this.annualSalaryMinInputValue = this.annualSalaryMin.toString();
    this.annualSalaryMaxInputValue = this.annualSalaryMax.toString();

    this.payRateMinInputValueError = null;
    this.payRateMaxInputValueError = null;
    this.markupPctInputValueError = null;
    this.billRateMinInputValueError = null;
    this.billRateMaxInputValueError = null;
    this.annualSalaryMinInputValueError = null;
    this.annualSalaryMaxInputValueError = null;
  }
}
