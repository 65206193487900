import React, { useCallback, useMemo } from "react";

import Stack from "../../../../components/lib/Stack";
import Box from "../../../../components/lib/Box";

import JobInternalValuesBlock from "../../components/JobInternalValuesBlock";
import { Val5000JobFeedbackBlock } from "../../components/JobFeedback";
import ReviewsPeckingOrderBlock from "../../components/ReviewsPeckingOrderBlock";
import { useReviewDataContext } from "../../context/ReviewDataContext";

import PageHeadBlock from "./PageHeadBlock";
import AttemptParametersBlock from "./AttemptParametersBlock";
import ValidatedTitlesBlock from "./ValidatedTitlesBlock";
import RatesValidationBlock from "./RatesValidationBlock";
import BackToListPageSection from "../../components/BackToListPageSection";

import RateDisplayContextProvider from "../../context/RateDisplayContext";
import { useVal5KAdminContext } from "../../context/Val5KAdminContext";

const AttemptItemPage = () => {
  const { router } = useVal5KAdminContext();

  const {
    reviewData,
    jobsData,
    firstJobData,
    reviewsGroupId,
    isClassicSurvey,
    isFillInTheBlankSurvey,
    isOrderedSurvey,
    isThreeLevelBanding,
  } = useReviewDataContext();

  const showInternalValuesBlock = useMemo(
    () =>
      reviewData.get("ratecard_id") != null ||
      (firstJobData.get("collection_id") != null &&
        firstJobData.get("country_id") != null),
    [reviewData, firstJobData]
  );

  // handlers

  const handleBackToList = useCallback(
    () => router.push("/admin/val5000/validations"),
    [router]
  );

  // rendering

  return (
    <RateDisplayContextProvider reviewData={reviewData}>
      <Stack>
        <PageHeadBlock />
        <AttemptParametersBlock />
        {isClassicSurvey && <RatesValidationBlock />}
        {isOrderedSurvey && jobsData.size > 0 && <ValidatedTitlesBlock />}
        {(isClassicSurvey || isFillInTheBlankSurvey) && showInternalValuesBlock && (
          <JobInternalValuesBlock />
        )}
        {(isClassicSurvey || isFillInTheBlankSurvey) && firstJobData?.size > 0 && (
          <Val5000JobFeedbackBlock
            // TODO remove these couple of props below and use context instead once the component will be migrated
            job={firstJobData}
            isThreeLevelBanding={isThreeLevelBanding}
          />
        )}
        {(isClassicSurvey || isFillInTheBlankSurvey) && reviewsGroupId && (
          <Box fill id="reviews-group">
            <ReviewsPeckingOrderBlock />
          </Box>
        )}
        <BackToListPageSection onButtonClick={handleBackToList} />
      </Stack>
    </RateDisplayContextProvider>
  );
};
AttemptItemPage.displayName = "AttemptItemPage";

export default AttemptItemPage;
