import { PageEventTypeEnum, PageEvent } from "../lib/Pagination";
import { RowData } from "./types";

export function isNumber(value: any): boolean {
  return isFinite(value) && !isNaN(parseFloat(value));
}

export const getChangingPageNumber = (
  pageEvent: PageEvent,
  currentPageNumber: number,
  totalPagesCount: number,
  defaultPageNumber: number = 1
) => {
  return pageEvent.type === PageEventTypeEnum.FIRST
    ? defaultPageNumber
    : pageEvent.type === PageEventTypeEnum.PREV
    ? Math.max(currentPageNumber - 1, defaultPageNumber)
    : pageEvent.type === PageEventTypeEnum.NEXT
    ? Math.min(currentPageNumber + 1, totalPagesCount)
    : pageEvent.type === PageEventTypeEnum.LAST
    ? totalPagesCount
    : pageEvent.type === PageEventTypeEnum.PAGE
    ? pageEvent.page
    : defaultPageNumber;
};

export function rowIdGetter<RD extends RowData>(row: RD): number {
  return row.get("id");
}
