import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import CheckBox from "../../../shared/CheckBox";
import classNames from "classnames";
import { Link } from "react-router";

const UserListItem = observer(
  class extends Component {
    static contextTypes = {
      router: PropTypes.object,
    };

    constructor(props) {
      super(props);

      this.handleViewDetail = this.handleViewDetail.bind(this);
    }

    handleViewDetail() {
      this.context.router.push({
        pathname: `/admin/users/${this.props.user.userId}`,
        query: this.context.router.query,
      });
    }

    render() {
      const props = this.props;
      const userDetail = props.user;
      const editing = userDetail.viewState.editing;
      const selected = userDetail.viewState.selected;
      var name = "";
      if (
        userDetail.firstName &&
        (userDetail.firstName !== "" || userDetail.firstName != null)
      ) {
        name = `${userDetail.firstName} ${userDetail.lastName}`;
      } else {
        name = "N/A";
      }
      const email = userDetail.email;
      //const clientName = userDetail.clientName;
      const id = userDetail.userId;
      const active = userDetail.active;
      const lastLogin = userDetail.lastLogin;
      const dateJoined = userDetail.dateJoined;
      const shareRateCards = userDetail.shareRateCards;
      const totalSearchesCount = userDetail.totalSearchesCount;
      const totalJobsCount = userDetail.totalJobsCount;
      const totalRatecardsCount = userDetail.totalRatecardsCount;
      const totalProjectsCount = userDetail.totalProjectsCount;
      // const totalBuyratesCount = userDetail.totalBuyratesCount;
      const username = userDetail.username;

      let onItemClick = this.props.onClick || this.handleViewDetail;
      let editingSection = null;
      //let sharedByYou = shared;

      if (editing) {
        onItemClick = userDetail.toggleSelected;
        editingSection = (
          <CheckBox
            type={props.store.allowMultipleItemSelection ? "checkbox" : "radio"}
            name="list-item-select"
            value={name}
            selected={selected}
            style={{ width: 34 }}
            onClick={onItemClick}
          />
        );
      }

      return (
        <div className="ratecard-list-item-container">
          <div
            className={classNames("ratecard-list-item", { selected: selected })}
            onClick={onItemClick}
          >
            <div className="ratecard-list-item-left ellipsis">
              {editingSection}
              <div className="ratecard-label-container ellipsis">
                <div className="ratecard-label ellipsis">
                  <span className="ellipsis">{name}</span>
                </div>
                <div className="ratecard-meta ellipsis">
                  <span className="item-subtext-muted">Registered with </span>
                  <span className="item-subtext-accent">{username}</span>
                </div>
                <div className="ratecard-meta ellipsis">
                  <span className="item-subtext-muted">Email </span>
                  <span className="item-subtext-accent">{email} </span>
                </div>
                <div className="ratecard-meta ellipsis">
                  <span className="item-subtext-muted">Share Retecards </span>
                  <span className="item-subtext-accent">
                    {shareRateCards ? "Yes" : "No"}{" "}
                  </span>
                </div>
                {dateJoined && (
                  <div className="ratecard-meta ellipsis">
                    <span className="item-subtext-muted">Created Login Date </span>
                    <span className="item-subtext-accent">{dateJoined} </span>
                  </div>
                )}
                {lastLogin && (
                  <div className="ratecard-meta ellipsis">
                    <span className="item-subtext-muted">Last Login </span>
                    <span className="item-subtext-accent">{lastLogin} </span>
                  </div>
                )}
                {!lastLogin && (
                  <div className="ratecard-meta ellipsis">
                    <span className="item-subtext-accent">Never logged in</span>
                  </div>
                )}
              </div>
            </div>
            <div className="ratecard-list-item-right">
              {
                <div className="ratecard-info w-300 text-center">
                  <header data-testid="ID-header-user-detail">ID</header>
                  <div>{id}</div>
                </div>
              }
              {/*            <div className="ratecard-info w-300 text-center">
              <header>Share Retecards</header>
              <div>{shareRateCards?'Yes':'No'}</div>
            </div>*/}
              <div className="ratecard-info w-300 text-center">
                <header data-testid="searches-header-user-detail">Searches</header>
                <div>{totalSearchesCount}</div>
              </div>
              <div className="ratecard-info w-300 text-center">
                <header data-testid="job-titles-header-user-detail">Job Titles</header>
                <div>{totalJobsCount}</div>
              </div>
              <div className="ratecard-info w-300 text-center">
                <header data-testid="ratecards-header-user-detail">Rate Cards</header>
                <div>{totalRatecardsCount}</div>
              </div>
              <div className="ratecard-info w-300 text-center">
                <header data-testid="PCE-header-user-detail">PCE</header>
                <div>{totalProjectsCount}</div>
              </div>
              {/*           <div className="ratecard-info w-300 text-center">
              <header>Worksheet</header>
              <div>{totalBuyratesCount}</div>
            </div>*/}
              <div className="ratecard-info w-300 text-center">
                <header data-testid="status-header-user-detail">Status</header>
                <div>{active ? "Active" : "Inactive"}</div>
              </div>
              {!editing && (
                <div className="rc-center">
                  {
                    <Link to={`/admin/users/${id}`} className="btn btn-default">
                      View
                    </Link>
                  }
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
);

UserListItem.propTypes = {
  store: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default UserListItem;
