import React from "react";

import { RatesTitleBase } from "../../../validator5K_admin/components/Rates/RatesBase";
import { getExpLevelInfo } from "../../../validator5K_admin/components/Rates/utils";
import {
  RATE_TYPES,
  RATE_TYPES_LABELS,
  SKILLS_LEVELS_3L_NUMERAL_LABELS,
  SKILLS_LEVELS_NUMERAL_LABELS,
} from "../../../validator5K_admin/types";
import { getJobRateTypeParameters } from "../../../validator5K_admin/utils";
import { useReviewDataContext } from "../../../validator5K_admin/context/ReviewDataContext";

import type { SKILLS_LEVELS_3L_TYPE, SKILLS_LEVELS_TYPE } from "../../types";
import type { RatesTitleProps } from "../../../validator5K_admin/components/Rates/types";
import { useRateDisplayContext } from "../../../validator5K_admin/context/RateDisplayContext";

function RatesTitle(props: RatesTitleProps) {
  const { expLevel } = props;

  const {
    firstJobData,
    firstJobRateType: rateType,
    isThreeLevelBanding,
  } = useReviewDataContext();
  const { displayRateType, displayRateMultiplier, resultingCurrencyData } =
    useRateDisplayContext();
  const resultingCurrencyName = resultingCurrencyData.get("name");

  const expLevelLabel = isThreeLevelBanding
    ? SKILLS_LEVELS_3L_NUMERAL_LABELS[expLevel as SKILLS_LEVELS_3L_TYPE]
    : SKILLS_LEVELS_NUMERAL_LABELS[expLevel as SKILLS_LEVELS_TYPE];
  const expLevelInfo = getExpLevelInfo(expLevel, isThreeLevelBanding);

  const { rateMultiplier } = React.useMemo(
    () => getJobRateTypeParameters(firstJobData, rateType),
    [firstJobData, rateType]
  );

  const rateTypeLabel = React.useMemo(() => {
    let result: string | React.ReactElement = RATE_TYPES_LABELS[rateType];

    if (
      rateType === RATE_TYPES.DAILY ||
      rateType === RATE_TYPES.WEEKLY ||
      rateType === RATE_TYPES.MONTHLY
    ) {
      result = (
        <span>
          {RATE_TYPES_LABELS[rateType]}
          {rateMultiplier != null ? ` (${rateMultiplier}h)` : ""}
        </span>
      );
    }

    if (
      displayRateType === RATE_TYPES.DAILY ||
      displayRateType === RATE_TYPES.WEEKLY ||
      displayRateType === RATE_TYPES.MONTHLY
    ) {
      result = (
        <span>
          {RATE_TYPES_LABELS[displayRateType]}
          {displayRateMultiplier != null && displayRateMultiplier > 1
            ? ` (${displayRateMultiplier}h)`
            : ""}
        </span>
      );
    }

    return result;
  }, [displayRateType, rateType, displayRateMultiplier, rateMultiplier]);

  return (
    <RatesTitleBase
      isThreeLevelBanding={isThreeLevelBanding}
      expLevelLabel={expLevelLabel}
      expLevelInfo={expLevelInfo}
      rateTypeLabel={rateTypeLabel}
      currencyLabel={resultingCurrencyName}
    />
  );
}

export default RatesTitle;
