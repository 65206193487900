// @flow

import { action, computed, extendObservable, runInAction } from "mobx";
import NetworkState from "../../models/NetworkState";
import ModalState from "../../models/ModalState";
import CCCCountry from "../../models/CCCCountry";
import matchSorter from "match-sorter";
import JobLibrary from "../../models/JobLibrary";
import RawJobTitle from "../../models/RawJobTitle";
import { DjangoApiPaginatedQueryArgs } from "../../models/Django";
import type MobXStore from "./MobXStore";
import type { FetchAPI, FetchGraphQL } from "../../App";
import { createTasteGraphQLWrapper } from "./SupportFunctions";

export default class JobLibraryTitleRequestCreateStore {
  fetchTasteGraphQL: FetchGraphQL;
  fetchGraphQL: FetchGraphQL;
  fetchAPI: FetchAPI;
  mobXStore: MobXStore;
  router: ?Object;
  network: NetworkState;
  userCountriesNetwork: NetworkState;
  successModal: ModalState;
  previousLocation: string;
  title: string;
  description: string;
  userMessage: string;
  titleError: string;
  descriptionError: string;
  selectedCountries: CCCCountry[];
  defaultSelectedCountries: ?(string[]);
  selectedCountriesError: string;
  userCountryCodes: string[];
  cccCountries: CCCCountry[];
  viewCountries: CCCCountry[];
  filteredCountries: CCCCountry[];
  loadingCountries: boolean;
  countryFilterQuery: string;
  requestNewCountries: boolean;
  libraries: JobLibrary[];
  filteredLibraries: JobLibrary[];
  selectedLibraries: JobLibrary[];
  loadingLibraries: boolean;
  libraryFilterQuery: string;

  submitRequest: () => void;
  getCCCCountries: () => Promise<any>;
  getLibraries: () => Promise<any>;
  getUserCountries: () => Promise<any>;
  reset: () => void;
  showSuccess: () => void;
  isValid: () => void;
  goBack: () => void;
  selectDefaultCountries: () => void;

  onTitleChange: (string) => void;
  onDescriptionChange: (string) => void;
  onUserMessageChange: (string) => void;
  onCountryFilterQueryChange: (string) => void;
  onAddSelectedCountry: (CCCCountry) => void;
  onRemoveSelectedCountry: (CCCCountry) => void;
  onSelectAllCountries: (string, Event) => void;
  onSelectNoneCountries: (string, Event) => void;
  onRequestNewCountriesChange: (Event) => void;

  onLibraryFilterQueryChange: (string) => void;
  onAddSelectedLibrary: (JobLibrary) => void;
  onRemoveSelectedLibrary: (JobLibrary) => void;
  onSelectAllLibraries: (string, Event) => void;
  onSelectNoneLibraries: (string, Event) => void;

  // categories
  // categoriesAutocompleteNetwork: NetworkState;
  // categoriesFound: string[];
  // category: string;
  // onCategoryChange: string => void;

  // rawJobTitles
  rawJobTitlesSelectNetwork: NetworkState;
  titleDescriptionModal: ModalState;
  rawJobTitles: RawJobTitle[];
  selectedRawJobTitle: ?RawJobTitle;
  rawJobTitlesFilterQuery: string;
  onRawJobTitlesFilterQueryChange: (string) => void;
  onSelectedRawJobTitleChange: (RawJobTitle) => void;
  onShowDescription: () => void;
  getRawJobTitles: (DjangoApiPaginatedQueryArgs) => Promise<any>;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI, mobXStore: MobXStore) {
    this.fetchTasteGraphQL = createTasteGraphQLWrapper(fetchAPI);
    this.fetchGraphQL = fetchGraphQL;
    this.fetchAPI = fetchAPI;
    this.mobXStore = mobXStore;

    // NOTE: Bound early to pass into pagination state
    extendObservable(this, {
      successModal: new ModalState(),
      network: new NetworkState(),
      userCountriesNetwork: new NetworkState(),
      title: "",
      description: "",
      userMessage: "",
      titleError: "",
      descriptionError: "",
      previousLocation: "/job-library",
      userCountryCodes: [],
      cccCountries: [],
      selectedCountries: [],
      defaultSelectedCountries: null,
      selectedCountriesError: "",
      // filteredCountries: [],
      loadingCountries: false,
      countryFilterQuery: "",
      requestNewCountries: false,
      viewCountries: computed(() => {
        if (this.requestNewCountries) {
          // show all countries
          return [...this.cccCountries];
        }

        // show only user countries
        return this.cccCountries.filter((item) => {
          return this.userCountryCodes.includes(
            item.iso3166Alpha2 ? item.iso3166Alpha2 : ""
          );
        });
      }),
      filteredCountries: computed(() => {
        return this.countryFilterQuery.trim()
          ? matchSorter(this.viewCountries, this.countryFilterQuery.trim(), {
              keys: ["title"],
            })
          : this.viewCountries;
      }),
      libraries: [],
      selectedLibraries: [],
      // filteredLibraries: [],
      loadingLibraries: false,
      libraryFilterQuery: "",
      filteredLibraries: computed(() => {
        return this.libraryFilterQuery.trim()
          ? matchSorter(this.libraries, this.libraryFilterQuery.trim(), {
              keys: ["title"],
            })
          : this.libraries;
      }),
      // categories
      // categoriesAutocompleteNetwork: new NetworkState(),
      // categoriesFound: [],
      // category: "",
      // rawJobTitles
      rawJobTitlesSelectNetwork: new NetworkState(),
      titleDescriptionModal: new ModalState(),
      rawJobTitles: [],
      selectedRawJobTitle: null,
      rawJobTitlesFilterQuery: "",
    });

    this.router = null;

    this.submitRequest = action(this.submitRequest.bind(this));
    this.getUserCountries = action(this.getUserCountries.bind(this));
    this.getCCCCountries = action(this.getCCCCountries.bind(this));
    this.reset = action(this.reset.bind(this));
    this.showSuccess = action(this.showSuccess.bind(this));
    this.isValid = action(this.isValid.bind(this));
    this.goBack = action(this.goBack.bind(this));
    this.onTitleChange = action(this.onTitleChange.bind(this));
    this.onDescriptionChange = action(this.onDescriptionChange.bind(this));
    this.onUserMessageChange = action(this.onUserMessageChange.bind(this));
    this.onCountryFilterQueryChange = action(this.onCountryFilterQueryChange.bind(this));
    this.onAddSelectedCountry = action(this.onAddSelectedCountry.bind(this));
    this.onRemoveSelectedCountry = action(this.onRemoveSelectedCountry.bind(this));
    this.onSelectAllCountries = action(this.onSelectAllCountries.bind(this));
    this.onSelectNoneCountries = action(this.onSelectNoneCountries.bind(this));
    this.selectDefaultCountries = action(this.selectDefaultCountries.bind(this));
    this.onRequestNewCountriesChange = action(
      this.onRequestNewCountriesChange.bind(this)
    );

    this.getLibraries = action(this.getLibraries.bind(this));
    this.onAddSelectedLibrary = action(this.onAddSelectedLibrary.bind(this));
    this.onRemoveSelectedLibrary = action(this.onRemoveSelectedLibrary.bind(this));
    this.onLibraryFilterQueryChange = action(this.onLibraryFilterQueryChange.bind(this));
    this.onSelectAllLibraries = action(this.onSelectAllLibraries.bind(this));
    this.onSelectNoneLibraries = action(this.onSelectNoneLibraries.bind(this));
    // this.onCategoryChange = action(this.onCategoryChange.bind(this));
    // rawJobTitles
    this.onRawJobTitlesFilterQueryChange = action(
      this.onRawJobTitlesFilterQueryChange.bind(this)
    );
    this.onSelectedRawJobTitleChange = action(
      this.onSelectedRawJobTitleChange.bind(this)
    );
    this.getRawJobTitles = action(this.getRawJobTitles.bind(this));
    this.onShowDescription = action(this.onShowDescription.bind(this));
  }

  async getUserCountries(): Promise<any> {
    if (this.userCountriesNetwork.loading === true) {
      // TODO: cancel request when axios is added
      return; // for now just try to debounce
    }

    const query = `
      query getUserCountries {
        viewer {
          countries {
            isoCode
          }
        }
      }
    `;

    this.userCountriesNetwork.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, {});
    } catch (e) {
      if (this.userCountriesNetwork.isCancelError(e)) {
        return e;
      }

      this.userCountriesNetwork.handleError("Getting Client UserCountries", e);
      if (res !== null) {
        this.userCountriesNetwork.logGraphQLError("Get Client UserCountries query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getUserCountries--success", () => {
      this.userCountriesNetwork.loading = false;
      this.userCountriesNetwork.error = null;
      if (
        this.userCountriesNetwork.logGraphQLError("Get Client UserCountries query", res)
      ) {
        // TODO: Display user friendly error message
        return;
      }

      if (!res) {
        return;
      }

      const userCountries = res.data.viewer.countries;

      this.userCountryCodes = userCountries.map((userCountry) => {
        return userCountry.isoCode;
      });

      return this.userCountryCodes;
    });
  }

  async getCCCCountries(): Promise<any> {
    if (this.network.loading) {
      // NOTE: Debounces submitRequest
      return;
    }

    if (this.cccCountries.length) return;

    const query = `
      query requestCCCCountries {
        cccCountries(order: [{field: TITLE}]) {
          edges {
            node {
              databaseId
              title
              iso3166Alpha2
            }
          }
        }
      }
    `;

    this.network.loading = true;
    this.loadingCountries = true;
    let res: ?Object = null;

    try {
      res = await this.fetchTasteGraphQL(query);
    } catch (e) {
      this.network.handleError("Requesting CCC Countries", e);
      // TODO: Display user friendly error message
      return;
    }

    return runInAction("getCCCCountries--success", () => {
      this.network.loading = false;
      this.network.error = null;
      this.loadingCountries = false;

      if (this.network.logGraphQLError("requestCCCCountries", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.network.loading = false;

      if (!res || !res.data) return;

      let cccCountries = [];
      if (res.data.cccCountries) {
        cccCountries = res.data.cccCountries.edges.map((edge) => {
          return new CCCCountry(this, edge.node);
        });
      }

      this.cccCountries = cccCountries;

      // if (this.requestNewCountries) {
      //   filter new countries for user
      // this.filteredCountries = this.cccCountries.filter(item => {
      //   return !this.userCountryCodes.includes(item.iso3166Alpha2 ? item.iso3166Alpha2 : "");
      // });
      // } else {
      //   filter user countries
      //   this.filteredCountries = this.cccCountries.filter(item => {
      //   return this.userCountryCodes.includes(item.iso3166Alpha2 ? item.iso3166Alpha2 : "");
      // });
      // }

      this.selectDefaultCountries();
    });
  }

  async getLibraries(): Promise<any> {
    if (this.loadingLibraries) {
      // NOTE: Debounces loading libraries
      return;
    }

    if (this.libraries.length) return;

    const query = { query: "", variables: {} };
    query.query = `
      query getClientLibraries {
        clientLibraries(order: [{field: NAME}]) {
          totalCount
          edges {
            node {
              id
              databaseId
              name
              systemLibrary
            }
          }
        }
      }
    `;

    this.loadingCountries = true;
    let res: ?Object = null;

    try {
      res = await this.fetchTasteGraphQL(query.query, query.variables);
    } catch (e) {
      this.network.handleError("Requesting Libraries", e);
      // TODO: Display user friendly error message
      return;
    }

    return runInAction("getLibraries--success", () => {
      this.loadingLibraries = true;
      this.network.error = null;

      if (this.network.logGraphQLError("getLibraries", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.loadingLibraries = false;

      if (!res || !res.data) return;

      let libraries = [];
      if (res.data.clientLibraries) {
        libraries = res.data.clientLibraries.edges.map((edge) => {
          return new JobLibrary(this, edge.node);
        });
      }

      this.libraries = libraries;
    });
  }

  // async getCategories(): Promise<any> {
  //   if (this.categoriesAutocompleteNetwork.loading === true) {
  //     // this.tasteApi.cancelCurrentRequest();
  //     // TODO: cancel request when axios is added
  //     return; // for now just try to debounce
  //   }
  //
  //   const query = `
  //     query getCategories($categoryFilter: String) {
  //       clientJobTitleCategories(filters: {nameOrId: $categoryFilter}, order: [{field: NAME}]) {
  //         edges {
  //           node {
  //             name
  //           }
  //         }
  //       }
  //     }
  //   `;
  //
  //   const variables = { categoryFilter: this.category };
  //
  //   this.categoriesAutocompleteNetwork.loading = true;
  //   let res = null;
  //
  //   try {
  //     res = await this.fetchTasteGraphQL(query, variables);
  //   } catch (e) {
  //     if (this.categoriesAutocompleteNetwork.isCancelError(e)) {
  //       return e;
  //     }
  //
  //     this.categoriesAutocompleteNetwork.handleError("Getting Client Categories", e);
  //     if (res !== null) {
  //       this.categoriesAutocompleteNetwork.logGraphQLError("Get Client Categories query", res);
  //     }
  //
  //     // TODO: Display user friendly error message
  //     return e;
  //   }
  //
  //   return runInAction("getCategories--success", () => {
  //     this.categoriesAutocompleteNetwork.loading = false;
  //     this.categoriesAutocompleteNetwork.error = null;
  //     if (this.categoriesAutocompleteNetwork.logGraphQLError("Get Client Categories query", res)) {
  //       // TODO: Display user friendly error message
  //       return;
  //     }
  //
  //     if (!res) {
  //       return;
  //     }
  //
  //     const categories = res.data.clientJobTitleCategories.edges;
  //
  //     this.categoriesFound = categories.map(category => {
  //       return category.node.name;
  //     });
  //
  //     return this.categoriesFound;
  //   });
  // }

  async submitRequest() {
    if (this.network.loading) {
      // NOTE: Debounces submitRequest
      return;
    }

    if (!this.isValid()) return;

    let query = {};
    query.operationName = "requestJobTitle";
    query.query = `
      mutation requestJobTitle(
        $jobTitle: String!, 
        $jobDescription: String!, 
        $cccCountryIds: [ID]!, 
        $newCountries: Boolean, 
        $mappedRawTitleId: ID,
        $libraryIds: [ID], 
        # $category: String,
        $userMessage: String
      ){
        createClientJobTitleRequest(input: {
            jobTitle: $jobTitle,
            jobDescription: $jobDescription,
            cccCountryIds: $cccCountryIds,
            mappedRawTitleId: $mappedRawTitleId,
            libraryIds: $libraryIds,
            isCountryRequest: $newCountries,
            # categoryName: $category,
            userMessage: $userMessage
          }) {

          success {
            message
          }

          errors {
            ...on NotAllowedForCurrentUserError {
              message
            }
            ...on RequiredFieldEmptyError {
              message
            }
            ...on DoesNotExistError {
              message
            }
          }
        }
      }
    `;

    query.variables = {
      jobTitle: this.title,
      jobDescription: this.description,
      cccCountryIds: this.selectedCountries.map((c) => c.id),
      newCountries: this.requestNewCountries,
      mappedRawTitleId: this.selectedRawJobTitle ? this.selectedRawJobTitle.id : null,
      libraryIds: this.selectedLibraries.map((l) => l.id),
      // category: this.category,
      userMessage: this.userMessage,
    };

    this.network.loading = true;
    let res: ?Object = null;

    try {
      res = await this.fetchTasteGraphQL(query.query, query.variables);
    } catch (e) {
      this.network.handleError("Requesting Job Title", e);
      // TODO: Display user friendly error message
      return;
    }

    runInAction("submitRequest--success", () => {
      this.network.loading = false;
      this.network.error = null;

      if (this.network.logGraphQLError("requestJobTitle", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.showSuccess();
    });
  }

  selectDefaultCountries() {
    if (!this.defaultSelectedCountries) return;

    const defaults = this.defaultSelectedCountries.slice() || [];
    this.selectedCountries = this.cccCountries.filter((country) => {
      return defaults.includes(String(country.id) || "");
    });
  }

  reset() {
    this.title = "";
    this.description = "";
    this.userMessage = "";
    this.successModal.hideModal();
    this.selectedCountries = [];
    this.selectedLibraries = [];
    this.loadingCountries = false;
    this.loadingLibraries = false;
    this.requestNewCountries = false;
    this.countryFilterQuery = "";
    this.libraryFilterQuery = "";
    this.rawJobTitles = [];
    this.selectedRawJobTitle = null;
    this.rawJobTitlesFilterQuery = "";

    this.selectDefaultCountries();
  }

  isValid() {
    if (!this.title) this.titleError = "A title is required.";
    else this.titleError = "";

    if (!this.description) this.descriptionError = "A description is required.";
    else this.descriptionError = "";

    if (!this.selectedCountries.length)
      this.selectedCountriesError = "Select at least one country.";
    else this.selectedCountriesError = "";

    return !this.titleError && !this.descriptionError && !this.selectedCountriesError;
  }

  goBack() {
    this.defaultSelectedCountries = null;
    this.reset();
    if (this.router) {
      this.router.push({
        pathname: this.previousLocation,
        query: this.router.query,
      });
    }
  }

  showSuccess() {
    this.successModal.showModal();
  }

  onTitleChange(value: string) {
    this.title = value;
    this.titleError = "";
  }

  // onCategoryChange(value: string) {
  //   this.category = value || "";
  //
  //   if (this.category.length > 1) {
  //     this.getCategories();
  //   } else {
  //     this.categoriesFound = [];
  //   }
  // }

  onDescriptionChange(value: string) {
    this.description = value;
    this.descriptionError = "";
  }

  onUserMessageChange(value: string) {
    this.userMessage = value;
  }

  onCountryFilterQueryChange(filterQuery: string) {
    this.countryFilterQuery = filterQuery;
    this.selectedCountriesError = "";
  }

  onAddSelectedCountry(country: CCCCountry) {
    this.selectedCountries.push(country);
    this.countryFilterQuery = "";
    this.selectedCountriesError = "";
  }

  onRemoveSelectedCountry(country: CCCCountry) {
    this.selectedCountries = this.selectedCountries.filter((c) => c !== country);
  }

  onSelectAllCountries(key: string, e: Event) {
    if (e) {
      e.preventDefault();
    }
    this.selectedCountries = this.viewCountries;
  }

  onSelectNoneCountries(key: string, e: Event) {
    if (e) {
      e.preventDefault();
    }
    this.selectedCountries = [];
  }

  onRequestNewCountriesChange(e: Event) {
    this.requestNewCountries = !this.requestNewCountries;
    this.countryFilterQuery = "";

    if (!this.requestNewCountries) {
      // remove countries that user don't have access to from selection
      this.selectedCountries = this.selectedCountries.filter((item) => {
        const selectedCountryCode = item.iso3166Alpha2 ? item.iso3166Alpha2 : "";
        return this.userCountryCodes.includes(selectedCountryCode);
      });
    }
  }

  onLibraryFilterQueryChange(filterQuery: string) {
    this.libraryFilterQuery = filterQuery;
  }

  onAddSelectedLibrary(library: JobLibrary) {
    this.selectedLibraries.push(library);
    this.onLibraryFilterQueryChange("");
  }

  onRemoveSelectedLibrary(library: JobLibrary) {
    this.selectedLibraries = this.selectedLibraries.filter((c) => c !== library);
  }

  onSelectAllLibraries(key: string, e: Event) {
    if (e) {
      e.preventDefault();
    }
    this.selectedLibraries = this.libraries;
  }

  onSelectNoneLibraries(key: string, e: Event) {
    if (e) {
      e.preventDefault();
    }
    this.selectedLibraries = [];
  }

  // ---------------------------------
  //   RawJobTitles
  // ---------------------------------

  onRawJobTitlesFilterQueryChange(query: string) {
    this.rawJobTitlesFilterQuery = query;
    if (!query || query === "") return;

    this.rawJobTitles = [];
    this.getRawJobTitles(new DjangoApiPaginatedQueryArgs({ page: 1, page_size: 50 }));
  }

  onSelectedRawJobTitleChange(rawJobTitle: RawJobTitle) {
    this.selectedRawJobTitle = rawJobTitle;
  }

  onShowDescription() {
    if (!this.selectedRawJobTitle) return;

    this.selectedRawJobTitle.getDescription();
    this.titleDescriptionModal.showModal();
  }

  async getRawJobTitles(pageQuery: DjangoApiPaginatedQueryArgs): Promise<any> {
    // if (!this.jobTitlesHasNextPage()) return;

    if (this.rawJobTitlesSelectNetwork.loading) {
      this.rawJobTitlesSelectNetwork.cancel();
    }

    this.rawJobTitlesSelectNetwork.loading = true;
    let res = null;

    const query = `
    query getRawJobTitlesFuzzySearch(
      $pageSize: Int, 
      $page: Int, 
      $search: String, 
      $libraryId: ID, 
      $countryId: ID, 
      $libraryTitlesOnly: Boolean, 
      $stockTitlesOnly: Boolean
    ) {
      viewer {
        jobTitles( 
          pageSize: $pageSize, 
          page: $page,
          search: $search, 
          libraryId: $libraryId, 
          countryId: $countryId, 
          libraryTitlesOnly: $libraryTitlesOnly, 
          stockTitlesOnly: $stockTitlesOnly
        ) {
          totalCount
          results {
            id
            title
            collection
            isJobLabel
            category
            shareInfo {
              jobLabelId
              searchOnly
              isMine
              sharedBy {
                userId
                firstName
                lastName
              }
            }
            clientJobLibraryInfo {
              created
              categoryName
              adhocCountries
              certifiedCountries
              searchableCountries
              mappedRawJobTitleTitle
              mappedRawJobTitleId
            }
          }
        }
      }
    }
    `;

    const variables = {
      pageSize: pageQuery.page_size,
      page: pageQuery.page,
      search:
        this.rawJobTitlesFilterQuery && this.rawJobTitlesFilterQuery.trim() !== ""
          ? this.rawJobTitlesFilterQuery
          : null,
      stockTitlesOnly: null,
    };

    try {
      res = await this.fetchGraphQL(
        query,
        variables,
        this.rawJobTitlesSelectNetwork.getCancelToken()
      );
    } catch (e) {
      if (this.rawJobTitlesSelectNetwork.isCancelError(e)) {
        return e;
      }

      this.network.handleError("Getting Client JobTitles", e);

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getJobTitles--success", () => {
      this.rawJobTitlesSelectNetwork.loading = false;
      this.network.error = null;

      if (!res) {
        return { count: 0, next: null, previous: null };
      }

      // if (this.jobTitlesNextPage) {
      //   this.jobTitlesCurrentPage = this.jobTitlesNextPage;
      //   this.jobTitlesNextPage = res.next ? this.jobTitlesNextPage + 1 : null;
      // }

      const jobTitlesData = res.data.viewer.jobTitles.results;

      this.rawJobTitles = this.rawJobTitles.concat(
        jobTitlesData.map((jobTitleData) => {
          return new RawJobTitle(this, jobTitleData);
        })
      );

      // this.jobTitlesTotalMatchesCount = res.count;

      return {
        count: res.count,
        next: res.next,
        previous: res.previous,
      };
    });
  }
}
