import React from "react";
import GroupChart from "./GroupChart";
import GroupChartSummary from "./GroupChartSummary";

const GroupChartList = (props) => {
  const { groups, store, payRateHourly } = props;
  return (
    <div>
      {groups.map((group) => (
        <GroupChart
          key={group.id}
          group={group}
          store={store}
          payRateHourly={payRateHourly}
        />
      ))}
      <GroupChartSummary groups={groups} store={store} payRateHourly={payRateHourly} />
    </div>
  );
};

export default GroupChartList;
