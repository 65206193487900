import React from "react";

import { shallowEqual } from "../../utils/immutableRender";

import TableImpl from "./impl/TableImpl";
import { TableGroups } from "./parts/TableGroups";
import { TableHead } from "./parts/TableHead";
import { TableBody } from "./parts/TableBody";
import { emptyList } from "./constants";

import type { ColumnLikeElement, GroupLikeElement, ColumnProps } from "./Schema";
import type {
  ColumnConfigObject,
  GroupConfigObject,
  SchemaConfigObject,
  RowOnClickHandler,
  CellOnClickHandler,
  RowIdGetterFunction,
  TableData,
  RowData,
} from "./types";
import { rowIdGetter as defaultRowIdGetter } from "./utils";

export type IsSchemaChangedPredicate<RD = RowData> = (
  children: TableProps<RD>["children"],
  nextChildren: TableProps<RD>["children"]
) => boolean;

export function isSchemaChangedPredicate<RD = RowData>(
  children: TableProps<RD>["children"],
  nextChildren: TableProps<RD>["children"]
): boolean {
  const columnsPropsArray: ColumnProps<RD>[] = [];
  const nextColumnsPropsArray: ColumnProps<RD>[] = [];
  let isSchemaChanged = false;

  React.Children.map(
    children,
    (ch) => ch && ch.props && columnsPropsArray.push({ ...ch.props } as ColumnProps<RD>)
  );
  React.Children.map(
    nextChildren,
    (ch) =>
      ch && ch.props && nextColumnsPropsArray.push({ ...ch.props } as ColumnProps<RD>)
  );

  // check if schema changed
  if (columnsPropsArray.length !== nextColumnsPropsArray.length) {
    isSchemaChanged = true;
  } else {
    for (let i = 0; i < columnsPropsArray.length; i++) {
      if (!shallowEqual(columnsPropsArray[i], nextColumnsPropsArray[i])) {
        isSchemaChanged = true;
        break;
      }
    }
  }

  return isSchemaChanged;
}

export type ProcessColumnFunction<RD = RowData> = (
  columnElement: ColumnLikeElement<RD>
) => ColumnConfigObject<RD>;

export function processColumnFunction<RD = RowData>(
  columnElement: ColumnLikeElement<RD>
): ColumnConfigObject<RD> {
  return { ...columnElement.props };
}

export type ProcessGroupFunction<RD = RowData> = (
  groupElement: GroupLikeElement<RD>,
  idxOffset: number,
  processColumn?: ProcessColumnFunction<RD>
) => [ColumnConfigObject<RD>[], GroupConfigObject];

export function processGroupFunction<RD = RowData>(
  groupElement: GroupLikeElement<RD>,
  idxOffset: number,
  processColumn: ProcessColumnFunction<RD> = processColumnFunction
): [ColumnConfigObject<RD>[], GroupConfigObject] {
  const { children, ...restGroupProps } = groupElement.props;
  const columnsConfigs: ColumnConfigObject<RD>[] = [];
  const groupConfig: GroupConfigObject = {
    ...restGroupProps,
    columns: [],
  };
  let idx = 0;

  React.Children.forEach(children, (columnElement) => {
    if (columnElement && columnElement.props?.children == null) {
      const columnConfig = processColumn(columnElement);

      if (columnConfig) {
        columnsConfigs.push(columnConfig);
        groupConfig.columns.push(idxOffset + idx);
        idx++;
      }
    }
  });

  return [columnsConfigs, groupConfig];
}

export type ProcessSchemaFunction<RD = RowData> = (
  children: TableProps<RD>["children"],
  processColumn?: ProcessColumnFunction<RD>,
  processGroup?: ProcessGroupFunction<RD>
) => SchemaConfigObject<RD>;

export function processSchemaFunction<RD = RowData>(
  children: TableProps<RD>["children"],
  processColumn: ProcessColumnFunction<RD> = processColumnFunction,
  processGroup: ProcessGroupFunction<RD> = processGroupFunction
): SchemaConfigObject<RD> {
  const schema: SchemaConfigObject<RD> = { columns: [], groups: [] };

  React.Children.forEach(children, (element) => {
    if (element && element.props?.children == null) {
      const columnConfigObject = processColumn(element as ColumnLikeElement<RD>);

      if (columnConfigObject) {
        schema.groups.push({
          uniqueKey: columnConfigObject.uniqueKey,
          title: "",
          columns: [schema.columns.length],
        });
        schema.columns.push(columnConfigObject);
      }
    } else if (element) {
      const [columnsConfig, groupConfigObject] = processGroup(
        element as GroupLikeElement<RD>,
        schema.columns.length,
        processColumn
      );

      if (columnsConfig.length > 0) {
        schema.columns = schema.columns.concat(columnsConfig);
        schema.groups.push(groupConfigObject);
      }
    }
  });

  return schema;
}

export function useTableSchemaState<RD = RowData>(
  children: TableProps<RD>["children"],
  isSchemaChanged: IsSchemaChangedPredicate<RD> = isSchemaChangedPredicate,
  processSchema: ProcessSchemaFunction<RD> = processSchemaFunction,
  processColumn: ProcessColumnFunction<RD> = processColumnFunction,
  processGroup: ProcessGroupFunction<RD> = processGroupFunction
): [
  SchemaConfigObject<RD>,
  React.Dispatch<React.SetStateAction<SchemaConfigObject<RD>>>
] {
  const [schemaState, setSchemaState] = React.useState<SchemaConfigObject<RD>>(
    processSchema(children, processColumn, processGroup)
  );
  const prevChildrenRef = React.useRef<TableProps<RD>["children"]>(children);

  React.useEffect(() => {
    if (isSchemaChanged(prevChildrenRef.current, children)) {
      setSchemaState(processSchema(children, processColumn, processGroup));
    }
    prevChildrenRef.current = children;
  }, [children, isSchemaChanged, processSchema, processColumn, processGroup]);

  return [schemaState, setSchemaState];
}

export type TableChild<RD = RowData> =
  | ColumnLikeElement<RD>
  | GroupLikeElement<RD>
  | false
  | null
  | undefined;

export type TableProps<RD = RowData> = {
  schema: SchemaConfigObject<RD>;
  data: TableData<RD>;
  bodyEmptyText?: React.ReactNode;
  highlighted?: boolean;
  // row/cell selection
  onRowClick?: RowOnClickHandler<RD>;
  onCellClick?: CellOnClickHandler<RD>;
  rowIdGetter?: RowIdGetterFunction<RD>; // for row highlighting
  selectedRowId?: string | number | null | undefined; // for row highlighting
  selectedCellId?: string | number | null | undefined; // for cell highlighting
  children: TableChild<RD> | Array<TableChild<RD>>;
};

/**
 * 1. This table implementation works with data wrapped with ImmutableJS by default
 * 2. This table implementation is styling agnostic.
 *   In order to use another styling approach rewrite implementations components under ./impl directory.
 */
export const Table = <RD = RowData,>(props: TableProps<RD>) => {
  const {
    schema,
    data,
    bodyEmptyText,
    highlighted = false,
    selectedRowId,
    selectedCellId,
    rowIdGetter,
    onRowClick,
    onCellClick,
  } = props;

  const tableGroups = <TableGroups groups={schema.groups} columns={schema.columns} />;
  const tableHead = <TableHead schema={schema} />;

  const tableBody = (
    <TableBody
      schema={schema}
      data={data}
      bodyEmptyText={bodyEmptyText}
      selectedRowId={selectedRowId}
      selectedCellId={selectedCellId}
      rowIdGetter={rowIdGetter!}
      onRowClick={onRowClick}
      onCellClick={onCellClick}
    />
  );

  return (
    <TableImpl
      groups={tableGroups}
      head={tableHead}
      body={tableBody}
      highlighted={highlighted}
    />
  );
};
Table.displayName = "Table";
Table.defaultProps = {
  data: emptyList,
  // row/cell selection
  rowIdGetter: defaultRowIdGetter,
  bodyEmptyText: "No data",
};

export default Table;
