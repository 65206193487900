/**
 * @generated SignedSource<<791a81afc902b5769dafaa956e01ecec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TagsContentTypeEnums = "JOB_LIBRARY" | "NEGOTIATION_WORKSHEET" | "PROJECT_STATS" | "RATE_CARD" | "REGIONS" | "SAVED_SEARCHES" | "%future added value";
export type TagsFiltersInput = {
  clientIdIn?: ReadonlyArray<string | null> | null;
  clientidIn?: ReadonlyArray<number | null> | null;
  fromDate?: string | null;
  isShared?: boolean | null;
  nameIContains?: string | null;
  ownerId?: ReadonlyArray<string | null> | null;
  toDate?: string | null;
  updatedFromDate?: string | null;
  updatedToDate?: string | null;
};
export type FilterAndSortTagsQuery$variables = {
  contentType?: TagsContentTypeEnums | null;
  count?: number | null;
  cursor?: string | null;
  filters?: TagsFiltersInput | null;
};
export type FilterAndSortTagsQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"FilterAndSortTags_viewer">;
  } | null;
};
export type FilterAndSortTagsQuery = {
  response: FilterAndSortTagsQuery$data;
  variables: FilterAndSortTagsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contentType"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v4 = {
  "kind": "Variable",
  "name": "contentType",
  "variableName": "contentType"
},
v5 = {
  "kind": "Variable",
  "name": "filters",
  "variableName": "filters"
},
v6 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v4/*: any*/),
  (v5/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Literal",
    "name": "order",
    "value": [
      {
        "direction": "ASC",
        "field": "NAME"
      }
    ]
  },
  {
    "kind": "Literal",
    "name": "section",
    "value": "ADMIN"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FilterAndSortTagsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v4/*: any*/),
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v5/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "FilterAndSortTags_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "FilterAndSortTagsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "TagNodeConnection",
            "kind": "LinkedField",
            "name": "tags",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TagNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TagNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "filters": [
              "filters"
            ],
            "handle": "connection",
            "key": "FilterAndSortTags_tags",
            "kind": "LinkedHandle",
            "name": "tags"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "564f1242313af218776f377411e9541f",
    "id": null,
    "metadata": {},
    "name": "FilterAndSortTagsQuery",
    "operationKind": "query",
    "text": "query FilterAndSortTagsQuery(\n  $filters: TagsFiltersInput\n  $count: Int\n  $cursor: String\n  $contentType: TagsContentTypeEnums\n) {\n  viewer {\n    ...FilterAndSortTags_viewer_3Rr5qy\n  }\n}\n\nfragment FilterAndSortTags_viewer_3Rr5qy on Viewer {\n  tags(first: $count, after: $cursor, filters: $filters, order: [{direction: ASC, field: NAME}], section: ADMIN, contentType: $contentType) {\n    edges {\n      node {\n        id\n        name\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8b9f7107dacd922c3d0c982ecddccfb6";

export default node;
