import React, { useState, useCallback, useEffect, useMemo } from "react";

import Stack from "../../../../components/lib/Stack";
import Box from "../../../../components/lib/Box";
import Text from "../../../../components/lib/Text";
import Center from "../../../../components/lib/Center";
import { AutoSearchBox } from "../../../../components/lib/SearchBox";
import { emptyList } from "../../../../constants";

import type { ImmutableList, ImmutableMap } from "../../../../types/immutable";

export type Item<T = any> = ImmutableMap<T>;
export type Items<T = Item> = ImmutableList<T>;
export type DataSourceFunc<T = Items> = (
  search: string | null,
  page: number,
  pageSize: number
) => Promise<DataSourceType<T> | undefined>;
export type RenderItemFunc<T = Item> = (item: T) => React.ReactNode;

type DataSourceType<T> = {
  data: T | null;
  itemsCount: number;
};

type SearchInputProps = {
  disabled: boolean;
  searchPlaceholder: string;
  onSearch: (search?: string) => Promise<void>;
};

const SearchInput = (props: SearchInputProps) => {
  const { searchPlaceholder, disabled, onSearch } = props;

  const [search, setSearch] = useState<string | undefined>(undefined);

  const handleChange = useCallback(
    (value?: string) => {
      if (value !== search) {
        setSearch(value);
      }
    },
    [search]
  );

  const handleSearch = useCallback(
    (value?: string) => {
      return onSearch(value);
    },
    [onSearch]
  );

  return (
    <AutoSearchBox
      value={search || ""}
      css={{
        width: "100%",
        borderWidth: "1px",
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        backgroundColor: "transparent",
      }}
      placeholder={searchPlaceholder}
      onChange={handleChange}
      onSubmit={disabled ? undefined : handleSearch}
      disabled={disabled}
    />
  );
};
SearchInput.displayName = "SearchInput";

type ItemsBlockProps = {
  disabled: boolean;
  loadingText: string;
  onScroll: () => Promise<void>;
};

const ItemsBlock = (props: React.PropsWithChildren<ItemsBlockProps>) => {
  const { disabled, loadingText, onScroll } = props;

  const [loading, setLoading] = useState<boolean>(false);

  const handleScroll = useCallback(
    async (e) => {
      const element = e.target;

      const isScrollPositionBottom =
        element.scrollHeight - element.scrollTop === element.clientHeight;
      if (isScrollPositionBottom && onScroll) {
        setLoading(true);
        await onScroll();
        setLoading(false);
      }
    },
    [onScroll]
  );

  return (
    <Stack
      fill
      nogap
      css={{
        border: "1px solid $primaryLight",
        opacity: disabled ? 0.55 : 1,
        borderTop: "none",
        borderRadius: "$rounded",
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        height: "200px",
        overflow: "hidden",
        overflowY: "auto",
      }}
      onScroll={disabled || loading ? undefined : handleScroll}
    >
      <Box fill>{props.children}</Box>
      {loading && (
        <Center css={{ paddingBottom: "$2" }}>
          <Text thin>{loadingText}</Text>
        </Center>
      )}
    </Stack>
  );
};
ItemsBlock.displayName = "ItemsBlock";

type RestfulDatasetListViewProps<T> = {
  renderItem: RenderItemFunc<T>;
  renderAction?: () => React.ReactElement;
  dataSource: DataSourceFunc<Items<T>>;
  searchPlaceholder?: string;
  noItemsFoundText?: string;
  loadingText?: string;
  uploadSize?: number;
  disabled?: boolean;
};

type RestfulDatasetListViewState<T> = {
  search: string | null;
  data: Items<T>;
  pagesUploaded: number;
  itemsAvailable: number;
};

const RestfulDatasetListView = <T extends Item>(
  props: React.PropsWithChildren<RestfulDatasetListViewProps<T>>
) => {
  const {
    renderItem,
    renderAction,
    dataSource,
    uploadSize = 30,
    searchPlaceholder = "Search by title...",
    noItemsFoundText = "No items found",
    loadingText = "loading...",
    disabled = false,
  } = props;

  const [state, setState] = useState<RestfulDatasetListViewState<T>>({
    search: null,
    data: emptyList as unknown as Items<T>,
    pagesUploaded: 0,
    itemsAvailable: 0,
  });

  // data fetching

  const fetchItems = useCallback(
    async (
      search: string | null = "",
      page: number = 1,
      pageSize: number = uploadSize
    ) => {
      const response = await dataSource(search, page, pageSize);
      const data = response?.data;
      if (data && response.itemsCount != null) {
        setState((prevState) => ({
          ...prevState,
          search,
          data: prevState.search === search ? prevState.data.concat(data) : data,
          pagesUploaded: page,
          itemsAvailable: response.itemsCount,
        }));
      }
    },
    [dataSource, uploadSize]
  );

  const handleSearch = useCallback(
    async (search?: string) => {
      await fetchItems(search, 1);
    },
    [fetchItems]
  );

  const handleScroll = useCallback(async () => {
    if (state.data.size < state.itemsAvailable) {
      await fetchItems(state.search, state.pagesUploaded + 1);
    }
  }, [state, fetchItems]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  // rendering

  const notFoundItems = useMemo(
    () => state.data.size === 0 && state.search,
    [state.data, state.search]
  );

  const renderItems = useCallback(() => {
    if (notFoundItems) {
      return (
        <Center css={{ padding: "$2" }}>
          <Text thin>{noItemsFoundText}</Text>
        </Center>
      );
    }

    return state.data
      .toArray()
      .map((item, idx) => <React.Fragment key={idx}>{renderItem(item)}</React.Fragment>);
  }, [state.data, notFoundItems, noItemsFoundText, renderItem]);

  return (
    <Stack nogap fill>
      <SearchInput
        disabled={disabled}
        searchPlaceholder={searchPlaceholder}
        onSearch={handleSearch}
      />
      <Box fill>{renderAction?.()}</Box>
      <ItemsBlock disabled={disabled} loadingText={loadingText} onScroll={handleScroll}>
        {renderItems()}
      </ItemsBlock>
    </Stack>
  );
};

RestfulDatasetListView.displayName = "RestfulDatasetListView";

export default RestfulDatasetListView;
