import React from "react";

import PromiseButton from "../../../components/lib/PromiseButton";
import Button from "../../../components/lib/Button";
import { ButtonGroupRight } from "../../../components/lib/ButtonGroup";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";
import { useModalState } from "../../../utils/hooks";

import { usePLIContext } from "../context";
import { useProgramContext } from "../ProgramDataProvider";
import {
  CONTRACTOR_GOOD_PROCESSING_STATUSES,
  djangoPaginationKey,
  djangoPaginationSizeKey,
} from "../constants";

import ExportIndexModal from "./ExportIndexModal";

import type { ButtonProps } from "../../../components/lib/Button";
import type {
  FiltersQueryObject,
  FiltersQueryOrderedMap,
  UrlQueryObject,
} from "../../../components/tables/types";
import type { ImmutableSet } from "../../../types/immutable";
import type { CONTRACTOR_STATUSES_TYPE } from "../types";
import type { FetchAPIResponse } from "../../../types/fetch";
import type { ProcessingsCheckObject } from "../pages/StoredIndexCreate";

export type BulkExportContractorsButtonProps = Omit<ButtonProps, "onClick"> & {
  contractorsFiltersQuery: FiltersQueryOrderedMap;
  allowedStatusesForExport: ImmutableSet<CONTRACTOR_STATUSES_TYPE>;
  currentPage: number;
  itemsTotal: number;
  itemsPerPage: number;
};

export function BulkExportContractorsButton(props: BulkExportContractorsButtonProps) {
  const {
    contractorsFiltersQuery,
    allowedStatusesForExport,
    currentPage,
    itemsTotal,
    itemsPerPage,
    ...restProps
  } = props;

  const {
    router,
    isPTAdmin,
    showConfirmationModal,
    closeConfirmationModal,
    showModalError,
    fetchM8FilteringAPI,
  } = usePLIContext();
  const { programId } = useProgramContext();

  // state

  const {
    modalState: exportIndexModalState,
    showModal: showExportIndexModal,
    closeModal: closeExportIndexModal,
  } = useModalState();

  // handlers

  const handleGoToProcessingsList = React.useCallback(() => {
    router.push(`/private-index/programs/${programId}/contractors/processings`);
  }, [router, programId]);

  const handleShowExportConfig = React.useCallback(() => {
    closeConfirmationModal();
    showExportIndexModal();
  }, [showExportIndexModal, closeConfirmationModal]);

  const handleBeforeShowExportConfig = async () => {
    if (!isPTAdmin) return handleShowExportConfig();

    const urlQuery: UrlQueryObject = {
      [djangoPaginationKey]: currentPage,
      [djangoPaginationSizeKey]: itemsPerPage,
    };
    const filtersQuery: FiltersQueryObject = {
      status__in: CONTRACTOR_GOOD_PROCESSING_STATUSES,
      ...contractorsFiltersQuery.toJS(),
    };
    let response: FetchAPIResponse<ProcessingsCheckObject> | null = null;
    let data: ProcessingsCheckObject | null = null;

    try {
      response = await fetchM8FilteringAPI(
        `programs/${programId}/contractors/check_for_processings/`,
        {
          params: urlQuery,
          data: filtersQuery,
        }
      );
      data = response.data;
    } catch (err: any) {
      logAsyncOperationError("checkForRunningProcessings", err);
      showModalError("Error occurred during processings check.");
      return;
    }

    const totalProcessingIdsNum = data ? data["total_processing_ids_num"] : 0;

    if (totalProcessingIdsNum) {
      const totalProcessingIdsNum = data["total_processing_ids_num"] || 0;
      const pageProcessingIdsNum = data["page_processing_ids_num"] || 0;
      const header = "Important!";
      const message = (
        <div>
          <h4>
            We found {totalProcessingIdsNum} refreshing rows in the index.
            {pageProcessingIdsNum > 0
              ? ` And ${
                  pageProcessingIdsNum === totalProcessingIdsNum
                    ? "all"
                    : pageProcessingIdsNum
                } of them are found on the current page.`
              : ""}
          </h4>
          <br />
          <span>
            <b>
              <u>Hint:</u>
            </b>{" "}
            Wait until all procedures will be done or continue with export. Also check up
            the rerun requests page to be up to date with latest processings in the
            system.
          </span>
        </div>
      );
      const footer = (
        <ButtonGroupRight fill css={{ flexDirection: "row-reverse" }}>
          <Button onClick={closeConfirmationModal}>Cancel</Button>
          <Button color="yellow" onClick={handleGoToProcessingsList}>
            See All Rerun Requests
          </Button>
          <Button color="brand" onClick={handleShowExportConfig}>
            Continue With Export
          </Button>
        </ButtonGroupRight>
      );

      showConfirmationModal(message, header, footer);
    } else {
      handleShowExportConfig();
    }
  };

  return (
    <>
      <PromiseButton
        onClick={isPTAdmin ? handleBeforeShowExportConfig : handleShowExportConfig}
        {...restProps}
      />
      <ExportIndexModal
        show={exportIndexModalState}
        onClose={closeExportIndexModal}
        filtersQuery={contractorsFiltersQuery}
        activePage={currentPage}
        itemsTotal={itemsTotal}
        itemsPerPage={itemsPerPage}
      />
    </>
  );
}

BulkExportContractorsButton.displayName = "BulkExportContractorsButton";

export default BulkExportContractorsButton;
