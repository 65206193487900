import React, { useCallback, useRef, useState, useContext } from "react";
// TODO get rid of "query-string" lib and use the "buildUrlQueryString" below
// import { buildUrlQueryString } from "./utils";
import queryString from "query-string";

import MessageModal from "./components/modals/MessageModal";
import ConfirmationModal from "./components/ConfirmationModal";
import { TickerPageLoader } from "./components/lib/TickerLoader";
import SessionInfo from "./models/SessionInfo";

import type { InjectedRouter, RouterState } from "react-router";
import type { Location } from "history";
// @ts-expect-error
import type MobXStore from "./stores/mobx/MobXStore";
import type { LogoutFunc, UpdateLoginStateFunc } from "./types/auth";
import type { FetchAPIFunc, FetchGraphQLAPIFunc } from "./types/fetch";
import type {
  HideModalFunc,
  MessageModalAPI,
  ShowModalFunc,
} from "./components/modals/MessageModal";
import type {
  ConfirmationModalAPI,
  CloseConfirmationModalFunc,
  ShowConfirmationModalFunc,
} from "./components/ConfirmationModal";
import type { HideLoaderFunc, ShowLoaderFunc } from "./components/lib/TickerLoader";
import { Environment, useRelayEnvironment } from "react-relay";

export type ServicesObject = { [serviceName: string]: string };

export type GlobalContextObject = {
  relayEnvironment: Environment;
  router: RouterState & InjectedRouter;
  location: Location;
  store: MobXStore;
  services: ServicesObject;
  //
  fetchAPI: FetchAPIFunc;
  fetchGraphQL: FetchGraphQLAPIFunc;
  fetchAPINew: FetchAPIFunc;
  fetchGraphQLNew: FetchGraphQLAPIFunc;
  //
  fetchM8API: FetchAPIFunc;
  fetchM8FilteringAPI: FetchAPIFunc;
  fetchArgusAPI: FetchAPIFunc;
  fetchArgusFilteringAPI: FetchAPIFunc;
  fetchTasteAPI: FetchAPIFunc;
  fetchCCCV1API: FetchAPIFunc;
  fetchCCCV2API: FetchAPIFunc;
  fetchCCCInternalAPI: FetchAPIFunc;
  //
  showModalError: ShowModalFunc;
  showModalWarning: ShowModalFunc;
  showModalSuccess: ShowModalFunc;
  showModalInfo: ShowModalFunc;
  hideModal: HideModalFunc;
  //
  showConfirmationModal: ShowConfirmationModalFunc;
  closeConfirmationModal: CloseConfirmationModalFunc;
  //
  showLoader: ShowLoaderFunc;
  hideLoader: HideLoaderFunc;
  //
  sessionInfo: SessionInfo;
  clientId: number;
  clientName: string;
  userId: number;
  userName: string;
  userEmail: string;
  legacySession: string;
  perSearchPricing: boolean;
  pspStoreFlag: boolean;
  isClientJobLibrary: boolean;
  //
  updateLoginState: UpdateLoginStateFunc;
  logout: LogoutFunc;
};

export const globalContextDefaults = {
  relayEnvironment: undefined as unknown as Environment,
  router: undefined as unknown as RouterState & InjectedRouter,
  location: undefined as unknown as Location,
  store: undefined as unknown as MobXStore,
  services: undefined as unknown as ServicesObject,
  //
  fetchAPI: undefined as unknown as FetchAPIFunc,
  fetchGraphQL: undefined as unknown as FetchGraphQLAPIFunc,
  fetchAPINew: undefined as unknown as FetchAPIFunc,
  fetchGraphQLNew: undefined as unknown as FetchGraphQLAPIFunc,
  //
  fetchM8API: undefined as unknown as FetchAPIFunc,
  fetchM8FilteringAPI: undefined as unknown as FetchAPIFunc,
  fetchTasteAPI: undefined as unknown as FetchAPIFunc,
  fetchArgusAPI: undefined as unknown as FetchAPIFunc,
  fetchArgusFilteringAPI: undefined as unknown as FetchAPIFunc,
  fetchCCCV1API: undefined as unknown as FetchAPIFunc,
  fetchCCCV2API: undefined as unknown as FetchAPIFunc,
  fetchCCCInternalAPI: undefined as unknown as FetchAPIFunc,
  //
  showModalError: undefined as unknown as ShowModalFunc,
  showModalWarning: undefined as unknown as ShowModalFunc,
  showModalSuccess: undefined as unknown as ShowModalFunc,
  showModalInfo: undefined as unknown as ShowModalFunc,
  hideModal: undefined as unknown as HideModalFunc,
  //
  showConfirmationModal: undefined as unknown as ShowConfirmationModalFunc,
  closeConfirmationModal: undefined as unknown as CloseConfirmationModalFunc,
  //
  showLoader: undefined as unknown as ShowLoaderFunc,
  hideLoader: undefined as unknown as HideLoaderFunc,
  //
  sessionInfo: undefined as unknown as SessionInfo,
  clientId: undefined as unknown as number,
  clientName: undefined as unknown as string,
  userId: undefined as unknown as number,
  userName: undefined as unknown as string,
  userEmail: undefined as unknown as string,
  legacySession: undefined as unknown as string,
  perSearchPricing: false,
  pspStoreFlag: false,
  isClientJobLibrary: false,
  //
  updateLoginState: undefined as unknown as UpdateLoginStateFunc,
  logout: undefined as unknown as LogoutFunc,
};

export const GlobalContext =
  React.createContext<GlobalContextObject>(globalContextDefaults);

type GlobalContextProviderProps = {
  router: RouterState & InjectedRouter;
  location: Location;
  store: MobXStore;
  services: ServicesObject;
  //
  fetchAPI: FetchAPIFunc;
  fetchGraphQL: FetchGraphQLAPIFunc;
  fetchAPINew: FetchAPIFunc;
  fetchGraphQLNew: FetchGraphQLAPIFunc;
  //
  sessionInfo: SessionInfo;
  updateLoginState: UpdateLoginStateFunc;
  //
  children: React.ReactElement;
};

export const GlobalContextProvider = (props: GlobalContextProviderProps) => {
  const {
    store,
    router,
    location,
    services,
    sessionInfo,
    fetchAPI,
    fetchAPINew,
    fetchGraphQL,
    fetchGraphQLNew,
    updateLoginState,
    children,
    ...restProps
  } = props;
  const relayEnvironment = useRelayEnvironment();

  const clientId = sessionInfo?.client?.legacyId;
  const clientName = sessionInfo?.client?.title;
  const userId = sessionInfo?.user?.userId;
  const userName = sessionInfo?.user?.username;
  const userEmail = sessionInfo?.user?.email;
  const legacySession = sessionInfo?.legacySession;
  const perSearchPricing = sessionInfo?.client?.perSearchPricing;
  const pspStoreFlag = sessionInfo?.client?.pspStoreFlag;
  const isClientJobLibrary = sessionInfo?.client?.isClientJobLibrary;

  // modal messager

  const modalMessagerRef = useRef<MessageModalAPI>(null);

  const showModalError: ShowModalFunc = useCallback(
    async (...args) => modalMessagerRef.current?.showError(...args),
    [modalMessagerRef]
  );

  const showModalWarning: ShowModalFunc = useCallback(
    async (...args) => modalMessagerRef.current?.showWarning(...args),
    [modalMessagerRef]
  );

  const showModalSuccess: ShowModalFunc = useCallback(
    async (...args) => modalMessagerRef.current?.showSuccess(...args),
    [modalMessagerRef]
  );

  const showModalInfo: ShowModalFunc = useCallback(
    async (...args) => modalMessagerRef.current?.showInfo(...args),
    [modalMessagerRef]
  );

  const hideModal: HideModalFunc = useCallback(
    async () => modalMessagerRef.current?.hideModal(),
    [modalMessagerRef]
  );

  // confirmation modal

  const confirmationModalRef = useRef<ConfirmationModalAPI>(null);

  const showConfirmationModal: ShowConfirmationModalFunc = useCallback(
    async (message, header, footer) =>
      confirmationModalRef.current?.confirm(message, header, footer),
    [confirmationModalRef]
  );

  const closeConfirmationModal = useCallback(
    async () => confirmationModalRef.current?.close(),
    [confirmationModalRef]
  );

  // global loader

  const [showLoaderState, setShowLoaderState] = useState<boolean>(false);
  const showLoader: ShowLoaderFunc = useCallback(
    async () => Promise.resolve(setShowLoaderState(true)),
    []
  );
  const hideLoader: HideLoaderFunc = useCallback(
    async () => Promise.resolve(setShowLoaderState(false)),
    []
  );

  // APIs access funcs

  const fetchM8API: FetchAPIFunc = useCallback(
    async (path, options = {}) => {
      const resultingOptions = options || {};

      resultingOptions.headers = {
        accept: "application/vnd.peopleticker.m8.api+json; version=1.0, application/json",
        ...(resultingOptions.headers || {}),
      };

      return await fetchAPINew(`api/m8/${path}`, resultingOptions);
    },
    [fetchAPINew]
  );

  const fetchM8FilteringAPI: FetchAPIFunc = useCallback(
    async (path, options = {}) => {
      const resultingOptions = options || {};

      resultingOptions.method = "post";
      resultingOptions.headers = {
        "content-type": "application/x-www-form-urlencoded",
        ...(resultingOptions.headers || {}),
      };
      resultingOptions.data = resultingOptions.data || {};
      // resultingOptions.data = buildUrlQueryString(resultingOptions.data);
      resultingOptions.data = queryString.stringify(resultingOptions.data);

      return await fetchM8API(path, resultingOptions);
    },
    [fetchM8API]
  );

  const fetchArgusAPI: FetchAPIFunc = useCallback(
    async (path, options = {}) => {
      const resultingOptions = options || {};

      resultingOptions.headers = {
        accept: "application/json",
        "x-session-id": legacySession,
        ...(resultingOptions.headers || {}),
      };

      return await fetchAPINew(`api/argus/v1/${path}`, resultingOptions);
    },
    [legacySession, fetchAPINew]
  );

  const fetchArgusFilteringAPI: FetchAPIFunc = useCallback(
    async (path, options = {}) => {
      const resultingOptions = options || {};

      resultingOptions.method = "post";
      resultingOptions.headers = {
        "content-type": "application/x-www-form-urlencoded",
        ...(resultingOptions.headers || {}),
      };
      resultingOptions.data = resultingOptions.data || {};
      // resultingOptions.data = buildUrlQueryString(resultingOptions.data);
      resultingOptions.data = queryString.stringify(resultingOptions.data);

      return await fetchArgusAPI(path, resultingOptions);
    },
    [fetchArgusAPI]
  );

  const fetchTasteAPI: FetchAPIFunc = useCallback(
    async (path, options = {}) => {
      const resultingOptions = options || {};

      resultingOptions.headers = {
        accept: "application/json",
        "x-session-id": legacySession,
        ...(resultingOptions.headers || {}),
      };

      return await fetchAPINew(`api/taste/v1/${path}`, resultingOptions);
    },
    [legacySession, fetchAPINew]
  );

  const fetchCCCV2API: FetchAPIFunc = useCallback(
    async (path, options = {}) => {
      const resultingOptions = options || {};

      resultingOptions.headers = {
        accept: "application/json",
        "x-session-id": legacySession,
        ...(resultingOptions.headers || {}),
      };

      return await fetchAPINew(`api/ccc/v2/${path}`, resultingOptions);
    },
    [legacySession, fetchAPINew]
  );

  const fetchCCCV1API: FetchAPIFunc = useCallback(
    async (path, options) => {
      const resultingOptions = options || {};

      resultingOptions.headers = {
        accept: "application/json",
        "x-session-id": legacySession,
        ...(resultingOptions?.headers || {}),
      };

      return await fetchAPINew(`api/ccc/v1/${path}`, resultingOptions);
    },
    [legacySession, fetchAPINew]
  );

  const fetchCCCInternalAPI: FetchAPIFunc = useCallback(
    async (path, options = {}) => {
      const resultingOptions = options || {};

      resultingOptions.headers = {
        accept: "application/json",
        "x-session-id": legacySession,
        ...(resultingOptions.headers || {}),
      };

      return await fetchAPINew(`api/ccc/internal/${path}`, resultingOptions);
    },
    [legacySession, fetchAPINew]
  );

  const logout: LogoutFunc = React.useCallback(() => {
    fetchGraphQL("mutation logout{ logoutUser{ ok } }")
      .then((res: any) => {
        updateLoginState(false, null, null);
        router.push({ pathname: "/login" });
      })
      .catch((e: any) => {
        console.error("Error logging user out", e);
        updateLoginState(false, null, null);
        router.push({ pathname: "/login" });
      });
  }, [fetchGraphQL, updateLoginState, router]);

  // global context values

  const globalContextValues: GlobalContextObject = {
    relayEnvironment,
    router,
    location,
    store,
    services,
    //
    fetchAPI,
    fetchAPINew,
    fetchGraphQL,
    fetchGraphQLNew,
    //
    fetchM8API,
    fetchM8FilteringAPI,
    fetchArgusAPI,
    fetchArgusFilteringAPI,
    fetchTasteAPI,
    fetchCCCV1API,
    fetchCCCV2API,
    fetchCCCInternalAPI,
    //
    showModalError,
    showModalWarning,
    showModalSuccess,
    showModalInfo,
    hideModal,
    //
    showConfirmationModal,
    closeConfirmationModal,
    //
    showLoader,
    hideLoader,
    //
    sessionInfo,
    clientId,
    clientName,
    userId,
    userName,
    userEmail,
    legacySession,
    perSearchPricing,
    pspStoreFlag,
    isClientJobLibrary,
    //
    updateLoginState,
    logout,
  };

  return (
    <GlobalContext.Provider value={globalContextValues}>
      {React.cloneElement(React.Children.only(children), {
        ...globalContextValues,
        ...restProps, // this helps propagate other contexts values
      })}
      <MessageModal ref={modalMessagerRef} />
      <ConfirmationModal ref={confirmationModalRef} />
      {showLoaderState && <TickerPageLoader overlay />}
    </GlobalContext.Provider>
  );
};

GlobalContextProvider.displayName = "GlobalContextProvider";

export function useGlobalContext() {
  return useContext(GlobalContext);
}

export interface CommonChildPageProps extends GlobalContextObject {}

export default GlobalContext;
