// @flow
import React, { Component } from "react";
import { observer } from "mobx-react";
import PlainHighChart from "../../ratecards/components/PlainHighChart";
import numeral from "numeral";

type ScatterPlotChartData = {
  name?: string,
  x: number,
  y: number,
};

type Props = {
  maxData: ScatterPlotChartData[],
  minData?: ?(ScatterPlotChartData[]),
  levelColor: string,
  secondaryColor: string,
  currencySymbol?: ?string,
};

function hexToRgb(hex) {
  if (!hex) return null;

  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

const PayRateBillRateScatterPlotChart = observer(
  class extends Component {
    props: Props;

    render() {
      const props = this.props;
      const primaryRGB = hexToRgb(props.levelColor);
      const secondaryRGB = hexToRgb(props.secondaryColor);

      const primaryRGBA = primaryRGB
        ? `rgba(${primaryRGB.r}, ${primaryRGB.g}, ${primaryRGB.b}, .6)`
        : null;
      const secondaryRGBA = secondaryRGB
        ? `rgba(${secondaryRGB.r}, ${secondaryRGB.g}, ${secondaryRGB.b}, .6)`
        : null;

      const currencySymbol = props.currencySymbol || "";

      let chartConfig = {
        chart: {
          type: "scatter",
          zoomType: "xy",
        },
        title: null,
        xAxis: {
          title: {
            enabled: true,
            text: "Pay Rate",
          },
          startOnTick: true,
          endOnTick: true,
          showLastLabel: true,
          labels: {
            formatter: function () {
              const x = numeral(this.value).format("Oa");
              return `${currencySymbol} ${x}`;
            },
          },
        },
        yAxis: {
          title: {
            text: "Bill Rate",
          },
          labels: {
            formatter: function () {
              const y = numeral(this.value).format("Oa");
              return `${currencySymbol} ${y}`;
            },
          },
        },
        plotOptions: {
          scatter: {
            turboThreshold: 10000,
            marker: {
              radius: 5,
            },
            tooltip: {
              valuePrefix: currencySymbol,
              headerFormat: "<b>{point.key}</b><br/>",
              pointFormatter: function () {
                const seriesName = this.series.name;
                const x = currencySymbol + " " + numeral(this.x).format("0,0.00");
                const y = currencySymbol + " " + numeral(this.y).format("0,0.00");

                return `${seriesName} Pay Rate: <b>${x}</b><br/>${seriesName} Bill Rate: <b>${y}</b><br/>`;
              },
            },
          },
        },
        series: [
          {
            name: "Min",
            color: secondaryRGBA,
            data: props.minData,
            marker: {
              states: {
                hover: {
                  fillColor: props.secondaryColor,
                },
              },
            },
          },
          {
            name: "Max",
            color: primaryRGBA,
            data: props.maxData,
            marker: {
              states: {
                hover: {
                  fillColor: props.levelColor,
                },
              },
            },
          },
        ],
      };

      return (
        <div className="chart-container" style={{ height: 426, width: "100%" }}>
          <PlainHighChart options={chartConfig} style={{ height: 426, width: "100%" }} />
        </div>
      );
    }
  }
);

export default PayRateBillRateScatterPlotChart;
