import React from "react";

import Icon from "../../../../../components/lib/Icon";
import { RATE_FEEDBACK_STATUS_TYPES } from "../../../types";
import { RatesButton } from "./ButtonStyle";

import type { RATE_FEEDBACK_STATUS_TYPES_TYPE } from "../../../types";

type Props = {
  status: RATE_FEEDBACK_STATUS_TYPES_TYPE;
  onClick: (value: RATE_FEEDBACK_STATUS_TYPES_TYPE) => void;
};

export default function RatesFeedbackDontKnowButton(props: Props) {
  const { status, onClick } = props;
  const isDontKnowStatus = status === RATE_FEEDBACK_STATUS_TYPES.DONT_KNOW;

  return (
    <RatesButton
      css={{
        backgroundColor: isDontKnowStatus ? "$warningLightest" : undefined,
        "&:hover": { backgroundColor: "$warningLighter" },
      }}
      onClick={() => onClick(RATE_FEEDBACK_STATUS_TYPES.DONT_KNOW)}
    >
      <Icon icon="question" /> {"I don't know"}
    </RatesButton>
  );
}

RatesFeedbackDontKnowButton.displayName = "RatesFeedbackDontKnowButton";
