// @flow
import { extendObservable, action, runInAction, observable } from "mobx";
import NetworkState from "../../models/NetworkState";
import ModalState from "../../models/ModalState";
import NegotiationWorksheet from "../../models/NegotiationWorksheet";
import MessageState from "../../models/MessageState";
import {
  PAY_TYPE_OPTIONS,
  RATE_TYPE_OPTIONS,
} from "../../constants/negotiationWorksheet";
import ApplyTagState from "../../models/ApplyTagState";
import CurrentUser from "../../models/User";
import axios from "axios";
import type MobXStore from "./MobXStore";
import type { FetchGraphQL } from "../../App";
import { sanitizeInput } from "../../utils/dom";

class NegotiationWorksheetDetailStore {
  store: MobXStore;
  router: Object;
  network: NetworkState;
  confirmDeleteModal: ModalState;
  negDetail: NegotiationWorksheet;
  renameWorksheetModal: ModalState;
  onNewWorksheetNameChange: (Event) => void;
  messaging: MessageState;
  showRenameWorksheetModal: () => void;
  worksheetId: number;
  showHelpModal: ModalState;
  showHelp: () => void;
  revisionNumber: number;
  savedVersionNumber: number;
  apiLocationId: number;
  jsonValues: Object;
  setValues: () => void;
  isLatest: boolean;
  applyTags: () => void;
  applyTagState: ApplyTagState;
  deleteSingleTags: () => void;
  CurrentUser: CurrentUser;
  selectedTags: Object;
  fetchGraphQL: FetchGraphQL;
  updateSchema: () => Promise<any>;

  constructor(fetchGraphQL: FetchGraphQL, store: MobXStore) {
    this.fetchGraphQL = fetchGraphQL;
    this.store = store;
    this.router = null;

    extendObservable(this, {
      worksheetId: null,
      search: null,
      confirmDeleteModal: new ModalState(),
      network: new NetworkState(),
      negDetail: null,
      renameWorksheetModal: new ModalState(),
      newWorksheetName: "",
      messaging: new MessageState(),
      showHelpModal: new ModalState(),
      revisionNumber: "",
      savedVersionNumber: null,
      apiLocationId: null,
      jsonValues: {},
      isLatest: true,
      applyTagState: new ApplyTagState(fetchGraphQL, this),
    });
    this.currentUser = null;
    this.fetchWorksheet = action(this.fetchWorksheet.bind(this));
    this.deleteWorksheet = action(this.deleteWorksheet.bind(this));
    this.onNewWorksheetNameChange = action(this.onNewWorksheetNameChange.bind(this));
    this.showRenameWorksheetModal = action(this.showRenameWorksheetModal.bind(this));
    this.renameNegotiationWorksheet = action(this.renameNegotiationWorksheet.bind(this));
    this.showHelp = action(this.showHelp.bind(this));
    this.setValues = action(this.setValues.bind(this));
    this.applyTags = action(this.applyTags.bind(this));
    this.deleteSingleTags = action(this.deleteSingleTags.bind(this));
    this.updateSchema = action(this.updateSchema.bind(this));
  }

  onNewWorksheetNameChange(e: Event) {
    this.newWorksheetName = sanitizeInput(e.target.value);
  }

  showRenameWorksheetModal() {
    this.messaging.removeAll();
    this.newWorksheetName = this.negDetail.worksheetName;
    this.renameWorksheetModal.showModal();
  }

  showHelp() {
    this.showHelpModal.showModal();
  }

  canExport() {
    const exportRole = "No Export Allowed";
    if (
      this.currentUser &&
      this.currentUser.roles &&
      this.currentUser.roles.indexOf(exportRole) > -1
    ) {
      return false;
    }
    return true;
  }

  async fetchWorksheet() {
    this.revisionNumber = "";
    this.store.negotiationWorksheetCreateStore.resetValues();
    let res = null;
    if (!/^\d+$/.test(this.worksheetId)) {
      if (this.router) {
        this.router.push({
          pathname: "/404NotFound",
          query: this.router.query,
        });
      }
      return res;
    }
    //   let params: string[] = pageQuery.params;
    // let args = pageQuery.args;
    // let variables = pageQuery.variables;
    let params: string[] = [];
    let args = [];
    let variables = {};

    let worksheetId = this.worksheetId;

    params.push("$id: Int!");
    args.push("id: $id");
    variables.id = worksheetId;

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
      query negotiationdetail(${queryParams}) {
        viewer {
          user{
            firstName
            lastName
            userId
            username
            email
            roles
          }
          negotiationWorksheet (${queryArgs}){
            worksheetName
            payRate
            totalMarkupPercent
            state {
              name
              locationId
            }
            tags{
              name
              tagId
            }
            isIc
            markupType
            rateType
            payType
            created
            createdBy {
              firstName
              lastName
            }
            worksheetId
            country {
              name
              locationId
              apiLocationId
            }
            state {
              name
              locationId
              apiLocationId
            }
            worksheetRevision{
              revisionNumber
              jsonData {
                location
                payType
                payValue
                rateType
                values
                version
                workerType
              }
            }
           }
         }
        }
        `;

    this.network.error = null;
    this.network.loading = true;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting Negotiation Detail", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Negotiation Detail query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    runInAction("fetchWorksheet--success", () => {
      if (!res.data.viewer.negotiationWorksheet) {
        if (this.router) {
          this.router.push({
            pathname: "/404NotFound",
            query: this.router.query,
          });
        }
        return;
      }
      this.network.loading = false;
      this.network.error = null;
      this.selectedTags = observable.map({});
      res.data.viewer.negotiationWorksheet.tags.forEach((item) => {
        this.selectedTags.set(item.tagId, item);
      });
      this.currentUser = new CurrentUser(this, res.data.viewer.user);
      const createStore = this.store.negotiationWorksheetCreateStore;
      const node = res.data.viewer.negotiationWorksheet.worksheetRevision;
      if (this.network.logGraphQLError("Get Worksheet query", res)) {
        // TODO: Display user friendly error message
        return;
      }
      this.negDetail = new NegotiationWorksheet(
        this,
        res.data.viewer.negotiationWorksheet
      );
      this.savedVersionNumber = node.jsonData.version;
      this.apiLocationId = res.data.viewer.negotiationWorksheet.country.apiLocationId;
      createStore.countryid = res.data.viewer.negotiationWorksheet.country.locationId;
      this.revisionNumber = node.revisionNumber;
      if (res.data.viewer.negotiationWorksheet.state) {
        createStore.state = res.data.viewer.negotiationWorksheet.state.name;
        createStore.stateid = res.data.viewer.negotiationWorksheet.state.locationId;
        createStore.apiStateId = res.data.viewer.negotiationWorksheet.state.apiLocationId;
        createStore.getSchema(
          this.apiLocationId,
          this.savedVersionNumber,
          "detail",
          createStore.apiStateId
        );
      } else {
        createStore.getSchema(this.apiLocationId, this.savedVersionNumber, "detail");
      }
      this.jsonValues = node.jsonData;
    });
  }

  setValues() {
    const createStore = this.store.negotiationWorksheetCreateStore;
    var jsonData = this.jsonValues;
    var newPayType = null;
    var newRateType = null;
    createStore.setPayValue(createStore, jsonData.payValue, "detail");

    for (var key in PAY_TYPE_OPTIONS) {
      if (!PAY_TYPE_OPTIONS.hasOwnProperty(key)) continue;

      var obj = PAY_TYPE_OPTIONS[key];
      for (var prop in obj) {
        if (!obj.hasOwnProperty(prop)) continue;

        if (obj.enumValue === jsonData.payType) {
          newPayType = obj.value;
        }
      }
    }
    if (newPayType) {
      createStore.setPayType(createStore, newPayType, "detail");
    }

    if (jsonData.payType !== 3) {
      for (var ptkey in RATE_TYPE_OPTIONS) {
        if (!RATE_TYPE_OPTIONS.hasOwnProperty(ptkey)) continue;

        var ptobj = RATE_TYPE_OPTIONS[ptkey];
        for (var ptprop in ptobj) {
          if (!ptobj.hasOwnProperty(ptprop)) continue;

          if (ptobj.label === jsonData.rateType) {
            newRateType = ptobj.value;
          }
        }
      }

      if (newRateType) {
        createStore.setRateType(createStore, newRateType, "detail");
      }
    }

    if (jsonData.workerType) {
      createStore.setWorkerType(createStore, jsonData.workerType, "detail");
    }

    if (jsonData.values) {
      createStore.currentState.unsavedValues = JSON.parse(jsonData.values);
    }

    createStore.locationId = this.apiLocationId;

    this.isLatest = createStore.isLatest;
    var initialCurrentState = {};

    initialCurrentState.payValue = createStore.currentState.payValue;
    initialCurrentState.payType = createStore.currentState.payType;
    initialCurrentState.rateType = createStore.currentState.rateType;
    initialCurrentState.workerTypeKey = createStore.currentState.workerTypeKey;
    initialCurrentState.unsavedValues = createStore.currentState.unsavedValues;
    createStore.undoQueue[0] = initialCurrentState;
    createStore.currentState = initialCurrentState;
    createStore.tempObj.payValue = jsonData.payValue;
    createStore.tempObj.payType = newPayType;
    createStore.tempObj.rateType = newRateType;
    createStore.tempObj.workerTypeKey = jsonData.workerType;
    createStore.tempObj.unsavedValues = JSON.parse(jsonData.values);
  }

  async deleteWorksheet(id) {
    let parameters = {};

    parameters.filters = {};
    parameters.worksheetIds = [id];

    const query = `
      mutation deleteWorksheet($input : DeleteNegotiationWorksheetInput!){
       deleteNegotiationWorksheet(input: $input){
         ok
       }
      }
    `;

    const variables = {
      input: parameters,
    };

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Deleting selected Worksheets", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    runInAction("deleteWorksheet--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("deleteWorksheet", res)) {
        // TODO: Display user friendly error message
        return;
      }

      if (this.router) {
        this.router.push({
          pathname: "/negotiation-worksheets",
          query: this.router.query,
        });
      }
    });
  }

  //rename Worksheet
  async renameNegotiationWorksheet() {
    if (this.network.loading) {
      return;
    }

    this.messaging.removeAll();

    if (!this.newWorksheetName.trim()) {
      this.messaging.createMessage("info", "Please enter a New Worksheet Name.");
      return;
    }

    const query = `
      mutation renameNegotiationWorksheet($worksheetId: Int!, $name: String!){
        renameNegotiationWorksheet(input: {name: $name, worksheetId: $worksheetId}){
          worksheet{
            worksheetId,
            worksheetName
          }
          errors {
            __typename
            ... on NameAlreadyExistError {
              message
            }
            ... on NameEmptyError {
              message
            }
         }
        }
      }
    `;
    const variables = {
      worksheetId: this.worksheetId,
      name: this.newWorksheetName,
    };

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Getting worksheet detail", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    // this.handleStopEdit();

    runInAction("renameNegotiationWorksheet--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (res.data.renameNegotiationWorksheet.errors) {
        this.messaging.removeAll();
        this.messaging.createMessage(
          "error",
          res.data.renameNegotiationWorksheet.errors[0].message
        );
        return;
      }
      if (this.network.logGraphQLError("Filter criteria query", res)) {
        // TODO: Display user friendly error message
        return;
      }
      this.negDetail.worksheetName =
        res.data.renameNegotiationWorksheet.worksheet.worksheetName;

      this.renameWorksheetModal.hideModal();
    });
  }

  async applyTags() {
    let params = [];
    let filterargs = [];
    let searchargs = [];
    let args = [];
    let vars = {};

    const taglist = this.applyTagState.getSelectedTagList();
    if (!taglist || !taglist.length) {
      console.error("Cannot Apply tags to Worksheets: No Worksheet selected");
      return;
    }
    params.push("$tagIds: [Int]!");
    filterargs.push("tagIds: $tagIds");
    vars.tagIds = taglist;
    params.push("$only: [ID]");
    args.push("only: $only");
    vars.only = [this.negDetail.worksheetId];

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
      mutation applyTags(${queryParams}){
       applyTagsToNegotiationWorksheets(input:{${filterargs}, filters: { ${queryArgs}}, ${searchargs}}) {
         ok
          errors {
           __typename
           ... on TagIdRequiredError {
             message
           }
           ... on TagIdsNotExistsError {
             message
           }
           ... on InvalidInputError {
             message
           }
           ... on ContentDoesNotExistsError {
             message
           }
         }
       }

      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Apply Tags to Selected Worksheets", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    runInAction("applyTags--success", () => {
      this.applyTagState.tagModal.hideModal();
      this.fetchWorksheet();
    });
  }

  async deleteSingleTags(content) {
    const query = `
    mutation removeTagsFromContent{
      removeTagsFromContent(input: {tagIds : [${content.tagId}],contentType :${content.contentType},contentId:${content.contentId}}){
        ok
        }
      }
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, null);
    } catch (e) {
      // TODO: Display user friendly error message
      console.log("error", e);
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }

    runInAction("deleteSingleTags--success", () => {
      // debugger
      this.negDetail.tags.forEach((item, index) => {
        if (content.tagId === item.tagId) {
          this.negDetail.tags.splice(index, 1);
          this.selectedTags.delete(item.tagId);
        }
      });
      this.negDetail = new NegotiationWorksheet(this, this.negDetail);
    });
  }

  async updateSchema() {
    const createStore = this.store.negotiationWorksheetCreateStore;
    const schemaFieldsBefore = [...createStore.worksheetSchema.fields];
    const unsavedValuesBefore = { ...createStore.currentState.unsavedValues };

    createStore.resetValues();
    await createStore.updateWorksheetSchema(this.worksheetId);
    await createStore.getSchema(
      this.apiLocationId,
      "latest",
      "detail",
      this.negDetail?.state?.apiLocationId
    );

    const schemaFieldsAfter = [...createStore.worksheetSchema.fields];
    // const unsavedValuesAfter = { ...createStore.currentState.unsavedValues };

    schemaFieldsBefore.forEach((schemaFieldBefore) => {
      const fieldKey = schemaFieldBefore.key;
      const schemaBefore = schemaFieldBefore.value;
      const valueBefore = unsavedValuesBefore[fieldKey];

      const schemaFieldAfter = schemaFieldsAfter.find((f) => f.key === fieldKey);
      const schemaAfter = schemaFieldAfter?.value;

      if (schemaFieldAfter === undefined) {
        console.warn(`schema field not found after update: ${fieldKey}`);
        return;
      }

      if (valueBefore === schemaBefore && schemaBefore !== schemaAfter) {
        // value not changed by user but schema value changed. update it.
        // unsavedValuesAfter[fieldKey] = schemaAfter;
        createStore.setFieldValue(schemaFieldAfter, schemaAfter);
        console.log("schema value updated to:", schemaAfter);
      }
    });

    if (createStore.canSave() || createStore.hasUnsavedChanges()) {
      createStore.updateWorksheet(this.negDetail);
    }
  }
}

export default NegotiationWorksheetDetailStore;
