import React, { useState, useCallback } from "react";

// @ts-expect-error
import Messager from "../../../components/Messager";
// @ts-expect-error
import LegacyUserSelect from "../../../components/selects/LegacyUserSelect";
import Alert from "../../../components/lib/Alert";
import TextInput from "../../../components/lib/TextInput";
import { Button } from "../../../components/lib/Button";
import {
  Checkbox,
  CheckboxIndicator,
  LabelInline,
} from "../../../components/lib/Checkbox";
import { useMessagerRef } from "../hooks";
import { ButtonGroupRight } from "../../../components/lib/ButtonGroup";
import Stack from "../../../components/lib/Stack";
import Icon from "../../../components/lib/Icon";
import Text from "../../../components/lib/Text";
import { reactSelectStyles } from "../constants";

import type { FetchGraphQLAPIFunc } from "../../../types/fetch";
import PromiseButton from "../../../components/lib/PromiseButton";

const MAX_NAME_LENGTH = 256;

type LegacyUser = {
  userId: number;
  userName: string;
};

type CreateRateCardFormState = {
  rateCardName: string | null;
  legacyUser: LegacyUser | null;
  emailMe: boolean;
};

export type CreateRateCardFormProps = {
  fetchGraphQL: FetchGraphQLAPIFunc;
  onCreateRateCard: (formState: CreateRateCardFormState) => Promise<any>;
  onClose: () => void;
};

const CreateRateCardForm = ({
  fetchGraphQL,
  onCreateRateCard,
  onClose,
}: CreateRateCardFormProps) => {
  const [formState, setFormState] = useState<CreateRateCardFormState>({
    rateCardName: null,
    legacyUser: null,
    emailMe: false,
  });

  const { messagerRef, showWarning } = useMessagerRef();

  // handlers

  const handleRateCardNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setFormState((prevState) => {
        return value !== prevState.rateCardName
          ? { ...prevState, rateCardName: value }
          : prevState;
      });
      if (value.length >= MAX_NAME_LENGTH) {
        showWarning(`name has been truncated to ${MAX_NAME_LENGTH} symbols`);
      }
    },
    [setFormState, showWarning]
  );

  const handleEmailMeChange = useCallback(
    (value: boolean) => {
      setFormState((prevState) => {
        return value !== prevState.emailMe ? { ...prevState, emailMe: value } : prevState;
      });
    },
    [setFormState]
  );

  const handleLegacyUserChange = useCallback(
    (value: LegacyUser) => {
      setFormState((prevState) => {
        const prevLegacyUser = prevState.legacyUser;
        const prevUserId = prevLegacyUser?.userId ?? null;

        return value?.userId != null && value?.userId !== prevUserId
          ? { ...prevState, legacyUser: value }
          : prevState;
      });
    },
    [setFormState]
  );

  const handleCreateRateCard = useCallback(() => {
    return onCreateRateCard({
      rateCardName: formState.rateCardName,
      legacyUser: formState.legacyUser,
      emailMe: formState.emailMe,
    });
  }, [onCreateRateCard, formState.rateCardName, formState.legacyUser, formState.emailMe]);

  return (
    <Stack css={{ alignItems: "start", width: "$full" }}>
      <Text>
        You are about to create/update a Rate Card in the given user's account with the
        current View's search results.
      </Text>

      <Alert color="warning">
        <Text>
          <Text bold underline italic>
            IMPORTANT:
          </Text>
          &nbsp; The Rate Card itself will be created right away if it doesn't exist. But
          the searches will be imported in the background. It could take much time
          depending on the size of the importing dataset. The importing progress bar will
          be displayed for you to be in-sync with the process.
        </Text>
      </Alert>

      <Stack css={{ alignItems: "start", width: "$full", gap: 0 }}>
        <label>User</label>
        <LegacyUserSelect
          className="full-width"
          fetchGraphQL={fetchGraphQL}
          value={formState.legacyUser}
          onChange={handleLegacyUserChange}
          styles={reactSelectStyles}
        />
      </Stack>

      <Stack fill css={{ alignItems: "start", width: "$full", gap: 0 }}>
        <label>Rate Card Name</label>
        <TextInput
          css={{ height: "38px" }}
          value={formState.rateCardName || ""}
          onChange={handleRateCardNameChange}
          maxLength={MAX_NAME_LENGTH}
        />
        <Messager ref={messagerRef} />
      </Stack>

      <LabelInline bold={false}>
        <Checkbox checked={formState.emailMe} onCheckedChange={handleEmailMeChange}>
          <CheckboxIndicator>
            <Icon icon="check" />
          </CheckboxIndicator>
        </Checkbox>
        Send me an email when it's done.
      </LabelInline>

      <ButtonGroupRight fill>
        <PromiseButton
          color="brand"
          size="large"
          title="Apply changes"
          loadingText="Create Rate Card"
          disabled={formState.rateCardName == null || formState.legacyUser == null}
          onClick={handleCreateRateCard}
        >
          Create Rate Card
        </PromiseButton>
        <Button size="large" onClick={onClose}>
          Cancel
        </Button>
      </ButtonGroupRight>
    </Stack>
  );
};

CreateRateCardForm.displayName = "CreateRateCardForm";

export default CreateRateCardForm;
