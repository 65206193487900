import React, { useState, useCallback } from "react";

import Stack from "../../../../components/lib/Stack";
import Button from "../../../../components/lib/Button";
import Table from "../../lib/Table";
import { TBody, TR, TD } from "../../../../components/lib/Table";
import { ButtonGroupRight } from "../../../../components/lib/ButtonGroup";
import { emptyOrderedMap } from "../../../../constants";
import { JOBS_ORDERING_KEYS } from "../../types";

import type {
  ReviewsDataOrderedMap,
  ReviewDataMap,
  JOBS_ORDERING_KEYS_TYPE,
} from "../../types";

export type ReviewsPeckingOrderTableBaseProps = {
  reviewId: string;
  reviewsGroupId: string;
  reviewsData: ReviewsDataOrderedMap;
  emptyDataText?: string;
};

export type FuncGetAllReviewsOrdered = (
  reviewsGroupData: ReviewsDataOrderedMap,
  dataOrderingKey: JOBS_ORDERING_KEYS_TYPE,
  asc: boolean
) => ReviewsDataOrderedMap;

type PageStateType = {
  reviewsData: ReviewsDataOrderedMap;
  dataOrderingKey: JOBS_ORDERING_KEYS_TYPE;
  dataOrderingAsc: boolean;
};

type Props = ReviewsPeckingOrderTableBaseProps & {
  tableHead: React.ReactElement;
  renderTableRow: (reviewData: ReviewDataMap) => React.ReactElement;
  getAllReviewsOrdered: FuncGetAllReviewsOrdered;
};

function ReviewsPeckingOrderTableBase(props: Props) {
  const { reviewsData, emptyDataText, tableHead, renderTableRow, getAllReviewsOrdered } =
    props;

  // state

  const [state, setState] = useState<PageStateType>({
    reviewsData: emptyOrderedMap as unknown as ReviewsDataOrderedMap,
    dataOrderingKey: JOBS_ORDERING_KEYS.MIN_VALUE,
    dataOrderingAsc: true,
  });

  const getAllReviews = useCallback(() => {
    return reviewsData || emptyOrderedMap;
  }, [reviewsData]);

  // handlers

  const handleMakeTableOrdered = useCallback(
    (dataOrderingKey: JOBS_ORDERING_KEYS_TYPE) => {
      const dataOrderingAsc =
        dataOrderingKey === state.dataOrderingKey ? !state.dataOrderingAsc : true;

      setState((prevData) => ({
        ...prevData,
        dataOrderingKey,
        dataOrderingAsc,
      }));
    },
    [state.dataOrderingKey, state.dataOrderingAsc]
  );

  // render

  const orderedReviewsData = getAllReviewsOrdered(
    getAllReviews(),
    state.dataOrderingKey,
    state.dataOrderingAsc
  );
  const icon = state.dataOrderingAsc ? "sort-numeric-down" : "sort-numeric-down-alt";
  const orderedReviewsDataSize = orderedReviewsData?.size || 0;

  return (
    <Stack fill css={{ alignItems: "stretch" }}>
      <Table highlighted={false} center>
        {tableHead}
        <TBody>
          {orderedReviewsDataSize ? (
            orderedReviewsData.toArray().map(renderTableRow)
          ) : (
            <TR>
              <TD colSpan={999}>{emptyDataText}</TD>
            </TR>
          )}
        </TBody>
      </Table>
      {orderedReviewsDataSize > 1 && (
        <ButtonGroupRight>
          <Button
            color="brand"
            variant="outlined"
            icon={
              state.dataOrderingKey === JOBS_ORDERING_KEYS.MIN_VALUE ? icon : undefined
            }
            onClick={() => handleMakeTableOrdered(JOBS_ORDERING_KEYS.MIN_VALUE)}
          >
            Sort Surveys By Lowest Wage
          </Button>
          <Button
            color="brand"
            variant="outlined"
            icon={
              state.dataOrderingKey === JOBS_ORDERING_KEYS.MAX_VALUE ? icon : undefined
            }
            onClick={() => handleMakeTableOrdered(JOBS_ORDERING_KEYS.MAX_VALUE)}
          >
            Sort Surveys By Highest Wage
          </Button>
        </ButtonGroupRight>
      )}
    </Stack>
  );
}

ReviewsPeckingOrderTableBase.displayName = "ReviewsPeckingOrderTableBase";

export default ReviewsPeckingOrderTableBase;
