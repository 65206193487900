// @flow

import { extendObservable, runInAction, action } from "mobx";
import NetworkState from "../NetworkState";
import type { FetchGraphQL } from "../../App";

export type JobsByCountryDataItem = {
  country: string,
  state: string,
  jobs: number,
};

export class JobsByCountryData {
  usOnly: ?boolean;
  series: ?(JobsByCountryDataItem[]);
  geojson: ?Object;

  constructor(payloadData: ?Object) {
    this.usOnly = null;
    this.series = null;
    this.geojson = null;

    if (payloadData) {
      this.usOnly = payloadData.usOnly;
      this.series = payloadData.series;
      this.geojson = payloadData.geojson ? JSON.parse(payloadData.geojson) : null;
    }
  }
}

export default class JobsByCountryChoroplethState {
  rateCardStore: Object;
  network: NetworkState;
  fetchGraphQL: FetchGraphQL;
  rateCardId: number;
  chartData: JobsByCountryData;
  load: (?boolean) => Promise<any>;

  constructor(store: Object, fetchGraphQL: FetchGraphQL) {
    this.rateCardStore = store;
    this.fetchGraphQL = fetchGraphQL;

    extendObservable(this, {
      network: new NetworkState(),
      chartData: new JobsByCountryData(null),
    });
    this.load = action(this.load.bind(this));
  }

  async load(fullReload: boolean = false) {
    this.network.loading = true;

    if (!fullReload && this.chartData) return;

    const query = `
    query getRateCardVisualizations($rateCardId: Int!) {
      viewer {
        rateCard(legacyId: $rateCardId) {
          visualizations {
            jobsByCountry {
              usOnly
              geojson
            }
          }
        }
      }
    }
    `;

    const variables = {
      rateCardId: this.rateCardStore.rateCardId,
    };

    let payload: ?Object;
    try {
      payload = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Getting Rate Card Visualizations", e);
      // TODO: Display user friendly error message
      return;
    }

    runInAction("getVisualizations--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("getRateCardVisualizations", payload)) {
        // TODO: Display user friendly error message
        return;
      }

      // NOTE: Flow refinement
      if (payload == null) {
        return;
      }

      this.chartData = new JobsByCountryData(
        payload.data.viewer.rateCard.visualizations.jobsByCountry
      );
    });
  }
}
