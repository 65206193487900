import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import ContainerSection from "../../ratecards/components/ContainerSection";
import CheckBox from "../../shared/CheckBox";

/**
 * ClientDetail component for job_labels detail page
 */
class Detail extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  componentDidMount() {
    let store = this.props.store.clientCreatePTStore;
    console.log(store);
    store.clientName = "";
    store.shareRatecardsFlag = false;
    store.activeFlag = true;
    store.perSearchPricingFlag = false;
    store.isClientLibrary = false;
    store.hasThreeLevel = false;
    store.hasNumericLevelsOnExport = false;
    store.isRefreshEligible = true;
  }

  /**
   * componentDidMount
   * @return {void}
   */
  UNSAFE_componentWillMount() {
    // this.props.store.clientDetailPTStore.clientId = this.props.router.params.clientId;
    this.props.store.clientCreatePTStore.router = this.context.router;
    this.props.store.clientCreatePTStore.clearAllErrorMessage();
    // this.props.store.clientDetailPTStore.getClientDetails();
  }
  /**
   * Render rate card detail page
   * @return {XML}
   */
  render() {
    const store = this.props.store.clientCreatePTStore;

    return (
      <div className="view">
        <div className="rc-container bring-forward">
          <div className="header-action-bar">
            <div className="btn-group pull-left">
              <h2>Create New Client</h2>
            </div>
            <div className="clearfix" />
          </div>
          <div className="row userjob">
            <div className="col-sm-6 col-md-6 col-lg-6">
              <div className="form-group">
                <label>Name</label>
                <div className="input_group">
                  <input
                    type="text"
                    min="0"
                    className="form-control"
                    value={store.clientName}
                    onChange={store.setClientName}
                  />
                  {store.messaging.messages.length > 0 && (
                    <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                      <a
                        href="#close"
                        className="close"
                        data-dismiss="alert"
                        aria-label="close"
                        onClick={store.messaging.removeMessage.bind(
                          store,
                          store.messaging.messages[0]
                        )}
                      >
                        &times;
                      </a>
                      <strong>{store.messaging.messages[0].messageLabel}</strong>
                      {store.messaging.messages[0].message}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-4 col-md-4 col-lg-4">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <label>Share Rate Card Flag</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <CheckBox
                          type="radio"
                          name="list-item-select"
                          value="true"
                          selected={store.shareRatecardsFlag}
                          onClick={store.setShareRatecardsFlag}
                          labelName={"Yes"}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <CheckBox
                          type="radio"
                          name="list-item-select"
                          value="false"
                          selected={!store.shareRatecardsFlag}
                          onClick={store.setShareRatecardsFlag}
                          labelName={"No"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <label>Active</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <CheckBox
                          type="radio"
                          name="list-item-select"
                          value="true"
                          selected={store.activeFlag}
                          onClick={store.setActiveFlag}
                          labelName={"Yes"}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <CheckBox
                          type="radio"
                          name="list-item-select"
                          value="false"
                          selected={!store.activeFlag}
                          onClick={store.setActiveFlag}
                          labelName={"No"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <label>Per Search Pricing</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <CheckBox
                          type="radio"
                          name="list-item-select"
                          value="true"
                          selected={store.perSearchPricingFlag}
                          onClick={store.setSearchPricingFlag}
                          labelName={"Yes"}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <CheckBox
                          type="radio"
                          name="list-item-select"
                          value="false"
                          selected={!store.perSearchPricingFlag}
                          onClick={store.setSearchPricingFlag}
                          labelName={"No"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <label>Client Library</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <CheckBox
                          type="radio"
                          name="list-item-select"
                          value="true"
                          selected={store.isClientLibrary}
                          onClick={store.setClientLibraryFlag}
                          labelName={"Yes"}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <CheckBox
                          type="radio"
                          name="list-item-select"
                          value="false"
                          selected={!store.isClientLibrary}
                          onClick={store.setClientLibraryFlag}
                          labelName={"No"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <label>Three Level</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <CheckBox
                          type="radio"
                          name="list-item-select"
                          value="true"
                          selected={store.hasThreeLevel}
                          onClick={store.setThreeLevel}
                          labelName={"Yes"}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <CheckBox
                          type="radio"
                          name="list-item-select"
                          value="false"
                          selected={!store.hasThreeLevel}
                          onClick={store.setThreeLevel}
                          labelName={"No"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <label>Has Numeric Levels On Export</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <CheckBox
                          type="radio"
                          name="list-item-select"
                          value="true"
                          selected={store.hasNumericLevelsOnExport}
                          onClick={store.setHasNumericLevelsOnExport}
                          labelName={"Yes"}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <CheckBox
                          type="radio"
                          name="list-item-select"
                          value="false"
                          selected={!store.hasNumericLevelsOnExport}
                          onClick={store.setHasNumericLevelsOnExport}
                          labelName={"No"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-4 col-md-4 col-lg-4">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <label>Refresh Eligible</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <CheckBox
                          type="radio"
                          name="refresh-elegible"
                          value="true"
                          selected={store.isRefreshEligible}
                          onClick={store.setRefreshEligible}
                          labelName={"Yes"}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <CheckBox
                          type="radio"
                          name="refresh-elegible"
                          value="false"
                          selected={!store.isRefreshEligible}
                          onClick={store.setRefreshEligible}
                          labelName={"No"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ContainerSection className="footer">
            <div className="pull-right">
              <button
                className="btn btn-lg btn-default"
                onClick={(e) => {
                  store.redirectToList();
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-lg btn-green"
                onClick={(e) => {
                  store.createClient();
                }}
              >
                Create Client
              </button>
            </div>
          </ContainerSection>
        </div>
      </div>
    );
  }
}

export default observer(Detail);
