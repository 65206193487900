// @flow
import React, { Component } from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToggleButton, { ToggleButtonGroup } from "./ToggleButton";

type ContingentRateType = "hourly" | "daily" | "weekly" | "monthly";

export const CONTINGENT_RATE_TYPE = {
  HOURLY: "hourly",
  DAILY: "daily",
  WEEKLY: "weekly",
  MONTHLY: "monthly",
};

type Props = {
  selectedOption?: ContingentRateType,
  onChange?: (ContingentRateType) => void,
  onConfig?: () => void,
};

class RateDisplayToggle extends Component {
  props: Props;

  static defaultProps = {
    selectedOption: "hourly",
  };

  _handleToggleButtonOnClick = (value: ContingentRateType) => {
    if (this.props.onChange) this.props.onChange(value);
  };

  _handleConfigButtonOnClick = () => {
    if (this.props.onConfig) this.props.onConfig();
  };

  render() {
    const selectedOption = this.props.selectedOption;

    return (
      <ToggleButtonGroup>
        <ToggleButton
          name="rate-options"
          type="radio"
          item={CONTINGENT_RATE_TYPE.HOURLY}
          value={CONTINGENT_RATE_TYPE.HOURLY}
          selected={CONTINGENT_RATE_TYPE.HOURLY === selectedOption}
          onClick={this._handleToggleButtonOnClick}
        >
          Hourly
        </ToggleButton>
        <ToggleButton
          name="rate-options"
          type="radio"
          item={CONTINGENT_RATE_TYPE.DAILY}
          value={CONTINGENT_RATE_TYPE.DAILY}
          selected={CONTINGENT_RATE_TYPE.DAILY === selectedOption}
          onClick={this._handleToggleButtonOnClick}
        >
          Daily
        </ToggleButton>
        <ToggleButton
          name="rate-options"
          type="radio"
          item={CONTINGENT_RATE_TYPE.WEEKLY}
          value={CONTINGENT_RATE_TYPE.WEEKLY}
          selected={CONTINGENT_RATE_TYPE.WEEKLY === selectedOption}
          onClick={this._handleToggleButtonOnClick}
        >
          Weekly
        </ToggleButton>
        <ToggleButton
          name="rate-options"
          type="radio"
          item={CONTINGENT_RATE_TYPE.MONTHLY}
          value={CONTINGENT_RATE_TYPE.MONTHLY}
          selected={CONTINGENT_RATE_TYPE.MONTHLY === selectedOption}
          onClick={this._handleToggleButtonOnClick}
        >
          Monthly
        </ToggleButton>
        <ToggleButton
          name="rate-options"
          type="radio"
          value="config"
          selected={false}
          onClick={this._handleConfigButtonOnClick}
        >
          <FontAwesomeIcon icon="cog" fixedWidth />
        </ToggleButton>
      </ToggleButtonGroup>
    );
  }
}

export default observer(RateDisplayToggle);
