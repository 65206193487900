import { Iterable, List, Map, Set, OrderedMap, fromJS } from "immutable";

import { emptyList, emptyOrderedMap } from "../../constants";
import {
  baseDjangoResponseConverter,
  SpecificDjangoResponseConverter,
} from "../../utils/django";
// @ts-expect-error
import byKey from "../../utils/byKey";

import {
  SKILLS_LEVELS_KEYS,
  ExportsDataList,
  RATE_TYPES_TYPE,
  CONTINGENT_VALUES_TYPES_TYPE,
  ALL_VALUES_TYPES_TYPE,
  VALUES_SUBTYPES_TYPE,
  SKILLS_LEVELS_TYPE,
  JOB_TITLE_MATCH_TYPES_TYPE,
  TABLE_TABS_TYPES_TYPE,
  CONTRACTOR_STATUSES_TYPE,
  PROCESSING_TYPES_TYPE,
  PROCESSING_STATUSES_TYPE,
  EXPORT_TYPES_TYPE,
  EXPORT_STATUSES_TYPE,
} from "./types";

import type {
  StoredIndexDataObject,
  ContractorDataObject,
  ContractorsDataOrderedMap,
  ContractorsDataList,
  StoredIndexTrendDataObject,
  StoredIndexTrendDataMap,
  StoredIndexDataMap,
  ProgramDataObject,
  ProgramDataMap,
  ProcessingDataObject,
  ProcessingDataMap,
  DiscoverySearchResultObject,
  DiscoverySearchResultMap,
  ProcessedContractorDataObject,
  ContractorsUploadsDataList,
  ContractorsUploadDataObject,
  ContractorsUploadDataMap,
  ProgramAccessDataObject,
  SampleVideoDataObject,
  SampleVideoDataMap,
  ContractorDataMap,
  MarketAnalysisDataObject,
  MarketAnalysisDataMap,
  MarketAnalysisResultObject,
  MarketAnalysisVariancesResultObject,
  AllLevelsDatasetLengthObject,
  AllMarketRatesObject,
  ContractorsTableTabsVisibleColumnsMap,
  ProcessedContractorDataMap,
  ProgramAdminDataMap,
  ProgramAdminDataObject,
  ProgramsDataOrderedMap,
  StoredIndexTrendDataRates,
  StoredIndexesDataList,
  StoredIndexesDataOrderedMap,
  ProgramAccessDataMap,
  ProgramAccessDataList,
  ProgramAccessDataOrderedMap,
  ProgramsDataList,
  SampleVideosDataList,
  ProcessingsDataOrderedMap,
  DiscoverySearchResultTotalsObject,
  DiscoverySearchResultTotalsMap,
  DiscoverySearchResultsList,
  ExportDataObject,
  ExportDataMap,
  CurrencyObject,
  CurrencyMap,
  AllCurrenciesMap,
  StoredIndexDuplicateData,
  StoredIndexDuplicateMap,
} from "./types";
import type { SavingsDataObject, SavingsDataMap } from "./components/SavingsDonut";
import type {
  FiltersQueryOrderedMap,
  FiltersConfigOrderedMap,
} from "../../components/tables/types";
import type { ImmutableArbitraryMap, ImmutableList } from "../../types/immutable";
import {
  BulkDeleteCheckResultMap,
  BulkDeleteCheckResultObject,
  BulkUpdateCheckResultMap,
  BulkUpdateCheckResultObject,
} from "./pages/StoredIndexCreate";

const defaultImmutableConversion = (key: any, value: any) => {
  return Iterable.isIndexed(value) ? value.toList() : value.toOrderedMap();
};

export const marketAnalysisItemToImmutableMap = (
  analysis: MarketAnalysisDataObject
): MarketAnalysisDataMap => {
  analysis["created"] = new Date(analysis["created"]);
  analysis["updated"] = new Date(analysis["updated"]);
  analysis["refresh_timestamp"] = new Date(analysis["refresh_timestamp"]);

  // convert matched exp. level
  analysis["market_level"] =
    analysis["market_level"] != null
      ? (parseInt("" + analysis["market_level"], 10) as SKILLS_LEVELS_TYPE)
      : null;

  // convert matched values
  (
    ["pay_rate", "markup", "bill_rate", "annual_salary"] as ALL_VALUES_TYPES_TYPE[]
  ).forEach((valueType) => {
    (["avg", "min", "mid", "max"] as VALUES_SUBTYPES_TYPE[]).forEach((valueSubtype) => {
      // values
      const valueKey =
        `market_${valueType}_${valueSubtype}` as keyof MarketAnalysisResultObject;
      analysis[valueKey] =
        analysis[valueKey] != null ? parseFloat("" + analysis[valueKey]) : null;
      // variances
      const varianceKey =
        `market_${valueType}_${valueSubtype}_variance` as keyof MarketAnalysisVariancesResultObject;
      analysis[varianceKey] =
        analysis[varianceKey] != null ? parseFloat("" + analysis[varianceKey]) : null;
    });
  });

  // convert savings
  analysis["pay_rate_potential_savings"] =
    analysis["pay_rate_potential_savings"] != null
      ? parseFloat("" + analysis["pay_rate_potential_savings"])
      : null;
  analysis["pay_rate_achieved_savings"] =
    analysis["pay_rate_achieved_savings"] != null
      ? parseFloat("" + analysis["pay_rate_achieved_savings"])
      : null;
  analysis["bill_rate_potential_savings"] =
    analysis["bill_rate_potential_savings"] != null
      ? parseFloat("" + analysis["bill_rate_potential_savings"])
      : null;
  analysis["bill_rate_achieved_savings"] =
    analysis["bill_rate_achieved_savings"] != null
      ? parseFloat("" + analysis["bill_rate_achieved_savings"])
      : null;
  analysis["annual_salary_potential_savings"] =
    analysis["annual_salary_potential_savings"] != null
      ? parseFloat("" + analysis["annual_salary_potential_savings"])
      : null;
  analysis["annual_salary_achieved_savings"] =
    analysis["annual_salary_achieved_savings"] != null
      ? parseFloat("" + analysis["annual_salary_achieved_savings"])
      : null;

  // convert all market values
  Object.values(SKILLS_LEVELS_KEYS).forEach((levelKey) => {
    // dataset length
    const datasetKey = `${levelKey}_dataset_length` as keyof AllLevelsDatasetLengthObject;
    analysis[datasetKey] =
      analysis[datasetKey] != null ? parseInt("" + analysis[datasetKey], 10) : null;

    // values
    (["pay_rate", "markup", "bill_rate"] as CONTINGENT_VALUES_TYPES_TYPE[]).forEach(
      (valueType) => {
        (["avg", "min", "mid", "max"] as VALUES_SUBTYPES_TYPE[]).forEach(
          (valueSubtype) => {
            const valueKey =
              `${levelKey}_${valueType}_${valueSubtype}` as keyof AllMarketRatesObject;
            analysis[valueKey] =
              analysis[valueKey] != null ? parseFloat("" + analysis[valueKey]) : null;
          }
        );
      }
    );
  });

  // convert surveys/validations data
  analysis["active_surveys_count"] =
    analysis["active_surveys_count"] != null
      ? parseInt("" + analysis["active_surveys_count"], 10)
      : null;
  analysis["inactive_surveys_count"] =
    analysis["inactive_surveys_count"] != null
      ? parseInt("" + analysis["inactive_surveys_count"], 10)
      : null;
  analysis["total_surveys_count"] =
    analysis["total_surveys_count"] != null
      ? parseInt("" + analysis["total_surveys_count"], 10)
      : null;
  analysis["validations_count"] =
    analysis["validations_count"] != null
      ? parseInt("" + analysis["validations_count"], 10)
      : null;

  return fromJS(analysis);
};

export const contractorMappingsItemToImmutableMap = (
  mappings: ProcessedContractorDataObject
): ProcessedContractorDataMap => {
  mappings["created"] = new Date(mappings["created"]);
  mappings["updated"] = new Date(mappings["updated"]);

  mappings["industry_id"] =
    mappings["industry_id"] != null ? parseInt("" + mappings["industry_id"], 10) : null;

  mappings["country_id"] =
    mappings["country_id"] != null ? parseInt("" + mappings["country_id"], 10) : null;
  mappings["region_id"] =
    mappings["region_id"] != null ? parseInt("" + mappings["region_id"], 10) : null;
  mappings["currency_id"] =
    mappings["currency_id"] != null ? parseInt("" + mappings["currency_id"], 10) : null;
  mappings["currency_factor"] =
    mappings["currency_factor"] != null
      ? parseFloat("" + mappings["currency_factor"])
      : null;

  mappings["job_title_id"] =
    mappings["job_title_id"] != null ? parseInt("" + mappings["job_title_id"], 10) : null;
  mappings["job_collection_id"] =
    mappings["job_collection_id"] != null
      ? parseInt("" + mappings["job_collection_id"], 10)
      : null;
  mappings["job_collection_public_title_id"] =
    mappings["job_collection_public_title_id"] != null
      ? parseInt("" + mappings["job_collection_public_title_id"], 10)
      : null;
  mappings["job_title_match_type"] =
    mappings["job_title_match_type"] != null
      ? (parseInt(
          "" + mappings["job_title_match_type"],
          10
        ) as unknown as JOB_TITLE_MATCH_TYPES_TYPE)
      : null;

  return fromJS(mappings);
};

export const contractorItemToImmutableMap = (
  contractor: ContractorDataObject
): ContractorDataMap => {
  contractor["date_engaged"] = new Date(contractor["date_engaged"]);
  contractor["rate_type"] = parseInt("" + contractor["rate_type"], 10) as RATE_TYPES_TYPE;
  contractor["exp_level"] =
    contractor["exp_level"] != null ? parseInt("" + contractor["exp_level"], 10) : null;
  contractor["status"] = parseInt(
    "" + contractor["status"],
    10
  ) as CONTRACTOR_STATUSES_TYPE;

  contractor["errors"] =
    contractor["errors"] != null ? fromJS(contractor["errors"]) : null;
  contractor["warnings"] =
    contractor["warnings"] != null ? fromJS(contractor["warnings"]) : null;

  type ClientRateKeys =
    | "pay_rate"
    | "bill_rate"
    | "markup"
    | "markup_amount"
    | "annual_salary"
    | "burden_perc"
    | "total_cost";

  // convert client rates
  (
    [
      "pay_rate",
      "bill_rate",
      "markup",
      "markup_amount",
      "annual_salary",
      "burden_perc",
      "total_cost",
    ] as ClientRateKeys[]
  ).forEach((key) => {
    contractor[key] = contractor[key] != null ? parseFloat("" + contractor[key]) : null;
  });

  // convert market analysis data
  if (contractor["market_analysis"] != null) {
    contractor["market_analysis"] = marketAnalysisItemToImmutableMap(
      contractor["market_analysis"] as unknown as MarketAnalysisDataObject
    );
  }

  // convert mappings data
  if (contractor["processed"] != null) {
    contractor["processed"] = contractorMappingsItemToImmutableMap(
      contractor["processed"] as unknown as ProcessedContractorDataObject
    );
  }

  return fromJS(contractor, defaultImmutableConversion);
};

const contractorsListToImmutableOrderedMap = (
  list: ContractorDataObject[]
): ContractorsDataOrderedMap => {
  return byKey(
    list,
    (i: ContractorDataObject) => i["id"],
    OrderedMap,
    contractorItemToImmutableMap
  );
};

const contractorsListToImmutableList = (
  list: ContractorDataObject[]
): ContractorsDataList => {
  return List(list.map(contractorItemToImmutableMap)) as ContractorsDataList;
};

export const transformContractorsData: SpecificDjangoResponseConverter<
  ContractorDataObject,
  ContractorsDataOrderedMap
> = (...args) => {
  return baseDjangoResponseConverter(
    contractorsListToImmutableOrderedMap,
    emptyOrderedMap as unknown as ContractorsDataOrderedMap,
    ...args
  );
};

const storedIndexTrendDataToImmutableMap = (
  item: StoredIndexTrendDataObject
): StoredIndexTrendDataMap => {
  item["created"] = new Date(item["created"]);
  item["updated"] = new Date(item["updated"]);
  item["freshness_timestamp"] = new Date(item["freshness_timestamp"]);

  item["dataset_length"] = parseInt("" + item["dataset_length"], 10);
  item["available_dataset_length"] = parseInt("" + item["available_dataset_length"], 10);

  (
    [
      "pay_rate_low",
      "pay_rate_median",
      "pay_rate_high",
      "pay_rate_average",
      "markup_low",
      "markup_median",
      "markup_high",
      "markup_average",
      "bill_rate_low",
      "bill_rate_median",
      "bill_rate_high",
      "bill_rate_average",
      "annual_salary_low",
      "annual_salary_median",
      "annual_salary_high",
      "annual_salary_average",
    ] as Array<keyof StoredIndexTrendDataRates>
  ).forEach((key) => {
    item[key] = item[key] != null ? parseFloat("" + item[key]) : null;
  });

  return fromJS(item);
};

export const storedIndexToImmutableMap = (
  item: StoredIndexDataObject
): StoredIndexDataMap => {
  const filters = (item["filters"] || "") as unknown as string;
  const djFilters = (item["dj_filters"] || "") as unknown as string;
  const visibleColumns = (item["visible_columns"] || "") as unknown as string;
  const contractors = (item["contractors"] || []) as unknown as ContractorDataObject[];

  item["filters"] = emptyOrderedMap as unknown as FiltersConfigOrderedMap;
  if (filters && filters.length) {
    item["filters"] = OrderedMap(JSON.parse(filters)).map((item) =>
      fromJS(item)
    ) as FiltersConfigOrderedMap;
  }
  item["dj_filters"] = emptyOrderedMap as unknown as FiltersQueryOrderedMap;
  if (djFilters && djFilters.length) {
    item["dj_filters"] = OrderedMap(JSON.parse(djFilters)).map((item) =>
      fromJS(item)
    ) as FiltersQueryOrderedMap;
  }
  if (contractors.length) {
    item["contractors"] = contractorsListToImmutableList(contractors);
  }
  if (item["first_trend_data"]) {
    item["first_trend_data"] = storedIndexTrendDataToImmutableMap(
      item["first_trend_data"] as unknown as StoredIndexTrendDataObject
    );
  }
  if (item["last_trend_data"]) {
    item["last_trend_data"] = storedIndexTrendDataToImmutableMap(
      item["last_trend_data"] as unknown as StoredIndexTrendDataObject
    );
  }
  if (visibleColumns) {
    item["visible_columns"] = (
      Map(JSON.parse(visibleColumns)) as unknown as ImmutableArbitraryMap<
        TABLE_TABS_TYPES_TYPE,
        string[]
      >
    ).map((list: string[]) =>
      Set(list)
    ) as unknown as ContractorsTableTabsVisibleColumnsMap;
  }

  return fromJS(item);
};

export const storedIndexesListToImmutableList = (
  list: StoredIndexDataObject[]
): StoredIndexesDataList => {
  return List(list.map(storedIndexToImmutableMap)) as StoredIndexesDataList;
};

export const storedIndexesListToImmutableOrderedMap = (
  list: StoredIndexDataObject[]
): StoredIndexesDataOrderedMap => {
  return byKey(
    list,
    (i: StoredIndexDataObject) => i["id"],
    OrderedMap,
    storedIndexToImmutableMap
  );
};

export const storedIndexDuplicateToImmutableMap = (
  item: StoredIndexDuplicateData
): StoredIndexDuplicateMap => {
  return fromJS(item);
};

export const programAdminToImmutableMap = (
  item: ProgramAdminDataObject
): ProgramAdminDataMap => {
  item["created"] = new Date(item["created"]);
  item["updated"] = new Date(item["updated"]);

  return fromJS(item);
};

const programAccessToImmutableMap = (
  item: ProgramAccessDataObject
): ProgramAccessDataMap => {
  item["created"] = new Date(item["created"]);
  item["updated"] = new Date(item["updated"]);

  return fromJS(item);
};

const programAccessListToImmutableList = (
  list: ProgramAccessDataObject[]
): ProgramAccessDataList => {
  return List(list.map(programAccessToImmutableMap)) as ProgramAccessDataList;
};

const programAccessListToImmutableOrderedMap = (
  list: ProgramAccessDataObject[]
): ProgramAccessDataOrderedMap => {
  return byKey(
    list,
    (i: ProgramAccessDataObject) => i["id"],
    OrderedMap,
    programAccessToImmutableMap
  );
};

export const transformProgramAccessData: SpecificDjangoResponseConverter<
  ProgramAccessDataObject,
  ProgramAccessDataOrderedMap
> = (...args) => {
  return baseDjangoResponseConverter(
    programAccessListToImmutableOrderedMap,
    emptyOrderedMap as unknown as ProgramAccessDataOrderedMap,
    ...args
  );
};

export const programToImmutableMap = (item: ProgramDataObject): ProgramDataMap => {
  item["created"] = new Date(item["created"]);
  item["updated"] = new Date(item["updated"]);

  const indexesList = (item["stored_indexes"] ||
    []) as unknown as StoredIndexDataObject[];
  const accessList = (item["access"] || []) as unknown as ProgramAccessDataObject[];
  const iconUrl = item["icon"];

  if (indexesList && indexesList.length) {
    item["stored_indexes"] = storedIndexesListToImmutableList(indexesList);
  }
  if (accessList && accessList.length) {
    item["access"] = programAccessListToImmutableList(accessList);
  }

  if (iconUrl != null && iconUrl.startsWith("/")) {
    item["icon"] = `_api/m8${iconUrl}`;
  }

  return fromJS(item);
};

const programsListToImmutableOrderedMap = (
  list: ProgramDataObject[]
): ProgramsDataOrderedMap => {
  return byKey(
    list,
    (i: ProgramDataObject) => i["id"],
    OrderedMap,
    programToImmutableMap
  );
};

export const programsListToImmutableList = (
  list: ProgramDataObject[]
): ProgramsDataList => {
  return List(list.map(programToImmutableMap)) as ProgramsDataList;
};

export const transformProgramsData: SpecificDjangoResponseConverter<
  ProgramDataObject,
  ProgramsDataOrderedMap
> = (...args) => {
  return baseDjangoResponseConverter(
    programsListToImmutableOrderedMap,
    emptyOrderedMap as unknown as ProgramsDataOrderedMap,
    ...args
  );
};

const sampleVideoToImmutableMap = (item: SampleVideoDataObject): SampleVideoDataMap => {
  const videoSrc = item["src"];
  const thumbnailSrc = item["thumbnail_src"];

  if (videoSrc != null && videoSrc.startsWith("/")) {
    item["src"] = `_api/m8${videoSrc}`;
  }
  if (thumbnailSrc != null && thumbnailSrc.startsWith("/")) {
    item["thumbnail_src"] = `_api/m8${thumbnailSrc}`;
  }

  return fromJS(item);
};

export const sampleVideosListToImmutableList = (
  list: SampleVideoDataObject[]
): SampleVideosDataList => {
  return List(list.map(sampleVideoToImmutableMap)) as SampleVideosDataList;
};

export const savingsDonutDataToImmutableMap = (
  data: SavingsDataObject
): SavingsDataMap => {
  if (data["potential_savings_perc"] != null) {
    data["potential_savings_perc"] = parseFloat("" + data["potential_savings_perc"]);
  }
  if (data["achieved_savings_perc"] != null) {
    data["achieved_savings_perc"] = parseFloat("" + data["achieved_savings_perc"]);
  }
  if (data["potential_savings"] != null) {
    data["potential_savings"] = parseFloat("" + data["potential_savings"]);
  }
  if (data["achieved_savings"] != null) {
    data["achieved_savings"] = parseFloat("" + data["achieved_savings"]);
  }

  return fromJS(data);
};

export const contractorsUploadToImmutableMap = (
  item: ContractorsUploadDataObject
): ContractorsUploadDataMap => {
  item["created"] = new Date(item["created"]);
  item["updated"] = new Date(item["updated"]);

  if (item["processing"] != null) {
    item["processing"] = contractorsProcessingToImmutableMap(
      item["processing"] as unknown as ProcessingDataObject
    );
  }

  return fromJS(item);
};

export const contractorsUploadsListToImmutableList = (
  list: ContractorsUploadDataObject[]
): ContractorsUploadsDataList => {
  return List(list.map(contractorsUploadToImmutableMap)) as ContractorsUploadsDataList;
};

export const transformContractorsUploadsData: SpecificDjangoResponseConverter<
  ContractorsUploadDataObject,
  ContractorsUploadsDataList
> = (...args) => {
  return baseDjangoResponseConverter(
    contractorsUploadsListToImmutableList,
    emptyList as ContractorsUploadsDataList,
    ...args
  );
};

export const contractorsProcessingToImmutableMap = (
  item: ProcessingDataObject
): ProcessingDataMap => {
  item["created"] = new Date(item["created"]);
  item["updated"] = new Date(item["updated"]);

  item["client_id"] = parseInt("" + item["client_id"], 10);
  item["user_id"] = parseInt("" + item["user_id"], 10);
  item["program_id"] = parseInt("" + item["program_id"], 10);
  item["stored_index_id"] =
    item["stored_index_id"] != null ? parseInt("" + item["stored_index_id"], 10) : null;
  item["upload_id"] =
    item["upload_id"] != null ? parseInt("" + item["upload_id"], 10) : null;

  item["type"] = parseInt("" + item["type"], 10) as PROCESSING_TYPES_TYPE;
  item["status"] = parseInt("" + item["status"], 10) as PROCESSING_STATUSES_TYPE;

  item["running_items"] = parseInt("" + item["running_items"], 10);
  item["finished_items"] = parseInt("" + item["finished_items"], 10);
  item["failed_items"] = parseInt("" + item["failed_items"], 10);
  item["total_items"] = parseInt("" + item["total_items"], 10);

  item["started_at"] = new Date(item["started_at"]);
  item["finished_at"] =
    item["finished_at"] != null ? new Date(item["finished_at"]) : null;
  item["time_spent"] =
    item["time_spent"] != null ? parseInt("" + item["time_spent"], 10) : null;
  item["estimated_item_time"] =
    item["estimated_item_time"] != null
      ? parseInt("" + item["estimated_item_time"], 10)
      : null;
  item["estimated_time_left"] =
    item["estimated_time_left"] != null
      ? parseInt("" + item["estimated_time_left"], 10)
      : null;
  item["queue_position_number"] =
    item["queue_position_number"] != null
      ? parseInt("" + item["queue_position_number"], 10)
      : null;

  return fromJS(item);
};

const contractorsProcessingsListToImmutableOrderedMap = (
  list: ProcessingDataObject[]
): ProcessingsDataOrderedMap => {
  return byKey(
    list,
    (i: ProcessingDataObject) => i["id"],
    OrderedMap,
    contractorsProcessingToImmutableMap
  );
};

export const transformContractorsProcessingsData: SpecificDjangoResponseConverter<
  ProcessingDataObject,
  ProcessingsDataOrderedMap
> = (...args) => {
  return baseDjangoResponseConverter(
    contractorsProcessingsListToImmutableOrderedMap,
    emptyOrderedMap as unknown as ProcessingsDataOrderedMap,
    ...args
  );
};

export const discoverySearchResultToImmutableMap = (
  item: DiscoverySearchResultObject
): DiscoverySearchResultMap => {
  item["job_collection_id"] = parseInt("" + item["job_collection_id"], 10);
  item["job_collection_public_title_id"] =
    item["job_collection_public_title_id"] != null
      ? parseInt("" + item["job_collection_public_title_id"], 10)
      : null;
  item["rows_count"] = parseInt("" + item["rows_count"], 10);
  item["labels_count"] = parseInt("" + item["labels_count"], 10);
  item["locations_count"] = parseInt("" + item["locations_count"], 10);
  item["regions_count"] = parseInt("" + item["regions_count"], 10);
  item["suppliers_count"] = parseInt("" + item["suppliers_count"], 10);
  item["managers_count"] = parseInt("" + item["managers_count"], 10);

  return fromJS(item);
};

export const discoverySearchTotalsToImmutableMap = (
  item: DiscoverySearchResultTotalsObject
): DiscoverySearchResultTotalsMap => {
  item["rows_count"] = parseInt("" + item["rows_count"], 10);
  item["labels_count"] = parseInt("" + item["labels_count"], 10);
  item["locations_count"] = parseInt("" + item["locations_count"], 10);
  item["regions_count"] = parseInt("" + item["regions_count"], 10);
  item["suppliers_count"] = parseInt("" + item["suppliers_count"], 10);
  item["managers_count"] = parseInt("" + item["managers_count"], 10);

  return fromJS(item);
};

export const discoverySearchResultsListToImmutableList = (
  list: DiscoverySearchResultObject[]
): DiscoverySearchResultsList => {
  return List(
    list.map(discoverySearchResultToImmutableMap)
  ) as DiscoverySearchResultsList;
};

export const discoverySearchResultsListToImmutableOrderedMap = (
  list: DiscoverySearchResultObject[]
) => {
  return byKey(
    list,
    (i: DiscoverySearchResultObject) => i["job_collection_id"],
    OrderedMap,
    discoverySearchResultToImmutableMap
  );
};

export const exportItemToImmutableMap = (item: ExportDataObject): ExportDataMap => {
  item["type"] = parseInt("" + item["type"], 10) as EXPORT_TYPES_TYPE;
  item["status"] = parseInt("" + item["status"], 10) as EXPORT_STATUSES_TYPE;

  item["dataset_length"] = parseInt("" + item["dataset_length"], 10);
  item["page"] = item["page"] != null ? parseInt("" + item["page"], 10) : null;
  item["page_size"] =
    item["page_size"] != null ? parseInt("" + item["page_size"], 10) : null;

  item["created"] = new Date(item["created"]);
  item["updated"] = new Date(item["updated"]);
  item["started_at"] = new Date(item["started_at"]);
  item["finished_at"] =
    item["finished_at"] != null ? new Date(item["finished_at"]) : null;

  if (item["file_url"] != null && item["file_url"].startsWith("/")) {
    item["file_url"] = `_api/m8${item["file_url"]}`;
  }

  return fromJS(item);
};
export const exportsListToImmutableList = (list: ExportDataObject[]): ExportsDataList => {
  return List(list.map(exportItemToImmutableMap)) as ExportsDataList;
};

const currencyItemToImmutableMap = (item: CurrencyObject): CurrencyMap => {
  item["id"] = parseInt("" + item["factor"], 10);
  item["factor"] = parseFloat("" + item["factor"]);

  return fromJS(item);
};
export const currenciesListToImmutableMap = (
  list: CurrencyObject[]
): AllCurrenciesMap => {
  return byKey(
    list,
    (i: CurrencyObject) => "" + i["code"],
    Map,
    currencyItemToImmutableMap
  );
};

export const bulkUpdateCheckDataToImmutableMap = (
  item: BulkUpdateCheckResultObject
): BulkUpdateCheckResultMap => {
  const idsToUpdate = (item["ids_to_update"] || []).map((i) =>
    parseInt("" + i, 10)
  ) as unknown as number[];

  item["ids_to_update"] = List(idsToUpdate) as ImmutableList<number>;
  item["unique_titles_count"] = parseInt("" + item["unique_titles_count"], 10);
  item["region_based_data_count"] = parseInt("" + item["region_based_data_count"], 10);
  item["location_based_data_count"] = parseInt(
    "" + item["location_based_data_count"],
    10
  );

  return fromJS(item);
};

export const bulkDeleteCheckDataToImmutableMap = (
  item: BulkDeleteCheckResultObject
): BulkDeleteCheckResultMap => {
  const idsToDelete = (item["ids_to_delete"] || []).map((i) =>
    parseInt("" + i, 10)
  ) as unknown as number[];

  item["ids_to_delete"] = List(idsToDelete) as ImmutableList<number>;
  item["ids_to_delete_count"] = parseInt("" + item["ids_to_delete_count"], 10);

  return fromJS(item);
};
