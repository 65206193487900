import React from "react";

import { THead, TH, TR, TD } from "../../../../components/lib/Table";

import Text from "../../../../components/lib/Text";
import TextCrop from "../../../validator5K_admin/components/TextCrop";
import RatesTableOrderedBase from "./base";
import { CheckboxItem, CheckedState } from "../../../../components/lib/Checkbox";
import { emptySet, emptyMap, emptyOrderedMap } from "../../../../constants";
import { decimalFormatter, getRegionString, getLocationString } from "../../constants";
import { LEVEL_KEYS } from "./utils";

import type { RatesTableOrderedBaseProps, RatesTableOrderedRowBaseProps } from "./base";
import type { JobDataMap } from "../../types";
import type { LEVEL_KEYS_TYPE } from "./utils";
import { useSelectMultipleRowsContext } from "../../context/SelectMultipleRowsContext";
import { useRateDisplayContext } from "../../context/RateDisplayContext";

type RateBlockProps = {
  rowData: JobDataMap;
  level: LEVEL_KEYS_TYPE;
};

function RateBlock(props: RateBlockProps) {
  const { rowData, level } = props;

  const { convertToDisplayCurrency, resultingCurrencySymbol } = useRateDisplayContext();

  const rateResults = rowData.get("rate_results") ?? emptyOrderedMap;
  const rateResult =
    level === LEVEL_KEYS.LOW
      ? rateResults.first() ?? emptyMap
      : rateResults.last() ?? emptyMap;
  const payRate: number | null = convertToDisplayCurrency(
    level === LEVEL_KEYS.LOW
      ? rateResult.get("pay_rate_min")
      : rateResult.get("pay_rate_max")
  );

  return <span>{decimalFormatter(payRate, false, resultingCurrencySymbol)}</span>;
}

const TableHead = () => {
  const { selecting, allIds, isAllSelected, onChangeSelected } =
    useSelectMultipleRowsContext();

  const handleSelectAllRows = (value: CheckedState) => {
    onChangeSelected?.(value ? allIds : emptySet);
  };

  return (
    <THead>
      <TR>
        {selecting && (
          <TH>
            <CheckboxItem
              css={{ justifyContent: "center" }}
              checked={isAllSelected}
              onCheckedChange={handleSelectAllRows}
            />
          </TH>
        )}
        <TH>Location</TH>
        <TH>Industry</TH>
        <TH>Title</TH>
        <TH>
          Lowest Wage
          <br />
          (least amount of experience)
        </TH>
        <TH>
          Highest Wage
          <br />
          (most amount of experience)
        </TH>
      </TR>
    </THead>
  );
};

const TableRow = (props: RatesTableOrderedRowBaseProps) => {
  const { jobId, jobsData } = props;

  const { selecting, selectedIds, onChangeSelected } = useSelectMultipleRowsContext();

  const jobData = jobsData.get(jobId);
  const jobIndustry = jobData.get("industry_name");
  const jobTitle = jobData.get("title");
  const jobDescription = jobData.get("description");
  const isRowSelected = !!(selecting && selectedIds.contains(jobId));

  const jobLocation = getLocationString(jobData);
  const isRowRegionBased = !!jobData.get("region");
  let jobRegion = getRegionString(jobData);
  if (isRowRegionBased && jobRegion && jobRegion.toLowerCase().indexOf("region") < 0) {
    jobRegion += " (region)";
  }

  // handlers

  const handleSelectRow = (rowId: number, value: CheckedState) => {
    onChangeSelected?.(value ? selectedIds.add(rowId) : selectedIds.delete(rowId));
  };

  // render

  return (
    <TR key={jobId}>
      {selecting && (
        <TD>
          <CheckboxItem
            css={{ justifyContent: "center" }}
            checked={isRowSelected}
            onCheckedChange={(value: CheckedState) => handleSelectRow(jobId, value)}
          />
        </TD>
      )}
      <TD>
        <TextCrop
          mode="tooltip"
          title="Location"
          text={isRowRegionBased ? jobRegion : jobLocation}
          emptyStub=""
        />
      </TD>
      <TD>
        <TextCrop mode="tooltip" title="Industry" text={jobIndustry} emptyStub="" />
      </TD>
      <TD>
        <Text underline>
          <TextCrop
            mode="modal"
            title={jobTitle}
            text={jobTitle}
            fullText={jobDescription}
            emptyStub=""
          />
        </Text>
      </TD>
      <TD>
        <RateBlock rowData={jobData} level={LEVEL_KEYS.LOW} />
      </TD>
      <TD>
        <RateBlock rowData={jobData} level={LEVEL_KEYS.HIGH} />
      </TD>
    </TR>
  );
};

export default function RatesTableOrdered(props: RatesTableOrderedBaseProps) {
  return (
    <RatesTableOrderedBase
      jobsData={props.jobsData}
      tableHeadImpl={TableHead}
      tableRowImpl={TableRow}
    />
  );
}

RatesTableOrdered.displayName = "RatesTableOrdered";
