import React from "react";
import { OrderedMap } from "immutable";

import { ValuesBlock, useSearchInputState } from "./BaseValuesFilter";
import {
  ValuesCheckFilter,
  BaseValuesCheckFilterProps,
  ValuesCheckListBlock,
  useSelectValuesHandlers,
} from "./ValuesCheckFilter";
import {
  OrderDirections,
  resultSetArgName,
  FilterTypes,
  emptyOrderedMap,
  djangoOperationSeparator,
  djangoOrderingKey,
  djangoPaginationKey,
  djangoPaginationSizeKey,
} from "../constants";
import {
  FiltersDataProviderRestful,
  FiltersQueryOrderedMap,
  OrderDirectionsType,
  RowData,
} from "../types";
import { SelectedValuesList, ValuesOrderedMap } from "./ValuesCheckList";
import { Activatable } from "./types";

const defaultPageNumber = 1;
const defaultItemsCount = 0;

const stringHashFunction = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

/**
 * Info: We have this redundant values format cause we need to provide some small key values to React
 * and actual value could be big, for example full description as a value
 **/
const transformToInternalValues = (data: any[]) => {
  return OrderedMap(
    data.map((value) => {
      const key = stringHashFunction("" + value);
      return [value, { key, value }];
    })
  ) as any as ValuesOrderedMap;
};

export type ValuesStateObject = {
  page: number;
  values: ValuesOrderedMap;
  itemsCount: number;
};

export const useValuesSource = (
  dataProvider: FiltersDataProviderRestful,
  dataProviderFilters: FiltersQueryOrderedMap,
  resultSetArgName: string,
  uniqueKey: string,
  defaultPageSize: number
) => {
  const [loadingState, setLoadingState] = React.useState<boolean>(false);

  const [valuesState, setValuesState] = React.useState<ValuesStateObject>({
    page: defaultPageNumber,
    values: emptyOrderedMap as ValuesOrderedMap,
    itemsCount: defaultItemsCount,
  });

  const searchValueRef = React.useRef<string | null>(null);
  const orderDirectionRef = React.useRef<OrderDirectionsType | null>(null);

  const fetchData = React.useCallback(
    async (
      search: string | null,
      orderDirection: OrderDirectionsType | null,
      page?: number,
      pageSize?: number
    ) => {
      searchValueRef.current = search;
      orderDirectionRef.current = orderDirection;

      // set filters

      const filtersQuery = {
        ...dataProviderFilters.toJS(),
        [resultSetArgName]: uniqueKey,
      };
      if (search) {
        filtersQuery[[uniqueKey, "icontains"].join(djangoOperationSeparator)] = (
          "" + search
        ).toLowerCase();
      }

      // set query

      const urlQuery: { [key: string]: string | number } = {};
      if (orderDirection && orderDirection !== OrderDirections.NO) {
        urlQuery[djangoOrderingKey] =
          (orderDirection === OrderDirections.DSC ? "-" : "") + uniqueKey;
      }
      if (page && pageSize) {
        urlQuery[djangoPaginationKey] = page;
        urlQuery[djangoPaginationSizeKey] = pageSize;
      }

      // request

      try {
        setLoadingState(true);
        const data = await dataProvider(urlQuery, filtersQuery);

        if (Array.isArray(data)) {
          return {
            values: transformToInternalValues(data),
            itemsCount: data.length,
          };
        }

        if (Array.isArray(data.results) && data.count != null) {
          return {
            values: transformToInternalValues(data.results),
            itemsCount: data.count,
          };
        }
      } catch (err) {
        console.error("Error occurred while filter values retrieval:", err);
      } finally {
        setLoadingState(false);
      }
    },
    [dataProvider, dataProviderFilters, resultSetArgName, uniqueKey]
  );

  const valuesSource = React.useCallback(
    async (
      search: string | null,
      orderDirection: OrderDirectionsType | null,
      page: number = defaultPageNumber,
      pageSize: number = defaultPageSize
    ) => {
      const data = await fetchData(search, orderDirection, page, pageSize);

      if (data == null) return;

      const { values, itemsCount } = data;

      if (values == null) return;

      setValuesState((prevData) => ({
        itemsCount,
        page,
        values: page === defaultPageNumber ? values : prevData.values.merge(values),
      }));
    },

    [defaultPageSize, fetchData]
  );

  const allValuesSource = React.useCallback(
    async (
      search: string,
      orderDirection: OrderDirectionsType,
      maxSelectItems?: number
    ) => {
      let data = null;

      if (valuesState.values.size >= valuesState.itemsCount) {
        const { values, itemsCount } = valuesState;
        data = { values, itemsCount };
      } else if (!!maxSelectItems && valuesState.values.size >= maxSelectItems) {
        const { values, itemsCount } = valuesState;
        data = { values, itemsCount };
      } else if (!!maxSelectItems) {
        data = await fetchData(search, orderDirection, 1, maxSelectItems);
      } else {
        data = await fetchData(search, orderDirection);
      }

      if (data) {
        const { values, itemsCount } = data;
        const nextPage =
          values.size && values.size > defaultPageSize
            ? Math.ceil(values.size / defaultPageSize)
            : defaultPageNumber;

        setValuesState({
          values,
          itemsCount,
          page: nextPage,
        });

        return values;
      }

      return null;
    },
    [valuesState, defaultPageSize, fetchData]
  );

  const handleScrollList: React.UIEventHandler<HTMLDivElement> = ({ currentTarget }) => {
    if (valuesState.values.size >= valuesState.itemsCount) return;

    if (
      !loadingState &&
      Math.round(currentTarget.scrollHeight - currentTarget.scrollTop) ===
        currentTarget.clientHeight
    ) {
      valuesSource(
        searchValueRef.current,
        orderDirectionRef.current,
        valuesState.page + 1
      );
    }
  };

  return {
    loading: loadingState,
    values: valuesState.values,
    itemsCount: valuesState.itemsCount,
    valuesSource,
    allValuesSource,
    handleScrollList,
  };
};

interface ValuesCheckFilterRestfulProps<RD = RowData>
  extends BaseValuesCheckFilterProps<RD> {
  dataProvider: FiltersDataProviderRestful;
  dataProviderFilters: FiltersQueryOrderedMap;
  uniqueKey: string;
  pageSize?: number;
  maxSelectItems?: number;
}

const ValuesCheckFilterRestful = React.forwardRef(
  <RD = RowData,>(
    props: ValuesCheckFilterRestfulProps<RD>,
    ref: React.ForwardedRef<Activatable>
  ) => {
    const {
      filter,
      pageSize,
      searchTimeout,
      maxSelectItems,
      emptyHint,
      uniqueKey,
      dataProvider,
      dataProviderFilters,
      isVisible,
      onChange,
    } = props;

    const [showAlertState, setShowAlertState] = React.useState(false);
    const showAlertWithTimeout = React.useCallback(
      (timeout = 1000) => {
        setShowAlertState(true);
        setTimeout(() => setShowAlertState(false), timeout);
      },
      [setShowAlertState]
    );

    const selectedValues = filter.getIn(["filter", "values"]);
    const orderDirection = filter.getIn(["order", "direction"], OrderDirections.NO);
    const searchValue = filter.getIn(["filter", "search"]);

    const { makeSearch, setSearchInputRef, setSearchFocus } = useSearchInputState(
      searchValue,
      filter,
      FilterTypes.VALUES_CHECKLIST,
      onChange
    );

    const { handleSelectValues: handleSelectValuesBase, handleUnselectAllValues } =
      useSelectValuesHandlers(selectedValues, filter, onChange);

    const {
      loading,
      values,
      itemsCount,
      valuesSource,
      allValuesSource,
      handleScrollList,
    } = useValuesSource(
      dataProvider,
      dataProviderFilters,
      resultSetArgName,
      uniqueKey,
      pageSize!
    );

    // external API

    React.useImperativeHandle(ref, () => ({
      initialize: () => valuesSource(searchValue, orderDirection, defaultPageNumber),
      focus: setSearchFocus,
    }));

    // handlers

    const handleSelectAllValues = async () => {
      const values = await allValuesSource(searchValue, orderDirection, maxSelectItems);

      if (values != null) {
        const selectedValues = !!maxSelectItems
          ? values.keySeq().slice(0, maxSelectItems).toList()
          : values.keySeq().toList();

        handleSelectValuesBase(selectedValues as SelectedValuesList);
      }
    };

    const handleSelectValues = (values: SelectedValuesList) => {
      if (values === selectedValues) return;

      if (maxSelectItems != null && values.size > maxSelectItems) {
        showAlertWithTimeout();
        values = values.slice(0, maxSelectItems);
      }

      handleSelectValuesBase(values);
    };

    // effect

    React.useEffect(() => {
      if (!isVisible) return;
      valuesSource(searchValue, orderDirection, defaultPageNumber);
    }, [isVisible, searchValue, orderDirection, valuesSource]);

    return (
      <ValuesBlock
        searchValue={searchValue}
        searchTimeout={searchTimeout!}
        inputRef={setSearchInputRef}
        maxSelectItems={maxSelectItems}
        itemsCount={itemsCount}
        onSearch={makeSearch}
        onSelectAll={handleSelectAllValues}
        onUnselectAll={handleUnselectAllValues}
      >
        <ValuesCheckListBlock
          values={values}
          selectedValues={selectedValues}
          maxSelectItems={maxSelectItems}
          loading={loading}
          showAlert={showAlertState}
          onSelect={handleSelectValues}
          onScroll={loading ? undefined : handleScrollList}
          emptyHint={emptyHint!}
        />
      </ValuesBlock>
    );
  }
) as {
  <RD = RowData>(
    props: ValuesCheckFilterRestfulProps<RD> & { ref: React.ForwardedRef<Activatable> }
  ): React.ReactElement;
  displayName: string;
  defaultProps: Partial<ValuesCheckFilterRestfulProps>;
};

ValuesCheckFilterRestful.displayName = "ValuesCheckFilterRestful";
ValuesCheckFilterRestful.defaultProps = {
  dataProviderFilters: emptyOrderedMap as FiltersQueryOrderedMap,
  searchTimeout: ValuesCheckFilter.defaultProps?.searchTimeout,
  emptyHint: ValuesCheckFilter.defaultProps?.emptyHint,
  pageSize: 30,
  maxSelectItems: 600, // !IMPORTANT: this value should be multiple of pageSize, example pageSize * 10
};

export default ValuesCheckFilterRestful;
