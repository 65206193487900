export function copyElementTextToClipboard(elementId: string): boolean {
  // @ts-ignore
  window.getSelection().selectAllChildren(document.getElementById(elementId));
  let success = false;
  try {
    success = document.execCommand("copy");
    // @ts-ignore
    window.getSelection().removeAllRanges();
  } catch (e) {
    console.error("Unable to copy to clipboard.", e);
  }

  return success;
}

/**
 * Checks if the input string is vulnerable to CSV Formula Injection.
 *
 * CSV Formula Injection occurs when an attacker injects a formula
 * into a CSV file that, when opened in a spreadsheet application,
 * can execute unintended actions.
 *
 * This function validates the input string by checking if the first
 * character is one of the formula-initiating characters (`=`, `-`, `+`, `@`).
 *
 * @param {string} value - The input string to be validated.
 * @returns {boolean} - Returns `true` if the input is vulnerable, otherwise `false`.
 */
export function hasForumulaInjection(value: string) {
  return value != null && "=-+@".indexOf(value.charAt(0)) >= 0;
}

/**
 * Sanitizes the input string to prevent CSV Formula Injection by removing
 * any leading characters that could trigger a formula in spreadsheet applications.
 *
 * This function specifically targets the characters `=`, `+`, `-`, and `@`
 * at the start of the input string, which are commonly used to initiate formulas
 * in CSV files. By removing these characters, the input is made safer for
 * inclusion in CSV files.
 *
 * @param {string} value - The input string to be sanitized.
 * @returns {string} - Returns the sanitized string
 * */
export function sanitizeInput(value: string): string {
  return value.trim().replace(/^[=+\-@]/, "");
}
