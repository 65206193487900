// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import SortIndicator from "../../ratecards/components/SortIndicator";
import { FILTER_COLUMN } from "../../../models/Filter";
import LoadingIndicator from "../../shared/LoadingIndicator";
import PunchoutsListItem from "./PunchoutsListItem";
import { SingleDatePicker } from "../../../components/SingleDatePicker";
import ToggleButton from "../../ratecards/components/ToggleButton";
import CreatedOnFilter from "../../filters/CreatedOnFilter";
import Pagination from "../../../components/lib/Pagination";

class PunchoutsList extends Component {
  constructor(props) {
    super(props);
    this.changeCurrent = this.changeCurrent.bind(this);
  }

  changeCurrent(value) {
    let store: DashboardPunchoutsListStore = this.props.store;
    store.pagination.handleCurrentPage(value);
  }

  render() {
    const store: DashboardPunchoutsListStore = this.props.store;
    const network = store.network;
    const fillContainer = this.props.fillContainer;
    const fixedHeight = this.props.fixedHeight;
    const maximumDate = new Date();

    let listBodyStyles = {};
    if (fixedHeight) {
      listBodyStyles.height = fixedHeight;
      listBodyStyles.overflowY = "auto";
    }

    // let selectAllSection = null;
    let selectAllOnPageSection = (
      <nav className="header-left-nav">
        <div className="btn" onClick={store.selectAllPage}>
          Select All
        </div>
        <div className="btn" onClick={store.deselectAllPage}>
          Deselect All
        </div>
      </nav>
    );

    // if (store.allOnPageSelected) {
    //   selectAllSection = (
    //     <div className="ratecards-list-select-all-section">
    //       <span>
    //         All &nbsp;
    //         <strong>
    //           {store.rateCardCounts}
    //         </strong>
    //         &nbsp; Rate Cards selected. {" "}
    //       </span>
    //       &nbsp;
    //       <span className="pt-link ratecards-all-items" key="clear-selections-link" onClick={store.clearAllSelections}>
    //         Clear all selections
    //       </span>
    //     </div>
    //   );
    // }

    let tableLeftActionBar = null;
    if (store.isEditing) {
      tableLeftActionBar = (
        <nav className="header-left-nav">
          {store.allowMultipleItemSelection && selectAllOnPageSection}
        </nav>
      );
    }

    const punchouts = store.punchoutsView;
    let tableContent = null;
    if (network.loading) {
      tableContent = <LoadingIndicator />;
    } else if (network.error) {
      // TODO: replace this with a more friendly error message
      tableContent = (
        <div className="pt-error-message">
          <h2>Error</h2>
          <pre>
            <code>{JSON.stringify(network.error, null, 2)}</code>
          </pre>
        </div>
      );
    } else if (punchouts.length === 0) {
      tableContent = (
        <div className="ratecards-table-empty">
          {Object.keys(store.appliedFilters).length > 0 ? (
            <div>No Punchout match your current filters</div>
          ) : (
            <div>
              No Punchout
              <p>Create a Punchouts using the button above or by searching.</p>
            </div>
          )}
        </div>
      );
    } else {
      tableContent = punchouts.map((punchout) => (
        <PunchoutsListItem
          key={punchout.buyrateId}
          store={store}
          punchout={punchout}
          editing={store.isEditing}
        />
      ));
    }

    var fromDate = (
      <SingleDatePicker
        id="from_date"
        date={store.createdOnFilter.fromDate}
        numberOfMonths={1}
        isOutsideRange={(day) => day.isAfter(maximumDate)}
        focused={store.createdOnFilter.fromFocused}
        onDateChange={store.createdOnFilter.fromDateChange}
        onFocusChange={store.createdOnFilter.fromFocusedChange}
      />
    );

    var toDate = (
      <SingleDatePicker
        id="to_date"
        date={store.createdOnFilter.toDate}
        numberOfMonths={1}
        isOutsideRange={(day) => store.toDateRange(day)}
        focused={store.createdOnFilter.toFocused}
        onDateChange={store.createdOnFilter.toDateChange}
        onFocusChange={store.createdOnFilter.toFocusedChange}
      />
    );

    return (
      <div className="ratecards-list">
        {store.createdOnFilter && (
          <CreatedOnFilter
            createdOnFilter={store.createdOnFilter}
            fromDate={fromDate}
            toDate={toDate}
          />
        )}

        <header
          className={classNames("ratecards-table-header", {
            active: store.isFiltered,
          })}
        >
          {tableLeftActionBar}
          <nav className="header-right-nav">
            {store.isFiltered && (
              <div className="btn btn-yellow" onClick={store.clearFilters}>
                Clear All Filters & Sorts
              </div>
            )}
            {store.createdOnFilter && (
              <ToggleButton
                borderless={true}
                name="rate-cards-filter"
                value={FILTER_COLUMN.PUNCHOUTS_DATE_RANGE}
                selected={Boolean(
                  store.appliedFilters[FILTER_COLUMN.PUNCHOUTS_DATE_RANGE]
                )}
                onClick={store.createdOnFilter.onShowModal}
              >
                <SortIndicator
                  sortType="numeric"
                  sortColumn={FILTER_COLUMN.PUNCHOUTS_DATE_RANGE}
                  sortsOrder={store.appliedSortsOrder}
                  sort={store.appliedSorts[FILTER_COLUMN.PUNCHOUTS_DATE_RANGE]}
                />
                Created On
                <span> ▼</span>
              </ToggleButton>
            )}
          </nav>
        </header>
        <div
          className={classNames("ratecards-table", {
            "ratecards-table-fill-container": fillContainer,
          })}
        >
          <div style={listBodyStyles}>
            {/*selectAllSection*/}
            {tableContent}
          </div>
        </div>
        {store.pagination.pageCount > 0 ? (
          <Pagination
            options={{
              variant: "full",
              currentPage: store.pagination.currentPage,
              numPages: store.pagination.pageCount,
            }}
            onPageClick={(pageEvent) => {
              if (pageEvent.type === "first") {
                this.changeCurrent(1);
              }
              if (pageEvent.type === "last") {
                this.changeCurrent(store.pagination.pageCount);
              }
              if (pageEvent.type === "next") {
                this.changeCurrent(
                  Math.min(store.pagination.currentPage + 1, store.pagination.pageCount)
                );
              }
              if (pageEvent.type === "prev") {
                this.changeCurrent(Math.max(store.pagination.currentPage - 1, 1));
              }
              if (pageEvent.type === "page") {
                this.changeCurrent(pageEvent.page);
              }
            }}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

PunchoutsList.propTypes = {
  store: PropTypes.object.isRequired,
  fixedHeight: PropTypes.number,
  fillContainer: PropTypes.bool,
};

export default observer(PunchoutsList);
