// @flow
import * as React from "react";
import classNames from "classnames";
import moment from "moment";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../../ratecards/components/Modal";
import ContainerSection from "../../ratecards/components/ContainerSection";
import ClientSelect from "../../../components/selects/ClientSelect";
import MultiCountrySelect from "../../../components/selects/MultiCountrySelect";
import MultiRoleSelect from "../../../components/selects/MultiRoleSelect";
import CheckBox from "../../shared/CheckBox";
import LoadingIndicator from "../../shared/LoadingIndicator";
import type MobXStore from "../../../stores/mobx/MobXStore";
import SessionInfo from "../../../models/SessionInfo";
import CopyRegionsWizardModal from "../../../components/CopyRegionsWizardModal";
import SingleSelect from "../../../components/selects/SingleSelect";

/**
 * Remove duplicates from array of objects.
 * @param {objectsArray} array.
 * @returns {objectsArray} The object array removing duplicates.
 */
function remove_duplicates(objectsArray) {
  var usedObjects = {};

  for (var i = objectsArray.length - 1; i >= 0; i--) {
    var so = JSON.stringify(objectsArray[i]);

    if (usedObjects[so]) {
      objectsArray.splice(i, 1);
    } else {
      usedObjects[so] = true;
    }
  }
  return objectsArray;
}

const UserDetail = observer((props) => {
  const store = props.store;

  let owner,
    userId,
    active,
    activeTxt = null;

  if (store.network.loading) {
    owner = "...";
    userId = "...";
    activeTxt = "...";
  } else if (store.userDetail) {
    //debugger
    owner = "";
    if (store.userDetail.username) {
      owner = store.userDetail.username;
    }

    active = store.userDetail.active;
    userId = store.userDetail.userId;
    activeTxt = active ? "    Active" : "    Inactive";
  }

  var actionBar = (
    <nav className="action-bar">
      <button
        className={classNames("btn", {
          "btn-green": store.welcomeEmailLastSentOn === null,
        })}
        onClick={() => {
          store.releasePasswordModal.showModal();
        }}
        disabled={false}
      >
        <FontAwesomeIcon icon="edit" fixedWidth className="icon" />
        Release Password
      </button>
      <button
        className={classNames("btn", {
          "btn-green": store.welcomeEmailLastSentOn === null,
        })}
        onClick={() => {
          store.releaseUserModal.showModal();
        }}
        disabled={false}
      >
        <FontAwesomeIcon icon="edit" fixedWidth className="icon" />
        Release User
      </button>
      <button
        className="btn"
        onClick={() => {
          store.isPasswordReset = false;
          store.resetPasswordModal.showModal();
        }}
      >
        <FontAwesomeIcon icon="edit" fixedWidth className="icon" />
        Reset Password
      </button>
      {/* <button
        className="btn"
        onClick={() => {
          store.setPasswordModal.showModal();
        }}
      >
        <FontAwesomeIcon icon="edit" fixedWidth className="icon" />
        Set Password
      </button> */}
      <button
        className="btn"
        onClick={() => {
          store.copyUserRegionsModal.showModal();
        }}
      >
        Copy User Regions
      </button>
      <button
        className="btn"
        onClick={() => {
          store.loginAsUserId(userId);
        }}
      >
        Login as User
      </button>
      {!active && !store.network.loading && (
        <button className="btn btn-green" onClick={(e) => store.showActiveModal(true)}>
          <FontAwesomeIcon icon="flag" fixedWidth className="icon" />
          Activate
        </button>
      )}
      {active && !store.network.loading && (
        <button className="btn btn-danger" onClick={(e) => store.showActiveModal(false)}>
          <FontAwesomeIcon icon="flag" fixedWidth className="icon" />
          Deactivate
        </button>
      )}
    </nav>
  );

  return (
    <div className="top">
      <div className="above">
        <div className="ratecard-label-header ellipsis max-width-80">
          {owner !== "" && <h2 className="ellipsis">{owner}</h2>}
          {owner === "" && <h2 className="ellipsis">N/A</h2>}
        </div>
        <nav className="back-bar">
          <button
            onClick={() => {
              window.history.back();
            }}
            className="btn btn-green"
          >
            <FontAwesomeIcon icon="arrow-left" fixedWidth className="icon" />
            Go Back
          </button>
          <button className="btn" onClick={store.showHelp}>
            <FontAwesomeIcon icon="question-circle" fixedWidth className="icon" />
            Help
          </button>
        </nav>
      </div>
      <div className="below">
        <div className="ratecard-meta ellipsis">
          <div className="ratecard-owner ellipsis">
            <FontAwesomeIcon icon="key" fixedWidth className="icon" />
            <span>{userId}</span>
          </div>
          {!active && (
            <div className="ratecard-owner ellipsis">
              <FontAwesomeIcon
                icon="times-circle"
                fixedWidth
                className="alert-red icon"
              />
              <span>{activeTxt}</span>
            </div>
          )}
          {active && (
            <div className="ratecard-owner ellipsis">
              <FontAwesomeIcon
                icon="check-circle"
                fixedWidth
                className="alert-green icon"
              />
              <span>{activeTxt}</span>
            </div>
          )}
        </div>
        {actionBar}
      </div>
      <Modal show={store.showHelpModal} onHide={store.hideHelp}>
        <div className="container-section header">
          <h4>Help</h4>
        </div>
        <div className="container-section footer">
          <p>Here you can see all the details of the User</p>
        </div>
      </Modal>
      <Modal
        show={store.resetPasswordModal.show}
        onHide={store.resetPasswordModal.hideModal}
      >
        <ContainerSection className="header">
          <h4>Reset Password</h4>
        </ContainerSection>
        {!store.isPasswordReset && (
          <ContainerSection>
            <p>Do you want to reset the password? </p>
            <p className="text-danger">
              <em>An email will be sent to your registered email address.</em>
            </p>
          </ContainerSection>
        )}
        {store.isPasswordReset && (
          <ContainerSection>
            <p>An Email has been sent to the user email address. </p>
          </ContainerSection>
        )}
        <ContainerSection className="footer">
          <div className="pull-right">
            {!store.isPasswordReset && (
              <button
                className="btn btn-lg btn-default"
                onClick={store.resetPasswordModal.hideModal}
              >
                Cancel
              </button>
            )}
            {!store.isPasswordReset && (
              <button className="btn btn-lg btn-green" onClick={store.resetUserPassword}>
                Yes, Reset
              </button>
            )}
            {store.isPasswordReset && (
              <button
                className="btn btn-lg btn-green"
                onClick={() => {
                  store.isPasswordReset = false;
                  store.resetPasswordModal.hideModal();
                }}
              >
                Done
              </button>
            )}
          </div>
        </ContainerSection>
      </Modal>
      <Modal
        show={store.releasePasswordModal.show}
        onHide={store.releasePasswordModal.hideModal}
      >
        <ContainerSection className="header">
          <h4>Release User's Password</h4>
        </ContainerSection>
        {!store.isPasswordReleased && (
          <ContainerSection>
            <p>Do you want to release the user's password? </p>
            <p>A Welcome Email will be sent to the user's registered email address.</p>
            {store.welcomeEmailLastSentOn !== null && (
              <p className="text-danger">
                <em>
                  A Welcome Email was last sent on{" "}
                  {moment(store.welcomeEmailLastSentOn).format("MMMM D, YYYY")}
                </em>
              </p>
            )}
          </ContainerSection>
        )}
        {store.isPasswordReleased && (
          <ContainerSection>
            <p>A Welcome Email has been sent to the user's email address. </p>
          </ContainerSection>
        )}
        <ContainerSection className="footer">
          <div className="pull-right">
            {!store.isPasswordReleased && (
              <button
                className="btn btn-lg btn-default"
                onClick={store.releasePasswordModal.hideModal}
              >
                Cancel
              </button>
            )}
            {!store.isPasswordReleased && (
              <button
                className="btn btn-lg btn-green"
                onClick={store.releaseUserPassword}
              >
                Yes, Release
              </button>
            )}
            {store.isPasswordReleased && (
              <button
                className="btn btn-lg btn-green"
                onClick={() => {
                  store.isPasswordReleased = false;
                  store.releasePasswordModal.hideModal();
                }}
              >
                Done
              </button>
            )}
          </div>
        </ContainerSection>
      </Modal>

      <Modal show={store.releaseUserModal.show} onHide={store.releaseUserModal.hideModal}>
        <ContainerSection className="header">
          <h4>Release User</h4>
        </ContainerSection>
        {!store.isUserReleased && (
          <ContainerSection>
            <p>Do you want to release the user? </p>
            <p>A Welcome Email will be sent to the user's registered email address.</p>
            {store.welcomeEmailLastSentOn !== null && (
              <p className="text-danger">
                <em>
                  A Welcome Email was last sent on{" "}
                  {moment(store.welcomeEmailLastSentOn).format("MMMM D, YYYY")}
                </em>
              </p>
            )}
          </ContainerSection>
        )}
        {store.isUserReleased && (
          <ContainerSection>
            <p>A Welcome Email has been sent to the user's email address. </p>
          </ContainerSection>
        )}
        <ContainerSection className="footer">
          <div className="pull-right">
            {!store.isUserReleased && (
              <button
                className="btn btn-lg btn-default"
                onClick={store.releaseUserModal.hideModal}
              >
                Cancel
              </button>
            )}
            {!store.isUserReleased && (
              <button className="btn btn-lg btn-green" onClick={store.releaseUser}>
                Yes, Release
              </button>
            )}
            {store.isUserReleased && (
              <button
                className="btn btn-lg btn-green"
                onClick={() => {
                  store.isUserReleased = false;
                  store.releaseUserModal.hideModal();
                }}
              >
                Done
              </button>
            )}
          </div>
        </ContainerSection>
      </Modal>

      <Modal show={store.setPasswordModal.show} onHide={store.setPasswordModal.hideModal}>
        <ContainerSection className="header">
          <h4>Set User's Password</h4>
        </ContainerSection>
        {!store.isPasswordSet && (
          <ContainerSection>
            {/* TODO: Add controlled text box with what the password should be */}
          </ContainerSection>
        )}
        {store.isPasswordSet && (
          <ContainerSection>
            <p>An Email has been sent to the user email address. </p>
          </ContainerSection>
        )}
        <ContainerSection className="footer">
          <div className="pull-right">
            {!store.isPasswordSet && (
              <button
                className="btn btn-lg btn-default"
                onClick={store.setPasswordModal.hideModal}
              >
                Cancel
              </button>
            )}
            {!store.isPasswordSet && (
              <button className="btn btn-lg btn-green" onClick={store.setUserPassword}>
                Yes, Reset
              </button>
            )}
            {store.isPasswordSet && (
              <button
                className="btn btn-lg btn-green"
                onClick={() => {
                  store.isPasswordSet = false;
                  store.setPasswordModal.hideModal();
                }}
              >
                Done
              </button>
            )}
          </div>
        </ContainerSection>
      </Modal>
      <Modal
        show={store.confirmActivateDeactivateModal.show}
        onHide={store.confirmActivateDeactivateModal.hideModal}
      >
        <div className="container-section header">
          <h4>Confirmation Needed</h4>
        </div>
        <div className="container-section">
          <p>
            Are you sure you want to{" "}
            {store.activeDeactiveBtnFlag ? "Activate" : "Deactivate"} this User?
          </p>
          {/*<p className="text-danger">
              <FontAwesomeIcon icon="exclamation-triangle"/> {" "}
              <em>This action cannot be undone!</em>
            </p>*/}
        </div>
        <div className="container-section footer">
          <div className="pull-right">
            <button
              className="btn btn-lg"
              onClick={store.confirmActivateDeactivateModal.hideModal}
            >
              No, Cancel
            </button>
            <button
              className="btn btn-lg btn-danger"
              onClick={(e) => {
                store.confirmActivateDeactivateModal.hideModal();
                store.ActivateDeactivateUser(store.activeDeactiveBtnFlag);
              }}
            >
              Yes, {store.activeDeactiveBtnFlag ? "Activate" : "Deactivate"}
            </button>
          </div>
        </div>
      </Modal>
      <CopyRegionsWizardModal
        toUserId={parseInt(userId || "0")}
        isOpen={store.copyUserRegionsModal.show}
        onClose={store.copyUserRegionsModal.hideModal}
      />
    </div>
  );
});

type Props = {
  store: MobXStore,
  sessionInfo: ?SessionInfo,
  router: Object,
};

class Detail extends React.Component<Props> {
  UNSAFE_componentWillMount() {
    // required for login as user functionality
    this.props.store.dashboardStore.sessionInfo = this.props.sessionInfo;
    this.props.store.dashboardStore.router = this.props.router;

    const store = this.props.store.UserDetailPTStore;
    store.sessionInfo = this.props.sessionInfo;
    store.clientNameMessage.removeAll();
    store.firstNameMessage.removeAll();
    store.lastNameMessage.removeAll();
    store.primaryEmailMessage.removeAll();
    store.secondaryEmailMessage.removeAll();
    store.multiCountryMessage.removeAll();
    store.rolesMessage.removeAll();
    store.userId = this.props.router.params.userId;
    store.router = this.props.router;
    store.getCountryOptions().then(() => store.getUserDetails());
  }

  componentWillUnmount() {
    this.props.store.UserDetailPTStore.isEditing = false;
  }

  render() {
    let store = this.props.store.UserDetailPTStore;

    var selectedLocations = [];
    var selectedRoles = [];
    var selectedClient = [];
    var locationsUnique = remove_duplicates(store.selectedLocations);
    locationsUnique.map((e) => {
      if (e) {
        var label = "";
        if (e.title) {
          label = e.title;
        }
        var object = {
          value: e.locationId,
          label: label,
        };
        selectedLocations.push(object);
      }
      return true;
    });

    var rolesUnique = remove_duplicates(store.selectedRoles);
    rolesUnique.map((e) => {
      if (e) {
        var label = "";
        if (e.title) {
          label = e.title;
        }
        var object = {
          value: e.roleId,
          label: label,
        };
        selectedRoles.push(object);
      }
      return true;
    });

    var clientUnique = remove_duplicates(store.selectedClient);
    clientUnique.map((e) => {
      if (e) {
        var label = "";
        if (e.title) {
          label = e.title;
        }
        var object = {
          value: e.clientId,
          label: label,
        };
        selectedClient.push(object);
      }
      return true;
    });

    var isSelected = store.selected;

    return (
      <div className="view">
        <div className="rc-container show-overflow">
          <UserDetail store={store} router={this.props.router} />
        </div>
        {store.network.loading && <LoadingIndicator />}
        {!store.network.loading && (
          <div className="rc-container bring-forward">
            <div className="header-action-bar">
              <h4>User Detail</h4>
              <div className="btn-group pull-right">
                {!store.isEditing && (
                  <button
                    className="btn btn-green"
                    onClick={(e) => {
                      store.startEditing();
                    }}
                  >
                    <span>
                      <FontAwesomeIcon icon="edit" fixedWidth className="icon" />
                      Edit
                    </span>
                  </button>
                )}
                {store.isEditing && (
                  <button
                    className="btn btn-yellow pull-right"
                    onClick={store.stopEditing}
                  >
                    Stop Editing{" "}
                  </button>
                )}
              </div>
              <div className="clearfix" />
            </div>
            <div className="row userjob">
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <label>Client </label>
                  <div className="input_group">
                    {!store.isEditing && selectedClient.length > 0 && (
                      <input
                        type="text"
                        disabled={true}
                        className="form-control"
                        value={selectedClient[0].label}
                      />
                    )}
                    {store.isEditing && selectedClient.length > 0 && (
                      <ClientSelect
                        Clients={(param) => store.ClientText(param)}
                        onChange={(value) => store.changeClientText([value])}
                        value={selectedClient}
                        disabled={false}
                      />
                    )}
                    {store.clientNameMessage.messages.length > 0 && (
                      <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                        <a
                          href="#close"
                          className="close"
                          data-dismiss="alert"
                          aria-label="close"
                          onClick={store.clientNameMessage.removeMessage.bind(
                            store,
                            store.clientNameMessage.messages[0]
                          )}
                        >
                          &times;
                        </a>
                        <strong>
                          {store.clientNameMessage.messages[0].messageLabel}
                        </strong>
                        {store.clientNameMessage.messages[0].message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <label>Username </label>
                  <div className="input_group">
                    {store.userDetail && (
                      <input
                        type="text"
                        disabled={!store.isEditing}
                        className="form-control"
                        value={store.username}
                        maxLength="64"
                        onChange={store.changeUsernameText}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row userjob">
              <div className="col-sm-4 col-md-4 col-lg-4">
                <div className="form-group">
                  <label>First Name</label>
                  <div className="input_group">
                    <input
                      type="text"
                      min="0"
                      disabled={!store.isEditing}
                      className="form-control"
                      placeholder="First Name"
                      value={store.firstName}
                      maxLength="30"
                      onChange={store.changeFirstNameText}
                    />
                    {store.firstNameMessage.messages.length > 0 && (
                      <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                        <a
                          href="#close"
                          className="close"
                          data-dismiss="alert"
                          aria-label="close"
                          onClick={store.firstNameMessage.removeMessage.bind(
                            store,
                            store.firstNameMessage.messages[0]
                          )}
                        >
                          &times;
                        </a>
                        <strong>{store.firstNameMessage.messages[0].messageLabel}</strong>
                        {store.firstNameMessage.messages[0].message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4">
                <div className="form-group">
                  <label>Middle Name</label>
                  <div className="input_group">
                    <input
                      type="text"
                      min="0"
                      disabled={!store.isEditing}
                      placeholder="Middle Name"
                      className="form-control"
                      value={store.middleName}
                      maxLength="30"
                      onChange={store.changeMiddleNameText}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4">
                <div className="form-group">
                  <label>Last Name</label>
                  <div className="input_group">
                    <input
                      type="text"
                      min="0"
                      disabled={!store.isEditing}
                      className="form-control"
                      placeholder="Last Name"
                      value={store.lastName}
                      maxLength="30"
                      onChange={store.changeLastNameText}
                    />
                    {store.lastNameMessage.messages.length > 0 && (
                      <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                        <a
                          href="#close"
                          className="close"
                          data-dismiss="alert"
                          aria-label="close"
                          onClick={store.lastNameMessage.removeMessage.bind(
                            store,
                            store.lastNameMessage.messages[0]
                          )}
                        >
                          &times;
                        </a>
                        <strong>{store.lastNameMessage.messages[0].messageLabel}</strong>
                        {store.lastNameMessage.messages[0].message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row userjob">
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <label>Primary Email</label>
                  <div className="input_group">
                    <input
                      type="text"
                      min="0"
                      disabled={!store.isEditing}
                      className="form-control"
                      value={store.primaryEmail}
                      onChange={store.changePrimaryEmail}
                    />
                    {store.primaryEmailMessage.messages.length > 0 && (
                      <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                        <a
                          href="#close"
                          className="close"
                          data-dismiss="alert"
                          aria-label="close"
                          onClick={store.primaryEmailMessage.removeMessage.bind(
                            store,
                            store.primaryEmailMessage.messages[0]
                          )}
                        >
                          &times;
                        </a>
                        <strong>
                          {store.primaryEmailMessage.messages[0].messageLabel}
                        </strong>
                        {store.primaryEmailMessage.messages[0].message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <label>Alternate Email</label>
                  <div className="input_group">
                    <input
                      className="form-control"
                      placeholder="Enter a second Email address(optional)"
                      type="text"
                      value={store.secondaryEmail}
                      maxLength="250"
                      onChange={store.changeSecondaryEmail}
                      disabled={!store.isEditing}
                    />
                    {store.secondaryEmailMessage.messages.length > 0 && (
                      <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                        <a
                          href="#close"
                          className="close"
                          data-dismiss="alert"
                          aria-label="close"
                          onClick={store.secondaryEmailMessage.removeMessage.bind(
                            store,
                            store.primaryEmailMessage.messages[0]
                          )}
                        >
                          &times;
                        </a>
                        <strong>
                          {store.secondaryEmailMessage.messages[0].messageLabel}
                        </strong>
                        {store.secondaryEmailMessage.messages[0].message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row userjob">
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <label>Primary Phone Number</label>
                  <div className="input-group">
                    <div className="input-group-addon">+</div>
                    <input
                      style={{ zIndex: 0 }}
                      className="form-control"
                      placeholder="Primary Phone Number"
                      type="text"
                      value={store.primaryPhone}
                      maxLength="250"
                      onChange={store.changePrimaryPhone}
                      disabled={!store.isEditing}
                    />
                  </div>
                  {store.primaryPhoneMessage.messages.length > 0 && (
                    <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                      <a
                        href="#close"
                        className="close"
                        data-dismiss="alert"
                        aria-label="close"
                        onClick={store.primaryPhoneMessage.removeMessage.bind(
                          store,
                          store.primaryEmailMessage.messages[0]
                        )}
                      >
                        &times;
                      </a>
                      <strong>
                        {store.primaryPhoneMessage.messages[0].messageLabel}
                      </strong>
                      {store.primaryPhoneMessage.messages[0].message}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <label>Secondary Phone Number</label>
                  <div className="input-group">
                    <div className="input-group-addon">+</div>
                    <input
                      style={{ zIndex: 0 }}
                      className="form-control"
                      placeholder="Secondary Phone Number"
                      type="text"
                      value={store.secondaryPhone}
                      maxLength="250"
                      onChange={store.changeSecondaryPhone}
                      disabled={!store.isEditing}
                    />
                  </div>
                  {store.secondaryPhoneMessage.messages.length > 0 && (
                    <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                      <a
                        href="#close"
                        className="close"
                        data-dismiss="alert"
                        aria-label="close"
                        onClick={store.secondaryPhoneMessage.removeMessage.bind(
                          store,
                          store.secondaryEmailMessage.messages[0]
                        )}
                      >
                        &times;
                      </a>
                      <strong>
                        {store.secondaryPhoneMessage.messages[0].messageLabel}
                      </strong>
                      {store.secondaryPhoneMessage.messages[0].message}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row userjob">
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <label>Main Address</label>
                  <div className="input_group">
                    <input
                      className="form-control"
                      placeholder="Main Address"
                      type="text"
                      value={store.mainAddress}
                      maxLength="250"
                      onChange={store.changeMainAddress}
                      disabled={!store.isEditing}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <label>Additional Address</label>
                  <div className="input_group">
                    <input
                      className="form-control"
                      placeholder="Additional Address"
                      type="text"
                      value={store.additionalAddress}
                      maxLength="250"
                      onChange={store.changeAdditionalAddress}
                      disabled={!store.isEditing}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row userjob">
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <label>City</label>
                  <div className="input_group">
                    <input
                      className="form-control"
                      placeholder="Enter City"
                      type="text"
                      value={store.city}
                      maxLength="250"
                      onChange={store.changeCity}
                      disabled={!store.isEditing}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <label>State</label>
                  <div className="input_group">
                    <input
                      className="form-control"
                      placeholder="Enter State"
                      type="text"
                      value={store.state}
                      maxLength="250"
                      onChange={store.changeState}
                      disabled={!store.isEditing}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row userjob">
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <label>Country</label>
                  <div className="input_group">
                    <SingleSelect
                      options={store.countryOptions}
                      text="Country"
                      name="country"
                      onChange={store.changeCountry}
                      value={store.selectedCountryOption}
                      disabled={!store.isEditing}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <label>Postal Code</label>
                  <div className="input_group">
                    <input
                      className="form-control"
                      placeholder="Enter Postal Code"
                      type="text"
                      value={store.postalCode}
                      maxLength="250"
                      onChange={store.changePostalCode}
                      disabled={!store.isEditing}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label>Share Rate Cards</label>
                  </div>
                </div>
                <div className="col-sm-2 col-md-2 col-lg-2">
                  <div className="form-group">
                    <CheckBox
                      type="radio"
                      name="list-item-select"
                      value="true"
                      selected={store.shareRateCards}
                      onClick={store.setShareRatecardsFlag}
                      labelName={"Yes"}
                      disabled={!store.isEditing}
                    />
                  </div>
                </div>
                <div className="col-sm-2 col-md-2 col-lg-2">
                  <div className="form-group">
                    <CheckBox
                      type="radio"
                      name="list-item-select"
                      value="false"
                      selected={!store.shareRateCards}
                      onClick={store.setShareRatecardsFlag}
                      labelName={"No"}
                      disabled={!store.isEditing}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="form-group cross-fix height-auto">
                  <label>Allowed Countries</label>
                  <MultiCountrySelect
                    CountryTitle={store.countryTitle}
                    onChange={store.changeCountryText}
                    value={selectedLocations}
                    disabled={!store.isEditing}
                  />
                  {store.multiCountryMessage.messages.length > 0 && (
                    <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                      <a
                        href="#close"
                        className="close"
                        data-dismiss="alert"
                        aria-label="close"
                        onClick={store.multiCountryMessage.removeMessage.bind(
                          store,
                          store.multiCountryMessage.messages[0]
                        )}
                      >
                        &times;
                      </a>
                      <strong>
                        {store.multiCountryMessage.messages[0].messageLabel}
                      </strong>
                      {store.multiCountryMessage.messages[0].message}
                    </div>
                  )}
                  <div className="width-110">
                    <div className="form-group">
                      <CheckBox
                        type={"checkbox"}
                        name="list-item-select"
                        value={"all"}
                        selected={isSelected}
                        onClick={store.selectAllCountries}
                        labelName={"Select All"}
                        disabled={!store.isEditing}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="form-group cross-fix height-auto">
                  <label>Roles</label>
                  <MultiRoleSelect
                    RoleTitle={(param) => store.getAllRoles(param)}
                    onChange={(value) => store.changeRoleText(value)}
                    value={selectedRoles}
                    disabled={!store.isEditing}
                  />
                  {store.rolesMessage.messages.length > 0 && (
                    <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                      <a
                        href="#close"
                        className="close"
                        data-dismiss="alert"
                        aria-label="close"
                        onClick={store.rolesMessage.removeMessage.bind(
                          store,
                          store.rolesMessage.messages[0]
                        )}
                      >
                        &times;
                      </a>
                      <strong>{store.rolesMessage.messages[0].messageLabel}</strong>
                      {store.rolesMessage.messages[0].message}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {store.isEditing && (
              <ContainerSection className="footer">
                <div className="pull-right">
                  <button
                    className="btn btn-lg btn-default"
                    onClick={(e) => {
                      store.stopEditing();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-lg btn-green"
                    onClick={(e) => {
                      store.saveEditedUserDetail();
                    }}
                  >
                    Update User Detail
                  </button>
                </div>
              </ContainerSection>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default observer(Detail);
