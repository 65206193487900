// @flow

import React, { Component } from "react";
import { Alert } from "react-bootstrap";
import { observer } from "mobx-react";
import type MobXStore from "../../../stores/mobx/MobXStore";
import { BatchSearchPSPDetailStore } from "../../../stores/mobx/BatchSearchDetailStore";
import BatchSearch, { BATCH_SEARCH_JL_STATUS } from "../../../models/BatchSearch";
import classNames from "classnames";
import LoadingIndicator from "../../shared/LoadingIndicator";
import { InlineElements } from "../../../components/lib/Inline";
import Button from "../../../components/lib/Button";
import Container from "../../../components/lib/Container";
import { Card, CardHeader, CardHeaderTitle } from "../../../components/lib/Card";
import Spacer from "../../../components/lib/Spacer";
import Box from "../../../components/lib/Box";

type Props = {
  store: MobXStore,
  router: {
    push: (any) => void,
    goBack: (any) => void,
    params: {
      batchSearchUUID: string,
    },
  },
};

class Detail extends Component<Props> {
  timeout: any;
  doRefreshStatus: () => void;
  handleRerun: () => void;
  handleGoBack: () => void;
  mounted: boolean;

  constructor(props: Props) {
    super(props);

    this.mounted = false;
    this.doRefreshStatus = this.doRefreshStatus.bind(this);
    this.handleRerun = this.handleRerun.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.timeout = null;
  }

  componentDidMount() {
    this.mounted = true;
    const store = this.props.store.batchSearchPSPDetailStore;
    store.router = this.props.router;
    store.batchSearchUUID = this.props.router.params.batchSearchUUID;

    setTimeout(() => {
      store.getBatchSearch().then(this.doRefreshStatus, this.doRefreshStatus);
    }, 1);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handleRerun() {
    const detailStore = this.props.store.batchSearchPSPDetailStore;
    const createStore = this.props.store.batchSearchPSPCreateStore;

    const batchSearch = detailStore.batchSearch;
    if (batchSearch && batchSearch.uuid) {
      this.props.router.push(`/batch-searches/create`);
      createStore.rerunBatchSearch(batchSearch.uuid);
    }
  }

  handleGoBack() {
    this.props.router.push(`/batch-searches`);
  }

  goToRateCard = (rateCardId: ?number) => {
    this.props.router.push(`/ratecards/${rateCardId || ""}`);
  };

  doRefreshStatus() {
    if (this.timeout) clearTimeout(this.timeout);
    if (!this.mounted) return;

    const store: BatchSearchPSPDetailStore = this.props.store.batchSearchPSPDetailStore;
    const progress: ?BatchSearch = store.batchSearch;

    // Stop refreshing once we're past the
    if (
      progress &&
      typeof progress.status === "number" &&
      progress.status > BATCH_SEARCH_JL_STATUS.RUNNING
    ) {
      return;
    }

    this.timeout = setTimeout(() => {
      store.getBatchSearch().then(this.doRefreshStatus, this.doRefreshStatus);
    }, 1500);
  }

  _renderProgressBar() {
    const store: BatchSearchPSPDetailStore = this.props.store.batchSearchPSPDetailStore;
    const progress: ?BatchSearch = store.batchSearch;
    if (!progress) return;

    const valueMax = progress.searchesTotal || 0;
    const runningValue = progress.searchesRunning || 0;
    const finishedValue = progress.searchesFinished + progress.searchesFailed;

    let classes = classNames("progress-bar", {
      active: progress.status === BATCH_SEARCH_JL_STATUS.RUNNING,
      "progress-bar-striped": progress.status === BATCH_SEARCH_JL_STATUS.RUNNING,
    });

    let finishedProgressBar, runningProgressBar;

    if (runningValue) {
      runningProgressBar = (
        <div
          className={classNames(classes, "progress-bar-warning")}
          role="progressbar"
          aria-valuenow={runningValue}
          aria-valuemin={0}
          aria-valuemax={valueMax}
          style={{ width: (runningValue / valueMax) * 100.0 + "%" }}
        />
      );
    }

    if (finishedValue) {
      finishedProgressBar = (
        <div
          className={classNames(classes, "progress-bar-info")}
          role="progressbar"
          aria-valuenow={finishedValue}
          aria-valuemin={0}
          aria-valuemax={valueMax}
          style={{ width: (finishedValue / valueMax) * 100.0 + "%" }}
        />
      );
    }
    return (
      <div className="progress" style={{ marginBottom: 10 }}>
        {finishedProgressBar}
        {runningProgressBar}
      </div>
    );
  }

  _renderPanelBodyRunning() {
    const store: BatchSearchPSPDetailStore = this.props.store.batchSearchPSPDetailStore;
    const progress: ?BatchSearch = store.batchSearch;

    if (!progress) return;

    let renderText = (num: number) => (
      <span>
        <b>{num}</b> {num === 1 ? "has" : "have"}
      </span>
    );
    let renderTextPresent = (num: number) => (
      <span>
        <b>{num}</b> {num === 1 ? "is" : "are"}
      </span>
    );

    return (
      <div>
        <h4 style={{ marginBottom: 6 }}>
          We will run <b>{progress.searchesTotal || 0}</b> searches. Of these searches{" "}
          {renderText(progress.searchesFinished || 0)} finished,{" "}
          {renderText(progress.searchesFailed || 0)} failed and{" "}
          {renderTextPresent(progress.searchesRunning || 0)} running.
        </h4>
        {this._renderProgressBar()}
        <Alert bsStyle="info">
          <h4>You can leave this page at any time. </h4>
          <p style={{ fontSize: 18 }}>
            You will receive a notification when all searches have been completed.
            Alternatively, you can remain here to monitor the progress of your searches in
            real time.
          </p>
        </Alert>
      </div>
    );
  }

  _renderPanelBodyQueued() {
    return (
      <Alert bsStyle="info">
        <h4 style={{ marginBottom: 6 }}>
          Your batch search is currently waiting in queue to be run.
        </h4>
        <p style={{ fontSize: 18 }}>
          You can leave this page at any time. You will receive a notification when all
          searches have been completed.
          <br />
          We'll begin processing your batch search shortly. This page will update in real
          time with progress as your batch search is being ran.
        </p>
      </Alert>
    );
  }

  _renderPanelBodyFinished() {
    const store: BatchSearchPSPDetailStore = this.props.store.batchSearchPSPDetailStore;
    const progress: ?BatchSearch = store.batchSearch;

    if (!progress) return;

    return (
      <Alert bsStyle="success">
        <h4 style={{ paddingBottom: 20 }}>
          Your rate search results are now available and can be found under&nbsp;
          <em>Rate Cards</em>: <strong>{progress.rateCardLabel}</strong> Rate Card.
        </h4>
        <InlineElements>
          <Button
            color="brand"
            icon="list-alt"
            onClick={() => this.goToRateCard(progress.rateCardId)}
          >
            View Rate Card
          </Button>
          <Button icon="play-circle" onClick={this.handleRerun}>
            Run Again
          </Button>
        </InlineElements>
      </Alert>
    );
  }

  _renderPanelBodyFinishedWithFailures() {
    const store: BatchSearchPSPDetailStore = this.props.store.batchSearchPSPDetailStore;
    const progress: ?BatchSearch = store.batchSearch;

    if (!progress) return;

    let renderText = (num) => (
      <span>
        <b>{num}</b> {num === 1 ? "search" : "searches"}
      </span>
    );

    return (
      <Alert bsStyle="warning">
        <h4 style={{ marginBottom: 6 }}>Not all searches completed successfully.</h4>
        <p style={{ fontSize: 18 }}>
          Of the {renderText(progress.searchesTotal)} that were searched,{" "}
          {renderText(progress.searchesFailed)} failed. However, the results of the
          searches that completed successfully are now available in the{" "}
          <strong>{progress.rateCardLabel}</strong> Rate Card.
        </p>
        <InlineElements>
          <Button
            color="brand"
            icon="list-alt"
            onClick={() => this.goToRateCard(progress.rateCardId)}
          >
            View Rate Card
          </Button>
          <Button icon="fa-play-circle" onClick={this.handleRerun}>
            Run Again
          </Button>
        </InlineElements>
      </Alert>
    );
  }

  _renderPanelBodyFailed() {
    return (
      <Alert bsStyle="danger">
        <h4 style={{ marginBottom: 6 }}>
          We couldn't complete any of the searches that you submitted!
        </h4>
        <p style={{ fontSize: 18 }}>
          We were unable to determine the rates for any of the searches that you have
          submitted. Please review your job title and job description selections.
        </p>
      </Alert>
    );
  }

  _renderPanelBody() {
    const store: BatchSearchPSPDetailStore = this.props.store.batchSearchPSPDetailStore;
    const progress: ?BatchSearch = store.batchSearch;

    if (!progress) return;

    switch (progress.status) {
      case BATCH_SEARCH_JL_STATUS.QUEUED:
        return this._renderPanelBodyQueued();
      case BATCH_SEARCH_JL_STATUS.CREATED:
        return this._renderPanelBodyRunning();
      case BATCH_SEARCH_JL_STATUS.CREATING_RATE_CARD:
        return this._renderPanelBodyRunning();
      case BATCH_SEARCH_JL_STATUS.RUNNING:
        return this._renderPanelBodyRunning();

      case BATCH_SEARCH_JL_STATUS.FINISHED:
        return this._renderPanelBodyFinished();

      case BATCH_SEARCH_JL_STATUS.FINISHED_WITH_FAILURES:
        return this._renderPanelBodyFinishedWithFailures();

      case BATCH_SEARCH_JL_STATUS.FAILED:
        return this._renderPanelBodyFailed();
      default:
        return this._renderPanelBodyQueued();
    }
  }

  render() {
    const store: BatchSearchPSPDetailStore = this.props.store.batchSearchPSPDetailStore;
    const progress: ?BatchSearch = store.batchSearch;

    if (!this.mounted) return null; // it renders before mounting

    if (!progress && store.network.loading) return <LoadingIndicator />;

    if (!progress) {
      console.error("Couldn't render view. Store has no Scheduled Search");
      return <code>Couldn't render view. Contact support.</code>;
    }

    const status = progress.status || 0;

    return (
      <Container
        css={{
          paddingTop: "$4",
          paddingLeft: 0,
          paddingRight: 0,
          width: "100%",
        }}
      >
        <Card>
          <CardHeader>
            <InlineElements>
              <CardHeaderTitle>
                Your batch search {status > BATCH_SEARCH_JL_STATUS.RUNNING ? "has" : "is"}{" "}
                {progress.statusDisplay ? progress.statusDisplay.toLowerCase() : ".."}.
              </CardHeaderTitle>
              <Spacer />
              <Button icon="arrow-left" onClick={this.handleGoBack}>
                Back to Batch Search list
              </Button>
            </InlineElements>
          </CardHeader>

          <Box css={{ padding: "$4" }}>{this._renderPanelBody()}</Box>
        </Card>
      </Container>
    );
  }
}

export default observer(Detail);
