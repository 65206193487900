import React, { useCallback } from "react";

import { List, fromJS } from "immutable";

import Icon from "../../../../components/lib/Icon";
import SelectItemRow from "../../lib/SelectItemRow";
import RestfulDatasetListView, {
  DataSourceFunc,
  RenderItemFunc,
} from "./BaseRestfulDatasetListView";
import Tooltip from "../../../../components/lib/Tooltip";
import { emptyList } from "../../../../constants";
// @ts-expect-error
import { logAsyncOperationError, logGraphQLError } from "../../../../utils/logging";

import type { ImmutableMap, ImmutableList } from "../../../../types/immutable";
import type { FetchGraphQLAPIResponse } from "../../../../types/fetch";
import { useVal5KAdminContext } from "../../context/Val5KAdminContext";

type RateCardNodeObject = {
  id: number;
  title: string;
  userId: number;
  userName: string;
  clientId: number;
  clientName: string;
};

export type RateCardNodeMap = ImmutableMap<RateCardNodeObject>;
export type RateCardNodeList = ImmutableList<RateCardNodeMap>;

type EdgesGraphQL = {
  node: {
    id: number;
    name: string;
    owner: {
      userId: number;
      userName: string;
      client: {
        clientId: number;
        clientName: string;
      };
    };
    searchCount: number;
  };
};

type ResponseGraphQL = {
  viewer: {
    allRateCards: {
      totalCount: number;
      pageInfo: {
        hasNextPage: boolean;
      };
      edges: EdgesGraphQL[];
    };
  };
};

const rateCardNodeToImmutableMap = (item: EdgesGraphQL) => {
  return fromJS({
    id: item.node.id,
    title: item.node.name,
    userId: item.node.owner.userId,
    userName: item.node.owner.userName,
    clientId: item.node.owner.client.clientId,
    clientName: item.node.owner.client.clientName,
  });
};

const query = `
      query getRateCardsList($search: String, $first: Int, $offset: Int) {
        viewer {
          allRateCards(search: $search, first: $first, offset: $offset, section: ADMIN, order: {field: CREATE_DATE, direction: DESC}) {
            totalCount
            pageInfo {
              hasNextPage
            }
            edges {
              node {
                id: ratecardId
                name
                owner {
                  userId
                  userName: username
                  client {
                    clientId
                    clientName: name
                  }
                }
                searchCount
              }
            }
          }
        }
      }
`;

type RateCardSelectProps = {
  values: RateCardNodeList;
  disabled?: boolean;
  onSelect: (item: RateCardNodeMap) => void;
};

const RateCardSelect = (props: RateCardSelectProps) => {
  const { values, disabled = false, onSelect } = props;

  const { fetchGraphQL, showModalError } = useVal5KAdminContext();

  const dataSource: DataSourceFunc<RateCardNodeList> = useCallback(
    async (search, page, pageSize) => {
      const variables = {
        search,
        first: pageSize,
        offset: Math.max(0, page),
      };

      try {
        const response: FetchGraphQLAPIResponse<ResponseGraphQL> = await fetchGraphQL(
          query,
          variables
        );

        if (logGraphQLError("fetchRateCardSelect", response.data)) {
          return { itemsCount: 0, data: emptyList as unknown as RateCardNodeList };
        }

        const allRateCards = response.data.data.viewer.allRateCards || {};
        const data = allRateCards.edges || [];

        return {
          itemsCount: allRateCards.totalCount,
          data: List(data.map(rateCardNodeToImmutableMap)) as unknown as RateCardNodeList,
        };
      } catch (err: any) {
        logAsyncOperationError("RateCardSelect", err);
        showModalError("Error fetching rate cards list");
      }
    },
    [showModalError, fetchGraphQL]
  );

  const renderItem: RenderItemFunc<RateCardNodeMap> = useCallback(
    (item) => {
      const itemId = item.get("id");
      const valueIds = values?.size ? values.map((i) => i.get("id")) : emptyList;
      const isSelected = !!(itemId != null && valueIds.size && valueIds.includes(itemId));

      const title = item.get("title");
      const clientName = item.get("clientName");
      const userName = item.get("userName");
      const value = `#${itemId} - ${title} (client: ${clientName}, user: ${userName})`;

      const component = (
        <SelectItemRow
          selected={isSelected}
          disabled={disabled}
          onClick={() => !disabled && onSelect?.(item)}
        >
          <Icon icon={["far", "file-alt"]} />
          {value}
        </SelectItemRow>
      );

      return disabled ? component : <Tooltip content={value}>{component}</Tooltip>;
    },
    [disabled, onSelect, values]
  );

  return (
    <RestfulDatasetListView
      disabled={disabled}
      dataSource={dataSource}
      renderItem={renderItem}
      searchPlaceholder="search rate cards by title"
    />
  );
};

RateCardSelect.displayName = "RateCardSelect";

export default RateCardSelect;
