// @flow

import React from "react";
import { observer } from "mobx-react";
import numeral from "numeral";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { LEVEL_ROMAN_NUMERAL } from "../../../constants/levels";
import PunchOutRowState from "../../../models/PunchOutRowState";
import { styled } from "../../../stitches.config";
import { buttonBaseStyles } from "../../../components/lib/Button";
import HTMLSelect from "../../../components/lib/HTMLSelect";
import TextInput from "../../../components/lib/TextInput";

export const PunchoutButtonGroup = styled("div", {
  background: "none",
  border: "none",
  margin: "-10px 0",
});

export const PunchoutButton = styled("button", buttonBaseStyles, {
  display: "inline-block",
  cursor: "pointer",
  padding: "10px 14px 11px",
  backgroundColor: "transparent",
  border: "2px solid transparent",
  borderRadius: 0,
  variants: {
    color: {
      primary: {
        color: "$primaryDark",
        "&:hover": {
          color: "$primaryDarker",
          backgroundColor: "$primaryLight",
          borderColor: "$primaryLight",
        },
        "&:focus": {
          color: "$primaryDarker",
          backgroundColor: "$primaryLight",
          borderColor: "$primaryLight",
          "$$pt-ringColor": "$colors$primary",
          "$$pt-ringOffsetColor": "$colors$primaryLightest",
        },
      },
      brand: {
        color: "$brand",
        "&:hover": {
          color: "$brandDark",
          backgroundColor: "$brandLighter",
          borderColor: "$brandLighter",
        },
        "&:focus": {
          color: "$brandDark",
          backgroundColor: "$brandLighter",
          borderColor: "$brandLighter",
          "$$pt-ringColor": "$colors$brand",
          "$$pt-ringOffsetColor": "$colors$brandLightest",
        },
      },
      danger: {
        color: "$danger",
        "&:hover": {
          color: "$dangerDark",
          backgroundColor: "$dangerLighter",
          borderColor: "$dangerLighter",
        },
        "&:focus": {
          color: "$dangerDark",
          backgroundColor: "$dangerLighter",
          borderColor: "$dangerLighter",
          "$$pt-ringColor": "$colors$danger",
          "$$pt-ringOffsetColor": "$colors$dangerLightest",
        },
      },
    },
  },
  defaultVariants: {
    color: "brand",
  },
});

export const TDForm = styled("td", { ".pt-ui &": { padding: "1px 0 1px 10px" } });

type Props = {
  modalState: PunchOutRowState,
};

const HourlyPunchOutLevelRow = (props: Props) => {
  if (props.modalState.createMode && !props.modalState.modal.show) {
    return (
      <tr>
        <td className="buy-rate-level-icon" />
        <td colSpan="8" className="text-right">
          <PunchoutButtonGroup>
            <PunchoutButton color="brand" onClick={props.modalState.enterInputMode}>
              <FontAwesomeIcon icon="plus" className="icon" /> Add Level
            </PunchoutButton>
          </PunchoutButtonGroup>
        </td>
      </tr>
    );
  }

  let collapsedStateClass = "collapsed";
  if (!props.modalState.isCollapsed) collapsedStateClass = "expanded";

  let payRateMin = 0;
  let payRateMax = 0;
  let markupPct = 0;
  let billRateMin = 0;
  let billRateMax = 0;

  // run a validation so if the state is not valid,
  // the ui will show the errors
  let isValid = props.modalState.validate();

  if (props.modalState.punchOutRow) {
    payRateMin = props.modalState.punchOutRow.payRateMin;
    payRateMax = props.modalState.punchOutRow.payRateMax;
    markupPct = props.modalState.punchOutRow.markupPct;
    billRateMin = props.modalState.punchOutRow.billRateMin;
    billRateMax = props.modalState.punchOutRow.billRateMax;
  }

  let currency = "";
  if (props.modalState.search.currency) {
    currency = props.modalState.search.currency.symbol;
  }

  const errorsPopOver = (
    <Popover id="popover-positioned-bottom" title="Errors">
      <ul className="rc-ul errors">
        {props.modalState.payRateMinInputValueError && (
          <li>{props.modalState.payRateMinInputValueError}</li>
        )}
        {props.modalState.payRateMaxInputValueError && (
          <li>{props.modalState.payRateMaxInputValueError}</li>
        )}
        {props.modalState.markupPctInputValueError && (
          <li>{props.modalState.markupPctInputValueError}</li>
        )}
        {props.modalState.billRateMinInputValueError && (
          <li>{props.modalState.billRateMinInputValueError}</li>
        )}
        {props.modalState.billRateMaxInputValueError && (
          <li>{props.modalState.billRateMaxInputValueError}</li>
        )}
        {props.modalState.annualSalaryMinInputValueError && (
          <li>{props.modalState.annualSalaryMinInputValueError}</li>
        )}
        {props.modalState.annualSalaryMaxInputValueError && (
          <li>{props.modalState.annualSalaryMaxInputValueError}</li>
        )}
      </ul>
    </Popover>
  );

  let defaultView = (
    <tr>
      <td className={`buy-rate-level-icon ${props.modalState.getLevelSelector()}`}>
        {props.modalState.getLevelRomanNumeral()}
      </td>
      <td className="text-muted hidden-xs hidden-sm">
        Level {props.modalState.getLevelRomanNumeral()}
      </td>
      <td className="text-muted text-right fixed-size hidden-xs hidden-sm">
        {currency} {numeral(payRateMin).format("0,0.00")}
      </td>
      <td className="text-muted text-right fixed-size hidden-xs hidden-sm">
        {currency} {numeral(payRateMax).format("0,0.00")}
      </td>
      <td className="text-muted text-right fixed-size hidden-xs hidden-sm">
        {numeral(markupPct).format("0,0.00")}%
      </td>
      <td className="text-muted text-right fixed-size hidden-xs hidden-sm">
        {currency} {numeral(billRateMin).format("0,0.00")}
      </td>
      <td className="nte-column text-right fixed-size">
        <div className="hidden-xs hidden-sm">
          {currency} {numeral(billRateMax).format("0,0.00")}
        </div>
        {/* MOBILE VIEW */}
        <div className="hidden-md hidden-lg">
          <table className="xs-nte-table no-padding no-margin">
            <tbody>
              <tr>
                <td className="nte-column text-left">NTE Bill:</td>
                <td className="text-right">
                  {currency} {numeral(billRateMax).format("0,0.00")}
                </td>
              </tr>
            </tbody>
          </table>
          {/* COLLAPSIBLE VALUES */}
          <div
            className={`hidden-md hidden-lg buy-rate-collapsible ${collapsedStateClass}`}
          >
            <table className="table level-data buy-rates">
              <thead />
              <tbody className="text-muted">
                <tr>
                  <td className="text-left">Min Bill:</td>
                  <td className="text-right">
                    {currency} {numeral(billRateMin).format("0,0.00")}
                  </td>
                </tr>
                <tr>
                  <td className="text-left">Markup:</td>
                  <td className="text-right">{numeral(markupPct).format("0,0.00")}%</td>
                </tr>
                <tr>
                  <td className="text-left">Max Pay:</td>
                  <td className="text-right">
                    {currency} {numeral(payRateMax).format("0,0.00")}
                  </td>
                </tr>
                <tr>
                  <td className="no-border text-left">Min Pay:</td>
                  <td className="no-border text-right">
                    {currency} {numeral(payRateMin).format("0,0.00")}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </td>
      <td className="text-right">
        <PunchoutButtonGroup>
          <div className="hidden-xs hidden-sm hidden-ml">
            <PunchoutButton color="brand" onClick={props.modalState.enterInputMode}>
              <FontAwesomeIcon icon="edit" />
            </PunchoutButton>
            <PunchoutButton color="danger" onClick={props.modalState.delete}>
              <FontAwesomeIcon icon="trash-alt" className="danger-icon" />
            </PunchoutButton>
          </div>
          <div className="visible-ml">
            <PunchoutButton color="brand" onClick={props.modalState.enterInputMode}>
              <FontAwesomeIcon icon="edit" className="icon" /> Edit
            </PunchoutButton>
            <PunchoutButton color="danger" onClick={props.modalState.delete}>
              <FontAwesomeIcon icon="trash-alt" className="icon" /> Delete
            </PunchoutButton>
          </div>
          <div className="hidden-md hidden-lg">
            <PunchoutButton
              color="brand"
              onClick={props.modalState.toggleCollapsed}
              className="btn-sm-device"
            >
              <FontAwesomeIcon icon="chevron-down" className={collapsedStateClass} />
            </PunchoutButton>
            <div className={`text-right buy-rate-collapsible ${collapsedStateClass}`}>
              <PunchoutButton
                color="brand"
                className="first"
                onClick={props.modalState.enterInputMode}
              >
                <FontAwesomeIcon icon="edit" />
              </PunchoutButton>
              <PunchoutButton color="danger" onClick={props.modalState.delete}>
                <FontAwesomeIcon icon="trash-alt" />
              </PunchoutButton>
            </div>
          </div>
        </PunchoutButtonGroup>
      </td>
    </tr>
  );

  let inputView = (
    <tr
      className="inline-input punchout-input-value"
      onKeyDown={props.modalState.submitOnKeyPress}
    >
      {!props.modalState.createMode && (
        <td className={`buy-rate-level-icon ${props.modalState.getLevelSelector()}`}>
          {props.modalState.getLevelRomanNumeral()}
        </td>
      )}
      {props.modalState.createMode && (
        <td
          className={`buy-rate-level-icon ${props.modalState.getLevelSelector()} hidden-xs hidden-sm`}
        >
          {props.modalState.getLevelRomanNumeral()}
        </td>
      )}
      {props.modalState.createMode && (
        <TDForm
          className={`buy-rate-level-icon ${props.modalState.getLevelSelector()} text-muted hidden-md hidden-lg`}
        >
          <HTMLSelect
            name="level"
            id="levelSelect"
            className="form-control"
            value={props.modalState.level}
            onChange={props.modalState.onLevelChange}
          >
            {props.modalState.getMissingLevels().map((level) => (
              <option key={level} value={level}>
                {LEVEL_ROMAN_NUMERAL[level]}
              </option>
            ))}
          </HTMLSelect>
        </TDForm>
      )}
      {props.modalState.createMode ? (
        <TDForm className="text-muted hidden-xs hidden-sm">
          <HTMLSelect
            deep
            name="level"
            id="levelSelect"
            value={props.modalState.level}
            onChange={props.modalState.onLevelChange}
          >
            {props.modalState.getMissingLevels().map((level) => (
              <option key={level} value={level}>
                {LEVEL_ROMAN_NUMERAL[level]}
              </option>
            ))}
          </HTMLSelect>
        </TDForm>
      ) : (
        <td className="text-muted hidden-xs hidden-sm">
          Level {props.modalState.getLevelRomanNumeral()}
        </td>
      )}
      <TDForm className="text-muted text-right fixed-size hidden-xs hidden-sm">
        <TextInput
          fill
          deep
          addon={currency}
          css={{ ".pt-ui & input": { textAlign: "right" } }}
          color={
            props.modalState.payRateMinInputValueError !== null ? "danger" : "primary"
          }
          id="payRateMinInput"
          placeholder="Amount"
          value={props.modalState.payRateMinInputValue}
          onChange={props.modalState.onPayRateMinChange}
        />
      </TDForm>
      <TDForm className="text-muted text-right fixed-size hidden-xs hidden-sm">
        <TextInput
          fill
          deep
          addon={currency}
          css={{ ".pt-ui & input": { textAlign: "right" } }}
          color={
            props.modalState.payRateMaxInputValueError !== null ? "danger" : "primary"
          }
          id="payRateMaxInput"
          placeholder="Amount"
          value={props.modalState.payRateMaxInputValue}
          onChange={props.modalState.onPayRateMaxChange}
        />
      </TDForm>
      <TDForm className="text-muted text-right fixed-size hidden-xs hidden-sm">
        <TextInput
          fill
          deep
          addon={currency}
          css={{ ".pt-ui & input": { textAlign: "right" } }}
          color={
            props.modalState.markupPctInputValueError !== null ? "danger" : "primary"
          }
          id="markupPctInput"
          placeholder="Percent"
          value={props.modalState.markupPctInputValue}
          onChange={props.modalState.onMarkupPctChange}
        />
      </TDForm>
      <TDForm className="text-muted text-right fixed-size hidden-xs hidden-sm">
        <TextInput
          fill
          deep
          addon={currency}
          css={{ ".pt-ui & input": { textAlign: "right" } }}
          color={
            props.modalState.billRateMinInputValueError !== null ? "danger" : "primary"
          }
          id="billRateMinInput"
          placeholder="Amount"
          value={props.modalState.billRateMinInputValue}
          onChange={props.modalState.onBillRateMinChange}
        />
      </TDForm>
      <TDForm className="nte-column text-right fixed-size">
        <div className="hidden-xs hidden-sm">
          <TextInput
            fill
            deep
            addon={currency}
            css={{ ".pt-ui & input": { textAlign: "right" } }}
            color={
              props.modalState.billRateMaxInputValueError !== null ? "danger" : "primary"
            }
            id="billRateMaxInput"
            placeholder="Amount"
            value={props.modalState.billRateMaxInputValue}
            onChange={props.modalState.onBillRateMaxChange}
          />
        </div>
        {/* MOBILE VIEW */}
        <div className="hidden-md hidden-lg">
          <table className="xs-nte-table no-padding no-margin">
            <tbody>
              <tr className="xs-nte-row-input">
                <td className="nte-column text-left">NTE Bill:</td>
                <TDForm className="nte text-right">
                  <TextInput
                    deep
                    fill
                    size="extra-small"
                    css={{ ".pt-ui & input": { textAlign: "right" } }}
                    color={
                      props.modalState.billRateMaxInputValueError !== null
                        ? "danger"
                        : "primary"
                    }
                    id="billRateMaxInput"
                    placeholder="Amount"
                    value={props.modalState.billRateMaxInputValue}
                    onChange={props.modalState.onBillRateMaxChange}
                  />
                </TDForm>
              </tr>
            </tbody>
          </table>
          {/* COLLAPSIBLE VALUES */}
          <div
            className={`hidden-md hidden-lg buy-rate-collapsible ${collapsedStateClass}`}
          >
            <table className="table level-data buy-rates input">
              <thead />
              <tbody className="text-muted">
                <tr>
                  <td className="text-left">Min Bill:</td>
                  <TDForm className="text-right">
                    <TextInput
                      deep
                      fill
                      size="extra-small"
                      css={{ ".pt-ui & input": { textAlign: "right" } }}
                      color={
                        props.modalState.billRateMinInputValueError !== null
                          ? "danger"
                          : "primary"
                      }
                      id="billRateMinInput"
                      placeholder="Amount"
                      value={props.modalState.billRateMinInputValue}
                      onChange={props.modalState.onBillRateMinChange}
                    />
                  </TDForm>
                </tr>
                <tr>
                  <td className="text-left">Markup:</td>
                  <TDForm className="text-right">
                    <TextInput
                      deep
                      fill
                      size="extra-small"
                      css={{ ".pt-ui & input": { textAlign: "right" } }}
                      color={
                        props.modalState.markupPctInputValueError !== null
                          ? "danger"
                          : "primary"
                      }
                      id="markupPctInput"
                      placeholder="Percent"
                      value={props.modalState.markupPctInputValue}
                      onChange={props.modalState.onMarkupPctChange}
                    />
                  </TDForm>
                </tr>
                <tr>
                  <td className="text-left">Max Pay:</td>
                  <TDForm className="text-right">
                    <TextInput
                      deep
                      fill
                      size="extra-small"
                      css={{ ".pt-ui & input": { textAlign: "right" } }}
                      color={
                        props.modalState.payRateMaxInputValueError !== null
                          ? "danger"
                          : "primary"
                      }
                      id="payRateMaxInput"
                      placeholder="Amount"
                      value={props.modalState.payRateMaxInputValue}
                      onChange={props.modalState.onPayRateMaxChange}
                    />
                  </TDForm>
                </tr>
                <tr>
                  <td className="no-border text-left">Min Pay:</td>
                  <TDForm className="no-border text-right">
                    <TextInput
                      deep
                      fill
                      size="extra-small"
                      css={{ ".pt-ui & input": { textAlign: "right" } }}
                      color={
                        props.modalState.payRateMinInputValueError !== null
                          ? "danger"
                          : "primary"
                      }
                      id="payRateMinInput"
                      placeholder="Amount"
                      value={props.modalState.payRateMinInputValue}
                      onChange={props.modalState.onPayRateMinChange}
                    />
                  </TDForm>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </TDForm>
      <td className="text-right">
        <PunchoutButtonGroup>
          <div className="hidden-xs hidden-sm hidden-ml">
            <OverlayTrigger
              trigger={["hover", "click"]}
              placement="left"
              rootClose
              overlay={errorsPopOver}
            >
              <FontAwesomeIcon
                icon="exclamation-triangle"
                fixedWidth
                className={classNames("icon text-danger", { hidden: isValid })}
              />
            </OverlayTrigger>

            <PunchoutButton color="brand" onClick={props.modalState.submit}>
              <FontAwesomeIcon icon="check" />
            </PunchoutButton>
            <PunchoutButton
              color="primary"
              onClick={props.modalState.resetAndClose}
              className={classNames({ hidden: props.modalState.submitRequired })}
            >
              <FontAwesomeIcon icon="ban" />
            </PunchoutButton>
          </div>
          <div className="visible-ml">
            <OverlayTrigger
              trigger={["hover", "click"]}
              placement="left"
              rootClose
              overlay={errorsPopOver}
            >
              <FontAwesomeIcon
                icon="exclamation-triangle"
                fixedWidth
                className={classNames("icon text-danger", { hidden: isValid })}
              />
            </OverlayTrigger>
            <PunchoutButton onClick={props.modalState.submit}>
              <FontAwesomeIcon icon="check" className="icon" /> Save
            </PunchoutButton>
            <PunchoutButton
              color="primary"
              onClick={props.modalState.resetAndClose}
              className={classNames({ hidden: props.modalState.submitRequired })}
            >
              <FontAwesomeIcon icon="ban" className="icon" /> Cancel
            </PunchoutButton>
          </div>
          <div className="hidden-md hidden-lg">
            <OverlayTrigger
              trigger="click"
              placement="left"
              rootClose
              overlay={errorsPopOver}
            >
              <FontAwesomeIcon
                icon="exclamation-triangle"
                fixedWidth
                className={classNames("icon text-danger", { hidden: isValid })}
              />
            </OverlayTrigger>
            <PunchoutButton
              color="brand"
              onClick={props.modalState.toggleCollapsed}
              className="btn-sm-device"
            >
              <FontAwesomeIcon icon="chevron-down" className={collapsedStateClass} />
            </PunchoutButton>
            <div className={`text-right buy-rate-collapsible ${collapsedStateClass}`}>
              <PunchoutButton
                color="brand"
                onClick={props.modalState.submit}
                className="first"
              >
                <FontAwesomeIcon icon="check" />
              </PunchoutButton>
              <PunchoutButton
                color="primary"
                onClick={props.modalState.resetAndClose}
                className={classNames({ hidden: props.modalState.submitRequired })}
              >
                <FontAwesomeIcon icon="ban" className="danger-icon" />
              </PunchoutButton>
            </div>
          </div>
        </PunchoutButtonGroup>
      </td>
    </tr>
  );

  if (props.modalState.modal.show) return inputView;

  return defaultView;
};

export default observer(HourlyPunchOutLevelRow);
