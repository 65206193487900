import moment from "moment";

export default class UserFeed {
  constructor(store, object) {
    this.store = store;
    this.title = null;
    this.link = null;
    this.description = null;
    this.pubdate = null;

    if (object) {
      this.title = object.title || null;
      this.link = object.link || null;
      this.descriptionText = object.descriptionText || null;
      this.pubdate = moment(object.pubdate) || null;
      this.pubdateDisplay = `${this.pubdate.format("D MMMM, YYYY")}`;
    }
  }

  static fromJS(store, object) {
    return new UserFeed(store, object);
  }
}
