import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/lib/Async";

import { reactSelectStyles, reactSelectTheme } from "../../constants/reactSelect";
import { logAsyncOperationError, logGraphQLError } from "../../utils/logging";

// `query getAllLegacyUsers($searchTerm: String!) {
//   viewer {
//     legacyUsers(excludeSelf: false, filters: {loginIContains: $searchTerm}) {
//       edges {
//         node {
//           userId
//           username
//         }
//       }
//     }
//   }
// }`,
// `query getAllLegacyUsers($searchTerm: String!) {
//   viewer {
//     allUsers(search: $searchTerm) {
//       edges {
//         node {
//           userId
//           username
//         }
//       }
//     }
//   }
// }`,
const loadOptionsQuery = `query fetchAllUsers($searchTerm: String!) {
  viewer {
    allUsers(filters: {usernameIContains: $searchTerm}) {
      edges {
        node {
          userId
          username
        }
      }
    }
  }
}`;

const getOptionValue = (option) => option.userId;
const getOptionLabel = (option) => option.username;

const LegacyUserSelect = (props) => {
  const { fetchGraphQL } = props;
  const loadOptions = React.useCallback(
    async (inputValue) => {
      try {
        const response = await fetchGraphQL(loadOptionsQuery, { searchTerm: inputValue });

        if (logGraphQLError("fetchAllUsers", response.data)) return [];

        return response.data.data.viewer.allUsers.edges.map((edge) => edge.node);
      } catch (err) {
        logAsyncOperationError("fetchAllUsers", err);
        throw err;
      }
    },
    [fetchGraphQL]
  );

  return (
    <AsyncSelect
      className={props.className}
      inputId={props.id}
      loadOptions={loadOptions}
      cacheOptions
      noOptionsMessage={(inputValue) => "No users found for your input..."}
      value={props.value}
      onChange={props.onChange}
      styles={props.styles}
      theme={props.theme}
      placeholder={props.placeholder}
      isLoading={props.loading}
      isDisabled={props.disabled}
      isMulti={props.multi}
      isSearchable={props.searchable}
      isClearable={props.clearable}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
    />
  );
};

LegacyUserSelect.propTypes = {
  id: PropTypes.string,
  fetchGraphQL: PropTypes.func.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  multi: PropTypes.bool,
  searchable: PropTypes.bool,
  clearable: PropTypes.bool,
  styles: PropTypes.object,
  theme: PropTypes.func,
};

LegacyUserSelect.defaultProps = {
  placeholder: "Type to find your user...",
  loading: false,
  disabled: false,
  multi: false,
  searchable: true,
  clearable: false,
  styles: reactSelectStyles,
  theme: reactSelectTheme,
};

export default LegacyUserSelect;
