// @flow
import * as React from "react";
import graphql from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import { createPaginationContainer } from "react-relay";
import FilterAndSort from "./FilterAndSort";
import type { RelayPaginationProp } from "react-relay";
import type { FilterAndSortCreatedByFetchAllQueryResponse } from "./__generated__/FilterAndSortCreatedByFetchAllQuery.graphql";
import type { FilterAndSortCreatedBy_viewer } from "./__generated__/FilterAndSortCreatedBy_viewer.graphql";
import type { SortDirection } from "../models/Sort";

type CreatedByFilter = {
  createdById: Array<string>,
};

export type CreatedBySort = {
  field: "OWNER_FIRST_NAME",
  direction: SortDirection,
  // caseSensitive: false // optional
};

export type MaybeCreatedByFilter = CreatedByFilter | null;
export type MaybeCreatedBySort = CreatedBySort | null;

type Props = {
  open: boolean,
  currentFilter: MaybeCreatedByFilter,
  currentSort: MaybeCreatedBySort,
  onClose: () => void,
  onApply: (MaybeCreatedByFilter, MaybeCreatedBySort) => void,
  +viewer: FilterAndSortCreatedBy_viewer | null,
  +relay: RelayPaginationProp,
};
type State = {
  refetching: boolean,
  page: number,
  disabled: boolean,
  searchText: string | null,
  selectAll: boolean,
  error: Object | null,
  selectedCreatedBys: ?Array<string>,
  sortDirection: SortDirection | null,
};

type CreatedByItem = { id: string, username: string, userId: number };

// Used when "getting all ids"
const FetchAllCreatedBysQuery = graphql`
  query FilterAndSortCreatedBy2FetchAllQuery($filters: AuthorFiltersInput) {
    viewer {
      authors(filters: $filters, section: ADMIN) {
        edges {
          node {
            id
            userId
          }
        }
      }
    }
  }
`;

// HACK: Duplicates FilterAndSortCreatedBy.jsx in order to fix the use of integer ids rather than relay ids
// Replace FilterAndSortCreatedBy.jsx when relay ids are used for filtering
class FilterAndSortCreatedBys extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      refetching: false,
      disabled: false,
      page: 0,
      selectAll: false,
      searchText: null,
      error: null,
      selectedCreatedBys: null,
      sortDirection: props.currentSort?.direction || null,
    };
  }

  isItemSelected = (id: string) =>
    this.state.selectedCreatedBys
      ? this.state.selectedCreatedBys.indexOf(id) !== -1
      : this.props.currentFilter
      ? this.props.currentFilter.createdById.indexOf(id) !== -1
      : false;

  handleApply = async () => {
    const { selectedCreatedBys: createdById, sortDirection } = this.state;

    let filter: MaybeCreatedByFilter = null;
    if (createdById && createdById.length > 0) filter = { createdById };

    let sort: MaybeCreatedBySort = null;
    if (sortDirection) sort = { field: "OWNER_FIRST_NAME", direction: sortDirection };

    this.setState({ selectedCreatedBys: null }, () => {
      this.props.onApply(filter, sort);
    });
  };

  handleToggleSelectAll = async () => {
    // If "All" selected get rid of them
    if (this.state.selectAll) {
      this.setState({ disabled: false, selectAll: false, selectedCreatedBys: [] });
    } else {
      const filters =
        this.state.searchText !== null ? { nameIContains: this.state.searchText } : {};
      this.setState({ disabled: true }, async () => {
        const data: FilterAndSortCreatedByFetchAllQueryResponse = await fetchQuery(
          this.props.relay.environment,
          FetchAllCreatedBysQuery,
          { filters }
        ).toPromise();
        this.setState({
          disabled: false,
          selectAll: true,
          selectedCreatedBys:
            data.viewer?.authors?.edges
              ?.map((edge) => edge?.node?.id || "")
              .filter((id) => id !== "") || [],
        });
      });
    }
  };

  handleToggleSelect = async (id: string) => {
    let selectedCreatedBys =
      this.state.selectedCreatedBys?.slice() ||
      this.props.currentFilter?.createdById.slice() ||
      [];
    const index = selectedCreatedBys.indexOf(id);
    const selected = index !== -1;
    if (selected) {
      selectedCreatedBys = selectedCreatedBys
        .slice(0, index)
        .concat(selectedCreatedBys.slice(index + 1, selectedCreatedBys.length));
    } else {
      selectedCreatedBys.push(id);
    }
    this.setState({
      selectAll: false,
      selectedCreatedBys,
    });
  };

  handleReset = () => {
    this.setState(
      {
        selectedCreatedBys: null,
        sortDirection: null,
      },
      () => {
        this.props.onApply(null, null);
      }
    );
  };

  handleClose = () => {
    this.setState(
      {
        selectedCreatedBys: null,
        sortDirection: null,
      },
      () => {
        this.props.onClose();
      }
    );
  };

  handleSortOnClick = (dir: SortDirection) => {
    this.setState({ sortDirection: dir });
  };

  renderItem = (item: CreatedByItem) => {
    return (
      <div>
        {item.username} [#{item.userId}]
      </div>
    );
  };

  render() {
    const { sortDirection } = this.state;
    const loading = this.props.relay.isLoading() || this.state.refetching;
    const itemsPerPage = 10;

    const authors = this.props.viewer?.authors?.edges || [];
    const page = authors.slice(
      itemsPerPage * this.state.page,
      Math.min(itemsPerPage * (this.state.page + 1), authors.length)
    );

    if (this.state.error) {
      return (
        <div>
          Error!: <pre>{this.state.error}</pre>
        </div>
      );
    }
    const users: Array<CreatedByItem> =
      page.map((edge, index) => ({
        id: edge?.node?.id || `missing-id-${index}`,
        userId: edge?.node?.userId || -1 * index,
        username: edge?.node?.username || "[error: 'username']",
      })) || [];

    return (
      <FilterAndSort
        title="Created By"
        allSelected={this.state.selectAll}
        show={this.props.open}
        disabled={this.state.disabled}
        sortKind="alpha"
        sortDirection={sortDirection}
        sortOnClick={this.handleSortOnClick}
        items={users}
        hasPrevPage={this.state.page > 0}
        onPageBackward={() => {
          this.setState({ page: this.state.page > 0 ? this.state.page - 1 : 0 });
        }}
        hasNextPage={
          (this.state.page + 1) * itemsPerPage < authors.length ||
          this.props.relay.hasMore()
        }
        onPageForward={() => {
          if (
            this.props.relay.hasMore &&
            (this.state.page + 1) * itemsPerPage > authors.length - itemsPerPage
          ) {
            console.log("loading more items");
            this.setState({ refetching: true }, () => {
              this.props.relay.loadMore(itemsPerPage, (error) => {
                if (error) {
                  console.error(error);
                }
                this.setState({ error, page: this.state.page + 1, refetching: false });
              });
            });
          } else {
            this.setState({ page: this.state.page + 1 });
          }
        }}
        renderItem={this.renderItem}
        loading={loading}
        onSearch={async (searchText: string) => {
          console.log("start search");
          this.setState({ refetching: true, selectAll: false, searchText }, () => {
            this.props.relay.refetchConnection(
              itemsPerPage,
              (error) => {
                console.log("search finished refetch");
                if (error) {
                  console.error(error);
                }
                this.setState({ error, page: 0, refetching: false });
              },
              {
                filters: {
                  usernameIContains: searchText,
                },
              }
            );
          });
        }}
        selectedItems={
          this.state.selectedCreatedBys || this.props.currentFilter?.createdById
        }
        isItemSelected={(createdBy) => this.isItemSelected(createdBy.id)}
        onApply={this.handleApply}
        onToggleSelectAll={this.handleToggleSelectAll}
        onToggleSelectItem={this.handleToggleSelect}
        onReset={this.handleReset}
        onClose={this.handleClose}
      />
    );
  }
}

export default createPaginationContainer(
  FilterAndSortCreatedBys,
  {
    viewer: graphql`
      fragment FilterAndSortCreatedBy2_viewer on Viewer
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        cursor: { type: "String" }
        filters: { type: "AuthorFiltersInput", defaultValue: {} }
        order: {
          type: "[AuthorSortInput]"
          defaultValue: [{ direction: ASC, field: USERNAME }]
        }
      ) {
        authors(
          first: $count
          after: $cursor
          filters: $filters
          order: $order
          section: ADMIN
        ) @connection(key: "FilterAndSortCreatedBy_authors", filters: ["filters"]) {
          edges {
            node {
              id
              userId
              firstName
              lastName
              username
              email
            }
          }
        }
      }
    `,
  },
  {
    direction: "forward",
    getVariables(props: Props, { count, cursor }, fragmentVariables) {
      return {
        count,
        cursor,
        filters: fragmentVariables.filters,
        order: fragmentVariables.order,
      };
    },
    query: graphql`
      query FilterAndSortCreatedBy2Query(
        $filters: AuthorFiltersInput
        $count: Int
        $cursor: String
      ) {
        viewer {
          ...FilterAndSortCreatedBy2_viewer
            @arguments(filters: $filters, count: $count, cursor: $cursor)
        }
      }
    `,
  }
);
