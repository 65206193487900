// @flow

import R from "ramda";
import moment from "moment";
import { extendObservable, action, runInAction, computed, observable } from "mobx";
import axios from "axios";
import TagModel from "../../../models/TagList";
import CurrentUser from "../../../models/User";
import {
  RateCardLabelFilter,
  CreatedByFilter,
  SharedFilter,
  CreatedOnFilter,
  UserFilter,
  ClientUserFilter,
} from "../../../models/FilterState";
import NetworkState from "../../../models/NetworkState";
import PaginationState from "../../../models/PaginationState";
import FilterObject, { FILTER_COLUMN } from "../../../models/Filter";
import Sort, { SORT_DIRECTION } from "../../../models/Sort";
import {
  addIdToPayload,
  consolidateAppliedFilters,
  consolidateAppliedSorts,
} from "../SupportFunctions";
import type { PageQuery, PaginationInfo } from "../../../models/PaginationState";
import type { FilterColumn } from "../../../models/Filter";
import type { GraphQLQuery } from "../../../models/GraphQL";
import MessageState from "../../../models/MessageState";
import ModalState from "../../../models/ModalState";
import type { FetchGraphQL, FetchAPI } from "../../../App";

const ownersCriteriaQuery = `
query authorList {
  viewer {
    authors(section : ADMIN) {
      edges {
        node {
          userId
          firstName
          lastName
          username
          email
        }
      }
    }
  }
}
`;

const rateTypesCriteriaQuery = `
query {
  viewer {
    rateCardsFilterCriteria {
      rateTypes
    }
  }
}
`;

const userCriteriaQuery = `
query authorList {
  viewer {
    authors {
      edges {
        node {
          userId
          firstName
          lastName
          username
          email
        }
      }
    }
  }
}
`;

const clientUserCriteriaQuery = `
query allClients {
  viewer {
    allClients(order : [{direction : ASC, field: NAME}]) {
      edges {
        node {
          clientId
          name
        }
      }
    }
  }
}
`;

export class TagManagementListComponentStore {
  network: NetworkState;
  pagination: PaginationState;

  tags: TagModel[];
  tagsViewState: Object;
  tagsView: TagModel[];
  tagCounts: any;

  currentUser: CurrentUser;
  isEditing: ?boolean;
  allOnPageSelected: boolean;
  allSelected: boolean;
  allSelectedfilter: boolean;
  allowMultipleItemSelection: boolean;

  tagLabelFilter: RateCardLabelFilter;
  createdByFilter: CreatedByFilter;
  sharedFilter: SharedFilter;
  createdOnFilter: CreatedOnFilter;
  userFilter: UserFilter;
  clientUserFilter: ClientUserFilter;
  requestFrom: boolean;

  defaultFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedSorts: {
    [key: FilterColumn]: Sort,
  };
  appliedSortsOrder: Array<FilterColumn>;
  isFiltered: boolean;
  hasOwnership: boolean;
  selectedTagId: null;
  sevenUrl: string;
  unSelectedTag: [];

  getTags: (PageQuery) => Promise<PaginationInfo>;
  applyDefaultFilter: (FilterColumn, FilterObject) => void;
  handleStartEdit: () => void;
  handleStopEdit: () => void;
  toggleSelectAllPage: (Object) => void;
  selectAllPage: (Event) => void;
  deselectAllPage: (Event) => void;
  toggleAllItems: () => void;
  clearAllSelections: () => void;
  getSelectedTags: () => Array<number>;
  getFirstSelectedTag: () => Object;

  getFilterCriteriaQuery: (FilterColumn) => GraphQLQuery;
  processFilterCriteria: (FilterColumn, Object) => Array<Object>;
  applyFilter: (FilterColumn, FilterObject) => void;
  removeFilter: (FilterColumn) => void;
  applySort: (FilterColumn, Sort) => void;
  removeSort: (FilterColumn) => void;
  toDateRange: () => void;
  filterTags: () => void;
  clearFilters: () => void;
  applyDefaultSort: () => void;
  updateRequestFrom: () => void;
  SingleSelectedTag: () => void;
  messaging: MessageState;
  tagLabel: string;
  changeTagLabelText: (Object) => void;
  helpModal: ModalState;
  selectAllOnPageItem: () => void;
  fetchGraphQL: FetchGraphQL;

  constructor(fetchGraphQL: FetchGraphQL) {
    this.fetchGraphQL = fetchGraphQL;

    // NOTE: Bound early to pass into pagination & filter state
    this.getTags = action(this.getTags.bind(this));

    this.getFilterCriteriaQuery = action(this.getFilterCriteriaQuery.bind(this));
    this.processFilterCriteria = action(this.processFilterCriteria.bind(this));
    this.applyFilter = action(this.applyFilter.bind(this));
    this.applySort = action(this.applySort.bind(this));
    this.removeFilter = action(this.removeFilter.bind(this));
    this.removeSort = action(this.removeSort.bind(this));
    this.changeTagLabelText = action(this.changeTagLabelText.bind(this));
    this.toDateRange = action(this.toDateRange.bind(this));
    this.exportUrl = "tags/basic/list/export/excel/";
    this.unSelectedTag = [];

    extendObservable(this, {
      network: new NetworkState(),
      pagination: new PaginationState(this.getTags),
      tagLabel: "",
      tags: [],
      tagCounts: 0,
      hasOwnership: true,
      selectedTagId: null,
      tagsViewState: observable.map({}),
      tagsView: computed(() => {
        return this.tags.map((tag) => {
          if (this.tagsViewState.has(tag.tagId)) {
            tag.viewState = this.tagsViewState.get(tag.tagId);

            return tag;
          }

          return tag;
        });
      }),
      isEditing: null, // we start with null so some view elements be hidden initially
      isFiltered: false,
      allSelected: false,
      allSelectedfilter: false,
      allowMultipleItemSelection: true,
      requestFrom: false,
      selectedCount: computed(() => {
        const selectedValues = this.tagsView.map((tag) => tag.viewState.selected);

        if (this.allSelected) {
          return this.pagination.totalCount;
        }

        let count = 0;

        selectedValues.forEach((v) => {
          if (v) {
            count += 1;
          }
        });

        return count;
      }),
      allOnPageSelected: computed(() => {
        const allTrue = R.all(R.equals(true));
        const selectedValues = this.tagsView.map((tag) => tag.viewState.selected);

        if (selectedValues.length === 0) {
          return false;
        }

        return allTrue(selectedValues);
      }),
      defaultFilters: {},
      appliedFilters: {},
      appliedSorts: {},
      appliedSortsOrder: observable.shallow([]),
      tagLabelFilter: new RateCardLabelFilter(
        this,
        FILTER_COLUMN.RATE_CARD_LABEL,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      createdByFilter: new CreatedByFilter(
        this,
        FILTER_COLUMN.CREATED_BY,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      sharedFilter: new SharedFilter(
        this,
        FILTER_COLUMN.SHARED,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      createdOnFilter: new CreatedOnFilter(
        this,
        FILTER_COLUMN.DATE_RANGE,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      userFilter: new UserFilter(
        this,
        FILTER_COLUMN.USER,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      clientUserFilter: new ClientUserFilter(
        this,
        FILTER_COLUMN.CLIENT_USER,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      messaging: new MessageState(),
      helpModal: new ModalState(),
    });

    this.currentUser = null;

    this.applyDefaultFilter = action(this.applyDefaultFilter.bind(this));
    this.handleStartEdit = action(this.handleStartEdit.bind(this));
    this.handleStopEdit = action(this.handleStopEdit.bind(this));
    this.toggleSelectAllPage = action(this.toggleSelectAllPage.bind(this));
    this.selectAllPage = action(this.selectAllPage.bind(this));
    this.deselectAllPage = action(this.deselectAllPage.bind(this));
    this.toggleAllItems = action(this.toggleAllItems.bind(this));
    this.clearAllSelections = action(this.clearAllSelections.bind(this));
    this.getSelectedTags = action(this.getSelectedTags.bind(this));
    this.getFirstSelectedTag = action(this.getFirstSelectedTag.bind(this));

    this.filterTags = action(this.filterTags.bind(this));
    this.clearFilters = action(this.clearFilters.bind(this));
    this.applyDefaultSort = action(this.applyDefaultSort.bind(this));
    this.updateRequestFrom = action(this.updateRequestFrom.bind(this));
    this.SingleSelectedTag = action(this.SingleSelectedTag.bind(this));
    this.selectAllOnPageItem = action(this.selectAllOnPageItem.bind(this));

    this.applyDefaultSort();
  }

  updateRequestFrom(request) {
    if (request && request === "pce") this.requestFrom = true;
    else {
      this.requestFrom = false;
    }
  }

  SingleSelectedTag(tag) {
    let viewState = this.tagsViewState.get(tag.tagId).selected;

    if (!viewState) {
      this.selectedTagId = tag.tagId;
    } else {
      this.selectedTagId = null;
    }

    if (!viewState && tag.owner && tag.owner.userId) {
      this.hasOwnership = String(tag.owner.userId) === String(this.currentUser.userId);
    } else {
      this.hasOwnership = true;
    }
  }

  applyDefaultSort() {
    this.createdOnFilter.sortState.direction = SORT_DIRECTION.DESC;
    this.createdOnFilter.sort = this.createdOnFilter.buildQuerySort();
    this.applySort(this.createdOnFilter.column, this.createdOnFilter.sort);
  }

  handleStartEdit() {
    this.isEditing = true;
    this.tagsView.forEach((tag) => tag.toggleEdit());
  }

  handleStopEdit() {
    this.isEditing = false;
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.tagsViewState.forEach((viewState) => {
      viewState.selected = false;
      viewState.editing = false;
    });
  }

  toggleSelectAllPage(e: Object) {
    if (!this.allowMultipleItemSelection) return;

    const setValue = !this.allOnPageSelected;

    this.tagsView.forEach((tag) => {
      tag.toggleSelected(e, null, null, setValue);
    });

    // When All items selected flag is up, clear selection
    if (setValue === false && this.allSelected) this.allSelected = false;
  }

  selectAllPage(e: Event) {
    this.unSelectedTag = [];
    this.allSelected = true;
    this.allSelectedfilter = true;
  }

  selectAllOnPageItem(e: Event) {
    this.tagsView.forEach((tag) => {
      tag.toggleSelected(e, null, null, true);
    });
  }

  deselectAllPage(e: Event) {
    this.tagsView.forEach((tag) => {
      tag.toggleSelected(e, null, null, false);
    });

    this.allSelected = false;
    this.allSelectedfilter = false;
  }

  toggleAllItems() {
    if (!this.allowMultipleItemSelection) return;

    this.allSelected = !this.allSelected;

    if (this.allSelected === false) {
      this.tagsViewState.forEach((value) => {
        value.selected = false;
      });
    }
  }

  clearAllSelections() {
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.tagsViewState.forEach((value) => {
      value.selected = false;
    });
  }

  getFilterCriteriaQuery(column: FilterColumn): GraphQLQuery {
    switch (column) {
      case FILTER_COLUMN.RATE_TYPE:
        return {
          query: rateTypesCriteriaQuery,
          variables: {},
        };

      case FILTER_COLUMN.CREATED_BY:
        return {
          query: ownersCriteriaQuery,
          variables: {},
        };

      case FILTER_COLUMN.USER:
        return {
          query: userCriteriaQuery,
          variables: {},
        };

      case FILTER_COLUMN.CLIENT_USER:
        return {
          query: clientUserCriteriaQuery,
          variables: {},
        };

      default:
        return null;
    }
  }

  processFilterCriteria(column: FilterColumn, payload: Object): ?Array<Object> {
    switch (column) {
      case FILTER_COLUMN.RATE_TYPE:
        const rateTypes: [String] = payload.data.viewer.rateCardsFilterCriteria.rateTypes;
        return addIdToPayload(rateTypes);

      case FILTER_COLUMN.CREATED_BY:
        const owners: [
          {
            userId: string,
            username: string,
          }
        ] = payload.data.viewer.authors.edges;
        let processedOwners = observable.map({});
        owners.forEach((owner) => {
          processedOwners.set(String(owner.node.userId), {
            id: String(owner.node.userId),
            ...owner,
          });
        });

        return processedOwners;

      case FILTER_COLUMN.USER:
        const users: [
          {
            userId: string,
            username: string,
          }
        ] = payload.data.viewer.authors.edges;
        let processedUsers = observable.map({});
        users.forEach((owner) => {
          processedUsers.set(String(owner.node.userId), {
            id: String(owner.node.userId),
            ...owner,
          });
        });

        return processedUsers;

      case FILTER_COLUMN.CLIENT_USER:
        const clientowners: [
          {
            clientId: string,
            name: string,
          }
        ] = payload.data.viewer.allClients.edges;
        let processedClientOwners = observable.map({});
        clientowners.forEach((owner) => {
          processedClientOwners.set(owner.node.clientId, {
            id: owner.node.clientId,
            ...owner,
          });
        });

        return processedClientOwners;

      default:
        return null;
    }
  }

  applyFilter(column: FilterColumn, filter: FilterObject) {
    this.appliedFilters[column] = filter;
    this.isFiltered = true;
  }

  applyDefaultFilter(column: FilterColumn, filter: FilterObject) {
    this.defaultFilters[column] = filter;
  }

  removeFilter(column: FilterColumn) {
    delete this.appliedFilters[column];

    let entries = Object.entries(this.appliedFilters);
    if (!entries.length) this.isFiltered = false;
  }

  applySort(column: FilterColumn, sort: Sort) {
    this.appliedSorts[column] = sort;

    const index = this.appliedSortsOrder.indexOf(column);
    if (index === -1) this.appliedSortsOrder.push(column);
  }

  removeSort(column: FilterColumn) {
    delete this.appliedSorts[column];

    const index = this.appliedSortsOrder.indexOf(column);
    if (index > -1) this.appliedSortsOrder.splice(index, 1);
  }

  clearFilters() {
    this.tagLabelFilter = new RateCardLabelFilter(
      this,
      FILTER_COLUMN.RATE_CARD_LABEL,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.createdByFilter = new CreatedByFilter(
      this,
      FILTER_COLUMN.CREATED_BY,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.sharedFilter = new SharedFilter(
      this,
      FILTER_COLUMN.SHARED,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.createdOnFilter = new CreatedOnFilter(
      this,
      FILTER_COLUMN.DATE_RANGE,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );

    this.userFilter = new UserFilter(
      this,
      FILTER_COLUMN.USER,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.clientUserFilter = new ClientUserFilter(
      this,
      FILTER_COLUMN.CLIENT_USER,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );

    this.appliedFilters = observable({});
    this.appliedSorts = observable({});
    this.appliedSortsOrder.length = 0;
    this.isFiltered = false;
    this.applyDefaultSort();

    return this.pagination.goFetch(null);
  }

  filterTags() {
    return this.pagination.goFetch(null);
  }

  getSelectedTags(): Array<number> {
    const tags = this.tagsViewState;

    let selectedTags = [];

    tags.forEach((value, key) => {
      if (value.selected) {
        selectedTags.push(parseInt(key, 10));
      }
    });

    return selectedTags;
  }

  getFirstSelectedTag() {
    for (let j = 0; j < this.tagsView.length; j++) {
      if (this.tags[j].viewState.selected) return this.tags[j];
    }

    return null;
  }

  changeTagLabelText(e: Object) {
    this.tagLabel = e.target.value;
  }

  async getTags(pageQuery: PageQuery): Promise<PaginationInfo> {
    let params: string[] = pageQuery.params;
    let args = pageQuery.args;
    let variables = pageQuery.variables;
    let filtersCriteria: string[] = [];

    let sortCriteria: string[] = [];
    consolidateAppliedSorts(this.appliedSorts, sortCriteria);

    consolidateAppliedFilters(this.appliedFilters, params, filtersCriteria, variables);

    // NOTE: applied filters could override default filters, handle this if needed
    consolidateAppliedFilters(this.defaultFilters, params, filtersCriteria, variables);

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");
    const queryFiltersCriteria = filtersCriteria.join(", ");
    const querySortCriteria = sortCriteria.join(", ");

    const query = `
        query getTag(${queryParams}){
          viewer{
              user{
              firstName
              lastName
              userId
              username
              email
            }
            tags(${queryArgs}, filters: { ${queryFiltersCriteria} }, order: [${querySortCriteria}], section: ADMIN){
              totalCount
              edges{
                node{
                  name
                  created
                  tagId
                  owner{
                    firstName
                    lastName
                    email
                    userId
                  }
                  sharedWith{
                    firstName
                    lastName
                    email
                    userId
                  }
                }
              }
            }
          }
        }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting Rate Cards", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Rate Card query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getTags--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get Rate Cards query", res)) {
        // TODO: Display user friendly error message
        return {
          totalCount: 0,
          startCursor: "",
          endCursor: "",
        };
      }

      this.currentUser = new CurrentUser(this, res.data.viewer.user);

      // // $FlowFixMe: Type the res value once we abstract gql calls.
      const cards = res.data.viewer.tags.edges;

      this.tagCounts = res.data.viewer.tags.totalCount;
      // // TODO: Deserialize this properly...
      this.tags = cards.map((card) => {
        const tag = new TagModel(this, card.node);
        // rateCard.searches = card.node.searches.totalCount;
        // rateCard.locations = card.node.locations;
        if (!this.tagsViewState.has(tag.tagId)) {
          this.tagsViewState.set(tag.tagId, {
            selected: this.allSelected,
            editing: this.isEditing,
            expanded: false,
          });
        } else {
          const selectedValue = this.allSelected
            ? true
            : this.tagsViewState.get(tag.tagId).selected;

          this.tagsViewState.set(tag.tagId, {
            selected: selectedValue,
            editing: this.isEditing,
            expanded: false,
          });
        }

        tag.viewState = this.tagsViewState.get(tag.tagId);

        return tag;
      });

      return {
        totalCount: res.data.viewer.tags.totalCount,
      };
    });
  }
}

export default class TagManagementListStore extends TagManagementListComponentStore {
  exportTags: () => void;
  fetchAPI: FetchAPI;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI) {
    super(fetchGraphQL);
    this.fetchAPI = fetchAPI;

    // Bound early to pass into export state
    this.exportTags = action(this.exportTags.bind(this));
  }

  exportTags() {
    if (this.network.loading) {
      return;
    }

    let params = [];
    let args = [];
    let vars = {};
    let exportURL = "tags/basic/list/export/excel/";
    // Change the Filename
    var fileName = "export_tags_" + Math.floor(Math.random() * 9999999 + 1000000);
    let parameters = "";
    let exparams = "";

    if (this.allSelectedfilter) {
      consolidateAppliedFilters(this.appliedFilters, params, args, vars);
      vars.exclude = [];
      if (vars.nameFilterText) {
        vars.nameIContains = vars.nameFilterText;
        delete vars.nameFilterText;
      }
      parameters = JSON.stringify(vars);
      if (parameters === "") {
        exparams = `{"fileName": "${fileName}","section":"admin" ,"tagFilters": {${parameters}}}`;
      } else {
        exparams = `{"fileName": "${fileName}","section":"admin" , "tagFilters": ${parameters}}`;
      }
    } else {
      const selectedTags = this.getSelectedTags();
      if (!selectedTags || !selectedTags.length) {
        console.error("Cannot export Tags: No Tags selected");
        return;
      }
      vars.only = selectedTags;
      parameters = JSON.stringify(vars);
      if (parameters === "") {
        exparams = `{"fileName": "${fileName}","section":"admin" ,"tagFilters": {${parameters}}}`;
      } else {
        exparams = `{"fileName": "${fileName}","section":"admin" , "tagFilters": ${parameters}}`;
      }
    }

    this.fetchAPI(exportURL, JSON.parse(exparams))
      .then((res) => {
        window.location.href = res.data.url;

        this.handleStopEdit();
      })
      .catch((e) => {
        console.error("Error downloading excel", e);
        throw e;
      });
  }

  toDateRange(date) {
    var val = true;
    if (this.createdOnFilter.fromDate) {
      val = !date.isBetween(
        this.createdOnFilter.fromDate,
        moment(new Date()),
        "days",
        "[]"
      );
    }
    return val;
  }
}
