import React from "react";
import graphql from "babel-plugin-relay/macro";
import { usePaginationFragment, useFragment } from "react-relay";
import { useVirtualizer } from "@tanstack/react-virtual";

import { useSelectionMachine } from "../machines/selectionMachine";

import Box from "./lib/Box";
import Icon from "./lib/Icon";
import Inline from "./lib/Inline";
import Button from "./lib/Button";
import { TextOverflow } from "./lib/Text";
import { CardListItem, CardListItemLeft } from "./lib/Card";
import { Checkbox, CheckboxIndicator, LabelInline } from "./lib/Checkbox";
import { TickerContentLoader } from "./lib/TickerLoader";
import SearchBox from "./lib/SearchBox";

import { FilterDialog, FilterDialogProps } from "./FilterDialog";

import type { SelectionSet } from "../machines/selectionMachine";
import type {
  FilterByTagDialog_viewer$data,
  FilterByTagDialog_viewer$key,
} from "./__generated__/FilterByTagDialog_viewer.graphql";
import type { FilterByAuthorDialogPaginationQuery$variables } from "./__generated__/FilterByAuthorDialogPaginationQuery.graphql";

type TagEdges = NonNullable<FilterByTagDialog_viewer$data["tags"]>["edges"];

const tagsQueryFragment = graphql`
  fragment FilterByTagDialog_viewer on Viewer
  @refetchable(queryName: "FilterByTagDialogPaginationQuery")
  @argumentDefinitions(
    after: { type: "String" }
    before: { type: "String" }
    first: { type: "Int" }
    last: { type: "Int" }
    search: { type: "String" }
  ) {
    tags(
      after: $after
      before: $before
      first: $first
      last: $last
      search: $search
      contentType: RATE_CARD
    ) @connection(key: "FilterByTagDialog_tags") {
      edges {
        node {
          id
          name
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

type TagsVirtualListProps = {
  viewer: FilterByTagDialog_viewer$key;
  searchTerm: string;
  selectedTags: SelectionSet<string>;
  onCheckedChange: (checked: boolean, tagName: string) => void;
};

export function TagsVirtualList(props: TagsVirtualListProps) {
  const { searchTerm, selectedTags } = props;

  // data query

  const { data: viewer, refetch } = usePaginationFragment(
    tagsQueryFragment,
    props.viewer
  );
  const tagsEdges: TagEdges = viewer.tags?.edges ?? [];

  // effects

  const prevSearchTermRef = React.useRef<string>(searchTerm);

  React.useEffect(() => {
    if (searchTerm !== prevSearchTermRef.current) {
      prevSearchTermRef.current = searchTerm;

      const variables: FilterByAuthorDialogPaginationQuery$variables = {};
      if (!!searchTerm) {
        variables["search"] = searchTerm;
      }

      refetch(variables, { fetchPolicy: "network-only" });
    }
  }, [searchTerm, refetch]);

  // list virtualization

  const parentRef = React.useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtualizer<HTMLDivElement, HTMLDivElement>({
    count: tagsEdges.length,
    getScrollElement: () => parentRef.current!,
    estimateSize: React.useCallback(() => 57, []),
    overscan: 5,
  });

  return (
    <Box
      ref={parentRef}
      css={{
        height: "250px",
        width: "$full",
        overflow: "auto",
      }}
    >
      <Box
        css={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: "$full",
          position: "relative",
        }}
      >
        {/* @ts-ignore */}
        {rowVirtualizer.getVirtualItems().map((virtualItem) => {
          const tagNode = tagsEdges[virtualItem.index]!.node;
          const tagName = tagNode!.name;

          return (
            <Box
              key={virtualItem.key}
              css={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "$full",
                height: `${virtualItem.size}px`,
                transform: `translateY(${virtualItem.start}px)`,
              }}
            >
              <CardListItem css={{ padding: 0 }}>
                <CardListItemLeft>
                  <LabelInline nowrap css={{ padding: "20px", overflow: "hidden" }}>
                    <Checkbox
                      checked={selectedTags.has(tagName)}
                      onCheckedChange={(checked) => {
                        if (checked !== "indeterminate" && tagName != null) {
                          props.onCheckedChange(checked, tagName);
                        }
                      }}
                    >
                      <CheckboxIndicator>
                        <Icon icon="check" />
                      </CheckboxIndicator>
                    </Checkbox>
                    <TextOverflow title={tagName}>{tagName}</TextOverflow>
                  </LabelInline>
                </CardListItemLeft>
              </CardListItem>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

TagsVirtualList.displayName = "TagsVirtualList";

export type FilterByTagDialogProps = {
  title?: string;
  viewer: FilterByTagDialog_viewer$key;
  disableApply: boolean;
  // toggle style modifiers
  filtered?: boolean;
  // values
  searchTerm?: string;
  selectedTags: TagsVirtualListProps["selectedTags"];
  // event handlers
  onChangeSearchTerm: (value: string) => void;
  onCheckedChange: TagsVirtualListProps["onCheckedChange"];
  onSelectAll: React.MouseEventHandler<HTMLButtonElement>;
  onDeSelectAll: React.MouseEventHandler<HTMLButtonElement>;
  onApply: FilterDialogProps["onApply"];
  onClear: FilterDialogProps["onClear"];
};

export function FilterByTagDialog(props: FilterByTagDialogProps) {
  const {
    title = "Filter By Tags",
    disableApply = false,
    filtered,
    searchTerm = "",
    selectedTags,
    onChangeSearchTerm,
    onCheckedChange,
    onSelectAll,
    onDeSelectAll,
  } = props;

  // state

  const [searchBoxState, setSearchBoxState] = React.useState<string>(searchTerm);

  // effects

  const prevSerchTermRef = React.useRef<string>(searchTerm);
  React.useEffect(() => {
    if (searchTerm !== prevSerchTermRef.current) {
      prevSerchTermRef.current = searchTerm;

      // reset search box state in case somebody has clicked "Clear Filter" button
      if (!searchTerm && !!searchBoxState) {
        setSearchBoxState("");
      }
    }
  }, [searchTerm, searchBoxState]);

  // handlers

  const handleSearchBoxStateChange = React.useCallback(
    (value?: string) => {
      if (value !== searchBoxState) setSearchBoxState(value ?? "");
    },
    [searchBoxState]
  );

  const handleSearchBoxSubmit = React.useCallback(
    (value?: string) => {
      if (value !== searchBoxState) setSearchBoxState(value ?? "");
      if (value !== searchTerm) onChangeSearchTerm(value ?? "");
    },
    [searchBoxState, searchTerm, onChangeSearchTerm]
  );

  return (
    <FilterDialog
      title={title}
      description={
        <div>
          <p>Filter by Specific Tag:</p>
          <Inline css={{ justifyContent: "space-between" }}>
            <SearchBox
              value={searchBoxState}
              onChange={handleSearchBoxStateChange}
              onSubmit={handleSearchBoxSubmit}
              css={{ width: 180 }}
            />
            <Inline css={{ gap: "$2" }}>
              <Button onClick={onSelectAll}>Select All</Button>
              <Button onClick={onDeSelectAll}>Deselect All</Button>
            </Inline>
          </Inline>
        </div>
      }
      filtered={filtered}
      disableApply={disableApply}
      onApply={props.onApply}
      onClear={props.onClear}
    >
      <React.Suspense
        fallback={
          <Box css={{ height: "300px", position: "relative" }}>
            <TickerContentLoader />
          </Box>
        }
      >
        <TagsVirtualList
          viewer={props.viewer}
          searchTerm={searchTerm}
          selectedTags={selectedTags}
          onCheckedChange={onCheckedChange}
        />
      </React.Suspense>
    </FilterDialog>
  );
}

FilterByTagDialog.displayName = "FilterByTagDialog";

type FilterByTagInitialStateType = {
  searchTerm: string;
  selection?: SelectionSet<string>;
};

export function useFilterByTagDialogState(
  initialState: FilterByTagInitialStateType,
  viewer: FilterByTagDialog_viewer$key,
  machineName: string
) {
  const [searchTerm, setSearchTerm] = React.useState<string>(initialState.searchTerm);

  // retrieve fragment data from cache
  const data = useFragment(tagsQueryFragment, viewer);

  const getAllTagsNamesFromViewer = React.useCallback(() => {
    if (!data.tags?.edges) return [];

    return data.tags?.edges.reduce((acc, edge) => {
      if (edge?.node) {
        acc.push(edge?.node?.name);
      }
      return acc;
    }, [] as string[]);
  }, [data.tags]);

  const {
    selectionState,
    handleSelectAll,
    handleDeSelectAll,
    handleCheckedChange,
    // TODO use initial selection state to provide initial context to the state machine
  } = useSelectionMachine<string>(getAllTagsNamesFromViewer, machineName);

  const resetWholeState = React.useCallback(() => {
    setSearchTerm("");
    handleDeSelectAll();
  }, [handleDeSelectAll]);

  return {
    searchTerm,
    selectionState,
    handleSelectAllTags: handleSelectAll,
    handleDeSelectAllTags: handleDeSelectAll,
    handleTagCheckedChange: handleCheckedChange,
    handleTagSearchTermChange: setSearchTerm,
    handleFilterByTagDialogStateReset: resetWholeState,
  };
}
