// @flow
import React, { Component } from "react";
import { observer } from "mobx-react";
import LoadingIndicator from "../shared/LoadingIndicator";
import type MobXStore from "../../stores/mobx/MobXStore";
import { LoginBox } from "./LoginForm";
import Logo from "../../components/Logo";
import Inline from "../../components/lib/Inline";
import Stack from "../../components/lib/Stack";
import TextInput from "../../components/lib/TextInput";
import Button from "../../components/lib/Button";
import Spacer from "../../components/lib/Spacer";
import LinkButton from "../../components/lib/LinkButton";
import Box from "../../components/lib/Box";
import Alert from "../../components/lib/Alert";

type Props = {
  store: MobXStore,
};

type State = {
  value: string,
  showPasswordMismatchError: string | null,
};

class ResetPassword extends Component<Props, State> {
  matchPassWord: (Object) => void;

  constructor() {
    super();
    this.state = {
      value: "",
      showPasswordMismatchError: null,
    };
    this.matchPassWord = this.matchPassWord.bind(this);
  }

  componentDidMount() {
    this.props.store.dashboardStore.token = this.props.router.params.token;
    this.props.store.dashboardStore.network.error = null;
    this.props.store.dashboardStore.newPassword = "";
    this.props.store.dashboardStore.confirmPassword = "";
  }

  matchPassWord(e) {
    e.preventDefault();
    const store = this.props.store.dashboardStore;
    let newPwd = store.newPassword;
    let confirmPassword = store.confirmPassword;
    this.showPasswordMismatchError = null;
    if (newPwd === confirmPassword) {
      this.setState({ showPasswordMismatchError: null });
      store.resetPassword();
    } else {
      this.setState({ showPasswordMismatchError: "Passwords do not match" });
    }
  }

  render() {
    const store = this.props.store.dashboardStore;
    const network = store.networkResetPassword;

    return (
      <div className="login-page">
        <Inline
          className="login-header"
          css={{
            ".login-page &.login-header": {
              justifyContent: "center",
              paddingBottom: 60,
              height: 180,
              backgroundColor: "$secondary",
            },
          }}
        >
          <a className="logo" href="https://ratepoint.pro-unlimited.com/login">
            <Logo />
          </a>
        </Inline>
        {network.loading ? (
          <LoadingIndicator />
        ) : (
          <form onSubmit={this.matchPassWord}>
            <LoginBox className="box-login">
              <div className="box-header">
                <div className="box-header-bg" />
                <h1>Change Password</h1>
              </div>
              <Stack>
                <TextInput
                  fill
                  name="userpassword"
                  type="password"
                  placeholder="Enter Password"
                  value={store.newPassword}
                  onChange={store.changeNewPassword}
                />
                <TextInput
                  fill
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirm Password"
                  value={store.confirmPassword}
                  onChange={store.changeConfirmPassword}
                />
                {this.state.showPasswordMismatchError && (
                  <Box css={{ color: "$danger" }}>
                    {this.state.showPasswordMismatchError}
                  </Box>
                )}
                {store.networkResetPassword.error && !store.showResetSuccessMsg ? (
                  <Alert color="danger">{store.networkResetPassword.error.message}</Alert>
                ) : (
                  ""
                )}
                <Spacer css={{ height: 10, flex: "auto" }} />
                <Button type="submit" size="large" color="brand">
                  Reset Password
                </Button>
                {store.showResetSuccessMsg && (
                  <div className="alert alert-success fade in pt-user-alert">
                    <strong>Password Reset </strong>
                    <br />
                    <span>{store.showResetSuccessMsg} </span>
                    <Inline css={{ paddingTop: "$2" }}>
                      <LinkButton to="/login" color="accent">
                        Go to Login
                      </LinkButton>
                    </Inline>
                  </div>
                )}
              </Stack>
            </LoginBox>
          </form>
        )}
      </div>
    );
  }
}

export default observer(ResetPassword);
