// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import PceRateCardDetail from "./PceRateCardDetail";
import Pagination from "../../../ratecards/components/Pagination";
import LoadingIndicator from "../../../shared/LoadingIndicator";
import CreatedOnFilter from "../../../filters/CreatedOnFilter";
import CreatedByFilter from "../../../filters/CreatedByFilter";
import SortIndicator from "../../../ratecards/components/SortIndicator";
import { FILTER_COLUMN } from "../../../../models/Filter";
import SearchBox from "../../../ratecards/components/SearchBox";
import ToggleButton from "../../../ratecards/components/ToggleButton";
import { SingleDatePicker } from "../../../../components/SingleDatePicker";

const RateCardListings = observer(
  class extends Component {
    static contextTypes = {
      router: PropTypes.object,
    };

    constructor(props) {
      super(props);

      this.changeCurrent = this.changeCurrent.bind(this);
    }

    // Rate Cards pagination
    changeCurrent(value) {
      let store: RateCardListComponentStore = this.props.store;
      store.pagination.handleCurrentPage(value);
    }

    componentDidMount() {
      this.props.store.pagination.goFetch();
      this.props.store.clearFilters();
      this.props.store.updateRequestFrom(this.props.requestFrom);
    }

    render() {
      const rcstore = this.props.store;
      const pcestore = this.props.pcestore;

      const network = rcstore.network;
      const selected = this.props.selected || false;

      const rateCards = rcstore.rateCardsView;

      let tableContent = null;
      if (network.loading) {
        tableContent = <LoadingIndicator />;
      } else if (network.error) {
        tableContent = (
          <div className="pt-error-message">
            <h2>Error</h2>
            <pre>
              <code>{JSON.stringify(network.error, null, 2)}</code>
            </pre>
          </div>
        );
      } else if (rateCards.length === 0) {
        tableContent = (
          <div className="ratecards-table-empty">
            {Object.keys(rcstore.appliedFilters).length > 0 ? (
              <div>No Available Rate Cards</div>
            ) : (
              <div>No Rate Cards</div>
            )}
          </div>
        );
      } else {
        tableContent = rateCards.map((ratecard) => (
          <RateCardListsElements
            key={ratecard.ratecardId}
            selected={selected}
            expandable={pcestore.allowExpand}
            expanded={ratecard.viewState.expanded}
            onItemClick={(e) => pcestore.PCEtoggleExpand(ratecard, rateCards)}
            rateCards={rateCards}
            card={ratecard}
            pcestore={pcestore}
            createstore={this.props.createstore}
          />
        ));
      }

      const maximumDate = new Date();

      var fromDate = (
        <SingleDatePicker
          id="from_date"
          date={rcstore.createdOnFilter.fromDate}
          numberOfMonths={1}
          isOutsideRange={(day) => day.isAfter(maximumDate)}
          focused={rcstore.createdOnFilter.fromFocused}
          onDateChange={rcstore.createdOnFilter.fromDateChange}
          onFocusChange={rcstore.createdOnFilter.fromFocusedChange}
        />
      );

      var toDate = (
        <SingleDatePicker
          id="to_date"
          date={rcstore.createdOnFilter.toDate}
          numberOfMonths={1}
          isOutsideRange={(day) => rcstore.toDateRange(day)}
          focused={rcstore.createdOnFilter.toFocused}
          onDateChange={rcstore.createdOnFilter.toDateChange}
          onFocusChange={rcstore.createdOnFilter.toFocusedChange}
        />
      );

      return (
        <div className="ratecards-list">
          {rcstore.createdOnFilter && (
            <CreatedOnFilter
              createdOnFilter={rcstore.createdOnFilter}
              fromDate={fromDate}
              toDate={toDate}
            />
          )}
          {rcstore.createdByFilter && (
            <CreatedByFilter
              createdByFilter={rcstore.createdByFilter}
              network={rcstore.createdByFilter.network.loading}
              instantSearchValue={rcstore.createdByFilter.instantSearchValue}
              onInstantSearch={rcstore.createdByFilter.onInstantSearch}
            />
          )}
          <div className="rc-container bring-forward">
            <div className="header-action-bar">
              <div className="pull-left">
                <SearchBox
                  value={rcstore.rateCardLabelFilter.textToLookFor}
                  onChange={rcstore.rateCardLabelFilter.onTextToLookForChange}
                  onSearch={rcstore.rateCardLabelFilter.onApply}
                  style={{
                    width: "260px",
                  }}
                />
              </div>
              <div>
                <header
                  className={classNames("ratecards-table-header", {
                    active: rcstore.isFiltered,
                  })}
                >
                  <nav className="header-right-nav">
                    {rcstore.isFiltered && (
                      <div className="btn btn-yellow" onClick={rcstore.clearFilters}>
                        Clear All Filters & Sorts
                      </div>
                    )}
                    {/*rcstore.createdByFilter && <ToggleButton borderless={true} name="rate-cards-filter" value={FILTER_COLUMN.CREATED_BY} selected={Boolean(rcstore.appliedFilters[FILTER_COLUMN.CREATED_BY])} onClick={rcstore.createdByFilter.onShowModal}>
                    <SortIndicator sortColumn={FILTER_COLUMN.CREATED_BY} sortsOrder={rcstore.appliedSortsOrder} sort={rcstore.appliedSorts[FILTER_COLUMN.CREATED_BY]}/>
                    Created by
                    <span> ▼</span>
                  </ToggleButton>*/}
                    {rcstore.createdOnFilter && (
                      <ToggleButton
                        borderless={true}
                        name="rate-cards-filter"
                        value={FILTER_COLUMN.DATE_RANGE}
                        selected={Boolean(
                          rcstore.appliedFilters[FILTER_COLUMN.DATE_RANGE]
                        )}
                        onClick={rcstore.createdOnFilter.onShowModal}
                      >
                        <SortIndicator
                          sortType="numeric"
                          sortColumn={FILTER_COLUMN.DATE_RANGE}
                          sortsOrder={rcstore.appliedSortsOrder}
                          sort={rcstore.appliedSorts[FILTER_COLUMN.DATE_RANGE]}
                        />
                        Created on
                        <span> ▼</span>
                      </ToggleButton>
                    )}
                  </nav>
                </header>
              </div>
            </div>
            {tableContent}
            {rcstore.pagination.pageCount > 0 ? (
              <Pagination
                pageCount={rcstore.pagination.pageCount}
                pagesToShow={rcstore.pagination.maxPagesToShow}
                currentPage={rcstore.pagination.currentPage}
                begin={rcstore.pagination.begin}
                end={rcstore.pagination.end}
                isEndRange={rcstore.pagination.isEndRange}
                handleCurrentPage={this.changeCurrent}
                firstPage={rcstore.pagination.firstPage}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      );
    }
  }
);

const RateCardListsElements = (props) => {
  const selected = props.selected || false;
  const store = props.pcestore;
  const card = props.card;
  const expanded = props.expanded; // Used if the view(Ratecard Row) is expanded or not
  let onItemClick = props.onItemClick;
  const expandable = props.expandable; // Used if the view(Ratecard Row) is expandable or not
  const name = card.name;
  const createdDisplay = card.createdDate.format("MMMM D, YYYY");
  const ownerName = card.owner.fullname;
  const id = card.ratecardId;

  return (
    <div className="search-list-item-container">
      <div
        className={classNames("search-list-item", { selected: selected })}
        onClick={onItemClick}
      >
        <div className="search-list-item-left ellipsis">
          {expandable && (
            <div className="search-expand-state">
              <span className={classNames("pt-ico-moon", { expanded: expanded })}></span>
            </div>
          )}
          <div className="ellipsis">
            <div className="search-label ellipsis">{name}</div>
            <div className="search-title ellipsis">
              <span className="item-subtext-muted">Created by </span>
              <span className="item-subtext-accent">{ownerName} </span>
              <span className="item-subtext-muted">on </span>
              <span className="item-subtext-accent">{createdDisplay}</span>
            </div>
          </div>
        </div>
      </div>
      {expandable && (
        <PceRateCardDetail
          expanded={expanded}
          store={store}
          createstore={props.createstore}
          ratecardId={id}
        />
      )}
    </div>
  );
};

RateCardListings.propTypes = {
  onClick: PropTypes.func,
};

export default RateCardListings;
