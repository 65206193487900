import React from "react";

import Stack from "../../../../components/lib/Stack";
import TextArea from "../../../../components/lib/TextArea";
import Icon from "../../../../components/lib/Icon";
import Button from "../../../../components/lib/Button";
import { ButtonGroup } from "../../../../components/lib/ButtonGroup";
import { styled } from "../../../../stitches.config";

const CustomButton = styled(Button, {
  width: "50%",
  borderRadius: 0,
  "&:focus": {
    boxShadow: "none",
    outline: "none",
  },
});

const CustomTextArea = styled(TextArea, {
  height: "141px",
  border: "none",
  resize: "none",
});

type RatesCommentProps = {
  comment?: string | null;
  placeholder?: string;
  disabled?: boolean;
  onChange: (value: string | null) => void;
  onSave: () => void;
  onCancel: () => void;
};

function RatesComment(props: RatesCommentProps) {
  const { comment, placeholder, disabled = false, onSave, onChange, onCancel } = props;

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value || null),
    [onChange]
  );

  return (
    <Stack fill nogap>
      <CustomTextArea
        fill
        size="small"
        value={comment ?? ""}
        onChange={handleChange}
        placeholder={placeholder}
        disabled={disabled}
      />
      <ButtonGroup nogap fill>
        <CustomButton color="success" size="normal" variant="light" onClick={onSave}>
          <Icon icon="check" /> Save
        </CustomButton>
        <CustomButton color="danger" size="normal" variant="light" onClick={onCancel}>
          <Icon icon="close" /> Cancel
        </CustomButton>
      </ButtonGroup>
    </Stack>
  );
}

export default RatesComment;
