// @flow

import React, { Component } from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBox from "../../ratecards/components/SearchBox";
import RateCardsList from "./components/RateCardsList";
import Modal from "../../ratecards/components/Modal";
import classNames from "classnames";
import ExportModal from "../../ratecards/components/ExportModal";
import type MobXStore from "../../../stores/mobx/MobXStore";
import LoadingIndicator from "../../shared/LoadingIndicator";
import SelectableItemsList from "../../../components/SelectableItemsList";
import SelectableItem from "../../ratecards/components/SelectableItem";

type Props = {
  store: MobXStore,
};

class List extends Component<Props, null> {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.store.rateCardListPTStore.updateRequestFrom("ratecard");
    this.props.store.rateCardListPTStore.pagination.goFetch();

    // reset to null so some elements visible while editing start hidden
    if (!this.props.store.rateCardListPTStore.isTagView) {
      this.props.store.rateCardListPTStore.isEditing = null;
    }
    // clean rate card details view state
    this.props.store.rateCardDetailPTStore.rateCardId = null;
    this.props.store.rateCardDetailPTStore.rateCard = null;
  }

  render() {
    const store = this.props.store.rateCardListPTStore;

    return (
      <div className="view list">
        <div className="rc-container bring-forward">
          <div className="header-action-bar">
            <h2>Rate Cards Admin</h2>
            <p className="subtext">Admin area for managing ratecards</p>
            <div className="clearfix" />
          </div>
          <div className="header-action-bar marginTopzero">
            <div className="pull-left">
              <SearchBox
                value={store.rateCardLabelFilter.textToLookFor}
                onChange={store.rateCardLabelFilter.onTextToLookForChange}
                onSearch={store.rateCardLabelFilter.onApply}
                style={{ width: "260px" }}
              />
            </div>
            {!store.isTagView && (
              <div className="btn-group pull-right rc-center" style={{ height: 40 }}>
                {store.isEditing && (
                  <button className="btn btn-yellow" onClick={store.handleStopEdit}>
                    Stop Editing
                  </button>
                )}
                {!store.isEditing && (
                  <div>
                    <button className="btn btn-green" onClick={store.handleStartEdit}>
                      <FontAwesomeIcon icon="edit" fixedWidth className="icon" /> Edit
                    </button>
                    <button className="btn" onClick={store.helpModal.showModal}>
                      <FontAwesomeIcon
                        icon="question-circle"
                        fixedWidth
                        className="icon"
                      />{" "}
                      Help
                    </button>
                  </div>
                )}
              </div>
            )}
            <div className="clearfix" />
          </div>
          <RateCardsList store={store} />
        </div>
        {!store.isTagView && (
          <div
            className={classNames("ratecards-edit-actions", {
              hidden: store.isEditing === null,
              "bottom-appear": store.isEditing === true,
              "bottom-disappear": store.isEditing === false,
            })}
          >
            <button
              className="btn"
              disabled={store.selectedCount === 0}
              onClick={store.selectUserForRateCardsMove}
            >
              <FontAwesomeIcon icon="people-arrows" fixedWidth className="icon" /> Move to
              User
            </button>
            {store.currentUser &&
              store.currentUser.permissions.allowExportRateCardsData && (
                <button
                  className="btn"
                  disabled={store.selectedCount === 0}
                  onClick={store.exportRateCards}
                >
                  <FontAwesomeIcon icon="download" fixedWidth className="icon" /> Export
                </button>
              )}
          </div>
        )}
        {!store.isTagView && (
          <div
            className={classNames("rc-container-backdrop", {
              hidden: store.isEditing === null,
              "fade-in": store.isEditing === true,
              "fade-out": store.isEditing === false,
            })}
          />
        )}

        <Modal show={store.helpModal.show} onHide={store.helpModal.hideModal}>
          <div className="container-section header">
            <h4>Help</h4>
          </div>
          <div className="container-section footer">
            <p>Here you can see all of your Rate Cards...</p>
          </div>
        </Modal>

        <Modal show={store.moveRateCardsModal.show} onHide={store.cancelRateCardsMove}>
          <div className="container-section header">
            <h4>Move Rate Cards to User</h4>
          </div>
          {!store.moveRateCardsError && (
            <div className="container-section">
              <div className="alert alert-info no-margin">
                <p className="bold">Keep in mind</p>
                <p className="no-margin">
                  This is not checking if the user you're moving the rate cards to has
                  access to the locations of the searches being moved. You can select a
                  user from any client. The system will move each rate card along with all
                  its searches and copy any tags, categories or jobs that the new owner
                  doesn't already have.
                </p>
              </div>
            </div>
          )}
          {store.moveRateCardsError && (
            <div className="container-section">
              <div className="alert alert-danger no-margin">
                {store.moveRateCardsError}
              </div>
            </div>
          )}
          <div className="container-section">
            <div style={{ marginBottom: 20 }}>
              <SearchBox
                placeholder="Search users"
                onSearch={store.onUserSearchSubmit}
                onChange={store.onUserSearchValueChange}
                value={store.userSearch}
                disabled={store.moveRateCardsNetwork.loading}
              />
            </div>
            <div style={{ minWidth: 680 }}>
              {store.moveRateCardsNetwork.loading ? (
                <LoadingIndicator loadingMessage={store.moveRateCardsLoadingMessage} />
              ) : store.users.length > 0 ? (
                <SelectableItemsList className="overlap-tb-padding">
                  {store.users.map((item) => (
                    <SelectableItem
                      type="radio"
                      key={item.userId}
                      item={item}
                      name="location-filter"
                      selected={item.userId === store.selectedUserId}
                      onChange={(e) => {
                        store.selectedUserId = item.userId;
                      }}
                    >
                      <div>
                        {[item.firstName, item.lastName]
                          .filter((p) => Boolean(p))
                          .join(" ")
                          .trim() || "NO NAME"}
                      </div>
                      <div className="text-x-small">
                        <FontAwesomeIcon
                          icon="user"
                          className="icon"
                          style={{ marginRight: 5 }}
                        />
                        {item.username}
                      </div>
                    </SelectableItem>
                  ))}
                </SelectableItemsList>
              ) : (
                <div className="ratecards-list-select-all-section">
                  <span className="text-center">No users found.</span>
                </div>
              )}
            </div>
            <div className="selectable-list-pagination-container">
              <button
                className="pagination-button"
                disabled={store.usersPagination.currentPage <= 1}
                onClick={() => store.usersPagination.goFetch(1)}
              >
                <FontAwesomeIcon icon="angle-double-left" style={{ marginRight: 5 }} />{" "}
                First
              </button>
              <button
                className="pagination-button"
                disabled={store.usersPagination.currentPage <= 1}
                onClick={() =>
                  store.usersPagination.goFetch(store.usersPagination.currentPage - 1)
                }
              >
                <FontAwesomeIcon icon="angle-left" style={{ marginRight: 5 }} /> Prev
              </button>
              <div className="pagination-info">
                {store.usersPagination.currentPage} / {store.usersPagination.pageCount}
              </div>
              <button
                className="pagination-button"
                disabled={
                  store.usersPagination.currentPage >= store.usersPagination.pageCount
                }
                onClick={() =>
                  store.usersPagination.goFetch(store.usersPagination.currentPage + 1)
                }
              >
                Next <FontAwesomeIcon icon="angle-right" style={{ marginLeft: 5 }} />
              </button>
              <button
                className="pagination-button"
                disabled={
                  store.usersPagination.currentPage >= store.usersPagination.pageCount
                }
                onClick={() =>
                  store.usersPagination.goFetch(store.usersPagination.pageCount)
                }
              >
                Last{" "}
                <FontAwesomeIcon icon="angle-double-right" style={{ marginLeft: 5 }} />
              </button>
            </div>
          </div>
          <div className="container-section footer">
            <div className="pull-right">
              <button
                className="btn btn-lg btn-default"
                onClick={store.cancelRateCardsMove}
              >
                Cancel
              </button>
              <button
                className="btn btn-lg btn-green"
                onClick={store.moveRateCardsToSelectedUser}
                disabled={store.selectedUserId <= 0}
              >
                Move
              </button>
            </div>
          </div>
        </Modal>

        <ExportModal exportState={store.exportModal} />
      </div>
    );
  }
}

export default observer(List);
