/**
 * @generated SignedSource<<faf0fe84e89c1a6407c179f9eef9fbf8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkerTypeField_viewer$data = {
  readonly workerTypes: ReadonlyArray<{
    readonly description: string | null;
    readonly name: string | null;
    readonly workerTypeId: number | null;
  } | null> | null;
  readonly " $fragmentType": "WorkerTypeField_viewer";
};
export type WorkerTypeField_viewer$key = {
  readonly " $data"?: WorkerTypeField_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerTypeField_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": 0,
      "kind": "LocalArgument",
      "name": "countryId"
    },
    {
      "defaultValue": 0,
      "kind": "LocalArgument",
      "name": "industryId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "viewer"
      ],
      "operation": require('./WorkerTypeFieldRefetchQuery.graphql')
    }
  },
  "name": "WorkerTypeField_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "countryId",
          "variableName": "countryId"
        },
        {
          "kind": "Variable",
          "name": "industryId",
          "variableName": "industryId"
        }
      ],
      "concreteType": "CountryWorkerType",
      "kind": "LinkedField",
      "name": "workerTypes",
      "plural": true,
      "selections": [
        {
          "alias": "workerTypeId",
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "7212d83a7eb511ddcacfe56978c68d92";

export default node;
