import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import { reactSelectStyles, reactSelectTheme } from "../../constants/reactSelect";
import Enum from "../../utils/enumerations";

export const SKILLS_LEVELS = new Enum(
  ["JUNIOR", "Junior", 0],
  ["INTERMEDIATE", "Intermediate", 1],
  ["SENIOR", "Senior", 2],
  ["LEAD", "Lead", 3],
  ["GURU", "Guru", 4]
);

export const SKILLS_LEVELS_ROMAN = new Enum(
  ["JUNIOR", "Level I", 0],
  ["INTERMEDIATE", "Level II", 1],
  ["SENIOR", "Level III", 2],
  ["LEAD", "Level IV", 3],
  ["GURU", "Level V", 4]
);

export const SKILLS_LEVELS_NUMERAL = new Enum(
  ["JUNIOR", "Level 1", 0],
  ["INTERMEDIATE", "Level 2", 1],
  ["SENIOR", "Level 3", 2],
  ["LEAD", "Level 4", 3],
  ["GURU", "Level 5", 4]
);

const ExpLevelSelect = (props) => {
  const {
    options,
    value,
    onChange,
    name,
    placeholder,
    disabled,
    multi,
    searchable,
    className,
    styles,
    theme,
  } = props;

  return (
    <Select
      className={className}
      options={options}
      value={value}
      onChange={onChange}
      name={name}
      placeholder={placeholder}
      isDisabled={disabled}
      isMulti={multi}
      isSearchable={searchable}
      styles={styles}
      theme={theme}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.title}
    />
  );
};
ExpLevelSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    })
  ),
  value: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  }),
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  multi: PropTypes.bool,
  searchable: PropTypes.bool,
  className: PropTypes.string,
  styles: PropTypes.object,
  theme: PropTypes.func,
};
ExpLevelSelect.defaultProps = {
  options: SKILLS_LEVELS_ROMAN.options("id", "title", "id"),
  placeholder: "Select expertise level...",
  disabled: false,
  multi: false,
  searchable: false,
  styles: reactSelectStyles,
  theme: reactSelectTheme,
};

export default ExpLevelSelect;
