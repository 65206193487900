/**
 * @generated SignedSource<<9204e2f0a5ef9848d88e7f7b4203fe86>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminSearches_viewer$data = {
  readonly savedsearches: {
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
      readonly node: {
        readonly id: string;
        readonly searchId: number | null;
        readonly " $fragmentSpreads": FragmentRefs<"AdminSearchesSearch_search">;
      } | null;
    } | null>;
    readonly pageInfo: {
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
    readonly totalCount: number | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"FilterAndSortClients_viewer" | "FilterAndSortCreatedBy2_viewer" | "FilterAndSortTags_viewer">;
  readonly " $fragmentType": "AdminSearches_viewer";
};
export type AdminSearches_viewer$key = {
  readonly " $data"?: AdminSearches_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminSearches_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "filters"
    },
    {
      "defaultValue": [
        {
          "direction": "DESC",
          "field": "CREATE_DATE"
        }
      ],
      "kind": "LocalArgument",
      "name": "order"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "savedsearches"
        ]
      }
    ]
  },
  "name": "AdminSearches_viewer",
  "selections": [
    {
      "alias": "savedsearches",
      "args": [
        {
          "kind": "Variable",
          "name": "filters",
          "variableName": "filters"
        }
      ],
      "concreteType": "SaveSearchNodeConnection",
      "kind": "LinkedField",
      "name": "__AdminSearches_savedsearches_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasPreviousPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SaveSearchNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "SaveSearchNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "searchId",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AdminSearchesSearch_search"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FilterAndSortClients_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FilterAndSortCreatedBy2_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FilterAndSortTags_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "aa1fc332836d341ba96f6f6b5be6f7c6";

export default node;
