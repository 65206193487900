// @flow

import React, { Component } from "react";
import type { Element } from "react";
import { Alert, OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react";
import {
  BatchSearchPSPCreateStore,
  BatchSearchJobListItem,
} from "../../../stores/mobx/BatchSearchCreateStore";
import type { WorkerType } from "../../../stores/mobx/BatchSearchCreateStore";
import InfiniteScrollSelect from "../../../components/selects/InfiniteScrollSelect";
import type { InfiniteScrollSelectProps } from "../../../components/selects/InfiniteScrollSelect";
import { LOCATION_TYPE } from "../../../models/LocationList";
import FlagIcon from "../../../components/FlagIcon";
import Industry from "../../../models/Industry";
import RegionList from "../../../models/RegionList";
import RawJobTitle from "../../../models/RawJobTitle";
import BatchSearchLocationSelect from "./BatchSearchLocationSelect";
import BatchSearchTagSelect from "./BatchSearchTagSelect";
import OldCheckBox from "../../shared/CheckBox";
import Box from "../../../components/lib/Box";
import TextInput from "../../../components/lib/TextInput";
import { Link } from "../../../components/lib/Link";
import TextArea from "../../../components/lib/TextArea";
import Button from "../../../components/lib/Button";
import Inline, { InlineElements } from "../../../components/lib/Inline";
import Spacer from "../../../components/lib/Spacer";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from "../../../components/lib/DropdownMenu";
import { Table, TBody, TD, TH, THead, TR } from "../../../components/lib/Table";
import { Checkbox, CheckboxIndicator } from "../../../components/lib/Checkbox";
import Tooltip from "../../../components/lib/Tooltip";
// import Location from "../../../models/LocationList";
// import LocationList from "../../../models/LocationList";
// import LinearVerticalWizard, { VerticalWizardStep } from "../../../components/wizards/LinearVerticalWizard";
// import { WizardState } from "../../../components/wizards/Wizard";

// const LocationInfiniteScrollSelect = (props: InfiniteScrollSelectProps<LocationList> & { country?: Location }) => {
//   const { country } = props;
//   const _renderLocationWithCountryIcon = (
//     location: Location,
//     countryCode: string = "",
//     term?: ?string = null,
//     highlight?: ?(string, string) => Element<any> = null
//   ): Element<any> => {
//     let title = term && highlight ? highlight(location.toString(), term) : location.toString();
//     if (term && location.highlight) {
//       title = (
//         <span>
//           <span
//             dangerouslySetInnerHTML={{
//               __html: location.highlight.title
//             }}
//           />{" "}
//           {location.highlight && location.highlight.subtitle !== "" && (
//             <span
//               dangerouslySetInnerHTML={{
//                 __html: "(" + location.highlight.subtitle + ")"
//               }}
//               className="item-subtext-muted text-xx-small"
//             />
//           )}
//         </span>
//       );
//     }
//
//     return (
//       <span className="no-wrap flex">
//         {countryCode && <FlagIcon code={countryCode} />} {title}
//       </span>
//     );
//   };
//
//   const renderLocationItem = (
//     location: Location,
//     term?: ?string = null,
//     highlight?: ?(string, string) => Element<any> = null
//   ): Element<any> => {
//     const countryCode = country?.countryCode || location.countryCode;
//     return _renderLocationWithCountryIcon(location, countryCode, term, highlight);
//   };
//
//   const renderSelectedLocation = (
//     location: Location,
//     term?: ?string = null,
//     highlight?: ?(string, string) => Element<any> = null
//   ): Element<any> => {
//     const countryCode = country?.countryCode || location.countryCode;
//     return _renderLocationWithCountryIcon(location, countryCode);
//   };
//
//   return <InfiniteScrollSelect renderItem={renderLocationItem} renderSelection={renderSelectedLocation} {...props} />;
// };

const RegionInfiniteScrollSelect = (props: InfiniteScrollSelectProps<RegionList>) => (
  <InfiniteScrollSelect {...props} />
);
const IndustryInfiniteScrollSelect = (props: InfiniteScrollSelectProps<Industry>) => (
  <InfiniteScrollSelect {...props} />
);
const WorkerTypeInfiniteScrollSelect = (props: InfiniteScrollSelectProps<WorkerType>) => (
  <InfiniteScrollSelect {...props} />
);
const RawJobTitleInfiniteScrollSelect = (
  props: InfiniteScrollSelectProps<RawJobTitle>
) => {
  const renderTitleItem = (
    rawJobTitle: RawJobTitle,
    term?: ?string = null,
    highlight?: ?(string, string) => Element<any> = null
  ): Element<any> => {
    // let title = term && highlight ? highlight(location.toString(), term) : location.toString();
    // if (location.highlight) {
    //   title = (
    //     <span>
    //       <span
    //         dangerouslySetInnerHTML={{
    //           __html: location.highlight.title.replace(/u>/g, "mark>")
    //         }}
    //       />{" "}
    //       {location.highlight && location.highlight.subtitle !== "" && (
    //         <span
    //           dangerouslySetInnerHTML={{
    //             __html: "(" + location.highlight.subtitle.replace(/u>/g, "mark>") + ")"
    //           }}
    //           className="item-subtext-muted text-xx-small"
    //         />
    //       )}
    //     </span>
    //   );
    // }

    const sharedInfo = rawJobTitle.sharedInfo;
    return (
      <span className="no-wrap flex" style={{ alignItems: "baseline", gap: ".375em" }}>
        {rawJobTitle.title}
        <span style={{ flex: "1 1 0%" }} />
        {rawJobTitle.showDescription && (
          <div className="rs-tag rs-tag-no-opacity">Has Description</div>
        )}
        {/*{sharedInfo?.searchOnly && <div className="rs-tag rs-tag-no-opacity">Search Only</div>}*/}
        {!sharedInfo?.isMine && sharedInfo?.sharedBy && (
          <div className="rs-tag rs-tag-no-opacity">
            Shared by: {sharedInfo.sharedBy.fullName()}
          </div>
        )}
        {rawJobTitle.isJobLabel && (
          <div className="rs-tag rs-tag-no-opacity rs-tag-yellow">via My Job Labels</div>
        )}
        {!rawJobTitle.isJobLabel && (
          <div className="rs-tag rs-tag-no-opacity rs-tag-blue">Stock Title</div>
        )}
        {/*<span className="item-subtext-muted text-xx-small">*/}
        {/*  {rawJobTitle.isJobLabel ? "(Job Label)" : "(stock title)"}*/}
        {/*</span>*/}
      </span>
    );
  };

  return <InfiniteScrollSelect renderItem={renderTitleItem} {...props} />;
};

type Props = {
  store: BatchSearchPSPCreateStore,
};

class WizardStep02TitlesAndLocations extends Component<Props> {
  handleAddJobButtonClick: () => void;
  handleAddMoreButtonClick: () => void;
  handleAddMoreClearButtonClick: () => void;
  handleLocationTypeChange: (number) => void;
  handleRemoveJobButtonClick: (BatchSearchJobListItem) => void;
  handleEditJobButtonClick: (BatchSearchJobListItem) => void;
  handleItemSelectedChange: (BatchSearchJobListItem) => void;
  handleKeyPress: (Event) => void;
  handleViewAddedClick: (Event) => void;

  constructor(props: Props) {
    super(props);

    this.handleLocationTypeChange = this.handleLocationTypeChange.bind(this);
    this.handleAddJobButtonClick = this.handleAddJobButtonClick.bind(this);
    this.handleAddMoreButtonClick = this.handleAddMoreButtonClick.bind(this);
    this.handleAddMoreClearButtonClick = this.handleAddMoreClearButtonClick.bind(this);
    this.handleEditJobButtonClick = this.handleEditJobButtonClick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleViewAddedClick = this.handleViewAddedClick.bind(this);
    this.handleItemSelectedChange = this.handleItemSelectedChange.bind(this);
  }

  componentDidMount() {
    // this.props.store.getUserCountries();
    this.props.store.getIndustries();
    this.props.store.getRemainingSearches();
  }

  handleKeyPress(e: KeyboardEvent) {
    if (e.key.toLowerCase() === "h" && e.ctrlKey && e.shiftKey) {
      this.props.store.showDescriptions = !this.props.store.showDescriptions;
      console.log("show descriptions:", this.props.store.showDescriptions);
    }
  }

  handleViewAddedClick(e: Event) {
    this.props.store.showAddJobTitlesWizard = false;
  }

  UNSAFE_componentWillMount() {
    window.addEventListener("keypress", this.handleKeyPress);
  }

  componentWillUnmount() {
    window.removeEventListener("keypress", this.handleKeyPress);
  }

  handleAddJobButtonClick(): void {
    const { store } = this.props;
    store.addJobToBatchSearch();
  }

  handleAddMoreButtonClick(): void {
    const { store } = this.props;
    store.addMoreJobs();
  }

  handleAddMoreClearButtonClick(): void {
    const { store } = this.props;
    store.addMoreJobs(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  handleLocationTypeChange(value: number): void {
    this.props.store.onLocationTypeChange(value);

    if (value === LOCATION_TYPE.REGION) {
      this.props.store.getRegions();
    }
  }

  handleRemoveJobButtonClick(listItem: BatchSearchJobListItem): void {
    this.props.store.removeJobFromSearch(listItem);
  }

  handleEditJobButtonClick(listItem: BatchSearchJobListItem): void {
    this.props.store.editJobToSearch(listItem);
  }

  handleItemSelectedChange(listItem: BatchSearchJobListItem) {
    listItem.setSelected(!listItem.viewState.selected);
  }

  handleSelectAllJobsOnClick = () => {
    this.props.store.jobsToSearchItemsToggleAllSelected();
  };

  renderBatchSearchJobItem(item: BatchSearchJobListItem): Element<any> {
    const store = this.props.store;
    return (
      <TR key={item.id} selected={item.viewState.selected}>
        <TD css={{ width: 30 }} onClick={this.handleItemSelectedChange.bind(this, item)}>
          <Checkbox checked={item.viewState.selected}>
            <CheckboxIndicator>
              <FontAwesomeIcon icon="check" />
            </CheckboxIndicator>
          </Checkbox>
          {/*<CheckBox onClick={this.handleItemSelectedChange} item={item} selected={item.viewState.selected} />*/}
        </TD>
        <TD onClick={this.handleItemSelectedChange.bind(this, item)}>{item.titleId}</TD>
        <TD onClick={this.handleItemSelectedChange.bind(this, item)}>{item.title}</TD>
        <TD onClick={this.handleItemSelectedChange.bind(this, item)}>
          {item.searchedTitle}
        </TD>
        <TD onClick={this.handleItemSelectedChange.bind(this, item)}>{item.industry}</TD>
        <TD onClick={this.handleItemSelectedChange.bind(this, item)}>
          {item.countryCode && <FlagIcon code={item.countryCode} />}
          {item.location}
        </TD>
        {store.showWorkerTypeColumn && (
          <TD onClick={this.handleItemSelectedChange.bind(this, item)}>
            {item.workerType}
          </TD>
        )}
        {store.showTagsColumn && (
          <TD
            onClick={this.handleItemSelectedChange.bind(this, item)}
            className="ellipsis"
            css={{ maxWidth: 150 }}
            title={item.tags}
          >
            {item.tags}
          </TD>
        )}
        {store.showGSSColumn && (
          <TD
            className="text-center"
            onClick={this.handleItemSelectedChange.bind(this, item)}
          >
            {item.isGSS && <FontAwesomeIcon icon="check" />}
          </TD>
        )}
        <TD className="text-center options" style={{ width: 70 }}>
          <InlineElements css={{ justifyItems: "center", justifyContent: "center" }}>
            <Tooltip side="top" id="gss-tooltip" content="Edit">
              <Box css={{ color: "$brand" }}>
                <FontAwesomeIcon
                  icon="edit"
                  onClick={this.handleEditJobButtonClick.bind(this, item)}
                />
              </Box>
            </Tooltip>
            <Tooltip side="top" id="gss-tooltip" content="Remove">
              <Box css={{ color: "$danger" }}>
                <FontAwesomeIcon
                  icon="trash"
                  onClick={this.handleRemoveJobButtonClick.bind(this, item)}
                />
              </Box>
            </Tooltip>
          </InlineElements>
        </TD>
      </TR>
    );
  }

  _renderGSSPopover = () => {
    return (
      <Popover
        className="pt-ui-popover"
        id="popover-positioned-top"
        title="Global Supplier Search"
      >
        <p className="text-xx-small">
          <strong>Important Information</strong>
        </p>
        <p className="text-xx-small">
          Select this if you would like results that are intended for a larger percentage
          of data from Global suppliers.
        </p>
      </Popover>
    );
  };

  _renderGSSTooltipIcon = () => {
    return (
      <OverlayTrigger placement="top" overlay={this._renderGSSPopover()}>
        <FontAwesomeIcon
          icon="question-circle"
          className="icon"
          style={{ marginLeft: ".2em" }}
        />
      </OverlayTrigger>
    );
  };

  // _renderFilterPopOver = () => {
  //   return (
  //     <Popover id="filters-popover" title="Popover bottom">
  //       <strong>Holy guacamole!</strong> Check this info.
  //     </Popover>
  //   );
  // };

  render() {
    const store = this.props.store;

    // const selectedUserCountry = store.selectedUserCountry;
    const canContinueWithLastAddedTitles = store.jobsToSearch.length > 0;
    // const showTitles = store.addTitleWizard.currentStep >= 3 && store.selectedUserCountry && store.jobTitles.length > 0;
    // const searchTitlesEnabled = store.addTitleWizard.currentStep >= 3 && store.selectedUserCountry;
    // const showNoTitlesFound =
    //   store.addTitleWizard.currentStep === 3 && store.selectedUserCountry && store.jobTitles.length === 0;
    // const showNoTitlesAvailableButLocationRequired =
    //   store.addTitleWizard.currentStep === 2 && store.selectedUserCountry && store.jobTitles.length === 0;
    // const showTitlesAvailableButLocationRequired =
    //   store.addTitleWizard.currentStep === 2 && store.selectedUserCountry && store.jobTitles.length > 0;
    const canAddSelectedTitles =
      !store.loadingForEdit &&
      !store.showItemExists &&
      (store.selectedLocations.length > 0 || store.selectedRegion) &&
      store.selectedIndustry &&
      store.selectedJobTitle &&
      (store.selectedJobTitle.isJobLabel ||
        (store.jobLabelValue && store.jobLabelIsUnique));

    const showLabelIsValid =
      !store.checkLabelNetwork.loading &&
      !store.selectedJobTitle?.isJobLabel &&
      !store.jobLabelHelperText &&
      store.jobLabelValue;
    const showLabelError =
      !store.checkLabelNetwork.loading &&
      !store.selectedJobTitle?.isJobLabel &&
      store.jobLabelHelperText;
    const showCheckingLabel =
      store.checkLabelNetwork.loading && !store.selectedJobTitle?.isJobLabel;
    return (
      <div style={{ minHeight: 310, maxWidth: 1200, margin: "auto" }}>
        <div className="form-horizontal pt-ss-title-form">
          <div className="form-group">
            <label
              className="rc-control-label control-label col-xs-12 col-sm-3"
              style={{ paddingTop: 10, paddingBottom: 10, fontSize: 18, fontWeight: 600 }}
            >
              Select Location
            </label>
            <div className="col-xs-12 col-sm-6">
              <Box
                className="rc-checkbox-group form-horizontal"
                css={{
                  ".pt-ui & label": {
                    fontSize: "$lg",
                    paddingTop: 10,
                  },
                }}
              >
                <OldCheckBox
                  style={{ width: 150 }}
                  type="radio"
                  name="locationType"
                  value={LOCATION_TYPE.LOCATION}
                  selected={store.locationType === LOCATION_TYPE.LOCATION}
                  onChange={store.onLocationTypeChange}
                >
                  Locations
                </OldCheckBox>
                <OldCheckBox
                  style={{ width: 150 }}
                  type="radio"
                  name="locationType"
                  value={LOCATION_TYPE.REGION}
                  selected={store.locationType === LOCATION_TYPE.REGION}
                  onChange={store.onLocationTypeChange}
                >
                  Regions
                </OldCheckBox>
              </Box>
              {store.locationType === LOCATION_TYPE.LOCATION && (
                <div style={{ display: "flex", flexFlow: "column", gap: 15 }}>
                  <BatchSearchLocationSelect
                    getLocations={store.getLocationsWithSearch}
                    onChange={store.onSelectedLocationsChange}
                    locations={store.selectedLocations}
                    onMenuClose={() => {
                      store.locationSearch = "";
                    }}
                  />
                </div>
              )}
              {store.locationType === LOCATION_TYPE.REGION && (
                <RegionInfiniteScrollSelect
                  // disabled={!store.selectedUserCountry && store.addTitleWizard.currentStep >= 2}
                  items={store.regions}
                  selectedItem={store.selectedRegion}
                  inputValue={store.regionSearch}
                  onInputValueChange={store.onRegionSearchChange}
                  onSelectedItemChange={store.onSelectedRegionChange}
                  disabled={store.loadingForEdit}
                />
              )}
              {store.loadingForEdit && (
                <div className="control-info-text text-warning fade-pulse">
                  Loading...
                </div>
              )}
              {store.showGSSOption && (
                <Box
                  className="control-info-text"
                  css={{
                    ".pt-ui & label": {
                      fontSize: "$lg",
                      paddingTop: 10,
                    },
                  }}
                >
                  <OldCheckBox
                    type="checkbox"
                    name="iss"
                    selected={store.isGSS}
                    onChange={store.onIsGSSChange}
                  >
                    Global Supplier Search {this._renderGSSTooltipIcon()}
                  </OldCheckBox>
                </Box>
              )}
            </div>
          </div>

          <div className="form-group">
            <label
              className="rc-control-label control-label col-xs-12 col-sm-3"
              style={{ paddingTop: 10, paddingBottom: 10, fontSize: 18, fontWeight: 600 }}
            >
              Select Industry
            </label>
            <div className="col-xs-12 col-sm-6">
              <IndustryInfiniteScrollSelect
                name="industries"
                id="industriesInfiniteScrollSelect"
                // disabled={!store.selectedUserCountry && store.addTitleWizard.currentStep >= 2}
                items={store.industries}
                selectedItem={store.selectedIndustry}
                inputValue={store.industrySearch}
                onInputValueChange={store.onIndustrySearchChange}
                onSelectedItemChange={store.onSelectedIndustryChange}
                disabled={store.loadingForEdit}
              />
            </div>
          </div>

          {store.showWorkerTypes && (
            <div className="form-group">
              <label
                className="rc-control-label control-label col-xs-12 col-sm-3"
                style={{
                  paddingTop: 2,
                  paddingBottom: 2,
                  fontSize: 18,
                  fontWeight: 600,
                  lineHeight: "1.125em",
                }}
              >
                Select Worker Type <br />
                <span className="text-muted text-x-small">(Optional)</span>
              </label>
              <div className="col-xs-12 col-sm-6">
                <WorkerTypeInfiniteScrollSelect
                  name="workerTypes"
                  clearable
                  showSearchInput={false}
                  disabled={!store.showWorkerTypes || store.loadingForEdit}
                  items={store.workerTypes}
                  selectedItem={store.selectedWorkerType}
                  onSelectedItemChange={store.onSelectedWorkerTypeChange}
                />
                {store.loadingForEdit && (
                  <div className="control-info-text text-warning fade-pulse">
                    Loading...
                  </div>
                )}
                {store.selectedWorkerType && (
                  <div className="control-info-text" style={{ width: 400 }}>
                    <strong>Worker Type Description: </strong>{" "}
                    {store.selectedWorkerType.description}
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="form-group">
            <label
              className="rc-control-label control-label col-xs-12 col-sm-3"
              style={{ paddingTop: 10, paddingBottom: 10, fontSize: 18, fontWeight: 600 }}
            >
              Select Job Title
            </label>
            <div className="col-xs-12 col-sm-6">
              <RawJobTitleInfiniteScrollSelect
                name="rawJobTitles"
                id="jobTitleInfiniteScrollSelect"
                items={store.jobTitles}
                onSelectedItemChange={store.onSelectedJobTitleChange}
                selectedItem={store.selectedJobTitle}
                loading={store.jobTitlesNetwork.loading}
                hasMoreItems={store.jobTitlesPagination.hasNextPage}
                loadMoreItems={store.debouncedJobTitlesNextPage}
                inputValue={store.jobTitleSearch}
                onInputValueChange={store.onJobTitleSearchChange}
                clearSearchOnSelectItem={false}
                disabled={
                  (!store.selectedLocations.length === 0 && !store.selectedRegion) ||
                  !store.selectedIndustry ||
                  store.loadingForEdit
                }
              />
              {store.loadingForEdit && (
                <div className="control-info-text text-warning fade-pulse">
                  Loading...
                </div>
              )}
              {store.selectedJobTitle?.isJobLabel && (
                <div className="control-info-text flex flex-align-baseline">
                  <FontAwesomeIcon icon="info-circle" className="icon" />
                  <div>
                    Reminder: Your job selection is from your{" "}
                    <Link to="/job-labels" style={{ cursor: "pointer" }}>
                      <strong>Job Labels</strong>
                    </Link>{" "}
                    and cannot be edited here. If your job selection is either identified
                    as from <strong>Job Labels</strong> or <strong>Shared By</strong> and
                    you change the Industry selection, the previously saved rate results
                    will be overwritten.
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="form-group">
            <label
              className="rc-control-label control-label col-xs-12 col-sm-3"
              style={{
                paddingTop: 2,
                paddingBottom: 2,
                fontSize: 18,
                fontWeight: 600,
                lineHeight: "1.125em",
              }}
            >
              Add Tags <br />
              <span className="text-muted text-x-small">(Optional)</span>
            </label>
            <div className="col-xs-12 col-sm-6">
              <BatchSearchTagSelect
                getTags={store.getTagsWithSearch}
                onChange={store.onSelectedTagsChange}
                selectedTags={store.selectedTags}
                onCreate={store.createTag}
                onMenuClose={() => {
                  store.tagSearch = "";
                }}
                disabled={
                  store.jobTitlesNetwork.loading ||
                  !store.selectedJobTitle ||
                  store.loadingForEdit
                }
              />
            </div>
          </div>

          <div className="form-group">
            <label
              className="rc-control-label control-label col-xs-12 col-sm-3"
              style={{ paddingTop: 10, paddingBottom: 10, fontSize: 18, fontWeight: 600 }}
            >
              Enter Job Label
            </label>
            <div className="col-xs-12 col-sm-6">
              <TextInput
                fill
                value={store.jobLabelValue}
                data-testid="job-label"
                onChange={(e) => store.onJobLabelChange(e.currentTarget.value)}
                placeholder="Job Label"
                disabled={
                  store.jobTitlesNetwork.loading ||
                  !store.selectedJobTitle ||
                  store.selectedJobTitle.isJobLabel ||
                  store.loadingForEdit
                }
              />
              {store.loadingForEdit && (
                <div className="control-info-text text-warning fade-pulse">
                  Loading...
                </div>
              )}
              {showCheckingLabel && (
                <div className="control-info-text text-warning">
                  Checking Job Label...
                </div>
              )}
              {showLabelError && (
                <div className="control-info-text text-danger">
                  {store.jobLabelHelperText}
                </div>
              )}
              {showLabelIsValid && (
                <div className="control-info-text text-success">Job Label is valid!</div>
              )}
            </div>
          </div>

          <div className="form-group">
            <label
              className="rc-control-label control-label col-xs-12 col-sm-3"
              style={{ paddingTop: 10, paddingBottom: 10, fontSize: 18, fontWeight: 600 }}
            >
              Enter Job Description
            </label>
            <div className="col-xs-12 col-sm-6">
              <TextArea
                fill
                value={store.jobDescriptionValue}
                onChange={(e) => store.onJobDescriptionChange(e.currentTarget.value)}
                placeholder={
                  store.jobDescriptionNetwork.loading ? "Loading..." : "Job Description"
                }
                rows={10}
                disabled={
                  store.jobTitlesNetwork.loading ||
                  !store.selectedJobTitle ||
                  store.selectedJobTitle.isJobLabel ||
                  store.loadingForEdit
                }
                css={{
                  resize: "vertical",
                }}
              />
              {store.loadingForEdit && (
                <div className="control-info-text text-warning fade-pulse">
                  Loading...
                </div>
              )}
            </div>
          </div>

          <div className="form-group">
            <label
              className="rc-control-label control-label col-xs-12 col-sm-3"
              style={{ paddingTop: 10, paddingBottom: 10, fontSize: 18, fontWeight: 600 }}
            />
            <div className="col-xs-12 col-sm-6">
              <Inline>
                <Button
                  icon="plus"
                  color={canAddSelectedTitles ? "brand" : "primary"}
                  onClick={this.handleAddJobButtonClick}
                  disabled={!canAddSelectedTitles}
                >
                  Add Search Item
                </Button>
                <label className="rc-control-label">
                  <em>
                    You've added <strong>{store.jobsToSearchItemsTotalCount}</strong>{" "}
                    {store.jobsToSearchItemsTotalCount === 1
                      ? "Search item "
                      : "Search items "}
                    so far{" "}
                    {!store.isUnlimited && (
                      <span>
                        (<strong>{store.remainingSearchesAfterRun}</strong> Remaining)
                      </span>
                    )}
                  </em>
                </label>
              </Inline>
              <div className="flex" style={{ gap: 10, minHeight: 24 }}>
                {store.showItemAdded && (
                  <div className="control-info-text text-success fade-in-wait-fade-out">
                    <FontAwesomeIcon icon="check" className="icon" /> {store.itemsAdded}{" "}
                    Search {store.itemsAdded === 1 ? "item" : "items"} added.
                  </div>
                )}
                {store.showItemExists && (
                  <div className="control-info-text text-danger fade-in-wait-fade-out">
                    <FontAwesomeIcon icon="times" className="icon" /> {store.itemsExist}{" "}
                    Search {store.itemsExist === 1 ? "item" : "items"} already exist.
                  </div>
                )}
              </div>
            </div>
          </div>

          <div style={{ maxWidth: 1200, margin: "30px auto 0 auto" }}>
            <div className="form">
              {store.jobsToSearch.length === 0 && (
                <Alert bsStyle="warning">
                  <h4 style={{ marginBottom: 15 }}>No searches have been added yet.</h4>
                </Alert>
              )}
              {store.jobsToSearch.length > 0 && (
                <div>
                  <div className="form-group">
                    <InlineElements css={{ paddingBottom: "$2" }}>
                      <div>
                        <label
                          className="control-label rc-control-label"
                          style={{ marginBottom: 10 }}
                        >
                          <em>
                            {store.jobsToSearchItemsTotalCount}{" "}
                            {store.jobsToSearchItemsTotalCount === 1
                              ? "Search item "
                              : "Search items "}
                            added so far{" "}
                            {!store.isUnlimited
                              ? `(${store.remainingSearchesAfterRun} Remaining)`
                              : ""}
                          </em>
                        </label>
                      </div>
                      <Spacer />
                      {store.jobsToSearchAppliedFilters.length > 0 && (
                        <Button
                          color="accent"
                          icon="close"
                          onClick={store.clearJobsToSearchFilters}
                        >
                          Clear Filters
                        </Button>
                      )}
                      <Button
                        icon="filter"
                        onClick={store.jobsToSearchFiltersModal.showModal}
                      >
                        Filters
                        {store.jobsToSearchAppliedFilters.length > 0 && (
                          <span>{`(${store.jobsToSearchItemsView.length} Match${
                            store.jobsToSearchItemsView.length === 1 ? "" : "es"
                          })`}</span>
                        )}
                      </Button>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button
                            id="batch-search-job-options-dropdown"
                            icon="ellipsis-v"
                            text="More Options"
                          />
                        </DropdownMenuTrigger>
                        <DropdownMenuContent side="bottom">
                          {canContinueWithLastAddedTitles && (
                            <DropdownMenuItem
                              onClick={this.handleAddMoreClearButtonClick}
                            >
                              <div style={{ textAlign: "left" }}>
                                Start from the top
                                <div className="control-info-text text-thin text-xx-small">
                                  <em>
                                    Select new Country, Location, Industry
                                    <br />
                                    and Title
                                  </em>
                                </div>
                              </div>
                            </DropdownMenuItem>
                          )}
                          {Boolean(
                            store.jobsToSearchItemsSelectedCount > 0 ||
                              store.jobsToSearchItemsSelectedCount > 0
                          ) && <DropdownMenuSeparator />}
                          {store.jobsToSearchItemsSelectedCount > 0 && (
                            <DropdownMenuItem
                              onClick={store.bulkChangeIndustryModal.showModal}
                            >
                              Change Industry For Selected Items
                            </DropdownMenuItem>
                          )}
                          {store.jobsToSearchItemsSelectedCount > 0 && (
                            <DropdownMenuItem
                              onClick={store.bulkRemoveJobsModal.showModal}
                            >
                              Remove Selected Items
                            </DropdownMenuItem>
                          )}
                          <DropdownMenuSeparator />
                          <InlineElements
                            css={{
                              color: "$text",
                              alignItems: "baseline",
                              flexWrap: "nowrap",
                              padding: "$1",
                              fontSize: "$sm",
                              lineHeight: "$sm",
                            }}
                          >
                            <Box css={{ paddingTop: "$1" }}>
                              <FontAwesomeIcon
                                icon="question-circle"
                                style={{ marginTop: 3 }}
                              />
                            </Box>
                            <Box>
                              Select the edit{" "}
                              <Box
                                as="span"
                                css={{
                                  color: "$brand",
                                  paddingTop: "$1",
                                  paddingLeft: "$1",
                                }}
                              >
                                <FontAwesomeIcon icon="edit" />
                              </Box>{" "}
                              option to add additional <br />
                              locations to the job role selected.
                            </Box>
                          </InlineElements>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </InlineElements>
                    <Table>
                      <THead>
                        <TR>
                          <TH
                            style={{ width: 30 }}
                            onClick={this.handleSelectAllJobsOnClick}
                          >
                            <Checkbox checked={store.jobsToSearchItemsAllSelected}>
                              <CheckboxIndicator>
                                <FontAwesomeIcon icon="check" />
                              </CheckboxIndicator>
                            </Checkbox>
                          </TH>
                          <TH>ID</TH>
                          <TH>Job Label</TH>
                          <TH>Job Title</TH>
                          <TH>Industry</TH>
                          <TH>Location</TH>
                          {store.showWorkerTypeColumn && <TH>Worker Type</TH>}
                          {store.showTagsColumn && <TH>Tags</TH>}
                          {store.showGSSColumn && (
                            <TH style={{ width: 64 }}>
                              GSS {this._renderGSSTooltipIcon()}
                            </TH>
                          )}
                          <TH style={{ width: 70 }}>Actions</TH>
                        </TR>
                      </THead>
                      <TBody>
                        {store.jobsToSearchItemsView.map(
                          (job: BatchSearchJobListItem) => {
                            return this.renderBatchSearchJobItem(job);
                          }
                        )}
                      </TBody>
                    </Table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(WizardStep02TitlesAndLocations);
