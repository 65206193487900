// @flow
import * as React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react";
import Button from "../components/lib/Button";
import Modal from "./ratecards/components/Modal";
import ContainerSection from "./ratecards/components/ContainerSection";
import LoadingIndicator from "./shared/LoadingIndicator";
import type { FetchGraphQL, UpdateLoginState } from "../App";
import Inline from "../components/lib/Inline";
import TextInput from "../components/lib/TextInput";
import LinkButton from "../components/lib/LinkButton";

const UserDetail = observer((props) => {
  const store = props.store;

  let owner = null;
  let country;

  if (store.network.loading) {
    owner = "...";
  } else if (store.userDetail) {
    owner = `${store.userDetail.firstName} ${store.userDetail.lastName}`;
    country = store.userDetail.country;
  }

  var actionBar = (
    <nav className="action-bar">
      <Button icon="edit" onClick={store.resetPasswordModal.showModal}>
        Change Password
      </Button>
    </nav>
  );

  return (
    <div className="top">
      <div className="above">
        <div className="ratecard-label-header ellipsis max-width-80">
          <h2 className="ellipsis" data-testid="username-heading">
            {owner}
          </h2>
        </div>
        <Inline css={{ gap: "$1", alignItems: "flex-start" }}>
          <LinkButton to="/" color="brand" icon="arrow-left">
            Go Back
          </LinkButton>
          {/* <Button onClick={store.showHelp} icon="question-circle">
            Help
          </Button> */}
        </Inline>
      </div>
      <div className="below">
        {country && (
          <div className="ratecard-meta ellipsis">
            <div
              className="ratecard-created-at ellipsis"
              title={country}
              data-testid="country-heading"
            >
              <FontAwesomeIcon icon={"map-pin"} fixedWidth className="icon" />
              {country}
            </div>
          </div>
        )}
        {actionBar}
      </div>
    </div>
  );
});

type Props = {
  fetchGraphQL: FetchGraphQL,
  updateLoginState: UpdateLoginState,
};

class Account extends React.Component<Props> {
  static contextTypes = {
    router: PropTypes.object,
  };

  clickDone: () => void;

  constructor(props: Props) {
    super(props);

    this.clickDone = this.clickDone.bind(this);
  }
  /**
   * componentDidMount
   * @return {void}
   */
  UNSAFE_componentWillMount() {
    const store = this.props.store.accountDetailStore;
    store.messaging.removeAll();
    store.lastNameMessage.removeAll();
    store.primaryEmailMessage.removeAll();
    store.secondaryEmailMessage.removeAll();
    store.resetPasswordMessage.removeAll();
    store.getUserDetails();
    store.password = "";
    store.newPassword = "";
    store.resetPasswordModal.hideModal();
  }

  componentWillUnmount() {
    this.props.store.accountDetailStore.isEditing = false;
  }

  clickDone() {
    this.props
      .fetchGraphQL("mutation logout{ logoutUser{ ok } }", null)
      .then((res) => {
        this.props.updateLoginState(false, null, null);
      })
      .catch((e) => {
        console.error("Error logging user out", e);
        this.props.updateLoginState(false, null, null);
      });
  }

  /**
   * Render account detail page
   * @return {XML}
   */
  render() {
    const store = this.props.store.accountDetailStore;
    let tableContent = null;
    const network = store.network;

    if (network.loading) {
      tableContent = <LoadingIndicator />;
    } else {
      tableContent = (
        <div>
          <div className="rc-container show-overflow">
            <UserDetail store={store} router={this.props.router} />
          </div>
          <div className="rc-container bring-forward">
            <div className="header-action-bar">
              <span>Profile</span>
              {!store.network.loading && !store.network.error && (
                <div className="btn-group pull-right">
                  {store.isEditing && (
                    <Button color="accent" onClick={store.stopEditing}>
                      Stop Editing
                    </Button>
                  )}
                  {!store.isEditing && (
                    <Button color="brand" icon="edit" onClick={store.startEditing}>
                      Edit
                    </Button>
                  )}
                </div>
              )}
              <div className="clearfix" />
            </div>
            <div className="row userjob">
              <div className="col-sm-4 col-md-4 col-lg-4">
                <div className="form-group">
                  <label>First Name</label>
                  <div className="input_group">
                    <TextInput
                      maxLength="30"
                      placeholder="First Name"
                      min="0"
                      fill
                      disabled={!store.isEditing}
                      value={store.firstName ?? ""}
                      onChange={store.setFirstName}
                    />
                    {store.messaging.messages.length > 0 && (
                      <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                        <a
                          href="#close"
                          className="close"
                          data-dismiss="alert"
                          aria-label="close"
                          onClick={store.messaging.removeMessage.bind(
                            store,
                            store.messaging.messages[0]
                          )}
                        >
                          &times;
                        </a>
                        <strong>{store.messaging.messages[0].messageLabel}</strong>
                        {store.messaging.messages[0].message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4">
                <div className="form-group">
                  <label>Middle Name</label>
                  <div className="input_group">
                    <TextInput
                      fill
                      min="0"
                      disabled={!store.isEditing}
                      placeholder="Middle Name"
                      maxLength="30"
                      value={store.middleName ?? ""}
                      onChange={store.setMiddleName}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4">
                <div className="form-group">
                  <label>Last Name</label>
                  <div className="input_group">
                    <TextInput
                      fill
                      maxLength="3"
                      placeholder="Last Name"
                      min="0"
                      disabled={!store.isEditing}
                      value={store.lastName ?? ""}
                      onChange={store.setLastName}
                    />
                    {store.lastNameMessage.messages.length > 0 && (
                      <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                        <a
                          href="#close"
                          className="close"
                          data-dismiss="alert"
                          aria-label="close"
                          onClick={store.lastNameMessage.removeMessage.bind(
                            store,
                            store.lastNameMessage.messages[0]
                          )}
                        >
                          &times;
                        </a>
                        <strong>{store.lastNameMessage.messages[0].messageLabel}</strong>
                        {store.lastNameMessage.messages[0].message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row userjob">
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <label>Email</label>
                  <div className="input_group">
                    <TextInput
                      fill
                      min="0"
                      disabled={!store.isEditing}
                      value={store.email ?? ""}
                      onChange={store.setEmail}
                    />
                    {store.primaryEmailMessage.messages.length > 0 && (
                      <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                        <a
                          href="#close"
                          className="close"
                          data-dismiss="alert"
                          aria-label="close"
                          onClick={store.primaryEmailMessage.removeMessage.bind(
                            store,
                            store.primaryEmailMessage.messages[0]
                          )}
                        >
                          &times;
                        </a>
                        <strong>
                          {store.primaryEmailMessage.messages[0].messageLabel}
                        </strong>
                        {store.primaryEmailMessage.messages[0].message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <label>Alternate Email</label>
                  <div className="input_group">
                    <TextInput
                      fill
                      placeholder="Enter a second Email address(optional)"
                      value={store.secondaryEmail ?? ""}
                      maxLength="250"
                      onChange={store.setSecondaryEmail}
                      disabled={!store.isEditing}
                    />
                    {store.secondaryEmailMessage.messages.length > 0 && (
                      <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                        <a
                          href="#close"
                          className="close"
                          data-dismiss="alert"
                          aria-label="close"
                          onClick={store.secondaryEmailMessage.removeMessage.bind(
                            store,
                            store.secondaryEmailMessage.messages[0]
                          )}
                        >
                          &times;
                        </a>
                        <strong>
                          {store.secondaryEmailMessage.messages[0].messageLabel}
                        </strong>
                        {store.secondaryEmailMessage.messages[0].message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row userjob">
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <label>Phone Number</label>
                  <TextInput
                    fill
                    icon="plus"
                    disabled={!store.isEditing}
                    value={store.phoneNumber ?? ""}
                    onChange={store.setPhoneNumber}
                  />
                  {store.phoneMessage.messages.length > 0 && (
                    <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                      <a
                        href="#close"
                        className="close"
                        data-dismiss="alert"
                        aria-label="close"
                        onClick={store.phoneMessage.removeMessage.bind(
                          store,
                          store.phoneMessage.messages[0]
                        )}
                      >
                        &times;
                      </a>
                      <strong>{store.phoneMessage.messages[0].messageLabel}</strong>
                      {store.phoneMessage.messages[0].message}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <label>Alternate Phone Number</label>
                  <TextInput
                    fill
                    icon="plus"
                    disabled={!store.isEditing}
                    value={store.alterPhoneNumber ?? ""}
                    onChange={store.setAlterPhoneNumber}
                  />
                  {store.alterPhoneMessage.messages.length > 0 && (
                    <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                      <a
                        href="#close"
                        className="close"
                        data-dismiss="alert"
                        aria-label="close"
                        onClick={store.alterPhoneMessage.removeMessage.bind(
                          store,
                          store.alterPhoneMessage.messages[0]
                        )}
                      >
                        &times;
                      </a>
                      <strong>{store.alterPhoneMessage.messages[0].messageLabel}</strong>
                      {store.alterPhoneMessage.messages[0].message}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="row userjob">
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <label>Main Address</label>
                  <div className="input_group">
                    <TextInput
                      fill
                      disabled={!store.isEditing}
                      value={store.address ?? ""}
                      onChange={store.setAddress}
                      placeholder="Main Address"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <label>Additional Address</label>
                  <div className="input_group">
                    <TextInput
                      fill
                      disabled={!store.isEditing}
                      value={store.apartment ?? ""}
                      onChange={store.setApartment}
                      placeholder="Additional Address"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row userjob">
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <label>City</label>
                  <div className="input_group">
                    <TextInput
                      fill
                      disabled={!store.isEditing}
                      value={store.city ?? ""}
                      onChange={store.setCity}
                      placeholder="Enter City"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <label>State</label>
                  <div className="input_group">
                    <TextInput
                      fill
                      disabled={!store.isEditing}
                      value={store.state ?? ""}
                      onChange={store.setState}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row userjob">
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <label>Country</label>
                  <div className="input_group">
                    <TextInput
                      fill
                      disabled={!store.isEditing}
                      value={store.country ?? ""}
                      onChange={store.setCountry}
                      placeholder="Enter Country"
                      data-testid="user-country"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <label>Postal Code</label>
                  <div className="input_group">
                    <TextInput
                      fill
                      disabled={!store.isEditing}
                      value={store.postalCode ?? ""}
                      onChange={store.setPostalCode}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row userjob">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="form-group">
                  <label>Country Access</label>
                  <div className="input_group">
                    <p>{store.allCountries}</p>
                  </div>
                </div>
              </div>
            </div>

            {store.isEditing && (
              <ContainerSection className="footer">
                <div className="pull-right">
                  <Button
                    color="brand"
                    size="large"
                    onClick={(e) => {
                      store.saveEditedAccountDetail();
                    }}
                  >
                    Update
                  </Button>
                </div>
              </ContainerSection>
            )}
          </div>
        </div>
      );
    }

    return (
      <div className="view">
        {tableContent}
        <Modal
          show={store.resetPasswordModal.show}
          onHide={store.resetPasswordModal.hideModal}
        >
          <ContainerSection className="header">
            <h4>Change Password</h4>
          </ContainerSection>
          <ContainerSection>
            <div className="form-group">
              <label>Enter Current Password:</label>
              <TextInput
                fill
                type={store.oldPasswordView ? "text" : "password"}
                value={store.password}
                onChange={store.setPassword}
              />
              {store.oldPasswordView && (
                <FontAwesomeIcon
                  icon="eye-slash"
                  className="ps"
                  onClick={store.changeOldPasswordView}
                />
              )}
              {!store.oldPasswordView && (
                <FontAwesomeIcon
                  icon="eye"
                  className="ps"
                  onClick={store.changeOldPasswordView}
                />
              )}
            </div>
            <div className="form-group">
              <label>Enter New Password:</label>
              <TextInput
                fill
                type={store.newPasswordView ? "text" : "password"}
                value={store.newPassword}
                onChange={store.changeNewPassword}
                data-testid="user-new-password"
              />
              {store.newPasswordView && (
                <FontAwesomeIcon
                  icon="eye-slash"
                  className="ps"
                  onClick={store.changeNewPasswordView}
                />
              )}
              {!store.newPasswordView && (
                <FontAwesomeIcon
                  icon="eye"
                  className="ps"
                  onClick={store.changeNewPasswordView}
                />
              )}
            </div>
            <Button color="brand" onClick={store.generatePassword}>
              Generate Password
            </Button>
            <div className="pt-user-messages">
              {store.resetPasswordMessage.messages.length > 0 && (
                <div
                  id="error_msg"
                  className="alert alert-danger alert-dismissable fade in pt-user-alert"
                >
                  <a
                    href="#close"
                    className="close"
                    data-dismiss="alert"
                    aria-label="close"
                    onClick={store.resetPasswordMessage.removeMessage.bind(
                      store,
                      store.resetPasswordMessage.messages[0]
                    )}
                  >
                    &times;
                  </a>
                  <strong>{store.resetPasswordMessage.messages[0].messageLabel}</strong>
                  {store.resetPasswordMessage.messages[0].message}
                </div>
              )}
              {store.resetPasswordSuccessMsg && (
                <div
                  id="alert_msg"
                  className="alert alert-success alert-dismissable fade in pt-user-alert"
                >
                  {store.resetPasswordSuccessMsg}
                </div>
              )}
            </div>
          </ContainerSection>
          <ContainerSection className="footer">
            <Inline css={{ justifyContent: "flex-end" }}>
              {!store.passwordChangeSuccess && (
                <Button onClick={store.closeResetModal}>Cancel</Button>
              )}
              {!store.passwordChangeSuccess && (
                <Button color="brand" onClick={store.resetUserPassword}>
                  Change Password
                </Button>
              )}
              {store.passwordChangeSuccess && (
                <Button color="brand" onClick={this.clickDone}>
                  Done
                </Button>
              )}
            </Inline>
          </ContainerSection>
        </Modal>
      </div>
    );
  }
}

export default observer(Account);
