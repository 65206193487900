// @flow

import { extendObservable, action, runInAction } from "mobx";
import NetworkState from "../../../models/NetworkState";
import MessageState from "../../../models/MessageState";
import ClientList from "../../../models/ClientList";
import type { FetchGraphQL } from "../../../App";
import randPasswordStr from "../../../utils/generatePassword";

type CountryItem = { title: string, locationId: number };
type CountryOption = { value: number, label: string };

class UserCreatePTStore {
  router: Object;
  network: NetworkState;
  clientDetail: ClientList;
  loginMessage: MessageState;
  passwordMessage: MessageState;
  firstNameMessage: MessageState;
  lastNameMessage: MessageState;
  primaryEmailMessage: MessageState;
  secondaryEmailMessage: MessageState;
  primaryPhoneMessage: MessageState;
  secondaryPhoneMessage: MessageState;
  clientNameMessage: MessageState;
  messaging: MessageState;
  multiCountryMessage: MessageState;
  rolesMessage: MessageState;

  loginName: string;
  password: string;
  newClientName: string;
  firstName: string;
  middleName: string;
  lastName: string;
  primaryEmail: string;
  secondaryEmail: string;
  primaryPhone: string;
  secondaryPhone: string;
  mainAddress: string;
  additionalAddress: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  shareRatecardsFlag: boolean;
  allowedCountries: Array<any>;
  selectedLocations: Array<any>;
  selectedRoles: Array<any>;
  selectedClient: { clientId: number, title: string } | null;
  selected: boolean;
  activeFlag: boolean;
  countryOptions: Array<CountryOption>;

  createUser: () => Promise<any>;
  changeLoginName: (Object) => void;
  changePassword: (Object) => void;
  changePrimaryEmail: (Object) => void;
  changeFirstNameText: (Object) => void;
  changeLastNameText: (Object) => void;
  setShareRatecardsFlag: (string) => void;
  ClientText: (string) => Promise<{
    clients: Array<{ node: { clientId: number, name: string } }>,
  }>;
  getAllRoles: () => void;
  changeClientText: (Object) => void;
  changeCountryText: (Object) => void;
  stopCreatingUser: (boolean) => void;
  changeSecondaryEmail: (Object) => void;
  changePrimaryPhone: (Object) => void;
  changeSecondaryPhone: (Object) => void;
  changeMainAddress: (Object) => void;
  changeAdditionalAddress: (Object) => void;
  changeCity: (Object) => void;
  changeState: (Object) => void;
  changeCountry: (CountryOption) => void;
  changePostalCode: (Object) => void;
  selectAllCountries: () => void;
  generatePassword: () => void;
  changeMiddleNameText: () => void;
  clearAllErrorMessages: () => void;
  fetchGraphQL: FetchGraphQL;
  getDefaultCountries: (clientId: number) => Promise<Array<CountryItem>>;
  getCountryOptions: () => Promise<void>;
  selectedCountryOption: ?CountryOption;

  constructor(fetchGraphQL: FetchGraphQL) {
    this.fetchGraphQL = fetchGraphQL;
    this.router = null;

    extendObservable(this, {
      clientId: null,
      network: new NetworkState(),
      renameNetwork: new NetworkState(),
      shareRatecardsFlag: true,
      loginName: "",
      password: "",
      firstName: "",
      middleName: "",
      lastName: "",
      primaryEmail: "",
      secondaryEmail: "",
      primaryPhone: "",
      secondaryPhone: "",
      mainAddress: "",
      additionalAddress: "",
      allowedCountries: [],
      city: "",
      state: "",
      country: "",
      postalCode: "",
      selectedLocations: [],
      selectedRoles: [],
      selectedClient: null,
      selected: false,
      loginMessage: new MessageState(),
      passwordMessage: new MessageState(),
      firstNameMessage: new MessageState(),
      lastNameMessage: new MessageState(),
      primaryEmailMessage: new MessageState(),
      secondaryEmailMessage: new MessageState(),
      primaryPhoneMessage: new MessageState(),
      secondaryPhoneMessage: new MessageState(),
      clientNameMessage: new MessageState(),
      messaging: new MessageState(),
      multiCountryMessage: new MessageState(),
      rolesMessage: new MessageState(),
      countryOptions: [],
      selectedCountryOption: null,
    });

    this.createUser = action(this.createUser.bind(this));
    this.changeLoginName = action(this.changeLoginName.bind(this));
    this.changePassword = action(this.changePassword.bind(this));
    this.changePrimaryEmail = action(this.changePrimaryEmail.bind(this));
    this.changeFirstNameText = action(this.changeFirstNameText.bind(this));
    this.changeLastNameText = action(this.changeLastNameText.bind(this));
    this.setShareRatecardsFlag = action(this.setShareRatecardsFlag.bind(this));
    this.ClientText = action(this.ClientText.bind(this));
    this.getAllRoles = action(this.getAllRoles.bind(this));
    this.changeClientText = action(this.changeClientText.bind(this));
    this.changeCountryText = action(this.changeCountryText.bind(this));

    this.changeSecondaryEmail = action(this.changeSecondaryEmail.bind(this));
    this.changePrimaryPhone = action(this.changePrimaryPhone.bind(this));
    this.changeSecondaryPhone = action(this.changeSecondaryPhone.bind(this));
    this.changeMainAddress = action(this.changeMainAddress.bind(this));
    this.changeAdditionalAddress = action(this.changeAdditionalAddress.bind(this));
    this.changeCity = action(this.changeCity.bind(this));
    this.changeState = action(this.changeState.bind(this));
    this.changeCountry = action(this.changeCountry.bind(this));
    this.changePostalCode = action(this.changePostalCode.bind(this));
    this.selectAllCountries = action(this.selectAllCountries.bind(this));
    this.stopCreatingUser = action(this.stopCreatingUser.bind(this));
    this.generatePassword = action(this.generatePassword.bind(this));
    this.changeMiddleNameText = action(this.changeMiddleNameText.bind(this));
    this.onBlurSecondaryEmail = action(this.onBlurSecondaryEmail.bind(this));
    this.clearAllErrorMessages = action(this.clearAllErrorMessages.bind(this));
    this.getDefaultCountries = action(this.getDefaultCountries.bind(this));
    this.getCountryOptions = action(this.getCountryOptions.bind(this));
  }

  stopCreatingUser(goBack: boolean = true) {
    this.clearAllErrorMessages();
    this.loginName = "";
    this.password = "";
    this.primaryEmail = "";
    this.firstName = "";
    this.middleName = "";
    this.lastName = "";
    this.shareRatecardsFlag = false;
    this.selectedLocations = [];
    this.selectedClient = null;
    this.selectedRoles = [];
    //this.selectAllCountries = [];
    this.primaryPhone = "";
    this.secondaryPhone = "";
    this.secondaryEmail = "";
    this.mainAddress = "";
    this.additionalAddress = "";
    this.city = "";
    this.state = "";
    this.country = "";
    this.postalCode = "";
    this.selected = false;
    if (goBack && this.router) {
      this.router.goBack();
    }
  }

  clearAllErrorMessages() {
    this.loginMessage.removeAll();
    this.passwordMessage.removeAll();
    this.firstNameMessage.removeAll();
    this.lastNameMessage.removeAll();
    this.primaryEmailMessage.removeAll();
    this.secondaryEmailMessage.removeAll();
    this.primaryPhoneMessage.removeAll();
    this.secondaryPhoneMessage.removeAll();
    this.clientNameMessage.removeAll();
    this.messaging.removeAll();
    this.multiCountryMessage.removeAll();
    this.rolesMessage.removeAll();
  }

  generatePassword() {
    this.password = randPasswordStr();
  }

  changeLoginName(e: Object) {
    this.loginMessage.removeAll();
    this.loginName = e.target.value;
  }

  changePassword(e: Object) {
    this.passwordMessage.removeAll();
    this.password = e.target.value;
  }

  validateEmail(email: string) {
    return /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  }

  changePrimaryEmail(e: Object) {
    this.primaryEmailMessage.removeAll();
    this.primaryEmail = e.target.value;
  }

  changeFirstNameText(e: Object) {
    this.firstNameMessage.removeAll();
    this.firstName = e.target.value;
  }

  changeMiddleNameText(e: Object) {
    this.middleName = e.target.value;
  }

  changeLastNameText(e: Object) {
    this.lastNameMessage.removeAll();
    this.lastName = e.target.value;
  }

  changeSecondaryEmail(e: Object) {
    this.secondaryEmail = e.target.value;
  }

  onBlurSecondaryEmail(e: Object) {
    if (this.primaryEmail === this.secondaryEmail) {
      this.secondaryEmailMessage.createMessage(
        "info",
        "Alternative Email should not be the same as Primary Email"
      );
      this.secondaryEmail = "";
    }
  }

  validatePhoneNumber(phoneNumber: string) {
    // return /^[+]?([\s]?([0-9]{1,3})[-\s.]?)?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}([#][0-9]+)?$/.test(
    //   phoneNumber
    // );
    // ref T5821: allowing free-form text since correct phone validation is complex
    // and one regex does not cover every use case for world-wide phone numbers
    return true;
  }

  changePrimaryPhone(e: Object) {
    this.primaryPhone = e.target.value || "";
  }

  changeSecondaryPhone(e: Object) {
    this.secondaryPhone = e.target.value || "";
  }

  changeMainAddress(e: Object) {
    this.mainAddress = e.target.value;
  }

  changeAdditionalAddress(e: Object) {
    this.additionalAddress = e.target.value;
  }

  changeCity(e: Object) {
    this.city = e.target.value;
  }

  changeState(e: Object) {
    this.state = e.target.value;
  }

  changeCountry(country: CountryOption) {
    this.selectedCountryOption = country;
    this.country = country.label;
  }

  changePostalCode(e: Object) {
    this.postalCode = e.target.value;
  }

  setShareRatecardsFlag(value: string) {
    this.shareRatecardsFlag = value === "false" ? false : true;
  }

  changeClientText(client: { name: string, clientId: number }) {
    this.clientNameMessage.removeAll();
    var clientText = {
      title: client.name,
      clientId: client.clientId,
    };
    this.selectedClient = clientText;

    this.getDefaultCountries(client.clientId);
  }

  changeCountryText(region) {
    this.multiCountryMessage.removeAll();
    var loc = [];
    if (region.length) {
      region.forEach((reg) => {
        loc.push({
          title: reg.name,
          locationId: reg.locationId,
        });
      });
      this.selectedLocations = loc;
    } else {
      this.selectedLocations = [];
    }

    if (this.allowedCountries.length === this.selectedLocations.length) {
      this.selected = true;
    } else {
      this.selected = false;
    }
  }

  selectAllCountries() {
    this.selected = !this.selected;
    if (this.selected) {
      this.multiCountryMessage.removeAll();
      this.selectedLocations = this.allowedCountries;
    } else {
      this.selectedLocations = [];
    }
  }

  changeRoleText(role) {
    this.rolesMessage.removeAll();
    var roleArr = [];
    if (role.length) {
      role.forEach((rol) => {
        roleArr.push({
          title: rol.name,
          roleId: rol.roleId,
        });
      });
      this.selectedRoles = roleArr;
    } else {
      this.selectedRoles = [];
    }
  }

  async ClientText(searchText) {
    const variables = {
      searchParam: searchText === undefined ? "" : searchText,
    };
    const query = `
          query getClient($searchParam: String) {
            viewer{
              allClients(filters: {nameIContains:$searchParam}, order:[{direction : ASC, field: NAME}]){
                edges{
                  node{
                    clientId
                    name
                  }
                }
              }
            }
          }
            `;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
      // should hit get request
    } catch (e) {
      console.error("Error in getting client ", e);
      // TODO: Handle errors properly
      console.log("err");
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);

      return;
    }

    runInAction("ClientText--success", () => {});

    return {
      clients: res.data.viewer.allClients.edges,
    };
  }

  async countryTitle(searchText) {
    var countryText = searchText === undefined ? "" : searchText;

    const variables = {
      searchParam: countryText,
    };

    /* locationTypeId
      1: Cities
      2: States
      3: Country
    */

    const query = `
      query countries($searchParam: String){
        viewer{
          allLocations (filters: {locationTypeId: 3, nameIContains: $searchParam}, order: [{field: NAME, direction: ASC}], section:ADMIN) {
            edges{
              node{
                locationId
                name
              }
            }
          }
        }
      }
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
      // should hit get request
    } catch (e) {
      console.error("Error in getting country title", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }
    runInAction("countryTitle--success", () => {
      res.data.viewer.allLocations.edges.forEach((reg) => {
        this.allowedCountries.push({
          title: reg.node.name,
          locationId: reg.node.locationId,
        });
      });
    });
    return {
      countries: res.data.viewer.allLocations.edges,
    };
  }

  async getDefaultCountries(clientId: number): Promise<Array<CountryItem>> {
    const query = `
      query defaultCountries($clientId: Int){
        viewer{
          defaultCountriesForClient(clientId: $clientId) {
            locationId
            name
          }
        }
      }
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, { clientId });
      // should hit get request
    } catch (e) {
      console.error("Error in getting default countries", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    const countries: Array<CountryItem> = [];
    runInAction("getDefaultCountries--success", () => {
      const defaultCountriesForClient =
        res?.data?.viewer?.defaultCountriesForClient || [];
      defaultCountriesForClient.forEach((loc) => {
        if (
          this.allowedCountries.find(
            (allowedCountry) => allowedCountry.locationId === loc.locationId
          )
        ) {
          countries.push({
            title: loc.name,
            locationId: loc.locationId,
          });
        }
      });

      this.selectedLocations = countries;
    });
    return countries;
  }

  async getAllRoles(searchText) {
    var roleText = searchText === undefined ? "" : searchText;

    const variables = {
      searchParam: roleText,
    };

    const query = `
      query roles{
        viewer{
          allRoles (filters: {name_Icontains:"${roleText}"}, order: {field: NAME, direction: ASC}) {
            edges{
              node{
                name
                roleId
              }
            }
          }
        }
      }
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
      // should hit get request
    } catch (e) {
      console.error("Error in getting roles", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }
    runInAction("role--success", () => {
      if (roleText.length === 0) {
        res.data.viewer.allRoles.edges.forEach((role) => {
          if (role.node.roleId === 6) {
            this.selectedRoles.push({
              title: role.node.name,
              roleId: role.node.roleId,
            });
          }
        });
      }
    });
    return {
      roles: res.data.viewer.allRoles.edges,
    };
  }

  formErrors = () => {
    this.clearAllErrorMessages();

    let validationFlag = false;
    if (!this.loginName.trim()) {
      this.loginMessage.createMessage("info", "Please enter a Username.");
      validationFlag = true;
    }

    // if (!this.password.trim()) {
    //   this.passwordMessage.createMessage("info", "Please enter a Password.");
    //   validationFlag = true;
    // }

    if (!this.selectedClient) {
      this.clientNameMessage.createMessage("info", "Please enter a Client Name.");
      validationFlag = true;
    }

    if (!this.firstName.trim()) {
      this.firstNameMessage.createMessage("info", "Please enter a First Name.");
      validationFlag = true;
    }

    if (!this.lastName.trim()) {
      this.lastNameMessage.createMessage("info", "Please enter a Last Name.");
      validationFlag = true;
    }

    if (!this.primaryEmail.trim()) {
      this.primaryEmailMessage.createMessage("info", "Please enter a Primary Email.");
      validationFlag = true;
    }

    if (!this.validateEmail(this.primaryEmail.trim())) {
      this.primaryEmailMessage.createMessage(
        "info",
        "Please enter a valid Primary Email."
      );
      validationFlag = true;
    }

    if (this.secondaryEmail.trim() && !this.validateEmail(this.secondaryEmail.trim())) {
      this.secondaryEmailMessage.createMessage(
        "info",
        "Please enter a valid Secondary Email."
      );
      validationFlag = true;
    }

    if (this.primaryPhone.trim() && !this.validatePhoneNumber(this.primaryPhone.trim())) {
      this.primaryPhoneMessage.createMessage(
        "info",
        "Please enter a valid phone number."
      );
      validationFlag = true;
    }

    if (this.primaryPhone.trim().length > 30) {
      this.primaryPhoneMessage.createMessage(
        "info",
        "This field is limited to 30 characters."
      );
      validationFlag = true;
    }

    if (
      this.secondaryPhone.trim() &&
      !this.validatePhoneNumber(this.secondaryPhone.trim())
    ) {
      this.secondaryPhoneMessage.createMessage(
        "info",
        "Please enter a valid phone number."
      );
      validationFlag = true;
    }

    if (this.secondaryPhone.trim().length > 30) {
      this.secondaryPhoneMessage.createMessage(
        "info",
        "This field is limited to 30 characters."
      );
      validationFlag = true;
    }

    if (!this.selectedLocations.length) {
      this.multiCountryMessage.createMessage("info", "Please select Country.");
      validationFlag = true;
    }

    if (!this.selectedRoles.length) {
      this.rolesMessage.createMessage("info", "Please select Role.");
      validationFlag = true;
    }

    return validationFlag;
  };

  async createUser() {
    if (this.formErrors()) {
      return;
    }

    let locationIds = [];
    this.selectedLocations.forEach((item) => {
      locationIds.push(item.locationId);
    });

    let roleIds = [];
    this.selectedRoles.forEach((role) => {
      roleIds.push(role.roleId);
    });

    const variables = {
      input: {
        username: this.loginName,
        clientId: this.selectedClient.clientId,
        termsOfAgreement: false,
        password: this.password || null,
        email: this.primaryEmail,
        secondaryEmail: this.secondaryEmail,
        firstName: this.firstName,
        lastName: this.lastName,
        middleName: this.middleName,
        shareRateCards: this.shareRatecardsFlag,
        locationIds: locationIds,
        roleIds: roleIds,
        profileData: {
          address: this.mainAddress,
          apartment: this.additionalAddress,
          phoneNumber: this.primaryPhone.trim(),
          alterPhoneNumber: this.secondaryPhone.trim(),
          city: this.city,
          state: this.state,
          postalCode: this.postalCode,
          country: this.country,
        },
      },
    };

    const query = `
      mutation createNewUser($input:  CreateUserInput!){
        createUser(input : $input){
          user {
            username
          }
          errors{
            __typename
            ... on UsernameAlreadyExistsError{
              message
            }
            ... on NoPermissionError{
              message
            }
            ... on RoleIdsNotExistsError{
              message
            }
            ... on LocationIdsNotExistsError{
              message
            }
            ... on InvalidPasswordError{
              message
            }
          }
        }
      }
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      console.error("Error editing client detail", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }

    runInAction("createUser--success", () => {
      // if (res.data.createUser.errors && res.data.createUser.errors[0].__typename === "UsernameAlreadyExistsError") {
      //   this.loginMessage.removeAll();
      //   this.loginMessage.createMessage("error", res.data.createUser.errors[0].message);
      //   return;
      // }
      // if (res.data.createUser.errors && res.data.createUser.errors[0].__typename === "InvalidPasswordError") {
      //   this.loginMessage.removeAll();
      //   this.loginMessage.createMessage("error", res.data.createUser.errors[0].message);
      //   return;
      // }
      // if(res.data.createUser.errors && res.data.createUser.errors[0].__typename === 'RoleIdsNotExistsError'){
      //   this.rolesMessage.removeAll();
      //   this.rolesMessage.createMessage('error', res.data.createUser.errors[0].message);
      //   return;
      // }
      // if(res.data.createUser.errors && res.data.createUser.errors[0].__typename === 'LocationIdsNotExistsError'){
      //   this.multiCountryMessage.removeAll();
      //   this.multiCountryMessage.createMessage('error', res.data.createUser.errors[0].message);
      //   return;
      // }
      this.messaging.removeAll();
      this.loginMessage.removeAll();
      this.passwordMessage.removeAll();
      if (res.data.createUser.errors) {
        const loginErrors = ["UsernameAlreadyExistsError"];
        const passwordErrors = ["InvalidPasswordError"];
        res.data.createUser.errors.forEach((error) => {
          this.messaging.createMessage("error", error.message);
          if (loginErrors.includes(error.__typename)) {
            this.loginMessage.createMessage("error", error.message);
          }
          if (passwordErrors.includes(error.__typename)) {
            this.passwordMessage.createMessage("error", error.message);
          }
        });
        return;
      }

      this.stopCreatingUser();
    });
  }

  async getCountryOptions() {
    const query = `
      query countries {
        viewer {
          countries {
            value: locationId
            label: name
          }
        }
      }
    `;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, {});
      // should hit get request
    } catch (e) {
      console.error("Error in getting countries", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      throw res.errors;
    }

    runInAction("getCountryOptions--success", () => {
      this.countryOptions = res?.data?.viewer?.countries || [];
    });
  }
}

export default UserCreatePTStore;
