// @flow

import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCurrency } from "./SupportFunctions";
import ContainerSection from "../../ratecards/components/ContainerSection";
import DisplayOptions from "./DisplayOptions";
import LoadingIndicator from "../../ratecards/components/LoadingIndicator";
import ResultLevelCard from "./ResultLevelCard";
import SearchDetailStore from "../../../stores/mobx/SearchDetailStore";
import DataDisplayOptions from "./DataDisplayOptions";
import RateDisplayToggle from "../../ratecards/components/RateDisplayToggle";
import { InlineElements } from "../../../components/lib/Inline";
import Spacer from "../../../components/lib/Spacer";
import Button from "../../../components/lib/Button";

const LevelCardsSection = (props: { store: SearchDetailStore }) => {
  const store: SearchDetailStore = props.store;
  const globalStore = store.mobxStore.globalStateStore;
  const { Junior, Mid, Senior, Lead, Guru } = store.search.resultsByLevel;
  const viewNotAvailable = store.search.showThreeLevelsView && !Junior && !Mid && !Senior;
  const canRevertLastUpdate = store.search.canRevertLatestUpdate;
  const resultsByLevel = store.search.resultsByLevel;
  let hideUpdateOption = true;

  for (let level in resultsByLevel) {
    if (!resultsByLevel.hasOwnProperty(level)) continue;

    if (resultsByLevel[level] === null || resultsByLevel[level] === undefined) continue;

    if (resultsByLevel[level].needsUpdate) {
      hideUpdateOption = false;
      break;
    }
  }

  let currency = getCurrency(store.search);

  const noData = !Junior && !Mid && !Senior && !Lead && !Guru;

  return (
    <div>
      <ContainerSection className="header">
        <InlineElements>
          <div className="title-info">
            Rates From {store.search.lastUpdated.format("MMMM DD, YYYY")}
          </div>
          <Spacer />
          <p
            className={classNames("text-muted text-x-small no-margin info", {
              hidden: !hideUpdateOption,
            })}
          >
            <FontAwesomeIcon icon="info-circle" fixedWidth className="icon" />
            <em>Rates are up to the latest available revision.</em>
          </p>
          {store.hasOwnership && (
            <Button
              color="accent"
              icon="undo"
              css={{ display: !canRevertLastUpdate ? "none" : "inline-flex" }}
              onClick={() => {
                store.confirmUndoUpdateRatesOnSearchModal.search = store.search;
                store.confirmUndoUpdateRatesOnSearchModal.showModal();
              }}
            >
              Undo Last Update
            </Button>
          )}
          {store.hasOwnership && (
            <Button
              color="brand"
              icon="sync"
              css={{ display: hideUpdateOption ? "none" : "inline-flex" }}
              onClick={() => {
                store.confirmUpdateRatesOnSearchModal.search = store.search;
                store.confirmUpdateRatesOnSearchModal.showModal();
              }}
            >
              Update Rates
            </Button>
          )}
          <DataDisplayOptions store={store} />
          <RateDisplayToggle
            selectedOption={globalStore.selectedContingentRateDisplayOption}
            onChange={globalStore.onContingentRatesDisplayChange}
            onConfig={globalStore.customMultipliersModal.showModal}
          />
        </InlineElements>
      </ContainerSection>
      <ContainerSection>
        <DisplayOptions store={store} />
      </ContainerSection>

      {viewNotAvailable ? (
        <div className="row">
          <div className="alert no-items full-width" role="alert">
            <h3>Three Level View is not available</h3>
            <p>This search does not have all five standard levels</p>
          </div>
        </div>
      ) : (
        <div className="result-cards-container">
          {Junior !== null && (
            <ResultLevelCard
              resultLevel={Junior}
              store={store}
              currencySymbol={currency}
            />
          )}
          {Mid !== null && (
            <ResultLevelCard resultLevel={Mid} store={store} currencySymbol={currency} />
          )}
          {Senior !== null && (
            <ResultLevelCard
              resultLevel={Senior}
              store={store}
              currencySymbol={currency}
            />
          )}
          {Lead !== null && (
            <ResultLevelCard resultLevel={Lead} store={store} currencySymbol={currency} />
          )}
          {Guru !== null && (
            <ResultLevelCard resultLevel={Guru} store={store} currencySymbol={currency} />
          )}
        </div>
      )}
      {noData && (
        <div className="row" style={{ padding: "0 15px" }}>
          <div className="alert alert-info no-items full-width" role="alert">
            <h3>No Data to Display</h3>
            <br />
            <p>
              This search has no rates data. Something probably went wrong. Please contact
              People Ticker.
            </p>
          </div>
        </div>
      )}
      <div
        className={classNames({
          "loading-indicator-container": true,
          show: store.network.loading || (store.search && store.search.network.loading),
        })}
      >
        <LoadingIndicator
          style={{
            minHeight: 0,
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
      </div>
    </div>
  );
};

export default observer(LevelCardsSection);
