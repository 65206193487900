// @flow

import React, { Component } from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LibraryTitleList from "./LibraryTitleList";
import LibraryTitleTable from "./LibraryTitleTable";
import Pagination from "./Pagination";
import LibraryTitleTableRow from "./LibraryTitleTableRow";
import LibrarySearch from "./LibrarySearch";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Modal from "../../components/modals/Modal";
import NonIdealState from "./components/NonIdealState";
import { LIBRARY_DISPLAY_OPTIONS } from "../../stores/mobx/JobLibraryListStore";
import FlagIcon from "../../components/FlagIcon";
import Industry from "../../models/Industry";
import type MobXStore from "../../stores/mobx/MobXStore";
import JobLibrary from "../../models/JobLibrary";
import type { CountryLibraryNode } from "../../stores/mobx/JobLibraryListStore";
import SessionInfo from "../../models/SessionInfo";
import ToggleButton from "../ratecards/components/ToggleButton";
import { RATE_TYPE } from "../../constants/search";
import InfiniteScrollSelect from "../../components/selects/InfiniteScrollSelect";
import Inline, { InlineElements } from "../../components/lib/Inline";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "../../components/lib/DropdownMenu";
import { styled } from "../../../src/stitches.config";
import Box from "../../components/lib/Box";
import Button from "../../components/lib/Button";

const DetailContainer = styled("div", {
  position: "relative",
  width: "100%",
  maxWidth: "980px",
  padding: "50px 40px",
  background: "#ffffff",
  border: "1px solid #e6e6e6",
  borderRadius: "2px",
  alignSelf: "flex-start",
});

const EllipsisButton = styled(Button, {
  border: "none !important",
  fontSize: "30px !important",
  color: "#888888 !important",
  backgroundColor: "transparent !important",
  "&:hover": {
    backgroundColor: "transparent !important",
  },
  "&:active": {
    backgroundColor: "transparent !important",
  },
  "&:focus": {
    backgroundColor: "transparent !important",
  },
});

type Router = {
  push: (Object | string) => void,
  location: { state: Object },
  replace: (Object | string) => void,
};

type Props = {
  store: MobXStore,
  params: { libraryId?: string, countryId?: string },
  router: Router,
  sessionInfo: SessionInfo,
};

class Detail extends Component<Props> {
  handleOnDelete = () => {
    const libraryId = this.props.params.libraryId;
    if (libraryId) {
      this.props.store.jobLibraryListStore.confirmDeleteLibraryModal.hideModal();
      this.props.store.jobLibraryListStore.deleteClientLibrary(libraryId);
    } else {
      console.error("Unable to get libraryId from url params");
    }
  };

  handleOnExport = () => {
    const libraryId = this.props.params.libraryId;
    if (libraryId) {
      this.props.store.jobLibraryListStore.exportSelectedLibrary(libraryId);
    } else {
      console.error("Unable to get libraryId from url params");
    }
  };

  handleOnExportCertifiedJobs = () => {
    const libraryId = this.props.params.libraryId;
    if (libraryId) {
      this.props.store.jobLibraryListStore.exportSelectedLibraryCertifiedJobs(libraryId);
    } else {
      console.error("Unable to get libraryId from url params");
    }
  };

  handleBatchSearchOnClick = () => {
    const jobLibraryListStore = this.props.store.jobLibraryListStore;
    const batchSearchCreateStore = this.props.store.batchSearchJLCreateStore;
    batchSearchCreateStore.resetAll();
    jobLibraryListStore.goBatchSearchModal.showModal();

    const isCountryLibrary =
      jobLibraryListStore.libraryDisplayValue === LIBRARY_DISPLAY_OPTIONS.COUNTRY_LIBRARY;
    if (isCountryLibrary) batchSearchCreateStore.getIndustries();
  };

  handleBatchSearch = () => {
    const libraryListStore = this.props.store.jobLibraryListStore;
    const batchSearchCreateStore = this.props.store.batchSearchJLCreateStore;
    const isCountryLibrary =
      libraryListStore.libraryDisplayValue === LIBRARY_DISPLAY_OPTIONS.COUNTRY_LIBRARY;
    const clientLibraryId = isCountryLibrary
      ? parseInt(this.props.params.countryId)
      : parseInt(this.props.params.libraryId);

    if (libraryListStore.validateBatchSearch()) {
      libraryListStore.goBatchSearchModal.hideModal();

      batchSearchCreateStore.setUpBatchSearchFromJobLibrary(
        clientLibraryId,
        isCountryLibrary
      );
      this.props.router.push("/batch-searches/create/");
    }
  };

  handleOnRateTypeChange = (rateType: Object) => {
    const libraryListStore = this.props.store.jobLibraryListStore;
    const batchSearchCreateStore = this.props.store.batchSearchJLCreateStore;

    batchSearchCreateStore.onRateTypeChange(rateType);
    libraryListStore.validateBatchSearch();
  };

  handleOnIndustryChange = (industry: ?Industry) => {
    const libraryListStore = this.props.store.jobLibraryListStore;
    const batchSearchCreateStore = this.props.store.batchSearchJLCreateStore;

    batchSearchCreateStore.onSelectedIndustryChange(industry);
    libraryListStore.validateBatchSearch();
  };

  handleOnEditLibrary = () => {
    const libraryId = this.props.params.libraryId;
    if (libraryId) {
      this.props.router.push(`/job-library/view/${libraryId}/edit/`);
    } else {
      console.error("Unable to get libraryId from url params");
    }
  };

  handleOnRequestJobTitle = () => {
    const countryId: ?string = this.props.params.countryId;

    if (countryId) {
      const requestJobTitleStore = this.props.store.jobLibraryTitleRequestCreateStore;
      requestJobTitleStore.defaultSelectedCountries = [countryId];
    }

    this.props.router.push(`/job-library/title-requests/create-title-request`);
  };

  handleOnRequestCountries = () => {
    const countryId: ?string = this.props.params.countryId;
    const libraryId: ?string = this.props.params.libraryId;

    if (countryId) {
      const requestCountryStore = this.props.store.jobLibraryRequestCountryStore;
      requestCountryStore.defaultSelectedCountries = [countryId];
    } else if (libraryId) {
      const requestCountryStore =
        this.props.store.jobLibraryTitleRequestCountryCreateStore;
      requestCountryStore.requestCountryForLibrary(parseInt(libraryId, 10));
    }

    this.props.router.push(`/job-library/title-requests/create-country-request`);
  };

  handleOnViewJobTitleRequests = () => {
    this.props.router.push(`/job-library/title-requests/`);
  };

  handleOnAddCountries = () => {
    const router = this.props.router;
    const libraryId = parseInt(this.props.params.libraryId, 10);
    const titleListStore = this.props.store.jobLibraryTitleListStore;
    titleListStore.addCountriesForLibrary(libraryId, router);
  };

  handleOnRemoveCountries = () => {
    const router = this.props.router;
    const libraryId = parseInt(this.props.params.libraryId, 10);
    const titleListStore = this.props.store.jobLibraryTitleListStore;
    titleListStore.removeCountriesForLibrary(libraryId, router);
  };

  componentDidMount() {
    // console.log("render Detail:componentDidMount");
    const libraryListStore = this.props.store.jobLibraryListStore;
    const titleListStore = this.props.store.jobLibraryTitleListStore;
    libraryListStore.sessionInfo = this.props.sessionInfo;
    titleListStore.sessionInfo = this.props.sessionInfo;
    titleListStore.handleStopEdit(); // clean state

    if (libraryListStore.network.loading) return;

    if (!this.storeHasLibraries()) return;

    if (this.props.params.countryId) {
      titleListStore.pagination.goFetch();
      return;
    }

    const libraryId = parseInt(this.props.params.libraryId, 10);
    const library = this.findLibraryWithId(libraryId);

    if (!library) {
      // library does not exist on the store, fall back to the top library
      this.navigateToTopLibraryDetail(this.props.router, this.props.store);
      return;
    }

    if (titleListStore.libraryId !== library.id) {
      libraryListStore.libraryDisplayValue = LIBRARY_DISPLAY_OPTIONS.LIBRARY;
      titleListStore.libraryId = library.id;
      titleListStore.selectTitlesByLibraryId = null;
      titleListStore.handleStopEdit();
      titleListStore.hardRefresh();
    }
  }

  componentDidUpdate() {
    // console.log("render Detail:componentDidUpdate");
    if (this.props.store.jobLibraryListStore.network.loading) return;

    if (!this.storeHasLibraries()) return;

    if (this.props.params.countryId) {
      const titleListStore = this.props.store.jobLibraryTitleListStore;
      titleListStore.pagination.goFetch();
      return;
    }

    const libraryId = parseInt(this.props.params.libraryId, 10);
    const library = this.findLibraryWithId(libraryId);

    if (!library) {
      // library does not exist on the store, fall back to the top library
      this.navigateToTopLibraryDetail(this.props.router, this.props.store);
      return;
    }

    if (this.props.store.jobLibraryTitleListStore.libraryId !== library.id) {
      this.props.store.jobLibraryListStore.libraryDisplayValue =
        LIBRARY_DISPLAY_OPTIONS.LIBRARY;
      this.props.store.jobLibraryTitleListStore.libraryId = library.id;
      this.props.store.jobLibraryTitleListStore.selectTitlesByLibraryId = null;
      this.props.store.jobLibraryTitleListStore.handleStopEdit();
      this.props.store.jobLibraryTitleListStore.hardRefresh();
    }
  }

  logoOnChangeHandler = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const store = this.props.store.jobLibraryListStore;
    const libraryId = parseInt(this.props.params.libraryId, 10);
    const logoFile = e.target.files[0];

    store.updateLibraryLogo(libraryId, logoFile);
  };

  logoOnClearHandler = () => {
    const store = this.props.store.jobLibraryListStore;
    const libraryId = parseInt(this.props.params.libraryId, 10);

    store.updateLibraryLogo(libraryId, null);
  };

  renderLoading = () => {
    return (
      <div className="cjl-centered-view-container">
        <div className="cjl-centered-view cjl-library-detail">
          <FontAwesomeIcon icon="ellipsis-v" className="cjl-detail-menu-target" />
          <header className="cjl-detail-name">
            <div className="cjl-detail-name-placeholder loading-gradient" />
          </header>
          <div className="cjl-detail-desc-placeholder loading-gradient" />
          <div>
            <div className="cjl-flex-table">
              <div className="cjl-flex-table row double-vertical-space">
                <div className="column auto no-padding-left">
                  <LibrarySearch width={300} placeholder="Search Titles..." />
                </div>
                <div className="column no-padding-right">
                  <span className="caption">[...] Titles Found</span>
                </div>
              </div>
            </div>
            <LibraryTitleTable store={this.props.store.jobLibraryTitleListStore}>
              <LibraryTitleTableRow key={0} />
              <LibraryTitleTableRow key={1} />
              <LibraryTitleTableRow key={2} />
              <LibraryTitleTableRow key={3} />
              <LibraryTitleTableRow key={4} />
              <Pagination
                hasLoaded={false}
                pageCount={5}
                pagesToShow={1}
                currentPage={1}
                begin={1}
                end={1}
                isEndRange={true}
                handleNextPage={() => {}}
                handlePrevPage={() => {}}
                handleFirstPage={() => {}}
                handleLastPage={() => {}}
              />
            </LibraryTitleTable>
          </div>
        </div>
      </div>
    );
  };

  renderNoLibrarySelected = () => {
    return (
      <div className="cjl-flex-table" style={{ maxHeight: 400 }}>
        <NonIdealState
          icon="info-circle"
          title="No Library Selected"
          description="A Library must be selected in order to see the Titles it contains. Please select a library from the list or create one."
        />
      </div>
    );
  };

  renderNoCountryLibraryAvailable = () => {
    return (
      <div className="cjl-flex-table" style={{ maxHeight: 400 }}>
        <NonIdealState
          icon="info-circle"
          title="Country Is Not Available"
          description="The country you're trying to view is not available. Please select a library from the list or create one."
        />
      </div>
    );
  };

  render() {
    // console.log("render Detail");
    const store = this.props.store.jobLibraryListStore;
    const batchSearchCreateStore = this.props.store.batchSearchJLCreateStore;
    const { client } = this.props.sessionInfo || {};
    const hasJobLibrarySubscription = client
      ? client.ratecardSubscriptionFlag || false
      : false;

    if (store.network.loading)
      return (
        <div className="cjl-flex-table" style={{ maxHeight: 400 }}>
          <NonIdealState
            icon="circle-notch"
            iconClasses="fa-spin"
            title="Loading"
            description="Please wait while data it's being transferred."
          />
        </div>
      );

    if (!this.storeHasLibraries()) return this.renderNoLibrarySelected();

    let libraryId = 0;
    let userIsOwner = false;
    let library = null;
    if (Boolean(this.props.params.libraryId)) {
      libraryId = parseInt(this.props.params.libraryId, 10);
      library = this.findLibraryWithId(libraryId);
      userIsOwner = store.currentUserIsOwnerOfLibrary(libraryId);
    }

    const countryView = Boolean(this.props.params.countryId);
    let countryId = 0;
    let countryCode = "";
    let countryLibrary = null;
    let countryTitle = "";
    if (Boolean(this.props.params.countryId)) {
      countryId = parseInt(this.props.params.countryId, 10);
      countryLibrary = this.findCountryLibraryWithId(countryId);
      countryCode = countryLibrary ? countryLibrary.iso3166Alpha2 : "";
      countryTitle = countryLibrary ? countryLibrary.title : "";
      if (!countryLibrary && store.network.loading) {
        return this.renderNoCountryLibraryAvailable();
      }
    }

    if (!library && !countryLibrary) {
      return this.renderLoading();
    }

    const isMain = library && library.isMainLibrary;

    let headerTitle = library ? library.name : <span>No Title</span>;
    let totalTitles = library ? library.totalTitles : 0;
    let totalJobs = library ? library.totalTitleBySearchableCountries : 0;

    if (countryLibrary) {
      headerTitle = countryTitle;
      totalTitles = countryLibrary.totalTitles
        ? countryLibrary.totalTitles.totalCount || 0
        : 0;
      totalJobs = countryLibrary ? countryLibrary.totalTitleBySearchableCountries : 0;
    }

    const rateTypeErrorTooltip = (
      <Tooltip id="rateTypeErrorTooltip" placement="top">
        {store.selectedRateTypeError}
      </Tooltip>
    );

    const industryErrorTooltip = (
      <Tooltip id="industryErrorTooltip" placement="top">
        {store.selectedIndustryError}
      </Tooltip>
    );

    return (
      <Box
        css={{
          display: "flex",
          flex: "1 1",
          flexBasis: "auto",
          width: "100%",
          justifyContent: "center",
          overflow: "visible",
        }}
      >
        <DetailContainer>
          <Inline
            css={{ justifyContent: "flex-end", position: "absolute", right: "40px" }}
          >
            <DropdownMenu id="dropdown-custom-menu">
              <DropdownMenuTrigger asChild>
                <EllipsisButton
                  icon="ellipsis-v"
                  data-testid="job-library-drop-down-button"
                />
              </DropdownMenuTrigger>

              <DropdownMenuContent side="bottom" align="end">
                {userIsOwner && (
                  <DropdownMenuItem eventkey="1" onSelect={this.handleOnEditLibrary}>
                    <span className="cjl-detail-menu-item">
                      <FontAwesomeIcon icon="edit" fixedWidth className="icon" /> Edit
                    </span>
                  </DropdownMenuItem>
                )}
                {userIsOwner && (
                  <DropdownMenuItem
                    eventkey="2"
                    onSelect={store.confirmDeleteLibraryModal.showModal}
                  >
                    <span className="cjl-detail-menu-item">
                      <FontAwesomeIcon icon="trash-alt" fixedWidth className="icon" />{" "}
                      Delete
                    </span>
                  </DropdownMenuItem>
                )}
                {library && (
                  <DropdownMenuItem eventkey="3" onSelect={this.handleOnExport}>
                    <span className="cjl-detail-menu-item">
                      <FontAwesomeIcon icon="download" fixedWidth className="icon" />{" "}
                      Export
                    </span>
                  </DropdownMenuItem>
                )}
                {library && (
                  <DropdownMenuItem
                    eventkey="4"
                    onSelect={this.handleOnExportCertifiedJobs}
                  >
                    <span className="cjl-detail-menu-item">
                      <FontAwesomeIcon icon="download" fixedWidth className="icon" />{" "}
                      Export Certified
                    </span>
                  </DropdownMenuItem>
                )}
                <DropdownMenuItem eventkey="5" onSelect={this.handleBatchSearchOnClick}>
                  <span className="cjl-detail-menu-item">
                    <FontAwesomeIcon icon="search" fixedWidth className="icon" /> Batch
                    Search
                  </span>
                </DropdownMenuItem>

                {!hasJobLibrarySubscription && <DropdownMenuSeparator />}
                {!hasJobLibrarySubscription && !countryView && (
                  <DropdownMenuItem eventkey="6" onSelect={this.handleOnRequestCountries}>
                    <span className="cjl-detail-menu-item">
                      <FontAwesomeIcon
                        icon="square"
                        fixedWidth
                        style={{ visibility: "hidden" }}
                        className="icon"
                      />
                      Request Countries
                    </span>
                  </DropdownMenuItem>
                )}
                {!hasJobLibrarySubscription && (
                  <DropdownMenuItem eventkey="6" onSelect={this.handleOnRequestJobTitle}>
                    <span className="cjl-detail-menu-item">
                      <FontAwesomeIcon
                        icon="square"
                        fixedWidth
                        style={{ visibility: "hidden" }}
                        className="icon"
                      />
                      Request Job Title
                    </span>
                  </DropdownMenuItem>
                )}
                {!hasJobLibrarySubscription && (
                  <DropdownMenuItem
                    eventkey="7"
                    onSelect={this.handleOnViewJobTitleRequests}
                  >
                    <span className="cjl-detail-menu-item">
                      <FontAwesomeIcon
                        icon="square"
                        fixedWidth
                        style={{ visibility: "hidden" }}
                        className="icon"
                      />
                      Current Job Title Requests
                    </span>
                  </DropdownMenuItem>
                )}
                {hasJobLibrarySubscription &&
                  !countryView &&
                  // TODO: Uncomment when store wizard code has been ported
                  // <MenuItem eventKey="7" onSelect={this.handleOnAddCountries}>
                  //   <span className="cjl-detail-menu-item">
                  //     <FontAwesomeIcon icon="plus" fixedWidth className="icon" /> Add Countries
                  //   </span>
                  // </MenuItem>
                  null}
                {hasJobLibrarySubscription &&
                  !countryView &&
                  // TODO: Uncomment when store wizard code has been ported
                  // <MenuItem eventKey="7" onSelect={this.handleOnRemoveCountries}>
                  //   <span className="cjl-detail-menu-item">
                  //     <FontAwesomeIcon icon="minus" fixedWidth className="icon" /> Remove Countries
                  //   </span>
                  // </MenuItem>
                  null}
              </DropdownMenuContent>
            </DropdownMenu>
          </Inline>

          <div className="cjl-detail-name flex center-items">
            {countryLibrary && (
              <div>
                <FlagIcon code={countryCode} />
              </div>
            )}
            {library && (
              <div className="cjl-logo-select-field">
                {Boolean(library.logoUrl) ? (
                  <div className="logo-wrapper">
                    <img className="logo-image" src={library.logoUrl} alt="Logo" />
                  </div>
                ) : (
                  <div className="logo-wrapper">
                    <FontAwesomeIcon icon="book" style={{ fontSize: "42px" }} />
                  </div>
                )}
                <label htmlFor="file-upload" className="logo-upload-overlay">
                  <div className="clear-logo-button" onClick={this.logoOnClearHandler}>
                    <FontAwesomeIcon icon="times" />
                  </div>
                  <input
                    id="file-upload"
                    type="file"
                    onChange={this.logoOnChangeHandler}
                    accept=".jpg,.jpeg,.png,.bmp,.gif"
                  />
                </label>
              </div>
            )}
            <div>{headerTitle}</div>
          </div>
          {library && library.description && (
            <p className="cjl-detail-desc">{library.description}</p>
          )}
          <LibraryTitleList
            store={this.props.store.jobLibraryTitleListStore}
            library={library}
            isMainLibrary={isMain}
            totalTitles={totalTitles}
            totalAvailability={totalJobs}
            router={this.props.router}
            isCountryLibrary={Boolean(countryLibrary)}
            showLatestSearch
          />
        </DetailContainer>

        <Modal
          show={store.confirmDeleteLibraryModal.show}
          onHide={store.confirmDeleteLibraryModal.hideModal}
        >
          <div className="container-section header">
            <h4>Confirmation Needed</h4>
          </div>
          <div className="container-section">
            <p>Are you sure you want to delete this Library?</p>
            <p className="text-danger">
              <FontAwesomeIcon icon="exclamation-triangle" fixedWidth />{" "}
              <em>This action cannot be undone!</em>
            </p>
          </div>
          <div className="container-section footer">
            <div className="pull-right">
              <button className="btn btn-lg btn-danger" onClick={this.handleOnDelete}>
                Yes, Delete Library
              </button>
              <button
                className="btn btn-lg"
                onClick={store.confirmDeleteLibraryModal.hideModal}
              >
                No, Cancel
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          show={store.goBatchSearchModal.show}
          onHide={store.goBatchSearchModal.hideModal}
        >
          <div className="container-section header">
            <h4>Setup Batch Search</h4>
          </div>
          <div className="container-section">
            <div className="pt-ss-title-form">
              {Boolean(countryLibrary) && (
                <div className="form-group">
                  <div className="alert alert-info text-x-small" role="alert">
                    NOTE: Default location selected will be the country. You can change
                    this before adding the titles to the batch search.
                  </div>
                </div>
              )}
              <div className="form-group">
                <label className="rc-control-label control-label">
                  Select a Rate Type{" "}
                  <span className="text-xx-small text-muted text-thin">(REQUIRED)</span>
                </label>
                <div className="flex center-items" style={{ position: "relative" }}>
                  <div className="rc-checkbox-group form-horizontal">
                    <InlineElements>
                      {batchSearchCreateStore.rateTypes.map((item) => (
                        <ToggleButton
                          key={item.value}
                          large={true}
                          item={item}
                          type="radio"
                          name="rate-type"
                          value={item.value}
                          selected={
                            batchSearchCreateStore.selectedRateType &&
                            item.value === batchSearchCreateStore.selectedRateType.value
                          }
                          onChange={this.handleOnRateTypeChange}
                        >
                          {item.value === RATE_TYPE.HOURLY ? (
                            <FontAwesomeIcon icon="hourglass" className="icon" />
                          ) : (
                            <FontAwesomeIcon icon="calendar" className="icon" />
                          )}
                          <span>{item.label}</span>
                        </ToggleButton>
                      ))}
                    </InlineElements>
                    {Boolean(store.selectedRateTypeError) && (
                      <OverlayTrigger placement="top" overlay={rateTypeErrorTooltip}>
                        <FontAwesomeIcon
                          icon="info-circle"
                          fixedWidth
                          className="icon text-danger"
                          style={{ marginLeft: 10 }}
                        />
                      </OverlayTrigger>
                    )}
                  </div>
                </div>
              </div>
              {Boolean(countryLibrary) && (
                <div className="form-group">
                  <label className="rc-control-label control-label ">
                    Industry{" "}
                    <span className="text-xx-small text-muted text-thin">(REQUIRED)</span>
                  </label>
                  <div className="flex center-items" style={{ position: "relative" }}>
                    <InfiniteScrollSelect
                      name="industries"
                      hasMoreItems={
                        batchSearchCreateStore.industries.length === 0 &&
                        batchSearchCreateStore.industrySearch === ""
                      }
                      items={batchSearchCreateStore.industries}
                      selectedItem={batchSearchCreateStore.selectedIndustry}
                      inputValue={batchSearchCreateStore.industrySearch}
                      onInputValueChange={batchSearchCreateStore.onIndustrySearchChange}
                      onSelectedItemChange={this.handleOnIndustryChange}
                      // loadMoreItems={batchSearchCreateStore.getIndustries}
                    />
                    {Boolean(store.selectedIndustryError) && (
                      <OverlayTrigger placement="top" overlay={industryErrorTooltip}>
                        <FontAwesomeIcon
                          icon="info-circle"
                          fixedWidth
                          className="icon text-danger"
                          style={{ marginLeft: 10 }}
                        />
                      </OverlayTrigger>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <Inline>
            <Button size="large" color="brand" onClick={this.handleBatchSearch}>
              Setup Batch Search
            </Button>
            <Button size="large" onClick={store.goBatchSearchModal.hideModal}>
              Cancel
            </Button>
          </Inline>
        </Modal>
      </Box>
    );
  }

  findLibraryWithId = (libraryId: number): ?JobLibrary => {
    const libraryStore = this.props.store.jobLibraryListStore;

    const libraries = libraryStore.clientLibraries;
    let library = null;
    for (let i = 0; i < libraries.length; i++) {
      if (libraries[i].id === libraryId) {
        library = libraries[i];
        break;
      }
    }

    return library;
  };

  findCountryLibraryWithId = (libraryId: number): ?CountryLibraryNode => {
    const libraryStore = this.props.store.jobLibraryListStore;

    const libraries = libraryStore.countryLibraries;
    let library = null;
    for (let i = 0; i < libraries.length; i++) {
      if (libraries[i].databaseId === libraryId) {
        library = libraries[i];
        break;
      }
    }

    return library;
  };

  storeHasLibraries = () => {
    return this.props.store.jobLibraryListStore.clientLibraries.length > 0;
  };

  navigateToTopLibraryDetail = (router: Router, mobXStore: MobXStore) => {
    const topLibrary = mobXStore.jobLibraryListStore.clientLibraries[0];

    if (topLibrary && topLibrary.id) {
      mobXStore.jobLibraryListStore.libraryDisplayValue = LIBRARY_DISPLAY_OPTIONS.LIBRARY;
      router.replace(`/job-library/view/${topLibrary.id}/`);
    }
  };

  navigateToTopCountryLibraryDetail = (router: Router, mobXStore: MobXStore) => {
    const topLibrary = mobXStore.jobLibraryListStore.countryLibraries[0];

    if (topLibrary && topLibrary.id) {
      mobXStore.jobLibraryListStore.libraryDisplayValue =
        LIBRARY_DISPLAY_OPTIONS.COUNTRY_LIBRARY;
      this.props.store.jobLibraryTitleListStore.libraryId = null;
      this.props.store.jobLibraryTitleListStore.countryId = topLibrary.databaseId;
      this.props.store.jobLibraryTitleListStore.countryTitle = topLibrary.title;
      this.props.store.jobLibraryTitleListStore.countryCode = topLibrary.iso3166Alpha2;
      this.props.store.jobLibraryTitleListStore.pagination.handleFirstPage();
      router.replace(`/job-library/view/countries/${topLibrary.id}/`);
    }
  };
}

export default observer(Detail);
