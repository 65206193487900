// @flow
import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  icon?: string,
  iconClasses?: null | string | string[],
  title?: string,
  description?: React.Node,
  style?: ?Object,
};

class NonIdealState extends React.Component<Props> {
  props: Props;

  render() {
    return (
      <div className="cjl-flex-table row cjl-non-ideal-state" style={this.props.style}>
        <div className="column center-items">
          <div>
            {this.props.icon && (
              <FontAwesomeIcon
                icon={this.props.icon}
                className={this.props.iconClasses}
                style={{ fontSize: 38 }}
              />
            )}
          </div>
          <header className="cjl-non-ideal-state title">{this.props.title}</header>
          <div className="cjl-non-ideal-state description">{this.props.description}</div>
        </div>
      </div>
    );
  }
}

export default NonIdealState;
