import React, { useEffect, useState, useCallback } from "react";

import Stack from "../../../components/lib/Stack";
import Text from "../../../components/lib/Text";
import Alert from "../../../components/lib/Alert";
import Icon from "../../../components/lib/Icon";

import { extractDjangoHTTP400Messages } from "../../../utils/django";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";

import { jobsValidationsListToImmutableOrderedMap } from "../../validator5K_admin/dataConverters";
import {
  CREATE_REVIEW_WIZARD_PAGES_LABELS,
  CREATE_REVIEW_WIZARD_PAGES,
} from "../../validator5K_admin/types";

import type { CREATE_REVIEW_WIZARD_PAGES_TYPE } from "../../validator5K_admin/types";

import ReviewContractorsWizardPage from "../components/ReviewContractorsWizardPage";
import SubmitContractorsWizardPage from "../components/SubmitContractorsWizardPage";

import {
  Card,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
  CardHeaderTitle,
  CardBody,
} from "../../../components/lib/Card";
import { NavigationButton } from "../../../components/lib/Button";

import {
  useStoredIndexContractorsTableGlobalState,
  useAllContractorsTableGlobalState,
  useDiscoverySearchContractorsTableGlobalState,
} from "../globalState";
import { emptyOrderedMap, emptySet, emptyList } from "../constants";
import { RATE_TYPES_LABELS } from "../types";

import type {
  ContractorsTableDataStateObject,
  JobsValidationsOrderedMap,
  JobValidationDataMap,
  JobValidationDataObject,
  ContractorDataMap,
  ContractorsDataOrderedMap,
  JobValidationErrorsList,
  JobValidationWarningsList,
} from "../types";
import type { CommonStoredIndexChildPageProps } from "../StoredIndexDataProvider";
import type { CommonProgramChildPageProps } from "../ProgramDataProvider";
import type { ImmutableSet } from "../../../types/immutable";
import type { FetchAPIResponse } from "../../../types/fetch";
import { Resetter, SetterOrUpdater } from "recoil";

type UniqueIdentifierPartsObject = {
  key: string;
  path: string | string[];
};

const uniqueIdentifierPartsSpecs: UniqueIdentifierPartsObject[] = [
  {
    key: "rate_type",
    path: "rate_type",
  },
  {
    key: "source",
    path: "source",
  },
  {
    key: "title",
    path: "job_title",
  },
  {
    key: "industry",
    path: ["processed", "industry_id"],
  },
  {
    key: "location",
    path: ["processed", "location_id"],
  },
  {
    key: "region",
    path: ["processed", "region_id"],
  },
  {
    key: "collection",
    path: ["processed", "job_collection_id"],
  },
  {
    key: "is_global_supplier_search",
    path: "is_global_supplier_search",
  },
];

function getUniqueIdentifier(contractorData: ContractorDataMap): string {
  return uniqueIdentifierPartsSpecs
    .map((spec) => {
      const key = spec.key;
      const path = Array.isArray(spec.path) ? spec.path : [spec.path];
      return `${key}:${contractorData.getIn(path) || ""}`;
    })
    .join("|")
    .toLowerCase();
}

type ReviewFromContractorsWizardProps = CommonProgramChildPageProps &
  Partial<
    Pick<
      CommonStoredIndexChildPageProps,
      | "indexData"
      | "indexTableConfig"
      | "indexId"
      | "indexTitle"
      | "indexDisplayCurrencyCode"
      | "indexUserId"
      | "indexIsPublic"
      | "indexIsOwned"
      | "indexIsEditingAllowed"
    >
  >;

const ReviewFromContractorsWizard = (props: ReviewFromContractorsWizardProps) => {
  const {
    router,
    location,
    userId,
    programId,
    indexId,
    showModalWarning,
    showModalError,
    showLoader,
    hideLoader,
    fetchArgusAPI,
  } = props;

  // state

  const [wizardPage, setWizardPage] = useState<CREATE_REVIEW_WIZARD_PAGES_TYPE>(
    CREATE_REVIEW_WIZARD_PAGES.REVIEW
  );
  const [jobsData, setJobsData] = useState<JobsValidationsOrderedMap>(
    emptyOrderedMap as unknown as JobsValidationsOrderedMap
  );
  const [areJobsValidated, setAreJobsValidated] = useState<boolean>(false);
  const [hasWarnings, setHasWarnings] = useState<boolean>(false);
  const [hasAnyProblems, setHasAnyProblems] = useState<boolean>(false);

  const setJobsDataAndFlags = (nextJobsData: JobsValidationsOrderedMap): void => {
    setJobsData(nextJobsData);
    setHasWarnings(
      !!nextJobsData.find((data: JobValidationDataMap): boolean => {
        const warnings = data.get("warnings") || (emptyList as JobValidationWarningsList);
        return warnings.size > 0;
      })
    );
    setHasAnyProblems(
      !!nextJobsData.find((data: JobValidationDataMap): boolean => {
        const errors = data.get("errors") || (emptyList as JobValidationErrorsList);
        const warnings = data.get("warnings") || (emptyList as JobValidationWarningsList);
        return errors.size + warnings.size > 0;
      })
    );
  };

  type ContractorsTableStateHook = (
    tableId: string
  ) => [
    ContractorsTableDataStateObject,
    SetterOrUpdater<ContractorsTableDataStateObject>,
    Resetter
  ];

  let contractorsTableStateHook: ContractorsTableStateHook | null = null;
  let contractorsTableId: string | null = null;

  switch (location.query.fromPage) {
    case "stored-index-create":
      contractorsTableStateHook = useAllContractorsTableGlobalState;
      contractorsTableId = `user-${userId}-program-${programId}-create-stored-index-contractors-table`;
      break;
    case "stored-index-details":
      contractorsTableStateHook = useStoredIndexContractorsTableGlobalState;
      contractorsTableId = `user-${userId}-program-${programId}-index-${indexId}-contractors-table`;
      break;
    case "discovery-dashboard":
      contractorsTableStateHook = useDiscoverySearchContractorsTableGlobalState;
      contractorsTableId = `user-${userId}-program-${programId}-collection-${location.query.collectionId}-discovery-search-contractors-table`;
      break;
    default:
      break;
  }

  const [{ selectedRows: contractorsData }] = contractorsTableStateHook!(
    contractorsTableId!
  );

  // fetch data

  const validateJobsDataset = useCallback(
    async (data: Array<JobValidationDataObject>): Promise<void> => {
      try {
        const response: FetchAPIResponse<JobValidationDataObject[]> = await fetchArgusAPI(
          "reviews/validate_jobs_dataset/",
          { method: "post", data: data }
        );
        const nextJobsData: JobsValidationsOrderedMap =
          jobsValidationsListToImmutableOrderedMap(response.data);

        setAreJobsValidated(true);
        setJobsDataAndFlags(nextJobsData);

        await hideLoader();
      } catch (err: any) {
        await hideLoader();

        if (err?.response?.status === 400) {
          extractDjangoHTTP400Messages(err).then((messages: string[]) => {
            if (messages) {
              showModalError(messages.join("\n"));
              logAsyncOperationError("validateJobsDataset", err);
            }
          });
        } else if (err?.response?.status > 400) {
          logAsyncOperationError("validateJobsDataset", err);
          showModalError("Error occurred while validating selected rows.");
        }
      }
    },
    [fetchArgusAPI, hideLoader, showModalError]
  );

  // utility functions

  const validateContractorsData = useCallback(async (): Promise<void> => {
    if (!contractorsData.size) return;

    showLoader();

    // dedupe data
    let dedupedContractorsData = emptyOrderedMap as unknown as ContractorsDataOrderedMap;
    let uniqueIdentifiersSet = emptySet as ImmutableSet<string>;

    contractorsData.forEach((item: ContractorDataMap) => {
      const identifier: string = getUniqueIdentifier(item);

      if (!uniqueIdentifiersSet.includes(identifier)) {
        uniqueIdentifiersSet = uniqueIdentifiersSet.add(identifier);
        dedupedContractorsData = dedupedContractorsData.set(item.get("id"), item);
      }
    });

    const duplicationsCount: number = contractorsData.size - dedupedContractorsData.size;

    if (!dedupedContractorsData.size) return;

    // send validation request to Argus
    await validateJobsDataset(
      dedupedContractorsData
        .toArray()
        .map<JobValidationDataObject>((item: ContractorDataMap) => ({
          category: item.get("category"),
          source: item.get("source"),
          country: item.get("country"),
          state: item.get("state"),
          city: item.get("city"),
          region: item.get("region"),
          // HINT: at this point all the mappings ("processed" object) shpild be in-place
          locations_ids: [item.get("processed")!.get("location_id")!],
          country_id: item.get("processed")!.get("country_id")!,
          region_id: item.get("processed")!.get("region_id")!,
          title: item.get("job_title"),
          description: item.get("job_description"),
          job_title_id: item.get("processed")!.get("job_title_id")!,
          collection_id: item.get("processed")!.get("job_collection_id")!,
          collection_title: item.get("processed")!.get("job_collection_title")!,
          collection_slug: item.get("processed")!.get("job_collection_slug")!,
          collection_public_title: item
            .get("processed")!
            .get("job_collection_public_title")!,
          collection_public_description: item
            .get("processed")!
            .get("job_collection_public_title_description")!,
          industry_id: item.get("processed")!.get("industry_id")!,
          industry_name: item.get("processed")!.get("industry_title")!,
          rate_type: item.get("rate_type"),
          rate_type_string: RATE_TYPES_LABELS[item.get("rate_type")],
          is_global_supplier_search: item.get("is_global_supplier_search", false),
          use_public_title: false,
        }))
    );

    if (duplicationsCount > 0) {
      showModalWarning(
        <span>
          <b>{duplicationsCount}</b> items were removed from the list as duplications.
        </span>
      );
    }
  }, [contractorsData, validateJobsDataset, showLoader, showModalWarning]);

  // effects

  useEffect(() => {
    validateContractorsData();
  }, [validateContractorsData]);

  return (
    <Stack>
      <Card fill>
        <CardActions>
          <CardActionsLeft>
            <CardHeaderTitle as="h2">
              Create Surveys From Private Index Data
            </CardHeaderTitle>
          </CardActionsLeft>
          <CardActionsRight>
            <NavigationButton icon="arrow-left" onClick={router.goBack}>
              Back to Private Index
            </NavigationButton>
          </CardActionsRight>
        </CardActions>
      </Card>

      <Card fill>
        <CardActions>
          <CardActionsLeft>
            <CardHeaderTitle as="h3">Create Survey Wizard</CardHeaderTitle>
          </CardActionsLeft>
          <CardActionsRight>
            <Text as="h3">
              <small>Page</small>&nbsp;&nbsp;{wizardPage}&nbsp;&nbsp;
              <small>of</small>&nbsp;&nbsp;
              {Object.values(CREATE_REVIEW_WIZARD_PAGES_LABELS).length}
            </Text>
          </CardActionsRight>
        </CardActions>

        {!areJobsValidated && (
          <CardBody>
            <Text>validating provided jobs list...</Text>
          </CardBody>
        )}
        {areJobsValidated && (
          <CardBody>
            <Stack css={{ alignItems: "stretch", width: "$full" }}>
              {jobsData.size === 0 && (
                <Alert color="warning">
                  <h4>No jobs data found</h4>
                </Alert>
              )}
              {jobsData.size > 0 && (
                <Text>
                  <Icon icon="info-circle" /> -{" "}
                  <small>Required steps marked with *</small>
                </Text>
              )}
              {jobsData.size > 0 && (
                <Alert color="warning">
                  <h4>You are in the middle of the import process!</h4>
                  <Text css={{ fontSize: "$lg" }}>
                    Don't refresh the page in order to be able to finish import.
                  </Text>
                </Alert>
              )}
              {jobsData.size > 0 && wizardPage === CREATE_REVIEW_WIZARD_PAGES.REVIEW && (
                <ReviewContractorsWizardPage
                  jobsData={jobsData}
                  setJobsData={setJobsDataAndFlags}
                  hasWarnings={hasWarnings}
                  hasAnyProblems={hasAnyProblems}
                  onChangeWizardPage={setWizardPage}
                  onGoBack={router.goBack}
                />
              )}
              {jobsData.size > 0 && wizardPage === CREATE_REVIEW_WIZARD_PAGES.SUBMIT && (
                <SubmitContractorsWizardPage
                  jobsData={jobsData}
                  onChangeWizardPage={setWizardPage}
                  onGoBack={router.goBack}
                />
              )}
            </Stack>
          </CardBody>
        )}
      </Card>
    </Stack>
  );
};

ReviewFromContractorsWizard.displayName = "ReviewFromContractorsWizard";

export default ReviewFromContractorsWizard;
