import React, { PropsWithChildren, useState } from "react";
import { useDroppable, useDraggable, useDndMonitor } from "@dnd-kit/core";

import Inline from "../../components/lib/Inline";
import Text from "../../components/lib/Text";
import { styled } from "../../stitches.config";

import type { UseDroppableArguments, UseDraggableArguments } from "@dnd-kit/core";
import type { SearchAllocatorInputObject } from "./types";

const SearchesRemainingDnDBox = styled(Inline, {
  padding: "$3 $6",
  border: "2px dashed $accent",
  background: "$white",
  fontSize: "$2xl",
  pointerEvents: "none",
  display: "none",

  variants: {
    isDrag: {
      true: {
        display: "flex",
        position: "absolute",
        zIndex: 9999999,
      },
    },
  },

  defaultVariants: {
    isDrag: false,
  },
});

type DroppableLayerProps = {
  onOver: (value: boolean) => void;
  onDrop: (value: boolean) => void;
  onSearchAllocatorInput: (value: SearchAllocatorInputObject) => void;
} & UseDroppableArguments;

export function DroppableLayer(props: PropsWithChildren<DroppableLayerProps>) {
  const { isOver, setNodeRef, active, over } = useDroppable({
    id: props.id,
  });

  useDndMonitor({
    onDragEnd(event) {
      if (
        event?.over?.id === props.id &&
        active?.id !== over?.id &&
        active?.id !== props.id
      ) {
        props.onDrop(true);
        props.onSearchAllocatorInput({
          source: String(active?.id),
          destination: String(props.id),
          amount: event.active.data.current?.searchesRemaining,
        });
      } else {
        props.onDrop(false); // only one User can be show drop form
      }
    },
  });

  if (active === null || over === null) {
    props.onOver(false);
  }

  if (active?.id !== over?.id) {
    props.onOver(isOver);
    props.onDrop(false);
  }

  return <div ref={setNodeRef}>{props.children}</div>;
}

type DraggableLayerProps = {
  searchesRemaining: number;
} & UseDraggableArguments;

export function DraggableLayer(props: PropsWithChildren<DraggableLayerProps>) {
  const { attributes, listeners, setNodeRef, transform, activatorEvent } = useDraggable({
    id: props.id,
    disabled: props.disabled,
    data: {
      searchesRemaining: props.searchesRemaining,
    },
  });

  const style =
    transform && activatorEvent !== null
      ? {
          transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
          left: `${(activatorEvent as MouseEvent).pageX}px`,
          top: `${(activatorEvent as MouseEvent).pageY}px`,
        }
      : undefined;

  return (
    <div ref={setNodeRef} {...listeners} style={{ cursor: "move" }}>
      {props.children}
      <SearchesRemainingDnDBox isDrag={!!transform} style={style} {...attributes}>
        <Text>{props.searchesRemaining}</Text>
      </SearchesRemainingDnDBox>
    </div>
  );
}

export function useDragAndDropState() {
  const defaultSearchAllocatorInput: SearchAllocatorInputObject = {
    source: "",
    destination: "",
    amount: 0,
  };

  const [isOver, setIsOver] = useState(false);
  const [isDrop, setIsDrop] = useState(false);
  const [searchAllocatorInput, setSearchAllocatorInput] =
    useState<SearchAllocatorInputObject>(defaultSearchAllocatorInput);

  return {
    isOver,
    setIsOver,
    isDrop,
    setIsDrop,
    searchAllocatorInput,
    setSearchAllocatorInput,
  };
}
