import React, { Component } from "react";
import Select from "react-select";
import { reactSelectStyles, reactSelectTheme } from "../../constants/reactSelect";

class IndustrySelectNew extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value, actionMeta) {
    // console.log('value', value);
    // console.log('actionMeta', actionMeta);

    this.props.onChange(
      value.map((industry) => ({
        id: industry.value,
        name: industry.label,
      }))
    );
  }

  render() {
    const industries = this.props.suggestions.map((e) => ({
      value: e.id,
      label: e.name,
    }));

    return (
      <Select
        inputId={this.props.id || "industrySelect"}
        name="industries"
        placeholder="Select Industry"
        openMenuOnFocus
        isMulti
        options={industries}
        value={this.props.value}
        onChange={this.handleChange}
        styles={reactSelectStyles}
        theme={reactSelectTheme}
      />
    );
  }
}

export default IndustrySelectNew;
