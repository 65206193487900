// @flow

import React, { Component } from "react";
import type { Element } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingIndicator from "../../ratecards/components/LoadingIndicator";
import { LEVEL_COLOR } from "../../../constants/css";
import InfiniteScrollSelect from "../../../components/selects/InfiniteScrollSelect";
import FlagIcon from "../../../components/FlagIcon";
import { CountryFilterCriterion, LevelFilterCriterion } from "../../../models/RateCard";
import SalaryHistogramChart from "./SalaryHistogramChart";
import { OverlayTrigger, Popover } from "react-bootstrap";
import RateCardDetailStore from "../../../stores/mobx/RateCardDetailStore";

type Props = {
  store: RateCardDetailStore,
};

class SalaryHistogramPanel extends Component<Props> {
  renderCountryItem = (
    country: CountryFilterCriterion,
    term?: ?string = null,
    highlight?: ?(string, string) => Element<any> = null
  ): Element<any> => {
    let title =
      term && highlight ? highlight(country.toString(), term) : country.toString();
    return (
      <span className="no-wrap flex">
        {country.isoCode && <FlagIcon code={country.isoCode} />} {title}
      </span>
    );
  };

  renderLevelItem = (
    level: LevelFilterCriterion,
    term?: ?string = null,
    highlight?: ?(string, string) => Element<any> = null
  ): Element<any> => {
    let title = term && highlight ? highlight(level.toString(), term) : level.toString();
    const romanNumeral = level.romanNumeral.toLowerCase();
    return (
      <span className="no-wrap">
        <FontAwesomeIcon
          icon="circle"
          className={classNames("level-filter-criterion-icon", {
            ["level-" + romanNumeral]: true,
          })}
        />
        {title}
      </span>
    );
  };

  _renderHintPopover = () => {
    return (
      <Popover
        className="pt-ui-popover"
        id="popover-positioned-top"
        title="About This Chart"
      >
        <p className="text-xx-small">
          Shows distribution of jobs over the min and max salary rates.
        </p>
        <h5>Restrictions</h5>
        <p className="text-xx-small">Region jobs are not supported.</p>
      </Popover>
    );
  };

  render() {
    const props = this.props;
    const store = props.store;
    const state = store.salaryHistogramState;
    const loading = store.network.loading || state.network.loading;
    const levelColor = state.selectedLevel
      ? LEVEL_COLOR[state.selectedLevel.literal]
      : null;

    return (
      <div className="panel panel-default no-margin">
        <div className="panel-heading">
          <div className="flex center-items">
            <div>
              <h3 className="panel-title">
                Salary Histogram
                <OverlayTrigger placement="top" overlay={this._renderHintPopover()}>
                  <FontAwesomeIcon
                    icon="question-circle"
                    className="icon"
                    style={{ marginLeft: ".25em" }}
                  />
                </OverlayTrigger>
              </h3>
            </div>
            <div style={{ flexGrow: 1 }} />
            <div style={{ width: 125, margin: "-10px 0 -10px 10px" }}>
              <InfiniteScrollSelect
                name="levels"
                items={state.levels}
                selectedItem={state.selectedLevel}
                onSelectedItemChange={state.onSelectedLevelChange}
                showSearchInput={false}
                renderItem={this.renderLevelItem}
                renderSelection={this.renderLevelItem}
                small
              />
            </div>
            <div style={{ width: 220, margin: "-10px -13px -10px 3px" }}>
              <InfiniteScrollSelect
                name="Countries"
                items={state.countriesView}
                selectedItem={state.selectedCountry}
                inputValue={state.countrySearch}
                onInputValueChange={state.onCountrySearchChange}
                onSelectedItemChange={state.onSelectedCountryChange}
                renderItem={this.renderCountryItem}
                renderSelection={this.renderCountryItem}
                small
              />
            </div>
          </div>
        </div>
        <div className="panel-body">
          <div style={{ position: "relative", height: 400, overflow: "hidden" }}>
            {!loading && (
              <SalaryHistogramChart
                maxData={state.chartData.maxSeries || []}
                minData={state.chartData.minSeries || null}
                levelColor={levelColor || "#ecd04b"}
                secondaryColor="#ecd04b"
                currencySymbol={state.chartData.currency || ""}
                binStart={state.chartData.binStart || 0}
                binSize={state.chartData.binSize || 0}
              />
            )}
            {loading && (
              <div
                className={classNames({
                  "loading-indicator-container": true,
                  show: loading,
                })}
              >
                <LoadingIndicator
                  style={{
                    height: 400,
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default observer(SalaryHistogramPanel);
