// @flow

import React, { Component } from "react";
import numeral from "numeral";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JobLibrary from "../../models/JobLibrary";
import { observer } from "mobx-react";
import JobLibraryListStore from "../../stores/mobx/JobLibraryListStore";
import { styled } from "../../../src/stitches.config";

const JobLibListItem = styled("div", {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  background: "white",
  border: "1px solid $primaryLight",
  borderRadius: "2px",
  color: "$brand",
  width: "230px",
  height: "150px",
  padding: "20px 20px 10px",
  marginBottom: "15px",
  textDecoration: "none",
  cursor: "pointer",
  transition: "border 150ms, box-shadow 250ms",
  "&:hover": { textDecoration: "none", border: "1px solid $brand" },
  variants: {
    color: {
      default: {
        backgroundColor: "#ffffff",
        boxShadow: "none",
      },
      selected: {
        backgroundColor: "$brandLightest",
        border: "1px solid $brandLight",
        boxShadow: "inset 0 3px $colors$brandLight",
      },
    },
  },
});

type Props = {
  library: JobLibrary,
  titlesFiltered: boolean,
  selected: boolean,
  onClick: (JobLibrary) => void,
  store: JobLibraryListStore,
};

class JobLibraryListItem extends Component<Props> {
  props: Props;
  handleOnClick = () => {
    if (this.props.onClick) {
      this.props.onClick(this.props.library);
    }
  };

  render() {
    const props = this.props;
    const library = props.library;
    const name = library.name || "N/A";
    const logoUrl = library.logoUrl;
    const description = library.description || "";
    const systemLibrary = library.systemLibrary || false;
    const jobsCount = library.totalTitleBySearchableCountries || 0;
    const titlesCount = library.titlesCount || 0;
    const titlesFiltered = props.titlesFiltered || false;
    const selected = props.selected || false;
    const store = props.store || {};
    const { sessionInfo } = store;
    const { client } = sessionInfo || {};
    const hasJobLibrarySubscription = client
      ? client.ratecardSubscriptionFlag || false
      : false;
    // const link = props.to || '/embed/client-job-library-next/libraries/';
    // const members = props.members || [];

    return (
      <JobLibListItem
        onClick={this.handleOnClick}
        color={selected ? "selected" : "default"}
      >
        <div>
          {systemLibrary && !hasJobLibrarySubscription && (
            <span className="cjl-system-library-icon">
              <FontAwesomeIcon icon="lock" className="icon" /> Managed by Pay Intel
            </span>
          )}
        </div>
        <div>
          <div
            className="cjl-list-item-name flex center-items"
            data-testid="job-libraries"
          >
            {Boolean(logoUrl) ? (
              <div className="logo">
                <div className="logo-wrapper">
                  <img className="logo-image" src={library.logoUrl} alt="Logo" />
                </div>
              </div>
            ) : (
              <div className="logo">
                <div className="logo-wrapper">
                  <FontAwesomeIcon icon="book" style={{ fontSize: "26px" }} />
                </div>
              </div>
            )}
            <div>{name}</div>
          </div>
        </div>
        <div className="cjl-list-item-description">{description}</div>
        {/*<Members className="cjl-list-item-members" members={members}/>*/}
        {titlesFiltered && (
          <div>
            <label className="cjl-matches-label">
              {numeral(titlesCount).format("0,0")} Title Matches
            </label>
          </div>
        )}
        {!titlesFiltered && (
          <div>
            <span className="caption">
              {numeral(jobsCount).format("0,0")} {jobsCount === 1 ? "Job" : "Jobs"}
            </span>
          </div>
        )}
      </JobLibListItem>
    );
  }
}

export default observer(JobLibraryListItem);
