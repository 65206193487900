import React from "react";
// import numeral from "numeral";

import Inline from "../../components/lib/Inline";
import Text from "../../components/lib/Text";
import Stack from "../../components/lib/Stack";
import Button from "../../components/lib/Button";
import NumberInput from "../../components/lib/NumberInput";
import Center from "../../components/lib/Center";

const MIN_SEARCH_QTY = 5;

type Props = {
  nextPage: () => void;
  total: number;
  searchPrice: number;
  searchTier: number;
  searchPurchaseCount: number;
  setSearchPurchaseCount: (searchPurchaseCount: number) => void;
};

export default function SearchAllocatorBuyModal(props: Props) {
  const {
    nextPage,
    total,
    searchPurchaseCount,
    searchPrice,
    searchTier,
    setSearchPurchaseCount,
  } = props;

  // NOTE: Something went wrong retrieving the search price
  if (searchPrice === null) {
    console.error("Client not configured for purchasing searches");
    return (
      <Stack css={{ fontSize: "$sm", alignItems: "center", gap: 0, padding: "$4" }}>
        <Text>Your account is not setup to purchase more searches.</Text>
        <Text>Contact Sales at 1-844-887-5501 to purchase more searches or email</Text>
        <Text>
          <a href="mailto:ratepoint@prounlimited.com">ratepoint@prounlimited.com.</a>
        </Text>
      </Stack>
    );
  }

  const handleSearchPurchaseCount = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newSearchPurchaseCount = Number.isNaN(e.target.valueAsNumber)
      ? 0
      : e.target.valueAsNumber;

    if (newSearchPurchaseCount < 0) return;

    let qty = MIN_SEARCH_QTY;

    if (newSearchPurchaseCount < searchPurchaseCount) {
      qty = -MIN_SEARCH_QTY;
    }

    const count = searchPurchaseCount + qty;

    setSearchPurchaseCount(count);
  };

  return (
    <Center css={{ gap: "$2", padding: "$4" }}>
      <p>Tier: {searchTier} search package</p>
      <p>Your price per search: ${searchPrice}</p>
      <p>Number of searches to purchase (5 search minimum)</p>
      <NumberInput
        fill
        value={searchPurchaseCount}
        onChange={handleSearchPurchaseCount}
      />
      <Inline css={{ paddingTop: "$2" }}>
        <Text centered>Total: ${total}</Text>
      </Inline>
      <Button
        size="large"
        color="success"
        disabled={searchPurchaseCount <= 0}
        onClick={nextPage}
      >
        Pay by Credit Card
      </Button>
    </Center>
  );
}
