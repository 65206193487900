// @flow
import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import LoadingIndicator from "../../ratecards/components/LoadingIndicator";
import BuyRate from "../../../models/BuyRate";
import BuyRateState from "../../../models/BuyRateState";
import type { FetchAPI, FetchGraphQL } from "../../../App";
import PunchOutRatesTab from "../../searches/components/PunchOutRatesTab";
import UndoDeleteMessageAlert from "../../searches/components/UndoDeleteMessageAlert";

type Props = {
  fetchGraphQL: FetchGraphQL,
  fetchAPI: FetchAPI,
  buyRate: BuyRate,
};

const PunchOutPeek = (props: Props) => {
  const { buyRate, fetchGraphQL, fetchAPI } = props;
  const search = buyRate.search;
  const expanded = buyRate.viewState.expanded;

  if (!search) return <code>Cannot render Punch Out peek</code>;

  const className = classNames("ratecards search-peek", {
    "search-peek-expand": expanded,
    "search-peek-collapse": !expanded,
  });

  if (buyRate.network.loading) {
    return (
      <div className={className}>
        <LoadingIndicator style={{ minHeight: 250 }} />
      </div>
    );
  } else if (buyRate.network.error) {
    return (
      <div className={className}>
        <h1>An error has occurred</h1>
        <pre>
          <code>{JSON.stringify(buyRate.network.error)}</code>
        </pre>
        {/*<button onClick={e => buyRate.getBuyRates()}>Retry</button>*/}
      </div>
    );
  }

  return (
    <div className={className}>
      <div className="search-peek-body full-width">
        <PunchOutPeekDetails
          buyRateState={new BuyRateState(fetchGraphQL, fetchAPI, search, buyRate)}
        />
      </div>
    </div>
  );
};

const PunchOutPeekDetails = observer((props: { buyRateState: BuyRateState }) => {
  const search = props.buyRateState.search;
  const buyRate = props.buyRateState.buyRate;
  const ratesTabSelected =
    props.buyRateState.selectedTab === props.buyRateState.PUNCH_OUT_RATES_TAB;

  return (
    <div
      className="panel panel-default panel-results full-width"
      onClick={props.buyRateState.ignoreDeletedInternalResult}
    >
      <div className="panel-body">
        <div className="row buy-rate-details">
          <div className="col-sm-3">
            <section className="section-detail text-small">
              <header className="text-xx-small">Job Title</header>
              {search.title}
            </section>
          </div>
          <div className="col-sm-3">
            <section className="section-detail text-small">
              <header className="text-xx-small">Location</header>
              {search.locationDetail()}
            </section>
          </div>
          <div className="col-sm-2">
            <section className="section-detail text-small">
              <header className="text-xx-small">Category</header>
              {search.category}
            </section>
          </div>
          <div className="col-sm-2">
            <section className="section-detail text-small">
              <header className="text-xx-small">Industry</header>
              {search.industry.title}
            </section>
          </div>
          <div className="col-sm-2">
            <section className="section-detail text-small">
              <header className="text-xx-small">Created Date</header>
              {buyRate.createdDate.format("MMMM DD, YYYY")}
            </section>
          </div>
        </div>

        {props.buyRateState.deletedPunchOutRow && (
          <UndoDeleteMessageAlert
            undoDelete={props.buyRateState.undoBuyRateDelete}
            punchOutRow={props.buyRateState.deletedPunchOutRow}
          />
        )}
        <div className="pull-right">
          {/* TODO: Re-enable this section once we have better scope for T3146 */}
          {/*<ToggleButton*/}
          {/*name="show-options"*/}
          {/*type="radio"*/}
          {/*value={props.buyRateState.PUNCH_OUT_RATES_TAB}*/}
          {/*selected={ratesTabSelected}*/}
          {/*onClick={props.buyRateState.onTabChange}*/}
          {/*>*/}
          {/*<FontAwesomeIcon icon="usd" fixedWidth className="icon" />*/}
          {/*<span className="hidden-xs hidden-sm"> Punchout Rates</span>*/}
          {/*</ToggleButton>*/}
          {/*<ToggleButton*/}
          {/*name="show-options"*/}
          {/*type="radio"*/}
          {/*value={props.buyRateState.PUNCH_OUT_MARKET_COMPARE_TAB}*/}
          {/*selected={compareTabSelected}*/}
          {/*onClick={props.buyRateState.onTabChange}*/}
          {/*>*/}
          {/*<FontAwesomeIcon icon="bar-chart" fixedWidth className="icon" />*/}
          {/*<span className="hidden-xs hidden-sm"> Market Comparison</span>*/}
          {/*</ToggleButton>*/}
        </div>
      </div>

      {ratesTabSelected && <PunchOutRatesTab buyRateState={props.buyRateState} />}

      {/*{compareTabSelected &&*/}
      {/*<PunchOutMarketCompareTab modalState={props.buyRateState} />}*/}

      <div
        className={classNames({
          "loading-indicator-container": true,
          show: props.buyRateState.networkState.loading,
        })}
      >
        <LoadingIndicator
          style={{
            minHeight: 0,
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
      </div>
    </div>
  );
});

export default observer(PunchOutPeek);
