import React from "react";

import { ActionButton } from "./ActionButton";
import { RATE_FEEDBACK_STATUS_TYPES } from "../../../types";
import { useVal5KPublicContext } from "../../../context/Val5KPublicContext";

import type { RATE_FEEDBACK_STATUS_TYPES_TYPE } from "../../../types";
import { CommentNRatesForm, type CommentNRatesValuesType } from "../FeedbackForm";

type ApplyValuesType = CommentNRatesValuesType & {
  status: typeof RATE_FEEDBACK_STATUS_TYPES.DENIED;
};

type DisagreeButtonProps = {
  payRateMin: number;
  payRateMax: number;
  currencySymbol: string;
  feedbackStatus: RATE_FEEDBACK_STATUS_TYPES_TYPE;
  feedbackComment: string | null;
  onApply: (values: ApplyValuesType) => Promise<void>;
};

export default function DisagreeButton(props: DisagreeButtonProps) {
  const {
    payRateMin,
    payRateMax,
    currencySymbol,
    feedbackStatus,
    feedbackComment,
    onApply,
  } = props;
  const { showConfirmationModal, closeConfirmationModal } = useVal5KPublicContext();

  // handlers

  const showDisagreeConfirmationModal = () => {
    const title = "Fix rates and leave a comment";
    const body = (
      <CommentNRatesForm
        payRateMin={payRateMin}
        payRateMax={payRateMax}
        comment={feedbackComment}
        currencySymbol={currencySymbol}
        onApply={(values) =>
          onApply({ ...values, status: RATE_FEEDBACK_STATUS_TYPES.DENIED })
        }
        onCancel={closeConfirmationModal}
      />
    );

    showConfirmationModal(body, title);
  };

  // render

  return (
    <ActionButton
      color={feedbackStatus === RATE_FEEDBACK_STATUS_TYPES.DENIED ? "danger" : "gray"}
      variant={feedbackStatus === RATE_FEEDBACK_STATUS_TYPES.DENIED ? "filled" : "light"}
      onClick={showDisagreeConfirmationModal}
    >
      Disagree
    </ActionButton>
  );
}

DisagreeButton.displayName = "DisagreeButton";
