import React, { useEffect, useRef, useCallback, useState } from "react";
import { useRecoilState } from "recoil";

import Stack from "../../../components/lib/Stack";
import Alert from "../../../components/lib/Alert";
import { ButtonGroup, ButtonGroupRight } from "../../../components/lib/ButtonGroup";
import { Column } from "../../../components/tables";

import { djangoPaginationKey, djangoPaginationSizeKey } from "../../../constants";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";

import { renderTableColumnsSpecs } from "../components/ExtendedRestfulTables";
import { RerunProcessingModal } from "../components/RerunIndexModals";
import {
  ProcessingsTable,
  processingsTableColumnsSpecs,
} from "../components/ProcessingsTable";
import {
  Card,
  CardHeader,
  CardBody,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
  CardHeaderTitle,
} from "../../../components/lib/Card";
import {
  NavigationButton,
  Button,
  IconButton,
  NavigationButtonProps,
} from "../../../components/lib/Button";
import { TickerPageLoader } from "../../../components/lib/TickerLoader";
import { InstructionsCheckMark } from "../lib/CheckMark";

import { contractorsProcessingTableGlobalState } from "../globalState";
import { transformContractorsProcessingsData } from "../dataConverters";
import { useIntervalDataRefresh, useScrollTo } from "../hooks";
import { PROCESSING_STATUSES } from "../types";
import { dateTimeFormatter } from "../constants";
import { rowIdGetter } from "../../../components/tables/utils";
import { usePLIContext } from "../context";

import type { ProcessingDataMap, ProcessingDataObject } from "../types";
import type { CommonProgramChildPageProps } from "../ProgramDataProvider";
import type {
  DataProviderRestful,
  FiltersDataProviderRestful,
  RestfulTableData,
  RestfulTableDataStateObject,
} from "../../../components/tables/types";
import type { FetchAPIResponse } from "../../../types/fetch";
import type { DjangoPaginatedResponse } from "../../../types/django";
import PromiseButton from "../../../components/lib/PromiseButton";

interface BackToPreviousViewButtonProps {
  color?: NavigationButtonProps["color"];
}

const BackToPreviousViewButton = (props: BackToPreviousViewButtonProps) => {
  const { router } = usePLIContext();

  return (
    <NavigationButton icon="arrow-left" onClick={router.goBack} {...props}>
      Back To Previous View
    </NavigationButton>
  );
};
BackToPreviousViewButton.displayName = "BackToPreviousViewButton";

const NoPermissionsBlock = () => {
  return (
    <Alert color="warning">
      <Stack fill css={{ alignItems: "start" }}>
        <h4>You have no permissions to access this page</h4>
        <BackToPreviousViewButton color="warning" />
      </Stack>
    </Alert>
  );
};
NoPermissionsBlock.displayNamwe = "NoPermissionsBlock";

const PageHeadBlock = () => {
  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft>
          <CardHeaderTitle>All Rerun Indexes Requests</CardHeaderTitle>
        </CardActionsLeft>
        <CardActionsRight>
          <BackToPreviousViewButton />
        </CardActionsRight>
      </CardActions>

      <CardBody>
        <h3>
          This page lists all indexes rerun requests in the system (after uploads
          processings are also displayed here).
        </h3>
        <h3>Here you can:</h3>
        <h3>
          <InstructionsCheckMark /> Browse all indexes processings requests in the system.
        </h3>
        <h3>
          <InstructionsCheckMark /> Track recently launched processings state.
        </h3>
        <h3>
          <InstructionsCheckMark /> Manage processings state, you can stop and rerun any
          processing.
        </h3>
      </CardBody>
    </Card>
  );
};
PageHeadBlock.displayName = "PageHeadBlock";

type RerunProcessingModalState = {
  show: boolean;
  rowData: ProcessingDataMap | null;
};

const ProcessingsList = (props: CommonProgramChildPageProps) => {
  const {
    clientId,
    isPTAdmin,
    programId,
    fetchM8API,
    fetchM8FilteringAPI,
    showModalError,
    showConfirmationModal,
    closeConfirmationModal,
  } = props;

  // modals

  const [rerunProcessingModalState, setRerunProcessingModalState] =
    useState<RerunProcessingModalState>({
      show: false,
      rowData: null,
    });

  const showProcessingRerunModal = useCallback((row: ProcessingDataMap) => {
    setRerunProcessingModalState({
      show: true,
      rowData: row,
    });
  }, []);

  const hideProcessingRerunModal = useCallback(() => {
    setRerunProcessingModalState({
      show: false,
      rowData: null,
    });
  }, []);

  // state

  const [processingsData, setProcessingsDataState] = useRecoilState(
    contractorsProcessingTableGlobalState
  );

  // data fetch functions

  const fetchContractorsProcessingsData: DataProviderRestful<ProcessingDataMap> =
    useCallback(
      async (urlQuery = {}, filterQuery = {}, nextStateUpdates = {}) => {
        try {
          const response: FetchAPIResponse<
            DjangoPaginatedResponse<ProcessingDataObject>
          > = await fetchM8FilteringAPI(`programs/${programId}/processings/filtered/`, {
            params: urlQuery,
            data: filterQuery,
          });
          const nextDataState: Partial<RestfulTableDataStateObject<ProcessingDataMap>> =
            transformContractorsProcessingsData(response.data);

          setProcessingsDataState(
            (prevState: RestfulTableDataStateObject<ProcessingDataMap>) => ({
              ...prevState,
              ...nextStateUpdates,
              ...nextDataState,
              loaded: true,
            })
          );

          return nextDataState;
        } catch (err: any) {
          logAsyncOperationError("fetchProcessingsList", err);
          showModalError(
            "Error occurred while loading processings list. Please, try again later."
          );
          throw err;
        }
      },
      [programId, setProcessingsDataState, fetchM8FilteringAPI, showModalError]
    );

  const fetchContractorsProcessingsFiltersData: FiltersDataProviderRestful = useCallback(
    async (urlQuery = {}, filtersQuery = {}) => {
      try {
        const response: FetchAPIResponse<DjangoPaginatedResponse<any>> =
          await fetchM8FilteringAPI(
            `programs/${programId}/processings/values/filtered/`,
            {
              params: urlQuery,
              data: filtersQuery,
            }
          );

        return response.data;
      } catch (err: any) {
        logAsyncOperationError("fetchProcessingsListFilterValues", err);
        showModalError(
          "Error occurred while loading filter values. Please, try again later."
        );
        throw err;
      }
    },
    [programId, fetchM8FilteringAPI, showModalError]
  );

  // utility functions

  const refreshContractorsProcessingsData = useCallback(async () => {
    return fetchContractorsProcessingsData(
      {
        [djangoPaginationKey]: processingsData.activePage,
        [djangoPaginationSizeKey]: processingsData.itemsPerPage,
      },
      processingsData.filtersQuery.toJS()
    );
  }, [
    processingsData.activePage,
    processingsData.itemsPerPage,
    processingsData.filtersQuery,
    fetchContractorsProcessingsData,
  ]);

  // scroll to table top

  const scrollToRef = useScrollTo([processingsData.activePage]);

  // initial data load

  const processingsDataLoadedRef = useRef(false);

  useEffect(() => {
    if (!processingsDataLoadedRef.current) {
      refreshContractorsProcessingsData();
      processingsDataLoadedRef.current = true;
    }
  }, [refreshContractorsProcessingsData, processingsDataLoadedRef]);

  // running processings refresh

  const needProcessingsRefresh = (
    processingsList: RestfulTableData<ProcessingDataMap>
  ) => {
    return !!processingsList.find(
      (item) => item.get("status") === PROCESSING_STATUSES.RUNNING
    );
  };

  useIntervalDataRefresh(
    refreshContractorsProcessingsData,
    processingsData.data,
    needProcessingsRefresh
  );

  // handlers

  const handleStopProcessing = useCallback(
    async (row: ProcessingDataMap) => {
      const processingId = row.get("id");
      const processingProgramId = row.get("program_id");

      try {
        await fetchM8API(
          `programs/${processingProgramId}/processings/${processingId}/stop/`,
          { method: "post" }
        );

        refreshContractorsProcessingsData();
        closeConfirmationModal();
      } catch (err: any) {
        logAsyncOperationError("stopProcessing", err);
        showModalError("Error occurred while stopping processing.");
      }
    },
    [
      refreshContractorsProcessingsData,
      fetchM8API,
      showModalError,
      closeConfirmationModal,
    ]
  );

  const handleConfirmStopProcessing = useCallback(
    (row: ProcessingDataMap) => {
      const id = row.get("id");
      const startedAt = row.get("started_at");
      const totalItems = row.get("total_items");
      const header = "Confirm stop processing action";
      const message = (
        <div>
          <span>
            You are about to stop following processing. Please confirm this action in
            order to proceed.
            <br />
            <b>{`- #${id}, ${totalItems} rows (started at ${dateTimeFormatter(
              startedAt
            )})`}</b>
          </span>
        </div>
      );
      const footer = (
        <ButtonGroupRight fill css={{ flexDirection: "row-reverse" }}>
          <Button size="large" onClick={closeConfirmationModal}>
            No, Cancel
          </Button>
          <PromiseButton
            icon={["far", "stop-circle"]}
            loadingText="Yes, Stop"
            color="accent"
            size="large"
            onClick={() => handleStopProcessing(row)}
          >
            Yes, Stop
          </PromiseButton>
        </ButtonGroupRight>
      );

      showConfirmationModal(message, header, footer);
    },
    [handleStopProcessing, showConfirmationModal, closeConfirmationModal]
  );

  const handlerRerunProcessing = useCallback(
    async (row: ProcessingDataMap, includeRemapping: boolean = false) => {
      const processingId = row.get("id");
      const processingProgramId = row.get("program_id");

      try {
        await fetchM8API(
          `programs/${processingProgramId}/processings/${processingId}/rerun/`,
          {
            method: "post",
            params: { __include_remapping: includeRemapping },
          }
        );

        refreshContractorsProcessingsData();
        hideProcessingRerunModal();
      } catch (err: any) {
        logAsyncOperationError("rerunProcessing", err);
        showModalError("Error occurred while rerunning processing.");
      }
    },
    [
      refreshContractorsProcessingsData,
      hideProcessingRerunModal,
      fetchM8API,
      showModalError,
    ]
  );

  // render

  if (!processingsData.loaded) return <TickerPageLoader />;
  else if (!isPTAdmin) return <NoPermissionsBlock />;

  const renderActionsButtons = (row: ProcessingDataMap) => {
    const status = row.get("status");
    const rowClientId = row.get("client_id");

    if (clientId !== rowClientId) return "";

    return (
      <ButtonGroup css={{ justifyContent: "center" }}>
        {status === PROCESSING_STATUSES.RUNNING && (
          <IconButton
            icon={["far", "stop-circle"]}
            color="accent"
            variant="outlined"
            title="Stop processing"
            onClick={() => handleConfirmStopProcessing(row)}
          />
        )}
        {status !== PROCESSING_STATUSES.RUNNING && (
          <IconButton
            icon="sync-alt"
            color="brand"
            variant="outlined"
            title="Rerun"
            onClick={() => showProcessingRerunModal(row)}
          />
        )}
      </ButtonGroup>
    );
  };

  const columns = renderTableColumnsSpecs(processingsTableColumnsSpecs).concat(
    <Column
      key="_actions"
      uniqueKey="_actions"
      title="Actions"
      getter={renderActionsButtons}
    />
  );

  return (
    <Stack>
      <RerunProcessingModal
        show={rerunProcessingModalState.show}
        processingData={rerunProcessingModalState.rowData}
        onRerun={handlerRerunProcessing}
        onHide={hideProcessingRerunModal}
      />
      <PageHeadBlock />
      <Card fill>
        <CardHeader>
          <CardHeaderTitle as="h3" ref={scrollToRef}>
            All Requests List
          </CardHeaderTitle>
        </CardHeader>

        <CardBody>
          <ProcessingsTable
            multimode
            rowIdGetter={rowIdGetter}
            dataProvider={fetchContractorsProcessingsData}
            filtersDataProvider={fetchContractorsProcessingsFiltersData}
            {...processingsData}
          >
            {columns}
          </ProcessingsTable>
        </CardBody>
      </Card>
    </Stack>
  );
};
ProcessingsList.displayName = "ProcessingsList";

export default ProcessingsList;
