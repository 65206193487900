import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import JobTitleSelectNew from "../../components/selects/JobTitleSelectNew";
import IndustrySelectNew from "../../components/selects/IndustrySelectNew";
import MultiRegionSelectNew from "../../components/selects/MultiRegionSelectNew";
import LocationSelectNew from "../../components/selects/LocationSelectNew";
import LocationModal from "../shared/modals/LocationModal";
import ContactRateSpecialistModal from "./ContactRateSpecialistModal";
import ClientJobLibrarySelect from "../../components/selects/ClientJobLibrarySelect";
import JobTitleInfoModal from "./JobTitleInfoModal";
import RequestMoreSearchesModal from "./RequestMoreSearchesModal";
import BuyMoreSearchesModal from "./BuyMoreSearchesModal";
import CheckBox from "../shared/CheckBox";
import WorkerTypeSelectNew from "../../components/selects/WorkerTypeSelectNew";

function remove_duplicates(objectsArray) {
  let usedObjects = {};

  for (let i = objectsArray.length - 1; i >= 0; i--) {
    let so = JSON.stringify(objectsArray[i]);

    if (usedObjects[so]) {
      objectsArray.splice(i, 1);
    } else {
      usedObjects[so] = true;
    }
  }

  return objectsArray;
}

const RegionField = observer(function (props) {
  return (
    <div
      className={`col-xs-12 form-group selectfixnew ${
        props.scheduledSearchCreateStore.regionValidTags ? "" : "has-error"
      }`}
    >
      <label htmlFor="regionSelect">Region (optional)</label>
      <MultiRegionSelectNew
        fetchGraphQL={props.fetchGraphQL}
        onChange={(regions) => {
          const rateSearchStore = props.rateSearchStore;
          const scheduledSearchCreateStore = props.scheduledSearchCreateStore;
          scheduledSearchCreateStore.handleRegionChange(regions);
          rateSearchStore.checkGSS(scheduledSearchCreateStore.locations, regions);
          rateSearchStore.checkShowWorkerTypes(
            scheduledSearchCreateStore.locations,
            scheduledSearchCreateStore.regionTags,
            rateSearchStore.tags,
            rateSearchStore.searchRateType
          );
        }}
      />
    </div>
  );
});

const RateTypeField = observer(function (props) {
  return (
    <div className="col-xs-12 form-group">
      <label id="rateTypeLabel">Rate Type</label>
      <br />
      <div aria-labelledby="rateTypeLabel" className="btn-group btn-group-sm">
        <button
          type="button"
          onClick={() => {
            const rateSearchStore = props.rateSearchStore;
            const scheduledSearchCreateStore = props.scheduledSearchCreateStore;
            rateSearchStore.searchRateType = 1;
            rateSearchStore.checkShowWorkerTypes(
              scheduledSearchCreateStore.locations,
              scheduledSearchCreateStore.regionTags,
              rateSearchStore.tags,
              rateSearchStore.searchRateType
            );
          }}
          className={
            props.rateSearchStore.searchRateType === 1
              ? "btn btn-lg btn-primary active mr-none rs-z-index-zero"
              : "btn btn-lg btn-default mr-none"
          }
        >
          Hourly
        </button>
        <button
          type="button"
          onClick={() => {
            const rateSearchStore = props.rateSearchStore;
            const scheduledSearchCreateStore = props.scheduledSearchCreateStore;
            props.rateSearchStore.searchRateType = 2;
            rateSearchStore.checkShowWorkerTypes(
              scheduledSearchCreateStore.locations,
              scheduledSearchCreateStore.regionTags,
              rateSearchStore.tags,
              rateSearchStore.searchRateType
            );
          }}
          className={
            props.rateSearchStore.searchRateType === 2
              ? "btn btn-lg btn-primary active mr-none rs-z-index-zero"
              : "btn btn-lg btn-default mr-none"
          }
        >
          Annual
        </button>
      </div>
    </div>
  );
});

const IndustryField = observer(function (props) {
  // NOTE: rateSearchStore.tags are actually industries!! Not sure why atlogys did this
  let selectedIndustries = [];
  props.rateSearchStore.tags.forEach((industry) => {
    if (industry) {
      let object = {
        value: industry.id,
        label: industry.name,
      };
      selectedIndustries.push(object);
    }
  });

  return (
    <div
      className={`col-xs-12 form-group selectfixnew ${
        props.rateSearchStore.tagsValid ? "" : "has-error"
      }`}
    >
      <label htmlFor="industrySelect">Industry</label>
      <IndustrySelectNew
        suggestions={props.rateSearchStore.industries}
        value={selectedIndustries}
        onChange={(industries) => {
          const rateSearchStore = props.rateSearchStore;
          const scheduledSearchCreateStore = props.scheduledSearchCreateStore;
          rateSearchStore.handleIndustryChange(industries);
          rateSearchStore.checkShowWorkerTypes(
            scheduledSearchCreateStore.locations,
            scheduledSearchCreateStore.regionTags,
            rateSearchStore.tags,
            rateSearchStore.searchRateType
          );
        }}
      />
    </div>
  );
});

const WorkerTypeField = observer(function (props) {
  const rateSearchStore = props.rateSearchStore;
  const { selectedWorkerType, workerTypes, handleWorkerTypeChange, workerTypesLoading } =
    rateSearchStore;
  let placeholder = "Select Worker Type";
  if (workerTypesLoading) placeholder = "Loading...";
  if (!workerTypesLoading && workerTypes.length === 0) placeholder = "None Available";
  return (
    <div className={`col-xs-12 form-group selectfixnew`}>
      <label htmlFor="industrySelect">Worker Type (Optional)</label>
      <WorkerTypeSelectNew
        disabled={workerTypes.length === 0 || workerTypesLoading}
        placeholder={placeholder}
        workerTypes={workerTypes}
        value={selectedWorkerType}
        isLoading={workerTypesLoading}
        onChange={(workerType) => handleWorkerTypeChange(workerType)}
      />
      {selectedWorkerType && selectedWorkerType.description && (
        <label style={{ marginTop: 5 }}>
          <strong>Worker Type Description: </strong> {selectedWorkerType.description}
        </label>
      )}
    </div>
  );
});

const JobLabelField = observer(function (props) {
  const tooltip = (
    <Tooltip id="tooltip">
      The Job Label is your internal job title identifier. The Job Title you select is the
      generic job title that will be used for searching along with the job description.
    </Tooltip>
  );

  return (
    <div
      className={`col-xs-12 col-sm-12 form-group ${
        props.jobLabelListStore.joblabelValid ? "" : "has-error"
      }`}
    >
      <label htmlFor="jobLabel">Job Label (optional)</label>
      <input
        id="jobLabel"
        name="jobLabel"
        className="form-control"
        placeholder="Job Label"
        maxLength="250"
        type="text"
        value={props.jobLabelListStore.joblabel}
        onChange={props.jobLabelListStore.changeJobLabelText}
      />
      <OverlayTrigger placement="bottom" overlay={tooltip}>
        <label className="tool_text">
          What's this?&nbsp;
          <FontAwesomeIcon icon="question-circle" aria-hidden="true" />
        </label>
      </OverlayTrigger>
    </div>
  );
});

const JobTitleField = observer(function (props) {
  return (
    <div
      className={`col-xs-12 col-sm-12 form-group ${
        props.jobLabelListStore.jobtitleValid ? "" : "has-error"
      }`}
    >
      <label htmlFor="jobTitleSelect">Job Title</label>
      <JobTitleSelectNew
        /* HACK: Force react-select to refetch options on selection of client job library */
        /* HACK: https://github.com/JedWatson/react-select/issues/1879#issuecomment-316871520 */
        key={JSON.stringify(props.rateSearchStore.clientJobLibraryId)}
        clientIsClientJobLibrary={props.clientIsClientJobLibrary}
        getJobTitles={
          props.clientIsClientJobLibrary
            ? props.rateSearchStore.getClientLibraryJobTitles
            : props.jobLabelListStore.JobTitle
        }
        onChange={(selectedJobTitle) => {
          props.jobLabelListStore.changeJobTitleText(selectedJobTitle);
          if (this.props.clientIsClientJobLibrary) {
            props.scheduledSearchCreateStore.resetLocationForm();
            props.rateSearchStore.searchableCountries =
              selectedJobTitle.clientJobLibraryInfo.searchableCountries;
            props.rateSearchStore.adhocCountries =
              selectedJobTitle.clientJobLibraryInfo.adhocCountries;
            props.rateSearchStore.certifiedCountries =
              selectedJobTitle.clientJobLibraryInfo.certifiedCountries;
            // console.log('selectedJobTitle.clientJobLibraryInfo', selectedJobTitle.clientJobLibraryInfo);
            // HACK: Filter the location modal's countries list to the selected job's searchable countries.
            props.rateSearchStore
              .countries()
              .then(
                (res) => (props.scheduledSearchCreateStore.countriesList = res.countries)
              );
          }
        }}
      />
      {props.clientIsClientJobLibrary && <RequestJobTitleAlert />}
      <label
        className="tool_text pt-link"
        title="Click Here to Learn about Job Title search."
        onClick={props.rateSearchStore.jobTitleInfoModal.showModal}
      >
        More Info&nbsp;
        <FontAwesomeIcon icon="info-circle" aria-hidden="true" />
      </label>
    </div>
  );
});

const JobLibraryField = observer(function (props) {
  return (
    <div
      className={`col-xs-12 col-sm-12 form-group ${
        props.jobLabelListStore.jobtitleValid ? "" : "has-error"
      }`}
    >
      <label htmlFor="librarySelect">Job Library</label>
      <ClientJobLibrarySelect
        getClientLibraries={props.rateSearchStore.getClientLibraries}
        onChange={props.rateSearchStore.selectClientJobLibrary}
      />
    </div>
  );
});

const JobDescriptionField = observer(function (props) {
  const rateSearchStore = props.rateSearchStore;
  let rows = 24;
  if (rateSearchStore.showGSS) rows += 3;
  if (rateSearchStore.showWorkerTypes && rateSearchStore.workerTypes.length > 0)
    rows += 5;

  return (
    <div
      className={`col-xs-12 col-sm-12 form-group ${
        props.jobLabelListStore.jobdescriptionValid ? "" : "has-error"
      }`}
    >
      <label htmlFor="jobDescription">Job Description (optional)</label>
      <textarea
        className="form-control"
        style={{ resize: "vertical" }}
        id="jobDescription"
        rows={rows}
        readOnly={props.readOnly}
        placeholder="You may enter a job description that includes relevant information such as job duties and requirements, education, and certifications."
        value={props.jobLabelListStore.jobdescription}
        onChange={props.jobLabelListStore.changeJobDescriptionText}
      />
    </div>
  );
});

const LocationField = observer(function (props) {
  let selectedLocations = [];
  let locationsUnique = remove_duplicates(props.scheduledSearchCreateStore.locations);
  locationsUnique.forEach((e) => {
    if (e) {
      let label = e.country;
      if (e.state && e.state.length > 0) {
        label = e.state + ", " + label;
      }
      if (e.city && e.city.length > 0) {
        label = e.city + ", " + label;
      }
      let object = {
        value: e.locationId,
        label: label,
        countryId: e.countryId,
      };
      selectedLocations.push(object);
    }
  });

  const locationTooltip = (
    <Tooltip id="tooltip">
      Selecting "Advanced" displays the Countries that the Job Title is available to
      search. Selecting the Country will then list the locations for selection.
    </Tooltip>
  );
  return (
    <div
      className={`col-xs-12 form-group ${
        props.scheduledSearchCreateStore.locationsValid ? "" : "has-error"
      }`}
    >
      <label htmlFor="locationSelect">Location</label>
      <LocationSelectNew
        /* HACK: Force react-select to refetch options on selection of client job library */
        /* HACK: https://github.com/JedWatson/react-select/issues/1879#issuecomment-316871520 */
        key={JSON.stringify(props.rateSearchStore.searchableCountries)}
        clientIsClientJobLibrary={props.clientIsClientJobLibrary}
        certifiedCountries={props.rateSearchStore.certifiedCountries}
        adhocCountries={props.rateSearchStore.adhocCountries}
        locationTitle={props.rateSearchStore.locationTitle}
        value={selectedLocations}
        onChange={(locations) => {
          const rateSearchStore = props.rateSearchStore;
          const scheduledSearchCreateStore = props.scheduledSearchCreateStore;
          scheduledSearchCreateStore.onLocationChange(locations);
          rateSearchStore.checkGSS(locations, scheduledSearchCreateStore.regionTags);
          rateSearchStore.checkShowWorkerTypes(
            scheduledSearchCreateStore.locations,
            scheduledSearchCreateStore.regionTags,
            rateSearchStore.tags,
            rateSearchStore.searchRateType
          );
        }}
      />
      <OverlayTrigger placement="top" overlay={locationTooltip}>
        <label
          className="tool_text pt-link"
          onClick={props.scheduledSearchCreateStore.showLocationModal}
        >
          Advanced&nbsp;
          <FontAwesomeIcon icon="question-circle" aria-hidden="true" />
        </label>
      </OverlayTrigger>
    </div>
  );
});

const GSSField = observer(function (props) {
  return (
    <div className="col-xs-12">
      <div className="pt-ui" style={{ background: "none", padding: 0 }}>
        <div className="form-group">
          <CheckBox
            type="checkbox"
            name="gss"
            selected={props.rateSearchStore.isGSS}
            onChange={props.rateSearchStore.onIsGSSChange}
            labelName={"Global Supplier Search"}
          />
        </div>
      </div>
    </div>
  );
});

class NewRateSearchForm extends Component {
  componentWillUnmount() {
    this.props.rateSearchStore.messaging.removeAll();
    this.props.jobLabelListStore.jobtitleValid = true;
    this.props.jobLabelListStore.joblabelValid = true;
    this.props.scheduledSearchCreateStore.regionValidTags = true;
    this.props.scheduledSearchCreateStore.locationsValid = true;
    this.props.jobLabelListStore.jobdescriptionValid = true;
    this.props.rateSearchStore.tagsValid = true;
  }

  componentDidMount() {
    this.props.rateSearchStore.messaging.removeAll();
    this.props.rateSearchStore.resetForm();
    this.props.scheduledSearchCreateStore.clearLocationModal();
    this.props.rateSearchStore.clientPerSearchPricing = this.props.clientPerSearchPricing;
    if (this.props.clientPerSearchPricing) {
      this.props.rateSearchStore.getSearchesRemaining();
    }

    this.props.rateSearchStore.getIndustries();
    this.props.rateSearchStore
      .countries()
      .then(
        (res) => (this.props.scheduledSearchCreateStore.countriesList = res.countries)
      );
  }

  render() {
    const jobLabelListStore = this.props.jobLabelListStore;
    const rateSearchStore = this.props.rateSearchStore;
    const scheduledSearchCreateStore = this.props.scheduledSearchCreateStore;

    let locTags = [];
    let tagsLoc = scheduledSearchCreateStore.calculateTags(
      scheduledSearchCreateStore.locationHistory
    );
    if (tagsLoc !== "") {
      tagsLoc.split("|").forEach((e) => {
        if (e !== " ") {
          locTags.push(
            <span key={e} className="Select-item-label">
              {e}
            </span>
          );
        }
      });
    }

    let pspSection = null;
    if (this.props.clientPerSearchPricing) {
      let searchesRemainingCount = 0;
      let searchesRemainingLabel = "Searches remaining";
      let unlimited = false;

      const resourceAllocations = this.props.rateSearchStore.resourceAllocations;
      const loadingSearchCount = resourceAllocations === null;
      if (!loadingSearchCount && resourceAllocations.length > 0) {
        if (resourceAllocations[0].isUnlimited) {
          searchesRemainingCount = "Unlimited";
          searchesRemainingLabel = "Searches";
          unlimited = true;
        } else {
          searchesRemainingCount = resourceAllocations[0].balance;
        }
      } else if (loadingSearchCount) {
        searchesRemainingLabel = "Loading remaining searches...";
      }

      pspSection = (
        <div className="countdown">
          <span data-testid="searchesRemainingLabel">
            {!loadingSearchCount && <strong>{searchesRemainingCount}</strong>}{" "}
            <span className="remaining-title">{searchesRemainingLabel}</span>
          </span>
          <button
            className="btn yellow-btn btn-green"
            onClick={rateSearchStore.requestMoreSearchesModal.showModal}
            disabled={loadingSearchCount || unlimited}
          >
            Request More
          </button>
        </div>
      );

      if (this.props.clientPspStoreFlag) {
        pspSection = (
          <div className="countdown">
            <span data-testid="searchesRemainingLabel">
              {!loadingSearchCount && <strong>{searchesRemainingCount}</strong>}{" "}
              <span className="remaining-title">{searchesRemainingLabel}</span>
            </span>
            <button
              className="btn yellow-btn btn-green"
              onClick={rateSearchStore.buyMoreSearchesModal.showModal}
              disabled={loadingSearchCount}
            >
              Buy More
            </button>
          </div>
        );
      }
    }

    let cjlLocationSection = null;
    let pspLocationSection = null;
    let locationSelect = (
      <div className="row">
        <div className="col-md-12 col-lg-6">
          <div className="row">
            <LocationField
              rateSearchStore={rateSearchStore}
              scheduledSearchCreateStore={scheduledSearchCreateStore}
              clientIsClientJobLibrary={this.props.clientIsClientJobLibrary}
            />
          </div>
        </div>
        <div className="col-md-12 col-lg-6">
          <div className="row">
            <RegionField
              rateSearchStore={rateSearchStore}
              scheduledSearchCreateStore={scheduledSearchCreateStore}
              fetchGraphQL={this.props.fetchGraphQL}
            />
          </div>
        </div>

        {rateSearchStore.showGSS && <GSSField rateSearchStore={rateSearchStore} />}
      </div>
    );

    if (this.props.clientIsClientJobLibrary) {
      cjlLocationSection = locationSelect;
    } else {
      // Support both PSP and non-PSP users by enabling location select when it's not client library.
      pspLocationSection = locationSelect;
    }

    return (
      <section className="content-wrapper equalHW">
        <div className="tab-content">
          <div className="tab-pane active">
            {rateSearchStore.messaging.messages.length > 0 && (
              <div
                id="alert_msg"
                className="alert alert-danger alert-dismissable fade in pt-user-alert"
              >
                <a
                  href="#close"
                  className="close"
                  data-dismiss="alert"
                  aria-label="close"
                  onClick={rateSearchStore.messaging.removeMessage.bind(
                    rateSearchStore,
                    rateSearchStore.messaging.messages[0]
                  )}
                >
                  &times;
                </a>
                <strong>{rateSearchStore.messaging.messages[0].messageLabel}</strong>
                {rateSearchStore.messaging.messages[0].message}
              </div>
            )}
            <section className="content-header">
              <div className="flex-row flex-align-baseline">
                <h3>Start Your Rate Search</h3>
                <div className="flex-fill" />
                {pspSection}
              </div>
            </section>
            <section className="search-card-content atlfixformborder">
              <form className="custom-form">
                <div className="row">
                  <div className="col-xs-6">
                    {this.props.clientIsClientJobLibrary && (
                      <div className="row">
                        <JobLibraryField
                          jobLabelListStore={jobLabelListStore}
                          rateSearchStore={rateSearchStore}
                        />
                      </div>
                    )}

                    <div className="row">
                      <JobTitleField
                        jobLabelListStore={jobLabelListStore}
                        rateSearchStore={rateSearchStore}
                        scheduledSearchCreateStore={scheduledSearchCreateStore}
                        clientIsClientJobLibrary={this.props.clientIsClientJobLibrary}
                      />
                    </div>

                    {cjlLocationSection}

                    {!this.props.clientIsClientJobLibrary && (
                      <div className="row">
                        <JobLabelField jobLabelListStore={jobLabelListStore} />
                      </div>
                    )}

                    <div className="row">
                      <IndustryField
                        rateSearchStore={rateSearchStore}
                        scheduledSearchCreateStore={scheduledSearchCreateStore}
                      />
                    </div>

                    {pspLocationSection}

                    <div className="row">
                      <RateTypeField
                        rateSearchStore={rateSearchStore}
                        scheduledSearchCreateStore={scheduledSearchCreateStore}
                      />
                    </div>

                    {rateSearchStore.showWorkerTypes &&
                      rateSearchStore.workerTypes.length > 0 && (
                        <div className="row">
                          <WorkerTypeField rateSearchStore={rateSearchStore} />
                        </div>
                      )}
                  </div>
                  <div className="col-xs-6">
                    <div className="row">
                      <JobDescriptionField
                        rateSearchStore={rateSearchStore}
                        jobLabelListStore={jobLabelListStore}
                        readOnly={this.props.clientIsClientJobLibrary}
                      />
                    </div>
                    <div className="text-right">
                      <button
                        onClick={() => rateSearchStore.cRSpeciatist()}
                        type="button"
                        className="btn btn-lg btn-default"
                      >
                        Contact Rate Specialist
                      </button>
                      <button
                        onClick={() => rateSearchStore.findRates()}
                        type="button"
                        className="btn btn-lg btn-green"
                        disabled={rateSearchStore.networkDetailRateSearch.loading}
                      >
                        Find Rate
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </section>
          </div>
        </div>
        <JobTitleInfoModal
          modalState={rateSearchStore.jobTitleInfoModal}
          clientIsClientJobLibrary={this.props.clientIsClientJobLibrary}
        />
        <LocationModal
          store={scheduledSearchCreateStore}
          rateSearchStore={rateSearchStore}
          clientIsClientJobLibrary={this.props.clientIsClientJobLibrary}
        />
        <ContactRateSpecialistModal rateSearchStore={rateSearchStore} />
        <RequestMoreSearchesModal
          rateSearchStore={rateSearchStore}
          modalState={rateSearchStore.requestMoreSearchesModal}
        />
        <BuyMoreSearchesModal
          rateSearchStore={rateSearchStore}
          modalState={rateSearchStore.buyMoreSearchesModal}
        />
      </section>
    );
  }
}

NewRateSearchForm.propTypes = {
  rateSearchStore: PropTypes.object.isRequired,
};

function RequestJobTitleAlert(props) {
  return (
    <div className="rs-alert-info" role="alert">
      <div className="media-left">
        <div>
          <span
            className="glyphicon glyphicon-info-sign small"
            style={{ marginRight: "4px" }}
          >
            {" "}
          </span>
        </div>
      </div>
      <div className="media-body">
        <div>
          <i>
            Don't see what you're looking for? You can&nbsp;
            <Link
              id="requestJobTitleLink"
              className="pt-info-link"
              href="/job-library/title-requests/create-title-request"
            >
              Request a Job Title.
            </Link>
          </i>
        </div>
      </div>
    </div>
  );
}

export default observer(NewRateSearchForm);
