import React from "react";
// @ts-expect-error
import { Collapse } from "react-bootstrap";

import StatsTableColumn, { StatsTableColumnValuesProps } from "./StatsTableColumn";
import { emptyMap } from "../../../constants";

import Icon from "../../../components/lib/Icon";
import Stack from "../../../components/lib/Stack";
import Box from "../../../components/lib/Box";
import { styled } from "../../../stitches.config";
import Text from "../../../components/lib/Text";

import {
  RATE_TYPES,
  SKILLS_LEVELS_ROMAN_LABELS,
  ALL_VALUES_TYPES,
  VALUES_SUBTYPES,
  RATE_TYPES_TYPE,
  ALL_VALUES_TYPES_TYPE,
  VALUES_SUBTYPES_TYPE,
  SKILLS_LEVELS_TYPE,
} from "../types";
import { decimalFormatter, percentFormatter } from "../constants";

import type {
  ContractorDataMap,
  CurrencyObject,
  AllCurrenciesMap,
  MarketSearchResultMap,
  MarketSearchResultObject,
} from "../types";

const TitleCollapse = styled("h4", {
  textAlign: "right",
  margin: 0,
  padding: "$1_5 $10 !important",
  color: "$primaryLight !important",
  fontSize: "$3xl",
  fontStyle: "italic",
  textTransform: "uppercase",
  whiteSpace: "nowrap",
  overflow: "hidden",
  borderBottom: "1px solid $primaryLighter",
  cursor: "pointer",

  "&:hover": {
    color: "$primary !important",
  },

  variants: {
    match: {
      true: {
        color: "$brandLight !important",

        "&:hover": {
          color: "$brand !important",
        },
      },
    },
  },
});

type DecimalFormatterFunc = (
  value: number | undefined | null,
  verbose?: boolean,
  units?: string
) => React.ReactElement | string | undefined | null;

const decimalVarianceFormatter: DecimalFormatterFunc = (value, verbose, units) => {
  if (value == null) return value;
  return (
    <Text bold color={value >= 0 ? "negative" : "positive"}>
      {decimalFormatter(value, verbose, units)}
    </Text>
  );
};

type ColumnConfig = {
  contractorKey: ALL_VALUES_TYPES_TYPE;
  marketKey: ALL_VALUES_TYPES_TYPE;
  formatter: DecimalFormatterFunc;
  varianceFormatter: DecimalFormatterFunc;
  title: string;
  exportFormats: {
    rate: "rate_decimal" | "rate_percentage";
    variance: "variance_decimal" | "variance_percentage";
  };
};

const hourlyColumnsConfig: Array<ColumnConfig> = [
  {
    contractorKey: ALL_VALUES_TYPES.BILL_RATE,
    marketKey: ALL_VALUES_TYPES.BILL_RATE,
    formatter: decimalFormatter,
    varianceFormatter: decimalVarianceFormatter,
    title: "Bill Rate",
    exportFormats: {
      rate: "rate_decimal",
      variance: "variance_decimal",
    },
  },
  {
    contractorKey: ALL_VALUES_TYPES.PAY_RATE,
    marketKey: ALL_VALUES_TYPES.PAY_RATE,
    formatter: decimalFormatter,
    varianceFormatter: decimalVarianceFormatter,
    title: "Pay Rate",
    exportFormats: {
      rate: "rate_decimal",
      variance: "variance_decimal",
    },
  },
  {
    contractorKey: ALL_VALUES_TYPES.MARKUP,
    marketKey: ALL_VALUES_TYPES.MARKUP,
    formatter: (value, verbose, symbol) => percentFormatter(value, verbose, "%"),
    varianceFormatter: decimalVarianceFormatter,
    title: "Markup",
    exportFormats: {
      rate: "rate_percentage",
      variance: "variance_percentage",
    },
  },
];

const annualColumnsConfig: Array<ColumnConfig> = [
  {
    contractorKey: ALL_VALUES_TYPES.ANNUAL_SALARY,
    marketKey: ALL_VALUES_TYPES.ANNUAL_SALARY,
    formatter: decimalFormatter,
    varianceFormatter: decimalVarianceFormatter,
    title: "Salary",
    exportFormats: {
      rate: "rate_decimal",
      variance: "variance_decimal",
    },
  },
];

type RowConfig = {
  subkey: VALUES_SUBTYPES_TYPE;
  propName: keyof StatsTableColumnValuesProps;
};

const rowsConfig: RowConfig[] = [
  {
    subkey: VALUES_SUBTYPES.AVG,
    propName: "valueAvg",
  },
  {
    subkey: VALUES_SUBTYPES.MIN,
    propName: "valueMin",
  },
  {
    subkey: VALUES_SUBTYPES.MID,
    propName: "valueMid",
  },
  {
    subkey: VALUES_SUBTYPES.MAX,
    propName: "valueMax",
  },
];

const rateKeysToConvert = ["bill_rate", "pay_rate", "annual_salary", "markup_amount"];

interface MarketRatesTableProps {
  contractorData: ContractorDataMap;
  searchData: MarketSearchResultMap;
  currencies: AllCurrenciesMap;
  displayCurrencyCode?: CurrencyObject["code"];
  expanded: boolean;
  showValues?: ALL_VALUES_TYPES_TYPE | null; // null or undefined means "show all values"
  showVariances: boolean;
  onToggle: (level: SKILLS_LEVELS_TYPE) => void;
}

/*
 *  This component is universal and used in several places
 *  (displayCurrencyCode and currencies props are optional)
 */

const MarketRatesTable = (props: MarketRatesTableProps) => {
  const {
    contractorData,
    searchData,
    currencies,
    showValues,
    showVariances,
    expanded,
    onToggle,
  } = props;

  const expLevel = searchData.get("level");
  const displayCurrencyCode: string | undefined = props.displayCurrencyCode;
  const displayCurrencyFactor: number | undefined = currencies.getIn([
    displayCurrencyCode,
    "factor",
  ]);
  const displayCurrencySymbol: string | undefined = currencies.getIn([
    displayCurrencyCode,
    "symbol",
  ]);
  const contractorCurrencyCode: string = contractorData.getIn([
    "processed",
    "currency_code",
  ]);
  const contractorCurrencyFactor: number = currencies.getIn([
    contractorCurrencyCode,
    "factor",
  ]);
  const contractorCurrencySymbol: string = currencies.getIn([
    contractorCurrencyCode,
    "symbol",
  ]);

  const contractorRateType: RATE_TYPES_TYPE =
    contractorData.get("rate_type") || RATE_TYPES.CONTRACT;
  const contractorExpLevel: SKILLS_LEVELS_TYPE = contractorData.getIn([
    "market_analysis",
    "market_level",
  ]);
  const isSystemMatch = expLevel === contractorExpLevel;
  const columnsConfig =
    contractorRateType === RATE_TYPES.FTE ? annualColumnsConfig : hourlyColumnsConfig;

  const handleCollapseTable = React.useCallback(
    () => onToggle(expLevel),
    [expLevel, onToggle]
  );

  return (
    <Stack css={{ alignItems: "stretch", gap: 0 }}>
      <TitleCollapse onClick={handleCollapseTable} match={isSystemMatch}>
        {expanded ? <Icon icon="chevron-up" /> : <Icon icon="chevron-down" />}
        {`Level ${SKILLS_LEVELS_ROMAN_LABELS[expLevel]}`}
      </TitleCollapse>
      <Collapse in={expanded}>
        <Box>
          <Stack
            css={{
              backgroundColor: "$primaryLightest",
              borderBottom: "1px solid $primaryLighter",
              padding: "&5",
              gap: 0,
              "@md": {
                flexDirection: "row",
              },
            }}
          >
            {columnsConfig.map((config) => {
              const { contractorKey, marketKey, title, varianceFormatter } = config;

              if (showValues && showValues !== contractorKey) return null;

              const formatter = (value: number | undefined | null) =>
                config.formatter(
                  value,
                  false,
                  displayCurrencySymbol || contractorCurrencySymbol
                );
              const needConversion = !!(
                displayCurrencyCode &&
                contractorCurrencyCode &&
                displayCurrencyCode !== contractorCurrencyCode &&
                rateKeysToConvert.indexOf(contractorKey) >= 0 &&
                displayCurrencyFactor &&
                contractorCurrencyFactor
              );
              const columnProps = {} as StatsTableColumnValuesProps;
              let contractorValue = contractorData.get(contractorKey);

              if (contractorValue && needConversion) {
                contractorValue =
                  (contractorValue / contractorCurrencyFactor) * displayCurrencyFactor;
              }

              rowsConfig.forEach(({ subkey, propName }) => {
                let marketValue = searchData.get(
                  [marketKey, subkey].join("_") as keyof MarketSearchResultObject
                );
                if (marketValue && needConversion) {
                  marketValue =
                    (marketValue / contractorCurrencyFactor) * displayCurrencyFactor;
                }
                const variance =
                  showVariances && marketValue != null && contractorValue != null
                    ? contractorValue - marketValue
                    : null;

                columnProps[propName] =
                  marketValue == null && variance == null ? null : (
                    <span>
                      {marketValue != null ? formatter(marketValue) : null}
                      {showVariances && variance != null ? <span> / </span> : null}
                      {showVariances && variance != null
                        ? varianceFormatter(variance, true)
                        : null}
                    </span>
                  );
              });

              return (
                <StatsTableColumn key={contractorKey} {...columnProps} title={title} />
              );
            })}
          </Stack>
        </Box>
      </Collapse>
    </Stack>
  );
};

MarketRatesTable.displayName = "MarketRatesTable";
MarketRatesTable.defaultProps = {
  currencies: emptyMap,
  expanded: false,
  showValues: "bill_rate",
  showVariances: false,
};

export default MarketRatesTable;
