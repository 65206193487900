import React from "react";
import TickerLoader from "../../../components/lib/TickerLoader";

const LoadingIndicator = (props) => {
  const style = Object.assign(
    {},
    {
      minHeight: 300,
      height: "100%",
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
    props.style
  );

  const innerStyle = Object.assign(
    {},
    {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    props.innerStyle
  );

  return (
    <div style={style}>
      <div style={innerStyle}>
        <TickerLoader />
        {props.showRetry && <button onClick={props.onRetry}>Retry</button>}
      </div>
    </div>
  );
};

export default LoadingIndicator;
