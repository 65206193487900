/**
 * @generated SignedSource<<88b371caae5aabf4ec9b112c4e89b0d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ModifyLocationsDialog_viewer$data = {
  readonly user: {
    readonly resourceAllocations: ReadonlyArray<{
      readonly balance: number;
      readonly id: string;
      readonly isUnlimited: boolean | null;
    } | null> | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"RegionsField_viewer">;
  readonly " $fragmentType": "ModifyLocationsDialog_viewer";
};
export type ModifyLocationsDialog_viewer$key = {
  readonly " $data"?: ModifyLocationsDialog_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ModifyLocationsDialog_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ModifyLocationsDialog_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "resourceType",
              "value": "SEARCH"
            }
          ],
          "concreteType": "TrapsResourceAllocation",
          "kind": "LinkedField",
          "name": "resourceAllocations",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "balance",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isUnlimited",
              "storageKey": null
            }
          ],
          "storageKey": "resourceAllocations(resourceType:\"SEARCH\")"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RegionsField_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "f33f003229c6cb0857ce1626871911aa";

export default node;
