import React, { useCallback } from "react";

import Inline from "../../../components/lib/Inline";
import Stack from "../../../components/lib/Stack";
import Icon from "../../../components/lib/Icon";
import Text from "../../../components/lib/Text";
import Button from "../../../components/lib/Button";
import Alert from "../../../components/lib/Alert";
import DownloadPOSTButtonExt from "../../private_index/components/DownloadPOSTButtonExt";
import { ButtonGroupRight } from "../../../components/lib/ButtonGroup";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogClose,
  DialogDescription,
  DialogActions,
} from "../../../components/lib/Dialog";

import type {
  FiltersQueryObject,
  UrlQueryObject,
} from "../../../components/tables/types";

type TableExportConfigProps = {
  show: boolean;
  endpoint: string;
  itemsTotal: number;
  itemsPerPage: number;
  getQueryArgs: (exportCurrentPage: boolean) => UrlQueryObject | null;
  getFormData: () => FiltersQueryObject | null;
  onClose: () => void;
};

const TableExportConfig = (props: TableExportConfigProps) => {
  const { show, itemsTotal, itemsPerPage, endpoint, getQueryArgs, getFormData, onClose } =
    props;

  const hasData = itemsTotal > 0;
  const isSinglePageExport = hasData && itemsTotal <= itemsPerPage;

  const handleExportError = useCallback((err: Error) => onClose(), [onClose]);

  return (
    <Dialog open={show} onOpenChange={onClose}>
      <DialogContent css={{ minWidth: "700px" }}>
        <DialogTitle asChild>
          <Inline css={{ justifyContent: "space-between" }}>
            <Text as="h4">Export to Excel.</Text>
            <DialogClose asChild>
              <Icon icon="times" />
            </DialogClose>
          </Inline>
        </DialogTitle>
        <DialogDescription css={{ backgroundColor: "inherit" }} asChild>
          <Stack css={{ alignItems: "flex-start", gap: "$4" }}>
            {!hasData && <Text bold>No data to export.</Text>}
            {hasData && (
              <Alert fill color="warning">
                <Text>
                  <Text bold underline italic>
                    Please Note
                  </Text>
                  : Exporting could take awhile depending on the size of the output.
                </Text>
              </Alert>
            )}
            {hasData && (
              <Stack css={{ alignItems: "flex-start", gap: "$2" }}>
                <Text bold>Following options available for exporting:</Text>
                {!isSinglePageExport && (
                  <DownloadPOSTButtonExt
                    size="small"
                    icon={["far", "file-excel"]}
                    color="brand"
                    variant="outlined"
                    loadingText="Export current page only."
                    service="argus"
                    endpoint={endpoint}
                    getQueryArgs={() => getQueryArgs(true)}
                    getFormData={() => getFormData()}
                    onSuccess={onClose}
                    onError={handleExportError}
                  >
                    Export current page only.
                  </DownloadPOSTButtonExt>
                )}
                <DownloadPOSTButtonExt
                  size="small"
                  icon={["far", "file-excel"]}
                  color="brand"
                  variant="outlined"
                  loadingText="Export whole list."
                  service="argus"
                  endpoint={endpoint}
                  getQueryArgs={() => getQueryArgs(false)}
                  getFormData={() => getFormData()}
                  onSuccess={onClose}
                  onError={handleExportError}
                >
                  Export whole list.
                </DownloadPOSTButtonExt>
              </Stack>
            )}
          </Stack>
        </DialogDescription>
        <DialogActions>
          <ButtonGroupRight fill>
            <Button size="large" onClick={onClose}>
              Close
            </Button>
          </ButtonGroupRight>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

TableExportConfig.displayName = "TableExportConfig";

export default TableExportConfig;
