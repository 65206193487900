import React, { forwardRef } from "react";
import { styled } from "../../stitches.config";
import Icon from "./Icon";
import Button, { IconName, ButtonProps } from "./Button";

export const Toggle = styled(Button, {
  padding: "5px 10px",
  cursor: "pointer",

  variants: {
    active: {
      true: {
        backgroundColor: "$brand",
        color: "White",
      },
      false: {
        backgroundColor: "none",
        color: "$brand",
      },
    },
  },
});

const StyledIcon = styled(Icon, {
  cursor: "pointer",
  fontSize: "$xl",

  variants: {
    active: {
      true: {
        color: "$brand",
      },
      false: {
        color: "$primary",
      },
    },
  },
});

export type IconToggleProps = {
  icon: IconName;
  active?: boolean;
} & Omit<ButtonProps, "icon">;

export const IconToggle = forwardRef<HTMLButtonElement, IconToggleProps>((props, ref) => {
  const { icon, active = false, ...btnProps } = props;
  return (
    <Button {...btnProps} ref={ref} variant="text" color="gray">
      <StyledIcon icon={icon} active={active} />
    </Button>
  );
});
