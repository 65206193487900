import React, { useState } from "react";
import Stack from "../../../components/lib/Stack";
import Box from "../../../components/lib/Box";
import { SimpleLink } from "../../../components/lib/Link";
import { JOB_DES_MIN_WORDS, MIN_NUM_SKILLS } from "../rsSearchBySkillsUtil";
import Inline from "../../../components/lib/Inline";
import Tag from "../../../components/lib/Tag";
import Text from "../../../components/lib/Text";
import RsSkillsWithJobDescription from "../JobTittleSkills";
import { DialogClose } from "../../../components/lib/Dialog";

const SupportedCountries = ({ listCountries, isCertified }) => {
  if (listCountries && listCountries.length > 0) {
    return (
      <Inline css={{ gap: "$2" }}>
        {isCertified && (
          <Tag icon="globe-americas" color="brand" size="extra-small">
            {listCountries.map((country) => country.name).join(", ")}
          </Tag>
        )}
        {!isCertified && (
          <Tag icon="globe-americas" color="danger" size="extra-small">
            {listCountries.map((country) => country.name).join(", ")}
          </Tag>
        )}
      </Inline>
    );
  }
  return null;
};

const RsSearchBySkillsResult = ({
  jobResult,
  handleGetSearchResults,
  perSearchPricing,
  jotTitleClickable,
  preJobTitle,
}) => {
  const [viewFullJobDescription, setViewFullJobDescription] = useState(false);
  const [skills, setSkills] = useState([]);

  const onClickViewFullJobDescription = (e) => {
    setViewFullJobDescription(!viewFullJobDescription);
  };

  const getJobId = (perSearchPricing, jobResult) => {
    return perSearchPricing
      ? jobResult["rawJobTitleIdMatched"]
      : jobResult?.["clientJobTitle"]?.["clientRawJobTitle"]?.["databaseId"];
  };

  const getJobTitle = (perSearchPricing, jobResult) => {
    return perSearchPricing
      ? jobResult["titleMatched"] + " (stock title)"
      : jobResult?.["clientJobTitle"]?.["clientRawJobTitle"]?.["title"];
  };

  const getJobDescription = (perSearchPricing, jobResult) => {
    return perSearchPricing
      ? jobResult["descriptionMatched"]
      : jobResult?.["clientJobTitle"]?.["clientRawJobTitle"]?.["description"];
  };

  const showViewFullJobDescriptionButton = (skills, jobResult, perSearchPricing) => {
    if (skills.length > MIN_NUM_SKILLS) {
      return true;
    }
    const jobDescription = getJobDescription(perSearchPricing, jobResult);
    if (
      jobDescription &&
      jobDescription.length > 0 &&
      jobDescription.split(" ").length > JOB_DES_MIN_WORDS
    ) {
      return true;
    }
    return false;
  };

  const handleGetSkills = (skills) => {
    setSkills(skills);
  };

  const getJobDescriptionShown = (perSearchPricing, jobResult) => {
    const jobDescription = getJobDescription(perSearchPricing, jobResult);
    if (!viewFullJobDescription && jobDescription && jobDescription.length > 0) {
      const texts = jobDescription.split(" ");
      return texts.length <= JOB_DES_MIN_WORDS
        ? jobDescription
        : texts.slice(0, JOB_DES_MIN_WORDS - 1).join(" ") + "...";
    } else {
      return jobDescription;
    }
  };

  const getCertifiedCountries = (jobResult) => {
    const edges = jobResult?.["clientJobTitle"]?.["certifiedCountries"]?.["edges"];
    if (edges && edges.length > 0) {
      let countries: any[] = [];
      edges.forEach((edge) => {
        const country = edge?.["node"]?.["country"];
        if (country && country["title"] && country["databaseId"]) {
          countries.push({ name: country["title"], locationId: country["databaseId"] });
        }
      });
      return countries;
    } else {
      return [];
    }
  };

  const getNotCertifiedCountries = (jobResult) => {
    const edges = jobResult?.["clientJobTitle"]?.["adhocCountries"]?.["edges"];
    if (edges && edges.length > 0) {
      let countries: any[] = [];
      edges.forEach((edge) => {
        const country = edge?.["node"]?.["country"];
        if (country && country["title"] && country["databaseId"]) {
          countries.push({ name: country["title"], locationId: country["databaseId"] });
        }
      });
      return countries;
    } else {
      return [];
    }
  };

  const onClickJobTitle = () => {
    const jobTitle = {
      id: getJobId(perSearchPricing, jobResult),
      title: getJobTitle(perSearchPricing, jobResult),
      slug: "",
      showDescription: false,
      isJobLabel: false,
      category: null,
      shareInfo: null,
      clientJobLibraryInfo: {
        adhocCountries: getNotCertifiedCountries(jobResult).map((l) => l.locationId),
        certifiedCountries: getCertifiedCountries(jobResult).map((l) => l.locationId),
      },
    };
    if (handleGetSearchResults) {
      handleGetSearchResults({
        jobTitle,
        jobDescription: getJobDescription(perSearchPricing, jobResult),
        certifiedCountries: getCertifiedCountries(jobResult),
        adhocCountries: getNotCertifiedCountries(jobResult),
      });
    }
  };

  // Omits Duplicates
  const shouldNotRender = perSearchPricing
    ? jobResult?.titleMatched === preJobTitle
    : jobResult?.clientJobTitle?.clientRawJobTitle?.title === preJobTitle;

  if (shouldNotRender) return null;

  return (
    <Stack fill css={{ alignItems: "flex-start", padding: "$4" }}>
      <Box fill css={{ gap: "$2" }}>
        <Box>
          {jotTitleClickable && (
            <DialogClose asChild>
              <SimpleLink
                css={{ fontSize: "$base", fontWeight: "$bold" }}
                onClick={onClickJobTitle}
              >
                {getJobTitle(perSearchPricing, jobResult)}
              </SimpleLink>
            </DialogClose>
          )}
          {!jotTitleClickable && (
            <Box css={{ fontSize: "$base", fontWeight: "$bold" }}>
              {getJobTitle(perSearchPricing, jobResult)}
            </Box>
          )}
          <Box css={{ fontSize: "$4", whiteSpace: "pre-line" }}>
            {getJobDescriptionShown(perSearchPricing, jobResult)}
          </Box>
        </Box>
        <Box>
          <h6>Skills:</h6>
          <RsSkillsWithJobDescription
            jobDescription={getJobDescription(perSearchPricing, jobResult)}
            showSkillLabel={false}
            showFullSkills={viewFullJobDescription}
            handleGetSkills={handleGetSkills}
          />
        </Box>
        {showViewFullJobDescriptionButton(skills, jobResult, perSearchPricing) && (
          <Box css={{ marginTop: "$2_5" }}>
            {!viewFullJobDescription && (
              <SimpleLink onClick={onClickViewFullJobDescription}>
                View Full Job Description
              </SimpleLink>
            )}
            {viewFullJobDescription && (
              <SimpleLink onClick={onClickViewFullJobDescription}>
                View Less Job Description
              </SimpleLink>
            )}
          </Box>
        )}
        {!perSearchPricing && (
          <Stack nogap css={{ alignItems: "flex-start" }}>
            <Text>Supported Countries:</Text>
            <Inline>
              <SupportedCountries
                listCountries={getCertifiedCountries(jobResult)}
                isCertified={true}
              />
              <SupportedCountries
                listCountries={getNotCertifiedCountries(jobResult)}
                isCertified={false}
              />
            </Inline>
          </Stack>
        )}
      </Box>
    </Stack>
  );
};

export default RsSearchBySkillsResult;
