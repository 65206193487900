// @flow
import * as React from "react";
import Select from "react-select";
import { reactSelectStyles, reactSelectTheme } from "../../constants/reactSelect";

/**
 * Options can be any kind of object. Unless the object has a value and a label prop
 * you must use getOptionLabel and getOptionValue so that the component knows which
 * prop to use as label and value.
 */
type Props<T> = {
  options: Array<T>,
  value?: ?T,
  defaultValue?: ?T,
  name?: string,
  placeholder?: string,
  onChange?: (T) => void,
  disabled?: boolean,
  getOptionLabel?: (T) => string,
  getOptionValue?: (T) => string | number,
  styles?: Object,
};

function SingleSelect<T>(props: Props<T>): React.Node {
  return (
    <Select
      name={props.name}
      placeholder={props.placeholder}
      options={props.options || []}
      value={props.value}
      defaultValue={props.defaultValue}
      onChange={props.onChange}
      isDisabled={props.disabled}
      getOptionLabel={props.getOptionLabel}
      getOptionValue={props.getOptionValue}
      backspaceRemoves={true}
      openOnFocus={true}
      styles={props.styles || reactSelectStyles}
      theme={reactSelectTheme}
    />
  );
}

export default SingleSelect;
