import React, { useCallback } from "react";

import Button from "../../../../components/lib/Button";
import {
  Card,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
  CardHeaderTitle,
} from "../../../../components/lib/Card";

import { useVal5KAdminContext } from "../../context/Val5KAdminContext";

export default function PageHeadBlock() {
  const { router } = useVal5KAdminContext();

  const handleBackToDashboard = useCallback(() => {
    router.push(`/admin/val5000`);
  }, [router]);
  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft>
          <CardHeaderTitle>Create Survey From Ratecard</CardHeaderTitle>
        </CardActionsLeft>
        <CardActionsRight>
          <Button
            icon="arrow-left"
            size="normal"
            color="brand"
            onClick={handleBackToDashboard}
          >
            Back to Dashboard
          </Button>
        </CardActionsRight>
      </CardActions>
    </Card>
  );
}

PageHeadBlock.displayName = "PageHeadBlock";
