import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import PlainHighChart from "../../views/ratecards/components/PlainHighChart";
import { theme } from "../../stitches.config";

const binLevels = [5, 10, 20, 30, 50, 100];

export default class HistogramChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      bins: 50,
    };
  }

  componentDidCatch(error, errorInfo) {
    console.log("Caught error:", error);
    console.log("Caught error info:", errorInfo);
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <div></div>;
    }

    if (!this.props.active) return null;

    return (
      <div>
        <PlainHighChart options={this.buildHighChartOptions()} />
      </div>
    );
  }

  _renderBinButtons = () => {
    return (
      <div className="text-center">
        <ButtonGroup>
          {binLevels.map((count) => {
            return (
              <Button
                key={count}
                bsStyle={count === this.state.bins ? "primary" : "default"}
                onClick={() => this.setState({ bins: count })}
              >
                {count}
              </Button>
            );
          })}
        </ButtonGroup>
      </div>
    );
  };

  buildHighChartOptions = () => {
    let rates = this.props.results;

    return {
      title: { text: "Histogram" },
      xAxis: [
        {
          title: { text: "Pay Rate" },
          alignTicks: false,
        },
      ],
      yAxis: [
        {
          title: { text: "Records" },
        },
      ],
      tooltip: {
        pointFormatter: function () {
          return `<span style="font-size: 10px">${this.x.toFixed(2)} - ${this.x2.toFixed(
            2
          )}</span><br/><span style="color: ${
            theme.colors.chart1.value
          };">●</span> Count <b>${this.y}</b><br/>`;
        },
      },
      plotOptions: {
        histogram: {
          // NOTE: Switch to the default number of bins we had in v6
          binsNumber: 50,
        },
      },
      series: [
        {
          name: "Histogram",
          type: "histogram",
          xAxis: 0,
          yAxis: 0,
          baseSeries: "s1",
          zIndex: -1,
        },
        {
          id: "s1",
          data: rates,
          visible: false,
        },
      ],
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
    };
  };
}
