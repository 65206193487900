// @flow

import React, { Component } from "react";
import type { Element } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  BatchSearchPSPCreateStore,
  RATE_CARD_ACTION,
} from "../../../stores/mobx/BatchSearchCreateStore";
import { RATE_TYPE } from "../../../constants/search";
import { LOCATION_TYPE } from "../../../models/LocationList";
import LoadingIndicator from "../../ratecards/components/LoadingIndicator";
import FlagIcon from "../../../components/FlagIcon";
import InvalidJobsErrorModal from "./InvalidJobsErrorModal";
import type { BatchSearchJobListItem } from "../../../stores/mobx/BatchSearchCreateStore";
import { Table, THead, TBody, TH, TR, TD } from "../../../components/lib/Table";
import Stack from "../../../components/lib/Stack";
import Box from "../../../components/lib/Box";
import Tooltip from "../../../components/lib/Tooltip";

type Props = {
  store: BatchSearchPSPCreateStore,
};

class WizardStep04Summary extends Component<Props> {
  handleRateTypeEditOnClick: () => void;
  handleJobsEditOnClick: () => void;
  handleRateCardEditOnClick: () => void;

  constructor(props: Props) {
    super(props);

    this.handleRateTypeEditOnClick = this.handleRateTypeEditOnClick.bind(this);
    this.handleJobsEditOnClick = this.handleJobsEditOnClick.bind(this);
    this.handleRateCardEditOnClick = this.handleRateCardEditOnClick.bind(this);
  }

  componentDidMount() {
    // this.props.store.batchSearchCreateStore.searchStore.pagination.goFetch();
  }

  handleRateTypeEditOnClick(): void {
    this.props.store.wizard.goTo(1);
  }

  handleJobsEditOnClick(): void {
    const store = this.props.store;
    // clean the fields
    store.resetJobTitles();
    store.resetIndustries();
    store.resetLocations();
    store.resetRegions();
    store.locationType = LOCATION_TYPE.LOCATION;

    store.wizard.goTo(2);
  }

  handleRateCardEditOnClick(): void {
    this.props.store.wizard.goTo(3);
  }

  renderBatchSearchJobListItem(item: BatchSearchJobListItem): Element<any> {
    const store = this.props.store;
    return (
      <TR key={item.id}>
        <TD>{item.titleId}</TD>
        <TD>{item.title}</TD>
        <TD>{item.searchedTitle}</TD>
        <TD>{item.industry}</TD>
        <TD>
          {item.countryCode && <FlagIcon code={item.countryCode} />}
          {item.location}
        </TD>
        {store.showWorkerTypeColumn && <TD>{item.workerType}</TD>}
        {store.showTagsColumn && (
          <TD className="ellipsis" style={{ maxWidth: 150 }} title={item.tags}>
            {item.tags}
          </TD>
        )}
        {store.showGSSColumn && (
          <TD className="text-center">
            {item.isGSS && <FontAwesomeIcon icon="check" />}
          </TD>
        )}
        <TD />
      </TR>
    );
  }

  renderInvalidBatchSearchJobItem(item: BatchSearchJobListItem): Element<any> {
    const store = this.props.store;
    return (
      <TR key={item.id}>
        <TD>{item.titleId}</TD>
        <TD>{item.title}</TD>
        <TD>{item.searchedTitle}</TD>
        <TD>{item.industry}</TD>
        <TD>
          {item.countryCode && <FlagIcon code={item.countryCode} />}
          {item.location}
        </TD>
        {store.showWorkerTypeColumn && <TD>{item.workerType}</TD>}
        {store.showTagsColumn && (
          <TD className="ellipsis" style={{ maxWidth: 150 }} title={item.tags}>
            {item.tags}
          </TD>
        )}
        {store.showGSSColumn && (
          <TD className="text-center">
            {item.isGSS && <FontAwesomeIcon icon="check" />}
          </TD>
        )}
        <TD />
      </TR>
    );
  }

  _renderGSSPopover = () => {
    return (
      <Popover
        className="pt-ui-popover"
        id="popover-positioned-top"
        title="Global Supplier Search"
      >
        <p className="text-xx-small">
          <strong>Important Information</strong>
        </p>
        <p className="text-xx-small">
          Select this if you would like results that are intended for a larger percentage
          of data from Global suppliers.
        </p>
      </Popover>
    );
  };

  _renderGSSTooltipIcon = () => {
    return (
      <OverlayTrigger placement="top" overlay={this._renderGSSPopover()}>
        <FontAwesomeIcon
          icon="question-circle"
          className="icon"
          style={{ marginLeft: ".2em" }}
        />
      </OverlayTrigger>
    );
  };

  render() {
    const store = this.props.store;

    if (store.network.loading) {
      return <LoadingIndicator />;
    }

    return (
      <Stack css={{ minHeight: 310, gap: "$6 " }}>
        <Table>
          <THead>
            <TR>
              <TH>Rate Type</TH>
              <TH style={{ width: 24 }}>
                <Tooltip side="top" content="Change">
                  <Box css={{ color: "$brand" }}>
                    <FontAwesomeIcon
                      icon="edit"
                      onClick={this.handleRateTypeEditOnClick}
                    />
                  </Box>
                </Tooltip>
              </TH>
            </TR>
          </THead>
          <TBody>
            <TR>
              {store.selectedRateType && (
                <TD>
                  {store.selectedRateType.value === RATE_TYPE.HOURLY ? (
                    <FontAwesomeIcon icon="hourglass" className="icon" />
                  ) : (
                    <FontAwesomeIcon icon="calendar" className="icon" />
                  )}
                  <span>{store.selectedRateType.label}</span>
                </TD>
              )}
              {!store.selectedRateType && <TD>-- Not Selected --</TD>}
              <TD />
            </TR>
          </TBody>
        </Table>

        <Table>
          <THead>
            <TR>
              <TH>ID</TH>
              <TH>Title</TH>
              <TH>Searched Title</TH>
              <TH>Industry</TH>
              <TH>Location</TH>
              {store.showWorkerTypeColumn && <TH>Worker Type</TH>}
              {store.showTagsColumn && <TH>Tags</TH>}
              {store.showGSSColumn && (
                <TH style={{ width: 64 }}>GSS {this._renderGSSTooltipIcon()}</TH>
              )}
              <TH style={{ width: 24 }}>
                <Tooltip side="top" content="Change">
                  <Box css={{ color: "$brand" }}>
                    <FontAwesomeIcon icon="edit" onClick={this.handleJobsEditOnClick} />
                  </Box>
                </Tooltip>
              </TH>
            </TR>
          </THead>
          <TBody className="table-border-bottom">
            {store.jobsToSearchItems.map((job: BatchSearchJobListItem) => {
              return this.renderBatchSearchJobListItem(job);
            })}
          </TBody>
        </Table>

        <Table>
          <THead>
            <TR>
              <TH>
                {store.rateCardAction === RATE_CARD_ACTION.CREATE && (
                  <span>Creating Rate Card</span>
                )}
                {store.rateCardAction === RATE_CARD_ACTION.USE_EXISTING && (
                  <span>Adding to existing Rate Card</span>
                )}
              </TH>
              <TH style={{ width: 24 }}>
                <Tooltip side="top" content="Change">
                  <Box css={{ color: "$brand" }}>
                    <FontAwesomeIcon
                      icon="edit"
                      onClick={this.handleRateCardEditOnClick}
                    />
                  </Box>
                </Tooltip>
              </TH>
            </TR>
          </THead>
          <TBody>
            <TR>
              {store.rateCardAction === RATE_CARD_ACTION.CREATE && (
                <TD>{store.newRateCardName}</TD>
              )}
              {store.rateCardAction === RATE_CARD_ACTION.USE_EXISTING && (
                <TD>{store.selectedRateCard ? store.selectedRateCard.name : null}</TD>
              )}
              <TD />
            </TR>
          </TBody>
        </Table>

        <InvalidJobsErrorModal
          modalState={store.invalidJobsErrorModal}
          onRemoveInvalidJobs={store.onRemoveInvalidJobsFromSearch}
        >
          <Table>
            <THead>
              <TR>
                <TH>ID</TH>
                <TH>Title</TH>
                <TH>Searched Title</TH>
                <TH>Industry</TH>
                <TH>Location</TH>
                {store.showWorkerTypeColumn && <TH>Worker Type</TH>}
                {store.showTagsColumn && <TH>Tags</TH>}
                {store.showGSSColumn && (
                  <TH style={{ width: 64 }}>GSS {this._renderGSSTooltipIcon()}</TH>
                )}
                <TH style={{ width: 24 }} />
              </TR>
            </THead>
            <TBody>
              {store.invalidJobs &&
                store.invalidJobs.map((invalidJob: BatchSearchJobListItem) => {
                  return this.renderInvalidBatchSearchJobItem(invalidJob);
                })}
            </TBody>
          </Table>
        </InvalidJobsErrorModal>
      </Stack>
    );
  }
}

export default observer(WizardStep04Summary);
