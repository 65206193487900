import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import R from "ramda";
import { AutoSearchBox } from "../../lib/SearchBox";
import { emptyList } from "../../../constants";

const baseComponentClassName = "pt-base-list-view";
const defaultIdGetter = (item) => item.get("id");
const defaultTitleGetter = (item) => item.get("title");

export default class BaseRestfulDatasetListView extends Component {
  static displayName = "BaseRestfulDatasetListView";
  static componentClassName = "pt-base-list-view";
  static propTypes = {
    uploadSize: PropTypes.number,
    searchPlaceholder: PropTypes.string,
    noItemsFoundText: PropTypes.string,
    loadingText: PropTypes.string,
    disabled: PropTypes.bool,
    idGetter: PropTypes.func,
    titleGetter: PropTypes.func,
  };
  static defaultProps = {
    uploadSize: 30,
    disabled: false,
    searchPlaceholder: "Search by title...",
    noItemsFoundText: "No items found",
    loadingText: "loading...",
    idGetter: defaultIdGetter,
    titleGetter: defaultTitleGetter,
  };
  static contextTypes = {
    showModal: PropTypes.func,
    showModalError: PropTypes.func,
    showModalWarning: PropTypes.func,
    hideModal: PropTypes.func,
  };

  state = {
    search: null,
    data: emptyList,
    pagesUploaded: 0,
    itemsAvailable: 0,
    loading: true,
  };

  // lifecycle methods

  componentDidMount() {
    const { uploadSize } = this.props;

    setTimeout(() => {
      this.dataSource("", 1, uploadSize).then((data) => {
        if (data && data.data) {
          this.setState({
            data: data.data,
            itemsAvailable: data.itemsCount,
            pagesUploaded: 1,
          });
        }
      });
    }, 0);
  }

  // api methods

  dataSource = async (search, page, pageSize) => {
    console.error('"dataSource" method should be implemented in descendants');
  };

  // handlers

  _onSearchChange = (value) => {
    if (value !== this.state.search) {
      this.setState({
        search: value,
      });
    }
  };

  _onMakeSearch = (value) => {
    const { uploadSize, disabled } = this.props;

    if (!disabled) {
      this.dataSource(value, 1, uploadSize).then((data) => {
        if (data && !R.isNil(data.data) && !R.isNil(data.itemsCount)) {
          this.setState({
            data: data.data,
            itemsAvailable: data.itemsCount,
            pagesUploaded: 1,
          });
        }
      });
    }
  };

  _onLoadNextPage = () => {
    const { uploadSize, disabled } = this.props;
    const { search, data, pagesUploaded, itemsAvailable } = this.state;

    if (!disabled && data.size < itemsAvailable) {
      this.dataSource(search, pagesUploaded + 1, uploadSize).then((data) => {
        if (data && data.data && data.data.size) {
          const itemsAvailable = data.itemsCount;
          const justUploadedData = data.data;
          const overallUploadedData = this.state.data.concat(justUploadedData);

          if (justUploadedData.size) {
            this.setState({
              data: overallUploadedData,
              itemsAvailable: itemsAvailable,
              pagesUploaded: pagesUploaded + 1,
            });
          }
        }
      });
    }
  };

  _onScrollList = (e) => {
    const { disabled } = this.props;
    const element = e.target;

    if (!disabled && element.scrollHeight - element.scrollTop === element.clientHeight) {
      this._onLoadNextPage();
    }
  };

  // render methods

  renderSearchInput() {
    const { searchPlaceholder, disabled } = this.props;
    const { search } = this.state;

    return (
      <AutoSearchBox
        value={search || ""}
        css={{
          width: "100%",
          borderWidth: "1px",
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          backgroundColor: "transparent",
        }}
        placeholder={searchPlaceholder}
        onChange={this._onSearchChange}
        onSubmit={this._onMakeSearch}
        disabled={disabled}
      />
    );
  }

  renderItemText(idx, item) {
    const itemTitle = this.props.titleGetter(item);

    return <span>{itemTitle}</span>;
  }

  renderItemRow(idx, item) {
    const itemId = this.props.idGetter(item);

    return (
      <div
        key={!R.isNil(itemId) ? itemId : idx}
        className={baseComponentClassName + "__results-item"}
      >
        {this.renderItemText(idx, item)}
      </div>
    );
  }

  renderResultsList() {
    const { disabled, loadingText, noItemsFoundText } = this.props;
    const { data, search, loading } = this.state;

    return (
      <div
        className={baseComponentClassName + "__results-list"}
        onScroll={loading || disabled ? undefined : this._onScrollList}
      >
        {search && data.size === 0 && (
          <div className={baseComponentClassName + "__not-found-text"}>
            {noItemsFoundText}
          </div>
        )}
        {data &&
          data.size > 0 &&
          data.toArray().map((item, idx) => this.renderItemRow(idx, item))}
        {loading && (
          <div className={baseComponentClassName + "__loading-text"}>
            <small>{loadingText}</small>
          </div>
        )}
      </div>
    );
  }

  render() {
    const { disabled, className } = this.props;
    const componentClassName = classNames(className, baseComponentClassName, {
      [baseComponentClassName + "--disabled"]: disabled,
    });

    return (
      <div className={componentClassName}>
        {this.renderSearchInput()}
        {this.renderResultsList()}
      </div>
    );
  }
}
