import { useGlobalContext } from "../../../globalContext";
import { NotFound } from "../../../utils/errorTypes";

/**
 *  Gets searchId parameter from url-serach-query
 * @throws `NotFound` if searchId is missing
 */
export function useSearchId() {
  const { location } = useGlobalContext();
  const ids = location.query.searchIds as string[] | string;
  const searchIdRaw = Array.isArray(ids) ? ids[0] : ids;

  if (!searchIdRaw || Number.isNaN(Number(searchIdRaw))) {
    throw new NotFound("Record not found, invalid search-id.");
  }

  const searchId = Number(searchIdRaw);

  return {
    searchId,
    ids: Array.isArray(ids) ? ids : [searchId],
  };
}
