import { atom } from "recoil";
import { fromJS, List, OrderedMap } from "immutable";

import { emptyOrderedMap, emptySet, emptyMap } from "../../constants";
import { REVIEW_STATUS_TYPES, ANALYSIS_COMPLETED_TYPES, ARCHIVED_TYPES } from "./types";
import { FilterTypes, OrderDirections } from "../../components/tables/types";

import type {
  ReviewsDataOrderedMap,
  ReviewDataMap,
  AttemptDataMap,
  AttemptsDataOrderedMap,
  TotalFeesPaidDataMap,
  TotalFeesPaidDataOrderedMap,
  NdaHistoryDataMap,
  NdaHistoryDataOrderedMap,
  PermissionDataMap,
  PermissionsDataOrderedMap,
  AlertDataMap,
  AlertsDataOrderedMap,
} from "./types";
import type {
  RowData,
  RestfulTableConfigObject,
  FiltersQueryOrderedMap,
  FiltersConfigOrderedMap,
  ColumnsFiltersQueriesOrderedMap,
  RestfulTableDataStateObject as BaseRestfulTableDataStateObject,
  VisibleColumnsSet,
  FilterConfigObject,
  FiltersQueryObject,
} from "../../components/tables/types";

export type RestfulTableDataStateObject<RD = RowData> =
  BaseRestfulTableDataStateObject<RD> & {
    loaded: boolean;
  };

export type ConfigurableRestfulTableDataStateObject<RD = RowData> =
  RestfulTableDataStateObject<RD> & {
    visibleColumns: VisibleColumnsSet;
  };

export const restfulTableConfigDefaults: RestfulTableConfigObject = {
  activePage: 1,
  itemsPerPage: 50,
  filters: emptyOrderedMap as unknown as FiltersConfigOrderedMap,
  filtersQuery: emptyOrderedMap as unknown as FiltersQueryOrderedMap,
  columnsFiltersQueries: emptyOrderedMap as unknown as ColumnsFiltersQueriesOrderedMap,
};

export type ReviewsTableDataStateObject =
  ConfigurableRestfulTableDataStateObject<ReviewDataMap>;

// surveys page global state

const defaultReviewsTableFilterObject: FilterConfigObject = {
  filter: {
    search: null,
    type: FilterTypes.ENUMERATION,
    values: List([true]),
  },
  order: {
    direction: OrderDirections.NO,
  },
};
const defaultReviewsTableFilterMap = fromJS(defaultReviewsTableFilterObject);
const defaultReviewsTableFiltersOrderedMap = OrderedMap({
  is_active: defaultReviewsTableFilterMap,
}) as unknown as FiltersConfigOrderedMap;

const defaultReviewsTableFiltersQueryObject: FiltersQueryObject = {
  is_active__in: [true],
};
const defaultReviewsTableFiltersQueryOrderedMap = OrderedMap(
  defaultReviewsTableFiltersQueryObject
) as unknown as FiltersQueryOrderedMap;

export const reviewsTableGlobalState = atom<ReviewsTableDataStateObject>({
  key: "reviewsTableGlobalState",
  default: {
    ...restfulTableConfigDefaults,
    filters: defaultReviewsTableFiltersOrderedMap,
    filtersQuery: defaultReviewsTableFiltersQueryOrderedMap,
    data: emptyOrderedMap as unknown as ReviewsDataOrderedMap,
    itemsCount: 0,
    loaded: false,
    visibleColumns: emptySet as VisibleColumnsSet,
  },
});

// attempts page global state

export type AttemptsTableDataStateObject =
  ConfigurableRestfulTableDataStateObject<AttemptDataMap>;

const defaultAttemptsTableReviewStatusFilterObject: FilterConfigObject = {
  filter: {
    search: null,
    type: FilterTypes.ENUMERATION,
    values: List([REVIEW_STATUS_TYPES.REVIEWED, REVIEW_STATUS_TYPES.NOT_REVIEWED]),
  },
  order: {
    direction: OrderDirections.NO,
  },
};

const defaultAttemptsTableAnalysisCompletedFilterObject: FilterConfigObject = {
  filter: {
    search: null,
    type: FilterTypes.ENUMERATION,
    values: List([ANALYSIS_COMPLETED_TYPES.NOT_COMPLETED]),
  },
  order: {
    direction: OrderDirections.NO,
  },
};

const defaultAttemptsTableArchivedFilterObject: FilterConfigObject = {
  filter: {
    search: null,
    type: FilterTypes.ENUMERATION,
    values: List([ARCHIVED_TYPES.NOT_ARCHIVED]),
  },
  order: {
    direction: OrderDirections.NO,
  },
};

const defaultAttemptsTableFiltersOrderedMap = OrderedMap({
  review_status: fromJS(defaultAttemptsTableReviewStatusFilterObject),
  analysis_completed: fromJS(defaultAttemptsTableAnalysisCompletedFilterObject),
  archived: fromJS(defaultAttemptsTableArchivedFilterObject),
}) as unknown as FiltersConfigOrderedMap;

const defaultAttemptsTableFilterQueryObject: FiltersQueryObject = {
  analysis_completed__in: [ANALYSIS_COMPLETED_TYPES.NOT_COMPLETED],
  archived__in: [ARCHIVED_TYPES.NOT_ARCHIVED],
  review_status__in: [REVIEW_STATUS_TYPES.REVIEWED, REVIEW_STATUS_TYPES.NOT_REVIEWED],
};

const defaultAttemptsTableFiltersQueryOrderedMap = OrderedMap(
  defaultAttemptsTableFilterQueryObject
) as unknown as FiltersQueryOrderedMap;

export const attemptsTableGlobalState = atom<AttemptsTableDataStateObject>({
  key: "attemptsTableGlobalState",
  default: {
    ...restfulTableConfigDefaults,
    filters: defaultAttemptsTableFiltersOrderedMap,
    filtersQuery: defaultAttemptsTableFiltersQueryOrderedMap,
    data: emptyOrderedMap as unknown as AttemptsDataOrderedMap,
    itemsCount: 0,
    loaded: false,
    visibleColumns: emptySet as VisibleColumnsSet,
  },
});

// payment report page global state

export type TotalFeesPaidTableDataStateObject =
  RestfulTableDataStateObject<TotalFeesPaidDataMap>;

export const totalFeesPaidTableGlobalState = atom<TotalFeesPaidTableDataStateObject>({
  key: "totalFeesPaidTableGlobalState",
  default: {
    ...restfulTableConfigDefaults,
    filters: emptyOrderedMap as unknown as FiltersConfigOrderedMap,
    filtersQuery: emptyOrderedMap as unknown as FiltersQueryOrderedMap,
    data: emptyOrderedMap as unknown as TotalFeesPaidDataOrderedMap,
    itemsCount: 0,
    itemsPerPage: 20,
    loaded: false,
  },
});

export type NdaHistoryTableDataStateObject =
  RestfulTableDataStateObject<NdaHistoryDataMap>;

export const ndaHistoryTableGlobalState = atom<NdaHistoryTableDataStateObject>({
  key: "ndaHistoryTableGlobalState",
  default: {
    ...restfulTableConfigDefaults,
    filters: emptyOrderedMap as unknown as FiltersConfigOrderedMap,
    filtersQuery: emptyOrderedMap as unknown as FiltersQueryOrderedMap,
    data: emptyOrderedMap as unknown as NdaHistoryDataOrderedMap,
    itemsCount: 0,
    itemsPerPage: 20,
    loaded: false,
  },
});

// validator permissions page global state

export type PermissionsTableDataStateObject =
  RestfulTableDataStateObject<PermissionDataMap>;

export const permissionsTableGlobalState = atom<PermissionsTableDataStateObject>({
  key: "permissionsTableGlobalState",
  default: {
    ...restfulTableConfigDefaults,
    data: emptyOrderedMap as unknown as PermissionsDataOrderedMap,
    itemsCount: 0,
    loaded: false,
  },
});

// alerts table state

export type AlertsTableDataStateObject = Omit<
  RestfulTableDataStateObject<AlertDataMap>,
  "visibleColumns"
>;

export const alertsTableGlobalState = atom<AlertsTableDataStateObject>({
  key: "alertsTableGlobalState",
  default: {
    ...restfulTableConfigDefaults,
    data: emptyOrderedMap as unknown as AlertsDataOrderedMap,
    itemsPerPage: 20,
    itemsCount: 0,
    loaded: false,
  },
});

// review page global state

export type ReviewGlobalState = {
  reviewData: ReviewDataMap;
  reviewDataLoaded: boolean;
};

export const reviewGlobalState = atom<ReviewGlobalState>({
  key: "reviewGlobalState",
  default: {
    reviewData: emptyMap as unknown as ReviewDataMap,
    reviewDataLoaded: false,
  },
});

// attempt page global state

export type AttemptGlobalState = {
  attemptData: AttemptDataMap;
  attemptDataLoaded: boolean;
};

export const attemptGlobalState = atom<AttemptGlobalState>({
  key: "attemptGlobalState",
  default: {
    attemptData: emptyMap as unknown as AttemptDataMap,
    attemptDataLoaded: false,
  },
});
