// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import CheckBox from "../../shared/CheckBox";
import classNames from "classnames";
import TagItem from "../../ratecards/components/TagItem";
import LinkButton from "../../../components/lib/LinkButton";
import {
  CardListItem,
  CardListItemLabel,
  CardListItemLeft,
  CardListItemRight,
  CardListSubText,
  SubTextAccent,
  Info,
  InfoData,
  InfoLabel,
} from "../../../components/lib/Card";
import Inline from "../../../components/lib/Inline";

const RegionListItem = observer(
  class extends Component {
    static contextTypes = {
      router: PropTypes.object,
    };

    constructor(props) {
      super(props);

      this.handleViewDetail = this.handleViewDetail.bind(this);
      this.deleteTag = this.deleteTag.bind(this);
      this.state = {
        updated: false,
      };
    }

    handleViewDetail() {
      this.context.router.push({
        pathname: `/regions/${this.props.region.regionId}`,
        query: this.context.router.query,
      });
    }

    deleteTag(event: object, tag_id) {
      event.preventDefault();
      event.stopPropagation();
      event.cancelBubble = true;
      event.returnValue = false;

      this.props.store
        .deleteSingleTags({
          tagId: tag_id,
          contentId: this.props.region.regionId,
        })
        .then((res) => {
          if (!res.result) return;
          this.props.region.tags.forEach((item, index) => {
            if (item.tagId === tag_id) {
              this.props.region.tags.splice(index, 1);
            }
          });
          this.setState({
            updated: true,
          });
        });
    }

    render() {
      const props = this.props;
      const region = props.region;
      const editing = region.viewState.editing;
      const selected = region.viewState.selected;
      const name = region.name;
      const country_name = region.country.name;
      const id = region.regionId;
      const searchCount = region.searchCount;

      const createdDisplay = region.created.format("MMMM D, YYYY");
      let ownerName = "You";
      let onItemClick = this.props.onClick || this.handleViewDetail;
      let editingSection = null;
      let tagSection = null;

      if (region.tags.length > 0 && !this.props.store.isTagView) {
        tagSection = region.tags.map((tag) => (
          <TagItem key={tag.tagId} tag={tag} onClick={this.deleteTag} />
        ));
      }

      if (editing) {
        onItemClick = region.toggleSelected;
        editingSection = (
          <CheckBox
            type={props.store.allowMultipleItemSelection ? "checkbox" : "radio"}
            name="list-item-select"
            value={name}
            selected={selected}
            style={{ width: 34 }}
            onClick={onItemClick}
          />
        );
      }

      return (
        <CardListItem selected={selected} onClick={onItemClick}>
          <CardListItemLeft>
            <Inline css={{ gap: 0 }}>
              {editingSection}
              <div>
                <CardListItemLabel>{name}</CardListItemLabel>
                <CardListSubText>
                  Created by <SubTextAccent>{ownerName}</SubTextAccent> on{" "}
                  <SubTextAccent>{createdDisplay}</SubTextAccent>
                </CardListSubText>
              </div>
            </Inline>
            <div className={classNames("tagbox", { "p-left-34": editing })}>
              {tagSection}
            </div>
          </CardListItemLeft>
          <CardListItemRight>
            <Info>
              <InfoLabel>Searches</InfoLabel>
              <InfoData>{searchCount}</InfoData>
            </Info>
            <Info css={{ width: "18ch" }}>
              <InfoLabel>Country</InfoLabel>
              <InfoData>{country_name}</InfoData>
            </Info>
            {!editing && (
              <div className="rc-center">
                {<LinkButton to={`/regions/${id}`}>View</LinkButton>}
              </div>
            )}
          </CardListItemRight>
        </CardListItem>
      );
    }
  }
);

RegionListItem.propTypes = {
  store: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default RegionListItem;
