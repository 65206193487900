// @flow
import React, { Component } from "react";
import type { Element } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroller";
import TextInput, { inputBorderStyle } from "../lib/TextInput";
import { styled } from "../../stitches.config";

const Wrapper = styled("div", inputBorderStyle, {
  ".pt-ui &[data-comp-id='infinite-scroll-select']": {
    outline: "none",
    padding: 0,
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: "$primaryLight",
    color: "$primaryDarker",
    "&:active, &:focus": {
      borderColor: "$brand",
      "& .infinite-scroll-select-wrapper-button.select-caret": {
        color: "$brand",
      },
    },

    "&:hover": {
      cursor: "pointer",
      "& .infinite-scroll-select-wrapper-button.select-caret": {
        color: "$brand",
      },
    },
    "& > .infinite-scroll-select > .infinite-scroll-select-wrapper": {
      padding: "calc($2 + 1px) $10 calc($2 + 1px) $3",
      "& > .infinite-scroll-select-text": {
        fontSize: "$base",
        lineHeight: "$base",
      },
    },
  },
});

const MenuItem = styled("div", {
  color: "$primaryDark",
  borderBottom: "none",
  borderRadius: 0,
  padding: "10px 20px",
  margin: 0,
  position: "relative",
  transition: "background-color .2s, color .2s",
  fontSize: "$base",
  lineHeight: "$base",
  "&:hover": {
    color: "$brand",
    backgroundColor: "$brandLightest",
  },
  variants: {
    disabled: {
      true: {
        pointerEvents: "none",
      },
    },
  },
});

type SelectableItem = {
  +id: string | number,
  name?: ?string,
  title?: ?string,
  label?: ?string,
  toString(): string,
};

function itemLabel(item: SelectableItem): string {
  const str = item.toString() || "";
  const label = item.name || item.title || item.label;

  if (str.toLowerCase() === "[object object]" && label) {
    return label;
  }

  return str;
}

type MenuProps = {
  children: Element<any> | Element<any>[],
  inputRef: (?HTMLInputElement) => void,
  inputValue?: string,
  inputPlaceholder?: string,
  onInputValueChange: (SyntheticInputEvent<HTMLInputElement>) => void,
  showSearchInput: boolean,
  loading?: boolean,
};

class CustomMenu extends Component<MenuProps> {
  static defaultProps = {
    inputValue: "",
    showSearchInput: true,
    loading: false,
  };

  render() {
    const {
      children,
      inputValue,
      onInputValueChange,
      inputRef,
      inputPlaceholder,
      showSearchInput,
      loading = false,
      dataTestId,
    } = this.props;
    const placeholder = inputPlaceholder || "Search...";

    return (
      <div className="dropdown-menu pt-dropdown">
        {showSearchInput && (
          <div className="search-box-container">
            <div className="pt-search">
              <TextInput
                fill
                autoComplete="off"
                name="search-box"
                placeholder={placeholder}
                data-testid={dataTestId || "search-input"}
                value={inputValue}
                onChange={onInputValueChange}
                ref={inputRef}
                css={{
                  backgroundColor: "$primaryLighter",
                  borderColor: "$primaryLighter",
                }}
              />
              {loading && (
                <FontAwesomeIcon icon="circle-notch" className="pt-search-icon fa-spin" />
              )}
              {!loading && <FontAwesomeIcon icon="search" className="pt-search-icon" />}
            </div>
          </div>
        )}
        <div className="pt-dropdown-list">{children}</div>
      </div>
    );
  }
}

export type InfiniteScrollSelectProps<T: SelectableItem> = {
  items: T[],
  selectedItem: ?T,
  onSelectedItemChange: (?T) => void,
  inputValue?: string,
  onInputValueChange?: (string) => void,
  hasMoreItems?: boolean,
  loadMoreItems?: () => void,
  renderItem?: (T, ?string, ?(string, string) => Element<any>) => any,
  renderSelection?: (T) => any,
  placeholder?: string,
  searchPlaceholder?: string,
  tabIndex?: number,
  innerRef?: (?HTMLElement) => void,
  disabled?: boolean,
  initialLoad?: boolean,
  showSearchInput?: boolean,
  small?: boolean,
  loading?: boolean,
  clearable?: boolean,
  clearSearchOnSelectItem?: boolean,
};

type State = {
  isOpen: boolean,
};

class InfiniteScrollSelect<T: SelectableItem> extends Component<
  InfiniteScrollSelectProps<T>,
  State
> {
  static defaultProps = {
    loadMoreItems: () => {},
    tabIndex: 0,
    initialLoad: false,
    showSearchInput: true,
    small: false,
    inputValue: "",
    loading: false,
    onInputValueChange: (value: string) => {},
    clearSearchOnSelectItem: true,
  };

  _searchInput: ?HTMLInputElement;
  _selectComponent: ?HTMLElement;

  onItemClick: (T, Event, number) => void;
  onInputChange: (SyntheticInputEvent<HTMLInputElement>) => void;
  onFieldKeyDown: (Event) => void;
  onWrapperClick: (Event) => void;
  onClear: (Event) => void;
  focusOnInput: () => void;
  inputRef: (?HTMLInputElement) => void;
  _handleDocumentClick: (SyntheticMouseEvent<HTMLElement>) => void;
  _handleDropdownOnToggle: (boolean) => void;
  _highlight: (string, string) => Element<any>;

  constructor(props: InfiniteScrollSelectProps<T>) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onFieldKeyDown = this.onFieldKeyDown.bind(this);
    this.onWrapperClick = this.onWrapperClick.bind(this);
    this.onItemClick = this.onItemClick.bind(this);
    this.onClear = this.onClear.bind(this);
    this.focusOnInput = this.focusOnInput.bind(this);
    this.inputRef = this.inputRef.bind(this);
    this._handleDocumentClick = this._handleDocumentClick.bind(this);
    this._handleDropdownOnToggle = this._handleDropdownOnToggle.bind(this);
    this._highlight = this._highlight.bind(this);
  }

  componentDidMount() {
    window.document.addEventListener("click", this._handleDocumentClick);
  }

  componentWillUnmount() {
    window.document.removeEventListener("click", this._handleDocumentClick);
  }

  componentDidUpdate() {
    // if its open focus should be on the search input
    if (this.state.isOpen) {
      if (document.activeElement !== this._searchInput) {
        this.focusOnInput();
      }
    }
  }

  _handleDocumentClick(e: SyntheticMouseEvent<HTMLElement>) {
    // $FlowFixMe ignore incompatible type error on .contains(e.target)
    if (!this._selectComponent.contains(e.target) && this.state.isOpen) {
      this.setState({ isOpen: false });
    }
  }

  onItemClick(item: T, event: Event, itemIndex: number) {
    const isSelected = this.props.selectedItem && this.props.selectedItem.id === item.id;
    if (!isSelected && this.props.onSelectedItemChange) {
      this.props.onSelectedItemChange(item);
    }

    if (this.props.onInputValueChange && this.props.clearSearchOnSelectItem) {
      this.props.onInputValueChange("");
    }

    this.setState({ isOpen: false });
  }

  onInputChange(event: SyntheticInputEvent<HTMLInputElement>) {
    const value: string = event.currentTarget.value;
    if (this.props.onInputValueChange) {
      this.props.onInputValueChange(value);
    }
  }

  onFieldKeyDown(event: Event & { keyCode: number }) {
    if (this.props.disabled) return;
    switch (event.keyCode) {
      case 8: // backspace
        // do nothing
        return;
      case 9: // tab
        return;
      case 27: // esc
        if (this.props.inputValue && this.props.onInputValueChange) {
          this.props.onInputValueChange("");
        }

        this.setState({ isOpen: false });
        return;
      case 46: // delete
        this.props.onSelectedItemChange(null);
        return;
      case 188: // comma
        break;
      default:
        const exceptKeys = [
          16, // shift
          17, // ctrl
          18, // alt/opt
          91, // Windows Key / Left ⌘ / Chromebook Search key
          93, // Windows Menu / Right ⌘
        ];

        if (!exceptKeys.includes(event.keyCode)) {
          this.setState({ isOpen: true });
          this.focusOnInput();
        }
        return;
    }
  }

  onWrapperClick(e: Event) {
    if (this.props.disabled) return;
    this.setState({ isOpen: !this.state.isOpen });
    if (!this.state.isOpen) {
      e.preventDefault();
    }
  }

  onClear(e: Event) {
    e.stopPropagation();
    this.props.onSelectedItemChange(null);
  }

  focusOnInput() {
    if (!this._searchInput) return;

    this._searchInput.focus();
    // $FlowFixMe ignore error
    if (typeof this._searchInput.getInput === "function") {
      this._searchInput.getInput().focus();
    }
  }

  inputRef(c: HTMLInputElement) {
    this._searchInput = c;
  }

  _highlight(text: string, term: string): Element<any> {
    // eslint-disable-next-line no-useless-escape
    let escapedTerm = term.replace(/([\(\)\.\+\*\[\]\?\$\^])/g, "\\$1");
    escapedTerm = escapedTerm.replace(/(\s+)/, "(<[^>]+>)*$1(<[^>]+>)*");
    const pattern = new RegExp("(" + escapedTerm + ")", "gi");

    text = text.replace(pattern, "<mark>$1</mark>");
    text = text.replace(/(<mark>[^<>]*)((<[^>]+>)+)([^<>]*<\/mark>)/, "$1$4");

    return <span dangerouslySetInnerHTML={{ __html: text }} />;
  }

  renderSelectedItem(selectedItem: ?T): * {
    const { renderSelection, placeholder } = this.props;
    if (selectedItem) {
      if (renderSelection) {
        return renderSelection(selectedItem);
      } else {
        return itemLabel(selectedItem);
      }
    }
    return <span className="placeholder">{placeholder || ""}&nbsp;</span>;
  }

  renderListItem(item: T): * {
    const { renderItem, inputValue } = this.props;

    if (renderItem) return renderItem(item, inputValue, this._highlight);

    const label = itemLabel(item);
    return inputValue ? this._highlight(label, inputValue) : label;
  }

  _handleDropdownOnToggle(shouldOpen: boolean): void {
    if (
      shouldOpen &&
      this.props.loadMoreItems &&
      this.props.items &&
      this.props.items.length === 0
    ) {
      // console.log("dropdown load more");
      this.props.loadMoreItems();
    }
  }

  render() {
    const {
      id,
      name,
      items,
      selectedItem,
      inputValue,
      hasMoreItems,
      loadMoreItems,
      disabled,
      initialLoad,
      showSearchInput,
      small,
      loading,
      clearable,
    } = this.props;

    const tabIndex = disabled ? -1 : this.props.tabIndex;
    return (
      <Wrapper
        data-comp-id="infinite-scroll-select" // to avoid using !important
        tabIndex={tabIndex || 0}
        style={{ width: "100%" }}
        ref={(element) => {
          this._selectComponent = element;
          if (this.props.innerRef) this.props.innerRef(this._selectComponent);
        }}
        className={classNames("infinite-scroll-select-field", {
          disabled: disabled,
          small: small,
        })}
        onKeyDown={this.onFieldKeyDown}
      >
        <Dropdown
          id={id || "infiniteScrollSelect"}
          className="infinite-scroll-select"
          open={this.state.isOpen}
          onToggle={this._handleDropdownOnToggle}
          data-testid="select-drop-down"
        >
          <div
            bsRole="toggle"
            className="infinite-scroll-select-wrapper"
            onClick={this.onWrapperClick}
          >
            <label
              className={classNames("infinite-scroll-select-text", {
                placeholder: !selectedItem,
              })}
            >
              {this.renderSelectedItem(selectedItem)}
            </label>

            {clearable && selectedItem && (
              <div
                className="infinite-scroll-select-wrapper-button select-clear"
                onClick={this.onClear}
              >
                <FontAwesomeIcon icon="times" />
              </div>
            )}

            <div className="infinite-scroll-select-wrapper-button select-caret">
              <FontAwesomeIcon icon="caret-down" />
            </div>
          </div>

          <CustomMenu
            bsRole="menu"
            // onKeyDown={this.onDropDownKeyDown}
            inputValue={inputValue}
            onInputValueChange={this.onInputChange}
            inputPlaceholder={this.props.searchPlaceholder}
            inputRef={this.inputRef}
            showSearchInput={showSearchInput}
            loading={loading}
            dataTestId={name}
          >
            <InfiniteScroll
              useWindow={false}
              threshold={50}
              hasMore={hasMoreItems}
              loadMore={loadMoreItems}
              pageStart={0}
              initialLoad={initialLoad}
              className="rc-selectable-list-container"
              loader={
                <MenuItem key="loader" disabled>
                  <FontAwesomeIcon icon="circle-notch" fixedWidth spin />
                </MenuItem>
              }
            >
              {items.map((item: T, index: number) => {
                return (
                  <MenuItem
                    key={`item-${index}`}
                    onClick={this.onItemClick.bind(this, item)}
                  >
                    {this.renderListItem(item)}
                  </MenuItem>
                );
              })}
              {!hasMoreItems && !items.length && (
                <MenuItem key="no-items" disabled>
                  <span>-- No items --</span>
                </MenuItem>
              )}
            </InfiniteScroll>
          </CustomMenu>
        </Dropdown>
      </Wrapper>
    );
  }
}

export default InfiniteScrollSelect;
