export function formatCurrency(
  rate: number,
  currency = "USD",
  maximumFractionDigits = 0
) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    maximumFractionDigits,
  }).format(rate);
}

export function calculateExchangeRate(
  fromCurrency: string,
  toCurrency: string,
  exchangeRates: Record<string, number>
) {
  if (fromCurrency === toCurrency) return 1;
  const fromCurrencyRate = exchangeRates[fromCurrency];
  const toCurrencyRate = exchangeRates[toCurrency];
  return toCurrencyRate / fromCurrencyRate;
}
