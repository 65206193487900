import React, {
  ReactNode,
  MouseEvent,
  ChangeEvent,
  useRef,
  useCallback,
  useEffect,
} from "react";
import type * as Stitches from "@stitches/react";

import Text from "./Text";
import Button, { IconName, ButtonProps } from "./Button";
import { InlineElements } from "./Inline";
import { inputBorderStyle } from "./TextInput";
import type { CSS } from "../../stitches.config";
import { styled } from "../../stitches.config";

const Wrapper = styled(InlineElements, inputBorderStyle, {
  borderWidth: "1px",
  padding: "$1",
  gap: 0,

  "& input": {
    visibility: "hidden",
    width: 0,
    height: 0,
  },

  "& button": {
    marginRight: "$2_5",
  },

  "& span": {
    fontWeight: "$light",
  },

  variants: {
    size: {
      "extra-small": {
        padding: "$1 $2",
        fontSize: "$xs",
        lineHeight: "$xs",
      },
      small: {
        padding: "$1_5 $2_5",
        fontSize: "$sm",
        lineHeight: "$sm",
      },
      normal: {
        padding: "calc($2 + 1px) calc($3 + 1px)",
        fontSize: "$base",
        lineHeight: "$base",
      },
      large: {
        padding: "$2_5 $4",
        fontSize: "$lg",
        lineHeight: "$lg",
      },
      "extra-large": {
        padding: "$3 $4",
        fontSize: "$2xl",
        lineHeight: "$2xl",
      },
    },
    fill: {
      true: {
        width: "$full",
      },
    },
    disabled: {
      true: {
        borderColor: "$primaryLighter",
        "& span": { fontWeight: "$thin" },
      },
    },
    invalid: {
      true: {
        borderColor: "$danger",
        "& span": { color: "$danger" },
      },
    },
  },

  defaultVariants: {
    size: "normal",
    fill: false,
    disabled: false,
  },
});

interface UtilInputProps {
  css?: CSS;
}

type InputVariants = Stitches.VariantProps<typeof Wrapper>;
type InputStyledProps = InputVariants & UtilInputProps;

type FileInputProps = {
  value: File | null;
  placeholder: string;
  disabled: boolean;
  invalid: boolean;
  icon: IconName;
  onChange: (newFiles: FileList | null, event: ChangeEvent<HTMLInputElement>) => void;
  onNotSupported: () => void;
  color: ButtonProps["color"];
  size?: ButtonProps["size"];
  name?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  children?: ReactNode;
};

const checkHtmlFileApiSupport = (): boolean => {
  return !!(window && window.Blob && window.File && window.FileReader);
};

const FileInput = (props: FileInputProps & InputStyledProps): JSX.Element => {
  const {
    name,
    value,
    placeholder,
    invalid,
    disabled,
    size,
    onChange,
    onClick,
    onNotSupported,
    ...rest
  } = props;

  const files = value ? (Array.isArray(value) ? value : [value]) : value;

  const fileNames =
    files &&
    files
      .map(({ name }) => name)
      .filter((n) => n && n)
      .join(", ");

  const inputRef = useRef<HTMLInputElement>(null);

  const reset = useCallback(() => {
    const element = inputRef.current;
    if (!!element?.value) element.value = "";
  }, []);

  useEffect(() => {
    if (!value) reset();
  }, [value, reset]);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;

      if (onChange && files) {
        onChange(files, event);
      }
    },
    [onChange]
  );

  const handleInputClick = useCallback(
    (event: MouseEvent<HTMLInputElement>) => reset(),
    [reset]
  );

  const handleButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (!checkHtmlFileApiSupport()) {
        if (onNotSupported) onNotSupported();
        console.error(
          "FileInput component: HTML5 File API is not supported by your browser. " +
            "Use modern browser to manage this page."
        );
        return;
      }

      const element = inputRef.current;

      if (element?.click) {
        element.click();
        if (onClick) onClick(event);
      }
    },
    [onNotSupported, onClick]
  );

  return (
    <Wrapper disabled={disabled} invalid={invalid} size={size}>
      <input
        ref={inputRef}
        type="file"
        name={name}
        disabled={disabled}
        onInput={handleInputChange}
        onClick={handleInputClick}
      />
      <Button {...rest} disabled={disabled} onClick={handleButtonClick} size={size} />
      {(fileNames || placeholder) && <Text>{fileNames || placeholder}</Text>}
    </Wrapper>
  );
};

FileInput.displayName = "FileInput";
FileInput.defaultProps = {
  disabled: false,
  invalid: false,
  placeholder: "no files selected",
  icon: ["far", "file-excel"],
};

export default FileInput;
