import React, { useCallback, ReactElement } from "react";

import Stack from "../../../../components/lib/Stack";
import CommentForm from "./CommentForm";
import SurveyParametersBlock from "./SurveyParametersBlock";
import AlertWithButton from "../../../../components/AlertWithButton";
import ButtonsBlock, { ButtonsBlockProps } from "./ButtonsBlock";
import { SimpleLink } from "../../../../components/lib/Link";
import { RATE_TYPES_LABELS, REVIEW_TYPES } from "../../types";
import { useReviewDataContext } from "../../../validator5K_admin/context/ReviewDataContext";
import { getLocationString, getRegionString } from "../../../validator5K_admin/constants";
import { REVIEW_STATUS_TYPES } from "../../types";
import { TickerPageLoader } from "../../../../components/lib/TickerLoader";

import { useAttemptDataContext } from "../../context/AttemptDataContext";
import { UI_PAGES, useValidationContext } from "../../context/ValidationContext";
import { useVal5KPublicContext } from "../../context/Val5KPublicContext";
import {
  Card,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderTitle,
} from "../../../validator5K_admin/lib/Card";
import { NavigationButton } from "../../../../components/lib/Button";
import RateDisplayContextProvider from "../../../validator5K_admin/context/RateDisplayContext";

type SurveyItemBaseProps = {
  ratesBlock?: ReactElement;
  secondaryParametersBlock?: React.ReactElement;
  hasNoRatesFeedback: boolean;
  hasNoQuestionsFeedback: boolean;
} & Omit<ButtonsBlockProps, "onSubmitIDK">;

export default function SurveyItemBase(props: SurveyItemBaseProps) {
  const {
    ratesBlock,
    secondaryParametersBlock,
    hasNoRatesFeedback,
    hasNoQuestionsFeedback,
    onSubmitFeedback,
    disableSubmit,
  } = props;

  const { navigateToSurveysList } = useVal5KPublicContext();
  const { attemptData, attemptId, updateAttemptData } = useAttemptDataContext();
  const {
    reviewData,
    reviewType,
    reviewTitle,
    countriesString,
    isActive,
    firstJobData,
    firstJobTitle,
    firstJobRateType,
    attemptsLeft,
    isOrderedSurvey,
  } = useReviewDataContext();
  const { page, dontBlockUI, goToCommentPage } = useValidationContext();

  // handlers

  const handleSubmitIDK = useCallback(async () => {
    await updateAttemptData(attemptId, { review_status: REVIEW_STATUS_TYPES.SKIPPED });
    await goToCommentPage();
  }, [attemptId, updateAttemptData, goToCommentPage]);

  const handleSubmitFeedback = useCallback(async () => {
    await onSubmitFeedback();
    await goToCommentPage();
  }, [onSubmitFeedback, goToCommentPage]);

  // render

  if (!attemptData.size) {
    return <TickerPageLoader />;
  }

  // check if validation is done already
  const reviewStatus = attemptData.get("review_status");
  if (
    (reviewStatus === REVIEW_STATUS_TYPES.REVIEWED ||
      reviewStatus === REVIEW_STATUS_TYPES.SKIPPED) &&
    !dontBlockUI
  ) {
    return (
      <AlertWithButton
        head="Review completed. Thank you for reviewing our rates!"
        onButtonClick={navigateToSurveysList}
      />
    );
  }

  const link = (
    <SimpleLink
      href="http://skillsvillage.peopleticker.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      Skills Village
    </SimpleLink>
  );

  // check for max_attempts number exceeded
  if (!attemptsLeft && !attemptData.size) {
    return (
      <AlertWithButton
        head={"Thank You for responding to our request!"}
        body={`At this time, we have received the maximum number of validation responses.
      Please visit ${link} to see other opportunities.`}
      />
    );
  }

  // survey has been marked as inactive
  // those validators who managed to start it prior to this fact - they will continue validating it
  // those who didn't manage to start validation - won't be able to validate it
  if (!isActive && hasNoRatesFeedback && hasNoQuestionsFeedback) {
    return (
      <AlertWithButton
        head="Thank You for responding to our request!"
        body={`At this time, we have received the maximum number of validation responses.
      Please visit ${link} to see other opportunities.`}
      />
    );
  }

  if (page === UI_PAGES.COMMENT_FORM) {
    return <CommentForm />;
  }

  if (page === UI_PAGES.RATES_FORM) {
    const locationString = getLocationString(firstJobData);
    const regionString = getRegionString(firstJobData);
    const isFirstJobRegionBased = firstJobData.get("region");

    const surveyTitle = isOrderedSurvey ? reviewTitle : firstJobTitle;
    const locationTitle =
      reviewType === REVIEW_TYPES.ORDERED
        ? countriesString
        : isFirstJobRegionBased
        ? regionString
        : locationString;

    const headerTitle =
      firstJobRateType && locationTitle ? (
        <>
          {surveyTitle}, {RATE_TYPES_LABELS[firstJobRateType]}{" "}
          <small>({locationTitle})</small>
        </>
      ) : (
        surveyTitle
      );

    return (
      <RateDisplayContextProvider reviewData={reviewData}>
        <Stack>
          <Card fill>
            <CardActions>
              <CardActionsLeft>
                <CardHeaderTitle>{headerTitle}</CardHeaderTitle>
              </CardActionsLeft>
              <CardActionsRight>
                <NavigationButton icon="arrow-left" onClick={navigateToSurveysList}>
                  Back to List
                </NavigationButton>
              </CardActionsRight>
            </CardActions>
            <CardBody>
              <SurveyParametersBlock
                secondaryParametersBlock={secondaryParametersBlock}
              />
            </CardBody>
            {ratesBlock == null && (
              <CardFooter>
                <ButtonsBlock
                  disableSubmit={disableSubmit}
                  onSubmitIDK={handleSubmitIDK}
                  onSubmitFeedback={handleSubmitFeedback}
                />
              </CardFooter>
            )}
          </Card>

          {ratesBlock != null && (
            <Card fill>
              <CardHeader>
                <CardHeaderTitle as="h3">Rates To Validate</CardHeaderTitle>
              </CardHeader>
              <CardBody>{ratesBlock}</CardBody>
              <CardFooter>
                <ButtonsBlock
                  disableSubmit={disableSubmit}
                  onSubmitIDK={handleSubmitIDK}
                  onSubmitFeedback={handleSubmitFeedback}
                />
              </CardFooter>
            </Card>
          )}
        </Stack>
      </RateDisplayContextProvider>
    );
  }

  return null;
}

SurveyItemBase.displayName = "SurveyItemBase";
