import React from "react";

import Inline, { InlineElements } from "../../../components/lib/Inline";
import Stack from "../../../components/lib/Stack";
import Text from "../../../components/lib/Text";
import Box from "../../../components/lib/Box";

import { styled } from "../../../stitches.config";
import { decimalFormatter } from "../constants";
import { RATE_TYPES_LABELS } from "../types";

import type { JobFeedbackDataMap } from "../types";
import { useRateDisplayContext } from "../context/RateDisplayContext";

export const RateFeedbackValue = styled(Inline, {
  variants: {
    editable: {
      true: {},
      false: {
        padding: "$1_5 $14",
        backgroundColor: "$brandLighter",
      },
    },
  },

  defaultVariants: {
    editable: false,
  },
});

type RateFeedbackElementProps = React.PropsWithChildren<{
  heading: string;
  description: string;
  editable?: boolean;
}>;

export const RateFeedbackBlock = (props: RateFeedbackElementProps) => {
  const { heading, description, editable = false, children } = props;

  return (
    <Inline fill nowrap>
      <Stack css={{ alignItems: "flex-start", gap: "$1" }}>
        <Text size="base">{heading}</Text>
        <Text size="sm" thin>
          {description}
        </Text>
      </Stack>
      <RateFeedbackValue editable={editable} nowrap>
        {children}
      </RateFeedbackValue>
    </Inline>
  );
};
RateFeedbackBlock.displayName = "RateFeedbackBlock";

type FillTheBlankHourlyFeedbackPreviewProps = {
  jobFeedbackData: JobFeedbackDataMap;
};

export function FillTheBlankHourlyFeedbackPreview(
  props: FillTheBlankHourlyFeedbackPreviewProps
) {
  const { jobFeedbackData } = props;

  const {
    displayRateType,
    convertToDisplayCurrency,
    resultingCurrencySymbol,
    convertToDisplayRateType,
    isRateTypeConversionRequired,
  } = useRateDisplayContext();

  let payRateMin = convertToDisplayCurrency(jobFeedbackData.get("pay_rate_min"));
  let payRateMax = convertToDisplayCurrency(jobFeedbackData.get("pay_rate_max"));
  let convertedPayRateMin = isRateTypeConversionRequired
    ? convertToDisplayRateType(payRateMin)
    : null;
  let convertedPayRateMax = isRateTypeConversionRequired
    ? convertToDisplayRateType(payRateMax)
    : null;

  const employmentTaxesPercentage = jobFeedbackData.get("employment_taxes_percentage");
  const supplierRewardsPercentageMin = jobFeedbackData.get(
    "supplier_rewards_percentage_min"
  );
  const supplierRewardsPercentageMax = jobFeedbackData.get(
    "supplier_rewards_percentage_max"
  );

  return (
    <>
      <RateFeedbackBlock
        heading="Lowest Realistic Hourly Wage:"
        description="This rate is for someone with up to 2 years of experience performing the duties described in the job description."
      >
        <InlineElements nowrap>
          <Text nowrap>
            {decimalFormatter(payRateMin, false, resultingCurrencySymbol)}&nbsp;
          </Text>
          {isRateTypeConversionRequired && (
            <Text nowrap>
              {[
                ` (${decimalFormatter(
                  convertedPayRateMin,
                  false,
                  resultingCurrencySymbol
                )}`,
                `${RATE_TYPES_LABELS[displayRateType].toLowerCase()})`,
              ].join(" ")}
            </Text>
          )}
        </InlineElements>
      </RateFeedbackBlock>

      <RateFeedbackBlock
        heading="Highest Realistic Hourly Wage:"
        description="This rate is for someone who is among the best of the best performing the duties described in the job description."
      >
        <InlineElements nowrap>
          <Text nowrap>
            {decimalFormatter(payRateMax, false, resultingCurrencySymbol)}&nbsp;
          </Text>
          {isRateTypeConversionRequired && (
            <Text nowrap>
              {` (${decimalFormatter(
                convertedPayRateMax,
                false,
                resultingCurrencySymbol
              )} ${RATE_TYPES_LABELS[displayRateType].toLowerCase()})`}
            </Text>
          )}
        </InlineElements>
      </RateFeedbackBlock>

      <Text size="base">
        What are taxes and supplier fees/rewards percentages to be expected for this
        resource:
      </Text>

      <Inline fill>
        <Stack css={{ alignItems: "flex-start", gap: "$2", justifySelf: "flex-start" }}>
          <Text>Employment/Payroll Taxes %:</Text>

          <RateFeedbackValue>
            <Text nowrap>{decimalFormatter(employmentTaxesPercentage)}&nbsp;</Text>
          </RateFeedbackValue>
        </Stack>

        <Stack css={{ alignItems: "flex-start", gap: "$2" }}>
          <Text>Supplier Fees % (including profit):</Text>

          <Inline>
            <Box css={{ width: "50px" }}>Low %:</Box>
            <RateFeedbackValue>
              <Text nowrap>{decimalFormatter(supplierRewardsPercentageMin)}&nbsp;</Text>
            </RateFeedbackValue>
          </Inline>

          <Inline>
            <Box css={{ width: "50px" }}>High %:</Box>
            <RateFeedbackValue>
              <Text nowrap>{decimalFormatter(supplierRewardsPercentageMax)}&nbsp;</Text>
            </RateFeedbackValue>
          </Inline>
        </Stack>
      </Inline>
    </>
  );
}

FillTheBlankHourlyFeedbackPreview.displayName = "FillTheBlankHourlyFeedbackPreview";

type FillTheBlankSalaryFeedbackPreviewProps = {
  jobFeedbackData: JobFeedbackDataMap;
};

export function FillTheBlankSalaryFeedbackPreview(
  props: FillTheBlankSalaryFeedbackPreviewProps
) {
  const { jobFeedbackData } = props;

  const { convertToDisplayCurrency, resultingCurrencySymbol } = useRateDisplayContext();

  let annualSalaryMin = convertToDisplayCurrency(
    jobFeedbackData.get("annual_salary_min")
  );
  let annualSalaryMax = convertToDisplayCurrency(
    jobFeedbackData.get("annual_salary_max")
  );

  return (
    <>
      <RateFeedbackBlock
        heading="Lowest Realistic Annual Base Salary:"
        description="This rate is for someone with up to 2 years of experience performing the duties described in the job description."
      >
        <Text>
          {decimalFormatter(annualSalaryMin, false, resultingCurrencySymbol)}&nbsp;
        </Text>
      </RateFeedbackBlock>

      <RateFeedbackBlock
        heading="Highest Realistic Annual Base Salary:"
        description="This rate is for someone who is among the best of the best performing the duties described in the job description."
      >
        <Text>
          {decimalFormatter(annualSalaryMax, false, resultingCurrencySymbol)}&nbsp;
        </Text>
      </RateFeedbackBlock>
    </>
  );
}

FillTheBlankSalaryFeedbackPreview.displayName = "FillTheBlankSalaryFeedbackPreview";
