import React, { useState } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "../../../components/lib/Dialog";
import Stack from "../../../components/lib/Stack";
import Button from "../../../components/lib/Button";
import Divider from "../../../components/lib/Divider";
import RsSearchBySkillsHeader from "./RsSearchBySkillsHeader";
import RsSearchBySkillsResult from "./RsSearchBySkillsResult";
import Box from "../../../components/lib/Box";
import { JobTitleInfo } from "../types";
import Text from "../../../components/lib/Text";

const NoResults = () => {
  return (
    <Stack
      css={{
        alignItems: "flex-center",
        gap: "0",
        width: "$full",
        border: "1px solid $ptGray300",
        padding: "$2_5",
        backgroundColor: "$ptGray300",
      }}
    >
      <h4>
        <Box>Sorry we couldn't find any</Box>
        <Box>matches for your search.</Box>
      </h4>
      <h5>
        <Box css={{ marginTop: "-$4" }}>
          Please try searching with another job description
        </Box>
      </h5>
    </Stack>
  );
};

interface RsSearchBySkillsDialogProps {
  children: React.ReactNode;
  preJobDescription?: string;
  preJobTitle?: string;
  preSkills?: string;
  clientId?: number;
  perSearchPricing: boolean;
  handleGetSearchResults?: (jobTitleInfo: JobTitleInfo) => void;
}

const RsSearchBySkillsDialog = ({
  children,
  preJobDescription = "",
  preJobTitle = "",
  preSkills = "",
  clientId,
  perSearchPricing,
  handleGetSearchResults,
}: RsSearchBySkillsDialogProps) => {
  const [searched, setSearched] = useState(false);
  const [jobResults, setJobResults] = useState([]);

  const onFetchJobResults = (results) => {
    setSearched(true);
    setJobResults(results);
  };

  const onOpenChangeHandler = (isOpen: boolean) => {
    setSearched(false);
    setJobResults([]);
  };

  return (
    <Dialog onOpenChange={onOpenChangeHandler}>
      <DialogTrigger asChild>
        <span>{children}</span>
      </DialogTrigger>
      <DialogContent css={{ width: 800, maxHeight: "80vh", padding: "$4" }}>
        <Stack fill css={{ gap: "$4", alignItems: "flex-start" }}>
          <RsSearchBySkillsHeader
            fetchJobResultsHandler={onFetchJobResults}
            preJobDescription={preJobDescription}
            preSkills={preSkills}
            preJobTitle={preJobTitle}
            clientId={clientId}
            perSearchPricing={perSearchPricing}
          />
          {jobResults.length > 0 && (
            <Stack fill nogap css={{ alignItems: "flex-start" }}>
              {preJobDescription === "" && (
                <Stack fill nogap css={{ alignItems: "flex-start" }}>
                  <Text as="h4" color="dark" noMargin>
                    Search Results:
                  </Text>
                  <Divider css={{ marginTop: "$1" }} />
                </Stack>
              )}

              {jobResults.map((jobResult, idx) => (
                <Box
                  css={{ width: "100%" }}
                  key={`jobResult_${jobResult["rawJobTitleIdMatched"]}`}
                >
                  <RsSearchBySkillsResult
                    jobResult={jobResult}
                    preJobTitle={preJobTitle}
                    handleGetSearchResults={handleGetSearchResults}
                    perSearchPricing={perSearchPricing}
                    jotTitleClickable={preJobDescription === ""}
                  />
                  {idx !== jobResults.length - 1 && (
                    <Divider css={{ marginTop: "$1", marginBottom: "$1" }} />
                  )}
                </Box>
              ))}
              <DialogClose asChild>
                <Button
                  variant="outlined"
                  color="brand"
                  size="small"
                  css={{ alignSelf: "flex-end" }}
                >
                  Close
                </Button>
              </DialogClose>
            </Stack>
          )}
          {jobResults.length === 0 && searched && <NoResults />}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default RsSearchBySkillsDialog;
