import { useState, useCallback, useEffect, useRef } from "react";

// HINT: for initial single fetch use-case use the "useInitialFetch" hook below
export function useIsMountedRef() {
  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;
    return function () {
      isMountedRef.current = false;
    };
  }, []);

  return isMountedRef;
}

export function useInitialFetch(callback: () => void) {
  const isMountedRef = useIsMountedRef();
  if (!isMountedRef.current) {
    callback();
  }
}

/* Hook with logic to manage the selection state of a list of items */
export function useSelectionState() {
  type SelectionState = {
    selectAll: boolean;
    selectAllOnPage: boolean;
    selectedItems: string[];
  };

  const INITIAL_SELECTION_STATE: SelectionState = {
    selectAll: false,
    selectAllOnPage: false,
    selectedItems: [],
  };

  let [selectionState, setSelectionState] = useState<SelectionState>(
    INITIAL_SELECTION_STATE
  );

  function isSelected(id: string) {
    const { selectAll, selectedItems } = selectionState;

    if (selectAll) {
      return true;
    }

    return selectedItems.indexOf(id) !== -1;
  }

  function handleToggleSelect(id: string) {
    let selectedItems = selectionState.selectedItems.slice();

    if (isSelected(id)) {
      // Can be "selected", but not in array.
      const selectedIndex = selectedItems.indexOf(id);
      if (selectedIndex !== -1) {
        selectedItems = selectedItems
          .slice(0, selectedIndex)
          .concat(selectedItems.slice(selectedIndex + 1, selectedItems.length));
      }
      // any deselection disables "select all *"
      // TODO: support "exclude" items while select all
      setSelectionState({
        selectAll: false,
        selectAllOnPage: false,
        selectedItems,
      });
    } else {
      selectedItems.push(id);
      setSelectionState({
        selectAll: selectionState.selectAll,
        selectAllOnPage: selectionState.selectAllOnPage,
        selectedItems,
      });
    }
  }

  function handleToggleSelectAllOnPage(idsOnPage: string[]) {
    const { selectAllOnPage } = selectionState;

    if (selectAllOnPage) {
      setSelectionState({
        selectAll: false,
        selectAllOnPage: false,
        selectedItems: [],
      });
    } else {
      setSelectionState({
        selectAll: false,
        selectAllOnPage: true,
        selectedItems: idsOnPage,
      });
    }
  }

  function handleToggleSelectAll() {
    const { selectAll } = selectionState;

    setSelectionState({
      selectAll: !selectAll,
      selectAllOnPage: !selectAll,
      selectedItems: [],
    });
  }

  function resetSelectionState() {
    setSelectionState({ ...INITIAL_SELECTION_STATE });
  }

  function hasSelection() {
    // Returns true when a selection of any sort has been made
    const { selectAll, selectAllOnPage, selectedItems } = selectionState;

    return selectAll || selectAllOnPage || selectedItems.length > 0;
  }

  return {
    selectionState,
    isSelected,
    hasSelection,
    handleToggleSelect,
    handleToggleSelectAllOnPage,
    handleToggleSelectAll,
    resetSelectionState,
  };
}

export function useModalState(): {
  modalState: boolean;
  showModal: () => void;
  closeModal: () => void;
} {
  const [modalState, setModalState] = useState<boolean>(false);
  const showModal = useCallback(() => setModalState(true), []);
  const closeModal = useCallback(() => setModalState(false), []);

  return {
    modalState,
    showModal,
    closeModal,
  };
}

export type RefreshRequestId = number | null;
export type MakeRefreshRequestFunc = () => Promise<any>;

export function useRefreshRequest(): [RefreshRequestId, MakeRefreshRequestFunc] {
  const [requestId, setRequestId] = useState<number | null>(null);
  const makeRefreshRequest = useCallback(async () => {
    return await setRequestId(new Date().getTime());
  }, [setRequestId]);
  return [requestId, makeRefreshRequest];
}
