import PropTypes from "prop-types";
import React, { Component } from "react";
import { observer } from "mobx-react";
import PlainHighChart from "../../ratecards/components/PlainHighChart";
// import classNames from 'classnames';
// import numeral from 'numeral';
// import LoadingIndicator from '../../ratecards/components/LoadingIndicator';
// import Change from './Change';
// import {Link} from 'react-router';

const LevelCardSparkLine = observer(
  class extends Component {
    render() {
      const data = this.props.data;
      const nonZeroValues = data.filter((val) => Boolean(val));
      const dataMin = nonZeroValues.length > 0 ? Math.min(...nonZeroValues) : 0;
      const dataMax = nonZeroValues.length > 0 ? Math.max(...nonZeroValues) : 0;

      const missingData = this.props.missingData;

      let chartConfig = {
        colors: ["#DDD", this.props.color],
        chart: {
          backgroundColor: null,
          type: "area",
          margin: [0, -15, 0, 0],
          padding: 0,
          width: 120,
          height: 25,
          style: {
            overflow: "visible",
            float: "right",
          },

          // small optimalization, saves 1-2 ms each sparkline
          skipClone: true,
        },
        title: {
          text: "",
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: this.props.categories,
          labels: {
            enabled: false,
          },
          title: {
            text: null,
          },
          startOnTick: false,
          gridLineWidth: 0,
          endOnTick: false,
          lineWidth: 0,
          minorTickLength: 0,
          tickLength: 0,
          tickPositions: [],
        },
        yAxis: {
          endOnTick: false,
          startOnTick: false,
          gridLineWidth: 0,
          lineWidth: 0,
          labels: {
            enabled: false,
          },
          title: {
            text: null,
          },
          tickPositions: [],
          min: dataMin - (dataMax - dataMin) / 2,
          max: dataMax,
        },
        legend: {
          enabled: false,
        },

        // animation: true,
        //   backgroundColor: "rgba(247,247,247,0.85)",
        //   borderColor: null,
        //   borderRadius: 3,
        //   borderWidth: 1,
        //   crosshairs: undefined,
        //   dateTimeLabelFormats: undefined,
        //   enabled: true,
        //   followPointer: false,
        //   followTouchMove: true,
        //   hideDelay: 500,
        //   padding: 8,
        //   pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>',
        //   shadow: true,
        //   shape: "callout",
        //   shared: false,
        //   split: false,
        //   useHTML: false,
        //   valueDecimals: undefined,
        //   valuePrefix: undefined,
        //   valueSuffix: undefined,
        //   xDateFormat: undefined,

        tooltip: {
          backgroundColor: null,
          borderWidth: 0,
          // shadow: false,
          useHTML: true,
          hideDelay: 0,
          shared: false,
          // padding: 0,
          positioner: function (w, h, point) {
            return { x: 10 - w, y: -8 };
          },
          // headerFormat: '<span style="font-size: 10px;">{point.x}</span><br/>',
          // pointFormat: '$ <b>{point.y:.2f}</b>',
          style: {
            color: this.props.color,
          },
        },

        plotOptions: {
          series: {
            animation: false,
            lineWidth: 1,
            shadow: false,
            states: {
              hover: {
                lineWidth: 1,
              },
            },
            marker: {
              radius: 2,
              states: {
                hover: {
                  radius: 2,
                  lineColor: this.props.color,
                },
              },
            },
            fillOpacity: 0.25,
          },
          // column: {
          //   negativeColor: '#910000',
          //   borderColor: 'silver'
          // }
        },

        series: [
          {
            data: missingData,
            pointStart: 0,
            fillOpacity: 0.2,
            lineColor: "rgba(160, 160, 160, .3)", // #A0A0A0 with 0.3 opacity
            tooltip: {
              headerFormat:
                '<span style="font-size: 10px; color: #999">{point.x}</span><br/>',
              pointFormat: '<span style="color: #999"><b>N/A</b></span>',
            },
            marker: {
              radius: 0.5,
              states: {
                hover: {
                  radius: 1,
                  lineColor: "rgba(160, 160, 160, .3)", // #A0A0A0 with 0.3 opacity
                },
              },
            },
          },
          {
            data: data,
            pointStart: 0,
            tooltip: {
              headerFormat: '<span style="font-size: 10px;">{point.x}</span><br/>',
              pointFormat: this.props.currencySymbol + " <b>{point.y}</b>",
              // pointFormatter: formatter,
              // valueDecimals: 2,
              // valuePrefix: this.props.currencySymbol,
              // valueSuffix: ' USD'
            },
          },
        ],
      };

      return <PlainHighChart options={chartConfig} />;
    }
  }
);

LevelCardSparkLine.propTypes = {
  categories: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  missingData: PropTypes.array.isRequired,
  color: PropTypes.string,
  currencySymbol: PropTypes.string,
};

LevelCardSparkLine.defaultProps = {
  color: "#A6D60A",
  currencySymbol: "",
};

export default LevelCardSparkLine;
