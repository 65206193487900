import React, { useState, useMemo, useCallback } from "react";

import Stack from "../../../../components/lib/Stack";
import JobFeedbackTableView from "./JobFeedbackTable";
import JobFeedbackDialogDetails from "./JobFeedbackDialogDetails";
import OrderedDetailsContent from "./OrderedDetailsContent";
import ClassicDetailsContent from "./ClassicDetailsContent";
import FillInTheBlanksDetailsContent from "./FillInTheBlanksDetailsContent";
import {
  Card,
  CardBody,
  CardActions,
  CardActionsLeft,
  CardHeaderTitle,
} from "../../../../components/lib/Card";
import {
  getRegionString,
  getLocationString,
  getValidatorString,
  convertToCurrency,
} from "../../constants";
import {
  REVIEW_TYPES,
  JobDataMap,
  AttemptDataMap,
  OverallJobFeedbackDataMap,
} from "../../types";
import { emptyMap, emptyList, emptyOrderedMap } from "../../../../constants";
import { reviewAttemptToImmutableMap } from "../../dataConverters";
import RateDisplayContextProvider, {
  useRateDisplayContext,
} from "../../context/RateDisplayContext";
import { getRateTypeLabel } from "../../utils";

import {
  ReviewDataContext,
  getReviewDataContextValues,
} from "../../context/ReviewDataContext";
// @ts-expect-error
import { logAsyncOperationError } from "../../../../utils/logging";

import type { ShowModalFunc } from "../../../../components/modals/MessageModal";
import type {
  RATE_TYPES_TYPE,
  CurrencyDataMap,
  AttemptDataObject,
  JobsDataOrderedMap,
} from "../../types";
import type { FetchAPIFunc, FetchAPIResponse } from "../../../../types/fetch";

type JobFeedbackState = {
  feedback: OverallJobFeedbackDataMap;
  attempt: AttemptDataMap;
  job: JobDataMap;
};

type JobFeedbackBlockProps = {
  jobTitle: string;
  jobTitleCollection: string | null;
  jobTitleCollectionId: number | null;
  jobCountry: string | null;
  jobCountryId: number | null;
  jobIndustry: string | null;
  jobIndustryId: number | null;
  rateType: RATE_TYPES_TYPE;
  currencyData: CurrencyDataMap;
  embedded: boolean;
  isThreeLevelBanding: boolean;
  disableButtons: boolean;
  fetchArgusAPI: FetchAPIFunc;
  fetchArgusFilteringAPI: FetchAPIFunc;
  showModalError: ShowModalFunc;
};

function JobFeedbackBlock(props: JobFeedbackBlockProps) {
  const {
    jobTitle,
    jobTitleCollection,
    jobTitleCollectionId,
    jobCountry,
    jobCountryId,
    jobIndustry,
    jobIndustryId,
    rateType,
    currencyData,
    embedded,
    isThreeLevelBanding,
    disableButtons,
    fetchArgusAPI,
    fetchArgusFilteringAPI,
    showModalError,
  } = props;

  const [state, setState] = useState<JobFeedbackState>({
    attempt: emptyMap as unknown as AttemptDataMap,
    job: emptyMap as unknown as JobDataMap,
    feedback: emptyMap as unknown as OverallJobFeedbackDataMap,
  });

  // context

  const { displayRateType, displayRateMultiplier } = useRateDisplayContext();

  const reviewContextValues = useMemo(
    () => getReviewDataContextValues(state.attempt.get("review") || emptyOrderedMap),
    [state]
  );

  // values

  const rateResults = state.job.get("rate_results") || emptyList;
  const ratesFeedback = state.attempt.get("rates_feedback") || emptyOrderedMap;
  const detailsShow =
    state.feedback.size > 0 && state.attempt.size > 0 && state.job.size > 0;
  const detailsReviewType = state.attempt.getIn(["review", "review_type"]);
  const reviewStatus = state.attempt.get("review_status");
  const isAnalysisCompleted = !!state.attempt.get("analysis_completed");
  const requiredCurrencyData = state.job.get("currency_data") || emptyMap;
  const rateTypeLabel = getRateTypeLabel(rateType, displayRateType, null);

  // handlers

  const handleShowDetails = useCallback(
    async (feedback: OverallJobFeedbackDataMap): Promise<void> => {
      const attemptId = feedback.getIn(["attempt", "id"]);
      const jobId = feedback.getIn(["job", "id"]);

      if (attemptId == null || jobId == null) {
        return;
      }

      try {
        const response: FetchAPIResponse<AttemptDataObject> = await fetchArgusAPI(
          `attempts/${attemptId}/`
        );
        const attempt = reviewAttemptToImmutableMap(response.data);

        if (attempt?.size) {
          const job = attempt.getIn(["review", "jobs", jobId]);

          if (job?.size) {
            setState({ feedback, attempt, job });
          }
        }
      } catch (err: any) {
        logAsyncOperationError("handleShowDetails", err);
        showModalError("Error occurred while retrieving validation data");
      }
    },
    [fetchArgusAPI, showModalError]
  );

  const handleCloseDetails = useCallback(() => {
    setState({
      feedback: emptyMap as unknown as OverallJobFeedbackDataMap,
      attempt: emptyMap as unknown as AttemptDataMap,
      job: emptyMap as unknown as JobDataMap,
    });
  }, []);

  const handleMarkAsAnalysisCompleted = useCallback(async () => {
    const attemptId = state.attempt.get("id");
    const isAnalysisCompleted = !!state.attempt.get("analysis_completed");

    if (attemptId == null) {
      return null;
    }

    try {
      const response: FetchAPIResponse<AttemptDataObject> = await fetchArgusAPI(
        `attempts/${attemptId}/`,
        {
          method: "patch",
          data: { analysis_completed: !isAnalysisCompleted },
        }
      );
      const attempt = reviewAttemptToImmutableMap(response.data);

      if (attempt?.size) {
        setState((prevState) => ({
          ...prevState,
          attempt,
        }));
      }

      return attempt;
    } catch (err: any) {
      logAsyncOperationError("handleMarkAsAnalysisCompleted", err);
      showModalError("Error occurred while updating analysis status");
    }
  }, [state.attempt, fetchArgusAPI, showModalError]);

  //render

  const contentBlock = (
    <Stack fill css={{ alignItems: "stretch" }}>
      <JobFeedbackTableView
        jobTitle={jobTitle}
        jobTitleCollection={jobTitleCollection}
        jobTitleCollectionId={jobTitleCollectionId}
        jobCountry={jobCountry}
        jobCountryId={jobCountryId}
        jobIndustry={jobIndustry}
        jobIndustryId={jobIndustryId}
        rateType={rateType}
        isThreeLevelBanding={isThreeLevelBanding}
        currencyData={currencyData}
        displayRateType={displayRateType}
        displayRateMultiplier={displayRateMultiplier}
        onClickDetails={handleShowDetails}
        fetchArgusFilteringAPI={fetchArgusFilteringAPI}
        showModalError={showModalError}
      />
      <JobFeedbackDialogDetails
        reviewStatus={reviewStatus}
        disableButtons={disableButtons}
        show={detailsShow}
        isAnalysisCompleted={isAnalysisCompleted}
        onClose={handleCloseDetails}
        onCompleteAnalysis={handleMarkAsAnalysisCompleted}
      >
        {state.attempt?.size > 0 && (
          <ReviewDataContext.Provider value={reviewContextValues}>
            <RateDisplayContextProvider reviewData={reviewContextValues.reviewData}>
              {detailsReviewType === REVIEW_TYPES.CLASSIC && (
                <ClassicDetailsContent
                  title={state.job.get("title")}
                  jobIsRegionBased={!!state.job.get("region")}
                  jobLocationString={getLocationString(state.job)}
                  jobRegionString={getRegionString(state.job)}
                  currencyCode={requiredCurrencyData.get("code")}
                  currencyName={requiredCurrencyData.get("name")}
                  industries={state.job.get("industry_name")}
                  rateTypeLabel={rateTypeLabel}
                  validatorString={getValidatorString(state.attempt.toJS())}
                  validationTimestamp={state.attempt.get("created")}
                  description={state.job.get("description") || ""}
                  questions={state.attempt.getIn(["review", "questions"])}
                  questionsFeedback={state.attempt.get("questions_feedback")}
                  rateResults={rateResults}
                  ratesFeedback={ratesFeedback}
                />
              )}
              {detailsReviewType === REVIEW_TYPES.FILL_THE_BLANK && (
                <FillInTheBlanksDetailsContent
                  title={state.job.get("title")}
                  jobIsRegionBased={!!state.job.get("region")}
                  jobLocationString={getLocationString(state.job)}
                  jobRegionString={getRegionString(state.job)}
                  currencyCode={currencyData.get("code")}
                  currencyName={currencyData.get("name")}
                  industries={state.job.get("industry_name")}
                  rateTypeLabel={rateTypeLabel}
                  validatorString={getValidatorString(state.attempt.toJS())}
                  validationTimestamp={state.attempt.get("created")}
                  questions={state.attempt.getIn(["review", "questions"])}
                  questionsFeedback={state.attempt.get("questions_feedback")}
                  summaryData={state.feedback}
                  rateType={rateType}
                  currencySymbol={requiredCurrencyData.get("symbol")}
                  convertToValue={(value: number | null) =>
                    convertToCurrency(
                      value,
                      currencyData.toJS(),
                      requiredCurrencyData.toJS()
                    )
                  }
                />
              )}
              {detailsReviewType === REVIEW_TYPES.ORDERED && (
                <OrderedDetailsContent
                  title={state.attempt.getIn(["review", "title"])}
                  countriesString={state.attempt.getIn(["review", "countries_string"])}
                  currencyCode={currencyData.get("code")}
                  currencyName={currencyData.get("name")}
                  industries={state.attempt.getIn(["review", "industries_string"])}
                  rateTypeLabel={rateTypeLabel}
                  validatorString={getValidatorString(state.attempt.toJS())}
                  validationTimestamp={state.attempt.get("created")}
                  questions={state.attempt.getIn(["review", "questions"])}
                  questionsFeedback={state.attempt.get("questions_feedback")}
                  jobsData={
                    (state.attempt.getIn([
                      "review",
                      "jobs",
                    ]) as unknown as JobsDataOrderedMap) || emptyOrderedMap
                  }
                  feedbackData={state.attempt.get("rates_feedback") || emptyOrderedMap}
                  shortMode
                />
              )}
            </RateDisplayContextProvider>
          </ReviewDataContext.Provider>
        )}
      </JobFeedbackDialogDetails>
    </Stack>
  );

  if (embedded) return contentBlock;

  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft>
          <CardHeaderTitle as="h3">Validator Feedback On Title</CardHeaderTitle>
        </CardActionsLeft>
      </CardActions>
      <CardBody>{contentBlock}</CardBody>
    </Card>
  );
}
JobFeedbackBlock.displayName = "JobFeedbackBlock";
JobFeedbackBlock.defaultProps = {
  currencyData: emptyMap,
  isThreeLevelBanding: false,
  embedded: false,
  disableButtons: false,
};

export default JobFeedbackBlock;
