// @flow
import PropTypes from "prop-types";
import React, { Component } from "react";
import { observer } from "mobx-react";
import CheckBox from "../../ratecards/components/CheckBox";
import SearchPeek from "./SearchPeek";
import TagItem from "../../ratecards/components/TagItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CardListItem,
  CardListItemLabel,
  CardListSubText,
  SubTextAccent,
} from "../../../components/lib/Card";
import Spacer from "../../../components/lib/Spacer";
import Tooltip from "../../../components/lib/Tooltip";
import LinkButton from "../../../components/lib/LinkButton";
import Box from "../../../components/lib/Box";

class SearchListItem extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  render() {
    const props = this.props;

    const store = this.props.store;
    const contingentRateFrequency = this.props.contingentRateFrequency;
    const expanded = this.props.expanded;
    const editing = this.props.editing || false;
    const selected = this.props.selected || false;
    const viewable = this.props.viewable;

    const search = props.search;
    let rateMultiplier = 1;
    if (search.frequency.title.toLowerCase() === "contract") {
      rateMultiplier = this.props.rateMultiplier || 1;
    }

    const label = search.label;
    const title = search.title;
    const lastUpdated = search.lastUpdatedDisplay;
    const lastUpdatedDate = search.lastUpdatedDateDisplay;
    const industry = search.industry;
    const frequency = search.frequency;
    const id = search.id;

    let onItemClick = this.props.itemClick;
    let expandable = this.props.expandable;

    let editingSection = null;
    if (editing) {
      editingSection = (
        <CheckBox
          id={`checkbox-${id}`}
          type={store.allowMultipleItemSelection ? "checkbox" : "radio"}
          name="list-item-select"
          value={id}
          selected={selected}
          style={{ width: 34 }}
          onClick={onItemClick}
        />
      );
    }

    let viewSection = null;
    if (viewable) {
      viewSection = (
        <div
          className="rc-center"
          onClick={(e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          }}
        >
          <LinkButton
            to={{
              pathname: `/searches/${search.id}`,
              state: { previousPath: this.context.router.location.pathname },
            }}
          >
            Details
          </LinkButton>
        </div>
      );
    }

    let location = search.locationDetail();

    const tags = search.tags;
    let tagSection = null;
    if (tags.length > 0) {
      tagSection = tags.map((tag) => <TagItem key={tag.tagId} tag={tag} />);
    }

    return (
      <CardListItem
        selected={selected}
        onClick={onItemClick}
        css={{ display: "flex", flexWrap: "wrap" }}
        role="listitem"
      >
        <div className="search-list-item-left ellipsis">
          {editingSection}
          {expandable && (
            <div className="search-expand-state">
              <Box
                as="span"
                css={{
                  fontFamily: "IcoMoon Ultimate",
                  fontSize: "$2xl",
                  transition: "transform 150ms ease-in, color 200ms",
                  color: "$brand",
                  transform: this.props.expanded ? "rotate(90deg)" : "none",
                }}
                // className={classNames("pt-ico-moon", {
                //   expanded: this.props.expanded
                // })}
              >
                
              </Box>
            </div>
          )}
          <div className="ellipsis">
            <CardListItemLabel
              htmlFor={this.props.editing ? `checkbox-${id}` : null}
              className="search-label ellipsis"
            >
              {label}
            </CardListItemLabel>
            <CardListSubText>
              <SubTextAccent>{title} </SubTextAccent> in{" "}
              <SubTextAccent>{location}</SubTextAccent>
            </CardListSubText>
          </div>
        </div>
        <div className="search-list-item-right">
          {search.isGSS && (
            <div className="search-info">
              <header>&nbsp; </header>
              <Tooltip side="top" content="Global Supplier Search">
                <div>
                  <FontAwesomeIcon icon="globe" fixedWidth className="icon" /> GSS
                </div>
              </Tooltip>
            </div>
          )}
          {search.workerTypeName && (
            <div className="search-info">
              <header>Worker Type</header>
              {search.workerTypeName}
            </div>
          )}
          <div className="search-info">
            <header>Industry</header>
            {industry.title}
          </div>
          <div className="search-info">
            <header>Rates from {lastUpdatedDate}</header>
            {lastUpdated}
          </div>
          <div className="search-info">
            <header>Rate Type</header>
            {frequency.title}
          </div>
          {viewSection}
        </div>
        <div className="tagbox">
          <Spacer css={{ flex: "0 1 auto", width: 34 }} />
          {tagSection}
        </div>
        {expandable && (
          <Box css={{ margin: "20px -20px -20px -20px", flex: "1 0 100%" }}>
            <SearchPeek
              store={store}
              expanded={expanded}
              search={search}
              rateMultiplier={rateMultiplier}
              contingentRateFrequency={contingentRateFrequency}
            />
          </Box>
        )}
      </CardListItem>
    );
  }
}

export default observer(SearchListItem);
