import { Set } from "immutable";
import { Observer } from "../../utils/observer";
import { Industry, JobTitle, Location } from "./types";

/**
 * Refetches recent searches on every emit call
 */
export const recentSearchesObserver = new Observer<void>();

/**
 * Emits a value when a jobTitle is selected
 */
export const jobTitleObserver = new Observer<JobTitle | null>();

/**
 * Refetches Pay difference by location data from a given set of search-ids
 */
export const payDifferenceObserver = new Observer<Set<number>>();

export const onLocationsOrIndustriesChange = new Observer<{
  locations: Location[];
  industries: Industry[];
}>();
