/**
 * @generated SignedSource<<59bef0a192a72763068e2c246452fbfe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RateCardSortField = "CREATE_DATE" | "JOB_LABEL" | "NAME" | "OWNER_FIRST_NAME" | "OWNER_LAST_NAME" | "OWNER_LOGIN" | "SHARE" | "%future added value";
export type SortDirection = "ASC" | "DESC" | "%future added value";
export type RateCardsFiltersInput = {
  active?: boolean | null;
  clientidIn?: ReadonlyArray<number | null> | null;
  exclude?: ReadonlyArray<string | null> | null;
  fromDate?: string | null;
  hasBuyRate?: boolean | null;
  hasSearch?: boolean | null;
  hasSearchWithTitleId?: string | null;
  isShared?: boolean | null;
  job_JobLabel?: string | null;
  nameIContains?: string | null;
  only?: ReadonlyArray<string | null> | null;
  ownerId?: ReadonlyArray<string | null> | null;
  rateType?: number | null;
  tagnameIn?: ReadonlyArray<string | null> | null;
  toDate?: string | null;
};
export type RateCardSortInput = {
  caseSensitive?: boolean | null;
  direction?: SortDirection | null;
  field: RateCardSortField;
};
export type RateCardsListPaginationQuery$variables = {
  after?: string | null;
  before?: string | null;
  filters?: RateCardsFiltersInput | null;
  first?: number | null;
  last?: number | null;
  order?: ReadonlyArray<RateCardSortInput | null> | null;
  search?: string | null;
};
export type RateCardsListPaginationQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"RateCardsList_viewer">;
  } | null;
};
export type RateCardsListPaginationQuery = {
  response: RateCardsListPaginationQuery$data;
  variables: RateCardsListPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "before"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "last"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RateCardsListPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "kind": "FragmentSpread",
            "name": "RateCardsList_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RateCardsListPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "RateCardsNodeConnection",
            "kind": "LinkedField",
            "name": "allRateCards",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RateCardsNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RateCardsNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ratecardId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shared",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TagNode",
                        "kind": "LinkedField",
                        "name": "tags",
                        "plural": true,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "tagId",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LegacyUserNode",
                        "kind": "LinkedField",
                        "name": "owner",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastName",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "searchCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "locationCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPreviousPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "filters": [
              "search",
              "filters",
              "order"
            ],
            "handle": "connection_window",
            "key": "RateCardsList_allRateCards",
            "kind": "LinkedHandle",
            "name": "allRateCards"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "684ef9ff4ffd6861172a9e3b4b2b6bbf",
    "id": null,
    "metadata": {},
    "name": "RateCardsListPaginationQuery",
    "operationKind": "query",
    "text": "query RateCardsListPaginationQuery(\n  $after: String\n  $before: String\n  $filters: RateCardsFiltersInput\n  $first: Int\n  $last: Int\n  $order: [RateCardSortInput]\n  $search: String\n) {\n  viewer {\n    ...RateCardsList_viewer_36SlHk\n  }\n}\n\nfragment RateCardsList_viewer_36SlHk on Viewer {\n  user {\n    userId\n  }\n  allRateCards(after: $after, before: $before, first: $first, last: $last, search: $search, filters: $filters, order: $order) {\n    edges {\n      node {\n        id\n        ratecardId\n        shared\n        tags {\n          name\n          tagId\n          id\n        }\n        owner {\n          firstName\n          lastName\n          userId\n        }\n        name\n        createdDate\n        searchCount\n        locationCount\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n      hasPreviousPage\n      startCursor\n    }\n    totalCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "b9f5df9753c8de6d2767ba2093a70399";

export default node;
