// @flow
import * as React from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PunchoutsList from "./components/PunchoutsList";
import SearchBox from "../../ratecards/components/SearchBox";
import classNames from "classnames";

const List = (props) => {
  const store = props.store.punchoutsListPTStore;

  return (
    <div className="view list">
      <div className="rc-container bring-forward">
        <div className="header-action-bar">
          <h2>Punchouts Admin</h2>
          <p className="subtext" />
          <div className="clearfix" />
        </div>
        <div className="header-action-bar marginTopzero">
          {
            <div className="pull-left">
              <SearchBox
                value={store.punchoutLabelFilter.textToLookFor}
                onChange={store.punchoutLabelFilter.onTextToLookForChange}
                onSearch={store.punchoutLabelFilter.onApply}
                style={{ width: "260px" }}
              />
            </div>
          }
          <div className="btn-group pull-right rc-center">
            {store.isEditing && (
              <button className="btn btn-yellow" onClick={store.handleStopEdit}>
                Stop Editing{" "}
              </button>
            )}
            {!store.isEditing && (
              <div>
                <button className="btn btn-green" onClick={store.handleStartEdit}>
                  <FontAwesomeIcon icon="edit" fixedWidth className="icon" />
                  Edit
                </button>
              </div>
            )}
          </div>
          <div className="clearfix" />
        </div>
        <PunchoutsList store={store} />
      </div>

      <div
        className={classNames("ratecards-edit-actions", {
          hidden: store.isEditing === null,
          "bottom-appear": store.isEditing === true,
          "bottom-disappear": store.isEditing === false,
        })}
      >
        {
          <button
            className="btn"
            disabled={store.selectedCount === 0}
            onClick={store.exportPunchouts}
          >
            <FontAwesomeIcon icon="download" fixedWidth className="icon" /> Export
          </button>
        }
      </div>

      <div
        className={classNames("rc-container-backdrop", {
          hidden: store.isEditing === null,
          "fade-in": store.isEditing === true,
          "fade-out": store.isEditing === false,
        })}
      />
    </div>
  );
};

export default observer(List);
