import React, { useState, useCallback } from "react";
import { fromJS } from "immutable";

import Grid from "../../../components/lib/Grid";
import Stack from "../../../components/lib/Stack";
import Box from "../../../components/lib/Box";
import Text from "../../../components/lib/Text";
import Alert from "../../../components/lib/Alert";
import Button from "../../../components/lib/Button";
import Icon from "../../../components/lib/Icon";
import PromiseButton from "../../../components/lib/PromiseButton";
import StickersSelect, { RenderStickerFunc } from "./selects/StickersSelect";
import CountrySelect from "./selects/CountrySelect";
import RateCardSelect, {
  RateCardNodeMap,
  RateCardNodeList,
} from "./selects/RateCardSelect";
import RadioGroupSelect from "./selects/RadioGroupSelect";
import UploadSelect from "./selects/UploadSelect";
import IndustriesSelect from "./selects/IndustriesSelect";
import { ButtonGroupRight, ButtonGroup } from "../../../components/lib/ButtonGroup";
import { RadioGroup, RadioGroupItem } from "../../../components/lib/RadioGroup";
import { emptyList, emptyMap } from "../../../constants";
import { styled, CSS } from "../../../stitches.config";

import type { IconProps } from "../../../components/lib/Icon";
import type { IndustryDataMap, IndustryDataList } from "./selects/IndustriesSelect";
import type {
  Values,
  PermissionDataMap,
  PermsReprKey,
  PermsReprAllowedItemMap,
  CountryDataMap,
  CountriesDataList,
  UploadDataMap,
  UploadDataList,
} from "../types";
import type { RowEditorComponentProps } from "../../../components/tables/types";
import { useVal5KAdminContext } from "../context/Val5KAdminContext";

const radioGroupItemCSS: CSS = {
  padding: "$2",
  color: "$primaryDark",
  backgroundColor: "$secondaryLightest",
};

const StepLabel = styled("label", {
  fontSize: "$base !important",
  color: "$primaryDark",
  paddingTop: "$2",

  variants: {
    disabled: {
      true: {
        color: "$primary",
      },
    },
  },
  defaultVariants: {
    disabled: false,
  },
});

const SelectsColumnsGrid = styled(Grid, {
  gap: "$6",
  gridTemplateColumns: "repeat(1, 84vw)",
  "@md": {
    gridTemplateColumns: "repeat(2, 42vw)",
  },
  "@lg": {
    gridTemplateColumns: "repeat(2, minmax(0, 600px))",
  },
});

const PERMS_TYPES = {
  WHITE_LIST: "white-list",
  COUNTRY_INDUSTRY_BASED: "country-industry-based",
} as const;

type PERMS_TYPES_TYPE = Values<typeof PERMS_TYPES>;

const getPermsTypeValue = (data: PermissionDataMap): PERMS_TYPES_TYPE => {
  const permsRepr = data.get("perms_repr") || emptyMap;
  const selectedRateCards = permsRepr.getIn(["ratecards", "allowed_items"]) || emptyList;
  const selectedUploads = permsRepr.getIn(["uploads", "allowed_items"]) || emptyList;

  if (selectedRateCards.size > 0 || selectedUploads.size > 0) {
    return PERMS_TYPES.WHITE_LIST;
  }

  return PERMS_TYPES.COUNTRY_INDUSTRY_BASED;
};

interface ValidatorPermissionsEditorProps
  extends RowEditorComponentProps<PermissionDataMap> {
  onApply: (data: PermissionDataMap) => Promise<unknown>;
  onDelete: (data: PermissionDataMap) => Promise<unknown>;
  onCancel: () => void;
}

const ValidatorPermissionsEditor = (props: ValidatorPermissionsEditorProps) => {
  const { data: initialPerms, onApply, onCancel, onDelete } = props;
  const { showConfirmationModal, closeConfirmationModal } = useVal5KAdminContext();

  const [permsState, setPermsState] = useState<PermissionDataMap>(initialPerms);
  const [permsTypeState, setPermsType] = useState<PERMS_TYPES_TYPE>(
    getPermsTypeValue(initialPerms)
  );
  const hasChanges = permsState !== initialPerms;

  // handlers

  const handleDeleteObject = useCallback(
    () => onDelete(permsState),
    [permsState, onDelete]
  );

  const handleApplyChanges = useCallback(
    () => onApply(permsState),
    [permsState, onApply]
  );

  const handlePermsTypeChange = useCallback(
    (value: PERMS_TYPES_TYPE) => {
      if (value !== permsTypeState) {
        if (value === PERMS_TYPES.COUNTRY_INDUSTRY_BASED) {
          setPermsState(
            permsState
              .setIn(["perms_repr", "ratecards"], emptyMap)
              .setIn(["perms_repr", "uploads"], emptyMap) as unknown as PermissionDataMap
          );
        }
        setPermsType(value);
        closeConfirmationModal();
      }
    },
    [permsState, permsTypeState, setPermsState, setPermsType, closeConfirmationModal]
  );

  const handlePermsTypeChangeConfirmation = useCallback(
    (value: PERMS_TYPES_TYPE) => {
      const permsRepr = permsState.get("perms_repr") || emptyMap;

      if (permsRepr?.size) {
        const header = "Permissions Type Change";
        const footer = (
          <ButtonGroupRight fill css={{ flexDirection: "row-reverse" }}>
            <Button size="large" onClick={closeConfirmationModal}>
              Cancel
            </Button>
            <Button
              size="large"
              color="brand"
              onClick={() => handlePermsTypeChange(value)}
            >
              Yes, Proceed
            </Button>
          </ButtonGroupRight>
        );
        const message = (
          <Stack fill css={{ alignItems: "flex-start" }}>
            <Text>You are about to change the way permissions applied.</Text>
            {value === PERMS_TYPES.WHITE_LIST && (
              <Alert color="warning">
                <Text>
                  <Text bold underline italic>
                    Important!
                  </Text>
                  &nbsp;This action will <Text bold>DISABLE</Text> all your existing
                  Country/Industry based permissions configuration.
                </Text>
              </Alert>
            )}
            {value === PERMS_TYPES.COUNTRY_INDUSTRY_BASED && (
              <Alert color="warning">
                <Text>
                  <Text bold underline italic>
                    Important!
                  </Text>
                  &nbsp;This action will <Text bold>REMOVE</Text> all your existing White
                  List based permissions configuration.
                </Text>
              </Alert>
            )}
          </Stack>
        );

        showConfirmationModal(message, header, footer);
      } else {
        handlePermsTypeChange(value);
      }
    },
    [permsState, handlePermsTypeChange, showConfirmationModal, closeConfirmationModal]
  );

  const handleSelectNoneItems = useCallback(
    (key: PermsReprKey) => {
      setPermsState(
        permsState.setIn(
          ["perms_repr", key],
          fromJS({
            allowed_none: true,
            allowed_all: false,
            allowed_items: emptyList,
          })
        ) as unknown as PermissionDataMap
      );
    },
    [permsState]
  );

  const handleSelectAllItems = useCallback(
    (key: PermsReprKey) => {
      setPermsState(
        permsState.setIn(
          ["perms_repr", key],
          fromJS({
            allowed_none: false,
            allowed_all: true,
            allowed_items: emptyList,
          })
        ) as unknown as PermissionDataMap
      );
    },
    [permsState]
  );

  const handleStartSelectItems = useCallback(
    (key: PermsReprKey) => {
      setPermsState(
        permsState.setIn(
          ["perms_repr", key],
          fromJS({
            allowed_none: false,
            allowed_all: false,
            allowed_items: emptyList,
          })
        ) as unknown as PermissionDataMap
      );
    },
    [permsState]
  );

  const handleSelectItem = useCallback(
    (key: PermsReprKey, selectedItemData: PermsReprAllowedItemMap) => {
      if (selectedItemData?.size) {
        const selectedItemId = selectedItemData.get("id");
        const selectedItems =
          permsState.getIn(["perms_repr", key, "allowed_items"]) || emptyList;
        const existing = selectedItems.find(
          (i: PermsReprAllowedItemMap) => i.get("id") === selectedItemId
        );

        if (existing == null) {
          setPermsState(
            permsState.setIn(
              ["perms_repr", key],
              fromJS({
                allowed_none: false,
                allowed_all: false,
                allowed_items: selectedItems.push(selectedItemData),
              })
            ) as unknown as PermissionDataMap
          );
        }
      }
    },
    [permsState]
  );

  const handleUnselectItem = useCallback(
    (key: PermsReprKey, unselectedItemData: PermsReprAllowedItemMap) => {
      if (unselectedItemData?.size) {
        const selectedItemId = unselectedItemData.get("id");
        const selectedItems =
          permsState.getIn(["perms_repr", key, "allowed_items"]) || emptyList;
        const newSelectedItems = selectedItems.filter(
          (i: PermsReprAllowedItemMap) => i.get("id") !== selectedItemId
        );

        if (newSelectedItems?.size !== selectedItems?.size) {
          setPermsState(
            permsState.setIn(
              ["perms_repr", key],
              fromJS({
                allowed_none: false,
                allowed_all: false,
                allowed_items: newSelectedItems,
              })
            ) as unknown as PermissionDataMap
          );
        }
      }
    },
    [permsState]
  );

  const permsRepr = permsState.get("perms_repr") || emptyMap;
  const selectedRateCards: RateCardNodeList =
    permsRepr.getIn(["ratecards", "allowed_items"]) || emptyList;
  const selectedUploads: UploadDataList =
    permsRepr.getIn(["uploads", "allowed_items"]) || emptyList;
  const countriesPermsRepr = permsRepr.get("countries") || emptyMap;
  const selectedNoneCountries = countriesPermsRepr.get("allowed_none") || false;
  const selectedAllCountries = countriesPermsRepr.get("allowed_all") || false;
  const selectedCountries: CountriesDataList =
    countriesPermsRepr.get("allowed_items") || emptyList;
  const industriesPermsRepr = permsRepr.get("industries") || emptyMap;
  const selectedNoneIndustries = industriesPermsRepr.get("allowed_none") || false;
  const selectedAllIndustries = industriesPermsRepr.get("allowed_all") || false;
  const selectedIndustries: IndustryDataList =
    industriesPermsRepr.get("allowed_items") || emptyList;

  type StickerValueFunc = (icon?: IconProps["icon"]) => RenderStickerFunc;
  const getStickerValue: StickerValueFunc = useCallback((icon?: IconProps["icon"]) => {
    return (item) => {
      const itemId = item.get("id");
      const itemTitle = item.get("title");
      return (
        <Text>
          {icon && <Icon icon={icon} />}&nbsp;{`#${itemId} - ${itemTitle}`}
        </Text>
      );
    };
  }, []);

  const getStickerCountryValue: RenderStickerFunc = useCallback((item) => {
    const title = item.get("title");
    const locationCode = item.get("location_code");

    return (
      <Text>
        <Icon icon="map-marker-alt" /> {title} {locationCode && ` (${locationCode})`}
      </Text>
    );
  }, []);

  const getStickerIndustriesValue: RenderStickerFunc = useCallback((item) => {
    const title = item.get("title");
    const icon = item.get("faIcon");

    return (
      <Text>
        {icon && <Icon icon={icon} />}&nbsp;{title}
      </Text>
    );
  }, []);

  return (
    <Stack fill nogap css={{ alignItems: "flex-start" }}>
      <RadioGroup
        value={permsTypeState}
        onValueChange={handlePermsTypeChangeConfirmation}
        style={{ width: "100%" }}
      >
        <RadioGroupItem value={PERMS_TYPES.WHITE_LIST} css={radioGroupItemCSS}>
          <Text as="h4">Ratecards/uploads white list</Text>
        </RadioGroupItem>
        <Stack fill css={{ alignItems: "flex-start", padding: "$5" }}>
          <Alert fill color="warning">
            <Text underline bold>
              IMPORTANT:
            </Text>
            &nbsp; White List based permissions will take precedence over the
            Country/Industry based approach.
          </Alert>
          <SelectsColumnsGrid>
            <Stack fill css={{ gap: "$2", alignItems: "flex-start" }}>
              <StepLabel disabled={permsTypeState !== PERMS_TYPES.WHITE_LIST}>
                1. Choose Rate Cards available for validation:
              </StepLabel>
              <Box fill>
                <StickersSelect<RateCardNodeMap>
                  selectedItems={selectedRateCards}
                  noItemsSelectedText="No rate cards selected"
                  getStickerValue={getStickerValue(["far", "file-alt"])}
                  disabled={permsTypeState !== PERMS_TYPES.WHITE_LIST}
                  onRemove={(permsState) => handleUnselectItem("ratecards", permsState)}
                />
                <RateCardSelect
                  values={selectedRateCards}
                  disabled={permsTypeState !== PERMS_TYPES.WHITE_LIST}
                  onSelect={(permsState) => handleSelectItem("ratecards", permsState)}
                />
              </Box>
            </Stack>

            <Stack fill css={{ gap: "$2", alignItems: "flex-start" }}>
              <StepLabel disabled={permsTypeState !== PERMS_TYPES.WHITE_LIST}>
                2. Choose Uploads available for validation:
              </StepLabel>
              <Box fill>
                <StickersSelect<UploadDataMap>
                  selectedItems={selectedUploads}
                  noItemsSelectedText="No uploads selected"
                  getStickerValue={getStickerValue(["far", "file-excel"])}
                  disabled={permsTypeState !== PERMS_TYPES.WHITE_LIST}
                  onRemove={(permsState) => handleUnselectItem("uploads", permsState)}
                />
                <UploadSelect
                  values={selectedUploads}
                  disabled={permsTypeState !== PERMS_TYPES.WHITE_LIST}
                  onSelect={(permsState) => handleSelectItem("uploads", permsState)}
                />
              </Box>
            </Stack>
          </SelectsColumnsGrid>
        </Stack>

        <RadioGroupItem
          value={PERMS_TYPES.COUNTRY_INDUSTRY_BASED}
          css={radioGroupItemCSS}
        >
          <Text as="h4">Country/Industry based permissions</Text>
        </RadioGroupItem>
        <Stack fill css={{ alignItems: "flex-start", padding: "$5" }}>
          <SelectsColumnsGrid>
            <Stack fill css={{ gap: "$2", alignItems: "flex-start" }}>
              <StepLabel disabled={permsTypeState !== PERMS_TYPES.COUNTRY_INDUSTRY_BASED}>
                1. Select available countries for validation:
              </StepLabel>
              <RadioGroupSelect
                selectedNone={selectedNoneCountries}
                selectedAll={selectedAllCountries}
                selectAllTitle="All countries allowed for validation"
                selectItemsTitle="Allow particular countries for validation"
                selectNoneTitle="No countries allowed for validation"
                onSelectAll={() => handleSelectAllItems("countries")}
                onStartSelectItems={() => handleStartSelectItems("countries")}
                onSelectNone={() => handleSelectNoneItems("countries")}
                disabled={permsTypeState !== PERMS_TYPES.COUNTRY_INDUSTRY_BASED}
              >
                <Stack fill css={{ gap: "$2", alignItems: "flex-start", margin: "$4" }}>
                  <StickersSelect<CountryDataMap>
                    selectedItems={selectedCountries}
                    noItemsSelectedText="No countries selected."
                    getStickerValue={getStickerCountryValue}
                    disabled={permsTypeState !== PERMS_TYPES.COUNTRY_INDUSTRY_BASED}
                    onRemove={(permsState) => handleUnselectItem("countries", permsState)}
                  />
                  <CountrySelect
                    values={selectedCountries}
                    disabled={
                      permsTypeState !== PERMS_TYPES.COUNTRY_INDUSTRY_BASED ||
                      selectedNoneCountries ||
                      selectedAllCountries
                    }
                    onSelect={(permsState: CountryDataMap) =>
                      handleSelectItem("countries", permsState)
                    }
                    searchPlaceholder="Search countries by title or code"
                    noItemsFoundText="No countries found"
                  />
                </Stack>
              </RadioGroupSelect>
            </Stack>

            <Stack fill css={{ gap: "$2", alignItems: "flex-start" }}>
              <StepLabel disabled={permsTypeState !== PERMS_TYPES.COUNTRY_INDUSTRY_BASED}>
                2. Select available industries for validation:
              </StepLabel>
              <RadioGroupSelect
                selectedAll={selectedAllIndustries}
                selectedNone={selectedNoneIndustries}
                selectAllTitle="All industries allowed for validation"
                selectItemsTitle="Allow particular industries for validation"
                selectNoneTitle="No industries allowed for validation"
                onSelectAll={() => handleSelectAllItems("industries")}
                onStartSelectItems={() => handleStartSelectItems("industries")}
                onSelectNone={() => handleSelectNoneItems("industries")}
                disabled={permsTypeState !== PERMS_TYPES.COUNTRY_INDUSTRY_BASED}
              >
                <Stack fill css={{ gap: "$2", alignItems: "flex-start", margin: "$4" }}>
                  <StickersSelect<IndustryDataMap>
                    selectedItems={selectedIndustries}
                    noItemsSelectedText="No industries selected"
                    getStickerValue={getStickerIndustriesValue}
                    disabled={permsTypeState !== PERMS_TYPES.COUNTRY_INDUSTRY_BASED}
                    onRemove={(permsState) =>
                      handleUnselectItem("industries", permsState)
                    }
                  />
                  <IndustriesSelect
                    values={selectedIndustries}
                    disabled={
                      permsTypeState !== PERMS_TYPES.COUNTRY_INDUSTRY_BASED ||
                      selectedNoneIndustries ||
                      selectedAllIndustries
                    }
                    onSelect={(permsState) => handleSelectItem("industries", permsState)}
                    searchPlaceholder="Search industries by title"
                    noItemsFoundText="No industries found"
                  />
                </Stack>
              </RadioGroupSelect>
            </Stack>
          </SelectsColumnsGrid>
        </Stack>
      </RadioGroup>

      <ButtonGroup css={{ padding: "$3 $4", paddingTop: "0" }}>
        <PromiseButton
          icon="check"
          color="success"
          variant="outlined"
          size="normal"
          title="Apply changes and close editor"
          loadingText="Apply Changes"
          disabled={!hasChanges}
          onClick={handleApplyChanges}
        >
          Apply Changes
        </PromiseButton>
        <PromiseButton
          icon="trash"
          color="danger"
          variant="outlined"
          size="normal"
          title="Delete permissions object"
          loadingText="Delete Object"
          onClick={handleDeleteObject}
        >
          Delete Object
        </PromiseButton>
        <PromiseButton
          icon="times"
          variant="outlined"
          size="normal"
          title="Cancel changes and close editor"
          loadingText="Close Editor"
          onClick={onCancel}
        >
          Close Editor
        </PromiseButton>
      </ButtonGroup>
    </Stack>
  );
};
ValidatorPermissionsEditor.displayName = "ValidatorPermissionsEditor";

export default ValidatorPermissionsEditor;
