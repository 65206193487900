import React from "react";
// lig
import { styled } from "../../../stitches.config";
import Tag from "../../../components/lib/Tag";
// types
import { JobTitle } from "../types";

const Wrapper = styled("span", {
  textAlign: "right",
});

type Props = {
  job: JobTitle | null;
  perSearchPricing: boolean;
  addMargin?: boolean;
  isSideMenu?: boolean;
};

export function JobTags(props: Props) {
  const { job, perSearchPricing, addMargin = false, isSideMenu = false } = props;

  const tagCss = {
    fontSize: "$xs",
    padding: "0 4px",
    "&:not(:first-child)": {
      marginLeft: addMargin ? "$1" : "0",
    },
  };

  return (
    <Wrapper>
      {!isSideMenu && !perSearchPricing && job?.shareInfo && !job?.shareInfo.isMine && (
        <Tag css={tagCss} size="extra-small" icon="user" color="gray">
          {job?.shareInfo?.sharedBy?.firstName}
          {job?.shareInfo?.sharedBy?.lastName}
        </Tag>
      )}
      {!perSearchPricing &&
        Boolean(job?.clientJobLibraryInfo?.certifiedCountries?.length) && (
          <Tag
            css={tagCss}
            size="extra-small"
            icon="globe-americas"
            color="brand"
            text={String(job?.clientJobLibraryInfo?.certifiedCountries?.length)}
          />
        )}
      {!perSearchPricing &&
        Boolean(job?.clientJobLibraryInfo?.adhocCountries?.length) && (
          <Tag
            css={tagCss}
            size="extra-small"
            icon="globe-americas"
            text={String(job?.clientJobLibraryInfo?.adhocCountries?.length)}
            color="danger"
          />
        )}
      {perSearchPricing && job?.isJobLabel && <span> (via My Job Labels)</span>}
      {perSearchPricing && !job?.isJobLabel && <span> (stock title)</span>}
    </Wrapper>
  );
}
