import React from "react";

import Text from "../../../../components/lib/Text";
import { emptyMap, emptyOrderedMap } from "../../../../constants";
import { compareDecimals, decimalFormatter } from "../../constants";
import { REVIEW_TYPES, RATE_TYPES } from "../../types";
import { getFirstJobData, getUnitsString, getJobRateTypeParameters } from "./utils";
import { useRateDisplayContext } from "../../context/RateDisplayContext";

import type {
  ReviewDataMap,
  JobFeedbackDataMap,
  RatesFeedbackDataMap,
} from "../../types";

type RateValue = "min" | "max";

type RateBlockProps = {
  reviewData: ReviewDataMap;
  rateValue: RateValue;
  showValueFeedback?: boolean;
};

export default function RateBlock(props: RateBlockProps) {
  const { reviewData, rateValue } = props;
  const {
    convertToDisplayCurrency,
    resultingCurrencySymbol,
    displayRateType,
    convertToDisplayRateType,
    isRateTypeConversionRequired,
  } = useRateDisplayContext();

  const reviewType = reviewData.get("review_type");
  const firstJobData = getFirstJobData(reviewData);
  const rateResults = firstJobData.get("rate_results") ?? emptyOrderedMap;
  const { rateType, rateMultiplier } = getJobRateTypeParameters(firstJobData);

  let rateResultData =
    rateValue === "min"
      ? rateResults.first() ?? emptyMap
      : rateResults.last() ?? emptyMap;

  // retrieve rate and feedback values
  let value = null;
  let feedbackValue = null;
  if (reviewType === REVIEW_TYPES.CLASSIC) {
    const rateResultId = rateResultData.get("id");
    const rateFeedbackData: RatesFeedbackDataMap =
      reviewData.getIn(["attempt", "rates_feedback", rateResultId]) ?? emptyMap;
    const key = `pay_rate_${rateValue}` as const;

    value = rateResultData?.get(key);
    feedbackValue = rateFeedbackData?.get(key);
  } else if (reviewType === REVIEW_TYPES.FILL_THE_BLANK) {
    const jobId = firstJobData.get("id");
    const jobFeedbackData: JobFeedbackDataMap =
      reviewData.getIn(["attempt", "jobs_feedback", jobId]) ?? emptyMap;
    const key = `${
      rateType === RATE_TYPES.ANNUAL ? "annual_salary" : "pay_rate"
    }_${rateValue}` as const;

    value = null;
    feedbackValue = jobFeedbackData.get(key);
  }

  // apply rate type conversion
  if (rateType !== RATE_TYPES.ANNUAL) {
    value = convertToDisplayRateType(value, rateType, rateMultiplier);
    feedbackValue = convertToDisplayRateType(feedbackValue, rateType, rateMultiplier);
  }

  // apply currency conversion
  value = convertToDisplayCurrency(value);
  feedbackValue = convertToDisplayCurrency(feedbackValue);

  const valueString = decimalFormatter(value, false, resultingCurrencySymbol);
  const valueFeedbackString = decimalFormatter(
    feedbackValue,
    false,
    resultingCurrencySymbol
  );
  const valueUnitsString = getUnitsString(
    isRateTypeConversionRequired ? displayRateType : rateType
  );
  const hasFeedback = !!feedbackValue && compareDecimals(value ?? 0, feedbackValue) !== 0;
  const colorText = !value
    ? undefined
    : feedbackValue && feedbackValue < value
    ? "negative"
    : "positive";

  return (
    <>
      {hasFeedback && (
        <>
          <Text>
            <s>{valueString}</s>
          </Text>
          <Text color={colorText}>
            {valueFeedbackString}
            {value != null ? ` / ${valueUnitsString}` : ""}
          </Text>
        </>
      )}
      {!hasFeedback && (
        <Text>
          {valueString}
          {value != null ? ` / ${valueUnitsString}` : ""}
        </Text>
      )}
    </>
  );
}

RateBlock.displayName = "RateBlock";
