// @flow strict
import * as React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment";
import graphql from "babel-plugin-relay/macro";
import { createFragmentContainer } from "react-relay";
import { Link } from "react-router";
import Tag from "./Tag";
import CheckBox from "../views/shared/CheckBox";
import type { TagItem } from "./Tag";
// $FlowFixMe
import type { Job_job } from "./__generated__/Job_job.graphql";

type Props = {
  job: Job_job,
  editing: boolean,
  selected: boolean,
  multiSelect: boolean,
  onToggleSelect: (id: string) => void,
  getDetailRoute?: (string) => string,
};

class Job extends React.Component<Props, {}> {
  static contextTypes = {
    router: PropTypes.object,
  };

  handleNavigate = () => {
    this.context.router.push({
      pathname: this.getDetailRoute(this.props.job.jobId.toString(10)),
      query: this.context.router.query,
    });
  };

  getDetailRoute = (id: string): string =>
    this.props.getDetailRoute ? this.props.getDetailRoute(id) : `/job-labels/${id}/`;

  render() {
    const { job, selected, editing, multiSelect } = this.props;

    const tagSection = job.tags?.map((tag, i) =>
      tag ? (
        <Tag
          key={tag.id}
          tag={tag}
          render={(tag: TagItem): React.Node => (
            <div className="toast-cover">
              {tag.name}
              <span />
            </div>
          )}
        />
      ) : (
        <div key={i}>[Error loading tag]</div>
      )
    );

    /*
  if (editing) {
    onItemClick = job.toggleSelected;
    editingSection = (
      <CheckBox
        type={store.allowMultipleItemSelection ? "checkbox" : "radio"}
        name="list-item-select"
        value={jobLabel}
        selected={selected}
        style={{
          width: 34
        }}
        onClick={onItemClick}
      />
    );
  }
  */

    let onItemClick = this.handleNavigate;
    let editingSection = null;

    if (editing) {
      onItemClick = () => {
        this.props.onToggleSelect(job.jobId);
      };
      editingSection = (
        <CheckBox
          type={multiSelect ? "checkbox" : "radio"}
          name="list-item-select"
          value={job.id}
          selected={selected}
          style={{
            width: 34,
          }}
          onClick={onItemClick}
        />
      );
    }

    return (
      <div className="ratecard-list-item-container">
        <div className="ratecard-list-item" onClick={onItemClick}>
          <div className="ratecard-list-item-left ellipsis">
            {editingSection}
            <div className="ratecard-label-container ellipsis">
              <div className="ratecard-label ellipsis">
                <span className="ellipsis">{job.jobLabel}</span>
              </div>
              <div className="ratecard-meta ellipsis">
                <span className="item-subtext-muted">Created by</span>
                <span className="item-subtext-accent"> {job.createdBy?.username}</span>
                <span className="item-subtext-muted"> on</span>
                <span className="item-subtext-accent">
                  {" "}
                  {moment(job.created).format("MMMM D, YYYY")}
                </span>
              </div>
              <div className="ratecard-meta ellipsis">
                <span className="item-subtext-muted">{job.jobDescription}</span>
              </div>
            </div>
          </div>
          <div className="ratecard-list-item-right">
            <div className="ratecard-info w-300 text-center">
              <header data-testid="ID-header-job-labels">ID</header>
              <div>{job.jobId}</div>
            </div>
            <div className="ratecard-info w-300 text-center">
              <header data-testid="job-title-header-job-labels">Job Title</header>
              <div>{job.jobTitle}</div>
            </div>

            <div className="rc-center">
              {!editing && (
                <Link
                  to={this.getDetailRoute(job.jobId.toString(10))}
                  className="btn btn-default"
                >
                  View
                </Link>
              )}
            </div>
          </div>
          <div className={classNames("tagbox", { "p-left-34 ": editing })}>
            {tagSection}
          </div>
        </div>
      </div>
    );
  }
}

export default createFragmentContainer(Job, {
  job: graphql`
    fragment Job_job on JobNodeType {
      id
      jobId
      jobTitle
      jobLabel
      jobDescription
      created
      createdBy {
        username
      }
      tags {
        id
        ...Tag_tag
      }
    }
  `,
});
