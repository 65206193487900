import React from "react";
import { Map } from "immutable";

import Button from "../../lib/Button";
import OrderFilter from "./OrderFilter";
import ValuesCheckFilter from "./ValuesCheckFilter";
import ValuesCheckFilterRestful from "./ValuesCheckFilterRestful";
import DatesFilter from "./DatesFilter";
import NumbersFilter from "./NumbersFilter";
import EnumFilter from "./EnumFilter";
import ValuesIContainsFilter from "./ValuesIContainsFilter";
import ValuesIContainsFilterRestful from "./ValuesIContainsFilterRestful";
import { ButtonGroup } from "../../lib/ButtonGroup";
import { emptyFilter, FilterTypes, emptyOrderedMap } from "../constants";
import Stack from "../../lib/Stack";
import Divider from "../../lib/Divider";

import {
  ComparatorsConfig,
  DataProvider,
  FilterConfigMap,
  FilterOptionsList,
  FilterPopupSizes,
  FilterPopupSizesType,
  FiltersConfigOrderedMap,
  FiltersDataProviderRestful,
  FiltersQueryOrderedMap,
  FilterTypesType,
  RowData,
  RowIdGetterFunction,
  ValueGetterFunc,
} from "../types";
import type {
  Activatable,
  Initializable,
  OnApplyFilterFunction,
  OnCancelFilterFunction,
} from "./types";

const contentSizeMap = Map<FilterPopupSizesType, string>({
  small: "200px",
  regular: "300px",
  wide: "400px",
  xwide: "500px",
});

type FiltersPopupProps<RD = RowData> = {
  uniqueKey: string;
  dataProvider: DataProvider<RD> | FiltersDataProviderRestful;
  dataProviderFilters: FiltersConfigOrderedMap | FiltersQueryOrderedMap;
  getter: ValueGetterFunc<RD>;
  idGetter: RowIdGetterFunction<RD>;
  comparators: ComparatorsConfig;
  // features switches
  filterable: boolean;
  sortable: boolean;
  restful: boolean;
  // filter props
  filter: FilterConfigMap;
  filterType: FilterTypesType;
  filterKey: string;
  filterOptions?: FilterOptionsList;
  filterExtraProps?: Object;
  onApply: OnApplyFilterFunction;
  onCancel: OnCancelFilterFunction;
  //
  isVisible: boolean;
  popupSize: FilterPopupSizesType;
};

const FiltersPopup = React.forwardRef(
  <RD = RowData,>(
    props: FiltersPopupProps<RD>,
    ref: React.ForwardedRef<Initializable>
  ) => {
    const {
      filterable,
      sortable,
      filter: defaultFilter,
      uniqueKey,
      filterType,
      filterKey,
      filterOptions,
      filterExtraProps,
      dataProvider,
      dataProviderFilters,
      getter,
      idGetter,
      comparators,
      restful,
      onApply,
      onCancel,
      isVisible,
      popupSize,
    } = props;

    // state

    const [filterState, setFilterState] = React.useState(defaultFilter);

    // effects

    React.useEffect(() => {
      setFilterState(defaultFilter);
    }, [defaultFilter]);

    // refs

    const filterRef = React.useRef<Activatable>(null);

    // external API methods

    const initialize = React.useCallback(() => {
      filterRef.current?.initialize?.();
      window.setTimeout(() => {
        filterRef.current?.focus?.();
      }, 100);
    }, []);

    React.useImperativeHandle(ref, () => ({ initialize }));

    // handlers

    const handleApplyClick = React.useCallback(() => {
      onApply(uniqueKey, filterState);
    }, [filterState, onApply, uniqueKey]);

    const handleCancelClick = React.useCallback(() => {
      onCancel();
      setFilterState(defaultFilter);
    }, [onCancel, defaultFilter]);

    const handleClearClick = React.useCallback(() => {
      setFilterState(emptyFilter);
    }, []);

    const handleChangeFilter = React.useCallback(
      (filterValue) => {
        if (filterValue !== filterState) {
          setFilterState(filterValue);
        }
      },
      [filterState]
    );

    // utils

    const isDateRangeFilter = React.useMemo(() => {
      if (filterType && filterType === FilterTypes.DATES_RANGE) {
        return true;
      }
      // auto-detect filter type
      // return !!(getter(data.first()) instanceof Date);
      return false;
    }, [filterType]);

    const isNumberRangeFilter = React.useMemo(() => {
      if (filterType && filterType === FilterTypes.NUMBERS_RANGE) {
        return true;
      }
      // auto-detect filter type
      // return !!(isNumber(getter(data.first())));
      return false;
    }, [filterType]);

    const isEnumerationFilter = React.useMemo(() => {
      if (filterType && filterType === FilterTypes.ENUMERATION) {
        return true;
      }
      // auto-detect filter type
      return !!(filterOptions && filterOptions.size > 0);
    }, [filterOptions, filterType]);

    const isValuesIContainsFilter = React.useMemo(() => {
      return !!(filterType && filterType === FilterTypes.VALUES_ICONTAINS);
    }, [filterType]);

    // render methods

    const renderFilterComponent = () => {
      if (isEnumerationFilter) {
        return (
          <EnumFilter
            ref={filterRef}
            filter={filterState}
            filterOptions={filterOptions!}
            comparators={comparators}
            onChange={handleChangeFilter}
            {...(filterExtraProps || {})}
          />
        );
      } else if (isDateRangeFilter) {
        return (
          <DatesFilter
            ref={filterRef}
            filter={filterState}
            onChange={handleChangeFilter}
            {...(filterExtraProps || {})}
          />
        );
      } else if (isNumberRangeFilter) {
        return (
          <NumbersFilter
            ref={filterRef}
            filter={filterState}
            onChange={handleChangeFilter}
            {...(filterExtraProps || {})}
          />
        );
      } else if (isValuesIContainsFilter) {
        if (restful) {
          return (
            <ValuesIContainsFilterRestful
              ref={filterRef}
              uniqueKey={filterKey || uniqueKey}
              dataProvider={dataProvider as FiltersDataProviderRestful}
              dataProviderFilters={dataProviderFilters as FiltersQueryOrderedMap}
              getter={getter}
              idGetter={idGetter}
              comparators={comparators}
              filter={filterState}
              onChange={handleChangeFilter}
              isVisible={isVisible}
              {...(filterExtraProps || {})}
            />
          );
        } else {
          return (
            <ValuesIContainsFilter
              ref={filterRef}
              dataProvider={dataProvider as DataProvider<RD>}
              dataProviderFilters={dataProviderFilters as FiltersConfigOrderedMap}
              getter={getter}
              idGetter={idGetter}
              comparators={comparators}
              filter={filterState}
              onChange={handleChangeFilter}
              isVisible={isVisible}
              {...(filterExtraProps || {})}
            />
          );
        }
      } else if (restful) {
        // fallback to default filter types
        return (
          <ValuesCheckFilterRestful
            ref={filterRef}
            uniqueKey={filterKey || uniqueKey}
            dataProvider={dataProvider as FiltersDataProviderRestful}
            dataProviderFilters={dataProviderFilters as FiltersQueryOrderedMap}
            getter={getter}
            idGetter={idGetter}
            comparators={comparators}
            filter={filterState}
            onChange={handleChangeFilter}
            isVisible={isVisible}
            {...(filterExtraProps || {})}
          />
        );
      } else {
        return (
          <ValuesCheckFilter
            ref={filterRef}
            dataProvider={dataProvider as DataProvider<RD>}
            dataProviderFilters={dataProviderFilters as FiltersConfigOrderedMap}
            getter={getter}
            idGetter={idGetter}
            comparators={comparators}
            filter={filterState}
            onChange={handleChangeFilter}
            isVisible={isVisible}
            {...(filterExtraProps || {})}
          />
        );
      }
    };

    const stopClickPropagation = React.useCallback((e: React.SyntheticEvent) => {
      // Important: stop event propagation cause this could lead to filter popup re-opening
      e?.stopPropagation?.();
    }, []);

    return (
      <Stack
        fill
        onClick={stopClickPropagation}
        css={{
          alignItems: "stretch",
          width: contentSizeMap.get(popupSize, "300px"),
          border: "1px solid $primaryLighter",
          borderRadius: "$rounded",
          boxShadow: "$3xl",
          background: "$white",
          padding: "$4",
        }}
      >
        {sortable && <OrderFilter filter={filterState} onChange={handleChangeFilter} />}
        {filterable && sortable && <Divider />}
        {filterable && renderFilterComponent()}
        {(filterable || sortable) && (
          <ButtonGroup fill css={{ "& > *": { flex: "1 1 auto" } }}>
            <Button color="brand" size="small" onClick={handleApplyClick}>
              Apply
            </Button>
            <Button color="brand" size="small" onClick={handleClearClick}>
              Clear
            </Button>
            <Button color="brand" size="small" onClick={handleCancelClick}>
              Cancel
            </Button>
          </ButtonGroup>
        )}
      </Stack>
    );
  }
) as {
  <RD = RowData>(
    props: FiltersPopupProps<RD> & { ref?: React.ForwardedRef<Initializable> }
  ): React.ReactElement;
  displayName: string;
  defaultProps: Partial<FiltersPopupProps>;
};

FiltersPopup.displayName = "FiltersPopup";
FiltersPopup.defaultProps = {
  dataProviderFilters: emptyOrderedMap as
    | FiltersConfigOrderedMap
    | FiltersQueryOrderedMap,
  filterable: false,
  sortable: false,
  restful: false,
  isVisible: false,
  popupSize: FilterPopupSizes.REGULAR,
};

export default FiltersPopup;
