import React, { useCallback } from "react";

import { styled } from "../../../stitches.config";

import Highchart from "../../../components/charts/Highchart";
import Stack from "../../../components/lib/Stack";
import Box from "../../../components/lib/Box";
import Grid from "../../../components/lib/Grid";
import Center from "../../../components/lib/Center";

import { percentFormatter, decimalFormatter, emptyMap } from "../constants";
import { DEVICE_TYPES, DEVICE_TYPES_TYPE } from "../types";

import type { ImmutableMap } from "../../../types/immutable";

export type ChartSizesObject = {
  [key in DEVICE_TYPES_TYPE]: {
    width: number | null;
    height: number;
  };
};

export type SavingsDataObject = {
  potential_savings_perc: number;
  achieved_savings_perc: number;
  potential_savings: number;
  achieved_savings: number;
};
export type SavingsDataMap = ImmutableMap<SavingsDataObject>;

const SavingsValue = styled(Center, {
  fontSize: "$xl",
  color: "$primaryDark",

  "&:first-of-type": {
    fontSize: "$lg",
  },
});

const chartSizes: ChartSizesObject = {
  desktop: {
    width: null,
    height: 300,
  },
  tablet: {
    width: null,
    height: 300,
  },
  mobile: {
    width: null,
    height: 250,
  },
};

const getChartData = (savingsData: SavingsDataMap) => {
  return [
    {
      id: "potential_savings",
      name: "Potential Savings",
      y: savingsData.get("potential_savings_perc"),
      color: "#d96868",
    },
    {
      id: "achieved_savings",
      name: "Achieved Savings",
      y: savingsData.get("achieved_savings_perc"),
      color: "#4cc885",
    },
  ];
};

const getChartOptions = (
  deviceType: DEVICE_TYPES_TYPE,
  savingsData: SavingsDataMap
): Highcharts.Options => {
  const sizes = chartSizes[deviceType] || chartSizes["desktop"];

  return {
    chart: {
      type: "pie",
      backgroundColor: "transparent",
      ...sizes,
    },
    title: {
      text: "Savings Per Year",
      style: {
        color: "#757575",
      },
    },
    plotOptions: {
      pie: {
        animation: {
          duration: 200,
          easing: "ease",
        },
        dataLabels: {
          enabled: false,
        },
        point: {
          events: {
            legendItemClick: () => false,
          },
        },
        showInLegend: true,
        shadow: false,
        center: ["50%", "50%"],
        size: "100%",
        innerSize: "70%",
      },
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      enabled: true,
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
      itemStyle: {
        cursor: "default",
        fontWeight: "initial",
        fontSize: "12px",
        color: "#757575",
      },
      itemHoverStyle: {
        fontWeight: "bold",
      },
    },
    credits: {
      enabled: false,
    },
    series: [{ data: getChartData(savingsData) } as any],
  };
};

const loadingMessage = <Center css={{ height: "300px" }}>loading...</Center>;

interface SavingsDonutProps {
  deviceType: DEVICE_TYPES_TYPE;
  displayCurrencySymbol: string;
  savingsData: SavingsDataMap;
  savingsDataLoaded: boolean;
}

const SavingsDonut = (props: SavingsDonutProps) => {
  const { savingsData, displayCurrencySymbol, deviceType, savingsDataLoaded } = props;

  const renderContent = useCallback(() => {
    const potentialSavingsPerc = savingsData.get("potential_savings_perc");
    const achievedSavingsPerc = savingsData.get("achieved_savings_perc");
    const potentialSavings = savingsData.get("potential_savings");
    const achievedSavings = savingsData.get("achieved_savings");

    return (
      <Grid
        css={{
          gridTemplateColumns: "100%",
          "@sm": {
            gridTemplateColumns: "50% auto",
          },
          gap: "$8",
        }}
      >
        <Center css={{ border: "1px solid $primaryLight" }}>
          <Highchart
            options={getChartOptions(deviceType, savingsData)}
            style={{ width: "auto" }}
          />
        </Center>
        <Stack
          css={{ alignItems: "stretch", justifyContent: "space-between", gap: "$6" }}
        >
          {(potentialSavingsPerc != null || potentialSavings != null) && (
            <Box
              css={{
                padding: "$6",
                border: "2px solid $dangerLight",
              }}
            >
              <SavingsValue>Potential Savings</SavingsValue>
              <SavingsValue>{percentFormatter(potentialSavingsPerc)}</SavingsValue>
              <SavingsValue>
                {decimalFormatter(potentialSavings, false, displayCurrencySymbol)}
              </SavingsValue>
            </Box>
          )}
          {(achievedSavingsPerc != null || achievedSavings != null) && (
            <Box
              css={{
                padding: "$6",
                border: "2px solid $successLight",
              }}
            >
              <SavingsValue>Achieved Savings</SavingsValue>
              <SavingsValue>{percentFormatter(achievedSavingsPerc)}</SavingsValue>
              <SavingsValue>
                {decimalFormatter(achievedSavings, false, displayCurrencySymbol)}
              </SavingsValue>
            </Box>
          )}
        </Stack>
      </Grid>
    );
  }, [savingsData, displayCurrencySymbol, deviceType]);

  return savingsDataLoaded ? renderContent() : loadingMessage;
};

SavingsDonut.displayName = "SavingsDonut";
SavingsDonut.defaultProps = {
  deviceType: DEVICE_TYPES.DESKTOP,
  savingsData: emptyMap,
  savingsDataLoaded: false,
};

export default SavingsDonut;
