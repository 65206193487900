import React from "react";
import R from "ramda";
import $ from "jquery";
import uuid from "uuid";
import LaddaPromiseButton from "./LaddaPromiseButton";
import { proxyBase } from "../../constants/api";
import { getCookie } from "../../utils/cookie";

class DownloadPOSTButton extends React.Component {
  _handleSubmit = (e) => {
    // Make UUID for cookie and iframe
    const cookieId = uuid.v4();
    const { service, endpoint, getFormData, onClick } = this.props;
    const $body = $("body");

    if (onClick) {
      onClick(e);
    }

    // Insert target Iframe
    let $iframe = $(
      `<iframe name="${cookieId}" src="about:blank" accept-charset="UTF-8"></iframe>`
    );
    let $form = $(
      `<form method="post"  target="${cookieId}" style="display: none"></form>`
    );

    $form.attr("action", `${proxyBase}/${service}/${endpoint}`);

    this.$form = $form;
    this.$iframe = $iframe;
    $body.append($iframe);

    // Insert Form
    // Insert hidden data,cookie inputs
    let $data = $('<input type="hidden" name="data" value="" />');
    let $cookie = $(`<input type="hidden" name="cookie" value="${cookieId}" />`);

    let promise = getFormData();
    if (!(promise instanceof Promise)) {
      promise = Promise.resolve(promise);
    }

    return promise.then((formData) => {
      if (R.isNil(formData)) {
        return Promise.resolve();
      }

      $data.val(JSON.stringify(formData));
      $form.append($data);
      $form.append($cookie);
      $form.append('<input name="iehack" type="hidden" value="&#9760;" />');
      $body.append($form);

      $form.submit();

      return new Promise((resolve, reject) => {
        this._startCheckingCookies(cookieId, resolve);
        $iframe.load(() => {
          // Load event fires on error
          reject();
          this._submitError();
          this._stopCheckingCookies();
        });
      });
    });
  };

  _startCheckingCookies(cookieId, resolve) {
    this.cookieInterval = setInterval(
      this._checkCookies.bind(this, cookieId, resolve),
      100
    );
  }

  _checkCookies(cookieId, resolve) {
    if (getCookie(cookieId) === "true") {
      resolve();
      document.cookie = `${cookieId}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
      this._stopCheckingCookies();
      this._submitSuccess();
    }
  }

  _stopCheckingCookies() {
    clearInterval(this.cookieInterval);
    if (this.$form) {
      this._cleanUpDOM();
    }
  }

  _cleanUpDOM() {
    this.$form.remove();
    this.$iframe.remove();
  }

  _submitSuccess() {
    const { onSuccess } = this.props;

    if (onSuccess) {
      onSuccess();
    }
  }

  _submitError(err) {
    const { onError } = this.props;

    if (onError) {
      onError(err);
    }
  }

  componentWillUnmount() {
    this._stopCheckingCookies();
  }

  render() {
    const { children, service, endpoint, getFormData, onClick, ...props } = this.props;

    return (
      <LaddaPromiseButton {...props} onClick={this._handleSubmit}>
        {children}
      </LaddaPromiseButton>
    );
  }
}

export default DownloadPOSTButton;
