import PropTypes from "prop-types";
import React from "react";
import { observer } from "mobx-react";
import DetailsTableRow from "./DetailsTableRow";

const ResultLevelCardPayRateDetailsTable = observer((props) => {
  const store = props.store;
  const search = props.search;
  const resultLevel = props.resultLevel;
  const currency = props.currency;
  const deltaObject = resultLevel.change;
  const hideChangeIcons = props.hideChangeIcons;
  const rateMultiplier = props.rateMultiplier || 1;

  let disclaimerTooltip =
    resultLevel.levelRomanNumeral === "I" ? search.minWageDisclaimerTooltip : null;

  return (
    <table className="table level-data sub-table no-margin">
      <tbody>
        <DetailsTableRow
          store={store}
          hidden={store.hideMax}
          text="Max:"
          prefix={currency}
          value={resultLevel.payRateMax * rateMultiplier}
          change={deltaObject.payRateMaxChange * rateMultiplier}
          changeIcon={deltaObject.payRateMaxChangeIcon}
          changeType={deltaObject.payRateMaxChangeType}
          hideIcon={hideChangeIcons}
        />

        <DetailsTableRow
          store={store}
          hidden={store.hideMedian}
          text="Median:"
          prefix={currency}
          value={resultLevel.payRateMid * rateMultiplier}
          change={deltaObject.payRateMidChange * rateMultiplier}
          changeIcon={deltaObject.payRateMidChangeIcon}
          changeType={deltaObject.payRateMidChangeType}
          hideIcon={hideChangeIcons}
        />

        <DetailsTableRow
          store={store}
          hidden={store.hideAverage}
          text="Average:"
          prefix={currency}
          value={resultLevel.payRateAvg * rateMultiplier}
          change={deltaObject.payRateAvgChange * rateMultiplier}
          changeIcon={deltaObject.payRateAvgChangeIcon}
          changeType={deltaObject.payRateAvgChangeType}
          hideIcon={hideChangeIcons}
        />

        {/* Not sure how to fix this, but you're not supposed to pass anything but a string here */}
        <DetailsTableRow
          store={store}
          hidden={store.hideMin}
          text={<span>Min: {disclaimerTooltip}</span>}
          prefix={currency}
          value={resultLevel.payRateMin * rateMultiplier}
          change={deltaObject.payRateMinChange * rateMultiplier}
          changeIcon={deltaObject.payRateMinChangeIcon}
          changeType={deltaObject.payRateMinChangeType}
          hideIcon={hideChangeIcons}
        />
      </tbody>
    </table>
  );
});

ResultLevelCardPayRateDetailsTable.propTypes = {
  store: PropTypes.object.isRequired,
  search: PropTypes.object.isRequired,
  resultLevel: PropTypes.object.isRequired,
  currency: PropTypes.string,
  hideChangeIcons: PropTypes.bool,
};

export default ResultLevelCardPayRateDetailsTable;
