import { styled } from "../../stitches.config";
import Box from "./Box";

const Grid = styled(Box, {
  display: "grid",
  gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
  gap: "$4",
});

Grid.displayName = "Grid";

export default Grid;
