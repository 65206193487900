import React from "react";
import { emptyOrderedMap } from "../../../constants";

import { globalContextDefaults, GlobalContextObject } from "../../../globalContext";
import { omitObjectKeys } from "../../../utils/object";

import type { AllCurrenciesMap } from "../../private_index/types";

export type Val5KAdminContextObject = Omit<
  GlobalContextObject,
  "fetchAPINew" | "fetchGraphQLNew"
> & {
  surveysValidationUIUrl: string;
  currenciesData: AllCurrenciesMap;
};

const val5KAdminContextDefaults = {
  ...omitObjectKeys(globalContextDefaults, ["fetchAPINew", "fetchGraphQLNew"]),
  surveysValidationUIUrl: undefined,
  currenciesData: emptyOrderedMap,
} as unknown as Val5KAdminContextObject;

export const Val5KAdminContext = React.createContext<Val5KAdminContextObject>(
  val5KAdminContextDefaults
);

export function useVal5KAdminContext() {
  return React.useContext(Val5KAdminContext);
}

export default Val5KAdminContext;
