import React from "react";
import Box from "../../../components/lib/Box";
import { FormControl, Label, LabelWrapper } from "../../../components/lib/Label";
import { GroupRadioButtons } from "../../../components/lib/GroupRadioButtons";
import RateTypeTooltip from "../../../components/RateTypeTooltip";
import { useRateSearchFormStore } from "../../../stores/RateSearchForm";
import { JobRateType } from "../types";

type RateTypeRadio = {
  label: JobRateType;
  value: JobRateType;
  id: string;
};

const radioButtons: RateTypeRadio[] = [
  { label: "Contract", value: "Contract", id: "rate-type-contract" },
  { label: "FTE", value: "FTE", id: "rate-type-fte" },
];

export function RateTypeGroup() {
  const rateType = useRateSearchFormStore((s) => s.rateType);
  const disabled = useRateSearchFormStore(
    (s) => Boolean(s.jobTitle?.isHealthcare) && s.roleType === "Traveler"
  );
  const setRateType = useRateSearchFormStore((s) => s.setRateType);
  return (
    <Box>
      <FormControl disabled={disabled}>
        <LabelWrapper css={{ justifyContent: "flex-start", gap: "$2" }}>
          <Label>Rate Type</Label>
          <RateTypeTooltip />
        </LabelWrapper>
        <GroupRadioButtons
          disabled={disabled}
          wrapperProps={{ css: { width: "100%" } }}
          buttons={radioButtons}
          color="brand"
          name="rate-type"
          value={rateType}
          onChange={(e) => setRateType(e.target.value as JobRateType)}
        />
      </FormControl>
    </Box>
  );
}
