// @flow
import * as React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import PceRateCardDetail from "./PceRateCardDetail";
import LoadingIndicator from "../../shared/LoadingIndicator";
import CreatedOnFilter from "../../filters/CreatedOnFilter";
import CreatedByFilter from "../../filters/CreatedByFilter";
import SortIndicator from "../../ratecards/components/SortIndicator";
import { FILTER_COLUMN } from "../../../models/Filter";
import SearchBox from "../../../components/lib/SearchBox";
import ToggleButton from "../../ratecards/components/ToggleButton";
import { SingleDatePicker } from "../../../components/SingleDatePicker";
import {
  CardHeader,
  CardHeaderTitle,
  CardActions,
  CardActionsLeft,
  CardFilters,
  CardListItemLabel,
  CardListSubText,
  SubTextAccent,
  CardListItem,
} from "../../../components/lib/Card";
import Box from "../../../components/lib/Box";
import Divider from "../../../components/lib/Divider";
import Inline from "../../../components/lib/Inline";
import Button from "../../../components/lib/Button";
import Pagination from "../../../components/lib/Pagination";

class _RateCardListings extends React.Component<{}, {}> {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.changeCurrent = this.changeCurrent.bind(this);
  }

  // Rate Cards pagination
  changeCurrent(value) {
    let store: RateCardListComponentStore = this.props.store;
    store.pagination.handleCurrentPage(value);
  }

  componentDidMount() {
    this.props.store.updateRequestFrom(this.props.requestFrom);
    // this.props.store.pagination.goFetch();
    this.props.store.clearFilters();
  }

  render() {
    const rcstore = this.props.store;
    const pcestore = this.props.pcestore;

    const network = rcstore.network;
    const selected = this.props.selected || false;

    const rateCards = rcstore.rateCardsView;

    let tableContent = null;
    if (network.loading) {
      tableContent = <LoadingIndicator />;
    } else if (network.error) {
      tableContent = (
        <div className="pt-error-message">
          <h2>Error</h2>
          <pre>
            <code>{JSON.stringify(network.error, null, 2)}</code>
          </pre>
        </div>
      );
    } else if (rateCards.length === 0) {
      tableContent = (
        <div className="ratecards-table-empty">
          {Object.keys(rcstore.appliedFilters).length > 0 ? (
            <div>No Available Rate Cards</div>
          ) : (
            <div>
              Project estimates can be created from the punchouts / buy rates from your
              ratecards.
            </div>
          )}
        </div>
      );
    } else {
      tableContent = rateCards.map((ratecard) => (
        <RateCardListsElements
          key={ratecard.ratecardId}
          selected={selected}
          expandable={pcestore.allowExpand}
          expanded={ratecard.viewState.expanded}
          onItemClick={(e) => pcestore.PCEtoggleExpand(ratecard, rateCards)}
          rateCards={rateCards}
          card={ratecard}
          pcestore={pcestore}
          createstore={this.props.createstore}
        />
      ));
    }

    const maximumDate = new Date();

    var fromDate = (
      <SingleDatePicker
        id="from_date"
        date={rcstore.createdOnFilter.fromDate}
        numberOfMonths={1}
        isOutsideRange={(day) => day.isAfter(maximumDate)}
        focused={rcstore.createdOnFilter.fromFocused}
        onDateChange={rcstore.createdOnFilter.fromDateChange}
        onFocusChange={rcstore.createdOnFilter.fromFocusedChange}
      />
    );

    var toDate = (
      <SingleDatePicker
        id="to_date"
        date={rcstore.createdOnFilter.toDate}
        numberOfMonths={1}
        isOutsideRange={(day) => rcstore.toDateRange(day)}
        focused={rcstore.createdOnFilter.toFocused}
        onDateChange={rcstore.createdOnFilter.toDateChange}
        onFocusChange={rcstore.createdOnFilter.toFocusedChange}
      />
    );

    return (
      <>
        {/*<div className="header-action-bar">
            <h2 className="ellipsis">Available Rate Cards</h2>
          </div>*/}
        {/*<div className="header-action-bar">
              <h2>Available Rate Cards</h2>
              <div className="clearfix"/>
          </div>*/}

        {this.props.showHeader && (
          <CardHeader>
            <CardHeaderTitle />
            Available Rate Cards
          </CardHeader>
        )}
        <div className="header-action-bar marginTopzero">
          <CardActions>
            <CardActionsLeft>
              <SearchBox
                value={rcstore.rateCardLabelFilter.textToLookFor}
                onChange={rcstore.rateCardLabelFilter.onTextToLookForChange}
                onSubmit={rcstore.rateCardLabelFilter.onApply}
                style={{
                  width: "260px",
                }}
              />
            </CardActionsLeft>
          </CardActions>
        </div>
        <Divider css={{ borderColor: "$primaryLighter" }} />
        <Box>
          {rcstore.createdOnFilter && (
            <CreatedOnFilter
              createdOnFilter={rcstore.createdOnFilter}
              fromDate={fromDate}
              toDate={toDate}
            />
          )}
          {rcstore.createdByFilter && (
            <CreatedByFilter
              createdByFilter={rcstore.createdByFilter}
              network={rcstore.createdByFilter.network.loading}
              instantSearchValue={rcstore.createdByFilter.instantSearchValue}
              onInstantSearch={rcstore.createdByFilter.onInstantSearch}
            />
          )}
          <CardFilters filtered={rcstore.isFiltered}>
            <Inline>
              {rcstore.isFiltered && (
                <Button color="accent" onClick={rcstore.clearFilters}>
                  Clear All Filters & Sorts
                </Button>
              )}
              {/*rcstore.createdByFilter && <ToggleButton borderless={true} name="rate-cards-filter" value={FILTER_COLUMN.CREATED_BY} selected={Boolean(rcstore.appliedFilters[FILTER_COLUMN.CREATED_BY])} onClick={rcstore.createdByFilter.onShowModal}>
                    <SortIndicator sortColumn={FILTER_COLUMN.CREATED_BY} sortsOrder={rcstore.appliedSortsOrder} sort={rcstore.appliedSorts[FILTER_COLUMN.CREATED_BY]}/>
                    Created By
                    <span> ▼</span>
                  </ToggleButton>*/}
              {rcstore.createdOnFilter && (
                <ToggleButton
                  borderless={true}
                  name="rate-cards-filter"
                  value={FILTER_COLUMN.DATE_RANGE}
                  selected={Boolean(rcstore.appliedFilters[FILTER_COLUMN.DATE_RANGE])}
                  onClick={rcstore.createdOnFilter.onShowModal}
                >
                  <SortIndicator
                    sortType="numeric"
                    sortColumn={FILTER_COLUMN.DATE_RANGE}
                    sortsOrder={rcstore.appliedSortsOrder}
                    sort={rcstore.appliedSorts[FILTER_COLUMN.DATE_RANGE]}
                  />
                  Created On
                  <span> ▼</span>
                </ToggleButton>
              )}
            </Inline>
          </CardFilters>
        </Box>

        {tableContent}
        {rcstore.pagination.pageCount > 0 ? (
          <Pagination
            options={{
              variant: "full",
              currentPage: rcstore.pagination.currentPage,
              numPages: rcstore.pagination.pageCount,
            }}
            onPageClick={(pageEvent) => {
              if (pageEvent.type === "first") {
                this.changeCurrent(1);
              }
              if (pageEvent.type === "last") {
                this.changeCurrent(rcstore.pagination.pageCount);
              }
              if (pageEvent.type === "next") {
                this.changeCurrent(
                  Math.min(
                    rcstore.pagination.currentPage + 1,
                    rcstore.pagination.pageCount
                  )
                );
              }
              if (pageEvent.type === "prev") {
                this.changeCurrent(Math.max(rcstore.pagination.currentPage - 1, 1));
              }
              if (pageEvent.type === "page") {
                this.changeCurrent(pageEvent.page);
              }
            }}
          />
        ) : (
          ""
        )}
      </>
    );
  }
}
const RateCardListings = observer(_RateCardListings);

const RateCardListsElements = (props) => {
  const selected = props.selected || false;
  const store = props.pcestore;
  const card = props.card;
  const expanded = props.expanded; // Used if the view(Ratecard Row) is expanded or not
  let onItemClick = props.onItemClick;
  const expandable = props.expandable; // Used if the view(Ratecard Row) is expandable or not
  const name = card.name;
  const createdDisplay = card.createdDate.format("MMMM D, YYYY");
  const ownerName = card.owner.fullname;
  const id = card.ratecardId;

  return (
    <>
      <CardListItem selected={selected} onClick={onItemClick}>
        <div className="search-list-item-left ellipsis">
          {expandable && (
            <div className="search-expand-state">
              <Box
                as="span"
                css={{
                  fontFamily: "IcoMoon Ultimate",
                  fontSize: "$2xl",
                  transition: "transform 150ms ease-in, color 200ms",
                  color: "$brand",
                  transform: expanded ? "rotate(90deg)" : "none",
                }}
              >
                
              </Box>
            </div>
          )}
          <div>
            <CardListItemLabel>{name}</CardListItemLabel>
            <CardListSubText>
              {" "}
              Created by <SubTextAccent>{ownerName} </SubTextAccent> on{" "}
              <SubTextAccent>{createdDisplay}</SubTextAccent>
            </CardListSubText>
          </div>
        </div>
      </CardListItem>
      {expandable && (
        <PceRateCardDetail
          expanded={expanded}
          store={store}
          createstore={props.createstore}
          ratecardId={id}
        />
      )}
    </>
  );
};

RateCardListings.propTypes = {
  onClick: PropTypes.func,
};

export default RateCardListings;
