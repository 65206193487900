// @flow

export class DjangoApiPaginatedQueryArgs {
  page: number;
  page_size: number;
  ordering: string;
  search: string;

  constructor(object: Object) {
    const { page, page_size, ordering, search, ...rest } = object;

    if (page) this.page = page;
    if (page_size) this.page_size = page_size;
    if (ordering) this.ordering = ordering;
    if (search) this.search = search;
    if (Object.keys(rest).length) Object.assign(this, rest);
  }
}

export class DjangoApiPaginatedResponse {
  count: number;
  next: string;
  previous: string;
  results: Array<any>;

  constructor(object: Object = {}) {
    this.next = object.next;
    this.previous = object.previous;
    this.count = object.count || 0;
    this.results = object.results || [];
  }
}
