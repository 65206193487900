// TODO need to check all functions in this file as it might be more reasonable to put them into local /utils dir

import moment from "moment";
// @ts-expect-error
import numeral from "numeral";
import { fromJS, Map, Set } from "immutable";

import { isNumber } from "../../components/tables/utils";
import { emptyList } from "../../constants";
import {
  RATE_TYPES,
  VALUE_TYPES,
  VALUE_TYPES_LABELS,
  ALL_VALUE_TYPES,
  ALL_VALUE_SUBTYPES,
  VALUE_SUBTYPES_OPTIONS,
  SKILLS_LEVELS_RATIO_LABELS,
} from "./types";

import type { CurrencyData } from "../../components/selects/CurrencySelect";
import type {
  AttemptDataObject,
  VALUE_SUBTYPES_TYPE,
  VALUE_TYPES_TYPE,
  RATE_TYPES_TYPE,
  JobDataMap,
  LevelsLimitsMap,
  CollectionLimitsMap,
  LevelLimitsValuesMap,
  LevelsLimitsValuesList,
  SKILLS_LEVELS_TYPE,
} from "./types";
import type { MarketSearchRatesDataObject } from "../private_index/types";
import type { StylesConfig } from "react-select/lib/styles";

export {
  djangoOrderingKey,
  djangoPaginationKey,
  djangoPaginationSizeKey,
  djangoSearchKey,
  emptyList,
  emptyMap,
  emptySet,
  emptyOrderedMap,
} from "../../constants";

type MarketSearchRatesKeys = keyof MarketSearchRatesDataObject;
type MarketSearchRatesKeysList = MarketSearchRatesKeys[];

const contingentColumnsSpecs = [
  {
    type: VALUE_TYPES.PAY_RATE,
    title: VALUE_TYPES_LABELS[VALUE_TYPES.PAY_RATE],
  },
  {
    type: VALUE_TYPES.MARKUP,
    title: VALUE_TYPES_LABELS[VALUE_TYPES.MARKUP],
  },
  {
    type: VALUE_TYPES.BILL_RATE,
    title: VALUE_TYPES_LABELS[VALUE_TYPES.BILL_RATE],
  },
];
const annualColumnsSpecs = [
  {
    type: VALUE_TYPES.PAY_RATE,
    title: VALUE_TYPES_LABELS[VALUE_TYPES.PAY_RATE],
  },
];
export const COLUMN_TYPES_DESC = {
  [RATE_TYPES.HOURLY]: contingentColumnsSpecs,
  [RATE_TYPES.ANNUAL]: annualColumnsSpecs,
  [RATE_TYPES.DAILY]: contingentColumnsSpecs,
  [RATE_TYPES.WEEKLY]: contingentColumnsSpecs,
  [RATE_TYPES.MONTHLY]: contingentColumnsSpecs,
};

export function getFilteredValueTypesDesc(
  rateType: RATE_TYPES_TYPE,
  requiredValueTypes: VALUE_TYPES_TYPE[] = []
) {
  if (rateType === RATE_TYPES.ANNUAL) {
    return COLUMN_TYPES_DESC[rateType];
  }

  // show pay rates only by default
  if (!requiredValueTypes.length) {
    requiredValueTypes = [VALUE_TYPES.PAY_RATE];
  }

  return COLUMN_TYPES_DESC[rateType].filter((i) => requiredValueTypes.includes(i.type));
}

export function getFilteredValueSubtypesDesc(
  requiredValuesSubtypes: VALUE_SUBTYPES_TYPE[] = []
): typeof VALUE_SUBTYPES_OPTIONS {
  // show all values types by default
  if (!requiredValuesSubtypes.length) {
    requiredValuesSubtypes = ALL_VALUE_SUBTYPES;
  }

  return VALUE_SUBTYPES_OPTIONS.filter((i) => requiredValuesSubtypes.includes(i.type));
}

export function getFilteredRatesKeys(
  rateType: RATE_TYPES_TYPE,
  requiredValueTypes: VALUE_TYPES_TYPE[] = ALL_VALUE_TYPES,
  requiredValuesSubtypes: VALUE_SUBTYPES_TYPE[] = ALL_VALUE_SUBTYPES
): MarketSearchRatesKeysList {
  const filteredValueTypesDesc = getFilteredValueTypesDesc(rateType, requiredValueTypes);
  const filteredValueSubtypesDesc = getFilteredValueSubtypesDesc(requiredValuesSubtypes);
  const keys: MarketSearchRatesKeysList = [];

  filteredValueTypesDesc.forEach(({ type }) => {
    filteredValueSubtypesDesc.forEach(({ type: subtype }) => {
      const key = [type, subtype].join("_") as MarketSearchRatesKeys;
      keys.push(key);
    });
  });

  return keys;
}

// formatting constants

export const DECIMAL_FORMAT = "0,0.00";
export const DECIMAL_FORMAT_WITH_SIGN = "+0,0.00";
export const DECIMAL_FORMAT_EDIT = "0.[00]";
export const DATE_FORMAT = "MM-DD-YYYY";
export const DATE_TIME_FORMAT = "MM-DD-YYYY h:mm:ss a";
export const DATE_TIME_FORMAT_EXT = "dddd, MMMM Do YYYY, h:mm:ss a";
export const PT_LOCAL_TIMEZONE = "EST";

export const dateFormatter = (value: Date, format: string = DATE_FORMAT): string => {
  return value != null ? moment.utc(value).format(format) : "";
};
export const dateTimeFormatter = (
  value: Date,
  format: string = DATE_TIME_FORMAT
): string => {
  return value != null ? moment.utc(value).format(format) : "";
};
export const dateTimeFormatterExt = (
  value: Date,
  format: string = DATE_TIME_FORMAT_EXT
): string => {
  return dateTimeFormatter(value, format);
};

export const decimalFormatter = <T extends number | string | null>(
  value: T,
  verbose: boolean = false,
  units: string = ""
): string => {
  const formatter = verbose ? DECIMAL_FORMAT_WITH_SIGN : DECIMAL_FORMAT;

  return value != null
    ? (units ? ` ${units} ` : "") + numeral(value).format(formatter)
    : "";
};
export const percentFormatter = <T extends number | string | null>(
  value: T,
  verbose: boolean = false,
  units: string = "%"
): string => {
  const formatter = verbose ? DECIMAL_FORMAT_WITH_SIGN : DECIMAL_FORMAT;

  return value != null
    ? numeral(value).format(formatter) + (units ? ` ${units} ` : "")
    : "";
};

// rates limits calculations functions

export function calculateLevelsLimits(data: CollectionLimitsMap): CollectionLimitsMap {
  if (data.size) {
    data = data.set("levelsLimits", Map() as LevelsLimitsMap);
    data = data.setIn(
      ["levelsLimits", "hourly"],
      calculateLevelsLimitsForRateType(data, "hourly")
    ) as CollectionLimitsMap;
    return data.setIn(
      ["levelsLimits", "salary"],
      calculateLevelsLimitsForRateType(data, "salary")
    ) as CollectionLimitsMap;
  }
  return data;
}

function calculateLevelsLimitsForRateType(
  data: CollectionLimitsMap,
  rateType: "hourly" | "salary"
): LevelsLimitsValuesList {
  let limits: LevelsLimitsValuesList = emptyList;
  let highRate = data.get(`${rateType}_high`);
  let lowRate = data.get(`${rateType}_low`);

  if (!isNumber(highRate) || !isNumber(lowRate)) {
    return emptyList;
  }

  // do parsing after check - parse function is smart to handle bad numbers
  highRate = parseFloat(highRate as unknown as string);
  lowRate = parseFloat(lowRate as unknown as string);

  const delta = Math.abs(highRate - lowRate);
  let prev_low = lowRate;

  Object.keys(SKILLS_LEVELS_RATIO_LABELS).forEach((key, idx) => {
    const ratio = SKILLS_LEVELS_RATIO_LABELS[key as unknown as SKILLS_LEVELS_TYPE];
    const low = prev_low;
    const high = low + delta * ratio;

    const limit: LevelLimitsValuesMap = fromJS({
      level: key,
      low,
      high,
    });

    limits = limits.set(idx, limit) as LevelsLimitsValuesList;

    prev_low = high;
  });

  return limits;
}

export function dedupeLocationValues<T extends string | null>(str: T): T {
  if (!str) return str;

  return Set<string>(
    str
      .split(",")
      .map((i) => i.trim())
      .filter((i) => i && i)
  )
    .toJS()
    .join(", ") as T;
}

export function getLocationString(jobData: JobDataMap) {
  if (!jobData || !jobData.size) return "";
  if (jobData.get("region")) return "";

  const city = jobData.get("city");
  const state = jobData.get("state");
  const country = jobData.get("country");

  return [
    dedupeLocationValues(city),
    dedupeLocationValues(state),
    dedupeLocationValues(country),
  ]
    .filter((i) => i && i)
    .join(", ");
}

export function getRegionString(jobData: JobDataMap) {
  if (!jobData || !jobData.size) return "";

  const region = jobData.get("region");
  const country = jobData.get("country");

  if (!region) return "";

  return [dedupeLocationValues(region), dedupeLocationValues(country)]
    .filter((i) => i && i)
    .join(", ");
}

export function getValidatorString(attempt: AttemptDataObject): string {
  if (!attempt || !Object.keys(attempt).length) return "";

  const fullName = [attempt["first_name"], attempt["last_name"]]
    .filter((i) => i && i)
    .join(" ");
  const email = attempt["email"];

  if (!fullName) return "";
  if (!email) return fullName;

  return `${fullName} (${email})`;
}

export function dedupeCSVString<T extends string | null>(value: T): T {
  if (!value) return value;

  const parts = value
    .split(",")
    .filter((i) => i && i)
    .map((i) => i.trim());
  const partsSet = Set(parts);

  return partsSet.join(", ") as T;
}

export function convertToCurrency<T extends number | null | undefined>(
  value: T,
  fromCurrencyObj: CurrencyData,
  toCurrencyObj: CurrencyData,
  precision = 4
): T {
  if (value == null) return value;
  if (!isNumber(value)) return value;

  const fromCurrencyCode = fromCurrencyObj["code"];
  const toCurrencyCode = toCurrencyObj["code"];

  if (fromCurrencyCode === toCurrencyCode) return value;
  if (!value) return value; // zero

  const fromCurrencyFactor = fromCurrencyObj["factor"];
  const toCurrencyFactor = toCurrencyObj["factor"];

  if (!fromCurrencyFactor || !toCurrencyFactor) return 0 as T;

  const result = (value / fromCurrencyFactor) * toCurrencyFactor;

  if (precision != null) {
    const helperValue = 10 ** precision;
    return (Math.round(result * helperValue) / helperValue) as T;
  }

  return result as T;
}

export function compareDecimals(value1: number, value2: number, precision = 2) {
  if (precision != null) {
    const helperValue = 10 ** precision;
    value1 = Math.round(value1 * helperValue) / helperValue;
    value2 = Math.round(value2 * helperValue) / helperValue;
  }

  return value1 === value2 ? 0 : value1 > value2 ? 1 : -1;
}

export const reactSelectStyles: StylesConfig = {
  control: (provided, state) => ({
    ...provided,
    fontSize: 14,
  }),
  menuList: (provided, state) => ({
    ...provided,
    fontSize: 14,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: 14,
    color: "#ccc",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: 14,
  }),
};
