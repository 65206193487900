import React from "react";

import SurveyItemBase from "./SurveyItemBase";
import RatesBlock from "./RatesBlock";
import StatusMessagerBlock from "./StatusMessagerBlock";
import { REVIEW_STATUS_TYPES } from "../../types";
import RatesTableOrderedWithFeedback from "../../components/RatesTableOrdered/RatesTableOrderedWithFeedback";

import { useCreateOrUpdateQuestionsFeedbackAPI } from "../../hooks";
import { useAttemptDataContext } from "../../context/AttemptDataContext";
import OrderedRatesFeedbackContext, {
  useCreateOrderedRatesFeedbackContext,
} from "../../context/feedback/OrderedRatesFeedbackContext";
import QuestionsFeedbackContext, {
  useCreateQuestionsFeedbackContext,
} from "../../context/feedback/QuestionsFeedbackContext";
import ValidationContext, {
  useCreateValidationContext,
} from "../../context/ValidationContext";

export function SurveyItemPageOrdered() {
  const { attemptId, updateAttemptData } = useAttemptDataContext();

  // state

  const ratesFeedbackContext = useCreateOrderedRatesFeedbackContext();
  const questionsFeedbackContext = useCreateQuestionsFeedbackContext();
  const validationContext = useCreateValidationContext();

  // fetch

  const createOrUpdateQuestionsFeedback = useCreateOrUpdateQuestionsFeedbackAPI();

  // handlers

  const isValidFeedback =
    !ratesFeedbackContext.hasMissingRateFeedback &&
    !questionsFeedbackContext.hasMissingRequiredQuestionsFeedback;

  const handleSubmitFeedback = React.useCallback(async () => {
    if (!isValidFeedback) return;

    await createOrUpdateQuestionsFeedback(questionsFeedbackContext.questionsFeedback);
    await updateAttemptData(attemptId, {
      review_status: ratesFeedbackContext.isAllJobsSkipped
        ? REVIEW_STATUS_TYPES.SKIPPED
        : REVIEW_STATUS_TYPES.REVIEWED,
    });
  }, [
    isValidFeedback,
    ratesFeedbackContext.isAllJobsSkipped,
    questionsFeedbackContext.questionsFeedback,
    attemptId,
    createOrUpdateQuestionsFeedback,
    updateAttemptData,
  ]);

  // render

  const ratesBlock = (
    <RatesBlock>
      <RatesTableOrderedWithFeedback jobsData={ratesFeedbackContext.jobsData} />
      <StatusMessagerBlock
        hasMissingRatesFeedback={ratesFeedbackContext.hasMissingRateFeedback}
        hasMissingQuestionsFeedback={
          questionsFeedbackContext.hasMissingRequiredQuestionsFeedback
        }
      />
    </RatesBlock>
  );

  return (
    <OrderedRatesFeedbackContext.Provider value={ratesFeedbackContext}>
      <QuestionsFeedbackContext.Provider value={questionsFeedbackContext}>
        <ValidationContext.Provider value={validationContext}>
          <SurveyItemBase
            ratesBlock={ratesBlock}
            hasNoRatesFeedback={!ratesFeedbackContext.feedbackData.size}
            hasNoQuestionsFeedback={!questionsFeedbackContext.questionsFeedback.size}
            disableSubmit={!isValidFeedback}
            onSubmitFeedback={handleSubmitFeedback}
          />
        </ValidationContext.Provider>
      </QuestionsFeedbackContext.Provider>
    </OrderedRatesFeedbackContext.Provider>
  );
}

SurveyItemPageOrdered.displayName = "SurveyItemPageOrdered";

export default SurveyItemPageOrdered;
