/**
 * @generated SignedSource<<e72723f4dbc7f49d5b5cf230a6fdc1a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FormRateSearch_viewer$data = {
  readonly user: {
    readonly " $fragmentSpreads": FragmentRefs<"HeadingForm_user" | "SubmitRateSearch_user">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"DescriptionField_viewer" | "IndustriesField_viewer" | "RegionsField_viewer" | "SupportedCountries_viewer" | "WorkerTypeField_viewer">;
  readonly " $fragmentType": "FormRateSearch_viewer";
};
export type FormRateSearch_viewer$key = {
  readonly " $data"?: FormRateSearch_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"FormRateSearch_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FormRateSearch_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HeadingForm_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SubmitRateSearch_user"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "IndustriesField_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorkerTypeField_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RegionsField_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SupportedCountries_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DescriptionField_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "a4d3e1dc5fff38da867adf22516f7c63";

export default node;
