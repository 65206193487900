// @flow

import { extendObservable, action } from "mobx";

import ExportOptions from "./ExportOptions";
import NetworkState from "./NetworkState";
import ModalState from "./ModalState";

export default class ExportOptionsState {
  networkState: NetworkState;
  modalState: ModalState;
  exportOptions: ExportOptions;

  showFileTypeOptions: boolean;
  showLevelTypeOptions: boolean;
  showExportTypeOptions: boolean;
  showCurrencyTypeOptions: boolean;
  exportFunction: (options: ExportOptions) => void;
  validateFunction: (options: ExportOptions) => void;
  disableFileTypeOptions: boolean;
  disableFileTypeReason: string;
  disableExportTypeOptions: boolean;
  disableExportTypeReason: string;
  info: ?string;

  onChangeFileType: (string) => void;
  onChangeLevelsType: (string) => void;
  onChangeExportType: (string) => void;
  onChangeCurrencyType: (string) => void;
  onChangeFileName: (Event) => void;
  onExport: (Event) => void;
  onCancel: (Event) => void;

  constructor(
    exportFunction: (options: ExportOptions) => void,
    validateFunction: (options: ExportOptions) => void,
    showFileTypeOptions: boolean = true,
    showLevelTypeOptions: boolean = false,
    showExportTypeOptions: boolean = true,
    showCurrencyTypeOptions: boolean = false
  ) {
    extendObservable(this, {
      showFileTypeOptions,
      showLevelTypeOptions,
      showExportTypeOptions,
      showCurrencyTypeOptions,
      disableFileTypeOptions: false,
      disableFileTypeReason: null,
      disableExportTypeOptions: false,
      disableExportTypeReason: null,
      disableCurrencyTypeOptions: false,
      disableCurrencyTypeReason: null,
      info: null,
    });

    this.exportFunction = exportFunction;
    this.validateFunction = validateFunction;

    this.networkState = new NetworkState();
    this.modalState = new ModalState();
    this.exportOptions = new ExportOptions();

    this.onChangeFileType = action(this.onChangeFileType.bind(this));
    this.onChangeLevelsType = action(this.onChangeLevelsType.bind(this));
    this.onChangeExportType = action(this.onChangeExportType.bind(this));
    this.onChangeFileName = action(this.onChangeFileName.bind(this));
    this.onChangeCurrencyType = action(this.onChangeCurrencyType.bind(this));
    this.onExport = action(this.onExport.bind(this));
    this.onCancel = action(this.onCancel.bind(this));
  }

  onChangeFileType(newValue: string) {
    this.exportOptions.fileType = newValue;
    this.validateFunction(this);
  }

  onChangeLevelsType(newValue: string) {
    this.exportOptions.levelType = newValue;
    this.validateFunction(this);
  }

  onChangeExportType(newValue: string) {
    this.exportOptions.exportType = newValue;
    this.validateFunction(this);
  }

  onChangeCurrencyType(newValue: string) {
    this.exportOptions.currencyType = newValue;
    this.validateFunction(this);
  }

  onChangeFileName(e: Event & { target: HTMLInputElement }) {
    this.exportOptions.fileName = e.target.value;
  }

  onExport(e: Event) {
    this.modalState.hideModal();
    this.exportFunction(this.exportOptions);
  }

  onCancel(e: Event) {
    this.modalState.hideModal();
  }
}
