import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default class BottomControls extends React.Component {
  static defaultProps = {
    height: "50px",
  };

  static propTypes = {
    containerClassName: PropTypes.any,
  };

  render() {
    return (
      <div
        className={classNames("pt-bottom-controls", this.props.className)}
        style={{ height: this.props.height }}
      >
        <div
          className={classNames(
            "pt-bottom-controls__container",
            this.props.containerClassName
          )}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export class BottomControlGroup extends React.Component {
  render() {
    return (
      <div
        className={classNames("pt-bottom-controls__control-group", this.props.className)}
      >
        {this.props.children}
      </div>
    );
  }
}
