import { fromJS } from "immutable";

import { emptyList } from "./constants";
import { OrderDirections } from "./types";

import type { ComparatorFunc, ComparatorsConfig, FilterConfigMap } from "./types";

export {
  djangoOrderingKey,
  djangoPaginationKey,
  djangoPaginationSizeKey,
  djangoSearchKey,
  emptyList,
  emptyMap,
  emptySet,
  emptyOrderedMap,
} from "../../constants";
export { OrderDirections, FilterTypes } from "./types";

const comparator: ComparatorFunc = (a, b) => (a === b ? 0 : a < b ? -1 : 1);
export const defaultComparators: ComparatorsConfig = {
  [OrderDirections.ASC]: comparator,
  [OrderDirections.DSC]: (...args) => comparator(...args) * -1,
  [OrderDirections.NO]: (...args) => comparator(...args) * -1,
};

export const emptyFilter: FilterConfigMap = fromJS({
  order: {
    direction: OrderDirections.NO,
  },
  filter: {
    values: emptyList,
    search: null,
  },
});

export const djangoOperationSeparator = "__";
export const djangoDateFormat = "YYYY-MM-DD";
export const resultSetArgName = "__resultset";

export const defaultPageNumber = 1;
export const defaultPageCapacity = 25;
