import React, { useState, useCallback } from "react";

import Stack from "../../../../components/lib/Stack";
import Table from "../../lib/Table";
import Button from "../../../../components/lib/Button";
import { TBody, TR, TD } from "../../../../components/lib/Table";
import { JOBS_ORDERING_TYPES } from "../../types";
import { ButtonGroupRight } from "../../../../components/lib/ButtonGroup";
import { getAllJobsOrdered } from "./utils";

import type {
  JOBS_ORDERING_TYPES_TYPE,
  JobsDataOrderedMap,
  RatesFeedbackOrderedMap,
} from "../../types";
import Box from "../../../../components/lib/Box";

type PageStateType = {
  orderingKey: JOBS_ORDERING_TYPES_TYPE;
  orderingAsc: boolean;
};

export type RatesTableOrderedHeadBaseProps = {
  shortMode?: boolean;
};

export type RatesTableOrderedRowBaseProps = {
  jobId: number;
  jobsData: JobsDataOrderedMap;
  feedbackData?: RatesFeedbackOrderedMap;
  shortMode?: boolean;
};

export type RatesTableOrderedBodyBaseProps = {
  jobsData: JobsDataOrderedMap;
  feedbackData?: RatesFeedbackOrderedMap;
  shortMode?: boolean;
  emptyDataText?: string;
  tableRowImpl: React.FunctionComponent<RatesTableOrderedRowBaseProps>;
  orderingKey: JOBS_ORDERING_TYPES_TYPE;
  orderingAsc: boolean;
};

export type RatesTableOrderedBaseProps = {
  jobsData: JobsDataOrderedMap;
  feedbackData?: RatesFeedbackOrderedMap;
  shortMode?: boolean;
  emptyDataText?: string;
};

type Props = RatesTableOrderedBaseProps & {
  tableHeadImpl: React.FunctionComponent<RatesTableOrderedHeadBaseProps>;
  tableBodyImpl?: React.FunctionComponent<RatesTableOrderedBodyBaseProps>;
  tableRowImpl: React.FunctionComponent<RatesTableOrderedRowBaseProps>;
};

export const RatesTableOrderedBody = (props: RatesTableOrderedBodyBaseProps) => {
  const {
    jobsData,
    feedbackData,
    orderingKey,
    orderingAsc,
    tableRowImpl: TableRowImpl,
    shortMode,
    emptyDataText,
  } = props;

  const orderedJobsData = getAllJobsOrdered(
    jobsData,
    orderingKey,
    orderingAsc,
    feedbackData
  );
  const orderedJobsDataSize = orderedJobsData?.size ?? 0;

  return (
    <TBody>
      {!!orderedJobsDataSize ? (
        orderedJobsData
          .toArray()
          .map((jobData) => (
            <TableRowImpl
              key={jobData.get("id")}
              jobId={jobData.get("id")}
              jobsData={orderedJobsData}
              feedbackData={feedbackData}
              shortMode={shortMode}
            />
          ))
      ) : (
        <TR>
          <TD colSpan={999}>{emptyDataText}</TD>
        </TR>
      )}
    </TBody>
  );
};

export default function RatesTableOrderedBase(props: Props) {
  const {
    jobsData,
    feedbackData,
    emptyDataText = "No Rates",
    shortMode = false,
    tableHeadImpl: TableHeadImpl,
    tableBodyImpl: TableBodyImpl = RatesTableOrderedBody,
    tableRowImpl: TableRowImpl,
  } = props;

  // state

  const [orderingState, setOrderingState] = useState<PageStateType>({
    orderingKey: JOBS_ORDERING_TYPES.PAY_RATE_MIN,
    orderingAsc: true,
  });
  const { orderingKey: orderingKeyState, orderingAsc: orderingAscState } = orderingState;

  // handlers

  const handleChangeTableOrdering = useCallback(
    (orderingKey: JOBS_ORDERING_TYPES_TYPE) => {
      setOrderingState({
        orderingKey,
        orderingAsc: orderingKey === orderingKeyState ? !orderingAscState : true,
      });
    },
    [orderingKeyState, orderingAscState]
  );

  // render

  const icon = orderingState.orderingAsc ? "sort-numeric-down" : "sort-numeric-down-alt";

  return (
    <Stack fill css={{ alignItems: "stretch" }}>
      <Box css={{ overflowX: "scroll" }}>
        <Table center highlighted={false} css={{ overflow: "hidden" }}>
          <TableHeadImpl shortMode={shortMode} />
          <TableBodyImpl
            jobsData={jobsData}
            feedbackData={feedbackData}
            tableRowImpl={TableRowImpl}
            orderingKey={orderingKeyState}
            orderingAsc={orderingAscState}
            shortMode={shortMode}
            emptyDataText={emptyDataText}
          />
        </Table>
      </Box>

      {jobsData?.size > 1 && (
        <ButtonGroupRight>
          <Button
            icon={
              orderingState.orderingKey === JOBS_ORDERING_TYPES.PAY_RATE_MIN
                ? icon
                : undefined
            }
            color="brand"
            variant={
              orderingState.orderingKey === JOBS_ORDERING_TYPES.PAY_RATE_MIN
                ? "filled"
                : "outlined"
            }
            onClick={() => handleChangeTableOrdering(JOBS_ORDERING_TYPES.PAY_RATE_MIN)}
          >
            Sort Roles By Lowest Wage
          </Button>
          <Button
            icon={
              orderingState.orderingKey === JOBS_ORDERING_TYPES.PAY_RATE_MAX
                ? icon
                : undefined
            }
            color="brand"
            variant={
              orderingState.orderingKey === JOBS_ORDERING_TYPES.PAY_RATE_MAX
                ? "filled"
                : "outlined"
            }
            onClick={() => handleChangeTableOrdering(JOBS_ORDERING_TYPES.PAY_RATE_MAX)}
          >
            Sort Roles By Highest Wage
          </Button>
        </ButtonGroupRight>
      )}
    </Stack>
  );
}

RatesTableOrderedBase.displayName = "RatesTableOrderedBase";
