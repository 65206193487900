// @flow
import moment from "moment";
import { extendObservable, computed } from "mobx";

export default class NotificationsList {
  store: Object;
  id: number;
  isRead: boolean;
  message: string;
  content: ?Object;
  created: ?Object;
  createdDisplay: string;
  rateCardLabel: string;

  constructor(store: Object, object: Object) {
    this.store = store;
    this.id = 0;
    this.isRead = false;
    this.message = "";
    this.content = null;
    this.created = null;
    this.createdDisplay = "";
    this.rateCardLabel = "";

    if (object) {
      extendObservable(this, {
        id: object.id || 0,
        content: object.contentObject || null,
        isRead: object.isRead || false,
        message: object.message || "",
        created: moment(object.created) || null,
        rateCardLabel: object.rateCardLabel || null,
        createdDisplay: computed(
          () =>
            `Created ${this.created ? this.created.format("MMMM D, YYYY") : "unknown"}`
        ),
      });
    }
  }
}
