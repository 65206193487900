import React from "react";

import { styled } from "../../stitches.config";
import Inline from "../lib/Inline";
import Box from "../lib/Box";

const PickerContainer = styled(Inline, {
  display: "inline-flex",
  gap: 0,
  whiteSpace: "nowrap",
  border: "1px solid $primaryLight",
  "& > *": {
    padding: "$2 $4",
    textAlign: "center",
  },
});
const PickerLabel = styled(Box, {
  whiteSpace: "nowrap",
  minWidth: "85px",
  background: "$primary",
  color: "$textLight",
});
const PickerOption = styled(Box, {
  whiteSpace: "nowrap",
  minWidth: "35px",
  borderLeft: "1px solid $primaryLight",
  cursor: "pointer",
  "&:hover": {
    background: "$primaryLightest",
  },
  variants: {
    active: {
      true: {
        background: "$brand",
        color: "$textLight",
        "&:hover": {
          background: "$brandLight",
        },
      },
    },
  },
  defaultVariants: {
    active: false,
  },
});

type ActiveOptionPredicate<T> = (value: T, optionValue: T) => boolean;

function singleValuePredicateFunc<T>(value: T, optionValue: T): boolean {
  return value === optionValue;
}
function multiValuePredicateFunc<T>(value: T, optionValue: T): boolean {
  const valueArray = Array.isArray(value) ? value : [value];
  return valueArray.indexOf(optionValue) >= 0;
}

export type PickerOptionObject<T> = {
  value: T;
  label: React.ReactNode;
  hint?: React.ReactNode;
};

export interface InlineValuePickerProps<T = any> {
  label: React.ReactNode;
  options: PickerOptionObject<T>[];
  value: T;
  onChange?: (value: T) => void;
  activeOptionPredicate?: ActiveOptionPredicate<T>;
}

const InlineValuePicker = (props: InlineValuePickerProps) => {
  const { label, value, options, onChange, activeOptionPredicate, ...rest } = props;

  return (
    <PickerContainer {...rest}>
      {label && <PickerLabel>{label}</PickerLabel>}
      {options.map((option) => {
        const { label: optionLabel, value: optionValue, hint: optionHint } = option;
        return (
          <PickerOption
            key={optionValue}
            title={(optionHint as string) || (optionLabel as string)}
            active={
              activeOptionPredicate != null
                ? activeOptionPredicate(value, optionValue)
                : false
            }
            onClick={onChange && onChange.bind(undefined, optionValue)}
          >
            {optionLabel}
          </PickerOption>
        );
      })}
    </PickerContainer>
  );
};

InlineValuePicker.displayName = "InlineValuePicker";
InlineValuePicker.defaultProps = {
  activeOptionPredicate: singleValuePredicateFunc,
};

export const InlineMultiValuePicker = (props: InlineValuePickerProps) => (
  <InlineValuePicker {...props} />
);

InlineMultiValuePicker.displayName = "InlineMultiValuePicker";
InlineValuePicker.defaultProps = {
  activeOptionPredicate: multiValuePredicateFunc,
};

export default InlineValuePicker;
