import React, { Component } from "react";

class InputNumberForAlert extends Component {
  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e, type) {
    let paytype = this.props.payRateType;
    this.props.onChange(e.target.value, paytype);
  }
  render() {
    let value = this.props.value.toString();
    return (
      <input
        type="number"
        min="0"
        value={value}
        onChange={this.handleChange}
        className="rc-input-box"
        style={{ width: "70%" }}
      />
    );
  }
}

export default InputNumberForAlert;
