// @flow
import moment from "moment";
import { extendObservable } from "mobx";

class LegacyUser {
  store: Object;
  id: ?string;
  legacyId: ?string;
  username: ?string;
  firstName: ?string;
  lastName: ?string;
  isActive: ?boolean;

  constructor(store, object) {
    this.store = store;

    if (object) {
      this.id = object.databaseId;
      this.legacyId = object.userId;
      this.username = object.username;
      this.firstName = object.firstName;
      this.lastName = object.lastName;
      this.isActive = object.isActive;
    }
  }
}

export default class JobLibrary {
  store: Object;
  id: number;
  name: ?string;
  description: ?string;
  systemName: ?string;
  systemLibrary: boolean;
  isMainLibrary: boolean;
  totalTitleByCountryCertifications: number;
  totalTitleBySearchableCountries: number;
  totalTitles: number;
  created: ?moment$Moment;
  modified: ?moment$Moment;
  titlesCount: number;
  createdBy: ?LegacyUser;
  logoUrl: ?string;
  searchableCountries: ?Array<number>;

  toString: () => string;

  constructor(store: Object, payloadObject: Object) {
    this.store = store;
    // this.id = null;
    extendObservable(this, {
      name: null,
      description: null,
      systemName: null,
      systemLibrary: false,
      isMainLibrary: false,
      totalTitleByCountryCertifications: 0,
      totalTitleBySearchableCountries: 0,
      totalTitles: 0,
      created: null,
      modified: null,
      titlesCount: 0,
      createdBy: null,
      logoUrl: null,
      searchableCountries: null,
    });

    if (payloadObject) {
      this.id = payloadObject.databaseId;
      this.name = payloadObject.name;
      this.description = payloadObject.description;
      this.systemName = payloadObject.systemName;
      this.systemLibrary = payloadObject.systemLibrary;
      this.isMainLibrary = payloadObject.isMainLibrary;
      this.totalTitleByCountryCertifications =
        payloadObject.totalTitleByCountryCertifications || 0;
      this.totalTitleBySearchableCountries =
        payloadObject.totalTitleBySearchableCountries || 0;
      this.totalTitles = payloadObject.totalTitles
        ? payloadObject.totalTitles.totalCount || 0
        : 0;
      this.created = moment(payloadObject.created);
      this.modified = moment(payloadObject.modified);
      this.logoUrl = payloadObject.logoUrl;
      this.titlesCount = payloadObject.clientJobTitles
        ? payloadObject.clientJobTitles.totalCount
        : 0;
      if (payloadObject.createdBy) {
        this.createdBy = new LegacyUser(store, payloadObject.createdBy);
      }
      if (payloadObject.searchableCountries) {
        this.searchableCountries = payloadObject.searchableCountries.map(
          (c) => c.databaseId
        );
      }
    }
  }

  toString(): string {
    return this.name || "<Name: Not Available>";
  }
}
