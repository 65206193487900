import React, { useCallback, useState } from "react";
import { List, fromJS } from "immutable";

import Button from "../../../components/lib/Button";
import Text from "../../../components/lib/Text";
import Inline from "../../../components/lib/Inline";
import Stack from "../../../components/lib/Stack";
import { IconButton } from "../../../components/lib/Button";
import { ButtonGroup } from "../../../components/lib/ButtonGroup";
import { ImmutableList } from "../../../types/immutable";

// Generic for InputImpl

type InputImplLabelsType = string[];
type InputImplValuesType = string[];

interface InputImplProps {
  labels: InputImplLabelsType;
  values: InputImplValuesType;
  disabled?: boolean;
  onChange?: (value: InputImplValuesType) => void;
}

// Generic for InputsList

type ValuesType = ImmutableList<string>;
type ValuesListType = ImmutableList<ValuesType>;
type LabelsType = InputImplLabelsType;

interface Props {
  inputImpl: React.FunctionComponent<InputImplProps>;
  values: ValuesListType;
  labels: LabelsType;
  onChange?: (values: ValuesListType) => void;
}

export default function InputsList(props: Props) {
  const { values: initialValues, labels, inputImpl: InputImpl, onChange } = props;

  const [values, setValues] = useState<ValuesListType>(initialValues);
  const [editingItemIdx, setEditingItemIdx] = useState<number | null>(null);

  const editMode = editingItemIdx != null;

  const handleInputsChanged = useCallback(
    (idx: number) => {
      return (value: InputImplValuesType) => {
        if (idx < values.size) {
          const newValues = values.set(idx, fromJS(value)) as unknown as ValuesListType;
          setValues(newValues);
        }
      };
    },
    [values]
  );

  const handleAddItem = useCallback(() => {
    // get initial values to cancel any editing process performing atm
    const defaultValues = List(["", ""]) as unknown as ValuesType;
    setValues(initialValues.push(defaultValues));
    setEditingItemIdx(initialValues.size);
  }, [initialValues]);

  const handleEditItem = useCallback(
    (idx: number) => {
      // get initial values to cancel any editing process performing atm
      if (idx < initialValues.size && idx !== editingItemIdx) {
        setValues(initialValues);
        setEditingItemIdx(idx);
      }
    },
    [initialValues, editingItemIdx]
  );

  const handleDeleteItem = useCallback(
    (idx: number) => {
      if (!(idx < values.size && idx < initialValues.size)) {
        return;
      }

      setEditingItemIdx(
        editMode && idx < editingItemIdx ? editingItemIdx - 1 : editingItemIdx
      );
      setValues(values.delete(idx));
      onChange?.(initialValues.delete(idx));
    },
    [values, editingItemIdx, editMode, initialValues, setEditingItemIdx, onChange]
  );

  const handleApplyChanges = useCallback(() => {
    if (editMode) {
      setEditingItemIdx(null);
      onChange?.(values);
    }
  }, [values, editMode, onChange]);

  const handleCancelChanges = useCallback(() => {
    if (editMode) {
      setValues(initialValues);
      setEditingItemIdx(null);
    }
  }, [initialValues, editMode]);

  return (
    <Stack css={{ alignItems: "stretch" }}>
      {values.toArray().map((value, idx) => {
        const isEditing = idx === editingItemIdx;
        const _value = value ? value.toJS() : ([] as unknown as InputImplValuesType);

        return (
          <Inline
            nowrap
            key={idx}
            css={{
              paddingBottom: "$4",
              "&:first-child": {
                paddingTop: "$4",
              },
              borderBottom: "1px solid $primaryLighter",
              "@md": {
                borderBottom: "none",
                padding: 0,
              },
            }}
          >
            <InputImpl
              labels={labels}
              values={_value}
              disabled={!isEditing}
              onChange={
                isEditing ? (_value) => handleInputsChanged(idx)(_value) : undefined
              }
            />
            <ButtonGroup>
              {isEditing && (
                <IconButton
                  css={{ width: "28px" }}
                  icon="check"
                  color="success"
                  variant="outlined"
                  title="Apply"
                  onClick={handleApplyChanges}
                />
              )}
              {isEditing && (
                <IconButton
                  css={{ width: "28px" }}
                  icon="times"
                  variant="outlined"
                  title="Cancel"
                  onClick={handleCancelChanges}
                />
              )}
              {!isEditing && (
                <IconButton
                  css={{ width: "28px" }}
                  icon="pencil-alt"
                  color="brand"
                  variant="outlined"
                  title="Edit item"
                  onClick={() => handleEditItem(idx)}
                />
              )}
              {!isEditing && (
                <IconButton
                  css={{ width: "28px" }}
                  icon="trash"
                  color="danger"
                  variant="outlined"
                  title="Delete item"
                  onClick={() => handleDeleteItem(idx)}
                />
              )}
            </ButtonGroup>
          </Inline>
        );
      })}

      <ButtonGroup>
        <Button
          color="brand"
          variant="outlined"
          onClick={editingItemIdx == null ? handleAddItem : undefined}
          disabled={editMode}
        >
          Add item
        </Button>
        {editMode && (
          <Text as="h6" color="negative">
            don't forget to apply item changes!
          </Text>
        )}
      </ButtonGroup>
    </Stack>
  );
}

InputsList.displayName = "InputsList";
