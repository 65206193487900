import React from "react";

import Icon from "./lib/Icon";
import Button from "./lib/Button";
import { CardFilter } from "./lib/Card";
import { ButtonGroupRight } from "./lib/ButtonGroup";
import {
  Dialog,
  DialogActions,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "./lib/Dialog";

export type SortDataType = "numeric" | "alpha";
export type SortDirectionType = "ASC" | "DESC";

export const SORT_DIRECTION = {
  ASC: "ASC",
  DESC: "DESC",
} as const;

export interface FilterDialogProps {
  title: string | JSX.Element;
  description?: JSX.Element;
  children?: React.ReactNode;
  filtered?: boolean;
  sorted?: {
    direction: SortDirectionType;
    dataType: SortDataType;
  };
  disableApply?: boolean;
  onApply: () => void;
  onClear: () => void;
}

export function getSortIcon(direction: SortDirectionType, dataType: SortDataType) {
  return {
    [SORT_DIRECTION.ASC]: (
      <Icon icon={`sort-${dataType}-down`} css={{ color: "$accent" }} />
    ),
    [SORT_DIRECTION.DESC]: (
      <Icon icon={`sort-${dataType}-down-alt`} css={{ color: "$accent" }} />
    ),
  }[direction];
}

export function FilterDialog(props: FilterDialogProps) {
  const { sorted, disableApply = false } = props;
  const sortIcon = sorted ? getSortIcon(sorted.direction, sorted.dataType) : undefined;

  return (
    <Dialog>
      <DialogTrigger asChild>
        <CardFilter filtered={props.filtered}>
          {sortIcon} {props.title}
          <span> ▼</span>
        </CardFilter>
      </DialogTrigger>
      <DialogContent
        onOpenAutoFocus={(e) => e.preventDefault()}
        onCloseAutoFocus={(e) => e.preventDefault()}
      >
        <DialogTitle>{props.title}</DialogTitle>
        {props.description && (
          <DialogDescription
            css={{ backgroundColor: "white", borderBottom: "1px solid #eceff1" }}
            asChild
          >
            {props.description}
          </DialogDescription>
        )}
        {props.children}
        <DialogActions>
          <ButtonGroupRight fill css={{ "& button": { flexGrow: 1 } }}>
            <DialogClose asChild>
              <Button
                color="brand"
                size="large"
                disabled={disableApply}
                onClick={props.onApply}
              >
                Apply
              </Button>
            </DialogClose>
            <DialogClose asChild>
              <Button size="large" onClick={props.onClear}>
                Clear
              </Button>
            </DialogClose>
            <DialogClose asChild>
              <Button size="large">Close</Button>
            </DialogClose>
          </ButtonGroupRight>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

FilterDialog.displayName = "FilterDialog";

export default FilterDialog;
