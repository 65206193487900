// @flow
import { extendObservable, action } from "mobx";
import FilterObject, {
  FilterWithSort,
  ValueSetFilter,
  FilterCriteriaLoader,
  IViewItem,
} from "../Filter";
import type { FilterColumn } from "../Filter";
import { GraphQLQuery } from "../GraphQL";
import Sort from "../Sort";
import SortState from "../SortState";
import { filter } from "fuzzaldrin-plus";

class JobTitleCriterionItem implements IViewItem {
  id: string;
  jobTitle: string;
  selected: boolean;

  constructor(object: Object) {
    this.id = object.id;
    this.jobTitle = object.jobTitle;

    extendObservable(this, {
      selected: object.selected,
    });
  }
}

export default class JobTitleFilter extends FilterCriteriaLoader(
  ValueSetFilter(FilterWithSort)
) {
  constructor(
    store: Object,
    column: FilterColumn,
    filterCriteriaQuery: (FilterColumn) => GraphQLQuery,
    processPayload: (FilterColumn, Object) => ?Array<Object>,
    applyFilter: (FilterColumn, FilterObject) => void,
    applySort: (FilterColumn, Sort) => void,
    removeFilter: (FilterColumn) => void,
    removeSort: (FilterColumn) => void,
    reloadCriteria: boolean = false
  ) {
    super(store, column);

    this.filterCriteriaQuery = filterCriteriaQuery;
    this.processPayload = processPayload;
    this.applyFilter = applyFilter;
    this.applySort = applySort;
    this.removeFilter = removeFilter;
    this.removeSort = removeSort;

    this.sortState = new SortState("JOB_TITLE");

    this.reloadCriteria = reloadCriteria;

    this.viewItemClass = JobTitleCriterionItem;

    // $FlowFixMe: Need to type these properties as invariant
    this.onInstantSearch = action(this.onInstantSearch.bind(this));
    // $FlowFixMe: Need to type these properties as invariant
    this.buildQueryFilter = action(this.buildQueryFilter.bind(this));
  }

  onInstantSearch(value: string) {
    if (super.onInstantSearch) super.onInstantSearch(value);

    if (!this.instantSearchValue) {
      this.viewItems = this.unfilteredViewItems;
      return;
    }

    this.viewItems = filter(this.unfilteredViewItems, value, {
      key: "jobTitle",
      maxResults: 20,
    });
  }

  buildQueryFilter() {
    const titles = this.selectedValues
      .entries()
      .filter((entry) => entry[1] === true)
      .map((entry) => this.criteria.get(entry[0]).jobTitle);

    const jobTitle = this.selectedValue;

    if (!titles.length && (!jobTitle || !jobTitle.trim())) {
      return null;
    }

    let params = [];
    let args = [];
    let vars = {};

    if (jobTitle && jobTitle.trim()) {
      params.push("$jobTitle: String!");
      args.push("jobTitleIContains: $jobTitle");
      vars.jobTitle = jobTitle.trim();
    }

    if (titles.length) {
      params.push("$jobTitles: [JobTitleCriterionInput]!");
      args.push("jobTitles: $jobTitles");
      vars.jobTitles = titles.map((title) => {
        return { jobTitle: title };
      });
    }

    return new FilterObject(params.join(", "), args.join(", "), vars);
  }
}
