import React from "react";
import R from "ramda";
import { Card, CardListGroup, CardListGroupItem } from "../../../components/material";
import { RATE_TYPE_OPTIONS } from "../../../constants/negotiationWorksheet";

const rateTypes = R.values(RATE_TYPE_OPTIONS);

export default class CalculatedResults extends React.Component {
  setRateType = (rateType) => {
    const { store } = this.props;
    const currentHourlyValue = RATE_TYPE_OPTIONS[
      store.currentState.rateType
    ].convertToHourly(store.currentState.payValue, this.props.conversionOptions);
    store.setRateType(store, rateType.value);
    var newPayValue = rateType.convertFromHourly(
      currentHourlyValue,
      this.props.conversionOptions
    );
    this.props.store.setPayValue(store, newPayValue);
  };

  render() {
    // console.log("hourly res props:", this.props)
    const {
      totalMarkup,
      billRateHourly,
      payRateHourly,
      conversionOptions,
      currencySymbol,
      billRateHourlyAfterTax,
      totalTax,
      store,
    } = this.props;

    const markupHourly = payRateHourly * (totalMarkup / 100);
    const taxHourly = billRateHourly * (totalTax / 100);

    return (
      <Card className="pt-worksheet__calculated-results">
        <CardListGroup className="pt-worksheet__calculated-results__list-group">
          <CardListGroupItem noBorder noPadding>
            <ul className="pt-worksheet__table-list pt-worksheet__table-list--header pt-worksheet__pointer">
              {rateTypes.map((it) => (
                <li
                  key={it.value}
                  className={
                    it.value === store.currentState.rateType
                      ? "pt-worksheet__table-list--selected"
                      : null
                  }
                  onClick={R.partial(this.setRateType, it)}
                >
                  {it.label}
                </li>
              ))}
            </ul>
          </CardListGroupItem>
          <CardListGroupItem noBorder noPadding>
            <h4 className="pt-worksheet__table-list__header">Pay Rate</h4>
            <ul className="pt-worksheet__table-list">
              {rateTypes.map((it) => (
                <li
                  key={it.value}
                  className={
                    it.value === store.currentState.rateType
                      ? "pt-worksheet__table-list--selected"
                      : null
                  }
                >
                  <span className="pt-worksheet__table-list__rate">
                    {currencySymbol}
                    {it.convertFromHourly(payRateHourly, conversionOptions).toFixed(2)}
                  </span>
                </li>
              ))}
            </ul>
          </CardListGroupItem>
          <CardListGroupItem noBorder noPadding>
            <h4 className="pt-worksheet__table-list__header">
              <span className="pt-worksheet__table-list__header__label">Markup</span>
              <div className="pt-worksheet__table-list__header__rate">{totalMarkup}%</div>
            </h4>
            <ul className="pt-worksheet__table-list">
              {rateTypes.map((it) => (
                <li
                  key={it.value}
                  className={
                    it.value === store.currentState.rateType
                      ? "pt-worksheet__table-list--selected"
                      : null
                  }
                  onClick={R.partial(this.setRateType, it)}
                >
                  <span className="pt-worksheet__table-list__rate">
                    {currencySymbol}
                    {it.convertFromHourly(markupHourly, conversionOptions).toFixed(2)}
                  </span>
                </li>
              ))}
            </ul>
          </CardListGroupItem>
          <CardListGroupItem noBorder noPadding>
            <h4 className="pt-worksheet__table-list__header">Bill Rate</h4>
            <ul className="pt-worksheet__table-list">
              {rateTypes.map((it) => (
                <li
                  key={it.value}
                  className={
                    it.value === store.currentState.rateType
                      ? "pt-worksheet__table-list--selected"
                      : null
                  }
                >
                  <span className="pt-worksheet__table-list__rate">
                    {currencySymbol}
                    {it.convertFromHourly(billRateHourly, conversionOptions).toFixed(2)}
                  </span>
                </li>
              ))}
            </ul>
          </CardListGroupItem>
          {totalTax ? (
            <div>
              <CardListGroupItem noBorder noPadding>
                <h4 className="pt-worksheet__table-list__header">
                  <span className="pt-worksheet__table-list__header__label">Tax</span>
                  <div className="pt-worksheet__table-list__header__rate">
                    {parseFloat(totalTax).toFixed(2)}%
                  </div>
                </h4>
                <ul className="pt-worksheet__table-list">
                  {rateTypes.map((it) => (
                    <li key={it.value}>
                      <span className="pt-worksheet__table-list__rate">
                        {currencySymbol}
                        {it.convertFromHourly(taxHourly, conversionOptions).toFixed(2)}
                      </span>
                    </li>
                  ))}
                </ul>
              </CardListGroupItem>
              <CardListGroupItem noBorder noPadding>
                <h4 className="pt-worksheet__table-list__header">Bill Rate with Tax</h4>
                <ul className="pt-worksheet__table-list">
                  {rateTypes.map((it) => (
                    <li
                      key={it.value}
                      className={
                        it.value === store.currentState.rateType
                          ? "pt-worksheet__table-list--selected"
                          : null
                      }
                    >
                      <span className="pt-worksheet__table-list__rate">
                        {currencySymbol}
                        {it
                          .convertFromHourly(billRateHourlyAfterTax, conversionOptions)
                          .toFixed(2)}
                      </span>
                    </li>
                  ))}
                </ul>
              </CardListGroupItem>
            </div>
          ) : null}
        </CardListGroup>
      </Card>
    );
  }
}
