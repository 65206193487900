import React from "react";
import Stack from "../../../components/lib/Stack";
import Button from "../../../components/lib/Button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "../../../components/lib/Dialog";
import Inline from "../../../components/lib/Inline";
import Text from "../../../components/lib/Text";

interface RsLevelsGuideDialogProps {
  children: any;
}

function RsLevelsGuideDialog(props: RsLevelsGuideDialogProps) {
  const { children } = props;

  return (
    <Dialog>
      <DialogTrigger asChild>
        <span>{children}</span>
      </DialogTrigger>
      <DialogContent
        onOpenAutoFocus={(e) => e.preventDefault()}
        onCloseAutoFocus={(e) => e.preventDefault()}
      >
        <Stack fill css={{ padding: "$6", alignItems: "flex-start" }}>
          <Text as="h3" noMargin>
            Levels Guide
          </Text>
          <Text>
            Every organization has their own way of defining experience levels. Through
            our research, it became apparent and logical that, regardless of how
            experience level banding is defined, one pattern emerges; the lower the
            experience level, the lower the rate or salary, and vice-versa. This is the
            basic premise that allows us to examine and categorize the un-banded
            marketplace. Using this methodology it is possible to define experience level
            banding against the un-banded marketplace and satisfy the demands of all
            companies regardless of the experience levels that they may use. Although our
            banding methodology is not determined by years of experience, there is often a
            correlation between levels and years of experience as follows.
          </Text>
          <table className="table table-condensed table-bordered" style={{ margin: 0 }}>
            <thead>
              <tr>
                <th style={{ textAlign: "left" }}>Level</th>
                <th style={{ textAlign: "left" }}>May correlate with</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Level I</td>
                <td>1-3 years</td>
              </tr>
              <tr>
                <td>Level II</td>
                <td>3-5 years</td>
              </tr>
              <tr>
                <td>Level III</td>
                <td>5-7 Years</td>
              </tr>
              <tr>
                <td>Level IV</td>
                <td>7 – 10 years</td>
              </tr>
              <tr>
                <td>Level V</td>
                <td>Top Earners (10+)</td>
              </tr>
            </tbody>
          </table>
          <Inline css={{ alignSelf: "flex-end" }}>
            <DialogClose asChild>
              <Button>Close</Button>
            </DialogClose>
          </Inline>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default RsLevelsGuideDialog;
