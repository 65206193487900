import React from "react";

import JobFeedbackBlock from "./JobFeedbackBlock";
import { useVal5KAdminContext } from "../../context/Val5KAdminContext";
import { emptyMap } from "../../../../constants";

import type { JobDataMap } from "../../types";

type Val5000JobFeedbackBlockProps = {
  job: JobDataMap;
  isThreeLevelBanding: boolean;
  embedded: boolean;
  disableButtons: boolean;
};

function Val5000JobFeedbackBlock(props: Val5000JobFeedbackBlockProps) {
  const { job, isThreeLevelBanding, embedded, disableButtons } = props;

  const { fetchArgusAPI, fetchArgusFilteringAPI, showModalError } =
    useVal5KAdminContext();

  const jobTitle = job.get("title");
  const jobTitleCollection = job.get("collection_title");
  const jobTitleCollectionId = job.get("collection_id");
  const jobCountry = job.get("country");
  const jobCountryId = job.get("country_id");
  const jobIndustry = job.get("industry_name");
  const jobIndustryId = job.get("industry_id");
  const rateType = job.get("rate_type");
  const currencyData = job.get("currency_data") || emptyMap;

  return (
    <JobFeedbackBlock
      jobTitle={jobTitle}
      jobTitleCollection={jobTitleCollection}
      jobTitleCollectionId={jobTitleCollectionId}
      jobCountry={jobCountry}
      jobCountryId={jobCountryId}
      jobIndustry={jobIndustry}
      jobIndustryId={jobIndustryId}
      rateType={rateType}
      currencyData={currencyData}
      isThreeLevelBanding={isThreeLevelBanding}
      embedded={embedded}
      disableButtons={disableButtons}
      fetchArgusAPI={fetchArgusAPI}
      fetchArgusFilteringAPI={fetchArgusFilteringAPI}
      showModalError={showModalError}
    />
  );
}

Val5000JobFeedbackBlock.displayName = "Val5000JobFeedbackBlock";
Val5000JobFeedbackBlock.defaultProps = {
  isThreeLevelBanding: false,
  embedded: false,
  disableButtons: false,
};

export default Val5000JobFeedbackBlock;
