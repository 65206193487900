// @flow
import React, { Component } from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import SearchBox from "../ratecards/components/SearchBox";
import SearchListItem from "./components/SearchListItem";
import FilterButton from "../ratecards/components/FilterButton";
import Loadable from "../ratecards/components/Loadable";

class List extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  UNSAFE_componentWillMount() {
    // this.props.store.getSearches();
  }

  render() {
    const store = this.props.store.searchListStore;

    let editButton = (
      <button className="btn btn-yellow">
        Edit <FontAwesomeIcon icon="edit" className="u-m-left" />
      </button>
    );

    return (
      <div className="view list">
        <div className="top">
          <SearchBox store={store} />
          <nav className="action-bar">{editButton}</nav>
        </div>
        <div className="search-table">
          <header className="search-table-header">
            <nav className="header-left-nav">
              <button className="btn-yellow">Clear Filters</button>
            </nav>
            <nav className="header-right-nav">
              <FilterButton onClick={store.rateTypeFilter.modal.showModal}>
                Rate Type
              </FilterButton>
              <FilterButton onClick={store.jobLabelFilter.modal.showModal}>
                Job Label
              </FilterButton>
              <FilterButton onClick={store.jobTitleFilter.modal.showModal}>
                Job Title
              </FilterButton>
              <FilterButton onClick={store.locationFilter.modal.showModal}>
                Locations
              </FilterButton>
              <FilterButton onClick={store.industryFilter.modal.showModal}>
                Industry
              </FilterButton>
              <FilterButton onClick={store.categoryFilter.modal.showModal}>
                Category
              </FilterButton>
            </nav>
          </header>
          <div>
            <Loadable isLoading={store.network.loading}>
              {store.searches.map((search) => (
                <SearchListItem
                  key={search.id}
                  search={search}
                  onClick={search.toggleExpand}
                  expanded={search.expanded}
                />
              ))}
            </Loadable>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(List);
