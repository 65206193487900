import React, { useCallback } from "react";
import { useRecoilState } from "recoil";
import moment from "moment";

import Box from "../../../components/lib/Box";
import Text from "../../../components/lib/Text";
import Icon from "../../../components/lib/Icon";
import TableExportConfig from "../components/TableExportConfig";
import TextCrop from "../../validator5K_admin/components/TextCrop";
import { TableFilterableRestful } from "./TableFilterableRestful";
import { useTableSchemaState } from "../../../components/tables/Table";
import {
  FilterTypes,
  djangoOrderingKey,
  djangoPaginationKey,
  djangoPaginationSizeKey,
} from "../../../components/tables/constants";
import { dateTimeFormatter } from "../constants";
import {
  ndaHistoryTableGlobalState,
  NdaHistoryTableDataStateObject,
} from "../globalState";
import { useInitialFetch } from "../../../utils/hooks";
import { renderTableColumnsSpecs } from "../../private_index/components/ExtendedRestfulTables";
import { transformNdaData } from "../dataConverters";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";
import { TickerContentLoader } from "../../../components/lib/TickerLoader";

import type { UrlQueryObject } from "../../../components/tables/types";
import type { RestfulTableDataStateObject } from "../globalState";
import type { NdaHistoryDataObject } from "../types";
import type { TableColumnSpecsObject, NdaHistoryDataMap } from "../types";
import type { TableFilterableRestfulProps } from "../../../components/tables/TableFilterableRestful";
import type { FetchAPIResponse } from "../../../types/fetch";
import type { DjangoPaginatedResponse } from "../../../types/django";
import { omitObjectKeys } from "../../../utils/object";
import { rowIdGetter } from "../../../components/tables/utils";
import { useVal5KAdminContext } from "../context/Val5KAdminContext";

export const ndaHistoryTableSpecs: TableColumnSpecsObject<NdaHistoryDataMap>[] = [
  {
    type: "column",
    uniqueKey: "created",
    title: "Confirmed At",
    getter: (row) => row.get("created"),
    formatter: (value) => dateTimeFormatter(value),
    filterType: FilterTypes.DATES_RANGE,
    filterExtraProps: {
      datesFormat: moment.defaultFormat,
    },
  },
  {
    type: "column",
    uniqueKey: "first_name",
    title: "First Name",
    getter: (row) => row.get("first_name"),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "last_name",
    title: "Last Name",
    getter: (row) => row.get("last_name"),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "email",
    title: "Email",
    getter: (row) => row.get("email"),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "nda_text",
    title: "Agreement Text",
    getter: (row) => row.get("nda_text"),
    formatter: (value) => (
      <Text>
        <Icon icon="file" css={{ color: "$brand" }} />
        <TextCrop
          mode="modal"
          title="Non Disclosure Agreement Terms"
          hint="Click in order to see full agreement terms"
          text={value}
          emptyStub=""
        />
      </Text>
    ),
    filterType: FilterTypes.VALUES_ICONTAINS,
  },
];

type NdaHistoryTableProps = Omit<TableFilterableRestfulProps, "schema">;

const NdaHistoryTable = (props: NdaHistoryTableProps) => {
  const [schema] = useTableSchemaState(props.children);
  return <TableFilterableRestful {...props} schema={schema} />;
};
NdaHistoryTable.displayName = "NdaHistoryTable";

type NdaHistoryTableViewProps = {
  modalState: boolean;
  closeModal: () => void;
};

const NdaHistoryTableView = (props: NdaHistoryTableViewProps) => {
  const { modalState, closeModal } = props;
  const { sessionInfo, fetchArgusFilteringAPI, showModalError } = useVal5KAdminContext();

  // table state

  const [tableState, setTableState] = useRecoilState<RestfulTableDataStateObject>(
    ndaHistoryTableGlobalState
  );
  const { loaded, ...restTableState } = tableState;
  const { itemsCount, itemsPerPage, activePage, filtersQuery } = restTableState;

  // table data source

  const fetchNdaHistoryData = useCallback(
    async (urlQuery = {}, filtersQuery = {}, nextStateUpdates = {}) => {
      try {
        const response: FetchAPIResponse<DjangoPaginatedResponse<NdaHistoryDataObject>> =
          await fetchArgusFilteringAPI(`nda/filtered/`, {
            params: urlQuery,
            data: { ...filtersQuery },
          });

        const nextDataState: Partial<NdaHistoryTableDataStateObject> = transformNdaData(
          response.data,
          nextStateUpdates
        );

        setTableState((prevDataState) => ({
          ...prevDataState,
          ...nextDataState,
          loaded: true,
        }));
        return nextDataState;
      } catch (err: any) {
        logAsyncOperationError("fetchNdaHistoryData", err);
        showModalError(
          "Error occurred while loading NDA history list. Please, try again later."
        );
        throw err;
      }
    },
    [setTableState, fetchArgusFilteringAPI, showModalError]
  );

  const fetchNdaHistoryFilters = useCallback(
    async (urlQuery = {}, filtersQuery = {}) => {
      try {
        const response: FetchAPIResponse<DjangoPaginatedResponse<any>> =
          await fetchArgusFilteringAPI(`nda/values/filtered/`, {
            params: urlQuery,
            data: { ...filtersQuery },
          });
        return response.data;
      } catch (err: any) {
        logAsyncOperationError("fetchNdaHistoryFilterValues", err);
        showModalError(
          "Error occurred while loading filter values. Please, try again later."
        );
        throw err;
      }
    },
    [fetchArgusFilteringAPI, showModalError]
  );

  // handlers

  const handleGetExportQueryArgs = useCallback(
    (exportCurrentPage: boolean = false): UrlQueryObject | null => {
      const queryArgs: UrlQueryObject = {};

      if (sessionInfo.legacySession) {
        queryArgs["x-session-id"] = sessionInfo.legacySession;
      }
      if (filtersQuery.has(djangoOrderingKey)) {
        queryArgs[djangoOrderingKey] = filtersQuery.get(djangoOrderingKey);
      }
      if (exportCurrentPage) {
        queryArgs[djangoPaginationKey] = activePage;
        queryArgs[djangoPaginationSizeKey] = itemsPerPage;
      }

      return Object.keys(queryArgs).length > 0 ? queryArgs : null;
    },
    [activePage, itemsPerPage, filtersQuery, sessionInfo.legacySession]
  );

  const handleGetExportFormData = useCallback(() => {
    return omitObjectKeys(filtersQuery.toJS(), [
      djangoOrderingKey,
      djangoPaginationKey,
      djangoPaginationSizeKey,
    ]);
  }, [filtersQuery]);

  // utility functions

  const refreshNdaHistoryData = useCallback(async () => {
    return fetchNdaHistoryData(
      {
        [djangoPaginationKey]: tableState.activePage,
        [djangoPaginationSizeKey]: tableState.itemsPerPage,
      },
      tableState.filtersQuery.toJS()
    );
  }, [
    tableState.filtersQuery,
    tableState.activePage,
    tableState.itemsPerPage,
    fetchNdaHistoryData,
  ]);

  // initial data fetch

  useInitialFetch(refreshNdaHistoryData);

  // rendering

  const columns = renderTableColumnsSpecs(ndaHistoryTableSpecs);

  if (!loaded) {
    return (
      <Box css={{ minHeight: "200px", position: "relative" }}>
        <TickerContentLoader />
      </Box>
    );
  }

  return (
    <>
      <NdaHistoryTable
        multimode
        rowIdGetter={rowIdGetter}
        bodyEmptyText="No data"
        disablePagination={false}
        dataProvider={fetchNdaHistoryData}
        filtersDataProvider={fetchNdaHistoryFilters}
        {...restTableState}
      >
        {columns}
      </NdaHistoryTable>
      <TableExportConfig
        show={modalState}
        itemsPerPage={itemsPerPage}
        itemsTotal={itemsCount}
        endpoint={"v1/nda/export/"}
        getQueryArgs={handleGetExportQueryArgs}
        getFormData={handleGetExportFormData}
        onClose={closeModal}
      />
    </>
  );
};
NdaHistoryTableView.displayName = "NdaHistoryTableView";

export default NdaHistoryTableView;
