import React from "react";
import { observer } from "mobx-react";
import ToggleButton, { ToggleButtonGroup } from "../../ratecards/components/ToggleButton";

const SectionDisplayOptions = (props) => {
  let store = props.store;
  return (
    <ToggleButtonGroup>
      <ToggleButton
        name="show-options"
        type="radio"
        value={store.SHOW_PAY_RATE_OPTION}
        selected={!store.hidePayRate}
        onClick={store.onHidePayRateChange}
      >
        Pay Rate
      </ToggleButton>
      <ToggleButton
        name="show-options"
        type="radio"
        value={store.SHOW_MARKUP_OPTION}
        selected={!store.hideMarkup}
        onClick={store.onHideMarkupChange}
      >
        Markup
      </ToggleButton>
      <ToggleButton
        name="show-options"
        type="radio"
        value={store.SHOW_BILL_RATE_OPTION}
        selected={!store.hideBillRate}
        onClick={store.onHideBillRateChange}
      >
        Bill Rate
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default observer(SectionDisplayOptions);
