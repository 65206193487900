import React from "react";

import { emptyList } from "../../../../constants";
import { checkMissingRequiredQuestionsFeedback } from "../../utils";
import { useReviewDataContext } from "../../../validator5K_admin/context/ReviewDataContext";
import { useAttemptDataContext } from "../AttemptDataContext";

import type { QuestionsOrderedMap, QuestionsFeedbackOrderedMap } from "../../types";

export type QuestionsFeedbackContextObject = {
  questionsData: QuestionsOrderedMap;
  questionsFeedback: QuestionsFeedbackOrderedMap;
  setQuestionsFeedback: React.Dispatch<React.SetStateAction<QuestionsFeedbackOrderedMap>>;
  hasMissingRequiredQuestionsFeedback: boolean;
};

export const QuestionsFeedbackContext =
  React.createContext<QuestionsFeedbackContextObject>({
    questionsData: emptyList,
    questionsFeedback: emptyList,
    setQuestionsFeedback: undefined,
    hasMissingRequiredQuestionsFeedback: false,
  } as unknown as QuestionsFeedbackContextObject);

export const useQuestionsFeedbackContext = () => {
  return React.useContext(QuestionsFeedbackContext);
};

export const useCreateQuestionsFeedbackContext = (): QuestionsFeedbackContextObject => {
  const { questionsData } = useReviewDataContext();
  const { questionsFeedback: initialQuestionsFeedback } = useAttemptDataContext();

  // state
  const [questionsFeedback, setQuestionsFeedback] =
    React.useState<QuestionsFeedbackOrderedMap>(initialQuestionsFeedback);

  const hasMissingRequiredQuestionsFeedback = React.useMemo<boolean>(
    () => checkMissingRequiredQuestionsFeedback(questionsData, questionsFeedback),
    [questionsFeedback, questionsData]
  );

  return {
    questionsData,
    questionsFeedback,
    setQuestionsFeedback,
    hasMissingRequiredQuestionsFeedback,
  };
};

export default QuestionsFeedbackContext;
