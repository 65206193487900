import React from "react";

import Text from "../../lib/Text";
import { ValuesOrderedMap } from "./ValuesCheckList";

type ValuesListProps = {
  values: ValuesOrderedMap;
};

const ValuesList = (props: ValuesListProps) => {
  const { values } = props;

  return (
    <>
      {values.toArray().map((item, idx) => {
        const { key, value } = item!;

        return (
          <Text nowrap key={key || idx} title={value || ""}>
            {value}
          </Text>
        );
      })}
    </>
  );
};
ValuesList.displayName = "ValuesList";

export default ValuesList;
