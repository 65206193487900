import React from "react";
import Button from "../../../components/lib/Button";
import Box from "../../../components/lib/Box";

const UndoDeleteMessageAlert = (props) => {
  return (
    <Box
      css={{
        display: "inline-flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "$4",
        position: "absolute",
        top: 10,
        left: "50%",
        transform: "translate(-50%)",
        padding: "$4",
        color: "$brandDarker",
        backgroundColor: "$brandLightest",
        border: "1px solid $brandLighter",
        borderRadius: "$md",
        boxShadow: "$md",
        fontSize: "$base",
        lineHeight: "$base",
      }}
      // className="alert alert-success rc-alert floating-alert text-center"
      onClick={(e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
      }}
    >
      {`Level ${props.punchOutRow.levelRomanNumeral}`} has been deleted.
      <Button color="brand" icon="undo" onClick={props.undoDelete}>
        Undo
      </Button>
    </Box>
  );
};

export default UndoDeleteMessageAlert;
