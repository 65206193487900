import React, { useEffect, useState, useCallback, useRef } from "react";
import { Set, fromJS } from "immutable";

import RunningProcessingsAlert, {
  RunningProcessingsAlertProps,
} from "../components/RunningProcessingsAlert";
import AdminContractorEditor from "../components/AdminContractorEditor";
import {
  PARAMS_FOR_BULK_UPDATE,
  PARAMS_FOR_BULK_UPDATE_TYPE,
} from "../components/AdminContractorBulkEditor";
import { NavigationButton, IconButton, Button } from "../../../components/lib/Button";
import { ButtonGroupRight, ButtonGroup } from "../../../components/lib/ButtonGroup";
import Icon from "../../../components/lib/Icon";
import {
  djangoPaginationKey,
  djangoPaginationSizeKey,
  emptyMap,
} from "../../../constants";
import {
  ContractorsTable,
  ContractorsTableProps,
  groupedContractorsTableColumnsSpecs,
  TableConfigChangesObject,
  useEditingRowState,
  useContractorsSelectionHandlers,
  StartRowsSelectionHandler,
  StopRowsSelectionHandler,
} from "../components/ContractorsTable";
import { Column, Group } from "../../../components/tables";
import { FilterTypes } from "../../../components/tables/constants";

import Box from "../../../components/lib/Box";
import Stack from "../../../components/lib/Stack";
import Alert from "../../../components/lib/Alert";
import {
  Card,
  CardBody,
  CardActions,
  CardActionsRight,
  CardActionsLeft,
} from "../../../components/lib/Card";
import { useUploadContractorsTableGlobalState } from "../globalState";
import {
  transformContractorsData,
  contractorsUploadToImmutableMap,
} from "../dataConverters";
import { useIntervalDataRefresh, useScrollTo } from "../hooks";
import { useRefreshRequest } from "../../../utils/hooks";
import { hasAnyProcessingContractorsPredicate } from "../utils";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";
import {
  TickerContentLoader,
  TickerPageLoader,
} from "../../../components/lib/TickerLoader";
import Text from "../../../components/lib/Text";
import { InstructionsCheckMark } from "../lib/CheckMark";
import { CONTRACTORS_TABLE_STATUS_OPTIONS } from "./StoredIndexCreate";
import {
  ALL_CONTRACTOR_STATUSES_VALUES,
  ALL_TABLE_TABS_TYPES,
  ContractorDataObject,
  ContractorsTableDataProvider,
  ContractorsTableFiltersDataProvider,
  ContractorsUploadDataObject,
  CONTRACTOR_STATUSES,
} from "../types";
import { dateTimeFormatter } from "../constants";
import { renderGroupedTableColumnsSpecs } from "../components/ExtendedRestfulTables";
import { BulkRerunContractorsButton } from "../components/BulkRerunContractorsButton";
import BulkDeleteContractorsButton from "../components/BulkDeleteContractorsButton";
import BulkUpdateButton from "../components/BulkUpdateButton";
import PromiseButton from "../../../components/lib/PromiseButton";
import { rowIdGetter } from "../../../components/tables/utils";
import { usePLIContext } from "../context";
import { useProgramContext } from "../ProgramDataProvider";
import {
  selectRowCheckbox,
  selectAllRowsCheckbox,
} from "../components/ContractorsTableSelectCheckbox";

import type { ButtonProps } from "../../../components/lib/Button";
import type { RowEditorComponent } from "../../../components/tables/types";
import type { GroupElement } from "../../../components/tables/Schema";
import type { ContractorsTableViewProps as BaseContractorsTableViewProps } from "./StoredIndexCreate";
import type {
  ContractorsUploadDataMap,
  ContractorsTableDataStateObject,
  ContractorDataMap,
  ProcessingMessagesMap,
  CONTRACTOR_STATUSES_TYPE,
} from "../types";
import type { CommonProgramChildPageProps } from "../ProgramDataProvider";
import type { FetchAPIResponse } from "../../../types/fetch";
import type { DjangoPaginatedResponse } from "../../../types/django";
import type { ImmutableSet } from "../../../types/immutable";

const allowedStatusesForBulkUpdate = Set([
  CONTRACTOR_STATUSES.FAILED,
  CONTRACTOR_STATUSES.NEED_APPROVAL,
  CONTRACTOR_STATUSES.CLEAN,
  CONTRACTOR_STATUSES.FINISHED,
]) as unknown as ImmutableSet<CONTRACTOR_STATUSES_TYPE>;

const allowedParametersForBulkUpdate = Set([
  PARAMS_FOR_BULK_UPDATE.INDUSTRY,
  PARAMS_FOR_BULK_UPDATE.EXP_LEVEL,
  // IMPORTANT: Per Marc - need more manual control on Accepted flag, should be always available
  PARAMS_FOR_BULK_UPDATE.LIMITS_ACCEPTED,
  PARAMS_FOR_BULK_UPDATE.GLOBAL_SUPPLIER_SEARCH,
  // below are all parameters available under specific data/filters conditions
  PARAMS_FOR_BULK_UPDATE.COLLECTION,
  PARAMS_FOR_BULK_UPDATE.DESCRIPTION,
  PARAMS_FOR_BULK_UPDATE.LOCATION,
  PARAMS_FOR_BULK_UPDATE.REGION,
  PARAMS_FOR_BULK_UPDATE.WORKER_TYPE,
]) as any as ImmutableSet<PARAMS_FOR_BULK_UPDATE_TYPE>;

const allowedStatusesForBulkRerun = Set(
  ALL_CONTRACTOR_STATUSES_VALUES
) as unknown as ImmutableSet<CONTRACTOR_STATUSES_TYPE>;

const allowedStatusesForBulkDelete = Set([
  CONTRACTOR_STATUSES.FAILED,
  CONTRACTOR_STATUSES.NEED_APPROVAL,
  CONTRACTOR_STATUSES.FINISHED,
]) as unknown as ImmutableSet<CONTRACTOR_STATUSES_TYPE>;

const UploadContractorsTable = (props: ContractorsTableProps) => (
  <ContractorsTable {...props} />
);
UploadContractorsTable.displayName = "UploadContractorsTable";
UploadContractorsTable.defaultProps = ContractorsTable.defaultProps;
UploadContractorsTable.getTableId = (
  userId: number,
  programId: number,
  uploadId: number
): string =>
  `user-${userId}-program-${programId}-upload-${uploadId}-upload-details-contractors-table`;

type PageHeadBlockProps = {
  onGoToUploadPage: () => void;
  onGoToCreateIndex: () => void;
  uploadData: ContractorsUploadDataMap;
};

const PageHeadBlock = (props: PageHeadBlockProps) => {
  const { onGoToUploadPage, onGoToCreateIndex, uploadData } = props;

  const uploadTitle = uploadData.get("title");
  const uploadTimestamp = uploadData.get("created");
  const isUploadActive = uploadData.get("is_active", false);

  return (
    <Card fill>
      <CardActions css={{ alignItems: "baseline" }}>
        <CardActionsLeft>
          <h2>
            Upload {uploadTitle}
            {isUploadActive ? (
              <Text as="small" color="positive">
                {" "}
                (visible)
              </Text>
            ) : (
              <Text as="small" color="negative">
                {" "}
                (hidden)
              </Text>
            )}
            <small>
              <br />
              created at {dateTimeFormatter(uploadTimestamp)}
            </small>
          </h2>
        </CardActionsLeft>
        <CardActionsRight>
          <ButtonGroupRight>
            <NavigationButton icon="plus" onClick={onGoToCreateIndex}>
              Create View
            </NavigationButton>
            <NavigationButton icon="arrow-left" onClick={onGoToUploadPage}>
              Back To Uploads Page
            </NavigationButton>
          </ButtonGroupRight>
        </CardActionsRight>
      </CardActions>

      <CardBody>
        <h3>Here you can:</h3>
        <h3>
          <InstructionsCheckMark /> Browse upload data.
        </h3>
        <h3>
          <InstructionsCheckMark /> Figure out what happened using failure status and
          messages for a particular row.
        </h3>
        <h3>
          <InstructionsCheckMark /> Enter editor ("Wrench" button) and fix contractor
          data.
        </h3>
        <h3>
          <InstructionsCheckMark /> Resolve the whole upload to make it visible for
          clients.
        </h3>
      </CardBody>
    </Card>
  );
};
PageHeadBlock.displayName = "PageHeadBlock";

type PageBottomBlockProps = {
  onGoToUploadPage: () => void;
};

const PageBottomBlock = (props: PageBottomBlockProps) => {
  const { onGoToUploadPage } = props;

  return (
    <Card css={{ padding: "$4", width: "100%" }}>
      <ButtonGroupRight>
        <NavigationButton icon="arrow-left" onClick={onGoToUploadPage}>
          Back To Uploads Page
        </NavigationButton>
      </ButtonGroupRight>
    </Card>
  );
};
PageBottomBlock.displayName = "PageBottomBlock";

type UploadIsNotFoundAlertProps = {
  onGoToUploadPage: () => void;
};

const UploadIsNotFoundAlert = (props: UploadIsNotFoundAlertProps) => {
  const { onGoToUploadPage } = props;

  return (
    <Alert color="warning">
      <Stack fill css={{ alignItems: "start" }}>
        <h4>Can't find upload data</h4>
        <NavigationButton icon="arrow-left" color="warning" onClick={onGoToUploadPage}>
          Back To Uploads Page
        </NavigationButton>
      </Stack>
    </Alert>
  );
};
UploadIsNotFoundAlert.displayName = "UploadIsNotFound";

type ContractorsTableViewProps = Omit<
  BaseContractorsTableViewProps,
  "onSeeRowValidations" | "onCloseRowValidations" | "checkingValidationsRowId"
> & {
  isUploadActive: boolean;
  processingsRefreshRequestId: number | null;
  onChangeProcessingsNumber: RunningProcessingsAlertProps["onChangeProcessingsNumber"];
};

const ContractorsTableView = (props: ContractorsTableViewProps) => {
  const {
    isUploadActive,
    processingsRefreshRequestId,
    onChangeProcessingsNumber,
    //
    editingRowId,
    contractorsData,
    contractorsDataProvider,
    contractorsFiltersDataProvider,
    onStartRowEditing,
    onCancelRowEditing,
    onApplyRowUpdate,
    onDeleteRow,
    onChangeTableConfig,
    //
    onSelectRow,
    onSelectAllRowsOnThePage,
  } = props;

  const { loaded: isContractorsDataLoaded, isBulkUpdateSelectingRows } = contractorsData;

  const { router, isPTAdmin } = usePLIContext();
  const { programId } = useProgramContext();

  // scroll to table top
  const scrollToRef = useScrollTo([contractorsData.activePage]);

  const renderRowActions = (rowData: ContractorDataMap) => {
    const rowId = rowData.get("id");
    const status = rowData.get("status");
    const isFinished = status === CONTRACTOR_STATUSES.FINISHED;
    const isProcessing = status === CONTRACTOR_STATUSES.PENDING;
    const isEditing = editingRowId != null;
    const isEditingOtherRow = isEditing && editingRowId !== rowId;

    const handleGoToMakeSearchPage = () => {
      router.push(
        `/private-index/programs/${programId}/contractors/${rowId}/market-search`
      );
    };

    return (
      <ButtonGroup css={{ justifyContent: "center" }}>
        <IconButton
          icon="search"
          color="brand"
          variant="outlined"
          title="Show Market Search Detail"
          onClick={() => handleGoToMakeSearchPage()}
          disabled={!isFinished || isEditingOtherRow}
        />
        <IconButton
          icon="wrench"
          color="brand"
          variant="outlined"
          title="Edit mappings"
          onClick={() => onStartRowEditing(rowData)}
          disabled={isProcessing || isEditingOtherRow}
        />
      </ButtonGroup>
    );
  };

  const renderRowStatus = (
    status: CONTRACTOR_STATUSES_TYPE,
    rowData: ContractorDataMap
  ) => {
    const errors = rowData.get("errors") || (emptyMap as ProcessingMessagesMap);
    const warnings = rowData.get("warnings") || (emptyMap as ProcessingMessagesMap);

    if (status === CONTRACTOR_STATUSES.FINISHED) {
      return <Text as={Icon} icon="check" color="positive" />;
    } else if (status === CONTRACTOR_STATUSES.FAILED) {
      if (errors.size || warnings.size) {
        return <Text color="negative">{errors.size + warnings.size} Problems</Text>;
      }
      return <Text color="negative">Failed</Text>;
    } else if (status === CONTRACTOR_STATUSES.NEED_APPROVAL) {
      return <span>Needs Approval</span>;
    } else {
      return <span>Processing...</span>;
    }
  };

  const tableGroups: Array<false | GroupElement<ContractorDataMap>> = [
    isBulkUpdateSelectingRows && (
      <Group key="__actions" uniqueKey="__actions" title="">
        <Column
          uniqueKey="__selecting"
          title={selectAllRowsCheckbox(
            contractorsData.selectedRows,
            contractorsData,
            onSelectAllRowsOnThePage
          )}
          getter={(rowData: ContractorDataMap) =>
            selectRowCheckbox(rowData, contractorsData.selectedRows, onSelectRow)
          }
          fixed
        />
      </Group>
    ),
    !isBulkUpdateSelectingRows && (
      <Group key="__actions" uniqueKey="__actions" title="">
        <Column uniqueKey="__actions" title="Actions" getter={renderRowActions} fixed />
        <Column
          uniqueKey="status"
          title="Status"
          getter={(row) => row.get("status")}
          formatter={renderRowStatus}
          filterType={FilterTypes.ENUMERATION}
          filterOptions={fromJS(CONTRACTORS_TABLE_STATUS_OPTIONS)}
          filterable
          sortable
          fixed
        />
      </Group>
    ),
  ].concat(renderGroupedTableColumnsSpecs(groupedContractorsTableColumnsSpecs));

  return (
    <Stack fill css={{ alignItems: "stretch" }}>
      {isContractorsDataLoaded && (
        <RunningProcessingsAlert
          programId={programId}
          refreshRequestId={processingsRefreshRequestId}
          onChangeProcessingsNumber={onChangeProcessingsNumber}
        />
      )}
      {!isUploadActive && (
        <Alert color="warning">
          <Text italic>
            <Text bold underline>
              IMPORTANT!
            </Text>
            &nbsp; The upload is not visible, and the uploaded data is not visible for the
            client.&nbsp; Click "Make Upload Visible" button in order to make data visible
            for the client.
          </Text>
        </Alert>
      )}
      <Box fill>
        <h5 ref={scrollToRef}>
          <small>* Table contains values in original currency</small>
        </h5>
        <UploadContractorsTable
          isPTAdmin={isPTAdmin}
          rowIdGetter={rowIdGetter}
          editable={isPTAdmin}
          editorImpl={AdminContractorEditor as RowEditorComponent<ContractorDataMap>}
          selectedRowId={editingRowId}
          onEditApply={onApplyRowUpdate}
          onEditCancel={onCancelRowEditing}
          onDeleteRow={onDeleteRow}
          multimode
          actions={ALL_TABLE_TABS_TYPES}
          dataProvider={contractorsDataProvider}
          filtersDataProvider={contractorsFiltersDataProvider}
          onChangeTableConfig={onChangeTableConfig}
          {...contractorsData}
        >
          {tableGroups}
        </UploadContractorsTable>
      </Box>
    </Stack>
  );
};
ContractorsTableView.displayName = "ContractorsTableView";

type MainContentBlockProps = Pick<
  ContractorsTableViewProps,
  | "editingRowId"
  | "contractorsData"
  | "contractorsDataProvider"
  | "contractorsFiltersDataProvider"
  | "processingsRefreshRequestId"
  | "onChangeProcessingsNumber"
  | "onStartRowEditing"
  | "onCancelRowEditing"
  | "onApplyRowUpdate"
  | "onDeleteRow"
  | "onChangeTableConfig"
  | "onSelectRow"
  | "onSelectAllRowsOnThePage"
> & {
  uploadData: ContractorsUploadDataMap;
  onMakeUploadVisible: ButtonProps["onClick"];
  onMakeUploadHidden: ButtonProps["onClick"];
  onRefreshPageData: (withProcessing?: boolean) => Promise<any>;
  onRefreshProcessingsData: () => Promise<any>;
  onStartRowsSelection: StartRowsSelectionHandler;
  onStopRowsSelection: StopRowsSelectionHandler;
};

const MainContentBlock = (props: MainContentBlockProps) => {
  const {
    uploadData,
    editingRowId,
    processingsRefreshRequestId,
    contractorsData,
    contractorsDataProvider,
    contractorsFiltersDataProvider,
    onStartRowEditing,
    onCancelRowEditing,
    onApplyRowUpdate,
    onDeleteRow,
    onMakeUploadVisible,
    onMakeUploadHidden,
    onChangeTableConfig,
    onChangeProcessingsNumber,
    onRefreshPageData,
    onRefreshProcessingsData,
    //
    onStartRowsSelection,
    onStopRowsSelection,
    onSelectRow,
    onSelectAllRowsOnThePage,
  } = props;

  const uploadId = uploadData.get("id");
  const isUploadActive = uploadData.get("is_active", false);
  const isContractorsDataLoaded = contractorsData.loaded;
  const bulkOperationFiltersQuery = React.useMemo(
    () => contractorsData.filtersQuery.set("upload__id", uploadId),
    [contractorsData.filtersQuery, uploadId]
  );

  return (
    <Card css={{ width: "100%" }}>
      <CardActions>
        <CardActionsLeft>
          <h3>Contained Data</h3>
        </CardActionsLeft>
        <CardActionsRight>
          {!isUploadActive && !contractorsData.isBulkUpdateSelectingRows && (
            <PromiseButton
              icon={["far", "thumbs-up"]}
              color="success"
              size="small"
              loadingText="Make Upload Visible"
              onClick={onMakeUploadVisible}
              disabled={!isContractorsDataLoaded}
            >
              Make Upload Visible
            </PromiseButton>
          )}
          {isUploadActive && !contractorsData.isBulkUpdateSelectingRows && (
            <Button
              icon="ban"
              color="danger"
              size="small"
              onClick={onMakeUploadHidden}
              disabled={!isContractorsDataLoaded}
            >
              Hide Upload
            </Button>
          )}
          {!contractorsData.isBulkUpdateSelectingRows && (
            <BulkRerunContractorsButton
              icon="sync"
              size="small"
              loadingText="Rerun Rows"
              disabled={
                !isContractorsDataLoaded ||
                !contractorsData.itemsCount ||
                editingRowId != null
              }
              allowedStatusesForRerun={allowedStatusesForBulkRerun}
              contractorsFiltersQuery={bulkOperationFiltersQuery}
              currentPage={contractorsData.activePage}
              itemsTotal={contractorsData.itemsCount}
              itemsPerPage={contractorsData.itemsPerPage}
              itemsOnCurrentPage={contractorsData.data.size}
              onRefreshIsDone={onRefreshProcessingsData}
            >
              Rerun Rows
            </BulkRerunContractorsButton>
          )}
          <BulkUpdateButton
            selectedRows={contractorsData.selectedRows}
            isBulkUpdateSelectingRows={contractorsData.isBulkUpdateSelectingRows}
            isEditingRow={false}
            isContractorsDataLoaded={isContractorsDataLoaded}
            itemsTotal={contractorsData.itemsCount}
            allowedStatusesForUpdate={allowedStatusesForBulkUpdate}
            allowedParametersForUpdate={allowedParametersForBulkUpdate}
            contractorsFiltersQuery={contractorsData.filtersQuery}
            onStartRowsSelection={() => onStartRowsSelection("isBulkUpdateSelectingRows")}
            onStopRowsSelection={() => onStopRowsSelection("isBulkUpdateSelectingRows")}
            onUpdateIsDone={onRefreshPageData}
          />
          {!contractorsData.isBulkUpdateSelectingRows && (
            <BulkDeleteContractorsButton
              icon={["far", "trash-alt"]}
              color="danger"
              size="small"
              loadingText="Bulk Delete"
              disabled={
                !isContractorsDataLoaded ||
                !contractorsData.itemsCount ||
                editingRowId != null
              }
              allowedStatusesForDelete={allowedStatusesForBulkDelete}
              contractorsFiltersQuery={bulkOperationFiltersQuery}
              onDeleteIsDone={onRefreshPageData}
            >
              Bulk Delete
            </BulkDeleteContractorsButton>
          )}
        </CardActionsRight>
      </CardActions>

      <CardBody>
        {!isContractorsDataLoaded ? (
          <Box css={{ minHeight: "400px", position: "relative" }}>
            <TickerContentLoader />
          </Box>
        ) : (
          <ContractorsTableView
            isUploadActive={isUploadActive}
            editingRowId={editingRowId}
            processingsRefreshRequestId={processingsRefreshRequestId}
            onChangeProcessingsNumber={onChangeProcessingsNumber}
            contractorsData={contractorsData}
            contractorsDataProvider={contractorsDataProvider}
            contractorsFiltersDataProvider={contractorsFiltersDataProvider}
            onApplyRowUpdate={onApplyRowUpdate}
            onDeleteRow={onDeleteRow}
            onStartRowEditing={onStartRowEditing}
            onCancelRowEditing={onCancelRowEditing}
            onChangeTableConfig={onChangeTableConfig}
            onSelectRow={onSelectRow}
            onSelectAllRowsOnThePage={onSelectAllRowsOnThePage}
          />
        )}
      </CardBody>
    </Card>
  );
};
MainContentBlock.displayName = "MainContentBlock";

interface UploadDetailsPageProps extends Omit<CommonProgramChildPageProps, "params"> {
  params: CommonProgramChildPageProps["params"] & { uploadId: string };
}

const UploadDetailsPage = (props: UploadDetailsPageProps) => {
  const {
    router,
    params,
    userId,
    programId,
    fetchM8API,
    fetchM8FilteringAPI,
    showModalError,
    showConfirmationModal,
    closeConfirmationModal,
  } = props;

  const uploadId: number = parseInt("" + params.uploadId, 10);

  // state

  const tableId = UploadContractorsTable.getTableId(userId, programId, uploadId);
  const [contractorsData, setContractorsDataState, resetContractorsDataState] =
    useUploadContractorsTableGlobalState(tableId);
  const [uploadData, setUploadDataState] = useState<ContractorsUploadDataMap>(
    emptyMap as ContractorsUploadDataMap
  );
  const [isUploadDataLoaded, setUploadDataLoadedState] = useState<boolean>(false);

  const uploadTitle = uploadData.get("title");
  const pagesNumber =
    Math.ceil(contractorsData.itemsCount / contractorsData.itemsPerPage) || 1;

  const {
    handleStartRowsSelection,
    handleStopRowsSelection,
    handleSelectRow,
    handleSelectAllRowsOnThePage,
  } = useContractorsSelectionHandlers(
    setContractorsDataState,
    contractorsData.selectedRows,
    contractorsData.data
  );

  // data fetch functions

  const fetchContractorsUploadData = useCallback(
    async (uploadId: number) => {
      try {
        const response: FetchAPIResponse<ContractorsUploadDataObject> = await fetchM8API(
          `uploads/${uploadId}/`,
          {}
        );
        const data: ContractorsUploadDataMap = contractorsUploadToImmutableMap(
          response.data
        );

        setUploadDataState(data);
        setUploadDataLoadedState(true);

        return response.data;
      } catch (err: any) {
        logAsyncOperationError("fetchUploadData", err);
        showModalError("Error occurred while loading upload data");
      }
    },
    [fetchM8API, showModalError]
  );

  const fetchContractorsData: ContractorsTableDataProvider = useCallback(
    async (urlQuery = {}, filtersQuery = {}, nextStateUpdates = {}) => {
      try {
        const response: FetchAPIResponse<DjangoPaginatedResponse<ContractorDataObject>> =
          await fetchM8FilteringAPI(`programs/${programId}/contractors/filtered/`, {
            params: urlQuery,
            data: { upload__id: uploadId, ...filtersQuery },
          });
        const nextDataState: Partial<ContractorsTableDataStateObject> =
          transformContractorsData(response.data, nextStateUpdates);

        setContractorsDataState((prevDataState: ContractorsTableDataStateObject) => ({
          ...prevDataState,
          ...nextDataState,
          loaded: true,
        }));

        return nextDataState;
      } catch (err: any) {
        logAsyncOperationError("fetchContractorsList", err);
        showModalError(
          "Error occurred while loading contractors list for upload. Please, try again later."
        );
        throw err;
      }
    },
    [programId, uploadId, setContractorsDataState, fetchM8FilteringAPI, showModalError]
  );

  const fetchContractorsFiltersData: ContractorsTableFiltersDataProvider = useCallback(
    async (urlQuery = {}, filtersQuery = {}) => {
      try {
        const response: FetchAPIResponse<DjangoPaginatedResponse<any>> =
          await fetchM8FilteringAPI(
            `programs/${programId}/contractors/values/filtered/`,
            {
              params: urlQuery,
              data: { upload__id: uploadId, ...filtersQuery },
            }
          );

        return response.data;
      } catch (err: any) {
        logAsyncOperationError("fetchContractorsListFilterValues", err);
        showModalError(
          "Error occurred while retrieving filter values. Please, try again later."
        );
        throw err;
      }
    },
    [programId, uploadId, fetchM8FilteringAPI, showModalError]
  );

  const updateUpload = useCallback(
    async (updateParams: Partial<ContractorsUploadDataObject> = {}) => {
      try {
        const response: FetchAPIResponse<ContractorsUploadDataObject> = await fetchM8API(
          `uploads/${uploadId}/`,
          {
            data: updateParams,
            method: "patch",
          }
        );
        const data: ContractorsUploadDataMap = contractorsUploadToImmutableMap(
          response.data
        );

        setUploadDataState(data);

        return data;
      } catch (err: any) {
        logAsyncOperationError("updateUploadData", err);
        showModalError(`Error occurred during contractors upload update`);
      }
    },
    [uploadId, setUploadDataState, fetchM8API, showModalError]
  );

  const fetchNotFinishedContractorsCount = useCallback(async () => {
    try {
      const response: FetchAPIResponse<DjangoPaginatedResponse<any>> =
        await fetchM8FilteringAPI(`programs/${programId}/contractors/filtered/`, {
          params: {
            [djangoPaginationKey]: 1,
            [djangoPaginationSizeKey]: 1,
          },
          data: {
            upload__id: uploadId,
            status__in: [
              CONTRACTOR_STATUSES.PENDING,
              CONTRACTOR_STATUSES.FAILED,
              CONTRACTOR_STATUSES.NEED_APPROVAL,
              CONTRACTOR_STATUSES.CLEAN,
            ],
          },
        });

      return response.data.count;
    } catch (err: any) {
      logAsyncOperationError("fetchNotFinishedContractorsNumber", err);
      showModalError(
        "Error occurred while checking the upload for failed contractors. Please, try again later."
      );
    }
  }, [programId, uploadId, fetchM8FilteringAPI, showModalError]);

  // utility functions

  const [processingsRefreshRequestId, refreshProcessingsData] = useRefreshRequest();

  const refreshContractorsData = useCallback(async () => {
    return fetchContractorsData(
      {
        [djangoPaginationKey]: contractorsData.activePage,
        [djangoPaginationSizeKey]: contractorsData.itemsPerPage,
        ordering: contractorsData.filtersQuery.toJS().ordering,
      },
      contractorsData.filtersQuery.toJS()
    );
  }, [
    contractorsData.activePage,
    contractorsData.itemsPerPage,
    contractorsData.filtersQuery,
    fetchContractorsData,
  ]);

  const refreshPageData = useCallback(
    async (withProcessing: boolean = true) => {
      if (withProcessing) {
        refreshProcessingsData();
      }
      return await refreshContractorsData();
    },
    [refreshContractorsData, refreshProcessingsData]
  );

  // effects

  useEffect(() => {
    fetchContractorsUploadData(uploadId);
  }, [fetchContractorsUploadData, uploadId]);

  const contractorsDataLoadedRef = useRef(false);

  useEffect(() => {
    if (!contractorsDataLoadedRef.current) {
      refreshPageData(false);
      contractorsDataLoadedRef.current = true;
    }
  }, [refreshPageData]);

  useIntervalDataRefresh(
    refreshContractorsData,
    contractorsData.data,
    hasAnyProcessingContractorsPredicate
  );

  useEffect(() => resetContractorsDataState, [resetContractorsDataState]);

  // handlers

  const handleGoToCreateIndex = useCallback(
    () => router.push(`/private-index/programs/${programId}/indexes/create`),
    [router, programId]
  );

  const handleGoToUploadContractors = useCallback(
    () => router.push(`/private-index/programs/${programId}/contractors/upload`),
    [router, programId]
  );

  const {
    editingRowId,
    startRowEditing: handleStartRowEditing,
    stopRowEditing: handleCancelRowEditing,
  } = useEditingRowState(refreshPageData);

  const handleApplyRowUpdate = useCallback(
    async () => refreshPageData(),
    [refreshPageData]
  );

  const handleDeleteRow = useCallback(
    () => handleCancelRowEditing(true),
    [handleCancelRowEditing]
  );

  const handleUpdateUpload = useCallback(
    async (updateParams: Partial<ContractorsUploadDataObject>) => {
      await updateUpload(updateParams);
      closeConfirmationModal();
    },
    [updateUpload, closeConfirmationModal]
  );

  const handleMakeUploadVisible = useCallback(async () => {
    const notFinishedRowsCount = await fetchNotFinishedContractorsCount();
    const header = "Confirm Action";
    const message = (
      <Stack fill css={{ alignItems: "stretch" }}>
        {!!notFinishedRowsCount && (
          <Alert color="warning">
            <Text italic>
              <Text bold underline>
                IMPORTANT!
              </Text>
              &nbsp; This upload has {notFinishedRowsCount} rows which are not ready yet.
            </Text>
          </Alert>
        )}
        <Box>
          You are about to make following upload visible for the client.
          <br />
          <b>{`- #${uploadId} ${uploadTitle}`}</b>
          <br />
          <br />
          All finished rows will be visible for the client. Other rows will be hidden
          until you fix them.
          <br />
          Please confirm this action in order to proceed.
          <br />
        </Box>
      </Stack>
    );
    const footer = (
      <ButtonGroupRight fill css={{ flexDirection: "row-reverse" }}>
        <Button size="large" onClick={closeConfirmationModal}>
          No, Cancel
        </Button>
        <PromiseButton
          icon={["far", "thumbs-up"]}
          color="success"
          size="large"
          loadingText="Make Upload Visible"
          onClick={() => handleUpdateUpload({ is_active: true })}
        >
          Yes, Make Upload Visible
        </PromiseButton>
      </ButtonGroupRight>
    );

    await showConfirmationModal(message, header, footer);
  }, [
    uploadId,
    uploadTitle,
    handleUpdateUpload,
    showConfirmationModal,
    closeConfirmationModal,
    fetchNotFinishedContractorsCount,
  ]);

  const handleMakeUploadHidden = useCallback(() => {
    const header = "Confirm hide upload action";
    const message = (
      <div>
        You are about to hide the whole following upload.
        <br />
        <b>{`- #${uploadId} ${uploadTitle}`}</b>
        <br />
        <br />
        All the data contained in the upload will no longer be visible for the client.
        <br />
        Please confirm this action in order to proceed.
        <br />
      </div>
    );
    const footer = (
      <ButtonGroupRight fill css={{ flexDirection: "row-reverse" }}>
        <Button size="large" onClick={closeConfirmationModal}>
          No, Cancel
        </Button>
        <PromiseButton
          color="danger"
          size="large"
          loadingText="Hide Upload"
          onClick={() => handleUpdateUpload({ is_active: false })}
        >
          Yes, Hide Upload
        </PromiseButton>
      </ButtonGroupRight>
    );

    showConfirmationModal(message, header, footer);
  }, [
    uploadId,
    uploadTitle,
    handleUpdateUpload,
    closeConfirmationModal,
    showConfirmationModal,
  ]);

  const handleChangeProcessingsNumber = useCallback(
    () => refreshPageData(false),
    [refreshPageData]
  );

  const handleChangeTableConfig = React.useCallback(
    async (changes: TableConfigChangesObject) => {
      setContractorsDataState((prevState) => ({
        ...prevState,
        ...changes,
      }));
    },
    [setContractorsDataState]
  );

  if (!isUploadDataLoaded) return <TickerPageLoader />;
  else if (!uploadData || !uploadData.size)
    return <UploadIsNotFoundAlert onGoToUploadPage={handleGoToUploadContractors} />;

  return (
    <Stack>
      <PageHeadBlock
        onGoToUploadPage={handleGoToUploadContractors}
        onGoToCreateIndex={handleGoToCreateIndex}
        uploadData={uploadData}
      />
      <MainContentBlock
        uploadData={uploadData}
        contractorsData={contractorsData}
        contractorsDataProvider={fetchContractorsData}
        contractorsFiltersDataProvider={fetchContractorsFiltersData}
        editingRowId={editingRowId}
        processingsRefreshRequestId={processingsRefreshRequestId}
        onStartRowEditing={handleStartRowEditing}
        onCancelRowEditing={handleCancelRowEditing}
        onApplyRowUpdate={handleApplyRowUpdate}
        onDeleteRow={handleDeleteRow}
        onMakeUploadVisible={handleMakeUploadVisible}
        onMakeUploadHidden={handleMakeUploadHidden}
        onChangeTableConfig={handleChangeTableConfig}
        onChangeProcessingsNumber={handleChangeProcessingsNumber}
        onRefreshPageData={refreshPageData}
        onRefreshProcessingsData={refreshProcessingsData}
        onStartRowsSelection={handleStartRowsSelection}
        onStopRowsSelection={handleStopRowsSelection}
        onSelectRow={handleSelectRow}
        onSelectAllRowsOnThePage={handleSelectAllRowsOnThePage}
      />
      {pagesNumber > 1 && (
        <PageBottomBlock onGoToUploadPage={handleGoToUploadContractors} />
      )}
    </Stack>
  );
};
UploadDetailsPage.displayName = "UploadDetailsPage";

export default UploadDetailsPage;
