// @flow
import { extendObservable, action, runInAction } from "mobx";
import { browserHistory } from "react-router";
import { RateCardListComponentStore } from "./RateCardListStore";
import ModalState from "../../models/ModalState";
import NetworkState from "../../models/NetworkState";
import type MobXStore from "./MobXStore";
import type { FetchGraphQL } from "../../App";

type Industry = {
  id: number,
  name: string,
};

export default class ScheduledSearchCreateStore {
  fetchGraphQL: FetchGraphQL;
  store: MobXStore;
  network: NetworkState;
  toggleRateCard: () => void;
  setRateCardCreateValue: () => void;
  rateCardType: ?string;
  rateCardCreateValue: string;
  rateCardExistingValue: string;
  searchType: ?string;
  selectedLocations: {
    locations: Array<any>,
    regions: Array<any>,
  };
  locationHistory: Array<any>;
  selectedGssLocations: Array<any>;
  selectedJobs: Array<any>;
  displayRateCard: () => void;
  rateCardsListStore: RateCardListComponentStore;
  featuredRateCardModal: ModalState;
  selectRateCard: () => void;
  toggleSearchType: () => void;
  cancel: () => void;
  industries: Array<Industry>;
  suggestions: Array<any>;
  handleIndustryChange: () => void;
  resetJobForm: () => void;
  resetLocationForm: () => void;
  addSearch: () => void;
  industriesValid: boolean;
  formValid: boolean;
  addJobValues: () => void;
  displayJobForm: boolean;
  showLocationinput: boolean;
  deleteJobRow: () => void;
  regionTags: Array<any>;
  calculateTags: () => void;
  countriesList: Array<any>;
  remove_duplicates: (Array<Object>) => Array<Object>;
  locations: Array<any>;
  showLocationPopup: boolean;
  showModel: () => void;
  closeModel: () => void;
  cityList: Array<any>;
  regionSuggestions: Array<any>;
  handleRegionChange: () => void;
  showLocationModal: () => void;
  setLocationTags: () => void;
  addLocationSearch: () => void;
  locationsValid: boolean;
  regionValidTags: boolean;
  locationformValid: boolean;
  addLocationValues: () => void;
  // TODO: Change these to nullable strings. Probably easier to write UI that way
  country: string;
  state: string;
  city: string;
  stateID: null;
  countryID: null;
  stateCities: Array<any>;
  countryStates: Array<any>;
  locationId: ?number;
  states: () => void;
  countrySelected: boolean;
  stateSelected: boolean;
  citySelected: boolean;
  saveLocation: () => void;
  onLocationChange: () => void;
  setLocationClick: () => void;
  getStates: () => void;
  selectedCity: () => void;
  getCities: () => void;
  showLocationMappedTable: boolean;
  deleteLocationRow: () => void;
  gssEnabledCountry: String;
  selectGssLocationId: () => void;
  scheduleSearch: () => void;
  clearLocalStorage: () => void;
  clearLocationModal: () => void;
  saveToLocalStorage: () => void;
  loadFromLocalStorage: () => void;
  loadMoreStates: boolean;
  loadMoreCities: boolean;
  scheduleInProgress: boolean;

  constructor(fetchGraphQL: FetchGraphQL, store: MobXStore) {
    this.store = store;
    this.fetchGraphQL = fetchGraphQL;
    this.toggleRateCard = action(this.toggleRateCard.bind(this));
    this.setRateCardCreateValue = action(this.setRateCardCreateValue.bind(this));
    this.displayRateCard = action(this.displayRateCard.bind(this));
    this.selectRateCard = action(this.selectRateCard.bind(this));
    this.toggleSearchType = action(this.toggleSearchType.bind(this));
    this.cancel = action(this.cancel.bind(this));
    this.handleIndustryChange = action(this.handleIndustryChange.bind(this));
    this.resetJobForm = action(this.resetJobForm.bind(this));
    this.resetLocationForm = action(this.resetLocationForm.bind(this));
    this.addSearch = action(this.addSearch.bind(this));
    this.addJobValues = action(this.addJobValues.bind(this));
    this.deleteJobRow = action(this.deleteJobRow.bind(this));
    this.calculateTags = action(this.calculateTags.bind(this));
    this.remove_duplicates = action(this.remove_duplicates.bind(this));
    this.showModel = action(this.showModel.bind(this));
    this.closeModel = action(this.closeModel.bind(this));
    this.handleRegionChange = action(this.handleRegionChange.bind(this));
    this.showLocationModal = action(this.showLocationModal.bind(this));
    this.setLocationTags = action(this.setLocationTags.bind(this));
    this.addLocationSearch = action(this.addLocationSearch.bind(this));
    this.addLocationValues = action(this.addLocationValues.bind(this));
    this.states = action(this.states.bind(this));
    this.saveLocation = action(this.saveLocation.bind(this));
    this.onLocationChange = action(this.onLocationChange.bind(this));
    this.getStates = action(this.getStates.bind(this));
    this.selectedCity = action(this.selectedCity.bind(this));
    this.getCities = action(this.getCities.bind(this));
    this.deleteLocationRow = action(this.deleteLocationRow.bind(this));
    this.selectGssLocationId = action(this.selectGssLocationId.bind(this));
    this.scheduleSearch = action(this.scheduleSearch.bind(this));
    this.setLocationClick = action(this.setLocationClick.bind(this));
    this.clearLocationModal = this.clearLocationModal.bind(this);
    this.clearLocalStorage = action(this.clearLocalStorage.bind(this));
    this.saveToLocalStorage = this.saveToLocalStorage.bind(this);
    this.loadFromLocalStorage = this.loadFromLocalStorage.bind(this);

    extendObservable(this, {
      rateCardType: null,
      rateCardCreateValue: "",
      rateCardExistingValue: "",
      searchType: null,
      selectedLocations: { locations: [], regions: [] },
      locationHistory: [],
      selectedGssLocations: [],
      selectedJobs: [],
      rateCardsListStore: new RateCardListComponentStore(fetchGraphQL),
      featuredRateCardModal: new ModalState(),
      industries: [
        {
          id: 1,
          name: "All",
        },
      ],
      suggestions: [],
      industriesValid: true,
      formValid: false,
      displayJobForm: false,
      showLocationinput: false,
      regionTags: [],
      countriesList: [],
      locations: [],
      showLocationPopup: false,
      cityList: [],
      regionSuggestions: [],
      locationsValid: true,
      regionValidTags: true,
      locationformValid: false,
      countrySelected: false,
      stateSelected: false,
      citySelected: false,
      country: "",
      state: "",
      city: "",
      stateID: null,
      countryID: null,
      stateCities: [],
      countryStates: [],
      locationId: null,
      showLocationMappedTable: false,
      gssEnabledCountry: "India",
      network: new NetworkState(),
      loadMoreStates: false,
      loadMoreCities: false,
      scheduleInProgress: false,
    });
  }

  saveToLocalStorage() {
    const variables = {
      rateCardType: this.rateCardType,
      rateCardCreateValue: this.rateCardCreateValue,
      rateCardExistingValue: this.rateCardExistingValue,
      searchType: this.searchType,
      selectedLocations: this.selectedLocations,
      selectedGssLocations: this.selectedGssLocations,
      selectedJobs: this.selectedJobs,
      displayJobForm: this.displayJobForm,
      showLocationinput: this.showLocationinput,
      showLocationMappedTable: this.showLocationMappedTable,
    };
    localStorage.setItem("scheduled_search", JSON.stringify(variables));
  }

  clearLocalStorage() {
    this.rateCardType = null;
    this.rateCardCreateValue = "";
    this.rateCardExistingValue = "";
    this.searchType = null;
    this.selectedLocations = { locations: [], regions: [] };
    this.selectedGssLocations = [];
    this.selectedJobs = [];
    this.displayJobForm = false;
    this.showLocationinput = false;
    this.showLocationMappedTable = false;
    this.saveToLocalStorage();
  }

  loadFromLocalStorage() {
    const localStorageValue = localStorage.getItem("scheduled_search");
    if (!!localStorageValue) {
      const variables = JSON.parse(localStorageValue);
      if (variables !== null) {
        this.rateCardType = variables.rateCardType || null;
        this.rateCardCreateValue = variables.rateCardCreateValue || "";
        this.rateCardExistingValue = variables.rateCardExistingValue || "";
        this.searchType = variables.searchType || null;
        this.selectedLocations = variables.selectedLocations || {
          locations: [],
          regions: [],
        };
        this.selectedGssLocations = variables.selectedGssLocations || [];
        this.selectedJobs = variables.selectedJobs || [];
        this.displayJobForm = variables.displayJobForm || false;
        this.showLocationinput = variables.showLocationinput || false;
        this.showLocationMappedTable = variables.showLocationMappedTable || false;
      }
    }
  }

  toggleRateCard(value: ?string) {
    this.rateCardType = value;
    this.saveToLocalStorage();
  }

  setRateCardCreateValue(value: ?any) {
    if (value != null) {
      this.rateCardCreateValue = value.target.value;
      this.saveToLocalStorage();
    }
  }

  displayRateCard() {
    this.rateCardsListStore.allowMultipleItemSelection = false;
    this.rateCardsListStore.isEditing = true;
    this.rateCardsListStore.pagination.goFetch();
    this.featuredRateCardModal.showModal();
  }

  selectRateCard() {
    const selectedRateCards = this.rateCardsListStore.getSelectedRateCardName();
    if (!!selectedRateCards) {
      this.rateCardExistingValue = selectedRateCards[0];
      this.saveToLocalStorage();
      this.featuredRateCardModal.hideModal();
    }
  }

  toggleSearchType(value: ?string) {
    this.searchType = value;
    this.displayJobForm = true;
    this.saveToLocalStorage();
  }

  cancel() {
    this.displayJobForm = false;
    this.showLocationinput = false;
    this.showLocationMappedTable = false;
    this.resetJobForm();
    this.resetLocationForm();
    this.clearLocalStorage();
    browserHistory.push("/scheduled_search");
  }

  handleIndustryChange(industries: Array<Industry>) {
    this.industries = industries;
  }

  resetJobForm() {
    let jobStore = this.store.jobLabelListStore;
    jobStore.jobtitle = "";
    jobStore.joblabel = "";
    jobStore.jobdescription = "";
    jobStore.jobtitleid = "";

    this.industries = [
      {
        id: 1,
        name: "All",
      },
    ];
  }

  resetLocationForm() {
    this.regionTags = [];
    this.locations = [];
    this.locationHistory = [];
    this.cityList = [];
    this.countrySelected = false;
    this.stateSelected = false;
    this.country = "";
    this.state = "";
    this.city = "";
    this.stateID = null;
    this.countryID = null;
    this.stateCities = [];
    this.countryStates = [];
    this.locationId = null;
  }

  addSearch() {
    this.industriesValid = !!(this.industries && this.industries.length > 0);

    this.formValid = this.store.jobLabelListStore.jobValidation() && this.industriesValid;

    if (this.formValid) {
      this.addJobValues();
    }
  }

  addJobValues() {
    const selectedJobs = this.selectedJobs;
    const jobStore = this.store.jobLabelListStore;

    this.selectedJobs = this.remove_duplicates(
      selectedJobs.concat(
        this.industries.map((industry) => ({
          jobLabel: jobStore.joblabel,
          jobDescription: jobStore.jobdescription,
          titleId: jobStore.jobtitleid,
          title: jobStore.jobtitle,
          industryId: parseInt(industry.id, 10),
          industryName: industry.name,
        }))
      )
    );
    this.saveToLocalStorage();
    this.resetJobForm();
    this.showLocationinput = true;
  }

  deleteJobRow(industryId: ?number, jobtitleid: ?string) {
    var index = this.selectedJobs.findIndex(
      (job) => job.industryId === industryId && job.titleId === jobtitleid
    );
    this.selectedJobs.splice(index, 1);
    this.saveToLocalStorage();
  }

  remove_duplicates(arr: Array<any>): Array<any> {
    const objectsSeen = {};

    return arr.filter((object) => {
      const jsonObject = JSON.stringify(object);
      if (objectsSeen[jsonObject]) {
        return false;
      }
      objectsSeen[jsonObject] = true;
      return true;
    });
  }

  calculateTags(value): string {
    if (value) {
      return this.remove_duplicates(value)
        .map((location) => location.title)
        .join(" | ");
    }
    return this.remove_duplicates(this.selectedLocations.locations)
      .map((location) => location.title)
      .join(" | ");
  }

  showModel() {
    this.locationHistory = this.locations.slice();
    this.showLocationPopup = true;
  }

  closeModel(locations) {
    // var selectedLocationsNew = this.remove_duplicates(this.locationVal.locations);
    // var newLoc = [];
    // selectedLocationsNew.map(val => {
    //   newLoc.push(val);
    // });
    this.locations = this.locationHistory.slice();
    this.showLocationPopup = false;
  }

  // TODO: Remove?
  handleRegionChange(regions: Array<any>) {
    this.regionTags = regions;
  }

  showLocationModal() {
    // this.setLocationTags();
    this.locationHistory = this.locations.slice();
    this.showLocationPopup = true;
    this.country = "";
    this.state = "";
    this.city = "";
    this.countrySelected = false;
    this.stateSelected = false;
    this.citySelected = false;
  }

  setLocationTags() {
    var locationInput = [];
    this.locations.forEach((location) => {
      var locationTitle =
        (location.city ? location.city + ", " : "") +
        (location.state ? location.state + ", " : "") +
        location.country;
      locationInput.push({
        title: locationTitle,
        country: location.country,
        locationId: parseInt(location.locationId, 10),
        countryId: location.countryId,
        city: location.city ? location.city : "",
        state: location.state ? location.state : "",
      });
    });
    locationInput = locationInput.filter(
      (loc, index, self) =>
        self.findIndex((t) => t.locationId === loc.locationId) === index
    );
    this.locations = locationInput;
    const rateSearchStore = this.store.rateSearchStore;
    rateSearchStore.checkGSS(this.locations, this.regionTags);
    rateSearchStore.checkShowWorkerTypes(
      this.locations,
      this.regionTags,
      rateSearchStore.tags,
      rateSearchStore.searchRateType
    );
  }

  addLocationSearch() {
    this.locationsValid = this.locations && this.locations.length > 0 ? true : false;
    this.regionValidTags = this.regionTags && this.regionTags.length > 0 ? true : false;
    if (this.locationsValid) {
      this.regionValidTags = true;
    } else if (this.regionValidTags) {
      this.locationsValid = true;
    }
    this.locationformValid = this.locationsValid || this.regionValidTags;

    return this.locationformValid;
  }

  addLocationValues() {
    if (this.addLocationSearch()) {
      var locations = [];
      var locregions = [];
      if (this.selectedLocations) {
        var alreadyExistinglocations = this.selectedLocations;
        var loc = alreadyExistinglocations.locations;
        var regions = alreadyExistinglocations.regions;
        for (var i = 0; i < loc.length; i++) {
          locations.push(loc[i]);
        }
        for (var j = 0; j < regions.length; j++) {
          locregions.push(regions[j]);
        }
      }
      for (var k = 0; k < this.locations.length; k++) {
        locations.push(this.locations[k]);
      }
      for (var l = 0; l < this.regionTags.length; l++) {
        locregions.push(this.regionTags[l]);
      }
      locations = locations.filter(
        (loc, index, self) =>
          self.findIndex(
            (t) => parseInt(t.locationId, 10) === parseInt(loc.locationId, 10)
          ) === index
      );
      this.selectedLocations.locations = this.remove_duplicates(locations);
      this.selectedLocations.regions = this.remove_duplicates(locregions);
      this.saveToLocalStorage();
      this.resetLocationForm();
      this.showLocationMappedTable = true;
    }
  }

  states(id, name) {
    this.store.rateSearchStore.states = [];
    this.store.rateSearchStore.cities = [];
    if (this.scrollState) {
      this.scrollState.pageLoaded = 0;
    }
    this.countrySelected = true;
    this.stateSelected = false;
    this.country = name;
    this.locationId = id;
    this.countryID = id;
    this.countryStates = [];
    this.getStates(1);
  }

  cities(id, name) {
    this.store.rateSearchStore.cities = [];
    if (this.scroll) {
      this.scroll.pageLoaded = 0;
    }
    this.stateSelected = true;
    this.citySelected = false;
    this.state = name;
    this.stateID = id;
    this.locationId = id;
    this.loadMoreCities = true;
    this.stateCities = [];
    this.getCities(1);
  }

  saveLocation() {
    this.showLocationPopup = false;
    this.setLocationTags();
    this.locationHistory = this.locations.slice();
  }

  onLocationChange(location) {
    location = location.filter(
      (loc, index, self) =>
        self.findIndex((t) => t.locationId === loc.locationId) === index
    );
    this.locations = location;
  }
  clearLocationModal() {
    this.locations = [];
    this.locationHistory = [];
  }
  setLocationClick(id, name, type, remove = false) {
    let locations = this.locations;
    if (remove) {
      locations = locations.filter((location) => {
        return location.locationId !== id;
      });
    } else {
      if (type === "country") {
        this.country = name;
        locations.push({
          locationId: id,
          countryId: id,
          country: name,
          state: "",
          city: "",
        });
      } else if (type === "state") {
        this.state = name;
        locations.push({
          locationId: id,
          countryId: this.countryID,
          country: this.country,
          state: name,
          city: "",
        });
      } else if (type === "city") {
        locations.push({
          locationId: id,
          countryId: this.countryID,
          country: this.country,
          state: this.state,
          city: name,
        });
      }
    }

    this.locations = this.remove_duplicates(locations);
  }

  getStates(page: any) {
    const rateSearchStore = this.store.rateSearchStore;
    // TODO: Add runInAction ?
    rateSearchStore.statesList(this.countryID, page).then((response) => {
      runInAction("getStates--success", () => {
        if (response) {
          this.countryStates = this.countryStates.concat(response.states);
          this.loadMoreStates = this.countryStates.length < response.totalCount;
        }
      });
    });
  }

  selectedCity(id: number, name: string) {
    this.locationId = id;
    this.city = name;
  }

  getCities(page) {
    const rateSearchStore = this.store.rateSearchStore;
    const that = this;
    rateSearchStore.citiesList(this.stateID, page).then((response) => {
      runInAction("getCities--success", () => {
        if (response) {
          that.stateCities = that.stateCities.concat(response.cities);
          that.loadMoreCities = that.stateCities.length < response.totalCount;
        }
      });
    });
  }

  deleteLocationRow(record: ?number, type: ?string) {
    if (type === "locations") {
      var locindex = this.selectedLocations.locations.findIndex(
        (loc) => loc.locationId === record
      );
      this.selectedLocations.locations.splice(locindex, 1);
    } else if (type === "regions") {
      var regindex = this.selectedLocations.regions.findIndex((reg) => reg.id === record);
      this.selectedLocations.regions.splice(regindex, 1);
    }

    const localStorageValue = localStorage.getItem("scheduled_search");
    if (!!localStorageValue) {
      const variables = JSON.parse(localStorageValue);
      if (
        variables.selectedGssLocations.length > 0 &&
        variables.selectedGssLocations.indexOf(record) !== -1
      ) {
        this.selectGssLocationId(record);
      }
    }

    this.saveToLocalStorage();
  }

  selectGssLocationId(e: ?any) {
    if (!!this.selectedGssLocations) {
      var pos = this.selectedGssLocations.indexOf(e);
      if (pos === -1) {
        this.selectedGssLocations.push(e);
      } else {
        this.selectedGssLocations.splice(pos, 1);
      }
      this.saveToLocalStorage();
    }
  }

  async scheduleSearch() {
    this.scheduleInProgress = true;
    const {
      rateCardType,
      rateCardCreateValue,
      rateCardExistingValue,
      searchType,
      selectedJobs,
      selectedLocations,
      selectedGssLocations,
    } = this;
    let gssLocationIds = [];

    const rateCardValue = rateCardType
      ? rateCardType === "create_new"
        ? rateCardCreateValue
        : rateCardExistingValue
      : "";
    const jobs = selectedJobs;
    const rateTypes = {
      hourly: "Hourly",
      annual: "Annual",
    };

    const locationIds = selectedLocations.locations.map((loc) =>
      parseInt(loc.locationId, 10)
    );
    const regionIds = selectedLocations.regions.map((region) => parseInt(region.id, 10));

    if (selectedGssLocations) {
      gssLocationIds = selectedGssLocations.map((loc) => parseInt(loc, 10));
    }

    // What does this do?
    for (let cnt = 0; cnt < jobs.length; cnt++) {
      delete jobs[cnt].industryName;
      delete jobs[cnt].title;
    }

    const variables = {
      input: {
        scheduledSearchData: {
          rateCardLabel: rateCardValue,
          rateType: rateTypes[searchType],
          jobs: jobs,
          locationIds: locationIds,
          regionIds: regionIds,
          gssLocationIds: gssLocationIds,
        },
      },
    };
    const query = `
      mutation createScheduledSearch($input : CreateScheduledSearchInput!){
        createScheduledSearch(input:$input){
          scheduledSearchUuid
        }
      }
    `;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      console.error("Error in scheduling search", e);
      this.network.loading = false;
      this.network.error = e;
      this.scheduleInProgress = false;
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      this.network.loading = false;
      this.scheduleInProgress = false;
      console.error("Errors", res.errors);
      this.network.error = {
        message: "Error in scheduling search request, please try again later.",
      };

      return;
    }

    runInAction("scheduleSearch--success", () => {
      this.network.error = {
        message: "",
      };
      this.scheduleInProgress = false;
      this.cancel();
    });
  }
}
