// @flow

import R from "ramda";
import { extendObservable, action, runInAction, computed, observable } from "mobx";
import { ObservableMap } from "mobx";
import axios from "axios";
import moment from "moment";
import CurrentUser from "../../models/User";
import { CreatedOnFilter, RateCardLabelFilter } from "../../models/FilterState";
import NetworkState from "../../models/NetworkState";
import PaginationState from "../../models/PaginationState";
import FilterObject, { FILTER_COLUMN } from "../../models/Filter";
import Sort from "../../models/Sort";
import { consolidateAppliedFilters, consolidateAppliedSorts } from "./SupportFunctions";
import type { PageQuery, PaginationInfo } from "../../models/PaginationState";
import type { FilterColumn } from "../../models/Filter";
import Search from "../../models/Search";
import ModalState from "../../models/ModalState";
import type { FetchAPI, FetchGraphQL } from "../../App";
import type MobXStore from "./MobXStore";
import BuyRate from "../../models/BuyRate";
import SessionInfo from "../../models/SessionInfo";

type ViewState = {
  selected: boolean,
  editing: boolean,
  expanded: boolean,
};

export class PunchoutsListComponentStore {
  fetchGraphQL: FetchGraphQL;
  fetchAPI: FetchAPI;
  mobxStore: MobXStore;
  network: NetworkState;
  pagination: PaginationState;
  sessionInfo: ?SessionInfo;

  buyRates: BuyRate[];
  buyRatesViewState: ObservableMap<ViewState>;
  buyRatesView: BuyRate[];
  punchoutCounts: any;

  currentUser: CurrentUser;
  isEditing: ?boolean;
  allOnPageSelected: boolean;
  allSelected: boolean;
  allSelectedfilter: boolean;
  allowMultipleItemSelection: boolean;

  //hasRateTypeFilter: HasRateTypeFilter;
  punchoutLabelFilter: RateCardLabelFilter;
  // createdByFilter: CreatedByFilter;
  // sharedFilter: SharedFilter;
  createdOnFilter: CreatedOnFilter;
  requestFrom: boolean;

  defaultFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedSorts: {
    [key: FilterColumn]: Sort,
  };
  appliedSortsOrder: Array<FilterColumn>;
  isFiltered: boolean;
  hasOwnership: boolean;
  selectedPunchoutId: null;
  unSelectedPunchout: [];

  getPunchouts: (PageQuery) => Promise<PaginationInfo>;
  getPunchoutsList: (PageQuery) => Promise<PaginationInfo>;
  applyDefaultFilter: (FilterColumn, FilterObject) => void;
  handleStartEdit: () => void;
  handleStopEdit: () => void;
  toggleSelectAllPage: (Object) => void;
  selectAllPage: (Event) => void;
  deselectAllPage: (Event) => void;
  toggleAllItems: () => void;
  clearAllSelections: () => void;
  getSelectedPunchouts: () => void;
  getFirstSelectedPunchout: () => Object;
  toDateRange: (Object) => void;
  // getFilterCriteriaQuery: (FilterColumn) => GraphQLQuery;
  // processFilterCriteria: (FilterColumn, Object) => Array < Object > ;
  applyFilter: (FilterColumn, FilterObject) => void;
  removeFilter: (FilterColumn) => void;
  applySort: (FilterColumn, Sort) => void;
  removeSort: (FilterColumn) => void;
  filterpunchouts: () => void;
  clearFilters: () => void;
  applyDefaultSort: () => void;
  //updateRequestFrom: () => void;
  singleSelectedPunchout: (BuyRate) => void;
  toggleExpanded: (BuyRate) => void;
  toggleSelected: (BuyRate, ?boolean) => void;
  allowExpand: boolean;
  expandAllSearches: () => void;
  collapseAllSearches: () => void;
  getUnSelectedPunchouts: () => void;
  //getSelectedPunchouts: () => void;
  confirmDeleteModal: ModalState;
  setAlertModal: ModalState;
  unSelectedPunchouts: number[];
  showSetAlertModal: () => void;
  payRateValues: Object;
  setAlertValues: () => void;
  showUpdateRateBtn: boolean;
  resultLevel: Object;
  selectAllOnPageItem: () => void;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI, mobxStore: MobXStore) {
    this.fetchGraphQL = fetchGraphQL;
    this.fetchAPI = fetchAPI;
    this.mobxStore = mobxStore;
    // NOTE: Bound early to pass into pagination & filter state
    this.getPunchoutsList = action(this.getPunchoutsList.bind(this));
    this.applyFilter = action(this.applyFilter.bind(this));
    this.applySort = action(this.applySort.bind(this));
    this.removeFilter = action(this.removeFilter.bind(this));
    this.removeSort = action(this.removeSort.bind(this));
    this.toDateRange = action(this.toDateRange.bind(this));
    this.unSelectedPunchout = [];
    this.sessionInfo = null;

    extendObservable(this, {
      network: new NetworkState(),
      pagination: new PaginationState(this.getPunchoutsList),
      buyRates: [],
      punchoutCounts: 0,
      hasOwnership: true,
      selectedPunchoutId: null,
      buyRatesViewState: observable.map({}),
      unSelectedPunchouts: [],
      buyRatesView: computed(() => {
        if (!this.buyRates) return [];
        return this.buyRates.map((buyRate) => {
          if (this.buyRatesViewState.has(buyRate.id)) {
            buyRate.viewState = this.buyRatesViewState.get(buyRate.id);
            return buyRate;
          }

          return buyRate;
        });
      }),
      isEditing: null, // we start with null so some view elements be hidden initially
      isFiltered: false,
      allSelected: false,
      allSelectedfilter: false,
      allowMultipleItemSelection: true,
      requestFrom: false,
      selectedCount: computed(() => {
        const selectedValues = this.buyRatesView.map(
          (punchout) => punchout.viewState.selected
        );

        if (this.allSelected) {
          return this.pagination.totalCount;
        }

        let count = 0;

        selectedValues.forEach((v) => {
          if (v) {
            count += 1;
          }
        });

        return count;
      }),
      allOnPageSelected: computed(() => {
        const allTrue = R.all(R.equals(true));
        const selectedValues = this.buyRatesView.map(
          (punchout) => punchout.viewState.selected
        );

        if (selectedValues.length === 0) {
          return false;
        }

        return allTrue(selectedValues);
      }),
      defaultFilters: {},
      appliedFilters: {},
      appliedSorts: {},
      appliedSortsOrder: observable.shallow([]),
      allowExpand: true,
      punchoutLabelFilter: new RateCardLabelFilter(
        this,
        FILTER_COLUMN.RATE_CARD_LABEL,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),

      createdOnFilter: new CreatedOnFilter(
        this,
        FILTER_COLUMN.PUNCHOUTS_DATE_RANGE,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      confirmDeleteModal: new ModalState(),
      setAlertModal: new ModalState(),
      payRateValues: {
        payAvg: null,
        payMid: null,
        payMin: null,
        payMax: null,
      },
      payRateAlertValues: {
        payRateMinAlert: 0,
        payRateMaxAlert: 0,
        punchoutId: null,
      },
      showUpdateRateBtn: true,
      resultLevel: {},
    });

    this.currentUser = null;

    this.applyDefaultFilter = action(this.applyDefaultFilter.bind(this));
    this.handleStartEdit = action(this.handleStartEdit.bind(this));
    this.handleStopEdit = action(this.handleStopEdit.bind(this));
    this.toggleSelectAllPage = action(this.toggleSelectAllPage.bind(this));
    this.selectAllPage = action(this.selectAllPage.bind(this));
    this.deselectAllPage = action(this.deselectAllPage.bind(this));
    this.toggleAllItems = action(this.toggleAllItems.bind(this));
    this.clearAllSelections = action(this.clearAllSelections.bind(this));
    this.getSelectedPunchouts = action(this.getSelectedPunchouts.bind(this));
    this.getFirstSelectedPunchout = action(this.getFirstSelectedPunchout.bind(this));

    this.filterpunchouts = action(this.filterpunchouts.bind(this));
    this.clearFilters = action(this.clearFilters.bind(this));
    this.applyDefaultSort = action(this.applyDefaultSort.bind(this));
    // this.updateRequestFrom = action(this.updateRequestFrom.bind(this));
    this.singleSelectedPunchout = action(this.singleSelectedPunchout.bind(this));
    this.toggleExpanded = action(this.toggleExpanded.bind(this));
    this.toggleSelected = action(this.toggleSelected.bind(this));
    this.expandAllSearches = action(this.expandAllSearches.bind(this));
    this.collapseAllSearches = action(this.collapseAllSearches.bind(this));
    this.getUnSelectedPunchouts = action(this.getUnSelectedPunchouts.bind(this));
    this.showSetAlertModal = action(this.showSetAlertModal.bind(this));
    this.setAlertValues = action(this.setAlertValues.bind(this));
    this.selectAllOnPageItem = action(this.selectAllOnPageItem.bind(this));

    this.applyDefaultSort();
  }

  singleSelectedPunchout(buyRate: BuyRate) {
    let viewState = this.buyRatesViewState.get(buyRate.id).selected;

    // if (!viewState) {
    //   this.selectedRateCardId = buyRate.id;
    // } else {
    //   this.selectedRateCardId = null;
    // }

    if (!viewState && buyRate.createdBy && buyRate.createdBy.userId) {
      this.hasOwnership =
        String(buyRate.createdBy.userId) === String(this.currentUser.userId);
    } else {
      this.hasOwnership = true;
    }
  }

  toDateRange(date: Object) {
    let val = true;
    if (this.createdOnFilter.fromDate) {
      val = !date.isBetween(
        this.createdOnFilter.fromDate,
        moment(new Date()),
        "days",
        "[]"
      );
    }
    return val;
  }

  applyDefaultSort() {
    // this.createdOnFilter.sortState.direction = SORT_DIRECTION.DESC;
    // this.createdOnFilter.sort = this.createdOnFilter.buildQuerySort();
    // this.applySort(this.createdOnFilter.column, this.createdOnFilter.sort);
  }

  handleStartEdit() {
    this.isEditing = true;
    this.allowExpand = false;
    this.buyRatesView.forEach((punchout) => punchout.toggleEdit());
  }

  handleStopEdit() {
    this.isEditing = false;
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.allowExpand = true;
    this.buyRatesViewState.forEach((viewState) => {
      viewState.selected = false;
      viewState.editing = false;
    });
  }

  toggleSelectAllPage(e: Object) {
    if (!this.allowMultipleItemSelection) return;

    const setValue = !this.allOnPageSelected;

    this.buyRatesView.forEach((punchout) => {
      this.toggleSelected(punchout, setValue);
    });

    // When All items selected flag is up, clear selection
    if (setValue === false && this.allSelected) this.allSelected = false;
  }

  selectAllPage(e: Event) {
    this.unSelectedPunchouts = [];
    this.allSelected = true;
    this.allSelectedfilter = true;
  }

  selectAllOnPageItem(e: Event) {
    this.buyRatesView.forEach((punchout) => {
      this.toggleSelected(punchout, true);
    });
  }

  deselectAllPage(e: Event) {
    this.buyRatesView.forEach((punchout) => {
      this.toggleSelected(punchout, false);
    });

    this.allSelected = false;
    this.allSelectedfilter = false;
  }

  toggleAllItems() {
    if (!this.allowMultipleItemSelection) return;

    this.allSelected = !this.allSelected;

    if (this.allSelected === false) {
      this.buyRatesViewState.forEach((value) => {
        value.selected = false;
      });
    }
  }

  clearAllSelections() {
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.buyRatesViewState.forEach((value) => {
      value.selected = false;
    });
  }

  applyFilter(column: FilterColumn, filter: FilterObject) {
    this.appliedFilters[column] = filter;
    this.isFiltered = true;
  }

  applyDefaultFilter(column: FilterColumn, filter: FilterObject) {
    this.defaultFilters[column] = filter;
  }

  removeFilter(column: FilterColumn) {
    delete this.appliedFilters[column];

    let entries = Object.entries(this.appliedFilters);
    if (!entries.length) this.isFiltered = false;
  }

  applySort(column: FilterColumn, sort: Sort) {
    this.appliedSorts[column] = sort;

    const index = this.appliedSortsOrder.indexOf(column);
    if (index === -1) this.appliedSortsOrder.push(column);
  }

  removeSort(column: FilterColumn) {
    delete this.appliedSorts[column];

    const index = this.appliedSortsOrder.indexOf(column);
    if (index > -1) this.appliedSortsOrder.splice(index, 1);
  }

  clearFilters() {
    this.punchoutLabelFilter = new RateCardLabelFilter(
      this,
      FILTER_COLUMN.RATE_CARD_LABEL,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.createdOnFilter = new CreatedOnFilter(
      this,
      FILTER_COLUMN.PUNCHOUTS_DATE_RANGE,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );

    this.appliedFilters = observable({});
    this.appliedSorts = observable({});
    this.appliedSortsOrder.length = 0;
    this.isFiltered = false;
    this.applyDefaultSort();

    return this.pagination.goFetch(null);
  }

  filterpunchouts() {
    return this.pagination.goFetch(null);
  }

  showSetAlertModal(resultLevel) {
    // this.payRateValues.payAvg = resultLevel.payRateAvg;
    // this.payRateValues.payMid = resultLevel.payRateMid;
    // this.payRateValues.payMin = resultLevel.payRateMin;
    // this.payRateValues.payMax = resultLevel.payRateMax;
    //
    // this.payRateAlertValues.payRateAvgAlert = resultLevel.payRateAvgAlert || 0;
    // this.payRateAlertValues.payRateMinAlert = resultLevel.payRateMinAlert || 0;
    // this.payRateAlertValues.payRateMaxAlert = resultLevel.payRateMaxAlert || 0;
    // this.payRateAlertValues.payRateMidAlert = resultLevel.payRateMidAlert || 0;
    //
    // this.resultLevel = resultLevel;
    //
    // this.payRateAlertValues.punchoutId = resultLevel.punchoutId;
    // this.setAlertModal.showModal();
  }

  setAlertValues(value, paytype) {
    // this.payRateAlertValues[paytype] = value;
  }

  getSelectedPunchouts() {
    const punchouts = this.buyRatesViewState;

    let selectedpunchouts = [];

    punchouts.forEach((value, key) => {
      if (value.selected) {
        selectedpunchouts.push(parseInt(key, 10));
      }
    });

    return selectedpunchouts;
  }

  getFirstSelectedPunchout() {
    for (let j = 0; j < this.buyRatesView.length; j++) {
      if (this.buyRates[j].viewState.selected) return this.buyRates[j];
    }

    return null;
  }

  toggleExpanded(buyRate: BuyRate) {
    buyRate.viewState.expanded = !buyRate.viewState.expanded;
    if (buyRate.viewState.expanded) {
      //search.setCurrencyType(this.currencyType);
      // buyRate.getSearchResults();
    }
  }

  toggleSelected(buyRate: BuyRate, setValue: ?boolean = null) {
    const viewState = this.buyRatesViewState.get(buyRate.id);

    //viewState.selected = !viewState.selected;

    if (setValue !== null) {
      viewState.selected = setValue;
      return;
    } else {
      viewState.selected = !viewState.selected;

      if (!viewState.selected && this.allSelected) {
        this.allSelected = false;
      }
    }

    if (viewState.selected === false) {
      this.unSelectedPunchouts.push(buyRate.id);
    } else {
      for (let i = 0; i < this.unSelectedPunchouts.length; i++) {
        if (this.unSelectedPunchouts[i] === buyRate.id) {
          this.unSelectedPunchouts.splice(i, 1);
        }
      }
    }

    if (!this.allowMultipleItemSelection) {
      // deselect all other rate cards
      this.buyRatesViewState.forEach((currentViewState) => {
        if (currentViewState === viewState) return;

        currentViewState.selected = false;
      });
    }
  }

  getUnSelectedPunchouts() {
    const punchouts = this.buyRatesViewState;

    punchouts.forEach((value, key) => {
      if (!value.selected) {
        this.unSelectedPunchouts.push(parseInt(key));
      }
    });

    return this.unSelectedPunchouts;
  }

  // getSelectedPunchouts() {
  //   const punchouts = this.punchoutsViewState;

  //   let selectedSearches = [];
  //  // this.exportRateCardModal.exportOptions.levelType = LEVELS_TYPE.DEFAULT;

  //   punchouts.forEach((value, key) => {
  //     if (value.selected) {
  //       debugger
  //       selectedSearches.push(key);
  //     }
  //   });

  //   return selectedSearches;
  // }

  expandAllSearches() {
    // this.buyRates.forEach((buyRate: BuyRate) => {
    //   buyRate.viewState.expanded = true;
    //   buyRate.setCurrencyType(this.currencyType);
    //   buyRate.getSearchResults();
    // });
  }

  collapseAllSearches() {
    this.buyRates.forEach((buyRate: BuyRate) => (buyRate.viewState.expanded = false));
  }

  async getPunchoutsList(pageQuery: PageQuery): Promise<PaginationInfo> {
    let params: string[] = pageQuery.params;
    let args = pageQuery.args;
    let variables = pageQuery.variables;
    let filtersCriteria: string[] = [];

    let sortCriteria: string[] = [];
    consolidateAppliedSorts(this.appliedSorts, sortCriteria);

    consolidateAppliedFilters(this.appliedFilters, params, filtersCriteria, variables);

    // NOTE: applied filters could override default filters, handle this if needed
    consolidateAppliedFilters(this.defaultFilters, params, filtersCriteria, variables);

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");
    const queryFiltersCriteria = filtersCriteria.join(", ");
    const querySortCriteria = sortCriteria.join(", ");

    const query = `
    query buyrates(${queryParams}) {
      viewer {
        buyrates(${queryArgs},filters: { ${queryFiltersCriteria} }, order: [${querySortCriteria}]) {
          totalCount
          pageInfo {
            startCursor
            endCursor
          }
          edges {
            node {
              buyrateId
              name
              jobLabel
              jobTitle
              created
              createdBy {
                firstName
                lastName
                email
                userId
              }
              punchouts {
                punchoutId
                levelId
                levelName
                levelRomanNumeral
                payRateMin
                payRateMax
                markupPct
                billRateMin
                billRateMax
                salaryMin
                salaryMax
                created
                modified
              }              
              search {
                searchId
                rateType
                country
                state
                city
                region {
                  name
                  country {
                    name
                    isoCode
                  }
                }
                industry {
                  legacyId
                  value
                }
                currency {              
                  legacyId
                  symbol
                }
                job {
                  id
                  jobLabel
                  jobTitle
                }
                marketrates {
                  created
                  modified
                  payRateMin
                  payRateMax
                  billRateMin
                  billRateMax
                  markupPctMin
                  markupPctMax
                  level {
                    legacyId
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting Rate Cards", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Rate Card query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getPunchoutsList--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get Rate Cards query", res)) {
        // TODO: Display user friendly error message
        return {
          totalCount: 0,
        };
      }

      if (!res) return { totalCount: 0 };

      const buyrates = res.data.viewer.buyrates.edges;
      this.punchoutCounts = res.data.viewer.buyrates.totalCount;

      this.buyRates = buyrates.map((item) => {
        const buyRate = new BuyRate(item.node);
        buyRate.search = new Search({}, item.node.search);

        if (!this.buyRatesViewState.has(buyRate.id)) {
          this.buyRatesViewState.set(buyRate.id, {
            selected: this.allSelected,
            editing: this.isEditing,
            expanded: false,
          });
        } else {
          const selectedValue = this.allSelected
            ? true
            : this.buyRatesViewState.get(buyRate.id).selected;

          this.buyRatesViewState.set(buyRate.id, {
            selected: selectedValue,
            editing: this.isEditing,
            expanded: false,
          });
        }

        buyRate.viewState = this.buyRatesViewState.get(buyRate.id);

        return buyRate;
      });

      return {
        totalCount: res.data.viewer.buyrates.totalCount,
      };
    });
  }
}

export default class PunchoutsListStore extends PunchoutsListComponentStore {
  deletePunchouts: () => void;
  setPunchoutAlert: () => void;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI, mobxStore: MobXStore) {
    super(fetchGraphQL, fetchAPI, mobxStore);

    // Bound early to pass into export state
    this.deletePunchouts = action(this.deletePunchouts.bind(this));
    this.setPunchoutAlert = action(this.setPunchoutAlert.bind(this));
  }

  async deletePunchouts() {
    if (this.network.loading) {
      return;
    }

    let params = [];
    let vars = {};
    let queryArgs = [];
    let filterargs = [];

    if (this.allSelectedfilter) {
      consolidateAppliedFilters(this.appliedFilters, params, queryArgs, vars);
      params.push("$exclude: [Int]!");
      filterargs.push("exclude: $exclude");
      vars.exclude = this.unSelectedPunchouts;
    } else {
      const selectedPunchouts = this.getSelectedPunchouts();
      if (!selectedPunchouts || !selectedPunchouts.length) {
        console.error("Cannot delete Punthcout: No punchouts selected");
        return;
      }
      params.push("$only: [Int]!");
      filterargs.push("only: $only");
      vars.only = selectedPunchouts;
    }
    //consolidateAppliedFilters(this.appliedFilters, params, queryArgs, vars);
    if (this.punchoutLabelFilter.textToLookFor) {
      params.push("$search: String");
      filterargs.push("search: $search");
      vars.search = this.punchoutLabelFilter.textToLookFor;
    }

    const queryParams = params.join(", ");
    //queryArgs = args.join(', ');
    const query = `
      mutation deleteBuyrates(${queryParams}){
       deleteBuyrates(input:{${filterargs.join(", ")},filters: { ${queryArgs.join(
      ", "
    )}}}){
         ok
       }
      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Delete searches on Rate card", e);
      // TODO: Display user friendly error message
      return;
    }
    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }
    this.handleStopEdit();
    this.confirmDeleteModal.hideModal();

    runInAction("deletePunchouts--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("deletePunchouts", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.pagination.goFetch(null);
    });
  }

  async setPunchoutAlert() {
    // if (this.network.loading) {
    //   return;
    // }
    //
    // let params = [];
    // let args = [];
    // let input = {};
    //
    // let tempObj = Object.keys(this.payRateAlertValues);
    // for (let i in tempObj) {
    //   if (tempObj[i] === "punchoutId") params.push(`$${tempObj[i]} : ID!`);
    //   else params.push(`$${tempObj[i]} : Float!`);
    //   args.push(`${tempObj[i]}: $${tempObj[i]}`);
    //   if (!this.payRateAlertValues[tempObj[i]]) {
    //     input[tempObj[i]] = 0;
    //   } else {
    //     input[tempObj[i]] = this.payRateAlertValues[tempObj[i]];
    //   }
    // }
    // const queryParams = params.join(", ");
    // //onst queryArgs = args.join(', ');
    //
    // const query = `
    //   mutation setPunchoutAlerts(${queryParams}){
    //    setPunchoutAlerts(input:{${args}}){
    //      punchout{
    //       punchoutId
    //      }
    //    }
    //   }
    // `;
    //
    // //this.network.loading = true;
    // let res = null;
    //
    // try {
    //   res = await this.fetchGraphQL(query, input);
    // } catch (e) {
    //   this.network.handleError("setPunchoutAlert", e);
    //   // TODO: Display user friendly error message
    //   return;
    // }
    // if (res.errors) {
    //   console.error("Errors", res.errors);
    //   this.network.loading = false;
    //   return;
    // }
    // this.handleStopEdit();
    // this.confirmDeleteModal.hideModal();
    // /**
    //  ** Update latest changes in resultlevel object
    //  **/
    // for (let i in tempObj) {
    //   this.resultLevel[tempObj[i]] = this.payRateAlertValues[tempObj[i]];
    // }
    //
    // runInAction("setPunchoutAlert--success", () => {
    //   this.network.loading = false;
    //   this.network.error = null;
    //   if (this.network.logGraphQLError("setPunchoutAlert", res)) {
    //     // TODO: Display user friendly error message
    //     return;
    //   }
    //
    //   //this.paginationList.goFetch(null);
    // });
  }
}
