// @flow

import React, { Component } from "react";
import type { Element } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import { action } from "mobx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../components/lib/DropdownMenu";
import RawJobTitle from "../../models/RawJobTitle";
import NonIdealState from "./components/NonIdealState";
import FlagIcon from "../../components/FlagIcon";
import JobLibraryTitle, { LibraryTitleLatestSearch } from "../../models/JobLibraryTitle";
import JobLibraryTitleListStore, {
  DisplayOption,
  EnumItem,
} from "../../stores/mobx/JobLibraryTitleListStore";
import CCCCountry from "../../models/CCCCountry";
import numeral from "numeral";
import { Link } from "../../components/lib/Link";
import { styled } from "../../../src/stitches.config";
import Button from "../../components/lib/Button";
import RsSkillsWithJobDescription from "../ratesearch2/JobTittleSkills";
import { getSkillFeatureFlag } from "../ratesearch2/rsSearchBySkillsUtil.ts";

const CertifiedCountry = styled("span", {
  background: "rgba(0,129,72,.1)",
  color: "#008148",
  margin: "3px !important",
  minWidth: "auto",
  display: "inline-block",
  border: "none",
  padding: "5px 6px !important",
  borderRadius: "3px",
  fontWeight: "bold",
  fontSize: "12px",
  textAlign: "center",
});

const EllipsisButton = styled(Button, {
  border: "none !important",
  fontSize: "22px !important",
  color: "#888888 !important",
  background: "transparent",
  "&:hover": {
    backgroundColor: "transparent !important",
  },
  "&:active": {
    backgroundColor: "transparent !important",
  },
  "&:focus": {
    backgroundColor: "transparent !important",
  },
});

const DropArrow = styled({
  "& svg.expanded": {
    color: "$brandLight !important",
  },
  "& svg.fa-play:hover": {
    color: "$brandLight !important",
  },
});

function getPlaceHolder(withActions, showCountries) {
  return {
    // status: <div className="cjl-job-title-status ad-hoc" style={{ height: 27, width: 49 }} />,
    clientTitleItem: null,
    titleId: <div className="loading-gradient" style={{ height: 25, width: 60 }} />,
    clientTitle: (
      <div className="loading-gradient" style={{ height: 25, width: "100%" }} />
    ),
    mappedTitle: <div className="loading-gradient" style={{ height: 19, width: 250 }} />,
    showCountries: showCountries,
    countries: null,
    created: <div className="loading-gradient" style={{ height: 25, width: 80 }} />,
    actions: withActions ? (
      <div className="loading-gradient" style={{ height: 31, width: 100 }} />
    ) : null,
    selected: false,
    loading: false,
    countryNames: [],
  };
}

type RowProps = {
  clientRawJobTitle: RawJobTitle,
  clientTitle: Element<any>,
  mappedTitle: Element<any>,
  description: ?string,
  showCountries: boolean,
  countries: ?Element<any> | ?(Element<any>[]),
  created: Element<any>,
  actions?: ?any,
  loading?: boolean,
  selected?: boolean,
  expandable?: boolean,
  expanded?: boolean,
  collapsed?: boolean,
  onRequestCountry?: (JobLibraryTitle) => void,
  onExpand?: () => void,
  onViewRateCards?: (JobLibraryTitle) => void,
  onViewRateSearch?: (JobLibraryTitle) => void,
  adHocCountries: number,
  certifiedCountries: CCCCountry[],
  adHocCountries: CCCCountry[],
  showLatestSearch: boolean,
  latestSearch: ?LibraryTitleLatestSearch,
  displayRateOption: EnumItem,
  displayAggregationOption: EnumItem,
  aggregationMultiplier: number,
  onDeleteTitle?: () => void,
  onAddCountry?: () => void,
  onRemoveCountry?: () => void,
  hasJobLibrarySubscription?: boolean,
  canBeDeleted?: boolean,
};

const TableRow = observer(
  class extends Component<RowProps> {
    props: RowProps;

    static defaultProps = {
      showCountries: true,
    };

    handleRequestCountry = () => {
      if (this.props.onRequestCountry) {
        this.props.onRequestCountry(this.props.clientRawJobTitle);
      }
    };

    handleListItemClick = () => {
      if (this.props.expandable && this.props.onExpand) {
        this.props.onExpand();
      }
    };

    handleViewRateCards = () => {
      if (this.props.onViewRateCards) {
        this.props.onViewRateCards(this.props.clientRawJobTitle);
      }
    };

    handleViewRateSearch = () => {
      if (this.props.onViewRateSearch) {
        this.props.onViewRateSearch(this.props.clientRawJobTitle);
      }
    };

    handleDeleteTitle = () => {
      if (this.props.onDeleteTitle) {
        this.props.onDeleteTitle();
      }
    };

    handleAddCountry = () => {
      if (this.props.onAddCountry) {
        this.props.onAddCountry();
      }
    };

    handleRemoveCountry = () => {
      if (this.props.onRemoveCountry) {
        this.props.onRemoveCountry();
      }
    };

    render() {
      const props = this.props;
      const classes = classNames("cjl-flex-table row list-item", {
        selected: props.selected,
        expandable: props.expandable,
        expanded: props.expanded,
      });

      const collapsibleContentClasses = classNames("collapsible-content", {
        collapsed: !props.expanded,
        expanded: props.expanded,
      });

      const {
        showLatestSearch,
        latestSearch,
        displayRateOption,
        displayAggregationOption,
        aggregationMultiplier,
      } = props;
      const hourly = latestSearch && latestSearch.rateType === 1;
      const showBillRates =
        hourly && displayRateOption.value === DisplayOption.RATES.BILL_RATE.value;
      const emptyRate = { payRateMin: 0, billRateMin: 0, payRateMax: 0, billRateMax: 0 };
      const minLevel = latestSearch ? latestSearch.rates[0] : emptyRate;
      const maxLevel = latestSearch
        ? latestSearch.rates[latestSearch.rates.length - 1]
        : emptyRate;
      const payRateMin = hourly
        ? (minLevel.payRateMin || 0) * aggregationMultiplier
        : minLevel.payRateMin;
      const billRateMin = hourly
        ? (minLevel.billRateMin || 0) * aggregationMultiplier
        : minLevel.billRateMin;
      const payRateMax = hourly
        ? (maxLevel.payRateMax || 0) * aggregationMultiplier
        : maxLevel.payRateMax;
      const billRateMax = hourly
        ? (maxLevel.billRateMax || 0) * aggregationMultiplier
        : maxLevel.billRateMax;
      const minRate = showBillRates ? billRateMin : payRateMin;
      const maxRate = showBillRates ? billRateMax : payRateMax;

      let rateUnit = "per year";
      if (
        hourly &&
        displayAggregationOption.value === DisplayOption.AGGREGATION.HOURLY.value
      ) {
        rateUnit = "per hour";
      } else if (
        hourly &&
        displayAggregationOption.value === DisplayOption.AGGREGATION.DAILY.value
      ) {
        rateUnit = "per day";
      } else if (
        hourly &&
        displayAggregationOption.value === DisplayOption.AGGREGATION.WEEKLY.value
      ) {
        rateUnit = "per week";
      } else if (
        hourly &&
        displayAggregationOption.value === DisplayOption.AGGREGATION.MONTHLY.value
      ) {
        rateUnit = "per month";
      }

      const caretClasses = classNames({ expanded: props.expanded });
      return (
        <div className="cjl-flex-table row">
          <div className="cjl-flex-table">
            <div className={classes}>
              <div
                className="column auto cjl-caret-col"
                onClick={this.handleListItemClick}
              >
                <DropArrow>
                  <FontAwesomeIcon icon="play" className={caretClasses} />
                </DropArrow>
              </div>
              <div
                className="column auto cjl-job-title-id-col"
                onClick={this.handleListItemClick}
              >
                {props.titleId}
              </div>
              <div
                className="column cjl-job-title-col"
                onClick={this.handleListItemClick}
              >
                <div
                  className="client-title"
                  title="Job Label"
                  data-testid="job-library-job-row"
                >
                  {props.clientTitle}
                </div>
                <div className="mapped-title" title="Market Job Title">
                  {props.mappedTitle}
                </div>
              </div>
              {showLatestSearch && latestSearch && (
                <div
                  className="column auto cjl-latest-search-col"
                  onClick={this.handleListItemClick}
                >
                  <div className="cjl-latest-search-range" dir="ltr">
                    <span className="cjl-search-rate">
                      {latestSearch.currencySymbol}
                      {numeral(minRate).format("0,0.00")}
                    </span>{" "}
                    {/*<span>&perp;</span> <span>{latestSearch.rateType === 0 ? "$/YEAR" : "$/HOUR"}</span>{" "}*/}
                    {/*<span>&perp;</span>{" "}*/}
                    <span>&mdash;</span>{" "}
                    <span className="cjl-search-rate">
                      {latestSearch.currencySymbol}
                      {numeral(maxRate).format("0,0.00")}
                    </span>
                  </div>
                  <div
                    className="cjl-item-subtitle"
                    style={{ width: "100%", textAlign: "right" }}
                  >
                    {rateUnit}
                  </div>
                </div>
              )}
              {props.showCountries && (
                <div
                  className="column auto cjl-countries-col"
                  onClick={this.handleListItemClick}
                >
                  <div className="flex">{props.countries}</div>
                </div>
              )}
              {!showLatestSearch && (
                <div
                  className="column auto cjl-date-col"
                  onClick={this.handleListItemClick}
                >
                  {props.created}
                </div>
              )}
              {props.actions && (
                <div
                  className="column auto cjl-job-title-actions-col"
                  onClick={this.handleListItemClick}
                >
                  <div className="cjl-button-bar">{props.actions}</div>
                </div>
              )}
              <div className="column auto">
                <DropdownMenu id="dropdown-no-caret">
                  <DropdownMenuTrigger asChild>
                    <EllipsisButton
                      icon="ellipsis-v"
                      style={{ backgroundColor: "transparent" }}
                    />
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuItem eventkey="1" onSelect={this.handleViewRateCards}>
                      <span>
                        <FontAwesomeIcon icon="share" fixedWidth /> View Rate Cards
                      </span>
                    </DropdownMenuItem>
                    <DropdownMenuItem eventkey="3" onSelect={this.handleViewRateSearch}>
                      <span>
                        <FontAwesomeIcon
                          icon="square"
                          fixedWidth
                          style={{ visibility: "hidden" }}
                        />{" "}
                        Rate Search
                      </span>
                    </DropdownMenuItem>
                    {!props.hasJobLibrarySubscription && (
                      <DropdownMenuItem eventkey="2" onSelect={this.handleRequestCountry}>
                        <span>
                          <FontAwesomeIcon
                            icon="square"
                            fixedWidth
                            style={{ visibility: "hidden" }}
                          />{" "}
                          Request a Country
                        </span>
                      </DropdownMenuItem>
                    )}
                    {props.hasJobLibrarySubscription &&
                      // TODO: Uncomment when store wizard code has been ported
                      // <MenuItem eventKey="3" onSelect={this.handleAddCountry}>
                      //   <span className="cjl-detail-menu-item">
                      //     <FontAwesomeIcon icon="plus" /> Add Countries
                      //   </span>
                      // </MenuItem>
                      null}
                    {props.hasJobLibrarySubscription &&
                      // TODO: Uncomment when store wizard code has been ported
                      // <MenuItem eventKey="3" onSelect={this.handleRemoveCountry}>
                      //   <span className="cjl-detail-menu-item">
                      //     <FontAwesomeIcon icon="minus" /> Remove Countries
                      //   </span>
                      // </MenuItem>
                      null}
                    {props.canBeDeleted && (
                      <DropdownMenuItem eventkey="4" onSelect={this.handleDeleteTitle}>
                        <span className="cjl-detail-menu-item">
                          <FontAwesomeIcon icon="trash" /> Delete
                        </span>
                      </DropdownMenuItem>
                    )}
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>

            <div className={collapsibleContentClasses}>
              {props.loading && (
                <NonIdealState
                  icon="circle-notch"
                  iconClasses="fa-spin"
                  title="Loading"
                  description="Please wait while data it's being transferred."
                />
              )}
              {!props.loading && (
                <div className="cjl-title-desc pt-job-description">
                  <div>
                    <label className="cjl-list-item-name">Created</label>
                    <div className="row">
                      <div
                        className="col col-sm-12 col-md-6"
                        style={{ paddingBottom: 20 }}
                      >
                        {props.created}
                      </div>
                    </div>
                  </div>
                  {props.showCountries && (
                    <div>
                      <label
                        className="cjl-list-item-name"
                        data-testid="job-row-available-countries"
                      >
                        Available Countries
                      </label>
                      <div className="row">
                        {props.certifiedCountries &&
                          props.certifiedCountries.length > 0 && (
                            <div
                              className="col col-sm-12 col-md-6"
                              style={{ paddingBottom: 20 }}
                            >
                              <div className="text-thin" style={{ paddingBottom: 5 }}>
                                Certified
                              </div>
                              <div className="flex-wrap" style={{ margin: -3 }}>
                                {props.certifiedCountries &&
                                  props.certifiedCountries.map((country) => (
                                    <CertifiedCountry key={country.id}>
                                      {country.iso3166Alpha2 && (
                                        <FlagIcon code={country.iso3166Alpha2} />
                                      )}{" "}
                                      {country.title}
                                    </CertifiedCountry>
                                  ))}
                              </div>
                            </div>
                          )}

                        {props.certifiedCountries &&
                          props.certifiedCountries.length === 0 && (
                            <div
                              className="col col-sm-12 col-md-6"
                              style={{ paddingBottom: 20 }}
                            >
                              <div className="text-thin" style={{ paddingBottom: 5 }}>
                                Certified
                              </div>
                              <div
                                className="flex-wrap"
                                style={{ margin: -3, padding: 6 }}
                              >
                                <span className="text-muted">-- No Countries --</span>
                              </div>
                            </div>
                          )}

                        {props.adHocCountries && props.adHocCountries.length > 0 && (
                          <div
                            className="col col-sm-12 col-md-6"
                            style={{ paddingBottom: 20 }}
                          >
                            <div className="text-thin" style={{ paddingBottom: 5 }}>
                              Non-Certified
                            </div>
                            <div className="flex-wrap" style={{ margin: -3 }}>
                              {props.adHocCountries &&
                                props.adHocCountries.map((country) => (
                                  <div
                                    key={country.id}
                                    className="cjl-job-title-status cjl-status-tag ad-hoc"
                                  >
                                    {country.iso3166Alpha2 && (
                                      <FlagIcon code={country.iso3166Alpha2} />
                                    )}{" "}
                                    {country.title}
                                  </div>
                                ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {getSkillFeatureFlag(props.sessionInfo?.client) &&
                    props.description && (
                      <RsSkillsWithJobDescription jobDescription={props.description} />
                    )}
                  {latestSearch && (
                    <div>
                      <label className="cjl-list-item-name">Latest Search Summary</label>
                      <div className="row">
                        <div className="col col-sm-12" style={{ paddingBottom: 20 }}>
                          <table className="table level-data" style={{ marginTop: -34 }}>
                            <thead>
                              <tr>
                                <th className="text-right" colSpan={hourly ? 4 : 3}>
                                  {latestSearch.isDraft ? (
                                    <span className="cjl-tag warning">
                                      <span className="fa fa-warning" /> Draft Search
                                    </span>
                                  ) : (
                                    <div>
                                      <span>Go to:</span>{" "}
                                      <Link to={`/searches/${latestSearch.searchId}`}>
                                        Search
                                      </Link>{" "}
                                      |{" "}
                                      <Link
                                        to={`/ratecards/${latestSearch.ratecardId || ""}`}
                                      >
                                        Rate Card
                                      </Link>
                                    </div>
                                  )}
                                </th>
                              </tr>
                              <tr>
                                <th>Location</th>
                                <th>Industry</th>
                                <th className="text-right">Pay Rate</th>
                                {hourly && <th className="text-right">Bill Rate</th>}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {latestSearch.countryCode && (
                                    <FlagIcon code={latestSearch.countryCode} />
                                  )}{" "}
                                  {latestSearch.location}
                                </td>
                                <td>{latestSearch.industry}</td>
                                <td className="value-cell text-right" dir="ltr">
                                  {latestSearch.currencySymbol}
                                  {numeral(payRateMin).format("0,0.00")}{" "}
                                  <span>&mdash;</span> {latestSearch.currencySymbol}
                                  {numeral(payRateMax).format("0,0.00")}
                                </td>
                                {hourly && (
                                  <td className="value-cell text-right" dir="ltr">
                                    {latestSearch.currencySymbol}
                                    {numeral(billRateMin).format("0,0.00")}{" "}
                                    <span>&mdash;</span> {latestSearch.currencySymbol}
                                    {numeral(billRateMax).format("0,0.00")}
                                  </td>
                                )}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                  <label className="cjl-list-item-name">Description</label>
                  {props.description && (
                    <pre className="text-wrap">{props.description}</pre>
                  )}
                  {!props.description && (
                    <pre className="text-wrap">
                      <span className="text-muted">-- No Description --</span>
                    </pre>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
);

export { TableRow };

type Props = {
  router?: Object,
  store?: JobLibraryTitleListStore,
  jobLibraryTitle?: JobLibraryTitle,
  showCountries?: boolean,
  expandable?: boolean,
  actions?: ?(any[]),
  showLatestSearch: boolean,
  sessionInfo: SessionInfo,
};

const LibraryTitleTableRow = observer(
  class extends Component<Props> {
    props: Props;

    static defaultProps = {
      showCountries: true,
      expandable: false,
    };

    handleRowOnExpand: () => void;
    handleRowOnViewRateCards: (RawJobTitle) => void;
    handleRowOnViewRateSearch: (RawJobTitle) => void;
    handleRowRequestCountry: (JobLibraryTitle) => void;
    handleRowDeleteTitle: () => void;
    handleRowOnAddCountry: () => void;
    handleRowOnRemoveCountry: () => void;

    constructor(props: Props) {
      super(props);

      this.handleRowOnExpand = action(this.handleRowOnExpand.bind(this));
      this.handleRowOnViewRateCards = action(this.handleRowOnViewRateCards.bind(this));
      this.handleRowOnViewRateSearch = action(this.handleRowOnViewRateSearch.bind(this));
      this.handleRowRequestCountry = action(this.handleRowRequestCountry.bind(this));
      this.handleRowDeleteTitle = action(this.handleRowDeleteTitle.bind(this));
      this.handleRowOnAddCountry = action(this.handleRowOnAddCountry.bind(this));
      this.handleRowOnRemoveCountry = action(this.handleRowOnRemoveCountry.bind(this));
    }

    handleRowRequestCountry() {
      const jobLibraryTitle = this.props.jobLibraryTitle;
      if (this.props.store) {
        const requestStore =
          this.props.store.mobXStore.jobLibraryTitleRequestCountryCreateStore;
        requestStore.requestCountryForTitle(jobLibraryTitle);
        if (this.props.router) {
          this.props.router.push("/job-library/title-requests/create-country-request");
        }
      }
    }

    handleRowOnExpand() {
      if (this.props.expandable && this.props.jobLibraryTitle) {
        this.props.jobLibraryTitle.toggleExpanded();
      }
    }

    handleRowOnViewRateCards(title: RawJobTitle) {
      if (this.props.store && this.props.router) {
        this.props.store.viewRateCardsForTitleId(title, this.props.router);
      }
    }

    handleRowOnViewRateSearch(title: RawJobTitle) {
      this.props.router.push({
        pathname: "/rate-search",
        state: {
          autofillJobTitle: {
            title: title.title,
            id: title.id,
          },
        },
      });
    }

    handleRowDeleteTitle() {
      const { jobLibraryTitle, router, store } = this.props;
      if (!store) {
        console.error("props.store is null/undefined");
        return;
      }
      if (!jobLibraryTitle) {
        console.error("props.jobLibraryTitle is null/undefined");
        return;
      }
      store.confirmDeleteTitle(jobLibraryTitle, router);
    }

    handleRowOnAddCountry() {
      const { jobLibraryTitle, router, store } = this.props;
      if (!store) {
        console.error("props.store is null/undefined");
        return;
      }
      if (!jobLibraryTitle) {
        console.error("props.jobLibraryTitle is null/undefined");
        return;
      }
      store.addCountriesForTitle(jobLibraryTitle, router);
    }

    handleRowOnRemoveCountry() {
      const { jobLibraryTitle, router, store } = this.props;
      if (!store) {
        console.error("props.store is null/undefined");
        return;
      }
      if (!jobLibraryTitle) {
        console.error("props.jobLibraryTitle is null/undefined");
        return;
      }
      store.removeCountriesForTitle(jobLibraryTitle, router);
    }

    render() {
      const props = this.props;
      const { jobLibraryTitle, store, actions, showCountries, showLatestSearch } = props;
      // const totalCountries = props.totalCountries;

      let tableProps = getPlaceHolder(Boolean(actions), showCountries);
      if (jobLibraryTitle) {
        // let status = <div className="cjl-job-title-status ad-hoc">Ad-hoc</div>;
        // if (jobLibraryTitle.isCertified) {
        //   status = <div className="cjl-job-title-status certified">Certified</div>;
        // }

        const { sessionInfo, isMainLibrary, totalCount } = store || {};
        const { client } = sessionInfo || {};
        const hasJobLibrarySubscription = client
          ? client.ratecardSubscriptionFlag || false
          : false;
        const canBeDeleted = hasJobLibrarySubscription && isMainLibrary && totalCount > 1;

        const { totalSearchableCountries, totalCertifiedCountries } = jobLibraryTitle;
        const totalAdHocCountries =
          (totalSearchableCountries || 0) - (totalCertifiedCountries || 0);
        let countries = [];
        if ((totalCertifiedCountries || 0) > 0) {
          countries.push(
            <OverlayTrigger
              key="certified"
              placement="top"
              overlay={
                <Tooltip id="certified-tooltip">
                  Countries where the rates for this title have been certified by our
                  analysts.
                </Tooltip>
              }
            >
              <div
                key="certified"
                className={classNames("cjl-job-title-status certified", {
                  disabled: !totalCertifiedCountries,
                })}
              >
                {totalCertifiedCountries} Certified
              </div>
            </OverlayTrigger>
          );
        }
        if (totalAdHocCountries > 0) {
          countries.push(
            <OverlayTrigger
              key="ad-hoc"
              placement="top"
              overlay={
                <Tooltip id="ad-hoc-tooltip">
                  Countries where the rates for this title have NOT been certified by our
                  analysts and the job was searched by a user.
                </Tooltip>
              }
            >
              <div
                key="ad-hoc"
                className={classNames("cjl-job-title-status ad-hoc", {
                  disabled: !totalAdHocCountries,
                })}
              >
                {totalAdHocCountries} Non-Certified
              </div>
            </OverlayTrigger>
          );
        }

        if (!totalCertifiedCountries && !totalAdHocCountries) {
          countries.push(
            <div key="no-countries" className="cjl-job-title-status ad-hoc">
              No Countries
            </div>
          );
        }

        tableProps = {
          // status: status,
          clientRawJobTitle: jobLibraryTitle.clientRawJobTitle,
          titleId: jobLibraryTitle.clientRawJobTitle.id,
          clientTitle: jobLibraryTitle.clientRawJobTitle.title,
          mappedTitle: jobLibraryTitle.mappedRawJobTitle.title,
          description: jobLibraryTitle.clientRawJobTitle.description,
          showCountries: this.props.showCountries,
          countries: countries,
          certifiedCountries: jobLibraryTitle.certifiedCountries,
          adHocCountries: jobLibraryTitle.adHocCountries,
          created: jobLibraryTitle.createdDisplay,
          selected: jobLibraryTitle.viewState.selected,
          loading: jobLibraryTitle.network.loading,
          expanded: jobLibraryTitle.viewState.expanded,
          expandable: this.props.expandable,
          actions: actions,
          hasJobLibrarySubscription: hasJobLibrarySubscription,
          onExpand: this.handleRowOnExpand,
          onViewRateCards: this.handleRowOnViewRateCards,
          onViewRateSearch: this.handleRowOnViewRateSearch,
          onRequestCountry: this.handleRowRequestCountry,
          onDeleteTitle: this.handleRowDeleteTitle,
          onAddCountry: this.handleRowOnAddCountry,
          onRemoveCountry: this.handleRowOnRemoveCountry,
          canBeDeleted: canBeDeleted,
          showLatestSearch: showLatestSearch,
          latestSearch: jobLibraryTitle.latestSearch,
          displayRateOption: store.selectedDisplayRateOption,
          displayAggregationOption: store.selectedDisplayAggregationOption,
          aggregationMultiplier: store.appliedMultiplier,
          sessionInfo: store.sessionInfo,
        };
      }

      return <TableRow {...tableProps} />;
    }
  }
);

export default LibraryTitleTableRow;
