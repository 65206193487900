import React, { useCallback } from "react";

import Highchart from "../../../components/charts/Highchart";
import Box from "../../../components/lib/Box";
import { DEVICE_TYPES, DEVICE_TYPES_TYPE } from "../types";

export type ChartDataItemObject = {
  readonly id: string | number;
  readonly name: string;
  readonly y: number;
};

type ChartSizesItem = {
  height: number;
  width: number;
};
type ChartSizes = {
  [key in DEVICE_TYPES_TYPE]: ChartSizesItem;
};

const defaultChartSizes: ChartSizes = {
  desktop: {
    width: 600,
    height: 350,
  },
  tablet: {
    width: 600,
    height: 300,
  },
  mobile: {
    width: 300,
    height: 400,
  },
};

const getChartOptions = (
  sizesMap: ChartSizes,
  deviceType: DEVICE_TYPES_TYPE,
  title: string,
  data: ChartDataItemObject[]
): Highcharts.Options => {
  const sizes = sizesMap[deviceType] || sizesMap["desktop"];
  let counter = 0;
  let betterFitmentRequired = false;

  if (data?.length > 0) {
    const totalValue = data.reduce((acc, { y }) => acc + y, 0);

    if (totalValue) {
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        const itemValue = item["y"];
        const itemValuePercentage = (itemValue / totalValue) * 100;

        if (itemValuePercentage < 5) {
          counter += 1;
        } else {
          counter = 0;
        }
        if (counter >= 2) {
          betterFitmentRequired = true;
          break;
        }
      }
    }
  }

  return {
    chart: {
      type: "pie",
      backgroundColor: "transparent",
      ...sizes,
    },
    title: {
      text: title,
      align: "left",
    },
    plotOptions: {
      pie: {
        animation: {
          duration: 200,
          easing: "ease",
        },
        dataLabels: {
          enabled: true,
          distance: betterFitmentRequired ? -15 : -35,
          format: "{point.percentage:.0f}%",
          filter: {
            property: "percentage",
            operator: ">=",
            value: 3.0,
          },
          style: {
            fontWeight: "normal",
            color: "white",
            fontSize: betterFitmentRequired || deviceType === "mobile" ? "9px" : "11px",
            textShadow: false,
          },
        },
        point: {
          events: {
            legendItemClick: () => false,
          },
        },
        showInLegend: true,
        shadow: false,
        center: ["50%", "50%"],
        size: "100%",
        innerSize: "30%",
      },
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      enabled: true,
      layout: "vertical",
      align: deviceType === "mobile" ? "center" : "right",
      verticalAlign: deviceType === "mobile" ? "bottom" : "middle",
      itemWidth: deviceType === "mobile" ? undefined : 270,
      maxHeight: deviceType === "mobile" ? 150 : 220,
      labelFormat: "{name} - {percentage:.0f}%",
      itemStyle: {
        display: "block",
        cursor: "default",
        fontWeight: "initial",
        fontSize: "12px",
        color: "#757575",
      },
      itemHoverStyle: {
        fontWeight: "bold",
      },
    },
    credits: {
      enabled: false,
    },
    series: [{ data } as any],
  };
};

type DiscoverySearchResultDonutProps = {
  title: string;
  data: ChartDataItemObject[];
  deviceType: DEVICE_TYPES_TYPE;
  sizesMap: ChartSizes;
};

const DiscoverySearchResultDonut = (props: DiscoverySearchResultDonutProps) => {
  const { data, sizesMap, deviceType, title } = props;

  const renderDonut = useCallback(() => {
    const chartOptions = getChartOptions(sizesMap, deviceType, title, data);
    return <Highchart options={chartOptions} />;
  }, [data, title, sizesMap, deviceType]);

  return (
    <Box>
      {data == null && <span>loading...</span>}
      {data != null && data.length === 0 && <span>No data found.</span>}
      {data != null && data.length > 0 && renderDonut()}
    </Box>
  );
};

DiscoverySearchResultDonut.displayName = "DiscoverySearchResultDonut";
DiscoverySearchResultDonut.defaultProps = {
  sizesMap: defaultChartSizes,
  deviceType: DEVICE_TYPES.DESKTOP,
};

export default DiscoverySearchResultDonut;
