//@flow
import React, { Component } from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LibraryTitleList from "./LibraryTitleList";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import classNames from "classnames";
import Modal from "../../components/modals/Modal";
import type MobXStore from "../../stores/mobx/MobXStore";
import Button from "../../components/lib/Button";
import Inline from "../../components/lib/Inline";
import TextArea from "../../components/lib/TextArea";

type Router = {
  push: (Object | string) => void,
  location: { state: Object },
  replace: (Object | string) => void,
};

type Props = {
  store: MobXStore,
  router?: Router,
  params: { libraryId?: string },
};

class JobLibraryEditCustom extends Component<Props> {
  constructor(props: Props) {
    super(props);

    const store = props.store.jobLibraryEditCustomStore;

    const libraryId = this.props.params.libraryId;
    if (!libraryId) return;

    store.getLibrary(libraryId);

    store.previousLocation = `/job-library/view/${libraryId}`;

    this.props.store.jobLibraryEditCustomStore.successModal.hideModal();
    this.props.store.jobLibraryEditCustomStore.reset();
  }

  componentDidMount() {
    const jobLibraryTitleListStore = this.props.store.jobLibraryTitleListStore;
    const router = this.props.router;

    this.props.store.jobLibraryEditCustomStore.router = router;
    jobLibraryTitleListStore.handleStartEdit();
    jobLibraryTitleListStore.libraryId = null;
    jobLibraryTitleListStore.selectTitlesByLibraryId = this.props.params.libraryId;
    jobLibraryTitleListStore.hardRefresh();
  }

  render() {
    const store = this.props.store.jobLibraryEditCustomStore;
    const jobTitlesStore = this.props.store.jobLibraryTitleListStore;

    const titleClasses = classNames("cjl-custom-input cjl-custom-title", {
      error: Boolean(store.titleError),
    });

    const titleErrorTooltip = (
      <Tooltip id="titleErrorTooltip" placement="top">
        {store.titleError}
      </Tooltip>
    );

    return (
      <div className="cjl-centered-view-container">
        <div className="cjl-centered-view create-custom-library">
          <Inline
            css={{
              justifyContent: "flex-end",
              position: "absolute",
              top: "20px",
              right: "40px",
            }}
          >
            <Button shape="rounded" color="brand" onClick={store.handleSaveLibrary}>
              <FontAwesomeIcon icon="save" fixedWidth className="icon" />
              Save
            </Button>
          </Inline>
          <form onSubmit={store.handleSaveLibrary}>
            <div className="flex" style={{ position: "relative" }}>
              <input
                type="text"
                className={titleClasses}
                placeholder="Enter Library Name Here"
                onChange={store.onTitleChange}
                value={store.title}
              />
              {Boolean(store.titleError) && (
                <OverlayTrigger placement="top" overlay={titleErrorTooltip}>
                  <FontAwesomeIcon
                    icon="info-circle"
                    fixedWidth
                    className="icon text-danger cjl-input-icon-right"
                  />
                </OverlayTrigger>
              )}
            </div>
            <TextArea
              fill
              css={{ border: " 1px dashed $brandLightest" }}
              name="description"
              id="description"
              cols="60"
              rows="4"
              placeholder="Enter description of library here."
              value={store.description}
              onChange={store.onDescriptionChange}
            />
          </form>
          <LibraryTitleList
            store={jobTitlesStore}
            itemActions={[store.addRemoveClientJobTitleAction]}
            selectedCount={store.selectedCount}
          />
        </div>

        <Modal
          show={store.confirmEmptyLibraryModal.show}
          onHide={store.handleConfirmEmptyLibraryCancel}
        >
          <div className="container-section header">
            <h4>Empty Library</h4>
          </div>
          <div className="container-section">
            <p>
              No titles have been selected for this library. To create the library you
              must select at least one title.
            </p>
          </div>
          <div className="container-section footer">
            <div className="pull-right">
              {/*<button className="btn btn-lg btn-danger" onClick={store.handleConfirmEmptyLibrarySubmit}>*/}
              {/*Yes, create empty library*/}
              {/*</button>*/}
              <button
                className="btn btn-lg"
                onClick={store.handleConfirmEmptyLibraryCancel}
              >
                Add titles now
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default observer(JobLibraryEditCustom);
