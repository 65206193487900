// @flow
import * as React from "react";
import classNames from "classnames";
import graphql from "babel-plugin-relay/macro";
import {
  createPaginationContainer,
  QueryRenderer,
  useRelayEnvironment,
} from "react-relay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import LoadingIndicator from "../views/shared/LoadingIndicator";
import Tag from "./Tag";
import SearchBox from "./SearchBox";
import CheckBox from "../views/shared/CheckBox";
import ToggleButton from "../views/ratecards/components/ToggleButton";
import Modal from "../views/ratecards/components/Modal";
import FilterAndSortClients from "./FilterAndSortClients";
import FilterAndSortCreatedBy from "./FilterAndSortCreatedBy";
import FilterAndSortCreatedOn from "./FilterAndSortCreatedOn";
import type { FetchAPI } from "../App";
import type { AdminTagsQueryResponse } from "./__generated__/AdminTagsQuery.graphql";
import type { AdminTags_viewer } from "./__generated__/AdminTags_viewer.graphql";
import type { TagItem } from "./Tag";
import type { RenderProps } from "react-relay/ReactRelayQueryRenderer";
import type { RelayPaginationProp } from "react-relay/ReactRelayTypes";
import type { MaybeCreatedOnSort } from "./FilterAndSortCreatedOn";
import SortIcon from "./SortIcon";
import { getSortingList, getVariables, updateSortingOrder } from "./common";
// import FilterAndSortTags from "./FilterAndSortTags";
// import SortIndicator from "./ratecards/components/SortIndicator";
// import { Link } from "react-router";
// import FilterAndSort from "./FilterAndSort";

type TagFilters = {
  nameIContains?: string,
  ownerId?: Array<string>,
  clientIdIn?: Array<string>,
  only?: Array<string>,
  exclude?: Array<string>,
  fromDate?: string, // formatted date
  toDate?: string, // formatted date
};

type AdminTagsListProps = {
  fetchAPI: FetchAPI,
  viewer: AdminTags_viewer,
  relay: RelayPaginationProp,
};

type AdminTagsListState = {
  showHelp: boolean,
  editing: boolean,
  refetching: boolean,
  page: number,
  error: Object | null,
  searchText: string | null,
  selectAllOnPage: boolean,
  selectAll: boolean,
  clientFilter: { clientIdIn: Array<string> } | null,
  clientsFilterOpen: boolean,
  createdByFilter: { createdById: Array<string> } | null,
  createdByFilterOpen: boolean,
  createdOnFilter: { fromDate?: string, toDate?: string } | null,
  createdOnFilterOpen: boolean,
  selectedItems: Array<string>,
  sortingOrder: Array<string>,
  createdOnSort: MaybeCreatedOnSort,
};

class AdminTagsImpl extends React.Component<AdminTagsListProps, AdminTagsListState> {
  DEFAULT_QUERY_VALUES = {
    searchText: null,
    clientFilter: null,
    createdByFilter: null,
    createdOnFilter: null,
  };
  constructor(props: AdminTagsListProps) {
    super(props);

    this.state = {
      showHelp: false,
      editing: false,
      refetching: false,
      page: 0,
      error: null,
      selectAllOnPage: false,
      selectAll: false,
      ...this.DEFAULT_QUERY_VALUES,
      clientsFilterOpen: false,
      createdByFilterOpen: false,
      createdOnFilterOpen: false,
      selectedItems: [],
      sortingOrder: [],
      createdOnSort: null,
    };
  }

  resetQuery = (): void => {
    this.setState(
      {
        ...this.DEFAULT_QUERY_VALUES,
        sortingOrder: [],
        createdOnSort: null,
      },
      this.applyFilterAndSort
    );
  };

  isSelected = (id: string) => {
    const { selectAll, selectAllOnPage, selectedItems } = this.state;
    if (selectAllOnPage || selectAll) {
      return true;
    }
    return selectedItems.indexOf(id) !== -1;
  };

  getFilters = (): TagFilters | null => {
    const { searchText, clientFilter, createdByFilter, createdOnFilter } = this.state;
    let filters: TagFilters | null = null;

    if (searchText) filters = { nameIContains: searchText };
    if (clientFilter)
      filters = Object.assign(filters || {}, { clientIdIn: clientFilter.clientIdIn });
    if (createdByFilter)
      filters = Object.assign(filters || {}, { ownerId: createdByFilter.createdById });
    if (createdOnFilter) filters = Object.assign(filters || {}, createdOnFilter);

    return filters;
  };

  getSorts = () => {
    const { sortingOrder, createdOnSort } = this.state;
    return getSortingList([createdOnSort], sortingOrder);
  };

  applyFilterAndSort = () => {
    const filters = this.getFilters();
    const order = this.getSorts();

    // TODO Tag filter

    this.setState({ refetching: true }, () => {
      this.props.relay.refetchConnection(
        10,
        (error) => {
          if (error) {
            console.error(error);
          }
          this.setState({ error, refetching: false });
        },
        {
          filters,
          order,
        }
      );
    });
  };

  handleToggleSelect = (id: string) => {
    let selectedItems = this.state.selectedItems.slice();
    if (this.isSelected(id)) {
      // Can be "selected", but not in array.
      const selectedIndex = selectedItems.indexOf(id);
      if (selectedIndex !== -1) {
        selectedItems = selectedItems
          .slice(0, selectedIndex)
          .concat(selectedItems.slice(selectedIndex + 1, selectedItems.length));
      }
      // any deselection disables "select all *"
      // TODO: support "exclude" items while select all
      this.setState({
        selectAll: false,
        selectAllOnPage: false,
        selectedItems,
      });
    } else {
      selectedItems.push(id);
      this.setState({
        selectedItems,
      });
    }
  };

  handleToggleSelectAllOnPage = () => {
    const { selectAllOnPage } = this.state;
    if (selectAllOnPage) {
      this.setState({
        selectAll: false,
        selectAllOnPage: false,
        selectedItems: [],
      });
    } else {
      this.setState({
        selectAll: false,
        selectAllOnPage: true,
        selectedItems: [],
      });
    }
  };

  handleToggleSelectAll = () => {
    const { selectAll } = this.state;
    this.setState({
      selectAll: !selectAll,
      selectAllOnPage: !selectAll,
      selectedItems: [],
    });
  };

  handleExportTags = () => {
    const exportURL = "tags/basic/list/export/excel/";
    const fileName = "export_tags_" + Math.floor(Math.random() * 9999999 + 1000000);

    const allSelected = false;
    if (allSelected) {
      // TODO Fix
      console.warn("No export for 'select all'");
    } else {
      if (this.state.selectedItems.length === 0) {
        // Should not happen because button is disabled when length == 0
        this.setState({
          error: "Cannot export jobs: No Tags Selected",
        });
        return;
      }

      const filters = Object.assign(this.getFilters() || {}, {
        onlyId: this.state.selectedItems,
      });

      this.props
        .fetchAPI(exportURL, {
          fileName,
          section: "admin",
          tagFilters: filters,
        })
        .then((res) => {
          window.location.href = res.data.url;
          this.setState({ editing: false });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ error, editing: false });
        });
    }
  };

  render() {
    const loading =
      !this.props.viewer || this.props.relay.isLoading() || this.state.refetching;
    const itemsPerPage = 10;
    const { showHelp, editing, selectAllOnPage, selectAll } = this.state;
    // Do not include search text
    const isFiltered = Boolean(this.getFilters());
    const isSorted = Boolean(this.getSorts());

    const totalCount = this.props?.viewer?.tags?.totalCount || 0;
    const tags = this.props?.viewer?.tags?.edges || [];

    const page = tags.slice(
      itemsPerPage * this.state.page,
      Math.min(itemsPerPage * (this.state.page + 1), tags.length)
    );

    return (
      <div className="view list">
        <div className="rc-container bring-forward">
          <div className="header-action-bar">
            <h2>Tags Admin</h2>
            <p className="subtext">Manage all Clients' Tags in the system.</p>
            <div className="clearfix" />
          </div>
          <div className="header-action-bar marginTopzero">
            <div className="pull-left">
              <SearchBox
                onSearch={async (searchText: string) => {
                  // NOTE: should handle when "select all *" and reset on search?
                  if (searchText === "") {
                    this.setState({ searchText: null, page: 0 }, this.applyFilterAndSort);
                  } else {
                    this.setState({ searchText, page: 0 }, this.applyFilterAndSort);
                  }
                }}
                disabled={loading}
                style={{
                  width: "260px",
                }}
              />
            </div>
            <div className="btn-group pull-right rc-center">
              {editing ? (
                <button
                  className="btn btn-yellow"
                  onClick={() => {
                    this.setState({
                      editing: false,
                      selectAll: false,
                      selectAllOnPage: false,
                      selectedItems: [],
                    });
                  }}
                >
                  Stop Editing
                </button>
              ) : (
                <div>
                  <button
                    className="btn btn-green"
                    onClick={() => {
                      this.setState({ editing: true });
                    }}
                  >
                    <FontAwesomeIcon icon="edit" fixedWidth className="icon" />
                    Edit
                  </button>
                  <button
                    className="btn"
                    onClick={() => {
                      this.setState({ showHelp: true });
                    }}
                  >
                    <FontAwesomeIcon icon="question-circle" fixedWidth className="icon" />
                    Help
                  </button>
                </div>
              )}
            </div>
            <div className="clearfix" />
          </div>
          <div className="ratecards-list">
            <header
              className={classNames("ratecards-table-header", {
                active: false,
              })}
            >
              <FilterAndSortClients
                viewer={this.props?.viewer || null}
                open={this.state.clientsFilterOpen}
                currentFilter={this.state.clientFilter}
                disabled={loading}
                onClose={() => {
                  this.setState({ clientsFilterOpen: false });
                }}
                onApply={(clientFilter) => {
                  this.setState(
                    { clientFilter, clientsFilterOpen: false },
                    this.applyFilterAndSort
                  );
                }}
              />
              <FilterAndSortCreatedBy
                viewer={this.props?.viewer || null}
                open={this.state.createdByFilterOpen}
                currentFilter={this.state.createdByFilter}
                disabled={loading}
                onClose={() => {
                  this.setState({ createdByFilterOpen: false });
                }}
                onApply={(createdByFilter) => {
                  this.setState(
                    { createdByFilter, createdByFilterOpen: false },
                    this.applyFilterAndSort
                  );
                }}
              />
              <FilterAndSortCreatedOn
                currentFilter={this.state.createdOnFilter}
                currentSort={this.state.createdOnSort}
                open={this.state.createdOnFilterOpen}
                onClose={() => {
                  this.setState({ createdOnFilterOpen: false });
                }}
                onApply={(createdOnFilter, createdOnSort) => {
                  const currentSort = this.state.createdOnSort;
                  let sortingOrder = this.state.sortingOrder;

                  this.setState(
                    {
                      createdOnFilter,
                      createdOnSort,
                      sortingOrder: updateSortingOrder(
                        createdOnSort,
                        currentSort,
                        sortingOrder
                      ),
                      createdOnFilterOpen: false,
                    },
                    this.applyFilterAndSort
                  );
                }}
              />
              {editing && (
                <nav className="header-left-nav">
                  <div
                    className="btn btn-toggle"
                    onClick={this.handleToggleSelectAllOnPage}
                  >
                    <FontAwesomeIcon
                      icon={selectAllOnPage || selectAll ? "check-square" : "square"}
                      className={classNames("rc-check-icon", {
                        selected: selectAllOnPage || selectAll,
                      })}
                    />{" "}
                    {selectAllOnPage || selectAll ? "Deselect All" : "Select All"}
                  </div>
                </nav>
              )}
              <nav className="header-right-nav alt-1">
                {Boolean(isFiltered || isSorted) && (
                  <div className="btn btn-yellow" onClick={this.resetQuery}>
                    Clear All Filters & Sorts
                  </div>
                )}
                <ToggleButton
                  borderless={true}
                  name="search-list-filter"
                  value={""}
                  selected={this.state.clientFilter !== null}
                  onClick={() => {
                    this.setState({ clientsFilterOpen: true });
                  }}
                >
                  Clients <span> ▼</span>
                </ToggleButton>
                <ToggleButton
                  borderless={true}
                  name="search-list-filter"
                  value={""}
                  selected={this.state.createdByFilter !== null}
                  onClick={() => {
                    this.setState({ createdByFilterOpen: true });
                  }}
                >
                  Created By <span> ▼</span>
                </ToggleButton>
                <ToggleButton
                  borderless={true}
                  name="search-list-filter"
                  value={""}
                  selected={this.state.createdOnFilter !== null}
                  onClick={() => {
                    this.setState({ createdOnFilterOpen: true });
                  }}
                >
                  <SortIcon
                    kind="numeric"
                    sortObject={
                      this.state.createdOnSort || { field: "", direction: "DESC" }
                    }
                    sortingOrder={this.state.sortingOrder}
                  />{" "}
                  Created On <span> ▼</span>
                </ToggleButton>
              </nav>
            </header>
            <div className="ratecards-table">
              {/* TODO Select All Section */}
              {this.state.error && (
                <div className="ratecard-list-item-container">
                  <div className="ratecard-list-item">
                    <div>
                      Unable to load tags: <pre>{this.state.error.toString()}</pre>
                    </div>
                  </div>
                </div>
              )}
              {selectAllOnPage &&
                (selectAll ? (
                  <div className="ratecards-list-select-all-section">
                    <span>
                      All &nbsp;
                      <strong>{totalCount}</strong>
                      &nbsp; tags selected.{" "}
                    </span>
                    &nbsp;
                    {/* todo check this */}
                    <span
                      className="pt-link ratecards-all-items"
                      key="clear-selections-link"
                      onClick={() => {
                        this.setState({
                          selectAll: false,
                          selectAllOnPage: false,
                          selectedItems: [],
                        });
                      }}
                    >
                      Clear all selections
                    </span>
                  </div>
                ) : (
                  <div className="ratecards-list-select-all-section">
                    <span>All tags on this page selected. &nbsp;</span>
                    &nbsp;
                    <span
                      className="pt-link ratecards-all-items"
                      key="select-all-item"
                      onClick={this.handleToggleSelectAll}
                    >
                      Select all &nbsp;
                      <strong>{totalCount}</strong>
                      &nbsp; tags.
                    </span>
                    &nbsp;&nbsp;
                    <span
                      className="pt-link ratecards-all-items"
                      key="clear-selections-link"
                      onClick={() => {
                        this.setState({
                          selectAll: false,
                          selectAllOnPage: false,
                          selectedItems: [],
                        });
                      }}
                    >
                      Clear all selections
                    </span>
                  </div>
                ))}
              {loading ? (
                <LoadingIndicator />
              ) : tags.length === 0 ? (
                <div>No Tags Found</div>
              ) : (
                page.map((tag) => {
                  const { node: tagNode, cursor: tagCursor } = tag || {};
                  if (!tagNode) {
                    return <div>[Error getting tag's details]</div>;
                  }
                  let onClick = () => {
                    this.context.router.push({
                      pathname: `/admin/tag/${
                        tagNode?.tagId.toString(10) || "null-id-error"
                      }/`,
                      query: this.context.router.query,
                    });
                  };
                  if (editing) {
                    onClick = this.handleToggleSelect;
                  }
                  const isSelected = this.isSelected(tagNode?.id || "null-id-error");

                  return (
                    <Tag
                      key={tagCursor}
                      tag={tagNode}
                      render={(tag: TagItem) => (
                        <div className="ratecard-list-item-container">
                          <div
                            className={classNames("ratecard-list-item", {
                              selected: isSelected,
                            })}
                            // onClick={onClick}
                          >
                            <div className="ratecard-list-item-left ellipsis">
                              {editing && (
                                <CheckBox
                                  type={"checkbox"}
                                  name="list-item-select"
                                  value={tag.id}
                                  selected={isSelected}
                                  style={{
                                    width: 34,
                                  }}
                                  onClick={onClick}
                                />
                              )}
                              <div className="ratecard-label-container ellipsis">
                                <div className="ratecard-label ellipsis">
                                  <span className="ellipsis">{tag.name}</span>
                                </div>
                                <div className="ratecard-meta ellipsis">
                                  <span className="item-subtext-muted">Created by </span>
                                  <span className="item-subtext-accent">
                                    {tag.owner.username}{" "}
                                  </span>
                                  <span className="item-subtext-muted">on </span>
                                  <span className="item-subtext-accent">
                                    {moment(tag.created).format("MMMM D, YYYY")}
                                  </span>
                                </div>
                              </div>
                            </div>
                            {/*<div className="ratecard-list-item-right">*/}
                            {/*  <div className="ratecard-info w-300 text-left">*/}
                            {/*    <header>Owner</header>*/}
                            {/*    <div>{tag.owner.username}</div>*/}
                            {/*  </div>*/}
                            {/*</div>*/}
                            {/*<div className="ratecard-list-item-right">*/}
                            {/*  <div className="ratecard-info w-300 text-right">*/}
                            {/*    <header>Client</header>*/}
                            {/*    <div>{tag.owner.client.name}</div>*/}
                            {/*  </div>*/}
                            {/*</div>*/}
                            <div className="ratecard-list-item-right">
                              <div className="ratecard-info text-center">
                                <header data-testid="ID-header-tags">ID</header>
                                <div>{tag.tagId}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  );
                })
              )}
            </div>
          </div>
          <div className="pt-pagination-outer">
            <button
              className="pt-prev-page-btn"
              disabled={this.state.page === 0}
              onClick={() => {
                this.setState({ page: this.state.page > 0 ? this.state.page - 1 : 0 });
              }}
            >
              <FontAwesomeIcon icon="chevron-left" className="pt-page-left-chevron" />{" "}
              Prev
            </button>
            <button
              className="pt-next-page-btn"
              disabled={!this.props.relay.hasMore()}
              onClick={() => {
                const newPage = this.state.page + 1;
                if (
                  this.props.relay.hasMore() &&
                  newPage * itemsPerPage > tags.length - itemsPerPage
                ) {
                  this.setState({ refetching: true }, () => {
                    this.props.relay.loadMore(itemsPerPage, (error) => {
                      if (error) {
                        console.error(error);
                      }
                      this.setState({ error, page: newPage, refetching: false });
                    });
                  });
                } else {
                  this.setState({ page: newPage });
                }
              }}
            >
              Next{" "}
              <FontAwesomeIcon icon="chevron-right" className="pt-page-right-chevron" />
            </button>
          </div>
        </div>
        {
          <div
            className={classNames("ratecards-edit-actions", {
              hidden: editing === null,
              "bottom-appear": editing === true,
              "bottom-disappear": editing === false,
            })}
          >
            <button
              className="btn"
              disabled={
                !selectAll && !selectAllOnPage && this.state.selectedItems.length === 0
              }
              onClick={this.handleExportTags}
            >
              <FontAwesomeIcon icon="download" fixedWidth className="icon" />
              Export
            </button>
          </div>
        }
        {
          <div
            className={classNames("rc-container-backdrop", {
              hidden: editing === null,
              "fade-in": editing === true,
              "fade-out": editing === false,
            })}
          />
        }
        <Modal
          show={showHelp}
          onHide={() => {
            this.setState({ showHelp: false });
          }}
        >
          <div className="container-section header">
            <h4>Help</h4>
          </div>
          <div className="container-section footer">
            <p>Here you can see all of your Tags...</p>
          </div>
        </Modal>
      </div>
    );
  }
}

const AdminTagsPaginationContainer = createPaginationContainer(
  AdminTagsImpl,
  {
    viewer: graphql`
      fragment AdminTags_viewer on Viewer
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        cursor: { type: "String" }
        filters: { type: "TagsFiltersInput", defaultValue: {} }
        order: {
          type: "[TagsSortInput]"
          defaultValue: [{ field: CREATE_DATE, direction: DESC }]
        }
      ) {
        tags(
          first: $count
          after: $cursor
          filters: $filters
          order: $order
          section: ADMIN
        ) @connection(key: "AdminTags_tags", filters: ["filters"]) {
          pageInfo {
            hasNextPage
            endCursor
            hasPreviousPage
            startCursor
          }
          totalCount
          edges {
            cursor
            node {
              id
              tagId
              ...Tag_tag
            }
          }
        }
        ...FilterAndSortClients_viewer
        ...FilterAndSortCreatedBy_viewer
      }
    `,
  },
  {
    direction: "forward",
    // getConnectionFromProps(props: AdminTagsListProps) {
    //   return props.viewer?.tags;
    // },
    // getFragmentVariables(prevVars, totalCount) {
    //   return {
    //     ...prevVars,
    //     count: totalCount
    //   };
    // },
    // getVariables(props, { count, cursor }, fragmentVariables) {
    //   return {
    //     count,
    //     cursor,
    //     filters: fragmentVariables.filters,
    //     order: fragmentVariables.order
    //   };
    // },
    getVariables,
    query: graphql`
      query AdminTagsPaginationQuery(
        $filters: TagsFiltersInput
        $order: [TagsSortInput]
        $count: Int
        $cursor: String
      ) {
        viewer {
          ...AdminTags_viewer
            @arguments(filters: $filters, order: $order, count: $count, cursor: $cursor)
        }
      }
    `,
  }
);

type AdminTagsProps = {
  route: {
    fetchAPI: FetchAPI,
  },
};

const AdminTags = (props: AdminTagsProps) => {
  const fetchAPI = props.route.fetchAPI;
  const relayEnvironment = useRelayEnvironment();

  return (
    <QueryRenderer
      environment={relayEnvironment}
      query={graphql`
        query AdminTagsQuery {
          viewer {
            ...AdminTags_viewer
          }
        }
      `}
      variables={{}}
      render={({ error, props }: RenderProps<AdminTagsQueryResponse>): any => {
        if (error) {
          console.error(error);
          return <div>Error!</div>;
        }

        const { viewer } = props || {};
        if (!viewer) return <LoadingIndicator />;

        return <AdminTagsPaginationContainer fetchAPI={fetchAPI} viewer={viewer} />;
      }}
    />
  );
};

export default AdminTags;
