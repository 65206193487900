import React from "react";

import Text from "../../../../components/lib/Text";
import Stack from "../../../../components/lib/Stack";
import Alert from "../../../../components/lib/Alert";

import { QuestionsViewer } from "../../components/QuestionsTable";
import { useReviewDataContext } from "../../context/ReviewDataContext";

const ReviewSecondaryParametersBlock = () => {
  const { instructions, questionsData } = useReviewDataContext();

  return (
    <Stack fill css={{ alignItems: "flex-start", height: "fit-content" }}>
      {instructions ? (
        <Alert fill color="warning">
          <b>Instructions:</b> {instructions}.
        </Alert>
      ) : (
        <Alert fill color="warning">
          <b>Instructions:</b> No special instructions configured for the survey.
        </Alert>
      )}
      {questionsData && questionsData.size > 0 ? (
        <Stack fill css={{ alignItems: "flex-start", gap: "$2" }}>
          <Text as="h4">Additional Questions:</Text>
          <QuestionsViewer questions={questionsData} />
        </Stack>
      ) : (
        <Alert fill color="warning">
          <b>Additional Questions:</b> No questions configured for the survey.
        </Alert>
      )}
    </Stack>
  );
};
ReviewSecondaryParametersBlock.displayName = "ReviewSecondaryParametersBlock";

export default ReviewSecondaryParametersBlock;
