import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "./Spinner";
import { config, css, styled } from "../../stitches.config";
import type * as Stitches from "@stitches/react";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import Button from "./Button";

export const inputBorderStyle = css({
  borderRadius: "$rounded",
  border: "2px solid $primaryLight",
  transitionProperty: "background-color, border-color, color, fill, stroke",
  transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
  transitionDuration: "150ms",
  "&:hover, &:focus": {
    outline: "none",
    boxShadow: "none",
  },
});

const Wrapper = styled("div", inputBorderStyle, {
  position: "relative",
  // overflow: "hidden",
  display: "inline-flex",
  alignItems: "center",
  "& input": {
    flex: "1 1 0%",
    minWidth: 5,
    backgroundColor: "transparent",
    border: "none",
    padding: 0,
    margin: 0,
    "&:hover, &:focus": {
      outline: "none",
      boxShadow: "none",
    },
  },
  borderWidth: 1,
  variants: {
    color: {
      primary: {
        backgroundColor: "transparent",
        borderColor: "$primaryLight",
        color: "$primaryDarker",
        "input::placeholder, [data-comp-id='textInputIcon'], [data-comp-id='textInputIconRight']":
          {
            color: "$primary",
          },
        "&:focus-within": {
          borderColor: "$brand",
          "[data-comp-id='textInputIcon'], [data-comp-id='textInputIconRight']": {
            color: "$primaryDarker",
          },
        },
        // "#textInputIconButton, #textInputIconRightButton": {
        //   color: "$textLight"
        // },
      },
      danger: {
        backgroundColor: "transparent",
        borderColor: "$danger",
        color: "$primaryDarker",
        "input::placeholder, [data-comp-id='textInputIcon'], [data-comp-id='textInputIconRight']":
          {
            color: "$primary",
          },
        "&:focus-within": {
          borderColor: "$brand",
          "[data-comp-id='textInputIcon'], [data-comp-id='textInputIconRight']": {
            color: "$primaryDarker",
          },
        },
      },
    },
    size: {
      "extra-small": {
        gap: "$2",
        padding: "$1 $2",
        fontSize: "$xs",
        lineHeight: "$xs",
        "& input": {
          fontSize: "$xs",
          lineHeight: "$xs",
        },
      },
      small: {
        gap: "$2_5",
        padding: "$1_5 $2_5",
        fontSize: "$sm",
        lineHeight: "$sm",
        "& input": {
          fontSize: "$sm",
          lineHeight: "$sm",
        },
      },
      normal: {
        gap: "$3",
        padding: "calc($2 + 1px) calc($3 + 1px)",
        fontSize: "$base",
        lineHeight: "$base",
        "& input": {
          fontSize: "$base",
          lineHeight: "$base",
        },
        "[data-comp-id='textInputIconButton']": {
          padding: "$2_5",
          margin: "-$2_5 0 -$2_5 -$2_5",
        },
        "[data-comp-id='textInputIconRightButton']": {
          padding: "$2_5",
          margin: "-$2_5 -$2_5 -$2_5 0",
        },
        "[data-comp-id='textInputAddon']": {
          padding: "calc($2 + 1px) calc($4 + 1px)",
          margin: "calc(-1*($2 + 1px)) 0 calc(-1*($2 + 1px)) calc(-1*($3 + 1px))",
        },
        "[data-comp-id='textInputAddonRight']": {
          padding: "calc($2 + 1px) calc($4 + 1px)",
          margin: "calc(-1*($2 + 1px)) calc(-1*($3 + 1px)) calc(-1*($2 + 1px)) 0",
        },
      },
      large: {
        gap: "$3_5",
        padding: "$2_5 $4",
        fontSize: "$lg",
        lineHeight: "$lg",
        "& input": {
          fontSize: "$lg",
          lineHeight: "$lg",
        },
      },
      "extra-large": {
        gap: "$4",
        padding: "$3 $4",
        fontSize: "$2xl",
        lineHeight: "$2xl",
        "& input": {
          fontSize: "$2xl",
          lineHeight: "$2xl",
        },
      },
    },
    fill: {
      true: {
        width: "100%",
      },
    },
    disabled: {
      true: {
        opacity: 0.55,
        backgroundColor: "$primaryLighter",
      },
    },
    deep: {
      true: {
        backgroundColor: "$secondaryLightest",
        borderColor: "$secondaryLightest",
        "& [data-comp-id='textInputAddon'], & [data-comp-id='textInputAddonRight']": {
          backgroundColor: "$primaryLightest",
        },
      },
    },
  },
  defaultVariants: {
    color: "primary",
    size: "normal",
    fill: false,
    disabled: false,
    deep: false,
  },
});

const AddonWrapper = styled("div", {
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  border: "none",
  backgroundColor: "$primaryLighter",
  variants: {
    side: {
      left: {
        borderTopLeftRadius: 3,
        borderBottomLeftRadius: 3,
        borderRight: "1px solid $primaryLight",
      },
      right: {
        borderTopRightRadius: 3,
        borderBottomRightRadius: 3,
        borderLeft: "1px solid $primaryLight",
      },
    },
  },
  defaultVariants: {
    side: "left",
  },
});

type InputVariants = Stitches.VariantProps<typeof Wrapper>;

interface UtilInputProps {
  css?: Stitches.CSS<typeof config>;
  loading?: boolean;
  // NOTE: We use IconName over IconProp (more correct) to help fix autocomplete usability but think we're hitting some vscode bugs still
  icon?: IconName;
  iconClicked?: React.MouseEventHandler<HTMLButtonElement>;
  iconRight?: IconName;
  iconRightClicked?: React.MouseEventHandler<HTMLButtonElement>;
  addon?: React.ReactNode;
  addonRight?: React.ReactNode;
  withoutWrapper?: boolean;
  wrapperRef?: React.MutableRefObject<HTMLDivElement | null>;
  wrapperClassName?: string;
  onWrapperClick?: React.MouseEventHandler<HTMLDivElement>;
}

type ReactInputProps = React.ComponentPropsWithRef<"input">;
export type InputProps = Omit<ReactInputProps, "size"> & InputVariants & UtilInputProps;

const TextInput = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref): JSX.Element => {
    const {
      css,
      loading,
      icon,
      iconClicked,
      iconRight,
      iconRightClicked,
      addon,
      addonRight,
      size,
      color,
      fill,
      disabled,
      deep,
      withoutWrapper = false,
      wrapperRef,
      wrapperClassName,
      onWrapperClick,
      ...rest
    } = props;

    return (
      <Wrapper
        className={wrapperClassName}
        size={size}
        color={color}
        fill={fill}
        disabled={disabled}
        deep={deep}
        css={css}
        ref={wrapperRef}
        onClick={onWrapperClick}
      >
        {addon && !withoutWrapper && (
          <AddonWrapper data-comp-id="textInputAddon">{addon}</AddonWrapper>
        )}

        {icon && !iconClicked && (
          <FontAwesomeIcon data-comp-id="textInputIcon" icon={icon} />
        )}
        {addon && withoutWrapper && addon}
        {icon && iconClicked && (
          <Button
            data-comp-id="textInputIconButton"
            color="brand"
            icon={icon}
            onClick={iconClicked}
            disabled={disabled}
          />
        )}
        <input ref={ref} disabled={disabled} {...rest} />
        {loading && <Spinner size={size} />}
        {iconRight && !iconRightClicked && !loading && (
          <FontAwesomeIcon data-comp-id="textInputIconRight" icon={iconRight} />
        )}
        {iconRight && iconRightClicked && !loading && (
          <Button
            data-comp-id="textInputIconRightButton"
            variant="text"
            color="brand"
            icon={iconRight}
            onClick={iconRightClicked}
            disabled={disabled}
          />
        )}
        {addonRight && !withoutWrapper && (
          <AddonWrapper side="right" data-comp-id="textInputAddonRight">
            {addonRight}
          </AddonWrapper>
        )}
        {addonRight && withoutWrapper && addonRight}
      </Wrapper>
    );
  }
);

TextInput.displayName = "TextInput";

export default TextInput;
