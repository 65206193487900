import React, { useState, useCallback, useEffect } from "react";
import moment from "moment";

import Stack from "../../../components/lib/Stack";
import Grid from "../../../components/lib/Grid";
import Box from "../../../components/lib/Box";
import Text from "../../../components/lib/Text";
import Inline from "../../../components/lib/Inline";
import Icon from "../../../components/lib/Icon";
import Alert from "../../../components/lib/Alert";
import Button from "../../../components/lib/Button";
import PromiseButton from "../../../components/lib/PromiseButton";
import SingleDatePicker from "../../../components/SingleDatePicker";
import { RadioGroup, RadioGroupItem } from "../../../components/lib/RadioGroup";
import { ButtonGroupRight } from "../../../components/lib/ButtonGroup";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogClose,
  DialogDescription,
  DialogActions,
} from "../../../components/lib/Dialog";
import {
  PAYMENT_REPORT_TYPES,
  PAYMENT_REPORT_TYPES_LABELS,
  REVIEW_STATUS_TYPES,
  READY_FOR_PAYMENT_TYPES,
} from "../types";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";
import { djangoDateFormat } from "../../../components/tables/constants";

import type { PAYMENT_REPORT_TYPES_TYPE, RewardsSummaryDataObject } from "../types";
import type { FetchAPIResponse } from "../../../types/fetch";
import type { UrlQueryObject } from "../../../components/tables/types";
import { useVal5KAdminContext } from "../context/Val5KAdminContext";

type PaymentReportFormState = {
  reportType: PAYMENT_REPORT_TYPES_TYPE;
  reportFromDate: string | null;
  reportToDate: string | null;
};

export type PaymentReportFormQueryArgs = {
  reportType: PAYMENT_REPORT_TYPES_TYPE;
  reportFromDate: string;
  reportToDate: string;
};

type PaymentReportFormProps = {
  show: boolean;
  onMakeReport: (reportParameters: PaymentReportFormQueryArgs) => void;
  onClose: () => void;
};

const PaymentReportForm = (props: PaymentReportFormProps) => {
  const { show, onMakeReport, onClose } = props;
  const { fetchArgusFilteringAPI, showModalError, showModalWarning } =
    useVal5KAdminContext();

  // state

  const [state, setState] = useState<PaymentReportFormState>({
    reportType: PAYMENT_REPORT_TYPES.BY_VALIDATOR,
    reportFromDate: null,
    reportToDate: null,
  });

  // effects

  useEffect(() => {
    if (!show) {
      setState({
        reportType: PAYMENT_REPORT_TYPES.BY_VALIDATOR,
        reportFromDate: null,
        reportToDate: null,
      });
    }
  }, [show]);

  // fetch functions

  const fetchAttemptsRewardsSummary = useCallback(
    async (filtersQuery = {}): Promise<RewardsSummaryDataObject> => {
      try {
        const response: FetchAPIResponse<RewardsSummaryDataObject> =
          await fetchArgusFilteringAPI("attempts/rewards-summary-values/filtered/", {
            method: "post",
            data: filtersQuery,
          });
        return response.data;
      } catch (err: any) {
        logAsyncOperationError("fetchAttemptsRewardsSummary", err);
        showModalError(
          "Error occurred while fetching rewards summary. Please, try again later."
        );
        return Promise.reject(err);
      }
    },
    [showModalError, fetchArgusFilteringAPI]
  );

  // handlers

  const handleChangeReportType = useCallback((value: PAYMENT_REPORT_TYPES_TYPE) => {
    setState((prevState) => ({
      ...prevState,
      reportType: value,
    }));
  }, []);

  const handleChangeReportFromDate = useCallback((value: moment.Moment) => {
    setState((prevState) => ({
      ...prevState,
      reportFromDate: value.format(djangoDateFormat),
    }));
  }, []);

  const handleChangeReportToDate = useCallback((value: moment.Moment) => {
    setState((prevState) => ({
      ...prevState,
      reportToDate: value.format(djangoDateFormat),
    }));
  }, []);

  const handleMakeReport = useCallback(async () => {
    if (state.reportFromDate == null || state.reportToDate == null) return;

    const queryArgs: UrlQueryObject = {
      review_status: REVIEW_STATUS_TYPES.REVIEWED,
      ready_for_payment: READY_FOR_PAYMENT_TYPES.READY,
      free_validation: false,
      total_reward__isnull: false,
      total_reward__gt: 0,
    };

    if (state.reportFromDate != null) {
      queryArgs["completed_timestamp__gte"] = moment
        .tz(state.reportFromDate, "EST")
        .hour(0)
        .minute(0)
        .second(0)
        .utc()
        .format();
    }
    if (state.reportToDate != null) {
      queryArgs["completed_timestamp__lte"] = moment
        .tz(state.reportToDate, "EST")
        .hour(23)
        .minute(59)
        .second(59)
        .utc()
        .format();
    }

    const rewardsSummary: RewardsSummaryDataObject = await fetchAttemptsRewardsSummary(
      queryArgs
    );

    if (!rewardsSummary?.total_reward_count) {
      onClose();
      showModalWarning("No data found for the specified period.", "Info");
      return;
    }

    onMakeReport({
      reportType: state.reportType,
      reportFromDate: state.reportFromDate,
      reportToDate: state.reportToDate,
    });
  }, [state, onMakeReport, onClose, showModalWarning, fetchAttemptsRewardsSummary]);

  const renderForm = (
    <Grid
      css={{
        gridTemplateColumns: "repeat(2, auto)",
        gridColumnGap: "$2",
        gridRowGap: "$3",
        alignItems: "center",
        width: "$full",
      }}
    >
      <Text underline>Report Type:</Text>
      <RadioGroup value={state.reportType} onValueChange={handleChangeReportType}>
        <Inline>
          <RadioGroupItem value={PAYMENT_REPORT_TYPES.BY_VALIDATOR}>
            {PAYMENT_REPORT_TYPES_LABELS[PAYMENT_REPORT_TYPES.BY_VALIDATOR]}
          </RadioGroupItem>
          <RadioGroupItem value={PAYMENT_REPORT_TYPES.BY_COUNTRY}>
            {PAYMENT_REPORT_TYPES_LABELS[PAYMENT_REPORT_TYPES.BY_COUNTRY]}
          </RadioGroupItem>
          <RadioGroupItem value={PAYMENT_REPORT_TYPES.BY_CLIENT}>
            {PAYMENT_REPORT_TYPES_LABELS[PAYMENT_REPORT_TYPES.BY_CLIENT]}
          </RadioGroupItem>
        </Inline>
      </RadioGroup>
      <Text underline>Report Period From:</Text>
      <Inline>
        <SingleDatePicker
          date={state.reportFromDate && moment.utc(state.reportFromDate)}
          onDateChange={handleChangeReportFromDate}
        />
      </Inline>
      <Text underline>Report Period To:</Text>
      <Inline>
        <SingleDatePicker
          date={state.reportToDate && moment.utc(state.reportToDate)}
          onDateChange={handleChangeReportToDate}
        />
      </Inline>
    </Grid>
  );

  return (
    <Dialog open={show} onOpenChange={onClose}>
      <DialogContent css={{ minWidth: "700px" }}>
        <DialogTitle asChild>
          <Inline css={{ justifyContent: "space-between" }}>
            <Box>
              <Text as="h4">Select Payment Report Parameters</Text>
            </Box>
            <DialogClose asChild>
              <Icon icon="times" />
            </DialogClose>
          </Inline>
        </DialogTitle>
        <DialogDescription css={{ backgroundColor: "inherit" }} asChild>
          <Stack css={{ fontSize: "$sm", alignItems: "flex-start" }}>
            <Alert fill>
              <Text>
                <Text bold underline italic>
                  PLEASE NOTE
                </Text>
                : both dates are required.
              </Text>
            </Alert>
            {renderForm}
          </Stack>
        </DialogDescription>
        <DialogActions>
          <ButtonGroupRight fill reverse>
            <Button color="gray" size="normal" onClick={onClose}>
              Close
            </Button>
            <PromiseButton
              color="green"
              size="normal"
              loadingText="Making Report..."
              disabled={!state.reportFromDate || !state.reportToDate}
              onClick={handleMakeReport}
            >
              Make A Report
            </PromiseButton>
          </ButtonGroupRight>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
PaymentReportForm.displayName = "PaymentReportForm";

export default PaymentReportForm;
