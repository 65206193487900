// @flow

import { extendObservable, action } from "mobx";
import FilterObject, {
  FilterWithSort,
  ValueSetFilter,
  FilterCriteriaLoader,
  IViewItem,
} from "../Filter";
import type FilterColumn from "../Filter";
import { GraphQLQuery } from "../GraphQL";
import Sort from "../Sort";
import SortState from "../SortState";

export class TagContents implements IViewItem {
  value: string;
  selected: string;

  constructor(object: Object) {
    this.value = object.value;
    this.id = object.id;

    extendObservable(this, {
      selected: object.selected,
    });
  }
}

export default class TagContentFilter extends FilterCriteriaLoader(
  ValueSetFilter(FilterWithSort)
) {
  constructor(
    store: Object,
    column: FilterColumn,
    filterCriteriaQuery: (FilterColumn) => GraphQLQuery,
    processPayload: (FilterColumn, Object) => Array<Object>,
    applyFilter: (FilterColumn, FilterObject) => void,
    applySort: (FilterColumn, Sort) => void,
    removeFilter: (FilterColumn) => void,
    removeSort: (FilterColumn) => void,
    reloadCriteria: boolean = true
  ) {
    super(store, column);

    this.filterCriteriaQuery = filterCriteriaQuery;
    this.processPayload = processPayload;
    this.applyFilter = applyFilter;
    this.applySort = applySort;
    this.removeFilter = removeFilter;
    this.removeSort = removeSort;

    this.sortState = new SortState("COUNTRY");

    this.reloadCriteria = reloadCriteria;

    this.viewItemClass = TagContents;

    this.buildQueryFilter = action(this.buildQueryFilter.bind(this));
  }

  onInstantSearch(value: string) {
    if (super.onInstantSearch) super.onInstantSearch(value);

    if (!this.instantSearchValue) {
      this.viewItems = this.unfilteredViewItems;
      return;
    }

    this.viewItems = this.unfilteredViewItems.filter((item) => {
      const tag = item.value.toLowerCase();
      const query = this.instantSearchValue.toLowerCase();

      // this works because ~ is the binary inverse of a number and ~ -1 = 0
      return ~tag.indexOf(query);
    });
  }

  buildQueryFilter() {
    const owners = this.selectedValues
      .entries()
      .filter((entry) => entry[1] === true)
      .map((entry) => this.criteria.get(entry[0]).value);

    if (!owners.length) {
      return null;
    }

    let params = [];
    let args = [];
    let vars = {};

    if (owners.length) {
      params.push("$contentTypes: [TagsContentTypeEnums]");
      args.push("contentTypes: $contentTypes");
      vars.contentTypes = owners.map((userId) => {
        return userId;
      });
    }

    return new FilterObject(params.join(", "), args.join(", "), vars);
  }
}
