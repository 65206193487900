// @flow
import { extendObservable, action, runInAction } from "mobx";
import axios from "axios";
import NetworkState from "../../../models/NetworkState";
import MessageState from "../../../models/MessageState";
import ClientList from "../../../models/ClientList";
import ModalState from "../../../models/ModalState";
import type { FetchGraphQL } from "../../../App";
import { copyElementTextToClipboard } from "../../../utils/dom";

class ClientDetailPTStore {
  router: Object;
  network: NetworkState;
  clientDetail: ClientList;
  messaging: MessageState;
  clientId: number;

  clientName: String;
  searchLimit: number;
  limitedSearchFlag: boolean;
  shareRatecardsFlag: boolean;
  perSearchPricingFlag: boolean;
  isClientLibrary: boolean;
  hasThreeLevel: boolean;
  hasNumericLevelsOnExport: boolean;
  termsOfAgreement: boolean;
  activeFlag: boolean;
  isRefreshEligible: boolean;

  showHelpModal: boolean;
  showHelp: () => void;
  hideHelp: () => void;
  getClientDetails: () => void;
  isUpdateEditing: boolean;
  stopEditing: () => void;
  startEditing: () => void;
  saveEditedClientDetail: () => void;
  setSearchLimit: () => void;
  setLimitedSearchFlag: () => void;
  setShareRatecardsFlag: () => void;
  setRefreshEligible: () => void;
  setSearchPricingFlag: () => void;
  setClientLibraryFlag: () => void;
  setThreeLevel: () => void;
  setHasNumericLevelsOnExport: () => void;
  setTermsOfAgreement: () => void;
  setByUserTermsFlag: () => void;
  toggleActiveUser: () => void;
  messaging: MessageState;
  confirmActivateDeactivateModal: ModalState;
  showActiveModal: () => void;
  activeDeactiveBtnFlag: boolean;
  fetchGraphQL: FetchGraphQL;

  // copy signup link
  copySignupLink: () => void;
  copyHint: string;
  signupLinkMouseEnter: () => void;
  signupLinkMouseExit: () => void;

  constructor(fetchGraphQL: FetchGraphQL) {
    this.fetchGraphQL = fetchGraphQL;
    this.router = null;

    extendObservable(this, {
      clientId: null,
      network: new NetworkState(),
      renameNetwork: new NetworkState(),
      clientDetail: null,
      showHelpModal: false,
      messaging: new MessageState(),
      isUpdateEditing: false,
      searchLimit: 0,
      limitedSearchFlag: false,
      shareRatecardsFlag: false,
      perSearchPricingFlag: false,
      isClientLibrary: false,
      byUserTermsFlag: false,
      hasThreeLevel: false,
      hasNumericLevelsOnExport: false,
      isRefreshEligible: true,
      termsOfAgreement: false,
      clientName: "",
      confirmActivateDeactivateModal: new ModalState(),
      copyHint: "",
    });

    this.showHelp = action(this.showHelp.bind(this));
    this.hideHelp = action(this.hideHelp.bind(this));
    this.getClientDetails = action(this.getClientDetails.bind(this));
    this.stopEditing = action(this.stopEditing.bind(this));
    this.startEditing = action(this.startEditing.bind(this));
    this.saveEditedClientDetail = action(this.saveEditedClientDetail.bind(this));
    this.setSearchLimit = action(this.setSearchLimit.bind(this));
    this.setLimitedSearchFlag = action(this.setLimitedSearchFlag.bind(this));
    this.setShareRatecardsFlag = action(this.setShareRatecardsFlag.bind(this));
    this.setClientName = action(this.setClientName.bind(this));
    this.toggleActiveUser = action(this.toggleActiveUser.bind(this));
    this.showActiveModal = action(this.showActiveModal.bind(this));
    this.setSearchPricingFlag = action(this.setSearchPricingFlag.bind(this));
    this.setClientLibraryFlag = action(this.setClientLibraryFlag.bind(this));
    this.setThreeLevel = action(this.setThreeLevel.bind(this));
    this.setHasNumericLevelsOnExport = action(
      this.setHasNumericLevelsOnExport.bind(this)
    );
    this.setTermsOfAgreement = action(this.setTermsOfAgreement.bind(this));
    this.setRefreshEligible = action(this.setRefreshEligible.bind(this));
    this.setByUserTermsFlag = action(this.setByUserTermsFlag.bind(this));
    this.copySignupLink = action(this.copySignupLink.bind(this));
    this.signupLinkMouseEnter = action(this.signupLinkMouseEnter.bind(this));
    this.signupLinkMouseExit = action(this.signupLinkMouseExit.bind(this));
  }

  copySignupLink() {
    copyElementTextToClipboard("signupLink");
    this.copyHint = "Copied to clipboard!";
    setTimeout(() => (this.copyHint = ""), 3000);
  }

  signupLinkMouseEnter() {
    this.copyHint = "Left click to copy.";
  }

  signupLinkMouseExit() {
    this.copyHint = "";
  }

  showHelp() {
    this.showHelpModal = true;
  }

  hideHelp() {
    this.showHelpModal = false;
  }

  showActiveModal(value) {
    this.activeDeactiveBtnFlag = value;
    this.confirmActivateDeactivateModal.showModal();
  }

  stopEditing() {
    this.searchLimit = this.clientDetail.searchLimit;
    this.limitedSearchFlag = this.clientDetail.limitedSearchFlag;
    this.shareRatecardsFlag = this.clientDetail.shareRatecardsFlag;
    this.clientName = this.clientDetail.name;
    this.isUpdateEditing = false;
  }

  startEditing() {
    this.isUpdateEditing = true;
  }

  setSearchLimit(e: Object) {
    this.searchLimit = e.target.value;
  }

  setLimitedSearchFlag(value) {
    this.limitedSearchFlag = value === "false" ? false : true;
  }

  setShareRatecardsFlag(value) {
    this.shareRatecardsFlag = value === "false" ? false : true;
  }

  setSearchPricingFlag(value) {
    this.perSearchPricingFlag = value === "false" ? false : true;
  }

  setClientLibraryFlag(value) {
    this.isClientLibrary = value === "false" ? false : true;
  }

  setThreeLevel(value) {
    this.hasThreeLevel = value === "false" ? false : true;
  }

  setHasNumericLevelsOnExport(value) {
    this.hasNumericLevelsOnExport = value === "false" ? false : true;
  }

  setTermsOfAgreement(value) {
    this.termsOfAgreement = value === "false" ? false : true;
  }

  setByUserTermsFlag(value) {
    this.byUserTermsFlag = value === "false" ? false : true;
  }

  setRefreshEligible(value) {
    this.isRefreshEligible = value === "false" ? false : true;
  }

  setClientName(e: Event) {
    this.clientName = e.target.value;
  }

  toggleActiveUser(value) {
    this.activeFlag = value;
    this.saveEditedClientDetail();
  }

  async saveEditedClientDetail() {
    this.messaging.removeAll();

    if (!this.clientName.trim()) {
      this.messaging.createMessage("info", "Please enter a New Client Name.");
      return;
    }

    let variables = {
      client: {
        name: this.clientName,
        //searchLimit: this.searchLimit,
        //limitedSearchFlag: this.limitedSearchFlag ,
        shareRatecardsFlag: this.shareRatecardsFlag,
        activeFlag: this.activeFlag,
        cid: this.clientDetail.cid,
        editionCode: this.clientDetail.editionCode,
        // ??? -- Charley, this.termsOfAgreement works, but this.clientDetail.termsOfAgreement does not work, check with Richard
        // termsOfAgreement: this.clientDetail.termsOfAgreement,
        termsOfAgreement: this.termsOfAgreement,
        freeTrial: this.clientDetail.freeTrial,
        byUserTermsFlag: this.clientDetail.byUserTermsFlag,
        perSearchPricing: this.perSearchPricingFlag,
        isClientJobLibrary: this.isClientLibrary,
        hasThreeLevel: this.hasThreeLevel,
        hasNumericLevelsOnExport: this.hasNumericLevelsOnExport,
        // TODO: enable editting when avaiable in schema
        refreshEligible: this.isRefreshEligible,
      },
    };

    const query = `
      mutation updateClientDetail($client: ClientInput){
        updateClient(input : {client:$client, clientId:${this.clientId}}){
          ok
        }
      }
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
      // should hit get request
    } catch (e) {
      console.error("Error editing client detail", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }

    runInAction("saveEditedClientDetail--success", () => {
      this.getClientDetails();
      this.isUpdateEditing = false;
    });
  }

  async getClientDetails() {
    let res = null;
    if (!/^\d+$/.test(this.clientId)) {
      if (this.router) {
        this.router.push({
          pathname: "/404NotFound",
          query: this.router.query,
        });
      }
      return res;
    }
    const variables = {
      id: parseInt(this.clientId, 10),
    };
    const query = `
      query clientDetail($id : Int!){
        viewer{
          clientDetail(id:$id){
            clientId
            name
            activeFlag
            shareRatecardsFlag
            cid
            editionCode
            termsOfAgreement
            freeTrial
            byUserTermsFlag
            encodedClientId
            isClientJobLibrary
            perSearchPricing
            totalUserCount
            totalSearchesCount
            totalJobLabelsCount
            totalRatecardsCount
            totalProjectsCount
            totalNegotiationWorksheetsCount
            totalActiveUserCount
            totalInactiveUserCount
            hasThreeLevel
            hasNumericLevelsOnExport
            refreshEligible
          }
        }
      }
    `;

    this.network.loading = true;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting Client Details", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Client Details query", res);
      }
      return e;
    }
    return runInAction("getClientDetails--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get Client Details query", res)) {
        return {};
      }

      if (res.data.viewer && !res.data.viewer.clientDetail) {
        if (this.router) {
          this.router.push({
            pathname: "/404NotFound",
            query: this.router.query,
          });
        }
        return;
      }

      const client = res.data.viewer.clientDetail;
      this.clientDetail = new ClientList(this, client);
      this.searchLimit = this.clientDetail.searchLimit;
      this.limitedSearchFlag = this.clientDetail.limitedSearchFlag;
      this.shareRatecardsFlag = this.clientDetail.shareRatecardsFlag;
      this.clientName = this.clientDetail.name;
      this.isRefreshEligible = this.clientDetail.isRefreshEligible;
      this.activeFlag = this.clientDetail.active;
      this.perSearchPricingFlag = this.clientDetail.perSearchPricing;
      this.isClientLibrary = this.clientDetail.isClientJobLibrary;
      this.hasThreeLevel = this.clientDetail.hasThreeLevel;
      this.hasNumericLevelsOnExport = this.clientDetail.hasNumericLevelsOnExport;
      this.termsOfAgreement = this.clientDetail.termsOfAgreement;
    });
  }
}
export default ClientDetailPTStore;
