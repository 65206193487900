// @flow
import { action } from "mobx";

import FilterObject, { FilterWithSort, ContainsTextFilter } from "../Filter";
import type { FilterColumn } from "../Filter";
import Sort from "../Sort";
import SortState from "../SortState";

// $FlowFixMe: Need to make the superclass properties covariant to subtype
export default class ClientJobTitleClientTitleFilter extends ContainsTextFilter(
  FilterWithSort
) {
  sortState: SortState;
  sort: ?Sort;

  applySort: (FilterColumn, Sort) => void;
  removeSort: (FilterColumn) => void;

  buildQueryFilter: () => ?FilterObject;
  buildQuerySort: () => ?Sort;
  onApply: () => void;
  onReset: () => void;
  softReset: () => void;
  softResetSort: () => void;

  constructor(
    store: Object,
    column: FilterColumn,
    applyFilter: (FilterColumn, FilterObject) => void,
    applySort: (FilterColumn, Sort) => void,
    removeFilter: (FilterColumn) => void,
    removeSort: (FilterColumn) => void
  ) {
    super(store, column);

    this.applyFilter = applyFilter;
    this.applySort = applySort;
    this.removeFilter = removeFilter;
    this.removeSort = removeSort;

    this.sortState = new SortState("CLIENT_TITLE");

    this.buildQueryFilter = action(this.buildQueryFilter.bind(this));
    this.onApply = action(this.onApply.bind(this));
  }

  buildQueryFilter() {
    if (!this.textToLookFor || !this.textToLookFor.trim()) {
      return null;
    }

    return new FilterObject(
      "$clientTitleFilterText: String!",
      "clientRawTitleIContains: $clientTitleFilterText",
      {
        clientTitleFilterText: this.textToLookFor,
      }
    );
  }
}
