// @flow
import React, { Component } from "react";
import FlagIcon from "../../components/FlagIcon";
import numeral from "numeral";
import type { CountryLibraryNode } from "../../stores/mobx/JobLibraryListStore";
import { styled } from "../../../src/stitches.config";

const CountryJobLibListItem = styled("div", {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  background: "white",
  border: "1px solid #e6e6e6",
  borderRadius: "2px",
  color: "$brand",
  width: "230px",
  padding: "20px 20px 10px",
  marginBottom: "15px",
  textDecoration: "none",
  cursor: "pointer",
  transition: "border 150ms, box-shadow 250ms",
  height: "initial",
  "&:hover": { textDecoration: "none", border: "1px solid $brand" },
  variants: {
    color: {
      default: {
        backgroundColor: "#ffffff",
        boxShadow: "none",
      },
      selected: {
        backgroundColor: "$brandLightest",
        border: "1px solid $brandLight",
        boxShadow: "inset 0 3px $colors$brandLight",
      },
    },
  },
});

type Props = {
  library: CountryLibraryNode,
  name: string,
  countryCode: string,
  selected?: boolean,
  titlesFiltered?: boolean,
  totalJobs?: number,
  titlesCount?: number,
  onClick?: (CountryLibraryNode) => void,
};

class JobLibraryCountryListItem extends Component<Props> {
  props: Props;

  handleOnClick = () => {
    if (this.props.onClick) {
      this.props.onClick(this.props.library);
    }
  };

  render() {
    const props = this.props;
    const name = props.name;
    const countryCode = props.countryCode;
    const selected = props.selected || false;
    const titlesFiltered = props.titlesFiltered || false;
    const totalJobs = props.totalJobs || 0;
    const titlesCount = props.titlesCount || 0;

    return (
      <CountryJobLibListItem
        onClick={this.handleOnClick}
        color={selected ? "selected" : "default"}
      >
        <div className="cjl-list-item-name">
          {countryCode && <FlagIcon code={countryCode} />}
          {name}
        </div>
        <div>
          {titlesFiltered && (
            <div>
              <label className="cjl-matches-label">
                {numeral(titlesCount).format("0,0")} Title Matches
              </label>
            </div>
          )}
          {!titlesFiltered && (
            <span className="caption">
              {numeral(totalJobs).format("0,0")} {totalJobs === 1 ? "Job" : "Jobs"}
            </span>
          )}
          {/*<span className="caption">*/}
          {/*{numeral(totalJobs).format("0,0")} {totalJobs === 1 ? "Job" : "Jobs"}*/}
          {/*</span>*/}
        </div>
      </CountryJobLibListItem>
    );
  }
}

export default JobLibraryCountryListItem;
