import React, { useCallback } from "react";
// utils
import { usePayDifference } from "../../hooks/usePayDifference";
import Text from "../../../../components/lib/Text";
import Stack from "../../../../components/lib/Stack";
import Inline from "../../../../components/lib/Inline";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../components/lib/DropdownMenu";
import Button from "../../../../components/lib/Button";
import { THead, TR, Table, TD, TBody } from "../../../../components/lib/Table";
import {
  MarketRateValue,
  MarketRateValues,
  useSearchResultsStore,
} from "../../../../stores/SearchResults";

const selectStyles = { minWidth: 100, justifyContent: "space-between" };
const textCenter = { textAlign: "center" };

const RateNames = ["Pay Rate", "Bill Rate", "Markup"] as const;
type RateName = (typeof RateNames)[number];

type MarketRate = NonNullable<
  ReturnType<typeof usePayDifference>[number]["marketrates"]
>[number];

function getMarketRate(
  name: RateName,
  value: MarketRateValue,
  marketRate: MarketRate | undefined
) {
  if (!marketRate) return 0;

  type Cases = Record<
    RateName,
    Record<MarketRateValue, keyof Omit<NonNullable<MarketRate>, "level">>
  >;

  const cases: Cases = {
    "Bill Rate": {
      Average: "billRateAvg",
      Max: "billRateMax",
      Min: "billRateMin",
      Median: "billRateMid",
    },
    "Pay Rate": {
      Average: "payRateAvg",
      Max: "payRateMax",
      Min: "payRateMin",
      Median: "payRateMid",
    },
    Markup: {
      Average: "markupPctAvg",
      Max: "markupPctMax",
      Min: "markupPctMin",
      Median: "markupPctMid",
    },
  };

  const rate = cases[name][value];
  return Number(marketRate[rate] ?? 0);
}

type Props = {
  payDifference: ReturnType<typeof usePayDifference>;
  isContract: boolean;
};

export function DifferecenByLocationTable(props: Props) {
  const { payDifference, isContract } = props;

  const setState = useSearchResultsStore((s) => s.setComparisonTableUI);
  const state = useSearchResultsStore((s) => s.comparisonTableUI);

  const toCurrency = useSearchResultsStore((s) => s.currency);
  const rateFrequency = useSearchResultsStore((s) => s.rateFrequency);
  const getRate = useSearchResultsStore((s) => s.calculateRate);

  const calculateRate = useCallback(
    (fromCurrency: string, rate: number) => {
      return getRate(fromCurrency, toCurrency, rateFrequency, true)(rate, true);
    },
    [getRate, toCurrency, rateFrequency]
  );

  if (!isContract && state.type !== "Pay Rate") {
    setState({ type: "Pay Rate" });
  }

  return (
    <Stack css={{ width: "$full", alignItems: "flex-start" }}>
      <Inline css={{ gap: "$4", width: "$full", justifyContent: "space-between" }}>
        <Stack nogap css={{ alignItems: "flex-start" }}>
          <Text as="h4" noMargin>
            Comparison Table By Location
          </Text>
          <Text color="primary">
            The data presented on this table is across all experience levels.
          </Text>
        </Stack>
        <Inline>
          {!isContract ? (
            <Button
              disabled={true}
              css={selectStyles}
              text={state.type}
              iconRight="caret-down"
            />
          ) : (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button css={selectStyles} text={state.type} iconRight="caret-down" />
              </DropdownMenuTrigger>
              <DropdownMenuContent style={{ minWidth: 100 }}>
                {RateNames.map((name) => (
                  <DropdownMenuItem
                    key={name}
                    text={name}
                    onSelect={() => setState({ type: name })}
                  />
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          )}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button css={selectStyles} text={state.value} iconRight="caret-down" />
            </DropdownMenuTrigger>
            <DropdownMenuContent style={{ minWidth: 100 }}>
              {MarketRateValues.map((value) => (
                <DropdownMenuItem
                  key={value}
                  text={value}
                  onSelect={() => setState({ value })}
                />
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </Inline>
      </Inline>

      <Table highlighted={false}>
        <THead css={{ textTransform: "uppercase" }}>
          <TR>
            <TD>Location</TD>
            <TD css={textCenter}>Level 1</TD>
            <TD css={textCenter}>Level 2</TD>
            <TD css={textCenter}>Level 3</TD>
            <TD css={textCenter}>Level 4</TD>
            <TD css={textCenter}>Level 5</TD>
          </TR>
        </THead>
        <TBody>
          {payDifference.map((data) => {
            return (
              <TR key={data.key}>
                <TD>
                  {data?.region?.name || data.locationLabel || data.locationFullLabel}
                </TD>
                {data.marketrates?.map((level) => {
                  const rate = getMarketRate(state.type, state.value, level);

                  const result =
                    state.type === "Markup"
                      ? `${rate}%`
                      : calculateRate(data.currency?.iso ?? "USD", rate);

                  return (
                    <TD key={`level-${level?.level.legacyId}`} css={textCenter}>
                      {result}
                    </TD>
                  );
                })}
              </TR>
            );
          })}
        </TBody>
      </Table>
    </Stack>
  );
}
