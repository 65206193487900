// @flow
import * as React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import { styled } from "../../../stitches.config";

const StyledIcon = styled(FontAwesomeIcon, {
  // all: "unset",
  color: "$primaryLight",
  width: "$5",
  height: "$5",
  borderRadius: 2,
  marginTop: 2,
  // marginRight: "$2",
  // display: "flex",
  // alignItems: "center",
  // justifyContent: "center",
  "&:hover": { color: "$accent" },
  '&[data-state="checked"]': {
    color: "$accent",
  },
  variants: {
    selected: {
      true: {
        color: "$accent",
      },
    },
  },
});

export const SelectableItemLabel = styled("label", {
  color: "$primaryDark",
  borderBottom: "none",
  borderRadius: 0,
  padding: "10px 20px",
  // font-size: 18px,
  // font-weight: normal,
  margin: 0,
  cursor: "pointer",
  position: "relative",

  '& input[type="checkbox"], & input[type="radio"]': {
    visibility: "hidden",
    position: "absolute",
    top: 0,
    left: 0,
  },
  "& .media": { margin: 0 },
  // "&:first-child": {
  //   borderTop: "1px solid $primaryLightest"
  // },

  // .fa.icon {
  //   margin-left: -4px,
  //
  //   & + span {
  //     margin-left: 10px,
  //   }
  // }

  "& .text-x-small": {
    color: "$primary",
    svg: {
      width: 14,
      height: 12,
      paddingRight: 2,
    },

    // .fa.icon {
    //   margin-left: 0,
    //   font-size: 13px,
    //
    //   & + span {
    //     margin-left: 5px,
    //   }
    // }
  },

  // borderBottom: "1px solid $primaryLighter",
  // cursor: "pointer",
  // padding: "10px 16px",
  transition: "background-color .2s",
  "&:hover": {
    color: "$brand",
    backgroundColor: "$brandLightest",
  },
  variants: {
    selected: {
      true: {
        color: "$brand",
        backgroundColor: "$brandLightest",
        "& .text-x-small:": {
          color: "$brandDark",
        },
      },
    },
  },
});
SelectableItemLabel.displayName = "SelectableItemLabel";

class SelectableItem extends React.Component {
  static generatedCount = 0;

  getGeneratedId = () => {
    SelectableItem.generatedCount += 1;
    return `input-${SelectableItem.generatedCount}`;
  };

  handleOnChange = (e) => {
    if (this.props.onChange && !this.props.checkBoxDisabled)
      this.props.onChange(this.props.item);
  };

  render() {
    const props = this.props;
    let checkboxIcon = "";
    if (props.type === "checkbox") {
      checkboxIcon = props.selected ? "check-square" : "square";
    } else if (props.type === "radio") {
      checkboxIcon = props.selected ? "dot-circle" : "circle";
    }

    const inputId = props.id || this.getGeneratedId();
    return (
      <SelectableItemLabel
        // className={classNames("rc-selectable-item", {
        //   selected: props.selected || props.highlighted
        // })}
        selected={props.selected}
        css={props.style}
        role="listitem"
        htmlFor={inputId}
      >
        <input
          id={inputId}
          type={props.type}
          value={props.value}
          checked={props.selected}
          onChange={this.handleOnChange}
        />
        <div className="media">
          <div className="media-left media-middle">
            {props.checkBoxDisabled && (
              <Tippy
                placement="top"
                content={
                  <span>
                    {props.checkBoxDisabledTooltip || "Not available for selection"}
                  </span>
                }
              >
                <div>
                  <StyledIcon
                    data-testid="checkboxIcon"
                    icon={props.checkBoxDisabledIcon || checkboxIcon}
                    css={{ opacity: 0.5 }}
                    selected={props.selected}
                    // className={classNames("rc-check-icon fa-fw", {
                    //   selected: props.selected
                    // })}
                  />
                </div>
              </Tippy>
            )}
            {!props.checkBoxDisabled && (
              <StyledIcon
                data-testid="checkboxIcon"
                icon={checkboxIcon}
                selected={props.selected}
                // className={classNames("rc-check-icon fa-fw", {
                //   selected: props.selected
                // })}
              />
            )}
          </div>
          <div
            className="media-body"
            onClick={(e) => {
              if (props.ignoreBodyClick) {
                e.stopPropagation();
                e.preventDefault();
              }
            }}
          >
            {props.children}
          </div>
        </div>
      </SelectableItemLabel>
    );
  }
}

SelectableItem.propTypes = {
  item: PropTypes.object,
  type: PropTypes.string, // radio, checkbox
  name: PropTypes.string,
  value: PropTypes.any,
  selected: PropTypes.bool,
  highlighted: PropTypes.bool,
  onChange: PropTypes.func,
  ignoreBodyClick: PropTypes.bool,
  checkBoxDisabled: PropTypes.bool,
  checkBoxDisabledTooltip: PropTypes.node,
  checkBoxDisabledIcon: PropTypes.string,
};

SelectableItem.defaultProps = {
  type: "checkbox",
  selected: false,
  onChange: (e) => e,
};

export default observer(SelectableItem);
