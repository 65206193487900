// @flow strict
import * as React from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "../../stitches.config";

const StyledIcon = styled(FontAwesomeIcon, {
  // all: "unset",
  color: "$primaryLight",
  borderRadius: 2,
  marginTop: 2,
  // marginRight: "$2",
  // display: "flex",
  // alignItems: "center",
  // justifyContent: "center",
  "&:hover": { color: "$accent" },
  '&[data-state="checked"]': {
    color: "$accent",
  },
  "&.svg-inline--fa": {
    width: "$5",
    height: "$5",
  },
  variants: {
    selected: {
      true: {
        color: "$accent",
      },
    },
  },
});

export const SelectableItemLabel = styled("label", {
  color: "$primaryDark",
  borderBottom: "none",
  borderRadius: 0,
  // padding: "10px 20px",
  // font-size: 18px,
  // font-weight: normal,
  margin: 0,
  cursor: "pointer",
  position: "relative",

  '& input[type="checkbox"], & input[type="radio"]': {
    visibility: "hidden",
    position: "absolute",
    top: 0,
    left: 0,
  },
  "& .media": { margin: 0 },
  // "&:first-child": {
  //   borderTop: "1px solid $primaryLightest"
  // },

  // .fa.icon {
  //   margin-left: -4px,
  //
  //   & + span {
  //     margin-left: 10px,
  //   }
  // }

  "& .text-x-small": {
    color: "$primary",
    svg: {
      width: 14,
      height: 12,
      paddingRight: 2,
      marginRight: "$2",
    },

    // .fa.icon {
    //   margin-left: 0,
    //   font-size: 13px,
    //
    //   & + span {
    //     margin-left: 5px,
    //   }
    // }
  },

  // borderBottom: "1px solid $primaryLighter",
  // cursor: "pointer",
  // padding: "10px 16px",
  transition: "background-color .2s",
  "&:hover": {
    color: "$brand",
    // backgroundColor: "$brandLightest"
  },
  variants: {
    selected: {
      true: {
        color: "$brand",
        // backgroundColor: "$brandLightest",
        "& .text-x-small:": {
          color: "$brandDark",
        },
      },
    },
  },
});
SelectableItemLabel.displayName = "SelectableItemLabel";

type Props<Item, Value> = {
  onChange?: (Value, ?Item) => void,
  onClick?: (Value) => void,
  item?: Item,
  value: Value,
  selected: boolean,
  style?: {
    margin?: string,
    padding?: string,
    width?: number | string,
  },
  type: "radio" | "checkbox",
  key?: string,
  disabled?: boolean,
  children?: React.ChildrenArray<React.Node>,
  labelName?: string,
};

class CheckBox<T, V> extends React.Component<Props<T, V>, void> {
  handleOnChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    if (this.props.onChange) {
      if (!this.props.item && this.props.value) {
        this.props.onChange(this.props.value);
      } else if (this.props.item && !this.props.value) {
        this.props.onChange(this.props.value);
      } else if (this.props.item && this.props.value) {
        // $FlowFixMe: Must check all usages first before fixing
        this.props.onChange(this.props.item, this.props.value);
      } else {
        // $FlowFixMe: Figure out why we'd pass through the event like this
        this.props.onChange(e);
      }
    }
  };

  handleOnClick = (e: SyntheticEvent<HTMLInputElement>) => {
    // console.log("onclick: " + this.props.value)
    if (this.props.onClick) {
      if (!this.props.item && this.props.value) {
        this.props.onClick(this.props.value, e);
      } else if (this.props.item && !this.props.value) {
        this.props.onClick(this.props.value, e);
      } else if (this.props.item && this.props.value) {
        // $FlowFixMe: Must check all usages first before fixing
        this.props.onClick(this.props.item, this.props.value, e);
      } else {
        // $FlowFixMe: Figure out why we'd pass through the event like this
        this.props.onClick(e);
      }
    }
  };

  render() {
    const props = this.props;

    return (
      <SelectableItemLabel
        key={props.key}
        selected={props.selected}
        css={props.style}
        htmlFor={props.children || props.labelName ? props.id : null}
      >
        <input
          id={props.id}
          type={props.type}
          value={props.value}
          checked={props.selected}
          onChange={this.handleOnChange}
          onClick={this.handleOnClick}
          disabled={props.disabled}
        />
        <div className="media no-margin">
          <div className="media-left media-top" data-testid="search-checkbox">
            {props.type === "checkbox" && (
              <StyledIcon
                icon={props.selected ? "check-square" : "square"}
                selected={props.selected}
              />
            )}
            {props.type === "radio" && (
              <StyledIcon
                icon={props.selected ? "dot-circle" : "circle"}
                selected={props.selected}
              />
            )}
          </div>
          <div className="media-body" data-testid={props.dataTestId || props.id}>
            {props.children}
            {props.labelName}
          </div>
        </div>
      </SelectableItemLabel>
    );
  }
}

export default observer(CheckBox);
