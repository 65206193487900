import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RegionLocationSelectNew from "../../../components/selects/RegionLocationSelect";
import Modal from "../../ratecards/components/Modal";
import ContainerSection from "../../ratecards/components/ContainerSection";
import InfiniteScroll from "react-infinite-scroller";
import { observer } from "mobx-react";
import SelectableItemsList from "../../../components/SelectableItemsList";
import SelectableItem from "../../ratecards/components/SelectableItem";
import NonIdealState from "../../job_library/components/NonIdealState";
import TickerLoader from "../../../components/lib/TickerLoader";
import Button from "../../../components/lib/Button";
import Inline from "../../../components/lib/Inline";

function remove_duplicates(objectsArray) {
  var usedObjects = {};

  for (var i = objectsArray.length - 1; i >= 0; i--) {
    var so = JSON.stringify(objectsArray[i]);

    if (usedObjects[so]) {
      objectsArray.splice(i, 1);
    } else {
      usedObjects[so] = true;
    }
  }

  return objectsArray;
}

class RegionLocationModal extends Component {
  render() {
    const { store, button } = this.props;
    var selectedLocations = [];

    var locationsUnique = remove_duplicates(store.selectedLocations);
    locationsUnique.map((e) => {
      if (e) {
        var label = "";
        if (e.state && e.state.id) {
          label = e.state.name;
        }
        if (e.city && e.city.id) {
          label = e.city.name + ", " + label;
        }
        if (e.title) {
          label = e.title;
        }
        var object = {
          value: e.locationId,
          label: label,
        };
        selectedLocations.push(object);
      }
      return false;
    });

    return (
      <Modal
        show={store.showLocationPopup}
        onHide={store.closeModel}
        fullScreen
        innerStyle={{
          width: "70%",
        }}
      >
        <ContainerSection className="header">
          <h4>Add Location to Region</h4>
        </ContainerSection>
        <ContainerSection>
          <RegionLocationSelectNew
            placeholder={`Search for ${store.country} locations here or navigate below`}
            locationTitle={(param) => store.locationTitle(param)}
            onChange={(locations) => store.onLocationChange(locations)}
            value={selectedLocations}
          />

          <div className="row text-x-small" style={{ margin: "1em 0 0" }}>
            <div className="col-sm-6 no-padding">
              <label className="text-x-small">
                {store.country}
                {store.country && store.country.endsWith("s") ? "'" : "'s"}{" "}
                States/Provinces
              </label>
            </div>
            <div className="col-sm-6 no-padding">
              <label className="text-x-small">
                {store.selectedState.name
                  ? `${store.selectedState.name}${
                      store.selectedState.name.endsWith("s") ? "' " : "'s "
                    }`
                  : ""}
                Cities
              </label>
            </div>
          </div>

          <div className="select-list">
            <div className="col-sm-6">
              <SelectableItemsList
                className="no-margin"
                style={{ overflowY: "scroll", height: "40vh" }}
              >
                {store.stateList.map((state, index) => {
                  const isSelected = selectedLocations.find(
                    (val) =>
                      parseInt(val.value, 10) === parseInt(state.node.locationId, 10)
                  );
                  const itemBody = (
                    <div
                      className="flex-row flex-align-center no-padding"
                      onClick={() =>
                        store.getCities(state.node.locationId, state.node.name)
                      }
                    >
                      <span
                        title={state.node.name}
                        className="flex-fill"
                        style={{ flexBasis: "84%" }}
                      >
                        {state.node.name}
                      </span>
                      {store.selectedState.name === state.node.name ? (
                        <FontAwesomeIcon icon="chevron-right" className="fa-fw" />
                      ) : (
                        <FontAwesomeIcon
                          icon="angle-right"
                          className="text-muted fa-fw"
                          style={{ opacity: 0.4 }}
                        />
                      )}
                    </div>
                  );
                  return (
                    <SelectableItem
                      key={index}
                      id={state.node.locationId}
                      checkBoxDisabled={!state.node.isActive}
                      checkBoxDisabledIcon="ban"
                      checkBoxDisabledTooltip={
                        <span>
                          This State/Province is not available
                          <br />
                          for selection as part of a region
                          <br />
                          but you can select it's cities.
                        </span>
                      }
                      ignoreBodyClick={true}
                      item={state}
                      selected={Boolean(isSelected)}
                      onChange={(state) => {
                        if (!state.node.isActive) return;
                        store.getCities(state.node.locationId, state.node.name);
                        if (!state.node.isActive) {
                          // do nothing
                        } else if (isSelected) {
                          store.setLocationClick(
                            state.node.locationId,
                            state.node.name,
                            "state",
                            "remove"
                          );
                        } else {
                          store.setLocationClick(
                            state.node.locationId,
                            state.node.name,
                            "state",
                            "add"
                          );
                        }
                      }}
                    >
                      {itemBody}
                    </SelectableItem>
                  );
                })}
              </SelectableItemsList>
            </div>
            <div className="col-sm-6">
              {store.citiesNetwork.loading && (
                <NonIdealState
                  description={<TickerLoader />}
                  style={{ height: "40vh" }}
                />
              )}
              {!store.citiesNetwork.loading && !Boolean(store.cityList.length) && (
                <NonIdealState
                  description={<div>Click on a State/Province to see it's cities</div>}
                  style={{ height: "40vh" }}
                />
              )}
              {!store.citiesNetwork.loading && Boolean(store.cityList.length) && (
                <SelectableItemsList className="no-margin">
                  <InfiniteScroll
                    loadMore={store.getCities}
                    hasMore={store.loadMoreCities}
                    useWindow={false}
                  >
                    {store.cityList.map((city, index) => {
                      const isSelected = selectedLocations.find(
                        (val) =>
                          parseInt(val.value, 10) === parseInt(city.node.locationId, 10)
                      );
                      return (
                        <SelectableItem
                          key={index}
                          id={city.node.locationId}
                          ignoreBodyClick={true}
                          item={city}
                          selected={Boolean(isSelected)}
                          checkBoxDisabledTooltip={
                            <span>
                              All cities are included <br />
                              if the state is selected
                            </span>
                          }
                          onChange={(city) => {
                            if (isSelected) {
                              store.setLocationClick(
                                city.node.locationId,
                                city.node.name,
                                "city",
                                "remove"
                              );
                            } else {
                              store.setLocationClick(
                                city.node.locationId,
                                city.node.name,
                                "city",
                                "add"
                              );
                            }
                          }}
                        >
                          <div
                            className="flex-row flex-align-center no-padding"
                            onClick={() =>
                              store.selectCity(city.node.name, city.node.locationId)
                            }
                          >
                            <span className="flex-fill" style={{ flexBasis: "84%" }}>
                              {city.node.name}
                            </span>
                          </div>
                        </SelectableItem>
                      );
                    })}
                  </InfiniteScroll>
                </SelectableItemsList>
              )}
            </div>
            <div className="clearfix" />
          </div>
          <div className="clearfix" />
        </ContainerSection>
        <ContainerSection className="footer">
          <Inline css={{ justifyContent: "flex-end" }}>
            <Button size="large" onClick={store.closeModel}>
              Cancel
            </Button>
            {button}
          </Inline>
        </ContainerSection>
      </Modal>
    );
  }
}

RegionLocationModal.propTypes = {
  store: PropTypes.object.isRequired,
};

export default observer(RegionLocationModal);
