import React from "react";
import DocumentTitle from "react-document-title";
import { RecoilRoot } from "recoil";
// TODO get rid of "query-string" lib and use the "buildUrlQueryString" below
// import { buildUrlQueryString } from "./utils";
import queryString from "query-string";

// @ts-expect-error
import MobXStore from "../../stores/mobx/MobXStore";
import Container from "../../components/lib/Container";
import Footer from "../../components/lib/Footer";
import NavBar from "./components/Val5KNavBar";
import SurveyAuthForm from "./components/SurveyAuthForm";

import { omitObjectKeys } from "../../utils/object";
import { useGlobalContext } from "../../globalContext";
import Val5KPublicContext from "./context/Val5KPublicContext";

import { getAuthCookie } from "./auth";
import { baseUrlPath } from "./constants";

import type { Location } from "history";
import type { FetchAPIFunc, FetchGraphQLAPIFunc } from "../../types/fetch";
import type { InjectedRouter } from "react-router";
import type { Val5KPublicContextObject } from "./context/Val5KPublicContext";

type Val5kPublicEntryProps = {
  router: InjectedRouter;
  location: Location;
  store: MobXStore;
  fetchAPINew: FetchAPIFunc;
  fetchGraphQLNew: FetchGraphQLAPIFunc;
  children: React.ReactElement;
};

const Val5KPublicEntry = (props: Val5kPublicEntryProps) => {
  const { children } = props;

  const globalContextValues = useGlobalContext();
  const { router, fetchAPINew, showModalError } = globalContextValues;

  // custom Argus fetch functions

  const fetchArgusAPI: FetchAPIFunc = React.useCallback(
    async (path, options = {}) => {
      const resultingOptions = options || {};

      resultingOptions.headers = {
        accept: "application/json",
        ...(resultingOptions.headers || {}),
      };

      // "public" prefix added to the path
      // special nginx proxy will handle that and won't require Auth for the paths marked with this prefix
      // see the .../docker/prod/nginx.conf.tmpl
      return await fetchAPINew(`api/argus/public/v1/${path}`, resultingOptions);
    },
    [fetchAPINew]
  );

  const fetchArgusFilteringAPI: FetchAPIFunc = React.useCallback(
    async (path, options = {}) => {
      const resultingOptions = options || {};

      resultingOptions.method = "post";
      resultingOptions.headers = {
        "content-type": "application/x-www-form-urlencoded",
        ...(resultingOptions.headers || {}),
      };
      resultingOptions.data = resultingOptions.data || {};
      // resultingOptions.data = buildUrlQueryString(resultingOptions.data);
      resultingOptions.data = queryString.stringify(resultingOptions.data);

      return await fetchArgusAPI(path, resultingOptions);
    },
    [fetchArgusAPI]
  );

  // navigation

  const navigateToSurveysList = React.useCallback(
    () => router.push(`${baseUrlPath}/surveys`),
    [router]
  );

  // auth

  const authData = getAuthCookie();

  if (!authData) {
    return (
      <SurveyAuthForm fetchArgusAPI={fetchArgusAPI} showModalError={showModalError} />
    );
  }

  // context values

  const val5KPublicContextValues = {
    ...omitObjectKeys(globalContextValues, ["fetchAPINew", "fetchGraphQLNew"]),
    //
    fetchAPI: globalContextValues.fetchAPINew,
    fetchGraphQL: globalContextValues.fetchGraphQLNew,
    fetchArgusAPI,
    fetchArgusFilteringAPI,
    //
    navigateToSurveysList,
    auth: authData,
  } as Val5KPublicContextObject;

  // render

  return (
    <Val5KPublicContext.Provider value={val5KPublicContextValues}>
      <NavBar />
      <Container
        css={{
          position: "relative",
          fontSize: "14px",
          paddingTop: "$4",
          paddingLeft: "$4",
          paddingRight: "$4",
          width: "100%",
        }}
      >
        <DocumentTitle title="Validate Survey" />
        <RecoilRoot>
          {React.cloneElement(React.Children.only(children), val5KPublicContextValues)}
        </RecoilRoot>
      </Container>
      <Footer />
    </Val5KPublicContext.Provider>
  );
};

Val5KPublicEntry.displayName = "Val5KPublicEntry";

export type CommonChildPageProps = Val5KPublicContextObject;

export default Val5KPublicEntry;
