/**
 * @generated SignedSource<<c22bec5dfc2e99935c8de0944d80b9d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DescriptionFieldRefetch$variables = {
  jobTitleId?: number | null;
  skipQuery?: boolean | null;
};
export type DescriptionFieldRefetch$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"DescriptionField_viewer">;
  } | null;
};
export type DescriptionFieldRefetch = {
  response: DescriptionFieldRefetch$data;
  variables: DescriptionFieldRefetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": 0,
    "kind": "LocalArgument",
    "name": "jobTitleId"
  },
  {
    "defaultValue": true,
    "kind": "LocalArgument",
    "name": "skipQuery"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DescriptionFieldRefetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "jobTitleId",
                "variableName": "jobTitleId"
              },
              {
                "kind": "Variable",
                "name": "skipQuery",
                "variableName": "skipQuery"
              }
            ],
            "kind": "FragmentSpread",
            "name": "DescriptionField_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DescriptionFieldRefetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "skipQuery",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "jobTitleId"
                  }
                ],
                "concreteType": "JobTitleDetailNode",
                "kind": "LinkedField",
                "name": "jobTitle",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "367e9291c059a7a8dbf8713de737f9b3",
    "id": null,
    "metadata": {},
    "name": "DescriptionFieldRefetch",
    "operationKind": "query",
    "text": "query DescriptionFieldRefetch(\n  $jobTitleId: Int = 0\n  $skipQuery: Boolean = true\n) {\n  viewer {\n    ...DescriptionField_viewer_18RZks\n  }\n}\n\nfragment DescriptionField_viewer_18RZks on Viewer {\n  jobTitle(id: $jobTitleId) @skip(if: $skipQuery) {\n    description\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "71ba2d0ad175f0f9da65982ef533c5af";

export default node;
