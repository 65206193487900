import React from "react";

import Icon from "../../lib/Icon";
import { styled, CSS } from "../../../stitches.config";

type ClearFiltersButtonProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  css?: CSS;
};

const ClearButton = styled("button", {
  display: "flex",
  position: "absolute",
  width: "$4xl",
  height: "$4xl",
  top: "-$3",
  left: "-$3",
  padding: "4px",
  borderRadius: "$full",
  fontSize: "$xs",
  cursor: "pointer",
  backgroundColor: "$brand",
  color: "$textLight",
  "&:hover": {
    transform: "scale(1.1)",
  },
  "&:active": {
    transform: "none",
  },
});

const ClearFiltersButton = (props: ClearFiltersButtonProps) => {
  const { onClick, css } = props;

  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e?.stopPropagation?.();
      if (onClick) onClick(e);
    },
    [onClick]
  );

  return (
    <ClearButton title="clear all filters" onClick={handleClick} css={css}>
      <Icon icon="sync" />
    </ClearButton>
  );
};

ClearFiltersButton.displayName = "ClearFiltersButton";

export default ClearFiltersButton;
