// @flow

import { extendObservable, action, runInAction } from "mobx";
import NetworkState from "../../models/NetworkState";
import ModalState from "../../models/ModalState";
import CCCCountry from "../../models/CCCCountry";
import matchSorter from "match-sorter";
import React from "react";
import type { Element } from "react";
import type { FetchAPI, FetchGraphQL } from "../../App";
import { createTasteGraphQLWrapper, flattenDataProp } from "./SupportFunctions";

export default class JobLibraryRequestCreateStore {
  fetchTasteGraphQL: FetchGraphQL;
  fetchGraphQL: FetchGraphQL;
  fetchAPI: FetchAPI;
  router: ?Object;
  network: NetworkState;
  userCountriesNetwork: NetworkState;
  successModal: ModalState;
  previousLocation: string;
  title: string;
  description: string;
  userMessage: string;
  titleError: string;
  jobTitlesFile: ?File;
  uploadButtonText: string;
  selectedCountries: CCCCountry[];
  selectedCountriesError: string;
  userCountryCodes: string[];
  cccCountries: CCCCountry[];
  filteredCountries: CCCCountry[];
  loadingCountries: boolean;
  countryFilterQuery: string;

  submitRequest: () => void;
  getCCCCountries: () => Promise<any>;
  getUserCountries: () => Promise<any>;
  reset: () => void;
  showSuccess: () => void;
  isValid: () => void;
  goBack: () => void;

  onTitleChange: (SyntheticInputEvent<HTMLInputElement>) => void;
  onDescriptionChange: (SyntheticInputEvent<HTMLInputElement>) => void;
  onUserMessageChange: (SyntheticInputEvent<HTMLInputElement>) => void;
  onJobTitlesFileChange: (SyntheticInputEvent<HTMLInputElement>) => void;
  onCountryFilterQueryChange: (string) => void;
  filterCountries: (string) => CCCCountry[];
  onAddSelectedCountry: (CCCCountry) => void;
  onRemoveSelectedCountry: (CCCCountry) => void;
  onSelectAllCountries: (string, Event) => void;
  onSelectNoneCountries: (string, Event) => void;

  errorMessage: Element<any>;
  errorModal: ModalState;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI) {
    this.fetchTasteGraphQL = createTasteGraphQLWrapper(fetchAPI);
    this.fetchGraphQL = fetchGraphQL;
    this.fetchAPI = fetchAPI;

    // NOTE: Bound early to pass into pagination state
    extendObservable(this, {
      successModal: new ModalState(),
      network: new NetworkState(),
      userCountriesNetwork: new NetworkState(),
      title: "",
      description: "",
      userMessage: "",
      jobTitlesFile: null,
      titleError: "",
      previousLocation: "/job-library/library-requests/",
      uploadButtonText: "Upload Titles & Descriptions",
      userCountryCodes: [],
      cccCountries: [],
      selectedCountries: [],
      selectedCountriesError: "",
      filteredCountries: [],
      loadingCountries: false,
      countryFilterQuery: "",
      errorMessage: "",
      errorModal: new ModalState(),
    });

    this.router = null;

    this.submitRequest = action(this.submitRequest.bind(this));
    this.getUserCountries = action(this.getUserCountries.bind(this));
    this.getCCCCountries = action(this.getCCCCountries.bind(this));
    this.reset = action(this.reset.bind(this));
    this.showSuccess = action(this.showSuccess.bind(this));
    this.isValid = action(this.isValid.bind(this));
    this.goBack = action(this.goBack.bind(this));
    this.onTitleChange = action(this.onTitleChange.bind(this));
    this.onJobTitlesFileChange = action(this.onJobTitlesFileChange.bind(this));
    this.onDescriptionChange = action(this.onDescriptionChange.bind(this));
    this.onUserMessageChange = action(this.onUserMessageChange.bind(this));
    this.onCountryFilterQueryChange = action(this.onCountryFilterQueryChange.bind(this));
    this.filterCountries = action(this.filterCountries.bind(this));
    this.onAddSelectedCountry = action(this.onAddSelectedCountry.bind(this));
    this.onRemoveSelectedCountry = action(this.onRemoveSelectedCountry.bind(this));
    this.onSelectAllCountries = action(this.onSelectAllCountries.bind(this));
    this.onSelectNoneCountries = action(this.onSelectNoneCountries.bind(this));
  }

  async getUserCountries(): Promise<any> {
    if (this.userCountriesNetwork.loading === true) {
      return;
    }

    const query = `
      query getUserCountries {
        viewer {
          countries {
            isoCode
          }
        }
      }
    `;

    this.userCountriesNetwork.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query);
    } catch (e) {
      // if (this.sevenApi.axios.isCancel(e)) {
      //   return e;
      // }

      this.userCountriesNetwork.handleError("Getting Client UserCountries", e);
      if (res !== null) {
        this.userCountriesNetwork.logGraphQLError("Get Client UserCountries query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getUserCountries--success", () => {
      this.userCountriesNetwork.loading = false;
      this.userCountriesNetwork.error = null;
      if (
        this.userCountriesNetwork.logGraphQLError("Get Client UserCountries query", res)
      ) {
        // TODO: Display user friendly error message
        return;
      }

      if (!res) {
        return;
      }

      const userCountries = res.data.viewer.countries;

      this.userCountryCodes = userCountries.map((userCountry) => {
        return userCountry.isoCode;
      });

      return this.userCountryCodes;
    });
  }

  async getCCCCountries(): Promise<any> {
    if (this.network.loading) {
      // NOTE: Debounces submitRequest
      return;
    }

    if (this.cccCountries.length) return;

    const query = `
      query requestCCCCountries($countryCodes: [String]!) {
        cccCountries(filters: {iso3166Alpha2CodeIn: $countryCodes}, order: [{field: TITLE}]) {
          edges {
            node {
              databaseId
              title
              iso3166Alpha2
            }
          }
        }
      }
    `;

    const variables = { countryCodes: this.userCountryCodes };

    this.network.loading = true;
    this.loadingCountries = true;
    let res: ?Object = null;

    try {
      res = await this.fetchTasteGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Requesting CCC Countries", e);
      // TODO: Display user friendly error message
      return;
    }

    return runInAction("getCCCCountries--success", () => {
      this.network.loading = false;
      this.network.error = null;
      this.loadingCountries = false;

      if (this.network.logGraphQLError("requestCCCCountries", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.network.loading = false;

      if (!res || !res.data) return;

      let cccCountries = [];
      if (res.data.cccCountries) {
        cccCountries = res.data.cccCountries.edges.map((edge) => {
          return new CCCCountry(this, edge.node);
        });
      }

      this.cccCountries = cccCountries;
      this.filteredCountries = cccCountries;
    });
  }

  async submitRequest() {
    if (this.network.loading) {
      // NOTE: Debounces submitRequest
      return;
    }

    if (!this.isValid()) return;

    // query.operationName = 'requestLibrary';
    const query = `
      mutation requestLibrary($libraryTitle: String!, $cccCountryIds: [ID]!, $libraryDescription: String, $userMessage: String){
        createClientLibraryRequest(input: {
            libraryTitle: $libraryTitle,
            cccCountryIds: $cccCountryIds,
            libraryDescription: $libraryDescription
            userMessage: $userMessage
          }) {

          success {
            message
          }

          errors {
            __typename
            ...on NotAllowedForCurrentUserError {
              message
            }
            ...on RequiredFieldEmptyError {
              message
            }
            ...on UnsupportedFileTypeError {
              message
            }
            ...on DoesNotExistError {
              message
            }
            ...on FileValidationError {
              row
              message
            }
          }
        }
      }
    `;

    const variables = {
      libraryTitle: this.title,
      cccCountryIds: this.selectedCountries.map((c) => c.id),
      libraryDescription: this.description,
      userMessage: this.userMessage,
    };

    let formData = new FormData();

    formData.append("operationName", "requestLibrary");
    formData.append("query", query);
    formData.append("variables", JSON.stringify(variables));
    // $FlowFixMe dunno how to fix
    formData.append("jobTitlesFile", this.jobTitlesFile);

    this.network.loading = true;
    let res: ?Object = null;

    try {
      res = await this.fetchAPI("taste/", formData);
    } catch (e) {
      this.network.handleError("Requesting Library", e);
      // TODO: Display user friendly error message
      return;
    }

    runInAction("submitRequest--success", () => {
      this.network.loading = false;
      this.network.error = null;

      res = flattenDataProp(res);

      if (this.network.logGraphQLError("createClientLibraryRequest", res)) {
        let errors = null;
        if (!errors && res) errors = res.errors;
        if (!errors && res && res.data) errors = res.data.errors;
        if (!errors && res && res.data && res.data.createClientLibraryRequest) {
          errors = res.data.createClientLibraryRequest.errors;
        }

        if (
          errors &&
          errors.length > 0 &&
          errors[0].__typename === "FileValidationError"
        ) {
          this.errorMessage = (
            <div>
              <p>
                Sorry! Your request could not be submitted. There are some problems with
                the uploaded file.
              </p>
              <div className="cjl-library-request-list">
                <div className="cjl-flex-table">
                  <div className="cjl-flex-table header">
                    <div className="column auto" style={{ width: 50 }}>
                      <span>Row</span>
                    </div>
                    <div className="column">
                      <span>Problem</span>
                    </div>
                  </div>

                  {errors.map((err) => (
                    <div
                      key={err.row}
                      className="cjl-flex-table row list-item"
                      style={{ justifyContent: "flex-start" }}
                    >
                      <div className="column auto" style={{ width: 50 }}>
                        <span>{err.row}</span>
                      </div>
                      <div className="column auto text-x-small" style={{ maxWidth: 450 }}>
                        <span>{err.message}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          );
          this.errorModal.showModal();
        } else {
          this.errorMessage = (
            <p>
              Sorry! Your Request could not be submitted. There was an error during the
              operation.
            </p>
          );
          this.errorModal.showModal();
        }
        return;
      } else {
        this.reset();
      }

      this.goBack();
      // this.showSuccess();
    });
  }

  reset() {
    this.title = "";
    this.jobTitlesFile = null;
    this.description = "";
    this.userMessage = "";
    this.uploadButtonText = "Upload Titles & Descriptions";
    this.successModal.hideModal();
    this.selectedCountries = [];
    this.loadingCountries = false;
    this.filteredCountries = this.cccCountries;
    this.countryFilterQuery = "";
  }

  isValid() {
    if (!this.title) this.titleError = "A title is required.";
    else this.titleError = "";

    // if (!this.selectedCountries.length)
    //   this.selectedCountriesError = 'Select at least one country.';
    // else
    //   this.selectedCountriesError = '';

    return !this.titleError; // && !this.selectedCountriesError;
  }

  goBack() {
    // console.log('previousLocation', this.previousLocation);
    // console.log('router', this.router);
    if (this.router) {
      this.router.goBack();
      // this.router.push({
      //   pathname: this.previousLocation,
      //   query: this.router.query
      // });
    }
  }

  showSuccess() {
    this.successModal.showModal();
  }

  onTitleChange(e: SyntheticInputEvent<HTMLInputElement>) {
    this.title = e.currentTarget.value;
    this.titleError = "";
  }

  onJobTitlesFileChange(e: SyntheticInputEvent<HTMLInputElement>) {
    const files = e.target.files;
    if (files && files.length > 0) {
      this.jobTitlesFile = files[0];
      this.uploadButtonText = this.jobTitlesFile.name;
    }
  }

  onDescriptionChange(e: SyntheticInputEvent<HTMLInputElement>) {
    this.description = e.currentTarget.value;
  }

  onUserMessageChange(e: SyntheticInputEvent<HTMLInputElement>) {
    this.userMessage = e.currentTarget.value;
  }

  onCountryFilterQueryChange(filterQuery: string) {
    this.countryFilterQuery = filterQuery;
    this.filteredCountries = this.filterCountries(filterQuery);
    this.selectedCountriesError = "";
  }

  filterCountries(value: string) {
    return value.trim()
      ? matchSorter(this.cccCountries, value.trim(), { keys: ["title"] })
      : this.cccCountries;
  }

  onAddSelectedCountry(country: CCCCountry) {
    this.selectedCountries.push(country);
    this.onCountryFilterQueryChange("");
  }

  onRemoveSelectedCountry(country: CCCCountry) {
    this.selectedCountries = this.selectedCountries.filter((c) => c !== country);
  }

  onSelectAllCountries(key: string, e: Event) {
    if (e) {
      e.preventDefault();
    }
    this.selectedCountries = this.cccCountries;
  }

  onSelectNoneCountries(key: string, e: Event) {
    if (e) {
      e.preventDefault();
    }
    this.selectedCountries = [];
  }
}
