/**
 * @generated SignedSource<<71c8b85a12cf897ad57767b67a7aed32>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DetailsQuery$variables = {
  searchID: number;
};
export type DetailsQuery$data = {
  readonly viewer: {
    readonly savedsearch: {
      readonly rateType: number | null;
      readonly rates: {
        readonly rawBillRates: ReadonlyArray<number | null> | null;
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"BellCurveBillRates_savedsearch">;
    } | null;
  } | null;
};
export type DetailsQuery = {
  response: DetailsQuery$data;
  variables: DetailsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchID"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "searchID"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rateType",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rawBillRates",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "SaveSearchNode",
            "kind": "LinkedField",
            "name": "savedsearch",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "RateSearchResponse",
                "kind": "LinkedField",
                "name": "rates",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "BellCurveBillRates_savedsearch"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "SaveSearchNode",
            "kind": "LinkedField",
            "name": "savedsearch",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "RateSearchResponse",
                "kind": "LinkedField",
                "name": "rates",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "perDiem",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rawMin",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rawAvg",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rawMid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rawMax",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CurrencyNode",
                "kind": "LinkedField",
                "name": "currency",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "iso",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "44b81b0a0655bb19d03fdcde0790b4c9",
    "id": null,
    "metadata": {},
    "name": "DetailsQuery",
    "operationKind": "query",
    "text": "query DetailsQuery(\n  $searchID: Int!\n) {\n  viewer {\n    savedsearch(id: $searchID) {\n      rateType\n      rates {\n        rawBillRates\n      }\n      ...BellCurveBillRates_savedsearch\n      id\n    }\n  }\n}\n\nfragment BellCurveBillRates_savedsearch on SaveSearchNode {\n  id\n  currency {\n    id\n    iso\n  }\n  rates {\n    rawBillRates\n    perDiem\n    rawMin\n    rawAvg\n    rawMid\n    rawMax\n  }\n}\n"
  }
};
})();

(node as any).hash = "c051f5f59172b978fe02341467895163";

export default node;
