import * as React from "react";
import AsyncSelect from "react-select/lib/Async";
import { reactSelectStyles, reactSelectTheme } from "../../../constants/reactSelect";
import { ActionMeta, OptionsType, ValueType } from "react-select/lib/types";
// @ts-ignore
import FlagIcon from "../../../components/FlagIcon";
import { Option } from "react-select/lib/filters";
import { MultiValueGenericProps } from "react-select/lib/components/MultiValue";
import { debounce } from "lodash";

const MultiValueLabel = (props: MultiValueGenericProps<Option>) => {
  const { data } = props;
  const title = (
    <span>
      <span>{data.data.name || data.data.title}</span>{" "}
      {Boolean(data.data.subtitle) && (
        <span className="item-subtext-muted ">({data.data.subtitle})</span>
      )}
    </span>
  );
  return (
    <div
      className="no-wrap flex"
      style={{ alignItems: "center", padding: "4px 2px 4px 6px" }}
    >
      {data.data.countryCode && <FlagIcon code={data.data.countryCode} />} {title}
    </div>
  );
};

interface Location {
  id: number;
  name?: string;
  title?: string;
  subtitle?: string;
  fullTitle?: string;
  fullSubtitle?: string;
  countryCode?: string;
  countryId?: number;
  highlight?: {
    title: string;
    subtitle: string;
  };
}

const mapToOptions = (locations: ReadonlyArray<Location>): Array<Option> => {
  if (!locations) return [];

  return locations.map((l) => {
    return {
      value: l.id.toString(),
      label: l.name || l.title || l.fullTitle || "-- No name --",
      data: l,
    };
  });
};

type OptionsCallback = (options: OptionsType<Option>) => void;
type LoadOptionsHandler = (
  inputValue: string,
  callback: OptionsCallback
) => Promise<any> | void;

type Props = {
  id?: string;
  locations?: ReadonlyArray<Location>;
  getLocations: (query: string) => Promise<Array<Location>>;
  onChange?: ((locations: Array<Location>) => void) | undefined;
  clientIsClientJobLibrary?: boolean;
};

function BatchSearchLocationSelect(props: Props): React.ReactNode {
  const { id, locations, getLocations, onChange } = props;

  const value = mapToOptions(locations || []);

  const loadOptions: LoadOptionsHandler = (
    inputValue: string,
    callback: OptionsCallback
  ) => {
    getLocations(inputValue)
      .then((locations) => callback(mapToOptions(locations)))
      .catch(() => callback([]));
  };

  const loadOptionsDebounced: LoadOptionsHandler = debounce(loadOptions, 250, {
    trailing: true,
    leading: false,
  });

  const handleChange = (value: ValueType<Option>, _actionMeta: ActionMeta) => {
    if (onChange) {
      onChange((value as Array<Option>).map((v) => v.data));
    }
  };

  const formatOptionLabel = (option: Option, _selectCtx: any) => {
    // return option.name || option.fullTitle || "-- No name --";
    const { countryCode, highlight } = option.data;
    let title: React.ReactNode =
      option.data.name || option.data.title || option.data.fullTitle || "-- No name --";
    if (highlight) {
      title = (
        <span>
          <span
            dangerouslySetInnerHTML={{
              __html: highlight.title,
            }}
          />{" "}
          {highlight && highlight.subtitle !== "" && (
            <span
              dangerouslySetInnerHTML={{
                __html: "(" + highlight.subtitle + ")",
              }}
              className="item-subtext-muted"
            />
          )}
        </span>
      );
    }

    return (
      <span key={option.data.id} className="no-wrap flex">
        {countryCode && <FlagIcon code={countryCode} />} {title}
      </span>
    );
  };

  const filterOption = (option: Option): boolean => {
    if (!value) return true;
    return !Boolean(value.find((l) => l.data.id === option.data.id));
  };

  return (
    <AsyncSelect
      components={{ MultiValueLabel }}
      inputId={id || "locationSelect"}
      name="locations"
      placeholder="Type to find your location..."
      isMulti
      cacheOptions={false}
      value={value}
      loadOptions={loadOptionsDebounced}
      onChange={handleChange}
      noOptionsMessage={(_inputValue) => "No locations found for your input..."}
      styles={reactSelectStyles}
      theme={reactSelectTheme}
      formatOptionLabel={formatOptionLabel}
      filterOption={filterOption}
    />
  );
}

export default BatchSearchLocationSelect;
