// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { styled } from "../../../stitches.config";
import { buttonBaseStyles } from "../../../components/lib/Button";

export const ToggleButtonLabel = styled("label", buttonBaseStyles, {
  $$borderThickness: "1px",
  color: "$primaryDark",
  border: "$$borderThickness solid $colors$primaryLight",
  backgroundColor: "white",
  position: "relative",
  transition: "all .2s",
  cursor: "pointer",
  borderRadius: "$rounded",
  padding: "4px $2_5",
  fontSize: "14px !important",
  lineHeight: "normal !important",
  margin: 0,
  '& input[type="checkbox"], & input[type="radio"]': {
    visibility: "hidden",
    position: "absolute",
    top: 0,
    left: 0,
  },
  "&:focus": {
    "$$pt-ringColor": "$colors$brandDark",
    "$$pt-ringOffsetColor": "$colors$brandLightest",
  },

  // &.btn-lg {
  //   border-radius: 6px,
  // }

  // &.selected,
  // &.selected:hover {
  //   color: $rc-green6,
  //   border: 2px solid lighten($rc-yellow, 6.8%) !important,
  //   background-color: $rc-green8,
  // }

  // TODO: figure out focus
  // "&:focus, &:focus-within, &:active": {
  //   boxShadow: "$$pt-ringOffsetShadow, $$pt-ringShadow, $$pt-shadow",
  //   "$$pt-ringColor": "$colors$brandDark",
  //   "$$pt-ringOffsetColor": "$colors$brandLightest"
  // },

  "&:hover": {
    color: "$brandDark",
    border: "$$borderThickness solid $colors$brand",
    backgroundColor: "$brandLightest",
  },

  // "&:active": {
  //   boxShadow: "none"
  // },

  // "&:last-child, &:only-child": {
  //   margin: 0
  // },

  // "&:not(:first-child)": {
  //   marginLeft: "$2"
  // },
  //
  // "& svg:first-child": {
  //   marginRight: "$1"
  // },

  "& .text-x-small": {
    color: "$primary",

    "& .fa.icon": {
      marginLeft: 0,
      // fontSize: "13px",

      "& + span": {
        marginLeft: 5,
      },
    },
  },
  variants: {
    borderless: {
      true: {
        border: "$$borderThickness solid transparent !important",
        backgroundColor: "transparent",
      },
    },
    large: {
      true: {
        borderRadius: "$lg",
        padding: "calc($4 + 1px) calc($8 + 1px) !important",
        fontSize: "$lg !important",
        lineHeight: "$lg !important",
      },
    },
    selected: {
      true: {
        color: "$brandDark",
        border: "$$borderThickness solid $colors$accent",
        backgroundColor: "$brandLightest",
        "&:hover": {
          border: "$$borderThickness solid $colors$accent",
        },
      },
    },
  },
});
ToggleButtonLabel.displayName = "ToggleButtonLabel";

export const ToggleButtonGroup = styled("div", {
  display: "inline-flex",
  gap: 1,
  padding: 0,
  backgroundColor: "$primaryLight",
  borderRadius: "$rounded",
  [`& ${ToggleButtonLabel}`]: {
    marginLeft: 0,
    borderRadius: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    zIndex: 0,
    padding: "5px $2_5",
    "&:focus": {
      zIndex: 1,
    },
    "&:hover": {
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
    "&:not(:first-child)": {
      marginLeft: 0,
    },
    "&:first-child": {
      paddingLeft: "calc($2_5 + 1px)",
      borderTopLeftRadius: "$rounded",
      borderBottomLeftRadius: "$rounded",
      borderLeftWidth: "$$borderThickness",
      "&:hover": {
        borderRightWidth: 0,
      },
    },
    "&:last-child": {
      paddingRight: "calc($2_5 + 1px)",
      borderTopRightRadius: "$rounded",
      borderBottomRightRadius: "$rounded",
      borderRightWidth: "$$borderThickness",
      "&:hover": {
        borderLeftWidth: 0,
      },
    },
  },
});
ToggleButtonGroup.displayName = "ToggleButtonGroup";

type Props = {
  item?: any,
  type?: "radio" | "checkbox",
  value?: any,
  name?: string,
  className?: string,
  selected?: boolean,
  onChange?: (any) => void,
  onClick?: (any) => void,
  large?: boolean,
  borderless?: boolean,
  style?: Object,
  children?: any,
};

class ToggleButton extends Component<Props> {
  props: Props;

  handleOnChange = (e: Event) => {
    if (this.props.onChange) this.props.onChange(this.props.item ? this.props.item : e);
  };

  handleOnClick = (e: Event) => {
    if (this.props.onClick) this.props.onClick(this.props.item ? this.props.item : e);
  };

  render() {
    const props = this.props;

    return (
      <ToggleButtonLabel
        tabIndex={0}
        large={props.large}
        borderless={props.borderless}
        selected={props.selected}
        // className={classNames("btn btn-toggle", props.className, {
        //   "btn-lg": props.large,
        //   borderless: props.borderless,
        //   selected: props.selected
        // })}
        style={props.style}
      >
        <input
          name={props.name}
          type={props.type}
          value={props.value}
          checked={props.selected || false}
          onChange={this.handleOnChange}
          onClick={this.handleOnClick}
        />
        <span>{props.children}</span>
      </ToggleButtonLabel>
    );
  }
}

ToggleButton.propTypes = {
  item: PropTypes.any,
  type: PropTypes.oneOf(["radio", "checkbox"]),
  name: PropTypes.string,
  value: PropTypes.any,
  className: PropTypes.string,
  selected: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  large: PropTypes.bool,
  borderless: PropTypes.bool,
};

ToggleButton.defaultProps = {
  type: "checkbox",
  value: false,
  selected: false,
  large: false,
  borderless: false,
  className: "",
};

export default observer(ToggleButton);
