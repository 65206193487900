import React, { useCallback } from "react";

import Icon from "../../../../components/lib/Icon";
import Text from "../../../../components/lib/Text";
import TextCrop from "../../../validator5K_admin/components/TextCrop";
import Button from "../../../../components/lib/Button";
import ReviewsPeckingOrderTableWithFeedback from "./ReviewsPeckingOrderTableWithFeedback";
import RateBlock from "./RateBlock";
import { SimpleLink } from "../../../../components/lib/Link";
import { THead, TH, TR, TD } from "../../../../components/lib/Table";
import { emptyMap } from "../../../../constants";
import { getJobRegion, getAttemptStatus, getFirstJobData } from "./utils";

import type { ReviewDataMap, AttemptDataMap } from "../../types";
import type { ReviewsPeckingOrderTableBaseProps } from "./base";
import { useVal5KAdminContext } from "../../context/Val5KAdminContext";

export default function AttemptsPeckingOrderTable(
  props: ReviewsPeckingOrderTableBaseProps
) {
  const { reviewId: currentReviewId } = props;
  const { router } = useVal5KAdminContext();

  const handleEnterRow = useCallback(
    (reviewData: ReviewDataMap) => {
      const attemptId = reviewData.getIn(["attempt", "id"]);
      router.push(`/admin/val5000/validations/${attemptId}`);
    },
    [router]
  );

  const renderTableRow = useCallback(
    (reviewData: ReviewDataMap) => {
      const reviewId = reviewData.get("id");
      const reviewIsActive = reviewData.get("is_active", false);
      const reviewIsAvailable = reviewData.get("is_available", true);
      const firstJobData = getFirstJobData(reviewData);
      const jobIndustry = firstJobData.get("industry_name");
      const jobTitle = firstJobData.get("title");
      const jobDescription = firstJobData.get("description");

      const attemptData =
        reviewData.get("attempt") || (emptyMap as unknown as AttemptDataMap);
      const [reviewStatus, reviewStatusString] = getAttemptStatus(attemptData);

      return (
        <TR key={reviewId}>
          <TD>
            {reviewStatus == null ? (
              <Text>not started</Text>
            ) : reviewId === currentReviewId ? (
              <SimpleLink href="#top">current page</SimpleLink>
            ) : (
              <Button
                icon="chevron-left"
                color="brand"
                variant="outlined"
                size="extra-small"
                title={
                  reviewStatus == null
                    ? "Validation has not started yet."
                    : "See validation results."
                }
                onClick={() => handleEnterRow(reviewData)}
                disabled={reviewStatus == null}
              />
            )}
          </TD>
          <TD>
            <Text underline>
              <TextCrop
                mode="modal"
                title={jobTitle}
                text={jobTitle}
                fullText={jobDescription}
                emptyStub=""
              />
            </Text>
          </TD>
          <TD>
            <TextCrop
              mode="tooltip"
              title="Location"
              text={getJobRegion(firstJobData)}
              emptyStub=""
            />
          </TD>
          <TD>
            <TextCrop mode="tooltip" title="Industry" text={jobIndustry} emptyStub="" />
          </TD>
          <TD>
            {reviewIsActive ? (
              <Icon css={{ color: "$success" }} icon="check" />
            ) : (
              <Icon css={{ color: "$danger" }} icon="times" />
            )}
          </TD>
          <TD>
            {reviewIsAvailable ? (
              <Icon css={{ color: "$success" }} icon="check" />
            ) : (
              <Icon css={{ color: "$danger" }} icon="times" />
            )}
          </TD>
          <TD>{reviewStatusString}</TD>
          <TD>
            <RateBlock reviewData={reviewData} rateValue="min" />
          </TD>
          <TD>
            <RateBlock reviewData={reviewData} rateValue="max" />
          </TD>
        </TR>
      );
    },
    [currentReviewId, handleEnterRow]
  );

  const tableHead = (
    <THead>
      <TR>
        <TH>Details</TH>
        <TH>Title</TH>
        <TH>Location</TH>
        <TH>Industry</TH>
        <TH>Is Active</TH>
        <TH>Is Available</TH>
        <TH>Status</TH>
        <TH>
          Lowest Wage
          <br />
          (least amount of experience)
        </TH>
        <TH>
          Highest Wage
          <br />
          (most amount of experience)
        </TH>
      </TR>
    </THead>
  );

  return (
    <ReviewsPeckingOrderTableWithFeedback
      {...props}
      tableHead={tableHead}
      renderTableRow={renderTableRow}
      emptyDataText="no surveys found."
    />
  );
}

AttemptsPeckingOrderTable.displayName = "AttemptsPeckingOrderTable";
