import { fromJS, Map, OrderedMap, Set } from "immutable";

import { emptyOrderedMap } from "../../constants";
import {
  ContractorDataMap,
  ContractorsTableConfigObject,
  ContractorsTableTabsVisibleColumnsMap,
  CONTRACTOR_STATUSES,
  TABLE_TABS_TYPES_TYPE,
} from "./types";
import type {
  ColumnsFiltersQueriesOrderedMap,
  FilterConfigMap,
  FilterConfigObject,
  FiltersConfigOrderedMap,
  FiltersQueryObject,
  FiltersQueryOrderedMap,
  RestfulTableData,
  VisibleColumnsSet,
} from "../../components/tables/types";

export const isSessionStorageAvailable = (): boolean =>
  typeof sessionStorage !== "undefined";
export const writeToSessionStorage = (key: string, dataString: string): boolean => {
  if (key && isSessionStorageAvailable()) {
    sessionStorage.setItem(key, dataString);
    return true;
  }
  return false;
};
export const readFromSessionStorage = (key: string): string | null => {
  if (key && isSessionStorageAvailable()) {
    return sessionStorage.getItem(key);
  }
  return null;
};

export const mergeChangesIntoTableConfig = (
  tableConfig: ContractorsTableConfigObject,
  changesObject: ContractorsTableConfigObject = {} as ContractorsTableConfigObject
): [ContractorsTableConfigObject, boolean] => {
  const mergedTableConfig: ContractorsTableConfigObject = { ...tableConfig };
  let hasChanges = false;

  // merge filters
  if (changesObject.filters !== null && changesObject.filters !== tableConfig.filters) {
    mergedTableConfig["filters"] = changesObject["filters"];
    mergedTableConfig["filtersQuery"] = changesObject["filtersQuery"];
    mergedTableConfig["columnsFiltersQueries"] = changesObject["columnsFiltersQueries"];
    hasChanges = true;
  }

  // merge other attributes
  if (
    changesObject["activePage"] !== undefined &&
    changesObject["activePage"] !== tableConfig["activePage"]
  ) {
    mergedTableConfig["activePage"] = changesObject["activePage"];
    hasChanges = true;
  }
  if (
    changesObject["activeTab"] !== undefined &&
    changesObject["activeTab"] !== tableConfig["activeTab"]
  ) {
    mergedTableConfig["activeTab"] = changesObject["activeTab"];
    hasChanges = true;
  }
  if (
    changesObject["visibleColumns"] !== undefined &&
    changesObject["visibleColumns"] !== tableConfig["visibleColumns"]
  ) {
    mergedTableConfig["visibleColumns"] = changesObject["visibleColumns"];
    hasChanges = true;
  }
  if (
    changesObject["itemsPerPage"] !== undefined &&
    changesObject["itemsPerPage"] !== tableConfig["itemsPerPage"]
  ) {
    mergedTableConfig["itemsPerPage"] = changesObject["itemsPerPage"];
    hasChanges = true;
  }

  return [mergedTableConfig, hasChanges];
};

export const serializeContractorsTableConfig = (
  tableConfig: ContractorsTableConfigObject
): null | string => {
  let result: ContractorsTableConfigObjectRaw | null = null;

  if (tableConfig && Object.keys(tableConfig).length > 0) {
    result = {} as ContractorsTableConfigObjectRaw;

    if (tableConfig.filters != null) {
      result["filters"] = (tableConfig.filters || emptyOrderedMap)
        .entrySeq()
        .toArray()
        .map(([key, item], idx) => [
          key,
          item.toJS(),
        ]) as unknown as ContractorsTableConfigObjectRaw["filters"];
    }
    if (tableConfig["filtersQuery"] != null) {
      result["filtersQuery"] = tableConfig[
        "filtersQuery"
      ].toJS() as unknown as ContractorsTableConfigObjectRaw["filtersQuery"];
    }
    if (tableConfig["columnsFiltersQueries"] != null) {
      result["columnsFiltersQueries"] = tableConfig[
        "columnsFiltersQueries"
      ].toJS() as unknown as ContractorsTableConfigObjectRaw["columnsFiltersQueries"];
    }

    if (tableConfig["visibleColumns"] != null) {
      result["visibleColumns"] = tableConfig[
        "visibleColumns"
      ].toJS() as unknown as ContractorsTableConfigObjectRaw["visibleColumns"];
    }

    if ("activePage" in tableConfig) {
      result["activePage"] = tableConfig["activePage"];
    }
    if ("activeTab" in tableConfig) {
      result["activeTab"] = tableConfig["activeTab"];
    }
    if ("itemsPerPage" in tableConfig) {
      result["itemsPerPage"] = tableConfig["itemsPerPage"];
    }
  }

  return result != null ? JSON.stringify(result) : null;
};

type ContractorsTableConfigObjectRaw = {
  filters: Array<[string, FilterConfigObject]>;
  filtersQuery: FiltersQueryObject;
  columnsFiltersQueries: { [K: string]: FiltersQueryObject };
  activeTab: TABLE_TABS_TYPES_TYPE;
  visibleColumns: { [K in TABLE_TABS_TYPES_TYPE]: string[] };
  activePage: number;
  itemsPerPage: number;
  search: string;
};

export const deserializeContractorsTableConfig = (
  tableConfigString: string | null
): ContractorsTableConfigObject => {
  let result = {} as ContractorsTableConfigObject;

  if (tableConfigString) {
    const tableConfigRaw: ContractorsTableConfigObjectRaw = JSON.parse(tableConfigString);

    if (tableConfigRaw && Object.keys(tableConfigRaw).length > 0) {
      if ("filters" in tableConfigRaw) {
        result["filters"] = OrderedMap(
          (tableConfigRaw["filters"] || []).map<[string, FilterConfigMap]>(
            ([key, item]) => [key, fromJS(item) as FilterConfigMap]
          )
        ) as unknown as FiltersConfigOrderedMap;
      }

      if ("filtersQuery" in tableConfigRaw) {
        result["filtersQuery"] = OrderedMap(
          Object.entries(tableConfigRaw["filtersQuery"] || {}).map<[string, any]>(
            ([key, item]) => [key, fromJS(item)]
          )
        ) as unknown as FiltersQueryOrderedMap;
      }

      if ("columnsFiltersQueries" in tableConfigRaw) {
        result["columnsFiltersQueries"] = OrderedMap(
          Object.entries(tableConfigRaw["columnsFiltersQueries"] || {}).map<
            [string, FiltersQueryOrderedMap]
          >(([key, item]) => [key, fromJS(item) as FiltersQueryOrderedMap])
        ) as unknown as ColumnsFiltersQueriesOrderedMap;
      }

      if ("visibleColumns" in tableConfigRaw) {
        result["visibleColumns"] = Map(
          Object.entries(tableConfigRaw["visibleColumns"])
            .map<[TABLE_TABS_TYPES_TYPE, VisibleColumnsSet | null]>(
              (entry: [string, string[]]) => {
                const [key, item] = entry;
                return [
                  key as TABLE_TABS_TYPES_TYPE,
                  item != null ? (Set(item) as unknown as VisibleColumnsSet) : null,
                ];
              }
            )
            .filter((entry: [TABLE_TABS_TYPES_TYPE, VisibleColumnsSet | null]) => {
              const [key, item] = entry;
              return key != null && item != null;
            }) as Array<[TABLE_TABS_TYPES_TYPE, VisibleColumnsSet]>
        ) as unknown as ContractorsTableTabsVisibleColumnsMap;
      }

      if ("activePage" in tableConfigRaw) {
        result["activePage"] = tableConfigRaw["activePage"];
      }
      if ("activeTab" in tableConfigRaw) {
        result["activeTab"] = tableConfigRaw["activeTab"];
      }
      if ("itemsPerPage" in tableConfigRaw) {
        result["itemsPerPage"] = tableConfigRaw["itemsPerPage"];
      }
    }
  }

  return result;
};

export const hasAnyProcessingContractorsPredicate = (
  data: RestfulTableData<ContractorDataMap>
): boolean => {
  return !!data.find(
    (row: ContractorDataMap) =>
      row.get("status") !== CONTRACTOR_STATUSES.FAILED &&
      row.get("status") !== CONTRACTOR_STATUSES.FINISHED &&
      row.get("status") !== CONTRACTOR_STATUSES.NEED_APPROVAL
  );
};

export function ensurePromise<T>(value: Promise<T> | T): Promise<T> {
  return value instanceof Promise ? value : Promise.resolve(value);
}
