import React, { useCallback, useState, useMemo } from "react";

import Button from "../../../components/lib/Button";
import Icon from "../../../components/lib/Icon";
import Inline from "../../../components/lib/Inline";
import {
  Card,
  CardBody,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
  CardHeaderTitle,
} from "../../../components/lib/Card";
import PaymentReportTableView from "../components/PaymentReportTable";
import { PAYMENT_REPORT_TYPES } from "../types";
import { useModalState } from "../../../utils/hooks";

import type {
  AttemptDataMap,
  GenericPaymentReportParamsObject,
  ByClientPaymentReportParamsObject,
  ByCountryPaymentReportParamsObject,
  ByValidatorPaymentReportParamsObject,
} from "../types";
import type { PaymentReportTableViewRefType } from "../components/PaymentReportTable";

const normalizeGroupName = (name: string) => {
  if (name) {
    name = name
      .split(",")
      .map((i) => i.trim())
      .filter((i) => i && i)
      .join("_");
    name = name
      .split(" ")
      .map((i) => i.trim())
      .filter((i) => i && i)
      .join("_");
  }
  return name;
};

const getGroupName = (reportParams: GenericPaymentReportParamsObject): string => {
  const { reportType } = reportParams;

  if (reportType === PAYMENT_REPORT_TYPES.BY_VALIDATOR) {
    const { firstName, lastName } = reportParams as ByValidatorPaymentReportParamsObject;
    return normalizeGroupName([firstName, lastName].join(" "));
  }

  if (reportType === PAYMENT_REPORT_TYPES.BY_COUNTRY) {
    const { countryName: countriesString } =
      reportParams as ByCountryPaymentReportParamsObject;
    return normalizeGroupName(countriesString);
  }

  if (reportType === PAYMENT_REPORT_TYPES.BY_CLIENT) {
    const { clientName } = reportParams as ByClientPaymentReportParamsObject;
    return normalizeGroupName(clientName);
  }

  return "";
};

const getGroupSummaryTitle = (reportParams: GenericPaymentReportParamsObject): string => {
  const { reportType } = reportParams;

  if (reportType === PAYMENT_REPORT_TYPES.BY_VALIDATOR) {
    const { firstName, lastName } = reportParams as ByValidatorPaymentReportParamsObject;
    return `Total reward for ${firstName} ${lastName}`;
  }

  if (reportType === PAYMENT_REPORT_TYPES.BY_COUNTRY) {
    const { countryName: countriesString } =
      reportParams as ByCountryPaymentReportParamsObject;
    return `Total reward for country ${countriesString}`;
  }

  if (reportType === PAYMENT_REPORT_TYPES.BY_CLIENT) {
    const { clientId, clientName } = reportParams as ByClientPaymentReportParamsObject;
    return `Total reward ${
      clientId != null ? `for client #${clientId} ${clientName}` : ""
    }`;
  }

  return "Total reward";
};

type PaymentReportBlockProps = {
  title: string;
  collapsed: boolean;
  onChangeRowPaidFlag: (
    value: boolean,
    rowData: AttemptDataMap,
    groupKey: string | null
  ) => Promise<unknown>;
} & GenericPaymentReportParamsObject;

const PaymentReportBlock = React.forwardRef(
  (
    props: PaymentReportBlockProps,
    ref: React.ForwardedRef<PaymentReportTableViewRefType>
  ) => {
    const {
      title,
      collapsed: initialCollapsed,
      onChangeRowPaidFlag,
      ...reportParams
    } = props;

    // state

    const [collapsed, setCollapsed] = useState<boolean>(initialCollapsed);
    const toggleCollapsed = useCallback(() => setCollapsed(!collapsed), [collapsed]);

    const {
      modalState: exportModalState,
      showModal: showExportModal,
      closeModal: closeExportModal,
    } = useModalState();

    // handlers

    const handleExportModal = useCallback(() => {
      if (collapsed) toggleCollapsed();
      showExportModal();
    }, [collapsed, toggleCollapsed, showExportModal]);

    // rendering

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const groupName = useMemo(() => getGroupName(reportParams), []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const groupSummaryTitle = useMemo(() => getGroupSummaryTitle(reportParams), []);

    return (
      <Card fill css={{ display: "block", borderRadius: "0" }}>
        <CardActions css={{ background: "$accentLightest" }}>
          <CardActionsRight>
            <CardHeaderTitle as="h4">
              <Inline>
                <Icon
                  icon={collapsed ? "chevron-down" : "chevron-up"}
                  onClick={toggleCollapsed}
                  css={{
                    color: "$brand",
                    alignItems: "end",
                    cursor: "pointer",
                    "&:hover": { transform: "scale(1.1)" },
                  }}
                />
                {title}
              </Inline>
            </CardHeaderTitle>
          </CardActionsRight>
          <CardActionsLeft>
            <Button icon={["far", "file-excel"]} size="small" onClick={handleExportModal}>
              Export
            </Button>
          </CardActionsLeft>
        </CardActions>
        <CardBody css={collapsed ? { display: "none" } : {}}>
          {!collapsed && (
            <PaymentReportTableView
              ref={ref}
              groupName={groupName}
              groupSummaryTitle={groupSummaryTitle}
              showModalExport={exportModalState}
              onCloseModalExport={closeExportModal}
              onChangeRowPaidFlag={onChangeRowPaidFlag}
              {...reportParams}
            />
          )}
        </CardBody>
      </Card>
    );
  }
);
PaymentReportBlock.displayName = "PaymentReportBlock";

export default PaymentReportBlock;
