import React from "react";
import PlainHighChart from "../../../views/ratecards/components/PlainHighChart";

import {
  RATE_TYPE_OPTIONS,
  SALARY_RATE_TYPE_OPTIONS,
} from "../../../constants/negotiationWorksheet";
import sumFieldsReducer from "../utils/sumFieldsReducer";
import fieldValueGetter from "../utils/fieldValueGetter";

import { Card } from "../../../components/material";
import * as filter from "../utils/filter";

export default class GroupChartSummary extends React.Component {
  state = {
    options: {
      title: {
        text: "Summary",
      },
      chart: {
        type: "pie",
        backgroundColor: null,
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
      },
      legend: {
        enabled: true,
        floating: true,
        layout: "vertical",
        align: "right",
        verticalAlign: "middle",
        backgroundColor: "#fdfdfd",
        borderColor: "#eeeeee",
        useHTML: true,
        labelFormat: `<span style="font-size:10px;">{name}: {y:.2f}% / ${this.props.store.worksheetSchema.currencySymbol}{price:.2f}</span>`,
      },
      tooltip: {
        borderColor: "#eeeeee",
        backgroundColor: "#fdfdfd",
        shadow: false,
        headerFormat: "",
        pointFormat: `<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> / ${this.props.store.worksheetSchema.currencySymbol}{point.price:.2f}<br/>`,
      },
      series: [
        {
          type: "pie",
        },
      ],
      plotOptions: {
        pie: {
          cursor: "pointer",
          center: ["15%", "50%"],
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      },
      credits: {
        enabled: false,
      },
    },
  };

  getPieChartSalaryValues(props, valueType = "markup") {
    const { store, groups, payRateHourly } = props;
    const conversionOptions = store.worksheetSchema.conversionOptions;
    const { unsavedValues } = store.currentState;
    const { values } = store;
    const fieldMap = store.worksheetSchema.fields;
    const getDollarValue = (value) =>
      SALARY_RATE_TYPE_OPTIONS.convertFromHourly(
        (value / 100) * payRateHourly,
        conversionOptions
      );
    const sumFields = sumFieldsReducer(fieldValueGetter(unsavedValues, values));

    const fieldFilter = filter.all(filter.rateTypeContains("salary"));

    if (store.checkLegacyStd() && store.apiStateId) {
      return groups.map((group) => {
        const fields = fieldMap
          .filter((field) => group.fields.includes(field.id))
          // .filter(rec => rec.states.includes(store.apiStateId))
          .filter(fieldFilter);

        if (fields.length === 0) return null;

        const markup = fields.reduce(sumFields, 0);

        return {
          y: markup,
          price: getDollarValue(markup),
          name: group.label,
        };
      });
    } else
      return groups.map((group) => {
        const fields = fieldMap
          .filter((field) => group.fields.includes(field.id))
          .filter(fieldFilter);

        if (fields.length === 0) return null;

        const markup = fields.reduce(sumFields, 0);

        return {
          y: markup,
          price: getDollarValue(markup),
          name: group.label,
        };
      });
  }

  getPieChartValues(props, valueType = "markup") {
    const { store, groups, payRateHourly } = props;
    const workerTypeId = store.selectedWorkerType.id;
    const conversionOptions = store.worksheetSchema.conversionOptions;
    const { rateType, unsavedValues } = store.currentState;
    const { values } = store;
    const fieldMap = store.worksheetSchema.fields;
    const getDollarValue = (value) =>
      RATE_TYPE_OPTIONS[rateType].convertFromHourly(
        (value / 100) * payRateHourly,
        conversionOptions
      );
    const sumFields = sumFieldsReducer(fieldValueGetter(unsavedValues, values));

    if (store.checkLegacyStd() && store.apiStateId) {
      return groups.map((group) => {
        const fields = fieldMap
          .filter((field) => group.fields.includes(field.id))
          // .filter(rec => rec.states.includes(store.apiStateId))
          .filter(filter.all(filter.notNull, filter.workerTypesContains(workerTypeId)));

        if (fields.length === 0) return null;

        const markup = fields.reduce(sumFields, 0);
        // fields.reduce(function (acc, obj) { return acc + obj.value; }, 0);

        return {
          y: markup,
          price: getDollarValue(markup),
          name: group.label,
        };
      });
    } else
      return groups.map((group) => {
        const fields = fieldMap
          .filter((field) => group.fields.includes(field.id))
          .filter(filter.all(filter.notNull, filter.workerTypesContains(workerTypeId)));

        if (fields.length === 0) return null;

        const markup = fields.reduce(sumFields, 0);
        // fields.reduce(function (acc, obj) { return acc + obj.value; }, 0);

        return {
          y: markup,
          price: getDollarValue(markup),
          name: group.label,
        };
      });
  }

  render() {
    var values =
      this.props.store.currentState.payType === 3
        ? this.getPieChartSalaryValues(this.props)
        : this.getPieChartValues(this.props);

    values = values.filter(function (n) {
      return n != null;
    });

    return (
      <Card>
        <div className="pt-worksheet__group-chart__chart">
          <PlainHighChart
            style={{ width: "100%", height: "300px" }}
            options={{
              ...this.state.options,
              ...{ series: [{ type: "pie", data: values }] },
            }}
          />
        </div>
      </Card>
    );
  }
}
