import React, { useEffect, useRef, useCallback } from "react";
import { useRecoilState } from "recoil";

// @ts-expect-error
import Overlay from "../../../components/OverlayMessager";
import Icon from "../../../components/lib/Icon";
import Box from "../../../components/lib/Box";
import Stack from "../../../components/lib/Stack";
import Inline from "../../../components/lib/Inline";
import Grid from "../../../components/lib/Grid";
import Center from "../../../components/lib/Center";
import { AutoSearchBox, AutoSearchBoxProps } from "../../../components/lib/SearchBox";

import { emptyList } from "../../../constants";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";

import DiscoverySearchResult from "../components/DiscoverySearchResult";
import DiscoverySearchResultCounter from "../components/DiscoverySearchResultCounter";

import { NavigationButton } from "../../../components/lib/Button";
import { TickerContentLoader } from "../../../components/lib/TickerLoader";
import { InstructionsCheckMark } from "../lib/CheckMark";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderTitle,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
} from "../../../components/lib/Card";

import { discoverySearchGlobalState } from "../globalState";
import {
  discoverySearchResultsListToImmutableList,
  discoverySearchTotalsToImmutableMap,
} from "../dataConverters";

import type {
  DiscoverySearchResultMap,
  DiscoverySearchResultObject,
  DiscoverySearchResultsList,
  DiscoverySearchResultTotalsMap,
  DiscoverySearchResultTotalsObject,
} from "../types";
import type { CommonProgramChildPageProps } from "../ProgramDataProvider";
import type { FetchAPIResponse } from "../../../types/fetch";
import PromiseButton from "../../../components/lib/PromiseButton";

const CSSDiscoverySearchResultCounter = {
  [`& ${Inline}:nth-child(1)`]: { fontSize: "$3xl" },
  [`& ${Inline}:nth-child(2)`]: { fontSize: "$xl" },
  [`& ${Inline}:nth-child(3)`]: { fontSize: "$base" },
};

type SearchResultsTotalsSectionProps = {
  rowsCount: number;
  labelsCount: number;
  locationsCount: number;
  regionsCount: number;
  suppliersCount: number;
  managersCount: number;
};

const SearchResultsTotalsSection = (props: SearchResultsTotalsSectionProps) => {
  const {
    rowsCount,
    labelsCount,
    locationsCount,
    regionsCount,
    suppliersCount,
    managersCount,
  } = props;

  return (
    <Grid
      css={{
        gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
        "@md": { gridTemplateColumns: "repeat(6, minmax(0, 1fr))" },
        gap: "$2",
        color: "$primaryDark",
        marginBottom: "$4",
      }}
    >
      <DiscoverySearchResultCounter
        css={CSSDiscoverySearchResultCounter}
        counter={rowsCount}
        icon={<Icon icon="list-ul" />}
        label="Rows"
      />
      <DiscoverySearchResultCounter
        css={CSSDiscoverySearchResultCounter}
        counter={labelsCount}
        icon={<Icon icon="list-alt" />}
        label="Labels"
      />
      <DiscoverySearchResultCounter
        css={CSSDiscoverySearchResultCounter}
        counter={locationsCount}
        icon={<Icon icon="map-marker-alt" />}
        label="Locations"
      />
      <DiscoverySearchResultCounter
        css={CSSDiscoverySearchResultCounter}
        counter={regionsCount}
        icon={<Icon icon="globe-americas" />}
        label="Regions"
      />
      <DiscoverySearchResultCounter
        css={CSSDiscoverySearchResultCounter}
        counter={suppliersCount}
        icon={<Icon icon="briefcase" />}
        label="Suppliers"
      />
      <DiscoverySearchResultCounter
        css={CSSDiscoverySearchResultCounter}
        counter={managersCount}
        icon={<Icon icon="users" />}
        label="Managers"
      />
    </Grid>
  );
};

SearchResultsTotalsSection.displayName = "SearchResultsTotalsSection";

type DiscoveryDashboardProps = CommonProgramChildPageProps & {
  searchLimit: number;
};

const DiscoveryDashboard = (props: DiscoveryDashboardProps) => {
  const {
    searchLimit,
    router,
    fetchM8API,
    programData,
    showModalError,
    showModalWarning,
  } = props;

  const [searchDataState, setSearchDataState] = useRecoilState(
    discoverySearchGlobalState
  );
  const {
    loaded: isResultsDataLoadedState,
    searchTotals: searchTotalsState,
    searchResults: searchResultsState,
    searchTerm: searchTermState,
    searchOffset: searchOffsetState,
    hasMoreResults: hasMoreResultsState,
  } = searchDataState;

  const programId = programData.get("id");
  const programName = programData.get("title");

  // overlay

  const searchOverlayRef = useRef<Overlay>(null);
  const showOverlay = () =>
    searchOverlayRef.current &&
    searchOverlayRef.current.hint("", null, false, true, false);
  const hideOverlay = () => searchOverlayRef.current && searchOverlayRef.current.hide();

  // utils

  const updateState = useCallback(
    (
      newTotals: DiscoverySearchResultTotalsMap,
      newResults: DiscoverySearchResultsList
    ) => {
      let searchResults = (
        searchOffsetState === 0 ? emptyList : searchResultsState
      ).concat(newResults) as DiscoverySearchResultsList;
      let searchOffset = searchOffsetState;
      let hasMoreResults = true;

      if (searchOffsetState >= searchLimit && newResults.size === 0) {
        hasMoreResults = false;
        showModalWarning("No more results found in the system.");
      } else if (newResults.size < searchLimit) {
        hasMoreResults = false;
      } else {
        searchOffset = searchOffsetState + searchLimit;
      }

      setSearchDataState((prevDataState) => ({
        ...prevDataState,
        loaded: true,
        searchTotals: newTotals,
        searchResults,
        searchOffset,
        hasMoreResults,
      }));

      return searchResults;
    },
    [
      searchResultsState,
      searchOffsetState,
      searchLimit,
      setSearchDataState,
      showModalWarning,
    ]
  );

  // data fetch func

  type DiscoverySearchResponse = {
    totals: DiscoverySearchResultTotalsObject;
    results: DiscoverySearchResultObject[];
  };

  const fetchSearchData = useCallback(
    async (searchTerm?: string) => {
      const queryArgs: { limit?: number; search?: string; offset?: number } = {
        limit: searchLimit,
      };
      if (searchTermState != null) queryArgs["search"] = searchTerm ?? searchTermState;
      if (searchOffsetState != null) queryArgs["offset"] = searchOffsetState;

      showOverlay();

      try {
        const response: FetchAPIResponse<DiscoverySearchResponse> = await fetchM8API(
          `programs/${programId}/discovery_search/`,
          {
            params: queryArgs,
          }
        );

        return updateState(
          discoverySearchTotalsToImmutableMap(response.data.totals),
          discoverySearchResultsListToImmutableList(response.data.results || [])
        );
      } catch (err: any) {
        logAsyncOperationError("makeDiscoverySearch", err);
        showModalError("Error occurred while making discovery search.");
      } finally {
        hideOverlay();
      }
    },
    [
      searchTermState,
      searchOffsetState,
      searchLimit,
      updateState,
      programId,
      fetchM8API,
      showModalError,
    ]
  );

  //handlers

  const handleGoBackToProgramDetails = useCallback(() => {
    router.push(`/private-index/programs/${programId}`);
  }, [router, programId]);

  const handleGoToDiscoverySearchResult = useCallback(
    (result: DiscoverySearchResultMap) => {
      if (result == null || !result.size) return;
      const collectionId = result.get("job_collection_id");
      router.push(
        `/private-index/programs/${programId}/discovery-dashboard/search/${collectionId}`
      );
    },
    [router, programId]
  );

  const handleFetchSearchData = useCallback(
    (searchTerm: AutoSearchBoxProps["value"]) => {
      fetchSearchData(searchTerm as string);
    },
    [fetchSearchData]
  );

  const handleFetchMoreSearchData = useCallback(
    (...args: any) => {
      fetchSearchData();
    },
    [fetchSearchData]
  );

  const handleOnChange = useCallback(
    (searchTerm: AutoSearchBoxProps["value"]) => {
      setSearchDataState((prevDataState) => ({
        ...prevDataState,
        searchOffset: 0,
        searchTerm: searchTerm as string,
      }));
    },
    [setSearchDataState]
  );

  // initial data fetch

  const dataLoadedRef = useRef(false);

  useEffect(() => {
    if (!dataLoadedRef.current) {
      fetchSearchData();
      dataLoadedRef.current = true;
    }
  }, [fetchSearchData]);

  useEffect(() => {
    return () => {
      setSearchDataState((prevState) => ({
        ...prevState,
        searchOffset: 0,
        loaded: false,
      }));
    };
  }, [setSearchDataState]);

  return (
    <Stack>
      <Card fill>
        <CardActions>
          <CardActionsLeft>
            <CardHeaderTitle>Discovery Dashboard</CardHeaderTitle>
          </CardActionsLeft>
          <CardActionsRight>
            <NavigationButton icon="arrow-left" onClick={handleGoBackToProgramDetails}>
              Back To Index Page
            </NavigationButton>
          </CardActionsRight>
        </CardActions>

        <CardBody>
          <h3>
            This page includes all data you have imported, but cleaned and grouped by
            titles.
            <br />
            Here you can:
          </h3>
          <h3>
            <InstructionsCheckMark /> Search job titles using your label or job title from
            PeopleTicker library.
          </h3>
          <h3>
            <InstructionsCheckMark /> Use subtotals and visualisations to explore your
            data.
          </h3>
          <h3>
            <InstructionsCheckMark /> See detailed dataset for every search.
          </h3>
          <h3>
            <InstructionsCheckMark /> Export detailed dataset to Excel.
          </h3>
        </CardBody>
      </Card>

      <Card fill>
        <CardHeader>
          <CardHeaderTitle as="h3">Roles Search</CardHeaderTitle>
        </CardHeader>

        <CardBody>
          {!isResultsDataLoadedState && (
            <Box css={{ minHeight: "200px" }}>
              <TickerContentLoader />
            </Box>
          )}

          {isResultsDataLoadedState && (
            <Stack fill>
              <SearchResultsTotalsSection
                rowsCount={searchTotalsState.get("rows_count") || 0}
                labelsCount={searchTotalsState.get("labels_count") || 0}
                locationsCount={searchTotalsState.get("locations_count") || 0}
                regionsCount={searchTotalsState.get("regions_count") || 0}
                suppliersCount={searchTotalsState.get("suppliers_count") || 0}
                managersCount={searchTotalsState.get("managers_count") || 0}
              />
              <AutoSearchBox
                value={searchTermState || ""}
                css={{
                  "& input": {
                    textAlign: "center !important",
                  },
                }}
                placeholder="Search by role..."
                onChange={handleOnChange}
                onSubmit={handleFetchSearchData}
                fill
              />
              <Grid
                css={{
                  width: "$full",
                  gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
                  "@md": { gridTemplateColumns: "repeat(2, minmax(0, 1fr))" },
                  "@lg": { gridTemplateColumns: "repeat(3, minmax(0, 1fr))" },
                  "@xl": { gridTemplateColumns: "repeat(4, minmax(0, 1fr))" },
                  gap: "$5 $8",
                }}
              >
                {searchResultsState.size > 0 &&
                  searchResultsState
                    .toArray()
                    .map((result, idx) => (
                      <DiscoverySearchResult
                        key={idx}
                        programName={programName}
                        result={result}
                        onExplore={() => handleGoToDiscoverySearchResult(result)}
                      />
                    ))}
                <Overlay ref={searchOverlayRef} />
              </Grid>

              {searchResultsState.size === 0 && !!searchTermState && (
                <Center
                  css={{
                    paddingBottom: "$44",
                    fontWeight: "$light",
                    fontSize: "$base",
                    color: "$primary",
                  }}
                >
                  <i>Nothing found. Use whole words to search.</i>
                </Center>
              )}

              {searchResultsState.size > 0 && hasMoreResultsState && (
                <Center css={{ width: "$full", marginTop: "$1" }}>
                  <PromiseButton
                    size="normal"
                    color="brand"
                    variant="outlined"
                    loadingText="Show More Results"
                    onClick={handleFetchMoreSearchData}
                    css={{
                      width: "30%",
                      minWidth: "155px",
                    }}
                  >
                    Show More Results
                  </PromiseButton>
                </Center>
              )}
            </Stack>
          )}
        </CardBody>
      </Card>

      <Card fill>
        <CardActions>
          <CardActionsLeft />
          <CardActionsRight>
            <NavigationButton icon="arrow-left" onClick={handleGoBackToProgramDetails}>
              Back To Index Page
            </NavigationButton>
          </CardActionsRight>
        </CardActions>
      </Card>
    </Stack>
  );
};

DiscoveryDashboard.displayName = "DiscoveryDashboard";
DiscoveryDashboard.defaultProps = {
  searchLimit: 12,
};

export default DiscoveryDashboard;
