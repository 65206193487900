import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Link } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../../ratecards/components/Modal";
import classNames from "classnames";
import ContentList from "./components/ContentList";

const TagDetail = observer((props) => {
  const store = props.store;

  let owner,
    tagId,
    tagName,
    //shared,
    createdDate = null;

  if (store.network.loading) {
    tagId = "...";
    tagName = "...";
    owner = "...";
    createdDate = "...";
    //shared = false;
  } else if (store.tagDetail) {
    tagId = store.tagDetail.tagId;
    tagName = `${store.tagDetail.name}`;
    owner = store.tagDetail.owner.fullname;
    createdDate = store.tagDetail.createdDisplay;
    //shared = store.tagDetail.shared;
    // if (String(store.tagDetail.owner.clientId) !== String(store.currentUser.userId)) {
    //   sharedByYou = false;
    // }
  }

  // const sharedTooltip = (
  //   <Tooltip id="shared-icon-tooltip">Shared...</Tooltip>
  // );

  let actionBar = null;
  if (!store.network.loading && !store.network.error)
    actionBar = (
      <nav className="action-bar">
        {/*<button className="btn" onClick={store.showRenameTagModal}>
          <FontAwesomeIcon icon="edit" fixedWidth className="icon"/>
          Rename
        </button>}
        { <button className="btn btn-danger" onClick={store.confirmDeleteModal.showModal}>
          <FontAwesomeIcon icon="trash-alt" fixedWidth className="icon"/>
          Delete
        </button>}
        {store.hasOwnership &&  <button className="btn" onClick={store.share}>
            <FontAwesomeIcon icon="share" fixedWidth className="icon" /> Share
        </button>*/}
      </nav>
    );

  return (
    <div className="top">
      <div className="above">
        <div className="ratecard-label-header ellipsis max-width-80">
          <h2 className="ellipsis">{tagName}</h2>
          {/*shared && sharedByYou && <OverlayTrigger placement="top" overlay={sharedTooltip}>
            <span className="tag text-small align-top">
              <FontAwesomeIcon icon="share" fixedWidth className="icon"/>
              By You
            </span>
          </OverlayTrigger>
}
          {shared && !sharedByYou && <OverlayTrigger placement="top" overlay={sharedTooltip}>
            <span className="tag text-small align-top">
              <FontAwesomeIcon icon="share" fixedWidth flip="horizontal" className="icon"/>
              To You
            </span>
          </OverlayTrigger>
*/}
        </div>
        <nav className="back-bar">
          <Link to={`/admin/tag/`} className="btn btn-green">
            <FontAwesomeIcon icon="arrow-left" fixedWidth className="icon" />
            Go Back
          </Link>
          <button className="btn" onClick={store.showHelpModal.showModal}>
            <FontAwesomeIcon icon="question-circle" fixedWidth className="icon" />
            Help
          </button>
        </nav>
      </div>
      <div className="below">
        <div className="ratecard-meta ellipsis">
          <div className="ratecard-owner ellipsis">
            <FontAwesomeIcon icon="key" fixedWidth className="icon" /> {tagId}
          </div>
          <div className="ratecard-owner ellipsis">
            <FontAwesomeIcon icon="user" fixedWidth className="icon" /> {owner}
          </div>
          <div className="ratecard-created-at ellipsis">
            <FontAwesomeIcon icon="calendar-alt" fixedWidth className="icon" />{" "}
            {createdDate}
          </div>
        </div>
        {actionBar}
      </div>
      <Modal show={store.showHelpModal.show} onHide={store.showHelpModal.hideModal}>
        <div className="container-section header">
          <h4>Help</h4>
        </div>
        <div className="container-section footer">
          <p>Here you can see all the tag details</p>
        </div>
      </Modal>
    </div>
  );
});

/**
 * TagDetail component for rate card detail page
 */
class Detail extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };
  /**
   * componentDidMount
   * @return {void}
   */
  componentDidMount() {
    const store = this.props.store.tagManagementDetailPTStore;
    store.handleStopEdit();
    store.tagId = this.props.router.params.tagId;
    store.router = this.context.router;
    store.isEditing = null;
    store.fetchTagDetail();
    window.scrollTo(0, 0);
  }

  /**
   * Render rate card detail page
   * @return {XML}
   */
  render() {
    const store = this.props.store.tagManagementDetailPTStore;
    const contentListPTStore = this.props.store.contentListPTStore;

    //let sharedByYou = false;
    // if (store.tagDetail) {
    //   if (String(store.tagDetail.owner.clientId) !== String(store.currentUser.userId)) {
    //     sharedByYou = true;
    //   }
    // }

    return (
      <div className="view">
        <div className="rc-container show-overflow">
          <TagDetail store={store} router={this.props.router} />
        </div>

        <div className="rc-container bring-forward headerfix">
          {!store.network.error && (
            <div>
              <ContentList
                store={contentListPTStore}
                tagStore={store}
                router={this.props.router}
              />
            </div>
          )}
          {store.network.error && (
            <div className="pt-error-message fade-in">
              <h2>Error</h2>
              <pre>
                <code>{JSON.stringify(store.network.error, null, 2)}</code>
              </pre>
            </div>
          )}
        </div>

        <div
          className={classNames("search-edit-actions", {
            hidden: store.isEditing === null,
            "bottom-appear": store.isEditing === true,
            "bottom-disappear": store.isEditing === false,
          })}
        />
        {/*<Modal show={contentListStore.confirmLocationDeleteModal.show} onHide={contentListStore.confirmLocationDeleteModal.hideModal}>
          <div className="container-section header">
            <h4>Confirmation Needed</h4>
          </div>
          <div className="container-section">
            <p>
              Are you sure you want to remove the selected components?
            </p>
            <p className="text-danger">
              <FontAwesomeIcon icon="exclamation-triangle"/> {" "}
              <em>This action cannot be undone!</em>
            </p>
          </div>
          <div className="container-section footer">
            <div className="pull-right">
              <button className="btn btn-lg btn-danger" onClick={e => {
                contentListStore.confirmLocationDeleteModal.hideModal();
                contentListStore.deleteTagContent();
              }}>
                Yes, Remove Components
              </button>
              <button className="btn btn-lg" onClick={contentListStore.confirmLocationDeleteModal.hideModal}>
                No, Cancel
              </button>
            </div>
          </div>
        </Modal>*/}
      </div>
    );
  }
}

export default observer(Detail);
