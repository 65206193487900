// @flow
import * as React from "react";
import { InputGroup, FormControl } from "react-bootstrap";

type Props = {
  onBlur?: () => void,
  onChange: (any) => void,
  value?: number | null,
  min: number | null,
  max: number | null,
  placeholder: string | null,
  addonBefore: string | null,
  addonAfter: string | null,
  disabled: boolean,
  step: string,
};

type State = {
  value: ?number,
};

export default class NumberInput extends React.Component<Props, State> {
  static defaultProps = {
    min: null,
    max: null,
    placeholder: null,
    disabled: false,
    addonBefore: null,
    addonAfter: null,
    step: "0.01",
  };

  state = {
    value: this.props.value,
  };

  // $FlowFixMe: Use React16's Input Event type
  onChange = (event) => {
    let value = event.target.value;
    let numValue = +value;

    // This check is for IE8 support. Don't let them type in things that don't parse as numbers,
    // otherwise everything goes ham.
    if (isNaN(numValue)) return;

    numValue = this.applyConstraints(numValue);
    if (numValue !== +value) {
      value = numValue;
    }

    if (+value === this.state.value) return;

    this.setState(
      {
        value: value,
      },
      () => {
        this.props.onChange && this.props.onChange(numValue);
      }
    );
  };

  applyConstraints(value: number) {
    const { min, max } = this.props;
    if (min !== null && value < min) {
      return min;
    }
    if (max !== null && value > max) {
      return max;
    }

    return value;
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (+nextProps.value !== +this.state.value) {
      this.setState({ value: nextProps.value });
    }
  }

  render() {
    return (
      <InputGroup>
        {this.props.addonBefore && (
          <InputGroup.Addon>{this.props.addonBefore}</InputGroup.Addon>
        )}
        <FormControl
          id={this.props.id}
          type="number"
          value={this.state.value}
          onChange={this.onChange}
          step={this.props.step}
          disabled={this.props.disabled}
          placeholder={this.props.placeholder}
        />
        <InputGroup.Addon>
          {this.props.addonBefore
            ? ""
            : this.props.addonAfter
            ? this.props.addonAfter
            : "%"}
        </InputGroup.Addon>
      </InputGroup>
    );
  }
}
