import React from "react";

import Grid from "../../../../components/lib/Grid";
import Stack from "../../../../components/lib/Stack";
import Text from "../../../../components/lib/Text";
import Box from "../../../../components/lib/Box";
import Icon from "../../../../components/lib/Icon";
import TableBase from "../../lib/Table";
import TextCrop from "../../../validator5K_admin/components/TextCrop";
import { Card, CardBody } from "../../../../components/lib/Card";
import { getFilteredValueTypesDesc } from "../../constants";
import {
  RATE_TYPES_TYPE,
  REQUIRED_RATES_LIST,
  VALUE_SUBTYPES_OPTIONS,
} from "../../types";
import { styled } from "../../../../stitches.config";
import {
  classicSkillsLevelsInfoText,
  threeLevelBandingSkillsLevelsInfoText,
} from "./utils";

import type { RatesProps, RatesRowProps, RatesTitleProps } from "./types";

const Table = styled("table", {
  width: "$full",

  "thead, tbody": {
    borderBottom: "1px solid $primaryLighter",
  },

  "thead th, tbody td:first-child": {
    fontWeight: "600",
  },

  "thead th, tbody td": {
    padding: "$1_5",
    fontSize: "inherit",
    textAlign: "right",
    whiteSpace: "nowrap",
  },
  "thead th:last-child, tbody td:last-child": {
    paddingRight: "$5",
  },

  tfoot: {
    fontSize: "var(--fontSizes-sm)",
  },

  "tfoot td": {
    padding: "0",
    overflow: "hidden",
  },
});

export const TableInfo = styled(TableBase, {
  maxWidth: "300px",
  "& > *, & th, & td": {
    border: "1px solid $primaryLight",
  },

  "& tr": {
    backgroundColor: "transparent",
  },
});

type RatesTitleBaseProps = {
  isThreeLevelBanding: boolean;
  expLevelLabel: React.ReactChild;
  expLevelInfo?: React.ReactChild;
  rateTypeLabel: React.ReactChild;
  currencyLabel: React.ReactChild;
  statusIcon?: React.ReactChild;
};

export function RatesTitleBase(props: RatesTitleBaseProps) {
  const {
    isThreeLevelBanding,
    expLevelLabel,
    expLevelInfo,
    rateTypeLabel,
    currencyLabel,
    statusIcon,
  } = props;

  return (
    <Grid
      css={{
        gridTemplateColumns: "auto auto",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Stack css={{ alignItems: "flex-start", gap: "$0_5" }}>
        <Text css={{ fontSize: "$2xl" }}>
          {`${expLevelLabel} `}
          <Text css={{ fontSize: "$base" }} thin>
            <TextCrop
              mode="modal"
              hint="Click to see details"
              title="Depth Of Capability"
              label={<Icon icon={["far", "question-circle"]} />}
              fullText={
                isThreeLevelBanding
                  ? threeLevelBandingSkillsLevelsInfoText
                  : classicSkillsLevelsInfoText
              }
            />
          </Text>
        </Text>

        <Text color="primary">
          {rateTypeLabel}
          {currencyLabel && `, ${currencyLabel}`}
        </Text>

        {expLevelInfo && <Text color="primary">{expLevelInfo}</Text>}
      </Stack>

      {statusIcon}
    </Grid>
  );
}

export type RatesBaseProps = RatesProps & {
  rateType: RATE_TYPES_TYPE;
  requiredRates: REQUIRED_RATES_LIST;
  ratesTitleImpl: React.FunctionComponent<RatesTitleProps>;
  ratesRowImpl: React.FunctionComponent<RatesRowProps>;
  ratesFeedbackImpl?: React.FunctionComponent<RatesProps>;
};

export function RatesBase(props: RatesBaseProps) {
  const {
    rateResult,
    rateFeedback,
    rateType,
    requiredRates,
    ratesTitleImpl: RatesTitleImpl,
    ratesRowImpl: RatesRowImpl,
    ratesFeedbackImpl: RatesFeedbackImpl,
  } = props;

  const expLevel = rateResult.get("level");

  return (
    <Card css={{ border: "1px solid $primaryLighter" }}>
      <CardBody css={{ padding: 0 }}>
        <Box css={{ padding: "$6", paddingBottom: 0 }}>
          <RatesTitleImpl expLevel={expLevel} rateFeedback={rateFeedback} />
        </Box>
        <Table>
          {/* rates headings: Pay Rate, Markup, Bill Rate */}
          <thead>
            <tr>
              <th />
              {getFilteredValueTypesDesc(rateType, requiredRates.toJS()).map(
                ({ title: valueTypeTitle }, idx) => {
                  return <th key={idx}>{valueTypeTitle}</th>;
                }
              )}
            </tr>
          </thead>
          {/* rates values - every row represents min/mid/avg/max values */}
          <tbody>
            {VALUE_SUBTYPES_OPTIONS.map(
              ({ type: valueSubtype, title: valueSubtypeTitle }, idx) => (
                <RatesRowImpl
                  key={idx}
                  rateResult={rateResult}
                  rateFeedback={rateFeedback}
                  valueSubtype={valueSubtype}
                  valueSubtypeTitle={valueSubtypeTitle}
                />
              )
            )}
          </tbody>
          {/* validation buttons */}
          {RatesFeedbackImpl && (
            <tfoot>
              <tr>
                <td colSpan={999} tabIndex={0}>
                  <RatesFeedbackImpl
                    rateResult={rateResult}
                    rateFeedback={rateFeedback}
                  />
                </td>
              </tr>
            </tfoot>
          )}
        </Table>
      </CardBody>
    </Card>
  );
}

RatesBase.displayName = "RatesBase";
