// @flow strict
import * as React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import numeral from "numeral";
import { Link } from "react-router";
import graphql from "babel-plugin-relay/macro";
import { createFragmentContainer } from "react-relay";
// $FlowFixMe
import type { AdminSearchesSearch_search } from "./__generated__/AdminSearchesSearch_search.graphql";
import { TagItemWithoutDelete } from "../views/ratecards/components/TagItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RATE_TYPE_OPTIONS_2 } from "../constants/rateTypes";

type Props = {
  currentUserId: number,
  search: AdminSearchesSearch_search,
};

class AdminSearchesSearch extends React.Component<Props, void> {
  static contextTypes = {
    router: PropTypes.object,
  };

  onItemClick = () => {
    this.context.router.push({
      pathname: `/admin/searches/${this.props.search?.searchId || "NULL_ERROR"}`,
      query: this.context.router.query,
    });
  };

  render() {
    const { search } = this.props;
    const tagSection = null;
    const showMarkupAndBillRate = search.rateType === 1;

    const { city, state, country, region, tags } = search;

    let ownerName = "You";

    if (String(search.createdBy?.userId) !== String(this.props.currentUserId)) {
      ownerName = `${search.createdBy?.firstName || "[Error: First]"} ${
        search.createdBy?.lastName || "[Error: Last]"
      }`;
    }

    const createdDisplay = moment(search.createdDate).format("MMMM D, YYYY");

    // $FlowFixMe: Specifically get "Level 3"
    const payRateAvgTotal = search.marketRate?.payRate || 0;
    const markupAvgTotal = search.marketRate?.markupPct || 0;
    const billRateAvgTotal = search.marketRate?.billRate || 0;

    return (
      <div className="ratecard-list-item-container saved-searches-list">
        <div
          className="ratecard-list-item align-items-center"
          // onClick={this.onItemClick}
        >
          <div className="ratecard-list-item-left ellipsis">
            <div className="ratecard-label-container ellipsis">
              <div className="ratecard-label ellipsis">
                <div className="ellipsis">
                  <div className="search-label ellipsis">{search.job.jobLabel}</div>
                  <div className="search-title ellipsis">
                    <span className="item-subtext-accent">{search.job.jobTitle} </span>
                    <span className="item-subtext-muted">in </span>
                    <span className="item-subtext-accent city">
                      {region
                        ? region.name
                        : [city, state, country].filter((l) => Boolean(l)).join(", ")}
                    </span>
                  </div>
                </div>
                {/*<span className="ellipsis">*/}
                {/*  {search.job.jobTitle} - {search.job.jobLabel}*/}
                {/*</span>*/}
                {/*<div className="city">*/}
                {/*  {city ? city + ",  " : ""}*/}
                {/*  {state}*/}
                {/*  {state || city ? <span className="pipe">{", "}</span> : ""}*/}
                {/*  {country}*/}
                {/*</div>*/}
              </div>
              <div className="ratecard-meta ellipsis">
                <span className="item-subtext-muted">Created by </span>
                <span className="item-subtext-accent">{ownerName} </span>
                {createdDisplay && <span className="item-subtext-muted">on </span>}
                {createdDisplay && (
                  <span className="item-subtext-accent">{createdDisplay}</span>
                )}
                <div className="tagbox">
                  {tags.map((tag) => (
                    <TagItemWithoutDelete key={tag.tagId} tag={tag} />
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="ratecard-list-item-right">
            {search.isGlobalSupplierSearch && (
              <div className="list-rate">
                <div className="ratecard-info text-center">
                  <header>&nbsp; </header>
                  <div>
                    <FontAwesomeIcon icon="globe" fixedWidth className="icon" /> GSS
                  </div>
                </div>
              </div>
            )}
            <div className="list-rate">
              <div className="ratecard-info text-center">
                <header data-testid="pay-rate-header-searches">Pay Rate</header>
                <div>
                  {search.currency?.symbol + numeral(payRateAvgTotal).format("0,0.00")}
                </div>
              </div>
            </div>
            {showMarkupAndBillRate && (
              <div className="list-rate">
                <div className="ratecard-info text-center">
                  <header data-testid="markup-header-searches">Markup</header>
                  <div>{markupAvgTotal.toFixed(2) + " %"}</div>
                </div>
              </div>
            )}
            {showMarkupAndBillRate && (
              <div className="list-rate">
                <div className="ratecard-info text-center">
                  <header data-testid="bill-rate-header-searches">Bill Rate</header>
                  <div>
                    {search.currency?.symbol + numeral(billRateAvgTotal).format("0,0.00")}
                  </div>
                </div>
              </div>
            )}

            <div className="ratecard-info text-center">
              <header data-testid="ID-header-searches">ID</header>
              <div>{search.searchId}</div>
            </div>
            <div className="ratecard-info text-center">
              <header data-testid="rate-type-header-searches">Rate Type</header>
              <div>
                {search.rateType === 1
                  ? RATE_TYPE_OPTIONS_2.CONTRACT.label
                  : RATE_TYPE_OPTIONS_2.FTE.label}
              </div>
            </div>
            <div className="rc-center">
              <Link
                to={`/admin/searches/${search?.searchId || "NULL_ERROR"}`}
                className="btn btn-default"
              >
                View
              </Link>
            </div>
          </div>
          <div className="tagbox">{tagSection}</div>
        </div>
      </div>
    );
  }
}

export default createFragmentContainer(AdminSearchesSearch, {
  search: graphql`
    fragment AdminSearchesSearch_search on SaveSearchNode {
      id
      searchId
      createdDate
      createdBy {
        userId
        username
        firstName
        lastName
      }
      job {
        id
        jobTitleId
        jobTitle
        jobLabel
      }
      city
      state
      country
      region {
        name
      }
      rateType
      currency {
        symbol
      }
      marketRate {
        # these are level III AVG values
        payRate
        billRate
        markupPct
      }
      tags {
        tagId
        name
      }
      isDraft
      isGlobalSupplierSearch
      # Not being used
      # marketchanges {
      #   level {
      #     legacyId
      #     value
      #   }
      #   payRateAvg
      #   markupPctAvg
      #   billRateAvg
      # }
    }
  `,
});
