// @flow
import { action } from "mobx";

import FilterObject, { FilterWithSort, ContainsTextFilter } from "../Filter";
import type FilterColumn from "../Filter";
import Sort from "../Sort";
import SortState from "../SortState";

export default class LocationFilterIContains extends ContainsTextFilter(FilterWithSort) {
  constructor(
    store: Object,
    column: FilterColumn,
    applyFilter: (FilterColumn, FilterObject) => void,
    applySort: (FilterColumn, Sort) => void,
    removeFilter: (FilterColumn) => void,
    removeSort: (FilterColumn) => void
  ) {
    super(store, column);

    this.applyFilter = applyFilter;
    this.applySort = applySort;
    this.removeFilter = removeFilter;
    this.removeSort = removeSort;

    this.sortState = new SortState("LOCATION");

    this.buildQueryFilter = action(this.buildQueryFilter.bind(this));
    this.onApply = action(this.onApply.bind(this));
  }

  buildQueryFilter() {
    if (!this.idToLookFor) {
      return null;
    }

    return new FilterObject(
      "$locationFilterId: [String]",
      "locationId: $locationFilterId",
      {
        locationFilterId: this.idToLookFor,
      }
    );
  }
}
