import { useCallback } from "react";

// @ts-expect-error
import { logAsyncOperationError } from "../../utils/logging";
import { useVal5KPublicContext } from "./context/Val5KPublicContext";

import {
  rateFeedbackToImmutableMap,
  reviewAttemptToImmutableMap,
  reviewToImmutableMap,
} from "../validator5K/dataConverters";

import type {
  AttemptDataMap,
  AttemptDataObject,
  JobFeedbackDataObject,
  RatesFeedbackDataObject,
  ReviewDataMap,
  ReviewDataObject,
} from "./types";
import type { QuestionsFeedbackOrderedMap } from "../validator5K_admin/types";
import type { FetchAPIResponse } from "../../types/fetch";

export type FetchReviewDataFunc = (
  reviewId: string
) => Promise<ReviewDataMap | undefined>;

export const useFetchReviewAPI = (): FetchReviewDataFunc => {
  const { fetchArgusAPI, showModalError } = useVal5KPublicContext();

  return useCallback(
    async (reviewId: string) => {
      try {
        const response: FetchAPIResponse<ReviewDataObject> = await fetchArgusAPI(
          `reviews/${reviewId}/`
        );
        const data: ReviewDataMap = reviewToImmutableMap(response.data);
        return data;
      } catch (err: any) {
        logAsyncOperationError("fetchReviewItemData", err);
        showModalError(`Error occurred while loading survey #${reviewId} data.`);
      }
    },
    [fetchArgusAPI, showModalError]
  );
};

type CreateAttemptPayload = {
  review_id: string;
  first_name: string;
  last_name: string;
  email: string;
};

export type FetchOrCreateAttemptDataFunc = (
  payload: CreateAttemptPayload
) => Promise<AttemptDataMap | undefined>;

export const useFetchOrCreateAttemptAPI = (): FetchOrCreateAttemptDataFunc => {
  const { fetchArgusAPI, showModalError } = useVal5KPublicContext();

  return useCallback(
    async (payload: CreateAttemptPayload) => {
      try {
        const response: FetchAPIResponse<AttemptDataObject> = await fetchArgusAPI(
          `attempts/`,
          {
            method: "post",
            data: payload,
          }
        );
        const data = reviewAttemptToImmutableMap(response.data);
        return data;
      } catch (err: any) {
        logAsyncOperationError("fetchGetOrCreateAttempt", err);
        showModalError(
          "Error occurred while creating user attempt object. Please, try again later."
        );
      }
    },
    [fetchArgusAPI, showModalError]
  );
};

export type UpdateAttemptDataFunc = (
  attemptId: number,
  payload: Partial<AttemptDataObject>
) => Promise<AttemptDataMap | undefined>;

export const useUpdateAttemptAPI = (): UpdateAttemptDataFunc => {
  const { fetchArgusAPI, showModalError } = useVal5KPublicContext();

  return useCallback(
    async (attemptId, payload) => {
      try {
        const response: FetchAPIResponse<AttemptDataObject> = await fetchArgusAPI(
          `attempts/${attemptId}/`,
          {
            method: "patch",
            data: payload,
          }
        );
        const data: AttemptDataMap = reviewAttemptToImmutableMap(response.data);
        return data;
      } catch (err: any) {
        logAsyncOperationError("updateAttempt", err);
        showModalError(
          "Error occurred while updating validation attempt. Please, try again later."
        );
      }
    },
    [fetchArgusAPI, showModalError]
  );
};

export function useCreateOrUpdateJobFeedbackAPI() {
  const { fetchArgusAPI, showModalError } = useVal5KPublicContext();

  return useCallback(
    async (payload: Partial<JobFeedbackDataObject>) => {
      try {
        return await fetchArgusAPI(`job-feedback/`, {
          method: "post",
          data: payload,
        });
      } catch (err: any) {
        logAsyncOperationError("createOrUpdateJobFeedback", err);
        showModalError(
          "Error occurred while submitting feedback. Please, try again later."
        );
      }
    },
    [fetchArgusAPI, showModalError]
  );
}

export function useCreateOrUpdateQuestionsFeedbackAPI() {
  const { fetchArgusAPI, showModalError } = useVal5KPublicContext();

  return useCallback(
    async (questionsFeedback: QuestionsFeedbackOrderedMap) => {
      try {
        return await fetchArgusAPI(`question-feedback/bulk_create/`, {
          method: "post",
          data: questionsFeedback.toList().toJS(),
        });
      } catch (err: any) {
        logAsyncOperationError("createOrUpdateQuestionsFeedback", err);
        showModalError(
          "Error occurred while submitting feedback. Please, try again later."
        );
      }
    },
    [fetchArgusAPI, showModalError]
  );
}

// IMPORTANT: should not be directly used - use appropriate context instead
export function useCreateRateFeedbackAPI() {
  const { showModalError, fetchArgusAPI } = useVal5KPublicContext();

  return useCallback(
    async (payload: Partial<Omit<RatesFeedbackDataObject, "id">>) => {
      try {
        const response: FetchAPIResponse<RatesFeedbackDataObject> = await fetchArgusAPI(
          `rate-feedback/`,
          {
            method: "post",
            data: payload,
          }
        );
        return rateFeedbackToImmutableMap(response.data);
      } catch (err: any) {
        logAsyncOperationError("createRateFeedback", err);
        showModalError(
          "Error occurred while creating rate feedback. Please, try again later."
        );
      }
    },
    [fetchArgusAPI, showModalError]
  );
}

// IMPORTANT: should not be directly used - use appropriate context instead
export function useUpdateRateFeedbackAPI() {
  const { showModalError, fetchArgusAPI } = useVal5KPublicContext();

  return useCallback(
    async (
      feedbackId: RatesFeedbackDataObject["id"],
      payload: Partial<RatesFeedbackDataObject>
    ) => {
      try {
        const response: FetchAPIResponse<RatesFeedbackDataObject> = await fetchArgusAPI(
          `rate-feedback/${feedbackId}/`,
          {
            method: "patch",
            data: payload,
          }
        );
        return rateFeedbackToImmutableMap(response.data);
      } catch (err: any) {
        logAsyncOperationError("updateRateFeedback", err);
        showModalError(
          "Error occurred while updating rate feedback. Please, try again later."
        );
      }
    },
    [fetchArgusAPI, showModalError]
  );
}
