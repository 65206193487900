import React from "react";

import type { RowData } from "./types";
import { rowIdGetter } from "./utils";

type RefreshDataFunc = () => Promise<any>;

export function useEditingRowState<RD extends RowData>(refreshData?: RefreshDataFunc) {
  const [editingRowId, setEditingRowId] = React.useState<number | null>(null);

  const startRowEditing = React.useCallback(
    (rowData: RD) => {
      const rowId = rowIdGetter(rowData);
      let nextEditingRowId: number | null = rowId;

      if (rowId != null) {
        if (rowId === editingRowId) {
          nextEditingRowId = null;
        }

        if (nextEditingRowId !== editingRowId) {
          setEditingRowId(nextEditingRowId);
        }
      }
    },
    [editingRowId]
  );

  const stopRowEditing = React.useCallback(
    async (refresh: boolean = false) => {
      setEditingRowId(null);
      return refresh && refreshData ? refreshData() : Promise.resolve();
    },
    [refreshData, setEditingRowId]
  );

  return {
    editingRowId,
    startRowEditing,
    stopRowEditing,
  };
}
