import * as React from "react";
import { styled } from "../../stitches.config";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import {
  scaleOut,
  slideDownAndFade,
  slideLeftAndFade,
  slideRightAndFade,
  slideUpAndFade,
} from "./Animations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-svg-core";

const StyledContent = styled(DropdownMenuPrimitive.Content, {
  minWidth: 220,
  backgroundColor: "white",
  borderRadius: "$md",
  padding: "$1",
  border: "1px solid $primaryLight",
  boxShadow:
    "0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)",
  "@media (prefers-reduced-motion: no-preference)": {
    animationDuration: "300ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    willChange: "transform, opacity",
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
    '&[data-state="closed"]': {
      animationName: scaleOut,
    },
  },
});
StyledContent.displayName = "StyledContent";

const itemStyles = {
  all: "unset",
  fontSize: "$sm",
  lineHeight: "$sm",
  color: "$text",
  borderRadius: "$rounded",
  display: "flex",
  alignItems: "center",
  position: "relative",
  userSelect: "none",
  padding: "6px 20px",

  "&[data-disabled]": {
    color: "$primaryLight",
    pointerEvents: "none",
  },

  "&:focus": {
    backgroundColor: "$brand",
    color: "$brandLightest",
    cursor: "pointer",
  },

  "&:hover": {
    backgroundColor: "$brandLightest",
    color: "$brand",
    cursor: "pointer",
  },
};

const StyledItem = styled(DropdownMenuPrimitive.Item, {
  ...itemStyles,
});
StyledItem.displayName = "StyledItem";

const StyledCheckboxItem = styled(DropdownMenuPrimitive.CheckboxItem, { ...itemStyles });
StyledCheckboxItem.displayName = "StyledCheckboxItem";

const StyledRadioItem = styled(DropdownMenuPrimitive.RadioItem, { ...itemStyles });
StyledRadioItem.displayName = "StyledRadioItem";

const StyledTriggerItem = styled(DropdownMenuPrimitive.TriggerItem, {
  '&[data-state="open"]': {
    backgroundColor: "$brandLighter",
    color: "$brandDark",
  },
  ...itemStyles,
});
StyledTriggerItem.displayName = "StyledTriggerItem";

const StyledLabel = styled(DropdownMenuPrimitive.Label, {
  paddingLeft: "$6",
  fontSize: "$xs",
  lineHeight: "$sm",
  color: "$primary",
});
StyledLabel.displayName = "StyledLabel";

const StyledSeparator = styled(DropdownMenuPrimitive.Separator, {
  height: 1,
  backgroundColor: "#787878",
  margin: 5,
  "&:last-child, &:first-child": {
    display: "none",
  },
});
StyledSeparator.displayName = "StyledSeparator";

const StyledItemIndicator = styled(DropdownMenuPrimitive.ItemIndicator, {
  position: "absolute",
  left: 0,
  width: "$6",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "$xs",
  lineHeight: "$xs",
  backgroundColor: "none",
});
StyledItemIndicator.displayName = "StyledItemIndicator";

const RightSlot = styled("div", {
  marginLeft: "auto",
  paddingLeft: "$5",
  color: "$primary",
  ":focus > &": { color: "white" },
  "[data-disabled] &": { color: "$primaryLight" },
});
RightSlot.displayName = "RightSlot";

export const DropdownMenu = DropdownMenuPrimitive.Root;
DropdownMenu.displayName = "DropdownMenu";

export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
DropdownMenuTrigger.displayName = "DropdownMenuTrigger";

type ContentPrimitiveProps = React.ComponentPropsWithoutRef<
  typeof DropdownMenuPrimitive.Content
>;
export const DropdownMenuContent = React.forwardRef<
  HTMLDivElement,
  ContentPrimitiveProps
>(({ children, ...props }, forwardedRef) => {
  return (
    <StyledContent sideOffset={4} {...props} ref={forwardedRef}>
      {children}
    </StyledContent>
  );
});
DropdownMenuContent.displayName = "DropdownMenuContent";

export const DropdownMenuSubContent = React.forwardRef<
  HTMLDivElement,
  ContentPrimitiveProps
>(({ children, ...props }, forwardedRef) => {
  return (
    <StyledContent sideOffset={2} alignOffset={-5} {...props} ref={forwardedRef}>
      {children}
    </StyledContent>
  );
});
DropdownMenuSubContent.displayName = "DropdownMenuSubContent";

export const DropdownMenuLabel = StyledLabel;
DropdownMenuLabel.displayName = "DropdownMenuLabel";

type ItemPrimitiveProps = React.ComponentPropsWithoutRef<
  typeof DropdownMenuPrimitive.Item
>;
type ItemUtilProps = {
  text?: string;
  icon?: IconName;
  rightSlot?: React.ReactNode;
};
export const DropdownMenuItem = React.forwardRef<
  HTMLDivElement,
  ItemPrimitiveProps & ItemUtilProps
>(({ children, icon, text, rightSlot, ...props }, forwardedRef) => {
  return (
    <StyledItem {...props} ref={forwardedRef}>
      {icon && (
        <StyledItemIndicator forceMount>
          <FontAwesomeIcon icon={icon} />
        </StyledItemIndicator>
      )}
      {text} {children} {rightSlot && <RightSlot>{rightSlot}</RightSlot>}
    </StyledItem>
  );
});
DropdownMenuItem.displayName = "DropdownMenuItem";

type TriggerItemPrimitiveProps = React.ComponentPropsWithoutRef<
  typeof DropdownMenuPrimitive.TriggerItem
>;
type TriggerItemUtilProps = {
  text?: string;
  icon?: IconName;
  rightSlot?: React.ReactNode;
};
export const DropdownMenuTriggerItem = React.forwardRef<
  HTMLDivElement,
  TriggerItemPrimitiveProps & TriggerItemUtilProps
>(({ children, icon, text, rightSlot, ...props }, forwardedRef) => {
  return (
    <StyledTriggerItem {...props} ref={forwardedRef}>
      {icon && (
        <StyledItemIndicator forceMount>
          <FontAwesomeIcon icon={icon} />
        </StyledItemIndicator>
      )}
      {text} {children}{" "}
      <RightSlot>
        {rightSlot ? rightSlot : <FontAwesomeIcon icon="chevron-right" />}
      </RightSlot>
    </StyledTriggerItem>
  );
});
DropdownMenuTriggerItem.displayName = "DropdownMenuTriggerItem";

export const DropdownMenuGroup = DropdownMenuPrimitive.Group;
DropdownMenuGroup.displayName = "DropdownMenuGroup";

type CheckboxItemPrimitiveProps = React.ComponentPropsWithoutRef<
  typeof DropdownMenuPrimitive.CheckboxItem
>;
type CheckboxItemUtilProps = {
  text?: string;
  rightSlot?: React.ReactNode;
};
export const DropdownMenuCheckboxItem = React.forwardRef<
  HTMLDivElement,
  CheckboxItemPrimitiveProps & CheckboxItemUtilProps
>(({ children, text, rightSlot, ...props }, forwardedRef) => {
  return (
    <StyledCheckboxItem {...props} ref={forwardedRef}>
      <StyledItemIndicator>
        <FontAwesomeIcon icon="check" />
      </StyledItemIndicator>
      {text} {children} {rightSlot && <RightSlot>{rightSlot}</RightSlot>}
    </StyledCheckboxItem>
  );
});
DropdownMenuCheckboxItem.displayName = "DropdownMenuCheckboxItem";

export const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;
DropdownMenuRadioGroup.displayName = "DropdownMenuRadioGroup";

type RadioItemPrimitiveProps = React.ComponentPropsWithoutRef<
  typeof DropdownMenuPrimitive.RadioItem
>;
type RadioItemUtilProps = {
  text?: string;
  rightSlot?: React.ReactNode;
};
export const DropdownMenuRadioItem = React.forwardRef<
  HTMLDivElement,
  RadioItemPrimitiveProps & RadioItemUtilProps
>(({ children, text, rightSlot, ...props }, forwardedRef) => {
  return (
    <StyledRadioItem {...props} ref={forwardedRef}>
      <StyledItemIndicator>
        <FontAwesomeIcon icon="dot-circle" />
      </StyledItemIndicator>
      {text} {children} {rightSlot && <RightSlot>{rightSlot}</RightSlot>}
    </StyledRadioItem>
  );
});
DropdownMenuRadioItem.displayName = "DropdownMenuRadioItem";

export const DropdownMenuSeparator = StyledSeparator;
DropdownMenuSeparator.displayName = "DropdownMenuSeparator";
