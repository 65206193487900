import React, { useCallback } from "react";
import { useRecoilState } from "recoil";

import { useVal5KPublicContext } from "../../context/Val5KPublicContext";
import { surveyHistoryTableGlobalState } from "../../globalState";
import { djangoPaginationKey, djangoPaginationSizeKey } from "../../../../constants";
import { useInitialFetch } from "../../../../utils/hooks";
import { TickerContentLoader } from "../../../../components/lib/TickerLoader";
import SurveysHistoryTable from "./SurveysHistoryTable";
import { transformAttemptsData } from "../../dataConverters";
import Box from "../../../../components/lib/Box";
// @ts-expect-error
import { logAsyncOperationError } from "../../../../utils/logging";

import type { SurveyHistoryTableDataStateObject } from "../../globalState";
import type { FetchAPIResponse } from "../../../../types/fetch";
import type { DjangoPaginatedResponse } from "../../../../types/django";
import type { AttemptDataObject } from "../../types";

const SurveysHistoryList = () => {
  const { auth, fetchArgusFilteringAPI, showModalError } = useVal5KPublicContext();

  // state

  const [surveyHistoryTableState, setSurveyHistoryTableState] =
    useRecoilState<SurveyHistoryTableDataStateObject>(surveyHistoryTableGlobalState);
  const { activePage, itemsPerPage, filtersQuery, loaded } = surveyHistoryTableState;

  // data fetch funcs

  const fetchSurveyHistoryData = useCallback(
    async (urlQuery = {}, filtersQuery = {}, nextStateUpdates = {}) => {
      try {
        const response: FetchAPIResponse<DjangoPaginatedResponse<AttemptDataObject>> =
          await fetchArgusFilteringAPI(`surveys/history/filtered/`, {
            params: {
              _email: auth.email,
              ...urlQuery,
            },
            data: filtersQuery,
          });

        const nextDataState: Partial<SurveyHistoryTableDataStateObject> =
          transformAttemptsData(response.data, nextStateUpdates);

        setSurveyHistoryTableState((prevDataState) => ({
          ...prevDataState,
          ...nextDataState,
          loaded: true,
        }));

        return { ...surveyHistoryTableState, ...nextDataState };
      } catch (err: any) {
        logAsyncOperationError("fetchSurveyHistoryData", err);
        showModalError(
          "Error occurred while loading survey history list. Please, try again later."
        );
        throw err;
      }
    },
    [
      auth.email,
      surveyHistoryTableState,
      setSurveyHistoryTableState,
      fetchArgusFilteringAPI,
      showModalError,
    ]
  );

  const fetchSurveyHistoryFiltersData = useCallback(
    async (urlQuery = {}, filtersQuery = {}) => {
      try {
        const response: FetchAPIResponse<DjangoPaginatedResponse<any>> =
          await fetchArgusFilteringAPI(`surveys/history/values/filtered/`, {
            params: {
              _email: auth.email,
              ...urlQuery,
            },
            data: filtersQuery,
          });

        return response.data;
      } catch (err: any) {
        logAsyncOperationError("fetchSurveyHistoryFiltersData", err);
        showModalError(
          "Error occurred while loading filter values. Please, try again later."
        );
        throw err;
      }
    },
    [auth.email, fetchArgusFilteringAPI, showModalError]
  );

  // data refresh utility func

  const refreshSurveyHistory = useCallback(async () => {
    return await fetchSurveyHistoryData(
      {
        [djangoPaginationKey]: activePage,
        [djangoPaginationSizeKey]: itemsPerPage,
      },
      filtersQuery.toJS()
    );
  }, [activePage, itemsPerPage, filtersQuery, fetchSurveyHistoryData]);

  // Initial data load

  useInitialFetch(refreshSurveyHistory);

  // rendering

  if (!loaded) {
    return (
      <Box fill css={{ minHeight: "200px", position: "relative" }}>
        <TickerContentLoader />
      </Box>
    );
  }

  return (
    <SurveysHistoryTable
      dataProvider={fetchSurveyHistoryData}
      filtersDataProvider={fetchSurveyHistoryFiltersData}
    />
  );
};

SurveysHistoryList.displayName = "SurveysHistoryList";

export default SurveysHistoryList;
