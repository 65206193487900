import * as React from "react";
// @ts-ignore
import ContainerSection from "../../views/ratecards/components/ContainerSection";
// @ts-ignore
import Modal from "../../views/ratecards/components/Modal";
import { observer } from "mobx-react";
// @ts-ignore
import { ModalButtons, ModalTitle } from "../../views/ratecards/components/FilterModal";
import Button from "../lib/Button";

interface ModalProps {
  show: boolean;
  onHide: () => void;
}

function LevelsGuideModal(props: ModalProps): React.ReactNode {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      innerStyle={{
        width: "70% !important",
        minHeight: "500px",
      }}
    >
      <ContainerSection className="header">
        <ModalTitle>Levels Guide</ModalTitle>
      </ContainerSection>
      <div className="rc-viewport overlap-lr-padding overlap-tb-padding">
        <ContainerSection className="text-medium">
          Every organization has their own way of defining experience levels. Through our
          research, it became apparent and logical that, regardless of how experience
          level banding is defined, one pattern emerges; the lower the experience level,
          the lower the rate or salary, and vice-versa. This is the basic premise that
          allows us to examine and categorize the un-banded marketplace. Using this
          methodology it is possible to define experience level banding against the
          un-banded marketplace and satisfy the demands of all companies regardless of the
          experience levels that they may use. Although our banding methodology is not
          determined by years of experience, there is often a correlation between levels
          and years of experience as follows.
          <br />
          <br />
          <table className="table table-condensed table-bordered">
            <thead>
              <tr>
                <th style={{ textAlign: "left" }}>Level</th>
                <th style={{ textAlign: "left" }}>May correlate with</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Level I</td>
                <td>1-3 years</td>
              </tr>
              <tr>
                <td>Level II</td>
                <td>3-5 years</td>
              </tr>
              <tr>
                <td>Level III</td>
                <td>5-7 Years</td>
              </tr>
              <tr>
                <td>Level IV</td>
                <td>7 – 10 years</td>
              </tr>
              <tr>
                <td>Level V</td>
                <td>Top Earners (10+)</td>
              </tr>
            </tbody>
          </table>
        </ContainerSection>
      </div>
      <ContainerSection className="footer">
        <ModalButtons>
          <Button size="large" onClick={props.onHide}>
            Close
          </Button>
        </ModalButtons>
      </ContainerSection>
    </Modal>
  );
}

LevelsGuideModal.displayName = "LevelsGuideModal";

// @ts-ignore
export default observer(LevelsGuideModal);
