import React, { useCallback, useEffect, useRef } from "react";
import { useRecoilState } from "recoil";

import ProgressBar from "../../../components/lib/ProgressBar";
import { Link } from "../../../components/lib/Link";
import Alert from "../../../components/lib/Alert";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";

import {
  djangoOrderingKey,
  djangoPaginationKey,
  djangoPaginationSizeKey,
  emptyMap,
} from "../../../constants";

import { usePLIContext } from "../context";
import { runningProcessingsAlertGlobalState } from "../globalState";
import { transformContractorsProcessingsData } from "../dataConverters";
import { useIntervalDataRefresh } from "../hooks";
import { PROCESSING_STATUSES, PROCESSING_TYPES, PROCESSING_TYPES_LABELS } from "../types";
import { secondsToIntervalString } from "../constants";

import type { FetchAPIResponse } from "../../../types/fetch";
import type { DjangoPaginatedResponse } from "../../../types/django";
import type { ProcessingDataMap, ProcessingDataObject } from "../types";

const baseComponentClassName = "pt-running-processings-alert";

export type RunningProcessingsAlertProps = {
  programId: number;
  storedIndexId?: number | null;
  refreshRequestId: number | null;
  onChangeProcessingsNumber: () => void;
};

const RunningProcessingsAlert = (props: RunningProcessingsAlertProps) => {
  const { programId, storedIndexId, refreshRequestId, onChangeProcessingsNumber } = props;

  const [processingsDataState, setProcessingsDataState] = useRecoilState(
    runningProcessingsAlertGlobalState
  );
  const { fetchM8FilteringAPI, showModalError } = usePLIContext();

  // fetch data

  const fetchProcessingsData = useCallback(async () => {
    try {
      const response: FetchAPIResponse<DjangoPaginatedResponse<ProcessingDataObject>> =
        await fetchM8FilteringAPI(`programs/${programId}/processings/filtered/`, {
          params: {
            [djangoPaginationKey]: 1,
            [djangoPaginationSizeKey]: 10,
            [djangoOrderingKey]: "-started_at",
          },
          data: {
            program__id: programId,
            status: PROCESSING_STATUSES.RUNNING,
          },
        });
      const processingsData = transformContractorsProcessingsData(response.data);
      let resultingData = processingsData.data;

      if (storedIndexId != null) {
        const viewProcessingsData = processingsData.data.filter(
          (item) => item.get("stored_index")?.get("id") === storedIndexId
        );
        const otherProcessingsData = processingsData.data.filter(
          (item) => item.get("stored_index")?.get("id") !== storedIndexId
        );

        resultingData = viewProcessingsData.concat(otherProcessingsData);
      }

      setProcessingsDataState((prevState) => ({
        ...prevState,
        ...processingsData,
        data: resultingData,
        loaded: true,
      }));

      return resultingData;
    } catch (err: any) {
      logAsyncOperationError("fetchRunningProcessingsList", err);
      showModalError("Error occurred while loading running processings list.");
    }
  }, [
    programId,
    storedIndexId,
    setProcessingsDataState,
    fetchM8FilteringAPI,
    showModalError,
  ]);

  // effects

  useEffect(() => {
    fetchProcessingsData();
  }, [fetchProcessingsData, refreshRequestId]);

  const needRefresh = useCallback(
    () => !!processingsDataState.itemsCount,
    [processingsDataState.itemsCount]
  );

  useIntervalDataRefresh(
    fetchProcessingsData,
    processingsDataState.data,
    needRefresh,
    1000
  );

  const prevProcessingsNumRef = useRef(processingsDataState.itemsCount);

  useEffect(() => {
    const isProcessingsNumChanged =
      processingsDataState.itemsCount !== prevProcessingsNumRef.current;

    if (
      processingsDataState.loaded &&
      isProcessingsNumChanged &&
      onChangeProcessingsNumber
    ) {
      onChangeProcessingsNumber();
    }

    prevProcessingsNumRef.current = processingsDataState.itemsCount;
  }, [
    processingsDataState.loaded,
    processingsDataState.itemsCount,
    onChangeProcessingsNumber,
  ]);

  // render

  if (processingsDataState.itemsCount <= 0) return null;

  const latestProcessing: ProcessingDataMap = processingsDataState.data.size
    ? processingsDataState.data.first()
    : (emptyMap as ProcessingDataMap);
  const latestProcessingType = latestProcessing.get("type", PROCESSING_TYPES.CONTRACTORS);
  const latestProcessingTotalItems = latestProcessing.get("total_items", 0);
  const latestProcessingFinishedItems = latestProcessing.get("finished_items", 0);
  const latestProcessingFailedItems = latestProcessing.get("failed_items", 0);
  const latestProcessingEstimatedTimeLeft = latestProcessing.get(
    "estimated_time_left",
    0
  );

  return (
    <Alert color="warning">
      <h3>
        {processingsDataState.itemsCount} data processing
        {processingsDataState.itemsCount > 1 ? "s" : ""} running in the system.
      </h3>
      {latestProcessing.size > 0 && (
        <div className={baseComponentClassName + "__progress-line"}>
          <label>
            {processingsDataState.itemsCount > 1 ? "Last " : ""}Processing #
            {latestProcessing.get("id")} Progress:
          </label>
          <ProgressBar
            progress={latestProcessingFinishedItems + latestProcessingFailedItems}
            total={latestProcessingTotalItems}
            label={PROCESSING_TYPES_LABELS[latestProcessingType]}
            postLabel={
              !latestProcessingFinishedItems && !latestProcessingFailedItems
                ? "(queueing)"
                : !!latestProcessingEstimatedTimeLeft
                ? `(${secondsToIntervalString(latestProcessingEstimatedTimeLeft)} left)`
                : null
            }
            css={{
              width: "300px",
              verticalAlign: "middle",
              backgroundColor: "inherit",
              border: "1px solid $warning",
              "& > div:first-child": {
                backgroundColor: "$warning",
              },
            }}
          />
        </div>
      )}
      <Link to={`/private-index/programs/${programId}/contractors/processings`}>
        <u>See All Running Processings</u>
      </Link>
    </Alert>
  );
};

RunningProcessingsAlert.displayName = "RunningProcessingsAlert";
RunningProcessingsAlert.defaultProps = {
  storedIndexId: null,
  refreshRequestId: null,
};

export default RunningProcessingsAlert;
