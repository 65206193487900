import { Set } from "immutable";
import { Country } from "../../types";
import { PayDifferenceSet } from "../../hooks/usePayDifference";
import { useMemo } from "react";

export const DEFAULT_PROJECTION = "geoMercator" as const;

export const USA_PROJECTION = "geoAlbersUsa" as const;

export const DEFAULT_MARKER_SCALE = 12.8 as const;

export type MapConfig = {
  topoURL: string;
  projection?: typeof DEFAULT_PROJECTION | typeof USA_PROJECTION;
  markerScale: typeof DEFAULT_MARKER_SCALE;
  scale?: number;
  center?: [number, number];
  rotation?: [number, number, number];
};

export const MAP_CONFIGS: Record<string, MapConfig> = {
  WORLD: {
    topoURL: "/vendor/mapdata-2/world-countries-sans-antarctica.json",
    projection: undefined,
    markerScale: DEFAULT_MARKER_SCALE,
  },
  US: {
    topoURL: "/vendor/mapdata-2/us-albers.json",
    projection: USA_PROJECTION,
    markerScale: DEFAULT_MARKER_SCALE,
  },
  AU: {
    topoURL: "/vendor/mapdata-2/au-all.topo.json",
    projection: DEFAULT_PROJECTION,
    markerScale: DEFAULT_MARKER_SCALE,
    scale: 800,
    center: [135, 331],
  },
  BE: {
    topoURL: "/vendor/mapdata-2/be-all.topo.json",
    projection: DEFAULT_PROJECTION,
    markerScale: DEFAULT_MARKER_SCALE,
    scale: 9000,
    center: [4.5, 50.5],
  },
  CA: {
    topoURL: "/vendor/mapdata-2/ca-all.topo.json",
    projection: DEFAULT_PROJECTION,
    markerScale: DEFAULT_MARKER_SCALE,
    scale: 250,
    center: [-100, 70],
  },
  CN: {
    topoURL: "/vendor/mapdata-2/cn-all.topo.json",
    projection: DEFAULT_PROJECTION,
    markerScale: DEFAULT_MARKER_SCALE,
    scale: 700,
    center: [104, 38],
  },
  CZ: {
    topoURL: "/vendor/mapdata-2/cz-all.topo.json",
    projection: DEFAULT_PROJECTION,
    markerScale: DEFAULT_MARKER_SCALE,
    scale: 6000,
    center: [15.5, 49.8],
  },
  FR: {
    topoURL: "/vendor/mapdata-2/fr-all.topo.json",
    projection: DEFAULT_PROJECTION,
    markerScale: DEFAULT_MARKER_SCALE,
    scale: 2000,
    center: [3, 46],
  },
  DE: {
    topoURL: "/vendor/mapdata-2/de-all.topo.json",
    projection: DEFAULT_PROJECTION,
    markerScale: DEFAULT_MARKER_SCALE,
    scale: 2300,
    center: [10, 51],
  },
  HK: {
    topoURL: "/vendor/mapdata-2/hongkong.json",
    projection: DEFAULT_PROJECTION,
    markerScale: DEFAULT_MARKER_SCALE,
    scale: 60000,
    center: [114.15, 22.35],
  },
  HU: {
    topoURL: "/vendor/mapdata-2/hu-all.topo.json",
    projection: DEFAULT_PROJECTION,
    markerScale: DEFAULT_MARKER_SCALE,
    scale: 5500,
    center: [19, 47],
  },
  IN: {
    topoURL: "/vendor/mapdata-2/in-all.topo.json",
    projection: DEFAULT_PROJECTION,
    markerScale: DEFAULT_MARKER_SCALE,
    scale: 1000,
    center: [78.9629, 22.5937],
  },
  IE: {
    topoURL: "/vendor/mapdata-2/ie-all.topo.json",
    projection: DEFAULT_PROJECTION,
    markerScale: DEFAULT_MARKER_SCALE,
    scale: 5000,
    center: [-8, 53.5],
  },
  IL: {
    topoURL: "/vendor/mapdata-2/il-all.topo.json",
    projection: DEFAULT_PROJECTION,
    markerScale: DEFAULT_MARKER_SCALE,
    scale: 7000,
    center: [35, 31.5],
  },
  JP: {
    topoURL: "/vendor/mapdata-2/jp-all.topo.json",
    projection: DEFAULT_PROJECTION,
    markerScale: DEFAULT_MARKER_SCALE,
    scale: 1300,
    center: [138, 37],
  },
  MX: {
    topoURL: "/vendor/mapdata-2/mx-all.topo.json",
    projection: DEFAULT_PROJECTION,
    markerScale: DEFAULT_MARKER_SCALE,
    scale: 1300,
    center: [-101, 23],
  },
  NL: {
    topoURL: "/vendor/mapdata-2/nl-all.topo.json",
    projection: DEFAULT_PROJECTION,
    markerScale: DEFAULT_MARKER_SCALE,
    scale: 6000,
    center: [5.3, 52],
  },
  NZ: {
    topoURL: "/vendor/mapdata-2/nz-all.topo.json",
    projection: DEFAULT_PROJECTION,
    markerScale: DEFAULT_MARKER_SCALE,
    scale: 1900,
    center: [174, -41],
  },
  PH: {
    topoURL: "/vendor/mapdata-2/ph-all.topo.json",
    projection: DEFAULT_PROJECTION,
    markerScale: DEFAULT_MARKER_SCALE,
    scale: 2000,
    center: [123, 12],
  },
  PL: {
    topoURL: "/vendor/mapdata-2/pl-all.topo.json",
    projection: DEFAULT_PROJECTION,
    markerScale: DEFAULT_MARKER_SCALE,
    scale: 3000,
    center: [20, 52],
  },
  RU: {
    topoURL: "/vendor/mapdata-2/ru-all.topo.json",
    projection: DEFAULT_PROJECTION,
    markerScale: DEFAULT_MARKER_SCALE,
    scale: 250,
    center: [100, 68],
    rotation: [-10, 0, 0],
  },
  SG: {
    topoURL: "/vendor/mapdata-2/sg-all.topo.json",
    projection: DEFAULT_PROJECTION,
    markerScale: DEFAULT_MARKER_SCALE,
    scale: 100000,
    center: [103.8, 1.35],
  },
  CH: {
    topoURL: "/vendor/mapdata-2/ch-all.topo.json",
    projection: DEFAULT_PROJECTION,
    markerScale: DEFAULT_MARKER_SCALE,
    scale: 8000,
    center: [8.3, 46.7],
  },
  GB: {
    topoURL: "/vendor/mapdata-2/gb-all.topo.json",
    projection: DEFAULT_PROJECTION,
    markerScale: DEFAULT_MARKER_SCALE,
    scale: 1700,
    center: [-3, 55.8],
  },
};

export function useMapConfig(locations: PayDifferenceSet, countries: Country[]) {
  return useMemo(() => {
    const countrySet = locations.reduce((set, location) => {
      if (!location.region && location.country) {
        const country = countries.find((c) => c?.name === location.country);
        return country ? set.add(country.isoCode) : set;
      }
      return set;
    }, Set<string>());

    if (countrySet.count() === 1) {
      const countryCode = countrySet.first();
      return MAP_CONFIGS[countryCode] || MAP_CONFIGS["WORLD"];
    }
    return MAP_CONFIGS["WORLD"];
  }, [locations, countries]);
}
