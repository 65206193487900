import React, {
  useState,
  KeyboardEventHandler,
  useRef,
  ReactNode,
  ChangeEventHandler,
  useCallback,
  MouseEventHandler,
} from "react";
import { styled } from "../../stitches.config";
import { useClickAwayListener } from "../../hooks/useClickAwayListener";
import TextInput, { InputProps } from "./TextInput";
import { FormControl, Label, LabelWrapper, RequiredSign } from "./Label";
import Icon from "./Icon";
import { useArrowNav } from "../../hooks/useArrowNav";
import { IconName } from "@fortawesome/fontawesome-common-types";
import { scaleIn, scaleOut } from "./Animations";
import { useEscapePress } from "../../hooks/useEscapePress";

const Content = styled("div", {
  backgroundColor: "$white",
  position: "absolute",
  top: "100%",
  left: "0",
  transformOrigin: "top bottom",
  overflowX: "hidden",
  overflowY: "auto",
  marginTop: "$0_5",
  borderRadius: "$rounded",
  border: "solid 1px $primaryLight",
  boxShadow: "$md",
  width: "100%",
  zIndex: 2,
  maxHeight: "195px",
  "&[data-state='open']": {
    animation: `${scaleIn} 300ms`,
  },
  "&[data-state='close']": {
    animation: `${scaleOut} 300ms`,
    transform: "scale(0)",
  },
  "&[data-state='initial']": {
    display: "none",
  },
});

const CloseBtn = styled("button", {
  backgroundColor: "$white",
  border: "none",
});

const List = styled("ul", {
  listStyle: "none",
  margin: 0,
  padding: 0,
});

const ListItem = styled("li", {
  width: "100%",
  "&[data-selected='true'] button": {
    backgroundColor: "$successLightest",
  },
  "&[data-disabled='true'] button": {
    backgroundColor: "$dangerLightest",
    cursor: "not-allowed",
  },
});

const Btn = styled("button", {
  padding: "$2 $4",
  backgroundColor: "$white",
  border: "none",
  fontSize: "$base",
  width: "100%",
  textAlign: "left",
  display: "inline-flex",
  justifyContent: "space-between",
  alignItems: "center",
  "&:hover": {
    backgroundColor: "$accentLighter",
  },
  "&:focus": {
    backgroundColor: "$accentLighter",
    border: "none",
    outline: "none",
  },
  variants: {
    disabled: {
      true: {
        cursor: "not-allowed",
      },
      false: {},
    },
  },
});

const Placeholder = styled("li", {
  display: "inline-block",
  padding: "$2 $4",
  fontSize: "$base",
  width: "100%",
  textAlign: "left",
  color: "$primary",
});

export type MultiSelectProps<Option> = {
  options: Option[];
  selected: Option[];
  disabledOptions?: Option[];
  size?: InputProps["size"];
  icon?: IconName;
  onSelect: (option: Option) => void;
  onClearSelections: () => void;
  loading?: boolean;
  readOnly?: boolean;
  label: string;
  extraLabel?: ReactNode;
  id: string;
  required?: boolean;
  placeholder?: string;
  onInputValueChange?: (value: string) => void;
  getKey: (option: Option) => string;
  renderAddon?: (option: Option[]) => ReactNode;
  renderOption: (opt: Option) => ReactNode;
  noResultsRender?: ReactNode;
  addons?: ReactNode;
  onKeyUp?: KeyboardEventHandler<HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  disabled?: boolean;
};

export function MultiSelect<Option>(props: MultiSelectProps<Option>) {
  const {
    loading = false,
    readOnly = false,
    options = [],
    selected = [],
    onInputValueChange = () => {},
    label,
    id,
    required = false,
    placeholder,
    getKey,
    renderAddon,
    noResultsRender = "No results matched your search",
    onClearSelections,
    onKeyUp,
    onKeyDown,
    renderOption,
    onSelect,
    icon,
    size = "normal",
    extraLabel = null,
    disabled = false,
    disabledOptions = [],
  } = props;

  const rootRef = useRef<HTMLDivElement | null>(null);
  const inputWrapperRef = useRef<HTMLDivElement | null>(null);
  const listRef = useRef(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [inputValue, setInputValue] = useState("");
  const [state, setState] = useState<"open" | "close" | "initial">("initial");

  const open = useCallback(() => {
    if (state !== "open") {
      setState("open");
    }
  }, [state]);

  const close = useCallback(() => {
    if (state === "open") {
      setState("close");
    }
  }, [state]);

  const clear: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();
      close();
      onClearSelections();
      setInputValue("");
    },
    [onClearSelections, setInputValue, close]
  );

  const handleValueChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setInputValue(e.target.value);
      onInputValueChange(e.target.value);
    },
    [setInputValue, onInputValueChange]
  );

  const handleOnSelect = useCallback(
    (option: Option) => {
      return () => {
        if (disabled) return;
        if (!disabledOptions.some((s) => getKey(s) === getKey(option))) {
          onSelect(option);
          setInputValue("");
          close();
        }
      };
    },
    [getKey, onSelect, setInputValue, close, disabledOptions, disabled]
  );

  useEscapePress(
    useCallback(() => {
      close();
      inputRef.current?.blur();
    }, [close]),
    state === "open"
  );
  useClickAwayListener(rootRef, close);
  useArrowNav(listRef, inputRef, state === "open");

  return (
    <FormControl disabled={disabled} fill ref={rootRef}>
      <LabelWrapper disabled={disabled}>
        <Label htmlFor={id}>
          {label}
          {required && <RequiredSign>&nbsp;*</RequiredSign>}
        </Label>
        {extraLabel}
      </LabelWrapper>
      <TextInput
        wrapperClassName="multiselect-input-wrapper"
        onWrapperClick={() => inputRef?.current?.focus()}
        wrapperRef={inputWrapperRef}
        ref={inputRef}
        placeholder={selected.length > 0 ? "" : placeholder}
        css={{ width: "100%", gap: "$1" }}
        id={id}
        size={size}
        addon={renderAddon ? renderAddon(selected) : null}
        addonRight={
          <>
            {!disabled && selected.length > 0 && (
              <CloseBtn
                onClick={clear}
                className="clear-select-btn"
                title="Clear selections"
              >
                <Icon icon="times" />
              </CloseBtn>
            )}
            {options.length > 0 && selected.length === 0 && <Icon icon="caret-down" />}
          </>
        }
        required={required}
        value={inputValue}
        onChange={handleValueChange}
        onFocus={open}
        autoComplete="off"
        icon={icon}
        aria-owns={`${id}-results`}
        loading={loading}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        withoutWrapper
        readOnly={readOnly}
        disabled={disabled}
      />

      <Content data-state={state}>
        <List id={`${id}-results`} role="listbox" tabIndex={0} ref={listRef}>
          {loading ? (
            <Placeholder role="option" aria-selected="false">
              Loading...
            </Placeholder>
          ) : options?.length === 0 ? (
            <Placeholder role="option" aria-selected="false">
              {noResultsRender}
            </Placeholder>
          ) : (
            options.map((option) => {
              const isSelected = selected.some((s) => getKey(s) === getKey(option));
              const isDisabled = disabledOptions.some(
                (s) => getKey(s) === getKey(option)
              );
              return (
                <ListItem
                  key={getKey(option)}
                  role="option"
                  data-selected={isSelected}
                  data-disabled={isDisabled}
                  aria-selected={isSelected}
                >
                  <Btn onClick={handleOnSelect(option)}>{renderOption(option)}</Btn>
                </ListItem>
              );
            })
          )}
        </List>
      </Content>
    </FormControl>
  );
}
