import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import LevelCardSparkLine from "../components/LevelCardSparkLine";
import LevelCardChangeSparkLine from "../components/LevelCardChangeSparkLine";

const ResultLevelCardSectionSparkLineChart = observer((props) => {
  let store = props.store;
  let months = props.months;
  let ratesHistoryData = props.ratesHistoryData;
  let ratesMissingData = props.ratesMissingData;
  let changesHistoryData = props.changesHistoryData;
  let changesMissingData = props.changesMissingData;
  let currencySymbol = props.currencySymbol;
  let seriesColor = props.seriesColor;
  let positiveColor = props.positiveColor;
  let negativeColor = props.negativeColor;

  return (
    <div>
      <div className={classNames({ hidden: !store.showRates })}>
        <LevelCardSparkLine
          color={seriesColor}
          data={ratesHistoryData}
          missingData={ratesMissingData}
          categories={months}
          currencySymbol={currencySymbol}
        />
      </div>
      <div className={classNames(props.changeType, { hidden: !store.showDeltas })}>
        <LevelCardChangeSparkLine
          color={positiveColor}
          negativeColor={negativeColor}
          data={changesHistoryData}
          missingData={changesMissingData}
          categories={months}
          currencySymbol={currencySymbol}
        />
      </div>
    </div>
  );
});

ResultLevelCardSectionSparkLineChart.propTypes = {
  store: PropTypes.object.isRequired,
  months: PropTypes.array.isRequired,
  ratesHistoryData: PropTypes.array.isRequired,
  ratesMissingData: PropTypes.array.isRequired,
  changesHistoryData: PropTypes.array.isRequired,
  changesMissingData: PropTypes.array.isRequired,
  seriesColor: PropTypes.string.isRequired,
  positiveColor: PropTypes.string.isRequired,
  negativeColor: PropTypes.string.isRequired,
  currencySymbol: PropTypes.string,
};

export default ResultLevelCardSectionSparkLineChart;
