// @flow

import R from "ramda";
import { extendObservable, action, runInAction, computed, observable } from "mobx";
import axios from "axios";

import JobLibraryTitle, { LibraryTitleLatestSearch } from "../../models/JobLibraryTitle";
import CurrentUser from "../../models/User";
import NetworkState from "../../models/NetworkState";
import PaginationState from "../../models/PaginationState";
import FilterObject, { FILTER_COLUMN } from "../../models/Filter";
import Sort, { SORT_DIRECTION } from "../../models/Sort";
import ModalState from "../../models/ModalState";
import {
  consolidateAppliedFilters,
  consolidateAppliedSorts,
  createTasteGraphQLWrapper,
} from "./SupportFunctions";
import type { PageQuery, PaginationInfo } from "../../models/PaginationState";
import type { FilterColumn } from "../../models/Filter";
import type { GraphQLQuery } from "../../models/GraphQL";
import {
  ClientJobTitleClientTitleFilter,
  // ClientJobTitleOnlyStatusFilter,
} from "../../models/FilterState";
import SortState from "../../models/SortState";
import { CLIENT_JOB_TITLE_SORT_FIELD } from "../../constants/clientJobTitle";
import RawJobTitle from "../../models/RawJobTitle";
import type MobXStore from "./MobXStore";
import type { FetchAPI, FetchGraphQL } from "../../App";
import SessionInfo from "../../models/SessionInfo";
import JobLibrary from "../../models/JobLibrary";
import CCCCountry from "../../models/CCCCountry";

export type EnumItem = {
  value: number,
  label: string,
};

type Options = {
  [string]: EnumItem,
};

export type CountryFilters = {
  exclude?: Array<number>,
  only?: Array<number>,
};

export const RateOptions: Options = {
  PAY_RATE: { value: 0, label: "Pay Rate" },
  BILL_RATE: { value: 1, label: "Bill Rate" },
};

export const AggregationOptions: Options = {
  HOURLY: { value: 0, label: "Hourly" },
  DAILY: { value: 1, label: "Daily" },
  WEEKLY: { value: 2, label: "Weekly" },
  MONTHLY: { value: 3, label: "Monthly" },
};

export const DisplayOption = {
  RATES: RateOptions,
  AGGREGATION: AggregationOptions,
};

function queryAllClientJobTitles(
  queryParams,
  queryArgs,
  queryFiltersCriteria,
  querySortCriteria,
  querySelectedCriteria = null
) {
  return `
      query getClientJobTitles(${queryParams}) {
        clientJobTitles(${queryArgs}, filters: { ${queryFiltersCriteria} }, order: [${querySortCriteria}]) {
          totalCount
          pageInfo {
            startCursor
            endCursor
          }
          edges {
            node {
              id
              databaseId
              clientRawJobTitle {
                databaseId
                title
              }
              mappedRawJobTitle {
                databaseId
                title
              }
              isCertified
              created
              totalCertifiedCountries
              totalSearchableCountries
              ${querySelectedCriteria ? `shouldBeSelected(${querySelectedCriteria})` : ""}
            }
          }
        }

        allTitles: clientJobTitles(first: 0) {
          totalCount
        }
      }
    `;
}

const latestRatesFragment = `
      latestSearches(isV7: true) {
        titleId
        searchId
        created
        rateType
        industry
        location
        countryCode
        currencySymbol
        isDraft
        ratecardId
        rates {
          levelId
          payRateMin
          payRateMax
          billRateMin
          billRateMax
        }
      }
`;

function queryLibraryClientJobTitles(
  queryParams,
  queryArgs,
  queryFiltersCriteria,
  querySortCriteria,
  latestRates
) {
  return `
      query getLibraryClientJobTitles($libraryId: ID!, ${queryParams}) {
        clientLibrary(databaseId: $libraryId) {
          clientJobTitles(${queryArgs}, filters: { ${queryFiltersCriteria} }, order: [${querySortCriteria}]) {
            ${latestRates ? latestRatesFragment : ""}
            totalCount
            pageInfo {
              startCursor
              endCursor
            }
            edges {
              node {
                id
                databaseId
                clientRawJobTitle {
                  databaseId
                  title
                }
                mappedRawJobTitle {
                  databaseId
                  title
                }
                isCertified
                created
                totalCertifiedCountries
                totalSearchableCountries
              }
            }
          }

          allTitles: clientJobTitles(first: 0) {
            totalCount
          }

          isMainLibrary
          totalTitleBySearchableCountries
        }
      }
    `;
}

const countryLibraryLatestRatesFragment = `
      latestSearches(isV7: true, cccCountryId: $countryId) {
        titleId
        searchId
        created
        rateType
        industry
        location
        countryCode
        currencySymbol
        isDraft
        ratecardId
        rates {
          levelId
          payRateMin
          payRateMax
          billRateMin
          billRateMax
        }
      }
`;

function queryCountryClientJobTitles(
  queryParams,
  queryArgs,
  queryFiltersCriteria,
  querySortCriteria,
  latestRates
) {
  return `
      query getCountryClientJobTitles($countryId: ID!, ${queryParams}) {
        clientJobTitles(${queryArgs}, filters: {
          countryId: $countryId
          ${queryFiltersCriteria}
        }, order: [${querySortCriteria}]) {
          ${latestRates ? countryLibraryLatestRatesFragment : ""}
          totalCount
          pageInfo {
            startCursor
            endCursor
          }
          edges {
            node {
              id
              databaseId
              clientRawJobTitle {
                databaseId
                title
              }
              mappedRawJobTitle {
                databaseId
                title
              }
              isCertified
              created
              totalCertifiedCountries
              totalSearchableCountries
            }
          }
        }
      }
    `;
}

export class JobLibraryTitleListComponentStore {
  fetchTasteGraphQL: FetchGraphQL;
  fetchGraphQL: FetchGraphQL;
  fetchAPI: FetchAPI;
  mobXStore: MobXStore;
  network: NetworkState;
  pagination: PaginationState;
  router: ?Object;

  sessionInfo: ?SessionInfo;
  isMainLibrary: boolean;

  library: ?JobLibrary;
  libraryId: ?number;
  countryId: ?number;
  countryTitle: string;
  countryCode: string;

  clientJobTitles: JobLibraryTitle[];
  clientJobTitlesViewState: Object;
  clientJobTitlesView: JobLibraryTitle[];

  currentUser: CurrentUser;
  isEditing: ?boolean;
  allOnPageSelected: boolean;
  allSelected: boolean;
  allowMultipleItemSelection: boolean;
  allowExpand: boolean;
  selectedCount: number;
  totalCount: number;
  resultsForText: string;
  selectTitlesByLibraryId: ?string;

  defaultToLibraryFilter: boolean;
  clientJobTitleTitleFilter: ClientJobTitleClientTitleFilter;
  applyFilterFromLibraryStore: () => void;
  // clientJobTitleOnlyStatusFilter: ClientJobTitleOnlyStatusFilter;
  sortState: SortState;
  onSortByField: (?string, ?boolean) => void;

  defaultFilters: { [key: FilterColumn]: FilterObject };
  appliedFilters: { [key: FilterColumn]: FilterObject };
  appliedSorts: { [key: FilterColumn]: Sort };
  appliedSortsOrder: Array<FilterColumn>;
  isFiltered: boolean;

  getClientJobTitles: (PageQuery) => Promise<PaginationInfo>;
  applyDefaultFilter: () => void;
  handleStartEdit: () => void;
  handleStopEdit: () => void;
  toggleSelectAllPage: (Object) => void;
  selectAllPage: (Event) => void;
  deselectAllPage: (Event) => void;
  toggleAllItems: () => void;
  clearAllSelections: () => void;
  getSelectedClientJobTitles: () => Array<string>;
  getFirstSelectedClientJobTitle: () => Object;

  getFilterCriteriaQuery: (column: FilterColumn) => GraphQLQuery;
  processFilterCriteria: (column: FilterColumn, Object) => Array<Object>;
  applyFilter: (FilterColumn, FilterObject) => void;
  removeFilter: (column: FilterColumn) => void;
  applySort: (FilterColumn, Sort) => void;
  removeSort: (column: FilterColumn) => void;
  filterClientJobTitles: () => void;
  clearFilters: (?boolean) => void;
  applyDefaultSort: () => void;
  hardRefresh: (?boolean) => void;
  filterBySelectedTitles: () => void;
  removeFilterBySelectedTitles: (fetch?: boolean) => void;
  isFilteredBySelectedTitles: boolean;

  viewRateCardsForTitleId: (RawJobTitle, Object) => void;

  storeCountriesNetwork: NetworkState;
  storeCountries: Array<CCCCountry>;
  selectedStoreCountry: ?CCCCountry;
  storeCountrySearch: string;
  onStoreCountrySearchChange: (string) => void;
  toggleStoreCountrySelected: (CCCCountry) => void;
  selectedStoreCountriesCount: number;
  totalStoreCountries: number;
  getStoreCountries: (filters: ?CountryFilters) => Promise<Array<CCCCountry>>;
  getTitleCountryIds: (mappingId: number) => Promise<Array<number>>;
  getLibraryCountryIds: (libraryId: number) => Promise<Array<number>>;

  addCountryModal: ModalState;
  addCountriesToCart: () => Promise<boolean>;
  addCountriesForTitle: (JobLibraryTitle, Object) => void;
  addCountriesForLibrary: (number, Object) => void;
  confirmAddCountries: () => void;
  addCountriesError: string;
  addCountriesMapping: ?JobLibraryTitle;
  addCountriesLibraryId: ?number;

  removeCountryModal: ModalState;
  removeCountriesFromLibrary: () => Promise<boolean>;
  removeCountriesForTitle: (JobLibraryTitle, Object) => void;
  removeCountriesForLibrary: (number, Object) => void;
  confirmRemoveCountries: () => void;
  removeCountriesError: string;
  removeCountriesMapping: ?JobLibraryTitle;
  removeCountriesLibraryId: ?number;

  displayOptionsModal: ModalState;
  selectedDisplayRateOption: EnumItem;
  handleDisplayRateOptionChange: (EnumItem) => void;
  selectedDisplayAggregationOption: EnumItem;
  handleDisplayAggregationOptionChange: (EnumItem) => void;
  appliedMultiplier: number;
  dailyMultiplier: string; // must be parsable as float
  weeklyMultiplier: string;
  monthlyMultiplier: string;
  dailyMultiplierError: string;
  weeklyMultiplierError: string;
  monthlyMultiplierError: string;
  handleDailyMultiplierChange: (SyntheticInputEvent<HTMLElement>) => void;
  handleWeeklyMultiplierChange: (SyntheticInputEvent<HTMLElement>) => void;
  handleMonthlyMultiplierChange: (SyntheticInputEvent<HTMLElement>) => void;
  getMultiplier: () => number;
  applyDisplayOptions: () => void;
  resetDisplayOptions: () => void;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI, mobXStore: MobXStore) {
    this.fetchTasteGraphQL = createTasteGraphQLWrapper(fetchAPI);
    this.fetchGraphQL = fetchGraphQL;
    this.fetchAPI = fetchAPI;
    this.mobXStore = mobXStore;
    this.router = null;

    // NOTE: Bound early to pass into pagination & filter state
    this.getClientJobTitles = action(this.getClientJobTitles.bind(this));

    this.getFilterCriteriaQuery = action(this.getFilterCriteriaQuery.bind(this));
    this.processFilterCriteria = action(this.processFilterCriteria.bind(this));
    this.applyFilter = action(this.applyFilter.bind(this));
    this.applySort = action(this.applySort.bind(this));
    this.removeFilter = action(this.removeFilter.bind(this));
    this.removeSort = action(this.removeSort.bind(this));
    this.onSortByField = action(this.onSortByField.bind(this));

    extendObservable(this, {
      network: new NetworkState(),
      pagination: new PaginationState(this.getClientJobTitles),
      sessionInfo: null,
      isMainLibrary: false,
      library: null,
      libraryId: null,
      countryId: null,
      countryTitle: "",
      countryCode: "",
      clientJobTitles: [],
      clientJobTitlesViewState: observable.map({}),
      clientJobTitlesView: computed(() => {
        return this.clientJobTitles.map((clientJobTitle) => {
          if (this.clientJobTitlesViewState.has(clientJobTitle.id)) {
            clientJobTitle.viewState = this.clientJobTitlesViewState.get(
              clientJobTitle.id
            );

            return clientJobTitle;
          }

          return clientJobTitle;
        });
      }),
      isEditing: null, // we start with null so some view elements be hidden initially
      isFiltered: false,
      allSelected: false,
      allowMultipleItemSelection: true,
      allowExpand: true,
      totalCount: 0,
      resultsForText: "",
      selectTitlesByLibraryId: null,
      selectedCount: computed(() => {
        // const selectedValues = this.clientJobTitlesView.map(
        //   clientJobTitle => clientJobTitle.viewState.selected
        // );

        if (this.allSelected) {
          return this.pagination.totalCount;
        }

        let count = 0;

        this.clientJobTitlesViewState.forEach((viewState) => {
          if (viewState.selected) {
            count += 1;
          }
        });

        return count;
      }),
      allOnPageSelected: computed(() => {
        const allTrue = R.all(R.equals(true));
        const selectedValues = this.clientJobTitlesView.map(
          (clientJobTitle) => clientJobTitle.viewState.selected
        );

        if (selectedValues.length === 0) {
          return false;
        }

        return allTrue(selectedValues);
      }),
      isFilteredBySelectedTitles: false,
      sortState: new SortState(
        CLIENT_JOB_TITLE_SORT_FIELD.CLIENT_TITLE,
        SORT_DIRECTION.ASC
      ),
      defaultFilters: {},
      appliedFilters: {},
      appliedSorts: {},
      appliedSortsOrder: observable.shallow([]),
      defaultToLibraryFilter: false,
      clientJobTitleTitleFilter: new ClientJobTitleClientTitleFilter(
        this,
        FILTER_COLUMN.CLIENT_JOB_TITLE_CLIENT_TITLE,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      // clientJobTitleOnlyStatusFilter: new ClientJobTitleOnlyStatusFilter(
      //   this,
      //   FILTER_COLUMN.CLIENT_JOB_TITLE_REQUEST_ONLY_STATUS,
      //   this.getFilterCriteriaQuery,
      //   this.processFilterCriteria,
      //   this.applyFilter,
      //   this.removeFilter
      // ),
      displayOptionsModal: new ModalState(),
      selectedDisplayRateOption: DisplayOption.RATES.PAY_RATE,
      selectedDisplayAggregationOption: DisplayOption.AGGREGATION.HOURLY,
      appliedMultiplier: 1,
      dailyMultiplier: "8",
      weeklyMultiplier: "40",
      monthlyMultiplier: "160",
      dailyMultiplierError: "",
      weeklyMultiplierError: "",
      monthlyMultiplierError: "",
      storeCountries: [],
      selectedStoreCountry: null,
      storeCountrySearch: "",
      storeCountriesNetwork: new NetworkState(),
      selectedStoreCountriesCount: computed(() => {
        return this.storeCountries.filter((c) => c.selected).length;
      }),
      totalStoreCountries: computed(() => {
        return this.storeCountries.length;
      }),
      addCountryModal: new ModalState(),
      addCountriesError: "",
      addCountriesMapping: null,
      addCountriesLibraryId: null,
      removeCountryModal: new ModalState(),
      removeCountriesError: "",
      removeCountriesMapping: null,
      removeCountriesLibraryId: null,
    });

    this.pagination.itemsPerPage = 10;
    this.currentUser = null;

    this.handleStartEdit = action(this.handleStartEdit.bind(this));
    this.handleStopEdit = action(this.handleStopEdit.bind(this));
    this.toggleSelectAllPage = action(this.toggleSelectAllPage.bind(this));
    this.selectAllPage = action(this.selectAllPage.bind(this));
    this.deselectAllPage = action(this.deselectAllPage.bind(this));
    this.toggleAllItems = action(this.toggleAllItems.bind(this));
    this.clearAllSelections = action(this.clearAllSelections.bind(this));
    this.getSelectedClientJobTitles = action(this.getSelectedClientJobTitles.bind(this));
    this.getFirstSelectedClientJobTitle = action(
      this.getFirstSelectedClientJobTitle.bind(this)
    );

    this.filterClientJobTitles = action(this.filterClientJobTitles.bind(this));
    this.clearFilters = action(this.clearFilters.bind(this));
    this.applyDefaultSort = action(this.applyDefaultSort.bind(this));
    this.applyDefaultFilter = action(this.applyDefaultFilter.bind(this));
    this.hardRefresh = action(this.hardRefresh.bind(this));
    this.applyFilterFromLibraryStore = action(
      this.applyFilterFromLibraryStore.bind(this)
    );
    this.filterBySelectedTitles = action(this.filterBySelectedTitles.bind(this));
    this.removeFilterBySelectedTitles = action(
      this.removeFilterBySelectedTitles.bind(this)
    );
    this.viewRateCardsForTitleId = action(this.viewRateCardsForTitleId.bind(this));
    this.handleDisplayRateOptionChange = action(
      this.handleDisplayRateOptionChange.bind(this)
    );
    this.handleDisplayAggregationOptionChange = action(
      this.handleDisplayAggregationOptionChange.bind(this)
    );
    this.handleDailyMultiplierChange = action(
      this.handleDailyMultiplierChange.bind(this)
    );
    this.handleWeeklyMultiplierChange = action(
      this.handleWeeklyMultiplierChange.bind(this)
    );
    this.handleMonthlyMultiplierChange = action(
      this.handleMonthlyMultiplierChange.bind(this)
    );
    this.applyDisplayOptions = action(this.applyDisplayOptions.bind(this));
    this.resetDisplayOptions = action(this.resetDisplayOptions.bind(this));
    this.getMultiplier = action(this.getMultiplier.bind(this));
    this.onStoreCountrySearchChange = action(this.onStoreCountrySearchChange.bind(this));
    this.toggleStoreCountrySelected = action(this.toggleStoreCountrySelected.bind(this));
    this.getStoreCountries = action(this.getStoreCountries.bind(this));
    this.getTitleCountryIds = action(this.getTitleCountryIds.bind(this));
    this.getLibraryCountryIds = action(this.getLibraryCountryIds.bind(this));

    this.addCountriesForTitle = action(this.addCountriesForTitle.bind(this));
    this.addCountriesForLibrary = action(this.addCountriesForLibrary.bind(this));
    this.addCountriesToCart = action(this.addCountriesToCart.bind(this));
    this.confirmAddCountries = action(this.confirmAddCountries.bind(this));

    this.removeCountriesForTitle = action(this.removeCountriesForTitle.bind(this));
    this.removeCountriesForLibrary = action(this.removeCountriesForLibrary.bind(this));
    this.removeCountriesFromLibrary = action(this.removeCountriesFromLibrary.bind(this));
    this.confirmRemoveCountries = action(this.confirmRemoveCountries.bind(this));

    this.applyDefaultSort();
    this.applyDefaultFilter();
  }

  toggleStoreCountrySelected(country: CCCCountry) {
    country.selected = !country.selected;
    this.addCountriesError = "";
  }

  onStoreCountrySearchChange(value: string) {
    this.storeCountrySearch = value;
    this.addCountriesError = "";
    // TODO: filter countries
  }

  handleDisplayRateOptionChange(option: EnumItem) {
    this.selectedDisplayRateOption = option;
  }

  handleDisplayAggregationOptionChange(option: EnumItem) {
    this.selectedDisplayAggregationOption = option;
    this.appliedMultiplier = this.getMultiplier();
  }

  handleDailyMultiplierChange(e: SyntheticInputEvent<HTMLElement>) {
    this.dailyMultiplier = e.target.value;
    if (!parseFloat(this.dailyMultiplier)) {
      this.dailyMultiplierError = "Please enter a valid number";
    } else {
      this.dailyMultiplierError = "";
    }
  }

  getMultiplier(): number {
    if (this.selectedDisplayAggregationOption.value === AggregationOptions.DAILY.value) {
      return parseFloat(this.dailyMultiplier);
    } else if (
      this.selectedDisplayAggregationOption.value === AggregationOptions.WEEKLY.value
    ) {
      return parseFloat(this.weeklyMultiplier);
    } else if (
      this.selectedDisplayAggregationOption.value === AggregationOptions.MONTHLY.value
    ) {
      return parseFloat(this.monthlyMultiplier);
    }

    return 1;
  }

  applyDisplayOptions() {
    if (
      this.dailyMultiplierError ||
      this.weeklyMultiplierError ||
      this.monthlyMultiplierError
    )
      return;
    this.appliedMultiplier = this.getMultiplier();
    this.displayOptionsModal.hideModal();
  }

  resetDisplayOptions() {
    this.selectedDisplayRateOption = RateOptions.PAY_RATE;
    this.selectedDisplayAggregationOption = AggregationOptions.HOURLY;
    this.dailyMultiplier = "8";
    this.weeklyMultiplier = "40";
    this.monthlyMultiplier = "160";
    this.dailyMultiplierError = "";
    this.weeklyMultiplierError = "";
    this.monthlyMultiplierError = "";
    this.appliedMultiplier = this.getMultiplier();
    this.displayOptionsModal.hideModal();
  }

  handleWeeklyMultiplierChange(e: SyntheticInputEvent<HTMLElement>) {
    this.weeklyMultiplier = e.target.value;
    if (!parseFloat(this.weeklyMultiplier)) {
      this.weeklyMultiplierError = "Please enter a valid number";
    } else {
      this.weeklyMultiplierError = "";
    }
  }

  handleMonthlyMultiplierChange(e: SyntheticInputEvent<HTMLElement>) {
    this.monthlyMultiplier = e.target.value;
    if (!parseFloat(this.monthlyMultiplier)) {
      this.monthlyMultiplierError = "Please enter a valid number";
    } else {
      this.monthlyMultiplierError = "";
    }
  }

  viewRateCardsForTitleId(title: RawJobTitle, router: Object) {
    this.mobXStore.rateCardListStore.libraryTitleId = title.id;
    this.mobXStore.rateCardListStore.libraryTitleName = title.title;
    this.mobXStore.rateCardListStore.libraryId =
      this.mobXStore.jobLibraryTitleListStore.libraryId;
    router.push("/ratecards/");
  }

  addCountriesForTitle(mapping: JobLibraryTitle, router: Object) {
    this.addCountriesLibraryId = null;
    this.addCountriesMapping = mapping;
    this.addCountriesError = "";
    this.router = router;
    this.addCountryModal.showModal();
    this.getTitleCountryIds(mapping.id).then((countryIds) =>
      this.getStoreCountries({ exclude: countryIds })
    );
  }

  addCountriesForLibrary(libraryId: number, router: Object) {
    this.addCountriesMapping = null;
    this.addCountriesLibraryId = libraryId;
    this.addCountriesError = "";
    this.router = router;
    this.addCountryModal.showModal();
    this.getStoreCountries();
  }

  confirmAddCountries() {
    this.addCountriesError = "";
    if (!this.addCountriesMapping && !this.addCountriesLibraryId) {
      this.addCountriesError = "No title or library selected.";
      return;
    }

    this.addCountriesToCart().then((result) => {
      if (result) {
        const router = this.router;
        if (!router) {
          this.addCountriesError = "Unable to show cart.";
          return;
        }
        this.addCountryModal.hideModal();
        this.addCountriesError = "";
        this.addCountriesLibraryId = null;
        this.addCountriesMapping = null;
        this.storeCountriesNetwork.loading = true;
        this.mobXStore.jobsLibraryWizardStore.startPage = 0;
        this.mobXStore.jobsLibraryWizardStore.startSubPage = 3;
        this.mobXStore.jobsLibraryWizardStore
          .loadSubscriptionData()
          .then((subscription) => {
            this.storeCountriesNetwork.loading = false;
            if (!subscription) {
              this.addCountriesError = "Unable load subscription data.";
              return;
            }
            router.push("/embed/sales/build-jobs-library");
          })
          .catch((error) => {
            this.storeCountriesNetwork.loading = false;
            this.addCountriesError = "Unable load subscription data.";
          });
      }
    });
  }

  removeCountriesForTitle(mapping: JobLibraryTitle, router: Object) {
    this.router = router;
    this.removeCountriesLibraryId = null;
    this.removeCountriesMapping = mapping;
    this.removeCountriesError = "";
    this.removeCountryModal.showModal();
    this.getTitleCountryIds(mapping.id).then((countryIds) =>
      this.getStoreCountries({ only: countryIds })
    );
  }

  removeCountriesForLibrary(libraryId: number, router: Object) {
    this.router = router;
    this.removeCountriesMapping = null;
    this.removeCountriesLibraryId = libraryId;
    this.removeCountriesError = "";
    this.removeCountryModal.showModal();
    this.getLibraryCountryIds(libraryId).then((countryIds) =>
      this.getStoreCountries({ only: countryIds })
    );
  }

  confirmRemoveCountries() {
    this.removeCountriesError = "";
    if (!this.removeCountriesMapping && !this.removeCountriesLibraryId) {
      this.removeCountriesError = "No title or library selected.";
      return;
    }

    this.removeCountriesFromLibrary().then((result) => {
      if (result) {
        this.removeCountryModal.hideModal();
        this.removeCountriesError = "";
        this.removeCountriesLibraryId = null;
        this.removeCountriesMapping = null;
        this.hardRefresh();
        if (this.router) {
          this.mobXStore.clientJobLibraryListStore.router = this.router;
          this.mobXStore.clientJobLibraryListStore.getClientLibraries();
          this.mobXStore.clientJobLibraryListStore.pagination.handleFirstPage();
        }
      }
    });
  }

  hardRefresh(clearSort?: boolean = false) {
    // NOTE: order is important
    this.clearAllSelections();
    // this.clientJobTitles = [];
    this.clientJobTitlesViewState = observable.map({});
    this.pagination = new PaginationState(this.getClientJobTitles);
    this.clearFilters(clearSort);
  }

  applyDefaultSort() {
    this.sortState = new SortState(
      CLIENT_JOB_TITLE_SORT_FIELD.CLIENT_TITLE,
      SORT_DIRECTION.ASC
    );
    const sortField = this.sortState.field;
    const sortDirection = this.sortState.direction || SORT_DIRECTION.ASC;
    const sort = new Sort("{field: " + sortField + ", direction: " + sortDirection + "}");
    this.applySort(this.sortState.field, sort);
  }

  applyDefaultFilter() {
    // let initialSelectedStatus = [0, 1];  // PROCESSING, PENDING
    // this.clientJobTitleOnlyStatusFilter.filter = this.clientJobTitleOnlyStatusFilter.createFilterObject(initialSelectedStatus);
    // this.applyFilter(this.clientJobTitleOnlyStatusFilter.column, this.clientJobTitleOnlyStatusFilter.filter);
  }

  filterBySelectedTitles() {
    if (this.selectTitlesByLibraryId) {
      // library titles + to add titles - to remove titles
      const editLibraryStore = this.mobXStore.jobLibraryEditCustomStore;

      const libraryFilter = new FilterObject(
        "$inLibraryId: ID!, $inTitleIds: [ID]!",
        "inLibraryOrIds: {libraryId: $inLibraryId, titleIds: $inTitleIds}",
        {
          inLibraryId: this.selectTitlesByLibraryId,
          inTitleIds: editLibraryStore.titlesToAdd,
        }
      );
      this.applyFilter(FILTER_COLUMN.CLIENT_JOB_TITLE_IN_ANY_LIBRARY, libraryFilter);

      const toRemoveTitlesFilter = new FilterObject(
        "$excludeIds: [ID]!",
        "exclude: $excludeIds",
        {
          excludeIds: editLibraryStore.titlesToRemove,
        }
      );
      this.applyFilter(FILTER_COLUMN.CLIENT_JOB_TITLE_SELECTED_IDS, toRemoveTitlesFilter);
    } else {
      const selectedIds = this.getSelectedClientJobTitles();
      const filterColumn = FILTER_COLUMN.CLIENT_JOB_TITLE_SELECTED_IDS;
      const filterObject = new FilterObject("$selectedIds: [ID]!", "only: $selectedIds", {
        selectedIds: selectedIds,
      });
      this.applyFilter(filterColumn, filterObject);
    }
    this.pagination.goFetch();
  }

  removeFilterBySelectedTitles(fetch?: boolean = true) {
    this.removeFilter(FILTER_COLUMN.CLIENT_JOB_TITLE_SELECTED_IDS);
    this.removeFilter(FILTER_COLUMN.CLIENT_JOB_TITLE_IN_ANY_LIBRARY);

    const clientJobTitles = this.clientJobTitlesViewState;
    clientJobTitles.forEach((value, _key) => {
      if (value.selected) {
        value.selected = false;
      }
    });

    if (fetch) this.pagination.goFetch();
  }

  handleStartEdit() {
    this.isEditing = true;
    this.allowExpand = false;
    this.clientJobTitlesView.forEach((clientJobTitle) => clientJobTitle.toggleEdit());
  }

  handleStopEdit() {
    this.isEditing = false;
    this.allSelected = false;
    this.allowExpand = true;
    this.clientJobTitlesViewState.forEach((viewState) => {
      viewState.selected = false;
      viewState.editing = false;
    });
  }

  toggleSelectAllPage(e: Object) {
    if (!this.allowMultipleItemSelection) return;

    const setValue = !this.allOnPageSelected;

    this.clientJobTitlesView.forEach((clientJobTitle) => {
      clientJobTitle.toggleSelected(e, null, null, setValue);
    });

    // When All items selected flag is up, clear selection
    if (setValue === false && this.allSelected) this.allSelected = false;
  }

  selectAllPage(e: Event) {
    this.clientJobTitlesView.forEach((clientJobTitle) => {
      clientJobTitle.toggleSelected(e, null, null, true);
    });
  }

  deselectAllPage(e: Event) {
    this.clientJobTitlesView.forEach((clientJobTitle) => {
      clientJobTitle.toggleSelected(e, null, null, false);
    });

    this.allSelected = false;
  }

  toggleAllItems() {
    if (!this.allowMultipleItemSelection) return;

    this.allSelected = !this.allSelected;

    if (this.allSelected === false) {
      this.clientJobTitlesViewState.forEach((value) => {
        value.selected = false;
      });
    }
  }

  clearAllSelections() {
    this.allSelected = false;
    this.clientJobTitlesViewState.forEach((value) => {
      value.selected = false;
    });
    this.removeFilterBySelectedTitles();
  }

  getFilterCriteriaQuery(column: FilterColumn): GraphQLQuery {
    switch (column) {
      // case FILTER_COLUMN.CLIENT_JOB_TITLE_REQUEST_ONLY_STATUS:
      //   return {
      //     query: statusCriteriaQuery,
      //     variables: {}
      //   };

      default:
        return null;
    }
  }

  processFilterCriteria(column: FilterColumn, payload: Object): ?Array<Object> {
    switch (column) {
      // case FILTER_COLUMN.CLIENT_JOB_TITLE_REQUEST_ONLY_STATUS:
      //   const statusChoices: [StatusCriterion] = payload.data.clientJobTitlesFilterCriteria.statusChoices;
      //   let processedStatusChoices = observable.map({});
      //   statusChoices.forEach(status => {
      //     processedStatusChoices.set(String(status.value), {
      //       id: String(status.value),
      //       ...status
      //     });
      //   });
      //
      //   return processedStatusChoices;

      default:
        return null;
    }
  }

  applyFilter(column: FilterColumn, filter: FilterObject) {
    this.appliedFilters[column] = filter;
    this.isFiltered = true;

    if (column === FILTER_COLUMN.CLIENT_JOB_TITLE_CLIENT_TITLE) {
      this.defaultToLibraryFilter = false;
    }
  }

  removeFilter(column: FilterColumn) {
    delete this.appliedFilters[column];

    let entries = Object.entries(this.appliedFilters);
    if (!entries.length) this.isFiltered = false;
  }

  applySort(column: FilterColumn, sort: Sort) {
    this.appliedSorts[column] = sort;

    const index = this.appliedSortsOrder.indexOf(column);
    if (index === -1) this.appliedSortsOrder.push(column);
  }

  removeSort(column: FilterColumn) {
    delete this.appliedSorts[column];

    const index = this.appliedSortsOrder.indexOf(column);
    if (index > -1) this.appliedSortsOrder.splice(index, 1);
  }

  clearFilters(clearSort: boolean = true) {
    this.clientJobTitleTitleFilter = new ClientJobTitleClientTitleFilter(
      this,
      FILTER_COLUMN.CLIENT_JOB_TITLE_CLIENT_TITLE,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );

    // this.clientJobTitleOnlyStatusFilter = new ClientJobTitleOnlyStatusFilter(
    //   this,
    //   FILTER_COLUMN.CLIENT_JOB_TITLE_REQUEST_ONLY_STATUS,
    //   this.getFilterCriteriaQuery,
    //   this.processFilterCriteria,
    //   this.applyFilter,
    //   this.removeFilter
    // );

    this.appliedFilters = observable({});
    this.isFiltered = false;

    if (clearSort) {
      this.appliedSorts = observable({});
      this.appliedSortsOrder.length = 0;
    }

    return this.pagination.goFetch();
  }

  filterClientJobTitles() {
    return this.pagination.goFetch();
  }

  getSelectedClientJobTitles(): Array<string> {
    const clientJobTitles = this.clientJobTitlesViewState;

    let selectedClientJobTitles = [];

    clientJobTitles.forEach((value, key) => {
      if (value.selected) {
        selectedClientJobTitles.push(key);
      }
    });

    return selectedClientJobTitles;
  }

  getFirstSelectedClientJobTitle() {
    for (let j = 0; j < this.clientJobTitlesView.length; j++) {
      if (this.clientJobTitles[j].viewState.selected) return this.clientJobTitles[j];
    }

    return null;
  }

  applyLibraryViewFilterByTitleIfNeeded() {
    if (!this.defaultToLibraryFilter) return;

    const libraryClientTitleFilter =
      this.mobXStore.jobLibraryListStore.clientLibraryClientRawJobTitleFilter;
    const filterInLibraries = libraryClientTitleFilter.textToLookFor;
    const clientTitleFilter = this.clientJobTitleTitleFilter;

    clientTitleFilter.textToLookFor = filterInLibraries;
    clientTitleFilter.filter = clientTitleFilter.buildQueryFilter();
    if (clientTitleFilter.filter)
      clientTitleFilter.applyFilter(clientTitleFilter.column, clientTitleFilter.filter);
    else clientTitleFilter.removeFilter(clientTitleFilter.column);

    this.defaultToLibraryFilter = false;
  }

  applyFilterFromLibraryStore() {
    this.defaultToLibraryFilter = true;
  }

  onSortByField(field?: string, caseSensitive: boolean = false) {
    // reset sort if no field is passed
    if (!field) {
      // remove sorts
      this.appliedSorts = observable({});
      this.appliedSortsOrder.length = 0;

      // remove sort state
      this.sortState = new SortState(
        CLIENT_JOB_TITLE_SORT_FIELD.CLIENT_TITLE,
        SORT_DIRECTION.ASC
      );
    } else {
      // remove previous sort
      if (this.sortState) {
        // remove sorts
        this.appliedSorts = observable({});
        this.appliedSortsOrder.length = 0;
      }

      // cycle field sort: asc -> desc -> default

      // new sort, sort asc
      if (!this.sortState || this.sortState.field !== field) {
        // create sort state (default SORT_DIRECTION.ASC)
        this.sortState = new SortState(field, SORT_DIRECTION.ASC);
      } else if (
        !this.sortState.direction ||
        this.sortState.direction === SORT_DIRECTION.ASC
      ) {
        // sort desc
        this.sortState.setSort(SORT_DIRECTION.DESC);
      } else if (this.sortState.direction === SORT_DIRECTION.DESC) {
        // reset sort
        // remove sort state
        this.sortState = new SortState(
          CLIENT_JOB_TITLE_SORT_FIELD.CLIENT_TITLE,
          SORT_DIRECTION.ASC
        );
      }
    }

    // apply sort
    const sortDirection = this.sortState.direction || SORT_DIRECTION.ASC;
    let sortFields = [this.sortState.field];

    if (this.sortState.field === CLIENT_JOB_TITLE_SORT_FIELD.COUNTRIES) {
      sortFields = [
        CLIENT_JOB_TITLE_SORT_FIELD.CERTIFIED_COUNTRIES,
        CLIENT_JOB_TITLE_SORT_FIELD.NON_CERTIFIED_COUNTRIES,
        CLIENT_JOB_TITLE_SORT_FIELD.CLIENT_TITLE,
      ];
    }

    sortFields.forEach((sortFieldName) => {
      this.applySort(
        sortFieldName,
        this.createSort(sortFieldName, sortDirection, caseSensitive)
      );
    });

    // fetch
    this.pagination.goFetch();
  }

  createSort = (fieldName: string, sortDirection: string, caseSensitive: boolean) => {
    const sortSensitive = caseSensitive ? "true" : "false";
    return new Sort(
      "{field: " +
        fieldName +
        ", direction: " +
        sortDirection +
        ", caseSensitive: " +
        sortSensitive +
        "}"
    );
  };

  async getStoreCountries(filters: ?CountryFilters = {}): Promise<Array<CCCCountry>> {
    // TODO: Load these form taste
    const storeCountries: Array<CCCCountry> = []; //ALLOWED_COUNTRIES.map(item => new CCCCountry(item));

    const countries = storeCountries.filter((c: CCCCountry) => {
      const matchSearch = this.storeCountrySearch
        ? c.title && c.title.toLowerCase().includes(this.storeCountrySearch.toLowerCase())
        : true;
      const included =
        filters && filters.only ? filters.only.includes(c.databaseId) : true;
      const excluded =
        filters && filters.exclude ? filters.exclude.includes(c.databaseId) : false;
      return matchSearch && included && !excluded;
    });

    runInAction("getStoreCountries--success", () => {
      this.storeCountries = countries;
    });

    return countries;
  }

  async getTitleCountryIds(mappingId: number): Promise<Array<number>> {
    if (this.storeCountriesNetwork.loading) {
      // this.tasteApi.cancelCurrentRequest();
    }

    this.storeCountriesNetwork.loading = true;

    const query = `
    query getLibraryTitleCountries($mappingId: ID!) {
      clientJobTitle(databaseId: $mappingId) {
        certifiedCountries(order: [{field: COUNTRY}]) {
          edges {
            node {
              country {
                databaseId
              }
            }
          }
        }
        adhocCountries(order: [{field: COUNTRY}]) {
          edges {
            node {
              country {
                databaseId
              }
            }
          }
        }
      }
    }
    `;

    const variables = { mappingId };
    let result = null;
    try {
      result = await this.fetchTasteGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        // console.log('Request Canceled ClientJobTitleListStore');
        return e;
      }

      this.storeCountriesNetwork.handleError("Getting title countries", e);
      if (result !== null) {
        this.storeCountriesNetwork.logGraphQLError("Get title countries query", result);
      }

      // TODO: Display user friendly error message
      return e;
    }

    this.storeCountriesNetwork.loading = false;
    this.storeCountriesNetwork.error = null;
    if (this.storeCountriesNetwork.logGraphQLError("Get title countries query", result)) {
      // TODO: Display user friendly error message
      return [];
    }

    if (!result) return [];

    const certifedIds: number[] = result.data.clientJobTitle.certifiedCountries.edges.map(
      (edge) => edge.node.country.databaseId
    );
    const adhocIds: number[] = result.data.clientJobTitle.adhocCountries.edges.map(
      (edge) => edge.node.country.databaseId
    );

    return [...certifedIds, ...adhocIds];
  }

  async getLibraryCountryIds(libraryId: number): Promise<Array<number>> {
    if (this.storeCountriesNetwork.loading) {
      // this.tasteApi.cancelCurrentRequest();
    }

    this.storeCountriesNetwork.loading = true;

    const query = `
    query getLibrary($libraryId: ID!){
      clientLibrary(databaseId: $libraryId) {
        searchableCountries {
          databaseId
        }
      }
    }
    `;

    const variables = { libraryId };
    let result = null;
    try {
      result = await this.fetchTasteGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        // console.log('Request Canceled ClientJobTitleListStore');
        return e;
      }

      this.storeCountriesNetwork.handleError("Getting title countries", e);
      if (result !== null) {
        this.storeCountriesNetwork.logGraphQLError("Get title countries query", result);
      }

      // TODO: Display user friendly error message
      return e;
    }

    this.storeCountriesNetwork.loading = false;
    this.storeCountriesNetwork.error = null;
    if (this.storeCountriesNetwork.logGraphQLError("Get title countries query", result)) {
      // TODO: Display user friendly error message
      return [];
    }

    if (!result) return [];
    const data = result.data || {};
    const library = data.clientLibrary || {};
    const searchableCountries = library.searchableCountries || [];

    return searchableCountries.map((country) => country.databaseId);
  }

  async addCountriesToCart(): Promise<boolean> {
    if (this.storeCountriesNetwork.loading) {
      return false;
    }

    this.storeCountriesNetwork.loading = true;

    const mappingId = this.addCountriesMapping ? this.addCountriesMapping.id : null;
    const libraryId = this.addCountriesLibraryId ? this.addCountriesLibraryId : null;
    const countryIds = this.storeCountries
      .filter((c) => c.selected)
      .map((c) => c.databaseId);

    const query = `
    mutation addCountriesToCart($libraryId: Int, $mappingId: Int, $countryIds: [Int]!) {
      addCountriesToCart(libraryId: $libraryId, titleMappingId: $mappingId, countryIds: $countryIds) {
        success {
          message
        }
        errors {
          __typename
          ... on OperationError {
            message
          }
          ... on RequiredFieldEmptyError {
            message
          }
        }
        members {
          __typename
        }
      }
    }
    `;

    const variables = { mappingId, libraryId, countryIds };
    let result = null;
    try {
      result = await this.fetchTasteGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        // console.log('Request Canceled ClientJobTitleListStore');
        return false;
      }

      this.storeCountriesNetwork.handleError("Add countries to cart", e);
      if (result !== null) {
        this.storeCountriesNetwork.logGraphQLError("addCountriesToCart", result);
      }

      this.addCountriesError =
        "There was an error during the operation. We're working to solve this issue. Wait a " +
        "few minutes and try again. If the problem persists please contact us.";
      return false;
    }

    this.storeCountriesNetwork.loading = false;
    this.storeCountriesNetwork.error = null;
    const { data } = result || {};
    const { addCountriesToCart } = data || {};

    if (this.storeCountriesNetwork.logGraphQLError("addCountriesToCart", result)) {
      runInAction("addCountriesToCart--failure", () => {
        this.storeCountriesNetwork.loading = false;
        // const errors = addCountriesToCart.errors || [];
        // const operationError = errors.find(e => e.__typename === "OperationError");
        // const requiredFieldError = errors.find(e => e.__typename === "RequiredFieldEmptyError");

        // if (operationError) {
        //   this.addCountriesError = "There was an error during the operation. Operation could not be completed."
        // }
        this.addCountriesError =
          "There was an error during the operation. We're working to solve this issue. Wait a " +
          "few minutes and try again. If the problem persists please contact us.";
      });
      return false;
    }

    const members = addCountriesToCart.members || [];
    if (!members.length) {
      runInAction("addCountriesToCart--success", () => {
        this.storeCountriesNetwork.loading = false;
        this.addCountriesError =
          "Selected countries are already available. Nothing will be added.";
      });
      return false;
    }

    runInAction("addCountriesToCart--success", () => {
      this.storeCountriesNetwork.loading = false;
    });
    return true;
  }

  async removeCountriesFromLibrary(): Promise<boolean> {
    if (this.storeCountriesNetwork.loading) {
      return false;
    }

    this.storeCountriesNetwork.loading = true;

    const mappingId = this.removeCountriesMapping ? this.removeCountriesMapping.id : null;
    const libraryId = this.removeCountriesLibraryId
      ? this.removeCountriesLibraryId
      : null;
    const countryIds = this.storeCountries
      .filter((c) => c.selected)
      .map((c) => c.databaseId);

    const query = `
    mutation removeCountries($mappingId: Int, $libraryId: Int, $countryIds: [Int]!) {
      removeCountriesFromLibrary(input: {titleMappingId: $mappingId, libraryId: $libraryId, countryIds: $countryIds}) {
        success {
          message
        }
        errors {
          __typename
          ...on OperationError {
            message
          }
          ...on RequiredFieldEmptyError {
            message
          }
          ...on NotAllowedForCurrentUserError {
            message
          }
        }
      }
    }
    `;

    const variables = { mappingId, libraryId, countryIds };
    let result = null;
    try {
      result = await this.fetchTasteGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        // console.log('Request Canceled ClientJobTitleListStore');
        return false;
      }

      this.storeCountriesNetwork.handleError("Remove countries from library", e);
      if (result !== null) {
        this.storeCountriesNetwork.logGraphQLError("removeCountriesFromLibrary", result);
      }

      this.removeCountriesError =
        "There was an error during the operation. We're working to solve this issue. Wait a " +
        "few minutes and try again. If the problem persists please contact us.";
      return false;
    }

    this.storeCountriesNetwork.loading = false;
    this.storeCountriesNetwork.error = null;

    if (
      this.storeCountriesNetwork.logGraphQLError("removeCountriesFromLibrary", result)
    ) {
      runInAction("removeCountriesFromLibrary--failure", () => {
        this.storeCountriesNetwork.loading = false;
        // const errors = removeCountriesFromLibrary.errors || [];
        // const operationError = errors.find(e => e.__typename === "OperationError");
        // const requiredFieldError = errors.find(e => e.__typename === "RequiredFieldEmptyError");

        // if (operationError) {
        //   this.removeCountriesError = "There was an error during the operation. Operation could not be completed."
        // }
        this.removeCountriesError =
          "There was an error during the operation. We're working to solve this issue. Wait a " +
          "few minutes and try again. If the problem persists please contact us.";
      });
      return false;
    }

    runInAction("removeCountriesFromLibrary--success", () => {
      this.storeCountriesNetwork.loading = false;
    });
    return true;
  }

  async getClientJobTitles(pageQuery: PageQuery): Promise<PaginationInfo> {
    this.applyLibraryViewFilterByTitleIfNeeded();

    let params: string[] = pageQuery.params;
    let args = pageQuery.args;
    let variables = pageQuery.variables;
    let filtersCriteria: string[] = [];
    let selectedCriteria: string[] = [];
    let query = {};

    let sortCriteria: string[] = [];
    consolidateAppliedSorts(this.appliedSorts, sortCriteria);

    consolidateAppliedFilters(this.appliedFilters, params, filtersCriteria, variables);

    // NOTE: applied filters could override default filters, handle this if needed
    consolidateAppliedFilters(this.defaultFilters, params, filtersCriteria, variables);

    if (this.selectTitlesByLibraryId) {
      params.push("$selectForLibraryId: ID!");
      selectedCriteria.push("selectForLibraryId: $selectForLibraryId");
      Object.assign(variables, { selectForLibraryId: this.selectTitlesByLibraryId });
    }

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");
    const queryFiltersCriteria = filtersCriteria.join(", ");
    const querySortCriteria = sortCriteria.join(", ");
    const querySelectedCriteria = selectedCriteria.join(", ");

    if (this.libraryId) {
      variables.libraryId = this.libraryId;
      query.query = queryLibraryClientJobTitles(
        queryParams,
        queryArgs,
        queryFiltersCriteria,
        querySortCriteria,
        true
      );
    } else if (this.countryId) {
      variables.countryId = this.countryId;
      query.query = queryCountryClientJobTitles(
        queryParams,
        queryArgs,
        queryFiltersCriteria,
        querySortCriteria,
        true
      );
    } else {
      query.query = queryAllClientJobTitles(
        queryParams,
        queryArgs,
        queryFiltersCriteria,
        querySortCriteria,
        querySelectedCriteria
      );
    }

    query.variables = variables;

    if (this.network.loading === true) {
      // console.log('Canceling ClientJobTiles list request');
      // this.tasteApi.cancelTokenSource.cancel();
    }

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchTasteGraphQL(query.query, query.variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        // console.log('Request Canceled ClientJobTitleListStore');
        return e;
      }

      this.network.handleError("Getting Client Job Titles", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Client Job Titles query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getClientJobTitles--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("getClientJobTitles", res)) {
        // TODO: Display user friendly error message
        return { totalCount: 0, startCursor: "", endCursor: "" };
      }

      if (!res) return { totalCount: 0, startCursor: "", endCursor: "" };

      // this.currentUser = new CurrentUser(this, res.data.viewer.user);

      const clientJobTitles = this.libraryId
        ? res.data.clientLibrary.clientJobTitles
        : res.data.clientJobTitles;

      if (this.countryId) {
        this.totalCount = clientJobTitles.totalCount;
        this.isMainLibrary = false;
      } else {
        this.totalCount = this.libraryId
          ? res.data.clientLibrary.allTitles.totalCount
          : res.data.allTitles.totalCount;
        this.isMainLibrary = this.libraryId
          ? res.data.clientLibrary.isMainLibrary
          : false;
        if (this.library) {
          this.library.totalTitles = res.data.clientLibrary.allTitles.totalCount;
          this.library.totalTitleBySearchableCountries =
            res.data.clientLibrary.totalTitleBySearchableCountries;
        }
      }

      if (this.isFiltered && this.clientJobTitleTitleFilter.textToLookFor) {
        this.resultsForText = this.clientJobTitleTitleFilter.textToLookFor;
      } else {
        this.resultsForText = "";
      }

      const latestSearches: Array<LibraryTitleLatestSearch> =
        clientJobTitles.latestSearches
          ? clientJobTitles.latestSearches.map(
              (search) => new LibraryTitleLatestSearch(search)
            )
          : [];

      this.clientJobTitles = clientJobTitles.edges.map((request) => {
        const clientJobTitle = new JobLibraryTitle(this, request.node);
        const titleId = clientJobTitle.clientRawJobTitle
          ? clientJobTitle.clientRawJobTitle.id
          : null;
        if (titleId) {
          clientJobTitle.latestSearch = latestSearches.find(
            (search: LibraryTitleLatestSearch) => search.titleId === titleId
          );
        }
        if (!this.clientJobTitlesViewState.has(clientJobTitle.id)) {
          const selectedValue =
            this.allSelected || Boolean(clientJobTitle.shouldBeSelected);
          this.clientJobTitlesViewState.set(clientJobTitle.id, {
            selected: selectedValue,
            editing: this.isEditing,
            expanded: false,
          });
        } else {
          const selectedValue = this.allSelected
            ? true
            : this.clientJobTitlesViewState.get(clientJobTitle.id).selected;
          this.clientJobTitlesViewState.set(clientJobTitle.id, {
            selected: selectedValue,
            editing: this.isEditing,
            expanded: false,
          });
        }

        clientJobTitle.viewState = this.clientJobTitlesViewState.get(clientJobTitle.id);

        return clientJobTitle;
      });

      this.isFilteredBySelectedTitles = Boolean(
        this.appliedFilters[FILTER_COLUMN.CLIENT_JOB_TITLE_SELECTED_IDS]
      );

      return {
        totalCount: clientJobTitles.totalCount,
        startCursor: clientJobTitles.pageInfo.startCursor,
        endCursor: clientJobTitles.pageInfo.endCursor,
      };
    });
  }
}

export default class JobLibraryTitleListStore extends JobLibraryTitleListComponentStore {
  showHelpModal: boolean;

  helpModal: ModalState;
  confirmDeleteModal: ModalState;
  confirmDeleteTitleModal: ModalState;
  titleMarkedForDeletion: ?JobLibraryTitle;

  showHelp: () => void;
  hideHelp: () => void;
  confirmDeleteTitle: (JobLibraryTitle, Object) => void;
  deleteTitleConfirmed: () => Promise<any>;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI, mobXStore: MobXStore) {
    super(fetchGraphQL, fetchAPI, mobXStore);

    extendObservable(this, {
      newClientJobTitleName: "",
      helpModal: new ModalState(),
      confirmUpdateRatesModal: new ModalState(),
      confirmUndoUpdateRatesModal: new ModalState(),
      confirmDeleteModal: new ModalState(),
      confirmDeleteTitleModal: new ModalState(),
      titleMarkedForDeletion: null,
    });

    this.showHelp = action(this.showHelp.bind(this));
    this.hideHelp = action(this.hideHelp.bind(this));
    this.confirmDeleteTitle = action(this.confirmDeleteTitle.bind(this));
    this.deleteTitleConfirmed = action(this.deleteTitleConfirmed.bind(this));
  }

  showHelp() {
    this.showHelpModal = true;
  }

  hideHelp() {
    this.showHelpModal = false;
  }

  confirmDeleteTitle(clientJobTitle: JobLibraryTitle, router: Object) {
    this.router = router;
    this.titleMarkedForDeletion = clientJobTitle;
    this.confirmDeleteTitleModal.showModal();
  }

  async deleteTitleConfirmed(): Promise<any> {
    if (this.network.loading) return null;
    if (!this.titleMarkedForDeletion) return null;

    const query = `
      mutation deleteLibraryTitles($titleIds: [ID]!) {
        deleteClientJobTitles(input: {titleIds:$titleIds}) {
          success {
            message
          }

          errors {
            __typename
            ...on NotAllowedForCurrentUserError {
              message
            }
            ...on OperationError {
              message
            }
          }
        }
      }
    `;

    const { clientRawJobTitle } = this.titleMarkedForDeletion || {};
    const { id: titleId } = clientRawJobTitle || {};
    const variables = { titleIds: [titleId || 0] };

    this.network.loading = true;
    let res = null;
    try {
      // res = await this.tasteApi.graphQL({}, { query, variables });
      res = await this.fetchTasteGraphQL(query, variables);
    } catch (e) {
      this.network.loading = false;
      if (axios.isCancel(e)) {
        // console.log('Request Canceled ClientJobTitleListStore');
        return null;
      }

      this.network.handleError("Deleting Client Job Titles", e);
      if (this.network.logGraphQLError("deleteClientJobTitles", res)) {
        const { data } = res || {};
        const { deleteClientJobTitles } = data || {};
        const { errors = [] } = deleteClientJobTitles || {};

        const operationError = errors.find((e) => e.__typename === "OperationError");
        const permissionError = errors.find(
          (e) => e.__typename === "NotAllowedForCurrentUserError"
        );

        if (operationError) {
          // TODO: Display user friendly error message
          console.error(operationError.message);
          return null;
        }

        if (permissionError) {
          // TODO: Display user friendly error message
          console.error(permissionError.message);
          return null;
        }
      }
      // TODO: Display user friendly error message
      // show generic error
      return null;
    }

    return runInAction("getClientJobTitles--success", () => {
      this.network.loading = false;
      this.titleMarkedForDeletion = null;
      // this.pagination.handleFirstPage();
      this.hardRefresh();
      if (this.router) {
        this.mobXStore.jobLibraryListStore.router = this.router;
        this.mobXStore.jobLibraryListStore.pagination.handleFirstPage();
        this.mobXStore.jobLibraryListStore.getCountryLibraries();
      }
    });
  }
}
