import React from "react";
import moment from "moment";
import { fromJS } from "immutable";

import Text from "../../../components/lib/Text";
import Icon from "../../../components/lib/Icon";
import { useTableSchemaState } from "../../../components/tables/Table";

import { TableFilterableRestful } from "./ExtendedRestfulTables";
import ProcessingProgressBar from "./ProcessingProgressBar";

import { PROCESSING_TYPES_LABELS, PROCESSING_TYPES_TYPE } from "../types";
import {
  PROCESSING_STATUSES_OPTIONS,
  PROCESSING_TYPES_OPTIONS,
  dateFormatterBase,
  FilterTypes,
} from "../constants";

import type { TableFilterableRestfulProps } from "../../../components/tables/TableFilterableRestful";
import type { ProcessingDataMap, TableColumnSpecsObject } from "../types";

export const processingsTableColumnsSpecs: TableColumnSpecsObject<ProcessingDataMap>[] = [
  {
    type: "column",
    uniqueKey: "id",
    title: "ID",
    getter: (row) => row.get("id"),
    filterType: FilterTypes.NUMBERS_RANGE,
  },
  {
    type: "column",
    uniqueKey: "started_at",
    title: "Started At",
    getter: (row) => row.get("started_at"),
    formatter: (value) => dateFormatterBase(value, "MM-DD-YYYY h:mm:ss a"),
    filterType: FilterTypes.DATES_RANGE,
    filterExtraProps: {
      datesFormat: moment.defaultFormat,
    },
  },
  {
    type: "column",
    uniqueKey: "user_name",
    title: "Started By",
    getter: (row) => row.get("user_name"),
    formatter: (value, _) => {
      if (!value) return null;

      return (
        <Text>
          <Icon icon="user" css={{ color: "$brand" }} /> {value}
        </Text>
      );
    },
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "program__title",
    title: "Index",
    getter: (row: ProcessingDataMap) => row.get("program_title"),
    formatter: (programTitle: string, row: ProcessingDataMap) => {
      const clientName = row.get("client_name");
      const result =
        clientName == null ? programTitle : `${clientName} / ${programTitle}`;

      return <span>{result}</span>;
    },
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "type",
    title: "Type",
    getter: (row: ProcessingDataMap) => row.get("type"),
    formatter: (value: PROCESSING_TYPES_TYPE, _) => PROCESSING_TYPES_LABELS[value],
    filterType: FilterTypes.ENUMERATION,
    filterOptions: fromJS(PROCESSING_TYPES_OPTIONS),
    sortable: false,
  },
  {
    type: "column",
    uniqueKey: "status",
    title: "Status",
    getter: (row: ProcessingDataMap) => row.get("status"),
    formatter: (_, row: ProcessingDataMap) => <ProcessingProgressBar processing={row} />,
    filterType: FilterTypes.ENUMERATION,
    filterOptions: fromJS(PROCESSING_STATUSES_OPTIONS),
    sortable: false,
  },
  {
    type: "column",
    uniqueKey: "counters",
    title: "Items Total/Finished/Failed",
    getter: () => null,
    formatter: (_, row: ProcessingDataMap) => {
      const totalItems = row.get("total_items");
      const finishedItems = row.get("finished_items");
      const failedItems = row.get("failed_items");

      if (
        [totalItems, finishedItems, failedItems].filter((i) => i != null).length === 0
      ) {
        return null;
      }

      return (
        <span>
          {totalItems}/<Text color="positive">{finishedItems}</Text>/
          <Text color="negative">{failedItems}</Text>
        </span>
      );
    },
    sortable: false,
    filterable: false,
  },
];

type ProcessingsTableProps = Omit<TableFilterableRestfulProps, "schema">;

export const ProcessingsTable = (props: ProcessingsTableProps) => {
  const [schema] = useTableSchemaState<ProcessingDataMap>(props.children);
  return <TableFilterableRestful {...props} schema={schema} />;
};
ProcessingsTable.displayName = "ProcessingsTable";
ProcessingsTable.defaultProps = TableFilterableRestful.defaultProps;

export default ProcessingsTable;
