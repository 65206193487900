import React from "react";
import PropTypes from "prop-types";
import NumberInput from "../utils/NumberInput";
import * as PercentField from "./PercentField";

export const valueType = "markup";

const codeCache = {};

const getCalculationFor = (field) => {
  const idKey = `${field.id}`;
  if (codeCache.hasOwnProperty(idKey)) {
    return codeCache[idKey];
  }

  const code = field.fieldTypeConfig.code.trim();
  if (!code) return false;

  try {
    codeCache[idKey] = new Function("payRate", code); // eslint-disable-line no-new-func
    return codeCache[idKey];
  } catch (e) {
    return false;
  }
};

export const calculateValue = (field, payRate) => {
  const calculationFunction = getCalculationFor(field);
  if (!calculationFunction) {
    return 0;
  }

  try {
    let returnValue = calculationFunction(payRate);

    if (!returnValue) returnValue = 0;

    returnValue = +returnValue;

    if (isNaN(returnValue)) returnValue = 0;

    return +Math.max(returnValue, 0).toFixed(2);
  } catch (e) {
    return 0;
  }
};

export class Field extends React.Component {
  static propTypes = {
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
  };

  shouldComponentUpdate(nextProps) {
    return nextProps.value !== this.props.value;
  }

  render() {
    return <NumberInput step="any" addonAfter="%" min={0} {...this.props} />;
  }
}

export const CalculatedValue = PercentField.CalculatedValue;
