import { library } from "@fortawesome/fontawesome-svg-core";
import {
  // faBlank is simply for making even spacing. Not something you actually import
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faArrowCircleUp,
  faArrowsUpDownLeftRight,
  faArchive,
  faBell,
  faBook,
  faPlay,
  faPause,
  faSort,
  faSortUp,
  faSortDown,
  faCircleNotch,
  faCalendar,
  faCalendarAlt,
  faCaretDown,
  faChartBar,
  faChartColumn,
  faCheck,
  faCheckSquare,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCommenting,
  faCopy,
  faDotCircle,
  faCog,
  faCompress,
  faDatabase,
  faDesktop,
  faDollarSign,
  faDownload,
  faEdit,
  faEnvelope,
  faEllipsisV,
  faExclamationCircle,
  faExclamationTriangle,
  faHourglass,
  faHistory,
  faEye,
  faEyeSlash,
  faFastBackward,
  faFile,
  faFileAlt,
  faFilter,
  faFlag,
  faCaretUp,
  faFolder,
  faInfo,
  faInfoCircle,
  faKey,
  faLifeRing,
  faList,
  faListAlt,
  faLock,
  faMapMarker,
  faMapPin,
  faMinus,
  faPlayCircle,
  faTable,
  faSignOut,
  faSortAlphaDown,
  faSortAlphaDownAlt,
  faSortAlphaUp,
  faSortNumericDown,
  faSortNumericDownAlt,
  faSortNumericUp,
  faPlus,
  faPlusCircle,
  faQuestion,
  faQuestionCircle,
  faRedo,
  faRss,
  faSearch,
  faShare,
  faStickyNote,
  faSpinner,
  faSquare,
  faSync,
  faSyncAlt,
  faTachometerAlt,
  faTag,
  faTasks,
  faTimes,
  faTimesCircle,
  faThList,
  faTrash,
  faTrashAlt,
  faUndo,
  faUser,
  faUserCheck,
  faUserPlus,
  faUsers,
  faMapMarked,
  faSortAmountDown,
  faSortAmountUp,
  faBan,
  faChevronCircleRight,
  faPeopleArrows,
  faExternalLinkAlt,
  faSlidersH,
  faGlobe,
  faPhone,
  faPhoneAlt,
  faUpload,
  faImage,
  faListUl,
  faMapMarkerAlt,
  faGlobeAmericas,
  faWrench,
  faFileUpload,
  faTh,
  faGraduationCap,
  faPlane,
  faPaperPlane,
  faLeaf,
  faCubes,
  faShoppingCart,
  faUniversity,
  faStethoscope,
  faUmbrella,
  faCogs,
  faTint,
  faFlask,
  faBolt,
  faBriefcase,
  faSignal,
  faThumbsUp,
  faExclamation,
  faPencilAlt,
  faFileExcel,
  faIndustry,
} from "@fortawesome/free-solid-svg-icons";
import {
  faSave as farSave,
  faFileExcel as farFileExcel,
  faFileAlt as farFileAlt,
  faTrashAlt as farTrashAlt,
  faCopy as farCopy,
  faEdit as farEdit,
  faStopCircle as farStopCircle,
  faQuestionCircle as farQuestionCircle,
  faThumbsUp as farThumbsUp,
  faCalendarAlt as farCalendarALt,
  faCalendar as farCalendar,
  faCheckSquare as farCheckSquare,
  faSquare as farSquare,
  faPlayCircle as farPlayCircle,
  faPauseCircle as farPauseCircle,
  faCommenting as farCommenting,
} from "@fortawesome/free-regular-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons/faLinkedinIn";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";

/**
 * TODO: setup dynamic icon importing.
 * Automatically includes just the icons you're using in your components,
 * optimizing your final bundle. Only the icons you use are included in the bundle.
 * https://fontawesome.com/v6/docs/web/use-with/react/add-icons#dynamic-icon-importing
 */
export function registerIcons() {
  library.add(
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleLeft,
    faAngleRight,
    faArrowLeft,
    faArrowRight,
    faArrowCircleUp,
    faArrowsUpDownLeftRight,
    faArchive,
    faBell,
    faBook,
    faCalendar,
    faCalendarAlt,
    faCaretDown,
    faCaretUp,
    faChartBar,
    faChartColumn,
    faCheck,
    faCheckSquare,
    farCheckSquare,
    faCheckCircle,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircle,
    faCog,
    faCommenting,
    farCommenting,
    faCopy,
    farCopy,
    faCircleNotch,
    faCompress,
    faTable,
    faSignOut,
    faSortAlphaDown,
    faSortAlphaDownAlt,
    faSortAlphaUp,
    faSortNumericDown,
    faSortNumericDownAlt,
    faSortNumericUp,
    faDatabase,
    faDesktop,
    faDollarSign,
    faDotCircle,
    faDownload,
    faPlay,
    faPause,
    faSort,
    faSortUp,
    faSortDown,
    faEdit,
    faEnvelope,
    farEdit,
    faEllipsisV,
    faEye,
    faEyeSlash,
    faExclamationCircle,
    faExclamationTriangle,
    faFacebookF,
    faFastBackward,
    faFile,
    faFileAlt,
    farFileAlt,
    faFilter,
    faFlag,
    faFolder,
    faInfo,
    faInfoCircle,
    faHourglass,
    faHistory,
    faKey,
    faLifeRing,
    faLinkedinIn,
    faList,
    faListAlt,
    faLock,
    faMapMarker,
    faMapPin,
    faMinus,
    faPlayCircle,
    faPlus,
    faPlusCircle,
    faQuestion,
    faQuestionCircle,
    farQuestionCircle,
    faRedo,
    faRss,
    faSearch,
    faShare,
    faStickyNote,
    faSpinner,
    faSquare,
    farSquare,
    faSync,
    faSyncAlt,
    faTachometerAlt,
    faTag,
    faTasks,
    faTimes,
    faTimesCircle,
    faThList,
    faTrash,
    faTrashAlt,
    farTrashAlt,
    faTwitter,
    faUndo,
    faUser,
    faUserCheck,
    faUserPlus,
    faUsers,
    faMapMarked,
    faSortAmountDown,
    faSortAmountUp,
    faBan,
    faChevronCircleRight,
    faPeopleArrows,
    faExternalLinkAlt,
    faGlobe,
    faSlidersH,
    faPhone,
    faPhoneAlt,
    faUpload,
    faImage,
    faListUl,
    faMapMarkerAlt,
    faGlobeAmericas,
    faBriefcase,
    faWrench,
    farSave,
    faFileExcel,
    farFileExcel,
    faFileUpload,
    faTh,
    faGraduationCap,
    faPlane,
    faPaperPlane,
    faLeaf,
    faCubes,
    faShoppingCart,
    faUniversity,
    faStethoscope,
    faUmbrella,
    faCogs,
    faTint,
    faFlask,
    faBolt,
    faBriefcase,
    faSignal,
    faThumbsUp,
    farThumbsUp,
    faExclamation,
    farQuestionCircle,
    faPencilAlt,
    farStopCircle,
    farCalendarALt,
    farCalendar,
    farPlayCircle,
    farPauseCircle,
    faIndustry
  );
}
