// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import CountrySelectNew from "../../components/selects/CountrySelectNew";
import StateSelectNew from "../../components/selects/StateSelectNew";
import ContainerSection from "../ratecards/components/ContainerSection";
import Overview from "./components/Overview";
import RateAndPayTypePicker from "./components/RateAndPayTypePicker";
import WorkerTypeCard from "./components/WorkerTypeCard";
import { fieldTypeEq, rateTypeContains } from "./utils/filter";
import GroupList from "./components/GroupList";
import { BottomControls, BottomControlGroup } from "../../components/material";
import GroupChartList from "./components/GroupChartList";
import CalculatedResults from "./components/CalculatedResults";
import CalculatedResultsForSalary from "./components/CalculatedResultsForSalary";
import ResultSummary from "./components/ResultSummary";
import ResultsSummaryForSalary from "./components/ResultsSummaryForSalary";
import LoadingIndicator from "../shared/LoadingIndicator";
import type MobXStore from "../../stores/mobx/MobXStore";
import SessionInfo from "../../models/SessionInfo";
import { Card } from "../../components/lib/Card";
import Stack from "../../components/lib/Stack";
import Inline, { InlineElements } from "../../components/lib/Inline";
import TextInput from "../../components/lib/TextInput";
import Button from "../../components/lib/Button";
import LinkButton from "../../components/lib/LinkButton";

type Props = {
  store: MobXStore,
  sessionInfo: SessionInfo,
  location: any,
};

const enableNegotiationWorksheetSalaryForClientIds = [
  4384, // PeopleTicker
];

class CreateNegotiationWorksheet extends Component<Props> {
  UNSAFE_componentWillMount() {
    const store = this.props.store.negotiationWorksheetCreateStore;

    if (this.props.location.state && this.props.location.state.error) {
      this.props.location.state.error.message = "";
    }

    let initialCurrentState = {};
    initialCurrentState.payValue = null;
    initialCurrentState.payType = null;
    initialCurrentState.rateType = null;
    initialCurrentState.workerTypeKey = "";
    initialCurrentState.unsavedValues = [];
    store.undoQueue[0] = initialCurrentState;
    store.currentState = initialCurrentState;
  }

  componentDidMount() {
    const store = this.props.store.negotiationWorksheetCreateStore;
    store.worksheetName = "";
    if (store.messaging.messages.length > 0) {
      store.messaging.messages.length = 0;
      store.messaging.removeMessage.bind(store, store.messaging.messages[0]);
    }
    store.country = "";
    store.countryid = "";
    store.resetValues();
    store.state = "";
    store.stateid = "";
    store.apiStateId = "";
  }

  setScriptedPercentage() {
    return this.hasFieldOfType("scriptedPercentage");
  }

  hasFieldOfType(fieldType) {
    const { worksheetSchema } = this.props.store.negotiationWorksheetCreateStore;

    if (!worksheetSchema.fields) return null;

    return worksheetSchema.fields.some(fieldTypeEq(fieldType));
  }

  getFieldKeySetSeqForSalaryType() {
    return this.getFieldsForSalaryType()
      ? this.getFieldsForSalaryType().map((field) => field.key)
      : [];
  }

  getFieldsForSalaryType() {
    const { worksheetSchema } = this.props.store.negotiationWorksheetCreateStore;

    if (!worksheetSchema.fields) return null;

    return worksheetSchema.fields.filter(rateTypeContains("salary"));
  }

  changeCountryText = (value) => {
    this.props.store.negotiationWorksheetCreateStore.changeCountryText(value);
  };

  componentWillUnmount() {
    this.props.store.negotiationWorksheetCreateStore.country = null;
  }

  render() {
    const store = this.props.store.negotiationWorksheetCreateStore;
    const salaryFieldsCheck = this.getFieldKeySetSeqForSalaryType();
    //const payTypeBillRateDisabled = this.setScriptedPercentage();
    const conversionOptions =
      store.worksheetSchema && store.worksheetSchema.conversionOptions
        ? store.worksheetSchema.conversionOptions
        : "";
    const calculatedResults = store.calculatePayAndBillRate(conversionOptions);
    const {
      payRateHourly,
      billRateHourly,
      totalMarkup,
      totalTax,
      billRateHourlyAfterTax,
    } = calculatedResults;
    const { valUpdated } = store;

    const sessionInfo = this.props.sessionInfo;
    const clientId = sessionInfo?.client?.legacyId || 0;
    const enableSalary = enableNegotiationWorksheetSalaryForClientIds.includes(clientId);

    return (
      <div className="view list">
        <div>
          <Card css={{ padding: "$6" }}>
            <Stack css={{ alignItems: "flex-start" }}>
              <h2>Create Worksheet</h2>
              <Inline css={{ alignItems: "flex-start" }} fill>
                <Stack css={{ alignItems: "flex-start", gap: 0, width: "30%" }}>
                  <label htmlFor="worksheetName">Worksheet Label:</label>
                  <TextInput
                    id="worksheetName"
                    fill
                    maxLength="250"
                    value={store.worksheetName}
                    onChange={store.changeWorksheetNameText}
                    data-testid="worksheet-name"
                  />
                  <div className="pt-user-messages">
                    {store.messaging.messages.length > 0 && (
                      <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                        <a
                          href="#close"
                          className="close"
                          data-dismiss="alert"
                          aria-label="close"
                          onClick={store.messaging.removeMessage.bind(
                            store,
                            store.messaging.messages[0]
                          )}
                        >
                          &times;
                        </a>
                        <strong>{store.messaging.messages[0].messageLabel}</strong>
                        {store.messaging.messages[0].message}
                      </div>
                    )}
                  </div>
                </Stack>
                <Stack css={{ alignItems: "flex-start", gap: 0, width: "30%" }}>
                  <label htmlFor="availableCountriesSelect" className="block">
                    Available Countries
                  </label>
                  <CountrySelectNew
                    id="availableCountriesSelect"
                    CountryTitle={(param) => store.countryTitle(param)}
                    onChange={(value) => this.changeCountryText(value)}
                  />
                </Stack>
                {store.checkLegacy() && store.displayState && (
                  <Stack css={{ alignItems: "flex-start", gap: 0, width: "30%" }}>
                    <label htmlFor="availableStatesSelect">Available States</label>
                    <StateSelectNew
                      id="availableStatesSelect"
                      StateTitle={(param) => store.getStates(param)}
                      onChange={(value) => store.changeStateText(value)}
                      value={store.state}
                      store={store}
                    />
                  </Stack>
                )}
              </Inline>
            </Stack>
          </Card>
          {store.countryid &&
            !store.worksheetSchema.currencySymbol &&
            this.props.location.state &&
            this.props.location.state.error &&
            this.props.location.state.error.message && (
              <div className="pt-user-messages">
                <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                  {this.props.location.state.error.message}
                </div>
              </div>
            )}
        </div>
        {((store.checkLegacy() && store.stateSelected && store.state) ||
          (!store.checkLegacy() && store.countryid)) && (
          <div>
            <div className="row">
              {!store.worksheetSchema.currencySymbol && <LoadingIndicator />}
              <div className="col-xs-6">
                {store.worksheetSchema.currencySymbol && (
                  <div className="rc-container">
                    <RateAndPayTypePicker
                      payTypeBillRateDisabled={store.payTypeBillRateDisabled}
                      store={store}
                      currencySymbol={store.worksheetSchema.currencySymbol}
                      payValue={store.currentState.payValue}
                      salaryFieldsCheck={salaryFieldsCheck.length}
                      setPayValue={store.setPayValue.bind(this, store)}
                      setPayType={store.setPayType.bind(this, store)}
                      setRateType={store.setRateType.bind(this, store)}
                      payType={store.currentState.payType}
                      rateType={
                        store.currentState.payType === 3
                          ? ""
                          : store.currentState.rateType
                      }
                      workerTypeKey={
                        store.currentState.payType === 3
                          ? ""
                          : store.currentState.workerTypeKey
                      }
                      setWorkerType={store.setWorkerType.bind(this, store)}
                      workerTypes={store.worksheetSchema.workerTypes}
                      enableSalaryRateTypeOption={enableSalary}
                    />
                  </div>
                )}
                {(store.currentState.rateType && store.selectedWorkerType.id) ||
                store.currentState.payType === 3 ? (
                  <GroupList
                    groups={store.worksheetSchema.groups}
                    fields={store.worksheetSchema.fields}
                    workerTypeId={store.selectedWorkerType.id}
                    currencySymbol={store.worksheetSchema.currencySymbol}
                    payRateHourly={payRateHourly}
                    billRateHourly={billRateHourly}
                    conversionOptions={conversionOptions}
                    store={store}
                    valUpdated={valUpdated}
                  />
                ) : (
                  ""
                )}
                {store.currentState.rateType &&
                  store.selectedWorkerType.id &&
                  store.currentState.payType !== 3 && (
                    <CalculatedResults
                      store={store}
                      payRateHourly={parseFloat(payRateHourly)}
                      conversionOptions={conversionOptions}
                      totalMarkup={parseFloat(totalMarkup)}
                      billRateHourly={parseFloat(billRateHourly)}
                      currencySymbol={store.worksheetSchema.currencySymbol}
                      totalTax={parseFloat(totalTax)}
                      billRateHourlyAfterTax={parseFloat(billRateHourlyAfterTax)}
                    />
                  )}
                {store.currentState.payType === 3 && (
                  <CalculatedResultsForSalary
                    conversionOptions={conversionOptions}
                    totalMarkup={parseFloat(totalMarkup)}
                    currencySymbol={store.worksheetSchema.currencySymbol}
                    payValue={store.currentState.payValue}
                  />
                )}
              </div>
              <div className="col-xs-6">
                {store.selectedWorkerType.id && store.currentState.rateType && (
                  <div className="rc-container">
                    <WorkerTypeCard workerType={store.selectedWorkerType} />
                  </div>
                )}
                {store.worksheetSchema.overviews && (
                  <div className="rc-container">
                    <Overview store={store} isExpanded={store.isExpanded} />
                  </div>
                )}
                {(store.currentState.rateType && store.selectedWorkerType.id) ||
                store.currentState.payType === 3 ? (
                  <GroupChartList
                    groups={store.worksheetSchema.groups}
                    store={store}
                    payRateHourly={payRateHourly}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <div className="rc-container">
                  <BottomControls height="50px">
                    {store.currentState.rateType &&
                      store.selectedWorkerType.id &&
                      store.currentState.payType !== 3 && (
                        <ResultSummary
                          currencySymbol={store.worksheetSchema.currencySymbol}
                          rateType={store.currentState.rateType}
                          payRateHourly={parseFloat(payRateHourly)}
                          billRateHourly={parseFloat(billRateHourly)}
                          totalTax={parseFloat(totalTax)}
                          totalMarkup={parseFloat(totalMarkup)}
                          billRateHourlyAfterTax={parseFloat(billRateHourlyAfterTax)}
                          conversionOptions={conversionOptions}
                          store={store}
                        />
                      )}
                    {store.currentState.payType === 3 && (
                      <ResultsSummaryForSalary
                        currencySymbol={store.worksheetSchema.currencySymbol}
                        payValue={store.currentState.payValue}
                        totalMarkup={totalMarkup}
                        conversionOptions={conversionOptions}
                        store={store}
                      />
                    )}
                    <BottomControlGroup>
                      <InlineElements css={{ justifyContent: "flex-end" }}>
                        <Button
                          disabled={!store.canUndo()}
                          color="brand"
                          icon="undo"
                          onClick={store.undo}
                        >
                          Undo
                        </Button>
                        <Button
                          disabled={!store.canRedo()}
                          color="brand"
                          icon="redo"
                          onClick={store.redo}
                        >
                          Redo
                        </Button>
                      </InlineElements>
                    </BottomControlGroup>
                  </BottomControls>
                  <ContainerSection className="footer">
                    <div className="flex align-items-center">
                      <div className="pt-user-messages no-margin">
                        {store.messaging.messages.length > 0 && (
                          <div className="alert alert-danger alert-dismissable fade in pt-user-alert no-margin">
                            <strong>Errors found!</strong> Please review all fields and
                            fix the errors.
                          </div>
                        )}
                      </div>
                      <div className="flex-fill" />
                      <InlineElements>
                        <LinkButton to="/negotiation-worksheets" size="large">
                          Cancel
                        </LinkButton>
                        {store.canShowCreateBtn() && (
                          <Button
                            disabled={!store.canSave()}
                            size="large"
                            color="brand"
                            onClick={store.saveWorksheet}
                          >
                            Create Worksheet
                          </Button>
                        )}
                      </InlineElements>
                    </div>
                  </ContainerSection>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

CreateNegotiationWorksheet.propTypes = {
  store: PropTypes.object.isRequired,
};

export default observer(CreateNegotiationWorksheet);
