// @flow

import * as React from "react";
import R from "ramda";
import { extendObservable, action, runInAction, computed, observable } from "mobx";
import axios from "axios";
import moment from "moment";
import RateCard from "../../../models/RateCard";
import CurrentUser from "../../../models/User";
import {
  RateCardLabelFilter,
  HasRateTypeFilter,
  CreatedByFilter,
  SharedFilter,
  CreatedOnFilter,
  TagsFilter,
  UserFilter,
  ClientUserFilter,
} from "../../../models/FilterState";
import NetworkState from "../../../models/NetworkState";
import PaginationState from "../../../models/PaginationState";
import FilterObject, { FILTER_COLUMN } from "../../../models/Filter";
import Sort, { SORT_DIRECTION } from "../../../models/Sort";
import ModalState from "../../../models/ModalState";
import {
  addIdToPayload,
  consolidateAppliedFilters,
  consolidateAppliedSorts,
} from "../SupportFunctions";
import type { PageQuery, PaginationInfo } from "../../../models/PaginationState";
import type { FilterColumn } from "../../../models/Filter";
import type { GraphQLQuery } from "../../../models/GraphQL";
import ApplyTagState from "../../../models/ApplyTagState";
import type { FetchAPI, FetchGraphQL } from "../../../App";

const ownersCriteriaQuery = `
query authorList {
  viewer {
    authors (section:ADMIN){
      edges {
        node {
          userId
          firstName
          lastName
          username
          email
        }
      }
    }
  }
}
`;

const rateTypesCriteriaQuery = `
query {
  viewer {
    rateCardsFilterCriteria {
      rateTypes
    }
  }
}
`;

const tagsFilterCriteria = `
query getTags {
 viewer{
   tagsFilterCriteria{
     tagnames(contentType: RATE_CARD, section : ADMIN){
       tag
     }
   }
 }
}
`;

const userCriteriaQuery = `
query authorList {
  viewer {
    authors (section: ADMIN){
      edges {
        node {
          userId
          firstName
          lastName
          username
          email
        }
      }
    }
  }
}
`;

const clientUserCriteriaQuery = `
query allClients {
  viewer {
    allClients(order : [{direction : ASC, field: NAME}]) {
      edges {
        node {
          clientId
          name
        }
      }
    }
  }
}
`;

// type Owner = { userId: string, username: string };

class UserNode {
  userId: number;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  selected: boolean;

  constructor(payload: Object, selected: boolean = false) {
    extendObservable(this, {
      selected,
      ...payload,
    });
  }
}

export class RateCardListComponentStore {
  network: NetworkState;
  pagination: PaginationState;

  rateCards: RateCard[];
  rateCardsViewState: Object;
  rateCardsView: RateCard[];
  rateCardCounts: any;

  currentUser: CurrentUser;
  isEditing: ?boolean;
  allOnPageSelected: boolean;
  allSelected: boolean;
  allSelectedfilter: boolean;
  allowMultipleItemSelection: boolean;

  hasRateTypeFilter: HasRateTypeFilter;
  rateCardLabelFilter: RateCardLabelFilter;
  createdByFilter: CreatedByFilter;
  sharedFilter: SharedFilter;
  createdOnFilter: CreatedOnFilter;
  tagsFilter: TagsFilter;
  userFilter: UserFilter;
  clientUserFilter: ClientUserFilter;
  requestFrom: boolean;

  defaultFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedSorts: {
    [key: FilterColumn]: Sort,
  };
  appliedSortsOrder: Array<FilterColumn>;
  isFiltered: boolean;
  hasOwnership: boolean;
  selectedRateCardId: null;
  unSelectedRateCard: [];

  getRateCards: (PageQuery) => Promise<PaginationInfo>;
  applyDefaultFilter: (FilterColumn, FilterObject) => void;
  handleStartEdit: () => void;
  handleStopEdit: () => void;
  toggleSelectAllPage: (Object) => void;
  selectAllPage: (Event) => void;
  deselectAllPage: (Event) => void;
  toggleAllItems: () => void;
  clearAllSelections: () => void;
  getSelectedRateCards: () => Array<string>;
  getFirstSelectedRateCard: () => Object;
  getSelectedRateCardName: () => String;
  toDateRange: () => void;
  getFilterCriteriaQuery: (FilterColumn) => GraphQLQuery;
  processFilterCriteria: (FilterColumn, Object) => Array<Object>;
  applyFilter: (FilterColumn, FilterObject) => void;
  removeFilter: (FilterColumn) => void;
  applySort: (FilterColumn, Sort) => void;
  removeSort: (FilterColumn) => void;
  filterRateCards: () => void;
  clearFilters: () => void;
  applyDefaultSort: () => void;
  updateRequestFrom: () => void;
  SingleSelectedRateCard: () => void;
  applyTagState: ApplyTagState;
  setTagViewFn: () => void;
  isTagView: ?boolean;
  selectAllOnPageItem: () => void;
  fetchGraphQL: FetchGraphQL;

  constructor(fetchGraphQL: FetchGraphQL) {
    this.fetchGraphQL = fetchGraphQL;

    // NOTE: Bound early to pass into pagination & filter state
    this.getRateCards = action(this.getRateCards.bind(this));

    this.getFilterCriteriaQuery = action(this.getFilterCriteriaQuery.bind(this));
    this.processFilterCriteria = action(this.processFilterCriteria.bind(this));
    this.applyFilter = action(this.applyFilter.bind(this));
    this.applySort = action(this.applySort.bind(this));
    this.removeFilter = action(this.removeFilter.bind(this));
    this.removeSort = action(this.removeSort.bind(this));
    this.toDateRange = action(this.toDateRange.bind(this));
    this.unSelectedRateCard = [];

    extendObservable(this, {
      network: new NetworkState(),
      pagination: new PaginationState(this.getRateCards),
      rateCards: [],
      rateCardCounts: 0,
      hasOwnership: true,
      selectedRateCardId: null,
      rateCardsViewState: observable.map({}),
      rateCardsView: computed(() => {
        return this.rateCards.map((rateCard) => {
          if (this.rateCardsViewState.has(rateCard.ratecardId)) {
            rateCard.viewState = this.rateCardsViewState.get(rateCard.ratecardId);

            return rateCard;
          }

          return rateCard;
        });
      }),
      isEditing: null, // we start with null so some view elements be hidden initially
      isFiltered: false,
      allSelected: false,
      allSelectedfilter: false,
      allowMultipleItemSelection: true,
      requestFrom: false,
      selectedCount: computed(() => {
        const selectedValues = this.rateCardsView.map(
          (rateCard) => rateCard.viewState.selected
        );

        if (this.allSelected) {
          return this.pagination.totalCount;
        }

        let count = 0;

        selectedValues.forEach((v) => {
          if (v) {
            count += 1;
          }
        });

        return count;
      }),
      allOnPageSelected: computed(() => {
        const allTrue = R.all(R.equals(true));
        const selectedValues = this.rateCardsView.map(
          (rateCard) => rateCard.viewState.selected
        );

        if (selectedValues.length === 0) {
          return false;
        }

        return allTrue(selectedValues);
      }),
      defaultFilters: {},
      appliedFilters: {},
      appliedSorts: {},
      appliedSortsOrder: observable.shallow([]),
      rateCardLabelFilter: new RateCardLabelFilter(
        this,
        FILTER_COLUMN.RATE_CARD_LABEL,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      hasRateTypeFilter: new HasRateTypeFilter(
        this,
        FILTER_COLUMN.RATE_TYPE,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.removeFilter
      ),
      createdByFilter: new CreatedByFilter(
        this,
        FILTER_COLUMN.CREATED_BY,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      sharedFilter: new SharedFilter(
        this,
        FILTER_COLUMN.SHARED,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      createdOnFilter: new CreatedOnFilter(
        this,
        FILTER_COLUMN.DATE_RANGE,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      tagsFilter: new TagsFilter(
        this,
        FILTER_COLUMN.TAGS,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      userFilter: new UserFilter(
        this,
        FILTER_COLUMN.USER,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      clientUserFilter: new ClientUserFilter(
        this,
        FILTER_COLUMN.CLIENT_USER,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      applyTagState: new ApplyTagState(fetchGraphQL),
      isTagView: null,
    });

    this.currentUser = null;

    this.applyDefaultFilter = action(this.applyDefaultFilter.bind(this));
    this.handleStartEdit = action(this.handleStartEdit.bind(this));
    this.handleStopEdit = action(this.handleStopEdit.bind(this));
    this.toggleSelectAllPage = action(this.toggleSelectAllPage.bind(this));
    this.selectAllPage = action(this.selectAllPage.bind(this));
    this.deselectAllPage = action(this.deselectAllPage.bind(this));
    this.toggleAllItems = action(this.toggleAllItems.bind(this));
    this.clearAllSelections = action(this.clearAllSelections.bind(this));
    this.getSelectedRateCards = action(this.getSelectedRateCards.bind(this));
    this.getSelectedRateCardName = action(this.getSelectedRateCardName.bind(this));

    this.getFirstSelectedRateCard = action(this.getFirstSelectedRateCard.bind(this));

    this.filterRateCards = action(this.filterRateCards.bind(this));
    this.clearFilters = action(this.clearFilters.bind(this));
    this.applyDefaultSort = action(this.applyDefaultSort.bind(this));
    this.updateRequestFrom = action(this.updateRequestFrom.bind(this));
    this.SingleSelectedRateCard = action(this.SingleSelectedRateCard.bind(this));
    this.setTagViewFn = action(this.setTagViewFn.bind(this));
    this.selectAllOnPageItem = action(this.selectAllOnPageItem.bind(this));

    this.applyDefaultSort();
  }

  setTagViewFn() {
    this.isTagView = true;
    this.isEditing = true;
  }

  toDateRange(date) {
    var val = true;
    if (this.createdOnFilter.fromDate) {
      val = !date.isBetween(
        this.createdOnFilter.fromDate,
        moment(new Date()),
        "days",
        "[]"
      );
    }
    return val;
  }

  updateRequestFrom(request) {
    if (request && request === "pce") this.requestFrom = true;
    else {
      this.requestFrom = false;
    }
  }

  SingleSelectedRateCard(ratecard) {
    let viewState = this.rateCardsViewState.get(ratecard.ratecardId).selected;

    if (!viewState) {
      this.selectedRateCardId = ratecard.ratecardId;
    } else {
      this.selectedRateCardId = null;
    }

    if (!viewState && ratecard.owner && ratecard.owner.userId) {
      this.hasOwnership =
        String(ratecard.owner.userId) === String(this.currentUser.userId);
    } else {
      this.hasOwnership = true;
    }
  }

  applyDefaultSort() {
    this.createdOnFilter.sortState.direction = SORT_DIRECTION.DESC;
    this.createdOnFilter.sort = this.createdOnFilter.buildQuerySort();
    this.applySort(this.createdOnFilter.column, this.createdOnFilter.sort);
  }

  handleStartEdit() {
    this.isEditing = true;
    this.rateCardsView.forEach((rateCard) => rateCard.toggleEdit());
  }

  handleStopEdit() {
    this.isEditing = false;
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.rateCardsViewState.forEach((viewState) => {
      viewState.selected = false;
      viewState.editing = false;
    });
  }

  toggleSelectAllPage(e: Object) {
    if (!this.allowMultipleItemSelection) return;

    const setValue = !this.allOnPageSelected;

    this.rateCardsView.forEach((rateCard) => {
      rateCard.toggleSelected(e, null, null, setValue);
    });

    // When All items selected flag is up, clear selection
    if (setValue === false && this.allSelected) this.allSelected = false;
  }

  selectAllPage(e: Event) {
    this.unSelectedRateCard = [];
    this.allSelected = true;
    this.allSelectedfilter = true;
  }

  selectAllOnPageItem(e: Event) {
    this.rateCardsView.forEach((rateCard) => {
      rateCard.toggleSelected(e, null, null, true);
    });
  }

  deselectAllPage(e: Event) {
    this.rateCardsView.forEach((rateCard) => {
      rateCard.toggleSelected(e, null, null, false);
    });

    this.allSelected = false;
    this.allSelectedfilter = false;
  }

  toggleAllItems() {
    if (!this.allowMultipleItemSelection) return;

    this.allSelected = !this.allSelected;

    if (this.allSelected === false) {
      this.rateCardsViewState.forEach((value) => {
        value.selected = false;
      });
    }
  }

  clearAllSelections() {
    this.isTagView = false;
    //this.isEditing = false;
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.rateCardsViewState.forEach((value) => {
      value.selected = false;
    });
  }

  getFilterCriteriaQuery(column: FilterColumn): GraphQLQuery {
    switch (column) {
      case FILTER_COLUMN.RATE_TYPE:
        return {
          query: rateTypesCriteriaQuery,
          variables: {},
        };

      case FILTER_COLUMN.CREATED_BY:
        return {
          query: ownersCriteriaQuery,
          variables: {},
        };
      case FILTER_COLUMN.TAGS:
        return {
          query: tagsFilterCriteria,
          variables: {},
        };
      case FILTER_COLUMN.USER:
        return {
          query: userCriteriaQuery,
          variables: {},
        };

      case FILTER_COLUMN.CLIENT_USER:
        return {
          query: clientUserCriteriaQuery,
          variables: {},
        };

      default:
        return null;
    }
  }

  processFilterCriteria(column: FilterColumn, payload: Object): ?Array<Object> {
    switch (column) {
      case FILTER_COLUMN.RATE_TYPE:
        const rateTypes: [String] = payload.data.viewer.rateCardsFilterCriteria.rateTypes;
        return addIdToPayload(rateTypes);

      case FILTER_COLUMN.CREATED_BY:
        const owners: [
          {
            userId: string,
            username: string,
          }
        ] = payload.data.viewer.authors.edges;
        let processedOwners = observable.map({});
        owners.forEach((owner) => {
          processedOwners.set(String(owner.node.userId), {
            id: String(owner.node.userId),
            ...owner,
          });
        });

        return processedOwners;

      case FILTER_COLUMN.TAGS:
        const tags: [
          {
            tag: string,
          }
        ] = payload.data.viewer.tagsFilterCriteria.tagnames;
        let processedTag = observable.map({});
        tags.forEach((tag, index) => {
          processedTag.set(String(index), {
            id: String(index),
            ...tag,
          });
        });
        return processedTag;

      case FILTER_COLUMN.USER:
        const users: [
          {
            userId: string,
            username: string,
          }
        ] = payload.data.viewer.authors.edges;
        let processedUsers = observable.map({});
        users.forEach((owner) => {
          processedUsers.set(String(owner.node.userId), {
            id: String(owner.node.userId),
            ...owner,
          });
        });

        return processedUsers;

      case FILTER_COLUMN.CLIENT_USER:
        const clientowners: [
          {
            clientId: string,
            name: string,
          }
        ] = payload.data.viewer.allClients.edges;
        let processedClientOwners = observable.map({});
        clientowners.forEach((owner) => {
          processedClientOwners.set(owner.node.clientId, {
            id: owner.node.clientId,
            ...owner,
          });
        });

        return processedClientOwners;

      default:
        return null;
    }
  }

  applyFilter(column: FilterColumn, filter: FilterObject) {
    this.appliedFilters[column] = filter;
    this.isFiltered = true;
  }

  applyDefaultFilter(column: FilterColumn, filter: FilterObject) {
    this.defaultFilters[column] = filter;
  }

  removeFilter(column: FilterColumn) {
    delete this.appliedFilters[column];

    let entries = Object.entries(this.appliedFilters);
    if (!entries.length) this.isFiltered = false;
  }

  applySort(column: FilterColumn, sort: Sort) {
    this.appliedSorts[column] = sort;

    const index = this.appliedSortsOrder.indexOf(column);
    if (index === -1) this.appliedSortsOrder.push(column);
  }

  removeSort(column: FilterColumn) {
    delete this.appliedSorts[column];

    const index = this.appliedSortsOrder.indexOf(column);
    if (index > -1) this.appliedSortsOrder.splice(index, 1);
  }

  clearFilters() {
    this.rateCardLabelFilter = new RateCardLabelFilter(
      this,
      FILTER_COLUMN.RATE_CARD_LABEL,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.hasRateTypeFilter = new HasRateTypeFilter(
      this,
      FILTER_COLUMN.RATE_TYPE,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.removeFilter
    );
    this.createdByFilter = new CreatedByFilter(
      this,
      FILTER_COLUMN.CREATED_BY,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.sharedFilter = new SharedFilter(
      this,
      FILTER_COLUMN.SHARED,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.createdOnFilter = new CreatedOnFilter(
      this,
      FILTER_COLUMN.DATE_RANGE,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );

    this.tagsFilter = new TagsFilter(
      this,
      FILTER_COLUMN.TAGS,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.userFilter = new UserFilter(
      this,
      FILTER_COLUMN.USER,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.clientUserFilter = new ClientUserFilter(
      this,
      FILTER_COLUMN.CLIENT_USER,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.appliedFilters = observable({});
    this.appliedSorts = observable({});
    this.appliedSortsOrder.length = 0;
    this.isFiltered = false;
    this.applyDefaultSort();

    return this.pagination.goFetch(null);
  }

  filterRateCards() {
    return this.pagination.goFetch(null);
  }

  getSelectedRateCards(): Array<string> {
    const rateCards = this.rateCardsViewState;

    let selectedRateCards = [];

    rateCards.forEach((value, key) => {
      if (value.selected) {
        selectedRateCards.push(key);
      }
    });

    return selectedRateCards;
  }

  getSelectedRateCardName() {
    const rateCards = this.rateCardsViewState;

    let selectedRateCards = [];

    rateCards.forEach((value, key) => {
      if (value.selected) {
        console.log(this.rateCards);
        for (var idx = 0; idx < this.rateCardsView.length; idx++) {
          console.log(this.rateCardsView[idx]);
          if (this.rateCardsView[idx].ratecardId === key) {
            selectedRateCards.push(this.rateCards[idx].name);
          }
        }
      }
    });

    return selectedRateCards;
  }

  getFirstSelectedRateCard() {
    for (let j = 0; j < this.rateCardsView.length; j++) {
      if (this.rateCards[j].viewState.selected) return this.rateCards[j];
    }

    return null;
  }

  async getRateCards(pageQuery: PageQuery): Promise<PaginationInfo> {
    let params: string[] = pageQuery.params;
    let args = pageQuery.args;
    let variables = pageQuery.variables;
    let filtersCriteria: string[] = [];

    let sortCriteria: string[] = [];
    consolidateAppliedSorts(this.appliedSorts, sortCriteria);

    consolidateAppliedFilters(this.appliedFilters, params, filtersCriteria, variables);

    // NOTE: applied filters could override default filters, handle this if needed
    consolidateAppliedFilters(this.defaultFilters, params, filtersCriteria, variables);

    if (this.requestFrom) {
      params.push("$hasSearch: Boolean");
      params.push("$hasBuyRate: Boolean");
      filtersCriteria.push("hasSearch: $hasSearch");
      filtersCriteria.push("hasBuyRate: $hasBuyRate");
      variables.hasSearch = true;
      variables.hasBuyRate = true;
    }

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");
    const queryFiltersCriteria = filtersCriteria.join(", ");
    const querySortCriteria = sortCriteria.join(", ");

    const query = `
    query rateCardList (${queryParams}) {
        viewer {
          user{
            firstName
            lastName
            userId
            username
            email
          }
          allRateCards(${queryArgs}, filters: { ${queryFiltersCriteria} }, order: [${querySortCriteria}], section: ADMIN){
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            totalCount
            edges {
              node {
                ratecardId
                shared
                tags{
                  name
                  tagId
                }
                owner {
                  firstName
                  lastName
                  email
                  userId
                  termsOfAgreement
                }
                name
                createdDate
                updateDate
                searchCount
                locationCount
              }
            }
          }
        }
      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting Rate Cards", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Rate Card query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getRateCards--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get Rate Cards query", res)) {
        // TODO: Display user friendly error message
        return {
          totalCount: 0,
          startCursor: "",
          endCursor: "",
        };
      }

      this.currentUser = new CurrentUser(this, res.data.viewer.user);

      // // $FlowFixMe: Type the res value once we abstract gql calls.
      const cards = res.data.viewer.allRateCards.edges;

      this.rateCardCounts = res.data.viewer.allRateCards.totalCount;
      // // TODO: Deserialize this properly...
      this.rateCards = cards.map((card) => {
        const rateCard = new RateCard(this, card.node);

        // rateCard.searches = card.node.searches.totalCount;
        // rateCard.locations = card.node.locations;
        if (!this.rateCardsViewState.has(rateCard.ratecardId)) {
          this.rateCardsViewState.set(rateCard.ratecardId, {
            selected: this.allSelected,
            editing: this.isEditing,
            expanded: false,
          });
        } else {
          const selectedValue = this.allSelected
            ? true
            : this.rateCardsViewState.get(rateCard.ratecardId).selected;

          this.rateCardsViewState.set(rateCard.ratecardId, {
            selected: selectedValue,
            editing: this.isEditing,
            expanded: false,
          });
        }

        rateCard.viewState = this.rateCardsViewState.get(rateCard.ratecardId);

        return rateCard;
      });

      return {
        totalCount: res.data.viewer.allRateCards.totalCount,
        startCursor: res.data.viewer.allRateCards.pageInfo.startCursor,
        endCursor: res.data.viewer.allRateCards.pageInfo.endCursor,
      };
    });
  }
}

export default class RateCardListStore extends RateCardListComponentStore {
  showHelpModal: boolean;
  helpModal: ModalState;
  networkShareUsers: NetworkState;

  showHelp: () => void;
  hideHelp: () => void;
  fetchAPI: FetchAPI;

  moveRateCardsModal: ModalState;
  moveRateCardsNetwork: NetworkState;
  selectUserForRateCardsMove: () => void;
  getUsers: (PageQuery) => Promise<PaginationInfo>;
  users: $ReadOnlyArray<UserNode>;
  usersPagination: PaginationState;
  userSearch: string;
  selectedUserId: number;
  moveRateCardsToSelectedUser: () => Promise<void>;
  cancelRateCardsMove: () => void;
  onUserSearchValueChange: (string) => void;
  onUserSearchSubmit: (string) => void;
  moveRateCardsError: React.Node;
  moveRateCardsLoadingMessage: React.Node;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI) {
    super(fetchGraphQL);

    this.fetchAPI = fetchAPI;
    // Bound early to pass into export state
    this.exportRateCards = action(this.exportRateCards.bind(this));
    // Bound early to pass into pagination state
    this.getUsers = action(this.getUsers.bind(this));

    extendObservable(this, {
      helpModal: new ModalState(),
      networkShareUsers: new NetworkState(),
      moveRateCardsModal: new ModalState(),
      moveRateCardsNetwork: new NetworkState(),
      usersPagination: new PaginationState(this.getUsers, 8),
      users: [],
      userSearch: "",
      selectedUserId: 0,
      moveRateCardsError: "",
      moveRateCardsLoadingMessage: "",
    });

    this.showHelp = action(this.showHelp.bind(this));
    this.hideHelp = action(this.hideHelp.bind(this));
    this.selectUserForRateCardsMove = action(this.selectUserForRateCardsMove.bind(this));
    this.moveRateCardsToSelectedUser = action(
      this.moveRateCardsToSelectedUser.bind(this)
    );
    this.cancelRateCardsMove = action(this.cancelRateCardsMove.bind(this));
    this.onUserSearchValueChange = action(this.onUserSearchValueChange.bind(this));
    this.onUserSearchSubmit = action(this.onUserSearchSubmit.bind(this));
  }

  showHelp() {
    this.showHelpModal = true;
  }

  hideHelp() {
    this.showHelpModal = false;
  }

  exportRateCards() {
    if (this.network.loading) {
      return;
    }

    let params = [];
    let args = [];
    let vars = {};
    //let input = {};
    let exportURL = "";
    const rateType = {};
    rateType.Annual = 2;
    rateType.Hourly = 1;
    let parameters = "";
    let exparams = "";

    if (this.allSelectedfilter) {
      consolidateAppliedFilters(this.appliedFilters, params, args, vars);
      if (vars.rateType) {
        for (var i in rateType) {
          if (rateType[i] === vars.rateType) {
            vars.rateType = i;
          }
        }
      }
      vars.exclude = this.unSelectedRateCard;
      exportURL = "ratecards/list/export/excel/";
      if (vars.nameFilterText) {
        vars.nameIContains = vars.nameFilterText;
        delete vars.nameFilterText;
      }
      parameters = JSON.stringify(vars);
      if (parameters === "") {
        exparams = `{"section":"admin" , "rateCardFilters": {${parameters}}}`;
      } else {
        exparams = `{"section":"admin" , "rateCardFilters": ${parameters}}`;
      }
    } else {
      const selectedRateCards = this.getSelectedRateCards();
      if (!selectedRateCards || !selectedRateCards.length) {
        console.error("Cannot export RateCards: No RateCards selected");
        return;
      }
      vars.only = selectedRateCards;
      exportURL = "ratecards/list/export/excel/";
      parameters = JSON.stringify(vars);
      if (parameters === "") {
        exparams = `{"section":"admin" , "rateCardFilters": {${parameters}}}`;
      } else {
        exparams = `{"section":"admin" , "rateCardFilters": ${parameters}}`;
      }
    }

    this.fetchAPI(exportURL, JSON.parse(exparams))
      .then((res) => {
        window.location.href = res.data.url;
        this.handleStopEdit();
      })
      .catch((e) => {
        console.error("Error downloading excel", e);
        throw e;
      });
  }

  selectUserForRateCardsMove() {
    this.moveRateCardsModal.showModal();
    if (!this.moveRateCardsNetwork.loading) {
      this.usersPagination.goFetch();
    }
  }

  async getUsers(pageQuery: PageQuery): Promise<PaginationInfo> {
    let params: string[] = pageQuery.params;
    let args = pageQuery.args;
    let variables = pageQuery.variables;

    if (this.userSearch !== "") {
      params.push("$search: String");
      args.push("search: $search");
      variables.search = this.userSearch;
    }

    let queryParams = params.join(", ");
    let queryArgs = args.join(", ");

    queryParams = queryParams.replace("$offset: Int!", "$after: String");
    queryArgs = queryArgs.replace("offset: $offset", "after: $after");
    variables.after = pageQuery.afterCursor;
    delete variables["offset"];

    const query = `
    query authorList(${queryParams}) {
      viewer {
        authors (${queryArgs}, order: [{field: FIRST_NAME}, {field: LAST_NAME}], section: ADMIN) {
          totalCount
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            node {
              userId
              firstName
              lastName
              username
              email
            }
          }
        }
      }
    }
    `;

    this.users = [];
    this.moveRateCardsNetwork.loading = true;
    this.moveRateCardsLoadingMessage = "Loading users...";
    this.moveRateCardsError = "";
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.moveRateCardsNetwork.loading = false;
      if (axios.isCancel(e)) {
        return e;
      }

      this.moveRateCardsNetwork.handleError("Getting users", e);
      if (res !== null) {
        this.moveRateCardsNetwork.logGraphQLError("Get users query", res);
      }

      this.moveRateCardsError = (
        <div>
          <p className="bold">Unexpected Error</p>
          <p className="no-margin">
            Something went wrong. We're unable to load the requested information at this
            time. Please try again later.
          </p>
        </div>
      );
      return e;
    }

    return runInAction("selectUserForRateCardsMove--success", () => {
      this.moveRateCardsNetwork.loading = false;
      this.moveRateCardsNetwork.error = null;
      if (this.moveRateCardsNetwork.logGraphQLError("Get users query", res)) {
        this.moveRateCardsError = (
          <div>
            <p className="bold">Unexpected Error</p>
            <p className="no-margin">
              Something went wrong. We're unable to load the requested information at this
              time. Please try again later.
            </p>
          </div>
        );
        return;
      }

      const authors = res?.data?.viewer?.authors || {};
      const userEdges = res?.data?.viewer?.authors?.edges || [];
      this.users = userEdges.map(({ node }) => {
        return new UserNode(node, node.userId === this.selectedUserId);
      });

      return {
        totalCount: authors.totalCount || 0,
        startCursor: authors.pageInfo?.startCursor || "",
        endCursor: authors.pageInfo?.endCursor || "",
      };
    });
  }

  async moveRateCardsToSelectedUser(): Promise<void> {
    if (this.moveRateCardsNetwork.loading) return;

    const query = `
    mutation moveRateCards($input: MoveRateCardsToUserInput!) {
      moveRateCardsToUser(input: $input) {
        ok
        errors {
          __typename
          ...on NoPermissionError {
            message
          }
          ...on UserDoesNotExistsError {
            message
          }
          ...on RateCardIdDoesNotExistError {
            message
          }
        }
      }
    }
    `;

    const variables = {
      input: {
        rateCardIds: this.getSelectedRateCards(),
        toUserId: this.selectedUserId,
      },
    };

    this.moveRateCardsNetwork.loading = true;
    this.moveRateCardsLoadingMessage =
      "Moving selected Rate Cards. This operation could take a few minutes...";
    this.moveRateCardsError = "";
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.moveRateCardsNetwork.loading = false;
      if (axios.isCancel(e)) {
        return e;
      }

      this.moveRateCardsNetwork.handleError("Moving rate cards", e);
      if (res !== null) {
        this.moveRateCardsNetwork.logGraphQLError("Move rate cards query", res);
      }

      this.moveRateCardsError = (
        <div>
          <p className="bold">Unexpected Error</p>
          <p className="no-margin">
            Something went wrong. We're unable to complete the requested operation at this
            time. Please try again later.
          </p>
        </div>
      );
      return e;
    }

    return runInAction("moveRateCardsToSelectedUser--success", () => {
      this.moveRateCardsNetwork.loading = false;
      this.moveRateCardsNetwork.error = null;
      if (this.moveRateCardsNetwork.logGraphQLError("Move rate cards query", res)) {
        const errors = res?.data?.moveRateCardsToUser?.errors || [];
        const noPermission = errors.find((e) => e.__typename === "NoPermissionError");
        const userError = errors.find((e) => e.__typename === "UserDoesNotExistsError");
        const rateCardError = errors.find(
          (e) => e.__typename === "RateCardIdDoesNotExistError"
        );
        if (noPermission) {
          this.moveRateCardsError = (
            <div>
              <p className="bold">Permission Denied</p>
              <p className="no-margin">{noPermission.message}</p>
            </div>
          );
        } else if (userError) {
          this.moveRateCardsError = (
            <div>
              <p className="bold">Permission Denied</p>
              <p className="no-margin">{userError.message}</p>
            </div>
          );
        } else if (rateCardError) {
          this.moveRateCardsError = (
            <div>
              <p className="bold">Permission Denied</p>
              <p className="no-margin">{rateCardError.message}</p>
            </div>
          );
        } else {
          this.moveRateCardsError = (
            <div>
              <p className="bold">Unexpected Error</p>
              <p className="no-margin">
                Something went wrong. We're unable to complete the requested operation at
                this time. Please try again later.
              </p>
            </div>
          );
        }
        return;
      }

      this.moveRateCardsModal.hideModal();
      this.selectedUserId = 0;
      this.userSearch = "";
      this.handleStopEdit();
      this.pagination.goFetch(this.pagination.currentPage);
    });
  }

  cancelRateCardsMove() {
    this.moveRateCardsModal.hideModal();
    this.selectedUserId = 0;
    this.userSearch = "";
  }

  onUserSearchValueChange(value: string) {
    this.userSearch = value;
  }

  onUserSearchSubmit(value: string) {
    this.usersPagination.goFetch(0);
  }
}
