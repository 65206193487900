// @flow
import { action, observable, extendObservable, runInAction } from "mobx";
import moment from "moment";
import axios from "axios";
import NetworkState from "./NetworkState";
import CCCCountry from "./CCCCountry";

export default class JobLibraryTitleRequest {
  network: NetworkState;
  store: Object;
  id: ?number;
  title: ?string;
  description: ?string;
  userMessage: ?string;
  status: ?string;
  statusVerbose: ?string;
  created: ?any;
  createdDisplay: ?string;
  modified: ?any;
  modifiedDisplay: ?string;
  viewState: ?{ selected?: boolean, expanded?: boolean, editing?: boolean };
  totalCertifiedCountries: ?number;
  certifiedCountries: ?(CCCCountry[]);

  toggleEdit: () => void;
  destroy: () => void;
  toggleExpanded: () => void;
  getDescription: () => void;
  toggleSelected: (SyntheticMouseEvent<HTMLElement>, ?any, ?any, ?boolean) => void;

  constructor(store: Object, payloadData: Object) {
    this.store = store;
    this.id = null;
    this.title = null;
    this.description = null;
    this.userMessage = null;
    this.status = null;
    this.statusVerbose = null;
    this.created = null;
    this.createdDisplay = null;
    this.modified = null;
    this.modifiedDisplay = null;
    this.viewState = null;
    this.network = new NetworkState();
    this.totalCertifiedCountries = null;
    this.certifiedCountries = null;

    if (payloadData) {
      let certifiedCountries = [];
      if (payloadData.certifiedCountries) {
        certifiedCountries = payloadData.certifiedCountries.edges.map((edge) => {
          return new CCCCountry(store, edge.node);
        });
      }

      this.id = payloadData.databaseId || null;
      this.status = payloadData.status || null;
      this.statusVerbose = payloadData.statusVerbose || null;
      this.title = payloadData.title || null;
      this.description = payloadData.description || null;
      this.userMessage = payloadData.userMessage || null;
      this.created = moment(payloadData.created);
      this.createdDisplay = `${this.created.format("MM/DD/YYYY")}`;
      this.modified = moment(payloadData.modified);
      this.modifiedDisplay = `${this.modified.format("MM/DD/YYYY")}`;
      this.totalCertifiedCountries = payloadData.totalCertifiedCountries;
      this.certifiedCountries = certifiedCountries;
    }

    extendObservable(this, {
      description: this.description || "",
      userMessage: this.userMessage || "",
      certifiedCountries: null,
      viewState: observable({
        selected: false,
        expanded: false,
        editing: false,
      }),
    });

    this.toggleEdit = action(this.toggleEdit.bind(this));
    this.toggleSelected = action(this.toggleSelected.bind(this));
    this.destroy = action(this.destroy.bind(this));
    this.toggleExpanded = action(this.toggleExpanded.bind(this));
    this.getDescription = action(this.getDescription.bind(this));
  }

  toggleExpanded() {
    if (!this.viewState) return;

    this.viewState.expanded = !this.viewState.expanded;
    if (this.viewState.expanded) {
      this.getDescription();
    }
  }

  toggleEdit() {
    const viewState = this.store.clientJobTitleRequestsViewState.get(this.id);

    viewState.editing = !viewState.editing;
  }

  toggleSelected(
    e: SyntheticMouseEvent<HTMLElement>,
    ignore1?: ?any = null,
    ignore2?: ?any = null,
    setValue?: ?boolean = null
  ) {
    const viewState = this.store.clientJobTitleRequestsViewState.get(this.id);

    if (setValue !== null) {
      viewState.selected = setValue;
      return;
    } else {
      viewState.selected = !viewState.selected;

      if (!viewState.selected && this.store.allSelected) {
        this.store.allSelected = false;
      }
    }

    if (!this.store.allowMultipleItemSelection) {
      // deselect all other rate cards
      this.store.clientJobTitleRequestsViewState.forEach((viewState) => {
        if (this.viewState === viewState) return;

        viewState.selected = false;
      });
    }
  }

  destroy() {
    this.store.clientJobTitleRequests.remove(this);
  }

  toJS() {
    return {
      id: this.id,
      name: this.title,
    };
  }

  async getDescription() {
    if (this.network.loading) return;

    if (this.description) return;

    const query = `
    query getDescription($databaseId: ID!) {
      clientJobTitleRequest(databaseId: $databaseId) {
        description
        userMessage
        certifiedCountries {
          edges {
            node {
              databaseId
              title 
              iso3166Alpha2
            }
          }
        }
      }
    }
    `;
    const variables = {
      databaseId: this.id,
    };

    this.network.loading = true;

    let payload = null;
    try {
      payload = await this.store.fetchTasteGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        // console.log('Request Canceled ClientJobTitleRequestListStore');
        return e;
      }

      this.network.handleError("Getting Client Job Title Request Description", e);
      if (payload !== null) {
        this.network.logGraphQLError(
          "Get Client Job Title Request Description query",
          payload
        );
      }

      // TODO: Display user friendly error message
      return e;
    }

    runInAction("getDescription--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (
        this.network.logGraphQLError(
          "Get Client Raw Job Title Description query",
          payload
        )
      ) {
        // TODO: Display user friendly error message
        return { totalCount: 0, startCursor: "", endCursor: "" };
      }

      if (!payload) return;

      this.description = payload.data.clientJobTitleRequest.description;
      this.userMessage = payload.data.clientJobTitleRequest.userMessage;
      if (payload.data.clientJobTitleRequest.certifiedCountries) {
        this.certifiedCountries =
          payload.data.clientJobTitleRequest.certifiedCountries.edges.map((edge) => {
            return new CCCCountry(this.store, edge.node);
          });
      }
    });
  }

  static fromJS(store: Object, object: Object) {
    return new JobLibraryTitleRequest(store, object);
  }
}
