import React from "react";

import JobFeedbackDetailsBlock from "./JobFeedbackDetailsBlock";

import { RatesWithFeedbackPreview } from "../Rates";

import type { JobFeedbackDetailsBlockProps } from "./JobFeedbackDetailsBlock";
import type { RateResultsOrderedMap, RatesFeedbackOrderedMap } from "../../types";

type ClassicDetailsContentProps = JobFeedbackDetailsBlockProps & {
  rateResults: RateResultsOrderedMap;
  ratesFeedback: RatesFeedbackOrderedMap;
};

export default function ClassicDetailsContent(props: ClassicDetailsContentProps) {
  const {
    title,
    jobIsRegionBased,
    jobLocationString,
    jobRegionString,
    currencyCode,
    currencyName,
    industries,
    rateTypeLabel,
    validatorString,
    validationTimestamp,
    description,
    questions,
    questionsFeedback,
    rateResults,
    ratesFeedback,
  } = props;

  return (
    <JobFeedbackDetailsBlock
      title={title}
      jobIsRegionBased={jobIsRegionBased}
      jobLocationString={jobLocationString}
      jobRegionString={jobRegionString}
      currencyCode={currencyCode}
      currencyName={currencyName}
      industries={industries}
      rateTypeLabel={rateTypeLabel}
      validatorString={validatorString}
      validationTimestamp={validationTimestamp}
      description={description}
      questions={questions}
      questionsFeedback={questionsFeedback}
    >
      {rateResults.toArray().map((rateResult) => {
        const rateResultId = rateResult.get("id");
        return (
          <RatesWithFeedbackPreview
            key={rateResultId}
            rateResult={rateResult}
            rateFeedback={ratesFeedback.get(rateResultId)}
          />
        );
      })}
    </JobFeedbackDetailsBlock>
  );
}

ClassicDetailsContent.displayName = "ClassicDetailsContent";
