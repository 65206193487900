// @flow
import * as React from "react";
import { observer } from "mobx-react";
import SearchBox from "../ratecards/components/SearchBox";
import NotificationsList from "./components/NotificationsList";
import type MobXStore from "../../stores/mobx/MobXStore";
import Button from "../../components/lib/Button";

type Props = {
  store: MobXStore,
};

const List = (props: Props) => {
  const store = props.store.notificationsListStore;

  let buttonMark = "";

  if (store.unreadNotificationCount) {
    buttonMark = (
      <div className="btn-group pull-right rc-center">
        <Button
          color="brand"
          onClick={(e) => {
            store.markAllNotificationAsRead();
          }}
        >
          Mark all as read({store.unreadNotificationCount})
        </Button>
      </div>
    );
  }

  return (
    <div className="view list">
      <div className="rc-container bring-forward">
        <div className="header-action-bar">
          <h2>Notifications</h2>
          <p className="subtext">Review notifications</p>
          <div className="clearfix" />
        </div>
        <div className="header-action-bar marginTopzero">
          <div className="pull-left">
            <SearchBox
              value={store.notificationFilter.textToLookFor}
              onChange={store.notificationFilter.onTextToLookForChange}
              onSearch={store.notificationFilter.onApply}
              style={{
                width: "260px",
              }}
            />
          </div>
          {buttonMark}
          <div className="clearfix" />
        </div>
        <NotificationsList store={store} />
      </div>
    </div>
  );
};

export default observer(List);
