/**
 * @generated SignedSource<<0ec32136efd24dff8bccca8c099bbc80>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AuthorFiltersInput = {
  clientId?: string | null;
  emailIContains?: string | null;
  exclude?: ReadonlyArray<string | null> | null;
  firstNameIContains?: string | null;
  isActive?: boolean | null;
  lastNameIContains?: string | null;
  only?: ReadonlyArray<string | null> | null;
  usernameIContains?: string | null;
};
export type FilterAndSortCreatedBy2Query$variables = {
  count?: number | null;
  cursor?: string | null;
  filters?: AuthorFiltersInput | null;
};
export type FilterAndSortCreatedBy2Query$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"FilterAndSortCreatedBy2_viewer">;
  } | null;
};
export type FilterAndSortCreatedBy2Query = {
  response: FilterAndSortCreatedBy2Query$data;
  variables: FilterAndSortCreatedBy2Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v3 = {
  "kind": "Variable",
  "name": "filters",
  "variableName": "filters"
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v3/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Literal",
    "name": "order",
    "value": [
      {
        "direction": "ASC",
        "field": "USERNAME"
      }
    ]
  },
  {
    "kind": "Literal",
    "name": "section",
    "value": "ADMIN"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FilterAndSortCreatedBy2Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v3/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "FilterAndSortCreatedBy2_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "FilterAndSortCreatedBy2Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "AuthorNodeConnection",
            "kind": "LinkedField",
            "name": "authors",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AuthorNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuthorNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "userId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "username",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "filters": [
              "filters"
            ],
            "handle": "connection",
            "key": "FilterAndSortCreatedBy_authors",
            "kind": "LinkedHandle",
            "name": "authors"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3ccc9304ca2768107fe4831e13f68f37",
    "id": null,
    "metadata": {},
    "name": "FilterAndSortCreatedBy2Query",
    "operationKind": "query",
    "text": "query FilterAndSortCreatedBy2Query(\n  $filters: AuthorFiltersInput\n  $count: Int\n  $cursor: String\n) {\n  viewer {\n    ...FilterAndSortCreatedBy2_viewer_4DMzEc\n  }\n}\n\nfragment FilterAndSortCreatedBy2_viewer_4DMzEc on Viewer {\n  authors(first: $count, after: $cursor, filters: $filters, order: [{direction: ASC, field: USERNAME}], section: ADMIN) {\n    edges {\n      node {\n        id\n        userId\n        firstName\n        lastName\n        username\n        email\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "87d46ccabca7a893dd5853bc51ac4cab";

export default node;
