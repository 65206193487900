// @flow

import R from "ramda";
import { extendObservable, action, runInAction, computed, observable } from "mobx";
import axios from "axios";
import moment from "moment";
// import RateCard from '../../models/RateCard';
import JobList from "../../../models/JobList";
import CurrentUser from "../../../models/User";
import {
  JobFilter,
  CreatedByFilter,
  CreatedOnFilter,
  TagsFilter,
  UserFilter,
  ClientUserFilter,
} from "../../../models/FilterState";
import NetworkState from "../../../models/NetworkState";
import PaginationState from "../../../models/PaginationState";
import FilterObject, { FILTER_COLUMN } from "../../../models/Filter";
import Sort, { SORT_DIRECTION } from "../../../models/Sort";
import ModalState from "../../../models/ModalState";
import { consolidateAppliedFilters, consolidateAppliedSorts } from "../SupportFunctions";
import type { PageQuery, PaginationInfo } from "../../../models/PaginationState";
import type { FilterColumn } from "../../../models/Filter";
import type { GraphQLQuery } from "../../../models/GraphQL";
import ExportOptionsState from "../../../models/ExportOptionsState";
import ExportOptions, { FILE_TYPE, EXPORT_TYPE } from "../../../models/ExportOptions";
import type { FetchGraphQL, FetchAPI } from "../../../App";

function mapJobTitles(res) {
  var subtitlewithoutJobLabel = "(stock title)";
  var subtitleWithJobLabel = "(via My Job Labels)";

  function applyJobTitles(jobTitle) {
    if (jobTitle.isJobLabel) {
      jobTitle.title = jobTitle.title + " " + subtitleWithJobLabel;
    } else {
      jobTitle.title = jobTitle.title + " " + subtitlewithoutJobLabel;
    }
  }
  res.data.viewer.jobTitles.results.map(applyJobTitles);

  return res;
}
const ownersCriteriaQuery = `
query authorList {
  viewer {
    authors (section : ADMIN){
      edges {
        node {
          userId
          firstName
          lastName
          username
          email
        }
      }
    }
  }
}
`;

const tagsFilterCriteria = `
query getTags {
 viewer{
   tagsFilterCriteria{
     tagnames(contentType: JOB_LIBRARY, section : ADMIN){
       tag
     }
   }
 }
}
`;

const userCriteriaQuery = `
query authorList {
  viewer {
    authors(section : ADMIN) {
      edges {
        node {
          userId
          firstName
          lastName
          username
          email
        }
      }
    }
  }
}
`;

const clientUserCriteriaQuery = `
query allClients {
  viewer {
    allClients(order : [{direction : ASC, field: NAME}]) {
      edges {
        node {
          clientId
          name
        }
      }
    }
  }
}
`;

export class JobLabelListComponentAdminStore {
  network: NetworkState;
  pagination: PaginationState;

  jobsList: JobList[];
  jobsListViewState: Object;
  jobsListView: JobList[];
  totalCount: any;

  currentUser: CurrentUser;
  isEditing: ?boolean;
  allOnPageSelected: boolean;
  allSelected: boolean;
  allowMultipleItemSelection: boolean;

  jobFilter: JobFilter;
  createdByFilter: CreatedByFilter;
  createdOnFilter: CreatedOnFilter;
  tagsFilter: TagsFilter;

  defaultFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedSorts: {
    [key: FilterColumn]: Sort,
  };
  appliedSortsOrder: Array<FilterColumn>;
  isFiltered: boolean;
  isTagView: ?boolean;
  getJobsList: (PageQuery) => Promise<PaginationInfo>;
  applyDefaultFilter: (FilterColumn, FilterObject) => void;
  handleStartEdit: () => void;
  handleStopEdit: () => void;
  toggleSelectAllPage: (Object) => void;
  selectAllPage: (Event) => void;
  deselectAllPage: (Event) => void;
  clearAllSelections: () => void;
  getSelectedjobsList: () => Array<string>;
  getFirstSelectedJobs: () => Object;
  toDateRange: () => void;
  getFilterCriteriaQuery: (FilterColumn) => GraphQLQuery;
  processFilterCriteria: (FilterColumn, Object) => Array<Object>;
  applyFilter: (FilterColumn, FilterObject) => void;
  removeFilter: (FilterColumn) => void;
  applySort: (FilterColumn, Sort) => void;
  removeSort: (FilterColumn) => void;
  filterjobsList: () => void;
  clearFilters: () => void;
  applyDefaultSort: () => void;
  setTagViewFn: () => void;
  selectAllOnPageItem: () => void;
  fetchGraphQL: FetchGraphQL;
  exportUrl: string;

  constructor(fetchGraphQL: FetchGraphQL) {
    this.fetchGraphQL = fetchGraphQL;

    // NOTE: Bound early to pass into pagination & filter state
    this.getJobsList = action(this.getJobsList.bind(this));

    this.getFilterCriteriaQuery = action(this.getFilterCriteriaQuery.bind(this));
    this.processFilterCriteria = action(this.processFilterCriteria.bind(this));
    this.applyFilter = action(this.applyFilter.bind(this));
    this.applySort = action(this.applySort.bind(this));
    this.removeFilter = action(this.removeFilter.bind(this));
    this.removeSort = action(this.removeSort.bind(this));
    this.toDateRange = action(this.toDateRange.bind(this));
    this.exportUrl = "job-labels/list/export/excel/";

    extendObservable(this, {
      network: new NetworkState(),

      pagination: new PaginationState(this.getJobsList),
      jobsList: [],
      totalCount: 0,
      jobsListViewState: observable.map({}),
      jobsListView: computed(() => {
        return this.jobsList.map((job) => {
          if (this.jobsListViewState.has(job.jobId)) {
            job.viewState = this.jobsListViewState.get(job.jobId);

            return job;
          }

          return job;
        });
      }),
      isEditing: null, // we start with null so some view elements be hidden initially
      isFiltered: false,
      allSelected: false,
      allowMultipleItemSelection: true,
      selectedCount: computed(() => {
        const selectedValues = this.jobsListView.map((job) => job.viewState.selected);

        if (this.allSelected) {
          return this.pagination.totalCount;
        }

        let count = 0;

        selectedValues.forEach((v) => {
          if (v) {
            count += 1;
          }
        });

        return count;
      }),
      allOnPageSelected: computed(() => {
        const allTrue = R.all(R.equals(true));
        const selectedValues = this.jobsListView.map((job) => job.viewState.selected);

        if (selectedValues.length === 0) {
          return false;
        }

        return allTrue(selectedValues);
      }),
      defaultFilters: {},
      appliedFilters: {},
      appliedSorts: {},
      appliedSortsOrder: observable.shallow([]),
      jobFilter: new JobFilter(
        this,
        FILTER_COLUMN.JOB_LABEL,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      createdByFilter: new CreatedByFilter(
        this,
        FILTER_COLUMN.CREATED_BY,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      createdOnFilter: new CreatedOnFilter(
        this,
        FILTER_COLUMN.DATE_RANGE,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      tagsFilter: new TagsFilter(
        this,
        FILTER_COLUMN.TAGS,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      userFilter: new UserFilter(
        this,
        FILTER_COLUMN.USER,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      clientUserFilter: new ClientUserFilter(
        this,
        FILTER_COLUMN.CLIENT_USER,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),

      isTagView: null,
    });

    this.currentUser = null;

    this.applyDefaultFilter = action(this.applyDefaultFilter.bind(this));
    this.handleStartEdit = action(this.handleStartEdit.bind(this));
    this.handleStopEdit = action(this.handleStopEdit.bind(this));
    this.toggleSelectAllPage = action(this.toggleSelectAllPage.bind(this));
    this.selectAllPage = action(this.selectAllPage.bind(this));
    this.deselectAllPage = action(this.deselectAllPage.bind(this));
    this.clearAllSelections = action(this.clearAllSelections.bind(this));
    this.getSelectedjobsList = action(this.getSelectedjobsList.bind(this));
    this.getFirstSelectedJobs = action(this.getFirstSelectedJobs.bind(this));

    this.filterjobsList = action(this.filterjobsList.bind(this));
    this.clearFilters = action(this.clearFilters.bind(this));
    this.applyDefaultSort = action(this.applyDefaultSort.bind(this));
    this.setTagViewFn = action(this.setTagViewFn.bind(this));
    this.selectAllOnPageItem = action(this.selectAllOnPageItem.bind(this));

    this.applyDefaultSort();
  }

  setTagViewFn() {
    this.isTagView = true;
    this.isEditing = true;
  }

  applyDefaultSort() {
    this.createdOnFilter.sortState.direction = SORT_DIRECTION.DESC;
    this.createdOnFilter.sort = this.createdOnFilter.buildQuerySort();
    this.applySort(this.createdOnFilter.column, this.createdOnFilter.sort);
  }

  handleStartEdit() {
    this.isEditing = true;
    this.jobsListView.forEach((job) => job.toggleEdit());
  }

  handleStopEdit() {
    this.isEditing = false;
    this.allSelected = false;
    this.jobsListViewState.forEach((viewState) => {
      viewState.selected = false;
      viewState.editing = false;
    });
  }

  toggleSelectAllPage(e: Object) {
    if (!this.allowMultipleItemSelection) return;

    const setValue = !this.allOnPageSelected;

    this.jobsListView.forEach((job) => {
      job.toggleSelected(e, null, null, setValue);
    });

    // When All items selected flag is up, clear selection
    if (setValue === false && this.allSelected) this.allSelected = false;
  }

  selectAllPage(e: Event) {
    this.allSelected = true;
  }

  selectAllOnPageItem(e: Event) {
    this.jobsListView.forEach((job) => {
      job.toggleSelected(e, null, null, true);
    });
  }

  deselectAllPage(e: Event) {
    this.jobsListView.forEach((job) => {
      job.toggleSelected(e, null, null, false);
    });

    this.allSelected = false;
  }

  clearAllSelections() {
    this.isTagView = false;
    //  this.isEditing = false;
    this.allSelected = false;
    this.jobsListViewState.forEach((value) => {
      value.selected = false;
      //value.editing = false;
    });
  }

  getFilterCriteriaQuery(column: FilterColumn): GraphQLQuery {
    switch (column) {
      case FILTER_COLUMN.CREATED_BY:
        return {
          query: ownersCriteriaQuery,
          variables: {},
        };

      case FILTER_COLUMN.TAGS:
        return {
          query: tagsFilterCriteria,
          variables: {},
        };
      case FILTER_COLUMN.USER:
        return {
          query: userCriteriaQuery,
          variables: {},
        };

      case FILTER_COLUMN.CLIENT_USER:
        return {
          query: clientUserCriteriaQuery,
          variables: {},
        };

      default:
        return null;
    }
  }

  processFilterCriteria(column: FilterColumn, payload: Object): ?Array<Object> {
    switch (column) {
      case FILTER_COLUMN.CREATED_BY:
        const owners: [
          {
            userId: string,
            username: string,
          }
        ] = payload.data.viewer.authors.edges;
        let processedOwners = observable.map({});
        owners.forEach((owner) => {
          processedOwners.set(String(owner.node.userId), {
            id: String(owner.node.userId),
            ...owner,
          });
        });

        return processedOwners;

      case FILTER_COLUMN.TAGS:
        const tags: [
          {
            tag: string,
          }
        ] = payload.data.viewer.tagsFilterCriteria.tagnames;
        let processedTag = observable.map({});
        tags.forEach((tag, index) => {
          processedTag.set(String(index), {
            id: String(index),
            ...tag,
          });
        });
        return processedTag;

      case FILTER_COLUMN.USER:
        const users: [
          {
            userId: string,
            username: string,
          }
        ] = payload.data.viewer.authors.edges;
        let processedUsers = observable.map({});
        users.forEach((owner) => {
          processedUsers.set(String(owner.node.userId), {
            id: String(owner.node.userId),
            ...owner,
          });
        });

        return processedUsers;

      case FILTER_COLUMN.CLIENT_USER:
        const clientowners: [
          {
            clientId: string,
            name: string,
          }
        ] = payload.data.viewer.allClients.edges;
        let processedClientOwners = observable.map({});
        clientowners.forEach((owner) => {
          processedClientOwners.set(owner.node.clientId, {
            id: owner.node.clientId,
            ...owner,
          });
        });

        return processedClientOwners;

      default:
        return null;
    }
  }

  applyFilter(column: FilterColumn, filter: FilterObject) {
    this.appliedFilters[column] = filter;
    this.isFiltered = true;
  }

  applyDefaultFilter(column: FilterColumn, filter: FilterObject) {
    this.defaultFilters[column] = filter;
  }

  removeFilter(column: FilterColumn) {
    delete this.appliedFilters[column];

    let entries = Object.entries(this.appliedFilters);
    if (!entries.length) this.isFiltered = false;
  }

  applySort(column: FilterColumn, sort: Sort) {
    this.appliedSorts[column] = sort;

    const index = this.appliedSortsOrder.indexOf(column);
    if (index === -1) this.appliedSortsOrder.push(column);
  }

  removeSort(column: FilterColumn) {
    delete this.appliedSorts[column];

    const index = this.appliedSortsOrder.indexOf(column);
    if (index > -1) this.appliedSortsOrder.splice(index, 1);
  }

  clearFilters() {
    this.jobFilter = new JobFilter(
      this,
      FILTER_COLUMN.JOB_LABEL,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.createdByFilter = new CreatedByFilter(
      this,
      FILTER_COLUMN.CREATED_BY,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.createdOnFilter = new CreatedOnFilter(
      this,
      FILTER_COLUMN.DATE_RANGE,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.tagsFilter = new TagsFilter(
      this,
      FILTER_COLUMN.TAGS,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.userFilter = new UserFilter(
      this,
      FILTER_COLUMN.USER,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.clientUserFilter = new ClientUserFilter(
      this,
      FILTER_COLUMN.CLIENT_USER,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.appliedFilters = observable({});
    this.appliedSorts = observable({});
    this.appliedSortsOrder.length = 0;
    this.isFiltered = false;
    this.applyDefaultSort();

    return this.pagination.goFetch(null);
  }

  filterjobsList() {
    return this.pagination.goFetch(null);
  }

  getSelectedjobsList(): Array<string> {
    const jobsList = this.jobsListViewState;

    let selectedjobsList = [];

    jobsList.forEach((value, key) => {
      if (value.selected) {
        selectedjobsList.push(key);
      }
    });

    return selectedjobsList;
  }

  getFirstSelectedJobs() {
    for (let j = 0; j < this.jobsListView.length; j++) {
      if (this.jobsList[j].viewState.selected) return this.jobsList[j];
    }

    return null;
  }

  async getJobsList(pageQuery: PageQuery): Promise<PaginationInfo> {
    let params: string[] = pageQuery.params;
    let args = pageQuery.args;
    let variables = pageQuery.variables;
    let filtersCriteria: string[] = [];

    let sortCriteria: string[] = [];
    consolidateAppliedSorts(this.appliedSorts, sortCriteria);

    consolidateAppliedFilters(this.appliedFilters, params, filtersCriteria, variables);

    // NOTE: applied filters could override default filters, handle this if needed
    consolidateAppliedFilters(this.defaultFilters, params, filtersCriteria, variables);

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");
    const queryFiltersCriteria = filtersCriteria.join(", ");
    const querySortCriteria = sortCriteria.join(", ");

    const query = `

    query listJobs(${queryParams}){
      viewer{
      jobs(${queryArgs}, filters: { ${queryFiltersCriteria} }, order: [${querySortCriteria}], section:ADMIN){
      edges{
      node{
      jobId,
      jobLabel,
      jobTitle,
      jobTitleId,
      jobDescription,
      tags{
        name
        tagId
      }
      createdBy{
          firstName,
          lastName,
          email
      },
        created,
        modified
      }
      },
      totalCount,
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
      }
      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting Jobs Cards", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Jobs query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getJobsList--success", () => {
      this.network.loading = false;
      this.network.totalCounterror = null;
      if (this.network.logGraphQLError("Get Jobs query", res)) {
        // TODO: Display user friendly error message
        return {
          totalCount: 0,
          startCursor: "",
          endCursor: "",
        };
      }
      // this.currentUser = new CurrentUser(this, res.data.viewer.user);

      // // $FlowFixMe: Type the res value once we abstract gql calls.
      const cards = res.data.viewer.jobs.edges;

      this.totalCount = res.data.viewer.jobs.totalCount;
      // // TODO: Deserialize this properly...
      this.jobsList = cards.map((card) => {
        const job = new JobList(this, card.node);

        // job.searches = card.node.searches.totalCount;
        // job.locations = card.node.locations;

        if (!this.jobsListViewState.has(job.jobId)) {
          this.jobsListViewState.set(job.jobId, {
            selected: this.allSelected,
            editing: this.isEditing,
          });
        } else {
          const selectedValue = this.allSelected
            ? true
            : this.jobsListViewState.get(job.jobId).selected;

          this.jobsListViewState.set(job.jobId, {
            selected: selectedValue,
            editing: this.isEditing,
          });
        }

        job.viewState = this.jobsListViewState.get(job.jobId);

        return job;
      });

      return {
        totalCount: res.data.viewer.jobs.totalCount,
        startCursor: res.data.viewer.jobs.pageInfo.startCursor,
        endCursor: res.data.viewer.jobs.pageInfo.endCursor,
      };
    });
  }
}

export default class JobLabelListAdminStore extends JobLabelListComponentAdminStore {
  showHelpModal: boolean;

  joblabel: string;
  jobtitle: string;
  jobdescription: string;

  helpModal: ModalState;
  confirmUpdateRatesModal: ModalState;
  confirmUndoUpdateRatesModal: ModalState;

  exportModalState: ExportOptionsState;

  showHelp: () => void;
  hideHelp: () => void;
  deletejobsList: () => void;
  refreshjobsList: () => void;
  exportjobsList: (options: ExportOptions) => void;
  validateExportOptions: (modalState: ExportOptionsState) => void;
  viewInMap: () => void;
  changeJobLabelText: () => void;
  export: () => void;
  selectJobs: () => void;
  changeDateFormat: () => void;
  exportJobs: () => void;
  fetchAPI: FetchAPI;
  apiServerUrl: string;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI, apiServerUrl: string) {
    super(fetchGraphQL);
    this.fetchAPI = fetchAPI;

    // Bound early to pass into export state
    // this.exportjobsList = action(this.exportjobsList.bind(this));
    this.validateExportOptions = action(this.validateExportOptions.bind(this));
    this.selectJobs = action(this.selectJobs.bind(this));
    this.changeDateFormat = action(this.changeDateFormat.bind(this));
    this.exportJobs = action(this.exportJobs.bind(this));

    extendObservable(this, {
      apiServerUrl,
      helpModal: new ModalState(),
      exportModal: new ExportOptionsState(
        this.exportjobsList,
        this.validateExportOptions
      ),
      networkShareUsers: new NetworkState(),
      joblabel: "",
      jobtitle: "",
      jobdescription: "",
    });

    this.showHelp = action(this.showHelp.bind(this));
    this.hideHelp = action(this.hideHelp.bind(this));
    this.export = action(this.export.bind(this));
  }

  showHelp() {
    this.showHelpModal = true;
  }

  hideHelp() {
    this.showHelpModal = false;
  }

  validateExportOptions(modalState: ExportOptionsState) {
    if (modalState.exportOptions.fileType === FILE_TYPE.CSV) {
      modalState.disableExportTypeOptions = true;
      modalState.exportOptions.exportType = EXPORT_TYPE.DETAILED;
      modalState.info = "CSV file format will always include rates data.";
    } else {
      modalState.disableExportTypeOptions = false;
      modalState.info = null;
    }
  }

  async JobTitle(searchText) {
    const variables = {
      searchParam: searchText === undefined ? "" : searchText,
    };

    const query = `
          query jobTitleList($searchParam: String){
          viewer{
            jobTitles(search: $searchParam, page:1, pageSize:7481){
              totalCount
              results {
              slug,
              showDescription,
              isJobLabel,
              title,
              category,
              shareInfo{
                jobLabelId,
                isMine,
              },
              collection,
              id
            }
          }
          }
        }
        `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
      // should hit get request
    } catch (e) {
      console.error("Error in getting job title", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);

      return;
    }

    runInAction("JobTitle--success", () => {
      // TODO: Deserialize this properly...
      // const list = new SignupUser(this, list);
      // this.signupuser.firstName = SignupUserData.firstName
      // this.signupuser.lastName = SignupUserData.lastName
      // this.signupuser.email = SignupUserData.email
    });
    if (res.data.viewer.jobTitles.totalCount > 0) {
      res = mapJobTitles(res);
    }

    return {
      jobTitles: res.data.viewer.jobTitles.results,
    };
  }

  async JobDescription(title_id) {
    const query = `
            query jobTitleDetail{
            viewer{
              jobTitle(id: ${title_id}){
                isJobLabel,
                title,
                slug,
                collection,
                description,
                id
              }
            }
          }
        `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, null);
      // should hit get request
    } catch (e) {
      console.error("Error editing job", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);

      return;
    }

    runInAction("JobDescription--success", () => {
      this.jobdescription = res.data.viewer.jobTitle.description;

      // TODO: Deserialize this properly...
      // const list = new SignupUser(this, list);
      // this.signupuser.firstName = SignupUserData.firstName
      // this.signupuser.lastName = SignupUserData.lastName
      // this.signupuser.email = SignupUserData.email
    });

    return {
      jobDescription: res.data.viewer.jobTitle.description,
    };
  }

  changeDateFormat(date) {
    var formatted_date = new Date(date);
    var year = formatted_date.getFullYear() + "";
    var month = formatted_date.getMonth() + 1 + "";
    var day = formatted_date.getDate() + "";
    var dateFormat = year + "-" + month + "-" + day;

    return dateFormat;
  }

  selectJobs(type) {
    if (this.network.loading) {
      return;
    }

    let params = [];
    let args = [];
    let vars = {};
    let searchCriteria = "";

    if (this.allSelected) {
      consolidateAppliedFilters(this.appliedFilters, params, args, vars);
      searchCriteria = {
        searchParam: vars,
      };
    } else {
      const selectedjobsList = this.getSelectedjobsList();

      if (!selectedjobsList) {
        console.error("Cannot " + type + " jobs: No Jobs selected");
        return;
      }
      searchCriteria = {
        jobId: selectedjobsList,
      };
    }

    return searchCriteria;
  }

  export() {
    var searchCriteria = this.selectJobs("export");
    let parameters = [];
    let searchquery = "";

    if (searchCriteria.jobId !== null && searchCriteria.jobId !== undefined) {
      searchquery = searchCriteria.jobId.toString().replace(/"/g, "");
      searchquery = "&jobId=" + searchquery;
      parameters = parameters.concat(searchquery);
    }

    if (
      searchCriteria.searchParam !== null &&
      searchCriteria.searchParam !== undefined &&
      "nameFilterText" in searchCriteria.searchParam
    ) {
      searchquery = "&search=" + searchCriteria.searchParam.nameFilterText;
      parameters = parameters.concat(searchquery);
    }

    if (
      searchCriteria.searchParam !== null &&
      searchCriteria.searchParam !== undefined &&
      "fromDate" in searchCriteria.searchParam &&
      "toDate" in searchCriteria.searchParam
    ) {
      const from_Date = this.changeDateFormat(searchCriteria.searchParam.fromDate);
      const to_date = this.changeDateFormat(searchCriteria.searchParam.toDate);
      searchquery = "&fromDate=" + from_Date + "&toDate=" + to_date;
      parameters = parameters.concat(searchquery);
    }

    const adminQuery = "&section=admin";
    parameters = parameters.concat(adminQuery);

    const params = parameters.join("");

    const nameOfFile = "exported_table_" + Math.floor(Math.random() * 9999999 + 1000000);

    try {
      setTimeout(() => {
        const response = {
          file: `${this.apiServerUrl}${this.exportUrl}?fileName=${nameOfFile}${params}`,
        };
        window.location.href = response.file;
      }, 100);
    } catch (e) {
      console.error("Error downloading excel", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    this.network.loading = false;
    this.handleStopEdit();
    //this.pagination.goFetch(null);
  }

  toDateRange(date) {
    var val = true;
    if (this.createdOnFilter.fromDate) {
      val = !date.isBetween(
        this.createdOnFilter.fromDate,
        moment(new Date()),
        "days",
        "[]"
      );
    }
    return val;
  }

  exportJobs() {
    if (this.network.loading) {
      return;
    }

    let params = [];
    let args = [];
    let vars = {};
    let exportURL = "job-labels/basic/list/export/excel/";
    // Change the Filename
    var fileName = "export_jobs_" + Math.floor(Math.random() * 9999999 + 1000000);
    let parameters = "";
    let exparams = "";

    if (this.allSelected) {
      consolidateAppliedFilters(this.appliedFilters, params, args, vars);
      vars.exclude = [];
      if (vars.nameFilterText) {
        vars.jobTitleIContains = vars.nameFilterText;
        delete vars.nameFilterText;
      }
      parameters = JSON.stringify(vars);
      if (parameters === "") {
        exparams = `{"fileName": "${fileName}","section":"admin", "jobsFilters": {${parameters}}}`;
      } else {
        exparams = `{"fileName": "${fileName}","section":"admin", "jobsFilters": ${parameters}}`;
      }
    } else {
      const selectedJobs = this.getSelectedjobsList();
      if (!selectedJobs || !selectedJobs.length) {
        console.error("Cannot export Jobs: No Jobs selected");
        return;
      }
      vars.only = selectedJobs;
      parameters = JSON.stringify(vars);
      if (parameters === "") {
        exparams = `{"fileName": "${fileName}","section":"admin", "jobsFilters": {${parameters}}}`;
      } else {
        exparams = `{"fileName": "${fileName}","section":"admin","jobsFilters": ${parameters}}`;
      }
    }

    this.fetchAPI(exportURL, JSON.parse(exparams))
      .then((res) => {
        window.location.href = res.data.url;
        this.handleStopEdit();
      })
      .catch((e) => {
        console.error("Error downloading excel", e);
        throw e;
      });
  }
}
