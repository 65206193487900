import React, { ReactElement } from "react";
import Alert from "../../../../components/lib/Alert";
import Text from "../../../../components/lib/Text";
import Box from "../../../../components/lib/Box";
import { withErrorCatch } from "../../../../utils/withErrorCatch";

type Props = {
  error: Error | null;
  children: ReactElement;
};

function ErrorBoundary({ error, children }: Props) {
  if (!error) return children;

  return (
    <Box css={{ padding: "$4" }}>
      <Alert
        color="warning"
        css={{ width: 300, flexDirection: "column", display: "flex" }}
      >
        <Text noMargin as="b">
          Something went wrong.
        </Text>
        <Text>We are not able to display the location map at this moment.</Text>
        <Text>
          {error.name}: {error.message}
        </Text>
      </Alert>
    </Box>
  );
}

export const MapErrorBoundary = withErrorCatch(ErrorBoundary);
