import React from "react";
import {
  RATE_TYPES,
  RATE_TYPES_TYPE,
  JobFeedbackDataMap,
  JobFeedbackDataObject,
  JobsFeedbackDataOrderedMap,
} from "../../types";
import { emptyMap } from "../../constants";
import { useAttemptDataContext } from "../AttemptDataContext";
import { emptyOrderedMap } from "../../../../constants";
import { Set } from "immutable";
import { useReviewDataContext } from "../../../validator5K_admin/context/ReviewDataContext";

const contingentRateKeys = [
  "pay_rate_min",
  "pay_rate_max",
  "employment_taxes_percentage",
  "supplier_rewards_percentage_min",
  "supplier_rewards_percentage_max",
] as const;
const annualRateKeys = ["annual_salary_min", "annual_salary_max"] as const;

const feedbackFormRequiredFieldsMapping = {
  [RATE_TYPES.HOURLY]: contingentRateKeys,
  [RATE_TYPES.DAILY]: contingentRateKeys,
  [RATE_TYPES.WEEKLY]: contingentRateKeys,
  [RATE_TYPES.MONTHLY]: contingentRateKeys,
  [RATE_TYPES.ANNUAL]: annualRateKeys,
} as Record<RATE_TYPES_TYPE, typeof contingentRateKeys | typeof annualRateKeys>;

type FillTheBlankFeedbackStateObject = {
  jobFeedback: JobFeedbackDataMap;
};

type UpdateJobFeedbackStateFunc = <K extends keyof JobFeedbackDataObject>(
  key: K,
  value: JobFeedbackDataObject[K]
) => void;

export type FillTheBlankFeedbackContextObject = FillTheBlankFeedbackStateObject & {
  hasMissingRatesFeedback: boolean;
  updateFeedbackState: UpdateJobFeedbackStateFunc;
};

export const FillTheBlankFeedbackContext =
  React.createContext<FillTheBlankFeedbackContextObject>({
    jobFeedback: emptyMap,
    hasMissingRatesFeedback: false,
    hasUncommittedChanges: false,
    updateFeedbackState: undefined,
  } as unknown as FillTheBlankFeedbackContextObject);

export const useFillTheBlankFeedbackContext = () => {
  return React.useContext(FillTheBlankFeedbackContext);
};

export const useCreateFillTheBlankFeedbackContext =
  (): FillTheBlankFeedbackContextObject => {
    const { firstJobRateType, firstJobId } = useReviewDataContext();
    const { attemptData: initialAttemptData } = useAttemptDataContext();

    // feedback stored in local currency
    const initialJobFeedback = React.useMemo(() => {
      let jobFeedback = initialAttemptData
        .get("jobs_feedback", emptyOrderedMap as JobsFeedbackDataOrderedMap)
        .get(firstJobId, emptyMap as JobFeedbackDataMap);

      return jobFeedback;
    }, [initialAttemptData, firstJobId]);

    // state

    const [jobFeedbackState, setJobFeedbackState] =
      React.useState<JobFeedbackDataMap>(initialJobFeedback);

    const updateFeedbackState: UpdateJobFeedbackStateFunc = React.useCallback(
      (key, value) => {
        if (jobFeedbackState.get(key) !== value) {
          setJobFeedbackState((prevState) => prevState.set(key, value));
        }
      },
      [jobFeedbackState]
    );

    // effects

    // Handles the case when user skipping the whole survey and the form need to cleanup prev feedback state
    const prevInitialJobFeedbackRef = React.useRef(initialJobFeedback);
    React.useEffect(() => {
      if (
        jobFeedbackState?.size > 0 &&
        prevInitialJobFeedbackRef.current?.size > 0 &&
        initialJobFeedback?.size === 0
      ) {
        setJobFeedbackState(initialJobFeedback);
      }
      prevInitialJobFeedbackRef.current = initialJobFeedback;
    }, [initialJobFeedback, jobFeedbackState]);

    // derivatives

    const hasMissingRatesFeedback = React.useMemo(() => {
      const requiredFieldsSet = Set(feedbackFormRequiredFieldsMapping[firstJobRateType]);
      const enteredFieldsSet = Set(jobFeedbackState.filter((i) => i != null).keySeq());
      return !requiredFieldsSet.isSubset(enteredFieldsSet);
    }, [jobFeedbackState, firstJobRateType]);

    return {
      jobFeedback: jobFeedbackState,
      hasMissingRatesFeedback,
      updateFeedbackState,
    };
  };

export default FillTheBlankFeedbackContext;
