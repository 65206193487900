// @flow
import { extendObservable, action } from "mobx";
import FilterObject, { ValueSetFilter, FilterWithSort, IViewItem } from "../Filter";
import type { FilterColumn } from "../Filter";
import Sort from "../Sort";
import SortState from "../SortState";

class ActiveCriterionItem implements IViewItem {
  id: string;
  value: string;
  selected: boolean;
  Active: boolean;

  constructor(object: Object) {
    this.id = object.id;
    this.value = object.value;
    this.Active = object.Active;

    extendObservable(this, {
      selected: object.selected,
    });
  }
}

export default class ActiveFilter extends ValueSetFilter(FilterWithSort) {
  constructor(
    store: Object,
    column: FilterColumn,
    applyFilter: (FilterColumn, FilterObject) => void,
    applySort: (FilterColumn, Sort) => void,
    removeFilter: (FilterColumn) => void,
    removeSort: (FilterColumn) => void
  ) {
    super(store, column);

    this.allowMultipleItemSelection = false; // only one item selected at a time

    this.applyFilter = applyFilter;
    this.removeFilter = removeFilter;
    this.applySort = applySort;
    this.removeSort = removeSort;

    if (this.column === "active") {
      this.sortState = new SortState("FIRST_NAME");
    } else {
      this.sortState = new SortState("NAME");
    }

    this.viewItemClass = ActiveCriterionItem;

    this.column = column;

    // create criteria and view items
    this.criteria.set("1", { id: "1", value: "Active", Active: true });
    this.criteria.set("2", { id: "2", value: "Inactive", Active: false });

    extendObservable(this, {
      viewItems: this.buildViewItems(this.criteria),
    });

    this.buildQueryFilter = action(this.buildQueryFilter.bind(this));
  }

  buildQueryFilter() {
    const ActiveSelectedValues = this.viewItems
      .filter((item) => item.selected)
      .map((item) => item.Active);

    if (!ActiveSelectedValues.length) {
      // no value selected, just return
      return null;
    }

    if (ActiveSelectedValues.length > 1) {
      // NOTE: Both Yes/No selected, just return
      console.error("Unable to build Rate Type filter: More than one value selected");
      return null;
    }

    const Active = ActiveSelectedValues[0];

    if (this.filter && this.filter.queryVars.Active === Active) return this.filter; // no change

    if (this.column === "active") {
      return new FilterObject("$isActive: Boolean", "isActive: $isActive", {
        isActive: Active,
      });
    }

    return new FilterObject("$activeFlag: Boolean", "activeFlag: $activeFlag", {
      activeFlag: Active,
    });
  }
}
