import React, { useState } from "react";

import Text from "../../components/lib/Text";
import Button from "../../components/lib/Button";
import Center from "../../components/lib/Center";

const successPurchaseCompleteBlock = (
  <Center css={{ gap: "$2", padding: "$4" }}>
    <Text size="lg">Thank you, your purchase is complete!</Text>
    <Text>
      If you chose Credit Card payment, your searches will be available immediately. If
      you chose Invoice payment, you will receive an invoice from RatePoint for your
      searches within 2 business days. Your searches will be available soon after your
      invoice is paid.
    </Text>
  </Center>
);

type Props = {
  searchPurchaseCount: number;
  total: number;
};

export default function PaymentOptions(props: Props) {
  const { searchPurchaseCount, total } = props;

  const [successPurchaseComplete, setSuccessPurchaseComplete] = useState(false);

  if (successPurchaseComplete) {
    return successPurchaseCompleteBlock;
  }

  return (
    <Center css={{ gap: "$2", padding: "$4" }}>
      <Text size="lg">
        {searchPurchaseCount} searches will be added to your company balance, for ${total}
      </Text>
      <Text>Choose your payment option below:</Text>
      <Button
        size="large"
        color="success"
        onClick={() => setSuccessPurchaseComplete(true)}
      >
        Credit Card
      </Button>
    </Center>
  );
}
