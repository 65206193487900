import React from "react";

import { THead, TH, TD, TR } from "../../../../components/lib/Table";
import Text from "../../../../components/lib/Text";
import { InlineElements } from "../../../../components/lib/Inline";
import TextCrop from "../../../validator5K_admin/components/TextCrop";
import FeedbackStatusBlock from "./FeedbackStatusBlock";
import {
  decimalFormatter,
  convertToCurrency,
  compareDecimals,
  getRegionString,
  getLocationString,
} from "../../constants";
import { RATE_FEEDBACK_STATUS_TYPES } from "../../types";
import RatesTableOrderedBase, {
  RatesTableOrderedBaseProps,
  RatesTableOrderedRowBaseProps,
} from "../../../validator5K_admin/components/RatesTableOrdered/base";
import {
  getLevelRateResultsForJob,
  getLevelRatesFeedbackForJob,
  getLowHighRatesFeedbackForJob,
  getJobCurrencyData,
  LEVEL_KEYS,
} from "../../../validator5K_admin/components/RatesTableOrdered/utils";
import FeedbackButtonsBlock from "./FeedbackButtonsBlock";
import { useRateDisplayContext } from "../../../validator5K_admin/context/RateDisplayContext";

import type { JobDataMap } from "../../types";
import type { LEVEL_KEYS_TYPE } from "../../../validator5K_admin/components/RatesTableOrdered/utils";
import { useOrderedRatesFeedbackContext } from "../../context/feedback/OrderedRatesFeedbackContext";

type RateItemProps = {
  jobData: JobDataMap;
  level: LEVEL_KEYS_TYPE;
};

function RateItem(props: RateItemProps) {
  const { jobData, level } = props;

  const { jobsData: allJobsData, feedbackData: ratesFeedbackData } =
    useOrderedRatesFeedbackContext();
  const { displayCurrencyData } = useRateDisplayContext();

  const jobId = jobData.get("id");
  const levelRates = getLevelRateResultsForJob(allJobsData, jobId, level);
  const levelRatesFeedback = getLevelRatesFeedbackForJob(
    allJobsData,
    jobId,
    level,
    ratesFeedbackData
  );

  // retrieve rates and feedback values
  let payRate: number | null =
    level === LEVEL_KEYS.LOW
      ? levelRates.get("pay_rate_min")
      : levelRates.get("pay_rate_max");
  let payRateFeedback =
    level === LEVEL_KEYS.LOW
      ? levelRatesFeedback.get("pay_rate_min")
      : levelRatesFeedback.get("pay_rate_max");

  const hasFeedback =
    !!payRate && !!payRateFeedback && compareDecimals(payRate, payRateFeedback) !== 0;

  // figure out resulting currency parameters
  const currencyData = getJobCurrencyData(jobData);
  const currencyCode = currencyData.get("code");
  const currencySymbol = currencyData.get("symbol");
  const displayCurrencyCode = displayCurrencyData.get("code");
  const displayCurrencySymbol = displayCurrencyData.get("symbol");
  const isConversionRequired = !!(
    displayCurrencyCode &&
    currencyCode &&
    displayCurrencyCode !== currencyCode
  );
  const currencySymbolToDisplay = isConversionRequired
    ? displayCurrencySymbol
    : currencySymbol;

  // convert to display currency
  if (isConversionRequired) {
    if (payRate) {
      payRate = convertToCurrency(
        payRate,
        currencyData.toJS(),
        displayCurrencyData.toJS()
      );
    }
    if (payRateFeedback) {
      payRateFeedback = convertToCurrency(
        payRateFeedback,
        currencyData.toJS(),
        displayCurrencyData.toJS()
      );
    }
  }

  // rates formatting
  const payRateString = decimalFormatter(payRate, false, currencySymbolToDisplay);
  const payRateFeedbackString = decimalFormatter(
    payRateFeedback,
    false,
    currencySymbolToDisplay
  );

  return (
    <InlineElements css={{ justifyContent: "center" }}>
      {hasFeedback ? (
        <Text as="s" color="negative">
          {payRateString}
        </Text>
      ) : (
        <Text>{payRateString}</Text>
      )}
      {hasFeedback && payRateFeedbackString}
    </InlineElements>
  );
}

RateItem.displayName = "RatesBlock";

const RatesTableHead = () => (
  <THead>
    <TR>
      <TH />
      <TH>Location</TH>
      <TH>Industry</TH>
      <TH>Title</TH>
      <TH>
        Lowest&nbsp;Wage
        <br />
        (least&nbsp;amount&nbsp;of&nbsp;experience)
      </TH>
      <TH>
        Highest&nbsp;Wage
        <br />
        (most&nbsp;amount&nbsp;of&nbsp;experience)
      </TH>
    </TR>
  </THead>
);

RatesTableHead.displayName = "TableHead";

const RatesTableRow = (props: RatesTableOrderedRowBaseProps) => {
  const { jobId, jobsData } = props;

  const { feedbackData } = useOrderedRatesFeedbackContext();

  const jobData = jobsData.get(jobId);
  const jobTitle = jobData.get("title");
  const jobDescription = jobData.get("description");
  const jobIndustry = jobData.get("industry_name");

  // resolve feedback rates
  const { lowLevelRatesFeedback, highLevelRatesFeedback } = getLowHighRatesFeedbackForJob(
    jobsData,
    jobId,
    feedbackData
  );

  // resolve feedback status
  const feedbackStatus =
    lowLevelRatesFeedback.get("status") ??
    highLevelRatesFeedback.get("status") ??
    RATE_FEEDBACK_STATUS_TYPES.NOT_VALIDATED;

  // resolve feedback comment
  const feedbackComment =
    lowLevelRatesFeedback.get("comment") ?? highLevelRatesFeedback.get("comment");

  // resolve location
  const jobLocationString = getLocationString(jobData);
  const isRowRegionBased = !!jobData.get("region");
  let jobRegionString = getRegionString(jobData);
  if (
    isRowRegionBased &&
    jobRegionString &&
    jobRegionString.toLowerCase().indexOf("region") < 0
  ) {
    jobRegionString += " (region)";
  }

  const [showButtons, setShowButtons] = React.useState<boolean>(false);

  // handlers

  const handleRowOver: React.MouseEventHandler<HTMLElement> = (e) => {
    const rowElement = e.currentTarget;
    const fromElement = e.relatedTarget;
    const toElement = e.target;

    // @ts-expect-error - not sure how to reasonably fix this types mismatch, but the code is actually working
    if (rowElement.contains(toElement) && !rowElement.contains(fromElement)) {
      setShowButtons(true);
    }
  };

  const handleRowOut: React.MouseEventHandler<HTMLTableRowElement> = (e) => {
    const rowElement = e.currentTarget;
    const fromElement = e.target;
    const toElement = e.relatedTarget;

    // @ts-expect-error - not sure how to reasonably fix this types mismatch, but the code is actually working
    if (rowElement.contains(fromElement) && !rowElement.contains(toElement)) {
      setShowButtons(false);
    }
  };

  // render

  return (
    <TR onMouseOver={handleRowOver} onMouseOut={handleRowOut}>
      <TD>
        <FeedbackStatusBlock
          feedbackStatus={feedbackStatus}
          feedbackComment={feedbackComment}
        />
      </TD>
      <TD>
        <TextCrop
          mode="tooltip"
          title="Location"
          text={isRowRegionBased ? jobRegionString : jobLocationString}
          emptyStub=""
        />
      </TD>
      <TD>
        <TextCrop mode="tooltip" title="Industry" text={jobIndustry} emptyStub="" />
      </TD>
      <TD>
        <Text underline>
          <TextCrop
            mode="modal"
            title={jobTitle}
            text={jobTitle}
            fullText={jobDescription}
            emptyStub=""
          />
        </Text>
      </TD>
      <TD>
        <RateItem jobData={jobData} level={LEVEL_KEYS.LOW} />
      </TD>
      <TD css={{ position: "relative" }}>
        <RateItem jobData={jobData} level={LEVEL_KEYS.HIGH} />
        <FeedbackButtonsBlock jobId={jobId} show={showButtons} />
      </TD>
    </TR>
  );
};

function RatesTableOrderedWithFeedback(props: RatesTableOrderedBaseProps) {
  const { jobsData } = props;
  const { feedbackData } = useOrderedRatesFeedbackContext();

  return (
    <RatesTableOrderedBase
      jobsData={jobsData}
      feedbackData={feedbackData}
      tableHeadImpl={RatesTableHead}
      tableRowImpl={RatesTableRow}
    />
  );
}

RatesTableOrderedWithFeedback.displayName = "RatesTableOrderedWithFeedback";

export default RatesTableOrderedWithFeedback;
