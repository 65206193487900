// @flow
import * as React from "react";
import classNames from "classnames";
import graphql from "babel-plugin-relay/macro";
import {
  createPaginationContainer,
  commitMutation,
  QueryRenderer,
  useRelayEnvironment,
} from "react-relay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingIndicator from "../views/shared/LoadingIndicator";
import AdminSearchesSearch from "./AdminSearchesSearch";
import SearchBox from "./SearchBox";
import ToggleButton from "../views/ratecards/components/ToggleButton";
// import SortIndicator from "./ratecards/components/SortIndicator";
import Modal from "../views/ratecards/components/Modal";
// import FilterAndSort from "./FilterAndSort";
import FilterAndSortTags from "./FilterAndSortTags";
import FilterAndSortClients from "./FilterAndSortClients";
import FilterAndSortCreatedBy from "./FilterAndSortCreatedBy2";
import FilterAndSortCreatedOn from "./FilterAndSortCreatedOn";
import ExportModal from "./ExportModal";
import SessionInfo from "../models/SessionInfo";
import type { RelayPaginationProp } from "react-relay";
import type { RenderProps } from "react-relay/ReactRelayQueryRenderer";
import type { FetchAPI } from "../App";
import type { AdminSearchesQueryResponse } from "./__generated__/AdminSearchesQuery.graphql";
import type { AdminSearches_viewer } from "./__generated__/AdminSearches_viewer.graphql";
import type { MaybeCreatedOnSort } from "./FilterAndSortCreatedOn";
import SortIcon from "./SortIcon";
import { getSortingList, getVariables, updateSortingOrder } from "./common";
import type {
  // ExportStatus,
  CreateSearchListExportInput,
  AdminSearchesCreateSearchListExportMutationResponse,
} from "./__generated__/AdminSearchesCreateSearchListExportMutation.graphql";
import type { MaybeCreatedBySort } from "./FilterAndSortCreatedBy";

type SearchFilters = {
  jobTitleIContains?: string,
  createdById?: Array<string>,
  clientIdIn?: Array<string>,
  tagIdIn?: Array<string>,
  only?: Array<number>,
  exclude?: Array<number>,
  fromDate?: string,
  toDate?: string,
};

const createExportMutationGraphQL = graphql`
  mutation AdminSearchesCreateSearchListExportMutation(
    $input: CreateSearchListExportInput!
  ) {
    exportSearchList(input: $input) {
      export {
        id
        status
        created
        modified
        expiresAt
        exportFile
      }
      errors {
        __typename
        ... on UnsupportedIDType {
          message
          type
        }
        ... on InvalidRateCardID {
          message
        }
        ... on InvalidSearchID {
          message
        }
        ... on InvalidClientID {
          message
        }
        ... on InvalidUserID {
          message
        }
      }
    }
  }
`;

type AdminSearchesListProps = {
  fetchAPI: FetchAPI,
  +currentUserId: ?number,
  +viewer: ?AdminSearches_viewer,
  +relay: RelayPaginationProp,
};

type AdminSearchesListState = {
  showHelp: boolean,
  refetching: boolean,
  page: number,
  error: Object | null,
  searchText: string | null,
  clientFilter: { clientIdIn: Array<string> } | null,
  clientsFilterOpen: boolean,
  tagsFilter: { tagIdIn: Array<string> } | null,
  tagsFilterOpen: boolean,
  createdByFilter: { createdById: Array<string> } | null,
  createdByFilterOpen: boolean,
  createdOnFilter: { fromDate?: string, toDate?: string } | null,
  createdOnFilterOpen: boolean,
  selectedItems: Array<number>,
  sortingOrder: Array<string>,
  createdOnSort: MaybeCreatedOnSort,
  createdBySort: MaybeCreatedBySort,
  exportModalOpen: boolean,
  exportConfirmModalOpen: boolean,
  exportId: string | null,
};

class AdminSearchesImpl extends React.Component<
  AdminSearchesListProps,
  AdminSearchesListState
> {
  DEFAULT_QUERY_VALUES = {
    clientFilter: null,
    tagsFilter: null,
    createdByFilter: null,
    createdOnFilter: null,
  };
  ITEMS_PER_PAGE = 10;

  constructor(props: AdminSearchesListProps) {
    super(props);

    this.state = {
      showHelp: false,
      refetching: false,
      page: 0,
      error: null,
      searchText: null,
      ...this.DEFAULT_QUERY_VALUES,
      tagsFilterOpen: false,
      clientsFilterOpen: false,
      createdByFilterOpen: false,
      createdOnFilterOpen: false,
      selectedItems: [],
      sortingOrder: [],
      createdOnSort: null,
      createdBySort: null,
      exportModalOpen: false,
      exportConfirmModalOpen: false,
      exportId: null,
    };
  }

  resetQuery = (): void => {
    this.setState(
      {
        ...this.DEFAULT_QUERY_VALUES,
        sortingOrder: [],
        createdOnSort: null,
        createdBySort: null,
      },
      this.applyFilterAndSort
    );
  };

  getFilters = (): SearchFilters | null => {
    const { searchText, clientFilter, createdByFilter, createdOnFilter, tagsFilter } =
      this.state;
    let filters: SearchFilters | null = null;

    if (searchText) filters = { jobTitleIContains: searchText };
    if (clientFilter)
      filters = Object.assign(filters || {}, { clientIdIn: clientFilter.clientIdIn });
    if (createdByFilter)
      filters = Object.assign(filters || {}, {
        createdById: createdByFilter.createdById,
      });
    if (createdOnFilter) filters = Object.assign(filters || {}, createdOnFilter);
    if (tagsFilter) filters = Object.assign(filters || {}, tagsFilter);

    return filters;
  };

  getSorts = () => {
    const { sortingOrder, createdOnSort, createdBySort } = this.state;
    return getSortingList([createdOnSort, createdBySort], sortingOrder);
  };

  applyFilterAndSort = () => {
    const filters = this.getFilters();
    const order = this.getSorts();

    // TODO Tag filter
    this.setState({ refetching: true }, () => {
      this.props.relay.refetchConnection(
        10,
        (error) => {
          if (error) {
            console.error(error);
          }
          this.setState({ error, refetching: false, page: 0 });
        },
        {
          filters,
          order,
        }
      );
    });
  };

  handleExportSearchList = (confirmed: boolean = false) => {
    const filters: SearchFilters = this.getFilters() || {};

    const input: $Shape<CreateSearchListExportInput> = {};

    // Clients
    if (filters.clientIdIn) {
      input.clientIds = filters.clientIdIn;
    }
    // Users
    if (filters.createdById) {
      input.userIds = filters.createdById;
    }
    // TODO: Ratecard
    // Search
    if (filters.only) {
      // I think number will fail here.
      input.searchIds = filters.only.map((v) => v.toString());
    }
    if (filters.toDate) {
      input.createdBefore = filters.toDate;
    }
    if (filters.fromDate) {
      input.createdAfter = filters.fromDate;
    }

    if (confirmed) {
      input.confirmNoFilters = true;
    }

    this.setState({
      exportModalOpen: true,
    });

    commitMutation(this.props.relay.environment, {
      mutation: createExportMutationGraphQL,
      variables: { input },
      onCompleted: (
        response: AdminSearchesCreateSearchListExportMutationResponse,
        errors
      ) => {
        console.log("Response received from server.");
        const mutationErrors = response.exportSearchList?.errors;
        if (mutationErrors) {
          if (
            mutationErrors.length > 0 &&
            mutationErrors[0]?.__typename === "NoFiltersWarning"
          ) {
            this.setState({
              exportId: null,
              exportModalOpen: false,
              exportConfirmModalOpen: true,
            });
          } else {
            console.log("Error creating export", mutationErrors);
            this.setState({
              // This is misusing this state.
              error: mutationErrors,
              exportId: null,
              exportModalOpen: false,
            });
          }
        } else {
          const searchExport = response.exportSearchList?.export;
          if (searchExport) {
            this.setState({
              exportId: searchExport.id,
              exportModalOpen: true,
              error: null,
            });
          }
        }
      },
      onError: (err) => {
        console.error(err);
      },
    });
  };

  render() {
    const loading =
      !this.props.viewer || this.props.relay.isLoading() || this.state.refetching;
    const itemsPerPage = 10;
    const { showHelp, exportConfirmModalOpen } = this.state;
    // Do not include search text
    const isFiltered = Boolean(this.getFilters());
    const isSorted = Boolean(this.getSorts());

    // const totalCount = this.props?.viewer?.savedsearches?.totalCount || 0;
    const searches = this.props?.viewer?.savedsearches?.edges || [];

    const page = searches.slice(
      itemsPerPage * this.state.page,
      Math.min(itemsPerPage * (this.state.page + 1), searches.length)
    );

    return (
      <div className="view list jobclients">
        <div className="rc-container bring-forward">
          <div className="header-action-bar">
            <h2>Searches Admin</h2>
            <p className="subtext">Manage all clients' searches in the system.</p>
            <div className="clearfix" />
          </div>
          <div className="header-action-bar marginTopzero">
            <div className="pull-left">
              <SearchBox
                onSearch={async (searchText: string) => {
                  // NOTE: should handle when "select all *" and reset on search?
                  if (searchText === "") {
                    this.setState({ searchText: null, page: 0 }, this.applyFilterAndSort);
                  } else {
                    this.setState({ searchText, page: 0 }, this.applyFilterAndSort);
                  }
                }}
                disabled={loading}
                style={{
                  width: "260px",
                }}
              />
            </div>
            <div className="btn-group pull-right rc-center">
              <div>
                <button
                  className="btn btn-green"
                  onClick={() => {
                    this.handleExportSearchList();
                  }}
                >
                  <FontAwesomeIcon icon="download" fixedWidth className="icon" />
                  Search History Export
                </button>
                <button
                  className="btn"
                  onClick={() => {
                    this.setState({ showHelp: true });
                  }}
                >
                  <FontAwesomeIcon icon="question-circle" fixedWidth className="icon" />
                  Help
                </button>
              </div>
            </div>
            <div className="clearfix" />
          </div>
          <div className="ratecards-list">
            <header
              className={classNames("ratecards-table-header", {
                active: false,
              })}
            >
              {/* TODO: Finish tags */}
              <FilterAndSortTags
                viewer={this.props?.viewer || null}
                contentType="SAVED_SEARCHES"
                open={this.state.tagsFilterOpen}
                disabled={loading}
                currentFilter={this.state.tagsFilter}
                onClose={() => {
                  this.setState({ tagsFilterOpen: false });
                }}
                onApply={(tagsFilter) => {
                  this.setState(
                    { tagsFilter, tagsFilterOpen: false },
                    this.applyFilterAndSort
                  );
                }}
              />
              <FilterAndSortClients
                viewer={this.props?.viewer || null}
                open={this.state.clientsFilterOpen}
                currentFilter={this.state.clientFilter}
                disabled={loading}
                onClose={() => {
                  this.setState({ clientsFilterOpen: false });
                }}
                onApply={(clientFilter) => {
                  this.setState(
                    { clientFilter, clientsFilterOpen: false },
                    this.applyFilterAndSort
                  );
                }}
              />
              <FilterAndSortCreatedBy
                viewer={this.props?.viewer || null}
                open={this.state.createdByFilterOpen}
                currentFilter={this.state.createdByFilter}
                currentSort={this.state.createdBySort}
                disabled={loading}
                onClose={() => {
                  this.setState({ createdByFilterOpen: false });
                }}
                onApply={(createdByFilter, createdBySort) => {
                  const currentSort = this.state.createdBySort;
                  let sortingOrder = this.state.sortingOrder;

                  this.setState(
                    {
                      createdByFilter,
                      createdBySort,
                      sortingOrder: updateSortingOrder(
                        createdBySort,
                        currentSort,
                        sortingOrder
                      ),
                      createdByFilterOpen: false,
                    },
                    this.applyFilterAndSort
                  );
                }}
              />
              <FilterAndSortCreatedOn
                currentFilter={this.state.createdOnFilter}
                currentSort={this.state.createdOnSort}
                open={this.state.createdOnFilterOpen}
                onClose={() => {
                  this.setState({ createdOnFilterOpen: false });
                }}
                onApply={(createdOnFilter, createdOnSort) => {
                  const currentSort = this.state.createdOnSort;
                  let sortingOrder = this.state.sortingOrder;

                  this.setState(
                    {
                      createdOnFilter,
                      createdOnSort,
                      sortingOrder: updateSortingOrder(
                        createdOnSort,
                        currentSort,
                        sortingOrder
                      ),
                      createdOnFilterOpen: false,
                    },
                    this.applyFilterAndSort
                  );
                }}
              />
              <nav className="header-right-nav alt-1">
                {isFiltered && (
                  <div className="btn btn-yellow" onClick={this.resetQuery}>
                    Clear All Filters & Sorts
                  </div>
                )}
                <ToggleButton
                  borderless={true}
                  name="search-list-filter"
                  value={""}
                  selected={this.state.clientFilter !== null}
                  onClick={() => {
                    this.setState({ clientsFilterOpen: true });
                  }}
                >
                  Clients <span> ▼</span>
                </ToggleButton>
                <ToggleButton
                  borderless={true}
                  name="search-list-filter"
                  value={""}
                  selected={this.state.tagsFilter !== null}
                  onClick={() => {
                    this.setState({ tagsFilterOpen: true });
                  }}
                >
                  {/* <SortIndicator
                  sortColumn={}
                  sortsOrder={store.appliedSortsOrder}
                  sort={store.appliedSorts[FILTER_COLUMN.TAGS]}
                /> */}
                  Tags <span> ▼</span>
                </ToggleButton>
                <ToggleButton
                  borderless={true}
                  name="search-list-filter"
                  value={""}
                  selected={this.state.createdByFilter !== null}
                  onClick={() => {
                    this.setState({ createdByFilterOpen: true });
                  }}
                >
                  <SortIcon
                    kind="alpha"
                    sortObject={this.state.createdBySort}
                    sortingOrder={this.state.sortingOrder}
                  />{" "}
                  Created By <span> ▼</span>
                </ToggleButton>
                <ToggleButton
                  borderless={true}
                  name="search-list-filter"
                  value={""}
                  selected={this.state.createdOnFilter !== null}
                  onClick={() => {
                    this.setState({ createdOnFilterOpen: true });
                  }}
                >
                  <SortIcon
                    kind="numeric"
                    sortObject={
                      isSorted
                        ? this.state.createdOnSort
                        : { field: "", direction: "DESC" }
                    }
                    sortingOrder={this.state.sortingOrder}
                  />{" "}
                  Created On <span> ▼</span>
                </ToggleButton>
              </nav>
            </header>
            <div className="ratecards-table">
              {/* TODO Select All Section */}
              {this.state.error && (
                <div className="ratecard-list-item-container">
                  <div className="ratecard-list-item">
                    <div>
                      Unable to load jobs: <pre>{this.state.error.toString()}</pre>
                    </div>
                  </div>
                </div>
              )}
              {loading ? (
                <LoadingIndicator />
              ) : searches.length === 0 ? (
                <div>No searches Found</div>
              ) : (
                page.map((search) => {
                  if (!search?.node) {
                    return <div>[Error getting search's details]</div>;
                  }
                  return (
                    <AdminSearchesSearch
                      key={search?.cursor}
                      currentUserId={this.props.currentUserId || -1}
                      search={search.node}
                    />
                  );
                })
              )}
            </div>
          </div>
          <div className="pt-pagination-outer">
            <button
              className="pt-prev-page-btn"
              disabled={this.state.page === 0}
              onClick={() => {
                this.setState({ page: this.state.page > 0 ? this.state.page - 1 : 0 });
              }}
            >
              <FontAwesomeIcon icon="chevron-left" className="pt-page-left-chevron" />{" "}
              Prev
            </button>
            <button
              className="pt-next-page-btn"
              disabled={!this.props.relay.hasMore()}
              onClick={() => {
                const newPage = this.state.page + 1;
                if (
                  this.props.relay.hasMore() &&
                  newPage * itemsPerPage > searches.length - itemsPerPage
                ) {
                  this.setState({ refetching: true }, () => {
                    this.props.relay.loadMore(itemsPerPage, (error) => {
                      if (error) {
                        console.error(error);
                      }
                      this.setState({ error, page: newPage, refetching: false });
                    });
                  });
                } else {
                  this.setState({ page: newPage });
                }
              }}
            >
              Next{" "}
              <FontAwesomeIcon icon="chevron-right" className="pt-page-right-chevron" />
            </button>
          </div>
        </div>
        <Modal
          show={showHelp}
          onHide={() => {
            this.setState({ showHelp: false });
          }}
        >
          <div className="container-section header">
            <h4>Help</h4>
          </div>
          <div className="container-section footer">
            <p>Here you can see all searches in the system</p>
          </div>
        </Modal>

        <Modal
          show={exportConfirmModalOpen}
          onHide={() => {
            this.setState({ exportConfirmModalOpen: false });
          }}
        >
          <div className="container-section header">
            <h4>Confirm Export</h4>
          </div>
          <div className="container-section footer">
            <p>No filters selected</p>
            <p>
              This will export <strong>all searches in the system</strong>.
            </p>

            <div className="pull-right">
              <button
                className="btn btn-lg btn-default"
                onClick={() => {
                  this.setState({ exportConfirmModalOpen: false });
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-lg btn-green"
                onClick={() => {
                  this.handleExportSearchList(true);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal>
        <ExportModal
          relayEnvironment={this.props.relay.environment}
          exportId={this.state.exportId}
          show={this.state.exportModalOpen}
          onHide={() => {
            this.setState({ exportId: null, exportModalOpen: false });
          }}
        />
      </div>
    );
  }
}

const AdminSearchesPaginationContainer = createPaginationContainer(
  AdminSearchesImpl,
  {
    viewer: graphql`
      fragment AdminSearches_viewer on Viewer
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        cursor: { type: "String" }
        filters: { type: "SavedSearchFiltersInput", defaultValue: {} }
        order: {
          type: "[SavedSearchSortInput]"
          defaultValue: [{ field: CREATE_DATE, direction: DESC }]
        }
      ) {
        savedsearches(
          first: $count
          after: $cursor
          filters: $filters
          order: $order
          section: ADMIN
        ) @connection(key: "AdminSearches_savedsearches", filters: ["filters"]) {
          pageInfo {
            hasNextPage
            endCursor
            hasPreviousPage
            startCursor
          }
          totalCount
          edges {
            cursor
            node {
              id
              searchId
              ...AdminSearchesSearch_search
            }
          }
        }
        ...FilterAndSortClients_viewer
        ...FilterAndSortCreatedBy2_viewer
        ...FilterAndSortTags_viewer
      }
    `,
  },
  {
    direction: "forward",
    // getFragmentVariables(prevVars, totalCount) {
    //   return {
    //     ...prevVars,
    //     count: totalCount
    //   };
    // },
    // getVariables(props: AdminSearchesListProps, { count, cursor }, { filters, order }) {
    //   return {
    //     count,
    //     cursor,
    //     filters,
    //     order
    //   };
    // },
    getVariables,
    query: graphql`
      query AdminSearchesPaginationQuery(
        $filters: SavedSearchFiltersInput
        $order: [SavedSearchSortInput]
        $count: Int
        $cursor: String
      ) {
        viewer {
          ...AdminSearches_viewer
            @arguments(filters: $filters, order: $order, count: $count, cursor: $cursor)
        }
      }
    `,
  }
);

type AdminSearchesProps = {
  route: {
    fetchAPI: FetchAPI,
  },
  sessionInfo: SessionInfo,
};

const AdminSearches = (props: AdminSearchesProps) => {
  const fetchAPI = props.route.fetchAPI;
  const currentUserId = props.sessionInfo.user?.userId;
  const relayEnvironment = useRelayEnvironment();

  return (
    <QueryRenderer
      environment={relayEnvironment}
      query={graphql`
        query AdminSearchesQuery {
          viewer {
            ...AdminSearches_viewer
          }
        }
      `}
      variables={{}}
      render={({ error, props }: RenderProps<AdminSearchesQueryResponse>): any => {
        if (error) {
          console.error(error);
          return (
            <div>
              Error on initially loading data. Please see console for more information.
            </div>
          );
        }

        const { viewer } = props || {};
        if (!viewer) return <LoadingIndicator />;

        return (
          <AdminSearchesPaginationContainer
            fetchAPI={fetchAPI}
            viewer={viewer}
            currentUserId={currentUserId}
          />
        );
      }}
    />
  );
};

export default AdminSearches;
