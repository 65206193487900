import React from "react";

import Stack from "../../../../components/lib/Stack";
import Button from "../../../../components/lib/Button";
import Inline from "../../../../components/lib/Inline";
import Icon from "../../../../components/lib/Icon";
import Text from "../../../../components/lib/Text";
import PromiseButton from "../../../../components/lib/PromiseButton";
import { ButtonGroupRight } from "../../../../components/lib/ButtonGroup";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogClose,
  DialogDescription,
  DialogActions,
} from "../../../../components/lib/Dialog";
import { REVIEW_STATUS_TYPES } from "../../types";

import type { REVIEW_STATUS_TYPES_TYPE } from "../../types";

type JobFeedbackDialogDetailsProps = {
  reviewStatus: REVIEW_STATUS_TYPES_TYPE;
  disableButtons?: boolean;
  show: boolean;
  isAnalysisCompleted: boolean;
  onClose: () => void;
  onCompleteAnalysis: () => void;
};

function JobFeedbackDialogDetails(
  props: React.PropsWithChildren<JobFeedbackDialogDetailsProps>
) {
  const {
    reviewStatus,
    disableButtons,
    isAnalysisCompleted,
    show,
    onClose,
    onCompleteAnalysis,
    children,
  } = props;

  return (
    <Dialog open={show} onOpenChange={onClose}>
      <DialogContent>
        <DialogTitle asChild>
          <Inline css={{ justifyContent: "space-between" }}>
            <Text as="h4">Validator feedback details</Text>
            <DialogClose asChild>
              <Icon icon="times" />
            </DialogClose>
          </Inline>
        </DialogTitle>

        <DialogDescription
          css={{ backgroundColor: "inherit", minWidth: "700px" }}
          asChild
        >
          <Stack fill css={{ alignItems: "stretch", padding: "$8" }}>
            {children}
          </Stack>
        </DialogDescription>

        <DialogActions>
          <ButtonGroupRight fill>
            {!disableButtons && (
              <PromiseButton
                icon={isAnalysisCompleted ? ["far", "check-square"] : ["far", "square"]}
                color="brand"
                size="large"
                css={{ width: "$80" }}
                loadingText="Mark As Analysis Completed"
                onClick={onCompleteAnalysis}
                disabled={
                  reviewStatus !== REVIEW_STATUS_TYPES.REVIEWED &&
                  reviewStatus !== REVIEW_STATUS_TYPES.SKIPPED
                }
              >
                Mark As Analysis Completed
              </PromiseButton>
            )}
            <Button size="large" onClick={onClose}>
              Close
            </Button>
          </ButtonGroupRight>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
JobFeedbackDialogDetails.displayName = "JobFeedbackDialogDetails";

export default JobFeedbackDialogDetails;
