import React from "react";
import Stack from "../../../../components/lib/Stack";
import { Placeholder } from "../../../../components/lib/Placeholder";
import Inline from "../../../../components/lib/Inline";

export default function HiringIntelLoader() {
  return (
    <Stack fill>
      <Stack nogap fill css={{ alignItems: "flex-start" }}>
        <Placeholder height={22} width={160} />
      </Stack>
      <Inline fill css={{ gap: "0", justifyContent: "space-between" }}>
        <Inline fill css={{ gap: "$6", justifyContent: "space-between" }}>
          <Placeholder height={156} css={{ flex: 1 }} />
          <Placeholder height={156} css={{ flex: 1 }} />
          <Placeholder height={156} css={{ flex: 1 }} />
        </Inline>
      </Inline>
    </Stack>
  );
}
