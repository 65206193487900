import React, { useCallback, useState } from "react";

import Stack from "../../../components/lib/Stack";
import Button from "../../../components/lib/Button";
import AlertsTableView from "../components/AlertsTable";
import { NavigationButton } from "../../../components/lib/Button";
import {
  Card,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
  CardBody,
  CardHeaderTitle,
} from "../../../components/lib/Card";

import { Values } from "../types";
import ValidatorAlertWizard from "../components/ValidatorAlertWizard";
import { useRefreshRequest } from "../../../utils/hooks";
import { useVal5KAdminContext } from "../context/Val5KAdminContext";

type BackToDashboardBlockProps = {
  title?: string;
};

const BackToDashboardBlock = (props: BackToDashboardBlockProps) => {
  const { title } = props;
  const { router } = useVal5KAdminContext();

  const handleBackToDashboard = useCallback(
    () => router.push(`/admin/val5000`),
    [router]
  );

  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft>
          {title && <CardHeaderTitle>{title}</CardHeaderTitle>}
        </CardActionsLeft>
        <CardActionsRight>
          <NavigationButton
            icon="arrow-left"
            onClick={handleBackToDashboard}
            css={{ width: "190px" }}
          >
            Back to Dashboard
          </NavigationButton>
        </CardActionsRight>
      </CardActions>
    </Card>
  );
};
BackToDashboardBlock.displayName = "BackToDashboardBlock";

const PAGE_STATES = {
  INITIAL: 1,
  CREATE_ALERTS: 2,
} as const;

type PAGE_STATES_TYPE = Values<typeof PAGE_STATES>;

const AlertsListPage = () => {
  const [state, setState] = useState<PAGE_STATES_TYPE>(PAGE_STATES.INITIAL);

  const [refreshRequestId, makeRefreshRequest] = useRefreshRequest();

  return (
    <Stack>
      <BackToDashboardBlock title="Alert Validators" />
      <Card fill>
        <CardActions>
          <CardActionsLeft>
            <CardHeaderTitle as="h3">Create New Alerts</CardHeaderTitle>
          </CardActionsLeft>
          <CardActionsRight />
        </CardActions>
        <CardBody>
          {state === PAGE_STATES.INITIAL && (
            <Button
              icon="play-circle"
              color="brand"
              size="normal"
              onClick={() => setState(PAGE_STATES.CREATE_ALERTS)}
            >
              Start Creating Procedure
            </Button>
          )}
          {state === PAGE_STATES.CREATE_ALERTS && (
            <ValidatorAlertWizard
              onFinish={() => setState(PAGE_STATES.INITIAL)}
              onRefreshTable={makeRefreshRequest}
            />
          )}
        </CardBody>
      </Card>
      <Card fill>
        <CardActions>
          <CardActionsLeft>
            <CardHeaderTitle as="h3">Latest Alerts List</CardHeaderTitle>
          </CardActionsLeft>
          <CardActionsRight />
        </CardActions>
        <CardBody>
          <AlertsTableView refreshRequestId={refreshRequestId} />
        </CardBody>
      </Card>
    </Stack>
  );
};
AlertsListPage.displayName = "AlertsListPage";

export default AlertsListPage;
