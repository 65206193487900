/**
 * @generated SignedSource<<32bbf82755d6464fb89a6a641c8fa542>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RateDetail_savedsearch$data = {
  readonly id: string;
  readonly rateType: number | null;
  readonly " $fragmentSpreads": FragmentRefs<"DetailCards_savedsearch" | "DetailTable_savedsearch">;
  readonly " $fragmentType": "RateDetail_savedsearch";
};
export type RateDetail_savedsearch$key = {
  readonly " $data"?: RateDetail_savedsearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"RateDetail_savedsearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RateDetail_savedsearch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rateType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DetailTable_savedsearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DetailCards_savedsearch"
    }
  ],
  "type": "SaveSearchNode",
  "abstractKey": null
};

(node as any).hash = "a7e91306802ef18c602b935de07a3d14";

export default node;
