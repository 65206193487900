//@flow
import React, { Component } from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LibraryTitleList from "./LibraryTitleList";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import classNames from "classnames";
import Modal from "../../components/modals/Modal";
import ErrorModal from "../../components/modals/ErrorModal";
import type MobXStore from "../../stores/mobx/MobXStore";
import Button from "../../components/lib/Button";
import Container from "../../components/lib/Container";
import { styled } from "../../../src/stitches.config";
import Inline from "../../components/lib/Inline";
import TextArea from "../../components/lib/TextArea";

const CLCenteredForm = styled("div", {
  padding: "50px 40px",
  background: "#ffffff",
  border: "1px solid #e6e6e6",
  borderRadius: "2px",
  width: "100%",
  maxWidth: "980px",
  position: "relative",
  overflow: "hidden",
  color: "#333333",
  alignSelf: "flex-start",
  margin: "auto",
});

const DescriptionArea = styled(TextArea, {
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "22px",
  width: "100%",
  paddingTop: "2px",
  border: "1px dashed transparent",
  resize: "none",
  "&:focus": {
    border: "1px dashed $brandLight !important",
    backgroundColor: "#f8fafb !important",
  },
});

const EnterLibraryName = styled("input", {
  flex: "0 1",
  flexBasis: "auto",
  fontSize: "34px !important",
  lineHeight: "38px",
  width: "100%",
  marginBottom: "5px",
  border: "none !important",
  borderBottom: "1px dashed #e2e7ea !important",
  color: "#666 !important",
  borderRadius: "0 !important",
  padding: "0 3px",
  margin: "0 -3px",
  background: "#fff",
  "&:focus": {
    borderBottom: "1px dashed $brandLight !important",
    backgroundColor: "#f8fafb !important",
    outline: "none",
  },
});

type Props = {
  store: MobXStore,
  router: Object,
};

class JobLibraryCreateCustom extends Component<Props> {
  constructor(props: Props) {
    super(props);

    const store = props.store.jobLibraryCreateCustomStore;

    store.previousLocation = "/job-library/library-requests/";

    this.props.store.jobLibraryCreateCustomStore.successModal.hideModal();
    this.props.store.jobLibraryCreateCustomStore.reset();
  }

  componentDidMount() {
    this.props.store.jobLibraryCreateCustomStore.router = this.props.router;
    this.props.store.jobLibraryTitleListStore.handleStartEdit();
    this.props.store.jobLibraryTitleListStore.libraryId = null;
    this.props.store.jobLibraryTitleListStore.selectTitlesByLibraryId = null;
    this.props.store.jobLibraryTitleListStore.hardRefresh();
  }

  componentWillUnmount() {
    const jobLibraryTitleListStore = this.props.store.jobLibraryTitleListStore;
    jobLibraryTitleListStore.removeFilterBySelectedTitles(false);
  }

  render() {
    const store = this.props.store.jobLibraryCreateCustomStore;
    const jobTitlesStore = this.props.store.jobLibraryTitleListStore;

    const titleClasses = classNames("cjl-custom-input cjl-custom-title", {
      error: Boolean(store.titleError),
    });

    const titleErrorTooltip = (
      <Tooltip id="titleErrorTooltip" placement="top">
        {store.titleError}
      </Tooltip>
    );

    return (
      <Container css={{ width: "100%" }}>
        <CLCenteredForm>
          <Inline
            css={{
              justifyContent: "flex-end",
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          >
            <Button color="brand" shape="rounded" onClick={store.handleSaveLibrary}>
              <FontAwesomeIcon icon="save" fixedWidth className="icon" />
              Save
            </Button>
          </Inline>

          <form onSubmit={store.handleSaveLibrary}>
            <EnterLibraryName
              type="text"
              className={titleClasses}
              placeholder="Enter Library Name Here"
              onChange={store.onTitleChange}
              value={store.title}
            />
            {Boolean(store.titleError) && (
              <OverlayTrigger placement="top" overlay={titleErrorTooltip}>
                <FontAwesomeIcon
                  icon="info-circle"
                  fixedWidth
                  className="icon text-danger cjl-input-icon-right"
                />
              </OverlayTrigger>
            )}
            <DescriptionArea
              name="description"
              id="description"
              cols="60"
              rows="4"
              className="cjl-custom-input"
              placeholder="Enter description of library here."
              value={store.description}
              onChange={store.onDescriptionChange}
            />
          </form>
          <LibraryTitleList
            store={jobTitlesStore}
            itemActions={[store.addRemoveClientJobTitleAction]}
          />
        </CLCenteredForm>

        <Modal
          show={store.confirmEmptyLibraryModal.show}
          onHide={store.handleConfirmEmptyLibraryCancel}
        >
          <div className="container-section header">
            <h4>Empty Library</h4>
          </div>
          <div className="container-section">
            <p>
              No titles have been selected for this library. To create the library you
              must select at least one title.
            </p>
          </div>
          <div className="container-section footer">
            <div className="pull-right">
              {/*<button className="btn btn-lg btn-danger" onClick={store.handleConfirmEmptyLibrarySubmit}>*/}
              {/*Yes, create empty library*/}
              {/*</button>*/}
              <button
                className="btn btn-lg"
                onClick={store.handleConfirmEmptyLibraryCancel}
              >
                Add titles now
              </button>
            </div>
          </div>
        </Modal>
        <ErrorModal modalState={store.errorModal} errorMessage={store.errorMessage} />
      </Container>
    );
  }
}

export default observer(JobLibraryCreateCustom);
