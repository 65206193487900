import { styled } from "../../../stitches.config";
// design system
import { Card } from "../../../components/lib/Card";
import Stack from "../../../components/lib/Stack";

export const Selections = styled("span", {
  display: "flex",
  paddingLeft: "$1",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "flex-start",
  textAlign: "left",
  maxWidth: "55%",
  gap: "$1_5",
  "@container RsSideMenu (max-width: 500px)": {
    maxWidth: "60%",
  },
});

export const Selected = styled("button", {
  display: "flex",
  gap: "$1_5",
  alignItems: "center",
  color: "$primaryDarker",
  height: "$sm",
  fontSize: "$sm",
  lineHeight: "$sm",
  padding: "0px 4px",
  backgroundColor: "$primaryLightest",
  border: "solid 1px $primaryLight",
  borderRadius: "$rounded",
  transition: "all 300ms ease",
  "&:hover": {
    backgroundColor: "$accentLighter",
    border: "solid 1px $accent",
  },
  variants: {
    disabled: {
      true: {
        cursor: "not-allowed",
        color: "$black",
        fontWeight: "$semibold",
        backgroundColor: "$primaryLightest",
        border: "none",
        "&:hover": {
          backgroundColor: "transparent",
          border: "none",
        },
      },
      false: {},
    },
  },
});

export const FormCard = styled(Card, {
  padding: "$6",
  marginBottom: "$8",
  "@container RsSideMenu (max-width: 500px)": {
    padding: "$6 $3 $3 $3",
    marginBottom: 0,
    border: "none",
    boxShadow: "unset",
  },
});

export const FieldWrapper = styled("div", {
  flexWrap: "wrap",
  alignItems: "start",
  display: "flex",
  justifyContent: "flex-start",
  gap: "$6",
  width: "100%",
  "& > div": {
    width: "calc(50% - $6)",
  },
  "@container RsSideMenu (max-width: 500px)": {
    flexDirection: "column",
    gap: "$3",
    "& > div": { width: "100%" },
  },
});

export const FormStack = styled(Stack, {
  alignItems: "flex-start",
  gap: "$6",
  "@container RsSideMenu (max-width: 500px)": {
    gap: "$3",
  },
});
