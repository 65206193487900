// @flow
import * as React from "react";
import classNames from "classnames";

const SelectableItemsList = (props: {
  className?: ?string,
  style?: ?Object,
  children?: ?React.ChildrenArray<any>,
}) => {
  const isEmpty =
    !props.children || (Array.isArray(props.children) && props.children.length === 0);
  const classes = classNames(props.className, "selectable-list-container", {
    empty: isEmpty,
  });

  return (
    <div className={classes} style={props.style}>
      {props.children}
    </div>
  );
};

export default SelectableItemsList;
