// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router";
import LocationList from "./components/LocationList";
import { TagItemWithoutDelete } from "../../ratecards/components/TagItem";

const RegionDetail = observer((props) => {
  const store = props.store;
  let tagSection = null;

  let owner,
    regionId,
    regionName,
    createdDate = null;

  let countryName = store.regionDetail.countryName;
  if (store.network.loading) {
    regionId = "...";
    regionName = "...";
    owner = "...";
    createdDate = "...";
  } else if (store.regionDetail) {
    regionName = `${store.regionDetail.name}`;
    owner = store.regionDetail.owner;
    regionId = store.regionDetail.regionId;
    createdDate = store.regionDetail.createdDisplay;
  }

  if (store.regionDetail.tags) {
    tagSection = store.regionDetail.tags.map((tag) => (
      <TagItemWithoutDelete
        key={tag.tagId}
        tag={tag}
        onClick={(e) => {
          props.onClick(e, tag.tagId);
        }}
      />
    ));
  }

  let actionBar = null;
  if (!store.network.loading && !store.network.error)
    actionBar = <nav className="action-bar" />;

  return (
    <div className="top">
      <div className="above">
        <div className="ratecard-label-header ellipsis max-width-80">
          <h2 className="ellipsis">{regionName}</h2>
        </div>
        <nav className="back-bar">
          <Link to={`/admin/regions/`} className="btn btn-green">
            <FontAwesomeIcon icon="arrow-left" fixedWidth className="icon" />
            Go Back
          </Link>
          <button className="btn" onClick={store.showHelp}>
            <FontAwesomeIcon icon="question-circle" fixedWidth className="icon" />
            Help
          </button>
        </nav>
      </div>
      <div className="below">
        <div className="ratecard-meta ellipsis">
          <div className="ratecard-owner ellipsis">
            <FontAwesomeIcon icon="key" fixedWidth className="icon" /> {regionId}
          </div>
          <div className="ratecard-owner ellipsis">
            <FontAwesomeIcon icon="user" fixedWidth className="icon" /> {owner}
          </div>
          <div className="ratecard-created-at ellipsis">
            <FontAwesomeIcon icon="calendar-alt" fixedWidth className="icon" />{" "}
            {createdDate}
          </div>
          <div className="ratecard-created-at ellipsis">
            <FontAwesomeIcon icon="map-marker" fixedWidth className="icon" />{" "}
            {countryName}
          </div>
        </div>
        {actionBar}
      </div>
      <div className="tagbox">{tagSection}</div>
    </div>
  );
});

/**
 * RegionDetail component for rate card detail page
 */
class Detail extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor() {
    super();
    this.deleteTag = this.deleteTag.bind(this);
  }
  /**
   * componentDidMount
   * @return {void}
   */
  componentDidMount() {
    this.props.store.locationListPTStore.regionId = this.props.router.params.regionId;
    this.props.store.locationListPTStore.router = this.context.router;
    this.props.store.locationListPTStore.pagination.goFetch();
    // reset to null so some elements visible while editing start hidden
    this.props.store.locationListPTStore.isEditing = null;
  }

  deleteTag(e: object, tag_id) {}
  /**
   * Render rate card detail page
   * @return {XML}
   */
  render() {
    const store = this.props.store.locationListPTStore;
    const regionCreateStore = this.props.store.regionCreateStore;

    return (
      <div className="view">
        <div className="rc-container show-overflow">
          <RegionDetail
            store={store}
            router={this.props.router}
            regionCreateStore={regionCreateStore}
            onClick={this.deleteTag}
          />
        </div>

        <div className="rc-container bring-forward headerfix">
          {!store.network.error && (
            <div>
              <LocationList store={store} />
            </div>
          )}

          {store.network.error && (
            <div className="pt-error-message fade-in">
              <h2>Error</h2>
              <pre>
                <code>{JSON.stringify(store.network.error, null, 2)}</code>
              </pre>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default observer(Detail);
