// @flow
import { extendObservable, action, runInAction } from "mobx";
import axios from "axios";
import NetworkState from "../../../models/NetworkState";
import ModalState from "../../../models/ModalState";
import MessageState from "../../../models/MessageState";
import JobList from "../../../models/JobList";
import type { FetchGraphQL } from "../../../App";

class JobLabelDetailAdminStore {
  router: Object;
  network: NetworkState;
  renameNetwork: NetworkState;
  confirmDeleteModal: ModalState;
  jobDetail: JobList;
  messaging: MessageState;
  jobId: number;
  jobTitle: String;
  newJobTitle: String;
  jobLabel: String;
  jobDescription: String;
  newJobDescription: String;
  showHelpModal: boolean;
  showHelp: () => void;
  hideHelp: () => void;
  getJobDetails: () => void;
  isEditing: boolean;
  stopEditing: () => void;
  startEditing: () => void;
  jobTitleId: number;
  newTitleId: number;
  changeJobLabelText: () => void;
  changeJobDescriptionText: () => void;
  changeJobTitleText: () => void;
  showRenameJobModal: () => void;
  renameJobModal: ModalState;
  newJobLabel: String;
  onNewJobLabelChange: (Event) => void;
  getJobDescription: () => void;
  fetchGraphQL: FetchGraphQL;

  constructor(fetchGraphQL: FetchGraphQL) {
    this.fetchGraphQL = fetchGraphQL;
    this.router = null;

    extendObservable(this, {
      jobId: null,
      confirmDeleteModal: new ModalState(),
      renameJobModal: new ModalState(),
      network: new NetworkState(),
      renameNetwork: new NetworkState(),
      jobDetail: null,
      showHelpModal: false,
      messaging: new MessageState(),
      isEditing: false,
      jobLabel: "",
      jobTitle: "",
      jobDescription: "",
      jobTitleId: null,
      newJobLabel: "",
      newJobTitle: "",
      newJobTitleId: null,
      newJobDescription: "",
    });

    this.showHelp = action(this.showHelp.bind(this));
    this.hideHelp = action(this.hideHelp.bind(this));
    this.getJobDetails = action(this.getJobDetails.bind(this));
    this.stopEditing = action(this.stopEditing.bind(this));
    this.startEditing = action(this.startEditing.bind(this));
    this.changeJobLabelText = action(this.changeJobLabelText.bind(this));
    this.changeJobDescriptionText = action(this.changeJobDescriptionText.bind(this));
    this.changeJobTitleText = action(this.changeJobTitleText.bind(this));
    this.showRenameJobModal = action(this.showRenameJobModal.bind(this));
    this.onNewJobLabelChange = action(this.onNewJobLabelChange.bind(this));
    this.getJobDescription = action(this.getJobDescription.bind(this));
  }

  showHelp() {
    this.showHelpModal = true;
  }

  hideHelp() {
    this.showHelpModal = false;
  }

  stopEditing() {
    this.isEditing = false;
  }

  startEditing() {
    this.isEditing = true;
  }

  changeJobLabelText(e: Object) {
    this.jobLabel = e.target.value;
  }

  changeJobDescriptionText(e: Object) {
    this.newJobDescription = e.target.value;
  }

  changeJobTitleText(e: Object) {
    if (e && e.id) {
      this.newJobTitle = e.title;
      this.newJobTitleId = e.id;
      this.getJobDescription(e.id);
    } else {
      this.newJobTitle = "";
      this.newJobDescription = "";
    }
  }

  showRenameJobModal() {
    this.newJobLabel = this.jobLabel;
    this.renameJobModal.showModal();
  }

  onNewJobLabelChange(e: Event) {
    this.newJobLabel = e.target.value;
  }

  async getJobDescription(title_id) {
    const query = `
            query jobTitleDetail{
            viewer{
              jobTitle(id: ${title_id}){
                description,
                id
              }
            }
          }
        `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, null);
      // should hit get request
    } catch (e) {
      console.error("Error editing job", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }

    runInAction("JobDescription--success", () => {
      this.newJobDescription = res.data.viewer.jobTitle.description;
    });

    return {
      jobDescription: res.data.viewer.jobTitle.description,
    };
  }

  async getJobDetails() {
    let res = null;
    if (!/^\d+$/.test(this.jobId)) {
      if (this.router) {
        this.router.push({
          pathname: "/404NotFound",
          query: this.router.query,
        });
      }
      return res;
    }
    const variables = {
      id: parseInt(this.jobId, 10),
    };
    const query = `
      query jobDetail($id : Int!){
        viewer{
          job(id:$id, section:ADMIN){
            jobId
            created
            modified
            jobTitle
            jobLabel
            jobTitleId
            jobDescription
            createdBy{
              firstName
              lastName
            }
            tags{
              name
              tagId
            }
          }
        }
      }
    `;

    this.network.loading = true;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting Job Details", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Job Details query", res);
      }
      return e;
    }
    return runInAction("getProjects--success", () => {
      this.network.loading = false;
      this.network.error = null;

      if (this.network.logGraphQLError("Get Job Details query", res)) {
        return {};
      }
      var that = this;
      if (res.data.viewer && !res.data.viewer.job) {
        if (that.router) {
          that.router.push({
            pathname: "/404NotFound",
            query: that.router.query,
          });
        }
        return;
      }

      const job = res.data.viewer.job;
      this.jobDetail = new JobList(this, job);
      this.jobLabel = this.jobDetail.jobLabel;
      this.newJobLabel = this.jobDetail.jobLabel;
      this.jobTitle = this.jobDetail.jobTitle;
      this.newJobTitle = this.jobDetail.jobTitle;
      this.jobDescription = this.jobDetail.jobDescription;
      this.newJobDescription = this.jobDetail.jobDescription;
      this.jobTitleId = this.jobDetail.jobTitleId;
      this.newJobTitleId = this.jobDetail.jobTitleId;
    });
  }
}
export default JobLabelDetailAdminStore;
