import React from "react";

import { SALARY_RATE_TYPE_OPTIONS } from "../../../constants/negotiationWorksheet";

import { Card, CardListGroup, CardListGroupItem } from "../../../components/material";

const CalculatedResultsForSalary = (props) => {
  // console.log("salary res props", props)
  const { conversionOptions, currencySymbol, payValue, totalMarkup, totalTax } = props;

  var markupHourly = parseFloat(payValue) * (parseFloat(totalMarkup) / 100);

  return (
    <Card className="pt-worksheet__calculated-results">
      <CardListGroup className="pt-worksheet__calculated-results__list-group">
        <CardListGroupItem noBorder noPadding>
          <ul className="pt-worksheet__table-list pt-worksheet__table-list--header pt-worksheet__pointer">
            <li
              key={SALARY_RATE_TYPE_OPTIONS.value}
              className={
                SALARY_RATE_TYPE_OPTIONS.value === 5
                  ? "pt-worksheet__table-list--selected"
                  : null
              }
            >
              {SALARY_RATE_TYPE_OPTIONS.label}
            </li>
          </ul>
        </CardListGroupItem>
        <CardListGroupItem noBorder noPadding>
          <h4 className="pt-worksheet__table-list__header">Base Salary</h4>
          <ul className="pt-worksheet__table-list">
            <li
              key={SALARY_RATE_TYPE_OPTIONS.value}
              className={
                SALARY_RATE_TYPE_OPTIONS.value === 5
                  ? "pt-worksheet__table-list--selected"
                  : null
              }
            >
              <span className="pt-worksheet__table-list__rate">
                {currencySymbol}
                {payValue}
              </span>
            </li>
          </ul>
        </CardListGroupItem>
        <CardListGroupItem noBorder noPadding>
          <h4 className="pt-worksheet__table-list__header">
            <span className="pt-worksheet__table-list__header__label">
              Benefits & Placement Fee
            </span>
            <div className="pt-worksheet__table-list__header__rate">
              {parseFloat(totalMarkup).toFixed(2)}%
            </div>
          </h4>
          <ul className="pt-worksheet__table-list">
            <li key={SALARY_RATE_TYPE_OPTIONS.value}>
              <span className="pt-worksheet__table-list__rate">
                {currencySymbol}
                {SALARY_RATE_TYPE_OPTIONS.convertFromHourly(
                  markupHourly,
                  conversionOptions
                ).toFixed(2)}
              </span>
            </li>
          </ul>
        </CardListGroupItem>
        <CardListGroupItem noBorder noPadding>
          <h4 className="pt-worksheet__table-list__header">Total Cost Of Employee</h4>
          <ul className="pt-worksheet__table-list">
            <li
              key={SALARY_RATE_TYPE_OPTIONS.value}
              className={
                SALARY_RATE_TYPE_OPTIONS.value === 5
                  ? "pt-worksheet__table-list--selected"
                  : null
              }
            >
              <span className="pt-worksheet__table-list__rate">
                {currencySymbol}
                {(payValue + markupHourly).toFixed(2)}
              </span>
            </li>
          </ul>
        </CardListGroupItem>
        {totalTax ? (
          <CardListGroupItem noBorder noPadding>
            <h4 className="pt-worksheet__table-list__header">Total Tax</h4>
            <ul className="pt-worksheet__table-list">
              <li
                key={SALARY_RATE_TYPE_OPTIONS.value}
                className={
                  SALARY_RATE_TYPE_OPTIONS.value === 5
                    ? "pt-worksheet__table-list--selected"
                    : null
                }
              >
                <span className="pt-worksheet__table-list__rate">
                  {currencySymbol}
                  {((payValue + markupHourly) * (1 + parseFloat(totalTax) / 100)).toFixed(
                    2
                  )}
                </span>
              </li>
            </ul>
          </CardListGroupItem>
        ) : null}
      </CardListGroup>
    </Card>
  );
};

export default CalculatedResultsForSalary;
