import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";

const SelectableItemsList = observer((props) => {
  // This is a fix for IE11. the div containing the items (selectable-list-container)
  // would get a height of -0.01px when the first letter typed into the filter box didn't
  // matched any item. This would cause a HUGE div height to be rendered. So we're explicitly
  // setting the height to 0 when there're no items on the list
  const isEmpty =
    !props.children || (Array.isArray(props.children) && props.children.length === 0);

  const classes = classNames(props.className, "selectable-list-container", {
    empty: isEmpty,
  });

  return (
    <div className={classes} style={props.style}>
      {props.children}
    </div>
  );
});

SelectableItemsList.propTypes = {
  className: PropTypes.string,
  empty: PropTypes.bool,
};

SelectableItemsList.defaultProps = {
  className: "",
  empty: false,
};

export default SelectableItemsList;
