import React from "react";

import Icon from "../../../../components/lib/Icon";
import Text from "../../../../components/lib/Text";
import { InlineElements } from "../../../../components/lib/Inline";
import NumberInput, {
  isFiniteNumber,
  InputProps,
} from "../../../../components/lib/NumberInput";

type EditableNumberInputProps = Omit<InputProps, "value"> & {
  editable: boolean;
  value: number | null;
  initialValue: number;
};

export const EditableNumberInput = (props: EditableNumberInputProps) => {
  const { editable = false, disabled, value, initialValue, onChange, ...rest } = props;

  // state
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const toggleIsEditing = React.useCallback(
    () => setIsEditing((prevState) => !prevState),
    []
  );

  const resultingValue = React.useMemo<number | null>(
    () => (isEditing ? value : isFiniteNumber(value) ? value : initialValue),
    [isEditing, value, initialValue]
  );

  const isValidValue = React.useMemo<boolean>(
    () => isFiniteNumber(resultingValue),
    [resultingValue]
  );

  return (
    <InlineElements nowrap css={{ justifyContent: "flex-end" }}>
      {editable && (
        <Text
          color="accent"
          onClick={editable ? toggleIsEditing : undefined}
          css={{
            cursor: "pointer",
            "&:hover": { transform: "scale(1.1)" },
            "&:active": { transform: "scale(0.9)" },
          }}
        >
          <Icon icon="pencil-alt" />
        </Text>
      )}
      <NumberInput
        iconRight={isValidValue ? "check" : "remove"}
        color={isValidValue ? "primary" : "danger"}
        value={isValidValue ? resultingValue ?? "" : ""}
        onChange={editable ? onChange : undefined}
        disabled={!editable || !isEditing}
        {...rest}
      />
    </InlineElements>
  );
};

export default EditableNumberInput;
