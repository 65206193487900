// @flow
import * as React from "react";
import { observer } from "mobx-react";
import { NavBarQueryLoader } from "../../components/NavBar2";

class RateSearchContainer extends React.Component {
  constructor(props: Props) {
    super(props);

    this.state = {
      pageFixed: false,
    };
  }

  setPageFixed = (fixed: boolean) => {
    this.setState({
      pageFixed: fixed,
    });
  };

  render() {
    const props = this.props;
    const user =
      props.sessionInfo && props.sessionInfo.user ? props.sessionInfo.user : null;

    if (!props.sessionInfo || !user) {
      props.router.replace({
        pathname: "/login",
        state: {
          error: {
            message: "User not logged in.",
          },
        },
      });
      return <code>Redirecting to login.</code>;
    }

    const pageStyle = this.state.pageFixed
      ? {
          position: "fixed",
        }
      : null;

    function logout() {
      this.props
        .fetchGraphQL("mutation logout{ logoutUser{ ok } }", null)
        .then((res) => {
          this.props.updateLoginState(false, null, null);
          this.props.router.push({
            pathname: "/login",
          });
        })
        .catch((e) => {
          console.error("Error logging user out", e);
          this.props.updateLoginState(false, null, null);
          this.props.router.push({
            pathname: "/login",
          });
        });
    }

    return (
      <div>
        <React.Suspense fallback={null}>
          <NavBarQueryLoader router={props.router} logout={logout} />
        </React.Suspense>
        <div style={pageStyle}>{props.children}</div>
      </div>
    );
  }
}

export default observer(RateSearchContainer);
