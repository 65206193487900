// @flow
import { action } from "mobx";

import FilterObject, { FilterWithSort, ContainsTextFilter } from "../Filter";
import type FilterColumn from "../Filter";
import Sort from "../Sort";
import SortState from "../SortState";

export default class UserNameFilter extends ContainsTextFilter(FilterWithSort) {
  constructor(
    store: Object,
    column: FilterColumn,
    applyFilter: (FilterColumn, FilterObject) => void,
    applySort: (FilterColumn, Sort) => void,
    removeFilter: (FilterColumn) => void,
    removeSort: (FilterColumn) => void
  ) {
    super(store, column);

    this.applyFilter = applyFilter;
    this.applySort = applySort;
    this.removeFilter = removeFilter;
    this.removeSort = removeSort;

    this.sortState = new SortState("REGION_LABEL");

    this.buildQueryFilter = action(this.buildQueryFilter.bind(this));
    this.onApply = action(this.onApply.bind(this));
  }

  buildQueryFilter() {
    if (!this.textToLookFor || !this.textToLookFor.trim()) {
      return null;
    }

    //return null;

    let params = [];
    let args = [];
    let vars = {};

    // params.push("$firstName_Icontains: String");
    // args.push("firstName_Icontains: $firstName_Icontains");
    // vars.firstName_Icontains = this.textToLookFor;

    // params.push("$lastName_Icontains: String");
    // args.push("lastName_Icontains: $lastName_Icontains");
    // vars.lastName_Icontains = this.textToLookFor;

    return new FilterObject(params, args, vars);
  }
}
