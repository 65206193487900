import React, { useMemo, useCallback } from "react";
import Stack from "../../../../components/lib/Stack";
import {
  Card,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
  CardBody,
  CardHeaderTitle,
} from "../../../../components/lib/Card";
import { NavigationButton } from "../../../../components/lib/Button";
import { dedupeLocationValues } from "../../../validator5K_admin/constants";
import Text from "../../../../components/lib/Text";
import SurveysPeckingOrderList from "./SurveysPeckingOrderList";
import { useReviewDataContext } from "../../../validator5K_admin/context/ReviewDataContext";
import { useVal5KPublicContext } from "../../context/Val5KPublicContext";

import { RATE_TYPES_LABELS, REVIEW_TYPES } from "../../../validator5K_admin/types";

export const SurveysPeckingOrderPage = () => {
  const {
    reviewData,
    reviewType,
    reviewsGroupId,
    firstJobData,
    firstJobRateType: rateType,
    reviewLink,
  } = useReviewDataContext();

  const { navigateToSurveysList, router } = useVal5KPublicContext();

  const countriesString = reviewData?.get("countries_string");
  const isRegionBased = firstJobData?.get("region");

  // parts of UI

  const jobTitle =
    reviewType === REVIEW_TYPES.ORDERED
      ? reviewData.get("title")
      : firstJobData.get("title");

  const regionString = useMemo(() => {
    if (!firstJobData?.size) return "";

    const region = firstJobData.get("region");
    const country = firstJobData.get("country");

    return [dedupeLocationValues(region), dedupeLocationValues(country)]
      .filter((i) => i && i)
      .join(", ");
  }, [firstJobData]);

  const locationString = useMemo(() => {
    if (!firstJobData?.size) return "";

    const city = firstJobData.get("city");
    const state = firstJobData.get("state");
    const country = firstJobData.get("country");

    return [
      dedupeLocationValues(city),
      dedupeLocationValues(state),
      dedupeLocationValues(country),
    ]
      .filter((i) => i && i)
      .join(", ");
  }, [firstJobData]);

  const locationTitle = useMemo(() => {
    if (reviewType !== REVIEW_TYPES.ORDERED) {
      return isRegionBased ? regionString : locationString;
    } else {
      return countriesString;
    }
  }, [reviewType, countriesString, isRegionBased, locationString, regionString]);

  const headerTitle = (
    <>
      {jobTitle}, {RATE_TYPES_LABELS[rateType]}&nbsp;
      <Text as="small">({locationTitle ? locationTitle : ""})</Text>
    </>
  );

  // handler

  const handleGoBackToSurveys = useCallback(() => {
    router.push(reviewLink);
  }, [reviewLink, router]);

  // render

  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft>
          <CardHeaderTitle>{headerTitle}</CardHeaderTitle>
        </CardActionsLeft>
        <CardActionsRight>
          <NavigationButton icon="arrow-left" onClick={handleGoBackToSurveys}>
            Back to Survey
          </NavigationButton>
          <NavigationButton icon="arrow-left" onClick={navigateToSurveysList}>
            Back to Surveys List
          </NavigationButton>
        </CardActionsRight>
      </CardActions>
      <CardBody>
        <Stack fill css={{ gap: "$8" }}>
          <Text as="h2" css={{ textAlign: "center", color: "$primary" }}>
            Review your answers and wages for accuracy.
          </Text>
          <Text as="h4" css={{ textAlign: "center" }}>
            Make sure that the jobs appear in the right order.
            <br />
            You should expect to see the lowest paid jobs at the top and the highest paid
            jobs at the bottom.
          </Text>
          {reviewsGroupId && <SurveysPeckingOrderList />}
        </Stack>
      </CardBody>
    </Card>
  );
};

SurveysPeckingOrderPage.displayName = "SurveysPeckingOrderPage";

export default SurveysPeckingOrderPage;
