import graphql from "babel-plugin-relay/macro";
import { Set } from "immutable";
import { useMemo } from "react";
import { useLazyLoadQuery } from "react-relay";
import { isNonNullable } from "../../../utils/hashmap";
import { useSearchDetailsQuery } from "./__generated__/useSearchDetailsQuery.graphql";

type LocationSearch = {
  fullTitle: string;
  fullSubtitle: null;
  countryId: number;
  locationId: string;
};

const query = graphql`
  query useSearchDetailsQuery(
    $jobTitle: String
    $industryId: ID
    $fromDateTime: DateTime
    $toDateTime: DateTime
  ) {
    viewer {
      savedsearches(
        filters: {
          jobTitles: [{ jobTitle: $jobTitle }]
          industries: [$industryId]
          fromDateTime: $fromDateTime
          toDateTime: $toDateTime
        }
      ) {
        edges {
          node @required(action: NONE) {
            searchId @required(action: NONE)
            locationId
            locationFullLabel @required(action: NONE)
            locationLabel
            currency {
              country @required(action: NONE) {
                locationId @required(action: NONE)
              }
            }
            region {
              name @required(action: NONE)
              regionId @required(action: NONE)
              locations {
                locationId @required(action: NONE)
                name
                parent {
                  locationId @required(action: NONE)
                  name
                }
              }
            }
          }
        }
      }
      jobTitles(search: $jobTitle, page: 1, pageSize: 25) {
        results {
          id @required(action: NONE)
          title @required(action: NONE)
          isJobLabel
          collection
          isHealthcare: showQuantiles
          shareInfo {
            isMine
            sharedBy {
              firstName
              lastName
            }
          }
          clientJobLibraryInfo {
            adhocCountries
            certifiedCountries
          }
        }
      }
    }
  }
`;

type DetailsVariables = useSearchDetailsQuery["variables"] & {
  jobTitleId?: number | null;
};

/**
 * Queries the data neede to initialiaze the side menu form.
 */
export function useSearchDetails(variables: DetailsVariables) {
  const data = useLazyLoadQuery<useSearchDetailsQuery>(query, variables);

  const searchIDs = useMemo(() => {
    const edges = data.viewer?.savedsearches?.edges || [];
    return Set(edges.filter(isNonNullable).map((edge) => edge.node.searchId));
  }, [data]);

  const regions = useMemo(
    () =>
      data.viewer?.savedsearches?.edges
        .map((edge) => edge?.node?.region)
        ?.filter(isNonNullable) || [],
    [data]
  );

  const locations = useMemo(() => {
    return (
      data.viewer?.savedsearches?.edges.reduce((list, edge) => {
        const node = edge?.node;
        if (!node?.locationId || !node?.currency) return list;
        const locId = String(node?.locationId!);

        if (list.every((l) => l.locationId !== locId))
          list.push({
            fullTitle: node?.locationFullLabel!,
            fullSubtitle: null,
            countryId: node?.currency?.country?.locationId!,
            locationId: locId,
          });

        return list;
      }, [] as LocationSearch[]) ?? []
    );
  }, [data]);

  const jobTitle = useMemo(() => {
    const results = data.viewer?.jobTitles?.results;
    const job = results?.find((j) => j?.id === String(variables.jobTitleId)) || null;
    return job;
  }, [data, variables.jobTitleId]);

  return {
    searchIDs,
    locations,
    regions,
    jobTitle,
  };
}
