// @flow
import React from "react";
import Modal from "../../ratecards/components/Modal";
import ContainerSection from "../../ratecards/components/ContainerSection";
import { observer } from "mobx-react";
import BuyRateState from "../../../models/BuyRateState";
import { ModalButtons, ModalTitle } from "../../ratecards/components/FilterModal";
import Button from "../../../components/lib/Button";

type Props = {
  buyRateState: BuyRateState,
  title: string,
  children: any,
};

const PunchOutModal = (props: Props) => (
  <Modal show={props.buyRateState.modal.show} onHide={props.buyRateState.hideModal}>
    <ContainerSection className="header">
      <ModalTitle>{props.title}</ModalTitle>
    </ContainerSection>
    {props.children}
    <ContainerSection className="footer">
      <ModalButtons>
        <Button size="large" color="brand" onClick={props.buyRateState.submit}>
          Create Punchout
        </Button>
        <Button size="large" onClick={props.buyRateState.resetAndClose}>
          Cancel
        </Button>
      </ModalButtons>
    </ContainerSection>
  </Modal>
);

export default observer(PunchOutModal);
