import { styled } from "../../stitches.config";
import Box from "./Box";

const Stack = styled(Box, {
  display: "flex",
  flexDirection: "column",
  gap: "$4",
  justifyContent: "flex-start",
  alignItems: "center",
  flexWrap: "nowrap",
  variants: {
    fill: {
      true: {
        width: "$full",
      },
    },
    nogap: {
      true: {
        gap: 0,
      },
    },
  },
  defaultVariant: {
    fill: false,
    nogap: false,
  },
});

Stack.displayName = "Stack";

export default Stack;
