import React from "react";
import PropTypes from "prop-types";
import * as PercentField from "./PercentField";
import * as NegotiationWorksheetPropTypes from "../../../../prop_types/NegotationWorksheet";

import { FormControl, InputGroup } from "react-bootstrap";
import {
  RATE_TYPE_OPTIONS,
  SALARY_RATE_TYPE_OPTIONS,
} from "../../../../constants/negotiationWorksheet";

export const Field = PercentField.Field;
export const valueType = "tax";

export class CalculatedValue extends React.Component {
  static propTypes = {
    value: PropTypes.number.isRequired,
    billRateHourly: PropTypes.number.isRequired,
    conversionOptions: NegotiationWorksheetPropTypes.JSConversionOptions.isRequired,
    currencySymbol: NegotiationWorksheetPropTypes.CurrencySymbol.isRequired,
  };

  shouldComponentUpdate(nextProps) {
    const props = this.props;

    return (
      nextProps.payType !== props.payType ||
      nextProps.billRateHourly !== props.payRateHourly ||
      nextProps.rateType !== props.rateType ||
      nextProps.conversionOptions !== props.conversionOptions ||
      nextProps.currencySymbol !== props.currencySymbol ||
      nextProps.value !== props.value
    );
  }

  render() {
    const {
      value,
      billRateHourly,
      rateType,
      payType,
      conversionOptions,
      currencySymbol,
    } = this.props;
    const calculatedValue =
      payType === 3
        ? SALARY_RATE_TYPE_OPTIONS.convertFromHourly(
            (value / 100) * billRateHourly,
            conversionOptions
          )
        : RATE_TYPE_OPTIONS[rateType].convertFromHourly(
            (value / 100) * billRateHourly,
            conversionOptions
          );

    return (
      <InputGroup>
        <InputGroup.Addon>{currencySymbol}</InputGroup.Addon>
        <FormControl type="number" value={calculatedValue.toFixed(2)} disabled={true} />
      </InputGroup>
    );
  }
}
