import React, { useRef, useCallback } from "react";
import { fromJS } from "immutable";
import { styled } from "../../../stitches.config";

import Icon from "../../../components/lib/Icon";
import PromiseButton from "../../../components/lib/PromiseButton";
import { ButtonGroup } from "../../../components/lib/ButtonGroup";
import Alert from "../../../components/lib/Alert";
import Box from "../../../components/lib/Box";

import {
  djangoPaginationKey,
  djangoPaginationSizeKey,
  FilterTypes,
} from "../../../components/tables/constants";
import { useTableSchemaState } from "../../../components/tables/Table";
import { Column } from "../../../components/tables";

// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";

import { TickerContentLoader } from "../../../components/lib/TickerLoader";

import { transformProgramAccessData } from "../dataConverters";
import { useScrollTo } from "../hooks";
import { usePLIContext } from "../context";
import {
  PROGRAM_ACCESS_STATUSES,
  PROGRAM_ACCESS_STATUSES_LABELS,
  PROGRAM_ACCESS_STATUSES_TYPE,
} from "../types";
import { PROGRAM_ACCESS_STATUSES_OPTIONS, dateFormatter } from "../constants";

import { renderTableColumnsSpecs, TableFilterableRestful } from "./ExtendedRestfulTables";

import type {
  DataProviderRestful,
  FiltersDataProviderRestful,
  RestfulTableDataStateObject,
} from "../../../components/tables/types";
import type { TableFilterableRestfulProps } from "../../../components/tables/TableFilterableRestful";
import type { DjangoPaginatedResponse } from "../../../types/django";
import type { FetchAPIResponse } from "../../../types/fetch";
import type {
  ProgramAccessDataMap,
  ProgramAccessDataObject,
  TableColumnSpecsObject,
} from "../types";

const StatusLabel = styled(Box, {
  padding: "$2 $3",
  borderRadius: "$sm",
  backgroundColor: "$primaryLighter",
  color: "$primaryDark",
  variants: {
    danger: {
      true: {
        backgroundColor: "$dangerLightest",
        color: "$dangerDark",
      },
    },
    success: {
      true: {
        backgroundColor: "$successLightest",
        color: "$successDark",
      },
    },
  },
});

export const programsAccessTableColumnSpecs: TableColumnSpecsObject<ProgramAccessDataMap>[] =
  [
    {
      type: "column",
      uniqueKey: "user_id",
      title: "User Id",
      getter: (row) => row.get("user_id"),
      filterType: FilterTypes.NUMBERS_RANGE,
      css: { width: "100px" },
    },
    {
      type: "column",
      uniqueKey: "user_name",
      title: "User Name",
      getter: (row) => row.get("user_name"),
      filterType: FilterTypes.VALUES_CHECKLIST,
    },
    {
      type: "column",
      uniqueKey: "created",
      title: "Requested On",
      getter: (row) => row.get("created"),
      formatter: dateFormatter,
      filterType: FilterTypes.DATES_RANGE,
      css: { width: "150px" },
    },
    {
      type: "column",
      uniqueKey: "status",
      title: "Status",
      getter: (row) => row.get("status"),
      formatter: (value: PROGRAM_ACCESS_STATUSES_TYPE) => {
        const isApproved = value === PROGRAM_ACCESS_STATUSES.APPROVED;
        const isDeclined = value === PROGRAM_ACCESS_STATUSES.DECLINED;
        const icon = isApproved ? (
          <Icon icon="check" />
        ) : isDeclined ? (
          <Icon icon="times" />
        ) : undefined;

        return (
          <StatusLabel success={isApproved} danger={isDeclined}>
            {icon} {PROGRAM_ACCESS_STATUSES_LABELS[value]}
          </StatusLabel>
        );
      },
      filterType: FilterTypes.ENUMERATION,
      filterOptions: fromJS(PROGRAM_ACCESS_STATUSES_OPTIONS),
      css: { width: "100px" },
    },
  ];

type ProgramsAccessTableProps = Omit<TableFilterableRestfulProps, "schema">;

export const ProgramsAccessTable = (props: ProgramsAccessTableProps) => {
  const [schema] = useTableSchemaState(props.children);
  return <TableFilterableRestful {...props} schema={schema} />;
};

ProgramsAccessTable.displayName = "ProgramsAccessTable";
ProgramsAccessTable.defaultProps = {
  ...TableFilterableRestful.defaultProps,
  itemsPerPage: 10,
};

type ProgramAccessTableRowActionsProps = {
  programId: number;
  row: ProgramAccessDataMap;
  refreshProgramAccessData: () => Promise<any>;
};

const ProgramAccessTableRowActions = (props: ProgramAccessTableRowActionsProps) => {
  const { programId, row, refreshProgramAccessData } = props;

  const { fetchM8API, showModalError } = usePLIContext();

  const status = row.get("status");
  const requestId = row.get("id");

  // handlers
  const updateRequestStatus = useCallback(
    async (newStatus: PROGRAM_ACCESS_STATUSES_TYPE) => {
      try {
        await fetchM8API(`programs/${programId}/access_list/${requestId}/`, {
          method: "patch",
          data: { status: newStatus },
        });
      } catch (err) {
        logAsyncOperationError("updateProgramAccessRequest", err);
        showModalError("Error occurred while updating program access request.");
      }

      return await refreshProgramAccessData();
    },
    [refreshProgramAccessData, requestId, programId, fetchM8API, showModalError]
  );

  const handleApproveRequest = useCallback(
    async () => updateRequestStatus(PROGRAM_ACCESS_STATUSES.APPROVED),
    [updateRequestStatus]
  );

  const handleDeclineRequest = useCallback(
    async () => updateRequestStatus(PROGRAM_ACCESS_STATUSES.DECLINED),
    [updateRequestStatus]
  );

  return (
    <ButtonGroup fill css={{ justifyContent: "center", "& button": { width: "100px" } }}>
      {(
        [
          PROGRAM_ACCESS_STATUSES.PENDING,
          PROGRAM_ACCESS_STATUSES.DECLINED,
        ] as PROGRAM_ACCESS_STATUSES_TYPE[]
      ).indexOf(status) >= 0 && (
        <PromiseButton
          color="success"
          variant="outlined"
          icon="check"
          loadingText="Approve"
          onClick={handleApproveRequest}
        >
          Approve
        </PromiseButton>
      )}
      {(
        [
          PROGRAM_ACCESS_STATUSES.PENDING,
          PROGRAM_ACCESS_STATUSES.APPROVED,
        ] as PROGRAM_ACCESS_STATUSES_TYPE[]
      ).indexOf(status) >= 0 && (
        <PromiseButton
          color="danger"
          variant="outlined"
          icon="times"
          loadingText="Decline"
          onClick={handleDeclineRequest}
        >
          Decline
        </PromiseButton>
      )}
    </ButtonGroup>
  );
};

ProgramAccessTableRowActions.displayName = "ProgramAccessTableRowActions";

type ProgramAccessTableDataState = RestfulTableDataStateObject<ProgramAccessDataMap> & {
  loaded: boolean;
};

type ProgramAccessTableViewProps = {
  programId: number;
};

const ProgramAccessTableView = (props: ProgramAccessTableViewProps) => {
  const { programId } = props;
  const { fetchM8FilteringAPI, showModalError } = usePLIContext();

  // table state
  const [accessTableState, setAccessTableState] =
    React.useState<ProgramAccessTableDataState>({
      data: ProgramsAccessTable.defaultProps.data as ProgramAccessTableDataState["data"],
      itemsCount: ProgramsAccessTable.defaultProps.itemsCount,
      activePage: ProgramsAccessTable.defaultProps.activePage,
      itemsPerPage: ProgramsAccessTable.defaultProps.itemsPerPage,
      filters: ProgramsAccessTable.defaultProps
        .filters as ProgramAccessTableDataState["filters"],
      filtersQuery: ProgramsAccessTable.defaultProps
        .filtersQuery as ProgramAccessTableDataState["filtersQuery"],
      columnsFiltersQueries: ProgramsAccessTable.defaultProps
        .columnsFiltersQueries as ProgramAccessTableDataState["columnsFiltersQueries"],
      loaded: false,
    });

  const emptyData = !accessTableState.data || !accessTableState.data.size;
  const noFiltersApplied = !accessTableState.filters || !accessTableState.filters.size;
  const hasNoData = emptyData && noFiltersApplied;

  // table data sources

  const fetchProgramAccessData: DataProviderRestful<ProgramAccessDataMap> = useCallback(
    async (urlQuery = {}, filtersQuery = {}, nextStateUpdates = {}) => {
      try {
        const response: FetchAPIResponse<
          DjangoPaginatedResponse<ProgramAccessDataObject>
        > = await fetchM8FilteringAPI(`programs/${programId}/access_list/filtered/`, {
          params: urlQuery,
          data: filtersQuery,
        });
        const nextDataState: Partial<ProgramAccessTableDataState> =
          transformProgramAccessData(response.data);

        setAccessTableState((prevState) => ({
          ...prevState,
          ...nextStateUpdates,
          ...nextDataState,
          loaded: true,
        }));

        return nextDataState;
      } catch (err) {
        logAsyncOperationError("fetchProgramAccessList", err);
        showModalError(
          "Error occurred while retrieving program access list. Please, try again later."
        );
        throw err;
      }
    },
    [programId, setAccessTableState, fetchM8FilteringAPI, showModalError]
  );

  const fetchProgramAccessFiltersData: FiltersDataProviderRestful = useCallback(
    async (urlQuery = {}, filtersQuery = {}) => {
      try {
        const response: FetchAPIResponse<DjangoPaginatedResponse<any>> =
          await fetchM8FilteringAPI(
            `programs/${programId}/access_list/values/filtered/`,
            {
              params: urlQuery,
              data: filtersQuery,
            }
          );

        return response.data;
      } catch (err) {
        logAsyncOperationError("fetchProgramAccessFilterValues", err);
        showModalError(
          "Error occurred while retrieving filter values. Please, try again later."
        );
        throw err;
      }
    },
    [programId, fetchM8FilteringAPI, showModalError]
  );

  // utility funcs

  const refreshProgramAccessData = useCallback(() => {
    return fetchProgramAccessData(
      {
        [djangoPaginationKey]: accessTableState.activePage,
        [djangoPaginationSizeKey]: accessTableState.itemsPerPage,
      },
      accessTableState.filtersQuery.toJS()
    );
  }, [
    accessTableState.activePage,
    accessTableState.itemsPerPage,
    accessTableState.filtersQuery,
    fetchProgramAccessData,
  ]);

  // scroll to the top

  const scrollToRef = useScrollTo([accessTableState.activePage]);

  // initial data fetch

  const accessTableLoadedRef = useRef<boolean>(false);

  React.useEffect(() => {
    if (!accessTableLoadedRef.current) {
      refreshProgramAccessData();
      accessTableLoadedRef.current = true;
    }
  }, [refreshProgramAccessData, accessTableLoadedRef]);

  if (!accessTableState.loaded) {
    return (
      <Box fill css={{ minHeight: "200px" }}>
        <TickerContentLoader />
      </Box>
    );
  }
  if (hasNoData) {
    return (
      <Alert color="warning">
        <h5>No access requests found </h5>
      </Alert>
    );
  }

  const renderRowActions = (value: any, row: ProgramAccessDataMap) => (
    <ProgramAccessTableRowActions
      programId={programId}
      row={row}
      refreshProgramAccessData={refreshProgramAccessData}
    />
  );

  const columns = renderTableColumnsSpecs(programsAccessTableColumnSpecs).concat(
    <Column
      key="__actions"
      uniqueKey="__actions"
      title="Actions"
      formatter={renderRowActions}
    />
  );

  return (
    <Box fill ref={scrollToRef}>
      <ProgramsAccessTable
        multimode
        dataProvider={fetchProgramAccessData}
        filtersDataProvider={fetchProgramAccessFiltersData}
        {...accessTableState}
      >
        {columns}
      </ProgramsAccessTable>
    </Box>
  );
};

ProgramAccessTableView.displayName = "ProgramAccessTableView";

export default ProgramAccessTableView;
