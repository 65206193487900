export type Listener<T> = (value: T) => void;

/**
 * Observer is a behavioral design pattern, which lets you define a subscription
 * mechanism to notify multiple objects about any events that
 * happen to the object they’re observing.
 */
export class Observer<T = undefined> {
  listeners: Set<Listener<T>>;

  /**
   * Creates a new Observer
   */
  constructor() {
    this.listeners = new Set<Listener<T>>();
  }

  /**
   * Adds a listener function that will be called,
   * when this observer emits a value/event
   */
  public subscribe(listener: Listener<T>) {
    this.listeners.add(listener);
    return () => this.unsubscribe(listener);
  }

  /**
   * Removes a listener function from the observer
   */
  public unsubscribe(listener: Listener<T>) {
    if (this.listeners.has(listener)) {
      this.listeners.delete(listener);
    }
  }

  /**
   * Executes all subscribed listeners with a given value
   */
  public emit(value: T) {
    this.listeners.forEach((listener) => {
      listener(value);
    });
  }
}
