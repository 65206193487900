import React from "react";

import Icon from "../../../components/lib/Icon";
import {
  Checkbox,
  CheckboxIndicator,
  LabelInline,
} from "../../../components/lib/Checkbox";
import Inline from "../../../components/lib/Inline";

type CheckboxQuestionInputProps = React.ComponentProps<typeof Checkbox> & {
  value: boolean;
  onChange: (value: boolean) => void;
};

export const CheckboxQuestionInput = (props: CheckboxQuestionInputProps) => {
  const { value, onChange, ...rest } = props;

  return (
    <Inline>
      <LabelInline bold={false}>
        <Checkbox
          {...rest}
          checked={value === true}
          onCheckedChange={(_) => onChange(true)}
        >
          <CheckboxIndicator>
            <Icon icon="check" />
          </CheckboxIndicator>
        </Checkbox>
        <span>Yes</span>
      </LabelInline>

      <LabelInline bold={false}>
        <Checkbox
          {...rest}
          checked={value === false}
          onCheckedChange={(_) => onChange(false)}
        >
          <CheckboxIndicator>
            <Icon icon="check" />
          </CheckboxIndicator>
        </Checkbox>
        <span>No</span>
      </LabelInline>
    </Inline>
  );
};

CheckboxQuestionInput.displayName = "CheckboxQuestionInput";

export default CheckboxQuestionInput;
