// @flow

import React from "react";
import { observer } from "mobx-react";
import RateCardListStore from "../../../stores/mobx/RateCardListStore";
import Modal from "./Modal";

const RateCardsListHelpModal = (props: { rateCardListStore: RateCardListStore }) => {
  const rateCardListStore = props.rateCardListStore;

  return (
    <Modal
      show={rateCardListStore.helpModal.show}
      onHide={rateCardListStore.helpModal.hideModal}
    >
      <div className="container-section header">
        <h4>Rate Cards Help</h4>
      </div>
      <div className="container-section footer">
        <p>
          This page contains all of the Rate Cards you have access to. Click{" "}
          <b>New Rate Card</b> to add a new Rate Card from your draft searches. Click{" "}
          <b>View</b> to view, edit or delete searches in a Rate Card.
        </p>
        <h4>Edit Mode</h4>
        <p>
          Perform bulk editing, sharing, exports, and updating on your Rate Cards using
          the <b>Edit</b> button. The <b>Edit</b> mode will also allow you to share
          specific rate cards among your company users. To export the entire rate card to
          Excel/CSV formats, click <b>Edit</b> and then select the rate cards you would
          like to export.
        </p>
      </div>
    </Modal>
  );
};

export default observer(RateCardsListHelpModal);
