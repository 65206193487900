import React from "react";
import { Set } from "immutable";
// LIB
import Text from "../../components/lib/Text";
import Stack from "../../components/lib/Stack";
import Inline from "../../components/lib/Inline";
import { Placeholder } from "../../components/lib/Placeholder";
// helpers
import { getAllCommaSkills, MIN_NUM_SKILLS } from "./rsSearchBySkillsUtil";
import RsViewSkills from "./components/RsViewSkills";
import { useFetchSkills } from "./hooks/useFetchSkills";

function getSkills(showFullSkills: boolean, skills: string[]) {
  if (!showFullSkills && skills && skills.length > MIN_NUM_SKILLS) {
    let retSkills = skills.slice(0, MIN_NUM_SKILLS - 1);
    retSkills.push("+" + (skills.length - MIN_NUM_SKILLS + 1) + " more skills");
    return retSkills;
  } else {
    return skills;
  }
}

interface Props {
  jobDescription: string;
  showFullSkills?: boolean;
  showSkillLabel?: boolean;
  showCommaSkills?: boolean;
  handleGetSkills?(skills: string[]): void;
}

export function JobTittleSkills(props: Props) {
  const {
    jobDescription,
    showFullSkills = true,
    showSkillLabel = true,
    showCommaSkills = false,
    handleGetSkills,
  } = props;
  const skills = useFetchSkills(jobDescription, handleGetSkills);

  if (skills.loading) {
    return <JobTittleSkillsLoader />;
  }

  if (skills.data.length > 0) {
    return (
      <Stack css={{ alignItems: "flex-start", marginBottom: "$5", gap: "$1" }}>
        {showSkillLabel && <Text>Skills</Text>}
        {!showCommaSkills && (
          <RsViewSkills skills={getSkills(showFullSkills, skills.data)} />
        )}
        {showCommaSkills && <Text>{getAllCommaSkills(skills.data)}</Text>}
      </Stack>
    );
  }

  return null;
}

export default JobTittleSkills;

const loaders = Set(new Array(30).fill(125).map((v) => Math.max(Math.random() * v, 50)));

export function JobTittleSkillsLoader({
  showSkillLabel = true,
}: {
  showSkillLabel?: boolean;
}) {
  return (
    <Stack css={{ alignItems: "flex-start", marginBottom: "$5", gap: "$1" }}>
      {showSkillLabel && <Text>Skills</Text>}
      <Inline css={{ gap: "$2" }}>
        {loaders.map((key) => (
          <Placeholder key={key} height={16} width={key} />
        ))}
      </Inline>
    </Stack>
  );
}
