import React from "react";
import { observer } from "mobx-react";
import Modal from "../ratecards/components/Modal";
import ContainerSection from "../ratecards/components/ContainerSection";
import { ModalButtons, ModalTitle } from "../ratecards/components/FilterModal";
import LinkButton from "../../components/lib/LinkButton";
import Button from "../../components/lib/Button";

class BuyMoreSearchesModal extends React.Component {
  render() {
    const props = this.props;

    return (
      <Modal
        show={props.modalState.show}
        onHide={props.modalState.hideModal}
        innerStyle={{ width: "500px" }}
      >
        <div className="rc-viewport overlap-lr-padding overlap-tb-padding">
          <ContainerSection className="header">
            <ModalTitle>Buy More Searches</ModalTitle>
          </ContainerSection>
          <ContainerSection>
            <p>
              Please visit our Search Allocator page to purchase more searches, click the
              go button below.
            </p>
          </ContainerSection>
          <ContainerSection className="footer">
            <ModalButtons>
              <LinkButton color="brand" size="large" to="/search-allocator">
                Go
              </LinkButton>
              <Button size="large" onClick={props.modalState.hideModal}>
                Cancel
              </Button>
            </ModalButtons>
          </ContainerSection>
        </div>
      </Modal>
    );
  }
}

export default observer(BuyMoreSearchesModal);
