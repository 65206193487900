import React from "react";

import { styled, CSS } from "../../../stitches.config";

import { Table as TableBase, TFoot as TFootBase, TBody, THead } from "../../lib/Table";
import Box from "../../lib/Box";

const Table = styled(TableBase, {
  width: "$full",
  borderCollapse: "collapse",
  borderSpacing: 0,
  background: "$white",
  fontSize: "inherit",
  lineHeight: "inherit",
  whiteSpace: "nowrap",
});

const TFoot = styled(TFootBase, {
  borderTop: "1px solid $primaryLight",
});

const TPagination = styled(Box, {});

const ScrollableBox = styled(Box, {
  border: "1px solid $primaryLight",
  overflowX: "auto",
});

// Outer container is important for attaching filters reset button
const OuterBox = styled(Box, {
  position: "relative",
});

type TableImplProps = {
  groups?: React.ReactElement;
  head: React.ReactElement;
  body: React.ReactElement | React.ReactElement[];
  foot?: React.ReactElement;
  pagination?: React.ReactNode;
  highlighted?: boolean;
  css?: CSS;
};

export const TableImpl = (props: TableImplProps) => {
  const { groups, head, body, foot, pagination, highlighted = false, css } = props;

  return (
    <OuterBox fill css={css}>
      <ScrollableBox fill>
        <Table highlighted={highlighted}>
          <THead>
            {groups}
            {head}
          </THead>
          <TBody>{body}</TBody>
          {foot && <TFoot>{foot}</TFoot>}
        </Table>

        {pagination && <TPagination>{pagination}</TPagination>}
      </ScrollableBox>
    </OuterBox>
  );
};

TableImpl.displayName = "TableImpl";

export default TableImpl;
