/**
 * @generated SignedSource<<e2937224d9fd40fc613a9df00bd1ef74>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ExportStatus = "FAILED" | "FINISHED" | "PENDING" | "PROCESSING" | "%future added value";
export type CreateSearchListExportInput = {
  clientIds?: ReadonlyArray<string | null> | null;
  clientMutationId?: string | null;
  confirmNoFilters?: boolean | null;
  createdAfter?: string | null;
  createdBefore?: string | null;
  rateCardIds?: ReadonlyArray<string | null> | null;
  searchIds?: ReadonlyArray<string | null> | null;
  userIds?: ReadonlyArray<string | null> | null;
};
export type AdminSearchesCreateSearchListExportMutation$variables = {
  input: CreateSearchListExportInput;
};
export type AdminSearchesCreateSearchListExportMutation$data = {
  readonly exportSearchList: {
    readonly errors: ReadonlyArray<{
      readonly __typename: "InvalidClientID";
      readonly message: string | null;
    } | {
      readonly __typename: "InvalidRateCardID";
      readonly message: string | null;
    } | {
      readonly __typename: "InvalidSearchID";
      readonly message: string | null;
    } | {
      readonly __typename: "InvalidUserID";
      readonly message: string | null;
    } | {
      readonly __typename: "UnsupportedIDType";
      readonly message: string | null;
      readonly type: string | null;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null> | null;
    readonly export: {
      readonly created: string;
      readonly expiresAt: string;
      readonly exportFile: string | null;
      readonly id: string;
      readonly modified: string;
      readonly status: ExportStatus | null;
    } | null;
  } | null;
};
export type AdminSearchesCreateSearchListExportMutation = {
  response: AdminSearchesCreateSearchListExportMutation$data;
  variables: AdminSearchesCreateSearchListExportMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateSearchListExportPayload",
    "kind": "LinkedField",
    "name": "exportSearchList",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SearchListExportNode",
        "kind": "LinkedField",
        "name": "export",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "modified",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "expiresAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "exportFile",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              }
            ],
            "type": "UnsupportedIDType",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": (v2/*: any*/),
            "type": "InvalidRateCardID",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": (v2/*: any*/),
            "type": "InvalidSearchID",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": (v2/*: any*/),
            "type": "InvalidClientID",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": (v2/*: any*/),
            "type": "InvalidUserID",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminSearchesCreateSearchListExportMutation",
    "selections": (v3/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdminSearchesCreateSearchListExportMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "2c5f1338f42115f5b660b2629324c262",
    "id": null,
    "metadata": {},
    "name": "AdminSearchesCreateSearchListExportMutation",
    "operationKind": "mutation",
    "text": "mutation AdminSearchesCreateSearchListExportMutation(\n  $input: CreateSearchListExportInput!\n) {\n  exportSearchList(input: $input) {\n    export {\n      id\n      status\n      created\n      modified\n      expiresAt\n      exportFile\n    }\n    errors {\n      __typename\n      ... on UnsupportedIDType {\n        message\n        type\n      }\n      ... on InvalidRateCardID {\n        message\n      }\n      ... on InvalidSearchID {\n        message\n      }\n      ... on InvalidClientID {\n        message\n      }\n      ... on InvalidUserID {\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fa2d7b7dccb63cd306a63d57bdc9d153";

export default node;
