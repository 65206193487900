// @flow

import React from "react";
import R from "ramda";
import { extendObservable, action, runInAction, computed, observable } from "mobx";
import axios from "axios";
import PaginationState from "../../models/PaginationState";
import NetworkState from "../../models/NetworkState";
import moment from "moment";
import type { PageQuery, PaginationInfo } from "../../models/PaginationState";
import RegionList from "../../models/RegionList";
import Region from "../../models/RegionOptions";
import FilterObject, { FILTER_COLUMN } from "../../models/Filter";
import { CreatedOnFilter, RegionFilter, TagsFilter } from "../../models/FilterState";
import { consolidateAppliedFilters, consolidateAppliedSorts } from "./SupportFunctions";
import ModalState from "../../models/ModalState";
import CountryFilter from "../../models/FilterState/CountryFilter";
import Sort, { SORT_DIRECTION } from "../../models/Sort";
import ApplyTagState from "../../models/ApplyTagState";
import CurrentUser from "../../models/User";
import MessageState from "../../models/MessageState";
import type { FetchGraphQL } from "../../App";

export type AffectedSearch = {
  databaseId: number,
  jobLabel: string,
  // jobTitle: string,
  regionName: string,
  rateCardName: ?string,
};

const countrysFilterCriteriaQuery = `
  query getCountries {
   viewer {
       regionsFilterCriteria {
         countries {
           country
       }
     }
   }
  }
`;

const tagsFilterCriteria = `
query getTags {
 viewer{
   tagsFilterCriteria{
     tagnames(contentType: REGIONS){
       tag
     }
   }
 }
}
`;

export class RegionListComponentStore {
  showHelpModal: boolean;
  getRegions: (PageQuery) => Promise<PaginationInfo>;
  getRegionList: () => void;
  network: NetworkState;
  pagination: PaginationState;
  totalCount: any;
  regionsList: RegionList[];
  regionOptionsList: Region;
  regionsListView: RegionList[];
  regionsListViewState: Object;
  createdOnFilter: CreatedOnFilter;
  regionFilter: RegionFilter;
  countryFilter: CountryFilter;
  tagsFilter: TagsFilter;

  defaultFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedSorts: {
    [key: FilterColumn]: Sort,
  };
  isFiltered: boolean;
  appliedSortsOrder: Array<FilterColumn>;
  unSelectedRegions: [];

  showHelp: () => void;
  hideHelp: () => void;
  applyDefaultFilter: (FilterColumn, FilterObject) => void;
  applyFilter: (FilterColumn, FilterObject) => void;
  removeFilter: (FilterColumn) => void;
  applySort: (FilterColumn, Sort) => void;
  removeSort: (FilterColumn) => void;
  clearFilters: () => void;
  isEditing: ?boolean;
  handleStartEdit: () => void;
  handleStopEdit: () => void;
  toggleSelectAllPage: (Object) => void;
  selectAllPage: (Event) => void;
  deselectAllPage: (Event) => void;
  clearAllSelections: () => void;
  changeRegionText: () => void;
  allSelected: boolean;
  allSelectedfilter: boolean;
  allOnPageSelected: boolean;
  allowMultipleItemSelection: boolean;
  confirmDeleteModal: ModalState;
  helpModal: ModalState;
  getSelectedRegionsList: () => Array<string>;
  getFilterCriteriaQuery: (FilterColumn) => GraphQLQuery;
  processFilterCriteria: (FilterColumn, Object) => Array<Object>;
  applyDefaultSort: () => void;
  regionTitle: string;
  regionTitleId: string;
  applyTagState: ApplyTagState;
  isTagView: ?boolean;
  setTagViewFn: () => void;
  CurrentUser: CurrentUser;
  selectAllOnPageItem: () => void;
  newRegionName: string;
  showRenameModal: () => void;
  renameModal: ModalState;
  onNewRegionNameChange: () => void;
  messaging: MessageState;
  getFirstSelectedRegion: () => void;
  clearAllSelectionsOnTagView: () => void;
  toDateRange: () => void;
  fetchGraphQL: FetchGraphQL;
  errorModal: ModalState;
  errorMessage: any;

  constructor(fetchGraphQL: FetchGraphQL) {
    this.fetchGraphQL = fetchGraphQL;
    this.getRegions = action(this.getRegions.bind(this));
    this.getRegionList = action(this.getRegionList.bind(this));

    this.applyFilter = action(this.applyFilter.bind(this));
    this.applySort = action(this.applySort.bind(this));
    this.removeFilter = action(this.removeFilter.bind(this));
    this.removeSort = action(this.removeSort.bind(this));
    this.toDateRange = action(this.toDateRange.bind(this));
    this.unSelectedRegions = [];
    this.changeRegionText = action(this.changeRegionText.bind(this));

    extendObservable(this, {
      helpModal: new ModalState(),
      network: new NetworkState(),
      pagination: new PaginationState(this.getRegions),
      totalCount: 0,
      regionsList: [],
      regionOptionsList: new Region(),
      allSelected: false,
      allSelectedfilter: false,
      regionTitle: "",
      regionsListViewState: observable.map({}),
      regionsListView: computed(() => {
        return this.regionsList.map((region) => {
          if (this.regionsListViewState.has(region.regionId)) {
            region.viewState = this.regionsListViewState.get(region.regionId);

            return region;
          }

          return region;
        });
      }),
      allOnPageSelected: computed(() => {
        const allTrue = R.all(R.equals(true));
        const selectedValues = this.regionsListView.map(
          (regionsListView) => regionsListView.viewState.selected
        );

        if (selectedValues.length === 0) {
          return false;
        }

        return allTrue(selectedValues);
      }),
      appliedSortsOrder: observable.shallow([]),
      createdOnFilter: new CreatedOnFilter(
        this,
        FILTER_COLUMN.REGION_DATE_RANGE,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      regionFilter: new RegionFilter(
        this,
        FILTER_COLUMN.REGION_LABEL,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      countryFilter: new CountryFilter(
        this,
        FILTER_COLUMN.COUNTRY,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      tagsFilter: new TagsFilter(
        this,
        FILTER_COLUMN.TAGS,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      defaultFilters: {},
      appliedFilters: {},
      appliedSorts: {},
      isFiltered: false,
      isEditing: null,
      allowMultipleItemSelection: true,
      selectedCount: computed(() => {
        const selectedValues = this.regionsListView.map(
          (region) => region.viewState.selected
        );

        if (this.allSelected) {
          return this.pagination.totalCount;
        }

        let count = 0;

        selectedValues.forEach((v) => {
          if (v) {
            count += 1;
          }
        });

        return count;
      }),
      confirmDeleteModal: new ModalState(),
      applyTagState: new ApplyTagState(fetchGraphQL, this),
      isTagView: null,
      renameModal: new ModalState(),
      newRegionName: "",
      messaging: new MessageState(),
      errorModal: new ModalState(),
      errorMessage: null,
    });
    this.CurrentUser = null;
    this.applyDefaultFilter = action(this.applyDefaultFilter.bind(this));
    this.handleStartEdit = action(this.handleStartEdit.bind(this));
    this.handleStopEdit = action(this.handleStopEdit.bind(this));
    this.clearFilters = action(this.clearFilters.bind(this));
    this.toggleSelectAllPage = action(this.toggleSelectAllPage.bind(this));
    this.selectAllPage = action(this.selectAllPage.bind(this));
    this.deselectAllPage = action(this.deselectAllPage.bind(this));
    this.clearAllSelections = action(this.clearAllSelections.bind(this));
    this.getSelectedRegionsList = action(this.getSelectedRegionsList.bind(this));
    this.getFilterCriteriaQuery = action(this.getFilterCriteriaQuery.bind(this));
    this.processFilterCriteria = action(this.processFilterCriteria.bind(this));
    this.showHelp = action(this.showHelp.bind(this));
    this.hideHelp = action(this.hideHelp.bind(this));
    this.applyDefaultSort = action(this.applyDefaultSort.bind(this));
    this.setTagViewFn = action(this.setTagViewFn.bind(this));
    this.selectAllOnPageItem = action(this.selectAllOnPageItem.bind(this));
    this.applyDefaultSort();
    this.showRenameModal = action(this.showRenameModal.bind(this));
    this.onNewRegionNameChange = action(this.onNewRegionNameChange.bind(this));
    this.getFirstSelectedRegion = action(this.getFirstSelectedRegion.bind(this));
    this.clearAllSelectionsOnTagView = action(
      this.clearAllSelectionsOnTagView.bind(this)
    );
  }

  showRenameModal() {
    this.messaging.removeAll();
    const region = this.getFirstSelectedRegion();
    if (!region) return;
    this.newRegionName = region.name;
    this.renameModal.showModal();
  }

  toDateRange(date) {
    var val = true;
    if (this.createdOnFilter.fromDate) {
      val = !date.isBetween(
        this.createdOnFilter.fromDate,
        moment(new Date()),
        "days",
        "[]"
      );
    }
    return val;
  }

  onNewRegionNameChange(e) {
    this.messaging.removeAll();
    this.newRegionName = e.target.value;
  }

  getFirstSelectedRegion() {
    for (let j = 0; j < this.regionsListView.length; j++) {
      if (this.regionsList[j].viewState.selected) return this.regionsList[j];
    }

    return null;
  }

  setTagViewFn() {
    this.isTagView = true;
    this.isEditing = true;
  }

  applyDefaultSort() {
    // this.createdOnFilter.sortState.direction = SORT_DIRECTION.DESC;
    // this.createdOnFilter.sort = this.createdOnFilter.buildQuerySort();
    // this.applySort(this.createdOnFilter.column, this.createdOnFilter.sort);

    const sort = new Sort("{field: NAME, direction: " + SORT_DIRECTION.ASC + "}");
    this.applySort("NAME", sort);
  }

  showHelp() {
    this.showHelpModal = true;
  }

  hideHelp() {
    this.showHelpModal = false;
  }

  handleStartEdit() {
    this.isEditing = true;
    this.regionsListView.forEach((region) => region.toggleEdit());
  }

  handleStopEdit() {
    this.isEditing = false;
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.regionsListViewState.forEach((viewState) => {
      viewState.selected = false;
      viewState.editing = false;
    });
  }

  toggleSelectAllPage(e: Object) {
    if (!this.allowMultipleItemSelection) return;

    const setValue = !this.allOnPageSelected;

    this.regionsListView.forEach((region) => {
      region.toggleSelected(e, null, null, setValue);
    });

    // When All items selected flag is up, clear selection
    if (setValue === false && this.allSelected) this.allSelected = false;
  }

  selectAllPage(e: Event) {
    this.allSelected = true;
    this.allSelectedfilter = true;
    this.unSelectedRegions = [];
  }

  selectAllOnPageItem(e: Event) {
    this.regionsListView.forEach((region) => {
      region.toggleSelected(e, null, null, true);
    });
  }

  deselectAllPage(e: Event) {
    this.regionsListView.forEach((region) => {
      region.toggleSelected(e, null, null, false);
    });

    this.allSelected = false;
    this.allSelectedfilter = false;
  }

  clearAllSelections() {
    this.isTagView = false;
    //    this.isEditing = false;
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.regionsListViewState.forEach((value) => {
      value.selected = false;
    });
  }

  clearAllSelectionsOnTagView() {
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.regionsListViewState.forEach((value) => {
      value.selected = false;
    });
  }

  applyFilter(column: FilterColumn, filter: FilterObject) {
    this.appliedFilters[column] = filter;
    this.isFiltered = true;
  }

  applyDefaultFilter(column: FilterColumn, filter: FilterObject) {
    this.defaultFilters[column] = filter;
  }

  removeFilter(column: FilterColumn) {
    delete this.appliedFilters[column];

    let entries = Object.entries(this.appliedFilters);
    if (!entries.length) this.isFiltered = false;
  }

  applySort(column: FilterColumn, sort: Sort) {
    this.appliedSorts[column] = sort;

    const index = this.appliedSortsOrder.indexOf(column);
    if (index === -1) this.appliedSortsOrder.push(column);
  }

  removeSort(column: FilterColumn) {
    delete this.appliedSorts[column];

    const index = this.appliedSortsOrder.indexOf(column);
    if (index > -1) this.appliedSortsOrder.splice(index, 1);
  }

  //clear all filters
  clearFilters() {
    this.countryFilter = new CountryFilter(
      this,
      FILTER_COLUMN.COUNTRY,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.regionFilter = new RegionFilter(
      this,
      FILTER_COLUMN.REGION_LABEL,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.createdOnFilter = new CreatedOnFilter(
      this,
      FILTER_COLUMN.REGION_DATE_RANGE,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.tagsFilter = new TagsFilter(
      this,
      FILTER_COLUMN.TAGS,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );

    this.appliedFilters = observable({});
    this.appliedSorts = observable({});
    this.appliedSortsOrder.length = 0;
    this.isFiltered = false;
    this.applyDefaultSort();

    return this.pagination.goFetch(null);
  }

  //creating country filter query
  getFilterCriteriaQuery(column: FilterColumn): GraphQLQuery {
    switch (column) {
      case FILTER_COLUMN.COUNTRY:
        return {
          query: countrysFilterCriteriaQuery,
          variables: {},
        };

      case FILTER_COLUMN.TAGS:
        return {
          query: tagsFilterCriteria,
          variables: {},
        };

      default:
        return null;
    }
  }

  //process country filter result data
  processFilterCriteria(column: FilterColumn, payload: Object): Array {
    switch (column) {
      case FILTER_COLUMN.COUNTRY:
        const countries: [
          {
            country: string,
          }
        ] = payload.data.viewer.regionsFilterCriteria.countries;
        let processedCountry = observable.map({});
        countries.forEach((country, index) => {
          processedCountry.set(String(index), {
            id: String(index),
            ...country,
          });
        });

        return processedCountry;

      case FILTER_COLUMN.TAGS:
        const tags: [
          {
            tag: string,
          }
        ] = payload.data.viewer.tagsFilterCriteria.tagnames;
        let processedTag = observable.map({});
        tags.forEach((tag, index) => {
          processedTag.set(String(index), {
            id: String(index),
            ...tag,
          });
        });
        return processedTag;

      default:
        return null;
    }
  }

  toggleAllItems() {
    if (!this.allowMultipleItemSelection) return;

    this.allSelected = !this.allSelected;

    if (this.allSelected === false) {
      this.projectCostsView.forEach((value) => {
        value.viewState.selected = false;
      });
    }
  }

  getSelectedRegionsList(): Array<string> {
    const regionsList = this.regionsListViewState;

    let selectedregionsList = [];

    regionsList.forEach((value, key) => {
      if (value.selected) {
        selectedregionsList.push(key);
      }
    });

    return selectedregionsList;
  }

  //function for get region list
  async getRegions(pageQuery: PageQuery): Promise<PaginationInfo> {
    let res = null;
    let params: string[] = pageQuery.params;
    let args = pageQuery.args;
    let variables = pageQuery.variables;
    let filtersCriteria: string[] = [];

    let sortCriteria: string[] = [];
    consolidateAppliedSorts(this.appliedSorts, sortCriteria);
    consolidateAppliedFilters(this.appliedFilters, params, filtersCriteria, variables);

    // NOTE: applied filters could override default filters, handle this if needed
    consolidateAppliedFilters(this.defaultFilters, params, filtersCriteria, variables);

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");
    const queryFiltersCriteria = filtersCriteria.join(", ");
    const querySortCriteria = sortCriteria.join(", ");

    const query = `
      query getRegions(${queryParams}){
        viewer{
          user{
            firstName
            lastName
            userId
            username
            email
          }
          regions(${queryArgs},filters: { ${queryFiltersCriteria} }, order: [${querySortCriteria}]){
            totalCount
            edges{
              node{
                regionId
                name
                country {
                  locationId
                  name
                }
                user {
                  firstName
                  lastName
                  userId
                }
                tags{
                  name
                  tagId
                }
                created
                searches(first: 0) {
                  totalCount
                }
              }
            }
          }
        }
      }
      `;

    this.network.loading = true;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      console.log("error", e);
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting Regions", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Regions query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getRegions--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get Region query", res)) {
        // TODO: Display user friendly error message
        return {
          totalCount: 0,
        };
      }
      this.currentUser = new CurrentUser(this, res.data.viewer.user);
      const regions = res.data.viewer.regions.edges;
      this.totalCount = res.data.viewer.regions.totalCount;
      // TODO: Deserialize this properly...
      this.regionsList = regions.map((reg) => {
        const region = new RegionList(this, reg.node);

        if (!this.regionsListViewState.has(region.regionId)) {
          this.regionsListViewState.set(region.regionId, {
            selected: this.allSelected,
            editing: this.isEditing,
          });
        } else {
          const selectedValue = this.allSelected
            ? true
            : this.regionsListViewState.get(region.regionId).selected;

          this.regionsListViewState.set(region.regionId, {
            selected: selectedValue,
            editing: this.isEditing,
          });
        }

        region.viewState = this.regionsListViewState.get(region.regionId);

        return region;
      });
      return {
        totalCount: res.data.viewer.regions.totalCount,
      };
    });
  }

  async getRegionList() {
    const query = `
      query getRegions{
        viewer{
          regions{
            totalCount
            edges{
              node{
                regionId
                name
                country {
                  locationId
                  name
                }
              }
            }
          }
        }
      }
      `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, null);
      // should hit get request
    } catch (e) {
      console.error("Error in getting regions", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);

      return;
    }

    runInAction("getRegionList--success", () => {
      // this.pagination.goFetch()

      const regionData = res.data.viewer.regions.edges;
      this.regionOptionsList = regionData.map((region) => {
        return new Region(this, region.node);
      });

      // TODO: Deserialize this properly...
      // const list = new SignupUser(this, list);
      // this.signupuser.firstName = SignupUserData.firstName
      // this.signupuser.lastName = SignupUserData.lastName
      // this.signupuser.email = SignupUserData.email
    });

    return {
      response: res.data.viewer.regions.edges,
    };
  }

  async RegionListTitle(searchText) {
    const variables = {
      searchParam: searchText === undefined ? "" : searchText,
    };
    const query = `
      query getRegions($searchParam: String) {
        viewer {
          regions(search: $searchParam) {
            edges {
              node {
                regionId
                name
              }
            }
          }
        }
      }
      `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
      // should hit get request
    } catch (e) {
      console.error("Error in getting region list", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);

      return;
    }

    runInAction("regionTitles--success", () => {
      // TODO: Deserialize this properly...
      // const list = new SignupUser(this, list);
      // this.signupuser.firstName = SignupUserData.firstName
      // this.signupuser.lastName = SignupUserData.lastName
      // this.signupuser.email = SignupUserData.email
    });
    // if (res.data.viewer.regions.edges.length > 0) {
    //   res = mapJobTitles(res);
    // }

    return {
      regionTitles: res.data.viewer.regions.edges,
    };
  }

  changeRegionText(e: Object) {
    if (e && e.id) {
      this.regionTitle = e.title;
      this.regionTitleId = e.id;
      //this.JobDescription(e.id);
    } else {
      this.regionTitle = "";
      //this.jobdescription = '';
    }
  }
}

export default class RegionListStore extends RegionListComponentStore {
  deleteRegions: () => void;
  deleteRegionNetwork: NetworkState;
  searchesAffectedByDeletion: AffectedSearch[];
  getAffectedSearchesByDeletion: () => Promise<void>;
  confirmRegionDeletion: () => void;
  applyTags: () => void;
  deleteSingleTags: () => void;
  renameRegion: () => void;

  constructor(fetchGraphQL: FetchGraphQL) {
    super(fetchGraphQL);

    extendObservable(this, {
      deleteRegionNetwork: new NetworkState(),
      searchesAffectedByDeletion: [],
    });

    this.deleteRegions = action(this.deleteRegions.bind(this));
    this.applyTags = action(this.applyTags.bind(this));
    this.deleteSingleTags = action(this.deleteSingleTags.bind(this));
    this.renameRegion = action(this.renameRegion.bind(this));
    this.getAffectedSearchesByDeletion = action(
      this.getAffectedSearchesByDeletion.bind(this)
    );
    this.confirmRegionDeletion = action(this.confirmRegionDeletion.bind(this));
  }

  confirmRegionDeletion() {
    this.confirmDeleteModal.showModal();
    this.getAffectedSearchesByDeletion();
  }

  async getAffectedSearchesByDeletion(): Promise<void> {
    if (this.deleteRegionNetwork.loading) return;

    this.searchesAffectedByDeletion = [];
    let params = [];
    let args = [];
    let vars = {};

    if (this.allSelectedfilter) {
      params.push("$isRegionSearch: Boolean!");
      args.push("isRegionSearch: $isRegionSearch");
      vars.isRegionSearch = true;
    } else {
      const selectedRegionsList = this.getSelectedRegionsList();
      if (!selectedRegionsList || !selectedRegionsList.length) {
        console.error("Cannot delete Regions: No Region selected");
        return;
      }
      params.push("$regionIdIn: [ID]!");
      args.push("regionIdIn: $regionIdIn");
      vars.regionIdIn = selectedRegionsList;
    }

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
    query regionSearches(${queryParams}) {
      viewer {
        savedsearches(filters: {${queryArgs}}) {
          edges {
            node {
              searchId
              job {
                jobLabel
              }
              rateCard {
                name
              }
              region {
                name
              }
            }
          }
        }
      }  
    }
    `;
    this.deleteRegionNetwork.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.deleteRegionNetwork.handleError(
        "Getting searches affected by region deletion",
        e
      );
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.deleteRegionNetwork.loading = false;
      return;
    }

    return runInAction("getAffectedSearchesByDeletion--success", () => {
      this.deleteRegionNetwork.loading = false;
      this.deleteRegionNetwork.error = null;

      if (!res) return null;

      this.searchesAffectedByDeletion = res.data.viewer.savedsearches.edges.map(
        (edge) => {
          const search: AffectedSearch = {
            databaseId: edge.node.searchId,
            jobLabel: edge.node.job.jobLabel,
            // jobTitle: edge.node.job.jobTitle,
            regionName: edge.node.region.name,
            rateCardName: edge.node.rateCard?.name,
          };
          return search;
        }
      );
      return this.searchesAffectedByDeletion;
    });
  }

  async deleteRegions() {
    let params = [];
    let filterargs = [];
    let args = [];
    let vars = {};

    if (this.allSelectedfilter) {
      consolidateAppliedFilters(this.appliedFilters, params, args, vars);
      params.push("$exclude: [String]");
      filterargs.push("exclude: $exclude");
      vars.exclude = this.unSelectedRegions;
      vars.exclude = vars.exclude.filter(function (item, pos) {
        return vars.exclude.indexOf(item) === pos;
      });
    } else {
      const selectedRegionsList = this.getSelectedRegionsList();
      if (!selectedRegionsList || !selectedRegionsList.length) {
        console.error("Cannot delete Regions: No Region selected");
        return;
      }
      params.push("$only: [String]!");
      filterargs.push("only: $only");
      vars.only = selectedRegionsList;
    }

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
      mutation deleteRegion(${queryParams}){
       deleteRegion(input:{${filterargs}, filters: { ${queryArgs} }}) {
         ok
         
         errors {
           __typename
           ...on CannotDeleteRegion {
              message
            }
         }
       }
      }
    `;
    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Deleting selected Regions", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    runInAction("deleteRegions--success", () => {
      this.network.loading = false;
      this.network.error = null;

      if (!res) return null;

      if (res.data.deleteRegion.errors) {
        const errors = res.data.deleteRegion.errors;
        const cannotDeleteRegion = errors.find(
          (err) => err.__typename === "CannotDeleteRegion"
        );
        if (cannotDeleteRegion) {
          this.errorMessage = (
            <div>
              <p>Could not delete selected regions.</p>
              <p>
                Regions referenced by searches cannot be deleted. Unselect any regions
                with a search count greater than zero and try again.
              </p>
            </div>
          );
          this.errorModal.showModal();
          // return;
        } else {
          this.errorMessage = (
            <p>
              Sorry! Could not delete selected regions. There was an error during the
              operation.
            </p>
          );
          this.errorModal.showModal();
          // return;
        }
      } else {
        this.handleStopEdit();
        this.pagination.goFetch(null);
      }
    });
  }

  async applyTags() {
    //var searchCriteria = this.selectWorksheets("delete");
    // let searchquery = '';
    // let parameters = {};

    let params = [];
    let filterargs = [];
    let searchargs = [];
    let args = [];
    let vars = {};

    const taglist = this.applyTagState.getSelectedTagList();
    if (!taglist || !taglist.length) {
      console.error("Cannot Apply tags to Region: No Region selected");
      return;
    }
    params.push("$tagIds: [Int]!");
    filterargs.push("tagIds: $tagIds");
    vars.tagIds = taglist;

    if (this.allSelectedfilter) {
      consolidateAppliedFilters(this.appliedFilters, params, args, vars);
      params.push("$exclude: [ID]");
      args.push("exclude: $exclude");
      vars.exclude = this.unSelectedRegions;
      // vars.exclude = vars.exclude.filter(function(item, pos) {
      //   return vars.exclude.indexOf(item) == pos;
      // })
    } else {
      const selectedRegionList = this.getSelectedRegionsList();
      if (!selectedRegionList || !selectedRegionList.length) {
        console.error("Cannot apply tage Region: No Region selected");
        return;
      }
      params.push("$only: [ID]");
      args.push("only: $only");
      vars.only = selectedRegionList;
    }

    if (this.regionFilter.textToLookFor) {
      params.push("$nameIContains: String");
      args.push("nameIContains:$nameIContains");
      vars.nameIContains = this.regionFilter.textToLookFor;
    }

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
      mutation applyTags(${queryParams}){
       applyTagsToRegions(input:{${filterargs}, filters: { ${queryArgs}}, ${searchargs}}) {
         ok
          errors {
           __typename
           ... on TagIdRequiredError {
             message
           }
           ... on TagIdsNotExistsError {
             message
           }
           ... on InvalidInputError {
             message
           }
           ... on ContentDoesNotExistsError {
             message
           }
         }
       }

      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Deleting selected Region", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    this.handleStopEdit();

    runInAction("deleteWorksheets--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("deleteWorksheets", res)) {
        // TODO: Display user friendly error message
        return;
      }
      this.applyTagState.tagModal.hideModal();
      this.pagination.goFetch(null);
    });
  }

  async deleteSingleTags(content) {
    if (this.network.loading) {
      return;
    }

    const query = `
    mutation removeTagsFromContent{
      removeTagsFromContent(input: {tagIds : [${content.tagId}],contentType :REGIONS,contentId:${content.contentId}}){
        ok
        }
      }
    `;

    // this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, null);
    } catch (e) {
      this.network.handleError("Deleting selected Tag", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    //this.handleStopEdit();

    runInAction("deleteSingleTags--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("deleteTag", res)) {
        // TODO: Display user friendly error message
        return;
      }

      //this.pagination.goFetch(null);
    });
    return {
      result: res.data.removeTagsFromContent.ok,
    };
  }

  async renameRegion() {
    if (this.network.loading) {
      return;
    }
    this.messaging.removeAll();

    if (
      !this.newRegionName.trim() ||
      this.getFirstSelectedRegion().name === this.newRegionName
    ) {
      this.messaging.createMessage("info", "Please enter a New Region Name.");
      return;
    }

    const query = `
      mutation updateRegion($regionId: Int!, $name: String!){
        updateRegion(input: {name: $name, regionId: $regionId}){
          region{
            regionId,
            name,
          }
          errors{
            __typename
            ...on NoPermissionError{
              message
            }
            ...on NameAlreadyExistError{
              message
            }
            ...on TagIdsNotExistsError{
              message
            }
            ...on InvalidRegionIdError{
              message
            }
          }
        }
      }
    `;
    const variables = {
      regionId: this.getSelectedRegionsList()[0],
      name: this.newRegionName,
    };

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Updating Region name", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    runInAction("renameRegion--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (res.data.updateRegion.errors) {
        this.messaging.removeAll();
        this.messaging.createMessage("error", res.data.updateRegion.errors[0].message);
        return;
      }
      this.handleStopEdit();
      this.pagination.goFetch(null);
      this.renameModal.hideModal();
    });
  }
}
