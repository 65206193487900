import React, { useCallback, useMemo } from "react";
import { useRecoilState } from "recoil";
import { Set, List, Map, fromJS } from "immutable";
import moment from "moment";

import Box from "../../../components/lib/Box";
import Text from "../../../components/lib/Text";
import Icon from "../../../components/lib/Icon";
import Inline from "../../../components/lib/Inline";
import TextCrop from "../../validator5K_admin/components/TextCrop";
import { Button, IconButton } from "../../../components/lib/Button";
import { TableFilterableRestful, TabButton, TabsPanel } from "./TableFilterableRestful";
import { Column, TableConfig as TableConfigModal } from "../../../components/tables";
import { useTableSchemaState } from "../../../components/tables/Table";
import { ButtonGroupRight } from "../../../components/lib/ButtonGroup";
import {
  FilterTypes,
  djangoPaginationKey,
  djangoPaginationSizeKey,
} from "../../../components/tables/constants";
import { dateTimeFormatter } from "../constants";
import { emptyList } from "../../../constants";
import { transformAttemptsData } from "../dataConverters";
import {
  attemptsTableGlobalState,
  type AttemptsTableDataStateObject,
} from "../globalState";
import {
  REVIEW_TYPES_LABELS,
  REVIEW_TYPES_OPTIONS,
  SOURCE_TYPES,
  SOURCE_TYPES_LABELS,
  SOURCE_TYPES_OPTIONS,
  ALL_SKILLS_LEVELS_NUMERAL,
  SKILLS_LEVELS_NUMERAL_LABELS,
  VALUE_TYPES,
  ALL_VALUE_TYPES,
  VALUE_TYPES_LABELS,
  REVIEW_STATUS_TYPES,
  REVIEW_STATUS_TYPES_LABELS,
  REVIEW_STATUS_TYPES_OPTIONS,
  THREE_LEVEL_BANDING_TYPES_OPTIONS,
  FREE_TYPES_OPTIONS,
  READY_FOR_PAYMENT_TYPES_OPTIONS,
  PAID_TYPES_OPTIONS,
  ANALYSIS_COMPLETED_TYPES_OPTIONS,
  ARCHIVED_TYPES_OPTIONS,
} from "../types";
// TODO get rid of PLI references
import { renderTableColumnsSpecs } from "../../private_index/components/ExtendedRestfulTables";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";
import { useInitialFetch, useModalState } from "../../../utils/hooks";
import { TickerContentLoader } from "../../../components/lib/TickerLoader";

import type { ImmutableSet } from "../../../types/immutable";
import type { ConfigurableRestfulTableDataStateObject } from "../globalState";
import type {
  VisibleColumnsSet,
  TableConfigOptionsList,
} from "../../../components/tables/types";
import type { TableFilterableRestfulProps } from "../../../components/tables/TableFilterableRestful";
import type { FetchAPIResponse } from "../../../types/fetch";
import type { DjangoPaginatedResponse } from "../../../types/django";
import type {
  TableColumnSpecsObject,
  AttemptDataObject,
  AttemptDataMap,
  REVIEW_TYPES_TYPE,
  SOURCE_TYPES_TYPE,
  SKILLS_LEVELS_TYPE,
  VALUE_TYPES_TYPE,
  REVIEW_STATUS_TYPES_TYPE,
} from "../types";
import PromiseButton from "../../../components/lib/PromiseButton";
import { rowIdGetter } from "../../../components/tables/utils";
import { useVal5KAdminContext } from "../context/Val5KAdminContext";

export const attemptsTableSpecs: TableColumnSpecsObject<AttemptDataMap>[] = [
  {
    type: "column",
    uniqueKey: "review__id",
    title: "Review ID",
    getter: (row) => row.getIn(["review", "id"]),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "review__title",
    title: "Review Title",
    getter: (row) => row.getIn(["review", "title"]),
    formatter: (value, row) => {
      const reviewsGroupId = row.getIn(["review", "reviews_group_id"]);

      return (
        <Inline nowrap css={{ gap: "$2" }}>
          {!!reviewsGroupId && (
            <Text color="brand">
              <Icon icon="list" title="Member of surveys group." />
            </Text>
          )}
          <TextCrop mode="tooltip" title="Title" text={value} emptyStub="" />
        </Inline>
      );
    },
    filterType: FilterTypes.VALUES_CHECKLIST,
    css: { textAlign: "left" },
    popupSize: "wide",
  },
  {
    type: "column",
    uniqueKey: "review__countries_string",
    title: "Country",
    getter: (row) => row.getIn(["review", "countries_string"]),
    formatter: (value) => (
      <TextCrop mode="tooltip" title="Country" text={value} emptyStub="" />
    ),
    filterType: FilterTypes.VALUES_CHECKLIST,
    popupSize: "wide",
  },
  {
    type: "column",
    uniqueKey: "review__states_string",
    title: "State",
    getter: (row) => row.getIn(["review", "states_string"]),
    formatter: (value) => (
      <TextCrop mode="tooltip" title="State" text={value} emptyStub="" />
    ),
    filterType: FilterTypes.VALUES_CHECKLIST,
    popupSize: "wide",
  },
  {
    type: "column",
    uniqueKey: "review__cities_string",
    title: "City",
    getter: (row) => row.getIn(["review", "cities_string"]),
    formatter: (value) => (
      <TextCrop mode="tooltip" title="City" text={value} emptyStub="" />
    ),
    filterType: FilterTypes.VALUES_CHECKLIST,
    popupSize: "wide",
  },
  {
    type: "column",
    uniqueKey: "review__regions_string",
    title: "Region",
    getter: (row) => row.getIn(["review", "regions_string"]),
    formatter: (value) => (
      <TextCrop mode="tooltip" title="Region" text={value} emptyStub="" />
    ),
    filterType: FilterTypes.VALUES_CHECKLIST,
    popupSize: "wide",
  },
  {
    type: "column",
    uniqueKey: "review__review_type",
    title: "Review Type",
    getter: (row) => row.getIn(["review", "review_type"]),
    formatter: (value: REVIEW_TYPES_TYPE) => REVIEW_TYPES_LABELS[value],
    sortable: false,
    filterType: FilterTypes.ENUMERATION,
    filterOptions: fromJS(REVIEW_TYPES_OPTIONS),
  },
  {
    type: "column",
    uniqueKey: "review__source_type",
    title: "Source Type",
    getter: (row) => row.getIn(["review", "source_type"]),
    formatter: (value: SOURCE_TYPES_TYPE) => SOURCE_TYPES_LABELS[value],
    sortable: false,
    filterType: FilterTypes.ENUMERATION,
    filterOptions: fromJS(
      SOURCE_TYPES_OPTIONS.filter((option) => option.value !== SOURCE_TYPES.FROM_UPLOAD)
    ),
  },
  {
    type: "column",
    uniqueKey: "review__source_title",
    title: "Ratecard/Upload/Index",
    getter: (row) => row.getIn(["review", "source_title"]),
    formatter: (value, row) => {
      const sourceType = row.getIn(["review", "source_type"]);
      const uploadId = row.getIn(["review", "upload_id"]);
      const ratecardId = row.getIn(["review", "ratecard_id"]);
      let resultingValue = value;

      if (sourceType === SOURCE_TYPES.FROM_RATECARD && ratecardId) {
        resultingValue = `(#${ratecardId}) ${value}`;
      } else if (sourceType === SOURCE_TYPES.FROM_UPLOAD && uploadId) {
        resultingValue = `(#${uploadId}) ${value}`;
      }

      return <TextCrop mode="tooltip" title="Title" text={resultingValue} emptyStub="" />;
    },
    filterType: FilterTypes.VALUES_CHECKLIST,
    css: { textAlign: "left" },
    popupSize: "xwide",
  },
  {
    type: "column",
    uniqueKey: "review__client_name",
    title: "Client",
    getter: (row) => row.getIn(["review", "client_name"]),
    formatter: (value, row) => {
      return value ? `(#${row.getIn(["review", "client_id"])}) ${value}` : "";
    },
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "review__required_levels",
    title: "Levels To Validate",
    getter: (row) => row.getIn(["review", "required_levels"], emptyList).toJS(),
    formatter: (value, row) => {
      if (!Array.isArray(value)) {
        value = [value];
      }
      if (value && value.length) {
        if (Set(value).equals(Set(ALL_SKILLS_LEVELS_NUMERAL.map((i) => parseInt(i))))) {
          return "All";
        }
        return value
          .sort()
          .map((i: SKILLS_LEVELS_TYPE) => SKILLS_LEVELS_NUMERAL_LABELS[i])
          .join(", ");
      }
      return "All";
    },
    sortable: false,
    filterable: false,
  },
  {
    type: "column",
    uniqueKey: "review__required_rates",
    title: "Rates To Validate",
    getter: (row) => row.getIn(["review", "required_rates"], emptyList).toJS(),
    formatter: (value, row) => {
      if (!Array.isArray(value)) {
        value = [value];
      }
      if (value && value.length) {
        if (Set(value).equals(Set(ALL_VALUE_TYPES))) {
          return "All";
        }
        return value.map((i: VALUE_TYPES_TYPE) => VALUE_TYPES_LABELS[i]).join(", ");
      }
      return VALUE_TYPES_LABELS[VALUE_TYPES.PAY_RATE];
    },
    sortable: false,
    filterable: false,
  },
  {
    type: "column",
    uniqueKey: "first_name",
    title: "First Name",
    getter: (row) => row.get("first_name"),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "last_name",
    title: "Last Name",
    getter: (row) => row.get("last_name"),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "review_status",
    title: "Review Status",
    getter: (row) => row.get("review_status"),
    formatter: (value: REVIEW_STATUS_TYPES_TYPE) =>
      value === REVIEW_STATUS_TYPES.REVIEWED ? (
        <Text color="positive">
          {REVIEW_STATUS_TYPES_LABELS[value]} <Icon icon="check" />
        </Text>
      ) : (
        <Text>{REVIEW_STATUS_TYPES_LABELS[value]}</Text>
      ),
    sortable: false,
    filterType: FilterTypes.ENUMERATION,
    filterOptions: fromJS(REVIEW_STATUS_TYPES_OPTIONS),
  },
  {
    type: "column",
    uniqueKey: "completed_timestamp",
    title: "Completed At",
    getter: (row) => row.get("completed_timestamp"),
    formatter: (value) => dateTimeFormatter(value),
    filterType: FilterTypes.DATES_RANGE,
    filterExtraProps: {
      datesFormat: moment.defaultFormat,
    },
  },
  {
    type: "column",
    uniqueKey: "review__is_three_level_banding",
    title: "Is Three Level Banding",
    getter: (row) => row.getIn(["review", "is_three_level_banding"], false),
    formatter: (value) =>
      !!value ? (
        <Text color="positive">
          Yes <Icon icon="check" />
        </Text>
      ) : null,
    sortable: false,
    filterType: FilterTypes.ENUMERATION,
    filterOptions: fromJS(THREE_LEVEL_BANDING_TYPES_OPTIONS),
  },
  {
    type: "column",
    uniqueKey: "free_validation",
    title: "Free Validation",
    getter: (row) => row.get("free_validation", false),
    formatter: (value) =>
      !!value ? (
        <Text color="positive">
          Free <Icon icon="check" />
        </Text>
      ) : null,
    sortable: false,
    filterType: FilterTypes.ENUMERATION,
    filterOptions: fromJS(FREE_TYPES_OPTIONS),
  },
  {
    type: "column",
    uniqueKey: "total_reward",
    title: "Reward",
    getter: (row) => row.get("total_reward"),
    filterType: FilterTypes.NUMBERS_RANGE,
  },
  {
    type: "column",
    uniqueKey: "ready_for_payment",
    title: "Ready For Payment",
    getter: (row) => row.get("ready_for_payment", false),
    formatter: (value) =>
      !!value ? (
        <Text color="positive">
          Ready <Icon icon="check" />
        </Text>
      ) : null,
    sortable: false,
    filterType: FilterTypes.ENUMERATION,
    filterOptions: fromJS(READY_FOR_PAYMENT_TYPES_OPTIONS),
  },
  {
    type: "column",
    uniqueKey: "paid",
    title: "Paid",
    getter: (row) => row.get("paid", false),
    formatter: (value) =>
      !!value ? (
        <Text color="positive">
          Paid <Icon icon="check" />
        </Text>
      ) : null,
    sortable: false,
    filterType: FilterTypes.ENUMERATION,
    filterOptions: fromJS(PAID_TYPES_OPTIONS),
  },
  {
    type: "column",
    uniqueKey: "analysis_completed",
    title: "Analysis Completed",
    getter: (row) => row.get("analysis_completed", false),
    formatter: (value) =>
      !!value ? (
        <Text color="positive">
          Completed <Icon icon="check" />
        </Text>
      ) : null,
    sortable: false,
    filterType: FilterTypes.ENUMERATION,
    filterOptions: fromJS(ANALYSIS_COMPLETED_TYPES_OPTIONS),
  },
  {
    type: "column",
    uniqueKey: "archived",
    title: "Archived",
    getter: (row) => row.get("archived", false),
    formatter: (value) =>
      !!value ? (
        <Text color="negative">
          Archived <Icon icon="archive" />
        </Text>
      ) : null,

    sortable: false,
    filterType: FilterTypes.ENUMERATION,
    filterOptions: fromJS(ARCHIVED_TYPES_OPTIONS),
  },
];

const defaultVisibleColumns = Set([
  "review__title",
  "review__countries_string",
  "review__regions_string",
  "review__source_title",
  "first_name",
  "last_name",
  "review_status",
  "completed_timestamp",
  "analysis_completed",
  "archived",
]) as any as ImmutableSet<string>;

export const attemptsTableConfigOptions = List(
  attemptsTableSpecs.map(({ uniqueKey, title }) => Map({ uniqueKey, title }))
) as TableConfigOptionsList;

const attemptsTableConfigKeys = attemptsTableConfigOptions
  .map((option) => option.get("uniqueKey"))
  .toSet();

interface AttemptsTableProps extends Omit<TableFilterableRestfulProps, "schema"> {
  visibleColumns: VisibleColumnsSet;
  onChangeTableConfig: (
    visibleColumns: VisibleColumnsSet | null,
    itemsPerPage: number | null
  ) => void;
}

const AttemptsTable = (props: AttemptsTableProps) => {
  const { visibleColumns, itemsPerPage, onChangeTableConfig } = props;

  // state

  const [schema] = useTableSchemaState(props.children);
  const {
    modalState: configModalState,
    showModal: showConfigModal,
    closeModal: closeConfigModal,
  } = useModalState();

  const customConfigTabModal = (
    <TableConfigModal
      options={attemptsTableConfigOptions}
      visibleColumns={visibleColumns}
      itemsPerPage={itemsPerPage}
      onChange={(...args) => onChangeTableConfig(...args)}
      show={configModalState}
      onHide={closeConfigModal}
      optionsGridCss={{
        gap: "$1",
        gridAutoFlow: "column",
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        gridTemplateRows: "repeat(23, minmax(0, 1fr))",
        "@lg": {
          gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
          gridTemplateRows: "repeat(8, minmax(0, 1fr))",
        },
      }}
    />
  );

  const customConfigTab = (
    <TabButton active={false} onClick={showConfigModal}>
      <Icon icon="cog" onClick={showConfigModal} title="Click to configure columns set" />
      &nbsp;&nbsp;Custom Table Config
    </TabButton>
  );

  return (
    <Box css={{ position: "relative", marginTop: "40px" }}>
      {customConfigTabModal}
      <TabsPanel>{customConfigTab}</TabsPanel>
      <TableFilterableRestful {...props} schema={schema} />
    </Box>
  );
};
AttemptsTable.displayName = "AttemptsTable";

export const AttemptsTableView = () => {
  const {
    router,
    fetchArgusAPI,
    fetchArgusFilteringAPI,
    showModalError,
    hideModal,
    showConfirmationModal,
    closeConfirmationModal,
  } = useVal5KAdminContext();

  // table state

  const [attemptsTableState, setAttemptsTableState] = useRecoilState<
    ConfigurableRestfulTableDataStateObject<AttemptDataMap>
  >(attemptsTableGlobalState);
  const { visibleColumns, loaded, ...restTableState } = attemptsTableState;

  // table data sources

  const fetchAttemptsData = useCallback(
    async (urlQuery = {}, filtersQuery = {}, nextStateUpdates = {}) => {
      try {
        const response: FetchAPIResponse<DjangoPaginatedResponse<AttemptDataObject>> =
          await fetchArgusFilteringAPI(`attempts/filtered/`, {
            params: urlQuery,
            data: filtersQuery,
          });

        const nextDataState: Partial<AttemptsTableDataStateObject> =
          transformAttemptsData(response.data, nextStateUpdates);

        setAttemptsTableState((prevDataState) => ({
          ...prevDataState,
          ...nextDataState,
          loaded: true,
        }));

        return nextDataState;
      } catch (err: any) {
        logAsyncOperationError("fetchAttemptsList", err);
        showModalError(
          "Error occurred while loading attempts list. Please, try again later."
        );
        throw err;
      }
    },
    [setAttemptsTableState, fetchArgusFilteringAPI, showModalError]
  );

  const fetchAttemptsFiltersData = useCallback(
    async (urlQuery = {}, filtersQuery = {}) => {
      try {
        const response: FetchAPIResponse<DjangoPaginatedResponse<any>> =
          await fetchArgusFilteringAPI(`attempts/values/filtered/`, {
            params: urlQuery,
            data: filtersQuery,
          });
        return response.data;
      } catch (err: any) {
        logAsyncOperationError("fetchAttemptsListFilterValues", err);
        showModalError(
          "Error occurred while loading filter values. Please, try again later."
        );
        throw err;
      }
    },
    [fetchArgusFilteringAPI, showModalError]
  );

  // utility functions

  const refreshAttemptsData = useCallback(async () => {
    return fetchAttemptsData(
      {
        [djangoPaginationKey]: attemptsTableState.activePage,
        [djangoPaginationSizeKey]: attemptsTableState.itemsPerPage,
      },
      attemptsTableState.filtersQuery.toJS()
    );
  }, [
    attemptsTableState.filtersQuery,
    attemptsTableState.activePage,
    attemptsTableState.itemsPerPage,
    fetchAttemptsData,
  ]);

  // initial data fetch

  useInitialFetch(refreshAttemptsData);

  // handlers

  const handleRowClick = useCallback(
    (rowData: AttemptDataMap, attemptId: string | number) => {
      if (attemptId) {
        router.push(`/admin/val5000/validations/${attemptId}`);
      }
    },
    [router]
  );

  const handleChangeTableConfig = useCallback(
    (visibleColumns: VisibleColumnsSet | null, itemsPerPage: number | null) => {
      const changesObject: Partial<{
        visibleColumns: VisibleColumnsSet;
        itemsPerPage: number;
      }> = {};

      if (visibleColumns != null) {
        changesObject["visibleColumns"] = visibleColumns;
      }
      if (itemsPerPage != null) {
        changesObject["itemsPerPage"] = itemsPerPage;
      }

      setAttemptsTableState((prevData) => ({
        ...prevData,
        ...changesObject,
      }));
    },
    [setAttemptsTableState]
  );

  const handleDeleteItem = useCallback(
    async (e: React.MouseEvent<HTMLElement>, attemptsId: number) => {
      e.stopPropagation();

      if (attemptsId) {
        try {
          await fetchArgusAPI(`attempts/${attemptsId}/`, { method: "delete" });
          await refreshAttemptsData();
          hideModal();
        } catch (err: any) {
          logAsyncOperationError("deleteAttemptsItem", err);
          showModalError(
            "Error occurred during attempts deletion. Please, try again later."
          );
        } finally {
          closeConfirmationModal();
        }
      }
    },
    [
      showModalError,
      closeConfirmationModal,
      hideModal,
      refreshAttemptsData,
      fetchArgusAPI,
    ]
  );

  const handleConfirmDeleteItem = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, attempt: AttemptDataMap) => {
      e.stopPropagation();

      const id = attempt.get("id");
      const title = attempt.getIn(["review", "title"]);
      const header = "Confirm delete action";
      const message = (
        <span>
          Do you really want to delete the following attempts?
          <br />
          <b>{`- ${title} (id: ${id})`}</b>
        </span>
      );
      const footer = (
        <ButtonGroupRight fill reverse>
          <Button size="large" onClick={closeConfirmationModal}>
            No, Cancel
          </Button>
          <PromiseButton
            icon="trash-alt"
            loadingText="Yes, Delete"
            color="danger"
            size="large"
            onClick={(e) => handleDeleteItem(e, id)}
          >
            Yes, Delete
          </PromiseButton>
        </ButtonGroupRight>
      );

      showConfirmationModal(message, header, footer);
    },
    [handleDeleteItem, showConfirmationModal, closeConfirmationModal]
  );

  // rendering

  const renderActionsButtons = useCallback(
    (row: AttemptDataMap) => {
      const reviewStatus = row.get("review_status");

      if (reviewStatus && reviewStatus === REVIEW_STATUS_TYPES.NOT_REVIEWED) {
        return (
          <IconButton
            variant="text"
            size="small"
            icon="trash-alt"
            title="Delete User Attempt"
            color="red"
            onClick={(e) => handleConfirmDeleteItem(e, row)}
          />
        );
      }
    },
    [handleConfirmDeleteItem]
  );

  const resultingVisibleColumns = useMemo(
    () =>
      (visibleColumns.size ? visibleColumns : defaultVisibleColumns).filter(
        (key: string) => attemptsTableConfigKeys.includes(key)
      ),
    [visibleColumns]
  );

  const resultingColumnsSpecs = useMemo(
    () =>
      attemptsTableSpecs.filter((specObject) =>
        resultingVisibleColumns.includes(specObject.uniqueKey)
      ),
    [resultingVisibleColumns]
  );

  const columns = renderTableColumnsSpecs(resultingColumnsSpecs).concat(
    <Column
      key="__actions"
      uniqueKey="__actions"
      title="Actions"
      getter={renderActionsButtons}
      fixed
    />
  );

  if (!loaded) {
    return (
      <Box css={{ minHeight: "200px", position: "relative" }}>
        <TickerContentLoader />
      </Box>
    );
  }

  return (
    <AttemptsTable
      multimode
      highlighted
      rowIdGetter={rowIdGetter}
      onRowClick={handleRowClick}
      bodyEmptyText="No data"
      disablePagination={false}
      dataProvider={fetchAttemptsData}
      filtersDataProvider={fetchAttemptsFiltersData}
      onChangeTableConfig={handleChangeTableConfig}
      visibleColumns={resultingVisibleColumns}
      {...restTableState}
    >
      {columns}
    </AttemptsTable>
  );
};
AttemptsTableView.displayName = "AttemptsTableView";
