import React from "react";
// @ts-ignore
import AsyncSelect from "react-select/lib/Async";
import graphql from "babel-plugin-relay/macro";
import Inline from "../../../components/lib/Inline";
import { reactSelectWithIconStyles, rpReactSelectTheme } from "./rpReactSelect";
import { includes } from "lodash";
import { components } from "react-select";
import Icon from "../../../components/lib/Icon";
import Tag from "../../../components/lib/Tag";
import { Location } from "../types";
import { RsLocationSelectQuery as RsLocationSelectQueryType } from "./__generated__/RsLocationSelectQuery.graphql";
import { debounce } from "lodash";
import { useMemo } from "react";
import { fetchQuery, useRelayEnvironment } from "react-relay";

function locationDisplay(location: Location) {
  const display = location?.fullSubtitle
    ? `${location?.fullTitle}, ${location?.fullSubtitle}`
    : location.fullTitle!;
  return display.replace(/(^, )|(, $)/g, ""); // remove trailing and ending ", " in case title or subtitle is null
}

const RsLocationSelectQuery = graphql`
  query RsLocationSelectQuery($searchParam: String!, $countryIds: [Int]) {
    viewer {
      locations(search: $searchParam, countryIds: $countryIds) {
        title
        subtitle
        type
        fullTitle
        fullSubtitle
        countryId
        locationId
        countryCode
        lat
        lon
      }
    }
  }
`;

function useRsLocationSelectQuery(selectedLocatios: Location[], countryIds: number[]) {
  const relayEnvironment = useRelayEnvironment();

  return useMemo(() => {
    function loadOptios(inputValue: string, callback: (options: Location[]) => void) {
      if (!inputValue) {
        callback([]);
        return;
      }

      fetchQuery<RsLocationSelectQueryType>(relayEnvironment, RsLocationSelectQuery, {
        searchParam: inputValue,
        countryIds,
      }).subscribe({
        next: (response: RsLocationSelectQueryType["response"]) => {
          if (response?.viewer?.locations) {
            const locations = response.viewer.locations.filter(
              (l) =>
                l &&
                !selectedLocatios.some(
                  (selected) => selected?.locationId === l.locationId
                )
            ) as Location[];
            callback(locations);
          } else {
            callback([]);
          }
        },
        error: () => {},
      });
    }

    return debounce(loadOptios, 300);
  }, [selectedLocatios, countryIds, relayEnvironment]);
}

type RsLocationSelectProps = {
  id?: string;
  value?: any;
  onChange: (value: Location[]) => void;
  selectedLocations?: Location[];
  countryIds?: number[];
  certifiedCountries?: number[];
  adhocCountries?: number[];
  placeholder?: string;
  canReset?: boolean;
  isDisabled?: boolean;
};

function RsLocationSelect(props: RsLocationSelectProps) {
  const { selectedLocations = [] } = props;

  const debouncedLoadOptions = useRsLocationSelectQuery(
    selectedLocations,
    props.countryIds || []
  );

  const formatOptionLabel = (option: any) => {
    const label = locationDisplay(option);

    return (
      <Inline css={{ gap: "8px", flexWrap: "nowrap" }}>
        <div>{label}</div>
        {includes(props.certifiedCountries, option.countryId) && (
          <Tag icon="globe-americas" text="Certified" size="extra-small" />
        )}
        {includes(props.adhocCountries, option.countryId) && (
          <Tag icon="globe-americas" text="Noncertified" color="red" size="extra-small" />
        )}
      </Inline>
    );
  };

  const handleChange = (newValue: Location[]) => {
    if (typeof props.onChange === "function") {
      props.onChange(newValue);
    }
    return newValue;
  };

  const ValueContainer = ({ children, ...props }: any) => {
    return (
      components.ValueContainer && (
        <components.ValueContainer {...props}>
          {!!children && (
            <Icon
              icon={"map-marker-alt"}
              style={{ position: "absolute", left: 13, color: "rgba(42,57,79,.6)" }}
            ></Icon>
          )}
          {children}
        </components.ValueContainer>
      )
    );
  };

  return (
    <Inline css={{ flexWrap: "nowrap", width: "100%" }}>
      <AsyncSelect
        name="jobLocation"
        inputId={props.id || "jobLocationSelect"}
        placeholder={props.placeholder || "Select a location"}
        isMulti
        value={props.value || []}
        backspaceRemovesValue
        openMenuOnFocus
        tabSelectsValue={false}
        defaultOptions
        cacheOptions={false}
        isClearable={props.canReset}
        components={{
          ValueContainer,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        loadOptions={debouncedLoadOptions}
        getOptionLabel={(option) =>
          option.title + ", " + option.fullSubtitle?.replace(",", " -")
        }
        formatOptionLabel={formatOptionLabel}
        getOptionValue={(option) => option.locationId}
        onChange={handleChange}
        styles={reactSelectWithIconStyles}
        theme={rpReactSelectTheme}
        isDisabled={props && props.isDisabled ? true : false}
        noOptionsMessage={() => "No options. Try Advanced search."}
      />
    </Inline>
  );
}

export default RsLocationSelect;
