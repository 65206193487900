/**
 * @generated SignedSource<<0697aafa1020cf64a4a30aad8b5ec0f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type JobRateType = "Contract" | "FTE" | "%future added value";
export type FindRateInput = {
  clientMutationId?: string | null;
  description: string;
  gss?: boolean | null;
  industries: ReadonlyArray<number | null>;
  isTravel?: boolean | null;
  jobLabel: string;
  jobTitle: string;
  jobTitleId: number;
  locationIds?: ReadonlyArray<string | null> | null;
  rateType?: JobRateType | null;
  regionIds?: ReadonlyArray<number | null> | null;
  showLevels?: boolean | null;
  showMarkups?: boolean | null;
  showQuantiles?: boolean | null;
  useLocationModifier?: boolean | null;
  workerTypeId?: number | null;
  workerTypeName?: string | null;
};
export type SubmitRateSearchMutation$variables = {
  input: FindRateInput;
};
export type SubmitRateSearchMutation$data = {
  readonly ratesearches: {
    readonly searches: {
      readonly ratesearches: ReadonlyArray<{
        readonly jobLabel: string | null;
        readonly jobTitle: string | null;
        readonly results: ReadonlyArray<{
          readonly searchId: number | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};
export type SubmitRateSearchMutation = {
  response: SubmitRateSearchMutation$data;
  variables: SubmitRateSearchMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FindRatePayload",
    "kind": "LinkedField",
    "name": "ratesearches",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RateSavedSearchesNode",
        "kind": "LinkedField",
        "name": "searches",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RateSearchesNode",
            "kind": "LinkedField",
            "name": "ratesearches",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "jobTitle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "jobLabel",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SavedSearchesList",
                "kind": "LinkedField",
                "name": "results",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "searchId",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubmitRateSearchMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubmitRateSearchMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d89e73f6cbc28507eb7246a430ce45d0",
    "id": null,
    "metadata": {},
    "name": "SubmitRateSearchMutation",
    "operationKind": "mutation",
    "text": "mutation SubmitRateSearchMutation(\n  $input: FindRateInput!\n) {\n  ratesearches(input: $input) {\n    searches {\n      ratesearches {\n        jobTitle\n        jobLabel\n        results {\n          searchId\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "56930cd16020118bcbcc533226f368f7";

export default node;
