// @flow
import React, { Component } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { globalSequenceId } from "./SupportFunctions";

type Props = {
  onChange: (string) => void,
  name?: ?string,
  error?: ?string,
  disabled?: boolean,
  readOnly?: boolean,
  width?: number,
  fill?: boolean,
  style?: Object,
  tabIndex?: number,
  placeholder?: string,
  value?: string,
  multiline?: boolean,
  type?: string,
  rows?: number,
};

const InputBox = observer(
  class extends Component<Props> {
    static defaultProps = {
      multiline: false,
      placeholder: "",
      tabIndex: 0,
      type: "text",
    };

    handleOnChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
      const onChange = this.props.onChange;

      if (onChange) {
        onChange(e.target.value);
      }
    };

    componentDidUpdate() {
      // ReactDOM.findDOMNode(this.refs.inputBox).focus();
    }

    render() {
      const classSelectors = classNames(
        "rc-input-box",
        { "has-error-info": Boolean(this.props.error) },
        this.props.className
      );

      let inputName = this.props.name;
      if (!inputName) {
        inputName = "input-box-" + globalSequenceId().toString(10);
      }

      const disabled = !!this.props.disabled;
      const readOnly = !!this.props.readOnly;

      let style = this.props.style ? this.props.style : {};
      let width = this.props.width;
      const fill = !!this.props.fill;
      if (typeof width === "number" || typeof width === "string") {
        style.width = width;
      } else if (fill) {
        style.width = "100%";
      }

      const tooltip = (
        <Tooltip id={`${inputName}-tooltip`} placement="top">
          {this.props.error}
        </Tooltip>
      );

      let input = (
        <input
          type={this.props.type || "text"}
          tabIndex={this.props.tabIndex ? this.props.tabIndex : 0}
          className={classSelectors}
          ref={inputName}
          name={inputName}
          style={style}
          value={this.props.value}
          onChange={this.handleOnChange}
          placeholder={this.props.placeholder}
          disabled={disabled}
          readOnly={readOnly}
        />
      );

      if (this.props.multiline)
        input = (
          <textarea
            tabIndex={this.props.tabIndex}
            className={classSelectors}
            ref={inputName}
            name={inputName}
            style={style}
            value={this.props.value}
            onChange={this.handleOnChange}
            placeholder={this.props.placeholder}
            disabled={disabled}
            readOnly={readOnly}
            rows={this.props.rows}
          />
        );

      return (
        <div style={{ position: "relative" }}>
          {input}
          {Boolean(this.props.error) && (
            <OverlayTrigger placement="top" overlay={tooltip}>
              <FontAwesomeIcon
                icon="info-circle"
                fixedWidth
                className="icon text-danger error-info-icon"
              />
            </OverlayTrigger>
          )}
        </div>
      );
    }
  }
);

export default InputBox;
