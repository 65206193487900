import React, { useCallback } from "react";

import Stack from "../../../components/lib/Stack";
import TotalFeesPaidTableView from "../components/TotalFeesPaidTable";
import NdaHistoryTableView from "../components/NdaHistoryTable";
import Button, { NavigationButton } from "../../../components/lib/Button";
import {
  Card,
  CardActions,
  CardActionsLeft,
  CardActionsRight,
  CardBody,
  CardHeaderTitle,
} from "../../../components/lib/Card";
import { useModalState } from "../../../utils/hooks";

import { useVal5KAdminContext } from "../context/Val5KAdminContext";

type BackToDashboardBlockProps = {
  title?: string;
};

const BackToDashboardBlock = (props: BackToDashboardBlockProps) => {
  const { title } = props;
  const { router } = useVal5KAdminContext();

  const handleBackToDashboard = useCallback(
    () => router.push(`/admin/val5000`),
    [router]
  );

  return (
    <Card fill>
      <CardActions>
        <CardActionsLeft>
          {title && <CardHeaderTitle>{title}</CardHeaderTitle>}
        </CardActionsLeft>
        <CardActionsRight>
          <NavigationButton
            icon="arrow-left"
            onClick={handleBackToDashboard}
            css={{ width: "190px" }}
          >
            Back to Dashboard
          </NavigationButton>
        </CardActionsRight>
      </CardActions>
    </Card>
  );
};
BackToDashboardBlock.displayName = "BackToDashboardBlock";

const PerformancePage = () => {
  const {
    modalState: exportTotalFeesPaidModalState,
    showModal: showTotalFeesPaidExportModal,
    closeModal: closeTotalFeesPaidExportModal,
  } = useModalState();

  const {
    modalState: exportNdaHistoryModalState,
    showModal: showNdaHistoryExportModal,
    closeModal: closeNdaHistoryExportModal,
  } = useModalState();

  return (
    <Stack>
      <BackToDashboardBlock title="Validator Performance" />
      <Card fill>
        <CardActions>
          <CardActionsLeft>
            <CardHeaderTitle as="h3">Total Fees Paid</CardHeaderTitle>
          </CardActionsLeft>
          <CardActionsRight>
            <Button
              icon={["far", "file-excel"]}
              size="small"
              onClick={showTotalFeesPaidExportModal}
            >
              Export
            </Button>
          </CardActionsRight>
        </CardActions>
        <CardBody>
          <TotalFeesPaidTableView
            modalState={exportTotalFeesPaidModalState}
            closeModal={closeTotalFeesPaidExportModal}
          />
        </CardBody>
      </Card>

      <Card fill>
        <CardActions>
          <CardActionsLeft>
            <CardHeaderTitle as="h3">Non Disclosure Agreement History</CardHeaderTitle>
          </CardActionsLeft>
          <CardActionsRight>
            <Button
              icon={["far", "file-excel"]}
              size="small"
              onClick={showNdaHistoryExportModal}
            >
              Export
            </Button>
          </CardActionsRight>
        </CardActions>
        <CardBody>
          <NdaHistoryTableView
            modalState={exportNdaHistoryModalState}
            closeModal={closeNdaHistoryExportModal}
          />
        </CardBody>
      </Card>

      <BackToDashboardBlock />
    </Stack>
  );
};
PerformancePage.displayName = "PerformancePage";

export default PerformancePage;
