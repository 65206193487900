import React from "react";

import Stack from "../../../components/lib/Stack";

import StatsTableCell, { StatsTableCellProps } from "./StatsTableCell";

export interface StatsTableColumnValuesProps {
  valueAvg: React.ReactElement | null;
  valueMin: React.ReactElement | null;
  valueMid: React.ReactElement | null;
  valueMax: React.ReactElement | null;
}

export interface StatsTableColumnProps
  extends Omit<StatsTableCellProps, "value">,
    StatsTableColumnValuesProps {}

const StatsTableColumn = (props: StatsTableColumnProps) => {
  const { valueAvg, valueMin, valueMid, valueMax, subtitle, ...otherProps } = props;

  return (
    <Stack
      fill
      css={{
        borderBottom: "3px dashed #e6e6e6",
        alignItems: "stretch",
        padding: "$5",
        gap: "$3",

        "&:last-of-type": {
          borderBottom: "none",
        },

        "@md": {
          width: "$1-of-3",
          borderBottom: "none",
        },
      }}
    >
      <StatsTableCell value={valueAvg} subtitle={subtitle ?? " Avg"} {...otherProps} />
      <StatsTableCell value={valueMin} subtitle={subtitle ?? " Min"} {...otherProps} />
      <StatsTableCell value={valueMid} subtitle={subtitle ?? " Mid"} {...otherProps} />
      <StatsTableCell value={valueMax} subtitle={subtitle ?? " Max"} {...otherProps} />
    </Stack>
  );
};

StatsTableColumn.displayName = "StatsTableColumn";

export default StatsTableColumn;
