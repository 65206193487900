import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import CheckBox from "../../../shared/CheckBox";
import classNames from "classnames";
import { Link } from "react-router";

const ContentListItem = observer(
  class extends Component {
    render() {
      const props = this.props;
      const tag = props.tag;
      const editing = tag.viewState.editing;
      const selected = tag.viewState.selected;

      const name = tag.name;
      const type = tag.type;
      const link = tag.link;

      let onItemClick = this.props.onClick;
      let editingSection = null;
      if (editing) {
        onItemClick = tag.toggleSelected;
        editingSection = (
          <CheckBox
            type={props.store.allowMultipleItemSelection ? "checkbox" : "radio"}
            name="list-item-select"
            value={name}
            selected={selected}
            style={{ width: 34 }}
            onClick={onItemClick}
          />
        );
      }

      return (
        <div className="ratecard-list-item-container">
          <div
            className={classNames("ratecard-list-item", { selected: selected })}
            onClick={onItemClick}
          >
            <div className="ratecard-list-item-left ellipsis">
              {editingSection}
              <div className="ratecard-label-container ellipsis">
                <div className="ratecard-label ellipsis">
                  <span className="ellipsis">{name}</span>
                </div>
              </div>
            </div>
            <div className="ratecard-list-item-right">
              <div className="ratecard-info w-300 text-center">
                <header>Type</header>
                <div>{type}</div>
              </div>
              {!editing && (
                <div className="rc-center">
                  {
                    <Link to={`/admin/${link}/${tag.id}`} className="btn btn-default">
                      View
                    </Link>
                  }
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
);

ContentListItem.propTypes = {
  store: PropTypes.object.isRequired,
  //rateCard: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default ContentListItem;
