import React, { useCallback, useMemo } from "react";
import { useRecoilState } from "recoil";
import { List, Set, Map, fromJS } from "immutable";
import moment from "moment";

import Icon from "../../../components/lib/Icon";
import Text from "../../../components/lib/Text";
import Box from "../../../components/lib/Box";
import PromiseButton from "../../../components/lib/PromiseButton";
import Button, { IconButton } from "../../../components/lib/Button";
import Inline from "../../../components/lib/Inline";
import { ButtonGroupRight } from "../../../components/lib/ButtonGroup";
import { TickerContentLoader } from "../../../components/lib/TickerLoader";
import {
  djangoPaginationKey,
  djangoPaginationSizeKey,
  FilterTypes,
} from "../../../components/tables/constants";
import { Column, TableConfig as TableConfigModal } from "../../../components/tables";
import { useTableSchemaState } from "../../../components/tables/Table";
import { rowIdGetter } from "../../../components/tables/utils";

// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";
import { transformReviewsData } from "../dataConverters";
import { emptyList } from "../../../constants";
import { ReviewsTableDataStateObject, reviewsTableGlobalState } from "../globalState";
import { dateTimeFormatter } from "../constants";
import {
  REVIEW_TYPES_TYPE,
  REVIEW_TYPES_LABELS,
  REVIEW_TYPES_OPTIONS,
  SOURCE_TYPES_TYPE,
  SOURCE_TYPES,
  SOURCE_TYPES_LABELS,
  SOURCE_TYPES_OPTIONS,
  SKILLS_LEVELS_TYPE,
  SKILLS_LEVELS_NUMERAL_LABELS,
  ALL_SKILLS_LEVELS_NUMERAL,
  VALUE_TYPES_TYPE,
  VALUE_TYPES,
  VALUE_TYPES_LABELS,
  ALL_VALUE_TYPES,
  REVIEW_PROCESSING_STATUS_TYPES,
  ACTIVE_TYPES_OPTIONS,
  READY_TYPES_OPTIONS,
  THREE_LEVEL_BANDING_TYPES_OPTIONS,
  FREE_TYPES_OPTIONS,
  ReviewDataMap,
  ReviewDataObject,
} from "../types";

import { TableFilterableRestful, TabButton, TabsPanel } from "./TableFilterableRestful";
import TextCrop from "../../validator5K_admin/components/TextCrop";

// TODO get rid of PLI references
import { renderTableColumnsSpecs } from "../../private_index/components/ExtendedRestfulTables";
import { useInitialFetch, useModalState } from "../../../utils/hooks";

import type {
  TableConfigOptionsList,
  VisibleColumnsSet,
} from "../../../components/tables/types";
import type { TableFilterableRestfulProps } from "../../../components/tables/TableFilterableRestful";
import type { ImmutableSet } from "../../../types/immutable";
import type { DjangoPaginatedResponse } from "../../../types/django";
import type { FetchAPIResponse } from "../../../types/fetch";
import type { TableColumnSpecsObject } from "../types";
import { useVal5KAdminContext } from "../context/Val5KAdminContext";

export const reviewsTableSpecs: TableColumnSpecsObject<ReviewDataMap>[] = [
  {
    type: "column",
    uniqueKey: "id",
    title: "Review ID",
    getter: (row) => row.get("id"),
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "title",
    title: "Review Title",
    getter: (row) => row.get("title"),
    formatter: (value, row) => {
      const reviewsGroupId = row.get("reviews_group_id");

      return (
        <Inline nowrap css={{ gap: "$2" }}>
          {!!reviewsGroupId && (
            <Text color="brand">
              <Icon icon="list" title="This survey is a member of a group." />
            </Text>
          )}
          <TextCrop mode="tooltip" title="Title" text={value} emptyStub="" />
        </Inline>
      );
    },
    filterType: FilterTypes.VALUES_CHECKLIST,
    popupSize: "wide",
    css: {
      textAlign: "left",
    },
  },
  {
    type: "column",
    uniqueKey: "countries_string",
    title: "Country",
    getter: (row) => row.get("countries_string"),
    formatter: (value) => (
      <TextCrop mode="tooltip" title="Country" text={value} emptyStub="" />
    ),
    filterType: FilterTypes.VALUES_CHECKLIST,
    popupSize: "wide",
  },
  {
    type: "column",
    uniqueKey: "states_string",
    title: "State",
    getter: (row) => row.get("states_string"),
    formatter: (value) => (
      <TextCrop mode="tooltip" title="State" text={value} emptyStub="" />
    ),
    filterType: FilterTypes.VALUES_CHECKLIST,
    popupSize: "wide",
  },
  {
    type: "column",
    uniqueKey: "cities_string",
    title: "City",
    getter: (row) => row.get("cities_string"),
    formatter: (value) => (
      <TextCrop mode="tooltip" title="City" text={value} emptyStub="" />
    ),
    filterType: FilterTypes.VALUES_CHECKLIST,
    popupSize: "wide",
  },
  {
    type: "column",
    uniqueKey: "regions_string",
    title: "Region",
    getter: (row) => row.get("regions_string"),
    formatter: (value) => (
      <TextCrop mode="tooltip" title="Region" text={value} emptyStub="" />
    ),
    filterType: FilterTypes.VALUES_CHECKLIST,
    popupSize: "wide",
  },
  {
    type: "column",
    uniqueKey: "review_type",
    title: "Review Type",
    getter: (row) => row.get("review_type"),
    formatter: (value: REVIEW_TYPES_TYPE) => REVIEW_TYPES_LABELS[value],
    sortable: false,
    filterType: FilterTypes.ENUMERATION,
    filterOptions: fromJS(REVIEW_TYPES_OPTIONS),
  },
  {
    type: "column",
    uniqueKey: "source_type",
    title: "Source Type",
    getter: (row) => row.get("source_type"),
    formatter: (value: SOURCE_TYPES_TYPE) => SOURCE_TYPES_LABELS[value],
    sortable: false,
    filterType: FilterTypes.ENUMERATION,
    filterOptions: fromJS(
      SOURCE_TYPES_OPTIONS.filter((option) => option.value !== SOURCE_TYPES.FROM_UPLOAD)
    ),
  },
  {
    type: "column",
    uniqueKey: "source_title",
    title: "Ratecard/Upload/Index",
    getter: (row) => row.get("source_title"),
    formatter: (value, row) => {
      const sourceType = row.get("source_type");
      const uploadId = row.get("upload_id");
      const ratecardId = row.get("ratecard_id");
      let resultingValue = value;

      if (sourceType === SOURCE_TYPES.FROM_RATECARD && ratecardId) {
        resultingValue = `(#${ratecardId}) ${value}`;
      } else if (sourceType === SOURCE_TYPES.FROM_UPLOAD && uploadId) {
        resultingValue = `(#${uploadId}) ${value}`;
      }

      return <TextCrop mode="tooltip" title="Title" text={resultingValue} emptyStub="" />;
    },
    filterType: FilterTypes.VALUES_CHECKLIST,
    popupSize: "xwide",
    css: {
      textAlign: "left",
    },
  },
  {
    type: "column",
    uniqueKey: "client_name",
    title: "Client",
    getter: (row) => row.get("client_name"),
    formatter: (value, row) => {
      return value ? `(#${row.get("client_id")}) ${value}` : "";
    },
    filterType: FilterTypes.VALUES_CHECKLIST,
  },
  {
    type: "column",
    uniqueKey: "required_levels",
    title: "Levels To Validate",
    getter: (row) => row.get("required_levels", emptyList).toJS(),
    formatter: (value, row) => {
      if (!Array.isArray(value)) {
        value = [value];
      }
      if (value && value.length) {
        if (Set(value).equals(Set(ALL_SKILLS_LEVELS_NUMERAL.map((i) => parseInt(i))))) {
          return "All";
        }
        return value
          .sort()
          .map((i: SKILLS_LEVELS_TYPE) => SKILLS_LEVELS_NUMERAL_LABELS[i])
          .join(", ");
      }
      return "All";
    },
    sortable: false,
    filterable: false,
  },
  {
    type: "column",
    uniqueKey: "required_rates",
    title: "Rates To Validate",
    getter: (row) => row.get("required_rates", emptyList).toJS(),
    formatter: (value, row) => {
      if (!Array.isArray(value)) {
        value = [value];
      }
      if (value && value.length) {
        if (Set(value).equals(Set(ALL_VALUE_TYPES))) {
          return "All";
        }
        return value.map((i: VALUE_TYPES_TYPE) => VALUE_TYPES_LABELS[i]).join(", ");
      }
      return VALUE_TYPES_LABELS[VALUE_TYPES.PAY_RATE];
    },
    sortable: false,
    filterable: false,
  },
  {
    type: "column",
    uniqueKey: "created",
    title: "Created Date",
    getter: (row) => row.get("created"),
    formatter: (value) => dateTimeFormatter(value),
    filterType: FilterTypes.DATES_RANGE,
    filterExtraProps: { datesFormat: moment.defaultFormat },
  },
  {
    type: "column",
    uniqueKey: "is_active",
    title: "Is Active",
    getter: (row) => row.get("is_active", false),
    formatter: (value) =>
      !!value ? (
        <Text color="positive">
          Active <Icon icon="check" />
        </Text>
      ) : null,
    sortable: false,
    filterType: FilterTypes.ENUMERATION,
    filterOptions: fromJS(ACTIVE_TYPES_OPTIONS),
  },
  {
    type: "column",
    uniqueKey: "processing_status",
    title: "Is Ready",
    getter: (row) => row.get("processing_status"),
    formatter: (value) => {
      if (value === REVIEW_PROCESSING_STATUS_TYPES.FINISHED) {
        return (
          <Text color="positive">
            Ready <Icon icon="check" />
          </Text>
        );
      } else if (value === REVIEW_PROCESSING_STATUS_TYPES.FAILED) {
        return (
          <Text color="negative">
            Failed <Icon icon="exclamation" />
          </Text>
        );
      } else if (value === REVIEW_PROCESSING_STATUS_TYPES.PROCESSING) {
        return <span>Processing...</span>;
      }
      return null;
    },
    sortable: false,
    filterType: FilterTypes.ENUMERATION,
    filterOptions: fromJS(READY_TYPES_OPTIONS),
  },
  {
    type: "column",
    uniqueKey: "is_three_level_banding",
    title: "Is Three Level Banding",
    getter: (row) => row.get("is_three_level_banding", false),
    formatter: (value) =>
      !!value ? (
        <Text color="positive">
          Yes <Icon icon="check" />
        </Text>
      ) : null,
    sortable: false,
    filterType: FilterTypes.ENUMERATION,
    filterOptions: fromJS(THREE_LEVEL_BANDING_TYPES_OPTIONS),
  },
  {
    type: "column",
    uniqueKey: "free_validation",
    title: "Is Free",
    getter: (row) => row.get("free_validation", false),
    formatter: (value) =>
      !!value ? (
        <Text color="positive">
          Free <Icon icon="check" />
        </Text>
      ) : null,
    sortable: false,
    filterType: FilterTypes.ENUMERATION,
    filterOptions: fromJS(FREE_TYPES_OPTIONS),
  },
  {
    type: "column",
    uniqueKey: "total_reward",
    title: "Reward",
    getter: (row) => row.get("total_reward"),
    filterType: FilterTypes.NUMBERS_RANGE,
  },
  {
    type: "column",
    uniqueKey: "attempts_count",
    title: "Attempts",
    getter: (row) => row.get("attempts_count"),
    filterType: FilterTypes.NUMBERS_RANGE,
  },
];

const defaultVisibleColumns = Set([
  "id",
  "title",
  "countries_string",
  "regions_string",
  "review_type",
  "source_type",
  "source_title",
  "client_name",
  "required_levels",
  "required_rates",
  "created",
  "is_active",
  "processing_status",
  "is_three_level_banding",
  "free_validation",
  "total_reward",
  "attempts_count",
]) as any as ImmutableSet<string>;

export const reviewsTableConfigOptions = List(
  reviewsTableSpecs.map(({ uniqueKey, title }) => Map({ uniqueKey, title }))
) as TableConfigOptionsList;

const reviewsTableConfigKeys = reviewsTableConfigOptions
  .map((option) => option.get("uniqueKey"))
  .toSet();

interface ReviewsTableProps extends Omit<TableFilterableRestfulProps, "schema"> {
  visibleColumns: VisibleColumnsSet;
  onChangeTableConfig: (
    visibleColumns: VisibleColumnsSet | null,
    itemsPerPage: number | null
  ) => void;
}

export const ReviewsTable = (props: ReviewsTableProps) => {
  const { visibleColumns, itemsPerPage, onChangeTableConfig } = props;

  // state

  const [schema] = useTableSchemaState(props.children);
  const {
    modalState: configModalState,
    showModal: showConfigModal,
    closeModal: closeConfigModal,
  } = useModalState();

  const customConfigTabModal = (
    <TableConfigModal
      options={reviewsTableConfigOptions}
      visibleColumns={visibleColumns}
      itemsPerPage={itemsPerPage}
      onChange={(...args) => onChangeTableConfig(...args)}
      show={configModalState}
      onHide={closeConfigModal}
      optionsGridCss={{
        gap: "$1",
        gridAutoFlow: "column",
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        gridTemplateRows: "repeat(19, minmax(0, 1fr))",
        "@lg": {
          gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
          gridTemplateRows: "repeat(7, minmax(0, 1fr))",
        },
      }}
    />
  );

  const customConfigTab = (
    <TabButton active={false} onClick={showConfigModal}>
      <Icon icon="cog" onClick={showConfigModal} title="Click to configure columns set" />
      &nbsp;&nbsp;Custom Table Config
    </TabButton>
  );

  return (
    <Box css={{ position: "relative", marginTop: "40px" }}>
      {customConfigTabModal}
      <TabsPanel>
        <Inline nogap>{customConfigTab}</Inline>
      </TabsPanel>
      <TableFilterableRestful {...props} schema={schema} />
    </Box>
  );
};

ReviewsTable.displayName = "ReviewsTable";

const ReviewsTableView = () => {
  const {
    router,
    fetchArgusAPI,
    fetchArgusFilteringAPI,
    showModalError,
    hideModal,
    showConfirmationModal,
    closeConfirmationModal,
  } = useVal5KAdminContext();

  // table state

  const [reviewsTableState, setReviewsTableState] =
    useRecoilState<ReviewsTableDataStateObject>(reviewsTableGlobalState);
  const { visibleColumns, loaded, ...restTableState } = reviewsTableState;

  // table data sources

  const fetchReviewsData = useCallback(
    async (urlQuery = {}, filtersQuery = {}, nextStateUpdates = {}) => {
      try {
        const response: FetchAPIResponse<DjangoPaginatedResponse<ReviewDataObject>> =
          await fetchArgusFilteringAPI(`reviews/filtered/`, {
            params: urlQuery,
            data: filtersQuery,
          });

        const nextDataState = transformReviewsData(response.data, nextStateUpdates);

        setReviewsTableState((prevDataState) => ({
          ...prevDataState,
          ...nextDataState,
          loaded: true,
        }));

        return nextDataState;
      } catch (err: any) {
        logAsyncOperationError("fetchReviewsList", err);
        showModalError(
          "Error occurred while loading reviews list. Please, try again later."
        );
        throw err;
      }
    },
    [setReviewsTableState, fetchArgusFilteringAPI, showModalError]
  );

  const fetchReviewsFiltersData = useCallback(
    async (urlQuery = {}, filtersQuery = {}) => {
      try {
        const response: FetchAPIResponse<DjangoPaginatedResponse<any>> =
          await fetchArgusFilteringAPI(`reviews/values/filtered/`, {
            params: urlQuery,
            data: filtersQuery,
          });

        return response.data;
      } catch (err: any) {
        logAsyncOperationError("fetchReviewsListFilterValues", err);
        showModalError(
          "Error occurred while loading filter values. Please, try again later."
        );
        throw err;
      }
    },
    [fetchArgusFilteringAPI, showModalError]
  );

  // utility functions

  const refreshReviewsData = useCallback(async () => {
    return fetchReviewsData(
      {
        [djangoPaginationKey]: reviewsTableState.activePage,
        [djangoPaginationSizeKey]: reviewsTableState.itemsPerPage,
      },
      reviewsTableState.filtersQuery.toJS()
    );
  }, [
    reviewsTableState.filtersQuery,
    reviewsTableState.activePage,
    reviewsTableState.itemsPerPage,
    fetchReviewsData,
  ]);

  // initial data load

  useInitialFetch(refreshReviewsData);

  // actions

  const handleRowClick = useCallback(
    (rowData, reviewId) => {
      if (reviewId) {
        router.push(`/admin/val5000/reviews/${reviewId}`);
      }
    },
    [router]
  );

  const handleDeleteItem = useCallback(
    async (e: React.MouseEvent<HTMLElement>, reviewId: string) => {
      e.stopPropagation();

      if (reviewId) {
        try {
          await fetchArgusAPI(`reviews/${reviewId}/`, { method: "delete" });
          await refreshReviewsData();
          hideModal();
        } catch (err: any) {
          logAsyncOperationError("deleteSurveyItem", err);
          showModalError(
            "Error occurred during survey deletion. Please, try again later."
          );
        } finally {
          closeConfirmationModal();
        }
      }
    },
    [showModalError, closeConfirmationModal, hideModal, refreshReviewsData, fetchArgusAPI]
  );

  const handleConfirmDeleteItem = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, review: ReviewDataMap) => {
      e.stopPropagation();

      const id = review.get("id");
      const title = review.get("title");
      const header = "Confirm delete action";
      const message = (
        <span>
          Do you really want to delete the following survey?
          <br />
          <b>{`- ${title} (id: ${id})`}</b>
        </span>
      );
      const footer = (
        <ButtonGroupRight fill reverse>
          <Button size="large" onClick={closeConfirmationModal}>
            No, Cancel
          </Button>
          <PromiseButton
            icon="trash-alt"
            loadingText="Yes, Delete"
            color="danger"
            size="large"
            onClick={(e) => handleDeleteItem(e, id)}
          >
            Yes, Delete
          </PromiseButton>
        </ButtonGroupRight>
      );

      showConfirmationModal(message, header, footer);
    },
    [handleDeleteItem, showConfirmationModal, closeConfirmationModal]
  );

  const handleDeactivateItem = useCallback(
    async (e: React.MouseEvent<HTMLElement>, reviewId: string) => {
      e.stopPropagation();

      if (reviewId) {
        try {
          await fetchArgusAPI(`reviews/${reviewId}/`, {
            method: "patch",
            data: { is_active: false },
          });
          await refreshReviewsData();
        } catch (err: any) {
          logAsyncOperationError("deactivateSurveyItem", err);
          showModalError(
            "Error ocurred during survey deactivation. Please, try again later."
          );
        }
      }
    },
    [refreshReviewsData, fetchArgusAPI, showModalError]
  );

  const handleActivateItem = useCallback(
    async (e: React.MouseEvent<HTMLElement>, reviewId: string) => {
      e.stopPropagation();

      if (reviewId) {
        try {
          await fetchArgusAPI(`reviews/${reviewId}/`, {
            method: "patch",
            data: { is_active: true },
          });
          await refreshReviewsData();
        } catch (err: any) {
          logAsyncOperationError("activateSurveyItem", err);
          showModalError(
            "Error ocurred during survey activation. Please, try again later."
          );
        }
      }
    },
    [refreshReviewsData, fetchArgusAPI, showModalError]
  );

  const handleChangeTableConfig = useCallback(
    (visibleColumns: VisibleColumnsSet | null, itemsPerPage: number | null) => {
      const changesObject: Partial<{
        visibleColumns: VisibleColumnsSet;
        itemsPerPage: number;
      }> = {};

      if (visibleColumns != null) {
        changesObject["visibleColumns"] = visibleColumns;
      }
      if (itemsPerPage != null) {
        changesObject["itemsPerPage"] = itemsPerPage;
      }

      setReviewsTableState((prevData) => ({
        ...prevData,
        ...changesObject,
      }));
    },
    [setReviewsTableState]
  );

  // rendering

  const renderActionsButtons = useCallback(
    (row: ReviewDataMap) => {
      const rowId = row.get("id");
      const isActive = row.get("is_active");
      const attemptsCount = row.get("attempts_count") || 0;

      if (attemptsCount > 0) {
        if (isActive) {
          return (
            <PromiseButton
              buttonImpl={IconButton}
              variant="text"
              size="small"
              icon="pause"
              color="warning"
              title="Deactivate Survey"
              onClick={(e) => handleDeactivateItem(e, rowId)}
            />
          );
        } else {
          return (
            <PromiseButton
              buttonImpl={IconButton}
              variant="text"
              size="small"
              icon="play"
              title="Activate Survey"
              color="success"
              onClick={(e) => handleActivateItem(e, rowId)}
            />
          );
        }
      }

      return (
        <PromiseButton
          buttonImpl={IconButton}
          variant="text"
          size="small"
          icon="trash-alt"
          title="Delete Survey"
          color="red"
          onClick={(e) => handleConfirmDeleteItem(e, row)}
        />
      );
    },
    [handleDeactivateItem, handleActivateItem, handleConfirmDeleteItem]
  );

  const resultingVisibleColumns = useMemo(
    () =>
      (visibleColumns.size ? visibleColumns : defaultVisibleColumns).filter(
        (key: string) => reviewsTableConfigKeys.includes(key)
      ),
    [visibleColumns]
  );

  const resultingColumnsSpecs = useMemo(
    () =>
      reviewsTableSpecs.filter((specObject) =>
        resultingVisibleColumns.includes(specObject.uniqueKey)
      ),
    [resultingVisibleColumns]
  );

  const columns = renderTableColumnsSpecs(resultingColumnsSpecs).concat(
    <Column
      key="__actions"
      uniqueKey="__actions"
      title="Actions"
      getter={renderActionsButtons}
      fixed
    />
  );

  if (!loaded) {
    return (
      <Box css={{ minHeight: "200px", position: "relative" }}>
        <TickerContentLoader />
      </Box>
    );
  }

  return (
    <ReviewsTable
      multimode
      highlighted
      rowIdGetter={rowIdGetter}
      onRowClick={handleRowClick}
      bodyEmptyText="No data"
      disablePagination={false}
      dataProvider={fetchReviewsData}
      filtersDataProvider={fetchReviewsFiltersData}
      onChangeTableConfig={handleChangeTableConfig}
      visibleColumns={resultingVisibleColumns}
      {...restTableState}
    >
      {columns}
    </ReviewsTable>
  );
};

ReviewsTableView.displayName = "ReviewsTableView";

export default ReviewsTableView;
