import React from "react";
import Immutable from "immutable";

/**
 * Performs equality by iterating through keys on an object and returning
 * false when any key has values which are not strictly equal between
 * objA and objB. Returns true when the values of all keys are strictly equal.
 *
 * @param objA {object}
 * @param objB {object}
 *
 * @return {boolean}
 */
export function shallowEqual(objA: any, objB: any) {
  if (objA === objB) {
    return true;
  }

  if (
    typeof objA !== "object" ||
    objA === null ||
    typeof objB !== "object" ||
    objB === null
  ) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  // Test for A's keys different from B.
  const bHasOwnProperty = Object.prototype.hasOwnProperty.bind(objB);
  for (let i = 0; i < keysA.length; i++) {
    if (!bHasOwnProperty(keysA[i]) || !Immutable.is(objA[keysA[i]], objB[keysA[i]])) {
      return false;
    }
  }

  return true;
}

export function shouldComponentUpdate(nextProps: Object, nextState: any) {
  // @ts-ignore
  return !shallowEqual(this.state, nextState) || !shallowEqual(this.props, nextProps);
}

export default class PureRenderImmutableComponent extends React.Component {
  shouldComponentUpdate(nextProps: Object, nextState: any) {
    return !shallowEqual(this.state, nextState) || !shallowEqual(this.props, nextProps);
  }
}
