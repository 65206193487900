import React, { useCallback } from "react";

import { ButtonGroupRight } from "../../../../components/lib/ButtonGroup";

import Button from "../../../../components/lib/Button";
import PromiseButton from "../../../../components/lib/PromiseButton";
import { useVal5KAdminContext } from "../../context/Val5KAdminContext";

type ActionsBlockProps = {
  created: boolean;
  onClear: () => void;
  onSubmit: () => Promise<null | undefined>;
};

export default function ActionsBlock(props: ActionsBlockProps) {
  const { created, onClear, onSubmit } = props;
  const { router } = useVal5KAdminContext();

  const handleClickManageReviews = useCallback(() => {
    router.push("/admin/val5000/surveys");
  }, [router]);

  return (
    <ButtonGroupRight>
      {!created && (
        <Button
          icon="arrow-left"
          color="brand"
          size="normal"
          onClick={handleClickManageReviews}
        >
          Manage Surveys
        </Button>
      )}
      <Button color="brand" size="normal" onClick={onClear}>
        Clear Form
      </Button>
      <PromiseButton
        size="normal"
        color="success"
        loadingText="Create Surveys"
        onClick={onSubmit}
      >
        Create Surveys
      </PromiseButton>
    </ButtonGroupRight>
  );
}

ActionsBlock.displayName = "ActionsBlock";
