// @flow
import { extendObservable, action, runInAction, observable } from "mobx";
import axios from "axios";
import NetworkState from "../../models/NetworkState";
import ModalState from "../../models/ModalState";
import MessageState from "../../models/MessageState";
import JobList from "../../models/JobList";
import { browserHistory } from "react-router";
import ApplyTagState from "../../models/ApplyTagState";
import CurrentUser from "../../models/User";
import type { FetchGraphQL } from "../../App";

class JobLabelDetailStore {
  router: Object;
  network: NetworkState;
  renameNetwork: NetworkState;
  confirmDeleteModal: ModalState;
  jobDetail: JobList;
  messaging: MessageState;
  jobId: number;
  jobTitle: String;
  newJobTitle: String;
  jobLabel: String;
  jobDescription: String;
  newJobDescription: String;
  showHelpModal: boolean;
  showHelp: () => void;
  hideHelp: () => void;
  getJobDetails: () => void;
  isEditing: boolean;
  stopEditing: () => void;
  startEditing: () => void;
  jobTitleId: number;
  newTitleId: number;
  saveEditedJob: () => void;
  changeJobLabelText: () => void;
  changeJobDescriptionText: () => void;
  changeJobTitleText: () => void;
  deletejob: () => void;
  showRenameJobModal: () => void;
  renameJobModal: ModalState;
  newJobLabel: String;
  onNewJobLabelChange: (Event) => void;
  renameJob: () => void;
  getJobDescription: () => void;
  applyTags: () => void;
  applyTagState: ApplyTagState;
  deleteSingleTags: () => void;
  CurrentUser: CurrentUser;
  selectedTags: Object;
  fetchGraphQL: FetchGraphQL;

  constructor(fetchGraphQL: FetchGraphQL) {
    this.router = null;
    this.fetchGraphQL = fetchGraphQL;

    extendObservable(this, {
      jobId: null,
      confirmDeleteModal: new ModalState(),
      renameJobModal: new ModalState(),
      network: new NetworkState(),
      renameNetwork: new NetworkState(),
      jobDetail: null,
      showHelpModal: false,
      messaging: new MessageState(),
      isEditing: false,
      jobLabel: "",
      jobTitle: "",
      jobDescription: "",
      jobTitleId: null,
      newJobLabel: "",
      newJobTitle: "",
      newJobTitleId: null,
      newJobDescription: "",
      applyTagState: new ApplyTagState(fetchGraphQL, this),
    });
    this.CurrentUser = null;
    this.showHelp = action(this.showHelp.bind(this));
    this.hideHelp = action(this.hideHelp.bind(this));
    this.getJobDetails = action(this.getJobDetails.bind(this));
    this.stopEditing = action(this.stopEditing.bind(this));
    this.startEditing = action(this.startEditing.bind(this));
    this.saveEditedJob = action(this.saveEditedJob.bind(this));
    this.changeJobLabelText = action(this.changeJobLabelText.bind(this));
    this.changeJobDescriptionText = action(this.changeJobDescriptionText.bind(this));
    this.changeJobTitleText = action(this.changeJobTitleText.bind(this));
    this.deletejob = action(this.deletejob.bind(this));
    this.showRenameJobModal = action(this.showRenameJobModal.bind(this));
    this.onNewJobLabelChange = action(this.onNewJobLabelChange.bind(this));
    this.renameJob = action(this.renameJob.bind(this));
    this.getJobDescription = action(this.getJobDescription.bind(this));
    this.applyTags = action(this.applyTags.bind(this));
    this.deleteSingleTags = action(this.deleteSingleTags.bind(this));
  }

  showHelp() {
    this.showHelpModal = true;
  }

  hideHelp() {
    this.showHelpModal = false;
  }

  stopEditing() {
    this.isEditing = false;
  }

  startEditing() {
    this.isEditing = true;
  }

  changeJobLabelText(e: Object) {
    this.jobLabel = e.target.value;
  }

  changeJobDescriptionText(e: Object) {
    this.newJobDescription = e.target.value;
  }

  changeJobTitleText(e: Object) {
    if (e && e.id) {
      this.newJobTitle = e.title;
      this.newJobTitleId = e.id;
      this.getJobDescription(e.id);
    } else {
      this.newJobTitle = "";
    }
  }

  showRenameJobModal() {
    this.messaging.removeAll();
    this.newJobLabel = this.jobLabel;
    this.renameJobModal.showModal();
  }

  onNewJobLabelChange(e: Event) {
    this.newJobLabel = e.target.value;
  }

  async getJobDescription(title_id) {
    const query = `
            query jobTitleDetail{
            viewer{
              jobTitle(id: ${title_id}){
                description,
                id
              }
            }
          }
        `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, null);
      // should hit get request
    } catch (e) {
      console.error("Error editing job", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }

    runInAction("JobDescription--success", () => {
      this.newJobDescription = res.data.viewer.jobTitle.description;
    });

    return {
      jobDescription: res.data.viewer.jobTitle.description,
    };
  }

  async renameJob() {
    const variables = {
      jobLabel: this.newJobLabel,
      jobId: this.jobId,
    };

    this.messaging.removeAll();

    if (!this.newJobLabel.trim()) {
      this.messaging.createMessage("info", "Please enter a New Job Label.");
      return;
    }

    const query = `
      mutation renameJob($jobId:Int!, $jobLabel:String! ){
        renameJob(jobLabel:$jobLabel, jobId:$jobId){
          ok
          error {
            __typename
            ... on JobDoesNotExistsError {
              message
            }
            ... on JobLabelEmptyError {
              message
            }
         }
        }

      }
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
      // should hit get request
    } catch (e) {
      console.error("Error renaming job", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }

    runInAction("renameJob--success", () => {
      if (res.data.renameJob.error) {
        this.messaging.removeAll();
        this.messaging.createMessage("error", res.data.renameJob.error[0].message);
        return;
      }
      this.getJobDetails();
      this.renameJobModal.hideModal();
    });

    return {};
  }

  async deletejob() {
    const variables = {
      jobData: {
        jobId: this.jobId,
      },
    };

    const query = `
      mutation deleteJob($jobData : DeleteJobInput){
       deleteJob(jobData: $jobData){
         ok
       }
      }
    `;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
      // should hit get request
    } catch (e) {
      console.error("Error Deleting job", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }

    runInAction("deleteJob--success", () => {
      browserHistory.push("/job-labels/");
    });

    return {};
  }

  async saveEditedJob() {
    const variables = {
      jobData: {
        jobLabel: this.newJobLabel,
        jobTitle: this.newJobTitle,
        jobTitleId: this.newJobTitleId,
        jobDescription: this.newJobDescription,
        jobId: this.jobId,
      },
    };

    const query = `
      mutation updateJobMutation($jobData: UpdateJobInput){
        updateJob(jobData:$jobData){
          job{
            jobLabel,
            jobTitle,
            jobTitleId,
            jobDescription
          }
        }
      }
    `;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
      // should hit get request
    } catch (e) {
      console.error("Error editing job", e);
      // TODO: Handle errors properly
      throw e; // Prevent success action from running
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }

    runInAction("saveEditedJob--success", () => {
      this.getJobDetails();
      this.isEditing = false;
    });

    return {
      job: res.data.updateJob.job,
    };
  }

  async getJobDetails() {
    let res = null;
    if (!/^\d+$/.test(this.jobId)) {
      if (this.router) {
        this.router.push({
          pathname: "/404NotFound",
          query: this.router.query,
        });
      }
      return res;
    }
    const variables = {
      id: parseInt(this.jobId, 10),
    };
    const query = `
      query jobDetail($id : Int!){
        viewer{
          user{
            firstName
            lastName
            userId
            username
            email
          }
          job(id:$id){
            jobId
            created
            modified
            jobTitle
            jobLabel
            jobTitleId
            jobDescription
            tags{
              name
              tagId
            }
            createdBy{
              firstName
              lastName
            }
          }
        }
      }
    `;

    this.network.loading = true;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting Job Details", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Job Details query", res);
      }
      return e;
    }
    return runInAction("getProjects--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (res.data.viewer && !res.data.viewer.job) {
        if (this.router) {
          this.router.push({
            pathname: "/404NotFound",
            query: this.router.query,
          });
        }
      }
      if (this.network.logGraphQLError("Get Job Details query", res)) {
        return {};
      }
      this.selectedTags = observable.map({});
      res.data.viewer.job.tags.forEach((item) => {
        this.selectedTags.set(item.tagId, item);
      });
      this.currentUser = new CurrentUser(this, res.data.viewer.user);
      const job = res.data.viewer.job;
      this.jobDetail = new JobList(this, job);
      this.jobLabel = this.jobDetail.jobLabel;
      this.newJobLabel = this.jobDetail.jobLabel;
      this.jobTitle = this.jobDetail.jobTitle;
      this.newJobTitle = this.jobDetail.jobTitle;
      this.jobDescription = this.jobDetail.jobDescription;
      this.newJobDescription = this.jobDetail.jobDescription;
      this.jobTitleId = this.jobDetail.jobTitleId;
      this.newJobTitleId = this.jobDetail.jobTitleId;
    });
  }

  async applyTags() {
    let params = [];
    let filterargs = [];
    let searchargs = [];
    let args = [];
    let vars = {};

    const taglist = this.applyTagState.getSelectedTagList();
    if (!taglist || !taglist.length) {
      console.error("Cannot Apply tags to Jobs: No Jobs selected");
      return;
    }
    params.push("$tagIds: [Int]!");
    filterargs.push("tagIds: $tagIds");
    vars.tagIds = taglist;
    params.push("$only: [ID]");
    args.push("only: $only");
    vars.only = [this.jobDetail.jobId];

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
      mutation applyTags(${queryParams}){
       applyTagsToJobs(input:{${filterargs}, filters: { ${queryArgs}}, ${searchargs}}) {
         ok
          errors {
           __typename
           ... on TagIdRequiredError {
             message
           }
           ... on TagIdsNotExistsError {
             message
           }
           ... on InvalidInputError {
             message
           }
           ... on ContentDoesNotExistsError {
             message
           }
         }
       }

      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Apply Tags to Selected Jobs", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    runInAction("applyTags--success", () => {
      this.applyTagState.tagModal.hideModal();
      this.getJobDetails();
    });
  }

  async deleteSingleTags(content) {
    const query = `
    mutation removeTagsFromContent{
      removeTagsFromContent(input: {tagIds : [${content.tagId}],contentType :${content.contentType},contentId:${content.contentId}}){
        ok
        }
      }
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, null);
    } catch (e) {
      // TODO: Display user friendly error message
      console.log("error", e);
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      return;
    }

    runInAction("deleteSingleTags--success", () => {
      // debugger
      this.jobDetail.tags.forEach((item, index) => {
        if (content.tagId === item.tagId) {
          this.jobDetail.tags.splice(index, 1);
          this.selectedTags.delete(item.tagId);
        }
      });
      this.jobDetail = new JobList(this, this.jobDetail);
    });
  }
}
export default JobLabelDetailStore;
