// @flow

import { extendObservable, action, runInAction, computed } from "mobx";
import axios from "axios";
import { PAGINATION_CONSTANTS } from "../constants/sharedConstants";

export type PageQuery = {
  params: string[],
  args: string[],
  variables: Object,
  page: number,
  itemsPerPage: number,
  afterCursor: ?string,
};

export type PaginationInfo = {
  totalCount: number,
  startCursor: string,
  endCursor: string,
};

class BasePaginationState {
  maxPagesToShow: number;
  begin: number;
  end: number;
  currentPage: number;
  hasLoaded: boolean;
  isLoading: boolean;
  isEndRange: boolean;
  itemsPerPage: number;
  totalCount: number;
  pageCount: number;

  fetchData: (PageQuery) => Promise<PaginationInfo>;
  +goFetch: (?boolean, ?boolean, ?number) => Promise<any>; // defined as covariant to allow inheritance

  handleFirstPage: () => void;
  handleNextPage: () => void;
  handlePrevPage: () => void;
  handleLastPage: () => void;

  constructor(fetchData: Function, object?: Object = {}) {
    this.maxPagesToShow = object.maxPagesToShow || 5;

    extendObservable(this, {
      begin: 1,
      end: this.maxPagesToShow,
      totalCount: 0,
      pageCount: 0,
      currentPage: 1,
      hasLoaded: false,
      isEndRange: false,
    });

    this.itemsPerPage = object.itemsPerPage || 10;
    this.isLoading = false;
    this.fetchData = fetchData;

    this.goFetch = action(this.goFetch.bind(this));
    this.handleFirstPage = action(this.handleFirstPage.bind(this));
    this.handleNextPage = action(this.handleNextPage.bind(this));
    this.handlePrevPage = action(this.handlePrevPage.bind(this));
    this.handleLastPage = action(this.handleLastPage.bind(this));
  }

  goFetch(
    forward: ?boolean = true,
    backward: ?boolean = false,
    last?: ?number = null
  ): Promise<any> {
    throw new Error("Not implemented goFetch()");
    // return Promise.reject(new Error("Not implemented goFetch()"));
  }

  async handleFirstPage() {
    await this.goFetch(false, false, null);
  }

  async handleNextPage() {
    await this.goFetch(true, false, null);
  }

  async handlePrevPage() {
    await this.goFetch(false, true, this.itemsPerPage);
  }

  async handleLastPage() {
    if (this.hasLoaded === false) {
      // NOTE: This should never be called until pagination data is loaded.
      return;
    }

    if (!this.totalCount) {
      return;
    }

    const remaining = this.totalCount % this.itemsPerPage;

    let lastOffset = null;
    if (remaining > 0) {
      lastOffset = remaining;
    } else {
      lastOffset = this.itemsPerPage;
    }

    await this.goFetch(false, false, lastOffset);
  }

  adjustPaginationState = (
    totalCount: number = 0,
    pageCount: number = 0,
    forward: ?boolean = false,
    backward: ?boolean = false,
    last: ?number = null
  ) => {
    this.totalCount = totalCount;
    this.pageCount = pageCount;

    // If the page we ask for no longer exists, while paging, go to the end of the new count.
    if (this.currentPage > this.pageCount) {
      this.currentPage = this.pageCount;
    }

    if (this.pageCount <= this.maxPagesToShow) {
      this.begin = 1;
      this.end = this.pageCount;
      this.isEndRange = true;

      if (forward === true) {
        this.currentPage += 1;
      }

      if (backward === true) {
        this.begin = Math.max(this.begin - 1, 1);
        this.end = Math.max(this.end - 1, this.pageCount);
        this.currentPage -= 1;
      }

      if (forward === false && backward === false && last === null) {
        this.currentPage = 1;
      }

      if (forward === false && backward === false && last !== null) {
        this.currentPage = this.pageCount;
      }

      // console.log('++++++++++');
      // console.log('totalCount', this.totalCount);
      // console.log('pageCount', this.pageCount);
      // console.log('currentPage', this.currentPage);
      // console.log('begin', this.begin);
      // console.log('end', this.end);
      // console.log('isEndRange', this.isEndRange);

      return;
    }

    let last_end = this.pageCount;
    let last_begin = this.pageCount - this.maxPagesToShow + 1;
    let last_midpoint = Math.ceil((last_end - last_begin) / 2) + last_begin;
    let midpoint = Math.ceil((this.end - this.begin) / 2) + this.begin;

    // Initial load
    if (forward === false && backward === false && last === null) {
      this.currentPage = 1;
      this.begin = 1;
      this.end = this.maxPagesToShow;
      this.isEndRange = this.currentPage >= last_midpoint;

      return;
    }

    if (this.currentPage < midpoint) {
      if (forward === true) {
        this.currentPage += 1;
      }

      if (backward === true) {
        this.currentPage -= 1;
      }

      if (forward === false && backward === false && last !== null) {
        this.currentPage = this.pageCount;
        this.begin = last_begin;
        this.end = last_end;
      }

      // console.log('++++++++++');
      // console.log('totalCount', this.totalCount);
      // console.log('pageCount', this.pageCount);
      // console.log('currentPage', this.currentPage);
      // console.log('begin', this.begin);
      // console.log('end', this.end);
      // console.log('isEndRange', this.isEndRange);
      // console.log('last_end', last_end);
      // console.log('last_begin', last_begin);
      // console.log('last_midpoint', last_midpoint);
      // console.log('midpoint', midpoint);
      // console.log('++++++++++');
      this.isEndRange = this.currentPage >= last_midpoint;

      return;
    }

    if (this.currentPage >= last_midpoint) {
      if (forward === true) {
        this.currentPage += 1;

        if (this.begin < last_begin) {
          this.begin += 1;
        }

        if (this.end < last_end) {
          this.end += 1;
        }
      }

      if (backward === true) {
        if (this.currentPage === last_midpoint) {
          this.begin = Math.max(this.begin - 1, 1);
          this.end = Math.max(this.end - 1, this.maxPagesToShow);
        }

        this.currentPage -= 1;
      }

      if (forward === false && backward === false && last !== null) {
        this.currentPage = this.pageCount;
        this.begin = last_begin;
        this.end = last_end;
      }

      // console.log('++++++++++');
      // console.log('totalCount', this.totalCount);
      // console.log('pageCount', this.pageCount);
      // console.log('currentPage', this.currentPage);
      // console.log('begin', this.begin);
      // console.log('end', this.end);
      // console.log('isEndRange', this.isEndRange);
      // console.log('last_end', last_end);
      // console.log('last_begin', last_begin);
      // console.log('last_midpoint', last_midpoint);
      // console.log('midpoint', midpoint);
      // console.log('++++++++++');
      this.isEndRange = this.currentPage >= last_midpoint;

      return;
    }

    if (this.currentPage === midpoint) {
      if (forward === true) {
        this.begin += 1;
        this.currentPage += 1;
        this.end += 1;
      }

      if (backward === true) {
        this.begin = Math.max(this.begin - 1, 1);
        this.end = Math.max(this.end - 1, this.maxPagesToShow);
        this.currentPage -= 1;
      }

      if (forward === false && backward === false && last !== null) {
        this.currentPage = this.pageCount;
        this.begin = last_begin;
        this.end = last_end;
      }

      // console.log('++++++++++');
      // console.log('totalCount', this.totalCount);
      // console.log('pageCount', this.pageCount);
      // console.log('currentPage', this.currentPage);
      // console.log('begin', this.begin);
      // console.log('end', this.end);
      // console.log('isEndRange', this.isEndRange);
      // console.log('last_end', last_end);
      // console.log('last_begin', last_begin);
      // console.log('last_midpoint', last_midpoint);
      // console.log('midpoint', midpoint);
      // console.log('++++++++++');
      this.isEndRange = this.currentPage >= last_midpoint;

      return;
    }

    if (this.currentPage < last_midpoint) {
      if (forward === true) {
        this.currentPage += 1;

        if (this.begin < last_begin) {
          this.begin += 1;
        }

        if (this.end < last_end) {
          this.end += 1;
        }
      }

      if (backward === true) {
        this.currentPage -= 1;
      }

      if (forward === false && backward === false && last !== null) {
        this.currentPage = this.pageCount;
        this.begin = last_begin;
        this.end = last_end;
      }

      // console.log('++++++++++');
      // console.log('totalCount', this.totalCount);
      // console.log('pageCount', this.pageCount);
      // console.log('currentPage', this.currentPage);
      // console.log('begin', this.begin);
      // console.log('end', this.end);
      // console.log('isEndRange', this.isEndRange);
      // console.log('last_end', last_end);
      // console.log('last_begin', last_begin);
      // console.log('last_midpoint', last_midpoint);
      // console.log('midpoint', midpoint);
      // console.log('++++++++++');

      this.isEndRange = this.currentPage >= last_midpoint;
    }
  };
}

/*
 *  Pagination state representation built for GraphQL-based API
 */
export class CursorPaginationState extends BasePaginationState {
  startCursor: ?string;
  endCursor: ?string;

  fetchData: (PageQuery) => Promise<PaginationInfo>;
  goFetch: (?boolean, ?boolean, ?number) => Promise<any>;

  constructor(fetchData: (PageQuery) => Promise<PaginationInfo>, object?: Object = {}) {
    super(fetchData, object);

    this.startCursor = null;
    this.endCursor = null;
  }

  async goFetch(
    forward: ?boolean = false,
    backward: ?boolean = false,
    last?: ?number = null
  ): Promise<any> {
    let params = [];
    let args = [];
    let variables = {};

    if (last === null) {
      params.push("$first: Int!");
      args.push("first: $first");
      variables.first = this.itemsPerPage;
    } else {
      params.push("$last: Int!");
      args.push("last: $last");
      variables.last = last;
    }

    if (forward) {
      params.push("$after: String!");
      args.push("after: $after");
      variables.after = this.endCursor;
    }

    if (backward) {
      params.push("$before: String!");
      args.push("before: $before");
      variables.before = this.startCursor;
    }

    const pageQuery = {
      params,
      args,
      variables,
      itemsPerPage: this.itemsPerPage,
      page: 0,
    };

    this.isLoading = true;

    let data = null;

    try {
      data = await this.fetchData(pageQuery);
    } catch (e) {
      if (axios.isCancel(e)) {
        // console.log('Request Canceled PaginationState');
        return Promise.reject(e);
      }

      // console.log('Request Error PaginationState');
      return Promise.reject(e);
    } finally {
      this.isLoading = false;
    }

    runInAction("goFetch--success", () => {
      if (!data) data = { totalCount: 0, startCursor: null, endCursor: null };

      const totalCount = data.totalCount;
      const pageCount = Math.ceil(totalCount / this.itemsPerPage);

      this.startCursor = data.startCursor;
      this.endCursor = data.endCursor;
      this.hasLoaded = true;

      return this.adjustPaginationState(totalCount, pageCount, forward, backward, last);
    });

    return Promise.resolve(data);
  }
}

export default class PaginationState {
  maxPagesToShow: number;
  begin: number;
  end: number;
  currentPage: number;
  isLoading: boolean;
  isEndRange: boolean;
  itemsPerPage: number;
  totalCount: number;
  startCursor: ?string;
  endCursor: ?string;
  pageCount: number;
  classArray: ?(string[]);
  midpoint: ?number;
  firstPage: number;
  hasLoaded: boolean;
  hasNextPage: boolean;

  fetchData: (PageQuery) => Promise<PaginationInfo>;

  goFetch: (?number) => Promise<any>;
  handleCurrentPage: (number) => void;

  constructor(
    fetchData: (PageQuery) => Promise<PaginationInfo>,
    itemPerPage?: number = 0
  ) {
    // this.maxPagesToShow = PAGINATION_CONSTANTS.maximum_pages_to_show;

    extendObservable(this, {
      begin: 1,
      end: this.maxPagesToShow,
      totalCount: 0,
      pageCount: 0,
      firstPage: PAGINATION_CONSTANTS.current_page,
      currentPage: PAGINATION_CONSTANTS.current_page,
      itemsPerPage: itemPerPage ? itemPerPage : PAGINATION_CONSTANTS.per_page,
      maxPagesToShow: PAGINATION_CONSTANTS.maximum_pages_to_show,
      isEndRange: false,
      classArray: [],
      midpoint: null,
      hasLoaded: false,
      startCursor: null,
      endCursor: null,
      isLoading: false,
      hasNextPage: computed(() => {
        return this.currentPage < this.pageCount;
      }),
    });

    // this.itemsPerPage = PAGINATION_CONSTANTS.per_page;
    // this.startCursor = null;
    // this.endCursor = null;
    // this.isLoading = false;
    // this.firstPage = PAGINATION_CONSTANTS.current_page;

    this.fetchData = fetchData;

    this.goFetch = action(this.goFetch.bind(this));
    this.handleCurrentPage = action(this.handleCurrentPage.bind(this));
  }

  async handleCurrentPage(activePage: number) {
    this.currentPage = activePage;
    window.scrollTo(0, 0);
    await this.goFetch(activePage);
  }

  async goFetch(activePage?: ?number = 0): Promise<any> {
    let params = [];
    let args = [];
    let variables = {};
    let offset = activePage ? activePage : 1;
    if (!activePage) {
      this.totalCount = 0;
      this.begin = 1;
      this.currentPage = 1;
    }
    let lastArray = [];

    params.push("$offset: Int!");
    args.push("offset: $offset");
    params.push("$first: Int!");
    args.push("first: $first");
    variables.offset = offset;
    variables.first = this.itemsPerPage;

    const pageQuery = {
      params,
      args,
      variables,
      itemsPerPage: this.itemsPerPage,
      page: offset,
      afterCursor:
        offset > 1
          ? btoa(`arrayconnection:${(offset - 1) * this.itemsPerPage - 1}`)
          : null,
    };

    this.isLoading = true;

    let data = null;

    try {
      // console.log("fetching:", pageQuery);
      data = await this.fetchData(pageQuery);
    } catch (e) {
      this.isLoading = false;
      if (axios.isCancel(e)) {
        // console.log('Request Canceled PaginationState');
        // TODO: do something here if the request was canceled?

        if (e.message === "reloading") {
          this.isLoading = true;
        }
      }
      // console.log('Request Error PaginationState');
      return Promise.reject(e);
    }

    return runInAction("goFetch--success", () => {
      // console.log("pagination data:", data);
      if (!data) return;

      this.hasLoaded = true;
      this.totalCount = data.totalCount || 0;
      // Display pagination only when the number of pages are greater than one
      if (this.totalCount === 0) {
        this.pageCount = 0;
      } else if (this.totalCount <= this.itemsPerPage) {
        this.pageCount = 1;
      } else {
        this.pageCount =
          this.totalCount / this.itemsPerPage > 1
            ? Math.ceil(this.totalCount / this.itemsPerPage)
            : 0;
      }
      this.midpoint = Math.ceil((this.end - this.begin) / 2) + this.begin;
      this.currentPage = offset;
      this.startCursor = data.startCursor;
      this.endCursor = data.endCursor;

      // if the number of pages are smaller than the maximum pages to be displayed the end value should be the last page or the total number of page count
      if (this.pageCount < this.maxPagesToShow) {
        this.isEndRange = true;
        this.end = this.pageCount;
      } else {
        // Array declared for displaying last page elements
        for (var i = this.pageCount - this.maxPagesToShow + 1; i <= this.pageCount; i++) {
          lastArray.push(i);
        }
        // last segment of the pagination
        if (lastArray.indexOf(this.currentPage) > -1) {
          this.isEndRange = true;
          this.begin = this.pageCount - this.maxPagesToShow + 1;
          this.end = this.pageCount;
        } else {
          this.isEndRange = false;

          if (this.midpoint && this.currentPage < this.midpoint) {
            if (this.currentPage === 1) {
              this.begin = 1;
              this.end = this.maxPagesToShow;
            } else {
              this.begin = Math.max(this.begin - 1, 1);
              this.end = Math.max(this.end - 1, this.maxPagesToShow);
            }
          } else {
            if (this.currentPage === 1) {
              this.begin = 1;
              this.end = this.maxPagesToShow;
            } else {
              this.begin = this.currentPage - 1;
              this.end = this.currentPage + 2;
              this.midpoint = this.currentPage + 1;
            }
          }
        }
      }
      return data;
    });
  }

  nextPage = (): Promise<any> => {
    if (!this.hasNextPage) return Promise.reject("There's no next page.");

    return this.goFetch(this.currentPage + 1);
  };
}

/*
 *  Pagination state representation adopted for the usage with Django-based API
 */
// class DjangoPaginationState extends PaginationState {
//   fetchData: DjangoApiPaginatedQueryArgs => Promise<DjangoApiPaginatedResponse>;
//   goFetch: (forward: boolean, backward: boolean, last: ?number) => Promise<?DjangoApiPaginatedResponse>;
//
//   constructor(fetchData: DjangoApiPaginatedQueryArgs => Promise<DjangoApiPaginatedResponse>, object: Object = {}) {
//     super(fetchData, object);
//   }
//
//   async goFetch(forward: boolean = false, backward: boolean = false, last: ?number = null) {
//     this.isLoading = true;
//
//     const pageQuery: DjangoApiPaginatedQueryArgs = new DjangoApiPaginatedQueryArgs({
//       page: 1,
//       page_size: this.itemsPerPage
//     });
//     let data: ?DjangoApiPaginatedResponse = null;
//
//     if (!forward && !backward && !last) {
//       // get first page
//       pageQuery.page = 1;
//     } else if (forward && !backward && !last) {
//       // get next page
//       pageQuery.page = this.currentPage + 1;
//     } else if (!forward && backward && last) {
//       // get prev page
//       pageQuery.page = this.currentPage - 1;
//     } else if (!forward && !backward && last) {
//       // get last page
//       pageQuery.page = this.pageCount;
//     }
//
//     try {
//       data = await this.fetchData(pageQuery);
//     } catch (e) {
//       if (axios.isCancel(e)) {
//         // console.log('Request Canceled PaginationState');
//         return e;
//       }
//
//       // console.log('Request Error PaginationState');
//       return e;
//     } finally {
//     }
//
//     runInAction("goFetch--success", () => {
//       this.isLoading = false;
//       this.hasLoaded = true;
//
//       if (data) {
//         const totalCount = data.count;
//         const pageCount = Math.ceil(totalCount / this.itemsPerPage);
//
//         return this.adjustPaginationState(totalCount, pageCount, forward, backward, last);
//       }
//     });
//
//     return data;
//   }
// }
//
// export { DjangoPaginationState };
