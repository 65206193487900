import React from "react";
import graphql from "babel-plugin-relay/macro";
// relay
import { SettingsMenuSearchResutls_viewer$key } from "./__generated__/SettingsMenuSearchResutls_viewer.graphql";
// lib
import Inline from "../../../../components/lib/Inline";
import { useFragment } from "react-relay";
import Button from "../../../../components/lib/Button";
import { styled } from "../../../../stitches.config";
import Icon from "../../../../components/lib/Icon";
import { Placeholder } from "../../../../components/lib/Placeholder";
// store
import { useSearchResultsStore } from "../../../../stores/SearchResults";
// components
import RsRateMultiplierDialog from "./MultiplierDialog";
import { RateFrequencyDropdown } from "./RateFrequencyDropdown";
import { CurrencyDropdown } from "./CurrencyDropdown";

const SettingsBtn = styled(Button, {
  position: "relative",
});

const SettingsIndicator = styled("span", {
  position: "absolute",
  top: "2px",
  left: "19px",
  minWidth: "10px",
  minHeight: "10px",
  background: "$brand",
  borderRadius: "$full",
});

const fragment = graphql`
  fragment SettingsMenuSearchResutls_viewer on Viewer
  @argumentDefinitions(searchId: { type: "Int!" }) {
    ...CurrencyDropdown_viewer
    savedsearch(id: $searchId) {
      id
      rateType
    }
  }
`;

type Props = {
  viewer: SettingsMenuSearchResutls_viewer$key;
};

export function SettingsMenu({ viewer }: Props) {
  const data = useFragment(fragment, viewer);
  const isContract = data.savedsearch?.rateType === 1;
  const hasMultipliersChanged = useSearchResultsStore((s) => s.hasMultipliersChanged());

  if (isContract) {
    return (
      <Inline>
        <RsRateMultiplierDialog>
          <SettingsBtn title="Change multipliers">
            <Icon icon="cog" />
            {hasMultipliersChanged && <SettingsIndicator />}
          </SettingsBtn>
        </RsRateMultiplierDialog>
        <RateFrequencyDropdown />
        <CurrencyDropdown viewer={data} />
      </Inline>
    );
  }

  return (
    <Inline>
      <CurrencyDropdown viewer={data} />
    </Inline>
  );
}

export function SettingsMenuLoader() {
  return (
    <Inline>
      <Placeholder bgColor="$primaryLight" width={38} height={30} />
      <Placeholder bgColor="$primaryLight" width={90} height={30} />
      <Placeholder bgColor="$primaryLight" width={64} height={30} />
    </Inline>
  );
}
