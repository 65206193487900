import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import Pagination from "../../../ratecards/components/Pagination";
import LoadingIndicator from "../../../shared/LoadingIndicator";
import UserListItem from "./UserListItem";
import ToggleButton from "../../../ratecards/components/ToggleButton";
import SortIndicator from "../../../ratecards/components/SortIndicator";
import { FILTER_COLUMN } from "../../../../models/Filter";
import FilterModal from "../../../ratecards/components/FilterModal";
import SortControls from "../../../ratecards/components/SortControls";
import ListContainer from "../../../ratecards/components/ListContainer";
import ContainerSection from "../../../ratecards/components/ContainerSection";
import SearchBox from "../../../ratecards/components/SearchBox";
import ClientUserFilter from "../../../filters/ClientUserFilter";

class UserList extends Component {
  constructor(props) {
    super(props);

    this.changeCurrent = this.changeCurrent.bind(this);
  }

  changeCurrent(value) {
    let store = this.props.store;
    store.pagination.handleCurrentPage(value);
  }

  UNSAFE_componentWillMount() {
    this.props.store.handleStopEdit();
    this.props.store.applyDefaultSort();
  }

  componentDidMount() {
    this.props.store.pagination.goFetch();
    this.props.store.userFilter.textToLookFor = "";
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this.props.store.clearFilters();
    this.props.store.clearAllSelections();
    this.props.store.clientId = "";
  }

  render() {
    const store = this.props.store;
    //debugger
    const network = store.network;
    const fillContainer = this.props.fillContainer;
    const fixedHeight = this.props.fixedHeight;
    let listBodyStyles = {};
    if (fixedHeight) {
      listBodyStyles.height = fixedHeight;
      listBodyStyles.overflowY = "auto";
    }

    let selectAllSection = null;
    let selectBtn = (
      <nav className="header-left-nav">
        <div className="btn" onClick={store.selectAllOnPageItem}>
          Select All
        </div>
        <div className="btn" onClick={store.deselectAllPage}>
          Deselect All
        </div>
      </nav>
    );

    let selectAllOnPageSection = (
      <div className="header-left-nav">
        <SearchBox
          value={store.userFilter.textToLookFor}
          onChange={store.userFilter.onTextToLookForChange}
          onSearch={store.userFilter.onApply}
          style={{
            width: "260px",
          }}
        />
      </div>
    );

    if (store.allOnPageSelected) {
      selectAllSection = (
        <div className="ratecards-list-select-all-section">
          <span>
            All &nbsp;
            <strong>{store.totalCount}</strong>
            &nbsp; Users selected.{" "}
          </span>
          &nbsp;
          {!store.isTagView && (
            <span
              className="pt-link ratecards-all-items"
              key="clear-selections-link"
              onClick={store.clearAllSelections}
            >
              Clear all selections
            </span>
          )}
        </div>
      );
    }

    if (store.allOnPageSelected && !store.allSelected) {
      selectAllSection = (
        <div className="ratecards-list-select-all-section">
          <span>All Users on this page selected. &nbsp;</span>
          &nbsp;
          <span
            className="pt-link ratecards-all-items"
            key="select-all-item"
            onClick={store.selectAllPage}
          >
            Select all &nbsp;
            <strong>{store.totalCount}</strong>
            &nbsp; Users.
          </span>
          &nbsp;&nbsp;
          <span
            className="pt-link ratecards-all-items"
            key="clear-selections-link"
            onClick={store.clearAllSelections}
          >
            Clear all selections
          </span>
        </div>
      );
    }

    let tableLeftActionBar = null;
    if (store.isEditing) {
      if (!store.clientId) {
        tableLeftActionBar = <nav className="header-left-nav">{selectBtn}</nav>;
      }
    }
    if (!store.isEditing) {
      if (store.clientId) {
        tableLeftActionBar = (
          <nav className="header-left-nav">{selectAllOnPageSection}</nav>
        );
      }
    }

    const users = store.usersListView;
    let tableContent = null;
    if (network.loading) {
      tableContent = <LoadingIndicator />;
    } else if (network.error) {
      // TODO: replace this with a more friendly error message
      tableContent = (
        <div className="pt-error-message">
          <h2>Error</h2>
          <pre>
            <code>{JSON.stringify(network.error, null, 2)}</code>
          </pre>
        </div>
      );
    } else if (users.length === 0) {
      tableContent = (
        <div className="ratecards-table-empty">
          <div>
            No Users Found
            {/*  <p>
                Create a new Region using the button above or by searching.
              </p>*/}
          </div>
        </div>
      );
    } else {
      tableContent = users.map((user) => (
        <UserListItem key={user.userId} store={store} user={user} />
      ));
    }

    return (
      <div className="ratecards-list">
        {store.clientUserFilter && (
          <ClientUserFilter
            clientUserFilter={store.clientUserFilter}
            network={store.clientUserFilter.network.loading}
            instantSearchValue={store.clientUserFilter.instantSearchValue}
            onInstantSearch={store.clientUserFilter.onInstantSearch}
          />
        )}
        {store.activeFilter && (
          <FilterModal filterState={store.activeFilter}>
            <div>
              <ContainerSection className="header overlap-t-padding">
                <h4 className="pull-left">Status</h4>
                <div className="pull-right">
                  <SortControls filterState={store.activeFilter} />
                </div>
                <div className="clearfix" />
              </ContainerSection>
              <ContainerSection>
                <p>Filter by a specific Status State:</p>
                <ListContainer>
                  {store.activeFilter.viewItems.map((item) => (
                    <ToggleButton
                      key={item.id}
                      large={true}
                      item={item}
                      type="radio"
                      name="shared-state"
                      value={item.value}
                      selected={item.selected}
                      onChange={store.activeFilter.setSelectedValue}
                    >
                      {item.value}
                    </ToggleButton>
                  ))}
                </ListContainer>
              </ContainerSection>
            </div>
          </FilterModal>
        )}

        <header
          className={classNames("ratecards-table-header", {
            active: store.isFiltered,
          })}
        >
          {tableLeftActionBar}
          <nav className="header-right-nav atl-1">
            {store.isFiltered && (
              <div className="btn btn-yellow" onClick={store.clearFilters}>
                Clear All Filters & Sorts
              </div>
            )}
            {!store.clientId && store.clientUserFilter && (
              <ToggleButton
                borderless={true}
                name="search-list-filter"
                value={FILTER_COLUMN.CLIENT_USER_LIST}
                selected={Boolean(store.appliedFilters[FILTER_COLUMN.CLIENT_USER_LIST])}
                onClick={store.clientUserFilter.onShowModal}
              >
                <SortIndicator
                  sortColumn={FILTER_COLUMN.CLIENT_USER_LIST}
                  sortsOrder={store.appliedSortsOrder}
                  sort={store.appliedSorts[FILTER_COLUMN.CLIENT_USER_LIST]}
                />
                Client <span> ▼</span>
              </ToggleButton>
            )}
            {store.activeFilter && (
              <ToggleButton
                borderless={true}
                name="rate-cards-filter"
                value={FILTER_COLUMN.ACTIVE}
                selected={Boolean(store.appliedFilters[FILTER_COLUMN.ACTIVE])}
                onClick={store.activeFilter.onShowModal}
              >
                <SortIndicator
                  sortColumn={FILTER_COLUMN.ACTIVE}
                  sortsOrder={store.appliedSortsOrder}
                  sort={store.appliedSorts[FILTER_COLUMN.ACTIVE]}
                />
                Status
                <span> ▼</span>
              </ToggleButton>
            )}
          </nav>
        </header>
        <div
          className={classNames("ratecards-table", {
            "ratecards-table-fill-container": fillContainer,
          })}
        >
          <div style={listBodyStyles}>
            {selectAllSection}
            {tableContent}
          </div>
        </div>
        {store.pagination.pageCount > 0 ? (
          <Pagination
            pageCount={store.pagination.pageCount}
            pagesToShow={store.pagination.maxPagesToShow}
            currentPage={store.pagination.currentPage}
            begin={store.pagination.begin}
            end={store.pagination.end}
            isEndRange={store.pagination.isEndRange}
            handleCurrentPage={this.changeCurrent}
            firstPage={store.pagination.firstPage}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

UserList.propTypes = {
  store: PropTypes.object.isRequired,
  fixedHeight: PropTypes.number,
  fillContainer: PropTypes.bool,
};

export default observer(UserList);
