/**
 * @generated SignedSource<<ffd2d6cfd945eeceb5bd20f3f707282b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BellCurveBillRates_savedsearch$data = {
  readonly currency: {
    readonly id: string;
    readonly iso: string;
  } | null;
  readonly id: string;
  readonly rates: {
    readonly perDiem: number;
    readonly rawAvg: number;
    readonly rawBillRates: ReadonlyArray<number | null>;
    readonly rawMax: number;
    readonly rawMid: number;
    readonly rawMin: number;
  } | null;
  readonly " $fragmentType": "BellCurveBillRates_savedsearch";
};
export type BellCurveBillRates_savedsearch$key = {
  readonly " $data"?: BellCurveBillRates_savedsearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"BellCurveBillRates_savedsearch">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BellCurveBillRates_savedsearch",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CurrencyNode",
      "kind": "LinkedField",
      "name": "currency",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "iso",
            "storageKey": null
          },
          "action": "NONE",
          "path": "currency.iso"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RateSearchResponse",
      "kind": "LinkedField",
      "name": "rates",
      "plural": false,
      "selections": [
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rawBillRates",
            "storageKey": null
          },
          "action": "NONE",
          "path": "rates.rawBillRates"
        },
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "perDiem",
            "storageKey": null
          },
          "action": "NONE",
          "path": "rates.perDiem"
        },
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rawMin",
            "storageKey": null
          },
          "action": "NONE",
          "path": "rates.rawMin"
        },
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rawAvg",
            "storageKey": null
          },
          "action": "NONE",
          "path": "rates.rawAvg"
        },
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rawMid",
            "storageKey": null
          },
          "action": "NONE",
          "path": "rates.rawMid"
        },
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rawMax",
            "storageKey": null
          },
          "action": "NONE",
          "path": "rates.rawMax"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SaveSearchNode",
  "abstractKey": null
};
})();

(node as any).hash = "0eaa6203e93e663844f0f63f5c6f00bc";

export default node;
