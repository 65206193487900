import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react";

const Info = observer((props) => (
  <div className="media no-margin">
    <div className="media-left media-top">
      <p className="no-margin">
        <FontAwesomeIcon icon={props.icon} fixedWidth className={props.iconClass} />
      </p>
    </div>
    <div className="media-body">{props.children}</div>
  </div>
));

Info.propTypes = {
  icon: PropTypes.string,
  iconClass: PropTypes.string,
  text: PropTypes.string,
};

export default Info;
