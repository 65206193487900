import React, { useCallback, useEffect } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";

import { TickerPageLoader } from "../../../components/lib/TickerLoader";
import { emptyList, emptyMap, emptyOrderedMap } from "../../../constants";
import { reviewAttemptToImmutableMap } from "../dataConverters";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";
import AlertWithButton from "../../../components/AlertWithButton";
import { attemptGlobalState } from "../globalState";
import { ReviewDataContext, getReviewDataContextValues } from "./ReviewDataContext";

import type {
  AttemptDataMap,
  AttemptDataObject,
  REVIEW_STATUS_TYPES_TYPE,
  JobFeedbackDataMap,
  JOB_FEEDBACK_STATUS_TYPES_TYPE,
  ReviewDataMap,
  JobsDataOrderedMap,
  JobDataMap,
  QuestionsFeedbackOrderedMap,
  JobsFeedbackDataOrderedMap,
} from "../types";
import type { FetchAPIResponse } from "../../../types/fetch";
import type { CommonChildPageProps } from "../Val5KAdmin";
import type { AttemptGlobalState } from "../globalState";
import { useVal5KAdminContext } from "./Val5KAdminContext";

type RefreshAttemptItemFuncType = () => Promise<AttemptDataMap | undefined>;

export type AttemptDataContextObject = {
  attemptData: AttemptDataMap;
  //
  refreshAttemptItemData: RefreshAttemptItemFuncType;
  reviewStatus: REVIEW_STATUS_TYPES_TYPE;
  validatorEmail: string;
  validatorString: string;
  completedTimestamp: Date | null;
  isReadyForPayment: boolean;
  readyForPaymentTimestamp: Date | null;
  isPaid: boolean;
  paidTimestamp: Date | null;
  isAnalysisCompleted: boolean;
  analysisCompletedTimestamp: Date | null;
  isArchived: boolean;
  archivedTimestamp: Date | null;
  comment: string | null;
  isFreeValidation: boolean;
  totalReward: number;
  createdTimestamp: Date;
  updatedTimestamp: Date;
  //
  questionsFeedbackData: QuestionsFeedbackOrderedMap;
  //
  reviewData: ReviewDataMap;
  jobsData: JobsDataOrderedMap;
  firstJobData: JobDataMap;
  jobFeedbackData: JobFeedbackDataMap;
  jobFeedbackStatus: JOB_FEEDBACK_STATUS_TYPES_TYPE;
};

export const AttemptDataContext = React.createContext<AttemptDataContextObject>({
  attemptData: undefined as unknown as AttemptDataMap,
  refreshAttemptItemData: undefined as unknown as RefreshAttemptItemFuncType,
  reviewStatus: undefined as unknown as REVIEW_STATUS_TYPES_TYPE,
  validatorString: undefined as unknown as string,
  validatorEmail: undefined as unknown as string,
  completedTimestamp: undefined as unknown as Date,
  isReadyForPayment: undefined as unknown as boolean,
  readyForPaymentTimestamp: undefined as unknown as Date,
  isPaid: undefined as unknown as boolean,
  paidTimestamp: undefined as unknown as Date,
  isAnalysisCompleted: undefined as unknown as boolean,
  analysisCompletedTimestamp: undefined as unknown as Date,
  isArchived: undefined as unknown as boolean,
  archivedTimestamp: undefined as unknown as Date,
  comment: undefined as unknown as string,
  isFreeValidation: undefined as unknown as boolean,
  totalReward: undefined as unknown as number,
  createdTimestamp: undefined as unknown as Date,
  updatedTimestamp: undefined as unknown as Date,
  //
  questionsFeedbackData: undefined as unknown as QuestionsFeedbackOrderedMap,
  //
  reviewData: undefined as unknown as ReviewDataMap,
  jobsData: emptyList as unknown as JobsDataOrderedMap,
  firstJobData: undefined as unknown as JobDataMap,
  jobFeedbackData: undefined as unknown as JobFeedbackDataMap,
  jobFeedbackStatus: undefined as unknown as JOB_FEEDBACK_STATUS_TYPES_TYPE,
} as AttemptDataContextObject);

export function getAttemptDataContextValues(
  attemptData: AttemptDataMap,
  refreshAttemptItemData?: RefreshAttemptItemFuncType
): AttemptDataContextObject {
  const validatorString = `${attemptData.get("first_name")} ${attemptData.get(
    "last_name"
  )} (${attemptData.get("email")})`;
  const reviewData = attemptData.get("review") || emptyMap;
  const jobsData = reviewData.get("jobs") || emptyOrderedMap;
  const firstJobData = jobsData.first() || emptyMap;
  const jobFeedbackData = attemptData
    .get("jobs_feedback", emptyOrderedMap as JobsFeedbackDataOrderedMap)
    .get(firstJobData.get("id"), emptyMap as JobFeedbackDataMap);

  return {
    attemptData,
    refreshAttemptItemData:
      refreshAttemptItemData ||
      ((() => undefined) as unknown as RefreshAttemptItemFuncType),
    reviewStatus: attemptData.get("review_status"),
    validatorString: validatorString,
    validatorEmail: attemptData.get("email"),
    completedTimestamp: attemptData.get("completed_timestamp"),
    isReadyForPayment: attemptData.get("ready_for_payment", false),
    readyForPaymentTimestamp: attemptData.get("ready_for_payment_timestamp"),
    isPaid: attemptData.get("paid", false),
    paidTimestamp: attemptData.get("paid_timestamp"),
    isAnalysisCompleted: attemptData.get("analysis_completed", false),
    analysisCompletedTimestamp: attemptData.get("analysis_completed_timestamp"),
    isArchived: attemptData.get("archived", false),
    archivedTimestamp: attemptData.get("archived_timestamp"),
    comment: attemptData.get("comment"),
    isFreeValidation: attemptData.get("free_validation", false),
    totalReward: attemptData.get("total_reward"),
    createdTimestamp: attemptData.get("created"),
    updatedTimestamp: attemptData.get("updated"),
    //
    questionsFeedbackData: attemptData.get("questions_feedback") || emptyOrderedMap,
    //
    reviewData: reviewData,
    jobsData: jobsData,
    firstJobData: firstJobData,
    jobFeedbackData: jobFeedbackData,
    jobFeedbackStatus: jobFeedbackData.get("status"),
  };
}

type AttemptDataContextProviderProps = CommonChildPageProps & {
  params: {
    attemptId: string;
  };
  children: React.ReactElement;
};

const AttemptDataContextProvider = (props: AttemptDataContextProviderProps) => {
  const { params, router, fetchArgusAPI, showModalError, children } = props;

  const attemptId = params.attemptId;

  // global attempt state

  const [{ attemptData, attemptDataLoaded }, setAttemptGlobalState] =
    useRecoilState<AttemptGlobalState>(attemptGlobalState);
  const resetAttemptGlobalState = useResetRecoilState(attemptGlobalState);

  // data fetch

  const fetchAttemptData = useCallback(async () => {
    try {
      const response: FetchAPIResponse<AttemptDataObject> = await fetchArgusAPI(
        `attempts/${attemptId}/`
      );
      const data: AttemptDataMap = reviewAttemptToImmutableMap(response.data);

      setAttemptGlobalState({
        attemptData: data,
        attemptDataLoaded: true,
      });

      return data;
    } catch (err: any) {
      logAsyncOperationError("fetchAttemptItemData", err);
      showModalError(`Error occurred while loading attempt #${attemptId} data.`);
    }
  }, [attemptId, setAttemptGlobalState, fetchArgusAPI, showModalError]);

  // handlers

  const handleBackToList: React.ReactEventHandler<HTMLButtonElement> = useCallback(() => {
    router.push("/admin/val5000/validations");
  }, [router]);

  // effects

  useEffect(() => {
    fetchAttemptData();
    return resetAttemptGlobalState;
  }, [fetchAttemptData, resetAttemptGlobalState]);

  // context

  const globalContextValues = useVal5KAdminContext();

  const attemptContextValues = React.useMemo(
    () => getAttemptDataContextValues(attemptData, fetchAttemptData),
    [attemptData, fetchAttemptData]
  );

  const reviewContextValues = React.useMemo(
    () => getReviewDataContextValues(attemptContextValues.reviewData),
    [attemptContextValues.reviewData]
  );

  // render

  if (!attemptDataLoaded) {
    return <TickerPageLoader />;
  }

  if (!attemptData.get("review")?.size) {
    return (
      <AlertWithButton
        head="Requested survey data is not found."
        onButtonClick={handleBackToList}
      />
    );
  }

  if (!attemptData.get("review")?.get("jobs")?.first()?.size) {
    return (
      <AlertWithButton
        head="No job data found for the survey."
        onButtonClick={handleBackToList}
      />
    );
  }

  return (
    <AttemptDataContext.Provider value={attemptContextValues}>
      <ReviewDataContext.Provider value={reviewContextValues}>
        {React.cloneElement(React.Children.only(children), {
          ...globalContextValues,
          ...attemptContextValues,
        })}
      </ReviewDataContext.Provider>
    </AttemptDataContext.Provider>
  );
};

AttemptDataContextProvider.displayName = "AttemptContextProvider";

export const useAttemptDataContext = () => React.useContext(AttemptDataContext);

export default AttemptDataContextProvider;
