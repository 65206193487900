import * as React from "react";
import {
  useRefetchableFragment,
  useMutation,
  fetchQuery,
  useFragment,
  useLazyLoadQuery,
  useRelayEnvironment,
} from "react-relay";
// @ts-ignore
import graphql from "babel-plugin-relay/macro";

import Icon from "./lib/Icon";
import { styled } from "../stitches.config";
import { NavBarLink } from "./lib/Link";
import { APPS, rolePTAdmin } from "../constants/sharedConstants";
import Inline from "./lib/Inline";
import Container from "./lib/Container";
import {
  Popover,
  PopoverPrimitiveContent,
  PopoverTrigger,
  PopoverArrow,
} from "./lib/Popover";
import Stack from "./lib/Stack";
// @ts-ignore
import { NOTIFICATION_CONTENT_TYPE } from "../models/Filter";
import {
  NavBar2NotificationsDropdown_notifications$data,
  NavBar2NotificationsDropdown_notifications$key,
} from "./__generated__/NavBar2NotificationsDropdown_notifications.graphql";
import { NavBar2MarkAllAsReadMutation } from "./__generated__/NavBar2MarkAllAsReadMutation.graphql";
import { NavBar2MarkNotificationAsReadMutation } from "./__generated__/NavBar2MarkNotificationAsReadMutation.graphql";
import { InjectedRouter, Link, RouterState } from "react-router";
import TextInput from "./lib/TextInput";
import { NavBar2NotificationsRefetchQuery } from "./__generated__/NavBar2NotificationsRefetchQuery.graphql";
import useInterval from "../utils/useInterval";
import { NavBar2_user$key } from "./__generated__/NavBar2_user.graphql";
import { NavBar2Query } from "./__generated__/NavBar2Query.graphql";
import Spacer from "./lib/Spacer";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogDescription,
  DialogClose,
} from "./lib/Dialog";
import Logo, { PfizerLogo } from "./Logo";
import { supportEmail } from "../constants";

export const Nav = styled("nav", {
  height: "60px",
  backgroundColor: "$secondary",
  color: "$textLight",
  position: "sticky",
  top: 0,
  left: 0,
  right: 0,
  zIndex: "$20",
  overflow: "hidden",
  display: "flex",
  alignItems: "center",
});

export const NavLeft = styled(Inline, {
  alignItems: "stretch",
});

export const NavRight = styled(Inline, {
  gap: 0,
  alignItems: "stretch",
});

export const NavItems = styled("ul", {
  display: "flex",
  flexDirection: "row",
  gap: "$2",
  listStyleType: "none",
  margin: "0",
  padding: "0",
  overflow: "hidden",
});

export const NavItem = styled("li", {
  display: "flex",
  alignItems: "center",
  a: {
    display: "flex",
    alignItems: "center",
    color: "$textLight !important",
    padding: "$2 $3",
    gap: "$2",
    transition: "background-color 0.15s ease-in-out",
    borderRadius: "$rounded",
    svg: {
      color: "$textLight",
    },
    "&:hover": {
      backgroundColor: "$accentDarkest",
      svg: {
        color: "$accentLight",
      },
    },
  },
  variants: {
    active: {
      true: {
        a: {
          backgroundColor: "$accentDarkest",
          svg: {
            color: "$accentLight",
          },
        },
      },
    },
  },
});

const BurgerContainer = styled("div", {
  width: "50px",
  height: "50px",
  margin: "5px",
  overflow: "hidden",
  cursor: "pointer",
  display: "inline-block",
  transition: "background-color 0.15s ease-in-out",
  "&:hover": {
    backgroundColor: "$secondaryLight",
  },
});

const Burger = styled("div", {
  width: "100%",
  height: "100%",
  position: "relative",
});

const BurgerLine = styled("div", {
  width: "30px",
  height: "4px",
  backgroundColor: "$textLight",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  "&::before": {
    content: '""',
    position: "absolute",
    top: "-9px",
    left: "50%",
    transform: "translate(-50%, 0)",
    width: "100%",
    height: "100%",
    backgroundColor: "$textLight",
    transition: "all 300ms ease-in-out",
  },
  "&::after": {
    content: '""',
    position: "absolute",
    top: "9px",
    left: "50%",
    transform: "translate(-50%, 0)",
    width: "100%",
    height: "100%",
    backgroundColor: "$textLight",
    transition: "all 300ms ease-in-out",
  },
  variants: {
    open: {
      true: {
        backgroundColor: "transparent",
        "&::before": { top: "0", left: "0", transform: "rotate(45deg)" },
        "&::after": { top: "0", left: "0", transform: "rotate(-45deg)" },
      },
      false: {},
    },
  },
  defaultVariants: {
    open: false,
  },
});

const CloseDialog = styled(DialogClose, {
  position: "relative",
  top: "-13px",
  right: "2px",
  "&:hover": {
    cursor: "pointer",
  },
});

interface BurgerMenuProps {
  isOpen: boolean;
  onClick: () => void;
}

function BurgerMenu(props: BurgerMenuProps) {
  return (
    <BurgerContainer onClick={props.onClick} data-testid="home-menu-button">
      <Burger>
        <BurgerLine open={props.isOpen} />
      </Burger>
    </BurgerContainer>
  );
}

const ContactUsTrigger = styled("button", {
  all: "unset",
  padding: "$3",
  color: "$brandLight",
  textAlign: "right",
  "&:visited": {
    textDecoration: "none",
  },
  "&:focus": {
    textDecoration: "none",
  },
});

const RPAlert = styled(Inline, {
  padding: "$3",
  backgroundColor: "$brandLightest",
  borderRadius: "$sm",
  svg: {
    color: "$primary",
    fontSize: "20px",
  },
});

const RPMailToLink = styled("a", {
  color: "$brand !important",
  fontSize: "14px",
  fontWeight: "$semibold",
  "&:hover": {
    textDecoration: "underline !important",
  },
});

interface ContactUsDialogProps {
  open: boolean;
  onOpenChange?: (state: boolean) => void;
}

function ContactUsDialog(props: ContactUsDialogProps) {
  const { open, onOpenChange } = props;

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent
        onOpenAutoFocus={(e) => e.preventDefault()}
        onCloseAutoFocus={(e) => e.preventDefault()}
      >
        <DialogTitle asChild>
          <Inline
            css={{
              justifyContent: "space-between",
              paddingRight: "20px",
              borderBottom: "none",
            }}
          >
            <h4 data-testid="contact-us-header">Contact Us</h4>
            <CloseDialog asChild>
              <Icon icon="times" />
            </CloseDialog>
          </Inline>
        </DialogTitle>
        <DialogDescription css={{ backgroundColor: "white", paddingTop: 0 }} asChild>
          <div>
            <p>Pay Intel Team</p>
            <p>
              For questions or recommendations for enhancements related to job titles,
              locations, rates, or skills, please contact the Pay Intel team.
            </p>
            <RPAlert>
              <Icon icon="envelope" />
              <RPMailToLink href={`mailto:${supportEmail}`}>{supportEmail}</RPMailToLink>
            </RPAlert>
          </div>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
}

const ProfileDropdownContent = styled(PopoverPrimitiveContent, {
  display: "flex",
  flexDirection: "column",
  width: "124px",
  backgroundColor: "$secondaryLight",
  boxShadow:
    "0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px -2px rgb(0 0 0 / 12%)",
  a: {
    padding: "$3",
    textAlign: "right",
    "&:visited": {
      textDecoration: "none",
    },
    "&:focus": {
      textDecoration: "none",
    },
  },
});

const ProfileButton = styled("button", {
  all: "unset",
  display: "flex",
  gap: "10px",
  alignItems: "center",
  paddingLeft: "$3",
  paddingRight: "$3",
  cursor: "pointer",
  position: "relative",
  transition: "background-color 0.15s ease-in-out",
  "&:hover": {
    backgroundColor: "$secondaryLight",
  },
});

const ProfileIcon = styled(Icon, {
  color: "$textLight",
});

interface ProfileDropdownProps {
  username: string;
  logout: () => void;
}

function ProfileDropdown(props: ProfileDropdownProps) {
  const { logout } = props;
  const [profileOpenState, setProfileOpenState] = React.useState(false);
  const [contactUsOpenState, setContactUsOpenState] = React.useState(false);

  const changeProfileDropdownState = React.useCallback(
    (state: boolean) => {
      setProfileOpenState(state);
    },
    [setProfileOpenState]
  );
  const closeProfileDropdown = React.useCallback(() => {
    setProfileOpenState(false);
  }, [setProfileOpenState]);
  const logoutUser = React.useCallback<React.MouseEventHandler<Link>>(
    (event) => {
      event.preventDefault();
      closeProfileDropdown();
      logout();
    },
    [logout, closeProfileDropdown]
  );

  const handleContactUsStateChange = React.useCallback(
    (state: boolean) => {
      setContactUsOpenState(state);
    },
    [setContactUsOpenState]
  );
  const handleContactUsOpen = React.useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();
      closeProfileDropdown();
      setContactUsOpenState(true);
    },
    [setContactUsOpenState, closeProfileDropdown]
  );

  return (
    <>
      <Popover open={profileOpenState} onOpenChange={changeProfileDropdownState}>
        <PopoverTrigger asChild>
          <ProfileButton>
            <ProfileIcon icon="user" />
            <span data-testid="username">{props.username}</span>
          </ProfileButton>
        </PopoverTrigger>
        <ProfileDropdownContent>
          <NavBarLink
            to="/account"
            onClick={closeProfileDropdown}
            css={{ color: "$brandLight !important" }}
          >
            My Account
          </NavBarLink>
          <ContactUsTrigger
            onClick={handleContactUsOpen}
            css={{ cursor: "pointer" }}
            data-testid="contact-us-button"
          >
            Contact Us
          </ContactUsTrigger>
          <NavBarLink
            to="/logout"
            onClick={logoutUser}
            css={{ color: "$brandLight !important" }}
            data-testid="logout-button"
          >
            Logout
          </NavBarLink>
        </ProfileDropdownContent>
      </Popover>

      <ContactUsDialog
        open={contactUsOpenState}
        onOpenChange={handleContactUsStateChange}
      />
    </>
  );
}

const NotificationsContainer = styled("button", {
  all: "unset",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  position: "relative",
  outline: "none",
  userSelect: "none",
  height: "100%",
  minWidth: "60px",
});

const NotificationTrigger = styled("span", {
  position: "relative",
  fontSize: "16px",
  color: "$textLight",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  padding: "0 15px",
  height: "100%",
  transition: "background-color 0.15s ease-in-out",
  "&:hover": {
    backgroundColor: "$secondaryLight",
  },
});

const NotificationCounter = styled("span", {
  position: "absolute",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  top: "11px",
  left: "28px",
  minWidth: "18px",
  height: "auto",
  width: "auto",
  padding: "$1",
  fontSize: "0.625rem",
  fontWeight: "bold",
  lineHeight: "0.625rem",
  color: "$textLight",
  background: "$brand",
  border: "none",
  borderRadius: "$full",
});

const NotificationDropdownContent = styled(PopoverPrimitiveContent, {
  display: "flex",
  flexDirection: "column",
  paddingLeft: "$4",
  paddingRight: "$4",
  width: "600px",
  backgroundColor: "$secondaryLight",
  boxShadow:
    "0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px -2px rgb(0 0 0 / 12%)",
  a: {
    textAlign: "right",
    "&:visited": {
      textDecoration: "none",
    },
    "&:focus": {
      textDecoration: "none",
    },
  },
});

const Notification = styled(NavBarLink, {
  display: "flex",
  gap: "$3",
  backgroundColor: "$brandLightest",
  color: "$brandDark !important",
  padding: "$2",
  variants: {
    isRead: {
      true: {
        backgroundColor: "white",
        color: "$text !important",
      },
    },
  },
});

const NotificationDropdownArrow = styled(PopoverArrow, {
  fill: "$secondaryLight",
});

// NOTE: Considering removing this and replacing with fragment for subset
// https://github.com/relay-tools/relay-compiler-language-typescript/issues/64#issuecomment-511765083
export type NoRefs<T> = T extends Record<string, unknown>
  ? Omit<T, " $refType" | " $fragmentRefs">
  : T;

export type ExtractRelayEdgeNode<
  T extends { edges: ReadonlyArray<{ node: any | null } | null> | null } | null
> = NoRefs<NonNullable<NonNullable<NonNullable<NonNullable<T>["edges"]>[0]>["node"]>>;

interface NotificationsDropdownProps {
  viewer: NavBar2NotificationsDropdown_notifications$key;
  router: RouterState & InjectedRouter;
}

function NotificationsDropdown(props: NotificationsDropdownProps) {
  const relayEnvironment = useRelayEnvironment();
  const [data, refetch] = useRefetchableFragment<
    NavBar2NotificationsRefetchQuery,
    NavBar2NotificationsDropdown_notifications$key
  >(
    graphql`
      fragment NavBar2NotificationsDropdown_notifications on Viewer
      @refetchable(queryName: "NavBar2NotificationsRefetchQuery") {
        notifications(first: 10) {
          unreadNotificationCount
          edges {
            node {
              id
              created
              isRead
              message
              contentObject {
                notificationType
                contentId
              }
            }
          }
        }
      }
    `,
    props.viewer
  );

  const refresh = React.useCallback(() => {
    // fetchQuery will fetch the query and write
    // the data to the Relay store. This will ensure
    // that when we re-render, the data is already
    // cached and we don't suspend
    fetchQuery(
      relayEnvironment,
      graphql`
        query NavBar2NotificationsQuery {
          viewer {
            ...NavBar2NotificationsDropdown_notifications
          }
        }
      `,
      {}
    ).subscribe({
      complete: () => {
        // *After* the query has been fetched, we call
        // refetch again to re-render with the updated data.
        // At this point the data for the query should
        // be cached, so we use the 'store-only'
        // fetchPolicy to avoid suspending.
        refetch({}, { fetchPolicy: "store-only" });
      },
      error: () => {},
    });
  }, [refetch, relayEnvironment]);

  const [
    markMarkNotificationAsReadMutationCommit,
    _markNotificationAsReadMutationIsInFlight,
  ] = useMutation<NavBar2MarkNotificationAsReadMutation>(
    graphql`
      mutation NavBar2MarkNotificationAsReadMutation(
        $input: MarkNotificationAsReadInput!
      ) {
        markNotificationAsRead(input: $input) {
          ok
          notification {
            id
            isRead
          }
        }
      }
    `
  );
  const [markAllAsReadMutationCommit, _markAllAsReadMutationIsInFlight] =
    useMutation<NavBar2MarkAllAsReadMutation>(
      graphql`
        mutation NavBar2MarkAllAsReadMutation {
          markAllNotificationAsRead(input: {}) {
            ok
          }
        }
      `
    );

  function handleMarkAllAsRead() {
    if (_markAllAsReadMutationIsInFlight) {
      return;
    }

    markAllAsReadMutationCommit({
      variables: {},
      onError(error) {
        console.error(error.toString());
      },
    });
  }

  function handleMarkNotificationAsRead(notificationId: string) {
    if (_markNotificationAsReadMutationIsInFlight) {
      return;
    }

    markMarkNotificationAsReadMutationCommit({
      variables: {
        input: {
          notificationId,
        },
      },
      onError(error) {
        console.error(error.toString());
      },
      optimisticResponse: {
        notification: {
          id: notificationId,
          isRead: true,
        },
      },
    });
  }

  function handleNotificationClick(
    notification: ExtractRelayEdgeNode<
      NavBar2NotificationsDropdown_notifications$data["notifications"]
    >
  ) {
    handleMarkNotificationAsRead(notification.id);
    // NOTE: Handle linking to content for specific notifications
    if (notification.contentObject && notification.contentObject.notificationType) {
      if (notification.contentObject.notificationType === "EXPORT_COMPLETE") {
        window.location.href = `/${
          NOTIFICATION_CONTENT_TYPE[notification.contentObject.notificationType]
        }/${notification.contentObject.contentId}`;
      } else {
        props.router.push({
          pathname: `/${
            NOTIFICATION_CONTENT_TYPE[
              notification.contentObject
                .notificationType as keyof typeof NOTIFICATION_CONTENT_TYPE
            ]
          }/${notification.contentObject.contentId}`,
          query: props.router.location.query,
        });
      }
    }
  }

  // NOTE: Refresh our notifications component every few seconds
  useInterval(() => {
    refresh();
  }, 120000);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <NotificationsContainer>
          <NotificationTrigger>
            <Icon icon="bell" />
            {data.notifications?.unreadNotificationCount !== 0 && (
              <NotificationCounter>
                {data.notifications?.unreadNotificationCount ?? 0}
              </NotificationCounter>
            )}
          </NotificationTrigger>
        </NotificationsContainer>
      </PopoverTrigger>
      <NotificationDropdownContent sideOffset={-10}>
        <NotificationDropdownArrow offset={20} width={20} height={10} />
        <NavBarLink
          to="/notifications"
          css={{ alignSelf: "flex-end", padding: "$2", color: "$brandLight !important" }}
          onClick={handleMarkAllAsRead}
        >
          Mark all as read
        </NavBarLink>
        <Stack css={{ gap: 0, alignItems: "stretch" }}>
          {data.notifications?.edges &&
            data.notifications?.edges?.length > 0 &&
            data.notifications?.edges.map((e) => {
              if (e?.node?.isRead) {
                return (
                  <Notification
                    key={e?.node?.id}
                    isRead={e?.node?.isRead}
                    to="/notifications"
                    // @ts-ignore
                    onClick={(event) => {
                      event.preventDefault();
                      if (e?.node?.id) {
                        handleNotificationClick(e.node);
                      }
                    }}
                  >
                    <span>{e?.node?.message}</span>
                  </Notification>
                );
              }

              return (
                <Notification
                  key={e?.node?.id}
                  to="/notifications"
                  // @ts-ignore
                  onClick={(event) => {
                    event.preventDefault();
                    if (e?.node?.id) {
                      handleNotificationClick(e.node);
                    }
                  }}
                >
                  <Icon icon="bell" />
                  <span>{e?.node?.message}</span>
                </Notification>
              );
            })}
          {data.notifications?.edges.length === 0 && (
            <Notification to="/notifications">
              <span>No notifications found</span>
            </Notification>
          )}
        </Stack>
        <NavBarLink
          to="/notifications"
          css={{ alignSelf: "center", padding: "$1", color: "$brandLight !important" }}
        >
          See More
        </NavBarLink>
      </NotificationDropdownContent>
    </Popover>
  );
}

const NavDrawer = styled("div", {
  backgroundColor: "rgba(87,86,86,.6);",
  position: "fixed",
  top: 60,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: "$20",
  overflowX: "hidden",
  overflowY: "auto",
  visibility: "hidden",
  paddingTop: "20px",
  paddingBottom: "25px",
  variants: {
    open: {
      true: {
        visibility: "visible",
      },
    },
  },
});

const NavDrawerItem = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "stretch",
  alignItems: "stretch",
  textAlign: "center",
  flexWrap: "wrap",
  borderRadius: "10px",
  backgroundColor: "$background",
  padding: "12px",
  width: "142px",
  height: "142px",
  boxShadow:
    "rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,rgba(0, 0, 0, 0.12) 0px 1px 5px 0px",
  svg: {
    fontSize: "32px",
    lineHeight: "32px",
    width: "auto",
    color: "$brandLight",
    margin: "12px",
  },
  a: {
    display: "flex",
    color: "$text !important",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
});

const SeperatorLabel = styled("h4", {
  display: "inline-block",
  textShadow: "0 2px 2px #00005c",
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "22px",
});

const SeperatorLine = styled("div", {
  display: "inline-block",
  flex: "1 1 auto",
  height: "1px",
  backgroundColor: "rgba(205, 205, 205, 0.8)",
  boxShadow:
    "rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px",
});

interface NavBarProps {
  viewer: NavBar2NotificationsDropdown_notifications$key;
  user: NavBar2_user$key;
  router: RouterState & InjectedRouter;
  logout: () => void;
}

export function NavBar(props: NavBarProps) {
  const inputRef = React.createRef<HTMLInputElement>();
  const [isOpen, setIsOpen] = React.useState(false);
  const [filterText, setFilterText] = React.useState("");
  const user = useFragment(
    graphql`
      fragment NavBar2_user on User {
        username
        roles
        client {
          title
          legacyClient {
            perSearchPricing
            isClientJobLibrary
            jobLibrarySubscriptionFlag
            pspStoreFlag
          }
        }
      }
    `,
    props.user
  );

  React.useEffect(() => {
    if (isOpen) inputRef.current?.focus();
  }, [isOpen, inputRef]);

  let navBarItems = [
    APPS["RATE_SEARCH"],
    APPS["BATCH_SEARCH"],
    APPS["RATE_CARD"],
    APPS["SAVED_SEARCHES"],
  ];

  if (user.client?.legacyClient?.isClientJobLibrary) {
    navBarItems.push(APPS["JOB_LIBRARY"]);
  }

  let navDrawerItems = Object.values(APPS).filter((i) => {
    let isAdminMenuItem = i.requiredRoles.includes(rolePTAdmin);
    let userHasRequiredRoles = i.requiredRoles.every((role) =>
      user.roles?.includes(role)
    );
    let clientHasRequiredFlags = i.requiredClientFlags.every(
      // @ts-ignore
      (flag) => user.client?.legacyClient[flag.flag] === flag.value
    );

    // console.log("menuItem:", i.key, "userroles:", user.roles, "flags", user.client.legacyClient);
    // console.log("isAdminMenuItem", isAdminMenuItem);
    // console.log("userHasRequiredRoles", userHasRequiredRoles);
    // console.log("clientHasRequiredFlags", clientHasRequiredFlags);

    return !isAdminMenuItem && userHasRequiredRoles && clientHasRequiredFlags;
  });

  let isAdmin = user.roles?.includes(rolePTAdmin);
  let navDrawerAdminItems = Object.values(APPS).filter(
    (i) => isAdmin && i.requiredRoles.includes(rolePTAdmin)
  );

  if (filterText !== "") {
    navDrawerItems = navDrawerItems.filter((i) =>
      i.label.toLowerCase().includes(filterText)
    );
    navDrawerAdminItems = navDrawerAdminItems.filter((i) =>
      i.label.toLowerCase().includes(filterText)
    );
  }

  function closeDrawer() {
    setIsOpen(false);
  }

  return (
    <Nav>
      <Inline
        css={{
          maxWidth: "$screenMax",
          margin: "0 auto",
          justifyContent: "space-between",
          alignItems: "stretch",
          flexGrow: "1", // width: 100% works too
          height: "100%",
        }}
      >
        <NavLeft>
          <Inline css={{ gap: 0, alignItems: "stretch" }}>
            <BurgerMenu
              isOpen={isOpen}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            />
            <NavBarLink to="/" style={{ alignSelf: "center" }}>
              {user.client?.title.toLowerCase().includes("pfizer") ? (
                <PfizerLogo />
              ) : (
                <Logo title="logo" width="140" height="45" />
              )}
            </NavBarLink>
          </Inline>
          <NavItems>
            {navBarItems.map((item) => {
              return (
                <NavItem
                  key={item.key}
                  active={window.location.pathname.startsWith(item.to)}
                >
                  <NavBarLink to={item.to}>
                    <Icon icon={item.icon} />
                    {item.label}
                  </NavBarLink>
                </NavItem>
              );
            })}
          </NavItems>
        </NavLeft>
        <Spacer />
        <NavRight>
          <NotificationsDropdown viewer={props.viewer} router={props.router} />
          <ProfileDropdown username={user.username} logout={props.logout} />
        </NavRight>
      </Inline>
      <NavDrawer open={isOpen}>
        <Container css={{ paddingLeft: "$4", paddingRight: "$4" }}>
          <TextInput
            ref={inputRef}
            placeholder="Filter menu items"
            fill
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setFilterText(e.currentTarget.value.toLowerCase());
            }}
            css={{
              backgroundColor: "hsla(0,0%,40.8%,.92)",
              marginBottom: "15px",
              input: {
                color: "$textLight",
              },
              "input::placeholder": {
                color: "$textLight",
              },
            }}
          />
          <Inline>
            {navDrawerItems.map((item) => {
              // NOTE: Handle external links without RR link component, to fix regression
              if (item.key === "CONTACT_US") {
                return (
                  <NavDrawerItem key={item.key} onClick={closeDrawer}>
                    <a href={item.to}>
                      <Icon icon={item.icon} />
                      {item.label}
                    </a>
                  </NavDrawerItem>
                );
              }

              return (
                <NavDrawerItem key={item.key} onClick={closeDrawer}>
                  <NavBarLink to={item.to} data-testid="menu-item">
                    <Icon icon={item.icon} />
                    {item.label}
                  </NavBarLink>
                </NavDrawerItem>
              );
            })}
          </Inline>
          {isAdmin && navDrawerAdminItems.length > 0 && (
            <Inline>
              <SeperatorLabel>Admin</SeperatorLabel>
              <SeperatorLine />
            </Inline>
          )}
          {isAdmin && navDrawerAdminItems.length > 0 && (
            <Inline>
              {navDrawerAdminItems.map((item) => {
                return (
                  <NavDrawerItem key={item.key} onClick={closeDrawer}>
                    <NavBarLink to={item.to} data-testid="admin-menu-item">
                      <Icon icon={item.icon} />
                      {item.label}
                    </NavBarLink>
                  </NavDrawerItem>
                );
              })}
            </Inline>
          )}
        </Container>
      </NavDrawer>
    </Nav>
  );
}

interface NavBarQueryLoaderProps {
  router: RouterState & InjectedRouter;
  logout: () => void;
}

export function NavBarQueryLoader(props: NavBarQueryLoaderProps) {
  const data = useLazyLoadQuery<NavBar2Query>(
    graphql`
      query NavBar2Query {
        viewer {
          user {
            ...NavBar2_user
          }
          ...NavBar2NotificationsDropdown_notifications
        }
      }
    `,
    {}
  );

  if (data.viewer == null) {
    return null;
  }

  return (
    <NavBar
      user={data.viewer.user}
      viewer={data.viewer}
      router={props.router}
      logout={props.logout}
    />
  );
}
