import React, { useCallback, useRef } from "react";

import Text from "../../components/lib/Text";
import Alert from "../../components/lib/Alert";
import Stack from "../../components/lib/Stack";

import { emptyMap } from "../../constants";
// @ts-expect-error
import { logAsyncOperationError } from "../../utils/logging";

import { TickerPageLoader } from "../../components/lib/TickerLoader";
import { NavigationButton } from "../../components/lib/Button";

import { usePLIContext } from "./context";
import { useResetStoredIndexGlobalState, useStoredIndexGlobalState } from "./globalState";
import { storedIndexToImmutableMap } from "./dataConverters";
import { useProgramContext } from "./ProgramDataProvider";

import type { CommonProgramChildPageProps } from "./ProgramDataProvider";
import type {
  ContractorsTableConfigObject,
  StoredIndexDataMap,
  StoredIndexDataObject,
} from "./types";
import type { FetchAPIResponse } from "../../types/fetch";
import type { CommonChildPageProps } from "./PrivateIndex";

export type StoredIndexContextObject = {
  indexData: StoredIndexDataMap;
  indexTableConfig: ContractorsTableConfigObject;
  indexId: number;
  indexTitle: string;
  indexDisplayCurrencyCode: string;
  indexUserId: number;
  indexIsPublic: boolean;
  indexIsOwned: boolean;
  indexIsEditingAllowed: boolean;
};

export const StoredIndexContext = React.createContext<StoredIndexContextObject>({
  indexData: undefined as unknown as StoredIndexDataMap,
  indexTableConfig: undefined as unknown as ContractorsTableConfigObject,
  indexId: undefined as unknown as number,
  indexTitle: undefined as unknown as string,
  indexDisplayCurrencyCode: undefined as unknown as string,
  indexUserId: undefined as unknown as number,
  indexIsPublic: undefined as unknown as boolean,
  indexIsOwned: undefined as unknown as boolean,
  indexIsEditingAllowed: undefined as unknown as boolean,
});

export function useStoredIndexContext() {
  return React.useContext(StoredIndexContext);
}

interface StoredIndexDataProviderProps extends CommonChildPageProps {
  programId: string;
  children: React.ReactElement;
}

const StoredIndexDataProvider = (props: StoredIndexDataProviderProps) => {
  const { params, router, children } = props;
  const { fetchM8API, showModalError } = usePLIContext();
  const { programId } = useProgramContext();

  // stored index state
  const [{ indexData, indexTableConfig, indexDataLoaded }, setStoredIndexGlobalState] =
    useStoredIndexGlobalState();
  const resetStoredIndexGlobalState = useResetStoredIndexGlobalState();

  const indexId = parseInt(params.indexId, 10);

  // data fetch func
  const fetchStoredIndexData = useCallback(async () => {
    try {
      const response: FetchAPIResponse<StoredIndexDataObject> = await fetchM8API(
        `programs/${programId}/stored_indexes/${indexId}/`
      );
      const data: StoredIndexDataMap = storedIndexToImmutableMap(response.data);

      setStoredIndexGlobalState((prevState) => ({
        ...prevState,
        indexData: data,
        indexDataLoaded: true,
      }));

      return data;
    } catch (err: any) {
      if (err?.response?.status === 404) {
        setStoredIndexGlobalState((prevState) => ({
          ...prevState,
          indexData: emptyMap as StoredIndexDataMap,
          indexDataLoaded: true,
        }));
      } else {
        logAsyncOperationError("fetchStoredIndexData", err);
        showModalError(`Error occurred while loading view #${indexId} data.`);
      }
    }
  }, [programId, indexId, setStoredIndexGlobalState, fetchM8API, showModalError]);

  // initial data load

  const isLoadedRef = useRef<boolean>(false);

  React.useEffect(() => {
    if (!isLoadedRef.current) {
      isLoadedRef.current = true;
      fetchStoredIndexData();
    }
  }, [fetchStoredIndexData]);

  // final cleanup

  React.useEffect(() => {
    return () => resetStoredIndexGlobalState();
  }, [resetStoredIndexGlobalState]);

  // context
  const pliContextValues = usePLIContext();
  const programContextValues = useProgramContext();

  // handlers
  const handleGoBackToProgramDetailsPage = useCallback(() => {
    router.push(`/private-index/programs/${programId}`);
  }, [router, programId]);

  if (!indexDataLoaded) {
    return <TickerPageLoader />;
  }
  if (!indexData || !indexData.size || indexData.get("id") !== indexId) {
    return (
      <Alert color="warning">
        <Stack css={{ alignItems: "start" }}>
          <Text as="h4">No view data found</Text>
          <NavigationButton
            icon="arrow-left"
            color="warning"
            onClick={handleGoBackToProgramDetailsPage}
          >
            Back to Views List
          </NavigationButton>
        </Stack>
      </Alert>
    );
  }

  const {
    userId: sessionUserId,
    isRegularUser,
    isClientAdmin,
    isPTAdmin,
  } = pliContextValues;
  const indexTitle = indexData.get("title");
  const indexDisplayCurrencyCode = indexData.get("display_currency_code", "USD");
  const indexUserId = indexData.get("user_id");
  const indexIsPublic = indexUserId == null;
  const indexIsOwned =
    indexUserId != null && sessionUserId != null && sessionUserId === indexUserId;
  const indexIsEditingAllowed =
    (isRegularUser && indexIsOwned) || isPTAdmin || isClientAdmin;
  const storedIndexContextValues = {
    indexData,
    indexTableConfig,
    indexId,
    indexTitle,
    indexDisplayCurrencyCode,
    indexUserId,
    indexIsPublic,
    indexIsOwned,
    indexIsEditingAllowed,
  };

  return (
    <StoredIndexContext.Provider value={storedIndexContextValues}>
      {React.cloneElement(React.Children.only(children), {
        ...pliContextValues,
        ...programContextValues,
        ...storedIndexContextValues,
      })}
    </StoredIndexContext.Provider>
  );
};

StoredIndexDataProvider.displayName = "StoredIndexDataProvider";

export interface CommonStoredIndexChildPageProps
  extends Omit<CommonProgramChildPageProps, "params"> {
  indexId: number;
  indexTitle: string;
  indexData: StoredIndexDataMap;
  indexTableConfig: ContractorsTableConfigObject;
  indexDisplayCurrencyCode: string;
  indexUserId: number;
  indexIsPublic: boolean;
  indexIsOwned: boolean;
  indexIsEditingAllowed: boolean;
  //
  params: CommonProgramChildPageProps["params"] & { indexId: string };
}

export default StoredIndexDataProvider;
