// @flow
import { extendObservable, action } from "mobx";
import FilterObject, {
  FilterWithSort,
  ValueSetFilter,
  FilterCriteriaLoader,
  IViewItem,
} from "../Filter";
import type { FilterColumn } from "../Filter";
import { GraphQLQuery } from "../GraphQL";
import Sort from "../Sort";
import SortState from "../SortState";
import { filter } from "fuzzaldrin-plus";

class OwnerItem implements IViewItem {
  id: string;
  userId: string;
  username: string;
  firstName: string;
  lastName: string;
  selected: boolean;

  constructor(object: Object) {
    this.id = object.node.userId;
    this.userId = object.node.userId;
    this.username = object.node.username;
    this.firstName = object.node.firstName;
    this.lastName = object.node.lastName;
    this.searchProp = `${this.firstName} ${this.lastName} ${this.username}`;

    extendObservable(this, {
      selected: object.node.selected,
    });
  }
}

export default class CreatedByFilter extends FilterCriteriaLoader(
  ValueSetFilter(FilterWithSort)
) {
  constructor(
    store: Object,
    column: FilterColumn,
    filterCriteriaQuery: (FilterColumn) => GraphQLQuery,
    processPayload: (FilterColumn, Object) => Array<Object>,
    applyFilter: (FilterColumn, FilterObject) => void,
    applySort: (FilterColumn, Sort) => void,
    removeFilter: (FilterColumn) => void,
    removeSort: (FilterColumn) => void,
    reloadCriteria: boolean = false
  ) {
    super(store, column);

    this.filterCriteriaQuery = filterCriteriaQuery;
    this.processPayload = processPayload;
    this.applyFilter = applyFilter;
    this.applySort = applySort;
    this.removeFilter = removeFilter;
    this.removeSort = removeSort;

    this.sortState = new SortState("OWNER_FIRST_NAME");

    this.reloadCriteria = reloadCriteria;

    this.viewItemClass = OwnerItem;

    // $FlowFixMe: Need to type these properties as invariant
    this.buildQueryFilter = action(this.buildQueryFilter.bind(this));
    // $FlowFixMe: Need to type these properties as invariant
    this.onInstantSearch = action(this.onInstantSearch.bind(this));
  }

  onInstantSearch(value: string) {
    if (super.onInstantSearch) super.onInstantSearch(value);

    if (!this.instantSearchValue) {
      this.viewItems = this.unfilteredViewItems;
      return;
    }

    this.viewItems = filter(this.unfilteredViewItems, value, {
      key: "searchProp",
      maxResults: 20,
    });

    // this.viewItems = this.unfilteredViewItems.filter(owner => {
    //   const firstName = owner.firstName.toLowerCase();
    //   const lastName = owner.lastName.toLowerCase();
    //   const username = owner.username.toLowerCase();
    //   const query = this.instantSearchValue.toLowerCase();
    //
    //   // this works because ~ is the binary inverse of a number and ~ -1 = 0
    //   return ~firstName.indexOf(query) || ~lastName.indexOf(query) || ~username.indexOf(query);
    // });
  }

  buildQueryFilter() {
    const owners = this.selectedValues
      .entries()
      .filter((entry) => entry[1] === true)
      .map((entry) => this.criteria.get(entry[0]).id);

    if (!owners.length) {
      return null;
    }

    let params = [];
    let args = [];
    let vars = {};

    if (owners.length) {
      params.push("$ownerId: [String]");
      args.push("ownerId: $ownerId");
      vars.ownerId = owners.map((userId) => {
        return userId;
      });
    }

    return new FilterObject(params.join(", "), args.join(", "), vars);
  }
}
