// @flow strict

import * as React from "react";
import TickerLoader from "../../components/lib/TickerLoader";

type Props = {
  showRetry?: boolean,
  loadingMessage?: React.Node,
  style?: {
    minHeight: number,
    height: number | string,
    display: "flex",
    justifyContent: "center",
    width: number | string,
  },
  innerStyle?: {
    display: "flex",
    flexDirection: "column" | "row",
    justifyContent: "center",
    alignItems: "center",
  },
  onRetry?: () => void,
};

const LoadingIndicator = (props: Props) => {
  const style = Object.assign(
    {},
    {
      minHeight: 300,
      height: "100%",
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
    props.style
  );

  const innerStyle = Object.assign(
    {},
    {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    props.innerStyle
  );

  return (
    <div style={style}>
      <div style={innerStyle}>
        <TickerLoader />
        {props.loadingMessage}
        {props.showRetry && <button onClick={props.onRetry}>Retry</button>}
      </div>
    </div>
  );
};

export default LoadingIndicator;
