import React, { ReactNode } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "../../../../components/lib/Dialog";
import Inline from "../../../../components/lib/Inline";
import { styled } from "../../../../stitches.config";
import Stack from "../../../../components/lib/Stack";
import Text from "../../../../components/lib/Text";
import { JobLibraryField } from "./JobLibraryField";
import { CountryLibraryField } from "./CountryLibraryField";
import Button from "../../../../components/lib/Button";
import { useRateSearchFormStore } from "../../../../stores/RateSearchForm";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../../components/lib/Tabs";

const RpDialogContent = styled(DialogContent, {
  padding: "$4",
  minHeight: "450px",
  width: "520px !important",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

interface Props {
  children: ReactNode;
  onResetJobOptions(): void;
}
const LibraryTypes = ["CLIENT", "COUNTRY"] as const;
type LibraryType = (typeof LibraryTypes)[number];

export function LibraryDialog(props: Props) {
  const { children, onResetJobOptions } = props;

  const setLibrary = useRateSearchFormStore((s) => s.setLibrary);

  function onReset() {
    onResetJobOptions();
    setLibrary(null, null);
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <span>{children}</span>
      </DialogTrigger>
      <RpDialogContent>
        <Stack fill css={{ alignItems: "flex-start", gap: "$4" }}>
          <Stack nogap css={{ alignItems: "flex-start" }}>
            <Text noMargin as="h3" color="dark">
              Job Library
            </Text>
            <Text color="primary">Select a library type:</Text>
          </Stack>

          <Tabs defaultValue={LibraryTypes[0]} css={{ width: "100%" }}>
            <TabsList>
              {LibraryTypes.map((type) => {
                const libraryTabName: Record<LibraryType, string> = {
                  CLIENT: "Main/Custom Library",
                  COUNTRY: "Country Library",
                };
                return (
                  <TabsTrigger
                    key={`tabs-${type}`}
                    value={type}
                    css={{ cursor: "pointer" }}
                  >
                    {libraryTabName[type]}
                  </TabsTrigger>
                );
              })}
            </TabsList>

            {LibraryTypes.map((type) => {
              const Content: Record<LibraryType, ReactNode> = {
                CLIENT: <JobLibraryField onResetJobOptions={onResetJobOptions} />,
                COUNTRY: <CountryLibraryField onResetJobOptions={onResetJobOptions} />,
              };
              return (
                <TabsContent key={`content-${type}`} value={type}>
                  {Content[type]}
                </TabsContent>
              );
            })}
          </Tabs>
        </Stack>

        <Inline css={{ alignSelf: "flex-end" }}>
          <DialogClose asChild>
            <Button onClick={onReset} variant="outlined" color="brand" size="small">
              Reset
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button variant="outlined" color="brand" size="small">
              Close
            </Button>
          </DialogClose>
        </Inline>
      </RpDialogContent>
    </Dialog>
  );
}
