import * as React from "react";
import { keyframes, styled } from "../../stitches.config";
import Box from "./Box";

const slideInBottom = keyframes({
  "0%": {
    transform: "translateY(100%)",
  },
  "100%": {
    transform: "translateY(0)",
  },
});

const slideOutBottom = keyframes({
  "0%": {
    transform: "translateY(0)",
  },
  "100%": {
    transform: "translateY(100%)",
  },
});

const fadeIn = keyframes({
  "0%": {
    visibility: "hidden",
    opacity: 0,
  },
  "100%": {
    visibility: "visible",
    opacity: 1,
  },
});

const fadeOut = keyframes({
  "0%": {
    visibility: "visible",
    opacity: 1,
  },
  "100%": {
    visibility: "hidden",
    opacity: 0,
  },
});

const EditActionsBar = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
  gap: "$2",
  backgroundColor: "$secondary",
  boxShadow: "$md",
  borderRadius: "4px",
  position: "fixed",
  bottom: "40px",
  right: "40px",
  left: "40px",
  height: "initial",
  padding: "20px",
  zIndex: "3",
  variants: {
    show: {
      true: {
        visibility: "visible",
        opacity: "1",
        transform: "translateY(0)",
        animation: `200ms ease-out 0s 1 ${fadeIn}, 200ms ease-out 0s 1 ${slideInBottom}`,
      },
      false: {
        visibility: "hidden",
        opacity: "0",
        transform: "translateY(100%)",
        animation: `200ms ease-out 0s 1 ${fadeOut}, 200ms ease-out 0s 1 ${slideOutBottom}`,
      },
    },
    hidden: {
      true: {
        display: "none",
      },
    },
  },
});

const EditActionsBackdrop = styled("div", {
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  position: "fixed",
  zIndex: 1,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  transition: "all 0.2s",
  variants: {
    show: {
      true: {
        visibility: "visible",
        opacity: "1",
        animation: `200ms ease-out 0s 1 ${fadeIn}`,
      },
      false: {
        visibility: "hidden",
        opacity: "0",
        animation: `200ms ease-out 0s 1 ${fadeOut}`,
      },
    },
    hidden: {
      true: {
        display: "none",
      },
    },
  },
});

interface EditActionsProps {
  show?: boolean | null;
  hidden?: boolean;
}

const EditActions = (props: React.PropsWithChildren<EditActionsProps>) => {
  const { show, hidden, children } = props;
  return (
    <Box css={{ height: 0, position: "relative" }}>
      <EditActionsBar show={Boolean(show)} hidden={hidden}>
        {children}
      </EditActionsBar>
      <EditActionsBackdrop show={Boolean(show)} hidden={hidden} />
    </Box>
  );
};

export default EditActions;
