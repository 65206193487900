// @flow
import { extendObservable } from "mobx";
import { CURRENCY_TYPE, FILE_TYPE } from "./ExportOptions";

export const FILE_TEMPLATE = {
  ALL_FIELDS: "AllFields",
  BILL_RATE_RANGE: "BillRateRange",
  NTE_BILL_RATE: "NotToExceedBillRate",
  THREE_LEVELS: "ThreeLevels",
};

export const RATE_TYPE_FREQUENCY = {
  HOURLY: "Hourly",
  DAILY: "Daily",
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
};

export default class CustomBuyRatesExportOptions {
  fileName: string;
  fileType: string;
  fileTemplate: string;
  rateFrequencyType: string;
  rateMultiplier: number;
  markupPercent: number;
  markupSource: string;
  useMarkupsFromRateCard: boolean;
  currencyType: string;

  constructor(
    fileName: string = "default_filename",
    fileTemplate: string = FILE_TEMPLATE.ALL_FIELDS,
    rateFrequencyType: string = RATE_TYPE_FREQUENCY.HOURLY,
    currencyType: string = CURRENCY_TYPE.DEFAULT,
    rateMultiplier: number = 1
  ) {
    extendObservable(this, {
      fileName: fileName,
      fileType: FILE_TYPE.CSV,
      fileTemplate: fileTemplate,
      rateFrequencyType: rateFrequencyType,
      rateMultiplier: rateMultiplier,
      markupPercent: 0,
      markupSource: "",
      useMarkupsFromRateCard: false,
      currencyType,
    });
  }
}
