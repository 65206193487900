// @flow
import * as React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import ClientSelect from "../../../components/selects/ClientSelect";
import MultiCountrySelect from "../../../components/selects/MultiCountrySelect";
import MultiRoleSelect from "../../../components/selects/MultiRoleSelect";
import CheckBox from "../../shared/CheckBox";
import ContainerSection from "../../ratecards/components/ContainerSection";
import type MobXStore from "../../../stores/mobx/MobXStore";
import SingleSelect from "../../../components/selects/SingleSelect";

/**
 * Remove duplicates from array of objects.
 * @param {objectsArray} array.
 * @returns {objectsArray} The object array removing duplicates.
 */
function remove_duplicates(objectsArray) {
  var usedObjects = {};

  for (var i = objectsArray.length - 1; i >= 0; i--) {
    var so = JSON.stringify(objectsArray[i]);

    if (usedObjects[so]) {
      objectsArray.splice(i, 1);
    } else {
      usedObjects[so] = true;
    }
  }
  return objectsArray;
}

type Props = {
  store: MobXStore,
};

class Create extends React.Component<Props> {
  static contextTypes = {
    router: PropTypes.object,
  };

  componentDidMount() {
    let store = this.props.store.userCreatePTStore;
    store.selectedLocations = {
      locations: [],
      regions: [],
    };
    store.router = this.context.router;
    store.clearAllErrorMessages();
    store.getCountryOptions();
  }

  componentWillUnmount() {
    let store = this.props.store.userCreatePTStore;
    store.stopCreatingUser(false);
  }

  render() {
    let store = this.props.store.userCreatePTStore;
    var selectedLocations = {
      locations: [],
      regions: [],
    };
    var selectedRoles = [];
    var selectedClient = null;
    var locationsUnique = remove_duplicates(
      store.selectedLocations.locations || store.selectedLocations
    );
    locationsUnique.map((e) => {
      if (e) {
        var label = "";
        if (e.title) {
          label = e.title;
        }
        var object = {
          value: e.locationId,
          label: label,
        };
        selectedLocations.locations.push(object);
      }
      return true;
    });

    var rolesUnique = remove_duplicates(store.selectedRoles);
    rolesUnique.map((e) => {
      if (e) {
        var label = "";
        if (e.title) {
          label = e.title;
        }
        var object = {
          value: e.roleId,
          label: label,
        };
        selectedRoles.push(object);
      }
      return true;
    });

    if (store.selectedClient !== null) {
      selectedClient = {
        value: store.selectedClient.clientId,
        label: store.selectedClient.title || "",
      };
    }

    //var selectedClient = store.selectedClient;

    return (
      <div className="view">
        <div className="rc-container bring-forward">
          <h2>Add User</h2>
          <label>
            <i>
              After the user account is created, use the "Release Password" option on the
              user profile to send an email to the user, which will prompt them to create
              a new password. The link will expire in 24 hours.
            </i>
          </label>
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6">
              <div className="form-group">
                <label>Login</label>
                <div className="input_group">
                  <input
                    type="text"
                    min="0"
                    placeholder="Enter Username"
                    className="form-control"
                    maxLength="64"
                    value={store.loginName}
                    onChange={store.changeLoginName}
                  />
                  {store.loginMessage.messages.length > 0 && (
                    <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                      <a
                        href="#close"
                        className="close"
                        data-dismiss="alert"
                        aria-label="close"
                        onClick={store.loginMessage.removeMessage.bind(
                          store,
                          store.loginMessage.messages[0]
                        )}
                      >
                        &times;
                      </a>
                      <strong>{store.loginMessage.messages[0].messageLabel}</strong>
                      {store.loginMessage.messages[0].message}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6">
              <div className="form-group selectfix">
                <label>Client </label>
                <div className="input_group">
                  {
                    <ClientSelect
                      Clients={store.ClientText}
                      onChange={store.changeClientText}
                      value={selectedClient}
                    />
                  }
                </div>
                {store.clientNameMessage.messages.length > 0 && (
                  <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                    <a
                      href="#close"
                      className="close"
                      data-dismiss="alert"
                      aria-label="close"
                      onClick={store.clientNameMessage.removeMessage.bind(
                        store,
                        store.clientNameMessage.messages[0]
                      )}
                    >
                      &times;
                    </a>
                    <strong>{store.clientNameMessage.messages[0].messageLabel}</strong>
                    {store.clientNameMessage.messages[0].message}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4 col-md-4 col-lg-4">
              <div className="form-group">
                <label>First Name</label>
                <div className="input_group">
                  <input
                    type="text"
                    min="0"
                    placeholder="First Name"
                    maxLength="30"
                    className="form-control"
                    value={store.firstName}
                    onChange={store.changeFirstNameText}
                  />
                  {store.firstNameMessage.messages.length > 0 && (
                    <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                      <a
                        href="#close"
                        className="close"
                        data-dismiss="alert"
                        aria-label="close"
                        onClick={store.firstNameMessage.removeMessage.bind(
                          store,
                          store.firstNameMessage.messages[0]
                        )}
                      >
                        &times;
                      </a>
                      <strong>{store.firstNameMessage.messages[0].messageLabel}</strong>
                      {store.firstNameMessage.messages[0].message}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4">
              <div className="form-group">
                <label>Middle Name</label>
                <div className="input_group">
                  <input
                    type="text"
                    min="0"
                    placeholder="Middle Name"
                    maxLength="30"
                    className="form-control"
                    value={store.middleName}
                    onChange={store.changeMiddleNameText}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4">
              <div className="form-group">
                <label>Last Name</label>
                <div className="input_group">
                  <input
                    type="text"
                    min="0"
                    placeholder="Last Name"
                    maxLength="30"
                    className="form-control"
                    value={store.lastName}
                    onChange={store.changeLastNameText}
                  />
                  {store.lastNameMessage.messages.length > 0 && (
                    <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                      <a
                        href="#close"
                        className="close"
                        data-dismiss="alert"
                        aria-label="close"
                        onClick={store.lastNameMessage.removeMessage.bind(
                          store,
                          store.lastNameMessage.messages[0]
                        )}
                      >
                        &times;
                      </a>
                      <strong>{store.lastNameMessage.messages[0].messageLabel}</strong>
                      {store.lastNameMessage.messages[0].message}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6">
              <div className="form-group">
                <label>Primary Email</label>
                <div className="input_group">
                  <input
                    type="text"
                    min="0"
                    placeholder="Enter your Email address"
                    className="form-control"
                    maxLength="250"
                    value={store.primaryEmail}
                    onChange={store.changePrimaryEmail}
                  />
                  {store.primaryEmailMessage.messages.length > 0 && (
                    <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                      <a
                        href="#close"
                        className="close"
                        data-dismiss="alert"
                        aria-label="close"
                        onClick={store.primaryEmailMessage.removeMessage.bind(
                          store,
                          store.primaryEmailMessage.messages[0]
                        )}
                      >
                        &times;
                      </a>
                      <strong>
                        {store.primaryEmailMessage.messages[0].messageLabel}
                      </strong>
                      {store.primaryEmailMessage.messages[0].message}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6">
              <div className="form-group">
                <label>Alternate Email</label>
                <div className="input_group">
                  <input
                    className="form-control"
                    placeholder="Enter a second Email address(optional)"
                    type="text"
                    value={store.secondaryEmail}
                    maxLength="250"
                    onChange={store.changeSecondaryEmail}
                    onBlur={store.onBlurSecondaryEmail}
                  />
                  {store.secondaryEmailMessage.messages.length > 0 && (
                    <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                      <a
                        href="#close"
                        className="close"
                        data-dismiss="alert"
                        aria-label="close"
                        onClick={store.secondaryEmailMessage.removeMessage.bind(
                          store,
                          store.secondaryEmailMessage.messages[0]
                        )}
                      >
                        &times;
                      </a>
                      <strong>
                        {store.secondaryEmailMessage.messages[0].messageLabel}
                      </strong>
                      {store.secondaryEmailMessage.messages[0].message}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6">
              <div className="form-group">
                <label>Primary Phone Number</label>
                <div className="input-group no-padding">
                  <div className="input-group-addon">+</div>
                  <input
                    className="form-control"
                    style={{ zIndex: 0 }}
                    placeholder="Primary Phone Number"
                    type="text"
                    value={store.primaryPhone}
                    maxLength="250"
                    onChange={store.changePrimaryPhone}
                  />
                </div>
                {store.primaryPhoneMessage.messages.length > 0 && (
                  <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                    <a
                      href="#close"
                      className="close"
                      data-dismiss="alert"
                      aria-label="close"
                      onClick={store.primaryPhoneMessage.removeMessage.bind(
                        store,
                        store.primaryPhoneMessage.messages[0]
                      )}
                    >
                      &times;
                    </a>
                    <strong>{store.primaryPhoneMessage.messages[0].messageLabel}</strong>
                    {store.primaryPhoneMessage.messages[0].message}
                  </div>
                )}
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6">
              <div className="form-group">
                <label>Secondary Phone Number</label>
                <div className="input-group no-padding">
                  <div className="input-group-addon">+</div>
                  <input
                    className="form-control"
                    style={{ zIndex: 0 }}
                    placeholder="Secondary Phone Number"
                    type="text"
                    value={store.secondaryPhone}
                    maxLength="250"
                    onChange={store.changeSecondaryPhone}
                  />
                </div>
                {store.secondaryPhoneMessage.messages.length > 0 && (
                  <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                    <a
                      href="#close"
                      className="close"
                      data-dismiss="alert"
                      aria-label="close"
                      onClick={store.secondaryPhoneMessage.removeMessage.bind(
                        store,
                        store.secondaryPhoneMessage.messages[0]
                      )}
                    >
                      &times;
                    </a>
                    <strong>
                      {store.secondaryPhoneMessage.messages[0].messageLabel}
                    </strong>
                    {store.secondaryPhoneMessage.messages[0].message}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6">
              <div className="form-group">
                <label>Main Address</label>
                <div className="input_group">
                  <input
                    className="form-control"
                    placeholder="Main Address"
                    type="text"
                    value={store.mainAddress}
                    maxLength="250"
                    onChange={store.changeMainAddress}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6">
              <div className="form-group">
                <label>Additional Address</label>
                <div className="input_group">
                  <input
                    className="form-control"
                    placeholder="Additional Address"
                    type="text"
                    value={store.additionalAddress}
                    maxLength="250"
                    onChange={store.changeAdditionalAddress}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6">
              <div className="form-group">
                <label>City</label>
                <div className="input_group">
                  <input
                    className="form-control"
                    placeholder="Enter City"
                    type="text"
                    value={store.city}
                    maxLength="250"
                    onChange={store.changeCity}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6">
              <div className="form-group">
                <label>State</label>
                <div className="input_group">
                  <input
                    className="form-control"
                    placeholder="Enter State"
                    type="text"
                    value={store.state}
                    maxLength="250"
                    onChange={store.changeState}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6">
              <div className="form-group">
                <label>Country</label>
                <div className="input_group">
                  <SingleSelect
                    options={store.countryOptions}
                    text="Country"
                    name="country"
                    onChange={store.changeCountry}
                    value={store.selectedCountryOption}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6">
              <div className="form-group">
                <label>Postal Code</label>
                <div className="input_group">
                  <input
                    className="form-control"
                    placeholder="Enter Postal Code"
                    type="text"
                    value={store.postalCode}
                    maxLength="250"
                    onChange={store.changePostalCode}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <label>Share Rate Cards</label>
                </div>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2">
                <div className="form-group">
                  <CheckBox
                    type="radio"
                    name="list-item-select"
                    value="true"
                    selected={store.shareRatecardsFlag}
                    onClick={store.setShareRatecardsFlag}
                    labelName={"Yes"}
                  />
                </div>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2">
                <div className="form-group">
                  <CheckBox
                    type="radio"
                    name="list-item-select"
                    value="false"
                    selected={!store.shareRatecardsFlag}
                    onClick={store.setShareRatecardsFlag}
                    labelName={"No"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6">
              <div className="form-group cross-fix height-auto">
                <label>Allowed Countries</label>
                <MultiCountrySelect
                  CountryTitle={(param) => store.countryTitle(param)}
                  onChange={(value) => store.changeCountryText(value)}
                  value={selectedLocations.locations}
                />
                {store.multiCountryMessage.messages.length > 0 && (
                  <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                    <a
                      href="#close"
                      className="close"
                      data-dismiss="alert"
                      aria-label="close"
                      onClick={store.multiCountryMessage.removeMessage.bind(
                        store,
                        store.multiCountryMessage.messages[0]
                      )}
                    >
                      &times;
                    </a>
                    <strong>{store.multiCountryMessage.messages[0].messageLabel}</strong>
                    {store.multiCountryMessage.messages[0].message}
                  </div>
                )}
                {/*<div className="width-110">*/}
                {/*<div className="form-group">*/}
                {/*<CheckBox*/}
                {/*type={"checkbox"}*/}
                {/*name="list-item-select"*/}
                {/*value={"all"}*/}
                {/*selected={store.selected}*/}
                {/*onClick={store.selectAllCountries}*/}
                {/*labelName={"Select All"}*/}
                {/*/>*/}
                {/*</div>*/}
                {/*</div>*/}
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6">
              <div className="form-group cross-fix height-auto">
                <label>Roles</label>
                <MultiRoleSelect
                  RoleTitle={(param) => store.getAllRoles(param)}
                  onChange={(value) => store.changeRoleText(value)}
                  value={selectedRoles}
                />
                {store.rolesMessage.messages.length > 0 && (
                  <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
                    <a
                      href="#close"
                      className="close"
                      data-dismiss="alert"
                      aria-label="close"
                      onClick={store.rolesMessage.removeMessage.bind(
                        store,
                        store.rolesMessage.messages[0]
                      )}
                    >
                      &times;
                    </a>
                    <strong>{store.rolesMessage.messages[0].messageLabel}</strong>
                    {store.rolesMessage.messages[0].message}
                  </div>
                )}
              </div>
            </div>
          </div>

          {store.messaging.messages.length > 0 && (
            <div className="alert alert-danger alert-dismissable fade in pt-user-alert">
              <a
                href="#close"
                className="close"
                data-dismiss="alert"
                aria-label="close"
                onClick={store.messaging.removeMessage.bind(
                  store,
                  store.messaging.messages[0]
                )}
              >
                &times;
              </a>
              <strong>{store.messaging.messages[0].messageLabel}</strong>
              {store.messaging.messages[0].message}
            </div>
          )}
          <div className="col-sm-12">
            <ContainerSection className="footer">
              <div className="pull-right">
                <button
                  className="btn btn-lg"
                  onClick={(e) => {
                    store.stopCreatingUser();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-lg btn-green"
                  onClick={(e) => {
                    store.createUser();
                  }}
                >
                  Create User
                </button>
              </div>
            </ContainerSection>
          </div>
        </div>
      </div>
    );
  }
}

Create.propTypes = {
  store: PropTypes.object.isRequired,
};

export default observer(Create);
