// @flow
import * as React from "react";
import graphql from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import { createPaginationContainer } from "react-relay";
import FilterAndSort from "./FilterAndSort";
// import type { RenderProps } from "react-relay/ReactRelayQueryRenderer";
import type { RelayPaginationProp } from "react-relay";
import type {
  // TagsFiltersInput,
  TagsContentTypeEnums,
  // FilterAndSortTagsQueryVariables
} from "./__generated__/FilterAndSortTagsQuery.graphql";
import type { FilterAndSortTagsFetchAllQueryResponse } from "./__generated__/FilterAndSortTagsFetchAllQuery.graphql";
import type { FilterAndSortTags_viewer } from "./__generated__/FilterAndSortTags_viewer.graphql";

type TagObject = { id: string, name: string };

type TagsFilter = {
  tagIdIn: Array<string>,
};

type Props = {
  open: boolean,
  currentFilter: TagsFilter | null,
  onClose: () => void,
  onApply: (TagsFilter | null) => void,
  +contentType: TagsContentTypeEnums,
  +viewer: FilterAndSortTags_viewer | null,
  +relay: RelayPaginationProp,
};

type State = {
  refetching: boolean,
  page: number,
  disabled: boolean,
  searchText: string | null,
  selectAll: boolean,
  error: Object | null,
  selectedTags: ?Array<string>,
};

// Used when "getting all ids"
const FetchAllTagsQuery = graphql`
  query FilterAndSortTagsFetchAllQuery(
    $filters: TagsFiltersInput
    $contentType: TagsContentTypeEnums!
  ) {
    viewer {
      tags(filters: $filters, section: ADMIN, contentType: $contentType) {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;

class FilterAndSortTags extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      refetching: false,
      disabled: false,
      page: 0,
      selectAll: false,
      searchText: null,
      error: null,
      selectedTags: null,
    };
  }

  isItemSelected = (tag: TagObject) =>
    this.state.selectedTags
      ? this.state.selectedTags.indexOf(tag.id) !== -1
      : this.props.currentFilter
      ? this.props.currentFilter.tagIdIn.indexOf(tag.id) !== -1
      : false;

  handleApply = async () => {
    if (!this.state.selectedTags) {
      // Do nothing if selected CreatedBys is null
      return;
    }
    const filter: TagsFilter = {
      tagIdIn: this.state.selectedTags,
    };
    this.setState({ selectedTags: null }, () => {
      this.props.onApply(filter);
    });
  };

  handleToggleSelectAll = async () => {
    // If "All" selected get rid of them
    if (this.state.selectAll) {
      this.setState({ disabled: false, selectAll: false, selectedTags: [] });
    } else {
      const filters =
        this.state.searchText !== null ? { nameIContains: this.state.searchText } : {};
      this.setState({ disabled: true }, async () => {
        const data: FilterAndSortTagsFetchAllQueryResponse = await fetchQuery(
          this.props.relay.environment,
          FetchAllTagsQuery,
          { filters }
        ).toPromise();
        this.setState({
          disabled: false,
          selectAll: true,
          selectedTags:
            data.viewer?.tags?.edges
              ?.map((edge, index) => edge?.node?.id || "")
              .filter((id) => id !== "") || [],
        });
      });
    }
  };

  handleToggleSelect = async (id: string) => {
    let selectedTags =
      this.state.selectedTags?.slice() || this.props.currentFilter?.tagIdIn.slice() || [];
    const index = selectedTags.indexOf(id);
    const selected = index !== -1;
    if (selected) {
      selectedTags = selectedTags
        .slice(0, index)
        .concat(selectedTags.slice(index + 1, selectedTags.length));
    } else {
      selectedTags.push(id);
    }
    this.setState({
      selectAll: false,
      selectedTags,
    });
  };

  handleReset = () => {
    this.setState(
      {
        selectedTags: null,
      },
      () => {
        this.props.onApply(null);
      }
    );
  };

  handleClose = () => {
    this.setState(
      {
        selectedTags: null,
      },
      () => {
        this.props.onClose();
      }
    );
  };

  render() {
    const loading = this.props.relay.isLoading() || this.state.refetching;
    const itemsPerPage = 10;

    const tags = this.props?.viewer?.tags?.edges || [];
    const page = tags.slice(
      itemsPerPage * this.state.page,
      Math.min(itemsPerPage * (this.state.page + 1), tags.length)
    );

    if (this.state.error) {
      return (
        <div>
          Error!: <pre>{this.state.error}</pre>
        </div>
      );
    }

    const tagItems: Array<TagObject> = page.map((edge, index) => ({
      id: edge?.node?.id || (-1 * index).toString(10),
      name: edge?.node?.name || "[Error]",
    }));

    // TODO: Fix tag filtering
    return (
      this.props.open && (
        <FilterAndSort
          title="Tags"
          allSelected={false}
          show={this.props.open}
          disabled={false}
          items={tagItems}
          renderItem={(item, onSelect) => {
            return <div>{item.name}</div>;
          }}
          isItemSelected={this.isItemSelected}
          hasNextPage={
            (this.state.page + 1) * itemsPerPage < tags.length ||
            this.props.relay.hasMore()
          }
          onPageForward={() => {
            if (
              this.props.relay.hasMore &&
              (this.state.page + 1) * itemsPerPage > tags.length - itemsPerPage
            ) {
              console.log("loading more items");
              this.setState({ refetching: true }, () => {
                this.props.relay.loadMore(itemsPerPage, (error) => {
                  if (error) {
                    console.error(error);
                  }
                  this.setState({ error, page: this.state.page + 1, refetching: false });
                });
              });
            } else {
              this.setState({ page: this.state.page + 1 });
            }
          }}
          hasPrevPage={this.state.page > 0}
          onPageBackward={() => {
            this.setState({ page: this.state.page > 0 ? this.state.page - 1 : 0 });
          }}
          loading={loading}
          onSearch={async (searchText: string) => {
            console.log("start search");
            this.setState({ refetching: true, selectAll: false, searchText }, () => {
              this.props.relay.refetchConnection(
                itemsPerPage,
                (error) => {
                  console.log("search finished refetch");
                  if (error) {
                    console.error(error);
                  }
                  this.setState({ error, page: 0, refetching: false });
                },
                {
                  filters: {
                    nameIContains: searchText,
                  },
                }
              );
            });
          }}
          selectedItems={this.state.selectedTags || this.props.currentFilter?.tagIdIn}
          onApply={this.handleApply}
          onToggleSelectAll={this.handleToggleSelectAll}
          onToggleSelectItem={this.handleToggleSelect}
          onReset={this.handleReset}
          onClose={this.props.onClose}
        />
      )
    );
  }
}

export default createPaginationContainer(
  FilterAndSortTags,
  {
    viewer: graphql`
      fragment FilterAndSortTags_viewer on Viewer
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        cursor: { type: "String" }
        filters: { type: "TagsFiltersInput", defaultValue: {} }
        order: {
          type: "[TagsSortInput]"
          defaultValue: [{ direction: ASC, field: NAME }]
        }
        contentType: { type: "TagsContentTypeEnums" }
      ) {
        tags(
          first: $count
          after: $cursor
          filters: $filters
          order: $order
          section: ADMIN
          contentType: $contentType
        ) @connection(key: "FilterAndSortTags_tags", filters: ["filters"]) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
  },
  {
    direction: "forward",
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props: Props, { count, cursor }, fragmentVariables) {
      return {
        count,
        cursor,
        filters: fragmentVariables.filters,
        order: fragmentVariables.order,
        contentType: props.contentType,
      };
    },
    query: graphql`
      query FilterAndSortTagsQuery(
        $filters: TagsFiltersInput
        $count: Int
        $cursor: String
        $contentType: TagsContentTypeEnums
      ) {
        viewer {
          ...FilterAndSortTags_viewer
            @arguments(
              filters: $filters
              count: $count
              cursor: $cursor
              contentType: $contentType
            )
        }
      }
    `,
  }
);
