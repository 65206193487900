// @flow
import * as React from "react";
import { LEVEL_SELECTOR } from "../constants/css";
import numeral from "numeral";

export interface RowData {
  id: string;
  levelName: "Junior" | "Mid" | "Senior" | "Lead" | "Guru" | string;
  levelRomanNumeral: string;
  payRateMin: number;
  payRateMax: number;
  billRateMin: number;
  billRateMax: number;
  markupPct: number;
}

type PunchOutTableRowProps = {|
  currencySymbol: string,
  data: RowData,
|};

export function PunchOutTableRow(props: PunchOutTableRowProps): React.Node {
  const { currencySymbol, data } = props;

  const {
    levelName,
    levelRomanNumeral,
    payRateMin,
    payRateMax,
    billRateMin,
    billRateMax,
    markupPct,
  } = data;
  const levelSelector = LEVEL_SELECTOR[levelName];

  return (
    <tr key={levelName}>
      <td className={`no-wrap table-header-background ${levelSelector}`}>
        Level {levelRomanNumeral}
      </td>
      {/* Pay Rate */}
      <td className="no-wrap align-right">
        {currencySymbol} {numeral(payRateMin).format("0,0.00")}
      </td>
      <td className="no-wrap align-right">
        {currencySymbol} {numeral(payRateMax).format("0,0.00")}
      </td>
      <td className="no-wrap align-right">{numeral(markupPct).format("0,0.00")} %</td>
      <td className="no-wrap align-right">
        {currencySymbol} {numeral(billRateMin).format("0,0.00")}
      </td>
      <td className="no-wrap align-right">
        {currencySymbol} {numeral(billRateMax).format("0,0.00")}
      </td>
    </tr>
  );
}

type PunchOutTableProps = {
  data: Array<RowData>,
  currencySymbol: string,
};

function PunchOutTable(props: PunchOutTableProps): React.Node {
  const { data, currencySymbol } = props;

  return (
    <>
      <div className="search-peek-table-container">
        <div className="flex-grid">
          <div className="flex-row no-gutters">
            <div className="flex-grid flex-auto" style={{ margin: "0 auto" }}>
              <div className="flex-row no-gutters no-wrap flex-auto">
                <div className="flex-col flex-auto">
                  <table className="table table-striped pay-rate-table no-margin">
                    <thead className="">
                      <tr>
                        <th className="table-header-no-background no-border" />
                        <th
                          className="table-header-no-background peek-table-header"
                          colSpan="4"
                        >
                          {/*PAY RATE*/}
                        </th>
                      </tr>
                      <tr>
                        <th className="" />
                        {/* Pay Rate */}
                        <th className="">Pay Min</th>
                        <th className="">Pay Max</th>
                        <th className="">Markup</th>
                        <th className="">Bill Min</th>
                        <th className="">Bill Max</th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {data.map((row) => {
                        if (!row) return null;

                        return (
                          <PunchOutTableRow
                            key={row.id}
                            data={row}
                            currencySymbol={currencySymbol}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PunchOutTable;
