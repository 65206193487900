import graphql from "babel-plugin-relay/macro";
import React, { Suspense, useRef } from "react";
import { styled } from "../../../stitches.config";
// relay
import { useFragment } from "react-relay";
import Stack from "../../../components/lib/Stack";
import { JobTitle, JobTitleLoader } from "./JobTitle";
import { ResultOptions, ResultOptionsLoader } from "./ResultOptions";
import { ResultTabs, ResultTabsLoader } from "./ResultTabs";
import { SettingsMenu, SettingsMenuLoader } from "./SettingsMenu";
import { ContentRateSearch_viewer$key } from "./__generated__/ContentRateSearch_viewer.graphql";

const Wrapper = styled("div", {
  flex: 3,
  display: "flex",
  flexDirection: "column",
  minHeight: "calc(100vh - 60px)",
  padding: "$4",
  gap: "$6",
  maxWidth: 1060,
  width: "calc(100vw - 400px)",
  "@media(max-width: 1200px)": {
    padding: "$6",
    maxWidth: "unset",
  },
});

const Header = styled("section", {
  display: "flex",
  flexDirection: "column",
  gap: "$4",
});

const InnerWrapper = styled("div", {
  display: "flex",
  flex: 1,
  gap: "$6",
});

const fragment = graphql`
  fragment ContentRateSearch_viewer on Viewer
  @argumentDefinitions(searchId: { type: "Int!" }) {
    ...JobTitleSearchResults_savesearch @arguments(searchId: $searchId)
    ...ResultOptions_viewer @arguments(searchId: $searchId)
    ...SettingsMenuSearchResutls_viewer @arguments(searchId: $searchId)
    ...ResultTabs_viewer @arguments(searchId: $searchId)
  }
`;

type Props = { viewer: ContentRateSearch_viewer$key };

export function Content({ viewer }: Props) {
  const data = useFragment(fragment, viewer);
  const pdfTargetRef = useRef<HTMLDivElement>(null);

  return (
    <Wrapper ref={pdfTargetRef}>
      <Header>
        <InnerWrapper>
          <Suspense fallback={<JobTitleLoader />}>
            <JobTitle savesearch={data} />
          </Suspense>
          <Suspense fallback={<ResultOptionsLoader />}>
            <Stack>
              <ResultOptions viewer={data} />
            </Stack>
          </Suspense>
        </InnerWrapper>
        <InnerWrapper>
          <Suspense fallback={<SettingsMenuLoader />}>
            <SettingsMenu viewer={data} />
          </Suspense>
        </InnerWrapper>
      </Header>
      <Suspense fallback={<ResultTabsLoader />}>
        <ResultTabs viewer={data} />
      </Suspense>
    </Wrapper>
  );
}
