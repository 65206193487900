import React from "react";
import { observer } from "mobx-react";
import Modal from "./Modal";
// @ts-ignore
import ModalState from "../../models/ModalState";
// @ts-ignore
import { ModalButtons, ModalTitle } from "../../views/ratecards/components/FilterModal";
import Button from "../lib/Button";
import Inline from "../lib/Inline";
import Icon from "../lib/Icon";

interface BaseErrorModalProps {
  errorMessage: React.ReactNode;
  show: boolean;
  onHide: () => void;
  style?: any;
}

export const BaseErrorModal = (props: BaseErrorModalProps): JSX.Element => {
  const { errorMessage, show, onHide, style } = props;

  return (
    <Modal show={show} onHide={onHide} innerStyle={style}>
      <div className="container-section header">
        <ModalTitle>Error</ModalTitle>
      </div>
      <div className="container-section">
        <Inline fill nowrap css={{ alignItems: "center" }}>
          <Icon icon="exclamation-circle" css={{ color: "$danger", fontSize: "$4xl" }} />
          <div>{errorMessage}</div>
        </Inline>
      </div>
      <div className="container-section footer">
        <ModalButtons>
          <Button size="large" onClick={onHide}>
            Close
          </Button>
        </ModalButtons>
      </div>
    </Modal>
  );
};
BaseErrorModal.displayName = "BaseErrorModal";

interface ErrorModalProps {
  modalState: ModalState;
  errorMessage: React.ReactNode;
  style?: any;
}

const ErrorModal = (props: ErrorModalProps) => {
  const { modalState, errorMessage, style } = props;

  return (
    <BaseErrorModal
      errorMessage={errorMessage}
      show={modalState.show}
      onHide={modalState.hideModal}
      style={style}
    />
  );
};
ErrorModal.displayName = "ErrorModal";

export default observer(ErrorModal);
