// @flow strict
import * as React from "react";
import ReactDOM from "react-dom";
import ladda from "ladda";
import classNames from "classnames";

type Props = {
  active: boolean,
  disabled: boolean,
  progress: number,
  style: string,
  className: string,
  color: string,
  size: string,
  spinnerSize: number,
  spinnerColor: string,
  children: any,
};

class LaddaButton extends React.Component<Props, {}> {
  active: boolean | null;

  static defaultProps = {
    style: "expand-left",
  };

  active = null;
  laddaButton = null;
  isUnmounted = false;

  getLaddaButton() {
    if (this.isUnmounted) return null;

    if (!this.laddaButton) this.laddaButton = ladda.create(ReactDOM.findDOMNode(this));

    return this.laddaButton;
  }

  removeLaddaButton() {
    if (this.laddaButton && this.laddaButton.remove) {
      this.laddaButton.remove();
      this.laddaButton = null;

      // Ladda library will clear disabled attribute when we call .remove(),
      // we need to check to see if we should re-add the 'disabled' attribute to keep
      // the actual button in sync with what we expect.
      if (this.props.disabled) {
        const button = ReactDOM.findDOMNode(this);
        // $FlowFixMe: Not sure how to narrow down to element
        button.setAttribute("disabled", "");
      }
    }
  }

  componentWillUnmount() {
    this.removeLaddaButton();
    this.isUnmounted = true;
  }

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    if (!this.props.active && this.laddaButton === null) return;

    const laddaButton = this.getLaddaButton();
    if (!laddaButton) {
      return;
    }

    if (this.props.active) {
      if (!this.active) {
        this.active = true;
        laddaButton.start();
      }
      if (this.props.progress) {
        laddaButton.setProgress(this.props.progress);
      }
    } else {
      this.active = false;
      laddaButton.stop();
      this.removeLaddaButton();
    }
  }

  static laddaOptions = {
    style: "data-style",
    color: "data-color",
    size: "data-size",
    spinnerSize: "data-spinner-size",
    spinnerColor: "data-spinner-color",
  };

  render() {
    var childProps = {
      className: classNames("ladda-button", this.props.className),
    };
    for (var prop in LaddaButton.laddaOptions) {
      if (this.props[prop]) {
        var dataAttr = LaddaButton.laddaOptions[prop];
        childProps[dataAttr] = this.props[prop];
      }
    }
    return React.cloneElement(React.Children.only(this.props.children), childProps);
  }
}

export default LaddaButton;
