import React from "react";
import Button from "../../../../components/lib/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../components/lib/DropdownMenu";
import {
  useSearchResultsStore,
  FilterRateFrequencies,
} from "../../../../stores/SearchResults";

export function RateFrequencyDropdown() {
  const rateFrequency = useSearchResultsStore((s) => s.rateFrequency);
  const setRateFrequency = useSearchResultsStore((s) => s.setRateFrequency);
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          css={{ minWidth: 90, justifyContent: "space-between" }}
          text={rateFrequency}
          iconRight="caret-down"
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent style={{ minWidth: "80px" }}>
        {FilterRateFrequencies.map((freq) => (
          <DropdownMenuItem
            text={freq}
            key={freq}
            onSelect={() => setRateFrequency(freq)}
          />
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
