import React, { Suspense, useState } from "react";
import { useMutation } from "react-relay";
// @ts-ignore
import graphql from "babel-plugin-relay/macro";

import Box from "../../components/lib/Box";
import Button from "../../components/lib/Button";
import Center from "../../components/lib/Center";
import { Checkbox, CheckboxIndicator, Label } from "../../components/lib/Checkbox";
import Container from "../../components/lib/Container";
import Inline from "../../components/lib/Inline";
import TickerLoader from "../../components/lib/TickerLoader";
import PageTitle from "../../components/PageTitle";
import { styled } from "../../../src/stitches.config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "../../components/lib/Card";
import Footer from "../../components/lib/Footer";
import Stack from "../../components/lib/Stack";
import Divider from "../../components/lib/Divider";
import { RpEulaUpdateUserTermsOfAgreementMutation } from "./__generated__/RpEulaUpdateUserTermsOfAgreementMutation.graphql";
import { SimpleLink } from "../../components/lib/Link";
import Logo from "../../components/Logo";
import { supportEmail } from "../../constants";

const RpEulaHeader = styled(Inline, {
  justifyContent: "center",
  padding: "15px 0px",
  backgroundColor: "$secondary",
});

const EulaTitle = styled("h4", {
  color: "$accent",
  fontWeight: "$bold",
});

const AcceptEulaButton = styled(Button, {
  width: "100px",
  borderRadius: "10",
  fontSize: "18px !important",
  fontWeight: "600",
  padding: "20px",
});

const DeclineEulaButton = styled(Button, {
  width: "100px",
  borderRadius: "10",
  fontSize: "18px !important",
  fontWeight: "600",
  padding: "20px",
});

const MailToLink = styled("a", {
  color: "$brand !important",
  "&:hover": {
    textDecoration: "underline !important",
  },
});

function RpEula(props: any) {
  const [acceptEula, setAcceptEula] = useState<boolean>(false);
  const [commitTermsOfAgreement, termsOfAgreementIsInFlight] =
    useMutation<RpEulaUpdateUserTermsOfAgreementMutation>(
      graphql`
        mutation RpEulaUpdateUserTermsOfAgreementMutation($termsOfAgreement: Boolean!) {
          updateUserTermsOfAgreement(termsOfAgreement: $termsOfAgreement) {
            ok
          }
        }
      `
    );

  function handleAcceptEula() {
    commitTermsOfAgreement({
      variables: {
        termsOfAgreement: true,
      },
      onCompleted(_data) {
        props.router.replace("/");
      },
    });
  }

  function logout() {
    props
      .fetchGraphQL("mutation logout{ logoutUser{ ok } }", null)
      .then((res: any) => {
        props.updateLoginState(false, null, null);
        props.router.push({
          pathname: "/login",
        });
      })
      .catch((e: any) => {
        console.error("Error logging user out", e);
        props.updateLoginState(false, null, null);
        props.router.push({
          pathname: "/login",
        });
      });
  }

  return (
    <Suspense
      fallback={
        <Center
          css={{ position: "fixed", top: 0, bottom: 0, left: 0, right: 0, zIndex: "$10" }}
        >
          <TickerLoader />
        </Center>
      }
    >
      <RpEulaHeader>
        <Logo title="logo" />
      </RpEulaHeader>
      <Card
        css={{
          maxWidth: "65%",
          padding: "20px 60px 40px 60px",
          margin: "auto",
          marginTop: "10px",
        }}
      >
        <PageTitle>
          <Box css={{ color: "$accent", textAlign: "center" }}>
            Pay Intel Business Intelligence™
          </Box>
          <Box css={{ color: "$accent", textAlign: "center" }}>
            End User License Agreement
          </Box>
        </PageTitle>
        <Stack css={{ alignItems: "flex-start", textAlign: "start" }}>
          <Inline>(Last Updated: 18 February 2021)</Inline>
          <Stack css={{ alignItems: "flex-start", textAlign: "start" }}>
            <EulaTitle>Introduction</EulaTitle>
            <p>
              Please read this End User License Agreement ("EULA") carefully before
              clicking the "I Agree” button or using the Pay Intel Business Intelligence™
              service (“Pay Intel”).
            </p>
            <p>
              By clicking the "I Agree" button or using Pay Intel, you are agreeing to be
              bound by the terms and conditions of this Agreement.
            </p>
            <p>
              This Agreement is a supplement to the Pay Intel Subscription Services
              Agreement (the “SSA”) entered into between your employer/client and Magnit,
              Inc. and/or its subsidiary, RightSourcing, Inc. (“Magnit”) and, without
              limiting any provision of such SSA is intended to notify you of certain
              provisions of the SSA that apply to your use of Pay Intel as made available
              to you by your employer/client and Magnit. If you do not understand, or do
              not agree to, the terms of this EULA, do not click on the "I Agree" button
              and do not use Pay Intel. Your use of Pay Intel must be strictly in
              accordance with the terms of this EULA and the SSA. If you have any
              questions about whether your use of Pay Intel in compliance with the SSA,
              please contact the Chief Information Office or Chief Technology Officer of
              your employer/client.
            </p>
          </Stack>
          <Stack css={{ alignItems: "flex-start", textAlign: "start" }}>
            <EulaTitle>Licenses</EulaTitle>
            <p>
              Magnit has granted your employer/Client that entered into the SSA a
              revocable, non-exclusive, non-transferable, limited license to use and
              access Pay Intel solely for its own use or for the provision of services to
              its clients, in each case in accordance with the terms of this EULA and the
              SSA. You understand and agree that you are being granted use of Pay Intel
              pursuant to such SSA, which places legal obligations on your use of Pay
              Intel with which you must comply.
            </p>
          </Stack>
          <Stack css={{ alignItems: "flex-start", textAlign: "start" }}>
            <EulaTitle>Restrictions</EulaTitle>
            <p>
              You understand and agree that, pursuant to the SSA, you will not yourself,
              and will not permit others to:
            </p>
            <Container>
              <ul>
                <li>
                  license, sell, rent, lease, assign, distribute, transmit, host,
                  outsource, disclose or otherwise commercially exploit Pay Intel or make
                  Pay Intel available to any third party.
                </li>
                <li>
                  copy or use Pay Intel for any purpose other than as permitted under the
                  SSA.
                </li>
                <li>
                  modify, make derivative works of, disassemble, decrypt, reverse compile
                  or reverse engineer any part of Pay Intel.
                </li>
                <li>
                  remove, alter or obscure any proprietary notice (including any notice of
                  copyright or trademark) of Magnit or its affiliates, partners, suppliers
                  or the licensors of Pay Intel.
                </li>
                <li>
                  access or use Pay Intel to build or support, directly or indirectly,
                  products or services competitive to Magnit.
                </li>
                <li>
                  introduce any virus, cancelbot, Trojan horse, worm or other harmful
                  component.
                </li>
                <li>
                  perform or disclose network discovery, port and service identification,
                  vulnerability scanning, password cracking or remote access testing of
                  Pay Intel.
                </li>
                <li>
                  perform or permit any integrations or interfaces that have not been
                  expressly authorized by Magnit.
                </li>
                <li>
                  share your unique user ID and password with anyone, including any
                  supervisor or manager.
                </li>
                <li>use or access Pay Intel in violation of any applicable laws.</li>
                <li>
                  use any information assessed from Pay Intel for any non-business purpose
                  or any purpose other than the properly licensed purpose permitted under
                  this Agreement.
                </li>
              </ul>
            </Container>
          </Stack>
          <Stack css={{ alignItems: "flex-start", textAlign: "start" }}>
            <EulaTitle>Intellectual Property</EulaTitle>
            <p>
              Pay Intel, including without limitation all copyrights, patents, trademarks,
              trade secrets and other intellectual property rights are, and shall remain,
              the sole and exclusive property of Magnit. The license is granted to you is
              limited to remote access to Pay Intel and does not include any right to the
              transfer or distribution of software or source code to you.
            </p>
          </Stack>
          <Stack css={{ alignItems: "flex-start", textAlign: "start" }}>
            <EulaTitle>Data Integrity</EulaTitle>
            <p>
              You and your employer/client are responsible for the accuracy, quality,
              integrity, legality, reliability, and appropriateness of the data that you
              enter into Pay Intel, for examining and confirming results before using
              them, and for adopting procedures for identifying and preventing errors in
              the data.
            </p>
          </Stack>
          <Stack css={{ alignItems: "flex-start", textAlign: "start" }}>
            <EulaTitle>Your Suggestions</EulaTitle>
            <p>
              Any feedback, comments, ideas, improvements or suggestions (collectively,
              "Suggestions") provided by you to Magnit with respect to Pay Intel shall
              remain the sole and exclusive property of Magnit.
            </p>
            <p>
              Magnit shall be free to use, copy, modify, publish, or redistribute the
              Suggestions for any purpose and in any way without any credit or any
              compensation to you.
            </p>
          </Stack>
          <Stack css={{ alignItems: "flex-start", textAlign: "start" }}>
            <EulaTitle>Modifications to Application</EulaTitle>
            <p>
              Magnit reserves the right to modify, suspend or discontinue, temporarily or
              permanently, Pay Intel or any service to which it connects, with or without
              notice and without liability to you.
            </p>
          </Stack>
          <Stack css={{ alignItems: "flex-start", textAlign: "start" }}>
            <EulaTitle>Privacy Policy</EulaTitle>
            <p>
              Magnit collects, stores, maintains, and shares information in accordance
              with its Privacy Statement, which is available{" "}
              <SimpleLink href="https://www.prounlimited.com/privacy-notice.php">
                here
              </SimpleLink>
              . By accepting this Agreement, you acknowledge that you have reviewed and
              understand the terms and conditions of our Privacy Statement.
            </p>
          </Stack>
          <Stack css={{ alignItems: "flex-start", textAlign: "start" }}>
            <EulaTitle>Term and Termination</EulaTitle>
            <p>
              This EULA shall remain in effect until terminated by your employer/client or
              Magnit.
            </p>
            <p>
              Magnit may terminate immediately and without prior notice from Magnit your
              access to Pay Intel if you fail to comply with any provision of this
              Agreement.
            </p>
            <p>Upon termination of the SSA, you shall cease all use of Pay Intel.</p>
            <p>
              Termination of your access to Pay Intel will not limit any of Magnit's
              rights or remedies at law or in equity in case of breach by you of any of
              your obligations under the SSA or this EULA.
            </p>
          </Stack>
          <Stack css={{ alignItems: "flex-start", textAlign: "start" }}>
            <EulaTitle>Confidential Information</EulaTitle>
            <p>
              You shall come into contact with certain proprietary and confidential
              information of Magnit, and components or elements of Pay Intel or its
              documentation which is designated, or which a reasonable person should know,
              is confidential (“Confidential Information”). You agree that you will not,
              directly or indirectly, disclose Confidential Information to any third
              party, nor use any Confidential Information for any purpose other than the
              purposes authorized under this Agreement.
            </p>
            <p>
              Notwithstanding anything in these terms to the contrary, the term
              Confidential Information shall not include any information that (a) has been
              independently developed by another party without reference to the
              Confidential Information; (b) was lawfully known to the receiver of the
              information without an obligation to keep it confidential; (c) is received
              from another source who can disclose it lawfully and without an obligation
              to keep it confidential; or (d) is, or becomes, publicly available without a
              breach of this Agreement or violation of the law.
            </p>
            <p>
              You acknowledge that the improper disclosure or use of Confidential
              Information may cause irreparable injury and damages that may not be readily
              ascertainable, such that injunctive relief may be proper in addition to such
              other remedies as may be available at law or in equity. You shall advise
              Magnit promptly if you have reason to suspect a threatened or actual
              disclosure or improper use of Confidential Information.
            </p>
          </Stack>
          <Stack css={{ alignItems: "flex-start", textAlign: "start" }}>
            <EulaTitle>Amendments to this EULA</EulaTitle>
            <p>
              Magnit reserves the right, at its sole discretion, to modify or replace this
              Agreement at any time. If a revision is material, we will provide at least
              30 days' notice prior to any new terms taking effect. What constitutes a
              material change will be determined at our sole discretion.
            </p>
            <p>
              By continuing to access or use our Application after any revisions become
              effective, you agree to be bound by the revised terms. If you do not agree
              to the new terms, you are no longer authorized to use Pay Intel.
            </p>
          </Stack>
          <Stack css={{ alignItems: "flex-start", textAlign: "start" }}>
            <EulaTitle>Governing Law</EulaTitle>
            <p>
              The laws of the State of California, United States of America, excluding its
              conflicts of law rules, shall govern this Agreement and your use of Pay
              Intel. Your use of Pay Intel may also be subject to other local, state,
              national, or international laws.
            </p>
            <p>
              This Agreement shall not be governed by the United Nations Convention on
              Contracts for the International Sale of Goods.
            </p>
          </Stack>
          <Stack css={{ alignItems: "flex-start", textAlign: "start" }}>
            <EulaTitle>Contact Information</EulaTitle>
            <p>
              Magnit may notify you with respect to Pay Intel by sending an email message
              to any email address you have provided or by posting on Pay Intel. Notices
              will be effective immediately. If you have any questions about this
              Agreement, please contact us by sending an email to{" "}
              <SimpleLink href="mailto:legal@prounlimited.com">
                legal@prounlimited.com
              </SimpleLink>
              .
            </p>
          </Stack>
        </Stack>
        <Divider css={{ marginTop: "30px", marginBottom: "30px" }} />
        <Box css={{ textAlign: "center" }}>
          <p>
            Please indicate that you have read and agree to the End User License Agreement
            in order to proceed. <br></br>
            By declining you will be denied access to Pay Intel.
          </p>
          <Inline css={{ gap: "1px", marginTop: 20, justifyContent: "center" }}>
            <Checkbox
              onCheckedChange={(e) => setAcceptEula(e.valueOf() ? true : false)}
              id="acceptEulaCheckBox"
            >
              <CheckboxIndicator>
                <FontAwesomeIcon icon="check" />
              </CheckboxIndicator>
            </Checkbox>
            <Label css={{ paddingLeft: 15 }} htmlFor="acceptEulaCheckBox">
              I Agree
            </Label>
          </Inline>
          <Inline css={{ gap: "50px", marginTop: 20, justifyContent: "center" }}>
            <AcceptEulaButton
              onClick={handleAcceptEula}
              disabled={!acceptEula || termsOfAgreementIsInFlight}
            >
              Continue
            </AcceptEulaButton>
            <DeclineEulaButton
              onClick={logout}
              disabled={acceptEula || termsOfAgreementIsInFlight}
            >
              Decline
            </DeclineEulaButton>
          </Inline>
        </Box>
      </Card>

      <Inline
        css={{
          width: "65%",
          justifyContent: "center",
          margin: "auto",
          padding: "$8 0",
          gap: "$1",
        }}
      >
        If you need help, please email
        <MailToLink href={`mailto:${supportEmail}`}>{supportEmail}</MailToLink>
      </Inline>
      <Footer />
    </Suspense>
  );
}

export default RpEula;
