import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingIndicator from "../../shared/LoadingIndicator";
import { observer } from "mobx-react";
import { browserHistory } from "react-router";
import Button from "../../../components/lib/Button";

export default class RecentActivity extends Component {
  showDetails() {
    browserHistory.push("/saved-searches");
  }

  showSearchDetails(searchId) {
    browserHistory.push("/searches/" + searchId);
  }

  render() {
    let indexStore = this.props.store;
    let network = indexStore.network;
    let savedSearches = this.props.savedSearches;
    let tableContent = null;
    let valText = null;
    let tableRow = null;

    valText = (
      <div>
        <Button onClick={this.showDetails}>More</Button>
      </div>
    );

    if (network.loading) {
      tableContent = <LoadingIndicator />;
    } else if (network.error) {
      tableContent = (
        <div className="clearfix">{JSON.stringify(network.error, null, 2)}</div>
      );
    } else if (savedSearches.length === 0) {
      tableContent = <div className="clearfix">No Recent Saved Search Found</div>;
    } else {
      tableRow = savedSearches.map((ss) => (
        <RecentSearches
          key={ss.searchId}
          search={ss}
          displaySavedSearchDetail={this.showSearchDetails}
        />
      ));
      tableContent = (
        <div>
          <div className="clearfix ratecard_new_table">
            <table
              className="no-spacing table table-bordered table-striped"
              cellSpacing="0"
              data-testid="recent-searches-list"
            >
              <thead className="">
                <tr>
                  <th>Job Title</th>
                  <th>Job Label</th>
                  <th>Location</th>
                  <th>Date Modified</th>
                  <th />
                </tr>
              </thead>
              <tbody>{tableRow}</tbody>
            </table>
          </div>
        </div>
      );
    }

    return (
      <div>
        <span className="icon border">
          <FontAwesomeIcon
            icon="file"
            style={{ float: "left", margin: "6px 15px 0 0" }}
          />
          <h3 data-testid="recent-searches-heading">Recent Searches</h3>
        </span>
        <br />
        {tableContent}
        {valText}
      </div>
    );
  }
}

const RecentSearches = observer((props) => {
  const search = props.search;
  // let location = [];

  // if (search.region) {
  //   location.push(search.region.name);
  // } else {
  //   if (search.city) {
  //     location.push(search.city);
  //   }
  //   if (search.state) {
  //     location.push(search.state);
  //   }
  //   if (search.country) {
  //     location.push(search.country);
  //   }
  // }

  const loc = search.locationFullLabel;
  const dm = search.recentUpdated;

  return (
    <tr>
      <td>
        <span>{search.job.jobTitle}</span>
      </td>
      <td>
        <span>{search.job.jobLabel}</span>
      </td>
      <td>
        <span>{loc}</span>
      </td>
      <td>
        <span>{dm}</span>
      </td>
      <td>
        <Button
          color="brand"
          onClick={(e) => {
            props.displaySavedSearchDetail(search.searchId);
          }}
        >
          View
        </Button>
      </td>
    </tr>
  );
});
