// @flow

import { extendObservable, computed, runInAction, action } from "mobx";
import { CountryFilterCriterion, RateTypeFilterCriterion } from "../RateCard";
import NetworkState from "../NetworkState";
import type { FetchGraphQL } from "../../App";

export type HighestPayingJobsWhiskerDataItem = {
  name: string,
  low: number,
  high: number,
  median: number,
  q1: number,
  q3: number,
};

export class HighestPayingJobsWhiskerData {
  currency: ?string;
  series: ?(HighestPayingJobsWhiskerDataItem[]);

  constructor(payloadData: ?Object) {
    this.currency = null;
    this.series = null;

    if (payloadData) {
      this.currency = payloadData.currency;
      this.series = payloadData.series;
    }
  }
}

export default class HighestPayingJobsWhiskerState {
  rateCardStore: Object;
  fetchGraphQL: FetchGraphQL;
  network: NetworkState;
  rateCardId: number;
  chartData: ?HighestPayingJobsWhiskerData;
  countries: CountryFilterCriterion[];
  countriesView: CountryFilterCriterion[];
  selectedCountry: ?CountryFilterCriterion;
  countrySearch: string;
  onCountrySearchChange: (string) => void;
  onSelectedCountryChange: (?CountryFilterCriterion) => void;
  rateTypes: RateTypeFilterCriterion[];
  selectedRateType: ?RateTypeFilterCriterion;
  onSelectedRateTypeChange: (?RateTypeFilterCriterion) => void;
  load: (?boolean) => Promise<any>;

  constructor(store: Object, fetchGraphQL: FetchGraphQL) {
    this.rateCardStore = store;
    this.fetchGraphQL = fetchGraphQL;

    // const rateTypes = [new RateTypeFilterCriterion(3), new RateTypeFilterCriterion(5), new RateTypeFilterCriterion(10)];
    //
    extendObservable(this, {
      network: new NetworkState(),
      chartData: new HighestPayingJobsWhiskerData(null),
      countries: [],
      rateTypes: [],
      selectedCountry: null,
      selectedRateType: null,
      countrySearch: "",
      countriesView: computed(() => {
        if (!this.countrySearch) return [...this.countries];

        return this.countries.filter((country: CountryFilterCriterion) => {
          return country
            .toString()
            .toLowerCase()
            .includes(this.countrySearch.toLowerCase());
        });
      }),
    });

    this.onCountrySearchChange = action(this.onCountrySearchChange.bind(this));
    this.onSelectedCountryChange = action(this.onSelectedCountryChange.bind(this));
    this.onSelectedRateTypeChange = action(this.onSelectedRateTypeChange.bind(this));
    this.load = action(this.load.bind(this));
  }

  onCountrySearchChange(value: string) {
    this.countrySearch = value;
  }

  onSelectedCountryChange(country: ?CountryFilterCriterion) {
    this.selectedCountry = country;
    this.load(true);
  }

  onSelectedRateTypeChange(rateType: ?RateTypeFilterCriterion) {
    this.selectedRateType = rateType;
    this.load(true);
  }

  async load(fullReload: boolean = false) {
    this.network.loading = true;

    if (!fullReload && this.chartData) return;

    const query = `
    query getRateCardVisualizations($rateCardId: Int!, $country: String!, $rateType: JobRateType!) {
      viewer {
        rateCard(legacyId: $rateCardId) {
          visualizations {
            highestPayingJobsByCountry(country: $country, rateType: $rateType) {
              currency
              series {
                name
                low
                high
                q1
                q3
              }
            }
          }
        }
      }
    }
    `;

    if (!this.selectedCountry) return;
    if (!this.selectedRateType) return;

    let payload: ?Object;
    try {
      const variables = {
        rateCardId: this.rateCardStore.rateCardId,
        country: this.selectedCountry.country,
        rateType: this.selectedRateType.name,
      };
      payload = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Getting Rate Card Visualizations", e);
      // TODO: Display user friendly error message
      return;
    }

    runInAction("getVisualizations--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("getRateCardVisualizations", payload)) {
        // TODO: Display user friendly error message
        return;
      }

      // NOTE: Flow refinement
      if (payload == null) {
        return;
      }

      this.chartData = new HighestPayingJobsWhiskerData(
        payload.data.viewer.rateCard.visualizations.highestPayingJobsByCountry
      );
    });
  }
}
