// @flow
import * as React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "../../../stitches.config";
import { linkBaseStyles } from "../../../components/lib/Link";

const PaginationButton = styled("button", linkBaseStyles, {
  fontSize: "$xs",
  lineHeight: "$xs",
  padding: "0 $1",
  "&:disabled": {
    cursor: "not-allowed",
    color: "$primary",
    textDecoration: "none",
  },
});

type Props<T> = {
  items: Array<T>,
  itemsPerPage: number,
  renderItem: (item: T, index?: number) => React.Node,
  noItemsView?: React.Node,
  loadingView?: React.Node,
  loading?: boolean,
  className?: ?string,
  style?: ?any,
};

type State = {
  pageNumber: number,
};

class SelectableItemsPaginatedList<T> extends React.Component<Props<T>, State> {
  state = {
    pageNumber: 1,
  };

  render() {
    const {
      items,
      itemsPerPage,
      renderItem,
      noItemsView,
      loadingView,
      loading,
      className,
      style,
    } = this.props;
    const { pageNumber } = this.state;

    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const pageItems = items.slice(startIndex, endIndex);
    const pageCount = Math.ceil(items.length / itemsPerPage);

    const hasNextPage = endIndex < items.length;
    const hasPrevPage = startIndex > 0;

    const isEmpty = !loading && !items.length;
    // console.log("pageNumber:", pageNumber);
    // console.log("startIndex:", startIndex);
    // console.log("endIndex:", endIndex);
    // console.log("pageCount:", pageCount);
    // console.log("hasNextPage:", hasNextPage);
    // console.log("hasPrevPage:", hasPrevPage);

    // This is a fix for IE11. the div containing the items (selectable-list-container)
    // would get a height of -0.01px when the first letter typed into the filter box didn't
    // matched any item. This would cause a HUGE div height to be rendered. So we're explicitly
    // setting the height to 0 when there are no items on the list
    const classes = classNames(className, "selectable-list-container", {
      empty: isEmpty && !noItemsView,
    });

    return (
      <>
        <div className={classes} style={style}>
          {loading && <>{loadingView}</>}
          {isEmpty && <>{noItemsView}</>}
          {!loading &&
            !isEmpty &&
            pageItems.map((item, index) => renderItem(item, index))}
          {!loading && !isEmpty && (
            <div className="selectable-list-pagination-container">
              <PaginationButton
                disabled={!hasPrevPage}
                onClick={() => this.setState({ pageNumber: 1 })}
              >
                <FontAwesomeIcon icon="angle-double-left" /> First
              </PaginationButton>
              <PaginationButton
                disabled={!hasPrevPage}
                onClick={() => this.setState({ pageNumber: pageNumber - 1 })}
              >
                <FontAwesomeIcon icon="angle-left" /> Prev
              </PaginationButton>
              <div className="pagination-info">
                {pageNumber} / {pageCount}
              </div>
              <PaginationButton
                disabled={!hasNextPage}
                onClick={() => this.setState({ pageNumber: pageNumber + 1 })}
              >
                Next <FontAwesomeIcon icon="angle-right" />
              </PaginationButton>
              <PaginationButton
                disabled={!hasNextPage}
                onClick={() => this.setState({ pageNumber: pageCount })}
              >
                Last <FontAwesomeIcon icon="angle-double-right" />
              </PaginationButton>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default observer(SelectableItemsPaginatedList);
