// @flow
import { action, observable, extendObservable, runInAction } from "mobx";
import axios from "axios";
import moment from "moment";
import RawJobTitle from "./RawJobTitle";
import NetworkState from "./NetworkState";
import CCCCountry from "./CCCCountry";

export class LibraryTitleLatestRates {
  levelId: number;
  payRateMin: ?number;
  payRateMax: ?number;
  billRateMin: ?number;
  billRateMax: ?number;

  constructor(payloadObject: Object) {
    const { levelId, payRateMin, payRateMax, billRateMin, billRateMax } = payloadObject;
    if (!levelId) throw new Error("Property payloadObject.levelId required.");
    this.levelId = levelId;
    this.payRateMin = payRateMin;
    this.payRateMax = payRateMax;
    this.billRateMin = billRateMin;
    this.billRateMax = billRateMax;
  }
}

export class LibraryTitleLatestSearch {
  titleId: number;
  searchId: number;
  ratecardId: ?number;
  created: ?moment$Moment;
  rateType: ?number;
  isDraft: ?boolean;
  industry: ?string;
  location: ?string;
  countryCode: ?string;
  currencySymbol: ?string;
  rates: Array<LibraryTitleLatestRates>;

  constructor(payloadObject: Object) {
    const {
      titleId,
      searchId,
      ratecardId,
      created,
      rateType,
      isDraft,
      industry,
      location,
      countryCode,
      currencySymbol,
      rates,
    } = payloadObject;
    if (!titleId) throw new Error("Property payloadObject.titleId required.");
    if (!searchId) throw new Error("Property payloadObject.searchId required.");
    this.titleId = titleId;
    this.searchId = searchId;
    this.ratecardId = ratecardId;
    this.created = created ? moment(created) : null;
    this.rateType = rateType;
    this.isDraft = isDraft;
    this.industry = industry;
    this.location = location;
    this.countryCode = countryCode;
    this.currencySymbol = currencySymbol;
    this.rates = rates
      ? rates.map((ratePayload) => new LibraryTitleLatestRates(ratePayload))
      : [];
    this.rates.sort((r1, r2) => {
      // sort rates ascending by level id: 1, 2, 3, 4, 5
      if (r1.levelId > r2.levelId) return 1;
      if (r1.levelId < r2.levelId) return -1;
      return 0;
    });
  }
}

class ClientJobLibrary {
  store: mixed;
  id: ?string;
  name: ?string;

  constructor(store: mixed, payloadObject: Object) {
    this.store = store;
    this.id = null;
    this.name = null;

    if (payloadObject) {
      this.id = payloadObject.databaseId;
      this.name = payloadObject.name;
    }
  }
}

export default class JobLibraryTitle {
  store: Object;
  network: NetworkState;
  viewState: {
    selected: boolean,
    expanded: boolean,
    editing: boolean,
  };

  id: number;
  status: ?string;
  isCertified: ?boolean;
  categoryName: ?string;
  categoryId: ?number;
  created: ?moment$Moment;
  createdDisplay: ?string;
  modified: ?moment$Moment;
  updatedDisplay: ?string;
  shouldBeSelected: ?boolean;
  totalCertifiedCountries: ?number;
  totalSearchableCountries: ?number;
  clientRawJobTitle: ?RawJobTitle;
  mappedRawJobTitle: ?RawJobTitle;
  libraries: Array<ClientJobLibrary>;
  certifiedCountries: Array<CCCCountry>;
  adHocCountries: Array<CCCCountry>;
  latestSearch: ?LibraryTitleLatestSearch;

  constructor(store: Object, payloadObject: Object) {
    this.store = store;
    this.network = new NetworkState();

    const {
      databaseId,
      status,
      isCertified,
      category,
      created,
      modified,
      shouldBeSelected,
      totalCertifiedCountries,
      totalSearchableCountries,
      clientLibraries,
      certifiedCountries,
      adhocCountries,
      clientRawJobTitle,
      mappedRawJobTitle,
    } = payloadObject;

    const { categoryName, categoryId } = category || {};

    if (!databaseId) throw new Error("Property payloadObject.databaseId required.");

    let libraries = [];
    if (clientLibraries) {
      libraries = clientLibraries.edges.map((edge) => {
        return new ClientJobLibrary(store, edge.node);
      });
    }

    let certCountries = [];
    if (certifiedCountries) {
      certCountries = payloadObject.certifiedCountries.edges.map((edge) => {
        return new CCCCountry(store, edge.node.country);
      });
    }

    let ahCountries = [];
    if (adhocCountries) {
      ahCountries = payloadObject.adhocCountries.edges.map((edge) => {
        return new CCCCountry(store, edge.node.country);
      });
    }

    this.id = databaseId;
    this.status = status;
    this.isCertified = isCertified;
    this.categoryName = categoryName;
    this.categoryId = categoryId;
    this.created = created ? moment(created) : null;
    this.createdDisplay = this.created ? this.created.format("MM/DD/YYYY") : "Unknown";
    this.modified = modified ? moment(modified) : null;
    this.updatedDisplay = this.modified ? this.modified.format("MM/DD/YYYY") : "Unknown";
    this.shouldBeSelected = shouldBeSelected;
    this.totalCertifiedCountries =
      totalCertifiedCountries >= 0 ? totalCertifiedCountries : null;
    this.totalSearchableCountries =
      totalSearchableCountries >= 0 ? totalSearchableCountries : null;
    this.clientRawJobTitle = new RawJobTitle(store, clientRawJobTitle);
    this.mappedRawJobTitle = new RawJobTitle(store, mappedRawJobTitle);
    this.libraries = libraries;
    this.certifiedCountries = certCountries;
    this.adHocCountries = ahCountries;

    extendObservable(this, {
      viewState: observable({
        selected: false,
        expanded: false,
        editing: false,
      }),
      latestSearch: null,
    });
  }

  toggleExpanded = action(() => {
    this.viewState.expanded = !this.viewState.expanded;
    if (this.viewState.expanded) {
      this.getClientRawJobTitleDescription();
    }
  });

  toggleEdit = action(() => {
    const viewState = this.store.clientJobTitlesViewState.get(this.id);

    viewState.editing = !viewState.editing;
  });

  toggleSelected = action(
    (e, ignoreThisParam1 = null, ignoreThisParam2 = null, setValue = null) => {
      const viewState = this.store.clientJobTitlesViewState.get(this.id);

      if (setValue !== null) {
        viewState.selected = setValue;
        return;
      } else {
        viewState.selected = !viewState.selected;

        if (!viewState.selected && this.store.allSelected) {
          this.store.allSelected = false;
        }
      }

      if (!this.store.allowMultipleItemSelection) {
        // deselect all other rate cards
        this.store.clientJobTitlesViewState.forEach((viewState) => {
          if (this.viewState === viewState) return;

          viewState.selected = false;
        });
      }
    }
  );

  destroy = action(() => {
    this.store.clientJobTitles.remove(this);
  });

  toJS = () => {
    return {
      id: this.id,
      name: this.clientRawJobTitle ? this.clientRawJobTitle.title : "Unknown",
    };
  };

  getClientRawJobTitleDescription = action(async () => {
    if (this.network.loading) return;

    if (this.clientRawJobTitle && this.clientRawJobTitle.description) return;

    const query = `
    query getRawJobTitleDescription($databaseId: ID!) {
      clientJobTitle(databaseId: $databaseId) {
        certifiedCountries(order: [{field: COUNTRY}]) {
          edges {
            node {
              country {
                databaseId
                title
                iso3166Alpha2
              }
            }
          }
        }
        adhocCountries(order: [{field: COUNTRY}]) {
          edges {
            node {
              country {
                databaseId
                title
                iso3166Alpha2
              }
            }
          }
        }
        clientRawJobTitle {
          description
        }
      }
    }
    `;
    const variables = {
      databaseId: this.id,
    };

    this.network.loading = true;

    let payload = null;
    try {
      payload = await this.store.fetchTasteGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        // console.log('Request Canceled ClientJobTitleListStore');
        return e;
      }

      this.network.handleError("Getting Client Raw Job Title Description", e);
      if (payload !== null) {
        this.network.logGraphQLError(
          "Get Client Raw Job Title Description query",
          payload
        );
      }

      // TODO: Display user friendly error message
      return e;
    }

    runInAction("getClientRawJobTitleDescription--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (
        this.network.logGraphQLError(
          "Get Client Raw Job Title Description query",
          payload
        )
      ) {
        // TODO: Display user friendly error message
        return { totalCount: 0, startCursor: "", endCursor: "" };
      }

      this.clientRawJobTitle.description =
        payload.data.clientJobTitle.clientRawJobTitle.description;

      if (payload.data.clientJobTitle.certifiedCountries) {
        this.certifiedCountries =
          payload.data.clientJobTitle.certifiedCountries.edges.map((edge) => {
            return new CCCCountry(this.store, edge.node.country);
          });
      }

      if (payload.data.clientJobTitle.adhocCountries) {
        this.adHocCountries = payload.data.clientJobTitle.adhocCountries.edges.map(
          (edge) => {
            return new CCCCountry(this.store, edge.node.country);
          }
        );
      }
    });
  });

  static fromJS(store: mixed, object: Object) {
    return new JobLibraryTitle(store, object);
  }
}
