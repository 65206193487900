// @flow
import { extendObservable, action } from "mobx";
import FilterObject, { ValueSetFilter, FilterCriteriaLoader, IViewItem } from "../Filter";
import type { FilterColumn } from "../Filter";
import { GraphQLQuery } from "../GraphQL";
import type { FetchGraphQL } from "../../App";

class LibraryItem implements IViewItem {
  id: string;
  databaseId: number;
  name: string;
  selected: boolean;

  constructor(object: Object) {
    this.id = object.id;
    this.databaseId = object.databaseId;
    this.name = object.name;

    extendObservable(this, {
      selected: object.selected,
    });
  }
}

export default class ClientJobTitleLibraryFilter extends FilterCriteriaLoader(
  ValueSetFilter()
) {
  fetchGraphQL: FetchGraphQL;

  constructor(
    store: Object,
    column: FilterColumn,
    filterCriteriaQuery: (FilterColumn) => GraphQLQuery,
    processPayload: (FilterColumn, Object) => Array<Object>,
    applyFilter: (FilterColumn, FilterObject) => void,
    removeFilter: (FilterColumn) => void,
    reloadCriteria: boolean = false
  ) {
    super(store, column);

    this.fetchGraphQL = store.fetchGraphQL;

    this.filterCriteriaQuery = filterCriteriaQuery;
    this.processPayload = processPayload;
    this.applyFilter = applyFilter;
    this.removeFilter = removeFilter;

    this.reloadCriteria = reloadCriteria;

    this.viewItemClass = LibraryItem;

    // $FlowFixMe: Need to type these properties as invariant
    this.buildQueryFilter = action(this.buildQueryFilter.bind(this));
    // $FlowFixMe: Need to type these properties as invariant
    this.onInstantSearch = action(this.onInstantSearch.bind(this));
  }

  onInstantSearch(value: string) {
    if (super.onInstantSearch) super.onInstantSearch(value);

    if (!this.instantSearchValue) {
      this.viewItems = this.unfilteredViewItems;
      return;
    }

    this.viewItems = this.unfilteredViewItems.filter((library) => {
      const libName = library.name.toLowerCase();
      const query = this.instantSearchValue.toLowerCase();

      // this works because ~ is the binary inverse of a number and ~ -1 = 0
      return ~libName.indexOf(query);
    });
  }

  buildQueryFilter() {
    const libraryIds = this.selectedValues
      .entries()
      .filter((entry) => entry[1] === true)
      .map((entry) => this.criteria.get(entry[0]).id);

    if (!libraryIds.length) {
      return null;
    }

    let params = [];
    let args = [];
    let vars = {};

    if (libraryIds.length) {
      params.push("$libraryIds: [ID]!");
      args.push("inAnyLibrary: $libraryIds");
      vars.libraryIds = libraryIds;
    }

    return new FilterObject(params.join(", "), args.join(", "), vars);
  }
}
