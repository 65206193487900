import React from "react";

import AlertWithButton from "../../../components/AlertWithButton";
import { useReviewDataContext } from "../context/ReviewDataContext";

type ReplacementsAlertProps = {
  onClick: () => void;
};

export const ReplacementsAlert = (props: ReplacementsAlertProps) => {
  const { onClick } = props;
  const { jobsData } = useReviewDataContext();

  const replacementsResolved = React.useMemo(
    () =>
      jobsData
        .toArray()
        .map((job) => !!job.get("replacements_resolved", true))
        .reduce((a, i) => a && i, true),
    [jobsData]
  );

  if (replacementsResolved) return null;

  return (
    <AlertWithButton
      head="Missed replacements found!"
      body='Try to resolve them manually by editing Job Title/Description. Use "Resolve" button to mark replacements as resolved.'
      buttonIcon="check"
      buttonText="Resolve"
      onButtonClick={onClick}
    />
  );
};
ReplacementsAlert.displayName = "ReplacementsAlert";

export const SurveysGroupAlert = () => {
  return (
    <AlertWithButton
      head="This survey is a part of a group."
      body={<a href="#reviews-group">See other surveys in the group.</a>}
      noButton
    />
  );
};
SurveysGroupAlert.displayName = "SurveysGroupAlert";
