// @flow

import React, { Component } from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  hasLoaded: boolean,
  isLoading?: boolean,
  pageCount: number,
  pagesToShow: number,
  currentPage: number,
  begin: number,
  end: number,
  isEndRange: boolean,
  handleNextPage: () => void,
  handlePrevPage: () => void,
  handleLastPage: () => void,
  handleFirstPage: () => void,
};

class Pagination extends Component<Props> {
  props: Props;

  nextPage: () => void;
  prevPage: () => void;
  goFirstPage: () => void;
  goLastPage: () => void;

  constructor(props: Props) {
    super(props);

    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this.goFirstPage = this.goFirstPage.bind(this);
    this.goLastPage = this.goLastPage.bind(this);
  }

  nextPage() {
    if (
      this.props.isLoading ||
      this.props.pageCount === 0 ||
      this.props.currentPage === this.props.pageCount
    ) {
      return;
    }

    const { handleNextPage } = this.props;

    handleNextPage();
  }

  prevPage() {
    if (this.props.isLoading || this.props.currentPage === 1) {
      return;
    }

    const { handlePrevPage } = this.props;

    if (handlePrevPage) {
      handlePrevPage();
    }
  }

  goFirstPage() {
    if (this.props.isLoading || this.props.currentPage === 1) {
      return;
    }

    const { handleFirstPage } = this.props;

    if (handleFirstPage) {
      handleFirstPage();
    }
  }

  goLastPage() {
    if (this.props.isLoading || this.props.currentPage === this.props.pageCount) {
      return;
    }

    const { handleLastPage } = this.props;

    if (handleLastPage) {
      handleLastPage();
    }
  }

  render() {
    const { begin, end, isEndRange, pageCount } = this.props;

    let pages = [];

    if (this.props.hasLoaded === false) {
      return null;
    }

    if (isEndRange) {
      for (let i = begin; i <= end; i++) {
        if (i === this.props.currentPage) {
          pages.push(
            <div key={i} className="cjl-page-box active">
              {i}
            </div>
          );
        } else {
          pages.push(
            <div key={i} className="cjl-page-box">
              {i}
            </div>
          );
        }
      }
    } else {
      let midpoint = Math.ceil((end - begin) / 2) + begin;

      for (let i = begin; i <= midpoint; i++) {
        if (i === this.props.currentPage) {
          pages.push(
            <div key={i} className="cjl-page-box active">
              {i}
            </div>
          );

          continue;
        }

        pages.push(
          <div key={i} className="cjl-page-box">
            {i}
          </div>
        );
      }

      pages.push(
        <div key={"collapsed"} className="cjl-page-box">
          <div className="cjl-page-box collapsed">•••</div>
        </div>
      );
      pages.push(
        <div key={pageCount} className="cjl-page-box">
          {pageCount}
        </div>
      );
    }

    return (
      <div className="cjl-pagination-container">
        <div className="cjl-first-page" onClick={this.goFirstPage}>
          <FontAwesomeIcon icon="angle-double-left" className="arrow" />
          <span className="cjl-first-page-text">First</span>
        </div>
        <div className="separator" />
        <div className="cjl-prev-page" onClick={this.prevPage}>
          <FontAwesomeIcon icon="angle-left" className="arrow" />
          <span className="cjl-first-page-text">Prev</span>
        </div>
        <div className="separator" />
        {pages}
        {this.props.pageCount > 0 && <div className="separator" />}
        <div className="cjl-next-page" onClick={this.nextPage}>
          <span className="cjl-first-page-text">Next</span>
          <FontAwesomeIcon icon="angle-right" className="arrow" />
        </div>
        <div className="separator" />
        <div className="cjl-last-page" onClick={this.goLastPage}>
          <span className="cjl-last-page-text">Last</span>
          <FontAwesomeIcon icon="angle-double-right" className="arrow" />
        </div>
      </div>
    );
  }
}

export default observer(Pagination);
