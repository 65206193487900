import { action } from "mobx";
import moment from "moment";

export default class UserList {
  constructor(store, object) {
    this.store = store;
    this.userId = null;
    this.firstName = null;
    this.middleName = null;
    this.lastName = null;
    this.active = null;
    this.email = null;
    this.secondaryEmail = null;
    this.shareRateCards = null;
    this.client = null;
    this.roles = null;
    this.allowedCountries = null;
    this.phoneNumber = null;
    this.alterPhoneNumber = null;
    this.address = null;
    this.apartment = null;
    this.city = null;
    this.state = null;
    this.postalCode = null;
    this.country = null;
    this.username = null;
    this.lastLogin = null;
    this.totalSearchesCount = null;
    this.totalJobsCount = null;
    this.totalRatecardsCount = null;
    this.totalProjectsCount = null;
    this.totalBuyratesCount = null;
    this.dateJoined = null;
    this.viewState = {
      selected: false,
      editing: false,
    };

    this.termsOfAgreement = null;
    this.freeTrial = null;

    if (object) {
      this.userId = object.userId || null;
      this.firstName = object.firstName || "";
      this.middleName = object.middleName || "";
      this.lastName = object.lastName || "";
      this.active = object.isActive || false;
      this.email = object.email || "";
      this.termsOfAgreement = object.termsOfAgreement || false;
      this.shareRateCards = object.shareRateCards || false;
      this.freeTrial = object.freeTrial || false;
      this.username = object.username || null;
      if (object.lastLogin)
        this.lastLogin = `${moment(object.lastLogin).format("MMMM D, YYYY")}`;
      if (object.dateJoined)
        this.dateJoined = `${moment(object.dateJoined).format("MMMM D, YYYY")}`;
      this.shareRateCards = object.shareRateCards || false;
      this.totalSearchesCount = object.totalSearchesCount || 0;
      this.totalJobsCount = object.totalJobsCount || 0;
      this.totalRatecardsCount = object.totalRatecardsCount || 0;
      this.totalProjectsCount = object.totalProjectsCount || 0;
      this.totalBuyratesCount = object.totalBuyratesCount || 0;
      this.client = [object.client];
      this.allowedCountries = object.allowedCountries;
      this.roles = object.roles;
      this.phoneNumber = object.phoneNumber || "";
      this.alterPhoneNumber = object.alterPhoneNumber || "";
      this.secondaryEmail = object.secondaryEmail || "";
      this.address = object.address || "";
      this.apartment = object.apartment || "";
      this.city = object.city || "";
      this.state = object.state || "";
      this.country = object.country || "";
      this.postalCode = object.postalCode || "";
    }

    this.toggleEdit = action(this.toggleEdit.bind(this));
    this.toggleSelected = action(this.toggleSelected.bind(this));
    this.destroy = action(this.destroy.bind(this));
  }

  toggleEdit() {
    const viewState = this.store.usersListViewState.get(this.userId);
    viewState.editing = !viewState.editing;
  }

  toggleSelected(e, ignore1 = null, ignore2 = null, setValue = null) {
    const viewState = this.store.usersListViewState.get(this.userId);

    if (setValue !== null) {
      viewState.selected = setValue;
      return;
    } else {
      viewState.selected = !viewState.selected;

      if (!viewState.selected && this.store.allSelected) {
        this.store.allSelected = false;
      }
    }

    if (viewState.selected === false) {
      this.store.unSelectedUsers.push(this.userId.toString());
    } else {
      for (var i = 0; i < this.store.unSelectedUsers.length; i++) {
        if (this.store.unSelectedUsers[i] === this.userId) {
          this.store.unSelectedUsers.splice(i, 1);
        }
      }
    }

    if (!this.store.allowMultipleItemSelection) {
      // deselect all other jobs
      this.store.usersListViewState.forEach((viewState) => {
        if (this.viewState === viewState) return;

        viewState.selected = false;
      });
    }
  }

  destroy() {
    this.store.userLists.remove(this);
  }

  toJS() {
    return {
      id: this.id,
      name: this.name,
    };
  }

  static fromJS(store, object) {
    return new UserList(store, object);
  }
}
