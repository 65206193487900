// @flow

import R from "ramda";
import { extendObservable, action, runInAction, computed, observable } from "mobx";
import axios from "axios";
import PaginationState from "../../../models/PaginationState";
import NetworkState from "../../../models/NetworkState";
import type { PageQuery, PaginationInfo } from "../../../models/PaginationState";
import ScheduledSearchList from "../../../models/ScheduledSearchList";
import FilterObject, { FILTER_COLUMN } from "../../../models/Filter";
import { ScheduleSearchFilter } from "../../../models/FilterState";
import { consolidateAppliedFilters } from "../SupportFunctions";
import ModalState from "../../../models/ModalState";
import type { FetchGraphQL } from "../../../App";

export class ScheduledSearchListComponentStore {
  showHelpModal: boolean;
  getScheduledSearches: (PageQuery) => Promise<PaginationInfo>;
  network: NetworkState;
  pagination: PaginationState;
  totalCount: any;
  scheduledSearchList: ScheduledSearchList[];
  scheduledSearchListView: ScheduledSearchList[];
  scheduledSearchListViewState: Object;
  scheduleFilter: ScheduleSearchFilter;

  defaultFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedSorts: {
    [key: FilterColumn]: Sort,
  };
  isFiltered: boolean;
  appliedSortsOrder: Array<FilterColumn>;
  unSelectedSchedules: [];

  showHelp: () => void;
  hideHelp: () => void;
  applyDefaultFilter: (FilterColumn, FilterObject) => void;
  applyFilter: (FilterColumn, FilterObject) => void;
  removeFilter: (FilterColumn) => void;
  removeSort: (FilterColumn) => void;
  clearFilters: () => void;
  isEditing: ?boolean;
  handleStartEdit: () => void;
  handleStopEdit: () => void;
  toggleSelectAllPage: (Object) => void;
  selectAllPage: (Event) => void;
  deselectAllPage: (Event) => void;
  clearAllSelections: () => void;
  allSelected: boolean;
  allSelectedfilter: boolean;
  allOnPageSelected: boolean;
  allowMultipleItemSelection: boolean;
  confirmDeleteModal: ModalState;
  helpModal: ModalState;
  getSelectedScheduledList: () => Array<string>;
  fetchGraphQL: FetchGraphQL;

  constructor(fetchGraphQL: FetchGraphQL) {
    this.fetchGraphQL = fetchGraphQL;
    this.getScheduledSearches = action(this.getScheduledSearches.bind(this));

    this.applyFilter = action(this.applyFilter.bind(this));
    this.applySort = action(this.applySort.bind(this));
    this.removeFilter = action(this.removeFilter.bind(this));
    this.removeSort = action(this.removeSort.bind(this));
    this.unSelectedSchedules = [];

    extendObservable(this, {
      helpModal: new ModalState(),
      network: new NetworkState(),
      pagination: new PaginationState(this.getScheduledSearches),
      totalCount: 0,
      scheduledSearchList: [],
      allSelected: false,
      allSelectedfilter: false,
      scheduledSearchListViewState: observable.map({}),
      scheduledSearchListView: computed(() => {
        return this.scheduledSearchList.map((schedule) => {
          if (this.scheduledSearchListViewState.has(schedule.uuid)) {
            schedule.viewState = this.scheduledSearchListViewState.get(schedule.uuid);

            return schedule;
          }

          return schedule;
        });
      }),
      allOnPageSelected: computed(() => {
        const allTrue = R.all(R.equals(true));
        const selectedValues = this.scheduledSearchListView.map(
          (scheduledSearchListView) => scheduledSearchListView.viewState.selected
        );

        if (selectedValues.length === 0) {
          return false;
        }

        return allTrue(selectedValues);
      }),
      appliedSortsOrder: observable.shallow([]),
      scheduleFilter: new ScheduleSearchFilter(
        this,
        FILTER_COLUMN.SCHEDULE_LABEL,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      defaultFilters: {},
      appliedFilters: {},
      appliedSorts: {},
      isFiltered: false,
      isEditing: null,
      allowMultipleItemSelection: true,
      selectedCount: computed(() => {
        const selectedValues = this.scheduledSearchListView.map(
          (schedule) => schedule.viewState.selected
        );

        if (this.allSelected) {
          return this.pagination.totalCount;
        }

        let count = 0;

        selectedValues.forEach((v) => {
          if (v) {
            count += 1;
          }
        });

        return count;
      }),
      confirmDeleteModal: new ModalState(),
    });

    this.handleStartEdit = action(this.handleStartEdit.bind(this));
    this.handleStopEdit = action(this.handleStopEdit.bind(this));
    this.toggleSelectAllPage = action(this.toggleSelectAllPage.bind(this));
    this.selectAllPage = action(this.selectAllPage.bind(this));
    this.deselectAllPage = action(this.deselectAllPage.bind(this));
    this.clearAllSelections = action(this.clearAllSelections.bind(this));
    this.getSelectedScheduledList = action(this.getSelectedScheduledList.bind(this));
    this.showHelp = action(this.showHelp.bind(this));
    this.hideHelp = action(this.hideHelp.bind(this));
  }

  showHelp() {
    this.showHelpModal = true;
  }

  hideHelp() {
    this.showHelpModal = false;
  }

  handleStartEdit() {
    this.isEditing = true;
    this.scheduledSearchListView.forEach((schedule) => schedule.toggleEdit());
  }

  handleStopEdit() {
    this.isEditing = false;
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.scheduledSearchListViewState.forEach((viewState) => {
      viewState.selected = false;
      viewState.editing = false;
    });
  }

  toggleSelectAllPage(e: Object) {
    if (!this.allowMultipleItemSelection) return;

    const setValue = !this.allOnPageSelected;

    this.scheduledSearchListView.forEach((schedule) => {
      schedule.toggleSelected(e, null, null, setValue);
    });

    // When All items selected flag is up, clear selection
    if (setValue === false && this.allSelected) this.allSelected = false;
  }

  selectAllPage(e: Event) {
    this.unSelectedSchedules = [];
    this.scheduledSearchListView.forEach((schedule) => {
      schedule.toggleSelected(e, null, null, true);
    });

    this.allSelected = true;
    this.allSelectedfilter = true;
  }

  deselectAllPage(e: Event) {
    this.scheduledSearchListView.forEach((schedule) => {
      schedule.toggleSelected(e, null, null, false);
    });

    this.allSelected = false;
    this.allSelectedfilter = false;
  }

  clearAllSelections() {
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.scheduledSearchListViewState.forEach((value) => {
      value.selected = false;
    });
  }

  applyFilter(column: FilterColumn, filter: FilterObject) {
    this.appliedFilters[column] = filter;
    this.isFiltered = true;
  }

  /*applyDefaultFilter(column: FilterColumn, filter: FilterObject) {
    this.defaultFilters[column] = filter;
  }*/

  removeFilter(column: FilterColumn) {
    delete this.appliedFilters[column];

    let entries = Object.entries(this.appliedFilters);
    if (!entries.length) this.isFiltered = false;
  }

  applySort(column: FilterColumn, sort: Sort) {
    this.appliedSorts[column] = sort;

    const index = this.appliedSortsOrder.indexOf(column);
    if (index === -1) this.appliedSortsOrder.push(column);
  }

  removeSort(column: FilterColumn) {
    delete this.appliedSorts[column];

    const index = this.appliedSortsOrder.indexOf(column);
    if (index > -1) this.appliedSortsOrder.splice(index, 1);
  }

  //clear all filters
  clearFilters() {
    this.scheduleFilter = new ScheduleSearchFilter(
      this,
      FILTER_COLUMN.SCHEDULE_LABEL,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );

    this.appliedFilters = observable({});
    this.appliedSorts = observable({});
    this.appliedSortsOrder.length = 0;
    this.isFiltered = false;

    return this.pagination.goFetch(null);
  }

  toggleAllItems() {
    if (!this.allowMultipleItemSelection) return;

    this.allSelected = !this.allSelected;

    if (this.allSelected === false) {
      this.projectCostsView.forEach((value) => {
        value.viewState.selected = false;
      });
    }
  }

  getSelectedScheduledList(): Array<string> {
    const scheduledSearchList = this.scheduledSearchListViewState;

    let selectedScheduleList = [];

    scheduledSearchList.forEach((value, key) => {
      if (value.selected) {
        selectedScheduleList.push(key);
      }
    });

    return selectedScheduleList;
  }

  //function for get region list
  async getScheduledSearches(pageQuery: PageQuery): Promise<PaginationInfo> {
    let result = null;
    let params: string[] = pageQuery.params;
    let args = pageQuery.args;
    let variables = pageQuery.variables;
    let filtersCriteria: string[] = [];

    consolidateAppliedFilters(this.appliedFilters, params, filtersCriteria, variables);

    // NOTE: applied filters could override default filters, handle this if needed
    consolidateAppliedFilters(this.defaultFilters, params, filtersCriteria, variables);

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");
    const queryFiltersCriteria = filtersCriteria.join(", ");

    const query = `
      query getScheduledSearches(${queryParams}){
          viewer{
            scheduledSearches(${queryArgs}, ${queryFiltersCriteria} ){
              totalCount
              results{
                uuid
                rateCardId
                rateCardLabel
                jobsTotal
                created
                statusDisplay
              }
            }
          }
        }
      `;

    this.network.loading = true;

    try {
      result = await this.fetchGraphQL(query, variables);
    } catch (e) {
      console.log("error", e);
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting Scheduled Search", e);
      if (result !== null) {
        this.network.logGraphQLError("Get Scheduled Search query", result);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getScheduledSearches--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get Scheduled Searches query", result)) {
        // TODO: Display user friendly error message
        return {
          totalCount: 0,
        };
      }

      const schedules = result.data.viewer.scheduledSearches.results;
      this.totalCount = result.data.viewer.scheduledSearches.totalCount;
      // // TODO: Deserialize this properly...

      this.scheduledSearchList = schedules.map((sch) => {
        const schedule = new ScheduledSearchList(this, sch);
        if (!this.scheduledSearchListViewState.has(schedule.uuid)) {
          this.scheduledSearchListViewState.set(schedule.uuid, {
            selected: this.allSelected,
            editing: this.isEditing,
          });
        } else {
          const selectedValue = this.allSelected
            ? true
            : this.scheduledSearchListViewState.get(schedule.uuid).selected;

          this.scheduledSearchListViewState.set(schedule.uuid, {
            selected: selectedValue,
            editing: this.isEditing,
          });
        }

        schedule.viewState = this.scheduledSearchListViewState.get(schedule.uuid);
        return schedule;
      });

      return {
        totalCount: result.data.viewer.scheduledSearches.totalCount,
      };
    });
  }
}

export default class ScheduledSearchListStore extends ScheduledSearchListComponentStore {}
