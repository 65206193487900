/**
 * @generated SignedSource<<ab36dedddda410d161c43d7bf6a5a22c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LocationCountryFiltersInput = {
  active?: boolean | null;
  anyPropertyIContains?: string | null;
  apiLocationIdIn?: ReadonlyArray<string | null> | null;
  exclude?: ReadonlyArray<string | null> | null;
  nameIContains?: number | null;
  only?: ReadonlyArray<string | null> | null;
};
export type RsLocationSearchDialogCountryQuery$variables = {
  filters?: LocationCountryFiltersInput | null;
};
export type RsLocationSearchDialogCountryQuery$data = {
  readonly viewer: {
    readonly countries: ReadonlyArray<{
      readonly isoCode: string | null;
      readonly locationId: number | null;
      readonly name: string | null;
    } | null> | null;
  } | null;
};
export type RsLocationSearchDialogCountryQuery = {
  response: RsLocationSearchDialogCountryQuery$data;
  variables: RsLocationSearchDialogCountryQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locationId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isoCode",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RsLocationSearchDialogCountryQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "CountryListNode",
            "kind": "LinkedField",
            "name": "countries",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RsLocationSearchDialogCountryQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "CountryListNode",
            "kind": "LinkedField",
            "name": "countries",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2c78b2522eefe779512290b0edf9cab1",
    "id": null,
    "metadata": {},
    "name": "RsLocationSearchDialogCountryQuery",
    "operationKind": "query",
    "text": "query RsLocationSearchDialogCountryQuery(\n  $filters: LocationCountryFiltersInput\n) {\n  viewer {\n    countries(filters: $filters) {\n      name\n      locationId\n      isoCode\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "55bd7a231cf594522dbb6270bf98ec52";

export default node;
