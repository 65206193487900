import React, { useCallback } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";

// @ts-expect-error
import Messager from "../../../components/Messager";
import Box from "../../../components/lib/Box";
import Stack from "../../../components/lib/Stack";
import Alert from "../../../components/lib/Alert";
import { ButtonGroupRight } from "../../../components/lib/ButtonGroup";
import { NavigationButton, Button } from "../../../components/lib/Button";
import { emptySet } from "../../../constants";
import { defaultEmptyProgram, programGlobalState } from "../globalState";
import { programToImmutableMap } from "../dataConverters";
import ProgramEditorForm, {
  EDITOR_MODES,
  FormFieldKey,
} from "../components/ProgramEditorForm";
import { useMessagerRef } from "../hooks";
// @ts-expect-error
import { logAsyncOperationError } from "../../../utils/logging";
import { TickerContentLoader } from "../../../components/lib/TickerLoader";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderTitle,
} from "../../../components/lib/Card";

import type { PROGRAM_STATUSES_TYPE, Values } from "../types";
import type { ProgramDataMap, ProgramDataObject } from "../types";
import type { ImmutableMap, ImmutableSet } from "../../../types/immutable";
import type { CommonChildPageProps } from "../PrivateIndex";
import type { FetchAPIResponse } from "../../../types/fetch";

const EDITOR_STATES = {
  INITIAL: "initial",
  SUCCESS: "success",
  FAILURE: "failure",
} as const;

type EDITOR_STATES_TYPE = Values<typeof EDITOR_STATES>;

const CARD_STYLES = {
  [EDITOR_STATES.INITIAL]: {},
  [EDITOR_STATES.SUCCESS]: { backgroundColor: "$successLightest", color: "$successDark" },
  [EDITOR_STATES.FAILURE]: { backgroundColor: "$dangerLightest", color: "$dangerDark" },
};

type IsEmptyPredicate<T> = (value: T) => boolean;

const formFieldsKeys: FormFieldKey[] = ["title", "description", "icon", "status"];
const requiredFormFieldsKeys: FormFieldKey[] = ["title", "description", "status"];
const formFieldsEmptyPredicates: {
  [K in FormFieldKey]: IsEmptyPredicate<ProgramDataObject[K]>;
} = {
  title: (value: string) => !value,
  description: (value: string) => !value,
  icon: (value: string) => value == null,
  status: (value: PROGRAM_STATUSES_TYPE) => value == null,
};
const fieldRequiredPredicate = (
  value: ProgramDataObject[FormFieldKey],
  key: FormFieldKey
): boolean => {
  return requiredFormFieldsKeys.indexOf(key) >= 0;
};
const fieldNotEmptyPredicate = (
  value: ProgramDataObject[FormFieldKey],
  key: FormFieldKey
): boolean => {
  return (
    formFieldsKeys.indexOf(key) >= 0 &&
    !(
      formFieldsEmptyPredicates[key] as IsEmptyPredicate<ProgramDataObject[FormFieldKey]>
    )(value)
  );
};
const checkRequiredFields = (programData: ProgramDataMap | ChangedProgramDataMap) => {
  return (
    // @ts-expect-error
    programData.filter(fieldRequiredPredicate).filter(fieldNotEmptyPredicate).size ===
    requiredFormFieldsKeys.length
  );
};

type ProgramDataState = {
  programData: ProgramDataMap;
  programDataLoaded: boolean;
};

type ChangedProgramDataObject = Omit<ProgramDataObject, "icon"> & { icon: string | File };
type ChangedProgramDataMap = ImmutableMap<ChangedProgramDataObject>;

type ChangedProgramdataState = {
  changedProgramData: ChangedProgramDataMap;
  changedFieldKeys: ImmutableSet<FormFieldKey>;
};

const ProgramEditor = (props: CommonChildPageProps) => {
  const {
    params,
    clientId,
    userId,
    userName,
    isPTAdmin,
    fetchM8API,
    router,
    showModalError,
  } = props;
  const programId =
    params.programId != null ? parseInt("" + params.programId, 10) : undefined;

  // global program state

  const [{ programData, programDataLoaded }, setProgramGlobalState] =
    useRecoilState<ProgramDataState>(programGlobalState);
  const resetProgramGlobalState = useResetRecoilState(programGlobalState);

  // editor local state

  const [editorState, setEditorState] = React.useState<EDITOR_STATES_TYPE>(
    EDITOR_STATES.INITIAL
  );
  const [{ changedProgramData, changedFieldKeys }, setProgramState] =
    React.useState<ChangedProgramdataState>({
      changedProgramData: programData,
      changedFieldKeys: emptySet, // Set(['title', 'description'])
    });
  const hasProgramChanges = changedFieldKeys.size > 0;

  // data fetch functions

  const fetchProgramData = useCallback(async () => {
    try {
      const response: FetchAPIResponse<ProgramDataObject> = await fetchM8API(
        `programs/${programId}/`
      );
      const data: ProgramDataMap = programToImmutableMap(response.data);

      return data;
    } catch (err: any) {
      logAsyncOperationError("fetchProgramData", err);
      showModalError(`Error occurred while loading program #${programId} data.`);
    }
  }, [programId, fetchM8API, showModalError]);

  // initial data fetch and final cleanup

  React.useEffect(() => {
    if (programId != null) {
      fetchProgramData().then((data) => {
        if (data != null) {
          setProgramGlobalState({
            programData: data,
            programDataLoaded: true,
          });
          setProgramState({
            changedProgramData: data,
            changedFieldKeys: emptySet,
          });
        }
      });
    } else {
      setProgramGlobalState((prevState) => ({
        ...prevState,
        programDataLoaded: true,
      }));
    }

    return resetProgramGlobalState;
  }, [
    programId,
    fetchProgramData,
    setProgramGlobalState,
    resetProgramGlobalState,
    setProgramState,
  ]);

  // messaging

  const { messagerRef, showError, showSuccess, showHint } = useMessagerRef();

  // handlers

  const handleGoBackToProgramsList = useCallback(() => {
    router.push("/private-index/programs");
  }, [router]);

  const handleChangeProgramAttr = useCallback(
    (key: FormFieldKey, value: "" | string | PROGRAM_STATUSES_TYPE | File) => {
      setProgramState({
        changedProgramData: changedProgramData.set(key, value),
        changedFieldKeys: changedFieldKeys.add(key),
      });
    },
    [changedProgramData, changedFieldKeys, setProgramState]
  );

  const fieldChangedPredicate = useCallback(
    (value: ProgramDataObject[FormFieldKey], key: FormFieldKey) => {
      return changedFieldKeys.includes(key);
    },
    [changedFieldKeys]
  );

  const handleUpdateProgramIcon = useCallback(
    async (
      programId: number,
      iconBlob: string,
      iconMimeType: string,
      iconFileName: string
    ) => {
      try {
        const response: FetchAPIResponse<ProgramDataObject> = await fetchM8API(
          `programs/${programId}/change_icon/`,
          {
            method: "post",
            data: iconBlob,
            params: { file_name: iconFileName },
            headers: { "content-type": iconMimeType },
          }
        );
        const data: ProgramDataMap = programToImmutableMap(response.data);

        return data;
      } catch (err: any) {
        logAsyncOperationError("updateProgramIcon", err);
        showError("Error occurred while updating program's icon");
        setEditorState(EDITOR_STATES.FAILURE);
      }
    },
    [setEditorState, fetchM8API, showError]
  );

  const handleDeleteProgramIcon = useCallback(
    async (programId: number) => {
      try {
        const response: FetchAPIResponse<ProgramDataObject> = await fetchM8API(
          `programs/${programId}/delete_icon/`,
          {
            method: "post",
          }
        );
        const data: ProgramDataMap = programToImmutableMap(response.data);

        return data;
      } catch (err: any) {
        logAsyncOperationError("deleteProgramIcon", err);
        showError("Error occurred while deleting program's icon");
        setEditorState(EDITOR_STATES.FAILURE);
      }
    },
    [setEditorState, fetchM8API, showError]
  );

  const handleCreateNewProgram = async () => {
    if (!checkRequiredFields(changedProgramData)) {
      showError("You should provide all required parameters.");
      setEditorState(EDITOR_STATES.FAILURE);
      return;
    }

    const icon: string | File = changedProgramData.get("icon");
    const changedParameters = changedProgramData
      .filter(fieldRequiredPredicate)
      .filter(fieldChangedPredicate);
    const isIconChanged = changedFieldKeys.includes("icon");

    showHint("processing...");

    try {
      const response: FetchAPIResponse<ProgramDataObject> = await fetchM8API(
        "programs/",
        {
          method: "post",
          data: {
            client_id: clientId,
            user_id: userId,
            user_name: userName,
            ...changedParameters.toJS(),
          },
        }
      );
      let data: ProgramDataMap = programToImmutableMap(response.data);
      const programId = data.get("id");

      if (isIconChanged && icon) {
        // @ts-ignore program form uses file input as image input
        const iconBlob = (icon as File).dataUrl.split("base64,")[1];
        const iconFileName = (icon as File).name;
        const iconMimeType = (icon as File).type;

        data = (await handleUpdateProgramIcon(
          programId,
          iconBlob,
          iconMimeType,
          iconFileName
        )) as ProgramDataMap;
      }

      showSuccess("created");
      setEditorState(EDITOR_STATES.SUCCESS);
      setProgramState({
        changedProgramData: data,
        changedFieldKeys: emptySet,
      });
      setProgramGlobalState({
        programData: data,
        programDataLoaded: true,
      });
    } catch (err: any) {
      logAsyncOperationError("createProgram", err);
      showError("Error occurred while creating a new program");
      setEditorState(EDITOR_STATES.FAILURE);
    }
  };

  const handleUpdateProgram = async () => {
    if (!checkRequiredFields(changedProgramData)) {
      showError("You should provide all required parameters.");
      setEditorState(EDITOR_STATES.FAILURE);
      return;
    }

    const programId = changedProgramData.get("id");
    const icon = changedProgramData.get("icon");
    const changedParameters = changedProgramData
      .filter(fieldRequiredPredicate)
      .filter(fieldChangedPredicate);
    const isIconChanged = changedFieldKeys.includes("icon");

    showHint("processing...");

    try {
      const response: FetchAPIResponse<ProgramDataObject> = await fetchM8API(
        `programs/${programId}/`,
        {
          method: "patch",
          data: changedParameters.toJS(),
        }
      );
      let data: ProgramDataMap = programToImmutableMap(response.data);

      if (isIconChanged) {
        if (icon) {
          // @ts-ignore program form uses file input as image input
          const iconBlob = (icon as File).dataUrl.split("base64,")[1];
          let iconMimeType = (icon as File).type;
          let iconFileName = (icon as File).name;

          data = (await handleUpdateProgramIcon(
            programId,
            iconBlob,
            iconMimeType,
            iconFileName
          )) as ProgramDataMap;
        } else {
          data = (await handleDeleteProgramIcon(programId)) as ProgramDataMap;
        }
      }

      showSuccess("updated");
      setEditorState(EDITOR_STATES.SUCCESS);
      setProgramState({
        changedProgramData: data,
        changedFieldKeys: emptySet,
      });
      setProgramGlobalState({
        programData: data,
        programDataLoaded: true,
      });
    } catch (err: any) {
      logAsyncOperationError("updateProgramData", err);
      showError("Error occurred while index parameters updating.");
      setEditorState(EDITOR_STATES.FAILURE);
    }
  };

  // render no perms

  if (programDataLoaded && !isPTAdmin) {
    return (
      <Alert color="warning">
        <Stack fill css={{ alignItems: "start" }}>
          <h4>You have no permissions to access this page </h4>
          <NavigationButton
            icon="arrow-left"
            color="warning"
            onClick={handleGoBackToProgramsList}
          >
            Back to Indexes List
          </NavigationButton>
        </Stack>
      </Alert>
    );
  }

  const hasProgramData = !!(
    changedProgramData &&
    changedProgramData.size &&
    changedProgramData !== defaultEmptyProgram
  );
  const editorMode = programId != null ? EDITOR_MODES.UPDATE : EDITOR_MODES.CREATE;

  // render no program found
  if (programDataLoaded && editorMode === EDITOR_MODES.UPDATE && !hasProgramData) {
    return (
      <Alert color="warning">
        <Stack fill css={{ alignItems: "start" }}>
          <h4>No Program found </h4>
          <NavigationButton
            icon="arrow-left"
            color="warning"
            onClick={handleGoBackToProgramsList}
          >
            Back to Indexes List
          </NavigationButton>
        </Stack>
      </Alert>
    );
  }

  const originalProgramTitle = programData?.size ? programData.get("title") : "";
  const header =
    editorMode === EDITOR_MODES.CREATE
      ? "Create a new index"
      : editorMode === EDITOR_MODES.UPDATE
      ? `Edit index ${originalProgramTitle != null ? `"${originalProgramTitle}"` : "..."}`
      : "";

  return (
    <Stack>
      <Card
        css={{
          width: "100%",
          "@md": {
            maxWidth: "900px",
          },
        }}
      >
        <CardHeader css={{ ...CARD_STYLES[editorState] }}>
          <CardHeaderTitle as="h3">{header}</CardHeaderTitle>
        </CardHeader>
        <CardBody>
          <Stack css={{ alignItems: "stretch" }}>
            {!programDataLoaded && (
              <Box css={{ minHeight: "200px" }}>
                <TickerContentLoader />
              </Box>
            )}
            {programDataLoaded && (
              <ProgramEditorForm
                programId={changedProgramData.get("id")}
                title={changedProgramData.get("title")}
                description={changedProgramData.get("description")}
                icon={changedProgramData.get("icon")}
                status={changedProgramData.get("status")}
                mode={editorMode}
                invalid={editorState === EDITOR_STATES.FAILURE}
                onChange={handleChangeProgramAttr}
                showModalError={showModalError}
              />
            )}
            <ButtonGroupRight css={{ "& button": { minWidth: "150px" } }}>
              <Messager ref={messagerRef} />
              {editorMode === EDITOR_MODES.CREATE && (
                <Button
                  size="large"
                  icon="plus"
                  loadingText="Create Index"
                  onClick={handleCreateNewProgram}
                  color="brand"
                  disabled={
                    !programDataLoaded ||
                    editorState === EDITOR_STATES.SUCCESS ||
                    !hasProgramChanges
                  }
                >
                  Create Index
                </Button>
              )}
              {editorMode === EDITOR_MODES.UPDATE && (
                <Button
                  size="large"
                  icon="download"
                  loadingText="Save"
                  onClick={handleUpdateProgram}
                  color="brand"
                  disabled={!programDataLoaded || !hasProgramChanges}
                >
                  Save
                </Button>
              )}
              <Button size="large" icon="arrow-left" onClick={handleGoBackToProgramsList}>
                Back To Indexes List
              </Button>
            </ButtonGroupRight>
          </Stack>
        </CardBody>
      </Card>
    </Stack>
  );
};

ProgramEditor.displayName = "ProgramEditor";

export default ProgramEditor;
