import { Set } from "immutable";
import type {
  ImmutableMap,
  ImmutableList,
  ImmutableOrderedMap,
} from "../../types/immutable";
import type { RowData, ColumnConfigObject } from "../../components/tables/types";
import type { CurrencyData } from "../../components/selects/CurrencySelect";
import type { IconProps } from "../../components/lib/Icon";
import { Nullable } from "../../types/generic";

export type {
  ImmutableMap,
  ImmutableList,
  ImmutableOrderedMap,
} from "../../types/immutable";

export type Values<T> = T[keyof T];

export const UPLOAD_TYPES = {
  FROM_FILE: 1,
  FROM_PRIVATE_INDEX: 2,
} as const;

export type UPLOAD_TYPES_TYPE = Values<typeof UPLOAD_TYPES>;

export const UPLOAD_TYPES_LABELS = {
  [UPLOAD_TYPES.FROM_FILE]: "From File",
  [UPLOAD_TYPES.FROM_PRIVATE_INDEX]: "From Private Index",
} as const;

export const SOURCE_TYPES = {
  FROM_RATECARD: 1,
  FROM_UPLOAD: 2,
  FROM_PRIVATE_INDEX: 3,
} as const;

export type SOURCE_TYPES_TYPE = Values<typeof SOURCE_TYPES>;

export const SOURCE_TYPES_LABELS = {
  [SOURCE_TYPES.FROM_RATECARD]: "Rate Card",
  [SOURCE_TYPES.FROM_UPLOAD]: "File Upload",
  [SOURCE_TYPES.FROM_PRIVATE_INDEX]: "Private Index",
} as const;

export const SOURCE_TYPES_OPTIONS = [
  {
    value: SOURCE_TYPES.FROM_RATECARD,
    label: SOURCE_TYPES_LABELS[SOURCE_TYPES.FROM_RATECARD],
  },
  {
    value: SOURCE_TYPES.FROM_UPLOAD,
    label: SOURCE_TYPES_LABELS[SOURCE_TYPES.FROM_UPLOAD],
  },
  {
    value: SOURCE_TYPES.FROM_PRIVATE_INDEX,
    label: SOURCE_TYPES_LABELS[SOURCE_TYPES.FROM_PRIVATE_INDEX],
  },
];

export const JOBS_ORDERING_KEYS = {
  MIN_VALUE: "min_value",
  MAX_VALUE: "max_value",
} as const;

export type JOBS_ORDERING_KEYS_TYPE = Values<typeof JOBS_ORDERING_KEYS>;

export const REVIEW_TYPES = {
  CLASSIC: 1,
  FILL_THE_BLANK: 2,
  ORDERED: 3,
} as const;

export type REVIEW_TYPES_TYPE = Values<typeof REVIEW_TYPES>;

export const REVIEW_TYPES_LABELS = {
  [REVIEW_TYPES.CLASSIC]: "Classic",
  [REVIEW_TYPES.FILL_THE_BLANK]: "Fill In The Blank",
  [REVIEW_TYPES.ORDERED]: "Ordered",
} as const;

export const REVIEW_TYPES_OPTIONS = [
  { value: REVIEW_TYPES.CLASSIC, label: REVIEW_TYPES_LABELS[REVIEW_TYPES.CLASSIC] },
  {
    value: REVIEW_TYPES.FILL_THE_BLANK,
    label: REVIEW_TYPES_LABELS[REVIEW_TYPES.FILL_THE_BLANK],
  },
  { value: REVIEW_TYPES.ORDERED, label: REVIEW_TYPES_LABELS[REVIEW_TYPES.ORDERED] },
];

export const RATE_TYPES = {
  HOURLY: 1,
  ANNUAL: 2,
  DAILY: 3,
  WEEKLY: 4,
  MONTHLY: 5,
} as const;

export type RATE_TYPES_TYPE = Values<typeof RATE_TYPES>;

export const RATE_TYPES_LABELS = {
  [RATE_TYPES.HOURLY]: "Hourly",
  [RATE_TYPES.ANNUAL]: "Annual",
  [RATE_TYPES.DAILY]: "Daily",
  [RATE_TYPES.WEEKLY]: "Weekly",
  [RATE_TYPES.MONTHLY]: "Monthly",
} as const;

export const RATE_TYPES_OPTIONS = [
  { value: RATE_TYPES.HOURLY, label: RATE_TYPES_LABELS[RATE_TYPES.HOURLY] },
  { value: RATE_TYPES.ANNUAL, label: RATE_TYPES_LABELS[RATE_TYPES.ANNUAL] },
  { value: RATE_TYPES.DAILY, label: RATE_TYPES_LABELS[RATE_TYPES.DAILY] },
  { value: RATE_TYPES.WEEKLY, label: RATE_TYPES_LABELS[RATE_TYPES.WEEKLY] },
  { value: RATE_TYPES.MONTHLY, label: RATE_TYPES_LABELS[RATE_TYPES.MONTHLY] },
];

export const CONTINGENT_RATE_TYPES = {
  HOURLY: 1,
  DAILY: 3,
  WEEKLY: 4,
  MONTHLY: 5,
} as const;

export type CONTINGENT_RATE_TYPES_TYPE = Values<typeof CONTINGENT_RATE_TYPES>;

export const CONTINGENT_RATE_TYPES_LABELS = {
  [CONTINGENT_RATE_TYPES.HOURLY]: "Hourly",
  [CONTINGENT_RATE_TYPES.DAILY]: "Daily",
  [CONTINGENT_RATE_TYPES.WEEKLY]: "Weekly",
  [CONTINGENT_RATE_TYPES.MONTHLY]: "Monthly",
} as const;

export const ALL_CONTINGENT_RATE_TYPES_VALUES = Object.values(CONTINGENT_RATE_TYPES);

export const GENERAL_RATE_TYPES = {
  HOURLY: 1,
  ANNUAL: 2,
} as const;

export type GENERAL_RATE_TYPES_TYPE = Values<typeof GENERAL_RATE_TYPES>;

export const GENERAL_RATE_TYPES_LABELS = {
  [GENERAL_RATE_TYPES.HOURLY]: "Hourly",
  [GENERAL_RATE_TYPES.ANNUAL]: "Annual",
} as const;

export const VALUE_TYPES = {
  PAY_RATE: "pay_rate",
  MARKUP: "markup",
  BILL_RATE: "bill_rate",
} as const;

export type VALUE_TYPES_TYPE = Values<typeof VALUE_TYPES>;

export type REQUIRED_RATES_LIST = ImmutableList<VALUE_TYPES_TYPE>;

export const VALUE_TYPES_LABELS = {
  [VALUE_TYPES.PAY_RATE]: "Pay Rate",
  [VALUE_TYPES.MARKUP]: "Markup",
  [VALUE_TYPES.BILL_RATE]: "Bill Rate",
} as const;

export const ALL_VALUE_TYPES = Object.values(VALUE_TYPES);

export const VALUE_SUBTYPES = {
  MIN: "min",
  MID: "mid",
  MAX: "max",
  AVG: "avg",
} as const;

export type VALUE_SUBTYPES_TYPE = Values<typeof VALUE_SUBTYPES>;

export const ALL_VALUE_SUBTYPES = Object.values(VALUE_SUBTYPES);

export const VALUE_SUBTYPES_LABELS = {
  [VALUE_SUBTYPES.MIN]: "Min",
  [VALUE_SUBTYPES.MID]: "Mid",
  [VALUE_SUBTYPES.MAX]: "Max",
  [VALUE_SUBTYPES.AVG]: "Avg",
} as const;

export const VALUE_SUBTYPES_OPTIONS = [
  { type: VALUE_SUBTYPES.MIN, title: VALUE_SUBTYPES_LABELS[VALUE_SUBTYPES.MIN] },
  { type: VALUE_SUBTYPES.MID, title: VALUE_SUBTYPES_LABELS[VALUE_SUBTYPES.MID] },
  { type: VALUE_SUBTYPES.MAX, title: VALUE_SUBTYPES_LABELS[VALUE_SUBTYPES.MAX] },
  { type: VALUE_SUBTYPES.AVG, title: VALUE_SUBTYPES_LABELS[VALUE_SUBTYPES.AVG] },
];

export const SKILLS_LEVELS = {
  JUNIOR: 1,
  INTERMEDIATE: 2,
  SENIOR: 3,
  LEAD: 4,
  GURU: 5,
} as const;

export const SKILLS_LEVELS_3L = {
  LOW: 1,
  MEDIUM: 2,
  HIGH: 3,
} as const;

export type SKILLS_LEVELS_TYPE = Values<typeof SKILLS_LEVELS>;
export type SKILLS_LEVELS_3L_TYPE = Values<typeof SKILLS_LEVELS_3L>;

export type REQUIRED_LEVELS_LIST = ImmutableList<SKILLS_LEVELS_TYPE>;

export const SKILLS_LEVELS_LABELS = {
  [SKILLS_LEVELS.JUNIOR]: "Junior",
  [SKILLS_LEVELS.INTERMEDIATE]: "Intermediate",
  [SKILLS_LEVELS.SENIOR]: "Senior",
  [SKILLS_LEVELS.LEAD]: "Lead",
  [SKILLS_LEVELS.GURU]: "Guru",
} as const;

export const SKILLS_LEVELS_ROMAN_LABELS = {
  [SKILLS_LEVELS.JUNIOR]: "I",
  [SKILLS_LEVELS.INTERMEDIATE]: "II",
  [SKILLS_LEVELS.SENIOR]: "III",
  [SKILLS_LEVELS.LEAD]: "IV",
  [SKILLS_LEVELS.GURU]: "V",
} as const;

export const SKILLS_LEVELS_RATIO_LABELS = {
  [SKILLS_LEVELS.JUNIOR]: 0.05,
  [SKILLS_LEVELS.INTERMEDIATE]: 0.1,
  [SKILLS_LEVELS.SENIOR]: 0.15,
  [SKILLS_LEVELS.LEAD]: 0.18,
  [SKILLS_LEVELS.GURU]: 0.2,
} as const;

export const SKILLS_LEVELS_NUMERAL_LABELS = {
  [SKILLS_LEVELS.JUNIOR]: "1",
  [SKILLS_LEVELS.INTERMEDIATE]: "2",
  [SKILLS_LEVELS.SENIOR]: "3",
  [SKILLS_LEVELS.LEAD]: "4",
  [SKILLS_LEVELS.GURU]: "5",
} as const;

export const SKILLS_LEVELS_3L_NUMERAL_LABELS = {
  [SKILLS_LEVELS_3L.LOW]: "1",
  [SKILLS_LEVELS_3L.MEDIUM]: "2",
  [SKILLS_LEVELS_3L.HIGH]: "3",
} as const;

export const SKILLS_LEVELS_NUMERAL_OPTIONS = [
  {
    value: SKILLS_LEVELS.JUNIOR,
    label: SKILLS_LEVELS_NUMERAL_LABELS[SKILLS_LEVELS.JUNIOR],
  },
  {
    value: SKILLS_LEVELS.INTERMEDIATE,
    label: SKILLS_LEVELS_NUMERAL_LABELS[SKILLS_LEVELS.INTERMEDIATE],
  },
  {
    value: SKILLS_LEVELS.SENIOR,
    label: SKILLS_LEVELS_NUMERAL_LABELS[SKILLS_LEVELS.SENIOR],
  },
  { value: SKILLS_LEVELS.LEAD, label: SKILLS_LEVELS_NUMERAL_LABELS[SKILLS_LEVELS.LEAD] },
  { value: SKILLS_LEVELS.GURU, label: SKILLS_LEVELS_NUMERAL_LABELS[SKILLS_LEVELS.GURU] },
];

export const ALL_SKILLS_LEVELS_NUMERAL = Object.values(SKILLS_LEVELS_NUMERAL_LABELS);

export const LEVELS_PICKER_OPTIONS = {
  JUNIOR: { label: "1", value: 1, hint: "1 (Junior)" },
  INTERMEDIATE: { label: "2", value: 2, hint: "2 (Intermediate)" },
  SENIOR: { label: "3", value: 3, hint: "3 (Senior)" },
  LEAD: { label: "4", value: 4, hint: "4 (Lead)" },
  GURU: { label: "5", value: 5, hint: "5 (Guru)" },
} as const;

export const LEVELS_PICKER_OPTIONS_SHORT = {
  JUNIOR: { label: "1", value: 1, hint: "1 (Junior)" },
  INTERMEDIATE: { label: "2", value: 2, hint: "2 (Intermediate)" },
  SENIOR: { label: "3", value: 3, hint: "3 (Senior)" },
} as const;

export const RATES_PICKER_OPTIONS = {
  PAY_RATE_ONLY: {
    label: "Pay Rates Only",
    value: Set(["pay_rate"]),
    hint: "Pay Rates Only",
  },
  ALL: {
    label: "All Rates (Pay Rate/Bill Rate/Markup)",
    value: Set(["pay_rate", "bill_rate", "markup"]),
    hint: "All Rates (Pay Rate/Bill Rate/Markup)",
  },
} as const;

export const JOB_FEEDBACK_STATUS_TYPES = {
  NOT_VALIDATED: 1,
  VALIDATED: 2,
  DONT_KNOW: 3,
} as const;

export type JOB_FEEDBACK_STATUS_TYPES_TYPE = Values<typeof JOB_FEEDBACK_STATUS_TYPES>;

export const JOB_FEEDBACK_STATUS_TYPES_LABELS = {
  [JOB_FEEDBACK_STATUS_TYPES.NOT_VALIDATED]: "Not Validated",
  [JOB_FEEDBACK_STATUS_TYPES.VALIDATED]: "Validated",
  [JOB_FEEDBACK_STATUS_TYPES.DONT_KNOW]: "Dont Know",
} as const;

export const JOBS_ORDERING_TYPES = {
  PAY_RATE_MIN: "pay_rate_min",
  PAY_RATE_MAX: "pay_rate_max",
} as const;

export type JOBS_ORDERING_TYPES_TYPE = Values<typeof JOBS_ORDERING_TYPES>;

export const JOBS_ORDERING_TYPES_LABELS = {
  [JOBS_ORDERING_TYPES.PAY_RATE_MIN]: "PayRateMin",
  [JOBS_ORDERING_TYPES.PAY_RATE_MAX]: "PayRateMax",
} as const;

export const RATE_FEEDBACK_STATUS_TYPES = {
  NOT_VALIDATED: 1,
  APPROVED: 2,
  DENIED: 3,
  DONT_KNOW: 4,
} as const;

export type RATE_FEEDBACK_STATUS_TYPES_TYPE = Values<typeof RATE_FEEDBACK_STATUS_TYPES>;

export const RATE_FEEDBACK_STATUS_TYPES_LABELS = {
  [RATE_FEEDBACK_STATUS_TYPES.NOT_VALIDATED]: "Not Validated",
  [RATE_FEEDBACK_STATUS_TYPES.APPROVED]: "Approved",
  [RATE_FEEDBACK_STATUS_TYPES.DENIED]: "Denied",
  [RATE_FEEDBACK_STATUS_TYPES.DONT_KNOW]: "I Don't Know",
} as const;

export const REVIEW_STATUS_TYPES = {
  NOT_REVIEWED: 1,
  REVIEWED: 2,
  SKIPPED: 3,
} as const;

export type REVIEW_STATUS_TYPES_TYPE = Values<typeof REVIEW_STATUS_TYPES>;

export const REVIEW_STATUS_TYPES_LABELS = {
  [REVIEW_STATUS_TYPES.NOT_REVIEWED]: "In Progress",
  [REVIEW_STATUS_TYPES.REVIEWED]: "Reviewed",
  [REVIEW_STATUS_TYPES.SKIPPED]: "Skipped",
} as const;

export const REVIEW_STATUS_TYPES_OPTIONS = [
  {
    value: REVIEW_STATUS_TYPES.NOT_REVIEWED,
    label: REVIEW_STATUS_TYPES_LABELS[REVIEW_STATUS_TYPES.NOT_REVIEWED],
  },
  {
    value: REVIEW_STATUS_TYPES.REVIEWED,
    label: REVIEW_STATUS_TYPES_LABELS[REVIEW_STATUS_TYPES.REVIEWED],
  },
  {
    value: REVIEW_STATUS_TYPES.SKIPPED,
    label: REVIEW_STATUS_TYPES_LABELS[REVIEW_STATUS_TYPES.SKIPPED],
  },
];

export const REVIEW_PROCESSING_STATUS_TYPES = {
  PROCESSING: 1,
  FINISHED: 2,
  FAILED: 3,
} as const;

export type REVIEW_PROCESSING_STATUS_TYPES_TYPE = Values<
  typeof REVIEW_PROCESSING_STATUS_TYPES
>;

export const REVIEW_PROCESSING_STATUS_TYPES_LABELSs = {
  [REVIEW_PROCESSING_STATUS_TYPES.PROCESSING]: "Processing",
  [REVIEW_PROCESSING_STATUS_TYPES.FINISHED]: "Finished",
  [REVIEW_PROCESSING_STATUS_TYPES.FAILED]: "Failed",
} as const;

export const QUESTION_ANSWER_FORMATS = {
  TEXTBOX: 1,
  SELECT: 2,
  CHECKBOX: 3,
} as const;

export type QUESTION_ANSWER_FORMATS_TYPE = Values<typeof QUESTION_ANSWER_FORMATS>;

export const QUESTION_ANSWER_FORMATS_LABELS = {
  [QUESTION_ANSWER_FORMATS.TEXTBOX]: "Textbox",
  [QUESTION_ANSWER_FORMATS.SELECT]: "Select",
  [QUESTION_ANSWER_FORMATS.CHECKBOX]: "Checkbox",
} as const;

export const QUESTION_ANSWER_FORMATS_OPTIONS = [
  {
    value: QUESTION_ANSWER_FORMATS.TEXTBOX,
    label: QUESTION_ANSWER_FORMATS_LABELS[QUESTION_ANSWER_FORMATS.TEXTBOX],
  },
  {
    value: QUESTION_ANSWER_FORMATS.SELECT,
    label: QUESTION_ANSWER_FORMATS_LABELS[QUESTION_ANSWER_FORMATS.SELECT],
  },
  {
    value: QUESTION_ANSWER_FORMATS.CHECKBOX,
    label: QUESTION_ANSWER_FORMATS_LABELS[QUESTION_ANSWER_FORMATS.CHECKBOX],
  },
];

export const QUESTION_INPUTS_TYPES = {
  STATEMENT: "statement",
  ANSWER_FORMAT: "answer_format",
  CHOICES: "choices",
  INCLUDED: "included",
  IS_REQUIRED: "is_required",
  ORDER: "order",
} as const;

export type QUESTION_INPUTS_TYPES_TYPE = Values<typeof QUESTION_INPUTS_TYPES>;

export const QUESTION_INPUTS_TYPES_LABELS = {
  [QUESTION_INPUTS_TYPES.STATEMENT]: "Statement",
  [QUESTION_INPUTS_TYPES.ANSWER_FORMAT]: "Answer Format",
  [QUESTION_INPUTS_TYPES.CHOICES]: "Choices",
  [QUESTION_INPUTS_TYPES.INCLUDED]: "Included",
  [QUESTION_INPUTS_TYPES.IS_REQUIRED]: "Is Required",
  [QUESTION_INPUTS_TYPES.ORDER]: "Order",
};

export const FREE_TYPES = {
  FREE: true,
  NOT_FREE: false,
} as const;

export type FREE_TYPES_TYPE = Values<typeof FREE_TYPES>;

export const FREE_TYPES_LABELS = {
  ["" + FREE_TYPES.FREE]: "Free",
  ["" + FREE_TYPES.NOT_FREE]: "Not Free",
} as const;

export const FREE_TYPES_OPTIONS = [
  { value: FREE_TYPES.FREE, label: FREE_TYPES_LABELS["" + FREE_TYPES.FREE] },
  { value: FREE_TYPES.NOT_FREE, label: FREE_TYPES_LABELS["" + FREE_TYPES.NOT_FREE] },
];

export const ACTIVE_TYPES = {
  ACTIVE: true,
  INACTIVE: false,
} as const;

export type ACTIVE_TYPES_TYPE = Values<typeof ACTIVE_TYPES>;

export const ACTIVE_TYPES_LABELS = {
  ["" + ACTIVE_TYPES.ACTIVE]: "Active",
  ["" + ACTIVE_TYPES.INACTIVE]: "Inactive",
} as const;

export const ACTIVE_TYPES_OPTIONS = [
  { value: ACTIVE_TYPES.ACTIVE, label: ACTIVE_TYPES_LABELS["" + ACTIVE_TYPES.ACTIVE] },
  {
    value: ACTIVE_TYPES.INACTIVE,
    label: ACTIVE_TYPES_LABELS["" + ACTIVE_TYPES.INACTIVE],
  },
];

export const THREE_LEVEL_BANDING_TYPES = {
  TRUE: true,
  FALSE: false,
} as const;

export type THREE_LEVEL_BANDING_TYPES_TYPE = Values<typeof THREE_LEVEL_BANDING_TYPES>;

export const THREE_LEVEL_BANDING_TYPES_LABELS = {
  ["" + THREE_LEVEL_BANDING_TYPES.TRUE]: "True",
  ["" + THREE_LEVEL_BANDING_TYPES.FALSE]: "False",
} as const;

export const THREE_LEVEL_BANDING_TYPES_OPTIONS = [
  {
    value: THREE_LEVEL_BANDING_TYPES.TRUE,
    label: THREE_LEVEL_BANDING_TYPES_LABELS["" + THREE_LEVEL_BANDING_TYPES.TRUE],
  },
  {
    value: THREE_LEVEL_BANDING_TYPES.FALSE,
    label: THREE_LEVEL_BANDING_TYPES_LABELS["" + THREE_LEVEL_BANDING_TYPES.FALSE],
  },
];

export const READY_TYPES = {
  PROCESSING: 1,
  READY: 2,
  FAILED: 3,
} as const;

export type READY_TYPES_TYPE = Values<typeof READY_TYPES>;

export const READY_TYPES_LABELS = {
  [READY_TYPES.PROCESSING]: "Processing",
  [READY_TYPES.READY]: "Ready",
  [READY_TYPES.FAILED]: "Failed",
} as const;

export const READY_TYPES_OPTIONS = [
  { value: READY_TYPES.PROCESSING, label: READY_TYPES_LABELS[READY_TYPES.PROCESSING] },
  { value: READY_TYPES.READY, label: READY_TYPES_LABELS[READY_TYPES.READY] },
  { value: READY_TYPES.FAILED, label: READY_TYPES_LABELS[READY_TYPES.FAILED] },
];

export const READY_FOR_PAYMENT_TYPES = {
  READY: true,
  NOT_READY: false,
} as const;

export type READY_FOR_PAYMENT_TYPES_TYPE = Values<typeof READY_FOR_PAYMENT_TYPES>;

export const READY_FOR_PAYMENT_TYPES_LABELS = {
  ["" + READY_FOR_PAYMENT_TYPES.READY]: "Ready",
  ["" + READY_FOR_PAYMENT_TYPES.NOT_READY]: "Not Ready",
} as const;

export const READY_FOR_PAYMENT_TYPES_OPTIONS = [
  {
    value: READY_FOR_PAYMENT_TYPES.READY,
    label: READY_FOR_PAYMENT_TYPES_LABELS["" + READY_FOR_PAYMENT_TYPES.READY],
  },
  {
    value: READY_FOR_PAYMENT_TYPES.NOT_READY,
    label: READY_FOR_PAYMENT_TYPES_LABELS["" + READY_FOR_PAYMENT_TYPES.NOT_READY],
  },
];

export const PAID_TYPES = {
  PAID: true,
  NOT_PAID: false,
} as const;

export type PAID_TYPES_TYPE = Values<typeof PAID_TYPES>;

export const PAID_TYPES_LABELS = {
  ["" + PAID_TYPES.PAID]: "Paid",
  ["" + PAID_TYPES.NOT_PAID]: "Not Paid",
} as const;

export const PAID_TYPES_OPTIONS = [
  { value: PAID_TYPES.PAID, label: PAID_TYPES_LABELS["" + PAID_TYPES.PAID] },
  { value: PAID_TYPES.NOT_PAID, label: PAID_TYPES_LABELS["" + PAID_TYPES.NOT_PAID] },
];

export const ARCHIVED_TYPES = {
  ARCHIVED: true,
  NOT_ARCHIVED: false,
} as const;

export type ARCHIVED_TYPES_TYPE = Values<typeof ARCHIVED_TYPES>;

export const ARCHIVED_TYPES_LABELS = {
  ["" + ARCHIVED_TYPES.ARCHIVED]: "Archived",
  ["" + ARCHIVED_TYPES.NOT_ARCHIVED]: "Not Archived",
} as const;

export const ARCHIVED_TYPES_OPTIONS = [
  {
    value: ARCHIVED_TYPES.ARCHIVED,
    label: ARCHIVED_TYPES_LABELS["" + ARCHIVED_TYPES.ARCHIVED],
  },
  {
    value: ARCHIVED_TYPES.NOT_ARCHIVED,
    label: ARCHIVED_TYPES_LABELS["" + ARCHIVED_TYPES.NOT_ARCHIVED],
  },
];

export const CONSIDERED_PAID_TYPES = {
  PAID: true,
  IN_PROGRESS: false,
} as const;

export type CONSIDERED_PAID_TYPES_TYPE = Values<typeof CONSIDERED_PAID_TYPES>;

export const CONSIDERED_PAID_TYPES_LABELS = {
  ["" + CONSIDERED_PAID_TYPES.PAID]: "Paid",
  ["" + CONSIDERED_PAID_TYPES.IN_PROGRESS]: "In progress",
} as const;

export const ANALYSIS_COMPLETED_TYPES = {
  COMPLETED: true,
  NOT_COMPLETED: false,
} as const;

export type ANALYSIS_COMPLETED_TYPES_TYPE = Values<typeof ANALYSIS_COMPLETED_TYPES>;

export const ANALYSIS_COMPLETED_TYPES_LABELS = {
  ["" + ANALYSIS_COMPLETED_TYPES.COMPLETED]: "Completed",
  ["" + ANALYSIS_COMPLETED_TYPES.NOT_COMPLETED]: "Not Completed",
} as const;

export const ANALYSIS_COMPLETED_TYPES_OPTIONS = [
  {
    value: ANALYSIS_COMPLETED_TYPES.COMPLETED,
    label: ANALYSIS_COMPLETED_TYPES_LABELS["" + ANALYSIS_COMPLETED_TYPES.COMPLETED],
  },
  {
    value: ANALYSIS_COMPLETED_TYPES.NOT_COMPLETED,
    label: ANALYSIS_COMPLETED_TYPES_LABELS["" + ANALYSIS_COMPLETED_TYPES.NOT_COMPLETED],
  },
];

export const CREATE_REVIEW_WIZARD_PAGES = {
  REVIEW: 1,
  SUBMIT: 2,
} as const;

export type CREATE_REVIEW_WIZARD_PAGES_TYPE = Values<typeof CREATE_REVIEW_WIZARD_PAGES>;

export const CREATE_REVIEW_WIZARD_PAGES_LABELS = {
  [CREATE_REVIEW_WIZARD_PAGES.REVIEW]: "Review",
  [CREATE_REVIEW_WIZARD_PAGES.SUBMIT]: "Submit",
} as const;

export type UploadDataObject = {
  id: number;
  user_id: number;
  upload_type: UPLOAD_TYPES_TYPE;
  title: string;
  dataset_length: number;
  file_name: string | null;
  created: Date;
};
export type UploadDataMap = ImmutableMap<UploadDataObject>;
export type UploadDataList = ImmutableList<UploadDataMap>;

// classic surveys specific format
export type RatesDataObject = Nullable<{
  bill_rate_min: number;
  bill_rate_max: number;
  bill_rate_avg: number;
  bill_rate_mid: number;
  pay_rate_min: number;
  pay_rate_max: number;
  pay_rate_avg: number;
  pay_rate_mid: number;
  markup_min: number;
  markup_max: number;
  markup_avg: number;
  markup_mid: number;
}>;
export type RatesDataMap = ImmutableMap<RatesDataObject>;
export type RatesDataKey = keyof RatesDataObject;
export type RateResultDataObject = RatesDataObject & {
  id: number;
  level: SKILLS_LEVELS_TYPE;
};
export type RateResultDataMap = ImmutableMap<RateResultDataObject>;
export type RateResultsDataList = ImmutableList<RateResultDataMap>;
export type RateResultsOrderedMap = ImmutableOrderedMap<number, RateResultDataMap>;

export type RatesFeedbackDataObject = Nullable<RatesDataObject> & {
  id: number;
  job: number;
  rate_result: number;
  attempt: number;
  status: RATE_FEEDBACK_STATUS_TYPES_TYPE;
  comment: string | null;
};
export type RatesFeedbackDataMap = ImmutableMap<RatesFeedbackDataObject>;
export type RatesFeedbackDataList = ImmutableList<RatesFeedbackDataMap>;
export type RatesFeedbackOrderedMap = ImmutableOrderedMap<number, RatesFeedbackDataMap>;

export type CurrencyDataMap = ImmutableMap<CurrencyData>;

export type JobDataObject = {
  id: number;
  review: string;
  title: string;
  description: string;
  job_title_id: number;
  collection_id: number;
  collection_title: string;
  collection_slug: string;
  industry_id: number;
  industry_name: string;
  category: string;
  region: string | null;
  country: string | null;
  state: string | null;
  city: string | null;
  is_global_supplier_search: boolean;
  locations_ids: ImmutableList<number>;
  country_id: number | null;
  region_id: number | null;
  rate_type: RATE_TYPES_TYPE;
  rate_results: RateResultsOrderedMap;
  hourly_multiplier: number;
  daily_multiplier: number;
  weekly_multiplier: number;
  monthly_multiplier: number;
  currency_id: number;
  currency_name: string;
  currency_code: string;
  currency_symbol: string;
  currency_factor: number;
  currency_data: CurrencyDataMap;
  replacements_resolved: boolean;
  source: string;
};
export type JobDataMap = ImmutableMap<JobDataObject>;
export type JobsDataList = ImmutableList<JobDataMap>;
export type JobsDataOrderedMap = ImmutableOrderedMap<number, JobDataMap>;

export type QuestionFeedbackDataObject = {
  id: number;
  question: number;
  attempt: number;
  value: string;
  created: Date;
};
export type QuestionFeedbackDataMap = ImmutableMap<QuestionFeedbackDataObject>;
export type QuestionsFeedbackDataList = ImmutableList<QuestionFeedbackDataMap>;
export type QuestionsFeedbackOrderedMap = ImmutableOrderedMap<
  number,
  QuestionFeedbackDataMap
>;

export type QuestionDataObject = {
  id: number;
  answer_format: QUESTION_ANSWER_FORMATS_TYPE;
  statement: string;
  choices?: string[];
  is_required: boolean;
  default_value: string | null;
  included: boolean;
  order: number;
};
export type QuestionDataMap = ImmutableMap<QuestionDataObject>;
export type QuestionsOrderedMap = ImmutableList<QuestionDataMap>;

type MetaDataObject = { processing_message: string };
export type MetaDataMap = ImmutableMap<MetaDataObject>;

export const PAYMENT_REPORT_TYPES = {
  BY_VALIDATOR: 1,
  BY_COUNTRY: 2,
  BY_CLIENT: 3,
} as const;

export type PAYMENT_REPORT_TYPES_TYPE = Values<typeof PAYMENT_REPORT_TYPES>;

export const PAYMENT_REPORT_TYPES_LABELS = {
  [PAYMENT_REPORT_TYPES.BY_VALIDATOR]: "By Validator",
  [PAYMENT_REPORT_TYPES.BY_COUNTRY]: "By Country",
  [PAYMENT_REPORT_TYPES.BY_CLIENT]: "By Client",
} as const;

export const PAYMENT_REPORT_TYPES_OPTIONS = [
  {
    value: PAYMENT_REPORT_TYPES.BY_VALIDATOR,
    label: PAYMENT_REPORT_TYPES_LABELS[PAYMENT_REPORT_TYPES.BY_VALIDATOR],
  },
  {
    value: PAYMENT_REPORT_TYPES.BY_COUNTRY,
    label: PAYMENT_REPORT_TYPES_LABELS[PAYMENT_REPORT_TYPES.BY_COUNTRY],
  },
  {
    value: PAYMENT_REPORT_TYPES.BY_CLIENT,
    label: PAYMENT_REPORT_TYPES_LABELS[PAYMENT_REPORT_TYPES.BY_CLIENT],
  },
];

// reviews types

export type ReviewDataObject = {
  id: string;
  title: string;
  reviews_group_id: string;
  countries_string: string;
  states_string: string;
  cities_string: string;
  regions_string: string;
  rate_type: RATE_TYPES_TYPE;
  review_type: REVIEW_TYPES_TYPE;
  source_type: SOURCE_TYPES_TYPE;
  source_title: string;
  client_name: string;
  client_id: number;
  required_levels: REQUIRED_LEVELS_LIST;
  required_rates: REQUIRED_RATES_LIST;
  created: Date;
  updated: Date;
  is_active: boolean;
  is_available: boolean;
  processing_status: REVIEW_PROCESSING_STATUS_TYPES_TYPE;
  is_three_level_banding: boolean;
  free_validation: boolean;
  total_reward: number;
  max_attempts: number;
  // related validation attempt made by some particular validator (user specified in the original request)
  attempt: AttemptDataMap | null;
  // all validation attempts made by various validators
  attempts: AttemptsDataOrderedMap;
  attempts_count: number;
  upload_id: number;
  upload: UploadDataMap;
  ratecard_id: number | null;
  ratecard_name: string | null;
  program_id: number;
  program_name: string;
  stored_index_id: number | null;
  stored_index_name: string | null;
  reward_per_job: number;
  instructions: string | null;
  industries_string: string;
  categories_string: string;
  display_currency_id: number | null;
  display_currency_name: string | null;
  display_currency_code: string | null;
  display_currency_symbol: string | null;
  display_currency_factor: number | null;
  display_currency_data: CurrencyDataMap;
  meta: MetaDataMap;
  jobs: JobsDataOrderedMap;
  questions: QuestionsOrderedMap;
};

export type ReviewDataMap = ImmutableMap<ReviewDataObject>;
export type ReviewsDataList = ImmutableList<ReviewDataMap>;
export type ReviewsDataOrderedMap = ImmutableOrderedMap<number, ReviewDataMap>;

// fill in the blanks surveys feedback format
export type JobRatesObject = {
  pay_rate_min: number;
  pay_rate_max: number;
  annual_salary_min: number;
  annual_salary_max: number;
  supplier_rewards_percentage_min: number;
  supplier_rewards_percentage_max: number;
  employment_taxes_percentage: number;
};
export type JobFeedbackRatesObject = Nullable<JobRatesObject>;
export type JobFeedbackDataObject = JobFeedbackRatesObject & {
  id: number;
  job: number;
  attempt: number;
  status: JOB_FEEDBACK_STATUS_TYPES_TYPE;
  created: Date;
};

export type JobFeedbackDataMap = ImmutableMap<JobFeedbackDataObject>;
export type JobsFeedbackDataList = ImmutableList<JobFeedbackDataMap>;
export type JobsFeedbackDataOrderedMap = ImmutableOrderedMap<number, JobFeedbackDataMap>;

export type OverallJobFeedbackDataObject = {
  job: JobDataMap;
  attempt: AttemptDataMap;
  bill_rate_min: number | null;
  bill_rate_max: number | null;
  pay_rate_min: number | null;
  pay_rate_max: number | null;
  markup_min: number | null;
  markup_max: number | null;
  annual_salary_min: number | null;
  annual_salary_max: number | null;
  validated_number: number | null;
  skipped_number: number | null;
  total_number: number | null;
};

export type OverallJobFeedbackDataMap = ImmutableMap<OverallJobFeedbackDataObject>;
export type OverallJobFeedbackDataOrderedMap = ImmutableOrderedMap<
  number,
  OverallJobFeedbackDataMap
>;
export type OverallJobFeedbackDataList = ImmutableList<OverallJobFeedbackDataMap>;

// attempts types

export type AttemptDataObject = {
  id: number;
  created: Date;
  updated: Date;
  review: ReviewDataMap;
  first_name: string;
  last_name: string;
  email: string;
  review_status: REVIEW_STATUS_TYPES_TYPE;
  completed_timestamp: Date;
  free_validation: boolean;
  total_reward: number;
  ready_for_payment: READY_FOR_PAYMENT_TYPES_TYPE;
  ready_for_payment_timestamp: Date | null;
  paid: PAID_TYPES_TYPE;
  paid_timestamp: Date | null;
  considered_as_paid: Boolean | null;
  analysis_completed: ANALYSIS_COMPLETED_TYPES_TYPE;
  analysis_completed_timestamp: Date | null;
  archived: ARCHIVED_TYPES_TYPE;
  archived_timestamp: Date | null;
  comment: string | null;
  reward_per_job: number;
  villager_id: number | null;
  limits_updated_timestamp: "Never" | "N/A" | Date | null;
  questions_feedback: QuestionsFeedbackOrderedMap;
  rates_feedback: RatesFeedbackOrderedMap;
  jobs_feedback: JobsFeedbackDataOrderedMap;
};

export type AttemptDataMap = ImmutableMap<AttemptDataObject>;
export type AttemptsDataOrderedMap = ImmutableOrderedMap<number, AttemptDataMap>;

export interface TableColumnSpecsObject<RD = RowData> extends ColumnConfigObject<RD> {
  type: "column";
}

// payment report types

export type BasePaymentReportParamsObject = {
  reportType: PAYMENT_REPORT_TYPES_TYPE;
  reportFromDate: string;
  reportToDate: string;
};
export type OverallPaymentReportParamsObject = BasePaymentReportParamsObject;
export type ByValidatorPaymentReportParamsObject = BasePaymentReportParamsObject & {
  email: string;
  firstName: string;
  lastName: string;
};
export type ByCountryPaymentReportParamsObject = BasePaymentReportParamsObject & {
  countryName: string;
};
export type ByClientPaymentReportParamsObject = BasePaymentReportParamsObject & {
  clientId: number;
  clientName: string;
};

export type OverallPaymentReportParamsMap =
  ImmutableMap<OverallPaymentReportParamsObject>;
export type ByValidatorPaymentReportParamsMap =
  ImmutableMap<ByValidatorPaymentReportParamsObject>;
export type ByCountryPaymentReportParamsMap =
  ImmutableMap<ByCountryPaymentReportParamsObject>;
export type ByClientPaymentReportParamsMap =
  ImmutableMap<ByClientPaymentReportParamsObject>;

export type GenericPaymentReportParamsObject =
  | OverallPaymentReportParamsObject
  | ByValidatorPaymentReportParamsObject
  | ByCountryPaymentReportParamsObject
  | ByClientPaymentReportParamsObject;
export type GenericPaymentReportParamsMap =
  | OverallPaymentReportParamsMap
  | ByValidatorPaymentReportParamsMap
  | ByCountryPaymentReportParamsMap
  | ByClientPaymentReportParamsMap;

// validator performance types

export type RewardsSummaryDataObject = {
  total_reward_count: number;
  total_reward_max: number;
  total_reward_min: number;
  total_reward_total: number;
};
export type RewardsSummaryDataMap = ImmutableMap<RewardsSummaryDataObject>;

export type RatesSummaryValuesObject = {
  annual_salary_max__max: number | null;
  annual_salary_min__min: number | null;
  bill_rate_max__max: number | null;
  bill_rate_min__min: number | null;
  markup_max__max: number | null;
  markup_min__min: number | null;
  pay_rate_max__max: number | null;
  pay_rate_min__min: number | null;
};

export type RatesSummaryValuesMap = ImmutableMap<RatesSummaryValuesObject>;

export type TotalFeesPaidDataObject = {
  count: number;
  email: string;
  fee_summ: number | null;
  first_name: string;
  last_name: string;
};

export type TotalFeesPaidDataMap = ImmutableMap<TotalFeesPaidDataObject>;
export type TotalFeesPaidDataList = ImmutableList<TotalFeesPaidDataMap>;
export type TotalFeesPaidDataOrderedMap = ImmutableOrderedMap<
  number,
  TotalFeesPaidDataMap
>;

export type TotalFeesPaidSummaryDataObject = {
  total_count: number;
  total_fee_summ: number | null;
};

export type NdaHistoryDataObject = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  nda_text: string;
  created: Date;
  updated: Date;
};

export type NdaHistoryDataMap = ImmutableMap<NdaHistoryDataObject>;
export type NdaHistoryDataList = ImmutableList<NdaHistoryDataMap>;
export type NdaHistoryDataOrderedMap = ImmutableOrderedMap<number, NdaHistoryDataMap>;

// validator permissions types

type PermsReprAllowedItemObject = {
  id: number;
  title: string;
  slug?: string;
  faIcon?: IconProps["icon"];
  location_code?: string;
};
export type PermsReprAllowedItemMap = ImmutableMap<PermsReprAllowedItemObject>;
export type PermsReprAllowedItemsList = ImmutableList<PermsReprAllowedItemObject>;

type PermsReprValueObject = {
  allowed_items: PermsReprAllowedItemsList | null;
  allowed_none: boolean | null;
  allowed_all: boolean | null;
};
type PermsReprValueMap = ImmutableMap<PermsReprValueObject>;

export type PermsReprKey = "industries" | "ratecards" | "countries" | "uploads";
type PermsReprObject = { [key in PermsReprKey]: PermsReprValueMap };
export type PermsReprMap = ImmutableMap<PermsReprObject>;

export type PermissionDataObject = {
  id: number;
  created: Date;
  updated: Date;
  email: string;
  first_name: string;
  last_name: string;
  allowed_all_countries: boolean;
  allowed_none_countries: boolean;
  allowed_countries_ids: ImmutableList<number> | null;
  allowed_countries_string: string | null;
  allowed_all_industries: boolean;
  allowed_none_industries: boolean;
  allowed_industries_ids: ImmutableList<number> | null;
  allowed_industries_string: string | null;
  allowed_ratecards_ids: ImmutableList<number> | null;
  allowed_ratecards_string: string | null;
  allowed_uploads_ids: ImmutableList<number> | null;
  allowed_uploads_string: string | null;
  just_created: boolean;
  perms_repr: PermsReprMap;
};

export type PermissionDataMap = ImmutableMap<PermissionDataObject>;
export type PermissionsDataList = ImmutableList<PermissionDataMap>;
export type PermissionsDataOrderedMap = ImmutableOrderedMap<number, PermissionDataMap>;

export type ValidatorSelectFormObject = {
  first_name: string | null;
  last_name: string | null;
  email: string | null;
};
export type ValidatorSelectFormDataMap = ImmutableMap<ValidatorSelectFormObject>;
export type ValidatorSelectFormDataList = ImmutableList<ValidatorSelectFormDataMap>;

// alerts system data types

export type AlertDataObject = {
  id: number;
  text: string;
  email: string;
  countries_string: string;
  created: Date;
  updated: Date;
};

export type AlertDataMap = ImmutableMap<AlertDataObject>;
export type AlertsDataList = ImmutableList<AlertDataMap>;
export type AlertsDataOrderedMap = ImmutableOrderedMap<number, AlertDataMap>;

export type CountryDataObject = {
  id: number;
  slug: string;
  title: string;
  category: number;
  location_code: string;
  parent: number | null;
};

export type CountryDataMap = ImmutableMap<CountryDataObject>;
export type CountriesDataList = ImmutableList<CountryDataMap>;
export type CountriesDataOrderedMap = ImmutableOrderedMap<number, CountryDataMap>;

// collection limits data types

export type CountryInfoDataObject = {
  id: number;
  country_code: string;
  country_name: string;
  currency_id: number;
  currency_code: string;
  currency_name: string;
  currency_symbol: string;
};
export type CountryInfoDataMap = ImmutableMap<CountryInfoDataObject>;

type LevelLimitsValuesObject = {
  level: SKILLS_LEVELS_TYPE;
  low: number;
  high: number;
};
export type LevelLimitsValuesMap = ImmutableMap<LevelLimitsValuesObject>;
export type LevelsLimitsValuesList = ImmutableList<LevelLimitsValuesMap>;

type LevelsLimitsObject = {
  hourly: LevelsLimitsValuesList;
  salary: LevelsLimitsValuesList;
};
export type LevelsLimitsMap = ImmutableMap<LevelsLimitsObject>;

export type CollectionLimitsObject = {
  id: number;
  created: Date;
  updated: Date;
  collection: number;
  collection_family: string;
  country: number;
  country_info: CountryInfoDataMap;
  hourly_high: number;
  hourly_low: number;
  salary_high: number;
  salary_low: number;
  is_client_accepted: boolean;
  is_generated: boolean;
  levelsLimits?: LevelsLimitsMap;
};

export type CollectionLimitsMap = ImmutableMap<CollectionLimitsObject>;
export type CollectionLimitsList = ImmutableList<CollectionLimitsMap>;
