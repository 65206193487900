import React from "react";

import { TableGroupCellImpl, TableGroupRowImpl } from "../impl/TableGroupsImpl";

import type { ColumnConfigObject, GroupConfigObject, RowData } from "../types";

type TableGroupsProps<RD = RowData> = {
  groups: GroupConfigObject[];
  columns: ColumnConfigObject<RD>[];
};

export const TableGroups = <RD = RowData,>(props: TableGroupsProps<RD>) => {
  const { groups, columns } = props;

  if (groups.length && columns.length && groups.length !== columns.length) {
    return (
      <TableGroupRowImpl>
        {groups.map(({ uniqueKey, columns, title, css }) => (
          <TableGroupCellImpl
            key={uniqueKey}
            colSpan={columns.length === 0 ? 1 : columns.length}
            css={css}
          >
            {title}
          </TableGroupCellImpl>
        ))}
      </TableGroupRowImpl>
    );
  }

  return null;
};
TableGroups.displayName = "TableGroups";
