import React from "react";
// lib
import Stack from "../../../../components/lib/Stack";
import Text from "../../../../components/lib/Text";
// relay
import graphql from "babel-plugin-relay/macro";
import {
  MedianPayChart_savedsearch$key,
  MedianPayChart_savedsearch$data,
} from "./__generated__/MedianPayChart_savedsearch.graphql";
import { useFragment } from "react-relay";
import { useSearchResultsStore } from "../../../../stores/SearchResults";
import { AverageRateChartSm } from "./AverageRateChartSm";
import Grid from "../../../../components/lib/Grid";

function getMarketRateByLevel(
  marketRates: NonNullable<MedianPayChart_savedsearch$data>["marketrates"] = [],
  level: number
) {
  return marketRates?.find((mr) => mr?.level?.legacyId === level) || null;
}

const gridCss = {
  gridTemplateRows: "min-content",
  gridGap: 0,
  width: "$full",
  gridTemplateColumns: "1fr",
  "@md": {
    gridTemplateColumns: "repeat(3, 1fr)",
  },
};

const fragment = graphql`
  fragment MedianPayChart_savedsearch on SaveSearchNode {
    id
    rateType @required(action: NONE)
    currency {
      id
      iso @required(action: NONE)
    }
    marketrates {
      id
      level {
        legacyId
        value
      }
      # pay rate
      payRateMin
      payRateMid
      payRateMax
      # bill rate
      billRateMin
      billRateMid
      billRateMax
      # markup %
      markupPctMin
      markupPctMid
      markupPctMax
    }
  }
`;

type Props = {
  savedsearch: MedianPayChart_savedsearch$key;
};

export function MedianPayChart(props: Props) {
  const { savedsearch } = props;

  const data = useFragment(fragment, savedsearch);
  const isContract = data?.rateType === 1;

  const fromCurrency = data?.currency?.iso || "USD";
  const toCurrency = useSearchResultsStore((s) => s.currency);
  const rateFrequency = useSearchResultsStore((s) => s.rateFrequency);
  const formatRate = useSearchResultsStore((s) => {
    const freq = isContract ? rateFrequency : "Hourly";
    return s.calculateRate(fromCurrency, toCurrency, freq, !isContract);
  });
  const marketRate1 = getMarketRateByLevel(data?.marketrates, 1);
  const marketRate3 = getMarketRateByLevel(data?.marketrates, 3);
  const marketRate5 = getMarketRateByLevel(data?.marketrates, 5);

  const pay = {
    minRate: formatRate(marketRate1?.payRateMin),
    medianRate: formatRate(marketRate3?.payRateMid),
    maxRate: formatRate(marketRate5?.payRateMax),
  };

  const bill = {
    minRate: formatRate(marketRate1?.billRateMin),
    medianRate: formatRate(marketRate3?.billRateMid),
    maxRate: formatRate(marketRate5?.billRateMax),
  };

  return (
    <Stack fill css={{ gap: "$4" }}>
      <Stack nogap fill css={{ alignItems: "flex-start" }}>
        <Text noMargin as="h4">
          Market Rates
        </Text>
        <Text color="primary">
          The data presented on these charts is across all experience levels.
        </Text>
      </Stack>
      <Grid css={gridCss}>
        <AverageRateChartSm
          title="Median Pay Rate"
          minRate={pay.minRate}
          medianRate={pay.medianRate}
          maxRate={pay.maxRate}
          variant="blue"
        />

        {isContract && (
          <>
            <AverageRateChartSm
              title="Median Markup"
              minRate={marketRate1?.markupPctMin + "%"}
              medianRate={marketRate3?.markupPctMid + "%"}
              maxRate={marketRate5?.markupPctMax + "%"}
              variant="green"
            />
            <AverageRateChartSm
              title="Median Bill Rate"
              minRate={bill.minRate}
              medianRate={bill.medianRate}
              maxRate={bill.maxRate}
              variant="yellow"
            />
          </>
        )}
      </Grid>
    </Stack>
  );
}
