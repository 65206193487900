import { useCallback } from "react";
import {
  atom,
  useRecoilState,
  useResetRecoilState,
  useRecoilValue,
  RecoilState,
  SetterOrUpdater,
  Resetter,
} from "recoil";
import { Map } from "immutable";

import { emptyList, emptyMap, emptyOrderedMap } from "../../constants";

import {
  FILE_UPLOADING_STATES,
  FILE_UPLOADING_STATES_TYPE,
} from "./components/ContractorsUploadFormBlock";
import {
  ContractorsDataOrderedMap,
  ContractorsTableConfigMap,
  ContractorsTableDataStateObject,
  ContractorsTableTabsVisibleColumnsMap,
  ContractorsUploadsDataList,
  DiscoverySearchResultsList,
  DiscoverySearchResultTotalsMap,
  ProcessingDataMap,
  ProcessingsDataOrderedMap,
  ProgramsDataOrderedMap,
  PROGRAM_STATUSES,
  StoredIndexesDataOrderedMap,
  TABLE_TABS_TYPES,
} from "./types";
import {
  readFromSessionStorage,
  writeToSessionStorage,
  deserializeContractorsTableConfig,
  mergeChangesIntoTableConfig,
  serializeContractorsTableConfig,
} from "./utils";
import { pickObjectKeys } from "../../utils/object";

import type {
  RestfulTableConfigObject,
  RestfulTableDataStateObject as BaseRestfulTableDataStateObject,
  RowData,
} from "../../components/tables/types";
import type {
  ColumnsFiltersQueriesOrderedMap,
  FiltersConfigOrderedMap,
  FiltersQueryOrderedMap,
} from "../../components/tables/types";
import type {
  ProgramDataMap,
  StoredIndexDataMap,
  ContractorsTableConfigObject,
} from "./types";
import type { ImmutableArbitraryMap } from "../../types/immutable";

type RestfulTableDataStateObject<RD = RowData> = BaseRestfulTableDataStateObject<RD> & {
  loaded: boolean;
};

const restfulTableConfigDefaults: RestfulTableConfigObject = {
  activePage: 1,
  itemsPerPage: 50,
  filters: emptyOrderedMap as unknown as FiltersConfigOrderedMap,
  filtersQuery: emptyOrderedMap as unknown as FiltersQueryOrderedMap,
  columnsFiltersQueries: emptyOrderedMap as unknown as ColumnsFiltersQueriesOrderedMap,
};

// Programs table global state

export const programsTableGlobalState = atom<RestfulTableDataStateObject<ProgramDataMap>>(
  {
    key: "programsTableGlobalState",
    default: {
      ...restfulTableConfigDefaults,
      itemsPerPage: 10,
      data: emptyOrderedMap as unknown as ProgramsDataOrderedMap,
      itemsCount: 0,
      loaded: false,
    },
  }
);

// Current Program global state

export const defaultEmptyProgram = Map({
  status: PROGRAM_STATUSES.PENDING,
}) as unknown as ProgramDataMap;

type ProgramGlobalState = {
  programData: ProgramDataMap;
  programDataLoaded: boolean;
};

export const programGlobalState = atom<ProgramGlobalState>({
  key: "programGlobalState",
  default: {
    programData: defaultEmptyProgram,
    programDataLoaded: false,
  },
});

// Stored Indexes list global state

export type StoredIndexesListGlobalState = {
  data: StoredIndexesDataOrderedMap;
  itemsCount: number;
  activePage: number;
  itemsPerPage: number;
  search: string;
  loaded: boolean;
};

export const storedIndexesListGlobalState = atom<StoredIndexesListGlobalState>({
  key: "storedIndexesListGlobalState",
  default: {
    data: emptyOrderedMap as unknown as StoredIndexesDataOrderedMap,
    itemsCount: 0,
    activePage: 1,
    itemsPerPage: 10,
    search: "",
    loaded: false,
  },
});

// Contractors Table global state hooks mechanism

type AllContractorsTablesConfigsGlobalState = ImmutableArbitraryMap<
  string,
  ContractorsTableConfigMap
>;

const allContractorsTablesConfigsGlobalState =
  atom<AllContractorsTablesConfigsGlobalState>({
    key: "allContractorsTablesConfigsGlobalState",
    default: emptyMap as AllContractorsTablesConfigsGlobalState,
  });

const contractorsTableConfigDefaults: ContractorsTableConfigObject = {
  activePage: 1,
  itemsPerPage: 50,
  filters: emptyOrderedMap as unknown as FiltersConfigOrderedMap,
  filtersQuery: emptyOrderedMap as unknown as FiltersQueryOrderedMap,
  columnsFiltersQueries: emptyOrderedMap as unknown as ColumnsFiltersQueriesOrderedMap,
  activeTab: TABLE_TABS_TYPES.COMPARE_WITH_MARKET,
  visibleColumns: emptyMap as ContractorsTableTabsVisibleColumnsMap,
};

const getContractorsTableConfigStateByTableId = (
  allTablesConfigsState: AllContractorsTablesConfigsGlobalState,
  tableId: string,
  defaults: ContractorsTableConfigObject
): [ContractorsTableConfigObject, boolean] => {
  // load from the state first
  let tableConfigState = (
    allTablesConfigsState.get(tableId) || (emptyMap as ContractorsTableConfigMap)
  ).toObject();
  const hasNoConfigInState = Object.keys(tableConfigState).length === 0;
  // if empty - get config from session storage
  if (hasNoConfigInState) {
    tableConfigState = deserializeContractorsTableConfig(readFromSessionStorage(tableId));
  }
  return [
    {
      activePage: tableConfigState["activePage"] || defaults["activePage"] || 1,
      itemsPerPage: tableConfigState["itemsPerPage"] || defaults["itemsPerPage"],
      filters: tableConfigState["filters"] || defaults["filters"],
      filtersQuery: tableConfigState["filtersQuery"] || defaults["filtersQuery"],
      columnsFiltersQueries:
        tableConfigState["columnsFiltersQueries"] || defaults["columnsFiltersQueries"],
      activeTab: tableConfigState["activeTab"] || defaults["activeTab"],
      visibleColumns: tableConfigState["visibleColumns"] || defaults["visibleColumns"],
    },
    hasNoConfigInState,
  ];
};

type ContractorsTableConfigStateSetter<T> = (
  configChangesObjectOrFunc: T | ((prevState: T) => T),
  syncToSessionStore: boolean
) => void;

export const useContractorsTableConfigGlobalState = (
  tableId: string,
  defaults: ContractorsTableConfigObject = contractorsTableConfigDefaults
): [
  ContractorsTableConfigObject,
  ContractorsTableConfigStateSetter<ContractorsTableConfigObject>
] => {
  const [allTablesConfigState, setAllTablesConfigState] = useRecoilState(
    allContractorsTablesConfigsGlobalState
  );
  const setTableConfigState: ContractorsTableConfigStateSetter<ContractorsTableConfigObject> =
    useCallback(
      (configChangesObjectOrFunc, syncToSessionStore) => {
        const isFunc = typeof configChangesObjectOrFunc === "function";

        setAllTablesConfigState(
          (prevAllTablesConfigState: AllContractorsTablesConfigsGlobalState) => {
            const [prevTableConfigState, hasNoTableConfigInState] =
              getContractorsTableConfigStateByTableId(
                prevAllTablesConfigState,
                tableId,
                defaults
              );
            const [mergedTableConfig, hasConfigChanges] = mergeChangesIntoTableConfig(
              prevTableConfigState,
              isFunc
                ? configChangesObjectOrFunc(prevTableConfigState)
                : configChangesObjectOrFunc
            );

            if (hasConfigChanges || hasNoTableConfigInState) {
              if (syncToSessionStore) {
                setTimeout(
                  () =>
                    writeToSessionStorage(
                      tableId,
                      serializeContractorsTableConfig(mergedTableConfig)!
                    ),
                  0
                );
              }
              return prevAllTablesConfigState.set(
                tableId,
                Map(mergedTableConfig) as ContractorsTableConfigMap
              ) as AllContractorsTablesConfigsGlobalState;
            }
            return prevAllTablesConfigState;
          }
        );
      },
      [setAllTablesConfigState, tableId, defaults]
    );

  const [tableConfigState] = getContractorsTableConfigStateByTableId(
    allTablesConfigState,
    tableId,
    defaults
  );

  return [tableConfigState, setTableConfigState];
};

export function useContractorsTableGlobalStateBase(
  tableId: string,
  contractorsTableGlobalState: RecoilState<ContractorsTableDataStateObject>,
  defaults?: ContractorsTableConfigObject
): [ContractorsTableDataStateObject, SetterOrUpdater<ContractorsTableDataStateObject>] {
  const [tableConfigState, setTableConfigState] = useContractorsTableConfigGlobalState(
    tableId,
    defaults
  );
  const [tableDataState, setTableDataState] = useRecoilState(contractorsTableGlobalState);

  const setTableState: SetterOrUpdater<ContractorsTableDataStateObject> = useCallback(
    (newStateObjectOrFunc) => {
      const isFunc = typeof newStateObjectOrFunc === "function";

      setTableConfigState(
        (prevState: ContractorsTableConfigObject): ContractorsTableConfigObject => {
          return pickObjectKeys<ContractorsTableConfigObject>(
            isFunc
              ? newStateObjectOrFunc(prevState as ContractorsTableDataStateObject)
              : newStateObjectOrFunc,
            Object.keys(prevState) as Array<keyof ContractorsTableConfigObject>
          ) as ContractorsTableConfigObject;
        },
        true
      );

      setTableDataState(
        (prevState: ContractorsTableDataStateObject): ContractorsTableDataStateObject => {
          return {
            ...prevState,
            ...(pickObjectKeys(
              isFunc ? newStateObjectOrFunc(prevState) : newStateObjectOrFunc,
              Object.keys(prevState) as Array<keyof ContractorsTableDataStateObject>
            ) as ContractorsTableDataStateObject),
          };
        }
      );
    },
    [setTableDataState, setTableConfigState]
  );

  return [{ ...tableDataState, ...tableConfigState }, setTableState];
}

// All Contractors Table global state (contractors table on the Create Index View page)

export const allContractorsTableGlobalState = atom<ContractorsTableDataStateObject>({
  key: "allContractorsTableGlobalState",
  default: {
    ...contractorsTableConfigDefaults,
    data: emptyOrderedMap as unknown as ContractorsDataOrderedMap,
    itemsCount: 0,
    loaded: false,
    isSelectingRows: false,
    isBulkUpdateSelectingRows: false,
    selectedRows: emptyOrderedMap as unknown as ContractorsDataOrderedMap,
  },
});

export const useAllContractorsTableGlobalState = (
  tableId: string
): [
  ContractorsTableDataStateObject,
  SetterOrUpdater<ContractorsTableDataStateObject>,
  Resetter
] => {
  return [
    ...useContractorsTableGlobalStateBase(tableId, allContractorsTableGlobalState),
    useResetRecoilState(allContractorsTableGlobalState),
  ];
};

// Current Stored Index global state

type StoredIndexGlobalState = {
  indexData: StoredIndexDataMap;
  indexDataLoaded: boolean;
  indexTableConfig: ContractorsTableConfigObject;
};

// Important!: Do NOT export, use hooks exported below
const storedIndexGlobalState = atom<StoredIndexGlobalState>({
  key: "storedIndexGlobalState",
  default: {
    indexData: emptyMap as StoredIndexDataMap,
    indexDataLoaded: false,
    // store precalculated table config
    indexTableConfig: contractorsTableConfigDefaults,
  },
});

export const useStoredIndexGlobalState = (): [
  StoredIndexGlobalState,
  SetterOrUpdater<StoredIndexGlobalState>
] => {
  const [storedIndexState, setStoredIndexState] = useRecoilState(storedIndexGlobalState);

  const setStateFunc: SetterOrUpdater<StoredIndexGlobalState> = useCallback(
    (newStateObjectOrFunc) => {
      const isFunc = typeof newStateObjectOrFunc === "function";

      setStoredIndexState((prevStateObject: StoredIndexGlobalState) => {
        const resultingStateObject: StoredIndexGlobalState = isFunc
          ? newStateObjectOrFunc(prevStateObject)
          : newStateObjectOrFunc;
        const hasConfigChanges =
          resultingStateObject.indexData.get("filters") !==
            prevStateObject.indexTableConfig.filters ||
          resultingStateObject.indexData.get("items_per_page") !==
            prevStateObject.indexTableConfig.itemsPerPage ||
          resultingStateObject.indexData.get("active_tab") !==
            prevStateObject.indexTableConfig.activeTab ||
          resultingStateObject.indexData.get("visible_columns") !==
            prevStateObject.indexTableConfig.visibleColumns;

        // precalculated table config stored in the index
        if (hasConfigChanges) {
          resultingStateObject.indexTableConfig = {
            activePage: contractorsTableConfigDefaults.activePage,
            itemsPerPage: resultingStateObject.indexData.get("items_per_page") || 20,
            filters:
              resultingStateObject.indexData.get("filters") ||
              contractorsTableConfigDefaults.filters,
            filtersQuery:
              resultingStateObject.indexData.get("dj_filters") ||
              contractorsTableConfigDefaults.filtersQuery,
            columnsFiltersQueries: contractorsTableConfigDefaults.columnsFiltersQueries,
            activeTab:
              resultingStateObject.indexData.get("active_tab") ||
              contractorsTableConfigDefaults.activeTab,
            visibleColumns:
              resultingStateObject.indexData.get("visible_columns") ||
              contractorsTableConfigDefaults.visibleColumns,
          };
        }

        return resultingStateObject;
      });
    },
    [setStoredIndexState]
  );

  return [storedIndexState, setStateFunc];
};

export const useResetStoredIndexGlobalState = (): Resetter => {
  return useResetRecoilState(storedIndexGlobalState);
};

// Stored Index Contractors Table global state (contractors table on the Index View Details page)

// IMPORTANT: Do NOT export, use hooks exported below
const storedIndexContractorsTableGlobalState = atom<ContractorsTableDataStateObject>({
  key: "storedIndexContractorsTableGlobalState",
  default: {
    ...contractorsTableConfigDefaults,
    data: emptyOrderedMap as unknown as ContractorsDataOrderedMap,
    itemsCount: 0,
    loaded: false,
    isSelectingRows: false,
    isBulkUpdateSelectingRows: false,
    selectedRows: emptyOrderedMap as unknown as ContractorsDataOrderedMap,
  },
});

export const useStoredIndexContractorsTableGlobalState = (
  tableId: string
): [
  ContractorsTableDataStateObject,
  SetterOrUpdater<ContractorsTableDataStateObject>,
  Resetter
] => {
  // IMPORTANT: indexData should be loaded and available by this moment
  const { indexTableConfig } = useRecoilValue(storedIndexGlobalState);
  return [
    ...useContractorsTableGlobalStateBase(
      tableId,
      storedIndexContractorsTableGlobalState,
      indexTableConfig
    ),
    useResetRecoilState(storedIndexContractorsTableGlobalState),
  ];
};

export const useResetStoredIndexContractorsTableGlobalState = (): Resetter => {
  return useResetRecoilState(storedIndexContractorsTableGlobalState);
};

// Discovery Search global state

type DiscoverySearchGlobalState = {
  searchResults: DiscoverySearchResultsList;
  searchTotals: DiscoverySearchResultTotalsMap;
  searchTerm: string | null;
  searchOffset: number;
  hasMoreResults: boolean;
  loaded: boolean;
};

export const discoverySearchGlobalState = atom<DiscoverySearchGlobalState>({
  key: "discoverySearchGlobalState",
  default: {
    searchResults: emptyList as DiscoverySearchResultsList,
    searchTotals: emptyMap as DiscoverySearchResultTotalsMap,
    searchTerm: null,
    searchOffset: 0,
    hasMoreResults: true,
    loaded: false,
  },
});

// Discovery Search Contractors Table global state

export const discoverySearchContractorsTableGlobalState =
  atom<ContractorsTableDataStateObject>({
    key: "discoverySearchContractorsTableGlobalState",
    default: {
      ...contractorsTableConfigDefaults,
      data: emptyOrderedMap as unknown as ContractorsDataOrderedMap,
      itemsCount: 0,
      loaded: false,
      isSelectingRows: false,
      isBulkUpdateSelectingRows: false,
      selectedRows: emptyOrderedMap as unknown as ContractorsDataOrderedMap,
    },
  });

export const useDiscoverySearchContractorsTableGlobalState = (
  tableId: string
): [
  ContractorsTableDataStateObject,
  SetterOrUpdater<ContractorsTableDataStateObject>,
  Resetter
] => [
  ...useContractorsTableGlobalStateBase(
    tableId,
    discoverySearchContractorsTableGlobalState
  ),
  useResetRecoilState(discoverySearchContractorsTableGlobalState),
];

// Running Processings global state

export const runningProcessingsAlertGlobalState = atom<
  RestfulTableDataStateObject<ProcessingDataMap>
>({
  key: "runningProcessingsTableGlobalState",
  default: {
    ...restfulTableConfigDefaults,
    itemsPerPage: 10,
    data: emptyOrderedMap as unknown as ProcessingsDataOrderedMap,
    itemsCount: 0,
    loaded: false,
  },
});

// Failed Contractors Table global state

export const failedContractorsTableGlobalState = atom<ContractorsTableDataStateObject>({
  key: "failedContractorsTableGlobalState",
  default: {
    ...contractorsTableConfigDefaults,
    data: emptyOrderedMap as unknown as ContractorsDataOrderedMap,
    itemsCount: 0,
    loaded: false,
    isSelectingRows: false, // not used
    isBulkUpdateSelectingRows: false, // not used
    selectedRows: emptyOrderedMap as unknown as ContractorsDataOrderedMap, // not used
  },
});

export const useFailedContractorsTableGlobalState = (
  tableId: string
): [
  ContractorsTableDataStateObject,
  SetterOrUpdater<ContractorsTableDataStateObject>,
  Resetter
] => [
  ...useContractorsTableGlobalStateBase(tableId, failedContractorsTableGlobalState),
  useResetRecoilState(failedContractorsTableGlobalState),
];

// Needs Approval Contractors Table global state

export const needApprovalContractorsTableGlobalState =
  atom<ContractorsTableDataStateObject>({
    key: "needApprovalContractorsTableGlobalState",
    default: {
      ...contractorsTableConfigDefaults,
      data: emptyOrderedMap as unknown as ContractorsDataOrderedMap,
      itemsCount: 0,
      loaded: false,
      isSelectingRows: false, // not used
      isBulkUpdateSelectingRows: false, // not used
      selectedRows: emptyOrderedMap as unknown as ContractorsDataOrderedMap, // not used
    },
  });

export const useNeedApprovalContractorsTableGlobalState = (
  tableId: string
): [
  ContractorsTableDataStateObject,
  SetterOrUpdater<ContractorsTableDataStateObject>,
  Resetter
] => [
  ...useContractorsTableGlobalStateBase(tableId, needApprovalContractorsTableGlobalState),
  useResetRecoilState(needApprovalContractorsTableGlobalState),
];

// Processings Table global state

export const contractorsProcessingTableGlobalState = atom<
  RestfulTableDataStateObject<ProcessingDataMap>
>({
  key: "contractorsProcessingTableGlobalState",
  default: {
    ...restfulTableConfigDefaults,
    itemsPerPage: 25,
    data: emptyOrderedMap as unknown as ProcessingsDataOrderedMap,
    itemsCount: 0,
    loaded: false,
  },
});

// Upload Form global state

type ContractorsUploadFormGlobalState = {
  title: string | null;
  industry: { id: number; name: string } | null;
  file: File | null;
  autofillDescription: boolean;
  uploadingState: FILE_UPLOADING_STATES_TYPE;
  detailsText: string | null;
};

export const contractorsUploadFormGlobalState = atom<ContractorsUploadFormGlobalState>({
  key: "contractorsUploadFormGlobalState",
  default: {
    title: null,
    industry: null,
    file: null,
    autofillDescription: false,
    uploadingState: FILE_UPLOADING_STATES.INITIAL,
    detailsText: null,
  },
});

// Latest Uploads List global state

export type ContractorsUploadsListGlobalState = {
  data: ContractorsUploadsDataList;
  itemsCount: number;
  activePage: number;
  itemsPerPage: number;
  search: string;
  loaded: boolean;
};

export const contractorsUploadsListGlobalState = atom<ContractorsUploadsListGlobalState>({
  key: "uploadsFlowGlobalState",
  default: {
    data: emptyList as unknown as ContractorsUploadsDataList,
    itemsCount: 0,
    activePage: 1,
    itemsPerPage: 20,
    search: "",
    loaded: false,
  },
});

// Upload Contractors Table global state

export const uploadContractorsTableGlobalState = atom<ContractorsTableDataStateObject>({
  key: "uploadContractorsTableGlobalState",
  default: {
    ...contractorsTableConfigDefaults,
    data: emptyOrderedMap as unknown as ContractorsDataOrderedMap,
    itemsCount: 0,
    loaded: false,
    isSelectingRows: false, // not used
    isBulkUpdateSelectingRows: false, // not used
    selectedRows: emptyOrderedMap as unknown as ContractorsDataOrderedMap, // not used
  },
});

export const useUploadContractorsTableGlobalState = (
  tableId: string
): [
  ContractorsTableDataStateObject,
  SetterOrUpdater<ContractorsTableDataStateObject>,
  Resetter
] => [
  ...useContractorsTableGlobalStateBase(tableId, uploadContractorsTableGlobalState),
  useResetRecoilState(uploadContractorsTableGlobalState),
];
