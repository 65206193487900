import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HistogramChart from "../../components/rate_search/HistogramChart";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import RateCardsList from "../ratecards/components/RateCardsList";
import SearchBox from "../ratecards/components/SearchBox";
import ShowMore from "react-show-more";
import SearchPeek from "../searches/components/SearchPeek";
import Modal from "../ratecards/components/Modal";
import ContainerSection from "../ratecards/components/ContainerSection";
import numeral from "numeral";
import percentile from "../../utils/percentile";
import ArrowChart from "../../components/charts/ArrowChart";
import PayRateBarChart from "../../components/charts/PayRateBarChart";
import CreateNegotiationWorksheet from "../negotiation_worksheets/CreateNegotiationWorksheet";
import CheckBox from "../ratecards/components/CheckBox";
import { RATE_CARD_ACTION } from "../../stores/mobx/BatchSearchCreateStore";
import InputBox from "../../components/inputs/InputBox";
import { RATE_TYPE } from "../../constants/search";
import PunchOutsView from "./PunchOutsView";
import Tippy from "@tippyjs/react";
import RateCardListItem from "../ratecards/components/RateCardListItem";
import CurrentUser from "../../models/User";
import LevelsGuideModal from "../../components/modals/LevelsGuideModal";

/**
 * RateDetail component
 */
class RateDetail extends Component {
  /**
   * constructor
   * @param {props} props The first number.
   */
  constructor(props) {
    super(props);

    this.createAnotherBuyRate = this.createAnotherBuyRate.bind(this);
    const rateSearchStore = this.props.rateSearchStore;
    rateSearchStore.sessionInfo = props.sessionInfo;
    rateSearchStore.rateCardsListStore.currentUser = new CurrentUser(
      rateSearchStore.rateCardsListStore,
      props.sessionInfo?.user
    );
    rateSearchStore.showAddToRateCardModal = false;
  }

  createAnotherBuyRate() {
    let rateSearchStore = this.props.rateSearchStore;
    let dataToDisplay = this.props.rateData.find(
      (rates) => rates.rateType === rateSearchStore.rateType
    );

    rateSearchStore.createAnotherBuyRate(dataToDisplay);
  }

  render() {
    let {
      rateData,
      rateSearchStore,
      rateSearchData,
      draftResults,
      clientIsJobLibrarySubscription,
    } = this.props;
    let dataToDisplay = rateData.find(
      (rates) => rates.rateType === rateSearchStore.rateType
    );
    // console.log("dataToDisplay:", dataToDisplay);
    // console.log("rateSearchData:", rateSearchData);
    let localData = null;
    if (rateSearchData && rateSearchData.length > 0) {
      localData = rateSearchData.find(
        (rsd) => rsd.searchId[0] === dataToDisplay.searchId
      );
    }
    const worksheetAvailableForLocation =
      localData?.location?.hasWorksheetSchema || false;
    // let searchId = dataToDisplay.searchId;
    let searchIds = rateData.map((rates) => rates.searchId);
    let draftSearch = dataToDisplay.isDraft;

    const isContingent = rateSearchStore.rateType === RATE_TYPE.HOURLY;

    const currency = dataToDisplay.currency
      ? { symbol: dataToDisplay.currency.symbol }
      : { symbol: "$ " };
    const payRateAvgTotal = draftResults
      ? rateData.results.rates.payRate.avg[2]
      : dataToDisplay.rates.payRate.avg[2];
    let payRateAvgMedian = draftResults
      ? rateData.results.rates.payRate.mid[2]
      : dataToDisplay.rates.payRate.mid[2];
    const markupAvgTotal = draftResults
      ? rateData.results.rates.markupPct.avg[2]
      : dataToDisplay.rates.markupPct.avg[2];
    let markupAvgMedian = draftResults
      ? rateData.results.rates.markupPct.mid[2]
      : dataToDisplay.rates.markupPct.mid[2];
    const billRateAvgTotal = draftResults
      ? rateData.results.rates.billRate.avg[2]
      : dataToDisplay.rates.billRate.avg[2];
    let billRateAvgMedian = draftResults
      ? rateData.results.rates.billRate.mid[2]
      : dataToDisplay.rates.billRate.mid[2];
    const showMarkupAndBillRate = rateSearchStore.rateType === 1;

    let arrowChartData = {
      currency,
      rateType: rateSearchStore.rateType,
      mid: percentile(50, dataToDisplay.ratesData),
      percentiles: {
        10: percentile(10, dataToDisplay.ratesData),
        25: percentile(25, dataToDisplay.ratesData),
        75: percentile(75, dataToDisplay.ratesData),
        90: percentile(90, dataToDisplay.ratesData),
      },
    };

    let barChartData = {
      currency,
      min: dataToDisplay.rates.payRate.min[0],
      max: dataToDisplay.rates.payRate.max[4],
    };

    // NOTE: setting the className attr on the TabPanel overrides
    // "display: none" with "display: flex" making the Tab appear
    // in all other tabs. So we conditionally set the className here
    let tabPanelWorksheet = {};
    if (rateSearchStore.tabIndex === 1) {
      tabPanelWorksheet = { className: "regions" };
    }

    return (
      <div key={rateSearchStore.rateType}>
        <div className="info_head">
          <h2 style={{ lineHeight: 1 }}>
            {/*{dataToDisplay.job.jobLabel && dataToDisplay.job.jobLabel}*/}
            {dataToDisplay.job.jobLabel}
            <br />
            <span className="text-muted">{dataToDisplay.job.jobTitle}</span>
          </h2>
          {clientIsJobLibrarySubscription && (
            <button
              className="btn btn-default pull-right"
              onClick={() => rateSearchStore.showDataPolicy()}
            >
              <FontAwesomeIcon icon="sticky-note" aria-hidden="true" />
              <span> Data Policy</span>
            </button>
          )}
          <div className="city">
            {dataToDisplay.region?.name
              ? dataToDisplay.region?.name
              : `${dataToDisplay.city ? dataToDisplay.city + ",  " : ""} ${
                  dataToDisplay.state || ""
                }`}{" "}
            {dataToDisplay.region || dataToDisplay.state || dataToDisplay.city ? (
              <span className="pipe">|</span>
            ) : (
              ""
            )}
            {dataToDisplay.country}
          </div>
          {dataToDisplay.workerTypeName && (
            <div className="city">{dataToDisplay.workerTypeName}</div>
          )}
          {Boolean(dataToDisplay.isGSS || dataToDisplay.isGlobalSupplierSearch) && (
            <div className="city">
              <FontAwesomeIcon icon="globe" fixedWidth className="icon" /> Global Supplier
              Search
            </div>
          )}
          <div>
            <ShowMore lines={2} more="more" less="less" anchorClass="">
              {dataToDisplay.job.jobDescription}
            </ShowMore>
          </div>
        </div>

        <div className="text-right bb-right">
          {draftSearch && (
            <button
              className="btn btn-default"
              disabled={!draftSearch}
              onClick={rateSearchStore.addToRateCard}
            >
              Add to Rate Card
            </button>
          )}
          {rateData.length > 1 && (
            <div className="btn-group btn-group-sm pull-right mb-12">
              <button
                type="button"
                onClick={() => rateSearchStore.toggleRateType(1)}
                className={
                  rateSearchStore.rateType === 1
                    ? "btn btn-primary active mr-none"
                    : "btn btn-default mr-none"
                }
              >
                Hourly
              </button>
              <button
                type="button"
                onClick={() => rateSearchStore.toggleRateType(2)}
                className={
                  rateSearchStore.rateType === 2
                    ? "btn btn-primary active mr-none"
                    : "btn btn-default mr-none"
                }
              >
                Annual
              </button>
            </div>
          )}
        </div>

        <Tabs
          selectedIndex={rateSearchStore.tabIndex}
          onSelect={(tabIndex) => rateSearchStore.tabIndexOnSelect(tabIndex)}
        >
          <TabList>
            <Tab>Overview</Tab>
            {isContingent && worksheetAvailableForLocation && (
              <Tab>Negotiation Worksheet</Tab>
            )}
            <Tab>Punchouts</Tab>
            <Tab>Statistics</Tab>
          </TabList>
          <TabPanel>
            <div className="row" style={{ fontSize: 14 }}>
              {!showMarkupAndBillRate && (
                <div className="col-sm-4 list-rate">
                  <div className="rate_box" />
                  <div className="rate_box" />
                </div>
              )}
              <div className="col-sm-4 list-rate">
                <div className="info-box ">
                  <Tippy
                    placement="top"
                    content={
                      <div
                        style={{
                          maxHeight: "300px",
                          overflowY: "auto",
                          fontSize: "14px",
                          textAlign: "left",
                          padding: "5px",
                        }}
                      >
                        Values shown from level III of rate results
                      </div>
                    }
                  >
                    {/* NOTE: We wrap in span to workaround FontAwesomeIcon component ref issue, https://github.com/atomiks/tippyjs-react#component-children */}
                    <span>
                      <FontAwesomeIcon icon="info-circle" aria-hidden="true" />
                    </span>
                  </Tippy>
                  <span>PAY RATE</span>
                </div>
                <div className="rate_box left-r" data-testid="average-pay-rate">
                  <h2>{currency.symbol + numeral(payRateAvgTotal).format("0,0.00")}</h2>
                  <small> Average</small>
                </div>
                <div className="rate_box" data-testid="median-pay-rate">
                  <h2>{currency.symbol + numeral(payRateAvgMedian).format("0,0.00")}</h2>
                  <small> Median</small>
                </div>
              </div>
              {showMarkupAndBillRate && (
                <div className="col-sm-4 list-rate">
                  <div className="info-box info-box_second ">
                    <Tippy
                      placement="top"
                      content={
                        <div
                          style={{
                            maxHeight: "300px",
                            overflowY: "auto",
                            fontSize: "14px",
                            textAlign: "left",
                            padding: "5px",
                          }}
                        >
                          Values shown from level III of rate results
                        </div>
                      }
                    >
                      <span>
                        <FontAwesomeIcon icon="info-circle" aria-hidden="true" />
                      </span>
                    </Tippy>
                    <span>MARKUP</span>
                  </div>
                  <div className="rate_box left-r" data-testid="average-markup">
                    <h2>{markupAvgTotal.toFixed(2)}%</h2>
                    <small> Average</small>
                  </div>
                  <div className="rate_box" data-testid="median-markup">
                    <h2>{markupAvgMedian.toFixed(2)}%</h2>
                    <small> Median</small>
                  </div>
                </div>
              )}
              {showMarkupAndBillRate && (
                <div className="col-sm-4 list-rate">
                  <div className="info-box info-box_third">
                    <Tippy
                      placement="top"
                      content={
                        <div
                          style={{
                            maxHeight: "300px",
                            overflowY: "auto",
                            fontSize: "14px",
                            textAlign: "left",
                            padding: "5px",
                          }}
                        >
                          Values shown from level III of rate results
                        </div>
                      }
                    >
                      <span>
                        <FontAwesomeIcon icon="info-circle" aria-hidden="true" />
                      </span>
                    </Tippy>
                    <span>BILL RATE</span>
                  </div>
                  <div className="rate_box left-r" data-testid="average-bill-rate">
                    <h2>
                      {currency.symbol + numeral(billRateAvgTotal).format("0,0.00")}
                    </h2>
                    <small> Average</small>
                  </div>
                  <div className="rate_box" data-testid="median-bill-rate">
                    <h2>
                      {currency.symbol + numeral(billRateAvgMedian).format("0,0.00")}
                    </h2>
                    <small> Median</small>
                  </div>
                </div>
              )}
            </div>
            <div className="ratesearch-chart-container">
              <ArrowChart className="ratesearch-arrow-chart" data={arrowChartData} />
              <PayRateBarChart
                className="ratesearch-bar-chart"
                jobRateType={rateSearchStore.rateType}
                data={barChartData}
              />
            </div>
            <br />
            <SearchPeek
              store={rateSearchStore}
              expanded="true"
              search={rateSearchStore.searches}
              showRateDisplayToggle={isContingent}
              disableRateChangesUI={true}
              extraActions={[
                <button
                  key="levels-guide-button"
                  className="btn btn-default"
                  onClick={rateSearchStore.showLevelsGuide}
                >
                  <FontAwesomeIcon icon="info-circle" aria-hidden="true" />
                  <span> Levels Guide</span>
                </button>,
              ]}
            />
            <div className="clearfix" />
            {draftSearch && (
              <button
                className="btn btn-lg btn-default pull-right btn-green save-btn margin-top-20"
                onClick={rateSearchStore.addToRateCard}
              >
                Add to Rate Card
              </button>
            )}
            <div className="clearfix" />
          </TabPanel>
          {isContingent && worksheetAvailableForLocation && (
            <TabPanel {...tabPanelWorksheet}>
              <CreateNegotiationWorksheet
                store={this.props.mobxStore}
                location={this.props.routerLocation}
              />
            </TabPanel>
          )}

          <TabPanel>
            <PunchOutsView rateSearchStore={rateSearchStore} />
          </TabPanel>

          <TabPanel>
            <a
              href="#toggle-histogram"
              className="hide-show-graph"
              onClick={() =>
                rateSearchStore.toggleHistogram(rateSearchStore.showHistogram)
              }
            >
              {rateSearchStore.showHistogram ? "Hide " : "Show "}
              Histogram
            </a>
            <div className="clearfix" />
            {rateSearchStore.showHistogram ? (
              <HistogramChart
                active={true}
                results={draftResults ? dataToDisplay.ratesData : dataToDisplay.ratesData}
              />
            ) : (
              ""
            )}
            {/*{dataToDisplay.country === "United States" && (*/}
            {/*<RateSearchOccupationData jobTitleId={dataToDisplay.job.jobTitleId} rateSearchStore={rateSearchStore} />*/}
            {/*)}*/}
          </TabPanel>
        </Tabs>

        <LevelsGuideModal
          show={rateSearchStore.showLevelsGuidePopup}
          onHide={rateSearchStore.closeLevelsGuide}
        />

        <Modal
          show={rateSearchStore.showDataPolicyPopup}
          onHide={() => rateSearchStore.closeDataPolicy()}
          innerStyle={{
            width: "70% !important",
          }}
        >
          <ContainerSection className="header">
            <h4>Data Policy</h4>
          </ContainerSection>
          <ContainerSection>
            {/* <a href="http://www.peopleticker.com/privacy" target="_blank" rel="noopener noreferrer">
              Terms of Use and Privacy Policy
            </a> */}
          </ContainerSection>
          <ContainerSection className="footer">
            <div className="pull-right">
              <button
                className="btn btn-lg btn-default"
                onClick={() => rateSearchStore.closeDataPolicy()}
              >
                Ok
              </button>
            </div>
          </ContainerSection>
        </Modal>
        <Modal
          show={rateSearchStore.showAddToRateCardModal}
          onHide={rateSearchStore.closeAddToRateCardModal}
          innerStyle={{ width: "100%" }}
          fullScreen={true}
        >
          <div className="container-section header">
            <h4>Choose Rate Card to add searches to</h4>
          </div>

          <div className="rc-viewport overlap-lr-padding">
            <ContainerSection className="overlap-t-padding">
              <div
                className="rc-checkbox-group form-horizontal"
                style={{ paddingTop: 10 }}
              >
                <CheckBox
                  style={{ width: 230 }}
                  type="radio"
                  name="rateCardAction"
                  value={RATE_CARD_ACTION.USE_EXISTING}
                  selected={
                    rateSearchStore.rateCardAction === RATE_CARD_ACTION.USE_EXISTING
                  }
                  onChange={rateSearchStore.handleRateCardAction}
                >
                  Use Existing Rate Card
                </CheckBox>
                <CheckBox
                  style={{ width: 230 }}
                  type="radio"
                  name="rateCardAction"
                  value={RATE_CARD_ACTION.CREATE}
                  selected={rateSearchStore.rateCardAction === RATE_CARD_ACTION.CREATE}
                  onChange={rateSearchStore.handleRateCardAction}
                >
                  Create New Rate Card
                </CheckBox>
                {rateSearchStore.lastRateCardUsed && (
                  <CheckBox
                    style={{ width: 230 }}
                    type="radio"
                    name="rateCardAction"
                    value={RATE_CARD_ACTION.USE_PREVIOUS}
                    selected={
                      rateSearchStore.rateCardAction === RATE_CARD_ACTION.USE_PREVIOUS
                    }
                    onChange={rateSearchStore.handleRateCardAction}
                  >
                    Same Rate Card I Used Last Time
                  </CheckBox>
                )}
              </div>
              {rateSearchStore.rateCardAction === RATE_CARD_ACTION.USE_EXISTING && (
                <div>
                  <div className="header-action-bar">
                    <SearchBox
                      value={
                        rateSearchStore.rateCardsListStore.rateCardLabelFilter
                          .textToLookFor
                      }
                      onChange={
                        rateSearchStore.rateCardsListStore.rateCardLabelFilter
                          .onTextToLookForChange
                      }
                      onSearch={
                        rateSearchStore.rateCardsListStore.rateCardLabelFilter.onApply
                      }
                      style={{ width: "260px" }}
                    />
                  </div>
                  <RateCardsList store={rateSearchStore.rateCardsListStore} />
                </div>
              )}
              {rateSearchStore.rateCardAction === RATE_CARD_ACTION.CREATE && (
                <InputBox
                  fill
                  value={rateSearchStore.newRateCardName}
                  onChange={rateSearchStore.onNewRateCardNameChange}
                  placeholder="Enter Rate Card name..."
                />
              )}
              {rateSearchStore.rateCardAction === RATE_CARD_ACTION.USE_PREVIOUS &&
                rateSearchStore.lastRateCardUsed && (
                  <div>
                    <div className="ratecards-list no-border">
                      <div className="ratecards-table ratecards-table-fill-container no-border">
                        <div data-testid="previous-rate-card">
                          <RateCardListItem
                            key={rateSearchStore.lastRateCardUsed.ratecardId}
                            store={rateSearchStore.rateCardsListStore}
                            rateCard={rateSearchStore.lastRateCardUsed}
                            editing={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </ContainerSection>
          </div>
          <ContainerSection className="footer">
            <div className="pull-right">
              <button
                className="btn btn-lg"
                onClick={rateSearchStore.closeAddToRateCardModal}
              >
                Cancel
              </button>
              <button
                className="btn btn-lg btn-green"
                onClick={async (e) => {
                  await rateSearchStore.addSearchesToRateCard(searchIds);
                  // rateSearchStore.closeAddToRateCardModal();
                }}
              >
                Add
              </button>
            </div>
          </ContainerSection>
        </Modal>
      </div>
    );
  }
}

RateDetail.propTypes = {
  rateSearchStore: PropTypes.object.isRequired,
};

export default observer(RateDetail);
