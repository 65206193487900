/**
 * @generated SignedSource<<e02226e3ad0a51dc900e58e348990048>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SortDirection = "ASC" | "DESC" | "%future added value";
export type TagSortField = "CREATED_BY" | "CREATE_DATE" | "NAME" | "OWNER_FIRST_NAME" | "OWNER_LAST_NAME" | "SHARE" | "UPDATE_DATE" | "%future added value";
export type TagsFiltersInput = {
  clientIdIn?: ReadonlyArray<string | null> | null;
  clientidIn?: ReadonlyArray<number | null> | null;
  fromDate?: string | null;
  isShared?: boolean | null;
  nameIContains?: string | null;
  ownerId?: ReadonlyArray<string | null> | null;
  toDate?: string | null;
  updatedFromDate?: string | null;
  updatedToDate?: string | null;
};
export type TagsSortInput = {
  caseSensitive?: boolean | null;
  direction?: SortDirection | null;
  field: TagSortField;
};
export type AdminTagsPaginationQuery$variables = {
  count?: number | null;
  cursor?: string | null;
  filters?: TagsFiltersInput | null;
  order?: ReadonlyArray<TagsSortInput | null> | null;
};
export type AdminTagsPaginationQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"AdminTags_viewer">;
  } | null;
};
export type AdminTagsPaginationQuery = {
  response: AdminTagsPaginationQuery$data;
  variables: AdminTagsPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v4 = {
  "kind": "Variable",
  "name": "filters",
  "variableName": "filters"
},
v5 = {
  "kind": "Variable",
  "name": "order",
  "variableName": "order"
},
v6 = {
  "kind": "Literal",
  "name": "section",
  "value": "ADMIN"
},
v7 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v4/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v5/*: any*/),
  (v6/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasNextPage",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endCursor",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientId",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v18 = [
  "filters"
],
v19 = {
  "kind": "Literal",
  "name": "filters",
  "value": {}
},
v20 = {
  "kind": "Literal",
  "name": "first",
  "value": 10
},
v21 = [
  (v19/*: any*/),
  (v20/*: any*/),
  {
    "kind": "Literal",
    "name": "order",
    "value": [
      {
        "direction": "ASC",
        "field": "NAME"
      }
    ]
  }
],
v22 = [
  (v19/*: any*/),
  (v20/*: any*/),
  {
    "kind": "Literal",
    "name": "order",
    "value": [
      {
        "direction": "ASC",
        "field": "USERNAME"
      }
    ]
  },
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminTagsPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "AdminTags_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "AdminTagsPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "TagNodeConnection",
            "kind": "LinkedField",
            "name": "tags",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPreviousPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TagNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TagNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "tagId",
                        "storageKey": null
                      },
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "created",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LegacyUserNode",
                        "kind": "LinkedField",
                        "name": "owner",
                        "plural": false,
                        "selections": [
                          (v14/*: any*/),
                          (v15/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ClientNode",
                            "kind": "LinkedField",
                            "name": "client",
                            "plural": false,
                            "selections": [
                              (v16/*: any*/),
                              (v13/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v17/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v7/*: any*/),
            "filters": (v18/*: any*/),
            "handle": "connection",
            "key": "AdminTags_tags",
            "kind": "LinkedHandle",
            "name": "tags"
          },
          {
            "alias": null,
            "args": (v21/*: any*/),
            "concreteType": "ClientTypeNodeConnection",
            "kind": "LinkedField",
            "name": "allClients",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ClientTypeNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClientTypeNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v16/*: any*/),
                      (v13/*: any*/),
                      (v17/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "allClients(filters:{},first:10,order:[{\"direction\":\"ASC\",\"field\":\"NAME\"}])"
          },
          {
            "alias": null,
            "args": (v21/*: any*/),
            "filters": (v18/*: any*/),
            "handle": "connection",
            "key": "FilterAndSortClients_allClients",
            "kind": "LinkedHandle",
            "name": "allClients"
          },
          {
            "alias": null,
            "args": (v22/*: any*/),
            "concreteType": "AuthorNodeConnection",
            "kind": "LinkedField",
            "name": "authors",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AuthorNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuthorNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v14/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastName",
                        "storageKey": null
                      },
                      (v15/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      (v17/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "authors(filters:{},first:10,order:[{\"direction\":\"ASC\",\"field\":\"USERNAME\"}],section:\"ADMIN\")"
          },
          {
            "alias": null,
            "args": (v22/*: any*/),
            "filters": (v18/*: any*/),
            "handle": "connection",
            "key": "FilterAndSortCreatedBy_authors",
            "kind": "LinkedHandle",
            "name": "authors"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8bdfef3fe2bad4ccb2ee7c3d72a491f5",
    "id": null,
    "metadata": {},
    "name": "AdminTagsPaginationQuery",
    "operationKind": "query",
    "text": "query AdminTagsPaginationQuery(\n  $filters: TagsFiltersInput\n  $order: [TagsSortInput]\n  $count: Int\n  $cursor: String\n) {\n  viewer {\n    ...AdminTags_viewer_1CnIei\n  }\n}\n\nfragment AdminTags_viewer_1CnIei on Viewer {\n  tags(first: $count, after: $cursor, filters: $filters, order: $order, section: ADMIN) {\n    pageInfo {\n      hasNextPage\n      endCursor\n      hasPreviousPage\n      startCursor\n    }\n    totalCount\n    edges {\n      cursor\n      node {\n        id\n        tagId\n        ...Tag_tag\n        __typename\n      }\n    }\n  }\n  ...FilterAndSortClients_viewer\n  ...FilterAndSortCreatedBy_viewer\n}\n\nfragment FilterAndSortClients_viewer on Viewer {\n  allClients(first: 10, filters: {}, order: [{direction: ASC, field: NAME}]) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalCount\n    edges {\n      node {\n        id\n        clientId\n        name\n        __typename\n      }\n      cursor\n    }\n  }\n}\n\nfragment FilterAndSortCreatedBy_viewer on Viewer {\n  authors(first: 10, filters: {}, order: [{direction: ASC, field: USERNAME}], section: ADMIN) {\n    edges {\n      node {\n        id\n        userId\n        firstName\n        lastName\n        username\n        email\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment Tag_tag on TagNode {\n  id\n  tagId\n  name\n  created\n  owner {\n    userId\n    username\n    client {\n      clientId\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1cfa875e247948c2bb88533321709b26";

export default node;
