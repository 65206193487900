// @flow
import * as React from "react";
import classNames from "classnames";
import graphql from "babel-plugin-relay/macro";
import { RelayRefetchProp, useRelayEnvironment } from "react-relay";
import { createRefetchContainer, QueryRenderer } from "react-relay";
import type { RenderProps } from "react-relay/ReactRelayQueryRenderer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import LoadingIndicator from "../views/shared/LoadingIndicator";
import Job from "./Job";
import SearchBox from "./SearchBox";
import ToggleButton from "../views/ratecards/components/ToggleButton";
import Modal from "../views/ratecards/components/Modal";
import FilterAndSortTags from "./FilterAndSortTags";
import FilterAndSortClients from "./FilterAndSortClients";
import FilterAndSortCreatedBy from "./FilterAndSortCreatedBy2";
import FilterAndSortCreatedOn from "./FilterAndSortCreatedOn";
import type { FetchAPI } from "../App";
import type { AdminClientJobsQueryResponse } from "./__generated__/AdminClientJobsQuery.graphql";
import type { AdminClientJobs_viewer } from "./__generated__/AdminClientJobs_viewer.graphql";
import WindowPagination from "./WindowPagination";
import { useSelectionState } from "../utils/hooks";

type AdminClientJobsListProps = {|
  fetchAPI: FetchAPI,
  +viewer: ?AdminClientJobs_viewer,
  // relay: {
  //   // TODO: put correct types
  //   environment: any,
  //   hasMore: () => boolean,
  //   isLoading: () => boolean,
  //   loadMore: (pageSize: number, callback: ?(error: ?Error) => void, options?: { force: boolean }) => any,
  //   refetchConnection: (
  //     totalCount: number,
  //     callback: (error: ?Error) => void,
  //     refetchVariables: {
  //       filters?: JobFilters
  //     }
  //   ) => any
  // },
  +relay: RelayRefetchProp,
|};

const INITIAL_FILTER_SORT_STATE = {};

function AdminClientJobsList(props: AdminClientJobsListProps) {
  // TODO: Handle selection across page changes
  let [editing, setEditing] = React.useState(false);
  let {
    selectionState,
    isSelected,
    hasSelection,
    handleToggleSelect,
    handleToggleSelectAll,
    handleToggleSelectAllOnPage,
    resetSelectionState,
  } = useSelectionState();
  let [clientsFilterOpen, setClientsFilterOpen] = React.useState(false);
  let [tagFilterOpen, setTagFilterOpen] = React.useState(false);
  let [createdByFilterOpen, setCreatedByFilterOpen] = React.useState(false);
  let [createdOnFilterOpen, setCreatedOnFilterOpen] = React.useState(false);
  let [showHelp, setShowHelp] = React.useState(false);
  let [error, setError] = React.useState(null);
  let [loading, setLoading] = React.useState(false);
  let [refetchVars, setRefetchVars] = React.useState(INITIAL_FILTER_SORT_STATE);

  const pageInfo = props.viewer.jobs?.pageInfo;
  const totalCount = props.viewer?.jobs?.totalCount || 0;
  const jobs = props.viewer?.jobs?.edges || [];
  const { selectAllOnPage, selectAll } = selectionState;
  const isFiltered = refetchVars.filters
    ? Object.keys(refetchVars.filters).length > 0
    : false;
  const clientsCurrentFilter = refetchVars.filters?.hasOwnProperty("clientIdIn")
    ? refetchVars.filters
    : null;
  const createdByCurrentFilter = refetchVars.filters?.hasOwnProperty("createdById")
    ? refetchVars.filters
    : null;
  const createdOnCurrentFilter =
    refetchVars.filters?.hasOwnProperty("fromDate") ||
    refetchVars.filters?.hasOwnProperty("toDate")
      ? refetchVars.filters
      : null;
  const tagsCurrentFilter = refetchVars.filters?.hasOwnProperty("tagIdIn")
    ? refetchVars.filters
    : null;

  function refetch(newVars = {}, onFetchFinish) {
    setLoading(true);
    props.relay.refetch(
      (fragmentVariables) => {
        return {
          ...fragmentVariables,
          // ...refetchVars,
          ...newVars,
        };
      },
      null,
      (error) => {
        setLoading(false);
        if (error) {
          console.error(error); // eslint-disable-line no-console
          return;
        }

        if (onFetchFinish) {
          onFetchFinish();
        }
      }
    );
  }

  function addFilters(
    vars: { filters?: { [filterKey: string]: mixed } },
    newFilters: { [filterKey: string]: mixed }
  ) {
    return {
      ...vars,
      filters: {
        ...vars.filters,
        ...newFilters,
      },
    };
  }

  function removeFilters(
    vars: { filters?: { [filterKey: string]: mixed } },
    filterKeys: string[]
  ) {
    let newRefetchVars = {
      ...vars,
      filters: {
        ...vars.filters,
      },
    };

    filterKeys.forEach((k) => delete newRefetchVars.filters[k]);

    return newRefetchVars;
  }

  function handleSearch(search) {
    let newRefetchVars = addFilters(refetchVars, { jobLabelIContains: search });

    refetch(newRefetchVars, () => {
      setRefetchVars(newRefetchVars);
    });
  }

  function clearFilterSort(): void {
    refetch({}, () => {
      setRefetchVars({});
    });
  }

  // TODO: Finish the export jobs function
  let handleExportJobs = () => {
    const exportURL = "job-labels/basic/list/export/excel/";
    const fileName = "export_jobs_" + Math.floor(Math.random() * 9999999 + 1000000);

    let filters = refetchVars.filters ?? {};
    let exportOptions = {
      fileName,
      section: "admin",
    };

    if (selectionState.selectedItems.length > 0) {
      filters.only = selectionState.selectedItems;
      exportOptions.jobsFilters = filters;
    }
    // TODO: Handle other filters
    // if (selectionState.selectAllOnPage) {
    // }

    props
      .fetchAPI(exportURL, exportOptions)
      .then((res) => {
        window.location.href = res.data.url;
        handleStopEditing();
      })
      .catch((error) => {
        console.error(error);
        setError(error);
        handleStopEditing();
      });
  };

  function handleClientsFilterSortApply(clientFilter) {
    setClientsFilterOpen(false);

    let newRefetchVars;
    if (clientFilter === null) {
      newRefetchVars = removeFilters(refetchVars, ["clientIdIn"]);
    } else {
      newRefetchVars = addFilters(refetchVars, clientFilter);
    }

    refetch(newRefetchVars, () => {
      setRefetchVars(newRefetchVars);
    });
  }

  function handleCreatedByFilterSortApply(createdByFilter) {
    setCreatedByFilterOpen(false);

    let newRefetchVars;
    if (createdByFilter === null) {
      newRefetchVars = removeFilters(refetchVars, ["createdById"]);
    } else {
      newRefetchVars = addFilters(refetchVars, createdByFilter);
    }

    refetch(newRefetchVars, () => {
      setRefetchVars(newRefetchVars);
    });
  }

  function handleCreatedOnFilterSortApply(createdOnFilter) {
    setCreatedOnFilterOpen(false);

    let newRefetchVars;
    if (createdOnFilter === null) {
      newRefetchVars = removeFilters(refetchVars, ["fromDate", "toDate"]);
    } else {
      newRefetchVars = addFilters(refetchVars, createdOnFilter);
    }

    refetch(newRefetchVars, () => {
      setRefetchVars(newRefetchVars);
    });
  }

  function handleTagFilterSortApply(tagFilter) {
    setTagFilterOpen(false);

    let newRefetchVars;
    if (tagFilter === null) {
      newRefetchVars = removeFilters(refetchVars, ["tagIdIn"]);
    } else {
      newRefetchVars = addFilters(refetchVars, tagFilter);
    }

    refetch(newRefetchVars, () => {
      setRefetchVars(newRefetchVars);
    });
  }

  function handlePageChange(args: {| before?: ?string, after?: ?string |}) {
    const ITEMS_COUNT = 10;

    return {
      first: args.after != null ? ITEMS_COUNT : null,
      after: args.after,
      last: args.before != null ? ITEMS_COUNT : null,
      before: args.before,
    };
  }

  function prevPage() {
    let pageVars = handlePageChange({ before: pageInfo.startCursor });

    refetch({ ...refetchVars, ...pageVars });
  }

  function nextPage() {
    let pageVars = handlePageChange({ after: pageInfo.endCursor });

    refetch({ ...refetchVars, ...pageVars });
  }

  function handleStopEditing() {
    setEditing(false);
    resetSelectionState();
  }

  function handleSelectOnPage() {
    handleToggleSelectAllOnPage(jobs.map((j) => j.node.jobId));
  }

  return (
    <div className="view list jobclients">
      <div className="rc-container bring-forward">
        <div className="header-action-bar">
          <h2>Job Labels Admin</h2>
          <p className="subtext">Manage all job labels in the system.</p>
          <div className="clearfix" />
        </div>
        <div className="header-action-bar marginTopzero">
          <div className="pull-left">
            <SearchBox
              onSearch={async (searchText: string) => {
                // NOTE: should handle when "select all *" and reset on search?
                if (searchText === "") {
                  handleSearch(searchText);
                } else {
                  handleSearch(searchText);
                }
              }}
              disabled={loading}
              style={{
                width: "260px",
              }}
            />
          </div>
          <div className="btn-group pull-right rc-center">
            {editing ? (
              <button className="btn btn-yellow" onClick={handleStopEditing}>
                Stop Editing
              </button>
            ) : (
              <div>
                <button className="btn btn-green" onClick={() => setEditing(true)}>
                  <FontAwesomeIcon icon="edit" fixedWidth className="icon" />
                  Edit
                </button>
                <button className="btn" onClick={() => setShowHelp(true)}>
                  <FontAwesomeIcon icon="question-circle" fixedWidth className="icon" />
                  Help
                </button>
              </div>
            )}
          </div>
          <div className="clearfix" />
        </div>
        <div className="ratecards-list">
          <header className="ratecards-table-header">
            {editing && (
              <nav className="header-left-nav">
                <div className="btn btn-toggle" onClick={handleSelectOnPage}>
                  <FontAwesomeIcon
                    icon={selectAllOnPage || selectAll ? "check-square" : "square"}
                    className={classNames("rc-check-icon", {
                      selected: selectAllOnPage || selectAll,
                    })}
                  />{" "}
                  {selectAllOnPage || selectAll ? "Deselect All" : "Select All"}
                </div>
              </nav>
            )}
            <nav className="header-right-nav alt-1">
              {isFiltered && (
                <div className="btn btn-yellow" onClick={clearFilterSort}>
                  Clear All Filters & Sorts
                </div>
              )}
              <ToggleButton
                borderless={true}
                name="search-list-filter"
                value={""}
                selected={refetchVars.filters?.clientIdIn !== undefined}
                onClick={() => setClientsFilterOpen(true)}
              >
                Clients <span> ▼</span>
              </ToggleButton>
              {/* TODO: Finish tags */}
              <ToggleButton
                borderless={true}
                name="search-list-filter"
                value={""}
                selected={refetchVars.filters?.tagIdIn !== undefined}
                onClick={() => setTagFilterOpen(true)}
              >
                {/* <SortIndicator
                  sortColumn={}
                  sortsOrder={store.appliedSortsOrder}
                  sort={store.appliedSorts[FILTER_COLUMN.TAGS]}
                /> */}
                Tags <span> ▼</span>
              </ToggleButton>
              <ToggleButton
                borderless={true}
                name="search-list-filter"
                value={""}
                selected={refetchVars.filters?.createdById !== undefined}
                onClick={() => setCreatedByFilterOpen(true)}
              >
                Created By <span> ▼</span>
              </ToggleButton>
              <ToggleButton
                borderless={true}
                name="search-list-filter"
                value={""}
                selected={refetchVars.filters?.fromDate !== undefined}
                onClick={() => setCreatedOnFilterOpen(true)}
              >
                Created On <span> ▼</span>
              </ToggleButton>
            </nav>
            {/* TODO: Finish tags */}
            <FilterAndSortTags
              viewer={props.viewer}
              open={tagFilterOpen}
              currentFilter={tagsCurrentFilter}
              disabled={loading}
              onClose={() => setTagFilterOpen(false)}
              onApply={handleTagFilterSortApply}
            />
            <FilterAndSortClients
              viewer={props.viewer}
              open={clientsFilterOpen}
              currentFilter={clientsCurrentFilter}
              disabled={loading}
              onClose={() => setClientsFilterOpen(false)}
              onApply={handleClientsFilterSortApply}
            />
            <FilterAndSortCreatedBy
              viewer={props.viewer}
              open={createdByFilterOpen}
              currentFilter={createdByCurrentFilter}
              disabled={loading}
              onClose={() => setCreatedByFilterOpen(false)}
              onApply={handleCreatedByFilterSortApply}
            />
            <FilterAndSortCreatedOn
              open={createdOnFilterOpen}
              currentFilter={createdOnCurrentFilter}
              onClose={() => setCreatedOnFilterOpen(false)}
              onApply={handleCreatedOnFilterSortApply}
            />
          </header>
          <div className="ratecards-table">
            {error && (
              <div className="ratecard-list-item-container">
                <div className="ratecard-list-item">
                  <div>
                    Unable to load jobs: <pre>{error.toString()}</pre>
                  </div>
                </div>
              </div>
            )}
            {selectAllOnPage &&
              (selectAll ? (
                <div className="ratecards-list-select-all-section">
                  <span>
                    All &nbsp;
                    <strong>{totalCount}</strong>
                    &nbsp; Jobs selected.{" "}
                  </span>
                  &nbsp;
                  {/* todo check this */}
                  <span
                    className="pt-link ratecards-all-items"
                    key="clear-selections-link"
                    onClick={resetSelectionState}
                  >
                    Clear all selections
                  </span>
                </div>
              ) : (
                <div className="ratecards-list-select-all-section">
                  <span>All Jobs on this page selected. &nbsp;</span>
                  &nbsp;
                  <span
                    className="pt-link ratecards-all-items"
                    key="select-all-item"
                    onClick={handleToggleSelectAll}
                  >
                    Select all &nbsp;
                    <strong>{totalCount}</strong>
                    &nbsp; Jobs.
                  </span>
                  &nbsp;&nbsp;
                  <span
                    className="pt-link ratecards-all-items"
                    key="clear-selections-link"
                    onClick={resetSelectionState}
                  >
                    Clear all selections
                  </span>
                </div>
              ))}
            {loading ? (
              <LoadingIndicator />
            ) : jobs.length === 0 ? (
              <div>No Jobs Found</div>
            ) : (
              jobs.map((job) => {
                if (!job?.node) {
                  return <div>[Error getting job's details]</div>;
                }
                return (
                  <Job
                    key={job.node.id}
                    job={job.node}
                    getDetailRoute={(id: string) => `/admin/jobs/${id}/`}
                    editing={editing}
                    multiSelect={true}
                    selected={isSelected(job.node.jobId)}
                    onToggleSelect={handleToggleSelect}
                  />
                );
              })
            )}
          </div>
        </div>
        <WindowPagination
          hasNextPage={pageInfo.hasNextPage}
          hasPreviousPage={pageInfo.hasPreviousPage}
          handleNext={nextPage}
          handlePrevious={prevPage}
        />
      </div>
      <div
        className={classNames("ratecards-edit-actions", {
          hidden: editing === null,
          "bottom-appear": editing === true,
          "bottom-disappear": editing === false,
        })}
      >
        <button className="btn" disabled={!hasSelection()} onClick={handleExportJobs}>
          <FontAwesomeIcon icon="download" fixedWidth className="icon" />
          Export
        </button>
      </div>
      <div
        className={classNames("rc-container-backdrop", {
          hidden: editing === null,
          "fade-in": editing === true,
          "fade-out": editing === false,
        })}
      />
      <Modal
        show={showHelp}
        onHide={() => {
          setShowHelp(false);
        }}
      >
        <div className="container-section header">
          <h4>Help</h4>
        </div>
        <div className="container-section footer">
          <p>
            Here you can see all of your job labels. A job label is created everytime you
            perform a search
          </p>
        </div>
      </Modal>
    </div>
  );
}

const AdminClientJobsListContainer = createRefetchContainer(
  AdminClientJobsList,
  {
    viewer: graphql`
      fragment AdminClientJobs_viewer on Viewer
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 10 }
        last: { type: "Int" }
        after: { type: "String" }
        before: { type: "String" }
        filters: { type: "JobFiltersInput", defaultValue: {} }
        order: {
          type: "[JobSortInput]!"
          defaultValue: [{ field: CREATE_DATE, direction: DESC }]
        }
      ) {
        jobs(
          first: $first
          last: $last
          after: $after
          before: $before
          filters: $filters
          order: $order
          section: ADMIN
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          totalCount
          edges {
            node {
              id
              jobId
              ...Job_job
            }
          }
        }
        ...FilterAndSortTags_viewer
        ...FilterAndSortClients_viewer
        ...FilterAndSortCreatedBy2_viewer
      }
    `,
  },
  graphql`
    query AdminClientJobsRefetchQuery(
      $first: Int
      $last: Int
      $after: String
      $before: String
      $filters: JobFiltersInput
      $order: [JobSortInput]!
    ) {
      viewer {
        ...AdminClientJobs_viewer
          @arguments(
            first: $first
            last: $last
            after: $after
            before: $before
            filters: $filters
            order: $order
          )
      }
    }
  `
);

type AdminClientJobsProps = {
  // TODO: Add missing prop types
  route: {
    fetchAPI: FetchAPI,
  },
};

function AdminClientJobs(props: AdminClientJobsProps) {
  const fetchAPI = props.route.fetchAPI;
  const relayEnvironment = useRelayEnvironment();

  return (
    <QueryRenderer
      environment={relayEnvironment}
      query={graphql`
        query AdminClientJobsQuery {
          viewer {
            ...AdminClientJobs_viewer
          }
        }
      `}
      variables={{}}
      render={({ error, props }: RenderProps<AdminClientJobsQueryResponse>): any => {
        if (error) {
          console.error(error);
          return <div>Error!</div>;
        }

        if (!props) {
          return <LoadingIndicator />;
        }

        if (!props.viewer) {
          console.error(error);
          return <div>Error!</div>;
        }

        return <AdminClientJobsListContainer fetchAPI={fetchAPI} viewer={props.viewer} />;
      }}
    />
  );
}

export default AdminClientJobs;
